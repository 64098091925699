import { Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import styles from './PaymentsCreatePage.module.scss';
import CreateFormpage from '../../../Components/CreateFormpage/CreateFormpage';

const PaymentsCreatePage = () => {
	const [state, setState] = useState({});
	const history = useHistory();

	const handleConfirm = () => {};

	const inputsList = [
		{
			value: state?.vendor?.name,
			width: '250px',
			handleSelection: (vendor) => {
				setState({
					...state,
					vendor,
				});
			},
			id: 'vendor',
			name: 'vendor',
			inputLabel: 'Select Vendor',
			labelText: 'Vendor Name',
			placeholder: 'Select A Vendor',
			mandatory: true,
			optionsList: [],
			inputType: 'options_search',
			hideAddNew: true,
		},
		{
			value: null,
			width: '250px',
			handleSelection: () => {},
			id: 'store',
			name: 'store',
			inputLabel: 'Select Store',
			placeholder: 'Select A Store',
			labelText: 'Store',
			mandatory: true,
			optionsList: [],
			inputType: 'options_search',
			hideAddNew: true,
		},
		[
			{
				value: null,
				onChange: () => {},
				id: 'orderDate',
				name: 'orderDate',
				labelText: 'Order Date',
				mandatory: true,
				inputType: 'dateSelector',
				width: '250px',
			},
			{
				value: null,
				onChange: () => {},
				id: 'orderNumber',
				name: 'orderNumber',
				labelText: 'Order Number',
				mandatory: false,
				width: '250px',
			},
		],
		{
			value: null,
			onChange: () => {},
			id: 'expenseCateogry',
			name: 'expenseCateogry',
			labelText: 'Select Category',
			inputLabel: 'Expense Category',
			mandatory: true,
			width: '250px',
			inputType: 'options',
			optionsList: [],
		},
		{
			value: null,
			onChange: () => {},
			id: 'amount',
			name: 'amount',
			labelText: 'Amount',
			mandatory: true,
			type: 'number',
			width: '250px',
		},
	];

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>Add New Bill</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
					// disabled={isRowError}
				>
					Save
				</Button>
			</div>
			<CreateFormpage inputsList={inputsList} />
		</div>
	);
};

export default PaymentsCreatePage;
