/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
import { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Radio from 'rsuite/Radio';
import RadioGroup from 'rsuite/RadioGroup';
import Input from 'rsuite/Input';
import InputGroup from 'rsuite/InputGroup';
import { Info } from '@mui/icons-material';
import styles from './BillsSummary.module.scss';
import { modalActions } from '../../Actions';
import SummaryTable from './SummaryTable/SummaryTable';
import { BackdropLoader } from '../../Components/Loaders';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import PaymentDetailsModal from '../../Components/Modals/PaymentDetailsModal/PaymentDetailsModal';
import financialTransactionsServices from '../../Services/Analytics Services/financial-transactions.services';
import TransactionsModal from '../../Components/Modals/TransactionsModal/TransactionsModal';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
// import BillsDrawer from './BillsDrawer/BillsDrawer';
import drawerActions from '../../Actions/drawer.actions';
import { classes } from '../../Helpers';
import { urls } from '../../Constants';
import TransactionDrawer from '../../Components/Drawers/TransactionDrawer/TransactionDrawer';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import LegendModal from './Legend/LegendModal';

const FIND_FILTERS = [
	'vendor',
	'dateRange',
	'timeType',
	'customExclusiveFilters',
];

const timeTypeArr = [
	{
		label: 'Due Date',
		value: 'dueDate',
	},
	{
		label: 'Bill Date',
		value: 'transactionDate',
	},
];

const queryByList = ['PO_ID', 'BILL_ID'];

const BillsSummary = (props) => {
	const {
		togglePaymentDetailsModal,
		// toggleTransactionModal,
		toggleTransactionDrawer,
	} = props;
	// eslint-disable-next-line no-unused-vars
	const [filters, setFitlers] = useState({
		dateRangeFilter: {
			timeType: null,
			from: null,
			to: null,
			active: true,
		},
		subTypes: [],
		filterType: 'VENDOR_WISE',
		query: {
			queryValue: '',
			queryBy: queryByList[0],
		},
	});
	const [summaryList, setSummaryList] = useState({});
	const [loading, setLoading] = useState(false);
	const [update, setUpdate] = useState(0);
	const [showLegend, setShowLegend] = useState(false);

	const handleListFetch = async (requestOptions) => {
		setLoading(true);
		let dateRangeFilter = {
			timeType: requestOptions?.timeType,
			from: moment(requestOptions?.dateRange?.from).format('YYYY-MM-DD'),
			to: moment(requestOptions?.dateRange?.to).format('YYYY-MM-DD'),
		};
		const billTypes = [...requestOptions?.customExclusiveFilters?.billType];
		if (billTypes?.length && billTypes?.includes('GRN')) {
			if (!billTypes?.includes('ADVANCE')) {
				billTypes?.push('ADVANCE');
			}
		}
		setFitlers({
			...filters,
			subTypes: requestOptions?.customExclusiveFilters?.billType,
			dateRangeFilter: {
				...dateRangeFilter,
				active: requestOptions?.dateRange?.active,
			},
		});
		if (!requestOptions?.dateRange?.active) {
			dateRangeFilter = undefined;
		}
		const reqFilters = {
			vendors: requestOptions?.vendors,
			storeId: requestOptions?.store,
			dateRangeFilter,
			query: filters?.query?.queryValue ? filters?.query : undefined,
			billTypes: billTypes?.length ? billTypes : undefined,
		};
		try {
			const responseData =
				await financialTransactionsServices.fetchBillsSummary({
					req: {
						data: {
							filters: reqFilters,
							filterType: filters?.filterType,
						},
					},
				});
			if (responseData?.statusCode === 200) {
				const serializedData = Object?.keys(responseData?.data)?.reduce(
					(acc, eleKey) => {
						acc[eleKey] = responseData?.data?.[eleKey]?.map(
							(ele) => {
								// const totalAdvPaid =
								// 	ele?.totalAdvanceBillAmount -
								// 	ele?.advanceBillDues;
								// const totalAdvUsed =
								// 	totalAdvPaid - ele?.advanceBillUnused;
								const totalBills =
									((ele?.advanceBillDues ?? 0) + (ele?.grnBillDues ?? 0)) -
									((ele?.dateRangeData?.advanceBillDues ?? 0) +
										(ele?.dateRangeData?.grnBillDues ?? 0)) +
									((ele?.dateRangeData?.totalAdvanceBillAmount ?? 0) +
									(ele?.dateRangeData?.totalGrnBillAmount ?? 0));
								const totalGrnPaid =
									(ele?.dateRangeData?.totalGrnBillAmount ?? 0) -
									(ele?.dateRangeData?.grnBillDues ?? 0);
								const netAmountRequired =
									(ele?.dateRangeData?.totalGrnBillAmount ?? 0) -
									(totalGrnPaid +
										(ele?.dateRangeData?.advanceBillUnused ?? 0) +
										(ele?.dateRangeData?.debitNoteAmount ??
											0));
								const transferredTillNow =
									totalGrnPaid +
									(ele?.dateRangeData?.advanceBillUnused ?? 0);
								return {
									...ele,
									...ele?.vendorData,
									...ele?.poData,
									name:
										ele?.poData?.clientPoId ??
										ele?.vendorData?.name,
									// totalBills:
									// 	(ele?.totalGrnBillAmount ?? 0) +
									// 	(ele?.totalAdvanceBillAmount ?? 0),
									totalBills,
									paymentToBeMaid:
										(ele?.advanceBillDues ?? 0) + (ele?.grnBillDues ?? 0),
									// netAmountRequired:
									// 	ele?.grnBillDues - totalGrnPaid - totalAdvPaid - ele?.debitNoteAmount,
									netAmountRequired,
									transferredTillNow,
								};
							}
						);
						return acc;
					},
					{}
				);
				setSummaryList(serializedData);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchViewBills = async (
		rowData,
		status,
		paymentType,
		columnHeader,
		billType
	) => {
		let orderId;
		let vendorId;
		let type = 'BILL';
		let subTypes;
		let dateRangeFilter = {
			from: filters?.dateRangeFilter?.from,
			to: filters?.dateRangeFilter?.to,
			timeType: filters?.dateRangeFilter?.timeType,
		};
		let transactionType = 'BILLS';
		if (paymentType === 'debitNoteAmount') {
			type = 'CREDIT';
			transactionType = 'CREDITS';
			dateRangeFilter = undefined;
		}
		if (filters?.filterType === 'PO_WISE') {
			orderId = rowData?.id;
		}
		if (filters?.filterType === 'VENDOR_WISE') {
			vendorId = rowData?.id;
		}
		if (filters?.filterType === 'VENDOR_WISE') {
			if (paymentType === 'debitNoteAmount') {
				subTypes = undefined;
			} else if (billType === 'GRN') {
				subTypes = [billType, 'ADVANCE'];
			} else {
				subTypes = [billType];
			}
		}
		if (!filters?.dateRangeFilter?.active) {
			dateRangeFilter = undefined;
		}
		try {
			setLoading(true);
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								vendorId,
								orderId,
								dateRangeFilter,
								status,
								subTypes,
								avoidFromDateFilter:
									paymentType === 'totalBills',
							},
							type,
						},
					},
				});
			if (response?.statusCode === 200) {
				// in case of bills were brought to frontend
				let listData = response?.data;
				if (paymentType === 'alreadyPaidAdvance') {
					listData = listData?.filter((ele) => {
						return !ele?.parentRowId;
					});
				} else if (paymentType === 'alreadyPaidGrn') {
					listData = listData?.filter((ele) => {
						return ele?.parentRowId;
					});
				}
				// if (paymentType === 'totalBills') {
				toggleTransactionDrawer(true, {
					transactionType,
					listData,
					heading: columnHeader,
				});
				// } else {
				// 	toggleTransactionModal(true, {
				// 		transactionType,
				// 		listData,
				// 	});
				// }
				// }
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handlePaymentClick = (
		rowData,
		paymentType,
		statusFilter,
		type,
		columnHeader,
		billType
	) => {
		let redirectionURL = `${urls.EXTENSION}
				${urls.BILLS}
				?status=${statusFilter}
				${
					filters?.filterType === 'PO_WISE'
						? `&poId=${rowData?.id}&clientPoId=${rowData?.clientPoId}`
						: `&vendor=${rowData?.id}`
				}
				&from=${filters?.dateRangeFilter?.from}
				&to=${filters?.dateRangeFilter?.to}
				&dateRangeActive=${filters?.dateRangeFilter?.active}`;
		if (type === 'credits') {
			redirectionURL = `${urls.EXTENSION}${urls.CREDITS}
				?status=${statusFilter}
				&vendor=${rowData?.id}
				&from=${filters?.dateRangeFilter?.from}
				&to=${filters?.dateRangeFilter?.to}
				&dateRangeActive=${filters?.dateRangeFilter?.active}`;
		}
		if (paymentType === 'name') {
			window.open(redirectionURL, '_blank');
			return;
		}
		if (
			paymentType === 'grnBillDues' ||
			paymentType === 'advanceBillDues'
		) {
			togglePaymentDetailsModal(true, {
				rowData,
				paymentType,
				dateRangeFilter: filters?.dateRangeFilter?.active
					? filters?.dateRangeFilter
					: undefined,
				filterType: filters?.filterType,
				avoidFromDateFilter: true,
				billType,
			});
		} else {
			let status = [];
			if (
				paymentType === 'alreadyPaidAdvance' ||
				paymentType === 'alreadyPaidGrn'
			) {
				status = ['PAID', 'PARTIALLY_PAID'];
			}
			if (paymentType === 'debitNoteAmount') {
				status = ['UNPAID', 'PARTIALLY_PAID'];
			}
			if (paymentType === 'paymentToBeMaid') {
				status = ['UNPAID', 'PARTIALLY_PAID'];
			}
			if (paymentType === 'totalBills') {
				status = ['PAID', 'UNPAID', 'PARTIALLY_PAID'];
			}
			fetchViewBills(
				rowData,
				status,
				paymentType,
				columnHeader,
				billType
			);
		}
	};

	const headerComponent = () => {
		return (
			<div className={styles.header}>
				{filters?.filterType === 'PO_WISE' && (
					<div
						className={classes(
							styles.query,
							filters?.filterType === 'PO_WISE' ? styles.show : ''
						)}
					>
						<InputGroup
							style={{
								width: 200,
								border: '1px solid #FFB703',
							}}
							size='sm'
						>
							<Input
								value={filters?.query?.queryValue}
								onChange={(value) => {
									setFitlers((prev) => {
										return {
											...prev,
											query: {
												...prev?.query,
												queryValue: value,
											},
										};
									});
								}}
							/>
							<InputGroup.Addon>
								<select
									value={filters?.query?.queryBy}
									onChange={(e) => {
										setFitlers((prev) => {
											return {
												...prev,
												query: {
													...prev?.query,
													queryBy: e?.target?.value,
												},
											};
										});
									}}
								>
									<option value=''>Search BY</option>
									{queryByList?.map((ele) => {
										return (
											<option value={ele}>{ele}</option>
										);
									})}
								</select>
							</InputGroup.Addon>
						</InputGroup>
					</div>
				)}
				<RadioGroup
					value={filters?.filterType}
					onChange={(value) => {
						setSummaryList({});
						setFitlers((prev) => {
							return { ...prev, filterType: value };
						});
					}}
					inline
				>
					<Radio value='VENDOR_WISE'>Vendor Wise</Radio>
					<Radio value='PO_WISE'>PO Wise</Radio>
				</RadioGroup>
			</div>
		);
	};

	const customExclusiveFilters = [
		{
			key: 'billType',
			filterList: ['GRN', 'EXPENSE', 'CAPEX', 'UTILITIES']?.map(
				(ele) => {
					return {
						// eslint-disable-next-line prefer-template
						id: ele,
						title: ele,
					};
				}
			),
			allowNoSelection: false,
			label: 'Select Bill Type',
			defaultState: [],
		},
	];

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={loading} showProgress />
			<PaymentDetailsModal
				updateState={() => {
					setUpdate((prev) => {
						return prev + 1;
					});
				}}
			/>
			<TransactionsModal />
			<TransactionDrawer />
			<POItemsModal />
			<LegendModal
				open={showLegend}
				handleClose={() => {
					setShowLegend(false);
				}}
			/>
			<div className={styles.filters}>
				<ImagesFinder
					pageLabel='Bills Summary'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					update={update}
					timeTypeArray={timeTypeArr}
					additionInfo={{
						showAdd: false,
					}}
					headerComponent={headerComponent}
					customExclusiveFilters={customExclusiveFilters}
				/>
			</div>
			<div
				onClick={() => {
					setShowLegend(true);
				}}
				className={styles.legend}
			>
				<Info className={styles.icon} />
			</div>
			<div className={styles.summary}>
				{!Object.keys(summaryList ?? {})?.length && (
					<p>No Summary Available</p>
				)}
				{Object.keys(summaryList ?? {})?.map((eleKey, idx) => {
					const element = summaryList?.[eleKey];
					return !!element?.length && (
						<SummaryTable
							element={element}
							idx={idx}
							vendorType={eleKey}
							filterType={filters?.filterType}
							handlePaymentClick={handlePaymentClick}
						/>
					);
				})}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { LIST: vendors } = state.form.ALL_VENDORS.data;

	return {
		vendors,
	};
};

const mapDispatchToProps = {
	togglePaymentDetailsModal: modalActions.togglePaymentDetailsModal,
	toggleTransactionModal: modalActions.toggleTransactionsModal,
	toggleTransactionDrawer: drawerActions.toggleTransactionDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsSummary);
