import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ImageModal.module.scss';

const ImageModal = (props) => {
	const { show, toggleModal, data, children } = props;

	const handleClose = () => {
		toggleModal(false);
	};

	const { image, heading } = data;

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={heading}
			open={show}
			handleClose={handleClose}
			renderBtns={() => {}}
			width={data?.width}
			height={data?.height}
		>
			<div className={`${styles.root}`}>
				<img
					src={image}
					alt=''
					style={{
						display: data?.centerImage ? 'block' : 'block',
						margin: data?.centerImage ? '0 auto' : 0,
					}}
				/>
			</div>
			{children}
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.imageModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleImageModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
