/* eslint-disable object-curly-newline */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { connect } from 'react-redux';
import { FormSectionHeader } from '../../../Components/Forms';
import { CreatePageHeader } from '../../../Components/Headers';
import GeneralDetails from './GeneralDetails';
import cookingProductConfig from './cookingProduct.config';
import CookingProductConfigSubSection from './CookingProductConfigSubSection';
import { history, inputHelper, isEmpty } from '../../../Helpers';
import { urls } from '../../../Constants';
import { formActions } from '../../../Actions';

const CookingProductConfigEditPage = (props) => {
	const { data, fetchProductConfig, saveProductConfig, breadcrumbs } = props;
	const [state, setState] = useState({
		...cookingProductConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const { id } = useRouteMatch().params;
	useEffect(() => {
		fetchProductConfig({
			entity: 'COOKING_PRODUCT_CONFIG',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, []);
	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(cookingProductConfig.deserializer(state, data));
		}
	}, [data]);
	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return fieldValue;
			})
		);
	};
	const handleCancel = () => {
		history.push(
			`${urls.EXTENSION}${urls.COOKING_PRODUCT_CONFIG}/view/${id}`
		);
	};
	const { fields } = state;
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = cookingProductConfig.serializer(state).fields;
		req.params = {
			id,
		};
		setNumberOfSubmissions(numberOfSubmissions + 1);
		saveProductConfig({
			entity: 'COOKING_PRODUCT_CONFIG',
			req,
			method: 'UPDATE',
			redirect: `${urls.EXTENSION}${urls.COOKING_PRODUCT_CONFIG}`,
		});
	};
	return (
		<div>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Edit Product Cooking Config'
					handleCancel={handleCancel}
					handleSave={handleSave}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: `Edit ${state.fields.productName}`,
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='Product Details'
					sectionName='Product Details'
				>
					<GeneralDetails fields={fields} />
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Product Cooking Details'
					sectionName='Product Cooking Details'
				>
					<CookingProductConfigSubSection
						fields={fields}
						handleInput={handleInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.form.COOKING_PRODUCT_CONFIG.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProductConfig: formActions.gateway,
	saveProductConfig: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingProductConfigEditPage);
