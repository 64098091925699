// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import PettyCash from '../../Pages/PettyCash/PettyCash';
import PettyCashCreatePage from '../../Pages/PettyCash/PettyCashCreatePage/PettyCashCreatePage';

const PettyCashRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<PettyCash />
			</Route>
			<Route exact path={`${path}/add`}>
				<PettyCashCreatePage />
			</Route>
		</Switch>
	);
};

export default PettyCashRoutes;
