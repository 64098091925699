import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Redirect, Route, Switch } from 'react-router';
// eslint-disable-next-line object-curly-newline
import { ListPage, ViewPage, CreatePage, EditPage } from '../../Pages';

const CRUDRoutes = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		path,
		entity,
		viewPage,
		createPage,
		listPageProps,
		editPage,
		listPage,
		listFilter,
		searchBy,
		placeholder,
		userPermissions,
		searchOnClick = false,
		listHeader = null,
		header = false,
		headerName = '',
		type,
		navState,
	} = props;

	return (
		<Switch>
			<Route exact path={`${path}`}>
				<ListPage
					ListComponent={listPage}
					placeholder={placeholder}
					listPageProps={listPageProps}
					entity={entity}
					listFilter={listFilter}
					searchBy={searchBy}
					userPermissions={userPermissions}
					searchOnClick={searchOnClick}
					listHeader={listHeader}
					header={header}
					headerName={headerName}
					type={type}
					navState={navState}
				/>
			</Route>
			<Route exact path={`${path}/view/:id`}>
				{userPermissions?.VIEW ? (
					<ViewPage
						viewPage={viewPage}
						userPermissions={userPermissions}
					/>
				) : (
					<Redirect to={path} />
				)}
			</Route>
			<Route exact path={`${path}/edit/:id`}>
				{userPermissions?.EDIT ? (
					<EditPage editPage={editPage} entity={entity} path={path} />
				) : (
					<Redirect to={path} />
				)}
			</Route>
			<Route exact path={`${path}/new`}>
				{userPermissions?.CREATE ? (
					<CreatePage createPage={createPage} entity={entity} />
				) : (
					<Redirect to={path} />
				)}
			</Route>
		</Switch>
	);
};

CRUDRoutes.defaultProps = {
	listFilter: null,
};

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	return {
		userPermissions,
		navState,
	};
};

export default connect(mapStateToProps)(CRUDRoutes);
