import { cloneDeep } from 'lodash';
import moment from 'moment';

const initialState = {
	licenseType: '',
	store: null,
	status: '',
	plan: '',
	startDate: new Date(),
	endDate: null,
	graceDays: 0,
	// processType: null,
	machineId: null,
	outlets: [],
	mappedMachine: {},
};

const reducer = (state, action) => {
	const outletsList = (action?.payload?.stores ?? [])?.map((store) => {
		return store?.storeId;
	});
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'SET_LICENSE':
			return {
				licenseType: action?.payload?.licenseType,
				status: action?.payload?.status,
				plan: action?.payload?.plan,
				graceDays: action?.payload?.graceDays,
				machineId: action?.payload?.machineId,
				startDate: new Date(action?.payload?.startDate),
				endDate: new Date(action?.payload?.endDate),
				store: action?.payload?.licenseStoreId,
				outlets: outletsList,
				mappedMachine: action?.payload?.machineAt[0] ?? {},
			};
		default:
			return state;
	}
};

const config = {
	initialState,
	reducer,
	deserializer: (state, isEdit) => {
		const clonedState = cloneDeep(state);

		const status = () => {
			const currDate = new Date();
			const isForthcoming = clonedState?.startDate > currDate;
			const isExpired = clonedState?.endDate <= currDate;
			if (isForthcoming) {
				return 'PENDING';
			}
			if (isExpired) {
				return 'EXPIRED';
			}
			return 'ACTIVE';
		};

		let newState = {
			...clonedState,
			storeId: clonedState?.store,
			status: status(),
			startDate: moment(clonedState?.startDate)?.format('YYYY-MM-DD'),
			endDate: moment(clonedState?.endDate)?.format('YYYY-MM-DD'),
		};
		if (isEdit) {
			// eslint-disable-next-line no-restricted-globals
			const graceDays = !isNaN(parseInt(clonedState?.graceDays, 10))
				? parseInt(clonedState?.graceDays, 10)
				: 0;
			newState = {
				storeId: clonedState?.store,
				graceDays,
				status: status(),
				startDate: moment(clonedState?.startDate)?.format('YYYY-MM-DD'),
				endDate: moment(clonedState?.endDate)?.format('YYYY-MM-DD'),
				plan: clonedState?.plan,
			};
		}
		return newState;
	},
};

export default config;
