import {
	forwardRef, useEffect, useState
} from 'react';
import { SketchPicker } from 'react-color';
import { Input } from '@/Components/ui/input';
import { Label } from '@/Components/ui/label';

const AddNewSection = forwardRef((props, ref) => {
	const [sectionColor, setSectionColor] = useState('#004225');
	const [newSectionName, setNewSectionName] = useState(null);
	const [showColorPicker, setShowColorPicker] = useState(false);

	const handleSectionColorChange = (newColor) => {
		setSectionColor(newColor.hex);
	};
	useEffect(() => {
		// eslint-disable-next-line no-param-reassign
		ref.current.sectionColor = sectionColor;
		// eslint-disable-next-line no-param-reassign
		ref.current.newSectionName = newSectionName;
	}, [sectionColor, newSectionName]);

	return (
		<div className='w-full flex flex-col gap-2 p-4'>
			<Label className='text-red-600'>
				Select Color and Enter Section Name*
			</Label>
			<div className='w-full flex flex-row justify-between gap-4'>
				<div className='w-[54px] h-[55px] p-1 border border-[#222222] rounded-md'>
					<div
						className='w-[100%] h-[100%] rounded-[4px] '
						onClick={() => {
							setShowColorPicker(!showColorPicker);
						}}
						style={{
							backgroundColor: sectionColor,
						}}
					/>

					{showColorPicker && (
						<div className='absolute z-[999]'>
							<div
								className='fixed top-0 bottom-0 left-0 right-0'
								onClick={() => {
									setShowColorPicker(false);
								}}
							/>
							<SketchPicker
								color={sectionColor}
								onChange={handleSectionColorChange}
								position='center'
							/>
						</div>
					)}
				</div>
				<Input
					type='text'
					value={newSectionName}
					placeholder='Section name'
					onChange={(e) => {
						setNewSectionName(e.target.value);
					}}
					className='outline-none'
				/>
			</div>
		</div>
	);
});

export default AddNewSection;
