import similarProductsConstants from '../Constants/similarProducts.constansts';
import similarProductsServices from '../Services/similarProducts.services';

const similarProductsActions = {
	fetchSimilarProducts,
};

function fetchSimilarProducts(
	req = {
		id: '',
	}
) {
	return (dispatch) => {
		dispatch(request());
		similarProductsServices.getGroups(req).then(
			(res) => {
				dispatch(success(res));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: similarProductsConstants.FETCH_GROUPS_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: similarProductsConstants.FETCH_GROUPS_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: similarProductsConstants.FETCH_GROUPS_FAILURE,
			payload,
		};
	}
}

export default similarProductsActions;
