import { connect } from 'react-redux';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { ListTable } from '../../ListTable';

const ACTION_COLUMNS = [];

const COLMAP = {
	'Order Id': 'id',
	'Product Name': 'productName',
	'Variant Name': 'variantName',
	Price: 'price',
	Store: 'storeName',
	'Order Received At': 'orderReceivedAt',
	Student: 'studentDetails',
	'Order Value': 'orderValue',
	'Ordered Quantity': 'qty',
	Fulfilled: 'fulfilled',
	'Updated At': 'updatedAt',
};

const OrdersItemsModal = (props) => {
	const TABLE_COLUMNS = [
		{
			title: 'Order Id',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Product Name',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Variant Name',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Store',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Price',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Order Received At',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Student',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Order Value',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Ordered Quantity',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Fulfilled',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Updated At',
			config: {
				type: 'DATA',
			},
		},
	];
	const { show, data, toggleModal } = props;

	const { listData = [], heading = 'Items', headers = [] } = data;

	const handleClose = () => {
		toggleModal(false, {});
	};

	// const SPECIAL_ITEMS = {
	// Unit: (itemId) => {
	// const item = items?.find((poItem) => {
	// return itemId === poItem?.id;
	// });
	// let itemUnit = item?.qty > 1000 ? 'Kg' : 'gram';
	// if (item?.unit === 'count') {
	// itemUnit = 'count';
	// }
	// return itemUnit;
	// },
	// 'Ordered Quantity': (itemId) => {
	// const item = items?.find((poItem) => {
	// return itemId === poItem?.id;
	// });
	// let itemQty = item?.qty;
	// if (item?.unit !== 'count') {
	// itemQty =
	// item?.qty > 1000
	// ? (item?.qty / 1000)?.toFixed(3)
	// : item?.qty;
	// }
	// return itemQty;
	// },
	// Packaged: (itemId) => {
	// const item = items?.find((poItem) => {
	// return itemId === poItem?.id;
	// });
	// return item?.packaged;
	// },
	// Received: (itemId) => {
	// const item = items?.find((poItem) => {
	// return itemId === poItem?.id;
	// });
	// return item?.storeFulfilled;
	// },
	// Dispatched: (itemId) => {
	// const item = items?.find((poItem) => {
	// return itemId === poItem?.id;
	// });
	// let itemFulfilled = item?.fulfilled;
	// if (item?.unit !== 'count') {
	// itemFulfilled =
	// item?.qty > 1000
	// ? (item?.fulfilled / 1000)?.toFixed(3)
	// : item?.fulfilled;
	// }
	// return itemFulfilled;
	// },
	// Fulfilled: (itemId) => {
	// const item = items?.find((poItem) => {
	// return itemId === poItem?.id;
	// });
	// let itemFulfilled = item?.fulfilled;
	// if (item?.unit !== 'count') {
	// itemFulfilled =
	// item?.qty > 1000
	// ? (item?.fulfilled / 1000)?.toFixed(3)
	// : item?.fulfilled;
	// }
	// return itemFulfilled;
	// },
	// };

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			width={data?.vendorId ? '40rem' : '60rem'}
			// height='20rem'
		>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS?.filter((ele) => {
					return headers?.includes(ele?.title);
				})}
				data={listData}
				actions={[...ACTION_COLUMNS]}
				special={{}}
			/>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.ordersItemsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleOrdersItemsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersItemsModal);
