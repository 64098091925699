/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { useHistory } from 'react-router';
// import { el } from 'date-fns/locale';
import Heading from '../Heading/Heading';
import styles from './Table.module.scss';
import DownloadButton from '../DownloadButton/DownloadButton';
import { classes, formatNumber } from '../../../Helpers';

function Table({ data, productsList, className = '', measurement }) {
	const { heading, tooltip, datasets, limit } = data;
	const history = useHistory();

	function createImage(tempData) {
		return (
			<div className={styles.image}>
				{[tempData.label, ...tempData?.data]
					?.slice(0, limit ? limit + 1 : undefined)
					?.map((ele, index) => {
						const product = productsList?.find((prod) => {
							// eslint-disable-next-line eqeqeq
							return prod?.id == ele;
						});
						if (index === 0) {
							return (
								<div
									className={styles.icon}
									style={{
										width: '35px',
										height: '35px',
										fontWeight: '500',
									}}
								/>
							);
						}
						return (
							<div
								className={styles.icon}
								onClick={() => {
									if (tempData?.url) {
										history.push(tempData.url + tempData?.ids?.[index - 1]);
									}
								}}
							>
								{/* <img alt='product' src={product?.productImageUrl} /> */}
								{product?.productImageUrl ? (
									<img
										alt='product'
										style={{
											width: '35px',
											height: '35px',
											cursor: 'pointer',
										}}
										src={product?.productImageUrl}
										// src='https://cdn2.webdamdb.com/md_I0TSl9ED8mY3.png?1565280602'
										product={product}
									/>
								) : '-'}
							</div>
						);
					})}
			</div>
		);
	}
	function createProductNameList(tempData) {
		return (
			<div className={styles.values}>
				{[tempData?.label, ...tempData?.data]
					?.slice(0, limit ? limit + 1 : undefined)
					?.map((ele, index) => {
						if (index === 0) {
							return (
								<div
									style={{
										width: '100%',
										height: '40px',
										fontWeight: '500',
									}}
								>
									{ele}
								</div>
							);
						}
						return (
							<>
								<div
									onClick={() => {
										if (tempData?.url) {
											history.push(tempData.url + tempData?.ids?.[index - 1]);
										}
									}}
									className={styles.name}
									style={{
										width: '100%',
										height: '40px',
										cursor: 'pointer',
									}}
								>
									{ele}
								</div>
							</>
						);
					})}
			</div>
		);
	}
	function createProductDeltaList(tempData) {
		return (
			<>
				{[tempData?.label, ...tempData?.data]
					?.slice(0, limit ? limit + 1 : undefined)
					?.map((ele, index) => {
						if (index === 0) {
							return (
								<div
									style={{
										width: '20px',
										height: '43px',
										minHeight: '43px',
										fontWeight: '500',
									}}
								>
									{ele}
								</div>
							);
						}
						const value = parseFloat(ele);
						return (
							<>
								<div
									onClick={() => {
										if (tempData?.url) {
											history.push(tempData.url + tempData?.ids?.[index - 1]);
										}
									}}
									className={styles.delta}
									style={{
										width: '20px',
										height: '43px',
										minHeight: '43px',
										display: 'flex',
										alignItems: 'center',
										color: ele < 0 ? 'red' : 'green',
										cursor: 'pointer',
									}}
								>
									{value < 0 ? (
										<>
											<ArrowDownwardOutlinedIcon
												style={{
													fontSize: '2rem',
													color: 'red',
												}}
											/>
										</>
									) : (
										<>
											<ArrowUpwardOutlinedIcon
												style={{
													fontSize: '2rem',
													color: 'green',
												}}
											/>
										</>
									)}
									<sub className='value'>{value?.toFixed(2)}</sub>
								</div>
							</>
						);
					})}
			</>
		);
	}

	const downloadableData = (() => {
		const tempHeading = data?.datasets.map((ele) => {
			return ele?.label?.split(',').join("'");
		});
		const tempData = data?.datasets?.map((ele) => {
			const values = ele?.data?.map((ele2) => {
				return ele2?.split(',').join("'");
			});
			return values;
		});
		const tempData2 = tempData?.[0]?.map((col, i) => {
			return tempData?.map((row) => {
				return row[i];
			});
		});
		return {
			header: tempHeading,
			fileData: tempData2,
			fileName: heading,
		};
	})();

	return (
		<div className={classes(styles.root, className)}>
			<div className={styles.header}>
				<Heading tooltip={tooltip} heading={heading} />
				<DownloadButton tableData={downloadableData} />
			</div>
			<table className={styles['table-container']}>
				<thead>
					<tr>
						{datasets?.map((ele) => {
							if (ele?.type === 'PRODUCT') {
								return (
									<th
										key={ele?.label}
										className={styles['img-col']}
									>
										{' '}
									</th>
								);
							}
							if (ele?.type === 'CURRENCY') {
								return (
									<th
										key={ele?.label}
										className={styles['img-col']}
									>
										{ele?.label}
										{measurement === 'US' ? '($)' : '(Rs.)'}
									</th>
								);
							}
							return <th key={ele?.label}>{ele?.label}</th>;
						})}
					</tr>
				</thead>
				<tbody>
					{datasets?.[0]?.data
						?.filter((item, idx) => {
							return limit ? idx < limit : true;
						})
						?.map((eleItem, indx) => {
							return (
								<tr key={eleItem}>
									{datasets?.map((ele) => {
										if (ele?.type === 'PRODUCT') {
											const product = productsList?.find(
												(prod) => {
													// eslint-disable-next-line eqeqeq
													return prod?.id == ele?.data?.[indx];
												}
											);
											return (
												<td
													key={ele?.data?.[indx]}
													className={styles.center}
													onClick={() => {
														if (
															ele?.url &&
															ele?.ids?.[indx]
														) {
															history.push(
																ele.url +
																	ele?.ids?.[
																		indx
																	]
															);
														}
													}}
												>
													<img
														alt='product'
														src={
															product?.productImageUrl
														}
														// src='https://cdn2.webdamdb.com/md_I0TSl9ED8mY3.png?1565280602'
														product={product}
													/>
												</td>
											);
										}
										if (ele?.type === 'DELTA') {
											const value = parseFloat(
												ele?.data?.[indx]
											);
											return (
												<td
													key={ele?.data?.[indx]}
													onClick={() => {
														if (
															ele?.url &&
															ele?.ids?.[indx]
														) {
															history.push(
																ele.url +
																	ele?.ids?.[
																		indx
																	]
															);
														}
													}}
												>
													<div
														onClick={() => {}}
														className={
															styles.center
														}
														style={{
															color:
																value < 0
																	? 'red'
																	: 'green',
														}}
													>
														{value < 0 ? (
															<>
																<ArrowDownwardOutlinedIcon
																	style={{
																		fontSize:
																			'2rem',
																		color: 'red',
																	}}
																/>
															</>
														) : (
															<>
																<ArrowUpwardOutlinedIcon
																	style={{
																		fontSize:
																			'2rem',
																		color: 'green',
																	}}
																/>
															</>
														)}
														<sub className='value'>
															{value?.toFixed(2)}
														</sub>
													</div>
												</td>
											);
										}
										if (ele?.type === 'CURRENCY') {
											return (
												<td
													key={ele?.data?.[indx]}
													onClick={() => {
														if (
															ele?.url &&
															ele?.ids?.[indx]
														) {
															history.push(
																ele.url +
																	ele?.ids?.[
																		indx
																	]
															);
														}
													}}
												>
													{/* {ele?.data?.[indx]} */}
													{formatNumber(
														measurement,
														ele?.data?.[indx]
													)}
												</td>
											);
										}
										return (
											<td
												key={ele?.data?.[indx]}
												onClick={() => {
													if (
														ele?.url &&
														ele?.ids?.[indx]
													) {
														history.push(
															ele.url +
																ele?.ids?.[indx]
														);
													}
												}}
											>
												{ele?.data?.[indx]}
											</td>
										);
									})}
								</tr>
							);
						})}
				</tbody>
			</table>
			{/* <div className={styles.grid}>
				{datasets?.map((ele) => {
					if (ele.type === 'PRODUCT') {
						return <div className={styles.images}>{createImage(ele)}</div>;
					}
					if (ele.type === 'DELTA') {
						return <div className={styles.arrow}>{createProductDeltaList(ele)}</div>;
					}
					return <div className={styles.values}>{createProductNameList(ele)}</div>;
				})}
			</div> */}
		</div>
	);
}

export default Table;
