/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import {
	useEffect, useMemo, useState
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'rsuite';
import { Edit } from '@mui/icons-material';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';
import { ListTable } from '@/Components/ListTable';
import { BackdropLoader } from '@/Components/Loaders';
import { notificationServices } from '@/Services/Notification';
import gstConfigServices from '@/Services/gst.services';

const TABLE_COLUMNS = [
	{
		title: 'Uy GST Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'GST Number',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Legal Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'City',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'State',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Pincode',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'Edit',
		config: {
			type: 'Data',
		},
	},
];
const COLMAP = {
	'Uy GST Id': 'id',
	'GST Number': 'gstNumber',
	'Legal Name': 'legalName',
	City: 'city',
	State: 'state',
	Pincode: 'pincode',
};

function ListGstDetails(props) {
	const { query } = props;
	const [gstData, setGstData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const { url } = useRouteMatch();

	const fetchGstData = async () => {
		try {
			setIsLoading(true);
			const response = await gstConfigServices?.getGst();
			if (response?.statusCode === 200) {
				setGstData(response?.data || []);
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Error fetching Details',
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchGstData();
	}, []);

	const newGstData = useMemo(() => {
		return (gstData || []).map((gst) => {
			return {
				id: gst?.id,
				gstNumber: gst?.gstNumber,
				city: gst?.city || '',
				state: gst?.state,
				pincode: gst?.pincode,
				legalName: gst?.legalName,
			};
		});
	}, [gstData]);

	const handleGstRfq = (id) => {
		history.push(`${url}/edit/${id}`);
	};

	// const handlerfq = (filters) => {
	// 	if (!filters?.dateRange?.from || !filters?.dateRange?.to) return;
	// 	const dateRangeFilter = {
	// 		from: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
	// 		to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
	// 	};
	// 	const rfqFilter = {
	// 		storeId: filters?.store,
	// 		dateRangeFilter,
	// 	};
	// 	fetchRfq(rfqFilter);
	// };

	const specialColumnsMappings = {
		Edit: (id) => {
			return (
				<div
					style={{
						width: '3.9rem',
						cursor: 'pointer',
					}}
				>
					<Button onClick={() => handleGstRfq(id)}>
						<Edit />
					</Button>
				</div>
			);
		},
	};
	return (
		<>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ListTable
				columns={TABLE_COLUMNS}
				data={newGstData?.filter((ele) => {
					if (
						ele?.legalName?.toLowerCase().includes(
							query.toLowerCase()
						)
					) {
						return true;
					}
					return false;
				})}
				colMap={COLMAP}
				special={specialColumnsMappings}
				actions={SPECIAL_COLUMNS}
			/>
		</>
	);
}
export default ListGstDetails;
