/* eslint-disable */
import { useEffect } from 'react';
import { SelectPicker } from 'rsuite';
import { Label } from '@/Components/ui/label';

const SingleSelectFilter = (props) => {
	const { filterData, filterProps, state, setState = () => {} } = props;
	const {
		compData = [],
		dependentOn = -1,
		label = '',
		selectedValueKey,
		onDependencyUpdate = () => {},
		disabled = false,
		compDataFromProp,
	} = filterProps;
	const dependency = filterData?.[dependentOn];
	const isDisabled =
		(dependentOn !== -1 &&
		!state?.[dependency?.selectedValueKey]) ||
		disabled;
	const selectedValue = state?.[selectedValueKey];

	useEffect(() => {
		onDependencyUpdate(state?.[dependency?.selectedValueKey]);
	}, [state?.[dependency?.selectedValueKey]]);

	
	const handleSelect = (value) => {
		setState((prev) => {
			return {
				...prev,
				[selectedValueKey]: value,
			};
		});
	};

	return (
		<div className='flex flex-col gap-1'>
			<Label className={`${filterProps?.mandatory ? 'text-red-500' : ''}`}>
				{label}
				{filterProps?.mandatory ? '*' : ''}
			</Label>
			<SelectPicker
				data={compDataFromProp ?? compData}
				// placeholder={filterLabels?.store ?? 'Select Store'}
				onChange={handleSelect}
				cleanable={false}
				value={selectedValue}
				disabled={isDisabled}
				style={{
					minWidth: '200px',
				}}
				// onClean={() => {
				// console.log('heeloooo there');
				// }}
			/>
		</div>
	);
};

export default SingleSelectFilter;
