import { useState } from 'react';
import { classes } from '../../../../Helpers';
import foodVector from '../../../../Images/foodVector.jpg';
import styles from './IMSSummaryPage.module.css';

const hourlyProduction = [
	{
		hourlyWindow: '2022-12-26 8 AM - 9 AM',
		noOfScans: 30,
		underWeight: 0,
		underWeightQuantity: 0.117,
		overWeight: 0,
		overWeightQuantity: 0.117,
	},
	{
		hourlyWindow: '2022-12-26 3 PM - 4 PM',
		noOfScans: 30,
		underWeight: 0,
		underWeightQuantity: 0.117,
		overWeight: 0,
		overWeightQuantity: 0.117,
	},
	{
		hourlyWindow: '2022-12-26 4 PM - 5 PM',
		noOfScans: 30,
		underWeight: 0,
		underWeightQuantity: 0.117,
		overWeight: 0,
		overWeightQuantity: 0.117,
	},
	{
		hourlyWindow: 'Total',
		noOfScans: 536,
		underWeight: 0,
		underWeightQuantity: 0.351,
		overWeight: 0,
		overWeightQuantity: 0.351,
	},
];

const productionSummaryData = [
	{
		skuName: 'Butterscotch Cake (500gm)',
		skuVariant: 'Piece ',
		vendor: null,
		stockInScansViaUY: 43,
		underWeight: 0,
		underWeightQuantity: 0.117,
		overWeight: 0,
		overWeightQuantity: 0.117,
		totalWeight: 24.634,
		averageWeight: 573,
	},
	{
		skuName: 'Choco Crunch KitKat Cake (500gm)',
		skuVariant: 'Piece ',
		vendor: null,
		stockInScansViaUY: 46,
		underWeight: 0,
		underWeightQuantity: 0.0,
		overWeight: 0,
		overWeightQuantity: 0.0,
		totalWeight: 26.616,
		averageWeight: 579,
	},
	{
		skuName: 'Choco Truffle Cake (500gm)',
		skuVariant: 'Piece ',
		vendor: null,
		stockInScansViaUY: 299,
		underWeight: 0,
		underWeightQuantity: 0.117,
		overWeight: 1,
		overWeightQuantity: 0.173,
		totalWeight: 178.731,
		averageWeight: 598,
	},
];

function IMSSummary() {
	const [dataType, setDataType] = useState('hourly_production');

	const handleSelection = (value) => {
		setDataType(value);
	};

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<p>IMS Summary</p>
			</div>
			<div className={styles['summary-data-selector']}>
				<div
					onClick={() => {
						return handleSelection('hourly_production');
					}}
					className={classes(
						styles.option,
						dataType === 'hourly_production' ? styles.selected : ''
					)}
				>
					Hourly Production
				</div>
				<div
					onClick={() => {
						return handleSelection('production_summary');
					}}
					className={classes(
						styles.option,
						dataType === 'production_summary' ? styles.selected : ''
					)}
				>
					Production Summary
				</div>
			</div>
			<div className={classes(styles['summary-data'])}>
				{dataType === 'hourly_production' && (
					<div
						className={classes(
							styles['hourly-table'],
							styles.table
						)}
					>
						<table>
							<thead>
								<th>Hourly Window</th>
								<th>No of Scans</th>
								<th>Under Weight</th>
								<th>Under Weight Quantity (kg)</th>
								<th>Over Weight</th>
								<th>Over Weight Quantity (kg)</th>
							</thead>
							<tbody>
								{hourlyProduction.map((hrProd) => {
									return (
										<tr>
											<td>{hrProd?.hourlyWindow}</td>
											<td>{hrProd?.noOfScans}</td>
											<td>{hrProd?.underWeight}</td>
											<td>
												{hrProd?.underWeightQuantity}
											</td>
											<td>{hrProd?.overWeight}</td>
											<td>
												{hrProd?.overWeightQuantity}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
				{dataType === 'production_summary' && (
					<div
						className={classes(
							styles['summary-table'],
							styles.table
						)}
					>
						<table>
							<thead>
								<th>SKU Name</th>
								<th>SKU Variant</th>
								<th>Vendor</th>
								<th>Stock In Scans (via UY)</th>
								<th>Under Weight</th>
								<th>Under Weight Quantity (kg)</th>
								<th>Over Weight</th>
								<th>Over Weight Quantity (kg)</th>
								<th>Total Weight (kg)</th>
								<th>Average Weight (gm)</th>
							</thead>
							<tbody>
								{productionSummaryData.map((summary) => {
									return (
										<tr>
											<td>
												<img className={styles['sku-img']} src={summary?.image ?? foodVector} alt='sku' />
												{summary?.skuName}
											</td>
											<td>{summary?.skuVariant}</td>
											<td>{summary?.vendor}</td>
											<td>{summary?.stockInScansViaUY}</td>
											<td>{summary?.underWeight}</td>
											<td>
												{summary?.underWeightQuantity}
											</td>
											<td>{summary?.overWeight}</td>
											<td>
												{summary?.overWeightQuantity}
											</td>
											<td>{summary?.totalWeight}</td>
											<td>{summary?.averageWeight}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
}

export default IMSSummary;
