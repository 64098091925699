import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Button } from '@mui/material';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import BackdropLoader from '../../Components/Loaders/BackdropLoader';
import { ListTable } from '../../Components/ListTable';
import { modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import { urls } from '../../Constants';
import { classes } from '../../Helpers';
import styles from './ListShift.module.scss';
import shiftServices from '../../Services/shift.service';
import ShiftTableModal from '../../Components/Modals/ShiftTableModal/ShiftTableModal';

const FIND_FILTERS = [
	'filteredStore',
	'processTypes',
	'dateOnly',
];

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Start Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'End Date',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const COL_MAP = {
	ID: 'id',
	Store: 'store.title',
	Name: 'shiftName',
	'Start Date': 'startDate',
	'End Date': 'endDate',
	Cancel: 'id',
};

const ListShiftManagment = ({ toggleModal, toggleAlert }) => {
	const [shiftData, setShiftData] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const history = useHistory();

	const fetchShifts = async () => {
		try {
			setLoading(true);
			const response = await shiftServices.listShift();
			if (response?.statusCode === 200) {
				setShiftData(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async (shiftId) => {
		try {
			setLoading(true);
			const response = await shiftServices.deleteShift({
				data: {
					id: shiftId,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: 'Shift deleted successfully',
				});
				const remShifts = shiftData.filter((shift) => {
					return shift.id !== shiftId;
				});

				setShiftData(remShifts);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const SPECIAL_ITEMS = {
		Actions: (shiftId) => {
			const shiftAction = shiftData.find((shift) => {
				return shift.id === shiftId;
			});
			return (
				<div className={styles.actions}>
					<Button
						onClick={() => {
							history.push(
								`${urls.EXTENSION}${urls.SHIFT_CREATE}?isEdit=True&id=${shiftId}`
							);
						}}
						variant='outlined'
						color='primary'
						fullWidth
					>
						Edit
					</Button>
					<div
						style={{
							width: '125px',
						}}
					>
						<Button
							variant='outlined'
							color='error'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Delete Shift?',
									handleConfirm: () => {
										handleDelete(shiftId);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Delete Shift ${shiftAction?.shiftName}?`,
								});
							}}
							fullWidth
						>
							Delete
						</Button>
					</div>
				</div>
			);
		},
	};

	useEffect(() => {
		fetchShifts();
	}, []);

	const handleFind = async (data) => {
		try {
			setLoading(true);
			const filter = {
				processTypes: data?.processTypes,
				store: data?.store,
			};
			if (data?.dateOnly?.active) {
				filter.dateOnly = moment(data?.dateOnly?.date).format('YYYY-MM-DD');
			}
			const response = await shiftServices.listShift({
				data: filter,
			});
			if (response?.statusCode === 200) {
				setShiftData(response?.data);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
		return data;
	};

	const handleAddClick = () => {
		history.push(`${urls.EXTENSION}${urls.SHIFT_CREATE}`);
	};

	const fetchShiftInfo = async (shiftId) => {
		try {
			setLoading(true);
			const response = await shiftServices.getShift({
				data: {
					id: shiftId,
				},
			});
			if (response?.statusCode === 200) {
				toggleModal(true, response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
		return {};
	};

	const handleViewClick = (shift) => {
		fetchShiftInfo(shift?.id);
	};

	return (
		<div className={styles.root}>
			<ShiftTableModal />
			<BackdropLoader isLoading={isLoading} showProgress />
			<div>
				<div
					className={styles.header}
				>
					<ImagesFinder
						pageLabel='Shift Managment'
						onSubmit={handleFind}
						FILTERS={FIND_FILTERS}
						dateOnlyText='Shifts for'
						additionInfo={{
							showAdd: true,
							addText: 'Shift',
							handleAddClick,
						}}
					/>
				</div>
				<ListTable
					columns={TABLE_COLUMNS}
					colMap={COL_MAP}
					actions={[...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
					data={shiftData?.map((ele) => {
						const status = ele?.status?.split(' ').join('_');
						return {
							...ele,
							rowStyles: classes(
								styles.row,
								styles?.[status]
							),
						};
					})}
				/>
			</div>

		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleShiftTableModal,
	toggleAlert: modalActions.toggleAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListShiftManagment);
