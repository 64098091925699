const ORIGIN =
	process.env.NODE_ENV === 'production'
		? window.location.origin
		: 'https://dev3.resoee.com';
const BASE_URL = `${ORIGIN}/server.storeDash/v2.1`;
const LOGIN_BASE_URL = `${ORIGIN}/server.kms`;
const LOGIN_BASE_URL_2 = `${ORIGIN}/server.storeDash`;
const PAYOUTS_BASE_URL = `${ORIGIN}/server.payouts`;
const WALLET_BASE_URL = `${ORIGIN}/server.wallet`;
const STORE2_BASE_URL = `${ORIGIN}/server.store2`;
const DASHBOARD_BASE_URL = `${ORIGIN}/server.dashboard`;
const CMS_BASE_URL = `${ORIGIN}/server.saasCMS`;
const ORDER_INFO_URL = `${ORIGIN}/server.orderinfo`;
const NODE_BASE_URL = `${ORIGIN}/server.node`;
// const DASHBOARD_BASE_URL = 'https://hyperpure.resoee.com/server.dashboard';
// const DASHBOARD_BASE_URL = `https://hyperpure.resoee.com/server.dashboard`;
// const AI_WORKFLOW_URL = 'http://localhost:8085';
const endpoints = {
	// APP
	// CHECK_TOKEN: `${MOCK_BASE_URL_NOV2}/checkToken`,
	// APP_CONFIG: `${MOCK_BASE_URL_NOV2}/v2.1/appConfig`,
	// LOGIN: `${MOCK_BASE_URL_NOV2}/login`,
	PROCESS_CONFIG: `${BASE_URL}/process/config`,
	CHECK_TOKEN: `${LOGIN_BASE_URL_2}/checkToken`,
	APP_CONFIG: `${LOGIN_BASE_URL_2}/v2.1/appConfig`,
	LOGIN: `${LOGIN_BASE_URL_2}/login`,

	STATIC_ENUMS: `${BASE_URL}/enums/list`,

	APP_MODULES: `${NODE_BASE_URL}/appModules`,

	REPORT_LIST: `${DASHBOARD_BASE_URL}/dashboard/reports`,
	REPORT_DATA_JAVA_URL: `${DASHBOARD_BASE_URL}/dashboard/report/java`,
	REPORT_DATA_PYTHON_URL: `${BASE_URL}/custom/report`,

	// dayendreport
	DAY_END_REPORT: `${BASE_URL}/dayend/analysis/list`,

	// USER
	USERS_LIST: `${NODE_BASE_URL}/users/list`,
	CREATE_USER: `${NODE_BASE_URL}/create/user`,
	CHANGE_PASSWORD: `${NODE_BASE_URL}/password/change`,
	STATE_CITY_MAPPING: `${BASE_URL}/statecitymapping`,
	GETGEODATA: `${BASE_URL}/citystoremapping`,
	REPORT_BUG: `${NODE_BASE_URL}/error/mail`,
	TRACKING_ORDERS_LIST: `${NODE_BASE_URL}/orderTracking/orders`,
	TRACKING_ORDER_QR_LIST: `${NODE_BASE_URL}/orderTracking/ordersQrCode`,
	TRACKING_PATH: `${NODE_BASE_URL}/orderTracking/Tracking`,
	TRACKING_POID: `${NODE_BASE_URL}/orderTracking/poIDTracking`,
	TRACKING_PRE_PRODUCTION: `${NODE_BASE_URL}/orderTracking/fmsTracking`,

	// DASHBOARD
	DASHBOARD: `${DASHBOARD_BASE_URL}/ims/dashboard/v2`,
	DASHBOARD_STATS: `${DASHBOARD_BASE_URL}/dashboard/v1/section`,
	SECTIONS_DASHBOARD: `${DASHBOARD_BASE_URL}/dashboard/v1/sections`,
	DASHBOARD_DATA_DOWNLOAD: `${DASHBOARD_BASE_URL}/ims/dashboard/download`,
	AI_ACCURACIES: `${DASHBOARD_BASE_URL}/ai-workflow/accuracies`,

	// ACCOUNT
	PROMO_CODES: `${BASE_URL}/promoCodes`,
	USERS: `${BASE_URL}/promoCodes/consumers`,
	BRANDING: `${BASE_URL}/branding`,
	FETCH_PAYMENT: `${PAYOUTS_BASE_URL}/fetchPaymentData`,
	FETCH_TRANSACTION_DATA: `${PAYOUTS_BASE_URL}/fetchTransactionData`,
	GET_INVOICE_URL: `${PAYOUTS_BASE_URL}/getInvoice?`,
	FETCH_BALANCE: `${WALLET_BASE_URL}/credits/balance/user`,
	ADD_CREDITS: `${WALLET_BASE_URL}/credits/balance/credit/direct`,
	WITHDRAW_CREDITS: `${WALLET_BASE_URL}/credits/balance/debit/direct`,
	FETCH_TRANSACTION_HISTORY: `${WALLET_BASE_URL}/credits/transactions/user`,
	ADD_CHILD: `${LOGIN_BASE_URL_2}/v2.1/child/add`,
	CHILD_LIST: `${ORDER_INFO_URL}/customer/address`,
	EDIT_CHILD: `${ORDER_INFO_URL}/customer/address/`,

	// Machines
	LIST_MACHINES: `${NODE_BASE_URL}/machines/list`,
	UPDATE_MACHINES: `${NODE_BASE_URL}/version/update`,
	ADD_MACHINE: `${NODE_BASE_URL}/machine/add`,

	// REPORTS
	FETCH_REPORTS: `${NODE_BASE_URL}/report/list`,
	UPDATE_REPORT: `${NODE_BASE_URL}/report/update`,

	// FINANCIAL TRANSACTIONS
	PETTY_CASH_LIST: `${NODE_BASE_URL}/petty/list`,
	FETCH_BILLS_SUMMARY: `${BASE_URL}/store/inventory/transaction/summary`,
	// UPDATE_REPORT: `${NODE_BASE_URL}/report/update`,

	// LICENSES
	LICENSES_LIST: `${NODE_BASE_URL}/license/list`,
	LICENSE_MAPPABLE_DATA: `${NODE_BASE_URL}/license/mappable`,
	LICENSES_EDIT: `${NODE_BASE_URL}/license/edit`,
	LICENSES_REMOVE: `${NODE_BASE_URL}/license/remove`,
	LICENSES_ADD: `${NODE_BASE_URL}/license/save`,

	// CONTAINERS
	CONTAINER_LIST: `${NODE_BASE_URL}/container/list`,
	CONTAINER_EDIT: `${NODE_BASE_URL}/container/edit`,
	CONTAINER_REMOVE: `${NODE_BASE_URL}/container/remove`,
	CONTAINER_ADD: `${NODE_BASE_URL}/container/save`,

	// AUDITS
	AUDITS_LIST: `${BASE_URL}/audit/list`,
	AUDITS_CREATE: `${BASE_URL}/audit/bulk/start`,
	AUDITS_CLOSE: `${BASE_URL}/audit/store/complete`,
	AUDIT_CANCEL: `${BASE_URL}/audit/status/change`,
	AUDIT_ITEMS: `${BASE_URL}/audit/review`,
	AUDIT_ITEMS_SCHEDULED: `${BASE_URL}/audit/review/products`,
	AUDIT_REPORT_ADJUST: `${BASE_URL}/audit/report/adjust`,
	AUDIT_NON_QR_ITEMS: `${BASE_URL}/audit/report/fetch`,
	AUDIT_NON_QR_ITEMS_UPLOAD: `${BASE_URL}/audit/report/upload`,

	// PROCESS FLOW
	PROCESS_FLOW_SAVE: `${NODE_BASE_URL}/flow/node/add`,
	PROCESS_FLOW_ADD: `${NODE_BASE_URL}/flow/node/upload`,
	PROCESS_FLOW_ANALYTICS: `${DASHBOARD_BASE_URL}/process`,
	PROCESS_FLOW_NODES: `${NODE_BASE_URL}/flow/nodes`,
	PROCESS_FLOW_AVAILABLE_NODES: `${NODE_BASE_URL}/flow/nodes/list`,
	PROCESS_FLOW_AVAILABLE_SECTIONS: `${NODE_BASE_URL}/flow/sections/list`,
	PROCESS_FLOW_ADD_SECTION: `${NODE_BASE_URL}/flow/section/add`,

	PRODUCT_PROCESS_MAP: `${DASHBOARD_BASE_URL}/product/mapping`,
	PRODUCT_PROCESS_MAP_DELETE: `${DASHBOARD_BASE_URL}/product/mapping/delete`,
	PRODUCT_PROCESS_LIST: `${DASHBOARD_BASE_URL}/product/mapping/list`,
	MAPPING_TYPE_VALUES: `${DASHBOARD_BASE_URL}/product/mapping/values`,
	AVAILABLE_MAPPING_TYPES: `${DASHBOARD_BASE_URL}/product/mapping/mappingType`,
	PRODUCTWISE_MAPPING: `${DASHBOARD_BASE_URL}/product/mapping/productwise`,
	PROCESS_FLOW_EDGE_DELETE: `${NODE_BASE_URL}/flow/nodes/edge/delete`,
	PROCESS_FLOW_NODE_DELETE: `${NODE_BASE_URL}/flow/node/delete`,
	PROCESS_FLOW_NODE_EDIT: `${NODE_BASE_URL}/flow/node/edit`,
	// ANALYTICS
	ANALYTICS: `${BASE_URL}/analytics/store`,
	MY_CUSTOMERS: `${BASE_URL}/customers`,
	MY_CUSTOMERS_CUSTOMER_DATA: `${BASE_URL}/customers`,
	INVENTORY_STOCK_IN_LIST: `${BASE_URL}/analytics/inventory/list`,
	INVENTORY_STOCK_OUT_LIST: `${BASE_URL}/analytics/inventory/list`,
	SKU_STOCK: `${BASE_URL}/analytics/inventoryReport`,
	SKU_TRAIL_SCAN_WISE: `${DASHBOARD_BASE_URL}/stock/trail/scanwise`,
	SKU_TRAIL_DAY_WISE: `${DASHBOARD_BASE_URL}/stock/trail/datewise/v1`,
	QUALITY_ANALYTICS: `${BASE_URL}/analytics/quality`,
	COOKING_ANALYTICS_LIST: `${BASE_URL}/cooking/list`,
	COOKING_YIELD_QCS: `${BASE_URL}/cooking/yield/qc`,
	ORDER_ANALYTICS_LIST: `${ORDER_INFO_URL}/fetch/orders`,
	CANCEL_ORDER: `${STORE2_BASE_URL}/v2/order/cancel`,
	ORDER_ITEMS: `${ORDER_INFO_URL}/fetch/order/detail`,

	EXPORT_GRN_WISE_ORDER_HISTORY: `${BASE_URL}/export/po/grn/items`,

	INVENTORY_ANALYTICS: `${BASE_URL}/analytics/inventory`,
	INVENTORY_PO_UPLOAD: `${BASE_URL}/inventory/upload/po`,
	FETCH_UPLOAD_TYPES: `${BASE_URL}/inventory/upload/types`,
	FETCH_SALES_ORDER_STORES: `${BASE_URL}/inventory/upload/so/stores`,
	FETCH_INVENTORY_TRANSACTION_RECORD: `${BASE_URL}/store/inventory/transaction/list`,
	FETCH_CATEGORY_LIST: `${BASE_URL}/list/categories`,
	FETCH_INV_TRANS_DOC: `${BASE_URL}/store/inventory/transaction/doc/base64`,
	FETCH_PO_PDF_DOC: `${BASE_URL}/store/inventory/po/doc/base64`,
	GET_TRANSACTION_RECORD: `${BASE_URL}/store/inventory/transaction`,
	CREATE_INVENTORY_TRANSACTION_RECORD: `${BASE_URL}/store/inventory/transaction/create`,
	UPDATE_INVENTORY_TRANSACTION_RECORD: `${BASE_URL}/store/inventory/transaction/update`,
	EDIT_INVENTORY_TRANSACTION_RECORD: `${BASE_URL}/store/inventory/transaction/edit`,
	VENDOR_DETAILS_INVOICE_UPLOAD: `${BASE_URL}/open/vendor/invoice`,
	UPLOAD_VENDOR_INVOICE: `${BASE_URL}/open/vendor/upload/invoice`,
	CREATE_CREDIT_NOTE: `${BASE_URL}/store/credit/note/create`,
	APPROVE_INVOICE_AND_BILL: `${BASE_URL}/store/invoice/update`,
	PO_LIST: `${BASE_URL}/inventory/po/list`,
	GET_PO: `${BASE_URL}/inventory/po`,
	TEMP_PO_ID: `${NODE_BASE_URL}/getPoId`,
	SINGLE_PURCHASE_ORDER: `${BASE_URL}/inventory/po`,
	ADD_VENDOR_PO: `${BASE_URL}/store/placeOrder`,
	EDIT_PO: `${BASE_URL}/store/editOrder`,
	EWAY_BILL_UPDATE: `${BASE_URL}/store/order/ewaybill/set`,
	CANCEL_EWAY_BILL: `${BASE_URL}/store/order/cancel/ewayBill`,
	GENERATE_EINVOICE: `${BASE_URL}/store/order/generate/eInvoice`,
	CANCEL_E_INVOICE: `${BASE_URL}/store/order/cancel/eInvoice`,
	PO_COMBINE: `${BASE_URL}/inventory/po/combine`,
	INITIATE_PRODUCTION_PLAN: `${BASE_URL}/production/plan/initiate`,
	GENERATE_PRODUCTION_PLAN: `${BASE_URL}/production/plan/generate`,
	FETCH_PRODUCTION_PLANS: `${BASE_URL}/production/plan/list`,
	FETCH_PRODUCTION_PLAN_DETAILS: `${BASE_URL}/production/plan/info`,
	FETCH_FORECASTING_LIST: `${ORIGIN}/server.forecast/v2.1/order/forecast/list`,

	INDENT_LIST: `${BASE_URL}/inventory/indent/list`,
	// GENERATE_STANDARD_PO
	GENERATE_STANDARD_PO: `${DASHBOARD_BASE_URL}/po/recommended`,
	FETCH_PO_GRNS: `${BASE_URL}/inventory/po/grn/list`,

	PO_CHANGE_STATUS: `${BASE_URL}/inventory/po/change/status`,
	GENERATE_GRN: `${BASE_URL}/inventory/po/grn/generate`,
	INDENT_CHANGE_STATUS: `${BASE_URL}/inventory/indent/change/status`,
	WORK_ORDER_LIST: `${BASE_URL}/cms/orders`,
	QUALITY_ANALYTICS_LIST: `${LOGIN_BASE_URL_2}/olddata`,
	// ====================
	// ========= COGS ANALYTICS ===========
	COGS_ANALYTICS: `${BASE_URL}/analytics/cost/product`,
	COGS_ANALYTICS_LIST: `${BASE_URL}/analytics/cost/product/list`,
	// ====================
	// ========= INV ANALYTICS ===========
	INV_ANALYTICS: `${BASE_URL}/inventory/analysis`,
	INV_ANALYTICS_LIST: `${BASE_URL}/inventory/analysis/list`,
	INV_ANALYTICS_EXPORT: `${BASE_URL}/inventory/analysis/export`,

	// ========= INV ANALYTICS ===========
	FORECASTING_ANALYTICS: `${ORIGIN}/server.forecast/v2.1/order/forecast/analysis`,
	FORECASTING_ANALYTICS_LIST: `${ORIGIN}/server.forecast/v2.1/order/forecast/analysis/list`,
	FORECASTING_ANALYTICS_EXPORT: `${ORIGIN}/server.forecast/v2.1/order/forecast/analysis/export`,

	// ====================
	// ====================
	// ========= AUDIT ANALYTICS ===========
	AUDIT_ANALYTICS: `${BASE_URL}/audit/analysis`,
	AUDIT_ANALYTICS_LIST: `${BASE_URL}/audit/analysis/list`,
	AUDIT_ANALYTICS_EXPORT: `${BASE_URL}/audit/analysis/export`,

	// ========= TRANSIT ANALYTICS ===========
	TRANSIT_ANALYTICS: `${BASE_URL}/inventory/transit/analysis`,
	TRANSIT_ANALYTICS_LIST: `${BASE_URL}/inventory/transit/analysis/list`,
	TRANSIT_ANALYTICS_EXPORT: `${BASE_URL}/inventory/transit/analysis/export`,

	// QUALITY_ANALYTICS_LIST_TEST: `${MOCK_BASE_URL_NOV2}/olddata`,
	WASTAGE_ANALYTICS_LIST: `${LOGIN_BASE_URL_2}/olddata`,
	WASTAGE_REASONS_LIST: `${BASE_URL}/wastage/reasons/list`,
	ADD_WASTAGE_REASON: `${BASE_URL}/wastage/reasons/add`,
	DELETE_WASTAGE_REASON: `${BASE_URL}/wastage/reasons/delete`,
	// QUALITY_ANALYTICS_LIST: 'http://localhost:7001/olddata',
	STATISTICAL_ANALYSIS: `${BASE_URL}/analytics/quality/statistical`,
	BRAND_ANALYSIS_LIST: `${BASE_URL}/analytics/brands`,
	BRAND_TOP_PRODUCTS: `${BASE_URL}/analytics/brand/products`,
	BRAND_TOP_DEFECTS: `${BASE_URL}/analytics/brand/defects`,
	MY_SUBSCRIPTIONS_LIST: `${NODE_BASE_URL}/subscriptions/list`,
	MY_SUBSCRIPTIONS_ITEMS: `${NODE_BASE_URL}/subscriptions/items`,
	MY_SUBSCRIPTIONS_PREV_ORDERS: `${NODE_BASE_URL}/subscriptions/previous-orders`,
	CANCEL_SUBSCRIPTION: `${NODE_BASE_URL}/subscriptions/cancel`,

	ASSETS_LIST: `${DASHBOARD_BASE_URL}/assetType`,
	ASSETS_TEMP_EDIT: `${BASE_URL}/asset/temp/edit`,
	ASSETS_ADD: `${DASHBOARD_BASE_URL}/assetType/addEdit`,
	ASSETS_REMOVE: `${DASHBOARD_BASE_URL}/assetType/remove`,
	STORE_ASSET_LIST: `${BASE_URL}/asset/list`,
	ASSET_IMMOVABLE: `${DASHBOARD_BASE_URL}/assets`,
	ASSETS_LIST_VARIENT: `${BASE_URL}/product/variants/distinct`,
	ASSETS_DASHBOARD_DATA: `${DASHBOARD_BASE_URL}/asset/hirearchy`,
	ASSETS_DASHBOARD_CHARTS_DATA: `${DASHBOARD_BASE_URL}/inventory/stats/entity`,
	ASSETS_FRIDGE_MONITORING_URL: `${BASE_URL}/asset/temp/history`,
	ASSETS_OEM_ADD: `${DASHBOARD_BASE_URL}/oem/addEdit `,
	ASSETS_OEM_List: `${DASHBOARD_BASE_URL}/oem `,
	ADD_ASSET_CATEGORY_MODAL: `${DASHBOARD_BASE_URL}/assetType/category/addEdit`,
	ASSETS_OEM_DELETE: `${DASHBOARD_BASE_URL}/oem/remove`,
	ASSETS_CATEGORY: `${DASHBOARD_BASE_URL}/assetType/category`,
	ASSETS_CATEGORY_REMOVE: `${DASHBOARD_BASE_URL}/assetType/category/remove`,

	ASSET_CAPACITY: `${BASE_URL}/asset/capacity`,
	CREATE_ASSET_CAPACITY: `${BASE_URL}/asset/capacity/create`,

	AI_DASHBOARD: `${DASHBOARD_BASE_URL}/ims/ai/dashboard`,

	AI_STATS_DATA: `${BASE_URL}/Ai/analysis/lists`,

	// FRANCHISEE
	MY_FRANCHISEE: `${BASE_URL}/franchisee`,
	// STORES
	STORES: `${BASE_URL}/store`,
	RIDERS: `${BASE_URL}/rider`,
	PRINTER_CONFIG: `${BASE_URL}/printerManagement`,
	EXPORT_STORES: `${DASHBOARD_BASE_URL}/store/export`,

	// REVENUE
	REVENUES: `${BASE_URL}/store/revenue/latest`,
	ADD_REVENUE: `${BASE_URL}/store/revenue/record`,

	// BRANDS
	GET_BRANDS: `${BASE_URL}/brandManagement`,
	BRAND: `${BASE_URL}/brand`,

	// AI MODEL
	AI_CREATION: `${LOGIN_BASE_URL_2}/train/ai`,
	AI_HISTORY: `${LOGIN_BASE_URL_2}/history/ai`,

	// VARIANTS THRESHOLD
	VARIANTS_THRESHOLD_LIST: `${NODE_BASE_URL}/threshold/list`,
	VARIANTS_THRESHOLD_EDIT: `${BASE_URL}/productVariants/threshold/update`,
	VARIANTS_THRESHOLD_IMPORT: `${BASE_URL}/upload/variant/threshold`,

	// PRODUCTS
	ALL_PRODUCTS: `${BASE_URL}/product`,
	GET_PRODUCTS: `${BASE_URL}/combo/products`,
	CUSTOMIZATIONS: `${BASE_URL}/customization`,
	SAVE_CUSTOMIZATION: `${BASE_URL}/saveCustomization`,
	PRODUCT_VARIANTS: `${BASE_URL}/productVariant`,
	GET_CATEGORIES: `${BASE_URL}/productCategoryManagement`,
	PRODUCT_CATEGORY: `${BASE_URL}/productCategory`,

	PRODUCT_SUB_CATEGORY: `${BASE_URL}/subcategory`,
	// CREATE_PRODUCT_SUB_CATEGORY: `${BASE_URL}/subcategory`,

	CREATE_COMBO: `${BASE_URL}/combo/save`,
	UPDATE_COMBO: `${BASE_URL}/combo/`,
	VIEW_COMBO: `${BASE_URL}/combo/`,
	LIST_COMBOS: `${BASE_URL}/combo/`,
	ENABLE_COMBO: `${BASE_URL}/combo/enable/`,

	NUTRITIONAL_INFO: `${BASE_URL}/productVariant`,
	UPDATE_NUTRITIONAL_INFO: `${BASE_URL}/product/nutrition`,
	SUBSCRIPTION: `${BASE_URL}/subscription`,
	UPDATE_SUBSCRIPTION: `${BASE_URL}/product/subscription`,

	// MENUS
	MENU: `${BASE_URL}/menu`,

	// QUALITY
	QUALITY_SETTING: `${BASE_URL}/settings/quality`,
	PRODUCT_QUALITY_CONFIG: `${BASE_URL}/quality/config`,

	// COOKING
	COOKING_STATIONS: `${BASE_URL}/cookingStation`,

	LIST_RECIPES: `${BASE_URL}/recipes`,
	VIEW_RECIPE: `${BASE_URL}/recipe`,
	SAVE_RECIPE: `${BASE_URL}/saveRecipe`,
	UPDATE_RECIPE_FLAGS: `${BASE_URL}/recipe/updateFlag`,
	EXPORT_RECIPES: `${DASHBOARD_BASE_URL}/recipe/calculate`,

	COOKING_RECIPE: `${BASE_URL}/cookingrecipe`,
	COOKING_PRODUCT_CONFIG: `${BASE_URL}/cooking-config`,
	COOKING_PRODUCT_CONFIG_UPDATE: `${BASE_URL}/product/cooking`,

	// INVENTORY
	INVENTORY_SETTING: `${BASE_URL}/settings/inventory`,
	LIST_SKUS: `${BASE_URL}/skus`,
	INVENTORY_SKUS: `${BASE_URL}/inventorySku`,
	INVENTORY_SKU: `${BASE_URL}/inventorySku`,
	INVENTORY_CATEGORY: `${BASE_URL}/inventoryCategory`,
	INVENTORY_CONFIG: `${BASE_URL}/inventory/config`,
	LIVE_IMS: `${BASE_URL}/ims/showLiveStatus`,
	PAST_IMS: `${BASE_URL}/ims/showPastStatus`,

	// DEFECTS
	LIST_DEFECTS: `${BASE_URL}/defects`,
	FIND_DEFECT_IMAGES: `${BASE_URL}/defect/analysis/apply/list`,
	FIND_DEFECT_IMAGES_BY_ID: `${BASE_URL}/defect/analysis/defect/list`,

	// IDENTIFICATION
	FIND_PRODUCT_IMAGES: `${BASE_URL}/identification/analysis/apply/list`,
	FIND_PRODUCT_IMAGES_BY_ID: `${BASE_URL}/identification/analysis/product/list`,

	// OUTWARDING
	FIND_OUTWARDING_IMAGES: `${BASE_URL}/outward/store/images`,
	FIND_OUTWARDING_IMAGES_BY_ID: `${BASE_URL}/identification/analysis/product/list`,
	// FIND_PRODUCT_IMAGES_BY_ID: `${BASE_URL}/identification/analysis/product/list`,

	// WASTAGE
	FIND_WASTAGE_IMAGES: `${BASE_URL}/identification/analysis/apply/list`,
	FIND_WASTAGE_IMAGES_BY_ID: `${BASE_URL}/identification/analysis/product/list`,

	// CONTAINER
	FIND_CONTAINER_IMAGES: `${BASE_URL}/container/analysis/apply/list`,
	FIND_CONTAINER_IMAGES_BY_ID: `${BASE_URL}/container/analysis/container/list`,

	// TEMPERATURE
	FIND_TEMPERATURE_IMAGES: `${BASE_URL}/temp/analysis/apply/list`,
	FIND_TEMPERATURE_IMAGES_BY_ID: `${BASE_URL}/temp/analysis/temp/list`,
	FIND_DIGIT_TEMPERATURE_IMAGES: `${BASE_URL}/digits/analysis/apply/list`,
	FIND_DIGIT_TEMPERATURE_IMAGES_BY_ID: `${BASE_URL}/digits/analysis/digits/list`,

	// COOKING
	FIND_COOKING_IMAGES: `${BASE_URL}/identification/analysis/apply/list`,
	// FIND_PRODUCT_IMAGES_BY_ID: `${BASE_URL}/identification/analysis/product/list`,

	// REFUNDS
	REQUEST_REFUND: `${BASE_URL}/refund`,
	APPROVE_REFUNDS: `${BASE_URL}/refund/client`,
	REFUND_HISTORY: `${BASE_URL}/refund/client?history=true`,

	SEND_NOTIFICATION: `${BASE_URL}/sendNotification`,
	showLiveStatus: `${LOGIN_BASE_URL_2}/showLiveStatus`,
	GET_PAST_QC_DETAILS: `${BASE_URL}/getPastQC`,
	QC_STORE: `${LOGIN_BASE_URL}/stores`,

	GET_WASTAGE_REASONS: `${BASE_URL}/qc/wastage/reason`,

	// ANNOTATIONS
	QC_ANNOTATIONS: `${BASE_URL}/annotation/qc`,
	UPDATE_QC: `${BASE_URL}/annotation/qc/update`,
	BULK_VALIDATION: `${BASE_URL}/annotation/qc/validate/bulk`,
	IMS_ANNOTATIONS: `${BASE_URL}/annotation/ims`,
	SUBMIT_TEMP_ANNOTATIONS: `${BASE_URL}/asset/temp/annotate`,
	TEMP_ANNOTATIONS: `${BASE_URL}/asset/temp/annotate/list`,
	FETCH_PRODUCTS: `${BASE_URL}/fetchProducts`,
	FETCH_CONTAINERS: `${BASE_URL}/container/mode/list`,
	ANNOTATION_LABELS: `${BASE_URL}/annotation/labels`,
	// QC_ANNOTATIONS: '${BASE_URL}/QCAnnotations',
	// FETCH_PRODUCTS: '${BASE_URL}/fetchProducts',

	// VENDORS
	VENDOR_TYPES: `${BASE_URL}/vendorTypes`,
	ALL_VENDORS: `${BASE_URL}/vendors`,
	UPDATE_VENDOR: `${BASE_URL}/updateVendor`,
	ADD_VENDOR: `${BASE_URL}/createVendor`,
	DELETE_VENDOR: `${BASE_URL}/deleteVendor`,
	ADD_VENDOR_PRODUCT_MAP: `${NODE_BASE_URL}/vendorProducts/add`,
	VENDOR_PRODUCT_MAP_LIST: `${NODE_BASE_URL}/vendorProducts`,
	MAPPED_VENDORS_TO_PRODUCT: `${NODE_BASE_URL}/vendorProducts/product/vendors`,
	CREATE_VENDOR_CONTRACT: `${BASE_URL}/vendor/contract/create`,
	VENDOR_CONTRACT_PRODUCTS_PRICING: `${BASE_URL}/vendor/contract/products/pricing`,
	EDIT_VENDOR_CONTRACT: `${BASE_URL}/vendor/contract/edit`,
	LIST_VENDOR_CONTRACT: `${BASE_URL}/vendor/contract/list`,
	VENDOR_CONTRACT_INFO: `${BASE_URL}/vendor/contract/info`,
	VENDOR_CONTRACT_DELETE: `${BASE_URL}/vendor/contract/delete`,
	VENDOR_CONTRACT_PRODUCTS_FILTER: `${BASE_URL}/vendor/contract/filter`,
	VENDOR_OPENINGS: `${BASE_URL}/vendor/opening`,
	EXPORT_VENDORS: `${DASHBOARD_BASE_URL}/vendor/export`,
	EXPORT_BUYERS: `${DASHBOARD_BASE_URL}/buyer/export`,

	// Buyers
	ALL_BUYERS: `${BASE_URL}/buyers`,
	ADD_BUYERS: `${BASE_URL}/createBuyer`,
	LIST_BUYER_CONTRACT: `${BASE_URL}/buyer/contract/list`,
	BUYER_CONTRACT_PRODUCTS_FILTER: `${BASE_URL}/buyer/contract/filter`,
	BUYER_CONTRACT_CREATE: `${BASE_URL}/buyer/contract/create`,
	EDIT_BUYER_CONTRACT: `${BASE_URL}/buyer/contract/edit`,
	BUYER_CONTRACT_DELETE: `${BASE_URL}/buyer/contract/delete`,

	// QOUTE

	REQUEST_QOUTE_ASSET: `${DASHBOARD_BASE_URL}/assetType `,
	REQUEST_QOUTE_ADD: `${DASHBOARD_BASE_URL}/asset/rfq/addEdit`,
	REQUEST_QOUTE_FETCH: `${DASHBOARD_BASE_URL}/asset/rfq`,
	VENDOR_QUOTE_SAVE: `${DASHBOARD_BASE_URL}/asset/rfq/reply/addEdit`,
	VENDOR_QUOTE_FETCH: `${DASHBOARD_BASE_URL}/asset/rfq/reply`,
	VENDOR_QUOTE_FETCH_BY_ID: `${DASHBOARD_BASE_URL}/asset/rfq`,
	// RECIPE
	GET_RECIPE: `${CMS_BASE_URL}/orderRecipe`,

	// ORDER HISTORY
	ORDER_HISTORY_CSV: `${NODE_BASE_URL}/order/history/csv`,

	// CAPEX SERVICE
	CAPEX_SERVICE: `${BASE_URL}/capexservice/list`,
	CAPEX_SERVICE_CREATE: `${BASE_URL}/capexservice/create`,
	CAPEX_SERVICE_UPDATE: `${BASE_URL}/capexservice/update`,
	CAPEX_SERVICE_DELETE: `${BASE_URL}/capexservice/delete`,

	// LEFT OVER INVENTORY
	ADD_LEFT_OVER: `${BASE_URL}/add/leftoverinventory`,
	LIST_LEFT_OVER: `${BASE_URL}/get/leftoverinventory`,
	LIST_LEFT_OVER_ITEM: `${BASE_URL}/get/leftoverinventory/items`,
	DELETE_LEFT_OVER_ITEM: `${BASE_URL}/delete/leftoverinventory`,
	LEFT_OVER_ITEM_EXPORT: `${DASHBOARD_BASE_URL}/leftover/export`,

	// Wastage Inventory
	LIST_WASTAGE: `${BASE_URL}/get/wastageinventory`,
	LIST_WASTAGE_ITEM: `${BASE_URL}/get/wastageinventory/items`,

	// GROUP
	SIMILAR_PRODUCT_GET_GROUPS: `${BASE_URL}/similar/groups`,
	SIMILAR_PRODUCT_GET_ALL_PRODUCTS: `${BASE_URL}/similar/products`,
	SIMILAR_PRODUCT_GET_GROUP_PRODUCTS: `${BASE_URL}/similar/group/products`,
	SIMILAR_PRODUCT_GET_GRADE_GROUP: `${BASE_URL}/similar/product/group`,
	SIMILAR_PRODUCT_CREATE_GROUP: `${BASE_URL}/similar/group/create`,
	SIMILAR_PRODUCT_UPDATE_GROUP: `${BASE_URL}/similar/group/product/add`,

	// REGION-STATE MAPPING
	CREATE_REGION: `${BASE_URL}/region/create`,
	LIST_REGION: `${BASE_URL}/region/list`,
	CLIENT_UNMAPPED_STATE: `${BASE_URL}/client/unmapped/state/list`,
	UPDATE_REGION_STATE_MAPPING: `${BASE_URL}/region/state/update`,
	LIST_ALL_REGION_MAPPING: `${BASE_URL}/all/region/mapping/list`,

	// USER ROLES AND PERMISSIONS
	CREATE_USER_ROLE: `${BASE_URL}/userRoles/add`,
	CHANGE_ACTIVE_STATUS: `${BASE_URL}/user/status`,
	LIST_USER_ROLES: `${BASE_URL}/userRoles/list`,
	LIST_USER_LEVELS: `${BASE_URL}/userLevels/list`,
	CREATE_USER_PER: `${BASE_URL}/user/create`,
	LIST_USERS: `${BASE_URL}/users/list`,
	GET_USER: `${BASE_URL}/get/user`,
	USER_BY_ROLE: `${BASE_URL}/get/users`,
	USER_LOGIN_PERM: `${BASE_URL}/login`,
	GET_USER_STORES: `${BASE_URL}/get/user/stores`,
	GET_USER_ROLES_AND_PERMISSIONS: `${BASE_URL}/get/user/roles/permissions`,

	// Label Generator
	LABEL_GENERATOR_GET_LABELS: `${BASE_URL}/labels`,
	LABEL_GENERATOR_DELETE_LABEL: `${BASE_URL}/deleteLabel`,
	LABEL_GENERATOR_CREATE_LABEL: `${BASE_URL}/createLabel`,

	GET_MODULES_CONFIGURATION: `${BASE_URL}/modules/configuration/get`,
	UPDATE_MODULES_CONFIGURATION: `${BASE_URL}/modules/configuration/update`,

	FETCH_WO_PDF_ZIP: `${BASE_URL}/wo/pdfs`,

	// Shift managment
	SHFIT_CREATE: `${BASE_URL}/shift/create`,
	SHIFT_GET: `${BASE_URL}/shift`,
	SHIFT_GET_PROCESS: `${BASE_URL}/shift/process`,
	SHIFT_LIST: `${BASE_URL}/shift/list`,
	SHIFT_EDIT: `${BASE_URL}/shift/edit`,
	SHIFT_DELETE: `${BASE_URL}/shift/delete`,

	// VENDOR QUOTE
	VENDOR_QUOTE_REPLY: `${DASHBOARD_BASE_URL}/asset/rfq/reply/order/add`,

	// gstUrl
	GST_CREATE: `${BASE_URL}/gstDetails/create`,
	GST_GETDETAILS: `${BASE_URL}/gstDetails`,
	// user Access Export
	USER_ACCESS_EXPORT: `${DASHBOARD_BASE_URL}/user/access/export`,
	// asset transit flow
	ASSET_TRANSIT_FLOW: `${DASHBOARD_BASE_URL}/po`,
};

export default endpoints;
