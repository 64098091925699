/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line object-curly-newline
import { authHeader, toPathStringURL, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

function getReportsList() {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({}),
	};
	return fetch(endpoints.REPORT_LIST, requestOptions).then(handleResponse);
}

function getReportData(req) {
	const { params = {} } = req;
	const { urlPath = [] } = req;
	const { endpoint = endpoints.REPORT_DATA_JAVA_URL } = req;
	let url = toPathStringURL(endpoint, urlPath);
	url = toQueryStringURL(url, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default {
	getReportData,
	getReportsList,
};
