/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
// import { get } from 'lodash';
// import { useEffect } from 'react';
import { Visibility } from '@mui/icons-material';
import styles from './OrderHistoryListPage.module.scss';
import analyticsAction from '../../../Actions/analytics.actions';
import { classes } from '../../../Helpers';
import { ListTable } from '../../../Components/ListTable';
// import { urls } from '../../../Constants';
import { ArrangePages } from '../../../Components/Arrange Pages';
import orderAnalyticsServices from '../../../Services/Analytics Services/orderAnalytics.services';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import locateIcon from '../../../Images/locate.svg';
import { urls } from '../../../Constants';

const COLMAP = {
	'Order Id': 'orderId',
	'Partner Order Id': 'partnerOrderId',
	'Order Time': 'orderTime',
	Store: 'storeName',
	'Order Status': 'orderStatus',
	'Payment Amount': 'paymentAmount',
	'Order Scheduled': 'scheduledAt',
	'Order Type': 'mealType',
	'Payment Mode': 'paymentType',
	'Payment Status': 'paymentStatus',
	'Mobile Number': 'mobile',
	Address: 'orderId',
	Traceability: 'traceability',
	'Total Items': 'itemCount',
	'View Items': 'orderId',
	'Trace Order': 'orderId',
};

const TABLE_COLUMNS = [
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Partner Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Time',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Scheduled',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Mode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mobile Number',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Address',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Items',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'View Items',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Traceability',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Trace Order',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [];

const getRowStyles = (item) => {
	switch (item?.batchStatus) {
		case 'CLOSED':
			return styles.normal;
		case 'COOKING':
			return styles.moderate;
		default:
			return styles.white;
	}
};

const OrderHistoryList = (props) => {
	// const history = useHistory();
	const {
		page,
		limit,
		nextPage,
		prevPage,
		setPage,
		data,
		storeId,
		setAnalyticsType,
		ANALYTICS_TYPE,
		timeType,
		orderStatus,
		listCount,
		SEARCH_BY,
		searchField,
		query,
		fetchData,
		queryTracker,
		toggleOrderItemsModal,
		handleOrderHistoryCsv,
		reqOptions,
	} = props;

	const handleCancel = async (orderData) => {
		try {
			const response = await orderAnalyticsServices.cancelOrder({
				orderData,
			});
			if (response?.data?.status === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.data?.message,
				});
				fetchData(reqOptions);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.data?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
			// console.log(error);
		}
	};

	const openModal = async (id) => {
		try {
			const response = await orderAnalyticsServices.fetchOrderItems({
				data: {
					orderId: id,
				},
			});
			toggleOrderItemsModal(true, {
				items: response?.data?.items,
				orderId: id,
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const SPECIAL_ITEMS = {
		'View Items': (orderId) => {
			return (
				<div className={styles['order-item-view']} onClick={() => { return openModal(orderId); }}>
					<Visibility className={styles.icon} />
				</div>
			);
		},
		Address: (orderId) => {
			const item = data?.find((ele) => {
				return ele.orderId === orderId;
			});
			return `${item?.addressLine1 ?? ''} ${item?.addressLine2 ?? ''} ${item?.city ?? ''} ${item?.state ?? ''}`;
		},
		'Trace Order': (orderId) => {
			const item = data?.find((ele) => {
				return ele.orderId === orderId;
			});
			if (item?.traceability <= 0) {
				return '-';
			}
			return (
				<div
					onClick={() => {
						window.open(
							// eslint-disable-next-line prefer-template
							urls.EXTENSION +
								urls.ORDER_TRACKNG +
								'?type=orderId&value=' +
								item?.orderId,
							'_blank'
						);
					}}
					className={styles.locate}
				>
					<img src={locateIcon} alt='locate' />
				</div>
			);
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ListTable
				hasDateRange
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				fixedHeaderTop={0}
				data={
					data &&
					data.map((obj, idx) => {
						const rowStyles = getRowStyles(obj);
						// const itemId = get(obj, COLMAP['Item Id']);
						// const qc = get(obj, COLMAP.qc);
						return {
							...obj,
							id: idx + 1,
							rowStyles,
							// onClick: () => {
							// history.push(
							// `${urls.EXTENSION}${urls.PAST_QC}/${itemId}?id=${qc}`
							// );
							// },
						};
					})
				}
				columns={[...TABLE_COLUMNS]}
				actions={[...ACTION_COLUMNS]}
				handleDelete={handleCancel}
				onExportClick={handleOrderHistoryCsv}
			/>
			<ArrangePages
				listCount={listCount}
				page={page}
				limit={limit}
				nextPage={nextPage}
				prevPage={prevPage}
				setPage={setPage}
				fetchData={fetchData}
				reqOptions={reqOptions}
				setAnalyticsType={setAnalyticsType}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				query={query}
				queryTracker={queryTracker}
			/>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	toggleOrderItemsModal: modalActions.toggleOrderItemsModal,
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryList);
