import { Tooltip } from '@mui/material';

/* eslint-disable max-len */
function Tool(props) {
	function handleDrag(Event) {
		const toolInUse = Event.target.id;
		props.toolDragged(toolInUse);
	}
	function chooseNameAndColor(name) {
		switch (name) {
			case 'Text':
				return {
					bgcolor: '#CCFF99',
					name: 'Field',
					title: 'This will print the text according to selected field',
				};
			case 'Static Text':
				return {
					bgcolor: '#CCFFFF',
					name: 'Static Text',
					title: 'This will print the text as it is you give',
				};
			case 'qrCode':
				return {
					bgcolor: '#ecf0f1',
					name: 'QR Code',
					title: 'Insert a QR Code on Label',
				};
			case 'barCode':
				return {
					bgcolor: '#fdfbfb',
					name: 'Barcode',
					title: 'Insert a Bar Code on Label',
				};
			case 'Insert':
				return {
					bgcolor: '#708090',
					name: 'Insert',
					title: 'Insert a Image on Label',
				};
			case 'table':
				return {
					bgcolor: '#708090',
					name: 'Table',
					title: 'Create a Table',
				};
			default:
				return {
					bgcolor: 'violet',
					name: 'Text',
					title: '',
				};
		}
	}
	const toolInfo = chooseNameAndColor(props?.info);
	return (
		<Tooltip title={toolInfo?.title}>
			<div
				id={props.info}
				onClick={handleDrag}
				style={{
					backgroundColor: toolInfo?.bgcolor,
					padding: '0.5rem 0.7rem',
					borderRadius: '0.5rem',
					cursor: 'pointer',
				}}
			>
				<div className='tool-bounding-box'>
					<img
						className='tool-img'
						id={props.info}
						src={props.src}
						alt={props.info}
						width='1rem'
					/>
					<p
						id={props.info}
						style={{
							margin: 0, fontWeight: 600,
						}}
					>
						{toolInfo?.name}
					</p>
				</div>
			</div>
		</Tooltip>
	);
}
export default Tool;
