import moment from 'moment';
import {
	useEffect,
	useMemo,
	useState
} from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import analyticsAction from '../../../../Actions/analytics.actions';
import { DefectImageModal } from '../../../../Components/Modals';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './AiGrnImagesPage.module.scss';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import {
	analyticsConstants, modalTypes, urls
} from '../../../../Constants';
import commonAnalyticsServices from '../../../../Services/Analytics Services/common-analytics.services';
import { ImagesResult } from '../../../../Components/ImagesResult';
import { modalActions } from '../../../../Actions';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const FIND_FILTERS = [
	'filteredStore',
	'processTypes',
	'brand',
	'product',
	'identificationType',
	'timeType',
	'pageLimit',
	'dateRange',
];

const AiGrnImagesPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		processTypes: null,
		products: [],
		brand: null,
		identification: 'Both',
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
	});

	const {
		updateImagesList,
		allImagesResults,
		fetchImages,
		resetImages,
		toggleModal,
	} = props;

	useEffect(() => {
		resetImages(analyticsConstants.RESET_AI_GRN_ANALYSIS);
	}, []);

	const submitFindRequest = ({
		store = null,
		processTypes = null,
		products = [],
		dateRange = {},
		page = 6,
		brand = null,
		identification,
	}) => {
		resetImages(analyticsConstants.RESET_AI_GRN_ANALYSIS);
		setFilters({
			store,
			processTypes,
			products,
			dateRange,
			page,
			brand,
			identification,
		});
		const data = {
			store,
			processTypes,
			products,
			brandId: brand,
			qcType: 'INWARD',
			identification,
			dateRangeFilter: dateRange?.active
				? {
					from: moment(dateRange?.from).format('YYYY-MM-DD'),
					to: moment(dateRange?.to).format('YYYY-MM-DD'),
				}
				: {},
		};
		fetchImages({
			entity: 'AI_GRN_ANALYSIS',
			method: 'FIND_IMAGES',
			req: {
				data,
				params: {
					limit: page,
				},
			},
		});
	};

	const fetchGrnImagesById = async ({ page, id: productId }, index) => {
		const response =
			await commonAnalyticsServices.fetchImagesById({
				params: {
					page,
					limit: filters?.page,
				},
				data: {
					store: filters?.store,
					brandId: filters?.brand,
					processTypes: filters?.processTypes,
					identification: filters?.identification,
					productId,
					qcType: 'INWARD',
					dateRangeFilter: filters?.dateRange?.active
						? {
							from: moment(filters?.dateRange?.from).format(
								'YYYY-MM-DD'
							),
							to: moment(filters?.dateRange?.to).format(
								'YYYY-MM-DD'
							),
						}
						: {},
				},
			});

		const { data = [] } = response;
		updateImagesList(index, data, 'AI_GRN_ANALYSIS', 'SET_AI_GRN_IMAGES');
		return data;
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	const totalValues = useMemo(() => {
		let totalShown = 0;
		let typeOfProducts = 0;
		let totalCount = 0;
		allImagesResults?.forEach((product) => {
			if (product?.Annotation.length > 0) {
				totalShown += product?.Annotation?.length ?? 0;
				typeOfProducts++;
			}
			totalCount += product?.count ?? 0;
		});
		return {
			totalShown,
			totalCount,
			typeOfProducts,
		};
	}, [allImagesResults]);

	const onItemClick = async ({ annotation, item: product }) => {
		const qcData = await fetchQCData(annotation.qcId);
		const item = {
			source: annotation.image,
			name: qcData?.ProductName,
			product,
			date: qcData?.QCTime,
			link:
				qcData != null
					? `${urls.EXTENSION}${urls.PAST_QC}/${qcData?.itemId}?id=${qcData?.id}`
					: null,
		};
		toggleModal(true, {
			item,
		});
	};

	return (
		<div className={styles.root}>
			<DefectImageModal />
			<ImagesFinder
				pageLabel='AI-GRN Images'
				FILTERS={FIND_FILTERS}
				onSubmit={submitFindRequest}
			/>
			{allImagesResults?.length !== 0 && (
				<div className={styles.numbers}>
					<div className={styles.element}>
						Total Count:
						<span>{totalValues?.totalCount}</span>
					</div>
					{/* <div className={styles.element}>
						Total Showing
						<span>{totalValues?.totalShown}</span>
					</div> */}
					<div className={styles.element}>
						Type of Products:
						<span>{totalValues?.typeOfProducts}</span>
					</div>
				</div>
			)}
			<div className={styles['image-results']}>
				{allImagesResults?.map((currentItem, index) => {
					return (
						currentItem.Annotation.length > 0 && (
							<ImagesResult
								key={currentItem?.id}
								showLoadMore
								fetchMoreImages={(req) => {
									fetchGrnImagesById(req, index);
								}}
								onItemClick={onItemClick}
								currentItem={currentItem}
								clickable
								fetchQCData={fetchQCData}
							/>
						)
					);
				})}
				{allImagesResults?.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_IMAGES: allImagesResults } = state.analytics.AI_GRN_ANALYSIS;

	return {
		allImagesResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateImagesList: (...args) => {
			dispatch(analyticsAction.updateImagesList(...args));
		},
		resetImages: (type) => {
			dispatch(analyticsAction.resetImages(type));
		},
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AiGrnImagesPage);
