/* eslint-disable no-shadow */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
/* eslint-disable */
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import styles from './MyMachinesPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import myMachineService from '../../../Services/AssetServices/myMachine.service';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Machine Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mac address',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mapped Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Current Version',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last Sync At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last updated',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last updated by',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Edit',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'Machine Name': 'machine_name',
	'Mac address': 'mac',
	'Store Name': 'storeName',
	'Mapped Process': 'processName',
	'Current Version': 'currentVersion',
	'Last Sync At': 'lastSyncAt',
	'Last updated': 'updatedAt',
	'Last updated by': 'updated_by',
};

const FIND_FILTERS = ['filteredStore'];

function MyMachinesPage({ createPath, navState, userList, fetchUserList }) {
	const history = useHistory();
	const [isloading, setIsloading] = useState(false);
	const [machinesData, setMachinesData] = useState([]);
	const [latestVersion, setLatestVersion] = useState(false);
	const [selectedStore, setSelectedStore] = useState(null);
	const { userConfig } = useAuth();

	// useEffect(() => {
	// 	fetchUserList();
	// }, []);

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/account']?.submenu['/account/my-machines']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	useEffect(() => {
			if (SPECIAL_COLUMNS?.[1]?.title !== 'Update') {
				// SPECIAL_COLUMNS.shift();
				// }
				SPECIAL_COLUMNS.push({
					title: 'Update',
					config: {
						type: 'DATA',
					},
				});
			}
	}, []);

	const handleListFetch = useCallback(
		({ store = null }) => {
			(async () => {
				setIsloading(true);
				try {
					const responseData =
						await myMachineService.fetchListOfMachines({
							req: {
								data: {
									storeId: store,
								},
							},
						});
					if (responseData?.statusCode === 200) {
						setSelectedStore(store);
						setMachinesData(responseData?.data?.machinesList);
						setLatestVersion(responseData?.data?.latestVersion);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setIsloading(false);
				}
			})();
		},
		[
			myMachineService.fetchListOfMachines,
			setMachinesData,
			setSelectedStore,
			selectedStore,
		]
	);
	const handleUpdate = useCallback(
		(versionName, machineId) => {
			(async () => {
				setIsloading(true);
				try {
					const responseData =
						await myMachineService.updateMachineService({
							req: {
								data: {
									versionName: versionName ?? null,
									machineId,
								},
							},
						});
					if (responseData?.statusCode === 200) {
						handleListFetch({ store: selectedStore });
						notificationServices.generateNotification({
							type: 'success',
							message: responseData?.message,
						});
					} else {
						notificationServices.generateNotification({
							type: 'error',
							message: responseData?.message,
						});
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setIsloading(false);
				}
			})();
		},
		[myMachineService.updateMachineService, handleListFetch]
	);
	// eslint-disable-next-line no-unused-vars

	useEffect(() => {
		handleListFetch({});
	}, []);

	const SPECIAL_ITEMS = {
		Edit: (id) => {
			// eslint-disable-next-line no-unused-vars
			const thisItem = machinesData?.find((ele) => {
				return ele.id === id;
			});
			return (
				<Button
					onClick={() => {
					// eslint-disable-next-line prefer-template
					history?.push(createPath + '?isEdit=true&machineId=' + id);
				}}
					variant='outlined'
				>Edit
				</Button>
			);
		},
		Update: (id) => {
			// eslint-disable-next-line no-unused-vars
			const thisItem = machinesData.find((ele) => {
				return ele.id === id;
			});
			return (
				<div
					style={{
						// width: '5rem',
						display: 'flex',
						flexDirection: 'column',
						cursor: 'pointer',
						alignContent: 'center',
						alignItems: 'center',
						gap: '.2rem',
						justifyContent: 'space-evenly',
						justifyItems: 'center',
					}}
				>
					{!thisItem?.toBeUpdated &&
					latestVersion?.version &&
					latestVersion?.version !== thisItem?.currentVersion ? (
						<Button
							variant='contained'
							color='success'
							size='small'
							style={{
								padding: '0px',
								fontWeight: 'bolder',
								color: 'white',
							}}
							onClick={() => {
								handleUpdate(
									latestVersion?.version,
									thisItem?.id
								);
							}}
						>
							Upgrade to {latestVersion?.version}
						</Button>
					) : (
						<>{!thisItem?.toBeUpdated && <>Already to Latest</>}</>
					)}
					{thisItem?.toBeUpdated && (
						<>
							<span style={{ fontSize: '.7rem' }}>
								Changing to{' '}
							</span>
							<span style={{ fontWeight: 500 }}>
								{' '}
								{thisItem?.toBeUpdated}
							</span>
							<Button
								variant='contained'
								color='error'
								size='small'
								onClick={() => {
									handleUpdate(null, thisItem?.id);
								}}
								style={{
									fontSize: '0.7rem',
									wordBreak: 'keep-all',
									color: 'white',
								}}
							>
								Cancel Change
							</Button>
						</>
					)}
					{thisItem?.previous_version &&
						!thisItem?.toBeUpdated &&
						thisItem?.currentVersion !==
							thisItem?.previous_version && (
							// eslint-disable-next-line react/jsx-indent
							<Button
								variant='contained'
								color='warning'
								size='small'
								onClick={() => {
									handleUpdate(
										thisItem?.previous_version,
										thisItem?.id
									);
								}}
								style={{
									fontSize: '.6rem',
									padding: '0px',
								}}
							>
								Downgrade to {thisItem?.previous_version}
							</Button>
						)}
				</div>
			);
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='My Machines'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					additionInfo={{
						showAdd: true,
						// showAdd: false,
						addText: 'Machine',
						handleAddClick,
					}}
				/>
			</div>
			<div className={styles?.version}>
				Latest available version{' : '}
				{latestVersion && <span> {latestVersion?.version} </span>}
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={machinesData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state.auth;
	return {
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchUserList: authActions.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMachinesPage);
