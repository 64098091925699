import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const liveIMSServices = {
	getLiveData,
	getPastData,
};

function getLiveData(req) {
	const url = toQueryStringURL(endpoints.LIVE_IMS, req.params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function getPastData(req) {
	const url = toQueryStringURL(endpoints.PAST_IMS, req.params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default liveIMSServices;
