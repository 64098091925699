/**
 * @typedef {Object} InputState
 * @property {string} id - The id of the input.
 * @property {string} type - The type of the input.
 * @property {string} [name] - The name of the input.
 * @property {string} [value] - The value of the input.
 * @property {string} [placeholder] - The placeholder text of the input.
 * @property {string} [labelText] - The label text of the input.
 * @property {boolean} [mandatory] - Indicates if the input is mandatory.
 * @property {Array<string>} [optionsList] - The list of options for select inputs.
 * @property {function} [onChange] - The change event handler.
 * @property {function} [disabledDate] - Indicates if the expiry date is disabled.
 * @property {boolean} [oneTap] - Indicates if only one tap is allowed.
 */

/**
 * @typedef {Object} Props
 * @property {Array<InputState>} [inputsList] - The list of input states.
 */

import { Button, TextField } from '@mui/material';
import RadioGroup from 'rsuite/RadioGroup';
import Radio from 'rsuite/Radio';
import Checkbox from 'rsuite/Checkbox';
import Input from 'rsuite/Input';
import styles from './CreateFormpage.module.scss';
import DatePickerCustom from '../DatePickerCustom/DatePickerCustom';
import CustomInputSelector from '../CustomInputSelector/CustomInputSelector';
import { IconInput } from '../Forms/Inputs';
import { FileUploaderModal } from '../Modals/FileUploaderModal';
import { classes } from '../../Helpers';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';

const paymentTerms = [
	'Net 15',
	'Net 30',
	'Net 45',
	'Net 60',
	'Due end of the month',
	'Due end of next month',
	'Due on receipt',
];

const LabelledOptionInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		width,
		labelText,
		mandatory,
		optionsList,
		extraInfo = {},
	} = props;
	return (
		<div className={styles.tile}>
			<label className={mandatory ? styles.mandatory : ''} htmlFor={id}>
				<div>
					{labelText}
					{mandatory ? '*' : ''}
				</div>
				{extraInfo?.text && (
					<span
						style={{
							color: extraInfo?.color,
						}}
					>
						{extraInfo?.text}
					</span>
				)}
			</label>
			<Select
				value={value}
				onValueChange={(newValue) => {
					onChange(newValue);
				}}
				name={name}
			>
				<SelectTrigger
					style={{
						width,
					}}
				>
					<SelectValue placeholder='Select' />
				</SelectTrigger>
				<SelectContent>
					{optionsList?.map((option) => {
						return (
							<SelectItem
								key={option?.id ?? option}
								value={option?.id ?? option}
							>
								{option?.title ?? option}
							</SelectItem>
						);
					})}
				</SelectContent>
			</Select>
		</div>
	);
};

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		placeholder,
		labelText,
		mandatory,
		width,
		type,
		prefix = false,
		step,
	} = props;
	return (
		<div className={styles.tile}>
			<label className={mandatory ? styles.mandatory : ''} htmlFor={id}>
				{labelText}
				{mandatory ? '*' : ''}
			</label>
			{prefix ? (
				<IconInput
					type='custom'
					iconText={prefix}
					size='large'
					id={id}
					name={name}
					inputType={type}
					value={value}
					handleInput={onChange}
					style={{
						width: width ?? '200px',
					}}
				/>
			) : (
				<TextField
					id={id}
					name={name}
					value={value}
					onChange={onChange}
					type={type}
					step={step}
					placeholder={placeholder}
					sx={{
						width: width ?? 200,
						'.MuiInputBase-root': {
							borderRadius: '6px',
						},
						'.MuiInputBase-input': {
							paddingTop: '6px',
							paddingBottom: '6px',
						},
					}}
				/>
			)}
		</div>
	);
};

const LabelledCustomSelector = (props) => {
	const {
		value,
		id,
		name,
		placeholder,
		labelText,
		mandatory,
		width,
		optionsList,
		handleKeyPress,
		handleSelection,
		handleAddNew,
		disabled,
		handleRemove,
		showImage,
		hideAddNew,
		allowRemove,
		addNewText,
	} = props;
	return (
		<div className={styles.tile}>
			<label className={mandatory ? styles.mandatory : ''} htmlFor={id}>
				{labelText}
				{mandatory ? '*' : ''}
			</label>
			<CustomInputSelector
				inputName={name}
				width={width ?? '300px'}
				inputLists={optionsList}
				handleKeyPress={handleKeyPress}
				handleSelection={handleSelection}
				addNewText={addNewText}
				placeholder={placeholder}
				selectedValue={value}
				classname={styles['custom-selector']}
				handleAddNew={handleAddNew}
				disabled={disabled}
				allowRemove={allowRemove}
				hideAddNew={hideAddNew}
				showImage={showImage}
				handleRemove={handleRemove}
			/>
		</div>
	);
};

const LabelledDateSelector = (props) => {
	const {
		value,
		onChange,
		id,
		labelText,
		mandatory,
		oneTap,
		disabledDate,
		width,
		cleanable,
	} = props;
	return (
		<div className={styles.tile}>
			<label
				className={mandatory ? styles.mandatory : ''}
				htmlFor='deliveryDate'
			>
				{`${labelText}${mandatory ? '*' : ''}`}
			</label>
			<DatePickerCustom
				id={id}
				selectedDate={value}
				selectDate={onChange}
				style={{
					width: width ?? 200,
				}}
				oneTap={oneTap}
				defaultValue={value}
				cleanable={cleanable}
				shouldDisableDate={disabledDate}
			/>
		</div>
	);
};

/**
 * Render inputs based on input state.
 * @param {InputState} inputState - The state of the input.
 * @returns {React.ReactNode} - The rendered input component.
 */

const renderInputs = (inputState) => {
	if (Array.isArray(inputState)) {
		return (
			<div className={styles.multi}>
				{inputState?.map((ele) => {
					return renderInputs(ele);
				})}
			</div>
		);
	}
	if (inputState?.inputType === 'options') {
		return (
			<LabelledOptionInput
				id={inputState?.id}
				name={inputState?.name}
				width={inputState?.width}
				value={inputState?.value}
				onChange={inputState?.onChange}
				mandatory={inputState?.mandatory}
				labelText={inputState?.labelText}
				extraInfo={inputState?.extraInfo ?? {}}
				inputLabel={inputState?.inputLabel}
				optionsList={inputState?.optionsList}
				placeholder={inputState?.placeholder}
			/>
		);
	}
	if (inputState?.inputType === 'options_search') {
		return (
			<LabelledCustomSelector
				id={inputState?.id}
				name={inputState?.name}
				width={inputState?.width}
				value={inputState?.value}
				onChange={inputState?.onChange}
				disabled={inputState?.disabled}
				mandatory={inputState?.mandatory}
				labelText={inputState?.labelText}
				showImage={inputState?.showImage}
				inputLabel={inputState?.inputLabel}
				hideAddNew={inputState?.hideAddNew}
				addNewText={inputState?.addNewText}
				placeholder={inputState?.placeholder}
				optionsList={inputState?.optionsList}
				allowRemove={inputState?.allowRemove}
				handleRemove={inputState?.handleRemove}
				handleAddNew={inputState?.handleAddNew}
				handleKeyPress={inputState?.handleKeyPress}
				handleSelection={inputState?.handleSelection}
			/>
		);
	}
	if (inputState?.inputType === 'dateSelector') {
		return (
			<LabelledDateSelector
				id={inputState?.id}
				value={inputState?.value}
				labelText={inputState?.labelText}
				width={inputState?.width}
				disabledDate={inputState?.disabledDate}
				mandatory={inputState?.mandatory}
				oneTap={inputState?.oneTap}
				onChange={inputState?.onChange}
				cleanable={inputState?.cleanable}
			/>
		);
	}
	if (inputState?.inputType === 'paymentTerm') {
		return (
			<div className={classes(styles.tile)}>
				<label className={styles.mandatory} htmlFor='paymentTerms'>
					Payment Terms*
				</label>
				<Select
					value={inputState?.value}
					onValueChange={(value) => {
						inputState?.onChange(value);
					}}
				>
					<SelectTrigger>
						<SelectValue placeholder='Theme' />
					</SelectTrigger>
					<SelectContent>
						{paymentTerms?.map((ele) => {
							return (
								<SelectItem key={ele} value={ele}>
									{ele}
								</SelectItem>
							);
						})}
					</SelectContent>
				</Select>
			</div>
		);
	}
	if (inputState?.inputType === 'toggleSelector') {
		return (
			<div className={classes(styles.tile)}>
				<label className={styles.mandatory}>
					{`${inputState?.labelText}${
						inputState?.mandatory ? '*' : ''
					}`}
				</label>
				<RadioGroup
					name={inputState?.name}
					onChange={inputState?.onChange}
					inline
					value={inputState?.value}
				>
					{inputState?.options?.map((ele) => {
						return (
							<Radio value={ele?.value}>{ele?.displayName}</Radio>
						);
					})}
				</RadioGroup>
			</div>
		);
	}
	if (inputState?.inputType === 'textArea') {
		return (
			<div className={classes(styles.tile)}>
				<label className={styles.mandatory}>
					{`${inputState?.labelText}${
						inputState?.mandatory ? '*' : ''
					}`}
				</label>
				<Input
					as='textarea'
					style={{
						width: inputState?.width,
					}}
					rows={3}
					placeholder={inputState?.placeholder}
					value={inputState?.value}
					onChange={inputState?.onChange}
				/>
			</div>
		);
	}
	if (inputState?.inputType === 'checkbox') {
		return (
			<div className={classes(styles.tile)}>
				<Checkbox
					style={{
						width: inputState?.width,
					}}
					checked={inputState?.value}
					onChange={inputState?.onChange}
				>
					{inputState?.labelText}
				</Checkbox>
			</div>
		);
	}
	if (inputState?.inputType === 'customComponent') {
		return inputState?.component;
	}
	if (inputState?.inputType === 'docUploader') {
		return (
			<div className={`${styles.tile}`}>
				<label
					className={inputState?.mandatory ? styles.mandatory : ''}
				>
					{inputState?.labelText}
					{inputState?.mandatory ? '*' : ''}
				</label>
				<div className={styles['uploaded-file']}>
					<div className={styles.btns}>
						<Button
							type='button'
							variant={
								inputState?.value?.file &&
								inputState?.value?.fileName
									? 'outlined'
									: 'contained'
							}
							color='primary'
							onClick={inputState?.uploadFileClick}
						>
							{inputState?.prefix}
							{' '}
							{inputState?.btnText}
						</Button>
						{inputState?.removeClick && (
							<Button
								type='button'
								variant='outlined'
								color='error'
								onClick={inputState?.removeClick}
							>
								Remove
							</Button>
						)}
					</div>
					{inputState?.value?.file && inputState?.value?.fileName && (
						<div className={styles.preview}>
							<p>{inputState?.value?.fileName}</p>
							<div className={styles['preview-container']}>
								<iframe
									className={styles.iframe}
									width='100%'
									height='100%'
									src={inputState?.value?.file}
									title='PDF Rendered from Base64'
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
	return (
		<LabelledInput
			id={inputState?.id}
			name={inputState?.name}
			value={inputState?.value}
			prefix={inputState?.prefix}
			type={inputState?.type}
			step={inputState?.step}
			width={inputState?.width}
			placeholder={inputState?.placeholder}
			labelText={inputState?.labelText}
			mandatory={inputState?.mandatory}
			onChange={inputState?.onChange}
		/>
	);
};

/**
 * Component for creating a form page.
 * @param {Props} props - The component props.
 * @returns {React.ReactNode} - The rendered component.
 */

const CreateFormpage = (props) => {
	const { inputsList = [] } = props;
	return (
		<div className={styles.root}>
			<FileUploaderModal />
			{inputsList?.map((ele) => {
				return renderInputs(ele);
			})}
		</div>
	);
};

export default CreateFormpage;
