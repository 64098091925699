import { TextField } from '@mui/material';
import { get } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions, productActions } from '../../../Actions';
import { modalTypes } from '../../../Constants';
import { BaseModal } from '../BaseModal';
import { ProductVariantsListItem } from './ProductVariantsListItem';
import styles from './ProductVariantsModal.module.scss';

const HEADING = 'Select a variant';
const MODAL_TYPE = modalTypes.PRODUCT_VARIANTS;
const initialState = {
	multiSelect: {
		productId: [],
		variantId: [],
	},
	default: {
		productId: null,
		variantId: null,
	},
};
const ProductVariantsModal = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		show,
		data,
		toggleModal,
		fetchProducts,
		products,
		groups,
		multiSelect,
	} = props;

	const { groupId = null, itemTypes = ['PRODUCT'] } = data;
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		fetchProducts();
	}, []);

	const [state, setState] = useState(() => {
		return initialState[multiSelect ? 'multiSelect' : 'default'];
	});

	const handleClose = () => {
		setState({
			...initialState[multiSelect ? 'multiSelect' : 'default'],
		});
		toggleModal(MODAL_TYPE, false);
	};

	const addVariant = (productId, variantId) => {
		if (multiSelect) {
			// check if the state already has the variant; deselect it
			if (
				state.productId === productId &&
				state.variantId === variantId
			) {
				setState({
					...state,
					productId: [],
					variantId: [],
				});
				// else set the variant in state and select it
			} else {
				setState({
					...state,
					productId: [...state.productId, productId],
					variantId: [...state.variantId, variantId],
				});
			}
			return;
		}

		// check if the state already has the variant; deselect it
		if (state.productId === productId && state.variantId === variantId) {
			setState({
				...state,
				productId: null,
				variantId: null,
			});
			// else set the variant in state and select it
		} else {
			setState({
				...state,
				productId,
				variantId,
			});
		}
	};

	const handleConfirm = () => {
		const { productId, variantId } = state;
		if (
			productId != null ||
			(Array.isArray(productId) && productId?.length !== 0)
		) {
			const selectedVariant =
				products[productId].ProductVariant[variantId];
			const variantObj = {
				productId,
				variantId,
				default: false,
				additionalPrice: 0,
				price: selectedVariant.cost,
				groupId,
			};

			props.handleConfirm(variantObj);
		}
		handleClose();
	};

	const filteredMemoizedProducts = useMemo(() => {
		return Object.keys(products).filter((id) => {
			const product = products[id];
			return (
				itemTypes.indexOf(product.skuType) !== -1 &&
				product?.productName
					?.toLowerCase()
					?.includes(searchQuery?.toLowerCase())
			);
		});
	}, [products, itemTypes, searchQuery]);

	const generateList = () => {
		return filteredMemoizedProducts?.map((id) => {
			const product = products[id];
			return (
				<ProductVariantsListItem
					product={product}
					choices={get(groups, [groupId, 'choices'])}
					key={id}
					handleClick={addVariant}
					selection={state}
				/>
			);
		});
	};

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
			width='50rem'
		>
			<div className={`${styles.root}`}>
				<TextField
					fullWidth
					value={searchQuery}
					onChange={(e) => {
						setSearchQuery(e?.target?.value);
					}}
					placeholder='Search'
				/>
				{generateList()}
				{filteredMemoizedProducts.length === 0 && (
					<p>No Items to display</p>
				)}
			</div>
		</BaseModal>
	);
};

ProductVariantsModal.defaultProps = {
	multiSelect: false,
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.[MODAL_TYPE];
	const { products } = state.product;
	return {
		show,
		data,
		products,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleModal,
	fetchProducts: productActions.fetchProducts,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductVariantsModal);
