/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import {
	Autocomplete,
	MenuItem,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { SelectPicker } from 'rsuite';
import { analyticsAction, formActions } from '../../Actions';
import styles from './ImsStockFilter.module.scss';
import { classes, skuTypesList } from '../../Helpers';

const skuTypes = ['ALL', ...skuTypesList];

const EMPTYVALUE = -999;
function ImsStockFilter(props) {
	const {
		IMS_STOCK_FILTER,
		applyFilters,
		fetchData,
		stores,
		fetchStoresList,
		skuType,
		setSkuType,
		stockDeficit,
		setStockDeficit,
	} = props;

	const [selectedStore, setSelectedStore] = useState(null);

	const handleChange = (e, value, storeId) => {
		if (value) {
			applyFilters(
				{
					IMS_STOCK_FILTER: {
						applied: true,
						value,
					},
					STORE_FILTER: {
						active: false,
						selectedStores: storeId ? [storeId] : [],
					},
				},
				false
			);
			fetchData('INVENTORY_ANALYTICS', 'SKU_STOCK', {});
		}
	};

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
			};
		});
		return tempStore;
	}, [stores]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		applyFilters(
			{
				IMS_STOCK_FILTER: {
					applied: true,
					value: 'stock',
				},
				STORE_FILTER: {
					active: false,
					selectedStores: [],
				},
			},
			false
		);
		return () => {
			applyFilters(
				{
					STORE_FILTER: {
						active: false,
						selectedStores: [],
					},
				},
				false
			);
		};
	}, []);

	const handleSkuType = (e) => {
		setSkuType(e.target.value);
	};

	const handleDeficit = () => {
		setStockDeficit(!stockDeficit);
	};

	return (
		<div className={styles.root}>
			<div className={classes(styles.filter, styles.product)}>
				<ToggleButtonGroup
					value={IMS_STOCK_FILTER?.value}
					exclusive
					onChange={(e, value) => {
						handleChange(
							e,
							value,
							selectedStore?.id !== EMPTYVALUE
								? selectedStore?.id
								: null
						);
					}}
					// aria-label='text alignment'?
				>
					<ToggleButton
						value='stock'
						className={
							IMS_STOCK_FILTER?.value === 'stock'
								? styles.selected
								: ''
						}
					>
						<p>Stocked</p>
					</ToggleButton>
					<ToggleButton
						value='rejected'
						className={
							IMS_STOCK_FILTER?.value === 'rejected'
								? styles.selected
								: ''
						}
					>
						<p>Rejected</p>
					</ToggleButton>
				</ToggleButtonGroup>
				<div className={styles['filter-body']}>
					<Autocomplete
						disablePortal
						id='combo-box-demo'
						popupIcon={<SearchIcon />}
						getOptionLabel={(option) => {
							return option?.title;
						}}
						onChange={(event, selectedOption) => {
							setSelectedStore(selectedOption);
							handleChange(
								event,
								IMS_STOCK_FILTER?.value,
								selectedOption?.id
							);
						}}
						value={selectedStore}
						options={STORES}
						sx={{
							width: 250,
							background: 'rgba(255,255,255,1)',
							'& .MuiAutocomplete-popupIndicator': {
								transform: 'none',
							},
						}}
						renderInput={(params) => {
							return (
								<TextField {...params} label='Select Store' />
							);
						}}
					/>
				</div>
				<div className={styles['sku-type-filter']}>
					{/* <TextField
						label='Select SKU Type'
						className={styles['store-select']}
						select
						value={skuType}
						onChange={handleSkuType}
					>
						{skuTypes?.map((ele) => {
							return (
								<MenuItem key={ele} value={ele}>
									{ele}
								</MenuItem>
							);
						})}
					</TextField> */}
					<SelectPicker
						data={skuTypes?.map((ele) => {
							return {
								label: ele,
								value: ele,
							};
						})}
						onChange={(skuTypeValue) => {
							setSkuType(skuTypeValue);
						}}
						cleanable={false}
						searchable={false}
						style={{
							width: 150,
						}}
						placeholder='SKU Type'
					/>
				</div>
				<div className={styles['sku-type-filter']}>
					<ToggleButtonGroup
						value={stockDeficit}
						exclusive
						onChange={handleDeficit}
					>
						<ToggleButton
							value='lessThan'
							className={stockDeficit ? styles.selected : ''}
						>
							<p>Deficit</p>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>
			<div className={styles.indicators}>
				<div className={styles['indicators-red']}>
					<div className={styles.color} />
					<p>Current Stock is less than Threshold</p>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { IMS_STOCK_FILTER } = state?.analytics;
	const stores = state?.form?.STORES?.data?.LIST;
	const data = state.analytics.INVENTORY_ANALYTICS.SKU_STOCK;
	return {
		stores,
		IMS_STOCK_FILTER,
		data,
	};
};

const mapDispatchToProps = {
	applyFilters: analyticsAction.applyFilters,
	filterStockInventory: analyticsAction.filterStockInventory,
	fetchStoresList: formActions.gateway,
	fetchData: analyticsAction.fetchDataRange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImsStockFilter);
