/* eslint-disable import/named */
// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { GeneralDetails } from '../../../Components/Forms/InventoryCategory/GeneralDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
import { inputHelper, isEmpty } from '../../../Helpers';
// eslint-disable-next-line object-curly-newline
import InventoryConfig from './InventoryCategory.config';

const InventoryCateogoryCreatePage = (props) => {
	const {
		isEdit,
		saveInventoryCategory,
		data,
		fetchInventoryCategory,
		breadcrumbs,
	} = props;
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const [state, setState] = useState({
		...InventoryConfig.initialState,
	});
	const ProductCategoryId = useParams().id;
	const history = useHistory();
	const { fields, errors } = state;
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === ProductCategoryId) {
				setState(InventoryConfig.serializer(state, data));
			} else {
				fetchInventoryCategory({
					entity: 'INVENTORY_CATEGORY',
					req: {
						params: {
							id: ProductCategoryId,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(InventoryConfig.serializer(state, data));
			}
		}
	}, [data]);
	const generateDefaultOption = (type, brand) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = InventoryConfig.deserializer(state);
		setNumberOfSubmissions(numberOfSubmissions + 1);
		if (isEdit) {
			req.params = {
				id: ProductCategoryId,
			};
			saveInventoryCategory({
				entity: 'INVENTORY_CATEGORY',
				req,
				method: 'UPDATE',
				redirect: `${urls.EXTENSION}${urls.INVENTORY_CATEGORY}`,
			});
		} else {
			saveInventoryCategory({
				entity: 'INVENTORY_CATEGORY',
				req,
				method: 'CREATE',
				redirect: `${urls.EXTENSION}${urls.INVENTORY_CATEGORY}`,
			});
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.INVENTORY_CATEGORY}/view/${ProductCategoryId}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.INVENTORY_CATEGORY}`);
		}
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={
						isEdit
							? 'Edit Inventory Category'
							: 'Create Inventory Category'
					}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				{/* Product details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={InventoryConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						generateDefaultOption={generateDefaultOption}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_CATEGORY.data.VIEW;
	return {
		data,
	};
};
const mapDispatchToProps = {
	fetchInventoryCategory: formActions.gateway,
	saveInventoryCategory: formActions.gateway,
	deleteInventoryCategory: formActions.gateway,
};
InventoryCateogoryCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryCateogoryCreatePage);
