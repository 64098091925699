import { useRouteMatch } from 'react-router';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';
import MasterMenu from '../../../Pages/Menu Page/Master Menu/MasterMenu';
import MasterMenuListPage from '../../../Pages/Menu Page/Master Menu/MasterMenuListPage';
import MasterMenuViewPage from '../../../Pages/Menu Page/Master Menu/MasterMenuViewPage';
import { urls } from '../../../Constants';

const MasterMenuRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.MASTER_MENUS}`,
			name: 'Menu',
		},
	];
	return (
		<CRUDRoutes
			entity='MASTER MENU'
			path={path}
			listPage={MasterMenuListPage}
			createPage={<MasterMenu breadcrumbs={breadcrumbs} />}
			editPage={<MasterMenu isEdit breadcrumbs={breadcrumbs} />}
			viewPage={<MasterMenuViewPage breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default MasterMenuRoutes;
