import { cloneDeep } from 'lodash';
import { notificationServices } from '../../../Services';

const initialState = {
	selectedStore: null,
	name: null,
	startDate: null,
	duration: null,
	endDate: null,
	roles: null,
	managers: null,
	days: null,
	capacity: {},
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_STORE':
			return {
				...state,
				selectedStore: action.payload,
			};
		case 'SET_EDIT':
			return {
				...state,
				...action.payload,
			};
		case 'SET_STATE':
			return {
				...state,
				[action.name]: action.payload,
			};
		default:
			return state;
	}
};

const DaysList = [
	{
		label: 'Monday',
		value: 1,
	},
	{
		label: 'Tuesday',
		value: 2,
	},
	{
		label: 'Wednesday',
		value: 3,
	},
	{
		label: 'Thrusday',
		value: 4,
	},
	{
		label: 'Friday',
		value: 5,
	},
	{
		label: 'Saturday',
		value: 6,
	},
	{
		label: 'Sunday',
		value: 7,
	},
];

function checkData(state) {
	if (!state?.selectedStore) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please select a store',
		});
		return false;
	}
	if (!state?.name) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please fill shift name',
		});
		return false;
	}
	if (!state?.startDate) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please select start date',
		});
		return false;
	}
	if (!state?.endDate) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please select end date',
		});
		return false;
	}
	if (!state?.roles || state?.roles?.length <= 0) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please select role(s)',
		});
		return false;
	}
	if (!state?.managers || state?.managers?.length <= 0) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please select a manager',
		});
		return false;
	}
	if (!state?.days || state?.days?.length === 0) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Select applicable days',
		});
		return false;
	}
	if (Object.keys(state?.capacity ?? {})?.length < 1) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Capacity not filled for any process',
		});
		return false;
	}
	return true;
}

function generateTimeList() {
	const times = [];
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	while (currentDate.getDate() === (new Date()).getDate()) {
		const ele = formatTime(currentDate);
		times.push({
			label: ele,
			value: ele,
		});
		currentDate.setMinutes(currentDate.getMinutes() + 30);
	}

	return times;
}

function generateDurationList() {
	const durationList = [];
	for (let duration = 0.5; duration <= 24; duration += 0.5) {
		const hours = Math.floor(duration);
		const minutes = Math.round((duration - hours) * 60);
		let currentLabel = '';
		if (hours) {
			currentLabel += `${hours} Hours`;
			if (minutes) {
				currentLabel += ` ${minutes} Minutes`;
			}
		} else if (minutes) {
			currentLabel += ` ${minutes} Minutes`;
		}
		durationList.push({
			label: currentLabel,
			value: duration,
		});
	}
	return durationList;
}

function formatTime(date) {
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	hours %= 12;
	hours = hours || 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	hours = hours < 10 ? `0${hours}` : hours;
	const strTime = `${hours}:${minutes} ${ampm}`;
	return strTime;
}

function handleApiCallingData(capacity) {
	const data = cloneDeep(capacity);
	const temp = {};
	Object.entries(data)?.forEach(([key, value]) => {
		if (value?.active) {
			const val = value;
			// delete val.duration;
			delete val.active;
			temp[key] = val;
		}
	});
	if (Object.keys(temp)?.length === 0) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please define atleast one process',
		});
		return null;
	}
	return temp;
}

export default {
	initialState,
	reducer,
	generateTimeList,
	generateDurationList,
	DaysList,
	handleApiCallingData,
	checkData,
};
