import { useRouteMatch } from 'react-router';
import { ProductListFilters } from '../../../Components/Forms/Products';
import { urls } from '../../../Constants';
import {
	QualityConfigEditPage,
	QualityConfigListPage,
	QualityConfigViewPage,
} from '../../../Pages/Quality';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const SEARCH_BY = {
	options: [
		{
			key: 'NAME',
			title: 'Name',
			get: 'productName',
		},
		{
			key: 'BRAND',
			title: 'Brand',
			get: 'brandName',
		},
	],
};

const QualityConfigRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.PRODUCT_QUALITY_CONFIG}`,
			name: 'All Products',
		},
	];
	return (
		<CRUDRoutes
			entity='QUALITY CONFIG'
			path={path}
			listPage={QualityConfigListPage}
			searchBy={SEARCH_BY}
			listFilter={
				(
					<ProductListFilters
						entity='PRODUCT_QUALITY_CONFIG'
						params={{
							combo: true,
						}}
					/>
				)
			}
			viewPage={<QualityConfigViewPage breadcrumbs={breadcrumbs} />}
			editPage={
				<QualityConfigEditPage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default QualityConfigRoutes;
