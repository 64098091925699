import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	memo, useEffect, useRef, useState
} from 'react';
import styles from './TreeNodeTile.module.css';
import { classes } from '../../../../Helpers';

function checkEquals(data, search) {
	if (data?.id === search?.id && data?.entityType === search?.entityType) return true;
	return false;
}

function TreeNodeTile({
	clickHandle,
	data,
	// setLevel,
	// levelData,
	selectionArray,
	zoomToElement,
	searchData,
}) {
	const [selectionType, setSelectionType] = useState(() => {
		let iSelectionType = 0;
		const index = selectionArray.findIndex((ele) => {
			return checkEquals(ele, data);
			// return ele.id === data?.id && ele.type === data?.type;
		});
		if (index === 0) {
			iSelectionType = 2;
		} else if (index > 0) {
			iSelectionType = 1;
		}
		return iSelectionType;
	});
	// const [expandMe, setexpandMe] = useState(false);
	const element = useRef(null);

	useEffect(() => {
		let tempSelectionType = 0;
		const index = selectionArray.findIndex((ele) => {
			return checkEquals(ele, data);
			// return ele.id === data?.id && ele.type === data?.type;
		});
		if (index === 0) {
			tempSelectionType = 2;
		} else if (index > 0) {
			tempSelectionType = 1;
		}
		setSelectionType(tempSelectionType);
	}, [selectionArray]);

	useEffect(() => {
		if (selectionType === 2) {
			setTimeout(() => {
				const executeScroll = () => {
					zoomToElement(element.current, 0.8, 300, 'easeOut');
				};
				executeScroll();
			}, 100);
		}
	}, [selectionType]);

	useEffect(() => {
		if (searchData?.search) {
			if (selectionType !== 2) {
				if (checkEquals(searchData?.search, data)) {
					setSelectionType(2);
					clickHandle();
				}
			}
		}
	}, [searchData?.search]);

	return (
		<div
			ref={element}
			className={classes(
				styles.root,
				selectionType === 1 ? styles.childSelected : '',
				selectionType === 2 ? styles.selected : ''
			)}
		>
			<Accordion
				square
				disableGutters
				expanded={selectionType === 2}
				className={styles.accordion}
				style={{
					background: data?.colour,
				}}
			>
				<AccordionSummary
					onClick={(event) => {
						// if (levelData?.level === 0) {
						// }
						event.stopPropagation();
						searchData?.setSearch(false);
						clickHandle();
						// executeScroll();
					}}
					className={styles.accordionSummary}
					expandIcon={(
						<ExpandMoreIcon
							sx={{
								color: selectionType ? 'white' : 'black',
							}}
						/>
					)}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<Typography>
						{data?.name}
						{' '}
						{/* {data?.id} */}
					</Typography>
				</AccordionSummary>
				{/*  */}
			</Accordion>
		</div>
	);
}

export default memo(TreeNodeTile);
