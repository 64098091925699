import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
// import Visibility from '@mui/icons-material/Visibility';
import { formActions } from '../../../Actions';
// import ListTable from '../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import VirtualizedListTable from '../../../Components/VirtualizedListTable/VirtualizedListTable';
import { notificationServices } from '@/Services';
import { handleResponseStreamDownload } from '@/Helpers';
import variantServices from '@/Services/Form Services/variants.services';

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Purchase Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'Size 1',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 2',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 3',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 4',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 5',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 6',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 7',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 8',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 9',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 10',
		config: {
			type: 'DATA',
		},
	},
];
const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	'Product Name': 'productName',
	Name: 'productName',
	Brand: 'brandName',
	'Inventory Unit': 'unit',
	'Purchase Unit': 'purchaseUnit',
	Category: 'categoryName',
	'SKU Type': 'skuType',
	'Size 1': 'variants',
	'Size 2': 'variants',
	'Size 3': 'variants',
	'Size 4': 'variants',
	'Size 5': 'variants',
	'Size 6': 'variants',
	'Size 7': 'variants',
	'Size 8': 'variants',
	'Size 9': 'variants',
	'Size 10': 'variants',
};
const generateIcon = (arr, idx) => {
	const iconDOM =
		// eslint-disable-next-line no-nested-ternary
		arr.length > idx ? (
			!arr[idx].isDisabled ? (
				<CheckCircleIcon
					size='small'
					style={{
						color: 'green',
					}}
				/>
			) : (
				<CancelIcon
					style={{
						color: 'red',
					}}
				/>
			)
		) : (
			<DoNotDisturbOnIcon
				style={{
					color: 'grey',
				}}
			/>
		);
	return <td key={idx}>{iconDOM}</td>;
};
const SPECIAL_ITEMS = {
	'Size 1': (arr) => {
		return generateIcon(arr, 0);
	},
	'Size 2': (arr) => {
		return generateIcon(arr, 1);
	},
	'Size 3': (arr) => {
		return generateIcon(arr, 2);
	},
	'Size 4': (arr) => {
		return generateIcon(arr, 3);
	},
	'Size 5': (arr) => {
		return generateIcon(arr, 4);
	},
	'Size 6': (arr) => {
		return generateIcon(arr, 5);
	},
	'Size 7': (arr) => {
		return generateIcon(arr, 6);
	},
	'Size 8': (arr) => {
		return generateIcon(arr, 7);
	},
	'Size 9': (arr) => {
		return generateIcon(arr, 8);
	},
	'Size 10': (arr) => {
		return generateIcon(arr, 9);
	},
};
const VariantsListPage = (props) => {
	const { fetchProducts, data, query, searchBy, resetVariants } = props;
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false);

	const fetchProductsFunc = async () => {
		await fetchProducts({
			entity: 'VARIANTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
			showNotification: true,
		});
	};

	useEffect(() => {
		fetchProductsFunc();
		return () => {
			resetVariants({
				entity: 'VARIANTS',
				method: 'LIST',
				innerField: 'data',
				index: -1,
				data: [],
			});
		};
	}, []);

	const goToDepth = (
		ArrayToBeSearched,
		splits,
		currentDepth,
		searchDepth,
		queryToBeSearched
	) => {
		if (currentDepth + 1 === searchDepth) {
			return ArrayToBeSearched.filter((item) => {
				const valueOfKey = get(item, splits[currentDepth]);
				return (
					!!valueOfKey &&
					valueOfKey
						?.toLowerCase()
						?.indexOf(queryToBeSearched?.trim()?.toLowerCase()) !==
						-1
				);
			});
		}
		return ArrayToBeSearched?.filter((item) => {
			// eslint-disable-next-line max-len
			const isValidLen = goToDepth(
				item[splits[currentDepth]],
				splits,
				currentDepth + 1,
				searchDepth,
				queryToBeSearched
			)?.length;
			return isValidLen > 0;
		});
	};

	const FILTERED_DATA = () => {
		const splits = searchBy?.get.split('.');
		const searchDepth = splits.length;
		const filteredData = goToDepth(data, splits, 0, searchDepth, query);
		return filteredData?.length ? filteredData : [];
	};

	const handleExport = async () => {
		try {
			const response = await variantServices.exportVariantList({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
						variants: true,
					},
				},
			});
			if (response.status === 200) {
				handleResponseStreamDownload(response, 'VARIANTS.csv');
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occurred',
			});
		}
	};

	return (
		<div className='list-page-root'>
			<BackdropLoader showProgress isLoading={isLoading} />
			<VirtualizedListTable
				colMap={COLMAP}
				columns={[...TABLE_COLUMNS, ...SPECIAL_COLUMNS]}
				data={FILTERED_DATA()}
				actions={[...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				fixedHeaderTop={0}
				onExportClick={handleExport}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.VARIANTS.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	resetVariants: formActions.updateFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantsListPage);
