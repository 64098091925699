// import { useState } from 'react';
import { classes } from '../../Helpers';
import styles from './InputWithToggle.module.scss';

function InputWithToggle(props) {
	// eslint-disable-next-line object-curly-newline
	const {
		type,
		name,
		value,
		handleInput,
		isDisabled,
		idx,
		min,
		options,
		selectedToggleValue,
		handleToggle,
		hideToggle = false,
		step,
		toggleType,
		width,
		placeholder = '',
		required,
	} = props;
	return (
		<div
			className={styles.root}
			style={{
				width,
			}}
		>
			{!hideToggle && (
				<div className={styles['toggle-container']}>
					{options?.map((ele) => {
						return (
							<div
								key={ele?.value}
								onClick={() => {
									if (!isDisabled) {
										handleToggle?.(
											ele?.value,
											toggleType,
											idx
										);
									}
								}}
								className={classes(
									styles.toggle,
									selectedToggleValue === ele?.value
										? styles.selected
										: '',
									isDisabled ? styles.disabled : styles.active
								)}
							>
								{ele?.displayName}
							</div>
						);
					})}
				</div>
			)}

			<input
				required={required}
				className={styles.input}
				type={type}
				name={name}
				value={value}
				onInput={handleInput}
				// maxLength={10}
				min={min}
				disabled={isDisabled}
				data-id={idx}
				step={step}
				placeholder={placeholder}
			/>
		</div>
	);
}

export default InputWithToggle;
