/* eslint-disable array-callback-return */
import { ArrowRightAlt, Cancel } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	FormControlLabel,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions, modalActions } from '../../../Actions';
import { inputHelper } from '../../../Helpers';
import aiImage from '../../../Images/ai_image.jpg';
import aiModelCreation from '../../../Services/Form Services/aiModelCreation.services';
import { getCookie } from '../../../Services/Storage Services';
import aiModelConfig from './AIModelCreation.config';
import aiLoader from '../../../Images/ai-loader.gif';
import styles from './AIModelCreation.module.scss';
import { notificationServices } from '../../../Services';
import { ProductListModal } from '../../../Components/Modals';

const modelTypes = [
	{
		type: 'FACE',
		label: 'FACE',
	},
	{
		type: 'FOOD-FEATURE',
		label: 'FOOD FEATURE',
	},
	{
		type: 'FOOD-IDENTIFICATION',
		label: 'FOOD IDENTIFICATION',
	},
];
const modeTypes = [
	{
		type: 'PRODUCTION',
		label: 'INWARD/PRODUCTION',
	},
	{
		type: 'OUTWARD',
		label: 'OUTWARD',
	},
	{
		type: 'WASTAGE',
		label: 'WASTAGE',
	},
	{
		type: 'CMS',
		label: 'CMS',
	},
	{
		type: 'CONTAINER',
		label: 'CONTAINER',
	},
];

function AddChild(props) {
	const { fetchStoresList, stores, toggleProductsModal } = props;
	const [state, setState] = useState({
		...aiModelConfig.initialState,
	});
	const [creationHistory, setCreationHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const [creationStarted, setCreationStarted] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (state?.model === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a model type first!',
			});
			return;
		}
		if (state?.model === 'FACE' && state?.store?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'At least one store should be selected',
			});
			return;
		}
		if (
			state?.model === 'FOOD-IDENTIFICATION' &&
			state?.store?.length === 0 &&
			!state?.allStore
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'At least one store should be selected',
			});
			return;
		}
		if (state?.model === 'FOOD-FEATURE' && state?.product?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a product',
			});
			return;
		}
		if (state?.modeType === 'CMS' && state?.product?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a product in CMS Mode',
			});
			return;
		}
		setLoading(true);
		try {
			const userData = JSON.parse(getCookie('user')) ?? {};
			const req = {
				data: {
					...state,
					product:
						state?.product?.length > 0
							? state?.product[0]?.productId
							: null,
					store: state?.store?.length > 0 ? state?.store : null,
					user: userData?.userDetails?.id,
					userName: userData?.userDetails?.name,
					imsMode: state?.modeType,
				},
			};
			delete req.data.modeType;
			const response = await aiModelCreation.gateway({
				req,
				method: 'CREATE',
			});
			if (response?.statusCode === 200) {
				setCreationHistory(response?.data);
				setCreationStarted(true);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? '',
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.message ?? '',
				});
			}
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? 'Some error Occurred',
			});
		}
	};

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		if (fieldName === 'model') {
			setState({
				...state,
				store: [],
				[fieldName]: fieldValue,
				product: [],
				modeType: '',
			});
			if (fieldValue === 'FOOD-FEATURE') {
				toggleProductsModal(true, {
					itemTypes: ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'],
				});
			}
		} else if (fieldName === 'modeType') {
			setState({
				...state,
				modeType: fieldValue,
				product: [],
			});
			if (fieldValue === 'CMS') {
				toggleProductsModal(true, {
					itemTypes: ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'],
				});
			}
		} else if (fieldName === 'singleStore') {
			setState({
				...state,
				store: [fieldValue],
			});
		} else {
			setState({
				...state,
				[fieldName]: fieldValue,
			});
		}
	};

	const handleProductSelect = (product) => {
		setState({
			...state,
			product,
		});
	};

	const handleRemoveProduct = () => {
		setState({
			...state,
			product: [],
		});
		toggleProductsModal(true, {
			itemTypes: ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'],
		});
	};

	const handleChangeProduct = () => {
		toggleProductsModal(true, {
			itemTypes: ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'],
		});
	};

	const handleAllStore = () => {
		setState({
			...state,
			allStore: state?.allStore ? null : true,
		});
	};

	return (
		<div className={`${styles.root}`}>
			<ProductListModal
				handleConfirm={handleProductSelect}
				selectedProdList={state?.product}
				selectOne
			/>
			<div className={styles.left}>
				<h1 className={styles.header}>AI Model Creation</h1>
				<form onSubmit={handleSubmit}>
					<TextField
						id='storeId'
						value={state?.model}
						select
						label='Select Model type'
						onChange={handleInput}
						name='model'
						className={styles.select}
						disabled={loading}
					>
						{modelTypes?.map((type) => {
							return (
								<MenuItem value={type.type}>
									{type.label}
								</MenuItem>
							);
						})}
					</TextField>
					{(state?.model === 'FOOD-FEATURE' ||
						(state?.model === 'FOOD-IDENTIFICATION' &&
							state?.modeType === 'CMS')) && (
						// eslint-disable-next-line react/jsx-indent
						<div className={styles['product-container']}>
							<p>Selected Product: </p>
							{state?.product?.map((prod) => {
								return (
									<div className={styles['selected-product']}>
										<p>{prod?.product?.productName}</p>
										<div
											className={styles.icon}
											onClick={handleRemoveProduct}
										>
											<Cancel />
										</div>
									</div>
								);
							})}
						</div>
					)}
					{(state?.model === 'FOOD-FEATURE' ||
						(state?.model === 'FOOD-IDENTIFICATION' &&
							state?.modeType === 'CMS')) && (
						// eslint-disable-next-line react/jsx-indent
						<Button
							onClick={handleChangeProduct}
							fullWidth
							variant='outlined'
							color='secondary'
							disabled={loading}
						>
							{state?.product?.length > 0
								? 'Change Product'
								: 'Select product'}
						</Button>
					)}
					{state?.model === 'FACE' ? (
						<TextField
							id='storeId'
							value={state?.store}
							label='Select Store'
							onChange={handleInput}
							name='singleStore'
							select
							className={styles.select}
							disabled={loading}
						>
							{stores?.map((store) => {
								return (
									<MenuItem value={store?.id}>
										{store?.name}
									</MenuItem>
								);
							})}
						</TextField>
					) : (
						state?.model === 'FOOD-IDENTIFICATION' && (
							<>
								<FormControlLabel
									className={styles['all-store-selection']}
									label='All Store'
									control={
										// eslint-disable-next-line react/jsx-wrap-multilines
										<Checkbox
											checked={state?.allStore}
											onChange={handleAllStore}
											disabled={loading}
										/>
									}
								/>
								{!state.allStore && (
									<div
										className={styles['multi-store-select']}
									>
										<InputLabel id='mutiple-select-label'>
											Select Stores
										</InputLabel>
										<Select
											labelId='mutiple-select-label'
											multiple
											name='store'
											value={state?.store}
											onChange={handleInput}
											className={styles.select}
											disabled={loading}
											renderValue={(selected) => {
												// eslint-disable-next-line consistent-return
												const schoolName = stores
													?.filter((ele) => {
														return selected?.includes(
															ele?.id
														);
													})
													.map((store) => {
														return store?.name;
													});

												return schoolName.join(', ');
											}}
										>
											{stores?.map((store) => {
												return (
													<MenuItem value={store?.id}>
														<ListItemIcon>
															<Checkbox
																checked={
																	state?.store?.indexOf(
																		store?.id
																	) > -1
																}
															/>
														</ListItemIcon>
														<ListItemText
															primary={
																store?.name
															}
														/>
													</MenuItem>
												);
											})}
										</Select>
									</div>
								)}
								<TextField
									id='modeType'
									value={state?.modeType}
									select
									label='Select Mode'
									onChange={handleInput}
									name='modeType'
									className={styles.select}
									disabled={loading}
								>
									{modeTypes?.map((type) => {
										return (
											<MenuItem value={type.type}>
												{type.label}
											</MenuItem>
										);
									})}
								</TextField>
							</>
						)
					)}
					{loading ? (
						<div className={styles['ai-loader']}>
							<img src={aiLoader} alt='loader' />
						</div>
					) : (
						<Button
							className={styles['submit-btn']}
							variant='contained'
							type='submit'
						>
							Start Model Creation
							{' '}
							<ArrowRightAlt fontSize='large' />
						</Button>
					)}
				</form>
			</div>
			<div
				style={{
					width: loading ? 0 : '60%',
				}}
				className={styles.right}
			>
				{!loading && creationStarted && (
					<div className={styles['table-container']}>
						<h1 className={styles['table-header']}>
							Model Creation History
						</h1>
						<table>
							<thead>
								<th>Model Type</th>
								<th>Store</th>
								<th>Created at</th>
								<th>Status</th>
								<th>User</th>
								<th>Updated at</th>
							</thead>
							<tbody>
								{creationHistory?.map((createdHis, idx) => {
									return (
										<tr
											className={
												idx % 2 !== 0 && styles.color
											}
										>
											<td>{createdHis?.modelType}</td>
											<td>{createdHis?.storeName}</td>
											<td>{createdHis?.createdAt}</td>
											<td>{createdHis?.status}</td>
											<td>
												{createdHis?.user}
												(
												{createdHis?.userId}
												)
											</td>
											<td>{createdHis?.updatedAt}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
				{!creationStarted && <img src={aiImage} alt='AI' />}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	return {
		stores,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	addChild: formActions.gateway,
	toggleProductsModal: modalActions.toggleProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddChild);
