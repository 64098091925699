/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
/* eslint-disable linebreak-style */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { GeneralDetails } from '../../../Components/Forms/Branding';
import { StoreDesign } from '../../../Components/Forms/Stores';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTab,
	StyledTabs,
	TabPanel,
} from '../../../Components/StyledTabs';
import { generateDisabledProps, history, isEmpty } from '../../../Helpers';
import BrandingConfig from './branding.config';

const TABS = ['GENERAL DETAILS', 'STORE DESIGN'];

const BrandViewPage = (props) => {
	const { data, fetchBrand } = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(BrandingConfig.initialState.fields),
	});
	useEffect(() => {
		fetchBrand({
			entity: 'BRANDING',
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(BrandingConfig.serializer(state, data));
		}
	}, [data]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return <GeneralDetails isDisabled fields={fields} />;
			case 1:
				return <StoreDesign isDisabled fields={fields} />;
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const handleDelete = () => {};
	return (
		<div className='root'>
			<ViewPageHeader
				showDelete={false}
				heading='View Branding'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				showBreadcrumb={false}
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset row'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.BRANDING.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchBrand: formActions.gateway,
	deleteBrand: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandViewPage);
