/* eslint-disable no-tabs */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import analyticsAction from '../../../../Actions/analytics.actions';
import {
	OutwardingImageResults,
	OutwardingImagesFinder,
} from '../../../../Components/Analytics/Outwarding';
import { DefectImageModal } from '../../../../Components/Modals';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './WastageComponentsPage.module.css';
import defectAnalyticsServices from '../../../../Services/Analytics Services/defect-analytics.services';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const WastageComponentsPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		products: [],
		brand: null,
		identification: 'Both',
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
	});

	const {
		allComponentsResults,
		fetchWastageComponents,
		resetWastageComponents,
		updateWastageComponentsById,
	} = props;

	useEffect(() => {
		resetWastageComponents();
	}, []);

	const submitFindRequest = ({
		store = null,
		brand,
		products = [],
		productCategories = [],
		identification,
		page,
		vendors = [],
		dateRange = {},
	}) => {
		resetWastageComponents();
		setFilters({
			store,
			brand,
			products,
			productCategories,
			vendors,
			identification,
			submissions: filters.submissions + 1,
			dateRange,
			page,
		});
		const data = {
			storeId: store,
			brandId: brand,
			products,
			productCategories,
			identification,
			type: 'IDENTITY',
			qcType: 'WASTAGE',
			dateRangeFilter: {
				from: moment(dateRange.from).format('YYYY-MM-DD'),
				to: moment(dateRange.to).format('YYYY-MM-DD'),
			},
		};
		fetchWastageComponents({
			entity: 'WASTAGE_COMPONENTS',
			method: 'FIND_WASTAGE_COMPONENTS',
			req: {
				params: {
					limit: page,
				},
				data,
			},
		});
	};

	const fetchWastageImagesById = async ({ pageNo, productId }, index) => {
		const response = await defectAnalyticsServices.findDefectImagesById({
			params: {
				page: pageNo,
				limit: filters?.page,
			},
			data: {
				storeId: filters.store,
				labelId: productId,
				products: filters.products,
				brandId: filters.brand,
				productCategories: filters.productCategories,
				type: 'IDENTITY',
				qcType: 'WASTAGE',
				identification: filters?.identification,
				dateRangeFilter: {
					from: moment(filters.dateRange.from).format('YYYY-MM-DD'),
					to: moment(filters.dateRange.to).format('YYYY-MM-DD'),
				},
			},
		});

		const { data = [] } = response;
		updateWastageComponentsById(index, data);
		return data;
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	return (
		<div className={styles.root}>
			<DefectImageModal />
			<OutwardingImagesFinder
				storeLabel='WASTAGES OF'
				showPageLimit
				finderFilter={['store']}
				onSubmit={submitFindRequest}
			/>
			<div className={styles['image-results']}>
				{allComponentsResults?.map((product, index) => {
					return (
						product.Annotation.length > 0 && (
							<OutwardingImageResults
								key={product?.id}
								showLoadMore
								fetchImagesById={(req) => {
									fetchWastageImagesById(req, index);
								}}
								product={product}
								clickable
								fetchQCData={fetchQCData}
							/>
						)
					);
				})}
				{allComponentsResults?.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_WASTAGE_COMPONENTS: allComponentsResults } =
		state.analytics.WASTAGE_COMPONENTS;

	return {
		allComponentsResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchWastageComponents: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateWastageComponentsById: (...args) => {
			dispatch(analyticsAction.updateWastageComponentsList(...args));
		},
		resetWastageComponents: () => {
			dispatch(analyticsAction.resetWastageComponents());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WastageComponentsPage);
