/* eslint-disable no-empty */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import {
	useCallback, useEffect, useMemo
} from 'react';
import { connect } from 'react-redux';
import PushPinIcon from '@mui/icons-material/PushPin';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import { Edit, WhereToVote } from '@mui/icons-material';
import { Button } from 'rsuite';
import { MapPinOff } from 'lucide-react';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import ListTable from '../../../../Components/ListTable/ListTable';
import styles from './ListAsset.module.css';
import assetActions from '../../../../Actions/asset.actions';
import { BackdropLoader } from '../../../../Components/Loaders';
import { modalActions } from '@/Actions';
import CoreSpecificationModal from '@/Components/Modals/CoreSpecification/CoreSpecificationModal';
import assetServices from '@/Services/AssetServices/asset.services';

const TABLE_COLUMNS = [
	{
		title: 'Asset Name',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
	{
		title: 'Asset Category Name',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
	{
		title: 'Capacity',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Movable',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
	{
		title: 'Traceble',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
	{
		title: 'Core Specification',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
	{
		title: 'Edit',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DATA',
			style: {
				'min-width': '100px',
			},
		},
	},
];

// const newData = [
// 	{
// 		CategoryName: 'fridge',
// 		totalLimit: 24,
// 		Movable: false,
// 	},
// 	{
// 		CategoryName: 'Tray',
// 		totalLimit: 24,
// 		Movable: true,
// 	},
// 	{
// 		CategoryName: 'Cold room',
// 		totalLimit: 100,
// 		Movable: false,
// 	},
// ];
// const ACTION_COLUMNS = [
// 	{
// 		title: 'View',
// 		config: {
// 			type: 'VIEW',
// 		},
// 	},
// 	{
// 		title: 'Delete',
// 		config: {
// 			type: 'DELETE',
// 		},
// 	},
// ];
const mobileConstants = {
	IMMOVABLE: 'IMMOVABLE',
	MOVABLE: 'MOVABLE',
};

const COLMAP = {
	'Asset Name': 'name',
	'Asset Category Name': 'CategoryName',
	Capacity: 'totalLimit',
	Movable: 'mobileType',
	Traceble: 'traceble',
	'Core Specification': 'coreSpecification',
};

function ListAsset(props) {
	const {
		query,
		assetList,
		fetchAssetList,
		isloading,
		toggleSpecificationModal,
	} = props;
	const history = useHistory();
	const { url } = useRouteMatch();
	const newDataSet = useMemo(() => {
		if (assetList.length > 0) {
			return assetList?.map((ele) => {
				return {
					name: ele?.name,
					id: ele?.id,
					CategoryName: ele?.assetCategory?.name,
					totalLimit: ele?.capacity,
					mobileType: ele?.[COLMAP?.Movable] ?? false,
					traceble: ele?.traceable,
					coreSpecification: ele?.coreSpecifications,
				};
			});
		}
		return [];
	}, [assetList]);

	const handleDelete = async (value) => {
		try {
			const response = await assetServices?.listAssetDelete({
				req: {
					data: {
						id: value?.id,
					},
				},
			});
		} catch (error) {}
		fetchAssetList();
	};

	const handleShowSpecification = (id, item) => {
		const assetName = assetList.find((data) => data?.id === id);
		toggleSpecificationModal(true, {
			id,
			item,
			heading: `Core Specification of ${assetName ? assetName?.name : 'unknown'}`,
		});
	};

	const handleEditAsset = (id) => {
		const assetData = assetList?.find((item) => item?.id === id);
		if (assetData) {
			history.push(`${url}/edit/${id}`, {
				assetData,
			});
		}
	};
	useEffect(() => {
		fetchAssetList({
			req: {},
		});
	}, []);

	const specialColumnsMappings = {
		Movable: (id, Movable) => {
			return (
				<div
					style={{
						width: '9rem',
						display: 'flex',
						alignContent: 'center',
						gap: '8px',
						justifyItems: 'flex-start',
					}}
				>
					{Movable === mobileConstants.MOVABLE ? (
						<>
							<AirlineStopsIcon fontSize='large' />
							<span>movable</span>
						</>
					) : (
						<>
							<PushPinIcon fontSize='large' />
							<span>non movable</span>
						</>
					)}
				</div>
			);
		},
		Traceble: (id, traceble) => {
			return (
				<div
					style={{
						width: '9rem',
						display: 'flex',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'flex-start',
					}}
				>
					{traceble ? (
						<>
							<WhereToVote fontSize='large' />
						</>
					) : (
						<>
							<MapPinOff />
						</>
					)}
				</div>
			);
		},
		'Core Specification': (id, coreSpecification) => {
			return (
				<div
					style={{
						width: '9rem',
						display: 'flex',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'flex-start',
					}}
				>
					{coreSpecification && (
						<Button
							onClick={() => handleShowSpecification(id, coreSpecification)}
						>
							<ListAltIcon style={{
								transition: 'all 0.2s ease-in-out',
								fontSize: '1.5rem',
								'&:hover': {
									fontSize: '1.6rem',
								},
							}}
							/>
						</Button>
					)}
				</div>
			);
		},
		Edit: (id) => {
			return (
				<div
					style={{
						width: '9rem',
						display: 'flex',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'flex-end',
					}}
				>
					{newDataSet && (
						<Button onClick={() => handleEditAsset(id)}>
							<Edit />
						</Button>
					)}
				</div>
			);
		},
	};

	const handleExcelDownload = useCallback((headers, subheaders, totalValues) => {
		const downloadBtn = document.createElement('a');
		const csvData = [
			headers,
			[
				...Array(headers.length - 1).fill(''),
				...subheaders,
			],
		];

		totalValues.forEach((item) => {
			const coreSpecs = item?.coreSpecifications || [];

			if (coreSpecs.length > 0) {
				coreSpecs.forEach((spec) => {
					csvData.push([
						item?.name || '',
						item?.assetCategory?.name || '',
						item?.capacity || '',
						item?.mobileType ? 'MOVABLE' : 'IMMOVABLE',
						item?.traceable ? 'TRUE' : 'FALSE',
						spec?.label || '',
						spec?.dataType || '',
						spec?.uom || '',
						spec?.recivingMandatory ? 'TRUE' : 'FALSE',
						spec?.supplierMandatory ? 'TRUE' : 'FALSE',
						spec?.RFQMandatory ? 'TRUE' : 'FALSE',
					]);
				});
			} else {
				csvData.push([
					item?.name || '',
					item?.assetCategory?.name || '',
					item?.capacity || '',
					item?.mobileType ? 'MOVABLE' : 'IMMOVABLE',
					item?.traceable ? 'TRUE' : 'FALSE',
					'', '', '', '', '', '',
				]);
			}
		});

		const csv = csvData.map((row) => row.join(',')).join('\n');

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = 'Asset_Data.csv';
		downloadBtn.click();
	}, []);

	const dataToExcel = () => {
		const headers = [
			'Asset Name',
			'Asset Category Name',
			'Capacity',
			'Movable',
			'Traceble',
			'Core Specification',
		];
		const subheaders = [
			'label',
			'Data Type',
			'Unit',
			'To be provided time of Receiving',
			'To be provided by Supplier',
			'To be provided with RFQ',
		];
		const totalValues = [];
		assetList?.forEach((item) => {
			totalValues.push(item);
		});
		handleExcelDownload(headers, subheaders, totalValues);
	};

	return (
		<>
			{/* <div className={styles['header-navigator']}>
				<div className=''>
					<Button className={styles.button}>+ Create Asset Category </Button>
				</div>
			</div> */}
			<CoreSpecificationModal />
			<div className={styles.Table}>
				<BackdropLoader showProgress isLoading={isloading} />
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={newDataSet?.filter((ele) => {
						if (
							ele?.name?.toLowerCase().includes(
								query.toLowerCase()
							)
						) {
							return true;
						}
						return false;
					})}
					actions={SPECIAL_COLUMNS}
					special={specialColumnsMappings}
					handleDelete={handleDelete}
					onExportClick={dataToExcel}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const assetList = state.assets.list;
	const { isloading } = state.assets;
	return {
		assetList,
		isloading,
	};
};

const mapDispatchToProps = {
	fetchAssetList: assetActions.fetchAssetList,
	toggleSpecificationModal: modalActions.toggleSpecificationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListAsset);
