import { classes } from '../../Helpers';
import styles from '../POItemSelection/POItemSelection.module.scss';
import VendorTable from './VendorTable';

const defaultColumns = [
	{
		title: 'Asset Type',
		icon: false,
		width: '10%',
	},
	{
		title: 'OEM ',
		icon: false,
		width: '10%',
	},
	{
		title: 'Core Specification',
		icon: false,
		width: '40%',
	},
	{
		title: 'Total Amount',
		icon: false,
		width: '10%',
	},
	{
		title: 'QUANTITY',
		icon: false,
		width: '150px',
	},
];

function VendorCreateTable(props) {
	const {
		row, setRow, oemList, maxQty, oems, assetData,
	} = props;
	// const { appConfig = {} } = useAuth();

	return (
		<div
			className={classes(styles.root)}
			style={{
				width: '85%',
			}}
		>
			<div className={styles['item-row']}>
				<table>
					<thead>
						<tr className='bg-[#d1d1d1]'>
							{defaultColumns?.map((ele) => {
								return (
									<th
										style={{
											width: ele?.width ? ele?.width : '',
											fontSize: 14,
											textAlign: 'center',
										}}
									>
										{ele?.title?.toUpperCase()}
										{' '}
										{ele?.icon}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						<VendorTable
							row={row}
							setRow={setRow}
							oemList={oemList}
							maxQty={maxQty}
							oems={oems}
							assetData={assetData}
						/>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default VendorCreateTable;
