/* eslint-disable no-unused-expressions */
import { connect } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './FileUploaderModal.module.scss';
import DragDropUploader from '../../../Pages/Analytics/Inventory Analytics/UploadPurchaseOrder/DragDropUploader/DragDropUploader';
import { Button } from '@/Components/ui/button';

const FileUploaderModal = (props) => {
	const { show, toggleModal, data } = props;
	const HEADING = `${data.heading}`;
	const [uploadedFile, setUploadedFile] = useState('');

	useEffect(() => {
		if (show && data?.uploadedFile) {
			setUploadedFile(data?.uploadedFile);
		}
	}, [show, data]);

	const handleClose = () => {
		toggleModal(false);
		setUploadedFile('');
	};
	const handleConfirm = () => {
		if (data?.handleConfirm) {
			handleClose();
			data?.handleConfirm(uploadedFile, data?.id);
			setUploadedFile('');
			return;
		}
		props?.handleConfirm(uploadedFile, data?.id);
		setUploadedFile('');
	};
	const buttons = () => {
		return (
			<div className={styles['btn-container']}>
				<Button
					onClick={handleClose}
					variant='destructive'
					className='flex-1'
				>
					<ClearIcon />
					{data?.cancelText ?? 'Cancel'}
				</Button>
				<Button
					onClick={handleConfirm}
					className='primary-btn flex-1'
				>
					<CheckIcon />
					{data?.confirmText ?? 'Proceed'}
				</Button>
			</div>
		);
	};

	return (
		<BaseModal
			heading={HEADING}
			height='36rem'
			renderBtns={buttons}
			open={show}
			handleClose={handleClose}
			width='50rem'
		>
			<DragDropUploader
				uploadType='pdfFile'
				uploadedFile={uploadedFile}
				setUploadedFile={setUploadedFile}
				fileAcceptable='.pdf, application/pdf'
			/>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.fileUploaderModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleFileUploaderModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUploaderModal);
