/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Delete, HelpOutline } from '@mui/icons-material';
import { DragSource, DropTarget } from 'react-dnd';
import draggableTypes from '../../draggable.types';
import styles from './DraggableVContainer.module.scss';

export const VContainer = forwardRef(
	(
		{
			id,
			isDragging,
			connectDragSource,
			connectDropTarget,
			children,
			handleInput,
			handleDelete,
			switchConfig,
			isDraggable,
			isDisabled,
			showDelete,
		},
		ref
	) => {
		const elementRef = useRef(null);
		const opacity = isDragging ? 0 : 1;
		if (isDraggable) {
			connectDragSource(elementRef);
			connectDropTarget(elementRef);
		}
		useImperativeHandle(ref, () => {
			return {
				getNode: () => {
					return elementRef.current;
				},
			};
		});
		return (
			<div
				ref={elementRef}
				style={{
					opacity,
				}}
				className={`${styles.root}`}
			>
				<div className={`${styles['switch-wrapper']}`}>
					<FormControlLabel
						control={
							// eslint-disable-next-line react/jsx-wrap-multilines
							<Switch
								name={switchConfig.name}
								value={switchConfig.name}
								size='small'
								color='primary'
								disabled={isDisabled}
								onChange={handleInput}
								checked={switchConfig.checked[id]}
								inputProps={{
									'data-id': id,
									'data-type': switchConfig.type,
								}}
							/>
						}
						label={switchConfig.label}
					/>
					<Tooltip title='some-text' placement='top-end'>
						<HelpOutline className={`${styles['help-icon']}`} />
					</Tooltip>
				</div>
				{!isDisabled && showDelete && (
					<Tooltip title='Delete Group' placement='left-end'>
						<Delete
							className={`${styles['delete-icon']}`}
							onClick={handleDelete}
							data-id={id}
							data-type={switchConfig.type}
						/>
					</Tooltip>
				)}
				{children}
			</div>
		);
	}
);
export const DraggableVContainer = DropTarget(
	draggableTypes.VCONTAINER,
	{
		hover(props, monitor, component) {
			if (!component) {
				return null;
			}
			// node = HTML Div element from imperative API
			const node = component.getNode();
			if (!node) {
				return null;
			}
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = node.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// Time to actually perform the action
			props.moveCard(dragIndex, hoverIndex);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			monitor.getItem().index = hoverIndex;
		},
	},
	(connect) => {
		return {
			connectDropTarget: connect.dropTarget(),
		};
	}
)(
	DragSource(
		draggableTypes.VCONTAINER,
		{
			beginDrag: (props) => {
				return {
					id: props.id,
					index: props.index,
				};
			},
		},
		(connect, monitor) => {
			return {
				connectDragSource: connect.dragSource(),
				isDragging: monitor.isDragging(),
			};
		}
	)(VContainer)
);
