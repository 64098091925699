// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
// import { urls } from '../../Constants';
import { AssetDashboardPage } from '../../Pages';

const InventoryDashboardRoute = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`}>
				<AssetDashboardPage />
			</Route>
		</Switch>
	);
};

export default InventoryDashboardRoute;
