import _, { get } from 'lodash';

const calculatedExpirationTime = (unit, time) => {
	if (unit === 'days') {
		return time * 24;
	}
	if (unit === 'months') {
		const newTime = time * 24 * 30;
		return newTime;
	}
	return time;
};
/* eslint-disable no-param-reassign */
const InventorySkuConfig = {
	initialState: {
		fields: {
			skuName: '',
			brand: '',
			category: null,
			image: null,
			weight: false,
			temperature: false,
			idealWeight: '',
			idealWeightTol: '',
			storageTemp: '',
			storageTempTol: '',
			trackingQuantity: '',
			trackingQuantityUnit: 'gram',
			costPrice: '',
			sellingPrice: '',
			threshold: 0,
			taxRate: '',
			expires: '',
			unit: 'hours',
			bulkMode: false,
		},
		errors: {
			skuName: '',
			brand: '',
			category: '',
			image: '',
			weight: '',
			temperature: '',
			idealWeight: '',
			idealWeightTol: '',
			storageTemp: '',
			storageTempTol: '',
			trackingQuantity: '',
			trackingQuantityUnit: '',
			costPrice: '',
			sellingPrice: '',
			threshold: '',
			taxRate: '',
			expires: '',
			unit: '',
			bulkMode: '',
		},
	},
	mandatoryFields: {
		skuName: true,
		brand: true,
		category: true,
		image: true,
		weight: false,
		temperature: false,
		idealWeight: true,
		idealWeightTol: true,
		storageTemp: true,
		storageTempTol: true,
		trackingQuantity: true,
		trackingQuantityUnit: true,
		costPrice: true,
		sellingPrice: true,
		threshold: true,
		taxRate: true,
		expires: true,
		unit: true,
		bulkMode: false,
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const idealWt = (data.weightMin + data.weightMax) / 2;
			const ideatTemp = (data.tempMin + data.tempMax) / 2;
			const newState = {
				skuName: data.skuName,
				brand: data.brandId,
				category: data.skuCategoryId,
				image: data.skuImageUrl,
				weight: data.weightCheckRequired,
				temperature: data.tempCheckRequired,
				idealWeight: idealWt,
				idealWeightTol: (
					((idealWt - data.weightMin) / idealWt) *
					100
				).toFixed(2),
				storageTemp: ideatTemp,
				storageTempTol: (
					((ideatTemp - data.tempMin) / ideatTemp) *
					100
				).toFixed(2),
				trackingQuantity: data.unitsInSKU,
				trackingQuantityUnit: data.unit,
				costPrice: data.price,
				sellingPrice: data.sellingPrice,
				threshold: data.threshold,
				taxRate: data.tax,
				expires: data.hoursToExpire,
				unit: 'hours',
				bulkMode: data.isBatchMode,
			};
			return newState;
		});
	},
	deserializer: (state, brands, category) => {
		const newTempMin = (
			state.fields.storageTemp -
			(state.fields.storageTemp * state.fields.storageTempTol) / 100
		).toString();
		const newTempMax = (
			state.fields.storageTemp +
			(state.fields.storageTemp * state.fields.storageTempTol) / 100
		).toString();
		const newState = {
			skuName: state.fields.skuName,
			brandId: parseInt(state.fields.brand, 10),
			skuCategoryId: parseInt(state.fields.category, 10),
			skuImageUrl: state.fields.image,
			weightCheckRequired: state.fields.weight,
			tempCheckRequired: state.fields.temperature,
			weightMin: (
				state.fields.idealWeight -
				(state.fields.idealWeight * state.fields.idealWeightTol) / 100
			).toString(),

			weightMax: (
				state.fields.idealWeight +
				(state.fields.idealWeight * state.fields.idealWeightTol) / 100
			).toString(),
			tempMin: newTempMin === 'NaN' ? '0' : newTempMin,

			tempMax: newTempMax === 'NaN' ? '0' : newTempMax,
			brandName: brands[state.fields.brand].name,
			categoryName: get(
				category.find((obj) => {
					return obj.id === parseInt(state.fields.category, 10);
				}),
				'name',
				''
			),
			unitsInSKU: parseInt(state.fields.trackingQuantity, 10),
			unit: state.fields.trackingQuantityUnit,
			price: state.fields.costPrice.toString(),
			sellingPrice: state.fields.sellingPrice.toString(),
			threshold: parseInt(state.fields.threshold, 10),
			tax: state.fields.taxRate.toString(),
			hoursToExpire: calculatedExpirationTime(
				state.fields.unit,
				parseInt(state.fields.expires, 10)
			),
			isBatchMode: state.fields.bulkMode,
		};
		return newState;
	},
};

export default InventorySkuConfig;
