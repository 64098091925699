import { useMemo } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import analyticsAction from '../../../Actions/analytics.actions';
import { BrandAnalyticsList, BrandTrends } from '../../../Pages';

const BrandAnalysisRoutes = (props) => {
	const { path } = useRouteMatch();

	const { dateRangeFilter, setDateRangeFilter } = props;

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				...dateRangeFilter,
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const setDate = (newValues) => {
		const { startDate: from, endDate: to } = newValues?.selection ?? {};
		setDateRangeFilter({
			active: true,
			from,
			to,
		});
	};

	return (
		<Switch>
			<Route path={`${path}`} exact>
				<BrandAnalyticsList
					dateRangeFilterArray={dateRangeFilterArray}
					setDate={setDate}
				/>
			</Route>
			<Route path={`${path}/:brand`}>
				<BrandTrends
					dateRangeFilterArray={dateRangeFilterArray}
					setDate={setDate}
				/>
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { navState } = state.navigation;
	return {
		navState,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BrandAnalysisRoutes);
