/* eslint-disable no-unneeded-ternary */
/* eslint-disable */
import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper } from '../../../Helpers';
import { BackdropLoader } from '../../../Components/Loaders';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Code',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Enabled',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Name: 'productCategoryName',
	Brand: 'brandName',
	Code: 'productCategoryCode',
	Enabled: 'isEnabled',
};

const ProductCategoryListPage = (props) => {
	const {
		fetchProductCategory,
		data,
		deleteProductCategory,
		toggleDisableProductCategory,
		query,
		navState,
		userList,
		fetchUserList,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const { userConfig } = useAuth();

	// userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu?.['/products/category']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// USER END

	const fetchFoodCategoryFunc = async () => {
		setIsLoading(true);
		await fetchProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		setIsLoading(false);
	};

	useEffect(() => {
		fetchFoodCategoryFunc();
	}, []);

	const handleDelete = ({ id }) => {
		deleteProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	const handleEnable = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		toggleDisableProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					isEnabled: fieldValue,
				},
			},
			method: 'ENABLE',
		});
	};

	const SPECIAL_ITEMS = {
		Enabled: (id, isEnabled) => {
			return (
				<Switch
					name='customization'
					value='customization'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					// disabled={permit ? false : true}
					inputProps={{
						'data-id': id,
						'data-type': 'PRODUCT_CATEGORY',
					}}
				/>
			);
		},
	};

	return (
		<div className='list-page-root'>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				fixedHeaderTop={0}
				data={data
					.filter((category) => {
						return (
							category.productCategoryName
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})
					.map((category) => {
						return {
							...category,
							_name: category?.[COLMAP?.Name],
						};
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_CATEGORY.data.LIST;
	const { navState, userPermissions } = state.navigation;
	const { userList } = state?.auth;
	return {
		data,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProductCategory: formActions.gateway,
	toggleDisableProductCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductCategoryListPage);
