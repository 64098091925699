// eslint-disable-next-line object-curly-newline
import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Ticks } from 'chart.js';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import { analyticsAction } from '../../../../Actions';
import analyticsTypeConstant from '../../../../Constants/analyticsType.contants';
import styles from './InventoryAnalysisGraph.module.scss';

import { CHART_COLORS } from '../../../../Styles/colors';
import {
	classes,
	convertTo24HourFormatTo12Hour,
	getDayName,
	useTimeFilter,
} from '../../../../Helpers';
import { timeFilterMap, urls } from '../../../../Constants';
import { BarChart } from '../../Charts';
import TimeFilter from '../../../TimeFilter/TimeFilter';

const DISCRETE_TIME_FILTERS = [
	{
		text: 'YTD',
		type: 'year',
		active: true,
	},
	{
		text: 'MTD',
		type: 'month',
		active: false,
	},
	{
		text: 'DTD',
		type: 'day',
		active: false,
	},
];

function InventoryAnalysisGraph(props) {
	const {
		timeFilter,
		setAnalyticsType,
		inventoryData,
		listURL,
		applyFilters,
	} = props;

	useEffect(() => {
		setAnalyticsType(
			analyticsTypeConstant.INVENTORY_ANALYTICS_CHART_DATA,
			false
		);
	}, []);

	const history = useHistory();

	const { setTimeFilter, timeFilters } = useTimeFilter({
		DISCRETE_TIME_FILTERS,
		applyFilters,
	});

	const barChartData = useMemo(() => {
		const {
			labels = [],
			failed = [],
			passed = [],
			skipped = [],
			overridden = [],
		} = inventoryData?.chartData ?? {
			labels: [],
			values: [],
		};

		const datasets = [
			{
				data: failed,
				label: 'Failed QCs',
			},
			{
				data: overridden,
				label: 'Overridden Qcs',
			},
			{
				data: skipped,
				label: 'Skipped Qcs',
			},
			{
				data: passed,
				label: 'Passed Qcs',
			},
		];

		return {
			labels,
			datasets: datasets.map((dataset, idx) => {
				return {
					label: dataset.label,
					data: dataset.data,
					...CHART_COLORS[idx],
					maxBarThickness: 120,
				};
			}),
		};
	}, [inventoryData?.chartData]);

	const handleBarChartClick = useCallback(
		(elem) => {
			if (elem.length > 0) {
				const datasetIndex = elem[0].index;
				const selection = barChartData.labels?.[datasetIndex];
				switch (timeFilter.type) {
					case 'year':
						setTimeFilter('month', {
							year: timeFilter.selection.year,
							month: timeFilterMap.month[selection - 1],
							day: null,
							hour: null,
							week: null,
						});
						break;
					case 'month':
						setTimeFilter('day', {
							year: timeFilter.selection.year,
							month: timeFilter.selection.month,
							day: selection,
							hour: null,
							week: null,
						});
						break;
					case 'hour':
						setTimeFilter('hour', {
							year: timeFilter.selection.year,
							month: timeFilter.selection.month,
							day: timeFilter.selection.day,
							hour: selection,
							week: timeFilter.selection.week,
						});
						history.push(
							listURL ??
								`${urls.EXTENSION}${urls.QUALITY_ANALYSIS_LIST}`
						);
						break;
					case 'week':
					case 'day':
						setTimeFilter('hour', {
							year: timeFilter.selection.year,
							month: timeFilter.selection.month,
							week: null,
							day: timeFilter.selection.day,
							hour: selection,
						});
						history.push(
							listURL ??
								`${urls.EXTENSION}${urls.QUALITY_ANALYSIS_LIST}`
						);
						break;
					default:
						break;
				}
			}
		},
		[timeFilter, setTimeFilter]
	);

	const formatTicks = (chartThis, value, index, ticks) => {
		const newValue = chartThis.getLabelForValue(value);
		if (timeFilters[0].active) {
			return timeFilterMap.month[value];
		}
		if (timeFilters[1].active) {
			const date = [
				timeFilters[0].text,
				timeFilterMap.monthNumber[timeFilters[1].text],
				newValue,
			];
			return `${newValue}-${getDayName(
				new Date(...date).getDay()
			)?.substring(0, 3)}`;
		}
		if (timeFilters[2].active) {
			return convertTo24HourFormatTo12Hour(value);
		}
		return Ticks.formatters.values.apply(chartThis, [
			newValue,
			index,
			ticks,
		]);
	};

	return (
		<div className={classes(styles.root)}>
			<Button
				className={classes(styles['list-button'])}
				variant='contained'
				color='primary'
				onClick={() => {
					history.push(
						listURL ??
							`${urls.EXTENSION}${urls.INVENTORY_ANALYSIS_LIST}`
					);
				}}
			>
				View List
			</Button>
			<BarChart
				data={barChartData}
				onClick={handleBarChartClick}
				className={classes(styles.chart)}
				maintainAspectRatio={false}
				title='Inventory Performance'
				xAxisCallback={formatTicks}
				stackX
				stackY
			/>
			<div className={classes(styles.filters)}>
				<TimeFilter timeFitlers={timeFilters} onClick={setTimeFilter} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { DATE_FILTER, loaders, INVENTORY_ANALYTICS } = state.analytics;
	return {
		timeFilter: DATE_FILTER,
		loaders,
		inventoryData: INVENTORY_ANALYTICS.CHART_DATA,
	};
};

const mapDispatchToProps = {
	applyFilters: analyticsAction.applyFilters,
	setAnalyticsType: analyticsAction.setAnalyticsType,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryAnalysisGraph);
