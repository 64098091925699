import { cloneDeep, update } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
// eslint-disable-next-line object-curly-newline
import { formActions, modalActions } from '../../../../Actions';
import { FormSectionHeader } from '../../../../Components/Forms';
import GeneralDetails from '../../../../Components/Forms/Special Menu/GeneralDetails';
import { CreatePageHeader } from '../../../../Components/Headers';
import { modalTypes, urls } from '../../../../Constants';
// eslint-disable-next-line object-curly-newline
import { history, inputHelper, isEmpty } from '../../../../Helpers';
import { notificationServices } from '../../../../Services';
import SpecialMenuConfig from './SpecialMenu.config';

const SpecialMenu = (props) => {
	const handleCancel = () => {
		history.push(`${urls.EXTENSION}${urls.STORE_SETTINGS}`);
	};
	const { id } = useRouteMatch().params;
	const {
		storeData,
		toggleProducts,
		products,
		saveMenus,
		toggleTimeSlots,
		breadcrumbs,
	} = props;
	const [state, setState] = useState({
		...SpecialMenuConfig.initialState,
	});
	const { fields, errors } = state;
	const { mandatoryFields } = SpecialMenuConfig;
	const handleSave = (e) => {
		e.preventDefault();
		const isValid = SpecialMenuConfig.validator(state);
		if (isValid) {
			const data = SpecialMenuConfig.deserializer(state);
			saveMenus({
				entity: 'STORES',
				req: {
					params: {
						id: storeData[id].id,
					},
					data: {
						specialMenu: data,
					},
				},
				method: 'PATCH',
				redirect: `${urls.EXTENSION}${urls.STORE_SETTINGS}`,
			});
		}
	};
	const openTimeSlots = (e) => {
		const { dataset } = inputHelper(e);
		toggleTimeSlots(true, {
			...dataset,
		});
	};

	useEffect(() => {
		if (isEmpty(storeData)) {
			history.push(`${urls.EXTENSION}${urls.STORE_SETTINGS}`);
		}
		if (!isEmpty(storeData[id].specialMenu)) {
			setState(
				SpecialMenuConfig.serializer(state, storeData[id].specialMenu)
			);
		}
	}, []);

	useEffect(() => {
		if (!isEmpty(storeData[id].specialMenu)) {
			setState(
				SpecialMenuConfig.serializer(state, storeData[id].specialMenu)
			);
		}
	}, [storeData]);

	const handleSliderChange = (target, changedValue) => {
		setState(
			update(
				cloneDeep(state),
				`fields.products[${target}].discount`,
				() => {
					return changedValue;
				}
			)
		);
	};

	const openProducts = () => {
		toggleProducts(true, {
			itemTypes: ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'],
		});
	};

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};
	const addNewProduct = (selectedProducts) => {
		const productArray = selectedProducts.map((productObj) => {
			const obj = {
				productId: productObj.productId,
				timeSlots: [],
				discount: 0,
			};
			return obj;
		});
		setState(
			update(cloneDeep(state), 'fields.products', (arr) => {
				const newArr = [...arr, ...productArray];
				return newArr;
			})
		);
	};

	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		switch (dataset.name) {
			case 'time-slot':
				setState(
					update(
						cloneDeep(state),
						`fields.products[${dataset.id}].timeSlots`,
						(arr) => {
							arr.splice(dataset.confirm, 1);
							const newArr = [...arr];
							return newArr;
						}
					)
				);

				break;
			default:
				setState(
					update(cloneDeep(state), 'fields.products', (arr) => {
						arr.splice(dataset.id, 1);
						const newArr = [...arr];
						return newArr;
					})
				);
				break;
		}
	};

	const addNewTimeSlot = ({ timeSlot, config }) => {
		// take the default object of choice/product
		const timeSlotObj = cloneDeep(timeSlot);
		timeSlotObj.startTime = `${moment(timeSlotObj.startTime).format(
			'HH:mm'
		)}`;
		timeSlotObj.endTime = `${moment(timeSlotObj.endTime).format('HH:mm')}`;
		if (
			timeSlotObj.startTime.length > 5 ||
			timeSlotObj.endTime.length > 5
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Time',
			});
		} else {
			setState(
				update(cloneDeep(state), 'fields.products', (arr) => {
					const newArr = [...arr];
					newArr[config.id].timeSlots.push(timeSlotObj);
					return newArr;
				})
			);
		}
	};
	const handleExpiredDateTime = (e) => {
		const timeSlotObj = cloneDeep(e);
		if (moment(timeSlotObj).isValid()) {
			setState(
				update(cloneDeep(state), 'fields.expires', () => {
					return timeSlotObj.toString();
				})
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Date Time',
			});
		}
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={
						!isEmpty(storeData[id].specialMenu)
							? 'Edit Special Menu'
							: 'Create Special Menu'
					}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: !isEmpty(storeData[id].specialMenu)
								? `Edit ${state.fields.name}`
								: 'Create New Special Menu',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						handleInput={handleInput}
						fields={fields}
						errors={errors}
						mandatoryFields={mandatoryFields}
						openProducts={openProducts}
						handleDelete={handleDelete}
						handleSliderChange={handleSliderChange}
						handleSwitchChange={handleInput}
						handleSave={handleSave}
						products={products}
						addNewProduct={addNewProduct}
						selectedProducts={fields.products}
						openTimeSlots={openTimeSlots}
						addNewTimeSlot={addNewTimeSlot}
						handleExpiredDateTime={handleExpiredDateTime}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MASTER_MENUS.data.VIEW;
	const products = state.form.ALL_PRODUCTS.data.LIST;
	const storeData = state.form.STORES.data.LIST;
	return {
		products,
		data,
		storeData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveMenus: (...args) => {
			dispatch(formActions.gateway(...args));
		},
		toggleProducts: (...args) => {
			dispatch(modalActions.toggleProducts(...args));
		},
		toggleTimeSlots: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.TIME_SLOTS, show, data)
			);
		},
	};
};

SpecialMenu.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialMenu);
