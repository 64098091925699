import { connect } from 'react-redux';
import moment from 'moment';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './OrderItemsModal.module.scss';
import { urls } from '../../../Constants';
import { classes } from '../../../Helpers';
import DynamicHeaderTable from '../../DynamicHeaderTable/DynamicHeaderTable';
import locateIcon from '../../../Images/locate.svg';

const OrderItemsModal = (props) => {
	const { show, data, toggleModal } = props;

	const handleClose = () => {
		toggleModal(false);
	};

	const getSubColumnsValues = ({ colId, rowItem }) => {
		if (colId === 2) {
			return rowItem?.count;
		}
		if (typeof colId !== 'number') {
			if (!rowItem?.traceability?.length) {
				return (
					<div className={styles.fields}>
						-
					</div>
				);
			}
			return (
				<div className={styles.fields}>
					{rowItem?.traceability?.map((ele) => {
						if (colId === 'origStockedInAt') {
							return (
								<div className={styles.field}>
									{moment(ele?.origStockedInAt)?.format(
										'YYYY-MM-DD HH:mm A'
									)}
								</div>
							);
						}
						return (
							<div className={classes(styles.field, styles.redirect_container)}>
								{colId === 'trace' ? (
									<div
										onClick={() => {
											window.open(
												// eslint-disable-next-line prefer-template
												urls.EXTENSION +
													urls.ORDER_TRACKNG +
													'?type=qrCode&value=' +
													ele?.qrCode,
												'_blank'
											);
										}}
										className={styles.redirect}
									>
										<p>Click here</p>
										<img src={locateIcon} alt='loate' />
									</div>
								) : (
									ele?.[colId]
								)}
							</div>
						);
					})}
				</div>
			);
		}
		return '-';
	};

	return (
		<BaseModal
			heading={`Order items of ${data?.orderId}`}
			width='60rem'
			height='40rem'
			open={show}
			handleClose={handleClose}
			showConfirm={false}
		>
			{data?.items?.length <= 0 ? <div>No items</div> : <></>}
			<DynamicHeaderTable
				headerData={[
					{
						colName: 'Name & Variant',
						id: 1,
						subCols: [],
					},
					{
						colName: 'Count',
						id: 2,
						subCols: [],
					},
					{
						colName: 'Traceability',
						id: 3,
						subCols: [
							{
								colName: 'Quantity',
								id: 'qty',
								subCols: [],
							},
							{
								colName: 'Manufactured At',
								id: 'origStockedInAt',
								subCols: [],
							},
							{
								colName: 'Trace Item',
								id: 'trace',
								subCols: [],
							},
						],
					},
				]}
				rowsData={data?.items?.map((ele) => {
					return {
						...ele,
						// eslint-disable-next-line prefer-template
						name: ele?.name + '(' + ele?.variant + ')',
						id: ele?.itemId,
					};
				})}
				excludedCols={['Name & Variant']}
				tableDataElement={getSubColumnsValues}
			/>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.orderItemsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleOrderItemsModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsModal);
