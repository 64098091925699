import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const brandingServices = {
	gateway,
	createBrand,
	viewBrands,
	getBrands,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createBrand({
				req,
			});
		case 'VIEW':
			return viewBrands({
				req,
			});
		case 'UPDATE':
			return updateBrand({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createBrand({ req }) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.BRANDING, requestOptions).then(handleResponse);
}

function updateBrand({ req }) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.BRANDING, requestOptions).then(handleResponse);
}

function viewBrands() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.BRANDING, requestOptions).then(handleResponse);
}

function getBrands() {
	const url = toQueryStringURL(endpoints.BRANDING);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default brandingServices;
