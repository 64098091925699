import styles from './MenuDetails.module.scss';

import { generateRequiredProps, generateDisabledProps } from '../../../Helpers';

const MenuDetails = (props) => {
	const { mandatoryFields, isDisabled, menuName, menuDescription } = props;
	const handleInput = (e) => {
		props.handleInput(e);
	};
	return (
		<div className={`${styles.root}`}>
			<fieldset
				{...generateDisabledProps(isDisabled)}
				className='form-fieldset row'
				style={{
					alignItems: 'flex-start',
				}}
			>
				<div
					style={{
						flexBasis: '50%',
					}}
				>
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='menuName'
							data-mandatory={mandatoryFields.name}
						>
							Menu Name
						</label>
						<input
							className='form-value'
							type='text'
							id='menuName'
							name='name'
							{...generateRequiredProps(mandatoryFields.name)}
							onInput={handleInput}
							data-type='menuName'
							value={menuName}
						/>
					</div>
					{/* description */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='description'
							data-mandatory={mandatoryFields.description}
						>
							Menu Description
						</label>
						<textarea
							className='form-value'
							type='text'
							id='description'
							name='description'
							{...generateRequiredProps(
								mandatoryFields.description
							)}
							data-type='menuDescription'
							onInput={handleInput}
							value={menuDescription}
						/>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

MenuDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
};
export default MenuDetails;
