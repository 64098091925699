/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable */
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import SearchBar from '../../Components/SearchBar/SearchBar';
import styles from './ListPage.module.scss';
import { Button } from '@/Components/ui/button';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { notificationServices } from '@/Services';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const CREATE_EXCLUSIONS = [
	'STORE_SETTINGS',
	'PRINTER_CONFIG',
	'VARIANTS',
	'SUBSCRIPTION',
	'NUTRITION',
	'COOKING_PRODUCT_CONFIG',
	'APPROVE_REFUNDS',
	'REFUND_HISTORY',
	// 'REQUEST_REFUND',
	'PAYMENT_SETTLEMENTS',
	'CUSTOMIZATIONS',
	'MY_CUSTOMERS',
	'INVENTORY CONFIG',
	'QUALITY CONFIG',
	'SKU STOCK REPORT',
];

const HEADER_EXCLUSIONS = ['QUALITY_SETTING', 'VENDORS'];

const ListPage = (props) => {
	const {
		entity,
		ListComponent,
		placeholder,
		listPageProps,
		listFilter,
		searchBy,
		userPermissions,
		searchOnClick = false,
		header = false,
		listHeader = '',
		headerName = '',
		type,
		navState,
		userList,
		fetchUserList,
	} = props;
	const { url } = useRouteMatch();
	const { userConfig } = useAuth();
	const history = useHistory();
	const [state, setState] = useState(() => {
		return {
			query: '',
			searchField: searchBy?.options?.[0] != null ? 0 : null,
		};
	});
	const [dummyQuery, setDummyQuery] = useState('');

	// userAcess
	// function pageRoleMap(pageName) {
	// 	switch (pageName) {
	// 		case 'PRODUCTS':
	// 			return navState['/products']?.submenu['/products/all']?.rolesMapped;
	// 		case 'PRODUCT CATEGORY':
	// 			return navState['/products']?.submenu?.['/products/category']?.rolesMapped;
	// 		case 'PRODUCT SUB CATEGORY':
	// 			return navState['/products']?.submenu?.['/products/sub/category']?.rolesMapped;
	// 		case 'COMBOS':
	// 			return navState['/products']?.submenu?.['/products/combos']?.rolesMapped;
	// 		case 'STORES':
	// 			return navState['/outlets']?.submenu['/outlets/stores']?.rolesMapped;
	// 		case 'BRANDS':
	// 			return navState['/brands']?.rolesMapped;
	// 		default:
	// 			return null;
	// 	}
	// }
	// function internalAccess(roleDetails, entity) {
	// 	if (entity === 'PRODUCTS' || entity === 'PRODUCT CATEGORY' || entity === 'PRODUCT SUB CATEGORY' || entity === 'COMBOS') {
	// 		return roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// 	}
	// 	return roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// }
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = pageRoleMap(entity) ?? [];
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = internalAccess(roleDetails, entity);
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	const navigateToAddItem = () => {
		history.push(`${url}/new`);
	};

	const handleInput = (query) => {
		if (searchOnClick) {
			setDummyQuery(query);
		} else {
			setState({
				...state,
				query,
			});
		}
	};

	const handleChange = (searchField) => {
		setState({
			...state,
			searchField,
		});
	};

	const handleClickSearch = () => {
		setState({
			...state,
			query: dummyQuery,
		});
	};

	return (
		<div className={`${styles['list-view-root']} page`}>
			{listHeader && (
				<div className='w-full pt-3 px-4'>
					<p className='text-lg font-semibold'>{listHeader}</p>
				</div>
			)}
			{HEADER_EXCLUSIONS.indexOf(entity) === -1 && (
				<div className={`${styles['list-view-header']}`}>
					{ searchOnClick ? (
						<div style={{
							display: 'flex',
							width: '100%',
							justifyContent: 'space-around',
						}}
						>
							<SearchBar
								handleInput={handleInput}
								handleChange={handleChange}
								searchBy={
									searchBy != null
										? {
											...(searchBy ?? {}),
											current: state.searchField,
										}
										: null
								}
								placeholder={placeholder}
							/>
							<Button
								className='ml-2.5 primary-btn'
								onClick={handleClickSearch}
							>
								Find
							</Button>
						</div>
					) : (
						<div className='search-bar'>
							{header === true ? <p className='text-xl font-medium'>{headerName}</p> : (
								<SearchBar
									handleInput={handleInput}
									handleChange={handleChange}
									searchBy={
										searchBy != null
											? {
												...(searchBy ?? {}),
												current: state.searchField,
											}
											: null
									}
									placeholder={placeholder}
								/>
							)}
						</div>
					)}

					{listFilter && (
						<div className={styles.filter}>{listFilter}</div>
					)}
					{userPermissions?.CREATE &&
						CREATE_EXCLUSIONS.indexOf(entity) === -1 && (
						<Button
							// variant='contained'
							// color='primary'
							className='bg-uy-color text-black'
							onClick={navigateToAddItem}
						>
							{entity === 'Quote' ? 'Raise RFQ' : `+ ADD ${entity}`}
						</Button>
					)}
					{/* {(entity === 'BRANDS' || entity === 'PRODUCTS' || entity === 'STORES' || entity === 'PRODUCT CATEGORY' || entity === 'PRODUCT SUB CATEGORY' || entity === 'COMBOS') && permit && !CREATE_EXCLUSIONS.includes(entity) ? (
						<Button
							className='bg-uy-color text-black'
							onClick={navigateToAddItem}
						>
							{`+ ADD ${entity}`}
						</Button>
					) : (!['BRANDS', 'PRODUCTS', 'STORES', 'PRODUCT CATEGORY', 'PRODUCT SUB CATEGORY', 'COMBOS'].includes(entity) && !CREATE_EXCLUSIONS.includes(entity)) ? (
						<Button
							className='bg-uy-color text-black'
							onClick={navigateToAddItem}
						>
							{entity === 'Quote' ? 'Raise RFQ' : `+ ADD ${entity}`}
						</Button>
					) : null} */}
				</div>
			)}
			<ListComponent
				query={state.query}
				listPageProps={listPageProps}
				searchBy={searchBy?.options?.[state?.searchField]}
				type={type}
			/>
		</div>
	);
};
ListPage.defaultProps = {
	placeholder: 'Search By Name',
};

const mapStateToProps = (state) => {
	const {userPermissions} = state.navigation;
	const { userList } = state.auth;
	return {
		userList,
		userPermissions,
	};
};

const mapDispatchToProps = {
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPage);
