// eslint-disable-next-line no-unused-vars
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router';
// import { IconButton } from '@mui/material';
import { Drawer, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SearchOutlined } from '@mui/icons-material';
import { modalActions, navigationActions } from '../../Actions';
import { classes, objectContainsProperty } from '../../Helpers';
import { urls } from '../../Constants';
import styles from './Sidebar.module.scss';
import SidebarItem from './SidebarItem/SidebarItem';
import Logo from '../../Images/resoee.png';
import { NavRequisitesModal } from '../Modals/NavRequisitesModal';
// import HamburgerToCross from '../Icons/HamburgerToCross/HamburgerToCross';

const container = window !== undefined ? window.document.body : undefined;

// /* eslint-disable */
function Sidebar(props) {
	const pathname = useLocation().pathname.slice(urls.EXTENSION.length);
	const {
		// handleNav,
		expandNav,
		collapseNav,
		navState,
		showSidebar,
		toggleNavItemRequisitesModal,
		toggleSearchModal,
	} = props;
	const history = useHistory();

	const handleNavClick = useCallback(
		(navKey) => {
			return () => {
				const navItem = navState[navKey];
				if (navItem.expand) {
					collapseNav();
				} else {
					expandNav(navKey);
				}
			};
		},
		[navState]
	);

	const handleEditClick = (navKey, navItem) => {
		toggleNavItemRequisitesModal(true, {
			navKey,
			navItem,
		});
	};

	useEffect(() => {
		Object.keys(navState ?? {})?.forEach((navEleKey) => {
			const navEle = navState?.[navEleKey];
			if (navEleKey === pathname) {
				if (navEle?.requisites?.length) {
					navEle?.requisites?.forEach((reqEle) => {
						if (!reqEle?.value) {
							handleEditClick(navEleKey, navEle);
						}
					});
				}
			} else if (navEle?.submenu?.[pathname]) {
				// const subMenuEle = navEle?.submenu?.[pathname];
				if (navEle?.requisites?.length) {
					navEle?.requisites?.forEach((reqEle) => {
						if (!reqEle?.value) {
							handleEditClick(navEleKey, navEle);
						}
					});
				}
			}
		});
	}, [pathname]);

	const handleGenerableNavClick = (generateLink) => {
		history.push(generateLink);
	};

	const renderNavMenus = (navObj) => {
		const navMenuDOM = Object.keys(navObj).reduce((navItemDOM, navKey) => {
			const navItem = navObj[navKey];
			const isExpandable = objectContainsProperty(navItem, 'submenu');
			const isExpanded = navItem.expand;
			const isActive = pathname.indexOf(navKey) > -1;
			const isDisabled = navItem.disabled;

			if (!isDisabled) {
				navItemDOM.push(
					<li
						className={`${styles['nav-item']} ${
							isExpanded ? styles.expanded : ''
						}`}
						key={navKey}
					>
						<SidebarItem
							navItem={navItem}
							navKey={navKey}
							key={navKey}
							isExpandable={isExpandable}
							isExpanded={isExpanded}
							isActive={isActive}
							handleClick={handleNavClick(navKey)}
							handleEditClick={handleEditClick}
							handleGenerableNavClick={handleGenerableNavClick}
						/>
						{objectContainsProperty(navItem, 'submenu') &&
							renderNavMenus(navItem.submenu)}
					</li>
				);
			}
			return navItemDOM;
		}, []);
		return <ul className={styles['nav-list']}>{navMenuDOM}</ul>;
	};

	const toggleSidebar = useCallback(() => {
		// showSidebar is false at the time of opening the sidebari
		// so block scroll along with opening the sidebar
		// if (!showSidebar) {
		// document.body.classList.add(styles['block-scroll']);
		// } else {
		// document.body.classList.remove(styles['block-scroll']);
		// }
		props.toggleSidebar(!showSidebar);
	}, [showSidebar]);

	const drawer = (
		<div
			className={`${styles['sidebar-root']} ${
				showSidebar ? styles.open : ''
			}`}
		>
			<div className={styles['logo-container']}>
				<img className={styles['sidebar-logo']} src={Logo} alt='logo' />
				<IconButton
					onClick={() => {
						toggleSearchModal(true, {});
					}}
					className={styles.search_tab}
				>
					<SearchOutlined />
					Search Tab
				</IconButton>
			</div>
			<div className={styles['sidebar-nav']}>
				{renderNavMenus(navState)}
			</div>
		</div>
	);

	return (
		<>
			<NavRequisitesModal />
			<Drawer
				container={container}
				variant='temporary'
				open={showSidebar}
				onClose={toggleSidebar}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				className={classes(styles.temporary, styles.drawer)}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant='permanent'
				className={classes(styles.permanent, styles.drawer)}
				open
			>
				{drawer}
			</Drawer>
		</>
	);
}

const mapStateToProps = (state) => {
	const { navState, showSidebar } = state.navigation;
	return {
		navState,
		showSidebar,
	};
};

const mapDispatchToProps = {
	handleNav: navigationActions.handleNav,
	expandNav: navigationActions.expandNav,
	collapseNav: navigationActions.collapseNav,
	toggleSidebar: navigationActions.toggleSidebar,
	toggleNavItemRequisitesModal: modalActions.toggleNavItemRequisitesModal,
	toggleSearchModal: modalActions.toggleSearchModal,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
