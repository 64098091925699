import 'rsuite/dist/rsuite.min.css';
import './App.scss';
// eslint-disable-next-line object-curly-newline
import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line object-curly-newline
import { Route, Router, Switch } from 'react-router';
import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect, useDispatch } from 'react-redux';
import { urls } from './Constants';
import AppRoutes from './Routes';
import { history } from './Helpers';
import ProtectedRoute from './Routes/Protected Routes/protected.routes';
import { Login } from './Components/Auth/Login';
import { AuthContext } from './Components/Auth/Login/auth';
import { deleteCookie } from './Services/Storage Services';
import { notificationServices } from './Services';
import authActions from './Actions/auth.actions';
import { BackdropLoader } from './Components/Loaders';
import {
	formActions, modalActions, navigationActions
} from './Actions';
import { SearchModal } from './Components/Modals/SearchModal';
import VendorInvoiceUploadPage from './Pages/VendorInvoiceUploadPage/VendorInvoiceUploadPage';

const theme = createTheme({
	palette: {
		primary: {
			main: '#4a70fb',
		},
		success: {
			main: '#66c24d',
		},
	},
});

function App(props) {
	const {
		forceLogout,
		isLoading,
		toggleLogout,
		updateUserPermissions,
		userPermissions,
		// fetchStoresList,
		// fetchStoresList,
		stores,
		navState,
		handleRemoveAllRequisites,
		showInternalData,
		fetchStaticEnums,
	} = props;

	const [isLoggedIn, setIsLoggedIn] = useState(null);
	const [signout, setSignout] = useState(null);
	const [appConfig, setAppConfig] = useState({});
	const [userConfig, setUserConfig] = useState({});
	const [userStores, setUserStores] = useState([]);
	const dispatch = useDispatch();

	const signIn = useCallback((uConfig) => {
		updateUserPermissions(uConfig);
		setUserConfig(uConfig);
		setSignout(false);
		setIsLoggedIn(true);
	}, [userStores]);

	useEffect(() => {
		if (userStores?.length === 0) {
			if (!userPermissions?.INTERNAL) {
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.MY_ACCOUNT,
						itemToDisable: urls.USERS_PERMISSIONS,
					})
				);
			} else {
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.MY_ACCOUNT,
						itemToEnable: urls.USERS_PERMISSIONS,
					})
				);
			}
			if (!userPermissions?.INTERNAL) {
				dispatch(navigationActions.disableNavItem({
					navItem: urls.ASSET_DASHBOARD,
				}));
			} else {
				dispatch(navigationActions.enableNavItem({
					navItem: urls.ASSET_DASHBOARD,
				}));
			}
			if (userConfig?.store?.storeType !== 'RETAIL') {
				if (
				// eslint-disable-next-line eqeqeq
					appConfig?.clientType && appConfig?.clientType != 'SCHOOL'
				) {
					dispatch(
						navigationActions.disableNavItem({
							navItem: urls.EXTERNAL_SALES_ORDERS,
							itemToDisable: urls.ADD_CREDITS,
						})
					);
					dispatch(
						navigationActions.disableNavItem({
							navItem: urls.MY_ACCOUNT,
							itemToDisable: urls.CHILD_LIST,
						})
					);
				}
			} else if (
				userConfig?.store?.storeId &&
				userConfig?.store?.storeType === 'RETAIL'
			) {
				dispatch(
					navigationActions.handleRetailNavItem({
						excluding: {
							[urls.DASHBOARD]: true,
							[urls.TEMPERATURE_IMAGES]: true,
							[urls.PURCHASE_ORDERS]: true,
							[urls.APP_OUTWARDING_IMAGES]: true,
							[urls.MY_AUDITS]: true,
							[urls.INTERNAL_PURCHASE_ORDERS]: true,
						},
					})
				);
			}
		}
	}, [
		appConfig?.clientType,
		userConfig?.store,
		userPermissions?.INTERNAL,
		userStores,
	]);

	const signOut = useCallback(() => {
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'Logging Out',
		});

		setIsLoggedIn(false);
		setSignout(true);
		setUserConfig({});
		deleteCookie('user');
		deleteCookie('userStores');
		localStorage.clear('userStores');
		toggleLogout(false);
		handleRemoveAllRequisites();
		dispatch(
			navigationActions.resetNavState()
		);
		dispatch(
			navigationActions.resetUserPermissions()
		);
		notificationServices.generateNotification({
			type: 'success',
			message: 'You are logged out',
			config: {
				id: notificationId,
			},
		});
	});

	useEffect(() => {
		if (userStores?.length === 0) {
			if (userConfig?.store?.storeType !== 'RETAIL') {
				if (!userPermissions?.INTERNAL) {
					dispatch(
						navigationActions.disableNavItem({
							navItem: urls.AI_WORKFLOW,
							itemToDisable: urls.AI_STATS,
						})
					);
				} else {
					dispatch(
						navigationActions.enableNavItem({
							navItem: urls.AI_WORKFLOW,
							itemToEnable: urls.AI_STATS,
						})
					);
				}
			}
		}
	}, [userConfig?.store, isLoggedIn, userStores]);

	const authContextValues = {
		signIn,
		signOut,
		signout,
		appConfig,
		setAppConfig,
		setUserConfig,
		userConfig,
		isLoggedIn,
		userStores,
		setUserStores,
	};

	useEffect(() => {
		if (forceLogout) {
			signOut();
		}
	}, [forceLogout]);

	useEffect(() => {
		if (userConfig && userConfig?.store?.storeId && stores?.length > 0) {
			const navs = [urls.PURCHASE_ORDERS, urls.INTERNAL_PURCHASE_ORDERS, urls.SALES_ORDERS];
			navs?.forEach((ele) => {
				const userStore = stores?.find((store) => {
					return store?.id === userConfig?.store?.storeId;
				});
				const newRequisite = navState?.[ele]?.requisites?.map((reqEle) => {
					if (reqEle?.type === 'store' && !reqEle?.value?.id) {
						return {
							...reqEle,
							value: userStore,
						};
					}
					return reqEle;
				});
				dispatch(navigationActions?.handleNavRequisite(ele, newRequisite, '', false));
			});
		}
	}, [userConfig?.store?.storeId, stores?.length]);

	useEffect(() => {
		if (userStores?.length === 0) {
			if (userConfig?.store?.storeType !== 'RETAIL') {
				if (!userPermissions?.INTERNAL) {
					dispatch(
						navigationActions.disableNavItem({
							navItem: urls.AI_WORKFLOW,
							itemToDisable: urls.AI_STATS,
						})
					);
				} else {
					dispatch(
						navigationActions.enableNavItem({
							navItem: urls.AI_WORKFLOW,
							itemToEnable: urls.AI_STATS,
						})
					);
				}
			}
		}
	}, [userPermissions?.INTERNAL, userConfig?.store, isLoggedIn, userStores]);

	useEffect(() => {
		if (userStores?.length === 0) {
			if (userConfig?.store?.storeType !== 'RETAIL') {
			// eslint-disable-next-line eqeqeq
				if (!userPermissions?.CONFIDENTIAL) {
					dispatch(
						navigationActions.disableNavItem({
							navItem: urls.COOKING,
							itemToDisable: urls.COOKING_RECIPE,
						})
					);
				} else {
					dispatch(
						navigationActions.enableNavItem({
							navItem: urls.COOKING,
							itemToEnable: urls.COOKING_RECIPE,
						})
					);
				}
			}
		}
	}, [userPermissions?.CONFIDENTIAL, userConfig?.store, isLoggedIn, userStores]);

	useEffect(() => {
		if (userStores?.length === 0) {
			if (!userPermissions?.INTERNAL) {
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.MY_ACCOUNT,
						itemToDisable: urls.USERS,
					})
				);
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.MY_ACCOUNT,
						itemToDisable: urls.MODULES_CONFIGURATION,
					})
				);
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.LABEL_GENERATOR,
					})
				);
			} else {
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.MY_ACCOUNT,
						itemToEnable: urls.USERS,
					})
				);
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.MY_ACCOUNT,
						itemToEnable: urls.MODULES_CONFIGURATION,
					})
				);
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.LABEL_GENERATOR,
					})
				);
			}
		}
	}, [userPermissions?.INTERNAL, isLoggedIn, userStores]);

	useEffect(() => {
		if (userStores?.length === 0) {
			if (!userPermissions?.CONFIDENTIAL) {
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.VENDORS_CONTAINER,
						itemToDisable: urls.VENDOR_CONTRACT,
					})
				);
			} else {
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.VENDORS_CONTAINER,
						itemToEnable: urls.VENDOR_CONTRACT,
					})
				);
			}
		}
	}, [userPermissions?.CONFIDENTIAL, isLoggedIn, userStores]);

	useEffect(() => {
		if (userStores?.length === 0) {
			if (!userPermissions?.CONFIG_EDIT) {
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.SIMILAR_PRODUCT,
					})
				);
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.REGION_MAPPING,
					})
				);
				dispatch(
					navigationActions.disableNavItem({
						navItem: urls.PRODUCT_PROCESS_MAP,
					})
				);
			} else {
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.SIMILAR_PRODUCT,
					})
				);
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.REGION_MAPPING,
					})
				);
				dispatch(
					navigationActions.enableNavItem({
						navItem: urls.PRODUCT_PROCESS_MAP,
					})
				);
			}
		}
	}, [userPermissions?.CONFIG_EDIT, isLoggedIn, userStores]);

	useEffect(() => {
		fetchStaticEnums();
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<AuthContext.Provider value={authContextValues}>
				<Router history={history}>
					<StyledEngineProvider injectFirst>
						<div className='App-root'>
							{showInternalData && (
								<div className='internal-data'>
									INTERNAL DATA
								</div>
							)}
							<BackdropLoader showProgress isLoading={isLoading} />
							<SearchModal />
							{/* <Notifications /> */}
							<Toaster />
							<DndProvider backend={HTML5Backend}>
								<Switch>
									<Route
										exact
										path={`${urls.EXTENSION}/login`}
									>
										<Login />
									</Route>
									<Route
										exact
										path={`${urls.EXTENSION}/vendor/invoice/upload`}
									>
										<VendorInvoiceUploadPage />
									</Route>
									<ProtectedRoute
										type='A'
										path={`${urls.EXTENSION}`}
										component={AppRoutes}
									/>
								</Switch>
							</DndProvider>
						</div>
					</StyledEngineProvider>
				</Router>
			</AuthContext.Provider>
		</ThemeProvider>
	);
}

const mapStateToProps = (state) => {
	const { forceLogout, isLoading } = state.auth;
	const { userPermissions, navState, showInternalData } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	return {
		forceLogout,
		isLoading,
		userPermissions,
		stores,
		navState,
		showInternalData,
	};
};

const mapDispatchToProps = {
	toggleLogout: authActions.toggleLogout,
	updateUserPermissions: navigationActions.updateUserPermissions,
	handleRemoveAllRequisites: navigationActions.handleRemoveAllRequisites,
	fetchStoresList: formActions.gateway,
	toggleSearchModal: modalActions.toggleSearchModal,
	fetchStaticEnums: authActions.fetchStaticEnums,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
