/* eslint-disable no-tabs */
import { Switch } from '@mui/material';
import { generateCheckedProps, generateRandomKey } from '../../../Helpers';
import styles from './CategoryAvailibility.module.scss';
import SubCategoryAvailibility from './SubCategoryAvaibility';

const CategoryAvailibility = (props) => {
	const {
		categoryDetails,
		activeCategory,
		mandatoryFields,
		isDisabled,
		fields,
		activeSubCategory,
		openProducts,
		setActiveSubCategory,
		handleSubCategoryReOrdering,
		handleSwitchChange,
		MakeUnAvailable,
		handleInput,
	} = props;
	const handleChange = () => {
		handleInput('categories', categoryDetails.id);
	};
	return (
		<div className={`${styles.root}`}>
			<fieldset className='form-fieldset'>
				{/* category name */}
				<div className='form-field row'>
					<label className='form-label' htmlFor='menuCategoryName'>
						{categoryDetails.name}
					</label>
					<Switch
						color='primary'
						size='small'
						className={`${styles.switch}`}
						name='menuCategoryName'
						{...generateCheckedProps(
							!MakeUnAvailable.categories.includes(
								categoryDetails.id
							)
						)}
						{...generateRandomKey()}
						onChange={handleChange}
						inputProps={{
							'data-id': 'idx',
							'data-type': 'choice',
						}}
					/>
				</div>
				<div className={`${styles.subCategory}`}>
					<SubCategoryAvailibility
						subCategoryDetails={
							fields.categories.items[activeCategory]
								.subCategories.items[activeSubCategory]
						}
						handleInput={handleInput}
						handleSubCategoryReOrdering={
							handleSubCategoryReOrdering
						}
						setActiveSubCategory={setActiveSubCategory}
						fields={fields}
						mandatoryFields={mandatoryFields}
						activeCategory={activeCategory}
						activeSubCategory={activeSubCategory}
						openProducts={openProducts}
						selectedProducts={
							fields.categories.items[activeCategory]
								.subCategories.items[activeSubCategory]
								.selectedProducts
						}
						isDisabled={isDisabled}
						handleSwitchChange={handleSwitchChange}
						MakeUnAvailable={MakeUnAvailable}
					/>
				</div>
			</fieldset>
		</div>
	);
};
CategoryAvailibility.defaultProps = {
	isDisabled: false,
};
export default CategoryAvailibility;
