/* eslint-disable no-tabs */
const config = {
	initialState: {
		store: null,
		parentStore: null,
		processType: null,
		brand: null,
		raisedBy: null,
		status: [],
		products: [],
		vendors: [],
		defects: [],
		productCategories: [],
		identificationType: 'AI',
		timeType: 'annotation',
		featureType: 'QUALITY',
		page: 6,
		date: new Date(),
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
		conf: {
			minConf: 0.5,
			maxConf: 1,
		},
	},
	deserializer: (filters) => {
		const req = {};

		req.store = filters?.store;
		req.parentStore = filters?.parentStore;

		req.brand = filters?.brand;

		req.raisedBy = filters?.raisedBy;

		req.status = filters?.status;

		req.vendors = filters?.vendors;

		req.products = filters?.products;

		req.productCategories = filters?.productCategories;

		req.identification = filters?.identificationType;

		req.conf = filters?.conf;

		req.timeType = filters?.timeType;

		req.page = filters?.pageLimit;

		req.date = filters?.date;

		req.dateRange = filters?.dateRange;

		req.labels = filters?.defects;

		req.featureType = filters?.featureType;

		req.processTypes =
			filters?.processTypes !== null ? [filters?.processTypes] : null;

		req.dateRange = filters?.dateRange;

		req.status = filters?.status;

		return req;
	},
};

export default config;
