import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateRequiredProps } from '../../../../Helpers';
import Stores from './ApplicableStores';
import Users from './ApplicableUsers';
import Category from './ApplicableProductCategories';
import Products from './ApplicableProducts';
import NonApplicableCategory from './NonApplicableProductCategories';
import styles from '../PromoCodeDetails.module.scss';
import { formActions } from '../../../../Actions';
import NonApplicableProducts from './NonApplicableProducts';

const Applicability = (props) => {
	const {
		fields,
		handleConfirm,
		handleDelete,
		isDisabled,
		fetchProductCategory,
		fetchStores,
	} = props;

	useEffect(() => {
		fetchStores({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});

		fetchProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		props.fetchProduct({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className={`${styles.root}`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* Applicable users */}
				<Users
					fields={fields}
					isDisabled={isDisabled}
					mandatoryFields
					errors
					handleConfirm={handleConfirm}
					handleDelete={handleDelete}
					generateRequiredProps={generateRequiredProps}
				/>
				<Stores
					fields={fields}
					isDisabled={isDisabled}
					mandatoryFields
					errors
					handleConfirm={handleConfirm}
					handleDelete={handleDelete}
					generateRequiredProps={generateRequiredProps}
				/>
				{/* Applicable Product Categories */}
				<Category
					fields={fields}
					isDisabled={isDisabled}
					mandatoryFields
					errors
					handleConfirm={handleConfirm}
					handleDelete={handleDelete}
					generateRequiredProps={generateRequiredProps}
				/>
				{/* Not Applicable Product Categories */}
				<NonApplicableCategory
					fields={fields}
					isDisabled={isDisabled}
					mandatoryFields
					errors
					handleConfirm={handleConfirm}
					handleDelete={handleDelete}
					generateRequiredProps={generateRequiredProps}
				/>
				{/* Applicable Products */}
				<Products
					fields={fields}
					isDisabled={isDisabled}
					mandatoryFields
					errors
					handleConfirm={handleConfirm}
					handleDelete={handleDelete}
					generateRequiredProps={generateRequiredProps}
				/>
				{/* Not Applicable to Products */}
				<NonApplicableProducts
					fields={fields}
					isDisabled={isDisabled}
					mandatoryFields
					errors
					handleConfirm={handleConfirm}
					handleDelete={handleDelete}
					generateRequiredProps={generateRequiredProps}
				/>
			</div>
		</div>
	);
};

Applicability.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleDelete: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const categories = state.form.PRODUCT_CATEGORY.data.VIEW;
	const products = state.form.ALL_PRODUCTS.data.VIEW;
	return {
		categories,
		products,
	};
};

const mapDispatchToProps = {
	fetchStores: formActions.gateway,
	fetchProductCategory: formActions.gateway,
	fetchProduct: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(Applicability);
