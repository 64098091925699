/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import SalesReturnPage from '../../../../Pages/SalesOrderPages/SalesReturnPage/SalesReturnPage';
import SalesReturnCreatePage from '../../../../Pages/SalesOrderPages/SalesReturnPage/SalesReturnCreatePage/SalesReturnCreatePage';
import { CreatePage } from '../../../../Pages';

const SalesReturnRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				{/* <MyMachinesPage createPath={`${path}/add`} /> */}
				<SalesReturnPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<SalesReturnCreatePage />}
					entity='MACHINES'
				/>
			</Route>
		</Switch>
	);
};

export default SalesReturnRoutes;
