import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import drawerActions from '../../../Actions/drawer.actions';
import BaseDrawer from '../BaseDrawer/BaseDrawer';
import { ListTable } from '../../ListTable';
import styles from './BillsDrawer.module.scss';
import { BackdropLoader } from '@/Components/Loaders';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';

const COLMAP = {
	'Note ID': 'noteId',
	'Note Type': 'noteType',
	'Product Name': 'productName',
	Unit: 'unit',
	Category: 'categoryName',
	'Sub Category': 'categoryName',
	Quantity: 'qty',
	'Item Price per Kg/Pc': 'price',
	'Item Tax Perc.': 'taxPercentage',
	'Total Item Amount': 'finalAmount',
	'Total Note Amount': 'totalNoteAmount',
};

const TABLE_COLUMNS = [
	{
		title: 'Note ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Note Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sub Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Price per Kg/Pc',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Tax Perc.',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Item Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Note Amount',
		config: {
			type: 'DATA',
		},
	},
];

const DebitCreditListDrawer = (props) => {
	const { show, data, toggleDrawer } = props;

	const [isLoading, setIsloading] = useState(false);
	const [debitCreditList, setDebitCreditList] = useState([]);

	const onClose = () => {
		toggleDrawer(false, {});
		setDebitCreditList([]);
	};

	const handleListFetch = async () => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								orderId: data?.poId,
							},
							type: 'CREDIT',
							transactionItems: true,
						},
					},
				});
			if (responseData?.statusCode === 200) {
				// setSelectedStore(store);
				setDebitCreditList(responseData?.data?.reduce((acc, currEle) => {
					currEle?.items?.forEach((currItem) => {
						acc?.push({
							noteType: currEle?.subType?.split('_')?.join(' '),
							noteId: currEle?.id,
							totalNoteAmount: currEle?.finalTransactionAmount,
							// itemCategoryName: currItem?.categoryName,
							...currItem,
						});
					});
					return acc;
				}, []));

				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		if (show) {
			handleListFetch();
		}
	}, [show]);

	return (
		<BaseDrawer header={data?.heading} open={show} onClose={onClose}>
			<BackdropLoader isloading={isLoading} showProgress />
			<div className={styles.list}>
				<ListTable
					allowExport={false}
					allowCheckList={false}
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={debitCreditList}
					actions={[]}
					special={{}}
				/>
			</div>
		</BaseDrawer>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.debitCreditNoteListDrawer;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleDrawer: drawerActions.toggleDebitCreditListDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebitCreditListDrawer);
