import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, handleResponse, toQueryStringURL } from '../../Helpers';

const requestRefundServices = {
	gateway,
};
function gateway({ req, method }) {
	switch (method) {
		case 'VIEW_REFUND':
			return viewRequestRefund({
				req,
			});
		case 'CREATE':
			return createRequestRefund({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createRequestRefund({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.REQUEST_REFUND, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewRequestRefund({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.REQUEST_REFUND, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default requestRefundServices;
