/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IconButton, Switch } from '@mui/material';
import { BreakfastDiningOutlined, Delete } from '@mui/icons-material';
import {
	useCallback, useEffect, useMemo,
} from 'react';
import InputWithToggle from '@/Components/InputWithToggle/InputWithToggle';
import {
	generateRandomKey, getUnitConvertedQuantity, getUnitConvertedUnit, options
} from '@/Helpers';

function ProductVariantsTable(props) {
	const {
		handleDelete,
		variants,
		handleVariantInput,
		state,
		isDisabled,
		measurement,
		handleToggle,
		unitprop = {},
	} = props;
	const { productUnit, skuTypeVariant, purchaseUnit } = unitprop;
	const unit = productUnit || state?.fields?.productUnits?.find((item) => item?.isApplied)?.id;
	const pUnit = purchaseUnit || state?.fields?.purchaseUnits?.find((item) => item?.isApplied)?.id;
	const skuType = skuTypeVariant || state?.fields?.productClass?.find((item) => item?.isApplied)?.id;
	const allowTransferSellingPrice = useMemo(() => {
		return skuType === 'product' || skuType === 'intermediate' || skuType === 'PRODUCT' || skuType === 'INTERMEDIATE';
	}, [skuType]);

	const getPrice = () => {
		if (measurement === 'US') {
			return '$';
		}
		return '₹';
	};

	const getProductUnit = (itemUnit, measurementType) => {
		if (itemUnit === 'count') {
			if (measurementType === 'US') {
				return 'oz';
			}
			return 'gram';
		}
		return itemUnit;
	};

	const handleSelectedValue = (index, toggleType) => {
		const variant = variants[index];
		if (variant?.[toggleType]) {
			return variant[toggleType];
		}
		const defaultValues = {
			minUnit: variants?.minUnit,
			maxUnit: variants?.maxUnit,
			containerUnit: variants?.containerUnit || 'g',
			costPriceUnit: variants?.costPriceUnit,
			sellingPriceUnit: variants?.sellingPriceUnit,
			transferPriceUnit: variants?.transferPriceUnit,
		};
		return defaultValues[toggleType] ?? 'g';
	};

	const getOptions = useCallback((minMaxUnit) => {
		if (minMaxUnit === 'count' || unit === 'count') {
			if (measurement === 'US') {
				return options?.oz;
			}
			return options?.g;
		}
		return options?.[minMaxUnit ?? unit];
	}, [unit]);

	const getPriceOptions = useCallback(
		(variantPriceUnit) => {
			return options?.[variantPriceUnit];
		},
		[variants?.costPriceUnit, variants?.sellingPriceUnit, variants?.transferPriceUnit]
	);

	const getContainerOpt = useCallback((containerWeightUnit) => {
		if (
			unit === 'count' ||
			unit === 'litre' ||
			unit === 'ml' ||
			unit === 'floz'
		) {
			if (measurement === 'US') {
				return options?.oz;
			}
			return options?.g;
		}
		return options?.[containerWeightUnit];
	}, [unit]);

	const getInputStep = (unit) => {
		if (unit === 'lbs') return 'any';
		if (unit === 'oz' || unit === 'floz' || unit === 'gal') return '.01';
		if (unit === 'kg') return '.001';
		return '';
	};

	return (
		<div className='w-full overflow-auto'>
			<table className='w-full border-collapse'>
				<thead>
					<tr className='bg-[#c4c4c4]'>
						<th>Size</th>
						<th>Default</th>
						<th>Enable</th>
						<th>UY Variant ID</th>
						<th>Display Name</th>
						<th>Client Variant ID</th>
						<th>Min</th>
						<th>Max</th>
						<th>Container Weight</th>
						<th>Serving Qty(%)</th>
						<th>Unit Conversion Ratio</th>
						<th>MOQ</th>
						<th>Cost price(₹)</th>
						{allowTransferSellingPrice && (
							<>
								<th>
									Transfer Price(
									{getPrice()}
									)
								</th>
								<th>
									Selling Price(
									{getPrice()}
									)
								</th>
							</>
						)}
						<th>Delete</th>
					</tr>
				</thead>
				<tbody className='w-full'>
					{variants?.map((variant, index) => {
						const minWeightOptions = getOptions(variant?.minUnit);
						const maxWeightOptions = getOptions(variant?.minUnit);
						const containerUnitOptions = getContainerOpt(variant?.containerUnit);
						return (
							<tr className='border-b border-[#c4c4c4]'>
								<td className='text-center p-8 '>
									<label className='w-12 block'>
										Size

										{index + 1}
									</label>
								</td>
								<td className='p-4'>
									<Switch
										key={generateRandomKey().key}
										name='isDefault'
										checked={variant.isDefault}
										onChange={handleVariantInput}
										disabled={isDisabled}
										inputProps={{
											'data-id': index,
										}}
										size='small'
										color='primary'
									/>

								</td>
								<td className='p-4'>
									<Switch
										key={generateRandomKey().key}
										name='isDisabled'
										size='small'
										color='primary'
										onChange={handleVariantInput}
										defaultChecked={variant.isDisabled}
										disabled={isDisabled}
										inputProps={{
											'data-id': index,
										}}
									/>
								</td>
								<td className='p-4'>{variant.id}</td>
								<td className='p-4'>
									<input
										className='max-w-[60px] text-center border border-[#c4c4c4] rounded-md p-2 font-sm outline-none'
										type='text'
										placeholder='Display Name'
										name='displayName'
										required
										value={variant.displayName}
										onInput={handleVariantInput}
										disabled={isDisabled}
										maxLength={10}
										data-id={index}
									/>
								</td>
								<td className='p-4'>
									<input
										className='min-w-[9rem] font-sm max-w-[60px] text-center border border-[#c4c4c4] rounded-md p-2 font-sm outline-none'
										type='text'
										placeholder='Client Variant ID'
										name='clientVariantId'
										value={variant?.clientVariantId}
										onInput={handleVariantInput}
										maxLength={25}
										disabled={isDisabled}
										data-id={index}
									/>
								</td>
								<td className='p-4'>
									<InputWithToggle
										width='75px'
										type='number'
										name='minWeight'
										value={variant.minWeight}
										handleInput={handleVariantInput}
										options={minWeightOptions}
										min={0}
										idx={index}
										isDisabled={isDisabled}
										toggleType='minUnit'
										selectedToggleValue={handleSelectedValue(index, 'minUnit')}
										handleToggle={handleToggle}
										step={getInputStep(
											variants?.minUnit
										)}
										required
									/>
								</td>
								<td className='p-4'>
									<InputWithToggle
										width='75px'
										type='number'
										name='maxWeight'
										value={variant.maxWeight}
										handleInput={handleVariantInput}
										min={0}
										idx={index}
										isDisabled={isDisabled}
										options={maxWeightOptions}
										toggleType='maxUnit'
										selectedToggleValue={handleSelectedValue(index, 'maxUnit')}
										handleToggle={handleToggle}
										step={getInputStep(
											variants?.maxUnit
										)}
										required
									/>
								</td>
								<td className='p-4'>
									<InputWithToggle
										width='75px'
										type='number'
										name='containerWeight'
										isDisabled={isDisabled}
										value={variant.containerWeight}
										handleInput={handleVariantInput}
										options={containerUnitOptions}
										min={0}
										idx={index}
										selectedToggleValue={handleSelectedValue(index, 'containerUnit')}
										handleToggle={handleToggle}
										toggleType='containerUnit'
										step={
											variants?.containerUnit === 'lbs'
												? 'any'
												: ''
										}
									/>
								</td>
								<td className='p-4'>
									<input
										className='max-w-[60px] text-center border border-[#c4c4c4] rounded-md p-2 font-sm outline-none'
										type='number'
										name='servingQtyPercentage'
										required
										value={variant.servingQtyPercentage}
										onInput={handleVariantInput}
										disabled={isDisabled}
										min={1}
										data-id={index}
									/>
								</td>
								<td className='p-4'>
									<div className='flex flex-col items-center justify-center mx-auto'>
										<span className='text-sm w-[8rem] relative bottom-2'>
											{(pUnit && unit)
												? `1 ${pUnit} = ${variant?.factorPurchaseUnit} ${unit}`
												: ''}
										</span>
										<input
											className='max-w-[60px] text-center border border-[#c4c4c4] rounded-md p-2 font-sm outline-none'
											type='number'
											name='factorPurchaseUnit'
											required
											value={variant.factorPurchaseUnit}
											onInput={handleVariantInput}
											disabled={isDisabled}
											min={1}
											data-id={index}
										/>
									</div>
								</td>
								<td className='p-4'>
									<div className='flex flex-col items-center justify-center mx-auto'>
										<span className='text-sm w-[8rem] relative bottom-2 text-center'>
											{unit
												? `Values are in ${unit}`
												: ''}
										</span>
										<input
											className='max-w-[60px] text-center border border-[#c4c4c4] rounded-md p-2 font-sm outline-none'
											type='text'
											name='pieces'
											required
											value={variant?.pieces}
											onInput={handleVariantInput}
											disabled={isDisabled}
											min={0}
											defaultValue={0}
											data-id={index}
										/>
									</div>
								</td>
								<td className='p-4'>
									<InputWithToggle
										width='75px'
										type='number'
										name='costPrice'
										required
										value={variant?.calculatedRecipeCost ? variant?.calculatedRecipeCost : variant?.costPrice}
										options={getPriceOptions(variant?.costPriceUnit)}
										selectedToggleValue={handleSelectedValue(index, 'costPriceUnit')}
										handleInput={handleVariantInput}
										min={0}
										idx={index}
										isDisabled={variant?.calculatedRecipeCost ? true : isDisabled}
										handleToggle={handleToggle}
										toggleType='costPriceUnit'
										step='any'
									/>
									{variant?.calculatedRecipeCost > 0 && (
										<span className='text-[10px] mt-[3px] block'>Calculated from recipe ₹/pc</span>
									)}
								</td>
								{allowTransferSellingPrice && (
									<>
										<td className='p-4'>
											<InputWithToggle
												width='75px'
												type='number'
												name='transferPrice'
												required
												value={variant.transferPrice}
												handleInput={handleVariantInput}
												options={getPriceOptions(variant?.transferPriceUnit)}
												// maxLength={10}
												isDisabled={isDisabled}
												handleToggle={handleToggle}
												selectedToggleValue={handleSelectedValue(index, 'transferPriceUnit')}
												min={0}
												idx={index}
												toggleType='transferPriceUnit'
												step='any'
											/>
										</td>
										<td className='p-4'>
											<InputWithToggle
												width='75px'
												type='number'
												name='sellingPrice'
												required
												value={
													variant?.sellingPrice
												}
												options={getPriceOptions(variant?.sellingPriceUnit)}
												handleInput={handleVariantInput}
												// maxLength={10}
												isDisabled={isDisabled}
												min={0}
												idx={index}
												handleToggle={handleToggle}
												selectedToggleValue={handleSelectedValue(index, 'sellingPriceUnit')}
												toggleType='sellingPriceUnit'
												step='any'
											/>
										</td>
									</>
								)}
								<td className='p-4'>
									<IconButton
										onClick={handleDelete}
										data-id={index}
									>
										<Delete />
									</IconButton>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default ProductVariantsTable;
