import reportsConstants from '../Constants/reports.constants';

const initialState = {
	list: [],
	isLoading: false,
	search: {
		searchBy: '',
		query: '',
	},
};

const reportsReducers = (state = initialState, action) => {
	switch (action?.type) {
		case reportsConstants.REPORTS_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				// list: [],
			};
		case reportsConstants.REPORTS_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				list: action?.payload,
			};
		case reportsConstants.REPORTS_LIST_FAILURE:
			return {
				...state,
				isLoading: false,
				// list: [],
			};
		case reportsConstants.REPORTS_LIST_SEARCH:
			return {
				...state,
				search: {
					searchBy: 'email',
					query: action?.payload,
				},
			};
		default:
			return state;
	}
};

export default reportsReducers;
