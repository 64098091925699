import {
	Route, Switch, useRouteMatch
} from 'react-router';
import ModulesConfiguration from '../../../Pages/Accounts/ModulesConfiguration/ModulesConfiguration';

const ModulesConfigurationRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<ModulesConfiguration />
			</Route>
		</Switch>
	);
};

export default ModulesConfigurationRoutes;
