// import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import analyticsAction from '../../../../Actions/analytics.actions';
// import { DefectImageResult } from '../../../../Components/Analytics/Defect';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import { ImagesResult } from '../../../../Components/ImagesResult';
import { DefectImageModal } from '../../../../Components/Modals';
import { modalTypes, urls } from '../../../../Constants';
import containerAnalyticsServices from '../../../../Services/Analytics Services/container-analytics.services';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './ContainerImagesPage.module.scss';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const FIND_FILTERS = ['pageLimit'];

const ContainerImagesPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		brand: null,
		featureType: 'QUALITY',
		defects: [],
		products: [],
		vendors: [],
		productCategories: [],
		identification: 'Both',
		submissions: 0,
		timeType: '',
		pageLimit: 6,
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
	});

	const {
		allContainerResults,
		fetchContainerImages,
		updateContainerImagesList,
		resetContainerImages,
		toggleModal,
		userPermissions,
	} = props;

	useEffect(() => {
		resetContainerImages();
	}, []);

	const submitFindRequest = ({
		store = null,
		brand,
		featureType,
		defects = [],
		products = [],
		productCategories = [],
		identification,
		timeType,
		page: pageLimit,
		vendors = [],
		dateRange = {},
	}) => {
		resetContainerImages();
		setFilters({
			store,
			brand,
			featureType,
			defects,
			products,
			vendors,
			productCategories,
			identification,
			submissions: filters.submissions + 1,
			timeType,
			pageLimit,
			dateRange,
		});
		fetchContainerImages({
			entity: 'CONTAINER_ANALYSIS',
			method: 'FIND_CONTAINER_IMAGES',
			req: {
				params: {
					limit: pageLimit,
				},
				data: {},
				// storeId: store,
				// brandId: brand,
				// labels: defects,
				// products,
				// vendors,
				// productCategories,
				// identification,
				// type: featureType,
				// dateRangeFilter: dateRange?.active
				// ? {
				// timeType,
				// from: moment(dateRange?.from).format('YYYY-MM-DD'),
				// to: moment(dateRange?.to).format('YYYY-MM-DD'),
				// }
				// : {},
				// },
			},
		});
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	const fetchContainerImagesById = async ({ page, id: containerId }, index) => {
		const response = await containerAnalyticsServices.fetchImagesById({
			params: {
				page,
				limit: filters?.pageLimit,
			},
			data: {
				containerId,
			},
			// labelId: defectId,
			// storeId: filters.store,
			// products: filters.products,
			// brandId: filters.brand,
			// vendors: filters.vendors,
			// productCategories: filters.productCategories,
			// identification,
			// type: filters?.featureType,
			// dateRangeFilter: filters?.dateRange?.active
			// ? {
			// timeType: filters?.timeType,
			// from: moment(filters?.dateRange.from).format('YYYY-MM-DD'),
			// to: moment(filters?.dateRange.to).format('YYYY-MM-DD'),
			// }
			// : {},
			// },
		});

		const { data = [] } = response;
		updateContainerImagesList(index, data);
		return data;
	};

	const onItemClick = async ({ annotation, item: product }) => {
		const qcData = await fetchQCData(annotation.qcId);
		toggleModal(true, {
			source: annotation.image,
			name: qcData?.ProductName,
			product,
			date: qcData?.QCTime,
			link:
				qcData != null
					? `${urls.EXTENSION}${urls.PAST_QC}/${qcData?.itemId}?id=${qcData?.id}`
					: null,
		});
	};

	return (
		<div className={styles.root}>
			<DefectImageModal />
			<ImagesFinder FILTERS={FIND_FILTERS} onSubmit={submitFindRequest} />
			<div className={styles['image-results']}>
				{allContainerResults.map((defect, index) => {
					return (
						<ImagesResult
							key={`${defect.id}${filters.submissions}`}
							identification={filters?.identification}
							// fetchQCData={fetchQCData}
							fetchMoreImages={(req) => {
								return fetchContainerImagesById(req, index);
							}}
							currentItem={defect}
							onItemClick={onItemClick}
							isInternal={userPermissions.INTERNAL}
						/>
					);
				})}
				{allContainerResults.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_CONTAINER_IMAGES: allContainerResults } =
		state.analytics.CONTAINER_ANALYSIS;
	const { userPermissions } = state.navigation;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	return {
		allContainerResults,
		dateRangeFilter,
		userPermissions,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchContainerImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateContainerImagesList: (...args) => {
			dispatch(analyticsAction.updateContainerImagesList(...args));
		},
		resetContainerImages: () => {
			dispatch(analyticsAction.resetContainerImages());
		},
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContainerImagesPage);
