import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './BrandAnalyticsList.module.scss';
import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import { ListTable } from '../../../../Components/ListTable';
import analyticsAction from '../../../../Actions/analytics.actions';
import SearchBar from '../../../../Components/SearchBar/SearchBar';
import { useSearch, history } from '../../../../Helpers';
import { urls } from '../../../../Constants';

const TABLE_COLUMNS = [
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Items Count',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Defects Count',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Defect %',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [];

const COLMAP = {
	Brand: 'brandName',
	'Items Count': 'items',
	'Defects Count': 'defects',
	'Defect %': 'defectPercent',
	_id: 'brand',
};

const SPECIAL = {
	'Defect %': (val, item) => {
		const percent = (
			(item?.[COLMAP['Defects Count']] / item?.[COLMAP['Items Count']]) *
			100
		).toFixed(2);
		return `${percent ?? '-'}%`;
	},
};

const BrandAnalyticsList = (props) => {
	const { dateRangeFilterArray, dateRangeFilter, setDate, data, fetchData } =
		props;

	useEffect(() => {
		if (dateRangeFilter.from != null && dateRangeFilter.to != null) {
			fetchData({
				entity: 'BRAND_ANALYSIS',
				method: 'LIST_DATA',
				req: {
					data: {
						filters: {
							dateRangeFilter: {
								from: moment(dateRangeFilter.from).format(
									'YYYY-MM-DD'
								),
								to: moment(dateRangeFilter.to).format(
									'YYYY-MM-DD'
								),
							},
						},
					},
				},
			});
		}
	}, [dateRangeFilter]);

	useEffect(() => {
		if (data.length === 1) {
			history.push(
				`${urls.EXTENSION}${urls.BRAND_TRENDS}/${
					// eslint-disable-next-line no-underscore-dangle
					data?.[0]?.[COLMAP._id]
				}`
			);
		}
	}, [data]);

	const { query, handleSearch } = useSearch();

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<SearchBar
					value={query}
					placeholder='Search by Brand Name'
					handleInput={handleSearch}
					className={styles.search}
				/>
				<Calender value={dateRangeFilterArray} setValue={setDate} />
			</div>
			<div className={styles.body}>
				<ListTable
					fixedHeaderTop={0}
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data
						.filter((obj) => {
							return (
								obj?.[COLMAP.Brand]
									?.toLowerCase?.()
									?.indexOf(query.trim().toLowerCase()) !== -1
							);
						})
						.map((obj, idx) => {
							return {
								...obj,
								id: idx,
								onClick: () => {
									history.push(
										`${urls.EXTENSION}${
											urls.BRAND_TRENDS
											// eslint-disable-next-line no-underscore-dangle
										}/${obj?.[COLMAP._id]}`
									);
								},
							};
						})}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST_DATA: data } = state.analytics.BRAND_ANALYSIS;
	return {
		data,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	fetchData: analyticsAction.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandAnalyticsList);
