// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import UtilitiesCreatePage from '../../Pages/UtilitiesPage/UtilitiesCreatePage/UtilitiesCreatePage';
import UtilitiesPage from '../../Pages/UtilitiesPage/UtilitiesPage';
import NotAccessiblePage from '../../Pages/NotAccessable/NotAccessable';

const UtilitiesPageRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<UtilitiesPage />
			</Route>
			<Route exact path={`${path}/add`}>
				<UtilitiesCreatePage />
			</Route>
			<Route path='*'>
				<NotAccessiblePage />
			</Route>
		</Switch>
	);
};

export default UtilitiesPageRoutes;
