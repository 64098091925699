import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import { WastageHistoryPage, WastageImagesPage } from '../../../Pages';
import WastageComponentsPage from '../../../Pages/Analytics/WastageAnalysis/WastageComponentsPage/WastageComponentsPage';

const getSubURL = (entity) => {
	const baseEntity = urls.WASTAGE_CONTAINER;
	return entity.slice(baseEntity.length);
};

const WastageAnalyticsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.WASTAGE_HISTORY_LIST)}`}>
				<WastageHistoryPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.WASTAGE_IMAGES)}`}>
				<WastageImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.WASTAGE_COMPONENTS)}`}>
				<WastageComponentsPage />
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

export default connect(mapStateToProps)(WastageAnalyticsRoutes);
