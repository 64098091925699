import { Switch } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper } from '../../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'Rider Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Rider Contact No.',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Start Time',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'End Time',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Rider Available',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];
const SPECIAL_COLUMS = [
	{
		title: 'Rider Enabled',
		config: {
			type: 'UPDATE',
		},
	},
];

const COLMAP = {
	'Rider Name': 'name',
	'Rider Contact No.': 'phoneNumber',
	'Start Time': 'riderAvailabilityStartAt',
	'End Time': 'riderAvailabilityEndAt',
	'Rider Available': 'riderAvailable',
	'Rider Enabled': 'riderEnable',
};

const RiderFormListPage = (props) => {
	const { fetchRider, data, query, toggleRiderAvailibilty, deleteRider } =
		props;
	const handleEnable = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		toggleRiderAvailibilty({
			entity: 'RIDERS',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					riderEnable: fieldValue,
				},
			},
			method: 'ENABLE',
		});
	};
	const handleDelete = ({ id }) => {
		deleteRider({
			entity: 'RIDERS',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};
	const SPECIAL_ITEMS = {
		'Rider Enabled': (id, isEnabled) => {
			return (
				<Switch
					name='customization'
					value='customization'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'RIDERS',
					}}
				/>
			);
		},
		'Rider Available': (id) => {
			return <span>{id ? 'Yes' : 'No'}</span>;
		},
	};
	useEffect(() => {
		fetchRider({
			entity: 'RIDERS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className='list-page-root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.filter((rider) => {
						return (
							rider.name
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})
					.map((rider) => {
						return {
							...rider,
							_name: rider?.[COLMAP?.['Rider Name']],
						};
					})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.RIDERS.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchRider: formActions.gateway,
	deleteRider: formActions.gateway,
	toggleRiderAvailibilty: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(RiderFormListPage);
