import notificationServices from './notification.services';
import authActions from '../Actions/auth.actions';
import { store } from '../Helpers';

const handleResponse = (response) => {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (
			data.status === 'Failed' ||
			data.errorMessage ||
			// (data?.statusCode && data.statusCode !== 200) ||
			(data.responseCode && data.responseCode !== 'SS-001')
		) {
			let error =
				data.errorMessage || data.message || data.responseMessage;
			if (
				error === 'Auth_Invalid' ||
				error === 'Unauthorized Access' ||
				data.statusCode === 403
			) {
				error = 'Your session has expired';
				store.dispatch(authActions.toggleLogout(true));
			}
			// if it's not a loading notification, display error message
			if (!store.getState().auth.isLoading) {
				store.dispatch(authActions.toggleLoading(false));
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
			}
			if (data.statusCode === 413) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Data is too large',
				});
			}
			return Promise.reject(error);
		}
		return data;
	});
};

export default handleResponse;
