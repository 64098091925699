/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { OpenInNew } from '@mui/icons-material';
import styles from './TransactionsModal.module.scss';
import { BaseModal } from '../BaseModal';
import { formActions, modalActions } from '../../../Actions';
import config from './transactionsModal.config';
import { ListTable } from '../../ListTable';
import { classes } from '../../../Helpers';

const TransactionsModal = (props) => {
	const { show, toggleModal, data, handleRedirect } = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const [tableConstants, setTableConstants] = useState({});

	useEffect(() => {
		if (show) {
			setTableConstants(
				config?.TABLE_DATA(data?.transactionType, data?.type)
			);
		}
	}, [show, data?.transactionType, data?.type]);

	const handleClose = () => {
		toggleModal(false, {});
	};

	const renderBtns = useCallback(() => {
		return <div className={styles['btns-container']} />;
	}, [state]);

	const specialItems = {
		'Bill Type': (id) => {
			const rowItem = data?.listData?.find((ele) => {
				return ele?.id === id;
			});
			const type = rowItem?.parentRowId ? 'GRN' : 'ADVANCE';
			return (
				<div className={classes(styles.type, styles?.[type])}>
					{type}
				</div>
			);
		},
		...tableConstants?.SPECIAL_ITEMS,
	};

	return (
		<BaseModal
			open={show}
			heading={data?.heading ?? 'Transaction Data'}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='50%'
			height='35rem'
		>
			<div className={styles.root}>
				<ListTable
					colMap={tableConstants?.Colmap}
					columns={tableConstants?.TABLE_COLUMNS}
					// allowCheckList
					allowExport={false}
					data={data?.listData}
					special={specialItems}
					fixedHeaderTop={0}
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.transactionsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleTransactionsModal,
	saveVendor: formActions.gateway,
	fetchVendors: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsModal);
