// eslint-disable-next-line object-curly-newline
import { useState } from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { ImsStockFilter } from '../../../Components/ImsStockFilter';
import { SkuStockReport } from '../../../Pages/Analytics/Inventory Analytics/SkuStockReport';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const SkuStockReportRoutes = () => {
	const { path } = useRouteMatch();
	const [skuType, setSkuType] = useState('ALL');
	const [stockDeficit, setStockDeficit] = useState(false);
	return (
		<Switch>
			<CRUDRoutes
				path={path}
				entity='SKU STOCK REPORT'
				listPage={(...args) => {
					return (
						<SkuStockReport
							stockDeficit={stockDeficit}
							skuType={skuType}
							{...args[0]}
						/>
					);
				}}
				listFilter={(
					<ImsStockFilter
						stockDeficit={stockDeficit}
						setStockDeficit={setStockDeficit}
						skuType={skuType}
						setSkuType={setSkuType}
					/>
				)}
			/>
		</Switch>
	);
};

export default SkuStockReportRoutes;
