import styles from './ItemsTable.module.scss';

const ItemsTable = (props) => {
	const { order } = props;
	return (
		<table className={`${styles['list-table-root']}`}>
			<thead>
				<tr>
					<th>Item Id</th>
					<th>Name</th>
					<th>Status</th>
					<th>Price(₹)</th>
				</tr>
			</thead>
			<tbody>
				{order &&
					order.map((item) => {
						return (
							<tr>
								<td>{item.itemId}</td>
								<td>{item.name}</td>
								<td>{item.status}</td>
								<td>{item.price}</td>
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};
export default ItemsTable;
