/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import styles from './SalesReturnPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import myMachineService from '../../../Services/AssetServices/myMachine.service';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../../Components/Modals/POItemsModal';
import { PaymentModal } from '../../../Components/Modals/PaymentModal';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { urls } from '../../../Constants';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sales Return ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'From Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Return Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Returned',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Refunded Amount',
	// config: {
	// type: 'DATA',
	// },
	// },
];

const SPECIAL_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MORE',
		config: {
			type: 'MORE',
		},
	},
];

const COLMAP = {
	ID: 'id',
	'Sales Return ID': 'clientTransactionId',
	'Order Id': 'orderId',
	'Return Date': 'transactionDate',
	'From Store': 'fromStoreName',
	Store: 'storeName',
	Status: 'transactionStatus',
	Returned: 'items',
	'Refunded Amount': 'currentAmount',
};

const FIND_FILTERS = [];

function SalesReturnPage({
	userPermissions,
	createPath,
	togglePOItemsModal,
	togglePaymentModal,
	navState,
}) {
	const history = useHistory();
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [salesReturnList, setSalesReturnList] = useState([]);

	const handleListFetch = async (requestOptions) => {
		// setSalesReturnList(dummyData);
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								storeId:
									navSelectedStore?.value?.id === -999
										? null
										: navSelectedStore?.value?.id,
							},
							type: 'SALES_RETURN',
						},
					},
				});
			if (responseData?.statusCode === 200) {
				// setSelectedStore(store);
				setSalesReturnList(responseData?.data);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
		// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {
		Returned: (items) => {
			return items?.length;
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleViewClick = (item) => {
		togglePOItemsModal(true, {
			heading: 'Delivery Challan of Invoice #13245',
			...item,
			excludedColumns: [
				'Packaged',
				'Dispatched',
				'Received',
				'Returned Quantity',
				'Fulfilled',
				'From Process',
				'PO Id',
				'Returned',
				'Quantity',
				'Used For',
			],
		});
	};

	const moreOptionsList = {
		'Generate Credit Note': (sr, cb) => {
			const url = `${urls.EXTENSION}${urls.CREDIT_NOTE}/add?srId=${sr?.id}`;
			history.push(url);
			cb();
		},
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<POItemsModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Sales Return'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={salesReturnList}
				// data={dummyData}
				moreOptionsList={moreOptionsList}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleViewClick={handleViewClick}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	return {
		navState,
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesReturnPage);
