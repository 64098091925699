import { endpoints } from '../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const productServices = {
	getProducts,
	getCategories,
};

function getProducts(req = {}) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.GET_PRODUCTS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function getCategories(req = {}) {
	const params = {
		...req,
	};

	const url = toQueryStringURL(endpoints.GET_CATEGORIES, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

export default productServices;
