/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import DragDropUploader from '../Analytics/Inventory Analytics/UploadPurchaseOrder/DragDropUploader/DragDropUploader';
import styles from './VendorInvoiceUploadPage.module.scss';
import { classes, useQuery } from '../../Helpers';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import notificationServices from '../../Services/notification.services';

const VendorInvoiceUploadPage = () => {
	const query = useQuery();
	const queryVendorToken = query.get('vendorToken');
	const [fullView, setFullView] = useState(false);
	const [uploadedDoc, setUploadedDoc] = useState({
		fileName: '',
		file: '',
	});
	const [orderDetails, setOrderDetails] = useState({});
	const [invoiceUploaded, setInvoiceUploaded] = useState(false);

	useEffect(() => {
		setInvoiceUploaded(false);
	}, []);

	const vendorDetails = async () => {
		try {
			const response = await inventoryAnalyticsServices.getVendorDetails({
				data: {
					token: queryVendorToken,
				},
			});
			if (response?.statusCode === 200) {
				setOrderDetails(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		if (queryVendorToken) {
			vendorDetails();
		}
	}, [queryVendorToken]);

	const handleUploadInvoice = async () => {
		try {
			const response = await inventoryAnalyticsServices.uploadVendorInvoice({
				data: {
					token: queryVendorToken,
					uploadedDoc: uploadedDoc?.file,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: '',
				});
				setOrderDetails({});
				setUploadedDoc({});
				setInvoiceUploaded(true);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return (
		<div className={styles.root}>
			<div className={styles.container}>
				<p className={styles.cx_name}>{orderDetails?.customerName}</p>
				{invoiceUploaded ? (
					<div className={styles['success-container']}>
						<CheckCircle className={styles.icon} />
						<p className={styles.success_header}>Success</p>
						<p>Invoice Uploaded</p>
					</div>
				) : (
					<>
						<div className={styles.po_details}>
							<div className={styles.multi}>
								<div className={styles.tile}>
									<label>PO ID:</label>
									<p>
										{orderDetails?.poData?.clientPoId ??
											orderDetails?.poData?.id}
									</p>
								</div>
								<div className={styles.tile}>
									<label>GRN ID:</label>
									<p>
										#
										{orderDetails?.grnData?.id}
									</p>
								</div>
								<div className={styles.tile}>
									<label>GRN Date:</label>
									<p>{orderDetails?.grnData?.transactionDate}</p>
								</div>
							</div>
							<div className={styles.multi}>
								<div className={styles.tile}>
									<label>Store:</label>
									<p>{orderDetails?.poData?.storeName}</p>
								</div>
							</div>
						</div>
						<div className={styles.upload_container}>
							<p className={styles.header}>Upload Invoice</p>
							<div className={styles.uploader_preview}>
								<DragDropUploader
									uploadType='pdfFile'
									uploadedFile={uploadedDoc}
									setUploadedFile={setUploadedDoc}
									fileAcceptable='.pdf, application/pdf'
									className={styles.uploader}
								/>
								<div
									className={classes(
										styles.preview,
										fullView ? styles.full_preview : ''
									)}
								>
									{!uploadedDoc.file && orderDetails?.grnUploadedDoc && (
										<p className={styles.previous}>
											Previously Uploaded Doc
										</p>
									)}
									<iframe
										className={styles.iframe}
										width='100%'
										height='100%'
										src={uploadedDoc?.file || orderDetails?.grnUploadedDoc}
										title='PDF Rendered from Base64'
									/>
								</div>
							</div>
						</div>
						{(uploadedDoc.file) && (
							<Button variant='contained' fullWidth onClick={handleUploadInvoice}>
								Upload
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default VendorInvoiceUploadPage;
