import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { classes } from '../../../../Helpers';
import styles from './MeasurementBox.module.scss';

function ResultIcon(props) {
	const { result } = props;
	switch (result) {
		case 'PASS':
			return (
				<CheckCircleIcon
					className={classes(styles.pass, styles['result-icon'])}
				/>
			);
		case 'FAIL':
			return (
				<CancelIcon
					className={classes(styles.fail, styles['result-icon'])}
				/>
			);
		default:
			return <></>;
	}
}

function MeasurementBox(props) {
	const { measurements, classname } = props;
	const {
		min,
		max,
		title,
		measurement,
		result,
		unit,
		textToShow,
		minUnit,
		maxUnit,
		piecesCount,
		// eslint-disable-next-line no-tabs
	} = measurements ?? {};
	return (
		<div className={classes(classname, styles.root)}>
			<div className={styles.header}>
				<p className={styles.title}>{title}</p>
				{piecesCount !== null && piecesCount !== undefined && (
					<p className={styles['helper-text']}>
						Pieces Count:
						{' '}
						{piecesCount}
					</p>
				)}
				<div className={styles.values}>
					<p className={styles.ranges}>
						{typeof min === 'number' ? min?.toFixed(2) : min}
						{minUnit ?? unit}
						<br />
						<span className={styles.label}>Min</span>
					</p>
					<div className={styles.divider} />
					<div className={styles['current-value-container']}>
						<div className={styles['current-value']}>
							<div className={styles.values}>
								{measurement?.map((obj) => {
									return (
										<div
											className={classes(
												styles[obj?.size],
												styles[obj?.color]
											)}
										>
											{obj?.value ?? '-'}
											{unit}
											<br />
										</div>
									);
								})}
							</div>
							<p className={styles.label}>
								{textToShow ?? 'Current'}
							</p>
						</div>
						{result != null && (
							<ResultIcon
								result={result === 'PASS' ? 'PASS' : 'FAIL'}
							/>
						)}
					</div>
					<div className={styles.divider} />
					<p className={styles.ranges}>
						{typeof max === 'number' ? max?.toFixed(2) : max}
						{maxUnit ?? unit}
						<br />
						<span className={styles.label}>Max</span>
					</p>
				</div>
			</div>
		</div>
	);
}

MeasurementBox.defaultProps = {
	measurements: {
		min: 0,
		max: 0,
		title: '',
		measurement: [],
		result: null,
	},
	unit: '',
};

export default MeasurementBox;
