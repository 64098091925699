/* eslint-disable */
import { connect } from 'react-redux';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { GeneralDetails } from '../../../Components/Forms/ProductCategory/GeneralDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTab,
	StyledTabs,
	TabPanel,
} from '../../../Components/StyledTabs';
import {
	generateDisabledProps, history, isEmpty
} from '../../../Helpers';
/* eslint-disable linebreak-style */

import ProductsConfig from './ProductSubCategory.config';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABS = ['GENERAL DETAILS'];

const ProductSubCategoryViewPage = (props) => {
	const {
		fetchProductSubCategory,
		data,
		deleteProductSubCategory,
		breadcrumbs,
		fetchUserList,
		navState,
		userList,
	} = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(ProductsConfig.initialState.fields),
	});
	const ProductSubCategoryId = useRouteMatch().params.id;
	// const { userConfig } = useAuth();
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu?.['/products/sub/category']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	useEffect(() => {
		fetchProductSubCategory({
			entity: 'PRODUCT_SUB_CATEGORY',
			req: {
				params: {
					id: ProductSubCategoryId,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(ProductsConfig.serializer(state, data));
		}
	}, [data]);

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const { activeTab, fields } = state;

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return <GeneralDetails isDisabled fields={fields} />;
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteProductSubCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: ProductSubCategoryId,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Product Category'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${state.fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset row'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_CATEGORY.data.VIEW;
	const { navState } = state.navigation;
	const { userList } = state.auth;
	return {
		data,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProductSubCategory: formActions.gateway,
	deleteProductSubCategory: formActions.gateway,
	fetchUserList: authActions.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSubCategoryViewPage);
