// getgroupedAssets= funtion
export const getSpecsLabelAsKeyWithValues = (specs) => {
	return specs.reduce((acc, spec) => {
		const isUnchecked =
			spec.isChecked === false ||
			(spec.isChecked === 'false' && spec.dataType === 'BOOLEAN');
		acc[spec.label] = isUnchecked ? spec.newValue : spec.value;
		return acc;
	}, {});
};

export const updateSpecsWithCorrectValues = (specs) => {
	return specs.reduce((acc, spec) => {
		const isUnchecked =
			spec.isChecked === false ||
			(spec.isChecked === 'false' && spec.dataType === 'BOOLEAN');
		const obj = {
			...spec,
		};
		obj.value = isUnchecked ? spec.newValue : spec.value;
		delete obj.isChecked;
		delete obj.newValue;
		acc.push(obj);
		return acc;
	}, []);
};

export const getGroupedAssetObj = (obj, type) => {
	if (type === 'PO_ITEM') {
		const specsAndInfo = obj?.extraData;
		const coreSpecs = specsAndInfo?.coreSpecs;
		const oem = specsAndInfo?.oem;
		const category = specsAndInfo?.category;
		const assetType = specsAndInfo?.assetType;
		const correctSpecsValues = updateSpecsWithCorrectValues(coreSpecs);
		const groupBySpecs = {
			categoryId: category.id,
			oemId: oem.id,
			...getSpecsLabelAsKeyWithValues(correctSpecsValues),
		};
		return {
			...obj,
			actualAsset: [],
			assetType,
			category,
			groupBySpecs,
			groupBySpecsKey: JSON.stringify(groupBySpecs),
			imageUrl: obj.productImageUrl,
			oem,
			refAsset: {},
			specsToBeShownOnGroups: [...correctSpecsValues],
			qty: obj.qty,
			productCost: obj.productCost,
			fulfilled: obj.fulfilled,
			storeFulfilled: obj.storeFulfilled,
		};
	}
	return {};
};

export const getGroupedAssets = (
	storeAssetQrDataList,
	avoidTrackingChildAssets = true
) => {
	const groups = {};
	storeAssetQrDataList.forEach((asset) => {
		const specsToBeShownOnGroups = [];
		const groupBySpecs = asset.coreSpecs
			.filter((spec) => spec.rfqMandatory)
			.reduce((acc, spec) => {
				specsToBeShownOnGroups.push(spec);
				const isUnchecked =
					spec.isChecked === false ||
					(spec.isChecked === 'false' && spec.dataType === 'BOOLEAN');
				acc[spec.label] = isUnchecked ? spec.newValue : spec.value;
				return acc;
			}, {});
		groupBySpecs.oemId = asset.oem?.id;
		groupBySpecs.categoryId = asset.category?.id;
		const groupBySpecsKey = JSON.stringify(groupBySpecs);

		if (!groups[groupBySpecsKey]) {
			groups[groupBySpecsKey] = {
				groupBySpecs,
				groupBySpecsKey,
				specsToBeShownOnGroups,
				category: asset.category,
				imageUrl: asset.imageUrl,
				oem: asset.oem,
				refAsset: asset,
				assetType: {
					id: asset.assetTypeId,
					name: asset.assetTypeName,
					assetTypeImageUrl: asset.assetTypeImageUrl,
				},
				actualAsset: [],
			};
		}
		if (!avoidTrackingChildAssets) {
			groups[groupBySpecsKey].actualAsset.push(asset);
		}
	});
	return groups;
};
