import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import {
	InventoryCategoryCreatePage,
	InventoryCategoryViewPage,
	InventoryCategoryListPage,
} from '../../../Pages';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const InventoryCategoryRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.INVENTORY_CATEGORY}`,
			name: 'Inventory Category',
		},
	];
	return (
		<CRUDRoutes
			entity='INVENTORY CATEGORY'
			path={path}
			listPage={InventoryCategoryListPage}
			viewPage={<InventoryCategoryViewPage breadcrumbs={breadcrumbs} />}
			createPage={
				<InventoryCategoryCreatePage breadcrumbs={breadcrumbs} />
			}
			editPage={
				<InventoryCategoryCreatePage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default InventoryCategoryRoutes;
