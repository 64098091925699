/* eslint-disable no-unused-vars */
import ViewOnlyCoreSpec from './ViewOnlyCoreSpec';

const SingleAssetCard = ({
	id,
	category,
	qrCode,
	coreSpecs,
	price,
	tax,
	onSelect,
	inCart,
}) => {
	return (
		<div key={id} className='border rounded hover:shadow-sm text-xs'>
			<div className='p-2 border-b bg-gray-50'>
				<div className='flex justify-between items-center'>
					<span>
						ID:
						{id}
					</span>
					<span className='text-gray-500'>{category?.name}</span>
				</div>
				<div className='text-gray-500'>
					QR:
					{qrCode}
				</div>
			</div>
			<div className='p-2'>
				<div className='space-y-1'>
					<ViewOnlyCoreSpec showPrev={false} coreSpecs={coreSpecs} />
				</div>
			</div>
			<div className='p-2 border-t flex justify-between items-center bg-gray-50'>
				<div>
					<div>
						₹
						{price}
					</div>
					<div className='text-gray-500'>
						+₹
						{tax}
					</div>
				</div>
				{/* <button
					onClick={() => onSelect()}
					className={`px-2 py-1 rounded-sm ${
						inCart
							? 'bg-green-100 text-green-700 cursor-not-allowed'
							: 'text-blue-500 border border-blue-500 hover:bg-blue-50'
					}`}
					disabled={inCart}
				>
					{inCart ? 'Added' : 'Select'}
				</button> */}
			</div>
		</div>
	);
};

export default SingleAssetCard;
