import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { inputHelper } from '../../../../Helpers';
import styles from './applicability.module.scss';
import Tag from '../../../Tag/Tag';
import ApplicableProdCategoryModal from '../../../Modals/ApplicabilityModal/ApplicableProdCategoryModal';

const Category = (props) => {
	const {
		fields,
		isDisabled,
		toggleApplicableProductCategoriesModal,
		handleConfirm,
	} = props;
	const handleClick = () => {
		toggleApplicableProductCategoriesModal(true);
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		props.handleDelete(dataset.id, 'applicableCategories', 'id');
	};
	const generateTags = (allTags, type) => {
		return allTags.map((tag) => {
			return (
				<Tag
					className={`${styles.tag}`}
					handleDelete={handleDelete}
					text={tag.productCategoryName}
					isDisabled={isDisabled}
					dataAttrs={{
						'data-type': type,
						'data-id': tag.id,
						'data-name': 'applicableCategories',
					}}
				/>
			);
		});
	};
	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}>
			<div className='form-field row'>
				<label className='form-label'>
					Applicable Product Categories
				</label>
				<div className={`${styles['tag-wrapper']}`}>
					{fields.applicableCategories &&
						generateTags(
							fields.applicableCategories,
							'applicableCategories'
						)}
					{!isDisabled && (
						<div className={`${styles.add}`} onClick={handleClick}>
							<AddOutlinedIcon
								fontSize='medium'
								color='primary'
								dataAttrs={{
									'data-type': 'applicableCategories',
									'data-id': Category,
									'data-name': 'applicableCategories',
								}}
							/>
						</div>
					)}
				</div>

				<ApplicableProdCategoryModal
					handleConfirm={handleConfirm}
					selectedCategoryList={[
						...fields.applicableCategories,
						...fields.nonapplicableCategories,
					]}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { data, show } = state.modal.applicable_product_categories;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleApplicableProductCategoriesModal:
		modalActions.toggleApplicableProductCategoriesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
