import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import { CookingImagesPage } from '../../../Pages/Analytics/CookingAnalysis';

const getSubURL = (entity) => {
	const baseEntity = urls.APP_OUTWARDING_IMAGES;
	return entity.slice(baseEntity.length);
};

const CookingAnalyticsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.COOKING_IMAGES)}`}>
				<CookingImagesPage />
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

export default connect(mapStateToProps)(CookingAnalyticsRoutes);
