import { get } from 'lodash';
import { useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './StageDependenciesModal.module.scss';
import { CheckList } from '../../CheckList';
import { modalTypes } from '../../../Constants';

const HEADING = 'Select Stage Dependencies';
const initialState = {
	selectedList: [],
};

const MODAL_TYPE = modalTypes.COOKING_STAGE_DEPS;

const StageDependenciesModal = (props) => {
	const { show, data, toggleModal, selectedDeps } = props;

	const [state, setState] = useState({
		...initialState,
	});
	const { selectedList } = state;
	const handleClose = () => {
		setState({
			...initialState,
		});
		toggleModal(false);
	};
	const handleClick = (id, isSelected) => {
		if (isSelected) {
			setState({
				...state,
				selectedList: selectedList.filter((productId) => {
					return id !== productId;
				}),
			});
		} else {
			setState({
				...state,
				selectedList: [...selectedList, id],
			});
		}
	};
	const handleConfirm = () => {
		props.handleConfirm(selectedList);
		setState({
			...initialState,
		});
		toggleModal(false);
	};

	const generateList = () => {
		return get(data, 'stages.order', [])
			.filter((stageId) => {
				// exclude items already selected, and also the stage itself
				const isAlreadySelected = selectedDeps.find((selectedDep) => {
					return selectedDep === parseInt(stageId, 10);
				});
				const isActiveStage =
					data.activeStage === parseInt(stageId, 10);
				return !isAlreadySelected && !isActiveStage;
			})
			.map((id) => {
				return {
					id: parseInt(id, 10),
					title: `Stage ${id} - ${data.stages.items[id].name}`,
				};
			});
	};
	return (
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<CheckList
					list={generateList()}
					selectedItems={selectedList}
					handleItemSelect={handleClick}
					emptyMessage='Cannot find suitable dependencies'
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.[MODAL_TYPE];
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(MODAL_TYPE, show, data));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StageDependenciesModal);
