import { useEffect, useState } from 'react';
import { format, isValid } from 'date-fns';
import { connect } from 'react-redux';
import { paymentSettlementActions } from '../../../../Actions';
import AmountContainerDiv from '../../../../Components/Forms/Payment Settlements/AmountContainerDiv';
import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import SearchBar from '../../../../Components/SearchBar/SearchBar';
import { notificationServices } from '../../../../Services';
import PaymentSettlementListData from './PaymentSettlementListData';
import paymentSettlementsConfig from './PaymentSettlements.config';
import styles from './PaymentSettlementListPage.module.scss';
import { isEmpty } from '../../../../Helpers';
import { DATE_FORMAT } from '../../../../Constants';

const PaymentSettlementsListPage = (props) => {
	const { data, fetchSettlement, setReduxDate, reduxDate } = props;
	const [state, setState] = useState({
		...paymentSettlementsConfig.initialState,
	});

	const networkReq = (date) => {
		setReduxDate(date);
		const [startDate, endDate] = date;
		fetchSettlement({
			entity: 'PAYMENT_SETTLEMENTS',
			req: {
				data: {
					startDate: format(startDate, DATE_FORMAT),
					endDate: format(endDate, DATE_FORMAT),
				},
			},
		});
	};

	useEffect(() => {
		networkReq(reduxDate);
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState({
				...paymentSettlementsConfig.deserializer(state, data),
			});
		}
	}, [data]);

	const setDate = (newValues) => {
		const { startDate = null, endDate = null } = newValues?.selection ?? {};
		if (
			startDate != null &&
			endDate != null &&
			isValid(startDate) &&
			isValid(endDate)
		) {
			networkReq([startDate, endDate]);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Date',
			});
		}
	};

	const handleInput = (query) => {
		setState({
			...state,
			query,
		});
	};
	return (
		<div className={`${styles.root} page`}>
			<AmountContainerDiv amountCaption={state.fields.metricsData} />
			<div className={`${styles['settlement-page-search']}`}>
				<div className='search-bar'>
					<SearchBar
						handleInput={handleInput}
						placeholder='Search on Settlement Id'
					/>
				</div>
				<div>
					<Calender
						value={[
							{
								startDate: reduxDate?.[0],
								endDate: reduxDate?.[1],
								key: 'selection',
							},
						]}
						setValue={setDate}
					/>
				</div>
			</div>
			<PaymentSettlementListData
				query={state.query}
				data={state.fields.paymentData || []}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.paymentSettlement.PAYMENT_SETTLEMENTS;
	const reduxDate = state.paymentSettlement.date;
	return {
		data,
		reduxDate,
	};
};
const mapDispatchToProps = {
	fetchSettlement: paymentSettlementActions.gateway,
	setReduxDate: paymentSettlementActions.setDate,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentSettlementsListPage);
