import { endpoints } from '../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader } from '../Helpers';
import handleResponse from './helpers';

const paymentSettlementServices = {
	gateway,
};

function gateway({ entity, req }) {
	switch (entity) {
		case 'PAYMENT_SETTLEMENTS':
			return fetchPaymentData({
				req,
			});
		case 'TRANSACTION':
			return fetchTransaction({
				req,
			});
		case 'INVOICE_URL':
			return fetchInvoiceUrl({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function fetchPaymentData({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.FETCH_PAYMENT, requestOptions).then(handleResponse);
}

function fetchTransaction({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.FETCH_TRANSACTION_DATA, requestOptions).then(
		handleResponse
	);
}
function fetchInvoiceUrl({ req }) {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(`${endpoints.GET_INVOICE_URL}orderId=${req.orderId}`, requestOptions).then(
		handleResponse
	);
}

export default paymentSettlementServices;
