/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { get } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { ArrowDownward, ArrowUpward, Check } from '@mui/icons-material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../../../Components/ListTable';
import {
	classes, convertWeightUnit, handleResponseStreamDownload, useQuery
   } from '../../../../Helpers';
import { urls } from '../../../../Constants';
import styles from './AiProductionListTable.module.scss';
import { analyticsAction } from '../../../../Actions';
import { useAuth } from '../../../../Components/Auth/Login/auth';
// import VirtualizedListTable from '../../../../Components/VirtualizedListTable/VirtualizedListTable';
import { VirtualizedListTable } from '@/Components/VirtualizedListTable';
import commonAnalyticsServices from '@/Services/Analytics Services/common-analytics.services';
import { notificationServices } from '@/Services';
import { ListTable } from '@/Components/ListTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	Store: 'storeName',
	'QC TIME': 'QCTime',
	'Order Id': 'OrderId',
	'Item Id': 'itemId',
	'Product Name': 'ProductName',
	Temp: 'Temp',
	// 'Inventory Type': 'Inventory Type',
	Weight: 'Weight',
	qc: 'id',
	Validated: 'validation',
	Duplicate: 'duplicate',
	Identified: 'identification',
	Features: 'annotated',
};

const Modes = {
	PRODUCTION: 'Ai-Inventory-In',
	INWARD: 'Ai-GRN',
	OUTWARD: 'Ai-Inventory-Out',
};

const TABLE_COLUMNS = [
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'QC TIME',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Item Id',
	// config: {
	// type: 'DATA',
	// },
	// },
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	// {
	// 	title: 'Inventory Type',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	{
		title: 'Duplicate',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Validated',
		config: {
			type: 'EDIT',
		},
	},
	{
		title: 'Identified',
		config: {
			type: 'ANNOTATE',
		},
	},
	{
		title: 'Features',
		config: {
			type: 'ANNOTATE',
		},
	},
	{
		title: 'Weight',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Temp',
		config: {
			type: 'DATA',
		},
	},
];

const renderColumn = (column) => {
	switch (column) {
		case 'Validated':
		case 'Identified':
		case 'Features':
			return (
				<div
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					{column?.toUpperCase()}
				</div>
			);
		default:
			return null;
	}
};

const getRowStyles = (item) => {
	switch (item?.result) {
		case 'FAILED':
			return styles.critical;
		case 'SKIPPED':
			return styles.skipped;
		case 'OVERRIDDEN':
			return styles.moderate;
		default:
			return styles.normal;
	}
};

function setAttr(text) {
	return <span className={styles.text}>{text}</span>;
}

const AiProductionListTable = (props) => {
	const history = useHistory();
	const { data, setAnalyticsType, ANALYTICS_TYPE, fetchDataAfterSetting } = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const params = useQuery();
	const listTable = Number(params.get('all'));
	// const listCount = data.length - 1;

	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {
			data: {
				qcType: 'PRODUCTION',
			},
		}, fetchDataAfterSetting);
	}, []);
	const SPECIAL_ITEMS = {
		Weight: (altProps) => {
			const item = data[altProps - 1];
			let failure =
				item?.Weight > item?.MaxWeight / 1000
					? 'OVER'
					: 'UNDER';
			failure =
				item?.WeightCheckRequired &&
				!item?.IsWeightInRange &&
				failure;
			const itemWeight = get(item, COLMAP.Weight, 0);
			let itemUnit = item?.unit;
			if (item?.unit === 'count') {
				if (measurement === 'US') {
					itemUnit = 'oz';
				} else {
					itemUnit = 'gram';
				}
			}
			// if (item?.unit && item?.unit === 'gram') {
			// if (itemWeight >= 1) {
			// itemUnit = 'Kg';
			// } else {
			// itemWeight *= 1000;
			// itemUnit = 'g';
			// }
			// }
			return (
				<span
					className={`${styles['no-wrap']} ${styles.text} ${
						failure ? styles.critical : styles.normal
					}`}
				>
					{convertWeightUnit(itemWeight, itemUnit) ?? '-'}
					{/* {itemUnit} */}
					{data[altProps - 1]?.IsWeightInRange === false ? (
						<>
							{failure === 'OVER' && <ArrowUpward />}
							{failure === 'UNDER' && <ArrowDownward />}
						</>
					) : (
						<></>
					)}
				</span>
			);
		},
		Temp: (altProps) => {
			let failure =
				data?.[altProps - 1]?.Temp > data?.[altProps - 1]?.MaxTemp
					? 'OVER'
					: 'UNDER';

			failure =
				data?.[altProps - 1]?.TempCheckRequired &&
				!data?.[altProps - 1]?.IsTempInRange &&
				failure;
			return (
				<span
					className={`${styles['no-wrap']} ${styles.text} ${
						failure ? styles.critical : styles.normal
					}`}
				>
					{get(data[altProps - 1], COLMAP.Temp)?.toFixed(2) ?? '-'}
					{measurement === 'US' ? '°F' : '°C'}
					{data[altProps - 1]?.IsTempInRange === false ? (
						<>
							{failure === 'OVER' && <ArrowUpward />}
							{failure === 'UNDER' && <ArrowDownward />}
						</>
					) : (
						<></>
					)}
				</span>
			);
		},
		// Brand: (altProps) => {
		// return (
		// <span className={styles['brand-column']}>
		// <img
		// className={styles.logo}
		// src={data[altProps - 1]?.BrandImage}
		// alt='Brand'
		// />
		// <p>{data[altProps - 1]?.brandName}</p>
		// </span>
		// );
		// },
		Store: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data?.[altProps - 1]?.storeName ?? '-'}</p>
				</span>
			);
		},
		Outlet: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data?.[altProps - 1]?.fromStoreName ?? '-'}</p>
				</span>
			);
		},
		'QC TIME': (altProps) => {
			return setAttr(get(data[altProps - 1], COLMAP['QC TIME']));
		},
		'Product Name': (altProps) => {
			return setAttr(get(data[altProps - 1], COLMAP['Product Name']));
		},
		'Inventory Type': (altProps) => {
			const currItem = data[altProps - 1];
			return setAttr(Modes?.[currItem?.type], COLMAP['Inventory Type']);
		},
		'Order Id': (altProps) => {
			const text = get(data[altProps - 1], COLMAP['Order Id']);
			// eslint-disable-next-line prefer-template
			return setAttr(
				`...${text?.substring(text?.length - 6, text?.length - 1)}`
			);
		},
		// 'Item Id': (altProps) => {
		// return setAttr(get(data[altProps - 1], COLMAP['Item Id']));
		// },
		Duplicate: (altProps) => {
			const isDuplicate = get(data[altProps - 1], COLMAP.Duplicate);

			if (isDuplicate) {
				return <ReportProblemIcon />;
			}
			return null;
		},
		Validated: (altProps) => {
			const validated = get(data[altProps - 1], COLMAP.Validated);

			if (validated) {
				return <Check />;
			}
			return null;
		},
		Identified: (altProps) => {
			const identification = get(data[altProps - 1], [
				COLMAP.Identified,
				'0',
			]);

			let identified = false;

			if (
				identification?.x != null &&
				identification?.y != null &&
				identification?.width != null &&
				identification?.height != null
			) {
				identified = true;
			}

			if (identified) {
				return <Check />;
			}
			return null;
		},
		Features: (altProps) => {
			const annotated = get(data[altProps - 1], COLMAP.Features);
			return annotated;
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			{listTable ? (
				<ListTable
					colMap={COLMAP}
					actions={[...TABLE_COLUMNS]}
					special={SPECIAL_ITEMS}
					data={data.map((obj, idx) => {
						const rowStyles = getRowStyles(obj);
					const itemId = get(obj, COLMAP['Item Id']);
					const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						id: idx + 1,
						rowStyles,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.PAST_QC}/${itemId}`,
								search: `id=${qc}`,
								state: {
									from: history?.location?.pathname,
								},
							});
						},
					};
					})}
					columns={[]}
				/>
			) : (
				<VirtualizedListTable
					// useWindowScroll={false}
					fixedHeaderTop={0}
					renderColumn={renderColumn}
					colMap={COLMAP}
					hasDateRange
					special={SPECIAL_ITEMS}
					data={data.map((obj, idx) => {
					const rowStyles = getRowStyles(obj);
					const itemId = get(obj, COLMAP['Item Id']);
					const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						id: idx + 1,
						rowStyles,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.PAST_QC}/${itemId}`,
								search: `id=${qc}`,
								state: {
									from: history?.location?.pathname,
								},
							});
						},
					};
				})}
					columns={[]}
					actions={[...TABLE_COLUMNS]}
					allowExport={false}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AiProductionListTable);
