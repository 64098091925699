import { cloneDeep, update } from 'lodash';
import { PRODUCT_CLASSES, PRODUCT_UNITS } from '../../../Constants/product.constants';

const inventoryConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			brand: null,
			image: null,
			productClass: PRODUCT_CLASSES.map((productClass) => {
				return {
					...productClass,
					isApplied: false,
				};
			}),
			productUnits: PRODUCT_UNITS?.map((productUnit) => {
				return {
					...productUnit,
					isApplied: false,
				};
			}),
			IMSEnabled: false,
			bulkEnabled: false,
			batchEnabled: false,
			unit: 'gram',
			expiry: null,
			expiryUnit: 'hrs',
			variants: {
				items: {},
				order: [],
			},
		},
	},
	mandatoryFields: {
		unit: true,
	},
	serializer: (state, data) => {
		return update(cloneDeep(state), 'fields', () => {
			const newState = {
				bulkEnabled: data.bulkConsumption,
				batchEnabled: data.isBatchMode,
				name: data.productName,
				description: data.productDescription,
				brand: data.brandId,
				image: data.productImageUrl,
				expiry: data.hoursToExpire,
				expiryUnit: 'hrs',
				unit: data.unit,
				productClass: PRODUCT_CLASSES.map((productClass) => {
					let isApplied = false;
					if (data.skuType.toLowerCase() === productClass.id) {
						isApplied = true;
					}
					return {
						...productClass,
						isApplied,
					};
				}),
				IMSEnabled: data.isIMSEnabled,
				variants: {
					items: Object.keys(data.ProductVariant).reduce(
						(accVariants, currVariantId, idx) => {
							const currVariant =
								data.ProductVariant[currVariantId];
							// eslint-disable-next-line no-param-reassign
							accVariants[idx] = {
								id: currVariant.id,
								name: currVariant.displayName,
								qty: currVariant.unitsInSKU,
								threshold: currVariant.threshold,
							};
							return accVariants;
						},
						{}
					),
					order: Object.keys(data.ProductVariant).map(
						(variant, idx) => {
							return idx;
						}
					),
				},
			};
			return newState;
		});
	},
	validator: () => {
		return true;
	},
	deserializer: (state) => {
		const newState = update(cloneDeep(state), 'fields', (newFields) => {
			const { expiry, expiryUnit } = newFields;
			let newExpiry = expiry;
			if (expiryUnit === 'days') {
				newExpiry *= 24;
			} else if (expiryUnit === 'months') {
				newExpiry *= 24 * 30;
			}
			return {
				unit: newFields.unit,
				expiry: newExpiry,
				bulkEnabled: newFields.bulkEnabled,
				batchEnabled: newFields.batchEnabled,
				IMSEnabled: newFields.IMSEnabled,
				variants: newFields.variants.order.map((variantId) => {
					const variant = newFields.variants.items[variantId];
					return {
						id: variant.id,
						qty: variant.qty,
						threshold: variant.threshold,
					};
				}),
			};
		});

		return new Promise((resolve) => {
			resolve({
				data: {
					...newState.fields,
				},
			});
		});
	},
};

export default inventoryConfig;
