import { Crop } from '@mui/icons-material';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import {
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper,
} from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import DownloadTMC from './DownloadTMC';
import styles from './GeneralDetails.module.scss';
import { Label } from '@/Components/ui/label';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		handleFileInput,
		handleImageInput,
		isDisabled,
		isEdit,
		toggleImageCrop,
	} = props;

	const renderTrademark = () => {
		if (isDisabled || isEdit) {
			return <DownloadTMC trademark={fields.trademark} />;
		}
		const trademarkName =
			fields.trademark === null
				? 'NO FILE UPLOADED'
				: fields.trademark.name;
		return trademarkName;
	};

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<Label
						className='form-label'
						htmlFor='brandName'
						data-mandatory={mandatoryFields.name}
					>
						Name
					</Label>
					<input
						className='form-value'
						type='text'
						id='brandName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				{/* Brand Code */}
				<div className='form-field row'>
					<Label
						className='form-label'
						htmlFor='brandCode'
						data-mandatory={mandatoryFields.brandCode}
					>
						Brand Code
					</Label>
					<input
						className='form-value'
						type='text'
						id='brandCode'
						name='brandCode'
						onInput={handleInput}
						value={fields.brandCode}
						{...generateRequiredProps(mandatoryFields.brandCode)}
					/>
					<span className='form-error'>{errors.brandCode}</span>
				</div>
				{/* Tagline */}
				<div className='form-field row'>
					<Label
						className='form-label'
						htmlFor='tagline'
						data-mandatory={mandatoryFields.tagline}
					>
						Tagline
					</Label>
					<textarea
						className='form-value'
						type='text'
						id='tagline'
						name='tagline'
						onInput={handleInput}
						value={fields.tagline}
						{...generateRequiredProps(mandatoryFields.tagline)}
					/>
					<span className='form-error'>{errors.description}</span>
				</div>
				{/* trademark */}
				<div className='form-field row'>
					<Label
						className='form-label'
						htmlFor='trademark'
						data-mandatory={mandatoryFields.trademark}
					>
						Trademark Certificate (only accepts pdf)
					</Label>
					<div
						className={`${isDisabled ? '' : 'border-2 border-slate-200'} px-2 py-1 rounded-md flex items-center`}
						style={{
							display: 'flex',
						}}
					>
						<div
							style={{
								border: '1px solid midgrey',
								padding: '5px',
								marginRight: '10px',
								marginLeft: '10px',
								borderRadius: '2px',
							}}
						>
							{renderTrademark()}
						</div>
						{isDisabled ? (
							<></>
						) : (
							<Label
								className='px-4 py-2 h-fit bg-uy-color rounded text-center cursor-pointer flex items-center'
								htmlFor='displayCertificate'
							>
								Upload
							</Label>
						)}
						<input
							type='file'
							id='displayCertificate'
							name='trademark'
							accept='application/pdf'
							onChange={handleFileInput}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
					</div>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* display image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<Label
						className='form-label'
						data-mandatory={mandatoryFields.image}
					>
						Display Image
					</Label>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={
									isDisabled
										? fields.image
										: generateImageUrl(fields.image)
								}
								className={`display-pic ${
									fields.image ? 'show' : 'hide'
								}`}
								alt='brand-display'
							/>
							{generateImageEditButton({
								isEdit:
									fields.image &&
									fields.image.includes(
										'data:image/jpeg;base64'
									),
								button: (
									<button
										type='button'
										onClick={() => {
											openImageCropModal('image');
										}}
										className='display-pic-edit'
									>
										<Crop />
									</button>
								),
							})}
						</div>
						{generateImageUploadButton({
							isDisabled,
							label: (
								<Label
									className='display-pic-label'
									htmlFor='displayPic'
								>
									Choose an image
								</Label>
							),
						})}
						<input
							type='file'
							id='displayPic'
							accept='image/png, image/jpg, image/jpeg, image/webp'
							name='image'
							onChange={handleImageSelect}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
					</div>
				</div>
				<ImageCropModal handleConfirm={handleImageInput} />
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = () => {};

const mapDispatchToProps = {
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
