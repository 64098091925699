import styles from './CheckMark.module.css';

function CheckMark(props) {
	const { isVeg } = props;

	if (isVeg == null) {
		return '';
	}

	return (
		<div
			className={`${styles['dot-container']} ${isVeg ? styles.veg : ''} ${
				!isVeg ? styles['non-veg'] : ''
			}`}
			style={{
				marginRight: '1rem',
			}}
		>
			<div className={styles.dot} />
		</div>
	);
}

CheckMark.defaultProps = {
	isVeg: true,
};

export default CheckMark;
