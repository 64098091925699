import _ from 'lodash';

const NutritionalValue = {
	initialState: {
		fields: {
			name: 'Product Name',
			productDescription: 'Product Description',
			image: '',
			Protein: 0,
			Carbs: 0,
			Fat: 0,
			Calories: 0,
			Calcium: 0,
		},
	},
	mandatoryFields: {
		productName: true,
		productDescription: true,
		image: true,
		protein: true,
		carb: true,
		fat: true,
		calories: true,
		calcium: true,
	},
	list: [],
	deserializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			let newState = {
				name: data.productName,
				productDescription: data.productDescription,
				image: data.productImageUrl,
				nutritionValue: data.variants.filter((variant) => {
					return variant.isDefault;
				}),
			};
			newState = {
				...newState,
				...newState.nutritionValue[0],
			};
			delete newState.nutritionValue;
			return newState;
		});
	},
	serializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', (data) => {
			return {
				Protein: Number(data.Protein)?.toFixed(2) || 0,
				Carbs: Number(data.Carbs)?.toFixed(2) || 0,
				Fat: Number(data.Fat)?.toFixed(2) || 0,
				Calories: Number(data.Calories)?.toFixed(2) || 0,
				Calcium: Number(data.Calcium)?.toFixed(2) || 0,
			};
		});
	},
};
export default NutritionalValue;
