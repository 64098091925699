import { Switch } from '@mui/material';
import { Check, Crop } from '@mui/icons-material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { brandActions, modalActions } from '../../../../Actions';
import {
	generateCheckedProps,
	generateDisabledProps,
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRandomKey,
	generateRequiredProps,
	inputHelper,
	isEmpty,
} from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import { IconInput } from '../../Inputs';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		handleImageInput,
		toggleImageCrop,
		brands,
		generateDefaultOption,
		isDisabled,
	} = props;

	useEffect(() => {
		props.fetchBrands({
			id: '',
			allData: true,
		});
	}, []);

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	return (
		<div className={`${styles.root}`}>
			<fieldset
				{...generateDisabledProps(isDisabled)}
				className='form-fieldset row'
				style={{
					alignItems: 'flex-start',
				}}
			>
				<div
					style={{
						flexBasis: '50%',
					}}
				>
					{/* combo name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='comboName'
							data-mandatory={mandatoryFields.name}
						>
							Combo Name
						</label>
						<input
							className='form-value'
							type='text'
							id='comboName'
							name='name'
							onInput={handleInput}
							value={fields.name}
							{...generateRequiredProps(mandatoryFields.name)}
						/>
						<span className='form-error'>{errors.name}</span>
					</div>
					{/* description */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='description'
							data-mandatory={mandatoryFields.description}
						>
							Description
						</label>
						<textarea
							className='form-value'
							type='text'
							id='description'
							name='description'
							onInput={handleInput}
							value={fields.description}
							{...generateRequiredProps(
								mandatoryFields.description
							)}
						/>
						<span className='form-error'>{errors.description}</span>
					</div>
					{/* brand name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='brand'
							data-mandatory={mandatoryFields.brand}
						>
							Brand
						</label>
						<select
							className='form-value'
							id='brand'
							name='brand'
							onChange={handleInput}
							value={fields.brand}
							{...generateRequiredProps(mandatoryFields.brand)}
						>
							{generateDefaultOption(
								'brand',
								fields.brand,
								fields.category
							)}
							{Object.keys(brands).map((brandId) => {
								const brand = brands[brandId];
								return (
									<option value={brandId}>
										{brand.name}
									</option>
								);
							})}
							{/* <option value='1'>Default</option> */}
						</select>
						<span className='form-error'>{errors.brand}</span>
					</div>
					{/* category name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='category'
							data-mandatory={mandatoryFields.category}
						>
							Category
						</label>
						<select
							className='form-value'
							id='category'
							name='category'
							onChange={handleInput}
							disabled={!fields.brand}
							value={fields.category}
							{...generateRequiredProps(mandatoryFields.category)}
						>
							{generateDefaultOption(
								'category',
								fields.brand,
								fields.category
							)}
							{fields.brand &&
								!isEmpty(brands) &&
								Object.keys(
									brands[fields.brand].ProductCategory
								).map((categoryId) => {
									const category =
										brands[fields.brand].ProductCategory[
											categoryId
										];
									return (
										<option value={categoryId}>
											{category.productCategoryName}
										</option>
									);
								})}
						</select>
						<span className='form-error'>{errors.category}</span>
					</div>
					{/* tax and veg */}
					<div className='form-field row'>
						<label
							htmlFor='tax'
							className='form-label'
							data-mandatory={mandatoryFields.tax}
						>
							Tax
						</label>
						<div className='form-value row'>
							<IconInput
								size='large'
								type='percent'
								name='tax'
								id='tax'
								value={fields.tax}
								handleInput={handleInput}
								dataType=''
								dataId=''
								dataAttrs={{
									required: mandatoryFields.tax,
								}}
							/>
							<div
								className='row'
								style={{
									flex: '1 1 50%',
								}}
							>
								<label
									className='form-label'
									htmlFor='brand'
									style={{
										width: 'auto',
										minWidth: 'auto',
										margin: '0px 1.5rem',
									}}
								>
									Veg
								</label>
								<div
									className='row'
									style={{
										justifyContent: 'space-around',
									}}
								>
									<label
										className={`dot-container veg ${
											fields.veg ? 'selected' : ''
										} ${isDisabled ? 'disabled' : ''}`}
										htmlFor='veg'
									>
										<div className='dot' />
										<Check className='tick' />
									</label>
									<input
										{...generateRandomKey()}
										{...generateCheckedProps(fields.veg)}
										type='radio'
										id='veg'
										hidden
										name='veg'
										value={1}
										onChange={handleInput}
									/>
									<label
										className={`dot-container non-veg ${
											fields.veg ? '' : 'selected'
										} ${isDisabled ? 'disabled' : ''}`}
										htmlFor='non-veg'
									>
										<div className='dot' />
										<Check className='tick' />
									</label>
									<input
										{...generateRandomKey()}
										{...generateCheckedProps(!fields.veg)}
										type='radio'
										id='non-veg'
										hidden
										name='veg'
										value={0}
										onChange={handleInput}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* customization */}
					<div className='form-field row'>
						<label
							htmlFor='customization'
							className='form-label'
							data-mandatory={mandatoryFields.customization}
						>
							Customizable
						</label>
						<div className='form-value row'>
							<Switch
								name='customization'
								value='customization'
								size='small'
								color='primary'
								onChange={handleInput}
								checked={fields.customization}
								inputProps={{
									'data-id': '',
									'data-type': '',
								}}
							/>
						</div>
					</div>
				</div>
				<div
					style={{
						marginLeft: '3rem',
						flexBasis: '50%',
					}}
				>
					{/* display image */}
					<div
						className='form-field row'
						style={{
							alignItems: 'flex-start',
						}}
					>
						<label
							className='form-label'
							data-mandatory={mandatoryFields.image}
						>
							Display Image
						</label>
						<div className='display-pic-root'>
							<div className='display-pic-preview'>
								<img
									src={
										isDisabled
											? fields.image
											: generateImageUrl(fields.image)
									}
									className={`display-pic ${
										fields.image ? 'show' : 'hide'
									}`}
									alt='combo-display'
								/>
								{generateImageEditButton({
									isEdit:
										fields.image &&
										fields.image.includes(
											'data:image/jpeg;base64'
										),
									button: (
										<button
											type='button'
											onClick={() => {
												openImageCropModal('image');
											}}
											className='display-pic-edit'
										>
											<Crop />
										</button>
									),
								})}
							</div>
							{generateImageUploadButton({
								isDisabled,
								label: (
									<label
										className='display-pic-label'
										htmlFor='displayPic'
									>
										Choose an image
									</label>
								),
							})}
							<input
								type='file'
								id='displayPic'
								accept='image/png, image/jpg, image/jpeg, image/webp'
								name='image'
								onChange={handleImageSelect}
								hidden
							/>
						</div>
					</div>
					<ImageCropModal handleConfirm={handleImageInput} />
				</div>
			</fieldset>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	fetchBrands: brandActions.fetchBrands,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
