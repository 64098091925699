/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
// eslint-disable-next-line object-curly-newline
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { DragIndicator } from '@mui/icons-material';
import { DragSource, DropTarget } from 'react-dnd';
import draggableTypes from '../../../Draggable/draggable.types';
import styles from './VTab.module.scss';

export const VTab = forwardRef((props, ref) => {
	const {
		text,
		id,
		active,
		isDragging,
		isDraggable,
		connectDragSource,
		connectDropTarget,
	} = props;
	const elementRef = useRef(null);
	const opacity = isDragging ? 0 : 1;
	if (isDraggable) {
		connectDragSource(elementRef);
		connectDropTarget(elementRef);
	}
	useImperativeHandle(ref, () => {
		return {
			getNode: () => {
				return elementRef.current;
			},
		};
	});

	const handleClick = () => {
		props.handleClick(id);
	};

	return (
		<div
			style={{
				opacity,
			}}
			className={`${styles.root} ${active ? styles.active : ''}`}
			ref={elementRef}
			onClick={handleClick}
		>
			<div
				className={`${styles['drag-handle']} ${
					isDraggable ? '' : styles.hide
				}`}
			>
				<DragIndicator />
			</div>
			<span className={`${styles.text}`}>{text}</span>
		</div>
	);
});

export const DraggableVTab = DropTarget(
	draggableTypes.VTAB,
	{
		hover(props, monitor, component) {
			if (!component) {
				return null;
			}
			// node = HTML Div element from imperative API
			const node = component.getNode();
			if (!node) {
				return null;
			}
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = node.getBoundingClientRect();
			// Get horizontal middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the left
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items width
			// When dragging leftwards, only move when the cursor crosses left more than 50%
			// When dragging rightwards, only move when the cursor crosses right more than 50%
			// Dragging rightwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging leftwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// Time to actually perform the action
			props.moveCard(dragIndex, hoverIndex);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			monitor.getItem().index = hoverIndex;
		},
	},
	(connect) => {
		return {
			connectDropTarget: connect.dropTarget(),
		};
	}
)(
	DragSource(
		draggableTypes.VTAB,
		{
			beginDrag: (props) => {
				return {
					id: props.id,
					index: props.index,
				};
			},
		},
		(connect, monitor) => {
			return {
				connectDragSource: connect.dragSource(),
				isDragging: monitor.isDragging(),
			};
		}
	)(VTab)
);
