import assetConstants from '../Constants/asset.constants';

const initialState = {
	isloading: false,
	list: [],
	dashboardAnalytics: {},
	dashboardChartsData: {},
	varientList: [],
	monitoringData: [],
	storeAssetList: {
		store: {},
		assets: [],
	},
};

function asset(state = initialState, action) {
	switch (action.type) {
		case assetConstants.FETCH_ASSET_REQUEST:
			return {
				...state,
				isloading: true,
			};
		case assetConstants.FETCH_ASSET_SUCCESS:
			return {
				...state,
				isloading: false,
				list: [...action?.payload],
			};
		case assetConstants.FETCH_ASSET_FAILURE:
			return {
				...state,
				isloading: false,
			};
		case assetConstants.ASSET_DASHBOARD_REQUEST:
			return {
				...state,
				isloading: true,
			};
		case assetConstants.ASSET_DASHBOARD_SUCCESS:
			return {
				...state,
				isloading: false,
				dashboardAnalytics: {
					...action?.payload,
				},
			};

		case assetConstants.ASSET_DASHBOARD_FAILURE:
			return {
				...state,
				isloading: false,
			};
		case assetConstants.ASSET_CHARTS_DASHBOARD_REQUEST:
			return {
				...state,
				isloading: true,
			};
		case assetConstants.ASSET_CHARTS_DASHBOARD_SUCCESS:
			return {
				...state,
				isloading: false,
				dashboardChartsData: {
					...action?.payload,
				},
			};

		case assetConstants.ASSET_CHARTS_DASHBOARD_FAILURE:
			return {
				...state,
				isloading: false,
			};

		case assetConstants.LIST_VAIRENT_ASSET_REQUEST:
			return {
				...state,
				isloading: true,
			};
		case assetConstants.LIST_VAIRENT_ASSET_SUCCESS:
			return {
				...state,
				isloading: false,
				varientList: [...action?.payload.variantNames],
			};

		case assetConstants.LIST_VAIRENT_ASSET_FAILURE:
			return {
				...state,
				isloading: false,
			};

		case assetConstants.ASSET_FRIDGE_MONITORING_REQUEST:
			return {
				...state,
				isloading: true,
			};
		case assetConstants.ASSET_FRIDGE_MONITORING_SUCCESS:
			return {
				...state,
				isloading: false,
				monitoringData: [...action?.payload],
			};
		case assetConstants.ASSET_FRIDGE_MONITORING_RESET:
			return {
				...state,
				isloading: false,
				monitoringData: [],
			};

		case assetConstants.ASSET_FRIDGE_MONITORING_FAILURE:
			return {
				...state,
				isloading: false,
			};

		case assetConstants.STORE_ASSET_LISTING_REQUEST:
			return {
				...state,
				isloading: true,
			};
		case assetConstants.STORE_ASSET_LISTING_SUCCESS:
			return {
				...state,
				isloading: false,
				storeAssetList: {
					store: action?.payload?.store,
					assets: action?.payload?.assets,
				},
			};
		case assetConstants.STORE_ASSET_LISTING_RESET:
			return {
				...state,
				isloading: false,
				storeAssetList: {
					store: {},
					assets: [],
				},
			};

		case assetConstants.STORE_ASSET_LISTING_FAILURE:
			return {
				...state,
				isloading: false,
			};
		default:
			return state;
	}
}

export default asset;
