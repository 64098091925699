import { cloneDeep, get } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import styles from './NutritionalListPage.module.scss';
import { BackdropLoader } from '../../../Components/Loaders';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'Protein',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Carbs',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Fat',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Calories',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Calcium',
		config: {
			type: 'DATA',
		},
	},
];
const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	Brand: 'brandName',
	Protein: 'Protein',
	Carbs: 'Carbs',
	Fat: 'Fat',
	Calories: 'Calories',
	Calcium: 'Calcium',
};
const NutritionalListPage = (props) => {
	const { fetchNutritions, data, query, searchBy } = props;

	const [isLoading, setIsLoading] = useState(false);

	const fetchNutritionsFunc = async () => {
		setIsLoading(true);
		fetchNutritions({
			entity: 'NUTRITIONAL_INFO',
			req: {
				params: {
					id: '',
					variants: true,
					skuType: 'PRODUCT',
				},
			},
			method: 'LIST',
		});
		setIsLoading(false);
	};

	useEffect(() => {
		fetchNutritionsFunc();
	}, []);
	const generateIcon = (value, iconText) => {
		return (
			<span className={styles.root}>
				{value}
				{iconText}
			</span>
		);
	};
	const listData = data.reduce((accProduct, currProduct) => {
		let tempProduct = cloneDeep(currProduct);
		[tempProduct.variants] = currProduct.variants.filter((variant) => {
			return variant.isDefault;
		});
		tempProduct = {
			...tempProduct,
			tempId: tempProduct.id,
			...tempProduct.variants,
		};
		tempProduct.id = tempProduct.tempId;
		delete tempProduct.variants;
		accProduct.push(tempProduct);
		return accProduct;
	}, []);

	const SPECIAL_ITEMS = {
		Protein: (value) => {
			return generateIcon(value, 'g');
		},
		Carbs: (value) => {
			return generateIcon(value, 'g');
		},
		Fat: (value) => {
			return generateIcon(value, 'g');
		},
		Calories: (value) => {
			return generateIcon(value, 'kcal');
		},
		Calcium: (value) => {
			return generateIcon(value, 'mg');
		},
	};
	return (
		<div className='list-page-root'>
			<BackdropLoader showProgess isLoading={isLoading} />
			<ListTable
				colMap={COLMAP}
				columns={[...TABLE_COLUMNS, ...SPECIAL_COLUMNS]}
				data={listData.filter((product) => {
					return (
						get(product, COLMAP?.[searchBy?.title])
							?.toLowerCase()
							?.indexOf(query?.trim()?.toLowerCase()) !== -1
					);
				})}
				fixedHeaderTop={0}
				actions={[...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.NUTRITIONAL_INFO.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchNutritions: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NutritionalListPage);
