import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { RiderListPage, RiderCreatePage, RiderViewPage } from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const RiderRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.RIDERS}`,
			name: 'Riders',
		},
	];
	return (
		<CRUDRoutes
			entity='RIDERS'
			path={path}
			listPage={RiderListPage}
			viewPage={<RiderViewPage breadcrumbs={breadcrumbs} />}
			createPage={<RiderCreatePage breadcrumbs={breadcrumbs} />}
			editPage={<RiderCreatePage isEdit breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default RiderRoutes;
