import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import CookingProductConfigEditPage from '../../../Pages/Cooking/Cooking Product Config/CookingProductConfigEditPage';
import CookingProductConfigListPage from '../../../Pages/Cooking/Cooking Product Config/CookingProductConfigListPage';
import CookingProductConfigViewPage from '../../../Pages/Cooking/Cooking Product Config/CookingProductConfigViewPage';
// eslint-disable-next-line object-curly-newline

import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const CookingProductConfigRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.COOKING_PRODUCT_CONFIG}`,
			name: 'Product Config',
		},
	];
	return (
		<CRUDRoutes
			entity='COOKING_PRODUCT_CONFIG'
			path={path}
			listHeader='Cooking Config'
			listPage={CookingProductConfigListPage}
			editPage={
				<CookingProductConfigEditPage breadcrumbs={breadcrumbs} />
			}
			viewPage={
				<CookingProductConfigViewPage breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default CookingProductConfigRoutes;
