import { cloneDeep, update } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions, modalActions } from '../../../Actions';
import AlertModal from '../../../Components/Modals/AlertModal/AlertModal';
import { inputHelper, isEmpty } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import approveRefundPageConfig from './approveRefundPageConfig';
import ApproveRefundTable from './ApproveRefundTable';

const CreateRefundPage = (props) => {
	const {
		fetchApproveRefunds,
		data,
		toggleAlert,
		approveOrRejectRefunds,
		query,
	} = props;
	const [state, setState] = useState({
		...approveRefundPageConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	useEffect(() => {
		fetchApproveRefunds({
			entity: 'APPROVE_REFUNDS',
			req: {
				params: {},
			},
			method: 'LIST',
		});
	}, []);

	const openAlert = (e, action) => {
		const { dataset } = inputHelper(e);
		const order = cloneDeep(state.fields.orders[dataset.id]);
		toggleAlert(true, {
			message: `Click Proceed to ${action} refund of ${
				order?.finalrefundAmount ?? '-'
			} on OrderId : ${order.orderId}`,
			order,
			action,
			heading: `${action} Refund`,
		});
	};

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(approveRefundPageConfig.serializer(state, data));
		}
	}, [data]);

	const handleInput = (e) => {
		const { dataset, fieldValue } = inputHelper(e);
		setState(
			update(
				cloneDeep(state),
				`fields.orders[${dataset.id}].finalrefundAmount`,
				() => {
					return fieldValue;
				}
			)
		);
	};

	const handleConfirm = ({ order, action }) => {
		if (order.finalRefundAmount > order.invoiceValue) {
			return notificationServices.generateNotification({
				type: 'error',
				message: "Invoice Value can't be greater than Invoice Value",
			});
		}
		setNumberOfSubmissions(numberOfSubmissions + 1);
		approveOrRejectRefunds({
			entity: 'APPROVE_REFUNDS',
			req: {
				data: {
					approve: action === 'approve',
					finalRefundAmount: order.finalrefundAmount,
					refundId: order.refundId,
				},
			},
			method: 'UPDATE',
		});
		return {};
	};

	return (
		<div>
			<AlertModal handleConfirm={handleConfirm} />
			<ApproveRefundTable
				orders={state.fields.orders.filter((order) => {
					return order.orderId.indexOf(query) !== -1;
				})}
				handleInput={handleInput}
				openAlert={openAlert}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.form.APPROVE_REFUNDS.data.LIST;
	return {
		data,
	};
};
const mapDispatchToProps = {
	fetchApproveRefunds: formActions.gateway,
	approveOrRejectRefunds: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateRefundPage);
