/* eslint-disable */
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions, productActions } from '../../../Actions';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import cookingRecipeConfig, { depthFirstIntermediates, fetchProductsRecipe, findAllIntermediateProducts } from './cookingRecipe.config';
import { GeneralDetails } from '../../../Components/Forms/Customizations';
import {
	RecipeDetails,
	// RecipeStage,
} from '../../../Components/Forms/Cooking/Cooking Recipe';
// import { DraggableHTabs } from '../../../Components/Draggable';
// eslint-disable-next-line import/no-unresolved, import/extensions
import CookingRecipeStages from '../../../Components/Cooking/CookingRecipeStages/CookingRecipeStages';
import cookingRecipesServices from '../../../Services/Form Services/cookingRecipes.services';
import ProductCookingConfig from '@/Components/Forms/Products/ProductCookingConfig';
import { cookingConfig } from '@/Pages/Food Products/All Products/cooking.config';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABS = ['RECIPE DETAILS'];

const CookingRecipeViewPage = (props) => {
	const {
		fetchProduct,
		fetchProducts,
		data,
		breadcrumbs,
		userPermissions,
		processTypes,
		fetchProcessTypesList,
		fetchCookingStations,
		recipeData,
		fetchProductRecipe,
		navState,
		userList,
		fetchUserList,
	} = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: cloneDeep(cookingRecipeConfig.initialState.fields),
	});

	const [activeStage, setActiveStage] = useState(1);
	const [activeProcess, setActiveProcess] = useState(
		state.fields.stages.items[1]?.processes.order.length ?? -1
	);
	const [cookingFields, setCookingFields] = useState({
			fields: cloneDeep(cookingConfig.initialState.fields),
		});

	const { id } = useRouteMatch().params;

	// const { userConfig } = useAuth();
	// // userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/kitchen/cooking']?.submenu['/kitchen/cooking/recipe']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	// end user Acces

	useEffect(() => {
		fetchProduct({
			entity: 'RECIPES',
			req: {
				params: {
					productId: id,
				},
			},
			method: 'VIEW',
		});
		fetchProducts();
		fetchProcessTypesList({
			entity: 'PROCESS_TYPES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchCookingStations({
			entity: 'COOKING_STATIONS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		fetchProductRecipe({
			entity: 'COOKING_PRODUCT_CONFIG',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, [id]);

	useEffect(() => {
		setCookingFields(cookingConfig.serializer(cookingFields, recipeData));
	}, [recipeData]);
	const allIntermediateProducts = useMemo(() => {
		return findAllIntermediateProducts(data);
	}, [data]);

	useEffect(() => {
		setState({
			activeTab: 0,
			fields: cloneDeep(cookingRecipeConfig.initialState.fields),
		});
		if (!isEmpty(data)) {
			const serializedProductRecipe = cookingRecipeConfig.serializer(
				state,
				data,
				processTypes
			);
			(async () => {
				const stagesArr = cloneDeep(
					serializedProductRecipe?.fields?.stages
				);
				await depthFirstIntermediates(allIntermediateProducts, {
					...stagesArr,
					stageLevel: 1,
				}, processTypes);
				const { stages, ...remaining } =
					serializedProductRecipe?.fields;
				stagesArr.items['1'] = {
					...stagesArr.items['1'],
					recipeData: remaining,
				};
				serializedProductRecipe.fields.stages = stagesArr;
				setState(serializedProductRecipe);
				setActiveStage(1);
				setActiveProcess(
					state.fields.stages.items[1]?.processes.order.length ?? -1
				);
			})();
			// const updatedData = 
		}
	}, [data, processTypes]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const totalRecipeCookingTime = useMemo(() => {
		let totalTimeRequired = 0;
		Object.values(state?.fields?.stages?.items)?.forEach((stageValue) => {
			Object.values(stageValue?.processes?.items)?.forEach(
				(processValue) => {
					totalTimeRequired += processValue?.time;
				}
			);
		});
		return totalTimeRequired;
	}, [state?.fields]);

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<>
						<GeneralDetails fields={fields} />
						<ProductCookingConfig
							isDisable
							fields={cookingFields?.fields}
						/>
						{/* <RecipeDetails
							fields={fields}
							mandatoryFields={
								cookingRecipeConfig.mandatoryFields
							}
							isDisabled
						/> */}
						<div className='mt-2 mb-10 w-full m-auto border border-slate-600' />
						<CookingRecipeStages
							fields={fields}
							setActiveStage={setActiveStage}
							activeStage={activeStage}
							cookingRecipeConfig={cookingRecipeConfig}
							activeProcess={activeProcess}
							setActiveProcess={setActiveProcess}
							totalRequiredTime={totalRecipeCookingTime}
						/>
					</>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Recipe'
				handleEdit={redirectToEdit}
				showEdit={userPermissions?.CONFIG_EDIT ?? false}
				showDelete={false}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.product.productName}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.RECIPES.data.VIEW;
	const { userPermissions, navState } = state.navigation;
	const processTypes = state?.form?.PROCESS_TYPES?.data?.LIST;
	const recipeData = state.form.COOKING_PRODUCT_CONFIG.data.VIEW;
	const { userList } = state?.auth;
	return {
		userPermissions,
		data,
		processTypes,
		recipeData,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProduct: formActions.gateway,
	fetchProducts: productActions.fetchProducts,
	fetchProcessTypesList: formActions.gateway,
	fetchCookingStations: formActions.gateway,
	fetchProductRecipe: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingRecipeViewPage);
