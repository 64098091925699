import { Loader } from '@googlemaps/js-api-loader';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// eslint-disable-next-line object-curly-newline
import React, { useEffect, useRef, useState } from 'react';
import { useDeepCompareEffectForMaps } from '../../../Helpers';
import styles from './Map.module.scss';

const Map = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { width, height, onDragEnd, onZoomChange, children, ...options } =
		props;
	const ref = useRef(null);
	const [map, setMap] = useState();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const loader = new Loader({
			apiKey: 'AIzaSyC10NmGXi8N5-A7pvbW8dBNv2Sp0ngwsK0',
			version: 'weekly',
		});
		loader
			.load()
			.then(() => {
				setIsLoaded(true);
			})
			.catch(() => {
				// do something
			});
	}, []);

	useEffect(() => {
		if (ref.current && !map && isLoaded) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map, isLoaded]);

	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions(options);
		}
	}, [map, options]);

	useEffect(() => {
		if (map) {
			['dragend'].forEach((eventName) => {
				// eslint-disable-next-line no-undef
				return google.maps.event.clearListeners(map, eventName);
			});

			if (onDragEnd) {
				map.addListener('dragend', () => {
					onDragEnd(map);
				});
			}

			if (onZoomChange) {
				map.addListener('zoom_changed', () => {
					onZoomChange(map);
				});
			}
		}
	}, [map, onDragEnd]);
	return (
		<div className={styles.root}>
			<div
				ref={ref}
				className={styles.map}
				style={{
					width,
					height,
				}}
			/>
			<LocationOnIcon className={styles.marker} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, {
						map,
						isLoaded,
					});
				}
				return <></>;
			})}
		</div>
	);
};

Map.defaultProps = {
	zoom: 14,
	draggable: true,
	width: 250,
	height: 250,
};

export default Map;
