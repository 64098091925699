import { endpoints } from '../../Constants';
import { authHeader, handleResponse } from '../../Helpers';

const childServices = {
	gateway,
};

function gateway({ req = {}, method }) {
	switch (method) {
		case 'CHILD_LIST':
			return fetchList({
				req,
			});
		case 'EDIT_CHILD':
			return editChild({
				req,
			});
		case 'ADD':
			return addChild({
				req,
			});
		default:
			break;
	}
	return new Promise(() => {});
}

function editChild({ req }) {
	const url = `${endpoints.EDIT_CHILD}${req?.id}`;
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchList({ req }) {
	// function fetchList() {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CHILD_LIST, requestOptions).then(handleResponse);
	// return new Promise((resolve) => {
	// setTimeout(() => {
	// resolve({
	// message: 'Fetched Successfully',
	// statusCode: 200,
	// data: [
	// {
	// id: 2,
	// addressLine1: 'Name 1',
	// addressLine2: 'Class 12',
	// city: 'Section H',
	// storeName: 'Some school of some area',
	// },
	// {
	// id: 4,
	// addressLine1: 'Name 2',
	// addressLine2: 'Class 11',
	// city: 'Section G',
	// storeName: 'Some school of some area',
	// },
	// {
	// id: 6,
	// addressLine1: 'Name 3',
	// addressLine2: 'Class 10',
	// city: 'Section F',
	// storeName: 'Some school of some area',
	// },
	// {
	// id: 8,
	// addressLine1: 'Name 4',
	// addressLine2: 'Class 9',
	// city: 'Section E',
	// storeName: 'Some school of some area',
	// },
	// ],
	// });
	// }, 1000);
	// });
}
function addChild({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.ADD_CHILD, requestOptions).then(handleResponse);
}

export default childServices;
