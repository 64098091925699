/* eslint-disable no-tabs */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { CreatePageHeader } from '../../../Components/Headers';
import MenuDetails from '../../../Components/Forms/MenuDetails/MenuDetails';
import { FormSectionHeader } from '../../../Components/Forms';
import masterMenuConfig from './mastermenu.config';
import { DraggableHTabs } from '../../../Components/Draggable';
import { inputHelper, isEmpty } from '../../../Helpers';
import MenuCategory from '../../../Components/Forms/MenuDetails/MenuCategory';
import styles from './MasterMenu.module.scss';
import { formActions, modalActions } from '../../../Actions';
import TimeModal from '../../../Components/Modals/TimeSlotModal/TimeModal';
import ProductListModal from '../../../Components/Modals/ProductModal/ProductListModal';

import { modalTypes, urls } from '../../../Constants';
// import { notificationServices } from '../../../Services';

const MasterMenu = (props) => {
	const {
		toggleTimeSlots,
		toggleProducts,
		isEdit,
		data,
		fetchMenus,
		saveMenus,
		fetchProducts,
		breadcrumbs,
		toggleImageCrop,
	} = props;
	const [state, setState] = useState({
		...masterMenuConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const history = useHistory();
	const { id } = useParams();
	const { fields, errors } = state;
	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					skuType: '',
				},
			},
			method: 'LIST',
		});
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setState(masterMenuConfig.serializer(state, data));
			} else {
				fetchMenus({
					entity: 'MASTER_MENUS',
					req: {
						params: {
							id,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(masterMenuConfig.serializer(state, data));
			}
		}
	}, [data]);
	const mandatoryFields = {
		...masterMenuConfig.mandatoryFields,
	};
	const [activeCategory, setActiveCategory] = useState(
		state.fields.categories.order.length
	);
	const [activeSubCategory, setActiveSubCategory] = useState(
		state.fields.categories.items[activeCategory].subCategories.order.length
	);
	const handleSliderChange = (target, changedValue) => {
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts[${target}].discount`,
				() => {
					return changedValue;
				}
			)
		);
	};
	const handleQtyChange = (target, changedValue) => {
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts[${target}].maxQty`,
				() => {
					return changedValue;
				}
			)
		);
	};
	const handleCategoryReOrdering = (dragIndex, hoverIndex) => {
		const newState = _.update(
			_.cloneDeep(state),
			'fields.categories.order',
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setActiveSubCategory(1);
		setState(newState);
	};
	const handleSubCategoryReOrdering = (dragIndex, hoverIndex) => {
		const newState = _.update(
			_.cloneDeep(state),
			`fields.categories.items[${activeCategory}].subCategories.order`,
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};
	const addNewCategory = () => {
		let newLen = activeCategory;
		let newState = _.update(
			_.cloneDeep(state),
			'fields.categories.order',
			(arr) => {
				const len = Math.max(...arr) + 1;
				arr.push(len);
				newLen = len;
				return arr;
			}
		);
		newState = _.update(newState, 'fields.categories.items', (obj) => {
			const newObj = {
				...obj,
			};
			newObj[newLen] = _.cloneDeep(
				masterMenuConfig.initialState.fields.categories.items[1]
			);
			return newObj;
		});

		setState(newState);
		setActiveSubCategory(1);
		setActiveCategory(newLen);
	};
	const addNewSubCategory = () => {
		let newLen = activeSubCategory;
		let newState = _.update(
			_.cloneDeep(state),
			`fields.categories.items[${activeCategory}].subCategories.order`,
			(arr) => {
				const len = Math.max(...arr) + 1;
				arr.push(len);
				newLen = len;
				return arr;
			}
		);
		newState = _.update(
			newState,
			`fields.categories.items[${activeCategory}].subCategories.items`,
			(obj) => {
				const newObj = {
					...obj,
				};
				newObj[newLen] = _.cloneDeep(
					masterMenuConfig.initialState.fields.categories.items[1]
						.subCategories.items[1]
				);
				return newObj;
			}
		);

		setState(newState);
		setActiveSubCategory(newLen);
	};
	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		const newState = {};
		// for form validations and error generation
		switch (dataset.type) {
			case 'menu':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.[${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'category':
				if (fieldName !== 'categoryLogo') {
					if (fieldName === 'isEnabled') {
						setState(
							_.update(
								_.cloneDeep(state),
								`fields.categories.items[${dataset.id}].timeSlots[${fieldName}]`,
								() => {
									return fieldValue;
								}
							)
						);
					} else {
						setState(
							_.update(
								_.cloneDeep(state),
								`fields.categories.items[${dataset.id}][${fieldName}]`,
								() => {
									return fieldValue;
								}
							)
						);
					}
				}
				break;
			case 'subCategory':
				if (fieldName === 'isEnabled') {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories.items[${dataset.id}].timeSlots[${fieldName}]`,
							() => {
								return fieldValue;
							}
						)
					);
				} else if (fieldName === 'isAddOn') {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts[${dataset.id}].isAddOn`,
							() => {
								return fieldValue;
							}
						)
					);
				} else {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						)
					);
				}
				break;
			default:
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue,
						...newState,
					},
					...errors,
				});
		}
	};

	const handleLogoInput = (fieldName, blob, categoryId) => {
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.categories.items[${categoryId}].[${fieldName}]`,
				() => {
					return blob;
				}
			)
		);
	};

	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		switch (dataset.type) {
			case 'category':
				if (dataset.name === 'time-slot') {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}]timeSlots.slots`,
							(slot) => {
								const newState = slot;
								newState.splice(dataset.confirm, 1);
								return newState;
							}
						)
					);
				} else {
					setState(
						_.update(
							_.cloneDeep(state),
							'fields.categories',
							(categories) => {
								const newState = categories;
								delete newState.items[activeCategory];
								newState.order = categories.order.filter(
									(variant) => {
										return variant !== activeCategory;
									}
								);
								if (newState.order.length === 0) {
									newState.items['1'] = _.cloneDeep(
										masterMenuConfig.initialState.fields
											.categories.items['1']
									);
									newState.order.push(1);
								}
								setActiveSubCategory(1);
								setActiveCategory(newState.order[0]);
								return newState;
							}
						)
					);
				}
				break;
			case 'subCategory':
				if (dataset.name === 'time-slot') {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].timeSlots.slots`,
							(slot) => {
								const newState = slot;
								newState.splice(dataset.confirm, 1);
								return newState;
							}
						)
					);
				} else {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories`,
							(subCategories) => {
								const newState = subCategories;
								delete newState.items[dataset.id];
								newState.order = subCategories.order.filter(
									(group) => {
										return (
											group !== parseInt(dataset.id, 10)
										);
									}
								);
								if (newState.order.length === 0) {
									newState.items['1'] = _.cloneDeep(
										masterMenuConfig.initialState.fields
											.categories.items['1'].subCategories
											.items['1']
									);
									newState.order.push(1);
								}
								setActiveSubCategory(newState.order[0]);
								return newState;
							}
						)
					);
				}
				break;
			case 'product':
				if (dataset.name === 'time-slot') {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts[${dataset.id}].timeSlots`,
							(timeSlot) => {
								const newState = timeSlot;
								newState.splice(dataset.confirm, 1);
								return newState;
							}
						)
					);
				} else {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.categories.items[${activeCategory}].subCategories.items[${dataset.parent}].selectedProducts`,
							(products) => {
								const newState = products;
								newState.splice(dataset.id, 1);
								return newState;
							}
						)
					);
				}
				break;
			default:
				break;
		}
	};
	const addNewProduct = (products) => {
		const productsArr = products;
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts`,
				(arr) => {
					// if the subCategories has no product, make it default
					// else set default to false; already default in the object provided
					// push it to the array
					const newArr = [...arr, ...productsArr];
					return newArr;
				}
			)
		);
	};

	const handleReorderItems = (dragIndex, hoverIndex) => {
		const newState = _.update(
			_.cloneDeep(state),
			`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}]selectedProducts`,
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};

	const handleActiveCategory = (tempId) => {
		if (
			fields.categories.items[tempId].subCategories.order.length <
			activeSubCategory
		) {
			setActiveSubCategory(1);
		}
		setActiveCategory(tempId);
	};

	const openTimeSlots = (e, days, dates, slots) => {
		const { dataset } = inputHelper(e);
		toggleTimeSlots(true, {
			...dataset,
			days,
			dates,
			slots,
		});
	};
	const openProducts = () => {
		toggleProducts(true, {
			itemTypes: [
				'PRODUCT',
				'INTERMEDIATE',
				'INGREDIENT',
				'PACKAGING',
				'OTHER',
			],
		});
	};

	const addNewTimeSlot = ({ timeSlot, config }) => {
		// take the default object of choice/product
		const timeSlotObj = {};
		timeSlotObj.startTime = `${moment(timeSlot?.startTime).format(
			'HH:mm'
		)}`;
		timeSlotObj.endTime = `${moment(timeSlot?.endTime).format('HH:mm')}`;
		switch (config.type) {
			case 'category':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.categories.items[${config.id}].timeSlots.slots`,
						(arr) => {
							arr.push(timeSlotObj);
							return arr;
						}
					)
				);
				break;
			case 'subCategory':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.categories.items[${activeCategory}].subCategories.items[${config.id}].timeSlots.slots`,
						(arr) => {
							arr.push(timeSlotObj);
							return arr;
						}
					)
				);
				break;
			case 'product':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts.[${config.id}]`,
						(obj) => {
							return {
								...obj,
								applicableDays: timeSlot?.applicableDays,
								applicableDates: timeSlot?.applicableDates,
								timeSlots: timeSlot?.timeSlots,
							};
						}
					)
				);
				break;
			default:
				break;
		}
	};
	const handleSave = (e) => {
		e.preventDefault();
		if (masterMenuConfig.validator(fields)) {
			const req = {};
			req.data = masterMenuConfig.deserializer(state).fields;
			setNumberOfSubmissions(numberOfSubmissions + 1);
			if (isEdit) {
				req.params = {
					id,
				};
				saveMenus({
					entity: 'MASTER_MENUS',
					req,
					method: 'UPDATE',
					redirect: `${urls.EXTENSION}${urls.MASTER_MENUS}`,
				});
				fetchMenus({
					entity: 'MASTER_MENUS',
					req: {
						params: {
							id,
						},
					},
					method: 'VIEW',
				});
			} else {
				saveMenus({
					entity: 'MASTER_MENUS',
					req,
					method: 'CREATE',
					redirect: `${urls.EXTENSION}${urls.MASTER_MENUS}`,
				});
			}
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.MASTER_MENUS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.MASTER_MENUS}`);
		}
	};
	return (
		<div className={`${styles.root}`}>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Menu' : 'New Menu'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit ? `Edit ${fields.name}` : 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<ProductListModal
					handleClick={openProducts}
					handleConfirm={addNewProduct}
					selectedProdList={
						fields.categories.items[activeCategory].subCategories
							.items[activeSubCategory].selectedProducts
					}
				/>
				<TimeModal
					handleDelete={handleDelete}
					handleConfirm={addNewTimeSlot}
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<MenuDetails
						mandatoryFields={mandatoryFields}
						handleInput={handleInput}
						menuName={fields.name}
						menuDescription={fields.description}
					/>
				</FormSectionHeader>
				<FormSectionHeader sectionName=' Details'>
					<DraggableHTabs
						handleReorder={handleCategoryReOrdering}
						items={fields.categories.items}
						order={fields.categories.order}
						handleActiveTab={handleActiveCategory}
						handleAddTab={addNewCategory}
						activeTab={activeCategory}
						limit={15}
						text='Category'
					/>
					<MenuCategory
						categoryDetails={
							fields.categories.items[activeCategory]
						}
						toggleImageCrop={toggleImageCrop}
						handleLogoInput={handleLogoInput}
						handleDelete={handleDelete}
						handleInput={handleInput}
						mandatoryFields={mandatoryFields}
						activeCategory={activeCategory}
						openTimeSlots={openTimeSlots}
						fields={fields}
						activeSubCategory={activeSubCategory}
						openProducts={openProducts}
						setActiveSubCategory={setActiveSubCategory}
						handleSubCategoryReOrdering={
							handleSubCategoryReOrdering
						}
						addNewSubCategory={addNewSubCategory}
						handleSliderChange={handleSliderChange}
						handleQtyChange={handleQtyChange}
						handleSwitchChange={handleInput}
						handleReorderItems={handleReorderItems}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MASTER_MENUS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	const gateway = (...args) => {
		dispatch(formActions.gateway(...args));
	};
	return {
		toggleTimeSlots: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.TIME_SLOTS, show, data)
			);
		},
		toggleProducts: (...args) => {
			dispatch(modalActions.toggleProducts(...args));
		},
		fetchMenus: gateway,
		saveMenus: gateway,
		fetchProducts: gateway,
		toggleImageCrop: (...args) => {
			dispatch(modalActions.toggleImageCrop(...args));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterMenu);
