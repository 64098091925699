import { Add } from '@mui/icons-material';
import styles from './AddHTab.module.scss';

const AddHTab = (props) => {
	const { text } = props;
	const handleClick = () => {
		props.handleClick();
	};
	return (
		<div className={`${styles.root}`} onClick={handleClick}>
			<Add className={`${styles['add-icon']}`} />
			<span className={styles.text}>{`${text}`}</span>
		</div>
	);
};

export default AddHTab;
