/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Accordion from 'rsuite/Accordion';
import { Button } from '@mui/material';
import styles from './BulkPaymentModal.module.scss';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { ListTable } from '../../ListTable';
import MasterDetail from '../../MasterDetail/MasterDetail';

const BulkPaymentModal = (props) => {
	const history = useHistory();
	const { show, data, toggleModal } = props;
	const { heading = 'Bulk Bills Payment', vendorWiseBills = [] } = data;
	const [selectedNav, setSelectedNav] = useState(null);

	const handleClose = () => {
		toggleModal(false, {});
	};

	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				<Button
					className={styles.pay_btn}
					variant='contained'
					color='primary'
				>
					Pay
				</Button>
			</div>
		);
	};

	const renderDetailBody = (selectedVendor) => {
		return (
			<ListTable
				colMap={{
					ID: 'id',
					'Client Bill ID': 'clientTransactionId',
					Amount: 'finalTransactionAmount',
				}}
				allowExport={false}
				renderBtns={renderBtns}
				columns={[
					{
						title: 'ID',
						config: {
							type: 'DATA',
						},
					},
					{
						title: 'Client Bill ID',
						config: {
							type: 'DATA',
						},
					},
					{
						title: 'Amount',
						config: {
							type: 'DATA',
						},
					},
				]}
				data={selectedVendor?.bills}
				actions={[]}
				special={{
					Amount: (amount) => {
						// eslint-disable-next-line prefer-template
						return '\u20B9' + amount;
					},
				}}
			/>
		);
	};

	const renderNavItem = (currItem) => {
		return (
			<div className={styles.nav_item}>
				<p>{currItem?.name}</p>
				<p className={styles.unpaid_text}>
					Total Un-Paid Bills:
					{' '}
					{currItem?.bills?.length}
				</p>
			</div>
		);
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			width='80%'
			height='80%'
		>
			<div>
				<MasterDetail
					selectionList={vendorWiseBills?.map((ele) => {
						return {
							id: ele?.vendorId,
							name: ele?.vendorName,
							bills: ele?.bills,
						};
					})}
					selectedNav={selectedNav}
					setSelectedNav={setSelectedNav}
					renderDetailBody={renderDetailBody}
					selectorHead='Vendor'
					renderNavItem={renderNavItem}
				/>
				{/* <Accordion bordered>
					{vendorWiseBills?.map((ele) => {
						return (
							<Accordion.Panel header={renderBillHeader(ele)}>
							</Accordion.Panel>
						);
					})}
				</Accordion> */}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.bulkPaymentModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleBulkPaymentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkPaymentModal);
