import styles from './HamburgerToCross.module.scss';

const HamburgerToCross = (props) => {
	const { open } = props;
	return (
		<div className={`${styles.root} ${open ? styles.open : ''}`}>
			<span />
			<span />
			<span />
			<span />
		</div>
	);
};

export default HamburgerToCross;
