import styles from './CustomerDetails.module.scss';

const CustomerDetails = (props) => {
	const { fields } = props;
	return (
		<div className={styles.root}>
			<div className='form-field row'>
				<div className='form-label'>Name</div>
				<div className='form-value'>{fields.name}</div>
			</div>
			<div
				className='form-field row'
				style={{
					flexBasis: '50%',
				}}
			>
				<div className='form-label'>Address</div>
				<div className='form-value'>{fields.address}</div>
			</div>
			<div className='form-field row'>
				<div className='form-label'>Mobile Number</div>
				<div
					className='form-value'
					style={{
						width: '50%',
					}}
				>
					{fields.mobile}
				</div>
			</div>
			<div className='form-field row'>
				<div className='form-label'>Email Address</div>
				<div className='form-value'>{fields.email}</div>
			</div>
		</div>
	);
};
CustomerDetails.defaultProps = {
	fields: {
		OrderId: 1455,
		Name: 'Abcd',
		MobileNumber: 9897987989,
		Address: '145689',
		Email: '456',
		items: [
			{
				itemId: 455,
				name: 'Kharbuja',
				status: 'Delivered',
				price: 455,
			},
			{
				itemId: 455,
				name: 'Kharbuja',
				status: 'Delivered',
				price: 455,
			},
		],
	},
};
export default CustomerDetails;
