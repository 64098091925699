/* eslint-disable no-tabs */
import { Line } from 'react-chartjs-2';
import styles from './StoreRevenue.module.scss';

const StoreRevenue = (props) => {
	const { data } = props;
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles.heading}`}>
				Store Revenue and CoGS Performance
			</div>
			<div className={`${styles.chart}`}>
				<Line
					data={data?.storeRevenueData}
					options={{
						responsive: true,
						customScale: {
							grace: '40%',
						},
						scales: {
							y: {
								ticks: {
									maxTicksLimit: 10,
									stepSize: 20,
								},
							},
						},
					}}
				/>
			</div>
			<div className={`${styles.heading}`}>
				Store Gross Margin Performance
			</div>
			<div className={`${styles.chart}`}>
				<Line
					data={data?.storeMarginData}
					options={{
						responsive: true,
						scales: {
							y: {
								max: 1,
								min: -1,
								ticks: {
									stepSize: 0.1,
									maxTicksLimit: 10,
								},
								type: 'linear',
							},
						},
					}}
				/>
			</div>
		</div>
	);
};

export default StoreRevenue;
