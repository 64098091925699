import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const cookingRecipesServices = {
	gateway,
	exportCookingRecipes,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createRecipe({
				req,
			});
		case 'VIEW':
			return viewRecipe({
				req,
			});
		case 'LIST':
			return listRecipes({
				req,
			});
		case 'UPDATE':
			return updateRecipe({
				req,
			});
		case 'PATCH':
			return patchRecipe({
				req,
			});
		case 'DELETE':
			return deleteRecipe({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createRecipe({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.SAVE_RECIPE, requestOptions).then(handleResponse);
}
function patchRecipe({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.UPDATE_RECIPE_FLAGS, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function updateRecipe({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.SAVE_RECIPE, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewRecipe({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.VIEW_RECIPE, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listRecipes({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.LIST_RECIPES, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function deleteRecipe({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.CUSTOMIZATIONS, params);
	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function exportCookingRecipes({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.EXPORT_RECIPES, requestOptions);
}

export default cookingRecipesServices;
