/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import nutritionalConfig from './nutritional.config';
import {
	NutritionalDetails,
	GeneralDetails,
} from '../../../Components/Forms/Nutrition';

const TABS = ['NUTRITIONAL DETAILS'];

const NutritionalViewPage = (props) => {
	const { fetchProductNutrition, data, breadcrumbs } = props;

	const [state, setState] = useState({
		activeTab: 0,
		...nutritionalConfig.initialState,
	});
	const { id } = useRouteMatch().params;

	useEffect(() => {
		fetchProductNutrition({
			entity: 'NUTRITIONAL_INFO',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(nutritionalConfig.deserializer(state, data));
		}
	}, [data]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<fieldset disabled className='form-fieldset'>
						<GeneralDetails fields={fields} />
						<NutritionalDetails
							fields={fields}
							isDisabled
							mandatoryFields={nutritionalConfig.mandatoryFields}
						/>
					</fieldset>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Nutrition'
				handleEdit={redirectToEdit}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${state.fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.NUTRITIONAL_INFO.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProductNutrition: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NutritionalViewPage);
