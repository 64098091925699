import { Clear, Check } from '@mui/icons-material';
import styles from './ApproveRefundTable.module.scss';

const ApproveRefundTable = (props) => {
	const { orders, openAlert } = props;
	const handleInput = (e) => {
		props.handleInput(e);
	};
	return (
		<table className={`${styles['list-table-root']}`}>
			<thead>
				<tr>
					<th>Order Id</th>
					<th>Initiated At </th>
					<th>Store</th>
					<th>Invoice Value(₹)</th>
					<th>Reason</th>
					<th>RECOMMENDED REFUND AMOUNT(₹)</th>
					<th>REFUND AMOUNT(₹)</th>
					<th>Approve</th>
					<th>Reject</th>
				</tr>
			</thead>
			<tbody>
				{orders &&
					orders.map((order, idx) => {
						return (
							<tr>
								<td>
									<label>{order.orderId}</label>
								</td>
								<td>
									<label>{order.initiatedAt}</label>
								</td>
								<td>
									<label>{order.store}</label>
								</td>
								<td>
									<label>{order.invoiceValue}</label>
								</td>
								<td
									style={{
										width: '150px',
										maxWidth: '100%',
									}}
								>
									<div
										style={{
											width: '150px',
											maxWidth: '100%',
										}}
									>
										{order.reason}
									</div>
								</td>
								<td>
									<label>
										{order.recommendedRefundAmount}
									</label>
								</td>
								<td>
									<input
										type='number'
										placeholder='0'
										name='finalrefundAmount'
										required
										value={order.finalrefundAmount}
										onInput={handleInput}
										maxLength={4}
										data-id={idx}
									/>
								</td>
								<td>
									<Check
										data-id={idx}
										onClick={(e) => {
											openAlert(e, 'approve');
										}}
										className={`${styles['special-item']} ${styles.approve}`}
									/>
								</td>
								<td>
									<Clear
										data-id={idx}
										onClick={(e) => {
											openAlert(e, 'reject');
										}}
										className={`${styles['special-item']} ${styles.reject}`}
									/>
								</td>
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};
export default ApproveRefundTable;
