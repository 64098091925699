/* eslint-disable no-tabs */
import _ from 'lodash';
import moment from 'moment';
import { notificationServices } from '../../../Services';

const promoCodeConfig = {
	initialState: {
		fields: {
			couponCode: '',
			applicableFrom: null,
			applicableTo: null,
			discount: '',
			minOrderAmount: '',
			maxDiscountAmount: '',
			noOfCoupons: '',
			description: '',
			discountType: 'FIXED',

			applicableStores: [],
			applicableUsers: [],
			applicableCategories: [],
			applicableProducts: [],
			nonapplicableCategories: [],
			nonapplicableProducts: [],
		},
		errors: {
			couponCode: '',
			applicableFrom: '',
			applicableTo: '',
			discount: '',
			minOrderAmount: '',
			maxDiscountAmount: '',
			noOfCoupons: '',
			description: '',
			discountType: '',
			applicableStores: '',
			applicableUsers: '',
			applicableCategories: '',
			applicableProducts: '',
			nonapplicableCategories: '',
			nonapplicableProducts: '',
		},
	},
	mandatoryFields: {
		couponCode: true,
		applicableFrom: true,
		applicableTo: true,
		discount: true,
		minOrderAmount: true,
		maxDiscountAmount: true,
		noOfCoupons: true,
		description: true,
		discountType: true,
		applicableStores: false,
		applicableUsers: false,
		applicableCategories: false,
		applicableProducts: false,
		nonapplicableCategories: false,
		nonapplicableProducts: false,
	},
	serializer: (
		state,
		data,
		stores = [],
		users = [],
		products = [],
		category = [],
		idx
	) => {
		const storeIdsArray = data[idx].storeIds?.split(',').map(Number) ?? [];
		const selectedStore = stores.filter((obj) => {
			return storeIdsArray.includes(obj.id);
		});
		const userIdsArray = data[idx].userIds?.split(',').map(Number) ?? [];
		const selectedUsers = users.filter((obj) => {
			return userIdsArray.includes(obj.userId);
		});
		const productIdsArray =
			data[idx].applicableProducts?.split(',').map(Number) ?? [];
		const selectedProducts = products.filter((obj) => {
			return productIdsArray.includes(obj.id);
		});
		const nonAppProductIdsArray =
			data[idx].nonApplicableProducts?.split(',').map(Number) ?? [];
		const selectedNonAppProduct = products.filter((obj) => {
			return nonAppProductIdsArray.includes(obj.id);
		});
		const categoryIdsArray =
			data[idx].applicableCategories?.split(',').map(Number) ?? [];
		const selectedCategory = category.filter((obj) => {
			return categoryIdsArray.includes(obj.id);
		});
		const nonAppCategoryIdsArray =
			data[idx].applicableCategories?.split(',').map(Number) ?? [];
		const selectedNonAppCategory = category.filter((obj) => {
			return nonAppCategoryIdsArray.includes(obj.id);
		});
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				couponCode: data[idx].couponCode,
				applicableFrom: data[idx].applicableFrom,
				applicableTo: data[idx].applicableTo,
				discount: data[idx].discount,
				minOrderAmount: data[idx].minOrderAmount,
				maxDiscountAmount: data[idx].maxDiscountAmount,
				noOfCoupons: data[idx].noOfCoupons,
				description: data[idx].description,
				discountType: data[idx].discountType,
				active: data[idx].active,
				id: data[idx].id,
				createdDate: data[idx].createdDate,
				updatedDate: data[idx].updatedDate,
				applicableStores: selectedStore,
				applicableUsers: selectedUsers,
				applicableCategories: selectedCategory,
				applicableProducts: selectedProducts,
				nonapplicableCategories: selectedNonAppCategory,
				nonapplicableProducts: selectedNonAppProduct,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const selectedStore = state.fields.applicableStores.map((obj) => {
				return obj.id;
			});
			const selectedUsers = state.fields.applicableUsers.map((obj) => {
				return obj.userId;
			});

			const selectedProducts = state.fields.applicableProducts.map(
				(obj) => {
					return obj.id;
				}
			);

			const selectedNonAppProduct =
				state.fields.nonapplicableProducts.map((obj) => {
					return obj.id;
				});

			const selectedCategory = state.fields.applicableCategories.map(
				(obj) => {
					return obj.id;
				}
			);

			const selectedNonAppCategory =
				state.fields.nonapplicableCategories.map((obj) => {
					return obj.id;
				});
			const newState = {
				couponCode: state.fields.couponCode,
				applicableFrom: state.fields.applicableFrom
					? moment(state.fields.applicableFrom).format(
						'YYYY-MM-DD HH:mm:ss'
					)
					: null,
				applicableTo: state.fields.applicableTo
					? moment(state.fields.applicableTo).format(
						'YYYY-MM-DD HH:mm:ss'
					)
					: null,
				discount: parseInt(state.fields.discount, 10),
				minOrderAmount: parseInt(state.fields.minOrderAmount, 10),
				maxDiscountAmount: parseInt(state.fields.maxDiscountAmount, 10),
				noOfCoupons: parseInt(state.fields.noOfCoupons, 10),
				description: state.fields.description,
				discountType: state.fields.discountType,

				storeIds: selectedStore.toString(),
				userIds: selectedUsers.toString(),
				applicableCategories: selectedCategory.toString(),
				applicableProducts: selectedProducts.toString(),
				nonApplicableCategories: selectedNonAppCategory.toString(),
				nonApplicableProducts: selectedNonAppProduct.toString(),
			};
			return newState;
		});
	},
	validator: (state) => {
		if (
			moment(state.fields.applicableFrom).isAfter(
				state.fields.applicableTo
			)
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter valid applicable date time',
			});
			return false;
		}
		if (!state.fields.applicableFrom || !state.fields.applicableTo) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter applicable date time',
			});
			return false;
		}
		if (!parseInt(state.fields.discount, 10)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter NUmber Of coupons',
			});
			return false;
		}
		return true;
	},
};

export default promoCodeConfig;
