import { connect } from 'react-redux';
import {
	Autocomplete, Button, IconButton, MenuItem, TextField
} from '@mui/material';
import { Add, ArrowCircleRight } from '@mui/icons-material';
import {
	useEffect, useMemo, useState
} from 'react';
import { formActions, modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ProductWiseMappingModal.module.scss';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../Services';
import {
	classes, inputHelper, skuTypesListWithId
} from '../../../Helpers';
import { Tag } from '../../Tags';
import { ListModal } from '../ListModal';
import { ListTable } from '../../ListTable';
import productProcessMapServices from '../../../Services/Analytics Services/product-process-map.services';

const COLMAP = {
	'Product name': 'productName',
	'Product Category': 'productCategoryName',
	'Product Type': 'skuType',
	'Mapping Type': 'mappingType',
	'Mapping type name': 'mappingTypeElement',
	'Mapped By': 'mappingOn',
	'Mapped Element name': 'mappingOnElement',
};

const TABLE_COLUMNS = [
	{
		title: 'Product name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mapping Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mapping type name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mapped By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mapped Element name',
		config: {
			type: 'DATA',
		},
	},
];

const skuTypes = [
	{
		id: -1,
		displayName: 'ALL',
	},
	...skuTypesListWithId,
];

const ProductWiseMappingModal = (props) => {
	const {
		show,
		toggleModal,
		data,
		handleConfirm,
		productsList,
		toggleListModal,
		storesList,
		fetchProducts,
		fetchProductCategory,
		categoryList,
	} = props;
	const { heading } = data;
	const [selectedProductList, setSelectedProductList] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [productWiseMappingData, setProductWiseMappingData] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null);
	const [selectedMappingType, setSelectedMappingType] = useState(null);
	const [selectedProductCategory, setSelectedProductCategory] = useState(null);
	const [skuType, setSkuType] = useState(null);

	const handleClose = () => {
		toggleModal(false);
	};

	useEffect(() => {
		if (!show) {
			setSelectedProductList([]);
		}
	}, [show]);
	useEffect(() => {
		if (show) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
			fetchProductCategory({
				entity: 'PRODUCT_CATEGORY',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [show]);

	useEffect(() => {
		if (show) {
			if (data?.selectedMappingType) {
				setSelectedMappingType(data?.selectedMappingType);
			}
			if (data?.selectedBaseStore?.id) {
				setSelectedStore(data?.selectedBaseStore);
			}
		}
	}, [show, data]);

	const fetchProductWiseMappingData = async () => {
		if (!selectedStore?.id) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select store',
			});
			return;
		}
		const req = {
			data: {
				store: {
					id: selectedStore?.id,
				},
				mappingTypes:
					selectedMappingType?.id && selectedMappingType?.id !== -99
						? [selectedMappingType?.id]
						: null,
				categoryId:
					selectedProductCategory?.id &&
					selectedProductCategory?.id !== -99
						? selectedProductCategory?.id : null,
				products: selectedProductList?.length
					? selectedProductList
					: null,
				skuId: skuType?.id !== -1 ? skuType?.id : null,
			},
		};
		try {
			const response = await productProcessMapServices?.fetchProductWiseMappingData(req);
			if (response?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.responseMessage,
				});
				setProductWiseMappingData(response?.data?.productwiseMappings);
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
		}
	};

	const PRODUCTS = useMemo(() => {
		return productsList.map((product) => {
			return {
				id: product.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList]);

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'PRODUCT') {
			setSelectedProductList((prev) => {
				return prev.filter((product) => {
					return product !== parseInt(id, 10);
				});
			});
		}
	};

	const addFilter = (filters, context) => {
		if (context.entity === 'PRODUCT') {
			setSelectedProductList((prev) => {
				return [...prev, ...filters];
			});
		}
	};

	const handleMappingType = (e) => {
		const type = data?.availableMappingTypes?.find((ele) => {
			return ele?.id === parseInt(e?.target?.value, 10);
		});
		setSelectedMappingType(type);
	};

	const handleCategorySelection = (e) => {
		const type = categoryList?.find((ele) => {
			return ele?.id === parseInt(e?.target?.value, 10);
		});
		setSelectedProductCategory(type);
	};
	const handleSkuType = (e) => {
		const type = skuTypes?.find((ele) => {
			return ele?.id === parseInt(e?.target?.value, 10);
		});
		setSkuType(type);
	};

	const availableMappingTypes = useMemo(() => {
		const temp = [
			{
				id: -99,
				displayName: 'ALL',
			},
			...data?.availableMappingTypes ?? [],
		];
		return temp;
	}, [data?.availableMappingTypes]);

	const PRODUCT_CATEGORIES = useMemo(() => {
		const temp = [
			{
				id: -99,
				productCategoryName: 'ALL',
			},
			...categoryList,
		];
		return temp;
	}, [categoryList]);

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			customStyles={{
				width: '75rem',
				height: '95%',
			}}
			heading={heading}
			open={show}
			handleClose={handleClose}
			handleConfirm={() => {
				handleConfirm();
			}}
			showConfirm={false}
		>
			<ListModal handleConfirm={addFilter} list={PRODUCTS} />
			<div className={`${styles.root}`}>
				<div className={styles.finder}>
					<div className={styles['filters-container']}>
						<div
							style={selectedStore?.id ? {
								gap: '1rem',
							} : {}}
							className={styles['store-mapType']}
						>
							<div className={styles.store}>
								<Autocomplete
									disablePortal
									id='combo-box-demo'
									color='#FFB703'
									value={selectedStore ?? null}
									getOptionLabel={(option) => {
										return option?.name ?? option?.title;
									}}
									onChange={(event, selectedOption) => {
										setSelectedStore(selectedOption);
									}}
									options={
										storesList ?? []
									}
									sx={{
										width: '250px',
										background: 'rgba(255,255,255,.1)',
									}}
									renderInput={(params) => {
										return (
											<TextField
												{...params}
												label='Select Store'
											/>
										);
									}}
								/>
								{!selectedStore?.id && (
									<p className={styles.warning}>
										Store selection is mandatory
									</p>
								)}
							</div>
							<TextField
								select
								value={selectedMappingType?.id}
								onChange={handleMappingType}
								label='Select Mapping Type'
								sx={{
									width: '250px',
								}}
							>
								{availableMappingTypes?.map((types) => {
									return (
										<MenuItem
											key={types?.id}
											value={types?.id}
										>
											{types?.displayName}
										</MenuItem>
									);
								})}
							</TextField>
							<TextField
								select
								value={selectedProductCategory?.id}
								onChange={handleCategorySelection}
								label='Select Product category'
								sx={{
									width: '250px',
								}}
							>
								{PRODUCT_CATEGORIES?.map((types) => {
									return (
										<MenuItem
											key={types?.id}
											value={types?.id}
										>
											{types?.productCategoryName}
										</MenuItem>
									);
								})}
							</TextField>
						</div>
						<TextField
							select
							value={skuType?.id}
							onChange={handleSkuType}
							label='Select SKU Type'
							sx={{
								width: '250px',
							}}
						>
							{skuTypes?.map((type) => {
								return (
									<MenuItem
										key={type?.id}
										value={type?.id}
									>
										{type?.displayName}
									</MenuItem>
								);
							})}
						</TextField>
						<div className={classes(styles.filter, styles.product)}>
							<p className={styles['filter-heading']}>
								Select Product(s)
							</p>
							<div className={styles['filter-body']}>
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									fullWidth
									onClick={() => {
										toggleListModal(true, {
											entity: 'PRODUCT',
											heading: 'Select Product(s)',
											list: PRODUCTS,
											selected: selectedProductList,
										});
									}}
								>
									Product
								</Button>
								<div className={styles.tags}>
									{selectedProductList.map((id) => {
										const product = PRODUCTS.find((d) => {
											return id === d.id;
										});
										return (
											<Tag
												key={id}
												width='100%'
												text={product?.title}
												handleDelete={deleteFilter}
												dataAttrs={{
													'data-id': id,
													'data-entity': 'PRODUCT',
												}}
											/>
										);
									})}
									{selectedProductList?.length === 0 && (
										<span>No product selected</span>
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						onClick={fetchProductWiseMappingData}
						className={styles.submit}
					>
						<span>Find</span>
						<IconButton type='submit' className={styles.btn}>
							<ArrowCircleRight />
						</IconButton>
					</div>
				</div>
				<ListTable
					colMap={COLMAP}
					hasDateRange
					special={{}}
					data={productWiseMappingData}
					columns={TABLE_COLUMNS}
					actions={[]}
					showTotal
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.productWiseMappingModal;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const storesList = state?.form?.STORES?.data?.LIST;
	const categoryList = state.form.PRODUCT_CATEGORY.data.LIST;

	return {
		show,
		data,
		productsList,
		storesList,
		categoryList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleProductWiseMappingModal,
	toggleListModal: modalActions.toggleListModal,
	fetchProducts: formActions.gateway,
	fetchProductCategory: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductWiseMappingModal);
