// eslint-disable-next-line object-curly-newline
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import { useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { inputHelper, isEmpty } from '../../../Helpers';
import { BaseModal } from '../BaseModal';
import styles from './ExportProcessModal.module.scss';
import { modalTypes } from '../../../Constants';

const HEADING = 'Move Process To Another Stage';
const MODAL_TYPE = modalTypes.EXPORT_COOKING_PROCESS;

const ExportProcessModal = (props) => {
	const { show, data, toggleModal } = props;
	const [state, setState] = useState({
		toStage: '',
	});

	if (isEmpty(data)) return <></>;

	const handleChange = (e) => {
		const { fieldValue } = inputHelper(e);
		setState({
			...state,
			toStage: fieldValue,
		});
	};

	const handleClose = () => {
		toggleModal(false);
	};

	const handleConfirm = () => {
		props.handleConfirm({
			from: data.from.id,
			to: state.toStage,
			processId: data.processId,
		});
		handleClose();
	};

	const isFallback = data.stages.order.length === 1;

	const renderBtns = () => {
		if (isFallback) {
			return <></>;
		}
		return (
			<Button variant='contained' color='primary' onClick={handleConfirm}>
				Confirm
			</Button>
		);
	};

	return (
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
			height='18rem'
			renderBtns={renderBtns}
		>
			<div className={`${styles.root}`}>
				{isFallback ? (
					<p className={styles.fallback}>
						Please create more than 1 stage to continue
					</p>
				) : (
					<>
						<div className={styles.from}>
							<span className={styles.title}>From</span>
							<span className={styles.value}>
								{data.from.name}
							</span>
						</div>
						<ArrowRightAlt className={`${styles['arrow-right']}`} />
						<div className={styles.to}>
							<span className={styles.title}>To</span>
							<span className={styles.value}>
								<FormControl fullWidth>
									<InputLabel id='stage-select'>
										Stage
									</InputLabel>
									<Select
										labelId='stage-select'
										id='stage-select'
										value={state.toStage}
										label='Stage'
										onChange={handleChange}
									>
										{data.stages.order
											.filter((stageId) => {
												return stageId !== data.from.id;
											})
											.map((stageId) => {
												const stage =
													data.stages.items[stageId];
												return (
													<MenuItem
														value={stageId}
														key={stageId}
													>
														{stage.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</span>
						</div>
					</>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal[MODAL_TYPE];
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(MODAL_TYPE, show, data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportProcessModal);
