// eslint-disable-next-line object-curly-newline
import { Button, IconButton } from '@mui/material';
import { ArrowCircleRight, Visibility } from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import SelectPicker from 'rsuite/SelectPicker';
import { urls } from '../../../../Constants';
import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import styles from './StockInReport.module.scss';
import analyticsAction from '../../../../Actions/analytics.actions';
import ImageModal from '../../../../Components/Modals/ImageModal/ImageModal';
import { formActions, modalActions } from '../../../../Actions';
import rowStyleSheet from '../../Quality Analysis/QualityAnalysisListPage.module.scss';
import SearchBar from '../../../../Components/SearchBar/SearchBar';
// eslint-disable-next-line object-curly-newline
import { classes, convertWeightUnit, useQuery, useSearch } from '../../../../Helpers';
import StockListModal from '../../../../Components/Modals/StockListModal/StockListModal';
import { useAuth } from '../../../../Components/Auth/Login/auth';
import { VirtualizedListTable } from '@/Components/VirtualizedListTable';
import excel from '../../../../Images/excel.svg';

const TABLE_COLUMNS = [
	{
		title: 'QrCode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Stock In Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Variant',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expiry Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Weight',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Stock In Temp',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Post Stock In Qty.',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Stock Out Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Consumed Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Post Stock Out Qty.',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [];

const ACTION_COLUMNS = [];

const getRowStyles = (item) => {
	if (item.isExpired) {
		return rowStyleSheet.critical;
	}
	return rowStyleSheet.normal;
};

const COLMAP = {
	QrCode: 'qrCode',
	'Stock In Date': 'stockedInAt',
	Category: 'categoryName',
	SKU: 'skuName',
	'Vendor Name': 'vendorName',
	Variant: 'variantName',
	'Expiry Date': 'expiryTime',
	'From Store': 'originStore',
	Store: 'store',
	Weight: 'variantId',
	'Stock In Temp': 'temp',
	'Post Stock In Qty.': 'updatedStockIn',
	Image: 'skuImage',
	'Stock Out Date': 'stockedOutAt',
	'Consumed Date': 'consumedAt',
	'Post Stock Out Qty.': 'updatedStockOut',
};

const PLACE_HOLDERS = {
	QR_CODE: 'Search by QR Code',
	SKU_NAME: 'Search by SKU Name',
};

const FIELD_MAP = {
	QR_CODE: 'qrCode',
	SKU_NAME: 'skuName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'QR_CODE',
			title: 'QR Code',
			get: 'qrCode',
		},
		{
			key: 'SKU_NAME',
			title: 'SKU Name',
			get: 'skuName',
		},
	],
};

const StockInReport = (props) => {
	const history = useHistory();
	const {
		dateRangeFilter,
		setDateRangeFilter,
		fetchData,
		data,
		toggleModal,
		searchQuery,
		searchBy,
		setIMSSearchResults,
		toggleStockListModal,
		stores,
		applyStoreFilter,
		selectedStores,
		type = 'STOCK_IN',
		fetchStores,
	} = props;
	const isStockConsume = type === 'STOCK_CONSUME';
	const colFilterFields = isStockConsume
		? [
			'Vendor Name',
			'Stock Out Date',
			'Post Stock In Qty.',
			'Stock In Temp',
			'Post Stock Out Qty.',
		]
		: ['From Store', 'Consumed Date'];
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const [state, setState] = useState({
		showStockedOut: false,
		showStockReceived: false,
	});
	const { showStockedOut } = state;
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [data]);
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	const queryParams = useQuery();

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setIMSSearchResults('', data, 'QR_CODE');
		}
	}, [queryParams]);

	useEffect(() => {
		fetchStores({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleShowStockedOutClick = () => {
		const show = !showStockedOut;
		setState({
			...state,
			showStockedOut: show,
		});
		fetchData('INVENTORY_ANALYTICS', 'STOCK_IN_LIST', {
			params: {
				stockedOut: !isStockConsume && show,
			},
		});
	};

	const getData = () => {
		if (dateRangeFilter.from != null && dateRangeFilter.to != null) {
			fetchData('INVENTORY_ANALYTICS', 'STOCK_IN_LIST', {
				params: {
					stockedOut: !isStockConsume && showStockedOut,
					stockReceived: isStockConsume,
				},
			});
		}
	};

	const SPECIAL_ITEMS = {
		'From Store': (storeId) => {
			const store = stores?.find((ele) => {
				return ele?.id === storeId;
			});
			return store?.name;
		},
		Store: (storeId) => {
			const store = stores?.find((ele) => {
				return ele?.id === storeId;
			});
			return store?.name;
		},
		Image: (image) => {
			const openModal = () => {
				toggleModal(true, {
					image,
					heading: 'Stocked In SKU',
				});
			};
			return <Visibility onClick={openModal} className={styles.view} />;
		},
		Weight: (variantId) => {
			const item = data?.find((ele) => {
				return ele?.variantId === variantId;
			});
			let unit = item?.unit;
			if (!unit && measurement === 'US') {
				unit = 'oz';
			}
			// const formattedWeight =
			// weight >= 1000
			// ? (weight / 1000)?.toFixed(2)
			// : weight?.toFixed(1);
			// const weightUnit = weight >= 1000 ? 'kg' : 'g';
			return <span>{convertWeightUnit(item?.weight, unit)}</span>;
		},
		'Stock In Temp': (temp) => {
			// // eslint-disable-next-line react/destructuring-assignment
			// const formattedtemp = (`${temp}`)?.toFixed(2);
			return <span>{temp}</span>;
		},
	};

	const setDate = (newValues) => {
		const { startDate: from, endDate: to } = newValues?.selection ?? {};
		setDateRangeFilter({
			from,
			to,
			active: true,
		});
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const generateData = () => {
		const filteredData = data.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[searchField]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		setIMSSearchResults(query, filteredData, SEARCH_BY?.options?.[searchField]?.key);
		return filteredData;
	};

	const handleStoreChange = (value) => {
		// const { fieldValue } = inputHelper(e);
		if (value === -999) {
			applyStoreFilter(
				{
					active: true,
					selectedStores: [],
				},
				false
			);
		} else {
			applyStoreFilter(
				{
					active: true,
					selectedStores: [value],
				},
				false
			);
		}
		fetchData('INVENTORY_ANALYTICS', 'STOCK_IN_LIST', {
			params: {
				stockedOut: !isStockConsume && showStockedOut,
				stockReceived: isStockConsume,
			},
		});
	};

	const BASE_STORES = useMemo(() => {
		const tempStoreList = stores?.map((ele) => {
			return {
				...ele,
				label: ele?.name,
				role: ele?.type,
				value: ele?.id,
			};
		});
		tempStoreList?.unshift({
			id: -999,
			label: 'All',
			role: 'ALL',
			value: -999,
		});
		return tempStoreList;
	}, [stores]);

	const exportData = () => {
		const downloadBtn = document.createElement('a');
		const header = TABLE_COLUMNS?.map((ele) => {
			return ele?.title;
		});

		const fileData = data?.map((ele) => {
			return TABLE_COLUMNS?.map((col) => {
				const value = ele?.[COLMAP?.[col?.title]];
				if (col?.title === 'Store') {
					return SPECIAL_ITEMS?.[col?.title]?.(value);
				}
				if (col?.title === 'Weight') {
					const weight = SPECIAL_ITEMS?.[col?.title]?.(value);
					return weight?.props?.children;
				}
				return value;
			});
		});

		const csvData = [[...header], ...fileData];
		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;
		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = 'Stock In Report';
		downloadBtn.click();
	};

	return (
		<div className={`list-page-root ${styles.root} ${rowStyleSheet.root}`}>
			<StockListModal />
			<p className={styles['page-name']}>
				{isStockConsume ? 'Stock Consumption' : 'Stock In Report'}
			</p>
			<div className={styles.header}>
				<SearchBar
					value={query}
					placeholder={PLACE_HOLDERS[searchField]}
					handleInput={handleSearch}
					handleChange={handleSearchBy}
					searchBy={
						SEARCH_BY != null
							? {
								...(SEARCH_BY ?? {}),
								current: searchField,
							}
							: null
					}
					className={styles.search}
				/>
				{/* <TextField
					select
					label='Select Store'
					defaultValue='All'
					className={styles['store-select']}
					value={selectedStores[0]}
					onChange={handleStoreChange}
					sx={{
						'.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
							{
								paddingTop: '6px',
								paddingBottom: '6px',
							},
					}}
				>
					<MenuItem key='All' value='All'>
						ALL
					</MenuItem>
					{stores?.map((store) => {
						return (
							<MenuItem key={store?.id} value={store?.id}>
								{store?.name}
							</MenuItem>
						);
					})}
				</TextField> */}
				<SelectPicker
					data={BASE_STORES}
					style={{
						width: 224,
					}}
					groupBy='role'
					placeholder='Select Store'
					onChange={(storeId) => {
						handleStoreChange(storeId);
					}}
					renderMenuItem={(_) => {
						return (
							<div>
								<i className='rs-icon rs-icon-user' />
								{_}
							</div>
						);
					}}
					renderMenuGroup={(_) => {
						return (
							<div>
								<i className='rs-icon rs-icon-group' />
								{_}
							</div>
						);
					}}
					cleanable={false}
					value={selectedStores?.length ? selectedStores?.[0] : -999}
					renderValue={(value, item) => {
						return (
							<div className='rsuite-select-picker-render-value'>
								{item?.label}
							</div>
						);
					}}
				/>
				<div className={styles['toggle-btns']}>
					{!isStockConsume && (
						<Button
							color='secondary'
							variant={showStockedOut ? 'contained' : 'outlined'}
							className={styles['show-stocked-out']}
							onClick={handleShowStockedOutClick}
						>
							{showStockedOut
								? 'Hide Stocked Out'
								: 'Show Stocked Out'}
						</Button>
					)}
				</div>
				<div className={styles['total-items']}>
					Total:
					{' '}
					{
						generateData().filter((obj) => {
							return (
								obj?.[
									FIELD_MAP?.[
										SEARCH_BY?.options?.[searchField]?.key
									]
								]
									?.toLowerCase()
									?.indexOf(query?.trim()?.toLowerCase()) !==
								-1
							);
						})?.length
					}
					<Button
						variant='contained'
						onClick={() => {
							const date = new Date();
							const hours = date.getHours();
							const mins = date.getMinutes();
							const time =
								hours > 12
									? `${hours - 12}:${mins} PM`
									: `${hours}:${mins} AM`;
							return toggleStockListModal(true, {
								data: generateData(),
								time,
							});
						}}
					>
						View Details
					</Button>
				</div>
				<Calender value={dateRangeFilterArray} setValue={setDate} />
				<div
					className={styles.downloadData}
					onClick={exportData}
				>
					<img src={excel} alt='excel' />
				</div>
				<IconButton
					type='submit'
					className={classes(
						styles.btn,
						shouldAnimate ? styles?.animate : '',
						isDataFetched ? styles?.disabled : ''
					)}
					onClick={getData}
				>
					<ArrowCircleRight />
				</IconButton>
			</div>
			<VirtualizedListTable
				fixedHeaderTop={0}
				hasDateRange
				colMap={COLMAP}
				columns={TABLE_COLUMNS?.filter((ele) => {
					return !colFilterFields?.includes(ele?.title);
				})}
				data={generateData()
					.filter((obj) => {
						return (
							obj?.[
								FIELD_MAP?.[
									SEARCH_BY?.options?.[searchField]?.key
								]
							]
								?.toLowerCase()
								?.indexOf(query?.trim()?.toLowerCase()) !== -1
						);
					})
					.map((obj, idx) => {
						const rowStyles = getRowStyles(obj);
						const qrCode = obj?.qrCode;
						const scanId = obj?.id;
						return {
							...obj,
							id: idx + 1,
							rowStyles,
							onClick: () => {
								if (!isStockConsume) {
									history.push(
										`${urls.EXTENSION}${urls.PAST_IMS}/${qrCode}?id=${scanId}`
									);
								}
							},
						};
					})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				allowExport={false}
			/>
			<ImageModal />
		</div>
	);
};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { STOCK_IN_LIST: data } = state.analytics.INVENTORY_ANALYTICS;
	const { query: searchQuery, searchBy } = state.analytics.INVENTORY_ANALYTICS.SEARCH_DATA;
	const stores = state?.form?.STORES?.data?.LIST;
	const { selectedStores } = state.analytics.STORE_FILTER;
	return {
		dateRangeFilter,
		data,
		searchQuery,
		searchBy,
		stores,
		selectedStores,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchData: analyticsAction.fetchDataRange,
	toggleModal: modalActions.toggleImageModal,
	toggleStockListModal: modalActions.toggleStockListModal,
	setIMSSearchResults: analyticsAction.setIMSSearchResults,
	applyStoreFilter: analyticsAction.setStoreFilter,
	fetchStores: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockInReport);
