import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import {
	CookingRecipeCreatePage,
	CookingRecipeViewPage,
} from '../../../Pages/Cooking/Cooking Recipe';
import CookingRecipeListPage from '../../../Pages/Cooking/Cooking Recipe/CookingRecipeListPage';

import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const CookingRecipeRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.COOKING_RECIPE}`,
			name: 'Recipe',
		},
	];
	return (
		<CRUDRoutes
			entity='COOKING_PRODUCT_CONFIG'
			path={path}
			listHeader='Cooking Recipe'
			listPage={CookingRecipeListPage}
			viewPage={<CookingRecipeViewPage breadcrumbs={breadcrumbs} />}
			editPage={<CookingRecipeCreatePage isEdit breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default CookingRecipeRoutes;
