import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const brandAnalysisServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'LIST_DATA':
			return fetchList(req);
		case 'TOP_DEFECTS':
			return fetchTopDefects(req);
		case 'TOP_PRODUCTS':
			return fetchTopProducts(req);
		default:
			break;
	}
	return new Promise();
}

function fetchList(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.BRAND_ANALYSIS_LIST, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch0(url, requestOptions, handleResponse);
}

// function mockFetch0() {
// const mockResponse = {
// data: [
// {
// brand: 89,
// brandName: 'North Star',
// items: 25,
// defects: 20,
// defectPercent: 60,
// },
// {
// brand: 23,
// brandName: 'Homely Foods',
// items: 125,
// defects: 420,
// defectPercent: 80,
// },
// ],
// message: 'Fetched successfully',
// status: 'Success',
// statusCode: 200,
// };
//
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function fetchTopDefects(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.BRAND_TOP_DEFECTS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions, handleResponse);
}

// function mockFetch1() {
// const mockResponse = {
// data: [
// {
// name: 'Broken Momo',
// count: 25,
// },
// {
// name: 'Missing Garnishing',
// count: 40,
// },
// {
// name: 'Missing Gravy',
// count: 80,
// },
// {
// name: 'Burnt Item',
// count: 8,
// },
// ],
// message: 'Fetched successfully',
// status: 'Success',
// statusCode: 200,
// };
//
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function fetchTopProducts(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.BRAND_TOP_PRODUCTS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions, handleResponse);
}

// function mockFetch2() {
// const mockResponse = {
// data: [
// {
// name: 'Noodles',
// count: 125,
// },
// {
// name: 'Rice Bowl',
// count: 340,
// },
// {
// name: 'Pasta',
// count: 480,
// },
// {
// name: 'Thalis',
// count: 448,
// },
// {
// name: 'Burger',
// count: 418,
// },
// {
// name: 'Pizza',
// count: 78,
// },
// {
// name: 'Coffee',
// count: 1278,
// },
// {
// name: 'Tea',
// count: 2278,
// },
// {
// name: 'Water',
// count: 1500,
// },
// {
// name: 'Vada Pav',
// count: 900,
// },
// ],
// message: 'Fetched successfully',
// status: 'Success',
// statusCode: 200,
// };
//
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

export default brandAnalysisServices;
