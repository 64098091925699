/* eslint-disable no-unused-vars */
// eslint-disable-next-line object-curly-newline
import { Badge, Button, IconButton } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowCircleRight, Tune } from '@mui/icons-material';
import analyticsAction from '../../../../Actions/analytics.actions';
import SearchBar from '../../../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import { classes, usePagination, useQuery, useSearch } from '../../../../Helpers';
import styles from './AiGrnHistory.module.scss';
import AiOutwardingListTable from './AiGrnListTable';
import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import { formActions, modalActions } from '../../../../Actions';
import excel from '../../../../Images/excel.svg';
import FilterModal from '../../../../Components/Filter Modal/FilterModal';

const ANALYTICS_TYPE = analyticsTypeConstant.AI_GRN_ANALYSIS_LIST_DATA;

const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	ORDER_ID: 'Search by Order Id',
	ITEM_ID: 'Search by Item Id',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	PRODUCT_NAME: 'ProductName',
	ORDER_ID: 'OrderId',
	ITEM_ID: 'itemId',
	VALIDATED: 'validation',
	IDENTIFIED: 'identification',
	FEATURES: 'annotated',
	DUPLICATE: 'duplicate',
	// BRAND_NAME: 'brandName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'PRODUCT_NAME',
			title: 'Product Name',
			get: 'ProductName',
		},
		{
			key: 'ORDER_ID',
			title: 'Order ID',
			get: 'OrderId',
		},
		{
			key: 'ITEM_ID',
			title: 'Item ID',
			get: 'itemId',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const FILTERS = [
	'BRAND_FILTER',
	'UNDETECTED_PRODUCTS_FILTER',
	'MEAL_TYPE_FILTER',
	'VALIDATED_DATA_FILTER',
	'FEATURES_DATA_FILTER',
	'IDENTIFIED_DATA_FILTER',
	'PRODUCT_COUNT_FILTER',
	'PROCESS_TYPE_FILTER',
	'SKU_TYPE_FILTER',
	'STORE_FILTER',
	'FROM_STORE_FILTER',
	'DUPLICATE_DATA_FILTER',
];

const AiGrnHistory = (props) => {
	const {
		dateRangeFilter,
		data,
		setDateRangeFilter,
		fetchData,
		setQCSearchResults,
		searchQuery,
		searchBy,
		// applyFilters,
		toggleModal,
		brandFilter,
		brandData,
		gateway,
		exportAiGrnAnalytics,
	} = props;

	const { page, limit } = usePagination({
		customLimit: 10,
	});

	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [data]);

	// console.log('1', data);
	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		setShouldAnimate(true);
	};

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
		if (brandData.length === 0) {
			gateway({
				entity: 'BRANDS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setQCSearchResults('', data, 'ORDER_ID');
		}
	}, [queryParams]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const generateData = () => {
		const filteredData = data.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		setQCSearchResults(
			query,
			filteredData,
			SEARCH_BY?.options?.[searchField]?.key
		);
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	const getData = () => {
		const { from, to } = dateRangeFilter;
		if (from != null && to != null) {
			fetchData({
				params: {
					page,
					limit,
				},
				data: {
					qcType: 'INWARD',
				},
			});
			setIsDataFetched(true);
		}
	};

	// const applyBrandFilter = (selectedList) => {
	// applyFilters({
	// BRAND_FILTER: {
	// selectedList,
	// },
	// });
	// };

	return (
		<div className={styles.root}>
			{/* <ListModal
				handleConfirm={applyBrandFilter}
				selectionBehaviour='SHOW'
			/> */}
			<FilterModal
				type='quality'
				filters={FILTERS}
				clear={queryParams.has('clear')}
			/>
			<div className={styles.header}>
				<SearchBar
					value={query}
					placeholder={PLACE_HOLDERS[searchField]}
					handleInput={handleSearch}
					handleChange={handleSearchBy}
					searchBy={
						SEARCH_BY != null
							? {
								...(SEARCH_BY ?? {}),
								current: searchField,
							}
							: null
					}
					className={styles.search}
				/>
				<Badge
					className={styles['brand-filter']}
					badgeContent={brandFilter?.selectedList?.length ?? 0}
					color='primary'
				>
					<Button
						variant='outlined'
						onClick={() => {
							toggleModal(true, {
								heading: 'Filter',
								list: brandData.map((brand) => {
									return {
										id: brand.id,
										title: brand.name,
									};
								}),
								selected: brandFilter.selectedList,
							});
						}}
					>
						Filters
						<Tune fontSize='medium' />
					</Button>
				</Badge>
				<div>
					Total:
					{' '}
					{generateData().length}
				</div>
				<Calender value={dateRangeFilterArray} setValue={setDate} />
				<div
					onClick={exportAiGrnAnalytics}
					className={styles.downloadData}
				>
					<img src={excel} alt='excel' />
				</div>
				<IconButton
					type='submit'
					className={classes(
						styles.btn,
						shouldAnimate ? styles?.animate : '',
						isDataFetched ? styles?.disabled : ''
					)}
					onClick={getData}
				>
					<ArrowCircleRight />
				</IconButton>
			</div>
			<AiOutwardingListTable
				data={generateData()}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				fetchDataAfterSetting={false}
			/>
		</div>
	);
};

AiGrnHistory.defaultProps = {};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter, BRAND_FILTER: brandFilter } =
		state.analytics;
	const { LIST_DATA: data } = state.analytics.AI_GRN_ANALYSIS;
	// console.log('3', data);
	const brandData = state.form.BRANDS.data.LIST;
	const { query: searchQuery, searchBy } =
		state.analytics.WASTAGE_HISTORY.SEARCH_DATA;
	return {
		dateRangeFilter,
		searchQuery,
		data,
		searchBy,
		brandData,
		brandFilter,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setQCSearchResults: analyticsAction.setQCSearchResults,
	fetchData: analyticsAction.fetchData,
	toggleModal: modalActions.toggleFilterModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
	exportAiGrnAnalytics: analyticsAction.exportAiGrnAnalytics,
};
export default connect(mapStateToProps, mapDispatchToProps)(AiGrnHistory);
