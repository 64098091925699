import _ from 'lodash';

const CookingStationsConfig = {
	initialState: {
		fields: {
			name: '',
			image: null,
			cameraEnabled: false,
		},
		errors: {
			name: '',
			image: '',
			cameraEnabled: '',
		},
	},
	mandatoryFields: {
		name: true,
		image: true,
		cameraEnabled: true,
	},
	api: [
		{
			name: 'Amit Verma',
			cameraEnabled: 'abcdef',
			image: null,
		},
	],
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.stationName,
				cameraEnabled: data.isStationCameraEnabled,
				image: data.stationImageUrl,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = {
			stationName: state.fields.name,
			isStationCameraEnabled: state.fields.cameraEnabled,
			stationImageUrl: state.fields.image,
		};
		return newState;
	},
};

export default CookingStationsConfig;
