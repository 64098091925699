import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../Pages';
import AddRevenuePage from '../../../Pages/Accounts/MyRevenue/AddRevenuePage/AddRevenuePage';
import MyRevenue from '../../../Pages/Accounts/MyRevenue/MyRevenue';

const RevenueRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MyRevenue createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage createPage={<AddRevenuePage />} entity='REVENUE' />
			</Route>
		</Switch>
	);
};

export default RevenueRoutes;
