import { AccessTime } from '@mui/icons-material';

const TimeRequired = (props) => {
	const {
		classname,
		totalRequiredTime = 0,
		heading = 'Total Required Time:',
	} = props;
	let timeText = `${totalRequiredTime} mins`;
	if (totalRequiredTime > 60) {
		timeText = `${Math.floor(totalRequiredTime / 60)}hour ${Math.floor(
			totalRequiredTime % 60
		)}mins`;
	}
	return (
		<div className={`${classname} flex items-center gap-1`}>
			<p className='text-sm'>{heading}</p>
			<div className='flex items-center gap-2'>
				<AccessTime />
				<p className='font-semibold'>{timeText}</p>
			</div>
		</div>
	);
};

export default TimeRequired;
