import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from '@mui/material';
import { formActions } from '../../../Actions';
import { inputHelper } from '../../../Helpers';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { VirtualizedListTable } from '@/Components/VirtualizedListTable';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [
	{
		title: 'Recipe Management',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Time Required',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Cooking Required',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	'Recipe Management': 'isCMSEnabled',
	'Time Required': 'cookingTime',
	'Cooking Required': 'isCookingEnabled',
};

const CookingProductConfigListPage = (props) => {
	const { fetchProductRecipe, toggleDisable, data, query } = props;

	const handleSwitch = (e) => {
		const { fieldValue, dataset, fieldName } = inputHelper(e);
		toggleDisable({
			entity: 'COOKING_PRODUCT_CONFIG',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					[fieldName]: fieldValue,
				},
			},
			method: 'EDIT',
		});
	};

	const SPECIAL_ITEMS = {
		'Recipe Management': (id, isCMSEnabled) => {
			return (
				<Switch
					name='isCMSEnabled'
					value={isCMSEnabled}
					size='small'
					color='primary'
					onChange={handleSwitch}
					defaultChecked={isCMSEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'COOKING_PRODUCT_CONFIG',
					}}
				/>
			);
		},
		'Time Required': (id, value) => {
			if (value) {
				return <span>{`${value ?? '-'} mins`}</span>;
			}
			return <></>;
		},
		'Cooking Required': (id, isCookingEnabled) => {
			return (
				<Switch
					name='isCookingEnabled'
					value={isCookingEnabled}
					size='small'
					color='primary'
					onChange={handleSwitch}
					defaultChecked={isCookingEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'COOKING_PRODUCT_CONFIG',
					}}
				/>
			);
		},
	};

	useEffect(() => {
		fetchProductRecipe({
			entity: 'COOKING_PRODUCT_CONFIG',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className='list-page-root'>
			<VirtualizedListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={[...TABLE_COLUMNS]}
				data={data.filter((product) => {
					return (
						product.productName
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.COOKING_PRODUCT_CONFIG.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProductRecipe: formActions.gateway,
	toggleDisable: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingProductConfigListPage);
