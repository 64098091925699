import { useState } from 'react';
import { connect } from 'react-redux';
import { BaseModal } from '../BaseModal';
import DragDropUploader from '@/Pages/Analytics/Inventory Analytics/UploadPurchaseOrder/DragDropUploader/DragDropUploader';
import { modalActions } from '@/Actions';

function UploadDataModal(props) {
	const [file, setFile] = useState({
		fileName: '',
		file: '',
	});
	const {
		show,
		handleImport,
		heading,
		toggleUploadDataModal,
		auditId,
		fileAcceptable = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		storeId,
	} = props;
	const confirmSelection = (uploadedFile) => {
		toggleUploadDataModal(false, {});
		if (auditId && storeId) {
			handleImport(auditId, uploadedFile, storeId);
		} else {
			handleImport(uploadedFile);
		}
	};
	const handleClose = () => {
		setFile(
			{
				fileName: '',
				file: '',
			}
		);
		toggleUploadDataModal(false, {});
	};
	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			handleConfirm={() => confirmSelection(file)}
		>
			<div className='flex items-center gap-5'>
				<DragDropUploader
					uploadType='csvFile'
					uploadedFile={file}
					setUploadedFile={setFile}
					fileAcceptable={fileAcceptable}
				/>
			</div>

		</BaseModal>
	);
}
const mapStateToProps = (state) => {
	const { show, payload } = state.modal.uploadDataModal;
	return {
		show,
		payload,
	};
};
const mapDispatchToProps = {
	toggleUploadDataModal: modalActions.toggleUploadDataModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadDataModal);
