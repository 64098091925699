/* eslint-disable no-tabs */
import { format } from 'date-fns';
// import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import { AiHistoryFinder } from '../../../Components/Forms/AiHistoryFinder';
import { ListTable } from '../../../Components/ListTable';
// import { DATE_FORMAT } from '../../../Constants';
import styles from './AIModelHistoryPage.module.css';

const COLMAP = {
	'Model Type': 'modelType',
	Mode: 'imsMode',
	Store: 'storeName',
	Product: 'productName',
	'Created At': 'createdAt',
	Status: 'status',
	User: 'user',
	'Updated At': 'updatedAt',
};

const AiDateFormat = 'yyyy-MM-dd';
const TABLE_COLUMNS = [
	{
		title: 'Model Type',
		config: {
			type: 'DATA',
		},
	},
	// {
	// 	title: 'Store',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	{
		title: 'Mode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'User',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Updated At',
		config: {
			type: 'DATA',
		},
	},
];

function AIModelHistoryPage(props) {
	const {
		fetchModelHistory,
		data,
		// fetchStoresList
	} = props;

	const fetchHistoryData = (date, reqData = {}) => {
		const [startDate, endDate] = date;
		const { customerId: clientId } = JSON.parse(
			localStorage.getItem('appConfig')
		);

		fetchModelHistory({
			entity: 'AI_MODEL',
			method: 'LIST',
			showNotification: true,
			req: {
				data: {
					clientId,
					store: [],
					model: '',
					startDate: format(startDate, AiDateFormat),
					endDate: format(endDate, AiDateFormat),
					...reqData,
				},
			},
		});
	};

	// useEffect(() => {
	// 	fetchStoresList({
	// 		entity: 'STORES',
	// 		req: {
	// 			params: {
	// 				id: '',
	// 			},
	// 		},
	// 		method: 'LIST',
	// 	});
	// }, []);

	return (
		<div className={styles.root}>
			<AiHistoryFinder fetchHistoryData={fetchHistoryData} />
			<ListTable
				hasDateRange
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data}
				actions={[]}
				special={{}}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { LIST } = state.form.AI_MODEL.data;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	return {
		data: LIST,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	fetchModelHistory: formActions.gateway,
	// fetchStoresList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIModelHistoryPage);
