/* eslint-disable no-tabs */
import styles from './DropDownIconInput.module.scss';

const DropDownInput = (props) => {
	const {
		id,
		name,
		value,
		handleInput,
		dataType,
		dataId,
		dataAttrs,
		size,
		max,
		step,
		selectValue,
	} = props;

	const CustomIcon = () => {
		return (
			<select
				id={`${name}Unit`}
				name={`${name}Unit`}
				value={selectValue}
				onChange={handleInput}
				className={styles.select}
			>
				<option value='hours'>hrs</option>
				<option value='days'>days</option>
				<option value='months'>months</option>
			</select>
		);
	};
	return (
		<div className={`${styles.root} ${styles[size]}`}>
			<div className={`${styles['icon-wrapper']}`}>
				<CustomIcon />
			</div>
			<input
				type='number'
				min='0'
				max={max}
				name={name}
				onInput={handleInput}
				id={id}
				step={step}
				value={value}
				className={`${styles.input} form-value`}
				data-type={dataType}
				data-id={dataId}
				{...dataAttrs}
			/>
		</div>
	);
};

DropDownInput.defaultProps = {
	size: 'small',
	max: 9999,
	iconText: '',
	dataAttrs: {},
	step: '1',
};

export default DropDownInput;
