import { BaseModal } from '../Modals/BaseModal';
import AssetGroupSelectionSummary from './AssetGroupSelectionSummary';

const GroupAssetCartModal = ({ open, onClose, cartItems, groupedAssets, extraData = {} }) => {
	return (
		<BaseModal
			open={open}
			handleClose={onClose}
			heading={extraData?.heading || 'Cart Items'}
			width='80%'
			height='80%'
			showConfirm={false}
		>
			<AssetGroupSelectionSummary
				extraData={extraData}
				cartItems={cartItems}
				groupedAssets={groupedAssets}
			/>
		</BaseModal>
	);
};

export default GroupAssetCartModal;
