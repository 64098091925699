import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const createAlert = (message) => {
	confirmAlert({
		customUI: ({ onClose }) => {
			return (
				<div className='create-alert-root'>
					<p className='heading'>ERROR</p>
					<p className='message'>{message}</p>
					<button
						type='button'
						className='create-alert-btn'
						onClick={() => {
							return onClose();
						}}
					>
						Okay
					</button>
				</div>
			);
		},
	});
};

export default createAlert;
