import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import selected from '../../../Images/selected.png';
import blank from '../../../Images/blank.png';
import styles from './CheckListItem.module.scss';

const CheckListItem = (props) => {
	const { item, isSelected, displayCheck, handleItemSelect, isExpandable } =
		props;
	const handleClick = () => {
		handleItemSelect(parseInt(item.id, 10), isSelected);
	};
	const handleItemExpand = () => {
		props.handleItemExpand(parseInt(item.id, 10));
	};
	return (
		<div className={`${styles['div-container']}`}>
			<div
				className={`${styles.root} ${
					isSelected ? styles.selected : ''
				}`}
				onClick={handleClick}
				data-item={item.id}
			>
				{displayCheck && (
					<div className={`${styles['img-container']}`}>
						<img
							src={isSelected ? selected : blank}
							className={styles['check-img']}
							alt={item}
						/>
					</div>
				)}
				<span className={`${styles.title}`}>
					{item?.title ?? item?.name}
				</span>
			</div>
			<div className={`${styles['arrow-button']}`}>
				{isExpandable && (
					<IconButton onClick={handleItemExpand} size='large'>
						<ArrowForwardIcon />
					</IconButton>
				)}
			</div>
		</div>
	);
};
CheckListItem.defaultProps = {
	isSelected: false,
	displayCheck: false,
	isExpandable: true,
	handleItemExpand: () => {},
};
export default CheckListItem;
