import {
	CheckBox,
	CheckBoxOutlineBlank,
	CheckCircle,
	CircleOutlined,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { classes } from '../../Helpers';
import styles from './CheckTile.module.scss';

const CheckTile = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		checked,
		title,
		onCheck,
		onExpand,
		isCheckable,
		dataAttrs,
		size,
		isRadio,
		image,
		rotate,
	} = props;

	const handleCheck = (event) => {
		event.stopPropagation();
		onCheck(dataAttrs);
	};

	const handleExpand = () => {
		onExpand(dataAttrs);
	};

	const renderCheckButton = () => {
		if (isRadio) {
			return checked ? <CheckCircle /> : <CircleOutlined />;
		}
		return checked ? <CheckBox /> : <CheckBoxOutlineBlank />;
	};

	return (
		<div
			className={classes(
				styles.root,
				checked ? styles.checked : '',
				styles[size]
			)}
			onClick={handleExpand}
		>
			{image != null && (
				<img
					src={image}
					alt={title}
					className={rotate ? styles['rotate-img'] : ''}
					width='40px'
				/>
			)}
			<IconButton
				aria-label='check'
				onClick={handleCheck}
				className={classes(
					styles['check-btn'],
					isCheckable ? styles.show : ''
				)}
				size='large'
			>
				{renderCheckButton()}
			</IconButton>
			<div className={classes(styles.title)}>
				<span>{title}</span>
			</div>
		</div>
	);
};

CheckTile.defaultProps = {
	onCheck: () => {},
	isCheckable: true,
	onExpand: () => {},
	dataAttrs: {},
	size: 'md',
	isRadio: false,
	image: null,
	rotateImg: false,
};

export default CheckTile;
