import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import styles from './ReportsConfiguration.module.scss';
import ListTable from '../../../Components/ListTable/ListTable';
import { modalActions } from '../../../Actions';
import reportsActions from '../../../Actions/reports.actions';
import CustomToggleRadio from '../../../Components/CustomRadio/CustomToggleRadio';
import reportsServices from '../../../Services/reportsServices';
import { notificationServices } from '../../../Services';
import { ReportEmailsModal } from '../../../Components/Modals';
import ReportsConfigurationHeader from './ReportsConfigurationHeader/ReportsConfigurationHeader';

const TABLE_COLUMNS = [
	{
		title: 'Report Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Description',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last updated',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Enable/Disable',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Emails',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'Report Name': 'reportName',
	Description: 'description',
	'Last updated': 'updatedAt',
};

function ReportsConfiguration(props) {
	const { fetchReportsList, list, toggleReportEmailsModal } = props;
	const [isLoading, setIsLoading] = useState({});

	const updateReport = async ({ reportId, isActive, emails }) => {
		const req = {
			data: {
				isActive,
				emails,
				reportId,
			},
		};
		setIsLoading({
			[reportId]: true,
		});
		try {
			const response = await reportsServices?.updateReportList(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				fetchReportsList();
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
		} finally {
			setIsLoading({
				[reportId]: false,
			});
		}
	};

	useEffect(() => {
		fetchReportsList();
	}, []);

	const SPECIAL_ITEMS = {
		'Enable/Disable': (id) => {
			const item = list?.find((ele) => {
				return ele?.id === id;
			});
			const onChange = () => {
				updateReport({
					reportId: id,
					isActive: !item?.isReportActive,
				});
			};
			return (
				<CustomToggleRadio
					isLoading={isLoading?.[id]}
					checked={item?.isReportActive}
					onChange={onChange}
				/>
			);
		},
		Emails: (id) => {
			const item = list?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles['emails-container']}>
					<div className={styles.emails}>
						<p>
							{item?.emails?.join(', ')?.substring(0, 45)}
							...
						</p>
					</div>
					<IconButton
						onClick={() => {
							return toggleReportEmailsModal(true, {
								item,
							});
						}}
						className={styles['edit-btn']}
					>
						<Edit />
					</IconButton>
				</div>
			);
		},
	};

	return (
		<div className={styles.root}>
			<ReportEmailsModal handleConfirm={updateReport} />
			<ReportsConfigurationHeader />
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={list}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { list } = state.reports;
	return {
		list,
	};
};

const mapDispatchToProps = {
	toggleReportEmailsModal: modalActions.toggleReportEmailsModal,
	fetchReportsList: reportsActions.fetchReportsList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReportsConfiguration);
