/* eslint-disable eqeqeq */
import { cloneDeep } from 'lodash';
import { notificationServices } from '../../../Services';

const initialState = {
	name: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_NAME':
			return {
				...state,
				name: action.payload,
			};
		case 'SET_EDIT_DATA':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = {
	name: true,
};

const validator = (data) => {
	if (!data?.name || data?.name?.trim().length == 0) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please input the service name',
		});
		return false;
	}
	return true;
};

const deserializer = (state) => {
	const clonedState = cloneDeep(state);
	const req = {
		name: clonedState?.name,
	};

	return req;
};

const config = {
	initialState,
	reducer,
	mandatoryFields,
	validator,
	deserializer,
};

export default config;
