/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Button, IconButton, MenuItem, TextField } from '@mui/material';
import { useEffect, useReducer, useState, useMemo } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './CreditNoteCreatePage.module.scss';
import config from './CreditNoteCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../Services';
import { ListModal } from '../../../Components/Modals';
import { formActions, modalActions } from '../../../Actions';
import licenseServices from '../../../Services/license-services';
import BackdropLoader from '../../../Components/Loaders/BackdropLoader';
import { classes, getStateFromGSTIN, inputHelper, useQuery } from '../../../Helpers';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import { POItemSelection } from '../../../Components/POItemSelection';
import CustomInputSelector from '../../../Components/CustomInputSelector/CustomInputSelector';
import { IconInput } from '../../../Components/Forms/Inputs';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { AddNewVendorModal } from '../../../Components/Modals/AddNewVendorModal';
import { POTotalContainer } from '../../../Components/POTotalContainer';
import { urls } from '../../../Constants';

function CreditNoteCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		processTypes,
		fetchProcessTypesList,
		fetchProducts,
		fetchVendors,
		vendors,
		toggleAddVendorModal,
		productsList,
		createCreditPageType,
		navState,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const {
		fromStore,
		store,
		creditingDate,
		dueDate,
		creditId,
		orderId,
		vendor: selectedVendor,
		salesReturnId,
	} = state ?? {};
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const billId = Number(params.get('billId'));
	const srId = Number(params.get('srId'));
	const [orderItems, setOrderItems] = useState([]);
	const isCreditNote = createCreditPageType === 'creditNote';
	// const orderId = parseInt(params.get('orderId'), 10);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (
			billId &&
			!isCreditNote &&
			vendors?.length > 0 &&
			stores?.length > 0
		) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.getTransactionRecord({
						params: {
							transactionId: billId,
						},
					});
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								vendor: vendors?.find((ele) => {
									return ele?.id === response?.data?.vendorId;
								}),
								// items: response?.data?.map((item) => {
								// 	return {

								// 	};
								// }),
								fromStore: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								store: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.storeId
									);
								}),
								orderId: response?.data?.orderId,
								billId: response?.data?.id,
								// orderId: response?.data?.orderId,
							},
						});
						setOrderItems(response?.data?.items);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [billId, stores, isCreditNote]);

	useEffect(() => {
		if (
			isCreditNote &&
			srId &&
			stores?.length > 0
		) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.getTransactionRecord({
						params: {
							transactionId: srId,
						},
					});
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								// vendor: vendors?.find((ele) => {
								// return ele?.id === response?.data?.vendorId;
								// }),
								// items: response?.data?.map((item) => {
								// 	return {

								// 	};
								// }),
								fromStore: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								store: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.storeId
									);
								}),
								salesReturnId: response?.data?.id,
								orderId: response?.data?.orderId,
							},
						});
						setOrderItems(response?.data?.items);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [srId, stores, isCreditNote]);

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer({
			...state,
			fromStore: !srId ? navSelectedStore?.value : state?.fromStore,
		});
		// const validate = config?.validator(state);
		// if (validate?.field) {
		// 	notificationServices?.generateNotification({
		// 		type: 'error',
		// 		// eslint-disable-next-line prefer-template
		// 		message: validate?.displayName + ' is mandatory',
		// 	});
		// 	return;
		// }
		const req = {
			data: {
				...deserializedData,
				type: 'CREDIT',
			},
		};
		setLoading(true);
		try {
			const response = await inventoryAnalyticsServices.createTransactionRecord(
				req
			);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const setItemsState = (items) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				items,
			},
		});
	};

	const PRODUCTS = useMemo(() => {
		return productsList?.filter((product) => {
			return (
				product?.skuType === 'INTERMEDIATE' ||
				product?.skuType === 'PRODUCT' ||
				product?.skuType === 'INGREDIENT'
			);
		});
	}, [productsList]);

	const handleKeyPress = (e) => {
		const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			// setState(`SET_${key}`, {});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					[key]: {},
				},
			});
		}
	};

	// const handleToStore = (newToStore) => {
	// 	dispatch({
	// 		type: 'SET_VALUE',
	// 		payload: {
	// 			toStore: newToStore,
	// 		},
	// 	});
	// };
	const handleFromStore = (newFromStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				fromStore: newFromStore,
			},
		});
	};
	const handleStore = (newFromStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				store: newFromStore,
			},
		});
	};
	const handleVendorSelection = (vendor) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				vendor,
			},
		});
	};

	const disabledDate = (rsuiteDate) => {
		return rsuiteDate > new Date();
	};

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalAmount = 0;
		let taxValues = {};
		const totalQuantity = state?.items?.length;
		state?.items?.forEach((ele) => {
			if (ele?.unit === 'gram') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
							ele?.selectedVariant?.price * (ele?.quantity / 1000)
					  );
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 1000);
			} else if (ele?.unit === 'oz') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
							ele?.selectedVariant?.price * (ele?.quantity / 16)
					  );
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 16);
			} else {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(ele?.selectedVariant?.price * ele?.quantity);
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
			}
		});
		taxValues.SGST = totalTax / 2;
		taxValues.CGST = totalTax / 2;
		if (
			state?.vendor?.id &&
			state?.vendor?.gstin &&
			state?.deliverTo?.gstNumber
		) {
			const vendorState = getStateFromGSTIN(state?.vendor?.gstin);
			const storeState = getStateFromGSTIN(state?.deliverTo?.gstNumber);
			if (vendorState !== storeState) {
				taxValues = {
					IGST: totalTax,
				};
			}
		}
		return {
			subTotal,
			totalQuantity,
			totalTax,
			totalAmount,
			taxValues,
		};
	}, [state?.items]);

	return (
		<div className={styles.root}>
			<AddNewVendorModal />
			<BackdropLoader isLoading={loading} showProgress />
			{/* <ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/> */}
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>Add New Credit Note</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				{!isCreditNote && selectedVendor?.id && (
					<div className={classes(styles['vendor-container'])}>
						<div className={styles['label-container']}>
							<label
								className={styles.mandatory}
								htmlFor='vendorSelection'
							>
								Vendor Name*
							</label>
							<CustomInputSelector
								inputName='VENDOR'
								width='300px'
								inputLists={vendors?.map((ele) => {
									return {
										...ele,
										imageUrl: ele?.logo,
									};
								})}
								handleKeyPress={handleKeyPress}
								handleSelection={handleVendorSelection}
								addNewText='Add New Vendor'
								placeholder='Select A Vendor'
								selectedValue={selectedVendor?.name}
								classname={styles['vendor-selector']}
								handleAddNew={
									() =>
										// eslint-disable-next-line implicit-arrow-linebreak, arrow-body-style
										toggleAddVendorModal(true, {})
									// eslint-disable-next-line react/jsx-curly-newline
								}
							/>
						</div>
						{(selectedVendor?.billingAddress1 ||
							selectedVendor?.billingAddress1 ||
							selectedVendor?.gstin) && (
							<div className={styles['label-container']}>
								<label htmlFor='vendorDetails' />
								<div className={styles['vendor-details']}>
									{selectedVendor?.gstin && (
										// eslint-disable-next-line react/jsx-one-expression-per-line
										<p>GSTIN: {selectedVendor?.gstin}</p>
									)}
									<div className={styles['address-container']}>
										{selectedVendor?.billingAddress1 && (
											<div className={styles.address}>
												<p className={styles.header}>
													Billing Address
												</p>
												<p>
													{
														selectedVendor?.billingAddress1
													}
												</p>
												<p>
													{
														selectedVendor?.billingAddress2
													}
												</p>
												{selectedVendor?.billingAddressCity && (
													<p>
														{`${selectedVendor?.billingAddressCity} ,${selectedVendor?.billingAddressState}`}
													</p>
												)}
												<p>
													{
														selectedVendor?.billingAddressPinCode
													}
												</p>
											</div>
										)}
										{selectedVendor?.shippingAddress1 && (
											<div className={styles.address}>
												<p className={styles.header}>
													Pickup Address
												</p>
												<p>
													{
														selectedVendor?.shippingAddress1
													}
												</p>
												<p>
													{
														selectedVendor?.shippingAddress2
													}
												</p>
												{selectedVendor?.shippingAddressCity && (
													<p>
														{`${selectedVendor?.shippingAddressCity} ,${selectedVendor?.shippingAddressState}`}
													</p>
												)}
												<p>
													{
														selectedVendor?.shippingAddressPinCode
													}
												</p>
											</div>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				)}
				{!isCreditNote && fromStore?.id && (
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Crediting store*
						</label>
						<CustomInputSelector
							inputName='fromStore'
							width='300px'
							inputLists={stores?.map((ele) => {
								return {
									...ele,
									imageUrl: ele?.logo,
								};
							})}
							handleKeyPress={handleKeyPress}
							handleSelection={handleFromStore}
							hideAddNew
							placeholder='Credit By Store'
							selectedValue={fromStore?.name}
							classname={styles['store-selector']}
						/>
					</div>
				)}
				{isCreditNote && (
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							For Store*
						</label>
						<CustomInputSelector
							inputName='store'
							width='300px'
							inputLists={stores?.map((ele) => {
								return {
									...ele,
									imageUrl: ele?.logo,
								};
							})}
							handleKeyPress={handleKeyPress}
							handleSelection={handleStore}
							hideAddNew
							placeholder='Credit note for Store'
							selectedValue={store?.name}
							classname={styles['store-selector']}
						/>
					</div>
				)}
				{!isCreditNote && (
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							To Store*
						</label>
						<div>{store?.name ?? navSelectedStore?.value?.name}</div>
					</div>
				)}
				{/* <div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						 Store*
					</label>
					<div>{fromStore?.name ?? navSelectedStore?.value?.name}</div>
				</div> */}
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Credit ID*
					</label>
					<IconInput
						type='custom'
						size='extra-large'
						inputType='text'
						value={creditId}
						handleInput={(e) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									creditId: e?.target?.value,
								},
							});
						}}
						style={{
							width: '200px',
						}}
					/>
				</div>
				<div className={styles.multi}>
					{!isCreditNote && (
						<div className={`${styles.tile} form-field row`}>
							<label
								className='form-label'
							>
								Bill Id
							</label>
							<p>{billId}</p>
						</div>
					)}
					{isCreditNote && (
						<div className={`${styles.tile} form-field row`}>
							<label
								className='form-label'
							>
								Sales return id
							</label>
							<p>{salesReturnId}</p>
						</div>
					)}
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Order id
						</label>
						<TextField
							id='orderNo'
							type='text'
							name='orderId'
							value={orderId}
							onChange={(e) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										orderId: e?.target?.value,
									},
								});
							}}
							placeholder='Order id'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Crediting Date*
						</label>
						<DatePickerCustom
							selectedDate={creditingDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										creditingDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 200,
							}}
							oneTap
							defaultValue={creditingDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Due Date
						</label>
						<DatePickerCustom
							selectedDate={dueDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										dueDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 200,
							}}
							oneTap
							defaultValue={dueDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
				</div>
			</div>
			<div className={styles['items-details-container']}>
				<POItemSelection
					state={state?.items}
					setState={setItemsState}
					defaultSelectedItems={orderItems}
					productsList={PRODUCTS}
					requiredFields={{
						fromStore: true,
						deliverTo: true,
						poId: true,
						date: true,
						expectedDeliveryDate: true,
						paymentTerms: false,
						generateStandardPO: false,
					}}
					disabled={false}
				/>
				<POTotalContainer calculatedData={calculatedData} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	return {
		userPermissions,
		stores,
		vendors,
		processTypes,
		productsList,
		navState,
	};
};
// ['PRODUCT', 'INTERMEDIATE']

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchVendors: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditNoteCreatePage);
