/* eslint-disable */
import { Add } from '@mui/icons-material';
import { useEffect } from 'react';
import { classes } from '@/Helpers';
import styles from '../ExportFilterDrawer.module.scss';
import { Button } from '@/Components/ui/button';
import { Tag } from '@/Components/Tags';

const MultiSelectFilter = (props) => {
	const {
		filterData,
		filterProps,
		state,
		deleteFilter,
		toggleListModal,
		compDataFromProp,
		disabled = false,
	} = props;
	const {
		compData = [],
		dependentOn = -1,
		label = '',
		selectedValueKey,
		defaultState,
		onDependencyUpdate = () => {},
	} = filterProps;
	const dependency = filterData?.[dependentOn];
	const isDisabled =
		(dependentOn !== -1 && !state?.[dependency?.selectedValueKey]) || disabled;
	const selectedValue = state?.[selectedValueKey] ?? defaultState;

	useEffect(() => {
		onDependencyUpdate(state?.[dependency?.selectedValueKey]);
	}, [state?.[dependency?.selectedValueKey]]);

	return (
		<div className={classes(styles.filter)}>
			<p
				className={classes(
					styles['filter-heading'],
					filterProps?.mandatory ? 'text-red-500' : ''
				)}
			>
				{label}
				{filterProps?.mandatory ? '*' : ''}
			</p>
			<div className={styles['filter-body']}>
				<Button
					variant='outlined'
					startIcon={<Add />}
					className={styles['add-button']}
					disabled={isDisabled}
					fullWidth
					onClick={() => {
						toggleListModal(true, {
							callee: 'exportFilter',
							entity: selectedValueKey,
							selectedValueType: typeof defaultState,
							heading: `Select ${label}(s)`,
							list: compDataFromProp ?? compData,
							selected: selectedValue,
						});
					}}
				>
					{label}
				</Button>
				<div className={styles.tags}>
					{selectedValue.map((id) => {
						const currValue = compData?.find((v) => {
							return id === v?.id;
						});
						return (
							<Tag
								key={currValue?.id}
								width='100%'
								text={currValue?.title}
								handleDelete={deleteFilter}
								dataAttrs={{
									'data-id': currValue?.id,
									'data-entity': selectedValueKey,
									'data-dataType': typeof defaultState,
								}}
							/>
						);
					})}
					{selectedValue?.length === 0 && (
						<span>All {label} Selected</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default MultiSelectFilter;
