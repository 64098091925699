import { getDate, getWeekOfMonth } from 'date-fns';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { timeFilterMap } from '@/Constants';

const filterDrawerConfig = {
	initialState: {
		levels: [
			{
				heading: 'Select Filter',
				type: null,
			},
		],
		TIME_FILTER: '',
		GEO_FILTER: {
			state: [],
			city: [],
			store: [],
		},
		BRAND_FILTER: {
			selectedList: [],
		},
		STORE_FILTER: {
			active: false,
			selectedStores: [],
		},
		PROCESS_TYPE_FILTER: {
			active: false,
			selectedProcessTypes: [],
		},
		SKU_TYPE_FILTER: {
			active: false,
			selectedSkuTypes: [],
		},
		FROM_STORE_FILTER: {
			active: false,
			selectedStores: [],
		},
		DATE_FILTER: {
			text: getDate(new Date()),
			active: true,
			type: 'day',
			selection: {
				year: new Date().getFullYear(),
				month: timeFilterMap.month[new Date().getUTCMonth()],
				week: getWeekOfMonth(new Date(), {
					weekStartsOn: 1,
				}),
				day: getDate(new Date()),
				hour: null,
			},
		},
		FAILURE_ANALYSIS_FILTER: {
			WEIGHT: {
				'over-weight': false,
				'under-weight': false,
			},
			TEMPERATURE: {
				'over-temperature': false,
				'under-temperature': false,
			},
			DEFECT: {
				applied: false,
				defects: [],
			},
		},
		STATISTICAL_FILTER: {
			selectedBrands: [],
			selectedProductIds: [],
			selected: false,
			filter: 'Weight',
		},
		UNDETECTED_PRODUCTS_FILTER: {
			applied: false,
			selected: [],
		},
		LIST_DATA_FILTER: {
			validated: null,
			identified: null,
			features: null,
		},
		DUPLICATE_DATA_FILTER: {
			applied: false,
			value: null,
		},
		VALIDATED_DATA_FILTER: {
			applied: false,
			value: null,
		},
		IDENTIFIED_DATA_FILTER: {
			applied: false,
			value: null,
		},
		FEATURES_DATA_FILTER: {
			applied: false,
			value: null,
		},
		PRODUCT_COUNT_FILTER: {
			applied: false,
			count: [],
		},
		MODE_TYPE_FILTER: {
			selectedList: [],
		},
		MEAL_TYPE_FILTER: {
			applied: false,
			selected: [],
			list: [
				{
					id: 1,
					title: 'Breakfast',
				},
				{
					id: 2,
					title: 'Lunch',
				},
				{
					id: 3,
					title: 'Snacks',
				},
				{
					id: 4,
					title: 'Dinner',
				},
			],
		},
	},
};
export default filterDrawerConfig;
