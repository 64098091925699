/* eslint-disable no-tabs */
// eslint-disable-next-line object-curly-newline

import { endpoints } from '../Constants';
import { authHeader } from '../Helpers';
import handleResponse from './helpers';

const stateCityServices = {
	getStateCity,
};

// const API = {
// 	message: 'Success',
// 	status: 'Success',
// 	statusCode: 200,
// 	data: [
// 		{
// 			cityId: '1',
// 			cityName: 'Mumbai',
// 			stateId: '1',
// 			stateName: 'Maharastra',
// 		},
// 		{
// 			cityId: '2',
// 			cityName: 'Delhi',
// 			stateId: '2',
// 			stateName: 'Delhi',
// 		},
// 		{
// 			cityId: '3',
// 			cityName: 'Chandigarh HQ',
// 			stateId: '3',
// 			stateName: 'Chandigarh',
// 		},
// 		{
// 			cityId: '4',
// 			cityName: 'Calicut',
// 			stateId: '5',
// 			stateName: 'Kerala',
// 		},
// 		{
// 			cityId: 5,
// 			cityName: 'Ahmedabad HQ',
// 			stateId: 6,
// 			stateName: 'Gujarat',
// 		},
// 		{
// 			cityId: '6',
// 			cityName: 'Calcutta',
// 			stateId: '7',
// 			stateName: 'West Bengal',
// 		},
// 		{
// 			cityId: '8',
// 			cityName: 'Lucknow  HQ',
// 			stateId: '9',
// 			stateName: 'Uttar Pradesh',
// 		},
// 		{
// 			cityId: '10',
// 			cityName: 'Ghaziabad',
// 			stateId: '9',
// 			stateName: 'Uttar Pradesh',
// 		},
// 		{
// 			cityId: '11',
// 			cityName: 'Noida',
// 			stateId: '9',
// 			stateName: 'Uttar Pradesh',
// 		},
// 		{
// 			cityId: '12',
// 			cityName: 'Merrut',
// 			stateId: '9',
// 			stateName: 'Uttar Pradesh',
// 		},
// 		{
// 			cityId: '9',
// 			cityName: 'Mumbai',
// 			stateId: '10',
// 			stateName: 'Maharashtra',
// 		},
// 	],
// };

function getStateCity() {
	const url = endpoints.STATE_CITY_MAPPING;

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// 	return new Promise((resolve) => {
	// 		resolve(API);
	// 	});
}

export default stateCityServices;
