/* eslint-disable */
import { HighlightOff } from '@mui/icons-material';
import { classes } from 'src/Helpers';
import { CheckPicker, SelectPicker } from 'rsuite';
import { useState } from 'react';
import styles from '../POItemSelection/TableRow/TableRow.module.scss';
import { Input } from '../ui/input';
import { notificationServices } from '@/Services';
import CoreSpecifications from '../CreateQuote/CoreSpecifications';
import { Chip } from '@mui/material';

function VendorTable(props) {
	const {
		row,
		setRow,
		oemList,
		rowStyles,
		maxQty,
		assetData,
	} = props;

	const mode = ['supplier', 'rfq'];
	const tableRows = (rowItem, index) => {
		return {
			'Asset Type': () => (
				<Input
					style={{
						width: 200,
					}}
					disabled
					value={rowItem.assetType}
				/>
			),
			OEM: () => {
				const oem = assetData?.find(asset => asset?.name === rowItem?.assetType)?.assetCategory?.oems;
				console.log(oem, 'oem');
				const displayOem = rowItem?.oem?.map(oem => ({
					id: oem?.id,
					name: oem?.label
				})) || [];
				return (
				<>
				<div>
  				{Array.isArray(displayOem) && 
   					displayOem.map((item, index) => (
    					<Chip key={index} label={item?.name || 'N/A'} />
					))
				}
				<SelectPicker
						data={oem?.map((item) => ({
							label: item?.name,
							value: item?.id,
						}))}
						style={{
							width: 200,
						}}
						menuStyle={{
							width: 200,
						}}
						placeholder='Select OEM'
						value={rowItem?.oems?.id}
						onChange={(value) => {
							const selectedOem = oemList?.find((ele) => ele.id === value);
							if (selectedOem) {
								const updateRow = [...row];
								updateRow[index].oems = {
									id: selectedOem.id,
									name: selectedOem.name,
								};
								console.log(updateRow, 'updateRow');
								setRow(updateRow);
							}
							console.log(selectedOem, 'selectedOem');
						} } />
						</div>
						</>
				);
			},
			'Core Specification': () => {
				const newCoreSpecification = rowItem?.specifications.map((spec) => {
					const existingSpec = row[index]?.coreSpecification?.find((item) => item.id === spec.id);
					return existingSpec ? { ...spec, value: existingSpec.value === 'true' ? true : existingSpec.value === 'false' ? false : existingSpec.value } : spec;
				});
				return (
					<CoreSpecifications
						specifications={newCoreSpecification}
						mode= {mode}
						onValueChange={(specId, value) => {
						const updatedRow = [...row];
						if (!updatedRow[index]?.specifications) {
						  updatedRow[index] = {
							...updatedRow[index],
							specifications: [],
						  };
						}
					
						updatedRow[index].specifications = updatedRow[index].specifications.map((spec) =>
						  spec.id === specId
							? {
								...spec,
								value,
							  }
							: spec
						);
					
						setRow(updatedRow);
					  }}
					/>
				);
			},
			'Total Amount': () => (
				<Input
					type='text'
					style={{
						width: 150,	
					}}
					placeholder='Enter the amount'
					value={rowItem.amount}
					onChange={(e) => {
						 const updateRow = [...row];
						 updateRow[index].amount = e.target.value;
						 setRow(updateRow);
					}}
				/>
			),
			QUANTITY: () => {
				const value = maxQty.find((val) => val?.id === rowItem?.id)?.qty;
				return (
					<Input
						style={{
							width: 100,
						}}
						value={rowItem?.qty}
						onChange={(e) => {
							const enteredValue = Number(e.target.value);
							if (enteredValue <= value) {
								const updateRow = [...row];
								updateRow[index].qty = enteredValue;
								setRow(updateRow);
							} else {
								notificationServices.generateNotification({
									type: 'error',
									message: 'Quantity cannot be greater than the given quantity',
								});
								const updateRow = [...row];
								updateRow[index].qty = value;
								setRow(updateRow);
							}
						}}
					/>
				);
			},
		};
	};
	return row.map((rowItem, index) => {
		const currentRowData = tableRows(rowItem, index);
		return (
			<tr
				key={rowItem?.id}
				className={classes(styles['table-tr'], rowStyles)}
			>
				{Object.keys(currentRowData).map((ele) => {
					return (
						<td
							style={{
								verticalAlign: 'middle',
							}}
							key={ele}
						>
							{currentRowData[ele]()}
						</td>
					);
				})}
			</tr>
		);
	});
}

export default VendorTable;