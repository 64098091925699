// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { urls } from '../../../Constants';
import { AiProductionHistory, ProductImagesPage } from '../../../Pages/Analytics/ProductAnalysis';

const getSubURL = (entity) => {
	const baseEntity = urls.AI_PRODUCTION;
	return entity.slice(baseEntity.length);
};

const AiProductionRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.PRODUCT_IMAGES)}`}>
				<ProductImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_PRODUCTION_HISTORY)}`}>
				<AiProductionHistory />
			</Route>
		</Switch>
	);
};

export default AiProductionRoutes;
