import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	analyticsAction,
	formActions,
	modalActions,
} from '../../../../Actions';
import { ListTable } from '../../../../Components/ListTable';
import WorkOrderItemsModal from '../../../../Components/Modals/WorkOrderItemsModal/WorkOrderItemsModal';
// import { notificationServices } from '../../../../Services';
import styles from './WorkOrderList.module.css';
import WorkOrderListHeader from './WorkOrderListHeader/WorkOrderListHeader';

const TABLE_COLUMNS = [
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Scheduled Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	'Order Id': 'id',
	'Scheduled Date': 'scheduledAt',
	'Order Status': 'orderStatus',
	// Store: 'storeName',
};

function WorkOrderList(props) {
	const { data, toggleModal, fetchProducts, productsList } = props;

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'VIEW',
		});
	}, []);

	const handleViewClick = (order) => {
		const variants = {};
		order?.items?.forEach((batch) => {
			if (!variants[batch?.variantId]) {
				const product = productsList?.find((prod) => {
					return prod?.id === batch?.productId;
				});
				variants[batch?.variantId] = {
					id: product?.id,
					name: product?.productName,
					variant: product?.variants?.find((variant) => {
						return variant?.id === batch.variantId;
					}),
					image: product?.productImageUrl,
				};
			}
		});
		const items = Object?.keys(variants)?.map((key) => {
			const batches = order?.items?.filter((batch) => {
				return batch?.variantId === variants[key]?.variant?.id;
			});
			return {
				...variants[key],
				batches,
			};
		});
		toggleModal(true, {
			heading: `Batches of #${order?.id}`,
			items,
		});
	};

	const SPECIAL_ITEMS = {};

	const listData = Object.keys(data)?.map((key) => {
		return data[key];
	});

	return (
		<div className={styles.root}>
			<WorkOrderItemsModal />
			<div className={styles.header}>
				<WorkOrderListHeader />
			</div>
			<div
				style={{
					width: '100%',
					overflow: 'auto',
					height: 'calc(100vh - 13rem)',
				}}
			>
				<ListTable
					fixedHeaderTop={0}
					hasDateRange
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={listData?.map((listItem) => {
						const scheduledDate =
							listItem?.scheduledAt?.split(' GMT')[0];
						const scheduledAt =
							moment(scheduledDate).format('DD-MMM-YYYY');
						return {
							...listItem,
							scheduledAt,
						};
					})}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { WORK_ORDER_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	const { VIEW: productsList } = state.form.ALL_PRODUCTS.data;

	return {
		data,
		stores,
		dateRangeFilter,
		productsList,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.toggleWorkOrderItemsModal,
	fetchProducts: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderList);
