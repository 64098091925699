import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PieChartIcon from '@mui/icons-material/PieChart';
// eslint-disable-next-line object-curly-newline
import { useHistory, useLocation } from 'react-router';
import { IconButton, Tooltip } from '@mui/material';
import { urls } from '../../../../Constants';
import styles from './AmountContainer.module.scss';
import { generateRandomKey } from '../../../../Helpers';

const AmountContainer = (props) => {
	const { color, amountCaption, renderText } = props;
	const history = useHistory();
	const { pathname } = useLocation();
	const returnIcon = (itemName) => {
		const onClickHandler = () => {
			history.push(`${urls.EXTENSION}${urls.STORE_ANALYSIS}/table`);
		};
		const onTempClickHandler = () => {
			history.push(`${urls.EXTENSION}${urls.STORE_ANALYSIS}`);
		};
		switch (itemName) {
			case 'list':
				// eslint-disable-next-line no-constant-condition
				return pathname.split('/').length === 4 ? (
					<IconButton onClick={onClickHandler} size='large'>
						<FormatListBulletedIcon />
					</IconButton>
				) : (
					<IconButton onClick={onTempClickHandler} size='large'>
						<PieChartIcon />
					</IconButton>
				);
			default:
				return <></>;
		}
	};
	return (
		<div className={`${styles.root}`} {...generateRandomKey()}>
			<div
				className={`${styles['amount-container']}`}
				style={{
					backgroundColor: color,
				}}
			>
				<div className={`${styles['icon-container']}`}>
					{amountCaption.itemIcon &&
						returnIcon(amountCaption.itemIcon)}
				</div>
				<Tooltip title={amountCaption.amount}>
					{renderText ? renderText() : (
						<>
							<p className={`${styles.amount}`}>{amountCaption.amount}</p>
							{!!amountCaption.percentageValue && (
								<p className={`${styles.percentage}`}>
									{amountCaption.percentageValue}
								</p>
							)}
						</>
					)}
				</Tooltip>
			</div>
			<p className='w-full text-center text-[0.75rem] text-slate-600 font-semibold'>{amountCaption.caption}</p>
			<p className='w-full text-center pt-0 m-0 text-[10px] text-slate-500'>{amountCaption.subText}</p>
		</div>
	);
};
AmountContainer.defaultProps = {
	amountObj: {
		color: '#ff6961',
		caption: 'Total Revenue',
		amount: '1088.95',
	},
	onClick: () => {},
	itemIcon: '',
};
export default AmountContainer;
