import styles from './AllVendorsListPage.module.scss';

const AddressPanel = (props) => {
	const {
		addressType, address1, address2, city, state, pincode,
	} = props?.addressDetails;
	return (
		<div className={styles.address}>
			<p className={styles.address_type}>{addressType}</p>
			<div className={styles.field}>
				<label>Address 1:</label>
				<p>{address1}</p>
			</div>
			<div className={styles.field}>
				<label>Address 2:</label>
				<p>{address2}</p>
			</div>
			<div className={styles.field}>
				<label>City:</label>
				<p>{city}</p>
			</div>
			<div className={styles.field}>
				<label>State:</label>
				<p>{state}</p>
			</div>
			<div className={styles.field}>
				<label>Pin Code:</label>
				<p>{pincode}</p>
			</div>
		</div>
	);
};

export default AddressPanel;
