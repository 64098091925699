import EnableDisable from '../../EnableDisable/EnableDisable';
import IconInput from '../../Inputs/IconInput';
import styles from './InventoryManagement.module.scss';

const TrackingParameters = (props) => {
	const { mandatoryFields, fields, errors, handleInput } = props;

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '100%',
				}}
			>
				<EnableDisable
					name='bulkMode'
					text='Bulk Consumption'
					handleIsEnabled={handleInput}
					dataAttrs={{
						'data-type': 'bulkMode',
					}}
					isEnabled={fields.bulkMode}
					condition
				>
					<div className={`form-field row ${styles.enableDisable}`}>
						<label
							htmlFor='trackingQuantityUnit'
							data-mandatory={
								mandatoryFields.trackingQuantityUnit
							}
							className='form-label'
						>
							Tracking Unit
						</label>
						<select
							id='trackingQuantityUnit'
							name='trackingQuantityUnit'
							value={fields.trackingQuantityUnit}
							onChange={handleInput}
							className='quantityUnit'
						>
							<option value='gram'>Gram</option>
							<option value='ml'>mL</option>
							<option value='count'>Count</option>
						</select>
						<span className='form-error'>
							{errors.trackingQuantityUnit}
						</span>
					</div>
					{/* sku tracking qunatity */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='trackingQuantity'
							data-mandatory={mandatoryFields.trackingQuantity}
						>
							SKU Tracking Quantity
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText={fields.trackingQuantityUnit}
							name='trackingQuantity'
							id='trackingQuantity'
							value={fields.trackingQuantity}
							handleInput={handleInput}
							dataType=''
							dataId=''
							dataAttrs={{
								required: mandatoryFields.trackingQuantity,
							}}
						/>
						<span className='form-error'>
							{errors.trackingQuantity}
						</span>

						<label
							htmlFor='threshold'
							data-mandatory={mandatoryFields.threshold}
							className={`${styles.label} form-label`}
						>
							Threshold Limit
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText={fields.trackingQuantityUnit}
							name='threshold'
							id='threshold'
							value={fields.threshold}
							handleInput={handleInput}
							dataType=''
							dataId=''
							dataAttrs={{
								required: mandatoryFields.threshold,
							}}
						/>
						<span className='form-error'>{errors.threshold}</span>
					</div>
				</EnableDisable>
			</div>
		</div>
	);
};

TrackingParameters.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

export default TrackingParameters;
