import { endpoints } from '@/Constants';
import { authHeader, handleResponse } from '@/Helpers';

const gstConfigServices = {
	createGst,
	getGst,
};
// eslint-disable-next-line no-unused-vars
function createGst(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints?.GST_CREATE, requestOptions).then(handleResponse);
}

function getGst(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints?.GST_GETDETAILS, requestOptions).then(handleResponse);
}

export default gstConfigServices;
