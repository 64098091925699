/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import { AddOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { brandActions, formActions, modalActions } from '../../../../Actions';
import {
	// generateCheckedProps,
	generateImageUploadButton,
	generateRequiredProps,
	inputHelper,
} from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import { Tag } from '../../../Tags';
import { VariantCheckboxes } from '../../Inputs/VariantCheckboxes';
import styles from './GeneralDetails.module.scss';
import { ProductListModal } from '../../../Modals';

const generateDefaultOption = (type, brand, category) => {
	switch (type) {
		case 'brand':
			if (!brand || brand.length === 0) {
				return (
					<option value='' selected disabled hidden>
						Select a Brand
					</option>
				);
			}
			break;
		case 'category':
			if (brand) {
				if (!category || category.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a Category
						</option>
					);
				}
			} else {
				return (
					<option value={null} selected disabled hidden>
						Select a Brand First
					</option>
				);
			}
			break;
		default:
			break;
	}
	return <></>;
};

const GeneralDetails = (props) => {
	const {
		isEdit,
		addProducts,
		setProductIs,
		handleIsProductDelete,
		isDisabled,
		fields,
		errors,
		handleInput,
		handleDelete,
		mandatoryFields,
		products,
		brands,
		fetchBrands,
		fetchProducts,
		toggleImageCrop,
		handleImage,
	} = props;

	const [showImageCropModal, setShowImageCropModal] = useState(false);

	const handleImageInput = (fieldName, blob) => {
		setShowImageCropModal(false);
		handleImage(fieldName, blob);
	};

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(false, {
			name,
			file,
		});
		setShowImageCropModal(true);
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	useEffect(() => {
		fetchBrands({
			id: '',
			allData: true,
		});
		if (products.length === 0) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	const toggleProductsModal = () => {
		const itemTypes = ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'];
		props.toggleProductsModal(true, {
			itemTypes,
			handleConfirm: addProducts,
			brand: fields.brand,
		});
	};
	const toggleProductsIsModal = () => {
		const itemTypes = ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'];
		props.toggleProductsModal(true, {
			itemTypes,
			singleType: true,
			handleConfirm: setProductIs,
			brand: fields.brand,
		});
	};

	// eslint-disable-next-line no-unused-vars
	// function handleImageSelect(e) {
	// setFile(URL.createObjectURL(e.target.files[0]));
	// }
	return (
		<>
			<ProductListModal
				selectedProdList={fields.products}
				type={fields.type}
			/>
			<fieldset
				className={`${styles.root} form-fieldset`}
				disabled={isDisabled}
			>
				{/* defect name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='defectName'
						data-mandatory={mandatoryFields.name}
					>
						Label
					</label>
					<input
						className='form-value'
						type='text'
						id='defectName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				{/* label image */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='imageLabel'
						data-mandatory={mandatoryFields.name}
					>
						Label Image
					</label>
					<div className={styles.choose_img_wrapper}>
						{generateImageUploadButton({
							isDisabled,
							label: (
								<label
									className={styles.img_tag}
									htmlFor='displayPic'
								>
									Choose Image
								</label>
							),
						})}
						<input
							type='file'
							id='displayPic'
							accept='image/png, image/jpg, image/jpeg, image/webp'
							name='image'
							onChange={handleImageSelect}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
						{fields?.image && (
							<div>
								<img src={fields?.image} alt='Feature' />
							</div>
						)}
						<ImageCropModal
							localShow={showImageCropModal}
							handleConfirm={handleImageInput}
							handleClose={() => {
								setShowImageCropModal(false);
							}}
						/>
					</div>
				</div>
				{/* defect type */}
				<div className='form-field row'>
					<label className='form-label' data-mandatory>
						Feature Type
					</label>
					<div
						className='form-value'
						// style={{
						// gap: '1rem',
						// }}
					>
						<VariantCheckboxes
							isDisabled={isDisabled}
							items={[
								{
									name: 'Identity',
									isApplied: fields.type.IDENTITY,
								},
								{
									name: 'Component',
									isApplied: fields.type.COMPONENT,
								},
								{
									name: 'Quality',
									isApplied: fields.type.QUALITY,
								},
							]}
							fieldName='type'
							handleInput={handleInput}
						/>
					</div>
				</div>
				{/* brand name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='brand'
						data-mandatory={mandatoryFields.brand}
					>
						Brand
					</label>
					<select
						className='form-value'
						id='brand'
						name='brand'
						onChange={handleInput}
						value={fields.brand}
						disabled={isEdit || fields?.products?.length > 0}
						{...generateRequiredProps(mandatoryFields.brand)}
					>
						{generateDefaultOption(
							'brand',
							fields.brand,
							fields.category
						)}
						{Object.keys(brands).map((brandId) => {
							const brand = brands[brandId];
							return (
								<option value={brandId}>{brand.name}</option>
							);
						})}
					</select>
					<span className='form-error'>{errors.brand}</span>
				</div>
				{/* products */}
				<div className='form-field row'>
					<div className='form-label'>Applicable on products</div>
					{fields.brand != null ? (
						<div className={`${styles.tags}`}>
							{fields.products.map((item) => {
								const product = products.find((productObj) => {
									return productObj.id === item.productId;
								});
								return (
									<Tag
										// className={`${styles.tag}`}
										key={product?.id}
										handleDelete={handleDelete}
										text={product?.productName}
										isDisabled={isDisabled}
										dataAttrs={{
											'data-id': product?.id,
											'data-name': 'product',
										}}
									/>
								);
							})}
							{fields.products.length === 0 &&
								fields.type.QUALITY && (
									<span>Applicable on all products</span>
								)}
							{!isDisabled && (
								<Button
									variant='outlined'
									startIcon={<AddOutlined fontSize='1rem' />}
									onClick={toggleProductsModal}
								>
									Product
								</Button>
							)}
						</div>
					) : (
						<span>Select a brand first</span>
					)}
				</div>
				{fields.type.IDENTITY && (
					<div className='form-field row'>
						<div className='form-label'>Product is</div>
						{fields.brand != null ? (
							<div className={`${styles.tags}`}>
								{fields?.productId ? (
									<Tag
										// className={`${styles.tag}`}
										key={fields?.productId}
										handleDelete={(e) => {
											handleIsProductDelete(e);
											toggleProductsIsModal();
										}}
										text={
											products.find((productObj) => {
												return (
													productObj.id ===
													fields?.productId
												);
											})?.productName
										}
										isDisabled={isDisabled}
										dataAttrs={{
											'data-id': fields?.productId,
											'data-name': 'product',
										}}
									/>
								) : (
									!isDisabled && (
										<Button
											variant='outlined'
											startIcon={
												<AddOutlined fontSize='1rem' />
											}
											onClick={toggleProductsIsModal}
										>
											Select a Product
										</Button>
									)
								)}
							</div>
						) : (
							<span>Select a brand first</span>
						)}
					</div>
				)}
				{(fields.type.IDENTITY || fields.type.COMPONENT) && (
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='defectName'
							data-mandatory={mandatoryFields?.skuMultiplyFactor}
						>
							Sku Multiplication Factor
						</label>
						<input
							className='form-value'
							type='number'
							id='skuMultiplyFactor'
							name='skuMultiplyFactor'
							onInput={handleInput}
							defaultValue={1}
							min={1}
							value={fields?.skuMultiplyFactor}
							{...generateRequiredProps(
								mandatoryFields?.skuMultiplyFactor
							)}
						/>
						<span className='form-error'>
							{errors?.skuMultiplyFactor}
						</span>
					</div>
				)}
			</fieldset>
		</>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	fields: {},
	errors: {},
	handleInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	const products = state.form.ALL_PRODUCTS.data.LIST;
	return {
		brands,
		products,
	};
};
const mapDispatchToProps = {
	fetchBrands: brandActions.fetchBrands,
	fetchProducts: formActions.gateway,
	toggleProductsModal: modalActions.toggleProducts,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
