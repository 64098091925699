import { endpoints } from '../../Constants';
import { authHeader, handleResponse } from '../../Helpers';

const creditsServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'GET_BALANCE':
			return fetchBalance({
				req,
			});
		case 'GET_TRANSACTION_HISTORY':
			return fetchTransactionHistory({
				req,
			});
		case 'ADD_CREDITS':
			return addCredits({
				req,
			});
		case 'WITHDRAW_CREDITS':
			return withdrawCredits({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function fetchBalance({ req = {} }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.body,
		}),
	};
	return fetch(endpoints.FETCH_BALANCE, requestOptions).then(handleResponse);
}

function fetchTransactionHistory({ req = {} }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.FETCH_TRANSACTION_HISTORY, requestOptions).then(
		handleResponse
	);
}

function addCredits({ req = {} }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.ADD_CREDITS, requestOptions).then(handleResponse);
}

function withdrawCredits({ req = {} }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.WITHDRAW_CREDITS, requestOptions).then(
		handleResponse
	);
}

export default creditsServices;
