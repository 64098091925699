/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { classes } from '../../../Helpers';
import DownloadButton from '../../../Pages/Dashboard/DownloadButton/DownloadButton';
import Heading from '../../../Pages/Dashboard/Heading/Heading';
import styles from './LineChart.module.scss';
import { CHART_COLORS } from '../../../Styles/colors';

Chart.register(annotationPlugin);
Chart.defaults.font.size = 11;

function LineChart(props) {
	const { data = {}, className } = props;
	// rangeMin,rangeMax,label,color
	const {
		rangeMin = 0,
		rangeMax = 0,
		rangeColor = '#00000022',
		rangeLabel = '',
	} = data;
	const xRangeMin = -1;
	// let xRangeMax = 0;
	const chartData = useMemo(() => {
		const barColors = [
			{
				backgroundColor: 'RGBA(255, 183, 3, 0.25)',
				borderColor: 'RGBA(255, 183, 3, 1)',
			},
		];
		// xRangeMin = 0;
		// xRangeMax = data?.labels?.length ?? 0;
		return {
			labels: data?.labels ?? [],
			datasets: (data?.datasets ?? [])?.map((dataset, idx) => {
				return {
					label: dataset?.label?.slice(0, data?.limit ?? undefined),
					data: dataset?.data?.slice(0, data?.limit ?? undefined),
					...barColors[idx],
					fill: true,
					// tension: 0.3,
					pointBorderColor: 'RGBA(255, 183, 3, 1)',
					pointBackgroundColor: '#ffffff',
					pointBorderWidth: 3,
					pointStyle: 'circle',
					pointRadius: 5,
				};
			}),
		};
	}, [data]);

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		// aspectRatio: 1,
		plugins: {
			annotation: {
				annotations: {
					box1: {
						display: rangeMin !== null,
						type: 'box',
						xMin: xRangeMin,
						xMax: data?.labels?.length,
						yMin: rangeMin,
						yMax: rangeMax,
						backgroundColor: CHART_COLORS.primary,
						label: {
							content: rangeLabel,
							display: true,
						},
					},
				},
			},
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: true,
				},
			},
			y: {
				grid: {
					display: true,
				},
				ticks: {},
				// beginAtZero: true,
			},
		},
		elements: {
			point: {
				radius: 5, // Increase the radius to make the dots bigger
				pointStyle: 'rectRot', // Use a square point style (rectangle with a cutout)
			},
		},
	};
	return (
		<div
			className={classes(
				styles['chart-container'],
				styles['popular-days'],
				className
			)}
		>
			{/* <p className={styles.unit}>in Kgs</p> */}
			{/* <span className={styles.unitx}>{data.xlabel}</span>
			<span className={styles.unity}>{data.ylabel}</span> */}
			<p className={styles.title}>
				<Heading heading={data.heading} tooltip={data.tooltip} />
				<DownloadButton data={data} />
			</p>
			<Line
				className={classes(styles['popular-days-chart'], styles.chart)}
				options={options}
				data={chartData}
			/>
		</div>
	);
}

export default LineChart;
