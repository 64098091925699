const labelComponents = [];
// const labelComponents=[
//     {
//         id:1,
//         type:"staticText",
//         position: {x: 0, y: 0},
//         staticText:{
//             font:"Font A",
//             fontSize:30,
//             text:"Bakingo",
//             height:"3rem",
//             width:"8rem",
//         },
//     },
//     // // {
//     // //     id:2,
//     // //     type:"staticText",
//     // //     staticText:{
//     // //         font:"Font A",
//     // //         fontSize:30,
//     // //         text:"Bakingo",
//     // //         height:10,
//     // //         width:1,
//     // //     },
//     // // },
//     // // {
//     // //     id:3,
//     // //     type:"staticText",
//     // //     staticText:{
//     // //         font:"Font A",
//     // //         fontSize:30,
//     // //         text:"Bakingo",
//     // //         height:10,
//     // //         width:10,
//     // //     },
//     // // },
//     // // {
//         // //     id:4,
//     // //     type:"staticText",
//     // //     staticText:{
//     // //         font:"Font A",
//     // //         fontSize:30,
//     // //         text:"Bakingo",
//     // //         height:10,
//     // //         width:10,
//     // //     },
//     // // },
//     {
//         id:6,
//         type:"text",
//         position: {x: 0, y: 0},
//         text:{
//             placeHolderName:"Product Name",
//             placeholderText:"",
//             font:"Font A",
//             height:"2rem",
//             width:"8rem",
//             fontSize:16,
//             inputLines:1,
//         },
//     },
//     {
//         id:7,
//         type:"qrCode",
//         position: {x: 0, y: 0},
//         qrCode:{
//             height:"7.333333333333333rem",
//             width:"7.333333333333333rem",
//         }
//     }
// ];
export default labelComponents;
