import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import VariantsCreatePage from '../../../Pages/Food Products/Variants/VariantsCreatePage';
import VariantsListPage from '../../../Pages/Food Products/Variants/VariantsListPage';
import VariantsViewPage from '../../../Pages/Food Products/Variants/VariantsViewPage';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const VariantsRoutes = (props) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.VARIANTS}`,
			name: 'Variants',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='VARIANTS'
			path={path}
			searchOnClick
			editPage={<VariantsCreatePage isEdit breadcrumbs={breadcrumbs} />}
			viewPage={<VariantsViewPage isDisabled breadcrumbs={breadcrumbs} />}
			listPage={VariantsListPage}
		/>
	);
};

export default VariantsRoutes;
