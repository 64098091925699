import { useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../Actions';
import { modalTypes } from '../../Constants';
import { usePagination } from '../../Helpers';
import { ImageThumbnail } from '../Image';
import styles from './ImagesResult.module.scss';
import loadMoreLoader from '../../Images/loadmoreLoader.svg';

const ImagesResult = (props) => {
	const { page, limit, nextPage } = usePagination({
		customLimit: 6,
	});

	const [disabled, setDisabled] = useState(false);
	const [loading, setLoading] = useState(false);

	const {
		isInternal,
		currentItem,
		toggleModal,
		fetchMoreImages,
		type,
		identification,
		onItemClick,
	} = props;

	const loadMoreImages = async () => {
		try {
			setDisabled(true);
			setLoading(true);
			await fetchMoreImages({
				id: currentItem.id,
				page: page + 1,
				identification,
				limit,
			});
			nextPage();
		} catch (err) {
			//
		} finally {
			setDisabled(false);
			setLoading(false);
		}
	};

	// eslint-disable-next-line no-unused-vars
	const openModal = (item) => {
		toggleModal(true, {
			item,
		});
	};

	return (
		<div className={styles.result}>
			<p className={styles['result-header']}>
				{currentItem?.label}
				<span className={styles.count}>
					{` (Showing ${currentItem?.Annotation?.length} of ${
						currentItem?.count ?? 0
					} image(s))`}
				</span>
			</p>
			<div className={styles['image-grid']}>
				{currentItem?.Annotation.map((annotation) => {
					return (
						<ImageThumbnail
							key={annotation.id}
							source={annotation.image}
							type={type}
							identificationType={annotation.ai}
							annotation={annotation}
							machineDetection={
								isInternal && annotation?.machineDetection
							}
							featureCount={annotation.featureCount}
							onClick={() => {
								onItemClick({
									// source: annotation.image,
									annotation,
									item: currentItem.label,
									// name: qcData?.ProductName,
									// date: qcData?.QCTime,
									// link:
									// qcData != null
									// ? `${urls.EXTENSION}${urls.PAST_QC}/${qcData?.itemId}?id=${qcData?.id}`
									// : null,
								});
							}}
						/>
					);
				})}
				{!!currentItem.count &&
					currentItem.Annotation.length !== currentItem.count && (
					<button
						// disabled={disabled}
						disabled={disabled}
						type='button'
						className={styles['load-more']}
						onClick={loadMoreImages}
					>
						{loading ? (
							<div>
								<img src={loadMoreLoader} alt='loader' />
								<p>Loading</p>
							</div>
						) : (
							'Load More'
						)}
					</button>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ImagesResult);
