/* eslint-disable object-curly-newline */
import { Add, ArrowCircleRight } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
// eslint-disable-next-line object-curly-newline
import {
	Autocomplete,
	Button,
	IconButton,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useReducer, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import {
	modalActions,
	formActions,
	analyticsAction,
	brandActions,
} from '../../../../Actions';
import { classes, inputHelper } from '../../../../Helpers';
import { UYDateRange } from '../../../Forms/Payment Settlements/Calender/UYDateRange';
import { ListModal } from '../../../Modals';
import { Tag } from '../../../Tags';
import styles from './OutwardingImagesFinder.module.scss';

const identificationTypes = [
	{
		id: 1,
		type: 'AI',
	},
	{
		id: 2,
		type: 'Manual',
	},
	{
		id: 3,
		type: 'Both',
	},
];

const EMPTYVALUE = -999;

const initialState = {
	store: null,
	products: [],
	page: 6,
	dateRange: {
		active: true,
		from: new Date(),
		to: new Date(),
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_STORE':
			return {
				...state,
				store: action.payload,
			};
		case 'SET_PAGE':
			return {
				...state,
				page: action.payload,
			};
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.payload,
			};
		case 'SET_BRAND':
			return {
				...state,
				brand: action.payload,
			};
		case 'SET_IDENTIFICATION_TYPE':
			return {
				...state,
				identificationType: action.payload,
			};
		case 'SET_DATE_RANGE':
			return {
				...state,
				dateRange: action.payload,
			};
		default:
			return state;
	}
};

const OutwardingImagesFinder = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		store,
		brand,
		products,
		dateRange,
		page: pageLimit,
		identificationType,
	} = state;
	const {
		storeLabel,
		finderFilter,
		showPageLimit,
		extraStyles,
		hideIdentification,
		hideBrandSelection,
		hideProductSelection,
		hideTimeSelection,
		hideTimeText,
		// storeLabel = 'WASTAGE(S) Of',
		toggleModal,
		productsList,
		fetchProducts,
		stores,
		onSubmit,
		fetchStoresList,
		brands,
		fetchBrands,
		// setDateRangeFilter,
		// dateRangeFilter,
	} = props;

	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		onSubmit({
			store: store?.id === EMPTYVALUE ? null : store?.id,
			...(!hideProductSelection && { products }),
			...(!hideIdentification && { identification: identificationType }),
			...(!hideBrandSelection && { brand }),
			...(!hideTimeSelection && { dateRange }),
			...(showPageLimit && { page: Number(pageLimit) }),
		});
	};

	const addFilter = (filters, context) => {
		if (context.entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: [...products, ...filters],
			});
		}
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: products.filter((product) => {
					return product !== parseInt(id, 10);
				}),
			});
		}
	};

	useEffect(() => {
		fetchBrands({
			id: '',
			allData: true,
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const selectDefectType = (e) => {
		const { fieldValue } = inputHelper(e);

		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: fieldValue,
		});
	};

	// eslint-disable-next-line no-unused-vars
	const selectStore = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_STORE',
			payload: fieldValue,
		});
	};
	const selectStoreAutoComplete = (selectedStore) => {
		dispatch({
			type: 'SET_STORE',
			payload: selectedStore,
		});
	};
	const setPage = (page) => {
		dispatch({
			type: 'SET_PAGE',
			payload: page,
		});
	};
	const selectBrand = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_BRAND',
			payload: fieldValue,
		});
	};

	const brandsList = useMemo(() => {
		return Object.keys(brands).map((id) => {
			const currBrand = brands[id];
			return currBrand;
		});
	}, [brands]);

	useEffect(() => {
		dispatch({
			type: 'SET_BRAND',
			payload: brandsList[0]?.id,
		});
	}, [brandsList]);

	useEffect(() => {
		if (brand != null) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
						brandId: brand,
					},
				},
				method: 'LIST',
			});
		}
	}, [brand]);

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
			};
		});
		tempStore?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return tempStore;
	}, [stores]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const PRODUCTS = useMemo(() => {
		return productsList.map((product) => {
			return {
				id: product.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList]);

	useEffect(() => {
		dispatch({
			type: 'SET_STORE',
			payload: STORES?.[0],
		});
	}, [stores, STORES]);
	useEffect(() => {
		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: 'Both',
		});
	}, []);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDateRange = ({ active, from, to }) => {
		dispatch({
			type: 'SET_DATE_RANGE',
			payload: {
				active,
				from,
				to,
			},
		});
	};

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
	};

	return (
		<form
			className={styles.root}
			style={extraStyles ?? {}}
			onSubmit={handleSubmit}
		>
			{!hideProductSelection && (
				<ListModal handleConfirm={addFilter} list={PRODUCTS} />
			)}
			<div className={styles.form}>
				<div className={styles.filters}>
					{finderFilter?.includes('store') && (
						<>
							<div
								className={classes(
									styles.filter,
									styles.product
								)}
							>
								<p className={styles['filter-heading']}>
									{storeLabel ?? 'Select Store'}
								</p>
								<div className={styles['filter-body']}>
									<Autocomplete
										disablePortal
										id='combo-box-demo'
										popupIcon={<SearchIcon />}
										getOptionLabel={(option) => {
											return option?.title;
										}}
										onChange={(event, selectedOption) => {
											selectStoreAutoComplete(
												selectedOption
											);
										}}
										value={store}
										options={STORES}
										sx={{
											width: 300,
											background: 'rgba(255,255,255,1)',
											'& .MuiAutocomplete-popupIndicator':
												{
													transform: 'none',
												},
										}}
										renderInput={(params) => {
											return (
												<TextField
													{...params}
													label='Select Store'
												/>
											);
										}}
									/>
								</div>
							</div>
						</>
					)}
					{showPageLimit && (
						<div className={classes(styles.filter, styles.product)}>
							<p>Page Limit:</p>
							<Select
								value={pageLimit ?? ''}
								className={styles['defect-select']}
								onChange={(e) => {
									setPage(e.target.value);
								}}
							>
								{[6, 25, 50, 100].map((page) => {
									return (
										<MenuItem key={page} value={page}>
											{page}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					)}
					{!hideIdentification && (
						<div className={classes(styles.filter, styles.product)}>
							<p>Identification Type:</p>
							<Select
								value={identificationType ?? ''}
								className={styles['defect-select']}
								onChange={selectDefectType}
								disabled={identificationTypes.length === 0}
							>
								{identificationTypes.map((type) => {
									return (
										<MenuItem
											key={type.id}
											value={type.type}
										>
											{type.type}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					)}
					{!hideBrandSelection && (
						<div className={classes(styles.filter, styles.product)}>
							<p className={styles['filter-heading']}>
								Select a Brand
							</p>
							<div className={styles['filter-body']}>
								<Select
									className={styles['brand-select']}
									value={brand ?? ''}
									onChange={selectBrand}
									disabled={products.length > 0}
								>
									{brandsList?.map((brandObj) => {
										return (
											<MenuItem
												key={brandObj.id}
												value={brandObj.id}
											>
												{brandObj.name}
											</MenuItem>
										);
									})}
								</Select>
							</div>
						</div>
					)}
					{!hideProductSelection && (
						<div className={classes(styles.filter, styles.product)}>
							<p className={styles['filter-heading']}>
								Select Product(s)
							</p>
							<div className={styles['filter-body']}>
								<div className={styles.tags}>
									{products.map((id) => {
										const product = PRODUCTS.find((d) => {
											return id === d.id;
										});
										return (
											<Tag
												key={id}
												text={product?.title}
												handleDelete={deleteFilter}
												dataAttrs={{
													'data-id': id,
													'data-entity': 'PRODUCT',
												}}
											/>
										);
									})}
									{products?.length === 0 && (
										<span>All products selected</span>
									)}
									<Button
										variant='outlined'
										startIcon={<Add />}
										className={styles['add-button']}
										// disabled={brand == null}
										onClick={() => {
											toggleModal(true, {
												entity: 'PRODUCT',
												heading: 'Select Product(s)',
												list: PRODUCTS,
												selected: products,
											});
										}}
									>
										Product
									</Button>
								</div>
							</div>
						</div>
					)}
					{!hideTimeSelection && (
						<div className={styles.filter}>
							{!hideTimeText && 'QC Time'}
							<UYDateRange
								dateRanges={dateRangeFilterArray}
								onDateRangeChange={setDate}
							/>
						</div>
					)}
				</div>
				<div className={styles.submit}>
					<span>Find</span>
					<IconButton type='submit' className={styles.btn}>
						<ArrowCircleRight />
					</IconButton>
				</div>
			</div>
		</form>
	);
};

OutwardingImagesFinder.defaultProps = {
	brands: [],
	products: [
		{
			label: 'Product D',
		},
	],
	onSubmit: () => {},
};

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { brands } = state.brand;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;

	return {
		stores,
		brands,
		productsList,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchBrands: brandActions.fetchBrands,
	fetchProducts: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OutwardingImagesFinder);
