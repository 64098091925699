import { useRouteMatch } from 'react-router';
import { useState } from 'react';
import { ProductListFilters } from '../../../Components/Forms/Products';
import { urls } from '../../../Constants';
import AllProductListPage from '../../../Pages/Food Products/All Products/AllProductListPage';
import ProductCreatePage from '../../../Pages/Food Products/All Products/ProductCreatePage';
import ViewProductPage from '../../../Pages/Food Products/All Products/ProductViewPage';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const AllProductRoutes = (props) => {
	const { path } = useRouteMatch();
	const [filter, setFilter] = useState(null);
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.ALL_PRODUCTS}`,
			name: 'All Products',
		},
	];

	return (
		<CRUDRoutes
			{...props}
			listPageProps={{
				filter,
			}}
			entity='PRODUCTS'
			path={path}
			listPage={AllProductListPage}
			listFilter={<ProductListFilters setFilter={setFilter} />}
			viewPage={<ViewProductPage breadcrumbs={breadcrumbs} />}
			editPage={<ProductCreatePage isEdit breadcrumbs={breadcrumbs} />}
			createPage={<ProductCreatePage breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default AllProductRoutes;
