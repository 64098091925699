import { Button } from '@mui/material';
import { PageNavigation } from '../../Headers/PageNavigation';
import styles from './Header.module.css';

function Header(props) {
	// eslint-disable-next-line object-curly-newline
	const { listData, listIndex, handleBack, navigate, fields } =
		props;

	return (
		<div className={styles.root}>
			<Button onClick={handleBack} variant='contained' color='primary'>
				Back
			</Button>
			<PageNavigation
				navigate={navigate}
				prev={{
					tooltip: 'Prev Order',
					show: listIndex > 0,
					disabled: listIndex === 0,
				}}
				current={{
					label: fields?.center?.label,
					value: fields?.center?.value,
				}}
				next={{
					tooltip: 'Next Order',
					show:
						listIndex >= 0 &&
						listIndex < listData.length - 1,
					disabled:
						listIndex < 0 && listIndex === listData.length - 1,
				}}
			/>
			<div className={styles.box}>
				<p>
					<strong>{fields?.right?.label}</strong>
				</p>
				<p>{fields?.right?.value}</p>
			</div>
		</div>
	);
}

export default Header;
