// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import CookingProductConfigRoutes from './Cooking Product Config Routes/cookingproductconfig.routes';
import CookingRecipeRoutes from './Cooking Recipe Routes/cookingRecipe.routes';
import CookingSettingRoutes from './Cooking Setting Routes/cookingsetting.routes';
import CookingStationsRoutes from './Cooking Station Routes/cookingStation.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.COOKING;
	return entity.slice(baseEntity.length);
};

const CookingRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.COOKING_SETTING)}`}>
				<CookingSettingRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.COOKING_PRODUCT_CONFIG)}`}>
				<CookingProductConfigRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.COOKING_STATIONS)}`}>
				<CookingStationsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.COOKING_RECIPE)}`}>
				<CookingRecipeRoutes />
			</Route>
		</Switch>
	);
};

export default CookingRoutes;
