import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch } from '@mui/material';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper } from '../../../Helpers';
import { BackdropLoader } from '../../../Components/Loaders';

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Start Time',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'End Time',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [
	{
		title: 'Enabled',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	'Product Name': 'productName',
	Name: 'productName',
	Brand: 'brandName',
	'Start Time': 'subscriptionFrom',
	'End Time': 'subscriptionTo',
	Enabled: 'subscriptionEnabled',
};

const SubscriptionListPage = (props) => {
	const {
		fetchSubscription,
		toggleDisableSubscription,
		data,
		query,
		searchBy,
	} = props;

	const [isLoading, setIsLoading] = useState(false);

	const handleEnable = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		toggleDisableSubscription({
			entity: 'SUBSCRIPTION',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					subscriptionEnabled: fieldValue,
				},
			},
			method: 'ENABLE',
		});
	};

	const SPECIAL_ITEMS = {
		Enabled: (id, isEnabled) => {
			return (
				<Switch
					name='isEnabled'
					value='isEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'SUBSCRIPTION',
					}}
				/>
			);
		},
	};

	const fetchSubscriptionFunc = async () => {
		setIsLoading(true);
		await fetchSubscription({
			entity: 'SUBSCRIPTION',
			req: {
				params: {
					id: '',
					variants: true,
					skuType: 'PRODUCT',
				},
			},
			method: 'LIST',
		});
		setIsLoading(false);
	};

	useEffect(() => {
		fetchSubscriptionFunc();
	}, []);

	return (
		<div className='list-page-root'>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ListTable
				colMap={COLMAP}
				fixedHeaderTop={0}
				columns={[...TABLE_COLUMNS]}
				data={data.filter((subscription) => {
					return (
						get(subscription, COLMAP?.[searchBy?.title])
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.SUBSCRIPTION.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchSubscription: formActions.gateway,
	toggleDisableSubscription: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubscriptionListPage);
