import { useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { modalTypes, urls } from '../../../../Constants';
import { usePagination } from '../../../../Helpers';
import { ImageThumbnail } from '../../../Image';
import styles from './DefectImageResult.module.scss';
import loadMoreLoader from '../../../../Images/loadmoreLoader.svg';

const DefectImageResult = (props) => {
	const { page, nextPage } = usePagination({
		customLimit: 6,
	});

	const [disabled, setDisabled] = useState(false);
	const [loading, setLoading] = useState(false);

	const { defect, fetchQCData, toggleModal, fetchDefectImagesById, identification } = props;

	const loadMoreImages = async () => {
		try {
			setDisabled(true);
			setLoading(true);
			await fetchDefectImagesById({
				defectId: defect.id,
				page: page + 1,
				identification,
			});
			nextPage();
		} catch (err) {
			//
		} finally {
			setDisabled(false);
			setLoading(false);
		}
	};

	const openModal = (item) => {
		toggleModal(true, {
			item,
		});
	};

	return (
		<div className={styles.result}>
			<div className={styles['result-header']}>
				{defect.image && (
					<img src={defect.image} alt='Feature' className={styles.bar_image} />
				)}
				<p>
					{defect.label}
					<span className={styles.count}>
						{` (Showing ${defect.Annotation.length} of ${
							defect?.count ?? 0
						} image(s))`}
					</span>
				</p>
			</div>
			<div className={styles['image-grid']}>
				{defect.Annotation.map((annotation) => {
					return (
						<ImageThumbnail
							key={annotation.id}
							source={annotation.image}
							identificationType={annotation.ai}
							annotation={annotation}
							onClick={async () => {
								const qcData = await fetchQCData(
									annotation.qcId
								);
								openModal({
									source: annotation.image,
									name: qcData?.ProductName,
									defect: defect.label,
									date: qcData?.QCTime,
									link:
										qcData != null
											? `${urls.EXTENSION}${urls.PAST_QC}/${qcData?.itemId}?id=${qcData?.id}`
											: null,
								});
							}}
						/>
					);
				})}
				{defect.Annotation.length !== defect.count && (
					<button
						// disabled={disabled}
						disabled={disabled}
						type='button'
						className={styles['load-more']}
						onClick={loadMoreImages}
					>
						{loading ? (
							<div>
								<img src={loadMoreLoader} alt='loader' />
								<p>Loading</p>
							</div>
						) : (
							'Load More'
						)}
					</button>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_DEFECT_IMAGES: allDefectResults } =
		state.analytics.DEFECT_ANALYSIS;
	return {
		allDefectResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DefectImageResult);
