/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
import {
	CheckCircle,
	ModeEdit,
	HighlightOff,
	Check,
	AddCircle,
} from '@mui/icons-material';
import {
	IconButton,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import moment from 'moment';
import {
 useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { modalActions } from '../../../Actions';
import { classes, handleExcelDownload } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
// eslint-disable-next-line import/no-cycle
import { ListTable } from '../../ListTable';
import { BaseModal } from '../BaseModal';
import styles from './ProductionPlanModal.module.scss';
import { Button } from '@/Components/ui/button';
import { Input } from '@/Components/ui/input';

const skuTypes = [
	{
		value: 'PRODUCT',
		color: '#FFD1DA',
	},
	{
		value: 'INTERMEDIATE',
		color: '#FBF1D3',
	},
	{
		value: 'INGREDIENT',
		color: '#D3FAC7',
	},
];

const ProductionPlanModal = (props) => {
	const history = useHistory();
	const { show, toggleProductionPlanModal, data } = props;
	const [planDescription, setPlanDescription] = useState('');
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [initialQty, setInitialQty] = useState({});
	const [isEdit, setIsEdit] = useState({});
	const [listData, setListData] = useState({
		INGREDIENT: [],
		INTERMEDIATE: [],
		PRODUCT: [],
		leftOverCheckPlannedAhead: false,
		stockCheckedAt: new Date(),
		storeInfo: {},
	});
	const isLeftOverCheck = !!listData?.leftOverCheckPlannedAhead;
	const [prevListData, setPrevListData] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [selectedType, setSelectedType] = useState({
		value: 'PRODUCT',
		color: '#FFD1DA',
	});

	const stockCheckedAt = moment(data?.listData?.stockCheckedAt)?.format(
		'DD-MMM HH:mm'
	);
	const stockCheckAtDate = moment(data?.listData?.stockCheckedAt)?.format(
		'DD-MMM'
	);
	const checkedAtDate = new Date(data?.listData?.stockCheckedAt);
	checkedAtDate.setDate(checkedAtDate.getDate() + 1);
	const nextStockCheckAtDate = moment(checkedAtDate)?.format('DD-MMM');
	const scheduledFor = moment(data?.date)?.format('DD-MMM');

	const COLMAP = {
		'Product Name': 'id',
		Unit: 'unit',
		Category: 'category',
		'From Process': 'From Process',
		'To Process': 'To Process',
		[`Stock At ${stockCheckedAt}`]: 'stock',
		[`Plan ${stockCheckAtDate} Produced till ${stockCheckedAt}`]:
			'currentDayStockIn',
		[`Plan ${stockCheckAtDate} Planned Production`]: 'currentDayCount',
		[`Projected Stock at ${nextStockCheckAtDate} 7:59 AM`]: 'prePlanStock',
		[`Minimum Stock at ${nextStockCheckAtDate} 7:59 AM`]: 'threshold',
		[`Order Quantity PO Delivery Date ${scheduledFor}`]: 'orderedQty',
		'Forecast for Next 7 Days': 'forecasted',
		Scheduled: 'scheduledAtDelta',
		'Production Factor (+/- Manual Calibration)': 'updatedQty',
		'Pre Batch Production Quantity': 'calculatedQty',
		'Batch Size': 'batchSize',
		'Final Production': 'finalProduction',
		'Final Indent': 'finalProduction',
		'Recipe Exists': 'skipped',
	};

	const SPECIAL_COLUMNS = [
		{
			title: 'Product Name',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Unit',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Category',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'From Process',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'To Process',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Scheduled',
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Stock At ${stockCheckedAt}`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Left Over (Input)',
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Plan ${stockCheckAtDate} Planned Extra Production`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Plan ${stockCheckAtDate} Produced till ${stockCheckedAt}`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Plan ${stockCheckAtDate} Planned Production`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Projected Stock at ${nextStockCheckAtDate} 7:59 AM`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Minimum Stock at ${nextStockCheckAtDate} 7:59 AM`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: `Order Quantity PO Delivery Date ${scheduledFor}`,
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Forecast for Next 7 Days',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Production Factor (+/- Manual Calibration)',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Expected Left Over',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Pre Batch Without Left Over',
			config: {
				type: 'DATA',
			},
		},

		{
			title: 'Left Over (Used/Input)',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Left Over (Already Planned)',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Left Over (From Executed plans)',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Pre Batch Production Quantity',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Wastage %',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Batch Size',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Final Production',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Final Indent',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Recipe Exists',
			config: {
				type: 'DATA',
			},
		},
	];

	useEffect(() => {
		setListData(data?.listData);
		setInitialQty(data?.listData);
	}, [data?.listData]);

	const handleClose = () => {
		setPlanDescription('');
		setPrevListData({});
		toggleProductionPlanModal(false, {});
	};

	const getUpdatedQty = (delta, orderedQty, updatedQty) => {
		if (!delta) {
			return updatedQty;
		}
		return Number(delta) + (orderedQty ?? 0);
		// return updatedQty;
	};

	const getElementId = (ele) => {
		return ele?.variantId + (ele?.fromProcessId ?? '') + ele?.scheduledAtDelta;
	};

	const items = useMemo(() => {
		const flattenData = [
			...(listData?.PRODUCT ?? []),
			...(listData?.INTERMEDIATE ?? []),
		];
		if (show) {
			const temp = flattenData?.map((ele) => {
				const { delta, ...restEle } = ele ?? {};
				return {
					...restEle,
					updatedQty: getUpdatedQty(
						delta,
						ele?.orderedQty,
						ele?.updatedQty
					),
				};
			});
			return temp;
		}
		return [];
	}, [data?.listData, listData?.PRODUCT, listData?.INTERMEDIATE]);

	const handleConfirm = async () => {
		if (data?.idsList && data?.idsList?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No PO Selected',
			});
			return;
		}
		if (!data?.storeId) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No Store selected',
			});
			return;
		}
		if (!planDescription) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please add Plan Description',
			});
			return;
		}
		const req = {
			data: {
				poIds: data?.idsList ?? [],
				description: planDescription,
				storeId: data?.storeId,
				items,
				stockCheckedAt: data?.listData?.stockCheckedAt,
				storeInfo: data?.listData?.storeInfo,
			},
		};
		if (data?.date) {
			req.data.date = data?.date;
		}
		try {
			setLoading(true);
			const response =
				await inventoryAnalyticsServices?.generateProductionPlan(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Generated Successfully',
				});
				handleClose();
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error Occurred',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleRecalculate = async () => {
		setLoading(true);
		try {
			setPrevListData(listData);
			const req = {
				data: {
					poIds: data?.idsList ?? [],
					storeId: data?.storeId,
					date: data?.date,
					recalculate: true,
					stockCheckedAt: data?.listData?.stockCheckedAt,
					storeInfo: data?.listData?.storeInfo,
					items,
				},
			};
			const response =
				await inventoryAnalyticsServices?.fetchProductionPlan(req);
			if (response?.statusCode === 200) {
				setListData(response?.data);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Generated Successfully',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error Occurred',
			});
		} finally {
			setLoading(false);
		}
	};

	const renderBtns = () => {
		return (
			!data?.hideButtons && (
				<div className={styles.btns}>
					<Button
						className='flex-1'
						disabled={Object.keys(isEdit)?.length === 0 || loading}
						onClick={handleRecalculate}
						variant='secondary'
					>
						Re-Calculate
					</Button>
					<Button
						className='flex-1 primary-btn'
						onClick={handleConfirm}
						disabled={loading}
					>
						{loading ? 'Loading...' : 'Generate'}
					</Button>
				</div>
			)
		);
	};

	const handleChange = (e) => {
		setPlanDescription(e.target.value);
	};

	const handleEditClick = (varId) => {
		setIsEdit((prev) => {
			const temp = {
				...prev,
			};
			if (temp[varId]) {
				temp[varId] = false;
			} else {
				temp[varId] = true;
			}
			return temp;
		});
	};

	const handleQtyChange = (e, elementId) => {
		setListData((prev) => {
			const temp = {
				...prev,
			};
			temp[selectedType?.value] = temp[selectedType?.value]?.map(
				(ele) => {
					if (
						elementId ===
						getElementId(ele)
					) {
						return {
							...ele,
							delta: e?.target.value,
						};
					}
					return ele;
				}
			);
			return {
				...temp,
			};
		});
	};

	const handleAddRecipe = (id) => {
		history.push(`/console2/kitchen/cooking/recipe/view/${id}`);
	};

	const SPECIAL_ITEMS = {
		'Product Name': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return `${product?.productName}${
				product?.variantName ? ` (${product?.variantName})` : ''
			}`;
		},
		Unit: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.unit;
		},
		Category: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.categoryName ?? '-';
		},
		'From Process': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.fromProcess;
		},
		'To Process': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.toProcess;
		},
		Scheduled: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			let str = '';
			if (product?.scheduledAtDelta) {
				if (product?.scheduledAtDelta < 0) {
					str = 'T' + product?.scheduledAtDelta;
				} else if (product?.scheduledAtDelta === 0) {
					str = 'T';
				} else {
					str = 'T+' + product?.scheduledAtDelta;
				}
			}
			return str;
		},
		[`Stock At ${stockCheckedAt}`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.stock;
		},
		[`Projected Stock at ${nextStockCheckAtDate} 7:59 AM`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.prePlanStock ?? '-';
		},
		[`Minimum Stock at ${nextStockCheckAtDate} 7:59 AM`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.threshold;
		},
		[`Order Quantity PO Delivery Date ${scheduledFor}`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.orderedQty;
		},
		'Pre Batch Production Quantity': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return (
				product?.calculatedQty +
				(product?.calculatedQtyNonAdjusted < 0
					? // eslint-disable-next-line prefer-template
					  '(' + product?.calculatedQtyNonAdjusted + ')'
					: '')
			);
		},
		'Wastage %': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.wastage ?? '-';
		},
		'Batch Size': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.batchSize;
		},
		'Final Production': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.finalProduction;
		},
		'Final Indent': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.finalProduction;
		},
		[`Plan ${stockCheckAtDate} Planned Production`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.currentDayCount;
		},
		[`Plan ${stockCheckAtDate} Produced till ${stockCheckedAt}`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.currentDayStockIn;
		},
		'Forecast for Next 7 Days': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.forecasted ?? '-';
		},
		'Left Over (From Executed plans)': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.leftOver ?? '-';
		},
		'Left Over (Already Planned)': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.plannedLeftOver ?? '-';
		},
		'Pre Batch Without Left Over': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.calculatedQtyNonAdjusted ?? '-';
		},
		'Left Over (Used/Input)': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			if (!product?.leftOver || !product?.inputLeftOver) return '-';
			return product?.leftOver + '/' + product?.inputLeftOver ?? '-';
		},
		'Left Over (Input)': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.stock ?? '-';
		},
		[`Plan ${stockCheckAtDate} Planned Extra Production`]: (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.extraScheduled ?? '-';
		},
		'Expected Left Over': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return product?.expectedLeftOver ?? '-';
		},
		'Production Factor (+/- Manual Calibration)': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			const initialProductData = prevListData?.[
				selectedType?.value
			]?.find((prod) => {
				return getElementId(prod) === id;
			});
			const initialValue =
				product?.updatedQty === product?.orderedQty
					? 0
					: product?.updatedQty - product?.orderedQty;

			const hasError =
				Number(product?.delta) < -initialProductData?.calculatedQty;

			let minPossible;
			if (initialProductData && initialProductData?.delta) {
				minPossible = Number(
					product?.calculatedQty - Number(initialProductData?.delta)
				);
			} else {
				minPossible = product?.calculatedQty;
			}
			if (isEdit[getElementId(product)]) {
				return (
					<div>
						<p>Min possible = -{minPossible}</p>
						<Input
							style={{
								outline: hasError ? 'none' : '',
								border: hasError ? '2px solid red' : '',
								color: hasError ? 'red' : '',
							}}
							type='number'
							placeholder='Change Order QTY'
							value={product?.delta ?? initialValue}
							onChange={(e) => {
								handleQtyChange(e, getElementId(product));
							}}
						/>
						<IconButton
							onClick={() => {
								if (hasError) {
									notificationServices.generateNotification({
										type: 'warning',
										message: 'Please check value',
									});
									return;
								}
								handleEditClick(getElementId(product));
							}}
						>
							<CheckCircle />
						</IconButton>
					</div>
				);
			}
			if (product?.delta) {
				return (
					<span className={styles['order-qty']}>
						{/* <s className={styles.actual}>{product?.orderedQty}</s> */}
						<p>{product?.delta}</p>
						{!data?.nonEditable && (
							<IconButton
								onClick={() => {
									handleEditClick(getElementId(product));
								}}
								className={styles['edit-btn']}
							>
								<ModeEdit />
							</IconButton>
						)}
					</span>
				);
			}
			if (product?.updatedQty !== product?.orderedQty) {
				return (
					<span className={styles['order-qty']}>
						{/* <s className={styles.actual}>{product?.orderedQty}</s> */}
						<p>{product?.updatedQty - product?.orderedQty}</p>
						{!data?.nonEditable && (
							<IconButton
								onClick={() => {
									handleEditClick(getElementId(product));
								}}
								className={styles['edit-btn']}
							>
								<ModeEdit />
							</IconButton>
						)}
					</span>
				);
			}
			return (
				<span className={styles['order-qty']}>
					<p>0</p>
					{!data?.nonEditable && (
						<IconButton
							onClick={() => {
								handleEditClick(getElementId(product));
							}}
							className={styles['edit-btn']}
						>
							<ModeEdit />
						</IconButton>
					)}
				</span>
			);
			// return product?.updatedQty - (product?.orderedQty ?? 0);
		},
		'Recipe Exists': (id) => {
			const product = listData?.[selectedType?.value]?.find((prod) => {
				return getElementId(prod) === id;
			});
			return !product?.skipped ? (
				<Check />
			) : (
				<div className={styles['no-recipe']}>
					<HighlightOff />
					<IconButton
						onClick={() => {
							handleAddRecipe(product?.productId);
						}}
						className={styles['add-recipe']}
					>
						<AddCircle className={styles.icon} />
						<p>Add Recipe</p>
					</IconButton>
				</div>
			);
		},
	};

	const getRowStyles = (ele) => {
		if (ele?.scheduledAtDelta > 0) {
			return styles.deltaHigh;
		}
		if (ele?.updatedQty !== null && ele?.updatedQty !== ele?.orderedQty) {
			return styles.updated;
		}
		if (ele?.skipped && selectedType?.value !== 'INGREDIENT') {
			return styles.skipped;
		}
		return '';
	};

	const handleSelectType = (e, value) => {
		if (value) {
			setSelectedType(value);
		}
	};

	const DATA = useMemo(() => {
		return (listData?.[selectedType?.value] ?? [])
			?.filter((ele) => {
				return (
					ele?.productName
						?.toLowerCase()
						?.indexOf(searchQuery?.toLowerCase()?.trim()) !== -1
				);
			})
			?.map((ele) => {
				const rowStyles = getRowStyles(ele);
				return {
					...ele,
					id:
						getElementId(ele),
					rowStyles,
				};
			});
	}, [listData, selectedType, searchQuery]);

	const handleExport = () => {
		const headers = SPECIAL_COLUMNS?.map((ele) => {
			return ele?.title;
		})?.filter((ele) => {
			return ele !== 'Final Indent' && ele !== 'Recipe Exists';
		});
		headers?.splice(1, 0, ...['Variant Name', 'Sku Type']);
		const totalValues = [];
		const exportData = [
			...listData?.PRODUCT,
			...listData?.INTERMEDIATE,
			...listData?.INGREDIENT,
		];
		exportData?.forEach((product) => {
			const delta =
				product?.updatedQty !== product?.orderedQty
					? product?.updatedQty - product?.orderedQty
					: 0;
			let str = '';
			if (product?.scheduledAtDelta) {
				if (product?.scheduledAtDelta < 0) {
					str = 'T' + product?.scheduledAtDelta;
				} else if (product?.scheduledAtDelta === 0) {
					str = 'T';
				} else {
					str = 'T+' + product?.scheduledAtDelta;
				}
			}
			totalValues?.push([
				product?.productName ?? '-',
				product?.variantName ?? '-',
				product?.productType ?? '-',
				product?.unit ?? '-',
				product?.categoryName ?? '-',
				product?.fromProcess ?? '-',
				product?.toProcess ?? '-',
				str ?? '-',
				product?.stock ?? '-',
				product?.currentDayStockIn ?? '-',
				product?.currentDayCount ?? '-',
				product?.prePlanStock ?? '-',
				product?.threshold ?? '-',
				product?.orderedQty ?? '-',
				'-',
				delta ?? '-',
				product?.expectedLeftOver ?? '-',
				product?.calculatedQtyNonAdjusted ?? '-',
				(product?.leftOver ?? '') + '/' + (product?.inputLeftOver ?? '') ?? '-',
				product?.plannedLeftOver ?? '-',
				product?.leftOver ?? '-',
				(product?.calculatedQty +
				(product?.calculatedQtyNonAdjusted < 0
					? // eslint-disable-next-line prefer-template
					  '(' + product?.calculatedQtyNonAdjusted + ')'
					: '')) ?? '-',
				product?.wastage ?? '-',
				product?.batchSize ?? '-',
				product?.finalProduction ?? '-',
			]);
		});
		handleExcelDownload(
			headers,
			totalValues,
			`Production Plan for ${data?.date}`
		);
	};
	return (
		<BaseModal
			heading={`Production Plan for ${data?.date}`}
			open={show}
			handleClose={handleClose}
			customStyles={{
				width: '95%',
				height: '95vh',
				// top: '25%',
			}}
			renderBtns={renderBtns}
			// handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<div className={styles.filters}>
					<Input
						className={styles.search}
						value={searchQuery}
						onChange={(e) => {
							setSearchQuery(e.target.value);
						}}
						placeholder='Search SKU'
					/>
					<ToggleButtonGroup
						value={selectedType}
						exclusive
						onChange={handleSelectType}
						style={{
							background: selectedType?.color,
							border: 'none',
						}}
					>
						{skuTypes?.map((ele) => {
							return (
								<ToggleButton
									style={{
										border: 'none',
										background: ele?.color,
									}}
									key={ele?.value}
									value={ele}
								>
									{ele?.value}
								</ToggleButton>
							);
						})}
					</ToggleButtonGroup>
				</div>
				<div
					style={{
						background: selectedType?.color,
					}}
					className={classes(
						styles.list,
						data?.hideButtons ? styles.fullHeight : ''
					)}
				>
					<ListTable
						colMap={COLMAP}
						fixedHeaderTop={0}
						columns={[]}
						data={DATA}
						onExportClick={handleExport}
						actions={[...SPECIAL_COLUMNS]?.filter((ele) => {
							let columnsToExclude = [
								'Final Indent',
								'Expected Left Over',
								'From Process',
								'To Process',
								'Pre Batch Without Left Over',
								'Left Over (Input)',
							];
							if (selectedType?.value === 'PRODUCT') {
								columnsToExclude?.push('Wastage %');
								columnsToExclude?.push(
									'Left Over (From Executed plans)'
								);
								columnsToExclude?.push(
									'Left Over (Used/Input)'
								);
								columnsToExclude?.push(
									'Left Over (Already Planned)'
								);
								columnsToExclude?.push(
									`Plan ${stockCheckAtDate} Planned Extra Production`
								);
							}
							if (selectedType?.value === 'INTERMEDIATE') {
								columnsToExclude?.push(
									...[
										'Stock At ' + stockCheckedAt,
										'Plan ' +
											stockCheckAtDate +
											' Produced till ' +
											stockCheckedAt,
										'Plan ' +
											stockCheckAtDate +
											' Planned Production',
										'Left Over (From Executed plans)',
										'Left Over (Used/Input)',
										'Left Over (Already Planned)',
									]
								);
								columnsToExclude = columnsToExclude?.filter(
									(col) => {
										return col !== 'Left Over (Input)';
									}
								);
							}
							if (selectedType?.value !== 'INGREDIENT') {
								columnsToExclude?.push('Scheduled');
							}
							if (selectedType?.value === 'INGREDIENT') {
								columnsToExclude = [
									'Recipe Exists',
									'Final Production',
									'Production Factor (+/- Manual Calibration)',
									'Production Factor (+/- Manual Calibration)',
									// eslint-disable-next-line prefer-template
									'Stock At ' + stockCheckedAt,
									'Plan ' +
										stockCheckAtDate +
										' Produced till ' +
										stockCheckedAt,
									'Plan ' +
										stockCheckAtDate +
										' Planned Production',
									'Projected Stock at ' +
										nextStockCheckAtDate +
										' 7:59 AM',
									'Minimum Stock at ' +
										nextStockCheckAtDate +
										' 7:59 AM',
									'Order Quantity PO Delivery Date ' +
										scheduledFor,
									`Plan ${stockCheckAtDate} Planned Extra Production`,
									// eslint-disable-next-line prefer-template
									...(!isLeftOverCheck
										? [
												'Left Over (From Executed plans)',
												'Left Over (Already Planned)',
										  ]
										: []),
								];
							}
							return !columnsToExclude?.includes(ele?.title);
						})}
						special={SPECIAL_ITEMS}
					/>
				</div>
				{/* <p>Please add Production Plan Description</p> */}
				{!data?.hideButtons && (
					<Input
						className={styles['desc-input']}
						value={planDescription}
						onChange={handleChange}
						placeholder='Description'
					/>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.productionPlanModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleProductionPlanModal: modalActions.toggleProductionPlanModal,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductionPlanModal);
