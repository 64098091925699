import { Switch } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import { ListTable } from '../../../Components/ListTable';
import { inputHelper } from '../../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'Store Name',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [
	{
		title: 'Automated KoT Printing',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'KoT/Order Summary',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Sales Item QR Labe',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Inventory Item QR Label',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Invoice Printing',
		config: {
			type: 'UPDATE',
		},
	},
];

const COLMAP = {
	'Store Name': 'name',
	'KoT/Order Summary': 'printOrderSummary',
	'Sales Item QR Labe': 'printItemQRLabel',
	'Inventory Item QR Label': 'printInventoryQRLabel',
	'Invoice Printing': 'printInvoice',
	'Automated KoT Printing': 'printKOT',
};

const PrinterConfigListPage = (props) => {
	const { fetchStores, toggleDisable, data, query } = props;
	const handleEnable = (e) => {
		const { fieldValue, dataset, fieldName } = inputHelper(e);
		toggleDisable({
			entity: 'PRINTER_CONFIG',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					[fieldName]: fieldValue,
				},
			},
			method: 'ENABLE',
		});
	};

	const SPECIAL_ITEMS = {
		'KoT/Order Summary': (id, isEnabled) => {
			return (
				<Switch
					name='printOrderSummary'
					value='KoT/printOrderSummary Summary'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'PRINTER_CONFIG',
					}}
				/>
			);
		},
		'Sales Item QR Labe': (id, isEnabled) => {
			return (
				<Switch
					name='printItemQRLabel'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'PRINTER_CONFIG',
					}}
				/>
			);
		},
		'Inventory Item QR Label': (id, isEnabled) => {
			return (
				<Switch
					name='printInventoryQRLabel'
					value='Inventory Item QR Label'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'PRINTER_CONFIG',
					}}
				/>
			);
		},
		'Invoice Printing': (id, isEnabled) => {
			return (
				<Switch
					name='printInvoice'
					value='Invoice Printing'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'PRINTER_CONFIG',
					}}
				/>
			);
		},
		'Automated KoT Printing': (id, isEnabled) => {
			return (
				<Switch
					name='printKOT'
					value='Automated KoT Printing'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'PRINTER_CONFIG',
					}}
				/>
			);
		},
	};

	useEffect(() => {
		fetchStores({
			entity: 'PRINTER_CONFIG',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className='list-page-root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data.filter((store) => {
					return (
						store.name
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRINTER_CONFIG.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchStores: formActions.gateway,
	toggleDisable: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrinterConfigListPage);
