/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
import { FormControlLabel, IconButton, Switch } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { IconInput } from '../../Inputs';
import styles from './CustomizationSKU.module.scss';
import { useAuth } from '../../../Auth/Login/auth';

const CustomizationSKU = (props) => {
	const {
		sku,
		variant,
		idx,
		applicableOn,
		customizationType,
		deltaPricing,
		mandatoryFields,
		handleInput,
		showVariants,
		allVariants,
		defaultVariant,
		checkApplicability,
		extraProps,
		isDisabled,
	} = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;

	const generateContainerWeightIcon = (skuUnit) => {
		if (measurement === 'US' && skuUnit !== 'oz') {
			return 'oz';
		}
		if (measurement !== 'US' && skuUnit !== 'gram') {
			return 'gram';
		}
		return skuUnit;
	};

	const generateInputFields = () => {
		if (showVariants) {
			return applicableOn.reduce(
				(accVariant, currVariant, idxVariant) => {
					const calculation =
						(sku.variants[0].qty *
							((allVariants?.[idxVariant]?.servingQtyPercentage ??
								0) /
								100) *
							allVariants?.[idxVariant]?.batchSize) /
						allVariants?.[0]?.batchSize;
					// console.log(
					// 'calculation',
					// calculation,
					// Number.isNaN(calculation)
					// );
					const variantQtyBatchWise = !Number.isNaN(calculation)
													? Number(calculation)?.toFixed(2) : 0;

					if (!checkApplicability || currVariant.isApplied) {
						const inputsDOM = (
							<>
								{(extraProps.skuMeta?.containerWeight ??
									true) &&
									idxVariant === 0 && (
										<div
											className='row'
											style={{
												justifyContent: 'space-between',
												margin: '0.65rem 0px',
												alignItems: 'center',
												border: isDisabled
													? '1px solid black'
													: 'none',
												borderLeft: 'none',
												borderRight: 'none',
												borderBottom: isDisabled
													? 'none'
													: 'auto',
											}}
										>
											<label
												className={`${styles.label} form-label`}
												htmlFor={`skuContainerWeight-${idx}`}
												style={{
													width: '50%',
												}}
											>
												Container Weight
											</label>
											<IconInput
												type='custom'
												iconText={generateContainerWeightIcon(
													sku.unit
												)}
												size='large'
												name='containerWeight'
												id={`skuContainerWeight-${idx}`}
												value={sku.containerWeight}
												handleInput={handleInput}
												dataType='sku'
												dataId={idx}
												required={
													mandatoryFields
														.customizations.items[1]
														.skus[0].containerWeight
												}
											/>
										</div>
									)}
								{idxVariant === 0 && !isDisabled && (
									<div
										className='row'
										style={{
											display: 'grid',
											gridTemplateColumns:
												'1fr .3fr .3fr',
											alignItems: 'center',
											border: '1px solid black',
											borderLeft: 'none',
											fontSize: isDisabled
												? '.7rem'
												: '.8rem',
											borderRight: 'none',
											padding: '1px 2px',
											borderBottom: isDisabled
												? 'none'
												: 'auto',
										}}
									>
										<label
											className={`${styles.label} form-label`}
											htmlFor={`skuQty-${idx}`}
										>
											Qty
										</label>
										<label
											className={`${styles.label} form-label`}
											htmlFor={`skuQty-${idx}`}
										>
											Use
										</label>
										<label
											style={{
												fontSize: '.7rem',
											}}
											className={`${styles.label} form-label`}
											htmlFor={`skuQty-${idx}`}
										>
											Override
										</label>
									</div>
								)}
								{(extraProps.skuMeta?.qty ?? true) && (
									<div
										className='row'
										style={{
											display: 'grid',
											gridTemplateColumns:
												'1fr .5fr .3fr',
											alignItems: 'center',
										}}
									>
										<IconInput
											strikeOff={
												sku.variants[idxVariant].skip
											}
											style={{ margin: '.2rem' }}
											type='custom'
											iconText={sku.unit}
											name='qty'
											step={0.01}
											size='large'
											isEdited={
												isDisabled
													? sku.variants[idxVariant]
															.overrideRatio ===
													  true
													: false
											}
											editedValue={variantQtyBatchWise}
											showExtra={sku.unit === 'count'}
											extraValue={
												((allVariants?.[idxVariant]
													?.minWeight +
													allVariants?.[idxVariant]
														?.maxWeight) /
													2) *
												parseFloat(
													sku.variants[idxVariant]
														?.overrideRatio
														? sku.variants[
																idxVariant
														  ].qty
														: variantQtyBatchWise
												)
											}
											id={`skuQty-${idx}`}
											value={
												// eslint-disable-next-line no-nested-ternary
												idxVariant !== 0
													? sku.variants[idxVariant]
															?.overrideRatio
														? sku.variants[
																idxVariant
														  ].qty
														: variantQtyBatchWise
													: sku.variants[0].qty
											}
											handleInput={handleInput}
											dataType='sku'
											dataId={idx}
											isDisabled={
												idxVariant > 0 &&
												!sku.variants[idxVariant]
													.overrideRatio
											}
											required={
												mandatoryFields.customizations
													.items[1].skus[0].qty
											}
											dataAttrs={{
												'data-variant': idxVariant,
											}}
										/>

										{!isDisabled && (
											<>
												<IconButton
													dataAttrs={{
														'data-variant':
															idxVariant,
													}}
													name='skip'
													onClick={() => {
														handleInput({
															target: {
																name: 'skip',
																type: 'checkbox',
																checked:
																	!sku
																		.variants[
																		idxVariant
																	].skip,
															},
															currentTarget: {
																dataset: {
																	type: 'sku',
																	id: `${idx}`,
																	variant: `${idxVariant}`,
																},
															},
														});
													}}
												>
													{isDisabled ? (
														<>
															{sku.variants[
																idxVariant
															].skip === true ? (
																<CloseIcon
																	style={{
																		color: 'red',
																	}}
																/>
															) : (
																<>
																	<CheckIcon
																		style={{
																			color: 'green',
																		}}
																	/>
																</>
															)}
														</>
													) : (
														<>
															{sku.variants[
																idxVariant
															].skip === true ? (
																<DisabledByDefaultIcon
																	style={{
																		color: 'red',
																	}}
																/>
															) : (
																<>
																	<CheckBoxIcon
																		style={{
																			color: 'green',
																		}}
																	/>
																</>
															)}
														</>
													)}
												</IconButton>
												<IconButton
													style={{
														visibility:
															idxVariant === 0
																? 'hidden'
																: 'visible',
													}}
													dataAttrs={{
														'data-variant':
															idxVariant,
													}}
													name='overrideRatio'
													onClick={() => {
														handleInput({
															target: {
																name: 'overrideRatio',
																type: 'checkbox',
																checked:
																	!sku
																		.variants[
																		idxVariant
																	]
																		.overrideRatio,
															},
															currentTarget: {
																dataset: {
																	type: 'sku',
																	id: `${idx}`,
																	variant: `${idxVariant}`,
																},
															},
														});
														handleInput({
															target: {
																name: 'qty',
																value: `${variantQtyBatchWise}`,
															},
															currentTarget: {
																dataset: {
																	type: 'sku',
																	id: `${idx}`,
																	variant: `${idxVariant}`,
																},
															},
														});
													}}
												>
													{isDisabled ? (
														<>
															{sku.variants[
																idxVariant
															].overrideRatio ===
															true ? (
																<CheckIcon
																	style={{
																		color: 'blue',
																	}}
																/>
															) : (
																<>
																	<CheckBoxOutlineBlankIcon
																		style={{
																			visibility:
																				'hidden',
																		}}
																	/>
																</>
															)}
														</>
													) : (
														<>
															{sku.variants[
																idxVariant
															].overrideRatio ===
															true ? (
																<CheckBoxIcon
																	style={{
																		color: 'blue',
																	}}
																/>
															) : (
																<>
																	<CheckBoxOutlineBlankIcon />
																</>
															)}
														</>
													)}
												</IconButton>
											</>
										)}
									</div>
								)}
								{(extraProps.skuMeta?.price ?? true) && (
									<div
										className='row'
										style={{
											justifyContent: 'space-between',
											margin: '0.65rem 0px',
											display: deltaPricing
												? 'flex'
												: 'none',
										}}
									>
										<label
											className={`${styles.label} form-label`}
											htmlFor={`skuPrice-${idx}`}
										>
											Price
										</label>
										<IconInput
											type='rupee'
											name='price'
											step='any'
											id={`skuPrice-${idx}`}
											value={
												sku.variants[idxVariant].price
											}
											handleInput={handleInput}
											dataType='sku'
											dataId={idx}
											required={
												mandatoryFields.customizations
													.items[1].skus[0].price
											}
											dataAttrs={{
												'data-variant': idxVariant,
											}}
										/>
									</div>
								)}
							</>
						);
						accVariant.push(inputsDOM);
					}
					return accVariant;
				},
				[]
			);
		}
		const defaultVariantIdx = sku.variants.findIndex((variantObj) => {
			return variantObj.id === defaultVariant.id;
		});
		if (defaultVariantIdx === -1) return <></>;
		const defaultVariantObj = sku.variants[defaultVariantIdx];
		return (
			<>
				<div
					className='row'
					style={{
						justifyContent: 'space-between',
						margin: '0.65rem 0px',
					}}
				>
					<label
						className={`${styles.label} form-label`}
						htmlFor={`skuQty-${idx}`}
					>
						Quantity
					</label>
					<IconInput
						type='custom'
						iconText={sku.unit}
						name='qty'
						id={`skuQty-${idx}`}
						value={defaultVariantObj.qty}
						handleInput={handleInput}
						dataType='sku'
						dataId={idx}
						required={
							mandatoryFields.customizations.items[1].skus[0].qty
						}
						dataAttrs={{
							'data-variant': defaultVariantIdx,
						}}
					/>
				</div>
			</>
		);
	};

	return (
		<div className={styles.root}>
			<fieldset disabled={isDisabled} className='form-fieldset'>
				<div className={`${styles.variant} row`}>
					{variant?.displayName ? `(${variant?.displayName})` : ''}
				</div>
				<div className='row'>
					{customizationType === 'REPLACEABLE' && (
						<FormControlLabel
							value='start'
							className={`${styles['form-control-label']}`}
							control={
								// eslint-disable-next-line react/jsx-wrap-multilines
								<Switch
									color='primary'
									size='small'
									className={`${styles.switch}`}
									name='isDefault'
									value='isDefault'
									checked={sku.isDefault}
									onChange={handleInput}
									inputProps={{
										'data-id': idx,
										'data-type': 'sku',
									}}
								/>
							}
							label='Default'
							labelPlacement='start'
						/>
					)}
				</div>
				{(extraProps.skuMeta || customizationType !== 'REMOVABLE') &&
					generateInputFields()}
			</fieldset>
		</div>
	);
};

export default CustomizationSKU;
