/* eslint-disable no-tabs */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import PromoCodeDetails from '../../../Components/Forms/Promo Code/PromoCodeDetails';
import Validity from '../../../Components/Forms/Promo Code/Validity';
import Applicability from '../../../Components/Forms/Promo Code/Applicability/Applicability';
import { CreatePageHeader } from '../../../Components/Headers';
import promoCodeConfig from './promoCodes.config';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import { inputHelper, isEmpty } from '../../../Helpers';
import { urls } from '../../../Constants';
import { formActions } from '../../../Actions';
import { notificationServices } from '../../../Services';

const CreatePromoCodePage = (props) => {
	const {
		isEdit,
		savePromoCode,
		fetchPromoCode,
		data,
		isDisabled,
		stores,
		users,
		products,
		categories,
		breadcrumbs,
	} = props;
	const [state, setState] = useState({
		...promoCodeConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const { fields, errors } = state;
	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (isEdit) {
			Promise.all([
				props.fetchStores({
					entity: 'STORES',
					req: {
						params: {
							id: '',
						},
					},
					method: 'LIST',
					promisify: true,
				}),

				props.fetchProductCategory({
					entity: 'PRODUCT_CATEGORY',
					req: {
						params: {
							id: '',
						},
					},
					method: 'LIST',
					promisify: true,
				}),
				props.fetchProduct({
					entity: 'ALL_PRODUCTS',
					req: {
						params: {
							id: '',
						},
					},
					method: 'LIST',
					promisify: true,
				}),
				props.fetchUsers({
					entity: 'USERS',
					req: {
						params: {
							id: '',
						},
					},
					method: 'LIST',
					promisify: true,
				}),
			])
				.then(() => {
					if (!isEmpty(data) && id === data.id) {
						setState(
							promoCodeConfig.serializer(
								state,
								data,
								stores,
								users,
								products,
								categories,
								id
							)
						);
					} else {
						fetchPromoCode({
							entity: 'PROMO_CODES',
							req: {
								params: {
									id,
								},
							},
							method: 'VIEW',
						});
					}
				})
				.catch(() => {});
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(
					promoCodeConfig.serializer(
						state,
						data,
						stores,
						users,
						products,
						categories,
						id
					)
				);
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		const newState = {};
		let error = '';
		// for form validations and error generation
		switch (fieldName) {
			case 'couponCode':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};

	const addNewData = (newArr, fieldName) => {
		setState(
			_.update(_.cloneDeep(state), `fields.[${fieldName}]`, (arr) => {
				const newAdd = arr.concat(newArr);
				return newAdd;
			})
		);
	};
	const handleChangeDateTime = (fieldValue, fieldName) => {
		const timeSlotObj = _.cloneDeep(fieldValue);
		if (moment(timeSlotObj).isValid()) {
			setState(
				_.update(_.cloneDeep(state), `fields.[${fieldName}]`, () => {
					return timeSlotObj.toString();
				})
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Date Time',
			});
		}
	};

	const handleDelete = (idx, fieldName, idKeyName) => {
		const arr = fields[fieldName].filter((obj) => {
			return obj[idKeyName] !== parseInt(idx, 10);
		});
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: arr,
			},
			...errors,
		});
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.PROMO_CODES}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.PROMO_CODES}`);
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = promoCodeConfig.deserializer(state).fields;
		const isValid = promoCodeConfig.validator(state);
		if (isValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			if (isEdit) {
				req.params = {
					couponCode: data[id].couponCode,
				};
				req.data.active = state.fields.active;
				req.data.id = state.fields.id;
				req.data.createdDate = state.fields.createdDate;
				req.data.updatedDate = state.fields.updatedDate;
				savePromoCode({
					entity: 'PROMO_CODES',
					req,
					method: 'UPDATE',
					redirect: `${urls.EXTENSION}${urls.PROMO_CODES}`,
				});
			} else {
				savePromoCode({
					entity: 'PROMO_CODES',
					req,
					method: 'CREATE',
					redirect: `${urls.EXTENSION}${urls.PROMO_CODES}`,
				});
			}
		}
	};

	const generateDefaultOption = (type, discountType) => {
		switch (type) {
			case 'discountType':
				if (!discountType || discountType.length === 0) {
					return (
						<option value={null} selected disabled hidden>
							Select a type
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Promo' : 'Create Promo'}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${fields.couponCode}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Promo Details'
					name='promoDetails'
				>
					<PromoCodeDetails
						mandatoryFields={promoCodeConfig.mandatoryFields}
						fields={fields}
						handleInput={handleInput}
						errors={errors}
						isEdit={isEdit}
						isDisabled={isDisabled}
						generateDefaultOption={generateDefaultOption}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='validity'
					sectionName='Validity'
					name='validity'
				>
					<Validity
						mandatoryFields={promoCodeConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						isDisabled={false}
						handleChangeDateTime={handleChangeDateTime}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='applicability'
					sectionName='Applicability'
					name='applicability'
				>
					<Applicability
						mandatoryFields={promoCodeConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						handleDelete={handleDelete}
						handleConfirm={addNewData}
						isDisabled={isDisabled}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PROMO_CODES.data.VIEW;
	const users = state.form.USERS.data.LIST;
	const categories = state.form.PRODUCT_CATEGORY.data.LIST;
	const products = state.form.ALL_PRODUCTS.data.LIST;
	const stores = state.form.STORES.data.LIST;
	return {
		data,
		stores,
		users,
		products,
		categories,
	};
};
const mapDispatchToProps = {
	savePromoCode: formActions.gateway,
	fetchPromoCode: formActions.gateway,
	fetchStores: formActions.gateway,
	fetchProductCategory: formActions.gateway,
	fetchProduct: formActions.gateway,
	fetchUsers: formActions.gateway,
};
CreatePromoCodePage.defaultProps = {
	isEdit: false,
	isDisabled: false,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreatePromoCodePage);
