import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BaseModal } from '../BaseModal';
import styles from './ApplicabilityModal.module.scss';
/* eslint-disable no-tabs */
import { formActions, modalActions } from '../../../Actions';
import { CheckList } from '../../CheckList';

const HEADING = 'Select Users';
const ApplicableUsersModal = (props) => {
	const initialState = {
		selectedList: [],
		query: '',
	};
	// eslint-disable-next-line object-curly-newline
	const {
		show,
		toggleApplicableUsersModal,
		fetchUsers,
		users,
		selectedUserList,
	} = props;
	useEffect(() => {
		fetchUsers({
			entity: 'USERS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);
	const [state, setState] = useState({
		...initialState,
	});
	const { selectedList } = state;
	const handleClose = () => {
		setState({
			...initialState,
			query: '',
		});
		toggleApplicableUsersModal(false);
	};
	const handleClick = (idx) => {
		if (selectedList.includes(idx)) {
			const newSelectedList = selectedList.filter((id) => {
				return id !== idx;
			});
			setState({
				...state,
				selectedList: newSelectedList,
			});
		} else {
			const newSelectedList = selectedList;
			newSelectedList.push(idx);
			setState({
				...state,
				selectedList: newSelectedList,
			});
		}
	};
	const handleConfirm = () => {
		const ids = [];
		for (let idx = 0; idx < selectedList.length; idx++) {
			ids.push(selectedList[idx]);
		}
		const list = users.filter((obj) => {
			return selectedList.includes(obj.userId);
		});
		props.handleConfirm(list, 'applicableUsers');
		toggleApplicableUsersModal(false);
		setState({
			...state,
			selectedList: [],
		});
	};
	const handleSearchInput = (queryString) => {
		setState({
			...state,
			query: queryString,
		});
	};

	const generateList = () => {
		return users
			.filter((obj) => {
				return (
					!selectedUserList.find((selectedUser) => {
						return selectedUser.userId === parseInt(obj.userId, 10);
					}) &&
					(obj.name
						.toLowerCase()
						.indexOf(state.query.trim().toLowerCase()) !== -1 ||
						obj.user.indexOf(state.query.trim().toLowerCase()) !== -1
					)
				);
			})
			.map((obj) => {
				const name = obj.name ? ` (${obj.name})` : '';
				return {
					id: parseInt(obj.userId, 10),
					title: `${obj.user}${name}`,
				};
			});
	};

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<CheckList
					list={generateList()}
					selectedItems={state.selectedList}
					handleItemSelect={handleClick}
					showSearch
					handleSearchInput={handleSearchInput}
					placeholder='Search User'
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const users = state.form.USERS.data.LIST;
	const { data, show } = state.modal.applicable_users;
	return {
		show,
		data,
		users,
	};
};

const mapDispatchToProps = {
	toggleApplicableUsersModal: modalActions.toggleApplicableUsersModal,
	fetchUsers: formActions.gateway,
	updateMenu: formActions.gateway,
};
ApplicableUsersModal.defaulProps = {
	selectedUserList: [],
	show: false,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ApplicableUsersModal);
