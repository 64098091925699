import moment from 'moment';
// import React from 'react';
import { Add, Remove } from '@mui/icons-material';
import { ListTable } from '@/Components/ListTable';
import { convertWeightUnit } from '@/Helpers';

const TABLE_COLUMNS = [
	{
		title: 'QRCode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Parent QRCode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ref. ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Stock In Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Movement Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Activity Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Post Update Qty.',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Reason',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	QRCode: 'qrCode',
	'Parent QRCode': 'parentQr',
	'Movement Type': 'movementType',
	'Ref. ID': 'id',
	'Stock In Date': 'stockedInAt',
	Expiry: 'expiryTime',
	Quantity: 'variantId',
	'Post Update Qty.': 'postUpdateQty',
	'Activity Date': 'activityDate',
	Reason: 'reason',
};

const ScanWiseTable = (props) => {
	const { data, measurement, itemDetails } = props;

	const SPECIAL_ITEMS = {
		QRCode: (qrCodeVal) => {
			return (
				<a
					className='underline text-blue-400'
					href={`/console2/order/tracking?type=qrCode&value=${qrCodeVal}`}
					target='_blank'
					rel='noreferrer'
				>
					{qrCodeVal}
				</a>
			);
		},
		'Post Update Qty.': (value) => {
			const unit = itemDetails?.unit;
			if (value == null) {
				return '-';
			}
			return `${convertWeightUnit(value, unit)}`;
		},
		'Ref. ID': (id, itemObj) => {
			if (itemObj?.orderId) {
				return `Dispatch Order ID: ${itemObj.orderId}`;
			}
			if (itemObj?.poId) {
				return `Work Order ID: ${itemObj.poId}`;
			}
			if (itemObj?.grnId) {
				return `GRN ID: ${itemObj.grnId}`;
			}
			if (itemObj?.auditId) {
				return `Audit ID: ${itemObj.auditId}`;
			}
			return '-';
		},
		SKU: (value, itemObj) => {
			return `${itemObj?.skuName} ${
				itemObj?.variantName ? `(${itemObj.variantName})` : ''
			}`;
		},
		Quantity: (variantId, itemObj) => {
			let unit = itemDetails?.unit ?? 'gram';
			if (!unit && measurement === 'US') {
				unit = 'oz';
			}
			let icon = <Add className='text-green-900' />;
			if (itemObj?.movementType === 'Stocked Out') {
				icon = <Remove className='text-red-800' />;
			}
			return (
				<div className='flex items-center gap-1'>
					<div className='animate-pulse'>{icon}</div>
					<p className=''>{convertWeightUnit(itemObj?.qty, unit)}</p>
				</div>
			);
		},
		'Stock In Date': (stockInDate) => {
			return (
				<div className='w-[150px]'>
					{moment(stockInDate)?.format('YYYY-MM-DD HH:mm A')}
				</div>
			);
		},
		'Activity Date': (activityDate) => {
			return (
				<div className='w-[150px]'>
					{moment(activityDate)?.format('YYYY-MM-DD HH:mm A')}
				</div>
			);
		},
	};

	const getRowStyles = (movementType) => {
		if (movementType === 'Stocked In') {
			return 'bg-[#00ff0d41]';
		}
		if (movementType === 'Stocked Out') {
			return 'bg-[#ff0c0c41]';
		}
		return '';
	};

	return (
		<div>
			<ListTable
				allowExport={false}
				allowCheckList={false}
				showBtnContainer={false}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data?.map((ele) => {
					return {
						...ele,
						rowStyles: getRowStyles(ele?.movementType),
					};
				})}
				special={{
					...SPECIAL_ITEMS,
				}}
				fixedHeaderTop={0}
			/>
		</div>
	);
};

export default ScanWiseTable;
