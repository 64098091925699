import { connect } from 'react-redux';
import {
	useEffect, useMemo, useState
} from 'react';
import {
	Autocomplete, Button, TextField
} from '@mui/material';
import styles from './VendorProductMap.module.scss';
// import { notificationServices } from '../../Services';
import { classes } from '../../Helpers';
import { notificationServices } from '../../Services';
import { formActions, modalActions } from '../../Actions';
import { ListTable } from '../../Components/ListTable';
import allVendorServices from '../../Services/Form Services/allvendor.services';
import VendorProductMapModal from '../../Components/Modals/VendorProductMapModal/VendorProductMapModal';
import MasterDetail from '../../Components/MasterDetail/MasterDetail';

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'Product Name': 'productName',
	'SKU Type': 'skuType',
};
const ACTION_COLUMNS = [
	{
		title: 'Delete Mapping',
		config: {
			type: 'CANCEL',
		},
	},
];

function VendorProductMap(props) {
	const { fetchProduct, toggleModal, vendorsList, fetchVendors, products } =
		props;
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [currentData, setCurrentData] = useState(null);
	// const [currentTab, setCurrentTab] = useState('PRODUCT_TYPE');
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [mappedVendors, setMappedVendors] = useState([]);

	useEffect(() => {
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchProduct({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
			promisify: true,
		});
		return () => {
			toggleModal(false, {});
		};
	}, []);

	async function getList() {
		if (loading) {
			return;
		}
		try {
			setLoading(true);
			const response = await allVendorServices.vendorProductMapList({
				params: {
					vendorId: selectedVendor?.id,
				},
			});
			if (response?.statusCode === 200) {
				setCurrentData(response?.data);
			} else {
				notificationServices?.generateNotification({
					message:
						response?.message ??
						response?.status ??
						'Some error Occured',
					type: 'error',
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				message: error,
				type: 'error',
			});
		} finally {
			setLoading(false);
		}
	}
	useEffect(() => {
		if (selectedVendor?.id) {
			getList();
		}
	}, [selectedVendor?.id]);

	const handleConfirmMap = async (selectedVendorList, productsList) => {
		if (!selectedVendorList?.length > 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No Vendor Selected',
			});
			return;
		}
		if (!productsList?.length > 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No Product Selected',
			});
			return;
		}
		try {
			const response = await allVendorServices.mapVendorToProduct({
				data: {
					vendorsList: selectedVendorList,
					productsList,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					message:
						response?.message ??
						response?.status ??
						'Some error Occured',
					type: 'success',
				});
				toggleModal(false, {});
				if (selectedVendor?.id) {
					getList();
				}
			} else {
				notificationServices?.generateNotification({
					message: response?.message ?? response?.status ?? 'Success',
					type: 'error',
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				message: error,
				type: 'error',
			});
		}
	};
	const handleDelete = async (productId) => {
		try {
			const response = await allVendorServices.deleteMapping({
				data: {
					vendorId: selectedVendor?.id,
					productId,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					message: response?.message ?? response?.status ?? 'Success',
					type: 'success',
				});
				if (selectedVendor?.id) {
					getList();
				}
			} else {
				notificationServices?.generateNotification({
					message:
						response?.message ??
						response?.status ??
						'Some error Occured',
					type: 'error',
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				message: error,
				type: 'error',
			});
		}
	};
	const SPECIAL_ITEMS = {
		'Delete Mapping': (itemId) => {
			const currMapping = currentData?.find((ele) => {
				return ele?.id === itemId;
			});
			return (
				<div
					style={{
						width: '125px',
					}}
				>
					<Button
						variant='outlined'
						color='error'
						onClick={() => {
							handleDelete(currMapping?.productId);
						}}
					>
						Delete
					</Button>
				</div>
			);
		},
	};

	const renderDetailBody = (selectedNav) => {
		setSelectedVendor(selectedNav);
		return (
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				showTotal
				data={currentData}
				actions={[...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		);
	};

	const handleProductSelection = async (event, selectedOption) => {
		setCurrentData([]);
		setSelectedVendor(null);
		setSelectedProduct(selectedOption);
		if (selectedOption) {
			const req = {
				params: {
					productId: selectedOption?.id,
				},
			};
			try {
				const response = await allVendorServices.getMappedVendors(req);
				if (response?.statusCode === 200) {
					setMappedVendors(response?.data);
				} else {
					setMappedVendors([]);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		} else {
			setMappedVendors([]);
		}
	};

	const VENDORS = useMemo(() => {
		return vendorsList?.filter((ele) => {
			if (!selectedProduct) return true;
			return mappedVendors?.includes(ele?.id);
		});
	}, [vendorsList, mappedVendors]);

	return (
		<div className={classes(styles.root)}>
			<VendorProductMapModal handleConfirm={handleConfirmMap} />
			<div className={styles.header}>
				<div className={styles.heading}>Vendor - Product Mapping</div>
			</div>
			<div className={styles.tabs}>
				<div className={styles?.selector}>
					<Autocomplete
						disablePortal
						id='combo-box-demo'
						value={selectedProduct ?? null}
						getOptionLabel={(option) => {
							return option?.productName;
						}}
						onChange={handleProductSelection}
						options={products ?? []}
						sx={{
							width: 300,
							background: 'rgba(255,255,255,.1)',
						}}
						renderInput={(params) => {
							return (
								<TextField {...params} label='Select Product' />
							);
						}}
					/>
					<p className={styles['helper-text']}>Select product to check vendors</p>
				</div>
				<div className={styles.buttons}>
					<Button
						onClick={() => {
							toggleModal(true, {
								heading: 'Vendor - Product Map creator',
								selectedVendor,
							});
							// if (selectedVendor?.id) {
							// } else {
							// notificationServices?.generateNotification({
							// message:
							// 'Please select a vendor to proceed',
							// type: 'error',
							// });
							// }
						}}
						variant='contained'
					>
						+ Add
					</Button>
				</div>
			</div>

			<div className={styles.container}>
				<MasterDetail
					selectionList={VENDORS?.map((ele) => {
						return {
							...ele,
						};
					})}
					renderDetailBody={renderDetailBody}
					selectorHead='Vendor:'
					selectedNav={selectedVendor}
					setSelectedNav={setSelectedVendor}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const vendorsList = state.form.ALL_VENDORS.data.LIST;
	const { LIST: products } = state.form.ALL_PRODUCTS.data;

	return {
		vendorsList,
		products,
		userPermissions,
	};
};
const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
	fetchProduct: formActions.gateway,
	toggleModal: modalActions.toggleVendorProductMapModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorProductMap);
