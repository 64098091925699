/* eslint-disable no-unused-vars */
import { BaseModal } from '../Modals/BaseModal';
import SingleAssetCard from './SingleAssetCard';

const SingleAssetSelectionModal = ({
	open,
	onClose,
	assets,
	onSelect,
	cartItems = {},
}) => (
	<BaseModal
		open={open}
		handleClose={onClose}
		heading='Select Asset'
		width='60rem'
		height='40rem'
		showConfirm={false}
	>
		<div className='grid grid-cols-3 gap-4 p-4 overflow-auto h-full'>
			{assets.map((asset) => (
				<SingleAssetCard
					key={asset.id}
					{...asset}
					onSelect={() => {
						onSelect(asset);
						onClose();
					}}
					// inCart={cartItems.some((item) => item.id === asset.id)}
				/>
			))}
		</div>
	</BaseModal>
);

export default SingleAssetSelectionModal;
