/* eslint-disable no-tabs */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Name: 'skuName',
	Brand: 'brandName',
};

const InventorySkuListPage = (props) => {
	const { data, query, deleteInventorySku, fetchInventorySku } = props;
	useEffect(() => {
		fetchInventorySku({
			entity: 'INVENTORY_SKU',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleDelete = (SkuId) => {
		deleteInventorySku({
			entity: 'INVENTORY_SKU',
			req: {
				params: {
					id: SkuId,
				},
			},
			method: 'DELETE',
		});
	};

	const SPECIAL_ITEMS = {};

	return (
		<div className='list-page-root'>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data.filter((brand) => {
					return (
						brand.skuName
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_SKU.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchInventorySku: formActions.gateway,
	toggleDisableInventorySku: formActions.gateway,
	deleteInventorySku: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventorySkuListPage);
