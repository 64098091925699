const parameters = {
	'Product Name': 'Amritsari Stuffed Kulcha Thali',
	// "Product Name": "Amdueudfuwduwfheuwfhiwehfiehfiewhfiewfiewifhewihihiwiwhewi",
	'Variant Name': 'Large',
	Weight: '158.2gm',
	Temperature: '20.5C',
	'Manufacturing Timestamp': new Date(),
	'Manufacturing Date': new Date(),
	'Expiry Date': '2025-12-01',
	'Batch Number': '2403064',
	'Selling Price': '100',
	ID: '3627',
	qrCode: '12345678912342',
	barCode: '12823891',
	'Maximum Retail Price': '100',
	'Unit Sale Price': '0.1/gm',
	Carbs: '0gm',
	Fats: '0gm',
	Protein: '0gm',
	Quantity: 3,
	Calories: '0gm',
	Calcium: '0gm',
	'Process Name': 'Labelling',
	'From Store': 'Delhi Base Kitchen',
	'Inward Date': new Date(),
	PoID: 'PO-1423',
	'Asset Type': 'Crate',
	'Client Name': 'UY',
	'Scheduled At': new Date(),
};

export default parameters;
