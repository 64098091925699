/* eslint-disable no-tabs */
import _ from 'lodash';
import moment from 'moment';
import { notificationServices } from '../../../../Services';

/* eslint-disable no-param-reassign */
const SpecialMenuConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			expires: null,
			products: [],
		},
		errors: {
			name: '',
			description: '',
			expires: '',
			products: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: true,
		expires: true,
		products: true,
	},
	serializer: (state, specialMenu) => {
		const productArray = Object.keys(specialMenu.products).map((key) => {
			const keyObj = specialMenu.products[key];
			const obj = {
				productId: parseInt(key, 10),
				timeSlots: getTimeSlots(keyObj.timeSlots),
				discount: keyObj.discount,
			};
			return obj;
		});
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: specialMenu.name,
				description: specialMenu.description,
				expires: specialMenu.endsOn
					? moment(specialMenu.endsOn, 'DD-MM-YYYY HH:mm').format(
						'YYYY MM DD HH:mm'
					)
					: null,
				products: productArray,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const obj = {};
		state.fields.products.map((productObj) => {
			obj[productObj.productId] = {
				timeSlots: generateTimeSlots(
					productObj.timeSlots,
					productObj.timeSlots.length
				),
				discount: productObj.discount,
			};
			return obj;
		});
		const newState = {
			name: state.fields.name,
			description: state.fields.description,
			endsOn: state.fields.expires
				? moment(state.fields.expires).format('DD-MM-YYYY HH:mm')
				: null,
			products: obj,
		};
		return newState;
	},
	validator: (state) => {
		if (!state.fields.expires) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter expire details',
			});
			return false;
		}
		if (state.fields.products.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select atleast one product',
			});
			return false;
		}
		return true;
	},
};
function generateTimeSlots(slots, limit) {
	const res = {};
	for (let i = 0; i < 3; i++) {
		if (i < limit) {
			res[`slot${i}`] = {
				endAt: slots[i].endTime,
				startAt: slots[i].startTime,
			};
		} else {
			res[`slot${i}`] = {
				endAt: null,
				startAt: null,
			};
		}
	}
	return res;
}
function getTimeSlots(slots) {
	const res = [];
	Object.keys(slots).map((key) => {
		if (slots[key].startAt) {
			res.push({
				startTime: slots[key].startAt,
				endTime: slots[key].endAt,
			});
		}
		return '';
	});

	return res;
}
export default SpecialMenuConfig;
