import { Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { generateRequiredProps } from '../../../Helpers';
import { DraggableVContainers } from '../../Draggable';
import { IconInput } from '../Inputs';
import { ComboGroup } from './ComboGroup';
import styles from './ComboVariant.module.scss';

const ComboVariant = (props) => {
	const {
		variantDetails,
		activeVariant,
		handleAddNewGroup,
		handleDelete,
		products,
		mandatoryFields,
		isDisabled,
	} = props;

	const [state, setState] = useState({
		discount: 0,
	});

	useEffect(() => {
		const ogPrice = Object.keys(variantDetails.groups.items).reduce(
			(acc, curr) => {
				const group = variantDetails.groups.items[curr];
				if (group.optional) {
					return acc;
				}
				return (
					acc +
					group.choices.reduce((groupOG, choice) => {
						if (choice.default) {
							return (
								groupOG +
								products[choice.productId].ProductVariant[
									choice.variantId
								].cost
							);
						}
						return groupOG;
					}, 0)
				);
			},
			0
		);

		const updatedDiscount =
			((ogPrice - variantDetails.price) / ogPrice) * 100;
		setState({
			...state,
			discount:
				Number.isNaN(updatedDiscount) ||
				!Number.isFinite(updatedDiscount)
					? 0
					: updatedDiscount.toFixed(2),
		});
	}, [variantDetails]);

	const handleInput = (e) => {
		props.handleInput(e);
	};

	return (
		<div className={`${styles.root}`}>
			<fieldset disabled={isDisabled} className='form-fieldset'>
				<div className={`${styles.details}`}>
					{/* delete variant button */}
					{!isDisabled && (
						<Tooltip title='Delete Variant' placement='left-end'>
							<Delete
								className={`${styles['delete-icon']}`}
								onClick={handleDelete}
								data-type='variant'
								data-id={activeVariant}
							/>
						</Tooltip>
					)}
					{/* variant name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='variantName'
							data-mandatory={mandatoryFields.variants[0].name}
						>
							Variant Name
						</label>
						<input
							className='form-value'
							type='text'
							id='variantName'
							name='name'
							onInput={handleInput}
							value={variantDetails.name}
							data-type='variant'
							maxLength={6}
							data-id={activeVariant}
							{...generateRequiredProps(
								mandatoryFields.variants[0].name
							)}
						/>
					</div>
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='clientVariantId'
							data-mandatory={mandatoryFields.variants[0]?.clientVariantId}
						>
							Client Variant Id
						</label>
						<input
							className='form-value'
							type='text'
							id='clientVariantId'
							name='clientVariantId'
							onInput={handleInput}
							value={variantDetails?.clientVariantId}
							data-type='variant'
							data-id={activeVariant}
							{...generateRequiredProps(
								mandatoryFields.variants[0]?.clientVariantId
							)}
						/>
					</div>
					{/* description */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='variantDescription'
							data-mandatory={
								mandatoryFields.variants[0].description
							}
						>
							Description
						</label>
						<textarea
							className='form-value'
							id='variantDescription'
							name='description'
							onInput={handleInput}
							value={variantDetails.description}
							data-type='variant'
							data-id={activeVariant}
							{...generateRequiredProps(
								mandatoryFields.variants[0].description
							)}
						/>
					</div>
					{/* serves */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='serves'
							data-mandatory={mandatoryFields.variants[0].serves}
						>
							Serves
						</label>
						<IconInput
							size='large'
							type='serves'
							name='serves'
							id='serves'
							value={variantDetails.serves}
							handleInput={handleInput}
							dataType='variant'
							dataId={activeVariant}
							dataAttrs={{
								required: mandatoryFields.variants[0].serves,
							}}
						/>
					</div>
				</div>
				<div className={`${styles.section}`}>
					<div className={`${styles['group-wrapper']}`}>
						<DraggableVContainers
							Component={ComboGroup}
							mandatoryFields={mandatoryFields}
							handleInput={handleInput}
							handleAddNew={handleAddNewGroup}
							handleDelete={handleDelete}
							addText='Selection'
							isDraggable={false}
							isDisabled={isDisabled}
							items={variantDetails.groups.items}
							order={variantDetails.groups.order}
							switchConfig={{
								checked: variantDetails.groups.order.reduce(
									(acc, curr) => {
										acc[curr] =
											variantDetails.groups.items[
												curr
											].optional;
										return acc;
									},
									{}
								),
								type: 'group',
								name: 'optional',
								label: 'Optional',
							}}
						/>
					</div>
					<div className={`${styles['pricing-wrapper']}`}>
						<div className={`${styles['pricing-root']}`}>
							<p className={`${styles['pricing-heading']}`}>
								Variant Pricing
							</p>
							<div className={`${styles['price-wrapper']} row`}>
								<label
									data-mandatory={
										mandatoryFields.variants[0].price
									}
								>
									Price
								</label>
								<IconInput
									size='large'
									type='rupee'
									name='price'
									id='price'
									value={variantDetails.price}
									handleInput={handleInput}
									dataType='variant'
									dataId={activeVariant}
									dataAttrs={{
										required:
											mandatoryFields.variants[0].price,
									}}
								/>
							</div>
							<p className={`${styles.savings}`}>
								Total discount availed:
								{` ${state.discount}%`}
							</p>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { products } = state.product;
	return {
		products,
	};
};

ComboVariant.defaultProps = {
	mandatoryFields: {},
};

export default connect(mapStateToProps)(ComboVariant);
