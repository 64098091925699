/* eslint-disable object-curly-newline */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ClickAwayListener, IconButton } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { classes } from '../../../../../Helpers';
import styles from './GenericExclusiveFilter.module.scss';

function GenericExclusiveFilter(props) {
	const [showList, setShowList] = useState(false);
	const [query, setQuery] = useState('');
	const {
		label,
		list,
		hideDone = false,
		increasedWidth = false,
		selectedList,
		handleClick,
		disabled,
		allowNoSelection,
	} = props;

	const handleSearch = (e) => {
		setQuery(e?.target?.value);
	};

	const checkSelected = useCallback(
		(id) => {
			if (Array.isArray(selectedList)) {
				return selectedList?.includes(id);
			}
			return selectedList === id;
		},
		[selectedList]
	);

	const handleSelection = (id, isSelected) => {
		if (Array.isArray(selectedList)) {
			if (isSelected) {
				handleClick(
					selectedList?.filter((ele) => {
						return ele !== id;
					})
				);
			} else {
				handleClick([id, ...selectedList]);
			}
		} else if (isSelected && allowNoSelection) {
			handleClick(null);
		} else {
			handleClick(id);
			if (hideDone) {
				setShowList(false);
			}
		}
	};

	const handleRemove = (id) => {
		if (Array.isArray(selectedList)) {
			handleClick(
				selectedList?.filter((ele) => {
					return ele !== id;
				})
			);
		} else if (allowNoSelection) {
			handleClick(null);
		}
	};

	const renderSelected = useCallback(() => {
		let selectedItem = selectedList ? [selectedList] : [];
		if (Array.isArray(selectedList)) {
			selectedItem = selectedList;
		}
		if (!selectedItem?.length > 0) return null;
		const finalAllowedLength = increasedWidth ? 40 : 15;
		return (
			<div
				className={styles.selected}
				style={{
					width: increasedWidth ? '100%' : '',
				}}
			>
				{selectedItem?.map((id) => {
					const item = list?.find((ele) => {
						return ele?.id === id;
					});
					return (
						<div key={item?.id} className={styles['selected-item']}>
							<p>
								{item?.title?.length > finalAllowedLength
									? item?.title?.substring(0, finalAllowedLength) + '...'
									: item?.title}
							</p>
							<IconButton
								className={styles.remove}
								onClick={() => {
									handleRemove(item?.id);
								}}
							>
								<Close />
							</IconButton>
						</div>
					);
				})}
			</div>
		);
	}, [selectedList, list]);

	const LIST = useMemo(() => {
		return list?.filter((ele) => {
			return ele?.title
				?.toLowerCase()
				?.includes(query?.toLowerCase());
		});
	}, [list, query]);

	return (
		<ClickAwayListener
			onClickAway={() => {
				setShowList(false);
			}}
		>
			<div className={styles.root}>
				<div
					className={classes(
						styles.label,
						showList ? styles['label-selected'] : '',
						disabled ? styles['label-disabled'] : ''
					)}
					style={{
						width: increasedWidth ? '400px' : '',
					}}
					onClick={() => {
						if (!disabled) {
							setShowList(!showList);
						}
					}}
				>
					<p>{label}</p>
					{showList ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</div>
				<div
					className={classes(
						styles.content,
						showList ? styles.show : styles.hide
					)}
				>
					<input
						className={styles.search}
						type='text'
						value={query}
						onChange={handleSearch}
						placeholder='Search'
					/>
					<div className={styles['list-container']}>
						<div className={styles['inner-container']}>
							{LIST?.map((ele) => {
								const isChecked = checkSelected(ele?.id);
								return (
									<div
										key={ele?.id}
										className={classes(
											styles.select,
											isChecked ? styles.checked : ''
										)}
										onClick={() => {
											handleSelection(ele?.id, isChecked);
										}}
									>
										<input
											type='checkbox'
											checked={isChecked}
										/>
										<p>{ele?.title}</p>
									</div>
								);
							})}
						</div>
					</div>
					{!hideDone && (
						<button
							type='button'
							className={styles.done}
							onClick={() => {
								setShowList(false);
							}}
						>
							Done
						</button>
					)}
				</div>
				{renderSelected()}
			</div>
		</ClickAwayListener>
	);
}

export default GenericExclusiveFilter;
