/* eslint-disable */
import { Badge, Button } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Tune } from '@mui/icons-material';
import { useLocation, useHistory } from 'react-router';
import analyticsAction from '../../Actions/analytics.actions';
import SearchBar from '../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import { usePagination, useQuery, useSearch } from '../../Helpers';
import styles from './ForecastingHistory.module.scss';
import Calender from '../../Components/Forms/Payment Settlements/Calender/Calender';
import { formActions, modalActions } from '../../Actions';
import FilterModal from '../../Components/Filter Modal/FilterModal';
import { BackdropLoader } from '../../Components/Loaders';
import aiAnalyticsActions from '../../Actions/aiAnalytics.actions';
import InvAnalysisListPage from './ForecastingAnalysisListPage';

const ANALYTICS_TYPE = analyticsTypeConstant.FORECASTING_HISTORY_LIST_DATA;

const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	PRODUCT_NAME: 'productName',
	ORDER_ID: 'OrderId',
	ITEM_ID: 'itemId',
	VALIDATED: 'validation',
	IDENTIFIED: 'identification',
	FEATURES: 'annotated',
	// BRAND_NAME: 'brandName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'PRODUCT_NAME',
			title: 'Product Name',
			get: 'ProductName',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const FILTERS = [
	'STORE_FILTER',
];

const ForecastingHistory = (props) => {
	const {
		dateRangeFilter,
		data,
		setDateRangeFilter,
		fetchData,
		setBomSearchResults,
		searchQuery,
		searchBy,
		// applyFilters,
		toggleModal,
		brandFilter,
		brandData,
		gateway,
	} = props;

	const { page, limit } = usePagination({
		customLimit: 10,
	});
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	// const history = useHistory();
	const params = new URLSearchParams(location.search);

	// console.log('1', data);
	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		if (from != null && to != null) {
			fetchData({
				params: {
					page,
					limit,
				},
			});
		}
	};

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
		if (brandData.length === 0) {
			gateway({
				entity: 'BRANDS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setBomSearchResults('', data, 'PRODUCT_NAME');
		}
	}, [queryParams]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const generateData = () => {
		const filteredData = data.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		setBomSearchResults(
			query,
			filteredData,
			SEARCH_BY?.options?.[searchField]?.key
		);
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	return (
		<>
			<BackdropLoader showProgress isLoading={loading} />
			<div className={styles.root}>
				{/* <ListModal
				handleConfirm={applyBrandFilter}
				selectionBehaviour='SHOW'
			/> */}
				<FilterModal
					type='quality'
					filters={FILTERS}
					clear={queryParams.has('clear')}
				/>
				{!params.has('s_no_filter') && (
					<div className={styles.header}>
						<SearchBar
							value={query}
							placeholder={PLACE_HOLDERS[searchField]}
							handleInput={handleSearch}
							handleChange={handleSearchBy}
							searchBy={
								SEARCH_BY != null
									? {
											...(SEARCH_BY ?? {}),
											current: searchField,
									  }
									: null
							}
							className={styles.search}
						/>
						<Badge
							className={styles['brand-filter']}
							badgeContent={
								brandFilter?.selectedList?.length ?? 0
							}
							color='primary'
						>
							<Button
								variant='outlined'
								onClick={() => {
									toggleModal(true, {
										heading: 'Filter',
										list: brandData.map((brand) => {
											return {
												id: brand.id,
												title: brand.name,
											};
										}),
										selected: brandFilter.selectedList,
									});
								}}
							>
								Filters
								<Tune fontSize='medium' />
							</Button>
						</Badge>
						<div>
							Total:
							{generateData().length}
						</div>
						<Calender
							value={dateRangeFilterArray}
							setValue={setDate}
						/>
					</div>
				)}

				<InvAnalysisListPage
					aiTrue={params.has('s_no_filter')}
					data={generateData()}
					ANALYTICS_TYPE={ANALYTICS_TYPE}
				/>
			</div>
		</>
	);
};

ForecastingHistory.defaultProps = {};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter, BRAND_FILTER: brandFilter } =
		state.analytics;
	const { LIST_DATA: data } = state.analytics.FORECASTING_ANALYTICS;
	// console.log('3', data);
	const brandData = state.form.BRANDS.data.LIST;
	const { query: searchQuery, searchBy } =
		state.analytics.FORECASTING_ANALYTICS.SEARCH_DATA;
	return {
		dateRangeFilter,
		searchQuery,
		data,
		searchBy,
		brandData,
		brandFilter,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setBomSearchResults: analyticsAction.setBomSearchResults,
	fetchData: analyticsAction.fetchData,
	fetchAiQcData: aiAnalyticsActions.fetchAiQcData,
	toggleModal: modalActions.toggleFilterModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForecastingHistory);
