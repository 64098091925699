// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import CustomerReports from '../../Pages/Reports/CustomerReports/CustomerReports';
// import { AddChild } from '../../Pages/Accounts/AddChild';

const ReportsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={path}>
				<CustomerReports />
			</Route>

			{/* <Route
				path={`${path}${getSubURL(urls.PAYMENT_SETTLEMENTS)}/view/:id`}
			>
				<TransactionViewPage />
			</Route> */}
		</Switch>
	);
};

export default ReportsRoutes;
