import { getDate, getWeekOfMonth } from 'date-fns';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { timeFilterMap } from '../Constants';

const yesterdayDate = new Date();
yesterdayDate.setDate(yesterdayDate.getDate() - 2);

const useTimeFilter = (props) => {
	const { DISCRETE_TIME_FILTERS, applyFilters } = props;

	const { DATE_FILTER: timeFilter } = useSelector((state) => {
		const { DATE_FILTER } = state.analytics;

		return {
			DATE_FILTER,
		};
	});

	const [timeFilters, setTimeFilters] = useState(() => {
		const newFilter = cloneDeep(timeFilter);
		return DISCRETE_TIME_FILTERS.map((tfilter) => {
			let active = false;
			const text = newFilter?.selection?.[tfilter.type] || tfilter.text;
			if (newFilter?.type === 'day' && tfilter?.type === 'week') {
				return {
					...tfilter,
					active: true,
					text,
				};
			}
			active = tfilter?.type === newFilter?.type;
			if (active) {
				return {
					active: newFilter?.active,
					text: newFilter?.text,
					type: newFilter?.type,
				};
			}
			return {
				...tfilter,
				active: false,
				text,
			};
		});
	});

	useEffect(() => {
		setTimeFilters(() => {
			const newFilter = cloneDeep(timeFilter);
			return DISCRETE_TIME_FILTERS.map((tfilter) => {
				let active = false;
				const text =
					newFilter?.selection?.[tfilter.type] || tfilter.text;
				if (newFilter?.type === 'day' && tfilter?.type === 'week') {
					return {
						...tfilter,
						active: true,
						text,
					};
				}
				active = tfilter?.type === newFilter?.type;
				if (active) {
					return {
						active: newFilter?.active,
						text: newFilter?.text,
						type: newFilter?.type,
					};
				}
				return {
					...tfilter,
					active: false,
					text,
				};
			});
		});
	}, [timeFilter]);

	/**
	 *
	 * @param {String} type
	 * @param {Object} selection
	 */
	const setTimeFilter = (
		type,
		selection = {
			year: yesterdayDate.getFullYear(),
			month: timeFilterMap.month[yesterdayDate.getUTCMonth()],
			day: getDate(yesterdayDate),
			hour: null,
			week: getWeekOfMonth(yesterdayDate),
		}
	) => {
		const clonedSelection = cloneDeep(selection);
		let fetchData = true;
		let selectedFilter = null;
		let overrideText = false;
		const newTimeFilters = timeFilters.map((tfilter, idx) => {
			const active = type === tfilter.type;
			let text =
				(!overrideText && selection[tfilter.type]) ||
				DISCRETE_TIME_FILTERS[idx].text;

			if (
				tfilter.type === 'month' &&
				typeof selection[tfilter.type] === 'number'
			) {
				const monthNumber = selection[tfilter.type] - 1;
				text = timeFilterMap.month[monthNumber];
			}

			clonedSelection[tfilter.type] = !overrideText
				? selection[tfilter.type]
				: null;

			const newFilter = {
				...tfilter,
				active,
				text,
			};

			if (active) {
				overrideText = true;
				selectedFilter = {
					...newFilter,
					selection: clonedSelection,
				};
			}
			return newFilter;
		});
		setTimeFilters(newTimeFilters);

		// type == hour
		// type == week
		if (!selectedFilter) {
			selectedFilter = {
				text: selection.day,
				type: 'day',
				active: true,
				selection: clonedSelection,
			};
			fetchData = false;
		}
		applyFilters(
			{
				DATE_FILTER: selectedFilter,
			},
			fetchData
		);
	};

	return {
		setTimeFilter,
		timeFilters,
	};
};

useTimeFilter.defaultProps = {
	timeFilter: {},
	applyFilters: () => {},
};

export default useTimeFilter;
