/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../Shared';

const retagServices = {
	fetchRetagReasons,
	submitRetag,
	updateQCScan,
};

function fetchRetagReasons(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(endpoints.ANNOTATION_LABELS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};

	// return mockFetchRetagReasons(url, requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

// function mockFetchRetagReasons() {
// 	const mockResponse = {
// 		message: 'Loaded successfully',
// 		status: 'Success',
// 		statusCode: 200,
// 		data: [
// 			{
// 				id: 1,
// 				label: 'Wrong Product',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 			{
// 				id: 2,
// 				label: 'Wrong Shape',
// 			},
// 		],
// 	};
// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 2000);
// 	});
// }

function submitRetag(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.QC_ANNOTATIONS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function updateQCScan(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.UPDATE_QC, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default retagServices;
