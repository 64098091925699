import styles from './TransactionHistoryTable.module.scss';

function TransactionHistoryTable(props) {
	const { transactionHistory } = props;
	return (
		<div className={styles.root}>
			<table>
				<thead>
					<tr>
						<th>Txn Date</th>
						<th>Order Id</th>
						<th>Other details</th>
						<th>Meal details</th>
						<th>Txn status</th>
						<th>Txn type</th>
						<th>Amount</th>
						<th>Closing Balance</th>
					</tr>
				</thead>
				<tbody>
					{transactionHistory?.map((txn = {}, idx) => {
						const txnDate =
							txn?.createdAt?.split('T')[0] +
							txn?.createdAt?.split('T')[1];
						return (
							<tr className={idx % 2 === 0 && styles.colored}>
								<td>{txnDate}</td>
								<td>{txn?.orderId}</td>
								<td>{txn?.otherDetails}</td>
								<td>{txn?.mealDetails}</td>
								<td>{txn?.status}</td>
								<td>{txn?.txnType}</td>
								<td>{txn?.amount}</td>
								<td>{txn?.postTxnBalance}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default TransactionHistoryTable;
