import {
	Button,
	Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField
} from '@mui/material';
import { isValid } from 'date-fns';
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { analyticsAction } from '../../../Actions';
import { inputHelper } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import { UYDateRange } from '../../Forms/Payment Settlements/Calender/UYDateRange';
import styles from './AIModelHistoryHeader.module.css';

const modelTypes = [
	{
		type: 'FACE',
		label: 'FACE',
	},
	{
		type: 'FOOD-FEATURE',
		label: 'FOOD FEATURE',
	},
	{
		type: 'FOOD-IDENTIFICATION',
		label: 'FOOD IDENTIFICATION',
	},
];

function AIModelHistoryHeader(props) {
	const { fetchHistoryData, stores } = props;
	const [model, setModel] = useState('');
	const [selectedStores, setSelectedStores] = useState([]);
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
		active: false,
	});

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		if (from != null && to != null && isValid(from) && isValid(to)) {
			setDateRange({
				from,
				to,
				active: true,
			});
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Date',
			});
		}
	};

	const handleSelection = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		if (fieldName === 'model') {
			setModel(fieldValue);
			// fetchHistoryData([from, to], {
			// [fieldName]: fieldValue,
			// store: selectedStores,
			// });
		}
		if (fieldName === 'store') {
			setSelectedStores(fieldValue);
			// fetchHistoryData([from, to], {
			// [fieldName]: fieldValue,
			// model,
			// });
		}
	};

	const handleSubmit = () => {
		fetchHistoryData([dateRange?.from, dateRange?.to], {
			model,
			store: selectedStores,
		});
	};

	return (
		<div className={styles.root}>
			<div className={styles['header-text']}>
				<h1>AI Model History</h1>
				<TextField
					id='storeId'
					value={model}
					select
					label='Select Model type'
					onChange={handleSelection}
					name='model'
					className={styles.select}
				>
					{modelTypes?.map((type) => {
						return (
							<MenuItem value={type.type}>{type.label}</MenuItem>
						);
					})}
				</TextField>

				<InputLabel id='mutiple-select-label'>Select Stores</InputLabel>
				<Select
					labelId='mutiple-select-label'
					multiple
					name='store'
					value={selectedStores}
					onChange={handleSelection}
					className={styles.select}
					renderValue={(selected) => {
						// eslint-disable-next-line consistent-return
						const schoolName = stores
							?.filter((ele) => {
								return selected?.includes(ele?.id);
							})
							.map((store) => {
								return store?.name;
							});

						return schoolName.join(', ');
					}}
				>
					{stores?.map((store) => {
						return (
							<MenuItem value={store?.id}>
								<ListItemIcon>
									<Checkbox
										checked={
											selectedStores?.indexOf(store?.id) >
											-1
										}
									/>
								</ListItemIcon>
								<ListItemText primary={store?.name} />
							</MenuItem>
						);
					})}
				</Select>
			</div>
			<UYDateRange
				// disabled={!dateRange.active}
				dateRanges={dateRangeFilterArray}
				onDateRangeChange={setDate}
			/>
			<Button variant='contained' type='button' onClick={handleSubmit}>Find</Button>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter } =
		state.analytics;
	const stores = state?.form?.STORES?.data?.LIST;

	return {
		dateRangeFilter,
		stores,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AIModelHistoryHeader);
