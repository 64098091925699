import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const defectServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewDefect({
				req,
			});
		case 'LIST':
			return listDefects({
				req,
			});
		case 'CREATE':
			return createDefect({
				req,
			});
		case 'UPDATE':
			return updateDefect({
				req,
			});
		case 'DELETE':
			return deleteDefect({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createDefect({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ANNOTATION_LABELS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockSaveDefect(url, requestOptions, handleResponse);
}

// function mockSaveDefect() {
// const mockResponse = {
// message: 'Created successfully',
// status: 'Success',
// statusCode: 200,
// };
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function updateDefect({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ANNOTATION_LABELS, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockUpdateDefect(url, requestOptions);
}

// function mockUpdateDefect() {
// const mockResponse = {
// message: 'Updated successfully',
// status: 'Success',
// statusCode: 200,
// };
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function viewDefect({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ANNOTATION_LABELS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetchDefect(url, requestOptions);
}

// function mockFetchDefect() {
// const mockResponse = {
// message: 'Loaded successfully',
// status: 'Success',
// statusCode: 200,
// data: {
// id: 1,
// label: 'Defect 1',
// brand: 88,
// ims: true,
// qms: false,
// products: [
// {
// id: 222,
// productId: 467,
// },
// {
// id: 221,
// productId: 468,
// },
// ],
// },
// };
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function listDefects({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ANNOTATION_LABELS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetchDefects(url, requestOptions);
}

// function mockFetchDefects() {
// const mockResponse = {
// message: 'Loaded successfully',
// status: 'Success',
// statusCode: 200,
// data: [
// {
// id: 1,
// name: 'Defect 1',
// type: ['INVENTORY', 'QUALITY'],
// brand: 2,
// products: [],
// },
// {
// id: 2,
// name: 'Feature 2',
// type: ['QUALITY'],
// brand: 2,
// products: [],
// },
// ],
// };
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function deleteDefect({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ANNOTATION_LABELS, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockDeleteDefect(url, requestOptions);
}

// function mockDeleteDefect() {
// const mockResponse = {
// message: 'Deleted successfully',
// status: 'Success',
// statusCode: 200,
// };
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

export default defectServices;
