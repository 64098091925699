import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { inputHelper } from '../../../../Helpers';
import styles from './applicability.module.scss';
import Tag from '../../../Tag/Tag';
import NonApplicableProductsModal from '../../../Modals/ApplicabilityModal/NonApplicableProductsModal';

const NonApplicableProducts = (props) => {
	const {
		fields,
		isDisabled,
		toggleNonApplicableProductsModal,
		handleConfirm,
	} = props;
	const handleClick = () => {
		toggleNonApplicableProductsModal(true);
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		props.handleDelete(dataset.id, 'nonapplicableProducts', 'id');
	};
	const generateTags = (allTags, type) => {
		return allTags.map((tag) => {
			return (
				<Tag
					className={`${styles.tag}`}
					handleDelete={handleDelete}
					text={tag.productName}
					isDisabled={isDisabled}
					dataAttrs={{
						'data-type': type,
						'data-id': tag.id,
						'data-name': 'nonapplicableProducts',
					}}
				/>
			);
		});
	};
	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}>
			<div className='form-field row'>
				<label className='form-label'>
					Non Applicable Product
				</label>
				<div className={`${styles['tag-wrapper']}`}>
					{fields.nonapplicableProducts &&
						generateTags(
							fields.nonapplicableProducts,
							'nonapplicableProducts'
						)}
					{!isDisabled && (
						<div className={`${styles.add}`} onClick={handleClick}>
							<AddOutlinedIcon
								fontSize='medium'
								color='primary'
								dataAttrs={{
									'data-type': 'nonapplicableProducts',
									'data-id': NonApplicableProducts,
									'data-name': 'nonapplicableProducts',
								}}
							/>
						</div>
					)}
				</div>

				<NonApplicableProductsModal
					handleConfirm={handleConfirm}
					selectedProdList={[
						...fields.applicableProducts,
						...fields.nonapplicableProducts,
					]}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { data, show } = state.modal.nonapplicable_products;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleNonApplicableProductsModal:
		modalActions.toggleNonApplicableProductsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NonApplicableProducts);
