import { cloneDeep, isEmpty } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useMemo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { BoxAnnotator } from '../../../Components/Annotation/BoxAnnotator';
import config from './config';
import { annotationServices, notificationServices } from '../../../Services';
import annotationActions from '../../../Actions/annotation.actions';
// eslint-disable-next-line object-curly-newline
import {
	history,
	inputHelper,
	keyPressHandler,
	useClientRect,
	useImageDimensionsRef,
	useQuery,
} from '../../../Helpers';
import { RouterPromptModal } from '../../../Components/Modals';
import { Header } from '../../../Components/Annotation/AnnotatorHeader';
import { liveQCServices } from '../../../Services/LiveQC';
import retagActions from '../../../Actions/retag.actions';
import { urls } from '../../../Constants';
import analyticsAction from '../../../Actions/analytics.actions';
import useImageDimensionState from '../../../Helpers/useImageDimensionState';
import useKeyPress from '../../../Helpers/useKeyPress';
import { modalActions } from '../../../Actions';

const initialState = {
	qcData: {
		BrandImage: '-',
		FoodImage: '-',
		IsTempInRange: true,
		IsWeightInRange: true,
		MaxTemp: 0,
		MaxWeight: 0,
		MinTemp: 0,
		MinWeight: 0,
		OrderId: '-',
		ProductImage: '-',
		ProductName: '-',
		QCTime: '01/01/12 00:00:00',
		Result: 0,
		Temp: 0,
		TempCheckRequired: false,
		Weight: 0,
		WeightCheckRequired: true,
		foodLabel: null,
		isTempMax: null,
		isVeg: 0,
		itemId: '-',
		otherTemp: null,
		brandId: null,
		productId: null,
		id: null,
		validation: null,
		identification: null,
		type: '-',
		container: {},
	},
};

const QMSAnnotationPage = (props) => {
	const {
		listData,
		fetchQMSAnnotations,
		qcAnnotations,
		products,
		containers,
		fetchProducts,
		fetchContainers,
		defects,
		fetchDefects,
		userPermissions,
		updateQCList,
		toggleContainerModal,
		// removeFromQCList,
	} = props;

	const { index } = useParams();
	const { ANNOTATE = false } = userPermissions;

	const query = useQuery();
	const qcId = query.get('id');
	const mode = query.get('ctx');
	const type = query.get('type');
	const isCms = query.get('CMS') === 'true';
	const recipeId = query.get('recipeId');

	const listIndex = useMemo(() => {
		return listData?.findIndex((qc) => {
			if (qcId != null) {
				return qc.itemId === index && qc.id === parseInt(qcId, 10);
			}
			return qc.itemId === index;
		});
	}, [index, qcId]);

	const [imageContainerRect, imageContainerRef] = useClientRect();
	const imageDimensions = useImageDimensionState(qcAnnotations?.image);

	const [isBlockingNavigation, setIsBlockingNavigation] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const [qcData, setQCData] = useState(initialState);

	const { brandId, productId } = qcData;

	const [annotations, setAnnotations] = useState([]);
	const [newAnnotation, setNewAnnotation] = useState([]);

	const qcImageDimension = useImageDimensionsRef(qcAnnotations?.image);

	const keyPressed = useKeyPress();

	const handleBack = useCallback(() => {
		// eslint-disable-next-line max-len
		// history.push(`${urls.EXTENSION}${urls.PAST_QC}/${index}?id=${qcId}${isCms ? '&type=CMS' : ''}`);
		history.goBack();
	}, [qcId]);

	const navigateQC = useCallback(
		(e) => {
			const { dataset } = inputHelper(e);

			let newListItem = null;

			if (dataset.type === 'next') {
				newListItem = listData?.[listIndex + 1];
			} else {
				newListItem = listData?.[listIndex - 1];
			}

			history.replace(
				`${urls.EXTENSION}${urls.QMS_ANNOTATION}/${newListItem?.itemId}?id=${newListItem?.id}&ctx=${mode}&type=${type}`
			);
		},
		[listIndex]
	);

	useEffect(() => {
		if (mode === 'IDENTIFICATION') {
			if (isEmpty(products)) {
				fetchProducts();
			}
		} else if (mode === 'CONTAINER') {
			if (isEmpty(containers)) {
				const req = {
					data: {
						mode: qcData?.type,
					},
				};
				fetchContainers(req);
			}
		} else if (mode === 'FEATURES') {
			if (brandId != null && productId != null) {
				fetchDefects({
					params: {
						brandId,
						productId,
						qcId,
						type: type ?? '',
					},
				});
			}
		}
	}, [mode, brandId, productId, qcId]);

	useEffect(() => {
		const data = listData?.[listIndex] ?? null;
		if (
			(keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight') &&
			data !== null
		) {
			const newListItem = keyPressHandler(
				keyPressed,
				listData,
				listIndex
			);
			if (newListItem) {
				history.replace(
					`${urls.EXTENSION}${urls.QMS_ANNOTATION}/${newListItem?.itemId}?id=${newListItem?.id}&ctx=${mode}&type=${type}`
				);
			}
		}
	}, [keyPressed]);

	const labels = useMemo(() => {
		if (mode === 'IDENTIFICATION') {
			return Object.keys(products ?? {}).map((pId, idx) => {
				const productName = products?.[pId];
				return {
					ID: pId,
					id: idx,
					title: productName,
				};
			});
		}
		if (mode === 'FEATURES') {
			return defects?.map((defect, idx) => {
				return {
					ID: defect.id,
					id: idx,
					title: defect.label,
				};
			});
		}
		if (mode === 'CONTAINER') {
			return containers?.map((container, idx) => {
				return {
					ID: container.id,
					id: idx,
					title: container.name,
				};
			});
		}
		return [];
	}, [mode, products, defects, containers]);

	useEffect(() => {
		if (labels.length > 0) {
			let currentAnnotations = [];
			if (mode === 'IDENTIFICATION') {
				currentAnnotations = qcAnnotations?.identification;
			} else if (mode === 'CONTAINER') {
				currentAnnotations = qcAnnotations?.container;
			} else if (mode === 'FEATURES') {
				currentAnnotations = qcAnnotations?.features;
			}
			setAnnotations(
				config.serializer({
					annotations: currentAnnotations ?? [],
					imageDimensions,
					imageContainerRect: imageContainerRect(),
					labels,
				})
			);
		}
	}, [index, qcAnnotations, imageDimensions, labels]);

	const getPastQCDetails = useCallback(async () => {
		try {
			const req = {
				params: {
					itemId: isCms ? '' : index,
					qcId: isCms ? recipeId : qcId,
				},
			};
			const response = await liveQCServices.getPastData(req);
			return response?.data ?? {};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			return {};
		}
	}, [index, qcId]);

	useEffect(() => {
		(async () => {
			let currentQC = listData?.[listIndex] ?? null;

			if (currentQC == null) {
				const data = await getPastQCDetails();
				currentQC = config.stateSerializer(data);
			}
			setQCData(config.stateSerializer(currentQC));
			fetchQMSAnnotations(currentQC.id, type);
			if (currentQC.validation) {
				setIsDisabled(false);
			} else {
				notificationServices.generateNotification({
					type: 'warning',
					message: 'The item needs to be validated first.',
				});
				setIsDisabled(true);
			}
		})();
	}, [index, qcId]);

	const handleSubmit = async (
		finalAnnotations,
		imageDimensionsOg,
		imageDimensionsNew
	) => {
		const isValid = config.validator(finalAnnotations);

		if (isValid) {
			const req = config.deserializer({
				annotations,
				imageDimensionsOg,
				imageDimensionsNew,
				mode,
				qcId: isCms ? recipeId : qcId,
				type,
			});

			try {
				const response = await annotationServices.submitAnnotations(
					req
				);
				if (!isCms) {
					const clonedItem = cloneDeep(listData?.[listIndex]);
					if (mode === 'FEATURES') {
						if (type === 'QUALITY') {
							clonedItem.features = req.data.features?.length;
						} else if (type && type?.includes('IDENTITY')) {
							clonedItem.identity = req.data.identity?.length;
						}
						clonedItem.annotated = req.data.features?.length;
					} else if (mode === 'IDENTIFICATION') {
						clonedItem.identification = req.data.identification;
					} else if (mode === 'CONTAINER') {
						clonedItem.container = req.data.container;
					}
					updateQCList(listIndex, clonedItem);
				}
				const { message } = response;
				notificationServices.generateNotification({
					type: 'success',
					message,
				});
				setIsBlockingNavigation(false);
				return true;
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					error,
				});
				return false;
			}
		}
		return false;
	};

	const saveAndRedirect = async () => {
		const canRedirect = await handleSubmit(
			annotations,
			qcImageDimension.current,
			imageContainerRect()
		);
		return canRedirect;
	};

	const cancelAndRedirect = () => {
		setIsBlockingNavigation(false);
		return true;
	};

	const updateAnnotations = (newState) => {
		setAnnotations(newState);
		setIsBlockingNavigation(newState.length > 0);
	};

	const handleCustomLabel = (annotationKey, labelId) => {
		toggleContainerModal(true, {
			selectedContainer: labelId ?? qcData?.container?.id,
			annotationKey,
		});
	};

	if (ANNOTATE === false) {
		return (
			<Redirect
				to={`${urls.EXTENSION}${urls.PAST_QC}/${index}?id=${qcId}`}
			/>
		);
	}

	return (
		<div>
			<Header
				listData={listData}
				listIndex={listIndex}
				isLiveQC={false}
				fields={{
					center: {
						label: 'Order Id / Item Id',
						value: `${qcData.OrderId} / ${qcData.itemId}`,
					},
					right: {
						label: 'QC Time',
						value: qcData.QCTime,
					},
				}}
				handleBack={handleBack}
				navigate={navigateQC}
			/>
			<BoxAnnotator
				disabled={isDisabled}
				image={qcAnnotations?.image}
				identifiedBy={qcAnnotations?.identifiedBy}
				handleSubmit={handleSubmit}
				customLabelSelection={
					mode === 'CONTAINER' ? handleCustomLabel : null
				}
				imageDimensionsOg={qcImageDimension.current}
				imageContainerRect={imageContainerRect()}
				imageContainerRef={imageContainerRef}
				annotations={annotations}
				setAnnotations={updateAnnotations}
				newAnnotation={newAnnotation}
				setNewAnnotation={setNewAnnotation}
				brandId={qcData.brandId}
				labels={labels}
				type={type}
				limit={
					mode === 'IDENTIFICATION' || mode === 'CONTAINER' ? 1 : null
				}
				heading={
					mode === 'IDENTIFICATION'
						? 'Product Tagging'
						: 'Item Tagging'
				}
				subHeading={
					mode === 'IDENTIFICATION' || mode === 'CONTAINER'
						? ''
						: qcData.identification?.[0]?.label &&
						`(${qcData.identification?.[0]?.label})`
				}
				modalHeading={
					mode === 'CONTAINER'
						? `Select container for ${qcData.identification?.[0]?.label}`
						: `Select feature for ${qcData.identification?.[0]?.label}`
				}
				allowEdit={mode === 'FEATURES' || mode === 'CONTAINER'}
				allowDelete={mode === 'FEATURES' || mode === 'CONTAINER'}
			/>
			<RouterPromptModal
				when={isBlockingNavigation}
				title='Save before leaving?'
				okText='Save'
				cancelText="Don't Save"
				message='You have unsaved changes, are you sure you want to leave?'
				onOK={saveAndRedirect}
				onCancel={cancelAndRedirect}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { list: listData = [] } =
		state.analytics.QUALITY_ANALYTICS.SEARCH_DATA;
	const { qcAnnotations, products, containers } = state.annotation;
	const { retagReasons: defects = [] } = state.retag;
	const { userPermissions } = state.navigation;
	return {
		listData,
		qcAnnotations,
		defects,
		products,
		containers,
		userPermissions,
	};
};

const mapDispatchToProps = {
	fetchQMSAnnotations: annotationActions.fetchQMSAnnotations,
	fetchProducts: annotationActions.fetchProducts,
	fetchContainers: annotationActions.fetchContainers,
	fetchDefects: retagActions.fetchRetagReasons,
	updateQCList: analyticsAction.updateQCList,
	removeFromQCList: analyticsAction.removeFromQCList,
	toggleContainerModal: modalActions?.toggleContainerModal,
};

QMSAnnotationPage.defaultProps = {
	mode: 'IDENTIFICATION',
};

export default connect(mapStateToProps, mapDispatchToProps)(QMSAnnotationPage);
