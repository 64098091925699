import { Switch } from '@mui/material';
import { IconInput } from '../Inputs';

function ProductCookingConfig(props) {
	const { handleInput, fields, isDisable } = props;
	return (
		<div>
			<div className='form-field row'>
				<div
					className='row'
					style={{
						gap: '4rem',
					}}
				>
					<label
						htmlFor='isCMSEnabled'
						style={{
							color: 'rgba(95, 95, 95, 0.75)',
						}}
					>
						Recipe Enabled
					</label>
					<Switch
						size='small'
						name='isCMSEnabled'
						checked={fields?.isCMSEnabled}
						onChange={handleInput}
						id='isCMSEnabled'
						color='primary'
						disable={isDisable}
					/>
				</div>
			</div>
			<div className='form-field row'>
				<div
					className='row'
					style={{
						gap: '4rem',
					}}
				>
					<label
						htmlFor='isEnabled'
						style={{
							color: 'rgba(95, 95, 95, 0.75)',
						}}
					>
						Cooking Required
					</label>
					<Switch
						size='small'
						name='isEnabled'
						checked={fields?.isEnabled}
						onChange={handleInput}
						id='isEnabled'
						color='primary'
						disable={isDisable}
					/>
				</div>
			</div>
			<div
				className='form-field row'
				style={{
					gap: '10rem',
				}}
			>
				<label
					htmlFor='time'
					style={{
						color: 'rgba(95, 95, 95, 0.75)',
					}}
				>
					Time
				</label>
				<IconInput
					size='medium'
					type='custom'
					iconText='min'
					name='timeReq'
					id='timeReq'
					handleInput={handleInput}
					value={fields?.timeReq}
					dataAttrs={{
						required: true,
						min: 1,
					}}
					isDisable={isDisable}
				/>
			</div>
		</div>

	);
}
export default ProductCookingConfig;
