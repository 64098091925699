/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@mui/material';
import {
	useEffect, useReducer, useState
} from 'react';
import { InputGroup, SelectPicker } from 'rsuite';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { CreatePageHeader } from '@/Components/Headers';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import config from './gstDetailsConfig';
import { inputHelper } from '@/Helpers';
import { notificationServices } from '@/Services';
import gstConfigServices from '@/Services/gst.services';
import { BackdropLoader } from '@/Components/Loaders';
import stateCityActions from '@/Actions/stateCity.actions';

const LabelledInput = (props) => {
	const {
		value, onChange, id, name, placeholder, labelText, mandatory, type = 'text', disabled,
	} = props;
	return (
		<div className='flex items-center gap-2'>
			<Label
				className={`${mandatory ? 'text-red-600' : ''} text-left min-w-[150px]`}
				htmlFor={id}
			>
				{mandatory ? `${labelText}*` : labelText}
			</Label>
			<Input
				autoComplete='off'
				disabled={disabled}
				id={id}
				type={type}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
			/>
			{/* <TextField
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                sx={{
                    '.MuiInputBase-root': {
                        borderRadius: '12px',
                    },
                    '.MuiInputBase-input': {
                        paddingTop: '6px',
                        paddingBottom: '6px',
                    },
                }}
            /> */}
		</div>
	);
};
function GstDetails(props) {
	const { stateCity, query } = props;
	const history = useHistory();
	const params = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [visible, setVisible] = useState(false);
	const [data, dispatch] = useReducer(config?.reducer, config?.initialState);
	const {
		userName,
		legalName,
		password,
		gstNumber,
		email,
		phoneNumber,
		billingAddress,
		city,
		state,
		pincode,
		billingAddress2,
	} = data;
	const handleChange = () => {
		setVisible((prevVisible) => !prevVisible);
	};
	const handleSubmit = async () => {
		const valid = config?.validator(data);
		if (valid) {
			setIsLoading(true);
			try {
				const deserlizerData = config?.deserilizer(data);
				const req = {
					data: {
						id: params.id || '',
						...deserlizerData,
					},
				};
				const response = await gstConfigServices?.createGst(req);
				if (response?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
					history.goBack();
					setIsLoading(false);
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message: response?.message,
					});
				}
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
			} finally {
				setIsLoading(false);
			}
		}
	};
	const renderBtns = () => {
		return (
			<Button
				variant='contained'
				color='primary'
				type='button'
				onClick={handleSubmit}
			>
				Save
			</Button>
		);
	};

	useEffect(() => {
		props.fetchStateCity({
			id: '',
			allData: true,
		});
	}, []);
	const handleBackClick = () => {
		history.goBack();
	};
	const handleInput = (e) => {
	// /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
		const { fieldName, fieldValue } = inputHelper(e);
		if (fieldName === 'legalName') {
			if (fieldValue?.length > 30) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Legal Name length cant be Greater than 30',
				});
				return;
			}
		}
		if (fieldName === 'pincode') {
			if (fieldValue.length > 6) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Pincode length cant be Greater than 6',
				});
				return;
			}
		}
		if (fieldName === 'phoneNumber') {
			if (fieldValue.length > 10) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Phone Number length cant  be Greater than 10',
				});
				return;
			}
		}
		if (fieldName === 'city') {
			if (fieldValue.length > 30) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'City length cant be Greater than 10',
				});
				return;
			}
		}
		if (fieldName === 'state') {
			if (fieldValue.length > 40) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'State length cant be Greater than 40',
				});
				return;
			}
		}
		if (fieldName === 'email') {
			if (fieldValue.length > 60) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Email Length Cannot be Greater than 60',
				});
				return;
			}
		}
		if (fieldName === 'billingAddress1' || fieldName === 'billingAddress2') {
			if (fieldValue.length > 100) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Address Length Cant be Greater than 100',
				});
				return;
			}
		}
		if (fieldName === 'gstNumber') {
			if (fieldValue.length === 15) {
				const reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[1-9A-Z]{1}$/;
				if (!reg.test(fieldValue)) {
					notificationServices?.generateNotification({
						type: 'error',
						message: 'Invalid GST number format.',
					});
					return;
				}
			}
		}
		if (fieldName === 'gstNumber') {
			if (fieldValue.length > 15) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Gst Length Cant be Greater than 15.',
				});
				return;
			}
		}

		dispatch({
			type: 'SET_VALUE',
			payload: {
				[fieldName]: fieldValue,
			},
		});
	};
	const fetchData = async () => {
		try {
			const res = await gstConfigServices?.getGst({
				data: {
					id: params?.id,
				},
			});
			if (res?.statusCode === 200) {
				const gst = res?.data;
				gst.forEach((item) => {
					dispatch({
						type: 'SET_VALUE',
						payload: {
							userName: item?.userName,
							legalName: item?.legalName,
							password: item?.password,
							gstNumber: item?.gstNumber,
							email: item?.email,
							phoneNumber: item?.phoneNumber,
							billingAddress: item?.billingAddress,
							city: {
								id: item?.cityId,
							},
							state: item?.state,
							pincode: item?.pincode,
							billingAddress2: item?.billingAddress2,
						},
					});
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};
	useEffect(() => {
		if (params?.id) {
			setIsEdit(true);
			fetchData();
		}
	}, [params?.id]);

	useEffect(() => {
		if (city?.id) {
		  const findState = stateCity?.find((item) => item?.cityId === city?.id)?.stateName;
		  dispatch({
				type: 'SET_VALUE',
				payload: {
			  state: findState,
				},
		  });
		}
	  }, [city, stateCity]);

	return (
		<div>
			<BackdropLoader showProgress isLoading={isLoading} />
			<CreatePageHeader
				handleSubmit={handleSubmit}
				showCancel={false}
				heading={`${isEdit ? 'Edit' : 'Create'} Gst Deatils`}
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className='w-[70%] p-5 flex flex-col gap-3'>
				<LabelledInput
					id='userName'
					name='userName'
					value={userName}
					onChange={handleInput}
					placeholder='Enter UserName'
					labelText='User Name'
				/>
				<LabelledInput
					mandatory
					id='legalName'
					name='legalName'
					value={legalName}
					onChange={handleInput}
					placeholder='Enter Legal Name'
					labelText='Legal Name'
				/>
				<div className='flex items-center gap-2'>
					<Label
						className='text-red-600 text-left min-w-[150px]'
						htmlFor='password'
					>
						Password
					</Label>
					<InputGroup inside>
						<Input type={visible ? 'text' : 'password'} id='password' name='password' value={password} onChange={handleInput} />
						<InputGroup.Button onClick={handleChange}>
							{visible ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
						</InputGroup.Button>
					</InputGroup>
				</div>
				<LabelledInput
					mandatory
					id='gstNumber'
					name='gstNumber'
					value={gstNumber}
					onChange={handleInput}
					placeholder='Enter GST Number'
					labelText='GST Number'
				/>
				<LabelledInput
					id='email'
					type='email'
					name='email'
					value={email}
					onChange={handleInput}
					placeholder='Enter email'
					labelText='Email'
				/>
				<LabelledInput
					id='phone'
					type='number'
					name='phoneNumber'
					value={phoneNumber}
					onChange={handleInput}
					placeholder='Enter Phone Number'
					labelText='Phone Number'
				/>
				<LabelledInput
					mandatory
					id='address1'
					type='text'
					name='billingAddress'
					value={billingAddress}
					onChange={handleInput}
					placeholder='Enter Address  Line '
					labelText='Billing Address 1'
				/>
				<LabelledInput
					mandatory
					id='address2'
					type='text'
					name='billingAddress2'
					value={billingAddress2}
					onChange={handleInput}
					placeholder='Enter Address  Line '
					labelText='Billing Address 2'
				/>
				<div className='flex items-center gap-2'>
					<Label
						className='text-red-600 text-left min-w-[150px]'
						htmlFor='city'
					>
						City*
					</Label>
					<SelectPicker
						className='w-[100%]'
						data={stateCity.map(({ cityName, cityId }) => ({
							label: cityName,
							value: cityId,
						}))}
						name='city'
						onChange={(value) => {
							const selectedCity = stateCity.find((city) => city.cityId === value);
							if (selectedCity) {
							  dispatch({
									type: 'SET_VALUE',
									payload: {
								  		city: {
											id: selectedCity.cityId,
											name: selectedCity.cityName,
								  		},
									},
							  });
							}
						  }}
						value={city?.id}
					/>
				</div>
				<LabelledInput
					mandatory
					disabled='true'
					id='state'
					type='state'
					name='state'
					value={state}
					onChange={handleInput}
					placeholder='State'
					labelText='State'
				/>
				<LabelledInput
					mandatory
					id='pincode'
					type='number'
					value={pincode}
					onChange={handleInput}
					name='pincode'
					placeholder='Enter Pincode'
					labelText='Pincode'
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { stateCity } = state.stateCity;
	return {
		stateCity,
	};
};

const mapDispatchToProps = {
	fetchStateCity: stateCityActions.fetchStateCity,
};
export default connect(mapStateToProps, mapDispatchToProps)(GstDetails);
