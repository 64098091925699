/* eslint-disable no-param-reassign */
import _, { cloneDeep } from 'lodash';
import { imageToBase64 } from '../../../Helpers';
import { notificationServices } from '../../../Services';

const combosConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			brand: null,
			category: null,
			tax: 0,
			veg: true,
			customization: false,
			image: null,
			variants: {
				items: {
					1: {
						name: 'Unnamed Variant',
						clientVariantId: '',
						description: '',
						price: 1,
						serves: 1,
						groups: {
							items: {
								1: {
									name: 'Unnamed Selection',
									optional: false,
									choices: [],
								},
							},
							order: [1],
						},
					},
				},
				order: [1],
			},
		},
		errors: {
			name: '',
			description: '',
			brand: '',
			category: '',
			tax: '',
			veg: '',
			customization: '',
			serves: '',
			image: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: false,
		brand: true,
		category: true,
		tax: true,
		veg: true,
		customization: false,
		serves: true,
		image: true,
		variants: [
			{
				name: true,
				clientVariantId: false,
				description: false,
				price: true,
				serves: true,
				groups: [
					{
						name: true,
						choices: [
							{
								price: true,
								additionalPrice: true,
							},
						],
					},
				],
			},
		],
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.productName,
				description: data.productDescription,
				brand: data.brandId,
				category: data.categoryId,
				tax: data.tax,
				veg: data.isVeg,
				customization: data.ProductVariant[0].customizationEnabled,
				image: data.productImageUrl,
				variants: {
					items: data.ProductVariant.reduce(
						(accVariant, currVariant, idxVariant) => {
							accVariant[idxVariant + 1] = {
								name: currVariant.displayName,
								id: currVariant.id,
								clientVariantId:
									currVariant?.clientVariantId ?? '',
								description: currVariant.description,
								price: currVariant.cost,
								serves: currVariant.serves,
								groups: {
									items: currVariant.ComboGroup.reduce(
										(accGroup, currGroup, idxGroup) => {
											accGroup[idxGroup + 1] = {
												name: currGroup.name,
												id: currGroup.id,
												optional: currGroup.optional,
												choices:
													currGroup.comboGroupItem.map(
														(choice) => {
															return {
																productId:
																	choice.product,
																variantId:
																	choice.variant,
																id: choice.id,
																default:
																	choice.default,
																additionalPrice:
																	choice.price ||
																	0,
																price:
																	choice.price ||
																	0,
															};
														}
													),
											};
											return accGroup;
										},
										{}
									),
									order: currVariant.ComboGroup.map(
										(group, idx) => {
											return idx + 1;
										}
									),
								},
							};
							return accVariant;
						},
						{}
					),
					order: data.ProductVariant.map((variant, idx) => {
						return idx + 1;
					}),
				},
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const req = {};
		req.data = _.update(clonedState.fields, 'variants', (variants) => {
			return variants.order.map((variantId) => {
				const variant = variants.items[variantId];
				variant.groups = variant.groups.order.map((groupId) => {
					const group = variant.groups.items[groupId];
					group.choices = group.choices.map((choice) => {
						const newChoice = choice;
						delete newChoice.additionalPrice;
						return choice;
					});
					return group;
				});
				return variant;
			});
		});
		return imageToBase64(req.data.image).then((base64Image) => {
			req.data.image = base64Image;
			return req;
		});
	},
	validator: (state) => {
		const { image, variants } = state.fields;

		// an image should be selected
		if (!image) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please upload an image for the combo',
			});
			return false;
		}

		// at least one variant should be created
		if (variants.order.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'At least one variant should be created',
			});
			return false;
		}

		for (
			let variantIdx = 0;
			variantIdx < variants.order.length;
			variantIdx++
		) {
			const variantId = variants.order[variantIdx];
			const variant = variants.items[variantId];

			// variant name cannot be empty
			if (variant.name.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please enter a name for Variant ${
						variantIdx + 1
					}`,
				});
				return false;
			}
			// variant name should be lesser than 6 characters
			if (variant.name.length > 6) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Variant name should be lesser than 6 character for Variant ${
						variantIdx + 1
					}`,
				});
				return false;
			}
			// at least one selection group should be created
			if (variant.groups.order.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `At least one selection group should be created in Variant ${
						variantIdx + 1
					}`,
				});
				return false;
			}

			for (
				let groupIdx = 0;
				groupIdx < variant.groups.order.length;
				groupIdx++
			) {
				const groupId = variant.groups.order[groupIdx];
				const group = variant.groups.items[groupId];

				// group name cannot be empty
				if (group.name.length === 0) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Please enter a name for Group ${
							groupIdx + 1
						}`,
					});
					return false;
				}

				// at least one choice should be present in a group
				if (group.choices.length === 0) {
					notificationServices.generateNotification({
						type: 'error',
						message: `At least one product should be selected in Variant ${
							variantIdx + 1
						}, Group ${groupIdx + 1}`,
					});
					return false;
				}
			}
		}

		return true;
	},
};

export default combosConfig;
