// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import BrandingRoutes from './Branding Routes/branding.routes';
import PromoCodesRoutes from './Promo Codes Routes/promocodes.routes';
import {
	PaymentSettlementsListPage,
	TransactionViewPage,
} from '../../Pages/Accounts/Payment Settlements';
import { AddCredits } from '../../Pages/Accounts/AddCredits';
// import { AddChild } from '../../Pages/Accounts/AddChild';
import { AddChild } from '../../Pages/Accounts/AddChild';
import ChildListPage from '../../Pages/Accounts/ChildList/ChildListPage';
import ReportsConfiguration from '../../Pages/Accounts/ReportsConfiguration/ReportsConfiguration';
import MyAuditsPage from '../../Pages/Accounts/MyAudits/MyAuditsPage';
import MyLicensesRoutes from './MyLicenseRoutes/myLicense.routes';
import MyMachinesRoutes from './MyMachinesRoutes/myMachines.routes';
import MyUsersRoutes from './MyUsersRoutes/myUsers.routes';
import MyUsersPermissionsRoutes from './MyUserPermissionsRoutes/myUsers.routes';
import MyContainersRoutes from './MyContainersRoutes/myContainers.routes';
import RevenueRoutes from './RevenueRoutes/revenue.routes';
import ModulesConfigurationRoutes from './ModulesConfigurationsRoutes/modulesConfiguration.routes';
import NotAccessiblePage from '../../Pages/NotAccessable/NotAccessable';
import WastageReasons from '@/Pages/Accounts/WastageReasons/WastageReasons';
// import { AIModelCreation } from '../../Pages/Accounts/AIModelCreation';
// import { AIModelHistoryPage } from '../../Pages/Accounts/AIModelHistoryPage';

const getSubURL = (entity) => {
	const baseEntity = urls.MY_ACCOUNT;
	return entity.slice(baseEntity.length);
};

const MyAccountRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.USERS)}`}>
				<MyUsersRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.USERS_PERMISSIONS)}`}>
				<MyUsersPermissionsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.REVENUE_PAGE)}`}>
				<RevenueRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.PROMO_CODES)}`}>
				<PromoCodesRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.BRANDING)}`}>
				<BrandingRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.MY_LICENSES)}`}>
				<MyLicensesRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.WASTAGE_REASONS)}`}>
				<WastageReasons />
			</Route>
			<Route path={`${path}${getSubURL(urls.MY_AUDITS)}`}>
				<MyAuditsPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.MY_MACHINES)}`}>
				<MyMachinesRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.MY_CONTAINERS)}`}>
				<MyContainersRoutes />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.PAYMENT_SETTLEMENTS)}`}>
				<PaymentSettlementsListPage />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.ADD_CREDITS)}`}>
				<AddCredits />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.CHILD_LIST)}`}>
				<ChildListPage />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.ADD_CHILD)}`}>
				<AddChild />
			</Route>
			<Route path={`${path}${getSubURL(urls.MODULES_CONFIGURATION)}`}>
				<ModulesConfigurationRoutes />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.REPORTS_CONFIG)}`}>
				<ReportsConfiguration />
			</Route>
			{/* <Route exact path={`${path}${getSubURL(urls.AI_MODEL_CREATION)}`}>
				<AIModelCreation />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.AI_MODEL_HISTORY)}`}>
				<AIModelHistoryPage />
			</Route> */}
			<Route
				path={`${path}${getSubURL(urls.PAYMENT_SETTLEMENTS)}/view/:id`}
			>
				<TransactionViewPage />
			</Route>
			<Route path='*'>
				<NotAccessiblePage />
			</Route>
		</Switch>
	);
};

export default MyAccountRoutes;
