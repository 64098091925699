import { useMemo } from 'react';
// eslint-disable-next-line object-curly-newline
import { Rect, Text, Label, Tag } from 'react-konva';

const RectLabel = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { x, y, stroke, label, width, height } = props;

	const textCoords = useMemo(() => {
		const coords = {
			x,
			y: y - 20,
		};

		if (width < 0) {
			coords.x = x + width;
		}
		if (height < 0) {
			coords.y += height;
		}

		return coords;
	}, [x, y, width, height]);

	return (
		<>
			<Label {...textCoords}>
				<Tag pointerWidth={10} fill={stroke} />
				<Text
					{...textCoords}
					fontSize={14}
					text={label}
					fill='white'
					padding={4}
				/>
			</Label>
			<Rect
				x={x}
				y={y}
				width={width}
				height={height}
				fill='transparent'
				stroke={stroke}
			/>
		</>
	);
};

export default RectLabel;
