import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../';
import { addCommas, classes } from '../../Helpers';
import styles from './ForecastingAnalysisListPage.module.scss';
import analyticsAction from '../../Actions/analytics.actions';
import { useAuth } from '../../Components/Auth/Login/auth';
import { ListTable } from '../../Components/ListTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	'Product Name': 'productName',
	Unit: 'unit',
	Store: 'storeName',
	'Day Start Stock': 'dayStartStock',
	'Day Start Stock Price': 'dayStartStockPrice',
	'Day End Stock': 'dayEndStock',
	'Day End Stock Price': 'dayEndStockPrice',
	'Total Stocked In': 'stockedIn',
	'Total Stocked Out': 'stockedOut',
};

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Day Start Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Day Start Stock Price',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Day End Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Day End Stock Price',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Stocked In',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Stocked Out',
		config: {
			type: 'DATA',
		},
	},
];

const getRowStyles = (item) => {
	switch (item?.result) {
		default:
			return styles.normal;
	}
};

// function setAttr(text) {
// return <span className={styles.text}>{text}</span>;
// }

const ForecastingAnalysisListPage = (props) => {
	// const history = useHistory();
	const { data, setAnalyticsType, ANALYTICS_TYPE, exportInvAnalytics } =
		props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;
	// const { appConfig = {} } = useAuth();
	// const { measurement } = appConfig?.configs ?? {};
	// const listCount = data.length - 1;
	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, []);
	const SPECIAL_ITEMS = {
		'Product Name': (_, obj) => {
			// eslint-disable-next-line prefer-template
			return obj?.productName + (obj?.variantName ? ' (' + obj?.variantName + ')' : '');
		},
		'Day Start Stock Price': (value) => {
			return addCommas(value, measurement, true);
		},
		'Day End Stock Price': (value) => {
			return addCommas(value, measurement, true);
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ListTable
				fixedHeaderTop={0}
				hasDateRange
				// renderColumn={renderColumn}
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				data={data?.map((obj) => {
					const rowStyles = getRowStyles(obj);
					// const itemId = get(obj, COLMAP['Item Id']);
					// const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						rowStyles,
					};
				})}
				columns={[...TABLE_COLUMNS]}
				actions={[]}
				onExportClick={exportInvAnalytics}
			/>
			{/* <ArrangePages
				listCount={61}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				setAnalyticsType={setAnalyticsType}
			/> */}
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	exportInvAnalytics: analyticsAction.exportInvAnalytics,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForecastingAnalysisListPage);
