import { useMemo } from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../../Components/SubHeader/SubHeader';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
import { getDateFilterString } from '../../../Helpers';
import QualityAnalysisListPage from './QualityAnalysisListPage';

const ANALYTICS_TYPE = analyticsTypeConstant.QUALITY_ANALYTICS_LIST_DATA;
const PastAnalysisHistoryPage = (props) => {
	const { breadcrumbs, DATE_FILTER, data } = props;
	const selectedDateString = useMemo(() => {
		return getDateFilterString(DATE_FILTER);
	}, [DATE_FILTER]);
	return (
		<div className='page'>
			<SubHeader
				breadcrumbs={breadcrumbs}
				showBreadcrumbs
				selectedDateString={selectedDateString}
			/>
			<QualityAnalysisListPage
				data={data}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { DATE_FILTER } = state.analytics;
	const { LIST_DATA: data } = state.analytics.QUALITY_ANALYTICS;
	return {
		DATE_FILTER: DATE_FILTER.selection,
		data,
	};
};
const mapDispatchToProps = {};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PastAnalysisHistoryPage);
