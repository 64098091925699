import { useRouteMatch } from 'react-router';
// eslint-disable-next-line object-curly-newline
import { PrinterConfigListPage } from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const PrinterConfigRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='PRINTER_CONFIG'
			path={path}
			listPage={PrinterConfigListPage}
		/>
	);
};

export default PrinterConfigRoutes;
