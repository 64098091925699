// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import analyticsAction from '../../../Actions/analytics.actions';
import {
	AnalysisGraph,
	OrderTable,
	StoreAnalysis,
} from '../../../Pages/Analytics';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';

const ANALYTICS_TYPE = analyticsTypeConstant.STORE_ANALYTICS;
const StoreAnalyticsRoutes = (props) => {
	const { setAnalyticsType } = props;
	const { path } = useRouteMatch();
	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {}, false);
	}, []);
	return (
		<>
			<StoreAnalysis />
			<Switch>
				<Route exact path={`${path}`}>
					<AnalysisGraph />
				</Route>
				<Route path={`${path}/table`}>
					<OrderTable />
				</Route>
			</Switch>
		</>
	);
};
const mapStateToProps = () => {};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StoreAnalyticsRoutes);
