import AmountContainer from './Amount Container/AmountContainer';
import styles from './AmountContainerDiv.module.scss';

const AmountContainerDiv = (props) => {
	const { boxElements, amountCaption, renderText } = props;

	return (
		<div className={`${styles.root}`}>
			{amountCaption.map((obj, idx) => {
				return (
					<AmountContainer
						color={obj?.color || boxElements[idx % boxElements.length]?.color}
						amountCaption={obj}
						renderText={!!renderText && (() => {
							return renderText(obj, idx);
						})}
					/>
				);
			})}
		</div>
	);
};
AmountContainerDiv.defaultProps = {
	boxElements: [
		{
			color: '#ff6961',
		},
		{
			color: '#66c24d',
		},
		{
			color: '#fdf8de',
		},
		{
			color: '#ffe766',
		},
		{
			color: '#c4c4c4',
		},
		{
			color: '#fdf8de',
		},
	],
	amountCaption: [
		{
			caption: 'Net Payment Receivable',
			amount: '1088.95',
		},
		{
			caption: 'Gross Amount',
			amount: '1088.95',
		},
		{
			caption: 'Net Amount',
			amount: '1088.95',
		},
		{
			caption: 'Payment Amount',
			amount: '10',
		},
		{
			caption: 'Refunds',
			amount: '18888888.95',
		},
		{
			caption: 'Platform Charge',
			amount: '10.95',
		},
	],
};
export default AmountContainerDiv;
