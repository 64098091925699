import { endpoints } from '../../Constants';
import {
	authHeader, handleResponse, toQueryStringURL
} from '../../Helpers';

const InventorySkuServices = {
	gateway,
	createInventorySku,
	viewInventorySku,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createInventorySku({
				req,
			});
		case 'VIEW':
			return viewInventorySku({
				req,
			});
		case 'LIST':
			return listInventorySku({
				req,
			});
		case 'UPDATE':
			return updateInventorySku({
				req,
			});
		case 'ENABLE':
			return toggleDisableInventorySku({
				req,
			});
		case 'DELETE':
			return deleteInventorySku({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createInventorySku({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.INVENTORY_SKU, requestOptions).then(handleResponse);
}

function updateInventorySku({ req }) {
	req.data.id = req.params.id;
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.INVENTORY_SKU, params);
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewInventorySku({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.INVENTORY_SKU, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function listInventorySku({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.INVENTORY_SKU, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function toggleDisableInventorySku({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.INVENTORY_SKU, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function deleteInventorySku({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.INVENTORY_SKU, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default InventorySkuServices;
