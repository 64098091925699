import { classes } from '../../../Helpers';
import styles from './GSTInComp.module.scss';

/**
 * @typedef {Object} Props
 * @property {string} gstIn - GST Identification Number
 * @property {function} onClick - Click event handler function
 */

/**
 * GSTInComp function component
 * @param {Props} props - Component props
 */
function GSTInComp(props) {
	const {
		gstIn,
		onClick = () => {},
	} = props;
	return (
		<div className={classes(!gstIn && styles['no-gstin'], styles.root)}>
			<p>GSTIN: </p>
			{gstIn ? (
				<p>{gstIn}</p>
			) : (
				<div className={styles['btn-container']}>
					<p>Not Configured</p>
					<button
						type='button'
						className={styles['edit-btn']}
						onClick={() => {
							onClick();
						}}
					>
						Edit
					</button>
				</div>
			)}
		</div>
	);
}

export default GSTInComp;
