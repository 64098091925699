import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useQuery from './useQuery';

/* SEARCH QUERIES */
/* [
    {
        id: string, ""
        type: string, "filter type such orderId"
        generateTitle: Function, ""
        removable: boolean,
        exclusiveKey: string "this refers to client ID which user will see in the UI",
        dependentKey: string "this refers to the dependent key for ex. 'poId comes with billId'"
    }
] */
/* SEARCH QUERIES */
/* SEARCH QUERIES */

export default function useSearchFilters(props) {
	const { searchQueries = [], callBackFn = () => {} } = props;
	const params = useQuery();
	const history = useHistory();
	// let poId = params.get('poId') ? Number(params.get('poId')) : undefined;
	// let billId = params.get('billId')
	// ? Number(params.get('billId'))
	// : undefined;
	// let grnId = params.get('grnId') ? Number(params.get('grnId')) : undefined;
	// let clientPOId = params.get('clientPoId');
	const [externalFilters, setExternalFilters] = useState([]);
	// const [externalFilters, setExternalFilters] = useState([]);

	const setSearchFilter = (ele) => {
		callBackFn();
		const currSearchFilter = {
			id: ele?.id,
			type: ele?.type,
			title: ele?.generateTitle(params?.get(ele?.exclusiveKey)),
			// title: ele?.generateTitle(params?.get(exclusiveKey)) `Order Id: ${exclusiveKey}`,
			removable: ele?.removable ?? true,
			value: params?.get(ele?.id),
			handleRemove: () => {
				params.delete(ele?.id);
				params.delete(ele?.exclusiveKey);
				if (ele?.dependentKey && params?.get(ele?.dependentKey)) {
					params.delete(ele?.dependentKey);
					// billId = null;
				}
				// poId = null;
				// clientPOId = null;
				callBackFn();
				setExternalFilters((prev) => {
					return prev?.filter((filter) => {
						if (ele?.dependentKey) {
							return (
								filter?.type !== ele?.type &&
								filter?.type !== ele?.dependentKey
							);
						}
						return filter?.type !== ele?.type;
					});
				});
				history.replace({
					search: params.toString(),
				});
			},
		};
		setExternalFilters((prev) => {
			const exists = prev?.find((currFilter) => {
				return currFilter?.type === ele?.type;
			});
			if (exists) {
				if (exists?.id !== ele?.id) {
					return prev?.map((currFilter) => {
						if (currFilter?.type === ele?.type) {
							return currSearchFilter;
						}
						return currFilter;
					});
				}
				return prev;
			}
			return [...prev, currSearchFilter];
		});
	};

	const getAndSetSearchFilters = () => {
		searchQueries?.forEach((ele) => {
			if (params?.get(ele?.id)) {
				setSearchFilter(ele);
			}
		});
	};

	useEffect(() => {
		getAndSetSearchFilters();
	}, [searchQueries]);

	return {
		searchFilters: externalFilters,
	};
}
