/* eslint-disable no-param-reassign */
import { IconButton, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useMemo } from 'react';
import {
	generateCheckedProps,
	generateRandomKey,
	options,
} from '../../../../Helpers';
import styles from './VariantsListTable.module.scss';
import InputWithToggle from '../../../InputWithToggle/InputWithToggle';
import { useAuth } from '../../../Auth/Login/auth';

const VariantsTable = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const { state, handleDelete, isDisabled, handleToggle } = props;
	const handleInput = (e) => {
		props.handleInput(e);
	};

	const getPrice = () => {
		if (measurement === 'US') {
			return '$';
		}
		return '₹';
	};

	const getInputStep = (unit) => {
		if (unit === 'lbs') return 'any';
		if (unit === 'oz' || unit === 'floz' || unit === 'gal') return '.01';
		if (unit === 'kg') return '.001';
		return '';
	};

	const getOptions = useCallback((unit) => {
		if (unit === 'count' || state?.unit === 'count') {
			if (measurement === 'US') {
				return options?.oz;
			}
			return options?.g;
		}
		return options?.[unit ?? state?.unit];
	}, [state?.unit]);
	const getContainerOpt = useCallback((unit) => {
		if (
			state?.unit === 'count' ||
			state?.unit === 'litre' ||
			state?.unit === 'ml' ||
			state?.unit === 'floz'
		) {
			if (measurement === 'US') {
				return options?.oz;
			}
			return options?.g;
		}
		return options?.[unit];
	}, [state?.unit]);

	const getPriceOptions = useCallback(
		(variantPriceUnit) => {
			return options?.[variantPriceUnit];
		},
		[state?.priceUnit]
	);

	const minMaxText = useMemo(() => {
		let minText = 'Min';
		let maxText = 'Max';

		if (
			state?.unit === 'litre' ||
			state?.unit === 'ml' ||
			state?.unit === 'floz'
		) {
			minText = 'Min Volume';
			maxText = 'Max Volume';
		}

		return {
			minText,
			maxText,
		};
	}, [state?.unit]);

	const allowTransferSellingPrice = useMemo(() => {
		return state?.skuType === 'PRODUCT' || state?.skuType === 'INTERMEDIATE';
	}, [state?.skuType]);

	return (
		<div className={`${styles['table-wrapper']}`}>
			<table className={`${styles['list-table-root']}`}>
				<thead>
					<tr>
						<th>Size</th>
						<th>Default </th>
						<th>Enable</th>
						<th>UY Variant ID</th>
						<th>Display Name</th>
						<th>Client Variant ID</th>
						{/* <th>Description</th> */}
						<th>{minMaxText.minText}</th>
						<th>{minMaxText.maxText}</th>
						<th>Container Weight</th>
						{/* <th>Minimum Order Quantity</th> */}
						<th>Serving Qty(%)</th>
						<th>Unit Conversion Ratio</th>
						{/* <th>Serves</th> */}
						<th>MOQ</th>
						<th>
							Cost price(
							{getPrice()}
							)
						</th>
						{allowTransferSellingPrice && (
							<>
								<th>
									Transfer Price(
									{getPrice()}
									)
								</th>
								<th>
									Selling Price(
									{getPrice()}
									)
								</th>
							</>
						)}

						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					{state &&
						state.variants &&
						state.variants.map((variant, idx) => {
							const minWeightOptions = getOptions(variant?.minUnit);
							const maxWeightOptions = getOptions(variant?.maxUnit);
							const containerWeightOptions = getContainerOpt(variant?.containerUnit);
							return (
								<tr>
									<td>
										<label>{`size${idx + 1}`}</label>
									</td>
									<td>
										<Switch
											key={generateRandomKey().key}
											name='isDefault'
											checked={idx === 0 ? true : variant.isDefault}
											onChange={(e) => {
												if (idx === 0) {
													variant.isDefault = true;
												} else {
													variant.isDefault = e.target.checked;
												}
												handleInput(e);
											}}
											disabled={
												variant.isDefault || isDisabled
											}
											inputProps={{
												'data-id': idx,
											}}
											size='small'
											color='primary'
										/>
									</td>
									<td>
										<Switch
											name='isDisabled'
											size='small'
											color='primary'
											{...generateCheckedProps(
												!variant.isDisabled
											)}
											onChange={handleInput}
											disabled={
												variant.isDefault || isDisabled
											}
											inputProps={{
												'data-id': idx,
											}}
											key={generateRandomKey().key}
										/>
									</td>
									<td>{variant.id}</td>
									<td>
										<input
											type='text'
											placeholder='Display Name'
											name='displayName'
											required
											value={variant.displayName}
											onInput={handleInput}
											disabled={isDisabled}
											maxLength={10}
											data-id={idx}
										/>
									</td>
									<td className={styles.expandTd}>
										<input
											className={styles.expandInput}
											type='text'
											placeholder='Client Variant ID'
											name='clientVariantId'
											value={variant?.clientVariantId}
											onInput={handleInput}
											disabled={isDisabled}
											maxLength={25}
											data-id={idx}
										/>
									</td>
									{/* <td
										style={{
											width: '150px',
											maxWidth: '100%',
										}}
									>
										<input
											type='text'
											placeholder='Description'
											name='description'
											value={variant.description || ''}
											onInput={handleInput}
											maxLength={20}
											data-id={idx}
											disabled={isDisabled}
											style={{
												width: '150px',
												maxWidth: '100%',
											}}
										/>
									</td> */}
									<td>
										{/* // <input
										// type='number'
										// name='minWeight'
										// value={variant.minWeight}
										// onInput={handleInput}
										// // maxLength={10}
										// min={0}
										// disabled={isDisabled}
										// data-id={idx}
										// /> */}
										<InputWithToggle
											width='75px'
											type='number'
											name='minWeight'
											value={variant.minWeight}
											handleInput={handleInput}
											// maxLength={10}
											min={0}
											isDisabled={isDisabled}
											idx={idx}
											options={minWeightOptions}
											selectedToggleValue={
												variant?.minUnit
													? variant?.minUnit
													: minWeightOptions?.[1]?.value
											}
											toggleType='minUnit'
											handleToggle={handleToggle}
											step={getInputStep(
												variant?.minUnit
											)}
											required
										/>
									</td>
									<td>
										<InputWithToggle
											width='75px'
											type='number'
											name='maxWeight'
											value={variant.maxWeight}
											handleInput={handleInput}
											// maxLength={10}
											min={1}
											isDisabled={isDisabled}
											idx={idx}
											options={maxWeightOptions}
											selectedToggleValue={
												variant?.maxUnit
													? variant?.maxUnit
													: maxWeightOptions?.[1]?.value
											}
											handleToggle={handleToggle}
											toggleType='maxUnit'
											step={getInputStep(
												variant?.maxUnit
											)}
											required
										/>
									</td>
									<td>
										{/* <input
											type='number'
											name='containerWeight'
											value={variant.containerWeight}
											onInput={handleInput}
											// maxLength={10}
											min={0}
											disabled={isDisabled}
											data-id={idx}
										/> */}
										<InputWithToggle
											width='75px'
											type='number'
											name='containerWeight'
											value={variant.containerWeight}
											handleInput={handleInput}
											// maxLength={10}
											min={0}
											isDisabled={isDisabled}
											idx={idx}
											options={containerWeightOptions}
											selectedToggleValue={
												variant?.containerUnit
													? variant?.containerUnit
													: containerWeightOptions?.[1]?.value
											}
											handleToggle={handleToggle}
											toggleType='containerUnit'
											step={
												variant?.containerUnit === 'lbs'
													? 'any'
													: ''
											}
										/>
									</td>
									<td>
										<input
											type='number'
											name='servingQtyPercentage'
											required
											value={variant.servingQtyPercentage}
											onInput={handleInput}
											min={1}
											disabled={isDisabled}
											data-id={idx}
										/>
									</td>
									<td>
										<div
											className={
												styles.calculatedCost
											}
										>
											<span className={styles.label}>
												{state.purchaseUnit
													? `1 ${state.purchaseUnit} = ${variant.factorPurchaseUnit} ${state.unit}`
													: ''}
											</span>
										</div>
										<input
											type='number'
											name='factorPurchaseUnit'
											required
											value={variant.factorPurchaseUnit}
											onInput={handleInput}
											// maxLength={10}
											min={1}
											disabled={isDisabled || !state.purchaseUnit}
											data-id={idx}
										/>
									</td>
									{/* <td>
										<input
											type='text'
											name='serves'
											required
											value={variant.serves}
											onInput={handleInput}
											maxLength={10}
											disabled={isDisabled}
											data-id={idx}
										/>
									</td> */}
									<td>
										<div
											className={
												styles.calculatedCost
											}
										>
											<span
												className={styles.label}
											>
												{state?.unit
													? `Values are in ${state.unit}`
													: ''}
											</span>
										</div>
										<input
											type='text'
											name='pieces'
											required
											value={variant?.pieces}
											onInput={handleInput}
											min={0}
											defaultValue={0}
											disabled={isDisabled}
											data-id={idx}
										/>
									</td>
									<td>
										<InputWithToggle
											width='75px'
											type='number'
											name='costPrice'
											required
											value={
												variant?.calculatedRecipeCost
													? variant?.calculatedRecipeCost
													: variant.costPrice
											}
											handleInput={handleInput}
											// maxLength={10}
											min={0}
											isDisabled={
												isDisabled ||
												variant?.calculatedRecipeCost
											}
											idx={idx}
											options={getPriceOptions(
												variant?.costPriceUnit
											)}
											selectedToggleValue={
												variant?.costPriceUnit
													? variant?.costPriceUnit
													: getPriceOptions(
														variant?.costPriceUnit
													)?.[1]?.value
											}
											handleToggle={handleToggle}
											toggleType='costPriceUnit'
											step='any'
										/>
										<div
											className={
												styles.calculatedCost
											}
										>
											<span
												className={styles.label}
											>
												{variant?.calculatedRecipeCost
													? `Calculated from recipe ${getPrice()}/${
																	variant?.calculatedRecipeCostUnit
															}`
													: ''}
											</span>
										</div>
									</td>
									{allowTransferSellingPrice && (
										<>
											<td>
												<InputWithToggle
													width='75px'
													type='number'
													name='transferPrice'
													required
													isDisabled={
														isDisabled
													}
													value={variant.transferPrice}
													handleInput={handleInput}
													// maxLength={10}
													min={0}
													idx={idx}
													options={getPriceOptions(
														variant?.transferPriceUnit
													)}
													selectedToggleValue={
														variant?.transferPriceUnit
															? variant?.transferPriceUnit
															: getPriceOptions(
																variant?.transferPriceUnit
															)?.[1]?.value
													}
													handleToggle={handleToggle}
													toggleType='transferPriceUnit'
													step='any'
												/>

											</td>
											<td>
												<InputWithToggle
													width='75px'
													type='number'
													name='sellingPrice'
													required
													value={
														variant?.sellingPrice
													}
													handleInput={handleInput}
													// maxLength={10}
													min={0}
													isDisabled={isDisabled}
													idx={idx}
													options={getPriceOptions(
														variant?.sellingPriceUnit
													)}
													selectedToggleValue={
														variant?.sellingPriceUnit
															? variant?.sellingPriceUnit
															: getPriceOptions(
																variant?.sellingPriceUnit
															)?.[1]?.value
													}
													handleToggle={handleToggle}
													toggleType='sellingPriceUnit'
													step='any'
												/>
											</td>
										</>
									)}
									<td>
										<IconButton
											disabled={
												variant.isDefault || isDisabled
											}
											onClick={handleDelete}
											data-id={idx}
											size='large'
										>
											<DeleteIcon />
										</IconButton>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};
export default VariantsTable;
