import { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Ticks } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChartSkeleton } from './Skeleton';
import {
	classes,
	formatNumber, inputHelper, useWindowDimensions
} from '../../../../Helpers';
import styles from './BarChart.module.scss';

const BarChart = (props) => {
	const {
		title,
		legendDisplay,
		maintainAspectRatio,
		data,
		className,
		loading,
		stackX,
		stackY,
		indexAxis,
		xAxisCallback,
		yAxisCallback,
		dataAttrs,
		showDataLabels,
		dataLabelFormatter,
		leftLabelName,
		rightLabelName,
	} = props;

	const ref = useRef(null);

	const onClick = (event) => {
		const { current: chart } = ref;
		if (chart) {
			const elementAtEvent = getElementAtEvent(chart, event);
			const { dataset } = inputHelper(event);
			// eslint-disable-next-line react/destructuring-assignment
			props.onClick(elementAtEvent, dataset);
		}
	};
	const { width } = useWindowDimensions();

	return (
		<div className={styles.root}>
			<div className={classes(styles.labelName, styles.left)}>
				{leftLabelName}
			</div>
			<div className={classes(styles.labelName, styles.right)}>
				{rightLabelName}
			</div>
			<Bar
				ref={ref}
				onClick={onClick}
				data={data}
				options={{
					responsive: true,
					legend: {
						position: 'top',
						display: legendDisplay,
					},
					indexAxis,
					maintainAspectRatio,
					scales: {
						x: {
							stacked: stackX,
							ticks: {
								font: {
									size: 13,
									color: 'black',
								},
								callback(value, index, ticks) {
									return xAxisCallback(
										this,
										value,
										index,
										ticks
									);
								},
							},
						},

						y: {
							stacked: stackY,
							grid: {
								drawOnChartArea: false,
							},
							ticks: {
								font: {
									size: 12,
									color: 'black',
								},
								callback(value, index, ticks) {
									return yAxisCallback(
										this,
										value,
										index,
										ticks
									);
								},
							},
						},
						'right-y-axis': {
							type: 'linear',
							position: 'right',
							grid: {
								display: false,
							},
							ticks: {
								color: 'rgb(73, 113, 116)',
								font: {
									weight: 'bold',
								},
							},
						},
					},
					plugins: {
						datalabels: {
							align: 'start',
							padding: '0',
							anchor: 'center',
							maxRotation: 0,
							rotation: width > 600 ? 0 : 270,
							formatter: dataLabelFormatter,
							display: showDataLabels,
							clamp: false,
							color: '#000',
							font: {
								weight: '400',
								size: 9,
							},
						},
						title: {
							display: true,
							text: title,
							font: {
								color: 'black',
								size: 16,
							},
						},
					},
				}}
				className={className}
				{...dataAttrs}
				plugins={[ChartDataLabels]}
			/>
			{loading && <BarChartSkeleton />}
		</div>
	);
};

BarChart.defaultProps = {
	title: '',
	legendDisplay: true,
	maintainAspectRatio: false,
	data: {
		datasets: [],
		labels: [],
	},
	showDataLabels: false,
	dataLabelFormatter: (context) => {
		if (context) {
			return formatNumber('IN', Math.round(context));
		}
		return '';
	},
	handleElementClick: () => {},
	className: '',
	maxBarThickness: 50,
	stackX: false,
	stackY: false,
	onClick: () => {},
	indexAxis: 'x',
	xAxisCallback(chartThis, value, index, ticks) {
		const newValue = chartThis.getLabelForValue(value);
		return Ticks.formatters.values.apply(chartThis, [
			newValue,
			index,
			ticks,
		]);
	},
	yAxisCallback(chartThis, value, index, ticks) {
		const newValue = chartThis.getLabelForValue(value);
		return Ticks.formatters.values.apply(chartThis, [
			newValue,
			index,
			ticks,
		]);
	},
	dataAttrs: {},
	leftLabelName: '',
	rightLabelName: '',
};

export default BarChart;
