/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
import { Add, ArrowForward, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import {
	analyticsAction,
	formActions,
	modalActions,
} from '../../../../../Actions';
import Calender from '../../../../../Components/Forms/Payment Settlements/Calender/Calender';
import { ListModal } from '../../../../../Components/Modals';
import { Tag } from '../../../../../Components/Tags';
import { classes, inputHelper, useQuery } from '../../../../../Helpers';
// import { notificationServices } from '../../../../../Services';
import styles from './IndentOrderListHeader.module.scss';

const EMPTYVALUE = -999;
const options = ['CANCELLED', 'PENDING', 'FULFILLED'];
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VENDORS':
			return {
				...state,
				vendors: action.payload,
			};
		default:
			return state;
	}
};

const initialState = {
	vendors: [],
};

const params = {
	VENDOR: 'vendor',
	from: 'from',
	to: 'to',
};

function IndentOrderListHeader(props) {
	const {
		toggleModal,
		dateRangeFilter,
		setDateRangeFilter,
		fetchAllVendors,
		vendorsList,
		fetchStoresList,
		stores,
		updateState,
		fetchPurchaseOrderList,
	} = props;
	const [selectedStatus, setSelectedStatus] = useState(['All']);
	const [selectedStore, setSelectedStore] = useState(EMPTYVALUE);
	const [selectedBaseStore, setselectedBaseStore] = useState(EMPTYVALUE);
	const [state, dispatch] = useReducer(reducer, initialState);
	const query = useQuery();
	const { vendors } = state;
	const [isHidden, setIsHidden] = useState(false);

	const handleChange = (event) => {
		const { value } = event.target;
		if (!value?.includes('All') && selectedStatus?.indexOf('All') > -1) {
			const filterStatus = selectedStatus?.filter((status) => {
				return status !== 'All' && selectedStatus?.indexOf(value) > -1;
			});
			setSelectedStatus(filterStatus);
		} else if (value?.includes('All')) {
			const allStatus = ['All'];
			setSelectedStatus(allStatus);
		} else {
			setSelectedStatus(value);
		}
	};

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchAllVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);
	const handleAutoFetchPurchaseOrderList = (
		vendorsArray,
		dateRangePayload
	) => {
		const req = {
			data: {
				filters: {
					storeId: null,
					fromStoreId:
						selectedBaseStore === EMPTYVALUE
							? null
							: selectedBaseStore,
					vendors: vendorsArray,
					dateRangeFilter: {
						from: moment(dateRangePayload.from).format(
							'YYYY-MM-DD'
						),
						to: moment(dateRangePayload.to).format('YYYY-MM-DD'),
					},
					status:
						selectedStatus?.indexOf('All') > -1
							? []
							: selectedStatus,
				},
			},
		};
		fetchPurchaseOrderList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'PO_LIST',
			req,
		});
		setIsHidden(true);
	};

	useEffect(() => {
		let sub = false;
		// const queryBrand = query.get(params.STORE);
		const queryVendor = query.get(params.VENDOR) && [
			Number.parseInt(query.get(params.VENDOR), 10),
		];
		const from = query.get(params.from);
		const to = query.get(params.to);

		if (query.has(params.VENDOR)) {
			sub = true;
			dispatch({
				type: 'SET_VENDORS',
				payload: queryVendor,
			});
		}
		if (sub) {
			const dateRangePayload = {
				// ...dateRange,
				from: from ? new Date(from) : new Date(),
				active: true,
				to: to ? new Date(to) : new Date(),
			};
			setDateRangeFilter({
				from: from ? new Date(from) : new Date(),
				active: true,
				to: to ? new Date(to) : new Date(),
			});
			handleAutoFetchPurchaseOrderList(queryVendor, dateRangePayload);
		}
	}, [query.get(params.VENDOR)]);

	/*
	useEffect(() => {
		if (selectedStatus?.length <= 0 && !selectedStore) {
			setIsHidden(false);
		}
	}, []);
	*/

	const selectStore = (e) => {
		const { fieldValue } = inputHelper(e);
		setSelectedStore(fieldValue);
	};
	// eslint-disable-next-line no-unused-vars
	const selectRetailStore = (e) => {
		const { fieldValue } = inputHelper(e);
		setselectedBaseStore(fieldValue);
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const setDate = (newValues) => {
		const { startDate: from, endDate: to } = newValues?.selection ?? {};
		setDateRangeFilter({
			from,
			to,
			active: true,
		});
	};

	const addFilter = (filters, context) => {
		if (context.entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: [...vendors, ...filters],
			});
		}
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;

		if (entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: vendors.filter((vendor) => {
					return vendor !== parseInt(id, 10);
				}),
			});
		}
	};

	const VENDORS = useMemo(() => {
		return vendorsList.map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
			};
		});
	}, [vendorsList]);

	const handleHideShow = () => {
		setIsHidden(!isHidden);
	};

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
			};
		});
		tempStore?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return tempStore;
	}, [stores]);
	const BASE_STORES = useMemo(() => {
		const tempStore = stores
			?.filter((ele) => {
				return ele.type === 'BASE';
			})
			?.map((tStore) => {
				return {
					id: tStore.id,
					title: tStore?.name ?? '',
				};
			});
		tempStore?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return tempStore;
	}, [stores]);

	useEffect(() => {
		setSelectedStore(STORES?.[0].id);
	}, [stores]);

	const renderSelectedStore = () => {
		const storeObj = STORES?.find((store) => {
			return store?.id === selectedStore;
		});
		return storeObj?.title;
	};
	// eslint-disable-next-line no-unused-vars
	const renderselectedBaseStore = () => {
		const storeObj = BASE_STORES?.find((store) => {
			return store?.id === selectedStore;
		});
		return storeObj?.title;
	};
	/*
	const handleFetchPurchaseOrderList = () => {
		if (!selectedStore) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select Store',
			});
		}
		if (selectedStatus?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select Status Type',
			});
		}
		if (
			selectedStore &&
			selectedStatus?.length > 0 &&
			dateRangeFilter.from &&
			dateRangeFilter.from
		) {
			const req = {
				data: {
					filters: {
						vendors,
						dateRangeFilter: {
							from: moment(dateRangeFilter.from).format('YYYY-MM-DD'),
							to: moment(dateRangeFilter.to).format('YYYY-MM-DD'),
						},
						status: selectedStatus?.indexOf('All') > -1 ? [] : selectedStatus,
					},
				},
				params: {
					storeId: selectedStore,
				},
			};
			fetchPurchaseOrderList({
				entity: 'INVENTORY_ANALYTICS',
				method: 'PO_LIST',
				req,
			});
			setIsHidden(true);
		}
	};
*/
	const handleFetchIndentOrderList = () => {
		const req = {
			data: {
				filters: {
					storeId:
						selectedStore === EMPTYVALUE ? null : selectedStore,
					fromStoreId:
						selectedBaseStore === EMPTYVALUE
							? null
							: selectedBaseStore,
					vendors,
					dateRangeFilter: {
						from: moment(dateRangeFilter.from).format('YYYY-MM-DD'),
						to: moment(dateRangeFilter.to).format('YYYY-MM-DD'),
					},
					status:
						selectedStatus?.indexOf('All') > -1
							? []
							: selectedStatus,
				},
			},
		};
		fetchPurchaseOrderList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'INDENT_LIST',
			req,
		});
		setIsHidden(true);
	};

	useEffect(() => {
		if (updateState !== 0) {
			handleFetchIndentOrderList();
		}
	}, [updateState]);

	const SelectedVendorsTitle = VENDORS.filter((vend) => {
		let exist = false;
		exist = vendors.find((ele) => {
			return ele === vend.id;
		});
		if (exist) return true;
		return false;
	}).map((tempV) => {
		return <div>{tempV.title}</div>;
	});

	return (
		<>
			<ListModal handleConfirm={addFilter} list={VENDORS} />
			<div
				className={classes(
					styles.root,
					isHidden ? styles.hide : styles.show
				)}
			>
				<p className={styles.header}>Indent Order List</p>
				{!isHidden ? (
					<>
						<div className={styles.storeDiv}>
							Indent Store
							<Select
								className={styles['store-select']}
								value={selectedStore}
								onChange={selectStore}
								disabled={false}
							>
								{STORES?.map((storeObj) => {
									return (
										<MenuItem
											key={storeObj.id + storeObj.title}
											value={storeObj?.id}
										>
											{storeObj.title}
										</MenuItem>
									);
								})}
							</Select>
						</div>
						<div className={classes(styles.filter, styles.vendor)}>
							<p className={styles['filter-heading']}>
								Select Raised by
							</p>
							<div className={styles['filter-body']}>
								<div className={styles.tags}>
									{vendors.map((id) => {
										const selectedVendor = VENDORS?.find(
											(v) => {
												return id === v?.id;
											}
										);
										return (
											<Tag
												key={selectedVendor?.id}
												text={selectedVendor?.title}
												handleDelete={deleteFilter}
												dataAttrs={{
													'data-id':
														selectedVendor?.id,
													'data-entity': 'VENDOR',
												}}
											/>
										);
									})}
									{vendors?.length === 0 && (
										<span>All selected</span>
									)}
								</div>
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									onClick={() => {
										toggleModal(true, {
											entity: 'VENDOR',
											heading: 'Select Raised by',
											list: VENDORS,
											selected: vendors,
										});
									}}
								>
									RAISED BY
								</Button>
							</div>
						</div>
						<div className={styles['status-select']}>
							<InputLabel id='mutiple-select-label'>
								Select Status
							</InputLabel>
							<Select
								labelId='mutiple-select-label'
								multiple
								value={selectedStatus}
								onChange={handleChange}
								renderValue={(selected) => {
									return selected.join(', ');
								}}
								defaultChecked='All'
								className={styles.select}
							>
								<MenuItem key='All' value='All'>
									<ListItemIcon>
										<Checkbox
											checked={
												selectedStatus.indexOf('All') >
												-1
											}
										/>
									</ListItemIcon>
									<ListItemText primary='All' />
								</MenuItem>
								{options.map((option) => {
									return (
										<MenuItem key={option} value={option}>
											<ListItemIcon>
												<Checkbox
													checked={
														selectedStatus.indexOf(
															option
														) > -1 ||
														selectedStatus.indexOf(
															'All'
														) > -1
													}
												/>
											</ListItemIcon>
											<ListItemText primary={option} />
										</MenuItem>
									);
								})}
							</Select>
						</div>
						<Calender
							allowFutureDates
							value={dateRangeFilterArray}
							setValue={setDate}
						/>
					</>
				) : (
					<>
						<p>
							Indent Store: <br />
							{renderSelectedStore()}
						</p>
						<p>
							Selected Raised by: <br />
							<div className={styles.selectedVendor}>
								{SelectedVendorsTitle.length > 0 ? (
									<>{SelectedVendorsTitle}</>
								) : (
									<>All selected</>
								)}
							</div>
						</p>
						<p>
							Selected Status: <br />
							{selectedStatus?.map((status) => {
								return <span>{status}</span>;
							})}
						</p>
						<p>
							Selected Date Range: <br />
							{moment(dateRangeFilter.from).format(
								'DD-MM-YYYY'
							)}{' '}
							- {moment(dateRangeFilter.to).format('DD-MM-YYYY')}
						</p>
					</>
				)}
				<Tooltip title={isHidden ? 'Change Filters' : 'Hide Filters'}>
					<div
						onClick={handleHideShow}
						className={styles['hide-icon-container']}
					>
						{isHidden ? (
							<ExpandMore className={styles.icon} />
						) : (
							<ExpandLess className={styles.icon} />
						)}
					</div>
				</Tooltip>
				<div
					className={styles['search-icon']}
					onClick={handleFetchIndentOrderList}
				>
					<ArrowForward className={styles.icon} />
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;

	return {
		stores,
		dateRangeFilter,
		vendorsList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchAllVendors: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchPurchaseOrderList: analyticsAction.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndentOrderListHeader);
