/* eslint-disable space-infix-ops */
/* eslint-disable no-undef */
/* eslint-disable prefer-template */
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelDownload from '../../../Components/ExcelDownload/ExcelDownload';
import styles from './DownloadButton.module.scss';
// const { header, fileData, fileName, className } = props;

function DownloadButton({ data, tableData }) {
	const hideDownload = data?.hideDownload;
	const name = data?.heading;
	const header = () => {
		const labelHeading = data?.xlabel || 'label';
		const datasetLabels =
			data?.datasets?.map((ele) => {
				if (ele?.type === 'LINE') {
					const unit = data?.ylabelLine ? data?.ylabelLine : ' ';
					// return `${ele?.label?.replace(',', '.')} [${unit}]`;
					return (ele?.label?.replace(',', '.') + ' [' + unit +'] ');
				}
				const unit = data?.ylabel ? data?.ylabel : ' ';
				// return `${ele?.label?.replace(',', '.')} [${unit}]`;
				return (ele?.label?.replace(',', '.') + ' [' + unit +'] ');
				// return 's';
			}) ?? [];
		return [labelHeading, ...datasetLabels];
	};
	const fileData = () => {
		const namesLabels = data?.labels?.map((ele) => {
			const label = ele?.split(',').join(" '");
			return label;
		});
		const datasetsData =
			data?.datasets?.map((ele) => {
				const unitData = ele.data?.map((ele2) => {
					if (ele?.type === 'LINE') {
						// return ele2 + data?.ylabelLine;
						return ele2;
					}
					// return ele2 + data?.ylabel;
					return ele2;
				});
				return unitData;
			}) ?? [];
		const tempdata = [namesLabels, ...datasetsData];
		// printing transpose of above matrix
		return tempdata?.[0].map((col, i) => {
			return tempdata?.map((row) => {
				return row[i];
			});
		});
	};
	return (
		<>
			{hideDownload ? (
				<></>
			) : (
				<>
					<div className={styles.root}>
						{tableData === undefined ? (
							<ExcelDownload
								className={styles.downloadButton}
								header={header()}
								fileData={fileData()}
								fileName={name}
							/>
						) : (
							<ExcelDownload
								className={styles.downloadButton}
								header={tableData?.header}
								fileData={tableData?.fileData}
								fileName={tableData?.fileName}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
}

export default DownloadButton;
