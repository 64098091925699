import { classes } from '../../../Helpers';
import styles from './PaymentDetailsModal.module.scss';

const DetailBox = (props) => {
	const { header, className, children } = props;
	return (
		<div className={classes(styles.box, className)}>
			<div className={styles.header}>
				<p>{header}</p>
			</div>
			<div className={styles.container}>{children}</div>
		</div>
	);
};

export default DetailBox;
