import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { AlertModal } from '../../Modals';
import styles from './ViewPageHeader.module.scss';

const ViewPageHeader = (props) => {
	const {
		heading,
		handleDelete,
		showDelete,
		showEdit,
		showBreadcrumbs,
		toggleAlert,
		breadcrumbs,
		userPermissions,
	} = props;

	const handleEdit = () => {
		props.handleEdit();
	};
	return (
		<div className={`${styles.root}`}>
			{showBreadcrumbs ? (
				<Breadcrumb breadcrumbs={breadcrumbs} />
			) : (
				<p className='heading'>{heading}</p>
			)}
			<div className={`${styles['btn-group']}`}>
				{showEdit && userPermissions?.EDIT && (
					<Button
						variant='outlined'
						color='primary'
						onClick={handleEdit}
					>
						Edit
					</Button>
				)}
				{showDelete && userPermissions?.DELETE && (
					<>
						<Button
							variant='outlined'
							color='secondary'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Delete Item?',
									message: 'Click Proceed to delete item',
								});
							}}
						>
							Delete
						</Button>
						<AlertModal
							handleConfirm={() => {
								handleDelete();
								toggleAlert(false);
							}}
						/>
					</>
				)}
			</div>
		</div>
	);
};

ViewPageHeader.defaultProps = {
	showDelete: true,
	showEdit: true,
	handleDelete: () => {},
};

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPageHeader);
