import _ from 'lodash';
import {
	navigationConstants, urls, navMenus
} from '../Constants';
import { objectContainsProperty, history } from '../Helpers';
import modalActions from './modal.actions';

const navigationActions = {
	expandNav,
	collapseNav,
	handleNav,
	toggleSidebar,
	updateNavPermissions,
	updateUserPermissions,
	disableNavItem,
	enableNavItem,
	handleRetailNavItem,
	handleNavRequisite,
	getParentRequisites,
	handleRemoveAllRequisites,
	handleUserRolesNavItem,
	updateNavStateRolesMapping,
	resetNavState,
	updateUserPermittedStores,
	updateUserPermissionsBasedOnLevels,
	resetUserPermissions,
	toggleShowInternalData,
};

function toggleShowInternalData() {
	return (dispatch, getState) => {
		const { showInternalData } = _.cloneDeep(getState().navigation);
		dispatch({
			type: navigationConstants.SET_SHOW_INTERNAL_DATA,
			payload: !showInternalData,
		});
	};
}

function handleNav(pathname) {
	return (dispatch, getState) => {
		const { navState } = _.cloneDeep(getState().navigation);
		const paths = pathname.split('/');
		const navKey = `/${paths[1]}`;
		// check for whether it's a valid navMenu
		if (objectContainsProperty(navState, navKey)) {
			const navItem = navState[navKey];
			// check for whether it's an expandable navMenu
			if (objectContainsProperty(navItem, 'submenu')) {
				// always expand the navMenu
				dispatch(expandNav(navKey));
				// check if there's a submenu in url
				if (paths.length > 2) {
					const subNavKey = `/${paths[1]}/${paths[2]}`;
					// select the sub nav
					dispatch(
						handleNavSelection({
							navState,
							navKey,
							subNavKey,
						})
					);
				}
			} else {
				// it's not an expandable nav, select it
				dispatch(
					handleNavSelection({
						navState,
						navKey,
					})
				);
			}
		}
	};
}

function handleRemoveAllRequisites() {
	return (dispatch, getState) => {
		let { navState } = _.cloneDeep(getState().navigation);
		navState = Object.keys(navState).reduce((acc, curr) => {
			const currNavItem = navState[curr];
			if (currNavItem.requisites) {
				currNavItem.requisites = currNavItem.requisites?.map((ele) => {
					if (ele?.value !== null) {
						return {
							...ele,
							value: null,
						};
					}
					return ele;
				});
				currNavItem.expand = false;
			}
			acc[curr] = currNavItem;
			return acc;
		}, {});
		dispatch({
			type: navigationConstants.NAV_REQUISITE,
			payload: navState,
		});
	};
}
// collapse all other navs and expand the selected nav
function expandNav(navKey, redirectTo = null) {
	return (dispatch, getState) => {
		let { navState } = _.cloneDeep(getState().navigation);
		navState = Object.keys(navState).reduce((acc, curr) => {
			const navItem = navState[curr];
			if (curr === navKey) {
				if (navItem?.requisites && navItem?.requisites?.length > 0) {
					const requisitesPassed = navItem?.requisites?.every(
						(ele) => {
							return ele?.value !== null;
						}
					);
					if (requisitesPassed) {
						navItem.expand = true;
						const submenu = navItem?.submenu;
						const submenuKeys = Object.keys(submenu);
						const firstKey = submenuKeys?.[0];
						const redirectLink = submenu?.[firstKey ?? '']?.href;
						const currPath = history?.location?.pathname;
						if (redirectTo) {
							history.push(redirectTo);
						}
						if (!submenuKeys?.includes(currPath?.split('/console2')[1])) {
							history.push(redirectLink);
						}
					} else {
						dispatch(
							modalActions?.toggleNavItemRequisitesModal(true, {
								navKey,
								navItem,
							})
						);
					}
				} else {
					navItem.expand = true;
				}
			} else {
				navItem.expand = false;
			}
			acc[curr] = navItem;
			// eslint-disable-next-line consistent-return
			return acc;
		}, {});
		dispatch({
			type: navigationConstants.EXPAND_NAV,
			payload: navState,
		});
	};
}

function handleNavRequisite(navKey, navRequisite, redirectTo = null, expand = true) {
	return (dispatch, getState) => {
		let { navState } = _.cloneDeep(getState().navigation);
		navState = Object.keys(navState).reduce((acc, curr) => {
			const currNavItem = navState[curr];
			if (curr === navKey) {
				currNavItem.requisites = navRequisite;
			}
			acc[curr] = currNavItem;
			return acc;
		}, {});
		dispatch({
			type: navigationConstants.NAV_REQUISITE,
			payload: navState,
		});
		if (expand) {
			dispatch(expandNav(navKey, redirectTo));
			dispatch(expandNav(navKey, redirectTo));
		}
	};
}

function getParentRequisites(menuElementKey) {
	return (dispatch, getState) => {
		const { navState } = _.cloneDeep(getState().navigation);
		let parentRequisite = [];
		Object.keys(navState)?.forEach((navKey) => {
			const navElement = navState?.[navKey];
			if (navElement?.submenu) {
				const indexOfSub = Object.keys(navElement?.submenu)?.findIndex((subMenuKey) => {
					return subMenuKey === menuElementKey;
				});
				if (indexOfSub >= 0) {
					parentRequisite = navElement?.requisites ?? [];
				}
			}
		});
		return parentRequisite;
	};
}

function collapseNav() {
	return (dispatch, getState) => {
		let { navState } = _.cloneDeep(getState().navigation);
		navState = Object.keys(navState).reduce((acc, curr) => {
			const navItem = navState[curr];
			navItem.expand = false;
			acc[curr] = navItem;
			return acc;
		}, {});
		dispatch({
			type: navigationConstants.COLLAPSE_NAV,
			payload: navState,
		});
	};
}

function handleNavSelection({ navState = null, navKey, subNavKey = null }) {
	return (dispatch, getState) => {
		dispatch(toggleSidebar(false));
		let finalNavState = {};
		if (navState) {
			finalNavState = _.cloneDeep(navState);
		} else {
			finalNavState = _.cloneDeep(getState().navigation.navState);
		}
		const activeNavKey = navKey;
		const activeSubNavKey = subNavKey;
		const payload = {
			navState: finalNavState,
			activeNavKey,
			activeSubNavKey,
		};

		return {
			type: navigationConstants.HANDLE_NAV_SELECTION,
			payload,
		};
	};
}
function updateNavPermissions() {
	return (dispatch, getState) => {
		let finalNavState = {};
		const { clientType } = JSON.parse(localStorage.getItem('appConfig'));
		const subDomain = window.location.host?.split('.')?.[0];
		finalNavState = _.cloneDeep(getState().navigation.navState);
		if (clientType !== 'FRANCHISEE') {
			finalNavState[urls.ADD_CUSTOMERS] = {
				...finalNavState[urls.ADD_CUSTOMERS],
				disabled: true,
			};
		}

		if (subDomain === 'nucleus') {
			const disabledURLS = [
				{
					url: 'MY_ACCOUNT',
					subURLS: [],
				},
				{
					url: 'STORE_ANALYSIS',
					subURLS: [],
				},
				{
					url: 'INVENTORY_ANALYTICS',
					subURLS: [],
				},
				{
					url: 'MY_CUSTOMERS',
					subURLS: [],
				},
				{
					url: 'MY_OUTLETS',
					subURLS: ['STORE_SETTINGS', 'RIDERS', 'PRINTER_CONFIG'],
				},
				{
					url: 'FOOD_PRODUCTS',
					subURLS: ['CUSTOMIZATIONS', 'NUTRITIONAL_INFO', 'SUBSCRIPTION'],
				},
				{
					url: 'COOKING',
					subURLS: [],
				},
				{
					url: 'INVENTORY',
					subURLS: [],
				},
				{
					url: 'REFUNDS',
					subURLS: [],
				},
			];

			disabledURLS.forEach((entity) => {
				const { url, subURLS = [] } = entity;

				if (subURLS.length === 0) {
					finalNavState[urls[url]] = {
						...finalNavState[urls[url]],
						disabled: true,
					};
				} else {
					finalNavState[urls[url]] = {
						...finalNavState[urls[url]],
						submenu: {
							...finalNavState[urls[url]].submenu,
							...subURLS.reduce((accSubmenus, currSubmenu) => {
								// eslint-disable-next-line no-param-reassign
								accSubmenus[urls[currSubmenu]] = {
									...finalNavState[urls[url]].submenu[urls[currSubmenu]],
									disabled: true,
								};
								return accSubmenus;
							}, {}),
						},
					};
				}
			});
			finalNavState[urls.MY_ACCOUNT] = {
				...finalNavState[urls.MY_ACCOUNT],
				disabled: true,
			};
		}
		const payload = {
			navState: {
				...finalNavState,
			},
		};

		dispatch({
			type: navigationConstants.UPDATE_PATHS,
			payload,
		});
	};
}
function toggleSidebar(show) {
	return {
		type: navigationConstants.TOGGLE_SIDEBAR,
		payload: show,
	};
}

function updateUserPermissions(config) {
	return (dispatch, getState) => {
		const { accessLevel } = config;
		const accessPermissions = accessLevel?.split(',') ?? [];

		let userPermissions = _.cloneDeep(getState().navigation?.userPermissions);

		accessPermissions.forEach((level) => {
			userPermissions[level] = true;
			if (level === 'EDIT') {
				userPermissions = {
					...userPermissions,
					VIEW: true,
					EDIT: true,
					DELETE: true,
					CANCEL: true,
					CREATE: true,
					UPDATE: true,
				};
			}
		});

		dispatch({
			type: navigationConstants.UPDATE_USER_PERMISSIONS,
			payload: userPermissions,
		});
	};
}

function resetUserPermissions() {
	return {
		type: navigationConstants.UPDATE_USER_PERMISSIONS,
		payload: {},
	};
}

function updateUserPermissionsBasedOnLevels(config) {
	return (dispatch, getState) => {
		const { userLevels, internal = false, accToInternal = false } = config;
		const accessPermissions = userLevels;

		let userPermissions = {
			...getState().navigation.userPermissions,
			VIEW: false,
			EDIT: false,
			DELETE: false,
			CANCEL: false,
			CREATE: false,
			UPDATE: false,
			MANAGER: false,
			FINANCE: false,
		};

		if (accToInternal) {
			userPermissions = {
				...userPermissions,
				INTERNAL: internal,
				CONFIG_EDIT: internal,
				ANNOTATE: internal,
				CONFIDENTIAL: internal,
			};
		}

		accessPermissions.forEach((level) => {
			if (level.includes('1')) {
				userPermissions = {
					...userPermissions,
					VIEW: true,
				};
			} else if (level.includes('2')) {
				userPermissions = {
					...userPermissions,
					VIEW: true,
					EDIT: true,
					DELETE: true,
					CANCEL: true,
					CREATE: true,
					UPDATE: true,
				};
			} else if (level.includes('3') || level.includes('4')) {
				userPermissions = {
					...userPermissions,
					VIEW: true,
					EDIT: true,
					DELETE: true,
					CANCEL: true,
					CREATE: true,
					UPDATE: true,
					MANAGER: true,
				};
			} else if (level.includes('5')) {
				userPermissions = {
					...userPermissions,
					VIEW: true,
					FINANCE: true,
				};
			}
		});
		dispatch({
			type: navigationConstants.UPDATE_USER_PERMISSIONS,
			payload: userPermissions,
		});
	};
}

function disableNavItem(config = {}) {
	return (dispatch, getState) => {
		let currentState = {};
		const { navItem, itemToDisable } = config;
		currentState = _.cloneDeep(getState().navigation.navState);
		if (itemToDisable) {
			currentState = {
				...currentState,
				[navItem]: {
					...currentState[navItem],
					disabled: false,
					submenu: {
						...currentState[navItem].submenu,
						[itemToDisable]: {
							...currentState?.[navItem]?.submenu?.[
								itemToDisable
							],
							disabled: true,
						},
					},
				},
			};
		} else {
			currentState = {
				...currentState,
				[navItem]: {
					...currentState[navItem],
					disabled: true,
				},
			};
		}

		dispatch({
			type: navigationConstants.DISABLE_NAV_ITEM,
			payload: currentState,
		});
	};
}
function handleRetailNavItem(config = {}) {
	return (dispatch, getState) => {
		const { excluding = {} } = config ?? {};
		let currentState = {};
		currentState = _.cloneDeep(getState().navigation.navState);
		Object.keys(currentState)?.forEach((key) => {
			if (!excluding[key]) {
				const subMenus = currentState[key]?.submenu;
				if (subMenus) {
					let anySubMenuExcluded = false;
					Object.keys(subMenus).forEach((subMenuKey) => {
						if (!excluding[subMenuKey]) {
							subMenus[subMenuKey] = {
								...subMenus[subMenuKey],
								disabled: true,
								active: false,
							};
						} else {
							anySubMenuExcluded = true; // At least one sub-menu is excluded
						}
					});
					if (!anySubMenuExcluded) {
						currentState[key] = {
							...currentState[key],
							disabled: true,
							active: false,
						};
					}
				} else {
					currentState[key] = {
						...currentState[key],
						disabled: true,
						active: false,
					};
				}
			}
		});
		dispatch({
			type: navigationConstants.DISABLE_NAV_ITEM,
			payload: currentState,
		});
	};
}

function handleUserRolesNavItem(config = {}) {
	return (dispatch, getState) => {
		const { userRoles = {} } = config ?? {};
		let currentState = {};
		currentState = _.cloneDeep(getState().navigation.navState);
		let parentFilerGroup = [];
		let newParent = true;
		let parentShouldDisabled = true;
		Object.keys(currentState)?.forEach((key) => {
			if (!currentState[key]?.type || currentState[key]?.type !== 'NON_LINK') {
				newParent = false;
				const subMenus = currentState[key]?.submenu;
				let excluding = true;
				currentState[key]?.rolesMapped?.forEach((roleId) => {
					if (excluding) excluding = !userRoles[roleId];
				});
				if (excluding && !subMenus) {
					currentState[key] = {
						...currentState[key],
						disabled: true,
					};
				} else {
					currentState[key] = {
						...currentState[key],
						disabled: false,
					};
				}
				if (subMenus) {
					let isSubPage = false;
					Object.keys(subMenus).forEach((subMenuKey) => {
						let subExcluding = true;
						subMenus[subMenuKey]?.rolesMapped?.forEach((roleId) => {
							if (subExcluding) subExcluding = !userRoles[roleId];
						});
						if (subExcluding) {
							subMenus[subMenuKey] = {
								...subMenus[subMenuKey],
								disabled: true,
							};
						} else {
							subMenus[subMenuKey] = {
								...subMenus[subMenuKey],
								disabled: false,
							};
							isSubPage = true;
						}
					});
					currentState[key] = {
						...currentState[key],
						disabled: !isSubPage,
					};
					if (isSubPage) {
						parentShouldDisabled = false;
					}
				}
			} else if (newParent) {
				parentFilerGroup.push(key);
			} else {
				parentFilerGroup.forEach((fillerKey) => {
					currentState[fillerKey] = {
						...currentState[fillerKey],
						disabled: parentShouldDisabled,
					};
				});
				parentFilerGroup = [];
				parentFilerGroup.push(key);
				newParent = true;
				parentShouldDisabled = true;
			}
		});
		dispatch({
			type: navigationConstants.DISABLE_NAV_ITEM,
			payload: currentState,
		});
	};
}
function updateNavStateRolesMapping(config = {}) {
	const { rolesConfig } = config;
	return (dispatch, getState) => {
		let currentState = {};
		currentState = _.cloneDeep(getState().navigation.navState);
		Object.keys(currentState)?.forEach((key) => {
			const itemId = currentState[key]?.id;
			if (itemId && rolesConfig[itemId]?.rolesMapped) {
				currentState[key].rolesMapped = rolesConfig[itemId]?.rolesMapped;
			}
			const subMenus = currentState[key]?.submenu;
			if (subMenus) {
				Object.keys(subMenus).forEach((subMenuKey) => {
					const subItemId = currentState[key].submenu[subMenuKey]?.id;
					if (subItemId && rolesConfig?.[subItemId]?.rolesMapped) {
						currentState[key].submenu[subMenuKey].rolesMapped = rolesConfig[subItemId]?.rolesMapped;
					}
				});
			}
		});
		dispatch({
			type: navigationConstants.UPDATE_NAV_ITEM,
			payload: currentState,
		});
	};
}
function resetNavState() {
	return (dispatch) => {
		dispatch({
			type: navigationConstants.UPDATE_NAV_ITEM,
			payload: _.cloneDeep(navMenus),
		});
	};
}
function updateUserPermittedStores(config = {}) {
	const { userStores } = config;
	return (dispatch) => {
		dispatch({
			type: navigationConstants.SET_PERMITTED_STORES,
			payload: userStores,
		});
	};
}
function enableNavItem(config = {}) {
	return (dispatch, getState) => {
		let currentState = {};
		const { navItem, itemToEnable } = config;
		currentState = _.cloneDeep(getState().navigation.navState);
		if (itemToEnable) {
			currentState = {
				...currentState,
				[navItem]: {
					...currentState[navItem],
					disabled: false,
					submenu: {
						...currentState[navItem].submenu,
						[itemToEnable]: {
							...currentState[navItem].submenu[itemToEnable],
							disabled: false,
						},
					},
				},
			};
		} else {
			currentState = {
				...currentState,
				[navItem]: {
					...currentState[navItem],
					disabled: false,
				},
			};
		}

		dispatch({
			type: navigationConstants.ENABLE_NAV_ITEM,
			payload: currentState,
		});
	};
}

export default navigationActions;
