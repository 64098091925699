import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../../Pages';
import BillsPage from '../../../../Pages/BillsPage/BillsPage';
import BillsCreatePage from '../../../../Pages/BillsPage/BillsCreatePage/BillsCreatePage';

const BillRoutes = ({ type }) => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				{/* <MyMachinesPage createPath={`${path}/add`} /> */}
				<BillsPage type={type} createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<BillsCreatePage />}
					entity='MACHINES'
				/>
			</Route>
		</Switch>
	);
};

export default BillRoutes;
