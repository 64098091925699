const productConstants = {
	FETCH_PRODUCTS_REQUEST: 'FETCH_PRODUCTS_REQUEST',
	FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
	FETCH_PRODUCTS_FAILURE: 'FETCH_PRODUCTS_FAILURE',

	FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST',
	FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
	FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',
};

export const PRODUCT_CLASSES = [
	{
		name: 'Product',
		id: 'product',
	},
	{
		name: 'Intermediate',
		id: 'intermediate',
	},
	{
		name: 'Ingredient',
		id: 'ingredient',
	},
	{
		name: 'Packaging',
		id: 'packaging',
	},
	{
		name: 'Other',
		id: 'other',
	},
];
export const PRODUCT_UNITS = [
	{
		name: 'Gram',
		id: 'gram',
	},
	{
		name: 'Count',
		id: 'count',
	},
	{
		name: 'mL',
		id: 'ml',
	},
	{
		name: 'Oz',
		id: 'oz',
	},
	{
		name: 'Floz',
		id: 'floz',
	},
];

export default productConstants;
