import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import styles from './Tag.module.scss';

const Tag = (props) => {
	const { text, dataAttrs, handleDelete, isDisabled, width } = props;
	return (
		<Button
			style={{
				width,
			}}
			className={`${styles.root} ${
				isDisabled ? styles.disabled : ''
			}`}
			onClick={handleDelete}
			{...dataAttrs}
		>
			<ClearIcon className={styles.clear} />
			<span className={styles.text}>{text}</span>
		</Button>
	);
};

Tag.defaultProps = {
	text: '',
	dataAttrs: {},
	handleDelete: () => {},
	isDisabled: false,
	width: '',
};

export default Tag;
