/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Button, IconButton, MenuItem, TextField } from '@mui/material';
import { useEffect, useReducer, useState, useMemo } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './DeliveryChallanCreatePage.module.scss';
import config from './DeliveryChallanCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../../Services';
import { ListModal } from '../../../../Components/Modals';
import { formActions, modalActions } from '../../../../Actions';
import licenseServices from '../../../../Services/license-services';
import BackdropLoader from '../../../../Components/Loaders/BackdropLoader';
import { classes, inputHelper, useQuery } from '../../../../Helpers';
import myMachinesServices from '../../../../Services/AssetServices/myMachine.service';
import DatePickerCustom from '../../../../Components/DatePickerCustom/DatePickerCustom';
import { POItemSelection } from '../../../../Components/POItemSelection';
import CustomInputSelector from '../../../../Components/CustomInputSelector/CustomInputSelector';
import { IconInput } from '../../../../Components/Forms/Inputs';
import inventoryAnalyticsServices from '../../../../Services/Analytics Services/inventory-analytics.services';
import { AddNewVendorModal } from '../../../../Components/Modals/AddNewVendorModal';
import { POTotalContainer } from '../../../../Components/POTotalContainer';
import { urls } from '../../../../Constants';

function DeliveryChallanCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		fetchProducts,
		fetchVendors,
		productsList,
		navState,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const {
		fromStore,
		store,
		challanId,
		orderId,
		invoiceId,
		deliveryChallanDate,
		// items,
	} = state ?? {};
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const poId = Number(params.get('poId'));
	const [orderItems, setOrderItems] = useState([]);
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	// const orderId = parseInt(params.get('orderId'), 10);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	async function fetchBillId() {
		if (challanId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_VALUE',
					payload: {
						challanId: response?.data,
					},
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	useEffect(() => {
		if (!challanId) {
			fetchBillId();
		}
	}, [challanId]);

	useEffect(() => {
		if (navSelectedStore?.value?.id && navSelectedStore?.value?.id !== -999) {
			const navStore = stores?.find((ele) => {
				return ele?.id === navSelectedStore?.value?.id;
			});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					fromStore: navStore,
				},
			});
		}
	}, [navSelectedStore?.value?.id]);

	useEffect(() => {
		if (
			poId &&
			stores?.length > 0
		) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.fetchPo(
						Number(poId)
					);
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								store: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								orderId: response?.data?.id,
							},
						});
						setOrderItems(response?.data?.items);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [poId, stores]);

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer(state, navSelectedStore?.value?.id);
		const validate = config?.validator(state);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
				type: 'DELIVERY_CHALLAN',
			},
		};
		setLoading(true);
		try {
			const response = await inventoryAnalyticsServices.createTransactionRecord(
				req
			);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const setItemsState = (items) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				items,
			},
		});
	};

	const PRODUCTS = useMemo(() => {
		return productsList?.filter((product) => {
			return (
				product?.skuType === 'INTERMEDIATE' ||
				product?.skuType === 'PRODUCT' ||
				product?.skuType === 'INGREDIENT'
			);
		});
	}, [productsList]);

	const handleKeyPress = (e) => {
		const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			// setState(`SET_${key}`, {});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					[key]: {},
				},
			});
		}
	};

	const handleToStore = (newToStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				store: newToStore,
			},
		});
	};
	const handleFromStore = (newStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				fromStore: newStore,
			},
		});
	};
	const handleVendorSelection = (vendor) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				vendor,
			},
		});
	};

	const disabledDate = (rsuiteDate) => {
		return rsuiteDate > new Date();
	};

	return (
		<div className={styles.root}>
			<AddNewVendorModal />
			<BackdropLoader isLoading={loading} showProgress />
			{/* <ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/> */}
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>
						Add New Delivery Challan
					</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						From Store*
					</label>
					<CustomInputSelector
						inputName='fromStore'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleKeyPress={handleKeyPress}
						handleSelection={handleFromStore}
						hideAddNew
						placeholder='Select From Store'
						selectedValue={fromStore?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Store*
					</label>
					<CustomInputSelector
						inputName='store'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleKeyPress={handleKeyPress}
						handleSelection={handleToStore}
						hideAddNew
						placeholder='Store'
						selectedValue={store?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Challan ID*
					</label>
					<IconInput
						type='custom'
						size='extra-large'
						inputType='text'
						iconText='DC-'
						value={challanId}
						handleInput={(e) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									challanId: e?.target?.value,
								},
							});
						}}
						style={{
							width: '200px',
						}}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className='form-label'
						// htmlFor={id}
					>
						Order id
					</label>
					<TextField
						id='orderNo'
						type='text'
						name='orderId'
						value={orderId}
						onChange={(e) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									orderId: e?.target?.value,
								},
							});
						}}
						placeholder='Order id'
						sx={{
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Delivery Challan date*
						</label>
						<DatePickerCustom
							selectedDate={deliveryChallanDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										deliveryChallanDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 200,
							}}
							oneTap
							defaultValue={deliveryChallanDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
				</div>
			</div>
			<div className={styles['items-details-container']}>
				<POItemSelection
					state={state?.items}
					setState={setItemsState}
					defaultSelectedItems={orderItems}
					productsList={PRODUCTS}
					requiredFields={{
						fromStore: true,
						deliverTo: true,
						poId: true,
						date: true,
						expectedDeliveryDate: true,
						paymentTerms: false,
						generateStandardPO: false,
					}}
					isDeliveryChallan
					disabled={false}
					filterColumns={[
						'EXPECTED LEFT OVER',
						'TAX %',
						'Sub total',
						'Total Tax',
					]}
				/>
				{/* <POTotalContainer calculatedData={calculatedData} /> */}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	return {
		userPermissions,
		stores,
		vendors,
		processTypes,
		productsList,
		navState,
	};
};
// ['PRODUCT', 'INTERMEDIATE']

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchVendors: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeliveryChallanCreatePage);
