const tokenTTL = 60; // in minutes

const setCookie = (key, value, expiryTime) => {
	const maxAge = expiryTime * 60;
	document.cookie = `${key}=${value}; max-age=${maxAge}; path=/;`;
};

const getCookie = (key) => {
	const name = `${key}=`;
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

const deleteCookie = (key) => {
	setCookie(key, 'null', 0);
};

const checkToken = (refreshToken = false) => {
	const token = getCookie('token');
	if (['', null, undefined].includes(token) === true) {
		// window.location.replace("http://localhost/SQC");
	} else if (refreshToken === true) setCookie('token', token, tokenTTL);
};

export { setCookie, getCookie, deleteCookie, checkToken, tokenTTL };
