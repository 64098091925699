// eslint-disable-next-line object-curly-newline
import { Input, Slider, Switch } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { generateDisabledProps } from '../../Helpers';
import ProductTimeSlots from '../Forms/MenuDetails/ProductTimeComponent/ProductTimeSlots';
import styles from './SliderDiscount.module.scss';

const generateUnderLineProps = (disableUnderline) => {
	// eslint-disable-next-line object-curly-newline
	return disableUnderline ? { disableUnderline } : {};
};

const SliderDiscount = (props) => {
	const {
		activeSubCategory,
		idx,
		productObj,
		product,
		openTimeSlots,
		handleDelete,
		handleSwitchChange,
		isDisabled,
		showAddOn,
		handleQtyChange,
	} = props;
	const discountedPrice =
		((parseInt(product.cost, 10) -
			(parseInt(product.cost, 10) *
				(productObj.discount ? productObj.discount : 0)) /
				100) *
			100) /
		100;
	const [value, setValue] = useState(0);
	const handleSliderChange = (event, newValue) => {
		setValue(newValue);
		props.handleSliderChange(idx, newValue);
	};
	const handleInputChange = (event) => {
		setValue(event.target.value === '' ? '' : Number(event.target.value));
		props.handleSliderChange(idx, Number(event.target.value));
	};
	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 100) {
			setValue(100);
		}
	};

	const handleQtyInput = (e) => {
		if (e.target.value !== '' && Number(e.target.value) > 0) {
			handleQtyChange(idx, Number(e.target.value));
		} else if (e.target.value !== '' && Number(e.target.value) <= 0) {
			toast.error('Max Qty should be more than 0');
		} else {
			handleQtyChange(idx, null);
		}
	};

	return (
		<div className={`${styles.root}`}>
			<ProductTimeSlots
				slots={productObj.timeSlots}
				days={productObj?.applicableDays}
				dates={productObj?.applicableDates}
				handleAddNewSlot={openTimeSlots}
				dataAttrs={{
					'data-type': 'product',
					'data-id': idx,
					'data-name': 'time-slot',
				}}
				handleDelete={handleDelete}
				className={`${styles['time-wrapper']}`}
				isDisabled={isDisabled}
			>
				<div className={`${styles.price}`}>
					<label htmlFor='price'>Price (₹)</label>
					<label htmlFor='price'>
						{productObj.discount > 0 ? (
							<div className={`${styles['price-wrapper']}`}>
								<span className={`${styles['actual-price']}`}>
									{parseInt(product.cost, 10)}
								</span>
								<span
									className={`${styles['discounted-price']}`}
								>
									{discountedPrice.toFixed(2)}
								</span>
							</div>
						) : (
							<span className={`${styles['discounted-price']}`}>
								{parseInt(product.cost, 10)}
							</span>
						)}
					</label>
				</div>
				{showAddOn && (
					<div className={`${styles.isAddOn}`}>
						<label htmlFor='isAddOn'>AddOn</label>
						<Switch
							name='isAddOn'
							value='isAddOn'
							size='small'
							color='primary'
							onChange={handleSwitchChange}
							inputProps={{
								'data-id': idx,
								'data-type': 'subCategory',
							}}
							checked={productObj.isAddOn}
							{...generateDisabledProps(isDisabled)}
						/>
					</div>
				)}
				<div className={`${styles['qty-container']}`}>
					<label htmlFor={`${styles.qty}`}>Max QTY</label>
					<Input
						value={productObj.maxQty}
						size='small'
						onChange={handleQtyInput}
						onBlur={handleBlur}
						defaultValue={productObj.maxQty}
						// inputProps={{
						// step: 1,
						// min: 0,
						// max: 100,
						// type: 'text',
						// 'aria-labelledby': 'input-slider',
						// }}
						className={`${styles.qty}`}
						{...generateDisabledProps(isDisabled)}
						{...generateUnderLineProps(isDisabled)}
					/>
				</div>
				<div className={`${styles.discount}`}>
					<label
						className={`${styles.discountName}`}
						htmlFor={`${styles.dis}`}
					>
						Discount (%)
					</label>
					<Input
						value={productObj.discount}
						size='small'
						onChange={handleInputChange}
						onBlur={handleBlur}
						defaultValue={productObj.discount}
						inputProps={{
							step: 1,
							min: 0,
							max: 100,
							type: 'text',
							'aria-labelledby': 'input-slider',
						}}
						className={`${styles.dis}`}
						{...generateDisabledProps(isDisabled)}
						{...generateUnderLineProps(isDisabled)}
					/>
				</div>
				{!isDisabled && (
					<div className={`${styles.slider}`}>
						<Slider
							data-type='product'
							data-id={idx}
							data-parent={activeSubCategory}
							value={productObj.discount}
							onChange={handleSliderChange}
							{...generateDisabledProps(isDisabled)}
						/>
					</div>
				)}
			</ProductTimeSlots>
		</div>
	);
};
SliderDiscount.defaultProps = {
	discount: 0,
	isDisabled: false,
	showAddOn: true,
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderDiscount);
