import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, handleResponse } from '../../Helpers';

const refundHistoryServices = {
	gateway,
};
function gateway({ req, method }) {
	switch (method) {
		case 'LIST':
			return listHistoryRefund({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function listHistoryRefund() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.REFUND_HISTORY, requestOptions).then(handleResponse);
}

export default refundHistoryServices;
