import { Button } from '@mui/material';

const DownloadPP = (props) => {
	const { PP } = props;
	return (
		<div className='button'>
			<Button variant='outlined' color='primary' href={PP} target='_blank'>
				view
			</Button>
		</div>
	);
};
export default DownloadPP;
