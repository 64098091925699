import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import CreatePurchaseOrder from '../../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder';
import PurchaseOrderHistory from '../../../Pages/Analytics/PurchaseOrder/PurchaseOrderHistory/PurchaseOrderHistory';
import BillRoutes from './BillRoutes/bill.routes';
import InvoiceRoutes from './InvoiceRoutes/invoice.routes';
import CreditNoteRoutes from './CreditNoteRoutes/creditNote.routes';
import PaymentsMadeRoutes from './PaymentsMade/paymentsMade.routes';
import DeliveryChallanRoutes from './DeliveryChallanRoutes/deliveryChallan.routes';
import SalesReturnRoutes from './SalesReturnRoutes/salesReturn.routes';
import EWayBillCreatePage from '../../../Pages/EWayBill/EWayBillCreatePage';
import BillsSummary from '../../../Pages/BillsSummary/BillsSummary';

const getSubURL = (entity) => {
	const baseEntity = urls.PURCHASE_ORDERS;
	return entity.slice(baseEntity.length);
};

const PurchaseOrderRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.PURCHASE_ORDERS_HISTORY)}`}>
				<PurchaseOrderHistory />
			</Route>
			<Route path={`${path}${getSubURL(urls.CREATE_EWAY_BILL)}`}>
				<EWayBillCreatePage />
			</Route>
			<Route path={`${path}${getSubURL(urls.CREATE_PURCHASE_ORDER)}`}>
				<CreatePurchaseOrder />
			</Route>
			<Route path={`${path}${getSubURL(urls.INVOICE_LIST)}`}>
				<InvoiceRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.EXTERNAL_SALES_ORDERS_BILL_LIST)}`}>
				<InvoiceRoutes type='externalSales' />
			</Route>
			<Route path={`${path}${getSubURL(urls.DELIVERY_CHALLAN)}`}>
				<DeliveryChallanRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.PAYMENT_RECEIVED)}`}>
				<PaymentsMadeRoutes type='paymentReceived' />
			</Route>
			<Route path={`${path}${getSubURL(urls.EXTERNAL_SALES_ORDERS_PAYMENTS)}`}>
				<PaymentsMadeRoutes type='paymentReceivedExternal' />
			</Route>
			<Route path={`${path}${getSubURL(urls.BILLS)}`}>
				<BillRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.BILLS_SUMMARY)}`}>
				<BillsSummary />
			</Route>
			<Route path={`${path}${getSubURL(urls.INTERNAL_PURCHASE_ORDERS_BILLS)}`}>
				<BillRoutes type='internalPoBills' />
			</Route>
			<Route path={`${path}${getSubURL(urls.CREDIT_NOTE)}`}>
				<CreditNoteRoutes type='creditNote' />
			</Route>
			<Route path={`${path}${getSubURL(urls.SALES_RETURN)}`}>
				<SalesReturnRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.CREDITS)}`}>
				<CreditNoteRoutes type='credits' />
			</Route>
			<Route path={`${path}${getSubURL(urls.PAYMENT_MADE)}`}>
				<PaymentsMadeRoutes type='paymentMade' />
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

export default connect(mapStateToProps)(PurchaseOrderRoutes);
