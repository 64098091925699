import { Tooltip, IconButton } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import {
	ArrowBack,
	ArrowBackIos,
	ArrowForwardIos,
	OnlinePrediction,
} from '@mui/icons-material';
import { useHistory } from 'react-router';
import { urls } from '../../../../Constants';
import { classes } from '../../../../Helpers';
import styles from './Header.module.scss';

function Header(props) {
	const {
		live,
		currentDate,
		idx,
		selectedStoreName,
		listData,
		storeSelector,
		storeList,
		machine,
		navigateIMS,
		stockInAt,
		stockOutAt,
		qrCode,
	} = props;
	const history = useHistory();

	const handleGoLive = async () => {
		history.push(`${urls.EXTENSION}${urls.LIVE_IMS}`);
	};

	return (
		<div className={classes(styles.root)}>
			{/* go back */}
			{!live && (
				<Tooltip title='Go Back' arrow>
					<IconButton
						onClick={() => {
							history.push(
								`${urls.EXTENSION}${urls.STOCK_IN_REPORT}`
							);
						}}
						size='large'
					>
						<ArrowBack />
					</IconButton>
				</Tooltip>
			)}
			{/* current date */}
			<div className={styles.stat}>
				<div className={styles.label}>Current Date</div>
				<div className={styles.value}>
					{currentDate === '' ? 'Not Active' : currentDate ?? '-'}
				</div>
			</div>
			{/* stock in time */}
			<div className={styles.stat}>
				<div className={styles.label}>Stock In At</div>
				<div className={styles.value}>{stockInAt}</div>
			</div>
			{/* stock out time */}
			<div className={styles.stat}>
				<div className={styles.label}>Stock Out At</div>
				<div className={styles.value}>{stockOutAt}</div>
			</div>
			{/* prev stock in */}
			<div className={styles.navigation}>
				<Tooltip title='Prev Stock In' arrow>
					<IconButton
						style={{
							visibility: idx > 0 && !live ? 'visible' : 'hidden',
						}}
						disabled={idx === 0 || live}
						onClick={navigateIMS}
						data-type='prev'
						size='large'
					>
						<ArrowBackIos />
					</IconButton>
				</Tooltip>
				<div className={styles.stat}>
					<div className={styles.label}>QR</div>
					<div className={styles.value}>{qrCode}</div>
				</div>
				<Tooltip title='Next Stock In' arrow>
					<IconButton
						style={{
							visibility:
								idx < listData.length - 1 && !live
									? 'visible'
									: 'hidden',
						}}
						disabled={idx === listData.length - 1 || live}
						onClick={navigateIMS}
						data-type='next'
						size='large'
					>
						<ArrowForwardIos />
					</IconButton>
				</Tooltip>
			</div>
			{/* store selection */}
			{live && storeList != null && (
				<div className={styles.stat}>
					<div className={styles.label}>Select Store</div>
					{storeList?.length > 0 && (
						<select
							className={classes(styles.value, styles.select)}
							selected={selectedStoreName}
							onChange={storeSelector}
						>
							<option value=''>None</option>
							{storeList?.map((elem, index) => {
								return (
									<option value={index}>{elem?.name}</option>
								);
							})}
						</select>
					)}
				</div>
			)}
			{/* machine status / go live */}
			{live ? (
				<div
					className={classes(
						styles['machine-status'],
						styles[machine?.status ?? 'OFFLINE']
					)}
				>
					<OnlinePrediction />
					{machine?.status}
				</div>
			) : (
				<button
					className={styles.live}
					onClick={handleGoLive}
					type='button'
				>
					Go Live
				</button>
			)}
		</div>
	);
}

Header.defaultProps = {
	live: false,
	currentDate: new Date().toISOString().slice(0, 10),
	stockInAt: '',
	stockOutAt: '',
	idx: -2,
	selectedStoreName: '',
	listData: [],
	storeList: [],
	storeSelector: () => {},
	navigateIMS: () => {},
};

export default Header;
