import { endpoints } from '../Constants';
import { authHeader, handleResponse } from '../Helpers';

const similarProductsServices = {
	getGroups,
	createGroup,
	updateGroup,
};

function createGroup(groupName) {
	const sendData = {
		groupName,
	};

	const url = endpoints.SIMILAR_PRODUCT_CREATE_GROUP;

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify(sendData),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function updateGroup(isGroup, list2) {
	const url = endpoints.SIMILAR_PRODUCT_UPDATE_GROUP;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			gradeGroupId: isGroup,
			products: list2,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function getGroups() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(endpoints.SIMILAR_PRODUCT_GET_GROUPS, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data.data.map((group) => {
					return {
						groupId: group.id,
						groupName: group.groupName,
					};
				});
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch Groups: ');
		});
}

export default similarProductsServices;
