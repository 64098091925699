/* eslint-disable no-tabs */
// eslint-disable-next-line object-curly-newline
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import GeneralDetails from '../../../Components/Forms/Cooking Stations/GeneralDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { imageToBase64, inputHelper, isEmpty } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import CookingStationsConfig from './CookingStation.config';

const CookingStationCreatePage = (props) => {
	const { isEdit, saveStation, data, fetchStation, breadcrumbs } = props;

	const [state, setState] = useState({
		...CookingStationsConfig.initialState,
	});

	const { fields, errors } = state;
	const stationId = useParams().id;
	const history = useHistory();
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === stationId) {
				setState(CookingStationsConfig.serializer(state, data));
			} else {
				fetchStation({
					entity: 'COOKING_STATIONS',
					req: {
						params: {
							id: stationId,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(CookingStationsConfig.serializer(state, data));
			}
		}
	}, [data]);
	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};

	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};

	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'image':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.COOKING_STATIONS}/view/${stationId}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.COOKING_STATIONS}`);
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = CookingStationsConfig.deserializer(state);
		if (req.data.stationImageUrl) {
			Promise.all([imageToBase64(req.data.stationImageUrl)]).then(
				(values) => {
					[
						req.data.stationImageUrl,
						req.data.tradeMarkCertificateUrl,
					] = values;
					setNumberOfSubmissions(numberOfSubmissions + 1);
					if (isEdit) {
						req.params = {
							id: stationId,
						};
						saveStation({
							entity: 'COOKING_STATIONS',
							req,
							method: 'UPDATE',
							redirect: `${urls.EXTENSION}${urls.COOKING_STATIONS}`,
						});
					} else {
						saveStation({
							entity: 'COOKING_STATIONS',
							req,
							method: 'CREATE',
							redirect: `${urls.EXTENSION}${urls.COOKING_STATIONS}`,
						});
					}
				}
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message:
					'Please Upload cooking station image before submission',
			});
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={
						isEdit
							? 'Edit Cooking Station'
							: 'Create Cooking Station'
					}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit ? `Edit ${state.fields.name}` : 'Create New',
						},
					]}
					showBreadcrumbs
					handleCancel={handleCancel}
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={CookingStationsConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						handleImageInput={handleImageInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.COOKING_STATIONS.data.VIEW;
	// const data = CookingStationsConfig.api;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchStation: formActions.gateway,
	saveStation: formActions.gateway,
};

CookingStationCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingStationCreatePage);
