import styles from './Description.module.scss';

function DescriptionBox(props) {
	const { data } = props;
	return (
		<div className={`${styles.root}`}>
			{data.map((ele) => {
				return (
					<div className={`${styles.label_container}`}>
						<div>{ele.component}</div>
						<div>{ele.label}</div>
					</div>
				);
			})}
		</div>
	);
}

export default DescriptionBox;
