import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions } from '../../Actions';
import { FormSectionHeader } from '../../Components/Forms';
import { CreatePageHeader } from '../../Components/Headers';
import { inputHelper, isEmpty } from '../../Helpers';
import { urls } from '../../Constants';
// import { notificationServices } from '../../Services';
import VendorDetails from '../../Components/Forms/Vendors/VendorDetails';
import vendorConfig from './allvendor.config';

const VendorCreatePage = (props) => {
	// eslint-disable-next-line object-curly-newline
// const { isEdit, saveVendor, fetchVendor, data, limit, breadcrumbs } =
	const { isEdit, saveVendor, fetchVendor, data, breadcrumbs } =
		props;
	const [state, setState] = useState({
		...vendorConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const { id } = useParams();
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setState(vendorConfig.serializer(state, data));
			} else {
				fetchVendor({
					entity: 'ALL_VENDORS',
					req: {
						params: {
							id,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(vendorConfig.serializer(state, data));
			}
		}
	}, [data]);
	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'image':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		const newState = {};
		// for form validations and error generation
		// switch (fieldName) {

		// }
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
		});
	};

	const handleSave = (e) => {
		e.preventDefault();
		const isValid = vendorConfig.validator(state);
		if (isValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			vendorConfig.deserializer(state).then((req) => {
				if (isEdit) {
					req.params = {
						id,
					};
					saveVendor({
						entity: 'ALL_VENDORS',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.ALL_VENDORS}`,
					});
				} else {
					saveVendor({
						entity: 'ALL_VENDORS',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.ALL_VENDORS}`,
					});
				}
			});
		}
	};

	const history = useHistory();
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.ALL_VENDORS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.ALL_VENDORS}`);
		}
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Vendor' : 'Create Vendor'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<VendorDetails
						mandatoryFields={vendorConfig.mandatoryFields}
						fields={state.fields}
						handleFileInput={handleFileInput}
						handleImageInput={handleImageInput}
						handleInput={handleInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.ALL_VENDORS.data.VIEW;
	return {
		data,
	};
};
const mapDispatchToProps = {
	saveVendor: formActions.gateway,
	fetchProduct: formActions.gateway,
};
VendorCreatePage.defaultProps = {
	limit: 3,
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorCreatePage);
