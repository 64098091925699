/* eslint-disable no-shadow */
/* eslint-disable no-mixed-spaces-and-tabs */
import drawerConstants from '../Constants/drawer.constants';

const drawerActions = {
	toggleBillsDrawer,
	toggleTransactionDrawer,
	toggleFilterDrawer,
	toggleStockHistoryDrawer,
	toggleExportFiltersDrawer,
	toggleDebitCreditListDrawer,
	toggleProcessSettingsDrawer,
};

function toggleBillsDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_BILLS_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_BILLS_DRAWER,
		payload: data,
	};
}
function toggleTransactionDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_TRANSACTION_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_TRANSACTION_DRAWER,
		payload: data,
	};
}
function toggleFilterDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_FILTER_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_FILTER_DRAWER,
		payload: data,
	};
}
function toggleStockHistoryDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_STOCK_HISTORY_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_STOCK_HISTORY_DRAWER,
		payload: data,
	};
}

function toggleExportFiltersDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_EXPORT_FILTER_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_EXPORT_FILTER_DRAWER,
		payload: data,
	};
}
function toggleDebitCreditListDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_DEBIT_CREDIT_LIST_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_DEBIT_CREDIT_LIST_DRAWER,
		payload: data,
	};
}

function toggleProcessSettingsDrawer(show, data = {}) {
	if (show) {
		return {
			type: drawerConstants.SHOW_SETTINGS_DRAWER,
			payload: data,
		};
	}
	return {
		type: drawerConstants.HIDE_SETTINGS_DRAWER,
		payload: data,
	// eslint-disable-next-line padded-blocks
	};
}

export default drawerActions;
