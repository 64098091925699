import { Switch } from '@mui/material';
import { generateRandomKey } from '../../../../Helpers';
import styles from './SubscriptionVariantTable.module.scss';

const SubscriptionTable = (props) => {
	const { variants, isDisabled } = props;
	const handleInput = (e) => {
		props.handleInput(e);
	};
	return (
		<table className={`${styles['list-table-root']}`}>
			<thead>
				<tr>
					<th>Variant Name</th>
					<th>Price</th>
					<th>Enabled</th>
				</tr>
			</thead>
			<tbody>
				{variants &&
					variants.map((variant, idx) => {
						return (
							<tr>
								<td>
									<label>{variant.displayName}</label>
								</td>
								<td>
									<input
										type='number'
										name='subscriptionPrice'
										required
										value={variant.subscriptionPrice}
										onInput={handleInput}
										min={1}
										disabled={isDisabled}
										data-id={idx}
									/>
								</td>
								<td>
									<Switch
										name='subscriptionEnabled'
										onChange={handleInput}
										size='small'
										color='primary'
										defaultChecked={
											variant.subscriptionEnabled
										}
										disabled={isDisabled}
										inputProps={{
											'data-id': idx,
										}}
										value={variant.subscriptionEnabled}
										{...generateRandomKey()}
									/>
								</td>
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};
export default SubscriptionTable;
