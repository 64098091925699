/* eslint-disable no-undef */
import {
	Checkbox, NativeSelect, Switch
} from '@mui/material';
import { IconInput } from '../Inputs';

function ProductQualityConfig(props) {
	const { fields, handleInput, isDisabled } = props;
	return (
		<div>
			{/* QMS enabled */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Quality Management
				</label>
				<Switch
					name='isQualityEnabled'
					checked={fields?.isQualityEnabled}
					onChange={handleInput}
					disable={isDisabled}
					size='small'
					color='primary'
					inputProps={{
						'data-type': 'QMS',
					}}
				/>
			</div>
			{/* weight check */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Weight Check
				</label>
				<Switch
					name='weightCheck'
					checked={fields?.weightCheck}
					onChange={handleInput}
					disable={isDisabled}
					size='small'
					color='primary'
					inputProps={{
						'data-type': 'weight',
					}}
				/>
			</div>
			{/* temp check */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Temperature Check
				</label>
				<Switch
					name='tempCheck'
					checked={fields?.tempCheck}
					onChange={handleInput}
					disable={isDisabled}
					size='small'
					color='primary'
					inputProps={{
						'data-type': 'temperature',
					}}
				/>
			</div>

			{/* defect check */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Defect Check
				</label>
				<Switch
					name='defectCheck'
					checked={fields?.defectCheck}
					onChange={handleInput}
					disable={isDisabled}
					size='small'
					color='primary'
					inputProps={{
						'data-type': 'defect',
					}}
				/>
			</div>
			{/* defect params */}
			<div
				className='form-field row'
				style={{
					gap: '2rem',
				}}
			>
				{fields?.defectCheck && (
					<>
						<div className='flex items-center justify-start'>
							<label
								htmlFor='QMSEnabled'
								className='form-label'
								style={{
									margin: 'auto 0px',
									height: '20px',
									maxWidth: 'auto',
								}}
							>
								On the basis of Area
							</label>
							<Checkbox
								name='areaSelection'
								onChange={handleInput}
								checked={fields?.defectParams?.areaSelection}
								disable={isDisabled}
								size='small'
								color='primary'
								inputProps={{
									'data-type': 'defect',
								}}
							/>
						</div>
						<div className='flex items-center justify-start'>
							<label
								htmlFor='QMSEnabled'
								className='form-label'
								style={{
									maxWidth: 'auto',
									margin: 'auto 0px',
									height: '20px',
								}}
							>
								On the basis of Count
							</label>
							<Checkbox
								name='countSelection'
								onChange={handleInput}
								disable={isDisabled}
								checked={fields?.defectParams?.countSelection}
								size='small'
								color='primary'
								inputProps={{
									'data-type': 'defect',
								}}
							/>
						</div>
						<span
							className='form-info'
							style={{
								margin: 'auto 0px',
								padding: '0px 1rem',
							}}
						>
							(Select at least one criteria for defect based rejection)
						</span>
					</>
				)}
			</div>

			<fieldset
				className='form-fieldset'
				style={{
					display: 'flex',
					gap: '4rem',
					justifyContent: 'flex-start',
					alignItems: 'baseline',
				}}
			>
				{/* defect params */}
				{fields?.defectCheck && fields?.defectParams?.areaSelection && (
					<div
						className='form-field row'
						style={{
							width: '30%',
						}}
					>
						<label className='form-label' htmlFor='areaThreshold'>
							Area Threshold (%)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='%'
							handleInput={handleInput}
							value={fields?.defectParams?.areaThreshold}
							name='areaThreshold'
							id='areaThreshold'
							disable={isDisabled}
							dataAttrs={{
								min: 0,
								max: 100,
								'data-type': 'defect',
							}}
							step='0.1'
						/>
					</div>
				)}
				{fields?.defectCheck &&
					fields?.defectParams?.areaSelection &&
					fields?.defectParams?.countSelection && (
					<>
						<NativeSelect
							defaultValue={false}
							id='andCondition'
							name='andCondition'
							onChange={handleInput}
							disable={isDisabled}
							value={fields?.defectParams?.andCondition}
							inputProps={{
								'data-type': 'defect',
							}}
						>
							<option value={false}>OR</option>
							<option value>AND</option>
						</NativeSelect>
					</>
				)}
				{fields?.defectCheck && fields?.defectParams?.countSelection && (
					<div
						className='form-field row'
						style={{
							width: '30%',
						}}
					>
						<label className='form-label' htmlFor='countThreshold'>
							Count Threshold (nos)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='nos'
							name='countThreshold'
							handleInput={handleInput}
							disable={isDisabled}
							value={fields?.defectParams?.countThreshold}
							id='countThreshold'
							dataAttrs={{
								'data-type': 'defect',
							}}
							step='0.1'
						/>
					</div>
				)}
			</fieldset>
		</div>
	);
}
export default ProductQualityConfig;
