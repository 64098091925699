/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import packaging from 'src/Images/packaging.json';
import inwarding from 'src/Images/inwarding.json';
import dispatch from 'src/Images/dispatch.json';
import qr from 'src/Images/qr.json';
import { useEffect, useState } from 'react';
import { Button } from '@/Components/ui/button';
import { BaseModal } from '../BaseModal';
import { modalActions } from '@/Actions';

const AssetTransitModal = (props) => {
	const { show, data, toggleModal } = props;
	const [listData, setListData] = useState([]);
	const handleClose = () => {
		toggleModal(false);
	};

	useEffect(() => {
		if (data) {
			setListData(data?.data);
		}
	}, [data]);

	const renderBtns = () => {
		return (
			<div className='w-full flex justify-center gap-2'>
				<Button className='flex-1' variant='secondary' onClick={handleClose}>Close</Button>
			</div>
		);
	};

	return (
		<BaseModal
			heading={data?.heading}
			open={show}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='600px'
			height='600px'
		>
			<table>
				<tr>
					<th>Assets</th>
					<th>
						<div>
							<div>
								<Lottie
									style={{
										width: 70,
										height: 40,
									}}
									animationData={qr}
									loop
								/>
							</div>
							<p>Count</p>
						</div>
					</th>
					<th>
						<div>
							<div>
								<Lottie
									style={{
										width: 70,
										height: 40,
									}}
									animationData={packaging}
									loop
								/>
							</div>
							<p>Packging</p>
						</div>
					</th>
					<th>
						<div>
							<div>
								<Lottie
									style={{
										width: 100,
										height: 40,
									}}
									animationData={dispatch}
									loop
								/>
							</div>
							<p>Dispatch</p>
						</div>
					</th>
					<th>
						<div>
							<div>
								<Lottie
									style={{
										width: 100,
										height: 40,
									}}
									animationData={inwarding}
									loop
								/>
							</div>
							<p>Reciving</p>
						</div>
					</th>
				</tr>
				{listData?.map((ele) => {
					return (
						<tr>
							<td>{ele?.qrCode}</td>
							<td>{ele?.qrCount}</td>
							<td>
								{ele?.packaged ? <DoneIcon className='text-green-600' /> : <CloseIcon className='text-red-600' />}
							</td>
							<td>
								{ele?.scannedForDispatch ? <DoneIcon className='text-green-600' /> : <CloseIcon className='text-red-600' />}
							</td>
							<td>
								{ele?.scannedAtStore ? <DoneIcon className='text-green-600' /> : <CloseIcon className='text-red-600' />}
							</td>
						</tr>
					);
				})}
			</table>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.assetTransit;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAssetTransit,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetTransitModal);
