import { DropDownIconInput } from '../../Inputs';
import styles from './ExpirationDetails.module.scss';

const ExpirationDetails = (props) => {
	const { mandatoryFields, fields, errors, handleInput } = props;

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '100%',
				}}
			>
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='expires'
						data-mandatory={mandatoryFields.expires}
					>
						Expires In
					</label>
					<DropDownIconInput
						size='large'
						type='custom'
						iconText={fields.unit}
						name='expires'
						id='expires'
						value={fields.expires}
						handleInput={handleInput}
						dataType=''
						dataId=''
						fields={fields}
						dataAttrs={{
							required: mandatoryFields.expires,
						}}
					/>
					<span className='form-error'>{errors.expires}</span>
				</div>
			</div>
		</div>
	);
};

ExpirationDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

export default ExpirationDetails;
