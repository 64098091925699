import ClearIcon from '@mui/icons-material/Clear';
import styles from './Tag.module.scss';

const Tag = (props) => {
	const { text, dataAttrs, handleDelete, isDisabled } = props;

	return (
		<div
			className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}
			{...dataAttrs}
			onClick={handleDelete}
		>
			<ClearIcon className={styles.clear} />
			<span className={styles.text}>{text}</span>
		</div>
	);
};

Tag.defaultProps = {
	text: '',
	dataAttrs: {},
	handleDelete: () => {},
	isDisabled: false,
};

export default Tag;
