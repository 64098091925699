import { endpoints } from '../Constants';
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const orderTrackingServices = {
	fetchQRCode,
	fetchOrders,
	fetchTimeline,
	fetchPOID,
	fetchPreProduction,
};

function fetchOrders(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.TRACKING_ORDERS_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchQRCode(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.TRACKING_ORDER_QR_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchTimeline(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.TRACKING_PATH, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchPOID(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.TRACKING_POID, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchPreProduction(req = {}) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.TRACKING_PRE_PRODUCTION, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
export default orderTrackingServices;
