/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
import { ArrowCircleRight, Today } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { getISOWeek, getWeekOfMonth } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

import { connect } from 'react-redux';
import { analyticsAction, formActions } from '../../Actions';
import DateRangePickerRsuit from '../../Components/DateRangePickerRsuit/DateRangePickerRsuit';
import { BackdropLoader } from '../../Components/Loaders';
import { timeFilterMap } from '../../Constants';
import analyticsTypeConstant from '../../Constants/analyticsType.contants';
import { classes } from '../../Helpers';
import styles from './Dashboard.module.scss';
import DataTileContainer from './DataTileContainer/DataTileContainer';
import TwoColumnCharts from './TwoColumnCharts/TwoColumnCharts';
import downloadDashboardDataService from '../../Services/downloadDashboardData.services';
import { notificationServices } from '../../Services/Notification';
import { useAuth } from '../../Components/Auth/Login/auth';

const localStorageProperty = {
	daily: 'TODAY',
	weekly: 'THIS_WEEK',
	monthly: 'THIS_MONTH',
	custom: 'CUSTOM',
};
// function setLocalStorage(str) {
// localStorage.setItem('DASHBOARD_DATERANGE_STATE', str);
// }
// function getLocalStorage() {
// return localStorage.getItem('DASHBOARD_DATERANGE_STATE');
// }

let analyticType = analyticsTypeConstant.DASHBOARD_ANALYTICS;

function Dashboard(props) {
	const {
		applyFilters,
		setAnalyticsType,
		fetchProducts,
		dateRangeFilter,
		setDateRangeFilter,
		productsList,
		data,
		noStoreForAiDashboardRoute,
		isLoading,
		dateFilterData,
		fetchDashboardData,
		stores,
		storeFilter,
		setStoreFilter,
		fetchStores,
	} = props;
	const [periodFilter, setPeriodFilter] = useState(localStorageProperty.monthly);
	const [activeDateClick, setActiveDateClick] = useState(false);
	const [isLocalLoading, setIsLocalLoading] = useState(false);
	const [selectedValue, setSelectedValue] = useState(false);
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					brandId: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			setDateRangeFilter({
				...dateRangeFilter,
				active: true,
			});
		};
	}, []);

	useEffect(() => {
		fetchStores({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handlePeriodFilterChange = (fieldValue) => {
		let tempFilters = null;
		setDateRangeFilter({
			...dateRangeFilter,
			active: false,
		});
		switch (fieldValue) {
			case 'TODAY':
				// setLocalStorage(localStorageProperty.daily);
				setSelectedValue("Today's ");
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getDate(),
						active: true,
						type: 'day',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: getWeekOfMonth(new Date()),
							day: new Date().getDate(),
							hour: null,
						},
					},
				};
				break;
			case 'THIS_YEAR':
				// setLocalStorage(localStorageProperty.daily);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getFullYear(),
						active: true,
						type: 'year',
						selection: {
							year: new Date().getFullYear(),
							month: null,
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_WEEK':
				// setLocalStorage(localStorageProperty.weekly);
				setSelectedValue("This Week's ");
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getFullYear(),
						active: true,
						type: 'week',
						selection: {
							year: new Date().getFullYear(),
							month: null,
							week: getISOWeek(new Date()),
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_MONTH':
				// setLocalStorage(localStorageProperty.monthly);
				setSelectedValue("This Months's ");
				tempFilters = {
					DATE_FILTER: {
						text: timeFilterMap.month[new Date().getUTCMonth()],
						active: true,
						type: 'month',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'CUSTOM':
				// setLocalStorage(localStorageProperty.custom);
				setSelectedValue('Selected Date Range ');
				setDateRangeFilter({
					...dateRangeFilter,
					active: true,
				});
				setActiveDateClick(false);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getDate(),
						active: false,
						type: 'day',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: getWeekOfMonth(new Date()),
							day: new Date().getDate(),
							hour: null,
						},
					},
				};
				break;
			default:
				break;
		}
		setPeriodFilter(fieldValue);
		if (tempFilters) {
			applyFilters(tempFilters, false);
		}
	};
	useEffect(() => {
		if (dateFilterData?.active) {
			applyFilters({}, true);
		}
	}, [dateFilterData]);

	useEffect(() => {
		if (noStoreForAiDashboardRoute) {
			analyticType = analyticsTypeConstant.AI_DASHBOARD_ANALYTICS;
		}
		setAnalyticsType(analyticType, {}, false);
		setStoreFilter({
			active: true,
			selectedStores: [],
		});
		handlePeriodFilterChange(periodFilter);
	}, []);
	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		setActiveDateClick(true);
	};

	const fetchDateData = () => {
		fetchDashboardData();
		setActiveDateClick(false);
	};
	function handleDownload() {
		const request = {
			period: {
				endDate: data?.endDate,
				startDate: data?.startDate,
			},
		};
		if (storeFilter?.selectedStores?.length > 0) {
			request.store = storeFilter?.selectedStores?.[0];
		}
		(async () => {
			setIsLocalLoading(true);
			const downloadLinkData = await downloadDashboardDataService({
				req: {
					data: request,
				},
			});
			setIsLocalLoading(false);
			if (downloadLinkData?.responseCode === 'SS-001') {
				window.open(downloadLinkData?.data?.downloadLink, '_blank');
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some Error occured',
				});
			}
			// console.log(downloadLinkData);
		})();
	}

	const handleStoreSelection = (id) => {
		if (id === 'all') {
			setStoreFilter({
				active: true,
				selectedStores: [],
			});
		} else {
			setStoreFilter({
				active: true,
				selectedStores: [id],
			});
		}
		fetchDashboardData();
	};

	return (
		<div className={classes(styles.root)}>
			<BackdropLoader
				showProgress
				isLoading={isLoading || isLocalLoading}
			/>
			<div className={styles.tabs}>
				<div className={styles['date-tabs']}>
					<div
						className={classes(
							styles['link-button'],
							periodFilter === 'TODAY' && styles.selected
						)}
						style={
							periodFilter === 'TODAY'
								? {
									borderColor: '#ced4da',
								}
								: {}
						}
						onClick={() => {
							return handlePeriodFilterChange('TODAY');
						}}
					>
						<Today />
						DAILY
					</div>
					<div
						style={
							periodFilter === 'THIS_WEEK'
								? {
									borderColor: '#cbc0d3',
								}
								: {}
						}
						className={classes(
							styles['link-button'],
							periodFilter === 'THIS_WEEK' && styles.selected
						)}
						onClick={() => {
							return handlePeriodFilterChange('THIS_WEEK');
						}}
					>
						WEEKLY STATS
					</div>
					<div
						style={
							periodFilter === 'THIS_MONTH'
								? {
									borderColor: '#F8F4EA',
								}
								: {}
						}
						className={classes(
							styles['link-button'],
							periodFilter === 'THIS_MONTH' && styles.selected
						)}
						onClick={() => {
							return handlePeriodFilterChange('THIS_MONTH');
						}}
					>
						MONTHLY STATS
					</div>
					<div
						style={
							periodFilter === 'CUSTOM'
								? {
									borderColor: '#c3e7ff',
								}
								: {}
						}
						className={classes(
							styles['link-button'],
							periodFilter === 'CUSTOM' && styles.selected
						)}
						onClick={() => {
							return handlePeriodFilterChange('CUSTOM');
						}}
					>
						CUSTOM
					</div>
				</div>
				<div
					className={classes(
						styles.dateRangeDiv,
						periodFilter === 'CUSTOM' && styles.visibleAnimation
					)}
				>
					<DateRangePickerRsuit
						MAX={31}
						dateRanges={dateRangeFilterArray}
						onDateRangeChange={setDate}
						enableOnlyBackwardSelection
					/>
					<IconButton
						disabled={!activeDateClick}
						onClick={fetchDateData}
						className={classes(
							styles.dateButton,
							activeDateClick
								? styles.allowClick
								: styles.disableClick
						)}
					>
						<ArrowCircleRight fontSize='2rem' />
					</IconButton>
				</div>
			</div>
			<div
				className={classes(
					styles['data-box-container'],
					styles[periodFilter]
				)}
			>
				{stores?.length > 1 && !noStoreForAiDashboardRoute && (
					<div className={styles?.stores}>
						<div
							onClick={() => {
								return handleStoreSelection('all');
							}}
							className={classes(
								styles.store,
								storeFilter?.selectedStores?.length <= 0 &&
									styles.active
							)}
						>
							All
						</div>
						{stores?.map((store) => {
							const isSelected =
								storeFilter?.selectedStores?.includes(
									store?.id
								);
							return (
								<div
									onClick={() => {
										return handleStoreSelection(store?.id);
									}}
									className={classes(
										styles.store,
										isSelected && styles.active
									)}
								>
									{store?.name}
								</div>
							);
						})}
					</div>
				)}
				{!isLoading && (
					<div className={styles?.downloadDataButton}>
						<Tooltip title={data?.heading} arrow>
							<div
								onClick={() => {
									handleDownload();
								}}
								className={styles.downloadCompleteData}
							>
								Download <span> {selectedValue} </span> Data
								<DownloadIcon className={styles.icon} />
							</div>
						</Tooltip>
					</div>
				)}
				{data?.statsHolder?.map((ele) => {
					return (
						<DataTileContainer
							arrowType={ele?.arrow}
							data={ele}
							measurementType={measurement}
						/>
					);
				})}
				{data?.chartsHolder?.map((ele) => {
					return (
						<TwoColumnCharts
							productsList={productsList}
							data={ele}
						/>
					);
				})}
			</div>
		</div>
	);
}

const mapStateToProps = (state, componentProps) => {
	const { noStoreForAiDashboardRoute } = componentProps;
	const { data, isLoading } = noStoreForAiDashboardRoute
		? state?.analytics.AI_DASHBOARD_ANALYTICS
		: state.analytics.DASHBOARD_ANALYTICS;
	const productsList = state.form.ALL_PRODUCTS.data.LIST;
	const stores = state.form.STORES.data.LIST;
	const dateFilterData = state.analytics.DATE_FILTER;
	const { DATE_RANGE_FILTER: dateRangeFilter, STORE_FILTER: storeFilter } =
		state.analytics;

	return {
		data,
		isLoading,
		productsList,
		dateFilterData,
		dateRangeFilter,
		stores,
		storeFilter,
	};
};

const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	applyFilters: analyticsAction.applyFilters,
	resetFilters: analyticsAction.resetFilters,
	fetchProducts: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchDashboardData: analyticsAction.fetchData,
	setStoreFilter: analyticsAction.setStoreFilter,
	fetchStores: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
