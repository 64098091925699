import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { inputHelper } from '../../../../Helpers';
import ApplicableStoresModal from '../../../Modals/ApplicabilityModal/ApplicableStoresModal';
import styles from './applicability.module.scss';
import Tag from '../../../Tag/Tag';

const Stores = (props) => {
	const { fields, isDisabled, toggleApplicableStoresModal, handleConfirm } =
		props;
	const handleClick = () => {
		toggleApplicableStoresModal(true);
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		props.handleDelete(dataset.id, 'applicableStores', 'id');
	};
	const generateTags = (allTags, type) => {
		return allTags.map((tag) => {
			return (
				<Tag
					className={`${styles.tag}`}
					handleDelete={handleDelete}
					text={tag.name}
					isDisabled={isDisabled}
					dataAttrs={{
						'data-type': type,
						'data-id': tag.id,
						'data-name': 'applicableStores',
					}}
				/>
			);
		});
	};
	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}>
			<div className='form-field row'>
				<label className='form-label'>Applicable Stores</label>
				<div className={`${styles['tag-wrapper']}`}>
					{fields.applicableStores &&
						generateTags(
							fields.applicableStores,
							'applicableStores'
						)}
					{!isDisabled && (
						<div className={`${styles.add}`} onClick={handleClick}>
							<AddOutlinedIcon
								fontSize='medium'
								color='primary'
								dataAttrs={{
									'data-type': 'applicableStores',
									'data-id': Stores,
									'data-name': 'applicableStores',
								}}
							/>
						</div>
					)}
				</div>

				<ApplicableStoresModal
					handleConfirm={handleConfirm}
					selectedStoreList={fields.applicableStores}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { data, show } = state.modal.applicable_stores;
	return {
		show,
		data,
	};
};
ApplicableStoresModal.defaultProps = {
	show: false,
};

const mapDispatchToProps = {
	toggleApplicableStoresModal: modalActions.toggleApplicableStoresModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Stores);
