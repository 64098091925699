// import React from 'react';
import moment from 'moment';
import { useMemo } from 'react';
import { classes } from '../../Helpers';
import styles from './BatchBox.module.css';

function BatchBox(props) {
	const { header, data, onClick } = props;

	const getBgColor = useMemo(() => {
		if (data.completedAt) {
			return {
				backgroundColor: '#609966',
				color: '#fff',
			};
		}
		if (data.startedAt && !data.isCompleted) {
			return {
				backgroundColor: '#FFF5B8',
			};
		}
		return {
			backgroundColor: '#D6E4E5',
		};
	}, [data]);

	const batchDateTime = useMemo(() => {
		const splittedStartDate = data?.startedAt && data?.startedAt.split('GMT')[0];
		const splittedCompleteDate =
			data?.completedAt && data?.completedAt.split('GMT')[0];
		const startDate =
			data?.startedAt && moment(splittedStartDate).format('DD-MMM-YYYY');
		const startTime =
			data?.startedAt && moment(splittedStartDate).format('hh:mm a');
		const completeTime = data
			?.completedAt && moment(splittedCompleteDate)
			.format('hh:mm a');
		const duration = moment(splittedCompleteDate).diff(splittedStartDate, 'minutes');
		return {
			startDate,
			startTime,
			completeTime,
			duration,
		};
	}, [data]);

	return (
		<div className={classes(styles.root)} style={getBgColor} onClick={onClick}>
			<div className={styles['head-container']}>
				<p className={styles.header}>{header}</p>
				{data?.startedAt && (
					<p className={styles.date}>
						{batchDateTime?.startDate}
					</p>
				)}
			</div>
			{!data?.startedAt && (
				<p>
					Status: Not Started yet
				</p>
			)}
			{data?.startedAt && data?.completedAt && (
				<p className={styles.duration}>
					Duration:
					{' '}
					{batchDateTime?.duration}
					{' '}
					mins
				</p>
			)}
			{data?.startedAt && (
				<p>
					Started:
					{' '}
					{batchDateTime?.startTime}
				</p>
			)}
			{data?.completedAt && (
				<p>
					Completed:
					{' '}
					{batchDateTime?.completeTime}
				</p>
			)}
			{data?.currentRecipeWeight > 0 && (
				<p>
					Consumed Weight:
					{data?.currentRecipeWeight}
					g
					{' '}
				</p>
			)}
		</div>
	);
}

export default BatchBox;
