/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { SelectPicker } from 'rsuite';
import { formActions, modalActions } from '../../../Actions';
import { CreatePageHeader } from '../../../Components/Headers';
import { ImageCropModal } from '../../../Components/Modals/ImageCropModal';
import { urls } from '../../../Constants';
import { inputHelper, isEmpty } from '../../../Helpers';
// eslint-disable-next-line object-curly-newline
import ProductsConfig from './ProductSubCategory.config';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { notificationServices } from '../../../Services';
import { Label } from '@/Components/ui/label';

const ProductSubCategoryCreatePage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const {
		isEdit,
		saveProductSubCategory,
		data,
		fetchProductSubCategory,
		fetchProductCategory,
		breadcrumbs,
		toggleImageCrop,
		categoriesList,
	} = props;

	const [state, setState] = useState({
		...ProductsConfig.initialState,
		fields: {
			...ProductsConfig.initialState?.fields,
		},
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const ProductSubCategoryId = Number(useParams().id);
	const history = useHistory();
	const { fields, errors } = state;

	const fetchFoodCategoryFunc = async () => {
		await fetchProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	};

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === ProductSubCategoryId) {
				setState(
					ProductsConfig.serializer(state, {
						...data,
						measurement,
						selectedCategory: categoriesList?.find((ele) => {
							return ele?.id === data?.categoryId;
						}),
					})
				);
			} else {
				fetchProductSubCategory({
					entity: 'PRODUCT_SUB_CATEGORY',
					req: {
						params: {
							id: ProductSubCategoryId,
						},
					},
					method: 'VIEW',
				});
			}
		}
		fetchFoodCategoryFunc();
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(
					ProductsConfig.serializer(state, {
						...data,
						measurement,
					})
				);
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
			},
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = ProductsConfig.deserializer(state);
		setNumberOfSubmissions(numberOfSubmissions + 1);
		if (isEdit) {
			req.params = {
				id: ProductSubCategoryId,
			};
			saveProductSubCategory({
				entity: 'PRODUCT_SUB_CATEGORY',
				req,
				method: 'UPDATE',
				redirect: `${urls.EXTENSION}${urls.PRODUCT_SUB_CATEGORY}`,
			});
		} else {
			saveProductSubCategory({
				entity: 'PRODUCT_SUB_CATEGORY',
				req,
				method: 'CREATE',
				redirect: `${urls.EXTENSION}${urls.PRODUCT_SUB_CATEGORY}`,
			});
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.PRODUCT_SUB_CATEGORY}/view/${ProductSubCategoryId}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.PRODUCT_SUB_CATEGORY}`);
		}
	};

	const openImageCropModal = (name, file = null) => {
		// console.log('TESTTTT', name, file);
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		// console.log('FILEEE', file);
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleLogoInput = (fieldName, blob) => {
		toggleImageCrop(false, {});
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: blob,
			},
			...errors,
		});
	};

	const selectCategory = (value) => {
		setState({
			...state,
			fields: {
				...state.fields,
				selectedCategory: value,
			},
		});
	};

	return (
		<div className='root'>
			<ImageCropModal
				handleConfirm={handleLogoInput}
				handleClose={() => {
					toggleImageCrop(false, {});
				}}
			/>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={
						isEdit
							? 'Edit Product Category'
							: 'Create Product Category'
					}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `View ${state.fields.name}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				<div className='flex p-2'>
					<div className='flex flex-col gap-2'>
						<div className='flex gap-3 items-center'>
							<Label className=' min-w-[200px]'>Name*</Label>
							<input
								placeholder='Sub Category Name'
								name='name'
								value={fields?.name}
								onChange={handleInput}
								className='border border-slate-400 rounded-lg p-2'
							/>
						</div>
						<div className='flex gap-3 items-center'>
							<Label className=' min-w-[200px]'>
								Description
							</Label>
							<input
								placeholder='Sub Category description'
								name='description'
								value={fields?.description}
								onChange={handleInput}
								className='border border-slate-400 rounded-lg p-2'
							/>
						</div>
						<div className='flex gap-3 items-center'>
							<Label className=' min-w-[200px]'>
								Sub Category Code*
							</Label>
							<input
								placeholder='Sub Category Code'
								name='subCategoryCode'
								value={fields?.subCategoryCode}
								onChange={handleInput}
								className='border border-slate-400 rounded-lg p-2'
							/>
						</div>
						<div className='flex gap-3 items-center'>
							<Label className=' min-w-[200px]'>Category*</Label>
							<SelectPicker
								data={categoriesList?.map((ele) => {
									return {
										...ele,
										label: ele?.productCategoryName,
										value: ele,
									};
								})}
								style={{
									width: 224,
								}}
								placeholder='Select Category'
								onChange={selectCategory}
								renderMenuItem={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-user' />
											{' '}
											{_}
										</div>
									);
								}}
								renderMenuGroup={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-group' />
											{_}
										</div>
									);
								}}
								cleanable={false}
								value={fields?.selectedCategory}
								renderValue={(_, item) => {
									return (
										<div className='rsuite-select-picker-render-value'>
											<p>{item?.productCategoryName ?? 'Select Category'}</p>
										</div>
									);
								}}
								// onClean={() => {
								// console.log('heeloooo there');
								// }}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_SUB_CATEGORY.data.VIEW;
	const categoriesList = state.form.PRODUCT_CATEGORY.data.LIST;
	return {
		data,
		categoriesList,
	};
};
const mapDispatchToProps = {
	fetchProductSubCategory: formActions.gateway,
	fetchProductCategory: formActions.gateway,
	saveProductSubCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
	toggleImageCrop: modalActions.toggleImageCrop,
};
ProductSubCategoryCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductSubCategoryCreatePage);
