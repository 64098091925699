import MeasurementBox from './MeasurementBox';
import styles from './Measurement.module.css';
import { classes } from '../../../../Helpers';

function Measurement(props) {
	const { temp, weight, className } = props;

	return (
		<div className={classes(className, styles.root)}>
			{temp?.measurement?.[0]?.rawValue !== null && (
				<MeasurementBox temp measurements={temp} />
			)}
			<MeasurementBox weight measurements={weight} />
		</div>
	);
}

Measurement.defaultProps = {
	temp: {
		min: 0,
		max: 0,
		title: 'Temperature',
		measurement: [],
		result: null,
	},
	weight: {
		min: 0,
		max: 0,
		title: 'Weight',
		measurement: [],
		result: null,
	},
	className: '',
};

export default Measurement;
