/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
// import { get } from 'lodash';
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { ArrowDownward, ArrowUpward, Check, Visibility } from '@mui/icons-material';
// import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { classes } from '../../../Helpers';
// import { urls } from '../../../Constants';
import styles from './MySubscriptionsListTable.module.scss';
import analyticsAction from '../../../Actions/analytics.actions';
import { ListTable } from '../../../Components/ListTable';
import subscriptionsAnalyticsServices from '../../../Services/Analytics Services/subscriptions-analytics.services';
import { notificationServices } from '../../../Services';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	'Subs. ID': 'id',
	Status: 'status',
	Store: 'storeName',
	User: 'user',
	'Applicable Days': 'applicableDays',
	'Start Date': 'startDate',
	'End Date': 'endDate',
	'Student Details': 'studentDetails',
	Amount: 'orderValue',
};

const TABLE_COLUMNS = [
	{
		title: 'Subs. ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'User',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Applicable Days',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Start Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'End Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Student Details',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Previous Orders',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Items',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Cancel Subs.',
		config: {
			type: 'DATA',
		},
	},
];

const getRowStyles = (item) => {
	switch (item?.status) {
		case 'ACTIVE':
			return styles.active;
		case 'CANCELLED':
			return styles.critical;
		case 'EXPIRED':
			return styles.expired;
		default:
			return styles.normal;
	}
};

const MySubscriptionsListTable = (props) => {
	// const history = useHistory();
	const {
		data,
		setAnalyticsType,
		ANALYTICS_TYPE,
		toggleOrdersItemsModal,
		fetchData,
		status,
		fetchDataAfterSetting,
	} = props;
	// const listCount = data.length - 1;
	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {
			params: {
				status,
			},
		}, fetchDataAfterSetting);
	}, []);

	const handlePrevOrderView = async (subscriptionId) => {
		const req = {
			params: {
				subscriptionId,
			},
		};
		const headers = ['Order Id', 'Order Received At', 'Order Value'];
		try {
			const response =
				await subscriptionsAnalyticsServices.fetchPreviousOrders({
					req,
				});
			if (response?.statusCode === 200) {
				toggleOrdersItemsModal(true, {
					listData: response?.data,
					subscriptionId,
					heading: 'Previous Orders',
					headers,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
		}
	};

	const handleItemsView = async (subscriptionId) => {
		const req = {
			params: {
				subscriptionId,
			},
		};
		const headers = ['Product Name', 'Variant Name', 'Price'];
		try {
			const response = await subscriptionsAnalyticsServices.fetchItems({
				req,
			});
			if (response?.statusCode === 200) {
				toggleOrdersItemsModal(true, {
					listData: response?.data,
					subscriptionId,
					heading: `Items of Order: ${subscriptionId}`,
					headers,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
		}
	};

	const handleCancel = async (subscriptionId) => {
		const req = {
			data: {
				subscriptionId,
			},
		};
		try {
			const response =
				await subscriptionsAnalyticsServices.cancelSubscription({
					req,
				});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				fetchData();
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
		}
	};

	const SPECIAL_ITEMS = {
		'Previous Orders': (id) => {
			return (
				<span className={styles['brand-column']} onClick={() => { return handlePrevOrderView(id); }}>
					<Visibility />
				</span>
			);
		},
		Items: (id) => {
			return (
				<span
					className={styles['brand-column']}
					onClick={() => {
						return handleItemsView(id);
					}}
				>
					<Visibility />
				</span>
			);
		},
		'Cancel Subs.': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			if (item?.status !== 'ACTIVE') {
				return '-';
			}
			return (
				<Button
					onClick={() => {
						return handleCancel(id);
					}}
					variant='outlined'
					color='error'
				>
					Cancel
				</Button>
			);
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ListTable
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				data={data.map((obj) => {
					const rowStyles = getRowStyles(obj);
					return {
						...obj,
						rowStyles,
					};
				})}
				columns={TABLE_COLUMNS}
				actions={[...SPECIAL_COLUMNS]}
			/>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	fetchData: analyticsAction.fetchData,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MySubscriptionsListTable);
