import _ from 'lodash';

const transactionConfig = {
	initialState: {
		query: '',
		fields: {
			transactionData: [],
			metricsData: [
				{
					caption: 'Gross Amount',
					amount: 0,
				},
				{
					amount: 0,
					caption: 'Net Payment Receivable',
				},
				{
					amount: 0,
					caption: 'Payment Amount',
				},
				{
					amount: 0,
					caption: 'Platform Charges',
				},
				{
					amount: 0,
					caption: 'Refunds',
				},
				{
					amount: 0,
					caption: 'Net Amount',
				},
			],
		},
	},
	deserializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				transactionData: data.transactionData,
				metricsData: [
					{
						caption: 'Gross Amount',
						amount: data.metrics['Gross Amount'],
					},
					{
						caption: 'Net Payment Receivable',
						amount: data.metrics['Net Payment Receivable'],
					},
					{
						caption: 'Payment Amount',
						amount: data.metrics['Payment Amount'],
					},
					{
						caption: 'Platform Charges',
						amount: data.metrics['Platform Charges'],
					},
					{
						caption: 'Refunds',
						amount: data.metrics.Refunds,
					},
					{
						caption: 'Net Amount',
						amount: data.metrics['Net Amount'],
					},
				],
			};
			return newState;
		});
	},
};
export default transactionConfig;
