import _ from 'lodash';

/* eslint-disable no-param-reassign */
const UpdateAvailibiltyConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			categories: {
				items: {
					1: {
						name: 'Unnamed Category',
						description: '',

						subCategories: {
							items: {
								1: {
									name: 'Unnamed Subcategory',
									description: '',

									selectedProducts: [],
								},
							},
							order: [1],
						},
					},
				},
				order: [1],
			},
			MakeUnAvailable: {
				categories: [],
				subCategories: [],
				products: [],
			},
		},
		errors: {
			name: '',
			description: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: true,
		categories: [
			{
				name: true,
				description: true,
				subCategories: [
					{
						name: true,
						description: true,
						timeSlots: true,
						discount: true,
						isAddOn: true,
						Price: true,
					},
				],
			},
		],
	},
	serializer: (state, data, menuAvailability) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.menuName,
				description: data.menuDescription,
				categories: {
					items: data.menuCategories.reduce(
						(accCategory, currCategory, idxCategory) => {
							if (
								menuAvailability &&
								menuAvailability.categories.length > 0 &&
								menuAvailability.categories.includes(
									currCategory.categoryId
								)
							) {
								state.fields.MakeUnAvailable.categories.push(
									currCategory.categoryId
								);
							}
							accCategory[idxCategory + 1] = {
								name: currCategory.menuCategoryName,
								description:
									currCategory.menuCategoryDescription,
								id: currCategory.categoryId,
								subCategories: {
									items: currCategory.menuSubCategories.reduce(
										(
											accSubCategory,
											currSubCategory,
											idxSubCategory
										) => {
											if (
												menuAvailability &&
												menuAvailability.subCategories.length > 0 &&
												menuAvailability.subCategories.includes(
													currSubCategory.subCategoryId
												)
											) {
												state.fields.MakeUnAvailable.subCategories.push(
													currSubCategory.subCategoryId
												);
											}
											accSubCategory[idxSubCategory + 1] =
												{
													name: currSubCategory.menuSubCategoryName,
													description:
														currSubCategory.menuSubCategoryDescription,
													id: currSubCategory.subCategoryId,
													selectedProducts:
														currSubCategory.products.map(
															(product) => {
																if (
																	menuAvailability &&
																	menuAvailability.products.includes(
																		product.productId
																	)
																) {
																	state.fields.MakeUnAvailable.products.push(
																		product.productId
																	);
																}
																return {
																	productId:
																		product.productId,
																	discount:
																		product.discount,
																	isAddOn:
																		product.isAddOn ||
																		false,
																};
															}
														),
												};
											return accSubCategory;
										},
										{}
									),
									order: currCategory.menuSubCategories.map(
										(subCategory, idx) => {
											return idx + 1;
										}
									),
								},
							};
							return accCategory;
						},
						{}
					),
					order: data.menuCategories.map((category, idx) => {
						return idx + 1;
					}),
				},
				MakeUnAvailable: state.fields.MakeUnAvailable,
			};
			return newState;
		});
	},
};

export default UpdateAvailibiltyConfig;
