/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch } from '@mui/material';
import { formActions } from '@/Actions';
// import ListTable from '../../../Components/ListTable/ListTable';
import { handleResponseStreamDownload, inputHelper } from '../../../Helpers';
import VirtualizedListTable from '../../../Components/VirtualizedListTable/VirtualizedListTable';
import { notificationServices } from '@/Services';
import allProductServices from '@/Services/Form Services/allproduct.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Purchase Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category Name',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Veg',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Enabled',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	'Inventory Unit': 'unit',
	'Purchase Unit': 'purchaseUnit',
	Brand: 'brandName',
	'SKU Type': 'skuType',
	'Category Name': 'categoryName',
	Veg: 'isVeg',
	Enabled: 'isEnabled',
};
const AllProductListPage = (props) => {
	const {
		fetchProducts,
		toggleDisableProduct,
		data,
		listPageProps,
		query,
		deleteProduct,
		searchBy,
		userPermissions,
		navState,
		userList,
		fetchUserList,
	} = props;
	const { userConfig } = useAuth();
	// userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu['/products/all']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user Acces
	const handleDelete = ({ id }) => {
		deleteProduct({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	const handleEnable = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		toggleDisableProduct({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					isEnabled: fieldValue,
				},
			},
			method: 'PATCH',
		});
	};

	const SPECIAL_ITEMS = {
		Veg: (id, isVeg) => {
			return (
				<div
					className={`dot-container small ${
						isVeg ? 'veg' : 'non-veg'
					}`}
				>
					<div className='dot' />
				</div>
			);
		},
		Enabled: (id, isEnabled) => {
			return (
				<Switch
					name='enabled'
					value='isEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'ALL_PRODUCTS',
					}}
				/>
			);
		},
	};
	const handleExport = async () => {
		try {
			const response = await allProductServices.exportListProduct({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						...listPageProps?.filter?.req?.params,
						export: 'True',
					},
				},
				method: 'EXPORT_LIST',
			});
			if (response.status === 200) {
				handleResponseStreamDownload(response, 'ALL_PRODUCTS.csv');
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occurred',
			});
		}
	};

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					// skuType: 'PRODUCT',
					skuType: '',
					combo: true,
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<VirtualizedListTable
			colMap={COLMAP}
			columns={TABLE_COLUMNS}
			importState={{
				show: false,
			}}
			fixedHeaderTop={0}
			data={data
				.filter((product) => {
					return (
						product?.[searchBy?.get]
							?.toLowerCase()
							?.indexOf(query?.trim()?.toLowerCase()) !== -1
					);
				})
				.map((product) => {
					return {
						...product,
						_name: product?.[COLMAP?.Name],
					};
				})}
			actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
			special={SPECIAL_ITEMS}
			handleDelete={handleDelete}
			onExportClick={handleExport}
		/>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.ALL_PRODUCTS.data.LIST;
	const { userPermissions, navState } = state.navigation;
	const { userList } = state.auth;
	return {
		data,
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleDisableProduct: formActions.gateway,
	deleteProduct: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllProductListPage);
