import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import {
	ExpirationDetails,
	GeneralDetails,
	TrackingParameters,
	SellingParameters,
} from '../../../Components/Forms/Inventory Sku';
import InventoryParams from '../../../Components/Forms/Inventory Sku/Inventory Parameters/InventoryParams';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { imageToBase64, inputHelper, isEmpty } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import InventorySkuConfig from './inventorySku.config';

const generateDefaultOption = (type, brand, category) => {
	switch (type) {
		case 'brand':
			if (!brand || brand.length === 0) {
				return (
					<option value='' selected disabled hidden>
						Select a Brand
					</option>
				);
			}
			break;
		case 'category':
			if (!category || category.length === 0) {
				return (
					<option value='' selected disabled hidden>
						Select a Category
					</option>
				);
			}
			break;
		default:
			break;
	}
	return <></>;
};
const InventorySkuCreatePage = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		isEdit,
		data,
		fetchInventorySku,
		saveSKU,
		brands,
		category,
		breadcrumbs,
	} = props;
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const [state, setState] = useState({
		...InventorySkuConfig.initialState,
	});

	const { fields, errors } = state;
	const SkuId = useParams().id;
	const history = useHistory();

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === SkuId) {
				setState(InventorySkuConfig.serializer(state, data));
			} else {
				fetchInventorySku({
					entity: 'INVENTORY_SKU',
					req: {
						params: {
							id: SkuId,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(InventorySkuConfig.serializer(state, data));
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};

	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'image':
			case 'brandImageUrl':
			case 'tradeMarkCertificateUrl':
			case 'trademark':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.INVENTORY_SKU}/view/${SkuId}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.INVENTORY_SKU}`);
		}
	};
	const handleImageInput = (fieldName, base64Iamge) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return base64Iamge;
			})
		);
	};

	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = InventorySkuConfig.deserializer(state, brands, category);
		if (req.data.skuImageUrl) {
			Promise.all([imageToBase64(req.data.skuImageUrl)]).then(
				(values) => {
					[req.data.skuImageUrl] = values;
					setNumberOfSubmissions(numberOfSubmissions + 1);
					if (isEdit) {
						req.params = {
							id: SkuId,
						};
						saveSKU({
							entity: 'INVENTORY_SKU',
							req,
							method: 'UPDATE',
							redirect: `${urls.EXTENSION}${urls.INVENTORY_SKU}`,
						});
					} else {
						saveSKU({
							entity: 'INVENTORY_SKU',
							req,
							method: 'CREATE',
							redirect: `${urls.EXTENSION}${urls.INVENTORY_SKU}`,
						});
					}
				}
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Upload SKU Image before submission',
			});
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={
						isEdit ? 'Edit Inventory Sku' : 'Create Inventory Sku'
					}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.skuName}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={InventorySkuConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
						generateDefaultOption={generateDefaultOption}
						handleImageInput={handleImageInput}
					/>
				</FormSectionHeader>

				{/* InventoryParams Enabled */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Quality Management Parameters'
				>
					<InventoryParams
						mandatoryFields={InventorySkuConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
				{/* Inventory Management Enabled */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Tracking Parameters'
				>
					<TrackingParameters
						mandatoryFields={InventorySkuConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
				{/* Inventory Management Enabled */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Selling Parameters'
				>
					<SellingParameters
						mandatoryFields={InventorySkuConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
				{/* Inventory Management Enabled */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Expiration Details'
				>
					<ExpirationDetails
						mandatoryFields={InventorySkuConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_SKU.data.VIEW;
	const { brands } = state.brand;
	const category = state.form.INVENTORY_CATEGORY.data.LIST;
	return {
		data,
		brands,
		category,
	};
};

const mapDispatchToProps = {
	fetchInventorySku: formActions.gateway,
	saveSKU: formActions.gateway,
};

InventorySkuCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventorySkuCreatePage);
