import Label from '../LabelGeneratorLabel/Label';
import SavedComponents from '../LabelGeneratorSavedComponents/SavedComponents';
import SavedLabels from '../LabelGeneratorSavedLabels/SavedLabels';
import PreviewLabel from '../LabelGeneratorPreviewLabel/PreviewLabel';

function Screen(props) {
	let content;
	if (props?.savedLabelsClicked) {
		content = (
			<div
				id='screen'
			>
				<SavedLabels
					savedLabels={props?.savedLabels}
					setSavedLabels={props?.setSavedLabels}
					setLabelComponentsState={props?.setLabelComponentsState}
					setBackButtonClicked={props?.setBackButtonClicked}
					labelDetails={props?.labelDetails}
					setLabelDetails={props?.setLabelDetails}
					handleBackButtonApp={props?.handleBackButtonApp}
					setIsLoading={props?.setIsLoading}
					setLabelType={props?.setLabelType}
				/>
			</div>
		);
	} else if (props?.myComponentsClicked) {
		content = (
			<div
				id='screen'
			>
				<SavedComponents
					labelComponentsState={props?.labelComponentsState}
					setLabelComponentsState={props?.setLabelComponentsState}
				/>
			</div>
		);
	} else if (props?.previewLabelClicked) {
		content = (
			<div
				id='screen'
			>
				<PreviewLabel
					imageSrc={props?.imageSrc}
					height={props?.labelDetails.size.height}
					width={props?.labelDetails.size.width}
				/>
			</div>
		);
	} else {
		content = (
			<div id='screen'>
				<div id='label-screen'>
					<Label
						tool={props.tool}
						backButtonClicked={props.backButtonClicked}
						saveButtonClicked={props.saveButtonClicked}
						setSaveButtonClicked={props?.setSaveButtonClicked}
						labelComponentsState={props?.labelComponentsState}
						setLabelComponentsState={props?.setLabelComponentsState}
						currentLabelComponents={props?.currentLabelComponents}
						setCurrentLabelComponents={props?.setCurrentLabelComponents}
						labelDetails={props?.labelDetails}
						setLabelDetails={props?.setLabelDetails}
						editComponentTool={(id) => {
							props?.editComponentTool(id);
						}}
						setBackButtonClicked={props?.setBackButtonClicked}
						handleSelectedComponent={(currElement) => {
							return props?.handleSelectedComponent(currElement);
						}}
						selectedComponent={props?.selectedComponent}
						setSelectedComponent={props?.setSelectedComponent}
						currentComponent={props?.currentComponent}
						setCurrentComponent={props?.setCurrentComponent}
						selectedToolComponent={props?.selectedToolComponent}
					/>
				</div>
			</div>
		);
	}
	return content;
}
export default Screen;
