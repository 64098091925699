import { Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Label',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Applicable On',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Is',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Brand: 'brandName',
	Label: 'label',
	Type: 'id',
	'Applicable On': 'LabelsMapping',
	'Product Is': 'productName',
	Category: 'Brand.ProductCategory[0].productCategoryName',
	Veg: 'isVeg',
	Enabled: 'isEnabled',
};

const DefectsListPage = (props) => {
	const { data, query, searchBy, fetchDefects, deleteDefect } = props;

	useEffect(() => {
		fetchDefects({
			entity: 'DEFECTS',
			req: {
				params: {},
			},
			method: 'LIST',
		});
	}, []);

	const SPECIAL_ITEMS = {
		Type: (id) => {
			const currentRow = data?.find((ele) => {
				return ele?.id === id;
			});
			if (currentRow?.type === 'IDENTITY' && currentRow?.productId === null) {
				return 'COMPONENT';
			}
			return currentRow?.type;
		},
		'Applicable On': (altProps) => {
			const sliceBy = 20;
			// eslint-disable-next-line react/destructuring-assignment
			const products = altProps.map((item) => {
				return item.productName;
			});
			return (
				<div>
					{products.toString().length >= sliceBy ? (
						<Tooltip title={products.toString()}>
							<span>
								{`${products.toString().slice(0, sliceBy)}...`}
							</span>
						</Tooltip>
					) : (
						<span>{products}</span>
					)}
				</div>
			);
		},
	};

	const handleDelete = ({ id }) => {
		deleteDefect({
			entity: 'DEFECTS',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	return (
		<div className='list-page-root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.filter((defect) => {
						return (
							defect?.[searchBy?.get]
								?.toLowerCase()
								?.indexOf(query?.trim()?.toLowerCase()) !== -1
						);
					})
					.map((defect) => {
						return {
							...defect,
							_name: defect?.[COLMAP?.Label],
						};
					})}
				special={SPECIAL_ITEMS}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.DEFECTS.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchDefects: formActions.gateway,
	deleteDefect: formActions.gateway,
	toggleDisableCombo: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectsListPage);
