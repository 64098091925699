import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const productSubCategoryServcies = {
	gateway,
	createProductSubCategory,
	viewProductSubCategory,
	listProductSubCategory,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createProductSubCategory({
				req,
			});
		case 'VIEW':
			return viewProductSubCategory({
				req,
			});
		case 'LIST':
			return listProductSubCategory({
				req,
			});
		case 'DELETE':
			return deleteProductSubCategory({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function deleteProductSubCategory({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PRODUCT_SUB_CATEGORY, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function createProductSubCategory({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.PRODUCT_SUB_CATEGORY, requestOptions).then(
		handleResponse
	);
}

function viewProductSubCategory({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_SUB_CATEGORY, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listProductSubCategory({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_SUB_CATEGORY, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default productSubCategoryServcies;
