/* eslint-disable no-console */
import { authServices } from '@/Services';
import { authConstants } from '../Constants';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';

const authActions = {
	toggleLogout,
	toggleLoading,
	fetchStaticEnums,
	fetchUserList,
};

function toggleLogout(isLogout) {
	return {
		type: authConstants.SET_LOGOUT,
		payload: isLogout,
	};
}

function toggleLoading(isLoading) {
	return {
		type: authConstants.SET_LOADING,
		payload: isLoading,
	};
}

function fetchStaticEnums() {
	return async (dispatch) => {
		try {
			const response = await authServices.fetchStaticEnums();
			if (response?.statusCode === 200) {
				dispatch({
					type: authConstants.SET_STATIC_ENUMS,
					payload: response?.data,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};
}

function fetchUserList() {
	return async (dispatch) => {
		try {
			const response = await userRolesAndPermissions.getUserRoles();
			dispatch({
				type: authConstants.SET_USER_LIST,
				payload: response,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export default authActions;
