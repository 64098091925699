/* eslint-disable */
import { Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { connect } from 'react-redux';
import { modalActions } from '../../../../../Actions';
import {
	generateDisabledProps,
	generateRequiredProps,
	inputHelper,
	isEmpty,
} from '../../../../../Helpers';
// import DraggableVTabs from '../../../../Draggable/DraggableVTabs/DraggableVTabs';
import EnableDisable from '../../../EnableDisable/EnableDisable';
import { RecipeProcess } from '../RecipeProcess';
import styles from './RecipeStage.module.scss';
import { StageDependencies } from './StageDependencies';
import { modalTypes } from '../../../../../Constants';
import { DraggableHTabs } from '@/Components/Draggable';
import TimeRequired from '@/Components/Cooking/TimeRequired/TimeRequired';
import { useMemo } from 'react';
import CustomInput from '@/Components/CustomInput/CustomInput';
import { IconInput } from '@/Components/Forms/Inputs';

const generateDefaultOption = (type, value) => {
	switch (type) {
		case 'station':
			if (!value || value.length === 0) {
				return (
					<option value='' selected disabled hidden>
						Select a Cooking Station
					</option>
				);
			}
			break;
		default:
			break;
	}
	return <></>;
};

const RecipeStage = (props) => {
	const {
		isDisabled,
		handleInput,
		handleDelete,
		activeStage,
		stageDetails,
		mandatoryFields,
		errors,
		activeProcess,
		setActiveProcess,
		handleProcessReOrdering,
		addNewProcess,
		stations,
		stages,
		allVariants,
		defaultVariant,
		toggleExportProcess,
		handleRecipeDetailsInput,
		handleBatchSize,
	} = props;

	if (isEmpty(stageDetails)) {
		return <></>;
	}

	const addDependencies = (dependencies) => {
		props.addDependencies(activeStage, dependencies);
	};

	const removeDependency = (dependency) => {
		props.removeDependency(activeStage, dependency);
	};

	const openExportProcess = (e) => {
		const { dataset } = inputHelper(e);
		toggleExportProcess(true, {
			stages,
			from: stageDetails,
			processId: dataset.id,
		});
	};

	const totalRecipeCookingTime = useMemo(() => {
		let totalTimeRequired = 0;
		Object.values(stageDetails?.processes?.items)?.forEach(
			(processValue) => {
				totalTimeRequired += Number(processValue?.time ?? 0);
			}
		);
		return totalTimeRequired;
	}, [stageDetails]);

	return (
		<div className={styles.root}>
			<fieldset
				disabled={isDisabled}
				className='form-fieldset flex flex-col gap-4'
			>
				<div className='icon_container absolute top-[-1.5rem] right-0 px-2 rounded-full bg-gray-300 flex gap-3'>
					{/* delete stage button */}
					{!isDisabled && activeStage != 1 && (
						<Tooltip title='Delete Stage' placement='left-end'>
							<Delete
								className={`${styles['delete-icon']}`}
								onClick={handleDelete}
								data-type='stage'
								data-id={activeStage}
							/>
						</Tooltip>
					)}
				</div>
				<div className='w-full'>
					<div className='flex flex-col gap-1 flex-1'>
						<div className='text-xs'>Default Variant</div>
						<div className='p-2 rounded-xl w-fit border-b-2 border-slate-400'>
							{`${
								stageDetails?.stageProduct?.defaultVariant
									?.description
							}(${(
								(stageDetails?.stageProduct?.defaultVariant
									?.maxWeight -
									stageDetails?.stageProduct?.defaultVariant
										?.minWeight) /
								2
							).toFixed(0)}g)`}
						</div>
					</div>
				</div>
				<div className='w-full flex gap-2 items-end justify-between'>
					{/* instructions */}
					{/* <div className='flex flex-col gap-1 flex-1'>
						<label className='text-xs'>
							Preparation Date delta
						</label>
						<CustomInput
							name='scheduledAtDelta'
							type='number'
							step={1}
							min={-1}
							max={1}
							value={stageDetails?.recipeData?.scheduledAtDelta}
							onChangeHandler={handleRecipeDetailsInput}
							className='w-full'
							inputClassName={`p-2 rounded-xl w-full ${
								isDisabled
									? 'border-b-2 border-slate-400'
									: 'bg-white border border-black'
							}`}
							requiredProps={{
								'data-type': 'stage',
							}}
						/>
					</div> */}
					{/*  is Assembly (bulk) */}
					<div className='flex flex-col gap-1 flex-1'>
						{isDisabled && (
							<label className='text-xs' htmlFor='isBulk'>Non Assembly?</label>
						)}
						<div className='p-2 rounded-xl w-full bg-white'>
							{isDisabled ? (
								<>
									{stageDetails?.recipeData?.isBulk ? (
										<>
											<div>yes</div>
										</>
									) : (
										<>
											<div>no</div>
										</>
									)}
								</>
							) : (
								<div className='flex items-center gap-2'>
									<input
										style={{
											width: '30px',
											display: 'flex',
											justifyItems: 'flex-start',
											justifyContent: 'flex-start',
											transform: 'scale(1.3)',
										}}
										className='form-value border border-black'
										type='checkbox'
										id='isBulk'
										name='isBulk'
										// onInput={handleInput}
										onChange={handleRecipeDetailsInput}
										checked={
											stageDetails?.recipeData?.isBulk
										}
										data-type='isBulk'
									/>
									<p>Non Assembly?</p>
								</div>
							)}
						</div>
					</div>
					<div className='flex flex-col gap-1 flex-1'>
						<label
							className='text-xs'
							htmlFor='weightTolerance'
							data-mandatory={mandatoryFields.recipeLink}
						>
							Weight tolerance
						</label>
						<IconInput
							size='custom'
							type='percent'
							name='weightTolerance'
							id='weightTolerance'
							iconWrapperClass='!bg-slate-400 !rounded-l-lg !w-[50px]'
							value={stageDetails?.recipeData?.weightTolerance}
							handleInput={handleRecipeDetailsInput}
							min={0.001}
							step={0.001}
							max={100}
							className='!w-full !border !border-black !rounded-lg !p-2 !pl-[60px]'
							style={{
								width: '100%',
								borderRadius: '12px',
								height: 'fit-content',
							}}
							dataAttrs={{
								required: mandatoryFields.weightTolerance,
							}}
						/>
					</div>
				</div>
				<div className='w-full'>
					<div className='flex flex-col gap-1 flex-1'>
						<label className='text-xs'>
							Serving Quantity (Variants Wise) -
							{stageDetails?.stageProduct?.unit?.toUpperCase()}
						</label>
						<div
							className={`flex gap-2 items-center w-full bg-white overflow-auto rounded-lg`}
						>
							{stageDetails?.stageProduct?.variants?.map(
								(ele) => {
									return (
										<div className='flex flex-col items-center min-w-[125px] p-1 rounded-xl w-fit border-b-2 border-slate-400 bg-slate-200'>
											<p className='text-xs m-0'>
												{ele?.displayName}
											</p>
											<p className='m-0 border-t border-slate-500 w-full text-center'>
												{ele?.servingQtyPercentage}%
											</p>
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
				<div className='w-full'>
					<div className='flex flex-col gap-1 flex-1'>
						<label className='text-xs'>
							Batch Size (Variants Wise)*
						</label>
						<div
							className={`flex gap-2 items-center w-full bg-white overflow-auto rounded-lg`}
						>
							{stageDetails?.stageProduct?.variants?.map(
								(ele) => {
									 const batchSizeValue =
											stageDetails?.recipeData
												?.batchSize?.[
												ele?.id?.toString()
											] ?? '';
									return (
										<div className='flex flex-col rounded-lg border border-slate-800 items-center'>
											<p className='text-sm bg-green-500 w-full rounded-t-lg text-center'>
												{ele?.displayName}:
											</p>
											<CustomInput
												name='batchSize'
												type='number'
												text={stageDetails?.stageProduct?.unit?.toUpperCase()}
												value={batchSizeValue}
												onChangeHandler={(e) => {
													handleBatchSize(e, ele?.id);
												}}
												className='w-full border-t border-black flex items-center rounded-b-lg'
												inputClassName='border-r border-black outline-none rounded-bl-lg pl-2'
												requiredProps={{
													'data-type': 'stage',
													...generateRequiredProps(
														mandatoryFields.batchSize
													),
												}}
											/>
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
				<div className='w-full flex gap-2 items-start justify-between'>
					{/* stage name
					<div className='form-field flex flex-col gap-1 flex-1'>
						<label
							className='text-xs'
							htmlFor='stageName'
							data-mandatory={mandatoryFields.stages.name}
						>
							Process Name
						</label>
						<input
							className='p-2 border-b-2 border-slate-400 rounded-xl w-full'
							type='text'
							id='stageName'
							name='name'
							onInput={handleInput}
							value={stageDetails.name}
							data-type='stage'
							data-id={activeStage}
							disabled={activeStage == 1}
							{...generateRequiredProps(
								mandatoryFields.stages.name
							)}
						/>
					</div> */}
					{/* station name */}
					<div className='flex flex-col gap-1 flex-1'>
						<label
							className='text-xs'
							htmlFor='station'
							data-mandatory={mandatoryFields.stages.station}
						>
							Station
						</label>
						<select
							key={activeStage}
							className={`p-2 rounded-xl w-full ${
								isDisabled
									? 'border-b-2 border-slate-400'
									: 'bg-white border border-black'
							}`}
							id='station'
							name='station'
							onChange={handleInput}
							value={stageDetails.station}
							{...generateRequiredProps(
								mandatoryFields.stages.station
							)}
							data-type='stage'
							data-id={activeStage}
						>
							{generateDefaultOption(
								'station',
								stageDetails.station
							)}
							{stations
								.filter((station) => {
									return station.isEnabled;
								})
								.map((station) => {
									return (
										<option value={station.id}>
											{station.stationName}
										</option>
									);
								})}
						</select>
						<span className='form-error'>{errors.station}</span>
					</div>
					{/* instructions */}
					<div className='flex flex-col gap-1 flex-1'>
						<label
							className='text-xs'
							htmlFor='stageInstructions'
							data-mandatory={mandatoryFields.stages.instructions}
						>
							Instructions to the chef
						</label>
						<textarea
							className={`p-2 rounded-xl w-full ${
								isDisabled
									? 'border-b-2 border-slate-400 resize-none'
									: 'bg-white border border-black'
							}`}
							id='stageInstructions'
							name='instructions'
							onInput={handleInput}
							value={stageDetails.instructions}
							data-type='stage'
							data-id={activeStage}
							{...generateRequiredProps(
								mandatoryFields.stages.instructions
							)}
						/>
					</div>
				</div>
				{/* dependencies */}
				<EnableDisable
					name='isDependent'
					handleIsEnabled={handleInput}
					dataAttrs={{
						'data-type': 'stage',
						'data-id': activeStage,
					}}
					isEnabled={stageDetails.isDependent}
					text='Is this Stage Dependent?'
					className={`${styles['enable-disable']}`}
					{...generateDisabledProps(stageDetails.isDependent)}
					checkBoxShow={!isDisabled}
				>
					<StageDependencies
						stages={stages}
						activeStage={activeStage}
						addDependencies={addDependencies}
						removeDependency={removeDependency}
						isDisabled={isDisabled}
					/>
				</EnableDisable>
			</fieldset>
			{/* recipe processes */}
			{/* {isDisabled && (
			)} */}
			<div className='flex items-center justify-between'>
				<p className='text-xl mt-3 mb-1'>Cooking Steps</p>
				<TimeRequired
					heading='Total Process Time'
					totalRequiredTime={totalRecipeCookingTime}
				/>
			</div>
			<div className='flex flex-col gap-2'>
				<div className={styles['tabs-wrapper']}>
					<DraggableHTabs
						handleReorder={handleProcessReOrdering}
						items={stageDetails.processes.items}
						order={stageDetails.processes.order}
						handleActiveTab={setActiveProcess}
						handleAddTab={addNewProcess}
						activeTab={activeProcess}
						limit={15}
						text='Step'
						isDisabled={isDisabled}
						isDraggable={!isDisabled}
					/>
				</div>
				<div className={styles['process-wrapper']}>
					<RecipeProcess
						isDisabled={isDisabled}
						handleInput={handleInput}
						allVariants={allVariants}
						handleDelete={handleDelete}
						activeProcess={activeProcess}
						processDetails={
							stageDetails.processes.items[activeProcess] ?? {}
						}
						mandatoryFields={mandatoryFields}
						defaultVariant={defaultVariant}
						openExportProcess={openExportProcess}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const stations = state.form.COOKING_STATIONS.data.LIST;
	return {
		stations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleExportProcess: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(
					modalTypes.EXPORT_COOKING_PROCESS,
					show,
					data
				)
			);
		},
	};
};

RecipeStage.defaultProps = {
	errors: {},
	handleInput: () => {},
	handleBatchSize: () => {},
	handleRecipeDetailsInput: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeStage);
