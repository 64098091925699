import { useState } from 'react';
import { connect } from 'react-redux';
import { Crop } from '@mui/icons-material';
import { modalActions } from '../../../../Actions';

import {
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper,
} from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import DownloadPP from './DownloadPP';
import DownloadTOS from './DownloadTOS';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		handleFileInput,
		isDisabled,
		toggleImageCrop,
	} = props;
	const [showLogoCropModal, setShowLogoCropModal] = useState(false);

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(false, {
			name,
			file,
		});
		setShowLogoCropModal(true);
	};

	const handleLogoInput = (fieldName, blob) => {
		setShowLogoCropModal(false);
		props.handleLogoInput(fieldName, blob);
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};
	const renderTos = () => {
		if (typeof fields.TOS === 'string') {
			return <DownloadTOS TOS={fields.TOS} />;
		}
		if (!fields.TOS) return 'NO FILE UPLOADED';
		return fields.TOS.name;
	};
	const renderPp = () => {
		if (typeof fields.PP === 'string') {
			return <DownloadPP PP={fields.PP} />;
		}
		if (!fields.PP) return 'NO FILE UPLOADED';
		return fields.PP.name;
	};
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='brandName'
						data-mandatory={mandatoryFields.name}
					>
						Name
					</label>
					<input
						className='form-value'
						type='text'
						id='brandName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				{/* TOS */}
				<div className='form-field row'>
					<label className='form-label' htmlFor='TOS'>
						Terms of Service
					</label>
					<div
						className='form-value'
						style={{
							display: 'flex',
						}}
					>
						<div
							style={{
								border: '1px solid midgrey',
								padding: '5px',
								marginRight: '10px',
								marginLeft: '10px',
								borderRadius: '2px',
							}}
						>
							{renderTos()}
						</div>
						{isDisabled ? (
							<></>
						) : (
							<label
								className={styles['tnc-label']}
								htmlFor='TOS'
							>
								Upload
							</label>
						)}
						<input
							type='file'
							id='TOS'
							name='TOS'
							accept='application/pdf'
							onInput={handleFileInput}
							{...generateRequiredProps(
								typeof fields.TOS !== 'string' &&
									mandatoryFields.TOS
							)}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
					</div>

					<span className='form-error'>{errors.TOS}</span>
				</div>
				{/* PP */}
				<div className='form-field row'>
					<label className='form-label' htmlFor='PP'>
						Privacy Policy
					</label>
					<div
						className='form-value'
						style={{
							display: 'flex',
						}}
					>
						<div
							style={{
								border: '1px solid midgrey',
								padding: '5px',
								marginRight: '10px',
								marginLeft: '10px',
								borderRadius: '2px',
							}}
						>
							{renderPp()}
						</div>
						{isDisabled ? (
							<></>
						) : (
							<label className={styles['tnc-label']} htmlFor='PP'>
								Upload
							</label>
						)}
						<input
							className='form-value'
							type='file'
							id='PP'
							name='PP'
							accept='application/pdf'
							onInput={handleFileInput}
							{...generateRequiredProps(
								typeof fields.PP !== 'string' &&
									mandatoryFields.PP
							)}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
					</div>

					<span className='form-error'>{errors.PP}</span>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* display logo */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.image}
					>
						Display Image
					</label>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={
									isDisabled
										? fields.logo
										: generateImageUrl(fields.logo)
								}
								className={`display-pic ${
									fields.logo ? 'show' : 'hide'
								}`}
								alt='brand-display'
							/>
							{generateImageEditButton({
								isEdit:
									fields.logo &&
									fields.logo.includes(
										'data:image/jpeg;base64'
									),
								button: (
									<button
										type='button'
										onClick={() => {
											openImageCropModal('logo');
										}}
										className='display-pic-edit'
									>
										<Crop />
									</button>
								),
							})}
						</div>
						{generateImageUploadButton({
							isDisabled,
							label: (
								<label
									className='display-pic-label'
									htmlFor='displayPic'
								>
									Choose a Logo
								</label>
							),
						})}
						<input
							type='file'
							id='displayPic'
							accept='image/png, image/jpg, image/jpeg, image/webp'
							name='logo'
							onChange={handleImageSelect}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
					</div>
				</div>
				<ImageCropModal
					localShow={showLogoCropModal}
					handleConfirm={handleLogoInput}
					handleClose={() => {
						setShowLogoCropModal(false);
					}}
				/>
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	isEdit: false,
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = () => {};

const mapDispatchToProps = {
	toggleImageCrop: modalActions.toggleImageCrop,
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
