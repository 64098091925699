import { connect } from 'react-redux';
import {
	useCallback, useEffect, useMemo, useState
} from 'react';
// import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { formActions, modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../Services';
import { inputHelper } from '../../../Helpers';
import { ListModal } from '../ListModal';
import StepWiseActionComponent from '../../StepWiseActionComponent/StepWiseActionComponent';
import AuditStep1 from './AuditStep1/AuditStep1';
import AuditStep2 from './AuditStep2/AuditStep2';
import AuditStep3 from './AuditStep3/AuditStep3';
import AuditStep4 from './AuditStep4/AuditStep4';
import styles from './AuditCreationModal.module.scss';
import regionStateServices from '../../../Services/regionState.services';

const regions = [
	{
		label: 'All Region',
		color: '#94a3b8',
	},
	{
		label: 'Region',
		color: '#facc15',
	},
	{
		label: 'State',
		color: '#008080',
	},
	{
		label: 'City',
		color: '#FF7F50',
	},
	{
		label: 'Store',
		color: '#2dd4bf',
	},
];

const AuditCreationModal = (props) => {
	const {
		show,
		toggleModal,
		data,
		handleConfirm,
		productCategoriesList,
		productsList,
		stores,
		toggleListModal,
		fetchStoresList,
		// fetchProducts,
		fetchProductCategoriesList,
		permittedStores,
	} = props;
	const { heading = 'Create New Audit' } = data;
	const [auditCreationState, setAuditCreationState] = useState({
		auditName: '',
		selectedProductList: [],
		selectedCategories: [],
		selectedStores: [],
		selectedSkus: [],
		isAllProduct: false,
		scheduled: 'NOW',
		selectedStoreTypes: [],
	});
	const [inputState, setInputState] = useState([]);
	const [rawInputState, setRawInputState] = useState([]);
	const [validStoreTypes, setValidStoreTypes] = useState({});
	const [validStoreIds, setValidStoreIds] = useState({});

	const handleClose = () => {
		toggleModal(false);
		setAuditCreationState({
			auditName: '',
			selectedProductList: [],
			selectedCategories: [],
			selectedStores: [],
			selectedSkus: [],
			isAllProduct: false,
			scheduled: 'NOW',
			selectedStoreTypes: [],
		});
		setInputState([]);
	};

	const fetchInputState = async () => {
		const response = await regionStateServices?.getAllRegionMappings();
		setRawInputState(response);
		const allRegion = convertJSON(response);
		allRegion[0].select = -1;
		setInputState(allRegion);
	};

	useEffect(() => {
		if (show) {
			fetchInputState();
			fetchStoresList({
				entity: 'STORES',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
			if (!permittedStores || permittedStores.length === 0) {
				setAuditCreationState(
					{
						...auditCreationState,
						selectedStoreTypes: [
							'BASE',
							'RETAIL',
							'WAREHOUSE_INGREDIENT',
							'WAREHOUSE',
							'WAREHOUSE_PRODUCT',
						],
					}
				);
			} else {
				const jsonValidStoreType = {};
				const jsonValidStoreIds = {};
				permittedStores?.forEach((store) => {
					jsonValidStoreType[store.storeType] = 1;
					jsonValidStoreIds[store.id] = 1;
				});
				setValidStoreTypes(jsonValidStoreType);
				setValidStoreIds(jsonValidStoreIds);
				setAuditCreationState(
					{
						...auditCreationState,
						selectedStoreTypes: Object.keys(jsonValidStoreType),
					}
				);
			}
			fetchProductCategoriesList({
				entity: 'PRODUCT_CATEGORY',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [show]);

	function convertJSON(jsonArray, level = 0) {
		return jsonArray.map((item) => {
			const newItem = {
				...item,
				color: level < regions.length ? regions[level].color : 'gray',
			};
			if (item?.children) {
				if (item?.type === 'city') {
					newItem.children = item?.children?.filter((ele) => {
						return auditCreationState?.selectedStoreTypes?.includes(
							ele?.storeType
						) && (permittedStores.length === 0 || validStoreIds[ele?.id]);
					}).map((ele) => {
						return {
							...ele,
							color: level < regions.length - 1 ? regions[level + 1].color : 'gray',
						};
					});
				} else {
					newItem.children = convertJSON(item.children, level + 1);
				}
			}
			return newItem;
		});
	}

	useEffect(async () => {
		if (show) {
			const allRegion = convertJSON(rawInputState);
			if (allRegion?.[0]) {
				allRegion[0].select = -1;
			}
			setInputState(allRegion);
		}
	}, [show, auditCreationState?.selectedStoreTypes?.length, rawInputState]);

	const PRODUCTS = useMemo(() => {
		return productsList.map((product) => {
			return {
				id: product.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList]);

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'PRODUCT') {
			setAuditCreationState((prev) => {
				return {
					...prev,
					selectedProductList: prev?.selectedProductList?.filter(
						(product) => {
							return product !== parseInt(id, 10);
						}
					),
				};
			});
		}
		if (entity === 'STORE') {
			setAuditCreationState((prev) => {
				return {
					...prev,
					selectedStores: prev?.selectedStores?.filter((store) => {
						return store !== parseInt(id, 10);
					}),
				};
			});
		}
		if (entity === 'CATEGORY') {
			setAuditCreationState((prev) => {
				return {
					...prev,
					selectedCategories: prev?.selectedCategories?.filter(
						(category) => {
							return category !== parseInt(id, 10);
						}
					),
				};
			});
		}
	};

	const addFilter = (filters, context) => {
		if (context.entity === 'PRODUCT') {
			setAuditCreationState((prev) => {
				return {
					...prev,
					selectedProductList: [
						...prev?.selectedProductList,
						...filters,
					],
				};
			});
		}
		if (context.entity === 'STORE') {
			setAuditCreationState((prev) => {
				return {
					...prev,
					selectedStores: [...prev?.selectedStores, ...filters],
				};
			});
		}
		if (context.entity === 'CATEGORY') {
			setAuditCreationState((prev) => {
				return {
					...prev,
					selectedCategories: [
						...prev?.selectedCategories,
						...filters,
					],
				};
			});
		}
	};

	const setAuditName = (value) => {
		setAuditCreationState((prev) => {
			return {
				...prev,
				auditName: value,
			};
		});
	};

	const setScheduledFor = (value) => {
		setAuditCreationState((prev) => {
			return {
				...prev,
				scheduled: value,
			};
		});
	};

	const skusList = useMemo(() => {
		const uniqueSkus = [];
		productsList?.forEach((ele) => {
			const alreadyExists = uniqueSkus?.includes(ele?.skuType);
			if (!alreadyExists) {
				uniqueSkus?.push(ele?.skuType);
			}
		});
		return uniqueSkus;
	}, [productsList]);

	const setSelectedSkyTypes = (value) => {
		setAuditCreationState((prev) => {
			return {
				...prev,
				selectedSkus: value,
			};
		});
	};

	const handleCreateAudit = () => {
		const auditProductsList = [...auditCreationState?.selectedProductList];
		handleConfirm({
			...auditCreationState,
			productsList: auditProductsList,
			isAllProduct: productsList?.length === auditProductsList?.length,
			productSelection: {
				selectedSkus: auditCreationState?.selectedSkus,
				selectedCategories: auditCreationState?.selectedCategories?.map(
					(categoryId) => {
						const category = productCategoriesList?.find((ele) => {
							return ele?.id === categoryId;
						});
						return {
							id: category?.id,
							productCategoryName: category?.productCategoryName,
						};
					}
				),
			},
		});
	};

	const handleRemoveAllProducts = () => {
		setAuditCreationState((prev) => {
			return {
				...prev,
				selectedProductList: [],
			};
		});
	};

	const handleSetNewProductList = (productList) => {
		setAuditCreationState((prev) => {
			return {
				...prev,
				selectedProductList: productList,
			};
		});
	};

	const RegionDependingStoresFetch = (itemToCheck, parentStatus) => {
		// eslint-disable-next-line prefer-const
		let allStores = [];
		itemToCheck?.forEach((ele) => {
			if (
				ele.type === 'store' &&
				(ele?.select === 1 || (ele.select === 0 && parentStatus === 1))
			) {
				allStores.push(ele);
			}
			// eslint-disable-next-line max-len
			const childStores = RegionDependingStoresFetch(
				ele.children,
				ele.select === 0 ? parentStatus : ele.select
			);
			allStores = [...allStores, ...childStores];
		});
		return allStores;
	};

	const getAllStores = useCallback(() => {
		return RegionDependingStoresFetch(inputState, 0);
	}, [inputState]);

	const handleStoreTypeSelection = (value) => {
		setAuditCreationState((prev) => {
			return {
				...prev,
				selectedStoreTypes: value,
			};
		});
	};

	const steps = [
		{
			stepHeader: 'Audit Name & Schedule',
			showBack: true,
			backBtnTxt: 'Cancel',
			showNext: true,
			showTooltip: true,
			tooltipContent: (
				<div className={styles.step1_toolip}>
					<p>
						Audit Name:
						{auditCreationState?.auditName}
					</p>
					<p>
						Scheduled:
						{auditCreationState?.scheduled}
					</p>
				</div>
			),
			onBack: (_, cb) => {
				handleClose();
				cb();
			},
			onNext: (_, cb) => {
				if (!auditCreationState?.auditName) {
					notificationServices?.generateNotification({
						type: 'error',
						message: 'Please enter Audit name',
					});
					return;
				}
				if (
					auditCreationState?.scheduled &&
					auditCreationState?.scheduled !== 'NOW'
				) {
					const selectedTime =
						auditCreationState.scheduled?.split(' ')?.[1];
					const selectedDateTime = moment(
						auditCreationState.scheduled,
						'M/D/YYYY HH:mm'
					);
					const currentDateTime = moment();
					const validSelectedTime =
						/^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/?.test(
							selectedTime
						);
					if (
						!selectedDateTime?.isValid() ||
						selectedDateTime.isBefore(currentDateTime)
					) {
						notificationServices.generateNotification({
							type: 'error',
							message: 'Please select valid Date & Time',
						});
						return;
					}
					if (!validSelectedTime) {
						notificationServices.generateNotification({
							type: 'error',
							message: 'Please select valid Time',
						});
						return;
					}
				}
				cb();
			},
			renderComponent: (currentStep) => {
				return (
					<AuditStep1
						auditName={auditCreationState?.auditName}
						setAuditName={setAuditName}
						scheduledFor={auditCreationState?.scheduled}
						setScheduledFor={setScheduledFor}
						currentStep={currentStep}
						show={show}
					/>
				);
			},
		},
		{
			stepHeader: 'Audit Store(s)',
			showBack: true,
			showNext: true,
			showTooltip: true,
			tooltipContent: (
				<div className={styles.step1_toolip}>
					<p>
						Total Stores Selected:
						{' '}
						{auditCreationState?.selectedStores?.length}
					</p>
				</div>
			),
			onBack: (_, cb) => {
				cb();
			},
			onNext: (_, cb) => {
				const allSelectedStores = getAllStores();
				if (!allSelectedStores?.length) {
					notificationServices?.generateNotification({
						type: 'error',
						message: 'Please select Store(s)',
					});
					return;
				}
				setAuditCreationState((prev) => {
					return {
						...prev,
						selectedStores: allSelectedStores?.map((ele) => {
							return ele?.id;
						}),
					};
				});
				cb();
			},
			renderComponent: () => {
				return (
					<AuditStep2
						toggleListModal={toggleListModal}
						deleteFilter={deleteFilter}
						selectedStores={auditCreationState?.selectedStores}
						stores={stores}
						inputState={inputState}
						setInputState={setInputState}
						regions={regions}
						setAuditCreationState={setAuditCreationState}
						auditCreationState={auditCreationState}
						handleStoreTypeSelection={handleStoreTypeSelection}
						validStoreTypes={validStoreTypes}
						// allStoresSelected={allStoresSelected}
						// setAllStoresSelected={setAllStoresSelected}
					/>
				);
			},
		},
		{
			stepHeader: 'SKU Type & Category',
			showBack: true,
			showNext: true,
			showTooltip: true,
			tooltipContent: (
				<div className={styles.step1_toolip}>
					<p>
						SKU Types:
						{auditCreationState?.selectedSkus?.toString()}
					</p>
				</div>
			),
			onBack: (_, cb) => {
				cb();
			},
			onNext: (_, cb) => {
				setAuditCreationState((prev) => {
					const selectedProductList = productsList
						?.filter((ele) => {
							if (
								!prev?.selectedCategories?.length &&
								!prev?.selectedSkus?.length
							) {
								return true;
							}
							if (!prev?.selectedCategories?.length) {
								return prev?.selectedSkus?.includes(
									ele?.skuType
								);
							}
							if (!prev?.selectedSkus?.length) {
								return prev?.selectedCategories?.includes(
									ele?.categoryId
								);
							}
							return (
								prev?.selectedCategories?.includes(
									ele?.categoryId
								) && prev?.selectedSkus?.includes(ele?.skuType)
							);
						})
						?.map((ele) => {
							return ele?.id;
						});
					return {
						...prev,
						selectedProductList,
					};
				});
				cb();
			},
			renderComponent: () => {
				return (
					<AuditStep3
						skusList={skusList}
						deleteFilter={deleteFilter}
						selectedSkus={auditCreationState?.selectedSkus}
						setSelectedSkyTypes={setSelectedSkyTypes}
						toggleListModal={toggleListModal}
						productCategoriesList={productCategoriesList}
						selectedCategories={
							auditCreationState?.selectedCategories
						}
					/>
				);
			},
		},
		{
			stepHeader: 'Product & Verify',
			showBack: true,
			showNext: true,
			nextBtnTxt: 'Confirm',
			onBack: (_, cb) => {
				if (auditCreationState?.selectedProductList?.length) {
					setAuditCreationState({
						...auditCreationState,
						selectedProductList: [],
					});
				}
				cb();
			},
			onNext: () => {
				setAuditCreationState((prev) => {
					const selectedProductList = productsList
						?.filter((ele) => {
							return (
								prev?.selectedCategories?.includes(
									ele?.categoryId
								) && prev?.selectedSkus?.includes(ele?.skuType)
							);
						})
						?.map((ele) => {
							return ele?.id;
						});
					return {
						...prev,
						selectedProductList,
					};
				});
				if (!auditCreationState?.selectedProductList?.length) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'No product selected',
					});
					return;
				}
				handleCreateAudit();
			},
			renderComponent: () => {
				return (
					<AuditStep4
						skusList={skusList}
						selectedSkus={auditCreationState?.selectedSkus}
						toggleListModal={toggleListModal}
						productCategoriesList={productCategoriesList}
						productsList={productsList}
						selectedCategories={
							auditCreationState?.selectedCategories
						}
						handleSetNewProductList={handleSetNewProductList}
						deleteFilter={deleteFilter}
						auditCreationState={auditCreationState}
						selectedProducts={
							auditCreationState?.selectedProductList
						}
						handleRemoveAllProducts={handleRemoveAllProducts}
					/>
				);
			},
		},
	];

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			customStyles={{
				width: '75%',
				height: '45rem',
			}}
			modalCallee='auditCreator'
			heading={heading}
			open={show}
			handleClose={handleClose}
			hideFooter
		>
			<ListModal handleConfirm={addFilter} list={PRODUCTS} />
			<StepWiseActionComponent steps={steps} />
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { permittedStores } = state?.navigation;
	const { show, data } = state.modal.auditCreationModal;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;

	return {
		show,
		data,
		stores,
		productsList,
		productCategoriesList,
		permittedStores,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAuditCreationModal,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchProducts: formActions.gateway,
	fetchProductCategoriesList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditCreationModal);
