/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import assetActions from '../../../Actions/asset.actions';
import styles from './AssetMonitoringPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import { modalActions } from '../../../Actions';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import AssetMonitoringHeader from './AssetMonitoringHeader/AssetMonitoringHeader';
import assetServices from '../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../Services';
import { urls } from '../../../Constants';

const TABLE_COLUMNS = [
	{
		title: 'Asset Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Monitor time',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Asset Temperature',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'image',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'annotate',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'Change Value',
		config: {
			type: 'EDIT',
		},
	},
];

const COLMAP = {
	'Asset Id': 'assetId',
	'Monitor time': 'createdAt',
	image: 'image',
	'Asset Name': 'assetName',
	'Asset Temperature': 'temp',
	Store: 'store',
};

function AssetMonitoringPage(props) {
	const { assetHisotryData, isloading, toggleImageModal, userPermissions } =
		props;
	const history = useHistory();
	if (!userPermissions.INTERNAL) {
		if (SPECIAL_COLUMNS?.[1]?.title === 'annotate') {
			SPECIAL_COLUMNS.pop();
		}
	}

	// eslint-disable-next-line no-unused-vars
	const handleAnnotate = (item) => {
		history.push(`${urls.EXTENSION}${urls.TEMP_ANNOTATION}?id=${item?.id}`);
	};

	const SPECIAL_ITEMS = {
		image: (id, src) => {
			return (
				<div
					style={{
						width: '5rem',
						display: 'flex',
						cursor: 'pointer',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'center',
					}}
				>
					<img
						onClick={() => {
							const item = assetHisotryData?.find((ele) => {
								return ele?.id === id;
							});
							toggleImageModal(true, {
								heading: `${item?.assetName} at ${item?.createdAt}`,
								image: item?.image,
								// height: 400,
							});
						}}
						width={100}
						height={50}
						src={src}
						alt={src}
					/>
				</div>
			);
		},
		annotate: (id) => {
			const item = assetHisotryData?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<Button
					variant='contained'
					color='primary'
					onClick={() => {
						return handleAnnotate(item);
					}}
				>
					Annotate
				</Button>
			);
		},
	};

	const handleEditingSubmit = async (editedValues) => {
		try {
			const response = await assetServices.editAssetTemp({
				data: editedValues,
				params: { type: 'ASSET' },
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error ?? error?.message,
			});
		}
	};

	return (
		<div className={styles.Table}>
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<AssetMonitoringHeader />
			<ListTable
				colMap={COLMAP}
				hasDateRange
				columns={TABLE_COLUMNS}
				data={assetHisotryData}
				actions={[...ACTION_COLUMNS, ...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleEditSubmit={handleEditingSubmit}
				editable
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	const assetHisotryData = state.assets.monitoringData;
	const { isloading } = state.assets;
	const { userPermissions } = state.navigation;
	return {
		assetHisotryData,
		isloading,
		userPermissions,
	};
};
const mapDispatchToProps = {
	fetchAssetMonitoringHistory: assetActions.fetchAssetMonitoringHistory,
	toggleImageModal: modalActions.toggleImageModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AssetMonitoringPage);
