/* eslint-disable nonblock-statement-body-position */
/* eslint-disable global-require */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable curly */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import '../../Quality Analysis/qc.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import blank from '../../../../Images/blank.png';
import pass from '../../../../Images/pass.png';
import fail from '../../../../Images/fail.png';
import defaultFood from '../../../../Images/food.jpg';
import defaultFace1 from '../../../../Images/userImage.jpg';
import { authHeader, imageToBase64 } from '../../../../Helpers';
import { endpoints, urls } from '../../../../Constants';
import { notificationServices } from '../../../../Services';
import authActions from '../../../../Actions/auth.actions';
import { cloneDeep } from 'lodash';
import { useAuth } from '../../../../Components/Auth/Login/auth';
import { createAlert } from '../../../../Components/Modals';
import { liveQCServices } from '../../../../Services/LiveQC';
const initialState = {
	id: null,
	idx: -2,
	itemId: '',
	Chef: '',
	state: 'offline',
	store: '',
	foodLabel: null,
	MaxTemp: '0',
	MaxWeight: '0',
	MinTemp: '0',
	MinWeight: '0',
	QC_Result: '',
	Temp: '0',
	TempSensor: true,
	Weight: '0',
	brandImage: blank,
	current_date: new Date().toISOString().slice(0, 10),
	faceCamera: true,
	faceImage: defaultFace1,
	failed_qcs: 0,
	foodCamera: true,
	foodImage: defaultFood,
	isVeg: blank,
	lastQcAt: '',
	live_temp: '-',
	live_weight: '-',
	message: '',
	orderId: '',
	pass_percentage: '-',
	passed_qcs: '-',
	productName: '',
	variantName: '',
	quality: '-',
	traceabilty: '-',
	location: '-',
	refrenceImage: defaultFood,
	intervalId: null,
	tempCheckRequired: '',
	tempInRange: true,
	total_qcs: '-',
	weighingScale: true,
	weightCheckRequired: '',
	weightInRange: true,
	faceLabel: '',
	passPercentColor: '',
	weightRangeColour: blank,
	temperatureRangeColour: blank,
	qcMessage: '',
	qcStatusColor: 'white',
	isLiveQC: true,
	qc_status_timestamp: '',
	updatedAt: '',
	TemperatureColor: '',
	complimentaryTemperatureColor: '',
	otherTemp: '',
	isTempMax: false,
	show: false,
	selectedStoreName: '',
	selectedStoreId: '',
	selectedStore: {
		storeId: -2,
		selectedStoreName: '',
	},
	stockInAt: '',
	stockOutAt: '',
	qrCode: '',
};

const showSnackbar = notificationServices.generateNotification;

const LiveIMS = (props) => {
	const { id, live, ims } = props;

	const [state, setState] = useState(() => {
		return {
			...initialState,
			isLiveQC: live,
		};
	});

	const [selectedStore, setSelectedStore] = useState({
		selectedStoreName: '',
		selectedStoreId: '',
	});

	const [storeList, setStoreList] = useState(null);

	const [listIdx, setListIdx] = useState(() => {
		return id;
	});

	const { signOut } = useAuth();

	const [intervalId, setIntervalId] = useState(null);

	const defaultState = () => {
		setState({
			id: null,
			idx: id,
			itemId: '',
			Chef: '',
			state: 'offline',
			store: '',
			foodLabel: null,
			MaxTemp: '0',
			MaxWeight: '0',
			MinTemp: '0',
			MinWeight: '0',
			QC_Result: '',
			Temp: '0',
			TempSensor: true,
			Weight: '0',
			brandImage: blank,
			current_date: `${new Date().getFullYear()}-${
				new Date().getMonth() + 1
			}-${new Date().getDate()}`,
			faceCamera: true,
			faceImage: defaultFace1,
			failed_qcs: 0,
			foodCamera: true,
			foodImage: defaultFood,
			isVeg: blank,
			lastQcAt: '',
			live_temp: '-',
			live_weight: '-',
			message: '',
			orderId: '',
			pass_percentage: '-',
			passed_qcs: '-',
			productName: '',
			quality: '-',
			traceabilty: '-',
			location: '-',
			refrenceImage: defaultFood,
			tempCheckRequired: '',
			tempInRange: true,
			total_qcs: '-',
			weighingScale: true,
			weightCheckRequired: '',
			weightInRange: true,
			faceLabel: '',
			passPercentColor: '',
			weightRangeColour: blank,
			temperatureRangeColour: blank,
			qcMessage: '',
			qcStatusColor: 'white',
			isLiveQC: true,
			qc_status_timestamp: '',
			updatedAt: '',
			TemperatureColor: '',
			complimentaryTemperatureColor: '',
			otherTemp: '',
			isTempMax: false,
			show: false,
			selectedStoreName: '',
			selectedStoreId: '',
			selectedStore: {
				storeId: -2,
				selectedStoreName: '',
			},
			stockInAt: '',
			stockOutAt: '',
			qrCode: '',
		});
	};
	const { listData } = props;
	const stateRef = useRef(state);
	const fetchData = async (first, stockInId, store) => {
		if (true) {
			const fetchTimeout = require('fetch-timeout');
			try {
				const endpoint =
					stockInId != null
						? `${endpoints.LIVE_IMS}?storeId=${store}&stockInId=${stockInId}`
						: `${endpoints.LIVE_IMS}?storeId=${
								selectedStore?.selectedStoreId || ''
						  }&currentId=${stateRef.current.id}`;
				const response = await fetchTimeout(
					endpoint,
					{
						method: 'GET',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							...authHeader(),
						},
					},
					1000
				);
				const res = await response.json();
				if (res.statusCode === 304) {
					return;
				}
				if (res.errorMessage === undefined) {
					if (res.data == null) {
						createAlert('Selected system is not activated today');
					}

					let tempState = cloneDeep(state);
					tempState = {
						...tempState,
						id: res.data.id,
						total_qcs: res.data.total_qcs,
						passed_qcs: res.data.passed_qcs,
						lastQcAt: res.data.lastQcAt,
						pass_percentage: res.data.pass_percentage,
						state: res.data.state,
						store: res.data.store,
						itemId: res.data.itemId,
						brandImage: res.data.brandImage,
						weighingScale: res.data.weight,
						TempSensor: true,
						foodCamera: true,
						show: true,
						orderId: res.data.orderId,
						productName: res.data.skuName,
						variantName: res.data.variantName,
						foodLabel: res.data.foodLabel,
						stockInAt: res.data.stockedInAt,
						stockOutAt: res.data.stockedOutAt,
						qrCode: res.data.qrCode,
					};

					tempState.MinWeight = res.data.minWeight;
					tempState.MaxWeight = res.data.maxWeight;
					tempState.Weight = res.data.weight;
					tempState.weightCheckRequired =
						res.data.weightCheckRequired;
					if (
						res.data.weightCheckRequired === 1 ||
						res.data.weightCheckRequired === true
					) {
						tempState.weightRangeColour = pass;
					}

					if (
						res.data.weightCheckRequired === 0 ||
						res.data.weightCheckRequired === false
					) {
						setState({
							...state,
							weightRangeColour: blank,
						});
						tempState.weightRangeColour = blank;
					}
					tempState = {
						...tempState,
						MinTemp: res.data.minTemp,
						Temp: res.data.temp,
						MaxTemp: res.data.maxTemp,
						tempCheckRequired: res.data.tempCheckRequired,
						otherTemp: null,
						isTempMax: true,
					};
					if (res.data.otherTemp != null) {
						if (res.data.isTempMax === true) {
							tempState.TemperatureColor = '#E32227';
							tempState.complimentaryTemperatureColor = '#00449E';
						} else {
							tempState.TemperatureColor = '#00449E';
							tempState.complimentaryTemperatureColor = '#E32227';
						}
					}

					if (
						res.data.tempCheckRequired === 1 ||
						res.data.tempCheckRequired === true
					) {
						tempState.temperatureRangeColour = pass;
					}

					if (
						res.data.tempCheckRequired === 0 ||
						res.data.tempCheckRequired === false
					) {
						tempState.temperatureRangeColour = blank;
					}
					tempState.foodImage = res.data.skuImage;
					tempState.refrenceImage = res.data.referenceImage;
					tempState.isVeg = res.data.isVeg;
					tempState.QC_Result = 'SUCCESS';
					tempState = {
						...tempState,
						qcStatusColor: 'green',
						qcMessage: 'Stocked In Successfully',
						qc_status_timestamp: 'Passed',
					};
					setState({
						...tempState,
					});
				} else {
					createAlert(res.errorMessage);
					if (res.errorMessage === 'api_auth_invalid') {
						signOut();
						createAlert('Session expired or new login detected');
					} else {
						createAlert(res.errorMessage);
					}
				}
			} catch (err) {
				// console.log('Some Error occurred');
			}
		} else {
			clearInterval(intervalId);
		}
	};
	useEffect(() => {
		stateRef.current = state;
	}, [state]);
	const fetchStoreList = async () => {
		try {
			const response = await liveQCServices.getStoreList();
			setStoreList(response?.data ?? []);
		} catch (err) {
			showSnackbar({
				message: 'Error occurred in fetching store list',
				type: 'error',
			});
			// console.log(err);
		}
	};
	const updateStates = async (id, makeLiveQc = false) => {
		if (makeLiveQc) {
			if (intervalId != null) {
				clearInterval(intervalId);
			}
			const newIntervalId = setInterval(() => {
				fetchData('');
			}, 4000);
			setIntervalId(newIntervalId);
			setState({
				...initialState,
				isLiveQC: true,
			});
			fetchData(true);
		} else {
			setListIdx(id);
			const listItem = listData?.[id - 1] ?? {};
			const { store = null, id: stockInId = null } = listItem;
			fetchData(true, stockInId, store);
		}
	};

	useEffect(() => {
		fetchStoreList();
	}, []);

	useEffect(() => {
		if (selectedStore.selectedStoreId || !live) {
			updateStates(listIdx, live);
		}
	}, [selectedStore]);

	useEffect(() => {
		return () => {
			clearInterval(intervalId);
		};
	}, [intervalId]);

	const storeSelector = (e) => {
		if (e.target.value === '') {
			setSelectedStore({
				selectedStoreName: '',
				selectedStoreId: '',
			});
		} else {
			setSelectedStore({
				selectedStoreName: storeList[e.target.value].name,
				selectedStoreId: storeList[e.target.value].id,
			});
		}
	};
	const history = useHistory();
	const handleGoLive = async () => {
		history.push(`${urls.EXTENSION}${urls.LIVE_IMS}`);
		setState({
			...initialState,
			isLiveQC: true,
		});
	};
	return (
		<div id='liveQC-parent' key={live ? 1 : 0}>
			<div
				id='div00'
				className={state.isLiveQC === true ? '' : 'notLiveQC'}
			>
				{!live && (
					<Tooltip title='Go Back' arrow>
						<button
							className='qc-back-btn btn'
							onClick={() => {
								history.goBack();
							}}
						>
							Back
						</button>
					</Tooltip>
				)}
				<div className='cf' data-name='slab-stat-small'>
					<div>
						<div className='stat-name'>Current Date</div>
						<div className='stat-value'>
							{state.current_date === ''
								? 'Not Active'
								: state.current_date ?? '-'}
						</div>
					</div>
					<div id='lastQCtime'>
						<div className='stat-name'>Stock In At</div>
						<div className='stat-value'>{state.stockInAt}</div>
					</div>
					<div id='lastQCtime'>
						<div className='stat-name'>Stock Out At</div>
						<div className='stat-value'>
							{state.stockOutAt ?? '-'}
						</div>
					</div>
					<div id='past-orders-heading'>
						<div className='f4 fw6 ml0'>Past Stock In</div>
					</div>
					<div id='orderID'>
						<div
							style={{
								minWidth: '50px',
								maxWidth: '50px',
							}}
						>
							<Tooltip title='Prev order' arrow>
								<ArrowLeftIcon
									class='nav-btn nav-btn-left'
									style={{
										display:
											listIdx - 1 !== 0 && !state.isLiveQC
												? 'block'
												: 'none',
									}}
									onClick={() => {
										updateStates(listIdx - 1);
									}}
								/>
							</Tooltip>
						</div>
						<div id='orderID-content'>
							<div className='stat-name'>QR</div>
							<div className='stat-value'>{state.qrCode}</div>
						</div>
						<div
							style={{
								minWidth: '50px',
								maxWidth: '50px',
							}}
						>
							<Tooltip title='Next order' arrow>
								<ArrowRightIcon
									class='nav-btn nav-btn-right'
									style={{
										display:
											listIdx - 1 !==
												(listData?.length ?? 0) - 1 &&
											!state.isLiveQC
												? 'block'
												: 'none',
									}}
									onClick={() => {
										updateStates(listIdx + 1);
									}}
								/>
							</Tooltip>
						</div>
					</div>
					{live && storeList !== null && (
						<div>
							<div className='stat-name'>Select Store</div>
							{storeList?.length > 0 && (
								<select
									className='qc-select-store'
									selected={state?.selectedStoreName}
									onChange={storeSelector}
								>
									<option value=''>None</option>
									{storeList?.map((elem, index) => {
										return (
											<option value={index}>
												{elem?.name}
											</option>
										);
									})}
								</select>
							)}
						</div>
					)}
				</div>
				<button className='go-live-btn btn' onClick={handleGoLive}>
					Go Live
				</button>
			</div>

			<div id='div1'>
				<img
					src={state.faceImage}
					className='user-img'
					alt='UserImage'
				/>
				<p>
					<b>Operator:</b>
					<br />
					{state.Chef === '' ? '-' : state.Chef}
				</p>
			</div>

			<div
				id='div1m'
				className={
					state.state === 'offline' && !state.foodCamera
						? 'offline-warning'
						: ''
				}
				style={{ height: '60vh' }}
			>
				<img
					src={state.foodImage}
					className='food-image'
					alt='FoodImage'
				/>
				{state.show && (
					<div
						className={`time-stamp time-stamp-left ${
							state.QC_Result === 'SUCCESS'
								? 'success'
								: 'success'
						}`}
						style={{
							display: state.orderId
								? state.foodCamera
									? 'block'
									: 'none'
								: 'none',
						}}
					>
						<span className='fw6 center'>
							{`${state.orderId}${
								state.itemId === '' ? '' : `/${state.itemId}`
							}`}
						</span>
						<br />
						<span className='fw6 ph2'>
							{state.qc_status_timestamp}
						</span>
						<br />
					</div>
				)}

				{state.show && (
					<div
						className={`time-stamp time-stamp-right ${
							state.QC_Result === 'SUCCESS'
								? 'success'
								: 'success'
						}`}
						style={{
							display: state.orderId
								? state.foodCamera
									? 'block'
									: 'none'
								: 'none',
						}}
					>
						<span className='fw6 ph2'>{state.current_date}</span>
						<br />
						<span className='fw6'>{state.lastQcAt}</span>
					</div>
				)}
			</div>
			<div
				id='div2m'
				align='center'
				style={{ height: '60vh', maxHeight: 'auto' }}
			>
				<img
					className='reference-img'
					src={
						state.refrenceImage != null
							? state.refrenceImage
							: defaultFood
					}
					alt=''
				/>
				<div>
					{/* <img
                src={
                  state.isVeg == blank
                    ? blank
                    : state.isVeg === 1 || state.isVeg === true
                    ? veg
                    : nonveg
                }
                alt=''
                class='mr2 is-veg-img'
              /> */}
					<span
						className='detail'
						id='product-name'
						style={{ top: '5px' }}
					>
						{state.productName}
						{state?.variantName?.length > 0
							? ` (${state.variantName})`
							: ''}
					</span>
				</div>
			</div>
			<div className='' style={{}}>
				<div
					id='div2'
					class={
						state.state === 'offline' && !state.TempSensor
							? 'offline-warning'
							: ''
					}
				>
					<div
						class='min-max-container'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<span className='detail qc-left'>
							{state.tempCheckRequired == 0
								? ''
								: state.MinTemp === '0'
								? 'Min: 0\u2103'
								: `Min: ${state.MinTemp ?? '-'}\u2103`}
						</span>
						{/* <span className='detail qc-left'>Min: 100℃</span> */}
						<span className='detail qc-center'>Item Temp</span>
						{/* <span className='detail live-text'>Live Temp&nbsp;&nbsp;{state.live_temp === '-' ? '-' : `${state.live_temp}\u2103`}</span> */}
						<span className='detail qc-right'>
							{state.tempCheckRequired == 0
								? ''
								: state.MaxTemp === '0'
								? 'Max: 0\u2103'
								: `Max: ${state.MaxTemp ?? '-'}\u2103`}
						</span>
						{/* <span className='detail qc-right'>Max: 100℃</span> */}
					</div>
					<div id='div22'>
						<img
							class='pass-img'
							src={state.temperatureRangeColour}
							alt=''
						/>
						{state.otherTemp !== null && (
							<div className='measurement'>
								<span
									id='main-temp'
									style={{
										color: state.TemperatureColor,
									}}
								>{`${state.Temp ?? '-'}\u2103`}</span>
								<span
									id='other-temp'
									style={{
										color: state.complimentaryTemperatureColor,
									}}
								>{`${state.otherTemp ?? '-'}\u2103`}</span>
							</div>
						)}
						{state.otherTemp === null && (
							<div className='measurement'>
								{`${state.Temp ?? '-'}\u2103`}
							</div>
						)}
					</div>
				</div>
				<div
					id='div3'
					class={
						state.state === 'offline' && !state.weighingScale
							? 'offline-warning'
							: ''
					}
				>
					<div
						class='min-max-container'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						{/* <span className='detail qc-left'>Min: 10000g</span> */}
						<span className='detail qc-left'>
							{state.weightCheckRequired == 0
								? ''
								: state.MinWeight === '0'
								? 'Min: 0g'
								: `Min: ${state.MinWeight ?? '-'}g`}
						</span>
						{/* <span className='detail live-text'>Live Weight&nbsp;&nbsp;{state.live_weight === '-' ? '-' : `${state.live_weight}g`}</span> */}
						<span className='detail qc-center'>Item Weight</span>
						{/* <span className='detail qc-right'>Max: 10000g</span> */}
						<span className='detail qc-right'>
							{state.weightCheckRequired == 0
								? ''
								: state.MaxWeight === '0'
								? 'Max: 0g'
								: `Max: ${state.MaxWeight ?? '-'}g`}
						</span>
					</div>
					<div id='div32'>
						<img
							class='pass-img'
							src={state.weightRangeColour}
							alt=''
						/>
						<div className='measurement'>
							{`${state.Weight ?? '-'}g`}
						</div>
					</div>
				</div>
				<div
					className='result'
					id='div4'
					align='center'
					style={{
						background: state.qcStatusColor,
						color: 'white',
					}}
				>
					{state.qcStatus}{' '}
					<text className='mb7 fw6 f3 appendMovingDots'>
						{' '}
						{state.qcMessage ?? '-'}{' '}
					</text>
					{/* <button style={{position:"relative",float:"left",display: (context.historyListIdx!=0) ? 'block' : 'none'}} onClick={()=>{context.setHistoryListIdx(context.historyListIdx--);updateStates();}}>previous</button> */}
					{/* <button style={{position:"relative",float:"right",display: (context.historyListIdx!=(context.listData.length-1)) ? 'block' : 'none'}} onClick={()=>{context.setHistoryListIdx(context.historyListIdx++);updateStates();}}>next</button> */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const listData = state.analytics.INVENTORY_ANALYTICS.STOCK_IN_LIST;
	const { id } = state.analytics.selectedListItem;
	return {
		listData,
		id,
	};
};

const mapDispatchToProps = {
	signOut: authActions.toggleLogout,
};

LiveIMS.defaultProps = {
	live: false,
	ims: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveIMS);
