/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import { ClickAwayListener, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import {
	ArrowDropDown, Close, Shower
} from '@mui/icons-material';
import './Dropdown.scss';
// import './OrderTracking.scss';

function DropdownInfo(props) {
	const {
		inputLists,
		isQrSelect,
		placeholder,
		width,
		handleKeyPress = () => {},
		showmapfun,
		inputName = '',
		disabled = false,
		idd,
		setidd,
		proId,
		setProdId,
		prodName,
		setProdName,
		showDiv,
		setShowDiv,
		selectedState,
		setSelectedState,
	} = props;
	const [searchQuery, setSearchQuery] = useState('');
	// const [selectedState?, setSelectedState] = useState();
	const [showDropdown, setShowDropdown] = useState(false);
	const handleChange = (e) => {
		setSearchQuery(e.target.value);
	};
	const hideDropdown = () => {
		if (selectedState && inputLists?.length > 0) {
			setShowDiv(true);
		}
		setShowDropdown(false);
	};
	const hideDropdown1 = () => {
		if (selectedState && inputLists?.length > 0) {
			setShowDiv(true);
		}
		setShowDropdown(!showDropdown);
	};
	const setshow = () => {
		setShowDiv(false);
		setShowDropdown(true);
	};
	const handleEvent = (prop) => {
		setidd(prop.id);
		setProdId(prop.productId);
		setProdName(prop.name);
		setShowDiv(true);
		setSelectedState((prev) => {
			return {
				...prev,
				image1: prop?.image,
				id1: prop?.id,
				name1: prop?.name,
				productId: prop?.productId,
				variantName: prop?.variantName,
			};
		});
		setShowDropdown(false);
		showmapfun(true);
	};
	const handleEventQr = (prop) => {
		setidd(prop.id);
		setProdId(prop.productId);
		setProdName(prop.name);
		showmapfun(true);
		setSelectedState((prev) => {
			return {
				...prev,
				image1: prop?.image,
				id1: prop?.id,
				name1: prop?.name,
				productId: prop?.productId,
				variantName: prop?.variantName,
			};
		});
	};
	useEffect(() => {
		if (isQrSelect === 'secondbt' && inputLists && inputLists.length > 0 && inputLists[0]) {
			handleEventQr(inputLists[0]);
		}
		if ((isQrSelect === 'firstbt' || isQrSelect === 'fourthbt') && inputLists && inputLists.length === 1 && inputLists[0]) {
			handleEventQr(inputLists[0]);
		}
	}, [isQrSelect, inputLists]);
	return (
		<>
			{isQrSelect === 'secondbt' && inputLists?.length > 0 && inputLists[0] && (
				<div
					style={{
						width,
					}}
					className='root'
				>
					<div className='input'>
						<div
							className='vlci1'
							key={inputLists[0]?.id}
							value={inputLists[0]?.id}
							name='me'
							onClick={() => {
								return handleEventQr(
									inputLists[0]
								);
							}}
						>
							<div>
								<span>
									{ inputLists[0]?.name + ' (' + (inputLists[0]?.variantName ? inputLists[0]?.variantName : '-') + ')' }
								</span>
								<br />
								<span>
									QR Code:
									{' '}
									{inputLists[0]?.id}
								</span>
							</div>
							<img className='imgx' src={inputLists[0]?.image} alt='cake' />
						</div>
					</div>
				</div>
			)}
			{(isQrSelect === 'firstbt' || isQrSelect === 'fourthbt') && inputLists?.length === 1 && inputLists[0] && (
				<div
					style={{
						width,
					}}
					className='root'
				>
					<div className='input'>
						<div
							className='vlci1'
							key={inputLists[0]?.id}
							value={inputLists[0]?.id}
							name='me'
							onClick={() => {
								return handleEventQr(
									inputLists[0]
								);
							}}
						>
							<div>
								<span>{ inputLists[0]?.name + ' (' + (inputLists[0]?.variantName ? inputLists[0]?.variantName : '-') + ')' }</span>
								<br />
								<span>
									QR Code:
									{' '}
									{inputLists[0]?.id}
								</span>
							</div>
							<img className='imgx' src={inputLists[0]?.image} alt='cake' />
						</div>
					</div>
				</div>
			)}
			{(isQrSelect === 'firstbt' || isQrSelect === 'fourthbt') && inputLists?.length > 1 && (
				<ClickAwayListener onClickAway={hideDropdown}>
					<div
						style={{
							width,
						}}
						className='root'
					>
						{showDiv && selectedState &&
					(
						<div className='input'>
							{
								selectedState?.id1
									? (
										<div
											className='vlci1'
											key={selectedState?.id1}
											value={selectedState?.id1}
											name='me'
											onClick={() => {
												return setshow();
											}}
										>
											<div>
												<span>{ selectedState?.name1 + ' (' + (selectedState?.variantName ? selectedState?.variantName : '-') + ')' }</span>
												<br />
												<span>
													QR Code:
													{' '}
													{selectedState?.id1}
												</span>
											</div>
											<img className='imgx' src={selectedState?.image1} alt='cake' />
											<ArrowDropDown />
										</div>
									)
									: (
										<>
											<input
												name={inputName}
												className='search-input'
												placeholder={placeholder}
												value={searchQuery}
												onChange={handleChange}
												onKeyDown={handleKeyPress}
												autoComplete='off'
												onFocus={() => {
													setShowDropdown(true);
												}}
											/>
											<ArrowDropDown
												style={{
													cursor: 'pointer',
												}}
												onClick={hideDropdown1}
											/>
										</>
									)
							}
						</div>
					)}
						{!showDiv && (
							<div className='input'>
								<input
									name={inputName}
									className='search-input'
									placeholder={placeholder}
									value={searchQuery}
									onChange={handleChange}
									onKeyDown={handleKeyPress}
									autoComplete='off'
									onFocus={() => {
										setShowDropdown(true);
									}}
								/>
								<ArrowDropDown
									style={{
										cursor: 'pointer',
									}}
									onClick={hideDropdown1}
								/>
							</div>
						)}
						{!disabled && showDropdown && (
							<div className='query-items'>
								{!inputLists?.length > 0 && (
									<p className='empty'>Empty List</p>
								)}
								{inputLists
									?.filter((ele) => {
										return (
											ele?.name
												?.toLowerCase()
												?.indexOf(
													searchQuery?.toLowerCase()?.trim()
												) !== -1
										);
									})
									?.map(({ image, id, name, productId }, index) => {
										const isSelected = id === idd;
										return (
											<div
												className={'vlci ' + (isSelected ? 'selected' : '')}
												key={id}
												value={id}
												name='me'
												onClick={() => {
													return handleEvent({
														image, id, name, productId,
													});
												}}
											>
												<div>
													<span>
														{index + 1}
														.
													</span>
													{' '}
													<span>{name}</span>
													<br />
													<span>
														QR Code:
														{' '}
														{id}
													</span>
												</div>
												<img className='imgx' src={image} alt='cake' />
											</div>
										);
									})}
							</div>
						)}
					</div>
				</ClickAwayListener>
			)}
		</>
	);
}

export default DropdownInfo;
