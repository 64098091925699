/* eslint-disable */
import { connect } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';
// eslint-disable-next-line import/no-extraneous-dependencies
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import Table from 'rsuite/Table';
import CachedIcon from '@mui/icons-material/Cached';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { formActions } from '../../../Actions';
import './overrideRsuit.css';
import { classes } from '../../../Helpers';
import reportsService from '../../../Services/ReportsServices/reports.service';
import { BackdropLoader } from '../../../Components/Loaders';
import styles from './CustomerReports.module.scss';
import excel from '../../../Images/excel.svg';
// eslint-disable-next-line import/order
import Pagination from 'rsuite/Pagination';
import GenericFilter from './GenericFilter/GenericFilter';
import { notificationServices } from '../../../Services';
import { endpoints } from '@/Constants';

const timerSplitter = '$$time$$';
function buildKey(filterData, reportId) {
	return `${JSON.stringify(filterData)}reportId:${reportId}`;
}
function setToCache(filterData, reportId, data) {
	// reportCache[buildKey(filterData, reportId)] = data;
	const cacheTime = moment().format('DD/MM/YYYY | h:mm a');
	sessionStorage.setItem(
		buildKey(filterData, reportId),
		JSON.stringify(data) + timerSplitter + cacheTime
	);
	return cacheTime;
}
function getFromCache(filterData, reportId) {
	const data = sessionStorage.getItem(buildKey(filterData, reportId));
	if (data) {
		const iData = data?.split(timerSplitter);
		return [JSON?.parse(iData?.[0] ?? {}), iData?.[1] ?? {}];
	}
	return [undefined, undefined];

	// return reportCache?.[buildKey(filterData, reportId)];
}

// eslint-disable-next-line no-unused-vars
const FIND_FILTERS = ['store'];
const urls = {
	python: endpoints.REPORT_DATA_PYTHON_URL,
	java: endpoints.REPORT_DATA_JAVA_URL,
};
const CustomerReports = () => {
	const [loading, setLoading] = useState(false);
	const [typesOfReports, setTypesOfReports] = useState({});
	const [currentFilter, setCurrentFilter] = useState({});
	const [currentReportData, setCurrentReportData] = useState({});
	const [expanded, setExpanded] = useState(true);
	const [activeKey, setActiveKey] = useState('0');
	const [currentSelectedReport, setCurrentSelectedReport] = useState({});
	const [isCurrentDataFromCache, setIsCurrentDataFromCache] = useState(false);
	const [holdDataForTableLoad, setHoldDataForTableLoad] = useState(false);
	const [changed, setChanged] = useState(0);

	useEffect(() => {
		const level = activeKey?.split('-');
		if (level?.length <= 2) {
			const firstIndex = level[0];
			const secondIndex = level[1];
			const herirar = Object.entries(typesOfReports)?.sort(
				([reportTypeName], [reportTypeName2]) => {
					if (reportTypeName > reportTypeName2) {
						return -1;
					}
					return 0;
				}
			);
			setCurrentSelectedReport(
				herirar?.[Number(firstIndex)]?.[1]?.[Number(secondIndex)]
			);

			setCurrentReportData({});
			setIsCurrentDataFromCache(false);
		}
	}, [activeKey, typesOfReports]);

	const getReportsData = useCallback(
		(
			data = currentFilter,
			reportId = currentSelectedReport?.id,
			forced = false,
			avoidApiCall = false
		) => {
			const copyFilter = {
				filter: {},
			};
			currentSelectedReport?.filters?.forEach((ele) => {
				if (ele?.name === 'dateRangeOnly') {
					copyFilter.filter.dateRangeFilter =
						data?.filter?.dateRangeFilter;
				}
				if (ele?.name === 'parentStore') {
					copyFilter.filter.parentStoreFilter =
						data?.filter?.parentStoreFilter;
				}
				if (ele?.name === 'dateOnly') {
					copyFilter.filter.dateOnly = data?.filter?.dateOnly;
				}
				if (ele?.name === 'store') {
					copyFilter.filter.storeFilter = data?.filter?.storeFilter;
				}
			});
			if (!forced) {
				const cachedData = getFromCache(copyFilter, reportId);
				if (cachedData?.[0]) {
					setCurrentReportData(cachedData?.[0]);
					setIsCurrentDataFromCache(cachedData?.[1]);
					setChanged(null);
					return;
				}
			}
			if (avoidApiCall) {
				return;
			}

			(async () => {
				setLoading(true);
				try {
					const resData = await reportsService.getReportData({
						data: copyFilter,
						urlPath: [reportId],
						endpoint:
							urls?.[currentSelectedReport?.platform] ??
							urls?.java,
					});
					if (resData?.responseCode === 'SS-001' || resData?.statusCode===200) {
						setCurrentReportData(resData?.data);
						if (resData?.data?.cacheRequired!=false){
							const cacheTime = setToCache(
								copyFilter,
								reportId,
								resData?.data
							);
							setIsCurrentDataFromCache(cacheTime);
							setChanged(null);
						}
					}
					// console.log(resData);
				} catch (e) {
					setCurrentReportData({});
				} finally {
					// setIsCurrentDataFromCache(false);
					setLoading(false);
				}
			})();
		},
		[currentSelectedReport?.id, getFromCache, currentFilter]
	);

	const submitFindRequest = useCallback(
		(data) => {
			// eslint-disable-next-line no-unused-vars
			const {
				store = null,
				dateRange = {},
				parentStore = null,
				date: dateOnly = null,
			} = data;
			const thisFilter = {
				filter: {
					storeFilter: [store],
					parentStoreFilter: [parentStore],
					dateRangeFilter: {
						from: moment(dateRange.from).format('YYYY-MM-DD'),
						to: moment(dateRange.to).format('YYYY-MM-DD'),
					},
					dateOnly: moment(dateOnly).format('YYYY-MM-DD'),
				},
			};
			setCurrentFilter(thisFilter);
			if (Object.keys(thisFilter?.filter ?? {}) === 0) {
				return;
			}
			if (Object.keys(currentSelectedReport ?? {}) === 0) {
				return;
			}
			const storeMandatory = (currentSelectedReport?.filters ?? [])?.find(
				(ele) => {
					return ele?.name === 'store';
				}
			);
			// const storeMandatory =
			// 	storeIndex !== -1
			// 		? currentSelectedReport?.filterMandatory?.[storeIndex]
			// 		: false;

			if (
				storeMandatory?.mandatory &&
				!thisFilter?.filter?.storeFilter?.[0]
			) {
				notificationServices.generateNotification({
					type: 'warning',
					message: 'Store is mandatory',
				});
				return;
			}
			const parentStoreMandatory = (
				currentSelectedReport?.requiredFilters ?? []
			)?.find((ele) => {
				return ele?.name === 'parentStore';
			});
			// const parentStoreMandatory =
			// 	parentStoreIndex !== -1
			// 		? currentSelectedReport?.filterMandatory?.[parentStoreIndex]
			// 		: false;

			if (
				parentStoreMandatory?.mandatory &&
				!thisFilter?.filter?.parentStoreFilter?.[0]
			) {
				notificationServices.generateNotification({
					type: 'warning',
					message: 'Parent Store is mandatory',
				});
				return;
			}
			getReportsData(thisFilter, currentSelectedReport?.id);
		},
		[currentSelectedReport]
	);

	const getTypesOfReportsList = useCallback(() => {
		(async () => {
			setLoading(true);
			try {
				const resp = await reportsService.getReportsList();
				setTypesOfReports(resp?.data);
				setActiveKey('0-0');
				// console.log(data);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		getTypesOfReportsList();
	}, []);

	useEffect(() => {
		setHoldDataForTableLoad(true);
		const timeout = setTimeout(() => {
			setHoldDataForTableLoad(false);
		}, 200);
		return () => {
			clearTimeout(timeout);
		};
	}, [expanded]);

	const downloadCSV = useCallback((csv, filename) => {
		const csvFile = new Blob([csv], {
			type: 'text/csv',
		});

		// Download link
		const downloadLink = document.createElement('a');

		// File name
		downloadLink.download = filename;

		// Create a link to the file
		downloadLink.href = window.URL.createObjectURL(csvFile);

		// Hide download link
		downloadLink.style.display = 'none';

		// Add the link to DOM
		document.body.appendChild(downloadLink);

		// Click download link
		downloadLink.click();
	}, []);

	const exportTableDataToCSV = useCallback((fileName, tableData) => {
		const listOfHeaders = [];
		const listOfHeaderKeys = [];
		const data = [];
		tableData?.headers?.forEach((header) => {
			if (header?.keys?.length > 0) {
				header?.keys?.forEach((subheader) => {
					listOfHeaders.push(
						// eslint-disable-next-line prefer-template
						header?.displayName + '-' + subheader?.displayName
					);
					listOfHeaderKeys.push(subheader?.key);
				});
			} else {
				listOfHeaders.push(header?.displayName);
				listOfHeaderKeys.push(header?.key);
			}
		});
		data?.push(listOfHeaders);
		tableData?.rowData?.forEach((obj) => {
			const tempRow = [];
			listOfHeaderKeys?.forEach((headerKey) => {
				tempRow.push(obj?.row?.[headerKey] ?? '-');
			});
			data?.push(tempRow?.join(','));
		});
		// const rows = document.querySelectorAll('table tr');

		// Download CSV file
		downloadCSV(data.join('\n'), fileName);
	}, []);

	const dynamicFilters = useMemo(() => {
		const reportFilter = (currentSelectedReport?.filters ?? [])?.map(
			(ele) => {
				return ele?.name;
			}
		);
		return [...reportFilter];
	}, [
		FIND_FILTERS,
		currentSelectedReport?.id,
		currentSelectedReport?.filters,
	]);

	console.log('dynamicFilters', dynamicFilters);

	useEffect(() => {
		setChanged((prev) => {
			return prev + 1;
		});
		getReportsData(undefined, undefined, false, true);
	}, [currentSelectedReport?.id, getReportsData]);

	const menuToBuild = Object.entries(typesOfReports ?? {})?.sort(
		([reportTypeName], [reportTypeName2]) => {
			if (reportTypeName > reportTypeName2) {
				return -1;
			}
			return 0;
		}
	);

	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={loading} />
			<div>
				<GenericFilter
					FILTERS={dynamicFilters}
					changed={changed}
					hideFinder
					setAutoStore={false}
					allStoreOption={false}
					onSubmit={submitFindRequest}
					filterTypes={currentSelectedReport?.filters}
				/>
			</div>

			<div className={styles.container}>
				<div className={styles.navContainer}>
					<div className={styles.nav}>
						<Sidenav
							expanded={expanded}
							defaultOpenKeys={['0', '1', '2', '3']}
						>
							<Sidenav.Body>
								<Nav
									className={styles?.defaultHeight}
									activeKey={activeKey}
									onSelect={(e) => {
										if (e === undefined) {
											setExpanded(true);
										}
										setActiveKey(e);
									}}
								>
									{menuToBuild?.map(
										(
											[reportTypeName, reportData],
											reportTypeIndex
										) => {
											const reportName =
												reportTypeName?.replace(
													'_',
													' '
												);
											return (
												<Nav.Menu
													placement='rightStart'
													eventKey={reportTypeIndex?.toString()}
													title={
														reportName
															?.charAt(0)
															.toUpperCase() +
														reportName?.slice(1)
													}
													onSelect={(ss) => {
														if (ss === undefined) {
															setExpanded(true);
														}

														setActiveKey(
															ss?.toString()
														);
													}}
													icon={<DashboardIcon />}
												>
													{reportData?.map(
														(
															report,
															reportIndex
														) => {
															return (
																<Nav.Item
																	eventKey={
																		reportTypeIndex +
																		'-' +
																		reportIndex
																	}
																>
																	{
																		report?.displayName
																	}
																</Nav.Item>
															);
														}
													)}
												</Nav.Menu>
											);
										}
									)}
								</Nav>
							</Sidenav.Body>
						</Sidenav>
					</div>

					<div className={styles?.hideViewNav}>
						<ExpandMoreIcon
							onClick={() => {
								setExpanded((prev) => {
									return !prev;
								});
							}}
							className={classes(
								styles.expandIcon,
								expanded ? styles.rotateExpandIcon : ''
							)}
						/>
					</div>
				</div>
				<div className={styles.tableContainer}>
					{isCurrentDataFromCache?.length > 0 && (
						<div
							onClick={() => {
								getReportsData(
									currentFilter,
									currentSelectedReport?.id,
									true
								);
							}}
							className={styles.cached}
						>
							<CachedIcon color='inherit' />
							Click to refresh, Last synced at
							<span
								style={{
									padding: '0px 0px 0px .5rem',
									fontSize: '.9rem',
									fontWeight: 500,
								}}
							>
								{isCurrentDataFromCache}
							</span>
						</div>
					)}
					{!holdDataForTableLoad && !currentReportData?.headers && (
						<div className={styles?.tableLoader}>No Data</div>
					)}
					{}
					{holdDataForTableLoad ? (
						<div className={styles?.tableLoader}>
							<CircularProgress color='inherit' />
						</div>
					) : (
						currentReportData?.headers && (
							<>
								<div
									className={styles?.downloadData}
									onClick={() => {
										exportTableDataToCSV(
											`${
												currentSelectedReport?.displayName
											} ${
												isCurrentDataFromCache?.length >
												0
													? isCurrentDataFromCache
													: moment().format(
															'DD/MM/YYYY | h:mm a'
													  )
											}`,
											currentReportData
										);
									}}
								>
									<img src={excel} alt='excel' />
									EXPORT
								</div>
								<div className={styles?.table}>
									<RenderTable data={currentReportData} />
								</div>
							</>
						)
					)}
				</div>
			</div>
		</div>
	);
};
const { Column, ColumnGroup, HeaderCell, Cell } = Table;
const RenderTable = ({ data = [] }) => {
	const [limit, setLimit] = useState(100);
	const [page, setPage] = useState(1);
	const showPagination = useMemo(() => {
		if (data?.rowData?.length > 200) {
			return true;
		}
		return false;
	}, [data?.rowData]);

	const handleChangeLimit = useCallback((dataKey) => {
		setPage(1);
		setLimit(dataKey);
	}, []);
	const headers = useMemo(() => {
		return data?.headers;
	}, [data?.headers]);

	const getData = useCallback(
		(data) => {
			return data?.map((ele) => {
				return {
					colour: ele?.colour,
					...ele?.row,
				};
			});
		},
		[data?.rowData]
	);

	console.log('getData', data?.rowData, getData(data?.rowData));

	const tableData = useMemo(() => {
		return data?.rowData?.filter((v, i) => {
			const start = limit * (page - 1);
			const end = start + limit;
			return i >= start && i < end;
		});
	}, [page, limit, data?.rowData]);

	const CustomCell = ({ rowData, ...props }) => {
		return (
			<Cell {...props} style={{ backgroundColor: rowData?.colour }}>
				{rowData?.[props?.dataKey]}
			</Cell>
		);
	};

	return useMemo(() => {
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
				}}
			>
				<div
					style={{
						height: showPagination ? '95%' : '100%',
					}}
				>
					<Table
						bordered
						cellBordered
						hover
						fillHeight
						wordWrap='keep-all'
						headerHeight={80}
						// virtualized
						data={
							showPagination
								? getData(tableData)
								: getData(data?.rowData)
						}
					>
						{headers?.map((header) => {
							if (header?.keys?.length > 0) {
								return (
									<ColumnGroup
										style={{
											padding: '1rem',
											fontWeight: 600,
											fontSize: '0.9rem',
										}}
										header={header?.displayName}
									>
										{header?.keys?.map((subheader) => {
											return (
												<Column
													width={
														subheader?.displayName
															?.length * 15
													}
												>
													<HeaderCell
														style={{
															padding: '1rem',
															fontWeight: 600,
															fontSize: '0.9rem',
														}}
													>
														{subheader?.displayName}
													</HeaderCell>
													<Cell
														style={{
															fontSize: '.9rem',
														}}
														dataKey={subheader?.key}
													/>
												</Column>
											);
										})}
									</ColumnGroup>
								);
							}

							return (
								<Column
									fixed={header?.fixedHeader}
									width={header?.displayName?.length * 18}
									align='center'
								>
									<HeaderCell
										style={{
											padding: '1rem',
											fontWeight: 600,
											fontSize: '1.1rem',
										}}
									>
										{header?.displayName}
									</HeaderCell>
									{/* <Cell
										style={(rowData) => {
											return {
												fontSize: '.9rem',
												background: rowData?.colour,
											};
										}}
										dataKey={header?.key}
									/> */}
									<CustomCell dataKey={header?.key} />
								</Column>
							);
						})}
					</Table>
				</div>
				{showPagination && (
					<div
						style={{
							padding: '.5rem',
							height: '5%',
						}}
					>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							boundaryLinks
							maxButtons={10}
							size='xs'
							layout={[
								'total',
								'-',
								'limit',
								'|',
								'pager',
								'skip',
							]}
							total={data?.rowData?.length}
							limitOptions={[10, 30, 50, 100, 200, 400]}
							limit={limit}
							activePage={page}
							onChangePage={(l) => {
								setPage(l);
							}}
							onChangeLimit={handleChangeLimit}
						/>
					</div>
				)}
			</div>
		);
	}, [data?.rowData, tableData, headers, limit, page, showPagination]);
};

const mapStateToProps = (state) => {
	const data = state.form.STORES.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchStore: formActions.gateway,
	saveStore: formActions.gateway,
};

CustomerReports.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReports);
