import styles from './VideoFeed.module.css';
import { classes } from '../../../../Helpers';
import TemperatureHUD from '../TempHUD/TemperatureHUD';
import MealDetails from '../../../Meal Details/MealDetails';

function VideoFeed(props) {
	// eslint-disable-next-line object-curly-newline
	const {
		className,
		source,
		weight,
		temp,
		disconnections,
		children,
		live,
		imageContainerRect,
		imageContainerRef,
		sensorValues,
		tempVisibility,
		nutrition,
		isCms,
	} = props;
	return (
		<div className={classes(className, styles.root)}>
			<div ref={imageContainerRef} className={styles['feed-container']}>
				<img
					className={styles.feed}
					src={source}
					alt='Live Video Feed'
				/>
				{tempVisibility && sensorValues?.length > 0 && (
					<TemperatureHUD
						sensorValues={sensorValues}
						imageContainerRect={imageContainerRect}
					/>
				)}
				{nutrition && Object.keys(nutrition).length > 0 && (
					<MealDetails meal={nutrition} />
				)}
			</div>
			<span
				className={classes(
					disconnections?.camera ? styles.disconnected : '',
					styles['camera-info']
				)}
			>
				<span>Camera Disconnected</span>
			</span>
			{live && (
				<div className={styles.footer}>
					<p
						className={classes(
							styles.live,
							styles.left,
							disconnections?.temp ? styles.disconnected : ''
						)}
					>
						{disconnections?.temp ? (
							<span>Temp Disconnected</span>
						) : (
							<>
								<span className={styles.label}>
									Live Temp :
								</span>
								<br />
								{temp?.live}
								{temp?.unit}
							</>
						)}
					</p>
					<p
						className={classes(
							styles.live,
							styles.right,
							disconnections?.weight ? styles.disconnected : ''
						)}
					>
						{disconnections?.weight ? (
							<span>Weight Disconnected</span>
						) : (
							<>
								<span className={styles.label}>
									Live Weight :
								</span>
								<br />
								{weight?.live}
								{weight?.unit}
							</>
						)}
					</p>
				</div>
			)}
			<div className={styles.measurement}>
				<div className={styles['live-measurement-container']}>
					{isCms && (
						<div className={styles['live-measurement']}>
							Net Recipe Weight:
							{weight?.live}
							{weight?.unit}
						</div>
					)}
					{/* {cms && (
							<div className={styles['live-measurement']}>
								Container Weight:
								{' '}
								{containerWeight ?? '0'}
								g
							</div>
						)} */}
				</div>
			</div>
			{children}
		</div>
	);
}

VideoFeed.defaultProps = {
	live: true,
	source: '',
	className: '',
	weight: {
		live: null,
		unit: 'kg',
	},
	temp: {
		live: null,
		unit: '℃',
	},
	disconnections: {},
};

export default VideoFeed;
