import { cloneDeep, update } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions, productActions } from '../../../../Actions';
import DraggableHTabs from '../../../../Components/Forms/UpdateAvaibility/SliderDiscount/DraggableHtabs/DraggableHTabs';
import { FormSectionHeader } from '../../../../Components/Forms';
import CategoryAvailibility from '../../../../Components/Forms/UpdateAvaibility/CategoryAvailibility';
import { CreatePageHeader } from '../../../../Components/Headers';
import { history, isEmpty } from '../../../../Helpers';
import UpdateAvailibiltyConfig from './UpdateAvailibiltyPage.config';
import styles from './UpdateAvailibiltyPage.module.scss';
import { urls } from '../../../../Constants';

const UpdateAvailibiltyPage = (props) => {
	const handleCancel = () => {
		history.push(`${urls.EXTENSION}${urls.STORE_SETTINGS}`);
	};
	const { id } = useRouteMatch().params;
	const {
		data,
		storeData,
		fetchMenus,
		fetchProducts,
		toggleProducts,
		saveMenus,
		breadcrumbs,
	} = props;
	const [state, setState] = useState({
		...UpdateAvailibiltyConfig.initialState,
	});
	const { fields } = state;
	const handleSave = (e) => {
		e.preventDefault();
		saveMenus({
			entity: 'STORES',
			req: {
				params: {
					id: storeData[id].id,
				},
				data: {
					menuAvailability: fields.MakeUnAvailable,
				},
			},
			method: 'PATCH',
			redirect: `${urls.EXTENSION}${urls.STORE_SETTINGS}`,
		});
	};

	const [activeCategory, setActiveCategory] = useState(
		state.fields.categories.order.length
	);
	const [activeSubCategory, setActiveSubCategory] = useState(
		state.fields.categories.items[activeCategory].subCategories.order.length
	);
	useEffect(() => {
		fetchMenus({
			entity: 'MASTER_MENUS',
			req: {
				params: {
					id: storeData[id].menuId,
				},
			},
			method: 'VIEW',
		});
		fetchProducts({
			params: {
				id: '',
				skuType: 'PRODUCT',
			},
		});
	}, []);
	const { MakeUnAvailable } = state.fields;

	useEffect(
		() => {
			if (!isEmpty(data)) {
				setState(
					UpdateAvailibiltyConfig.serializer(
						state,
						data,
						storeData[id].menuAvailability
					)
				);
			}
		},

		[data],
		[MakeUnAvailable]
	);
	const handleSliderChange = (target, changedValue) => {
		setState(
			update(
				cloneDeep(state),
				`fields.categories.items[${activeCategory}].subCategories.items[${activeSubCategory}].selectedProducts[${target}].discount`,
				() => {
					return changedValue;
				}
			)
		);
	};
	const handleSubCategoryReOrdering = (dragIndex, hoverIndex) => {
		const newState = update(
			cloneDeep(state),
			`fields.categories.items[${activeCategory}].subCategories.order`,
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};
	const handleActiveCategory = (tempId) => {
		if (
			fields.categories.items[tempId].subCategories.order.length <
			activeSubCategory
		) {
			setActiveSubCategory(1);
		}
		setActiveCategory(tempId);
	};
	const handleInput = (fieldName, toggleId) => {
		const newState = update(
			cloneDeep(state),
			`fields.MakeUnAvailable[${fieldName}]`,
			(arr) => {
				const dragObj = arr;
				if (dragObj.includes(toggleId)) {
					const narr = dragObj.filter((fid) => {
						return fid !== toggleId;
					});
					return narr;
				}
				dragObj.push(toggleId);

				return dragObj;
			}
		);
		setState(newState);
	};
	const openProducts = () => {
		toggleProducts(true, {
			itemTypes: ['PRODUCT', 'INGREDIENT', 'INTERMEDIATE'],
		});
	};
	return (
		<div className={`${styles.root}`}>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Update Availibility'
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: 'Update Availibility',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<DraggableHTabs
						items={fields.categories.items}
						order={fields.categories.order}
						handleActiveTab={handleActiveCategory}
						activeTab={activeCategory}
						limit={5}
						text='Category'
						isDraggable={false}
					/>
					<CategoryAvailibility
						categoryDetails={
							fields.categories.items[activeCategory]
						}
						handleInput={handleInput}
						activeCategory={activeCategory}
						fields={fields}
						activeSubCategory={activeSubCategory}
						openProducts={openProducts}
						setActiveSubCategory={setActiveSubCategory}
						handleSubCategoryReOrdering={
							handleSubCategoryReOrdering
						}
						handleSliderChange={handleSliderChange}
						handleSwitchChange={handleInput}
						handleSave={handleSave}
						MakeUnAvailable={MakeUnAvailable}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MASTER_MENUS.data.VIEW;
	const storeData = state.form.STORES.data.LIST;
	return {
		data,
		storeData,
	};
};

const mapDispatchToProps = {
	fetchMenus: formActions.gateway,
	fetchProducts: productActions.fetchProducts,
	saveMenus: formActions.gateway,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateAvailibiltyPage);
