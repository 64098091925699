/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './SearchModal.module.scss';
import { modalActions } from '../../../Actions';

const SearchModal = (props) => {
	const history = useHistory();
	const { show, toggleModal, data, navState, toggleNavItemRequisitesModal } =
		props;
	const [searchQuery, setSearchQuery] = useState('');
	const [navList, setNavList] = useState([]);
	const [searchedNavList, setSearchedNavList] = useState([]);

	const handleClose = () => {
		toggleModal(false, {});
		setSearchQuery('');
	};

	const handleClick = (navItemEle) => {
		if (navItemEle?.isRequisiteRequired) {
			toggleNavItemRequisitesModal(true, {
				navKey: navItemEle?.parentKey,
				navItem: navItemEle?.parentNav,
				redirectTo: navItemEle?.href,
			});
			handleClose();
			return;
		}
		if (navItemEle?.href) {
			history?.push(
				navItemEle?.href
			);
		}
		handleClose();
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && searchQuery?.length > 0 && searchedNavList.length > 0) {
			handleClick(searchedNavList[0]);
		}
	};

	useEffect(() => {
		setSearchedNavList(navList
			?.filter((navItemEle) => {
				return (
					navItemEle?.name
						?.toLowerCase()
						?.includes(
							searchQuery?.toLowerCase()
						) &&
					!navItemEle?.disabled &&
					navItemEle?.active &&
					!navItemEle?.submenu
				);
			}));
	}, [searchQuery]);

	useEffect(() => {
		if (show) {
			let currentFiller = null;
			const result = Object.keys(navState)?.reduce((acc, eleKey) => {
				const navItemEle = navState?.[eleKey];
				const submenus = navItemEle?.submenu ? Object.keys(
					navItemEle?.submenu
				) : [];
				const isRequisiteRequired =
					navItemEle?.requisites?.length &&
					navItemEle?.requisites?.find((req) => {
						return req?.type === 'store';
					});
				if (navItemEle?.type === 'NON_LINK' && navItemEle?.name !== '') {
					currentFiller = navItemEle;
				}
				if (submenus?.length > 0) {
					submenus?.forEach((subMenuKey) => {
						const subMenuEle = navItemEle?.submenu?.[subMenuKey];
						acc.push({
							...subMenuEle,
							parentKey: eleKey,
							parentFiller: currentFiller,
							parentNav: navItemEle,
							isRequisiteRequired,
						});
					});
				}
				acc.push(
					{
						...navItemEle,
						parentKey: eleKey,
						parentFiller: currentFiller,
						isRequisiteRequired,
					}
				);
				return acc;
			}, []);
			setNavList(result);
		}
	}, [navState, show]);

	// console.log('currentFiller', navList);

	return (
		<Modal className={styles.model} open={show} onClose={handleClose}>
			<div className={styles.root}>
				<div className={styles.body}>
					<div className={styles['input-container']}>
						<input
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							className={styles['search-input']}
							placeholder='Search Tab'
							value={searchQuery}
							onChange={(e) => {
								setSearchQuery(e?.target?.value);
							}}
							onKeyPress={handleKeyPress}
						/>
					</div>
					{searchQuery?.length > 0 && (
						<div className={styles['result-container']}>
							{searchedNavList
								?.map((navItemEle) => {
									return (
										<div
											onClick={() => handleClick(navItemEle)}
											className={styles.result}
										>
											<p className={styles.filler}>
												Under
												{' '}
												{navItemEle?.parentFiller?.name}
											</p>
											<p className={styles.name}>
												{navItemEle?.parentNav ? navItemEle?.parentNav?.name : ''}
												/
												{navItemEle?.name}
											</p>
										</div>
									);
								})}
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.searchModal;
	const { navState } = state.navigation;
	return {
		show,
		data,
		navState,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleSearchModal,
	toggleNavItemRequisitesModal: modalActions.toggleNavItemRequisitesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);
