import _ from 'lodash';
import { connect } from 'react-redux';
import { ComboChoice } from '../ComboChoice';
import { AddProductCard, ProductCard } from '../..';
import { modalActions } from '../../../../Actions';
import { generateRequiredProps, isEmpty } from '../../../../Helpers';
import styles from './ComboGroup.module.scss';
import { modalTypes } from '../../../../Constants';

const ComboGroup = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		item,
		id,
		toggleModal,
		products,
		handleDelete,
		mandatoryFields,
		isDisabled,
	} = props;
	const handleInput = (e) => {
		if (e.target.dataset.type === 'choice') {
			e.target.dataset.parent = id;
		}
		props.handleInput(e);
	};

	const openProductVariants = () => {
		toggleModal(true, id);
	};

	if (isEmpty(products)) {
		return <></>;
	}
	return (
		<div className={`${styles.root}`}>
			{/* group name */}
			<div className='form-field row'>
				<label
					className='form-label'
					htmlFor={`groupName-${id}`}
					data-mandatory={mandatoryFields.variants[0].groups[0].name}
				>
					Selection Name
				</label>
				<input
					className='form-value'
					type='text'
					id={`groupName-${id}`}
					name='name'
					onInput={handleInput}
					value={item.name}
					data-type='group'
					data-id={id}
					{...generateRequiredProps(
						mandatoryFields.variants[0].groups[0].name
					)}
				/>
			</div>

			<div className={`${styles['choices-wrapper']}`}>
				{item.choices.map((choice, idx) => {
					const product = _.get(products, [choice.productId]) || {};
					const variant =
						_.get(products, [
							choice.productId,
							'ProductVariant',
							choice.variantId,
						]) || {};
					return (
						<ProductCard
							title={product.productName}
							img={product.productImageUrl}
							key={`${choice.productId}-${choice.variantId}`}
							idx={idx}
							handleDelete={handleDelete}
							dataAttrs={{
								'data-type': 'choice',
								'data-id': idx,
								'data-parent': id,
							}}
							isDisabled={isDisabled}
						>
							<ComboChoice
								variant={variant}
								product={product}
								isDefault={choice.default}
								price={choice.price}
								additionalPrice={choice.additionalPrice}
								id={`${choice.productId}-${choice.variantId}`}
								idx={idx}
								handleInput={handleInput}
								isOptional={item.optional}
								mandatoryFields={mandatoryFields}
							/>
						</ProductCard>
					);
				})}
				{!isDisabled && !item?.choices?.length && (
					<AddProductCard
						text='Product'
						handleClick={openProductVariants}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { products } = state.product;
	return {
		products,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, groupId) => {
			dispatch(
				modalActions.toggleModal(modalTypes.PRODUCT_VARIANTS, show, {
					groupId,
				})
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboGroup);
