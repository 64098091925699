/* eslint-disable no-console */
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineDot from '@mui/lab/TimelineDot';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import Lottie from 'lottie-react';
import { TimelineOppositeContent } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Cards from './Cards';
import { modalActions } from '../../../Actions';
import colors from './CardColours';
import { notificationServices } from '../../../Services/Notification';
import orderTrackingServices from '../../../Services/orderTracking.services';
// import image1 from '../../../Images/orderTrackingAssets/ot-1.json';
import Unload from '../../../Images/orderTrackingAssets/store_unloading.json';
import image3 from '../../../Images/orderTrackingAssets/packaging-in-progress.json';
import image5 from '../../../Images/orderTrackingAssets/ot-5.json';
import dispatch from '../../../Images/orderTrackingAssets/ot-6.json';
import negChiller from '../../../Images/orderTrackingAssets/-ve freezer.json';
import posChiller from '../../../Images/orderTrackingAssets/+ve freezer.json';
import verification from '../../../Images/orderTrackingAssets/verification.json';
import Customer from '../../../Images/orderTrackingAssets/delivery-guy.json';
import Audit from '../../../Images/orderTrackingAssets/audit.json';
import Label from '../../../Images/orderTrackingAssets/ot-4.json';
import { BackdropLoader } from '../../Loaders';
// import { endpoints } from '../../../Constants';

function Timecards({
	id, toggleImageModal, processSelect, poData, selectProcess, orderId, type,
}) {
	const [nodes, setNodes] = useState();
	const [isLoading, setIsLoading] = useState(false);
	async function fetchQRCode(qrId, oId, idType) {
		setIsLoading(true);
		console.log('loading', isLoading);
		try {
			const response = await orderTrackingServices.fetchTimeline({
				params: {
					qrCode: qrId,
					orderId: oId,
					idType,
				},
			});
			notificationServices.generateNotification({
				type: 'success',
				message: response?.message,
			});
			setNodes(
				response?.data?.map((ele, idx) => {
					return {
						...ele,
						data: {
							...ele?.data,
							color: ele?.data?.color
								? ele?.data?.color
								: colors[idx % 16],
						},
					};
				})
			);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(async () => {
		if (id) {
			fetchQRCode(id, orderId, type);
		}
		if (processSelect === 'thirdbt') {
			if (poData) {
				setNodes(poData);
			}
		}
	}, [id, poData, selectProcess]);

	const handleImageClick = (image) => {
		toggleImageModal(true, {
			image,
			width: '50rem',
			height: '40rem',
		});
	};
	function checkNegPos(name) {
		if (name?.includes('-')) {
			return true;
		}
		return false;
	}
	function selectImage(name, valType) {
		switch (name) {
			case 'Labelling':
				return (Label);
			case 'Packaging':
				return (image3);
			case 'Dispatch':
				return (dispatch);
			case 'TRANSIT':
				return (image5);
			case 'UNLOADING':
				return (Unload);
			case 'VERIFICATION':
				return (verification);
			case 'Customer Dispatch':
				return (Customer);
			case 'Audit':
				return (Audit);
			default:
				return (checkNegPos(valType) ? negChiller : posChiller);
		}
	}
	return (
		<>
			{/* {closeModal && <ModalData image={image} closeModal={setCloseModal} />}
			{clos} */}
			<BackdropLoader isLoading={isLoading} showProgress />
			<Timeline
				position='alternate'
				style={{
					padding: 0,
				}}
			>
				{(nodes ?? [])?.map((node, index) => {
					return (
						<TimelineItem
							key={node.id}
						>
							<TimelineOppositeContent color='text.secondary'>
								<div className='side'>
									{ (index % 2 !== 0) && (
										<div className='backIcon'>
											<ArrowBackIcon />
											{' '}
										</div>
									)}
									<div className='image'>
										<Lottie
											animationData={selectImage(node?.data?.name, node?.data?.assetType)}
											style={{
												height: '150px',
											}}
										/>
									</div>
									{ (index % 2 === 0) && (
										<div className='forwardIcon'>
											<ArrowForwardIcon />
											{' '}
										</div>
									)}
								</div>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<SwipeUpAltIcon
									style={{
										color: node?.data?.color,
										padding: '.5rem',
										borderRadius: '50%',
										fontSize: '3rem',
										// eslint-disable-next-line prefer-template
										border: '1px solid ' + node?.data?.color,
									}}
								/>
								<TimelineConnector />
								{/* <ArrowForwardIcon /> */}
							</TimelineSeparator>
							<TimelineContent
								style={{
									textAlign: 'left',
								}}
							>
								<Cards node={node} handleImageClick={handleImageClick} />
							</TimelineContent>
						</TimelineItem>
					);
				})}
			</Timeline>
		</>
	);
}

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	toggleImageModal: modalActions.toggleImageModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Timecards);
