import { Skeleton } from '@mui/material';
import styles from './BarChartSkeleton.module.css';

const BARS = [...Array(15).keys()];

const BarChartSkeleton = () => {
	return (
		<div className={styles.root}>
			{BARS.map((bar) => {
				const height = `${Math.floor(Math.random() * 95)}%`;
				return (
					<Skeleton
						key={bar}
						variant='rectangular'
						width={50}
						height={height}
					/>
				);
			})}
		</div>
	);
};

export default BarChartSkeleton;
