import { cloneDeep } from 'lodash';
import { retagConstants } from '../Constants';
import { retagServices } from '../Services';

const retagActions = {
	fetchRetagReasons,
	addRetagReason,
};

function fetchRetagReasons(req) {
	return async (dispatch) => {
		try {
			const response = await retagServices.fetchRetagReasons(req);
			const { data } = response;
			dispatch(success(data));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	function success(payload) {
		return {
			type: retagConstants.FETCH_RETAG_REASONS_SUCCESS,
			payload,
		};
	}
}

function addRetagReason(reason, brand) {
	return (dispatch, getState) => {
		const { retagReasons } = cloneDeep(getState().retag);
		retagReasons[brand].push(reason);
		dispatch({
			type: retagConstants.ADD_RETAG_REASON,
			payload: retagReasons,
		});
	};
}

export default retagActions;
