export const chartTypes = {
	pieChart: 'PIE',
	areaChart: 'AREA',
	barChart: 'BAR',
	barWithLineChart: 'BARLINE',
	listTable: 'TABLE',
};

export const tilestype = {
	percent: 'PERCENT',
	count: 'COUNT',
	weight: 'WEIGHT',
	amount: 'AMOUNT',
	genric: 'GENERIC',
};

export const chart = {
	statsHodler: [
		{
			stats: [], heading: '',
		},
		{
			stats: [], heading: '',
		},
	],
	chartHolder: [
		{
			charts: [],
			heading: 'absolute chart',
		},
		{
			charts: [],
			heading: '',
		},
	],
};

// ListTable
// charts tooltip
// i at top corner last hover
// xlabel ylabel for charts,
// percent on pizza slices
// side arrow..
/*

*/
export const tempData = {
	itemStats: [
		{
			heading: 'abc',
			type: 'GENRIC',
			value: 1314.32,
			tooltip: 'whgdjkahdcw',
			stats: [
				{
					heading: 'abc',
					type: 'WEIGHT',
					value: 14.32,
					unit: 'kg',
					tooltip: 'whgdjkahdcw',
				},
				{
					heading: 'abc',
					type: 'PERCENT',
					value: 1314.32,
					unit: '%',
					tooltip: 'whgdjkahdcw',
				},
			],
		},
		{
			heading: 'New Data',
			type: 'GENRIC',
			value: 1314.32,
			tooltip: 'whgdjkahdcw',
			stats: [
				{
					heading: 'abc',
					type: 'WEIGHT',
					value: 14.32,
					unit: 'kg',
					tooltip: 'whgdjkahdcw',
				},
				{
					heading: 'abc',
					type: 'PERCENT',
					value: 1314.32,
					unit: '%',
					tooltip: 'whgdjkahdcw',
				},
				{
					heading: 'abc',
					type: 'AMOUNT',
					value: 1314.32,
					unit: 'Rs',
					tooltip: 'whgdjkahdcw',
				},
			],
		},
		{
			heading: 'abc',
			type: 'WEIGHT',
			value: 114.32,
			unit: 'kg',
			tooltip: 'weight of inwarded',
		},
	],
	chartList: [
		{
			type: 'BAR',
			heading: 'PO Vs Fulfilled',
			tooltip: 'PO Vs Fulfilled data in kgs',
			xLabel: 'Vendors',
			yLabel: 'kg',
			labels: ['Vendor 1', 'Vendor 2', 'Vendor 3', 'someVen'],
			datasets: [
				{
					label: 'PO Quantity',
					data: ['100', '400', '200', '500'],
				},
				{
					label: 'Fulfilled',
					data: ['100', '400', '200', '600'],
				},
			],
		},
		{
			type: 'BARLINE',
			heading: 'Fulfilled vs Defetcs',
			tooltip: 'Fulfilled vs Defetcs data in kgs',
			xLabel: 'Vendors',
			yLabel: 'kg',
			yLabelLine: '%',
			labels: ['Vendor 1', 'Vendor 2', 'Vendor 3'],
			datasets: [
				{
					label: 'PO Quantity',
					data: ['100', '400', '200'],
					type: 'BAR',
				},
				{
					label: 'Fulfilled',
					data: ['100', '400', '200'],
					type: 'BAR',
				},
				{
					label: 'Defect %',
					data: ['10', '5', '20'],
					type: 'LINE',
				},
				{
					label: 'Defect wihda',
					data: ['20', '100', '10'],
					type: 'LINE',
				},
				{
					label: 'Defect wihda',
					data: ['70', '20', '50'],
					type: 'LINE',
				},
				{
					label: 'Defect wihda',
					data: ['5', '10', '100'],
					type: 'LINE',
				},
			],
		},
		{
			type: 'PIE',
			heading: 'Fulfillment Distribution',
			tooltip: 'Fulfillment Distribution data in kgs',
			xLabel: 'Vendors',
			yLabel: 'kg',
			labels: ['Vendosad0  1', 'Vendor 2', 'Vendor 3', 'Vendero'],
			datasets: [
				{
					data: ['100', '400', '2000', '234'],
				},
			],
		},
		{
			type: 'AREA',
			heading: 'Inwarding Last 7 Days',
			tooltip: 'Inwarding Last 7 Days',
			xLabel: 'day',
			yLabel: 'kg',
			labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			datasets: [
				{
					label: 'PO Quantity',
					data: ['100', '400', '200'],
				},
			],
		},
		{
			type: 'AREA',
			heading: 'Inwarding Last 7 Days',
			tooltip: 'Inwarding Last 7 Days',
			xLabel: 'day',
			yLabel: 'kg',
			labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			datasets: [
				{
					label: 'PO Quantity',
					data: ['100', '400', '200'],
				},
			],
		},
		{
			type: 'TABLE',
			heading: 'Top 5 SKU',
			tooltip: 'Top 5 SKU',
			datasets: [
				{
					data: [
						'Fresh Mushroom  Button',
						'Frozen Sweet asjdhnjk akjsnd askjd Corn',
						'Fresh Mushroom  Button',
						'Frozen Sweet Corn',
						'Fresh Mushroom  Button',
						'Frozen Sweet Corn',
						'Fresh Mushroom  Button',
						'Frozen Sweet Corn',
						'Fresh Mushroom  Button',
						'Frozen Sweet Corn',
					],
				},
				{
					data: [
						'4750kg',
						'4700kg',
						'4750kg',
						'4700kg',
						'4750kg',
						'4700kg',
						'4750kg',
						'4700kg',
						'4750kg',
						'4700kg',
						'4750kg',
						'4700kg',
					],
				},
				{
					data: [
						'-10',
						'+20',
						'-10',
						'+20',
						'-10',
						'+20',
						'-10',
						'+20',
						'-10',
						'+20',
						'-10',
						'+20',
					],
					type: 'DELTA',
				},
				{
					data: [
						'871',
						'872',
						'873',
						'874',
						'875',
						'876',
						'124',
						'732',
						'124',
						'732',
						'124',
						'732',
					],
					type: 'PRODUCT',
				},
			],
		},
	],
};
