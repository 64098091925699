/* eslint-disable prefer-template */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
/* eslint-disable prefer-spread */
// import { Visibility } from '@mui/icons-material';
// import { useEffect } from 'react';
// import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ListTable } from '../../../../Components/ListTable';
// import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import styles from './SkuStock.module.scss';
import analyticsAction from '../../../../Actions/analytics.actions';
import {
	addCommas, classes, convertToHigherUnit, getMonetaryUnit, handleExcelDownload
} from '../../../../Helpers';
import drawerActions from '@/Actions/drawer.actions';
import StockHistoryDrawer from '@/Components/Drawers/StockHistoryDrawer/StockHistoryDrawer';
import { useAuth } from '@/Components/Auth/Login/auth';
// import { isEmpty } from '../../../../Helpers/utils';
// import ImageModal from '../../../../Components/Modals/ImageModal/ImageModal';

const TABLE_COLUMNS = [
	{
		title: 'UY Sku ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sub Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Current Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Stock worth',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory Par Level',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Required Stock',
		config: {
			type: 'DATA',
		},
	},
];

let objValues = [];

const ACTION_COLUMNS = [];

const COLMAP = {
	'UY Sku ID': 'id',
	SKU: 'skuName',
	'Inventory Unit': 'unit',
	Category: 'categoryName',
	'Sub Category': 'subCategoryName',
	'SKU Type': 'skuType',
	'Current Stock': 'currentStock',
	'Stock worth': 'currentStock',
	'Inventory Par Level': 'threshold',
	'Required Stock': 'stockRequired',
};

const SkuStockReport = (props) => {
	const { data, query, skuType, stockDeficit, toggleStockHistoryDrawer } =
		props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};

	objValues = Object.values(data);
	// objValues = [].concat.apply([], objValues);

	const SPECIAL_ITEMS = {
		'Current Stock': (id) => {
			const item = objValues?.find((ele) => {
				return ele?.id === id;
			});
			return item?.currentStock?.map((stock, i) => {
				const stockInNum =
					stock?.qty?.split(' ')[0] !== '-'
						? Number(stock?.qty?.split(' ')[0])
						: '';
				const thresholdInNum =
					item?.threshold[i]?.qty?.split(' ')[0] !== '-'
						? Number(item?.threshold[i]?.qty?.split(' ')[0])
						: '';
				const isLessThan = stockInNum < thresholdInNum;
				const qtyValue = stock?.qty?.split(' ')?.[0];
				const qtyUnit = stock?.qty?.split(' ')?.[1];
				// eslint-disable-next-line no-restricted-globals
				const NumberQty = !isNaN(Number(qtyValue))
					? Number(qtyValue)
					: 0;
				return (
					<div className='flex gap-1 items-center'>
						{/* {isLessThan && (
							<p className={styles['summary-text']}>
								Current Stock is less than Threshold
							</p>
						)} */}
						<div
							className={classes(
								styles['multi-items'],
								isLessThan ? styles.less : ''
							)}
						>
							{/* {isLessThan && (
								<p></p>
							)} */}
							<p>{stock?.variantName}:</p>
							<p>
								{NumberQty < 0 ? 0 : NumberQty} {qtyUnit}
							</p>
						</div>
						<div
							onClick={() => {
								toggleStockHistoryDrawer(true, {
									itemDetails: {
										...item,
										variantId: stock?.variantId,
										variantName: stock?.variantName,
									},
									heading: `Stock Trail of ${
											item?.skuName
										}${
											stock?.variantName
												? `(${stock?.variantName})`
												: ''
										}`,
								});
							}}
							className='text-sm underline text-uy-color font-semibold pr-4 cursor-pointer'
						>
							Trail
						</div>
					</div>
				);
			});
		},
		'Stock worth': (id) => {
			const item = objValues?.find((ele) => {
				return ele?.id === id;
			});
			return item?.currentStock?.map((stock) => {
				const perUnit = convertToHigherUnit(item?.unit)?.unit;
				const moneyUnit = getMonetaryUnit(measurement);
				return (
					<div className='flex gap-1 items-center relative'>
						<div className={classes(styles['multi-items'])}>
							<div className='flex flex-col items-start'>
								<p className='text-xs text-blue-600'>
									{moneyUnit}
									{stock?.rate}
									{' '}
									per {perUnit}
								</p>
								<p>{stock?.variantName}:</p>
							</div>
							<p>
								{addCommas(stock?.stockAmount, measurement) ??
									'-'}
							</p>
						</div>
					</div>
				);
			});
		},
		'Inventory Par Level': (id) => {
			const item = objValues?.find((ele) => {
				return ele?.id === id;
			});
			return item?.threshold?.map((stock, i) => {
				const stockInNum =
					item?.currentStock[i]?.qty?.split(' ')[0] !== '-'
						? Number(item?.currentStock[i]?.qty?.split(' ')[0])
						: '';
				const thresholdInNum =
					stock?.qty?.split(' ')[0] !== '-'
						? Number(stock?.qty?.split(' ')[0])
						: '';
				const isLessThan = stockInNum < thresholdInNum;
				return (
					<div className={styles['items-container']}>
						{/* {isLessThan && (
							<p className={styles['summary-text']}>
								Threshold is more than Current Stock
							</p>
						)} */}
						<div
							className={classes(
								styles['multi-items'],
								isLessThan ? styles.less : ''
							)}
						>
							<p>{stock?.variantName}:</p>
							<p>{stock?.qty}</p>
						</div>
					</div>
				);
			});
		},
		'Required Stock': (id) => {
			const item = objValues?.find((ele) => {
				return ele?.id === id;
			});
			return item?.stockRequired?.map((stock) => {
				return (
					<div className={styles['items-container']}>
						<div className={styles['multi-items']}>
							<p>{stock?.variantName}:</p>
							<p>{stock?.qty}</p>
						</div>
					</div>
				);
			});
		},
	};

	const handleExport = () => {
		const currDate = moment(new Date()).format('YYYY-MM-DD hh:mm');
		const headers = [
			'SKU Name',
			'SKU Type',
			'Category',
			'Sub Category',
			'Item Rate',
			'Current Stock',
			'Stock worth',
			'Threshold',
		];
		const totalValues = [];
		objValues?.forEach((item) => {
			const skuName = item?.skuName?.split(',').join("'");
			const perUnit = convertToHigherUnit(item?.unit)?.unit;
			item?.currentStock?.forEach((stock, idx) => {
				const productName = skuName + (stock.variantName ? ' (' + stock.variantName + ')' : '');
				const rate = stock?.rate ? stock?.rate + ' per ' + perUnit : '-';
				totalValues?.push([
					productName,
					item.skuType ?? '-',
					item.categoryName ?? '-',
					item.subCategoryName ?? '-',
					rate,
					stock.qty ?? '-',
					stock?.stockAmount?.toFixed(2) ?? '-',
					item?.threshold[idx]?.qty ?? '-',
				]);
			});
		});
		handleExcelDownload(headers, totalValues, `Current Stock ${currDate}`);
	};

	return (
		<div className={`${styles.root}`}>
			<StockHistoryDrawer />
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={objValues
					.filter((SKU) => {
						const skuNameMatches =
							SKU.skuName
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1;
						const skuTypeMatches =
							!skuType ||
							skuType === 'ALL' ||
							SKU.skuType === skuType;
						return skuNameMatches && skuTypeMatches;
						// eslint-disable-next-line consistent-return, array-callback-return
					})
					.map((ele) => {
						if (!stockDeficit) return ele;
						const isLessThanStock = ele?.currentStock?.map(
							(stock, i) => {
								const stockInNum =
									stock?.qty?.split(' ')[0] !== '-'
										? Number(stock?.qty?.split(' ')[0])
										: '';
								const thresholdInNum =
									ele?.threshold[i]?.qty?.split(' ')[0] !==
									'-'
										? Number(
											ele?.threshold[i]?.qty?.split(
												' '
											)[0]
										)
										: '';
								return stockInNum < thresholdInNum;
							}
						);
						return isLessThanStock?.includes(true) ? ele : null;
					})
					.filter(Boolean)}
				special={SPECIAL_ITEMS}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				onExportClick={handleExport}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	// const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const data = state.analytics.INVENTORY_ANALYTICS.SKU_STOCK;
	return {
		// dateRangeFilter,
		data,
	};
};

const mapDispatchToProps = {
	fetchData: analyticsAction.fetchDataRange,
	toggleStockHistoryDrawer: drawerActions.toggleStockHistoryDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuStockReport);
