/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import {
	useCallback, useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import './OrderTracking.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Nav from 'rsuite/Nav';
import Dummy2 from '../../Components/OrderTracking/DropDownInfo/Dummy2';
import DropdownInfo from '../../Components/OrderTracking/DropDownInfo/DropdownInfo';
import notificationServices from '../../Services/notification.services';
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
import frontImage from '../../Images/front_image.jpg';
import orderTrackingServices from '../../Services/orderTracking.services';
import { BackdropLoader } from '../../Components/Loaders';
import { useQuery } from '../../Helpers';

function OrderTracking(parentProps) {
	const { userPermissions } = parentProps;

	const [inputQuery, setInputQuery] = useState(null);
	const [showOrderData, setShowOrderData] = useState(false);
	const [showmapping, setShowMapping] = useState(false);
	const [selectedId, setselectedId] = useState(null);
	const [idForItem, setIdForItem] = useState();
	const [selectedState, setSelectedState] = useState({
		orderId: '',
	});
	// eslint-disable-next-line no-unused-vars
	const [orders, setOrders] = useState({});
	const [showDiv, setShowDiv] = useState(false);
	const [btselect, setBtselect] = useState('firstbt');
	// eslint-disable-next-line no-unused-vars
	const [selectProcess, setSelectProcess] = useState('scm');
	const [prodId, setProdId] = useState();
	const [productName, setProductName] = useState();
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const query = useQuery();

	function generateNotification(type, message) {
		notificationServices.generateNotification({
			type,
			message,
		});
	}

	function notification(statusCode, message) {
		if (message === 'Auth_Invalid') {
			generateNotification('error', 'Invalid Session. Please login again.');
			return;
		}
		switch (statusCode) {
			case 200:
				generateNotification('success', message);
				break;
			case 404:
				generateNotification('error', message);
				break;
			case 500:
				generateNotification('error', message);
				break;
			default:
				generateNotification('error', 'Internal Server Error');
		}
	}

	const resetState = () => {
		setInputQuery(null);
		setIdForItem(null);
		setselectedId(null);
		setSelectedState(null);
		setOrders(null);
		setShowMapping(false);
		setShowDiv(false);
	};
	const fetchQRCode = async () => {
		try {
			const response = await orderTrackingServices.fetchQRCode({
				params: {
					qrCode: inputQuery,
				},
			});
			notification(response?.statusCode, response?.message);
			if (response?.statusCode === 404) {
				resetState();
				setShowMapping(false);
			}
			setOrders(response.data);
			setShowOrderData(true);
			setIdForItem(null);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchOrders = async (type) => {
		setIsLoading(true);
		try {
			const response = await orderTrackingServices.fetchOrders({
				params: {
					orderId: inputQuery,
					type,
				},
			});
			if (response?.statusCode === 200) {
				notification(response.statusCode, response.message);
				if (response?.data) {
					if (response?.data?.items?.length === 1) {
						setIdForItem(response?.data?.items?.id);
						setShowMapping(true);
					} else {
						setShowMapping(false);
						setIdForItem(null);
						setShowDiv(false);
						// setSelectedState(null);
					}
					setOrders({
						...response?.data,
					});
					setShowOrderData(true);
				} else {
					resetState();
				}
			} else {
				resetState();
				notification(response.statusCode, response.message);
			}
			setIdForItem(null);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error');
		} finally {
			setIsLoading(false);
		}
	};

	const fetchPOID = async () => {
		setIsLoading(true);
		try {
			const response = await orderTrackingServices.fetchPOID({
				params: {
					poID: inputQuery,
				},
			});
			notification(response?.statusCode, response?.message);
			if (response?.statusCode === 404) {
				resetState();
			} else if (response?.statusCode === 200) {
				setOrders(response?.data);
				setShowMapping(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchAPI = useCallback(() => {
		if (inputQuery) {
			if (btselect === 'secondbt') {
				fetchQRCode();
				return;
			}
			if (btselect === 'firstbt') {
				setSelectedState({
					orderId: inputQuery,
					type: 'urban piper',
				});
				fetchOrders('urban piper');
				return;
			}
			if (btselect === 'fourthbt') {
				setSelectedState({
					orderId: inputQuery,
					type: 'uyDbId',
				});
				fetchOrders('uyDbId');
				return;
			}
			fetchPOID();
		}
	}, [btselect, inputQuery]);

	const handleChange = (event) => {
		if (event.target.value !== btselect) {
			document.getElementById('in').value = '';
			resetState();
		}
		setBtselect(event.target.value);
	};

	// const handleProcess = (process) => {
	// setSelectProcess(process);
	// };
	useEffect(() => {
		if (inputQuery && query && query.has('value')) {
			fetchAPI();
		}
	}, [query, inputQuery]);

	useEffect(() => {
		if (query.has('type') && query.has('value')) {
			const type = query.get('type');
			const value = query.get('value');
			if (type === 'qrCode') {
				setInputQuery(value);
				setBtselect('secondbt');
			}
			if (type === 'orderId') {
				setInputQuery(value);
				setBtselect('fourthbt');
			}
		}
	}, [query]);

	const Navbar = ({ active, onSelect, ...props }) => {
		return (
			<Nav {...props} activeKey={active} onSelect={onSelect}>
				{userPermissions?.INTERNAL && (
					<Nav.Item
						eventKey='fms'
						style={{
							backgroundColor: '#e0efde',
						}}
					>
						Pre Production
					</Nav.Item>
				)}
				<Nav.Item
					eventKey='scm'
					style={{
						backgroundColor: 'bisque',
					}}
				>
					Post Production
				</Nav.Item>
			</Nav>
		);
	};

	const getPlaceholder = () => {
		if (btselect === 'firstbt') {
			return 'Search on Partner Order ID';
		}
		if (btselect === 'secondbt') {
			return 'Search on QR Code';
		}
		if (btselect === 'fourthbt') {
			return 'UY Order ID';
		}
		return 'Search on PO ID';
	};

	return (
		<>
			<BackdropLoader isLoading={isLoading} showProgress />
			<ImageModal />
			<div className='start'>
				<div className='front'>
					<div className='text'>
						<div className='text-heading'>Product Traceability</div>
						<Navbar
							className='sub-heading'
							appearance='tabs'
							active={selectProcess}
							onSelect={setSelectProcess}
						/>
					</div>
					<div>
						{showOrderData && (
							<DropdownInfo
								width='410px'
								inputLists={orders?.items}
								isQrSelect={btselect}
								placeholder='select a product'
								showImage='true'
								idd={idForItem}
								setidd={setIdForItem}
								prodId={prodId}
								setProdId={setProdId}
								prodName={productName}
								setProdName={setProductName}
								mapfun={showmapping}
								showmapfun={setShowMapping}
								showDiv={showDiv}
								setShowDiv={setShowDiv}
								selectedState={selectedState}
								setSelectedState={setSelectedState}
							/>
						)}
					</div>
					<div className='left'>
						<div className='select-box'>
							<div className='input-box'>
								{' '}
								<input
									id='in'
									type='string'
									placeholder={getPlaceholder()}
									value={inputQuery}
									onChange={(e) => {
										setInputQuery(e?.target?.value);
									}}
									className='select-box-input'
									onKeyDown={(e) => {
										if (e?.key === 'Enter' && inputQuery) {
											fetchAPI();
										}
									}}
								/>
							</div>

							<div className='process-selected'>
								<select
									value={btselect}
									onChange={handleChange}
									className='process-selected-box'
								>
									<option value='firstbt'>Partner Order ID</option>
									<option value='secondbt'>QR Code</option>
									<option value='thirdbt'>PO ID</option>
									<option value='fourthbt'>UY Order ID</option>
								</select>
							</div>
						</div>
						<button
							onClick={fetchAPI}
							type='button'
							className='btn-circle'
						>
							<ArrowForwardIcon />
						</button>
					</div>
				</div>
				<div
					className={`start2 ${
						selectProcess === 'scm' ? 'selectedScm' : 'selectedFms'
					}`}
				>
					{!showmapping && (
						<div className='blank-container'>
							<div className='left-container'>
								<img
									src={frontImage}
									alt='blank'
									style={{
										opacity: '0.5',
									}}
								/>
							</div>
							<div className='right-container'>
								<p>Trace Product using Order ID/Item QR Code</p>
							</div>
						</div>
					)}
					{showmapping && (
						<Dummy2
							id={idForItem}
							originStoreId={orders?.originStore}
							storename={orders?.baseKitchen}
							outletname={orders?.storeName}
							processSelect={btselect}
							poData={orders?.data}
							orderId={idForItem}
							prodId={prodId}
							setProdId={setProdId}
							prodName={productName}
							setProdName={setProductName}
							data={inputQuery}
							showmap={showmapping}
							showmapfun={setShowMapping}
							selectedId={selectedId}
							setselectedId={setselectedId}
							selectProcess={selectProcess}
							setIsLoading={setIsLoading}
							isLoading={isLoading}
							selectedState={selectedState}
							setSelectedState={setSelectedState}
						/>
					)}
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTracking);
