import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import styles from './AddTag.module.scss';

const AddTag = (props) => {
	const { handleClick, text } = props;

	return (
		<div className={`${styles.root}`} onClick={handleClick}>
			<AddOutlinedIcon color='primary' className={`${styles.icon}`} />
			<span className={styles.text}>{text}</span>
		</div>
	);
};

AddTag.defaultProps = {
	handleClick: () => {},
	text: '',
};

export default AddTag;
