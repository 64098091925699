import { useMemo } from 'react';
import { UYDateRange } from '@/Components/Forms/Payment Settlements/Calender/UYDateRange';
import { Label } from '@/Components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/Components/ui/select';

const DateRangeFilter = (props) => {
	const { filterData, filterProps, state, setState } = props;
	const {
		dependentOn = -1,
		label = '',
		selectedValueKey,
		defaultState,
		withInput = false,
		disabled = false,
		// onDependencyUpdate = () => {},
	} = filterProps;
	const dependency = filterData?.[dependentOn];
	const isDisabled =
		(dependentOn !== -1 && !state?.[dependency?.selectedValueKey]) || disabled;
	const selectedValue = state?.[selectedValueKey] ?? defaultState;

	const doesInputRequired = filterData?.find((ele) => {
		return ele?.type === 'dateRangeInput';
	});
	const isWithInput = !!doesInputRequired && withInput;
	const dateRangeInputData = doesInputRequired;

	const dateRangeFilterArray = useMemo(() => {
		const dateRangeKeys = Object.keys(defaultState ?? {});
		return [
			{
				startDate: selectedValue[dateRangeKeys?.[0]],
				endDate: selectedValue[dateRangeKeys?.[1]],
				key: 'selection',
			},
		];
	}, [selectedValue]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};
		const dateRangeKeys = Object.keys(defaultState ?? {});
		setState((prev) => {
			return {
				...prev,
				[selectedValueKey]: {
					[dateRangeKeys?.[0]]: from,
					[dateRangeKeys?.[1]]: to,
				},
			};
		});
	};
	return (
		<div className='flex gap-2 h-fit border border-uy-color p-2 rounded-lg'>
			{isWithInput && (
				<div className='flex flex-col gap-1'>
					<Label
						className={`${
							filterProps?.mandatory ? 'text-red-500' : ''
						}`}
					>
						{dateRangeInputData?.label}
						{filterProps?.mandatory ? '*' : ''}
					</Label>
					<Select
						onValueChange={(value) => {
							setState((prev) => {
								return {
									...prev,
									[dateRangeInputData?.selectedValueKey]:
										value,
								};
							});
						}}
						defaultValue={dateRangeInputData?.defaultState}
						value={state?.[dateRangeInputData?.selectedValueKey]}
						disabled={isDisabled}
					>
						<SelectTrigger className='w-[180px]'>
							<SelectValue placeholder='' />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							{dateRangeInputData?.compData?.map((ele) => {
								return (
									<SelectItem value={ele?.value}>
										{ele?.label}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
			)}
			<div className='flex flex-col gap-1'>
				<Label>{label}</Label>
				<UYDateRange
					disabled={isDisabled}
					dateRanges={dateRangeFilterArray}
					onDateRangeChange={setDate}
					enableOnlyBackwardSelection={false}
					placement='topStart'
				/>
			</div>
		</div>
	);
};

export default DateRangeFilter;
