import { Button } from '@mui/material';
import { classes } from '../../../../Helpers';
import styles from './Footer.module.css';

function Footer(props) {
	const { onOverride, message, showOverride, result, className } = props;
	return (
		<div className={classes(className, styles.root, styles[result])}>
			<p className={styles.text}>{message}</p>
			{showOverride && (
				<Button
					className={styles.button}
					variant='outlined'
					onClick={onOverride}
				>
					Override
				</Button>
			)}
		</div>
	);
}

Footer.defaultProps = {
	onOverride: () => {},
	message: '',
	showOverride: false,
	className: '',
};

export default Footer;
