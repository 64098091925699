/* eslint-disable no-tabs */
import { Switch } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper } from '../../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Enabled',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Name: 'stationName',
	Enabled: 'isEnabled',
};

const CookingStationsListPage = (props) => {
	const {
		data,
		query,
		deleteCookingStations,
		fetchCookingStations,
		toggleDisablStation,
	} = props;
	useEffect(() => {
		fetchCookingStations({
			entity: 'COOKING_STATIONS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleDelete = (stationId) => {
		deleteCookingStations({
			entity: 'COOKING_STATIONS',
			req: {
				params: {
					id: stationId,
				},
			},
			method: 'DELETE',
		});
	};
	const handleEnable = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		toggleDisablStation({
			entity: 'COOKING_STATIONS',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					isEnabled: fieldValue,
				},
			},
			method: 'PATCH',
		});
	};

	const SPECIAL_ITEMS = {
		Enabled: (id, isEnabled) => {
			return (
				<Switch
					name='customization'
					value='customization'
					size='small'
					color='primary'
					onChange={handleEnable}
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'COOKING_STATIONS',
					}}
				/>
			);
		},
	};

	return (
		<div className='list-page-root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data.filter((station) => {
					return (
						station.stationName
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.COOKING_STATIONS.data.LIST;
	// const data = CookingStationsConfig.api;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchCookingStations: formActions.gateway,
	deleteCookingStations: formActions.gateway,
	toggleDisablStation: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingStationsListPage);
