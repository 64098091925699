import { cloneDeep } from 'lodash';

const initialState = {
	licenseType: '',
	store: null,
	status: '',
	plan: '',
	startDate: new Date(),
	endDate: null,
	processType: null,
	machineAt: null,
	outlets: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

const config = {
	initialState,
	reducer,
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const newState = {
			...clonedState,
			clientVendorId:
				clonedState?.clientVendorId !== ''
					? clonedState?.clientVendorId
					: null,
			companyName:
				clonedState?.companyName !== ''
					? clonedState?.companyName
					: null,
			gstin: clonedState?.gstin !== '' ? clonedState?.gstin : null,
			vendorEmail:
				clonedState?.vendorEmail !== ''
					? clonedState?.vendorEmail
					: null,
			vendorPhone:
				clonedState?.vendorPhone === ''
					? null
					: Number(clonedState?.vendorPhone),
			billingAddress: {
				...clonedState?.billingAddress,
				addressPinCode:
					clonedState?.billingAddress?.addressPinCode === ''
						? null
						: Number(clonedState?.billingAddress?.addressPinCode),
			},
			shippingAddress: {
				...clonedState?.shippingAddress,
				addressPinCode:
					clonedState?.shippingAddress?.addressPinCode === ''
						? null
						: Number(clonedState?.shippingAddress?.addressPinCode),
			},
		};
		return newState;
	},
};

export default config;
