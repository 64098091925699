import moment from 'moment';
import './Timeline.scss';

function TimeComponent(prop) {
	function Time(arrTime) {
		const h = moment(arrTime).format('hh');
		const m = moment(arrTime).format('mm');
		const am = moment(arrTime).format('A');
		return (
			<div className='Time'>
				<div>{h}</div>
				<div>:</div>
				<div>{m}</div>
				<div>{am}</div>
			</div>
		);
	}
	function Component(labelArrived, arrivedTime, labelDispatch, dispatchTime) {
		return (
			<>
				{dispatchTime && (
					<div
						className='time_component'
						style={{
							width: '100%',
						}}
					>
						<p className='label'>
							{labelDispatch}
						</p>
						<p style={{
							fontSize: '18px',
							fontWeight: 500,
							width: '100%',
						}}
						>
							{Time(
								dispatchTime
							)}
						</p>
						<p className='date'>
							{moment(dispatchTime).format(
								'YYYY-MM-DD'
							)}
						</p>
					</div>
				)}
				{arrivedTime && (
					<div
						className='time_component'
						style={{
							borderTop: dispatchTime ? '1px solid white' : null,
						}}
					>
						<p
							className='label'
						>
							{labelArrived}
						</p>
						<div style={{
							fontSize: '18px',
							fontWeight: 500,
							width: '100%',
						}}
						>
							{Time(
								arrivedTime
							)}
						</div>
						<p className='date'>
							{moment(arrivedTime).format(
								'YYYY-MM-DD'
							)}
						</p>
					</div>
				)}
				{/* {arrivedTime && dispatchTime && (
					<div className='duration'>
						Duration:
						{' '}
						<TimeDuration arrivedTime={arrivedTime} dispatchTime={dispatchTime} />
					</div>
				)} */}
			</>
		);
	}
	function selectComponent(Name, arrivedTime, dispatchtime) {
		switch (Name) {
			case 'Customer Dispatch':
				return (
					Component('Order Recieved At:', arrivedTime, 'Outward At:', dispatchtime)
				);
			case 'Labelling':
				return (
					Component('Manufactured On:', arrivedTime, null, null)
				);
			case 'Packaging':
				return (Component('Packaging Started At:', arrivedTime, 'Packaging Completed At:', dispatchtime));
			case 'Dispatch':
				return (Component('Dispatching Started At:', arrivedTime, 'Dispatching Completed At:', dispatchtime));
			case 'TRANSIT':
				return (Component(null, null, 'Arrived At:', dispatchtime));
			case 'UNLOADING':
				return (Component('Unloading Started At:', arrivedTime, 'Unloading Completed At:', dispatchtime));
			case 'VERIFICATION':
				return (Component('Verification Started At:', arrivedTime, 'Verification Completed At:', dispatchtime));
			case 'Audit':
				return (Component('Audit started At:', arrivedTime, 'Audit Completed At', dispatchtime));

			default:
				return (Component('Placed At:', arrivedTime, 'Dispatched At:', dispatchtime));
		}
	}
	return (
		selectComponent(prop?.name, prop?.arrivedTime, prop?.dispatchTime)
	);
}

export default TimeComponent;
