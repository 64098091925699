import { getDate, getWeekOfMonth } from 'date-fns';
import { analyticsConstants } from '../Constants';
import { timeFilterMap } from '../Constants/analytics.constansts';
import analyticsTypeConstant from '../Constants/analyticsType.contants';

const yesterdayDate = new Date();
yesterdayDate.setDate(yesterdayDate.getDate() - 2);

const initialState = {
	analyticsType: analyticsTypeConstant.DASHBOARD_ANALYTICS,
	DASHBOARD_ANALYTICS: {
		isLoading: false,
		data: [],
	},
	AI_DASHBOARD_ANALYTICS: {
		isLoading: false,
		data: [],
	},
	STORE_ANALYSIS: {
		metrics: {},
		graphData: {
			labels: [],
			storeCogs: [],
			storeMargin: [],
			storeRevenue: [],
		},
		ordersData: [],
		pieData: {
			labels: [],
			data: [],
		},
		popularGraphData: {
			day: {
				data: [],
				labels: [],
			},
			time: {
				data: [],
				labels: [],
			},
		},
	},
	DEFECT_ANALYSIS: {
		FIND_DEFECT_IMAGES: [],
		FIND_DEFECT_IMAGES_BY_ID: [],
	},
	PRODUCT_ANALYSIS: {
		FIND_PRODUCT_IMAGES: [],
		FIND_PRODUCT_IMAGES_BY_ID: [],
	},
	OUTWARDING_ANALYSIS: {
		FIND_OUTWARDING_IMAGES: [],
		FIND_OUTWARDING_IMAGES_BY_ID: [],
	},
	WASTAGE_ANALYSIS: {
		FIND_WASTAGE_IMAGES: [],
		FIND_WASTAGE_IMAGES_BY_ID: [],
	},
	CONTAINER_ANALYSIS: {
		FIND_CONTAINER_IMAGES: [],
		FIND_CONTAINER_IMAGES_BY_ID: [],
	},
	TEMPERATURE_ANALYSIS: {
		FIND_TEMPERATURE_IMAGES: [],
		FIND_TEMPERATURE_IMAGES_BY_ID: [],
		FIND_DIGIT_TEMPERATURE_IMAGES: [],
		FIND_DIGIT_TEMPERATURE_IMAGES_BY_ID: [],
	},
	WASTAGE_COMPONENTS: {
		FIND_WASTAGE_COMPONENTS: [],
		FIND_WASTAGE_COMPONENTS_BY_ID: [],
	},
	PRODUCTION_PLANS: {
		LIST: [],
	},
	INVENTORY_ANALYTICS: {
		STOCK_IN_LIST: [],
		STOCK_OUT_LIST: {
			data: [],
		},
		SKU_STOCK: {},
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: {
			listData: [],
		},
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'QR_CODE',
		},
		PO_LIST: [],
		INDENT_LIST: [],
		VENDOR_ORDERS_LIST: [],
		FMS_ORDERS_LIST: [],
		WORK_ORDER_LIST: [],
	},
	QUALITY_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'ORDER_ID',
		},
	},
	COGS_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	COMMON_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	AUDIT_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	TRANSIT_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	BOM_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	INV_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	FORECASTING_ANALYTICS: {
		CHART_DATA: {
			brands: {},
			statsData: {},
			chartData: {
				labels: [],
				passed: [],
				failed: [],
			},
		},
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
	},
	GEO_FILTER: {
		state: [],
		city: [],
		store: [],
	},
	BRAND_FILTER: {
		selectedList: [],
	},
	STORE_FILTER: {
		active: false,
		selectedStores: [],
	},
	PROCESS_TYPE_FILTER: {
		active: false,
		selectedProcessTypes: [],
	},
	SKU_TYPE_FILTER: {
		active: false,
		selectedSkuTypes: [],
	},
	DUPLICATE_DATA_FILTER: {
		applied: false,
		value: null,
	},
	FROM_STORE_FILTER: {
		active: false,
		selectedStores: [],
	},
	TIME_FILTER: 'curr_day',
	DATE_RANGE_FILTER: {
		active: true,
		from: new Date(),
		to: new Date(),
	},
	SINGLE_DATE_FILTER: {
		active: true,
		value: new Date(),
	},
	MEAL_TYPE_FILTER: {
		applied: false,
		selected: [],
		list: [
			{
				id: 1,
				title: 'Breakfast',
			},
			{
				id: 2,
				title: 'Lunch',
			},
			{
				id: 3,
				title: 'Snacks',
			},
			{
				id: 4,
				title: 'Dinner',
			},
		],
	},
	LIST_DATA_FILTER: {
		validated: null,
		identified: null,
		features: null,
	},
	VALIDATED_DATA_FILTER: {
		applied: false,
		value: null,
	},
	IDENTIFIED_DATA_FILTER: {
		applied: false,
		value: null,
	},
	FEATURES_DATA_FILTER: {
		applied: false,
		value: null,
	},
	PRODUCT_COUNT_FILTER: {
		applied: false,
		count: [],
	},
	IMS_STOCK_FILTER: {
		applied: false,
		value: 'stock',
	},
	COOKING_ANALYTICS: {
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'PRODUCT_NAME',
		},
		FIND_COOKING_IMAGES: [],
		FIND_COOKING_IMAGES_BY_ID: [],
	},
	ORDER_HISTORY: {
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	WASTAGE_HISTORY: {
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	AI_PRODUCTION_ANALYSIS: {
		FIND_IMAGES: [],
		FIND_IMAGES_BY_ID: [],
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	AI_LEFT_OVER_INVENTORY_ANALYSIS: {
		FIND_IMAGES: [],
		FIND_IMAGES_BY_ID: [],
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	AI_DISPATCH_ANALYSIS: {
		FIND_IMAGES: [],
		FIND_IMAGES_BY_ID: [],
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	AI_OUTWARDING_ANALYSIS: {
		FIND_IMAGES: [],
		FIND_IMAGES_BY_ID: [],
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	AI_GRN_ANALYSIS: {
		FIND_IMAGES: [],
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	SUBSCRIPTIONS_HISTORY: {
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'Order_ID',
		},
	},
	UNDETECTED_PRODUCTS_FILTER: {
		applied: false,
		selected: [],
	},
	PRODUCT_FILTER: [],
	DATE_FILTER: {
		text: getDate(yesterdayDate),
		active: true,
		type: 'day',
		selection: {
			year: yesterdayDate.getFullYear(),
			month: timeFilterMap.month[yesterdayDate.getUTCMonth()],
			week: getWeekOfMonth(yesterdayDate, {
				weekStartsOn: 1,
			}),
			day: getDate(yesterdayDate),
			hour: null,
		},
	},
	GROUP_TYPE_FILTER: {
		active: false,
		type: 'periodWise',
		currentSelection: 'brand',
		selection: {
			category: {
				value: null,
				label: 'Category',
			},
			subCategory: {
				value: null,
				label: 'Sub Category',
			},
		},
	},
	FAILURE_ANALYSIS_FILTER: {
		WEIGHT: {
			'over-weight': false,
			'under-weight': false,
		},
		TEMPERATURE: {
			'over-temperature': false,
			'under-temperature': false,
		},
		DEFECT: {
			applied: false,
			defects: [],
		},
	},
	STATISTICAL_FILTER: {
		selectedBrands: [],
		selectedProductIds: [],
		selected: false,
		filter: 'Weight', // Weight or Temperature
	},
	ANALYSIS_DASHBOARD_FILTERS: {
		DATA_TYPE: '',
		analysisType: '',
		analysisSubType: '',
	},
	QC_STATISTICAL_ANALYSIS: {
		boxData: [
			{
				total: 0,
				passed: 0,
				pass_percent: 0.0,
			},
			{
				brandId: 0,
				total: 0,
				passed: 0,
				pass_percent: 0,
			},
		],
		metrics: {},
		graphData: {
			x: [],
			y: {},
		},
	},
	BRAND_ANALYSIS: {
		TOP_DEFECTS: [],
		TOP_PRODUCTS: [],
		LIST_DATA: [],
		SEARCH_DATA: {
			list: [],
			query: '',
			searchBy: 'ORDER_ID',
		},
	},
	selectedListItem: {
		id: 1,
	},
	selectedStoreId: {
		storeId: -1,
	},
};

function analytics(state = initialState, action) {
	switch (action.type) {
		case analyticsConstants.FETCH_DASHBOARD_REQUEST:
			return {
				...state,
				DASHBOARD_ANALYTICS: {
					...state.DASHBOARD_ANALYTICS,
					isLoading: true,
				},
			};
		case analyticsConstants.SET_DASHBOARD_ANALYTICS:
			return {
				...state,
				DASHBOARD_ANALYTICS: action.payload,
			};
		case analyticsConstants.FETCH_DASHBOARD_FAILURE:
			return {
				...state,
				DASHBOARD_ANALYTICS: {
					isLoading: false,
					data: [],
				},
			};
		case analyticsConstants.FETCH_AI_DASHBOARD_REQUEST:
			return {
				...state,
				AI_DASHBOARD_ANALYTICS: {
					...state.AI_DASHBOARD_ANALYTICS,
					isLoading: true,
				},
			};
		case analyticsConstants.SET_AI_DASHBOARD_ANALYTICS:
			return {
				...state,
				AI_DASHBOARD_ANALYTICS: action.payload,
			};
		case analyticsConstants.FETCH_AI_DASHBOARD_FAILURE:
			return {
				...state,
				AI_DASHBOARD_ANALYTICS: {
					isLoading: false,
					data: [],
				},
			};
		case analyticsConstants.FETCH_ANALYTICS_REQUEST:
			return {
				...state,
				INVENTORY_ANALYTICS: {
					...state?.INVENTORY_ANALYTICS,
					SKU_STOCK: {},
				},
			};
		case analyticsConstants.FETCH_ANALYTICS_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case analyticsConstants.FETCH_ANALYTICS_FAILURE:
			return {
				...state,
			};
		case analyticsConstants.UPDATE_ANALYTICS:
			return {
				...state,
				...action.payload,
			};
		case analyticsConstants.SET_GEO_FILTER_DATA:
			return {
				...state,
				GEO_FILTER: action.payload,
			};
		case analyticsConstants.SET_BRAND_FILTER_DATA:
			return {
				...state,
				BRAND_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_STORE_FILTER:
			return {
				...state,
				STORE_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_DUPLICATE_DATA_FILTER:
			return {
				...state,
				DUPLICATE_DATA_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_PROCESS_TYPE_FILTER:
			return {
				...state,
				PROCESS_TYPE_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_SKU_TYPE_FILTER:
			return {
				...state,
				SKU_TYPE_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_FROM_STORE_FILTER:
			return {
				...state,
				FROM_STORE_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_TIME_FILTER_DATA:
			return {
				...state,
				TIME_FILTER: action.payload,
			};
		case analyticsConstants.SET_GROUP_TYPE_FILTER:
			return {
				...state,
				GROUP_TYPE_FILTER: action.payload,
			};
		case analyticsConstants.SET_DATE_RANGE_FILTER:
			return {
				...state,
				DATE_RANGE_FILTER: action.payload,
			};
		case analyticsConstants.SET_SINGLE_DATE_FILTER:
			return {
				...state,
				SINGLE_DATE_FILTER: action.payload,
			};
		case analyticsConstants.SET_ANALYTICS_TYPE:
			return {
				...state,
				analyticsType: action.payload,
			};
		case analyticsConstants.SET_DATE_FILTER:
			return {
				...state,
				DATE_FILTER: action.payload,
			};
		case analyticsConstants.SET_FAILURE_ANALYSIS_FILTER:
			return {
				...state,
				FAILURE_ANALYSIS_FILTER: action.payload,
			};
		case analyticsConstants.SET_SELECTED_LIST_ID:
			return {
				...state,
				selectedListItem: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_STATISTICAL_FILTER:
			return {
				...state,
				STATISTICAL_FILTER: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_QC_STATISTICAL_ANALYSIS:
			return {
				...state,
				QC_STATISTICAL_ANALYSIS: {
					...action.payload,
				},
			};

		case analyticsConstants.SET_PRODUCT_FILTER:
			return {
				...state,
				PRODUCT_FILTER: [...action.payload],
			};
		case analyticsConstants.SET_IMS_STOCK_FILTER:
			return {
				...state,
				IMS_STOCK_FILTER: action.payload,
			};
		case analyticsConstants.SET_QC_SEARCH_RESULT:
			return {
				...state,
				QUALITY_ANALYTICS: {
					...state.QUALITY_ANALYTICS,
					SEARCH_DATA: action.payload,
				},
			};
		case analyticsConstants.SET_COGS_SEARCH_RESULT:
			return {
				...state,
				QUALITY_ANALYTICS: {
					...state.COGS_ANALYTICS,
					SEARCH_DATA: action.payload,
				},
			};
		case analyticsConstants.SET_COMMON_SEARCH_RESULT:
			return {
				...state,
				COMMON_ANALYTICS: {
					...state.COMMON_ANALYTICS,
					SEARCH_DATA: action.payload,
				},
			};
		case analyticsConstants.SET_BOM_SEARCH_RESULT:
			return {
				...state,
				BOM_ANALYTICS: {
					...state.BOM_ANALYTICS,
					SEARCH_DATA: action.payload,
				},
			};
		case analyticsConstants.SET_IMS_SEARCH_RESULT:
			return {
				...state,
				INVENTORY_ANALYTICS: {
					...state.INVENTORY_ANALYTICS,
					SEARCH_DATA: action.payload,
				},
			};
		case analyticsConstants.SET_DEFECT_IMAGES:
			return {
				...state,
				DEFECT_ANALYSIS: {
					...state.DEFECT_ANALYSIS,
					FIND_DEFECT_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_DEFECT_ANALYSIS:
			return {
				...state,
				DEFECT_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_PRODUCT_IMAGES:
			return {
				...state,
				AI_PRODUCTION_ANALYSIS: {
					...state.AI_PRODUCTION_ANALYSIS,
					FIND_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_PRODUCT_ANALYSIS:
			return {
				...state,
				AI_PRODUCTION_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_AI_DISPATCH_IMAGES:
			return {
				...state,
				AI_DISPATCH_ANALYSIS: {
					...state.AI_DISPATCH_ANALYSIS,
					FIND_IMAGES: action.payload,
				},
			};
		case analyticsConstants.SET_LEFT_OVER_INVENTORY_IMAGES:
			return {
				...state,
				AI_LEFT_OVER_INVENTORY_ANALYSIS: {
					...state.AI_LEFT_OVER_INVENTORY_ANALYSIS,
					FIND_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_LEFT_OVER_INVENTORY_ANALYSIS:
			return {
				...state,
				AI_LEFT_OVER_INVENTORY_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.RESET_AI_DISPATCH_ANALYSIS:
			return {
				...state,
				AI_DISPATCH_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_OUTWARDING_IMAGES:
			return {
				...state,
				OUTWARDING_ANALYSIS: {
					...state.OUTWARDING_ANALYSIS,
					FIND_OUTWARDING_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_OUTWARDING_ANALYSIS:
			return {
				...state,
				OUTWARDING_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.RESET_AI_OUTWARDING_ANALYSIS:
			return {
				...state,
				AI_OUTWARDING_ANALYSIS: {
					...state.AI_OUTWARDING_ANALYSIS,
					...action.payload,
				},
			};
		case analyticsConstants.SET_AI_OUTWARDING_IMAGES:
			return {
				...state,
				AI_OUTWARDING_ANALYSIS: {
					...state.AI_OUTWARDING_ANALYSIS,
					FIND_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_AI_GRN_ANALYSIS:
			return {
				...state,
				AI_GRN_ANALYSIS: {
					...state.AI_GRN_ANALYSIS,
					...action.payload,
				},
			};
		case analyticsConstants.SET_AI_GRN_IMAGES:
			return {
				...state,
				AI_GRN_ANALYSIS: {
					...state.AI_GRN_ANALYSIS,
					FIND_IMAGES: action.payload,
				},
			};
		case analyticsConstants.SET_WASTAGE_IMAGES:
			return {
				...state,
				WASTAGE_ANALYSIS: {
					...state.WASTAGE_ANALYSIS,
					FIND_WASTAGE_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_WASTAGE_ANALYSIS:
			return {
				...state,
				WASTAGE_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_CONTAINER_IMAGES:
			return {
				...state,
				CONTAINER_ANALYSIS: {
					...state.CONTAINER_ANALYSIS,
					FIND_CONTAINER_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_CONTAINER_ANALYSIS:
			return {
				...state,
				CONTAINER_ANALYSIS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_DIGIT_TEMPERATURE_IMAGES:
			return {
				...state,
				TEMPERATURE_ANALYSIS: {
					...state.TEMPERATURE_ANALYSIS,
					FIND_DIGIT_TEMPERATURE_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_DIGIT_TEMPERATURE_ANALYSIS:
			return {
				...state,
				TEMPERATURE_ANALYSIS: {
					...state.TEMPERATURE_ANALYSIS,
					...action.payload,
				},
			};
		case analyticsConstants.SET_TEMPERATURE_IMAGES:
			return {
				...state,
				TEMPERATURE_ANALYSIS: {
					...state.TEMPERATURE_ANALYSIS,
					FIND_TEMPERATURE_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_TEMPERATURE_ANALYSIS:
			return {
				...state,
				TEMPERATURE_ANALYSIS: {
					...state.TEMPERATURE_ANALYSIS,
					...action.payload,
				},
			};
		case analyticsConstants.SET_WASTAGE_COMPONENTS:
			return {
				...state,
				WASTAGE_COMPONENTS: {
					...state.WASTAGE_ANALYSIS,
					FIND_WASTAGE_COMPONENTS: action.payload,
				},
			};
		case analyticsConstants.RESET_WASTAGE_COMPONENTS:
			return {
				...state,
				WASTAGE_COMPONENTS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_COOKING_IMAGES:
			return {
				...state,
				COOKING_ANALYTICS: {
					...state.COOKING_ANALYTICS,
					FIND_COOKING_IMAGES: action.payload,
				},
			};
		case analyticsConstants.RESET_COOKING_ANALYSIS:
			return {
				...state,
				COOKING_ANALYTICS: {
					...action.payload,
				},
			};
		case analyticsConstants.SET_UNDETECTED_PRODUCTS_FILTER:
			return {
				...state,
				UNDETECTED_PRODUCTS_FILTER: action.payload,
			};
		case analyticsConstants.SET_MEAL_TYPE_FILTER:
			return {
				...state,
				MEAL_TYPE_FILTER: {
					list: [...state.MEAL_TYPE_FILTER.list],
					...action.payload,
				},
			};
		case analyticsConstants.SET_LIST_DATA_FILTER:
			return {
				...state,
				LIST_DATA_FILTER: action.payload,
			};
		case analyticsConstants.SET_VALIDATED_DATA_FILTER:
			return {
				...state,
				VALIDATED_DATA_FILTER: action.payload,
			};
		case analyticsConstants.SET_IDENTIFIED_DATA_FILTER:
			return {
				...state,
				IDENTIFIED_DATA_FILTER: action.payload,
			};
		case analyticsConstants.SET_FEATURES_DATA_FILTER:
			return {
				...state,
				FEATURES_DATA_FILTER: action.payload,
			};
		case analyticsConstants.SET_PRODUCT_COUNT_FILTER:
			return {
				...state,
				PRODUCT_COUNT_FILTER: action.payload,
			};
		case analyticsConstants.SET_ANALYSIS_DASHBOARD_FILTERS:
			return {
				...state,
				ANALYSIS_DASHBOARD_FILTERS: action.payload,
			};
		default:
			return state;
	}
}
export default analytics;
