/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import styles from './InvoicesPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../Components/Loaders';
import ListTable from '../../Components/ListTable/ListTable';
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
import myMachineService from '../../Services/AssetServices/myMachine.service';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { PaymentModal } from '../../Components/Modals/PaymentModal';
import { useAuth } from '../../Components/Auth/Login/auth';
import { urls } from '../../Constants';
import { TransactionsModal } from '../../Components/Modals/TransactionsModal';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'For Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'By Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Inv. ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Invoice Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Due Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Invoice Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Received',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'View Payments',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Record payment',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Approve payment',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Cancel Invoice',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	ID: 'id',
	'Client Inv. ID': 'clientTransactionId',
	'Order ID': 'orderId',
	'For Store': 'storeName',
	'By Store': 'fromStoreName',
	'Invoice Date': 'transactionDate',
	'Due Date': 'dueDate',
	'Invoice Amount': 'finalTransactionAmount',
	'Payment Received': 'currentAmount',
	Status: 'transactionStatus',
};

const FIND_FILTERS = ['store'];

function InvoicesPage({
	userPermissions,
	createPath,
	togglePOItemsModal,
	togglePaymentModal,
	toggleTransactionsModal,
	navState,
}) {
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const history = useHistory();
	const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [invoicesList, setInvoicesList] = useState([]);
	const [update, setUpdate] = useState(0);

	// useEffect(() => {
	// if (!userConfig?.isBusinessUser) {
	// SPECIAL_COLUMNS = SPECIAL_COLUMNS?.filter((ele) => {
	// return ele?.title !== 'Approve payment';
	// });
	// } else {
	// const alreadyExists = SPECIAL_COLUMNS?.findIndex((ele) => {
	// return ele?.title === 'Approve payment';
	// });
	// if (alreadyExists === -1) {
	// SPECIAL_COLUMNS?.push({
	// title: 'Approve payment',
	// config: {
	// type: 'DATA',
	// },
	// });
	// }
	// }
	// }, [userConfig?.isBusinessUser]);

	const handleListFetch = async (requestOptions) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							// filters: {
							// storeId: requestOptions?.store,
							// },
							type: 'BILL',
						},
					},
				});
			if (responseData?.statusCode === 200) {
				// setSelectedStore(store);
				setInvoicesList(responseData?.data);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};
	const handleApproveInvoice = async (item) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction(
					{
						params: {
							transactionId: item?.id,
						},
						data: {
							approved: true,
							type: 'BILL',
						},
					}
				);
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};
	const handleCancelInvoice = async (item) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction(
					{
						params: {
							transactionId: item?.id,
						},
						data: {
							status: 'CANCELLED',
							type: 'BILL',
						},
					}
				);
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {
		'Record payment': (id) => {
			const item = invoicesList?.find((ele) => {
				return ele?.id === id;
			});
			if (!item?.isApproved) {
				return '-';
			}
			if (item?.finalTransactionAmount === item?.currentAmount) {
				return 'PAID';
			}
			return (
				<Button
					onClick={() => {
						togglePaymentModal(true, {
							heading: `Record payment for Record #${id}`,
							totalAmount: item?.finalTransactionAmount - item?.currentAmount,
							storeId: item?.storeId,
							parentId: item?.id,
						});
					}}
					variant='contained'
					color='primary'
				>
					Record
				</Button>
			);
		},
		'Approve payment': (id) => {
			const item = invoicesList?.find((ele) => {
				return ele?.id === id;
			});
			if (!item?.isApproved && !userConfig?.isBusinessUser) {
				return 'Not Approved';
			}
			if (item?.isApproved) {
				return 'Approved';
			}
			return (
				<Button
					onClick={() => {
						handleApproveInvoice(item);
					}}
					variant='contained'
					color='primary'
				>
					Approve
				</Button>
			);
		},
		'View Payments': (id) => {
			const item = invoicesList?.find((ele) => {
				return ele?.id === id;
			});
			if (item?.childTransactionRecord?.length) {
				return (
					<Visibility
						className={`${styles['action-btn']} ${styles.view}`}
						onClick={() => {
							toggleTransactionsModal(true, {
								listData: item?.childTransactionRecord,
								transactionType: 'PAYMENTS',
								type: 'paymentReceived',
								heading: `Payment Received of Invoice #${item?.clientTransactionId}`,
							});
						}}
					/>
				);
			}
			return '-';
		},
		'Cancel Invoice': (id) => {
			const item = invoicesList?.find((ele) => {
				return ele?.id === id;
			});
			if (item?.childTransactionRecord?.length) {
				return (
					<Visibility
						className={`${styles['action-btn']} ${styles.view}`}
						onClick={() => {
							handleCancelInvoice(item);
						}}
					/>
				);
			}
			return '-';
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleViewClick = (item) => {
		togglePOItemsModal(true, {
			heading: `Invoice Items Items of #${
				item?.clientInvoiceId ?? item?.id
			}`,
			...item,
			excludedColumns: [
				'Packaged',
				'Returned Quantity',
				'Dispatched',
				'Received',
				'Fulfilled',
				'From Process',
				'PO Id',
				'Returned',
				'Quantity',
				'Used For',
			],
		});
	};

	const handleMakePayment = async (data) => {
		try {
			const response = await inventoryAnalyticsServices.createTransactionRecord({
				data: {
					...data,
					type: 'PAYMENTS',
				},
			});
			if (response?.statusCode === 200) {
				// setStoreCreditsList(response?.data);
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<PaymentModal handleConfirm={handleMakePayment} />
			<TransactionsModal />
			<ImageModal />
			<POItemsModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Invoice List'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'Invoice',
						handleAddClick,
					}}
					update={update}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={invoicesList}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleViewClick={handleViewClick}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	const { userPermissions } = state.navigation;

	return {
		navState,
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePaymentModal: modalActions.togglePaymentModal,
	toggleTransactionsModal: modalActions.toggleTransactionsModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage);
