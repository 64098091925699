/* eslint-disable react/jsx-wrap-multilines */
// eslint-disable-next-line object-curly-newline
import React, {
	useCallback,
	useEffect,
	useRef,
	useReducer,
	useMemo,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
	FeedHUD,
	Reference,
	VideoFeed,
} from '../../../Components/Analytics/Quality';
import { LiveQCHeader } from '../../../Components/Headers';
import { endpoints, urls } from '../../../Constants';
import { formActions, modalActions } from '../../../Actions';
// eslint-disable-next-line object-curly-newline
import {
	cloneDeep,
	history,
	inputHelper,
	keyPressHandler,
	useClientRect,
	useImageDimensionsRef,
	useQuery,
} from '../../../Helpers';
import config from './config';
import styles from './QCPage.module.scss';
import { liveQCServices } from '../../../Services/LiveQC';
import { STAT_COLORS } from '../../../Styles/colors';
import {
	AlertModal, AltAlertModal, RetagModal
} from '../../../Components/Modals';
import { MachineLayout } from '../../Layouts';
import analyticsAction from '../../../Actions/analytics.actions';
import { notificationServices, retagServices } from '../../../Services';
import useKeyPress from '../../../Helpers/useKeyPress';
import RecipeItems from '../RecipeItems/RecipeItems';
import notAddedImg from '../../../Images/notAdded.svg';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
import { useAuth } from '../../../Components/Auth/Login/auth';
import AppQcReference from '../../../Components/AppQcReference/AppQcReference';

const LIVE_QC_POLLING_INTERVAL = 10000;

const initialState = cloneDeep(config.initialState);

function reducer(state, action) {
	switch (action.type) {
		case 'INIT':
			return {
				...initialState,
				...(action?.payload ?? {}),
			};
		case 'SET_QC_STATUS':
			return {
				...state,
				...config.serializer(state, action.payload),
			};
		case 'SET_QC_STATUS_POLLING_ID':
			return {
				...state,
				qcStatusPollingId: action.payload,
			};

		case 'SET_STORE_DATA':
			return {
				...state,
				stores: action.payload,
			};
		case 'SET_SELECTED_STORE_DATA':
			return {
				...state,
				selectedStore: action.payload,
			};
		case 'SET_PAST_QC_DATA':
			return {
				...state,
				...config.serializer(state, action.payload, false),
			};
		case 'SET_WASTAGE_REASONS':
			return {
				...state,
				wastageReasons: action.payload,
			};
		case 'SET_PAST_RECIPE_DATA':
			return {
				...state,
				recipe: action.payload,
			};
		default:
			return state;
	}
}

const QCPage = (props) => {
	const {
		live,
		listData,
		toggleAltAlert,
		userPermissions,
		updateQCList,
		setAnalyticsType,
		fetchProducts,
		toggleAlert,
	} = props;
	const [tempVisibility, setTempVisibility] = useState(true);
	const { EDIT = false, ANNOTATE = false } = userPermissions;
	const { index } = useParams();
	const query = useQuery();
	const keyPressed = useKeyPress();
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};

	const qcId = query.get('id');
	const type = query.get('type');
	const previousPageType = query.get('previousPageType');
	const isCms = type === 'CMS';

	const listIndex = useMemo(() => {
		return listData?.findIndex((qc) => {
			if (qcId != null) {
				return qc.itemId === index && qc.id === parseInt(qcId, 10);
			}
			return qc.itemId === index;
		});
	}, [index, qcId]);

	const [state, dispatch] = useReducer(reducer, initialState);

	const { lastQcAt, selectedStore } = state;

	const qcStatusPollingRef = useRef(null);
	const mountRef = useRef(true);

	const [getContainerRect, imageContainerRef] = useClientRect();
	const imageDimensions = useImageDimensionsRef(
		state?.measurements?.camera?.image
	);
	const [stageExpanded, setStageExpanded] = useState();
	const [currentRecipe, setCurrentRecipe] = useState({});
	const [ingredients, setIngredients] = useState([]);

	useEffect(() => {
		(async () => {
			const response = await liveQCServices.getStoreList();
			dispatch({
				type: 'SET_STORE_DATA',
				payload: response?.data ?? [],
			});
		})();
	}, []);

	useEffect(() => {
		if (previousPageType === 'WASTAGE_IMAGES') {
			setAnalyticsType(
				analyticsTypeConstant.WASTAGE_HISTORY_LIST_DATA,
				{}
			);
		}
	}, [previousPageType]);

	useEffect(() => {
		const qcData = listData?.[listIndex] ?? null;
		if (
			(keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight') &&
			qcData !== null
		) {
			const newListItem = keyPressHandler(
				keyPressed,
				listData,
				listIndex
			);
			if (newListItem) {
				history.push({
					pathname: `${urls.EXTENSION}${urls.PAST_QC}/${newListItem?.itemId}`,
					search: `id=${newListItem?.id}`,
					state: {
						from: history?.location?.state?.from,
					},
				});
			}
		}
	}, [keyPressed]);

	const getWastageReasons = async () => {
		const req = {
			params: {
				qcId,
			},
		};
		try {
			const response = await liveQCServices?.getWastageReasons(req);
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_WASTAGE_REASONS',
					payload: response?.data ?? [],
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.errorMessage ?? response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
		}
	};

	useEffect(() => {
		if (state?.type === 'WASTAGE') {
			getWastageReasons();
		}
	}, [state?.type, qcId]);

	const getQCStatus = useCallback(
		async (first, imgDimension, containerRect) => {
			try {
				const req = {
					params: {
						storeId: selectedStore?.storeId || '',
						lastQCAt: first ? '' : lastQcAt || '',
					},
				};
				const response = await liveQCServices.getLiveData(req);
				const { message = null } = response ?? {};

				if (
					message?.toLowerCase() ===
					'selected system is not activated today'
				) {
					toggleAltAlert(true, {
						heading: 'System Not Active',
						message,
					});
				}
				dispatch({
					type: 'SET_QC_STATUS',
					payload: response
						? {
							...response,
							imgDimension,
							containerRect,
							measurementType: measurement,

						}
						: null,
				});
			} catch (error) {
				dispatch({
					type: 'MACHINE_OFFLINE',
				});
				// eslint-disable-next-line no-console
				console.log(error);
			}
		},
		[lastQcAt, selectedStore]
	);

	const getPastQCDetails = useCallback(
		async (
			itemId,
			imgDimension = imageDimensions.current,
			containerRect = getContainerRect()
		) => {
			try {
				const req = {
					params: {
						itemId: isCms ? '' : index,
					},
				};

				if (qcId != null) {
					req.params = {
						...req.params,
						qcId: isCms ? itemId : qcId,
					};
				}

				const response = await liveQCServices.getPastData(req);
				dispatch({
					type: 'SET_PAST_QC_DATA',
					payload:
						response?.data != null
							? {
								...response?.data,
								imgDimension,
								containerRect,
								measurementType: measurement,
							}
							: null,
				});
				updateQCList(
					listIndex > -1 ? listIndex : 0,
					response?.data ?? {}
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		},
		[qcId]
	);

	const startQC = useCallback(() => {
		try {
			if (qcStatusPollingRef.current != null) {
				clearInterval(qcStatusPollingRef.current);
				qcStatusPollingRef.current = null;
			}
			const qcStatusPollingId = setInterval(() => {
				if (!mountRef.current) {
					clearInterval(qcStatusPollingRef.current);
				} else {
					getQCStatus(
						false,
						imageDimensions.current,
						getContainerRect()
					);
				}
			}, LIVE_QC_POLLING_INTERVAL);
			qcStatusPollingRef.current = qcStatusPollingId;
			getQCStatus(true, imageDimensions.current, getContainerRect());
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}, [getQCStatus, imageDimensions, getContainerRect]);

	const getPastRecipeData = useCallback(async () => {
		try {
			const req = {
				data: {
					recipeId: qcId,
				},
			};

			const response = await liveQCServices.getRecipeData(req);
			dispatch({
				type: 'SET_PAST_RECIPE_DATA',
				payload: response,
			});
			setStageExpanded(response?.stages[0]?.id);
			// updateQCList(
			// listIndex > -1 ? listIndex : 0,
			// response?.data ?? {}
			// );
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}, [qcId]);

	useEffect(() => {
		if (!isCms) {
			if (live === true) {
				if (state?.selectedStore?.storeId != null) {
					startQC();
				} else {
					dispatch({
						type: 'INIT',
						payload: {
							stores: state.stores,
						},
					});
				}
			} else if (live === false) {
				// past qc
				// const qcData = listData?.[listIndex] ?? null;
				// if (qcData == null) {
				getPastQCDetails(
					index,
					imageDimensions.current,
					getContainerRect()
				);
				// }
				// } else {
				// dispatch({
				// type: 'SET_PAST_QC_DATA',
				// payload: {
				// ...qcData,
				// imgDimension: imageDimensions.current,
				// containerRect: getContainerRect(),
				// },
				// });
				// }
			}
		}
		return () => {
			clearInterval(qcStatusPollingRef.current);
		};
	}, [
		live,
		selectedStore,
		imageDimensions.current.width,
		imageDimensions.current.height,
		listIndex,
		getContainerRect,
	]);

	useEffect(() => {
		if (isCms) {
			getPastRecipeData();
		}
	}, []);

	useEffect(() => {
		return () => {
			mountRef.current = false;
			clearInterval(qcStatusPollingRef.current);
		};
	}, []);

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const storeSelector = (e) => {
		let newSelection = {};
		if (e.target.value === '') {
			newSelection = {
				storeName: null,
				storeId: null,
			};
		} else {
			newSelection = {
				storeName: state.stores[e.target.value].name,
				storeId: state.stores[e.target.value].id,
			};
		}
		dispatch({
			type: 'SET_SELECTED_STORE_DATA',
			payload: newSelection,
		});
	};

	const navigateQC = (e) => {
		const { dataset } = inputHelper(e);

		let newListItem = null;

		if (dataset.type === 'next') {
			newListItem = listData?.[listIndex + 1];
		} else {
			newListItem = listData?.[listIndex - 1];
		}
		history.push({
			pathname: `${urls.EXTENSION}${urls.PAST_QC}/${newListItem?.itemId}`,
			search: `id=${newListItem?.id}`,
			state: {
				from: history?.location?.state?.from,
			},
		});

		// history.replace(
		// `${urls.EXTENSION}${urls.PAST_QC}/${newListItem?.itemId}?id=${newListItem?.id}`
		// );
	};

	const validateSKU = async ({ labelId = null, label = null } = {}) => {
		let pastIdentification = state.identification?.[0] ?? {};
		if (isCms) {
			pastIdentification = currentRecipe?.identification?.[0];
		}
		const req = {};
		req.params = {
			qcId: isCms ? currentRecipe?.qcId : qcId,
			aiCorrect: true,
		};
		req.data = {
			identification: [
				{
					x: pastIdentification?.x ?? null,
					y: pastIdentification?.y ?? null,
					width: pastIdentification?.width ?? null,
					height: pastIdentification?.height ?? null,
					labelId:
						labelId === -1 ? null : labelId ?? state.product.id,
					label: label ?? state.product.name,
				},
			],
		};

		try {
			const response = await retagServices.submitRetag(req);
			const { message } = response;
			notificationServices.generateNotification({
				type: 'success',
				message,
			});
			getPastQCDetails(
				isCms ? currentRecipe?.qcId : index,
				imageDimensions?.current,
				getContainerRect()
			);
			return true;
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
			return false;
		}
	};
	const reValidateSKU = async ({ labelId = null, label = null } = {}) => {
		const pastIdentification = state.identification?.[0] ?? {};
		const req = {};
		req.params = {
			qcId: isCms ? currentRecipe?.qcId : qcId,
			aiCorrect: false,
		};
		req.data = {
			identification: [
				{
					x: pastIdentification?.x ?? null,
					y: pastIdentification?.y ?? null,
					width: pastIdentification?.width ?? null,
					height: pastIdentification?.height ?? null,
					labelId:
						labelId === -1 ? null : labelId ?? state.product.id,
					label: label ?? state.product.name,
				},
			],
		};

		try {
			const response = await retagServices.submitRetag(req);
			const { message } = response;
			notificationServices.generateNotification({
				type: 'success',
				message,
			});
			getPastQCDetails(
				isCms ? currentRecipe?.qcId : index,
				imageDimensions?.current,
				getContainerRect()
			);
			return true;
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
			return false;
		}
	};

	const updateSKU = async () => {
		const req = {
			params: {
				qcId,
			},
			data: {
				duplicate: !state?.duplicate,
			},
		};

		try {
			const response = await retagServices.updateQCScan(req);
			const { message } = response;
			notificationServices.generateNotification({
				type: 'success',
				message,
			});
			getPastQCDetails(
				isCms ? currentRecipe?.qcId : index,
				imageDimensions?.current,
				getContainerRect()
			);
			return true;
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
			return false;
		}
	};

	if (state?.scanType === 'APP') {
		return (
			<div className={styles['app-qc']}>
				<LiveQCHeader
					isLiveQC={live}
					live={live}
					currentDate={state.currentDate}
					totalQCS={state.totalQCS}
					passedQCS={state.passedQCS}
					passPercentage={state.passedPercentage}
					passPercentColor={STAT_COLORS[0]}
					lastQcAt={state.lastQcAt}
					navigateQC={navigateQC}
					idx={listIndex}
					orderId={state.orderId}
					scanId={qcId}
					itemId={state.itemId}
					updatedAt={state.updatedAt}
					selectedStoreName={state.selectedStore.storeName}
					// updateStates={updateStates}
					listData={listData}
					storeSelector={storeSelector}
					storeList={state.stores}
					machine={state.machine}
					machineName={state.machineName}
					scanType={state.scanType}
					processName={state.processName}
					expiryTime={state.expiryTime}
					clientPoId={state.clientPoId}
					modeType={state?.type}
				/>
				<div className={styles.body}>
					<AppQcReference
						name={state.product.name}
						source={state.product.image}
						isVeg={state.product.isVeg}
						brand={state.product.brand}
						result={state.result}
						message={state.message}
						weight={{
							min: state.ranges.weight.min,
							max: state.ranges.weight.max,
							minUnit: state.ranges.weight.minUnit,
							maxUnit: state.ranges.weight.maxUnit,
							title: 'Weight',
							measurement: state.measurements.weight.values,
							unit: state.measurements.weight.unit,
							result: state.measurements.weight.result,
							live: state?.measurements.weight.live,
							piecesCount: state?.measurements.weight.piecesCount,
						}}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<AlertModal
				handleConfirm={() => {
					updateSKU();
					toggleAlert(false);
				}}
			/>
			<MachineLayout
				Header={
					<LiveQCHeader
						isLiveQC={live}
						live={live}
						currentDate={state.currentDate}
						totalQCS={state.totalQCS}
						passedQCS={state.passedQCS}
						passPercentage={state.passedPercentage}
						passPercentColor={STAT_COLORS[0]}
						lastQcAt={state.lastQcAt}
						navigateQC={navigateQC}
						idx={listIndex}
						orderId={state.orderId}
						scanId={qcId}
						itemId={state.itemId}
						updatedAt={state.updatedAt}
						selectedStoreName={state.selectedStore.storeName}
						// updateStates={updateStates}
						listData={listData}
						storeSelector={storeSelector}
						storeList={state.stores}
						machine={state.machine}
						machineName={state.machineName}
						scanType={state.scanType}
						processName={state.processName}
						expiryTime={state.expiryTime}
						clientPoId={state.clientPoId}
						modeType={state?.type}
						stockedOutAt={state?.stockedOutAt}
					/>
				}
				Reference={
					isCms ? (
						<div
							style={{
								height: '100%',
								overflowY: 'scroll',
							}}
						>
							<p
								style={{
									fontSize: '1.25rem',
									fontWeight: 500,
								}}
							>
								Recipe:
								{state?.recipe?.recipeName}
							</p>
							{state?.recipe?.stages?.map((stage) => {
								return (
									<RecipeItems
										stageData={stage}
										stageExpanded={stageExpanded}
										setStageExpanded={setStageExpanded}
										setCurrentRecipe={setCurrentRecipe}
										getPastQCDetails={getPastQCDetails}
										currentRecipe={currentRecipe}
										setIngredients={setIngredients}
										measurement={{
											temp: {
												live: state?.recipe
													?.totalWeight,
											},
										}}
									/>
								);
							})}
						</div>
					) : (
						<Reference
							// className={styles.referenceBox}
							name={state.product.name}
							source={state.product.image}
							isVeg={state.product.isVeg}
							isRTV={state.isRTV}
							brand={state.product.brand}
							fromStoreName={state?.fromStoreName ?? null}
							wastageDate={state?.wastageDate ?? null}
							weight={{
								min: state.ranges.weight.min,
								max: state.ranges.weight.max,
								minUnit: state.ranges.weight.minUnit,
								maxUnit: state.ranges.weight.maxUnit,
								title: 'Weight',
								measurement: state.measurements.weight.values,
								unit: state.measurements.weight.unit,
								result: state.measurements.weight.result,
								live: state?.measurements.weight.live,
								piecesCount:
									state?.measurements.weight.piecesCount,
							}}
							temp={{
								min: state.ranges.temp.min,
								max: state.ranges.temp.max,
								title: 'Temperature',
								measurement: state.measurements.temp.values,
								unit: state.measurements.temp.unit,
								result: state.measurements.temp.result,
								live: state?.measurements.temp.live,
								piecesCount: null,
							}}
						/>
					)
				}
				VideoFeed={
					<VideoFeed
						// className={styles.videoFeed}
						live={live}
						tempVisibility={tempVisibility}
						imageContainerRect={getContainerRect()}
						imageContainerRef={imageContainerRef}
						sensorValues={
							state?.measurements?.temp?.sensorValues ?? []
						}
						source={
							isCms
								? currentRecipe?.qcImage ?? notAddedImg
								: state?.measurements?.camera?.image ??
								endpoints.START_QC_VIDEO_FEED
						}
						disconnections={{
							weight: !state.measurements.weight.connected,
							temp: !state.measurements.temp.connected,
							camera: !state.measurements.camera.connected,
						}}
						nutrition={state?.nutrition ?? {}}
						weight={{
							unit: isCms ? 'g' : state.measurements.weight.unit,
							live: isCms
								? state?.recipe?.totalWeight
								: state?.measurements.weight.live,
						}}
					>
						<FeedHUD
							identifiedProducts={state?.identification}
							scanId={qcId}
							result={state.result}
							orderId={state.orderId}
							itemId={state.itemId}
							weightByComponents={state?.weightByComponents}
							currentDate={state.currentDate}
							lastQcAt={state.lastQcAt}
							currentIndex={index}
							showAnnotation={
								state.validated && ANNOTATE && !live
							}
							showRetag={state.validated && ANNOTATE && !live}
							showValidate={
								state.validated != null && EDIT && !live
							}
							showFirstTimeValidate={
								state.validated == null && EDIT && !live
							}
							validation={
								isCms
									? currentRecipe?.validated
									: state.validated
							}
							identification={state.identification?.[0]}
							features={state?.features ?? 0}
							identity={state?.identity ?? 0}
							validateSKU={validateSKU}
							setTempVisibility={setTempVisibility}
							tempVisibility={tempVisibility}
							sensorValues={
								state?.measurements?.temp?.sensorValues
							}
							isDuplicate={state?.duplicate}
							ingredients={ingredients}
							isCms={isCms}
							isWastage={state?.type === 'WASTAGE'}
							wastageReason={
								state?.wastageReasons?.length > 0
									? state?.wastageReasons
									: state?.wastageReason
							}
							currentRecipe={currentRecipe}
							setCurrentRecipe={setCurrentRecipe}
							getPastQCDetails={getPastQCDetails}
							message={state.message}
							container={state?.container}
							duplicateActionBy={state?.duplicateActionBy}
							onDuplicacy={() => {
								const heading = state?.duplicate
									? ' Scan not Duplicate?'
									: 'Duplicate Scan?';
								toggleAlert(true, {
									heading,
									colorReverse: false,
									cancelText: 'No',
									confirmText: state?.duplicate
										? 'Unmark'
										: 'Mark',
									message: (
										<>
											<p className={styles.duplicacy_text}>
												This Scan is
												{' '}
												{state?.duplicate
													? 'Duplicate'
													: 'Non-Duplicate'}
											</p>
											<p>
												Are you sure you want to mark
												this scan as
												{' '}
												{state?.duplicate
													? 'Non-Duplicate'
													: 'Duplicate'}
												?
											</p>
										</>
									),
									width: '28rem',
									height: '15rem',
								});
							}}
							// hideAnnotation={isCms}
						/>
					</VideoFeed>
				}
			/>
			<AltAlertModal />
			<RetagModal
				handleSubmit={reValidateSKU}
				identification={state.identification}
				brandId={state.product.brandId}
				skuType='PRODUCT'
			/>
		</>
	);
};

QCPage.defaultProps = {
	live: false,
};

const mapStateToProps = (state) => {
	const { list: listData = [] } =
		state.analytics.QUALITY_ANALYTICS.SEARCH_DATA;
	const { userPermissions } = state.navigation;
	return {
		listData,
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleAltAlert: modalActions.toggleAltAlertModal,
	updateQCList: analyticsAction.updateQCList,
	setAnalyticsType: analyticsAction.setAnalyticsType,
	fetchProducts: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(QCPage));
