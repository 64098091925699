function Image(props) {
	const imageStyle = {
		height: props?.height,
		width: props?.width,
		overflow: 'hidden', // Ensure text doesn't overflow the textBox
		border:
			// eslint-disable-next-line no-nested-ternary
			props?.selectedComponent === props?.id
				? '1px solid orange'
				: props?.currentComponent === props?.id
					? '1px solid red'
					: null,
	};
	return (
		<div style={imageStyle}>
			<img
				key={props?.id}
				id={props?.id}
				src={props?.src}
				height='100%'
				width='100%'
				// height={props?.image?.height}
				// width={props?.image?.width}
				alt=''
			/>
		</div>
	);
}
export default Image;
