import styles from './MachineLayout.module.css';

const MachineLayout = (props) => {
	const { Header, Reference, Measurement, VideoFeed } = props;

	return (
		<div className={styles.root}>
			<div className={styles.header}>{Header}</div>
			<div className={styles.body}>
				<div className={styles['reference-measurement']}>
					<div className={styles.referenceBox}>{Reference}</div>
					<div className={styles.measurementBox}>{Measurement}</div>
				</div>
				<div className={styles.videoFeed}>{VideoFeed}</div>
			</div>
			{/* <div className={styles.footer}>{Footer}</div> */}
		</div>
	);
};

MachineLayout.defaultProps = {
	Header: null,
	Reference: null,
	Measurement: null,
	VideoFeed: null,
	Footer: null,
};

export default MachineLayout;
