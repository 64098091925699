import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const commonAnalyticsServices = {
	gateway,
	fetchAnalyticsList,
	fetchImagesById,
	exportAnalyticsList,
};

function fetchImagesById(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_WASTAGE_IMAGES_BY_ID, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions).then(handleResponse);
}

function gateway({ req, method }) {
	switch (method) {
		case 'FIND_IMAGES':
			return findImages(req);
		case 'LIST_DATA':
			return fetchAnalyticsList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function findImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_WASTAGE_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

function fetchAnalyticsList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.WASTAGE_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function exportAnalyticsList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.WASTAGE_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions);
}

// function skuStock() {
// const requestOptions = {
// method: 'GET',
// headers: {
// ...authHeader(),
// 'Content-Type': 'application/json',
// },
// };
// return fetch(endpoints.SKU_STOCK, requestOptions).then(handleResponse);
// }

export default commonAnalyticsServices;
