/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../../Pages';
import CreditNotePage from '../../../../Pages/CreditNotePage/CreditNotePage';
import CreditNoteCreatePage from '../../../../Pages/CreditNotePage/CreditNoteCreatePage/CreditNoteCreatePage';

const CreditNoteRoutes = (props) => {
	const { type = 'creditNote' } = props;
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				{/* <MyMachinesPage createPath={`${path}/add`} /> */}
				<CreditNotePage creditPageType={type} createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<CreditNoteCreatePage createCreditPageType={type} />}
					entity='MACHINES'
				/>
			</Route>
		</Switch>
	);
};

export default CreditNoteRoutes;
