const assetConstants = {
	FETCH_ASSET_REQUEST: 'FETCH_ASSET_REQUEST',
	FETCH_ASSET_SUCCESS: 'FETCH_ASSET_SUCCESS',
	FETCH_ASSET_FAILURE: 'FETCH_ASSET_FAILURE',

	ADD_ASSET_REQUEST: 'ADD_ASSET_REQUEST',
	ADD_ASSET_SUCCESS: 'ADD_ASSET_SUCCESS',
	ADD_ASSET_FAILURE: 'ADD_ASSET_FAILURE',

	LIST_VAIRENT_ASSET_REQUEST: 'LIST_VAIRENT_ASSET_REQUEST',
	LIST_VAIRENT_ASSET_SUCCESS: 'LIST_VAIRENT_ASSET_SUCCESS',
	LIST_VAIRENT_ASSET_FAILURE: 'LIST_VAIRENT_ASSET_FAILURE',

	ASSET_DASHBOARD_REQUEST: 'ASSET_DASHBOARD_REQUEST',
	ASSET_DASHBOARD_SUCCESS: 'ASSET_DASHBOARD_SUCCESS',
	ASSET_DASHBOARD_FAILURE: 'ASSET_DASHBOARD_FAILURE',

	ASSET_FRIDGE_MONITORING_REQUEST: 'ASSET_FRIDGE_MONITORING_REQUEST',
	ASSET_FRIDGE_MONITORING_SUCCESS: 'ASSET_FRIDGE_MONITORING_SUCCESS',
	ASSET_FRIDGE_MONITORING_FAILURE: 'ASSET_FRIDGE_MONITORING_FAILURE',
	ASSET_FRIDGE_MONITORING_RESET: 'ASSET_FRIDGE_MONITORING_RESET',

	STORE_ASSET_LISTING_REQUEST: 'STORE_ASSET_LISTING_REQUEST',
	STORE_ASSET_LISTING_SUCCESS: 'STORE_ASSET_LISTING_SUCCESS',
	STORE_ASSET_LISTING_FAILURE: 'STORE_ASSET_LISTING_FAILURE',
	STORE_ASSET_LISTING_RESET: 'STORE_ASSET_LISTING_RESET',

	ASSET_CHARTS_DASHBOARD_REQUEST: 'ASSET_CHARTS_DASHBOARD_REQUEST',
	ASSET_CHARTS_DASHBOARD_SUCCESS: 'ASSET_CHARTS_DASHBOARD_SUCCESS',
	ASSET_CHARTS_DASHBOARD_FAILURE: 'ASSET_CHARTS_DASHBOARD_FAILURE',
};

export default assetConstants;
