/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable */
// import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import {
	useEffect, useMemo, useState
} from 'react';
import { Eye, PlusIcon } from 'lucide-react';
import { SelectPicker } from 'rsuite';
import styles from './ProductProcessMap.module.scss';
// import { notificationServices } from '../../Services';
import { classes } from '../../Helpers';
import productProcessMapServices from '../../Services/Analytics Services/product-process-map.services';
import { notificationServices } from '../../Services';
import ProductProcessMapCreatorModal from '../../Components/Modals/ProductProcessMapCreatorModal/ProductProcessMapCreatorModal';
import { formActions, modalActions } from '../../Actions';
import { ListTable } from '../../Components/ListTable';
import ProductWiseMappingModal from '../../Components/Modals/ProductWiseMappingModal/ProductWiseMappingModal';
import { Button } from '@/Components/ui/button';
import {
	Tabs, TabsList, TabsTrigger
} from '@/Components/ui/tabs';
import {
	Select, SelectContent, SelectItem, SelectTrigger, SelectValue
} from '@/Components/ui/select';
import { Label } from '@/Components/ui/label';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import { AlertModal } from '@/Components/Modals';
import authActions from '@/Actions/auth.actions';

// import NodeTypes from './NodeTypes/NodeTypes';

const maps = {
	PRODUCT_TYPE: 'Product type (3)',
	CATEGORY: 'Product category (2)',
	PRODUCT: 'Products (1)',
};

const ACTION_COLUMNS = [
	{
		title: 'Delete Mapping',
		config: {
			type: 'CANCEL',
		},
	},
];

function ProductProcessMap(props) {
	const {
		toggleProductWiseMappingModal,
		toggleModal,
		toggleAlert,
		stores,
		fetchStoresList,
		navState,
		userList,
		fetchUserList,
	} = props;
	const { userConfig } = useAuth();
	const [loading, setLoading] = useState(false);
	const [currentData, setCurrentData] = useState(null);
	const [currentTab, setCurrentTab] = useState('PRODUCT_TYPE');
	const [selectedBaseStore, setSelectedBaseStore] = useState(null);
	const [selectedStoreType, setSelectedStoreType] = useState(null);
	const [selectedMappingType, setSelectedMappingType] = useState(null);
	const [availableMappingTypes, setAvailableMappingTypes] = useState([]);
	const [
		workflowSchedules,
		setWorkflowSchedules,
	] = useState([]);

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/product-process-map']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Production' || item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	const fetchAvailableMappingTypes = async () => {
		try {
			const response = await productProcessMapServices.fetchAvailableMappingTypes();
			if (response?.data?.mappingTypes) {
				setAvailableMappingTypes(response?.data?.mappingTypes);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchAvailableMappingTypes();
		fetchUserList();
	}, []);

	const ALL_STORES = useMemo(() => {
		const tempStore = stores
			?.map((tStore) => {
				return {
					id: tStore.id,
					title: tStore?.name ?? '',
					type: tStore?.type,
					subType: tStore?.subType,
				};
			});
		return tempStore;
	}, [stores]);

	const STORE_TYPES = useMemo(() => {
		const tempStoreTypes = ALL_STORES?.reduce((acc, curr) => {
			const type = curr?.type;
			const subType = curr?.subType;
			const typeSubTypeLabel = `${type}${subType ? ` (${subType})` : ''}`;
			const typeSubTypeValue = `${type}${subType ? `__${subType}` : ''}`;
			if (!acc.find((typeObj) => typeObj?.value === typeSubTypeValue)) {
				acc.push({
					value: typeSubTypeValue,
					label: typeSubTypeLabel,
				});
			}
			return acc;
		}, []);
		return tempStoreTypes;
	}, [ALL_STORES]);

	async function getList() {
		if (loading) {
			return;
		}
		try {
			setLoading(true);
			const response = await productProcessMapServices.listProductProcess(
				{
					data: {
						storeId: selectedBaseStore?.id,
						mappingType: selectedMappingType?.id,
						storeTypeSubType: selectedStoreType,
					},
				}
			);
			if (response?.responseCode === 'SS-001') {
				setCurrentData(response?.data);
			} else {
				notificationServices?.generateNotification({
					message:
						response?.responseMessage ??
						response?.status ??
						'Some error Occured',
					type: 'error',
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				message: error,
				type: 'error',
			});
		} finally {
			setLoading(false);
		}
	}
	useEffect(() => {
		if (
			selectedMappingType?.id && (
				!selectedMappingType?.storeBased ||
				selectedBaseStore?.id
			) &&
			(
				!selectedMappingType?.storeTypeBased ||
				selectedStoreType
			)
		) {
			getList();
		}
	}, [selectedBaseStore?.id, selectedMappingType, selectedStoreType]);

	const handleConfirmMap = async ({
		selectedMappingTypeValue,
		selectedElement,
	}) => {
		try {
			const response =
				await productProcessMapServices.mapProductToProcess({
					data: {
						mappingOn: currentTab,
						mappingOnElements: selectedElement,
						store: selectedBaseStore?.id && {
							id: selectedBaseStore?.id,
						},
						storeTypeSubType: selectedStoreType,
						mappingType: {
							id: selectedMappingType?.id,
						},
						mappingTypeElement: selectedMappingTypeValue?.id,
					},
				});
			if (response?.responseCode === 'SS-001') {
				notificationServices?.generateNotification({
					message: response?.responseMessage ?? response?.status,
					type: 'success',
				});
				toggleModal(false);
				getList();
			} else {
				notificationServices?.generateNotification({
					message:
						response?.responseMessage ??
						response?.status ??
						'Some error occurred',
					type: 'error',
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				message: error,
				type: 'error',
			});
		}
	};
	const handleDelete = async (id) => {
		try {
			const response =
				await productProcessMapServices.deleteProcessProductMap({
					data: {
						id,
					},
				});
			if (response?.responseCode === 'SS-001') {
				notificationServices?.generateNotification({
					message:
						response?.responseMessage ??
						response?.status ??
						'Success',
					type: 'success',
				});
				getList();
			} else {
				notificationServices?.generateNotification({
					message:
						response?.responseMessage ??
						response?.status ??
						'Some error Occured',
					type: 'error',
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				message: error,
				type: 'error',
			});
		}
	};
	const SPECIAL_ITEMS = {
		'Delete Mapping': (itemId) => {
			return (
				<div
					style={{
						width: '125px',
					}}
				>
					<Button
						className='danger-btn'
						onClick={() => toggleAlert(true, {
							  heading: 'Delete Product Process',
							  message: 'Are You Sure Want to Delete this Mapping',
							  handleConfirm: () => {
								handleDelete(itemId);
								toggleAlert(false);
							  },
						})}
					>
						Delete
					</Button>
				</div>
			);
		},
	};

	const handleMappingType = async (value) => {
		setCurrentData([]);
		setSelectedBaseStore(null);
		const type = availableMappingTypes?.find((ele) => {
			return ele?.id === parseInt(value, 10);
		});
		setSelectedMappingType(type);
		try {
			const req = {
				params: {
					mappingTypeId: selectedMappingType?.id,
				},
			};
			const response =
				await productProcessMapServices.fetchProductWorkflowSchedule(req);
			if (response?.statusCode === 200) {
				setWorkflowSchedules(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const TABLE_COLUMNS = [
		{
			title: 'Element Name',
			config: {
				type: 'DATA',
			},
		},
		{
			title: `${selectedMappingType?.displayName ?? 'Process Name'}`,
			config: {
				type: 'DATA',
			},
		},
	];

	const COLMAP = {
		'Element Name': 'mappingOnElementName',
		[`${selectedMappingType?.displayName ?? 'Process Name'}`]: 'mappingTypeElementName',
	};

	const handleProductWise = () => {
		toggleProductWiseMappingModal(true, {
			heading: 'Product Wise Mapping',
			availableMappingTypes,
			selectedBaseStore,
			selectedMappingType,
		});
	};

	return (
		<div className={classes(styles.root)}>
			<ProductProcessMapCreatorModal
				handleConfirmMap={handleConfirmMap}
				selectedMappingType={selectedMappingType}
			/>
			<AlertModal />
			<ProductWiseMappingModal />
			<div className='flex justify-between items-center p-3'>
				<div className='font-semibold text-lg'>Product Mapping</div>
				<div className='flex flex-col gap-1'>
					<Label
						className={`${
							selectedMappingType?.id ? '' : 'text-red-600 animate-pulse'
						}`}
					>
						Select Mapping Type
					</Label>
					<Select
						value={selectedMappingType?.id}
						onValueChange={(value) => {
							setSelectedStoreType(undefined);
							setSelectedBaseStore(undefined);
							handleMappingType(value);
						}}
					>
						<SelectTrigger className='w-full mt-2 outline-none'>
							<SelectValue placeholder='Select Mapping Type' />
						</SelectTrigger>
						<SelectContent>
							{availableMappingTypes?.map((types) => {
								return (
									<SelectItem
										key={types?.id}
										value={types?.id}
									>
										{types?.displayName}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className={styles.tabs}>
				<Tabs
					value={currentTab}
					onValueChange={(value) => {
						setCurrentTab(value);
					}}
				>
					<TabsList className=''>
						{Object?.keys(maps).map((ele) => {
							return (
								<TabsTrigger
									className='data-[state=active]:bg-uy-color'
									value={ele}
								>
									{maps[ele]}
								</TabsTrigger>
							);
						})}
					</TabsList>
				</Tabs>
				<div className={styles?.selector}>
					{selectedMappingType?.storeBased && (
						<div className={styles['store-selector']}>
							<SelectPicker
								data={(ALL_STORES ?? [])?.map((ele) => {
									return {
										...ele,
										label: ele?.title,
										role: ele?.type,
										value: ele,
									};
								})}
								style={{
									width: 224,
								}}
								groupBy='type'
								placeholder='Select Store'
								onChange={(storeId) => {
									setSelectedBaseStore(storeId);
								}}
								renderMenuItem={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-user' />
											{' '}
											{_}
										</div>
									);
								}}
								renderMenuGroup={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-group' />
											{_}
										</div>
									);
								}}
								cleanable={false}
								value={selectedBaseStore}
								renderValue={(_, item) => {
									return (
										<div
											className={`${styles.selected_store} rsuite-select-picker-render-value`}
										>
											<p>
												Store:
												{item?.title}
											</p>
										</div>
									);
								}}
								// onClean={() => {
								// console.log('heeloooo there');
								// }}
							/>
							{!selectedBaseStore?.id && (
								<p className={styles.warning}>
									Store Selection is Mandatory
								</p>
							)}
						</div>
					)}
					{selectedMappingType?.storeTypeBased && (
						<div className={styles['store-type-selector']}>
							<Select
								value={selectedStoreType ?? undefined}
								onValueChange={(value) => {
									setSelectedStoreType(value);
								}}
							>
								<SelectTrigger className='w-full mt-2 outline-none'>
									<SelectValue placeholder='Select Store Type' />
								</SelectTrigger>
								<SelectContent>
									{STORE_TYPES?.map((types) => {
										return (
											<SelectItem
												key={types?.value}
												value={types?.value}
											>
												{types?.label}
											</SelectItem>
										);
									})}
								</SelectContent>
							</Select>
							{!selectedStoreType && (
								<p className={styles.warning}>
									Store Type Selection is Mandatory
								</p>
							)}
						</div>
					)}
					<Button className='flex items-center gap-2' variant='outline' onClick={handleProductWise}>
						<Eye className='h-4' />
						View Product Wise Mapping
					</Button>
				</div>
				<div className={styles.buttons}>
						<Button
							onClick={() => {
								if (selectedMappingType?.storeBased) {
									if (!selectedBaseStore?.id) {
										notificationServices?.generateNotification(
											{
												message:
													'Please select a store',
												type: 'error',
											}
										);
										return;
									}
								}
								if (selectedMappingType?.storeTypeBased) {
									if (!selectedStoreType) {
										notificationServices?.generateNotification(
											{
												message:
													'Please select a store type',
												type: 'error',
											}
										);
										return;
									}
								}
								if (!selectedMappingType?.id) {
									notificationServices?.generateNotification({
										message: 'Please select a Mapping Type',
										type: 'error',
									});
									return;
								}

								toggleModal(true, {
									heading: `Product ${selectedMappingType?.displayName}`,
									selectorType: currentTab,
									selectedBaseStore,
									selectedStoreType,
									workflowSchedules,
									selectedMappingType,
								});
							}}
							className='primary-btn flex items-center'
						>
							<PlusIcon className='h-4' />
							Add
						</Button>
				</div>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				showTotal
				data={currentData?.[currentTab] ?? []}
				actions={ [...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state.auth;
	const stores = state?.form?.STORES?.data?.LIST;

	return {
		stores,
		userPermissions,
		navState,
		userList,
	};
};
const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
	toggleModal: modalActions.toggleProductProcessMapCreatorModal,
	toggleProductWiseMappingModal: modalActions.toggleProductWiseMappingModal,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductProcessMap);
