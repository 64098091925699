import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import ListGstDetails from '@/Pages/GstDetails/listGstDetails';
import CRUDRoutes from '../CRUD Routes/CRUD.routes';
import GstDetails from '@/Pages/GstDetails/gstDetail';
import { urls } from '@/Constants';

function CrudGsttRoutes({ props }) {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.GST_DETAILS}`,
			name: 'GST Details',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='Gst Details'
			path={path}
			listPage={ListGstDetails}
			// listFilter={<ProductListFilters />}
			// viewPage={<ListAsset breadcrumbs={breadcrumbs} />}
			editPage={<GstDetails isEdit breadcrumbs={breadcrumbs} />}
			createPage={<GstDetails breadcrumbs={breadcrumbs} />}
		/>
	);
}

export default CrudGsttRoutes;
