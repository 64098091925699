/* eslint-disable */
import {
	useEffect, useCallback, useRef, useState,
	useMemo
} from 'react';
import { connect } from 'react-redux';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Button } from '@mui/material';
import SelectPicker from 'rsuite/SelectPicker';
import Input from 'rsuite/Input';
// import ListTable from '../../../Components/ListTable/ListTable';
import styles from './VariantsThresholdListPage.module.scss';
import { notificationServices } from '../../../Services';
import variantsThresholdServices from '../../../Services/variantsThreshold.services';
import { BackdropLoader } from '../../../Components/Loaders';
import UploadDataModal from '../../../Components/Modals/UploadDataModal/UploadDataModal';
// eslint-disable-next-line import/no-unresolved, import/extensions
// import { VirtualizedListTable } from '@/Components/VirtualizedListTable';
import { ListTable } from '@/Components/ListTable';
import { skuTypesList } from '@/Helpers';
import { formActions, modalActions } from '@/Actions';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'Size 1',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 2',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 3',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 4',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 5',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 6',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 7',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 8',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 9',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Size 10',
		config: {
			type: 'DATA',
		},
	},
];
const ACTION_COLUMNS = [
	// {
	// title: 'View',
	// config: {
	// type: 'VIEW',
	// },
	// },
];

const COLMAP = {
	'Product Name': 'productName',
	Name: 'productName',
	'SKU Type': 'skuType',
	Category: 'category',
	Unit: 'unit',
	'Size 1': 'id',
	'Size 2': 'id',
	'Size 3': 'id',
	'Size 4': 'id',
	'Size 5': 'id',
	'Size 6': 'id',
	'Size 7': 'id',
	'Size 8': 'id',
	'Size 9': 'id',
	'Size 10': 'id',
};

const skuTypes = ['ALL', ...skuTypesList];

// eslint-disable-next-line no-unused-vars
const generateIcon = (arr, idx, value, onInputChange) => {
	const iconDOM =
		// eslint-disable-next-line no-nested-ternary
		arr.length > idx ? (
			<div className={styles['variant-input']}>
				<p>{arr[idx]?.variantName}</p>
				<input className={styles.thres_input} type='number' value={value} onChange={onInputChange} />
			</div>
		) : (
			<DoNotDisturbOnIcon
				style={{
					color: 'grey',
				}}
			/>
		);
	return <td key={idx}>{iconDOM}</td>;
};

const VariantsThresholdListPage = (props) => {
	const {
		fetchVariantsThreshold,
		data,
		stores,
		fetchStoresList,
		updateVariantsThresholdList,
		toggleUploadDataModal,
		showUploadDataModal,
		payload,
		navState,
		fetchUserList,
		userList,
	} = props;
	const listRef = useRef(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedStore, setSelectedStore] = useState(null);
	const [selectedSkuType, setSelectedSkuType] = useState(null);
	const [updateState, setUpdateState] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [pageCount, setPageCount] = useState({
		currentPage: 1,
		prevPage: 0,
	});
	const [isLoading, setIsLoading] = useState(false);

	// // SCROLL STATE
	// const [currPage, setCurrPage] = useState(1);
	// const [prevPage, setPrevPage] = useState(0);
	// const [lastList, setLastList] = useState(false);
	// const { userConfig } = useAuth();
	// // userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu?.['/products/threshold/variants']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// USER END

	useEffect(() => {
		setIsLoading(true);
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		setIsLoading(false);
		return () => {
			updateVariantsThresholdList({
				entity: 'VARIANTS_THRESHOLD',
				method: 'LIST_DATA',
				innerField: 'data',
				data: [],
			});
		};
	}, []);

	useEffect(() => {
		if (
			selectedStore &&
			pageCount?.currentPage !== pageCount?.prevPage
		) {
			updateVariantsThresholdList({
				entity: 'VARIANTS_THRESHOLD',
				method: 'LIST_DATA',
				innerField: 'data',
				data: [],
			});
			fetchVariantsThreshold({
				entity: 'VARIANTS_THRESHOLD',
				req: {
					params: {
						storeId: selectedStore,
						productType:
							selectedSkuType === 'ALL' ? null : selectedSkuType,
					},
				},
				method: 'LIST_DATA',
				showNotification: true,
			});
		}
	}, [selectedStore, selectedSkuType, pageCount?.currentPage, pageCount?.prevPage]);

	useEffect(() => {
		setUpdateState({});
		if (data?.length > 0) {
			let temp = {};
			data?.forEach((prod) => {
				prod?.variants?.forEach((variant) => {
					temp = {
						...temp,
						[prod?.id]: {
							...temp?.[prod?.id],
							[variant?.variantId]: variant?.threshold,
						},
					};
				});
			});
			setUpdateState(temp);
		}
	}, [data]);

	const handleImport = async (uploadFile) => {
		try {
			setIsLoading(true);
			const response = await variantsThresholdServices.uploadVariantsThresholdData({
				data: {
					variantThreshold: uploadFile?.file,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				fetchVariantsThreshold({
					entity: 'VARIANTS_THRESHOLD',
					req: {
						params: {
							storeId: selectedStore,
							productType:
								selectedSkuType === 'ALL' ? null : selectedSkuType,
						},
					},
					method: 'LIST_DATA',
					showNotification: true,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occured while uploading data',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const uploadVariantsThresholdData = () => {
		toggleUploadDataModal(true, {
			heading: 'Upload Variant Threshold and Recommended Stock',
			handleImport,
		});
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [
		];
		const downloadBtn = document.createElement('a');
		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const handleExport = () => {
		const headers = [
			'Item Code',
			'UY Item Id',
			'Store Id',
			'Store Code',
			// 'Store Name',
			'Product Name',
			'Variant Name',
			'Threshold',
			'Recommended Stock',
		];
		const totalValues = [];
		const exportData = [];
		const selectedStoreNames = [];
		data?.forEach((product) => {
			product?.variants?.forEach((variant) => {
				exportData.push({
					clientVariantId: variant?.clientVariantId,
					variantId: variant?.variantId,
					storeId: product?.storeId,
					clientStoreId: product?.clientStoreId,
					// storeName: product?.storeName,
					productName: product?.productName?.split(',').join(' '),
					variantName: variant?.variantName,
					threshold: variant?.threshold,
					recommendedStock: variant?.recommendedStock,
				});
				selectedStoreNames.push(product?.storeName);
			});
		});
		exportData?.forEach((product) => {
			totalValues?.push([
				product?.clientVariantId,
				product?.variantId,
				product?.storeId,
				product?.clientStoreId,
				// product?.storeName,
				product?.productName,
				product?.variantName,
				product?.threshold,
				product?.recommendedStock,
			]);
		});
		const storeNames = [...new Set(selectedStoreNames)];
		handleExcelDownload(
			headers,
			totalValues,
			`Variant Threshold of Products for Stores ${storeNames?.join(',')}`
		);
	};

	const onInputChange = (e, prodId, varId) => {
		setUpdateState((prev) => {
			return {
				...prev,
				[prodId]: {
					...prev?.[prodId],
					[varId]: e?.target?.value,
				},
			};
		});
	};

	const SPECIAL_ITEMS = {
		'Size 1': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value = updateState?.[item?.id]?.[item?.variants[0]?.variantId];
			return generateIcon(item?.variants, 0, value, (e) => {
				onInputChange(e, id, item?.variants?.[0]?.variantId);
			});
		},
		'Size 2': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value = updateState?.[id]?.[item?.variants[1]?.variantId];
			return generateIcon(item?.variants, 1, value, (e) => {
				onInputChange(e, id, item?.variants?.[1]?.variantId);
			});
		},
		'Size 3': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value =
				updateState?.[item?.id]?.[item?.variants[2]?.variantId];
			return generateIcon(item?.variants, 2, value, (e) => {
				onInputChange(e, id, item?.variants?.[2]?.variantId);
			});
		},
		'Size 4': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value =
				updateState?.[item?.id]?.[item?.variants[3]?.variantId];
			return generateIcon(item?.variants, 3, value, (e) => {
				onInputChange(e, id, item?.variants?.[3]?.variantId);
			});
		},
		'Size 5': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value =
				updateState?.[item?.id]?.[item?.variants[4]?.variantId];
			return generateIcon(item?.variants, 4, value, (e) => {
				onInputChange(e, id, item?.variants?.[4]?.variantId);
			});
		},
		'Size 6': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value = updateState?.[item?.id]?.[item?.variants[5]?.variantId];
			return generateIcon(item?.variants, 5, value, (e) => {
				onInputChange(e, id, item?.variants?.[5]?.variantId);
			});
		},
		'Size 7': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value = updateState?.[id]?.[item?.variants[6]?.variantId];
			return generateIcon(item?.variants, 6, value, (e) => {
				onInputChange(e, id, item?.variants?.[6]?.variantId);
			});
		},
		'Size 8': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value =
				updateState?.[item?.id]?.[item?.variants[7]?.variantId];
			return generateIcon(item?.variants, 7, value, (e) => {
				onInputChange(e, id, item?.variants?.[7]?.variantId);
			});
		},
		'Size 9': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value =
				updateState?.[item?.id]?.[item?.variants[8]?.variantId];
			return generateIcon(item?.variants, 8, value, (e) => {
				onInputChange(e, id, item?.variants?.[8]?.variantId);
			});
		},
		'Size 10': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const value =
				updateState?.[item?.id]?.[item?.variants[9]?.variantId];
			return generateIcon(item?.variants, 9, value, (e) => {
				onInputChange(e, id, item?.variants?.[9]?.variantId);
			});
		},
	};

	// const selectStore = (e) => {
	// setSelectedStore(e.target.value);
	// };

	const onSubmit = async () => {
		let editValues = {};
		data?.forEach((prod) => {
			prod?.variants?.forEach((variant) => {
				// eslint-disable-next-line eqeqeq
				if (updateState?.[prod?.id]?.[variant?.variantId] != variant?.threshold) {
					editValues = {
						...editValues,
						[prod?.id]: {
							...editValues?.[prod?.id],
							[variant?.variantId]:
								updateState?.[prod?.id]?.[variant?.variantId],
						},
					};
				}
			});
		});
		try {
			const response = await variantsThresholdServices.editThreshold({
				params: {
					storeId: selectedStore,
				},
				data: {
					editValues,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				fetchVariantsThreshold({
					entity: 'VARIANTS_THRESHOLD',
					req: {
						params: {
							storeId: selectedStore,
							productType:
								selectedSkuType === 'ALL' ? null : selectedSkuType,
						},
					},
					method: 'LIST_DATA',
					showNotification: true,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occured',
			});
		}
	};

	const handleScroll = useCallback(() => {
		// if (listRef?.current) {
		// const { scrollTop, scrollHeight, clientHeight } = listRef.current;
		// if (Math.floor(scrollTop + clientHeight) === scrollHeight) {
		// console.log('CHeckkk');
		// setPageCount((prev) => {
		// return {
		// ...prev,
		// currentPage: prev?.currentPage + 1,
		// };
		// });
		// }
		// }
	}, []);

	const BASE_STORES = useMemo(() => {
		const tempStoreList = stores?.map((ele) => {
			return {
				...ele,
				label: ele?.name,
				role: ele?.type,
				value: ele?.id,
			};
		});
		return tempStoreList;
	}, [stores]);

	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={isLoading} />
			<UploadDataModal
				show={showUploadDataModal}
				handleImport={handleImport}
				heading={payload?.heading}
			/>
			<div className={styles.header}>
				<div className={styles.filter}>
					<Input
						value={searchQuery}
						onChange={(value) => {
							setSearchQuery(value);
						}}
						style={{
							width: 200,
							borderRadius: 25,
							border: '1px solid #000',
						}}
						placeholder='Search Product'
					/>
					<div className={styles['store-container']}>
						{/* <TextField
							label='Select Store'
							className={styles['store-select']}
							select
							value={selectedStore}
							onChange={selectStore}
						>
							{stores?.map((ele) => {
								return (
									<MenuItem key={ele.id} value={ele.id}>
										{ele?.name}
									</MenuItem>
								);
							})}
						</TextField> */}
						<SelectPicker
							data={BASE_STORES}
							style={{
								width: 224,
							}}
							groupBy='role'
							placeholder='Select Store'
							onChange={(storeId) => {
								setSelectedStore(storeId);
							}}
							renderMenuItem={(_) => {
								return (
									<div>
										<i className='rs-icon rs-icon-user' />
										{_}
									</div>
								);
							}}
							renderMenuGroup={(_) => {
								return (
									<div>
										<i className='rs-icon rs-icon-group' />
										{_}
									</div>
								);
							}}
							value={selectedStore}
							cleanable={false}
							renderValue={(value, item) => {
								return (
									<div className='rsuite-select-picker-render-value'>
										{item?.label}
									</div>
								);
							}}
						/>
						{!selectedStore && (
							<p>Select Store to get Variants threshold</p>
						)}
					</div>
					{/* <TextField
						label='Select SKU Type'
						className={styles.selector}
						select
						value={selectedSkuType}
						onChange={(e) => {
							setSelectedSkuType(e?.target.value);
						}}
					>
						{skuTypes?.map((ele) => {
							return (
								<MenuItem key={ele} value={ele}>
									{ele}
								</MenuItem>
							);
						})}
					</TextField> */}
					<SelectPicker
						data={skuTypes?.map((ele) => {
							return {
								label: ele,
								value: ele,
							};
						})}
						onChange={(skuTypeValue) => {
							setSelectedSkuType(skuTypeValue);
						}}
						cleanable={false}
						searchable={false}
						style={{
							width: 150,
						}}
						placeholder='SKU Type'
					/>
				</div>
				<div className='flex flex-row gap-2'>
					{
						selectedStore && (

							<Button
								variant='contained'
								className={styles.button}
								onClick={uploadVariantsThresholdData}
							>
								Update Threshold
							</Button>
						)
					}
					{
						Object.keys(updateState ?? {})?.length > 0 && (

							<Button
								variant='contained'
								className={styles.button}
								onClick={onSubmit}
							>
								Submit
							</Button>
						)
					}
				</div>
			</div>
			<div
				onScroll={handleScroll}
				ref={listRef}
				className={styles['list-container']}
			>
				<ListTable
					useWindowScroll={false}
					onScroll={handleScroll}
					colMap={COLMAP}
					columns={[...TABLE_COLUMNS, ...SPECIAL_COLUMNS]}
					data={data.filter((product) => {
						return (
							product?.productName
								?.toLowerCase()
								?.indexOf(
									searchQuery?.trim()?.toLowerCase()
								) !== -1
						);
					})}
					fixedHeaderTop={0}
					listRef={listRef}
					showTotal
					actions={[...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					classname={styles.table}
					onExportClick={handleExport}
					onImportClick={handleImport}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.VARIANTS_THRESHOLD.data.LIST_DATA;
	const stores = state?.form?.STORES?.data?.LIST;
	const { uploadDataModal } = state.modal;
	const showUploadDataModal = uploadDataModal?.show;
	const payload = uploadDataModal?.data;
	const { userPermissions, navState } = state?.navigation;
	const { userList } = state?.auth;
	return {
		data,
		stores,
		showUploadDataModal,
		payload,
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchVariantsThreshold: formActions.gateway,
	fetchStoresList: formActions.gateway,
	updateVariantsThresholdList: formActions.updateFields,
	toggleUploadDataModal: modalActions.toggleUploadDataModal,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VariantsThresholdListPage);
