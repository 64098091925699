/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-template */
import { useState } from 'react';
import { connect } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './DynamicTableModal.module.scss';
import DynamicHeaderTable from '../../DynamicHeaderTable/DynamicHeaderTable';
import { classes } from '../../../Helpers';
import { useAuth } from '../../Auth/Login/auth';
import { BackdropLoader } from '../../Loaders';
import logo from '../../../Images/logo.aeb5ee07.png';
import POVendorDetails from '../../POGeneralDetails/VendorDetails/POVendorDetails';

const DynamicTableModal = (props) => {
	const { show, data, toggleModal, isBuyer } = props;
	const { contract, pricing: prices, products, regions, vendor } = data;
	const HEADING = `${isBuyer ? 'Buyer Contract' : 'Vendor Contract'} - ${vendor?.name}`;
	const { appConfig = {} } = useAuth();
	const [isLoading, setLoading] = useState();
	const { measurement } = appConfig?.configs;
	const mappingData = (dataToMap) => {
		const tempFlatData = {};

		const recFunction = (dataToRec) => {
			const returnData = [];
			let excludedChildren = [];
			dataToRec?.forEach((ele) => {
				const { returnData: children, excludedChildren: excChildren } =
					recFunction(ele?.children);
				const item = {};
				if (ele?.select === 1) {
					let name = ele?.name;
					if (excChildren.length) {
						name += ' ( Excluding ';
						name += excChildren
							?.map((childEle) => {
								return childEle?.name;
							})
							.join(', ');
						name += ' )';
					}
					item.colName = name;
					item.id = ele?.id;
					item.type = ele?.type;
					const flatItem = {
						type: ele?.type,
						id: ele?.id,
						value: '',
						name: ele?.name,
					};
					tempFlatData[ele?.type + '-' + ele?.id] = flatItem;
					if (children.length > 0) {
						item.subCols = [
							{
								colName: 'Default',
								id: ele?.id,
								type: ele?.type,
								subCols: [],
							},
						];
					}
				} else if (ele?.select === -1) {
					excludedChildren.push(ele);
				}

				if (ele?.select !== 1) {
					excludedChildren = [...excludedChildren, ...excChildren];
				}

				if (children.length > 0) {
					let name = ele?.name;
					if (excChildren.length) {
						name += ' ( Excluding ';
						name += excChildren
							?.map((childEle) => {
								return childEle?.name;
							})
							.join(', ');
						name += ' )';
					}
					item.colName = name;
					if (item.subCols) {
						item.subCols = [...item.subCols, ...children];
					} else {
						item.subCols = children;
					}
				}
				if (Object.keys(item).length !== 0) {
					returnData.push(item);
				}
			});
			return {
				returnData,
				excludedChildren,
			};
		};

		const { returnData: children } = recFunction(dataToMap);
		const returnData = [
			{
				colName: 'Products',
				subCols: [],
			},
			{
				colName: 'Unit',
				subCols: [],
			},
			...children,
		];
		return returnData;
	};

	const EditableInput = ({ colId: id, type, rowItem }) => {
		const variantId = rowItem?.variantId;
		const item = prices?.[variantId]?.[type + '-' + id];
		let itemPrice = item?.value;
		if (rowItem?.unit === 'gram' || rowItem?.unit === 'ml') {
			itemPrice *= 1000;
		}
		if (rowItem?.unit === 'oz') {
			itemPrice *= 16;
		}
		if (rowItem?.unit === 'floz') {
			itemPrice *= 128;
		}
		return (
			<div className={classes(styles['editable-input'])}>
				<div className={classes(styles['item-center'])}>
					<span className={classes(styles['custom-input'])}>
						{item?.access ? (measurement === 'US' ? '$' : '₹') + (itemPrice ?? 0) : 'N.A'}
					</span>
				</div>
				<span>
					{item?.access ? (
						<span className={classes(styles.includeIcon)}>
							<CheckCircleIcon />
						</span>
					) : (
						<span className={classes(styles.excludeIcon)}>
							<CancelIcon />
						</span>
					)}
				</span>
			</div>
		);
	};

	const addImageContained = async (pdf, imageData, format, x, y, width, height) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = imageData;

			img.onload = () => {
				const aspectRatio = img.width / img.height;
				const targetAspectRatio = width / height;

				let scaleX;
				let scaleY;
				if (aspectRatio > targetAspectRatio) {
					scaleX = width / img.width;
					scaleY = scaleX;
				} else {
					scaleY = height / img.height;
					scaleX = scaleY;
				}

				const finalWidth = img.width * scaleX;
				const finalHeight = img.height * scaleY;

				pdf.addImage(
					imageData,
					format,
					x + (width - finalWidth) / 2,
					y + (height - finalHeight) / 2,
					finalWidth,
					finalHeight
				);
				resolve();
			};

			img.onerror = (error) => {
				reject(error);
			};
		});
	};

	const generatePDF = async () => {
		try {
			setLoading(true);
			const input = document.getElementById('divIdToPrint');
			const canvas = await html2canvas(input);
			const imgData = canvas.toDataURL('image/png');
			const text = 'Powered by Food Production and Supply Chain AI Tech from ';
			// const text1 = 'Powered by Food Production and';
			// const text2 = 'Supply Chain AI Tech from udyogyantra';
			// eslint-disable-next-line new-cap
			const pdf = new jsPDF();
			pdf.setFontSize(16);
			pdf.text(HEADING, 5, 35);
			const scaleFactor = pdf.internal.pageSize.getWidth() / canvas.width;
			await addImageContained(pdf, appConfig?.logob64, 'JPEG', 5, 5, 35, 20);
			// await addImageContained(pdf, logo, 'JPEG', 160, 5, 35, 20);
			// pdf.setFontSize(8);
			// pdf.text(text1, 157, 25);
			// pdf.text(text2, 157, 28);
			const height = canvas.height * scaleFactor;
			pdf.addImage(imgData, 'PNG', 5, 40, (canvas.width * scaleFactor) - 10, height);

			let remainingHeight = height - (pdf.internal.pageSize.getHeight() - 40);
			let currentPage = 1;
			while (remainingHeight > 0) {
				const pageHeight = pdf.internal.pageSize.getHeight() - (currentPage === 1 ? 42 : 20);
				pdf.addPage();
				pdf.addImage(
					imgData, 'PNG', 0,
					(pageHeight * (-1 * currentPage)),
					canvas.width * scaleFactor, height, null, 'SLOW'
				);
				remainingHeight -= pdf.internal.pageSize.getHeight();
				currentPage++;
			}
			let ystart = pdf.internal.pageSize.getHeight() + remainingHeight + 60;
			if (remainingHeight > -65) {
				ystart = 60;
				pdf.addPage();
			}
			pdf.setFontSize(12);
			pdf.text(
				contract?.vendorAuth ?? '',
				15, (ystart - 7)
			);
			pdf.text(
				'Vendor Signin Authority',
				10, ystart
			);
			pdf.text(
				contract?.companyAuth ?? '',
				150, ystart - 7
			);
			pdf.text(
				appConfig?.companyName + ' Signin Authority',
				145, ystart
			);
			pdf.setFontSize(8);
			pdf.text(text, 55, pdf.internal.pageSize.getHeight() - 5);
			await addImageContained(pdf, logo, 'JPEG', 133, pdf.internal.pageSize.getHeight() - 18, 35, 20);

			pdf.save(HEADING + '.pdf');
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
		} finally {
			setLoading(false);
		}
	};
	return (
		<BaseModal
			heading={HEADING}
			width='80%'
			height='98%'
			open={show}
			showConfirm={false}
			handleClose={() => {
				toggleModal(false, {});
				// setInputState([]);
			}}
		>
			<BackdropLoader isLoading={isLoading} showProgress />
			<span className={classes(styles.btnPrint)} onClick={generatePDF}>
				<LocalPrintshopIcon />
				Print
			</span>
			<div id='divIdToPrint' className={classes(styles['contract-container'])}>
				<div className={classes(styles['details-container'])}>
					<div className={classes(styles.text)}>
						Contract Detail:
						<div className={classes(styles['vendor-details'])}>
							<div className={classes(styles['detail-row'])}>
								<span>
									{isBuyer ? 'Buyer Name' : 'Vendor Name'}
									:
									{' '}
								</span>
								<span>{vendor?.name}</span>
							</div>
							<div className={classes(styles['detail-row'])}>
								<span>Payment Term: </span>
								<span>{contract?.paymentTerm}</span>
							</div>
							<div className={classes(styles['detail-row'])}>
								<span>Created At: </span>
								<span>{moment(contract?.createdAt).format('YYYY-MM-DD')}</span>
							</div>
							<div className={classes(styles['detail-row'])}>
								<span>Last updated At: </span>
								<span>{moment(contract?.updatedAt).format('YYYY-MM-DD')}</span>
							</div>
							<div className={classes(styles['detail-row'])}>
								<span>Valid till: </span>
								<span>{moment(contract?.expiryDate).format('YYYY-MM-DD')}</span>
							</div>
						</div>
					</div>
					<div className={classes(styles.text)}>
						{isBuyer ? 'Buyer GST Detail' : 'Vendor GST Detail'}
						<POVendorDetails
							showLabel={false}
							vendorDetails={vendor}
						/>
					</div>
				</div>
				<div className={classes(styles.text)}>
					{isBuyer ? 'Buyer Products Pricing' : 'Vendor Products Pricing'}
				</div>
				<DynamicHeaderTable
					headerData={mappingData(regions)}
					rowsData={products}
					tableDataElement={EditableInput}
					excludedCols={['Products', 'Unit']}
					showUnit
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.dynamicTableModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleDynamicTableModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicTableModal);
