import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { formActions, modalActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import FranchiseeDetails from '../../../Components/Forms/FranchiseeDetails/FranchiseeDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
import { inputHelper } from '../../../Helpers';
import AlertModal from '../../../Components/Modals/AlertModal/AlertModal';
import addFranchiseeConfig from './addFranchisee.config';

const AddCustomersViewPage = (props) => {
	const {
		isEdit,
		breadcrumbs,
		label,
		fetchFranchiseeData,
		data,
		deleteFranchiseeData,
		toggleAlert,
	} = props;
	const [state, setState] = useState({
		...addFranchiseeConfig.initialState,
	});

	const { fields, errors } = state;
	const { id } = useParams();

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setState({
			...state,
			fields: {
				...fields,
				[fieldName]: fieldValue,
			},
		});
	};

	useEffect(() => {
		fetchFranchiseeData({
			entity: 'MY_FRANCHISEE',
			req: {
				params: {
					user: id,
				},
			},
			method: 'VIEW',
		});
		return () => {
			toggleAlert(false);
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(data) && data.mobile === id) {
			setState(addFranchiseeConfig.serializer(state, data));
		}
	}, [data]);

	const updateLocation = ({ latitude = null, longitude = null }) => {
		const newState = {};
		setState({
			...state,
			fields: {
				...state.fields,
				latitude,
				longitude,
				...newState,
			},
			...errors,
		});
	};
	const { url } = useRouteMatch();
	const history = useHistory();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const handleDelete = () => {
		toggleAlert(true, {
			heading: `Do You Want To Delete Franchise ${fields.name}?`,
		});
	};

	const handleConfirm = () => {
		const req = {
			params: {
				user: id,
			},
		};
		deleteFranchiseeData({
			entity: 'MY_FRANCHISEE',
			req,
			method: 'DELETE',
			redirect: `${urls.EXTENSION}${urls.ADD_CUSTOMERS}`,
		});
		toggleAlert(false);
	};

	return (
		<div>
			<ViewPageHeader
				showBreadcrumbs
				heading={isEdit ? 'Edit Franchisee' : 'Add Franchisee'}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: isEdit
							? `Edit ${fields.name}`
							: `View Franchisee - ${label}`,
					},
				]}
				handleDelete={handleDelete}
				handleEdit={redirectToEdit}
			/>
			<FormSectionHeader
				sectionName='Franchisee Details'
				tooltip='Franchisee Details'
			>
				<FranchiseeDetails
					fields={fields}
					handleInput={handleInput}
					mandatoryFields={addFranchiseeConfig.mandatoryFields}
					updateLocation={updateLocation}
					coords={{
						lat: fields.latitude,
						lng: fields.longitude,
					}}
					isDisabled
					errors={errors}
				/>
			</FormSectionHeader>
			<AlertModal handleConfirm={handleConfirm} />
		</div>
	);
};
AddCustomersViewPage.defaultProps = {
	breadcrumbs: [],
};
const mapStateToProps = (state) => {
	const data = state.form.MY_FRANCHISEE.data.VIEW;
	const label = state.form.MY_FRANCHISEE.data.VIEW.name;
	return {
		data,
		label,
	};
};
const mapDispatchToProps = {
	fetchFranchiseeData: formActions.gateway,
	deleteFranchiseeData: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCustomersViewPage);
