/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { BackdropLoader } from '../../../Components/Loaders';
import { notificationServices } from '../../../Services';
import downloadDashboardDataService from '../../../Services/downloadDashboardData.services';
import DataTile from './DataTile/DataTile';
import styles from './DataTileContainer.module.scss';

// export { tempData };
function DataTileContainer({ data, arrowType, measurementType }) {
	const [isLoading, setIsLoading] = useState(false);
	// function handleDownload() {
	// 	const request = {
	// 		period: {
	// 			endDate: data?.endDate,
	// 			startDate: data?.startDate,
	// 		},
	// 	};
	// 	if (data.store) {
	// 		request.store = data.store;
	// 	}
	// 	(async () => {
	// 		setIsLoading(true);
	// 		const downloadLinkData = await downloadDashboardDataService({
	// 			req: {
	// 				data: request,
	// 			},
	// 		});
	// 		setIsLoading(false);
	// 		if (downloadLinkData?.responseCode === 'SS-001') {
	// 			window.open(downloadLinkData?.data?.downloadLink, '_blank');
	// 		} else {
	// 			notificationServices.generateNotification({
	// 				type: 'error',
	// 				message: 'Some Error occured',
	// 			});
	// 		}
	// 		// console.log(downloadLinkData);
	// 	})();
	// }

	return (
		<div
			className={styles.root}
			style={{
				background: data?.color ?? '',
			}}
		>
			{data?.heading ? (
				<div className={styles.heading}>
					<div className={styles.line} />
					{data?.heading}
					<div className={styles.line} />
				</div>
			) : (
				<></>
			)}
			{/* {data?.isDownload ? (
				<Tooltip title={data?.heading} arrow>
					<div
						onClick={() => {
							handleDownload();
						}}
						className={styles.downloadCompleteData}
					>
						<DownloadIcon className={styles.icon} />
					</div>
				</Tooltip>
			) : (
				<></>
			)} */}

			{/* <div className={styles.container}>
				{data?.stats?.map((ele) => {
					return (<DataTile key={ele?.heading} data={ele} />);
				})}
			</div> */}
			<div
				className={styles.container}
				style={
					arrowType
						? {
							gap: '2rem',
						}
						: {}
				}
			>
				{data?.stats?.map((ele, index, array) => {
					return (
						<>
							<DataTile
								showArrow={
									arrowType && index !== array?.length - 1
								}
								showOnLeft={
									arrowType && index % 4 === 0 && index !== 0
								}
								showOnRight={
									arrowType &&
									(index + 1) % 4 === 0 &&
									index !== array?.length - 1
								}
								key={ele?.heading}
								data={ele}
								measurementType={measurementType}
							/>
						</>
					);
				})}
			</div>
			<BackdropLoader showProgress isLoading={isLoading} />
		</div>
	);
}

export default DataTileContainer;
