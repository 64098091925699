const navigationConstants = {
	EXPAND_NAV: 'EXPAND_NAV',
	COLLAPSE_NAV: 'COLLAPSE_NAV',
	NAV_REQUISITE: 'NAV_REQUISITE',
	HANDLE_NAV_SELECTION: 'HANDLE_NAV_SELECTION',
	TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',

	UPDATE_PATHS: 'UPDATE_PATHS',

	UPDATE_USER_PERMISSIONS: 'UPDATE_USER_PERMISSIONS',

	DISABLE_NAV_ITEM: 'DISABLE_NAV_ITEM',
	ENABLE_NAV_ITEM: 'ENABLE_NAV_ITEM',
	UPDATE_NAV_ITEM: 'UPDATE_NAV_ITEM',

	SET_PERMITTED_STORES: 'SET_PERMITTED_STORES',

	SET_SHOW_INTERNAL_DATA: 'SET_SHOW_INTERNAL_DATA',
};

export default navigationConstants;
