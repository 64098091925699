/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
import moment from 'moment';
import _ from 'lodash';
import { getUnitConvertedQuantity } from '../../../Helpers';

const initialState = {
	vendor: {},
	vendorLocation: {},
	fromStore: {},
	deliverTo: {},
	fromProcess: null,
	toProcess: null,
	store: null,
	selectedProcess: {},
	purchaseOrderId: '',
	date: new Date(),
	expectedDeliveryDate: new Date(
		new Date().setDate(new Date().getDate() + 1)
	),
	paymentTerm: '',
	poItems: [],
	poItemsObject: {},
	deliveryCharges: 0,
	packagingCharges: 0,
	editedFields: [],
	selectedShift: null,
	rejectionReason: '',
	transferOrderType: 'DISPATCH',
	additionalCharges: [
		{
			type: 'Additional Charges',
			taxPercentage: 18,
			price: 0,
		},
	],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VENDOR':
			return {
				...state,
				vendor: action.payload === undefined ? null : action.payload,
			};
		case 'SET_FROM_STORE':
			return {
				...state,
				fromStore: action.payload,
			};
		case 'SET_DELIVER_TO':
			return {
				...state,
				deliverTo: action.payload,
				selectedProcess: {},
			};
		case 'SET_PURCHASE_ORDER':
			return {
				...state,
				purchaseOrderId: action.payload,
			};
		case 'SET_DATE':
			return {
				...state,
				date: action.payload,
			};
		case 'SET_EXPECTED_DELIVERY_DATE':
			return {
				...state,
				expectedDeliveryDate: action.payload,
			};
		case 'SET_PAYMENT_TERM':
			return {
				...state,
				paymentTerm: action.payload,
			};
		case 'SET_PO_ITEMS':
			return {
				...state,
				poItems: action.payload,
			};
		case 'SET_PO_ITEMS_OBJECT':
			return {
				...state,
				poItemsObject: action.payload,
			};
		case 'SET_SHIFT':
			return {
				...state,
				selectedShift: action.payload,
			};
		case 'SET_PACKAGING_CHARGES':
			return {
				...state,
				packagingCharges: action.payload,
			};
		case 'SET_DELIVERY_CHARGES':
			return {
				...state,
				deliveryCharges: action.payload,
			};
		case 'SET_PROCESS':
			return {
				...state,
				selectedProcess: action.payload,
			};
		case 'SET_FROM_PROCESS':
			return {
				...state,
				fromProcess: action.payload,
			};
		case 'SET_TO_PROCESS':
			return {
				...state,
				toProcess: action.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action.payload,
			};
		case 'SET_VENDOR_LOCATION':
			return {
				...state,
				vendorLocation: action.payload,
			};
		case 'SET_TRANSFER_ORDER_TYPE':
			return {
				...state,
				transferOrderType: action.payload,
				selectedShift: null,
			};
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

// const getTotalPrice = (price, tax, quantity, unit) => {
const getTotalPrice = (subTotal, totalTax) => {
	// if (unit === 'gram') {
	// return (
	// ((price * tax) / 100) * (quantity / 1000) +
	// price * (quantity / 1000)
	// );
	// }
	// if (unit === 'oz') {
	// return (
	// ((price * tax) / 100) * (quantity / 16) +
	// price * (quantity / 16)
	// );
	// }
	// return ((price * tax) / 100) * quantity + price * quantity;
	return subTotal + totalTax;
};
const getTotalDiscount = (price, discountPercentage, quantity, unit) => {
	const gramDiscountPrice =
		(price * (quantity / 1000) * discountPercentage) / 100;
	const ozDiscountPrice =
		price * (quantity / 16) * (discountPercentage / 100);
	const countDiscountPrice = (price * quantity * discountPercentage) / 100;
	if (unit === 'gram' || unit === 'g' || unit === 'ml') {
		return gramDiscountPrice;
	}
	if (unit === 'oz') {
		return ozDiscountPrice;
	}
	return countDiscountPrice;
};
const getSubTotal = (price, discountPercentage, quantity, unit) => {
	const gramDiscountPrice =
		price * (quantity / 1000) * (discountPercentage / 100);
	const ozDiscountPrice =
		price * (quantity / 16) * (discountPercentage / 100);
	const countDiscountPrice = price * quantity * (discountPercentage / 100);
	if (unit === 'gram' || unit === 'g' || unit === 'ml') {
		return price * (quantity / 1000) - gramDiscountPrice;
	}
	if (unit === 'oz') {
		return price * (quantity / 16) - ozDiscountPrice;
	}
	if (unit === 'floz') {
		return price * (quantity / 128) - ozDiscountPrice;
	}
	return price * quantity - countDiscountPrice;
};

const getTotalTax = (price, discountPercentage, tax, quantity, unit) => {
	const gramDiscountPrice =
		price * (quantity / 1000) * (discountPercentage / 100);
	const ozDiscountPrice =
		price * (quantity / 16) * (discountPercentage / 100);
	const countDiscountPrice = price * quantity * (discountPercentage / 100);
	if (unit === 'gram' || unit === 'g' || unit === 'ml') {
		return (price * (quantity / 1000) - gramDiscountPrice) * (tax / 100);
	}
	if (unit === 'oz') {
		return (price * (quantity / 16) - ozDiscountPrice) * (tax / 100);
	}
	return (price * quantity - countDiscountPrice) * (tax / 100);
};
const getCapexSerializedItem = (item, result) => {
	let reqOem = item?.oems?.find?.((oem) => oem?.isSelected);
	// Handle case where reqOem is undefined before JSON operations
	reqOem = reqOem ? JSON.parse(JSON.stringify(reqOem)) : null;
	if (reqOem) {
		delete reqOem?.isSelected;
		delete reqOem?.client;
		delete reqOem?.active;
	}

	return {
		productId: item?.productId,
		variantId: result,
		qty: Number(item?.quantity),
		price: Number(item?.selectedVariant?.price),
		description: item?.description ?? '',
		tax: item?.tax,
		discountPercentage: 0.0,
		info: {
			coreSpecifications: item?.coreSpecifications,
			oem: reqOem,
		},
	};
};

const getAssetGroupedSerializedItem = (groupedObject, cartItem) => {
	const serializedItems = [];
	Object.entries(cartItem).forEach(([groupedKey, orderQty]) => {
		const groupedItem = groupedObject[groupedKey];
		const orderItem = {
			qty: orderQty,
			info: {
				refAssetId: groupedItem?.refAsset?.id,
				assetType: {
					id: groupedItem?.assetType?.id,
					name: groupedItem?.assetType?.name,
				},
				category: groupedItem?.category,
				oem: groupedItem?.oem,
				coreSpecs: groupedItem?.specsToBeShownOnGroups?.map((spec) => ({
					id: spec?.id,
					label: spec?.label,
					dataType: spec?.dataType,
					uom: spec?.uom,
					value:
						spec?.isChecked === false ? spec.newValue : spec?.value,
				})),
			},
		};
		serializedItems.push(orderItem);
	});
	return serializedItems;
};
const config = {
	initialState,
	reducer,
	validator: (state, orderType) => {
		let focusIndex = -1;
		const errors = [];
		const intOrderType = Number(orderType);
		// Check if vendor object is empty
		if (intOrderType == 1 && Object.keys(state.vendor)?.length === 0) {
			errors.push('Vendor is mandatory');
		}

		// Check if fromStore object is empty
		if (
			![1, 4, 5, 7].includes(Number(intOrderType)) &&
			Object.keys(state.fromStore).length === 0
		) {
			errors.push('From Store is mandatory');
		}

		// Check if deliverTo object is empty
		if (
			intOrderType != 5 &&
			intOrderType != 8 &&
			Object.keys(state.deliverTo).length === 0
		) {
			errors.push('Deliver To is mandatory');
		}

		// Check if purchaseOrderId is empty
		if (intOrderType != 5 && !state.purchaseOrderId) {
			errors.push('Purchase Order ID is mandatory');
		}

		// Check if paymentTerm is empty
		if (intOrderType != 5 && !state.paymentTerm) {
			errors.push('Payment Term is mandatory');
		}
		// Check if poItems array is empty
		if (intOrderType === 7) {
			if (_.isEmpty(state?.poItemsObject)) {
				errors.push('Asset Items are mandatory');
			}
			return [];
		}
		if (state.poItems.length === 0) {
			errors.push('PO Items are mandatory');
		}

		if (intOrderType === 5) {
			if (!state?.store?.id) {
				errors.push('Store is mandatory');
			}
			if (!state?.fromProcess?.id) {
				errors.push('From process is mandatory');
			}
			if (!state?.toProcess?.id) {
				errors.push('To process is mandatory');
			}
			if (!state?.selectedShift?.id) {
				errors.push('Shift selection is mandatory');
			}
		}

		if (
			intOrderType != 5 &&
			state?.vendor?.id &&
			state.vendor?.vendor_locations?.length &&
			!state?.vendorLocation?.id
		) {
			errors.push('Please select vendor location');
		}
		if (intOrderType == 4) {
			let specInfo = {};
			let oemNotProvided = false;
			const supplierMandatoryItemWithNoValue = state?.poItems?.find(
				(item, index) => {
					// eslint-disable-next-line max-len
					if (item?.oems?.length > 0) {
						const isSelected = item?.oems?.some(
							(thisOem) => thisOem?.isSelected
						);
						if (!isSelected) {
							focusIndex = index;
							oemNotProvided = true;
							return true;
						}
					}
					// eslint-disable-next-line max-len
					const supplierMandatoryCoreSpecification =
						item?.coreSpecifications?.find((coreSpecification) => {
							if (coreSpecification?.rfqMandatory) {
								if (
									coreSpecification?.value === '' ||
									coreSpecification?.value === undefined ||
									coreSpecification?.value === null
								) {
									specInfo = coreSpecification;
									return item;
								}
							}
							return false;
						});
					if (supplierMandatoryCoreSpecification) {
						focusIndex = index;
						return true;
					}
					return false;
				}
			);
			if (supplierMandatoryItemWithNoValue) {
				errors.push(
					`${
						oemNotProvided ? 'OEM' : specInfo?.label
					} Field is mandatory for ${
						supplierMandatoryItemWithNoValue?.productName
					} at index ${focusIndex + 1}`
				);
			}
			return [errors, focusIndex];
		}
		if (intOrderType == 5) {
			state?.poItems?.forEach((item) => {
				const itemMoq = item?.selectedVariant?.pieces?.split(',')?.[0];
				if (intOrderType == 3 && itemMoq) {
					if (
						item?.productInputUnit === 'kg' &&
						Number(item?.quantity) % (Number(itemMoq) / 1000) !== 0
					) {
						errors?.push(
							// eslint-disable-next-line prefer-template
							'Quantity of Item (' +
								item?.productName +
								') should be according to MOQ(' +
								Number(itemMoq) / 1000 +
								') like ' +
								(Number(itemMoq) / 1000) * 1 +
								', ' +
								(Number(itemMoq) / 1000) * 2 +
								';'
						);
					} else if (
						item?.productInputUnit === 'g' &&
						Number(item?.quantity) % Number(itemMoq) !== 0
					) {
						errors?.push(
							// eslint-disable-next-line prefer-template
							'Quantity of Item (' +
								item?.productName +
								') should be according to MOQ(' +
								itemMoq +
								') like ' +
								Number(itemMoq) * 1 +
								', ' +
								Number(itemMoq) * 2 +
								';'
						);
					}
				}
			});
		}

		return [errors, focusIndex];
	},
	deserialize: (state, orderTypeId, parentGroupedAssets = {}) => {
		let type;
		if (orderTypeId === 4 || orderTypeId === 7) {
			type = 'CAPEX';
		} else if (orderTypeId === 5) {
			type = 'TRANSFER';
		}

		const newState = {
			orderType: orderTypeId,
			date: moment(state?.date)?.format('M/D/YYYY') ?? '',
			scheduled:
				moment(state?.expectedDeliveryDate)?.format('M/D/YYYY') ?? '',
			jsonData: {
				paymentTerm: state?.paymentTerm ?? '',
				additionalCharges:
					state?.additionalCharges?.map((ele) => ({
						...ele,
						taxPercentage: Number(ele?.taxPercentage ?? 0),
						price: Number(ele?.price ?? 0),
					})) ?? [],
			},
			store: orderTypeId === 5 ? state?.store?.id : state?.deliverTo?.id,
			toProcess: state?.toProcess?.id ?? null,
			fromProcess: state?.fromProcess?.id ?? null,
			vendor: state?.vendor?.id === undefined ? null : state?.vendor?.id,
			shift: state?.selectedShift?.id ?? null,
			transferOrderType: state?.transferOrderType ?? '',
			startTime: state?.selectedShift?.process?.[0]?.startTime ?? null,
			appOrderType: orderTypeId === 5 ? 'FMS_TRANSFER' : undefined,
			type,
			processType: state?.selectedProcess?.id,
			vendorLocationId:
				state?.vendorLocation?.id === undefined
					? null
					: state?.vendorLocation?.id,
			fromStore:
				orderTypeId === 5 ? state?.store?.id : state?.fromStore?.id,
			purchaseOrderId: `PO-${state?.purchaseOrderId}`,
			deliveryCharges:
				state?.deliveryCharges > 0 ? Number(state?.deliveryCharges) : 0,
			packagingCharges:
				state?.packagingCharges > 0
					? Number(state?.packagingCharges)
					: 0,
		};
		if (orderTypeId === 7) {
			newState.items = getAssetGroupedSerializedItem(
				parentGroupedAssets,
				state?.poItemsObject
			);
		} else {
			newState.items =
				state?.poItems?.map((ele) => {
					const itemQty =
						ele?.productInputUnit !== 'count'
							? Number(
									getUnitConvertedQuantity(
										Number(ele?.quantity),
										ele?.productInputUnit,
										false,
										true
									)
							  )
							: Number(ele?.quantity);
					const displayName = ele?.selectedVariant?.displayName;
					let result;
					if (displayName === 'ASSET') {
						result = -1;
					} else if (displayName === 'SERVICE') {
						result = -2;
					} else {
						result = ele?.selectedVariant?.id ?? null;
					}
					if (
						orderTypeId === 4 &&
						(displayName === 'ASSET' ||
							displayName === 'SERVICE') &&
						ele?.coreSpecifications?.length > 0
					) {
						return getCapexSerializedItem(ele, result);
					}
					return {
						productId: ele?.productId ?? null,
						variantId: result,
						qty: !isNaN(itemQty) ? itemQty : null,
						price: Number(ele?.selectedVariant?.price ?? 0),
						description: ele?.description ?? '',
						tax: ele?.tax ?? 0,
						discountPercentage: !isNaN(
							Number(ele?.discountPercentage)
						)
							? Number(ele?.discountPercentage)
							: 0,
					};
				}) ?? [];
		}

		return newState;
	},
	getTotalPrice,
	getSubTotal,
	getTotalTax,
	getTotalDiscount,
};

export default config;
