import _ from 'lodash';
import moment from 'moment';
import { notificationServices, stateCityServices } from '../../../Services';

function handleTimeFormat(value) {
	const arr = value.split(':');
	const d = new Date(2018, 11, 24, arr[0], arr[1], 30, 0);
	return d;
}
const StoresConfig = {
	initialState: {
		fields: {
			storeName: '',
			clientStoreId: '',
			address: '',
			city: null,
			pincode: null,
			type: null,
			subType: null,
			dispatchLeadDays: null,
			parentStore: null,
			appModules: [],
			support: '',
			orderplacementnumber: '',
			mealScheduling: false,
			subscription: false,
			gstCheck: true,
			GSTno: '',
			managerName: '',
			email: '',
			phoneno: '',
			longitude: 77.185024,
			latitude: 28.6523392,
			roundOclock: true,
			openingTime: null,
			closingTime: null,
			takeaway: false,
			delivery: false,
			distance: 0,
			slabs: [
				{
					deliveryCharges: null,
					deliveryRadius: null,
				},
			],
			selfDelivery: false,
			servicabilityRadius: null,
			serviceablePincodes: [],
			packagingCharge: null,
			minCart: 0,
			maxCart: 1000,
			online: false,
			offline: false,
			QRcode: null,
			bannerList: [],
			tracking: false,
			timezone: false,
			enableSection: {
				storeDesign: false,
				payment: false,
				serviceModes: false,
				storeTiming: false,
				managerDetails: true,
				generalDetails: true,
			},
		},
		errors: {
			storeName: '',
			clientStoreId: '',
			address: '',
			city: '',
			pincode: '',
			type: '',
			subType: '',
			dispatchLeadDays: '',
			parentStore: '',
			appModules: '',
			support: '',
			orderplacementnumber: '',
			mealScheduling: '',
			subscription: '',
			gstCheck: '',
			GSTno: '',
			managerName: '',
			email: '',
			phoneno: '',
			longitude: '',
			latitude: '',
			roundOclock: '',
			openingTime: '',
			closingTime: '',
			takeaway: '',
			delivery: '',
			selfDelivery: '',
			deliveryCharges: '',
			servicabilityRadius: '',
			serviceablePincodes: '',
			packagingCharge: '',
			minCart: '',
			maxCart: '',
			online: '',
			offline: '',
			QRcode: '',
			bannerList: '',
			bannerListMobile: '',
			tracking: '',
			enableSection: {
				storeDesign: '',
				payment: '',
				serviceModes: '',
				storeTiming: '',
				managerDetails: '',
				generalDetails: '',
			},
		},
	},
	mandatoryFields: {
		storeName: true,
		clientStoreId: false,
		address: true,
		city: true,
		type: true,
		subType: true,
		dispatchLeadDays: true,
		parentStore: false,
		appModules: false,
		pincode: true,
		support: false,
		orderplacementnumber: false,
		mealScheduling: false,
		subscription: false,
		gstCheck: false,
		GSTno: false,
		managerName: false,
		email: false,
		phoneno: false,
		longitude: true,
		latitude: true,
		roundOclock: false,
		openingTime: false,
		closingTime: false,
		takeaway: false,
		delivery: false,
		slabs: true,
		selfDelivery: false,
		deliveryCharges: true,
		servicabilityRadius: true,
		serviceablePincodes: true,
		packagingCharge: true,
		minCart: true,
		maxCart: true,
		online: '',
		offline: '',
		QRcode: false,
		bannerList: false,
		bannerListMobile: false,
		tracking: false,
		timezone: false,
		enableSection: {
			storeDesign: false,
			payment: false,
			serviceModes: false,
			storeTiming: false,
			managerDetails: false,
			generalDetails: false,
		},
	},
	serializer: (state, data) => {
		const newSlab = [];
		if (data.deliveryCharges) {
			const slabArr = Object.keys(data.deliveryCharges).sort((a, b) => {
				return parseFloat(a, 10) - parseFloat(b, 10);
			});
			slabArr.map((key) => {
				const newObj = {
					deliveryRadius: key,
					deliveryCharges: data.deliveryCharges[key],
				};
				newSlab.push(newObj);
				return null;
			});
		}
		const result = _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				storeName: data.name,
				clientStoreId: data.clientStoreId,
				address: data.address,
				city: '',
				type: data.type,
				subType: data.subType,
				dispatchLeadDays: data.dispatchedDaysDelta,
				parentStore: data.parentStore,
				distance: data.distanceFromParent,
				appModules: data.appModules,
				pincode: data.pincode,
				support: data.customersupport,
				orderplacementnumber: data.orderplacementnumber,
				mealScheduling: data.mealScheduling,
				subscription: data.mealSubscription,
				gstCheck: true,
				GSTno: data.gstNumber,
				managerName: data.managerName,
				email: data.managerEmail,
				phoneno: data.managerPhone,
				longitude: parseFloat(data.longitude, 10),
				latitude: parseFloat(data.latitude, 10),
				roundOclock: !data.storeStartAt && !data.storeShutsAt,
				openingTime:
					data.storeStartAt !== null
						? handleTimeFormat(data.storeStartAt)
						: '',
				closingTime:
					data.storeShutsAt !== null
						? handleTimeFormat(data.storeShutsAt)
						: '',
				takeaway: data.pickup,
				delivery: data.delivery,
				slabs: newSlab,
				selfDelivery: data.selfDelivery,
				servicabilityRadius: data.deliveryRadius,
				serviceablePincodes: data.seviceablePincodes
					? data.seviceablePincodes.split(',')
					: [],
				packagingCharge: data.packagingCharges,
				minCart: data.minCartValue,
				maxCart: data.maxCartValue,
				online: data.payment_digital,
				offline: data.payment_cod,
				QRcode: data.storeQRImageUrl,
				bannerList: data.bannerImages === null ? [] : data.bannerImages,
				bannerListMobile:
					data.phoneBannerImages === null
						? []
						: data.phoneBannerImages,
				tracking: data.tracking,
				timezone: data?.tz,
				enableSection: {
					storeDesign: data.tabSettings.designDetails,
					payment: data.tabSettings.paymentDetails,
					serviceModes: data.tabSettings.serviceModeDetails,
					storeTiming: data.tabSettings.timmingDetails,
					managerDetails: true,
					generalDetails: data.tabSettings.generalDetails,
				},
			};
			return newState;
		});
		return new Promise((resolve) => {
			stateCityServices.getStateCity().then((res) => {
				const currentStateCity = res.data.find((stateCityObj) => {
					return stateCityObj.cityId === parseInt(data.city, 10);
				});
				result.fields.city = currentStateCity;
				if (
					result.fields.openingTime === null ||
					result.fields.closingTime === null
				) {
					result.fields.roundOclock = true;
				}
				resolve(result);
			});
		});
	},
	deserializer: (state) => {
		const newSlab = state.fields.slabs.map((obj) => {
			if (obj.deliveryRadius) {
				const newObj = {
					[obj.deliveryRadius.toString()]:
						obj.deliveryCharges.toString(),
				};
				return newObj;
			}
			return {};
		});
		const newSlabObj = Object.assign({}, ...newSlab);
		const newState = {
			name: state.fields.storeName,
			clientStoreId: state.fields.clientStoreId,
			address: state.fields.address,
			city: parseInt(state.fields.city.cityId, 10),
			pincode: parseInt(state.fields.pincode, 10),
			type: state.fields.type,
			subType: state.fields.type === 'RETAIL' ? state.fields.subType : null,
			dispatchedDaysDelta: state.fields.dispatchLeadDays,
			parentStore: parseInt(state.fields.parentStore, 10),
			appModules: state?.fields?.appModules,
			customersupport: state.fields.support,
			orderplacementnumber: state.fields.orderplacementnumber,
			mealSubscription: state.fields.subscription,
			mealScheduling: state.fields.mealScheduling,
			gstCheck: true,
			gstNumber: state.fields.GSTno,
			managerName: state.fields.managerName,
			managerEmail: state.fields.email,
			managerPhone: state.fields.phoneno,
			longitude: state.fields.longitude.toString(),
			latitude: state.fields.latitude.toString(),
			distance: parseInt(state.fields.distance, 10),
			storeStartAt: !state.fields.roundOclock
				? moment(state.fields.openingTime).format('HH:mm')
				: null,
			storeShutsAt: !state.fields.roundOclock
				? moment(state.fields.closingTime).format('HH:mm')
				: null,
			pickup:
				state.fields.takeaway === null ? false : state.fields.takeaway,
			delivery:
				state.fields.delivery === null ? false : state.fields.delivery,
			selfDelivery:
				state.fields.selfDelivery === null
					? false
					: state.fields.selfDelivery,
			deliveryRadius: state.fields.servicabilityRadius
				? state.fields.servicabilityRadius.toString()
				: null,
			deliveryCharges: newSlabObj,
			packagingCharges: parseInt(state.fields.packagingCharge, 10),
			minCartValue: parseInt(state.fields.minCart, 10),
			maxCartValue: parseInt(state.fields.maxCart, 10),
			payment_digital:
				state.fields.online === null ? false : state.fields.online,
			payment_cod:
				state.fields.offline === null ? false : state.fields.offline,
			storeQRImageUrl: state.fields.QRcode,
			bannerImages: state.fields.bannerList,
			phoneBannerImages: state.fields.bannerListMobile,
			seviceablePincodes: state.fields.serviceablePincodes.join(','),
			tz: state.fields.timezone,
			tracking:
				state.fields.tracking === null ? false : state.fields.tracking,
			tabSettings: {
				designDetails: state.fields.enableSection.storeDesign,
				generalDetails: state.fields.enableSection.generalDetails,
				managerDetails: true,
				paymentDetails: state.fields.enableSection.payment,
				serviceModeDetails: state.fields.enableSection.serviceModes,
				timmingDetails: state.fields.enableSection.storeTiming,
			},
		};
		return newState;
	},
	validator: (state) => {
		if (!state.fields.city) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select city',
			});
			return false;
		}
		if (!state.fields.type) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select Store Type',
			});
			return false;
		}
		if (state.fields.type === 'RETAIL' && !state.fields.subType) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select Store Sub Type',
			});
			return false;
		}
		if (!state.fields.longitude || !state.fields.latitude) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter valid coordinates',
			});
			return false;
		}
		if (state.fields.longitude.toString().length <= 3) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter valid longitude',
			});
			return false;
		}
		if (state.fields.latitude.toString().length <= 3) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter valid latitude',
			});
			return false;
		}
		// if (!state.fields.parentStore) {
		// notificationServices.generateNotification({
		// type: 'error',
		// message: 'Please select a Parent Store',
		// });
		// return false;
		// }
		if (
			state.fields.pincode.toString().length !== 5 &&
			state.fields.pincode.toString().length !== 6
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter 5-6 digit pin/zip code',
			});
			return false;
		}
		if (
			state.fields.enableSection.payment &&
			!state.fields.online &&
			!state.fields.offline
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select atleast one payment method',
			});
			return false;
		}
		if (state.fields.delivery && state.fields.slabs.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please add atleast 1 delivery slab',
			});
			return false;
		}
		if (state.fields.enableSection.storeDesign) {
			if (state.fields.bannerList.length < 3) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Please upload atleast 3 banner images',
				});
				return false;
			}
		}
		if (
			state.fields.enableSection.storeTiming &&
			!state.fields.roundOclock
		) {
			if (!state.fields.openingTime || !state.fields.closingTime) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Please enter valid store timings',
				});
				return false;
			}
		}
		if (state.fields.delivery && state.fields.slabs.length > 0) {
			const convertedServiceabilityradius =
				typeof state.fields.servicabilityRadius === 'string' &&
				state.fields.servicabilityRadius.includes('.')
					? parseFloat(state.fields.servicabilityRadius, 10)
					: parseInt(state.fields.servicabilityRadius, 10);
			for (let slab = 0; slab < state.fields.slabs.length; slab++) {
				if (!state.fields.slabs[slab].deliveryCharges) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Please enter value in Delivery charges of slab ${
							slab + 1
						}`,
					});
					return false;
				}
				if (!state.fields.slabs[slab].deliveryRadius) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Please enter value in Delivery distance in slab ${
							slab + 1
						}`,
					});
					return false;
				}
				const val2 = state.fields.slabs[slab].deliveryRadius;

				const convertedVal2 = val2.includes('.')
					? parseFloat(val2, 10)
					: parseInt(val2, 10);
				if (slab > 0) {
					const val1 = state.fields.slabs[slab - 1].deliveryRadius;
					const convertedVal1 = val1.includes('.')
						? parseFloat(val1, 10)
						: parseInt(val1, 10);
					if (convertedVal1 > convertedVal2) {
						notificationServices.generateNotification({
							type: 'error',
							message: `Please enter greater Delivery distance than Delivery slab ${slab}`,
						});
						return false;
					}
				}
				if (
					state.fields.slabs.length - 1 === slab &&
					convertedVal2 > convertedServiceabilityradius
				) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Your delivery distance is greater than delivery radius in slab ${
							slab + 1
						}`,
					});
					return false;
				}
				if (
					state.fields.parentStore !== null && parseInt(state.fields.distance, 10) === 0
				) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Distance should be greater than zero',
					});
					return false;
				}
			}
		}

		return true;
	},
};
export default StoresConfig;
