/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const inventoryConfigServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewInventoryConfig({
				req,
			});
		case 'LIST':
			return listInventoryConfig({
				req,
			});
		case 'UPDATE':
			return updateInventoryConfig({
				req,
			});
		// case 'PATCH':
		// return updateInventoryConfig({
		// req,
		// });
		default:
			break;
	}
	return new Promise();
}

function viewInventoryConfig({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.INVENTORY_CONFIG, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	// return fetch(url, requestOptions).then(handleResponse);
	return mockViewInventoryConfig(url, requestOptions);
}

function mockViewInventoryConfig() {
	const mockResponse = {
		message: 'Loaded successfully',
		status: 'Success',
		statusCode: 200,
		data: {
			id: 1,
			name: 'Paneer Roll',
			description: 'A paneer roll',
			brand: 1,
			image: 'https://foodelish.in/wp-content/uploads/2020/06/mughlai_paneer-roll.jpeg',
			productClass: 'product',
			bulkEnabled: false,
			batchEnabled: true,
			IMSEnabled: true,
			variants: [
				{
					name: 'Variant 1',
					id: 1,
					qty: 300,
					threshold: 200,
				},
				{
					name: 'Variant 2',
					id: 2,
					qty: 400,
					threshold: 100,
				},
			],
		},
	};
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(mockResponse);
		}, 2000);
	});
}

function listInventoryConfig({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.GET_PRODUCTS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
	// return mockListInventoryConfig(url, requestOptions);
}

function updateInventoryConfig({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.INVENTORY_CONFIG, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

// function mockListInventoryConfig() {
// 	const mockResponse = {
// 		message: 'Loaded successfully',
// 		status: 'Success',
// 		statusCode: 200,
// 		data: [
// 			{
// 				id: 1,
// 				name: 'Paneer Roll',
// 				description: 'A paneer roll',
// 				brand: 1,
// 				image: 'https://foodelish.in/wp-content/uploads/2020/06/mughlai_paneer-roll.jpeg',
// 				productClass: 'product',
// 				variants: [
// 					{
// 						name: 'Variant 1',
// 						weightCheck: {
// 							applied: true,
// 							minWeight: 100,
// 							maxWeight: 100,
// 						},
// 						tempCheck: {
// 							applied: true,
// 							minTemp: 100,
// 							maxTemp: 100,
// 						},
// 					},
// 					{
// 						name: 'Variant 2',
// 						weightCheck: {
// 							applied: false,
// 							minWeight: 200,
// 							maxWeight: 200,
// 						},
// 						tempCheck: {
// 							applied: true,
// 							minTemp: 100,
// 							maxTemp: 100,
// 						},
// 					},
// 				],
// 			},
// 			{
// 				id: 2,
// 				name: 'Paneer Roll',
// 				description: 'A paneer roll',
// 				brand: 1,
// 				image: 'https://foodelish.in/wp-content/uploads/2020/06/mughlai_paneer-roll.jpeg',
// 				productClass: 'product',
// 				variants: [
// 					{
// 						name: 'Variant 1',
// 						weightCheck: {
// 							applied: true,
// 							minWeight: 100,
// 							maxWeight: 100,
// 						},
// 						tempCheck: {
// 							applied: true,
// 							minTemp: 100,
// 							maxTemp: 100,
// 						},
// 					},
// 					{
// 						name: 'Variant 2',
// 						weightCheck: {
// 							applied: false,
// 							minWeight: 200,
// 							maxWeight: 200,
// 						},
// 						tempCheck: {
// 							applied: true,
// 							minTemp: 100,
// 							maxTemp: 100,
// 						},
// 					},
// 				],
// 			},
// 		],
// 	};
// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 2000);
// 	});
// }

export default inventoryConfigServices;
