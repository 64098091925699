import { useRouteMatch } from 'react-router';
import CookingSettingCreatePage from '../../../Pages/Cooking/Cooking Setting/CookingSettingCreatePage';
// eslint-disable-next-line object-curly-newline

import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const CookingSettingRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='COOKING SETTING'
			path={path}
			listPage={CookingSettingCreatePage}
			createPage={<CookingSettingCreatePage />}
		/>
	);
};

export default CookingSettingRoutes;
