import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { useSearch } from '../../../Helpers';
import ExcelDownload from '../../ExcelDownload/ExcelDownload';
import { BaseModal } from '../BaseModal';
import styles from './StockListModal.module.css';

function StockListModal(props) {
	const { show, data, toggleModal, from, to } = props;
	const { clearSearch } = useSearch();
	const [listData, setListData] = useState({});

	const handleClose = () => {
		clearSearch();
		toggleModal(false);
	};

	const getTableData = () => {
		const items = {};
		data?.data?.forEach((stockItem) => {
			if (items[stockItem?.variantId]) {
				items[stockItem?.variantId].total += 1;
			} else {
				items[stockItem?.variantId] = {
					name: stockItem.skuName,
					total: 1,
					variant: stockItem.variantName,
				};
			}
		});
		setListData(items);
	};

	useEffect(() => {
		getTableData();
	}, [data?.data]);

	const renderDownloadBtn = () => {
		const fileName = `${from?.getDate()}/${from?.getMonth()}/${from?.getFullYear()}_${to?.getDate()}/${to?.getMonth()}/${to?.getFullYear()}_StockHistoryList`;
		const heading = [
			'SKU Name',
			'Total',
		];

		const fileData = Object.keys(listData)?.map((value) => {
			const dataArr = [
				// eslint-disable-next-line prefer-template
				listData[value]?.name + '-' + listData[value]?.variant,
				listData[value]?.total,
			];
			return dataArr;
		});
		const currDate = new Date();
		fileData.push([]);
		fileData.push([]);
		fileData.push([
			'Generated on: ',
			`${currDate?.getDate()}/${currDate?.getMonth()}/${currDate?.getFullYear()} ${data?.time}`,
		]);

		return <ExcelDownload header={heading} fileData={fileData} fileName={fileName} />;
	};

	return (
		<BaseModal
			open={show}
			heading='Stock Data'
			handleClose={handleClose}
			width='25rem'
			renderBtns={renderDownloadBtn}
			className={styles.modal}
		>
			<div className={styles.root}>
				<table>
					<thead>
						<tr>
							<th>SKU Name</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(listData)?.map((item) => {
							return (
								<tr>
									<td>
										{listData[item]?.name}
										{' '}
										{listData[item]?.variant}
									</td>
									<td>{listData[item]?.total}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</BaseModal>
	);
}

const mapStateToProps = (state) => {
	const { show, data } = state.modal.stockListModal;
	const { from, to } = state.analytics.DATE_RANGE_FILTER;
	return {
		show,
		data,
		from,
		to,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleStockListModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockListModal);
