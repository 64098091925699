// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import { useMemo } from 'react';
import DatePickerCustom from '../../../../Components/DatePickerCustom/DatePickerCustom';
// import { timeFilterMap } from '../../../../Constants';
import {
	classes,
	getLast12Months,
	getLastFourYears,
} from '../../../../Helpers';
import styles from './PeriodFilter.module.scss';

const periods = [
	{
		displayName: 'TODAY',
		value: 'TODAY',
		list: [],
		enum: 'day',
	},
	{
		displayName: 'MONTH',
		value: 'THIS_MONTH',
		list: getLast12Months(),
		enum: 'month',
	},
	{
		displayName: 'YEAR',
		value: 'THIS_YEAR',
		list: getLastFourYears(),
		enum: 'year',
	},
];

function PeriodFilter(props) {
	const { filterState, handleSelection, selectedPeriod } = props;

	// eslint-disable-next-line no-unused-vars
	const isFilterActive = (filter) => {
		if (filter === 'PERIOD') {
			const periodFilter = periods?.find((ele) => {
				return ele?.value === selectedPeriod;
			});
			return periodFilter?.displayName;
		}
		return false;
	};

	const handleClick = (periodType, periodOption) => {
		if (periodType === 'TODAY') {
			const date = new Date(periodOption);
			const day = date?.getDate();
			const year = parseInt(moment(date)?.format('YYYY'), 10);
			const month = new Date(date)?.getMonth();
			handleSelection(periodType, {
				day,
				year,
				month,
			});
		} else if (periodType === 'THIS_MONTH') {
			const formattedDate = `01 ${periodOption}`; // Appending '01' for the day
			const year = parseInt(moment(formattedDate)?.format('YYYY'), 10);
			const month = new Date(formattedDate)?.getMonth();
			handleSelection(periodType, {
				year,
				month,
			});
		} else {
			handleSelection(periodType, {
				year: periodOption,
			});
		}
	};

	const isOptionSelected = (option, currEle) => {
		if (option?.enum === 'month') {
			const formattedDate = `01 ${filterState?.selection?.month} ${filterState?.selection?.year}`; // Appending '01' for the day
			const selectedDate = moment(formattedDate)?.format('MMM YY');
			return filterState?.type === option?.enum && selectedDate === currEle;
		}
		if (option?.enum === 'year') {
			return (
				filterState?.type === option?.enum &&
				filterState?.selection?.year === currEle
			);
		}
		return false;
	};

	const selectedPeriodDate = useMemo(() => {
		const selectedFilterState = filterState?.selection;
		const initialDate = new Date()?.getDate();
		const initialMonth = new Date()?.toLocaleString('default', {
			month: 'short',
		}).substring(0, 3);
		const date = new Date(
			`${selectedFilterState?.year} ${
				selectedFilterState?.month ?? initialMonth
			} ${selectedFilterState?.day ?? initialDate}`
		);
		return date;
	}, [filterState]);

	return (
		<div className={classes(styles.root)}>
			{periods?.map((option) => {
				const isActive = filterState?.type === option?.enum && !option?.list?.length > 0;
				return (
					<div
						key={option?.value}
						// onClick={() => {
						// if (option?.list?.length === 0) {
						// handleSelection(option?.value);
						// }
						// }}
						className={classes(
							styles.item,
							isActive
								? styles.active
								: ''
						)}
					>
						{option?.enum === 'day' ? (
							<div className={styles.today}>
								<DatePickerCustom
									style={{
										width: '100%',
									}}
									selectDate={(date) => {
										handleClick(option?.value, date);
									}}
									selectedDate={selectedPeriodDate}
								/>
							</div>
						) : (
							<>
								<p className={styles.heading}>{option?.displayName}</p>
								{option?.list?.length > 0 && (
									<div className={styles['options-list']}>
										{option?.list?.map((ele) => {
											const isSelected = isOptionSelected(option, ele);
											return (
												<div
													key={ele}
													onClick={() => {
														handleClick(option?.value, ele);
													}}
													className={classes(
														styles['list-item'],
														isSelected ? styles.active : ''
													)}
												>
													{ele}
												</div>
											);
										})}
									</div>
								)}
							</>
						)}
					</div>
				);
			})}
		</div>
	);
}

export default PeriodFilter;
