/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { CheckPicker } from 'rsuite';
import { Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Button } from '../ui/button';
import { BaseModal } from './BaseModal';
import { modalActions } from '@/Actions';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import assetServices from '@/Services/AssetServices/asset.services';
import { notificationServices } from '@/Services/Notification';

const AddAssetCategoryModal = (props) => {
	const {
		show,
		toggleModal,
		handleDeleteOem,
		fetchOemList,
		oemList,
		fetchCategoryAsset,
	} = props;
	const [asset, setAsset] = useState(null);
	const [oem, setOem] = useState([]);
	const [showOem, setShowOem] = useState(false);
	const [oemName, setOemName] = useState('');
	const [assetCode, setAssetCode] = useState(null);

	const handleClick = (item, e) => {
		e.stopPropagation();
		handleDeleteOem(item);
	};

	const handleShowOem = () => {
		setShowOem(true);
	};

	const saveOem = async () => {
		try {
			const response = await assetServices.addOemService({
				req: {
					data: {
						name: oemName,
					},
				},
			});
			notificationServices.generateNotification({
				type: 'success',
				message: response.message,
			});
		} catch (errorT) {
		} finally {
			setOemName('');
			setShowOem(false);
			fetchOemList();
		}
	};

	useEffect(() => {
		fetchOemList();
	}, []);

	const renderMenu = (item) => {
		return (
			<div className='flex justify-between items-center'>
				{item.label}
				<Trash2
					className='text-red-500 w-5 h-5 ml-2 cursor-pointer'
					onClick={(e) => handleClick(item, e)}
				/>
			</div>
		);
	};

	const handleClose = () => {
		toggleModal(false);
	};

	const handleSave = async () => {
		if (asset === null) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset name is mandatory',
			});
			return;
		}
		if (assetCode === null) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset Category Code is mandatory',
			});
			return;
		}
		const selectedOems = oem?.map((id) => {
			const selectedItems = oemList?.find((item) => item?.id === id);
			return selectedItems ? {
				id: selectedItems?.id,
				name: selectedItems?.name,
			} : null;
		});
		try {
			const resp = await assetServices?.addAssetCategoryModalService({
				req: {
					data: {
						name: asset,
						oems: selectedOems,
						categoryCode: assetCode,
					},
				},
			});
		} catch (error) {

		}
		setAsset('');
		setOem([]);
		toggleModal(false);
		fetchCategoryAsset();
	};
	const renderBtns = () => {
		return (
			<div className='w-full flex justify-center gap-2'>
				<Button className='flex-1' variant='secondary' onClick={handleClose}>Cancel</Button>
				<Button className='flex-1 primary-btn' onClick={handleSave}>Save</Button>
			</div>
		);
	};
	return (
		<BaseModal
			heading='Add Asset Category'
			open={show}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='40rem'
			height='27rem'
		>
			<div className='flex flex-col gap-4 p-2 '>
				<div className='flex justify-start items-center  text-left'>
					<Label className='min-w-[160px] text-red-500'>Asset Category*</Label>
					<Input type='text' className='w-full' value={asset} onChange={(e) => setAsset(e.target.value)} />
				</div>
				<div className='flex justify-start items-center  text-left'>
					<Label className='min-w-[160px] text-red-500'>Asset Category Code*</Label>
					<Input type='text' className='w-full' value={assetCode} onChange={(e) => setAssetCode(e.target.value)} />
				</div>
				<div className='flex justify-start items-center  text-left'>
					<Label className='min-w-[160px]'>Allowed OEM</Label>
					<CheckPicker
						className='w-full'
						value={oem}
						onChange={(value) => setOem(value)}
						data={oemList.map((item) => ({
							label: item?.name, value: item?.id,
						}))}
						menuStyle={{
							zIndex: 1300,
						}}
						renderMenuItem={(label, item) => renderMenu(item)}
					/>
				</div>
				{showOem
					? (
						<>
							<div className='flex justify-start text-center gap-2'>
								<Input type='text' placeholder='Enter OEM' onChange={(e) => setOemName(e.target.value)} />
								<Button variant='secondary' onClick={saveOem}>Save</Button>
							</div>
						</>
					)
					: (
						<span className='text-blue-600 w-24 cursor-pointer text-left ' onClick={handleShowOem}>Add OEM...</span>
					)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addAssetCategoryModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddAssetCategoryModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssetCategoryModal);
