import { brandConstants } from '../Constants';

const initialState = {
	brands: {},
};

function brand(state = initialState, action) {
	switch (action.type) {
		case brandConstants.FETCH_BRANDS_REQUEST:
			return {
				...state,
			};
		case brandConstants.FETCH_BRANDS_SUCCESS:
			return {
				...state,
				brands: action.payload,
			};
		case brandConstants.FETCH_BRANDS_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default brand;
