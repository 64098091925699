import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const usersServices = {
	gateway,
};
function gateway({ req, method }) {
	switch (method) {
		case 'LIST':
			return listStore({
				req,
			});

		default:
			break;
	}
	return new Promise();
}

function listStore({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.USERS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

export default usersServices;
