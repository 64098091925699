import { endpoints } from '../Constants';
import { authHeader, handleResponse } from '../Helpers';

const labelGeneratorServices = {
	createLabel,
	deleteLabel,
	getLabels,
	printLabel,
};

function createLabel(label) {
	const url = endpoints.LABEL_GENERATOR_CREATE_LABEL;

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify(label),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function deleteLabel(id) {
	const url = `${endpoints.LABEL_GENERATOR_DELETE_LABEL}/${id}`;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: id,
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function getLabels() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(endpoints.LABEL_GENERATOR_GET_LABELS, requestOptions)
		.then(handleResponse);
}

function printLabel(data) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify(data),
	};
	return fetch(endpoints.LABEL_GENERATOR_Print_Label, requestOptions)
		.then(handleResponse);
}

export default labelGeneratorServices;
