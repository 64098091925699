import _ from 'lodash';

const ProductSubConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			subCategoryImage: '',
			subCategoryCode: '',
			selectedCategory: {},
		},
		errors: {
			name: '',
			description: '',
			subCategoryImage: '',
			subCategoryCode: '',
			selectedCategory: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: false,
		subCategoryImage: true,
		subCategoryCode: true,
		selectedCategory: true,
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.subCategoryName,
				description: data.subCategoryDescription,
				subCategoryCode: data.subCategoryCode,
				subCategoryImage: data.subCategoryImage,
				selectedCategory: data?.selectedCategory,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = {
			name: state.fields.name,
			description: state.fields.description,
			image: state.fields.brand,
			code: state.fields.subCategoryCode,
			categoryId: state?.fields?.selectedCategory?.id,
		};
		return newState;
	},
};
export default ProductSubConfig;
