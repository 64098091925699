/* eslint-disable prefer-template */
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	Button, IconButton, TextField
} from '@mui/material';
// import _ from 'lodash';
import { Add, ModeEdit } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './MyLicensesPage.module.scss';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import licenseServices from '../../../Services/license-services';
import { classes } from '../../../Helpers';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import AddNewLicenseModal from '../../../Components/Modals/AddNewLicenseModal/AddNewLicenseModal';

const TABLE_COLUMNS = [
	{
		title: 'License No',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Plan',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'License type',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Stores',
	// config: {
	// type: 'DATA',
	// },
	// },
];

const SPECIAL_COLUMNS = [
	{
		title: 'Start Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'End Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Grace Days',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Process Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Machine Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Machine at',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Outlets',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'License No': 'id',
	// 'Machine Id': 'machineId',
	// 'Machine at': 'machineAt',
	// 'Process Type': 'processType',
	// 'Start Date': 'startDate',
	'Store name': 'storeName',
	Status: 'status',
	'License type': 'licenseType',
	Plan: 'plan',
	// Stores: 'stores',
};

function MyLicensesPage(props) {
	const { userPermissions, createPath } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [licensesList, setLicensesList] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [isEdit, setIsEdit] = useState({});
	const history = useHistory();

	const fetchLicenses = async () => {
		try {
			setIsLoading(true);
			const response = await licenseServices?.fetchLicenseList();
			if (response?.statusCode === 200) {
				setLicensesList(response?.data?.map((ele) => {
					//  deposit: `₹${ele?.deposit}`,
					//  cost: `₹${ele?.cost}`,
					//  Cannot read property 'value' of null Occurred while linting
					//  Getting this error when using template string,
					//  couldn't find a solution to fix it,
					//  and I have tried all the possible solution I could find
					return {
						...ele,
						// eslint-disable-next-line prefer-template
						deposit: '₹' + ele?.deposit,
						// eslint-disable-next-line prefer-template
						cost: '₹' + ele?.cost,
						startDate: moment(ele?.startDate)?.format('YYYY-MM-DD'),
						endDate: moment(ele?.endDate)?.format('YYYY-MM-DD'),
					};
				}));
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? 'Some error occurred',
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchLicenses();
	}, []);

	const handleEditClick = (varId) => {
		setIsEdit((prev) => {
			const temp = {
				...prev,
			};
			if (temp[varId]) {
				temp[varId] = false;
			} else {
				temp[varId] = true;
			}
			return temp;
		});
	};

	const handleRemoveLicense = async (license) => {
		const req = {
			data: {
				licenseId: license?.id,
			},
		};
		try {
			const response = await licenseServices?.removeLicense(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
			fetchLicenses();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const SPECIAL_ITEMS = {
		'Start Date': (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			const date = new Date(currItem?.startDate);
			if (isEdit[id]) {
				return (
					<div className={styles['edit-container']}>
						<DatePickerCustom
							selectedDate={date}
							cleanable={false}
							selectDate={(poDate) => {
								if (poDate) {
									const newDate =
										moment(poDate).format('YYYY-MM-DD');
									setLicensesList((prev) => {
										return prev?.map((ele) => {
											if (ele?.id === id) {
												return {
													...ele,
													startDate: newDate,
												};
											}
											return ele;
										});
									});
								}
							}}
							style={{
								width: 125,
							}}
							oneTap
						/>
					</div>
				);
			}
			return (
				<div
					style={{
						width: 125,
					}}
					className={styles['edit-container']}
				>
					<p>{currItem?.startDate ?? '-'}</p>
				</div>
			);
		},
		'End Date': (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			const date = new Date(currItem?.endDate);
			if (isEdit[id]) {
				return (
					<div className={styles['edit-container']}>
						<DatePickerCustom
							selectedDate={date}
							cleanable={false}
							selectDate={(poDate) => {
								if (poDate) {
									const newDate =
										moment(poDate).format('YYYY-MM-DD');
									setLicensesList((prev) => {
										return prev?.map((ele) => {
											if (ele?.id === id) {
												return {
													...ele,
													endDate: newDate,
												};
											}
											return ele;
										});
									});
								}
							}}
							style={{
								width: 125,
							}}
							size='sm'
							oneTap
							shouldDisableDate={(rDate) => {
								const startDate = new Date(currItem?.startDate);
								return rDate <= startDate;
							}}
						/>
					</div>
				);
			}
			return (
				<div
					style={{
						width: 125,
					}}
					className={styles['edit-container']}
				>
					<p>{currItem?.endDate ?? '-'}</p>
					{userPermissions?.INTERNAL && (
						<IconButton
							onClick={() => {
								handleEditClick(id);
							}}
							className={styles['edit-btn']}
						>
							<ModeEdit />
						</IconButton>
					)}
				</div>
			);
		},
		'Grace Days': (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			return currItem?.graceDays;
		},
		'Process Type': (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			if (currItem?.machineAt?.length === 0) return '-';
			return (
				<div
					style={{
						width: '100px',
					}}
				>
					{currItem?.machineAt?.map((ele) => {
						return <p>{ele?.processType ?? '-'}</p>;
					})}
				</div>
			);
		},
		'Machine Name': (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			if (currItem?.machineAt?.length === 0) return '-';
			return (
				<div>
					{currItem?.machineAt?.map((ele) => {
						return (
							<p>
								{ele?.machineName ?? '-'}
								{ele?.machineId
									? '(' + ele?.machineId + ')'
									: ''}
							</p>
						);
					})}
				</div>
			);
		},
		'Machine at': (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			if (currItem?.machineAt?.length === 0) return '-';
			return (
				<div>
					{currItem?.machineAt?.map((ele) => {
						return <p>{ele?.machineStoreName ?? '-'}</p>;
					})}
				</div>
			);
		},
		Outlets: (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			if (currItem?.stores?.length === 0) return '-';
			return (
				<div
					style={{
						width: '200px',
						textAlign: 'left',
					}}
				>
					{currItem?.stores?.map((ele) => {
						return <div>{ele?.name ?? '-'}</div>;
					})}
				</div>
			);
		},
		Actions: (id) => {
			const currItem = licensesList?.find((ele) => {
				return ele?.id === id;
			});
			return userPermissions?.INTERNAL ? (
				<div className={styles.btns}>
					<Button
						onClick={() => {
							handleRemoveLicense(currItem);
						}}
						color='error'
						variant='outlined'
						fullWidth
					>
						Remove
					</Button>
					<Button
						onClick={() => {
							history?.push(
								createPath + '?isEdit=true&licenseId=' + id
							);
						}}
						color='primary'
						variant='outlined'
						fullWidth
					>
						Edit
					</Button>
				</div>
			) : '-';
		},
	};

	// eslint-disable-next-line func-names
	const rowColor = function (status) {
		switch (status) {
			case 'ACTIVE':
				return styles.active;
			case 'EXPIRED':
				return styles.expired;
			default:
				return null;
		}
	};

	const handleSearch = (e) => {
		setSearchQuery(e.target.value);
	};

	const LICENSE_DATA = useMemo(() => {
		return licensesList?.filter((ele) => {
			if (!ele?.machineAt?.length > 0) return true;
			return ele?.machineAt?.find((macAt) => {
				return macAt?.machineStoreName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
			});
		});
	}, [licensesList, searchQuery]);

	const handleSave = async () => {
		const req = {
			data: {
				licensesList,
			},
		};
		try {
			const response = await licenseServices?.editLicenses(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				setIsEdit({});
				fetchLicenses();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return (
		<div className={styles.root}>
			<AddNewLicenseModal />
			<BackdropLoader showProgress isLoading={isLoading} />
			<div className={styles?.header}>
				<TextField
					name='store'
					onChange={(e) => {
						handleSearch(e);
					}}
					value={searchQuery}
					label='Search Machine at'
				/>
				<div className={styles['btns-container']}>
					{userPermissions?.INTERNAL && (
						<Button
							onClick={() => {
								history.push(createPath);
							}}
							variant='contained'
							color='primary'
						>
							<Add />
							Add New License
						</Button>
					)}
					{Object?.keys(isEdit)?.length > 0 && (
						<Button
							onClick={handleSave}
							variant='contained'
							color='primary'
						>
							Save
						</Button>
					)}
				</div>
			</div>
			<div className={styles.list}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					showTotal
					data={LICENSE_DATA?.map((ele) => {
						return {
							...ele,
							rowStyles: classes(
								styles.listItem,
								rowColor(ele?.status)
							),
						};
					})}
					actions={[...SPECIAL_COLUMNS]}
					special={SPECIAL_ITEMS}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	toggleAddLicenseModal: modalActions.toggleAddLicenseModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLicensesPage);
