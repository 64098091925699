// import { Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import styles from './ProductTimeSlots.module.scss';
import AddProductTimeSlot from './AddProductTimeSlot/AddProductTimeSlot';
import { weekDays } from '../../../../Helpers';

const TimeSlots = (props) => {
	const {
		slots, handleAddNewSlot, dataAttrs, children, days, dates,
	} = props;

	return (
		<div className={styles.root}>
			{children}
			<div className={`${styles['time-display']}`}>
				<div className={`${styles['add-slot']}`}>
					<AddProductTimeSlot
						handleClick={(e) => {
							// eslint-disable-next-line no-unused-expressions
							handleAddNewSlot &&
								handleAddNewSlot(e, days, dates, slots);
						}}
						dataAttrs={dataAttrs}
					/>
				</div>
				{dates && dates?.length > 0 && (
					<Tooltip
						title={dates?.map((date, idx) => {
							return (
								<span>
									{date}
									{idx < dates?.length - 1 ? ', ' : ''}
								</span>
							);
						})}
					>
						<div className={styles['dates-wrapper']}>
							Dates:
							{dates?.map((date, idx) => {
								return (
									idx < 4 && (
										<div>
											{date}
											{idx < dates?.length - 1
												? ', '
												: ''}
										</div>
									)
								);
							})}
							{dates?.length > 4 && '...'}
						</div>
					</Tooltip>
				)}
				{days && days?.length > 0 && (
					<Tooltip
						title={weekDays
							.filter((day) => {
								return days?.includes(day.id);
							})
							.map((day, idx) => {
								return (
									<span className={styles.days}>
										{day.key}
										{idx < days?.length - 1 ? ', ' : ''}
									</span>
								);
							})}
					>
						<div className={styles['days-wrapper']}>
							{/* {days} */}
							Days:
							{weekDays
								.filter((day) => {
									return days?.includes(day.id);
								})
								.map((day, idx) => {
									return (
										idx < 3 && (
											<div className={styles.days}>
												{day.key}
											</div>
										)
									);
								})}
							{days?.length > 3 && '...'}
						</div>
					</Tooltip>
				)}
				{slots.map((slot) => {
					const { startTime, endTime } = slot;
					return (
						<div className={`${styles['time-container']}`}>
							{/* {!isDisabled && (
								<Tooltip
									title='Delete Time Slot'
									placement='right-end'
								>
									<Cancel
										className={`${styles['delete-icon']}`}
										onClick={handleDelete}
										data-confirm={idx}
										{...dataAttrs}
									/>
								</Tooltip>
							)} */}
							<p className={styles.heading}>
								{`${startTime} to ${endTime}`}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

TimeSlots.defaultProps = {
	limit: 3,
	slots: [],
	isDisabled: false,
};
export default TimeSlots;
