import { retagConstants } from '../Constants';

const initialState = {
	retagReasons: [],
};

function retag(state = initialState, action) {
	switch (action.type) {
		case retagConstants.FETCH_RETAG_REASONS_SUCCESS:
		case retagConstants.ADD_RETAG_REASON: {
			return {
				...state,
				retagReasons: action.payload,
			};
		}
		default:
			return state;
	}
}

export default retag;
