import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { useHistory, useParams } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
import { DraggableHTabs } from '../../../Components/Draggable';
import { FormSectionHeader } from '../../../Components/Forms';
import { ComboVariant, GeneralDetails } from '../../../Components/Forms/Combos';
import { CreatePageHeader } from '../../../Components/Headers';
import ProductVariantsModal from '../../../Components/Modals/ProductVariantsModal/ProductVariantsModal';
import { modalTypes, urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { inputHelper, isEmpty } from '../../../Helpers';
import combosConfig from './combos.config';

const generateDefaultOption = (type, brand, category) => {
	switch (type) {
		case 'brand':
			if (!brand || brand.length === 0) {
				return (
					<option value='' selected disabled hidden>
						Select a Brand
					</option>
				);
			}
			break;
		case 'category':
			if (brand) {
				if (!category || category.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a Category
						</option>
					);
				}
			} else {
				return (
					<option value={null} selected disabled hidden>
						Select a Brand First
					</option>
				);
			}
			break;
		default:
			break;
	}
	return <></>;
};

const ComboCreatePage = (props) => {
	const [state, setState] = useState({
		...combosConfig.initialState,
	});
	const [activeVariant, setActiveVariant] = useState(
		state.fields.variants.order.length
	);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const { fields, errors } = state;

	const {
		PRODUCT_VARIANTS,
		toggleProductVariants,
		isEdit,
		data,
		fetchCombo,
		saveCombo,
		breadcrumbs,
	} = props;

	const history = useHistory();
	const { id } = useParams();

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setState(combosConfig.serializer(state, data));
			} else {
				fetchCombo({
					entity: 'COMBOS',
					req: {
						params: {
							combo_id: id,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(combosConfig.serializer(state, data));
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'comboName':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'veg':
				newState[fieldName] = fieldValue === '1';
				break;
			default:
				break;
		}

		switch (dataset.type) {
			case 'variant':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.variants.items[${dataset.id}][${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'group':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.variants.items[${activeVariant}].groups.items[${dataset.id}][${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'choice':
				if (fieldName === 'default') {
					const clonedState = _.cloneDeep(state);
					clonedState.fields.variants.items[
						activeVariant
					].groups.items[dataset.parent].choices.forEach(
						(choice, idx) => {
							if (parseInt(dataset.id, 10) === idx) {
								// eslint-disable-next-line no-param-reassign
								choice[fieldName] = fieldValue;
							} else {
								// eslint-disable-next-line no-param-reassign
								choice[fieldName] = false;
							}
						}
					);
					setState(clonedState);
				} else {
					setState(
						_.update(
							_.cloneDeep(state),
							`fields.variants.items[${activeVariant}].groups.items[${dataset.parent}].choices[${dataset.id}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						)
					);
				}
				break;
			default:
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue,
						...newState,
					},
					...errors,
				});
		}
	};

	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};

	const handleVariantReOrdering = (dragIndex, hoverIndex) => {
		const newState = _.update(
			_.cloneDeep(state),
			'fields.variants.order',
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};

	const addNewVariant = () => {
		let newLen = activeVariant;
		let newState = _.update(
			_.cloneDeep(state),
			'fields.variants.order',
			(arr) => {
				const len = Math.max(...arr) + 1;
				arr.push(len);
				newLen = len;
				return arr;
			}
		);
		newState = _.update(newState, 'fields.variants.items', (obj) => {
			const newObj = {
				...obj,
			};
			newObj[newLen] = _.cloneDeep(
				combosConfig.initialState.fields.variants.items[1]
			);
			return newObj;
		});

		setState(newState);
		setActiveVariant(newLen);
	};

	const addNewGroup = () => {
		let newLen = 0;
		let newState = _.update(
			_.cloneDeep(state),
			`fields.variants.items[${activeVariant}].groups.order`,
			(arr) => {
				const len = Math.max(...arr) + 1;
				arr.push(len);
				newLen = len;
				return arr;
			}
		);
		newState = _.update(
			newState,
			`fields.variants.items[${activeVariant}].groups.items`,
			(obj) => {
				const newObj = {
					...obj,
				};
				newObj[newLen] = _.cloneDeep(
					combosConfig.initialState.fields.variants.items[1].groups
						.items[1]
				);
				return newObj;
			}
		);
		setState(newState);
	};

	const addNewChoice = (choice) => {
		// take the default object of choice/product
		const choiceObj = choice;
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.variants.items[${activeVariant}].groups.items[${PRODUCT_VARIANTS.data.groupId}].choices`,
				(arr) => {
					// if the group has no choices, make it default
					if (arr.length === 0) {
						choiceObj.default = true;
					}
					// else set default to false; already default in the object provided
					// push it to the array
					arr.push(choiceObj);
					return arr;
				}
			)
		);
		toggleProductVariants(false);
	};

	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		switch (dataset.type) {
			case 'variant':
				setState(
					_.update(
						_.cloneDeep(state),
						'fields.variants',
						(variants) => {
							const newState = variants;
							delete newState.items[activeVariant];
							newState.order = variants.order.filter(
								(variant) => {
									return variant !== activeVariant;
								}
							);
							if (newState.order.length === 0) {
								newState.items['1'] = _.cloneDeep(
									combosConfig.initialState.fields.variants
										.items['1']
								);
								newState.order.push(1);
							}
							setActiveVariant(newState.order[0]);
							return newState;
						}
					)
				);
				break;
			case 'group':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.variants.items[${activeVariant}].groups`,
						(groups) => {
							const newState = groups;
							delete newState.items[dataset.id];
							newState.order = groups.order.filter((group) => {
								return group !== parseInt(dataset.id, 10);
							});
							if (newState.order.length === 0) {
								newState.items['1'] = _.cloneDeep(
									combosConfig.initialState.fields.variants
										.items['1'].groups.items['1']
								);
								newState.order.push(1);
							}
							return newState;
						}
					)
				);

				break;
			case 'choice':
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.variants.items[${activeVariant}].groups.items[${dataset.parent}]choices`,
						(choices) => {
							const newState = choices;
							newState.splice(dataset.id, 1);
							return newState;
						}
					)
				);
				break;
			default:
				break;
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		const isValid = combosConfig.validator(state);
		if (isValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			combosConfig.deserializer(state).then((req) => {
				if (isEdit) {
					req.params = {
						combo_id: id,
					};
					saveCombo({
						entity: 'COMBOS',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.COMBOS}`,
					});
				} else {
					saveCombo({
						entity: 'COMBOS',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.COMBOS}`,
					});
				}
			});
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.COMBOS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.COMBOS}`);
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Combo' : 'Create Combo'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `View ${state.fields.name}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				<ProductVariantsModal
					handleConfirm={addNewChoice}
					groups={fields.variants.items[activeVariant].groups.items}
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={combosConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleImageInput={handleImageInput}
						generateDefaultOption={generateDefaultOption}
					/>
				</FormSectionHeader>
				{/* combo details */}
				<FormSectionHeader sectionName='Combo Details'>
					<DraggableHTabs
						handleReorder={handleVariantReOrdering}
						items={fields.variants.items}
						order={fields.variants.order}
						handleActiveTab={setActiveVariant}
						handleAddTab={addNewVariant}
						activeTab={activeVariant}
						limit={5}
						text='variant'
					/>
					<ComboVariant
						handleInput={handleInput}
						handleDelete={handleDelete}
						variantDetails={fields.variants.items[activeVariant]}
						handleAddNewGroup={addNewGroup}
						activeVariant={activeVariant}
						mandatoryFields={combosConfig.mandatoryFields}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { PRODUCT_VARIANTS } = state.modal;
	const data = state.form.COMBOS.data.VIEW;
	return {
		PRODUCT_VARIANTS,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleProductVariants: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(
					modalTypes.PRODUCT_VARIANTS,
					show,
					data
				)
			);
		},
		fetchCombo: (...args) => {
			dispatch(formActions.gateway(...args));
		},
		saveCombo: (...args) => {
			dispatch(formActions.gateway(...args));
		},
	};
};

ComboCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboCreatePage);
