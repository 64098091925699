import { notificationServices } from '../../../Services';
import { STAT_COLORS } from '../../../Styles/colors';

const config = {
	validator: (annotations) => {
		for (
			let annotationIndex = 0;
			annotationIndex < annotations.length;
			annotationIndex++
		) {
			const annotation = annotations[annotationIndex];
			if (annotation.class == null) {
				notificationServices.generateNotification({
					type: 'error',
					message:
						'Please assign a label to every box before submitting!',
				});
				return false;
			}
		}
		return true;
	},
	serializer: ({ annotations, imageDimensions, imageContainerRect }) => {
		if (
			annotations?.length > 0 &&
			imageContainerRect != null &&
			imageDimensions?.width != null &&
			imageDimensions?.height != null
		) {
			const scaleX = imageDimensions.width / imageContainerRect.width;
			const scaleY = imageDimensions.height / imageContainerRect.height;
			return (
				annotations?.map((annotation, idx) => {
					const productName = annotation.label;
					const x = (annotation.x - annotation.width / 2) / scaleX;
					const y = (annotation.y - annotation.height / 2) / scaleY;
					return {
						x,
						y,
						width: annotation.width / scaleX,
						height: annotation.height / scaleY,
						key: idx + 1,
						stroke: STAT_COLORS[idx],
						id: annotation?.id ?? null,
						labelId: annotation?.labelId ?? null,
						label: productName,
						class: productName,
						ai: annotation?.ai ?? false,
						creatorName: annotation?.createrName,
					};
				}) ?? []
			);
		}
		return [];
	},
	deserializer: ({
		annotations,
		imageDimensionsOg,
		imageDimensionsNew,
		mode,
		qcId,
		type,
	}) => {
		const newAnnotations = annotations.map((annotation) => {
			const centerX = annotation.width / 2 + annotation.x;
			const centerY = annotation.height / 2 + annotation.y;
			const scaleX = imageDimensionsOg.width / imageDimensionsNew.width;
			const scaleY = imageDimensionsOg.height / imageDimensionsNew.height;
			return {
				x: Math.abs(centerX * scaleX),
				y: Math.abs(centerY * scaleY),
				width: Math.abs(annotation.width * scaleX),
				height: Math.abs(annotation.height * scaleY),
				id: annotation.id,
				labelId: annotation.labelId,
				label: annotation.label,
			};
		});

		const req = {
			params: {},
			data: {},
		};

		req.params = {
			qcId,
		};
		if (mode === 'FEATURES') {
			if (type && type?.includes('IDENTITY')) {
				req.data.identity = newAnnotations;
			} else {
				req.data.features = newAnnotations;
			}
		} else if (mode === 'IDENTIFICATION') {
			req.data.identification = newAnnotations;
		} else if (mode === 'CONTAINER') {
			req.data.container = newAnnotations;
		}

		return req;
	},
	stateSerializer: (data) => {
		return {
			BrandImage: data.BrandImage,
			FoodImage: data.FoodImage,
			IsTempInRange: data.IsTempInRange,
			IsWeightInRange: data.IsWeightInRange,
			MaxTemp: data.MaxTemp,
			MaxWeight: data.MaxWeight,
			MinTemp: data.MinTemp,
			MinWeight: data.MinWeight,
			OrderId: data.OrderId,
			ProductImage: data.ProductImage,
			ProductName: data.ProductName,
			QCTime: data.QCTime,
			Result: data.Result,
			Temp: data.Temp,
			TempCheckRequired: data.TempCheckRequired,
			Weight: data.Weight,
			WeightCheckRequired: data.WeightCheckRequired,
			foodLabel: data.foodLabel,
			isTempMax: data.isTempMax,
			isVeg: data.isVeg,
			itemId: data.itemId,
			otherTemp: data.otherTemp,
			brandId: data.brandId,
			productId: data.productId,
			id: data.id,
			validation: data.validation,
			identification: data.identification,
			type: data?.type,
			container: data?.container,
		};
	},
};

export default config;
