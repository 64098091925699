import {
	useEffect, useState, useMemo, useCallback
} from 'react';
import { connect } from 'react-redux';
import {
	MenuItem, TextField, Tooltip
} from '@mui/material';
import moment from 'moment';
import SelectPicker from 'rsuite/SelectPicker';
import { Settings } from '@mui/icons-material';
import FlowChart from './FlowChart/FlowChart';
import styles from './ProcessFlowPage.module.scss';
import { classes } from '../../Helpers';
import { ProcessModal } from '../../Components/Modals/ProcessModal';
import { formActions, modalActions } from '../../Actions';
import processFlowServices from '../../Services/Analytics Services/process-flow.services';
import { notificationServices } from '../../Services';
import DatePickerCustom from '../../Components/DatePickerCustom/DatePickerCustom';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { AlertModal, ProductionPlanModal } from '../../Components/Modals';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import BackdropLoader from '../../Components/Loaders/BackdropLoader';
import drawerActions from '../../Actions/drawer.actions';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ProcessSettingsDrawer from '@/Components/Drawers/ProcessSettingsDrawer/ProcessSettingsDrawer';
import assetServices from '@/Services/AssetServices/asset.services';
import { ImageModal } from '@/Components/Modals/ImageModal';
import EditNodeModal from '@/Components/Modals/EditNodeModal/EditNodeModal';
import { Button } from '@/Components/ui/button';
// import NodeTypes from './NodeTypes/NodeTypes';

function ProcessFlowPage(props) {
	const {
		userPermissions,
		toggleProcessModal,
		// toggleProductionPlanModal,
		stores,
		fetchStoresList,
		togglePOItemsModal,
		toggleProcessSetting,
		toggleImageModal,
		toggleEditNodeModal,
		toggleAlert,
	} = props;
	const [loading, setLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [productionPlanLoading, setProductionPlanLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [showCheckBox, setShowCheckBox] = useState(false);
	const [checkboxStates, setCheckboxStates] = useState({});

	const [availableProductionPlans, setAvailableProductionPlans] = useState(
		[]
	);
	const [selectedStore, setSelectedStore] = useState(null);
	const [state, setState] = useState({
		selectedDate: new Date(),
		selectedPlan: -1,
		selectedTimeType: 'WORK_ORDER',
	});
	const [initialState, setInitialState] = useState({
		nodes: [],
		edges: [],
	});
	const [nodesState, setNodesState] = useState({
		nodes: [],
		edges: [],
	});
	const [immovableAssetsList, setImmovableAssetsList] = useState([]);

	const isInternal = userPermissions?.INTERNAL;

	const fetchImmovableAssets = async () => {
		setLoading(true);
		const data = {
			store: selectedStore,
		};
		try {
			const response = await assetServices.storeAssetInfo({
				data,
			});
			if (response?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.responseMessage,
				});
				setImmovableAssetsList(response?.data);
			}
		} catch {
			// eslint-disable-next-line no-console
			console.log('error');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (selectedStore) {
			fetchImmovableAssets();
		}
	}, [selectedStore]);

	useEffect(() => {
		setIsEdit(true);
	}, [isInternal]);

	const fetchNodes = async () => {
		setLoading(true);
		try {
			const response = await processFlowServices?.fetchNodes({
				params: {
					storeId: selectedStore,
				},
			});
			if (response?.statusCode === 200) {
				const { nodes, edges } = response?.data ?? {};
				const stats = await fetchAnalytics();
				setInitialState({
					nodes: nodes?.map((ele) => {
						return {
							...ele,
							id: ele?.id?.toString(),
							type: 'customNode',
							data: {
								...ele?.data,
								stats: stats?.nodesStat[ele?.id?.toString()]?.stats ?? {},
								pending: stats?.nodesStat[ele?.id?.toString()]?.pending,
								completed:
									stats?.nodesStat[ele?.id?.toString()]?.completed,
								orderId:
									stats?.nodesStat[ele?.id?.toString()]?.orderId ?? null,
							},
						};
					}),
					edges: edges?.map((ele) => {
						return {
							...ele,
							data: [
								...(stats?.edgesStat[ele?.id] ?? []),
							],
						};
					}),
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (selectedStore) {
			fetchNodes();
		}
	}, [
		selectedStore,
		state?.selectedPlan,
		state?.selectedDate,
		state?.selectedTimeType,
	]);

	useEffect(() => {
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
					type: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const formattedDate = useMemo(() => {
		return moment(state?.selectedDate).format('YYYY-MM-DD');
	}, [state?.selectedDate]);

	async function fetchAnalytics() {
		const nodesStat = {};
		const edgesStat = {};
		try {
			const response = await processFlowServices.fetchAnalytics({
				data: {
					store: selectedStore,
					period: {
						type: state?.selectedTimeType,
						startDate: formattedDate,
						endDate: formattedDate,
					},
					plan:
						state?.selectedPlan !== -1 ? state?.selectedPlan : null,
				},
			});
			if (response?.responseCode === 'SS-001') {
				response?.data?.nodes?.forEach((ele) => {
					if (!nodesStat[ele.id]) {
						nodesStat[ele.id] = {
							stats: ele?.stats,
							orderId: ele?.poSummary,
							pending: ele?.pending,
							completed: ele?.completed,
						};
					}
				});
				response?.data?.edges?.forEach((ele) => {
					if (!edgesStat[ele.id]) {
						edgesStat[ele.id] = ele?.stats;
					}
				});
				if (response?.data?.productionPlans !== null) {
					setAvailableProductionPlans(
						response?.data?.productionPlans
					);
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
		return {
			nodesStat,
			edgesStat,
		};
	}

	const handleNodesState = (nodes, edges) => {
		setNodesState({
			nodes,
			edges,
		});
	};

	const handleSave = async () => {
		if (!selectedStore) {
			notificationServices?.generateNotification({
				message: 'Store is not selected',
				type: 'error',
			});
			return;
		}
		try {
			const response = await processFlowServices?.saveNodes({
				data: nodesState,
				params: {
					storeId: selectedStore,
				},
			});
			if (response?.statusCode === 200) {
				setIsChanged(false);
				fetchNodes();
				toggleProcessSetting(false, {});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	};

	// const handlePositionChange = (id, e) => {
	// const { name, value } = e?.target;
	// setNodesState((prev) => {
	// const temp = [...prev?.nodes];
	// const index = temp?.findIndex((ele) => {
	// return ele?.id === id;
	// });
	// temp[index].position[name] = value;
	// return {
	// nodes: temp,
	// edges: prev?.edges,
	// };
	// });
	// };

	const BASE_STORES = useMemo(() => {
		return stores?.map((ele) => {
			return {
				...ele,
				label: ele?.name,
				role: ele?.type,
				value: ele?.id,
			};
		});
	}, [stores]);

	useEffect(() => {
		if (BASE_STORES?.length > 0) {
			setSelectedStore(BASE_STORES[0]?.id);
			setState({
				...state,
				selectedPlan: -1,
			});
		}
	}, [BASE_STORES]);

	const handleDateSelection = (date) => {
		setAvailableProductionPlans([]);
		setState({
			...state,
			selectedDate: date,
			selectedPlan: -1,
		});
	};

	// const handleGeneratePlan = async () => {
	// // toggleProductionPlanModal(true, {
	// // storeId: selectedStore,
	// // date: moment(state?.selectedDate).format('YYYY-MM-DD'),
	// // });
	// const req = {
	// data: {
	// storeId: selectedStore,
	// date: moment(state?.selectedDate).format('YYYY-MM-DD'),
	// },
	// };
	// setProductionPlanLoading(true);
	// try {
	// const response =
	// await inventoryAnalyticsServices.fetchProductionPlan(req);
	// if (response?.statusCode === 200) {
	// notificationServices.generateNotification({
	// type: 'success',
	// message: response?.message,
	// });
	// toggleProductionPlanModal(true, {
	// storeId: selectedStore,
	// date: moment(state?.selectedDate).format('YYYY-MM-DD'),
	// listData: response?.data,
	// });
	// } else {
	// notificationServices.generateNotification({
	// type: 'error',
	// message: response?.errorMessage ?? response?.message,
	// });
	// }
	// } catch (error) {
	// // eslint-disable-next-line no-console
	// console.log('Error In initiating Production Plan', error);
	// // notificationServices.generateNotification({
	// // type: 'error',
	// // message: 'Some Error occurred',
	// // });
	// } finally {
	// setProductionPlanLoading(false);
	// }
	// };

	const poActionHandler = async (processNode, type = 1) => {
		if (type === 1) {
			const poId = processNode?.orderId
				?.map((ele) => {
					return ele?.poId;
				})
				?.join(',');
			try {
				const response =
					await inventoryAnalyticsServices?.fetchPurchaseOrder({
						params: {
							poId,
						},
					});
				if (response?.statusCode === 200) {
					togglePOItemsModal(true, {
						heading: `Production plan of ${processNode?.heading}`,
						items: response?.data?.items,
						clientPoId: response?.data?.clientPoId,
						excludedColumns: [
							'Packaged',
							'Dispatched',
							'Received',
							'Returned',
							'Returned Quantity',
							'Item Price',
							'Item Sub Total',
							'Tax Percentage',
							'Item Tax',
							'Tax value',
							'Item Amount',
							'Total Item Amount',
							'Quantity',
							'Used For',
							'Discount %',
						],
						customColumns: ['Order Type'],
						includedColumns: [],
					});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('error', error);
			}
		} else if (type === 2) {
			setProductionPlanLoading(true);
			const poIds = processNode?.orderId?.map((ele) => {
				return ele?.poId;
			});
			try {
				const response = await inventoryAnalyticsServices.fetchWoPdfZip(
					{
						poIds,
					}
				);

				if (response?.statusCode === 200) {
					const blob = new Blob(
						[
							Uint8Array.from(atob(response?.data), (c) => {
								return c.charCodeAt(0);
							}),
						],
						{
							type: 'application/zip',
						}
					);
					const downloadUrl = window.URL.createObjectURL(blob);
					const hiddenElement = document.createElement('a');
					hiddenElement.href = downloadUrl;
					// eslint-disable-next-line prefer-template
					hiddenElement.download = `${processNode?.heading}_Work_order.zip`;
					document.body.appendChild(hiddenElement);
					hiddenElement.click();
					window.URL.revokeObjectURL(downloadUrl);
					document.body.removeChild(hiddenElement);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			} finally {
				setProductionPlanLoading(false);
			}
		}
	};

	const handleCancel = () => {
		fetchNodes();
		setIsChanged(false);
	};

	// const handleCheckBox = () => {
	// setShowCheckBox(true);
	// };

	const handleCheckboxChange = (id) => {
		setCheckboxStates((prevStates) => ({
			...prevStates,
			[id]: !prevStates[id],
		}));
	};

	const handleDel = () => {
		setShowCheckBox(false);
	};

	const nodeDelete = useCallback(
		async (id) => {
			try {
				const response = await processFlowServices.nodeDelete({
					data: {
						nodeId: id,
					},
				});
				if (response?.statusCode === 200) {
					notificationServices?.generateNotification({
						message: response?.message,
						type: 'success',
					});
					fetchNodes();
					toggleAlert(false);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('erorrr', error);
			}
		},
		[selectedStore]
	);

	const edgeDelete = async (id) => {
		try {
			const response = await processFlowServices.edgeDelete({
				data: {
					edgeId: id,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					message: response?.message,
					type: 'success',
				});
				fetchNodes();
				toggleAlert(false);
			} else if (response?.statusCode === 404) {
				fetchNodes();
				toggleAlert(false);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('erorrr', error);
		}
	};

	const handleNodeDelete = useCallback(
		(item) => {
			toggleAlert(true, {
				heading: 'Delete Process?',
				message: `Are you sure you want to delete ${item.heading} process?`,
				handleConfirm: () => {
					nodeDelete(item?.id);
				},
			});
		},
		[selectedStore]
	);

	const handleEdgeDelete = useCallback(
		(id) => {
			toggleAlert(true, {
				heading: 'Delete Edge?',
				message: 'Are you sure you want to delete Edge?',
				handleConfirm: () => {
					edgeDelete(id);
				},
			});
		},
		[selectedStore]
	);

	return (
		<div className={classes(styles.root)}>
			<ProcessModal />
			<POItemsModal />
			<ProductionPlanModal />
			<ProcessSettingsDrawer />
			<BackdropLoader
				isLoading={productionPlanLoading || loading}
				showProgress
			/>
			<EditNodeModal
				storeId={selectedStore}
				immovableAssetsList={immovableAssetsList}
				fetchNodes={fetchNodes}
				stores={BASE_STORES}
			/>
			<AlertModal />
			<ImageModal />
			{/* <NodeTypes nodes={nodesState?.nodes} /> */}
			<div className={styles['filters-container']}>
				<SelectPicker
					data={BASE_STORES}
					style={{
						width: 224,
					}}
					groupBy='type'
					placeholder='Select Store'
					cleanable={false}
					onChange={(storeId) => {
						setSelectedStore(storeId);
						setState({
							...state,
							selectedPlan: -1,
						});
					}}
					renderMenuItem={(_) => {
						return (
							<div>
								<i className='rs-icon rs-icon-user' />
								{' '}
								{_}
							</div>
						);
					}}
					renderMenuGroup={(_) => {
						return (
							<div>
								<i className='rs-icon rs-icon-group' />
								{_}
							</div>
						);
					}}
					value={selectedStore}
					renderValue={(value, item) => {
						return (
							<div className='rsuite-select-picker-render-value'>
								{item?.name}
							</div>
						);
					}}
				/>
				{availableProductionPlans?.length > 1 && (
					<TextField
						select
						value={state?.selectedPlan}
						label='Select Production plan'
						onChange={(e) => {
							setState({
								...state,
								selectedPlan: e?.target?.value,
							});
						}}
						style={{
							width: '200px',
							background: '#fff',
						}}
					>
						<MenuItem value={-1}>All</MenuItem>
						{availableProductionPlans?.map((ele) => {
							return (
								<MenuItem value={ele?.id}>
									{ele?.description}
									{' '}
									(ID:
									{ele?.id}
									)
								</MenuItem>
							);
						})}
					</TextField>
				)}
				<div className={styles.date_type}>
					<SelectPicker
						data={[
							{
								label: 'Work Order On',
								value: 'WORK_ORDER',
							},
							{
								label: 'Production Plan Generated for',
								value: 'PRODUCTION_PLAN',
							},
						]}
						value={state?.selectedTimeType}
						searchable={false}
						cleanable={false}
						onChange={(value) => {
							setState({
								...state,
								selectedTimeType: value,
							});
						}}
						style={{
							width: 200,
						}}
						placeholder='Select Date Type'
					/>
					<DatePickerCustom
						label='Select Date'
						oneTap
						selectedDate={state?.selectedDate}
						format='yyyy-MM-dd'
						selectDate={handleDateSelection}
					/>
				</div>
			</div>
			{/* <div className='absolute top-20  left-4 cursor-pointer z-[999] '>
				<Button onClick={handleCheckBox}>Select</Button>
			</div> */}
			{showCheckBox && (
				<div
					className='absolute top-20  left-4 cursor-pointer z-[999] '
					onClick={handleDel}
				>
					<Button>
						Delete Selective
						{' '}
						{Object.values(checkboxStates).filter(Boolean).length}
					</Button>
				</div>
			)}
			<div className={classes(styles.orders_count)}>
				<Tooltip title='Pending Work orders'>
					<div className={classes(styles.count, styles.pending)}>
						<div className={styles.container} />
						<p className={styles.count_header}>WO Pending</p>
					</div>
				</Tooltip>
				<Tooltip title='Completed Work orders'>
					<div className={classes(styles.count, styles.completed)}>
						<div className={styles.container} />
						<p className={styles.count_header}>WO Completed</p>
					</div>
				</Tooltip>
			</div>
			<FlowChart
				toggleProcessModal={toggleProcessModal}
				isInternal={isInternal}
				isEdit={isEdit}
				initialState={initialState}
				handleNodesState={handleNodesState}
				poActionHandler={poActionHandler}
				setIsChanged={setIsChanged}
				toggleImageModal={toggleImageModal}
				toggleEditNodeModal={toggleEditNodeModal}
				toggleAlert={toggleAlert}
				showCheckBox={showCheckBox}
				onCheckboxChange={handleCheckboxChange}
				handleEdgeDelete={handleEdgeDelete}
				handleNodeDelete={handleNodeDelete}
				selectedStore={selectedStore}
			/>
			{isInternal && (
				<>
					<Tooltip title='Settings'>
						<div
							onClick={() => toggleProcessSetting(true, {
								storeId: selectedStore,
								baseStores: BASE_STORES,
								setInitialState,
								isChanged,
								setIsChanged,
								immovableAssetsList,
							})}
							className=' text-white absolute top-4 right-5 z-30 w-12 h-12 bg-blue-600 rounded-full flex justify-center items-center'
						>
							<Settings className='' />
						</div>
					</Tooltip>
					<div>
						{isChanged && (
							<div className=' animate-pulse flex gap-2 absolute top-[1.3rem] right-[6rem] z-[999]'>
								<Button
									variant='secondary'
									onClick={handleCancel}
								>
									Cancel
								</Button>
								<Button
									className='primary-btn'
									onClick={handleSave}
								>
									Confirm
								</Button>
							</div>
						)}
					</div>
				</>
				// <ProcessSideBar
				// showSidebar={showSidebar}
				// setShowSidebar={setShowSidebar}
				// initialState={initialState}
				// eslint-disable-next-line no-tabs
				// 	setInitialState={setInitialState}
				// handleSave={handleSave}
				// nodesState={nodesState}
				// handlePositionChange={handlePositionChange}
				// baseStores={BASE_STORES}
				// storeId={selectedStore}
				// isChanged={isChanged}
				// setIsChanged={setIsChanged}
				// />
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	// const stores = state?.form?.STORES?.data?.LIST;
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;

	return {
		stores,
		userPermissions,
	};
};
const mapDispatchToProps = {
	toggleProcessModal: modalActions?.toggleProcessModal,
	toggleProductionPlanModal: modalActions?.toggleProductionPlanModal,
	togglePOItemsModal: modalActions?.togglePOItemsModal,
	fetchStoresList: formActions.gateway,
	toggleProcessSetting: drawerActions.toggleProcessSettingsDrawer,
	toggleImageModal: modalActions.toggleImageModal,
	toggleEditNodeModal: modalActions.toggleEditNodeModal,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessFlowPage);
