import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ShiftTableModal.module.scss';
import DynamicHeaderTable from '../../DynamicHeaderTable/DynamicHeaderTable';
import { classes } from '../../../Helpers';

const ShiftTableModal = (props) => {
	const { show, data, toggleModal } = props;
	const HEADING = `Shift - ${data?.shiftName}`;

	const valueList = {
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thrusday',
		5: 'Friday',
		6: 'Saturday',
		7: 'Sunday',
	};

	const getListAsStr = (colName) => {
		let str = '';

		data?.[colName]?.forEach((item) => {
			if (str) {
				str += ', ';
			}
			if (colName === 'days') {
				str += valueList[item];
			} else {
				str += item;
			}
		});
		return str;
	};

	const headers = [
		{
			colName: 'Process',
			subCols: [],
		},
		{
			colName: 'Start time',
			id: 'startTime',
			subCols: [],
		},
		{
			colName: 'End time',
			id: 'endTime',
			subCols: [],
		},
		{
			colName: 'Capacity (In Kg)',
			action: true,
			subCols: [
				{
					colName: 'Default',
					id: 'default',
					subCols: [],
				},
				...(data?.exceptionCategory ?? []),
			],
		},
	];

	const EditableInput = ({ colId, rowItem }) => {
		return (
			<div className={classes(styles['editable-input'])}>
				<div className={classes(styles['item-center'])}>
					<span className={classes(styles['custom-input'])}>
						{data?.capacity?.[rowItem?.id]?.[colId] ?? ''}
					</span>
				</div>
			</div>
		);
	};

	return (
		<BaseModal
			heading={HEADING}
			width='80%'
			height='98%'
			open={show}
			showConfirm={false}
			handleClose={() => {
				toggleModal(false, {});
				// setInputState([]);
			}}
		>
			<div id='divIdToPrint' className={classes(styles['contract-container'])}>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'Store: '}
					</span>
					<span>
						{data?.store?.title}
					</span>
				</div>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'Shift Name: '}
					</span>
					<span>
						{data?.shiftName}
					</span>
				</div>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'Start Date: '}
					</span>
					<span>
						{data?.startDate}
					</span>
				</div>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'End Date: '}
					</span>
					<span>
						{data?.endDate}
					</span>
				</div>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'Applicable Days: '}
					</span>
					<span>
						{getListAsStr('days')}
					</span>
				</div>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'Manager Roles: '}
					</span>
					<span>
						{getListAsStr('managerNames')}
					</span>
				</div>
				<div className={classes(styles['detail-row'])}>
					<span className={classes(styles.text)}>
						{'Managers: '}
					</span>
					<span>
						{getListAsStr('roleNames')}
					</span>
				</div>
				<div className={classes(styles.text)}>
					Shift Process Capacity
				</div>
				<DynamicHeaderTable
					headerData={headers}
					rowsData={data?.processes}
					tableDataElement={EditableInput}
					excludedCols={['Process']}
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.shiftTableModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleShiftTableModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftTableModal);
