/* eslint-disable no-tabs */
/* eslint-disable block-spacing */
// import { useEffect } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Contact',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last Order At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Orders',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Order Value',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'name',
	Contact: 'id',
	'Last Order At': 'lastOrderAt',
	'Total Orders': 'totalOrders',
	'Total Order Value': 'totalOrderValue',
};

const CustomerListPage = (props) => {
	const { fetchCustomers, data, query } = props;

	useEffect(() => {
		fetchCustomers({
			entity: 'MY_CUSTOMERS',
			req: {
				params: {
					user: '',
					storeId: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className='root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data.filter((customer) => {
					return (
						customer?.name?.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...ACTION_COLUMNS]}
				special={{}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MY_CUSTOMERS.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchCustomers: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListPage);
