import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import {
	SubscriptionEditPage,
	SubscriptionListPage,
	SubscriptionViewPage,
} from '../../../Pages/Food Products/Subscription';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const SubscriptionRoutes = (props) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.SUBSCRIPTION}`,
			name: 'Subscription',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='SUBSCRIPTION'
			path={path}
			listPage={SubscriptionListPage}
			viewPage={<SubscriptionViewPage breadcrumbs={breadcrumbs} />}
			editPage={<SubscriptionEditPage breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default SubscriptionRoutes;
