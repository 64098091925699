import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	useEffect, useMemo, useState
} from 'react';
import styles from './ProcessAccordion.module.css';
import IngredientAccordion from '../IngredientAccordion/IngredientAccordion';
import { classes } from '../../../../Helpers';

function ProcessAccordion(props) {
	const {
		key,
		processData,
		processExpanded,
		setProcess,
		setCurrentRecipe,
		currentRecipe,
		getPastQCDetails,
	} = props;
	const [ingredientExpanded, setIngredientExpanded] = useState(
		processData?.ingredients[0]?.id ?? false
	);

	useEffect(() => {
		setCurrentRecipe({
			...processData?.ingredients[0],
			validated: processData?.ingredients[0]?.validation,
		});
		getPastQCDetails(processData?.ingredients[0]?.qcId);
	}, [processData]);

	useEffect(() => {
		setIngredientExpanded(currentRecipe?.id);
	}, [currentRecipe]);

	const accordionClasses = useMemo(() => {
		if (processData?.startedAt && !processData?.isCompleted) {
			return styles.progress;
		}
		if (processExpanded && processData?.isCompleted) {
			return styles.completed;
		}
		if (processExpanded && !processData?.isCompleted) {
			return styles.selected;
		}
		return '';
	}, [processData]);

	const handleCurrentIngredient = (ingredient) => {
		setIngredientExpanded(ingredient?.id);
		setCurrentRecipe({
			...ingredient,
			validated: ingredient?.validation,
		});
		getPastQCDetails(ingredient?.qcId);
	};

	return (
		<div key={key}>
			<Accordion
				expanded={processExpanded === processData?.id}
				onChange={() => {
					setProcess(processData);
				}}
				className={classes(styles.root, accordionClasses)}
			>
				<AccordionSummary
					className={styles['accordion-heading']}
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<Typography className={styles.name}>
						{processData?.name}
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={styles?.['accordion-details']}>
					{processData?.ingredients?.map((ingredient, idx) => {
						return (
							<IngredientAccordion
								key={ingredient?.id}
								ingredient={ingredient}
								isExpanded={idx === 0 && ingredient?.id}
								ingredientExpanded={ingredientExpanded}
								setIngredient={handleCurrentIngredient}
							/>
						);
					})}
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default ProcessAccordion;
