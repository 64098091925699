/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable*/
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import {
	useCallback, useEffect, useState
} from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import { useHistory } from 'react-router';
import {
	analyticsAction, formActions, modalActions
} from '../../../Actions';
import { ListTable } from '../../../Components/ListTable';
import { POItemsModal } from '../../../Components/Modals/POItemsModal';
import styles from './CapexOrderListPage.module.scss';
import { VanImageModal } from '../../../Components/Modals/VanImageModal';
import assetServices from '../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal } from '../../../Components/Modals';
import { VendorOrderGrnsModal } from '../../../Components/Modals/VendorOrderGrns';
import { urls } from '../../../Constants';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import { classes } from '../../../Helpers';
import { ItemsModal } from '../../../Components/Modals/ItemsModal';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatch By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver To',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor Challan ID',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Order Date',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'Download',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'View More',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'CANCEL',
		},
	},
	{
		title: 'MORE',
		config: {
			type: 'MORE',
		},
	},
];

const COLMAP = {
	'PO Id': 'id',
	'Client PO Id': 'clientPoId',
	'Order Date': 'createdAt',
	'Dispatch By': 'scheduledAt',
	'Delivery Time': 'deliveryTime',
	'Deliver To': 'storeName',
	BASE: 'fromStoreName',
	'Van Temp': 'receivingTemp',
	'Transit Van Image': 'receivingTempImage',
	'Order Status': 'id',
	'Vendor Challan ID': 'vendorChallanId',
	Vendor: 'vendorName',
};

const filterOrderStatus = [
	'CANCELLED',
	'PENDING',
	'FULFILLED',
	'APPROVAL_PENDING',
	'APPROVAL_REJECTED',
];

const dateTypeArr = [
	{
		label: 'Dispatch By',
		value: 'scheduled',
	},
	{
		label: 'Order Date',
		value: 'ordered',
	},
];

const FIND_FILTERS = ['vendor', 'status', 'dateRange', 'timeType', 'store'];

function CapexOrdersListPage(props) {
	const {
		data,
		toggleModal,
		// toggleFileUploaderModal,
		toggleAlert,
		fetchOrdersList,
		toggleVendorOrderGrnsModal,
		dateRangeFilter,
		navState,
		userPermissions,
		toggleVanImageModal,
		vendors,
		fetchVendors,
		resetInventoryAnalyticsState,
		userList,
		fetchUserList,
	} = props;
	const history = useHistory();
	const [update, setUpdate] = useState(0);
	const navRequisites = navState?.[urls.PURCHASE_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});

	// const { userConfig } = useAuth();
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/analytics/po']?.submenu['/analytics/list/capex/vendor/orders']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const name = roleDetails.some((item) => item.name === 'CXO Office');
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item?.name === 'Procurement');
	// const vendorApproval = roleDetails.some((ele) => ele?.name === 'Procurement');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1 ;
	// }

	// end user Acces

	useEffect(() => {
		fetchUserList();
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			resetInventoryAnalyticsState({
				entity: 'INVENTORY_ANALYTICS',
				method: 'VENDOR_ORDERS_LIST',
				data: [],
			});
		};
	}, []);

	const handleCancelPo = (id, clientPoId) => {
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Cancelling Po ${clientPoId || ''}`,
			});
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status: 'CANCEL',
						},
					}
				);
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	const handleFulfillPo = (id) => {
		(async () => {
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status: 'GRN_FULFILL',
						},
					}
				);
				if (resp?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: 'PO Successfully fulfilled',
					});
					setUpdate((prev) => {
						return prev + 1;
					});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('some error', error);
			}
		})();
	};

	const handleViewClick = (item) => {
		const vendorDetails = vendors?.find((ele) => {
			return ele?.id === item?.vendorId;
		});
		toggleModal(true, {
			heading: `Items of Asset Purchase Orders ${
				item?.clientPoId ? `of ${item?.clientPoId}` : ''
			}`,
			...item,
			showPdf: item?.poURL,
			showTotal: true,
			showPoDetails: true,
			width: '80%',
			height: '70%',
			transactionData: {
				'Order ID': item?.clientPoId,
				'Deliver To Store': item?.storeName,
				Vendor: item?.vendorName,
				'Deliver To Store GSTIN': item?.storeGstin ?? '',
				'Vendor GSTIN': vendorDetails?.gstin ?? '',
				'Payment Term': item?.jsonData?.paymentTerm,
				'Vendor Address': `${
					vendorDetails?.shippingAddressCity ?? ''
				} ${vendorDetails?.shippingAddressState ?? ''}, ${
					vendorDetails?.shippingAddressPinCode ?? ''
				}`,
				'Order Date': moment(item?.createdAt).format('YYYY-MM-DD'),
				'Dispatch By': moment(item?.scheduledAt).format('YYYY-MM-DD'),
			},
			excludedColumns: [
				'Packaged',
				'Dispatched',
				'Received',
				'From Process',
				'PO Id',
			],
		});
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [
			'PO ID',
			'Client PO ID',
			'PO Scheduled Date',
			'PO Order Date',
			'PO Order status',
			'PO Raised by',
			'Vendor',
		];
		const downloadBtn = document.createElement('a');
		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const fetchPOGrns = async (orderId) => {
		const req = {
			data: {
				filters: {
					orderId,
				},
				type: 'GRN',
			},
		};
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'loading...',
		});
		try {
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req,
				});
			// eslint-disable-next-line no-empty
			if (response?.statusCode === 200) {
				toggleVendorOrderGrnsModal(true, {
					heading: `GRNs of PO: #${orderId}`,
					grnList: response?.data,
					orderType: 'CAPEX',
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
				config: {
					id: notificationId,
				},
			});
		}
	};

	const showPOBills = (orderId, clientPoId) => {
		history.push(
			`${urls.EXTENSION}${urls.BILLS}?poId=${orderId}&clientPoId=${clientPoId}`
		);
	};

	const viewMoreOptionsList = {
		'View Items': (item, cb) => {
			handleViewClick(item);
			cb();
		},
		'View GRNs': (item, cb) => {
			fetchPOGrns(item?.id);
			cb();
		},
	};

	const SPECIAL_ITEMS = {
		'Order Status': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			if (item?.orderStatus === 'APPROVAL_REJECTED') {
				return (
					<div className={styles.rejected}>
						<p>
							{item?.orderStatus?.split('_')?.join(' ')}
							{item?.jsonData?.rejectionReason
								? ` (Reason: ${item?.jsonData?.rejectionReason})`
								: ''}
						</p>
					</div>
				);
			}
			return item?.orderStatus?.split('_')?.join(' ');
		},
		'Van Temp': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return item?.receivingTemp ?? '-';
		},
		'Transit Van Image': (id, src) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div
					style={{
						width: '5rem',
						display: 'flex',
						cursor: 'pointer',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'center',
					}}
				>
					{item?.receivingTempImage ? (
						// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
						<img
							onClick={() => {
								toggleVanImageModal(true, {
									heading: `${item?.id ?? '-'} at ${
										item?.scheduledAt
									}`,
									image: item?.receivingTempImage,
									vanTemp: item?.receivingTemp,
									id: item?.id,
									// image: 'https://placehold.co/600x400',
									// height: 400,
								});
							}}
							width={100}
							height={50}
							// src='https://placehold.co/600x400'
							src={item?.receivingTempImage}
							alt={src}
						/>
					) : (
						<>-</>
					)}
				</div>
			);
		},
		Download: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			// const clientPOId = item?.clientPoId ?? '-';
			// const deliveryDate = item?.scheduledAt;
			// const orderDate = moment(item?.createdAt).format('YYYY-MM-DD');
			// const orderStatus = item?.orderStatus;
			// const raisedBy = item?.storeName;
			// const fullFilledBy = item?.vendorName ?? '-';
			// const CommonValues = [
			// item?.id,
			// clientPOId,
			// deliveryDate,
			// orderDate,
			// orderStatus,
			// raisedBy,
			// fullFilledBy,
			// ];
			// const extraHeaders = ['Product Name', 'unit', 'Ordered Quantity'];
			return (
				<div className={styles?.downloadDiv}>
					{item?.poURL && (
						<Chip
							size='small'
							label='PO'
							onClick={() => {
								if (item?.poURL) {
									window.open(item?.poURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
					{item?.grnURL && (
						<Chip
							size='small'
							label='GRN'
							onClick={() => {
								if (item?.grnURL) {
									window.open(item?.grnURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
				</div>
			);
		},
		Vendor: (vendorName) => {
			return (
				<div
					style={{
						width: '100px',
						textAlign: 'left',
					}}
				>
					{vendorName ?? '-'}
				</div>
			);
		},
		'Order Date': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div
					style={{
						width: '100px',
						textAlign: 'left',
					}}
				>
					{moment(item?.createdAt)?.format('YYYY-MM-DD') ?? '-'}
				</div>
			);
		},
		Actions: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const vendorDetails = vendors?.find((ele) => {
				return ele?.id === item?.vendorId;
			});
			return (
				<div className={styles.btns}>
					{item?.orderStatus === 'APPROVAL_PENDING' &&
						item?.orderStatus !== 'APPROVAL_REJECTED' && userPermissions.MANAGER ? (
							<Button
									onClick={() => {
									toggleModal(true, {
										heading: `Items of Asset Purchase Orders #${
										item?.clientPoId ?? item?.id
									}`,
										...item,
										isApprove: true,
										approvalText:
											'I have checked all the items of Capex Order',
										showTotal: true,
										showPdf: true,
										showPoDetails: true,
										transactionData: {
											'Order ID': item?.clientPoId,
											'Deliver To Store': item?.storeName,
											Vendor: item?.vendorName,
											'Deliver To Store GSTIN': item?.storeGstin,
											'Vendor GSTIN': vendorDetails?.gstin ?? '',
											'Payment Term': item?.jsonData?.paymentTerm,
											'Vendor Address': `${
											vendorDetails?.shippingAddressCity ??
											''
										} ${
											vendorDetails?.shippingAddressState ??
											''
										}, ${
											vendorDetails?.shippingAddressPinCode ??
											''
										}`,
											'Order Date': moment(item?.createdAt).format('YYYY-MM-DD'),
											'Dispatch By': moment(item?.scheduledAt).format('YYYY-MM-DD'),
										},
										width: '90%',
										height: '90%',
										excludedColumns: [
											'Packaged',
											'Dispatched',
											'Received',
											'Fulfilled',
											'From Process',
											'PO Id',
											'Returned',
										],
									});
								}}
									variant='contained'
									color='primary'
									fullWidth
								>
								Review
								</Button>
						) : null}
					{/* {(item?.orderStatus === 'PENDING') && (
						<Button
							onClick={() => {
								toggleAlert(true, {
									heading: 'FulFill Capex Order?',
									height: '15rem',
									handleConfirm: () => {
										handleFulfillPo(itemId);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Have you received the items/services of Capex order #${
										item?.clientPoId || ''
									}?`,
								});
							}}
							variant='contained'
							color='primary'
							fullWidth
						>
							Fulfill PO
						</Button>
					)} */}
					{item?.orderStatus === 'PENDING' ||
						item?.orderStatus === 'APPROVAL_PENDING' ? (
							<div
								style={{
									width: '125px',
								}}
							>
								<Button
									variant='outlined'
									color='error'
									onClick={() => {
										toggleAlert(true, {
											heading: 'Cancel PO?',
											height: '15rem',
											handleConfirm: () => {
												handleCancelPo(
													itemId,
													item?.clientPoId
												);
												toggleAlert(false);
											},
											colorReverse: false,
											cancelText: 'No',
											confirmText: 'Yes',
											message: `Are you sure you want to Cancel ${
												item?.clientPoId || ''
											}?`,
										});
									}}
									fullWidth
								>
									Cancel PO
								</Button>
							</div>
						) : (
							'-'
						)}
				</div>
			);
		},
	};

	const handleEditSubmit = async (editedValues) => {
		try {
			const response = await assetServices.editAssetTemp({
				data: editedValues,
				params: {
					type: 'PO',
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error ?? error?.message,
			});
		}
	};

	const exportItemsWiseData = () => {
		const headers = [
			'GRN ID',
			'GRN Start Time',
			'GRN End Time',
			'Product Name',
			'Variant',
			'Unit',
			'PO Ordered Quantity',
			'PO Fulfilled Quantity',
			'GRN Fulfilled Quantity',
		];
		const totalValues = [];
		data?.forEach((ele) => {
			if (ele?.grn?.length > 0) {
				ele?.grn?.forEach((grnEle) => {
					const startTime = grnEle?.createdAt
						? moment(grnEle?.createdAt)?.format('HH:MM A')
						: '';
					const endTime = grnEle?.closedAt
						? moment(grnEle?.closedAt)?.format('HH:MM A')
						: '';
					if (grnEle?.items?.length) {
						grnEle?.items?.forEach((item) => {
							totalValues?.push([
								ele?.id,
								ele?.clientPoId ?? '-',
								ele?.scheduledAt,
								moment(ele?.createdAt).format('YYYY-MM-DD'),
								ele?.orderStatus,
								ele?.storeName,
								ele?.vendorName,
								grnEle?.id,
								startTime,
								endTime,
								item?.productName?.replaceAll(',', "'"),
								item?.variantName,
								item?.unit,
								item?.qty,
								'-',
								item?.fulfilled,
							]);
						});
					} else {
						totalValues?.push([
							ele?.id,
							ele?.clientPoId ?? '-',
							ele?.scheduledAt,
							moment(ele?.createdAt).format('YYYY-MM-DD'),
							ele?.orderStatus,
							ele?.storeName,
							ele?.vendorName,
							grnEle?.id,
							startTime,
							endTime,
							'-',
							'-',
							'-',
							'-',
							'-',
							'-',
						]);
					}
				});
			} else {
				ele?.items?.forEach((poItem) => {
					totalValues?.push([
						ele?.id,
						ele?.clientPoId ?? '-',
						ele?.scheduledAt,
						moment(ele?.createdAt).format('YYYY-MM-DD'),
						ele?.orderStatus,
						ele?.storeName,
						ele?.vendorName,
						'-',
						'-',
						'-',
						poItem?.productName?.replaceAll(',', "'"),
						poItem?.variantName,
						poItem?.unit,
						poItem?.qty,
						poItem?.fulfilled,
						'-',
					]);
				});
			}
		});
		handleExcelDownload(
			headers,
			totalValues,
			`CAPEX_ORDER_${moment(dateRangeFilter?.from)?.format(
				'YYYY-MM-DD'
			)}_${moment(dateRangeFilter?.to)?.format('YYYY-MM-DD')}`
		);
	};

	const fetchVendorsOrders = (filters) => {
		// console.log('filters', filters);
		const vendorOrderFilters = {
			dateRangeFilter: {
				dateType: filters?.timeType,
				from: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
				to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
			},
			fromStoreId: filters?.store,
			status: filters?.status,
			storeId:
				navSelectedStore?.value?.id === -999
					? null
					: navSelectedStore?.value?.id,
			vendors: filters?.vendors,
			orderType: 'capex',
		};
		const req = {
			data: {
				filters: vendorOrderFilters,
			},
		};
		fetchOrdersList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'VENDOR_ORDERS_LIST',
			req,
		});
	};

	const moreOptionsList = {};
	// 'Generate Performa Invoice': (po, cb) => {
	// if (po?.orderStatus === 'APPROVAL_PENDING') {
	// notificationServices.generateNotification({
	// type: 'error',
	// message: 'PO is not approved',
	// });
	// return null;
	// }
	// const url = `${urls.EXTENSION}${urls.BILLS}/add?poId=${po?.id}&isAdvance=true`;
	// history.push(url);
	// cb();
	// return null;
	// },
	// };

	const handleApprovePo = async (item) => {
		// setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.changeOrderStatus({
					params: {
						poId: item?.id,
						status: 'APPROVE',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				toggleModal(false, {});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
		// finally {
		// setIsloading(false);
		// }
	};
	const handleRejectPo = async (item, rejectionReason) => {
		// setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.changeOrderStatus({
					params: {
						poId: item?.id,
						status: 'REJECT',
						rejectionReason,
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				toggleModal(false, {});
				toggleAlert(false);

				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return (
		<div className={styles.root}>
			<AlertModal />
			{/* <POItemsModal
				handleConfirm={handleApprovePo}
				handleReject={handleRejectPo}
			/> */}
			<ItemsModal
				handleConfirm={handleApprovePo}
				handleReject={handleRejectPo}
			/>
			<VendorOrderGrnsModal />
			<VanImageModal handleEditSubmit={handleEditSubmit} />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Asset Purchase Orders'
					FILTERS={FIND_FILTERS?.filter((ele) => {
						return ele !== 'store';
					})}
					onSubmit={fetchVendorsOrders}
					update={update}
					timeTypeArray={dateTypeArr}
					statusList={filterOrderStatus?.map((ele) => {
						if (ele.id === 'FULFILLED') {
							return {
								id: ele.id,
								title: 'CLOSED',
							};
						}
						return {
							id: ele,
							title: ele,
						};
					})}
					preventDateRangeCheckbox
					additionInfo={{
						showAdd: true,
						addText: 'Order',
						prefix: 'Create',
						handleAddClick: () => {
							const redirectUrl =
								navSelectedStore?.value?.id !== -999
									? `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?userOrderType=purchase&typeId=4&toStore=${navSelectedStore?.value?.id}`
									: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?userOrderType=purchase&typeId=4`;
							history.push(redirectUrl);
						},
					}}
					filterLabels={{
						store: 'Deliver From',
					}}
					// externalFilters={externalFilters}
				/>
			</div>
			<div
				className={styles?.table}
				style={{
					width: '100%',
				}}
			>
				<ListTable
					hasDateRange
					allowChangeColumn
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data?.map((ele) => {
						return {
							...ele,
							rowStyles: classes(
								styles.row,
								styles?.[ele?.orderStatus]
							),
						};
					})}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
					onExportClick={exportItemsWiseData}
					moreOptionsList={moreOptionsList}
					viewMoreOptionsList={viewMoreOptionsList}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { navState, userPermissions } = state?.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { VENDOR_ORDERS_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	const { userList } = state?.auth;
	return {
		data,
		stores,
		dateRangeFilter,
		navState,
		userPermissions,
		vendors,
		userList,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.toggleItemsModal,
	toggleVendorOrderGrnsModal: modalActions.toggleVendorOrderGrnsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleVanImageModal: modalActions.toggleVanImageModal,
	// toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	toggleTransactionsModal: modalActions.toggleTransactionsModal,
	fetchOrdersList: analyticsAction.gateway,
	fetchVendors: formActions.gateway,
	resetInventoryAnalyticsState: analyticsAction.resetInventoryAnalyticsState,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CapexOrdersListPage);
