import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import AddCreditsConfig from './AddCredits.config';
import styles from './AddCredits.module.scss';
import creditsServices from '../../../Services/Form Services/credits.services';
import AddWithdrawModal from '../../../Components/AddWithdrawModal/AddWithdrawModal';
import { modalActions } from '../../../Actions';
import { modalTypes } from '../../../Constants';
import { notificationServices } from '../../../Services';
import TransactionHistoryTable from '../../../Components/TransactionHistoryTable/TransactionHistoryTable';

function AddCredits(props) {
	// eslint-disable-next-line no-unused-vars
	const [state, setState] = useState({
		...AddCreditsConfig.initialState,
	});
	const [type, setType] = useState(null);

	const { toggleModal } = props;

	useEffect(() => {
		if (state?.balance !== null && state?.balance !== '') {
			try {
				(async () => {
					const response = await creditsServices.gateway({
						req: {
							data: {
								mobile: state?.number,
							},
						},
						method: 'GET_TRANSACTION_HISTORY',
					});
					if (response?.responseCode === 'SS-001') {
						notificationServices.generateNotification({
							type: 'success',
							message:
								response?.responseMessage ||
								'Transaction History Fetched',
						});
						setState({
							...state,
							transactionHistory: response?.data?.transactions,
						});
					} else {
						notificationServices.generateNotification({
							type: 'error',
							message:
								response?.responseMessage ||
								'Some error occured',
						});
					}
				})();
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error?.message || 'Some error occured',
				});
			}
		}
	}, [state?.balance]);

	const fetchBalance = async (userNum) => {
		try {
			const res = await creditsServices.gateway({
				req: {
					body: {
						mobile: userNum,
					},
				},
				method: 'GET_BALANCE',
			});
			if (res?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: res?.responseMessage || 'Success',
				});
				setState({
					...state,
					balance: res?.data?.balance,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: res?.responseMessage || 'Some error occured',
				});
				setState({
					...state,
					balance: null,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message || 'Some error occured',
			});
		}
	};

	const handleInput = (e) => {
		const { name, value } = e.target;
		setState({
			...state,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		try {
			fetchBalance(state?.number);
		} catch (error) {
			// console.log(error);
		}
	};

	const handleAddCredits = async (amount) => {
		try {
			const response = await creditsServices.gateway({
				req: {
					data: {
						mobile: state?.number,
						amount,
						comments: state?.comment,
					},
				},
				method: 'ADD_CREDITS',
			});
			if (response?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.responseMessage || 'Success',
				});
				setState({
					...state,
					balance: response?.data?.balance,
					amount: null,
					comment: '',
				});
				toggleModal();
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.responseMessage || 'Some Error Occured',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message || error || 'Some error occured',
			});
		}
	};

	const handleWithdrawCredits = async (amount) => {
		try {
			const response = await creditsServices.gateway({
				req: {
					data: {
						mobile: state?.number,
						amount,
						comments: state?.comment,
					},
				},
				method: 'WITHDRAW_CREDITS',
			});
			if (response?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.responseMessage || 'Success',
				});
				setState({
					...state,
					balance: response?.data?.balance,
					amount: null,
					comment: '',
				});
				toggleModal();
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.responseMessage || 'Some Error Occured',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message || error || 'Some error occured',
			});
		}
	};

	const handleClick = (actionType) => {
		toggleModal(true, {});
		setType(actionType);
	};

	return (
		<div className={styles.root}>
			<AddWithdrawModal
				handleInput={handleInput}
				handleAddCredits={handleAddCredits}
				handleWithdrawCredits={handleWithdrawCredits}
				type={type}
				number={state?.number}
				amount={state?.amount}
			/>
			<h1>Add Credits</h1>
			<form className={styles['form-wrapper']} onSubmit={handleSubmit}>
				<input
					onInput={handleInput}
					name='number'
					type='number'
					placeholder='Enter mobile number'
				/>
				<Button variant='contained' color='primary' type='submit'>
					Check balance
				</Button>
			</form>
			{state?.balance !== null && state?.balance !== '' && (
				<div className={styles['details-wrapper']}>
					<p>
						Current Balance:
						{' '}
						{state?.balance}
					</p>
					<div className={styles['btn-container']}>
						<button
							type='button'
							onClick={() => {
								return handleClick('add');
							}}
						>
							Add Credits
						</button>
						<button
							onClick={() => {
								return handleClick('withdraw');
							}}
							type='button'
						>
							Withdraw Credits
						</button>
					</div>
					<div className={styles.history}>
						<TransactionHistoryTable transactionHistory={state?.transactionHistory} />
					</div>
				</div>
			)}
		</div>
	);
}

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.ADD_WITHDRAW, show, data)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCredits);
