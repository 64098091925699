/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import PaymentsMade from '../../../../Pages/PaymentsMade/PaymentsMade';
import { CreatePage } from '../../../../Pages';
import PaymentsCreatePage from '../../../../Pages/PaymentsMade/PaymentsCreatePage/PaymentsCreatePage';
// eslint-disable-next-line object-curly-newline

const PaymentsMadeRoutes = (props) => {
	const { type = 'paymentMade' } = props;
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				{/* <MyMachinesPage createPath={`${path}/add`} /> */}
				<PaymentsMade paymentPageType={type} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<PaymentsCreatePage />}
					entity='PAYMENTS'
				/>
			</Route>
		</Switch>
	);
};

export default PaymentsMadeRoutes;
