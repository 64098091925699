import moment from 'moment';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'rsuite/DatePicker';
import { DownloadIcon } from 'lucide-react';
import { formActions } from '../../../../Actions';
import inventoryAnalyticsServices from '../../../../Services/Analytics Services/inventory-analytics.services';
import { notificationServices } from '../../../../Services/Notification';
import DragDropUploader from './DragDropUploader/DragDropUploader';
import styles from './UploadPurchaseOrder.module.scss';
import spinnerLoader from '../../../../Images/Spinner-1s-200px.svg';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/Components/ui/select';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import { Button } from '@/Components/ui/button';

function UploadPurchaseOrder(props) {
	const { filteredStores, fetchStoresList } = props;
	const [selectedStore, setSelectedStore] = useState(null);
	const [salesOrderStores, setSalesOrderStores] = useState([]);
	const [orderTypes, setOrderTypes] = useState([]);
	const [selectedOrderDate, setSelectedOrderDate] = useState(null);
	const [clientOrderId, setClientOrderId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [csvFile, setCsvFile] = useState({
		fileName: '',
		file: '',
	});
	const [imagesFile, setImagesFile] = useState({
		fileName: '',
		file: '',
	});
	const [selectedOrderType, setSelectedOrderType] = useState({
		type: null,
		displayName: null,
		templateUrl: null,
	});

	useEffect(() => {
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const response =
					await inventoryAnalyticsServices.fetchOrdersType();
				if (response?.statusCode === 200) {
					setOrderTypes(response?.data);
					setSelectedOrderType(response?.data[0]);
				}
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error?.message,
				});
			}
		})();
	}, []);
	useEffect(() => {
		if (selectedOrderType?.type === 'SALES') {
			(async () => {
				try {
					const response =
						await inventoryAnalyticsServices.fetchSalesOrderStores();
					if (response?.statusCode === 200) {
						setSalesOrderStores(response?.data?.stores);
					}
				} catch (error) {
					notificationServices.generateNotification({
						type: 'error',
						message: error?.message,
					});
				}
			})();
		}
	}, [selectedOrderType?.type]);

	const checkSelectedStore = () => {
		if (selectedOrderType?.storeSelection && selectedStore) return true;
		if (!selectedOrderType?.storeSelection) return true;
		return false;
	};

	const isUploadable = useMemo(() => {
		if (
			selectedOrderType?.type === 'PRODUCTS' &&
			imagesFile?.file &&
			csvFile?.file
		) {
			return true;
		}
		if (
			checkSelectedStore() &&
			selectedOrderType?.type &&
			(imagesFile?.file || csvFile?.file)
		) {
			return true;
		}
		return false;
	}, [selectedOrderType, imagesFile, csvFile, selectedStore]);

	const handleUploadFile = async () => {
		if (!selectedOrderType?.type) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select Order Type',
			});
			return;
		}
		if (selectedOrderType?.type === 'SALES' && !selectedOrderDate) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select Date',
			});
			return;
		}
		if (selectedOrderType?.storeSelection && !selectedStore) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select Store',
			});
			return;
		}
		if (isUploadable) {
			try {
				const formattedOrderDate =
					moment(selectedOrderDate).format('YYYY-MM-DD');
				const formData = new FormData();
				if (selectedOrderType?.type === 'PRODUCTS') {
					formData.append(
						'images',
						imagesFile?.file,
						imagesFile?.file?.name
					);
				}
				formData.append('po', csvFile?.file);
				formData.append('storeId', selectedStore);
				formData.append('type', selectedOrderType?.type);
				formData.append('clientOrderId', clientOrderId);
				formData.append('orderDate', formattedOrderDate);
				const req = {
					formData,
				};
				setIsLoading(true);
				const response =
					await inventoryAnalyticsServices.uploadPurchaseOrder({
						req,
					});
				if (response?.statusCode === 200) {
					// showMessage('File uploaded successfully!', 'success', 2000);
					setSelectedStore(null);
					setCsvFile({
						fileName: '',
						file: '',
					});
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('errorr in upload', error);
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 1000);
			}
		}
	};

	const STORES_LIST = useMemo(() => {
		if (selectedOrderType?.type === 'SALES') {
			return salesOrderStores;
		}
		return filteredStores;
	}, [salesOrderStores, filteredStores, selectedOrderType?.type]);

	const selectOrderDate = (date) => {
		setSelectedOrderDate(date);
	};

	const handleClientOrderId = (e) => {
		setClientOrderId(e.target.value);
	};

	return (
		<div className='flex flex-col gap-10 p-5'>
			<div className=' w-full flex items-center justify-between'>
				<h1 className='text-2xl font-semibold'>Upload Data</h1>
				{selectedOrderType?.type && (
					<div className={styles['template-download']}>
						<Button
							onClick={() => {
								return window.open(selectedOrderType?.template);
							}}
							variant='outline'
						>
							<DownloadIcon className='mr-2 h-4 w-4' />
							{`DOWNLOAD ${selectedOrderType?.type} TEMPLATE`}
						</Button>
					</div>
				)}
			</div>
			<div className='w-full flex items-center gap-4'>
				<div className='flex-1 flex flex-col gap-2'>
					<Label>Select Order Type</Label>
					<Select
						value={selectedOrderType}
						onValueChange={(value) => {
							setSelectedOrderType(value);
						}}
					>
						<SelectTrigger>
							<SelectValue placeholder='Theme' />
						</SelectTrigger>
						<SelectContent>
							{orderTypes?.map((currType) => {
								return (
									<SelectItem
										key={currType?.type}
										value={currType}
									>
										{currType?.displayName ??
											currType?.type}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
				{selectedOrderType?.type === 'SALES' && (
					<div className='flex-1 flex flex-col gap-2'>
						<Label>Select Date</Label>
						<DatePicker
							oneTap
							format='yyyy-MM-dd'
							style={{
								width: '100%',
								// border: '2px solid #334257',
								// borderRadius: '10px',
								outline: 'none',
								// padding: '0.25rem 0',
							}}
							value={selectedOrderDate}
							onChange={selectOrderDate}
							cleanable={false}
						/>
					</div>
				)}
				{selectedOrderType?.type === 'SALES' && (
					<div className='flex-1 flex flex-col gap-2'>
						<Label>Client Order ID (Optional)</Label>
						<Input
							className=''
							value={clientOrderId}
							onChange={handleClientOrderId}
						/>
					</div>
				)}
				{selectedOrderType.type && selectedOrderType?.storeSelection && (
					<div className='flex-1 flex flex-col gap-2'>
						<Label>Select Store</Label>
						<Select
							value={selectedStore}
							onValueChange={(value) => {
								setSelectedStore(value);
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder='Theme' />
							</SelectTrigger>
							<SelectContent>
								{STORES_LIST?.map((store) => {
									return (
										<SelectItem
											key={store?.id}
											value={store?.id}
										>
											{store?.name}
										</SelectItem>
									);
								})}
							</SelectContent>
						</Select>
					</div>
				)}
			</div>
			{selectedOrderType.type && (
				<>
					<div className='flex items-center gap-5'>
						<DragDropUploader
							uploadType='csvFile'
							uploadedFile={csvFile}
							setUploadedFile={setCsvFile}
							fileAcceptable='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
						/>
						{selectedOrderType?.type === 'PRODUCTS' && (
							<DragDropUploader
								uploadType='zipFile'
								uploadedFile={imagesFile}
								setUploadedFile={setImagesFile}
								fileAcceptable='zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
							/>
						)}
					</div>
					{isUploadable && (
						<Button
							onClick={handleUploadFile}
							className='primary-btn w-full'
							disabled={isLoading}
						>
							{isLoading ? (
								<span>
									<img
										style={{
											width: '40px',
										}}
										src={spinnerLoader}
										alt='Spinner'
									/>
									Loading
								</span>
							) : (
								'Upload'
							)}
						</Button>
					)}
				</>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const filteredStores = state?.form?.FILTERED_STORES?.data?.LIST;

	return {
		stores,
		filteredStores,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UploadPurchaseOrder);
