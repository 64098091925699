/* eslint-disable max-len */
import { ReceiptText } from 'lucide-react';
import { ArrowBack } from '@mui/icons-material';
import { Button } from '@/Components/ui/button';

const CreateHeader = (props) => {
	const { history, handleSave, qouteData } = props;

	return (
		<div className='w-full flex justify-between items-center p-4   mb-2 border-b border-[1px solid #d1d1d1]'>
			<div className='flex gap-2 justify-center items-center'>
				<div
					onClick={() => {
						history.goBack();
					}}
					className='w-10 h-10 rounded-full hover:bg-slate-100 cursor-pointer flex justify-center items-center'
				>
					<ArrowBack />
				</div>
				<ReceiptText className='text-yellow-500' />
				<p className='text-xl font-medium'>Generate PO / Generate</p>
			</div>
			<div className='relative right-28'>
				<p className='h-full'>
					Total Amount : ₹
					{new Intl.NumberFormat('en-IN').format(qouteData?.reduce((total, item) => {
						const itemTotal = total + Object.values(item)?.reduce((sum, quote) => sum + Number(quote?.price || 0), 0);
						return itemTotal;
					}, 0))}
				</p>
			</div>
			<Button className='primary-btn' onClick={handleSave}>Place Orders</Button>
		</div>
	);
};

export default CreateHeader;
