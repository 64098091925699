/* eslint-disable no-case-declarations */
/* eslint-disable object-curly-newline */
import { AccountBalanceWallet, Percent, Dashboard } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import styles from './DataTile.module.scss';
import { urls } from '../../../../Constants';
import { appendAsQueryString, formatNumber } from '../../../../Helpers';
import { useAuth } from '../../../../Components/Auth/Login/auth';

function DataTile(props) {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;
	const {
		tileData,
		color,
		storeFilter,
		dateFilter,
		showLink,
		selectedTab,
		showRedirect,
	} = props;
	const { day, month, year } = dateFilter;
	const queryParams = {
		storeId: storeFilter === -999 ? null : storeFilter,
		day,
		month,
		year,
	};
	const history = useHistory();

	const renderValue = () => {
		switch (tileData?.type) {
			case 'AMOUNT': {
				return `${tileData?.unit}${formatNumber(
					measurement,
					tileData?.value
				)} ${tileData?.otherValue ? `(${tileData?.otherValue})` : ''}`;
			}
			case 'PERCENT': {
				return `${tileData?.value?.toFixed(2)}${tileData?.unit} ${
					tileData?.otherValue ? `(${tileData?.otherValue})` : ''
				}`;
			}
			default:
				const valueString = tileData?.value?.toString();
				const decimalPart = valueString?.split('.')[1];
				const formattedValue =
					decimalPart && decimalPart.length > 2
						? Number(tileData.value).toFixed(2)
						: tileData?.value;
				return `${formattedValue} ${
					tileData?.otherValue ? `(${tileData?.otherValue})` : ''
				}`;
		}
	};

	const renderIcon = () => {
		switch (tileData?.type) {
			case 'AMOUNT': {
				return (
					<div className={styles['icon-container']}>
						<AccountBalanceWallet className={styles.icon} />
					</div>
				);
			}
			case 'PERCENT': {
				return (
					<div className={styles['icon-container']}>
						<Percent className={styles.icon} />
					</div>
				);
			}
			default:
				return (
					<div className={styles['icon-container']}>
						<Dashboard className={styles.icon} />
					</div>
				);
		}
	};

	return (
		<div
			style={{
				backgroundColor: color ?? '',
			}}
			className={styles.root}
		>
			<div className={styles.lhs}>{renderIcon()}</div>
			<div className={styles.rhs}>
				<p className={styles.header}>{renderValue()}</p>
				<p className={styles.subHeader}>{tileData?.heading}</p>
			</div>
			{showRedirect &&
				showLink && (
				<Whisper
					followCursor
					placement='bottom'
					speaker={<Tooltip>Move to analysis</Tooltip>}
				>
					<div
						onClick={() => {
							let link = '';
							const tp = selectedTab.type;
							switch (tp) {
								case 'COGS':
									link = `${urls.EXTENSION}${urls.COGS_ANALYSIS}`;
									break;
								case 'INV_MAT':
									link = `${urls.EXTENSION}${urls.INV_ANALYSIS}`;
									break;
								case 'INV_PROD':
									link = `${urls.EXTENSION}${urls.BOM_ANALYSIS}`;
									break;
								case 'AUDIT':
									link = `${urls.EXTENSION}${urls.AUDIT_ANALYSIS}`;
									break;
								case 'QC':
									link = `${urls.EXTENSION}${urls.QUALITY_ANALYSIS}?clear=true`;
									break;
								default:
									break;
							}
							history.push(
								appendAsQueryString(
										`${link}`,
										queryParams
								)
							);
						}}
						className={styles.link}
					>
						<LaunchIcon className={styles.icon} />
					</div>
				</Whisper>
			)}
			{/* {tileData?.heading?.toLowerCase()?.includes?.('inventory') &&
				selectedTab?.redirectAllow &&
				showRedirect && (
				<Whisper
					followCursor
					placement='bottom'
					speaker={<Tooltip>Inventory Analysis</Tooltip>}
				>
					<div
						onClick={() => {
							history.push(
								appendAsQueryString(
										`${urls.EXTENSION}${urls.INV_ANALYSIS}`,
										queryParams
								)
							);
						}}
						className={styles.link}
					>
						<LaunchIcon className={styles.icon} />
					</div>
				</Whisper>
			)}
			{tileData?.heading?.toLowerCase()?.includes?.('production') &&
				selectedTab?.redirectAllow &&
				showRedirect && (
				<Whisper
					followCursor
					placement='bottom'
					speaker={<Tooltip>Production Cost Analysis</Tooltip>}
				>
					<div
						onClick={() => {
							history.push(
								appendAsQueryString(
										`${urls.EXTENSION}${urls.BOM_ANALYSIS}`,
										queryParams
								)
							);
						}}
						className={styles.link}
					>
						<LaunchIcon className={styles.icon} />
					</div>
				</Whisper>
			)} */}
			{/* {selectedTab?.redirectAllow &&
				selectedTab?.type === 'QC' &&
				showRedirect && (
					<Whisper
						followCursor
						placement='bottom'
						speaker={<Tooltip>{tileData?.heading}</Tooltip>}
					>
						<div
							onClick={() => {
								history.push(
									appendAsQueryString(
										`${urls.EXTENSION}${urls.QUALITY_ANALYSIS}`,
										queryParams
									)
								);
							}}
							className={styles.link}
						>
							<LaunchIcon className={styles.icon} />
						</div>
					</Whisper>
				)}
			{selectedTab?.redirectAllow &&
				selectedTab?.type === 'AUDIT' &&
				showRedirect && (
					<Whisper
						followCursor
						placement='bottom'
						speaker={<Tooltip>{tileData?.heading}</Tooltip>}
					>
						<div
							onClick={() => {
								history.push(
									appendAsQueryString(
										`${urls.EXTENSION}${urls.AUDIT_ANALYSIS}`,
										queryParams
									)
								);
							}}
							className={styles.link}
						>
							<LaunchIcon className={styles.icon} />
						</div>
					</Whisper>
				)} */}
		</div>
	);
}

export default DataTile;
