import { Switch } from '@mui/material';
import { IconInput } from '../../../Components/Forms/Inputs';
import { generateRandomKey } from '../../../Helpers';
import styles from './CookingProductConfig.module.scss';

const CookingProductConfigSubSection = (props) => {
	const { fields, handleInput, isDisabled } = props;
	return (
		<div className={styles.root}>
			<div className='form-field row'>
				<label htmlFor='cookingRequired' className='form-label'>
					Recipe Enabled
				</label>
				<Switch
					size='small'
					name='cookingRequired'
					id='cookingRequired'
					checked={fields.cookingRequired}
					onChange={handleInput}
					{...generateRandomKey()}
					disabled={isDisabled}
					color='primary'
				/>
				<div
					className='row'
					style={{
						flex: '1 1 50%',
					}}
				>
					<label
						className='form-label'
						htmlFor='isEnabled'
						style={{
							width: 'auto',
							minWidth: 'auto',
							margin: '0px 1.5rem',
						}}
					>
						Cooking Required
					</label>
					<Switch
						size='small'
						name='isEnabled'
						id='isEnabled'
						defaultChecked={fields.isEnabled}
						onChange={handleInput}
						{...generateRandomKey()}
						disabled={isDisabled}
						color='primary'
					/>
				</div>
			</div>
			<div
				className={`${styles['time-details']} form-field row`}
				disabled={isDisabled}
			>
				<label htmlFor='time' className='form-label' data-mandatory>
					Time
				</label>
				<IconInput
					size='medium'
					type='custom'
					iconText='min'
					name='timeReq'
					id='timeReq'
					value={fields.timeReq}
					handleInput={handleInput}
					dataType=''
					dataId=''
					dataAttrs={{
						required: true,
						min: 1,
					}}
				/>
			</div>
		</div>
	);
};
CookingProductConfigSubSection.defaultProps = {
	fields: {},
	handleInput: () => {},
};
export default CookingProductConfigSubSection;
