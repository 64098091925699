import { endpoints } from '@/Constants';
import {
	authHeader, handleResponse, toQueryStringURL
} from '@/Helpers';

const processConfigServices = {
	createProcessConfig,
};
// eslint-disable-next-line no-unused-vars
async function createProcessConfig(req = {}, method = 'POST') {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.PROCESS_CONFIG, params);
	const requestOptions = {
		method,
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	if (method !== 'GET') {
		requestOptions.body = JSON.stringify({
			...req?.data,
		});
	}

	return fetch(url, requestOptions).then(handleResponse);
}

export default processConfigServices;
