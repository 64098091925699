/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	EyeIcon, FileArchiveIcon, FileBadge
} from 'lucide-react';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'rsuite';
import { Edit, PictureAsPdf } from '@mui/icons-material';
import { Chip } from '@mui/material';
import quoteServices from '@/Services/QuoteServices/quote.services';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';
import { formActions } from '@/Actions';
import { ListTable } from '@/Components/ListTable';
import { urls } from '@/Constants';
import { BackdropLoader } from '@/Components/Loaders';
import { notificationServices } from '@/Services/Notification';

const TABLE_COLUMNS = [
	{
		title: 'RFQ Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Purchasing Store',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'RFQ ITEM',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'RFQ View / Edit / Generate',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Feed Vendor Response / Quotation',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Generate PO / Generate',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Download PDF',
		config: {
			type: 'Data',
		},
	},
];
const COLMAP = {
	'RFQ Id': 'rfqId',
	'Purchasing Store': 'store',
	'RFQ ITEM': 'items',
	'Generate PO / Generate': 'captureVendorResponse',
	'Download PDF': 'download',
};

function ListQoute(props) {
	const { fetchVendors, fetchStoresList, storesList = [] } = props;
	const [rfqData, setRfqData] = useState([]);
	const [rfqAssetList, setRfqAssetList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const { url } = useRouteMatch();

	const fetchRfq = async (rfqFilter) => {
		try {
			setIsLoading(true);
			const response = await quoteServices?.fetchRfq({
				req: {
					data: {
						storeId: rfqFilter?.storeId,
						dateRangeFilter: rfqFilter?.dateRangeFilter,
					},
				},
			});
			if (response?.statusCode === 200) {
				setRfqData(response?.data || []);
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Error fetching RFQ',
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const fetchAssetList = async () => {
			const response = await quoteServices?.requestQuoteAssetList();
			if (response?.statusCode === 200) {
				setRfqAssetList(response?.data || []);
			}
		};
		fetchAssetList();
	}, []);

	const newRfqData = useMemo(() => {
		return (rfqData || []).map((rfq) => {
			const assetType = Array.isArray(rfq?.items)
				? rfq.items.map((item) => item?.assetType)
				: [];

			return {
				id: rfq?.id,
				rfqId: rfq?.id,
				store: storesList?.find((store) => store?.id === rfq?.store)?.name || '',
				items: Array.isArray(rfqAssetList)
					? rfqAssetList.filter((asset) => assetType.includes(asset?.id)).map((asset) => asset?.name)
					: [],
				captureVendorResponse: rfq?.id,
				download: [{
					doc: rfq?.doc,
				}, {
					poUrl: rfq?.poUrl,
				}],
			};
		});
	}, [rfqData, storesList, rfqAssetList]);

	const showCaptureVendorResponse = (id) => {
		history.push(`${urls.EXTENSION}${urls.VENDOR_RESPONSE_QUOTE}/${id}`);
	};
	const vendorQouteResponse = (id) => {
		history.push(`${urls.EXTENSION}${urls.VENDOR_QUOTE}/${id}`);
	};

	useEffect(() => {
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleEditRfq = (id) => {
		const rfq = rfqData?.find((item) => item?.id === id);
		history.push(`${url}/edit/${id}`, {
			rfq,
		});
	};

	const handlerfq = (filters) => {
		if (!filters?.dateRange?.from || !filters?.dateRange?.to) return;
		const dateRangeFilter = {
			from: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
			to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
		};
		const rfqFilter = {
			storeId: filters?.store,
			dateRangeFilter,
		};
		fetchRfq(rfqFilter);
	};

	const specialColumnsMappings = {
		'RFQ ITEM': (id, items) => {
			if (!items?.length) return null;
			if (items?.length <= 3) {
				return <div>{items.join(',')}</div>;
			}
			return <div>{`${items.slice(0, 3).join(',')}...`}</div>;
		},
		'Generate PO / Generate': (id, CaptureVendorResponse) => {
			return (
				<div
					style={{
						width: '2rem',
						cursor: 'pointer',
						display: 'block',
						margin: '0 auto',
					}}
					onClick={() => {
						showCaptureVendorResponse(id);
					}}
				>
					<EyeIcon />
				</div>
			);
		},
		'Feed Vendor Response / Quotation': (id, VendorQouteResponse) => {
			return (
				<div
					style={{
						width: '2rem',
						cursor: 'pointer',
						display: 'block',
						margin: '0 auto',
					}}
					onClick={
						() => {
							vendorQouteResponse(id);
						}
					}
				>
					<FileBadge />
				</div>
			);
		},
		'RFQ View / Edit / Generate': (id) => {
			return (
				<div
					style={{
						width: '2rem',
						cursor: 'pointer',
						display: 'block',
						margin: '0 auto',
					}}
				>
					<Button onClick={() => handleEditRfq(id)}>
						<Edit />
					</Button>
				</div>
			);
		},
		'Download PDF': (id, download) => {
			if (!download?.length) return null;

			return download.map((item, index) => {
				if (!item) return null;

				if (item?.doc) {
					return (
						<Chip
							size='small'
							label='PDF'
							onClick={() => {
								if (item?.doc) {
									const anchor = document.createElement('a');
									anchor.href = item.doc;
									anchor.download = 'RFQ.pdf';
									anchor.target = 'blank';
									anchor.click();
								}
							}}
							icon={<PictureAsPdf />}
						/>
					);
				} if (item?.poUrl) {
					return (
						<Chip
							size='small'
							label='PO'
							onClick={() => {
								if (item?.poUrl) {
									const anchor = document.createElement('a');
									anchor.href = item.poUrl;
									anchor.download = 'PO.zip';
									anchor.target = 'blank';
									anchor.click();
								}
							}}
							icon={<FileArchiveIcon />}
						/>
					);
				}
				return null;
			});
		},
	};
	return (
		<>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ImagesFinder
				FILTERS={['store', 'dateRangeOnly']}
				onSubmit={handlerfq}
				dateRangeOnlyText='RFQ Date'
			/>
			<ListTable
				columns={TABLE_COLUMNS}
				data={newRfqData}
				colMap={COLMAP}
				special={specialColumnsMappings}
				actions={SPECIAL_COLUMNS}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	const { LIST: vendors } = state?.form?.ALL_VENDORS?.data || {};
	const { LIST: storesList } = state?.form?.STORES?.data || {};
	return {
		vendors,
		storesList,
	};
};

const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListQoute);
