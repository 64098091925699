import { similarProductsConstants } from '../Constants';

const initialState = {
	similarProducts: [],
};

function similarProducts(state = initialState, action) {
	switch (action.type) {
		case similarProductsConstants.FETCH_GROUPS_REQUEST:
			return {
				...state,
			};
		case similarProductsConstants.FETCH_GROUPS_SUCCESS:
			return {
				...state,
				similarProducts: action.payload,
			};
		case similarProductsConstants.FETCH_GROUPS_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default similarProducts;
