/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './GenericItemList.module.scss';
import { classes } from '../../../../Helpers';

function map(input, in_min, in_max, out_min, out_max) {
	return (
		((input - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
	);
}
function buildItem(data) {
	//   const thisElementIsClicked =
	//     clickFeature?.currentElement?.id === data?.id &&
	//     clickFeature?.currentElement?.processId === data?.processId &&
	//     clickFeature?.currentElement?.stageId === data?.stageId;

	return (
		<div
			// onClick={()=>{
			//     if(data?.current){
			//         clickFeature.applyClickWithScroll(null,null,null,null);
			//     }else
			//     if(thisElementIsClicked){
			//         clickFeature.applyClickWithScroll(null,null,null,null);
			//     }else{
			//         clickFeature.applyClickWithScroll(data?.id,data?.stageId,data?.processId,data?.qcImage);
			//     }
			// }}
			// style={{
			// transform: `scale(${scale})`,
			// }}
			className={classes(styles?.item)}
		>
			{/* <div className={styles.Number}>
        <span>{data?.itemNo}</span>
      </div> */}
			<div className={styles.img}>
				<span className={styles.completedText}>
					{`${data?.weight ?? ''} ${
						(data?.unit === 'gram' ? 'g' : data?.unit) ?? ''
					} `}
				</span>
				<img
					style={{
						width: '100%',
						height: 'auto',
					}}
					src={data?.image}
					alt='prblm showing'
				/>
			</div>
			<div className={styles.name}>
				<span>
					{data?.name?.length > 15
						? `${data?.name?.substring(0, 15)}..`
						: data?.name}
				</span>
			</div>
		</div>
	);
}

const minScale = 0.9;
const maxScale = 1.1;

function GenericItemList({ idKeyValueData, productsList }) {
	let minWeight = 0;
	let maxWeight = 0;
	const arraysData = Object.entries(idKeyValueData).map(([key, value]) => {
		return {
			...value,
			id: Number(key),
		};
	});
	arraysData?.forEach((ele) => {
		const thisWeight = Number(ele?.weight);
		if (minWeight > thisWeight) {
			minWeight = thisWeight;
		}
		if (maxWeight < thisWeight) {
			maxWeight = thisWeight;
		}
	});

	return (
		<>
			<div className={styles.root}>
				<div className={styles.list}>
					{arraysData?.map((data) => {
						const scale = map(
							data?.weight,
							minWeight,
							maxWeight,
							minScale,
							maxScale
						);
						const product = productsList?.find((prod) => {
							// eslint-disable-next-line eqeqeq
							return prod?.id == data?.id;
						});
						return buildItem(
							{
								...data,
								image: product?.productImageUrl,
								name: product?.productName,
							},
							scale
						);
					})}
				</div>
			</div>
			<div className={styles.arrow}>
				<ExpandMoreIcon
					sx={{
						fontSize: 20,
					}}
					color='white'
				/>
			</div>
		</>
	);
}

export default React.memo(GenericItemList);
