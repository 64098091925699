// eslint-disable-next-line object-curly-newline
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import { modalActions } from '../../../Actions';
import BaseModal from '../BaseModal/BaseModal';
import styles from './ImageCropModal.module.scss';
import 'react-image-crop/dist/ReactCrop.css';

const HEADING = 'Crop and Upload Image';

const ImageCropModal = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { show, data, toggleModal, aspectRatio, localShow } = props;
	// eslint-disable-next-line no-debugger

	const [crop, setCrop] = useState({
		unit: '%',
		width: 50,
		x: 25,
		y: 25,
		aspect: aspectRatio.split(':')[0] / aspectRatio.split(':')[1],
	});

	const [upImg, setUpImg] = useState();
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const [completedCrop, setCompletedCrop] = useState(null);

	useEffect(() => {
		if (data.file) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setUpImg(reader.result);
			});
			reader.readAsDataURL(data.file);
		}
	}, [data.file]);

	const handleClose = () => {
		toggleModal(false);
		setUpImg(null);
		props.handleClose();
	};
	const handleCrop = (newCrop) => {
		setCrop(newCrop);
	};

	const handleComplete = (c) => {
		setCompletedCrop(c);
	};

	const handleFileInput = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setUpImg(reader.result);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const tempCrop = completedCrop;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = tempCrop.width * pixelRatio * scaleX;
		canvas.height = tempCrop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			tempCrop.x * scaleX,
			tempCrop.y * scaleY,
			tempCrop.width * scaleX,
			tempCrop.height * scaleY,
			0,
			0,
			tempCrop.width * scaleX,
			tempCrop.height * scaleY
		);
	}, [completedCrop]);

	const handleConfirm = () => {
		const canvas = previewCanvasRef.current;
		props.handleConfirm(data.name, canvas.toDataURL('image/jpeg'));
		handleClose();
	};

	const isModalVisible = () => {
		if (localShow !== null) {
			return localShow;
		}
		return show;
	};

	return (
		<BaseModal
			open={isModalVisible()}
			heading={HEADING}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
			height='auto'
		>
			<div className={styles.root}>
				<div className={`${styles.og} ${styles['img-container']}`}>
					<ReactCrop
						src={upImg}
						crop={crop}
						onImageLoaded={onLoad}
						onChange={handleCrop}
						onComplete={handleComplete}
					/>
				</div>
				<p>{`Recommended Image Aspect Ratio is ${aspectRatio}`}</p>
				<label className={`${styles.label}`} htmlFor='displayPicModal'>
					Choose another image
				</label>
				<input
					type='file'
					id='displayPicModal'
					accept='image/png, image/jpg, image/jpeg, image/webp'
					name='image'
					onChange={handleFileInput}
					style={{
						opacity: 0,
						width: 1,
						height: 1,
					}}
				/>
				<div className={`${styles.cropped} ${styles['img-container']}`}>
					<canvas
						className={styles.canvas}
						ref={previewCanvasRef}
						style={{
							width: Math.round(completedCrop?.width ?? 0),
							height: Math.round(completedCrop?.height ?? 0),
						}}
					/>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.imageCrop;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleImageCrop,
};

ImageCropModal.defaultProps = {
	aspectRatio: '4:3',
	localShow: null,
	handleClose: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropModal);
