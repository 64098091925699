import { stateCityConstants } from '../Constants';
import { stateCityServices } from '../Services';

const stateCityActions = {
	fetchStateCity,
};

function fetchStateCity(
	req = {
		id: '',
	}
) {
	return (dispatch) => {
		dispatch(request());
		stateCityServices.getStateCity(req).then(
			(res) => {
				const { data } = res;
				dispatch(success(data));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: stateCityConstants.FETCH_STATECITY_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: stateCityConstants.FETCH_STATECITY_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: stateCityConstants.FETCH_STATECITY_FAILURE,
			payload,
		};
	}
}

export default stateCityActions;
