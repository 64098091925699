import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import {
	ProductCategoryViewPage,
	ProductCategoryCreatePage,
	ProductCategoryListPage,
} from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const ProductCategoryRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`,
			name: 'Product Category',
		},
	];
	return (
		<CRUDRoutes
			entity='PRODUCT CATEGORY'
			path={path}
			listPage={ProductCategoryListPage}
			viewPage={<ProductCategoryViewPage breadcrumbs={breadcrumbs} />}
			createPage={<ProductCategoryCreatePage breadcrumbs={breadcrumbs} />}
			editPage={
				<ProductCategoryCreatePage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default ProductCategoryRoutes;
