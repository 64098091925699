import { cloneDeep, update } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { GeneralDetails } from '../../../Components/Forms/Customizations';
import { CustomizationDetails } from '../../../Components/Forms/Customizations/CustomizationDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import ProductVariantsModal from '../../../Components/Modals/ProductVariantsModal/ProductVariantsModal';
import { urls } from '../../../Constants';
import { inputHelper, isEmpty } from '../../../Helpers';
import customizationConfig from './customization.config';

const CustomizationCreatePage = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [state, setState] = useState(
		cloneDeep(customizationConfig.initialState)
	);

	const { fields, errors } = state;

	const {
		fetchCustomization,
		productsMap,
		data,
		isEdit,
		saveCustomization,
		breadcrumbs,
	} = props;

	const history = useHistory();
	const { id } = useParams();

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setState(customizationConfig.serializer(state, data));
			} else {
				fetchCustomization({
					entity: 'CUSTOMIZATIONS',
					req: {
						params: {
							productId: id,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			// if data is submitted once, do not reset state with deserializer
			if (!isEmpty(data) && state.numberOfSubmissions === 0) {
				setState(customizationConfig.serializer(state, data));
			}
		}
	}, [data]);

	const addNewGroup = () => {
		let newLen = 0;
		let newState = update(
			cloneDeep(state),
			'fields.customizations.order',
			(arr) => {
				if (arr.length > 0) {
					const len = Math.max(...arr) + 1;
					arr.push(len);
					newLen = len;
				} else {
					const len = 1;
					arr.push(len);
					newLen = len;
				}
				return arr;
			}
		);
		newState = update(newState, 'fields.customizations.items', (obj) => {
			const newObj = {
				...obj,
			};
			newObj[newLen] = cloneDeep(customizationConfig.newGroupObject);
			newObj[newLen].applicableOn = fields.product.variants.map(
				(variant) => {
					return {
						id: variant.id,
						name: variant.displayName,
						size: variant.size,
						isApplied: false,
					};
				}
			);
			return newObj;
		});
		setState(newState);
	};

	const addSKUs = ({ productId, variantId, price, groupId }) => {
		const { applicableOn } = fields.customizations.items[groupId];
		const sku = productsMap[productId];
		const finalObj = {
			id: null,
			skuId: productId,
			variantId,
			isDefault: false,
			unit: sku.unit,
			variants: applicableOn.map((variant) => {
				return {
					qty: 1,
					price,
					id: variant.id,
				};
			}),
		};
		setState(
			update(
				cloneDeep(state),
				`fields.customizations.items[${groupId}].skus`,
				(skusArr) => {
					skusArr.push(finalObj);
					return skusArr;
				}
			)
		);
	};

	const handleReorderGroups = (dragIndex, hoverIndex) => {
		const newState = update(
			cloneDeep(state),
			'fields.customizations.order',
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};

	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'comboName':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'veg':
				newState[fieldName] = fieldValue === '1';
				break;
			default:
				break;
		}

		switch (dataset.type) {
			case 'group':
				if (fieldName === 'isApplied') {
					setState(
						update(
							cloneDeep(state),
							`fields.customizations.items[${dataset.parent}].applicableOn[${dataset.id}]`,
							(applicableOnObj) => {
								const newApplicableOn =
									cloneDeep(applicableOnObj);
								newApplicableOn.isApplied = fieldValue;
								return newApplicableOn;
							}
						)
					);
				} else {
					setState(
						update(
							cloneDeep(state),
							`fields.customizations.items[${dataset.id}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						)
					);
				}
				break;
			case 'sku':
				if (dataset.variant) {
					setState(
						update(
							cloneDeep(state),
							`fields.customizations.items[${dataset.parent}].skus[${dataset.id}].variants[${dataset.variant}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						)
					);
				} else {
					setState(
						update(
							cloneDeep(state),
							`fields.customizations.items[${dataset.parent}].skus[${dataset.id}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						)
					);
				}
				break;
			default:
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue,
						...newState,
					},
					...errors,
				});
		}
	};

	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		const clonedState = cloneDeep(state);
		let newState = {};
		switch (dataset.type) {
			case 'sku':
				newState = update(
					clonedState,
					`fields.customizations.items[${dataset.parent}].skus`,
					(skusArr) => {
						skusArr.splice(dataset.id, 1);
						return skusArr;
					}
				);
				setState(newState);
				break;
			case 'group':
				newState = update(
					clonedState,
					'fields.customizations.items',
					(customizationObj) => {
						const newCustomizationObj = cloneDeep(customizationObj);
						delete newCustomizationObj[dataset.id];
						return newCustomizationObj;
					}
				);
				newState = update(
					newState,
					'fields.customizations.order',
					(arr) => {
						return arr.filter((custmizationIdx) => {
							return custmizationIdx !== parseInt(dataset.id, 10);
						});
					}
				);
				setState(newState);
				break;
			default:
				break;
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		const isValid = customizationConfig.validator(state);
		if (isValid) {
			req.data = customizationConfig.deserializer(state);
			req.params = {
				productId: state.fields.product.id,
			};
			setState({
				...state,
				numberOfSubmissions: state.numberOfSubmissions + 1,
			});
			saveCustomization({
				entity: 'CUSTOMIZATIONS',
				req,
				method: 'UPDATE',
				redirect: `${urls.EXTENSION}${urls.CUSTOMIZATIONS}`,
			});
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.CUSTOMIZATIONS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.CUSTOMIZATIONS}`);
		}
	};

	const productVariantsGroups = useMemo(() => {
		return fields.customizations.order.reduce(
			(accCustomizations, currCustomizationId) => {
				const customizationObj =
					fields.customizations.items[currCustomizationId];
				// eslint-disable-next-line no-param-reassign
				accCustomizations[currCustomizationId] = {
					choices: customizationObj.skus.map((skuObj) => {
						return {
							productId: skuObj.skuId,
							variantId: skuObj.variantId,
						};
					}),
				};
				return accCustomizations;
			},
			{}
		);
	}, [fields.customizations.items]);

	return (
		<div>
			<ProductVariantsModal
				groups={productVariantsGroups}
				handleConfirm={addSKUs}
			/>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Edit Customization'
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${fields.product.productName}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Product Details'
				>
					<GeneralDetails fields={fields} />
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Enter all the product customization data'
					sectionName='Customization Details'
				>
					<CustomizationDetails
						customizations={fields.customizations}
						mandatoryFields={customizationConfig.mandatoryFields}
						handleReorder={handleReorderGroups}
						handleInput={handleInput}
						handleDelete={handleDelete}
						handleAddNewGroup={addNewGroup}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.CUSTOMIZATIONS.data.VIEW;
	const productsMap = state.product.products;

	return {
		data,
		productsMap,
	};
};

const mapDispatchToProps = {
	toggleSKUs: modalActions.toggleSKUs,
	fetchCustomization: formActions.gateway,
	saveCustomization: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizationCreatePage);
