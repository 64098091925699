import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './RegionMenuModal.module.scss';
import MultiCascaderTree from '../../MultiCascaderTree/MultiCascaderTree';
import DescriptionBox from '../../DescriptionBox/DescriptionBox';

const HEADING = 'Define Regions';

const regions = [
	{
		label: 'All Region',
		color: '#94a3b8',
	},
	{
		label: 'Region',
		color: '#facc15',
	},
	{
		label: 'State',
		color: '#008080',
	},
	{
		label: 'City',
		color: '#FF7F50',
	},
	{
		label: 'Store',
		color: '#2dd4bf',
	},
];

const descriptionDataStatus = [
	{
		component: (
			<span
				style={{
					alignItems: 'center',
					display: 'flex',
					height: '100%',
				}}
			>
				<div
					style={{
						color: 'white',
						backgroundColor: 'green',
						margin: '3px',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						width: '10px',
						height: '10px',
						borderRadius: '50%',
					}}
				>
					{' '}
				</div>
			</span>
		),
		label: 'Area is selected heirarchical',
	},
	{
		component: (
			<span
				style={{
					alignItems: 'center',
					display: 'flex',
					height: '100%',
				}}
			>
				<div
					style={{
						color: 'white',
						backgroundColor: 'red',
						margin: '3px',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						width: '10px',
						height: '10px',
						borderRadius: '50%',
					}}
				>
					{' '}
				</div>
			</span>
		),
		label: 'Area is not selected heirarchical',
	},
];

const RegionMenuModal = (props) => {
	const { show, data, toggleModal, handleConfirm } = props;
	const { descriptionData = [], tableState = [] } = data;
	const [inputState, setInputState] = useState([]);
	const [expandedIds, setExpandedIds] = useState({});

	useEffect(() => {
		if (show) {
			const dummyData = cloneDeep(tableState);
			setInputState(dummyData ?? []);
		}
	}, [show, tableState]);

	const onConfirm = () => {
		handleConfirm(inputState);
		toggleModal(false);
	};

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={HEADING}
			setExpandedIds={setExpandedIds}
			expandedIds={expandedIds}
			customStyles={{
				overflow: 'hidden',
			}}
			width='80%'
			height='98%'
			open={show}
			handleClose={() => {
				toggleModal(false, {});
				setInputState([]);
			}}
			handleConfirm={onConfirm}
		>
			<DescriptionBox data={[...descriptionData, ...descriptionDataStatus]} />
			<div className={`${styles.root}`}>
				<MultiCascaderTree
					inputState={inputState}
					setInputState={setInputState}
					headers={regions}
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.regionMenuModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleRegionModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionMenuModal);
