import { Crop } from '@mui/icons-material';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
// eslint-disable-next-line object-curly-newline
import {
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper,
} from '../../../Helpers';
import { ImageCropModal } from '../../Modals/ImageCropModal';
import EnableDisable from '../EnableDisable/EnableDisable';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		handleImageInput,
		isDisabled,
		toggleImageCrop,
	} = props;

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='stationName'
						data-mandatory={mandatoryFields.name}
					>
						Name
					</label>
					<input
						className='form-value'
						type='text'
						id='stationName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				{/* Description */}
				<div className='form-field row'>
					<EnableDisable
						name='cameraEnabled'
						text='Enable Camera'
						isEnabled={fields.cameraEnabled}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'cameraEnabled',
						}}
					/>
					<span className='form-error'>{errors.cameraEnabled}</span>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* display image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.image}
					>
						Display Image
					</label>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={
									isDisabled
										? fields.image
										: generateImageUrl(fields.image)
								}
								className={`display-pic ${
									fields.image ? 'show' : 'hide'
								}`}
								alt='station-display'
							/>
							{generateImageEditButton({
								isEdit:
									fields.image &&
									fields.image.includes(
										'data:image/jpeg;base64'
									),
								button: (
									<button
										type='button'
										onClick={() => {
											openImageCropModal('image');
										}}
										className='display-pic-edit'
									>
										<Crop />
									</button>
								),
							})}
						</div>
						{generateImageUploadButton({
							isDisabled,
							label: (
								<label
									className='display-pic-label'
									htmlFor='displayPic'
								>
									Choose an image
								</label>
							),
						})}
						<input
							type='file'
							id='displayPic'
							accept='image/png, image/jpg, image/jpeg, image/webp'
							name='image'
							onChange={handleImageSelect}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
						/>
					</div>
				</div>
				<ImageCropModal handleConfirm={handleImageInput} />
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = () => {};

const mapDispatchToProps = {
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
