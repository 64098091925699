import { cloneDeep, update } from 'lodash';
import { PRODUCT_CLASSES } from '../../../Constants/product.constants';

const qualityConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			brand: null,
			image: null,
			productClass: PRODUCT_CLASSES.map((productClass) => {
				return {
					...productClass,
					isApplied: false,
				};
			}),
			QMSEnabled: false,
			weightCheck: false,
			tempCheck: false,
			defectCheck: false,
			variants: {
				items: {},
				order: [],
			},
			defectParams: {
				areaSelection: false,
				countSelection: false,
				areaThreshold: 0.1,
				countThreshold: 1,
				andCondition: false,
			},
		},
	},
	mandatoryFields: {
		minTemp: true,
		maxTemp: true,
		minWeight: true,
		maxWeight: true,
		areaThreshold: true,
		countThreshold: true,
	},
	serializer: (state, data) => {
		return update(cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.productName,
				description: data.productDescription,
				brand: data.brandId,
				image: data.productImageUrl,
				productClass: PRODUCT_CLASSES.map((productClass) => {
					let isApplied = false;
					if (data.skuType.toLowerCase() === productClass.id) {
						isApplied = true;
					}
					return {
						...productClass,
						isApplied,
					};
				}),
				weightCheck: data.weightCheckRequired,
				tempCheck: data.tempCheckRequired,
				defectCheck: (data.defectConfig ? data.defectConfig.applied : false),
				/* might have some issue here for defect */

				tempParams: {
					minTemp: data.minTemp,
					maxTemp: data.maxTemp,
				},
				defectParams: {
					areaSelection: (data.defectConfig ? data.defectConfig.areaSelection : false),
					countSelection: (data.defectConfig ? data.defectConfig.countSelection : false),
					areaThreshold: (data.defectConfig ? data.defectConfig.areaThreshold : 0.1),
					countThreshold: (data.defectConfig ? data.defectConfig.countThreshold : 1),
					andCondition: (data.defectConfig ? data.defectConfig.andCondition : false),
				},
				QMSEnabled: data.isQCEnabled,

				variants: {
					items: Object.keys(data.ProductVariant).reduce(
						(accVariants, currVariantId, idx) => {
							const currVariant =
								data.ProductVariant[currVariantId];
							// eslint-disable-next-line no-param-reassign
							accVariants[idx] = {
								id: currVariant.id,
								name: currVariant.displayName,
								weightCheck: {
									minWeight: currVariant.minWeight,
									maxWeight: currVariant.maxWeight,
								},
							};
							return accVariants;
						},
						{}
					),
					order: Object.keys(data.ProductVariant).map(
						(variant, idx) => {
							return idx;
						}
					),
				},
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = update(cloneDeep(state), 'fields', (newFields) => {
			const { minTemp, maxTemp } = newFields.tempParams;
			const {
				areaSelection,
				countSelection,
				areaThreshold,
				countThreshold,
				andCondition,
			} = newFields.defectParams;
			return {
				QMSEnabled: newFields.QMSEnabled,
				tempCheck: {
					applied: newFields.tempCheck,
					minTemp,
					maxTemp,
				},
				weightCheck: newFields.weightCheck,
				variants: newFields.variants.order.map((variantId) => {
					const variant = newFields.variants.items[variantId];
					const { minWeight, maxWeight } = variant.weightCheck;
					return {
						id: variant.id,
						weightCheck: {
							minWeight,
							maxWeight,
							idealWeight: variant.weightCheck.idealWeight,
						},
					};
				}),
				defectConfig: {
					applied: newFields.defectCheck,
					areaSelection: (newFields.defectCheck ? areaSelection : false),
					countSelection: (newFields.defectCheck ? countSelection : false),
					areaThreshold: (newFields.defectCheck ? parseFloat(areaThreshold) : 0.1),
					countThreshold: (newFields.defectCheck ? parseFloat(countThreshold) : 1),
					andCondition: (andCondition === 'true'),
				},
			};
		});
		return new Promise((resolve) => {
			resolve({
				data: {
					...newState.fields,
				},
			});
		});
	},
	validator: (state) => {
		const valid = (
			!(state?.fields?.defectCheck) ||
			(state?.fields?.defectCheck &&
			(state?.fields?.defectParams?.areaSelection ||
				state?.fields?.defectParams?.countSelection))
		);
		return valid;
	},
};

export default qualityConfig;
