import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import {
	getStateCodeFromGSTIN,
	isObject,
	paymentModes,
} from '../../../Helpers';

const inputsList = [
	{
		value: null,
		width: '250px',
		handleSelection: () => {},
		id: 'vendor',
		name: 'vendor',
		inputLabel: 'Select Vendor',
		labelText: 'Vendor Name',
		placeholder: 'Select A Vendor',
		mandatory: true,
		optionsList: [],
		inputType: 'options_search',
		hideAddNew: true,
	},
	{
		value: null,
		width: '250px',
		handleSelection: () => {},
		id: 'store',
		name: 'store',
		inputLabel: 'Select Store',
		placeholder: 'Select A Store',
		labelText: 'Store',
		mandatory: true,
		optionsList: [],
		inputType: 'options_search',
		hideAddNew: true,
	},
	{
		value: null,
		onChange: () => {},
		id: 'clientTransactionId',
		name: 'clientTransactionId',
		labelText: 'Utility ID',
		mandatory: true,
		width: '250px',
		prefix: 'UT-',
		type: 'text',
	},
	[
		{
			value: null,
			onChange: () => {},
			id: 'periodFrom',
			name: 'periodFrom',
			labelText: 'Period From',
			mandatory: true,
			inputType: 'dateSelector',
			width: '250px',
		},
		{
			value: null,
			onChange: () => {},
			id: 'periodTo',
			name: 'periodTo',
			labelText: 'Period To',
			mandatory: true,
			inputType: 'dateSelector',
			width: '250px',
		},
	],
	{
		value: null,
		onChange: () => {},
		id: 'utilityCategory',
		name: 'utilityCategory',
		labelText: 'Select Category',
		inputLabel: 'Category',
		mandatory: true,
		width: '250px',
		inputType: 'options',
		optionsList: [],
	},
	{
		value: null,
		onChange: () => {},
		id: 'taxSlab',
		name: 'taxSlab',
		labelText: 'Tax slab',
		inputLabel: 'Select tax slab',
		mandatory: true,
		width: '250px',
		inputType: 'options',
		optionsList: [],
	},
	{
		value: null,
		onChange: () => {},
		id: 'amount',
		name: 'amount',
		labelText: 'Amount Inc. Tax \u20B9',
		mandatory: true,
		// type: 'number',
		width: '250px',
	},
	[
		{
			value: null,
			onChange: () => {},
			id: 'billDate',
			name: 'billDate',
			labelText: 'Bill Date',
			mandatory: true,
			inputType: 'dateSelector',
			cleanable: false,
			width: '250px',
		},
		{
			value: null,
			onChange: () => {},
			id: 'dueDate',
			name: 'dueDate',
			labelText: 'Due Date',
			mandatory: true,
			inputType: 'dateSelector',
			cleanable: false,
			width: '250px',
		},
	],
	[
		{
			value: null,
			onChange: () => {},
			id: 'billNo',
			name: 'billNo',
			labelText: 'Bill No',
			mandatory: true,
			width: '250px',
		},
		{
			value: {},
			id: 'uploadedDoc',
			name: 'uploadedDoc',
			labelText: 'Upload Ref. Doc',
			mandatory: true,
			// width: '250px',
			inputType: 'docUploader',
		},
	],
	{
		inputType: 'customComponent',
		component: null,
	},
];

const initialState = {
	vendor: {},
	store: {},
	periodFrom: new Date(),
	periodTo: new Date(),
	category: '',
	amount: '',
	clientTransactionId: '',
	billDate: new Date(),
	dueDate: new Date(),
	billNo: '',
	taxSlab: null,
	uploadedDoc: {},
	alreadyPaid: {
		isPaid: null,
		paymentMode: paymentModes[0],
		uploadedDocFileName: '',
		uploadedDocFile: '',
		transactionRefNumber: '',
	},
};

const reducer = (state, action) => {
	switch (action?.type) {
		case 'SET_VENDOR':
			return {
				...state,
				vendor: action?.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action?.payload,
			};
		case 'SET_PERIOD_FROM':
			return {
				...state,
				periodFrom: action?.payload,
			};
		case 'SET_PERIOD_TO':
			return {
				...state,
				periodTo: action?.payload,
			};
		case 'SET_CATEGORY':
			return {
				...state,
				category: action?.payload,
			};
		case 'SET_TAX_SLAB':
			return {
				...state,
				taxSlab: action?.payload,
			};
		case 'SET_AMOUNT':
			return {
				...state,
				amount: action?.payload,
			};
		case 'SET_BILL_DATE':
			return {
				...state,
				billDate: action?.payload,
			};
		case 'SET_DUE_DATE':
			return {
				...state,
				dueDate: action?.payload,
			};
		case 'SET_BILL_NO':
			return {
				...state,
				billNo: action?.payload,
			};
		case 'SET_UPLOADED_DOC':
			return {
				...state,
				uploadedDoc: action?.payload,
			};
		case 'SET_UTILITY_ID':
			return {
				...state,
				clientTransactionId: action?.payload,
			};
		case 'SET_ALREADY_PAID':
			return {
				...state,
				alreadyPaid: action?.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const deserializer = (state) => {
	const clonedState = cloneDeep(state);
	const { ...remaining } = clonedState;
	const vendorGst = remaining?.vendor?.gstin;
	const storeGst = remaining?.store?.gstNumber;
	const isSameState =
		getStateCodeFromGSTIN(vendorGst) === getStateCodeFromGSTIN(storeGst);
	const isMandatoryTaxSlab = vendorGst?.length > 0 && storeGst?.length > 0;
	const newState = {
		transactionDate: moment(remaining?.billDate)?.format('M/D/YYYY'),
		dueDate: moment(remaining?.dueDate)?.format('M/D/YYYY'),
		storeId: remaining?.store?.id,
		storeName: remaining?.store?.name,
		vendorId: remaining?.vendor?.id,
		vendorName: remaining?.vendor?.name,
		category: remaining?.category?.value,
		type: 'BILL',
		subType: 'UTILITIES',
		finalTransactionAmount: remaining?.amount,
		uploadedDoc: remaining?.uploadedDoc?.file,
		clientTransactionId: `UT-${remaining?.clientTransactionId}`,
		transactionStatus: 'APPROVAL_PENDING_MANAGER',
		paymentDoc: remaining?.alreadyPaid?.uploadedDocFile,
		extraData: {
			periodFrom: moment(remaining?.periodFrom)?.format('M/D/YYYY'),
			periodTo: moment(remaining?.periodTo)?.format('M/D/YYYY'),
			billNumber: remaining?.billNo,
			...(remaining?.taxSlab &&
				isMandatoryTaxSlab && {
				taxSlab: remaining?.taxSlab,
				isSameState,
			}),
			paymentReferenceNumber:
				remaining?.alreadyPaid?.transactionRefNumber,
			paymentMode: remaining?.alreadyPaid?.paymentMode,
			isPaid: remaining?.alreadyPaid?.isPaid,
			uploadedDocFileName: remaining?.alreadyPaid?.uploadedDocFileName,
		},
	};
	return newState;
};

const utilitiesCreateConfig = {
	inputsList,
	initialState,
	reducer,
	deserializer,
	validator: (state) => {
		const cloned = cloneDeep(state);

		const vendorGst = cloned?.vendor?.gstin;
		const storeGst = cloned?.store?.gstNumber;
		const isMandatoryTaxSlab =
			vendorGst?.length > 0 && storeGst?.length > 0;

		const mandatoryFields = [
			'vendor',
			'store',
			'periodFrom',
			'periodTo',
			'category',
			'amount',
			'billDate',
			'dueDate',
			'clientTransactionId',
			'uploadedDoc',
			...(isMandatoryTaxSlab ? ['taxSlab'] : []),
			'alreadyPaid',
			'billNo',
		];
		return mandatoryFields.some((field) => {
			const value = cloned[field];
			if (field === 'alreadyPaid') {
				if (value?.isPaid) {
					if (!value?.paymentMode) {
						return true;
					}
					// cash
					if (value?.paymentMode === paymentModes[1]) {
						return false;
					}
					if (!value?.transactionRefNumber) {
						return true;
					}
					if (!value?.uploadedDocFile) {
						return true;
					}
				} else if (value?.isPaid === null) {
					return true;
				}
			}
			if (field === 'uploadedDoc' && !value?.file) return true;
			if (value instanceof Date) return false;
			if (
				typeof value === 'undefined' ||
				value === null ||
				value === '' ||
				value === '0' ||
				value === 0 ||
				(isObject(value) && isEmpty(value))
			) {
				return true;
				// eslint-disable-next-line no-prototype-builtins
			}
			if (
				isObject(value) &&
				// eslint-disable-next-line no-prototype-builtins
				value?.hasOwnProperty('id') &&
				value?.id !== null
			) {
				return false;
			}
			return false;
		});
	},
};

export default utilitiesCreateConfig;
