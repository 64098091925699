/* eslint-disable linebreak-style */
import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import { InventorySkuCreatePage, InventorySkuListPage } from '../../../Pages';
import InventorySkuViewPage from '../../../Pages/Inventory/Inventory SKU/InventorySkuViewPage';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';
// eslint-disable-next-line object-curly-newline

const InventorySkuRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.INVENTORY_SKU}`,
			name: 'Inventory SKU',
		},
	];
	return (
		<CRUDRoutes
			entity='INVENTORY SKU'
			path={path}
			listPage={InventorySkuListPage}
			viewPage={<InventorySkuViewPage breadcrumbs={breadcrumbs} />}
			createPage={<InventorySkuCreatePage breadcrumbs={breadcrumbs} />}
			editPage={
				<InventorySkuCreatePage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default InventorySkuRoutes;
