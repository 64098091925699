/* eslint-disable object-curly-newline */
import { connect } from 'react-redux';
import { Button, MenuItem, TextField } from '@mui/material';
import { useReducer, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import InputPicker from 'rsuite/InputPicker';
import styles from './AddRevenuePage.module.scss';
import { BackdropLoader } from '../../../../Components/Loaders';
import config from './addRevenuePage.config';
import { inputHelper } from '../../../../Helpers';
import { notificationServices } from '../../../../Services';
import { CreatePageHeader } from '../../../../Components/Headers';
import { formActions, modalActions } from '../../../../Actions';
import DatePickerCustom from '../../../../Components/DatePickerCustom/DatePickerCustom';
import storeServices from '../../../../Services/Form Services/store.services';
import { AlertModal } from '../../../../Components/Modals';

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		inputLabel,
		labelText,
		mandatory,
		optionsList,
		disabled,
		search = false,
		handleSelect,
	} = props;
	return (
		<div className={`${styles.tile} form-field row`}>
			<label
				className={`${mandatory ? styles.mandatory : ''} form-label`}
				htmlFor={id}
			>
				{labelText}
			</label>
			{!search ? (
				<TextField
					id={id}
					select
					name={name}
					value={value}
					onChange={onChange}
					label={inputLabel}
					disabled={disabled}
					sx={{
						width: 200,
						'.MuiInputBase-root': {
							borderRadius: '4px',
						},
					// '.MuiInputBase-input': {
					// // paddingTop: '6px',
					// // paddingBottom: '6px',
					// },
					}}
				>
					{optionsList?.map((option) => {
						return (
							<MenuItem
								key={option?.id ?? option}
								value={option?.id ?? option}
							>
								{option?.title ?? option}
							</MenuItem>
						);
					})}
				</TextField>
			) : (
				<InputPicker
					data={optionsList}
					name={name}
					onSelect={(val) => handleSelect(val)}
					style={{
						width: 224,
					}}
				/>
			)}
		</div>
	);
};

function AddRevenuePage(props) {
	const {
		storesList,
		fetchStoresList,
		processTypes,
		toggleAlert,
	} = props;
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const {
		store,
		date,
		amount,
	} = state;
	// const params = useQuery();
	const history = useHistory();
	// const id = parseInt(params.get('id'), 10);
	// const isEdit = Boolean(params.get('isEdit'));
	const [isLoading, setIsloading] = useState(false);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					type: 'RETAIL,BASE',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleChange = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[fieldName]: fieldValue,
			},
		});
	};

	const STORES_LIST = useMemo(() => {
		const tempData = storesList?.map((ele) => {
			return {
				value: ele?.id,
				label: ele?.name,
			};
		});
		return tempData;
	}, [processTypes]);

	const handleSubmit = async (update) => {
		const isValid = config?.validator(state);
		if (isValid) {
			try {
				setIsloading(true);
				const deserializedData = config?.deserializer(state);
				const req = {
					data: {
						...deserializedData,
						update,
					},
				};
				const response = await storeServices?.addStoreRevenue(req);
				if (response?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
					dispatch({
						type: 'INIT',
					});
				} else if (response?.statusCode === 201) {
					toggleAlert(true, {
						heading: 'Revenue Already Recorded',
						handleConfirm: () => {
							handleSubmit(true);
							toggleAlert(false);
						},
						colorReverse: false,
						cancelText: 'Cancel',
						confirmText: 'Apply Update',
						// eslint-disable-next-line no-useless-escape
						message: [
							`${response?.errorMessage}!`,
							<br />,
							'Are you sure you want to Update?',
						],
						width: '30rem',
					});
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message: response?.message,
					});
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
			} finally {
				setIsloading(false);
			}
		}
	};

	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				{/* {isEdit ? (
					<Button
						onClick={() => {
							setIsEdit(false);
							dispatch({
								type: 'INIT',
							});
						}}
						variant='outlined'
						color='error'
						type='button'
					>
						Cancel
					</Button>
				) : (
					<Button onClick={handleEditUser} variant='outlined' color='primary' type='button'>
						Edit Logged In
					</Button>
				)} */}
				<Button
					onClick={() => {
						handleSubmit();
					}}
					variant='contained'
					color='primary'
					type='button'
				>
					Save
				</Button>
			</div>
		);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} />
			<AlertModal />
			<CreatePageHeader
				handleSubmit={handleSubmit}
				showCancel={false}
				heading='Add Revenue'
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className={styles.body}>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Amount*
					</label>
					<TextField
						id='amount'
						name='amount'
						value={amount}
						type='number'
						step={0.001}
						onChange={handleChange}
						placeholder='Enter Amount'
						sx={{
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Date*
					</label>
					<DatePickerCustom
						selectedDate={date}
						selectDate={(rsuiteDate) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									date: rsuiteDate,
								},
							});
						}}
						style={{
							width: 225,
						}}
						oneTap
					/>
				</div>
				<LabelledInput
					mandatory
					id='store'
					name='store'
					value={store}
					onChange={handleChange}
					labelText='Select Store*'
					optionsList={STORES_LIST}
					inputLabel='Select Store'
					search
					handleSelect={(val) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								store: val,
							},
						});
					}}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const storesList = state?.form?.STORES?.data?.LIST;

	return {
		userPermissions,
		storesList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAuditItemsModal,
	fetchStoresList: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRevenuePage);
