import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const allProductServices = {
	gateway,
	createProduct,
	viewProduct,
	patchProduct,
	updateProduct,
	exportListProduct,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createProduct({
				req,
			});
		case 'VIEW':
			return viewProduct({
				req,
			});
		case 'LIST':
			return listProduct({
				req,
			});
		case 'UPDATE':
			return updateProduct({
				req,
			});
		case 'PATCH':
			return patchProduct({
				req,
			});
		case 'DELETE':
			return deleteProduct({
				req,
			});
		case 'EXPORT_LIST':
			return exportListProduct({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createProduct({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ALL_PRODUCTS, requestOptions).then(handleResponse);
}
function patchProduct({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function updateProduct({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewProduct({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listProduct({ req }) {
	const params = {
		...req.params,
	};

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);

	return fetch(url, requestOptions).then(handleResponse);
}
function exportListProduct({ req }) {
	const params = {
		...req.params,
		export: 'True',
	};

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);

	return fetch(url, requestOptions);
}

function deleteProduct({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);
	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
export default allProductServices;
