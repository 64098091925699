import { useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import CheckList from '../../CheckList/CheckList';
import styles from './ApplicabilityModal.module.scss';

const initialState = {
	selectedList: [],
	query: '',
};
const HEADING = 'Select Stores';
const ApplicableStoresModal = (props) => {
	const { toggleApplicableStoresModal, show, stores, selectedStoreList } =
		props;
	const [state, setState] = useState(cloneDeep(initialState));
	const { selectedList } = state;
	const handleClose = () => {
		setState({
			...state,
			selectedList: [],
			query: '',
		});
		toggleApplicableStoresModal(false);
	};
	const handleClick = (idx) => {
		if (selectedList.includes(idx)) {
			const newSelectedList = selectedList.filter((id) => {
				return id !== idx;
			});
			setState({
				...state,
				selectedList: newSelectedList,
			});
		} else {
			const newSelectedList = selectedList;
			newSelectedList.push(idx);
			setState({
				...state,
				selectedList: newSelectedList,
			});
		}
	};

	const handleConfirm = () => {
		const ids = [];
		for (let idx = 0; idx < selectedList.length; idx++) {
			ids.push(stores[selectedList[idx]]);
		}
		const list = stores.filter((obj) => {
			return selectedList.includes(obj.id);
		});
		props.handleConfirm(list, 'applicableStores');
		toggleApplicableStoresModal(false);
		setState({
			...state,
			selectedList: [],
		});
	};
	const handleSearchInput = (queryString) => {
		setState({
			...state,
			query: queryString,
		});
	};

	const generateList = () => {
		return stores
			.filter((obj) => {
				return (
					!selectedStoreList.find((selectedStore) => {
						return selectedStore.id === parseInt(obj.id, 10);
					}) &&
					obj.name
						.toLowerCase()
						.indexOf(state.query.trim().toLowerCase()) !== -1
				);
			})
			.map((obj) => {
				const name = obj.name ? `${obj.name}` : '';
				return {
					id: parseInt(obj.id, 10),
					title: name,
				};
			});
	};

	return (
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<CheckList
					list={generateList()}
					selectedItems={state.selectedList}
					handleItemSelect={handleClick}
					showSearch
					handleSearchInput={handleSearchInput}
					placeholder='Search Store'
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const stores = state.form.STORES.data.LIST;
	const { data, show } = state.modal.applicable_stores;
	return {
		show,
		data,
		stores,
	};
};
ApplicableStoresModal.defaultProps = {
	selectedStoreList: [],
	show: false,
};

const mapDispatchToProps = {
	toggleApplicableStoresModal: modalActions.toggleApplicableStoresModal,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ApplicableStoresModal);
