import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const cookingStationsServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewStation({
				req,
			});
		case 'LIST':
			return listStation({
				req,
			});
		case 'UPDATE':
			return updateStation({
				req,
			});
		case 'PATCH':
			return patchStation({
				req,
			});
		case 'DELETE':
			return deleteStation({
				req,
			});
		case 'CREATE':
			return createStation({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function createStation({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.COOKING_STATIONS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function deleteStation({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.COOKING_STATIONS, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function updateStation({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.COOKING_STATIONS, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function patchStation({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.COOKING_STATIONS, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewStation({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.COOKING_STATIONS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listStation({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.COOKING_STATIONS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default cookingStationsServices;
