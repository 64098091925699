const addChildConfig = {
	initialState: {
		name: '',
		class: '',
		section: '',
		parentNumber: '',
		email: '',
		school: '',
	},
	deserialize: (state) => {
		return {
			addressLine1: state?.name,
			addressLine2: state?.class,
			city: state?.section,
			email: state?.email,
			lat: null,
			long: null,
			name: state?.name,
			storeId: state?.school,
			user: state?.parentNumber,
		};
	},
};

export default addChildConfig;
