/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	useReducer, useState, useEffect, useMemo,
	useCallback
} from 'react';
import { connect } from 'react-redux';
import { Popover, TextField } from '@mui/material';
import TagPicker from 'rsuite/TagPicker';
import SelectPicker from 'rsuite/SelectPicker';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BackdropLoader } from '../../../Components/Loaders';
import { CreatePageHeader } from '../../../Components/Headers';
import styles from './shiftCreate.module.scss';
import { classes, useQuery } from '../../../Helpers';
import CustomInputSelector from '../../../Components/CustomInputSelector/CustomInputSelector';
import useFilteredStores from '../../../Helpers/useFilteredStores';
import config from './shiftCreate.config';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import { notificationServices } from '../../../Services';
import userRolesAndPermissions from '../../../Services/userRolesAndPermissions.services';
import DynamicHeaderTable from '../../../Components/DynamicHeaderTable/DynamicHeaderTable';
import processFlowServices from '../../../Services/Analytics Services/process-flow.services';
import { formActions } from '../../../Actions';
import shiftServices from '../../../Services/shift.service';

const ShiftCreate = ({
	productCategoriesList,
	fetchProductCatergoriesList,
	fetchStores,
	stores,
}) => {
	const [isLoading, setLoading] = useState(false);
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const [userRoles, setUserRoles] = useState([]);
	const [users, setUsers] = useState([]);
	const [process, setProcess] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [headers, setHeaders] = useState([
		{
			colName: 'Process',
			subCols: [],
		},
		{
			colName: 'Start time',
			id: 'startTime',
			subCols: [],
		},
		{
			colName: 'Duration',
			id: 'duration',
			subCols: [],
		},
		{
			colName: 'End time',
			id: 'endTime',
			subCols: [],
		},
		{
			colName: 'Capacity (In Kg)',
			action: true,
			actionId: 1,
			subCols: [
				{
					colName: 'Default',
					id: 'default',
					subCols: [],
				},
			],
		},
	]);

	const params = useQuery();
	const isEdit = Boolean(params.get('isEdit'));
	const id = params.get('id');

	const history = useHistory();

	useEffect(() => {
		fetchStores({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const filteredStores = useMemo(() => {
		return useFilteredStores();
	}, [stores]);

	const fetchEditData = useCallback(async () => {
		setLoading(true);
		const res = await shiftServices.getShift({
			data: {
				id,
			},
		});
		const mapDataToDurationAndActive = (resCapacity) => {
			const localRes = resCapacity;
			Object.entries(localRes)?.forEach(([key, value]) => {
				// const endTime = moment(value?.endTime, 'hh:mm A');
				// const startTime = moment(value?.startTime, 'hh:mm A');
				localRes[key] = {
					...value,
					active: !!value?.startTime,
				};
			});
			return localRes;
		};
		if (res.statusCode === 200) {
			const data = res?.data;
			const store = filteredStores.find((fstore) => {
				return fstore?.id === data?.store?.id;
			});
			const payload = {
				name: data?.shiftName,
				startDate: new Date(data?.startDate),
				endDate: new Date(data?.endDate),
				days: data?.days,
				roles: data?.roles,
				managers: data?.managers,
				capacity: mapDataToDurationAndActive(data?.capacity),
				selectedStore: store,
			};
			dispatch({
				type: 'SET_EDIT',
				payload,
			});
			const newHeaders = [
				{
					colName: 'Process',
					id: 'process',
					subCols: [],
				},
				{
					colName: 'Start time',
					id: 'startTime',
					subCols: [],
				},
				{
					colName: 'Duration',
					id: 'duration',
					subCols: [],
				},
				{
					colName: 'End time',
					id: 'endTime',
					subCols: [],
				},
				{
					colName: 'Capacity (In Kg)',
					action: true,
					subCols: [
						{
							colName: 'Default',
							id: 'default',
							subCols: [],
						},
						...(data?.exceptionCategory ?? []),
					],
				},
			];
			setHeaders(newHeaders);
		}
		setLoading(false);
	}, [filteredStores]);

	useEffect(() => {
		if (isEdit && id) {
			fetchEditData();
		}
	}, [isEdit, id]);

	useEffect(() => {
		fetchProductCatergoriesList({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const getRole = async () => {
		setLoading(true);
		try {
			const userRolesRes = await userRolesAndPermissions.getUserRoles();
			setUserRoles(userRolesRes);
		} catch (err) {
			notificationServices.generateNotification({
				type: 'error',
				message: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(getRole, []);

	async function fetchProcess() {
		try {
			const response = await processFlowServices?.fetchNodes({
				params: {
					storeId: state?.selectedStore?.id,
				},
			});
			if (response?.statusCode === 200) {
				const { nodes } = response?.data ?? {};
				const flatItem = {
					default: null,
					startTime: null,
					endTime: null,
					active: true,
				};
				const capacityObj = {};
				const rowData = nodes
					?.filter((node) => {
						return !node?.data?.nodeStore && node?.data?.type !== 'STORAGE';
					})
					?.map((node) => {
						capacityObj[node?.id] = flatItem;
						return {
							id: node?.id,
							name: node?.data?.heading,
							includeRowValidity: true,
							style: {
								display: 'flex',
								alignItems: 'center',
								jusifyContent: 'center',
								padding: '0 5px',
								gap: 2,
							},
						};
					});
				setProcess(rowData);
				if (!isEdit) {
					dispatch({
						type: 'SET_STATE',
						name: 'capacity',
						payload: capacityObj,
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	}

	useEffect(() => {
		if (state?.selectedStore?.id) {
			fetchProcess();
		} else {
			setProcess([]);
			dispatch({
				type: 'SET_STATE',
				name: 'capacity',
				payload: {},
			});
		}
	}, [state?.selectedStore?.id]);

	const fetchUsers = async (roleIds, stateId) => {
		try {
			setLoading(true);
			const response = await userRolesAndPermissions?.getUsersByRole({
				roles: roleIds,
				storeId: stateId,
			});
			if (response?.statusCode === 200) {
				setUsers(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (state?.roles && state?.selectedStore?.id) {
			fetchUsers(state?.roles, state?.selectedStore?.id);
		} else {
			setUsers([]);
		}
	}, [state?.roles, state?.selectedStore?.id]);

	const handleSave = async () => {
		const valid = config?.checkData(state);
		if (!valid) return;
		const startDate = moment(state?.startDate).format('YYYY-MM-DD');
		const endDate = moment(state?.endDate).format('YYYY-MM-DD');
		const capacity = config.handleApiCallingData(state?.capacity);
		if (!capacity) {
			return;
		}
		let response = null;
		try {
			setLoading(true);
			if (isEdit) {
				response = await shiftServices.editShift({
					data: {
						id,
						...state,
						capacity,
						startDate,
						endDate,
					},
				});
			} else {
				response = await shiftServices.createShift({
					data: {
						...state,
						capacity,
						startDate,
						endDate,
					},
				});
			}
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Successful',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const handleBackClick = () => {
		history.goBack();
	};
	const handleStoreSelection = (store) => {
		dispatch({
			type: 'SET_STORE',
			payload: store,
		});
	};

	const disabledExpiryDate = (rsuiteDate) => {
		return (
			rsuiteDate < state?.expiryDate ||
			rsuiteDate < new Date().setDate(new Date().getDate() - 1)
		);
	};

	const headerActionHelper = (item) => {
		const newHeaderToSet = {
			colName: item?.productCategoryName,
			id: item?.id,
			subcols: [],
		};
		setHeaders((prevHeader) => {
			const newHeader = [...prevHeader];
			const newCols = {
				...newHeader[4],
			};
			const newSubCols = [...newCols.subCols, newHeaderToSet];
			newCols.subCols = newSubCols;
			newHeader[4] = newCols;
			return newHeader;
		});
	};

	const updateValue = (categoryId, processId, value = '') => {
		const localCapacity = state?.capacity;
		if (categoryId === 'root') {
			localCapacity[processId] = {
				...localCapacity?.[processId],
				active: !localCapacity?.[processId]?.active,
			};
			dispatch({
				type: 'SET_STATE',
				name: 'capacity',
				payload: localCapacity,
			});
			return;
		}
		if (
			!localCapacity?.[processId]?.[categoryId] &&
			(categoryId === 'startTime' || categoryId === 'duration')
		) {
			Object.keys(localCapacity).forEach((localCapKey) => {
				localCapacity[localCapKey][categoryId] = value;
				if (
					localCapacity[localCapKey]?.startTime &&
					localCapacity[localCapKey]?.duration
				) {
					const startTime = moment(localCapacity[localCapKey]?.startTime, 'hh:mm A');
					const endTime = startTime?.add(
						localCapacity[localCapKey]?.duration,
						'hours'
					);
					localCapacity[localCapKey].endTime = moment(endTime).format('hh:mm A');
				}
			});
			dispatch({
				type: 'SET_STATE',
				name: 'capacity',
				payload: localCapacity,
			});
			return;
		}
		const prevObj = {
			...state?.capacity?.[processId],
			[categoryId]: value,
		};
		if ((categoryId === 'duration' || categoryId === 'startTime') && (prevObj?.duration && prevObj?.startTime)) {
			const startTime = moment(prevObj?.startTime, 'hh:mm A');
			const endTime = startTime?.add(
				prevObj?.duration,
				'hours'
			);
			prevObj.endTime = moment(endTime).format('hh:mm A');
		}
		const setNewObj = {
			...state?.capacity,
			[processId]: prevObj,
		};
		dispatch({
			type: 'SET_STATE',
			name: 'capacity',
			payload: setNewObj,
		});
	};

	const EditableInput = ({ colId, rowItem }) => {
		const componentRenderAccToColId = () => {
			if (colId === 'root') {
				return (
					<input
						type='checkbox'
						checked={state?.capacity?.[rowItem?.id]?.active}
						onChange={() => {
							updateValue(colId, rowItem?.id);
						}}
					/>
					// <div
					// onClick={() => {
					// }}
					// style={{
					// width: '10px', height: '10px',
					// }}
					// >
					// {rowItem?.id}
					// {state?.capacity?.[rowItem?.id]?.active ? 'active' : 'deactive'}
					// </div>
				);
			}
			if (colId === 'startTime' || colId === 'duration') {
				return (
					<SelectPicker
						preventOverflow
						value={state?.capacity?.[rowItem?.id]?.[colId]}
						disabled={!state?.capacity?.[rowItem?.id]?.active}
						className={styles.picker}
						data={
							colId === 'duration'
								? config?.generateDurationList()
								: config?.generateTimeList()
						}
						searchable
						placeholder={
							colId === 'duration'
								? 'Select Duration'
								: 'Select Time'
						}
						style={{
							borderRadius: '5px !important',
						}}
						onChange={(rTime) => {
							updateValue(colId, rowItem?.id, rTime);
						}}
						cleanable={false}
					/>
				);
			}
			if (colId === 'endTime') {
				return (
					<div
						style={{
							textAlign: 'center',
							width: '100%',
							fontWeight: '500',
							color: `${!state?.capacity?.[rowItem?.id]?.active ? 'gray' : 'black'}`,
						}}
					>
						<span>
							{state?.capacity?.[rowItem?.id]?.[colId] ?? '-'}
						</span>
					</div>
				);
			}
			return (
				<input
					className={classes(styles['custom-input'])}
					type='number'
					min={0}
					// eslint-disable-next-line prefer-template
					value={state?.capacity?.[rowItem?.id]?.[colId]}
					disabled={colId === 'action' || (!state?.capacity?.[rowItem?.id]?.active)}
					onChange={(e) => {
						const inputValue = e?.target?.value;
						const isPositiveDecimal = /^[0-9]\d*(\.\d+)?$/.test(
							inputValue
						);
						if (inputValue === '' || isPositiveDecimal) {
							updateValue(colId, rowItem?.id, inputValue);
						}
					}}
					placeholder='Capacity (in Kg)'
				/>
			);
		};
		return (
			<div className={classes(styles['item-center'])}>
				{componentRenderAccToColId()}
			</div>
		);
	};

	const handleBtnClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const headerAction = () => {
		return (
			<div className={classes(styles['label-container'])}>
				<button
					aria-describedby='action'
					type='button'
					variant='contained'
					onClick={handleBtnClick}
					className={classes(styles.yellow)}
				>
					+ Add new Category
				</button>
				<Popover
					id='action'
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					style={{
						height: '250px',
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{productCategoriesList
							?.sort((a, b) => {
								const nameA =
									a.productCategoryName.toLowerCase();
								const nameB =
									b.productCategoryName.toLowerCase();
								if (nameA < nameB) {
									return -1;
								}
								if (nameA > nameB) {
									return 1;
								}
								return 0;
							})
							?.map((catItem) => {
								return (
									<button
										key={catItem.id}
										type='button'
										className={classes(styles.list)}
										onClick={() => {
											setAnchorEl(null);
											headerActionHelper(catItem);
										}}
									>
										{catItem?.productCategoryName}
									</button>
								);
							})}
					</div>
				</Popover>
			</div>
		);
	};

	return (
		<div>
			<BackdropLoader isLoading={isLoading} showProgress />
			<CreatePageHeader
				heading={isEdit ? 'Edit Shift' : 'Add Shift'}
				handleSave={handleSave}
				showBack
				showCancel={false}
				handleBackClick={handleBackClick}
			/>
			<div className={styles.root}>
				<div className={classes(styles['store-container'])}>
					<div className={classes(styles['label-container'])}>
						<label className={styles.mandatory} htmlFor='store'>
							Select Store*
						</label>
						<CustomInputSelector
							inputName='Store'
							width='300px'
							inputLists={filteredStores}
							handleRemove={() => {
								handleStoreSelection(null);
							}}
							handleKeyPress={handleStoreSelection}
							handleSelection={handleStoreSelection}
							placeholder='Select A Store'
							selectedValue={state?.selectedStore?.title}
							classname={styles['store-selector']}
							hideAddNew
						/>
					</div>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory}>Shift Name*</label>
					<TextField
						placeholder='Shift Name'
						value={state?.name}
						onChange={(name) => {
							dispatch({
								type: 'SET_STATE',
								name: 'name',
								payload: name?.target?.value?.trim(),
							});
						}}
						sx={{
							width: 300,
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='startDate'>
						Applicable start date*
					</label>
					<DatePickerCustom
						selectedDate={state?.startDate}
						selectDate={(deliveryDate) => {
							dispatch({
								type: 'SET_STATE',
								name: 'startDate',
								payload: deliveryDate,
							});
						}}
						style={{
							width: 300,
						}}
						oneTap
						defaultValue={state?.startDate}
						shouldDisableDate={disabledExpiryDate}
					/>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='endDate'>
						Applicable end date*
					</label>
					<DatePickerCustom
						selectedDate={state?.endDate}
						selectDate={(deliveryDate) => {
							dispatch({
								type: 'SET_STATE',
								name: 'endDate',
								payload: deliveryDate,
							});
						}}
						style={{
							width: 300,
						}}
						oneTap
						defaultValue={state?.endDate}
						shouldDisableDate={disabledExpiryDate}
					/>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='days'>
						Applicable Days*
					</label>
					<TagPicker
						preventOverflow
						data={config?.DaysList}
						style={{
							width: 400,
						}}
						menuStyle={{
							width: 300,
							maxHeight: 200,
							overflowY: 'auto',
						}}
						value={state?.days}
						onChange={(value) => {
							dispatch({
								type: 'SET_STATE',
								name: 'days',
								payload: value,
							});
						}}
					/>
					<div className={classes(styles['anchor-decor'])}>
						<a
							onClick={() => {
								dispatch({
									type: 'SET_STATE',
									name: 'days',
									payload: [1, 2, 3, 4, 5, 6, 7],
								});
							}}
						>
							Select all Days
						</a>
					</div>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='roles'>
						Manager Roles*
					</label>
					<TagPicker
						preventOverflow
						data={userRoles}
						style={{
							width: 400,
						}}
						menuStyle={{
							width: 300,
							maxHeight: 200,
							overflowY: 'auto',
						}}
						value={state?.roles}
						onChange={(value) => {
							dispatch({
								type: 'SET_STATE',
								name: 'roles',
								payload: value,
							});
						}}
					/>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='managers'>
						Select Managers*
					</label>
					{state?.roles?.length > 0 ? (
						<TagPicker
							preventOverflow
							data={users?.map((user) => {
								return {
									label: user?.name,
									value: user?.id,
								};
							})}
							style={{
								width: 400,
							}}
							menuStyle={{
								width: 300,
								maxHeight: 200,
								overflowY: 'auto',
							}}
							value={state?.managers}
							onChange={(value) => {
								dispatch({
									type: 'SET_STATE',
									name: 'managers',
									payload: value,
								});
							}}
						/>
					) : (
						<div className={classes(styles.error)}>
							Select a role first
						</div>
					)}
				</div>
				<div className={classes(styles.abs)}>
					{((state?.selectedStore?.id && !process.length) ||
						!state?.selectedStore?.id) && (
						<div className={classes(styles.errorAbs)}>
							<p className={classes(styles.text)}>
								{state?.selectedStore?.id
									? 'No process mapped to this store, select some other store'
									: 'Please Select a store first'}
							</p>
						</div>
					)}
					<DynamicHeaderTable
						headerData={headers}
						tableDataElement={EditableInput}
						excludedCols={['Process']}
						rowsData={
							process.length
								? process
								: [
									{
										name: '-',
									},
								]
						}
						headerAction={headerAction}
						colors={['#e5e7eb', '#14b8a6', '#ABFB6D', '#ABFB6D']}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStatesToProps = (state) => {
	const stores = state.form.STORES.data.LIST;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;

	return {
		productCategoriesList,
		stores,
	};
};

const mapDispatchToProps = {
	fetchProductCatergoriesList: formActions.gateway,
	fetchStores: formActions.gateway,
};

export default connect(mapStatesToProps, mapDispatchToProps)(ShiftCreate);
