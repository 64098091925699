import { useEffect } from 'react';
import { classes } from '../../Helpers';
import styles from './MasterDetail.module.scss';

function MasterDetail(props) {
	const {
		selectionList = [],
		renderDetailBody,
		selectorHead,
		selectedNav,
		setSelectedNav,
		showHeader = true,
		renderNavItem = null,
	} = props;
	// const [selectedNav, setSelectedNav] = useState(null);

	useEffect(() => {
		if (!selectionList?.length > 0) {
			setSelectedNav(null);
		}
	}, [selectionList]);

	return (
		<div className={styles.root}>
			<div className={styles.lhs}>
				{showHeader && (
					<p className={styles.header}>
						Select
						{' '}
						{selectorHead}
					</p>
				)}
				{selectionList?.map((ele) => {
					const isActive = selectedNav?.id === ele?.id;
					return (
						<div
							className={classes(
								styles.selector,
								isActive ? styles.active : ''
							)}
							onClick={() => {
								setSelectedNav(ele);
							}}
						>
							{renderNavItem ? renderNavItem(ele) : ele?.name}
						</div>
					);
				})}
			</div>
			<div className={styles.rhs}>{renderDetailBody(selectedNav)}</div>
		</div>
	);
}

export default MasterDetail;
