import { notificationServices } from '../../../Services';

function handleTimeFormat(value) {
	const arr = value.split(':');
	const d = new Date(2021, 11, 24, arr[0], arr[1], 30, 0);
	return d;
}
const StoreSettingConfig = {
	initialState: [
		{
			name: '',
			storeShutsAt: null,
			storeStartAt: null,
			isEnabled: null,
			menuId: '',
			id: '',
			menuName: '',
			inwardingMenu: '',
			inwardingMenuName: '',
			outwardingMenu: '',
			outwardingMenuName: '',
			roundOclock: null,
			wastageMenu: '',
			cmsMenu: '',
		},
	],

	serializer: (data) => {
		const newData = data.map((store) => {
			const newStore = {
				name: store.name,
				id: store.id,
				storeShutsAt:
					store.storeShutsAt !== null &&
					store?.storeShutsAt?.includes(':')
						? handleTimeFormat(store?.storeShutsAt)
						: null,
				storeStartAt:
					store?.storeStartAt !== null &&
					store?.storeStartAt?.includes(':')
						? handleTimeFormat(store?.storeStartAt)
						: null,
				isEnabled: store.isEnabled,
				menuId: store.menuId,
				menuName: store.menuName,
				specialMenu: store.specialMenu,
				isEdit:
					store.storeStartAt == null || store.storeShutsAt == null,
				roundOclock:
					store.storeStartAt == null ||
					store.storeShutsAt == null ||
					store.storeStartAt === 'Invalid date' ||
					store.storeShutsAt === 'Invalid date',
				inwardingMenu: store?.inwardingMenu,
				inwardingMenuName: store?.inwardingMenuName,
				outwardingMenu: store?.outwardingMenu,
				outwardingMenuName: store?.outwardingMenuName,
				wastageMenu: store?.wastageMenu,
				wastageMenuName: store?.wastageMenuName,
				cmsMenu: store?.cmsMenu,
				cmsMenuName: store?.cmsMenuName,
			};
			return newStore;
		});
		return newData;
	},

	validator: (type, data) => {
		switch (type) {
			case 'timings':
				if (data.startTime === null || data.endTime === null) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Please enter valid timings for Store ${
							data.idx + 1
						}`,
					});
					return false;
				}
				break;
			default:
				break;
		}
		return true;
	},
};
export default StoreSettingConfig;
