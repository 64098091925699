/* eslint-disable max-len */
function PlaceholderText(props) {
	const textBoxStyle = {
		backgroundColor: '#CCFF99',
		width: props.width,
		overflow: 'hidden', // Ensure text doesn't overflow the textBox
		fontSize: props?.fontSize,
		border:
			// eslint-disable-next-line no-nested-ternary
			props?.selectedComponent === props?.id
				? '1px solid orange'
				: props?.currentComponent === props?.id
					? '1px solid red'
					: null,

		resize: 'none',
		margin: 0,
		fontFamily: 'sans-serif',
		fontWeight: '550',
	};

	const content = (
		<textarea
			ref={props?.ref}
			readOnly
			style={textBoxStyle}
			className='placeholder-text'
			id='placeholder-text'
			rows={props?.inputLines}
			value={
				props?.placeholderText === undefined ||
				props?.placeholderText !== ''
					? props?.placeholderText
					: props.placeHolderName
			}
		/>
	);
	return content;
}
export default PlaceholderText;
