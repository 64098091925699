/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import {
	Button, IconButton, MenuItem, TextField
} from '@mui/material';
import {
	useEffect, useReducer, useState
} from 'react';
import { ArrowBack, Crop } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './MyContainersCreatePage.module.scss';
import config from './MyContainersCreatePage.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../../Services';
import { formActions, modalActions } from '../../../../Actions';
import BackdropLoader from '../../../../Components/Loaders/BackdropLoader';
import {
	generateImageEditButton, generateImageUploadButton, generateImageUrl, inputHelper, modes
} from '../../../../Helpers';
import { useAuth } from '../../../../Components/Auth/Login/auth';
import InputWithToggle from '../../../../Components/InputWithToggle/InputWithToggle';
import { ImageCropModal } from '../../../../Components/Modals/ImageCropModal';
import containerServices from '../../../../Services/myContianer.services';

const options = {
	IND: [
		{
			displayName: 'Kg',
			value: 'kg',
		},
		{
			displayName: 'G',
			value: 'g',
		},
	],
	US: [
		{
			displayName: 'Lb',
			value: 'lbs',
		},
		{
			displayName: 'Oz',
			value: 'oz',
		},
	],
};

function MyMachinesCreatePage(props) {
	const { toggleImageCrop } = props;
	const history = useHistory();
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const isUS = measurement === 'US';
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const { containerName, containerImage, weight, containerUnit, mode } =
		state ?? {};
	const [loading, setLoading] = useState(false);
	const [showLogoCropModal, setShowLogoCropModal] = useState(false);

	useEffect(() => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				containerUnit: isUS ? 'oz' : 'g',
			},
		});
	}, [isUS]);

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(false, {
			name,
			file,
		});
		setShowLogoCropModal(true);
	};

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer(state);
		const validate = config?.validator(deserializedData);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
			},
		};
		setLoading(true);
		try {
			const response = await containerServices.saveContainer(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[fieldName]: fieldValue,
			},
		});
	};
	const handleToggle = (unit) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				containerUnit: unit,
			},
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleLogoInput = (fieldName, blob) => {
		setShowLogoCropModal(false);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				containerImage: blob,
			},
		});
	};

	return (
		<div className={styles.root}>
			<ImageCropModal
				localShow={showLogoCropModal}
				handleConfirm={handleLogoInput}
				handleClose={() => {
					setShowLogoCropModal(false);
				}}
			/>
			<BackdropLoader isLoading={loading} showProgress />
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>Add New Container</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				<div className={styles.left}>
					<div className={styles.multi}>
						<div className={`${styles.tile} form-field row`}>
							<label
								className={`${styles.mandatory} form-label`}
								// htmlFor={id}
							>
								Container Name*
							</label>
							<TextField
								id='containerName'
								name='containerName'
								value={containerName}
								onChange={handleChange}
								placeholder='Enter ContainerName'
								sx={{
									width: 225,
									'.MuiInputBase-root': {
										borderRadius: '4px',
									},
									'.MuiInputBase-input': {
										paddingTop: '6px',
										paddingBottom: '6px',
									},
								}}
							/>
						</div>
					</div>
					<div className={styles.multi}>
						<div className={`${styles.tile} form-field row`}>
							<label
								className={`${styles.mandatory} form-label`}
								// htmlFor={id}
							>
								Weight*
							</label>
							<InputWithToggle
								width='225px'
								placeholder={`Enter Weight ${containerUnit ? `in ${containerUnit}` : ''}`}
								type='number'
								name='weight'
								value={weight}
								handleInput={handleChange}
								// maxLength={10}
								min={0}
								options={options?.[measurement ?? 'IND']}
								selectedToggleValue={containerUnit}
								// toggleType='minUnit'
								handleToggle={handleToggle}
								// step={getInputStep(variant?.minUnit)}
							/>
						</div>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							htmlFor='mode'
						>
							Mode*
						</label>
						<TextField
							id='mode'
							select
							name='mode'
							value={mode}
							onChange={handleChange}
							sx={{
								width: 225,
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
							}}
						>
							{Object?.keys(modes)?.map((key) => {
								return (
									<MenuItem
										key={key}
										value={key}
									>
										{modes[key]}
									</MenuItem>
								);
							})}
						</TextField>
					</div>
				</div>
				<div className={styles.right}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Display Image*
						</label>
						<div className='display-pic-root'>
							<div className='display-pic-preview'>
								<img
									src={generateImageUrl(containerImage)}
									className={`display-pic ${
										containerImage ? 'show' : 'hide'
									}`}
									alt='brand-display'
								/>
								{generateImageEditButton({
									isEdit:
										containerImage &&
										containerImage.includes(
											'data:image/jpeg;base64'
										),
									button: (
										<button
											type='button'
											onClick={() => {
												openImageCropModal('logo');
											}}
											className='display-pic-edit'
										>
											<Crop />
										</button>
									),
								})}
							</div>
							{generateImageUploadButton({
								isDisabled: false,
								label: (
									<label
										className='display-pic-label'
										htmlFor='displayPic'
									>
										Choose an Image
									</label>
								),
							})}
							<input
								type='file'
								id='displayPic'
								accept='image/png, image/jpg, image/jpeg, image/webp'
								name='logo'
								onChange={handleImageSelect}
								style={{
									opacity: 0,
									width: 1,
									height: 1,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;

	return {
		userPermissions,
		stores,
		processTypes,
	};
};

const mapDispatchToProps = {
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyMachinesCreatePage);
