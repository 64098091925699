import _ from 'lodash';
import { formatNumber } from '../../Helpers';

const transitDashboardAnalysisConfig = {
	initialState: {
		fields: {
			amountCaption: [
				{
					caption: 'Total Shipments',
					amount: 'N/A',
				},
				{
					caption: 'On-Time Delivery %',
					amount: 'N/A',
				},
				{
					caption: 'Delayed Shipments',
					amount: 'N/A',
				},
				{
					caption: 'Temperature Excursions',
					amount: 'N/A',
				},
				{
					caption: 'Damage Incidents',
					amount: 'N/A',
				},
				{
					caption: 'Average Transit Time',
					amount: 'N/A',
				},
			],
		},
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				amountCaption: [
					...Object.keys(data).map((current) => {
						let amount = data[current]?.value;
						let percentage = null;
						if ('percentageValue' in data?.[current]) {
							percentage = data?.[current]?.percentageValue;
						}
						if (data?.[current]?.type === 'currency') {
							amount = `\u20B9${formatNumber(
								'IN',
								data[current]?.value
							)}`;
						}
						if (data?.[current]?.type === 'time') {
							amount = `${data[current]?.value} hrs`;
						}
						return {
							caption: data[current].label,
							amount,
							color: data?.[current]?.color,
							percentageValue: percentage
								? `${percentage}%`
								: null,
						};
					}),
				],
			};
			return newState;
		});
	},
};
export default transitDashboardAnalysisConfig;
