import { connect } from 'react-redux';
import {
	Button, MenuItem, TextField
} from '@mui/material';
import {
	useCallback, useEffect, useReducer,
} from 'react';
import InputPicker from 'rsuite/InputPicker';
import styles from './AddNewLicenseModal.module.scss';
import { BaseModal } from '../BaseModal';
import { formActions, modalActions } from '../../../Actions';
import config from './addNewLicense.config';
import { notificationServices } from '../../../Services/Notification';
import DatePickerCustom from '../../DatePickerCustom/DatePickerCustom';
import { ListModal } from '../ListModal';

const licenseTypes = ['FMS', 'RESOEE'];

const plansList = ['QUARTERLY', 'YEARLY'];

const LabelledInput = (props) => {
	const {
		value, onChange, id, name, inputLabel,
		labelText, mandatory, optionsList,
		search = false, handleSelect,
	} = props;
	return (
		<div className={styles.tile}>
			<label className={mandatory ? styles.mandatory : ''} htmlFor={id}>
				{labelText}
			</label>
			{!search ? (
				<TextField
					id={id}
					select
					name={name}
					value={value}
					onChange={onChange}
					label={inputLabel}
					sx={{
						width: 200,
						'.MuiInputBase-root': {
							borderRadius: '4px',
						},
						'.MuiInputBase-input': {
							paddingTop: '6px',
							paddingBottom: '6px',
						},
					}}
				>
					{optionsList?.map((option) => {
						return (
							<MenuItem
								key={option?.id ?? option}
								value={option?.id ?? option}
							>
								{option?.title ?? option}
							</MenuItem>
						);
					})}
				</TextField>
			) : (
				<InputPicker
					data={optionsList}
					name={name}
					onSelect={(val) => handleSelect(val)}
					style={{
						width: 224,
					}}
				/>
			)}
		</div>
	);
};

const AddNewVendorModal = (props) => {
	const {
		show,
		toggleModal,
		saveVendor,
		fetchStoresList,
		stores,
		toggleListModal,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const {
		licenseType,
		store,
		plan,
		startDate,
		endDate,
		machineAt,
		outlets,
	} = state ?? {};

	const handleClose = () => {
		toggleModal(false, {});
	};

	useEffect(() => {
		if (show) {
			fetchStoresList({
				entity: 'STORES',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [show]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[name]: value,
			},
		});
	};

	const handleConfirm = () => {
		const deserializedData = config?.deserializer(state);
		if (!deserializedData?.name) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Vendor Name is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
			},
		};
		saveVendor({
			entity: 'ALL_VENDORS',
			req,
			method: 'CREATE',
		});
		handleClose();
	};

	const renderBtns = useCallback(() => {
		return (
			<div className={styles['btns-container']}>
				<Button fullWidth variant='outlined' onClick={handleClose}>
					Cancel
				</Button>
				<Button fullWidth variant='contained' color='primary' onClick={handleConfirm}>
					Confirm
				</Button>
			</div>
		);
	}, [state]);

	// eslint-disable-next-line no-unused-vars
	const addMonths = (input, months) => {
		const date = new Date(input);
		date.setDate(1);
		date.setMonth(date.getMonth() + months);
		date.setDate(
			Math.min(
				input.getDate(),
				new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
			)
		);
		return date;
	};

	const handleStartDate = () => {};
	const handleEndDate = () => {};

	const selectOutlets = () => {};

	return (
		<BaseModal
			open={show}
			heading='Add New License'
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='60rem'
			height='40rem'
		>
			<div className={styles.root}>
				<ListModal
					handleConfirm={selectOutlets}
					selectionBehaviour='SHOW'
				/>
				<LabelledInput
					mandatory
					id='licenseType'
					name='licenseType'
					value={licenseType}
					onChange={handleChange}
					labelText='License Type*'
					optionsList={licenseTypes}
				/>
				<LabelledInput
					mandatory
					id='baseStore'
					name='store'
					value={store}
					onChange={handleChange}
					optionsList={stores?.map((ele) => {
						return {
							...ele,
							title: ele?.name,
						};
					})}
					labelText='Select License Store*'
				/>
				<LabelledInput
					id='licensePlan'
					mandatory
					name='plan'
					value={plan}
					onChange={handleChange}
					optionsList={plansList}
					labelText='Select License Plan*'
				/>
				<div className={styles.tile}>
					<label className={styles.mandatory} htmlFor='startDate'>
						Start Date
					</label>
					<DatePickerCustom
						selectedDate={startDate}
						selectDate={handleStartDate}
						style={{
							width: 200,
							zIndex: 1300,
						}}
						oneTap
					/>
				</div>
				<div className={styles.tile}>
					<label className={styles.mandatory} htmlFor='endDate'>
						End Date
					</label>
					<DatePickerCustom
						selectedDate={endDate}
						selectDate={handleEndDate}
						style={{
							width: 200,
						}}
						oneTap
					/>
				</div>
				{false}
				{licenseType === 'RESOEE' && (
					<>
						<LabelledInput
							id='machineAt'
							name='machineAt'
							value={machineAt}
							onChange={handleChange}
							optionsList={[]}
							labelText='Select Machine At*'
						/>
						<LabelledInput
							id='processType'
							name='processType'
							onChange={handleChange}
							optionsList={[]}
							labelText='Select Process Type*'
						/>
						<div className={styles.tile}>
							<label
								className={styles.mandatory}
								htmlFor='endDate'
							>
								Select Outlets
							</label>
							<Button
								onClick={() => {
									toggleListModal(true, {
										entity: 'OUTLETS',
										heading: 'Select Outlets',
										list: stores
											?.filter((ele) => {
												return ele?.type === 'RETAIL';
											})
											?.map((ele) => {
												return {
													...ele,
													title: ele?.name,
												};
											}),
										selected: outlets,
									});
								}}
							>
								Outlets
							</Button>
						</div>
					</>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addLicenseModal;
	const stores = state?.form?.STORES?.data?.LIST;

	return {
		show,
		data,
		stores,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddLicenseModal,
	toggleListModal: modalActions.toggleListModal,
	saveVendor: formActions.gateway,
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVendorModal);
