import { endpoints } from '@/Constants';
import { authHeader, handleResponse } from '../../Helpers';

function requestQuoteAssetList({ req } = {
	req: {},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.REQUEST_QOUTE_ASSET, requestOptions).then(handleResponse);
}

function requestQuoteAssetSave({ req } = {
	req: {},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.REQUEST_QOUTE_ADD, requestOptions).then(handleResponse);
}
const fetchRfq = ({ req } = {
	req: {},
}) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.REQUEST_QOUTE_FETCH, requestOptions).then(handleResponse);
};
const saveVendorQuote = ({ req } = {
	req: {},
}) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.VENDOR_QUOTE_SAVE, requestOptions).then(handleResponse);
};
const fetchVendorQuote = ({ req } = {
	req: {},
}) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.VENDOR_QUOTE_FETCH, requestOptions).then(handleResponse);
};
const fetchVendorQuoteById = ({ req } = {
	req: {},
}) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.VENDOR_QUOTE_FETCH_BY_ID}/${req?.params?.id}/reply`, requestOptions).then(handleResponse);
};
const saveVendorQuoteReply = ({ req } = {
	req: {},
}) => {
	const data = req?.data;
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};
	return fetch(endpoints.VENDOR_QUOTE_REPLY, requestOptions).then(handleResponse);
};

export default {
	requestQuoteAssetList,
	requestQuoteAssetSave,
	fetchRfq,
	saveVendorQuote,
	fetchVendorQuote,
	fetchVendorQuoteById,
	saveVendorQuoteReply,
};
