import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { urls } from '@/Constants';
import CRUDRoutes from '../CRUD Routes/CRUD.routes';
import CreateQuote from '@/Components/CreateQuote/CreateQuote';
import ListQoute from '@/Pages/RequestQuote/ListQoute';

function CrudQouteRoute() {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.QUOTE_LIST}`,
			name: 'Asset Qoutes',
		},
	];
	const header = true;
	const headerName = 'Assets Quotes';
	return (
		<CRUDRoutes
			entity='Quote'
			path={path}
			listPage={ListQoute}
			header={header}
			headerName={headerName}
			editPage={<CreateQuote breadcrumbs={breadcrumbs} />}
			createPage={<CreateQuote breadcrumbs={breadcrumbs} />}
		/>
	);
}

export default CrudQouteRoute;
