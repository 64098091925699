/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import {
	// eslint-disable-next-line no-unused-vars
	Autocomplete,
	Button,
	Chip,
	TextField,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import {
	useEffect, useMemo, useState
} from 'react';
import { formActions, modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './VendorProductMapModal.module.scss';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../Services';
import { classes, inputHelper } from '../../../Helpers';
import { Tag } from '../../Tags';
import { ListModal } from '../ListModal';

const ProductProcessMapCreatorModal = (props) => {
	const {
		show,
		toggleModal,
		data,
		handleConfirm,
		productsList,
		vendorsList,
		toggleListModal,
	} = props;
	const { heading } = data;
	const [selectedProductList, setSelectedProductList] = useState([]);
	const [selectedVendorList, setSelectedVendorList] = useState([]);
	const handleClose = () => {
		toggleModal(false);
	};

	useEffect(() => {
		if (!show) {
			setSelectedProductList([]);
			setSelectedVendorList([]);
		}
	}, [show]);

	const PRODUCTS = useMemo(() => {
		return productsList.map((product) => {
			return {
				id: product.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList]);

	const VENDORS = useMemo(() => {
		return vendorsList?.map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
			};
		});
	}, [vendorsList]);

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'PRODUCT') {
			setSelectedProductList((prev) => {
				return prev.filter((product) => {
					return product !== parseInt(id, 10);
				});
			});
		} else if (entity === 'VENDOR') {
			setSelectedVendorList((prev) => {
				return prev?.filter((vendor) => {
					return vendor !== parseInt(id, 10);
				});
			});
		}
	};

	const addFilter = (filters, context) => {
		if (context.entity === 'PRODUCT') {
			setSelectedProductList((prev) => {
				return [...prev, ...filters];
			});
		} else if (context.entity === 'VENDOR') {
			setSelectedVendorList((prev) => {
				return [...prev, ...filters];
			});
		}
	};

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			customStyles={{
				width: '50rem',
				height: '400px',
			}}
			heading={heading}
			open={show}
			handleClose={handleClose}
			handleConfirm={() => {
				handleConfirm(selectedVendorList, selectedProductList);
			}}
		>
			<ListModal handleConfirm={addFilter} list={PRODUCTS} />
			<div className={`${styles.root}`}>
				<div className={classes(styles.filter, styles.product)}>
					<p className={styles['filter-heading']}>
						Select Product(s)
					</p>
					<div className={styles['filter-body']}>
						<Button
							variant='outlined'
							startIcon={<Add />}
							className={styles['add-button']}
							// disabled={brand == null}
							fullWidth
							onClick={() => {
								toggleListModal(true, {
									entity: 'PRODUCT',
									heading: 'Select Product(s)',
									list: PRODUCTS,
									selected: selectedProductList,
								});
							}}
						>
							Product
						</Button>
						<div className={styles.tags}>
							{selectedProductList.map((id) => {
								const product = PRODUCTS.find((d) => {
									return id === d.id;
								});
								return (
									<Tag
										key={id}
										width='100%'
										text={product?.title}
										handleDelete={deleteFilter}
										dataAttrs={{
											'data-id': id,
											'data-entity': 'PRODUCT',
										}}
									/>
								);
							})}
							{selectedProductList?.length === 0 && (
								<span>No product selected</span>
							)}
						</div>
					</div>
				</div>
				<div className={classes(styles.filter, styles.vendor)}>
					<p className={styles['filter-heading']}>Select Vendor(s)</p>
					<div className={styles['filter-body']}>
						<Button
							variant='outlined'
							startIcon={<Add />}
							className={styles['add-button']}
							// disabled={brand == null}
							fullWidth
							onClick={() => {
								toggleListModal(true, {
									entity: 'VENDOR',
									heading: 'Select Vendors(s)',
									list: VENDORS,
									selected: selectedVendorList,
								});
							}}
						>
							VENDOR
						</Button>
						<div className={styles.tags}>
							{selectedVendorList.map((id) => {
								const selectedVendor = VENDORS?.find((v) => {
									return id === v?.id;
								});
								return (
									<Tag
										key={selectedVendor?.id}
										width='100%'
										text={selectedVendor?.title}
										handleDelete={deleteFilter}
										dataAttrs={{
											'data-id': selectedVendor?.id,
											'data-entity': 'VENDOR',
										}}
									/>
								);
							})}
							{selectedVendorList?.length === 0 && (
								<span>No Vendor selected</span>
							)}
						</div>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.vendorProductMapModal;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const vendorsList = state.form.ALL_VENDORS.data.LIST;

	return {
		show,
		data,
		productsList,
		vendorsList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleVendorProductMapModal,
	toggleListModal: modalActions.toggleListModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductProcessMapCreatorModal);
