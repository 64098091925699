import { Switch } from '@mui/material';
import { VariantCheckboxes } from '../../../Inputs/VariantCheckboxes';
import styles from './GeneralSettings.module.scss';

const GeneralSettings = (props) => {
	const { fields, handleInput, isDisabled } = props;

	return (
		<div className={styles.root}>
			{/* master level quality check */}
			<div className='form-field row'>
				<div className='form-label'>Master Level Quality Check</div>
				<div className='form-value'>
					<Switch
						name='isMasterQC'
						value='isEnabled'
						size='small'
						color='primary'
						onChange={handleInput}
						checked={fields.isMasterQC}
						disabled={isDisabled}
						disableRipple={isDisabled}
						inputProps={{
							'data-id': null,
							'data-type': 'MASTER',
						}}
					/>
				</div>
			</div>
			{/* qc mode */}
			<div className='form-field row'>
				<div className='form-label'>Quality Check Mode</div>
				<div className='form-value'>
					<VariantCheckboxes
						isDisabled={isDisabled}
						items={[
							{
								name: 'Range',
								isApplied: fields.qcMode === 'RANGE',
							},
							{
								name: 'Tolerance',
								isApplied: fields.qcMode === 'TOLERANCE',
							},
						]}
						fieldName='qcMode'
						handleInput={handleInput}
						dataAttrs={{
							'data-type': 'MASTER',
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default GeneralSettings;
