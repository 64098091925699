import { useRouteMatch } from 'react-router';
import { CustomerListPage, OrderViewPage } from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const CustomerRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<CRUDRoutes
				entity='MY_CUSTOMERS'
				path={path}
				listPage={CustomerListPage}
				viewPage={<OrderViewPage />}
			/>
		</>
	);
};

export default CustomerRoutes;
