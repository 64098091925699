import Button from '../LabelGeneratorButton/Button';

function SavedComponents(props) {
	let content;
	const deleteComponent = (val) => {
		props?.setLabelComponentsState(props?.labelComponentsState.filter((component) => {
			return component?.id !== val;
		}));
	};
	return (
		<div className='saved-components'>
			<div className='saved-component'>
				{props?.labelComponentsState.map((component) => {
					if (component?.type === 'staticText') {
						content = (
							<div
								className='saved-component-description'
								style={{
									height: '3rem', fontSize: '1rem', margin: '1rem',
								}}
							>
								<div style={{
									flex: 5, margin: 'auto',
								}}
								>
									{component?.type}
								</div>
								<div
									style={{
										flex: 8,
										margin: 'auto',
										marginRight: '5rem',
									}}
								>
									{component?.staticText?.text}
								</div>
								<div
									className='saved-components-delete'
									style={{
										flex: 4,
									}}
								>
									<Button
										key={component?.id}
										id={component?.id}
										type='delete-component'
										className='delete-component'
										text='Delete'
										handleClick={(e) => { return deleteComponent(e?.itemId); }}
										variant='delete'
									/>
								</div>
							</div>
						);
					} else if (component?.type === 'text') {
						content = (
							<div
								className='saved-component-description'
								style={{
									height: '3rem', fontSize: '1rem', margin: '1rem',
								}}
							>
								<div style={{
									flex: 5, margin: 'auto',
								}}
								>
									{component?.type}
								</div>
								<div
									style={{
										flex: 8,
										margin: 'auto',
										marginRight: '5rem',
									}}
								>
									{component?.text?.placeHolderName}
								</div>
								<div
									className='saved-components-delete'
									style={{
										flex: 4,
									}}
								>
									<Button
										key={component?.id}
										id={component?.id}
										type='delete-component'
										className='delete-component'
										text='Delete'
										handleClick={(e) => { return deleteComponent(e?.itemId); }}
										variant='delete'
									/>
								</div>
							</div>
						);
					} else if (component?.type === 'qrCode') {
						content = (
							<div
								className='saved-component-description'
								style={{
									height: '3rem', fontSize: '1rem', margin: '1rem',
								}}
							>
								<div style={{
									flex: 5, margin: 'auto',
								}}
								>
									{component?.type}
								</div>
								<div
									style={{
										flex: 8,
										margin: 'auto',
										marginRight: '5rem',
									}}
								/>
								<div
									className='saved-components-delete'
									style={{
										flex: 4,
									}}
								>
									<Button
										key={component?.id}
										id={component?.id}
										type='delete-component'
										className='delete-component'
										text='Delete'
										handleClick={(e) => { return deleteComponent(e?.itemId); }}
										variant='delete'
									/>
								</div>
							</div>
						);
					} else if (component?.type === 'barCode') {
						content = (
							<div
								className='saved-component-description'
								style={{
									height: '3rem', fontSize: '1rem', margin: '1rem',
								}}
							>
								<div style={{
									flex: 5, margin: 'auto',
								}}
								>
									{component?.type}
								</div>
								<div
									style={{
										flex: 8,
										margin: 'auto',
										marginRight: '5rem',
									}}
								/>
								<div
									className='saved-components-delete'
									style={{
										flex: 4,
									}}
								>
									<Button
										key={component?.id}
										id={component?.id}
										type='delete-component'
										className='delete-component'
										text='Delete'
										handleClick={(e) => { return deleteComponent(e?.itemId); }}
										variant='delete'
									/>
								</div>
							</div>
						);
					} else if (component?.type === 'image') {
						content = (
							<div
								className='saved-component-description'
								style={{
									height: '3rem', fontSize: '1rem', margin: '1rem',
								}}
							>
								<div style={{
									flex: 5, margin: 'auto',
								}}
								>
									{component?.type}
								</div>
								<div
									style={{
										flex: 8,
										margin: 'auto',
										marginRight: '5rem',
									}}
								/>
								<div
									className='saved-components-delete'
									style={{
										flex: 4,
									}}
								>
									<Button
										key={component?.id}
										id={component?.id}
										type='delete-component'
										className='delete-component'
										text='Delete'
										handleClick={(e) => { return deleteComponent(e?.itemId); }}
										variant='delete'
									/>
								</div>
							</div>
						);
					}
					return <div>{content}</div>;
				})}
			</div>
		</div>
	);
}
export default SavedComponents;
