import { cloneDeep, update } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import ProductDetails from '../../../Components/Forms/Products/ProductDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import qualityConfig from './quality-config.config';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import { inputHelper, isEmpty } from '../../../Helpers';
import { urls } from '../../../Constants';
import { formActions } from '../../../Actions';
import VariantQualityConfig from '../../../Components/Forms/Quality/Quality Config/Variant Quality Config/VariantQualityConfig';

const QualityConfigEditPage = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { isEdit, saveProduct, fetchProduct, productsMap, breadcrumbs } =
		props;
	const [state, setState] = useState({
		...qualityConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const [activeVariant, setActiveVariant] = useState(0);

	const { id } = useParams();

	const data = useMemo(() => {
		return productsMap[id];
	}, [id, productsMap]);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setState(qualityConfig.serializer(state, data));
			} else {
				fetchProduct({
					entity: 'PRODUCT_QUALITY_CONFIG',
					req: {
						params: {
							id,
							combo: true,
						},
					},
					method: 'LIST',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(qualityConfig.serializer(state, data));
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let newState = {};
		switch (dataset.type) {
			case 'weight':
				if (fieldName === 'applied') {
					newState.fields = {
						...state.fields,
						weightCheck: fieldValue,
					};
				} else {
					newState.fields = update(
						cloneDeep(state.fields),
						'variants.items',
						(variantItems) => {
							// eslint-disable-next-line no-param-reassign
							variantItems[dataset.id].weightCheck[fieldName] =
								fieldValue;
							return variantItems;
						}
					);
				}
				break;
			case 'temperature':
				if (fieldName === 'applied') {
					newState.fields = {
						...state.fields,
						tempCheck: fieldValue,
					};
				} else {
					newState = update(
						cloneDeep(state),
						'fields',
						(newFields) => {
							return {
								...newFields,
								tempParams: {
									...newFields.tempParams,
									[fieldName]: fieldValue,
								},
							};
						}
					);
				}
				break;
			case 'defect':
				if (fieldName === 'applied') {
					newState.fields = {
						...state.fields,
						defectCheck: fieldValue,
					};
				} else {
					newState = update(
						cloneDeep(state),
						'fields',
						(newFields) => {
							return {
								...newFields,
								defectParams: {
									...newFields.defectParams,
									[fieldName]: fieldValue,
								},
							};
						}
					);
				}
				break;
			case 'QMS':
				newState.fields = update(
					cloneDeep(state.fields),
					'QMSEnabled',
					() => {
						return fieldValue;
					}
				);
				break;
			default:
				break;
		}

		setState({
			...state,
			...newState,
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const isValid = qualityConfig.validator(state);
		if (isValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			qualityConfig.deserializer(state).then((req) => {
				if (isEdit) {
					req.params = {
						id,
					};
					saveProduct({
						entity: 'PRODUCT_QUALITY_CONFIG',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.PRODUCT_QUALITY_CONFIG}`,
					});
				} else {
					saveProduct({
						entity: 'PRODUCT_QUALITY_CONFIG',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.PRODUCT_QUALITY_CONFIG}`,
					});
				}
			});
		} else {
			toast.error('Select at least one criteria for defect based rejection!');
		}
	};
	const generateDefaultOption = (type, brand, category) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			case 'category':
				if (brand) {
					if (!category || category.length === 0) {
						return (
							<option value={null} selected disabled hidden>
								Select a Category
							</option>
						);
					}
				} else {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand First
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const history = useHistory();
	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.PRODUCT_QUALITY_CONFIG}/view/${id}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.PRODUCT_QUALITY_CONFIG}`);
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Product' : 'Create Product'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='Basic details about the product'
					sectionName='General Details'
				>
					<ProductDetails
						mandatoryFields={qualityConfig.mandatoryFields}
						fields={state.fields}
						generateDefaultOption={generateDefaultOption}
						handleInput={handleInput}
						isDisabled
						exclude={{
							price: true,
							category: true,
							brand: true,
							veg: true,
						}}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Details required for quality management of the product'
					sectionName='Quality Parameters'
				>
					<VariantQualityConfig
						mandatoryFields={qualityConfig.mandatoryFields}
						fields={state.fields}
						activeVariant={activeVariant}
						setActiveVariant={setActiveVariant}
						handleInput={handleInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const productsMap = state.form.PRODUCT_QUALITY_CONFIG.data.LIST;
	return {
		productsMap,
	};
};
const mapDispatchToProps = {
	saveProduct: formActions.gateway,
	fetchProduct: formActions.gateway,
};
QualityConfigEditPage.defaultProps = {
	limit: 3,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityConfigEditPage);
