import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './Heading.module.css';
/*
function WeightIcon(value) {
	return (
		<div className={styles.weightIconContainer}>
			<span>{`${value} ${value >= 1.0 ? 'kg' : 'g'}`}</span>
			<Scale className={styles.icon} />
		</div>
	);
}
function CurrencyIcon(value) {
	return (
		<div className={styles.currencyIconContainer}>
			<CurrencyRupeeIcon className={styles.icon} />
			<p>{`${value}`}</p>
		</div>
	);
}

// eslint-disable-next-line no-unused-vars
function CorrespondingIcon(type, value) {
	switch (type) {
		case 'percent':
			break;
		case 'kg':
			return WeightIcon(value);
			case 'price':
				return CurrencyIcon(value);

		default:
			break;
		}
	}
*/

export const HtmlTooltip = styled(({ className, ...props }) => {
	return (
		<Tooltip
			{...props}
			classes={{
				popper: className,
			}}
		/>
	);
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			background: '#eff5f5',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid black',
		},
	};
});

function Heading({ heading, tooltip, size }) {
	return tooltip?.length ? (
		<>
			<HtmlTooltip
				style={{
					fontSize: size || '',
				}}
				title={(
					<>
						<Typography color='inherit'>{tooltip}</Typography>
					</>
				)}
				placement='top'
				followCursor
			>
				<span className={styles.header}>
					{heading}
					<InfoOutlinedIcon className={styles.tooltipIcon} />
					{/* {heading.substring(0, 100)} */}
					{/* {heading.length < 17 ? '' : '...'} */}
				</span>
			</HtmlTooltip>
		</>
	) : (
		<>
			<span
				style={{
					fontSize: size || '',
				}}
				className={styles.header}
			>
				{heading}
			</span>
		</>
	);
}

export default Heading;
