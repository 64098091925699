import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { classes } from '../../Helpers';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import styles from './SubHeader.module.scss';

const SubHeader = (props) => {
	const {
		heading,
		handleDelete,
		showDelete,
		showEdit,
		showBreadcrumbs,
		breadcrumbs,
		handleEdit,
		selectedDateString,
		userPermissions,
	} = props;

	return (
		<div className={`${styles.root}`}>
			<div className={styles.breadcrumb}>
				{showBreadcrumbs ? (
					<Breadcrumb breadcrumbs={breadcrumbs} />
				) : (
					<p className='heading'>{heading}</p>
				)}
				<p className={classes(styles.date)}>{selectedDateString}</p>
			</div>
			<div className={`${styles['btn-group']}`}>
				{showEdit && userPermissions?.EDIT && (
					<Button
						variant='outlined'
						color='primary'
						onClick={handleEdit}
					>
						Edit
					</Button>
				)}
				{showDelete && userPermissions?.DELETE && (
					<Button
						variant='outlined'
						color='secondary'
						onClick={handleDelete}
					>
						Delete
					</Button>
				)}
			</div>
		</div>
	);
};

SubHeader.defaultProps = {
	showDelete: false,
	showEdit: false,
	showBreadcrumbs: true,
	breadcrumbs: [
		{
			link: '',
			name: 'View',
		},
	],
};
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

export default connect(mapStateToProps)(SubHeader);
