// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { urls } from '../../../Constants';
import { AiGrnHistory } from '../../../Pages/Analytics/AiGrnAnalysis/AiGrnHistory';
import AiGrnImagesPage from '../../../Pages/Analytics/AiGrnAnalysis/AiGrnImagesPage/AiGrnImagesPage';

const getSubURL = (entity) => {
	const baseEntity = urls.AI_GRN;
	return entity.slice(baseEntity.length);
};

const AiGrnRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.AI_GRN_IMAGES)}`}>
				<AiGrnImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_GRN_HISTORY)}`}>
				<AiGrnHistory />
			</Route>
		</Switch>
	);
};

export default AiGrnRoutes;
