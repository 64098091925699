import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const storeServices = {
	gateway,
	createStore,
	viewStore,
	getStore,
	fetchRevenues,
	addStoreRevenue,
	exportStoresList,
};
function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createStore({
				req,
			});
		case 'VIEW':
			return viewStore({
				req,
			});
		case 'LIST':
			return listStore({
				req,
			});
		case 'UPDATE':
			return updateStore({
				req,
			});
		case 'PATCH':
			return patchStore({
				req,
			});
		case 'DELETE':
			return deleteStore({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function patchStore({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.STORES, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function createStore({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.STORES, requestOptions).then(handleResponse);
}

function updateStore({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.STORES, params);
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewStore({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.STORES, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function listStore({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.STORES, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function getStore(req = {}) {
	const params = {
		...req,
	};
	const url = toQueryStringURL(endpoints.STORES, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function deleteStore({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.STORES, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchRevenues(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.REVENUES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function addStoreRevenue(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.ADD_REVENUE, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then((res) => {
		return res?.json();
	});
}
function exportStoresList(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};

	return fetch(endpoints.EXPORT_STORES, requestOptions);
}

export default storeServices;
