import { brandConstants } from '../Constants';
import { brandServices } from '../Services';

const brandActions = {
	fetchBrands,
};

function fetchBrands(
	req = {
		id: '',
	}
) {
	return (dispatch) => {
		dispatch(request());
		brandServices.getBrands(req).then(
			(res) => {
				const { data } = res;
				dispatch(success(data));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: brandConstants.FETCH_BRANDS_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: brandConstants.FETCH_BRANDS_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: brandConstants.FETCH_BRANDS_FAILURE,
			payload,
		};
	}
}

export default brandActions;
