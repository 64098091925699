import EnableDisable from '../../EnableDisable/EnableDisable';
import { IconInput } from '../../Inputs';
import styles from './InventoryParams.module.scss';

const InventoryParams = (props) => {
	const { mandatoryFields, fields, errors, handleInput } = props;

	return (
		<div className={`${styles.root} row`}>
			<div
				className={`${styles['enable-disable-container']}`}
				style={{
					flexBasis: '100%',
				}}
			>
				<div className={`${styles.enableDisable}`}>
					<EnableDisable
						name='weight'
						text='Weight'
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'weight',
						}}
						isEnabled={fields.weight}
						className={`${styles['enable-disable-container']}`}
					/>
				</div>

				{/* wieght params */}
				<div className='form-field row params'>
					<label
						className='form-label'
						htmlFor='idealWeight'
						data-mandatory={mandatoryFields.idealWeight}
					>
						Ideal Weight (grams)
					</label>

					<IconInput
						size='large'
						type='custom'
						iconText='gram'
						name='idealWeight'
						id='idealWeight'
						value={fields.idealWeight}
						handleInput={handleInput}
						dataType=''
						dataId=''
						dataAttrs={{
							required: mandatoryFields.idealWeight,
						}}
						step='0.01'
					/>
					<span className='form-error'>{errors.idealWeight}</span>

					<label
						className={`${styles.label} form-label`}
						htmlFor='idealWeightTol'
						data-mandatory={mandatoryFields.idealWeightTol}
					>
						Tolerance (%)
					</label>

					<IconInput
						size='large'
						type='percent'
						iconText=''
						name='idealWeightTol'
						id='idealWeightTol'
						value={fields.idealWeightTol}
						handleInput={handleInput}
						dataType=''
						dataId=''
						dataAttrs={{
							required: mandatoryFields.idealWeightTol,
						}}
						step='0.01'
					/>
					<span className='form-error'>{errors.idealWeightTol}</span>
				</div>
				<div className={`${styles.enableDisable}`}>
					<EnableDisable
						name='temperature'
						text='Temperature'
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'temperature',
						}}
						isEnabled={fields.temperature}
					/>
				</div>
				{/* Temperature Children */}
				{fields.temperature ? (
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='storageTemp'
							data-mandatory={mandatoryFields.storageTemp}
						>
							Storage Temperature (℃)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='℃'
							name='storageTemp'
							id='storageTemp'
							value={fields.storageTemp}
							handleInput={handleInput}
							dataType=''
							dataId=''
							dataAttrs={{
								required:
									fields.temperature &&
									mandatoryFields.storageTemp,
							}}
							step='0.01'
						/>
						<span className='form-error'>{errors.storageTemp}</span>

						<label
							htmlFor='storageTempTol'
							data-mandatory={mandatoryFields.storageTempTol}
							className={`${styles.label} form-label`}
						>
							Tolerance (%)
						</label>
						<IconInput
							size='large'
							type='percent'
							iconText=''
							name='storageTempTol'
							id='storageTempTol'
							value={fields.storageTempTol}
							handleInput={handleInput}
							dataType=''
							dataId=''
							dataAttrs={{
								required:
									fields.temperature &&
									mandatoryFields.storageTempTol,
							}}
							step='0.01'
						/>
						<span className='form-error'>
							{errors.storageTempTol}
						</span>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};

InventoryParams.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

export default InventoryParams;
