import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import styles from './StoreTiming.module.scss';
import EnableDisable from '../../EnableDisable/EnableDisable';

const StoreTiming = (props) => {
	const { fields, errors, handleInput, isDisabled, mandatoryFields } = props;
	const [value1, setValue1] = useState(fields.openingTime);
	const [value2, setValue2] = useState(fields.closingTime);
	useEffect(() => {
		setValue1(fields.openingTime);
		setValue2(fields.closingTime);
	}, [fields]);
	const handleInputChnage = (name, newValue) => {
		if (name === 'openingTime') {
			setValue1(newValue);
		} else {
			setValue2(newValue);
		}
		props.updateTime(name, newValue);
	};

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* 24x7 */}
				<div className='form-field row'>
					<EnableDisable
						name='roundOclock'
						text='Store Open 24X7'
						fields={fields}
						isEnabled={fields.roundOclock}
						handleIsEnabled={handleInput}
						condition='true'
						dataAttrs={{
							'data-type': 'roundOclock',
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<div className='form-field row'>
								<label
									className='form-label'
									htmlFor='openingTime'
									data-mandatory={
										fields.enableSection.storeTiming ||
										mandatoryFields.openingTime
									}
								>
									Opening Time
								</label>
								<TimePicker
									disableOpenPicker={isDisabled}
									label='Start Time'
									value={value1}
									onChange={(newValue) => {
										handleInputChnage(
											'openingTime',
											newValue
										);
									}}
									renderInput={(params) => {
										return <TextField {...params} />;
									}}
								/>
							</div>
							<div className='form-field row'>
								<label
									className='form-label'
									htmlFor='closingTime'
									data-mandatory={
										fields.enableSection.storeTiming ||
										mandatoryFields.closingTime
									}
								>
									Closing Time
								</label>
								<TimePicker
									label='Shut Time'
									value={value2}
									disableOpenPicker={isDisabled}
									onChange={(newValue) => {
										handleInputChnage(
											'closingTime',
											newValue
										);
									}}
									renderInput={(params) => {
										return <TextField {...params} />;
									}}
								/>
							</div>
						</LocalizationProvider>
					</EnableDisable>
					<span className='form-error'>{errors.roundOclock}</span>
				</div>
			</div>
		</div>
	);
};
StoreTiming.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};
export default StoreTiming;
