/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import {
	useState, useReducer, useEffect,
	useMemo
} from 'react';
import { InputPicker, Toggle } from 'rsuite';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { CreatePageHeader } from '@/Components/Headers';
import { BackdropLoader } from '@/Components/Loaders';
import { formActions } from '@/Actions';
import { config } from './processStateConfig';
import processConfigServices from '@/Services/process.config';
import { notificationServices } from '@/Services';

function ProcessConfig(props) {
	const {
		storesList,
		processTypes,
		fetchStoresList,
	    fetchProcessTypesList,
	} = props;
	const [isLoading, setIsloading] = useState(false);
	const isEdit = false;
	const history = useHistory();
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const {
		store,
		processId,
		qualityControl,
		overrideAllowed,
		supervisorControl,
		duplicateAllowed,
		duplicateControl,
		leftOverInventory,
	} = state;

	const handleSubmit = async () => {
		const valid = config?.validator(state);
		if (valid) {
			setIsloading(true);
			try {
				const deserializedData = config?.deserializer(state);
				const req = {
					data: deserializedData,
				};
				const response = await processConfigServices?.createProcessConfig(req);
				if (response?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
					setIsloading(false);
					// history.goBack();
					// dispatch({
					// type: 'SET_VALUE',
					// payload: {
					// ...config?.initialState,
					// },
					// });
				} else {
					setIsloading(false);
					notificationServices.generateNotification({
						type: 'error',
						message: response?.message,
					});
				}
			} catch (err) {
				setIsloading(false);
				notificationServices.generateNotification({
					type: 'error',
					message: err,
				});
			}
		}
	};
	const handleBackClick = () => {
		history.goBack();
	};
	const renderBtns = () => {
		return (
			<Button
				variant='contained'
				color='primary'
				type='button'
				onClick={handleSubmit}
			>
				Save
			</Button>
		);
	};
	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (store) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: store,
					},
				},
				method: 'LIST',
			});
		}
	}, [store]);
	const fetchUser = async () => {
		try {
			setIsloading(true);
			const response = await processConfigServices.createProcessConfig({
				params: {
					processId,
				},
			}, 'GET');
			if (response?.statusCode === 200) {
				const userData = response?.data;
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'SET_VALUE',
					payload: {
						qualityControl: userData?.qualityControl,
						overrideAllowed: userData?.overrideAllowed,
						supervisorControl: userData?.supervisorControl,
						duplicateAllowed: userData?.duplicateAllowed,
						duplicateControl: userData?.duplicateControl,
						leftOverInventory: userData?.leftOverInventory,
					},
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Problem in process config',
			});
			// eslint-disable-next-line no-console
			// console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		if (processId) {
			fetchUser();
		}
	}, [processId]);
	const opreationProcess = useMemo(() => {
		const processData = processTypes?.filter((item) => item?.data?.type === 'OPERATION');
		const data = [
			...processData?.map((item) => {
				return {
					label: item?.data?.heading,
					value: item?.id,
				};
			}),
		];
		return data;
	}, [processTypes]);

	return (
		<>
			<BackdropLoader showProgress isLoading={isLoading} />
			<CreatePageHeader
				handleSubmit={handleSubmit}
				showCancel={false}
				heading={`${isEdit ? 'Edit' : 'Create'} Process Config`}
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className='m-w-[80%] mx-3 my-3 flex flex-col gap-3'>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-red-600 w-[12rem]'> Select Store*</label>
					<InputPicker
						data={storesList?.map((item) => {
							return {
								label: item?.name,
								value: item?.id,
							};
						})}
						value={store}
						placeholder='Enter the Store'
						onSelect={(val) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									store: val,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-red-600 w-[12rem]'> Select Process*</label>
					<InputPicker
						data={opreationProcess}
						value={processId}
						onSelect={(val) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									processId: val,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-[#5f5f5f] w-[12rem]'> Quality Control</label>
					<Toggle
						id='qualityControl'
						name='qualityControl'
						size='sm'
						checked={qualityControl}
						onChange={(checked) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									qualityControl: checked,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-[#5f5f5f] w-[12rem]'> Quality Override</label>
					<Toggle
						id='overrideAllowed'
						name='overrideAllowed'
						size='sm'
						checked={overrideAllowed}
						onChange={(checked) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									overrideAllowed: checked,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-[#5f5f5f] w-[12rem]'> Duplicate Control </label>
					<Toggle
						id='duplicateControl'
						name='duplicateControl'
						size='sm'
						checked={duplicateControl}
						onChange={(checked) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									duplicateControl: checked,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-[#5f5f5f] w-[12rem]'> Duplicate Override</label>
					<Toggle
						id='duplicateAllowed'
						name='duplicateAllowed'
						size='sm'
						checked={duplicateAllowed}
						onChange={(checked) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									duplicateAllowed: checked,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-[#5f5f5f] w-[12rem]'>Supervisor Control</label>
					<Toggle
						id='supervisorControl'
						name='supervisorControl'
						size='sm'
						checked={supervisorControl}
						onChange={(checked) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									supervisorControl: checked,
								},
							});
						}}
					/>
				</div>
				<div className='h-12 flex gap-5 justify-start items-center'>
					<label className='text-base text-[#5f5f5f] w-[12rem]'>Left Over Inventory</label>
					<Toggle
						id='leftOverInventory'
						name='leftOverInventory'
						size='sm'
						checked={leftOverInventory}
						onChange={(checked) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									leftOverInventory: checked,
								},
							});
						}}
					/>
				</div>
			</div>
		</>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const storesList = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;

	return {
		userPermissions,
		storesList,
		processTypes,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessConfig);
