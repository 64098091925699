import { Tooltip } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import styles from './RecipeProductCard.module.scss';

const RecipeProductCard = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		children,
		title,
		img,
		handleDelete,
		dataAttrs,
		isDisabled,
		className = '',
	} = props;

	return (
		<div className={`${styles.root} ${className}`}>
			<div className={`${styles['image-wrapper']} rounded-xl`}>
				<img
					className={`${styles.image} rounded-xl`}
					alt='choice'
					src={img}
					loading='lazy'
				/>
				{!isDisabled && (
					<Tooltip title='Delete Item' placement='right-start'>
						<Cancel
							className={`${styles['delete-icon']} z-[3]`}
							onClick={handleDelete}
							{...dataAttrs}
						/>
					</Tooltip>
				)}
				<div className='w-full h-full absolute z-[2] top-0 gradient1 rounded-[14px]' />
				<span
					className={`${styles.title} w-full absolute z-[3] bottom-0 text-white font-semibold text-center`}
				>
					{title}
				</span>
			</div>
			<div className='p-2'>{children}</div>
		</div>
	);
};

export default RecipeProductCard;
