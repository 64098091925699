import { Button } from '@mui/material';
import { connect } from 'react-redux';
import analyticsAction from '../../../../Actions/analytics.actions';
import { urls } from '../../../../Constants';
// eslint-disable-next-line object-curly-newline
import { classes, history, useTimeFilter } from '../../../../Helpers';
import TimeFilter from '../../../TimeFilter/TimeFilter';
import { LineChart } from '../../Charts';
import styles from './QualityStatisticalGraph.module.scss';

const DISCRETE_TIME_FILTERS = [
	{
		text: 'YTD',
		type: 'year',
		active: true,
	},
	{
		text: 'MTD',
		type: 'month',
		active: false,
	},
	{
		text: 'DTD',
		type: 'day',
		active: false,
	},
];

const QualityStatisticalGraph = (props) => {
	const { graphData, graphAnnotations, applyFilters, statisticalFilter } =
		props;

	const { setTimeFilter, timeFilters } = useTimeFilter({
		DISCRETE_TIME_FILTERS,
		applyFilters,
	});

	return (
		<div className={styles.root}>
			<Button
				className={classes(styles['list-button'])}
				variant='contained'
				color='primary'
				onClick={() => {
					applyFilters({
						PRODUCT_FILTER: [
							...statisticalFilter.selectedProductIds,
						],
					});
					history.push(
						`${urls.EXTENSION}${urls.QUALITY_ANALYSIS_LIST}`
					);
				}}
			>
				View List
			</Button>
			<LineChart
				className={styles.chart}
				data={graphData}
				annotations={graphAnnotations}
				legendDisplay={false}
				xScale={{
					type: 'linear',
				}}
			/>
			<TimeFilter timeFilters={timeFilters} onClick={setTimeFilter} />
		</div>
	);
};

QualityStatisticalGraph.defaultProps = {
	graphData: {
		labels: [],
		datasets: [],
	},
	graphAnnotations: {},
};

const mapStateToProps = (state) => {
	const { STATISTICAL_FILTER: statisticalFilter } = state.analytics;
	return {
		statisticalFilter,
	};
};

const mapDispatchToProps = {
	applyFilters: analyticsAction.applyFilters,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityStatisticalGraph);
