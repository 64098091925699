import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import styles from './StoreDesign.module.scss';
import { generateImageUrl } from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import { modalActions } from '../../../../Actions';
import { notificationServices } from '../../../../Services';

const StoreDesign = (props) => {
	const {
		entity,
		handleBannerList,
		bannerList,
		isDisabled,
		toggleImageCrop,
		// aspectRatio,
		bannerListMobile,
	} = props;
	const [list, setList] = useState(bannerList ?? []);
	const [mobileList, setMobileList] = useState(bannerListMobile ?? []);

	useEffect(() => {
		if (bannerList !== list) {
			setList(bannerList);
		}

		if (bannerListMobile !== mobileList) {
			setMobileList(bannerListMobile);
		}
	}, [bannerList, bannerListMobile]);

	const fileHandler = (idx, file = null) => {
		if (file) {
			toggleImageCrop(true, {
				name: idx,
				file,
			});
		}
	};

	const addBanner = () => {
		if (entity === 'DESKTOP') {
			if ((bannerList ?? []).length >= 5) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'You can upload only 5 banner images',
				});
				return;
			}

			const l = [...list];
			l.push('');
			setList(l);
			handleBannerList(l, entity);
		} else {
			if ((bannerListMobile ?? []).length >= 5) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'You can upload only 5 banner images',
				});
				return;
			}

			const l = [...mobileList];
			l.push('');
			setMobileList(l);
			handleBannerList(l, entity);
		}
	};

	const deleteBanner = (idx) => {
		if (entity === 'DESKTOP') {
			const l = [...list];
			l.splice(idx, 1);
			setList(l);
			handleBannerList(l, entity);
		} else {
			const l = [...(mobileList ?? [])];
			l.splice(idx, 1);
			setMobileList(l);
			handleBannerList(l, entity);
		}
	};

	const handleImageInput = (idx, blob) => {
		if (entity === 'DESKTOP') {
			const l = [...list];
			l[idx] = blob;
			setList(l);
			handleBannerList(l, entity);
		} else {
			const l = [...mobileList];
			l[idx] = blob;
			setMobileList(l);
			handleBannerList(l, entity);
		}
	};
	return (
		entity === 'DESKTOP'
			? (
				<div
					className={styles.root}
					style={{
						width: '100%',
					}}
				>
					<div className={`${styles['table-btn-container']}`}>
						{!isDisabled && (
							<div className={`${styles['add-btn']}`}>
								<Button
									variant='contained'
									color='primary'
									onClick={addBanner}
								>
									Add New Banner
								</Button>
							</div>
						)}
					</div>
					<div>
						<table
							className={`${styles['list-table-root']}`}
							style={{
								border: '1px solid black',
								borderRadius: 10,
								textAlign: 'center',
								width: '100%',
								marginBottom: 20,
								marginTop: 20,
							}}
						>
							<thead>
								<tr>
									<th>Sr. No</th>
									<th>Image</th>
									{!isDisabled && <th>Delete</th>}
								</tr>
							</thead>
							<tbody>
								{bannerList &&
							bannerList.map((elem, idx) => {
								return (
									<tr>
										<td>{idx + 1}</td>
										<td>
											<div className='inputs'>
												<input
													type='file'
													id={`banner-${idx}`}
													name='bannerImg'
													accept='image/png, image/jpg, image/jpeg, image/webp'
													onChange={(e) => {
														return fileHandler(
															idx,
															e.target.files[0]
														);
													}}
													style={{
														opacity: 0,
														width: 1,
														height: 1,
													}}
												/>
												<img
													src={
														isDisabled
															? elem
															: generateImageUrl(
																elem
															)
													}
													alt='Banner'
													className='bannerPreview'
												/>
											</div>
											{typeof elem !== 'string' ||
												(elem === '' && (
													<div className='btn upload-img'>
														<Button
															variant='outlined'
															size='small'
															color='primary'
														>
															<label
																htmlFor={`banner-${idx}`}
															>
																Upload Banner Image
															</label>
														</Button>
													</div>
												))}
										</td>
										{!isDisabled && (
											<td>
												<div className='btn'>
													<DeleteIcon
														onClick={() => {
															return deleteBanner(
																idx
															);
														}}
														style={{
															color: 'red',
															cursor: 'pointer',
														}}
													/>
												</div>
											</td>
										)}
									</tr>
								);
							})}
							</tbody>
							{/*  */}
						</table>
						<ImageCropModal
							handleConfirm={handleImageInput}
							aspectRatio='4:3'
						/>
					</div>
				</div>
			)
			: (
				<div
					className={styles.root}
					style={{
						width: '100%',
					}}
				>
					<div className={`${styles['table-btn-container']}`}>
						{!isDisabled && (
							<div className={`${styles['add-btn']}`}>
								<Button
									variant='contained'
									color='primary'
									onClick={addBanner}
								>
									Add New Banner
								</Button>
							</div>
						)}
					</div>
					<div>
						<table
							className={`${styles['list-table-root']}`}
							style={{
								border: '1px solid black',
								borderRadius: 10,
								textAlign: 'center',
								width: '100%',
								marginBottom: 20,
								marginTop: 20,
							}}
						>
							<thead>
								<tr>
									<th>Sr. No</th>
									<th>Image</th>
									{!isDisabled && <th>Delete</th>}
								</tr>
							</thead>
							<tbody>
								{bannerListMobile &&
					bannerListMobile.map((elem, idx) => {
						return (
							<tr>
								<td>{idx + 1}</td>
								<td>
									<div className='inputs'>
										<input
											type='file'
											id={`banner-${idx}`}
											name='bannerImg'
											accept='image/png, image/jpg, image/jpeg, image/webp'
											onChange={(e) => {
												return fileHandler(
													idx,
													e.target.files[0]
												);
											}}
											style={{
												opacity: 0,
												width: 1,
												height: 1,
											}}
										/>
										<img
											src={
												isDisabled
													? elem
													: generateImageUrl(
														elem
													)
											}
											alt='Banner'
											className='bannerPreview'
										/>
									</div>
									{typeof elem !== 'string' ||
										(elem === '' && (
											<div className='btn upload-img'>
												<Button
													variant='outlined'
													size='small'
													color='primary'
												>
													<label
														htmlFor={`banner-${idx}`}
													>
														Upload Banner Image
													</label>
												</Button>
											</div>
										))}
								</td>
								{!isDisabled && (
									<td>
										<div className='btn'>
											<DeleteIcon
												onClick={() => {
													return deleteBanner(
														idx
													);
												}}
												style={{
													color: 'red',
													cursor: 'pointer',
												}}
											/>
										</div>
									</td>
								)}
							</tr>
						);
					})}
							</tbody>
							{/*  */}
						</table>
						<ImageCropModal
							handleConfirm={handleImageInput}
							aspectRatio='9:16'
						/>
					</div>
				</div>
			)
	);
};

StoreDesign.defaultProps = {
	isDisabled: false,
	isEdit: true,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = () => {};

const mapDispatchToProps = {
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreDesign);
