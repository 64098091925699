/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const qualityAnalyticsServices = {
	gateway,
	fetchQualityAnalysisGraph,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CHART_DATA':
			return fetchQualityAnalysisGraph({
				req,
			});
		case 'LIST_DATA':
			return fetchQualityAnalysisList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function fetchQualityAnalysisGraph({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.QUALITY_ANALYTICS, requestOptions).then(
		handleResponse
	);
	// const responseData1 = {
	// 	message: 'Success',
	// 	status: 'Success',
	// 	statusCode: 200,
	// 	data: {
	// 		brands: {
	// 			brandId: {
	// 				name: 'Hellooo',
	// 				image: 'abcdddd',
	// 			},
	// 		},
	// 		statsData: {
	// 			Total: {
	// 				label: 'Total',
	// 				value: 123,
	// 			},
	// 			'Passed %': {
	// 				label: 'Passed %',
	// 				value: 42.5,
	// 			},
	// 			'Weight Failure': {
	// 				label: 'Weight Failure',
	// 				value: 48,
	// 			},
	// 			'Temp Failure': {
	// 				label: 'Temp Failure',
	// 				value: 49,
	// 			},
	// 			'Food texture': {
	// 				label: 'Food texture',
	// 				value: 48,
	// 			},
	// 			Cpk: {
	// 				label: 'Cpk',
	// 				value: 48,
	// 			},
	// 		},
	// 		chartData: {
	// 			labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	// 			passed: [0, 0, 15, 16, 24, 0, 0, 0, 0, 2, 0, 0],
	// 			failed: [0, 0, 0, 0, 0, 0, 0, 16, 4, 0, 0, 0],
	// 		},
	// 	},
	// };
	// const responseData2 = {
	// 	message: 'Success',
	// 	status: 'Success',
	// 	statusCode: 200,
	// 	data: {
	// 		brands: {
	// 			brandId: {
	// 				name: 'Hellooo',
	// 				image: 'abcdddd',
	// 			},
	// 		},
	// 		statsData: {
	// 			Total: {
	// 				label: 'Total',
	// 				value: 123,
	// 			},
	// 			'Passed %': {
	// 				label: 'Passed %',
	// 				value: 42.5,
	// 			},
	// 			'Weight Failure': {
	// 				label: 'Weight Failure',
	// 				value: 48,
	// 			},
	// 			'Temp Failure': {
	// 				label: 'Temp Failure',
	// 				value: 49,
	// 			},
	// 			'Food texture': {
	// 				label: 'Food texture',
	// 				value: 48,
	// 			},
	// 			Cpk: {
	// 				label: 'Cpk',
	// 				value: 48,
	// 			},
	// 		},
	// 		chartData: {
	// 			labels: [
	// 				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
	// 				18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
	// 			],
	// 			passed: [
	// 				0, 0, 15, 16, 24, 0, 0, 0, 0, 2, 0, 0, 0, 0, 15, 16, 24, 0,
	// 				0, 0, 0, 2, 0, 0, 0, 0, 0, 2, 0, 0,
	// 			],
	// 			failed: [
	// 				0, 0, 15, 16, 24, 0, 0, 0, 0, 2, 0, 0, 0, 0, 15, 16, 24, 0,
	// 				0, 0, 0, 2, 0, 0, 0, 0, 0, 2, 0, 0,
	// 			],
	// 		},
	// 	},
	// };
	// const responseData3 = {
	// 	message: 'Success',
	// 	status: 'Success',
	// 	statusCode: 200,
	// 	data: {
	// 		brands: {
	// 			brandId: {
	// 				name: 'Hellooo',
	// 				image: 'abcdddd',
	// 			},
	// 		},
	// 		statsData: {
	// 			Total: {
	// 				label: 'Total',
	// 				value: 123,
	// 			},
	// 			'Passed %': {
	// 				label: 'Passed %',
	// 				value: 42.5,
	// 			},
	// 			'Weight Failure': {
	// 				label: 'Weight Failure',
	// 				value: 48,
	// 			},
	// 			'Temp Failure': {
	// 				label: 'Temp Failure',
	// 				value: 49,
	// 			},
	// 			'Food texture': {
	// 				label: 'Food texture',
	// 				value: 48,
	// 			},
	// 			Cpk: {
	// 				label: 'Cpk',
	// 				value: 48,
	// 			},
	// 		},
	// 		chartData: {
	// 			labels: [
	// 				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
	// 				18, 19, 20, 21, 22, 23, 24,
	// 			],
	// 			passed: [
	// 				0, 0, 15, 16, 24, 0, 0, 0, 0, 2, 0, 0, 0, 0, 15, 16, 24, 0,
	// 				0, 0, 0, 2, 0, 0,
	// 			],
	// 			failed: [
	// 				0, 0, 15, 16, 24, 0, 0, 0, 0, 2, 0, 0, 0, 0, 15, 16, 24, 0,
	// 				0, 0, 0, 2, 0, 0,
	// 			],
	// 		},
	// 	},
	// };
	// const responseData4 = {
	// 	message: 'Success',
	// 	status: 'Success',
	// 	statusCode: 200,
	// 	data: {
	// 		listData: [
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4063',
	// 				ProductName: 'Rice',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Rice-11-10-21_17%3A59%3A27.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=de853e57e1ef80e060b662e823722d154eba8ec8f9b6b85dca5f84e95cb7130b',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:18:34',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Rice/21_10_10%3A18%3A33.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=6a13d9627cd1acdf39124febb9b0a1e3bf5c934ccb083a4b34d2b44f5461d9c1',
	// 				MinWeight: 50000,
	// 				Weight: 4.33,
	// 				MaxWeight: 51000,
	// 				MinTemp: 24,
	// 				Temp: 0,
	// 				foodLabel: 'Food 5',
	// 				MaxTemp: 36,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4072',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:22:38',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A22%3A38.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=48de12b4e5e20b554841340ef1033a9511f3a6dc3266d60fb682abca7e848027',
	// 				MinWeight: 50000,
	// 				Weight: 2.02,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4065',
	// 				ProductName: 'Rice',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Rice-11-10-21_17%3A59%3A27.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=de853e57e1ef80e060b662e823722d154eba8ec8f9b6b85dca5f84e95cb7130b',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:29:19',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Rice/21_10_10%3A29%3A19.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=43555bfea61d461ecf7ba9f7b46002ddd8c7dbf3b3c1187054eea563590979f7',
	// 				MinWeight: 50000,
	// 				Weight: 2.02,
	// 				MaxWeight: 51000,
	// 				MinTemp: 24,
	// 				Temp: 0,
	// 				foodLabel: 'Food 5',
	// 				MaxTemp: 36,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4064',
	// 				ProductName: 'Rice',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Rice-11-10-21_17%3A59%3A27.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=de853e57e1ef80e060b662e823722d154eba8ec8f9b6b85dca5f84e95cb7130b',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:42:04',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Rice/21_10_10%3A42%3A03.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=b238b4bc76392fac6c01fe2162fbd91e3b59a760e28032a2901c2127b881711f',
	// 				MinWeight: 50000,
	// 				Weight: 1.27,
	// 				MaxWeight: 51000,
	// 				MinTemp: 24,
	// 				Temp: 0,
	// 				foodLabel: 'Food 5',
	// 				MaxTemp: 36,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4067',
	// 				ProductName: 'Toor daal',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Toor%20daal-11-10-21_18%3A01%3A45.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f3733c0d047bdfd132737fe5da45feea91c85ef2d8cb32741ef4db6000061296',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:44:26',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Toor%20daal/21_10_10%3A44%3A26.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=bf6a9de21093468e31f8aa1b67b15a153d54ffe565ee4bb2653187ec21ef346f',
	// 				MinWeight: 10000,
	// 				Weight: 2.63,
	// 				MaxWeight: 10000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4071',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:45:03',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A45%3A02.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=4035b59352ed081472dc36febc5ae60b7496b19bfeaf10f05feb72c55e9725f8',
	// 				MinWeight: 50000,
	// 				Weight: 2.64,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4074',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:45:38',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A45%3A38.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=42fc5fe2386d9db87e4be3b4f754efc6e73ce3ad861eba13ddd5f6eefe0d28f9',
	// 				MinWeight: 50000,
	// 				Weight: 1.92,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4073',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:46:01',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A46%3A01.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=057cecddcd81eabcf81072dffd386314355563e8abdc10ca5dc6f0687470a35b',
	// 				MinWeight: 50000,
	// 				Weight: 2.69,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100033',
	// 				itemId: '4126',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:50:18',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A50%3A18.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=9797ce049bd3bfdcb3f99ce893219c3816d97bba74bffd6d60494899d4181de3',
	// 				MinWeight: 50000,
	// 				Weight: 2.64,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100033',
	// 				itemId: '4127',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:50:56',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A50%3A55.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=35683fe9e03a6bdb0209388ffbeab69cf3c2c73b50ceafd98a268c7eff510213',
	// 				MinWeight: 50000,
	// 				Weight: 0.76,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4072',
	// 				ProductName: 'Tomato',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Tomato-11-10-21_18%3A05%3A13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=7d791d2ff8418617d0af6cbb7f790102661b2d97f4d436a9ce464a870e79b754',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:51:33',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Tomato/21_10_10%3A51%3A32.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=a823eb90b3bc245ec2b962a961b9a4f293486cb0fb0e829060606096693105d7',
	// 				MinWeight: 50000,
	// 				Weight: 2.64,
	// 				MaxWeight: 51000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4068',
	// 				ProductName: 'Toor daal',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Toor%20daal-11-10-21_18%3A01%3A45.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f3733c0d047bdfd132737fe5da45feea91c85ef2d8cb32741ef4db6000061296',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:52:11',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Toor%20daal/21_10_10%3A52%3A10.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=03f2e057990a0ad4ceef7dc4912ace5263caedbb9c0a7d6f0a9c2b38bce7918e',
	// 				MinWeight: 10000,
	// 				Weight: 1.66,
	// 				MaxWeight: 10000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4064',
	// 				ProductName: 'Rice',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Rice-11-10-21_17%3A59%3A27.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=de853e57e1ef80e060b662e823722d154eba8ec8f9b6b85dca5f84e95cb7130b',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:56:19',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Rice/21_10_10%3A56%3A18.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=6bfb297ce521d8b7f063a61beda49673358e21e5cff455b7b8c88ea1a46d237a',
	// 				MinWeight: 50000,
	// 				Weight: 1.7,
	// 				MaxWeight: 51000,
	// 				MinTemp: 24,
	// 				Temp: 0,
	// 				foodLabel: 'Food 5',
	// 				MaxTemp: 36,
	// 			},
	// 			{
	// 				OrderId: '100015',
	// 				itemId: '4070',
	// 				ProductName: 'Toor daal',
	// 				ProductImage:
	// 					'https://resoee.s3.amazonaws.com/6/Product/Toor%20daal-11-10-21_18%3A01%3A45.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f3733c0d047bdfd132737fe5da45feea91c85ef2d8cb32741ef4db6000061296',
	// 				BrandImage:
	// 					'https://resoee.s3.amazonaws.com/6/Brand/Chandni%20Chowk-20-10-21_19%3A56%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=f396c9217699bf76465cc71e294e4c9fa7959985ca8caf00f195f7f54d1aaf33',
	// 				QCTime: '21/10/21 10:57:20',
	// 				isVeg: 1,
	// 				IsWeightInRange: false,
	// 				WeightCheckRequired: true,
	// 				TempCheckRequired: true,
	// 				IsTempInRange: false,
	// 				Result: 0,
	// 				otherTemp: 34,
	// 				isTempMax: false,
	// 				FoodImage:
	// 					'https://resoee.s3.amazonaws.com/liveqcdata/Toor%20daal/21_10_10%3A57%3A20.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWJYV4FDLOTPTR56M%2F20220329%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T090034Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaCmFwLXNvdXRoLTEiRzBFAiAwr5c5UKdQviaFB50wD2Px232lcXzWO7qyi0KZEaKAnQIhAMkxSCR4FGjLXa263D9GuqGeYh3zh78%2FnnUPKXJpHMpzKoUECM7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNDMzMzAwNzExNjM4IgxeEPSUi32r5RU7qyoq2QPWsKFd9QBMygqd4kWD7q%2F5xWGuVZeDyoHtWOTXeJrkcuQRjgYVenu4NCTDIqFhVUPMzWJTcBPKMcTjfDOgjdCBjzvnzkBtNA8KfdMfH9fS9dzsRLRNnCu1x1CWqL2XQ6l96k4N2gj4D7vdyW31tIp63h39A7F9v%2FsqLymLm0VYR6MP%2BgqlDk7elfAcBRTGnlMzP2PCS2tloeNK7JD3W90O3wLFX1ZfIUbyeei3n%2FqxdDovqsO5WyzG%2BrXc%2BMiORBmK9JAGdeh68XiP%2FLLhV66dLDqUu%2BHxykAl5lYdpe%2BUjfsRvSR5DhZPU0ReLX8eA3q7jlGlMbsFzeAjcEIWaw4HmvYMAnPWyYIHeF85FQzMEV6fFcwn3%2FVl%2BD%2FXgUfxqJZh81sdU4M7%2Bc46MFuO8F4gqW4bPyKHIMjlMZ06c2ZLv9bdGQL6cMit21qWPTFoEiX8i2RZQ4Yd7S4c7pFaR%2F1odxmQHYFvCGnM%2FXyoGP3TB97BFaXzC1lYUSVhaS3REeNQMxHeZMsd2m6gqJmHw%2B1OunZBbtTnayFtxZ%2FKHC2zmQX0SOtONGYQRerQqedN9ewFwM17n%2F32w1c8hV4%2FOBQohclWzYKlp9CsR%2FmP14KDclNLuWHO8HCEPTCGnoqSBjqlAQHuyIpXU6XVihvZi85sdpnJEgcGwScX14MR52ttkbtmM7viYsqEpXBFMuqNZs3YWSQi9DkveA3pv7L79pFOj96bH%2Bj06cIHrROGSd8ZdkyP6vj5aLMyIWFVBsGCVhSDvcENjmYfCcETqzBD0%2FNbaMzGetQXV%2FiQnfdf0gUpfaXtAC0KdaT1VTT64pv3NZPaQzli8EDKTtUrvzQcIPDZu7FeMjTPKw%3D%3D&X-Amz-Signature=aa78dd8812ee78edc6445c0b606c690e76740a2d13456a810ffd51265ac849e0',
	// 				MinWeight: 10000,
	// 				Weight: 1.66,
	// 				MaxWeight: 10000,
	// 				MinTemp: 21,
	// 				Temp: 0,
	// 				foodLabel: null,
	// 				MaxTemp: 39,
	// 			},
	// 		],
	// 	},
	// };
	// return fetch(endpoints.ANALYTICS, requestOptions).then(() => {
	// 	if (req.dateFilter.hour) {
	// 		return responseData4.data;
	// 	}
	// 	if (req.dateFilter.day) {
	// 		return responseData3.data;
	// 	}
	// 	if (req.dateFilter.month) {
	// 		return responseData2.data;
	// 	}
	// 	return responseData1;
	// });
}

function fetchQualityAnalysisList({ req }) {
	const { params = {} } = req;
	// console.log('passed req', {
	// req,
	// });
	// const url = toQueryStringURL(endpoints.QUALITY_ANALYTICS_LIST, params);
	const url = toQueryStringURL(endpoints.QUALITY_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// clientID: 52,
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockQAList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

// function mockQAList() {
// 	const mockResponse = {
// 		data: [
// 			{
// 				BrandImage:
// 					'https://resoee.s3.amazonaws.com/5/Brand/North%20Star-21-04-22_16%3A46%3A48.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220721%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220721T054108Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=4a9b99e15e795cbb264f00cc014164b8f08026cf1ee700435ae63c2d152dd70e',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/5/QualityCheck/0d455183-f103-4c14-b1b5-7c5314cd6550.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220721%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220721T054919Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=557075c3b5f3bc25a4e04197da70fc9232480756d927dac254b4d35159a9c55a',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 30,
// 				MaxWeight: 800,
// 				MinTemp: 25,
// 				MinWeight: 700,
// 				OrderId: '1657620388475',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/5/ProductCategory/Paneer%20Onion%20Pizza-09-06-22_22%3A29%3A20.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220721%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220721T054108Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=6f48d7dd9f80a3b5509259c8ab73af3bd731706e43985859e58e8b33a9917594',
// 				ProductName: 'Paneer Onion Pizza',
// 				QCTime: '12/07/22 15:36:28',
// 				Result: 1,
// 				Temp: 27.6,
// 				TempCheckRequired: true,
// 				Weight: 777.7,
// 				WeightCheckRequired: true,
// 				annotated: 6,
// 				brandId: 89,
// 				brandName: 'North Star',
// 				foodLabel: null,
// 				id: 2906,
// 				identification: [
// 					{
// 						height: 200.48979591836732,
// 						id: null,
// 						label: 'Garlic Bread',
// 						labelId: 503,
// 						width: 203.1708516986799,
// 						x: 327.2527040336868,
// 						y: 224.32653061224488,
// 					},
// 				],
// 				isTempMax: null,
// 				isVeg: 1,
// 				itemId: '1657620388475',
// 				otherTemp: 26.7,
// 				productId: 488,
// 				result: 'PASSED',
// 				validation: true,
// 				temperature: {
// 					sensorValues: [
// 						{
// 							x: 150,
// 							y: 240,
// 							temperature: 21.2,
// 							width: 50,
// 							height: 50,
// 						},
// 						{
// 							x: 250,
// 							y: 240,
// 							temperature: 30.2,
// 							width: 50,
// 							height: 50,
// 						},
// 						{
// 							x: 350,
// 							y: 240,
// 							temperature: 45.2,
// 							width: 50,
// 							height: 50,
// 						},
// 						{
// 							x: 450,
// 							y: 240,
// 							temperature: 10.2,
// 							width: 50,
// 							height: 50,
// 						},
// 						{
// 							x: 590,
// 							y: 430,
// 							temperature: 10.2,
// 							width: 50,
// 							height: 50,
// 						},
// 					],
// 				},
// 			},
// 		],
// 	};

// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 1000);
// 	});
// }

export default qualityAnalyticsServices;
