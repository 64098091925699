/* eslint-disable no-tabs */
import { endpoints } from '../Constants';
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const brandServices = {
	gateway,
	createBrand,
	viewBrands,
	getBrands,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createBrand({
				req,
			});
		case 'VIEW':
			return viewBrands({
				req,
			});
		case 'LIST':
			return listBrands({
				req,
			});
		case 'UPDATE':
			return updateBrand({
				req,
			});
		case 'ENABLE':
			return toggleDisableBrand({
				req,
			});
		case 'DELETE':
			return deleteBrand({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createBrand({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.BRAND, requestOptions).then(handleResponse);
}

function updateBrand({ req }) {
	req.data.id = req.params.id;
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.BRAND, params);
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewBrands({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.BRAND, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function listBrands({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.BRAND, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function toggleDisableBrand({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.BRAND, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function getBrands(req = {}) {
	const params = {
		...req,
	};
	const url = toQueryStringURL(endpoints.GET_BRANDS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function deleteBrand({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.BRAND, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default brandServices;
