/* eslint-disable no-unused-vars */
import { Tooltip, IconButton } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { ArrowBack, OnlinePrediction } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { urls } from '../../../Constants';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
import { classes } from '../../../Helpers';
import styles from './LiveQCHeader.module.scss';
import { PageNavigation } from '../PageNavigation';

function LiveQCHeader(props) {
	const {
		isLiveQC,
		live,
		currentDate,
		totalQCS,
		passedQCS,
		passPercentage,
		passPercentColor,
		lastQcAt,
		idx,
		orderId,
		itemId,
		scanId,
		updatedAt,
		selectedStoreName,
		listData,
		storeSelector,
		storeList,
		machine,
		navigateQC,
		analyticsType,
		modeType,
		machineName,
		scanType,
		processName,
		expiryTime,
		clientPoId,
		stockedOutAt,
	} = props;
	const history = useHistory();
	const fromRoute =
		history?.location?.state?.from ??
		`${urls.EXTENSION}${urls.QC_HISTORY_LIST}`;

	const getLastQCTime = () => {
		if (lastQcAt === '') {
			if (isLiveQC === true) {
				return 'Not Active';
			}
			return `${currentDate ?? '-'} ${lastQcAt ?? '-'} `;
		}
		return lastQcAt;
	};

	const handleGoLive = async () => {
		history.push(`${urls.EXTENSION}${urls.LIVE_QC}`);
	};

	return (
		<div className={classes(styles.root)}>
			{/* go back */}
			{!live && (
				<Tooltip title='Go Back' arrow>
					<IconButton
						onClick={() => {
							// if (analyticsType === analyticsTypeConstant.QUALITY_HISTORY_LIST_DATA) {
							// history.push(`${urls.EXTENSION}${urls.QC_HISTORY_LIST}`);
							// } else if (
							// analyticsType === analyticsTypeConstant.COOKING_HISTORY_LIST_DATA
							// ) {
							// history.goBack();
							// } else if (
							// analyticsType === analyticsTypeConstant.WASTAGE_HISTORY_LIST_DATA
							// ) {
							// history.push(`${urls.EXTENSION}${urls.WASTAGE_HISTORY_LIST}`);
							// } else {
							// history.push(`${urls.EXTENSION}${urls.QUALITY_ANALYSIS_LIST}`);
							// }
							history.push(fromRoute);
						}}
						size='large'
					>
						<ArrowBack />
					</IconButton>
				</Tooltip>
			)}
			{/* current date */}
			{/* <div className={styles.stat}>
				<div className={styles.label}>Current Date</div>
				<div className={styles.value}>
					{currentDate === '' ? 'Not Active' : currentDate ?? '-'}
				</div>
			</div> */}
			<div className={styles.stat}>
				<div className={styles.label}>Mode</div>
				<div className={styles.value}>{modeType || '-'}</div>
			</div>
			<div className={styles.stat}>
				<div className={styles.label}>Scan Type</div>
				<div className={styles.value}>{scanType || '-'}</div>
			</div>
			<div className={styles.stat}>
				<div className={styles.label}>Process name</div>
				<div className={styles.value}>{processName || '-'}</div>
			</div>
			<div className={styles.stat}>
				<div className={styles.label}>Machine name</div>
				<div className={styles.value}>{machineName || '-'}</div>
			</div>
			{/* pass % */}
			{live && (
				<div className={styles.stat}>
					<div className={styles.label}>Passed QC %</div>
					<div
						className={styles.value}
						style={{
							color: passPercentColor,
						}}
					>
						{totalQCS === '-' || totalQCS === 0
							? 'Not Active'
							: `${passedQCS}/${totalQCS}(${passPercentage}%)` ??
							'-'}
					</div>
				</div>
			)}
			{/* qc time */}
			<div className={styles.stat}>
				<div className={styles.label}>QC Time</div>
				<div className={styles.value}>{getLastQCTime()}</div>
			</div>
			{/* expiry */}
			<div className={styles.stat}>
				<div className={styles.label}>Expiry</div>
				<div className={styles.value}>{expiryTime ?? '-'}</div>
			</div>
			{/* stockedOutAt */}
			<div className={styles.stat}>
				<div className={styles.label}>Stock Out</div>
				<div className={styles.value}>{stockedOutAt ?? '-'}</div>
			</div>
			<div className={styles.stat}>
				<div className={styles.label}>Order</div>
				<div className={styles.value}>{clientPoId || '-'}</div>
			</div>
			{/* qc navigation */}
			<PageNavigation
				navigate={navigateQC}
				prev={{
					tooltip: 'Prev Order',
					show: idx > 0 && !isLiveQC,
					disabled: idx === 0 || isLiveQC,
				}}
				current={{
					label: 'ID',
					value:
						orderId === ''
							? 'Not Active'
							: `${scanId === '' ? '' : `${scanId ?? '-'}`}`,
				}}
				next={{
					tooltip: 'Next Order',
					show: idx >= 0 && idx < listData.length - 1 && !isLiveQC,
					disabled:
						(idx < 0 && idx === listData.length - 1) || isLiveQC,
				}}
			/>
			{/* last updated at */}
			{live && (
				<div className={styles.stat}>
					<div className={styles.label}>Last Updated At</div>
					<div className={styles.value}>
						{updatedAt === '' ? 'Not Active' : updatedAt ?? '-'}
					</div>
				</div>
			)}
			{/* store selection */}
			{live && storeList != null && (
				<div className={styles.stat}>
					<div className={styles.label}>Select Store</div>
					{storeList?.length > 0 && (
						<select
							className={classes(styles.value, styles.select)}
							selected={selectedStoreName}
							onChange={storeSelector}
						>
							<option value=''>None</option>
							{storeList?.map((elem, index) => {
								return (
									<option value={index}>{elem?.name}</option>
								);
							})}
						</select>
					)}
				</div>
			)}
			{/* machine status / go live */}
			{/* {live ? (
				<div
					className={classes(
						styles['machine-status'],
						styles[machine?.status ?? 'OFFLINE']
					)}
				>
					<OnlinePrediction />
					{machine?.status}
				</div>
			) : (
				<button className={styles.live} onClick={handleGoLive} type='button'>
					Go Live
				</button>
			)} */}
		</div>
	);
}

LiveQCHeader.defaultProps = {
	isLiveQC: true,
	live: false,
	currentDate: new Date().toISOString().slice(0, 10),
	totalQCS: '-',
	passedQCS: '-',
	passPercentage: '-',
	passPercentColor: '',
	lastQcAt: '',
	idx: -2,
	orderId: '',
	itemId: '',
	scanId: '',
	updatedAt: '',
	selectedStoreName: '',
	updateStates: () => {},
	listData: [],
	storeList: [],
	storeSelector: () => {},
	navigateQC: () => {},
};
const mapStateToProps = (state) => {
	const { analyticsType } = state.analytics;
	return {
		analyticsType,
	};
};
const mapDispatchToProps = () => {};
export default connect(mapStateToProps, mapDispatchToProps)(LiveQCHeader);
