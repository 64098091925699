// eslint-disable-next-line object-curly-newline
import { useRouteMatch } from 'react-router';
import { QualitySettingEditPage } from '../../../Pages/Quality';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';
// <Switch>
// <Route exact path={`${path}`}>
// <CreatePage
// createPage={<QualitySettingEditPage />}
// entity='QUALITY SETTING'
// />
// </Route>
// </Switch>

const QualitySettingRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='QUALITY_SETTING'
			path={path}
			listPage={QualitySettingEditPage}
		/>
	);
};

export default QualitySettingRoutes;
