// eslint-disable-next-line object-curly-newline
import { IconButton, Modal } from '@mui/material';
import { Close, ArrowBackIos } from '@mui/icons-material';
import styles from './BaseModal.module.scss';
import { Button } from '@/Components/ui/button';

const BaseModal = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		width,
		height,
		open,
		heading,
		children,
		renderBtns,
		handleClose,
		handleConfirm,
		aria,
		handleBack,
		showBack,
		showClose,
		className,
		showConfirm,
		customStyles,
		hideFooter = false,
		wrapperClassName = '',
	} = props;

	return (
		<Modal
			className={className}
			open={open}
			onClose={handleClose}
			aria-labelledby={aria.label}
			aria-describedby={aria.description}
		>
			<div
				className={`${styles.root}`}
				style={{
					width,
					height,
					...customStyles,
				}}
			>
				{showClose && (
					<IconButton
						onClick={handleClose}
						className={`${styles['close-button']}`}
						size='large'
					>
						<Close className={`${styles['close-icon']}`} />
					</IconButton>
				)}
				{showBack && (
					<IconButton
						onClick={handleBack}
						className={`${styles['back-button']}`}
						size='large'
					>
						<ArrowBackIos className={`${styles['back-icon']}`} />
					</IconButton>
				)}
				<div className='font-semibold text-lg mb-2 py-1'>{heading}</div>
				<div className={`${styles.body} ${wrapperClassName} ${hideFooter ? styles.full_body : ''}`}>{children}</div>
				{!hideFooter && (
					<div className={`${styles.footer}`}>
						{renderBtns !== undefined ? (
							renderBtns()
						) : showConfirm && (
							<>
								<Button
									className='flex-1'
									variant='secondary'
									onClick={handleClose}
								>
									Cancel
								</Button>
								<Button
									className='flex-1 primary-btn'
									onClick={handleConfirm}
								>
									Confirm
								</Button>
							</>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
};

BaseModal.defaultProps = {
	width: '30rem',
	height: '30rem',
	aria: {
		label: 'modal',
		description: 'modal',
	},
	showBack: false,
	showConfirm: true,
	showClose: true,
	handleBack: () => {},
	className: '',
};

export default BaseModal;
