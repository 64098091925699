import { Add } from '@mui/icons-material';
import styles from './AddVTab.module.scss';

const AddVTab = (props) => {
	const { text } = props;
	const handleClick = () => {
		props.handleClick();
	};
	return (
		<div className={`${styles.root}`} onClick={handleClick}>
			<Add className={`${styles['add-icon']}`} />
			{`${text}`}
		</div>
	);
};

export default AddVTab;
