import { CheckListItemImage } from './CheckListItemImage';
import './CheckListImage.module.scss';

const CheckList = (props) => {
	const { list, emptyMessage } = props;
	return (
		<div className='check-list'>
			{list.length > 0 ? (
				list.map((item) => {
					return (
						<CheckListItemImage
							key={item.id}
							item={item}
							isSelected={
								// eslint-disable-next-line react/destructuring-assignment
								props.selectedItems.indexOf(item.id) !== -1 ||
								// eslint-disable-next-line react/destructuring-assignment
								props.selectAll
							}
							// eslint-disable-next-line react/destructuring-assignment
							handleItemSelect={props.handleItemSelect}
							// eslint-disable-next-line react/destructuring-assignment
							displayCheck={props.displayCheck}
						/>
					);
				})
			) : (
				<p>{emptyMessage}</p>
			)}
		</div>
	);
};

CheckList.defaultProps = {
	list: [],
	selectedItems: [],
	selectAll: false,
	displayCheck: true,
	handleItemSelect: () => {},
	emptyMessage: 'No items found',
};

export default CheckList;
