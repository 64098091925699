import { cloneDeep } from 'lodash';

const initialLocationState = {
	id: new Date().getTime(),
	name: 'Location 1',
	gstin: '',
	defaultLocationId: null,
	clientLocationId: '',
	locationEmail: '',
	locationPhone: '',
	billingAddress: {
		address1: '',
		address2: '',
		addressCity: '',
		addressState: '',
		addressPinCode: '',
	},
	shippingAddress: {
		address1: '',
		address2: '',
		addressCity: '',
		addressState: '',
		addressPinCode: '',
	},
};

const initialState = {
	defaultVendorId: null,
	name: '',
	legalName: '',
	logoImage: '',
	companyName: '',
	type: null,
	paymentTermCode: null,
	clientVendorId: null,
	isExpenseAllowed: false,
	locations: [initialLocationState],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VENDOR_NAME':
			return {
				...state,
				name: action.payload,
			};
		case 'SET_LEGAL_NAME':
			return {
				...state,
				legalName: action.payload,
			};
		case 'SET_COMPANY_NAME':
			return {
				...state,
				companyName: action.payload,
			};
		case 'SET_LOCATIONS':
			return {
				...state,
				locations: action.payload,
			};
		case 'SET_CLIENT_VENDOR_ID':
			return {
				...state,
				clientVendorId: action.payload,
			};
		case 'SET_PAYMENT_TERM_CODE':
			return {
				...state,
				paymentTermCode: action.payload,
			};
		case 'SET_VENDOR_EMAIL':
			return {
				...state,
				vendorEmail: action.payload,
			};
		case 'SET_VENDOR_PHONE':
			return {
				...state,
				vendorPhone: action.payload,
			};
		case 'SET_BILLING_ADDRESS':
			return {
				...state,
				billingAddress: action.payload,
			};
		case 'SET_SHIPPING_ADDRESS':
			return {
				...state,
				shippingAddress: action.payload,
			};
		case 'SET_TYPE':
			return {
				...state,
				type: action.payload,
			};
		case 'SET_IS_EXPENSE_ALLOWED':
			return {
				...state,
				isExpenseAllowed: action.payload,
			};
		case 'SET_DEFAULT':
			return {
				...initialState,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const config = {
	initialLocationState,
	initialState,
	reducer,
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const newState = {
			...clonedState,
			id: clonedState?.defaultVendorId,
			companyName:
				clonedState?.companyName !== ''
					? clonedState?.companyName
					: null,
			legalName:
				clonedState?.legalName !== ''
					? clonedState?.legalName
					: null,
			// gstin: clonedState?.gstin !== '' ? clonedState?.gstin : null,
			locations: clonedState?.locations?.map((currLocation) => {
				return {
					...currLocation,
					id: currLocation?.defaultLocationId ? currLocation?.defaultLocationId : undefined,
					locationEmail:
						currLocation?.locationEmail !== ''
							? currLocation?.locationEmail
							: null,
					locationPhone:
						currLocation?.locationPhone === ''
							? null
							: Number(currLocation?.locationPhone),
					billingAddress: {
						...currLocation?.billingAddress,
						addressPinCode:
							currLocation?.billingAddress?.addressPinCode === ''
								? null
								: Number(
									currLocation?.billingAddress
										?.addressPinCode
								),
					},
					shippingAddress: {
						...currLocation?.shippingAddress,
						addressPinCode:
							currLocation?.shippingAddress?.addressPinCode === ''
								? null
								: Number(
									currLocation?.shippingAddress
										?.addressPinCode
								),
					},
					clientVendorId:
						currLocation?.clientVendorId !== ''
							? currLocation?.clientVendorId
							: null,
				};
			}),
		};
		return newState;
	},
};

export default config;
