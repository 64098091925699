import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { inputHelper } from '../../../../../Helpers';
import { notificationServices } from '../../../../../Services';
import SlabTag from '../SlabTag/SlabTag';
import styles from './Slabs.module.scss';

const Slabs = (props) => {
	const { fields, isDisabled, handleSlabInput, mandatoryFields } = props;
	const handleClick = () => {
		if (fields.slabs.length < 5) {
			props.addSlab();
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Sorry, you can add only 5 delivery slabs',
			});
		}
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		props.deleteSlab(dataset.id);
	};
	const getStartingPoint = (allTags, idx) => {
		if (idx === 0) return idx;
		if (allTags[idx - 1].deliveryRadius) {
			return allTags[idx - 1].deliveryRadius;
		}
		return "' '";
	};
	const generateTags = (allTags, type) => {
		return allTags.map((tag, idx) => {
			const key = idx + 1;
			return (
				<SlabTag
					className={`${styles.tag}`}
					handleDelete={handleDelete}
					slab={tag}
					idx={idx}
					key={key}
					deliveryFrom={getStartingPoint(allTags, idx)}
					mandatoryFields={mandatoryFields}
					isDisabled={isDisabled}
					handleSlabInput={handleSlabInput}
					dataAttrs={{
						'data-type': type,
						'data-id': idx,
						'data-name': 'slabs',
					}}
				/>
			);
		});
	};
	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}>
			{/* <div className='form-field row'> */}
			<label className='form-label'>Delivery Slabs</label>
			<div className={`${styles['tag-wrapper']}`}>
				{fields.slabs && generateTags(fields.slabs, 'slabs')}
				{!isDisabled && fields.slabs.length < 5 && (
					<div
						className={`${styles.add}`}
						onClick={handleClick}
						dataAttrs={{
							'data-type': 'serviceablePincodes',
							'data-id': Slabs,
							'data-name': 'serviceablePincodes',
						}}
					>
						<AddOutlinedIcon
							className={styles.addIcon}
							fontSize='large'
							color='primary'
						/>
					</div>
				)}
			</div>
			{/* </div> */}
		</div>
	);
};
Slabs.defaultProps = {
	isDisabled: false,
	handleDelete: () => {},
};

export default Slabs;
