import { useEffect, useState } from 'react';

const useImageDimensionsRef = (url) => {
	const [dimensions, setDimensions] = useState({
		current: {
			width: null,
			height: null,
		},
	});

	useEffect(() => {
		let isMounted = true; // To handle cleanup
		const img = new Image();

		const handleLoad = () => {
			if (isMounted) {
				setDimensions({
					current: {
						width: img.naturalWidth,
						height: img.naturalHeight,
					},
				});
			}
		};

		img.addEventListener('load', handleLoad);
		img.src = url;

		return () => {
			isMounted = false;
			img.removeEventListener('load', handleLoad);
		};
	}, [url]);
	return dimensions;
};

export default useImageDimensionsRef;
