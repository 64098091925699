/* eslint-disable no-tabs */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { GeneralDetails } from '../../../Components/Forms/RiderForm';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { history, imageToBase64, inputHelper, isEmpty } from '../../../Helpers';
import RiderConfig from './rider.config';

const RiderCreatePage = (props) => {
	const [state, setState] = useState({
		...RiderConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const { fields, errors } = state;
	const { id } = useParams();
	const {
		isEdit,
		fetchRider,
		saveRider,
		data,
		breadcrumbs,
	} = props;
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				RiderConfig.serializer(state, data).then((newState) => {
					setState(newState);
				});
			} else {
				fetchRider({
					entity: 'RIDERS',
					req: {
						params: {
							id,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				RiderConfig.serializer(state, data).then((newState) => {
					setState(newState);
				});
			}
		}
	}, [data]);
	const generateDefaultOption = (type, city) => {
		if (!city || city.length === 0) {
			return (
				<option value='' selected disabled hidden>
					Select a City
				</option>
			);
		}
		return <></>;
	};

	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		const valid = RiderConfig.validator(state);
		if (valid) {
			req.data = RiderConfig.deserializer(state);

			imageToBase64(req.data.imageUrl).then((values) => {
				req.data.imageUrl = values;
				setNumberOfSubmissions(numberOfSubmissions + 1);
				if (isEdit) {
					req.params = {
						id,
					};
					saveRider({
						entity: 'RIDERS',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.RIDERS}`,
					});
				} else {
					saveRider({
						entity: 'RIDERS',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.RIDERS}`,
					});
				}
			});
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.RIDERS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.RIDERS}`);
		}
	};
	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		switch (fieldName) {
			case 'name':
			case 'vehicleNo':
			case 'licenseNo':
				if (fieldValue.length < 13) {
					// error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'phoneNo':
				if (fieldValue.toString().length > 11) {
					// error = 'This field is required';
					return;
				}
				break;
			case 'city':
				newState[fieldName] = JSON.parse(fieldValue);
				break;
			default:
				break;
		}
		switch (dataset.type) {
			default:
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue,
						...newState,
					},
					...errors,
				});
		}
	};

	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Rider' : 'Create Rider'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${fields.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={RiderConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						generateDefaultOption={generateDefaultOption}
						handleImageInput={handleImageInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.RIDERS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchRider: formActions.gateway,
	saveRider: formActions.gateway,
};

RiderCreatePage.defaultProps = {
	isEdit: false,
};
export default connect(mapStateToProps, mapDispatchToProps)(RiderCreatePage);
