/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import labelGeneratorServices from '../../../Services/labelGeneratorServices';
import { notificationServices } from '../../../Services';
import ImagesFinder from '../../ImagesFinder/ImagesFinder';
import { ListTable } from '../../ListTable';
import { modalActions } from '../../../Actions';
import styles from './SavedLabels.module.scss';
// import LabelGenerator from '../../../Pages/LabelGenerator/LabelGenerator';
import { urls } from '../../../Constants';
import labelGeneratorConfig from '../LabelGenerator.config';

const TABLE_COLUMNS = [
	{
		title: 'Label Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Label Size',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Label Type',
		config: {
			type: 'DATA',
		},
	},
];

const excludedColumns = [];
const colMap = {
	'Label Name': 'name',
	'Label Size': 'size',
	'Label Type': 'type',
};

function SavedLabels(props) {
	const { toggleAlert } = props;
	const history = useHistory();
	const [savedLabels, setSavedLabels] = useState([]);
	const [isLoading, setIsLoading] = useState();
	const handleSavedLabelsAPI = async () => {
		setIsLoading(true);
		try {
			const response = await labelGeneratorServices.getLabels();
			if (response?.statusCode === 200) {
				const result = response?.savedLabels;
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				setSavedLabels(
					result
						?.filter((ele) => {
							return ele?.customerId;
						})
						?.map((ele) => {
							return {
								key: ele?.id,
								id: ele?.id,
								size: ele?.label?.size,
								name: ele?.labelName,
								label: ele?.label?.components,
								type: ele?.labelType,
								printCount: ele?.printCount,
							};
						})
				);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			notificationServices.generateNotification({
				type: 'error',
				message: 'Unable to fetch labels, please connect with UY Team',
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		handleSavedLabelsAPI();
	}, []);

	const handleDeleteLabelAPI = async (val) => {
		setIsLoading(true);
		try {
			const response = await labelGeneratorServices.deleteLabel(val);
			if (response?.statusCode === 200) {
				handleSavedLabelsAPI();
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Unable to delete, please connect with UY Team',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const deleteLabel = async (val) => {
		setIsLoading(true);
		try {
			await handleDeleteLabelAPI(val);
			setSavedLabels(
				savedLabels.filter((component) => {
					return component?.id !== val;
				})
			);
		} finally {
			setIsLoading(false);
		}
	};

	const openLabel = (val) => {
		const currLabel = savedLabels.find((label) => {
			return label.key === val;
		});
		history.push(`${urls.EXTENSION}${urls.LABEL_GENERATOR_CREATE}?currLabel=${JSON.stringify(currLabel)}`);
		// setLabelComponentsState(currLabel?.label);
		// setBackButtonClicked(true);
		// setLabelDetails({
		// key: currLabel?.key,
		// id: currLabel?.id,
		// size: currLabel?.size,
		// name: currLabel?.name,
		// type: currLabel?.type,
		// });
		// setLabelType(currLabel?.type);
		// handleBackButtonApp('back-button');
	};

	const ACTION_COLUMNS = [
		{
			title: 'Actions',
			config: {
				type: 'DATA',
			},
		},
	];

	const SPECIAL_ITEMS = {
		'Label Type': (labelType) => {
			const type = labelGeneratorConfig?.namingOfMachineType?.[labelType];
			return (
				type
			);
		},
		Actions: (labelId) => {
			const selectedLabel = savedLabels.find((label) => {
				return label.id === labelId;
			});
			return (
				<div className={styles?.actions}>
					<Button
						onClick={() => {
							openLabel(labelId);
						}}
						variant='outlined'
						color='primary'
						fullWidth
					>
						Edit
					</Button>
					<div
						style={{
							width: '125px',
						}}
					>
						<Button
							variant='outlined'
							color='error'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Delete Label?',
									handleConfirm: () => {
										deleteLabel(labelId);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to delete ${selectedLabel?.name}?`,
								});
							}}
							fullWidth
						>
							Delete
						</Button>
					</div>
				</div>
			);
		},
	};

	const handleAddClick = () => {
		history.push(`${urls.EXTENSION}${urls.LABEL_GENERATOR_CREATE}`);
	};

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Label Generator'
					onSubmit={handleSavedLabelsAPI}
					additionInfo={{
						showAdd: true,
						// showAdd: false,
						addText: 'Create New Label',
						handleAddClick,
					}}
				/>
			</div>
			<ListTable
				colMap={colMap}
				columns={TABLE_COLUMNS?.filter((ele) => {
					return !excludedColumns?.includes(ele?.title);
				})}
				actions={[...ACTION_COLUMNS]}
				data={savedLabels?.map((ele) => {
					return {
						...ele,
						// eslint-disable-next-line prefer-template
						size: ele?.size?.width + 'x' + ele?.size?.height,
					};
				})}
				special={{
					...SPECIAL_ITEMS,
				}}
				allowExport={false}
			/>
		</div>
	);
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedLabels);
