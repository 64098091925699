/* eslint-disable no-tabs */
import { Switch } from '@mui/material';
import { get } from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper } from '../../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	// {
	// 	title: 'Quality Management',
	// 	config: {
	// 		type: 'UPDATE',
	// 	},
	// },
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	Brand: 'Brand.name',
	Type: 'skuType',
	'Quality Management': 'isQCEnabled',
};

const QualityConfigListPage = (props) => {
	const {
		data,
		query,
		searchBy,
		fetchQualityConfig,
		toggleBulkConmsumption,
	} = props;
	useEffect(() => {
		fetchQualityConfig({
			entity: 'PRODUCT_QUALITY_CONFIG',
			req: {
				params: {
					id: '',
					skuType: 'PRODUCT',
					combo: true,
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleEnable = (e) => {
		const { fieldValue, dataset } = inputHelper(e);

		const { name, id } = dataset;

		toggleBulkConmsumption({
			entity: 'PRODUCT_QUALITY_CONFIG',
			req: {
				params: {
					id,
				},
				data: {
					[name]: fieldValue,
				},
			},
			method: 'PATCH',
		});
	};

	const SPECIAL_ITEMS = {
		'Quality Management': (id, isEnabled) => {
			return (
				<Switch
					key={id}
					name='isQCEnabled'
					value='isQCEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'PRODUCT_QUALITY_CONFIG',
					}}
				/>
			);
		},
	};

	return (
		<div className='list-page-root'>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={Object.keys(data)
					.filter((productId) => {
						const product = data[productId];
						return (
							get(product, COLMAP?.[searchBy?.title])
								?.toLowerCase()
								?.indexOf(query?.trim()?.toLowerCase()) !== -1
						);
					})
					.map((productId) => {
						return data[productId];
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_QUALITY_CONFIG.data.LIST;
	// const data = CookingStationsConfig.api;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchQualityConfig: formActions.gateway,
	toggleBulkConmsumption: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityConfigListPage);
