/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import parameters from '../../../Pages/LabelGenerator/parameters';
import { notificationServices } from '../../../Services';

function InputBox(props) {
	const {
		labelDetails,
		setLabelDetails,
		textBoxDim,
		handleText,
		handleTextSize,
		handleInputLines,
		handleHighlight,
		handleStaticTextSize,
		handleStaticText,
		handleQRCode,
		handleBarcode,
		handleImage,
		handleTableFontSize,
		handleTableColumnRatio,
		handleTableDim,
		handleTable,
		handleChangeScreen,
		setPrinterIP,
		placeholderArray,
		setCurrentParameters,
		handlePlaceHolderTextChange,
		id,
		info,
		min,
		max,
		type,
		value,
		setDefaultValue,
	} = props;

	// eslint-disable-next-line no-unused-vars
	let labelDimensionsWidth = labelDetails?.size?.width;
	const [input, setInput] = useState();

	useEffect(() => {
		if (type === 'Number') {
			setInput(Number(value));
		} else {
			setInput(value);
		}
	}, [value]);

	useEffect(() => {
		labelDimensionsWidth = labelDetails?.size?.width;
	}, [labelDetails?.size]);

	const handleChange = (Event) => {
		if (type === 'Number') {
			setInput(Number(Event?.target?.value));
		} else {
			setInput(Event?.target?.value);
		}
		if (Event.target.className === 'label-size-input-box') {
			let newWidth = document.getElementById(
				'size-input-box-width'
			).value;
			let newHeight = document.getElementById(
				'size-input-box-height'
			).value;

			newHeight = Number(newHeight);
			newWidth = Number(newWidth);

			if (
				!isNaN(newHeight) &&
				!isNaN(newWidth) &&
				newHeight !== 0 &&
				newWidth !== 0
			) {
				setLabelDetails({
					...labelDetails,
					size: {
						width: newWidth,
						height: newHeight,
					},
				});
			}
		} else if (Event.target.className === 'text-size-input-box') {
			let newWidth = document.getElementById(
				'text-size-input-box-width'
			).value;
			newWidth = Number(newWidth);
			if (!isNaN(newWidth) && newWidth !== 0) {
				if (newWidth > labelDetails?.size?.width) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Entered dimensions are larger than label Size...',
					});
				} else {
					newWidth = (newWidth * (96 / 25.4)) / 12.25;
					const textBoxDimensions = {
						...textBoxDim,
						width: `${newWidth}rem`,
					};
					handleText(textBoxDimensions);
				}
			}
		} else if (Event.target.className === 'text-font-size-input-box') {
			const fontSize = Number(
				document.getElementById('text-font-size-input-box').value
			);
			if (!isNaN(fontSize) && fontSize !== 0) {
				handleTextSize(fontSize);
			}
		} else if (Event.target.className === 'text-lines-input-box') {
			const lines = Number(
				document.getElementById('text-lines-input-box').value
			);
			if (!isNaN(lines) && lines !== 0) {
				handleInputLines(lines);
			}
		} else if (Event.target.className === 'text-highlight-input-box') {
			const highlight =
				document.getElementById('text-highlight-input-box').checked;
			handleHighlight(highlight);
		} else if (Event.target.className === 'static-text-highlight-input-box') {
			const highlight =
				document.getElementById('static-text-highlight-input-box').checked;
			handleHighlight(highlight);
		} else if (Event.target.className === 'static-text-font-size-input-box') {
			const fontSize = Number(
				document.getElementById('static-text-font-size-input-box').value
			);
			if (!isNaN(fontSize) && fontSize !== 0) {
				handleStaticTextSize(fontSize);
			}
		} else if (Event.target.className === 'static-text-size-input-box') {
			let newWidth = document.getElementById(
				'static-text-size-input-box-width'
			).value;
			let newHeight = document.getElementById(
				'static-text-size-input-box-height'
			).value;

			newHeight = Number(newHeight);
			newWidth = Number(newWidth);
			if (
				!isNaN(newHeight) &&
				!isNaN(newWidth) &&
				newHeight !== 0 &&
				newWidth !== 0
			) {
				if (newWidth > labelDetails?.size?.width || newHeight > labelDetails?.size?.height) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Entered dimensions are larger than label Size...',
					});
				} else {
					newWidth = (newWidth * (96 / 25.4)) / 12.25;
					newHeight = (newHeight * (96 / 25.4)) / 12.25;
					const staticTextBoxDim = {
						width: `${newWidth}rem`,
						height: `${newHeight}rem`,
					};
					handleStaticText(staticTextBoxDim); // Change
				}
			}
		} else if (Event.target.className === 'qrcode-size-input-box') {
			let newWidth;
			let newHeight;
			newWidth = document.getElementById(
				'qrcode-size-input-box-width'
			).value;
			newHeight = document.getElementById(
				'qrcode-size-input-box-width'
			).value;
			newHeight = Number(newHeight);
			newWidth = Number(newWidth);
			if (
				!isNaN(newHeight) &&
				!isNaN(newWidth) &&
				newHeight !== 0 &&
				newWidth !== 0
			) {
				if (newWidth > labelDetails?.size?.width || newHeight > labelDetails?.size?.height) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Entered dimensions are larger than label Size...',
					});
					if (newWidth > labelDetails?.size?.width) {
						newWidth = labelDetails?.size?.width;
						setInput(newWidth);
					} else {
						newWidth = labelDetails?.size?.height;
						setInput(newWidth);
					}
				// eslint-disable-next-line max-len
				} else if (newWidth < 6 || newWidth > Math.min(labelDetails?.size?.width, labelDetails?.size?.height, 30)) {
					notificationServices.generateNotification({
						type: 'error',
						message: `QR Code dimensions should be between 6 and ${Math.min(labelDetails?.size?.width, labelDetails?.size?.height, 30)}`,
					});
					if (newWidth < 6) {
						newWidth = 6;
					// eslint-disable-next-line max-len
					} else if (newWidth > Math.min(labelDetails?.size?.width,
						labelDetails?.size?.height, 30)) {
						newWidth = Math.min(labelDetails?.size?.width, labelDetails?.size?.height, 30);
					}
				}
				newWidth = (newWidth * (96 / 25.4)) / 12.25;
				const qrCodeDim = {
					width: `${newWidth}rem`,
					height: `${newWidth}rem`,
				};
				handleQRCode(qrCodeDim);
			}
		} else if (Event.target.className === 'table-input-box') {
			const rows = document.getElementById(
				'tableRows'
			).value;
			const cols = document.getElementById(
				'tableCols'
			).value;
			handleTable(rows, cols);
		} else if (Event.target.className === 'table-font-size-input-box') {
			const fontSize = Number(
				document.getElementById('table-font-size-input-box').value
			);
			if (!isNaN(fontSize) && fontSize !== 0) {
				handleTableFontSize(fontSize);
			}
		} else if (Event.target.className === 'table-column-ratio-input-box') {
			const ratio = document.getElementById('table-column-ratio-input-box').value;
			let values = ratio.split(',');
			let sum = 0;
			for (let i = 0; i < values.length; i++) {
				if (Number(values[i]) <= 0 || Number(values[i]) >= 1) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Enter correct ratio...',
					});
					sum = -1;
					break;
				} else {
					sum += Number(values[i]);
				}
			}
			if (sum !== 1) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Ratio must sum to 1...',
				});
				values = [];
			} else {
				// eslint-disable-next-line array-callback-return
				const newValues = values;
				values = [];
				newValues.forEach((str) => {
					values = [...values, Number(str)];
				});
			}
			handleTableColumnRatio(values);
		} else if (Event.target.className === 'table-size-input-box') {
			let newWidth;
			newWidth = document.getElementById(
				'table-size-input-box-width'
			).value;
			newWidth = Number(newWidth);
			if (
				!isNaN(newWidth) &&
				newWidth !== 0
			) {
				if (newWidth > labelDetails?.size?.width) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Entered dimensions are larger than label Size...',
					});
				} else {
					newWidth = (newWidth * (96 / 25.4)) / 12.25;
					const tableDim = {
						width: `${newWidth}rem`,
					};
					handleTableDim(tableDim);
				}
			}
		} else if (Event.target.className === 'barcode-size-input-box') {
			// changeRequired
			let newWidth;
			let newHeight;
			newWidth = document.getElementById(
				'barcode-size-input-box-width'
			).value;
			newHeight = document.getElementById(
				'barcode-size-input-box-height'
			).value;
			newHeight = Number(newHeight);
			newWidth = Number(newWidth);
			if (
				!isNaN(newHeight) &&
				!isNaN(newWidth) &&
				newHeight !== 0 &&
				newWidth !== 0
			) {
				if (newWidth > labelDetails?.size?.width || newHeight > labelDetails?.size?.height) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Entered dimensions are larger than label Size...',
					});
				} else {
					newWidth = (newWidth * (96 / 25.4)) / 12.25;
					newHeight = (newHeight * (96 / 25.4)) / 12.25;
					const barCodeDim = {
						width: `${newWidth}rem`,
						height: `${newHeight}rem`,
					};
					handleBarcode(barCodeDim);
				}
			}
		} else if (Event.target.className === 'image-size-input-box') {
			let newWidth = document.getElementById(
				'image-size-input-box-width'
			).value;
			let newHeight = document.getElementById(
				'image-size-input-box-height'
			).value;
			newHeight = Number(newHeight);
			newWidth = Number(newWidth);
			if (
				!isNaN(newHeight) &&
				!isNaN(newWidth) &&
				newHeight !== 0 &&
				newWidth !== 0
			) {
				if (newWidth > labelDetails?.size?.width || newHeight > labelDetails?.size?.height) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Entered dimensions are larger than label Size...',
					});
				} else {
					newWidth = (newWidth * (96 / 25.4)) / 12.25;
					newHeight = (newHeight * (96 / 25.4)) / 12.25;
					const imageDim = {
						width: `${newWidth}rem`,
						height: `${newHeight}rem`,
					};
					handleImage(imageDim);
				}
			}
		} else if (Event.target.className === 'text-input-box') {
			const text = document.getElementById('static-text-input-box').value;
			handleChangeScreen(text);
		} else if (Event.target.className === 'printer-ip-address') {
			const text1 = Number(document.getElementById('printer-ip-address1').value);
			const text2 = Number(document.getElementById('printer-ip-address2').value);
			const text3 = Number(document.getElementById('printer-ip-address3').value);
			const text4 = Number(document.getElementById('printer-ip-address4').value);
			// eslint-disable-next-line max-len
			if (text1 >= 0 && text1 <= 255 && text2 >= 0 && text2 <= 255 && text3 >= 0 && text3 <= 255 && text4 >= 0 && text4 <= 255) {
				setDefaultValue([text1, text2, text3, text4]);
				setPrinterIP(`${text1}.${text2}.${text3}.${text4}`);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'IP needs to be between 0 and 255',
				});
			}
		} else if (Event.target.className === 'parameter-input-box') {
			// eslint-disable-next-line no-shadow
			placeholderArray.forEach(({ name, id }) => {
				if (name === 'QR Code') {
					if (document.getElementById(id).value === '') {
						document.getElementById(id).value = parameters.qrCode;
					}
					setCurrentParameters((prevParamas) => {
						return {
							...prevParamas,
							qrCode: document.getElementById(id).value,
						};
					});
				} else {
					if (document.getElementById(id).value === '') {
						document.getElementById(id).value = parameters[name];
					}
					setCurrentParameters((prevParamas) => {
						return {
							...prevParamas,
							[name]: document.getElementById(id).value,
						};
					});
				}
			});
		} else if (Event.target.className === 'label-input-box') {
			const text = document.getElementById('label-name-input-box').value;
			const printCountInput = document.getElementById('label-count-input-box').value;
			if (!isNaN(text)) {
				setLabelDetails({
					...labelDetails,
					name: '',
					printCount: printCountInput ?? 1,
				});
			} else {
				setLabelDetails({
					...labelDetails,
					name: text,
					printCount: printCountInput ?? 1,
				});
			}
		} else if (Event.target.className === 'placeholder-text-input-box') {
			const text = document.getElementById(
				'placeholder-text-input-box'
			).value;
			handlePlaceHolderTextChange(text);
		}
	};

	return (
		<input
			id={id}
			className={props?.class}
			type={type}
			placeholder={info}
			onChange={handleChange}
			handleText={handleText}
			handleStaticText={handleStaticText}
			handleQRCode={handleQRCode}
			handleBarcode={handleBarcode}
			value={input}
			checked={input}
			min={min}
			max={max}
		/>
	);
}
export default InputBox;
