import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const containerAnalyticsServices = {
	gateway,
	// fetchAnalyticsList,
	fetchImagesById,
};

function fetchImagesById(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_CONTAINER_IMAGES_BY_ID, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions).then(handleResponse);
}

function gateway({ req, method }) {
	switch (method) {
		case 'FIND_CONTAINER_IMAGES':
			return findContainerImages(req);
		// case 'LIST_DATA':
		// return fetchAnalyticsList({
		// req,
		// });
		default:
			break;
	}
	return new Promise();
}

function findContainerImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_CONTAINER_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

export default containerAnalyticsServices;
