import { geoDataConstants } from '../Constants';
import { geoDataServices } from '../Services';

const geoDataActions = {
	fetchGeoData,
};

function fetchGeoData(
	req = {
		id: '',
	}
) {
	return (dispatch) => {
		dispatch(request());
		geoDataServices.getGeoData(req).then(
			(res) => {
				dispatch(success(res));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: geoDataConstants.FETCH_GEODATA_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: geoDataConstants.FETCH_GEODATA_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: geoDataConstants.FETCH_GEODATA_FAILURE,
			payload,
		};
	}
}

export default geoDataActions;
