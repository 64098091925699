/* eslint-disable */
import { RecipeStage } from '@/Components/Forms/Cooking/Cooking Recipe';
import CookingProcessesTabs from '@/Components/Forms/Cooking/CookingProcessesTabs/CookingProcessesTabs';
import TimeRequired from '../TimeRequired/TimeRequired';

const CookingRecipeStages = (props) => {
	const {
		fields,
		setActiveStage,
		activeStage,
		cookingRecipeConfig,
		activeProcess,
		setActiveProcess,
		totalRequiredTime = null,
	} = props;
	const activeStageValue = fields.stages.items?.[activeStage];
	return (
		<div className='grid grid-cols-4'>
			<div className='col-span-1'>
				<CookingProcessesTabs
					processes={fields.stages.items}
					activeStage={activeStage}
					setActiveStage={setActiveStage}
				/>
			</div>
			<div
				className={`border-2 border-black p-3 w-full rounded-xl col-span-3 relative`}
				style={{
					background: `${activeStageValue?.process?.color}20`,
					borderColor: activeStageValue?.process?.color,
				}}
			>
				{totalRequiredTime && (
					<TimeRequired classname='absolute top-[-2rem] right-0' totalRequiredTime={totalRequiredTime} />
				)}
				<RecipeStage
					activeStage={activeStage}
					stageDetails={fields.stages.items[activeStage] ?? {}}
					mandatoryFields={cookingRecipeConfig.mandatoryFields}
					activeProcess={activeProcess}
					setActiveProcess={setActiveProcess}
					stages={fields.stages}
					defaultVariant={fields.product.defaultVariant}
					allVariants={fields.product.variants}
					isDisabled
				/>
			</div>
		</div>
	);
};

export default CookingRecipeStages;
