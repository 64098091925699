import { Scale } from '@mui/icons-material';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Pie } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useHistory } from 'react-router';
import styles from './SKUTile.module.css';
import Heading from '../../Heading/Heading';
import { tilestype } from '../../tempdata';
/*
function showTooltip(tooltip) {
	return (
		<>
			{tooltip?.length ? (
				<>
					<HtmlTooltip title={<>{tooltip}</>} placement='top' followCursor>
						<InfoOutlinedIcon />
						hell
					</HtmlTooltip>
				</>
			) : (
				<></>
			)}
		</>
	);
}
*/
/*
const HtmlTooltip = styled(({ className, ...props }) => {
	return (
		<Tooltip
			{...props}
			classes={{
				popper: className,
			}}
		/>
	);
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			background: '#eff5f5',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid black',
		},
	};
});
*/
function numDifferentiation(val) {
	let k = '';
	let simpleRs = false;
	if (val >= 10000000) {
		k = `${(val / 10000000)?.toFixed(3)} Cr`;
	} else if (val >= 100000) {
		k = `${(val / 100000)?.toFixed(2)} Lac`;
	} else {
		simpleRs = true;
		k = `${val?.toFixed(2)}`;
	}
	/* else if(val >= 1000) val = (val/1000).toFixed(2) + ' K'; */
	return [k, simpleRs];
}
function weightDifferentiation(val) {
	let k = '';
	let simpleRs = false;
	if (val >= 1000) {
		k = `${(val / 1000)?.toFixed(2)} T `;
	} else {
		simpleRs = true;
		k = `${(val / 1)?.toFixed(2)}`;
	}
	/* else if(val >= 1000) val = (val/1000).toFixed(2) + ' K'; */
	return [k, simpleRs];
}

function CorrespondingDiv(id, type, value, url, scale, unit = '', tooltip) {
	switch (type) {
		case tilestype.percent:
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
							wordBreak: 'keep-all',
						}}
					>
						<Heading
							heading={value + (tooltip?.length > 0 ? unit : '')}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '1.1rem' : '1.7rem',
									}}
								>
									{unit}
								</span>
							</>
						)}
					</p>
					<Pie
						className={scale ? styles.smallPieChart : styles.pieChart}
						data={{
							labels: [`${value}`, ' '],
							datasets: [
								{
									data: [value, 100 - value],
									// backgroundColor: [loss ? 'red' : 'green', '#eff5f5'],
									backgroundColor: ['rgba(73, 113, 116, 1)', '#eff5f5'],
									// borderColor: loss ? 'red' : 'green',
									borderColor: 'rgba(73, 113, 116, 1)',
									borderWidth: '5',
								},
							],
						}}
						options={{
							plugins: {
								tooltip: {
									enabled: false,
								},
								legend: {
									display: false,
								},
							},
						}}

					/>
				</>
			);

		case tilestype.weight:
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
						}}
					>
						<Heading
							heading={
								weightDifferentiation(value)[0] +
								(tooltip?.length > 0
									? ` ${weightDifferentiation(value)[1] ? unit : ''}`
									: '')
							}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '1.1rem' : '1.7rem',
									}}
								>
									{` ${weightDifferentiation(value)[1] ? unit : ''}`}
								</span>
							</>
						)}
					</p>
					<Scale
						style={{
							color: 'rgba(73, 113, 116, .8)',
							fontSize: scale ? '1.7rem' : '',
						}}
						className={styles.icon}
					/>
				</>
			);
		case tilestype.amount:
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
						}}
					>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '0.9rem' : '1.5rem',
									}}
								>
									{`${unit} `}
								</span>
							</>
						)}
						<Heading
							heading={
								// eslint-disable-next-line no-nested-ternary
								(tooltip?.length > 0
									? numDifferentiation(value)[1]
										? 'Rs '
										: ''
									: '') + numDifferentiation(value)[0]
								// eslint-disable-next-line no-nested-ternary
							}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
					</p>
					<CurrencyRupeeIcon
						style={{
							color: 'rgba(79, 160, 149, 1)',
							fontSize: scale ? '1.7rem' : '',
						}}
						className={styles.icon}
					/>
				</>
			);
		case tilestype.count:
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
						}}
					>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '0.9rem' : '1.5rem',
									}}
								>
									{`${unit} `}
								</span>
							</>
						)}
						<Heading
							heading={value}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
					</p>
				</>
			);
		case tilestype.genric:
			return (
				<>
					<div className={styles.generic}>
						{value?.map((ele) => {
							return (
								<div key={ele?.type + ele?.tooltip} className={styles.item}>
									{CorrespondingDiv(
										ele.type,
										ele.count,
										ele.value,
										true,
										ele.unit,
										ele?.tooltip
									)}
								</div>
							);
						})}
						{/* <div className={styles.item}>{CorrespondingDiv('price', 20, true)}</div>
						<div className={styles.item}>{CorrespondingDiv('percent', 45, true)}</div> */}
					</div>
					{/* {''.split().join} */}
					{/* {value.split('/').join('  ')} */}
				</>
			);

		default:
			return (
				<>
					<div className={styles.generic}>
						{/* <div className={styles.item}>{CorrespondingDiv('scale', 45, true)}</div>
						<div className={styles.item}>{CorrespondingDiv('price', 20, true)}</div>
						<div className={styles.item}>{CorrespondingDiv('percent', 45, true)}</div> */}
						data not provided
						{JSON.stringify(value)}
					</div>
					{/* {''.split().join} */}
					{/* {value.split('/').join('  ')} */}
				</>
			);
	}
}

function SKUTile({ data }) {
	const {
		heading, id, value, url, type, unit, tooltip, bgClr, workflow: extraData,
	} = data;
	const history = useHistory();
	return (
		<div
			key={heading}
			style={{
				background: bgClr,
				cursor: 'pointer',
			}}
			className={styles.root}
			onClick={() => {
				history.push(`/console2/${url}${id}`);
			}}
		>
			{/* <div className={styles.data}>Today’s Plan Production</div> */}
			<Heading heading={heading} tooltip={tooltip} />
			<div className={styles.content}>
				{/* <p>2300</p> */}
				{CorrespondingDiv(id, type, type === tilestype.genric ? extraData : value,
					url, false, unit)}
			</div>
		</div>
	);
}

export default SKUTile;
