/* eslint-disable prefer-template */
/* eslint-disable object-curly-newline */
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
// import RadioGroup from 'rsuite/RadioGroup';
// import Radio from 'rsuite/Radio';
import SelectPicker from 'rsuite/SelectPicker';
import styles from './BillsPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../Components/Loaders';
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
// import { ListModal } from '../../../Components/Modals';
import { formActions, modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { PaymentModal } from '../../Components/Modals/PaymentModal';
// import { useAuth } from '../../Components/Auth/Login/auth';
import { urls } from '../../Constants';
import { TransactionsModal } from '../../Components/Modals/TransactionsModal';
import { useQuery } from '../../Helpers';
import { AlertModal } from '../../Components/Modals';
import { FileUploaderModal } from '../../Components/Modals/FileUploaderModal';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { BulkPaymentModal } from '../../Components/Modals/BulkPaymentModal';
import BillsListing from '../../Components/BillsListing/BillsListing';

const FIND_FILTERS = ['filteredStore', 'vendor', 'status', 'dateRange', 'timeType'];

const statusList = [
	{
		id: 'PENDING',
		title: 'PENDING',
	},
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'PAID',
		title: 'PAID',
	},
	{
		id: 'UNPAID',
		title: 'UNPAID',
	},
	{
		id: 'PARTIALLY_PAID',
		title: 'PARTIALLY PAID',
	},
	{
		id: 'APPROVAL_PENDING_FINANCE',
		title: 'APPROVAL PENDING FINANCE',
	},
];

const timeTypeArr = [
	{
		label: 'Bill Date',
		value: 'transactionDate',
	},
	{
		label: 'Due Date',
		value: 'dueDate',
	},
];

function BillsPage({
	userPermissions,
	createPath,
	togglePOItemsModal,
	togglePaymentModal,
	// toggleTransactionsModal,
	toggleAlert,
	toggleFileUploaderModal,
	vendors,
	fetchVendors,
	toggleBulkPaymentModal,
	navState,
}) {
	const params = useQuery();
	let poId = params.get('poId') ? Number(params.get('poId')) : undefined;
	let billId = params.get('billId')
		? Number(params.get('billId'))
		: undefined;
	let grnId = params.get('grnId') ? Number(params.get('grnId')) : undefined;
	let clientPOId = params.get('clientPoId');
	const history = useHistory();
	// const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [billsList, setBillsList] = useState([]);
	const [update, setUpdate] = useState(0);
	const [externalFilters, setExternalFilters] = useState([]);
	const [selectedBills, setSelectedBills] = useState([]);
	const [parentType, setParentType] = useState('ALL');
	// const navRequisites = navState?.[urls.PURCHASE_ORDERS]?.requisites ?? [];
	// const navSelectedStore = navRequisites?.find((ele) => {
	// return ele?.type === 'store';
	// });

	const BILLS = useMemo(() => {
		return billsList?.filter((ele) => {
			if (!billId) return true;
			return ele?.id === billId;
		});
	}, [billsList]);

	useEffect(() => {
		if (poId) {
			setUpdate((prev) => {
				return prev + 1;
			});
			const poFilterExternal = {
				id: poId,
				type: 'orderId',
				title: `Order Id: ${clientPOId}`,
				removable: true,
				handleRemove: () => {
					params.delete('poId');
					params.delete('clientPoId');
					if (billId) {
						params.delete('billId');
						billId = null;
					}
					poId = null;
					clientPOId = null;
					setUpdate((prev) => {
						return prev + 1;
					});
					setExternalFilters((prev) => {
						return prev?.filter((ele) => {
							return (
								ele?.type !== 'orderId' &&
								ele?.type !== 'billId'
							);
						});
					});
					history.replace({
						search: params.toString(),
					});
				},
			};
			setExternalFilters((prev) => {
				const exists = prev?.find((ele) => {
					return ele?.type === 'orderId';
				});
				if (exists) {
					if (exists?.id !== poId) {
						return prev?.map((ele) => {
							if (ele?.type === 'orderId') {
								return poFilterExternal;
							}
							return ele;
						});
					}
					return prev;
				}
				return [...prev, poFilterExternal];
			});
		}
	}, [poId, billId]);

	useEffect(() => {
		if (grnId) {
			setUpdate((prev) => {
				return prev + 1;
			});
			const grnFilterExternal = {
				id: grnId,
				type: 'grnId',
				title: `GRN Id: ${grnId}`,
				removable: true,
				handleRemove: () => {
					params.delete('grnId');
					grnId = null;
					history.replace({
						search: params.toString(),
					});
					setUpdate((prev) => {
						return prev + 1;
					});
					setExternalFilters((prev) => {
						return prev?.filter((ele) => {
							return ele?.type !== 'grnId';
						});
					});
				},
			};
			setExternalFilters((prev) => {
				const exists = prev?.find((ele) => {
					return ele?.type === 'grnId';
				});
				if (exists) {
					if (exists?.id !== poId) {
						return prev?.map((ele) => {
							if (ele?.type === 'grnId') {
								return grnFilterExternal;
							}
							return ele;
						});
					}
					return prev;
				}
				return [...prev, grnFilterExternal];
			});
		}
	}, [grnId]);

	useEffect(() => {
		if (poId && billId) {
			const billFilterExternal = {
				id: billId,
				type: 'billId',
				title: `Bill Id: ${billId}`,
				removable: true,
				handleRemove: () => {
					params.delete('billId');
					billId = null;
					history.replace({
						search: params.toString(),
					});
					setExternalFilters((prev) => {
						return prev?.filter((ele) => {
							return ele?.type !== 'billId';
						});
					});
				},
			};
			setExternalFilters((prev) => {
				const exists = prev?.find((ele) => {
					return ele?.type === 'billId';
				});
				if (exists) {
					if (exists?.id !== poId) {
						return prev?.map((ele) => {
							if (ele?.type === 'billId') {
								return billFilterExternal;
							}
							return ele;
						});
					}
					return prev;
				}
				return [...prev, billFilterExternal];
			});
		}
	}, [grnId]);

	useEffect(() => {
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleListFetch = async (requestOptions) => {
		setIsloading(true);
		const filters = {
			// fromStoreId: requestOptions?.store,
			vendors: requestOptions?.vendors,
			storeId: requestOptions?.store,
			orderId: poId,
			parentId: grnId,
			status: requestOptions?.status,
			subTypes: parentType === 'ALL' ? ['GRN', 'ADVANCE', 'CAPEX'] : [parentType],
		};
		if (requestOptions?.dateRange?.active) {
			filters.dateRangeFilter = {
				timeType: requestOptions?.timeType,
				from: moment(requestOptions?.dateRange?.from).format(
					'YYYY-MM-DD'
				),
				to: moment(requestOptions?.dateRange?.to).format('YYYY-MM-DD'),
			};
		}
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters,
							type: 'BILL',
							transactionItems: true,
							parent: true,
						},
					},
				});
			if (responseData?.statusCode === 200) {
				// setSelectedStore(store);
				setBillsList(responseData?.data);
				setSelectedBills([]);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleApproveBill = async (item) => {
		setIsloading(true);
		let status = '';
		if (item?.transactionStatus === 'APPROVAL_PENDING_MANAGER') {
			status = 'APPROVAL_PENDING_FINANCE';
		}
		if (item?.transactionStatus === 'APPROVAL_PENDING_FINANCE') {
			status = 'UNPAID';
		}
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: item?.id,
					},
					data: {
						status,
						type: 'BILL',
						nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.BILLS}?poId=${item?.orderId}&clientPOId=${item?.clientOrderId}&billId=${item?.id}`,
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				togglePOItemsModal(false, {});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};
	const handleRejectBill = async (item, rejectionReason) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: item?.id,
					},
					data: {
						rejectionReason,
						rejected: true,
						type: 'BILL',
						nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.BILLS}?poId=${item?.orderId}&clientPOId=${item?.clientOrderId}&billId=${item?.id}`,
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				togglePOItemsModal(false, {});
				toggleAlert(false, {});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleCancelBill = async (item) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: item?.id,
					},
					data: {
						status: 'CANCELLED',
						type: 'BILL',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleMakePayment = async (data) => {
		try {
			const response =
				await inventoryAnalyticsServices.createTransactionRecord({
					data: {
						...data,
						type: 'PAYMENTS',
					},
				});
			if (response?.statusCode === 200) {
				// setStoreCreditsList(response?.data);
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const moreOptionsList = {
		// 'Create Credits': (po, cb) => {
		// const url = `${urls.EXTENSION}${urls.CREDITS}/add?billId=${po?.id}`;
		// history.push(url);
		// cb();
		// },
		'Upload Invoice': (po, cb) => {
			toggleFileUploaderModal(true, {
				heading: 'Upload Invoice',
				selectedFile: '',
				confirmText: 'Upload',
				id: po?.id,
			});
			cb();
		},
	};

	const handleInvoiceUpload = async (fileData, id) => {
		const billItem = billsList?.find((ele) => {
			return ele?.id === id;
		});
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: id,
					},
					data: {
						approved: false,
						status:
							billItem?.transactionStatus !==
							'APPROVAL_PENDING_MANAGER'
								? 'APPROVAL_PENDING_MANAGER'
								: undefined,
						type: 'BILL',
						uploadedDoc: fileData?.file,
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				togglePOItemsModal(false, {});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
		toggleFileUploaderModal(false, {});
		// eslint-disable-next-line no-console
		console.log('UPLOADED INVOICE FILE DATA', fileData);
	};

	const viewMoreOptionsList = {
		'View Payments': (item, cb) => {
			window.open(`${urls.EXTENSION}${urls.PAYMENT_MADE}?billId=${item?.id}&clientTransactionId=${item?.clientTransactionId}`);
			// toggleTransactionsModal(true, {
			// listData: item?.childTransactionRecord?.filter((ele) => {
			// return ele?.rowType !== 'CREDIT';
			// }),
			// transactionType: 'PAYMENTS',
			// type: 'paymentMade',
			// heading: `Payment Made For Bill #${item?.clientTransactionId}`,
			// });
			cb();
		},
		'View Invoice': (item, cb) => {
			if (!item?.uploadedDoc) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'No invoice uploaded',
				});
			} else {
				window.open(item?.uploadedDoc, '_blank');
			}
			cb();
		},
		'View Items': (item, cb) => {
			const vendorDetails = vendors?.find((ele) => {
				return ele?.id === item?.vendorId;
			});
			const isAdvanceBill = !item?.parentRowId;
			if (isAdvanceBill) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Advance bill have no item',
				});
				return;
			}
			togglePOItemsModal(true, {
				heading: `Bills Items of #${
					item?.clientTransactionId ?? item?.id
				}`,
				...item,
				packagingCharges: item?.extraData?.packagingCharges,
				deliveryCharges: item?.extraData?.deliveryCharges,
				packagingTaxPercentage: item?.extraData?.taxOnPackagingCharges,
				deliveryTaxPercentage: item?.extraData?.taxOnDeliveryCharges,
				taxType: item?.extraData?.taxValues?.SGST ? 'STATE' : '',
				showPdf: false,
				showTotal: true,
				showPoDetails: true,
				poId: item?.orderId,
				width: '60rem',
				height: '40rem',
				transactionData: {
					'Bill ID': item?.clientTransactionId,
					'PO ID': item?.clientOrderId,
					Vendor: item?.vendorName,
					'Deliver To Store': item?.storeName,
					'Vendor GSTIN': vendorDetails?.gstin ?? '',
					'Deliver To Store GSTIN': item?.storeGstin,
					'Vendor Address': `${
						vendorDetails?.shippingAddressCity ?? ''
					} ${vendorDetails?.shippingAddressState ?? ''}, ${
						vendorDetails?.shippingAddressPinCode ?? ''
					}`,
					...(item?.parentRowId && {
						'GRN ID': item?.parentRowId,
					}),
					'Client Bill ID': item?.clientTransactionId,
					'GRN Date': item
						?.parentTransactionRecord ? moment(
							item?.parentTransactionRecord?.transactionDate
						).format('YYYY-MM-DD') : '-',
					'Bill Date': moment(item?.transactionDate).format(
						'YYYY-MM-DD'
					),
					'Bill Status': item?.transactionStatus,
					'Bill Amount': '\u20B9' + item?.finalTransactionAmount,
					'Payment Term': item?.extraData?.paymentTerm,
					'Order Amount': '\u20B9' + item?.orderAmount,
				},
				excludedColumns: [
					'Packaged',
					'Dispatched',
					// 'Received',
					'Returned Quantity',
					'Fulfilled',
					'From Process',
					'PO Id',
					'Returned',
					'Quantity',
					'Used For',
				],
			});
			cb();
		},
	};

	const handleBulkPayment = () => {
		notificationServices.generateNotification({
			type: 'warning',
			message: 'Only Un-Paid bills are selected',
		});
		const selectedBillsData = selectedBills
			?.map((ele) => {
				const billItem = BILLS?.find((bill) => {
					return bill?.id === ele;
				});
				return billItem;
			})
			?.filter((ele) => {
				return (
					ele?.transactionStatus === 'UNPAID' && !!ele?.parentRowId
				);
			});
		const vendorWiseBills = {};
		selectedBillsData?.forEach((ele) => {
			const vendorExists = vendorWiseBills?.[ele?.vendorId];
			if (vendorExists) {
				vendorWiseBills[ele?.vendorId]?.bills?.push(ele);
			} else if (ele?.vendorId !== null) {
				vendorWiseBills[ele?.vendorId] = {
					vendorId: ele?.vendorId,
					vendorName: ele?.vendorName,
					bills: [ele],
				};
			}
		});
		toggleBulkPaymentModal(true, {
			vendorWiseBills: Object.values(vendorWiseBills),
		});
	};

	const handleParentType = (value) => {
		setParentType(value);
	};

	const footerComponent = () => {
		return (
			<div className={styles.footer}>
				<div className={styles.bill_type}>
					<label>Bill Type:</label>
					<SelectPicker
						data={['ALL', 'GRN', 'ADVANCE', 'CAPEX']?.map((ele) => {
							return {
								label: ele,
								value: ele,
							};
						})}
						value={parentType}
						onChange={handleParentType}
						searchable={false}
						style={{ width: 150 }}
						placeholder='Select without search'
					/>
				</div>
			</div>
		);
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<POItemsModal
				handleConfirm={handleApproveBill}
				handleReject={handleRejectBill}
			/>
			<AlertModal />
			<TransactionsModal />
			<BulkPaymentModal />
			<FileUploaderModal handleConfirm={handleInvoiceUpload} />
			<PaymentModal handleConfirm={handleMakePayment} />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Bills List'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					update={update}
					statusList={statusList}
					timeTypeArray={timeTypeArr}
					additionInfo={{
						// showAdd: userPermissions.INTERNAL,
						showAdd: false,
						addText: 'Bill',
						handleAddClick,
					}}
					externalFilters={externalFilters}
					footerComponent={footerComponent}
				/>
			</div>
			<div className={styles['list-container']}>
				<BillsListing
					BILLS={BILLS}
					vendors={vendors}
					userPermissions={userPermissions}
					togglePaymentModal={togglePaymentModal}
					toggleFileUploaderModal={toggleFileUploaderModal}
					togglePOItemsModal={togglePOItemsModal}
					toggleAlert={toggleAlert}
					handleCancelBill={handleCancelBill}
					selectedBills={selectedBills}
					setSelectedBills={setSelectedBills}
					moreOptionsList={moreOptionsList}
					viewMoreOptionsList={viewMoreOptionsList}
					handleBulkPayment={handleBulkPayment}
					navState={navState}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;

	return {
		userPermissions,
		navState,
		vendors,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
	toggleTransactionsModal: modalActions.toggleTransactionsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	toggleBulkPaymentModal: modalActions.toggleBulkPaymentModal,
	fetchVendors: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsPage);
