import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const financialTransactionsServices = {
	fetchPettyCashList,
	fetchBillsSummary,
};

function fetchPettyCashList(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.PETTY_CASH_LIST, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchBillsSummary({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FETCH_BILLS_SUMMARY, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default financialTransactionsServices;
