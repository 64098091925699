/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
// import SkuHighlightChart from './SkuHighlightChart/SkuHighlightChart';
// import VendorHighlightChart from './VendorHighlightChart/VendorHighlightChart';
import { useState, Fragment } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
// import { Chart } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './TwoColumnCharts.module.scss';
import PieChart from '../PieChart/PieChart';
import BarWithLineChart from '../BarWithLineChart/BarWithLineChart';
import { chartTypes } from '../tempdata';
import HighlightChart from '../../../Components/Charts/HighlightChart/HighlightChart';
import LineChart from '../../../Components/Charts/LineChart/LineChart';
import Table from '../Table/Table';
import downloadDashboardDataService from '../../../Services/downloadDashboardData.services';
import { BackdropLoader } from '../../../Components/Loaders';
import notificationServices from '../../../Services/notification.services';

// Chart.register(ChartDataLabels);

function displayChart(type, data, productsList) {
	switch (type) {
		case chartTypes.pieChart:
			return (
				<>
					<PieChart data={data} className={styles.height} />
				</>
			);
		case chartTypes.barWithLineChart:
			return (
				<>
					<BarWithLineChart data={data} className={styles.height} />
				</>
			);
		case chartTypes.barChart:
			return (
				<>
					<HighlightChart data={data} className={styles.height} />
				</>
			);
		case chartTypes.areaChart:
			return (
				<>
					<LineChart data={data} className={styles.height} />
				</>
			);
		case chartTypes.listTable:
			return (
				<>
					<Table
						productsList={productsList}
						data={data}
						className={styles.height}
					/>
				</>
			);

		default:
			// eslint-disable-next-line consistent-return
			return (
				<>
					<div> some other Chart </div>
				</>
			);
	}
}
function TwoColumnCharts(props) {
	const { data = {} } = props;
	const [isLoading, setIsLoading] = useState(false);
	// function handleDownload() {
	// 	const request = {
	// 		period: {
	// 			endDate: data?.endDate,
	// 			startDate: data?.startDate,
	// 		},
	// 	};
	// 	if (data.store) {
	// 		request.store = data.store;
	// 	}
	// 	(async () => {
	// 		setIsLoading(true);
	// 		const downloadLinkData = await downloadDashboardDataService({
	// 			req: {
	// 				data: request,
	// 			},
	// 		});
	// 		setIsLoading(false);
	// 		if (downloadLinkData?.responseCode === 'SS-001') {
	// 			window.open(downloadLinkData?.data?.downloadLink, '_blank');
	// 		} else {
	// 			notificationServices.generateNotification({
	// 				type: 'error',
	// 				message: 'Some Error occured',
	// 			});
	// 		}
	// 		// console.log(downloadLinkData);
	// 	})();
	// }

	// eslint-disable-next-line no-unused-vars, consistent-return
	return (
		<div
			className={styles.root}
			style={{
				background: data?.color ?? '',
			}}
		>
			{data?.heading ? (
				<div className={styles.heading}>
					<div className={styles.line} />
					{data?.heading}
					<div className={styles.line} />
				</div>
			) : (
				<></>
			)}
			{/* {data?.isDownload ? (
				<Tooltip title={data?.heading} arrow>
					<div
						onClick={() => {
							handleDownload();
						}}
						className={styles.downloadCompleteData}
					>
						<DownloadIcon className={styles.icon} />
					</div>
				</Tooltip>
			) : (
				<></>
			)} */}
			<div className={styles.container}>
				{data?.charts?.map((ele) => {
					return (
						<Fragment key={ele?.heading}>
							{displayChart(ele?.type, ele, props?.productsList)}
						</Fragment>
					);
				})}
			</div>
			<BackdropLoader showProgress isLoading={isLoading} />
		</div>
	);
}

export default TwoColumnCharts;
