// eslint-disable-next-line object-curly-newline
import { useRouteMatch } from 'react-router';
import DefectsListFilter from '../../Components/Forms/Defects/DefectsListFilter/DefectsListFilter';
import { urls } from '../../Constants';
import {
	DefectsCreatePage,
	DefectsListPage,
	DefectsViewPage,
} from '../../Pages';
import CRUDRoutes from '../CRUD Routes/CRUD.routes';

const breadcrumbs = [
	{
		link: `${urls.EXTENSION}${urls.DEFECTS}`,
		name: 'Features',
	},
];

const SEARCH_BY = {
	options: [
		{
			key: 'LABEL',
			title: 'Label',
			get: 'label',
		},
		{
			key: 'BRAND',
			title: 'Brand',
			get: 'brandName',
		},
	],
};

const DefectRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='FEATURES'
			path={path}
			listPage={DefectsListPage}
			listFilter={<DefectsListFilter />}
			searchBy={SEARCH_BY}
			viewPage={<DefectsViewPage breadcrumbs={breadcrumbs} />}
			createPage={<DefectsCreatePage breadcrumbs={breadcrumbs} />}
			editPage={<DefectsCreatePage breadcrumbs={breadcrumbs} isEdit />}
		/>
	);
};

export default DefectRoutes;
