/* eslint-disable import/named */
// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { GeneralDetails } from '../../../Components/Forms/ProductCategory/GeneralDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import { ImageCropModal } from '../../../Components/Modals/ImageCropModal';
import { urls } from '../../../Constants';
import { inputHelper, isEmpty } from '../../../Helpers';
// eslint-disable-next-line object-curly-newline
import ProductsConfig from './ProductCategory.config';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { notificationServices } from '../../../Services';

const ProductCreatePage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const {
		isEdit,
		saveProductCategory,
		data,
		fetchProductCategory,
		breadcrumbs,
		toggleImageCrop,
	} = props;

	const disabledUnits =
		measurement === 'US' ? ['gram', 'litre', 'ml'] : ['oz', 'floz'];
	const [state, setState] = useState({
		...ProductsConfig.initialState,
		fields: {
			...ProductsConfig.initialState?.fields,
			unit: ProductsConfig.initialState?.fields?.unit?.filter(
				(ele) => {
					return !disabledUnits?.includes(ele?.id);
				}
			),
		},
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const [unitExist, setUnitExist] = useState(true);

	const ProductCategoryId = Number(useParams().id);
	const history = useHistory();
	const { fields, errors } = state;
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === ProductCategoryId) {
				setUnitExist(!!data?.unit);
				setState(ProductsConfig.serializer(state, {
					...data, measurement,
				}));
			} else {
				fetchProductCategory({
					entity: 'PRODUCT_CATEGORY',
					req: {
						params: {
							id: ProductCategoryId,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(ProductsConfig.serializer(state, {
					...data, measurement,
				}));
			}
		}
	}, [data]);
	const generateDefaultOption = (type, brand) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'unit':
				newState[fieldName] = state.fields.unit.map(
					(productUnitObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							newState.perUnit = productUnitObject?.id === 'floz' ? 'Gal' : 'ltr';
							return {
								...productUnitObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = ProductsConfig.deserializer(state);
		if (!req.data.unit || req.data.unit === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No unit selected',
			});
			return;
		}
		setNumberOfSubmissions(numberOfSubmissions + 1);
		if (isEdit) {
			req.params = {
				id: ProductCategoryId,
			};
			saveProductCategory({
				entity: 'PRODUCT_CATEGORY',
				req,
				method: 'UPDATE',
				redirect: `${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`,
			});
		} else {
			saveProductCategory({
				entity: 'PRODUCT_CATEGORY',
				req,
				method: 'CREATE',
				redirect: `${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`,
			});
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.PRODUCT_CATEGORY}/view/${ProductCategoryId}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`);
		}
	};

	const openImageCropModal = (name, file = null) => {
		// console.log('TESTTTT', name, file);
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		// console.log('FILEEE', file);
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleLogoInput = (fieldName, blob) => {
		toggleImageCrop(false, {});
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: blob,
			},
			...errors,
		});
	};

	return (
		<div className='root'>
			<ImageCropModal
				handleConfirm={handleLogoInput}
				handleClose={() => {
					toggleImageCrop(false, {});
				}}
			/>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={
						isEdit
							? 'Edit Product Category'
							: 'Create Product Category'
					}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `View ${state.fields.name}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				{/* Product details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={ProductsConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						generateDefaultOption={generateDefaultOption}
						handleImageSelect={handleImageSelect}
						unitExist={unitExist}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_CATEGORY.data.VIEW;
	return {
		data,
	};
};
const mapDispatchToProps = {
	fetchProductCategory: formActions.gateway,
	saveProductCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
	toggleImageCrop: modalActions.toggleImageCrop,
};
ProductCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreatePage);
