import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const processFlowServices = {
	gateway,
	fetchNodes,
	fetchAvailableNodes,
	fetchAvailableSections,
	saveNodes,
	addNode,
	fetchAnalytics,
	addSection,
	edgeDelete,
	nodeDelete,
	nodeEdit,
};

function gateway({ req, method }) {
	switch (method) {
		case 'LIST':
			return fetchNodes(req);
		default:
			break;
	}
	return new Promise();
}
function edgeDelete(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.PROCESS_FLOW_EDGE_DELETE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function nodeDelete(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.PROCESS_FLOW_NODE_DELETE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function nodeEdit(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.PROCESS_FLOW_NODE_EDIT, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchNodes(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.PROCESS_FLOW_NODES, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}
function fetchAvailableNodes() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.PROCESS_FLOW_AVAILABLE_NODES, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}
function fetchAvailableSections(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.PROCESS_FLOW_AVAILABLE_SECTIONS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

function saveNodes(req) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.PROCESS_FLOW_SAVE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function addNode(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(endpoints.PROCESS_FLOW_ADD, requestOptions).then(
		handleResponse
	);
}
function fetchAnalytics(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(endpoints.PROCESS_FLOW_ANALYTICS, requestOptions).then(handleResponse);
}
function addSection(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(endpoints.PROCESS_FLOW_ADD_SECTION, requestOptions).then(
		handleResponse
	);
}

export default processFlowServices;
