import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../Pages';
import MyContainersCreatePage from '../../../Pages/Accounts/MyContainers/MyContainersCreatePage/MyContainersCreatePage';
import MyContainersPage from '../../../Pages/Accounts/MyContainers/MyContainersPage';

const MyContainersRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MyContainersPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<MyContainersCreatePage />}
					entity='CONTAINERS'
				/>
			</Route>
		</Switch>
	);
};

export default MyContainersRoutes;
