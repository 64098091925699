/* eslint-disable object-curly-newline */
import { useState, useMemo, useCallback } from 'react';
import AssetGroup from './AssetGroup';

const FilterButton = ({ onClick, colorClasses, count, title }) => {
	const baseClasses = 'px-2 py-1 rounded-full text-xs cursor-pointer';
	return (
		<span
			onClick={onClick}
			className={`relative flex items-center gap-1 ${baseClasses} ${colorClasses}`}
		>
			{title}
			{count > 0 && (
				<span className=' bg-white rounded-full w-4 h-4 flex items-center justify-center text-[8px] font-medium text-gray-500'>
					{count}
				</span>
			)}
		</span>
	);
};

const FilterSection = ({ items, selectedId, onSelect, colorScheme, name }) => {
	const colorClasses = useCallback(
		(selected) => {
			switch (colorScheme) {
				case 'blue':
					return `${
						selected
							? 'bg-blue-600 text-white hover:text-black'
							: 'bg-blue-50 text-blue-600'
					} hover:bg-blue-100 `;
				case 'green':
					return `${
						selected
							? 'bg-emerald-600 text-white hover:text-black'
							: 'bg-emerald-50 text-emerald-600'
					} hover:bg-emerald-100`;
				case 'yellow':
					return `${
						selected
							? 'bg-amber-600 text-white hover:text-black'
							: 'bg-amber-50 text-amber-600'
					} hover:bg-amber-100`;
				default:
					return '';
			}
		},
		[colorScheme]
	);

	return (
		<div className='flex flex-col px-2 py-1  rounded-lg bg-slate-100 gap-1'>
			<div
				className={`w-full text-xs px-2 
					rounded-lg font-bold 
					${colorClasses(false)} !bg-transparent !hover:bg-transparent`}
			>
				{name ?? 'name'}
			</div>
			<div className='flex gap-2  flex-wrap'>
				{Array.from(items.values()).map((item) => (
					<FilterButton
						key={item.id}
						onClick={() => {
							onSelect(item.id === selectedId ? -999 : item.id);
						}}
						count={item.count}
						title={item.name}
						colorClasses={colorClasses(item.id === selectedId)}
					/>
				))}
			</div>
		</div>
	);
};

const CartFilters = ({
	uniqueCategories,
	selectedCategory,
	setSelectedCategory,
	uniqueOems,
	selectedOem,
	setSelectedOem,
	uniqueTypes,
	selectedType,
	setSelectedType,
}) => {
	return (
		<div className='flex flex-row gap-1'>
			<FilterSection
				items={uniqueCategories}
				selectedId={selectedCategory}
				onSelect={setSelectedCategory}
				colorScheme='blue'
				name='Categories'
			/>
			<FilterSection
				items={uniqueOems}
				selectedId={selectedOem}
				onSelect={setSelectedOem}
				colorScheme='green'
				name='OEMs'
			/>
			<FilterSection
				items={uniqueTypes}
				selectedId={selectedType}
				onSelect={setSelectedType}
				colorScheme='yellow'
				name='Asset Types'
			/>
		</div>
	);
};

// eslint-disable-next-line no-unused-vars
const AssetGroupSelectionSummary = ({ cartItems, groupedAssets, extraData = {} }) => {
	const [selectedCategory, setSelectedCategory] = useState(-999);
	const [selectedOem, setSelectedOem] = useState(-999);
	const [selectedType, setSelectedType] = useState(-999);

	const { uniqueCategories, uniqueOems, uniqueTypes } = useMemo(() => {
		const categories = new Map([['-999', { id: -999, name: 'All' }]]);
		const oems = new Map([['-999', { id: -999, name: 'All' }]]);
		const types = new Map([['-999', { id: -999, name: 'All' }]]);

		Object.values(groupedAssets)
			?.filter((group) => cartItems?.[group?.groupBySpecsKey])
			.forEach((group) => {
				if (group.category?.id) {
					categories.set(group.category.id, {
						...group.category,
						count: cartItems?.[group?.groupBySpecsKey],
					});
				}
				if (group.oem?.id) {
					oems.set(group.oem.id, {
						...group.oem,
						count: cartItems?.[group?.groupBySpecsKey],
					});
				}
				if (group.assetType?.id) {
					types.set(group.assetType.id, {
						...group.assetType,
						count: cartItems?.[group?.groupBySpecsKey],
					});
				}
			});

		return {
			uniqueCategories: categories,
			uniqueOems: oems,
			uniqueTypes: types,
		};
	}, [groupedAssets, cartItems]);

	const filteredCartItems = useMemo(() => {
		return Object.entries(cartItems).filter(([groupKey]) => {
			const group = groupedAssets[groupKey];
			const matchesCategory =
				selectedCategory === -999 ||
				group.category?.id === selectedCategory;
			const matchesOem =
				selectedOem === -999 || group.oem?.id === selectedOem;
			const matchesType =
				selectedType === -999 || group.assetType?.id === selectedType;
			return matchesCategory && matchesOem && matchesType;
		});
	}, [cartItems, groupedAssets, selectedCategory, selectedOem, selectedType]);

	return (
		<div className={`flex flex-col ${extraData?.containerCss ? extraData?.containerCss : ''} gap-2`}>
			<CartFilters
				uniqueCategories={uniqueCategories}
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
				uniqueOems={uniqueOems}
				selectedOem={selectedOem}
				setSelectedOem={setSelectedOem}
				uniqueTypes={uniqueTypes}
				selectedType={selectedType}
				setSelectedType={setSelectedType}
			/>
			<div className={`grid grid-cols-4 gap-2 p-4 flex-1 overflow-y-auto scrollbar-thick ${extraData?.gridCss ? extraData?.gridCss : ''}`}>
				{filteredCartItems.map(([groupKey, currentCount]) => {
					const group = groupedAssets[groupKey];
					return (
						<div key={groupKey} className='relative p-1 '>
							<AssetGroup
								showAsItem
								groupTitle={group.groupBySpecs}
								category={group.category}
								assetType={group.assetType}
								imageUrl={group.imageUrl}
								oem={group.oem}
								specsToBeShownOnGroups={
									group.specsToBeShownOnGroups
								}
							/>
							<div className='absolute -top-2 -right-2'>
								<span className='flex items-center justify-center w-6 h-6 bg-blue-100 text-blue-600 rounded-full text-sm font-medium'>
									{currentCount}
								</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AssetGroupSelectionSummary;
