import styles from './TxnHeader.module.scss';

const TxnHeader = (props) => {
	const { heading, helper } = props;
	return (
		<div className={styles.root}>
			<p>{heading}</p>
			<p className={styles.helper}>{helper}</p>
		</div>
	);
};

export default TxnHeader;
