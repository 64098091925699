import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';

function PurchaseOrderHistory() {
	return (
		<div>
			<ImagesFinder FILTERS={['raisedBy', 'vendor', 'status']} />
		</div>
	);
}

export default PurchaseOrderHistory;
