import { Backdrop } from '@mui/material';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import cookingLoader from '../../Images/cooking-loader.json';

const styles = {
	padding: '5px',
	border: '1px solid #fff',
	borderRadius: '4px',
	cursor: 'pointer',
};

const BackdropLoader = (props) => {
	const { isLoading, showProgress } = props;
	const [loading, setLoading] = useState(isLoading ?? false);

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	return (
		<Backdrop
			sx={{
				color: '#fff',
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem',
				zIndex: (theme) => {
					return theme.zIndex.drawer + 1;
				},
			}}
			open={loading}
		>
			{/* {showProgress && <CircularProgress color='inherit' />} */}
			{showProgress && (
				<Lottie
					style={{
						width: 200,
						height: 200,
					}}
					animationData={cookingLoader}
					loop
				/>
			)}
			<div
				onClick={() => {
					setLoading(false);
				}}
				style={styles}
			>
				Hide Loading
			</div>
		</Backdrop>
	);
};

BackdropLoader.defaultProps = {
	showProgress: false,
	isLoading: false,
};

export default BackdropLoader;
