/* eslint-disable react/jsx-wrap-multilines */
import { cloneDeep } from 'lodash';
// eslint-disable-next-line object-curly-newline
import {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { modalActions } from '../../../Actions';
import analyticsAction from '../../../Actions/analytics.actions';
import { Header } from '../../../Components/Analytics/Inventory';
import {
	FeedHUD,
	// FeedHUD,
	// Footer,
	// Measurement,
	Reference,
	VideoFeed,
} from '../../../Components/Analytics/Quality';
import { AltAlertModal, RetagModal } from '../../../Components/Modals';
import { endpoints, urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import {
	history,
	inputHelper,
	keyPressHandler,
	useClientRect,
	useImageDimensionsRef,
	useQuery,
} from '../../../Helpers';
import useKeyPress from '../../../Helpers/useKeyPress';
import {
	liveIMSServices,
	notificationServices,
	retagServices,
} from '../../../Services';
import { liveQCServices } from '../../../Services/LiveQC';
import { MachineLayout } from '../../Layouts';
import config from './config';

const initialState = cloneDeep(config.initialState);

function reducer(state, action) {
	switch (action.type) {
		case 'INIT':
			return {
				...initialState,
				...(action?.payload ?? {}),
			};
		case 'SET_IMS_STATUS':
			return {
				...state,
				...config.serializer(state, action.payload),
			};
		case 'SET_STORE_DATA':
			return {
				...state,
				stores: action.payload,
			};
		case 'SET_SELECTED_STORE_DATA':
			return {
				...state,
				selectedStore: action.payload,
			};
		case 'SET_PAST_IMS_DATA':
			return {
				...state,
				...config.serializer(state, action.payload),
			};
		case 'NO_STOCK_IN_TODAY':
			return {
				...initialState,
				stores: state.store,
				selectedStore: state.selectedStore,
				result: 'FAIL',
				message: 'No Stock In Done Today',
			};
		default:
			return state;
	}
}

const IMSPage = (props) => {
	const { live, listData, toggleAltAlert, updateIMSList, userPermissions } =
		props;
	const [tempVisibility, setTempVisibility] = useState(true);
	const { EDIT = false, ANNOTATE = false } = userPermissions;
	const keyPressed = useKeyPress();

	// qr code
	const { index } = useParams();
	const query = useQuery();

	const scanId = query.get('id');

	const listIndex = useMemo(() => {
		const parsedIndex =
			index?.split('_')?.length > 1 ? index : parseInt(index, 10);
		return listData?.findIndex((scan) => {
			return (
				parseInt(scan.qrCode, 10) === parsedIndex &&
				scan.id === parseInt(scanId, 10)
			);
		});
	}, [index, scanId]);

	const [state, dispatch] = useReducer(reducer, initialState);

	const { selectedStore } = state;

	const imsStatusPollingRef = useRef(null);
	const mountRef = useRef(true);

	const [getContainerRect, imageContainerRef] = useClientRect();
	const imageDimensions = useImageDimensionsRef(
		state?.measurements?.camera?.image
	);

	useEffect(() => {
		const imsData = listData?.[listIndex] ?? null;
		if (
			(keyPressed === 'ArrowLeft' ||
				keyPressed === 'ArrowRight') &&
			imsData !== null
		) {
			const newListItem = keyPressHandler(
				keyPressed,
				listData,
				listIndex
			);
			if (newListItem) {
				history.push(
					`${urls.EXTENSION}${urls.PAST_IMS}/${newListItem?.qrCode}?id=${newListItem?.id}`
				);
			}
		}
	}, [keyPressed]);

	useEffect(() => {
		(async () => {
			const response = await liveQCServices.getStoreList();
			dispatch({
				type: 'SET_STORE_DATA',
				payload: response?.data ?? [],
			});
		})();
	}, []);

	const getIMSStatus = useCallback(
		async ({
			stockInId = null,
			store = null,
			imgDimension,
			containerRect,
		}) => {
			try {
				const req = {
					params: {
						storeId: selectedStore?.storeId || '',
					},
				};

				if (store != null) {
					req.params = {
						...req.params,
						storeId: store,
					};
				}

				if (stockInId != null) {
					req.params = {
						...req.params,
						stockInId,
					};
				}

				const response = await liveIMSServices.getLiveData(req);
				const { data } = response ?? {};

				if (data == null) {
					toggleAltAlert(true, {
						heading: 'System Not Active',
						message: 'Selected system is not activated today',
					});
					dispatch({
						type: 'NO_STOCK_IN_TODAY',
						payload: {
							stores: state.stores,
						},
					});
				} else {
					dispatch({
						type: 'SET_IMS_STATUS',
						payload:
							data != null
								? {
									...data,
									imgDimension,
									containerRect,
								}
								: null,
					});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		},
		[selectedStore]
	);

	const getPastIMSData = useCallback(
		async ({ qrCode, imgDimension, containerRect }) => {
			try {
				const req = {
					params: {
						qrCode,
					},
				};

				const response = await liveIMSServices.getPastData(req);

				dispatch({
					type: 'SET_PAST_IMS_DATA',
					payload:
						response?.data !== null
							? {
								...response?.data,
								imgDimension,
								containerRect,
							}
							: null,
				});
				updateIMSList(
					listIndex > -1 ? listIndex : 0,
					response?.data ?? {}
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		},
		[listIndex]
	);

	const startIMS = useCallback(() => {
		try {
			if (imsStatusPollingRef.current != null) {
				clearInterval(imsStatusPollingRef.current);
				imsStatusPollingRef.current = null;
			}
			const imsStatusPollingId = setInterval(() => {
				if (!mountRef.current) {
					clearInterval(imsStatusPollingRef.current);
				} else {
					getIMSStatus(false, imageDimensions.current, getContainerRect());
				}
			}, 10000);
			imsStatusPollingRef.current = imsStatusPollingId;
			getIMSStatus(true, imageDimensions.current, getContainerRect());
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}, [getIMSStatus, imageDimensions, getContainerRect]);

	const validateSKU = async ({ labelId = null, label = null } = {}) => {
		const pastIdentification = state.identification?.[0] ?? {};
		const req = {};
		req.params = {
			qcId: scanId,
		};
		req.data = {
			identification: [
				{
					x: pastIdentification?.x ?? null,
					y: pastIdentification?.y ?? null,
					width: pastIdentification?.width ?? null,
					height: pastIdentification?.height ?? null,
					labelId:
						labelId === -1 ? null : labelId ?? state.product.id,
					label: label ?? state.product.name,
				},
			],
		};

		try {
			const response = await retagServices.submitRetag(req);
			const { message } = response;
			notificationServices.generateNotification({
				type: 'success',
				message,
			});
			getPastIMSData({
				imgDimensions: imageDimensions.current,
				containerRect: getContainerRect(),
			});
			return true;
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
			return false;
		}
	};

	useEffect(() => {
		if (live === true) {
			if (state?.selectedStore?.storeId != null) {
				startIMS();
			} else {
				dispatch({
					type: 'INIT',
					payload: {
						stores: state.stores,
					},
				});
			}
		} else if (live === false) {
			// past ims
			const imsData = listData?.[listIndex] ?? null;

			if (imsData != null) {
				dispatch({
					type: 'SET_PAST_IMS_DATA',
					payload: {
						...imsData,
						imgDimensions: imageDimensions.current,
						containerRect: getContainerRect(),
					},
				});
			} else {
				getPastIMSData({
					qrCode: index,
					imgDimensions: imageDimensions.current,
					containerRect: getContainerRect(),
				});
			}
		}
		return () => {
			clearInterval(imsStatusPollingRef.current);
		};
	}, [
		live,
		startIMS,
		selectedStore,
		listIndex,
		imageDimensions,
		getContainerRect,
	]);

	useEffect(() => {
		return () => {
			mountRef.current = false;
			clearInterval(imsStatusPollingRef.current);
		};
	}, []);

	const storeSelector = (e) => {
		let newSelection = {};
		if (e.target.value === '') {
			newSelection = {
				storeName: null,
				storeId: null,
			};
		} else {
			newSelection = {
				storeName: state.stores[e.target.value].name,
				storeId: state.stores[e.target.value].id,
			};
		}
		dispatch({
			type: 'SET_SELECTED_STORE_DATA',
			payload: newSelection,
		});
	};

	const navigateIMS = (e) => {
		const { dataset } = inputHelper(e);

		let newListItem = null;

		if (dataset.type === 'next') {
			newListItem = listData?.[listIndex + 1];
		} else {
			newListItem = listData?.[listIndex - 1];
		}

		history.push(
			`${urls.EXTENSION}${urls.PAST_IMS}/${newListItem?.qrCode}?id=${newListItem?.id}`
		);
	};

	return (
		<>
			<MachineLayout
				Header={
					<Header
						live={live}
						navigateIMS={navigateIMS}
						idx={listIndex}
						selectedStoreName={state.selectedStore.storeName}
						listData={listData}
						storeSelector={storeSelector}
						storeList={state.stores}
						machine={state.machine}
						stockInAt={state.stockInAt}
						stockOutAt={state.stockOutAt}
						qrCode={state.qrCode}
					/>
				}
				Reference={
					<Reference
						// className={styles.referenceBox}
						name={state.product.name}
						source={state.product.image}
						isVeg={state.product.isVeg}
						brand={state.product.brand}
						vendorName={state.product.vendorName}
						assetId={state.assetId}
						temp={{
							min: state.ranges.temp.min,
							max: state.ranges.temp.max,
							title: 'Temperature',
							measurement: state.measurements.temp.values,
							unit: state.measurements.temp.unit,
							result: state.measurements.temp.result,
						}}
						weight={{
							min: state.ranges.weight.min,
							max: state.ranges.weight.max,
							minUnit: state.ranges.weight.minUnit,
							maxUnit: state.ranges.weight.maxUnit,
							title: 'Weight',
							measurement: state.measurements.weight.values,
							unit: state.measurements.weight.unit,
							result: state.measurements.weight.result,
						}}
					/>
				}
				VideoFeed={
					<VideoFeed
						// className={styles.videoFeed}
						live={false}
						tempVisibility={tempVisibility}
						imageContainerRect={getContainerRect()}
						imageContainerRef={imageContainerRef}
						sensorValues={
							state?.measurements?.temp?.sensorValues ?? []
						}
						source={
							state?.measurements?.camera?.image ??
							endpoints.START_QC_VIDEO_FEED
						}
						weight={{
							live: state.measurements.weight.live,
							unit: state.measurements.weight.unit,
						}}
						temp={{
							live: state.measurements.temp.live,
							unit: state.measurements.temp.unit,
						}}
						disconnections={{
							weight: !state.measurements.weight.connected,
							temp: !state.measurements.temp.connected,
							camera: !state.measurements.camera.connected,
						}}
					>
						<FeedHUD
							result={state.result}
							itemId={state.qrCode}
							scanId={scanId}
							currentDate={state.currentDate}
							lastQcAt={state.lastQcAt}
							currentIndex={index}
							showAnnotation={ANNOTATE && !live}
							showRetag={ANNOTATE && !live}
							showValidate={EDIT && !live}
							showFirstTimeValidate={EDIT && !live}
							type='IMS'
							setTempVisibility={setTempVisibility}
							tempVisibility={tempVisibility}
							isDuplicate={state?.duplicate}
							hideAnnotation
							message={state.message}
						/>
					</VideoFeed>
				}
			/>
			<AltAlertModal />
			<RetagModal
				handleSubmit={validateSKU}
				identification={state.identification}
				brandId={state.product.brandId}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const { list: listData } = state.analytics.INVENTORY_ANALYTICS.SEARCH_DATA;
	const { userPermissions } = state.navigation;

	return {
		listData,
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleAltAlert: modalActions.toggleAltAlertModal,
	updateIMSList: analyticsAction.updateIMSList,
};

IMSPage.defaultProps = {
	live: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(IMSPage);
