/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const skuServices = {
	gateway,
	listSKUs,
};

function gateway({ req, method }) {
	switch (method) {
		case 'LIST':
			return listSKUs({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

// const skusList = {
// 	message: 'Success',
// 	status: 'Success',
// 	statusCode: 200,
// 	data: [
// 		{
// 			id: 50,
// 			brandId: 12,
// 			brandName: 'Chandni Chowk',
// 			skuName: 'Gol Gappe (Pack of 6) - Sooji',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/https%3A//resoee.s3-ap-south-1.amazonaws.com/6/Inventory/SKU/Gol%20Gappe%20%28Pack%20of%206%29%20-%20Sooji-01-06-21_14%3A31%3A00.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=2c16bce7f825656bfb3a00d7b049deb54ad37915697bb7472de64808aac6c622',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 60,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 61,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Pizza batter',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Pizza%20batter-07-06-21_19%3A41%3A51.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=212b135bc1929399b99e99c872a9cebda93090232f68b443aab8af396d51712a',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 62,
// 			brandId: 26,
// 			brandName: 'Ludico',
// 			skuName: 'Pizza Sauce',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Pizza%20Sauce-07-06-21_19%3A43%3A57.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=706c803f8b250f80ea83f7589594b21c854d369555aa6caaefe70a59122c44af',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 63,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Pepperoni',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Pepperoni-07-06-21_19%3A46%3A10.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=38bba9eadf91f5607bfc65ca4f906f363f9b671ea357dbb7b4289285499341a1',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 64,
// 			brandId: 26,
// 			brandName: 'Ludico',
// 			skuName: 'Cheese',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Cheese-07-06-21_19%3A47%3A40.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=51787ebe9a30852952576765bfc8e00718b1388a2a693c93305c9e9e195d5925',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 500,
// 		},
// 		{
// 			id: 65,
// 			brandId: 27,
// 			brandName: 'Obvious! Burger',
// 			skuName: 'Burger patty',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Burger%20patty-07-06-21_22%3A26%3A22.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=3333851caaf02b8843102958c2fead44a806f2bca90cb381c1d768ac87c3d67b',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 30,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 66,
// 			brandId: 27,
// 			brandName: 'Obvious! Burger',
// 			skuName: 'Burger Bun',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Burger%20Bun-07-06-21_22%3A27%3A36.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=f7adbf099e6e2137071a74ecdd8d21ecf611d38edfb4f9f6fb70e32d24ffa638',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 10,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 67,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Onion',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Onion-07-06-21_22%3A34%3A54.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d611f958a5ddd91e9d12bb97fec488cd9307f884b24c4e36bf366a9555b5d3f0',
// 			unit: 'gram',
// 			skuCategoryId: 54,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 50,
// 		},
// 		{
// 			id: 68,
// 			brandId: 27,
// 			brandName: 'Obvious! Burger',
// 			skuName: 'Burger Sauce',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Burger%20Sauce-07-06-21_22%3A42%3A17.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=36d179dcd543c7dab9c9b682b2adea1ea968eb4d07661094752360b786563cfa',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 450,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 69,
// 			brandId: 27,
// 			brandName: 'Obvious! Burger',
// 			skuName: 'Lettuce',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Lettuce-07-06-21_22%3A48%3A01.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=1939ac0c862d0669aed6dddce546f756e20ec4af684629c1e471030c5b9e08c3',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 70,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Tomato',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Tomato-07-06-21_22%3A50%3A01.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=583484ed6f8d2382fc3198e71c39bea3455b3ac1b62439c72099b36fd6f860fa',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 25,
// 		},
// 		{
// 			id: 131,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Pizza Dough Batter',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Pizza%20Dough%20Batter-10-06-21_15%3A38%3A59.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=f42a586eb524711ba7b0c3b5e216e1769502ee55c541226a6f66fc4c702cfe0e',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 10,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 132,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Grated Pizza Cheese',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Grated%20Pizza%20Cheese-10-06-21_15%3A53%3A24.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=bafae2a5bec215d06ec14fed274e42264fe785c2f2afbdd4080c697e450e0048',
// 			unit: 'gram',
// 			skuCategoryId: 55,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 158,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Chopped Capcicum',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Product/Chopped%20Capcicum-10-06-21_19%3A48%3A50.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=0e7207ad5285f3e813578678d025feb559000bd173e4b8e58ac47d31facc6341',
// 			unit: 'gram',
// 			skuCategoryId: 60,
// 			sellingPrice: null,
// 			categoryName: null,
// 			tax: 1,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 226,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'New Hand Tossed',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/New%20Hand%20Tossed-23-09-21_11%3A12%3A31.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=c9112f084ec63ba620b610da4f13f09a571e5b6209d1f038317a80812c228962',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 10,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 227,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Wheat Thin Crust',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Wheat%20Thin%20Crust-23-09-21_11%3A13%3A21.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e23a777cfcc5c8d9e68c1704c7a23584b50ca493b302195d1a2e29bfe4257a4c',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 10,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 228,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Cheese Burst',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Cheese%20Burst-23-09-21_11%3A14%3A59.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=cbf319afe444f89a870c164d61b63ba967cb35a80e7457c98b18a9c6f269ca30',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 10,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 229,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Fresh Pan Pizza',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Fresh%20Pan%20Pizza-23-09-21_11%3A15%3A57.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=899399fa9093b9f4c4ae7c7364f7d005026c3dd945eec4bd8d603959bbc72231',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 10,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 230,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Grilled Mushrooms',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Grilled%20Mushrooms-23-09-21_11%3A19%3A19.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=f9c7e06d9782ba57cbde4dd965d49fc816ab36b801d5d63028ff710b07fa3a69',
// 			unit: 'gram',
// 			skuCategoryId: 54,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 231,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Capsicum',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Capsicum-23-09-21_11%3A20%3A59.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=5ffe7f61126e45a15d761a33b180867da75ed191d2d3379ba36168ffa0bdc092',
// 			unit: 'gram',
// 			skuCategoryId: 55,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 232,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Paneer',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Paneer-23-09-21_11%3A25%3A01.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=a5511a60e188015800c9e308401968ef7e980b68661862e5cebba8710e5ef1fa',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 233,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Black Olive',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Black%20Olive-23-09-21_11%3A26%3A39.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=b75e494256f081c309d92f823ca8a14021b74b8e1cfb4aadbf63a983029d71e7',
// 			unit: 'gram',
// 			skuCategoryId: 55,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1000,
// 		},
// 		{
// 			id: 234,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Golden Corn',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Golden%20Corn-23-09-21_11%3A27%3A16.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=35326e466c0e6a26c5bb25d061ba2d55901a1b770289662abd6a4be0fddf09f8',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 100,
// 		},
// 		{
// 			id: 235,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: ' Multigrain Bread',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20Multigrain%20Bread-23-09-21_11%3A54%3A10.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=bd5fefc962a8acfb31b39060f34a21ce8ab618690a6e258c57563204f7820a7e',
// 			unit: 'count',
// 			skuCategoryId: 55,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 236,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: 'Multigrain Honey Oats Bread',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/Multigrain%20Honey%20Oats%20Bread-23-09-21_12%3A33%3A54.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=76f8d668aebe4ac7504196bc155903dd6928433b77cc7b62ec03acfbf8e10491',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 1,
// 		},
// 		{
// 			id: 237,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: '  White Italian Bread',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20%20White%20Italian%20Bread-23-09-21_12%3A35%3A55.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=b7b42923b903f493237ae106c351bf3b13970632ba29fe97e86502d6032608f6',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 10,
// 		},
// 		{
// 			id: 238,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: ' Roasted Garlic Bread',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20Roasted%20Garlic%20Bread-23-09-21_13%3A07%3A26.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=c0512e950ad070600b351ae1c2863421babef1de7d9630ce5717cb5e48f0cb3e',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 100,
// 		},
// 		{
// 			id: 239,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: ' Parmesan Oregano Bread',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20Parmesan%20Oregano%20Bread-23-09-21_13%3A08%3A44.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=bc609e1d9dc157ca88351662d4c5c394b7a4e96cf45ff620b92690c90fb41896',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 100,
// 		},
// 		{
// 			id: 240,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: ' Flat Bread',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20Flat%20Bread-23-09-21_13%3A10%3A08.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=b682b736b3507b6f33ab7354ad397d123417135e2db3ee960d4653f71cca68c6',
// 			unit: 'count',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 100,
// 		},
// 		{
// 			id: 241,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: ' Mayonnaise',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20Mayonnaise-23-09-21_13%3A26%3A01.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=70bfb881bbb7c602a31eeee517cbe1ede03e0821a6a4cb83cbae09a6c233acd8',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 100,
// 		},
// 		{
// 			id: 242,
// 			brandId: 36,
// 			brandName: 'Dominos',
// 			skuName: ' Mint Mayonnaise',
// 			skuImageUrl:
// 				'https://resoee.s3.amazonaws.com/6/Inventory/SKU/%20Mint%20Mayonnaise-23-09-21_13%3A33%3A02.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20211013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T061530Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=2a3de35a6adfa5f3ba78d7024e7258ffe3fab7310d4f96a9c6452455ef07009f',
// 			unit: 'gram',
// 			skuCategoryId: 56,
// 			sellingPrice: 1,
// 			categoryName: null,
// 			tax: 5,
// 			unitsInSKU: 100,
// 		},
// 	],
// };

// function listSKUs() {
// 	return new Promise((resolve) => {
// 		resolve(skusList);
// 	});
// }
// return fetch(url, requestOptions).then(handleResponse);
function listSKUs({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.INVENTORY_SKUS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default skuServices;
