import { useState } from 'react';

const usePagination = (props) => {
	const { customLimit = 25 } = props ?? {};

	const [state, setState] = useState(() => {
		return {
			page: 1,
			limit: customLimit != null ? customLimit : 25,
		};
	});

	const { page, limit } = state;

	const nextPage = () => {
		setState({
			...state,
			page: page + 1,
		});
		return page + 1;
	};

	const prevPage = () => {
		setState({
			...state,
			page: page - 1,
		});
		return page - 1;
	};

	const jumpNext = (jumpBy) => {
		setState({
			...state,
			page: page + jumpBy,
		});
		return page + jumpBy;
	};

	const jumpPrev = (jumpBy) => {
		setState({
			...state,
			page: page - jumpBy,
		});
		return page - jumpBy;
	};

	const setPage = (pageNum) => {
		setState({
			...state,
			page: pageNum,
		});
	};

	const setLimit = (pageLimit) => {
		setState({
			...state,
			limit: pageLimit,
		});
	};

	return {
		page,
		limit,
		nextPage,
		prevPage,
		jumpNext,
		jumpPrev,
		setPage,
		setLimit,
	};
};

export default usePagination;
