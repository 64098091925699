import { AccessTime } from '@mui/icons-material';
import styles from './AddProductTimeSlot.module.scss';

const AddProductTimeSlot = (props) => {
	const { handleClick, dataAttrs, disabled } = props;
	const text = 'Schedule';
	return (
		<div className={`${styles.root}`} onClick={handleClick} {...dataAttrs}>
			<span>{`${text}`}</span>
			{!disabled && <AccessTime className={`${styles['add-icon']}`} />}
		</div>
	);
};

AddProductTimeSlot.defaultProps = {
	disabled: false,
	dataAttrs: {},
};

export default AddProductTimeSlot;
