import { useEffect } from 'react';
import { connect } from 'react-redux';
import downArrow from '../../../../Images/down-arrow.gif';
import { UI_FILTERS } from '../../../../Constants';
import CheckTile from '../../../CheckTile/CheckTile';
import styles from './Failure.module.scss';
import { CheckList } from '../../../CheckList';
import { useSearch } from '../../../../Helpers';
import { formActions } from '../../../../Actions';

const checkIfApplied = (key, value) => {
	switch (key) {
		case 'WEIGHT':
			return value['over-weight'] || value['under-weight'];
		case 'TEMPERATURE':
			return value['over-temperature'] || value['under-temperature'];
		case 'DEFECT':
			return value.applied;
		default:
			return false;
	}
};

const Failure = (props) => {
	const { dispatch, level, defects, filterState } = props;

	const { query, handleSearch, clearSearch } = useSearch();

	useEffect(() => {
		return () => {
			clearSearch();
		};
	}, [level]);

	switch (level.current) {
		case 1: {
			const toggleSelection = (key, checked) => {
				switch (key) {
					case 'WEIGHT':
						{
							let newFilter = {};
							if (checked) {
								newFilter = {
									'over-weight': false,
									'under-weight': false,
								};
							} else {
								newFilter = {
									'over-weight': true,
									'under-weight': true,
								};
							}
							dispatch({
								type: 'SET_FAILURE_WEIGHT',
								payload: newFilter,
							});
						}
						break;
					case 'TEMPERATURE':
						{
							let newFilter = {};
							if (checked) {
								newFilter = {
									'over-temperature': false,
									'under-temperature': false,
								};
							} else {
								newFilter = {
									'over-temperature': true,
									'under-temperature': true,
								};
							}
							dispatch({
								type: 'SET_FAILURE_TEMPERATURE',
								payload: newFilter,
							});
						}
						break;
					case 'DEFECT':
						{
							let newFilter = [];
							if (checked) {
								newFilter = [];
							} else {
								newFilter = defects.map((defect) => {
									return defect.id;
								});
							}
							dispatch({
								type: 'SET_DEFECTS',
								payload: newFilter,
							});
						}
						break;
					default:
						break;
				}
			};
			return (
				<div className={styles.grid}>
					{UI_FILTERS.FAILURE.map((currentFilter) => {
						const checked = checkIfApplied(
							currentFilter.key,
							filterState[currentFilter.key]
						);
						return (
							<CheckTile
								key={currentFilter.key}
								onExpand={() => {
									dispatch({
										type: 'NEXT_LEVEL',
										payload: {
											type: 'FAILURE',
											heading: currentFilter.title,
											subType: currentFilter.key,
										},
									});
								}}
								title={currentFilter.title}
								onCheck={() => {
									toggleSelection(currentFilter.key, checked);
								}}
								checked={checked}
							/>
						);
					})}
				</div>
			);
		}
		case 2: {
			const currentFilter = UI_FILTERS.FAILURE.find((failure) => {
				return failure.key === level?.subType;
			});
			switch (level?.subType) {
				case 'WEIGHT': {
					const toggleSelection = (child) => {
						const value = filterState.WEIGHT[child.key];
						dispatch({
							type: 'SET_FAILURE_WEIGHT',
							payload: {
								...filterState.WEIGHT,
								[child.key]: !value,
							},
						});
					};
					return (
						<div className={styles.grid}>
							{currentFilter.children.map((child) => {
								return (
									<CheckTile
										key={child.key}
										onExpand={() => {
											toggleSelection(child);
										}}
										title={child.title}
										onCheck={() => {
											toggleSelection(child);
										}}
										image={downArrow}
										rotate={child.key === 'over-weight'}
										checked={filterState.WEIGHT[child.key]}
									/>
								);
							})}
						</div>
					);
				}
				case 'TEMPERATURE': {
					const toggleSelection = (child) => {
						const value = filterState.TEMPERATURE[child.key];
						dispatch({
							type: 'SET_FAILURE_TEMPERATURE',
							payload: {
								...filterState.TEMPERATURE,
								[child.key]: !value,
							},
						});
					};
					return (
						<div className={styles.grid}>
							{currentFilter.children.map((child) => {
								return (
									<CheckTile
										key={child.key}
										onExpand={() => {
											toggleSelection(child);
										}}
										title={child.title}
										onCheck={() => {
											toggleSelection(child);
										}}
										image={downArrow}
										rotate={
											child.key === 'over-temperature'
										}
										checked={
											filterState.TEMPERATURE[child.key]
										}
									/>
								);
							})}
						</div>
					);
				}
				case 'DEFECT': {
					const selectDefect = (id, selected) => {
						let newDefects = filterState?.DEFECT?.defects ?? [];
						if (selected) {
							newDefects = newDefects.filter((defect) => {
								return defect !== id;
							});
						} else {
							newDefects = [...newDefects, id];
						}
						dispatch({
							type: 'SET_DEFECTS',
							payload: newDefects,
						});
					};

					const list = defects
						.filter((defect) => {
							const name = defect?.label;
							return (
								name
									?.toLowerCase()
									?.indexOf(query?.toLowerCase()?.trim()) !==
								-1
							);
						})
						.map((defect) => {
							return {
								id: defect.id,
								title: defect.label,
							};
						});
					return (
						<div className={styles.list}>
							<CheckList
								list={list}
								selectedItems={
									filterState?.DEFECT?.defects ?? []
								}
								handleItemSelect={selectDefect}
								showSearch
								handleSearchInput={handleSearch}
								placeholder='Search Defect'
							/>
						</div>
					);
				}
				default:
					return <></>;
			}
		}
		default:
			return <></>;
	}
};

Failure.defaultProps = {
	level: {
		current: 2,
		key: 'defect',
		heading: '',
	},
	defects: [],
	filterState: null,
	dispatch: () => {},
};

const mapStateToProps = (state) => {
	const { LIST: defects } = state.form.DEFECTS.data;
	return {
		defects,
	};
};

const mapDispatchToProps = {
	fetchDefects: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(Failure);
