import { useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { inputHelper } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import { BaseModal } from '../BaseModal';
import styles from './PincodeModal.module.scss';

const PincodeModal = (props) => {
	const HEADING = 'Add Pincode';
	const { togglePincodeModal, show } = props;
	const initialState = {
		data: '',
	};
	const [state, setState] = useState({
		...initialState,
	});

	const handleClose = () => {
		setState({
			...initialState,
		});
		togglePincodeModal(false);
	};

	const handleConfirm = () => {
		if (state.data.length === 6) {
			props.handleConfirm(state.data);
			handleClose();
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter 6 digits only',
			});
		}
	};

	const handleInput = (e) => {
		const { fieldValue } = inputHelper(e);
		if (fieldValue.length > 6) {
			return;
		}
		setState({
			...state,
			data: fieldValue,
		});
	};
	return (
		<BaseModal
			heading={HEADING}
			height={200}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
		>
			<form onSubmit={handleConfirm}>
				<div className='form-field'>
					<input
						className={`${styles.input}`}
						type='text'
						id='data'
						name='data'
						onChange={handleInput}
						value={state.data}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus
					/>
				</div>
			</form>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { data, show } = state.modal.pincodes;
	return {
		show,
		data,
	};
};
PincodeModal.defaultProps = {
	show: true,
};

const mapDispatchToProps = {
	togglePincodeModal: modalActions.togglePincodeModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(PincodeModal);
