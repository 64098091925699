/* eslint-disable prefer-template */
import { connect } from 'react-redux';
import {
	Button, IconButton, MenuItem, TextField
} from '@mui/material';
import {
	useEffect, useReducer, useState
} from 'react';
// eslint-disable-next-line no-unused-vars
import InputPicker from 'rsuite/InputPicker';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './MyLicensesCreatePage.module.scss';
import config from './myLicensesCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../../Services';
import { ListModal } from '../../../../Components/Modals';
import DatePickerCustom from '../../../../Components/DatePickerCustom/DatePickerCustom';
import { formActions, modalActions } from '../../../../Actions';
import { Tag } from '../../../../Components/Tags';
import { inputHelper, useQuery } from '../../../../Helpers';
import licenseServices from '../../../../Services/license-services';
import BackdropLoader from '../../../../Components/Loaders/BackdropLoader';

const licenseTypes = ['FMS', 'RESOEE'];

const plansList = ['QUARTERLY', 'YEARLY'];

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		inputLabel,
		labelText,
		mandatory,
		optionsList,
		disabled,
		handleSelect,
		search = false,
	} = props;

	return (
		<div className={`${styles.tile} form-field row`}>
			<label
				className={`${mandatory ? styles.mandatory : ''} form-label`}
				htmlFor={id}
			>
				{labelText}
			</label>
			{!search ? (
				<TextField
					id={id}
					select
					name={name}
					value={value}
					onChange={onChange}
					label={inputLabel}
					disabled={disabled}
					sx={{
						width: 200,
						'.MuiInputBase-root': {
							borderRadius: '4px',
						},
					// '.MuiInputBase-input': {
					// // paddingTop: '6px',
					// // paddingBottom: '6px',
					// },
					}}
				>
					{optionsList?.map((option) => {
						return (
							<MenuItem
								key={option?.id ?? option}
								value={option?.id ?? option}
							>
								{option?.title ?? option}
							</MenuItem>
						);
					})}
				</TextField>
			) : (
				<InputPicker
					preventOverflow
					disabled={disabled}
					data={optionsList}
					name={name}
					onSelect={(val) => handleSelect(val)}
					style={{
						width: 224,
					}}
				/>
			)}
		</div>
	);
};

function MyLicensesCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		toggleListModal,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const [mappableData, setMappableData] = useState({});
	const {
		licenseType,
		store,
		plan,
		startDate,
		endDate,
		machineId,
		outlets,
		mappedMachine,
		graceDays,
	} = state ?? {};
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const isEdit = Boolean(params.get('isEdit'));
	const licenseId = parseInt(params.get('licenseId'), 10);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		if (isEdit && !isNaN(licenseId)) {
			fetchLicense();
		}
	}, [isEdit, licenseId]);

	async function fetchLicense() {
		const req = {
			params: {
				licenseId,
			},
		};
		try {
			const response = await licenseServices?.fetchLicenseList(req);
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_LICENSE',
					payload: response?.data[0],
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	const fetchMappableData = async () => {
		try {
			const response = await licenseServices?.fetchMappableData();
			if (response?.statusCode === 200) {
				setMappableData(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchMappableData();
	}, []);

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer(state, isEdit);
		if (!isEdit && !deserializedData?.licenseType) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'License type is mandatory',
			});
			return;
		}
		if (deserializedData?.licenseType === 'RESOEE' && (!deserializedData?.machineId && deserializedData?.outlets?.length === 0)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Machine or At-least one outlet is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
			},
		};
		// eslint-disable-next-line no-restricted-globals
		if (isEdit && !isNaN(licenseId)) {
			req.params = {
				licenseId,
			};
		}
		setLoading(true);
		try {
			const response = await licenseServices.saveLicense(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const addMonths = (input, months) => {
		const date = new Date(input);
		date.setDate(1);
		date.setMonth(date.getMonth() + months);
		date.setDate(
			Math.min(
				input.getDate(),
				new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
			)
		);
		return date;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'plan') {
			let newEndDate = new Date();
			if (startDate) {
				if (value === 'QUARTERLY') {
					newEndDate = addMonths(startDate, 3);
				}
				if (value === 'YEARLY') {
					newEndDate = addMonths(startDate, 12);
				}
				dispatch({
					type: 'SET_VALUE',
					payload: {
						endDate: newEndDate,
					},
				});
			}
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[name]: value,
			},
		});
	};

	const handleStartDate = (date) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				startDate: date,
			},
		});
		let newEndDate = new Date();
		if (plan && date) {
			if (plan === 'QUARTERLY') {
				newEndDate = addMonths(date, 3);
			}
			if (plan === 'YEARLY') {
				newEndDate = addMonths(date, 12);
			}
			dispatch({
				type: 'SET_VALUE',
				payload: {
					endDate: newEndDate,
				},
			});
		}
	};
	const handleEndDate = (date) => {
		if (!startDate) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select Start Date First',
			});
			return;
		}
		if (startDate > date) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'End Date cannot be less than Start Date',
			});
			return;
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				endDate: date,
			},
		});
	};

	const selectOutlets = (outletsList) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				outlets: outletsList,
			},
		});
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'OUTLET') {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					outlets: outlets.filter((outlet) => {
						return outlet !== parseInt(id, 10);
					}),
				},
			});
		}
	};

	const disabledDate = (rsuiteDate) => {
		if (!startDate) return true;
		return rsuiteDate < startDate;
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={loading} showProgress />
			<ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/>
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>Create New License</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				<LabelledInput
					mandatory
					id='licenseType'
					name='licenseType'
					value={licenseType}
					onChange={handleChange}
					labelText='License Type*'
					optionsList={licenseTypes}
					inputLabel='Select License Type'
					disabled={isEdit}
				/>
				<LabelledInput
					mandatory
					id='baseStore'
					name='store'
					value={store}
					optionsList={stores?.map((ele) => {
						return {
							value: ele?.id,
							label: ele?.name,
						};
					})}
					labelText='Select License Store*'
					inputLabel='Select License Store'
					search
					handleSelect={(val) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								store: val,
							},
						});
					}}
				/>
				<LabelledInput
					id='licensePlan'
					mandatory
					name='plan'
					value={plan}
					onChange={handleChange}
					optionsList={plansList}
					labelText='Select License Plan*'
					inputLabel='Select Plan'
				/>
				<div className={styles.multi}>
					<div className={styles.tile}>
						<label className={styles.mandatory} htmlFor='startDate'>
							Start Date*
						</label>
						<DatePickerCustom
							selectedDate={startDate}
							selectDate={handleStartDate}
							style={{
								width: 200,
							}}
							oneTap
						/>
					</div>
					<div className={styles.tile}>
						<label className={styles.mandatory} htmlFor='endDate'>
							End Date*
						</label>
						<DatePickerCustom
							selectedDate={endDate}
							selectDate={handleEndDate}
							style={{
								width: 200,
							}}
							oneTap
							shouldDisableDate={disabledDate}
						/>
					</div>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className='form-label'
						htmlFor='graceDays'
					>
						Grace Days
					</label>
					<TextField
						id='graceDays'
						name='graceDays'
						value={graceDays}
						onChange={handleChange}
						placeholder='Enter Grace days'
						type='number'
						sx={{
							width: 200,
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				{licenseType === 'RESOEE' && (
					<>
						<div className={styles.multi}>
							{mappedMachine?.machineId ? (
								<div
									className={`${styles.tile} form-field row`}
								>
									<label className='form-label'>
										Mapped Machine
									</label>
									<div className={styles['mapped-machine']}>
										<p>
											{(mappedMachine?.machineName ??
												'') +
												'(' +
												mappedMachine?.machineId +
												')'}
										</p>
										{/* <IconButton
											onClick={() => {
												dispatch({
													type: 'SET_VALUE',
													payload: {
														mappedMachine: {},
													},
												});
											}}
										>
											<Cancel />
										</IconButton> */}
									</div>
								</div>
							) : (
								<LabelledInput
									id='machineId'
									name='machineId'
									value={machineId}
									onChange={handleChange}
									optionsList={mappableData?.availableMachines?.map(
										(ele) => {
											const title =
												(ele?.machine_name ?? '') +
												'(' +
												ele?.id +
												')';
											return {
												...ele,
												title,
											};
										}
									)}
									labelText='Select Machine'
									inputLabel='Select Machine'
								/>
							)}
						</div>
						<div className={styles.tile}>
							<label
								className={styles.mandatory}
								htmlFor='endDate'
							>
								Select Outlets*
							</label>
							<div className={styles['btn-list-container']}>
								<Button
									onClick={() => {
										toggleListModal(true, {
											entity: 'OUTLETS',
											heading: 'Select Outlets',
											list: mappableData?.availableOutlets?.map(
												(ele) => {
													return {
														...ele,
														title: ele?.name,
													};
												}
											),
											selected: outlets,
										});
									}}
									variant='outlined'
									style={{
										width: '200px',
									}}
									disabled={isEdit}
								>
									Outlets
								</Button>
								<div className={styles.tags}>
									{outlets.map((id) => {
										const selectedOutlet = stores.find(
											(d) => {
												return id === d.id;
											}
										);
										return (
											<Tag
												key={id}
												width='200px'
												text={selectedOutlet?.name}
												handleDelete={deleteFilter}
												isDisabled={isEdit}
												dataAttrs={{
													'data-id': id,
													'data-entity': 'OUTLET',
												}}
											/>
										);
									})}
									{outlets?.length === 0 && (
										<span>All products selected</span>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;

	return {
		userPermissions,
		stores,
	};
};

const mapDispatchToProps = {
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyLicensesCreatePage);
