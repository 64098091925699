import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import FranchiseeDetails from '../../../Components/Forms/FranchiseeDetails/FranchiseeDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
import { inputHelper } from '../../../Helpers';
import addFranchiseeConfig from './addFranchisee.config';

const AddCustomersCreatePage = (props) => {
	const {
		isEdit,
		breadcrumbs,
		fetchFranchiseeData,
		data,
		saveFranchiseData,
		label,
	} = props;
	const [state, setState] = useState({
		...addFranchiseeConfig.initialState,
	});
	const { fields, errors } = state;
	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		switch (fieldName) {
			case 'name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'support':
				if (fieldValue.toString().length > 11) {
					return;
				}
				break;
			case 'phoneno':
				if (fieldValue.toString().length > 11) {
					return;
				}
				break;
			case 'city':
				newState[fieldName] = JSON.parse(fieldValue);
				break;
			case 'pincode':
				if (fieldValue.toString().length > 6) {
					return;
				}
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...fields,
				[fieldName]: fieldValue,
				...newState,
			},
		});
	};
	const { id } = useParams();
	useEffect(() => {
		if (isEdit) {
			// if (!isEmpty(data) && data.number === id) {
			// setState(addFranchiseeConfig.serializer(state, data));
			// } else {
			fetchFranchiseeData({
				entity: 'MY_FRANCHISEE',
				req: {
					params: {
						user: id,
					},
				},
				method: 'VIEW',
			});
			// }
		}
	}, []);
	useEffect(() => {
		if (isEdit) {
			if (data) setState(addFranchiseeConfig.serializer(state, data));
		}
	}, [data]);
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		if (addFranchiseeConfig.validate(fields)) {
			req.data = addFranchiseeConfig.deseralizer(fields);
			if (isEdit) {
				req.params = {
					user: id,
				};
				saveFranchiseData({
					entity: 'MY_FRANCHISEE',
					req,
					method: 'UPDATE',
					redirect: `${urls.EXTENSION}${urls.ADD_CUSTOMERS}`,
				});
			} else {
				req.params = {
					user: '',
				};
				saveFranchiseData({
					entity: 'MY_FRANCHISEE',
					req,
					method: 'CREATE',
					redirect: `${urls.EXTENSION}${urls.ADD_CUSTOMERS}`,
				});
			}
		}
	};

	const updateLocation = ({ latitude = null, longitude = null }) => {
		const newState = {};
		setState({
			...state,
			fields: {
				...state.fields,
				latitude,
				longitude,
				...newState,
			},
			...errors,
		});
	};
	const generateDefaultOption = (type, city) => {
		switch (type) {
			case 'city':
				if (!city || city.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a City
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const history = useHistory();
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.ADD_CUSTOMERS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.ADD_CUSTOMERS}`);
		}
	};
	return (
		<div>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					showBreadcrumbs
					heading={isEdit ? 'Edit Franchisee' : 'Add Franchisee'}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${label}`
								: 'Create New Franchisee',
						},
					]}
				/>
				<FormSectionHeader
					sectionName='Franchisee Details'
					tooltip='Franchisee Details'
				>
					<FranchiseeDetails
						fields={fields}
						handleInput={handleInput}
						mandatoryFields={addFranchiseeConfig.mandatoryFields}
						updateLocation={updateLocation}
						coords={{
							lat: fields.latitude,
							lng: fields.longitude,
						}}
						generateDefaultOption={generateDefaultOption}
						errors={errors}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};
AddCustomersCreatePage.defaultProps = {
	breadcrumbs: [],
};
const mapStateToProps = (state) => {
	const data = state.form.MY_FRANCHISEE.data.VIEW;
	const label = state.form.MY_FRANCHISEE.data.VIEW.name;
	return {
		data,
		label,
	};
};
const mapDispatchToProps = {
	fetchFranchiseeData: formActions.gateway,
	saveFranchiseData: formActions.gateway,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCustomersCreatePage);
