import Modal from 'rsuite/Modal';
import { useEffect, useRef } from 'react';
import Table from 'rsuite/Table';
import {
	billsSummaryColumns,
	billsSummaryDescription,
} from './legentModal.config';
// import styles from '../BillsSummary.module.scss';

const { Column, HeaderCell, Cell } = Table;

const LegendModal = (props) => {
	const { open, handleClose } = props;
	const tableRef = useRef(null);
	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'auto';
			if (tableRef?.current && tableRef?.current?.body) {
				tableRef.current.body.style.width = '100%';
			}
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [open]);
	return (
		<Modal
			backdrop
			keyboard
			open={open}
			onClose={handleClose}
			overflow={false}
			enforceFocus={false}
			style={{
				width: '70vw',
				zIndex: 9999,
				transform: 'translate(30% , 0)',
				// left: '3rem',
				// background: 'red',
			}}
		>
			<Modal.Header>
				{/* <Modal.Title>Modal Title</Modal.Title> */}
			</Modal.Header>

			<Modal.Body
				style={{
					width: '100%',
				}}
			>
				<Table
					// style={{
					// width: '100%',
					// }}
					width='100%'
					height={150}
					data={billsSummaryDescription}
					rowHeight={80}
					cellBordered
					headerHeight={30}
					wordWrap='break-word'
					ref={tableRef}
				>
					{billsSummaryColumns?.map((ele) => {
						return (
							<Column
								width={ele?.colWidth}
								fixed={ele?.fixed}
								sortable={ele?.sortable}
								fullText={ele?.fullText}
							>
								<HeaderCell
									style={{
										background: ele?.bg,
									}}
									className={ele?.headerClass}
								>
									{ele?.header}
								</HeaderCell>
								<Cell
									style={{
										fontSize: '0.9rem',
										// eslint-disable-next-line prefer-template
										background: ele?.bg + '95',
									}}
									dataKey={ele?.dataKey}
									className={ele?.cellClass}
									predefinedStyle={{
										minHeight: '150px',
									}}
								/>
							</Column>
						);
					})}
				</Table>
			</Modal.Body>
		</Modal>
	);
};

export default LegendModal;
