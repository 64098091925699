// import Checkbox from 'rsuite/Checkbox';
import { connect } from 'react-redux';
import Radio from 'rsuite/Radio';
import RadioGroup from 'rsuite/RadioGroup';
import { paymentModes } from '../../../../Helpers';
import CreateFormpage from '../../../../Components/CreateFormpage/CreateFormpage';
import { modalActions } from '../../../../Actions';
import styles from './BillAlreadyPaidInputs.module.scss';

const inputs = [
	[
		{
			value: paymentModes[0],
			onChange: () => {},
			id: 'paymentMode',
			name: 'paymentMode',
			labelText: 'Payment Mode',
			inputLabel: 'Select Payment mode',
			mandatory: true,
			width: '250px',
			inputType: 'options',
			optionsList: paymentModes,
		},

		{
			value: {},
			id: 'transactionRef',
			name: 'transactionRef',
			width: '250px',
			labelText: 'Transaction Ref. Number',
			mandatory: true,
		},
	],
	{
		value: {},
		id: 'uploadedDoc',
		name: 'uploadedDoc',
		labelText: 'Transaction Ref. Doc',
		mandatory: true,
		// width: '250px',
		inputType: 'docUploader',
	},
];

const BillAlreadyPaidInputs = (props) => {
	const {
		state,
		setState,
		// checkboxStyles = {},
		// checkboxLabel = 'Already Paid?',
		toggleFileUploaderModal,
	} = props;
	let checkValue = null;
	if (state?.checkValue === true) {
		checkValue = 'PAID';
	} else if (state?.checkValue === false) {
		checkValue = 'UNPAID';
	}

	const getInputs = (list) => {
		return list?.map((ele) => {
			if (Array.isArray(ele)) {
				return getInputs(ele);
			}
			if (ele?.inputType === 'docUploader') {
				return {
					...ele,
					value: state?.uploadedDoc,
					prefix: state?.uploadedDoc?.file ? 'Update' : 'Upload',
					btnText: 'Transaction Ref. Doc',
					mandatory: state?.paymentMode !== paymentModes[1],

					uploadFileClick: () => {
						toggleFileUploaderModal(true, {
							heading: 'Upload Vendor Invoice',
							selectedFile: '',
							confirmText: 'Upload',
							handleConfirm: (uploadedFile) => {
								setState({
									uploadedDoc: uploadedFile,
								});
							},
						});
					},
				};
			}
			if (ele?.inputType === 'options' && ele?.id === 'paymentMode') {
				return {
					...ele,
					value: state?.paymentMode || ele?.value,
					onChange: (value) => {
						setState({
							paymentMode: value,
						});
					},
				};
			}
			return {
				...ele,
				mandatory: state?.paymentMode !== paymentModes[1],
				value: state?.transactionRefNumber,
				onChange: (e) => {
					setState({
						transactionRefNumber: e?.target?.value,
					});
				},
			};
		});
	};

	return (
		<div className={styles.root}>
			<div className={styles.isPaidDiv}>
				<div className={styles.label}>Payment status:*</div>
				<RadioGroup
					onChange={(value) => {
						setState({
							checkValue: value === 'PAID',
						});
					}}
					inline
					defaultValue={null}
					value={checkValue}
				>
					<Radio color='orange' value='PAID'>
						PAID
					</Radio>
					<Radio color='green' value='UNPAID'>
						UNPAID
					</Radio>
				</RadioGroup>
			</div>

			{/* <Checkbox
				style={checkboxStyles}
				checked={state?.checkValue}
				onChange={() => {
					setState({
						checkValue: !state?.checkValue,
					});
				}}
			>
				{checkboxLabel}
			</Checkbox> */}
			{state?.checkValue && (
				<CreateFormpage inputsList={getInputs(inputs)} />
			)}
		</div>
	);
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BillAlreadyPaidInputs);
