import { paymentSettlementConstants } from '../Constants';
import { notificationServices, paymentSettlementsServices } from '../Services';

const paymentSettlementActions = {
	gateway,
	setDate,
};

function gateway({ entity, req }) {
	return (dispatch) => {
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'Loading...',
		});
		if (entity === 'PAYMENT_SETTLEMENTS' || entity === 'TRANSACTION') {
			dispatch(request(req));
		} else {
			dispatch(orderInvoiceRequest(req));
		}
		paymentSettlementsServices
			.gateway({
				entity,
				req,
			})
			.then(
				(res) => {
					const { message } = res;
					notificationServices.generateNotification({
						type: 'success',
						message,
						config: {
							id: notificationId,
						},
					});
					if (entity === 'PAYMENT_SETTLEMENTS' || entity === 'TRANSACTION') {
						dispatch(
							success({
								[entity]: res,
							})
						);
					} else {
						// fetch('https://resoee.s3.amazonaws.com/Invoice/139.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220117T094239Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=8de8d4a1c739cff9c4f2f4bf824233ac43592ddfe759c6ba20821aed4fc145a7');
						dispatch(orderInvoiceSuccess({
							res,
						}));
					}
				},
				(error) => {
					notificationServices.generateNotification({
						type: 'error',
						message: error,
						config: {
							id: notificationId,
						},
					});
					if (entity === 'PAYMENT_SETTLEMENTS' || entity === 'TRANSACTION') {
						dispatch(failure(error));
					} else {
						// fetch('https://resoee.s3.amazonaws.com/Invoice/139.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220117T094239Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=8de8d4a1c739cff9c4f2f4bf824233ac43592ddfe759c6ba20821aed4fc145a7');
						dispatch(orderInvoiceFailure(error));
					}
				}
			);
	};
}
function request(payload) {
	return {
		type: paymentSettlementConstants.FETCH_PAYMENT_REQUEST,
		payload,
	};
}
function success(payload) {
	return {
		type: paymentSettlementConstants.FETCH_PAYMENT_SUCCESS,
		payload,
	};
}
function failure(payload) {
	return {
		type: paymentSettlementConstants.FETCH_PAYMENT_FAILURE,
		payload,
	};
}
function orderInvoiceRequest(payload) {
	return {
		type: paymentSettlementConstants.FETCH_ORDER_INVOICE_LINK_REQUEST,
		payload,
	};
}
function orderInvoiceSuccess(payload) {
	return {
		type: paymentSettlementConstants.FETCH_ORDER_INVOICE_LINK_SUCCESS,
		payload,
	};
}
function orderInvoiceFailure(payload) {
	return {
		type: paymentSettlementConstants.FETCH_ORDER_INVOICE_LINK_FAILURE,
		payload,
	};
}
function setDate(payload) {
	return {
		type: paymentSettlementConstants.SET_DATE,
		payload,
	};
}
export default paymentSettlementActions;
