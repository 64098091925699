import {
	// Add,
	ArrowCircleRight
} from '@mui/icons-material';
import {
	// Checkbox,
	// InputLabel,
	// ListItemIcon,
	// ListItemText,
	MenuItem,
	// Select,
	TextField,
	IconButton,
} from '@mui/material';
import { isValid } from 'date-fns';
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { analyticsAction } from '../../../Actions';
import { inputHelper, classes } from '../../../Helpers';
import { notificationServices } from '../../../Services';

import { UYDateRange } from '../Payment Settlements/Calender/UYDateRange';
// eslint-disable-next-line object-curly-newline
import styles from './AiHistoryFinder.module.scss';

const modelTypes = [
	{
		type: 'FACE',
		label: 'FACE',
	},
	{
		type: 'FOOD-FEATURE',
		label: 'FOOD FEATURE',
	},
	{
		type: 'FOOD-IDENTIFICATION',
		label: 'FOOD IDENTIFICATION',
	},
];

const AiHistoryFinder = (props) => {
	const {
		fetchHistoryData,
		// stores
	} = props;
	const [model, setModel] = useState('');
	const [selectedStores, setSelectedStores] = useState([]);
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
		active: false,
	});

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		if (from != null && to != null && isValid(from) && isValid(to)) {
			setDateRange({
				from,
				to,
				active: true,
			});
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Date',
			});
		}
	};

	const handleSelection = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		if (fieldName === 'model') {
			setModel(fieldValue);
			// fetchHistoryData([from, to], {
			// [fieldName]: fieldValue,
			// store: selectedStores,
			// });
		}
		if (fieldName === 'store') {
			setSelectedStores(fieldValue);
			// fetchHistoryData([from, to], {
			// [fieldName]: fieldValue,
			// model,
			// });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		fetchHistoryData([dateRange?.from, dateRange?.to], {
			model,
			store: selectedStores,
		});
	};

	return (
		<form className={styles.root} onSubmit={handleSubmit}>
			<div className={styles['header-container']}>
				<div className={styles['header-selector-wrapper']}>
					<p className={styles.header}>AI Model History Filters</p>
				</div>
				<div className={classes(styles['range-container'])}>
					<UYDateRange
						// disabled={!dateRange.active}
						dateRanges={dateRangeFilterArray}
						onDateRangeChange={setDate}
					/>
				</div>
			</div>
			<div className={styles.form}>
				<div className={styles.filters}>
					<div className={classes(styles.filter, styles.brand)}>
						<div className={styles['filter-body']}>
							<TextField
								id='storeId'
								value={model}
								select
								label='Select Model type'
								onChange={handleSelection}
								name='model'
								className={styles.select}
							>
								{modelTypes?.map((type) => {
									return (
										<MenuItem value={type.type}>
											{type.label}
										</MenuItem>
									);
								})}
							</TextField>
						</div>
					</div>
					{/* <div className={styles.seperator}>
						<Add />
					</div> */}
					{/* <div className={classes(styles.filter, styles.defect)}>
						<div className={styles['filter-body']}>
							<div className={styles.tags}>
								<InputLabel id='mutiple-select-label'>
									Select Stores
								</InputLabel>
								<Select
									labelId='mutiple-select-label'
									multiple
									name='store'
									value={selectedStores}
									onChange={handleSelection}
									className={styles.select}
									renderValue={(selected) => {
										// eslint-disable-next-line consistent-return
										const schoolName = stores
											?.filter((ele) => {
												return selected?.includes(
													ele?.id
												);
											})
											.map((store) => {
												return store?.name;
											});

										return schoolName.join(', ');
									}}
								>
									{stores?.map((store) => {
										return (
											<MenuItem value={store?.id}>
												<ListItemIcon>
													<Checkbox
														checked={
															selectedStores?.indexOf(
																store?.id
															) > -1
														}
													/>
												</ListItemIcon>
												<ListItemText
													primary={store?.name}
												/>
											</MenuItem>
										);
									})}
								</Select>
							</div>
						</div>
					</div> */}
				</div>
				<div className={styles.submit}>
					<span>Find</span>
					<IconButton
						type='submit'
						className={styles.btn}
					>
						<ArrowCircleRight />
					</IconButton>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	// const stores = state?.form?.STORES?.data?.LIST;

	return {
		dateRangeFilter,
		// stores,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(AiHistoryFinder);
