/* eslint-disable max-len */
/* eslint-disable */
import { useEffect, useState } from 'react';
// import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import styles from './VendorOpening.module.scss';
import { ListTable } from '../../Components/ListTable';
// import allVendorServices from '../../Services/Form Services/allvendor.services';
import { BackdropLoader } from '../../Components/Loaders';
import { urls } from '../../Constants';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { addCommas, classes } from '../../Helpers';
import { modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Vendor Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Opening Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Opening Amount',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [];

const COLMAP = {
	'Vendor Name': 'vendorName',
	'Bill Type': 'subType',
	Status: 'transactionStatus',
	'Opening Date': 'createdAt',
	'Opening Amount': 'finalTransactionAmount',
};

const FIND_FILTERS = ['vendor'];

const VendorOpening = (props) => {
	const { toggleAlert, navState, fetchUserList, userList } = props;
	const history = useHistory();
	const [vendorOpenings, setVendorOpenings] = useState([]);
	const [loading, setLoading] = useState(false);
	const [update, setUpdate] = useState(0);
	const { userConfig } = useAuth();

	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/finance/management']?.submenu['/vendor/opening']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Finance');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user access

	const handleCancelBill = async (item) => {
		setLoading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: item?.id,
					},
					data: {
						status: 'CANCELLED',
						type: 'BILL',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const SPECIAL_ITEMS = {
		'Bill Type': (type) => {
			return (
				<div className={classes(styles.type, styles?.[type])}>
					{type}
				</div>
			);
		},
		'Opening Date': (openingDate) => {
			return moment(openingDate)?.format('YYYY-MM-DD');
		},
		'Opening Amount': (openingAmount) => {
			return `\u20B9${addCommas(openingAmount)}`;
		},
		Actions: (id) => {
			const item = vendorOpenings?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div>
					{item?.transactionStatus !== 'CANCELLED' && (
						<Button
							onClick={() => {
								toggleAlert(true, {
									heading: 'Cancel Vendor Opening?',
									handleConfirm: () => {
										handleCancelBill(item);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Cancel Vendor Opening: ${
										item?.clientTransactionId || ''
									} of Vendor: ${item?.vendorName}?`,
									width: '28rem',
								});
							}}
							variant='outlined'
							color='error'
							fullWidth
						>
							Cancel
						</Button>
					)}
				</div>
			);
		},
	};

	const handleListFetch = async (requestOptions) => {
		setLoading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								vendors: requestOptions?.vendors,
								isOpeningBalance: true,
							},
							type: 'BILL',
						},
					},
				});
			if (responseData?.statusCode === 200) {
				setVendorOpenings(responseData?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const handleAddClick = () => {
		history.push(`${urls.EXTENSION}${urls.VENDOR_OPENING}/add`);
	};

	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={loading} />
			<div className={styles.filter}>
				<ImagesFinder
					pageLabel='Vendor Opening'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					update={update}
					dateRangeOnlyText='Opening Date Range'
					additionInfo={{
						showAdd: true,
						addText: 'Vendor Opening',
						handleAddClick,
					}}
				/>
			</div>
			<div className={styles.listing}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={vendorOpenings?.map((ele) => {
						return {
							...ele,
							rowStyles: classes(styles.row, styles?.[ele?.transactionStatus]),
						};
					})}
					showTotal
					// data={dummyData}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state?.auth;
	return {
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	toggleAlert: modalActions.toggleAlert,
	fetchUserList: authActions.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorOpening);
