import { annotationConstants } from '../Constants';

const initialState = {
	products: {},
	qcAnnotations: {},
	imsAnnotations: {},
	containers: [],
};

function annotation(state = initialState, action) {
	switch (action.type) {
		case annotationConstants.FETCH_ANNOTATION_PRODUCTS_SUCCESS: {
			return {
				...state,
				products: action.payload,
			};
		}
		case annotationConstants.FETCH_ANNOTATION_CONTAINERS_SUCCESS: {
			return {
				...state,
				containers: action.payload,
			};
		}
		case annotationConstants.FETCH_QMS_ANNOTATION_SUCCESS: {
			return {
				...state,
				qcAnnotations: action.payload,
			};
		}
		case annotationConstants.FETCH_TEMP_ANNOTATION_SUCCESS: {
			return {
				...state,
				tempAnnotations: action.payload,
			};
		}
		case annotationConstants.FETCH_IMS_ANNOTATION_SUCCESS: {
			return {
				...state,
				imsAnnotations: action.payload,
			};
		}
		default:
			return state;
	}
}

export default annotation;
