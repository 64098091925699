/* eslint-disable prefer-template */
// import { Button } from '@mui/material';
/* eslint-disable object-curly-newline */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Ticks } from 'chart.js';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import moment from 'moment';
import styles from './COGSAnalysisGraph.module.scss';
import TimeFilter from '../../../TimeFilter/TimeFilter';
import {
	classes,
	convertTo24HourFormatTo12Hour,
	getDayName,
	groupTypeFilterList,
	useTimeFilter,
} from '../../../../Helpers';
import { BarChart } from '../../Charts';
import { COGS_CHART_COLORS } from '../../../../Styles/colors';
import { urls } from '../../../../Constants';
import { timeFilterMap } from '../../../../Constants/analytics.constansts';
import analyticsAction from '../../../../Actions/analytics.actions';
import analyticsTypeConstant from '../../../../Constants/analyticsType.contants';
import FilterModal from '../../../Filter Modal/FilterModal';
import { useAuth } from '../../../Auth/Login/auth';
import { Button } from '@/Components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/Components/ui/tabs';
import { UYDateRange } from '@/Components/Forms/Payment Settlements/Calender/UYDateRange';

/**
 * ! don't forget to change the index in handleBarChartClick, when changing this array
 */

const DISCRETE_TIME_FILTERS = [
	{
		text: 'YTD',
		type: 'year',
		active: true,
	},
	{
		text: 'MTD',
		type: 'month',
		active: false,
	},
	{
		text: 'DTD',
		type: 'day',
		active: false,
	},
];

const FILTERS = [
	'GEO_FILTER',
	'DATE_FILTER',
	// 'BRAND_FILTER',
	// 'FAILURE_ANALYSIS_FILTER',
	// 'STATISTICAL_FILTER',
	'STORE_FILTER',
];

const COGSAnalysisGraph = (props) => {
	const {
		timeFilter,
		qualityData,
		listURL,
		setAnalyticsType,
		applyFilters,
		isClear,
		GROUP_TYPE_FILTER,
		brandsList,
		resetInventoryAnalyticsState,
		dateRangeFilter,
		setDateRangeFilter,
	} = props;

	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const [pageLimit, setPageLimit] = useState({
		number: 1,
		limit: 31,
	});
	// eslint-disable-next-line no-unused-vars
	const [graphYear, setGraphYear] = useState(new Date().getFullYear());

	useEffect(() => {
		setAnalyticsType(
			analyticsTypeConstant.COGS_ANALYTICS_CHART_DATA,
			{},
			false
		);
		setDateRangeFilter({
			...dateRangeFilter,
			from: new Date(moment().startOf('month').format('YYYY-MM-DD')),
			to: new Date(moment().format('YYYY-MM-DD')),
			active: false,
		});
	}, []);

	useEffect(() => {
		const brand = {
			brand: {
				value: brandsList?.[0]?.id,
				label: brandsList?.[0]?.name,
			},
		};
		applyFilters(
			{
				GROUP_TYPE_FILTER: {
					...GROUP_TYPE_FILTER,
					active: true,
					type: 'periodWise',
					selection: {
						...brand,
					},
				},
			},
			false
		);
	}, [brandsList]);

	const history = useHistory();

	const { setTimeFilter, timeFilters } = useTimeFilter({
		DISCRETE_TIME_FILTERS,
		applyFilters,
	});

	const resetPageNumber = () => {
		setPageLimit((prev) => {
			return {
				...prev,
				number: 1,
			};
		});
	};

	const barChartData = useMemo(() => {
		const {
			labels = [],
			dataset = [],
			labelIds = [],
		} = qualityData?.chartData ?? {
			labels: [],
			dataset: [],
			labelIds: [],
		};

		// Calculate the start and end indices for the current page
		const startIdx = pageLimit?.limit * (pageLimit?.number - 1);
		const endIdx = pageLimit?.limit * pageLimit?.number;

		// Filter labels and labelIds based on the calculated indices
		const filteredLabels = labels.slice(startIdx, endIdx);
		const filteredLabelIds = labelIds.slice(startIdx, endIdx);

		// Filter and map the dataset
		const filteredDatasets = dataset.map((ds, idx) => {
			const dsData = ds?.data?.slice(startIdx, endIdx);
			return {
				...COGS_CHART_COLORS[idx % COGS_CHART_COLORS.length],
				...ds,
				yAxisID: ds?.yAxisID ?? 'y',
				data: dsData,
			};
		});

		return {
			labels: filteredLabels,
			labelIds: filteredLabelIds,
			datasets: filteredDatasets,
		};
	}, [qualityData?.chartData, GROUP_TYPE_FILTER, pageLimit]);

	const handleBarChartClick = useCallback(
		(elem) => {
			if (elem.length > 0) {
				const datasetIndex = elem[0].index;
				const selection = barChartData.labelIds?.[datasetIndex];
				const selectionText = barChartData.labels?.[datasetIndex];
				if (GROUP_TYPE_FILTER?.type === 'periodWise') {
					switch (timeFilter.type) {
						case 'year':
							setTimeFilter('month', {
								year: timeFilter.selection.year,
								month: timeFilterMap.month[selection - 1],
								day: null,
								hour: null,
								week: null,
							});
							break;
						case 'month':
							setTimeFilter('day', {
								year: timeFilter.selection.year,
								month: timeFilter.selection.month,
								day: selection,
								hour: null,
								week: null,
							});
							break;
						case 'hour':
							setTimeFilter('hour', {
								year: timeFilter.selection.year,
								month: timeFilter.selection.month,
								day: timeFilter.selection.day,
								hour: selection,
								week: timeFilter.selection.week,
							});
							history.push(
								listURL ??
									`${urls.EXTENSION}${urls.COGS_ANALYSIS_LIST}`
							);
							break;
						case 'week':
						case 'day':
							setTimeFilter('hour', {
								year: timeFilter.selection.year,
								month: timeFilter.selection.month,
								week: null,
								day: timeFilter.selection.day,
								hour: selection,
							});
							history.push(
								listURL ??
									`${urls.EXTENSION}${urls.COGS_ANALYSIS_LIST}`
							);
							break;
						default:
							break;
					}
				}
				if (GROUP_TYPE_FILTER?.type === 'productGroupWise') {
					switch (GROUP_TYPE_FILTER?.currentSelection) {
						case 'brand':
							applyFilters({
								GROUP_TYPE_FILTER: {
									...GROUP_TYPE_FILTER,
									active: true,
									type: GROUP_TYPE_FILTER?.type,
									currentSelection: 'category',
									selection: {
										...GROUP_TYPE_FILTER?.selection,
										category: {
											value: barChartData.labelIds?.[
												datasetIndex
											],
											label: selectionText,
										},
									},
								},
							});
							resetPageNumber();
							break;
						case 'category':
							applyFilters({
								GROUP_TYPE_FILTER: {
									...GROUP_TYPE_FILTER,
									active: true,
									type: GROUP_TYPE_FILTER?.type,
									currentSelection: 'subCategory',
									selection: {
										...GROUP_TYPE_FILTER?.selection,
										subCategory: {
											value: barChartData.labelIds?.[
												datasetIndex
											],
											label: selectionText,
										},
									},
								},
							});
							resetPageNumber();
							break;

						case 'subCategory':
							history.push(
								listURL ??
									`${urls.EXTENSION}${urls.COGS_ANALYSIS_LIST}`
							);
							resetPageNumber();
							break;
						default:
							break;
					}
				}
				if (GROUP_TYPE_FILTER?.type === 'geographyWise') {
					switch (GROUP_TYPE_FILTER?.currentSelection) {
						case 'brand':
							applyFilters({
								GROUP_TYPE_FILTER: {
									...GROUP_TYPE_FILTER,
									active: true,
									type: GROUP_TYPE_FILTER?.type,
									currentSelection: 'region',
									selection: {
										...GROUP_TYPE_FILTER?.selection,
										region: {
											value: barChartData.labelIds?.[
												datasetIndex
											],
											label: selectionText,
										},
									},
								},
							});
							resetPageNumber();
							break;
						case 'region':
							applyFilters({
								GROUP_TYPE_FILTER: {
									...GROUP_TYPE_FILTER,
									active: true,
									type: GROUP_TYPE_FILTER?.type,
									currentSelection: 'city',
									selection: {
										...GROUP_TYPE_FILTER?.selection,
										city: {
											value: barChartData.labelIds?.[
												datasetIndex
											],
											label: selectionText,
										},
									},
								},
							});
							resetPageNumber();
							break;

						case 'city':
							history.push(
								listURL ??
									`${urls.EXTENSION}${urls.COGS_ANALYSIS_LIST}`
							);
							resetPageNumber();
							break;
						default:
							break;
					}
				}
			}
		},
		[timeFilter, setTimeFilter]
	);

	const formatTicks = (chartThis, value, index, ticks) => {
		const newValue = chartThis.getLabelForValue(value);
		if (GROUP_TYPE_FILTER?.type === 'periodWise') {
			if (timeFilters[0].active) {
				return timeFilterMap.month[value];
			}
			if (timeFilters[1].active) {
				const date = [
					timeFilters[0].text,
					timeFilterMap.monthNumber[timeFilters[1].text],
					newValue,
				];
				return `${newValue}-${getDayName(
					new Date(...date).getDay()
				)?.substring(0, 3)}`;
			}
			if (timeFilters[2].active) {
				return convertTo24HourFormatTo12Hour(value);
			}
		}
		return Ticks.formatters.values.apply(chartThis, [
			newValue,
			index,
			ticks,
		]);
	};

	const handleFilterType = (value) => {
		const typeFilterData = groupTypeFilterList?.find((ele) => {
			return ele?.value === value;
		});
		const brand = {
			brand: {
				value: brandsList?.[0]?.id,
				label: brandsList?.[0]?.name,
			},
		};
		if (typeFilterData.value === 'periodWise') {
			setDateRangeFilter({
				...dateRangeFilter,
				active: false,
			});
		} else {
			setDateRangeFilter({
				...dateRangeFilter,
				active: true,
			});
		}
		applyFilters({
			GROUP_TYPE_FILTER: {
				...GROUP_TYPE_FILTER,
				active: true,
				type: typeFilterData?.value,
				currentSelection: typeFilterData?.currentSelection,
				selection: {
					...brand,
					...typeFilterData?.selection,
				},
			},
		});
		resetInventoryAnalyticsState({
			entity: 'COGS_ANALYTICS',
			method: 'CHART_DATA',
			data: {},
		});
		resetPageNumber();
	};

	const handleTypeFilter = (type) => {
		applyFilters({
			GROUP_TYPE_FILTER: {
				...GROUP_TYPE_FILTER,
				currentSelection: type,
			},
		});
		resetPageNumber();
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		applyFilters({});
	};

	return (
		<div className={classes(styles.root)}>
			<FilterModal type='cogs' filters={FILTERS} clear={isClear} />
			<div className='flex items-center gap-2'>
				<div className='flex flex-col gap-1'>
					<Tabs
						value={GROUP_TYPE_FILTER?.type}
						onValueChange={handleFilterType}
						defaultValue={GROUP_TYPE_FILTER?.type}
					>
						<TabsList className=''>
							{groupTypeFilterList?.map((ele) => {
								return (
									<TabsTrigger
										className='data-[state=active]:bg-uy-color'
										value={ele?.value}
									>
										{ele?.label}
									</TabsTrigger>
								);
							})}
						</TabsList>
					</Tabs>
					{GROUP_TYPE_FILTER?.type !== 'periodWise' && (
						<UYDateRange
							disabled={!dateRangeFilter.active}
							dateRanges={dateRangeFilterArray}
							onDateRangeChange={setDate}
							enableOnlyBackwardSelection={false}
							menuStyles={{
								zIndex: 999,
							}}
							placement='bottomStart'
						/>
					)}
				</div>
				<Button
					className={classes(styles['list-button'])}
					variant='contained'
					color='primary'
					onClick={() => {
						history.push(
							listURL ??
								`${urls.EXTENSION}${urls.COGS_ANALYSIS_LIST}`
						);
					}}
				>
					View List
				</Button>
			</div>
			<div className='relative w-full'>
				{qualityData?.chartData?.labels?.length > pageLimit?.limit && (
					<div className='absolute flex items-center gap-4 -top-8 left-[50%] translate-x-[-50%]'>
						<Button
							onClick={() => {
								if (pageLimit?.number > 1) {
									setPageLimit((prev) => {
										return {
											...prev,
											number: prev?.number - 1,
										};
									});
								}
							}}
							variant='outline'
							size='icon'
							disabled={pageLimit?.number === 1}
						>
							<ChevronLeft className='h-4 w-4' />
						</Button>
						<Button
							onClick={() => {
								const maxPageNumber = Math.ceil(
									qualityData?.chartData?.labels?.length /
										pageLimit?.limit
								);
								if (pageLimit?.number < maxPageNumber) {
									setPageLimit((prev) => {
										return {
											...prev,
											number: prev?.number + 1,
										};
									});
								}
							}}
							variant='outline'
							size='icon'
							disabled={
								pageLimit?.number ===
								Math.ceil(
									qualityData?.chartData?.labels?.length /
										pageLimit?.limit
								)
							}
						>
							<ChevronRight className='h-4 w-4' />
						</Button>
					</div>
				)}
				<BarChart
					data={barChartData}
					onClick={handleBarChartClick}
					className={classes(styles.chart)}
					maintainAspectRatio={false}
					title='COGS'
					xAxisCallback={formatTicks}
					stackX
					stackY
					leftLabelName={
						'Cost ' + (measurement === 'US' ? '($)' : '(₹)')
					}
					rightLabelName='Quantity'
				/>
			</div>
			<div className={classes(styles.filters)}>
				{GROUP_TYPE_FILTER?.type === 'periodWise' ? (
					<TimeFilter
						timeFilters={timeFilters}
						onClick={setTimeFilter}
						graphYear={graphYear}
					/>
				) : (
					<TimeFilter
						timeFilters={Object.keys(
							GROUP_TYPE_FILTER?.selection ?? {}
						)?.map((eleKey) => {
							const eleValue =
								GROUP_TYPE_FILTER?.selection?.[eleKey];
							return {
								type: eleKey,
								text: eleValue?.label,
								active:
									eleKey ===
									GROUP_TYPE_FILTER?.currentSelection,
							};
						})}
						onClick={handleTypeFilter}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		DATE_FILTER,
		loaders,
		COGS_ANALYTICS,
		GROUP_TYPE_FILTER,
		DATE_RANGE_FILTER: dateRangeFilter,
	} = state.analytics;
	const { LIST: brandsList } = state?.form?.BRANDS?.data ?? {};

	return {
		timeFilter: DATE_FILTER,
		loaders,
		qualityData: COGS_ANALYTICS.CHART_DATA,
		brandsList,
		GROUP_TYPE_FILTER,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	applyFilters: analyticsAction.applyFilters,
	setAnalyticsType: analyticsAction.setAnalyticsType,
	setTypeFilter: analyticsAction.setTypeFilter,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	resetInventoryAnalyticsState: analyticsAction.resetInventoryAnalyticsState,
};

export default connect(mapStateToProps, mapDispatchToProps)(COGSAnalysisGraph);
