// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { CheckList } from '../../CheckList';
import { useSearch } from '../../../Helpers';
import { notificationServices } from '../../../Services';

const INITIAL_STATE = {
	selectedItems: [],
};

const ListModal = (props) => {
	const {
		show,
		data,
		handleConfirm,
		toggleModal,
		selectionBehaviour,
		singleSelectable,
		maxSelectLimit,
		modalCallee,
	} = props;
	const { list = [], selected = [], heading = 'Select Items' } = data;

	const { query, handleSearch, clearSearch } = useSearch();

	const [state, setState] = useState({
		...INITIAL_STATE,
	});

	const { selectedItems } = state;

	useEffect(() => {
		const sideEffectState = {};
		if (selectionBehaviour === 'SHOW') {
			sideEffectState.selectedItems = [...selected];
		}
		setState({
			...INITIAL_STATE,
			...sideEffectState,
		});
	}, [show]);

	const handleClose = () => {
		clearSearch();
		toggleModal(false);
	};

	const selectItem = (id, isSelected) => {
		const newState = {
			selectedItems: [],
		};
		// already selected, then remove
		if (isSelected) {
			newState.selectedItems = selectedItems.filter((item) => {
				return item !== id;
			});
		} else {
			// eslint-disable-next-line no-lonely-if
			if (singleSelectable) {
				newState.selectedItems = [id];
			} else {
				newState.selectedItems = [...selectedItems, id];
			}
		}

		if (maxSelectLimit !== undefined) {
			if (newState?.selectedItems?.length <= maxSelectLimit) {
				setState({
					...state,
					...newState,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Selection Limit Exceeded',
				});
			}
		} else {
			setState({
				...state,
				...newState,
			});
		}
	};

	const confirmSelection = () => {
		handleConfirm(selectedItems, data);
		handleClose();
	};

	const memoizedList = useMemo(() => {
		return list.filter((item) => {
			const { id, title } = item;
			return (
				title?.toLowerCase()?.indexOf(query?.trim()?.toLowerCase()) !==
					-1 &&
				((selectionBehaviour === 'HIDE' &&
					selected?.indexOf(id) === -1) ||
					selectionBehaviour === 'SHOW')
			);
		});
	}, [list, selected, query, selectionBehaviour]);

	return (
		<BaseModal
			open={modalCallee ? (modalCallee === data?.callee && show) : show}
			heading={heading}
			handleClose={handleClose}
			handleConfirm={confirmSelection}
			// width='12rem'
			// height='12rem'
		>
			<CheckList
				showSearch
				list={memoizedList}
				selectedItems={selectedItems}
				handleItemSelect={selectItem}
				handleSearchInput={handleSearch}
			/>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.listModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
};

ListModal.defaultProps = {
	selectionBehaviour: 'HIDE',
};

export default connect(mapStateToProps, mapDispatchToProps)(ListModal);
