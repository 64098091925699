import { geoDataConstants } from '../Constants';

const initialState = {
	GEO_DATA: {
		data: {},
	},
};
function getGeoData(state = initialState, action) {
	switch (action.type) {
		case geoDataConstants.FETCH_GEODATA_REQUEST:
			return {
				...state,
			};
		case geoDataConstants.FETCH_GEODATA_SUCCESS:
			return {
				...state,
				GEO_DATA: action.payload,
			};
		case geoDataConstants.FETCH_GEODATA_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
}
export default getGeoData;
