/* eslint-disable prefer-template */
import { connect } from 'react-redux';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { OpenInNew } from '@mui/icons-material';
import drawerActions from '../../../Actions/drawer.actions';
import BaseDrawer from '../BaseDrawer/BaseDrawer';
import { ListTable } from '../../ListTable';
import styles from './TransactionDrawer.module.scss';
import config from './transactionTable.data';
import { notificationServices } from '../../../Services';
import { modalActions } from '../../../Actions';
import { addCommas, classes } from '../../../Helpers';
import { urls } from '../../../Constants';

const TransactionDrawer = (props) => {
	const { show, data, toggleDrawer, togglePOItemsModal, vendors } = props;
	const tableData = config.TABLE_DATA(data?.transactionType);
	const onClose = () => {
		toggleDrawer(false, {});
	};

	const SPECIAL_ITEMS = {
		'Bill Amount': (amount) => {
			return `₹${addCommas(amount)}`;
		},
		'Paid Amount': (amount) => {
			return `₹${addCommas(amount)}`;
		},
		'Bill Type': (id) => {
			const rowItem = data?.listData?.find((ele) => {
				return ele?.id === id;
			});
			const type = rowItem?.subType;
			return (
				<div className={classes(styles.type, styles?.[type])}>
					{type}
				</div>
			);
		},
		Status: (transactionId) => {
			const item = data?.listData?.find((ele) => {
				return ele?.id === transactionId;
			});
			if (item?.transactionStatus === 'REJECTED') {
				return (
					<div>
						<p>
							{item?.transactionStatus}
							{item?.description
								? ` (Reason: ${item?.description})`
								: ''}
						</p>
						<p className={styles.reupload}>Re-Upload Invoice</p>
					</div>
				);
			}
			return item?.transactionStatus?.split('_')?.join(' ');
		},
		'View Bills Items': (id) => {
			const item = data?.listData?.find((ele) => {
				return ele?.id === id;
			});
			const vendorDetails = vendors?.find((ele) => {
				return ele?.id === item?.vendorId;
			});
			const isAdvanceBill = !item?.parentRowId;
			return (
				<div
					className={styles.icon}
					onClick={() => {
						if (isAdvanceBill) {
							notificationServices?.generateNotification({
								type: 'error',
								message: 'Advance bill have no item',
							});
							return;
						}
						togglePOItemsModal(true, {
							heading: `Bills Items of #${
								item?.clientTransactionId ?? item?.id
							}`,
							...item,
							packagingCharges: item?.extraData?.packagingCharges,
							deliveryCharges: item?.extraData?.deliveryCharges,
							packagingTaxPercentage:
								item?.extraData?.taxOnPackagingCharges,
							deliveryTaxPercentage:
								item?.extraData?.taxOnDeliveryCharges,

							showPdf: false,
							showTotal: true,
							showPoDetails: true,
							width: '60rem',
							height: '40rem',
							transactionData: {
								'Bill ID': item?.clientTransactionId,
								'PO ID': item?.clientOrderId,
								Vendor: item?.vendorName,
								'Deliver To Store': item?.storeName,
								'Vendor GSTIN': vendorDetails?.gstin ?? '',
								'Deliver To Store GSTIN': item?.storeGstin,
								'Vendor Address': `${
									vendorDetails?.shippingAddressCity ?? ''
								} ${
									vendorDetails?.shippingAddressState ?? ''
								}, ${
									vendorDetails?.shippingAddressPinCode ?? ''
								}`,
								...(item?.parentRowId && {
									'GRN ID': item?.parentRowId,
								}),
								'Client Bill ID': item?.clientTransactionId,
								'GRN Date': moment(
									item?.parentTransactionRecord
										?.transactionDate
								).format('YYYY-MM-DD'),
								'Bill Date': moment(
									item?.transactionDate
								).format('YYYY-MM-DD'),
								'Bill Status': item?.transactionStatus,
								'Bill Amount':
									'\u20B9' + item?.finalTransactionAmount,
								'Payment Term': item?.extraData?.paymentTerm,
								'Order Amount': '\u20B9' + item?.orderAmount,
							},
							excludedColumns: [
								'Packaged',
								'Dispatched',
								'Received',
								'Returned Quantity',
								'Fulfilled',
								'From Process',
								'PO Id',
								'Returned',
								'Quantity',
								'Used For',
							],
						});
					}}
				>
					<VisibilityIcon fontSize='10px' />
				</div>
			);
		},
		'View Payments': (id) => {
			const item = data?.listData?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div
					className={styles.icon}
					onClick={() => {
						window.open(
							`${urls.EXTENSION}${urls.PAYMENT_MADE}?billId=${item?.id}&clientTransactionId=${item?.clientTransactionId}`
						);
					}}
				>
					<OpenInNew fontSize='10px' />
				</div>
			);
		},
	};

	return (
		<BaseDrawer header={data?.heading} open={show} onClose={onClose}>
			<div className={styles.root}>
				<ListTable
					allowExport={false}
					allowCheckList={false}
					showBtnContainer={false}
					colMap={tableData?.COLUMN_MAP}
					columns={tableData?.TABLE_COLUMNS}
					data={data?.listData}
					actions={tableData?.SPECIAL_COLUMNS}
					special={{
						...SPECIAL_ITEMS,
					}}
					fixedHeaderTop={0}
				/>
			</div>
		</BaseDrawer>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.transactionDrawer;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	return {
		show,
		data,
		vendors,
	};
};

const mapDispatchToProps = {
	toggleDrawer: drawerActions.toggleTransactionDrawer,
	togglePOItemsModal: modalActions.togglePOItemsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDrawer);
