/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import { Badge, Button } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Add, Tune } from '@mui/icons-material';
import { useLocation } from 'react-router';
import moment from 'moment';
import analyticsAction from '../../../Actions/analytics.actions';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import {
	classes,
	store,
	usePagination,
	useQuery,
	useSearch,
} from '../../../Helpers';
import styles from '../../BOMDashboard/BOMHistory.module.scss';
import { formActions, modalActions } from '../../../Actions';
import FilterModal from '../../../Components/Filter Modal/FilterModal';
import { BackdropLoader } from '../../../Components/Loaders';
import aiAnalyticsActions from '../../../Actions/aiAnalytics.actions';
import BOMAnalysisListPage from '../../BOMDashboard/BOMAnalysisListPage';
import DatePickerCustom from '@/Components/DatePickerCustom/DatePickerCustom';
import { Tag } from '@/Components/Tags';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';
import DayEndReportListTable from './DayEndReportListTable';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';

// import {
// 	Tabs, TabsList, TabsTrigger
// } from '@/Components/ui/tabs';

const ANALYTICS_TYPE = analyticsTypeConstant.BOM_HISTORY_LIST_DATA;

const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	PRODUCT_NAME: 'productName',
	ORDER_ID: 'OrderId',
	ITEM_ID: 'itemId',
	VALIDATED: 'validation',
	IDENTIFIED: 'identification',
	FEATURES: 'annotated',
	// BRAND_NAME: 'brandName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'PRODUCT_NAME',
			title: 'Product Name',
			get: 'ProductName',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const FILTERS = ['STORE_FILTER'];
const FIND_FILTERS = ['dateOnly', 'store'];

const dummyData = [
	{
		stock: 100,
		expired: 'expired',
		nonUsable: 'non-usable',
		notRecived: 'not-recived',
		notScanned: 'notscanned',
		stockDispatched: 'Stock Dispatched Without Tracebility',
		outOfOrderDispatch: 'Out of Order Dispatch',
		missingInventory: 'Missing Inventory/Items',
		expectedStock: 'Expected Stock@Date',
	},
	{
		stock: 100,
		expired: 'expired',
		nonUsable: 'non-usable',
		notRecived: 'not-recived',
		notScanned: 'notscanned',
		stockDispatched: 'Stock Dispatched Without Tracebility',
		outOfOrderDispatch: 'Out of Order Dispatch',
		missingInventory: 'Missing Inventory/Items',
		expectedStock: 'Expected Stock@Date',
	},
	{
		stock: 100,
		expired: 'expired',
		nonUsable: 'non-usable',
		notRecived: 'not-recived',
		notScanned: 'notscanned',
		stockDispatched: 'Stock Dispatched Without Tracebility',
		outOfOrderDispatch: 'Out of Order Dispatch',
		missingInventory: 'Missing Inventory/Items',
		expectedStock: 'Expected Stock@Date',
	},
];

const DayEndReport = (props) => {
	const [dayendreportdata, setDayEndReport] = useState([]);
	const [dateOnly, setDateOnly] = useState({
		active: true,
		date: (() => {
			const currentDate = new Date();
			currentDate.setDate(currentDate.getDate() - 1);
			return currentDate;
		})(),
	});
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	// const history = useHistory();
	const params = new URLSearchParams(location.search);

	const queryParams = useQuery();
	// fecthapi
	const fetchData = async (filters) => {
		setLoading(true);
		setDayEndReport(dummyData);
		try {
			const response = await inventoryAnalyticsServices.fetchDayEndReport(
				{
					data: {
						date: moment(filters?.dateOnly?.date).format(
							'YYYY-MM-DD'
						),
						storeId: filters?.store,
					},
				}
			);
			if (response?.statusCode === 200) {
				setDayEndReport(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('erorrr', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<BackdropLoader showProgress isLoading={loading} />
			<div className={styles.root}>
				{/* <ListModal
				handleConfirm={applyBrandFilter}
				selectionBehaviour='SHOW'
			/> */}
				<FilterModal
					type='quality'
					filters={FILTERS}
					clear={queryParams.has('clear')}
				/>
				{!params.has('s_no_filter') && (
					<div
						className={styles.header}
						style={{
							height: '206px',
						}}
					>
						<div className='w-[99%]'>
							<ImagesFinder
								pageLabel='Day End Report'
								FILTERS={FIND_FILTERS?.filter((ele) => {
									return ele;
								})}
								defaultState={{
									DATE_ONLY: dateOnly,
								}}
								onSubmit={fetchData}
							/>
						</div>
					</div>
				)}
				<DayEndReportListTable data={dummyData} />
			</div>
		</>
	);
};

DayEndReport.defaultProps = {};

const mapStateToProps = () => {};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setBomSearchResults: analyticsAction.setBomSearchResults,
	fetchData: analyticsAction.fetchData,
	fetchAiQcData: aiAnalyticsActions.fetchAiQcData,
	toggleModal: modalActions.toggleFilterModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
	setAnalysisDashboardFilters: analyticsAction.setAnalysisDashboardFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(DayEndReport);
