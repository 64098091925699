import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, TextField } from '@mui/material';
import styles from './AddGroupModal.module.scss';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';

const LabelledInput = (props) => {
	const {
		value, onChange, id, name, placeholder, labelText, mandatory,
	} =
		props;
	return (
		<div className={styles.tile}>
			<label className={mandatory ? styles.mandatory : ''} htmlFor={id}>
				{labelText}
			</label>
			<TextField
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				sx={{
					'.MuiInputBase-root': {
						borderRadius: '12px',
					},
					'.MuiInputBase-input': {
						paddingTop: '6px',
						paddingBottom: '6px',
					},
				}}
			/>
		</div>
	);
};

const AddNewGroupModal = ({ show, toggleModal, onSubmit, filterLabels = {} }) => {
	const [groupName, setGroupName] = useState('');

	const handleClose = () => {
		toggleModal(false);
		setGroupName('');
	};

	const handleSubmit = () => {
		if (!groupName) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter a group name.',
			});
			return;
		}
		onSubmit(groupName);
		handleClose();
	};

	return (
		<BaseModal
			open={show}
			heading={filterLabels?.addGroup ?? 'Add New Group'}
			handleClose={handleClose}
			renderBtns={() => {
				return (
					<div className={styles.btnsContainer}>
						<Button fullWidth variant='outlined' onClick={handleClose}>
							Cancel
						</Button>
						<Button
							fullWidth
							variant='contained'
							color='primary'
							onClick={handleSubmit}
						>
							Save
						</Button>
					</div>
				);
			}}
			width='30rem'
			height='25rem'
		>
			<div className={styles.root}>
				<LabelledInput
					mandatory
					id='groupName'
					name='GROUP_NAME'
					value={groupName}
					placeholder={filterLabels.enterGroupName ?? 'Enter GroupName'}
					labelText={filterLabels.groupName ?? 'Group Name*'}
					onChange={(e) => { return setGroupName(e.target.value); }}
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addGroupModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddGroupModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewGroupModal);
