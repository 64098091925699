// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import ApproveRefundRoutes from './Approve Refunds Routes/approveRefunds.routes';
import RefundHistoryRoutes from './Refund History Routes/refundHistory.routes';
import RequestRefundRoutes from './Request Refund Routes/requestRefund.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.REFUNDS;
	return entity.slice(baseEntity.length);
};

const RefundRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.APPROVE_REFUNDS)}`}>
				<ApproveRefundRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.REFUNDS_HISTORY)}`}>
				<RefundHistoryRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.REQUEST_REFUNDS)}`}>
				<RequestRefundRoutes />
			</Route>
		</Switch>
	);
};

export default RefundRoutes;
