/* eslint-disable max-len */
function StaticText(props) {
	const textBoxStyle = {
		fontSize: props.fontSize,
		height: props.textBoxHeight,
		width: props.textBoxWidth,
		overflow: 'hidden',
		backgroundColor: '#CCFFFF',
		border:
			// eslint-disable-next-line no-nested-ternary
			props?.selectedComponent === props?.id
				? '1px solid orange'
				: props?.currentComponent === props?.id
					? '1px solid red'
					: null,

		fontFamily: 'sans-serif',
		fontWeight: '550',
		resize: 'none',
		margin: 0,
	};

	return (
		<textarea
			className='static-text'
			style={textBoxStyle}
			value={props.text}
			readOnly
			rows='1'
		/>
	);
}

export default StaticText;
