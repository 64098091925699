import { notificationServices } from '../Notification';
import authActions from '../../Actions/auth.actions';
import { store } from '../../Helpers';

const handleResponse = (response) => {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (data.status === 'FAILED' || data.errorMessage) {
			let error = data.errorMessage || data.message;
			if (error === 'Auth_Invalid') {
				error = 'Your session has expired';
				store.dispatch(authActions.toggleLogout(true));
			}
			// if it's not a loading notification, display error message
			if (!store.getState().auth.isLoading) {
				store.dispatch(authActions.toggleLoading(false));
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
			}
			return Promise.reject(error);
		}
		return data;
	});
};

export default handleResponse;
