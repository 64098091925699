/* eslint-disable object-curly-newline */
import { Button, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { modalActions } from '../../../Actions';
import { urls } from '../../../Constants';
import { inputHelper, keyPressHandler } from '../../../Helpers';
import useKeyPress from '../../../Helpers/useKeyPress';
import { notificationServices } from '../../../Services';
import { PageNavigation } from '../../Headers/PageNavigation';
import { BaseModal } from '../BaseModal';
import fallbackImg from '../../../Images/tempNotFound.png';
import styles from './VanImageModal.module.scss';

const initialState = {
	id: null,
	heading: '',
	vanTemp: null,
	image: '',
};

const VanImageModal = (props) => {
	const history = useHistory();
	const {
		show,
		toggleModal,
		data,
		handleEditSubmit,
		poList,
		userPermissions,
	} = props;
	const [modalState, setModalState] = useState(initialState);
	const [tempValue, setTempValue] = useState(null);
	const { image, heading, vanTemp, id } = modalState;
	const keyPressed = useKeyPress();

	const listIndex = useMemo(() => {
		return poList?.findIndex((ele) => {
			return ele.id === parseInt(id, 10);
		});
	}, [id]);

	const handleClose = () => {
		toggleModal(false);
	};

	useEffect(() => {
		setModalState(data);
	}, [data]);

	useEffect(() => {
		setTempValue(vanTemp ?? '');
	}, [vanTemp]);

	useEffect(() => {
		const itemData = poList?.[listIndex] ?? null;
		if (
			(keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight') &&
			itemData !== null
		) {
			const newListItem = keyPressHandler(keyPressed, poList, listIndex);
			if (newListItem) {
				setModalState({
					heading: `${newListItem?.clientPoId ?? '-'} at ${
						newListItem?.scheduledAt
					}`,
					image: newListItem?.receivingTempImage,
					vanTemp: newListItem?.receivingTemp,
					id: newListItem?.id,
				});
			}
		}
	}, [keyPressed]);

	const handleAnnotate = () => {
		history.push(
			`${urls.EXTENSION}${urls.TEMP_ANNOTATION}?id=${id}&type=PO`
		);
	};

	const renderBtns = () => {
		return (
			<>
				{userPermissions?.INTERNAL && (
					<div className={styles.btns}>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={handleAnnotate}
						>
							Annotate
						</Button>
					</div>
				)}
			</>
		);
	};

	const handleChange = (e) => {
		const { fieldValue } = inputHelper(e);
		setTempValue(fieldValue);
	};

	const handleEdit = () => {
		if (vanTemp !== tempValue) {
			handleEditSubmit({
				[id]: tempValue,
			});
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Temperature Value not changed',
			});
		}
	};

	const navigateQC = useCallback(
		(e) => {
			const { dataset } = inputHelper(e);

			let newListItem = null;

			if (dataset.type === 'next') {
				newListItem = poList?.[listIndex + 1];
			} else {
				newListItem = poList?.[listIndex - 1];
			}

			setModalState({
				heading: `${newListItem?.clientPoId ?? '-'} at ${
					newListItem?.scheduledAt
				}`,
				image: newListItem?.receivingTempImage,
				vanTemp: newListItem?.receivingTemp,
				id: newListItem?.id,
			});
		},
		[listIndex, poList]
	);

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={heading}
			open={show}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='35rem'
			height='40rem'
		>
			<div className={`${styles.root}`}>
				<PageNavigation
					navigate={navigateQC}
					prev={{
						tooltip: 'Prev Order',
						show: listIndex > 0,
						disabled: listIndex === 0,
					}}
					next={{
						tooltip: 'Next Order',
						show: listIndex >= 0 && listIndex < poList.length - 1,
						disabled:
							listIndex < 0 && listIndex === poList.length - 1,
					}}
				/>
				<div className={styles['img-container']}>
					<p>Van temp Image</p>
					<img src={image ?? fallbackImg} alt='' />
				</div>
				<div className={styles['edit-temp']}>
					<TextField
						label='Change Temp'
						id='outlined-size-normal'
						value={tempValue}
						onChange={handleChange}
						// className={styles.}
					/>
					<Button variant='contained' onClick={handleEdit}>
						Submit Temp
					</Button>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.vanImageModal;
	const { PO_LIST: poList } = state?.analytics?.INVENTORY_ANALYTICS;
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
		show,
		data,
		poList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleVanImageModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VanImageModal);
