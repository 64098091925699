/* eslint-disable max-len */

import _ from 'lodash';

/* eslint-disable comma-dangle */
const analyticsGraphConfig = {
	initialState: {
		filter: {
			timeFilter: 'curr_day',
			brandFilter: [],
			geoFilter: [],
		},
		fields: {
			graphData: {
				storeRevenueData: {
					labels: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
					],
					datasets: [
						{
							label: 'Store Revenue',
							data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							fill: false,
							borderColor: 'rgba(75,192,192,1)',
						},
						{
							label: 'Store CoGS',
							data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							fill: false,
							borderColor: '#742774',
						},
					],
				},
				storeMarginData: {
					labels: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
					],
					datasets: [
						{
							label: 'Store Gross Margin',
							data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							fill: false,
							borderColor: 'rgba(75,192,192,1)',
						},
					],
				},
			},
			pieData: {
				responsive: true,
				maintainAspectRatio: true,
				labels: [],
				datasets: [
					{
						data: [],
					},
				],
			},
			popularGraphData: {
				day: {
					datasets: [
						{
							backgroundColor: 'rgba(75,192,192,1)',
							data: [0, 0, 0, 0, 0, 0, 0],
							borderWidth: 2,
							label: '',
						},
					],
					labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				},
				time: {
					datasets: [
						{
							backgroundColor: 'rgba(75,192,192,1)',
							data: [0, 0, 0, 0, 0, 0, 0],
							label: '',
						},
					],
					labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				},
			},
		},
	},
	deserializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				graphData: {
					storeRevenueData: {
						labels: data?.graphData?.labels,
						datasets: [
							{
								label: 'Store Revenue',
								data: data?.graphData?.storeRevenue,
								fill: false,
								borderColor: 'rgba(75,192,192,1)',
							},
							{
								label: 'Store CoGS',
								data: data?.graphData?.storeCogs,
								fill: false,
								borderColor: '#742774',
							},
						],
					},
					storeMarginData: {
						labels: data?.graphData?.labels,
						datasets: [
							{
								label: 'Store Gross Margin',
								data: data?.graphData?.storeMargin,
								fill: false,
								borderColor: 'rgba(75,192,192,1)',
							},
						],
					},
				},
				pieData: {
					maintainAspectRatio: true,
					responsive: true,
					labels: data?.pieData?.labels ?? [],
					datasets: [
						{
							data: data?.pieData?.data ?? [],
							backgroundColor: [
								'#666699',
								'#CC9933',
								'#006666',
								'#3399FF',
								'#993300',
								'#CCCC99',
								'#666666',
								'#FFCC66',
								'#6699CC',
							],
							hoverBackgroundColor: [
								'#666699',
								'#CC9933',
								'#006666',
								'#3399FF',
								'#993300',
								'#CCCC99',
								'#666666',
								'#FFCC66',
								'#6699CC',
							],
						},
					],
				},
				popularGraphData: {
					day: {
						labels: data?.popularGraphData?.day.labels,
						datasets: [
							{
								backgroundColor: 'rgba(75,192,192,1)',
								data: data?.popularGraphData?.day?.data,
								label: '',
							},
						],
					},
					time: {
						labels: data?.popularGraphData?.time?.labels,
						datasets: [
							{
								backgroundColor: 'rgba(75,192,192,1)',
								data: data?.popularGraphData?.time?.data,
								label: '',
							},
						],
					},
				},
			};
			return newState;
		});
	},
	serializer: (state, geoFilterData, brandFilterData = []) => {
		return _.update(_.cloneDeep(state), 'filter', () => {
			return {
				...state.filter,
				geoFilter: geoFilterData,
				brandFilter: brandFilterData
			};
		});
	},
};
export default analyticsGraphConfig;
