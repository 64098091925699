/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { SelectPicker } from 'rsuite';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import VendorQuoteHeading from './VendorQuoteHeading';
import VendorCreateTable from './VendorCreateTable';
import styles from '../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder.module.scss';
import { formActions, modalActions } from '@/Actions';
import quoteServices from '@/Services/QuoteServices/quote.services';
import assetServices from '@/Services/AssetServices/asset.services';
import { notificationServices } from '@/Services/Notification';
import { Button } from '../ui/button';
import { FileUploaderModal } from '../Modals/FileUploaderModal';

const VendorQuoteList = (props) => {
	const { vendors, storesList, fetchVendors, fetchStoresList, toggleFileUploaderModal } = props;
	const params = useParams();
	const history = useHistory();
	const [vendor, setVendor] = useState([]);
	const [store, setStore] = useState(null);
	const [rfq, setRfq] = useState([]);
	const [assets, setAssets] = useState([]);
	const [oemList, setOemList] = useState([]);
	const [row, setRow] = useState([]);
	const [maxQty, setMaxQty] = useState([]);
	const [oems, setOems] = useState([]);
	const [getRfq, setGetRfq] = useState([]);
	const [doc, setDoc] = useState(null);

	useEffect(() => {
		if (rfq.length > 0 && params?.id) {
			const selectedRfq = rfq.find((item) => String(item.id) === params?.id);
			setGetRfq(selectedRfq ? [selectedRfq] : []);
		}
		if (params?.id) {
			const storeId = rfq.find((item) => String(item.id) === params?.id);
			setStore(storeId?.store);
		}
	}, [params, rfq]);

	useEffect(() => {
		const updatedRows = getRfq.flatMap((rfqItem) => rfqItem.items.map((item) => ({
			id: item.id,
			assetType: assets.find((asset) => asset.id === item.assetType)?.name || 'N/A',
			oem: item.oems.map((oem) => ({
				label: oem.name,
				id: oem.id,
			})),
			specifications: (() => {
				const assetSpecs =
						assets.find((asset) => asset.id === item.assetType)?.coreSpecifications || [];
				const itemSpecs = item.coreSpecifications || [];

				const combinedSpecs = [
					...assetSpecs,
					...itemSpecs.filter(
						(itemSpec) => !assetSpecs.some((assetSpec) => assetSpec.id === itemSpec.coreSpecification?.id)
					),
				];

				return combinedSpecs.map((spec) => {
					const itemSpec = itemSpecs.find(
						(val) => val?.coreSpecification?.id === spec.id
					);
					return {
						...spec,
						value: itemSpec?.coreSpecification?.rfqMandatory
							? itemSpec?.value || 'No Value Found'
							: null,
					};
				});
			})(),
			qty: item.qty || 0,
		})));
		setRow(updatedRows);
	}, [rfq, assets]);

	useEffect(() => {
		const quantity = getRfq?.flatMap((item) => item?.items?.map((itemValue) => ({
			id: itemValue?.id,
			qty: itemValue?.qty || 0,
		})));

		setMaxQty(quantity);
		const oem = getRfq?.flatMap((item) => item?.items?.flatMap((itemValue) => itemValue?.oems?.map((val) => ({
			id: itemValue?.id,
			oem: [{
				id: val?.id,
				name: val?.name,
			}],
		}))));

		setOems(oem);
	}, [row]);

	useEffect(() => {
		const fetchRfqAndAssets = async () => {
			const rfqResponse = await quoteServices.fetchRfq();
			const assetsResponse = await quoteServices.requestQuoteAssetList();

			if (rfqResponse?.statusCode === 200 && assetsResponse?.statusCode === 200) {
				setRfq(rfqResponse?.data);
				setAssets(assetsResponse?.data);
			}
		};
		const fetchOemList = async () => {
			const oemResponse = await assetServices?.assetOemListService();
			if (oemResponse?.statusCode === 200) {
				setOemList(oemResponse?.data);
			}
		};

		fetchOemList();
		fetchRfqAndAssets();
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);
	const handleSave = async () => {
		let hasError = false;
		if (vendor?.length === 0) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Vendor is Required',
			});
			hasError = true;
			return;
		}
		row?.forEach((item) => {
			const mandatorySpecs = item?.specifications?.filter((spec) => spec?.rfqMandatory || spec?.supplierMandatory);
			if (mandatorySpecs?.length > 0) {
				// if (!item.specifications || item.specifications.length === 0) {
				// 	hasError = true;
				// 	notificationServices?.generateNotification({
				// 		type: 'error',
				// 		message: 'Specification is required for each item.',
				// 	});
				// 	return;
				// }

				const mandatorySpecsWithEmptyValues = mandatorySpecs.filter((spec) => {
					if (spec.rfqMandatory || spec.supplierMandatory) {
						const matchingSpec = item.specifications.find((s) => s.id === spec.id);
						if (spec?.dataType === 'BOOLEAN') {
							return false;
						}

						return !matchingSpec?.value || matchingSpec.value === '' || matchingSpec.value === null;
					}
					return false;
				});

				if (mandatorySpecsWithEmptyValues.length > 0) {
					hasError = true;
					mandatorySpecsWithEmptyValues?.forEach((err) => {
						notificationServices?.generateNotification({
							type: 'error',
							message: ` ${err?.label || 'All mandatory feilds'} must have a value`,
						});
					});
				}
			}
		});
		if (hasError) return;
		try {
			const response = await quoteServices.saveVendorQuote({
				req: {
					data: {
						assetRFQ: {
							id: params?.id,
						},
						vendor,
						store,
						refDoc: doc?.file,
						items: row.map((item) => ({
							assetRFQItem: {
								id: item?.id,
							},
							assetType: rfq?.find((rfqItem) => rfqItem?.items?.some((i) => i.id === item?.id))?.items?.find((i) => i.id === item?.id)?.assetType,
							oem: item?.oems,
							qty: item?.qty,
							amount: item?.amount,
							coreSpecifications: item?.specifications
								?.filter((spec) => spec?.rfqMandatory || spec?.supplierMandatory)
								?.map((spec) => ({
									coreSpecification: {
										id: spec?.id,
									},
									value: spec?.value,
								})),
						})),
					},
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: 'Response Sent Successfully',
				});
				setStore(null);
				setVendor([]);
				setRow([]);
				history.goBack();
			} else {
				const errorMessage = response?.responseMessage || response?.message || 'An unknown error occurred.';
				notificationServices?.generateNotification({
					type: 'error',
					message: errorMessage,
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};
	const uploadInvoiceCick = () => {
		toggleFileUploaderModal(true, {
			heading: 'Upload Vendor Invoice',
			selectedFile: '',
			confirmText: 'Upload',
		});
	};
	const handleDocUpload = (fileData) => {
		setDoc(fileData);
		toggleFileUploaderModal(false);
	};

	return (
		<>
			<FileUploaderModal handleConfirm={handleDocUpload} />
			<VendorQuoteHeading history={history} handleSave={handleSave} />
			<div className='w-full'>
				<div className='flex gap-4'>
					<div
						className={styles.body}
						style={{
							padding: '1rem',
							width: '50%',
						}}
					>
						<div className='flex gap-20 justify-start items-center mb-2'>
							<label
								className='text-red-500'
								htmlFor='vendorSelection'
							>
								Vendor Name*
							</label>
							<SelectPicker
								data={vendors?.map((item) => ({
									label: item?.name, value: item?.id,
								}))}
								value={vendor?.id}
								placeholder='Select Vendors'
								style={{
									width: 304,
								}}
								menuStyle={{
									width: 304,
								}}
								onChange={(value) => setVendor({
									id: value,
								})}
							/>
						</div>
						<div className='flex gap-[5.7rem] justify-start items-center mb-2'>
							<label className='text-red-500' htmlFor='store'>
								Select Store*
							</label>
							<SelectPicker
								value={store}
								data={storesList?.map((item) => (
									{
										label: item?.name, value: item?.id,
									}
								))}
								style={{
									width: 304,
								}}
								menuStyle={{
									width: 304,
								}}
								onChange={(value) => {
									setStore(value);
								}}
							/>
						</div>
						<div className='flex gap-8 justify-start items-center mb-2'>
							<label className={styles.mandatory} htmlFor='dateSelector'>
								Quote Request Date*
							</label>
							<span>{moment(new Date())?.format('YYYY-MM-DD')}</span>
						</div>
					</div>

					<div
						className={`flex items-center gap-4 ${doc?.file ? 'h-[20rem]' : 'h-auto'} p-2 w-[49%] bg-transparent`}
					>
						<Button
							type='button'
							variant='outline'
							color='primary'
							onClick={uploadInvoiceCick}
						>
							Refrence Document
						</Button>
						{doc?.file && doc?.fileName && (
							<div className='w-full h-full'>
								<iframe
									className='w-full h-full border rounded-md'
									src={doc?.file}
									title='PDF Rendered from Base64'
								/>
							</div>
						)}
					</div>

				</div>
				<VendorCreateTable
					row={row}
					setRow={setRow}
					oemList={oemList}
					maxQty={maxQty}
					assetData={assets}
					rfq={rfq}
					oems={oems}
				/>
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	const { LIST: storesList } = state?.form?.STORES?.data;

	return {
		vendors,
		storesList,
	};
};
const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VendorQuoteList);
