/* eslint-disable no-tabs */
import { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Coupon Code',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Applicable From',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Applicable To',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Discount Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Discount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Min Order Value',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Max Discount',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	'Coupon Code': 'couponCode',
	'Created Date': 'createdDate',
	'Applicable From': 'applicableFrom',
	'Applicable To': 'applicableTo',
	'Discount Type': 'discontType',
	Discount: 'discount',
	'Min Order Value': 'minOrderAmount',
	'Max Discount': 'maxDiscountAmount',
};
const PromoCodeListPage = (props) => {
	const { fetchPromoCodes, data, query, deletePromoCodes, copyOfData } =
		props;

	useEffect(() => {
		fetchPromoCodes({
			entity: 'PROMO_CODES',
			req: {
				params: {},
			},
			method: 'LIST',
		});
	}, []);

	const handleDelete = (idx) => {
		deletePromoCodes({
			entity: 'PROMO_CODES',
			req: {
				params: {
					id: copyOfData[idx].id,
					couponCode: copyOfData[idx].couponCode,
				},
			},
			method: 'DELETE',
		});
	};
	return (
		<div className='root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.filter((code) => {
						return (
							code.couponCode
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})
					.map((code, idx) => {
						const newObj = code;
						newObj.id = idx;
						return {
							...newObj,
							_name: code?.[COLMAP?.['Coupon Code']],
						};
					})
					.filter((obj) => {
						return obj.active;
					})}
				actions={[...ACTION_COLUMNS]}
				special={{}}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = _.cloneDeep(state.form.PROMO_CODES.data.LIST);
	const copyOfData = _.cloneDeep(state.form.PROMO_CODES.data.LIST);
	return {
		data,
		copyOfData,
	};
};

const mapDispatchToProps = {
	fetchPromoCodes: formActions.gateway,
	deletePromoCodes: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeListPage);
