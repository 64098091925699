/* eslint-disable no-unused-vars */
// import { TextField } from '@mui/material';
// import { classes } from '../../../Helpers';
import { HighlightOff } from '@mui/icons-material';
import { CheckPicker, SelectPicker } from 'rsuite';
import {
	addCommas,
	classes,
	convertWeightUnit,
	options,
} from '../../../Helpers';
import CustomInputSelector from '../../CustomInputSelector/CustomInputSelector';
import styles from './TableRow.module.scss';
import CoreSpecifications from './CoreSpecifications';

const currencyTitles = [
	'Total Discount',
	'Sub total',
	'Total Tax',
	'Total Amount',
];

const renderQtyInfo = (skuStockInfo, index, product) => {
	return (
		<div className={styles?.quantitiesContainer}>

			{skuStockInfo?.map((infoEle) => {
				if (infoEle?.columnIndexToShowIn !== index) {
					return <></>;
				}
				const currentVariantValue = infoEle?.values?.[product?.selectedVariant?.id];
				return (
					<span
						className={styles?.quantities}
						style={{
							backgroundColor: infoEle?.bgColor,
							color: infoEle?.color,
						}}
					>
						<span className={styles?.title}>
							{infoEle?.name}
							:
						</span>
						<span className={styles?.value}>
							{currentVariantValue?.unit
								? `${currentVariantValue?.qty} ${currentVariantValue?.unit}`
								: convertWeightUnit(
									infoEle?.values?.[
										product?.selectedVariant?.id
									] ?? 0,
									product?.unit
								)}
						</span>
					</span>
				);
			})}
		</div>
	);
};

function OEMSelector(props) {
	const { product, handleOemSelection } = props;
	return (
		<SelectPicker
			style={{
				width: '100%',
				fontSize: '11px',
				padding: '4px',
				margin: '4px 0',
				borderRadius: '4px',
			}}
			value={product?.oems?.find((oem) => oem?.isSelected)?.id || null}
			onChange={(selectedOemId) => {
				handleOemSelection([selectedOemId]);
			}}
			data={product?.oems?.map((oem) => ({
				label: oem.name,
				value: oem.id,
			}))}
			placeholder='Select OEM'
			searchable={false}
		/>
	);
}

function TableRow(props) {
	const {
		key,
		product,
		idx,
		quantity,
		handleQuantity = () => {},
		handleItemDescription = () => {},
		disabled,
		productWiseMappingData,
		handleSelection,
		productsList,
		handleRate,
		ratePerString = null,
		handleDiscount,
		addedItems = [],
		handleRemove = () => {},
		rowStyles,
		skuStockInfo,
		measurementType,
		columns = [],
		handleOemSelection = () => {},
		handleSpecifications = () => {},
		isDeliveryChallan = false,
		isChangeable = true,
		rowError = {},
		handleChangeProductUnit = () => {},
		typeId,
		qtyRegex = null,
		customComponents = {},
	} = props;

	const tableRows = {
		'S.No': () => {
			return product?.unit ? (idx + 1) : '-';
		},
		'ITEM DETAILS': () => {
			return product?.productName ? (
				<>
					<div className={styles['added-item']}>
						{product?.productImageUrl && (
							<img src={product?.productImageUrl} alt='product' />
						)}
						<div className={styles['head-variants']}>
							<p>
								{product?.productName}
								(
								{product?.selectedVariant?.displayName ?? product?.variantName}
								)
							</p>
							{product?.productName &&
							renderQtyInfo(skuStockInfo, 0, product)}
						</div>
					</div>
					{(typeId === 4) && product?.oems && (
						<OEMSelector
							product={product}
							handleOemSelection={(selectedOemsIds) => {
								handleOemSelection(idx, selectedOemsIds);
							}}
						/>
					)}
				</>
			) : (
				<CustomInputSelector
					addedItems={typeId === 4 ? [] : addedItems}
					showImage
					handleSelection={handleSelection}
					inputLists={productsList?.map((ele) => {
						return {
							...ele,
							name: ele?.productName,
							imageUrl: ele?.productImageUrl,
						};
					})}
					skuStockInfo={skuStockInfo}
					addNewText='Add new Product'
					placeholder='Type or Click to select item'
				/>
			);
		},
		UNIT: () => {
			return product?.unit ?? '-';
		},
		'QTY-INFO': () => {
			return (
				product?.productName && renderQtyInfo(skuStockInfo, 2, product)
			);
		},
		SPECIFICATIONS: () => {
			return (
				<CoreSpecifications
					mode='rfq'
					showAllSpecs={false}
					className={styles['core-specs']}
					specifications={product?.coreSpecifications}
					onValueChange={(id, value) => {
						handleSpecifications(idx, id, value);
					}}
				/>
			);
		},
		'EXPECTED LEFT OVER': () => {
			return product?.expectedLeftOver ?? '-';
		},
		QUANTITY: () => {
			return (
				<>
					<div className={classes(styles['editable-input'])}>
						{customComponents?.QUANTITY?.(product)}
						<input
							className={classes(
								styles['custom-input'],
								rowError?.[product?.id]?.quantity
									? styles.error
									: ''
							)}
							type='text'
							min={0}
							value={quantity}
							onChange={(e) => {
								const inputValue = e?.target?.value;
								let defaultRegex = /^(?:\d+(\.\d*)?|\.\d+)?$/;
								if (product?.unit === 'count') {
									defaultRegex = /^\+?(0|[1-9]\d*)?$/;
								}
								const isPositiveDecimal = (
									qtyRegex ?? defaultRegex
								).test(inputValue);
								if (isPositiveDecimal) {
									handleQuantity(product?.id, inputValue, typeId === 4 ? idx : null);
								}
							}}
							placeholder='Quantity'
							disbaled={!isChangeable}
						/>
					</div>
					{product?.unit && product?.unit !== 'count' && (
						<div className={styles.input_unit}>
							<p
								style={{
									fontSize: '.8rem',
								}}
							>
								Quantity in
							</p>
							<div className={styles.unit_options}>
								{options?.[product?.productInputUnit]?.map(
									(ele) => {
										const isSelected =
											ele?.value ===
											product?.productInputUnit;
										return (
											<div
												onClick={() => {
													handleChangeProductUnit(
														product?.id,
														ele?.value
													);
												}}
												className={classes(
													styles.unit,
													isSelected
														? styles.selected
														: ''
												)}
											>
												{ele?.displayName}
											</div>
										);
									}
								)}
							</div>
						</div>
					)}
				</>
			);
		},
		RATE: () => {
			const findIfPriceFixed = productWiseMappingData?.find((ele) => {
				return ele?.productId === product?.productId;
			});
			return (
				<div className='min-w-20'>
					<div className={classes(styles['editable-input'])}>
						<input
							type='number'
							className={classes(
								styles['custom-input'],
								rowError?.[product?.id]?.rate
									? styles.error
									: ''
							)}
							min={0}
							value={
								product?.selectedVariant?.price
									? parseFloat(
										product?.selectedVariant?.price
									)
									: 0
							}
							onChange={(e) => {
								if (e?.target?.value >= 0) {
									handleRate(
										product?.id,
										e?.target.value,
										typeId === 4 ? idx : null
									);
								}
							}}
							placeholder='Rate'
							step='any'
							disabled={
								product?.disabledPrice ||
								(!(typeId === 4 || typeId === 7) &&
									(!findIfPriceFixed?.mappingTypeElementValue ||
										findIfPriceFixed?.mappingTypeElementValue ===
											'0'))
							}
						/>
					</div>
					{product?.unit && product?.unit !== 'count' && (
						<p
							style={{
								fontSize: '.8rem',
							}}
						>
							{ratePerString}
							{' '}
							Rate per
							{' '}
							{options?.[product?.unit]?.[0]?.displayName}
						</p>
					)}
					{product?.unit === 'count' && (
						<p
							style={{
								fontSize: '.8rem',
							}}
						>
							{ratePerString}
							Rate per item
						</p>
					)}
				</div>
			);
		},
		'Discount%': () => {
			const isPriceFixed = productWiseMappingData?.find((ele) => {
				return ele?.productId === product?.productId;
			});
			if (
				typeId !== 4 &&
				(!isPriceFixed?.mappingTypeElementValue ||
					isPriceFixed?.mappingTypeElementValue === '0')
			) {
				return (
					<p className={styles['disabled-value']}>
						{product?.discountPercentage
							? Number(product?.discountPercentage)?.toFixed()
							: 0}
					</p>
				);
			}
			return (
				<>
					<div className={styles['editable-input']}>
						<input
							type='text'
							className={styles['custom-input']}
							value={
								product?.discountPercentage
									? product?.discountPercentage
									: 0
							}
							onChange={(e) => {
								const inputValue = e.target.value;
								const pattern =
									/^(100(\.0+)?|\d{0,2}(\.\d{0,2})?)$/;
								if (pattern.test(inputValue)) {
									handleDiscount(
										product?.id,
										e?.target.value
									);
								}
							}}
							placeholder='Discount %'
						/>
					</div>
				</>
			);
		},
		'Total Discount': () => {
			return (
				<div className={styles['editable-input']}>
					₹
					{addCommas(product?.totalDiscount ?? 0) ?? 0}
				</div>
			);
		},
		'TAX %': () => {
			return product?.tax ? `${product?.tax}%` : 0;
		},
		'Sub total': () => {
			return `₹${addCommas(product?.subTotal ?? 0) ?? 0}`;
		},
		'Total Tax': () => {
			return `₹${addCommas(product?.totalTax ?? 0) ?? 0}`;
		},
		'Total Amount': () => {
			if (isDeliveryChallan) {
				return `₹${Number(product?.subTotal ?? 0)?.toFixed(2) ?? 0}`;
			}
			return `₹${
				product?.selectedVariant
					? addCommas(product?.totalPriceIncTax ?? 0)
					: 0
			}`;
		},
	};

	return (
		<tr
			key={product?.selectedVariant?.id}
			className={classes(styles['table-tr'], rowStyles)}
		>
			{columns?.map((ele) => {
				const isCurrency = currencyTitles?.includes(ele?.title);
				return (
					<td
						align={isCurrency && 'right'}
						style={{
							width: ele?.width,
						}}
					>
						{tableRows?.[ele?.title]()}
					</td>
				);
			})}
			{/* <td>
			</td>
			<td>{product?.unit ?? '-'}</td>
			<td>
				{product?.productName &&
					renderQtyInfo(skuStockInfo, 2, product)}
			</td>
			<td>
				<div className={styles['editable-input']}>
					<input
						className={styles['custom-input']}
						type='number'
						value={product?.quantity ? product?.quantity : quantity}
						onChange={(e) => {
							handleQuantity(product?.id, e?.target.value);
						}}
						placeholder='Quantity'
					/>
				</div>
				{(product?.unit === 'gram' || product?.unit === 'oz') && (
					<p style={{
						fontSize: '.8rem',
					}}
					>
						Quantity in
						{' '}
						{product?.unit}
					</p>
				)}
			</td>
			<td>
				<div className={styles['editable-input']}>
					<input
						className={styles['custom-input']}
						value={
							product?.selectedVariant?.price
								? Number(
									product?.selectedVariant?.price
								)?.toFixed()
								: 0
						}
						onChange={(e) => {
							handleRate(product?.id, e?.target.value);
						}}
						placeholder='Rate'
					/>
				</div>
				{(product?.unit === 'gram' || product?.unit === 'oz') && (
					<p style={{
						fontSize: '.8rem',
					}}
					>
						Rate per
						{measurementType === 'US' ? 'lb' : 'kg'}
					</p>
				)}
			</td>
			<td>{product?.tax ? `${product?.tax}%` : 0}</td>
			<td>{Number(product?.subTotal ?? 0)?.toFixed(2) ?? 0}</td>
			<td>{Number(product?.totalTax ?? 0)?.toFixed(2) ?? 0}</td>
			<td>
				{product?.selectedVariant
					? Number(product?.totalPriceIncTax)?.toFixed(2)
					: 0}
			</td> */}
			{product?.id && !disabled && (
				<div
					onClick={() => {
						handleRemove(product, idx);
					}}
					className={styles['remove-container']}
				>
					<HighlightOff className={styles.icon} />
				</div>
			)}
		</tr>
	);
}

export default TableRow;
