// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import CrudAssetRoutes from './CrudAssetRoutes';
import { AssetListing, AssetMonitoringPage } from '../../Pages';
import ImmovableAsset from '../../Pages/Assets/ImmovableAsset/ImmovableAsset';
import AssetCapacity from '../../Pages/Assets/AssetCapacity/AssetCapacity';

const getSubURL = (entity) => {
	const baseEntity = urls.ASSET;
	return entity.slice(baseEntity.length);
};
const AssetRoute = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.ASSET_CATEGORY)}`}>
				<CrudAssetRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.ASSET_MONITORING)}`}>
				<AssetMonitoringPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.ASSET_IMMOVEABLE)}`}>
				<ImmovableAsset />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.ASSET_CAPACITY)}`}>
				<AssetCapacity />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.ASSET_LISTING)}`}>
				<AssetListing />
			</Route>
		</Switch>
	);
};

export default AssetRoute;
