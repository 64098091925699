/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from 'react-router-dom';
import styles from './AiStatsPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import { OutwardingImagesFinder } from '../../../Components/Analytics/Outwarding';
import myMachineService from '../../../Services/AssetServices/myMachine.service';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../../Actions';
import { aiStatsServices } from '../../../Services';
import { classes } from '../../../Helpers';
import { urls } from '../../../Constants';
// import { notificationServices } from '../../../Services';

const SPECIAL_COLUMNS = [
	{
		title: 'navigate',
		config: {
			type: 'DATA',
		},
	},
];

// const COLMAP = {
// 	'Machine Name': 'machine_name',
// 	'Mac address': 'mac',
// 	'Store Name': 'storeName',
// 	'Current Version': 'currentVersion',
// 	'Last updated': 'updatedAt',
// 	'Last updated by': 'updated_by',
// };

function AiStatsPage() {
	const [isloading, setIsloading] = useState(false);
	const [aiData, setAiData] = useState([]);
	const [tableDataToShow, setTableDataToShow] = useState([]);
	const [currentDateRange, setCurrentDateRange] = useState({
		from: new Date().toISOString(),
		to: new Date().toISOString(),
	});
	const history = useHistory();
	const [currentCollumns, setCurrentCollumns] = useState([]);
	const [currentCollumnsMap, setCurrentCollumnsMap] = useState({});
	// const [machinesData, setMachinesData] = useState([]);

	const buildColumns = useCallback(
		(data = {}, hideValues = []) => {
			const colMap = Object?.keys(data).filter((key) => {
				return !hideValues?.includes(key);
			});
			const obj = {};
			const obj2 = [];
			colMap?.forEach((ele) => {
				if (ele === 'filterAnnotate' || ele === 'filterView') return;
				obj[ele] = ele;
				obj2.push({
					title: ele,
					config: {
						type: 'DATA',
					},
				});
			});
			setCurrentCollumnsMap(obj);
			setCurrentCollumns(obj2);
		},
		[setCurrentCollumns, setCurrentCollumnsMap]
	);

	useEffect(() => {
		if (tableDataToShow?.tabName?.length > 0) {
			buildColumns(
				tableDataToShow?.tabData?.[0],
				tableDataToShow?.hideKeys
			);
		}
	}, [tableDataToShow]);

	const handleListFetch = useCallback(
		({ dateRange = null }, firstCall = false) => {
			(async () => {
				setIsloading(true);
				setCurrentDateRange({
					from: dateRange.from,
					to: dateRange.to,
				});
				try {
					const responseData = await aiStatsServices.fetchAiData({
						req: {
							data: {
								dateRangeFilter: dateRange
									? { ...dateRange }
									: {},
							},
						},
					});
					if (responseData?.statusCode === 200) {
						setAiData(responseData?.data);
						// if (firstCall) {
						// }
						setTableDataToShow(responseData?.data?.[0] ?? []);
						// setSelectedStore(store);
						// setMachinesData(responseData?.data?.machinesList);
						// setLatestVersion(responseData?.data?.latestVersion);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setIsloading(false);
				}
			})();
		}
	);
	// eslint-disable-next-line no-unused-vars

	useEffect(() => {
		handleListFetch(
			{
				dateRange: {
					to: currentDateRange.to,
					from: currentDateRange.from,
					active: true,
				},
			},
			true
		);
	}, []);

	const SPECIAL_ITEMS = {
		navigate: (id) => {
			const thisItem = tableDataToShow?.tabData?.find((ele) => {
				return ele.id === id;
			});
			return (
				<div
					style={{
						// width: '5rem',
						display: 'flex',
						flexDirection: 'row',
						cursor: 'pointer',
						alignContent: 'center',
						alignItems: 'center',
						gap: '.2rem',
						justifyContent: 'space-evenly',
						justifyItems: 'center',
					}}
				>
					<Button
						variant='contained'
						color='warning'
						size='small'
						style={{
							padding: '0px 6px',
							fontWeight: 'bolder',
							fontSize: '.5rem',
							color: 'white',
						}}
						onClick={() => {
							if (Object.keys(thisItem?.filterView).length < 1) {
								return;
							}

							history.push({
								pathname: urls.EXTENSION + urls.QC_HISTORY_LIST,
								aiFilter: { ...thisItem?.filterView }, // Pass the object as state
							});
						}}
					>
						<VisibilityIcon fontSize='small' />
						Wrong Prediction
					</Button>

					<Button
						variant='contained'
						size='small'
						onClick={() => {
							if (
								Object.keys(thisItem?.filterAnnotate).length < 1
							) {
								return;
							}

							history.push({
								pathname: urls.EXTENSION + urls.QC_HISTORY_LIST,
								aiFilter: { ...thisItem?.filterAnnotate }, // Pass the object as state
							});
						}}
						style={{
							fontSize: '.5rem',
							padding: '0px 4px',
							background: 'blue',
						}}
					>
						Show items to annotate
					</Button>
				</div>
			);
		},
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<div className={styles.header}>
				<div className={styles.typeFilters}>
					<div className={styles.heading}>Ai Stats</div>
					<div className={styles.buttonFilters}>
						{aiData.map((ele, index) => {
							return (
								<div
									key={ele?.tabName}
									onClick={() => {
										setTableDataToShow(aiData[index]);
									}}
									className={classes(
										ele?.tabName ===
											tableDataToShow?.tabName
											? styles?.selected
											: ''
									)}
								>
									{ele?.tabName}
								</div>
							);
						})}
					</div>
				</div>
				<div className={styles.filter}>
					<OutwardingImagesFinder
						extraStyles={{
							backgroundColor: 'rgba(0,255,0,.1)',
						}}
						storeLabel='Select Store'
						hideIdentification
						hideBrandSelection
						hideProductSelection
						hideTimeText
						onSubmit={handleListFetch}
					/>
				</div>
			</div>
			<div className={styles?.table}>
				<ListTable
					colMap={currentCollumnsMap}
					columns={currentCollumns}
					data={tableDataToShow?.tabData}
					actions={[...SPECIAL_COLUMNS]}
					special={SPECIAL_ITEMS}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AiStatsPage);
