import _ from 'lodash';
import { navigationConstants, navMenus } from '../Constants';

const initalState = {
	navState: _.cloneDeep(navMenus) || {},
	activeNav: '',
	showSidebar: false,
	showInternalData: false,
	userPermissions: {
		VIEW: false,
		EDIT: false,
		DELETE: false,
		CANCEL: false,
		CREATE: false,
		UPDATE: false,
		ANNOTATE: false,
	},
	permittedStores: [],
};

function navigation(state = initalState, action) {
	switch (action.type) {
		case navigationConstants.EXPAND_NAV:
		case navigationConstants.COLLAPSE_NAV:
		case navigationConstants.NAV_REQUISITE:
			return {
				...state,
				navState: action.payload,
			};
		case navigationConstants.TOGGLE_SIDEBAR:
			return {
				...state,
				showSidebar: action.payload,
			};
		case navigationConstants.UPDATE_PATHS:
			return {
				...state,
				navState: action.payload.navState,
			};
		case navigationConstants.UPDATE_USER_PERMISSIONS:
			return {
				...state,
				userPermissions: action.payload,
			};
		case navigationConstants.DISABLE_NAV_ITEM:
			return {
				...state,
				navState: action.payload,
			};
		case navigationConstants.UPDATE_NAV_ITEM:
			return {
				...state,
				navState: action.payload,
			};
		case navigationConstants.ENABLE_NAV_ITEM:
			return {
				...state,
				navState: action.payload,
			};
		case navigationConstants.SET_PERMITTED_STORES:
			return {
				...state,
				permittedStores: action.payload,
			};
		case navigationConstants.SET_SHOW_INTERNAL_DATA:
			return {
				...state,
				showInternalData: action.payload,
			};
		default:
			return state;
	}
}

export default navigation;
