import {
	Route, Switch, useRouteMatch
} from 'react-router';
import { CreatePage } from '../../../../Pages';
import DeliveryChallan from '../../../../Pages/SalesOrderPages/DeliveryChallans/DeliveryChallan';
import DeliveryChallanCreatePage from '../../../../Pages/SalesOrderPages/DeliveryChallans/DeliveryChallanCreatePage/DeliveryChallanCreatePage';

const DeliveryChallanRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<DeliveryChallan createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<DeliveryChallanCreatePage />}
					entity='DELIVERY_CHALLAN'
				/>
			</Route>
		</Switch>
	);
};

export default DeliveryChallanRoutes;
