import { Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { connect } from 'react-redux';
import AddProductCard from '../AddProductCard';
import ProductCard from '../ProductCard';
import { modalActions } from '../../../Actions';
import { generateRequiredProps } from '../../../Helpers';
import { DraggableHCards } from '../../Draggable/DraggableHCards';
import DraggableVTabs from '../../Draggable/DraggableVTabs/DraggableVTabs';
import SliderDiscount from '../../SliderDiscount/SliderDiscount';
import EnableDisable from '../EnableDisable/EnableDisable';
import styles from './MenuSubCategory.module.scss';
import TimeSlots from './TimeComponent/TimeSlots';

const MenuSubCategory = (props) => {
	const {
		subCategoryDetails,
		activeCategory,
		mandatoryFields,
		handleSubCategoryReOrdering,
		setActiveSubCategory,
		addNewSubCategory,
		activeSubCategory,
		fields,
		isDisabled,
		handleDelete,
		openProducts,
		openTimeSlots,
		handleSliderChange,
		selectedProducts,
		products,
		handleSwitchChange,
		handleReorderItems,
		checkBoxShow,
		handleQtyChange,
	} = props;

	const handleInput = (e) => {
		props.handleInput(e);
	};

	const generateDOMCardsList = () => {
		return selectedProducts.map((productObj, idx) => {
			let product = products.filter((currProduct) => {
				return (
					parseInt(currProduct.id, 10) ===
					parseInt(productObj.productId, 10)
				);
			});
			product = product.length
				? {
					...product[0],
				}
				: {};
			return (
				<ProductCard
					title={product.productName}
					img={product.productImageUrl}
					key={`${productObj.productId}`}
					idx={idx}
					handleDelete={handleDelete}
					dataAttrs={{
						'data-type': 'product',
						'data-id': idx,
						'data-parent': activeSubCategory,
					}}
					isDisabled={isDisabled}
				>
					<SliderDiscount
						idx={idx}
						product={product}
						handleSliderChange={handleSliderChange}
						handleQtyChange={handleQtyChange}
						data-type='product'
						data-id={idx}
						data-parent={activeSubCategory}
						productObj={productObj}
						openTimeSlots={openTimeSlots}
						handleDelete={handleDelete}
						handleSwitchChange={handleSwitchChange}
						isDisabled={isDisabled}
					/>
				</ProductCard>
			);
		});
	};

	return (
		<div className={`${styles.root}`}>
			<DraggableVTabs
				handleReorder={handleSubCategoryReOrdering}
				items={
					fields.categories.items[activeCategory].subCategories.items
				}
				order={
					fields.categories.items[activeCategory].subCategories.order
				}
				handleActiveTab={setActiveSubCategory}
				handleAddTab={addNewSubCategory}
				activeTab={activeSubCategory}
				limit={15}
				text='Sub Category'
				isDisabled={isDisabled}
				isDraggable={!isDisabled}
			/>
			<fieldset disabled={isDisabled} className='form-fieldset'>
				{/* delete sub category button */}
				{!isDisabled && (
					<Tooltip title='Delete Sub Category' placement='left-end'>
						<Delete
							className={`${styles['delete-icon']}`}
							onClick={handleDelete}
							data-type='subCategory'
							data-id={activeSubCategory}
						/>
					</Tooltip>
				)}
				<div className={`${styles.right}`}>
					{/* sub category name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='sub-category-name'
							data-mandatory={
								mandatoryFields.categories[0].subCategories[0]
									.name
							}
						>
							Sub Category Name
						</label>
						<input
							className='form-value'
							type='text'
							id='sub-category-name'
							name='name'
							onInput={handleInput}
							value={subCategoryDetails.name}
							data-type='subCategory'
							data-id={activeSubCategory}
							{...generateRequiredProps(
								mandatoryFields.categories[0].subCategories[0]
									.name
							)}
						/>
					</div>
					{/* sub category description */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='subCategoryDescription'
							data-mandatory={
								mandatoryFields.categories[0].subCategories[0]
									.description
							}
						>
							Sub Category Description
						</label>
						<textarea
							className='form-value'
							id='subCategoryDescription'
							name='description'
							onInput={handleInput}
							value={subCategoryDetails.description}
							data-type='subCategory'
							data-id={activeSubCategory}
							{...generateRequiredProps(
								mandatoryFields.categories[0].subCategories[0]
									.description
							)}
						/>
					</div>
					<div className={`${styles.enabledisable}`}>
						<EnableDisable
							name='isEnabled'
							handleIsEnabled={handleInput}
							dataAttrs={{
								'data-type': 'subCategory',
								'data-id': activeSubCategory,
							}}
							isEnabled={
								fields.categories.items[activeCategory]
									.subCategories.items[activeSubCategory]
									.timeSlots.isEnabled
							}
							text='Add Time Scheduling to this sub category?'
							className='enable-disable'
							isDisabled={isDisabled}
							checkBoxShow={checkBoxShow}
						>
							<div className={`${styles.timeWrapper}`}>
								<TimeSlots
									slots={
										// eslint-disable-next-line indent
										fields.categories.items[activeCategory]
											.subCategories.items[activeSubCategory
											// eslint-disable-next-line indent
										].timeSlots.slots
									}
									handleAddNewSlot={openTimeSlots}
									dataAttrs={{
										'data-type': 'subCategory',
										'data-id': activeSubCategory,
										'data-name': 'time-slot',
									}}
									handleDelete={handleDelete}
									className='time-container'
									isDisabled={isDisabled}
								/>
							</div>
						</EnableDisable>
					</div>
					<div className={`${styles['product-wrapper']}`}>
						<DraggableHCards
							moveCard={handleReorderItems}
							isDraggable={!isDisabled}
							cardsList={generateDOMCardsList()}
						/>
						{!isDisabled && (
							<AddProductCard
								text='Product'
								handleClick={openProducts}
							/>
						)}
					</div>
				</div>
			</fieldset>
		</div>
	);
};
const mapStateToProps = (state) => {
	const products = state.form.ALL_PRODUCTS.data.LIST;
	return {
		products,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		toggleProducts: (show) => {
			dispatch(modalActions.toggleProducts(show));
		},
	};
};
MenuSubCategory.defaultProps = {
	isDisabled: false,
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuSubCategory);
