import AddNewProcess from './AddNewProcess';
import AddNewSection from './AddNewSection';
import MapProcess from './MapProcess';

const settingsSubmenu = [
	{
		id: 1,
		header: 'Add New Process Flow',
		comp: (props) => {
			return <AddNewProcess {...props} />;
		},
	},
	{
		id: 2,
		header: 'Add New Section',
		comp: (props) => {
			return <AddNewSection {...props} />;
		},
	},
	{
		id: 3,
		header: 'Map Process To store',
		comp: (props) => {
			return <MapProcess {...props} />;
		},
	},
];
export default settingsSubmenu;
