/* eslint-disable react/jsx-one-expression-per-line */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { generateRequiredProps } from '../../../../Helpers';
import CustomInput from '../../../CustomInput/CustomInput';
import { IconInput } from '../../Inputs';
import styles from './RecipeDetails.module.scss';
// import EnableDisable from '../../EnableDisable/EnableDisable';

const RecipeDetails = (props) => {
	const {
		fields,
		mandatoryFields,
		handleInput,
		errors,
		isDisabled,
		handleBatchSize,
	} = props;
	return (
		<div>
			{/* instructions and recipe link */}
			<fieldset disabled={isDisabled} className='form-fieldset'>
				{/* instructions */}
				<div className='form-field row'>
					<label className='form-label'>
						Preparation Date delta
						<AccessTimeIcon />
					</label>
					<div
						style={{
							gap: '0.5rem',
						}}
						className='row'
					>
						<CustomInput
							name='scheduledAtDelta'
							type='number'
							step={1}
							min={-1}
							max={1}
							value={fields?.scheduledAtDelta}
							onChangeHandler={handleInput}
							className={styles['input-unit']}
							inputClassName={styles.input}
							requiredProps={{
								'data-type': 'recipe',
							}}
						/>
					</div>
				</div>
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='instructions'
						data-mandatory={mandatoryFields.instructions}
					>
						Instructions to the chef
					</label>
					<textarea
						className='form-value'
						type='text'
						id='instructions'
						name='instructions'
						onInput={handleInput}
						value={fields.instructions}
						data-type='recipe'
						{...generateRequiredProps(mandatoryFields.instructions)}
					/>
					<span className='form-error'>{errors.instructions}</span>
				</div>
				{/*  recipe link */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='recipeLink'
						data-mandatory={mandatoryFields.recipeLink}
					>
						Recipe Link
					</label>
					<input
						className='form-value'
						type='text'
						id='recipeLink'
						name='recipeLink'
						onInput={handleInput}
						value={fields.recipeLink}
						data-type='recipe'
						{...generateRequiredProps(mandatoryFields.recipeLink)}
					/>
					<span className='form-error'>{errors.recipeLink}</span>
				</div>
				<div className='form-field row'>
					<label className='form-label' htmlFor='isBulk'>
						Non Assembly?
					</label>
					{isDisabled ? (
						<>
							{fields?.isBulk ? (
								<>
									<div>yes</div>
								</>
							) : (
								<>
									<div>no</div>
								</>
							)}
						</>
					) : (
						<>
							<input
								style={{
									width: '30px',
									display: 'flex',
									justifyItems: 'flex-start',
									justifyContent: 'flex-start',
									transform: 'scale(1.3)',
								}}
								className='form-value'
								type='checkbox'
								id='isBulk'
								name='isBulk'
								// onInput={handleInput}
								onChange={handleInput}
								checked={fields?.isBulk}
								data-type='isBulk'
							/>
						</>
					)}
				</div>
				{/* default variant */}
				<div className='form-field row'>
					<div className='form-label'>Default Variant</div>
					<div className='form-value'>
						{`${fields?.product?.defaultVariant?.description}(${(
							(fields?.product?.defaultVariant?.maxWeight -
								fields?.product?.defaultVariant?.minWeight) /
							2
						).toFixed(0)}g)`}
					</div>
				</div>
				<div className='form-field row'>
					<label className='form-label'>
						Serving Quantity (Variants Wise) -
						{fields?.product?.unit?.toUpperCase()}
					</label>
					<div
						style={{
							gap: '0.5rem',
						}}
						className={`row ${styles['variant-wise']}`}
					>
						{fields?.product?.variants?.map((ele) => {
							return (
								<div
									style={{
										padding: '0.25rem 0.5rem',
										border: '1px solid #dddddd',
										borderRadius: '25px',
										width: '125px',
									}}
									className='row'
								>
									{ele?.displayName}:{' '}
									{ele?.servingQtyPercentage}%
								</div>
							);
						})}
					</div>
				</div>
				<div className='form-field row'>
					<label className='form-label'>
						Batch Size (Variants Wise)
					</label>
					<div
						style={{
							gap: '0.5rem',
						}}
						className={`row ${styles['variant-wise']}`}
					>
						{fields?.product?.variants?.map((ele) => {
							return (
								<div className={styles['variant-wise-batch']}>
									<div>{ele?.displayName}:</div>
									<CustomInput
										name='batchSize'
										type='number'
										text={fields?.product?.unit?.toUpperCase()}
										value={
											fields?.batchSize?.[
												ele?.id?.toString()
											]
										}
										onChangeHandler={(e) => {
											handleBatchSize(e, ele?.id);
										}}
										requiredProps={{
											'data-type': 'recipe',
											...generateRequiredProps(
												mandatoryFields.batchSize
											),
										}}
									/>
								</div>
							);
						})}
					</div>
				</div>
				{/* <div className='form-field row'>
					<label className='form-label' htmlFor='batchSize'>
						Batch Size (Variant Wise)
						-
						{(fields?.product?.unit)?.toUpperCase()}
					</label>
					<input
						className='form-value'
						type='number'
						id='batchSize'
						name='batchSize'
						// onInput={handleInput}
						onChange={handleInput}
						value={fields?.batchSize}
						data-type='batchSize'
					/>
				</div> */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='weightTolerance'
						data-mandatory={mandatoryFields.recipeLink}
					>
						Weight tolerance
					</label>
					<IconInput
						size='large'
						type='percent'
						name='weightTolerance'
						id='weightTolerance'
						value={fields.weightTolerance}
						handleInput={handleInput}
						min={0.001}
						step={0.001}
						max={100}
						dataAttrs={{
							required: mandatoryFields.weightTolerance,
						}}
					/>
					<span className='form-error'>{errors.recipeLink}</span>
				</div>
			</fieldset>
		</div>
	);
};

RecipeDetails.defaultProps = {
	errors: {},
};

export default RecipeDetails;
