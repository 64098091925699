import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const productAnalyticsServices = {
	gateway,
	findProductImagesById,
};

function gateway({ req, method }) {
	switch (method) {
		case 'FIND_PRODUCT_IMAGES':
			return findProductImages(req);
		case 'FETCH_PRODUCT_IMAGES_BY_ID':
			return findProductImagesById(req);
		case 'SKU_STOCK':
			return skuStock(req);
		default:
			break;
	}
	return new Promise();
}

function findProductImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_PRODUCT_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

// function mockFetch1() {
// const mockResponse = {
// data: [
// {
// Annotation: [
// {
// brandId: 89,
// customerId: 5,
// height: 214,
// id: 25,
// image: null,
// label: 'Defect B',
// labelId: 2,
// productId: 519,
// qcId: 2640,
// width: 219,
// x: 290,
// y: 237,
// },
// {
// brandId: 23,
// customerId: 5,
// height: 180,
// id: 27,
// image: null,
// label: 'Defect B',
// labelId: 2,
// productId: 510,
// qcId: 2406,
// width: 272,
// x: 225,
// y: 220,
// },
// {
// brandId: 23,
// customerId: 5,
// height: 164,
// id: 28,
// image: null,
// label: 'Defect B',
// labelId: 2,
// productId: 510,
// qcId: 2410,
// width: 141,
// x: 326,
// y: 187,
// },
// {
// brandId: 89,
// customerId: 5,
// height: 154,
// id: 38,
// image: '5/QualityCheck/Annotated/d589fd9d-2d69-4589-8df0-0e9567c82d02_raw_1.jpg',
// label: 'Defect B',
// labelId: 2,
// productId: 519,
// qcId: 2712,
// width: 239,
// x: 298,
// y: 196,
// },
// {
// brandId: 89,
// customerId: 5,
// height: 168,
// id: 39,
// image: '5/QualityCheck/Annotated/d589fd9d-2d69-4589-8df0-0e9567c82d02_raw_2.jpg',
// label: 'Defect B',
// labelId: 2,
// productId: 519,
// qcId: 2712,
// width: 220,
// x: 402,
// y: 297,
// },
// ],
// Brand: null,
// LabelsMapping: [],
// allProducts: true,
// brandId: 89,
// customerId: 5,
// id: 2,
// inventory: true,
// isDeleted: false,
// label: 'Defect B',
// quality: true,
// },
// {
// Annotation: [
// {
// brandId: 89,
// customerId: 5,
// height: 301,
// id: 26,
// image: null,
// label: 'Defect C',
// labelId: 4,
// productId: 523,
// qcId: 2645,
// width: 248,
// x: 320,
// y: 235,
// },
// {
// brandId: 89,
// customerId: 5,
// height: 242,
// id: 29,
// image: null,
// label: 'Defect C',
// labelId: 4,
// productId: 519,
// qcId: 2641,
// width: 213,
// x: 222,
// y: 222,
// },
// {
// brandId: 89,
// customerId: 5,
// height: 242,
// id: 40,
// image: null,
// label: 'Defect C',
// labelId: 4,
// productId: 519,
// qcId: 2641,
// width: 213,
// x: 222,
// y: 222,
// },
// ],
// Brand: null,
// LabelsMapping: [],
// allProducts: true,
// brandId: 23,
// customerId: 5,
// id: 4,
// inventory: false,
// isDeleted: false,
// label: 'Defect C',
// quality: true,
// },
// ],
// message: 'Fetched successfully',
// status: 'Success',
// statusCode: 200,
// };
//
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function findProductImagesById(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_PRODUCT_IMAGES_BY_ID, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions).then(handleResponse);
}

// function mockFetch2() {
// const mockResponse = {
// data: [
// {
// brandId: 89,
// customerId: 5,
// height: 103,
// id: 69,
// image: 'https://resoee.s3.amazonaws.com/5/QualityCheck/Annotated/090536f4-f40d-4f7d-b688-d4521fc4daa5_raw_5.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220613%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220613T115319Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=512780a47e9c5516e57d6583762a5ce7fd943b0fda9478bee9ab216073fb6156',
// label: 'Defect B',
// labelId: 2,
// productId: 530,
// qcId: 2722,
// width: 39,
// x: 39,
// y: 112,
// },
// ],
// message: 'Fetched successfully',
// pages: 2,
// status: 'Failed',
// statusCode: 200,
// mock: true,
// };
//
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

function skuStock() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.SKU_STOCK, requestOptions).then(handleResponse);
}

export default productAnalyticsServices;
