/* eslint-disable no-tabs */
import { Add, DeleteForever } from '@mui/icons-material';
import {
	Button, IconButton, InputAdornment, TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	createTheme, ThemeProvider, useTheme
} from '@mui/material/styles';
import { formActions, modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ReportEmailsModal.module.scss';
import { notificationServices } from '../../../Services';

const customTheme = (outerTheme) => {
	return createTheme({
		palette: {
			mode: outerTheme.palette.mode,
		},
		components: {
			MuiTextField: {
				styleOverrides: {
					root: {
						borderRadius: '50px',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '50px',
					},
				},
			},
		},
	});
};

const ReportEmailsModal = (props) => {
	const outerTheme = useTheme();
	const { show, toggleModal, data } = props;
	const [state, setState] = useState([]);
	const [newEmail, setNewEmail] = useState('');

	useEffect(() => {
		setState(data?.item?.emails ?? []);
	}, [data]);

	const handleClose = () => {
		toggleModal(false);
	};
	const handleConfirm = () => {
		props?.handleConfirm({
			reportId: data?.item?.id,
			emails: state,
		});
		toggleModal(false);
		setState([]);
	};

	const renderHeading = () => {
		return (
			<div className={styles.heading}>
				<p>Add/Edit Emails of Report:</p>
				<p>{data?.item?.reportName ?? '-'}</p>
			</div>
		);
	};

	const handleAddEmailChange = (e) => {
		setNewEmail(e?.target?.value);
	};

	const handleAdd = () => {
		// eslint-disable-next-line no-useless-escape
		const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
		if (!newEmail) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please type a email address to add',
			});
		} else if (!regex.test(newEmail)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Invalid Email Address',
			});
		} else if (state?.includes(newEmail)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Email Address already added',
			});
		} else {
			setState((prev) => {
				return [newEmail, ...prev];
			});
			setNewEmail('');
		}
	};

	const handleDelete = (mail) => {
		setState((prev) => {
			return prev?.filter((ele) => {
				return ele !== mail;
			});
		});
	};

	const renderBtns = () => {
		return data?.item?.isReportActive && (
			<div className={styles.btns}>
				<Button
					onClick={handleConfirm}
					variant='contained'
					className={styles.btn}
				>
					Confirm
				</Button>
			</div>
		);
	};

	return (
		<BaseModal
			heading={renderHeading()}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
			renderBtns={renderBtns}
			width='25rem'
			height='40rem'
			customStyles={{
				borderRadius: 0,
				padding: '0.5rem',
			}}
		>
			<div className={`${styles.root}`}>
				{data?.item?.isReportActive ? (
					<ThemeProvider theme={customTheme(outerTheme)}>
						<TextField
							value={newEmail}
							label='Add New Email'
							fullWidth
							onChange={handleAddEmailChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={handleAdd} className={styles['add-btn']} edge='end' color='primary'>
											<Add />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</ThemeProvider>
				) : (
					<div className={styles['enable-text']}>
						<p>Please Enable the report for adding/editing emails</p>
					</div>
				)}
				<div className={styles['mails-container']}>
					{data?.item?.isReportActive && !state?.length > 0 && (
						<div>
							No Email added
						</div>
					)}
					{state?.map((ele) => {
						return (
							<div key={ele} className={styles.email}>
								{ele}
								{data?.item?.isReportActive && (
									<IconButton
										onClick={() => {
											return handleDelete(ele);
										}}
										className={styles['remove-button']}
									>
										<DeleteForever className={styles.icon} />
									</IconButton>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.reportEmailsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleReportEmailsModal,
	fetchMenus: formActions.gateway,
	updateMenu: formActions.gateway,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportEmailsModal);
