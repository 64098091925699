/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Button, IconButton, MenuItem, TextField } from '@mui/material';
import { useEffect, useReducer, useState, useMemo } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './InvoiceCreatePage.module.scss';
import config from './invoiceCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../Services';
import { ListModal } from '../../../Components/Modals';
import { formActions, modalActions } from '../../../Actions';
import licenseServices from '../../../Services/license-services';
import BackdropLoader from '../../../Components/Loaders/BackdropLoader';
import { getStateFromGSTIN, inputHelper, useQuery } from '../../../Helpers';
import myMachinesServices from '../../../Services/AssetServices/myMachine.service';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import { POItemSelection } from '../../../Components/POItemSelection';
import CustomInputSelector from '../../../Components/CustomInputSelector/CustomInputSelector';
import { IconInput } from '../../../Components/Forms/Inputs';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { POTotalContainer } from '../../../Components/POTotalContainer';

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		inputLabel,
		labelText,
		mandatory,
		optionsList,
	} = props;
	return (
		<div className={`${styles.tile} form-field row`}>
			<label
				className={`${mandatory ? styles.mandatory : ''} form-label`}
				htmlFor={id}
			>
				{labelText}
			</label>
			<TextField
				id={id}
				select
				name={name}
				value={value}
				onChange={onChange}
				label={inputLabel}
				sx={{
					width: 225,
					'.MuiInputBase-root': {
						borderRadius: '4px',
					},
				}}
			>
				{optionsList?.map((option) => {
					return (
						<MenuItem
							key={option?.id ?? option}
							value={option?.id ?? option}
						>
							{option?.title ?? option}
						</MenuItem>
					);
				})}
			</TextField>
		</div>
	);
};

function InvoiceCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		processTypes,
		fetchProcessTypesList,
		fetchProducts,
		productsList,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const {
		fromStore,
		toStore,
		store,
		invoiceDate,
		dueDate,
		clientInvoiceId,
		orderId,
	} = state ?? {};
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const poId = Number(params.get('poId'));
	const [orderItems, setOrderItems] = useState([]);
	// const orderId = parseInt(params.get('orderId'), 10);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (poId && productsList?.length > 0 && stores?.length > 0) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.fetchPo(
						Number(poId)
					);
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								// items: response?.data?.map((item) => {
								// return {

								// };
								// }),
								toStore: stores?.find((ele) => {
									return ele?.id === response?.data?.storeId;
								}),
								fromStore: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								orderId: response?.data?.id,
							},
						});
						setOrderItems(response?.data?.items);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [poId, productsList, stores]);

	useEffect(() => {
		if (store) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: store,
					},
				},
				method: 'LIST',
			});
		}
	}, [store]);

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer(state);
		const validate = config?.validator(deserializedData);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
				type: 'BILL',
			},
		};
		setLoading(true);
		try {
			const response = await inventoryAnalyticsServices.createTransactionRecord(
				req
			);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const setItemsState = (items) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				items,
			},
		});
	};

	const PRODUCTS = useMemo(() => {
		return productsList?.filter((product) => {
			return (
				product?.skuType === 'INGREDIENT' ||
				product?.skuType === 'INTERMEDIATE' ||
				product?.skuType === 'PRODUCT'
			);
		});
	}, [productsList]);

	const handleKeyPress = (e) => {
		const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			// setState(`SET_${key}`, {});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					[key]: {},
				},
			});
		}
	};

	const handleToStore = (newToStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				toStore: newToStore,
			},
		});
	};
	const handleFromStore = (newFromStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				fromStore: newFromStore,
			},
		});
	};

	const disabledDate = (rsuiteDate) => {
		return rsuiteDate > new Date();
	};

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalAmount = 0;
		let taxValues = {};
		const totalQuantity = state?.items?.length;
		state?.items?.forEach((ele) => {
			if (ele?.unit === 'gram') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
							ele?.selectedVariant?.price * (ele?.quantity / 1000)
					  );
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 1000);
			} else if (ele?.unit === 'oz') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
							ele?.selectedVariant?.price * (ele?.quantity / 16)
					  );
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 16);
			} else {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(ele?.selectedVariant?.price * ele?.quantity);
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
			}
		});
		taxValues.SGST = totalTax / 2;
		taxValues.CGST = totalTax / 2;
		if (
			state?.vendor?.id &&
			state?.vendor?.gstin &&
			state?.deliverTo?.gstNumber
		) {
			const vendorState = getStateFromGSTIN(state?.vendor?.gstin);
			const storeState = getStateFromGSTIN(state?.deliverTo?.gstNumber);
			if (vendorState !== storeState) {
				taxValues = {
					IGST: totalTax,
				};
			}
		}
		return {
			subTotal,
			totalQuantity,
			totalTax,
			totalAmount,
			taxValues,
		};
	}, [state?.items]);
	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={loading} showProgress />
			{/* <ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/> */}
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>
						Add New Invoice {orderId ? `for ${orderId}` : ''}
					</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						To Store*
					</label>
					<CustomInputSelector
						inputName='toStore'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleKeyPress={handleKeyPress}
						handleSelection={handleToStore}
						hideAddNew
						placeholder='Select To Store'
						selectedValue={toStore?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						By Store*
					</label>
					<CustomInputSelector
						inputName='fromStore'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleKeyPress={handleKeyPress}
						handleSelection={handleFromStore}
						hideAddNew
						placeholder='Select By Store'
						selectedValue={fromStore?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Client Invoice ID*
					</label>
					<IconInput
						type='custom'
						iconText='INV-'
						size='extra-large'
						inputType='text'
						value={clientInvoiceId}
						handleInput={(e) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									clientInvoiceId: e?.target?.value,
								},
							});
						}}
						style={{
							width: '200px',
						}}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className='form-label'
						// htmlFor={id}
					>
						Order ID
					</label>
					<div className={styles['order-id']}>{orderId}</div>
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Invoice Date*
						</label>
						<DatePickerCustom
							selectedDate={invoiceDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										invoiceDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 200,
							}}
							oneTap
							defaultValue={invoiceDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Due Date
						</label>
						<DatePickerCustom
							selectedDate={dueDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										dueDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 200,
							}}
							oneTap
							defaultValue={dueDate}
							shouldDisableDate={(dates) => {
								return (
									dates >
									new Date().setDate(new Date().getDate() + 1)
								);
							}}
						/>
					</div>
				</div>
			</div>
			<div className={styles['items-details-container']}>
				<POItemSelection
					state={state?.items}
					setState={setItemsState}
					defaultSelectedItems={orderItems}
					productsList={PRODUCTS}
					requiredFields={{
						fromStore: true,
						deliverTo: true,
						poId: true,
						date: true,
						expectedDeliveryDate: true,
						paymentTerms: false,
						generateStandardPO: false,
					}}
					disabled={false}
				/>
				<POTotalContainer calculatedData={calculatedData} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;

	return {
		userPermissions,
		stores,
		processTypes,
		productsList,
	};
};
// ['PRODUCT', 'INTERMEDIATE']

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCreatePage);
