/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const myFranchiseeServices = {
	gateway,
	listCustomers,
	viewCustomer,
};
function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewCustomer({
				req,
			});
		case 'LIST':
			return listCustomers({
				req,
			});
		case 'UPDATE':
			return updateFranchise({
				req,
			});
		case 'CREATE':
			return createFranchise({
				req,
			});
		case 'DELETE':
			return deleteFranchise({
				req,
			});
		case 'PRINT':
			return printFranchiseeData({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function viewCustomer({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.MY_FRANCHISEE, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listCustomers({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.MY_FRANCHISEE, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function updateFranchise({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.MY_FRANCHISEE, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req.data),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function createFranchise({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.MY_FRANCHISEE, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req.data),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function printFranchiseeData({ req }) {
	const url = endpoints.SEND_NOTIFICATION;

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function deleteFranchise({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.MY_FRANCHISEE, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
export default myFranchiseeServices;
