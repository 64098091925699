/* eslint-disable no-tabs */
import { Switch } from '@mui/material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import SliderDiscount from './SliderDiscount/SliderDiscount';
import Htabs from './Htabs/Htabs';
import styles from './SubCategoryAvailibility.module.scss';
import { generateCheckedProps, generateRandomKey } from '../../../Helpers';
import ProductCard from '../ProductCard';

const SubCategoryAvailibility = (props) => {
	const {
		activeCategory,
		handleSubCategoryReOrdering,
		setActiveSubCategory,
		addNewSubCategory,
		activeSubCategory,
		fields,
		isDisabled,
		handleDelete,
		openTimeSlots,
		handleSliderChange,
		selectedProducts,
		products,
		handleSwitchChange,
		subCategoryDetails,
		handleInput,
		MakeUnAvailable,
	} = props;
	const handleChange = () => {
		handleInput('subCategories', subCategoryDetails.id);
	};
	return (
		<div className={`${styles.root}`}>
			<Htabs
				handleReorder={handleSubCategoryReOrdering}
				items={
					fields.categories.items[activeCategory].subCategories.items
				}
				order={
					fields.categories.items[activeCategory].subCategories.order
				}
				handleActiveTab={setActiveSubCategory}
				handleAddTab={addNewSubCategory}
				activeTab={activeSubCategory}
				limit={5}
				text='Sub Category'
				isDisabled
				isDraggable={false}
			/>
			<div className={`${styles.right}`}>
				{/* sub category name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='sub-category-name'
						// data-mandatory={
						// 	mandatoryFields.categories[0].subCategories.name
						// }
					>
						{subCategoryDetails.name}
					</label>
					<Switch
						color='primary'
						size='small'
						className={`${styles.switch}`}
						name='default'
						{...generateCheckedProps(
							!MakeUnAvailable.subCategories.includes(
								subCategoryDetails.id
							)
						)}
						{...generateRandomKey()}
						onChange={handleChange}
						inputProps={{
							'data-id': 'idx',
							'data-type': 'choice',
						}}
					/>
				</div>
				<div className={`${styles['product-wrapper']}`}>
					{selectedProducts.map((productObj, idx) => {
						const product =
							_.get(products, [productObj.productId]) || {};
						return (
							<ProductCard
								title={product.productName}
								img={product.productImageUrl}
								key={`${productObj.productId}`}
								idx={idx}
								handleDelete={handleDelete}
								dataAttrs={{
									'data-type': 'product',
									'data-id': idx,
									'data-parent': activeSubCategory,
								}}
								isDisabled
							>
								<SliderDiscount
									idx={productObj.productId}
									product={product}
									handleSliderChange={handleSliderChange}
									data-type='product'
									data-id={productObj.productId}
									data-parent={activeSubCategory}
									productObj={productObj}
									openTimeSlots={openTimeSlots}
									handleInput={handleInput}
									handleSwitchChange={handleSwitchChange}
									isDisabled={isDisabled}
									MakeUnAvailable={MakeUnAvailable}
								/>
							</ProductCard>
						);
					})}
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { products } = state.product;
	return {
		products,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		toggleProducts: (show) => {
			dispatch(modalActions.toggleProducts(show));
		},
	};
};
SubCategoryAvailibility.defaultProps = {
	isDisabled: false,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubCategoryAvailibility);
