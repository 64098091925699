/* eslint-disable indent */
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
	getStateCodeFromGSTIN,
	isEmpty,
	isObject,
	paymentModes,
} from '../../../Helpers';

const paymentTypes = [
	{
		value: 'Expense',
		displayName: 'Expense',
	},
	{
		value: 'Income',
		displayName: 'Income',
	},
];

const inputsList = [
	{
		value: paymentTypes[0],
		onChange: () => {},
		id: 'paymentType',
		name: 'paymentType',
		labelText: 'Payment Type',
		mandatory: true,
		width: '250px',
		inputType: 'toggleSelector',
		options: paymentTypes,
	},
	{
		value: null,
		width: '250px',
		handleSelection: () => {},
		id: 'store',
		name: 'store',
		inputLabel: 'Select Store',
		placeholder: 'Select A Store',
		labelText: 'Store',
		mandatory: true,
		optionsList: [],
		inputType: 'options_search',
		hideAddNew: true,
	},
	{
		value: paymentModes[0],
		onChange: () => {},
		id: 'paymentMode',
		name: 'paymentMode',
		labelText: 'Payment Mode',
		inputLabel: 'Select Payment mode',
		mandatory: true,
		width: '250px',
		inputType: 'options',
		optionsList: paymentModes,
	},
	{
		value: null,
		width: '250px',
		handleSelection: () => {},
		id: 'vendor',
		name: 'vendor',
		inputLabel: 'Select Vendor',
		labelText: 'Vendor Name',
		placeholder: 'Select A Vendor',
		mandatory: true,
		optionsList: [],
		inputType: 'options_search',
		hideAddNew: true,
	},
	{
		value: null,
		width: '250px',
		onChange: () => {},
		id: 'clientTransactionId',
		name: 'clientTransactionId',
		labelText: 'Expense/Income ID',
		placeholder: 'Client Petty Cash ID',
		prefix: 'AH-',
		mandatory: true,
		type: 'text',
	},
	{
		value: null,
		onChange: () => {},
		id: 'orderNumber',
		name: 'orderNumber',
		labelText: 'Bill Ref. Number',
		mandatory: true,
		width: '250px',
	},
	[
		{
			value: null,
			onChange: () => {},
			id: 'orderDate',
			name: 'orderDate',
			labelText: 'Transaction Date',
			mandatory: true,
			inputType: 'dateSelector',
			width: '250px',
		},
		{
			value: null,
			onChange: () => {},
			id: 'dueDate',
			name: 'dueDate',
			labelText: 'Due Date',
			mandatory: true,
			inputType: 'dateSelector',
			width: '250px',
		},
	],
	[
		{
			value: null,
			onChange: () => {},
			id: 'category',
			name: 'category',
			labelText: 'Select Category',
			inputLabel: 'Category',
			mandatory: true,
			width: '250px',
			inputType: 'options',
			optionsList: [],
		},
		{
			value: {},
			id: 'uploadedDoc',
			name: 'uploadedDoc',
			labelText: 'Upload Bill Ref. Doc',
			mandatory: true,
			// width: '250px',
			inputType: 'docUploader',
		},
	],
	{
		value: null,
		onChange: () => {},
		id: 'taxSlab',
		name: 'taxSlab',
		labelText: 'Tax slab',
		inputLabel: 'Select tax slab',
		mandatory: true,
		width: '250px',
		inputType: 'options',
		optionsList: [],
	},
	[
		{
			value: null,
			onChange: () => {},
			id: 'amount',
			name: 'amount',
			labelText: 'Amount Inc. Tax \u20B9',
			mandatory: true,
			width: '250px',
		},
		{
			value: null,
			onChange: () => {},
			id: 'description',
			name: 'description',
			labelText: 'Description',
			placeholder: 'Enter Description',
			mandatory: true,
			inputType: 'textArea',
			width: 250,
		},
	],
	{
		inputType: 'customComponent',
		component: null,
	},
	// {
	// value: null,
	// id: 'paymentTerm',
	// name: 'paymentTerm',
	// labelText: 'Payment Term',
	// mandatory: true,
	// inputType: 'paymentTerm',
	// },
];

const initialState = {
	paymentType: '',
	store: {},
	paymentMode: paymentModes[0],
	vendor: {},
	refNo: null,
	orderDate: new Date(),
	dueDate: new Date(),
	description: '',
	amount: '',
	uploadedDoc: {},
	clientTransactionId: '',
	paymentTerm: '',
	taxSlab: null,
	alreadyPaid: {
		isPaid: null,
		paymentMode: paymentModes[0],
		uploadedDocFileName: '',
		uploadedDocFile: '',
		transactionRefNumber: '',
	},
};

const reducer = (state, action) => {
	switch (action?.type) {
		case 'SET_VENDOR':
			return {
				...state,
				vendor: action?.payload,
			};
		case 'SET_ORDER_DATE':
			return {
				...state,
				orderDate: action?.payload,
			};
		case 'SET_DUE_DATE':
			return {
				...state,
				dueDate: action?.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action?.payload,
			};
		case 'SET_REF_NO':
			return {
				...state,
				refNo: action?.payload,
			};
		case 'SET_PAYMENT_MODE':
			return {
				...state,
				paymentMode: action?.payload,
			};
		case 'SET_TAX_SLAB':
			return {
				...state,
				taxSlab: action?.payload,
			};
		case 'SET_PAYMENT_TYPE':
			return {
				...state,
				paymentType: action?.payload,
			};
		case 'SET_CATEGORY':
			return {
				...state,
				category: action?.payload,
			};
		case 'SET_AMOUNT':
			return {
				...state,
				amount: action?.payload,
			};
		case 'SET_UPLOADED_DOC':
			return {
				...state,
				uploadedDoc: action?.payload,
			};
		case 'SET_DESCRIPTION':
			return {
				...state,
				description: action?.payload,
			};
		case 'SET_PETTY_CASH_ID':
			return {
				...state,
				clientTransactionId: action?.payload,
			};
		case 'SET_ALREADY_PAID':
			return {
				...state,
				alreadyPaid: action?.payload,
			};
		// case 'SET_PAYMENT_TERM':
		// return {
		// ...state,ssz
		// paymentTerm: action?.payload,
		// };
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const deserializer = (state) => {
	const clonedState = cloneDeep(state);
	const { ...remaining } = clonedState;
	const { paymentType } = remaining;
	const vendorGst = remaining?.vendor?.gstin;
	const storeGst = remaining?.store?.gstNumber;
	const isSameState =
		getStateCodeFromGSTIN(vendorGst) === getStateCodeFromGSTIN(storeGst);
	const isMandatoryTaxSlab = vendorGst?.length > 0 && storeGst?.length > 0;

	const newState = {
		transactionDate: moment(remaining?.orderDate)?.format('M/D/YYYY'),
		dueDate: moment(remaining?.dueDate)?.format('M/D/YYYY'),
		storeId: remaining?.store?.id,
		storeName: remaining?.store?.name,
		vendorId:
			remaining?.vendor?.id === undefined ? null : remaining?.vendor?.id,
		vendorName: remaining?.vendor?.name,
		category: remaining?.category?.value,
		type: 'BILL',
		subType: remaining?.paymentType?.toUpperCase(),
		finalTransactionAmount: remaining?.amount,
		uploadedDoc: remaining?.uploadedDoc?.file,
		clientTransactionId: `AH-${remaining?.clientTransactionId}`,
		transactionStatus: 'APPROVAL_PENDING_MANAGER',
		paymentDoc: remaining?.alreadyPaid?.uploadedDocFile,
		description: remaining?.description,
		extraData: {
			referenceNumber:
				paymentType === 'Income' ? undefined : remaining?.refNo,
			paymentMode:
				remaining?.paymentType === 'Expense'
					? remaining?.alreadyPaid?.paymentMode
					: remaining?.paymentMode,
			paymentType: remaining?.paymentType,
			isPaid: remaining?.alreadyPaid?.isPaid,
			taxSlab: paymentType !== 'Income' ? remaining?.taxSlab : undefined,
			...(remaining?.taxSlab &&
				paymentType !== 'Income' &&
				isMandatoryTaxSlab && {
					taxSlab: remaining?.taxSlab,
					isSameState,
				}),
			paymentReferenceNumber:
				remaining?.alreadyPaid?.transactionRefNumber,
			uploadedDocFileName: remaining?.alreadyPaid?.uploadedDocFileName,
		},
	};
	return newState;
};

const pettyCashCreateConfig = {
	inputsList,
	initialState,
	reducer,
	deserializer,
	validator: (state) => {
		const cloned = cloneDeep(state);

		const vendorGst = cloned?.vendor?.gstin;
		const storeGst = cloned?.store?.gstNumber;
		const isMandatoryTaxSlab =
			cloned?.paymentType !== 'Income' &&
			vendorGst?.length > 0 &&
			storeGst?.length > 0;

		const mandatoryFields = [
			'store',
			'orderDate',
			'clientTransactionId',
			'amount',
			'category',
			'uploadedDoc',
			'paymentMode',
			'paymentType',
			...(isMandatoryTaxSlab ? ['taxSlab'] : []),
			'description',
			'refNo',
			'vendor',
			'alreadyPaid',
		];

		return mandatoryFields.some((field) => {
			const value = cloned[field];
			if (cloned?.paymentType === 'Expense' && field === 'alreadyPaid') {
				if (value?.isPaid === null) {
					return true;
				}
				if (value?.isPaid) {
					if (!value?.paymentMode) {
						return true;
					}
					// cash
					if (value?.paymentMode === paymentModes[1]) {
						return false;
					}
					if (!value?.transactionRefNumber) {
						return true;
					}
					if (!value?.uploadedDocFile) {
						return true;
					}
				}
			}
			if (
				cloned?.paymentType === 'Income' &&
				field === 'paymentMode' &&
				!value
			) return true;
			if (cloned?.paymentType === 'Income' && field === 'category') return false;
			if (cloned?.paymentType === 'Income' && field === 'refNo') return false;
			if (cloned?.paymentType === 'Income' && field === 'uploadedDoc') return false;
			if (field === 'uploadedDoc' && !value?.file) return true;
			if (
				typeof value === 'undefined' ||
				value === null ||
				value === '' ||
				(isObject(value) && isEmpty(value))
			) {
				return true;
			}
			// eslint-disable-next-line no-prototype-builtins
			if (
				isObject(value) &&
				// eslint-disable-next-line no-prototype-builtins
				value?.hasOwnProperty('id') &&
				value?.id !== null
			) {
				return false;
			}
			return false;
		});
	},
};

export default pettyCashCreateConfig;
