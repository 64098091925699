// eslint-disable-next-line object-curly-newline

import {
	Route, Switch, useRouteMatch
} from 'react-router-dom/cjs/react-router-dom.min';
import { urls } from '@/Constants';
import CrudGsttRoutes from './CurdGstRoute';

const getSubURL = (entity) => {
	const baseEntity = urls.GST;
	return entity.slice(baseEntity.length);
};
const GstRoute = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.GST_DETAILS)}`}>
				<CrudGsttRoutes />
			</Route>
		</Switch>
	);
};

export default GstRoute;
