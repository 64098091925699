import { connect } from 'react-redux';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import styles from './AltAlertModal.module.scss';

const AltAlertModal = (props) => {
	const { show, toggleModal, data } = props;
	const HEADING = `${data.heading ?? 'Alert'}`;

	const handleClose = () => {
		toggleModal(false);
	};
	return (
		<BaseModal
			className={styles.root}
			heading={HEADING}
			width='24rem'
			height='12rem'
			open={show}
			renderBtns={() => {}}
			handleClose={handleClose}
		>
			<span className={styles.message}>{data.message}</span>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { data, show } = state.modal.altAlertModal;
	return {
		data,
		show,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAltAlertModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AltAlertModal);
