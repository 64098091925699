/* eslint-disable */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './GRNsPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../Components/Loaders';
import ListTable from '../../Components/ListTable/ListTable';
import { modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { useAuth } from '../../Components/Auth/Login/auth';
import { urls } from '../../Constants';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'From Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'To Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View More',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	id: 'id',
	Vendor: 'vendorName',
	'From Store': 'fromStoreName',
	'To Store': 'storeName',
	'Order id': 'orderId',
	Status: 'status',
};

const FIND_FILTERS = ['store', 'vendor'];

function GRNsPage({
	userPermissions,
	createPath,
	togglePOItemsModal,
	navState,
	fetchUserList,
	userList,
}) {
	const history = useHistory();
	const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [grnsList, setGrnsList] = useState([]);
	const [update, setUpdate] = useState(0);
	const navRequisites = navState?.[urls.PURCHASE_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});

	// // userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });

	// const pageRole = navState['/analytics/po']?.submenu['/analytics/list/grn']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });

	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	const handleListFetch = async (requestOptions) => {
		setIsloading(true);
		try {
			const responseData =
		await inventoryAnalyticsServices.fetchInvTransRecord({
			req: {
				data: {
					filters: {
						fromStoreId: requestOptions?.store,
						vendors: requestOptions?.vendors,
						storeId: navSelectedStore?.value?.id,
					},
					type: 'GRN',
				},
			},
		});
			if (responseData?.statusCode === 200) {
				setGrnsList(responseData?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const viewMoreOptionsList = {
		'View Items': (item, cb) => {
			togglePOItemsModal(true, {
				heading: `Items of GRN #${item?.id}`,
				...item,
				excludedColumns: [
					'Packaged',
					'Dispatched',
					'Returned Quantity',
					'Received',
					'Fulfilled',
					'From Process',
					'PO Id',
					'Returned',
					'Quantity',
					'Used For',
				],
			});
			cb();
		},
		'View Bills': (item, cb) => {
			window.open(`${urls.EXTENSION}${urls.BILLS}?grnId=${item?.id}`);
			cb();
		},
		'View GRN': (item, cb) => {
			if (!item?.grnURL) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'No GRN File Generated',
				});
			} else {
				window.open(item?.grnURL, '_blank');
			}
			cb();
		},
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<POItemsModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='GRNs List'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					update={update}
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'GRN',
						handleAddClick,
					}}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={grnsList}
				// data={dummyData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				viewMoreOptionsList={viewMoreOptionsList}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state?.auth;
	return {
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(GRNsPage);
