/* eslint-disable no-console */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import { Button } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import {
	Select, SelectContent, SelectItem, SelectTrigger, SelectValue
} from '@/Components/ui/select';
import { modes, processTypes } from '@/Helpers';
import processFlowServices from '@/Services/Analytics Services/process-flow.services';
import { notificationServices } from '@/Services';
import { Checkbox } from '@/Components/ui/checkbox';

const emptyValue = -999;

const EditNodeModal = (props) => {
	const {
		show, toggleModal, data, storeId, immovableAssetsList, fetchNodes, stores,
	} = props;

	const [fetchSection, setFetchSection] = useState([]);
	const [head, setHeading] = useState('');
	const [section, setSection] = useState('');
	const [typeName, setType] = useState('');
	const [asset, setAsset] = useState('');
	const [mode, setMode] = useState('');
	const [nodeStore, setNodeStore] = useState('');
	const [isIndent, setIsIndent] = useState(false);

	useEffect(() => {
		if (data) {
			setHeading(data?.heading);
			setSection(data?.sectionId);
			setType(data?.type);
			setAsset(data?.asset);
			setMode(data?.defaultMode);
			setNodeStore(data?.nodeStore);
		}
	}, [data]);

	const handleClose = () => {
		toggleModal(false);
	};
	const nodeEdit = async () => {
		try {
			if (typeName === 'STORAGE') {
				if (!asset) {
					notificationServices.generateNotification({
						type: 'error',
						message: ' Asset is mandatory in storage type',
					});
					return;
		    	}
			}
			if (typeName === 'OPERATION') {
				if (!mode) {
					notificationServices.generateNotification({
						type: 'error',
						message: ' Default mode is mandatory in operation type',
					});
					return;
			 }
			}
			const response = await processFlowServices.nodeEdit({
				data: {
					nodeId: data?.id,
					sectionId: section,
					type: typeName,
					assetId: asset,
					defaultMode: mode,
					nodeStoreId: nodeStore === emptyValue ? null : nodeStore,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					message: response?.message,
					type: 'sucess',
				});
			}
		} catch (erorr) {
			console.log(erorr);
		}
		fetchNodes();
		setHeading(null);
		setSection(null);
		setType(null);
		setAsset(null);
		setMode(null);
		setNodeStore(null);
		toggleModal(null);
	};

	useEffect(() => {
		if (storeId) {
			fetchSections();
		}
	}, [storeId]);

	async function fetchSections() {
		try {
			const response = await processFlowServices?.fetchAvailableSections({
				params: {
					storeId,
				},
			});
			if (response?.statusCode === 200) {
				setFetchSection(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	}

	const renderBtns = () => {
		return (
			<div className='w-full flex justify-center gap-2'>
				<Button className='flex-1' variant='secondary' onClick={handleClose}>Cancel</Button>
				<Button className='flex-1 primary-btn' onClick={nodeEdit}>Save</Button>
			</div>
		);
	};

	return (
		<BaseModal
			heading='Edit Node'
			open={show}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width={data?.width || '40rem'}
			height={data?.height || '40rem'}
		>
			<div className='flex flex-col gap-4 p-2'>
				<div className='flex justify-start items-center  text-left '>
					<Label className='min-w-[150px]'>Heading</Label>
					<Input
						className='w-full'
						value={head}
						disabled
						onChange={(e) => setHeading(e.target.value)}
					/>
				</div>
				<div className='flex justify-start items-center text-left min-h-12'>
					<div className='flex min-w-[150px]  gap-1'>
						<Checkbox
							checked={isIndent}
							className='data-[state=checked]:bg-uy-color data-[state=checked]:text-black '
							onCheckedChange={(checked) => {
								setIsIndent(checked);
								if (!checked) {
									setNodeStore(null);
								}
							}}
						/>
						<Label className='min-w-[150px]'>Indent Store</Label>
					</div>
					{isIndent && (
						<Select
							onValueChange={(value) => {
								setNodeStore(value);
							}}
							value={nodeStore}
						>
							<SelectTrigger className='w-full'>
								<SelectValue />
							</SelectTrigger>
							<SelectContent className='z-[9999]'>
								<SelectItem value={emptyValue}>None</SelectItem>
								{stores?.filter((ele) => {
									return ele?.id !== storeId;
								}).map((ele) => {
									return (
										<SelectItem value={ele.id}>
											{ele.name}
										</SelectItem>
									);
								})}
							</SelectContent>
						</Select>
					)}
				</div>
				<div className='flex justify-start items-center text-left'>
					<Label className='min-w-[150px]'>Select Section</Label>
					<Select
						onValueChange={(value) => {
							setSection(value);
						}}
						value={section}
					>
						<SelectTrigger className='w-full'>
							<SelectValue />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							{fetchSection.map((ele) => {
								return (
									<SelectItem value={ele.id}>
										{ele.sectionName}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
				<div className='flex justify-start items-center  text-left'>
					<Label className='min-w-[150px] text-red-600'>Select Process Type *</Label>
					<Select
						onValueChange={(value) => {
							setType(value);
						}}
						value={typeName}
					>
						<SelectTrigger className='w-full'>
							<SelectValue />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							{processTypes.map((key) => {
								return (
									<SelectItem value={key.id}>
										{key?.name}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
				{typeName === 'STORAGE' && (
					<div className='flex justify-start items-center  text-left'>
						<Label className='min-w-[150px] text-red-600'>Select Asset *</Label>
						<Select
							value={asset}
							onValueChange={(value) => {
								setAsset(value);
							}}
						>
							<SelectTrigger className='w-full '>
								<SelectValue />
							</SelectTrigger>
							<SelectContent className='z-[9999]'>
								{immovableAssetsList.map((ele) => {
									return (
										<SelectItem value={ele.id}>
											{!ele?.name ? '-' : ele?.name}
											{' '}
											{ele?.assetType ? `(${ele?.assetType})` : ''}
										</SelectItem>
									);
								})}
							</SelectContent>
						</Select>
					</div>
				)}
				{typeName === 'OPERATION' && (
					<div className='flex justify-start items-center  text-left'>
						<Label className='min-w-[150px] text-red-600'>Default Mode *</Label>
						<Select
							onValueChange={(value) => {
								setMode(value);
							}}
							value={mode}
						>
							<SelectTrigger className='w-full'>
								<SelectValue />
							</SelectTrigger>
							<SelectContent className='z-[9999]'>
								{Object?.keys(modes).map((key) => {
									return (
										<SelectItem value={key}>
											{modes[key]}
										</SelectItem>
									);
								})}
							</SelectContent>
						</Select>
					</div>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.editNodeModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleEditNodeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNodeModal);
