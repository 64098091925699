import styles from './CustomDatesPicker.module.css';

const dates = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
	22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

function CustomDatesPicker({ selectedDates, handleSelectedDates, disabled }) {
	const handleDateSelection = (date) => {
		if (selectedDates?.includes(date)) {
			const newArr = selectedDates?.filter((selectedDate) => {
				return selectedDate !== date;
			});
			handleSelectedDates(newArr);
		} else {
			selectedDates?.push(date);
			handleSelectedDates(selectedDates);
		}
	};

	return (
		<div className={styles.root}>
			<div className={`${styles.dates}`}>
				{dates.map((date) => {
					return (
						<div
							// eslint-disable-next-line arrow-body-style
							onClick={() => {
								return !disabled && handleDateSelection(date);
							}}
							className={`${styles.date} ${disabled ? styles.disabled : ''}`}
							style={{
								backgroundColor:
									!disabled && selectedDates?.includes(date)
										? '#4a70fb'
										: '',
								color:
									!disabled && selectedDates?.includes(date)
										? '#ffffff'
										: '#000000',
							}}
						>
							{date}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default CustomDatesPicker;
