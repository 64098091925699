// eslint-disable-next-line object-curly-newline
import { Clear, Edit } from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import { Button, IconButton, Tooltip } from '@mui/material';
import { Stage, Layer } from 'react-konva';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { modalTypes } from '../../../Constants';
import { classes, useNoScroll } from '../../../Helpers';
import { BOX_COLORS } from '../../../Styles/colors';
import { AnnotationToolModal } from '../../Modals/AnnotationToolModal';
import ContainerModal from '../../Modals/ContainerModal/ContainerModal';
import RectLabel from '../RectLabel/RectLabel';
import styles from './BoxAnnotator.module.css';

const setBoxDimensions = (rect) => {
	return {
		width: rect?.width,
		height: rect?.height,
	};
};

const BoxAnnotator = (props) => {
	const {
		disabled,
		labels,
		limit,
		heading,
		subHeading,
		modalHeading,
		image,
		toggleModal,
		imageDimensionsOg,
		imageContainerRect,
		imageContainerRef,
		annotations,
		setAnnotations,
		newAnnotation,
		setNewAnnotation,
		allowEdit,
		allowDelete,
		identifiedBy,
		customLabelSelection,
		customCanvasStyle,
		customBoxClassName,
		cstmImgContainerClass,
		customBoxStyles,
		stageStyles,
	} = props;
	const { clientType } = JSON.parse(localStorage.getItem('appConfig'));
	const { enableScroll, disableScroll } = useNoScroll();

	const color = '#c3c3c3';

	const handleMouseDown = (event) => {
		const { x, y } = event.target.getStage().getPointerPosition();
		if (limit != null && annotations.length === limit) {
			const lastAnnotation = annotations?.[limit - 1] ?? {};
			setAnnotations(
				annotations.filter((ann, idx) => {
					return idx !== limit - 1;
				})
			);
			setNewAnnotation([
				{
					x,
					y,
					width: 0,
					height: 0,
					key: limit - 1,
					id: lastAnnotation?.id ?? null,
					labelId: lastAnnotation?.labelId,
					label: lastAnnotation?.label,
					class: lastAnnotation?.class,
					stroke: color,
					limit: true,
				},
			]);
		} else if (newAnnotation.length === 0) {
			setNewAnnotation([
				{
					x,
					y,
					width: 0,
					height: 0,
					key: null,
					id: null,
					labelId: null,
					label: null,
					class: null,
					stroke: color,
				},
			]);
		}
	};

	const handleTouchDown = (event) => {
		disableScroll();
		handleMouseDown(event);
	};

	const handleMouseUp = (event) => {
		if (newAnnotation.length === 1) {
			const lastAnnotation = newAnnotation[0];
			const colorFinal = BOX_COLORS[annotations.length];
			const sx = lastAnnotation.x;
			const sy = lastAnnotation.y;
			const { x, y } = event.target.getStage().getPointerPosition();
			const newAnnotations = [...annotations];
			let annotationToAdd = {};
			// if the new annotation has reached the limit
			if (lastAnnotation?.key != null && lastAnnotation?.limit === true) {
				annotationToAdd = {
					...lastAnnotation,
					x: sx,
					y: sy,
					width: x - sx,
					height: y - sy,
					key: (annotations?.[annotations.length - 1]?.key ?? 0) + 1,
					stroke: colorFinal,
				};
				newAnnotations.splice(limit - 1, 1, annotationToAdd);
			} else {
				annotationToAdd = {
					x: sx,
					y: sy,
					width: x - sx,
					height: y - sy,
					key: (annotations?.[annotations.length - 1]?.key ?? 0) + 1,
					id: null,
					labelId: null,
					label: null,
					class: null,
					stroke: colorFinal,
				};
				newAnnotations.splice(annotations.length, 0, annotationToAdd);
				if (customLabelSelection) {
					customLabelSelection(annotationToAdd.key);
				} else {
					toggleModal(true, {
						annotationKey: annotationToAdd.key,
					});
				}
			}
			setNewAnnotation([]);
			setAnnotations(newAnnotations);
		}
	};

	const handleTouchEnd = (event) => {
		enableScroll();
		handleMouseUp(event);
	};

	const handleMouseMove = (event) => {
		if (newAnnotation.length === 1) {
			const lastAnnotation = newAnnotation[0];
			const sx = lastAnnotation.x;
			const sy = lastAnnotation.y;
			const { x, y } = event.target.getStage().getPointerPosition();
			setNewAnnotation([
				{
					...lastAnnotation,
					x: sx,
					y: sy,
					width: x - sx,
					height: y - sy,
				},
			]);
		}
	};

	const annotationsToDraw = [...annotations, ...newAnnotation];

	const handleDelete = (key, ignore = false) => {
		if (!ignore) {
			const updatedAnnotations = annotations.filter((value) => {
				return key !== value.key;
			});
			setAnnotations(updatedAnnotations);
		}
	};

	const handleEdit = (annotationKey, labelId = null) => {
		if (customLabelSelection) {
			customLabelSelection(annotationKey, labelId);
		} else {
			toggleModal(true, {
				annotationKey,
				isEdit: true,
			});
		}
	};

	const handleSelection = ({ menuItem, annotationKey }) => {
		const newAnnotations = annotations.map((annotation) => {
			const tempAnnotation = annotation;
			if (tempAnnotation.key === annotationKey) {
				tempAnnotation.label = menuItem.label;
				tempAnnotation.labelId = menuItem.id;
				tempAnnotation.class = menuItem.key;
			}
			return tempAnnotation;
		});

		setAnnotations(newAnnotations);
	};

	const handleSubmit = () => {
		// eslint-disable-next-line react/destructuring-assignment
		props.handleSubmit(annotations, imageDimensionsOg, imageContainerRect);
	};

	return (
		<div className={classes(styles.root, customBoxClassName)}>
			<div
				className={classes(styles.overlay, disabled && clientType !== 'SCHOOL' ? styles.show : '')}
			>
				<span>Disabled for Tagging</span>
			</div>
			<AnnotationToolModal
				heading={modalHeading}
				labels={labels}
				handleConfirm={handleSelection}
				handleCancel={handleDelete}
			/>
			<ContainerModal
				heading={modalHeading}
				labels={labels}
				handleConfirm={handleSelection}
			/>
			<div
				style={customBoxStyles}
				className={classes(
					styles['image-container'],
					cstmImgContainerClass
				)}
				ref={imageContainerRef}
			>
				<Stage
					onTouchStart={handleTouchDown}
					onTouchEnd={handleTouchEnd}
					onTouchMove={handleMouseMove}
					onMouseDown={handleMouseDown}
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove}
					{...(stageStyles || setBoxDimensions(imageContainerRect))}
					style={
						customCanvasStyle ?? {
							backgroundImage: `url(${image})`,
							backgroundPosition: 'center',
							backgroundSize: '100% 100%',
							backgroundRepeat: 'no-repeat',
							maxWidth: '100%',
							position: 'absolute',
							inset: 0,
						}
					}
				>
					<Layer>
						{annotationsToDraw.map((value) => {
							return <RectLabel {...value} key={value.key} />;
						})}
					</Layer>
				</Stage>
			</div>
			<div className={classes(styles['labels-wrapper'])}>
				<p className={classes(styles.heading)}>{heading}</p>
				<p className={classes(styles['sub-heading'])}>{subHeading}</p>
				{identifiedBy && (
					<p className={classes(styles['sub-heading'])}>
						Identified By:
						{' '}
						{identifiedBy}
					</p>
				)}

				{annotationsToDraw.length === 0 ? (
					<p className={classes(styles.fallback)}>
						Drag mouse/finger on image to start annotating
					</p>
				) : (
					<>
						<div className={classes(styles.labels)}>
							{annotationsToDraw.map((value) => {
								return (
									<div
										className={classes(
											styles['label-container']
										)}
										key={value.key}
									>
										<span
											className={classes(
												styles['key-color']
											)}
											style={{
												background: value.stroke,
											}}
										/>
										<span
											className={classes(
												styles['label-name']
											)}
										>
											<span>
												{value.label ??
													'No label selected'}
											</span>
											<span
												className={classes(
													styles[
														'identification-type'
													],
													value.ai
														? styles.AI
														: styles.MN
												)}
											>
												{value.ai ? 'AI' : 'MN'}
											</span>
											<span className={classes()}>
												{value?.creatorName}
											</span>
										</span>
										<div
											className={styles['label-actions']}
										>
											{allowEdit && (
												<Tooltip
													title='Edit Label'
													placement='top'
												>
													<IconButton
														onClick={() => {
															handleEdit(
																value.key,
																value?.labelId
															);
														}}
														className={classes(
															styles.edit
														)}
														size='large'
													>
														<Edit />
													</IconButton>
												</Tooltip>
											)}
											{allowDelete && (
												<Tooltip
													title='Remove Annotation'
													placement='top'
												>
													<IconButton
														onClick={() => {
															handleDelete(
																value.key
															);
														}}
														className={classes(
															styles.delete
														)}
														size='large'
													>
														<Clear />
													</IconButton>
												</Tooltip>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
				<div className={classes(styles.footer)}>
					<Button
						variant='contained'
						color='primary'
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(
					modalTypes.ANNOTATION_LABELS,
					show,
					data
				)
			);
		},
	};
};

BoxAnnotator.defaultProps = {
	disabled: false,
	labels: [],
	limit: null,
	heading: 'Annotations',
	subHeading: '',
	modalHeading: 'Select Label',
	allowDelete: false,
	allowEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxAnnotator);
