import { connect } from 'react-redux';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { GeneralDetails } from '../../../Components/Forms/InventoryCategory/GeneralDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTab,
	StyledTabs,
	TabPanel,
} from '../../../Components/StyledTabs';
import {
	generateDisabledProps, history, isEmpty
} from '../../../Helpers';
/* eslint-disable linebreak-style */

import ProductsConfig from './InventoryCategory.config';

const TABS = ['GENERAL DETAILS'];

const InventoryCateogoryViewPage = (props) => {
	const { fetchProductCategory, data, deleteProductCategory, breadcrumbs } =
		props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(ProductsConfig.initialState.fields),
	});
	const ProductCategoryId = useRouteMatch().params.id;
	useEffect(() => {
		fetchProductCategory({
			entity: 'INVENTORY_CATEGORY',
			req: {
				params: {
					id: ProductCategoryId,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(ProductsConfig.serializer(state, data));
		}
	}, [data]);

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const { activeTab, fields } = state;

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return <GeneralDetails isDisabled fields={fields} />;
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteProductCategory({
			entity: 'INVENTORY_CATEGORY',
			req: {
				params: {
					id: ProductCategoryId,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};
	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Product Category'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${state.fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset row'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_CATEGORY.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProductCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryCateogoryViewPage);
