/* eslint-disable no-unused-vars */
import { Check, Crop } from '@mui/icons-material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	generateCheckedProps,
	generateDisabledProps,
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRandomKey,
	generateRequiredProps,
	inputHelper,
	isEmpty,
	objectContainsProperty,
	// options,
} from '../../../Helpers';
import { DropDownIconInput, IconInput } from '../Inputs';
import styles from './ProductDetails.module.scss';
import { ImageCropModal } from '../../Modals/ImageCropModal';
import { VariantCheckboxes } from '../Inputs/VariantCheckboxes';
import { useAuth } from '../../Auth/Login/auth';
import { brandActions, modalActions } from '@/Actions';

// const getDensityText = (unitsArr) => {
// const unit = unitsArr?.reduce((finalUnit, currUnit) => {
// if (currUnit?.isApplied) {
// return currUnit?.id;
// }
// return finalUnit;
// }, '');
// if (unit === 'litre') return 'per Litre';
// if (unit === 'floz') return 'per Fl oz';
// return '';
// };

// const perUnitOptions = {
// floz: 'gallon',
// // gallon: ['floz', 'gallon'],
// litre: 'litre',
// };

const ProductDetails = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		toggleImageCrop,
		handleImageInput,
		brands,
		generateDefaultOption,
		isDisabled,
		exclude,
		disableUnit,
		subCategoriesList,
		disableMsg,
		disablebox = false,
	} = props;

	useEffect(() => {
		props.fetchBrands({
			id: '',
			allData: true,
		});
	}, []);

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};
	const readOnly = () => {
		return fields.productType === 'COMBO';
	};
	const activeSkuType = fields.productClass?.find((ele) => {
		return ele?.isApplied;
	});
	const activeProductUnit = fields.productUnits?.find((ele) => {
		return ele?.isApplied;
	});
	return (
		<div
			className={`${styles.root}`}
		>
			<fieldset
				{...generateDisabledProps(isDisabled)}
				className='form-fieldset row'
				style={{
					alignItems: 'flex-start',
				}}
			>
				<div
					style={{
						flexBasis: '50%',
					}}
				>
					{/* product name */}
					{!objectContainsProperty(exclude, 'name') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='productName'
								data-mandatory={mandatoryFields.name}
							>
								Product Name
							</label>
							<input
								className='form-value'
								type='text'
								id='productName'
								name='name'
								onInput={handleInput}
								value={fields.name}
								{...generateRequiredProps(mandatoryFields.name)}
							/>
							<span className='form-error'>{errors.name}</span>
						</div>
					)}
					{/* description */}
					{!objectContainsProperty(exclude, 'description') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='description'
								data-mandatory={mandatoryFields.description}
							>
								Description
							</label>
							<textarea
								className='form-value'
								type='text'
								id='description'
								name='description'
								onInput={handleInput}
								value={fields.description}
								{...generateRequiredProps(
									mandatoryFields.description
								)}
							/>
							<span className='form-error'>
								{errors.description}
							</span>
						</div>
					)}
					{/* brand name */}
					{!objectContainsProperty(exclude, 'brand') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='brand'
								data-mandatory={mandatoryFields.brand}
							>
								Brand
							</label>
							<select
								className='form-value'
								id='brand'
								name='brand'
								onChange={handleInput}
								value={fields.brand}
								{...generateRequiredProps(
									mandatoryFields.brand
								)}
							>
								{generateDefaultOption(
									'brand',
									fields.brand,
									fields.category
								)}
								{Object.keys(brands).map((brandId) => {
									const brand = brands[brandId];
									return (
										<option value={brandId}>
											{brand.name}
										</option>
									);
								})}
								{/* <option value='1'>Default</option> */}
							</select>
							<span className='form-error'>{errors.brand}</span>
						</div>
					)}
					{/* category name */}
					{!objectContainsProperty(exclude, 'category') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='category'
								data-mandatory={mandatoryFields.category}
							>
								Category
							</label>
							<select
								className='form-value'
								id='category'
								name='category'
								onChange={handleInput}
								disabled={!fields.brand}
								value={fields.category}
								{...generateRequiredProps(
									mandatoryFields.category
								)}
							>
								{generateDefaultOption(
									'category',
									fields.brand,
									fields.category
								)}
								{fields.brand &&
									!isEmpty(brands) &&
									Object.keys(
										brands[fields.brand].ProductCategory
									)
										?.filter((categoryId) => {
											const category =
												brands[fields.brand]
													.ProductCategory[
														categoryId
													];
											return !category?.isDeleted;
										})
										.map((categoryId) => {
											const category =
												brands[fields.brand]
													.ProductCategory[
														categoryId
													];
											return (
												<option value={categoryId}>
													{
														category.productCategoryName
													}
												</option>
											);
										})}
							</select>
							<span className='form-error'>
								{errors.category}
							</span>
						</div>
					)}
					{/* subcategory name */}
					{!objectContainsProperty(exclude, 'subcategory') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='subcategory'
								data-mandatory={mandatoryFields.subCategory}
							>
								Sub Category
							</label>
							<select
								className='form-value'
								id='subcategory'
								name='subCategory'
								onChange={handleInput}
								// disabled={!fields.subCategory}
								value={fields.subCategory}
								{...generateRequiredProps(
									mandatoryFields.subCategory
								)}
							>
								{generateDefaultOption(
									'subCategory',
									fields.brand,
									fields.category,
									fields.subCategory
								)}
								{subCategoriesList?.map((subCat) => {
									return (
										<option value={subCat?.id}>
											{subCat?.name}
										</option>
									);
								})}
							</select>
							<span className='form-error'>
								{errors.subcategory}
							</span>
						</div>
					)}
					{/* price and tax */}
					{!objectContainsProperty(exclude, 'tax') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='tax'
								style={{
									width: 'auto',
									minWidth: 'auto',
								}}
								{...generateRequiredProps(mandatoryFields.tax)}
							>
								Tax
							</label>
							<IconInput
								size='large'
								type='percent'
								name='tax'
								style={{
									position: 'relative',
									left: '11rem',
								}}
								id='tax'
								step='any'
								value={fields.tax}
								handleInput={handleInput}
								dataType=''
								dataId=''
								{...generateRequiredProps(mandatoryFields.tax)}
								dataAttrs={{
									min: 0,
									required: mandatoryFields.tax,
									max: 100,
									disabled: readOnly(),
								}}
							/>
						</div>
					)}
					{/*  HSN Code */}
					{!objectContainsProperty(exclude, 'hsnCode') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='hsnCode'
								data-mandatory={mandatoryFields.hsnCode}
							>
								HSN Code
							</label>
							<input
								className='form-value'
								type='text'
								id='hsnCode'
								name='hsnCode'
								onInput={handleInput}
								value={fields.hsnCode}
								{...generateRequiredProps(mandatoryFields.hsnCode)}
							/>
							<span className='form-error'>{errors.hsnCode}</span>
						</div>
					)}
					{/* expiry */}
					{!objectContainsProperty(exclude, 'expiry') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='expires'
								data-mandatory={mandatoryFields.expiry}
							>
								Expires In
							</label>
							<DropDownIconInput
								size='large'
								type='custom'
								name='expiry'
								id='expiry'
								dataType=''
								dataId=''
								value={fields.expiry}
								handleInput={handleInput}
								selectValue={fields.expiryUnit}
								dataAttrs={{
									required: mandatoryFields.expiry,
								}}
							/>
						</div>
					)}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='minTemp'
						>
							Min Temperature (℃)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='℃'
							name='minTemp'
							id='minTemp'
							value={fields.minTemp}
							handleInput={handleInput}
							dataAttrs={{
								min: -20,
								max: Math.min(120, fields?.maxTemp),
								'data-type': 'temperature',
							}}
							step='0.1'
						/>
					</div>
					<div className='form-field row'>
						<label
							htmlFor='maxTemp'
							className='form-label'
						>
							Max Temperature (℃)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='℃'
							name='maxTemp'
							id='maxTemp'
							value={fields.maxTemp}
							handleInput={handleInput}
							dataAttrs={{
								min: Math.max(-20, fields?.minTemp),
								max: 120,
								'data-type': 'temperature',
							}}
							step='0.1'
						/>
						<span
							className='form-info'
							style={{
								margin: 'auto 0px',
								padding: '0px 1rem',
								fontSize: '12px',
							}}
						>
							(Permissible range for temperature is -20℃ to 120℃)
						</span>
					</div>
					{/* purchase unit */}
					<div className='relative w-full'>
						{/* Overlay for disable message */}
						{disableMsg && (
							<div className='w-full h-full absolute top-[-6px] left-0 flex items-center justify-center bg-white bg-opacity-50 cursor-not-allowed z-10'>
								<p className='text-red-800 text-xl text-center font-bold relative left-[18rem]'>{disableMsg}</p>
							</div>
						)}

						{/* Product Unit */}
						{!objectContainsProperty(exclude, 'productUnits') && (
							<div className='form-field row mt-12 relative'>
								<label className='form-label' data-mandatory={mandatoryFields.productUnit}>
									Inventory Unit
								</label>
								<VariantCheckboxes
									items={fields?.productUnits ?? []}
									fieldName='productUnits'
									handleInput={handleInput}
									isDisabled={disablebox ? true : disableUnit}
								/>
								<span className='form-error'>{errors.name}</span>
							</div>
						)}
						{/* Purchase Unit */}
						{!objectContainsProperty(exclude, 'purchaseUnits') && !['Product', 'Intermediate'].includes(activeSkuType?.name) && (
							<div className='form-field row mt-12 relative'>
								<label className='form-label' data-mandatory={mandatoryFields.purchaseUnits}>
									Purchase Unit
								</label>
								<VariantCheckboxes
									items={fields?.purchaseUnits ?? []}
									fieldName='purchaseUnits'
									handleInput={handleInput}
									isDisabled={disablebox ? true : isDisabled}
								/>
								<span className='form-error'>{errors.name}</span>
							</div>
						)}
					</div>

					{/* product Density */}
					{!objectContainsProperty(exclude, 'density') && activeProductUnit?.id === 'ml' && (
						<div
							className='form-field row'
							style={{
								marginTop: '3rem',
							}}
						>
							<label
								className='form-label'
								data-mandatory={mandatoryFields.density}
							>
								Product Density
							</label>
							<div className={styles.density}>
								<input
									className={`${styles.input} form-value`}
									type='number'
									id='density'
									name='density'
									onInput={handleInput}
									value={fields.density}
									// {...generateRequiredProps(
									// mandatoryFields.density
									// )}
								/>
								{/* <select
									className={`${styles['unit-selector']} form-value`}
									id='brand'
									name='densityUnit'
									onChange={handleInput}
									value={fields?.densityUnit}
								>
									{options?.[fields?.densityUnit]?.map(
										(option) => {
											return (
												<option value={option?.value}>
													{option?.displayName}
												</option>
											);
										}
									)}
								</select> */}
								<p>
									{fields?.densityUnit}
									{' '}
									per
								</p>
								<p>{fields?.perUnit}</p>
								{/* <select
									className={`${styles['unit-selector']} form-value`}
									id='brand'
									name='perUnit'
									onChange={handleInput}
									value={fields?.perUnit}
								>
									{perUnitOptions?.[fields?.perUnit]?.map(
										(option) => {
											return (
												<option value={option}>
													{option}
												</option>
											);
										}
									)}
								</select> */}
							</div>
							<span className='form-error'>{errors.name}</span>
						</div>
					)}
					{/* veg - nonveg */}
					{!objectContainsProperty(exclude, 'veg') && (
						<div className='form-field row'>
							<label
								htmlFor='customization'
								className='form-label'
								data-mandatory={mandatoryFields.veg}
							>
								Veg
							</label>
							<div className='row'>
								<label
									className={`dot-container veg ${
										fields.veg ? 'selected' : ''
									} ${isDisabled ? 'disabled' : ''}`}
									htmlFor='veg'
									style={{
										marginRight: '1rem',
									}}
								>
									<div className='dot' />
									<Check className='tick' />
								</label>
								<input
									{...generateRandomKey()}
									type='radio'
									id='veg'
									hidden
									name='veg'
									value={1}
									onChange={handleInput}
									{...generateCheckedProps(fields.veg)}
								/>
								<label
									className={`dot-container non-veg ${
										fields.veg ? '' : 'selected'
									} ${isDisabled ? 'disabled' : ''}`}
									htmlFor='non-veg'
								>
									<div className='dot' />
									<Check className='tick' />
								</label>
								<input
									{...generateRandomKey()}
									{...generateCheckedProps(!fields.veg)}
									type='radio'
									id='non-veg'
									hidden
									name='veg'
									value={0}
									onChange={handleInput}
								/>
							</div>
						</div>
					)}
					{/* product type */}
					{!objectContainsProperty(exclude, 'productClass') && (
						<div
							className='form-field row'
							style={{
								marginTop: '3rem',
								marginRight: '2rem',
							}}
						>
							<label
								className='form-label'
								data-mandatory={mandatoryFields.productClass}
							>
								Product Type
							</label>
							<VariantCheckboxes
								items={fields.productClass}
								fieldName='productClass'
								handleInput={handleInput}
								isDisabled={isDisabled}
							/>
							<span className='form-error'>{errors.name}</span>
						</div>
					)}
				</div>
				<div
					style={{
						marginLeft: '3rem',
						flexBasis: '50%',
					}}
				>
					{/* display image */}
					{!objectContainsProperty(exclude, 'image') && (
						<div
							className='form-field row'
							style={{
								alignItems: 'flex-start',
							}}
						>
							<label
								className='form-label'
								data-mandatory={mandatoryFields.image}
							>
								Display Image
							</label>
							<div className='display-pic-root'>
								<div className='display-pic-preview'>
									<img
										src={
											isDisabled
												? fields.image
												: generateImageUrl(fields.image)
										}
										className={`display-pic ${
											fields.image ? 'show' : 'hide'
										}`}
										alt='combo-display'
									/>
									{generateImageEditButton({
										isEdit:
											fields.image &&
											fields.image.includes(
												'data:image/jpeg;base64'
											),
										button: (
											<button
												type='button'
												onClick={() => {
													openImageCropModal('image');
												}}
												className='display-pic-edit'
											>
												<Crop />
											</button>
										),
									})}
								</div>
								{generateImageUploadButton({
									isDisabled,
									label: (
										<label
											className='display-pic-label'
											htmlFor='displayPic'
										>
											Choose an image
										</label>
									),
								})}
								<input
									type='file'
									id='displayPic'
									accept='image/png, image/jpg, image/jpeg, image/webp'
									name='image'
									onChange={handleImageSelect}
									{...generateRequiredProps(
										typeof fields.image !== 'string' &&
											mandatoryFields.image
									)}
									style={{
										opacity: 0,
										width: 1,
										height: 1,
									}}
								/>
							</div>
						</div>
					)}
					<ImageCropModal handleConfirm={handleImageInput} />
				</div>
			</fieldset>
		</div>
	);
};

ProductDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
	exclude: {},
	disableUnit: true,
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	fetchBrands: brandActions.fetchBrands,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
