/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button } from '@mui/material';
import AmountContainerDiv from '../../../../Components/Forms/Payment Settlements/AmountContainerDiv';
import { isEmpty } from '../../../../Helpers';
import styles from './StoreAnalysis.module.scss';
import storeAnalysisMetrics from './StoreAnalysis.config';
import filter from '../../../../Images/filter.svg';
import FilterModal from '../../../../Components/Filter Modal/FilterModal';
import { modalActions } from '../../../../Actions';
import analyticsAction from '../../../../Actions/analytics.actions';
import { Tag } from '../../../../Components/Tags';

const FILTERS = ['GEO_FILTER', 'BRAND_FILTER', 'TIME_FILTER'];
const StoreAnalysis = (props) => {
	const {
		data,
		toggleFilterModal,
		geoFilterData,
		geoData,
		setGeoData,
		brandFilterData,
		brandListData,
		setBrandFilterData,
		timeFilterData,
		fetchData,
	} = props;
	const [state, setState] = useState({
		...storeAnalysisMetrics.initialState,
	});
	useEffect(() => {
		if (!isEmpty(data)) {
			setState({
				...storeAnalysisMetrics.serializer(state, data),
			});
		}
	}, [data]);
	const onClickHandler = () => {
		toggleFilterModal(true);
	};
	const delStoreHandler = (storeData) => {
		const updatedStoreId = geoFilterData.store.filter((currStoreId) => {
			return parseInt(storeData.id, 10) !== currStoreId;
		});
		const currCityStores = Object.keys(
			geoData[parseInt(storeData.stateId, 10)].city[
				parseInt(storeData.cityId, 10)
			].store
		).filter((currStoreId) => {
			return geoFilterData.store.includes(parseInt(currStoreId, 10));
		});
		const updatedCityId =
			currCityStores.length > 1
				? geoFilterData.city
				: geoFilterData.city.filter((currCityId) => {
						return currCityId !== parseInt(storeData.cityId, 10);
				  });
		const currStateCity = Object.keys(
			geoData[parseInt(storeData.stateId, 10)].city
		).filter((currCityId) => {
			return geoFilterData.city.includes(parseInt(currCityId, 10));
		});
		const updatedStateId =
			currCityStores.length > 1 || currStateCity.length > 1
				? geoFilterData.state
				: geoFilterData.state.filter((currStateId) => {
						return parseInt(storeData.stateId, 10) !== currStateId;
				  });
		fetchData({
			entity: 'STORE_ANALYSIS',
			req: {
				data: {
					brandFilter: brandFilterData,
					timeFilter: timeFilterData,
					geoFilter: updatedStoreId,
				},
			},
		});
		setGeoData({
			state: updatedStateId,
			city: updatedCityId,
			store: updatedStoreId,
		});
	};
	const delBrandHandler = (brandData) => {
		const updatedBrandData = brandFilterData.filter((brandId) => {
			return brandId !== parseInt(brandData, 10);
		});
		fetchData({
			entity: 'STORE_ANALYSIS',
			req: {
				data: {
					brandFilter: updatedBrandData,
					timeFilter: timeFilterData,
					geoFilter: geoFilterData.store,
				},
			},
		});
		setBrandFilterData({
			selectedList: updatedBrandData,
		});
	};
	const getFilterData = () => {
		const filterData = geoFilterData.state.reduce(
			(accData, currStateId) => {
				const selectedStateCities = Object.keys(
					geoData[currStateId].city
				).filter((currCityId) => {
					return geoFilterData.city.includes(
						parseInt(currCityId, 10)
					);
				});
				const selectedCityStores = selectedStateCities.reduce(
					(accCityStores, currCityId) => {
						const currCityStores = Object.keys(
							geoData[currStateId].city[currCityId].store
						).reduce((accStoreData, currStoreId) => {
							if (
								geoFilterData.store.includes(
									parseInt(currStoreId, 10)
								)
							) {
								const nameAndId = {
									name: geoData[currStateId].city[currCityId]
										.store[currStoreId].name,
									id: currStoreId,
									cityId: currCityId,
									stateId: currStateId,
								};
								accStoreData.push(nameAndId);
							}
							return accStoreData;
						}, []);
						accCityStores.push(...currCityStores);
						return accCityStores;
					},
					[]
				);
				// eslint-disable-next-line no-param-reassign
				accData = [...accData, ...selectedCityStores];
				return accData;
			},
			[]
		);
		const destructuredBrandData = brandListData.filter((currBrandData) => {
			return brandFilterData.includes(currBrandData.id);
		});
		return (
			<>
				{filterData.map((storeData) => {
					return (
						<Tag
							handleDelete={() => {
								delStoreHandler(storeData);
							}}
							text={storeData.name}
						/>
						// <div className={`${styles['filter-data']}`} key={storeData.id}>
						// <IconButton onClick={() => { delStoreHandler(storeData); }} className={`${styles['cross-button']}`}>
						// <CancelIcon />
						// </IconButton>
						// {storeData.name}
						// </div>
					);
				})}
				{destructuredBrandData.map((brandData) => {
					return (
						<Tag
							handleDelete={() => {
								delBrandHandler(brandData.id);
							}}
							dataAttrs={{
								key: brandData.id,
							}}
							text={brandData.name}
						/>
						// <div className={`${styles['filter-data']}`} key={brandData.id}>
						// <IconButton onClick={() => { delBrandHandler(brandData.id); }} className={`${styles['cross-button']}`}>
						// <CancelIcon />
						// </IconButton>
						// {brandData.name}
						// </div>
					);
				})}
			</>
		);
	};
	useEffect(() => {
		getFilterData();
	}, [brandFilterData, geoFilterData]);
	return (
		<div className={`${styles.root}`}>
			<FilterModal filters={FILTERS} />
			<div className={`${styles['filter-container']}`}>
				<div className={`${styles['button-container']}`}>
					<Badge
						badgeContent={
							(geoFilterData?.store?.length || 0) +
							(brandFilterData?.length || 0)
						}
						color='primary'
					>
						<Button
							variant='outlined'
							className={`${styles['filter-button']}`}
							onClick={onClickHandler}
						>
							filter{' '}
							<img
								src={filter}
								alt='filter-icon'
								className={`${styles['filter-icon']}`}
							/>
						</Button>
					</Badge>
				</div>
				<div className={`${styles['filter-data-container']}`}>
					{getFilterData()}
				</div>
			</div>
			<div>
				<AmountContainerDiv
					amountCaption={state.fields.amountCaption}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.analytics.STORE_ANALYSIS.metrics;
	const geoData = state.geoData.GEO_DATA.data;
	const geoFilterData = state.analytics.GEO_FILTER;
	const brandFilterData = state.analytics.BRAND_FILTER.selectedList;
	const brandListData = state.form.BRANDS.data.LIST;
	const timeFilterData = state.analytics.TIME_FILTER;
	return {
		geoData,
		geoFilterData,
		data,
		brandFilterData,
		brandListData,
		timeFilterData,
	};
};
const mapDispatchToProps = {
	toggleFilterModal: modalActions.toggleFilterModal,
	setBrandFilterData: analyticsAction.setBrandFilterData,
	setGeoData: analyticsAction.setGeoData,
	fetchData: analyticsAction.gateway,
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreAnalysis);
