import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const { fields } = props;
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* product name */}
				<div className='form-field row'>
					<div className='form-label'>Product Name</div>
					<div className='form-value'>
						{fields.product.productName}
					</div>
				</div>
				{/* brand name */}
				<div className='form-field row'>
					<div className='form-label'>Brand Name</div>
					<div className='form-value'>{fields.product.brandName}</div>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* display image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<span className='form-label'>Display Image</span>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={fields.product.productImageUrl}
								className={`display-pic ${
									fields.product.productImageUrl
										? 'show'
										: 'hide'
								}`}
								alt='product-display'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	fields: {},
};

export default GeneralDetails;
