// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router';
import { urls } from '../../Constants';
import { IMSAnnotationPage, IMSPage } from '../../Pages';
import InventoryConfigRoutes from './Inventory Config/inventory-config.routes';
import InventorySettingRoutes from './Inventory Setting Routes/inventorySetting.routes';
import InventoryCategoryRoutes from './InventoryCategory Routes/inventoryCategory.routes';
import InventorySkuRoutes from './InventorySku Routes/inventorysku.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.INVENTORY;
	return entity.slice(baseEntity.length);
};
const InventoryRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.INVENTORY_SETTING)}`}>
				<InventorySettingRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.INVENTORY_SKU)}`}>
				<InventorySkuRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.INVENTORY_CATEGORY)}`}>
				<InventoryCategoryRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.INVENTORY_CONFIG)}`}>
				<InventoryConfigRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.LIVE_IMS)}`} exact>
				<IMSPage live />
			</Route>
			<Route path={`${path}${getSubURL(urls.PAST_IMS)}/:index`} exact>
				<IMSPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.IMS_ANNOTATION)}/:index`} exact>
				<IMSAnnotationPage />
			</Route>
		</Switch>
	);
};
export default InventoryRoutes;
