import {
	useEffect, useState, forwardRef
} from 'react';
import { SketchPicker } from 'react-color';
import { Input } from '@/Components/ui/input';
import { Label } from '@/Components/ui/label';

const AddNewProcess = forwardRef((props, ref) => {
	const [heading, setHeading] = useState('');
	const [flowColor, setFlowColor] = useState('#000000');
	const [showFlowColorPicker, setShowFlowColorPicker] = useState(false);
	const handleFlowColorChange = (newColor) => {
		setFlowColor(newColor.hex);
	};

	useEffect(() => {
		// eslint-disable-next-line no-param-reassign
		ref.current.heading = heading;
		// eslint-disable-next-line no-param-reassign
		ref.current.flowColor = flowColor;
	}, [heading, flowColor]);

	return (
		<form
			className='w-full rounded-[4px] flex flex-col gap-2 p-4'
			// onSubmit={handleUpload}
			encType='multipart/form-data'
		>
			<Label className='text-red-600'>
				Select Process Color and Enter Name*
			</Label>
			<div className=' flex justify-between gap-4 '>
				<div>
					<div className='w-[54px] h-[55px] border border-[#222222] rounded-md'>
						<div
							className='"w-full h-full rounded-md p-1'
							onClick={() => {
								setShowFlowColorPicker(!showFlowColorPicker);
							}}
							style={{
								backgroundColor: flowColor,
							}}
						/>
					</div>
					{showFlowColorPicker && (
						<div className='absolute z-[999]'>
							<div
								className='fixed top-0 bottom-0 left-0 right-0'
								onClick={() => {
									setShowFlowColorPicker(false);
								}}
							/>
							<SketchPicker
								color={flowColor}
								onChange={handleFlowColorChange}
								position='center'
							/>
						</div>
					)}
				</div>
				<Input
					className='outline-none'
					type='text'
					placeholder='Process Heading'
					value={heading}
					onChange={(e) => {
						return setHeading(e.target.value);
					}}
				/>
			</div>
		</form>
	);
});
export default AddNewProcess;
