import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styles from '../List View/ListPage.module.scss';
import { ListTable } from '../../Components/ListTable';
import assetServices from '../../Services/AssetServices/asset.services';
import { notificationServices } from '../../Services';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
];

const COL_MAP = {
	ID: 'id',
	Name: 'name',
	Edit: 'id',
};

const ACTION_COLUMNS = [
	{
		title: 'Edit',
		config: {
			type: 'CANCEL',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'CANCEL',
		},
	},
];

const CapexService = (props) => {
	const { query } = props;
	const { url } = useRouteMatch();
	const history = useHistory();
	const [data, setData] = useState([]);

	const fetchServices = async () => {
		try {
			const response = await assetServices.listCapexService();
			if (response?.statusCode === 200) {
				setData(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchServices();
	}, []);

	const SPECIAL_ITEMS = {
		Edit: (itemId) => {
			return (
				<Button
					variant='outlined'
					onClick={() => {
						const newUrl = `${url}/edit/${itemId}`;
						history.push(newUrl);
					}}
				>
					Edit
				</Button>
			);
		},
	};

	const handleDelete = async (item) => {
		try {
			const response = await assetServices.deleteCapexService({
				id: item?.id,
			});

			if (response.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: 'Service deleted Successfully',
				});

				setData(data?.filter((service) => {
					return service?.id !== item?.id;
				}));
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return (
		<div className={`${styles['list-view-root']} page`}>
			<ListTable
				data={data?.filter((service) => {
					return (
						service?.name
							?.toLowerCase()
							?.indexOf(query?.trim()?.toLowerCase()) !== -1
					);
				})}
				columns={TABLE_COLUMNS}
				colMap={COL_MAP}
				actions={ACTION_COLUMNS}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};
CapexService.defaultProps = {
	placeholder: 'Search By Name',
};
export default CapexService;
