import { Add } from '@mui/icons-material';
import styles from './AddTimeSlot.module.scss';

const AddTimeSlot = (props) => {
	const { handleClick, dataAttrs, isDisabled } = props;
	const text = 'Add Slot';
	return (
		!isDisabled && (
			<div
				className={`${styles.root}`}
				onClick={handleClick}
				{...dataAttrs}
			>
				<Add className={`${styles['add-icon']}`} />
				{`${text}`}
			</div>
		)
	);
};

AddTimeSlot.defaultProps = {
	dataAttrs: {},
};

export default AddTimeSlot;
