import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../Pages';
import MyLicensesPage from '../../../Pages/Accounts/MyLicenses/MyLicensesPage';
import MyLicensesCreatePage from '../../../Pages/Accounts/MyLicenses/MyLicensesCreatePage/MyLicensesCreatePage';
// import BrandingCreatePageMobile from '../../../Pages/Accounts/Branding/BrandingCreatePageMobile';

const MyLicensesRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MyLicensesPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<MyLicensesCreatePage />}
					entity='BRANDING'
				/>
			</Route>
		</Switch>
	);
};

export default MyLicensesRoutes;
