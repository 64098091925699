// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
// import { urls } from '../../Constants';
// import { Dashboard } from '../../Pages';
import AIAccuracies from '../../Pages/AIWorkflow/AIAccuracies/AIAccuracies';

const AI_DASHBOARD = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`}>
				<AIAccuracies noStoreForAiDashboardRoute />
			</Route>
		</Switch>
	);
};

export default AI_DASHBOARD;
