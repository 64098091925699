import { endpoints } from '../../Constants';
import { authHeader, handleResponse } from '../../Helpers';

const dashboardAnalyticsService = {
	fetchDashboardAnalytics,
	fetchAIWorkflowAnalytics,
	fetchAiDashboardAnalytics,
	fetchSections,
	fetchDashboardData,
};

function fetchSections() {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	// return mockResponse(requestOptions);
	return fetch(endpoints.SECTIONS_DASHBOARD, requestOptions).then(
		handleResponse
	);
}
function fetchDashboardData(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	// return mockResponse(requestOptions);
	return fetch(
		`${endpoints.DASHBOARD_STATS}/${req?.data?.sectionId}`,
		requestOptions
	).then(handleResponse);
}
function fetchDashboardAnalytics(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	// return mockResponse(requestOptions);
	return fetch(endpoints.DASHBOARD, requestOptions).then(handleResponse);
}
function fetchAiDashboardAnalytics(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	// return mockResponse(requestOptions);
	return fetch(endpoints.AI_DASHBOARD, requestOptions).then(handleResponse);
}

function fetchAIWorkflowAnalytics(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	// return mockResponse(requestOptions);
	return fetch(endpoints.AI_ACCURACIES, requestOptions).then(handleResponse);
}

// function mockResponse() {
// const mockData = {
// data: {
// production: {
// count: 98,
// weight: 49.034,
// },
// qcFailed: {
// count: 10,
// weight: 9.014,
// },
// overweight: {
// count: 7,
// weight: 6.023,
// },
// underweight: {
// count: 3,
// weight: 3.987,
// },
// visibleDefected: {
// count: 2,
// weight: 1.034,
// },
// rejected: {
// count: 3,
// weight: 1.572,
// },
// visibleDefects: 5,
// avgTemp: 37.5,
// },
// };
// return mockData;
// // setTimeout(() => {
// // }, 1000);
// }

export default dashboardAnalyticsService;
