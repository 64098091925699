import { FormControlLabel, Switch } from '@mui/material';
import { IconInput } from '../../Inputs';
import styles from './ComboChoice.module.scss';

const ComboChoice = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		id,
		variant,
		isDefault,
		isOptional,
		handleInput,
		price,
		additionalPrice,
		idx,
		mandatoryFields,
	} = props;
	return (
		<div className={`${styles.root}`}>
			{/* is default */}
			<div
				className='row'
				style={{
					display: isOptional ? 'none' : 'flex',
				}}
			>
				<FormControlLabel
					value='start'
					className={`${styles['form-control-label']}`}
					control={
						// eslint-disable-next-line react/jsx-wrap-multilines
						<Switch
							color='primary'
							size='small'
							className={`${styles.switch}`}
							name='default'
							value='default'
							checked={isDefault}
							onChange={handleInput}
							inputProps={{
								'data-id': idx,
								'data-type': 'choice',
							}}
						/>
					}
					label='Default'
					labelPlacement='start'
				/>
			</div>
			{/* variant name */}
			<div
				className='row'
				style={{
					justifyContent: 'space-between',
					margin: '0.25rem 0px',
				}}
			>
				<div>
					<span>Variant</span>
					<br />
					<span>{`(₹${variant.cost})`}</span>
				</div>
				<span className='variant-circle'>{variant.displayName}</span>
			</div>
			{/* price */}
			<div
				className='row'
				style={{
					justifyContent: 'space-between',
					margin: '0.65rem 0px',
					visibility: isDefault && !isOptional ? 'hidden' : 'visible',
				}}
			>
				<label
					className={`${styles.label} form-label`}
					htmlFor={`itemPrice-${id}`}
				>
					{isOptional ? 'Price' : 'Additional Price'}
				</label>
				{isOptional ? (
					<IconInput
						type='rupee'
						name='price'
						id={`itemPrice-${id}`}
						value={price}
						handleInput={handleInput}
						dataType='choice'
						dataId={idx}
						required={
							mandatoryFields.variants[0].groups[0].choices.price
						}
					/>
				) : (
					<IconInput
						type='rupee'
						name='additionalPrice'
						id={`itemPrice-${id}`}
						value={additionalPrice}
						handleInput={handleInput}
						dataType='choice'
						dataId={idx}
						required={
							mandatoryFields.variants[0].groups[0].choices
								.additionalPrice
						}
					/>
				)}
			</div>
		</div>
	);
};

export default ComboChoice;
