import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { inputHelper } from '../../../../Helpers';
import ApplicableUsersModal from '../../../Modals/ApplicabilityModal/ApplicableUsersModal';
import styles from './applicability.module.scss';
import Tag from '../../../Tag/Tag';

const Users = (props) => {
	const { fields, isDisabled, toggleApplicableUsersModal, handleConfirm } =
		props;
	const handleClick = (e) => {
		const { dataset } = inputHelper(e);
		toggleApplicableUsersModal(true, {
			...dataset,
		});
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		props.handleDelete(dataset.id, 'applicableUsers', 'userId');
	};
	const generateTags = (allTags, type) => {
		return allTags.map((tag) => {
			return (
				<Tag
					className={`${styles.tag}`}
					handleDelete={handleDelete}
					text={tag.name}
					isDisabled={isDisabled}
					dataAttrs={{
						'data-type': type,
						'data-id': tag.userId,
						'data-name': 'applicableUsers',
					}}
				/>
			);
		});
	};
	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}>
			<div className='form-field row'>
				<label className='form-label'>Applicable Users</label>
				<div className={`${styles['tag-wrapper']}`}>
					{fields.applicableUsers &&
						generateTags(fields.applicableUsers, 'applicableUsers')}
					{!isDisabled && (
						<div className={`${styles.add}`} onClick={handleClick}>
							<AddOutlinedIcon
								fontSize='medium'
								color='primary'
								dataAttrs={{
									'data-type': 'applicableUsers',
									'data-id': Users,
									'data-name': 'applicableUsers',
								}}
							/>
						</div>
					)}
				</div>

				<ApplicableUsersModal
					handleConfirm={handleConfirm}
					selectedUserList={fields.applicableUsers}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { data, show } = state.modal.applicable_users;
	return {
		show,
		data,
	};
};
ApplicableUsersModal.defaultProps = {
	data: [],
};

const mapDispatchToProps = {
	toggleApplicableUsersModal: modalActions.toggleApplicableUsersModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
