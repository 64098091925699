/* eslint-disable prefer-template */
import {
	useEffect, useRef, useState
} from 'react';
import Nav from 'rsuite/Nav';
import { classes } from '../../../Helpers';
import dashboardAnalyticsService from '../../../Services/DashbaordAnalyticsServices/dashboardAnalytics.services';
// import PeriodSelector from '../PeriodSelector/PeriodSelector';
import styles from './DashboardNav.module.scss';

// eslint-disable-next-line no-unused-vars
const navsList = [
	'TAB 1',
	'TAB 2',
	'TAB 3',
	'TAB 4',
	'TAB 5',
	'TAB 6',
	'TAB 7',
	'TAB 8',
];

const DashboardNav = (props) => {
	const { selectedTab, setSelectedTab } = props;
	const scrollContainerRef = useRef(null);
	const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
	const [showRightScrollButton, setShowRightScrollButton] = useState(false);
	const [sectionsList, setSectionsList] = useState([]);

	const fetchSections = async () => {
		try {
			const response = await dashboardAnalyticsService.fetchSections();
			if (response?.responseCode === 'SS-001') {
				setSectionsList(response?.data?.sections?.map((ele) => {
					if (
						[
							'COGS',
							'INV_MAT',
							'INV_PROD',
							'QC',
							'AUDIT',
						]?.includes(ele?.type)
					) {
						return {
							...ele,
							redirectAllow: ele?.redirectAllow ?? true,
						};
					}
					return {
						...ele,
						redirectAllow: ele?.redirectAllow ?? false,
					};
				}));
				setSelectedTab(response?.data?.sections.length > 0 ? response?.data?.sections?.[0] : {});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchSections();
	}, []);

	const scrollHorizontally = (distance) => {
		if (!scrollContainerRef.current) return;

		const container = scrollContainerRef.current;
		const currentScrollLeft = container.scrollLeft;
		container.scrollTo({
			left: currentScrollLeft + distance,
			behavior: 'smooth',
		});
	};

	// eslint-disable-next-line no-unused-vars
	const checkHorizontalScroll = () => {
		if (!scrollContainerRef.current) return false;

		const container = scrollContainerRef.current;
		return container.scrollWidth > container.clientWidth;
	};

	const handleLeftButtonClick = () => {
		scrollHorizontally(-100); // Negative value to scroll left
	};

	const handleRightButtonClick = () => {
		scrollHorizontally(100); // Positive value to scroll right
	};

	useEffect(() => {
		const handleScroll = () => {
			const container = scrollContainerRef.current;
			if (!container) return;

			const canScrollLeft = container.scrollLeft > 0;
			setShowLeftScrollButton(canScrollLeft);

			const canScrollRight =
				container.scrollLeft <
				container.scrollWidth - container.clientWidth;
			setShowRightScrollButton(canScrollRight);
		};

		if (scrollContainerRef.current) {
			scrollContainerRef.current.addEventListener('scroll', handleScroll);
			// Trigger initial check on mount
			handleScroll();
		}

		return () => {
			if (scrollContainerRef.current) {
				scrollContainerRef.current.removeEventListener(
					'scroll',
					handleScroll
				);
			}
		};
	}, [sectionsList]);

	return (
		<div className={styles.root}>
			{showLeftScrollButton && (
				<div
					onClick={handleLeftButtonClick}
					className={classes(styles.scroll, styles.leftS)}
				>
					{'<'}
				</div>
			)}
			{showRightScrollButton && (
				<div
					onClick={handleRightButtonClick}
					className={classes(styles.scroll, styles.rightS)}
				>
					{'>'}
				</div>
			)}
			<Nav
				activeKey={selectedTab}
				onSelect={setSelectedTab}
				appearance='tabs'
				justified
				className={styles['tab-container']}
				ref={scrollContainerRef}
			>
				{sectionsList?.map((ele) => {
					const isActive = selectedTab?.id === ele?.id;
					return (
						<Nav.Item
							key={ele?.id}
							style={{
								backgroundColor: isActive ? ele?.color : ele?.color + 'aa',
							}}
							className={classes(
								styles.tab,
								isActive ? styles.active : ''
							)}
							eventKey={ele}
						>
							{ele?.displayName}
						</Nav.Item>
					);
				})}
			</Nav>
		</div>
	);
};

export default DashboardNav;
