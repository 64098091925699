const config = {
	initialState: {
		selectedStatus: ['All'],
		vendors: [],
		dateType: '',
		raisedBy: null,
		productionPlan: [],
		productionPlanDuration: 'Week',
		fulfilledBy: null,
		store: null,
		processType: null,
		dateRange: {
			active: true,
			from: new Date(),
			to: new Date(),
		},
	},
	// eslint-disable-next-line no-unused-vars
	deserializer: (filters, INCLUDED_FILTERS, isInternal) => {
		const req = {
			dateType: filters?.dateType,
		};
		if (INCLUDED_FILTERS?.includes('vendor')) {
			req.vendors = filters?.vendors;
		}
		if (INCLUDED_FILTERS?.includes('raisedBy')) {
			req.storeId = filters?.storeId;
		}
		if (INCLUDED_FILTERS?.includes('fulfilledBy')) {
			req.fulfilledBy = filters?.fulfilledBy;
		}
		if (INCLUDED_FILTERS?.includes('status')) {
			req.status = filters?.status;
		}
		if (INCLUDED_FILTERS?.includes('dateRange')) {
			req.dateRangeFilter = filters?.dateRangeFilter;
		}
		if (INCLUDED_FILTERS?.includes('store') || INCLUDED_FILTERS?.includes('filteredStore')) {
			req.storeId = filters?.store;
		}
		if (INCLUDED_FILTERS?.includes('processType')) {
			req.processType = filters?.processType;
		}
		if (INCLUDED_FILTERS?.includes('productionPlan')) {
			req.productionPlans = filters?.productionPlan;
		}
		return req;
	},
};

export default config;
