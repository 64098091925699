import { connect } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import BackdropLoader from '../../Components/Loaders/BackdropLoader';
import { ListTable } from '../../Components/ListTable';
import { notificationServices } from '../../Services';
import productionPlanServices from '@/Services/Analytics Services/production-plan.services';

const getRowStyles = (item) => {
	const variancePercentage = parseFloat(item.variancePercentage);
	if (variancePercentage <= 10) {
		return 'bg-green-500';
	}
	if (variancePercentage > 10 && variancePercentage <= 80) {
		return 'bg-pink-500';
	}
	if (variancePercentage > 80) {
		return 'bg-red-500';
	}

	return '';
};

const FIND_FILTERS = [
	'store',
	'product',
	'dateRangeOnly',
	'confidence',
];

const TABLE_COLUMNS = [
	{
		title: 'From Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'UY variant Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sub Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Estimated Value',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Actual Value',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Variance %',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Show',
		config: {
			type: 'DATA',
		},
	},
];

const COL_MAP = {
	'From Date': 'fromDate',
	Store: 'storeName',
	'Product Name': 'productName',
	Unit: 'unit',
	'UY variant Id': 'variantId',
	Category: 'productCategoryName',
	'Sub Category': 'subCategoryName',
	'Estimated Value': 'calibratedQuantity',
	'Actual Value': 'actualQuantity',
	'Variance %': 'variancePercentage',
	Show: 'showFlag',
};

const ListForecsting = () => {
	const [contractData, setContractData] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const handleFind = async (data) => {
		try {
			setLoading(true);
			const filter = {
				storeId: data.store,
				productIds: data.products,
				dateRange: {
					from: moment(data?.dateRange?.from).format('YYYY-MM-DD'),
					to: moment(data?.dateRange?.to).format('YYYY-MM-DD'),
				},
				variancePercentageRange: {
					minVar: data.conf.minConf,
					maxVar: data.conf.maxConf,
				},
			};
			const response = await productionPlanServices.getForcastingList({
				data: filter,
			});
			if (response?.statusCode === 200) {
				setContractData(response?.data);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
		return data;
	};

	const SPECIAL_ITEMS = {
		'Product Name': (_, obj) => {
			return (
				<div>
					{obj?.productName}
					{' '}
					(
					{obj?.variantName}
					)
				</div>
			);
		},
	};

	return (
		<div>
			<BackdropLoader isLoading={isLoading} showProgress />
			<div className='p-2.5'>
				<ImagesFinder
					pageLabel='Forecasting'
					onSubmit={handleFind}
					FILTERS={FIND_FILTERS}
					filterLabels={{
						store: 'Store',
						dateRangeOnly: 'Left Over Inventory Date Range',
						confidence: 'Variance Percentage',
					}}
					dateRangeOnlyText='7 Days From'
					minConf={0} // Set minimum confidence value
					maxConf={100}
					step={0.1}
					additionInfo={{
						showAdd: false,
					}}
					storeTypes='BASE'
				/>
			</div>

			<ListTable
				columns={TABLE_COLUMNS}
				colMap={COL_MAP}
				// actions={[...ACTION_COLUMNS]}
				// handleViewClick={handleViewClick}
				special={SPECIAL_ITEMS}
				data={contractData?.map((ele) => {
					return {
						...ele,
						rowStyles: getRowStyles(ele),
					};
				})}
			/>
		</div>

	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ListForecsting);
