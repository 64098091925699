/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const auditAnalyticsServices = {
	gateway,
	fetchAuditAnalysisExport,
	// fetchCogsAnalysisGraph,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CHART_DATA':
			return fetchAuditAnalysisGraph({
				req,
			});
		case 'LIST_DATA':
			return fetchAuditAnalysisList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function fetchAuditAnalysisGraph({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.AUDIT_ANALYTICS, requestOptions).then(
		handleResponse
	);
}

function fetchAuditAnalysisList({ req }) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDIT_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchAuditAnalysisExport({ req }) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDIT_ANALYTICS_EXPORT, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions);
}

// function mockQAList() {
// 	const mockResponse = {
// 		statusCode: 200,
// 		message: 'Success',
// 		data: [
// 			{
// 				id: 7812,
// 				productName: 'Butter Scotch Cake',
// 				skuType: 'PRODUCT',
// 				amountUsed: 6782,
// 				costCategory: 'Inventory Consumed',
// 			},
// 			{
// 				id: 7813,
// 				productName: 'Butter Scotch Base',
// 				skuType: 'INGREDIENT',
// 				amountUsed: 3082,
// 				costCategory: 'PL',
// 			},
// 			{
// 				id: 7814,
// 				productName: 'Cheese',
// 				skuType: 'INGREDIENT',
// 				amountUsed: 3890,
// 				costCategory: 'Wastage',
// 			},
// 		],
// 	};

// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 1000);
// 	});
// }

export default auditAnalyticsServices;
