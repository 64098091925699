/* eslint-disable no-unused-vars */
import { cloneDeep } from 'lodash';
import { Message } from 'rsuite';
import { notificationServices } from '@/Services';

const initialState = {
	store: null,
	processId: null,
	qualityControl: false,
	overrideAllowed: false,
	supervisorControl: false,
	duplicateAllowed: false,
	duplicateControl: false,
	leftOverInventory: false,
};
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export const config = {
	initialState,
	reducer,
	validator: (state) => {
		if (!state?.store) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Store is Mandatory',
			});
			return false;
		}
		if (!state?.processId) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Process  is Mandatory',
			});
			return false;
		}
		return true;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		delete clonedState?.store;
		return clonedState;
	},
};
