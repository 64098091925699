// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import NotAccessiblePage from '../../Pages/NotAccessable/NotAccessable';
import VendorOpening from '../../Pages/VendorOpening/VendorOpening';
import VendorOpeningCreatePage from '../../Pages/VendorOpening/VendorOpeningCreatePage/VendorOpeningCreatePage';

const VendorOpeningRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<VendorOpening />
			</Route>
			<Route exact path={`${path}/add`}>
				<VendorOpeningCreatePage />
			</Route>
			<Route path='*'>
				<NotAccessiblePage />
			</Route>
		</Switch>
	);
};

export default VendorOpeningRoutes;
