/* eslint-disable object-curly-spacing */
/* eslint-disable prefer-template */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import { Button, Fade, IconButton, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowCircleRight } from '@mui/icons-material';
import { brandActions, formActions } from '../../../../Actions';
import { PRODUCT_CLASSES } from '../../../../Constants/product.constants';
import { inputHelper } from '../../../../Helpers';
import styles from './ProductListFilters.module.scss';

const EMPTYVALUE = -999;
const initialState = {
	productType: PRODUCT_CLASSES[0].id,
	category: null,
	brand: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_PRODUCT_TYPE':
			return {
				...state,
				productType: action.payload,
			};
		case 'SET_CATEGORY':
			return {
				...state,
				category: action.payload,
			};
		case 'SET_BRAND':
			return {
				...state,
				brand: action.payload,
			};
		default:
			return state;
	}
};

const ProductListFilters = (props) => {
	const [fetchedData, setFetchedData] = useState(true);
	const { fetchProducts, setFilter, entity, params, fetchAPI, brands, fetchBrands } =
		props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const { brand, category, productType } = state;
	// const [state, setState] = useState({
	// ...initialState,
	// });
	const NEW_PRODUCT_CLASSES = useMemo(() => {
		return [
			{
				name: 'ALL',
				id: '-99',
			},
			...PRODUCT_CLASSES,
		];
	}, [PRODUCT_CLASSES]);

	const handleFilterSelect = (event) => {
		const { fieldValue } = inputHelper(event);
		// const { dataset } = inputHelper(event);

		// const { id } = dataset;
		const id = fieldValue;
		dispatch({
			type: 'SET_PRODUCT_TYPE',
			payload: id,
		});
		setFetchedData(false);
	};

	useEffect(() => {
		fetchBrands({
			id: '',
			allData: true,
		});
	}, []);

	const selectBrand = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_BRAND',
			payload: fieldValue,
		});
		dispatch({
			type: 'SET_CATEGORY',
			payload: EMPTYVALUE,
		});
		setFetchedData(false);
	};
	const selectCategory = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_CATEGORY',
			payload: fieldValue,
		});
		setFetchedData(false);
	};
	const brandsList = useMemo(() => {
		const arrayTe = Object.keys(brands)?.map((id) => {
			const currBrand = brands[id];
			return currBrand;
		});
		arrayTe?.unshift({
			id: EMPTYVALUE,
			name: 'ALL',
		});
		return arrayTe;
	}, [brands]);

	const categoryList = useMemo(() => {
		if (!brand && !brands) {
			return [];
		}
		const arrayTe = Object.values(
			brands?.[brand + '']?.ProductCategory ?? {}
		)?.map((value) => {
			const currBrand = value;
			return currBrand;
		});
		arrayTe?.unshift({
			id: EMPTYVALUE,
			productCategoryName: 'ALL',
		});
		return arrayTe;
	}, [brands, brand]);

	useEffect(() => {
		dispatch({
			type: 'SET_BRAND',
			payload: brandsList[0]?.id,
		});
		dispatch({
			type: 'SET_CATEGORY',
			payload: EMPTYVALUE,
		});
	}, [brandsList]);

	useEffect(() => {
		dispatch({
			type: 'SET_PRODUCT_TYPE',
			payload: NEW_PRODUCT_CLASSES[0]?.id,
		});
	}, [NEW_PRODUCT_CLASSES]);

	const applyFilter = () => {
		if (fetchAPI) {
			fetchAPI(productType === NEW_PRODUCT_CLASSES[0]?.id
				? ''
				: productType?.toUpperCase());
		} else {
			fetchProducts({
				entity,
				req: {
					params: {
						id: '',
						skuType:
							productType === NEW_PRODUCT_CLASSES[0]?.id
								? ''
								: productType?.toUpperCase(),
						brandId: brand === EMPTYVALUE ? '' : brand,
						categoryId: category === EMPTYVALUE ? '' : category,
						// combo: false,
						...params,
					},
				},
				method: 'LIST',
			});
		}
		setFetchedData(true);
	};
	useEffect(() => {
		setFilter?.({
			req: {
				params: {
					id: '',
					skuType:
						productType === NEW_PRODUCT_CLASSES[0]?.id
							? ''
							: productType?.toUpperCase(),
					brandId: brand === EMPTYVALUE ? '' : brand,
					categoryId: category === EMPTYVALUE ? '' : category,
					// combo: false,
					...params,
				},
			},
		});
	}, [productType, category, brand]);

	return (
		<div className={styles.root}>
			<div className={styles.headerBrandSelector}>
				<p className={styles['filter-heading']}>Select Sku Type</p>
				<div className={styles['filter-body']}>
					<Select
						className={styles['brand-select']}
						value={productType ?? ''}
						onChange={handleFilterSelect}
					>
						{NEW_PRODUCT_CLASSES?.map((f) => {
							return (
								<MenuItem key={f.id} value={f.id}>
									{f.name}
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</div>
			<div className={styles.headerBrandSelector}>
				<p className={styles['filter-heading']}>Select a Brand</p>
				<div className={styles['filter-body']}>
					<Select
						className={styles['brand-select']}
						value={brand ?? ''}
						onChange={selectBrand}
					>
						{brandsList?.map((brandObj) => {
							return (
								<MenuItem
									key={brandObj?.id}
									value={brandObj?.id}
								>
									{brandObj?.name}
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</div>
			<div className={styles.headerBrandSelector}>
				<p className={styles['filter-heading']}>Select a Category</p>
				<div className={styles['filter-body']}>
					<Select
						className={styles['brand-select']}
						value={category ?? 'Select a Brand'}
						onChange={selectCategory}
					>
						{categoryList?.map((c) => {
							return (
								<MenuItem key={c?.id} value={c?.id}>
									{c?.productCategoryName}
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</div>
			<div>
				<div className={styles.submit}>
					{!fetchedData && (
						<IconButton
							onClick={applyFilter}
							type='submit'
							className={styles.btn}
						>
							<ArrowCircleRight />
						</IconButton>
					)}
					{/* <Fade in={!fetchedData}></Fade> */}
				</div>
			</div>
		</div>
	);
};

ProductListFilters.defaultProps = {
	entity: 'ALL_PRODUCTS',
	params: {},
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	fetchBrands: brandActions.fetchBrands,
	fetchProducts: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListFilters);
