import { Crop } from '@mui/icons-material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { brandActions, modalActions } from '../../../Actions';
import {
	// generateCheckedProps,
	generateDisabledProps,
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	// generateRandomKey,
	generateRequiredProps,
	inputHelper,
	// isEmpty,
	objectContainsProperty,
} from '../../../Helpers';
// import { IconInput } from '../Inputs';
import styles from './VendorDetails.module.scss';
import { ImageCropModal } from '../../Modals/ImageCropModal';
// import { VariantCheckboxes } from '../Inputs/VariantCheckboxes';

const VendorDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		toggleImageCrop,
		handleImageInput,
		// brands,
		// generateDefaultOption,
		isDisabled,
		exclude,
	} = props;

	useEffect(() => {
		props.fetchVendors({
			id: '',
			allData: true,
		});
	}, []);

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	return (
		<div className={`${styles.root}`}>
			<fieldset
				{...generateDisabledProps(isDisabled)}
				className='form-fieldset row'
				style={{
					alignItems: 'flex-start',
				}}
			>
				<div
					style={{
						flexBasis: '50%',
					}}
				>
					{/* Vendor name */}
					{!objectContainsProperty(exclude, 'name') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='vendorName'
								data-mandatory={mandatoryFields.name}
							>
								Vendor Name
							</label>
							<input
								className='form-value'
								type='text'
								id='vendorName'
								name='name'
								onInput={handleInput}
								value={fields.name}
								{...generateRequiredProps(mandatoryFields.name)}
							/>
							<span className='form-error'>{errors.name}</span>
						</div>
					)}
					{/* Customer Id */}
					{!objectContainsProperty(exclude, 'customerId') && (
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='customerId'
								data-mandatory={mandatoryFields.customerId}
							>
								Description
							</label>
							<textarea
								className='form-value'
								type='text'
								id='customerId'
								name='customerId'
								onInput={handleInput}
								value={fields.customerId}
								{...generateRequiredProps(
									mandatoryFields.customerId
								)}
							/>
							<span className='form-error'>
								{errors.customerId}
							</span>
						</div>
					)}
				</div>
				<div
					style={{
						marginLeft: '3rem',
						flexBasis: '50%',
					}}
				>
					{/* display image */}
					{!objectContainsProperty(exclude, 'logo') && (
						<div
							className='form-field row'
							style={{
								alignItems: 'flex-start',
							}}
						>
							<label
								className='form-label'
								data-mandatory={mandatoryFields.image}
							>
								Logo
							</label>
							<div className='display-pic-root'>
								<div className='display-pic-preview'>
									<img
										src={
											isDisabled
												? fields.image
												: generateImageUrl(fields.image)
										}
										className={`display-pic ${
											fields.image ? 'show' : 'hide'
										}`}
										alt='combo-display'
									/>
									{generateImageEditButton({
										isEdit:
											fields.image &&
											fields.image.includes(
												'data:image/jpeg;base64'
											),
										button: (
											<button
												type='button'
												onClick={() => {
													openImageCropModal('image');
												}}
												className='display-pic-edit'
											>
												<Crop />
											</button>
										),
									})}
								</div>
								{generateImageUploadButton({
									isDisabled,
									label: (
										<label
											className='display-pic-label'
											htmlFor='displayPic'
										>
											Choose an image
										</label>
									),
								})}
								<input
									type='file'
									id='displayPic'
									accept='image/png, image/jpg, image/jpeg, image/webp'
									name='image'
									onChange={handleImageSelect}
									{...generateRequiredProps(
										typeof fields.image !== 'string' &&
											mandatoryFields.image
									)}
									style={{
										opacity: 0,
										width: 1,
										height: 1,
									}}
								/>
							</div>
						</div>
					)}
					<ImageCropModal handleConfirm={handleImageInput} />
				</div>
			</fieldset>
		</div>
	);
};

VendorDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
	exclude: {},
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	fetchVendors: brandActions.fetchVendors,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);
