import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import {
	CustomizationViewPage,
	CustomizationListPage,
	CustomizationCreatePage,
} from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const CustomizationRoutes = (props) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.CUSTOMIZATIONS}`,
			name: 'Customizations',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='CUSTOMIZATIONS'
			path={path}
			listPage={CustomizationListPage}
			viewPage={<CustomizationViewPage breadcrumbs={breadcrumbs} />}
			createPage={<CustomizationCreatePage breadcrumbs={breadcrumbs} />}
			editPage={
				<CustomizationCreatePage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default CustomizationRoutes;
