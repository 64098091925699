// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import MasterMenuRoutes from './Master Menu Routes/mastermenu.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.MENUS;
	return entity.slice(baseEntity.length);
};

const MenuRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.MASTER_MENUS)}`}>
				<MasterMenuRoutes />
			</Route>
		</Switch>
	);
};

export default MenuRoutes;
