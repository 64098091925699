import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from '../utils';

const useForm = (props) => {
	const { config, type, data, fetchData, isEdit } = props;

	const [formState, setFormState] = useState({
		...config.initialState,
	});

	const { id } = useParams();

	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	useEffect(() => {
		if (type === 'VIEW' || type === 'EDIT' || isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setFormState(config?.serializer(formState, data));
			} else {
				fetchData();
			}
		}
	}, []);

	useEffect(() => {
		if (type === 'VIEW' || type === 'EDIT' || isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setFormState(config.serializer(formState, data));
			}
		}
	}, [data]);

	const validateAndDeserialize = async () => {
		let req = null;
		const isValid = config?.validator(formState);
		if (isValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			req = await config?.deserializer(formState);
		}
		return new Promise((resolve, reject) => {
			if (isValid) {
				resolve(req);
			} else {
				reject();
			}
		});
	};

	return {
		numberOfSubmissions,
		setNumberOfSubmissions,
		formState,
		setFormState,
		validateAndDeserialize,
	};
};

export default useForm;
