/* eslint-disable no-unused-vars */
import { Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { classes } from '../../Helpers';
// import { notificationServices } from '../../Services';
import styles from '../POItemSelection/POItemSelection.module.scss';
import config from '../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder.config';
import { useAuth } from '../Auth/Login/auth';
import { Button } from '../ui/button';
import CreateQuoteTable from './CreateQuoteTable.js';

const defaultColumns = [
	{
		title: 'Asset Type',
		icon: false,
		width: '30%',
	},
	{
		title: 'OEM',
		icon: false,
		width: '30%',
	},
	{
		title: 'Core Specification',
		icon: false,
		width: '40%',
	},
	{
		title: 'QUANTITY',
		icon: <Edit className={styles.icon} />,
		width: '180px',
	},
];

function QuoteItemSelection(props) {
	const { showRow, setRow, row, editMode } = props;
	const { appConfig = {} } = useAuth();
	return (
		<div className={classes(styles.root)}>
			<div className={styles['item-row']}>
				<table>
					<thead>
						<tr className='bg-[#d1d1d1]'>
							{defaultColumns?.map((ele) => {
								return (
									<th
										style={{
											width: ele?.width ? ele?.width : '',
											fontSize: 14,
											textAlign: 'center',
										}}
									>
										{ele?.title?.toUpperCase()}
										{' '}
										{ele?.icon}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						<CreateQuoteTable
							showRow={showRow}
							setRow={setRow}
							row={row}
							editMode={editMode}
						/>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default QuoteItemSelection;
