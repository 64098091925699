/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { CheckPicker } from 'rsuite';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ProductProcessMapCreatorModal.module.scss';
import productProcessMapServices from '../../../Services/Analytics Services/product-process-map.services';
import { notificationServices } from '../../../Services';

const maps = {
	CATEGORY: 'Product category',
	PRODUCT_TYPE: 'Product type',
	PRODUCT: 'Products',
};

const ProductProcessMapCreatorModal = (props) => {
	const {
		show,
		toggleModal,
		data,
		handleConfirmMap,
		selectedMappingType,
	} = props;
	const [currentData, setCurrentData] = useState(false);
	const [selectedMappingTypeValue, setSelectedMappingTypeValue] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedElement, setSelectedElement] = useState([]);
	const handleClose = () => {
		toggleModal(false);
	};

	const { heading, selectorType, selectedBaseStore, selectedStoreType } = data;

	const fetchMappingTypeValues = async () => {
		setLoading(true);
		try {
			const response =
				await productProcessMapServices.getMappingTypeValues({
					data: {
						mappingType: {
							id: selectedMappingType?.id,
						},
						mappingOn: selectorType,
						store: selectedBaseStore?.id && {
							id: selectedBaseStore?.id,
						},
						storeTypeSubType: selectedStoreType,
					},
				});
			if (response?.responseCode === 'SS-001') {
				setCurrentData(response?.data);
			} else {
				notificationServices?.generateNotification({
					message:
						response?.responseMessage ??
						response?.status ??
						'Some error Occured',
					type: 'error',
				});
				toggleModal(false);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			toggleModal(false);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (show) {
			fetchMappingTypeValues();
		} else {
			setCurrentData([]);
		}
	}, [show]);

	useEffect(() => {
		setSelectedMappingTypeValue(null);
		setSelectedElement([]);
	}, [selectorType, selectedMappingType?.id]);

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			customStyles={{
				width: '600px',
				height: '400px',
			}}
			heading={heading}
			open={show}
			handleClose={handleClose}
			handleConfirm={() => {
				if (selectedMappingTypeValue) {
					handleConfirmMap({
						selectedMappingTypeValue,
						selectedElement,
					});
				} else {
					notificationServices?.generateNotification({
						message: 'Please fill both parameters',
						type: 'error',
					});
				}
			}}
		>
			<div className={`${styles.root}`}>
				{loading ? (
					<div> Getting Mappable data, Please wait.. </div>
				) : (
					<>
						<div className={styles.flow}>
							<div className={styles.text}>
								Select
								{' '}
								{selectedMappingType?.displayName}
							</div>
							<Autocomplete
								disablePortal
								id='combo-box-demo'
								getOptionLabel={(option) => {
									return option.displayName;
								}}
								onChange={(event, selectedOption) => {
									setSelectedMappingTypeValue(selectedOption);
								}}
								options={currentData?.mappingTypeValues}
								value={selectedMappingTypeValue}
								sx={{
									width: 300,
									background: 'rgba(255,255,255,.1)',
								}}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											label={`Select ${selectedMappingType?.displayName}`}
										/>
									);
								}}
							/>
						</div>
						<div className={styles.flow}>
							<div className={styles.text}>
								Select
								{' '}
								{maps[selectorType]}
							</div>
							<CheckPicker
								placeholder={`Select ${maps[selectorType]}`}
								id='combo-box-demo'
								data={currentData?.mappingOnValues?.map((option) => ({
									label: option?.displayName,
									value: option?.id,
								})) ?? []}
								value={selectedElement ?? null}
								onChange={(value) => {
									setSelectedElement(value ?? []);
								}}
								style={{
									width: 300,
									background: 'rgba(255,255,255,.1)',
								}}
								menuStyle={{
									width: 300,
									zIndex: 4000,
								}}
								virtualized
								countable
							/>
							{/* <Autocomplete
								disablePortal
								id='combo-box-demo'
								value={selectedElement ?? null}
								getOptionLabel={(option) => {
									return option?.displayName;
								}}
								onChange={(event, selectedOption) => {
									setSelectedElement(selectedOption);
								}}
								options={
									currentData?.mappingOnValues ?? []
								}
								sx={{
									width: 300,
									background: 'rgba(255,255,255,.1)',
								}}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											label={`Select ${maps[selectorType]}`}
										/>
									);
								}}
							/> */}
						</div>
						<div className={styles.textNote}>
							Note: Only elements which are not mapped are listed
						</div>
					</>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.productProcessMapCreatorModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleProductProcessMapCreatorModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductProcessMapCreatorModal);
