// eslint-disable-next-line object-curly-newline
import _ from 'lodash';
import { useEffect, useState } from 'react';
// import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { GeneralDetails } from '../../../Components/Forms/Branding';
import { StoreDesign } from '../../../Components/Forms/Stores';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { imageToBase64, inputHelper, isEmpty } from '../../../Helpers';
import BrandingConfig from './branding.config';

const storeDesignBannerDate = [
	{
		for: 'desktop',
		tooltip: 'description for the section',
		sectionName: 'Store Design For Desktop',
		name: 'storeDesign',
		entity: 'DESKTOP',
		aspectRatio: '4:3',
	},
	// {
	// for: 'mobile',
	// tooltip: 'description for the section',
	// sectionName: 'Store Design For Mobile',
	// name: 'storeDesign',
	// entity: 'MOBILE',
	// aspectRatio: '9:16',
	// },
];
const BrandingCreatePage = (props) => {
	const { isEdit, saveBrand, data, fetchBrand } = props;
	const [state, setState] = useState({
		...BrandingConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const { fields, errors } = state;
	const history = useHistory();

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(BrandingConfig.serializer(state, data));
		} else {
			fetchBrand({
				entity: 'BRANDING',
				method: 'VIEW',
			});
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(BrandingConfig.serializer(state, data));
			}
		}
	}, [data]);
	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};

	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'logo':
			case 'TOS':
			case 'PP':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.BRANDING}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.BRANDING}`);
		}
	};
	const generateBannerPromises = (banners) => {
		if (banners === null || banners.length === 0 || banners[0] === null) {
			return [];
		}
		return banners.map((banner) => {
			return imageToBase64(banner);
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const isValid = BrandingConfig.validator(state);
		if (isValid) {
			const req = {};
			req.data = BrandingConfig.deserializer(state);
			Promise.all([
				...generateBannerPromises(req.data.bannerImages),
				...generateBannerPromises([
					req.data.logo,
					req.data.privacyUrl,
					req.data.TOSUrl,
				]),
			]).then((values) => {
				if (req.data.bannerImages) {
					const bannerLength = req.data.bannerImages.length;
					req.data.bannerImages = [];
					for (let i = 0; i < values.length; i++) {
						if (i < bannerLength) {
							req.data.bannerImages.push(values[i] || null);
						} else if (i === bannerLength) {
							req.data.logo = values[i] || null;
						} else if (i === bannerLength + 1) {
							req.data.privacyUrl = values[i] || null;
						} else {
							req.data.TOSUrl = values[i] || null;
						}
					}
				} else {
					req.data.bannerImages = [];
					for (let i = 0; i <= values.length; i++) {
						if (i === 0) {
							req.data.logo = values[i] || null;
						} else if (i === 1) {
							req.data.privacyUrl = values[i] || null;
						} else {
							req.data.TOSUrl = values[i] || null;
						}
					}
				}
				setNumberOfSubmissions(numberOfSubmissions + 1);

				if (isEdit) {
					saveBrand({
						entity: 'BRANDING',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.BRANDING}`,
					});
				} else {
					saveBrand({
						entity: 'BRANDING',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.BRANDING}`,
					});
				}
			});
		}
	};
	const handleLogoInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};
	// const handleBannerList = (list, entity) => {
	const handleBannerList = (list) => {
		const newState = {};
		const fieldName = 'bannerList';

		// if (entity === 'MOBILE') {
		// fieldName = 'bannerListMobile';
		// }

		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: list,
				...newState,
			},
			...errors,
		});
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Branding' : 'Create Branding'}
					handleCancel={handleCancel}
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={BrandingConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
						handleLogoInput={handleLogoInput}
					/>
				</FormSectionHeader>
				{storeDesignBannerDate.map((ele) => {
					return (
						<FormSectionHeader
							tooltip={ele.tooltip}
							sectionName={ele.sectionName}
							name={ele.name}
						>
							<StoreDesign
								entity={ele.entity}
								aspectRatio={ele.aspectRatio}
								mandatoryFields={BrandingConfig.mandatoryFields}
								bannerList={fields.bannerList}
								bannerListMobile={fields.bannerListMobile}
								isEdit={isEdit}
								handleBannerList={handleBannerList}
							/>
						</FormSectionHeader>
					);
				})}
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.BRANDING.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchBrand: formActions.gateway,
	saveBrand: formActions.gateway,
};

BrandingCreatePage.defaultProps = {
	isEdit: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandingCreatePage);
