import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const approveRefundsServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'LIST':
			return listRefund({
				req,
			});
		case 'UPDATE':
			return updateRefund({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function updateRefund({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.APPROVE_REFUNDS, requestOptions).then(
		handleResponse
	);
}

function listRefund({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.APPROVE_REFUNDS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default approveRefundsServices;
