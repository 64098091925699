/* eslint-disable no-tabs */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line object-curly-newline
import { Button, Switch, TextField } from '@mui/material';
// import { useRouteMatch } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
// import TimePicker from '@mui/lab/TimePicker';
// import { LocalizationProvider } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { connect } from 'react-redux';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Delete } from '@mui/icons-material';
import { CookingPotIcon } from 'lucide-react';
import {
	generateCheckedProps,
	generateRandomKey,
	// history,
	inputHelper,
} from '../../../../Helpers';
import styles from './StoreSetting.module.scss';
import { formActions, modalActions } from '../../../../Actions';
import { MenuModal } from '../../../../Components/Modals/MenuModal';
import MenuListModal from '../../../../Components/Modals/MenuListModal/MenuListModal';
// import { SpecialMenuModal } from '../../../../Components/Modals/Special Menu Modal';

const StoreSettingTable = (props) => {
	const {
		data,
		handle24X7,
		handletimeChange,
		toggleEditUpdate,
		toggleMenuModal,
		toggleMenuList,
		// toggleSpecialMenuModal,
		// updateSpecialMenu,
	} = props;
	const handleInput = (e) => {
		props.handleToggle(e);
	};

	const openModal = (e) => {
		const { dataset } = inputHelper(e);
		const menu = {
			id: data[dataset.id].menuId,
			idx: dataset.id,
			name: data[dataset.id].menuName,
			menuType: dataset?.type,
		};
		if (dataset?.type !== 'masterMenu') {
			menu.id = data[dataset.id][dataset?.type];
			menu.name = data[dataset.id][`${dataset?.type}Name`];
		}
		toggleMenuModal(true, menu);
	};
	// const openSpecialModal = (e) => {
	// 	const { dataset } = inputHelper(e);
	// 	const menu = {
	// 		id: data[dataset.id].id,
	// 		idx: dataset.id,
	// 		name: data[dataset.id].specialMenu.name,
	// 	};
	// 	toggleSpecialMenuModal(true, menu);
	// };
	const updateMenuList = (idx, id, menuId, name, menuType) => {
		props.updateMenuState(menuId, idx, data[idx].id, name, menuType);
	};
	const openlistModal = (e) => {
		const { dataset } = inputHelper(e);
		const menu = {
			id: data[dataset.id].id,
			idx: dataset.id,
			name: data[dataset.id].name,
			menuType: dataset.type,
		};
		toggleMenuList(true, menu);
	};
	// const { url } = useRouteMatch();
	// const navigateToItem = (idx) => {
	// 	return () => {
	// 		history.push(`${url}/view/${idx}`);
	// 	};
	// };
	return (
		<table className={`${styles['list-table-root']}`}>
			<thead>
				<tr>
					<th>Store Name</th>
					<th>Live/Offline </th>
					<th>24/7</th>
					<th className={`${styles['time-div']}`}>Opening Time</th>
					<th className={`${styles['time-div']}`}> Shut Time</th>
					<th>Edit Timings</th>
					<th>Master Menu</th>
					<th>AI-In Menu</th>
					<th>AI-Out Menu</th>
					<th>CMS Menu</th>
					<th>Wastage Menu</th>
				</tr>
			</thead>
			<tbody>
				{data.length === 0 ? (
					<tr>
						<td colSpan={7}>No store to Display</td>
					</tr>
				) : (
					data.map((store, idx) => {
						return (
							<tr>
								<td>
									<span>{store.name}</span>
								</td>
								<td>
									<Switch
										{...generateRandomKey()}
										name='isEnabled'
										onChange={handleInput}
										inputProps={{
											'data-id': idx,
										}}
										{...generateCheckedProps(
											store.isEnabled
										)}
										size='small'
										color='primary'
									/>
								</td>
								<td>
									<Switch
										{...generateRandomKey()}
										name='roundOclock'
										size='small'
										color='primary'
										onChange={handle24X7}
										{...generateCheckedProps(
											store.roundOclock
										)}
										inputProps={{
											'data-id': idx,
										}}
									/>
								</td>
								<td>
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										className={`${styles['time-div']}`}
									>
										<TimePicker
											name='storeStartAt'
											value={store.storeStartAt}
											onChange={(newValue) => {
												handletimeChange(
													'storeStartAt',
													newValue,
													idx
												);
											}}
											disabled={
												store.roundOclock ||
												!store.isEdit
											}
											disableOpenPicker={
												store.roundOclock ||
												!store.isEdit
											}
											renderInput={(params) => {
												return (
													<TextField {...params} />
												);
											}}
										/>
									</LocalizationProvider>
								</td>
								<td>
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										className={`${styles['time-div']}`}
									>
										<TimePicker
											className={`${styles['time-slot']}`}
											name='startingTime'
											value={store.storeShutsAt}
											onChange={(newValue) => {
												handletimeChange(
													'storeShutsAt',
													newValue,
													idx
												);
											}}
											disabled={
												store.roundOclock ||
												!store.isEdit
											}
											disableOpenPicker={
												store.roundOclock ||
												!store.isEdit
											}
											renderInput={(params) => {
												return (
													<TextField {...params} />
												);
											}}
										/>
									</LocalizationProvider>
								</td>
								<td>
									<Button
										name='editTiming'
										size='large'
										variant='outlined'
										onClick={toggleEditUpdate}
										data-id={idx}
										disabled={store.roundOclock}
									>
										{store.isEdit ? 'Update' : 'Edit'}
									</Button>
								</td>

								<td className={styles['icon-action']}>
									{store.menuId ? (
										<MenuBookIcon
											name='menu'
											size='medium'
											color='primary'
											onClick={openModal}
											defaultChecked={data.masterMenu}
											data-id={idx}
											data-type='masterMenu'
											cursort='pointer'
										/>
									) : (
										<AddIcon
											onClick={openlistModal}
											data-id={idx}
											data-type='masterMenu'
										/>
									)}
								</td>
								<td className={styles['icon-action']}>
									{store.inwardingMenu ? (
										<MenuBookIcon
											name='menu'
											size='medium'
											color='primary'
											onClick={openModal}
											defaultChecked={data.inwardingMenu}
											data-id={idx}
											data-type='inwardingMenu'
											cursort='pointer'
										/>
									) : (
										<AddIcon
											onClick={openlistModal}
											data-id={idx}
											data-type='inwardingMenu'
										/>
									)}
								</td>
								<td className={styles['icon-action']}>
									{store.outwardingMenu ? (
										<MenuBookIcon
											name='menu'
											size='medium'
											color='primary'
											onClick={openModal}
											defaultChecked={data.outwardingMenu}
											data-id={idx}
											data-type='outwardingMenu'
											cursort='pointer'
										/>
									) : (
										<AddIcon
											onClick={openlistModal}
											data-id={idx}
											data-type='outwardingMenu'
										/>
									)}
								</td>
								<td className={styles['icon-action']}>
									{store.cmsMenu ? (
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
											<CookingPotIcon
												name='menu'
												size='19'
												color='#0000FF'
												onClick={openModal}
												data-id={idx}
												data-type='cmsMenu'
												cursort='pointer'
											/>
										</div>
									) : (
										<AddIcon
											onClick={openlistModal}
											data-id={idx}
											data-type='cmsMenu'
										/>
									)}
								</td>
								<td className={styles['icon-action']}>
									{store.wastageMenu ? (
										<Delete
											name='menu'
											size='medium'
											className=''
											color='primary'
											onClick={openModal}
											defaultChecked={data.wastageMenu}
											data-id={idx}
											data-type='wastageMenu'
											cursort='pointer'
										/>
									) : (
										<AddIcon
											onClick={openlistModal}
											data-id={idx}
											data-type='wastageMenu'
										/>
									)}
								</td>
							</tr>
						);
					})
				)}
				<MenuModal />
				{/* <SpecialMenuModal updateSpecialMenu={updateSpecialMenu} /> */}
				<MenuListModal handleConfirm={updateMenuList} />
			</tbody>
		</table>
	);
};
StoreSettingTable.defaultProps = {
	data: [],
};
const mapStateToProps = (state) => {
	const { show1 } = state.modal.livemenu;
	const { show2 } = state.modal.menuList;
	return {
		show1,
		show2,
	};
};

const mapDispatchToProps = {
	toggleMenuModal: modalActions.toggleMenuModal,
	toggleMenuList: modalActions.toggleMenuList,
	updateMenu: formActions.gateway,
	toggleSpecialMenuModal: modalActions.toggleSpecialMenuModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreSettingTable);
