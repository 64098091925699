/* eslint-disable no-restricted-syntax */
// /* eslint-disable */
import { get } from 'lodash';
import React, {
	useEffect, useMemo, useState
} from 'react';
import _uniqueId from 'lodash/uniqueId';
// eslint-disable-next-line object-curly-newline
import {
	DeleteForever,
	FileCopy,
	ModeEdit,
	Upload,
	Visibility,
} from '@mui/icons-material';
import {
	IconButton,
	Menu,
	MenuItem,
	TextField,
	Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dropdown from 'rsuite/Dropdown';
import { TableVirtuoso } from 'react-virtuoso';
import { objectContainsProperty } from '../../Helpers';
import { modalActions } from '../../Actions';
// eslint-disable-next-line import/no-cycle
import { AlertModal } from '../Modals';
import styles from './VirtualizedListTable.module.scss';
import excel from '../../Images/excel.svg';
import { Button } from '../ui/button';

const VirtualizedListTable = (props) => {
	// eslint-disable-next-line object-curly-newline
	const [uniqueId] = useState(_uniqueId('prefix'));
	const [editDisable, setEditDisable] = useState(true);
	const [anchorEl, setAnchorEl] = useState({});
	const [viewAnchorEl, setViewAnchorEl] = useState({});
	// const openMoreOptions = Boolean(anchorEl);

	const [editedValues, setEditedValues] = useState({});
	const {
		colMap,
		columns,
		renderColumn,
		data,
		actions,
		special,
		toggleAlert,
		// hasDateRange = false,
		handleClone,
		handleDelete,
		userPermissions,
		handleViewClick,
		handleEditSubmit,
		onExportClick,
		editable,
		renderBtns = () => {},
		showTotal,
		allowCheckList,
		generateProductionPlan,
		moreOptionsList,
		viewMoreOptionsList,
		showBtnContainer = true,
		selectedRows = undefined,
		setSelectedRow = () => {},
		allowExport = true,
		allowChangeColumn = false,
		importState = {
			show: false,
			handleImport: () => {},
		},
		disabledRowSelection = false,
		fixedHeaderTop,
		useWindowScroll = true,
		classname = null,
		btnContainerClassname = null,
	} = props;

	const { url } = useRouteMatch();
	const history = useHistory();

	const [checkedList, setCheckedList] = useState([]);
	const navigateToItem = (id) => {
		history.push(`${url}/view/${id}`);
	};

	// eslint-disable-next-line no-unused-vars
	const [columnsToShow, setColumnsToShow] = useState([]);

	useEffect(() => {
		setColumnsToShow(
			columns?.map((ele) => {
				return ele?.title?.toUpperCase();
			})
		);
	}, [columns]);

	function downloadCSV(csv, filename) {
		// CSV file
		const csvFile = new Blob([csv], {
			type: 'text/csv',
		});

		// Download link
		const downloadLink = document.createElement('a');

		// File name
		downloadLink.download = filename;

		// Create a link to the file
		downloadLink.href = window.URL.createObjectURL(csvFile);

		// Hide download link
		downloadLink.style.display = 'none';

		// Add the link to DOM
		document.body.appendChild(downloadLink);

		// Click download link
		downloadLink.click();
	}

	function codeValue(a = '') {
		return a.charCodeAt(0);
	}
	function exportTableToCSV(filename) {
		const csv = [];
		// const rows = document.querySelectorAll('table tr');
		const rows = document.querySelectorAll(`#${uniqueId} tr`);

		for (let i = 0; i < rows.length; i++) {
			const row = [];
			const cols = rows[i].querySelectorAll('td, th');

			for (let j = 0; j < cols.length; j++) {
				// row.push(cols[j].innerText?.replaceAll(',', '"'));
				// row.push(cols[j].innerText?.replaceAll(',', '-'));
				const p = cols[j].innerText;
				let outp = '';
				for (const a of p) {
					if (codeValue(a) > 255) {
						// eslint-disable-next-line no-continue
						continue;
					}
					outp += a;
				}

				row.push(`"${outp}"`);
			}
			csv.push(row.join(','));
		}

		// Download CSV file
		downloadCSV(csv.join('\n'), filename);
	}

	const handleChangevalue = (value, id) => {
		setEditedValues((prev) => {
			return {
				...prev,
				[id]: value,
			};
		});
	};

	const handleSelection = (itemId, isChecked, all) => {
		if (selectedRows) {
			if (all === 'selectAll') {
				if (selectedRows?.length === data?.length) {
					setSelectedRow([]);
				} else {
					const allPOs = data?.map((ele) => {
						return ele?.id;
					});
					setSelectedRow(allPOs);
				}
			} else if (isChecked) {
				setSelectedRow((prev) => {
					return prev?.filter((ele) => {
						return ele !== itemId;
					});
				});
			} else {
				setSelectedRow((prev) => {
					return [...prev, itemId];
				});
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (all === 'selectAll') {
				if (checkedList?.length === data?.length) {
					setCheckedList([]);
				} else {
					const allPOs = data?.map((ele) => {
						return ele?.id;
					});
					setCheckedList(allPOs);
				}
			} else if (isChecked) {
				setCheckedList((prev) => {
					return prev?.filter((ele) => {
						return ele !== itemId;
					});
				});
			} else {
				setCheckedList((prev) => {
					return [...prev, itemId];
				});
			}
		}
	};

	const handleMoreOptClick = (event, id) => {
		setAnchorEl({
			[id]: event.currentTarget,
		});
	};
	const handleCloseMoreOptions = () => {
		setAnchorEl(null);
	};
	const handleViewMoreOptClick = (event, id) => {
		setViewAnchorEl({
			[id]: event.currentTarget,
		});
	};
	const handleCloseViewMoreOptions = () => {
		setViewAnchorEl(null);
	};

	const generateActionBtns = (item) => {
		const {
			id,
			_name = null,
			orderStatus = null,
			orderId = null,
			mobile = null,
			paymentStatus,
			// paymentType,
		} = item;

		const openMoreOptions = Boolean(anchorEl?.[id]);
		const openViewMoreOptions = Boolean(viewAnchorEl?.[id]);

		const actionBtns = {
			Clone: (
				<td className={`${styles['action-item']}`}>
					<FileCopy
						className={`${styles['action-btn']} ${styles.clone}`}
						onClick={() => {
							handleClone(id);
						}}
					/>
				</td>
			),
			View: (
				<td className={`${styles['action-item']}`}>
					<Visibility
						className={`${styles['action-btn']} ${styles.view}`}
						onClick={() => {
							if (handleViewClick) return handleViewClick(item);
							return navigateToItem(id);
						}}
					/>
				</td>
			),
			Delete: (
				<td className={`${styles['action-item']}`}>
					<DeleteForever
						className={`${styles['action-btn']} ${styles.delete}`}
						onClick={() => {
							toggleAlert(true, {
								heading: 'Delete Item?',
								message: `Click Proceed to delete item ${
									_name != null ? `- ${_name}` : ''
								}`,
								id,
							});
						}}
					/>
				</td>
			),
			Cancel: (
				<td className={`${styles['action-item']}`}>
					{orderStatus !== 'Refunded' &&
						orderStatus !== 'Order Cancelled / Payment Failed' &&
						paymentStatus === 'TXN_SUCCESS' ? (
							<Button
								className='danger-btn'
								onClick={() => {
									toggleAlert(true, {
										heading: 'Cancel Order?',
										message: `Click Proceed to cancel order ${
											_name != null ? `- ${_name}` : ''
										}`,
										id,
										orderId,
										// storeName,
										mobile,
									});
								}}
							>
								Cancel
							</Button>
						) : (
							'-'
						)}
				</td>
			),
			'Change Value': (
				<td className={`${styles['action-item']}`}>
					<Tooltip
						title={
							editDisable ? 'Click On Edit Button to enable' : ''
						}
					>
						<TextField
							type='number'
							variant='outlined'
							margin='normal'
							fullWidth
							name='value'
							onChange={(e) => {
								return handleChangevalue(e?.target?.value, id);
							}}
							disabled={editDisable}
						/>
					</Tooltip>
				</td>
			),
			MORE: (
				<td className={`${styles['action-item']}`}>
					<div
						style={{
							position: 'relative',
						}}
					>
						<IconButton
							aria-label='more'
							id='long-button'
							aria-controls={
								openMoreOptions ? 'long-menu' : undefined
							}
							aria-expanded={openMoreOptions ? 'true' : undefined}
							aria-haspopup='true'
							onClick={(e) => {
								handleMoreOptClick(e, id);
							}}
						>
							<MoreVertIcon />
						</IconButton>
					</div>
					<Menu
						id='long-menu'
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl?.[id]}
						open={openMoreOptions}
						onClose={handleCloseMoreOptions}
						PaperProps={{
							style: {
								maxHeight: '200px',
								width: '25ch',
							},
						}}
					>
						{Object.keys(moreOptionsList ?? {})?.map(
							(optionKey) => {
								const optionValue =
									moreOptionsList?.[optionKey];
								return (
									<MenuItem
										key={optionKey}
										// selected={optionKey === 'Pyxis'}
										onClick={() => {
											optionValue(
												item,
												handleCloseMoreOptions
											);
										}}
									>
										{optionKey}
									</MenuItem>
								);
							}
						)}
					</Menu>
				</td>
			),
			'View More': (
				<td className={`${styles['action-item']}`}>
					<div
						style={{
							position: 'relative',
						}}
					>
						<IconButton
							aria-label='view'
							id='view-long-button'
							aria-controls={
								viewMoreOptionsList
									? 'long-view-menu'
									: undefined
							}
							aria-expanded={
								viewMoreOptionsList ? 'true' : undefined
							}
							aria-haspopup='true'
							onClick={(e) => {
								handleViewMoreOptClick(e, id);
							}}
						>
							<VisibilityIcon />
						</IconButton>
					</div>
					<Menu
						id='long-view-menu'
						MenuListProps={{
							'aria-labelledby': 'view-long-button',
						}}
						anchorEl={viewAnchorEl?.[id]}
						open={openViewMoreOptions}
						onClose={handleCloseViewMoreOptions}
						PaperProps={{
							style: {
								maxHeight: '200px',
								width: '25ch',
							},
						}}
					>
						{Object.keys(viewMoreOptionsList ?? {})?.map(
							(optionKey) => {
								const optionValue =
									viewMoreOptionsList?.[optionKey];
								return (
									<MenuItem
										key={optionKey}
										// selected={optionKey === 'Pyxis'}
										onClick={() => {
											optionValue(
												item,
												handleCloseViewMoreOptions
											);
										}}
									>
										{optionKey}
									</MenuItem>
								);
							}
						)}
					</Menu>
				</td>
			),
		};
		return actions
			.filter((action) => {
				if (action?.config?.type?.toLowerCase() === 'cancel') {
					return userPermissions?.CANCEL;
				}
				if (action?.config?.type?.toLowerCase() === 'delete') {
					return userPermissions?.DELETE;
				}
				if (action?.config?.type?.toLowerCase() === 'view') {
					return userPermissions?.VIEW;
				}
				if (action?.config?.type?.toLowerCase() === 'annotate') {
					return userPermissions?.ANNOTATE;
				}
				if (
					action?.config?.type?.toLowerCase() === 'edit' ||
					action?.config?.type?.toLowerCase() === 'update' ||
					action?.config?.type?.toLowerCase() === 'enabled'
				) {
					return userPermissions?.UPDATE;
				}
				return true;
			})
			.map((action) => {
				const { title = action } = action;
				if (objectContainsProperty(actionBtns, title)) {
					return actionBtns[title];
				}
				const prop = colMap[title];
				const val = item[prop];
				return (
					<td className={`${styles['action-item']}`}>
						{special
							? special[title] && special[title](id, val)
							: ''}
					</td>
				);
			});
	};

	const handleColumnsToShowSelect = (column) => {
		if (columnsToShow?.includes(column)) {
			setColumnsToShow((prev) => {
				return prev?.filter((ele) => {
					return ele !== column;
				});
			});
		} else {
			setColumnsToShow((prev) => {
				return [...prev, column];
			});
		}
	};

	const fixedHeaderContent = () => {
		return (
			<tr>
				{allowCheckList && (
					<th
						style={{
							top: fixedHeaderTop,
						}}
					>
						<div
							style={{
								display: 'flex',
								gap: '0.5rem',
							}}
						>
							<input
								checked={
									data?.length ===
									(selectedRows || checkedList)?.length
								}
								disabled={disabledRowSelection}
								onChange={() => {
									handleSelection(null, null, 'selectAll');
								}}
								type='checkbox'
								className='list-select-input'
							/>
						</div>
					</th>
				)}
				{[
					...columns?.filter((ele) => columnsToShow?.includes(ele?.title?.toUpperCase())),
					...actions,
				]
					.filter((action) => {
						if (action?.config?.type?.toLowerCase() === 'cancel') return userPermissions?.CANCEL;
						if (action?.config?.type?.toLowerCase() === 'delete') return userPermissions?.DELETE;
						if (action?.config?.type?.toLowerCase() === 'view') return userPermissions?.VIEW;
						if (action?.config?.type?.toLowerCase() === 'annotate') return userPermissions?.ANNOTATE;
						if (
							['edit', 'update', 'enabled'].includes(
								action?.config?.type?.toLowerCase()
							)
						) return userPermissions?.UPDATE;
						return true;
					})
					.map((col) => {
						const { title } = col;
						const customCol = renderColumn(title);
						if (customCol != null) {
							return (
								<th
									key={title}
									className={styles['custom-col']}
									style={{
										top: fixedHeaderTop,
									}}
								>
									{customCol}
								</th>
							);
						}
						if (title === 'Change Value') {
							return (
								<th
									key={title}
									className={styles['change-value']}
									style={{
										top: fixedHeaderTop,
									}}
								>
									Change Value
									{editDisable && data?.length > 0 && (
										<div
											onClick={() => {
												setEditDisable(false);
											}}
											className={styles.icon}
										>
											<ModeEdit />
										</div>
									)}
								</th>
							);
						}
						return (
							<th
								key={title}
								className={
									columns?.length < 4 ? styles.center : ''
								}
								style={{
									top: fixedHeaderTop,
								}}
							>
								{title === 'Cancel' ? 'Cancel Order' : title}
							</th>
						);
					})}
			</tr>
		);
	};

	return (
		<>
			{showBtnContainer && (
				<div className={`${styles['btns-container']} ${btnContainerClassname}`}>
					{editable && !editDisable && (
						<Button
							className='primary-btn'
							onClick={() => {
								if (!editDisable) {
									setEditDisable(true);
									handleEditSubmit(editedValues);
								}
							}}
						>
							Submit
						</Button>
					)}
					{renderBtns()}
					{!selectedRows && checkedList?.length > 0 && (
						<Button
							className='primary-btn'
							onClick={() => {
								generateProductionPlan(checkedList);
							}}
						>
							Generate Production Plan
						</Button>
					)}
					{showTotal && (
						<div className={styles.total}>
							<p>Total: </p>
							<p>{data?.length}</p>
						</div>
					)}
					{allowExport && (
						<div
							onClick={() => {
								if (onExportClick) {
									onExportClick();
									return;
								}
								exportTableToCSV(
									props?.exportFilename || 'Table data'
								);
							}}
							className={styles.downloadData}
						>
							<img src={excel} alt='excel' />
							EXPORT
						</div>
					)}
					{importState?.show && (
						<div
							onClick={() => {
								importState?.handleClick();
							}}
							className={styles.import_data}
						>
							<Upload />
							IMPORT
						</div>
					)}
					{allowChangeColumn && (
						<Dropdown title='Columns' placement='bottomEnd'>
							{columns?.map((ele) => {
								const currCol = ele?.title?.toUpperCase();
								return (
									<Dropdown.Item
										className={styles.column_dropdown}
										active={columnsToShow?.includes(
											currCol
										)}
										onSelect={handleColumnsToShowSelect}
										eventKey={currCol}
									>
										{currCol}
									</Dropdown.Item>
								);
							})}
						</Dropdown>
					)}
				</div>
			)}
			<TableVirtuoso
				useWindowScroll={useWindowScroll}
				style={{
					width: '100%',
				}}
				data={data}
				fixedHeaderContent={fixedHeaderContent}
				components={{
					Table: React.forwardRef(({ children }) => (
						<table
							id={uniqueId}
							className={`${styles['list-table-root']} ${styles.sticky} ${classname}`}
						>
							{children}
						</table>
					)),
					TableRow: useMemo(() => {
						return (rowProps) => {
							const { children, ...rest } = rowProps;
							const rowStyles = data?.[rest?.['data-index']]?.rowStyles;
							const newLocal = rest?.item?.onClick ? {
								onClick: rest?.item?.onClick,
							} : {};
							const key = rest?.['data-item-index'];
							return (
								<tr
									key={key}
									{...rest}
									{...newLocal}
									className={`border-r border-neutral-20 ${rowStyles}`}
								>
									{children}
								</tr>
							);
						};
					}, [columns, actions, data]),
				}}
				itemContent={useMemo(() => {
					return (_, item) => {
						const isChecked = selectedRows
							? selectedRows.includes(item?.id)
							: checkedList.includes(item?.id);

						return (
							<>
								{allowCheckList && (
									<td>
										<input
											type='checkbox'
											checked={isChecked}
											disabled={disabledRowSelection}
											onChange={() => {
												handleSelection(
													item?.id,
													isChecked
												);
											}}
										/>
									</td>
								)}
								{columns
									.filter((col) => {
										if (
											col?.config?.type?.toLowerCase() ===
											'cancel'
										) return userPermissions?.CANCEL;
										if (
											col?.config?.type?.toLowerCase() ===
											'delete'
										) return userPermissions?.DELETE;
										if (
											col?.config?.type?.toLowerCase() ===
											'view'
										) return userPermissions?.VIEW;
										if (
											['update', 'enabled'].includes(
												col?.config?.type?.toLowerCase()
											)
										) return userPermissions?.UPDATE;
										return columnsToShow?.includes(
											col?.title?.toUpperCase()
										);
									})
									.map((col) => {
										const { title } = col;
										const prop = colMap[title];
										const value = get(item, prop);
										if (
											objectContainsProperty(
												special,
												title
											)
										) {
											return (
												<td key={title}>
													{special[title](
														value,
														item
													) ?? '-'}
												</td>
											);
										}
										return (
											<td key={title}>{value ?? '-'}</td>
										);
									})}
								{generateActionBtns(item)}
							</>
						);
					};
				}, [data, columns, colMap])}
			/>
			<AlertModal
				handleConfirm={(args) => {
					handleDelete(args);
					toggleAlert(false);
				}}
			/>
		</>
	);
};

VirtualizedListTable.defaultProps = {
	columns: [],
	actions: [],
	special: {},
	sticky: true,
	data: [],
	renderColumn: () => {
		return null;
	},
	fixedHeaderTop: '50px',
};

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleAlert: modalActions.toggleAlert,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VirtualizedListTable);
