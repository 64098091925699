import { connect } from 'react-redux';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { formActions } from '../../../Actions';
// import { generateRequiredProps } from '../../../Helpers';
import styles from './PromoCodeDetails.module.scss';
import { generateRandomKey } from '../../../Helpers';

const PromoCodeDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		isDisabled,
		handleChangeDateTime,
	} = props;
	const handleStartsFromDateTime = (e) => {
		handleChangeDateTime(e, 'applicableFrom');
	};
	const handleEndsFromDateTime = (e) => {
		handleChangeDateTime(e, 'applicableTo');
	};

	return (
		<div className={`${styles.root}`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* applicable from */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='applicableFrom'
						data-mandatory={mandatoryFields.applicableFrom}
					>
						Applicable From:
					</label>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							disableOpenPicker={isDisabled}
							value={fields.applicableFrom}
							onChange={handleStartsFromDateTime}
							renderInput={(params) => {
								return <TextField {...params} />;
							}}
							{...generateRandomKey}
						/>
					</LocalizationProvider>
					<span className='form-error'>{errors.applicableFrom}</span>
				</div>
				{/* applicable to */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='applicableTo'
						data-mandatory={mandatoryFields.applicableTo}
					>
						Applicable To:
					</label>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							disableOpenPicker={isDisabled}
							value={fields.applicableTo}
							onChange={handleEndsFromDateTime}
							renderInput={(params) => {
								return <TextField {...params} />;
							}}
							{...generateRandomKey}
						/>
					</LocalizationProvider>
					<span className='form-error'>{errors.applicableTo}</span>
				</div>
			</div>
		</div>
	);
};

PromoCodeDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const data = state.form.PROMO_CODES.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchPromoCode: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeDetails);
