/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Description',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Code',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Name: 'name',
	Description: 'description',
	Category: 'categoryName',
	Code: 'code',
};

const ProductSubCategoryListPage = (props) => {
	const {
		fetchProductSubCategory,
		data,
		deleteProductSubCategory,
		query,
		navState,
		fetchUserList,
		userList,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const { userConfig } = useAuth();

	const fetchSubCategoryFunc = async () => {
		setIsLoading(true);
		await fetchProductSubCategory({
			entity: 'PRODUCT_SUB_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		setIsLoading(false);
	};

	useEffect(() => {
		fetchSubCategoryFunc();
	}, []);

	const handleDelete = ({ id }) => {
		deleteProductSubCategory({
			entity: 'PRODUCT_SUB_CATEGORY',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	const SPECIAL_ITEMS = {};
	// USER START
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu?.['/products/sub/category']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// USER END

	return (
		<div className='list-page-root'>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				fixedHeaderTop={0}
				data={data
					.filter((category) => {
						return (
							category.name
								.toLowerCase()
								.indexOf(query?.trim()?.toLowerCase()) !== -1
						);
					})
					.map((category) => {
						return {
							...category,
							_name: category?.[COLMAP?.Name],
						};
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	const { navState, userPermissions } = state.navigation;
	const { userList } = state.auth;
	return {
		data,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProductSubCategory: formActions.gateway,
	toggleDisableProductCategory: formActions.gateway,
	deleteProductSubCategory: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductSubCategoryListPage);
