import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../';
import { classes } from '../../../Helpers';
import analyticsAction from '../../../Actions/analytics.actions';
import DynamicHeaderTable from '@/Components/DynamicHeaderTable/DynamicHeaderTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const TABLE_COLUMNS = [
	{
		colName: 'Stock Status',
		id: 2,
		subCols: [
			{
				colName: 'Good/Healthy Stock',
				id: 'stock',
				subCols: [],
			},
		],
	},
	{
		colName: 'Wastage',
		id: 3,
		subCols: [
			{
				colName: 'Expired',
				id: 'expired',
				subCols: [],
			},
			{
				colName: 'Non-Usable',
				id: 'nonUsable',
				subCols: [],
			},
		],
	},
	{
		colName: 'Unexpected Stock Fund',
		id: 4,
		subCols: [
			{
				colName: 'Not Recived against  PO @Outlets',
				id: 'notRecived',
				subCols: [],
			},
			{
				colName: 'Not Scanned / Dispatched in Sale @ Outlets',
				id: 'notScanned',
				subcols: [],
			},
		],
	},
	{
		colName: 'Stock Dispatched Without Tracebility',
		id: 'stockDispatched',
		subCols: [],
	},
	{
		colName: 'Out of Order Dispatch',
		id: 'outOfOrderDispatch',
		subCols: [],
	},
	{

		colName: 'Missing Inventory/Items',
		id: 'missingInventory',
		subCols: [],
	},
	{
		colName: 'Expected Stock@Date',
		id: 'expectedStock',
		subCols: [],
	},
];

const DayEndReportListTable = (props) => {
	const {
		data,
		setAnalyticsType,
		ANALYTICS_TYPE,
		analysisFilters,
		setAnalysisDashboardFilters,
	} = props;

	useEffect(() => {
		setAnalysisDashboardFilters(
			{
				...analysisFilters,
				analysisType: 'BOM_INGREDIENT',
			},
			false
		);
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, []);

	const renderColumn = ({ rowItem, colId }) => {
		return rowItem[colId] || '-';
	};

	return (
		// eslint-disable-next-line no-undef
		<div className={classes('list-page-root')}>
			<DynamicHeaderTable
				headerData={TABLE_COLUMNS}
				colors={TABLE_COLUMNS.map(() => {
					return '#f1f1f1';
				})}
				rowsData={data}
				tableDataElement={renderColumn}
				hideConstantCol
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { ANALYSIS_DASHBOARD_FILTERS } = state.analytics;
	return {
		analysisFilters: ANALYSIS_DASHBOARD_FILTERS,
	};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	setAnalysisDashboardFilters: analyticsAction.setAnalysisDashboardFilters,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DayEndReportListTable);
