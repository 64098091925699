import { cloneDeep, update } from 'lodash';
import { notificationServices } from '../../Services';

const config = {
	initialState: {
		fields: {
			name: '',
			brand: null,
			type: {
				IDENTITY: null,
				QUALITY: 'QUALITY',
			},
			products: [],
			image: '',
			productId: null,
			skuMultiplyFactor: 1,
		},
	},
	mandatoryFields: {
		name: true,
		brand: true,
	},
	validator: (state) => {
		// if (
		// state.fields.type.IDENTITY === '' &&
		// state.fields.type.QUALITY === ''
		// ) {
		// notificationServices.generateNotification({
		// type: 'error',
		// message: 'Please select at least 1 feature type.',
		// });
		// return false;
		// }

		if (state.fields.brand == null) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a brand.',
			});
			return false;
		}
		if (state.fields.type.IDENTITY && !state.fields.productId) {
			notificationServices.generateNotification({
				type: 'error',
				message: '"Product is" is mandatory for Indentity type Feature',
			});
			return false;
		}
		// if (state.fields.type.IDENTITY && state.fields.productId == null) {
		// notificationServices.generateNotification({
		// type: 'error',
		// message: 'Feature type "Identity", Please select "Product is".',
		// });
		//  false;
		// }

		return true;
	},
	serializer: (state, data) => {
		const clonedState = cloneDeep(state);
		return update(clonedState, 'fields', () => {
			return {
				name: data?.label ?? '',
				brand: data?.brandId ?? null,
				type: {
					IDENTITY:
						data.type === 'IDENTITY' && data?.productId
							? 'IDENTITY'
							: null,
					QUALITY: data.type === 'QUALITY' ? 'QUALITY' : null,
					COMPONENT:
						data.type === 'IDENTITY' && !data?.productId
							? 'COMPONENT'
							: null,
				},
				image: data.image,
				products: data?.LabelsMapping?.map((product) => {
					return {
						id: product.id,
						productId: product.productId,
					};
				}),
				productId: data?.productId,
				skuMultiplyFactor: data?.skuMultiplyFactor,
			};
		});
	},
	deserializer: (state) => {
		return new Promise((resolve) => {
			resolve({
				data: {
					label: state.fields.name,
					brandId: state.fields.brand,
					// eslint-disable-next-line no-nested-ternary
					type:
						state.fields.type.IDENTITY ||
						state.fields.type.COMPONENT
							? 'IDENTITY'
							: 'QUALITY',
					image: state.fields.image,
					LabelsMapping: state.fields.products.map((product) => {
						return {
							id: product.id,
							productId: product.productId,
						};
					}),
					...(state.fields.type.IDENTITY && {
						productId: state?.fields?.productId,
					}),
					...(state.fields.type.COMPONENT && {
						productId: null,
					}),
					skuMultiplyFactor: state?.fields?.skuMultiplyFactor,
				},
			});
		});
	},
};

export default config;
