import { Crop } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { TextField } from '@mui/material';
import _, { get } from 'lodash';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import stateCityActions from '../../../Actions/stateCity.actions';
import {
	generateDisabledProps,
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper,
} from '../../../Helpers';
import { ImageCropModal } from '../../Modals/ImageCropModal';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		fields,
		errors,
		mandatoryFields,
		handleInput,
		isDisabled,
		generateDefaultOption,
		handleImageInput,
		stateCity,
		toggleImageCrop,
	} = props;
	useEffect(() => {
		props.fetchStateCity({
			id: '',
			allData: true,
		});
	}, []);
	const [value1, setValue1] = useState(fields.startTime);
	const [value2, setValue2] = useState(fields.endTime);

	useEffect(() => {
		setValue1(fields.startTime);
		setValue2(fields.endTime);
	}, [fields]);

	const handleInputChnage = (name, newValue) => {
		const time = _.cloneDeep(newValue);
		// const value = moment(time).format('HH:mm');
		if (name === 'startTime') {
			setValue1(newValue);
			fields.startTime = time;
		} else {
			setValue2(newValue);
			fields.endTime = time;
		}
	};

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	return (
		<div className={`${styles.root}`}>
			<fieldset
				{...generateDisabledProps(isDisabled)}
				className='form-fieldset row'
				style={{
					alignItems: 'flex-start',
				}}
			>
				<div
					style={{
						flexBasis: '50%',
					}}
				>
					{/* Rider name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='riderName'
							data-mandatory={mandatoryFields.name}
						>
							Rider Name
						</label>
						<input
							className='form-value'
							type='text'
							id='riderName'
							name='name'
							onInput={handleInput}
							value={fields.name}
							{...generateRequiredProps(mandatoryFields.name)}
						/>
						<span className='form-error'>{errors.name}</span>
					</div>
					{/* Address */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='address1'
							data-mandatory={mandatoryFields.address1}
						>
							Address Line 1
						</label>
						<textarea
							className='form-value'
							type='text'
							id='address1'
							name='address1'
							onInput={handleInput}
							value={fields.address1}
							{...generateRequiredProps(mandatoryFields.address1)}
						/>
						<span className='form-error'>{errors.address1}</span>
					</div>
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='address2'
							data-mandatory={mandatoryFields.address2}
						>
							Address Line 2
						</label>
						<textarea
							className='form-value'
							type='text'
							id='address2'
							name='address2'
							onInput={handleInput}
							value={fields.address2}
							{...generateRequiredProps(mandatoryFields.address2)}
						/>
						<span className='form-error'>{errors.address2}</span>
					</div>
					{/* city name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='city'
							data-mandatory={mandatoryFields.city}
						>
							City
						</label>
						<select
							className='form-value'
							id='city'
							name='city'
							onChange={handleInput}
							value={
								fields.city
									? JSON.stringify(fields.city)
									: fields.city
							}
							{...generateRequiredProps(mandatoryFields.city)}
						>
							{!isDisabled &&
								generateDefaultOption('city', fields.city)}
							{stateCity.map((obj) => {
								return (
									<option value={JSON.stringify(obj)}>
										{obj.cityName}
									</option>
								);
							})}
							{/* <option value='1'>Default</option> */}
						</select>
						<span className='form-error'>{errors.city}</span>
					</div>
					{/* state name */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='state'
							data-mandatory={mandatoryFields.state}
						>
							State
						</label>
						<input
							className='form-value'
							type='text'
							value={get(fields.city, 'stateName', null)}
							readOnly
						/>
						<span className='form-error'>{errors.state}</span>
					</div>
					{/* rider phone number */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='phoneNo'
							data-mandatory={mandatoryFields.phoneNo}
						>
							Rider Contact No.
						</label>
						<input
							className='form-value'
							type='text'
							id='phoneNo'
							name='phoneNo'
							onInput={handleInput}
							value={fields.phoneNo}
							{...generateRequiredProps(mandatoryFields.phoneNo)}
						/>
						<span className='form-error'>{errors.phoneNo}</span>
					</div>
					{/* Vehivle number */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='vehicleNo'
							data-mandatory={mandatoryFields.vehicleNo}
						>
							Vehicle No.
						</label>
						<input
							className='form-value'
							type='text'
							id='vehicleNo'
							name='vehicleNo'
							onInput={handleInput}
							value={fields.vehicleNo}
							{...generateRequiredProps(
								mandatoryFields.vehicleNo
							)}
						/>
						<span className='form-error'>{errors.vehicleNo}</span>
					</div>
					{/* License no. */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='licenseNo'
							data-mandatory={mandatoryFields.licenseNo}
						>
							License No.
						</label>
						<input
							className='form-value'
							type='text'
							id='licenseNo'
							name='licenseNo'
							onInput={handleInput}
							value={fields.licenseNo}
							{...generateRequiredProps(
								mandatoryFields.licenseNo
							)}
						/>
						<span className='form-error'>{errors.licenseNo}</span>
					</div>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='startTime'
								data-mandatory={mandatoryFields.startTime}
							>
								Start Time
							</label>
							<TimePicker
								disableOpenPicker={isDisabled}
								label='Start Time'
								value={value1}
								onChange={(newValue) => {
									handleInputChnage('startTime', newValue);
								}}
								renderInput={(params) => {
									return <TextField {...params} />;
								}}
							/>
						</div>
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='endTime'
								data-mandatory={mandatoryFields.endTime}
							>
								End Time
							</label>
							<TimePicker
								label='End Time'
								value={value2}
								disableOpenPicker={isDisabled}
								onChange={(newValue) => {
									handleInputChnage('endTime', newValue);
								}}
								renderInput={(params) => {
									return <TextField {...params} />;
								}}
							/>
						</div>
					</LocalizationProvider>
				</div>
				<div
					style={{
						marginLeft: '3rem',
						flexBasis: '50%',
					}}
				>
					{/* display image */}
					<div
						className='form-field row'
						style={{
							alignItems: 'flex-start',
						}}
					>
						<label
							className='form-label'
							data-mandatory={mandatoryFields.image}
						>
							Display Image
						</label>
						<div className='display-pic-root ratio-3-4'>
							<div className='display-pic-preview'>
								<img
									src={
										isDisabled
											? fields.image
											: generateImageUrl(fields.image)
									}
									className={`display-pic ${
										fields.image ? 'show' : 'hide'
									}`}
									alt='rider-display'
								/>
								{generateImageEditButton({
									isEdit:
										fields.image &&
										fields.image.includes('data:image/jpeg;base64'),
									button: (
										<button
											type='button'
											onClick={() => {
												openImageCropModal('image');
											}}
											className='display-pic-edit'
										>
											<Crop />
										</button>
									),
								})}
							</div>
							{generateImageUploadButton({
								isDisabled,
								label: (
									<label
										className='display-pic-label'
										htmlFor='displayPic'
									>
										Choose an image
									</label>
								),
							})}
							<input
								type='file'
								id='displayPic'
								accept='image/png, image/jpg, image/jpeg, image/webp'
								name='image'
								onChange={handleImageSelect}
								style={{
									opacity: 0,
									width: 1,
									height: 1,
								}}
							/>
						</div>
					</div>
					<ImageCropModal
						handleConfirm={handleImageInput}
					/>
				</div>
			</fieldset>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleImageInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const { stateCity } = state.stateCity;
	return {
		stateCity,
	};
};

const mapDispatchToProps = {
	fetchStateCity: stateCityActions.fetchStateCity,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
