import { Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { generateCheckedProps } from '../../Helpers';
import styles from './FormSectionHeader.module.scss';

const FormSectionHeader = (props) => {
	const {
		tooltip,
		sectionName,
		children,
		isExpandable,
		isChecked,
		name,
		handleIsChecked,
	} = props;

	return (
		<div className={`${styles.root}`}>
			<div className={`${styles['text-wrapper']}`}>
				{isExpandable && (
					<input
						type='checkbox'
						name={name}
						defaultChecked={isChecked}
						onChange={() => {
							handleIsChecked(name, isChecked);
						}}
						{...generateCheckedProps(isChecked)}
						className={`${styles.checkbox}`}
					/>
				)}
				<p className={`${styles['text-container']}`}>
					<span className={`${styles['section-name']}`}>
						{sectionName}
					</span>
					{tooltip && (
						<Tooltip placement='right-start' title={tooltip}>
							<HelpOutline className={`${styles['help-icon']}`} />
						</Tooltip>
					)}
				</p>
			</div>
			{isChecked ? children : ''}
		</div>
	);
};

FormSectionHeader.defaultProps = {
	isChecked: true,
	isExpandable: false,
};

export default FormSectionHeader;
