/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */

import { connect } from 'react-redux';
import {
	Button, Chip, TextField
} from '@mui/material';
import {
	useReducer, useEffect, useState,
	useMemo
} from 'react';
import InputPicker from 'rsuite/InputPicker';
import { useHistory } from 'react-router';
import TagPicker from 'rsuite/TagPicker';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CheckPicker, SelectPicker } from 'rsuite';
import _ from 'lodash';
import styles from './CreateUserPage.module.scss';
import { BackdropLoader } from '../../../../Components/Loaders';
import config from './createUserPage.config';
import {
	inputHelper, supplyChainFeilds, useQuery
} from '../../../../Helpers';
import { notificationServices } from '../../../../Services';
import { CreatePageHeader } from '../../../../Components/Headers';
import { formActions, modalActions } from '../../../../Actions';
import assetActions from '../../../../Actions/asset.actions';
import { RegionMenuModal } from '../../../../Components/Modals/RegionMenuModal';
import regionStateServices from '../../../../Services/regionState.services';
import DynamicHeaderTable from '../../../../Components/DynamicHeaderTable/DynamicHeaderTable';
import userRolesAndPermissions from '../../../../Services/userRolesAndPermissions.services';
import DescriptionBox from '../../../../Components/DescriptionBox/DescriptionBox';
import { ImageCropModal } from '@/Components/Modals/ImageCropModal';

const regions = [
	{
		label: 'All Region',
		color: '#94a3b8',
	},
	{
		label: 'Region',
		color: '#facc15',
	},
	{
		label: 'State',
		color: '#008080',
	},
	{
		label: 'City',
		color: '#FF7F50',
	},
	{
		label: 'Store',
		color: '#2dd4bf',
	},
];

const colors = regions.map((item) => {
	return item.color;
});

const roundedCancel = (
	<span className={`${styles.excludeIcon}`}>
		<CancelIcon />
	</span>
);

const roundedTick = (
	<span className={`${styles.includeIcon}`}>
		<CheckCircleIcon />
	</span>
);

// const [selectedLevels] = {};

const descriptionData = [
	{
		component: (
			<div className={`${styles.checkbox} ${styles.negative}`}>
				<svg
					id='i-close'
					viewBox='0 0 32 32'
					width='16'
					height='16'
					fill='none'
					stroke='currentcolor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='10.9375%'
				>
					<path d='M2 30 L30 2 M30 30 L2 2' />
				</svg>
			</div>
		),
		label: 'Roles Applicable On Selected Areas Mapped With It',
	},
	{
		component: (
			<div className={`${styles.checkbox} ${styles.positive}`}>
				<svg
					id='i-checkmark'
					viewBox='0 0 32 32'
					width='16'
					height='16'
					fill='none'
					stroke='currentcolor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='10.9375%'
				>
					<path d='M2 20 L12 28 30 4' />
				</svg>
			</div>
		),
		label: 'Roles Are Applicable To All Its Mapped Area',
	},
	{
		component: <div className={`${styles.checkbox}`} />,
		label: 'Roles Applicable As Its Parent',
	},
];

const descriptionTableData = [
	{
		component: roundedCancel,
		label: 'Access is not allowed to that region',
	},
	{
		component: roundedTick,
		label: 'Choose level to overwrite its parent access level',
	},
];

function CreateUserPage(props) {
	const {
		fetchStoresList,
		fetchProcessTypesList,
		fetchStoreAssetListing,
		toggleModal,
		storesList,
		processTypes,
		toggleImageCrop,
	} = props;
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const {
		username,
		password,
		confirmationPassword,
		displayName,
		email,
		contact,
		store,
		selectedLevels,
		userRoles,
		saveMenuState,
		appUser,
		machineUser,
		userLevel,
		processIds,
		consoleUser,
		processPasscode,
		image,
	} = state;
	const params = useQuery();
	const history = useHistory();
	const id = parseInt(params.get('id'), 10);
	const isEdit = Boolean(params.get('isEdit'));
	const [errors, setErrors] = useState({});
	const [isLoading, setIsloading] = useState(false);
	const [userRolesList, setUserRolesList] = useState([]);
	const [Levels, setLevels] = useState([]);
	const [menuState, setMenuState] = useState([]);
	const [parentChildMap, setParentChildMap] = useState({});
	const [childParentDict, setChildParentDict] = useState({});
	const [selectedChip, setSelectedChip] = useState('');
	const [prevData, setPrevData] = useState({});

	const levelOptions = useMemo(
		() => Levels?.map((item) => ({
			label: item?.name,
			value: item?.value,
		})),
		[Levels]
	);

	useEffect(() => {
		if (isEdit) {
			setPrevData(state);
		}
	}, [isEdit]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (machineUser && store) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: store,
					},
				},
				method: 'LIST',
			});
		}
	}, [machineUser, store]);

	useEffect(() => {
		if (isEdit) {
			const chipSelect = storesList?.find((item) => item?.id === store)?.type;
			const selectedSupplyChain = supplyChainFeilds.find((ele) => {
				if (Array.isArray(ele?.value)) {
					return ele?.value?.find((subEle) => {
						return subEle === chipSelect;
					});
				}
				return ele?.value === chipSelect;
			});
			setSelectedChip(selectedSupplyChain?.value);
		}
	}, [isEdit, store, state]);

	const process = useMemo(() => {
		const processData = processTypes?.filter((item) => item?.data?.type === 'OPERATION');
		const data = [
			...processData?.map((item) => {
				return {
					label: item?.data?.heading,
					value: item?.id,
				};
			}),
		];
		return data;
	}, [processTypes]);

	const handleAppUserChange = (checked) => {
		dispatch({
			type: 'SET_VALUE',
		  	payload: {
				appUser: checked,
				machineUser: false,
				consoleUser: false,
				userLevel: (prevData?.level && prevData?.appUser) ? prevData?.level : null,
				store: (prevData?.store && prevData?.appUser) ? prevData?.store : null,
				processIds:	[],
				processPasscode: prevData?.appUser ? prevData?.processPasscode : '',
				userRoles: [],
				selectedLevels: {},
				saveMenuState: {},
			},
		});
		setSelectedChip('');
	};

	  const handleMachineUserChange = (checked) => {
		dispatch({
		  type: 'SET_VALUE',
		  payload: {
				machineUser: checked,
				appUser: false,
				consoleUser: false,
				userLevel: (prevData?.level && prevData?.machineUser) ? prevData?.level : null,
				store: (prevData?.store && prevData?.machineUser) ? prevData?.store : null,
				processIds: prevData?.processIds ? prevData?.processIds : [],
				processPasscode: prevData?.processPasscode ? prevData?.processPasscode : '',
				userRoles: [],
				selectedLevels: {},
				saveMenuState: {},
		  },
		});
		setSelectedChip('');
	  };
	  const handleConsoleUserChange = (checked) => {
		dispatch({
		  type: 'SET_VALUE',
		  payload: {
				consoleUser: checked,
				appUser: false,
				machineUser: false,
				userLevel: (prevData?.level && (!prevData?.appUser && (prevData?.machineUser === null || prevData?.machineUser === 0))) ? prevData?.level : null,
				store: null,
				processIds: [],
				processPasscode: '',
				userRoles: prevData?.userRoles ? prevData?.userRoles : [],
				selectedLevels: prevData?.selectedLevels ? prevData?.selectedLevels : {},
				saveMenuState: prevData?.saveMenuState ? prevData?.saveMenuState : {},
		  },
		});
		setSelectedChip('');
	  };
	  const handleChipSelect = (item) => {
		setSelectedChip(item);
	};
	// const STORES_LIST = useMemo(() => {
	// 	const tempData = [
	// 		...storesList?.map((ele) => {
	// 			return {
	// 				label: ele.name,
	// 				value: ele.id,
	// 			};
	// 		}),
	// 	];
	// 	return tempData;
	// }, []);

	const handleStore = (item) => {
		if (Array.isArray(item)) {
			return storesList
				?.filter((ele) => ['WAREHOUSE_INGREDIENT', 'WAREHOUSE', 'WAREHOUSE_PRODUCT'].includes(ele?.type))
				.map((ele) => ({
					label: ele?.name,
					value: ele?.id,
				}));
		}
		switch (item) {
			case 'WAREHOUSE_INGREDIENT':
			case 'WAREHOUSE':
			case 'WAREHOUSE_PRODUCT':
				return storesList
					?.filter((ele) => ['WAREHOUSE_INGREDIENT', 'WAREHOUSE', 'WAREHOUSE_PRODUCT'].includes(ele?.type))
					.map((ele) => ({
						label: ele?.name,
						value: ele?.id,
					}));
		  case 'BASE':
				return storesList
			  ?.filter((ele) => ele?.type === item)
			  .map((ele) => ({
						label: ele?.name,
						value: ele?.id,
			  }));
		  case 'RETAIL':
				return storesList
			  ?.filter((ele) => ele?.type === item)
			  .map((ele) => ({
						label: ele?.name,
						value: ele?.id,
			  }));
		  case 'transit':
				return [{
					label: 'No Store', value: 'none',
				}];
		  default:
				return [];
		}
	  };

	useEffect(() => {
		if (store) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: store,
					},
				},
				method: 'LIST',
			});
			fetchStoreAssetListing({
				req: {
					data: {
						storeId: store,
						permanent: true,
						category: 'IMMOVABLE',
					},
				},
			});
		}
	}, [store]);
	// here is user input state

	function convertJSON(jsonArray, level = 0) {
		return jsonArray.map((item) => {
			const newItem = {
				...item,
				color: level < regions.length ? regions[level].color : 'gray',
			};
			const key = item?.type + '-' + item?.id;
			if (item.children) {
				newItem.children = convertJSON(item.children, level + 1, key);
			}
			return newItem;
		});
	}

	const mapParentChild = (
		myParent = null,
		menu,
		parentChildArray = {},
		childParentDictParam = {}
	) => {
		menu?.forEach((item, index) => {
			if (item.select === 1) {
				const key = item.id + '-' + item.type;
				if (myParent) {
					if (parentChildArray[myParent]?.length) {
						parentChildArray[myParent].push(key);
					} else {
						parentChildArray[myParent] = [];
						parentChildArray[myParent].push(key);
					}
				}
				childParentDictParam[key] = myParent;
				const selectedOptions = selectedLevels;
				Object.entries(selectedOptions).forEach(([valKey, value]) => {
					if (!value[key]?.parentValue && myParent) {
						let parentVal = selectedOptions[valKey][myParent]?.parentValue || '1';
						if (
							!selectedOptions[valKey][myParent]?.parentValue ||
							(selectedOptions[valKey][myParent]?.override === 1 &&
							selectedOptions[valKey][myParent]?.select !== -1)
						) {
							// console.log('inqqqqq');
							parentVal = selectedOptions[valKey][myParent]?.value || '1';
						}
						value[key] = {
							...value[key],
							parentValue: parentVal,
						};
						if (!value[key]?.value) {
							value[key] = {
								...value[key], value: consoleUser ? userLevel : '1',
							};
						}
					}
				});
				dispatch({
					type: 'SET_VALUE',
					payload: {
						selectedLevels: selectedOptions,
					},
				});
				mapParentChild(
					key,
					item?.children,
					parentChildArray,
					childParentDictParam
				);
			} else {
				mapParentChild(
					myParent,
					item?.children,
					parentChildArray,
					childParentDictParam
				);
			}
		});
	};

	useEffect(() => {
		const parentChildArray = {};
		const childParentDictParam = {};
		mapParentChild(null, menuState, parentChildArray, childParentDictParam);
		// console.log('ppppp', childParentDict, parentChildArray);
		setParentChildMap(parentChildArray);
		setChildParentDict(childParentDictParam);
	}, [menuState, selectedLevels, userLevel]);

	const EditableInput = ({ colId, type, rowItem }) => {
		function getSelectedValue(levels, selId, key, modifyType) {
			if (levels && levels[selId] && levels[selId][key]) {
				let specificKey;
				switch (modifyType) {
					case 'value':
						if (
							!levels[selId][key].override &&
							levels[selId][key].parentValue
						) {
							specificKey = levels[selId][key].parentValue;
						} else specificKey = levels[selId][key].value;
						break;
					case 'override':
						specificKey = levels[selId][key].override;
						break;
					case 'select':
						specificKey = levels[selId][key].select;
						break;
					default:
						break;
				}
				return specificKey !== undefined ? specificKey : 0;
			}
			return 0;
		}

		const updateValue = (selectedOptions, parentKey, val) => {
			parentChildMap?.[parentKey]?.forEach((childKey) => {
				if (!selectedOptions[rowItem.id][childKey]) {
					selectedOptions[rowItem.id][childKey] = {};
				}
				selectedOptions[rowItem.id][childKey].parentValue = val;
				if (!selectedOptions[rowItem.id][childKey]?.override) {
					updateValue(selectedOptions, childKey, val);
				} else if (selectedOptions[rowItem.id][childKey].select === -1) {
					updateValue(selectedOptions, childKey, val);
				}
			});
			// console.log(selectedOptions);
		};

		const handleChange = (val, modifyType) => {
			const selectedOptions = selectedLevels;

			if (!selectedOptions[rowItem.id]) {
				selectedOptions[rowItem.id] = {};
			}
			const key = `${colId}-${type}`;

			if (!selectedOptions[rowItem.id][key]) {
				selectedOptions[rowItem.id][key] = {};
			}

			switch (modifyType) {
				case 'value':
					selectedOptions[rowItem.id][key].value = val;
					updateValue(selectedOptions, key, val);
					break;
				case 'override':
					selectedOptions[rowItem.id][key].override = val;
					if (val === 1) {
						updateValue(selectedOptions, key, selectedOptions[rowItem.id][key].value);
					} else {
						updateValue(selectedOptions, key, selectedOptions[rowItem.id][key].parentValue);
					}
					break;
				case 'select':
					selectedOptions[rowItem.id][key].select = val;
					if (val !== -1 && (selectedOptions[rowItem.id][key].override || !childParentDict[key])) {
						updateValue(selectedOptions, key, selectedOptions[rowItem.id][key].value);
					} else {
						updateValue(selectedOptions, key, selectedOptions[rowItem.id][key].parentValue);
					}
					break;
				default:
					break;
			}

			dispatch({
				type: 'SET_VALUE',
				payload: {
					selectedLevels: selectedOptions,
				},
			});
		};

		return (
			<div className={`${styles.dropdown}`}>
				{/* <select
					value={getSelectedValue(
						selectedLevels,
						rowItem.id,
						`${colId}-${type}`,
						'value'
					)}
					className={`${styles.dropdownMenu}`}
					onChange={(e) => {
						handleChange(e?.target?.value, 'value');
					}}
					disabled={
						getSelectedValue(
							selectedLevels,
							rowItem.id,
							`${colId}-${type}`,
							'select'
						) === -1 ||
							getSelectedValue(
								selectedLevels,
								rowItem.id,
								`${colId}-${type}`,
								'override'
							) === 0
					}
				>
					{Levels.map((level) => {
						return (
							<option key={level?.id} value={level?.value}>
								{level.name}
							</option>
						);
					})}
				</select>
				<div className={styles.checkToggleContainer}>
					<input
						type='checkbox'
						className={styles.checkboxType}
						disabled={
							!childParentDict[`${colId}-${type}`] ||
							getSelectedValue(
								selectedLevels,
								rowItem.id,
								`${colId}-${type}`,
								'select'
							) === -1
						}
						checked={
							getSelectedValue(
								selectedLevels,
								rowItem.id,
								`${colId}-${type}`,
								'override'
							) === 1
						}
						onChange={(e) => {
							// console.log(e.target.checked);
							if (e.target.checked) {
								handleChange(1, 'override');
							} else handleChange(0, 'override');
						}}
					/> */}
				{getSelectedValue(
					selectedLevels,
					rowItem.id,
						`${colId}-${type}`,
						'select'
				) !== -1 ? (
				// eslint-disable-next-line react/jsx-indent
						<span
							onClick={() => {
								handleChange(-1, 'select');
							}}
							className={`${styles.includeIcon}`}
						>
							<CheckCircleIcon />
						</span>
					) : (
						<span
							onClick={() => {
								handleChange(0, 'select');
							}}
							className={`${styles.excludeIcon}`}
						>
							<CancelIcon />
						</span>
					)}
			</div>
		);
	};

	function mappingToSaveSelection(newtableState) {
		let reqData = {};
		newtableState?.forEach((item) => {
			if (item?.select !== 0) {
				// eslint-disable-next-line prefer-template
				const key = item?.id + '-' + item?.type;
				reqData[key] = item?.select;
			}
			const childReq = mappingToSaveSelection(item?.children);
			reqData = {
				...reqData,
				...childReq,
			};
		});
		return reqData;
	}

	useEffect(() => {
		updateSelectedLevels('userRoles');
	}, [userRoles, userLevel]);

	useEffect(() => {
		updateSelectedLevels('menuState');
	}, [saveMenuState, childParentDict, userLevel]);

	function updateSelectedLevels(modifiedType) {
		const selectedOptions = selectedLevels;
		if (modifiedType === 'menuState') {
			Object.values(selectedOptions)?.forEach((value) => {
				Object.keys(value)?.forEach((valKey) => {
					if (saveMenuState[valKey] !== 1) {
						// eslint-disable-next-line no-param-reassign
						delete value[valKey];
					}
				});
			});
			Object.entries(saveMenuState).forEach(([menuKey, menuValue]) => {
				Object.values(selectedOptions)?.forEach((value) => {
					if (menuValue === 1 && !value[menuKey]) {
						value[menuKey] = {
							override: childParentDict[menuKey] ? 0 : 1,
							value: consoleUser ? userLevel : 1,
							// value: 1,
						};
					} else if (menuValue === 1 &&
						value[menuKey]?.override === 0 &&
						!childParentDict[menuKey]) {
						value[menuKey].override = 1;
					}
				});
			});
		} else if (modifiedType === 'userRoles') {
			Object.keys(selectedOptions)?.forEach((value) => {
				if (!userRoles?.includes(parseInt(value, 10))) {
					delete selectedOptions[value];
				}
			});
			userRoles.forEach((roleId) => {
				if (!selectedOptions[roleId]) {
					selectedOptions[roleId] = {};
					Object.entries(saveMenuState).forEach(([regionColId, value]) => {
						if (parseInt(value, 10) === 1) {
							selectedOptions[roleId][regionColId] = {
								value: consoleUser ? userLevel : '1',
								parentValue: '1',
								override: childParentDict[regionColId] ? 0 : 1,
							};
						}
					});
				}
			});
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				selectedLevels: selectedOptions,
			},
		});
	}

	function handleConfirm(newtableState) {
		const menuStateToBeSaved = mappingToSaveSelection(newtableState);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				saveMenuState: menuStateToBeSaved,
			},
		});
		setMenuState(newtableState);
	}

	const fetchMenuState = async () => {
		setIsloading(true);
		const response = await regionStateServices.getAllRegionMappings();
		const allRegion = convertJSON(response);
		setMenuState(allRegion);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				saveMenuState: mappingToSaveSelection(allRegion),
			},
		});
		setIsloading(false);
	};

	useEffect(async () => {
		setIsloading(true);
		try {
			const userRolesRes = await userRolesAndPermissions.getUserRoles();
			setUserRolesList(userRolesRes);
			const userLevels = await userRolesAndPermissions.getUserLevels();
			setLevels(userLevels);
		} catch (err) {
			notificationServices.generateNotification({
				type: 'error',
				message: err.message,
			});
		} finally {
			setIsloading(false);
		}
	}, []);

	useEffect(() => {
		if (confirmationPassword && !password) {
			setErrors({
				confirmationPassword: 'Please Enter Password first',
			});
		} else if (
			confirmationPassword &&
			password &&
			password !== confirmationPassword
		) {
			setErrors({
				confirmationPassword:
					"Password and Confirm Password doesn't match",
			});
		} else {
			setErrors({});
		}
	}, [password, confirmationPassword]);

	const fetchUser = async () => {
		try {
			setIsloading(true);
			const response = await userRolesAndPermissions.getUser({
				params: {
					userId: id,
				},
			});
			if (response?.statusCode === 200) {
				const userData = response?.data;
				setMenuState(convertJSON(userData?.saveMenuState));
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'SET_VALUE',
					payload: {
						id: userData?.id,
						username: userData?.username,
						displayName: userData?.displayName,
						email: userData?.email,
						contact: userData?.contact,
						userRoles: userData?.userRoles,
						saveMenuState: mappingToSaveSelection(
							userData?.saveMenuState
						),
						accessLevel: userData?.accessLevel?.split(','),
						appModules: userData?.appModules ?? [],
						selectedLevels: userData?.selectedLevels,
						store: userData?.store,
						appUser: userData?.appUser,
						machineUser: userData?.machineUser === 1 ? true : false,
						processIds: userData?.processIds,
						processPasscode: userData?.processPasscode,
						userLevel: userData?.level,
						image: userData?.image,
						consoleUser: (userData?.machineUser === 0 || userData?.machineUser === null) && userData?.appUser === false ? true : false,
					},
				});
				setPrevData(userData);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			// console.log(error);
			setIsloading(false);
		}
	};

	useEffect(() => {
		if (isEdit && id) {
			fetchUser();
		} else {
			dispatch({
				type: 'INIT',
			});
			fetchMenuState();
		}
	}, [id, isEdit]);

	const handleChange = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);

		if (fieldName === 'processId' && state?.assetId !== -11) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					assetId: null,
				},
			});
		}

		if (fieldName === 'assetId' && state?.processId !== -11) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					processId: null,
				},
			});
		}
		if (fieldName === 'processPasscode' && fieldValue.length > 6) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Password must be 6 digit',
			});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					[fieldName]: fieldValue.slice(0, 6),
				},
			});
			return;
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[fieldName]: fieldValue,
			},
		});
	};

	const handleSubmit = async () => {
		const isValid = config?.validator(state, isEdit);
		if (isValid) {
			if ((appUser || machineUser) && !selectedChip) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Please Select Location',
				});
				return;
			}
			setIsloading(true);
			try {
				const deserializedData = config?.deserializer(state);
				const req = {
					data: deserializedData,
				};

				const response = await userRolesAndPermissions.addUser(req);
				if (response?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
					setIsloading(false);
					history.goBack();
				} else {
					setIsloading(false);
					notificationServices.generateNotification({
						type: 'error',
						message: response?.message,
					});
				}
			} catch (err) {
				setIsloading(false);
				notificationServices.generateNotification({
					type: 'error',
					message: err,
				});
			}
		}
	};

	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				{/* {isEdit ? (
					<Button
						onClick={() => {
							setIsEdit(false);
							dispatch({
								type: 'INIT',
							});
						}}
						variant='outlined'
						color='error'
						type='button'
					>
						Cancel
					</Button>
				) : (
					<Button onClick={handleEditUser} variant='outlined' color='primary' type='button'>
						Edit Logged In
					</Button>
				)} */}
				<Button
					onClick={handleSubmit}
					variant='contained'
					color='primary'
					type='button'
				>
					Save
				</Button>
			</div>
		);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const mappingRecFunction = (data) => {
		const returnData = [];
		let excludedChildren = [];
		data?.forEach((ele) => {
			const {
				returnData: children,
				excludedChildren: excChildren,
			} = mappingRecFunction(ele?.children);
			const item = {};
			if (ele?.select === 1) {
				let name = ele?.name;
				if (excChildren.length) {
					name += ' ( Excluding ';
					name += excChildren?.map((childEle) => { return childEle?.name; }).join(', ');
					name += ' )';
				}
				item.colName = name;
				item.id = ele?.id;
				item.type = ele?.type;
				if (children.length > 0) {
					item.subCols = [
						{
							colName: 'Default',
							id: ele?.id,
							type: ele?.type,
							subCols: [],
						},
					];
				}
			} else if (ele?.select === -1) {
				excludedChildren.push(ele);
			} else if (ele?.select !== 1) {
				excludedChildren = [...excludedChildren, ...excChildren];
			}
			if (children.length > 0) {
				let name = ele?.name;
				if (excChildren.length) {
					name += ' ( Excluding ';
					name += excChildren?.map((childEle) => { return childEle?.name; }).join(', ');
					name += ' )';
				}
				item.colName = name;
				if (item.subCols) {
					item.subCols = [...item.subCols, ...children];
				} else {
					item.subCols = children;
				}
			}
			if (Object.keys(item).length !== 0) {
				returnData.push(item);
			}
		});
		return {
			returnData, excludedChildren,
		};
	};

	const mappingData = (data) => {
		const { returnData: children } = mappingRecFunction(data);
		const returnData = [
			{
				colName: 'User Roles',
				subCols: [],
			},
			...children,
		];
		return returnData;
	};

	const mapUserRoles = () => {
		const result = [];
		userRoles?.forEach((roleId) => {
			const userRole = userRolesList.find((ele) => {
				return roleId === ele?.value;
			});
			const mapUserRole = {
				id: userRole?.value,
				name: userRole?.label,
			};
			if (mapUserRole.name) result.push(mapUserRole);
		});
		return result;
	};
	// image
	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageInput = (fieldName, blob) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				image: blob,
			},
		});
	};
	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ImageCropModal handleConfirm={handleImageInput} />
			<CreatePageHeader
				handleSubmit={handleSubmit}
				showCancel={false}
				heading={`${isEdit ? 'Edit' : 'Create'} User`}
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className={styles.body}>
				<div className={styles.inputAndDescription}>
					<div className={styles.inputContainer}>
						<div className='flex gap-7'>
							<div className='flex flex-col gap-16'>
								<div className='w-[77%] mx-auto flex flex-col gap-3'>
									<div className='h-[13rem] border border-stone-600 rounded-md'>
										<img
											src={image}
											className={` ${image?.blob ? 'h-full w-full' : 'h-full w-full'}`}
											alt={image ? 'user-image' : ''}
										/>
									</div>
									<label htmlFor='selectImage' className='block text-center text-lg font-semibold bg-[#fff394] text-black px-2 py-2 rounded'> Upload Image</label>
									<input
										type='file'
										id='selectImage'
										accept='image/png, image/jpg, image/jpeg, image/webp'
										name='image'
										onChange={handleImageSelect}
										style={{
											opacity: 0,
											width: 1,
											height: 1,
											display: 'none',
										}}
									/>
								</div>
								<div
									className={styles.levelDescription}
									style={{
										width: '70%',
										margin: '0 auto',
										background: '#eeeeee',
									}}
								>
									<div
										className={styles.header}
										style={{
											color: 'black',
    										fontSize: '16px',
										}}
									>
										Level Description
									</div>
									{' '}
									{Levels.map((level, index) => {
										return (
											<div
												className={styles.descriptionContainer}
												style={{
													fontSize: '13px',
													height: '2.3rem',
													borderBottom: index === Levels.length - 1 ? 'none' : '2px solid #bdbdbd',
													margin: '11px',
													color: '#e8663d',
												}}
											>
												{level.name}
												<div
													className={styles.description}
													style={{
														fontSize: '13px',
														color: '#757575',
													}}
												>
													{level.description}
												</div>
											</div>
										);
									})}
								</div>
							</div>
							{/* textfeild */}
							<div>
								<div className={styles.multi}>
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										// htmlFor={id}
										>
											Username*
										</label>
										<TextField
											id='username'
											name='username'
											value={username}
											onChange={handleChange}
											placeholder='Enter Username'
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									</div>
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										// htmlFor={id}
										>
											Display Name*
										</label>
										<TextField
											id='displayName'
											autoComplete='off'
											name='displayName'
											value={displayName}
											onChange={handleChange}
											placeholder='Enter Display name'
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									</div>
								</div>
								<div className={styles.multi}>
									<div
										className={`${styles.tile} form-field row`}
									>
										<label
											className={`${!isEdit ? styles.mandatory
												: ''
									} form-label`}
										// htmlFor={id}
										>
											{isEdit ? 'New ' : ''}
											Password
											{isEdit ? '' : '*'}
										</label>
										<TextField
											id='Password'
											type='password'
											name='password'
											autoComplete='off'
											value={password}
											onChange={handleChange}
											placeholder={`Enter ${
											isEdit ? ' New ' : ''
										} Password`}
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									</div>
									<div
										className={`${styles.tile} form-field row`}
									>
										<label
											className={`${!isEdit ? styles.mandatory : ''} form-label`}
										// htmlFor={id}
										>
											Confirm
											{isEdit ? ' New ' : ''}
											Password
											{isEdit ? '' : '*'}
										</label>
										<TextField
											id='confirmationPassword'
											type='password'
											name='confirmationPassword'
											value={confirmationPassword}
											onChange={handleChange}
											placeholder={`Enter ${
											isEdit ? ' New ' : ''
										} Confirm Password`}
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
										<p className={styles.error}>
											{errors?.confirmationPassword}
										</p>
									</div>
								</div>
								<div className={styles.multi}>
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										// htmlFor={id}
										>
											Email Address*
										</label>
										<TextField
											id='email'
											name='email'
											value={email}
											onChange={handleChange}
											placeholder='Enter Email address'
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									</div>
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										// htmlFor={id}
										>
											Contact Number*
										</label>
										<TextField
											id='contact'
											name='contact'
											type='number'
											value={contact}
											onChange={handleChange}
											placeholder='Enter Number'
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									</div>
								</div>
								<div className={`${styles.tile} form-field row`}>
									<label
										className='form-label'
										// htmlFor={id}
									>
										 User Type?
									</label>
									<div className='flex gap-4 justify-start items-center w-[60%]'>
										<div className={`${styles.tile} form-field row`}>
											<div
												className={styles['access-level']}
											>
												<input
													name='businessUser'
													type='checkbox'
													checked={appUser}
													onChange={(e) => handleAppUserChange(e?.target?.checked)}
												/>
												<p>App User</p>
											</div>
										</div>
										<div className={`${styles.tile} form-field row`}>
											<div className={styles['access-level']}>
												<input
													name='machineUser'
													type='checkbox'
													checked={machineUser}
													onChange={(e) => handleMachineUserChange(e?.target?.checked)}
												/>
												<p>Machine User</p>
											</div>
										</div>
										<div className={`${styles.tile} form-field row`}>
											<div className={styles['access-level']}>
												<input
													name='consoleUser'
													type='checkbox'
													checked={consoleUser}
													onChange={(e) => handleConsoleUserChange(e?.target?.checked)}
												/>
												<p>Console User</p>
											</div>
										</div>
									</div>
								</div>
								{(appUser || machineUser) && (
									<div className={`${styles.tile} form-field row`}>
										<label className={`${styles.mandatory} form-label`}>
											Location*
										</label>
										{supplyChainFeilds
											?.filter((item) => {
												if (appUser) return true;
												if (machineUser) {
													const validValues = [
														'WAREHOUSE_INGREDIENT',
														'WAREHOUSE',
														'WAREHOUSE_PRODUCT',
														'BASE',
													];
													return Array.isArray(item?.value)
														? item.value.some((val) => validValues.includes(val))
														: validValues.includes(item?.value);
												}
												return false;
											})
											.map((item) => (
												<Chip
													className='mx-1'
													label={item?.name}
													variant={selectedChip === item?.value ? 'filled' : 'outlined'}
													color={selectedChip === item?.value ? 'primary' : 'default'}
													onClick={() => handleChipSelect(item?.value)}
												/>
											))}
									</div>
								)}
								{/* level */}
								{ (appUser || machineUser || consoleUser) && (
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										>
											Level*
										</label>
										<SelectPicker
											data={levelOptions}
											style={{
												width: 224,
											}}
											value={userLevel}
											onChange={(val) => {
												dispatch({
													type: 'SET_VALUE',
													payload: {
														userLevel: val,
													},
												});
											}}
											placeholder='Select Level'
										/>
									</div>
								)}
								{(appUser || machineUser) && (
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										>
											{appUser ? 'User Store*' : 'Machine Store*' }
										</label>
										<SelectPicker
											data={handleStore(selectedChip)}
											style={{
												width: 224,
											}}
											value={store}
											onChange={(val) => {
												dispatch({
													type: 'SET_VALUE',
													payload: {
														store: val,
													},
												});
											}}
											placeholder='Select Store'
										/>
									</div>
								)}
								{machineUser && (userLevel === 3 || userLevel === 4) && (
									<div className={`${styles.tile} form-field row`}>
										<label className={`${styles.mandatory} form-label`}>Process*</label>
										<CheckPicker
											data={process}
											style={{
												width: 224,
											}}
											value={processIds}
											onChange={(val) => {
												dispatch({
													type: 'SET_VALUE',
													payload: {
														processIds: val,
													},
												});
											}}
											placeholder='Select Process'
										/>
									</div>
								)}

								{(appUser || machineUser) && (userLevel === 3 || userLevel === 4) && (
									<div className={`${styles.tile} form-field row`}>
										<label className={`${styles.mandatory} form-label`}>
											Approval PassCode*
										</label>
										<TextField
											id='processPasscode'
											type='number'
											name='processPasscode'
											value={processPasscode}
											onChange={handleChange}
											placeholder='XXXXXX'
											sx={{
												'.MuiInputBase-root': {
													borderRadius: '4px',
												},
												'.MuiInputBase-input': {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									</div>
								)}

								{consoleUser && (
									<div className={`${styles.tile} form-field row`}>
										<label
											className={`${styles.mandatory} form-label`}
										>
											User Roles*
										</label>
										<TagPicker
											data={userRolesList}
											style={{
												width: 400,
											}}
											menuStyle={{
												width: 300,
												maxHeight: 200,
												overflowY: 'auto',
											}}
											value={userRoles}
											onChange={(value) => {
												dispatch({
													type: 'SET_VALUE',
													payload: {
														userRoles: value,
													},
												});
											}}
										/>
									</div>
								)}
								{consoleUser && (
									<div className={`${styles.tile} form-field row`}>
										<label
											className='form-label'
										>
											Service Regions ?
										</label>
										<div className={styles['access-level']}>
											<Button
												variant='outlined'
												color='primary'
												type='button'
												disabled={userRoles?.length === 0}
												onClick={() => {
													const dataToMenu = {
														tableState: menuState,
														descriptionData,
													};
													toggleModal(true, dataToMenu);
												}}
											>
												Update Regions
											</Button>
											{ !userRoles.length ? (
												<span
													style={{
														color: 'red',
													}}
												>
													Please select roles to update regions
												</span>
											) : ''}
										</div>
									</div>
								)}

								<RegionMenuModal
									handleConfirm={(newState) => {
										handleConfirm(newState);
									}}
								/>
								{userRoles?.length && !appUser ? (
									<div className={`${styles.tableDescriptionContainer}`}>
										<DescriptionBox data={descriptionTableData} />
										<div className={`${styles.tableContainer}`}>
											<DynamicHeaderTable
												headerData={mappingData(menuState)}
												rowsData={mapUserRoles()}
												tableDataElement={EditableInput}
												excludedCols={['User Roles']}
												colors={['#e5e7eb', ...colors]}
											/>
										</div>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const storesList = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const assets = state.assets.storeAssetList?.assets;

	return {
		userPermissions,
		storesList,
		processTypes,
		assets,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleRegionModal,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
	fetchStoreAssetListing: assetActions.fetchStoreAssetListing,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserPage);
