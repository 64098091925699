import { classes } from '../../Helpers';
import MeasurementBox from '../Analytics/Quality/Measurement/MeasurementBox';
import CheckMark from '../isVegCheckMark/CheckMark';
import styles from './AppQcReference.module.scss';

function AppQcReference(props) {
	const {
		vendorName,
		source,
		isVeg,
		assetId,
		className,
		brand,
		name,
		weight,
		result,
		message,
	} = props;
	return (
		<div className={classes(styles.root, className)}>
			<div className={styles.imageContainer}>
				{vendorName && (
					<div className={styles.vendor_name}>
						Vendor Name:
						{vendorName}
					</div>
				)}
				<img
					className={styles.image}
					src={source}
					alt='Food Reference'
				/>
				{isVeg != null && (
					<div className={styles.footer}>
						<img className={styles.brand} src={brand} alt='' />
						<CheckMark isVeg={isVeg} />
						<div className={styles['name-container']}>
							<p className={styles.name}>{name}</p>
							{assetId && <p>{assetId}</p>}
						</div>
					</div>
				)}
			</div>
			<div className={styles.measurement}>
				<div className={classes(styles.qcDone, styles[result])}>
					<p>{message}</p>
				</div>
				<MeasurementBox weight measurements={weight} classname={styles.box} />
			</div>
		</div>
	);
}

export default AppQcReference;
