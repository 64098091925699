import { Autocomplete, TextField } from '@mui/material';

// import IconButton from '@mui/material/IconButton';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
// import { notificationServices } from '../../../../../Services';
// import { ArrowForward } from '@mui/icons-material';
import moment from 'moment';
import styles from './AssetMonitoringHeader.module.scss';
import { classes } from '../../../../Helpers';
import { formActions } from '../../../../Actions';
import assetActions from '../../../../Actions/asset.actions';
import DateRangePickerRsuit from '../../../../Components/DateRangePickerRsuit/DateRangePickerRsuit';

// const EMPTYVALUE = -999;
const SELECTION = {
	store: 'STORE',
	Asset: 'Asset',
};

function AssetMonitoringHeader({
	stores,
	fetchStoresList,
	fetchStoreAssetListing,
	selectedStoreAssets,
	fetchAssetMonitoringHistory,
	// resetAssetMonitoringHistory,
}) {
	// const [isHidden, setIsHidden] = useState(false);
	const [dateRangeFilter, setDateRangeFilter] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
		},
	]);
	const [selectedStore, setSelectedStore] = useState(null);
	const [selectedStoreAsset, setSelectedStoreAsset] = useState(null);

	const handleSearch = (type, data) => {
		switch (type) {
			case SELECTION.store: {
				setSelectedStore(data);
				setSelectedStoreAsset(null);
				break;
			}
			case SELECTION.Asset: {
				setSelectedStoreAsset(data);
				break;
			}
			default:
				break;
		}
	};
	useEffect(() => {
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		// commented to maintain the list so that annotation page can move forward/backward
		// return () => {
		// resetAssetMonitoringHistory();
		// };
	}, []);

	const handleFetchHistoryList = () => {
		fetchAssetMonitoringHistory({
			req: {
				data: {
					storeId: selectedStore?.id,
					assetId: selectedStoreAsset?.id,
					dateRangeFilter: {
						from: moment(dateRangeFilter[0]?.startDate).format(
							'YYYY-MM-DD'
						),
						to: moment(dateRangeFilter[0]?.endDate).format(
							'YYYY-MM-DD'
						),
					},
				},
			},
		});
	};
	useEffect(() => {
		if (selectedStore) {
			fetchStoreAssetListing(
				{
					req: {
						data: {
							storeId: selectedStore?.id,
						},
					},
				},
				selectedStore
			);
		}
	}, [selectedStore]);
	useEffect(() => {
		handleFetchHistoryList();
	}, [selectedStore, selectedStoreAsset, dateRangeFilter]);

	const setDate = (ele) => {
		setDateRangeFilter([
			{
				startDate: ele?.selection?.startDate,
				endDate: ele?.selection?.endDate,
			},
		]);
	};

	return (
		<>
			<div className={classes(styles.root)}>
				{/* <p className={styles.header}>Purchase Order List</p> */}
				<div className={styles.storeDiv}>
					<Autocomplete
						disablePortal
						id='combo-box-demo'
						popupIcon={<SearchIcon />}
						getOptionLabel={(option) => {
							return option?.name;
						}}
						onChange={(event, selectedOption, reason) => {
							handleSearch(
								SELECTION.store,
								selectedOption,
								reason
							);
						}}
						options={stores}
						sx={{
							width: 300,
							background: 'rgba(255,255,255,1)',
							'& .MuiAutocomplete-popupIndicator': {
								transform: 'none',
							},
						}}
						renderInput={(params) => {
							return (
								<TextField {...params} label='Select Store' />
							);
						}}
					/>
				</div>
				<div className={styles.storeDiv}>
					<Autocomplete
						disablePortal
						id='combo-box-demo'
						popupIcon={<SearchIcon />}
						onChange={(event, selectedOption) => {
							handleSearch(SELECTION.Asset, selectedOption);
						}}
						getOptionLabel={(option) => {
							return `${option?.name} [id:${option.id}]`;
						}}
						value={selectedStoreAsset}
						// disabled={!selectedStore}
						options={selectedStoreAssets}
						sx={{
							width: 300,
							background: 'rgba(255,255,255,1)',
							'& .MuiAutocomplete-popupIndicator': {
								transform: 'none',
							},
						}}
						renderInput={(params) => {
							return (
								<TextField
									{...params}
									label={
										selectedStore?.name
											? `Select ${selectedStore?.name}.. Asset`
											: 'Please select a store first'
									}
								/>
							);
						}}
					/>
				</div>
				<DateRangePickerRsuit
					dateRanges={dateRangeFilter}
					onDateRangeChange={setDate}
					enableOnlyBackwardSelection
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;
	const { assets: selectedStoreAssets } = state.assets.storeAssetList;
	return {
		stores,
		selectedStoreAssets,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	fetchAssetMonitoringHistory: assetActions.fetchAssetMonitoringHistory,
	fetchStoreAssetListing: assetActions.fetchStoreAssetListing,
	resetAssetMonitoringHistory: assetActions.resetAssetMonitoringHistory,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AssetMonitoringHeader);
