/* eslint-disable no-tabs */
// /* eslint-disable no-tabs */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { IconButton } from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import { useHistory } from 'react-router-dom';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import OrderAddressModal from '../../../Components/Modals/OrderAddressModal/OrderAddressModal';
import { modalActions, formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import styles from './OrderViewPage.module.scss';

const TABLE_COLUMNS = [
	{
		title: 'Order No',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Items',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Value',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Download Invoice',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Address',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [];

const COLMAP = {
	'Order No': 'orderId',
	Date: 'date',
	Items: 'numberOfItems',
	Status: 'orderStatus',
	'Order Value': 'value',
	'Download Invoice': 'invoiceUrl',
	Address: 'address',
};

const OrderViewPage = (props) => {
	const { fetchOrders, data } = props;
	const history = useHistory();
	const { id } = useParams();
	const [state, setState] = useState({
		query: '',
	});

	const handleInput = (query) => {
		setState({
			...state,
			query,
		});
	};

	const handleAddress = (address) => {
		props.toggleModal(true, {
			address,
		});
	};
	const SPECIAL_ITEMS = {
		'Download Invoice': (currId, value) => {
			return (
				<a href={value} download='invoice'>
					{value ? (
						<ReceiptIcon
							style={{
								fill: 'black',
								cursor: 'pointer',
							}}
						/>
					) : (
						<span>-</span>
					)}
				</a>
			);
		},
		Address: (currId, value) => {
			return (
				<PinDropIcon
					style={{
						cursor: 'pointer',
					}}
					onClick={() => {
						handleAddress(value);
					}}
				/>
			);
		},
	};
	useEffect(() => {
		fetchOrders({
			entity: 'MY_CUSTOMERS',
			req: {
				params: {
					user: id,
					storeId: '',
				},
			},
			method: 'VIEW',
		});
	}, []);
	const user = `Viewing User : ${id}`;
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles['list-view-header']}`}>
				<IconButton
					onClick={() => {
						history.goBack();
					}}
					className={`${styles.back}`}
					size='small'
				>
					<ArrowBackIosSharpIcon />
				</IconButton>
				<div className={`${styles['search-bar-container']}`}>
					<SearchBar handleInput={handleInput} />
				</div>
				<div className={`${styles.name}`}>
					<span>{user}</span>
				</div>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data.filter((order) => {
					const orderId = order.orderId.toString();
					return orderId.indexOf(state.query) !== -1;
				})}
				actions={[...ACTION_COLUMNS, ...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
			<OrderAddressModal handleClick={handleAddress} />
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MY_CUSTOMERS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddress,
	fetchOrders: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderViewPage);
