import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import styles from './CreatePageHeader.module.scss';

const CreatePageHeader = (props) => {
	const {
		heading,
		handleCancel,
		showCancel,
		showSave,
		showBreadcrumbs,
		breadcrumbs,
		renderBtns = false,
		handleBackClick,
		showBack,
		handleSave,
	} = props;

	return (
		<div className={`${styles.root}`}>
			{showBreadcrumbs ? (
				<Breadcrumb breadcrumbs={breadcrumbs} />
			) : (
				<div className={styles['header-container']}>
					{showBack && (
						<IconButton onClick={handleBackClick}>
							<ArrowBack />
						</IconButton>
					)}
					<p className={styles.heading}>{heading}</p>
				</div>
			)}
			{renderBtns ? (
				renderBtns()
			) : (
				<div className={`${styles['btn-group']}`}>
					{showCancel && (
						<Button
							variant='outlined'
							color='secondary'
							onClick={handleCancel}
						>
							Cancel
						</Button>
					)}
					{showSave && (
						<Button
							variant='contained'
							color='primary'
							type='submit'
							onClick={handleSave}
						>
							Save
						</Button>
					)}
				</div>
			)}
		</div>
	);
};

CreatePageHeader.defaultProps = {
	showCancel: true,
	showSave: true,
	handleSave: () => {},
};

export default CreatePageHeader;
