import { Badge, Button } from '@mui/material';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Tune } from '@mui/icons-material';
import { modalActions } from '../../Actions';
import analyticsAction from '../../Actions/analytics.actions';
import AmountContainerDiv from '../../Components/Forms/Payment Settlements/AmountContainerDiv';
import cogsDashboardAnalysisConfig from './auditDashboardAnalysis.config';
import styles from './AuditDashboard.module.scss';
import { Tag } from '../../Components/Tags';
import { classes, useQuery } from '../../Helpers';
import { timeFilterMap } from '../../Constants';
import { AuditAnalysisGraph } from '@/Components/Analytics/Audit/AuditAnalysisGraph';

const AuditDashboard = (props) => {
	const [state, setState] = useState({
		...cogsDashboardAnalysisConfig.initialState,
	});
	const {
		toggleFilterModal,
		geoFilterData,
		dateFilterData,
		geoData,
		storeFilterData,
		applyFilters,
		statFilterData,
		productData,
		data,
		resetFilters,
		showInternalData,
	} = props;

	const queryParams = useQuery();
	const queryStoreId = queryParams.get('storeId') ? Number(queryParams.get('storeId')) : null;
	const queryDay = queryParams.get('day') ? Number(queryParams.get('day')) : null;
	const queryMonth = queryParams.get('month') ? Number(queryParams.get('month')) : null;
	const queryYear = queryParams.get('year') ? Number(queryParams.get('year')) : null;

	const handleParamsFilters = () => {
		const filters = {
			STORE_FILTER: {
				active: false,
				selectedStores: [],
			},
			DATE_FILTER: {
				...dateFilterData,
			},
		};
		if (queryStoreId) {
			filters.STORE_FILTER = {
				active: true,
				selectedStores: [queryStoreId],
			};
		}
		if (queryDay && queryMonth && queryYear) {
			filters.DATE_FILTER = {
				...dateFilterData,
				text: queryDay,
				type: 'day',
				active: true,
				selection: {
					...dateFilterData.selection,
					year: queryYear,
					month: timeFilterMap.month[queryMonth - 1],
					day: queryDay,
				},
			};
		}
		if (!queryDay && queryMonth && queryYear) {
			filters.DATE_FILTER = {
				...dateFilterData,
				text: timeFilterMap.month[queryMonth - 1],
				type: 'month',
				active: true,
				selection: {
					...dateFilterData.selection,
					year: queryYear,
					month: timeFilterMap.month[queryMonth - 1],
					day: null,
				},
			};
		}
		if (!queryDay && !queryMonth && queryYear) {
			filters.DATE_FILTER = {
				...dateFilterData,
				text: queryYear,
				type: 'year',
				active: true,
				selection: {
					...dateFilterData.selection,
					year: queryYear,
					month: null,
					day: null,
				},
			};
		}
		applyFilters(
			{
				...filters,
			},
			true
		);
	};

	useEffect(() => {
		handleParamsFilters();
	}, [queryDay, queryMonth, queryYear, queryStoreId]);

	useEffect(() => {
		let newDateFilter = {
			...dateFilterData,
		};
		if (!dateFilterData?.active) {
			newDateFilter = {
				...dateFilterData,
				active: true,
				selection: {
					...dateFilterData.selection,
					hour: null,
				},
			};
			applyFilters(
				{
					DATE_FILTER: newDateFilter,
				},
				false
			);
		}
	}, []);
	useEffect(() => {
		applyFilters(
			{},
			true
		);
	}, [showInternalData]);

	const onClickHandler = () => {
		toggleFilterModal(true);
	};
	const delStoreHandler = (storeData) => {
		const updatedStoreId = geoFilterData.store.filter((currStoreId) => {
			return parseInt(storeData.id, 10) !== currStoreId;
		});
		const currCityStores = Object.keys(
			geoData[parseInt(storeData.stateId, 10)].city[
				parseInt(storeData.cityId, 10)
			].store
		).filter((currStoreId) => {
			return geoFilterData.store.includes(parseInt(currStoreId, 10));
		});
		const updatedCityId =
			currCityStores.length > 1
				? geoFilterData.city
				: geoFilterData.city.filter((currCityId) => {
					return currCityId !== parseInt(storeData.cityId, 10);
				});
		const currStateCity = Object.keys(
			geoData[parseInt(storeData.stateId, 10)].city
		).filter((currCityId) => {
			return geoFilterData.city.includes(parseInt(currCityId, 10));
		});
		const updatedStateId =
			currCityStores.length > 1 || currStateCity.length > 1
				? geoFilterData.state
				: geoFilterData.state.filter((currStateId) => {
					return parseInt(storeData.stateId, 10) !== currStateId;
				});
		applyFilters({
			GEO_FILTER: {
				state: updatedStateId,
				city: updatedCityId,
				store: updatedStoreId,
			},
		});
	};

	const delStatHandler = (delId) => {
		const newStatFilterData = cloneDeep(statFilterData);
		newStatFilterData.selectedProductIds =
			newStatFilterData.selectedProductIds.filter((id) => {
				return id !== delId;
			});
		newStatFilterData.selected =
			newStatFilterData.selectedProductIds.length > 0;
		if (!newStatFilterData.selected) {
			newStatFilterData.selectedBrands = [];
		}
		applyFilters({
			STATISTICAL_FILTER: newStatFilterData,
		});
	};

	const totalAppliedFilters =
		(geoFilterData?.store?.length || 0) +
		(storeFilterData?.length || 0) +
		(statFilterData?.selectedProductIds.length || 0);

	const getFilterData = () => {
		const filterData = geoFilterData.state.reduce(
			(accData, currStateId) => {
				const selectedStateCities = Object.keys(
					geoData[currStateId].city
				).filter((currCityId) => {
					return geoFilterData.city.includes(
						parseInt(currCityId, 10)
					);
				});
				const selectedCityStores = selectedStateCities.reduce(
					(accCityStores, currCityId) => {
						const currCityStores = Object.keys(
							geoData[currStateId].city[currCityId].store
						).reduce((accStoreData, currStoreId) => {
							if (
								geoFilterData.store.includes(
									parseInt(currStoreId, 10)
								)
							) {
								const nameAndId = {
									name: geoData[currStateId].city[currCityId]
										.store[currStoreId].name,
									id: currStoreId,
									cityId: currCityId,
									stateId: currStateId,
								};
								accStoreData.push(nameAndId);
							}
							return accStoreData;
						}, []);
						accCityStores.push(...currCityStores);
						return accCityStores;
					},
					[]
				);
				// eslint-disable-next-line no-param-reassign
				accData = [...accData, ...selectedCityStores];
				return accData;
			},
			[]
		);

		return (
			<>
				{totalAppliedFilters > 0 && (
					<Tag
						handleDelete={() => {
							resetFilters([
								'GEO_FILTER',
								'PRODUCT_FILTER',
								'STORE_FILTER',
							]);
						}}
						text='CLEAR ALL'
					/>
				)}
				{filterData.map((storeData) => {
					return (
						<Tag
							handleDelete={() => {
								delStoreHandler(storeData);
							}}
							text={storeData.name}
						/>
					);
				})}

				{statFilterData.selected ? (
					statFilterData.selectedProductIds.map((id) => {
						return (
							<Tag
								handleDelete={() => {
									delStatHandler(id);
								}}
								text={productData?.[id]?.productName ?? ''}
							/>
						);
					})
				) : (
					<></>
				)}
			</>
		);
	};
	useEffect(() => {
		setState({
			...cogsDashboardAnalysisConfig.serializer(state, data),
		});
	}, [data]);

	const renderBody = useMemo(() => {
		// if (statFilterData.selected) {
		// return <QualityStatisticalAnalysis />;
		// }

		return (
			<>
				<AmountContainerDiv
					// {...AmountContainerProps}
					boxElements={[
						{
							color: '#73c2fd',
						},
						{
							color: '#66c24d',
						},
						{
							color: '#ed9121',
						},
						{
							color: '#ff6961',
						},
						{
							color: 'rgba(158, 123, 181, 0.5)',
						},
					]}
					amountCaption={state.fields.amountCaption}
				/>
				<AuditAnalysisGraph
					// FILTERS={FILTERS}
					isClear={queryParams.has('clear')}
				/>
			</>
		);
	}, [statFilterData.selected, state.fields?.amountCaption]);

	return (
		<div className={classes(styles.root, 'page')}>
			<div className={`${styles['filter-container']}`}>
				<div className={`${styles['button-container']}`}>
					<Badge badgeContent={totalAppliedFilters} color='primary'>
						<Button
							variant='outlined'
							className={`${styles['filter-button']}`}
							onClick={onClickHandler}
						>
							filter
							{/* <img
								src={filter}
								alt='filter-icon'
								className={`${styles['filter-icon']}`}
							/> */}
							<Tune fontSize='medium' />
						</Button>
					</Badge>
				</div>
				<div className={`${styles['filter-data-container']}`}>
					{getFilterData()}
				</div>
			</div>
			{renderBody}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.analytics.AUDIT_ANALYTICS.CHART_DATA.statsData;
	const geoData = state.geoData.GEO_DATA.data;
	const geoFilterData = state.analytics.GEO_FILTER;
	const dateFilterData = state.analytics.DATE_FILTER;
	const storeFilterData = state.analytics.STORE_FILTER.selectedStores;
	const timeFilterData = state.analytics.TIME_FILTER;
	const statFilterData = state.analytics.STATISTICAL_FILTER;
	const { LIST: defects } = state.form.DEFECTS.data;
	const productData = state.product.products;
	const { showInternalData } = state.navigation;

	return {
		geoData,
		geoFilterData,
		data,
		storeFilterData,
		statFilterData,
		timeFilterData,
		dateFilterData,
		productData,
		defects,
		showInternalData,
	};
};
const mapDispatchToProps = {
	toggleFilterModal: modalActions.toggleFilterModal,
	setGeoData: analyticsAction.setGeoData,
	fetchData: analyticsAction.gateway,
	applyFilters: analyticsAction.applyFilters,
	resetFilters: analyticsAction.resetFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(AuditDashboard);
