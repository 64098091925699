/* eslint-disable linebreak-style */
import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import {
	StoreCreatePage,
	StoreViewPage,
	StoreListPage,
} from '../../../Pages/My Outlets';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const StoreRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.STORES}`,
			name: 'Stores',
		},
	];
	return (
		<CRUDRoutes
			entity='STORES'
			path={path}
			createPage={<StoreCreatePage breadcrumbs={breadcrumbs} />}
			listPage={StoreListPage}
			viewPage={<StoreViewPage breadcrumbs={breadcrumbs} />}
			editPage={<StoreCreatePage isEdit breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default StoreRoutes;
