import { useEffect, useState } from 'react';

const useImageDimensionState = (url) => {
	const [dimensionsState, setDimensions] = useState({
		width: null,
		height: null,
	});

	useEffect(() => {
		const img = new Image();
		img.addEventListener('load', () => {
			setDimensions({
				width: img.naturalWidth,
				height: img.naturalHeight,
			});
		});
		img.src = url;
		return () => {
			img.removeEventListener('load', () => {});
		};
	}, [url]);
	return dimensionsState;
};

export default useImageDimensionState;
