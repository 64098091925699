/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template */
import { CheckCircle, ModeEdit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { classes } from '../../../Helpers';
// eslint-disable-next-line import/no-cycle
import { ListTable } from '../../ListTable';
import { BaseModal } from '../BaseModal';
import styles from './AuditItemsModal.module.scss';
import { Button } from '@/Components/ui/button';
import { Input } from '@/Components/ui/input';
import { modalActions } from '@/Actions';

const COLMAP = {
	'Item Name': 'productName',
	Unit: 'unit',
	Category: 'category',
	'SKU Type': 'skuType',
	'Expected Stock': 'preAuditQty',
	'Audit Stock': 'auditStock',
	Wastages: 'wastages',
	Dispatched: 'dispatched',
};

const SPECIAL_COLUMNS = [
	{
		title: 'Item Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Code',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'UY Variant ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Sub Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expected Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Audit Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Wastages',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatched',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: '+/- Manual Adjustment',
	// config: {
	// type: 'DATA',
	// },
	// },
	{
		title: 'Adjustment to current Stock',
		config: {
			type: 'DATA',
		},
	},
];

const AuditItemsModal = (props) => {
	const { show, toggleAuditItemsModal, data, handleConfirm } = props;
	const {
		showInitiatedAuditItems,
		categories,
		skus,
		storeName,
		showGeneralDetails = false,
	} = data ?? {};
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [isEdit, setIsEdit] = useState({});
	const [listData, setListData] = useState([]);

	const calculatePostAdjustmentStock = (product) => {
		let postAdjustmentStock =
	(product.auditStock + product.dispatchedStock) - product.preAuditStock;
		if (product.adjustment && !isNaN(product.adjustment)) {
			postAdjustmentStock += Number(product.adjustment);
		}
		return postAdjustmentStock;
	};
	useEffect(() => {
		if (!data?.items || data?.items.length === 0) {
			return;
		}
		const sortedData = data?.items.sort((a, b) => {
			const postAdjustmentStockA = calculatePostAdjustmentStock(a);
			const postAdjustmentStockB = calculatePostAdjustmentStock(b);
			return postAdjustmentStockA - postAdjustmentStockB;
		});
		setListData(sortedData);
	}, [data?.items]);
	const handleClose = () => {
		toggleAuditItemsModal(false, {});
	};

	const handleConfirmClick = () => {
		const adjustedData = listData
			?.filter((listEle) => {
				const prevItem = data?.items?.find((item) => {
					return item?.id === listEle?.id;
				});
				return listEle?.adjustment !== prevItem?.adjustment;
			})
			?.map((listEle) => {
				return {
					auditReportId: listEle?.id,
					adjustment: listEle?.adjustment
						? Number(listEle?.adjustment)
						: null,
				};
			});
		handleConfirm(data?.auditId, data?.storeId, adjustedData);
		// handleClose();
	};

	const renderBtns = () => {
		return (
			!data?.hideButtons && (
				<div className={styles.btns}>
					<Button
						className='flex-1'
						disabled={loading}
						onClick={handleClose}
						variant='secondary'
					>
						Close
					</Button>
					<Button
						className='flex-1 primary-btn'
						onClick={handleConfirmClick}
						disabled={data?.isDisabled}
					>
						{loading ? 'Loading...' : 'Adjust and Complete Audit'}
					</Button>
				</div>
			)
		);
	};

	const handleEditClick = (itemId) => {
		setIsEdit((prev) => {
			const temp = {
				...prev,
			};
			if (temp[itemId]) {
				temp[itemId] = false;
			} else {
				temp[itemId] = true;
			}
			return temp;
		});
	};

	const handleQtyChange = (e, id) => {
		setListData((prev) => {
			return prev?.map((ele) => {
				if (ele?.id === id) {
					return {
						...ele,
						adjustment: e?.target?.value,
					};
				}
				return ele;
			});
		});
	};

	const SPECIAL_ITEMS = {
		'Item Name': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return (
				<div>
					{product?.productName}
					{product?.variantName
						? ' (' + product?.variantName + ')'
						: ''}
				</div>
			);
		},
		Unit: (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return product?.unit;
		},
		'Expected Stock': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return product?.preAuditStock?.toLocaleString();
		},
		'SKU Type': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return product?.skuType ?? product?.product?.skuType ?? '-';
		},
		'Item Category': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return (
				product?.categoryName ?? product?.product?.ProductCategory?.productCategoryName ?? '-'
			);
		},
		'Item Sub Category': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return (
				product?.product?.ProductSubCategory?.name ?? '-'

			);
		},
		'SKU Code': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return (
				product?.variant?.clientVariantId ?? '-'
			);
		},
		'UY Variant ID': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return (
				product?.variantId ?? '-'
			);
		},
		'Audit Stock': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return product?.auditStock?.toLocaleString() ?? '-';
		},
		Wastages: (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return product?.rejectedStock?.toLocaleString();
		},
		Dispatched: (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			return product?.dispatchedStock ?? '-';
		},
		'+/- Manual Adjustment': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			if (!data?.isDisabled && isEdit[id]) {
				return (
					<div>
						<input
							type='number'
							placeholder='Change Order QTY'
							value={product?.adjustment ?? 0}
							onChange={(e) => {
								handleQtyChange(e, product?.id);
							}}
						/>
						<IconButton
							onClick={() => {
								handleEditClick(product?.id);
							}}
						>
							<CheckCircle />
						</IconButton>
					</div>
				);
			}
			if (product?.adjustment) {
				return (
					<span className={styles['order-qty']}>
						{/* <s className={styles.actual}>{product?.orderedQty}</s> */}
						<p>{product?.adjustment?.toLocaleString()}</p>
						{!data?.isDisabled && (
							<IconButton
								onClick={() => {
									handleEditClick(product?.id);
								}}
								className={styles['edit-btn']}
							>
								<ModeEdit />
							</IconButton>
						)}
					</span>
				);
			}
			return (
				<span className={styles['order-qty']}>
					<p>0</p>
					{!data?.isDisabled && (
						<IconButton
							onClick={() => {
								handleEditClick(product?.id);
							}}
							className={styles['edit-btn']}
						>
							<ModeEdit />
						</IconButton>
					)}
				</span>
			);
		},
		'Adjustment to current Stock': (id) => {
			const product = listData?.find((prod) => {
				return prod?.id === id;
			});
			let postAdjustmentStock =
				(product?.auditStock + product?.dispatchedStock) -
				product?.preAuditStock;
			// eslint-disable-next-line no-restricted-globals
			if (product?.adjustment && !isNaN(product?.adjustment)) {
				postAdjustmentStock += Number(product?.adjustment);
			}
			return postAdjustmentStock?.toLocaleString();
		},
	};

	const DATA = useMemo(() => {
		return listData?.filter((ele) => {
			return (
				ele?.productName
					?.toLowerCase()
					?.indexOf(searchQuery?.toLowerCase()?.trim()) !== -1
			);
		});
	}, [listData, searchQuery]);

	const getRowColor = (ele) => {
		const product = listData?.find((prod) => {
			return prod?.id === ele?.id;
		});
		let postAdjustmentStock =
			(product?.auditStock + product?.dispatchedStock) -
			product?.preAuditStock;
		// eslint-disable-next-line no-restricted-globals
		if (product?.adjustment && !isNaN(product?.adjustment)) {
			postAdjustmentStock += Number(product?.adjustment);
		}
		const value = parseFloat(String(postAdjustmentStock || '').replace(/,/g, ''));
		if (value > 0) {
			return styles.positive;
		}
		if (value < 0) {
			return styles.negative;
		}
		return -1;
	};
	// const handleExport = () => {
	// const headers = SPECIAL_COLUMNS?.map((ele) => {
	// return ele?.title;
	// })?.filter((ele) => {
	// return ele !== 'Final Indent' && ele !== 'Recipe Exists';
	// });
	// headers?.splice(1, 0, ...['Variant Name', 'Sku Type']);
	// const totalValues = [];
	// const exportData = [
	// ...listData?.PRODUCT,
	// ...listData?.INTERMEDIATE,
	// ...listData?.INGREDIENT,
	// ];
	// exportData?.forEach((product) => {
	// const delta =
	// product?.updatedQty !== product?.orderedQty
	// ? product?.updatedQty - product?.orderedQty
	// : 0;
	// totalValues?.push([
	// product?.productName ?? '-',
	// product?.variantName ?? '-',
	// product?.productType ?? '-',
	// product?.unit ?? '-',
	// product?.stock ?? '-',
	// product?.currentDayStockIn ?? '-',
	// product?.currentDayCount ?? '-',
	// product?.prePlanStock ?? '-',
	// product?.threshold ?? '-',
	// product?.orderedQty ?? '-',
	// '-',
	// delta ?? '-',
	// product?.calculatedQty ?? '-',
	// product?.batchSize ?? '-',
	// product?.finalProduction ?? '-',
	// ]);
	// });
	// handleExcelDownload(
	// headers,
	// totalValues,
	// `Production Plan for ${data?.date}`
	// );
	// };

	return (
		<BaseModal
			heading={`Audit items (id: ${data?.auditId})`}
			open={show}
			handleClose={handleClose}
			customStyles={{
				width: '95%',
				height: '90vh',
				// top: '25%',
			}}
			renderBtns={renderBtns}
			// handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				{showGeneralDetails && (

					<div className={styles.general_details}>
						<div className={styles.labelled_value}>
							<label>Store Name</label>
							<div className={styles.values}>
								<p className={styles.value}>{storeName}</p>
							</div>
						</div>
						<div className={styles.labelled_value}>
							<label>Categories</label>
							<div className={styles.values}>
								{categories?.map((ele) => {
									return (
										<div className={styles.value}>
											{ele?.productCategoryName}
										</div>
									);
								})}
							</div>
						</div>
						<div className={styles.labelled_value}>
							<label>SKU Types</label>
							<div className={styles.values}>
								{skus?.map((ele) => {
									return (
										<div className={styles.value}>{ele}</div>
									);
								})}
							</div>
						</div>
					</div>
				)}
				<div className={styles.filters}>
					<Input
						className='focus:outline-none'
						value={searchQuery}
						onChange={(e) => {
							setSearchQuery(e.target.value);
						}}
						placeholder='Search SKU'
					/>
				</div>
				<div
					className={classes(
						styles.list,
						data?.hideButtons ? styles.fullHeight : ''
					)}
				>
					<ListTable
						colMap={COLMAP}
						columns={[]}
						data={DATA?.map((ele) => {
							const rowStyles = getRowColor(ele);
							return {
								...ele,
								rowStyles,
							};
						})}
						actions={[...SPECIAL_COLUMNS]?.filter((ele) => {
							if (showInitiatedAuditItems) {
								return (
									ele?.title !== 'Expected Stock' &&
									ele?.title !== 'Audit Stock' &&
									ele?.title !== 'Wastages' &&
									ele?.title !== 'Dispatched' &&
									ele?.title !== 'Adjustment to current Stock'
								);
							}
							return true;
						})}
						special={SPECIAL_ITEMS}
					/>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.auditItemsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleAuditItemsModal: modalActions.toggleAuditItemsModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(AuditItemsModal);
