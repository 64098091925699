import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const wastageAnalyticsServices = {
	gateway,
	fetchWastageAnalyticsList,
	fetchWastageImagesById,
	fetchWastageReasonsList,
	addWastageReason,
	deleteWastageReason,
};

function fetchWastageImagesById(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_WASTAGE_IMAGES_BY_ID, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
			qcType: 'WASTAGE',
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions).then(handleResponse);
}

function gateway({ req, method }) {
	switch (method) {
		case 'FIND_WASTAGE_IMAGES':
			return findWastageImages(req);
		case 'LIST_DATA':
			return fetchWastageAnalyticsList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function findWastageImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_WASTAGE_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
			qcType: 'WASTAGE',
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

function fetchWastageAnalyticsList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.WASTAGE_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchWastageReasonsList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.WASTAGE_REASONS_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}
function addWastageReason({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.ADD_WASTAGE_REASON, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}
function deleteWastageReason({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.DELETE_WASTAGE_REASON, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

// function skuStock() {
// const requestOptions = {
// method: 'GET',
// headers: {
// ...authHeader(),
// 'Content-Type': 'application/json',
// },
// };
// return fetch(endpoints.SKU_STOCK, requestOptions).then(handleResponse);
// }

export default wastageAnalyticsServices;
