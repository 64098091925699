import { classes } from '../../../Helpers';
import styles from '../SummaryTable/SummaryTable.module.scss';

export const billsSummaryDescription = [
	{
		totalBills: 'All Approved Bills in period and Un-paid bill remaining amount before period.',
		advancesPaid: 'Advance payment for bills in this period',
		invoicesPaid: 'Invoices payment for bills in this period',
		debitNote: 'Debit Note payment for bills in this period',
		advancesDues:
			'Advances dues for bills in this period and Un-paid before period.',
		invoicesDues:
			'Invoices dues for bills in this period and Un-paid before period.',
		nonAdjusted:
			'Non-adjusted advances and debit notes in this period.',
		transferredTillNow:
			'Physical money transferred in this period.',
		netAmounPending:
			'Net Amount to be transferred',
		descriptionKey: 'Description',
	},
];

export const billsSummaryColumns = [
	{
		dataKey: 'descriptionKey',
		header: '',
		fullText: true,
		colWidth: 175,
		bg: '#f1f1f1',
		fixed: true,
		sortable: false,
	},
	{
		dataKey: 'totalBills',
		headerClass: styles.total,
		header: 'Total Bills',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#ffffff',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'advancesPaid',
		headerClass: styles.already,
		header: 'Advances Already Paid (Payments)',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#A1EB98',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'invoicesPaid',
		headerClass: styles.already,
		header: 'Invoices Already Paid (Payments)',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#A1EB98',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'debitNote',
		headerClass: styles.debit,
		header: 'Debit Note',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#71F065',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'advancesDues',
		headerClass: styles.advances,
		header: 'Advances Due (Bills)',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#E97685',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'invoicesDues',
		headerClass: styles.invoices,
		header: 'Invoices Due (Bills)',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#F06577',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'nonAdjusted',
		headerClass: styles.payment,
		header: 'Non adjusted ',
		fullText: false,

		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#f1f1f1',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'transferredTillNow',
		headerClass: styles.payment,
		header: 'Transferred till now',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#f1f1f1',
		fixed: false,
		sortable: false,
	},
	{
		dataKey: 'netAmounPending',
		headerClass: styles.payment,
		header: 'Net Amount Pending Transfer',
		fullText: false,
		colWidth: 175,
		cellClass: classes(styles.field),
		bg: '#f1f1f1',
		fixed: false,
		sortable: false,
	},
];
