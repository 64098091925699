import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';
import {
	ProductSubCategoryCreatePage,
	ProductSubCategoryListPage,
	ProductSubCategoryViewPage,
} from '@/Pages/Food Products/ProductSubCategory';

const ProductSubCategoryRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`,
			name: 'Product Category',
		},
	];
	return (
		<CRUDRoutes
			entity='PRODUCT SUB CATEGORY'
			path={path}
			listPage={ProductSubCategoryListPage}
			viewPage={<ProductSubCategoryViewPage breadcrumbs={breadcrumbs} />}
			createPage={
				<ProductSubCategoryCreatePage breadcrumbs={breadcrumbs} />
			}
			editPage={(
				<ProductSubCategoryCreatePage
					isEdit
					breadcrumbs={breadcrumbs}
				/>
			)}
		/>
	);
};

export default ProductSubCategoryRoutes;
