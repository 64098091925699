import { Button } from '@mui/material';

const DownloadTOS = (props) => {
	const { TOS } = props;
	return (
		<div className='button'>
			<Button
				variant='outlined'
				color='primary'
				href={TOS}
				target='_blank'
			>
				view
			</Button>
		</div>
	);
};
export default DownloadTOS;
