import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import StoreAnalyticsRoutes from './Store Analytics/storeanalytics.routes';
import CustomerRoutes from './My Customers Routes/mycustomer.routes';
import AddCustomersRoutes from './Add Customers Routes/AddCustomers.routes';
import { InventoryAnalyticsRoutes } from './Inventory Analytics Routes';
import QualityAnalyticsRoutes from './QualityAnalytics Routes/QualityAnalytics.routes';
import { DefectAnalyticsRoutes } from './Defect Analytics Routes';
import { BrandAnalysisRoutes } from './Brand Analysis Routes';
import { CookingAnalyticsRoutes } from './Cook Analytic Routes';
import { OrdersHistoryRoutes } from './Order History Routes';
import { OutwardingAnalysisRoutes } from './OutwardingAnalysisRoutes';
import { CookingAnalysisRoutes } from './CookingAnalysisRoutes';
import WastageAnalyticsRoutes from './Wastage Analytics Routes/wastage-analytics.routes';
import AiOutwardingRoutes from './AiOutwardingRoutes/ai-outwarding.routes';
import { AiGrnRoutes } from './AiGrnRoutes';
import { ContainerImagesPage } from '../../Pages/Analytics/Container Analysis';
import { TemperatureDigitImagesPage } from '../../Pages/Analytics/Temperature Analysis/TemperatureDigitImagesPage';
import { AiProductionRoutes } from './AiProductionRoutes';
import VendorOrdersListPage from '../../Pages/Analytics/VendorOrdersListPage/VendorOrdersListPage';
import FMSOrdersListPage from '../../Pages/Analytics/FMSOrdersListPage/FMSOrdersListPage';
import ProductionPlans from '../../Pages/ProductionPlans/ProductionPlans';
import { PurchaseOrderRoutes } from './PurchaseOrderRoutes';
import AiDispatchRoutes from './AiDispatchRoutes/ai-dispatch.routes';
import GRNRoutes from './PurchaseOrderRoutes/GRNRoutes/grn.routes';
import CapexOrderListPage from '../../Pages/Analytics/CapexOrderListPage/CapexOrderListPage';
import COGSAnalyticsRoutes from './COGSAnalytics Routes/COGSAnalytics.routes';
import BOMAnalyticsRoutes from './BOMAnalytics Routes/BOMAnalytics.routes';
import INVAnalyticsRoutes from './INVAnalytics Routes/INVAnalytics.routes';
import AuditAnalyticsRoutes from './AuditAnalytics Routes/AuditAnalytics.routes';
import CommonAnalyticsRoutes from './CommonAnalytics Routes/CommonAnalytics.routes';
import VendorQuoteList from '@/Components/VendorQuote/VendorQuoteList';
import VendorResponseQuote from '@/Pages/VendorListingQuote/VendorResponseqoute';
import CrudQouteRoute from '../CurdQouteRoute/CrudQouteRoute';
import ForecastingAnalyticsRoutes from './ForecastingAnalytics Routes/ForecastingAnalytics.routes';
import TransitAnalyticsRoutes from './TransitAnalytics Routes/TransitAnalytics.routes';
import StoreAssetOrdersList from '@/Pages/Analytics/StoreAssetOrdersList/StoreAssetOrdersList';

// import MySubscriptionsRoutes from './MySubscriptionsRoutes/mySubscriptions.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.ANALYTICS;
	return entity.slice(baseEntity.length);
};

const AnalyticsRoutes = (props) => {
	const { navState } = props;

	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.STORE_ANALYSIS)}`}>
				{navState[urls.STORE_ANALYSIS].disabled ? (
					<Redirect
						to={`${path}${getSubURL(
							urls.QUALITY_ANALYSIS_CONTAINER
						)}`}
					/>
				) : (
					<StoreAnalyticsRoutes />
				)}
			</Route>
			<Route path={`${path}${getSubURL(urls.BRAND_TRENDS)}`}>
				<BrandAnalysisRoutes />
			</Route>
			<Route
				path={`${path}${getSubURL(urls.QUALITY_ANALYSIS_CONTAINER)}`}
			>
				<QualityAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.COOKING_ANALYSIS)}`}>
				<CookingAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.ORDER_HISTORY)}`}>
				<OrdersHistoryRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.WASTAGE_CONTAINER)}`}>
				<WastageAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.PRODUCTION_PLANS)}`}>
				<ProductionPlans />
			</Route>
			<Route path={`${path}${getSubURL(urls.MY_CUSTOMERS)}`}>
				<CustomerRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.ADD_CUSTOMERS)}`}>
				<AddCustomersRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.INVENTORY_ANALYTICS)}`}>
				<InventoryAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_OUTWARDING)}`}>
				<AiOutwardingRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_GRN)}`}>
				<AiGrnRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_DISPATCH)}`}>
				<AiDispatchRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.DEFECT_ANALYTICS)}`}>
				<DefectAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.CONTAINER_IMAGES)}`}>
				<ContainerImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.TEMPERATURE_DIGIT_IMAGES)}`}>
				<TemperatureDigitImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_PRODUCTION)}`}>
				<AiProductionRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.APP_OUTWARDING_ANALYTICS)}`}>
				<OutwardingAnalysisRoutes />
			</Route>
			{/* <Route path={`${path}${getSubURL(urls.WASTAGE_IMAGES)}`}>
				<WastageAnalysisRoutes />
			</Route> */}
			<Route path={`${path}${getSubURL(urls.COOKING_IMAGES)}`}>
				<CookingAnalysisRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.VENDOR_ORDER_LIST)}`}>
				<VendorOrdersListPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.QUOTE_LIST)}`}>
				<CrudQouteRoute />
			</Route>
			<Route path={`${path}${getSubURL(urls.VENDOR_QUOTE)}/:id`}>
				<VendorQuoteList />
			</Route>
			<Route path={`${path}${getSubURL(urls.VENDOR_RESPONSE_QUOTE)}/:id`}>
				<VendorResponseQuote />
			</Route>
			<Route path={`${path}${getSubURL(urls.BUYER_ORDER_LIST)}`}>
				<VendorOrdersListPage type='BUYER' />
			</Route>
			<Route path={`${path}${getSubURL(urls.CAPEX_ORDER_LIST)}`}>
				<CapexOrderListPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.INTERNAL_STORE_ASSET_ORDERS)}`}>
				<StoreAssetOrdersList />
			</Route>
			<Route
				path={`${path}${getSubURL(urls.INTERNAL_PURCHASE_ORDERS_LIST)}`}
			>
				<VendorOrdersListPage type='internalPoList' />
			</Route>
			<Route path={`${path}${getSubURL(urls.GRN_LIST)}`}>
				<GRNRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.PURCHASE_ORDERS)}`}>
				<PurchaseOrderRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.COGS_ANALYTICS)}`}>
				<COGSAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.BOM_ANALYTICS)}`}>
				<BOMAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.INV_ANALYTICS)}`}>
				<INVAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.FORECASTING_ANALYTICS)}`}>
				<ForecastingAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.COMMON_ANALYTICS)}`}>
				<CommonAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.AUDIT_ANALYTICS)}`}>
				<AuditAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.TRANSIT_ANALYTICS)}`}>
				<TransitAnalyticsRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.FMS_ORDERS_LIST)}`}>
				<FMSOrdersListPage />
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

export default connect(mapStateToProps)(AnalyticsRoutes);
