/* eslint-disable*/
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputAdornment,
	Radio,
	RadioGroup,
	Switch
} from '@mui/material';
import { connect } from 'react-redux';
import { Trash2 } from 'lucide-react';
import { CheckPicker, SelectPicker } from 'rsuite';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import styles from './AddAsset.module.scss';
import { CreatePageHeader } from '../../../../Components/Headers';
import { FormSectionHeader } from '../../../../Components/Forms';
import { notificationServices } from '../../../../Services/Notification';
import {
	generateImageEditButton, generateImageUploadButton, generateImageUrl, generateRequiredProps, inputHelper
} from '../../../../Helpers';
import assetServices from '../../../../Services/AssetServices/asset.services';
import assetActions from '../../../../Actions/asset.actions';
import {
	Select, SelectContent, SelectItem, SelectTrigger, SelectValue
} from '@/Components/ui/select';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import { Button } from '@/Components/ui/button';
import AddAssetCategoryModal from '@/Components/Modals/AddAssetCategoryModal';
import { modalActions } from '@/Actions';
import { AlertModal } from '@/Components/Modals';
import { ImageCropModal } from '@/Components/Modals/ImageCropModal';

const initialState = {
	assetName: '',
	category: '',
	movable: false,
	traceble: '',
	count: 0,
	packaging: false,
};

const initialErrorState = {
	assetName: '',
	category: '',
	movable: '',
	traceble: '',
	count: '',
	packaging: '',
};

function AddAsset(props) {
	const { toggleAddAssetCategoryModal, toggleAlert, toggleImageCrop, assetList } = props;
	const history = useHistory();
	const location = useLocation();
	const params = useParams();
	const { breadcrumbs, fetchVarients } = props;
	const [state, setState] = useState(initialState);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(initialErrorState);
	const [parameters, setParameters] = useState([]);
	const [assetName, setAssetName] = useState('');
	const [category, setCategory] = useState({});
	const [movable, setMovable] = useState(false);
	const [traceble, setTraceble] = useState(false);
	const [count, setCount] = useState(0);
	const [packaging, setPackaging] = useState(false);
	const [specification, setSpecification] = useState(false);
	const [oemList, setOemList] = useState([]);
	const [assetCategory, setAssetCategory] = useState([]);
	const [image, setImage] = useState();
	const [editMode, setEditMode] = useState(false);
	const [showOemList, setShowOemList] = useState([]);
	const [deprecation, setDeprecation] = useState(null);
	const [suggestion, setSuggestion] = useState(false);
	const [model, setModel] = useState(false);
	const [serial, setSerial] = useState(false);
	const [showInput, setShowInput] = useState(false);
	const [newOem, setNewOem] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const { assetData } = location?.state || {};


	useEffect(() => {
		if (assetData) {
			setAssetName(assetData.name);
			setCategory(assetData.assetCategory || {});
			setMovable(assetData.mobileType === 'MOVABLE');
			setTraceble(assetData.traceable);
			setCount(assetData.capacity);
			setPackaging(assetData?.usedForPackaging);
			setImage(assetData.image);
			setDeprecation(assetData?.depriciation);
			setParameters(assetData.coreSpecifications);
			setShowOemList(assetData.assetCategory ? assetData?.assetCategory?.oems : []);
			setEditMode(true);
			setHsnCode(assetData?.hsnCode);
		}
	}, [assetData]);

	const handleSerial = () => {
		const newParameter = {
			label: 'Serial Number',
			dataType: 'TEXT',
			uom: null,
			rfqMandatory: false,
			supplierMandatory: false,
			receiverMandatory: true,
		};
		const isObjectEmpty = (obj) => {
			return Object.values(obj).every((value) => value === null || value === false);
		};
		if (parameters.length > 0 && isObjectEmpty(parameters[0])) {
			const updatedParameters = [...parameters];
			updatedParameters[0] = newParameter;
			setParameters(updatedParameters);
		} else {
			setParameters([...parameters, newParameter]);
		}
		setSerial(false);
	};

	const handleModel = () => {
		const newParameter = {
			label: 'Model Number',
			dataType: 'TEXT',
			uom: null,
			rfqMandatory: false,
			supplierMandatory: false,
			receiverMandatory: true,
		};
		const isObjectEmpty = (obj) => {
			return Object.values(obj).every((value) => value === null || value === false);
		};
		if (parameters.length > 0 && isObjectEmpty(parameters[0])) {
			const updatedParameters = [...parameters];
			updatedParameters[0] = newParameter;
			setParameters(updatedParameters);
		} else {
			setParameters([...parameters, newParameter]);
		}
		setModel(false);
	};

	const addParameter = () => {
		setParameters([...parameters, {
			label: null,
			dataType: null,
			uom: null,
			rfqMandatory: false,
			supplierMandatory: false,
			receiverMandatory: false,
		}]);
		setSpecification(true);
	};

	useEffect(() => {
		if (specification) {
			setSuggestion(true);
			setSerial(true);
			setModel(true);
		}
	}, [specification]);

	useEffect(() => {
		if (!model && !serial) {
			setSuggestion(false);
		}
	}, [model, serial]);

	const updateParameter = (index, key, value) => {
		const newParameters = [...parameters];
		newParameters[index][key] = value;

		if (key === 'rfqMandatory' && value) {
			newParameters[index].receiverMandatory = false;
			newParameters[index].supplierMandatory = false;
		} else if (key === 'supplierMandatory' && value) {
			newParameters[index].receiverMandatory = false;
			newParameters[index].rfqMandatory = false;
		} else if (key === 'receiverMandatory' && value) {
			newParameters[index].supplierMandatory = false;
			newParameters[index].rfqMandatory = false;
		}
		setParameters(newParameters);
	};

	const deleteParameter = (index) => {
		const newParameters = [...parameters];
		newParameters.splice(index, 1);
		if (newParameters.length === 0) {
			setSpecification(false);
			setSuggestion(false);
		}
		setParameters(newParameters);
	};

	const handleSave = async () => {
		if (assetName === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset name is Mandatory',
			});
			return;
		}

		if (Object.keys(category).length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset Category is Mandatory',
			});
			return;
		}
		if(hsnCode.length > 0){
		if (hsnCode.length !== 4 && hsnCode.length !== 6) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'HSN Code must be 4 or 6 digits',
				});
				return;
			}
		}
		if (!image) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Image is mandatory',
			});
			return;
		}
		if (specification) {
			if (parameters.length > 0) {
				const labelEmpty = parameters.some((item) => item?.label === null);
				const typeEmpty = parameters.some((item) => item?.dataType === null);
				if (labelEmpty) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Label is mandatory',
					});
					return;
				}
				if (typeEmpty) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Type is mandatory',
					});
					return;
				}
			}
		}
		if (loading) {
			return;
		}

		setLoading(true);
		const id = 9999;
		notificationServices.generateNotification({
			type: '',
			message: 'Sending request..',
			config: {
				id,
			},
		});
		try {
			const resp = await assetServices.addAssetService({
				req: {
					data: {
						name: assetName,
						assetCategory: {
							id: category?.id,
						},
						mobileType: movable ? 'MOVABLE' : 'IMMOVABLE',
						traceable: traceble,
						capacity: count <= 0 ? null : Number(count),
						usedForPackaging: packaging,
						coreSpecifications: parameters,
						depriciation: deprecation,
						image,
						hsnCode,
					},
				},
			});
			if (resp?.statusCode !== 200) {
				throw new Error(resp?.message || 'Failed to save asset.');
			}else{
			notificationServices.generateNotification({
				type: 'success',
				message: resp.message,
			});
			history.goBack();
			setLoading(false);
			setAssetName('');
			setCategory({
				id: null,
			});
			setMovable(false);
			setTraceble(false);
			setCount(0);
			setPackaging(false);
				setParameters([]);
				setOemList([]);
				setDeprecation(null);
				setHsnCode('');
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'An error occurred while sending the request.',
			});
		}
		finally{
			setLoading(false);
		}
	};

	const fetchCategoryAsset = async () => {
		try {
			const response = await assetServices.assetTypeCategory();
			if (response?.statusCode === 200) {
				setAssetCategory(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const fetchOemList = async () => {
		try {
			const response = await assetServices.assetOemListService();
			if (response?.statusCode === 200) {
				setOemList(response?.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const showModal = () => {
		toggleAddAssetCategoryModal(true);
	};

	const deleteOemClick = async (item) => {
		try {
			await assetServices.assetOemDeleteService({
				req: {
					data: {
						id: item?.value,
						name: item?.label,
					},
				},
			});
		} catch (error) {
			console.error(error);
		}
		toggleAlert(false);
		fetchOemList();
		fetchCategoryAsset();
	};

	const deleteAssetClick = async (val) => {
		try {
			await assetServices.assetTypeCategoryDelete({
				req: {
					data: {
						id: val?.value,
						name: val?.label,
					},
				},
			});
		} catch (error) {
			console.error(error);
		}
		toggleAlert(false);
		fetchCategoryAsset();
	};

	const AddOem = async () => {
		try {
			const response = await assetServices.addOemService({
				req: {
					data: {
						name: newOem,
					},
				},
			});
			notificationServices.generateNotification({
				type: 'success',
				message: response.message,
			});
		} catch (error) {
			console.error(error);
			notificationServices.generateNotification({
				type: 'error', 
				message: 'Failed to add OEM'
			});
		} finally {
			setShowInput(false);
			fetchOemList();
			mapAssetCategory();
		}
	};

	const renderAssetCategory = (item) => {
		return (
			<div className='flex justify-between items-center'>
				{item.label}
				<Trash2
					className='text-red-500 w-5 h-5 ml-2 cursor-pointer'
					onClick={(e) => handleAssetClick(item, e)}
				/>
			</div>
		);
	};

	const mapAssetCategory = async () => {
		const selectedItems = oemList?.find((oem) => oem?.name === newOem);
		console.log('selectedItems', selectedItems);
		try {
			if (!newOem) {
				throw new Error('Please add an OEM');
			}

			const selectedItems = oemList?.find((oem) => oem?.name === newOem);
			if (!selectedItems) {
				throw new Error('Selected OEM not found');
			}

			const mappedItems = {
				id: selectedItems?.id,
				name: selectedItems?.name,
			};

			const selectedCategory = assetCategory?.find((cat) => cat?.id === category?.id);
			if (!selectedCategory) {
				throw new Error('Selected category not found');
			}

			const oems = selectedCategory?.oems ? [...selectedCategory.oems, mappedItems] : [mappedItems];

			const response = await assetServices?.addAssetCategoryModalService({
				req: {
					data: {
						id: selectedCategory?.id,
						name: selectedCategory?.name,
						categoryCode: selectedCategory?.categoryCode,
						oems: oems,
					},
				},
			});

			if (response?.statusCode === 200) {
				fetchCategoryAsset();
				setNewOem('');
				notificationServices.generateNotification({
					type: 'success',
					message: response.message || 'Asset category mapped successfully'
				});
			}
		} catch (error) {
			console.error('Error mapping asset category:', error);
			notificationServices.generateNotification({
				type: 'error',
				message: error.message || 'Failed to map asset category'
			});
		}
	};

	const deleteOem = (val) => {
		toggleAlert(true, {
			heading: 'Delete OEM?',
			message: `Are you sure you want to delete ${val?.label} ?`,
			handleConfirm: () => {
				deleteOemClick(val);
			},
		});
	};

	const handleAsset = (val) => {
		toggleAlert(true, {
			heading: 'Delete Asset Category?',
			message: `Are you sure you want to delete ${val?.label} ?`,
			handleConfirm: () => {
				deleteAssetClick(val);
			},
		});
	};

	const handleAssetClick = (item, e) => {
		e.stopPropagation();
		handleAsset(item);
	};

	useEffect(() => {
		fetchCategoryAsset();
	}, []);

	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageInput = (fieldName, blob) => {
		setImage(blob);
	};

	const showOems = (id) => {
		const oems = assetCategory?.find((item) => item?.id === id);
		setShowOemList(oems?.oems);
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleEdit = async () => {
		if (assetName === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset name is mandatory',
			});
			return;
		}

		if (Object.keys(category).length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset Category is Mandatory',
			});
			return;
		}
		if(hsnCode?.length > 0){
			if (hsnCode?.length !== 4 && hsnCode?.length !== 6) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'HSN Code must be 4 or 6 digits',
					});
					return;
				}
			}
		
		if (specification) {
			if (parameters?.length > 0) {
				const labelEmpty = parameters.some((item) => item?.label === null);
				const typeEmpty = parameters.some((item) => item?.dataType === null);
				if (labelEmpty) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Label is mandatory',
					});
					return;
				}
				if (typeEmpty) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Type is mandatory',
					});
					return;
				}
			}
		}

		if (loading) {
			return;
		}

		setLoading(true);
		const id = 9999;
		notificationServices.generateNotification({
			type: '',
			message: 'Sending request..',
			config: {
				id,
			},
		});

		try {
			const resp = await assetServices.addAssetService({
				req: {
					data: {
						id: params?.id,
						name: assetName,
						assetCategory: {
							id: category?.id,
						},
						mobileType: movable ? 'MOVABLE' : 'IMMOVABLE',
						traceable: traceble,
						capacity: count <= 0 ? null : Number(count),
						usedForPackaging: packaging,
						coreSpecifications: parameters,
						depriciation: deprecation,
						image,
						hsnCode,
					},
				},
			});
			if (resp?.statusCode !== 200) {
				notificationServices.generateNotification({
				type: 'error',
					message: resp.message,
				});
			}else {
				notificationServices.generateNotification({
					type: 'success',
						message: resp.message,
					});
				setEditMode(false);
				history.push('/console2/asset/asset-category');
				setLoading(false);
				setAssetName('');
				setCategory({
					id: null,
				});
				setMovable(false);
				setTraceble(false);
				setCount(0);
				setPackaging(false);
				setParameters([]);
				setOemList([]);
				setDeprecation(null);
				setHsnCode('');
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'An error occurred while updating the asset.',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<AddAssetCategoryModal handleDeleteOem={deleteOem} fetchOemList={fetchOemList} oemList={oemList} fetchCategoryAsset={fetchCategoryAsset} />
			<AlertModal />
			<CreatePageHeader
				heading={editMode ? 'Edit' : 'Create'}
				handleSave={() => {
					if (editMode) {
						handleEdit();
					} else {
						handleSave();
					}
				}}
				handleCancel={() => {
					history.goBack();
				}}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: editMode ? 'Edit Asset' : 'Create New',
					},
				]}
				showBreadcrumbs
			/>
			<div className='w-1/5 float-right relative top-[75px] right-[58px] shadow-md p-1'>
				<div className='h-[140px] mb-3 '>
					<img
						src={image}
						className={`bg-stone-300  ${image?.blob ? 'h-full w-full' : 'h-full w-full'}`}
						alt={image ? 'asset-image' : ''}
					/>
				</div>
				<label htmlFor='selectImage' className='block text-center text-lg font-semibold bg-[#4A70FB] text-white px-2 py-2 rounded'> Choose a Image</label>
				<input
					type='file'
					id='selectImage'
					accept='image/png, image/jpg, image/jpeg, image/webp'
					name='image'
					onChange={handleImageSelect}
					style={{
						opacity: 0,
						width: 1,
						height: 1,
						display: 'none',
					}}
				/>
			</div>
			<ImageCropModal handleConfirm={handleImageInput} />
			<div className={styles.container}>
				<FormSectionHeader
					sectionName='Asset Category'
				>
					<div className={styles.gridDiv}>
						<Label
							className={styles.textLabel}
							style={{
								color: '#ef4444',
							}}
						>
							Asset Type*
						</Label>
						<Input
							placeholder='Asset Type'
							type='text'
							maxLength={50}
							onChange={(e) => setAssetName(e.target.value)}
							value={assetName}
						/>
						<Label
							className={styles.textLabel}
							style={{
								color: '#ef4444',
							}}
						>
							Asset Category Name*
						</Label>
						<div className='flex gap-4 items-center'>
							<SelectPicker
								data={assetCategory.map((item) => ({
									label: item?.name,
									value: item?.id,
								}))}
								placeholder='Asset Category'
								style={{
									width: 300,
								}}
								menuStyle={{
									width: 300,
								}}
								value={category?.id}
								onChange={(value) => {
									setCategory({
										id: value,
									});
									showOems(value);
								}}
								renderMenuItem={(label, item) => renderAssetCategory(item)}
							/>
							<Button className='h-12' variant='secondary' onClick={showModal}>+ Add Asset Category</Button>
						</div>
						<Label className={styles.textLabel}>Allowed Oems</Label>
						<div className='flex items-center gap-2'>
							{showOemList?.map((item, index) => {
								
									return (
										<span className='rounded-full bg-[#dfdfdf] px-4 py-2 ml-2' key={item.id}>
											{item?.name}
										</span>
									);
							})}
							{category?.id  && (
								<div className='flex items-center gap-2'>
									{!showInput ? (
									<Button className='h-12' variant='secondary' onClick={() => setShowInput(true)}>+ Add OEM</Button>
								) : (
									<>
										<Input
											type='text'
											onChange={(e) => setNewOem(e.target.value)}
											value={newOem}
										/>
										<Button className='h-12' variant='secondary' onClick={AddOem}>Add</Button>
									</>
								)}
								</div>
							)}
						</div>
						<Label className={styles.textLabel}>Asset Movable</Label>
						<FormControlLabel
							name='movable'
							control={<Switch checked={movable} />}
							onChange={(e) => setMovable(e.target.checked)}
						/>
						<Label className={styles.textLabel}>Inventory Traceable</Label>
						<FormControlLabel
							name='traceable'
							className='mb-1'
							onChange={(e) => setTraceble(e.target.checked)}
							control={<Switch checked={traceble} />}
						/>
						<label className={styles.textLabel}>Capacity </label>
						<FormControl>
							<Input
								id='ChildrenCount'
								value={count}
								name='count'
								type='number'
								onChange={(e) => setCount(e.target.value)}
								endAdornment={
									<InputAdornment position='end'>Count</InputAdornment>
								}
								aria-describedby='Total count of children'
								inputProps={{
									'aria-label': 'weight',
									min: 0,
								}}
							/>
							<FormHelperText id='weight'>zero = no limit</FormHelperText>
						</FormControl>
						<label className={styles.textLabel}>Used in packaging </label>
						<FormControlLabel
							name='packaging'
							onChange={(e) => setPackaging(e.target.checked)}
							control={<Switch checked={packaging} />}
						/>
						<label className={styles.textLabel}>Yearly Depreciation % </label>
						<Input
							type='number'
							onChange={(e) => {
								const { value } = e.target;
								if (/^\d*\.?\d{0,2}$/.test(value)) {
									setDeprecation(value);
								}
							}}
							value={deprecation}
						/>
						<label className={styles.textLabel} style={{marginTop: '10px'}}>HSN Code </label>
							<Input
								type='text'
								style={{marginTop: '10px'}}
								onChange={(e) => {
									setHsnCode(e.target.value);
							}}
							value={hsnCode}
							/>
					</div>
				</FormSectionHeader>
				{suggestion && (
					<div className='relative flex gap-2 left-[13rem] top-[2rem] max-w-md'>
						<p>Suggested Fields:</p>
						{serial && (
							<button
								className='animate-pulse text-blue font-bold underline py-0 px-0 hover:text-blue-700'
								onClick={handleSerial}
							>
								Serial Number
							</button>
						)}
						{model && (
							<button
								className='animate-pulse text-blue font-bold underline py-0 px-0 hover:text-blue-700'
								onClick={handleModel}
							>
								Model Number
							</button>
						)}
					</div>
				)}
				<div className='flex items-center justify-between pl-4 mb-3'>
					<Label className='text-[#878787] text-base'> Core Specification </Label>
					<Button
						onClick={addParameter}
						variant='secondary'
					>
						+ Core Specification
					</Button>
				</div>
				<div className='pl-4'>
					{parameters.length > 0 && (
						<table className='w-full'>
							<thead>
								<tr className='text-sm font-bold uppercase'>
									<th className='text-red-500 text-sm'>Label *</th>
									<th className='text-red-500'>Type *</th>
									<th>Unit</th>
									<th>To be provided with RFQ/Order</th>
									<th>To be provided by supplier</th>
									<th>To be provided time of receiving</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{parameters.map((param, index) => (
									<tr key={index}>
										<td>
											<Input
												type='text'
												className='w-full'
												value={param.label}
												onChange={(e) => {
													updateParameter(index, 'label', e.target.value);
												}}
											/>
										</td>
										<td className='text-center'>
											<Select
												value={param?.dataType}
												onValueChange={(value) => updateParameter(index, 'dataType', value)}
											>
												<FormControl>
													<SelectTrigger className='text-black'>
														<SelectValue placeholder='Select type' />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													<SelectItem value='TEXT'>Text</SelectItem>
													<SelectItem value='NUMBER'>Number</SelectItem>
													<SelectItem value='DATE'>Date</SelectItem>
													<SelectItem value='BOOLEAN'>Boolean</SelectItem>
													<SelectItem value='DECIMAL'>Decimal</SelectItem>
												</SelectContent>
											</Select>
										</td>
										<td className='text-center'>
											<Input
												type='text'
												className='w-full ml-2'
												value={param.uom}
												onChange={(e) => updateParameter(index, 'uom', e.target.value)}
											/>
										</td>
										<td className='text-center'>
											<Radio
												checked={param?.rfqMandatory}
												onChange={() => updateParameter(index, 'rfqMandatory', true)}
												value='rfqMandatory'
											/>
										</td>
										<td className='text-center'>
											<Radio
												checked={param?.supplierMandatory}
												onChange={() => updateParameter(index, 'supplierMandatory', true)}
												value='supplierMandatory'
											/>
										</td>
										<td className='text-center'>
											<Radio
												checked={param?.receiverMandatory}
												onChange={() => updateParameter(index, 'receiverMandatory', true)}
												value='receiverMandatory'
											/>
										</td>
										<td className='text-center'>
											<Button variant='icon' onClick={() => deleteParameter(index)}>
												<Trash2 className='text-red-500 w-6 h-6' />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	fetchVarients: assetActions.fetchAssetListVarient,
	toggleAddAssetCategoryModal: modalActions.toggleAddAssetCategoryModal,
	toggleAlert: modalActions.toggleAlert,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAsset);
