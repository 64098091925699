/* eslint-disable no-unused-vars */
/* eslint-disable */
import {
	Add, ArrowForward, MergeType
} from '@mui/icons-material';
import {
	Button,
	Checkbox,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	// TextField,
} from '@mui/material';
import { CheckPicker } from 'rsuite';
import moment from 'moment';
import {
	useEffect, useMemo, useReducer, useState
} from 'react';
import { connect } from 'react-redux';
import SelectPicker from 'rsuite/SelectPicker';
import {
	analyticsAction, formActions, modalActions
} from '../../Actions';
import {
	classes, inputHelper, useQuery
} from '../../Helpers';
import { notificationServices } from '../../Services';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { UYDateRange } from '../Forms/Payment Settlements/Calender/UYDateRange';
import { ListModal } from '../Modals';
import { Tag } from '../Tags';
import config from './config';
import styles from './ListFinderHeader.module.scss';
import processFlowServices from '../../Services/Analytics Services/process-flow.services';

const EMPTYVALUE = -999;

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_STATUS':
			return {
				...state,
				selectedStatus: action.payload,
			};
		case 'SET_VENDORS':
			return {
				...state,
				vendors: action.payload,
			};
		case 'SET_RAISED_BY':
			return {
				...state,
				raisedBy: action.payload,
			};
		case 'SET_FULFILLED_BY':
			return {
				...state,
				fulfilledBy: action.payload,
			};
		case 'SET_DATE_TYPE':
			return {
				...state,
				dateType: action.payload,
			};
		case 'SET_DATE_RANGE':
			return {
				...state,
				dateRange: action.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action.payload,
			};
		case 'SET_PROCESS_TYPES':
			return {
				...state,
				processType: action.payload,
			};
		case 'SET_PRODUCTION_PLAN':
			return {
				...state,
				productionPlan: action.payload,
			};
		case 'SET_PRODUCTION_PLAN_DURATION':
			return {
				...state,
				productionPlanDuration: action.payload,
			};
		default:
			return state;
	}
};

const params = {
	VENDOR: 'vendor',
	from: 'from',
	to: 'to',
};

function calculateDate(duration) {
	const currentDate = new Date();

	if (duration === 'Week') {
		// Subtract 7 days from the current date
		currentDate.setDate(currentDate.getDate() - 7);
	} else if (duration === 'Month') {
		// Subtract 1 month from the current date
		currentDate.setMonth(currentDate.getMonth() - 1);
	}

	return currentDate;
}

const allId = -99;

function ListFinderHeader(props) {
	const {
		toggleModal,
		dateRangeFilter,
		setDateRangeFilter,
		fetchAllVendors,
		vendorsList,
		fetchStoresList,
		stores,
		fetchOrders = () => {},
		FILTERS = [],
		dateTypeArr = [],
		// processTypesList,
		filterStatusOptions = null,
		// fetchProcessTypesList,
		allowFutureDate,
		updateState,
		filterNames,
		productionPlans,
		fetchProductionPlans,
		navSelectedStore,
		headerOptions = {},
		update,
		filteredStore,
		setExportFilter = () => {},
	} = props;
	const options = filterStatusOptions ?? ['CANCELLED', 'PENDING', 'FULFILLED', 'APPROVAL_PENDING', 'ABANDONED'];
	const [state, dispatch] = useReducer(reducer, config?.initialState);
	const query = useQuery();
	const [selectedvalue,setSelectedValue] = useState([])
	const [pass ,setPass] = useState('')

	const handleValue = (value)=>{
         setSelectedValue(value)
	}
	console.log(selectedvalue)
	const {
		vendors,
		selectedStatus,
		raisedBy,
		fulfilledBy,
		dateRange,
		dateType,
		processType,
		store,
		productionPlan,
		productionPlanDuration,
	} = state;
	// eslint-disable-next-line no-unused-vars
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [processTypesList, setProcessTypesList] = useState([]);
     
	const getData = () => {
	    const data = selectedvalue.map(item => item.id);
         setPass(data)
		 console.log(pass)
	       }

		  
	async function fetchNodes() {
		// setLoading(true);
		try {
			const response = await processFlowServices?.fetchNodes({
				params: {
					storeId: store !== EMPTYVALUE && store,
				},
			});
			if (response?.statusCode === 200) {
				const { nodes } = response?.data ?? {};
				setProcessTypesList(nodes);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	}

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		const filters = config?.deserializer(
			{
				storeId: raisedBy === EMPTYVALUE ? null : raisedBy,
				fulfilledBy: fulfilledBy === EMPTYVALUE ? null : fulfilledBy,
				vendors,
				dateRangeFilter: {
					from: moment(dateRange?.from).format('YYYY-MM-DD'),
					to: moment(dateRange?.to).format('YYYY-MM-DD'),
				},
				status:
					selectedStatus?.indexOf('All') > -1 ? [] : selectedStatus,
				dateType,
				processType: processType === EMPTYVALUE ? null : processType,
				store: store === EMPTYVALUE ? null : store,
				productionPlan,
			},
			FILTERS
		);
		setExportFilter(filters);
		setShouldAnimate(true);
	}, [state]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchAllVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (store !== EMPTYVALUE) {
			fetchNodes();
		}
	}, [store]);

	useEffect(() => {
		if (update) {
			const filters = config?.deserializer(
				{
					storeId: raisedBy === EMPTYVALUE ? null : raisedBy,
					fulfilledBy: fulfilledBy === EMPTYVALUE ? null : fulfilledBy,
					vendors,
					dateRangeFilter: {
						from: moment(dateRange?.from).format('YYYY-MM-DD'),
						to: moment(dateRange?.to).format('YYYY-MM-DD'),
					},
					status:
						selectedStatus?.indexOf('All') > -1 ? [] : selectedStatus,
					dateType,
					processType: processType === EMPTYVALUE ? null : processType,
					store: store === EMPTYVALUE ? null : store,
					productionPlan,
				},
				FILTERS
			);
			fetchOrders(filters);
		}
	}, [update]);

	useEffect(() => {
		let sub = false;
		// const queryBrand = query.get(params.STORE);
		const queryVendor = query.get(params.VENDOR) && [
			Number.parseInt(query.get(params.VENDOR), 10),
		];
		const from = query.get(params.from);
		const to = query.get(params.to);

		if (query.has(params.VENDOR)) {
			sub = true;
			dispatch({
				type: 'SET_VENDORS',
				payload: queryVendor,
			});
		}
		if (sub) {
			// eslint-disable-next-line no-unused-vars
			const dateRangePayload = {
				// ...dateRange,
				from: from ? new Date(from) : new Date(),
				active: true,
				to: to ? new Date(to) : new Date(),
			};
			setDateRangeFilter({
				from: from ? new Date(from) : new Date(),
				active: true,
				to: to ? new Date(to) : new Date(),
			});
			// handleAutoFetchPurchaseOrderList(queryVendor, dateRangePayload);
		}
	}, [query.get(params.VENDOR)]);

	// =====================
	// Change Handlers

	useEffect(() => {
		if (dateTypeArr && dateTypeArr?.length > 1) {
			dispatch({
				type: 'SET_DATE_TYPE',
				payload: dateTypeArr[0]?.value,
			});
		}
	}, [dateTypeArr]);

	const PRODUCTION_PLANS = useMemo(() => {
		// const allObj = {
		// id: allId,
		// description: 'All',
		// };
		return [...productionPlans];
	}, [productionPlans]);

	const setRaisedBy = (storeId) => {
		// const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_RAISED_BY',
			payload: storeId,
		});
	};
	const setFulfilledBy = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_FULFILLED_BY',
			payload: fieldValue,
		});
	};
	const selectDateType = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_DATE_TYPE',
			payload: fieldValue,
		});
	};

	const setDateRange = ({ active, from, to }) => {
		dispatch({
			type: 'SET_DATE_RANGE',
			payload: {
				active,
				from,
				to,
			},
		});
		setDateRangeFilter({
			from: from ? new Date(from) : new Date(),
			active: true,
			to: to ? new Date(to) : new Date(),
		});
	};

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
	};

	const setSelectedStatus = (payload) => {
		dispatch({
			type: 'SET_STATUS',
			payload,
		});
	};
	const setStore = (value) => {
		// const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_STORE',
			payload: value,
		});
	};
	const setProcessTypes = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_PROCESS_TYPES',
			payload: fieldValue,
		});
	};
	// const setProductionPlan = (e) => {
	// const { fieldValue } = inputHelper(e);
	// dispatch({
	// type: 'SET_PRODUCTION_PLAN',
	// payload: fieldValue,
	// });
	// };
	const handlePPDuration = (ele) => {
		dispatch({
			type: 'SET_PRODUCTION_PLAN_DURATION',
			payload: ele,
		});
	};
	const addFilter = (filters, context) => {
		if (context.entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: [...vendors, ...filters],
			});
		}
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;

		if (entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: vendors.filter((vendor) => {
					return vendor !== parseInt(id, 10);
				}),
			});
		}
	};

	const handleStatusChange = (event) => {
		const { value } = event.target;
		if (!value?.includes('All') && selectedStatus?.indexOf('All') > -1) {
			const filterStatus = selectedStatus?.filter((status) => {
				return status !== 'All' && selectedStatus?.indexOf(value) > -1;
			});
			setSelectedStatus(filterStatus);
		} else if (value?.includes('All')) {
			const allStatus = ['All'];
			setSelectedStatus(allStatus);
		} else {
			setSelectedStatus(value);
		}
	};

	const handleProductionPlansSelection = (productionPlansVal) => {
		// if (!value?.includes(allId) && productionPlan?.indexOf(allId) > -1) {
		// const filterPP = productionPlan?.filter((status) => {
		// return status !== allId && productionPlan?.indexOf(value) > -1;
		// });
		// dispatch({
		// type: 'SET_PRODUCTION_PLAN',
		// payload: filterPP,
		// });
		// } else if (value?.includes(allId)) {
		// const allPPs = [allId];
		// dispatch({
		// type: 'SET_PRODUCTION_PLAN',
		// payload: allPPs,
		// });
		// } else {
		dispatch({
			type: 'SET_PRODUCTION_PLAN',
			payload: productionPlansVal,
		});
		// }
	};
	// =====================

	const VENDORS = useMemo(() => {
		return vendorsList.map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
			};
		});
	}, [vendorsList]);

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
			};
		});
		tempStore?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return tempStore;
	}, [stores]);
	const BASE_STORES = useMemo(() => {
		const tempStore = stores
			?.filter((ele) => {
				return ele.type === 'BASE';
			})
			?.map((tStore) => {
				return {
					id: tStore.id,
					title: tStore?.name ?? '',
					type: tStore?.type,
				};
			});
		tempStore?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return tempStore;
	}, [stores]);

	const BASE_STORES_LIST = useMemo(() => {
		const tempStoreList = stores?.map((ele) => {
			return {
				...ele,
				label: ele?.name,
				role: ele?.type,
				value: ele?.id,
			};
		});
		tempStoreList?.unshift({
			id: -999,
			label: 'All',
			role: 'ALL',
			value: -999,
		});
		return tempStoreList;
	}, [stores]);

	const BASE_FILTERED_STORES_LIST = useMemo(() => {
		const tempStoreList = filteredStore?.map((ele) => {
			return {
				...ele,
				label: ele?.name,
				role: ele?.type,
				value: ele?.id,
			};
		});
		tempStoreList?.unshift({
			id: -999,
			label: 'All',
			role: 'ALL',
			value: -999,
		});
		return tempStoreList;
	}, [filteredStore]);

	const PROCESS_TYPES = useMemo(() => {
		const list = (processTypesList ?? [])?.map((ele) => {
			return {
				id: ele?.id,
				title: ele?.data?.heading,
			};
		});
		list?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return list;
	}, [processTypesList]);

	useEffect(() => {
		dispatch({
			type: 'SET_PROCESS_TYPES',
			payload: PROCESS_TYPES?.[0]?.id,
		});
	}, [PROCESS_TYPES]);

	useEffect(() => {
		dispatch({
			type: 'SET_RAISED_BY',
			payload: STORES?.[0].id,
		});
	}, [stores]);
	useEffect(() => {
		dispatch({
			type: 'SET_STORE',
			payload: BASE_STORES?.[0].id,
		});
	}, [stores]);
	useEffect(() => {
		dispatch({
			type: 'SET_FULFILLED_BY',
			payload: BASE_STORES?.[0].id,
		});
	}, [BASE_STORES]);

	useEffect(() => {
		const date = calculateDate(productionPlanDuration);
		fetchProductionPlans({
			entity: 'PRODUCTION_PLANS',
			req: {
				data: {
					filters: {
						dateRangeFilter: {
							from: moment(date)?.format('YYYY-MM-DD'),
							to: moment(new Date())?.format('YYYY-MM-DD'),
						},
						recentOnly: true,
					},
				},
			},
			method: 'LIST',
		});
	}, [productionPlanDuration]);

	const handleFetchPurchaseOrderList = () => {
		const filters = config?.deserializer(
			{
				storeId: raisedBy === EMPTYVALUE ? null : raisedBy,
				fulfilledBy: fulfilledBy === EMPTYVALUE ? null : fulfilledBy,
				vendors,
				dateRangeFilter: {
					from: moment(dateRange?.from).format('YYYY-MM-DD'),
					to: moment(dateRange?.to).format('YYYY-MM-DD'),
				},
				status:
					selectedStatus?.indexOf('All') > -1 ? [] : selectedStatus,
				dateType,
				processType: processType === EMPTYVALUE ? null : processType,
				store: store === EMPTYVALUE ? null : store,
				productionPlan,
			},
			FILTERS
		);
		fetchOrders(filters);
	};

	useEffect(() => {
		if (updateState > 0) {
			handleFetchPurchaseOrderList();
		}
	}, [updateState]);

	const isFulfilledByBaseStore = useMemo(() => {
		if (raisedBy === EMPTYVALUE) return false;
		const currStore = BASE_STORES?.find((ele) => {
			return ele?.id === raisedBy;
		});
		return currStore?.type === 'BASE';
	}, [raisedBy]);

	const combinePO = async () => {
		const from = moment(dateRangeFilter.from).format('YYYY-MM-DD');
		const to = moment(dateRangeFilter.to).format('YYYY-MM-DD');
		try {
			const req = {
				data: {
					storeId: raisedBy,
					dateRangeFilter: {
						from,
						to,
					},
				},
			};
			const response =
				await inventoryAnalyticsServices.combinePurchaseOrder({
					req,
				});

			// Check if the Content-Type of the response is 'text/csv'
			const contentType = response?.headers?.get('Content-Type');
			if (contentType && contentType?.includes('text/csv')) {
				const blob = await response.blob(); // Get the response data as a Blob
				const blobUrl = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = blobUrl;
				link.download = `Combined_PO_${from}-${to}.csv`;
				link.click();
				window.URL.revokeObjectURL(blobUrl);

				notificationServices.generateNotification({
					type: 'success',
					message: 'Combined and Downloaded Successfully',
				});
			} else {
				// Handle the case where the response is not 'text/csv'
				try {
					const errorData = await response.json(); // Try to parse the response as JSON
					const errorMessage = errorData?.errorMessage; // Extract the error message
					notificationServices.generateNotification({
						type: 'error',
						message: errorMessage || 'Some error occurred.',
					});
				} catch (jsonError) {
					// Handle the case where the response is not JSON or doesn't contain an error message
					notificationServices.generateNotification({
						type: 'error',
						message: 'Some error occurred.',
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some Error occurred while combining',
			});
		}
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	return (
		<>
			<ListModal handleConfirm={addFilter} list={VENDORS} />
			<div className={classes(styles.root)}>
				{headerOptions?.title && (
					<div className={styles.header}>
						<p>{headerOptions?.title}</p>
						{headerOptions?.generateButton && (
							<button
								onClick={headerOptions?.generateButton?.onClick}
								type='button'
								className={styles['add-btn']}
							>
								<Add />
								{headerOptions?.generateButton?.title}
							</button>
						)}
					</div>
				)}
				{/* <p className={styles.header}>Purchase Order List</p> */}
				<div className={styles.body}>
					{FILTERS?.includes('raisedBy') && (
						<div className={styles.storeDiv}>
							Deliver to
							{navSelectedStore?.type === 'raisedBy' &&
								navSelectedStore?.id ? (
									<p
										style={{
											width: '100px',
											fontSize: '.9rem',
										}}
									>
										{navSelectedStore?.name}
									</p>
								) : (
									// <Select
									// className={styles['store-select']}
									// value={raisedBy}
									// onChange={setRaisedBy}
									// disabled={false}
									// >
									// {STORES?.map((storeObj) => {
									// return (
									// <MenuItem
									// key={
									// storeObj.id + storeObj.title
									// }
									// value={storeObj?.id}
									// >
									// {storeObj.title}
									// </MenuItem>
									// );
									// })}
									// </Select>
									<SelectPicker
										data={BASE_STORES_LIST}
										style={{
											width: 200,
										}}
										groupBy='role'
										placeholder='Select Store'
										onChange={(storeId) => {
											setRaisedBy(storeId);
										}}
										renderMenuItem={(_) => {
											return (
												<div>
													<i className='rs-icon rs-icon-user' />
													{_}
												</div>
											);
										}}
										renderMenuGroup={(_) => {
											return (
												<div>
													<i className='rs-icon rs-icon-group' />
													{_}
												</div>
											);
										}}
										value={
											raisedBy
										}
										cleanable={false}
										renderValue={(value, item) => {
											return (
												<div className='rsuite-select-picker-render-value'>
													{item?.label}
												</div>
											);
										}}
									/>
								)}
						</div>
					)}
					{FILTERS?.includes('store') && (
						<div className={styles.storeDiv}>
							Kitchen
							{/* <Select
								className={styles['store-select']}
								value={store}
								onChange={setStore}
								disabled={false}
							>
								{BASE_STORES?.map((storeObj) => {
									return (
										<MenuItem
											key={storeObj.id + storeObj.title}
											value={storeObj?.id}
										>
											{storeObj.title}
										</MenuItem>
									);
								})}
							</Select> */}
							<SelectPicker
								data={BASE_STORES_LIST}
								style={{
									width: 200,
								}}
								groupBy='role'
								placeholder='Select Store'
								onChange={(storeId) => {
									setStore(storeId);
								}}
								cleanable={false}
								renderMenuItem={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-user' />
											{_}
										</div>
									);
								}}
								renderMenuGroup={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-group' />
											{_}
										</div>
									);
								}}
								value={
									store
								}
								renderValue={(value, item) => {
									return (
										<div className='rsuite-select-picker-render-value'>
											{item?.label}
										</div>
									);
								}}
							/>
						</div>
					)}
					{FILTERS?.includes('filteredStore') && (
						<div className={styles.storeDiv}>
							Kitchen
							<SelectPicker
								data={BASE_FILTERED_STORES_LIST}
								style={{
									width: 200,
								}}
								groupBy='role'
								placeholder='Select Store'
								onChange={(storeId) => {
									setStore(storeId);
								}}
								cleanable={false}
								renderMenuItem={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-user' />
											{_}
										</div>
									);
								}}
								renderMenuGroup={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-group' />
											{_}
										</div>
									);
								}}
								value={
									store
								}
								renderValue={(value, item) => {
									return (
										<div className='rsuite-select-picker-render-value'>
											{item?.label}
										</div>
									);
								}}
							/>
						</div>
					)}
					{FILTERS?.includes('processType') && (
						<div className={styles.storeProcess}>
							{filterNames?.processType ?? 'Process Type'}
							<Select
								className={styles['store-select']}
								value={processType}
								onChange={setProcessTypes}
								disabled={store === EMPTYVALUE}
							>
								{PROCESS_TYPES?.map((pT) => {
									return (
										<MenuItem key={pT?.id} value={pT?.id}>
											{pT?.title}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					)}
					{FILTERS?.includes('productionPlan') && (
						<div className={styles['pp-filter']}>
							Recent Production Plan
							<div className={styles['pp-filter-container']}>
								{/* <Select
									labelId='mutiple-select-label'
									multiple
									value={productionPlan}
									onChange={handleProductionPlansSelection}
									renderValue={(selected) => {
										const selectedList = selected?.map(
											(id) => {
												const pPlan =
													PRODUCTION_PLANS?.find(
														(ele) => {
															return (
																ele?.id === id
															);
														}
													);
												const idString =
													pPlan?.id !== allId
														? '(ID: ' +
														pPlan?.id +
														')'
														: '';
												return (
													pPlan?.description +
													idString
												);
											}
										);
										return selectedList.join(', ');
									}}
									defaultChecked={-99}
									className={styles.select}
									sx={{
										width: 150,
									}}
								>
									{PRODUCTION_PLANS && (
									)}
								</Select> */}
								<CheckPicker
									data={PRODUCTION_PLANS.map((ele) => ({
										label: ele?.description,
										value: ele?.id,
									}))}
									searchable={false}
									// eslint-disable-next-line object-curly-newline
									style={{ width: 224 }}
									placeholder='Recent Production Plan'
									onChange={handleProductionPlansSelection}
									value={productionPlan}
								/>
								{/* <div className={styles.duration}>
									{['Week', 'Month']?.map((ele) => {
										const isChecked =
											ele === productionPlanDuration;
										return (
											<div
												key={ele}
												onClick={() => {
													handlePPDuration(ele);
												}}
												className={styles['dur-type']}
											>
												<input
													type='radio'
													checked={isChecked}
												/>
												<p>
													{isChecked
														? ele +
														"'s production plans"
														: ele}
												</p>
											</div>
										);
									})}
								</div> */}
							</div>
						</div>
					)}
					{FILTERS?.includes('fulfilledBy') && (
						<div className={styles.storeDiv}>
							Deliver from
							<Select
								className={styles['store-select']}
								value={fulfilledBy}
								onChange={setFulfilledBy}
								disabled={false}
							>
								{BASE_STORES?.map((storeObj) => {
									return (
										<MenuItem
											key={storeObj.id + storeObj.title}
											value={storeObj?.id}
										>
											{storeObj.title}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					)}
					{FILTERS?.includes('vendor') && (
						<div className={classes(styles.filter, styles.vendor)}>
							<p className={styles['filter-heading']}>
								Select Vendor(s)
							</p>
							<div className={styles['filter-body']}>
								<div className={styles.tags}>
									{vendors.map((id) => {
										const selectedVendor = VENDORS?.find(
											(v) => {
												return id === v?.id;
											}
										);
										return (
											<Tag
												key={selectedVendor?.id}
												text={selectedVendor?.title}
												handleDelete={deleteFilter}
												dataAttrs={{
													'data-id':
														selectedVendor?.id,
													'data-entity': 'VENDOR',
												}}
											/>
										);
									})}
									{vendors?.length === 0 && (
										<span>All Vendors selected</span>
									)}
								</div>
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									onClick={() => {
										toggleModal(true, {
											entity: 'VENDOR',
											heading: 'Select Vendors(s)',
											list: VENDORS,
											selected: vendors,
										});
									}}
								>
									VENDOR
								</Button>
							</div>
						</div>
					)}
					{FILTERS?.includes('status') && (
						<div className={styles['status-select']}>
							<InputLabel id='mutiple-select-label'>
								Select Status
							</InputLabel>
							<Select
								labelId='mutiple-select-label'
								multiple
								value={selectedStatus}
								onChange={handleStatusChange}
								renderValue={(selected) => {
									return selected.join(', ');
								}}
								defaultChecked='All'
								className={styles.select}
							>
								<MenuItem key='All' value='All'>
									<ListItemIcon>
										<Checkbox
											checked={
												selectedStatus.indexOf('All') >
												-1
											}
										/>
									</ListItemIcon>
									<ListItemText primary='All' />
								</MenuItem>
								{options?.map((option) => {
									if (option === 'FULFILLED') {
										option = 'CLOSED';
									}
									return (
										<MenuItem key={option} value={option}>
											<ListItemIcon>
												<Checkbox
													checked={
														selectedStatus.indexOf(
															option
														) > -1 ||
														selectedStatus.indexOf(
															'All'
														) > -1
													}
												/>
											</ListItemIcon>
											<ListItemText primary={option} />
										</MenuItem>
									);
								})}
							</Select>
						</div>
					)}
					{FILTERS?.includes('dateRange') && (
						<div className={classes(styles['range-container'])}>
							<div
								onClick={() => {
									notificationServices.generateNotification({
										type: 'error',
										message:
											'Please remove Production Plan Filter',
									});
								}}
								className={
									productionPlan?.length
										? styles.disabled
										: ''
								}
							/>
							<Select
								value={dateType ?? ''}
								className={styles['time-select']}
								onChange={selectDateType}
							>
								{dateTypeArr.map((type, idx) => {
									return (
										<MenuItem
											// eslint-disable-next-line react/no-array-index-key
											key={idx}
											value={type.value}
										>
											{type.label}
										</MenuItem>
									);
								})}
							</Select>
							<UYDateRange
								dateRanges={dateRangeFilterArray}
								onDateRangeChange={setDate}
								enableOnlyBackwardSelection={!allowFutureDate}
							/>
						</div>
					)}
					<div className={styles['find-combine-container']}>
						<div
							className={classes(
								styles['search-icon'],
								shouldAnimate ? styles?.animate : ''
							)}
							onClick={()=>{handleFetchPurchaseOrderList()}}
						>
							<ArrowForward className={styles.icon} />
						</div>
						{isFulfilledByBaseStore && (
							<div
								onClick={combinePO}
								className={styles['combine-po']}
							>
								<MergeType className={styles.icon} />
								<p>Combine</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const filteredStore = state?.form?.FILTERED_STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const processTypesList = state?.form?.PROCESS_TYPES?.data?.LIST;
	const { LIST: productionPlans } = state?.analytics.PRODUCTION_PLANS;

	return {
		stores,
		dateRangeFilter,
		vendorsList,
		processTypesList,
		productionPlans,
		filteredStore,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchAllVendors: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchPurchaseOrderList: analyticsAction.gateway,
	fetchProcessTypesList: formActions.gateway,
	fetchProductionPlans: analyticsAction.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFinderHeader);
