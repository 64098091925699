import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import {
	CookingStationCreatePage,
	CookingStationsListPage,
} from '../../../Pages/Cooking/Cooking Stations';
import CookingStationViewPage from '../../../Pages/Cooking/Cooking Stations/CookingStationViewPage';
// eslint-disable-next-line object-curly-newline

import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const CookingStationsRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.COOKING_STATIONS}`,
			name: 'Cooking Stations',
		},
	];
	return (
		<CRUDRoutes
			entity='COOKING STATIONS'
			path={path}
			listPage={CookingStationsListPage}
			createPage={<CookingStationCreatePage breadcrumbs={breadcrumbs} />}
			viewPage={<CookingStationViewPage breadcrumbs={breadcrumbs} />}
			editPage={
				<CookingStationCreatePage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default CookingStationsRoutes;
