import { Text, Circle } from 'react-konva';

const convertFahrenheitToCelsius = (fahrenheitTemp) => {
	return Number(Number(((Number(fahrenheitTemp) - 32) * 5) / 9).toFixed(1));
};

function pickHex(color1, color2, weight) {
	const w1 = weight;
	const w2 = 1 - w1;
	const rgb = [
		Math.round(color1[0] * w1 + color2[0] * w2),
		Math.round(color1[1] * w1 + color2[1] * w2),
		Math.round(color1[2] * w1 + color2[2] * w2),
	];
	return rgb;
}

function mapTemperatureToColor(temp, isFahrenheit, transparency) {
	let red = 0;
	let green = 0;
	let blue = 0;
	const temperature = isFahrenheit
		? convertFahrenheitToCelsius(temp)
		: Number(temp);

	// Map temperature to color gradient
	if (temperature < 20) {
		blue = 255;
		green = 65;
	} else if (temperature >= 20 && temperature < 30) {
		const baseColor = [255, 105, 0]; // Orange
		const gradientColor = [230, 165, 5]; // Darker Orange
		const weight = (temperature - 20) / (30 - 20);
		[red, green, blue] = pickHex(baseColor, gradientColor, weight);
	} else if (temperature >= 30 && temperature < 40) {
		const baseColor = [255, 70, 0]; // Dark Orange
		const gradientColor = [255, 105, 0]; // Orange
		const weight = (temperature - 30) / (40 - 30);
		[red, green, blue] = pickHex(baseColor, gradientColor, weight);
	} else if (temperature >= 40 && temperature < 60) {
		const baseColor = [255, 0, 0]; // Red
		const gradientColor = [255, 70, 0]; // Dark Orange
		const weight = (temperature - 40) / (60 - 40);
		[red, green, blue] = pickHex(baseColor, gradientColor, weight);
	} else {
		red = 255;
		green = 0;
		blue = 0;
	}

	// Return the RGBA value
	return `rgba(${red}, ${green}, ${blue}, ${transparency})`;
}

const CircleLabel = (props) => {
	const { x, y, value, width, isFahrenheit } = props;
	return (
		<>
			<Circle
				x={x}
				y={y}
				radius={width / 2}
				fill={mapTemperatureToColor(value, isFahrenheit, 0.2)}
				stroke={mapTemperatureToColor(value, isFahrenheit, 0.8)}
				// fill='rgba(0, 0, 0, 0.2)'
				// stroke='rgba(0, 0, 0, 0.8)'
			/>
			<Text
				x={x - width}
				y={y - width}
				fontSize={width / 4 < 8 ? 8 : width / 4}
				text={value}
				wrap='char'
				align='center'
				verticalAlign='middle'
				fill='white'
				padding={4}
				width={width * 2}
				height={width * 2}
			/>
		</>
	);
};

export default CircleLabel;
