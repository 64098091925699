/* eslint-disable */
import { cloneDeep, get, update } from 'lodash';
import { notificationServices } from '../../../Services';
import { isEmpty } from '../../../Helpers';
// eslint-disable-next-line import/no-unresolved
import cookingRecipesServices from '@/Services/Form Services/cookingRecipes.services';

export const findAllIntermediateProducts = (data) => {
	const skus = [];
	const recursionFn = (obj, idx) => {
		if (obj?.skus) {
			skus.push(
				...obj?.skus?.filter((ele) => {
					return ele?.skuType === 'INTERMEDIATE';
				})
			);
			return;
		}
		if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
			Object.keys(obj)?.forEach((objKey) => {
				const objValue = obj[objKey];
				if (objKey !== 'skus') {
					recursionFn(objValue, idx + 1);
				}
			});
		}
		if (typeof obj === 'object' && obj !== null && Array.isArray(obj)) {
			obj?.forEach((objValue) => {
				recursionFn(objValue, idx + 1);
			});
		}
	};
	recursionFn(
		{
			...data?.recipe,
			stages: [data?.recipe?.stages?.[0]],
		},
		0
	);
	return skus;
};
export const findSerializedIntermediateProducts = (data) => {
	const skus = [];
	const recursionFn = (obj, idx) => {
		if (obj?.skus) {
			skus.push(
				...obj?.skus?.filter((ele) => {
					return ele?.skuType === 'INTERMEDIATE';
				})
			);
			return;
		}
		Object.keys(obj)?.forEach((objKey) => {
			const objValue = obj[objKey];
			if (
				typeof objValue === 'object' &&
				objValue !== null &&
				!Array.isArray(objValue) &&
				objKey !== 'skus'
			) {
				recursionFn(objValue, idx + 1);
			}
		});
	};
	recursionFn(
		data,
		0
	);
	return skus;
};

const cookingRecipeConfig = {
	initialState: {
		fields: {
			id: null,
			instructions: '',
			recipeLink: '',
			version: 1,
			isBulk: false,
			scheduledAtDelta: '0',
			weightTolerance: null,
			isIntermediate: false,
			totalCookingTime: 0,
			batchSize: {},
			stages: {
				items: {},
				order: [],
			},
			product: {
				id: '',
				productName: '',
				brandName: '',
				productImageUrl: '',
				variants: [],
				defaultVariant: {},
				unit: '',
			},
		},
		errors: {
			instructions: '',
			recipeLink: '',
		},
	},
	mandatoryFields: {
		instructions: false,
		recipeLink: false,
		batchSize: false,
		stages: {
			name: true,
			station: true,
			instructions: false,
			processes: {
				name: true,
				time: true,
				instructions: false,
				customizations: {
					items: {
						1: {
							name: true,
							type: true,
							limit: true,
							skus: [
								{
									qty: true,
									price: true,
								},
							],
						},
					},
				},
			},
		},
	},
	newStageObj: {
		id: null,
		stageId: null,
		name: '',
		station: null,
		instructions: '',
		isDependent: false,
		dependencies: [],
		isBulk: false,
		scheduledAtDelta: 0,
		processes: {
			items: {},
			order: [],
		},
	},
	newProcessObj: {
		id: null,
		processId: null,
		name: '',
		time: 0,
		instructions: '',
		isCustomizable: false,
		customizations: {
			items: {
				1: {
					name: 'Unnamed Customization',
					type: '',
					limit: null,
					isRemovable: false,
					deltaPricing: false,
					applicableOn: [],
					skus: [],
				},
			},
			order: [1],
		},
	},
	serializer: (state, data, processTypesList = []) => {
		const clonedState = cloneDeep(state);
		const clonedData = cloneDeep(data);
		const currStage = clonedData?.recipe?.stages?.[0] ?? {};
		const currProductProcess = processTypesList?.find((ele) => {
			return ele?.id === clonedData?.product?.processId;
		});
		return update(clonedState, 'fields', (fields) => {
			const newFields = fields;
			newFields.product = {
				id: clonedData.product.id,
				productName: clonedData.product.productName,
				brandName: clonedData.product.brandName,
				productImageUrl: clonedData.product.productImageUrl,
				variants: clonedData.product.variants?.map((variant) => {
					return {
						...variant,
						batchSize:
							clonedData?.recipe?.variantBatchSize?.[
								variant?.id
							] ?? null,
					};
				}),
				unit: clonedData.product.unit,
				defaultVariant: clonedData.product.variants.find((variant) => {
					return variant.isDefault;
				}),
			};

			if (!isEmpty(clonedData.recipe)) {
				newFields.id = clonedData.recipe.id;
				newFields.isIntermediate = clonedData.recipe.isIntermediate;
				newFields.totalCookingTime = clonedData.recipe.totalCookingTime;
				newFields.version = clonedData.recipe.version;
				newFields.scheduledAtDelta = clonedData.recipe.scheduledAtDelta
					? Number(clonedData.recipe.scheduledAtDelta)
					: 0;
				newFields.isBulk = clonedData.recipe.isBulk;
				newFields.batchSize = clonedData.recipe.variantBatchSize ?? {};
				// newFields.product.unit = clonedData.product.unit;
				newFields.weightTolerance =
					clonedData.recipe?.weightTolerance ?? '';
				newFields.instructions = clonedData.recipe.recipeDescription;
				newFields.recipeLink = clonedData.recipe.recipeURL;
				newFields.stages = {
					items: {
						1: {
							id: 1,
							stageId: currStage.id,
							name: clonedData?.product?.productName,
							stageProduct: newFields.product,
							station: currStage.stationId,
							instructions: currStage.stageDescription,
							rawStageValue: {
								...currStage,
							},
							process: {
								...currProductProcess?.data,
								id: currProductProcess?.id,
							},
							isDependent:
								currStage.stageDependency?.length > 0 ?? false,
							dependencies: currStage.stageDependency.map(
								(stageId) => {
									const foundIndex =
										clonedData.recipe.stages.findIndex(
											(stage) => {
												return (
													stage.id ===
													parseInt(stageId, 10)
												);
											}
										);
									return foundIndex + 1;
								}
							),
							processes: {
								items: currStage.processes.reduce(
									(accProcesses, currProcess, idxProcess) => {
										// eslint-disable-next-line no-param-reassign
										accProcesses[idxProcess + 1] = {
											id: idxProcess + 1,
											processId: currProcess.id,
											name: currProcess.processName,
											time: currProcess.processTime,
											instructions:
												currProcess.processDescription,
											isCustomizable:
												currProcess.customizationType !==
												'NONE',
											customizations: {
												items: {
													1: {
														name: currProcess.customizationName,
														type: currProcess.customizationType,
														limit: currProcess.addOnLimit,
														isRemovable:
															currProcess.removableProcess,
														deltaPricing:
															currProcess.deltaPricing,
														applicableOn:
															clonedData.product.variants.map(
																(variant) => {
																	return {
																		id: variant.id,
																		name: variant.displayName,
																		size: variant.size,
																		isApplied:
																			currProcess.applicableSizeVariants.includes(
																				variant.id.toString()
																			),
																	};
																}
															),
														skus: currProcess.skus.map(
															(skuObj) => {
																return {
																	id: skuObj.id,
																	skuId: skuObj.skuId,
																	isDefault:
																		skuObj.replaceable,
																	unit: skuObj.unit,
																	variantId:
																		skuObj.variantId,
																	processFlowId:
																		skuObj.processId,
																	skuType:
																		skuObj.skuType,
																	containerWeight:
																		skuObj?.containerWeight,
																	variants:
																		clonedData.product.variants.map(
																			(
																				variant,
																				idx
																			) => {
																				if (
																					variant.id ===
																					get(
																						skuObj.pricing,
																						`pricing[${idx}]`,
																						{}
																					)
																						.id
																				) {
																					return skuObj
																						.pricing
																						.pricing[
																						idx
																					];
																				}
																				return {
																					id: variant.id,
																					qty: 1,
																					price: 1,
																				};
																			}
																		),
																};
															}
														),
													},
												},
												order: [1],
											},
										};
										return accProcesses;
									},
									{}
								),
								order: currStage.processes.map(
									(process, idx) => {
										return idx + 1;
									}
								),
							},
						},
					},
					order: clonedData.recipe.stages.map((stage, idx) => {
						return idx + 1;
					}),
				};
			} else {
				newFields.id = null;
				newFields.instructions = '';
				newFields.recipeLink = '';
				newFields.version = 1;
				newFields.isIntermediate = false;
				newFields.totalCookingTime = 0;
				newFields.isRecipeEmpty= true;
				newFields.stages = {
					items: {
						1: {
							id: 1,
							stageId: clonedData?.product?.id,
							name: clonedData?.product?.productName,
							stageProduct: newFields?.product,
							station: null,
							instructions: '',
							skuType: clonedData?.product?.skuType,
							process: {
								...currProductProcess?.data,
								id: currProductProcess?.id,
							},
							isDependent: false,
							dependencies: [],
							processes: {
								items: {},
								order: [],
							},
						},
					},
					order: [1],
				};
			}

			return newFields;
		});
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const firstStage = clonedState?.fields?.stages?.items?.['1'];
		const req = {};
		let totalCookingTime = 0;
		req.id = clonedState.fields.id;
		req.productId = clonedState.fields.product.id;
		req.scheduledAtDelta = Number(clonedState.fields.scheduledAtDelta);
		req.recipeDescription = clonedState.fields.instructions;
		req.recipeURL = clonedState.fields.recipeLink;
		req.variantBatchSize = clonedState.fields.batchSize;
		req.isBulk = clonedState.fields.isBulk;
		req.weightTolerance =
			clonedState.fields?.weightTolerance?.length > 0
				? Number(clonedState.fields.weightTolerance)
				: null;
		req.isIntermediate = false;
		req.stages = [{
			id: firstStage?.rawStageValue?.id,
			stageName: 'Stage 1',
			stageDescription: firstStage.instructions,
			stationId: parseInt(firstStage.station, 10),
			stageDependency: firstStage.dependencies.map((depId) => {
				const depIdx = clonedState.fields.stages.order.findIndex(
					(sId) => {
						return sId === depId;
					}
				);
				return parseInt(depIdx, 10);
			}),
			processes: firstStage.processes.order.map((processId) => {
				const process = firstStage.processes.items[processId];
				const customization = process.customizations.items[1];
				totalCookingTime += parseInt(process.time, 10);
				return {
					id: process.processId,
					processName: process.name,
					processDescription: process.instructions,
					processTime: process.time,
					customizationType: process.isCustomizable
						? customization.type
						: 'NONE',
					deltaPricing: customization.deltaPricing,
					customizationName: customization.name,
					removableProcess: customization.isRemovable,
					addOnLimit: customization.limit,
					applicableSizeVariants: customization.applicableOn
						.reduce((accVariants, currVariant) => {
							if (
								currVariant.isApplied ||
								!process.isCustomizable
							) {
								accVariants.push(currVariant.id);
							}
							return accVariants;
						}, [])
						.join(','),
					skus: customization.skus.map((sku) => {
						return {
							id: sku.id,
							skuId: sku.skuId,
							variantId: sku.variantId,
							unit: sku.unit,
							replaceable: sku.isDefault,
							containerWeight: sku?.containerWeight
								? sku?.containerWeight
								: null,
							pricing: {
								pricing: sku.variants.filter(
									(variant, idx) => {
										return (
											customization.applicableOn[idx]
												.isApplied ||
											!process.isCustomizable
										);
									}
								),
							},
						};
					}),
				};
			}),
		}];
		req.totalCookingTime = totalCookingTime;
		return req;
	},
	validator: (state) => {
		const totalStages = 1;
		// at least one stage
		if (totalStages === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please create at least 1 Cooking Stage',
			});
			return false;
		}
		const variantBatchSize = state?.fields?.product?.variants?.every(
			(ele) => {
				return state?.fields?.batchSize?.[ele?.id];
			}
		);
		if (!variantBatchSize) {
			notificationServices.generateNotification({
				type: 'error',
				message: `Batch size is mandatory for all Variants of ${state?.fields?.product?.productName}`,
			});
			return false;
		}
		for (let stageIdx = 0; stageIdx < totalStages; stageIdx++) {
			// const stageId = state.fields.stages.order[stageIdx];
			const stage = state.fields.stages.items[1];
			// stage mandatory fields check
			if (stage.name.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please enter a stage name for Stage - ${
						stageIdx + 1
					} in ${state?.fields?.product?.productName}`,
				});
				return false;
			}
			if (stage.station === '' || !stage.station) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please select a cooking station for Stage - ${
						stageIdx + 1
					} in ${state?.fields?.product?.productName}`,
				});
				return false;
			}
			// stage dependency check
			if (stage.isDependent && stage.dependencies.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `You have checked Stage - ${
						stageIdx + 1
					} to be dependent but haven't added any dependencies in ${
						state?.fields?.product?.productName
					}`,
				});
				return false;
			}
			// PROCESS
			const totalProcesses = stage.processes.order.length;
			// at least one process in a stage
			if (totalProcesses === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please create at least 1 Cooking Step inside ${state?.fields?.product?.productName}`,
				});
				return false;
			}

			for (
				let processIdx = 0;
				processIdx < totalProcesses;
				processIdx++
			) {
				const processId = stage.processes.order[processIdx];
				const process = stage.processes.items[processId];
				// process mandatory fields check
				if (process.name.length === 0) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Please enter a process name for Step - ${
							processIdx + 1
						}, inside ${
							state?.fields?.product?.productName
						}`,
					});
					return false;
				}
				// if (process.instructions.length === 0) {
				// 	notificationServices.generateNotification({
				// 		type: 'error',
				// 		message: `Please enter a process description for Step - ${
				// 			processIdx + 1
				// 		}, inside ${
				// 			state?.fields?.product?.productName
				// 		}`,
				// 	});
				// 	return false;
				// }

				if (
					process.time.length === 0 ||
					parseInt(process.time, 10) <= 0
				) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Please enter a positive process time for Step - ${
							processIdx + 1
						}, inside ${
							state?.fields?.product?.productName
						}`,
					});
					return false;
				}
				// CUSTOMIZATION
				// customization checks
				if (process.isCustomizable) {
					// customization type cannot be empty
					const groupObj = process.customizations.items[1];
					if (groupObj.type === '' || !groupObj.type) {
						notificationServices.generateNotification({
							type: 'error',
							message: `Please select a customization type in in Step - ${
								processIdx + 1
							}, inside ${
								state?.fields?.product?.productName
							}`,
						});
						return false;
					}
					const isAppliedToSomeVariant = groupObj.applicableOn.some(
						(variant) => {
							return variant.isApplied;
						}
					);
					// at least one variant must be selected
					if (!isAppliedToSomeVariant) {
						notificationServices.generateNotification({
							type: 'error',
							message: `Please apply customization to at least one variant in Process - ${
								processIdx + 1
							}, inside Stage - ${stageIdx + 1} in ${
								state?.fields?.product?.productName
							}`,
						});
						return false;
					}
					// at least one sku must be selected in a group: SKIPPED

					// SKUS
					const totalSkus =
						process.customizations.items[1].skus.length;
					for (let skuIdx = 0; skuIdx < totalSkus; skuIdx++) {
						const sku =
							process.customizations.items[1].skus[skuIdx];
						// variant level qty
						const totalVariants = sku.variants.length;
						for (
							let variantIdx = 0;
							variantIdx < totalVariants;
							variantIdx++
						) {
							const variant = sku.variants[variantIdx];
							// sku checks
							if (
								variant.qty.length === 0 ||
								parseInt(variant.qty.length, 10) <= 0
							) {
								notificationServices.generateNotification({
									type: 'error',
									message: `Please enter a positive qty for ingredients in Process - ${
										processIdx + 1
									}, inside Stage - ${stageIdx + 1} in ${
										state?.fields?.product?.productName
									}`,
								});
								return false;
							}
						}
					}
				}
			}
		}

		return true;
	},
};

export const fetchProductsRecipe2 = async (products = [], processTypes, startFrom = 1, forStage = 0) => {
	const recipesList = {};
	let totalItems = 0;

	const recCall = async (productsArr = [], processTypes, innerForStage) => {
		try {
			const apis = productsArr.map((ele) =>
				cookingRecipesServices.gateway({
					req: {
						params: {
							productId: ele?.skuId,
						},
					},
					method: 'VIEW',
				})
			);

			const values = await Promise.all(apis);
			for (let i = 0; i < values?.length; i++) {
				const ele = values[i];
				if (ele?.data) {
					const serializedData = cookingRecipeConfig.serializer(
						{
							fields: {
								product: {},
							},
						},
						ele.data,
						processTypes
					)?.fields;
					const { stages: cookingStage, ...remaining } = serializedData;
					const srlzedValue = cookingStage?.items?.['1'];
					const doesExist = Object.entries(recipesList)?.find(
						([key, rcpeEle]) => {
							return (
								rcpeEle?.stageProduct?.id ===
								srlzedValue?.stageProduct?.id
							);
						}
					);
					startFrom += i;
					if (!doesExist) {
						recipesList[startFrom] = {
							...srlzedValue,
							forStage: [innerForStage],
							recipeData: remaining,
						};
						totalItems++;
					} else {
						recipesList[doesExist[0]]?.forStage?.push(innerForStage);
					}

					const recipeIntermediateProducts =
						findSerializedIntermediateProducts(srlzedValue);
					if (recipeIntermediateProducts?.length) {
						innerForStage = startFrom;
						startFrom += 1;
						await recCall(recipeIntermediateProducts, processTypes, innerForStage);
					}
				}
			}
		} catch (error) {
			console.error('Error fetching product recipes:', error);
		}
	};

	await recCall(products, processTypes, forStage);
	return {
		recipesList,
		totalItemsCount: totalItems,
	};
};


export const fetchProductsRecipe = async (products = [], processTypes) => {
	try {
		const apis = products?.map((ele) => {
			const productRecipeResponse = cookingRecipesServices.gateway({
				req: {
					params: {
						productId: ele?.skuId,
					},
				},
				method: 'VIEW',
			});
			return productRecipeResponse;
		});
		const responseArray = Promise.all(apis).then((values) => {
			return values?.map((ele) => {
				return cookingRecipeConfig.serializer(
					{
						fields: {
							product: {},
						},
					},
					ele?.data,
					processTypes
				)?.fields;
			});
		});
		return responseArray;
	} catch (error) {
		return [];
	}
};

export const dFIClosure = (acc = {}) => {
	const recFn = async (
		productsArr = [],
		processTypes,
		currentStage = null,
		itemsAddedCount = 0
	) => {
		const prdctsRecipes = await fetchProductsRecipe(
			productsArr,
			processTypes
		);
		for (const ele of prdctsRecipes) {
			const newOrder = (currentStage ?? acc?.order?.length) + 1;
			const stageIdToCheck = ele?.stages?.items?.['1']?.stageId;

			const isStageAlreadyExists = Object.entries(acc.items).find(
				([key, value]) => value?.stageId === stageIdToCheck
			);
			if (isStageAlreadyExists !== undefined) {
				acc.items[isStageAlreadyExists?.[0]] = {
					...isStageAlreadyExists[1],
					forStage: [
						...isStageAlreadyExists[1]?.forStage,
						acc?.stageLevel,
					],
				};
			} else {
				acc.items[newOrder] = {
					...ele?.stages?.items?.['1'],
					isIntermediate: true,
					forStage: [acc?.stageLevel],
				};
				if (!acc?.order?.includes(newOrder)) {
					acc?.order?.push(newOrder);
				}
				itemsAddedCount++;
			}

			const recipeIntermediateProducts =
				findSerializedIntermediateProducts(ele);
			if (recipeIntermediateProducts?.length) {
				acc = {
					...acc,
					stageLevel: acc.order?.length,
				}
				itemsAddedCount = await recFn(
					recipeIntermediateProducts,
					processTypes,
					currentStage ? currentStage + 1 : undefined,
					itemsAddedCount
				);
			}
		}
		return itemsAddedCount;
	};
	return recFn;
}

export const depthFirstIntermediates = async (
	productsArr = [],
	acc = {},
	processTypes,
	currentStage = null,
	itemsAddedCount = 0,
) => {
	const prdctsRecipes = await fetchProductsRecipe(productsArr, processTypes);
	for (const ele of prdctsRecipes) {
		const newOrder = (currentStage ?? acc?.order?.length) + 1;
		const stageIdToCheck = ele?.stages?.items?.['1']?.stageId;

		const isStageAlreadyExists = Object.entries(acc.items).find(
			([key, value]) => value?.stageId === stageIdToCheck
		);
		if (isStageAlreadyExists !== undefined) {
			acc.items[isStageAlreadyExists?.[0]] = {
				...isStageAlreadyExists[1],
				forStage: [
					...isStageAlreadyExists[1]?.forStage,
					acc?.stageLevel,
				],
			};
		} else {
			const { stages: eleStages, ...remaining } = ele;
			acc.items[newOrder] = {
				...eleStages?.items?.['1'],
				recipeData: remaining,
				isIntermediate: true,
				forStage: [acc?.stageLevel],
			};
			if (!acc?.order?.includes(newOrder)) {
				acc?.order?.push(newOrder);
			}
			itemsAddedCount++;
		}

		const recipeIntermediateProducts =
			findSerializedIntermediateProducts(ele);
		if (recipeIntermediateProducts?.length){
			itemsAddedCount = await depthFirstIntermediates(
				recipeIntermediateProducts,
				{
					...acc,
					stageLevel: acc.order?.length,
				},
				processTypes,
				currentStage ? currentStage + 1 : undefined,
				itemsAddedCount
			);
		}
	}
	return itemsAddedCount;	
};

export const addToStages = async ({
	productsArr,
	addToIndex,
	activeStage,
	processTypes,
	currentStagesAcc,
}) => {
	const addedItemsObj = await fetchProductsRecipe2(
		productsArr,
		processTypes,
		activeStage + 1,
		activeStage
	);

	currentStagesAcc.items = Object.entries(currentStagesAcc.items).reduce((acc, [key, valuee]) => {
		const numKey = Number(key);
		if (numKey >= (activeStage + 1)) {
			acc[Number(key) + addedItemsObj?.totalItemsCount] = {
				...valuee,
				forStage: valuee.forStage?.map((ele) => {
					if (ele >= (activeStage + 1)) {
						return ele + addedItemsObj?.totalItemsCount;
					}
					return ele;
				})
			}
		} else {
			acc[key] = valuee;
		}
		return acc;
	}, {})
	let keys = Object.keys(currentStagesAcc.items); 
	const newKeys = Object.keys(addedItemsObj.recipesList);
	const indexOfActiveStage = keys.indexOf(`${activeStage}`)
	if (indexOfActiveStage >= 0) {
		const newStages = {
			items: {},
			order: [],
		};
		keys.splice(indexOfActiveStage + 1, 0, ...newKeys);
		const mergedStages = {
			...currentStagesAcc.items,
			...addedItemsObj.recipesList
		};
		keys?.forEach((eleKey) => {
			const isAlreadyAdded = Object.entries(newStages.items)?.find(([key, value]) => {
				return value?.stageProduct?.id === mergedStages[eleKey]?.stageProduct?.id;
			});
			if (!isAlreadyAdded) {
				newStages.items[eleKey] = mergedStages[eleKey];
				newStages.order.push(eleKey);
			} else {
				newStages.items[isAlreadyAdded[0]] = {
					...isAlreadyAdded[1],
					forStage: [
						...isAlreadyAdded[1].forStage,
						...mergedStages[eleKey]?.forStage,
					],
				};
			}
		});
		currentStagesAcc = newStages;
	}

	return currentStagesAcc;
};

export const sortStages = (
	productsArr = [],
	acc = {}
) => {
	for (const ele of prdctsRecipes) {
		const newOrder = acc?.order?.length + 1;
		const isStageAlreadyExists = Object.entries(acc.items)?.find(
			([key, value]) => {
				return value?.stageId === ele?.stages?.items?.['1']?.stageId;
			}
		);
		if (isStageAlreadyExists !== undefined) {
			acc.items[isStageAlreadyExists?.[0]] = {
				...isStageAlreadyExists[1],
				forStage: [
					...isStageAlreadyExists[1]?.forStage,
					acc?.stageLevel,
				],
			};
		} else {
			acc.items[newOrder] = {
				...ele?.stages?.items?.['1'],
				isIntermediate: true,
				forStage: [acc?.stageLevel],
			};
			acc?.order?.push(newOrder);
		}

		const recipeIntermediateProducts =
			findSerializedIntermediateProducts(ele);

		depthFirstIntermediates(
			recipeIntermediateProducts,
			{
				...acc,
				stageLevel: acc.order?.length,
			}
		);
	}
};

export const updateForStageReferences = (stages, addedIndex, addedCount = 0) => {
	const updatedStages = { ...stages };

	Object.entries(updatedStages).forEach(([key, stage]) => {
		if (stage.forStage) {
			updatedStages[key] = {
				...stage,
				forStage: stage.forStage.map((index) => {
					if (index >= addedIndex) {
						console.log('inddeexxxxx', index, stage?.forStage, stage?.stageProduct?.productName);
						return index + addedCount;
					}
					return index;
				}),
			};
		}
	});

	return updatedStages;
};


export default cookingRecipeConfig;
