/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-self-compare */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { PictureAsPdf } from '@mui/icons-material';
import CreateHeader from './CreateHeader';
import VendorQouteListTable from './VendorQouteListTable';
import quoteServices from '@/Services/QuoteServices/quote.services';
import { notificationServices } from '@/Services';

function VendorResponseQuote() {
	const history = useHistory();
	const params = useParams();
	const [assetType, setAssetType] = useState([]);
	const [vendorResponseQuote, setVendorResponseQuote] = useState([]);
	const [finalTableData, setFinalTableData] = useState([]);
	const [tableHeader, setTableHeader] = useState([]);
	const [selectedQuote, setSelectedQuote] = useState({});
	const [editableValues, setEditableValues] = useState({});
	const [qouteGetData, setQouteGetData] = useState([]);
	const handleSave = async () => {
		try {
			const data = Object.entries(selectedQuote).flatMap(([assetId, quotes]) => quotes.map((quote) => ({
				assetRFQId: params?.id,
				assetRFQItem: {
					id: quote.itemId,
					assetType: quote?.assetType,
				},
				assetRFQReplyItem: {
					id: quote?.id,
					coreSpecificationsTemp: quote?.coreSpecifications,
					oem: {
						id: quote?.oem?.id,
						name: quote?.oem?.name,
					},
				},
				storeId: quote?.storeId,
				vendorId: quote?.vendorId,
				qty: quote.qty,
				totalPrice: quote.price,
			})));
			if (data.map((item) => item.totalPrice).some((price) => price === 0)) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Total price cannot be zero',
				});
				return;
			}
			const response = await quoteServices?.saveVendorQuoteReply({
				req: {
					data,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: 'Quote Request Accepted Successfully',
				});
				history.goBack();
			} else {
				notificationServices?.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};

	const selectedQuoteData = () => {
		return Object.entries(selectedQuote).map(([assetId, quotes]) => ({
			...quotes.map((quote) => ({
				assetId,
				id: quote?.id,
				qty: quote?.qty,
				price: quote?.price,
			})),
		}));
	};

	useEffect(() => {
		const headerData = vendorResponseQuote.map((item) => {
			return {
				vendor: item?.vendor?.name,
				doc: item?.refDoc,
			};
		});
		const ans = vendorResponseQuote
			?.map((quote) => quote?.assetRFQ?.items?.map((item) => ({
				vendorId: quote?.vendor?.id,
				vendor: quote?.vendor?.name,
				assetName: assetType?.find((asset) => asset?.id === item?.assetType)?.name,
				assetType: item?.assetType,
				quantity: item?.qty || 0,
			})))
			.flat();
		const groupedAns = ans.reduce((acc, curr) => {
			const existingAsset = acc.find((a) => a.assetName === curr.assetName);
			if (!existingAsset) {
				acc.push({
					assetName: curr.assetName,
					assetType: curr.assetType,
					quantity: curr.quantity,
				});
			}
			return acc;
		}, []);
		const quotes = vendorResponseQuote
			?.flatMap((quote) => quote?.items?.map((item) => ({
				itemId: item?.assetRFQItem?.id,
				storeId: quote?.assetRFQ?.store,
				vendorId: quote?.vendor?.id,
				vendor: quote?.vendor?.name,
				assetType: item?.assetType,
				id: item?.id,
				price: item?.amount,
				qty: item?.qty,
				oem: item?.oem,
				coreSpecifications: item?.coreSpecifications?.map((val) => ({
					...val?.coreSpecification,
					id: val?.id,
					value:
          val?.value === 'true'
          	? 'Yes'
          	: val?.value === 'false'
          		? 'No'
          		: val?.value,
				})),
			})));

		const groupedQuotes = quotes.reduce((acc, curr) => {
			const existingEntry = acc.find((entry) => entry.id === curr.id);
			if (existingEntry) {
				existingEntry.quotes.push(curr);
			} else {
				acc.push({
					vendor: curr.vendor,
					id: curr.id,
					assetType: curr.assetType,
					quotes: [curr],
				});
			}
			return acc;
		}, []);
		const finalData = [];
		groupedAns?.forEach((item) => {
			const matchingQuotes = groupedQuotes.filter(
				(group) => group.assetType === item.assetType
			);

			if (matchingQuotes.length > 0) {
				const existingItem = finalData.find(
					(data) => data.assetName === item.assetName
				);

				if (existingItem) {
					matchingQuotes.forEach((group) => {
						group.quotes.forEach((quote) => {
							existingItem.qoute.push({
								itemId: quote?.itemId,
								assetType: quote?.assetType,
								storeId: quote?.storeId,
								vendorId: quote?.vendorId,
								vendor: quote?.vendor,
								id: quote?.id,
								price: quote?.price,
								qty: quote?.qty,
								oem: quote?.oem,
								coreSpecifications: quote?.coreSpecifications,
							});
						});
					});
				} else {
					finalData.push({
						id: item?.assetType,
						assetName: item?.assetName,
						quantity: item?.quantity,
						qoute: matchingQuotes.flatMap((group) => group.quotes.map((quote) => ({
							itemId: quote?.itemId,
							storeId: quote?.storeId,
							vendorId: quote?.vendorId,
							vendor: quote?.vendor,
							id: quote?.id,
							price: quote?.price,
							qty: quote?.qty,
							oem: quote?.oem,
							assetType: quote?.assetType,
							coreSpecifications: quote?.coreSpecifications,
						}))),
					});
				}
			}
		});
		setTableHeader(headerData);
		setFinalTableData(finalData);
		setQouteGetData(selectedQuoteData());
	}, [vendorResponseQuote, assetType, selectedQuote]);

	const fetchVendorResponseQoute = async () => {
		const response = await quoteServices?.fetchVendorQuoteById(
			{
				req: {
					params: {
						id: Number(params?.id),
					},
				},
			}
		);
		if (response?.statusCode === 200) {
			setVendorResponseQuote(response?.data);
		}
	};

	useEffect(() => {
		const fetchAssetType = async () => {
			const response = await quoteServices?.requestQuoteAssetList();
			if (response?.statusCode === 200) {
				setAssetType(response?.data);
			}
		};
		fetchAssetType();
		fetchVendorResponseQoute();
	}, []);

	return (
		<>
			<CreateHeader
				history={history}
				handleSave={handleSave}
				qouteData={qouteGetData}
			/>
			<div className='w-[95%] mx-auto p-2 '>
				<table className='w-full'>
					<thead className='text-center h-16'>
						<tr className='bg-[#D1D1D1]'>
							<td className='w-[15rem]'>Asset Name</td>
							<td className='w-[15rem]'>RFQ Quantity</td>
							{tableHeader?.length > 0 && tableHeader?.map((item, index) => (
								<td key={index} className='w-[15rem]'>
									{item?.vendor}
									{item?.doc && (
										<div
											className='cursor-pointer'
											onClick={() => {
												window.open(item?.doc, '_blank');
											}}
										>
											<div className='flex gap-2 justify-center items-center text-blue-700'>
												<span>Vendor Reply Document</span>
												<PictureAsPdf />
											</div>
										</div>
									)}
								</td>
							))}
						</tr>
					</thead>
					<tbody className='text-center'>
						<VendorQouteListTable
							tableData={finalTableData}
							selectedQuote={selectedQuote}
							setSelectedQuote={setSelectedQuote}
							editableValues={editableValues}
							setEditableValues={setEditableValues}
						/>
					</tbody>
				</table>
			</div>
		</>
	);
}

export default VendorResponseQuote;
