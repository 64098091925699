/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { Handle } from 'react-flow-renderer';
import { FactCheck, Settings } from '@mui/icons-material';
import './CmsCustomNode.scss';
import moment from 'moment';
import '../Timeline/Timeline.scss';
import { useState, useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { modalActions } from '../../../Actions';
import blueTemp from '../../../Images/blue temp.gif';
import redTemp from '../../../Images/red temp.gif';
import weight from '../../../Images/weighing-machine.png';

function CmsCustomeNode({ data, ndata, poActionHandler, handleImageClick = () => {} }) {
	const [showAll, setShowAll] = useState(false);
	const itemsList = useMemo(() => {
		if (!data || !data?.item) return [];
		if (!showAll) {
			return data?.item?.slice(0, 2);
		}
		if (showAll) {
			return data?.item;
		}
	}, [data, showAll]);
	function handleShow() {
		setShowAll(!showAll);
	}
	const visible = showAll ? data?.item : data?.item?.slice(0, 1);
	function convertUnit(value) {
		switch (value) {
			case 'gram':
				return 'g';
			case 'count':
				return 'pcs';
			default:
				return value;
		}
	}
	function convertbatch(batchNo) {
		return moment(batchNo).format('YYYYMMDDHH');
	}
	function isDecimal(input) {
		// Check if the input string contains a decimal point.
		if (input.indexOf('.') !== -1) {
			return true;
		}
		return false;
	}
	function roundoff(value) {
		const [amount, unit] = value.split('\n');
		const newValue = parseFloat(amount);
		if (isDecimal(amount)) {
			switch (unit) {
				case 'g':
					return (`${newValue.toFixed(0)} g`);
				case 'kg':
					return (`${newValue.toFixed(3)} kg`);
				default:
					return value;
			}
		} else {
			return value;
		}
	}
	function convertRange(value) {
		const [amount, unit] = value.split('\n');
		const valueInGrams = parseFloat(amount);
		switch (unit) {
			case 'g':
				return (valueInGrams);
			case 'kg':
				return (valueInGrams * 1000);
			default:
				return (valueInGrams);
		}
	}
	function ShowInformation(infoEle) {
		const { name, value } = infoEle;
		return (
			<div className='info'>
				{name && (
					<div className='heading'>
						{name}
						:
						{' '}
					</div>
				)}
				<p>{value}</p>
			</div>
		);
	}
	function ShowInformationInChip(infoEle) {
		const { label, icon, value, isImg } = infoEle;
		let showIcon = '';
		if (isImg) {
			showIcon = (
				<img
					src={icon}
					alt='icon'
					style={{
						height: '35px',
					}}
				/>
			);
		} else {
			showIcon = icon;
		}
		return (
			<div className='chipInfo'>

				{label && (
					<div className='heading'>
						{label}
						:
						{' '}
					</div>
				)}
				{infoEle?.minRange ? (
					<div
						className='cms-customize-chip'
						style={{
							backgroundColor: '#F9FBF2',
						}}
					>
						<div className='cms-range'>
							<div>min</div>
							<div>{infoEle?.minRange}</div>
						</div>
						<div className='cms-customize-icon'>
							<img
								src={icon}
								alt='icon'
								style={{
									height: '220px',
								}}
							/>
						</div>
						<div
							className='cms-customize-label'
							style={{
								color: (convertRange(value) <= convertRange(infoEle?.maxRange) && convertRange(value) >= convertRange(infoEle?.minRange)) ? 'green' : 'red',
							}}
						>
							{roundoff(value)}
						</div>
						<div className='cms-customize-icon'>
							{(convertRange(value) <= convertRange(infoEle?.maxRange) && convertRange(value) >=
							convertRange(infoEle?.minRange))
								? (
									<CheckCircleIcon style={{
										color: 'green',
										fontSize: '50px',
									}}
									/>
								) : (
									<CancelIcon style={{
										color: 'red',
										fontSize: '50px',
									}}
									/>
								)}
						</div>
						<div className='cms-range'>
							<div>max</div>
							<div>{infoEle?.maxRange}</div>
						</div>
					</div>
				)
					: (
						<Chip
							className='chip'
							style={{
								backgroundColor: '#F9FBF2',
								color: infoEle?.color,
							}}
							icon={showIcon}
							label={value}
							variant='outlined'
						/>
					)}
			</div>
		);
	}
	function ShowInformationwithImage(infoEle) {
		const { label, value } = infoEle;
		return (
			<div className='imageInfo'>
				{label && (
					<div className='heading'>
						{label}
						:
						{' '}
					</div>
				)}
				<div onClick={() => {
					handleImageClick(value);
				}}
				>
					<img
						className='showbigimage'
						height='40'
						width='70'
						src={value}
						alt={label}
					/>
				</div>
			</div>
		);
	}
	function selectInformation(infoEle) {
		const { name, valuName, value } = infoEle;
		switch (name) {
			case 'Product Name':
				return ShowInformation({
					name: null,
					value,
				});
			case 'Sku Image':
				return ShowInformationwithImage({
					label: null,
					value,
				});
			case 'Machine Name':
				return ShowInformation({
					name: 'Scanned On',
					value,
				});
			case 'Weight':
				return ShowInformationInChip({
					label: name,
					icon: weight,
					value,
					isImg: true,
					minRange: infoEle?.minWeight,
					maxRange: infoEle?.maxWeight,
				});
			case 'Temperature':
				return ShowInformationInChip({
					label: 'Temp',
					icon: value <= 10 ? blueTemp : redTemp,
					value,
					minRange: infoEle?.minTemp,
					maxRange: infoEle?.maxTemp,
					isImg: true,
				});
			default:
				return ShowInformation({
					name,
					value,
				});
		}
	}
	return (
		<>
			{/* {console.log(`${ndata?.id}-top`)} */}
			<Handle
				type='target'
				position='top'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${ndata?.id}-top`}
			/>
			<Handle
				type='target'
				position='left'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${ndata?.id}-left`}
			/>
			<div
				style={{
					background: data?.color,
				}}
				className='custom-node'
			>
				{data?.quantityUsedData && (
					<div className='actions-container'>
						{data?.item && (
							<div
								className='action'
								onClick={() => {
									poActionHandler(data?.quantityUsedData);
								}}
							>
								<FactCheck className='icon' />
							</div>
						)}
					</div>
				)}
				<p className='Top-heading'>{data?.heading}</p>
				<div
					className='data-container'
				>
					{/* <div className='section'>
						{data?.productionPlanName && (
							<div>
								<p>Production Plan:</p>
								{' '}
								<p>{data?.productionPlanName}</p>
							</div>
						)}
					</div> */}
					{
						itemsList && (
							itemsList?.map((item) => {
								return (
									<div className='analytics'>
										<div className='analytic-allInfo-temp'>
											<div className='analytic-info-allInfo'>
												<div className='analytic-info-allInfo-dbInfo'>
													{item?.skuName && (selectInformation({
														name: 'Product Name',
														value: item?.skuName,
													}))}
													{item?.qty && (selectInformation({
														name: 'Qty',
														value: item?.qty + convertUnit(item?.unit),
													}))}
													{data?.heading !== 'Production Indent' && item?.containerWeight && (
														selectInformation({
															name: 'Container Weight',
															value: item?.containerWeight + 'g',
														})
													)}
													{data?.heading !== 'Production Indent' && item?.batchNo && (selectInformation({
														name: 'Batch No',
														value: convertbatch(item?.batchNo),
													}))}
													{data?.heading !== 'Production Indent' && item?.cogs && (selectInformation({
														name: 'COGS',
														value: 'Rs' + item?.cogs,
													}))}
													{data?.heading === 'Production Indent' && item?.vendorName && (selectInformation({
														name: 'Vendor',
														value: item?.vendorName,
													}))}
													{
														data?.heading === 'Production Indent' && item?.price && (selectInformation({
															name: 'Price',
															// eslint-disable-next-line prefer-template
															value: 'Rs' + item?.price,
														}))
													}
												</div>
												<div className='analytic-info-allInfo-machineInfo'>
													{item?.skuImage && (selectInformation({
														name: 'Sku Image',
														value: item?.skuImage,
													}))}
													{data?.heading !== 'Production Indent' && item?.machineName && (selectInformation({
														name: 'Machine Name',
														value: item?.machineName,
													}))}
												</div>
											</div>
											{data?.heading !== 'Production Indent' && (
												<div className='analytic-info-temp-weight'>
													<div className='analytic-chip'>
														{data?.heading !== 'Production Indent' && item?.weight && (selectInformation({
															name: 'Weight',
															value: item?.weight,
															minWeight: item?.minWeight,
															maxWeight: item?.maxWeight,
														}))}
													</div>
													<div className='analytic-chip'>
														{item?.temp && (selectInformation({
															name: 'Temperature',
															value: item?.temp + '\u00B0C',
															minTemp: item?.minTemp + '\u00B0C',
															maxTemp: item?.maxTemp + '\u00B0C',
														}))}
													</div>
												</div>

											)}
										</div>
										{data?.heading !== 'Production Indent' && (
											<div className='analytic-info-with-date'>
												<div
													className='batch-dateTime'
													style={{
														borderBottom: '2px solid black',
													}}
												>
													<p className='label'>Batch Started At:</p>
													<div className='dates'>
														<p className='value'>-------</p>
													</div>
												</div>
												<div
													className='batch-dateTime'
												>
													<p className='label'>Batch Completed At:</p>
													<div className='dates'>
														<p className='value'>-------</p>
													</div>
												</div>
											</div>
										)}
									</div>
								);
							})
						)
					}
				</div>
				{!showAll && data?.item && data.item.length > 2 && (
					<div className='show-more' onClick={handleShow}>
						Show More
					</div>
				)}
				{showAll && data?.item && data.item.length > 2 && (
					<div className='show-more' onClick={handleShow}>
						Show less
					</div>
				)}

			</div>
			<Handle
				type='source'
				position='bottom'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${ndata?.id}-bottom`}
			/>
			<Handle
				type='source'
				position='right'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${ndata?.id}-right`}
			/>
		</>
	);
}
export default CmsCustomeNode;
