import { authConstants } from '../Constants';

const initialState = {
	forceLogout: false,
	isLoading: false,
	staticEnums: {},
	userList: [],
};

function auth(state = initialState, action) {
	switch (action.type) {
		case authConstants.SET_LOGOUT:
			return {
				...state,
				forceLogout: action.payload,
			};
		case authConstants.SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case authConstants.SET_STATIC_ENUMS:
			return {
				...state,
				staticEnums: action.payload,
			};
		case authConstants.SET_USER_LIST:
			return {
				...state,
				userList: action.payload,
			};
		default:
			return state;
	}
}

export default auth;
