import {
	useEffect, useRef, useState
} from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import errorImage from '../../../Images/somethingWentWrong.png';
import { authServices, notificationServices } from '../../../Services';
import { getCookie } from '../../../Services/Storage Services';
import styles from './Errorfallback.module.scss';

const ErrorFallback = (props) => {
	const { error, resetErrorBoundary } = props;
	// eslint-disable-next-line no-console
	console.log('prroooppppsss', props);
	const currPathRef = useRef(null);
	const [secondsLeft, setSecondsLeft] = useState(10);
	const user = JSON.parse(getCookie('user'));
	const location = useLocation();
	const history = useHistory();

	const handleReport = async () => {
		const req = {
			data: {
				subject: 'Bug Reported: Something went wrong in Console2',
				text: `Hello there \n A bug has been reported by ${
					user?.userDetails?.name
				}, in Console2 (Page: ${window?.location?.origin ?? ''}${
					location?.pathname ?? ''
				}). \n ${
					error?.stack ?? error?.message ?? error
				} \n Thank you.`,
			},
		};
		try {
			const response = await authServices?.reportBug(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Email Sent Successfully',
				});
			}
		} catch (err) {
			notificationServices.generateNotification({
				type: 'error',
				message: err ?? err?.message ?? 'Fetched Successfully',
			});
		}
	};

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') handleReport();
	}, [error]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setSecondsLeft((prev) => {
				return prev - 1;
			});
		}, 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		currPathRef.current = history.location.pathname;
	}, []);

	useEffect(() => {
		if (secondsLeft === 0) {
			resetErrorBoundary();
			history.push('/console2/ops-dashboard');
			// if (userPermissions.INTERNAL) {
			// } else {
			// history.push('/console2/ops-dashboard');
			// }
			setSecondsLeft(10);
		}
	}, [secondsLeft]);
	useEffect(() => {
		if (
			currPathRef.current !== history.location.pathname &&
			secondsLeft > 0
		) {
			resetErrorBoundary();
		}
	}, [history?.location?.pathname]);

	return (
		<div className={styles.root}>
			<img
				src={errorImage}
				alt='errorBoundary'
				className={styles.image}
			/>
			<div className={styles['text-container']}>
				<p>Something Went Wrong!</p>
				<p>
					We have sent an Email to our technical team, we will be
					working on it soon.
				</p>
			</div>
			<div className={styles['redirect-timer']}>
				<p>You will be redirected to Homepage within</p>
				<span>{secondsLeft}</span>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

export default connect(mapStateToProps)(ErrorFallback);
