import moment from 'moment';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import analyticsAction from '../../../../Actions/analytics.actions';
// eslint-disable-next-line import/no-cycle
import { ProductImagesResult } from '../../../../Components/Analytics/Product';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import { DefectImageModal, RetagModal } from '../../../../Components/Modals';
// import { useQuery } from '../../../../Helpers';
import defectAnalyticsServices from '../../../../Services/Analytics Services/defect-analytics.services';
import productAnalyticsServices from '../../../../Services/Analytics Services/product-analytics.services';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './ProductImagesPage.module.scss';
import { productActions } from '../../../../Actions';
import StickyLegends from '../../../../Components/MySticky/StickyLegends/StickyLegends';
import { useAuth } from '../../../../Components/Auth/Login/auth';
import { notificationServices } from '../../../../Services';
import myMachineService from '../../../../Services/AssetServices/myMachine.service';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

export const legendWithColors = {
	egaPercentage: {
		color: '#9AC8CD',
		label: 'EGA %',
		suffix: '%',
	},
	minWeight: {
		color: '#8DECB4',
		label: 'Ideal Weight',
		hasUnit: true,
	},
	weight: {
		color: '#ECCA9C',
		label: 'Weight',
		hasUnit: true,
	},
	confidence: {
		color: '#E59BE9',
		label: 'Confidence',
	},
};

const FIND_FILTERS = [
	'store',
	'brand',
	'processTypes',
	// 'vendor',
	'product',
	'productCategory',
	'identificationType',
	'confidence',
	'egaPercentage',
	'timeType',
	'pageLimit',
	'dateRange',
	'customExclusiveFilters',
];

const ProductImagesPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		processTypes: null,
		brand: null,
		products: [],
		vendors: [],
		conf: 1,
		egaPercentage: undefined,
		productCategories: [],
		identification: 'Both',
		submissions: 0,
		timeType: '',
		pageLimit: 6,
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
		customExclusiveFilters: {},
	});
	const { clientType } = JSON.parse(localStorage.getItem('appConfig'));
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const isUS = measurement === 'US';
	const {
		allProductResults,
		fetchProductImages,
		updateProductImagesList,
		allDefectResults,
		fetchProducts,
		resetProductImages,
		userPermissions,
		updateDefectImagesList,
	} = props;
	// const query = useQuery();

	useEffect(() => {
		resetProductImages();
	}, []);

	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [defaultState, _] = useState({
		DATE_RANGE: {
			active: true,
			from: new Date(),
			to: new Date(),
		},
	});

	const [machinesList, setMachineList] = useState([]);

	const handleListFetch = async ({ store = null }) => {
		// setIsloading(true);
		try {
			const responseData =
					await myMachineService.fetchListOfMachines({
						req: {
							data: {
								storeId: store,
							},
						},
					});
			if (responseData?.statusCode === 200) {
				setMachineList(responseData?.data?.machinesList);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		handleListFetch({});
	}, []);

	const submitFindRequest = (incomingData) => {
		const {
			store = null,
			processTypes = null,
			brand,
			products = [],
			productCategories = [],
			identification,
			conf,
			egaPercentage = undefined,
			timeType,
			page: pageLimit,
			vendors = [],
			dateRange = {},
			customExclusiveFilters = {},
		} = incomingData;
		resetProductImages();
		setFilters({
			store,
			processTypes,
			brand,
			products,
			productCategories,
			vendors,
			egaPercentage,
			identification,
			conf: userPermissions.INTERNAL ? conf : 1,
			submissions: filters.submissions + 1,
			timeType,
			dateRange,
			pageLimit,
			customExclusiveFilters,
		});
		const data = {
			storeId: store,
			brandId: brand,
			processTypes,
			products,
			vendors,
			...(egaPercentage > -1 && {
				egaPercentage,
			}),
			productCategories,
			identification,
			qcType: 'PRODUCTION',
			conf: userPermissions.INTERNAL ? conf : 1,
			type: 'IDENTITY',
			machineIds: customExclusiveFilters?.machineType?.includes(-999)
				? null
				: customExclusiveFilters?.machineType,
			dateRangeFilter: dateRange?.active
				? {
					timeType,
					from: moment(dateRange.from).format('YYYY-MM-DD'),
					to: moment(dateRange.to).format('YYYY-MM-DD'),
				}
				: {},
		};
		fetchProductImages({
			entity:
				clientType === 'SCHOOL'
					? 'DEFECT_ANALYSIS'
					: 'AI_PRODUCTION_ANALYSIS',
			method:
				clientType === 'SCHOOL' ? 'FIND_DEFECT_IMAGES' : 'FIND_IMAGES',
			req: {
				params: {
					limit: pageLimit,
				},
				data,
			},
		});
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	const fetchProductImagesById = async (
		{
			page, productId, identification, typeRefresh = false, limit, machineIds,
		},
		index
	) => {
		if (clientType !== 'SCHOOL') {
			const response =
				await productAnalyticsServices.findProductImagesById({
					params: {
						page,
						limit: typeRefresh ? limit : filters?.pageLimit,
					},
					data: {
						storeId: filters.store,
						productId,
						processTypes: filters.processTypes,
						products: filters.products,
						brandId: filters.brand,
						conf: filters.conf,
						...(filters.egaPercentage > -1 && {
							egaPercentage: filters.egaPercentage,
						}),
						vendors: filters.vendors,
						productCategories: filters.productCategories,
						identification,
						qcType: 'PRODUCTION',
						type: 'IDENTITY',
						machineIds:
							filters?.customExclusiveFilters?.machineType,
						dateRangeFilter: filters.dateRange?.active
							? {
								timeType: filters?.timeType,
								from: moment(filters.dateRange.from).format(
									'YYYY-MM-DD'
								),
								to: moment(filters.dateRange.to).format(
									'YYYY-MM-DD'
								),
							}
							: {},
					},
				});

			const { data = [] } = response;
			updateProductImagesList(index, data, typeRefresh);
			if (typeRefresh) {
				setLoading(false);
			}
			return data;
		}
		const response = await defectAnalyticsServices.findDefectImagesById({
			params: {
				page,
				limit: typeRefresh ? limit : filters?.pageLimit,
			},
			data: {
				storeId: filters.store,
				labelId: productId,
				products: filters.products,
				brandId: filters.brand,
				vendors: filters.vendors,
				productCategories: filters.productCategories,
				identification,
				type: 'QUALITY',
				conf: filters.conf,
				machineIds,
				dateRangeFilter: filters.dateRange?.active
					? {
						timeType: filters?.timeType,
						from: moment(filters.dateRange.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filters.dateRange.to).format(
							'YYYY-MM-DD'
						),
					}
					: {},
			},
		});

		const { data = [] } = response;
		updateDefectImagesList(index, data, typeRefresh);
		if (typeRefresh) {
			setLoading(false);
		}
		return data;
	};

	useEffect(() => {
		fetchProducts({
			params: {
				id: '',
				skuType: 'PRODUCT',
				brandId: '',
			},
		});
	}, []);

	const totalValues = useMemo(() => {
		let totalShown = 0;
		let typeOfProducts = 0;
		let totalCount = 0;
		allProductResults?.forEach((product) => {
			if (product?.Annotation.length > 0) {
				totalShown += product?.Annotation?.length ?? 0;
				typeOfProducts++;
			}
			totalCount += product?.count ?? 0;
		});
		return {
			totalShown,
			totalCount,
			typeOfProducts,
		};
	}, [allProductResults]);

	const displayLegendsValues = (key, value) => {
		if (
			(filters?.egaPercentage > -1 && key !== 'confidence') ||
			(key === 'confidence' && userPermissions.INTERNAL)
		) {
			return {
				color: value?.color, label: value?.label,
			};
		}
		return {};
	};

	useEffect(() => {
		notificationServices.generateNotification({
			type: 'wave',
			message: 'Search with product for faster result',
		});
	}, []);

	const customExclusiveFilters = [
		{
			key: 'machineType',
			filterList: [{
				id: -999, title: 'ALL',
			}].concat(
				machinesList?.map((ele) => {
					return {
						// eslint-disable-next-line prefer-template
						id: ele.id,
						// title: ele.storeName,
						// eslint-disable-next-line prefer-template
						title: ele.machine_name + (ele?.storeName ? '(' + ele?.storeName + ')' : ''),
					};
				})
			),
			allowNoSelection: false,
			label: 'Select Machine Name',
			defaultState: [],
		},
	];

	return (
		<div className={styles.root}>
			<RetagModal
				handleSubmit={({ labelId, functionToExecute }) => {
					// validate(false, labelId);
					functionToExecute(labelId);
				}}
				skuType='PRODUCT'
			/>
			<DefectImageModal />
			<ImagesFinder
				FILTERS={FIND_FILTERS}
				isInternal={userPermissions.INTERNAL}
				onSubmit={submitFindRequest}
				defaultState={defaultState}
				setParentFilterState={(state) => {
					if (state?.store !== -999) {
						handleListFetch({
							store: state.store,
						});
					} else {
						handleListFetch({});
					}
				}}
				customExclusiveFilters={customExclusiveFilters}
			/>
			{allProductResults?.length !== 0 && (
				<div className={styles.numbers}>
					<div className={styles.element}>
						Total Count:
						<span>{totalValues?.totalCount}</span>
					</div>
					<div className={styles.element}>
						Type of Products:
						<span>{totalValues?.typeOfProducts}</span>
					</div>
				</div>
			)}
			<StickyLegends
				getValueFunction={displayLegendsValues}
				legendKeyValues={legendWithColors}
			/>
			<div className={styles['image-results']}>
				{clientType === 'SCHOOL' &&
					allDefectResults.map((product, index) => {
						return (
							product.Annotation.length > 0 && (
								<ProductImagesResult
									refreshLoading={loading}
									setRefreshLoading={setLoading}
									isInternal={userPermissions.INTERNAL}
									// eslint-disable-next-line react/no-array-index-key
									key={`${product.id}${filters.submissions}${index}`}
									identification={filters.identification}
									fetchQCData={fetchQCData}
									fetchProductImagesById={(req) => {
										return fetchProductImagesById(
											req,
											index
										);
									}}
									product={product}
								/>
							)
						);
					})}
				{clientType !== 'SCHOOL' &&
					allProductResults.map((product, index) => {
						return (
							product.Annotation.length > 0 && (
								<ProductImagesResult
									refreshLoading={loading}
									setRefreshLoading={setLoading}
									isInternal={userPermissions.INTERNAL}
									// eslint-disable-next-line react/no-array-index-key
									key={`${product.id}${filters.submissions}${index}`}
									identification={filters.identification}
									fetchQCData={fetchQCData}
									isUS={isUS}
									egaPercentageSelected={
										filters?.egaPercentage > -1
									}
									fetchProductImagesById={(req) => {
										return fetchProductImagesById(
											req,
											index
										);
									}}
									product={product}
									machineIds={
										filters?.customExclusiveFilters
											?.machineType
									}
								/>
							)
						);
					})}
				{(clientType !== 'SCHOOL'
					? allProductResults
					: allDefectResults
				).length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_IMAGES: allProductResults = {} } =
		state?.analytics?.AI_PRODUCTION_ANALYSIS;
	const { FIND_DEFECT_IMAGES: allDefectResults = {} } =
		state?.analytics?.DEFECT_ANALYSIS;
	const { userPermissions = {} } = state?.navigation;
	return {
		userPermissions,
		allProductResults,
		allDefectResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProductImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateProductImagesList: (...args) => {
			dispatch(analyticsAction.updateProductImagesList(...args));
		},
		updateDefectImagesList: (...args) => {
			dispatch(analyticsAction.updateDefectImagesList(...args));
		},
		resetProductImages: () => {
			dispatch(analyticsAction.resetProductImages());
		},
		fetchProducts: (...args) => {
			dispatch(productActions.fetchProducts(...args));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImagesPage);
