import styles from './CreatePage.module.scss';

const CreatePage = (props) => {
	const { createPage } = props;

	return (
		<div className={`${styles.root} page`}>
			{createPage}
		</div>
	);
};

export default CreatePage;
