import { endpoints } from '../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const reportsServices = {
	fetchReportsList,
	updateReportList,
};

function fetchReportsList(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.FETCH_REPORTS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function updateReportList(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.UPDATE_REPORT, requestOptions).then(handleResponse);
}

export default reportsServices;
