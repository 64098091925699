/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader } from '../../Helpers';
import handleResponse from '../helpers';

function fetchStatisticalAnalysisGraph({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.STATISTICAL_ANALYSIS, requestOptions).then(
		handleResponse
	);
}

export default fetchStatisticalAnalysisGraph;
