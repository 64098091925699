import {
	FormControl, InputLabel, MenuItem, Select
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../../Actions';

function DefectsListFilter(props) {
	const { fetchDefects } = props;
	const [typeFilter, setTypeFilter] = useState('ALL');

	const handleTypeFilterChange = (e) => {
		setTypeFilter(e.target.value);
	};
	useEffect(() => {
		fetchDefects({
			entity: 'DEFECTS',
			req: {
				params: {
					type: typeFilter === 'ALL' ? '' : typeFilter,
				},
			},
			method: 'LIST',
		});
	}, [typeFilter]);
	return (
		<div>
			<FormControl size='small'>
				<InputLabel id='type-filter'>Type</InputLabel>
				<Select
					labelId='type-filter'
					id='type-filter'
					value={typeFilter}
					label='Type'
					onChange={handleTypeFilterChange}
				>
					<MenuItem value='IDENTITY'>Identity</MenuItem>
					<MenuItem value='COMPONENT'>Component</MenuItem>
					<MenuItem value='QUALITY'>Quality</MenuItem>
					<MenuItem value='ALL'>All</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}

const mapStateToProps = () => {};

const mapDispatchToProps = {
	fetchDefects: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectsListFilter);
