/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import TagPicker from 'rsuite/TagPicker';
import { Radio, RadioGroup } from 'rsuite';
import { classes } from '../../../../Helpers';
import { Tag } from '../../../Tags';
import styles from './AuditStep4.module.scss';
import productProcessMapServices from '@/Services/Analytics Services/product-process-map.services';
import { notificationServices } from '@/Services';

const PRODUCT_TYPES = {
	ALL: 'ALL',
	QR: 'QR',
	NON_QR: 'NON_QR',
};

const AuditStep4 = (props) => {
	const {
		selectedCategories,
		selectedProducts,
		selectedSkus,
		skusList,
		productCategoriesList,
		deleteFilter,
		auditCreationState,
		toggleListModal,
		productsList,
		handleSetNewProductList,
		handleRemoveAllProducts,
	} = props;

	const [productType, setProductType] = useState(PRODUCT_TYPES.ALL);
	const [nonQrProductIdList, setNonQrProductIdList] = useState([]);
	const [originalSelectedProductList, _] = useState(selectedProducts);
	const PRODUCT_CATEGORIES = useMemo(() => {
		return productCategoriesList?.map((category) => {
			return {
				id: category.id,
				title: category?.productCategoryName ?? '',
			};
		});
	}, [productCategoriesList]);

	useEffect(() => {
		let finalList = [];
		if (productType === PRODUCT_TYPES.NON_QR) {
			finalList = originalSelectedProductList?.filter((id) => {
				return nonQrProductIdList?.includes(id);
			});
		} else if (productType === PRODUCT_TYPES.QR) {
			finalList = originalSelectedProductList?.filter((id) => {
				return !nonQrProductIdList?.includes(id);
			});
		} else if (productType === PRODUCT_TYPES.ALL) {
			finalList = originalSelectedProductList;
		}
		handleSetNewProductList(finalList);
	}, [productType, nonQrProductIdList, originalSelectedProductList]);

	useEffect(() => {
		(async () => {
			const req = {
				data: {
					store: {
						id: 1,
					},
					mappingTypes: [8],
					categoryId: null,
					products: null,
				},
			};
			try {
				const response =
					await productProcessMapServices?.fetchProductWiseMappingData(
						req
					);
				if (response?.responseCode === 'SS-001') {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.responseMessage,
					});
					setNonQrProductIdList(
						response?.data?.productwiseMappings
							?.filter((ele) => {
								return ele?.mappingTypeElementValue === '1';
							})
							?.map((ele) => {
								return ele?.productId;
							})
					);
				}
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error?.message,
				});
			}
		})();
	}, []);

	const PRODUCTS = useMemo(() => {
		return productsList
			?.map((product) => {
				return {
					id: product.id,
					title: product?.productName ?? '',
					skuType: product?.skuType,
					categoryId: product?.categoryId,
				};
			});
	}, [productsList, productType, nonQrProductIdList]);

	return (
		<div className={styles.root}>
			<div className={styles.labelled_input}>
				<label>SKU Type</label>
				<TagPicker
					size='lg'
					data={skusList?.map((ele) => {
						return {
							value: ele,
							label: ele,
						};
					})}
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-start',
					}}
					readOnly
					placeholder='Select SKU Type'
					value={selectedSkus}
					menuStyle={{
						zIndex: 9999,
						display: 'none',
					}}
					// disabled
				/>
			</div>
			<div className={styles?.filters}>
				<div className={classes(styles.filter)}>
					<p className={styles['filter-heading']}>Product category</p>
					<div className={styles['filter-body']}>
						<div className={styles.tags}>
							{selectedCategories?.map((id) => {
								const selectedCategory =
									PRODUCT_CATEGORIES?.find((v) => {
										return id === v?.id;
									});
								return (
									<Tag
										key={selectedCategory?.id}
										width='100%'
										text={selectedCategory?.title}
										dataAttrs={{
											'data-id': selectedCategory?.id,
											'data-entity': 'CATEGORY',
										}}
										isDisabled
									/>
								);
							})}
							{selectedCategories?.length === 0 && (
								<span>No Categories selected</span>
							)}
						</div>
					</div>
				</div>
				<div className={classes(styles.filter, styles.product)}>
					<p className={styles['filter-heading']}>
						<div>Select Product(s)</div>
						<RadioGroup
							value={productType}
							onChange={(value) => {
								setProductType(value);
							}}
							appearance='picker'
							name='radioList'
							inline
						>
							{Object.values(PRODUCT_TYPES).map((ele) => {
								return (
									<Radio
										className='text-[10px] '
										color='black'
										value={ele}
									>
										{ele}
									</Radio>
								);
							})}
						</RadioGroup>
						<div
							onClick={handleRemoveAllProducts}
							className={styles.remove_all}
						>
							<p>Remove All Products</p>
						</div>
					</p>
					<div className={styles['filter-body']}>
						<Button
							variant='outlined'
							startIcon={<Add />}
							className={styles['add-button']}
							// disabled={brand == null}
							fullWidth
							onClick={() => {
								toggleListModal(true, {
									entity: 'PRODUCT',
									callee: 'auditStep4',
									heading: 'Select Product(s)',
									list: PRODUCTS?.filter((ele) => {
										if (
											!auditCreationState
												?.selectedCategories?.length &&
											!auditCreationState?.selectedSkus
												?.length
										) {
											return true;
										}
										if (
											!auditCreationState
												?.selectedCategories?.length
										) {
											return auditCreationState?.selectedSkus?.includes(
												ele?.skuType
											);
										}
										if (
											!auditCreationState?.selectedSkus
												?.length
										) {
											return auditCreationState?.selectedCategories?.includes(
												ele?.categoryId
											);
										}
										return (
											auditCreationState?.selectedCategories?.includes(
												ele?.categoryId
											) &&
											auditCreationState?.selectedSkus?.includes(
												ele?.skuType
											)
										);
									})?.filter((ele) => {
										if (productType === PRODUCT_TYPES.ALL) {
											return true;
										}
										if (productType === PRODUCT_TYPES.NON_QR) {
											return nonQrProductIdList?.includes(
												ele?.id
											);
										}
										if (productType === PRODUCT_TYPES.QR) {
											return !nonQrProductIdList?.includes(
												ele?.id
											);
										}
										return true;
									}),
									selected: selectedProducts,
								});
							}}
						>
							Product
						</Button>
						<div className={styles.tags}>
							{selectedProducts?.map((id) => {
								const product = PRODUCTS.find((d) => {
									return id === d.id;
								});
								return (
									<Tag
										key={id}
										width='100%'
										text={product?.title}
										handleDelete={deleteFilter}
										dataAttrs={{
											'data-id': id,
											'data-entity': 'PRODUCT',
										}}
									/>
								);
							})}
							{selectedProducts?.length === 0 && (
								<span>No products selected</span>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuditStep4;
