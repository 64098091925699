import _ from 'lodash';
import { PRODUCT_UNITS } from '../../../Constants/product.constants';
// eslint-disable-next-line object-curly-newline
const ProductsConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			brand: null,
			categoryImage: '',
			unit: PRODUCT_UNITS?.map((productUnit) => {
				return {
					...productUnit,
					isApplied: false,
				};
			}),
		},
		errors: {
			name: '',
			description: '',
			brand: '',
			categoryImage: '',
			unit: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: true,
		brand: true,
		categoryImage: false,
		unit: true,
	},
	serializer: (state, data) => {
		const disabledUnits = data?.measurement === 'US' ? ['gram', 'litre', 'ml'] : ['oz', 'floz'];
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.productCategoryName,
				description: data.productCategoryDescription,
				brand: data.brandId,
				categoryImage: data.productCategoryImage,
				unit: PRODUCT_UNITS?.map((productUnit) => {
					return {
						...productUnit,
						isApplied: data?.unit === productUnit?.id,
					};
				})?.filter((ele) => {
					return !disabledUnits?.includes(ele?.id);
				}),
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = {
			productCategoryName: state.fields.name,
			productCategoryDescription: state.fields.description,
			brandId: state.fields.brand,
			productCategoryImage: state.fields.categoryImage,
			unit: state?.fields?.unit?.reduce(
				(finalUnit, currUnit) => {
					if (currUnit?.isApplied) {
						return currUnit?.id;
					}
					return finalUnit;
				},
				''
			),
		};
		return newState;
	},
};
export default ProductsConfig;
