import { Tooltip } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useState } from 'react';
import {
	generateDisabledProps,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper
} from '../../../Helpers';
import styles from './MenuCategory.module.scss';
import TimeSlots from './TimeComponent/TimeSlots';
import EnableDisable from '../EnableDisable/EnableDisable';
import MenuSubCategory from './MenuSubCategory';
import { ImageCropModal } from '../../Modals/ImageCropModal';

const MenuCategory = (props) => {
	const {
		categoryDetails,
		activeCategory,
		handleDelete,
		mandatoryFields,
		isDisabled,
		openTimeSlots,
		fields,
		activeSubCategory,
		openProducts,
		setActiveSubCategory,
		handleSubCategoryReOrdering,
		addNewSubCategory,
		handleSliderChange,
		handleSwitchChange,
		handleReorderItems,
		checkBoxShow,
		handleQtyChange,
		toggleImageCrop,
	} = props;

	const [showLogoCropModal, setShowLogoCropModal] = useState(false);

	const handleInput = (e) => {
		props.handleInput(e);
	};

	const openImageCropModal = (name, file = null) => {
		// console.log('TESTTTT', name, file);
		toggleImageCrop(false, {
			name,
			file,
		});
		setShowLogoCropModal(true);
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		// console.log('FILEEE', file);
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleLogoInput = (fieldName, blob) => {
		// console.log('TESTTT', fieldName, blob);
		setShowLogoCropModal(false);
		props.handleLogoInput(fieldName, blob, activeCategory);
	};

	return (
		<div className={`${styles.root}`}>
			<ImageCropModal
				localShow={showLogoCropModal}
				handleConfirm={handleLogoInput}
				handleClose={() => {
					setShowLogoCropModal(false);
				}}
			/>
			<fieldset disabled={isDisabled} className='form-fieldset'>
				{/* delete category button */}
				{!isDisabled && (
					<Tooltip title='Delete Category' placement='left-end'>
						<Delete
							className={`${styles['delete-icon']}`}
							onClick={handleDelete}
							data-type='category'
							data-id={activeCategory}
						/>
					</Tooltip>
				)}
				{/* category name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='categoryName'
						data-mandatory={mandatoryFields.categories[0].name}
					>
						Category Name
					</label>
					<input
						className='form-value'
						type='text'
						id='categoryName'
						name='name'
						onInput={handleInput}
						value={categoryDetails.name}
						data-type='category'
						data-id={activeCategory}
						{...generateRequiredProps(
							mandatoryFields.categories[0].name
						)}
					/>
				</div>
				{/* category description */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='categoryDescription'
						data-mandatory={
							mandatoryFields.categories[0].description
						}
					>
						Category Description
					</label>
					<textarea
						className='form-value'
						id='categoryDescription'
						name='description'
						onInput={handleInput}
						value={categoryDetails.description}
						data-type='category'
						data-id={activeCategory}
						{...generateRequiredProps(
							mandatoryFields.categories[0].description
						)}
					/>
				</div>
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.categoryLogo}
					>
						Category Logo
					</label>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={
									isDisabled
										? categoryDetails.categoryLogo
										: generateImageUrl(categoryDetails.categoryLogo)
								}
								className={`display-pic ${
									categoryDetails.categoryLogo ? 'show' : 'hide'
								}`}
								alt='category-display'
							/>
							{/* {generateImageEditButton({
								isEdit:
									fields.categoryLogo &&
									fields.categoryLogo.includes(
										'data:image/jpeg;base64'
									),
								button: (
									<button
										type='button'
										onClick={() => {
											openImageCropModal('logo');
										}}
										className='display-pic-edit'
									>
										<Crop />
									</button>
								),
							})} */}
						</div>
						{generateImageUploadButton({
							isDisabled,
							label: (
								<label
									className='display-pic-label'
									htmlFor='displayPic'
								>
									Choose Image
								</label>
							),
						})}
						<input
							type='file'
							id='displayPic'
							accept='image/png, image/jpg, image/jpeg, image/webp'
							name='categoryLogo'
							onChange={handleImageSelect}
							style={{
								opacity: 0,
								width: 1,
								height: 1,
							}}
							data-type='category'
							data-id={activeCategory}
							{...generateRequiredProps(
								mandatoryFields.categories[0].categoryLogo
							)}
						/>
					</div>
				</div>
				<div className={`${styles.enabledisable}`}>
					<EnableDisable
						name='isEnabled'
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'category',
							'data-id': activeCategory,
						}}
						isEnabled={
							fields.categories.items[activeCategory].timeSlots
								.isEnabled
						}
						text='Add Time Scheduling to this category?'
						className={`${styles['enable-disable']}`}
						{...generateDisabledProps(isDisabled)}
						checkBoxShow={checkBoxShow}
					>
						<div className={`${styles.timeWrapper}`}>
							<TimeSlots
								slots={
									fields.categories.items[activeCategory]
										.timeSlots.slots
								}
								handleAddNewSlot={openTimeSlots}
								dataAttrs={{
									'data-type': 'category',
									'data-id': activeCategory,
									'data-name': 'time-slot',
								}}
								handleDelete={handleDelete}
								className={`${styles['time-wrapper']}`}
								isDisabled={isDisabled}
							/>
						</div>
					</EnableDisable>
				</div>
			</fieldset>
			<div className={`${styles.subCategory}`}>
				<MenuSubCategory
					subCategoryDetails={
						fields.categories.items[activeCategory].subCategories
							.items[activeSubCategory]
					}
					handleDelete={handleDelete}
					handleInput={handleInput}
					handleSubCategoryReOrdering={handleSubCategoryReOrdering}
					setActiveSubCategory={setActiveSubCategory}
					addNewSubCategory={addNewSubCategory}
					fields={fields}
					mandatoryFields={mandatoryFields}
					activeCategory={activeCategory}
					activeSubCategory={activeSubCategory}
					openProducts={openProducts}
					selectedProducts={
						fields.categories.items[activeCategory].subCategories
							.items[activeSubCategory].selectedProducts
					}
					openTimeSlots={openTimeSlots}
					handleSliderChange={handleSliderChange}
					handleQtyChange={handleQtyChange}
					isDisabled={isDisabled}
					handleSwitchChange={handleSwitchChange}
					handleReorderItems={handleReorderItems}
					checkBoxShow={checkBoxShow}
				/>
			</div>
		</div>
	);
};
MenuCategory.defaultProps = {
	isDisabled: false,
};
export default MenuCategory;
