import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { ComboListPage, ComboCreatePage, ComboViewPage } from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const ComboRoutes = (props) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.COMBOS}`,
			name: 'Combos',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='COMBOS'
			path={path}
			listPage={ComboListPage}
			viewPage={<ComboViewPage breadcrumbs={breadcrumbs} />}
			createPage={<ComboCreatePage breadcrumbs={breadcrumbs} />}
			editPage={<ComboCreatePage isEdit breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default ComboRoutes;
