import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { brandActions } from '../../../../Actions';
import analyticsAction from '../../../../Actions/analytics.actions';
import { BarChart } from '../../../../Components/Analytics';
import Breadcrumb from '../../../../Components/Breadcrumb/Breadcrumb';
import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import { urls } from '../../../../Constants';
import { isEmpty } from '../../../../Helpers';
// import { history } from '../../../../Helpers';
import { CHART_COLORS } from '../../../../Styles/colors';
import styles from './BrandTrends.module.scss';

const BrandTrends = (props) => {
	const {
		brands,
		fetchBrands,
		dateRangeFilter,
		dateRangeFilterArray,
		setDate,
		fetchData,
		topProducts,
		topDefects,
		// brands,
	} = props;

	const { brand: brandId } = useParams();

	useEffect(() => {
		if (dateRangeFilter.from != null && dateRangeFilter.to != null) {
			fetchData({
				batch: [
					{
						entity: 'BRAND_ANALYSIS',
						method: 'TOP_DEFECTS',
						req: {
							params: {
								limit: 10,
								brandId,
							},
							data: {
								filters: {
									dateRangeFilter: {
										from: moment(
											dateRangeFilter.from
										).format('YYYY-MM-DD'),
										to: moment(dateRangeFilter.to).format(
											'YYYY-MM-DD'
										),
									},
								},
							},
						},
					},
					{
						entity: 'BRAND_ANALYSIS',
						method: 'TOP_PRODUCTS',
						req: {
							params: {
								limit: 10,
								brandId,
							},
							data: {
								filters: {
									dateRangeFilter: {
										from: moment(
											dateRangeFilter.from
										).format('YYYY-MM-DD'),
										to: moment(dateRangeFilter.to).format(
											'YYYY-MM-DD'
										),
									},
								},
							},
						},
					},
				],
			});
		}
	}, [dateRangeFilter]);

	useEffect(() => {
		if (isEmpty(brands)) {
			fetchBrands({
				id: '',
			});
		}
	}, []);

	const topProductsChartData = useMemo(() => {
		const labels = [];
		const data = [];

		topProducts.forEach((product) => {
			labels.push(product?.name ?? '');
			data.push(product?.count ?? '');
		});

		const datasets = [
			{
				data,
				label: 'Orders Count',
			},
		];
		return {
			labels,
			datasets: datasets.map((dataset, idx) => {
				return {
					label: dataset.label,
					data: dataset.data,
					...CHART_COLORS[idx + 1],
					maxBarThickness: 60,
				};
			}),
		};
	}, [topProducts]);

	const topDefectsChartData = useMemo(() => {
		const labels = [];
		const data = [];

		topDefects.forEach((defect) => {
			labels.push(defect?.name ?? '');
			data.push(defect?.count ?? '');
		});

		const datasets = [
			{
				data,
				label: 'Defects Count',
			},
		];
		return {
			labels,
			datasets: datasets.map((dataset, idx) => {
				return {
					label: dataset.label,
					data: dataset.data,
					...CHART_COLORS[idx],
					maxBarThickness: 60,
				};
			}),
		};
	}, [topDefects]);

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<Breadcrumb
					breadcrumbs={[
						{
							link: `${urls.EXTENSION}${urls.BRAND_TRENDS}`,
							name: 'Brand Trends',
						},
						{
							name: brands?.[brandId]?.name ?? 'Brand',
						},
					]}
				/>
				<Calender value={dateRangeFilterArray} setValue={setDate} />
			</div>
			<div className={styles.body}>
				<div className={styles['chart-container']}>
					<BarChart
						className={styles.chart}
						indexAxis='y'
						title='Top Selling Products'
						data={topProductsChartData}
					/>
				</div>
				<div className={styles['chart-container']}>
					<BarChart
						className={styles.chart}
						indexAxis='y'
						title='Most Occurring Defects'
						data={topDefectsChartData}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { TOP_PRODUCTS: topProducts, TOP_DEFECTS: topDefects } =
		state.analytics.BRAND_ANALYSIS;
	return {
		brands,
		topProducts,
		topDefects,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	fetchData: analyticsAction.gateway,
	fetchBrands: brandActions.fetchBrands,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandTrends);
