import { combineReducers } from 'redux';
import navigation from './navigation.reducers';
import modal from './modal.reducers';
import product from './product.reducers';
import brand from './brand.reducers';
import form from './form.reducers';
import paymentSettlement from './paymentSettlement.reducer';
import stateCity from './stateCity.reducer';
import analytics from './analytics.reducers';
import geoData from './getGeoData.reducers';
import auth from './auth.reducers';
import annotation from './annotation.reducers';
import retag from './retag.reducers';
import assets from './asset.reducer';
import reportsReducers from './reports.reducers';
import similarProducts from './similarProducts.reducers';
import drawer from './drawer.reducers';

const rootReducer = combineReducers({
	auth,
	navigation,
	modal,
	product,
	brand,
	form,
	paymentSettlement,
	stateCity,
	analytics,
	geoData,
	annotation,
	retag,
	assets,
	similarProducts,
	reports: reportsReducers,
	drawer,
});

export default rootReducer;
