import assetConstants from '../Constants/asset.constants';
import assetServices from '../Services/AssetServices/asset.services';
import { notificationServices } from '../Services/Notification';

function fetchAssetList(req = {}) {
	return (dispatch) => {
		dispatch(request());
		assetServices.listAssetsService(req).then(
			(res) => {
				const { data } = res;
				dispatch(success(data));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: assetConstants.FETCH_ASSET_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: assetConstants.FETCH_ASSET_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: assetConstants.FETCH_ASSET_FAILURE,
			payload,
		};
	}
}
function fetchAssetDashboardData(req = {}) {
	return (dispatch) => {
		dispatch(request());
		assetServices.assetDashboardDataService(req).then(
			(res) => {
				const { data } = res;
				dispatch(success(data));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: assetConstants.ASSET_DASHBOARD_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: assetConstants.ASSET_DASHBOARD_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: assetConstants.ASSET_DASHBOARD_FAILURE,
			payload,
		};
	}
}
function fetchAssetDashboardChartsData(req = {}) {
	return (dispatch) => {
		dispatch(request());
		assetServices.assetChartsDashboardService(req).then(
			(res) => {
				const { data } = res;
				dispatch(success(data));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: assetConstants.ASSET_CHARTS_DASHBOARD_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: assetConstants.ASSET_CHARTS_DASHBOARD_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		notificationServices.generateNotification({
			type: 'error',
			message: JSON.stringify(payload),
		});
		return {
			type: assetConstants.ASSET_CHARTS_DASHBOARD_FAILURE,
			payload,
		};
	}
}
function fetchAssetListVarient(req = {}) {
	return (dispatch) => {
		dispatch(request());
		assetServices.assetListVarientService(req).then(
			(res) => {
				const { data } = res;
				dispatch(success(data));
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: assetConstants.LIST_VAIRENT_ASSET_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: assetConstants.LIST_VAIRENT_ASSET_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: assetConstants.LIST_VAIRENT_ASSET_FAILURE,
			payload,
		};
	}
}

function fetchAssetMonitoringHistory(req = {}) {
	return (dispatch) => {
		dispatch(request());
		assetServices.assetFridgeMonitoringService(req).then(
			(res) => {
				const { data } = res;
				notificationServices.generateNotification({
					type: 'success',
					message: res?.message,
				});
				dispatch(
					success(
						data?.map((ele) => {
							return {
								...ele,
								createdAt: ele?.createdAt?.split('GMT')[0],
							};
						})
					)
				);
			},
			(error) => {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: assetConstants.ASSET_FRIDGE_MONITORING_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: assetConstants.ASSET_FRIDGE_MONITORING_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: assetConstants.ASSET_FRIDGE_MONITORING_FAILURE,
			payload,
		};
	}
}
function resetAssetMonitoringHistory() {
	return (dispatch) => {
		dispatch(reset([]));
	};

	function reset(payload) {
		return {
			type: assetConstants.ASSET_FRIDGE_MONITORING_RESET,
			payload,
		};
	}
}

function resetStoreAssetListing() {
	return (dispatch) => {
		dispatch({
			type: assetConstants.STORE_ASSET_LISTING_RESET,
			payload: [],
		});
	};
}

function fetchStoreAssetListing(req = {}, store) {
	return (dispatch) => {
		dispatch(request());
		assetServices.storeAssetListingService(req).then(
			(res) => {
				const { data } = res;
				// notificationServices.generateNotification({
				// type: 'success',
				// message: res?.message,
				// });
				dispatch(
					success({
						assets: data,
						store,
					})
				);
			},
			(error) => {
				dispatch(failure(error));
				notificationServices.generateNotification({
					type: 'success',
					message: error,
				});
			}
		);
	};

	function request(payload) {
		return {
			type: assetConstants.STORE_ASSET_LISTING_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: assetConstants.STORE_ASSET_LISTING_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: assetConstants.STORE_ASSET_LISTING_FAILURE,
			payload,
		};
	}
}

export default {
	resetAssetMonitoringHistory,
	fetchAssetList,
	fetchAssetDashboardData,
	fetchAssetListVarient,
	fetchAssetDashboardChartsData,
	fetchAssetMonitoringHistory,
	fetchStoreAssetListing,
	resetStoreAssetListing,
};
