import { cloneDeep } from 'lodash';
import { notificationServices } from '../../../Services';

const Headers = ['Category', 'Product', 'Variant'];

const initialState = {
	vendor: null,
	products: [],
	paymentTerm: '',
	paymentMode: null,
	endDate: null,
	vendorAuth: null,
	companyAuth: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				[action.name]: action.payload,
			};
		case 'SET_VENDOR':
			return {
				...state,
				vendor: action.payload,
			};
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.payload,
			};
		case 'SET_PAYMENT_TERM':
			return {
				...state,
				paymentTerm: action.payload,
			};
		case 'SET_END_DATE':
			return {
				...state,
				endDate: action.payload,
			};
		case 'SET_VENDOR_AUTH':
			return {
				...state,
				vendorAuth: action.payload,
			};
		case 'SET_COMPANY_AUTH':
			return {
				...state,
				companyAuth: action.payload,
			};
		case 'SET_EDIT_DATA':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = {
	products: true,
	paymentTerm: true,
	paymentMode: true,
	period: true,
	vendor: true,
};

const validator = (data) => {
	if (!data?.name || data?.name?.trim().length === 0) {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Please input the service name',
		});
		return false;
	}
	return true;
};

const deserializer = (state) => {
	const clonedState = cloneDeep(state);
	const req = {
		name: clonedState?.name,
	};

	return req;
};

const config = {
	Headers,
	initialState,
	reducer,
	mandatoryFields,
	validator,
	deserializer,
};

export default config;
