/* eslint-disable object-curly-newline */
import Table from 'rsuite/Table';
import { useEffect, useRef, useState } from 'react';
import { Payment, Visibility } from '@mui/icons-material';
import { classes, formatNumber } from '../../../Helpers';
import styles from './SummaryTable.module.scss';

const { Column, HeaderCell, Cell } = Table;

const SummaryTable = (props) => {
	const { element, vendorType, handlePaymentClick, filterType } = props;
	const [loading, setLoading] = useState(false);
	const [sortColumn, setSortColumn] = useState();
	const [sortType, setSortType] = useState();
	const tableRef = useRef(null);

	const handleSortColumn = (rSortColumn, rSortType) => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			setSortColumn(rSortColumn);
			setSortType(rSortType);
		}, 500);
	};

	const getData = (data) => {
		if (sortColumn && sortType) {
			return data?.sort((a, b) => {
				let x = a[sortColumn];
				let y = b[sortColumn];
				if (typeof x === 'string') {
					x = x.charCodeAt();
				}
				if (typeof y === 'string') {
					y = y.charCodeAt();
				}
				if (sortType === 'asc') {
					return x - y;
				}
				return y - x;
			});
		}
		return data?.filter((ele) => {
			return ele?.totalBills > 0;
		});
	};

	const renderColumns = () => {
		const columns = [
			{
				dataKey: 'name',
				headerClass: styles.name,
				header: filterType === 'PO_WISE' ? 'PO ID' : 'Vendor Name',
				fullText: true,
				children: (rowData, onClick) => {
					const pendingBillsCount =
						(rowData?.unapprovedGrnBillCount ?? 0) +
						(rowData?.unapprovedAdvanceBillCount ?? 0);
					return (
						<div className={styles.vendorDetails}>
							<div>{rowData?.name}</div>
							<div className={styles.heading}>
								Approval pending finance
							</div>
							<div
								style={{ background: '#ffaBa8' }}
								className={styles.item}
								onClick={() => {
									if (pendingBillsCount > 0) {
										onClick(
											'APPROVAL_PENDING_FINANCE',
											'bills'
										);
									}
								}}
							>
								Bills
								<span>{pendingBillsCount}</span>
							</div>
							{filterType === 'VENDOR_WISE' && (
								<div
									style={{ background: '#ffffa8' }}
									className={styles.item}
									onClick={() => {
										if (
											rowData?.unapprovedDebitNoteCount >
											0
										) {
											onClick(
												'APPROVAL_PENDING_FINANCE',
												'credits'
											);
										}
									}}
								>
									Debits
									<span>
										{rowData?.unapprovedDebitNoteCount ?? 0}
									</span>
								</div>
							)}
						</div>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#f1f1f1',
				fixed: true,
				sortable: true,
			},
			{
				dataKey: 'totalBills',
				headerClass: styles.total,
				header: 'Total Bills',
				fullText: false,
				children: (rowData, onClick) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber('IN', rowData?.totalBills)}
							</p>
							{rowData?.totalBills > 0 && (
								<button
									className={styles.button}
									onClick={onClick}
									type='button'
								>
									<Visibility fontSize='12px' />
									<p>View Bills</p>
								</button>
							)}
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#ffffff',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'alreadyPaidAdvance',
				headerClass: styles.already,
				header: 'Advances Already Paid (Payments)',
				fullText: false,
				children: (rowData, onClick) => {
					const calculatedData =
						(rowData?.dateRangeData?.totalAdvanceBillAmount ?? 0) -
						(rowData?.dateRangeData?.advanceBillDues ?? 0);
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber('IN', calculatedData)}
							</p>
							{calculatedData > 0 && (
								<button
									className={styles.button}
									onClick={onClick}
									type='button'
								>
									<Visibility fontSize='12px' />
									<p>View Bills</p>
								</button>
							)}
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#A1EB98',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'alreadyPaidGrn',
				headerClass: styles.already,
				header: 'Invoices Already Paid (Payments)',
				fullText: false,
				children: (rowData, onClick) => {
					const calculatedData =
						rowData?.dateRangeData?.totalGrnBillAmount -
						rowData?.dateRangeData?.grnBillDues;
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber('IN', calculatedData)}
							</p>
							{calculatedData > 0 && (
								<button
									className={styles.button}
									onClick={onClick}
									type='button'
								>
									<Visibility fontSize='12px' />
									<p>View Bills</p>
								</button>
							)}
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#A1EB98',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'debitNoteAmount',
				headerClass: styles.debit,
				header: 'Debit Note',
				fullText: false,
				children: (rowData, onClick) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber('IN', rowData?.debitNoteAmount)}
							</p>
							{rowData?.debitNoteAmount > 0 && (
								<button
									className={classes(styles.button)}
									onClick={onClick}
									type='button'
								>
									<Visibility fontSize='12px' />
									<p>View Debits</p>
								</button>
							)}
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#71F065',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'advanceBillDues',
				headerClass: styles.advances,
				header: 'Advances Due (Bills)',
				fullText: false,
				children: (rowData, onClick) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber('IN', rowData?.advanceBillDues)}
							</p>
							{rowData?.advanceBillDues > 0 && (
								<button
									className={classes(
										styles.button,
										styles.payment
									)}
									onClick={onClick}
									type='button'
								>
									<Payment fontSize='12px' />
									<p>Pay Bills</p>
								</button>
							)}
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#E97685',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'grnBillDues',
				headerClass: styles.invoices,
				header: 'Invoices Due (Bills)',
				fullText: false,
				children: (rowData, onClick) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber('IN', rowData?.grnBillDues)}
							</p>
							{rowData?.grnBillDues > 0 && (
								<button
									className={classes(
										styles.button,
										styles.payment
									)}
									onClick={onClick}
									type='button'
								>
									<Payment fontSize='12px' />
									<p>Pay Bills</p>
								</button>
							)}
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#F06577',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'extraAmount',
				headerClass: styles.payment,
				header: 'Non adjusted ',
				fullText: false,
				children: (rowData) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber(
									'IN',
									(rowData?.dateRangeData
										?.advanceBillUnused ?? 0) +
										(rowData?.dateRangeData
											?.debitNoteAmount ?? 0)
								)}
							</p>
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#f1f1f1',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'extraAmount2',
				headerClass: styles.payment,
				header: 'Transferred till now',
				fullText: false,
				children: (rowData) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber(
									'IN',
									rowData?.transferredTillNow
								)}
							</p>
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#f1f1f1',
				fixed: false,
				sortable: true,
			},
			{
				dataKey: 'netAmountRequired',
				headerClass: styles.payment,
				header: 'Net Amount Pending Transfer',
				fullText: false,
				children: (rowData) => {
					return (
						<>
							<p className={styles.value}>
								&#x20B9;
								{formatNumber(
									'IN',
									rowData?.netAmountRequired ?? 0
								)}
							</p>
						</>
					);
				},
				colWidth: 175,
				cellClass: classes(styles.field),
				bg: '#f1f1f1',
				fixed: false,
				sortable: true,
			},
		];
		return columns
			?.filter((ele) => {
				if (filterType === 'PO_WISE') {
					return ![
						'debitNoteAmount',
						'netAmountRequired',
						'extraAmount',
						'extraAmount2',
					]?.includes(ele?.dataKey);
				}
				return true;
			})
			?.map((ele) => {
				const columnHeader = ele?.header;
				return (
					<Column
						width={ele?.colWidth}
						fixed={ele?.fixed}
						sortable={ele?.sortable}
						fullText={ele?.fullText}
					>
						<HeaderCell
							style={{
								background: ele?.bg,
							}}
							className={ele?.headerClass}
						>
							{ele?.header}
						</HeaderCell>
						<Cell
							style={{
								// eslint-disable-next-line prefer-template
								background: ele?.bg + '95',
							}}
							dataKey={ele?.dataKey}
							className={ele?.cellClass}
						>
							{(rowData) => {
								return ele?.children(
									rowData,
									(filterStatus, type) => {
										handlePaymentClick(
											rowData,
											ele?.dataKey,
											filterStatus,
											type,
											columnHeader,
											vendorType
										);
									}
								);
							}}
						</Cell>
					</Column>
				);
			});
	};

	useEffect(() => {
		tableRef.current.scrollLeft(Math.random() * 100 + 100);
		return () => {};
	}, [element]);

	return (
		<div className={styles.root}>
			<div className={styles.vendor_name_container}>{vendorType}</div>
			<Table
				ref={tableRef}
				width='100%'
				height={400}
				data={getData(element)}
				sortColumn={sortColumn}
				sortType={sortType}
				onSortColumn={handleSortColumn}
				loading={loading}
				rowHeight={110}
				cellBordered
				headerHeight={50}
			>
				{renderColumns()}
			</Table>
		</div>
	);
};

export default SummaryTable;
