/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

function listAssetsService({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};
	return fetch(endpoints.ASSETS_LIST, requestOptions).then(handleResponse);
}

function listAssetDelete({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};
	return fetch(endpoints.ASSETS_REMOVE, requestOptions).then(handleResponse);
}
function saveCapacities(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};
	return fetch(endpoints.CREATE_ASSET_CAPACITY, requestOptions).then(
		handleResponse
	);
}
function fetchCapacities(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};
	return fetch(endpoints.ASSET_CAPACITY, requestOptions).then(handleResponse);
}

function listCapexService(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req ?? {}),
	};
	return fetch(endpoints.CAPEX_SERVICE, requestOptions).then(handleResponse);
}

function addCapexService(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.CAPEX_SERVICE_CREATE, requestOptions).then(handleResponse);
}

function updateCapexService(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.CAPEX_SERVICE_UPDATE, requestOptions).then(handleResponse);
}

function deleteCapexService(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.CAPEX_SERVICE_DELETE, requestOptions).then(handleResponse);
}

function editAssetTemp(req) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.ASSETS_TEMP_EDIT, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function addAssetService({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_ADD, requestOptions).then(handleResponse);
}
function addOemService({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_OEM_ADD, requestOptions).then(handleResponse);
}

function assetOemListService({ req = {} } = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_OEM_List, requestOptions).then(handleResponse);
}
function addAssetCategoryModalService({ req }) {
	const requestOptions = {
		method: 'Post',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ADD_ASSET_CATEGORY_MODAL, requestOptions).then(handleResponse);
}

function assetOemDeleteService({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_OEM_DELETE, requestOptions).then(handleResponse);
}

function assetTypeCategory({ req } = {
	req: {},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_CATEGORY, requestOptions).then(handleResponse);
}

function assetTypeCategoryDelete({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_CATEGORY_REMOVE, requestOptions).then(handleResponse);
}

function assetDashboardDataService({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ASSETS_DASHBOARD_DATA, requestOptions).then(handleResponse);
}
function assetListVarientService() {
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(endpoints.ASSETS_LIST_VARIENT, requestOptions).then(handleResponse);
}
function assetChartsDashboardService({ req }) {
	const type = req?.params?.type ?? '';
	const id = req?.params?.id ?? '';
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(`${endpoints.ASSETS_DASHBOARD_CHARTS_DATA}/${type}/${id}`, requestOptions).then(
		handleResponse
	);
}

function assetFridgeMonitoringService(
	{ req } = {
		req: {},
	}
) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.ASSETS_FRIDGE_MONITORING_URL}`, requestOptions).then(handleResponse);
}

function storeAssetListingService(
	{ req } = {
		req: {},
	}
) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.STORE_ASSET_LIST}`, requestOptions).then(handleResponse);
}
function storeAssetInfo(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.ASSET_IMMOVABLE}`, requestOptions).then(handleResponse);
}
function fetchAssetTransitFlow({ req } = {
	req: {},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.ASSET_TRANSIT_FLOW}/${req?.params?.id}/assets`, requestOptions).then(handleResponse);
}
export default {
	listAssetsService,
	addAssetService,
	assetDashboardDataService,
	assetListVarientService,
	assetChartsDashboardService,
	storeAssetListingService,
	assetFridgeMonitoringService,
	editAssetTemp,
	listCapexService,
	addCapexService,
	updateCapexService,
	deleteCapexService,
	storeAssetInfo,
	fetchCapacities,
	saveCapacities,
	addOemService,
	assetOemListService,
	addAssetCategoryModalService,
	assetOemDeleteService,
	assetTypeCategory,
	assetTypeCategoryDelete,
	listAssetDelete,
	fetchAssetTransitFlow,
};
