import { endpoints } from '../Constants';
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const licenseServices = {
	fetchLicenseList,
	fetchMappableData,
	editLicenses,
	saveLicense,
	removeLicense,
};

function fetchLicenseList(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.LICENSES_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchMappableData(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.LICENSE_MAPPABLE_DATA, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function editLicenses(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.LICENSES_EDIT, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function removeLicense(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.LICENSES_REMOVE, params);
	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function saveLicense(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.LICENSES_ADD, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

export default licenseServices;
