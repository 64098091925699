import { weekDays } from '../../Helpers';
import styles from './CustomWeekDays.module.css';

function CustomWeekDays({ selectedDays, handleSelectedDays, disabled }) {
	const selectionHandler = (id) => {
		if (selectedDays?.includes(id)) {
			const newDayArr = selectedDays.filter((dayId) => {
				return dayId !== id;
			});
			handleSelectedDays(newDayArr);
		} else {
			selectedDays?.push(id);
			handleSelectedDays(selectedDays);
		}
	};

	return (
		<div className={styles.root}>
			{weekDays.map((day) => {
				return (
					<div
						key={day?.id}
						className={`${styles.day} ${
							!disabled && selectedDays?.includes(day?.id)
								? styles.selected
								: ''
						} ${disabled ? styles.disabled : ''}`}
						// eslint-disable-next-line arrow-body-style
						onClick={() => !disabled && selectionHandler(day?.id)}
					>
						{day?.label}
					</div>
				);
			})}
		</div>
	);
}

export default CustomWeekDays;
