/* eslint-disable no-restricted-globals */
import { cloneDeep } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';

const initialState = {
	paymentId: '',
	amount: null,
	date: new Date(),
	paymentMode: '',
	paymentRefDoc: {},
	// referenceFile: '',
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_AMOUNT':
			return {
				...state,
				amount: action.payload,
			};
		case 'SET_PAYMENT_ID':
			return {
				...state,
				paymentId: action.payload,
			};
		case 'SET_DATE':
			return {
				...state,
				date: action.payload,
			};
		case 'SET_PAYMENT_MODE':
			return {
				...state,
				paymentMode: action.payload,
			};
		case 'SET_PAYMENT_REF_DOC':
			return {
				...state,
				paymentRefDoc: action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const getPaymentType = (paymentAmount, credits) => {
	const totalCreditsAmountUsed = credits?.reduce((acc, curr) => {
		return acc + curr?.debitAmount;
	}, 0);
	if (paymentAmount > totalCreditsAmountUsed) {
		return 'OTHER_CREDIT';
	}
	if (paymentAmount === totalCreditsAmountUsed) {
		return 'CREDIT';
	}
	if (paymentAmount > 0 && totalCreditsAmountUsed === 0) {
		return 'OTHER';
	}
	return 'OTHER';
};

const config = {
	initialState,
	reducer,
	deserializer: (state, creditsValue, billsValue) => {
		const clonedState = cloneDeep(state);
		const appliedCredits = creditsValue?.map((ele) => {
			return {
				creditId: ele?.id,
				debitAmount: !isNaN(Number(ele?.amount)) ? Number(ele?.amount) : 0,
			};
		});
		const appliedBills = billsValue?.map((ele) => {
			return {
				billId: ele?.id,
				debitAmount: !isNaN(Number(ele?.amount))
					? Number(ele?.amount)
					: 0,
			};
		});
		const newState = {
			paymentAmount: !isNaN(Number(clonedState?.amount))
				? Number(clonedState?.amount)
				: 0,
			clientTransactionId: clonedState?.paymentId,
			transactionDate: moment(clonedState?.date)?.format('M/D/YYYY'),
			paymentType: getPaymentType(
				Number(clonedState?.amount),
				appliedCredits
			),
			appliedCredits,
			appliedBills,
			uploadedDoc: clonedState?.paymentRefDoc?.file,
		};
		return newState;
	},
};

export default config;
