import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import CreatePromoCodePage from '../../../Pages/Accounts/Promo Code/CreatePromoCodePage';
import PromoCodeListPage from '../../../Pages/Accounts/Promo Code/PromoCodeListPage';
import PromoCodeViewPage from '../../../Pages/Accounts/Promo Code/PromoCodeViewPage';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const PromoCodeRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.PROMO_CODES}`,
			name: 'Promo Codes',
		},
	];
	return (
		<CRUDRoutes
			entity='PROMO CODE'
			path={path}
			createPage={<CreatePromoCodePage breadcrumbs={breadcrumbs} />}
			listPage={PromoCodeListPage}
			viewPage={<PromoCodeViewPage breadcrumbs={breadcrumbs} />}
			editPage={<CreatePromoCodePage isEdit breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default PromoCodeRoutes;
