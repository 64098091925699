import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions, modalActions } from '../../../Actions';
import { modalTypes } from '../../../Constants';
import { useSearch } from '../../../Helpers';
import { CheckList } from '../../CheckList';
import { BaseModal } from '../BaseModal';

const INTIAL_STATE = {
	selectedProduct: null,
};

const MODAL_TYPE = modalTypes.RETAG;

function RetagModal(props) {
	// eslint-disable-next-line object-curly-newline
	const {
		show,
		data,
		toggleModal,
		fetchProducts,
		products,
		// reFetch = true,
		identification,
		// brandId,
		// skuType,
	} = props;

	const [state, setState] = useState(INTIAL_STATE);

	const { query, handleSearch, clearSearch } = useSearch();

	const { defaultSelect = null } = data;

	useEffect(() => {
		if (show) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
						brandId: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [show]);

	const handleClose = () => {
		setState(INTIAL_STATE);
		toggleModal(false);
		clearSearch();
	};

	useEffect(() => {
		if (defaultSelect != null) {
			setState({
				selectedProduct: defaultSelect,
			});
		} else {
			setState({
				selectedProduct: identification
					? identification?.[0]?.labelId ?? -1
					: null,
			});
		}
	}, [identification, defaultSelect]);

	const handleSubmit = async () => {
		const labelId = state.selectedProduct;
		let label = null;
		if (state.selectedProduct === -1) {
			label = 'Product not identifiable';
		} else {
			label = products?.[labelId]?.productName ?? null;
		}
		await props.handleSubmit({
			labelId,
			label,
			functionToExecute: data?.functionToExecute,
		});
		handleClose();
	};

	const generateProductList = () => {
		const productList = products
			.filter((product) => {
				const name = product?.productName;
				return (
					name
						?.toLowerCase()
						?.indexOf(query?.toLowerCase()?.trim()) !== -1
				);
			})
			.map((product) => {
				const name = product?.productName;
				return {
					id: product.id,
					title: name,
				};
			});
		productList.unshift({
			id: -1,
			title: 'Product not identifiable',
		});
		return productList;
	};

	const handleProductSelect = (id) => {
		setState({
			selectedProduct: id,
		});
	};

	const renderBtns = () => {
		if (state.selectedProduct == null) {
			return <></>;
		}
		return (
			<Button variant='contained' color='primary' onClick={handleSubmit}>
				Confirm
			</Button>
		);
	};

	return (
		<BaseModal
			heading='Validate SKU'
			height='45rem'
			open={show}
			width='30rem'
			handleClose={handleClose}
			handleConfirm={handleSubmit}
			renderBtns={renderBtns}
		>
			<CheckList
				list={generateProductList()}
				selectedItems={[state.selectedProduct]}
				handleItemSelect={handleProductSelect}
				showSearch
				handleSearchInput={handleSearch}
				placeholder='Search Product'
			/>
		</BaseModal>
	);
}

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.[MODAL_TYPE];
	const { LIST: products } = state.form.ALL_PRODUCTS.data;

	return {
		show,
		data,
		products,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(MODAL_TYPE, show, data));
		},
		fetchProducts: (...args) => {
			dispatch(formActions.gateway(...args));
		},
	};
};

RetagModal.defaultProps = {
	skuType: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(RetagModal);
