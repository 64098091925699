// import moment from 'moment';
import { Label } from '@/Components/ui/label';
// import { classes } from '../../../../Helpers';
import styles from './TransactionDetails.module.scss';

const TransactionDetails = (props) => {
	const { transactionData } = props;

	const renderFields = (key, ele) => {
		if (key === 'empty') {
			return <div />;
		}
		if (typeof ele === 'object') {
			return (
				<div className='flex items-center'>
					<Label className='text-left text-xs text-slate-600 w-[115px]'>
						{key}
					</Label>
					<div className={styles['inner-obj']}>
						{Object?.keys(ele ?? {})?.map((innerEleKey) => {
							return renderFields(
								innerEleKey,
								ele?.[innerEleKey]
							);
						})}
					</div>
				</div>
			);
		}
		return (
			<div className='flex items-center'>
				<Label className='text-left text-xs text-slate-600 w-[115px]'>
					{key}
					:
				</Label>
				<p className='font-semibold px-1 text-left text-wrap'>{ele || '-'}</p>
			</div>
		);
	};

	return (
		<div className={styles.root}>
			{Object.keys(transactionData ?? {})?.map((eleKey) => {
				const ele = transactionData?.[eleKey];
				return renderFields(eleKey, ele);
			})}
		</div>
	);
};

export default TransactionDetails;
