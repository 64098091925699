// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import { QMSAnnotationPage } from '../../Pages';
import QCPage from '../../Pages/Quality/QCPage/QCPage';
import QualityConfigRoutes from './Quality Config Routes/quality-config.routes';
import QualitySettingRoutes from './Quality Setting Routes/qualitySetting.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.QUALITY;
	return entity.slice(baseEntity.length);
};

const QualityRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.QUALITY_SETTING)}`}>
				<QualitySettingRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.PRODUCT_QUALITY_CONFIG)}`}>
				<QualityConfigRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.LIVE_QC)}`} exact>
				<QCPage live />
			</Route>
			<Route path={`${path}${getSubURL(urls.PAST_QC)}/:index`} exact>
				<QCPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.QMS_ANNOTATION)}/:index`} exact>
				<QMSAnnotationPage />
			</Route>
		</Switch>
	);
};

export default QualityRoutes;
