// eslint-disable-next-line object-curly-newline
import { update, cloneDeep, isEmpty } from 'lodash';

const inventoryAnalysisConfig = {
	initialState: {
		fields: {
			amountCaption: [
				{
					caption: 'Total',
					amount: 'N/A',
				},
				{
					caption: 'Passed %',
					amount: 'N/A',
				},
				{
					caption: 'Overidden',
					amount: 'N/A',
				},
				{
					caption: 'Skipped',
					amount: 'N/A',
				},
				{
					caption: 'Weight Failure',
					amount: 'N/A',
				},
				{
					caption: 'Temp Failure',
					amount: 'N/A',
				},
			],
		},
	},
	serializer: (state, data) => {
		if (isEmpty(data)) {
			return cloneDeep(state);
		}
		return update(cloneDeep(state), 'fields', () => {
			const newState = {
				amountCaption: [
					...Object.keys(data).map((current) => {
						return {
							caption: data[current].label,
							amount: data[current].value,
						};
					}),
				],
			};
			return newState;
		});
	},
};
export default inventoryAnalysisConfig;
