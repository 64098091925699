import _ from 'lodash';

const refundHistoryConfig = {
	initialState: {
		fields: {
			refundItems: [],
		},
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				refundItems: data,
			};
			return newState;
		});
	},
};
export default refundHistoryConfig;
