import { connect, useDispatch } from 'react-redux';
import { MenuItem, TextField } from '@mui/material';
import {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import styles from './NavRequisitesModal.module.scss';
import { BaseModal } from '../BaseModal';
import {
	formActions, modalActions, navigationActions
} from '../../../Actions';
import CustomInputSelector from '../../CustomInputSelector/CustomInputSelector';
import { notificationServices } from '../../../Services';
import { Button } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
// import { urls } from '../../../Constants';

// eslint-disable-next-line no-unused-vars
const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		inputLabel,
		labelText,
		mandatory,
		optionsList,
	} = props;
	return (
		<div className={`${styles.tile} form-field row`}>
			<label
				className={`${mandatory ? styles.mandatory : ''} form-label`}
				htmlFor={id}
			>
				{labelText}
			</label>
			<TextField
				id={id}
				select
				name={name}
				value={value}
				onChange={onChange}
				label={inputLabel}
				sx={{
					width: 225,
					'.MuiInputBase-root': {
						borderRadius: '4px',
					},
				}}
			>
				{optionsList?.map((option) => {
					return (
						<MenuItem
							key={option?.id ?? option}
							value={option?.id ?? option}
						>
							{option?.title ?? option}
						</MenuItem>
					);
				})}
			</TextField>
		</div>
	);
};

const NavRequisitesModal = (props) => {
	const dispatch = useDispatch();
	const {
		show,
		toggleModal,
		data,
		stores,
		fetchStoresList,
		permittedStores,
	} = props;
	const [requisites, setRequisites] = useState([]);
	const { navItem = {}, navKey = '', redirectTo = null } = data ?? {};

	const handleClose = () => {
		toggleModal(false, {});
	};

	useEffect(() => {
		setRequisites(navItem?.requisites);
	}, [navItem?.requisites]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleConfirm = () => {
		const nullRequisite = requisites?.find((ele) => {
			return ele?.value === null;
		});
		if (!nullRequisite) {
			dispatch(
				navigationActions?.handleNavRequisite(
					navKey,
					requisites,
					redirectTo
				)
			);
			handleClose();
			setRequisites([]);
		} else {
			const message =
				nullRequisite?.type === 'store'
					? 'Please Select a store'
					: 'All requisites are not selected';
			notificationServices.generateNotification({
				type: 'error',
				message,
			});
		}
	};

	const renderBtns = useCallback(() => {
		return (
			<div className='w-full flex gap-2'>
				<Button
					variant='secondary'
					onClick={handleClose}
					className='flex-1'
				>
					Cancel
				</Button>
				<Button
					className='primary-btn flex-1'
					onClick={handleConfirm}
				>
					Confirm
				</Button>
			</div>
		);
	}, [requisites]);

	const handleChange = (storeSelected, requisiteObj) => {
		const newRequisite = requisites?.map((ele) => {
			if (ele?.type === requisiteObj?.type) {
				return {
					...ele,
					value: storeSelected,
				};
			}
			return ele;
		});
		setRequisites(newRequisite);
	};

	const handleStoreKeyPress = (e) => {
		// const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			// setState(`SET_${key}`, {});
			const newRequisite = requisites?.map((ele) => {
				if (ele?.type === 'store') {
					return {
						...ele,
						value: null,
					};
				}
				return ele;
			});
			setRequisites(newRequisite);
		}
	};

	// console.log('navKey', navKey, urls.INTERNAL_PURCHASE_ORDERS);
	const STORES = useMemo(() => {
		const validStores = {};
		permittedStores?.forEach((validStoreLoop) => {
			validStores[validStoreLoop.id] = true;
		});
		const tempStore = stores?.filter((storeFilter) => {
			return permittedStores?.length === 0 || validStores[storeFilter.id];
		});
		// if (navKey === urls.INTERNAL_PURCHASE_ORDERS) {
		tempStore?.unshift({
			id: -999,
			name: 'ALL',
		});
		return tempStore;
	}, [stores, navKey, permittedStores]);

	const renderSelectors = (ele) => {
		switch (ele?.type) {
			case 'store':
				return (
					<div className='w-full flex flex-col gap-2'>
						<Label className='text-left'>
							Select Store
							<span className='text-red-600'>*</span>
						</Label>
						<CustomInputSelector
							inputName='store'
							width='100%'
							inputLists={STORES?.map((storeEle) => {
								return {
									...storeEle,
									imageUrl: storeEle?.logo,
								};
							})}
							// eslint-disable-next-line no-unused-vars
							// handleRemove={(key) => {
							// handleFromStoreSelection(null);
							// }}
							handleKeyPress={handleStoreKeyPress}
							handleSelection={(selectedStore) => {
								handleChange(selectedStore, ele);
							}}
							allowRemove={false}
							hideAddNew
							placeholder='Select From Store'
							selectedValue={ele?.value?.name}
							classname={styles.selector}
						/>
					</div>
				);
			default:
				return '';
		}
	};

	return (
		<BaseModal
			open={show}
			heading='Please Select Store'
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='30rem'
			height='30rem'
		>
			<div className={styles.root}>
				<div className='w-full'>
					{requisites?.map((ele) => {
						return renderSelectors(ele);
					})}
				</div>
				<div className={styles.note}>
					<p>
						Note: Store is mandatory for
						{' '}
						{navItem?.name}
						{' '}
						tab!
					</p>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.navRequisitesModal;
	const stores = state?.form?.STORES?.data?.LIST;
	const { permittedStores } = state?.navigation;
	return {
		show,
		data,
		stores,
		permittedStores,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleNavItemRequisitesModal,
	saveVendor: formActions.gateway,
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavRequisitesModal);
