import { Switch } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../Actions';
import ListTable from '../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'School',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Address',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SUPPORT NUMBER',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MANAGER NAME',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MANAGER NUMBER',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const SPECIAL_COLUMNS = [];
const COLMAP = {
	School: 'school',
	Address: 'address',
	'SUPPORT NUMBER': 'customersupport',
	'MANAGER NAME': 'managerName',
	'MANAGER NUMBER': 'managerPhone',
};
const SPECIAL_ITEMS = {
	Enabled: (id, isEnabled) => {
		return (
			<Switch
				name='customization'
				value='customization'
				size='small'
				color='primary'
				defaultChecked={isEnabled}
				inputProps={{
					'data-id': id,
					'data-type': 'COMBO',
				}}
			/>
		);
	},
};
const HolidayListPage = (props) => {
	const { fetchStore, data, query, deleteStore } = props;
	useEffect(() => {
		fetchStore({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleDelete = ({ id }) => {
		deleteStore({
			entity: 'STORES',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	return (
		<div className='list-page-root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.filter((store) => {
						return (
							store.name
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})
					.map((store) => {
						return {
							...store,
							_name: store?.[COLMAP?.Name],
						};
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.STORES.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchStore: formActions.gateway,
	deleteStore: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayListPage);
