import { useRouteMatch } from 'react-router';
import ApproveRefundPage from '../../../Pages/Refund/Approve Refund/ApproveRefundPage';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const ApproveRefundRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='APPROVE_REFUNDS'
			path={path}
			listPage={ApproveRefundPage}
			placeholder='Search By Order Id'
		/>
	);
};

export default ApproveRefundRoutes;
