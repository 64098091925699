/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Button, IconButton, MenuItem, TextField } from '@mui/material';
import { useEffect, useReducer, useState, useMemo } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import styles from './SalesReturnCreatePage.module.scss';
import config from './SalesReturnCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../../Services';
import { ListModal } from '../../../../Components/Modals';
import { formActions, modalActions } from '../../../../Actions';
import licenseServices from '../../../../Services/license-services';
import BackdropLoader from '../../../../Components/Loaders/BackdropLoader';
import { classes, inputHelper, useQuery } from '../../../../Helpers';
import myMachinesServices from '../../../../Services/AssetServices/myMachine.service';
import DatePickerCustom from '../../../../Components/DatePickerCustom/DatePickerCustom';
import { POItemSelection } from '../../../../Components/POItemSelection';
import CustomInputSelector from '../../../../Components/CustomInputSelector/CustomInputSelector';
import { IconInput } from '../../../../Components/Forms/Inputs';
import inventoryAnalyticsServices from '../../../../Services/Analytics Services/inventory-analytics.services';
import { AddNewVendorModal } from '../../../../Components/Modals/AddNewVendorModal';
import { POTotalContainer } from '../../../../Components/POTotalContainer';

function SalesReturnCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		processTypes,
		fetchProcessTypesList,
		fetchProducts,
		fetchVendors,
		vendors,
		toggleAddVendorModal,
		productsList,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const {
		store,
		fromStore,
		challanId,
		orderId,
		salesReturnDate,
		// items,
	} = state ?? {};
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const poId = Number(params.get('poId'));
	const [orderItems, setOrderItems] = useState([]);
	// const orderId = parseInt(params.get('orderId'), 10);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (
			poId &&
			productsList?.length > 0 &&
			stores?.length > 0
		) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.fetchPo(
						Number(poId)
					);
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								// items: response?.data?.map((item) => {
								// 	return {

								// 	};
								// }),
								fromStore: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.storeId
									);
								}),
								store: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								orderId: response?.data?.id,
							},
						});
						setOrderItems(response?.data?.items);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [poId, productsList, stores]);

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer(state);
		const validate = config?.validator(state);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
				// fromStore:
				type: 'SALES_RETURN',
			},
		};
		setLoading(true);
		try {
			const response = await inventoryAnalyticsServices.createTransactionRecord(
				req
			);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const setItemsState = (items) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				items,
			},
		});
	};

	const PRODUCTS = useMemo(() => {
		return productsList?.filter((product) => {
			return (
				product?.skuType === 'INTERMEDIATE' ||
				product?.skuType === 'PRODUCT' ||
				product?.skuType === 'INGREDIENT'
			);
		});
	}, [productsList]);

	const handleKeyPress = (e) => {
		const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			// setState(`SET_${key}`, {});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					[key]: {},
				},
			});
		}
	};

	// const handleToStore = (newToStore) => {
	// 	dispatch({
	// 		type: 'SET_VALUE',
	// 		payload: {
	// 			toStore: newToStore,
	// 		},
	// 	});
	// };
	const handleFromStore = (newFromStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				fromStore: newFromStore,
			},
		});
	};
	const handleVendorSelection = (vendor) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				vendor,
			},
		});
	};

	const disabledDate = (rsuiteDate) => {
		return rsuiteDate > new Date();
	};

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalAmount = 0;
		const taxValues = {};
		const totalQuantity = state?.items?.length;
		state?.items?.forEach((ele) => {
			if (ele?.unit === 'gram') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
							ele?.selectedVariant?.price * (ele?.quantity / 1000)
					  );
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 1000);
			} else if (ele?.unit === 'oz') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
							ele?.selectedVariant?.price * (ele?.quantity / 16)
					  );
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 16);
			} else {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(ele?.selectedVariant?.price * ele?.quantity);
				totalAmount +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
			}
		});
		taxValues.SGST = totalTax / 2;
		taxValues.CGST = totalTax / 2;
		return {
			subTotal,
			totalQuantity,
			totalTax,
			totalAmount,
			taxValues,
		};
	}, [state?.items]);

	return (
		<div className={styles.root}>
			<AddNewVendorModal />
			<BackdropLoader isLoading={loading} showProgress />
			{/* <ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/> */}
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>Add New Sales Return</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						From Store*
					</label>
					<CustomInputSelector
						inputName='fromStore'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleKeyPress={handleKeyPress}
						handleSelection={handleFromStore}
						hideAddNew
						placeholder='From Store'
						selectedValue={fromStore?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Store*
					</label>
					<CustomInputSelector
						inputName='store'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleKeyPress={handleKeyPress}
						handleSelection={handleFromStore}
						hideAddNew
						placeholder='Store'
						selectedValue={store?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Sales Return ID*
					</label>
					<IconInput
						type='custom'
						size='extra-large'
						inputType='text'
						value={challanId}
						handleInput={(e) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									salesReturnId: e?.target?.value,
								},
							});
						}}
						style={{
							width: '200px',
						}}
					/>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className='form-label'
						// htmlFor={id}
					>
						Order id
					</label>
					<TextField
						id='orderNo'
						type='text'
						name='orderId'
						value={orderId}
						onChange={(e) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									orderId: e?.target?.value,
								},
							});
						}}
						placeholder='Order id'
						sx={{
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Sales Return Date*
						</label>
						<DatePickerCustom
							selectedDate={salesReturnDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										salesReturnDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 200,
							}}
							oneTap
							defaultValue={salesReturnDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
				</div>
			</div>
			<div className={styles['items-details-container']}>
				<POItemSelection
					state={state?.items}
					setState={setItemsState}
					defaultSelectedItems={orderItems}
					productsList={PRODUCTS}
					requiredFields={{
						fromStore: true,
						deliverTo: true,
						poId: true,
						date: true,
						expectedDeliveryDate: true,
						paymentTerms: false,
						generateStandardPO: false,
					}}
					disabled={false}
				/>
				<POTotalContainer calculatedData={calculatedData} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	return {
		userPermissions,
		stores,
		vendors,
		processTypes,
		productsList,
	};
};
// ['PRODUCT', 'INTERMEDIATE']

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchVendors: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SalesReturnCreatePage);
