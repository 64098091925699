import { connect } from 'react-redux';
import { useState } from 'react';
import { modalActions } from '../../../../Actions';
import { modalTypes, urls } from '../../../../Constants';
import { ImageThumbnail } from '../../../Image';
import styles from './OutwardingImageResults.module.scss';
import loadMoreLoader from '../../../../Images/loadmoreLoader.svg';
import { usePagination } from '../../../../Helpers';

const OutwardingImageResults = (props) => {
	const {
		showLoadMore,
		product,
		clickable,
		extraLinkData,
		toggleModal,
		fetchQCData,
		fetchImagesById,
	} = props;
	const { page, limit, nextPage } = usePagination({
		customLimit: 6,
	});
	const [disabled, setDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const openModal = (item) => {
		toggleModal(true, {
			item,
		});
	};

	const loadMoreImages = async () => {
		try {
			setDisabled(true);
			setLoading(true);
			await fetchImagesById({
				productId: product.id,
				pageNo: page + 1,
				limit,
			});
			nextPage();
		} catch (err) {
			//
		} finally {
			setDisabled(false);
			setLoading(false);
		}
	};

	return (
		<div className={styles.result}>
			<p className={styles['result-header']}>
				{product?.label}
				<span className={styles.count}>
					{` (Showing ${product.Annotation.length} of ${
						product?.count ?? '-'
					} image(s))`}
				</span>
			</p>
			<div className={styles['image-grid']}>
				{product?.Annotation?.map((annotation) => {
					return (
						<ImageThumbnail
							key={annotation.id}
							source={annotation.image}
							identificationType={annotation.ai}
							hideIdentificationType
							annotation={annotation}
							featureCount={annotation.featureCount}
							onClick={async () => {
								if (clickable) {
									const qcData = await fetchQCData(
										annotation.qcId
									);
									const link =
										// eslint-disable-next-line prefer-template
										urls?.EXTENSION +
										urls?.PAST_QC +
										'/' +
										qcData?.itemId +
										'?id=' +
										qcData?.id +
										'&previousPageType=' +
										(extraLinkData ?? '');
									openModal({
										source: annotation.image,
										name: qcData?.ProductName,
										product: product.label,
										date: qcData?.QCTime,
										link: qcData != null ? link : null,
									});
								}
							}}
						/>
					);
				})}
				{showLoadMore && product.count &&
					product.Annotation.length !== product.count && (
					<button
						// disabled={disabled}
						disabled={disabled}
						type='button'
						className={styles['load-more']}
						onClick={loadMoreImages}
					>
						{loading ? (
							<div>
								<img src={loadMoreLoader} alt='loader' />
								<p>Loading</p>
							</div>
						) : (
							'Load More'
						)}
					</button>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OutwardingImageResults);
