/* eslint-disable no-unused-vars */
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useMemo } from 'react';
import TagPicker from 'rsuite/TagPicker';
import { classes } from '../../../../Helpers';
import { Tag } from '../../../Tags';
import styles from './AuditStep3.module.scss';

const AuditStep3 = (props) => {
	const {
		selectedCategories,
		skusList,
		productCategoriesList,
		toggleListModal,
		deleteFilter,
		selectedSkus,
		setSelectedSkyTypes,
	} = props;

	const PRODUCT_CATEGORIES = useMemo(() => {
		return productCategoriesList?.map((category) => {
			return {
				id: category.id,
				title: category?.productCategoryName ?? '',
			};
		});
	}, [productCategoriesList]);

	return (
		<div className={styles.root}>
			<div className={styles.labelled_input}>
				<label>SKU Type</label>
				<TagPicker
					size='lg'
					data={skusList?.map((ele) => {
						return {
							value: ele,
							label: ele,
						};
					})}
					onChange={(value) => {
						setSelectedSkyTypes(value);
					}}
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-start',
					}}
					value={selectedSkus}
					placeholder='Select SKU Type'
					menuStyle={{
						zIndex: 9999,
					}}
				/>
			</div>
			<div className={classes(styles.filter)}>
				<p className={styles['filter-heading']}>Product category</p>
				<div className={styles['filter-body']}>
					<Button
						variant='outlined'
						startIcon={<Add />}
						className={styles['add-button']}
						// disabled={brand == null}
						fullWidth
						onClick={() => {
							toggleListModal(true, {
								callee: 'auditStep3',
								entity: 'CATEGORY',
								heading: 'Select Category(s)',
								list: PRODUCT_CATEGORIES,
								selected: selectedCategories,
							});
						}}
					>
						Category
					</Button>
					<div className={styles.tags}>
						{selectedCategories?.map((id) => {
							const selectedCategory = PRODUCT_CATEGORIES?.find(
								(v) => {
									return id === v?.id;
								}
							);
							return (
								<Tag
									key={selectedCategory?.id}
									width='100%'
									text={selectedCategory?.title}
									handleDelete={deleteFilter}
									dataAttrs={{
										'data-id': selectedCategory?.id,
										'data-entity': 'CATEGORY',
									}}
								/>
							);
						})}
						{selectedCategories?.length === 0 && (
							<span>No Categories selected</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuditStep3;
