/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
import moment from 'moment';
import ComputerTwoToneIcon from '@mui/icons-material/ComputerTwoTone';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { ListTable } from '../../Components/ListTable';
import { LOService, notificationServices } from '../../Services';
import { modalActions } from '../../Actions';
// import { POItemsModal } from '../../Components/Modals/POItemsModal';
import BackdropLoader from '../../Components/Loaders/BackdropLoader';
import LeftOverItemsModal from '../../Components/Modals/LeftOverItemsModal/LeftOverItemsModal';
import { handleResponseStreamDownload } from '@/Helpers';
import machine from '../../Images/scan.jpeg';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory At Day End Of',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'Captured On',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Remove',
		config: {
			type: 'CANCEL',
		},
	},
];

// {'productId': item[0], 'variantId': item[1]}

const COL_MAP = {
	ID: 'id',
	Store: 'store',
	Process: 'processName',
	'Inventory At Day End Of': 'dayEndOf',
	'Captured On': 'id',
	Remove: 'id',
};

function ProcessClosingsPage(props) {
	const history = useHistory();
	const { toggleAlert, createPath, toggleLeftOverItemsModal } = props;
	const [loading, setLoading] = useState(false);
	const [leftOverInventoryList, setLeftOverInventoryList] = useState([]);
	const [currentFilterState, setCurrentFilterState] = useState({});

	const handleFind = async (requestOptions) => {
		setLoading(true);
		const filters = {
			dateRangeFilter: {
				timeType: requestOptions?.timeType,
				from: moment(requestOptions?.dateRange?.from).format(
					'YYYY-MM-DD'
				),
				to: moment(requestOptions?.dateRange?.to).format('YYYY-MM-DD'),
			},
			processTypeIds: requestOptions?.processTypes,
			storeId: requestOptions?.store,
		};
		try {
			const response = await LOService.listLOInventory({
				data: {
					...filters,
				},
			});
			if (response?.statusCode === 200) {
				const data = response?.data?.map((item) => {
					return {
						...item,
						// dayEndOf: moment(item.day).format('YYYY-MM-DD'),
					};
				});

				setLeftOverInventoryList(data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setCurrentFilterState(filters);
			setLoading(false);
		}
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleViewClick = async (item) => {
		setLoading(true);
		try {
			const response = await LOService.listLOInventoryItems({
				id: item?.id,
			});
			if (response?.statusCode === 200) {
				let itemsData = {};
				if (Array.isArray(response?.data)) {
					itemsData = response?.data?.reduce((acc, invItem) => {
						if (acc?.[invItem?.skuType]) {
							acc?.[invItem?.skuType]?.push(invItem);
						} else {
							acc[invItem?.skuType] = [invItem];
						}
						return acc;
					}, {});
				} else {
					itemsData = Object?.keys(response?.data)?.reduce((acc, invItem) => {
						const currentTypeItems = response?.data?.[invItem];
						acc[invItem] = currentTypeItems?.map((ele) => {
							return {
								...ele,
								qty: ele?.qty ?? ele?.count,
								productName: ele?.name,
								variantName: ele?.variant,
							};
						});
						return acc;
					}, {});
				}

				toggleLeftOverItemsModal(true, {
					heading: `Day End Inventory of ${item?.processName} on ${item?.dayEndOf}`,
					items: itemsData,
					date: item?.dayEndOf,
					processName: item?.processName,
					isApprove: false,
					advanceBill: false,
					showTotal: false,
					showPdf: false,
					showPoDetails: false,
					width: '60rem',
					height: '40rem',
					excludedColumns: [
						'Packaged',
						'Dispatched',
						'Received',
						'Returned Quantity',
						'Fulfilled',
						'From Process',
						'PO Id',
						'Returned',
						// 'Category Name',
						'Ordered Quantity',
						'Item Price',
						'Tax Percentage',
						'Tax value',
						'Total Item Amount',
						'Discount %',
						'Used For',
					],
					hideItems: false,
					isAdvanceBill: false,
				});
				// toggleOrderItemsModal(true, {
				// items: data,
				// orderId: item?.id,
				// });
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async (itemId) => {
		setLoading(true);
		try {
			const response = await LOService.deleteLOInventory({
				id: itemId,
			});
			if (response?.statusCode === 200) {
				setLeftOverInventoryList((prevList) => {
					return prevList.filter((item) => { return item?.id !== itemId; });
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const SPECIAL_ITEMS = {
		'Captured On': (ele, val) => {
			const captured = leftOverInventoryList.find((ele) => ele.id === val)?.machine;
			return captured ? (
				<div className='w-[30px] ml-[2rem]'>
					<img src={machine} alt='machine' />
				</div>
			) : (
				<div className='w-[30px] ml-[2rem]'>
					<ComputerTwoToneIcon />
				</div>
			);
		},
		Remove: (itemId, val) => {
			const itemToRemove = leftOverInventoryList.filter((item) => { return item?.id === val; });
			return (
				<Button
					variant='outlined'
					color='error'
					onClick={() => {
						toggleAlert(true, {
							heading: 'Remove',
							handleConfirm: () => {
								handleDelete(itemId);
								toggleAlert(false);
							},
							colorReverse: false,
							cancelText: 'No',
							confirmText: 'Yes',
							message: `Are you sure you to remove day end inventory of ${itemToRemove[0]?.processName || ' '} on ${
									itemToRemove[0]?.dayEndOf
								}?`,
						});
					}}
				>
					Remove
				</Button>
			);
		},
	};
	async function onExportClick() {
		try {
			const response = await LOService.getLeftOverInventoryCsv({
				...currentFilterState,
			});
			if (response.status === 200) {
				handleResponseStreamDownload(response, `leftover_${currentFilterState?.dateRangeFilter?.from}_${currentFilterState?.dateRangeFilter?.to}.csv`);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		}
	}

	return (
		<div>
			{/* <OrderItemsModal /> */}
			{/* <POItemsModal /> */}
			<LeftOverItemsModal />
			<BackdropLoader isLoading={loading} showProgress />
			<div
				style={{
					padding: '10px',
				}}
			>
				<ImagesFinder
					pageLabel='Left Over Inventory'
					onSubmit={handleFind}
					FILTERS={['filteredStore', 'dateRangeOnly', 'processTypes']}
					filterLabels={{
						store: 'Store',
						processTypes: 'Process',
						dateRangeOnly: 'Left Over Inventory Date Range',
					}}
					additionInfo={{
						showAdd: true,
						// showAdd: false,
						addText: 'Left Over Inventory',
						handleAddClick,
					}}
					dateRangeOnlyText='Inventory at day end range'
				/>
			</div>
			<ListTable
				data={leftOverInventoryList}
				columns={TABLE_COLUMNS}
				colMap={COL_MAP}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				handleViewClick={handleViewClick}
				onExportClick={onExportClick}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	return {
		productsList,
	};
};
const mapDispatchToProps = {
	toggleAlert: modalActions.toggleAlert,
	toggleOrderItemsModal: modalActions.toggleOrderItemsModal,
	toggleLeftOverItemsModal: modalActions.toggleLeftOverItemsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessClosingsPage);
