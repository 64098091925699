import { withStyles } from '@mui/styles';
import { Tab, Tabs } from '@mui/material';
import './StyledTabs.scss';

export const StyledTabs = withStyles(() => {
	return {
		root: {
			position: 'sticky',
			top: 'calc(57px + 50px)',
			zIndex: 11,
			background: 'white',
		},
	};
})((props) => {
	return (
		<Tabs
			{...props}
			TabIndicatorProps={{
				children: <span />,
			}}
			textColor='primary'
			indicatorColor='primary'
		/>
	);
});

export const StyledTab = withStyles(() => {
	return {
		root: {
			textTransform: 'capitalize',
			color: '#5F5F5F',
			fontWeight: '400',
			fontSize: '0.8rem',
			marginRight: '1rem',
			maxWidth: 180,
			minWidth: 100,
			'&:focus': {
				opacity: 1,
			},
		},
	};
})((props) => {
	return <Tab disableRipple {...props} />;
});

export const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`item-info-${index}`}
			aria-labelledby={`item-info-${index}`}
			{...other}
			className='tab-panel'
		>
			{value === index && children}
		</div>
	);
};
