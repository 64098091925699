/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import TransitDashboard from '@/Pages/TransitDashboard/TransitDashboard';
import PastTransitAnalysisHistoryPage from '@/Pages/TransitDashboard/PastTransitAnalysisHistoryPage';
import TransitAnalysisHistory from '@/Pages/TransitDashboard/TransitAnalysisHistory';

const getSubURL = (entity) => {
	const baseEntity = urls.TRANSIT_ANALYTICS;
	return entity.slice(baseEntity.length);
};
const TransitAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route path={`${path}${getSubURL(urls.TRANSIT_ANALYSIS)}`} exact>
					<TransitDashboard />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.TRANSIT_ANALYSIS_LIST)}`}
					exact
				>
					<PastTransitAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.TRANSIT_ANALYSIS,
								name: 'Transit Loss Analysis',
							},
							{
								link: '',
								name: 'Transit Loss Analysis History List',
							},
						]}
					/>
				</Route>
				<Route
					path={`${path}${getSubURL(urls.TRANSIT_HISTORY_LIST)}`}
					exact
				>
					<TransitAnalysisHistory />
				</Route>
			</Switch>
		</div>
	);
};

export default TransitAnalyticsRoutes;
