import { DASHBOARD_CHARTS_COLORS } from '../../../Styles/colors';
import DataTile from './DataTile/DataTile';
import styles from './HighlightTilesContainer.module.scss';

function HighlightTilesContainer(props) {
	const {
		data = [],
		storeFilter,
		dateFilter,
		showLink,
		selectedTab,
	} = props;
	return (
		<div className={styles.root}>
			{data?.map((ele, idx) => {
				return (
					<DataTile
						showRedirect={idx === 0}
						storeFilter={storeFilter}
						dateFilter={dateFilter}
						showLink={showLink}
						key={ele?.heading}
						tileData={ele}
						selectedTab={selectedTab}
						color={DASHBOARD_CHARTS_COLORS?.highlightsColor?.[idx]}
					/>
				);
			})}
		</div>
	);
}

export default HighlightTilesContainer;
