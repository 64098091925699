const TRANSACTION_TYPES = {
	PAYMENTS: {
		id: 'id',
		Vendor: 'vendorName',
		'Payment ID': 'clientTransactionId',
		'Bills Paid': 'extraData',
		Status: 'transactionStatus',
		'Recorded By': 'l1ActionByUserDisplayName',
		'Payment date': 'transactionDate',
		'Paid Amount': 'finalTransactionAmount',
	},
	CREDITS: {
		id: 'id',
		Vendor: 'vendorName',
		Store: 'storeName',
		'Order id': 'orderId',
		'Client Debit. Id': 'clientTransactionId',
		'Debit Note Date': 'transactionDate',
		Status: 'transactionStatus',
		'Debit Amount': 'finalTransactionAmount',
		'Balance Amount': 'currentAmount',
	},
	BILLS: {
		id: 'id',
		Vendor: 'vendorName',
		'Bill Type': 'id',
		'Billing Store': 'storeName',
		'Order id': 'clientOrderId',
		'GRN Id': 'parentRowId',
		'Client Bill. id': 'clientTransactionId',
		'Bill date': 'transactionDate',
		'Due Date': 'dueDate',
		'Bill Amount': 'finalTransactionAmount',
		'Paid Amount': 'currentAmount',
		Status: 'id',
		'View Bills Items': 'id',
		'View Payments': 'id',
	},
};

const getTableData = (transactionType) => {
	const transactionTableData = TRANSACTION_TYPES?.[transactionType];
	return {
		TABLE_COLUMNS: Object?.keys(transactionTableData ?? {})?.map((title) => ({
			title,
			config: {
				type: 'DATA',
			},
		})),
		SPECIAL_COLUMNS: [],
		COLUMN_MAP: transactionTableData,
	};
};

const config = {
	TABLE_DATA: getTableData,
};

export default config;
