import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import RefundHistoryTable from '../../../Components/Forms/Refund History/RefundHistoryTable';
import { isEmpty } from '../../../Helpers';
import refundHistoryConfig from './refundHistory.config';

const RefundHistoryList = (props) => {
	const { fetchRefundsHistory, data, query } = props;
	const [state, setState] = useState({
		...refundHistoryConfig.initialState,
	});
	useEffect(() => {
		fetchRefundsHistory({
			entity: 'REFUND_HISTORY',
			req: {
				params: {},
			},
			method: 'LIST',
		});
	}, []);
	useEffect(() => {
		if (!isEmpty(data)) {
			setState({
				...refundHistoryConfig.serializer(state, data),
			});
		}
	}, [data]);
	const { fields } = state;
	return (
		<div>
			<RefundHistoryTable
				orders={fields.refundItems.filter((order) => {
					return order.orderId.toString().indexOf(query) !== -1;
				})}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.form.REFUND_HISTORY.data.LIST;
	return {
		data,
	};
};
const mapDispatchToProps = {
	fetchRefundsHistory: formActions.gateway,
};
export default connect(mapStateToProps, mapDispatchToProps)(RefundHistoryList);
