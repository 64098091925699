import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../';
import { addCommas, classes } from '../../Helpers';
import styles from './INVAnalysisListPage.module.scss';
import analyticsAction from '../../Actions/analytics.actions';
import { useAuth } from '../../Components/Auth/Login/auth';
import { VirtualizedListTable } from '@/Components/VirtualizedListTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	'Product Name': 'productName',
	Unit: 'unit',
	Store: 'storeName',
	'Opening Stock Qty': 'openingStockQty',
	'Opening Stock Value (Rs)': 'openingStockValue',
	'Closing Stock Qty': 'closingStockQty',
	'Closing Stock Value (Rs)': 'closingStockValue',
	'Total Stocked In Qty': 'totalStockedInQty',
	'Total Stocked Out Qty': 'totalStockedOutQty',
};

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Opening Stock Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Opening Stock Value (Rs)',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Closing Stock Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Closing Stock Value (Rs)',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Stocked In Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Stocked Out Qty',
		config: {
			type: 'DATA',
		},
	},
];

const getRowStyles = (item) => {
	switch (item?.result) {
		default:
			return styles.normal;
	}
};

// function setAttr(text) {
// return <span className={styles.text}>{text}</span>;
// }

const INVAnalysisListPage = (props) => {
	// const history = useHistory();
	const { data, setAnalyticsType, ANALYTICS_TYPE, exportInvAnalytics } =
		props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;
	// const { appConfig = {} } = useAuth();
	// const { measurement } = appConfig?.configs ?? {};
	// const listCount = data.length - 1;
	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, []);
	const SPECIAL_ITEMS = {
		'Product Name': (_, obj) => {
			// eslint-disable-next-line prefer-template
			return obj?.productName + (obj?.variantName ? ' (' + obj?.variantName + ')' : '');
		},
		'Opening Stock Value (Rs)': (value) => {
			return addCommas(value, measurement, true);
		},
		'Closing Stock Value (Rs)': (value) => {
			return addCommas(value, measurement, true);
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<VirtualizedListTable
				fixedHeaderTop={0}
				hasDateRange
				// renderColumn={renderColumn}
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				data={data?.map((obj) => {
					const rowStyles = getRowStyles(obj);
					// const itemId = get(obj, COLMAP['Item Id']);
					// const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						rowStyles,
					};
				})}
				columns={[...TABLE_COLUMNS]}
				actions={[]}
				onExportClick={exportInvAnalytics}
			/>
			{/* <ArrangePages
				listCount={61}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				setAnalyticsType={setAnalyticsType}
			/> */}
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	exportInvAnalytics: analyticsAction.exportInvAnalytics,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(INVAnalysisListPage);
