/* eslint-disable no-mixed-spaces-and-tabs */
import { cloneDeep, update } from 'lodash';

export const qualityConfig = {
	initialState: {
		fields: {
			isQualityEnabled: false,
			weightCheck: false,
			tempCheck: false,
			defectCheck: false,
			defectParams: {
				areaSelection: false,
				countSelection: false,
				areaThreshold: 0.1,
				countThreshold: 1,
				andCondition: false,
			},
		},
	},
	mandatoryFields: {
		minWeight: true,
		maxWeight: true,
		areaThreshold: true,
		countThreshold: true,
	},
	validator: (state) => {
		const valid = (
			!(state?.fields?.defectCheck) ||
        (state?.fields?.defectCheck &&
        (state?.fields?.defectParams?.areaSelection ||
            state?.fields?.defectParams?.countSelection))
		);
		return valid;
	},
	serializer: (state, data) => {
		return update(cloneDeep(state), 'fields', () => {
			const defectConfig = data.defectConfig || {};

			const newState = {
			  weightCheck: data.weightCheckRequired,
			  tempCheck: data.tempCheckRequired,
			  defectCheck: defectConfig.applied,

			  defectParams: {
					areaSelection: defectConfig.areaSelection,
					countSelection: defectConfig.countSelection,
					areaThreshold: defectConfig?.areaThreshold ?? 0.1,
					countThreshold: defectConfig?.countThreshold ?? 1,
					andCondition: defectConfig.andCondition,
			  },

			  isQualityEnabled: data.isQCEnabled,
			};
			return newState;
		});
	},
	deserilizer: (state) => {
		const newData = cloneDeep(state);
		const newState = {
			QMSEnabled: newData?.fields?.isQualityEnabled,
			defectConfig: {
				applied: newData?.fields?.defectCheck,
				andCondition: newData?.fields?.defectParams?.andCondition,
				areaSelection: newData?.fields?.defectParams?.areaSelection,
				countSelection: newData?.fields?.defectParams?.countSelection,
				areaThreshold: newData?.fields?.defectParams?.areaThreshold,
				countThreshold: newData?.fields?.defectParams?.countThreshold,
			},
			tempCheck: {
				applied: newData?.fields?.tempCheck,
				minTemp: 0,
				maxTemp: 0,
			},
			variants: [],
			weightCheck: newData?.fields?.weightCheck,
		};
		return newState;
	},
};
