import { cloneDeep } from 'lodash';
import moment from 'moment';
import { notificationServices } from '../../../../Services';

const initialState = {
	store: null,
	date: new Date(),
	amount: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				store: null,
				date: new Date(),
				amount: null,
			};
		default:
			return state;
	}
};

const config = {
	initialState,
	reducer,
	validator: (state) => {
		if (!state?.store) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Store is Mandatory',
			});
			return false;
		}
		if (!state?.date) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Date is mandatory',
			});
			return false;
		}
		if (!state?.amount) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Amount should be more than 0',
			});
			return false;
		}
		return true;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const newState = {
			// ...clonedState,
			date: clonedState?.date
				? moment(clonedState?.date).format('YYYY-MM-DD')
				: '',
			revenue: clonedState?.amount,
			storeId: clonedState?.store,
		};
		return newState;
	},
};

export default config;
