/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { CookingHistoryListData } from '../../../Pages';
import WorkOrderList from '../../../Pages/Analytics/Cooking History/WorkOrderList/WorkOrderList';
import { urls } from '../../../Constants';

const getSubURL = (entity) => {
	const baseEntity = urls.COOKING_ANALYSIS;
	return entity.slice(baseEntity.length);
};
const CookingAnalyticsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route
					path={`${path}${getSubURL(urls.COOKING_ANALYSIS_LIST)}`}
					exact
				>
					<CookingHistoryListData />
				</Route>
				<Route path={`${path}${getSubURL(urls.WORK_ORDER_LIST)}`} exact>
					<WorkOrderList />
				</Route>
			</Switch>
		</div>
	);
};

export default CookingAnalyticsRoutes;
