import { Add, HighlightOff } from '@mui/icons-material';
import { addCommas, classes } from '../../Helpers';
import styles from './POTotalContainer.module.scss';

function POTotalContainer(props) {
	const {
		calculatedData,
		packagingCharges,
		taxPercentage = 5,
		deliveryCharges,
		handleChange,
		showCharges = false,
		disableCharges = true,
		showAdditionalCharge = false,
		allowAddAdditional = true,
		allowRemoveAdditional = true,
		additionalCharges = [],
		handleAdditionalCharges = () => {},
		addAdditonalCharges = () => {},
		handleRemoveAdditionalCharge = () => {},
	} = props;

	return (
		<div className={styles.root}>
			<div className={classes(styles['total-container'])}>
				{!!calculatedData?.totalDiscount && (
					<div className={classes(styles.tile)}>
						<p className={styles.title}>
							Total Discount
						</p>
						<p className={styles.discount}>
							₹(-
							{addCommas(calculatedData?.totalDiscount)}
							)
						</p>
					</div>
				)}
				<div className={classes(styles.tile)}>
					<p className={styles.title}>
						Sub Total (
						{calculatedData?.totalQuantity}
						{' '}
						Item(s))
					</p>
					<p className={styles.value}>
						₹
						{addCommas(calculatedData?.subTotal)}
					</p>
				</div>
				{showCharges && (
					<>
						<div className={classes(styles.tile)}>
							<p className={styles.title}>Delivery Charges: </p>
							<div className={styles?.container}>
								<div className={styles?.tax}>
									Tax:
									{' '}
									{taxPercentage}
									%
								</div>
								<div>
									₹
									{' '}
									<input
										// name=''
										disabled={disableCharges}
										type='text'
										min={0}
										className={styles.charges}
										value={deliveryCharges}
										onChange={(e) => {
											const value = e?.target?.value;
											const isPositiveDecimal =
												/^(?:\d+(\.\d*)?|\.\d+)?$/.test(value);
											if (value === '' || isPositiveDecimal) {
												handleChange(
													'SET_DELIVERY_CHARGES',
													e.target?.value
												);
											}
										}}
									/>
								</div>
								<div className={styles?.appliedTax}>
									Tax amount
									{': '}
									{((deliveryCharges * taxPercentage) / 100)?.toFixed(2)}
								</div>
							</div>
						</div>
						<div className={classes(styles.tile)}>
							<p className={styles.title}>Packaging Charges: </p>
							<div className={styles?.container}>
								<div className={styles?.tax}>
									Tax:
									{' '}
									{taxPercentage}
									%
								</div>
								<div>
									₹
									{' '}
									<input
										// name=''
										disabled={disableCharges}
										type='text'
										min={0}
										className={styles.charges}
										value={packagingCharges}
										onChange={(e) => {
											const value = e?.target?.value;
											const isPositiveDecimal =
												/^(?:\d+(\.\d*)?|\.\d+)?$/.test(value);
											if (value === '' || isPositiveDecimal) {
												handleChange(
													'SET_PACKAGING_CHARGES',
													e.target?.value
												);
											}
										}}
									/>
								</div>
								<div className={styles?.appliedTax}>
									Tax amount
									{': '}
									{((packagingCharges * taxPercentage) / 100)?.toFixed(2)}
								</div>
							</div>
						</div>
					</>
				)}
				{showAdditionalCharge && (
					<div className={styles.additional_charge_container}>
						{additionalCharges?.map((ele, idx) => {
							return (
								<div className={styles.additional_charge}>
									<div className={styles.text_input}>
										<input
											name='type'
											className={styles.charges}
											value={ele?.type}
											onChange={(e) => {
												handleAdditionalCharges(idx, e);
											}}
										/>
										:
									</div>
									<div className={styles.tax_price}>
										Tax %
										<input
											name='taxPercentage'
											type='text'
											min={0}
											className={styles.tax_percentage}
											value={ele?.taxPercentage}
											onChange={(e) => {
												const value = e?.target?.value;
												const isPositiveDecimal =
													/^(?:\d+(\.\d*)?|\.\d+)?$/.test(
														value
													);
												if (value === '' || isPositiveDecimal) {
													handleAdditionalCharges(idx, e);
												}
											}}
										/>
										₹
										<div className={styles.charge_price}>
											<input
												name='price'
												disabled={disableCharges}
												type='text'
												min={0}
												className={styles.charges}
												value={ele?.price}
												onChange={(e) => {
													const value = e?.target?.value;
													const isPositiveDecimal =
														/^(?:\d+(\.\d*)?|\.\d+)?$/.test(
															value
														);
													if (isPositiveDecimal) {
														handleAdditionalCharges(
															idx,
															e
														);
													}
												}}
											/>
											<div className={styles?.tax_amount}>
												Tax amount
												{': '}
												{((ele?.price *
													ele?.taxPercentage) /
													100)?.toFixed(2)}
											</div>
											{idx > 0 && allowRemoveAdditional && (
												<div
													className={styles.remove_charge}
													onClick={() => {
														handleRemoveAdditionalCharge(idx);
													}}
												>
													<HighlightOff
														className={styles.icon}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							);
						})}
						{allowAddAdditional && (
							<div className={styles.add_additional}>
								<button onClick={addAdditonalCharges} type='button' className={styles.btn}>
									<Add />
									Add Additional Charge
								</button>
							</div>
						)}
					</div>
				)}
				{'taxValues' in calculatedData && (
					<div className={classes(styles.tile)}>
						<p className={styles.title}>Total Tax: </p>
						<div className={styles['milti-gst']}>
							{Object.keys(calculatedData?.taxValues ?? {})?.map(
								(eleKey) => {
									const element =
									calculatedData?.taxValues?.[eleKey];
									return (
										<>
											<p className={styles.value}>
												{eleKey}
												:
												{' '}
												₹
												{addCommas(element)}
											</p>
										</>
									);
								}
							)}
							<p className={styles.value}>
								Total:
								{' '}
								₹
								{addCommas(calculatedData?.totalTax)}
							</p>
						</div>
					</div>
				)}
				<div className={classes(styles.tile, styles?.finalAmount)}>
					<p className={styles.title}>
						Total Amount
					</p>
					<p className={styles.value}>
						₹
						{addCommas(calculatedData?.totalAmount)}
					</p>
				</div>
			</div>
		</div>
	);
}

export default POTotalContainer;
