import { cloneDeep } from 'lodash';
import moment from 'moment';

const initialState = {
	machineName: '',
	macAddress: '',
	store: null,
	process: null,
	manufacturedOn: new Date(),
	installedOn: null,
	expiresOn: null,
	serialNo: null,
	modelNo: null,
	softwareVersion: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = [
	{
		field: 'machineName',
		displayName: 'Machine Name',
	},
	{
		field: 'macAddress',
		displayName: 'MAC Address',
	},
	{
		field: 'modelNo',
		displayName: 'Model Number',
	},
	{
		field: 'serialNo',
		displayName: 'Serial Number',
	},
	{
		field: 'store',
		displayName: 'Store',
	},
	{
		field: 'manufacturedOn',
		displayName: 'Manufactured On',
	},
	{
		field: 'installedOn',
		displayName: 'Installed On',
	},
	{
		field: 'expiresOn',
		displayName: 'Expires On',
	},
];

const config = {
	initialState,
	reducer,
	serializer: (data) => {
		const newState = {
			machineName: data?.machine_name,
			macAddress: data?.mac,
			store: data?.storeId,
			process: data?.processId,
			manufacturedOn: data?.manufactured_on
				? new Date(data?.manufactured_on)
				: null,
			installedOn: data?.installed_on
				? new Date(data?.installed_on)
				: null,
			expiresOn: data?.warranty_expires_on
				? new Date(data?.warranty_expires_on)
				: null,
			serialNo: data?.serial_number,
			modelNo: data?.model_number,
		};
		return newState;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const { softwareVersion, ...remaining } = clonedState ?? {};

		const newState = {
			...remaining,
			manufacturedOn: moment(remaining?.manufacturedOn)?.format(
				'YYYY-MM-DD hh:mm:ss'
			),
			installedOn: moment(remaining?.installedOn)?.format(
				'YYYY-MM-DD hh:mm:ss'
			),
			expiresOn: moment(remaining?.expiresOn)?.format(
				'YYYY-MM-DD hh:mm:ss'
			),
			software_version: softwareVersion?.version,
		};
		return newState;
	},
	validator: (state) => {
		return mandatoryFields?.find((key) => {
			return state[key?.field] === null || state[key?.field] === '';
		});
	},
};

export default config;
