import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const productCategoryServcies = {
	gateway,
	createProductCategory,
	viewProductCategory,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createProductCategory({
				req,
			});
		case 'VIEW':
			return viewProductCategory({
				req,
			});
		case 'LIST':
			return listProductCategory({
				req,
			});
		case 'UPDATE':
			return updateProductCategory({
				req,
			});
		case 'ENABLE':
			return toggleDisableProductCategory({
				req,
			});
		case 'DELETE':
			return deleteProductCategory({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function deleteProductCategory({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PRODUCT_CATEGORY, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function createProductCategory({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.PRODUCT_CATEGORY, requestOptions).then(
		handleResponse
	);
}

function updateProductCategory({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_CATEGORY, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewProductCategory({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_CATEGORY, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listProductCategory({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_CATEGORY, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function toggleDisableProductCategory({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_CATEGORY, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default productCategoryServcies;
