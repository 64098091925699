/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, handleResponse, toQueryStringURL } from '../../Helpers';

const promoCodeServices = {
	gateway,
	listPromoCodes,
	viewPromoCode,
};
function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewPromoCode({
				req,
			});
		case 'LIST':
			return listPromoCodes({
				req,
			});
		case 'UPDATE':
			return updatePromoCode({
				req,
			});
		case 'CREATE':
			return createPromoCode({
				req,
			});
		case 'DELETE':
			return deletePromoCode({
				req,
			});
		default:
			break;
	}
	return new Promise(() => {});
}

function deletePromoCode({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PROMO_CODES, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function createPromoCode({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PROMO_CODES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function updatePromoCode({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PROMO_CODES, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function viewPromoCode({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PROMO_CODES, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listPromoCodes({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PROMO_CODES, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default promoCodeServices;
