/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { useAuth } from '../../../Components/Auth/Login/auth';
import {
	VariantsTable,
	GeneralDetails,
} from '../../../Components/Forms/Variants';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import variantsConfig from './variants.config';

const TABS = ['VARIANTS DETAILS'];

const VariantsViewPage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const {
		fetchVariant,
		data,
		breadcrumbs,
		subCategoriesList,
		fetchSubCategories,
	} = props;

	const [state, setState] = useState({
		activeTab: 0,
		...variantsConfig.initialState,
	});
	const { id } = useRouteMatch().params;

	useEffect(() => {
		fetchVariant({
			entity: 'VARIANTS',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
			showNotification: true,
		});
	}, []);

	useEffect(() => {
		if (data?.categoryId) {
			fetchSubCategories({
				entity: 'PRODUCT_SUB_CATEGORY',
				req: {
					params: {
						categoryId: data?.categoryId,
					},
				},
				method: 'LIST',
			});
		}
	}, [data?.categoryId]);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(
				variantsConfig.serializer(state, {
					...data,
					category: subCategoriesList?.find(
						(ele) => ele?.categoryId === data?.categoryId
					)?.categoryName,
					subCategory: subCategoriesList?.find(
						(ele) => ele?.categoryId === data?.categoryId
					)?.name,
					measurement,
				})
			);
		}
	}, [data, subCategoriesList]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<>
						<GeneralDetails fields={fields} />
						<VariantsTable state={fields} isDisabled />
					</>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Variants'
				handleEdit={redirectToEdit}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${state.fields.name} Variants`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.VARIANTS.data.VIEW;
	const subCategoriesList = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	return {
		data,
		subCategoriesList,
	};
};

const mapDispatchToProps = {
	fetchVariant: formActions.gateway,
	fetchSubCategories: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantsViewPage);
