import { productConstants } from '../Constants';

const initialState = {
	products: {},
	categories: {},
};

function product(state = initialState, action) {
	switch (action.type) {
		case productConstants.FETCH_PRODUCTS_REQUEST:
			return {
				...state,
			};
		case productConstants.FETCH_PRODUCTS_SUCCESS:
			return {
				...state,
				products: action.payload,
			};
		case productConstants.FETCH_PRODUCTS_FAILURE:
			return {
				...state,
			};
		case productConstants.FETCH_CATEGORIES_REQUEST:
			return {
				...state,
			};
		case productConstants.FETCH_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: action.payload,
			};
		case productConstants.FETCH_CATEGORIES_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default product;
