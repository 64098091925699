/* eslint-disable no-tabs */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { ViewPageHeader } from '../../../Components/Headers';
import PromoCodeDetails from '../../../Components/Forms/Promo Code/PromoCodeDetails';
import Validity from '../../../Components/Forms/Promo Code/Validity';
import Applicability from '../../../Components/Forms/Promo Code/Applicability/Applicability';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
// eslint-disable-next-line object-curly-newline
import { generateDisabledProps, history, isEmpty } from '../../../Helpers';
import promoCodeConfig from './promoCodes.config';

const TABS = ['PROMO DETAILS', 'VALIDITY', 'APPLICABILITY'];

const PromoCodeViewPage = (props) => {
	const {
		fetchPromoCodes,
		data,
		deletePromoCodes,
		isDisabled,
		stores,
		users,
		products,
		categories,
		breadcrumbs,
	} = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(promoCodeConfig.initialState.fields),
	});
	const { id } = useRouteMatch().params;
	useEffect(() => {
		Promise.all([
			props.fetchStores({
				entity: 'STORES',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
				promisify: true,
			}),

			props.fetchProductCategory({
				entity: 'PRODUCT_CATEGORY',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
				promisify: true,
			}),
			props.fetchProduct({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
				promisify: true,
			}),
			props.fetchUsers({
				entity: 'USERS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
				promisify: true,
			}),
		])
			.then(() => {
				if (!isEmpty(data) && id === data.id) {
					setState(
						promoCodeConfig.serializer(
							state,
							data,
							stores,
							users,
							products,
							categories,
							id
						)
					);
				} else {
					fetchPromoCodes({
						entity: 'PROMO_CODES',
						req: {
							params: {
								id,
							},
						},
						method: 'VIEW',
					});
				}
			})
			.catch(() => {});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(
				promoCodeConfig.serializer(
					state,
					data,
					stores,
					users,
					products,
					categories,
					id
				)
			);
		}
	}, [data]);
	const { activeTab, fields, errors } = state;
	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<PromoCodeDetails
						mandatoryFields={promoCodeConfig.mandatoryFields}
						isDisabled={isDisabled}
						fields={fields}
						errors={errors}
					/>
				);
			case 1:
				return (
					<Validity
						mandatoryFields={promoCodeConfig.mandatoryFields}
						isDisabled={isDisabled}
						fields={fields}
						errors={errors}
					/>
				);
			case 2:
				return (
					<Applicability
						mandatoryFields={promoCodeConfig.mandatoryFields}
						isDisabled={isDisabled}
						errors={errors}
						fields={fields}
					/>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	const handleDelete = () => {
		const splitURL = url.split('/view');
		deletePromoCodes({
			entity: 'PROMO_CODES',
			req: {
				params: {
					id: state.fields.id,
					couponCode: state.fields.couponCode,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Promos'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				showBreadcrumbs
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.couponCode}`,
					},
				]}
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PROMO_CODES.data.LIST;
	const users = state.form.USERS.data.LIST;
	const categories = state.form.PRODUCT_CATEGORY.data.LIST;
	const products = state.form.ALL_PRODUCTS.data.LIST;
	const stores = state.form.STORES.data.LIST;
	const isStoresFetched = state.form.STORES.isFetched.LIST;
	const isUsersFetched = state.form.USERS.isFetched.LIST;
	const isProductsFetched = state.form.ALL_PRODUCTS.isFetched.LIST;
	const isCategoriesFetched = state.form.PRODUCT_CATEGORY.isFetched.LIST;
	return {
		data,
		stores,
		users,
		products,
		categories,
		isStoresFetched,
		isUsersFetched,
		isProductsFetched,
		isCategoriesFetched,
	};
};

const mapDispatchToProps = {
	fetchPromoCodes: formActions.gateway,
	deletePromoCodes: formActions.gateway,
	fetchStores: formActions.gateway,
	fetchProductCategory: formActions.gateway,
	fetchProduct: formActions.gateway,
	fetchUsers: formActions.gateway,
};
PromoCodeViewPage.defaultProps = {
	isDisabled: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeViewPage);
