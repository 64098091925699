// eslint-disable-next-line object-curly-newline
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { classes } from '../../../Helpers';
import { BaseModal } from '../BaseModal';
import styles from './ContainerModal.module.scss';

const HEADING = 'Select Label';

const initialState = {
	selectedContainerId: null,
};

const ContainerModal = (props) => {
	const { show, data, toggleModal, heading, containers } = props;
	const [state, setState] = useState({
		...initialState,
	});

	useEffect(() => {
		setState({
			...state,
			selectedContainerId: data?.selectedContainer,
		});
	}, [show, data]);

	const handleClose = () => {
		toggleModal(false);
	};

	const handleCancel = () => {
		// // eslint-disable-next-line react/destructuring-assignment
		// props.handleCancel(data.annotationKey, data?.isEdit ?? false);
		handleClose();
	};

	const handleConfirm = () => {
		const selectedContainer = containers?.find((ele) => {
			return ele?.id === state?.selectedContainerId;
		});
		// eslint-disable-next-line react/destructuring-assignment
		props.handleConfirm({
			menuItem: {
				id: selectedContainer?.id,
				label: selectedContainer?.name,
				key: selectedContainer?.name,
			},
			annotationKey: data?.annotationKey,
		});
		handleClose();
	};

	const handleClick = (id, isSelected) => {
		if (isSelected) {
			setState({
				selectedContainerId: null,
			});
		} else {
			setState({
				selectedContainerId: id,
			});
		}
	};

	const renderBtns = () => {
		return (
			state?.selectedContainerId && (
				<div className={`${styles.footer}`}>
					<Button
						variant='outlined'
						// color='secondary'
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleConfirm}
					>
						Submit
					</Button>
				</div>
			)
		);
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleCancel}
			height='30rem'
			width='35rem'
			handleConfirm={handleConfirm}
			renderBtns={renderBtns}
		>
			<div className={styles.containers}>
				{containers?.map((container) => {
					return (
						<div
							key={container?.id}
							onClick={() => {
								handleClick(
									container?.id,
									container?.id ===
										state?.selectedContainerId
								);
							}}
							className={classes(
								styles.container,
								container?.id ===
									state?.selectedContainerId
									? styles.selected
									: ''
							)}
						>
							<img
								src={container?.image}
								alt={container?.name}
								className={styles.image}
							/>
							<div className={styles['name-weight']}>
								<p>{container?.name}</p>
								<p>
									Weight:
									{' '}
									{container?.weight}
									{' '}
									g
								</p>
							</div>
						</div>
					);
				})}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.containerModal;
	const { containers } = state.annotation;
	return {
		show,
		data,
		containers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleContainerModal(show, data));
		},
	};
};

ContainerModal.defaultProps = {
	heading: HEADING,
	handleConfirm: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerModal);
