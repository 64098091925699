/* eslint-disable prefer-template */
/* eslint-disable no-mixed-spaces-and-tabs */
import { connect } from 'react-redux';
import { Button, TextField } from '@mui/material';
import {
	useEffect, useMemo, useState
} from 'react';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { ListTable } from '../../ListTable';
import styles from './ItemsModal.module.scss';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal } from '../AlertModal';
import { addCommas } from '../../../Helpers';
import TransactionDetails from '../POItemsModal/TransactionDetails';

const ACTION_COLUMNS = [];

const COLMAP = {
	Name: 'assetName',
	Dispatched: 'fulfilled',
	Received: 'storeFulfilled',
	Packaged: 'packaged',
	'Ordered Quantity': 'qty',
	Fulfilled: 'fulfilled',
	Returned: 'returned',
	'PO Id': 'poId',
	'Item Price': 'id',
	'Item Sub Total': 'id',
	'Tax Percentage': 'id',
	'Item Tax': 'id',
	'Tax value': 'id',
	'Item Amount': 'id',
};
const TABLE_COLUMNS = [];

const SPECIAL_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ordered Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Fulfilled',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Returned',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Packaged',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatched',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Received',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Price',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Tax Percentage',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Tax value',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Item Amount',
		config: {
			type: 'DATA',
		},
	},
];

const POItemsModal = (props) => {
	const [searchQuery, setSearchQuery] = useState('');
	const { show, data, toggleModal, toggleAlert } = props;
	const {
		items = [],
		heading = 'Items Modal',
		excludedColumns,
		isApprove,
		warningText = false,
		advanceBill = false,
		// type = "",
		showPdf,
		showTotal,
		showPoDetails = false,
		previewFile,
		hideItems = false,
		isAdvanceBill = false,
	} = data;
	const [checkedInvoiceBill, setCheckedInvoiceBill] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [pdfState, setPdfState] = useState('');

	const fetchPdfData = async () => {
		try {
			const response = await inventoryAnalyticsServices.fetchInvTransDoc({
				req: {
					params: {
						transactionId: data?.id,
					},
				},
			});
			if (response?.statusCode === 200) {
				setPdfState(response?.data?.base64);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	const fetchPoPdfData = async () => {
		try {
			const response = await inventoryAnalyticsServices.fetchPoPdfDoc({
				req: {
					params: {
						orderId: data?.id,
					},
				},
			});
			if (response?.statusCode === 200) {
				setPdfState(response?.data?.base64);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		if (!previewFile) {
			if (data?.id && isApprove && data?.uploadedDoc) {
				fetchPdfData();
			}
			if (data?.id && !!data?.poURL) {
				fetchPoPdfData();
			}
		} else {
			setPdfState(previewFile);
		}
	}, [data?.id, data?.poURL, previewFile]);

	const handleClose = () => {
		toggleModal(false, {});
		setPdfState('');
		setCheckedInvoiceBill(false);
	};

	const ITEMS = useMemo(() => {
		return items
			?.filter((item) => {
				if (!searchQuery) {
					return true && item?.qty > 0; // Return true for all items when searchQuery is empty
				}
				return (
					item?.productName
						?.toLowerCase()
						?.includes(searchQuery?.toLowerCase()) && item?.qty > 0
				);
			});
	}, [items, searchQuery]);

	const SPECIAL_ITEMS = {
		Name: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				<div style={{
					textAlign: 'left',
				}}
				>
					<p>{item?.productName}</p>
					<p>{item?.description ? 'Desc.: ' + item?.description : ''}</p>
				</div>
			);
		},
		Type: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			if (item?.assetId) {
				return 'ASSET';
			}
			return 'SERVICE';
		},
		'Ordered Quantity': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemQty = item?.qty;
			if (item?.unit === 'gram') {
				itemQty =
					item?.qty > 1000
						? (item?.qty / 1000)?.toFixed(3)
						: item?.qty;
			}
			return itemQty;
		},
		Packaged: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemPacked = item?.packaged;
			if (item?.unit === 'gram') {
				itemPacked =
					item?.qty > 1000
						? (item?.packaged / 1000)?.toFixed(3)
						: item?.packaged;
			}
			return itemPacked;
		},
		Received: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemReceived = item?.storeFulfilled;
			if (item?.unit === 'gram') {
				itemReceived =
					item?.qty > 1000
						? (item?.storeFulfilled / 1000)?.toFixed(3)
						: item?.storeFulfilled;
			}
			return itemReceived;
		},
		Dispatched: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemFulfilled = item?.fulfilled;
			if (item?.unit === 'gram') {
				itemFulfilled =
					item?.qty > 1000
						? (item?.fulfilled / 1000)?.toFixed(3)
						: item?.fulfilled;
			}
			return itemFulfilled;
		},
		Fulfilled: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemFulfilled = item?.fulfilled;
			if (item?.unit === 'gram') {
				itemFulfilled =
					item?.qty > 1000
						? (item?.fulfilled / 1000)?.toFixed(3)
						: item?.fulfilled;
			}
			return itemFulfilled;
		},
		Returned: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.returned;
		},
		'PO Id': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.poId ?? '-';
		},
		'Total Item Amount': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `₹${addCommas(item?.totalValue ?? item?.finalAmount)}` ?? '-';
		},
		'Item Price': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `₹${item?.productCost ?? item?.price ?? '-'}`;
		},
		'Tax Percentage': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `${item?.taxPercentage}%` ?? '-';
		},
		'Tax value': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `₹${addCommas(item?.productTaxValue)}` ?? '-';
		},
	};

	const isDisabled = useMemo(() => {
		if (advanceBill) {
			return !checkedInvoiceBill;
		}
		return !pdfState || !checkedInvoiceBill;
	}, [pdfState, checkedInvoiceBill, advanceBill]);

	const renderBtns = () => {
		return (
			isApprove && (
				<div className={styles['btn-container']}>
					<div className={styles['terms-conditions']}>
						{warningText ? (
							<label
								style={{
									color: 'red',
								}}
								htmlFor='termsCheckbox'
							>
								{data?.warningText}
							</label>
						) : (
							<>
								<input
									id='termsCheckbox'
									className={styles.checkbox}
									type='checkbox'
									checked={checkedInvoiceBill}
									onClick={() => {
										setCheckedInvoiceBill(
											!checkedInvoiceBill
										);
									}}
								/>
								<label htmlFor='termsCheckbox'>
									{data?.approvalText}
								</label>
							</>
						)}
					</div>
					<div className={styles.btns}>
						<Button
							fullWidth
							type='button'
							variant='contained'
							color='primary'
							disabled={isDisabled}
							onClick={() => {
								props.handleConfirm(data);
								setCheckedInvoiceBill(false);
								setPdfState('');
							}}
						>
							Approve
						</Button>
						<Button
							fullWidth
							type='button'
							variant='contained'
							color='error'
							onClick={() => {
								if (props.handleReject) {
									toggleAlert(true, {
										handleConfirm: (rejectionReason) => {
											props?.handleReject(
												data,
												rejectionReason
											);
											handleClose();
											toggleAlert(false, {});
										},
										heading: `Reject #${data?.clientPoId ?? data?.clientTransactionId}`,
										takeInput: true,
										confirmText: 'Reject',
										height: 'fit-content',
										width: '27.5rem',
										message: 'Please Give reason to Reject',
										inputLabel: 'Enter Reason',
										colorReverse: true,
										rendererType: 'poItemsModal',
									});
									// props.handleReject(data);
								}
							}}
						>
							Reject
						</Button>
					</div>
				</div>
			)
		);
	};

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalPrice = 0;
		let taxOnPackagingCharges = 0;
		let taxOnDeliveryCharges = 0;
		const additionalCharges = {};
		if (data?.packagingCharges) {
			taxOnPackagingCharges = data?.packagingCharges * (data?.packagingTaxPercentage / 100);
		}
		if (data?.deliveryCharges) {
			taxOnDeliveryCharges = data?.deliveryCharges * (data?.deliveryTaxPercentage / 100);
		}
		totalTax += (taxOnDeliveryCharges + taxOnPackagingCharges);
		totalPrice += (
			(data?.deliveryCharges + taxOnDeliveryCharges) +
			(data?.packagingCharges + taxOnPackagingCharges)
		);
		data?.jsonData?.additionalCharges?.forEach((ele) => {
			// eslint-disable-next-line no-prototype-builtins
			if (additionalCharges?.hasOwnProperty(ele?.type)) {
				additionalCharges[ele?.type] += Number(ele?.price)?.toFixed(2);
				additionalCharges['Tax on ' + ele?.type] += Number(
					(ele?.price * ele?.taxPercentage) / 100
				)?.toFixed(2);
			} else {
				additionalCharges[ele?.type] = Number(ele?.price)?.toFixed(2);
				additionalCharges['Tax on ' + ele?.type] = Number(
					(ele?.price * ele?.taxPercentage) / 100
				)?.toFixed(2);
			}
			totalTax += Number((ele?.price * ele?.taxPercentage) / 100);
			totalPrice += Number(ele?.price * (1 + (ele?.taxPercentage / 100)));
		});

		items?.forEach((ele) => {
			if (ele?.qty > 0) {
				if (ele?.finalValue) {
					totalPrice += ele?.finalValue;
					subTotal += ele?.finalValue - ele?.productTaxValue;
					totalTax += ele?.productTaxValue;
				} else {
					if (ele?.finalAmount) {
						totalPrice += ele?.finalAmount;
					}
					const itemSubTotal =
						ele?.finalAmount / (1 + ele?.taxPercentage / 100);
					if (!ele?.subTotal) {
						subTotal += itemSubTotal;
					}
					if (!ele?.taxValue) {
						totalTax += ele?.finalAmount - itemSubTotal;
					}
				}
			}
		});
		return {
			'Sub Total:': subTotal,
			'Packaging: ': data?.packagingCharges,
			'Tax On Packaging: ': taxOnPackagingCharges,
			'Delivery: ': data?.deliveryCharges,
			'Tax On Delivery: ': taxOnDeliveryCharges,
			'Additional Charges': additionalCharges,
			'Tax:':
				data?.taxType === 'STATE'
					? {
						SGST: (totalTax / 2)?.toFixed(2),
						CGST: (totalTax / 2)?.toFixed(2),
						Total: totalTax?.toFixed(2),
					  }
					: {
						IGST: totalTax?.toFixed(2),
						Total: totalTax?.toFixed(2),
					  },
			'Total Price: ': totalPrice,
		};
	}, [items]);

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			renderBtns={renderBtns}
			width={data?.width ?? '60rem'}
			height={data?.height ?? '30rem'}
		>
			<AlertModal type='poItemsModal' />
			{isAdvanceBill && <p className={styles.advance}>Advance Bill</p>}
			<div className={styles.root}>
				{showPdf && pdfState && (
					<div className={styles.lhs}>
						<iframe
							className={styles.iframe}
							width='100%'
							height='100%'
							src={pdfState}
							title='PDF Rendered from Base64'
						/>
					</div>
				)}
				<div className={styles.rhs}>
					{showPoDetails && (
						<TransactionDetails
							transactionData={data?.transactionData}
						/>
					)}
					{!hideItems && (
						<div className={styles.items}>
							<TextField
								fullWidth
								value={searchQuery}
								onChange={(e) => {
									setSearchQuery(e?.target?.value);
								}}
								placeholder='Search Item'
							/>
							<div className={styles.list}>
								<ListTable
									colMap={COLMAP}
									columns={TABLE_COLUMNS}
									data={ITEMS}
									exportFilename={heading?.split(' ')?.join('_')}
									showTotal
									actions={[
										...SPECIAL_COLUMNS,
										...ACTION_COLUMNS,
									]?.filter((col) => {
										return !(
											excludedColumns ?? [
												'Fulfilled',
												'From Process',
												'PO Id',
											]
										)?.includes(col?.title);
									})}
									special={SPECIAL_ITEMS}
								/>
							</div>
						</div>
					)}
					{showTotal && (
						<div className={styles['total-container']}>
							{Object.keys(calculatedData ?? {})?.map((ele) => {
								const dataItem = calculatedData?.[ele];
								const isObject =
									typeof dataItem === 'object' &&
									dataItem !== null;
								return (
									<div key={ele} className={styles.total_tile}>
										<label>
											{ele}
										</label>
										{isObject ? (
											<div
												className={styles['multi-tax']}
											>
												{Object.keys(
													dataItem ?? {}
												)?.map((innerKey) => {
													const innerDataItem =
														dataItem?.[innerKey];
													return (
														<div
															className={
																styles.total_tile
															}
														>
															<label>
																{innerKey}
																:
															</label>
															<p>
																₹
																{innerDataItem}
															</p>
														</div>
													);
												})}
											</div>
										) : (
											<p>
												₹
												{Number(dataItem ?? 0)?.toFixed(
													2
												)}
											</p>
										)}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.itemsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleItemsModal,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(POItemsModal);
