/* eslint-disable no-empty */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded';
import { Button } from '@mui/material';
import styles from './similarProducts.module.scss';
import similarProductsActions from '../../Actions/similarProducts.actions';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { AddGroupModal } from '../../Components/Modals/AddGroupModal';
import { modalActions, formActions } from '../../Actions';
import { BackdropLoader } from '../../Components/Loaders';
import { notificationServices } from '../../Services';
import { ProductCard } from '../../Components/Forms';
import similarProductsServices from '../../Services/similarProducts.services';

function App(props) {
	const {
		data,
		similarProducts,
		userPermissions,
		fetchProducts,
		toggleAddGroupModal,
		productsList,
	} = props;
	const [list1, setlist] = useState([]);
	const [list2, setlist2] = useState([]);
	const [query, setQuery] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isGroup, setIsGroup] = useState(false);
	const [changesMade, setChangesMade] = useState(false);
	useEffect(() => {
		similarProducts();
		// allProducts({ gradeGroupId: 2 });
		// groupProducts({ gradeGroupId: 2 });
	}, []);

	useEffect(() => {
		if (data?.Products) {
			setlist(
				data?.Products?.map((ele) => {
					const currentProduct = productsList?.find((product) => {
						return product.id === ele.productId;
					});
					// console.log('prince', currentProduct);
					return {
						...ele,
						productImageUrl: currentProduct?.productImageUrl,
					};
				})
			);
		}
	}, [data?.Products]);

	useEffect(() => {
		if (data?.groupProducts) {
			setlist2(
				data?.groupProducts?.map((ele) => {
					const currentProduct = productsList?.find((product) => {
						return product.id === ele.productId;
					});
					// console.log('prince', currentProduct);
					return {
						...ele,
						productImageUrl: currentProduct?.productImageUrl,
					};
				})
			);
		}
	}, [data?.groupProducts]);

	const FIND_FILTERS = ['group', 'productExclusive'];

	function filterProducts(gradeGroupId) {
		setIsGroup(gradeGroupId);
		setlist(() => {
			return productsList?.filter((ele) => {
				return ele?.gradeGroupId === null;
			});
		});
		setlist2(() => {
			return productsList?.filter((ele) => {
				return ele?.gradeGroupId === gradeGroupId;
			});
		});
	}

	const handleGroupsFetch = async (req) => {
		setIsLoading(true);
		try {
			// console.log('this', req);
			// setIsProduct(req.product);
			if (req.group) {
				filterProducts(req.group);
			} else if (req.productExclusive) {
				const product = productsList.find((ele) => {
					return ele.id === req.productExclusive;
				});
				const gradeGroupId = product ? product.gradeGroupId : null;
				if (gradeGroupId === null) {
					notificationServices.generateNotification({
						type: 'warning',
						message: 'This product does not have a group.',
					});
					setlist([]);
					setlist2([]);
					setChangesMade(false);
				} else {
					filterProducts(gradeGroupId);
				}
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		} finally {
			setIsLoading(false);
			if (!req.group && !req.productExclusive) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Please Select Group or Product',
				});
				setlist([]);
				setlist2([]);
				setChangesMade(false);
			}
		}
	};

	const getGroupName = (groupId) => {
		const group = data?.similarProducts.find(
			(item) => { return item.groupId === groupId; }
		);
		return group ? group.groupName : '';
	};

	const handleLeftClick = (ele) => {
		const updatedList2 = [...list2, ele];
		setlist2(updatedList2);
		const updatedList1 = list1.filter((item) => { return item !== ele; });
		setlist(updatedList1);
		setChangesMade(true);
	};

	const handleRightClick = (ele) => {
		const updatedList1 = [...list1, ele];
		setlist(updatedList1);
		const updatedList2 = list2.filter((item) => { return item !== ele; });
		setlist2(updatedList2);
		setChangesMade(true);
	};

	const handleAddClick = () => {
		toggleAddGroupModal(true, {});
	};

	const handleAddGroup = async (groupName) => {
		try {
			const response = await similarProductsServices.createGroup(
				groupName
			);
			if (response.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: 'Group created successfully!',
				});
				similarProducts();
			}
		} catch (_) {}
	};

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			// console.log('prince', list2);
			// console.log('groupPrince', isGroup);
			const response = await similarProductsServices.updateGroup(
				isGroup,
				list2.map((item) => {
					return {
						productId: item.id,
					};
				})
			);
			if (response.statusCode === 200) {
				const successMessage = isGroup
					? `${getGroupName(isGroup)} updated successfully!`
					: 'Group created successfully!';
				notificationServices.generateNotification({
					type: 'success',
					message: successMessage,
				});
				fetchProducts({
					entity: 'ALL_PRODUCTS',
					req: {
						params: {
							id: '',
							brandId: '',
						},
					},
					method: 'LIST',
				});
			}
			// if (response.ok) {
			// console.log('List2 submitted successfully');
			// } else {
			// console.log('Failed to submit list2');
			// }
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		} finally {
			setIsLoading(false);
			// setlist([]);
			// setlist2([]);
			setChangesMade(false);
		}
	};

	const handleSearch = (e) => {
		setQuery(e?.target?.value);
	};

	return (
		<div className='App styles'>
			<AddGroupModal
				onSubmit={handleAddGroup}
			/>
			<BackdropLoader showProgress isLoading={isLoading} />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Similar Product Groups'
					FILTERS={FIND_FILTERS}
					onSubmit={handleGroupsFetch}
					// groups={[
					// { groupId: 1, groupName: 'Dip' },
					// { groupId: 2, groupName: 'Mango' },
					// ]}
					groups={data?.similarProducts}
					conditionalFilters={['group', 'productExclusive']}
					// products={productsList}
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'Group',
						handleAddClick,
					}}
				/>
			</div>

			{(list1.length > 0 || list2.length > 0) && (
				<div
					style={{
						justifyContent: 'space-between',
						display: 'flex',
						height: '400px',
						width: '100%',
						gap: '1rem',
						margin: 'auto',
						alignItems: 'center',
						// pointerEvents: 'none',
						// border: '1px solid black',
					}}
				>
					<div
						style={{
							// width: '45%',
							height: '100%',
							border: '2px solid white',
							backgroundColor: '#A7ADC6',
							borderRadius: '8px',
							// margin: 'auto',
							flex: 1,
						}}
					>
						<div className='SimilarProducts'>
							<div className='group-box'>
								<div
									style={{
										fontSize: '18px',
										fontWeight: 'bold',
										color: '#333',
										margin: '10px',
										textAlign: 'center',
									}}
								>
									Product(s) NOT IN
									{isGroup
										? ` ${getGroupName(isGroup)}`
										: ' GROUP'}
								</div>
							</div>
						</div>
						<div className={styles.search_product_container}>
							<input
								placeholder='Search Product'
								className={styles.search}
								type='text'
								value={query}
								onChange={handleSearch}
							/>
							<div
								style={{
									maxHeight: '300px',
									overflow: 'auto',
									padding: '10px',
									display: 'grid',
									gridTemplateColumns: 'repeat(3, 1fr)',
									// display: 'flex',
									// flexWrap: 'wrap',
									width: '100%',
									gap: '.5rem',
								}}
							>
								{list1.filter((ele) => {
									return ele?.productName?.toLowerCase()?.includes(query?.toLowerCase());
								})?.map((ele) => {
									return (
										<span
											onClick={() => { return handleLeftClick(ele); }}
										>
											<ProductCard
												className={styles.card}
												title={ele.productName}
												img={ele?.productImageUrl}
												isDisabled
											/>
										</span>
									);
								})}
							</div>
						</div>
					</div>
					<MultipleStopRoundedIcon sx={
						{
							fontSize: 50,
						}
					}
					/>
					<div
						style={{
							// width: '45%',
							height: '100%',
							border: '2px solid white',
							backgroundColor: '#94A187',
							borderRadius: '8px',
							margin: 'auto',
							flex: 1,
						}}
					>
						<div className='SimilarProducts'>
							<div className='group-box'>
								<div
									style={{
										fontSize: '18px',
										fontWeight: 'bold',
										color: '#333',
										margin: '10px',
										textAlign: 'center',
									}}
								>
									Product(s) In
									{isGroup
										? ` ${getGroupName(isGroup)}`
										: ' GROUP'}
								</div>
							</div>
						</div>
						<div
							style={{
								maxHeight: '350px',
								overflow: 'auto',
								padding: '10px',
								display: 'grid',
								gridTemplateColumns: 'repeat(3, 1fr)',
								// display: 'flex',
								// flexWrap: 'wrap',
								gap: '.5rem',
							}}
						>
							{list2?.map((ele) => {
								return (
									<span
										onClick={() => { return handleRightClick(ele); }}
									>
										<ProductCard
											className={styles.card}
											title={ele.productName}
											img={ele?.productImageUrl}
											isDisabled
										/>
									</span>
								);
							})}
						</div>
					</div>
				</div>
			)}
			{changesMade && (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						onClick={handleSubmit}
						variant='contained'
						color='primary'
					>
						Submit
					</Button>
				</div>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;

	const data = state.similarProducts;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	return {
		data,
		userPermissions,
		productsList,
	};
};

const mapDispatchToProps = {
	similarProducts: similarProductsActions.fetchSimilarProducts,

	toggleAddGroupModal: modalActions.toggleAddGroupModal,
	fetchProducts: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
