const namingOfMachineType = {
	APP: 'App (Product)',
	MACHINE: 'Machine (Product)',
	ASSET: 'Asset',
};

const labelGeneratorConfig = {
	namingOfMachineType,
};

export default labelGeneratorConfig;
