/* eslint-disable prefer-template */
import { getISOWeek, getWeekOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { analyticsAction, formActions } from '../../Actions';
import { BackdropLoader } from '../../Components/Loaders';
import comingSoon from '../../Images/cookingLottie.json';
import { timeFilterMap } from '../../Constants';
import { classes, getMonthNumber } from '../../Helpers';
import styles from './NewDashboard.module.scss';
import DashboardNav from './DashboardNav/DashboardNav';
import HighlightTilesContainer from './HighlightTilesContainer/HighlightTilesContainer';
import TrendTilesContainer from './TrendTilesContainer/TrendTilesContainer';
import FilterSelector from './FilterSelector/FilterSelector';
import dashboardAnalyticsService from '../../Services/DashbaordAnalyticsServices/dashboardAnalytics.services';

function getDateFilter(selection) {
	let filterString = `${selection?.year}`;
	if (selection?.day) {
		filterString =
			selection?.day + '-' + selection?.month + '-' + selection?.year;
	}
	if (!selection?.day && selection?.month) {
		filterString = selection?.month + '-' + selection?.year;
	}
	return filterString;
}

function Dashboard(props) {
	const {
		fetchProducts,
		dateRangeFilter,
		setDateRangeFilter,
		productsList,
		isLoading,
		fetchStores,
		stores,
	} = props;
	const [periodFilter, setPeriodFilter] = useState('THIS_MONTH');
	const [selectedTab, setSelectedTab] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [activeDateClick, setActiveDateClick] = useState(false);
	const [isLocalLoading, setIsLocalLoading] = useState(false);
	const [dashboardData, setDashboardData] = useState({});
	const [filters, setFilters] = useState({
		selectedStore: {
			id: -999,
			name: 'All',
		},
		DATE_FILTER: {
			text: timeFilterMap.month[new Date().getUTCMonth()],
			active: true,
			type: 'month',
			selection: {
				year: new Date().getFullYear(),
				month: timeFilterMap.month[new Date().getUTCMonth()],
				week: null,
				day: null,
				hour: null,
			},
		},
	});

	useEffect(() => {
		const currentDate = moment();
		const isFirstDayOfMonth = currentDate.date() === 1;

		if (isFirstDayOfMonth) {
			const previousMonth = currentDate.subtract(1, 'months');
			const monthText = timeFilterMap.month[previousMonth.month()];
			const year = previousMonth.year();

			setFilters((prevFilters) => ({
				...prevFilters,
				DATE_FILTER: {
					...prevFilters.DATE_FILTER,
					text: monthText,
					selection: {
						...prevFilters.DATE_FILTER.selection,
						year,
						month: monthText,
					},
				},
			}));
		}
	}, []);

	const fetchDashboard = async () => {
		const storeFilter =
			filters?.selectedStore?.id && filters?.selectedStore?.id !== -999;
		const req = {
			data: {
				sectionId: selectedTab?.id,
				storeFilter: storeFilter ? [filters?.selectedStore?.id] : [],
				dateFilter: {
					...filters?.DATE_FILTER?.selection,
					month: getMonthNumber(filters?.DATE_FILTER?.selection?.month),
				},
			},
		};
		setIsLocalLoading(true);
		try {
			const response = await dashboardAnalyticsService.fetchDashboardData(
				req
			);
			if (response?.responseCode === 'SS-001') {
				setDashboardData(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLocalLoading(false);
		}
	};

	useEffect(() => {
		if (selectedTab?.id) {
			fetchDashboard();
		}
	}, [selectedTab, filters?.selectedStore?.id, filters?.DATE_FILTER]);

	useEffect(() => {
		document.addEventListener('fullscreenchange', fetchDashboard);

		return () => {
			document.removeEventListener(
				'fullscreenchange',
				fetchDashboard
			);
		};
	}, []);

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					brandId: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			setDateRangeFilter({
				...dateRangeFilter,
				active: false,
			});
			// setDateRangeFilter({});
		};
	}, []);

	useEffect(() => {
		fetchStores({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handlePeriodFilterChange = (fieldValue, selectedValue) => {
		let tempFilters = null;
		setDateRangeFilter({
			...dateRangeFilter,
			active: false,
		});
		switch (fieldValue) {
			case 'TODAY':
				// setLocalStorage(localStorageProperty.daily);
				// eslint-disable-next-line no-case-declarations
				const date = new Date(`${selectedValue?.year}-${selectedValue?.month + 1}-${selectedValue?.day}`);
				// eslint-disable-next-line no-case-declarations, max-len
				const selectedDay = selectedValue?.day !== undefined ? date?.getDate() : new Date().getDate();
				// eslint-disable-next-line no-case-declarations
				const selectedMonth =
					selectedValue?.month !== undefined
						? timeFilterMap.month[selectedValue?.month]
						: timeFilterMap.month[new Date().getUTCMonth()];
				// eslint-disable-next-line no-case-declarations
				const selectedDateYear = selectedValue?.year
					? selectedValue?.year
					: new Date().getFullYear();
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getDate(),
						active: true,
						type: 'day',
						selection: {
							year: selectedDateYear,
							month: selectedMonth,
							week: getWeekOfMonth(date),
							day: selectedDay,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_YEAR':
				// setLocalStorage(localStorageProperty.daily);
				// eslint-disable-next-line no-case-declarations
				const year = selectedValue?.year
					? selectedValue?.year
					: new Date().getFullYear();
				tempFilters = {
					DATE_FILTER: {
						text: year,
						active: true,
						type: 'year',
						selection: {
							year,
							month: null,
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_WEEK':
				// setLocalStorage(localStorageProperty.weekly);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getFullYear(),
						active: true,
						type: 'week',
						selection: {
							year: new Date().getFullYear(),
							month: null,
							week: getISOWeek(new Date()),
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_MONTH':
				// setLocalStorage(localStorageProperty.monthly);
				// eslint-disable-next-line no-case-declarations
				const month =
					selectedValue?.month !== undefined
						? timeFilterMap.month[selectedValue?.month]
						: timeFilterMap.month[new Date().getUTCMonth()];
				// eslint-disable-next-line no-case-declarations
				const selectedYear = selectedValue?.year
					? selectedValue?.year
					: new Date().getFullYear();
				tempFilters = {
					DATE_FILTER: {
						text: month,
						active: true,
						type: 'month',
						selection: {
							year: selectedYear,
							month,
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'CUSTOM':
				// setLocalStorage(localStorageProperty.custom);
				setDateRangeFilter({
					...dateRangeFilter,
					active: true,
				});
				setActiveDateClick(false);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getDate(),
						active: false,
						type: 'day',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: getWeekOfMonth(new Date()),
							day: new Date().getDate(),
							hour: null,
						},
					},
				};
				break;
			default:
				break;
		}
		setPeriodFilter(fieldValue);
		if (tempFilters) {
			setFilters({
				...filters,
				...tempFilters,
			});
		}
	};

	const handleSelectStore = (store) => {
		setFilters({
			...filters,
			selectedStore: store,
		});
	};
	return (
		<div
			style={{
				minHeight: `${window?.innerHeight - 50}px`,
			}}
			className={classes(styles.root)}
		>
			<BackdropLoader
				showProgress
				isLoading={isLoading || isLocalLoading}
			/>
			<DashboardNav
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
			/>
			<div
				style={{
					borderColor: selectedTab?.color,
					backgroundColor: selectedTab?.color + '55',
				}}
				className={classes(
					styles['data-box-container'],
					styles[periodFilter]
				)}
			>
				<div className={styles['default-selected']}>
					<p>
						Store:
						{' '}
						{filters?.selectedStore?.name?.length > 10
							? filters?.selectedStore?.name?.substring(0, 12) +
							'...'
							: filters?.selectedStore?.name}
					</p>
					<div className={styles.divider} />
					<p>{getDateFilter(filters?.DATE_FILTER?.selection)}</p>
				</div>
				{selectedTab?.isAvailaible ? (
					<>
						{selectedTab?.disableText && (
							<div className={styles['disabled-water-mark']}>
								<div className={styles.rotate}>
									{selectedTab?.disableText}
								</div>
							</div>
						)}
						<HighlightTilesContainer
							storeFilter={filters?.selectedStore?.id}
							dateFilter={{
								...filters?.DATE_FILTER?.selection,
								month: getMonthNumber(
									filters?.DATE_FILTER?.selection?.month
								),
							}}
							data={dashboardData?.highlights}
							showLink={['COGS', 'INV_MAT', 'INV_PROD', 'AUDIT', 'QC'].includes(selectedTab?.type)}
							selectedTab={selectedTab}
						/>
						{/* <SectionDivider heading='Section Divided' /> */}
						<TrendTilesContainer
							data={dashboardData?.charts}
							productsList={productsList}
						/>
					</>
				) : (
					<div className={styles['coming-soon']}>
						<Lottie
							style={{
								width: '50%',
								height: '60%',
							}}
							animationData={comingSoon}
							loop
						/>
						<p>Coming Soon</p>
					</div>
				)}
			</div>
			<FilterSelector
				filters={filters}
				setSelectedStore={handleSelectStore}
				stores={stores}
				selectedPeriod={periodFilter}
				handlePeriod={handlePeriodFilterChange}
			/>
		</div>
	);
}

const mapStateToProps = (state, componentProps) => {
	const { userPermissions } = state.navigation;
	const { noStoreForAiDashboardRoute } = componentProps;
	const { data, isLoading } = noStoreForAiDashboardRoute
		? state?.analytics.AI_DASHBOARD_ANALYTICS
		: state.analytics.DASHBOARD_ANALYTICS;
	const productsList = state.form.ALL_PRODUCTS.data.LIST;
	const stores = state.form.STORES.data.LIST;
	const dateFilterData = state.analytics.DATE_FILTER;
	const { DATE_RANGE_FILTER: dateRangeFilter, STORE_FILTER: storeFilter } =
		state.analytics;

	return {
		data,
		isLoading,
		productsList,
		dateFilterData,
		dateRangeFilter,
		stores,
		storeFilter,
		userPermissions,
	};
};

const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	applyFilters: analyticsAction.applyFilters,
	resetFilters: analyticsAction.resetFilters,
	fetchProducts: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchDashboardData: analyticsAction.fetchData,
	setStoreFilter: analyticsAction.setStoreFilter,
	fetchStores: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
