import { connect } from 'react-redux';
import { Crop } from '@mui/icons-material';
import { modalActions } from '../../../../Actions';
import {
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper,
} from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import EnableDisable from '../../EnableDisable/EnableDisable';
import { IconInput } from '../../Inputs';
import styles from './Payment.module.scss';

const Payment = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		isDisabled,
		handleImageInput,
		toggleImageCrop,
	} = props;
	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* Packaging charge */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='packagingCharge'
						data-mandatory={
							fields.enableSection.payment &&
							mandatoryFields.packagingCharge
						}
					>
						Packaging charge
					</label>

					<IconInput
						size='large'
						type='rupee'
						name='packagingCharge'
						id='packagingCharge'
						handleInput={handleInput}
						dataType='packagingCharge'
						value={fields.packagingCharge}
						dataAttrs={{
							required:
								fields.enableSection.payment &&
								mandatoryFields.packagingCharge,
						}}
					/>
					<span className='form-error'>{errors.packagingCharge}</span>
				</div>
				{/* min cart value */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='minCart'
						data-mandatory={
							fields.enableSection.payment &&
							mandatoryFields.minCart
						}
					>
						Min Cart Value
					</label>

					<IconInput
						size='large'
						type='rupee'
						name='minCart'
						id='minCart'
						handleInput={handleInput}
						dataType='minCart'
						value={fields.minCart}
						required={
							fields.enableSection.payment &&
							mandatoryFields.minCart
						}
					/>
					<span className='form-error'>{errors.minCart}</span>
				</div>
				{/* Max Cart Value */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='maxCart'
						data-mandatory={
							fields.enableSection.payment &&
							mandatoryFields.maxCart
						}
					>
						Max Cart Value
					</label>
					<IconInput
						size='large'
						type='rupee'
						name='maxCart'
						id='maxCart'
						max={100000}
						handleInput={handleInput}
						dataType='maxCart'
						value={fields.maxCart}
						required={
							fields.enableSection.payment &&
							mandatoryFields.maxCart
						}
					/>
					<span className='form-error'>{errors.maxCart}</span>
				</div>
				{/* Offline */}
				<div className='form-field row'>
					<EnableDisable
						name='offline'
						text='Offline Payment'
						isEnabled={fields.offline}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'offline',
						}}
					/>
					<span className='form-error'>{errors.offline}</span>
				</div>
				{/* Online */}
				<div className='form-field row'>
					<EnableDisable
						name='online'
						text='Online Payment'
						isEnabled={fields.online}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'online',
						}}
					/>
					<span className='form-error'>{errors.online}</span>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* QRcode */}
				{fields.online ? (
					<div
						className='form-field row'
						style={{
							alignItems: 'flex-start',
						}}
					>
						<label
							className='form-label'
							data-mandatory={fields.online}
						>
							QR Code
						</label>
						<div className='display-pic-root'>
							<div className='display-pic-preview'>
								<img
									src={
										isDisabled
											? fields.QRcode
											: generateImageUrl(fields.QRcode)
									}
									className={`display-pic ${
										fields.QRcode ? 'show' : 'hide'
									}`}
									alt='QRcode-display'
								/>
								{generateImageEditButton({
									isEdit:
										fields.QRcode &&
										fields.QRcode.includes(
											'data:image/jpeg;base64'
										),
									button: (
										<button
											type='button'
											onClick={() => {
												openImageCropModal('image');
											}}
											className='display-pic-edit'
										>
											<Crop />
										</button>
									),
								})}
							</div>
							{generateImageUploadButton({
								isDisabled,
								label: (
									<label
										className='display-pic-label'
										htmlFor='displayPic'
									>
										Choose an image
									</label>
								),
							})}
							<input
								type='file'
								id='displayPic'
								accept='image/png, image/jpg, image/jpeg, image/webp'
								name='QRcode'
								onChange={handleImageSelect}
								style={{
									opacity: 0,
									width: 1,
									height: 1,
								}}
								{...generateRequiredProps(
									typeof fields.QRcode !== 'string' &&
										fields.online
								)}
								// {...generateRequiredProps(
								// eslint-disable-next-line no-tabs
								// 	mandatoryFields.QRcode
								// )}
							/>
						</div>
					</div>
				) : (
					''
				)}
				<ImageCropModal
					handleConfirm={handleImageInput}
					aspectRatio='3:4'
				/>
			</div>
		</div>
	);
};

Payment.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleImageInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = () => {};

const mapDispatchToProps = {
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
