/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line object-curly-newline
import { AddCircle, ArrowDropDown, Close } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { classes, convertWeightUnit } from '../../Helpers';
import styles from './CustomInputSelector.module.scss';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Input } from '../ui/input';

const renderVariantSelector = (
	ele,
	addedItems,
	handleVariantSelection,
	skuStockInfo
) => {
	return (
		<div className={styles.variants}>
			{ele?.variants?.map((variant) => {
				const alreadyAdded = addedItems?.find((poItem) => {
					return poItem?.id === variant?.id;
				});
				return (
					!alreadyAdded && (
						<div
							onClick={() => {
								handleVariantSelection(variant, ele);
							}}
							className={styles.variantInfoContainer}
						>
							<div className={styles.variant}>
								{variant?.displayName}
							</div>
							{skuStockInfo?.map((infoEle) => {
								if (!infoEle?.showInDropDown) {
									return <></>;
								}
								return (
									<span
										className={styles?.quantities}
										style={{
											backgroundColor: infoEle?.bgColor,
											color: infoEle?.color,
										}}
									>
										<span className={styles?.title}>
											{infoEle?.name}
											:
										</span>
										<span className={styles?.value}>
											{convertWeightUnit(
												infoEle?.values?.[
													variant?.id
												] ?? 0,
												ele?.unit
											)}
										</span>
									</span>
								);
							})}
						</div>
					)
				);
			})}
		</div>
	);
};

function CustomInputSelector(props) {
	const {
		inputLists,
		placeholder,
		width,
		handleSelection,
		selectedValue,
		addNewText,
		skuStockInfo,
		classname,
		handleAddNew = () => {},
		handleKeyPress = () => {},
		handleRemove = () => {},
		showImage = false,
		addedItems = [],
		hideAddNew = false,
		allowRemove = true,
		inputName = '',
		disabled = false,
		required = false,
		inputMin,
	} = props;
	const [searchQuery, setSearchQuery] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const inputRef = useRef(null);

	const handleChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleVariantSelection = (variant, product) => {
		setShowDropdown(false);
		handleSelection({
			...product,
			selectedVariant: variant,
		});
	};

	function areAllVariantsAdded(variants, objectsArray) {
		return variants.every((variant) => {
			return objectsArray.some((object) => {
				return object.id === variant?.id;
			});
		});
	}

	const hideDropdown = () => {
		setShowDropdown(false);
	};

	useEffect(() => {
		if (document.activeElement === inputRef.current) {
			setShowDropdown(true);
		}
	}, [document.activeElement]);

	return (
		// <div classname={classname}>
		// 	<DropdownMenu>
		// 		<DropdownMenuTrigger>
		// 			<Input
		// 				name={inputName}
		// 				required={required}
		// 				className={styles['search-input']}
		// 				placeholder={placeholder}
		// 				value={selectedValue ?? searchQuery}
		// 				onChange={handleChange}
		// 				onKeyDown={handleKeyPress}
		// 				autoComplete='off'
		// 				min={inputMin}
		// 				onFocus={() => {
		// 					setShowDropdown(true);
		// 				}}
		// 				ref={inputRef}
		// 			/>
		// 		</DropdownMenuTrigger>
		// 		<DropdownMenuContent className='z-[9999]'>
		// 			{!hideAddNew && (
		// 				<DropdownMenuItem
		// 					className={styles['dropdown-item']}
		// 					onClick={() => {
		// 						setShowDropdown(false);
		// 						handleAddNew();
		// 					}}
		// 				>
		// 					<AddCircle className={styles.icon} />
		// 					<p>{addNewText}</p>
		// 				</DropdownMenuItem>
		// 			)}
		// 			{inputLists
		// 				?.filter((ele) => {
		// 					return (
		// 						ele?.name
		// 							?.toLowerCase()
		// 							?.indexOf(
		// 								searchQuery?.toLowerCase()?.trim()
		// 							) !== -1
		// 					);
		// 				})
		// 				?.map((ele) => {
		// 					return (
		// 						<DropdownMenuItem
		// 							onClick={() => {
		// 								if (!ele?.variants?.length > 0) {
		// 									setShowDropdown(false);
		// 									handleSelection(ele);
		// 								}
		// 							}}
		// 							style={
		// 								!ele?.variants?.length > 0
		// 									? {
		// 											cursor: 'pointer',
		// 									  }
		// 									: {}
		// 							}
		// 							className={styles['dropdown-item']}
		// 						>
		// 							{showImage && ele?.imageUrl && (
		// 								<img src={ele?.imageUrl} alt='Option' />
		// 							)}
		// 							<div
		// 								className={styles['details-container']}
		// 							>
		// 								<p>{ele?.name ?? ele?.title}</p>
		// 								{ele?.variants?.length > 0 && (
		// 									<div>
		// 										{!areAllVariantsAdded(
		// 											ele?.variants,
		// 											addedItems
		// 										)
		// 											? renderVariantSelector(
		// 													ele,
		// 													addedItems,
		// 													handleVariantSelection,
		// 													skuStockInfo
		// 											  )
		// 											: 'Item Already Added'}
		// 									</div>
		// 								)}
		// 							</div>
		// 						</DropdownMenuItem>
		// 					);
		// 				})}
		// 			{/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
		// 			{/* <DropdownMenuItem>Profile</DropdownMenuItem>
		// 			<DropdownMenuItem>Billing</DropdownMenuItem>
		// 			<DropdownMenuItem>Team</DropdownMenuItem>
		// 			<DropdownMenuItem>Subscription</DropdownMenuItem> */}
		// 		</DropdownMenuContent>
		// 	</DropdownMenu>
		// </div>
		<ClickAwayListener onClickAway={hideDropdown}>
			<div
				style={{
					width,
				}}
				className={classes(styles.root, classname)}
			>
				<div className={styles.input}>
					{disabled && <div className={styles.disabled} />}
					<input
						name={inputName}
						required={required}
						className={styles['search-input']}
						placeholder={placeholder}
						value={selectedValue ?? searchQuery}
						onChange={handleChange}
						onKeyDown={handleKeyPress}
						autoComplete='off'
						min={inputMin}
						onFocus={() => {
							setShowDropdown(true);
						}}
						ref={inputRef}
					/>
					<div className={styles['icon-conainer']}>
						{selectedValue && allowRemove && (
							<div
								onClick={() => {
									handleRemove(inputName);
								}}
								className={styles.remove}
							>
								<Close />
							</div>
						)}
						<ArrowDropDown />
					</div>
				</div>
				{!disabled && showDropdown && (
					<div className={styles['query-items']}>
						{!hideAddNew && (
							<div
								className={styles['dropdown-item']}
								onClick={() => {
									setShowDropdown(false);
									handleAddNew();
								}}
							>
								<AddCircle className={styles.icon} />
								<p>{addNewText}</p>
							</div>
						)}
						{!inputLists?.length > 0 && (
							<p className={styles.empty}>Empty List</p>
						)}
						{inputLists
							?.filter((ele) => {
								return (
									ele?.name
										?.toLowerCase()
										?.indexOf(
											searchQuery?.toLowerCase()?.trim()
										) !== -1
								);
							})
							?.map((ele) => {
								return (
									<div
										onClick={() => {
											if (!ele?.variants?.length > 0) {
												setShowDropdown(false);
												handleSelection(ele);
											}
										}}
										style={
											!ele?.variants?.length > 0
												? {
														cursor: 'pointer',
												  }
												: {}
										}
										className={styles['dropdown-item']}
									>
										{showImage && ele?.imageUrl && (
											<img
												src={ele?.imageUrl}
												alt='Option'
											/>
										)}
										<div
											className={
												styles['details-container']
											}
										>
											<p>{ele?.name ?? ele?.title}</p>
											{ele?.variants?.length > 0 && (
												<div>
													{!areAllVariantsAdded(
														ele?.variants,
														addedItems
													)
														? renderVariantSelector(
																ele,
																addedItems,
																handleVariantSelection,
																skuStockInfo
														  )
														: 'Item Already Added'}
												</div>
											)}
										</div>
									</div>
								);
							})}
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
}

export default CustomInputSelector;
