import {
	useCallback, useEffect, useMemo, useState
} from 'react';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
// import { defaults } from 'chart.js';
import { useHistory } from 'react-router';
import { classes } from '../../../Helpers';
import DownloadButton from '../../../Pages/Dashboard/DownloadButton/DownloadButton';
import Heading from '../../../Pages/Dashboard/Heading/Heading';
import { DASHBOARD_CHARTS_COLORS } from '../../../Styles/colors';
import styles from './HighlightChart.module.scss';

ChartJS.defaults.font.size = 11;

function HighlightChart(props) {
	const { data = {}, className = '' } = props;
	const history = useHistory();
	const [highlightData, setHighlightData] = useState({
		labels: [],
		datasets: [
			{
				data: [],
				url: undefined,
				ids: [],
				label: 'PO Raised',
			},
			{
				url: undefined,
				ids: [],
				data: [],
				label: 'Inwarded',
			},
			{
				url: undefined,
				ids: [],
				data: [],
				label: 'Quality Rejects',
			},
		],
	});
	const [dataNum, setDataNum] = useState({
		start: 0,
		end: 8,
	});

	const handleHighlightState = () => {
		if (data?.datasets?.length > 0) {
			const labels = (data?.labels ?? highlightData?.labels)?.filter((label, idx) => {
				return idx >= dataNum?.start && idx < dataNum?.end;
			});
			const datasets = (data?.datasets ?? highlightData?.datasets)?.map((dataset) => {
				return {
					label: dataset?.label,
					data: dataset?.data?.slice(dataNum?.start, dataNum?.end),
					ids: dataset?.ids?.slice(dataNum?.start, dataNum?.end),
					url: dataset?.url,
				};
			});
			setHighlightData({
				labels,
				datasets,
			});
		}
	};

	useEffect(handleHighlightState, [dataNum, data]);

	const nextDataSet = useCallback(() => {
		if (data?.labels?.length > dataNum?.end) {
			setDataNum((prev) => {
				return {
					start: prev.start + 8,
					end: prev.end + 8,
				};
			});
		}
	}, []);

	const prevDataSet = useCallback(() => {
		if (dataNum?.start !== 0) {
			setDataNum((prev) => {
				return {
					start: prev.start - 8,
					end: prev.end - 8,
				};
			});
		}
	}, [dataNum]);

	const barChartData = useMemo(() => {
		const adjustedLabels = highlightData?.labels?.map((label) => {
			return label?.split(' ');
		});
		return {
			labels: adjustedLabels,
			datasets: highlightData?.datasets?.map((dataset, idx) => {
				return {
					label: dataset?.label,
					data: dataset?.data,
					ids: dataset?.ids,
					url: dataset?.url,
					backgroundColor: DASHBOARD_CHARTS_COLORS.barChart[idx],
					barThickness: 20,
					borderRadius: 4,
					borderSkipped: false,
					barPercentage: 0.75,
					// pointHitRadius: 20, // hover distance
					categoryPercentage: 0.45,
				};
			}),
		};
	}, [highlightData]);

	return (
		<div className={classes(styles.root, styles.highlight, className)}>
			<p className={styles.unity}>{data?.ylabel}</p>
			<p className={styles.unitx}>{data?.xlabel}</p>
			<div className={styles['highlight-header']}>
				{dataNum?.start !== 0 && (
					<div className={styles.previous} onClick={prevDataSet}>
						{'<'}
					</div>
				)}
				<p className={styles.heading}>
					{data?.header ?? (
						<>
							<Heading
								heading={data?.heading}
								tooltip={data?.tooltip}
							/>
						</>
					)}
					<DownloadButton data={data} />
				</p>
				{(data?.limit ?? data?.labels?.length) > dataNum?.end && (
					<div className={styles.next} onClick={nextDataSet}>
						{'>'}
					</div>
				)}
			</div>
			<div className={styles['chart-container']}>
				<Bar
					data={barChartData}
					// plugins={[ChartDataLabels]}
					options={{
						onClick(evt, currentSet) {
							const currentDataset =
								highlightData.datasets[
									currentSet?.[0]?.datasetIndex
								];
							const currentIndex = currentSet?.[0]?.index;
							const url = currentDataset?.url;
							if (url) {
								history.push(
									`/console2/${url}${currentDataset?.ids?.[currentIndex]}`
								);
							}
						},
						maintainAspectRatio: false,
						// responsive: true,
						// aspectRatio: 1,
						plugins: {
							legend: {
								// position: 'bottom',
								labels: {
									// boxWidth: 15,
									usePointStyle: true,
								},
							},
						},
						scales: {
							x: {
								grid: {
									display: false,
								},
								ticks: {
									callback: (value, index) => {
										const label = barChartData?.labels[
											index
										]?.filter((val, idx) => {
											return (
												idx < 2 && val?.substring(0, 5)
											);
										});
										return label;
									},
								},
							},
							y: {
								grid: {
									display: false,
								},
								ticks: {},
							},
						},
					}}
					className={styles.chart}
				/>
			</div>
		</div>
	);
}

export default HighlightChart;
