import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import stateCityActions from '../../../Actions/stateCity.actions';
import { generateRequiredProps } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import { Map } from '../../Maps/Map';
import styles from './FranchiseeDetails.module.scss';

const getLatLng = (map) => {
	if (map) {
		const currLocation = map.getCenter();
		const lat = currLocation.lat();
		const lng = currLocation.lng();
		return {
			latitude: lat,
			longitude: lng,
		};
	}
	return {
		latitude: null,
		longitude: null,
	};
};
const FranchiseeDetails = (props) => {
	const {
		fields,
		handleInput,
		mandatoryFields,
		updateLocation,
		isDisabled,
		coords,
		// generateDefaultOption,
		errors,
		// stateCity,
		isEdit,
	} = props;
	useEffect(() => {
		props.fetchStateCity({
			id: '',
			allData: true,
		});
	}, []);
	const showPosition = (position) => {
		updateLocation({
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
		});
	};
	const onErrorInLocation = () => {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Enable your Location',
		});
	};
	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				showPosition,
				onErrorInLocation
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Geolocation is not supported by this browser.',
			});
		}
	};
	const [zoom, setZoom] = useState(14);
	const handleZoomChange = (map) => {
		if (map) {
			setZoom(map.getZoom());
		}
	};
	const handleDragEnd = (map) => {
		if (map) {
			updateLocation(getLatLng(map));
		}
	};
	return (
		<div className={`${styles.root} row`}>
			<div className={styles.container}>
				<div className='form-field row'>
					<label
						htmlFor='name'
						className='form-label'
						data-mandatory={mandatoryFields.name}
					>
						Franchisee Name
					</label>
					<input
						id='name'
						name='name'
						className='form-value'
						type='text'
						{...generateRequiredProps(mandatoryFields.name)}
						value={fields.name}
						onInput={handleInput}
						disabled={isDisabled}
					/>
				</div>
				<div className='form-field row'>
					<label
						htmlFor='contact'
						className='form-label'
						data-mandatory={mandatoryFields.contact}
					>
						Contact No.
					</label>
					<input
						id='contact'
						name='contact'
						className='form-value'
						type='number'
						{...generateRequiredProps(mandatoryFields.contact)}
						value={fields.contact}
						onInput={(e) => {
							if (e.target.value.length <= 10) {
								handleInput(e);
							}
						}}
						disabled={isDisabled || isEdit}
					/>
				</div>
				<div className='form-field row'>
					<label
						htmlFor='email'
						className='form-label'
						data-mandatory={mandatoryFields.email}
					>
						Email
					</label>
					<input
						id='email'
						name='email'
						className='form-value'
						type='email'
						{...generateRequiredProps(mandatoryFields.email)}
						value={fields.email}
						onInput={handleInput}
						disabled={isDisabled || isEdit}
					/>
				</div>
				<div className='form-field row'>
					<label
						htmlFor='otp'
						className='form-label'
						data-mandatory={mandatoryFields.otp}
					>
						Login Pin
					</label>
					<input
						id='otp'
						name='otp'
						disabled={isDisabled}
						className='form-value'
						type='number'
						{...generateRequiredProps(mandatoryFields.otp)}
						value={fields.otp}
						onInput={(e) => {
							if (e.target.value.length <= 4) {
								handleInput(e);
							}
						}}
					/>
				</div>
				{/* Address1 */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='addressLine1'
						data-mandatory={mandatoryFields.address}
					>
						Address Line 1
					</label>
					<textarea
						disabled={isDisabled}
						className='form-value'
						type='text'
						id='addressLine1'
						name='addressLine1'
						onInput={handleInput}
						value={fields.addressLine1}
						{...generateRequiredProps(mandatoryFields.address)}
					/>
					<span className='form-error'>{errors.address}</span>
				</div>
				{/* Address2 */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='addressLine2'
						data-mandatory={mandatoryFields.addressLine2}
					>
						Address Line 2
					</label>
					<textarea
						disabled={isDisabled}
						className='form-value'
						type='text'
						id='addressLine2'
						name='addressLine2'
						onInput={handleInput}
						value={fields.addressLine2}
						{...generateRequiredProps(mandatoryFields.address)}
					/>
					<span className='form-error'>{errors.address}</span>
				</div>
				{/* city name */}
				{/* <div className='form-field row'>
					<label
						className='form-label'
						htmlFor='city'
						data-mandatory={mandatoryFields.city}
					>
						City
					</label>
					<select
						className='form-value'
						disabled={isDisabled}
						id='city'
						name='city'
						onChange={handleInput}
						value={
							fields.city
								? JSON.stringify(fields.city)
								: fields.city
						}
						{...generateRequiredProps(mandatoryFields.city)}
					>
						{!isDisabled &&
							generateDefaultOption('city', fields.city)}
						{stateCity.map((obj) => {
							return (
								<option value={JSON.stringify(obj)}>
									{obj.cityName}
								</option>
							);
						})}
						<option value='1'>Default</option>
					</select>
					<span className='form-error'>{errors.city}</span>
				</div> */}
				{/* state name */}
				{/* <div className='form-field row'>
					<label
						className='form-label'
						htmlFor='state'
						data-mandatory={mandatoryFields.state}
					>
						State
					</label>
					<input
						className='form-value'
						type='text'
						value={get(fields.city, 'stateName', null)}
						readOnly
					/>
					<span className='form-error'>{errors.state}</span>
				</div> */}
				{/* Address2 */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='pincode'
						data-mandatory={mandatoryFields.pincode}
					>
						Pincode
					</label>
					<input
						className='form-value'
						type='number'
						id='pincode'
						name='pincode'
						onInput={handleInput}
						value={fields.pincode}
						disabled={isDisabled}
						{...generateRequiredProps(mandatoryFields.pincode)}
					/>
					<span className='form-error'>{errors.pincode}</span>
				</div>
			</div>
			<div
				className={styles.container}
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* geolocation image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.image}
					>
						Geolocation
					</label>
					<div className='display-pic-root'>
						<div className={styles['map-container']}>
							<Map
								center={coords}
								zoom={zoom}
								onDragEnd={handleDragEnd}
								onZoomChange={handleZoomChange}
								draggable={!isDisabled}
								height={218}
							/>
						</div>
						{isDisabled ? (
							<></>
						) : (
							<Button
								variant='contained'
								className={styles['btn-location']}
								onClick={getLocation}
							>
								Locate me
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
FranchiseeDetails.defaultProps = {
	isDisabled: false,
	coords: {
		lat: 0,
		lng: 0,
	},
};
const mapStateToProps = (state) => {
	const { stateCity } = state.stateCity;
	return {
		stateCity,
	};
};

const mapDispatchToProps = {
	fetchStateCity: stateCityActions.fetchStateCity,
};
export default connect(mapStateToProps, mapDispatchToProps)(FranchiseeDetails);
