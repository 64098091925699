import styles from './ServiceEnabled.module.scss';
import { generateCheckedProps } from '../../../../Helpers';

const ServiceEnabled = (props) => {
	const { fields, handleInput } = props;
	return (
		<div className={styles.root}>
			<label className={`${styles['checkbox-wrapper']}`}>
				<input
					type='checkbox'
					name='QMS'
					value={fields.QMS}
					defaultChecked={fields.QMS}
					onChange={handleInput}
					{...generateCheckedProps(fields.QMS)}
				/>
				<span>Quality Management System</span>
			</label>
			<label className={`${styles['checkbox-wrapper']}`}>
				<input
					type='checkbox'
					name='IMS'
					defaultChecked={fields.IMS}
					onChange={handleInput}
					{...generateCheckedProps(fields.IMS)}
				/>
				<span>Inventory Management System</span>
			</label>
			<label className={`${styles['checkbox-wrapper']}`}>
				<input
					type='checkbox'
					name='CMS'
					defaultChecked={fields.CMS}
					onChange={handleInput}
					{...generateCheckedProps(fields.CMS)}
				/>
				<span>Cooking Management System</span>
			</label>
		</div>
	);
};

export default ServiceEnabled;
