import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const subscriptionsAnalyticsServices = {
	gateway,
	fetchSubscriptionsList,
	fetchPreviousOrders,
	fetchItems,
	cancelSubscription,
};

function gateway({ req, method }) {
	switch (method) {
		case 'LIST_DATA':
			return fetchSubscriptionsList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function fetchSubscriptionsList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.MY_SUBSCRIPTIONS_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchPreviousOrders({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(
		endpoints.MY_SUBSCRIPTIONS_PREV_ORDERS,
		params
	);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchItems({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.MY_SUBSCRIPTIONS_ITEMS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function cancelSubscription({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.CANCEL_SUBSCRIPTION, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default subscriptionsAnalyticsServices;
