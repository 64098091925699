/* eslint-disable no-unused-vars */
import {
	useEffect, useMemo, useState
} from 'react';
import DatePicker from 'rsuite/DatePicker';
import moment from 'moment';
import SelectPicker from 'rsuite/SelectPicker';
import styles from './AuditStep1.module.scss';
import { classes } from '../../../../Helpers';
import './auditstep1.css';

const AuditStep1 = (props) => {
	const { auditName, setAuditName, scheduledFor, setScheduledFor, show } = props;
	const [pickerDate, setPickerDate] = useState(null);
	const [pickerTime, setPickerTime] = useState(null);
	const [timeOptions, setTimeOptions] = useState([]);

	useEffect(() => {
		if (show && scheduledFor !== 'NOW') {
			const scheduledTime = scheduledFor?.split(' ')?.[1];
			const scheduledForDate = moment(scheduledFor, 'M/D/YYYY HH:mm');
			const dateObject = scheduledForDate.toDate();
			const timeString = scheduledForDate.format('HH:mm');
			if (
				dateObject instanceof Date &&
				scheduledTime !== 'Invalid' &&
				scheduledTime !== 'null'
			) {
				setPickerDate(dateObject);
				setPickerTime(timeString);
			}
		}
	}, [scheduledFor, show]);

	// Function to calculate time options for the current date's remaining time
	const calculateTimeOptions = () => {
		const remainingTimeOptions = [];
		for (let hour = 0; hour < 24; hour++) {
			for (let minute = 0; minute < 60; minute += 30) {
				const formattedHour = hour < 10 ? `0${hour}` : hour;
				const formattedMinute = minute === 0 ? '00' : minute;
				const timeOption = `${formattedHour}:${formattedMinute}`;
				remainingTimeOptions.push(timeOption);
			}
		}
		setTimeOptions(remainingTimeOptions);
	};

	useEffect(() => {
		calculateTimeOptions();
	}, []);

	const TIME_OPTIONS = useMemo(() => {
		const currentDate = new Date(); // Get current date and time
		const currentDay = moment(currentDate).startOf('day');
		const currentTime =
			currentDate.getHours() * 60 + currentDate.getMinutes();
		return timeOptions
			?.filter((time) => {
				const [hour, minute] = time.split(':').map(Number);
				const optionTime = hour * 60 + minute;
				if (
					moment(moment(pickerDate).startOf('day')).isBefore(
						currentDay
					)
				) {
					return false;
				}
				if (
					moment(pickerDate).format('YYYY-MM-DD') ===
					moment(currentDate).format('YYYY-MM-DD')
				) {
					return optionTime >= currentTime;
				}
				return true;
			})
			.map((ele) => {
				return {
					label: ele,
					value: ele,
				};
			});
	}, [pickerDate, timeOptions]);

	return (
		<div className={styles.root}>
			<div className={styles.labelled_input}>
				<label>Audit Name</label>
				<input
					type='text'
					value={auditName}
					onChange={(e) => {
						setAuditName(e?.target?.value);
					}}
				/>
			</div>
			<div className={styles.date_selector}>
				<div
					className={classes(
						styles.selector,
						scheduledFor === 'NOW' ? styles.selected : ''
					)}
					onClick={() => {
						setScheduledFor('NOW');
					}}
				>
					<input
						type='checkbox'
						checked={scheduledFor === 'NOW'}
						onChange={() => {
							setScheduledFor('NOW');
						}}
					/>
					<p>Start Now</p>
				</div>
				<div
					className={classes(
						styles.selector,
						scheduledFor !== 'NOW' ? styles.selected : ''
					)}
				>
					<input
						type='checkbox'
						checked={scheduledFor !== 'NOW'}
						onChange={() => {
							const selectedDate =
								moment(pickerDate)?.format('M/D/YYYY');
							setScheduledFor(`${selectedDate} ${pickerTime}`);
						}}
					/>
					<div className={styles.time_selector}>
						<label className={styles.schedule_later}>
							Schedule Later
						</label>
						<DatePicker
							className={styles.picker}
							value={pickerDate}
							cleanable={false}
							oneTap
							shouldDisableDate={(date) => {
								return (
									date <
									new Date(new Date().setHours(0, 0, 0, 0))
								);
							}}
							onChange={(rDate) => {
								calculateTimeOptions();
								setPickerDate(rDate);
								if (
									!moment(
										moment(rDate).startOf('day')
									).isBefore(moment().startOf('day'))
								) {
									const selectedDate =
										moment(rDate)?.format('M/D/YYYY');
									setScheduledFor(
										`${selectedDate} ${pickerTime}`
									);
								} else {
									setPickerTime(null);
								}
							}}
						/>
						<SelectPicker
							value={pickerTime}
							className={styles.picker}
							data={TIME_OPTIONS}
							searchable={false}
							placeholder='Select Time'
							onChange={(rTime) => {
								setPickerTime(rTime);
								const selectedDate =
									moment(pickerDate)?.format('M/D/YYYY');
								setScheduledFor(`${selectedDate} ${rTime}`);
							}}
							cleanable={false}
							menuStyle={{
								zIndex: 9999,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuditStep1;
