/* eslint-disable eqeqeq */

const initialState = {
	deliverTo: {},
	selectedProcess: {},
	inventoryAtDayEndOf: new Date(new Date().setDate(new Date().getDate() - 1)),
	poItems: {
		INTERMEDIATE: [],
		INGREDIENT: [],
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_DELIVER_TO':
			return {
				...state,
				deliverTo: action.payload,
				selectedProcess: {},
			};
		case 'SET_PO_ITEMS':
			return {
				...state,
				poItems: action.payload,
			};
		case 'SET_INVENTORY_DATE':
			return {
				...state,
				inventoryAtDayEndOf: action.payload,
			};
		case 'SET_PROCESS':
			return {
				...state,
				selectedProcess: action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const config = {
	initialState,
	reducer,
	validateItems: (poItems) => {
		const errorsArray = [];
		[...poItems?.INTERMEDIATE, ...poItems?.INGREDIENT]?.forEach((element) => {
			if (element?.quantity === '' || element?.quantity === null || element?.quantity === undefined) {
				errorsArray.push(
					// eslint-disable-next-line prefer-template
					element?.productName
				);
			}
		});
		return errorsArray;
	},
};

export default config;
