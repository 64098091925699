/* eslint-disable object-curly-newline */
/* eslint-disable react/button-has-type */

import { useState, useMemo, useCallback, useEffect } from 'react';
import { ShoppingCart } from 'lucide-react';

import { notificationServices } from '@/Services/Notification';
import { getGroupedAssets } from './assetSelector.config';
import AssetGroup from './AssetGroup';
import GroupAssetCartModal from './GroupAssetCartModal';

const CategoryTabs = ({ categories, selectedCategory, onSelect }) => (
	<div className='flex w-full overflow-x-auto gap-[0.2rem] px-1 rounded-t-lg border-b border-[#e5e7eb] p-[0.2rem] bg-white scrollbar-thin'>
		{categories.map((c) => (
			<button
				key={c.id}
				onClick={() => onSelect(c)}
				className={`px-2 py-1 text-xs rounded-t-lg transition-colors duration-200 whitespace-nowrap ${
					selectedCategory.id === c.id
						? 'bg-blue-50 border-b-2 border-blue-500 text-blue-600 font-medium'
						: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
				}`}
			>
				{c.name}
			</button>
		))}
	</div>
);

const AssetTypeSidebar = ({ types, selectedType, onSelect }) => (
	<div className='w-36 flex relative items-center border-r p-2'>
		<div className='flex  w-full overflow-x-auto gap-[0.2rem] px-1 rounded-lg p-[0.2rem] bg-white scrollbar-thin flex-col'>
			{types.map((t) => (
				<button
					key={t.id}
					onClick={() => onSelect(t)}
					className={`px-2 py-1 text-xs rounded-lg transition-colors duration-200 whitespace-nowrap ${
						selectedType.id === t.id
							? 'bg-blue-50 border-b-2 border-blue-500 text-blue-600 font-medium'
							: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
					}`}
				>
					{t.id === -999 ? 'All' : t.name}
				</button>
			))}
		</div>
	</div>
);

// for each group show nice summary of its keys

function AssetSelector({
	height,
	// assetTypeList,
	storeAssetQrDataList,
	onAssetSelect = () => {},
	setParentGroupedAssets,
	avoidTrackingChildAssets = true,
	// eslint-disable-next-line no-unused-vars
	onAssetGroupSelect = (callback, groupKey, delta, newCart) => {
		callback();
	},
}) {
	const [selectedCategory, setSelectedCategory] = useState({
		id: -999,
		name: 'All',
	});
	const [selectedAssetType, setSelectedAssetType] = useState({
		id: -999,
		name: 'All',
	});

	const [cart, setCart] = useState({});
	const [showCart, setShowCart] = useState(false);
	const [groupedAssets, setGroupedAssets] = useState({});

	useEffect(() => {
		const groups = getGroupedAssets(storeAssetQrDataList, avoidTrackingChildAssets);
		setGroupedAssets(groups);
		if (setParentGroupedAssets) {
			setParentGroupedAssets(groups);
		}
	}, [storeAssetQrDataList, setParentGroupedAssets, avoidTrackingChildAssets]);

	const categories = useMemo(() => {
		const groupings = Object.values(groupedAssets);
		if (!groupings?.length) return [];
		const uniqueCategories = new Map([
			[
				'-999',
				{
					id: -999,
					name: 'All',
				},
			],
		]);
		groupings.forEach((group) => {
			if (
				group.category?.name &&
				!uniqueCategories.has(group.category.id)
			) {
				uniqueCategories.set(group.category.id, {
					id: group.category.id,
					name: group.category.name,
				});
			}
		});
		return Array.from(uniqueCategories.values());
	}, [groupedAssets]);

	const assetTypes = useMemo(() => {
		const groupings = Object.values(groupedAssets);
		if (!groupings?.length) return [];
		const uniqueTypes = new Map([
			[
				'-999',
				{
					id: -999,
					name: 'All',
				},
			],
		]);
		const categoryFilteredAssets = groupings.filter(
			(group) => selectedCategory.id === -999 ||
				selectedCategory.id === group.category?.id
		);
		categoryFilteredAssets.forEach((group) => {
			if (group.assetType?.id && !uniqueTypes.has(group.assetType.id)) {
				uniqueTypes.set(group.assetType.id, {
					id: group.assetType.id,
					name: group.assetType.name,
				});
			}
		});
		return Array.from(uniqueTypes.values());
	}, [groupedAssets, selectedCategory]);

	const filteredGroups = useMemo(() => {
		const groupings = Object.values(groupedAssets);
		if (!groupings?.length) return [];
		const filteredAssets = groupings.filter((group) => {
			const categoryMatch =
				selectedCategory.id === -999 ||
				group.category?.id === selectedCategory.id;
			const typeMatch =
				selectedAssetType.id === -999 ||
				group.assetType?.id === selectedAssetType.id;
			return categoryMatch && typeMatch;
		});
		const groups = {};
		filteredAssets.forEach((group) => {
			groups[group.groupBySpecsKey] = group;
		});
		return groups;
	}, [selectedCategory, selectedAssetType, groupedAssets]);

	const handleCartChange = useCallback(
		(groupKey, delta) => {
			const newCart = {
				...cart,
			};
			const currentCount = newCart?.[groupKey] || 0;
			const newCount = currentCount + delta;
			if (newCount <= 0) {
				delete newCart[groupKey];
			} else {
				newCart[groupKey] = newCount;
			}
			const callback = () => {
				setCart(newCart);
			};
			onAssetGroupSelect(callback, groupKey, delta, newCart);
		},
		[cart]
	);

	if (!storeAssetQrDataList?.length) return <div className='p-2 text-sm'>No assets available</div>;

	return (
		<div className='w-full flex flex-col'>
			<div className='flex justify-between relative items-center px-4 pt-3 pb-1 bg-gray-50 border-b'>
				<span className='absolute -top-1 left-0 text-[8px] bg-white px-1 rounded-sm font-medium text-gray-500'>
					Asset Categories
				</span>
				<CategoryTabs
					categories={categories}
					selectedCategory={selectedCategory}
					onSelect={setSelectedCategory}
				/>
				<div className='pl-2 pr-1 flex h-full'>
					<button
						onClick={() => {
							if (
								Object.values(cart).reduce(
									(acc, curr) => acc + curr,
									0
								) > 0
							) {
								setShowCart(true);
							} else {
								notificationServices.generateNotification({
									type: 'error',
									message: 'No items selected',
								});
							}
						}}
						className='flex relative items-center border border-yellow-800  gap-2 px-3 py-1 text-sm bg-yellow-50 text-yellow-600 rounded hover:bg-yellow-100'
					>
						<ShoppingCart size={16} />
						<span>Cart</span>
						<div className='absolute -top-2 -right-2'>
							<span className='bg-yellow-600 text-white w-4 h-4 flex items-center justify-center rounded-full text-[10px]'>
								{Object.values(cart).reduce(
									(acc, curr) => acc + curr,
									0
								)}
							</span>
						</div>
					</button>
				</div>
			</div>
			<div className='flex relative flex-1'>
				<div className='absolute -rotate-90 top-1/2 -left-7 z-10 text-[8px] bg-white px-1 rounded-sm font-medium text-gray-500'>
					Asset Types
				</div>
				<AssetTypeSidebar
					types={assetTypes}
					selectedType={selectedAssetType}
					onSelect={setSelectedAssetType}
				/>
				<div
					style={{
						height: height - 60,
					}}
					className='p-2 overflow-auto flex-1 scrollbar-thick'
				>
					<div className='grid  grid-cols-4 gap-2 scrollbar-thick'>
						{Object.entries(filteredGroups).map(
							([groupKey, group]) => (
								<AssetGroup
									key={groupKey}
									groupKey={groupKey}
									groupTitle={group.groupBySpecs}
									assets={group.actualAsset}
									specsToBeShownOnGroups={
										group.specsToBeShownOnGroups
									}
									imageUrl={group.imageUrl}
									category={group.category}
									oem={group.oem}
									assetType={group.assetType}
									onAssetSelect={onAssetSelect}
									handleCartChange={handleCartChange}
									// handleChildAssetAddToCart={
									// handleChildAssetAddToCart
									// }
									currentCartCount={cart?.[groupKey]}
								/>
							)
						)}
					</div>
				</div>
			</div>
			<GroupAssetCartModal
				open={showCart}
				onClose={() => setShowCart(false)}
				cartItems={cart}
				groupedAssets={groupedAssets}
			/>
		</div>
	);
}

export default AssetSelector;
