/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */

const renderUnit = (unit) => {
	if (unit && unit.length > 0) {
		return (
			<span className='ml-[.1rem] text-gray-500 text-[10px]'>{unit}</span>
		);
	}
	return null;
};
const ViewOnlyCoreSpec = ({ coreSpecs, showPrev = true }) => {
	const renderSpecValue = (spec) => {
		const { value, uom, rfqMandatory, isChecked, newValue, dataType } =
			spec;
		// Handle different data types
		if (dataType === 'DATE') {
			const date = new Date(value);
			const showValue = date.toLocaleDateString();
			let newShowValue = null;
			if (rfqMandatory && isChecked === false) {
				newShowValue = date.toLocaleDateString();
			}
			return (
				<>
					{newShowValue !== null && showPrev && (
						<span className='line-through ml-1 text-red-500'>
							{showValue}
							{renderUnit(uom)}
						</span>
					)}
					{showValue && (
						<span>
							{newShowValue ?? showValue}
							{renderUnit(uom)}
						</span>
					)}
				</>
			);
		}
		if (dataType === 'BOOLEAN') {
			const showValue = value === 'true' || value === true;
			let newBoolValue = null;
			if (rfqMandatory && isChecked === false) {
				newBoolValue = newValue === 'true' || newValue === true;
			}
			return (
				<>
					{newBoolValue !== null && showPrev && (
						<span
							className={`px-[.2rem] py-[.1rem] line-through rounded-sm ${
								showValue
									? 'bg-green-200 text-green-700'
									: 'bg-red-200 text-red-700'
							}`}
						>
							{showValue ? 'Yes' : 'No'}
						</span>
					)}
					{showValue !== undefined && (
						<span
							className={`px-[.2rem] py-[.1rem] rounded-sm ${
								newBoolValue ?? showValue
									? 'bg-green-200 text-green-700'
									: 'bg-red-200 text-red-700'
							}`}
						>
							{newBoolValue ?? showValue ? 'Yes' : 'No'}
						</span>
					)}
				</>
			);
		}
		if (['NUMBER', 'DECIMAL', 'TEXT'].includes(dataType)) {
			const showValue = value;
			let newShowValue = null;
			if (rfqMandatory && isChecked === false) {
				newShowValue = newValue;
			}
			return (
				<>
					{newShowValue !== null && showPrev && (
						<span className='px-[.2rem] py-[.1rem] line-through rounded-sm'>
							{showValue}
							{renderUnit(uom)}
						</span>
					)}
					{showValue !== undefined && (
						<span className='px-[.2rem] py-[.1rem] rounded-sm'>
							{newShowValue ?? showValue}
							{renderUnit(uom)}
						</span>
					)}
				</>
			);
		}
		// Default value display
		return (
			<>
				<span>{value}</span>
				{uom && uom.length > 0 && (
					<span className='ml-1 text-gray-500'>{uom}</span>
				)}
			</>
		);
	};

	return (
		<div className='space-y-[.1rem] '>
			{coreSpecs?.map((spec) => (
				<div
					key={spec.id}
					className={`flex items-center justify-between  rounded-sm p-[.2rem] text-xs ${
						spec.rfqMandatory ? 'bg-blue-50' : 'bg-gray-50'
					} ${
						spec.isChecked === false && showPrev
							? 'border border-dashed border-red-300 bg-red-50'
							: 'border border-transparent'
					}`}
				>
					<span className='text-gray-600 font-medium'>
						{spec.label}:
					</span>
					<span>{renderSpecValue(spec)}</span>
				</div>
			))}
		</div>
	);
};

export default ViewOnlyCoreSpec;
