import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import analyticsAction from '../../../../Actions/analytics.actions';
// import { DefectImageResult } from '../../../../Components/Analytics/Defect';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import { ImagesResult } from '../../../../Components/ImagesResult';
import { DefectImageModal } from '../../../../Components/Modals';
import { modalTypes, urls } from '../../../../Constants';
import temperatureAnalyticsServices from '../../../../Services/Analytics Services/temperature-analytics.services';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './TemperatureDigitImagesPage.module.scss';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const FIND_FILTERS = ['store', 'processTypes', 'pageLimit', 'dateRange'];

const TemperatureDigitImagesPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		brand: null,
		processTypes: null,
		featureType: 'QUALITY',
		defects: [],
		products: [],
		vendors: [],
		productCategories: [],
		identification: 'Both',
		submissions: 0,
		timeType: '',
		pageLimit: 6,
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
	});

	const {
		allTemperatureResults,
		fetchTemperatureImages,
		updateTemperatureImagesList,
		resetDigitTemperatureImages,
		toggleModal,
		userPermissions,
	} = props;

	useEffect(() => {
		resetDigitTemperatureImages();
	}, []);

	const submitFindRequest = ({
		store = null,
		processTypes = null,
		brand,
		featureType,
		defects = [],
		products = [],
		productCategories = [],
		identification,
		timeType,
		page: pageLimit,
		vendors = [],
		dateRange = {},
	}) => {
		resetDigitTemperatureImages();
		setFilters({
			store,
			processTypes,
			brand,
			featureType,
			defects,
			products,
			vendors,
			productCategories,
			identification,
			submissions: filters.submissions + 1,
			timeType,
			pageLimit,
			dateRange,
		});
		fetchTemperatureImages({
			entity: 'TEMPERATURE_ANALYSIS',
			method: 'FIND_DIGIT_TEMPERATURE_IMAGES',
			req: {
				params: {
					limit: pageLimit,
				},
				data: {
					storeId: store,
					brandId: brand,
					labels: defects,
					processTypes,
					products,
					vendors,
					productCategories,
					identification,
					type: featureType,
					dateRangeFilter: dateRange?.active
						? {
							timeType,
							from: moment(dateRange?.from).format(
								'YYYY-MM-DD'
							),
							to: moment(dateRange?.to).format('YYYY-MM-DD'),
						}
						: {},
				},
			},
		});
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	const fetchTemperatureImagesById = async (
		{ page, id },
		index
	) => {
		const response = await temperatureAnalyticsServices.fetchDigitImagesById({
			params: {
				page,
				limit: filters?.pageLimit,
			},
			data: {
				labelId: id,
				storeId: filters.store,
				products: filters.products,
				processTypes: filters.processTypes,
				brandId: filters.brand,
				vendors: filters.vendors,
				productCategories: filters.productCategories,
				identification: filters?.identification,
				type: filters?.featureType,
				dateRangeFilter: filters?.dateRange?.active
					? {
						timeType: filters?.timeType,
						from: moment(filters?.dateRange.from).format('YYYY-MM-DD'),
						to: moment(filters?.dateRange.to).format('YYYY-MM-DD'),
					}
					: {},
			},
		});

		const { data = [] } = response;
		updateTemperatureImagesList(index, data);
		return data;
	};

	const onItemClick = async ({ annotation, item: product }) => {
		const qcData = await fetchQCData(annotation.qcId);
		toggleModal(true, {
			source: annotation.image,
			name: qcData?.ProductName,
			product,
			date: qcData?.QCTime,
			link:
				qcData != null
					? `${urls.EXTENSION}${urls.PAST_QC}/${qcData?.itemId}?id=${qcData?.id}`
					: null,
		});
	};

	return (
		<div className={styles.root}>
			<DefectImageModal />
			<ImagesFinder FILTERS={FIND_FILTERS} onSubmit={submitFindRequest} />
			<div className={styles['image-results']}>
				{allTemperatureResults.map((defect, index) => {
					return (
						<ImagesResult
							key={`${defect.id}${filters.submissions}`}
							identification={filters?.identification}
							// fetchQCData={fetchQCData}
							fetchMoreImages={(req) => {
								return fetchTemperatureImagesById(req, index);
							}}
							currentItem={defect}
							onItemClick={onItemClick}
							isInternal={userPermissions.INTERNAL}
						/>
					);
				})}
				{allTemperatureResults.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_DIGIT_TEMPERATURE_IMAGES: allTemperatureResults } =
		state.analytics.TEMPERATURE_ANALYSIS;
	const { userPermissions } = state.navigation;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	return {
		allTemperatureResults,
		dateRangeFilter,
		userPermissions,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTemperatureImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateTemperatureImagesList: (...args) => {
			dispatch(analyticsAction.updateTemperatureImagesList(...args));
		},
		resetDigitTemperatureImages: () => {
			dispatch(analyticsAction.resetDigitTemperatureImages());
		},
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TemperatureDigitImagesPage);
