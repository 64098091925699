// eslint-disable-next-line max-len
// 'C:\Users\uytec\Desktop\new\resoee-console\src\Components\Draggable\DraggableVTabs\DraggableVTab\DraggableVTab.js';
import styles from './Htabs.module.scss';
import { VTab } from '../VTabs/VTab';

const Htabs = (props) => {
	const {
		items,
		order,
		activeTab,
		handleActiveTab,
		isDraggable,
	} = props;
	return (
		<div className={`${styles.root}`}>
			{order.map((id, idx) => {
				const { name } = items[id];

				return (
					<VTab
						isDraggable={isDraggable}
						key={id}
						id={id}
						text={name}
						index={idx}
						active={id === activeTab}
						handleClick={handleActiveTab}
					/>
				);
			})}
		</div>
	);
};

Htabs.defaultProps = {
	limit: 10,
	isDraggable: true,
	isDisabled: false,
};

export default Htabs;
