import { Crop } from '@mui/icons-material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { brandActions, formActions, modalActions } from '../../../../Actions';
import {
	generateImageEditButton,
	generateImageUploadButton,
	generateImageUrl,
	generateRequiredProps,
	inputHelper,
} from '../../../../Helpers';
import { ImageCropModal } from '../../../Modals/ImageCropModal';
import { IconInput } from '../../Inputs';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		isDisabled,
		brands,
		category,
		generateDefaultOption,
		handleImageInput,
		toggleImageCrop,
	} = props;
	useEffect(() => {
		props.fetchBrands({
			id: '',
			allData: true,
		});
		props.fetchCategory({
			entity: 'INVENTORY_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);
	const openImageCropModal = (name, file = null) => {
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* sku name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='skuName'
						data-mandatory={mandatoryFields.skuName}
					>
						SKU Name
					</label>
					<input
						className='form-value'
						type='text'
						id='skuName'
						name='skuName'
						onInput={handleInput}
						value={fields.skuName}
						{...generateRequiredProps(mandatoryFields.skuName)}
					/>
					<span className='form-error'>{errors.skuName}</span>
				</div>
				{/* brand name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='brand'
						data-mandatory={mandatoryFields.brand}
					>
						Brand
					</label>
					<select
						className='form-value'
						id='brand'
						name='brand'
						onChange={handleInput}
						value={fields.brand}
						{...generateRequiredProps(mandatoryFields.brand)}
					>
						{generateDefaultOption('brand', fields.brand)}
						{Object.keys(brands).map((brandId) => {
							const brand = brands[brandId];
							return (
								<option value={brandId}>{brand.name}</option>
							);
						})}
						{/* <option value='1'>Default</option> */}
					</select>
					<span className='form-error'>{errors.brand}</span>
				</div>
				{/* category name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='category'
						data-mandatory={mandatoryFields.category}
					>
						Category
					</label>
					<select
						className='form-value'
						id='category'
						name='category'
						onChange={handleInput}
						value={fields.category}
						{...generateRequiredProps(mandatoryFields.category)}
					>
						{generateDefaultOption(
							'category',
							fields.brand,
							fields.category
						)}
						{category.map((skuCategoryId) => {
							return (
								<option value={skuCategoryId.id}>
									{skuCategoryId.name}
								</option>
							);
						})}
					</select>
					<span className='form-error'>{errors.category}</span>
				</div>
				<div className='form-field row params'>
					<label
						className='form-label'
						htmlFor='costPrice'
						data-mandatory={mandatoryFields.costPrice}
					>
						Cost Price
					</label>
					<IconInput
						size='large'
						type='rupee'
						iconText=''
						name='costPrice'
						id='costPrice'
						value={fields.costPrice}
						handleInput={handleInput}
						dataType=''
						dataId=''
						dataAttrs={{
							required: mandatoryFields.costPrice,
						}}
						step='0.01'
					/>
					<span className='form-error'>{errors.costPrice}</span>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* display image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.image}
					>
						SKU Image
					</label>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={
									isDisabled
										? fields.image
										: generateImageUrl(fields.image)
								}
								className={`display-pic ${
									fields.image ? 'show' : 'hide'
								}`}
								alt='Inventory Display'
							/>
							{generateImageEditButton({
								isEdit:
									fields.image &&
									fields.image.includes(
										'data:image/jpeg;base64'
									),
								button: (
									<button
										type='button'
										onClick={() => {
											openImageCropModal('image');
										}}
										className='display-pic-edit'
									>
										<Crop />
									</button>
								),
							})}
						</div>
						{generateImageUploadButton({
							isDisabled,
							label: (
								<label
									className='display-pic-label'
									htmlFor='displayPic'
								>
									Choose an image
								</label>
							),
						})}
						<input
							type='file'
							id='displayPic'
							accept='image/png, image/jpg, image/jpeg, image/webp'
							name='image'
							className={styles['display-pic-input']}
							onChange={handleImageSelect}
							{...generateRequiredProps(
								typeof fields.image !== 'string' &&
									mandatoryFields.image
							)}
						/>
					</div>
				</div>
				<ImageCropModal handleConfirm={handleImageInput} />
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const { brands } = state.brand;
	const category = state.form.INVENTORY_CATEGORY.data.LIST;
	return {
		brands,
		category,
	};
};

const mapDispatchToProps = {
	fetchBrands: brandActions.fetchBrands,
	fetchCategory: formActions.gateway,
	toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
