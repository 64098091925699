import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Description',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const COLMAP = {
	Name: 'name',
	Description: 'description',
};

const InventoryCategoryListPage = (props) => {
	const { fetchProductCategory, data, deleteProductCategory, query } = props;
	useEffect(() => {
		fetchProductCategory({
			entity: 'INVENTORY_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleDelete = (ProductCategoryId) => {
		deleteProductCategory({
			entity: 'INVENTORY_CATEGORY',
			req: {
				params: {
					id: ProductCategoryId,
				},
			},
			method: 'DELETE',
		});
	};

	return (
		<div className='list-page-root'>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data.filter((pc) => {
					return (
						pc.name
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				actions={[...ACTION_COLUMNS]}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_CATEGORY.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProductCategory: formActions.gateway,
	toggleDisableProductCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryCategoryListPage);
