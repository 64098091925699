import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import MySubscriptionsHistory from '../../Pages/Analytics/MySubscriptionsHistory/MySubscriptionsHistory';

const getSubURL = (entity) => {
	const baseEntity = urls.MY_SUBSCRIPTIONS;
	return entity.slice(baseEntity.length);
};

const MySubscriptionsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.MY_SUBSCRIPTIONS_HISTORY)}`}>
				<MySubscriptionsHistory />
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

export default connect(mapStateToProps)(MySubscriptionsRoutes);
