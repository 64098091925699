// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
// import { AddChild } from '../../Pages/Accounts/AddChild';
import AIModelCreation from '../../Pages/AIWorkflow/AIModelCreation/AIModelCreation';
import AIModelHistoryPage from '../../Pages/AIWorkflow/AIModelHistoryPage/AIModelHistoryPage';
import AiStatsPage from '../../Pages/AIWorkflow/AiStatsPage/AiStatsPage';

const getSubURL = (entity) => {
	const baseEntity = urls.AI_WORKFLOW;
	return entity.slice(baseEntity.length);
};

const AIWorkflowRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}${getSubURL(urls.AI_MODEL_HISTORY)}`}>
				<AIModelHistoryPage />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.AI_MODEL_CREATION)}`}>
				<AIModelCreation />
			</Route>
			<Route exact path={`${path}${getSubURL(urls.AI_STATS)}`}>
				<AiStatsPage />
			</Route>
			{/* <Route
				path={`${path}${getSubURL(urls.PAYMENT_SETTLEMENTS)}/view/:id`}
			>
				<TransactionViewPage />
			</Route> */}
		</Switch>
	);
};

export default AIWorkflowRoutes;
