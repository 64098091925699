import _ from 'lodash';
import { formatNumber } from '../../Helpers';

const auditDashboardAnalysisConfig = {
	initialState: {
		fields: {
			amountCaption: [
				{
					caption: 'Total',
					amount: 'N/A',
				},
				{
					caption: 'Passed %',
					amount: 'N/A',
				},
				{
					caption: 'Weight Failure',
					amount: 'N/A',
				},
				{
					caption: 'Temp Failure',
					amount: 'N/A',
				},
				{
					caption: 'Food texture',
					amount: 'N/A',
				},
				{
					caption: 'Cpk',
					amount: 'N/A',
				},
			],
		},
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				amountCaption: [
					...Object.keys(data).map((current) => {
						let amount = data[current]?.value;
						let percentage = null;
						if ('percentageValue' in data?.[current]) {
							percentage = data?.[current]?.percentageValue;
						}
						if (data?.[current]?.type === 'currency') {
							amount = `\u20B9${formatNumber(
								'IN',
								data[current]?.value
							)}`;
						}
						return {
							caption: data[current].label,
							amount,
							color: data?.[current]?.color,
							percentageValue: percentage
								? `${percentage} %`
								: null,
						};
					}),
				],
			};
			return newState;
		});
	},
};
export default auditDashboardAnalysisConfig;
