import { connect } from 'react-redux';
import { useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { modalActions } from '@/Actions';
import { BaseModal } from '../BaseModal';
import { Button } from '@/Components/ui/button';
import { ListTable } from '@/Components/ListTable';

function CoreSpecificationModal(props) {
	const { show, toggleModal, data } = props;

	const handleClose = () => {
		toggleModal(false);
	};

	const TABLE_COLUMNS = [

		{
			title: 'label',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Data Type',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Unit',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'To be provided with RFQ/Order',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'To be provided by Supplier',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'To be provided time of Receiving',
			config: {
				type: 'DATA',
			},
		},
	];

	const COLMAP = {
		label: 'label',
		'Data Type': 'dataType',
		Unit: 'uom',
		'To be provided with RFQ': 'rfqMandatory',
		'To be provided by Supplier': 'supplierMandatory',
		'To be provided time of Receiving': 'receiverMandatory',
	};

	const newData = useMemo(() => {
		if (data?.item?.length > 0) {
			return data.item.map((item) => ({
				id: item.id,
				label: item.label,
				dataType: item.dataType,
				uom: item.uom,
				receiverMandatory: item.receiverMandatory ? <DoneIcon className='text-green-600' /> : '',
				rfqMandatory: item.rfqMandatory ? <DoneIcon className='text-green-600' /> : '',
				supplierMandatory: item.supplierMandatory ? <DoneIcon className='text-green-600' /> : '',
			}));
		}
		return [];
	}, [data]);

	const renderBtns = () => {
		return (
			<div className='w-full flex justify-center gap-2'>
				<Button className='flex-1' variant='secondary' onClick={handleClose}>Close</Button>
			</div>
		);
	};
	return (
		<BaseModal
			heading={data?.heading}
			open={show}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='60rem'
			height='auto'
		>
			<ListTable
				columns={TABLE_COLUMNS}
				colMap={COLMAP}
				data={newData}
			/>
		</BaseModal>
	);
}

const mapStateToProps = (state) => {
	const { show, data } = state.modal.showCoreSpecificationModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleSpecificationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreSpecificationModal);
