/* eslint-disable no-unused-vars */
import {
	useEffect, useMemo, useRef, useState
} from 'react';
import { connect } from 'react-redux';
import { MenuItem, Select } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import moment from 'moment';
import SelectPicker from 'rsuite/SelectPicker';
import {
	inputHelper,
	usePagination,
	useQuery,
	useSearch,
} from '../../../Helpers';
import { analyticsAction } from '../../../Actions';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
import styles from './OrderHistory.module.scss';
import { ListModal } from '../../../Components/Modals';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import Calender from '../../../Components/Forms/Payment Settlements/Calender/Calender';
import OrderHistoryList from './OrdersHistoryList';
import { OrderItemsModal } from '../../../Components/Modals/OrderItemsModal';
import orderAnalyticsServices from '../../../Services/Analytics Services/orderAnalytics.services';
import { notificationServices } from '../../../Services';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';

const ANALYTICS_TYPE = analyticsTypeConstant.ORDER_HISTORY_LIST_DATA;
const PLACE_HOLDERS = {
	ORDER_ID: 'Search by Order Id',
	MOBILE: 'Search by Mobile',
};

const orderTypes = ['REGULAR', 'ADHOC', 'DELIVERY'];

const SEARCH_BY = {
	options: [
		{
			key: 'ORDER_ID',
			title: 'Order ID(UY)',
			get: 'orderId',
		},
		{
			key: 'PARTNER_ORDER_ID',
			title: 'Partner Order ID(UP)',
			get: 'partnerOrderId',
		},
		{
			key: 'PLATFORM_ORDER_ID',
			title: 'Platform Order ID',
			get: 'platformOrderId',
		},
		{
			key: 'MOBILE',
			title: 'Mobile',
			get: 'mobile',
		},
	],
};

const TIME_TYPES = [
	{
		label: 'Order time',
		value: 'ORDER',
	},
	{
		label: 'Scheduled time',
		value: 'SCHEDULED',
	},
];

const ORDER_STATUS = [
	{
		id: 'Order Received',
		title: 'Order Received',
		label: 'Order Received',
		value: 'Order Received',
	},
	{
		id: 'Accepted',
		title: 'Accepted',
		label: 'Accepted',
		value: 'Accepted',
	},
	{
		id: 'Ready For Pickup',
		title: 'Ready For Pickup',
		label: 'Ready For Pickup',
		value: 'READY_FOR_PICKUP',
	},
	{
		id: 'Out For Delivery',
		title: 'Out For Delivery',
		label: 'Out For Delivery',
		value: 'OUT_FOR_DELIVERY',
	},
	{
		id: 'Order Fulfilled',
		title: 'Order Fulfilled',
		label: 'Order Fulfilled',
		value: 'Order Fulfilled',
	},
	{
		id: 'Payment Pending',
		title: 'Payment Pending',
		label: 'Payment Pending',
		value: 'Payment Pending',
	},
	{
		id: 'Order Cancelled / Payment Failed',
		title: 'Order Cancelled / Payment Failed',
		label: 'Order Cancelled / Payment Failed',
		value: 'Order Cancelled / Payment Failed',
	},
	{
		id: 'Refunded',
		title: 'Refunded',
		label: 'Refunded',
		value: 'Refunded',
	},
];

const FIND_FILTERS = [
	'filteredStore',
	'status',
	'dateRange',
	'timeType',
	'customExclusiveFilters',
];

const OrdersHistory = (props) => {
	const {
		fetchData,
		data,
		count,
		dateRangeFilter,
		setDateRangeFilter,
		searchQuery,
		searchBy,
		setQCSearchResults,
		stores,
		permittedStores,
	} = props;

	const { page, limit, nextPage, prevPage, setPage } = usePagination({
		customLimit: 10,
	});

	const [countState, setCountState] = useState();
	// eslint-disable-next-line no-unused-vars
	const [timeTypeIndex, setTimeTypeIndex] = useState(0);
	const [selectedOrderStatus, setSelectedOrderStatus] = useState(0);
	const [storeId, setStoreId] = useState('');
	const filterStateRef = useRef({});

	let countObj = {};

	useEffect(() => {
		if (count !== null && countObj?.count === undefined) {
			countObj = {
				count,
			};
			setCountState(countObj.count);
		}
	}, [count]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,

		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	const [queryTracker, setQueryTracker] = useState(0);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		if (from != null && to != null) {
			setPage(1);
			const searchKey =
				SEARCH_BY?.options?.[searchField !== -1 ? searchField : 0]?.key;
			const req = {
				data: {
					mobile: searchKey === 'MOBILE' ? query : '',
					orderId: searchKey === 'ORDER_ID' ? query : '',
					partnerOrderId: searchKey === 'PARTNER_ORDER_ID' ? query : '',
					platformOrderId: searchKey === 'PLATFORM_ORDER_ID' ? query : '',
					dateType: TIME_TYPES[timeTypeIndex]?.value,
					orderStatus: ORDER_STATUS[selectedOrderStatus]?.value,
					storeId,
				},
				params: {
					page,
					limit,
				},
			};
			fetchData(req);
		}
	};

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setQCSearchResults('', data, 'ORDER_ID');
		}
	}, [queryParams]);

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	const handleOrderSearch = (filters) => {
		setQueryTracker(1);
		setPage(1);
		const searchKey =
			SEARCH_BY?.options?.[searchField !== -1 ? searchField : 0]?.key;
		const req = {
			data: {
				fromDate: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
				toDate: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
				mobile: searchKey === 'MOBILE' ? query : '',
				orderId: searchKey === 'ORDER_ID' ? query : '',
				partnerOrderId: searchKey === 'PARTNER_ORDER_ID' ? query : '',
				platformOrderId: searchKey === 'PLATFORM_ORDER_ID' ? query : '',
				dateType: filters?.timeType,
				storeId: filters?.store,
				orderStatus: filters?.status?.[0],
				orderType: filters?.customExclusiveFilters?.orderTypes,
			},
			params: {
				page,
				limit,
			},
		};
		fetchData(req);
	};

	const handleTimeTypeChange = (e) => {
		const { fieldValue: index } = inputHelper(e);
		setTimeTypeIndex(index);
		handleOrderSearch({
			dateType: TIME_TYPES[index]?.value,
			orderStatus: ORDER_STATUS[selectedOrderStatus]?.value,
			reqStoreId: storeId,
		});
	};
	const handleOrderStatusChange = (e) => {
		const { fieldValue: index } = inputHelper(e);
		setSelectedOrderStatus(index);
		handleOrderSearch({
			dateType: TIME_TYPES[timeTypeIndex]?.value,
			reqStoreId: storeId,
			orderStatus: ORDER_STATUS[index]?.value,
		});
	};

	const handleOrderHistoryCsv = async () => {
		const searchKey =
			SEARCH_BY?.options?.[searchField !== -1 ? searchField : 0]?.key;
		const req = {
			data: {
				...filterStateRef?.current,
			},
		};
		try {
			const response = await orderAnalyticsServices.downloadCsv({
				req,
			});
			if (
				response.ok &&
				response?.headers
					?.get('Content-Type')
					?.includes('application/zip')
			) {
				const blob = await response?.blob();
				const downloadUrl = window.URL.createObjectURL(blob);
				const hiddenElement = document.createElement('a');
				hiddenElement.href = downloadUrl;
				hiddenElement.download = `order_history_${moment(
					dateRangeFilter?.from
				).format('YYYY-MM-DD')}-${moment(dateRangeFilter?.to).format(
					'YYYY-MM-DD'
				)}.zip`;
				document.body.appendChild(hiddenElement);
				hiddenElement.click();
				window.URL.revokeObjectURL(downloadUrl);
				document.body.removeChild(hiddenElement);
			} else {
				const res = await response?.json();
				notificationServices.generateNotification({
					type: 'error',
					message: res?.message ?? res?.errorMessage,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const BASE_STORES = useMemo(() => {
		const validStores = {};
		permittedStores.forEach((validStoreLoop) => {
			validStores[validStoreLoop.id] = true;
		});
		const tempStore = stores?.filter((storeFilter) => {
			return permittedStores.length === 0 || validStores[storeFilter.id];
		})?.map((tStore) => {
			return {
				id: tStore.id,
				label: tStore?.name ?? '',
				value: tStore.id,
				role: tStore?.type,
				title: tStore?.name ?? '',
				type: tStore?.type,
			};
		});
		tempStore?.unshift({
			id: '',
			value: '',
			label: 'ALL',
			title: 'ALL',
			type: 'ALL',
		});
		return tempStore;
	}, [stores]);

	const footerComponent = () => {
		return (
			<div className='flex items-center gap-2'>
				<div className={styles.bill_type}>
					<SearchBar
						value={query}
						placeholder={PLACE_HOLDERS[searchField]}
						handleInput={handleSearch}
						handleChange={handleSearchBy}
						// onChange={onChange}
						searchBy={
							SEARCH_BY != null
								? {
									...(SEARCH_BY ?? {}),
									current:
											searchField === -1
												? 0
												: searchField,
								}
								: null
						}
						className={styles.search}
						showBtn
						handleOrderSearch={handleOrderSearch}
					/>
				</div>
			</div>
		);
	};

	const customExclusiveFilters = [
		{
			key: 'orderTypes',
			filterList: orderTypes?.map((ele) => {
				return {
					// eslint-disable-next-line prefer-template
					id: ele,
					// title: ele.storeName,
					// eslint-disable-next-line prefer-template
					title: ele,
				};
			}),
			allowNoSelection: true,
			label: 'Select Order Type',
			defaultState: null,
			hideDone: true,
		},
	];

	const handleFiltersState = (filters) => {
		const searchKey = SEARCH_BY?.options?.[searchField !== -1 ? searchField : 0]?.key;
		filterStateRef.current = {
			from: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
			to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
			mobile: searchKey === 'MOBILE' ? query : '',
			orderId: searchKey === 'ORDER_ID' ? query : '',
			partnerOrderId: searchKey === 'PARTNER_ORDER_ID' ? query : '',
			platformOrderId: searchKey === 'PLATFORM_ORDER_ID' ? query : '',
			dateType: filters?.timeType,
			storeId: filters?.store,
			orderStatus: filters?.status?.[0] ?? '',
			orderType: filters?.customExclusiveFilters?.orderTypes,
		};
	};

	const reqOptions = useMemo(() => {
		const searchKey =
			SEARCH_BY?.options?.[searchField !== -1 ? searchField : 0]?.key;

		return {
			data: {
				fromDate: moment(filterStateRef?.current?.from).format(
					'YYYY-MM-DD'
				),
				toDate: moment(filterStateRef?.current?.to).format(
					'YYYY-MM-DD'
				),
				mobile: searchKey === 'MOBILE' ? query : '',
				orderId: searchKey === 'ORDER_ID' ? query : '',
				partnerOrderId: searchKey === 'PARTNER_ORDER_ID' ? query : '',
				platformOrderId: searchKey === 'PLATFORM_ORDER_ID' ? query : '',
				...filterStateRef?.current,
			},
			params: {
				page,
				limit,
			},
		};
	}, [searchField, page, limit]);

	return (
		<div className={styles.root}>
			<OrderItemsModal />
			<ListModal selectionBehaviour='SHOW' />
			<div className='p-2'>
				<ImagesFinder
					pageLabel='Customer Order List'
					FILTERS={FIND_FILTERS}
					onSubmit={handleOrderSearch}
					// update={update}
					statusList={ORDER_STATUS}
					timeTypeArray={TIME_TYPES}
					additionInfo={{
						// showAdd: userPermissions.INTERNAL,
						showAdd: false,
						addText: 'Bill',
						handleAddClick: null,
					}}
					footerComponent={footerComponent}
					customExclusiveFilters={customExclusiveFilters}
					onUpdate={handleFiltersState}
				/>
			</div>
			{/* <div className={styles.header}>
				<div className={styles['search-container']}>
					<SearchBar
						value={query}
						placeholder={PLACE_HOLDERS[searchField]}
						handleInput={handleSearch}
						handleChange={handleSearchBy}
						// onChange={onChange}
						searchBy={
							SEARCH_BY != null
								? {
									...(SEARCH_BY ?? {}),
									current:
										searchField === -1
											? 0
											: searchField,
								}
								: null
						}
						className={styles.search}
						showBtn
						handleOrderSearch={handleOrderSearch}
					/>
					<Select
						className={styles['order-status']}
						value={selectedOrderStatus}
						onChange={handleOrderStatusChange}
					>
						{ORDER_STATUS?.map((option, idx) => {
							const { title } = option;
							return (
								<MenuItem value={idx} key={title}>
									{title}
								</MenuItem>
							);
						})}
					</Select>
				</div>
				<div>
					<SelectPicker
						data={BASE_STORES}
						style={{
							width: 224,
						}}
						groupBy='type'
						placeholder='Select Store'
						onChange={(id) => {
							setStoreId(id);
							handleOrderSearch({
								dateType: TIME_TYPES[timeTypeIndex]?.value,
								reqStoreId: id,
								orderStatus:
									ORDER_STATUS[selectedOrderStatus]?.value,
							});
						}}
						renderMenuItem={(_, item) => {
							return (
								<div>
									<i className='rs-icon rs-icon-user' />
									{' '}
									{_}
								</div>
							);
						}}
						cleanable={false}
						renderMenuGroup={(_, item) => {
							return (
								<div>
									<i className='rs-icon rs-icon-group' />
									{_}
								</div>
							);
						}}
						value={storeId}
						renderValue={(value, item) => {
							const selectedItem = BASE_STORES.find((ele) => {
								return ele?.value === storeId;
							});
							return (
								<div className='rsuite-select-picker-render-value'>
									{'Store: '}
									{selectedItem?.label}
								</div>
							);
						}}
					/>
				</div>
				<div className={styles.dateFilter}>
					<div className={styles.dateType}>
						<Select
							className={styles['search-by']}
							value={timeTypeIndex}
							onChange={handleTimeTypeChange}
						>
							{TIME_TYPES?.map((option, idx) => {
								const { title } = option;
								return (
									<MenuItem value={idx} key={title}>
										{title}
									</MenuItem>
								);
							})}
						</Select>
					</div>
					<Calender
						value={dateRangeFilterArray}
						allowFutureDates
						setValue={setDate}
					/>
				</div>
			</div> */}
			<OrderHistoryList
				page={page}
				limit={limit}
				nextPage={nextPage}
				prevPage={prevPage}
				setPage={setPage}
				storeId={storeId}
				data={data}
				timeType={TIME_TYPES[timeTypeIndex]?.value}
				orderStatus={ORDER_STATUS[selectedOrderStatus]?.value}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				listCount={countState}
				SEARCH_BY={SEARCH_BY}
				searchField={searchField}
				query={query}
				fetchData={fetchData}
				queryTracker={queryTracker}
				handleOrderHistoryCsv={handleOrderHistoryCsv}
				reqOptions={reqOptions}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST_DATA: data } = state.analytics.ORDER_HISTORY;
	const stores = state?.form?.STORES?.data?.LIST;
	const count = data ? data.recordCount : 0;
	const { query: searchQuery, searchBy } =
		state.analytics.ORDER_HISTORY.SEARCH_DATA;
	const { permittedStores } = state.navigation;
	return {
		stores,
		dateRangeFilter,
		data: data?.orders,
		count,
		searchQuery,
		searchBy,
		permittedStores,
	};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	fetchData: analyticsAction.fetchData,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setQCSearchResults: analyticsAction.setQCSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory);
