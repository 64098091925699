import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from './MyRevenue.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import storeServices from '../../../Services/Form Services/store.services';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const COLMAP = {
	ID: 'id',
	Store: 'storeId',
	Date: '_date',
	Amount: 'revenue',
};

const FIND_FILTERS = ['filteredStore', 'dateRangeOnly'];

function MyRevenue({ userPermissions, createPath, stores }) {
	const history = useHistory();
	const [isloading, setIsloading] = useState(false);
	const [usersData, setUsersData] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null);

	const handleListFetch = async (filters) => {
		setIsloading(true);
		try {
			const response = await storeServices.fetchRevenues({
				data: {
					storeId: filters?.store ?? '',
					dateRangeFilter: {
						from: moment(filters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filters?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
					},
				},
			});
			if (response?.statusCode === 200) {
				setSelectedStore(selectedStore);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				setUsersData(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {
		Store: (storeId) => {
			const store = stores?.find((ele) => {
				return ele?.id === storeId;
			});
			return store?.name;
		},
		Amount: (revenue) => {
			let numStr = String(revenue);

			// Use regex to add commas after every 3 digits from the end
			numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

			return numStr;
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<div className={styles.header}>
				<ImagesFinder
					allStoreOption={false}
					pageLabel='Revenue Listing'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					storeTypes='RETAIL,BASE'
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'Revenue',
						handleAddClick,
					}}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={usersData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;

	return {
		userPermissions,
		stores,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRevenue);
