import { ArrowForward } from '@mui/icons-material';
import {
	MenuItem,
	Select,
} from '@mui/material';
import moment from 'moment';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { analyticsAction, formActions } from '../../../../../Actions';
import Calender from '../../../../../Components/Forms/Payment Settlements/Calender/Calender';
import { classes, inputHelper } from '../../../../../Helpers';
import styles from './WorkOrderListHeader.module.css';

function WorkOrderListHeader(props) {
	const {
		dateRangeFilter,
		setDateRangeFilter,
		fetchStoresList,
		stores,
		fetchList,
	} = props;
	const [selectedStore, setSelectedStore] = useState(-1);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const selectStore = (e) => {
		const { fieldValue } = inputHelper(e);
		setSelectedStore(fieldValue);
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const setDate = (newValues) => {
		const { startDate: from, endDate: to } = newValues?.selection ?? {};
		setDateRangeFilter({
			from,
			to,
		});
	};

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore?.id,
				title: tStore?.name ?? '',
			};
		});
		return tempStore;
	}, [stores]);

	const fetchWorkOrderList = () => {
		const req = {
			data: {
				filters: {
					dateRangeFilter: {
						from: moment(dateRangeFilter.from).format('YYYY-MM-DD'),
						to: moment(dateRangeFilter.to).format('YYYY-MM-DD'),
					},
				},
				storeId: selectedStore === -1 ? null : selectedStore,
			},
		};
		fetchList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'WORK_ORDER_LIST',
			req,
		});
	};
	return (
		<div
			className={classes(
				styles.root
			)}
		>
			<div className={styles.storeDiv}>
				Select Store
				<Select
					className={styles['store-select']}
					value={selectedStore}
					onChange={selectStore}
					disabled={false}
					defaultValue={-1}
				>
					<MenuItem
						key={-1}
						value={-1}
					>
						All
					</MenuItem>
					{STORES?.map((storeObj) => {
						return (
							<MenuItem
								key={storeObj?.id + storeObj?.title}
								value={storeObj?.id}
							>
								{storeObj.title}
							</MenuItem>
						);
					})}
				</Select>
			</div>
			<Calender
				allowFutureDates
				value={dateRangeFilterArray}
				setValue={setDate}
			/>
			<div
				className={styles['search-icon']}
				onClick={fetchWorkOrderList}
			>
				<ArrowForward className={styles.icon} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;

	return {
		stores,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchList: analyticsAction.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WorkOrderListHeader);
