/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
import { ArrowCircleRight } from '@mui/icons-material';
import {
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import _ from 'lodash';
import { useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import EditChildModal from '../../../Components/Modals/EditChildModal/EditChildModal';
import { urls } from '../../../Constants';
import styles from './ChildList.module.scss';
import SearchBar from '../../../Components/SearchBar/SearchBar';

const initialState = {
	number: '',
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_NUMBER':
			return {
				...state,
				number: action.payload,
			};
		default:
			return state;
	}
};

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Class',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Section',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mobile',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'School Name',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [];

const COLMAP = {
	Name: 'addressLine1',
	Class: 'addressLine2',
	Section: 'city',
	Mobile: 'mobile',
	'School Name': 'storeName',
};
const ChildListPage = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [nameSearch, setNameSearch] = useState('');
	const [classSearch, setClassSearch] = useState('');
	const { fetchList, data, toggleModal } = props;
	const history = useHistory();

	const handleInputChange = (e) => {
		dispatch({
			type: 'SET_NUMBER',
			payload: e.target.value,
		});
	};

	const isDisabled = useMemo(() => {
		return (
			(!state?.number && !state?.number?.match(/^([+]\d{2})?\d{10}$/)) &&
			nameSearch?.length === 0 &&
			classSearch?.length === 0
		);
	}, [state?.number, nameSearch, classSearch]);

	const handleSearch = () => {
		fetchList({
			entity: 'CHILD',
			req: {
				data: {
					mobile: state?.number,
					addressLine1: nameSearch,
					addressLine2: classSearch,
				},
			},
			method: 'CHILD_LIST',
		});
	};

	const handleAddChild = () => {
		history.push(`${urls.EXTENSION}${urls.ADD_CHILD}`);
	};

	const handleEditClick = (item) => {
		toggleModal(true, item);
	};

	const SPECIAL_COLUMNS = [
		{
			title: 'EDIT',
			config: {
				type: 'DATA',
			},
		},
	];

	const handleValuesInputChange = (e = {}) => {
		const { name, value } = e?.target;
		if (name === 'name') {
			setNameSearch(value);
		}
		if (name === 'class') {
			setClassSearch(value);
		}
	};

	const SPECIAL_ITEMS = {
		EDIT: (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<Button
					onClick={() => {
						handleEditClick(item);
					}}
					type='button'
					variant='outlined'
				>
					Edit
				</Button>
			);
		},
	};

	return (
		<div className={styles.root}>
			<EditChildModal handleSearch={handleSearch} />
			<div className={styles.header}>
				<div className={styles['input-container']}>
					<TextField
						fullWidth
						name='email'
						onChange={handleInputChange}
						value={state?.number}
						label='Mobile Number'
					/>
					<TextField
						fullWidth
						name='name'
						onChange={handleValuesInputChange}
						value={nameSearch}
						label='Child Name'
					/>
					<FormControl fullWidth>
						<InputLabel id='class-label'>Class</InputLabel>
						<Select
							name='class'
							onChange={handleValuesInputChange}
							value={classSearch}
							labelId='class-label'
							label='Class'
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11}>11</MenuItem>
							<MenuItem value={12}>12</MenuItem>
						</Select>
					</FormControl>
					<IconButton
						onClick={handleSearch}
						type='button'
						className={styles.btn}
						disabled={isDisabled}
					>
						<ArrowCircleRight />
					</IconButton>
				</div>
				<Button
					type='button'
					variant='contained'
					onClick={handleAddChild}
				>
					+ Add Child
				</Button>
			</div>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data}
				actions={[...ACTION_COLUMNS, ...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = _.cloneDeep(state.form.CHILD.data.CHILD_LIST?.address);
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchList: formActions.gateway,
	toggleModal: modalActions.toggleEditChildModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildListPage);
