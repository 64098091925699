import { endpoints } from '../Constants';
import {
	authHeader, handleResponse, toQueryStringURL
} from '../Helpers';

const userRolesAndPermissions = {
	getUserRoles,
	getUserLevels,
	addUser,
	getUsersList,
	getUser,
	getUserStores,
	getUserRolesAndPermissions,
	addUserRole,
	getUsersByRole,
	changeUserActiveStatus,
	getUserExport,
};

function addUserRole(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CREATE_USER_ROLE, requestOptions).then(handleResponse);
}
function changeUserActiveStatus(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CHANGE_ACTIVE_STATUS, requestOptions).then(
		handleResponse
	);
}

function getUserRoles() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(endpoints.LIST_USER_ROLES, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data.data.map((item) => {
					return {
						label: item.userRole,
						value: item.id,
					};
				});
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch User Roles');
		});
}

function getUserLevels() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(endpoints.LIST_USER_LEVELS, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data.data.map((item) => {
					return {
						name: item.level,
						value: item.id,
						description: item.description,
					};
				});
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch User Levels');
		});
}

function addUser(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CREATE_USER_PER, requestOptions).then(handleResponse);
}

function getUsersByRole(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			clientId: 5,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	return fetch(endpoints.USER_BY_ROLE, requestOptions).then(handleResponse);
}

function getUsersList() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(endpoints.LIST_USERS, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data;
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch Users List');
		});
}

function getUser(req) {
	const params = {
		...req.params,
	};
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(toQueryStringURL(endpoints.GET_USER, params), requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data;
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch User');
		});
}

function getUserStores() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(endpoints.GET_USER_STORES, requestOptions)
		.then(handleResponse);
}

function getUserRolesAndPermissions(req) {
	const params = {
		...req.params,
	};
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(toQueryStringURL(endpoints.GET_USER_ROLES_AND_PERMISSIONS, params), requestOptions)
		.then(handleResponse);
}
function getUserExport({ req } = {
	req: {},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.USER_ACCESS_EXPORT, requestOptions);
}
export default userRolesAndPermissions;
