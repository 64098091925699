import { Tooltip } from '@mui/material';

const CookingProcessesTabs = (props) => {
	const { processes = {}, activeStage, setActiveStage } = props;
	return (
		<div className='w-full'>
			<p className='text-xl pb-2'>Cooking Processes</p>
			{Object.entries(processes)?.map(([key, value]) => {
				// eslint-disable-next-line eqeqeq
				const isSelected = key == activeStage;
				const forStages = value?.forStage?.map((stageKey) => {
					return processes?.[stageKey];
				});
				const forStagesProducts = forStages?.map((ele) => {
					return ele?.stageProduct?.productName;
				});
				const forStagesProductsName = forStagesProducts?.join(', ');
				return (
					<div
						onClick={() => {
							setActiveStage(key);
						}}
						className={`bg-gradient-to-r from-[${
							value?.process?.color ?? ''
						}] rounded-xl p-2 min-w-[275px] cursor-pointer ${
							isSelected
								? 'rounded-r-none border border-slate-400 font-semibold'
								: ''
						}`}
						style={{
							background: isSelected
								? `linear-gradient(90deg, ${value?.process?.color ?? '#132a13'} 25%, rgba(241,241,241,0.75) 60%)`
								: `linear-gradient(90deg, ${value?.process?.color ?? '#132a13'} 0%, rgba(241,241,241,0.75) 10%)`,
						}}
					>
						<div className='w-full h-full bg-white rounded-xl relative p-2'>
							<div className='absolute top-1 right-1'>
								<p className='text-xs text-gray-400 font-medium'>
									v
									{value?.recipeData?.version}
								</p>
							</div>
							<p className='text-xl bg-blend-color-dodge'>{value?.name}</p>
							<p className='text-sm m-0'>
								{value?.process?.heading ?? '-'}
							</p>
							{value?.forStage?.length && (
								<div className='flex gap-2 text-sm'>
									<p>For: </p>
									<Tooltip title={forStagesProducts?.join(', ')}>
										{forStagesProductsName?.length > 24
											? `${forStagesProductsName?.substring(0, 24)}...`
											: forStagesProductsName}
									</Tooltip>
								</div>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default CookingProcessesTabs;
