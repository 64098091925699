import { Tooltip } from '@mui/material';
import GSTInComp from '../GSTInComp/GSTInComp';
import styles from './POVendorDetails.module.scss';

const POVendorDetails = (props) => {
	const { vendorDetails, toggleAddVendorModal, showLabel = true } = props;
	return (
		<div className={styles['label-container']}>
			{showLabel && (
				<label htmlFor='vendorDetails' />
			)}
			<div className={styles['vendor-details']}>
				{vendorDetails?.vendorType && (
					<p>
						Vendor Type:
						{' '}
						<Tooltip
							title={vendorDetails?.vendorType}
						>
							<b>
								{vendorDetails?.vendorType?.length > 50
									? `${vendorDetails?.vendorType?.substring(
										0,
										50
									)}...`
									: vendorDetails?.vendorType}
							</b>
						</Tooltip>
					</p>
				)}
				<GSTInComp
					gstIn={vendorDetails?.gstin}
					onClick={() => {
						toggleAddVendorModal(true, {
							vendorData: vendorDetails,
							isEdit: true,
						});
					}}
				/>
				{vendorDetails?.vendorEmail && (
					<p>
						Vendor Email:
						{' '}
						<Tooltip
							title={vendorDetails?.vendorEmail
								?.split(',')
								.join('\n')}
						>
							<b>
								{vendorDetails?.vendorEmail?.length > 50
									? `${vendorDetails?.vendorEmail?.substring(
										0,
										50
									)}...`
									: vendorDetails?.vendorEmail}
							</b>
						</Tooltip>
					</p>
				)}
				<div className={styles['address-container']}>
					{vendorDetails?.billingAddress1 && (
						<div className={styles.address}>
							<p className={styles.header}>Billing Address</p>
							<p>{vendorDetails?.billingAddress1}</p>
							<p>{vendorDetails?.billingAddress2}</p>
							{vendorDetails?.billingAddressCity && (
								<p>{`${vendorDetails?.billingAddressCity} ,${vendorDetails?.billingAddressState}`}</p>
							)}
							<p>{vendorDetails?.billingAddressPinCode}</p>
						</div>
					)}
					{vendorDetails?.shippingAddress1 && (
						<div className={styles.address}>
							<p className={styles.header}>Pickup Address</p>
							<p>{vendorDetails?.shippingAddress1}</p>
							<p>{vendorDetails?.shippingAddress2}</p>
							{vendorDetails?.shippingAddressCity && (
								<p>{`${vendorDetails?.shippingAddressCity} ,${vendorDetails?.shippingAddressState}`}</p>
							)}
							<p>{vendorDetails?.shippingAddressPinCode}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default POVendorDetails;
