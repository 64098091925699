import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import Whisper from 'rsuite/Whisper';
import { RemoveRedEye } from '@mui/icons-material';
import Popover from 'rsuite/Popover';
import styles from './MyUsersPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import { modalActions } from '../../../Actions';
import { authServices, notificationServices } from '../../../Services';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Username',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Display Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Email',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Asset',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Process',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Access Level',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'App Modules',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Edit User',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	ID: 'id',
	Username: 'username',
	'Display Name': 'displayName',
	Store: 'storeName',
	Email: 'email',
	Asset: 'assetName',
	Process: 'processName',
};

const FIND_FILTERS = ['store'];

function MyUsersPage({ userPermissions, createPath }) {
	const history = useHistory();
	const [isloading, setIsloading] = useState(false);
	const [usersData, setUsersData] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null);

	const handleListFetch = async (filters) => {
		setIsloading(true);
		try {
			const response = await authServices.fetchUsersList({
				params: {
					storeId: filters?.store ?? '',
				},
			});
			if (response?.statusCode === 200) {
				setSelectedStore(selectedStore);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				setUsersData(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleClick = (userId) => {
		history.push(`${createPath}?isEdit=true&id=${userId}`);
	};

	const SPECIAL_ITEMS = {
		'Access Level': (id) => {
			const user = usersData?.find((ele) => {
				return ele?.id === id;
			});
			return (user?.accessLevel ?? '')?.split(',')?.map((ele) => {
				return <div>{ele}</div>;
			});
		},
		'App Modules': (id) => {
			// eslint-disable-next-line no-unused-vars
			const user = usersData?.find((ele) => {
				return ele?.id === id;
			});
			if (!user?.appModules?.length) {
				return '-';
			}
			return (
				<Whisper
					placement='bottom'
					trigger='click'
					speaker={(
						<Popover>
							{(user?.appModules ?? [])?.map((ele) => {
								return <div className={styles.module}>{(ele ?? '')?.toUpperCase()}</div>;
							})}
						</Popover>
					)}
				>
					<div className={styles.modeules_view}>
						<RemoveRedEye />
					</div>
				</Whisper>
			);
		},
		'Edit User': (id) => {
			const user = usersData?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles.btn_container}>
					<Button
						fullWidth
						variant='outlined'
						color='primary'
						onClick={() => {
							handleClick(user?.id);
						}}
					>
						EDIT
					</Button>
				</div>
			);
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='My Users'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'User',
						handleAddClick,
					}}
				/>
			</div>
			<div className={styles.list}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={usersData}
					actions={[...SPECIAL_COLUMNS]}
					special={SPECIAL_ITEMS}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyUsersPage);
