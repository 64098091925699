/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable no-tabs */
import _, { cloneDeep } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import ProductDetails from '../../../Components/Forms/Products/ProductDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import productConfig from './allproduct.config';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import { getUnitConvertedUnit, inputHelper, isEmpty } from '../../../Helpers';
import { urls } from '../../../Constants';
import { formActions } from '../../../Actions';
import { useAuth } from '../../../Components/Auth/Login/auth';
import productSubCategoryServcies from '@/Services/Form Services/productSubCategoryServcies';
import ProductVariantsTable from '@/Components/Forms/Products/ProductVariantsTable';
import ProductQualityConfig from '@/Components/Forms/Products/ProductQualityConfig';
import { qualityConfig } from './quality.config';
import { notificationServices, productServices } from '@/Services';
import allProductServices from '@/Services/Form Services/allproduct.services';
import variantServices from '@/Services/Form Services/variants.services';
import qualityConfigServices from '@/Services/Form Services/qualityConfig.services';

const ProductCreatePage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	// eslint-disable-next-line object-curly-newline
	const {
		isEdit,
		saveProduct,
		fetchProduct,
		saveVariant,
		fetchVariant,
		data,
		breadcrumbs,
		brands,
		variantData,
		qualityData,
		limit,
	} = props;
	const history = useHistory();
	const firstRender = useRef(true);
	const disable = true;
	const disabledUnits =
		measurement === 'US' ? ['gram', 'litre', 'ml'] : ['oz', 'floz'];
	const [state, setState] = useState({
		...productConfig.initialState,
		fields: {
			...productConfig.initialState?.fields,
			densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
			productUnits:
				productConfig.initialState?.fields?.productUnits?.filter(
					(ele) => {
						return !disabledUnits?.includes(ele?.id);
					}
				),
			purchaseUnits:
				productConfig.initialState?.fields?.productUnits?.filter(
					(ele) => {
						return !disabledUnits?.includes(ele?.id);
					}
				),
		},
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const [disableUnit, setDisableUnit] = useState(true);
	const [subCategoriesList, setSubCategoriesList] = useState([]);
	const [qualityState, setQualityState] = useState({
		...qualityConfig?.initialState,
	});
	// variants
	const [variant, setVariantData] = useState({
		fields: cloneDeep(productConfig.initialState?.fields?.variants),
	});
	const isChanged = useRef(false);

	const { id } = useParams();
	const quality = useMemo(() => {
		return qualityData[id];
	}, [id, qualityData]);
	const unit = state?.fields?.productUnits?.find((ele) => ele?.isApplied === true)?.id;
	const pUnit = state?.fields?.purchaseUnits?.find((ele) => ele?.isApplied === true)?.id;
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data?.id === id) {
				setState(
					productConfig.serializer(state, {
						...data,
						measurement,
						densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
						variants: variantData?.variants,
					})
				);
			} else {
				fetchProduct({
					entity: 'ALL_PRODUCTS',
					req: {
						params: {
							id,
						},
					},
					method: 'VIEW',
				});
			}
			fetchVariant({
				entity: 'VARIANTS',
				req: {
					params: {
						id,
					},
				},
				method: 'VIEW',
				showNotification: true,
			});
			fetchProduct({
				entity: 'PRODUCT_QUALITY_CONFIG',
				req: {
					params: {
						id,
						combo: true,
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	const fetchSubCategories = async () => {
		try {
			const response =
				await productSubCategoryServcies.listProductSubCategory({
					req: {
						params: {
							categoryId: state?.fields?.category,
						},
					},
				});
			if (response?.statusCode === 200) {
				setSubCategoriesList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		if (state?.fields?.category) {
			fetchSubCategories();
		}
	}, [state?.fields?.category]);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(
					productConfig.serializer(state, {
						...data,
						measurement,
						densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
					})
				);
			}
			if (!isEmpty(variantData)) {
				setVariantData(productConfig?.variantSerilizer(variantData));
			}
			if (quality) {
				setQualityState(qualityConfig.serializer(state, quality));
			}
		}
	}, [data, quality, variantData]);

	// useEffect(() => {
	// 	if (state?.fields?.category) {
	// 		fetchSubCategories({
	// 			entity: 'PRODUCT_SUB_CATEGORY',
	// 			req: {
	// 				params: {
	// 					categoryId: state?.fields?.category,
	// 				},
	// 			},
	// 			method: 'LIST',
	// 		});
	// 	}
	// }, [state?.fields?.category]);

	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'image':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};
	const handleInput = (e) => {
		isChanged.current = true;
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		const newState = {};
		let haveUnit = false;
		// for form validations and error generation
		switch (fieldName) {
			case 'veg':
				newState[fieldName] = fieldValue === '1';
				break;
			case 'productClass':
				newState[fieldName] = state.fields.productClass.map(
					(productClassObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							return {
								...productClassObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productClassObject,
							isApplied: false,
						};
					}
				);
				newState.purchaseUnits = state.fields.purchaseUnits.map(
					(productUnitObject) => {
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			case 'category':
				newState.productUnits = state.fields.productUnits.map(
					(productUnitObject) => {
						if (
							productUnitObject.id ===
							brands[state.fields.brand].ProductCategory[
								fieldValue
							]?.unit
						) {
							newState.perUnit =
								productUnitObject?.id === 'floz'
									? 'Gal'
									: 'ltr';
							haveUnit = true;
							return {
								...productUnitObject,
								isApplied: true,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			case 'productUnits':
				newState[fieldName] = state.fields.productUnits.map(
					(productUnitObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							newState.perUnit =
								productUnitObject?.id === 'floz'
									? 'Gal'
									: 'ltr';
							return {
								...productUnitObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			case 'purchaseUnits':
				newState[fieldName] = state.fields.purchaseUnits.map(
					(productUnitObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							return {
								...productUnitObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			default:
				break;
		}
		if (fieldName === 'category') {
			setDisableUnit(haveUnit);
		}
		if (fieldName === 'hsnCode') {
			if (fieldValue.length > 8 || fieldValue.length === 5 || fieldValue.length === 7) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'HSN Code length must be either 4 or 6 or 8 digits.',
				});
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue.slice(0, 8),
						...newState,
					},
				});
				return;
			}
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
		});
	};

	// const handleSave = (e) => {
	// 	e.preventDefault();
	// 	const deserializer = qualityConfig?.deserilizer(qualityState);
	// 	const productDeserilizer = productConfig.deserializer(state);
	// 	const variantDeserlizer = productConfig.variantDeserlizer(state);
	// 	console.log(deserializer, 'deserializer');
	// 	console.log(productDeserilizer, 'productdeserlizer');
	// 	console.log(variantDeserlizer, 'variantDeserlizer');
	// 	// const isValid = productConfig.validator(state);
	// 	const isValid = false;
	// 	if (isValid) {
	// 		setNumberOfSubmissions(numberOfSubmissions + 1);
	// 		productConfig.deserializer(state).then((req) => {
	// 			if (isEdit) {
	// 				req.params = {
	// 					id,
	// 				};
	// 				saveProduct({
	// 					entity: 'ALL_PRODUCTS',
	// 					req,
	// 					method: 'UPDATE',
	// 					redirect: `${urls.EXTENSION}${urls.ALL_PRODUCTS}`,
	// 				});
	// 			} else {
	// 				saveProduct({
	// 					entity: 'ALL_PRODUCTS',
	// 					req,
	// 					method: 'CREATE',
	// 					redirect: `${urls.EXTENSION}${urls.ALL_PRODUCTS}`,
	// 				});
	// 			}
	// 		});
	// 	}
	// };

	const handleEdit = async (req) => {
		const valid = productConfig?.validator(state, variant, isEdit);
		if (!valid) return;
		if (!isChanged.current) {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
			return;
		}
		try {
			req.params = {
				id,
	  };
	  req.data = {
				...req.data,
				variants: variant?.fields?.variants[0],
	  };
	  const productResponse = await allProductServices?.updateProduct({
				req,
	  });
	  const variantRequest = {
		  params: {
			  id,
		  },
		  data: {
			  variants: productConfig?.variantDeserlizer(variant),
		  },
	  };
	 const variantResponse = await variantServices?.updateProduct({
				req: variantRequest,
			});
	  const qualityRequest = {
		  params: {
			  id,
		  },
		  data: {
			  ...qualityConfig?.deserilizer(qualityState),
		  },
	  };
			const qualityResponse = await qualityConfigServices.updateConfig({
				req: qualityRequest,
			});
	  if (productResponse?.statusCode !== 200 || qualityResponse?.statusCode !== 200 || variantResponse?.statusCode !== 200) {
				throw new Error('Failed to save Product.');
	  } else {
				notificationServices?.generateNotification({
					type: 'success',
					message: 'Product successfully Update!',
				});
				history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
	  }
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error.message || 'An error occurred',
			});
		}
	};

	const handleSave = async (e) => {
		e.preventDefault();

		const valid = productConfig?.validator(state);
		if (!valid) return;

		try {
			let productId = null;
			const req = await productConfig.deserializer(state);
			const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

			if (isEdit) {
				await handleEdit(req);
			} else {
				const productResponse = await allProductServices?.createProduct({
					req: {
						data: req?.data,
					},
				});
				productId = productResponse?.data?.productId;
				if (productId) {
					const variantRequest = {
						params: {
							id: productId,
						},
						data: {
							variants: productConfig?.variantDeserlizer(state),
						},
					};
					await delay(1000);
					const variantResponse = await variantServices?.updateProduct({
						req: variantRequest,
					});
					const qualityRequest = {
						params: {
							id: productId,
						},
						data: {
							...qualityConfig?.deserilizer(qualityState),
						},
					};
					await delay(1000);
					const qualityResponse = await qualityConfigServices.updateConfig({
						req: qualityRequest,
					});
					if (productResponse?.statusCode !== 200 || variantResponse?.statusCode !== 200 ||
						qualityResponse?.statusCode !== 200) {
						throw new Error(resp?.message || 'Failed to save Product.');
					} else {
						notificationServices?.generateNotification({
							type: 'success',
							message: 'Product successfully created!',
						});
						history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
					}
				}
			}
			setNumberOfSubmissions((prev) => prev + 1);
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error.message || 'An error occurred',
			});
		}
	};

	const generateDefaultOption = (type, brand, category, subcategory) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			case 'category':
				if (brand) {
					if (!category || category.length === 0) {
						return (
							<option value={null} selected disabled hidden>
								Select a Category
							</option>
						);
					}
				} else {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand First
						</option>
					);
				}
				break;
			case 'subCategory':
				if (category) {
					if (!subcategory || subCategoriesList?.length === 0) {
						return (
							<option value={null} selected disabled hidden>
								Select a Sub Category
							</option>
						);
					}
				}
				return (
					<option value={null} selected disabled hidden>
						Select a Category First
					</option>
				);
			default:
				break;
		}
		return <></>;
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
		}
	};

	const excludedFields = useMemo(() => {
		let exclude = {};
		const productUnit = state?.fields?.productUnits?.reduce(
			(finalUnit, currUnit) => {
				if (currUnit?.isApplied) {
					return currUnit?.id;
				}
				return finalUnit;
			},
			''
		);

		if (
			productUnit === 'litre' ||
			productUnit === 'ml' ||
			productUnit === 'floz'
		) {
			exclude = {};
		} else {
			exclude.productDensity = true;
		}
		return exclude;
	}, [state?.fields?.productUnits]);
	//  varient
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		if (isEdit) {
			setVariantData(_.update(_.cloneDeep(variant), 'fields.variants', (variant) => {
				const newState = variant.filter((variant, idx) => {
					return idx != dataset.id;
				});
				return newState;
			}));
		} else {
			setState(_.update(_.cloneDeep(state), 'fields.variants', (variant) => {
				const newState = variant.filter((variant, idx) => {
					return idx != dataset.id;
				});
				return newState;
			}));
		}
	};

	const getMinMaxUnit = (productUnit, weight, measurementType) => {
		let tempUnit = productUnit;
		if (productUnit === 'count') {
			if (measurementType === 'US') {
				tempUnit = 'oz';
			} else {
				tempUnit = 'gram';
			}
		}
		return getUnitConvertedUnit(weight, tempUnit, true);
	};
	const getPriceUnit = (productUnit) => {
		switch (productUnit) {
			case 'count':
				return 'piece';
			case 'ltr':
				return 'ltr';
			case 'floz':
				return 'gal';
			case 'oz':
				return 'lbs';
			case 'gram':
				return 'kg';
			case 'ml':
				return 'ltr';
			default:
				return productUnit;
		}
	};
	const getNewVariantUnits = (variants) => {
		  const updatedVariant = {
			...variants,
		};
		//   const unit = state?.fields?.productUnits?.find((ele) => ele?.isApplied === true)?.id;
		//   const pUnit = state?.fields?.purchaseUnits?.find((ele) => ele?.isApplied === true)?.id;

		  updatedVariant.minUnit = getMinMaxUnit(unit, 1);
		  updatedVariant.maxUnit = getMinMaxUnit(unit, 1);
		  updatedVariant.containerUnit = getUnitConvertedUnit(0, unit, true);
		  updatedVariant.costPriceUnit = getPriceUnit(pUnit || unit);
		  updatedVariant.transferPriceUnit = getPriceUnit(pUnit || unit);
		  updatedVariant.sellingPriceUnit = getPriceUnit(pUnit || unit);
		  return updatedVariant;
	  };

	  useEffect(() => {
		// Skip the first render to prevent unwanted execution
		if (firstRender.current) {
		  firstRender.current = false;
		  return;
		}

		// Only proceed when the unit or pUnit changes (and not on first render)
		const variantUnit = isEdit
		  ? variant?.fields?.variants.map((ele) => {
			  const units = getNewVariantUnits(ele);
			  return {
					...ele,
					...units,
			  };
			})
		  : state?.fields?.variants.map((ele) => {
			  const units = getNewVariantUnits(ele);
			  return {
					...ele,
					...units,
			  };
			});

		if (isEdit) {
		  // When in edit mode, update using `setVariantData`
		  setVariantData((prevState) => ({
				...prevState,
				fields: {
			  ...prevState.fields,
			  variants: variantUnit,
				},
		  }));
		} else {
		  // When not in edit mode, update using `setState`
		  setState((prevState) => ({
				...prevState,
				fields: {
			  ...prevState.fields,
			  variants: variantUnit,
				},
		  }));
		}
	  }, [unit, pUnit, isEdit]);
	const addNewVariant = () => {
		const unit = state?.fields?.productUnits?.find((ele) => ele?.isApplied === true)?.id;
		const purchaseUnit = state?.fields?.purchaseUnits?.find((ele) => ele?.isApplied === true)?.id;
		if (isEdit) {
			const editCurrentVariants = _.get(variant, 'fields.variants', []);
			if (
				editCurrentVariants.length > 0 &&
				((purchaseUnit == null && unit !== 'count') ||
				(purchaseUnit != null && purchaseUnit !== 'count'))
			  ) {
				notificationServices.generateNotification({
					  type: 'error',
					  message: 'Multiple Variants not allowed for this product',
				});
				return;
			  }
			  setVariantData(
				_.update(_.cloneDeep(variant), 'fields.variants', (variants) => {
					  const newArr = variants;
					  const newUnits = getNewVariantUnits(variants);
					const newV = {
						...productConfig?.newVariant,
						...newUnits,
					};
					if (newArr.length >= 10) {
			  			notificationServices.generateNotification({
							type: 'error',
							message: "Can't add more than 10 Variants",
			  			});
					} else if (newArr.length === 0) {
						newV.isDefault = true;
					}
					newArr.push(newV);
					return newArr;
				})
			  );
		} else {
			const currentVariants = _.get(state, 'fields.variants', []);

			if (currentVariants.length > 0 &&
		  ((purchaseUnit == null && unit !== 'count') ||
			(purchaseUnit != null && purchaseUnit !== 'count'))
			) {
		  		notificationServices.generateNotification({
					type: 'error',
					message: 'Multiple Variants not allowed for this product',
		  });
		  return;
			}
			setState(
		  _.update(_.cloneDeep(state), 'fields.variants', (variant) => {
					const newArr = variant;
					const newUnits = getNewVariantUnits(variant);
					const newV = {
						...productConfig?.newVariant,
						...newUnits,
					};
					if (newArr.length >= 10) {
			  			notificationServices.generateNotification({
							type: 'error',
							message: "Can't add more than 10 Variants",
			  			});
					} else if (newArr.length === 0) {
						newV.isDefault = true;
					}
					newArr.push(newV);
					return newArr;
		  })
			);
		}
	  };

	const handleVariantInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		isChanged.current = true;
		// Extract the values of the state variables
		if (isEdit) {
			if (fieldName === 'pieces') {
				const reg = /^[\d,]+$/;
				if (reg.test(fieldValue)) {
					setVariantData(
						_.update(
							_.cloneDeep(variant),
									`fields.variants[${dataset.id}][${fieldName}]`,
									() => {
										if (fieldName === 'isDisabled') return !fieldValue;
										return fieldValue;
									}
						)
					);
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Only Comma and Number values are allowed',
					});
				}
			} else {
				const updatedState = _.cloneDeep(variant);
				_.set(updatedState, `fields.variants[${dataset.id}][${fieldName}]`, fieldValue);
				_.set(updatedState, `fields.variants[${dataset.id}]`, {
			  ...updatedState.fields.variants[dataset.id],
				});
				setVariantData(updatedState);
			}
			return;
		}
		if (fieldName === 'pieces') {
			const reg = /^[\d,]+$/;
			if (reg.test(fieldValue)) {
				setState(
					_.update(
						_.cloneDeep(state),
								`fields.variants[${dataset.id}][${fieldName}]`,
								() => {
									if (fieldName === 'isDisabled') return !fieldValue;
									return fieldValue;
								}
					)
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Only Comma and Number values are allowed',
				});
			}
		} else {
			const updatedState = _.cloneDeep(state);

			_.set(updatedState, `fields.variants[${dataset.id}][${fieldName}]`, fieldValue);

			_.set(updatedState, `fields.variants[${dataset.id}]`, {
		  ...updatedState.fields.variants[dataset.id],
			});
			setState(updatedState);
		}
	  };

	const handleSaveUnits = (name, value, id) => {
		setState(
			_.update(
				_.cloneDeep(state), `fields.variants[${id}][${name}]`,
				() => {
					return value;
				}
			)
		);
	};
	const handleToggle = (value, name, variantIdx) => {
		if (isEdit) {
			setVariantData(
				_.update(_.cloneDeep(variant), 'fields.variants', (variants) => {
					const newArr = variants;
					newArr[variantIdx][name] = value;
					return newArr;
				})
			);
		} else {
			setState(
				_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
					const newArr = variants;
					newArr[variantIdx][name] = value;
					return newArr;
				})
			);
		}
	};
	// const handleSelectedValue = (value, name, id) => {
	// 	setState(
	// 		_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
	// 			const newArr = variants;
	// 			newArr[id][name] = value;
	// 			return newArr;
	// 		})
	// 	);
	// };
	// qualityconfig
	const handleQualityInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		isChanged.current = true;

		setQualityState((prevState) => {
			const newFields = {
				...prevState.fields,
				defectParams: {
					...prevState.fields.defectParams,
				},
			};

			switch (dataset.type) {
				case 'QMS':
				case 'weight':
				case 'temperature':
					newFields[fieldName] = fieldValue;
					break;

				case 'defect':
					if (fieldName === 'defectCheck') {
						newFields.defectCheck = fieldValue;
						if (!fieldValue) {
							newFields.defectParams = {};
						}
					} else {
						newFields.defectParams = {
							...newFields.defectParams,
							[fieldName]: fieldValue === 'true' ? true : fieldValue,
						};
					}
					break;

				default:
					break;
			}
			return {
				...prevState,
				fields: newFields,
			};
		});
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Product' : 'Create Product'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Product Details'
				>
					<ProductDetails
						mandatoryFields={productConfig.mandatoryFields}
						fields={state.fields}
						disableMsg={isEdit ? false : state.fields?.variants?.length > 1
							? 'Please Remove Variants to edit fields again'
							: false}
						disablebox={isEdit ? variant?.fields?.variants?.length > 1 ? disable : false : false}
						handleFileInput={handleFileInput}
						handleImageInput={handleImageInput}
						generateDefaultOption={generateDefaultOption}
						handleInput={handleInput}
						exclude={excludedFields}
						disableUnit={disableUnit}
						subCategoriesList={subCategoriesList}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Varients Details'
				>
					<div className='w-full'>
						<div className='float-right m-3'>
							<Button
								variant='outlined'
								color='primary'
								onClick={addNewVariant}
							>
								Add New Variant
							</Button>
						</div>
						<ProductVariantsTable
							handleDelete={handleDelete}
							// unitprop={variant?.fields}
							variants={isEdit ? variant?.fields?.variants : state?.fields?.variants}
							handleVariantInput={handleVariantInput}
							state={state}
							setState={setState}
							handleSaveUnits={handleSaveUnits}
							handleToggle={handleToggle}
							measurement={measurement}
							// minUnit={minUnit}
							// setMinUnit={setMinUnit}
							// maxUnit={maxUnit}
							// setMaxUnit={setMaxUnit}
							// costPriceUnit={costPriceUnit}
							// setCostPriceUnit={setCostPriceUnit}
							// transferPriceUnit={transferPriceUnit}
							// setTransferPriceUnit={setTransferPriceUnit}
							// sellingPriceUnit={sellingPriceUnit}
							// setSellingPriceUnit={setSellingPriceUnit}
							// containerUnit={containerUnit}
							// setContainerUnit={setContainerUnit}
						/>
					</div>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Quality Config'
				>
					<ProductQualityConfig
						fields={qualityState?.fields}
						handleInput={handleQualityInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};
ProductCreatePage.defaultProps = {
	limit: 10,
};
const mapStateToProps = (state) => {
	const data = state.form.ALL_PRODUCTS.data.VIEW;
	const variantData = state.form.VARIANTS.data.VIEW;
	const { brands } = state.brand;
	const qualityData = state.form.PRODUCT_QUALITY_CONFIG.data.LIST;
	return {
		brands,
		data,
		variantData,
		qualityData,
	};
};
const mapDispatchToProps = {
	saveProduct: formActions.gateway,
	saveVariant: formActions.gateway,
	fetchProduct: formActions.gateway,
	fetchVariant: formActions.gateway,
};
ProductCreatePage.defaultProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProductCreatePage);
