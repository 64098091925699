/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable object-curly-newline */
import { Home } from '@mui/icons-material';
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import { QualityAnalysis, QCHistoryListData } from '../../../Pages';
import PastAnalysisHistoryPage from '../../../Pages/Analytics/Quality Analysis/PastAnalysisHistoryPage';

const getSubURL = (entity) => {
	const baseEntity = urls.QUALITY_ANALYSIS_CONTAINER;
	return entity.slice(baseEntity.length);
};
const QualityAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route
					path={`${path}${getSubURL(urls.QUALITY_ANALYSIS)}`}
					exact
				>
					<QualityAnalysis />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.QUALITY_ANALYSIS_LIST)}`}
					exact
				>
					<PastAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.STORE_ANALYSIS,
								name: <Home />,
							},
							{
								link: `${urls.EXTENSION}${urls.QUALITY_ANALYSIS}`,
								name: 'Quality Analysis',
							},
							{
								link: '',
								name: 'Kitchen Quality Report',
							},
						]}
					/>
				</Route>
				<Route
					path={`${path}${getSubURL(urls.NUTRITION_ANALYSIS)}`}
					exact
				>
					<QualityAnalysis />
				</Route>
				<Route path={`${path}${getSubURL(urls.QC_HISTORY_LIST)}`}>
					<QCHistoryListData />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.NUTRITION_ANALYSIS_LIST)}`}
					exact
				>
					<PastAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.STORE_ANALYSIS,
								name: <Home />,
							},
							{
								link: `${urls.EXTENSION}${urls.NUTRITION_ANALYSIS}`,
								name: 'Nutrition Analysis',
							},
							{
								link: '',
								name: 'Kitchen Nutrition Report',
							},
						]}
					/>
				</Route>
			</Switch>
		</div>
	);
};

export default QualityAnalyticsRoutes;
