/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Switch } from '@mui/material';
import { formActions } from '../../../Actions';
import {
	handleResponseStreamDownload,
	inputHelper,
	isEmpty,
} from '../../../Helpers';
import { CustomizationListTable } from '../../../Components/Forms/Customizations/CustomizationListTable';
import cookingRecipesServices from '@/Services/Form Services/cookingRecipes.services';
import { notificationServices } from '@/Services';
import drawerActions from '@/Actions/drawer.actions';
import ExportFiltersDrawer from '@/Components/Drawers/ExportFiltersDrawer/ExportFiltersDrawer';
import { BackdropLoader } from '@/Components/Loaders';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Veg',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [
	{
		title: 'Current State',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Customization Enabled',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Recipe Management',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	Brand: 'brandName',
	Veg: 'isVeg',
	'Customization Enabled': 'isCustomizationEnabled',
	'Recipe Management': 'isCMSEnabled',
};
const initialState = [];
const CookingRecipeListPage = (props) => {
	const {
		fetchProducts,
		toggleFlag,
		data,
		userPermissions,
		query,
		toggleAlert,
		toggleExportFiltersDrawer,
		stores,
		fetchStoresList,
		navState,
		userList,
		fetchUserList,
	} = props;

	const [state, setState] = useState(initialState);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	// const { userConfig } = useAuth();
	// // userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/kitchen/cooking']?.submenu['/kitchen/cooking/recipe']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item?.name === 'Internal' || item?.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user Acces

	useEffect(() => {
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
					type: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleEnable = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);

		const clonedState = cloneDeep(state);

		const foundIndex = data.findIndex((obj) => {
			return obj.id === parseInt(dataset.id, 10);
		});
		clonedState[foundIndex][fieldName] = fieldValue;

		let entity = '';
		let req = {};
		let method = '';

		switch (fieldName) {
			case 'isCustomizationEnabled':
				entity = 'CUSTOMIZATIONS';
				req = {
					params: {
						productId: dataset.id,
					},
					data: {
						isCustomizationEnabled: fieldValue,
					},
				};
				method = 'PATCH';
				break;
			case 'isCMSEnabled':
				entity = 'RECIPES';
				req = {
					params: {
						productId: dataset.id,
					},
					data: {
						isCMSEnabled: fieldValue,
					},
				};
				method = 'PATCH';
				break;
			default:
				break;
		}
		toggleFlag({
			entity,
			req,
			method,
			promisify: true,
		})
			.then((res) => {
				if (res.status === 'Success') {
					setNumberOfSubmissions(numberOfSubmissions + 1);
					setState(clonedState);
				}
			})
			.catch(() => {
				// eslint-disable-next-line no-console
			});
	};
	const handleDelete = (productId) => {
		toggleAlert(true, {
			message: 'Click Proceed to delete product recipe',
			heading: 'Delete Product',
			productId,
		});
	};
	const SPECIAL_ITEMS = {
		Veg: (dataObj) => {
			const { isVeg } = dataObj;
			return (
				<div
					className={`dot-container small ${
						isVeg ? 'veg' : 'non-veg'
					}`}
				>
					<div className='dot' />
				</div>
			);
		},
		'Customization Enabled': (dataObj, alt) => {
			const { id, isCustomizationEnabled } = dataObj;
			const { isDisabled } = alt;
			return (
				<Switch
					name='isCustomizationEnabled'
					value='isEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isCustomizationEnabled}
					disabled={isDisabled}
					disableRipple={isDisabled}
					inputProps={{
						'data-id': id,
						'data-type': 'CUSTOMIZATIONS',
					}}
				/>
			);
		},
		'Recipe Management': (dataObj, alt) => {
			const { id, isCMSEnabled } = dataObj;
			const { isDisabled } = alt;
			return (
				<Switch
					name='isCMSEnabled'
					value='isEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isCMSEnabled}
					disabled={isDisabled}
					disableRipple={isDisabled}
					inputProps={{
						'data-id': id,
						'data-type': 'RECIPE',
					}}
				/>
			);
		},
		'Current State': (dataObj, alt) => {
			const { text } = alt;
			return <div>{text}</div>;
		},
	};

	useEffect(() => {
		fetchProducts({
			entity: 'RECIPES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(data);
		}
	}, [data]);

	const handleExport = async (filters) => {
		setIsLoading(true);
		try {
			const response = await cookingRecipesServices.exportCookingRecipes({
				req: {
					data: {
						filters,
					},
				},
			});
			if (response.status === 200) {
				const selectedStore = stores?.find((ele) => {
					return ele?.id === filters?.storeId;
				});
				handleResponseStreamDownload(
					response,
					`COOKING_RECIPES_${selectedStore?.name?.toUpperCase()}.csv`
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		} finally {
			setIsLoading(false);
		}
	};
	const openExportDrawer = () => {
		toggleExportFiltersDrawer(true, {
			heading: 'Export Cooking Recipes',
			// note: 'Filters are disabled for now, they will be LIVE Soon!',
			onConfirm: (args) => {
				const { filtersState } = args ?? {};
				handleExport(filtersState);
			},
			filterData: [
				{
					type: 'singleSelect',
					dependentOn: -1,
					compData: stores
						?.filter((ele) => {
							return ele?.type === 'BASE';
						})
						?.map((ele) => {
							return {
								id: ele?.id,
								label: ele?.name,
								title: ele?.name,
								value: ele?.id,
							};
						}),
					selectBy: 'id',
					label: 'Base Store',
					selectedValueKey: 'storeId',
					dateFormat: null,
					disabled: false,
					defaultState: null,
					mandatory: true,
				},
			],
		});
	};

	return (
		<div className='list-page-root'>
			<BackdropLoader isLoading={isLoading} showProgress />
			<ExportFiltersDrawer />
			<CustomizationListTable
				entity='RECIPE'
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				hideRecipeCreation={!userPermissions?.CONFIG_EDIT}
				data={state.filter((product) => {
					return (
						product.productName
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1 &&
						product?.skuType !== 'INGREDIENT'
					);
				})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
				onExportClick={openExportDrawer}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.RECIPES.data.LIST;
	const { userPermissions, navState } = state.navigation;
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;
	const { userList } = state?.auth;

	return {
		userPermissions,
		data,
		stores,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleFlag: formActions.gateway,
	deleteProduct: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleExportFiltersDrawer: drawerActions.toggleExportFiltersDrawer,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingRecipeListPage);
