import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../Components/Auth/Login/auth';
import { urls } from '../../Constants';
import { notificationServices } from '../../Services';

// A - Auth

const ProtectedRoute = ({
	component: Component,
	type,
	loggedIn,
	selectedStore,
	generateNotification,
	...rest
}) => {
	const { isLoggedIn } = useAuth();
	return (
		<Route
			{...rest}
			render={(props) => {
				let isAllowed = true;
				let message = '';
				switch (type) {
					case 'A':
					case 'B':
						isAllowed = isLoggedIn;
						message = 'You must login first';
						break;
					default:
						break;
				}
				if (isAllowed) {
					return <Component {...props} />;
				}
				notificationServices.generateNotification({
					type: 'warning',
					message,
				});
				return (
					<Redirect
						to={{
							pathname: `${urls.EXTENSION}/login`,
							state: {
								from: props.location,
							},
						}}
					/>
				);
			}}
		/>
	);
};

export default ProtectedRoute;
