// eslint-disable-next-line object-curly-newline
import { Switch } from '@mui/material';
import { connect } from 'react-redux';
import { generateCheckedProps, generateRandomKey } from '../../../../Helpers';
import styles from './SliderDiscount.module.scss';

const SliderDiscount = (props) => {
	const { idx, handleInput, MakeUnAvailable } = props;
	const handleChange = () => {
		handleInput('products', idx);
	};
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles.isAddOn}`}>
				<label htmlFor='AvailableProduct'>Available</label>
				<Switch
					name='AvailableProduct'
					value='AvailableProduct'
					size='small'
					color='primary'
					checked={MakeUnAvailable.products.includes()}
					onChange={handleChange}
					inputProps={{
						'data-id': idx,
						'data-type': 'subCategory',
					}}
					{...generateCheckedProps(
						!MakeUnAvailable.products.includes(idx)
					)}
					{...generateRandomKey()}
				/>
			</div>
		</div>
	);
};
SliderDiscount.defaultProps = {
	discount: 0,
	isDisabled: false,
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderDiscount);
