/* eslint-disable */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import {
	GeneralDetails,
	ManagerDetails,
	Payment,
	StoreDesign,
	StoreTiming,
} from '../../../Components/Forms/Stores';
import ServiceModes from '../../../Components/Forms/Stores/Mode of Services/ServiceModes';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTab,
	StyledTabs,
	TabPanel,
} from '../../../Components/StyledTabs';
// eslint-disable-next-line object-curly-newline
import { generateDisabledProps, history, isEmpty } from '../../../Helpers';
import StoresConfig from './Stores.config';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABS = [
	'GENERAL DETAILS',
	'MANAGER DETAILS',
	'STORE TIMINGS',
	'MODES OF SERVICES',
	'PAYMENTS',
	'STORE DESIGN',
];

const StoreViewPage = (props) => {
	const {
		fetchStore, data, deleteStore, isDisabled, breadcrumbs, navState,
		userPermissions, fetchUserList, userList,
	} = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(StoresConfig.initialState.fields),
	});
	const StoreId = useRouteMatch().params.id;
	useEffect(() => {
		fetchStore({
			entity: 'STORES',
			req: {
				params: {
					id: StoreId,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			StoresConfig.serializer(state, data).then((newState) => {
				setState(newState);
			});
		}
	}, [data]);

	const { activeTab, fields, errors } = state;
	const { userConfig } = useAuth();

	// userAcess start
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/outlets']?.submenu['/outlets/stores']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// userAcess end

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return fields.enableSection.generalDetails ? (
					<GeneralDetails
						isDisabled={isDisabled}
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						coords={{
							lat: fields.latitude,
							lng: fields.longitude,
						}}
						errors={errors}
					/>
				) : (
					'No data to Display'
				);
			case 1:
				return fields.enableSection.managerDetails ? (
					<ManagerDetails
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						isDisabled
					/>
				) : (
					'No data to Display'
				);
			case 2:
				return fields.enableSection.storeTiming ? (
					<StoreTiming
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						isDisabled
					/>
				) : (
					'No data to Display'
				);
			case 3:
				return fields.enableSection.serviceModes ? (
					<ServiceModes
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						isDisabled
					/>
				) : (
					'No data to Display'
				);
			case 4:
				return fields.enableSection.payment ? (
					<Payment
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						isDisabled
					/>
				) : (
					'No data to Display'
				);
			case 5:
				return fields.enableSection.storeDesign ? (
					<StoreDesign
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						isDisabled={isDisabled}
					/>
				) : (
					'No data to Display'
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteStore({
			entity: 'STORES',
			req: {
				params: {
					id: StoreId,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};
	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Stores'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.storeName}`,
					},
				]}
				userPermissions={userPermissions}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.STORES.data.VIEW;
	const { userPermissions, navState } = state.navigation;
	const { userList } = state?.auth;
	return {
		data,
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchStore: formActions.gateway,
	deleteStore: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};
StoreViewPage.defaultProps = {
	isDisabled: true,
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreViewPage);
