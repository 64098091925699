import {
	useEffect, useMemo, useRef, useState
} from 'react';
import { classes } from '../../../../../Helpers';
import styles from './DragDropUploader.module.scss';

function DragDropUploader(props) {
	const {
		fileAcceptable,
		uploadType,
		uploadedFile,
		setUploadedFile,
		className,
		isLoading = false,
	} = props;
	const fileDropRef = useRef(null);
	const [dragging, setDragging] = useState(false);
	const [message, setMessage] = useState({
		show: false,
		text: '',
		type: null,
	});

	const showMessage = (text, type, timeout) => {
		setMessage({
			show: true,
			text,
			type,
		});

		setTimeout(() => {
			return setMessage({
				show: false,
				text: null,
				type: null,
			});
		}, timeout);
	};

	const handleBase64UploadFile = (file) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			setUploadedFile({
				fileName: file?.name,
				file: reader.result,
			});
			// showMessage('File uploaded successfully!', 'success', 2000);
		};
		reader.readAsDataURL(file);
	};

	const handleFileUpload = (e) => {
		const file = e.target.files[0];

		if (uploadType === 'csvFile') {
			if (file) {
				if (file?.name?.toLowerCase()?.includes('csv')) {
					handleBase64UploadFile(file);
				} else {
					showMessage('Please upload csv file only', 'error', 2000);
				}
			}
		} else if (uploadType === 'pdfFile') {
			if (file?.name?.toLowerCase()?.includes('pdf')) {
				handleBase64UploadFile(file);
			} else {
				showMessage('Please upload PDF file type only', 'error', 2000);
			}
		} else if (file) {
			if (file?.name?.toLowerCase()?.includes('zip')) {
				setUploadedFile({
					fileName: file?.name,
					file,
				});
			} else {
				showMessage('Please upload zip file only', 'error', 2000);
			}
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const file = e.dataTransfer.files[0];

		if (uploadType === 'csvFile') {
			if (file) {
				if (file?.name?.toLowerCase()?.includes('csv')) {
					const reader = new FileReader();
					reader.onloadend = () => {
						setUploadedFile({
							fileName: file?.name,
							file: reader.result,
						});
						// showMessage('File uploaded successfully!', 'success', 2000);
					};
					reader.readAsDataURL(file);
				} else {
					showMessage('Please upload csv file only', 'error', 2000);
				}
			}
		} else if (file) {
			if (file?.name?.toLowerCase()?.includes('zip')) {
				setUploadedFile({
					fileName: file?.name,
					file,
				});
			} else {
				showMessage('Please upload zip file only', 'error', 2000);
			}
		}
		setDragging(false);
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// if (e.target !== fileDropRef.current) {
		setDragging(true);
		// }
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// if (e.target === fileDropRef.current) {
		setDragging(false);
		// }
	};

	useEffect(() => {
		fileDropRef?.current?.addEventListener('dragover', handleDragOver);
		fileDropRef?.current?.addEventListener('drop', handleDrop);
		fileDropRef?.current?.addEventListener('dragenter', handleDragEnter);
		fileDropRef?.current?.addEventListener('dragleave', handleDragLeave);

		return () => {
			fileDropRef?.current?.removeEventListener(
				'dragover',
				handleDragOver
			);
			fileDropRef?.current?.removeEventListener('drop', handleDrop);
			fileDropRef?.current?.removeEventListener(
				'dragenter',
				handleDragEnter
			);
			fileDropRef?.current?.removeEventListener(
				'dragleave',
				handleDragLeave
			);
		};
	}, [fileDropRef?.current]);

	const removeSelectedFile = () => {
		setUploadedFile({
			file: '',
			fileName: '',
		});
	};

	const uploadedFileName = useMemo(() => {
		if (uploadedFile?.fileName?.length > 80) {
			return `${uploadedFile?.fileName?.substring(
				0,
				uploadedFile?.fileName?.length / 10
			)}...${uploadedFile?.fileName?.substring(
				uploadedFile?.fileName?.length / 2
			)}`;
		}
		return uploadedFile.fileName;
	}, [uploadedFile.fileName]);

	return (
		<div className={classes(styles['file-upload-container'], className)}>
			{isLoading && <p>Loading, please wait...</p>}
			{!isLoading && (
				<div ref={fileDropRef} className={styles['file-uploader']}>
					{!message.show ? (
						<>
							<div
								className={
									uploadedFile?.fileName
										? styles['file-name']
										: ''
								}
								onClick={removeSelectedFile}
							>
								<span>{uploadedFileName}</span>
								{!uploadedFile?.fileName &&
									(dragging
										? 'Drop the File here'
										: 'Drag & Drop file here to Upload')}
							</div>
							{!uploadedFile?.fileName && (
								<>
									<p>OR</p>
									<div className={styles['file-input']}>
										<button type='button'>
											Browse File
										</button>
										<input
											onChange={handleFileUpload}
											type='file'
											name='po-file'
											accept={fileAcceptable}
										/>
									</div>
								</>
							)}
						</>
					) : (
						<div
							ref={fileDropRef}
							className={classes(
								styles['message-container'],
								styles[message.type]
							)}
						>
							<p>{message?.text}</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default DragDropUploader;
