import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const riderServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CREATE':
			return createRider({
				req,
			});
		case 'VIEW':
			return viewRider({
				req,
			});
		case 'LIST':
			return listRider({
				req,
			});
		case 'UPDATE':
			return updateRider({
				req,
			});
		case 'DELETE':
			return deleteRider({
				req,
			});
		case 'ENABLE':
			return toggleAvailability({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function deleteRider({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.RIDERS, params);

	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function createRider({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.RIDERS, requestOptions).then(handleResponse);
}

function updateRider({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.RIDERS, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewRider({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.RIDERS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listRider({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.RIDERS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function toggleAvailability({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.RIDERS, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default riderServices;
