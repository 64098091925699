import { useState } from 'react';
import {
	Step, StepLabel, Stepper, Tooltip
} from '@mui/material';
import styles from './StepWiseActionComponent.module.scss';
import { Button } from '../ui/button';

const StepWiseActionComponent = (props) => {
	const { steps = [] } = props;
	const [currentStep, setCurrentStep] = useState(0);
	return (
		<div className={styles.root}>
			<div className={styles.body_container}>
				<Stepper activeStep={currentStep}>
					{steps.map((ele, index) => {
						if (ele?.showTooltip && currentStep > index) {
							return (
								<Tooltip
									title={ele?.tooltipContent}
									followCursor
								>
									<Step
										key={ele?.stepHeader}
										completed={currentStep > index}
									>
										<StepLabel>{ele?.stepHeader}</StepLabel>
									</Step>
								</Tooltip>
							);
						}
						return (
							<Step
								key={ele?.stepHeader}
								completed={currentStep > index}
							>
								<StepLabel>{ele?.stepHeader}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				<div className={styles.component_container}>
					{steps?.[currentStep]?.renderComponent(currentStep)}
				</div>
			</div>
			<div className={styles.btn_container}>
				{steps?.[currentStep]?.showBack && (
					<Button
						className='flex-1'
						variant='secondary'
						onClick={() => {
							steps?.[currentStep]?.onBack(currentStep, () => {
								if (currentStep > 0) {
									setCurrentStep((prev) => {
										return prev - 1;
									});
								}
							});
						}}
						type='button'
					>
						{steps?.[currentStep]?.backBtnTxt ?? 'Back'}
					</Button>
				)}
				{steps?.[currentStep]?.showNext && (
					<Button
						type='button'
						className='flex-1 primary-btn'
						onClick={() => {
							steps?.[currentStep]?.onNext(currentStep, () => {
								if (currentStep < steps?.length) {
									setCurrentStep((prev) => {
										return prev + 1;
									});
								}
							});
						}}
					>
						{steps?.[currentStep]?.nextBtnTxt ?? 'Next'}
					</Button>
				)}
			</div>
		</div>
	);
};

export default StepWiseActionComponent;
