function Unit(props) {
	return (
		<div
			id='unit'
			className={props?.className}
			style={{
				marginTop: '6px',
				marginLeft: '6px',
				marginRight: '1rem',
			}}
		>
			mm
		</div>
	);
}
export default Unit;
