import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { modalActions } from '../../Actions';
import { modalTypes } from '../../Constants';
import { BaseModal } from '../Modals/BaseModal';
import styles from './AddWithdrawModal.module.scss';

function AddWithdrawModal(props) {
	const {
		show,
		toggleModal,
		type,
		number,
		amount,
		handleInput,
		handleAddCredits,
		handleWithdrawCredits,
	} = props;

	const handleClose = () => {
		toggleModal();
	};

	const handleAddConfirm = () => {
		handleAddCredits(amount);
	};

	const handleWithdrawConfirm = () => {
		handleWithdrawCredits(amount);
	};

	const renderBtns = () => {
		return type === 'add' ? (
			<Button
				variant='contained'
				color='primary'
				onClick={handleAddConfirm}
			>
				Add Credits
			</Button>
		) : (
			<Button
				variant='contained'
				color='primary'
				onClick={handleWithdrawConfirm}
			>
				Withdraw Credits
			</Button>
		);
	};

	return (
		<div>
			<BaseModal
				heading={type === 'add' ? 'Add Credits' : 'Withdraw Credits'}
				height='20rem'
				handleClose={handleClose}
				open={show}
				width='34rem'
				renderBtns={renderBtns}
			>
				<div className={styles.root}>
					<p>
						Number:
						{' '}
						{number}
					</p>
					<div className={styles['input-container']}>
						<input
							onInput={handleInput}
							name='amount'
							type='number'
							placeholder='Enter amount'
						/>
						<input
							onInput={handleInput}
							name='comment'
							placeholder='Comment'
						/>
					</div>
				</div>
			</BaseModal>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { show, data } = state.modal.ADD_WITHDRAW;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.ADD_WITHDRAW, show, data)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWithdrawModal);
