import DatePicker from 'rsuite/DatePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BaseModal } from '@/Components/Modals/BaseModal';

const DatePickerModal = (props) => {
	const { show, onClose, onSubmit, heading, currentPo } = props;
	const [date, setDate] = useState(currentPo?.scheduledAt);
	useEffect(() => {
		if (currentPo?.scheduledAt) {
			setDate(
				moment(currentPo?.scheduledAt, 'YYYY-MM-DD HH:mm:ss').toDate()
			);
		} else {
			setDate(new Date());
		}
	}, [currentPo?.scheduledAt]);

	return (
		<BaseModal
			heading={heading}
			width='24rem'
			height='16rem'
			open={show}
			handleClose={onClose}
			handleConfirm={() => onSubmit(date, currentPo)}
		>
			<div
				style={{
					padding: '1rem',
				}}
			>
				<DatePicker
					block
					shouldDisableDate={(tDate) => moment(tDate).isBefore(moment(), 'day')}
					placeholder='Select Date'
					value={date}
					onChange={setDate}
				/>
			</div>
		</BaseModal>
	);
};

export default DatePickerModal;
