import moment from 'moment';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { analyticsAction } from '../../../Actions';
import { UYDateRange } from '../../../Components/Forms/Payment Settlements/Calender/UYDateRange';
import styles from './ProductPlanHeader.module.scss';

function ProductPlanHeader(props) {
	const { fetchProductionPlans } = props;
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
	});

	useEffect(() => {
		fetchProductionPlans({
			entity: 'PRODUCTION_PLANS',
			req: {
				data: {
					filters: {
						dateRangeFilter: {
							from: moment(dateRange?.from)?.format('YYYY-MM-DD'),
							to: moment(dateRange?.to)?.format('YYYY-MM-DD'),
						},
					},
				},
			},
			method: 'LIST',
		});
	}, [dateRange?.from, dateRange?.to]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
	};
	return (
		<div className={styles.root}>
			<p className={styles.header}>Production Plans</p>
			<div className={styles.filters}>
				<div className={styles['date-filter']}>
					<p>Scheduled For: </p>
					<UYDateRange
						// disabled={!dateRange.active}
						dateRanges={dateRangeFilterArray}
						onDateRangeChange={setDate}
						enableOnlyBackwardSelection={false}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = () => {};

const mapDispatchToProps = {
	fetchProductionPlans: analyticsAction.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPlanHeader);
