/* eslint-disable object-curly-newline */
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@mui/material';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import styles from './PettyCashCreatePage.module.scss';
import CreateFormpage from '../../../Components/CreateFormpage/CreateFormpage';
import { CreatePageHeader } from '../../../Components/Headers';
import { formActions, modalActions } from '../../../Actions';
import pettyCashCreateConfig from './pettyCashCreate.config';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import {
	getStateCodeFromGSTIN,
	getTaxFromStr,
	paymentModes,
	useQuery,
} from '../../../Helpers';
import { BackdropLoader } from '../../../Components/Loaders';
import BillAlreadyPaidInputs from './BillAlreadyPaidInputs/BillAlreadyPaidInputs';
import { urls } from '../../../Constants';
// import POTotalContainer from '../../../Components/POTotalContainer/POTotalContainer';

const PettyCashCreatePage = (props) => {
	const query = useQuery();
	const history = useHistory();
	const queryToStore = query.get('toStore');

	const {
		fetchVendors,
		vendorsList,
		storesList,
		toggleFileUploaderModal,
		permittedStores,
	} = props;
	const [state, dispatch] = useReducer(
		pettyCashCreateConfig?.reducer,
		pettyCashCreateConfig?.initialState
	);
	const [categoryList, setCategoryList] = useState([]);
	const [taxSlabsList, setTaxSlabsList] = useState([]);
	const [isLoading, setIsloading] = useState(false);

	const fetchCategoryList = async (catType = 'PETTY_CASH') => {
		try {
			const response = await inventoryAnalyticsServices.fetchCategoryList(
				{
					req: {
						data: {
							type: catType,
						},
					},
				}
			);
			if (response?.statusCode === 200) {
				if (catType === 'PETTY_CASH') {
					setCategoryList(response?.data?.categories);
					dispatch({
						type: 'SET_EXPENSE_CATEGORY',
						payload: {
							...response?.data?.categories?.[0],
							title: response?.data?.categories?.[0]?.value,
						},
					});
				} else if (catType === 'TAX_SLAB') {
					setTaxSlabsList(response?.data?.categories);
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};

	useEffect(() => {
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchVendors({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchCategoryList();
		fetchCategoryList('TAX_SLAB');
	}, []);

	useEffect(() => {
		if (queryToStore && queryToStore !== 'undefined') {
			const newStore = storesList?.find((ele) => {
				return ele?.id === Number(queryToStore);
			});
			dispatch({
				type: 'SET_STORE',
				payload: newStore,
			});
		}
	}, [queryToStore, storesList]);

	async function fetchTempId() {
		if (state?.clientTransactionId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_PETTY_CASH_ID',
					payload: response?.data,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	useEffect(() => {
		fetchTempId();
	}, []);

	const handleSubmit = async () => {
		if (state?.amount?.includes('-') || state?.amount <= 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter valid Amount',
			});
			return;
		}
		const isInvalid = pettyCashCreateConfig?.validator(state);
		if (isInvalid) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please enter Mandatory Fields',
			});
			return;
		}
		const deserializedData = pettyCashCreateConfig?.deserializer(state);
		const req = {
			data: {
				...deserializedData,
				nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.PETTY_CASH}?clientPettyCashId=${deserializedData?.clientOrderId}`,
			},
		};
		setIsloading(true);
		try {
			const response =
				await inventoryAnalyticsServices.createTransactionRecord(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch ({ name, message }) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message,
			});
		} finally {
			setIsloading(false);
		}
	};

	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				<Button
					onClick={() => {
						handleSubmit();
					}}
					variant='contained'
					color='primary'
					type='button'
				>
					Save
				</Button>
			</div>
		);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const handleVendorSelection = (newVendor) => {
		dispatch({
			type: 'SET_VENDOR',
			payload: newVendor,
		});
	};

	const handleStoreSelection = (newStore) => {
		dispatch({
			type: 'SET_STORE',
			payload: newStore,
		});
	};

	const handleRemoveVendor = (e) => {
		if (e) {
			if (e === 'vendor' || e.keyCode === 8) {
				dispatch({
					type: 'SET_VENDOR',
					payload: {},
				});
			}
		}
	};
	const handleRemoveStore = (e) => {
		if (e) {
			if (e === 'store' || e.keyCode === 8) {
				dispatch({
					type: 'SET_STORE',
					payload: {},
				});
			}
		}
	};

	const handleDateSelection = (newDate) => {
		dispatch({
			type: 'SET_ORDER_DATE',
			payload: newDate,
		});
	};

	const STORES = useMemo(() => {
		const validStores = {};
		permittedStores.forEach((validStoreLoop) => {
			validStores[validStoreLoop.id] = true;
		});
		const tempStore = storesList?.filter((storeFilter) => {
			return permittedStores.length === 0 || validStores[storeFilter.id];
		});
		return tempStore;
	}, [storesList, permittedStores]);

	const isStateTax = (vendorGst, storeGst) => {
		if (
			getStateCodeFromGSTIN(vendorGst) === getStateCodeFromGSTIN(storeGst)
		) {
			return true;
		}
		return false;
	};

	const formattedInputs = (mainList = []) => {
		const vendorGst = state?.vendor?.gstin;
		const storeGst = state?.store?.gstNumber;
		let vendorHaveGst = false;
		let isSameState = false;
		// eslint-disable-next-line no-unused-vars, prefer-const
		let extraLabelData = { color: 'green' };
		let tax = 0;
		if (
			vendorGst &&
			vendorGst?.length > 0 &&
			storeGst &&
			state?.paymentType !== 'Income'
		) {
			vendorHaveGst = true;
			isSameState = isStateTax(vendorGst, storeGst);
			if (state?.taxSlab) {
				tax = getTaxFromStr(state.taxSlab);
				if (!isSameState) {
					extraLabelData.text = `IGST ${tax.toFixed(1)}% `;
				} else {
					extraLabelData.text = `SGST ${(tax / 2).toFixed(
						1
					)}%  |  CGST ${(tax / 2).toFixed(1)}%`;
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (!isSameState) {
					extraLabelData.text = 'IGST';
				} else {
					extraLabelData.text = 'SGST | CGST';
				}
			}
			// eslint-disable-next-line no-unused-vars
		}
		let list = mainList;
		if (!vendorHaveGst) {
			list = list?.filter((ele) => {
				return ele?.id !== 'taxSlab';
			});
		}

		return list
			?.filter((ele) => {
				if (state?.paymentType === 'Income') {
					return (
						ele?.id !== 'category' &&
						ele?.inputType !== 'customComponent' &&
						ele?.id !== 'dueDate' &&
						ele?.id !== 'taxSlab' &&
						ele?.id !== 'orderNumber' &&
						ele?.id !== 'uploadedDoc'
					);
				}
				return ele?.id !== 'paymentMode';
			})
			?.map((ele) => {
				if (Array.isArray(ele)) {
					return formattedInputs(ele);
				}
				if (ele?.inputType === 'customComponent') {
					return {
						...ele,
						component: (
							<BillAlreadyPaidInputs
								state={{
									checkValue: state?.alreadyPaid?.isPaid,
									uploadedDoc: {
										file: state?.alreadyPaid
											?.uploadedDocFile,
										fileName:
											state?.alreadyPaid
												?.uploadedDocFileName,
									},
									paymentMode:
										state?.alreadyPaid?.paymentMode,
								}}
								setState={(fields) => {
									if (fields?.uploadedDoc) {
										dispatch({
											type: 'SET_ALREADY_PAID',
											payload: {
												...state?.alreadyPaid,
												uploadedDocFile:
													fields?.uploadedDoc?.file,
												uploadedDocFileName:
													fields?.uploadedDoc
														?.fileName,
											},
										});
										return;
									}
									if (fields?.paymentMode) {
										dispatch({
											type: 'SET_ALREADY_PAID',
											payload: {
												...state?.alreadyPaid,
												paymentMode:
													fields?.paymentMode,
											},
										});
										return;
									}
									if (fields?.transactionRefNumber) {
										dispatch({
											type: 'SET_ALREADY_PAID',
											payload: {
												...state?.alreadyPaid,
												transactionRefNumber:
													fields?.transactionRefNumber,
											},
										});
										return;
									}
									dispatch({
										type: 'SET_ALREADY_PAID',
										payload: {
											...state?.alreadyPaid,
											isPaid: fields?.checkValue,
										},
									});
								}}
							/>
						),
					};
				}
				if (
					ele?.inputType === 'options_search' &&
					ele?.id === 'vendor'
				) {
					const vendors = vendorsList.filter((vendor) => {
						if (state?.paymentType === 'Income') {
							return vendor?.vendorType === 'INCOME';
						}
						return (
							(vendor?.vendorType === 'INDENT' &&
								vendor?.isExpenseAllowed) ||
							vendor?.vendorType === 'EXPENSE'
						);
					});
					return {
						...ele,
						value: state?.vendor?.name,
						handleSelection: handleVendorSelection,
						optionsList: vendors,
						handleKeyPress: handleRemoveVendor,
						handleRemove: handleRemoveVendor,
						disabled: !state?.paymentType,
					};
				}
				if (ele?.inputType === 'options' && ele?.id === 'category') {
					return {
						...ele,
						optionsList: categoryList?.map((catItem) => {
							return {
								...catItem,
								title: catItem?.value,
							};
						}),
						value: state?.category?.id,
						onChange: (value) => {
							const categoryItem = categoryList?.find(
								(currItem) => {
									return (
										currItem?.id === Number(value)
									);
								}
							);
							dispatch({
								type: 'SET_CATEGORY',
								payload: categoryItem,
							});
						},
					};
				}
				if (
					ele?.inputType === 'options_search' &&
					ele?.id === 'store'
				) {
					return {
						...ele,
						value: state?.store?.name,
						handleSelection: handleStoreSelection,
						optionsList: STORES,
						handleKeyPress: handleRemoveStore,
						handleRemove: handleRemoveStore,
					};
				}
				if (
					ele?.inputType === 'dateSelector' &&
					ele?.id === 'orderDate'
				) {
					return {
						...ele,
						value: state?.orderDate,
						optionsList: vendorsList,
						oneTap: true,
						onChange: handleDateSelection,
						cleanable: false,
					};
				}
				if (
					ele?.inputType === 'dateSelector' &&
					ele?.id === 'dueDate'
				) {
					return {
						...ele,
						value: state?.dueDate,
						oneTap: true,
						cleanable: false,
						disabledDate: (rsuiteDates) => {
							return rsuiteDates < state?.orderDate;
						},
						onChange: (rSuiteDate) => {
							dispatch({
								type: 'SET_DUE_DATE',
								payload: rSuiteDate,
							});
						},
					};
				}
				if (
					ele?.inputType === 'toggleSelector' &&
					ele?.id === 'paymentType'
				) {
					return {
						...ele,
						value: state?.paymentType,
						onChange: (value) => {
							dispatch({
								type: 'SET_PAYMENT_TYPE',
								payload: value,
							});
							dispatch({
								type: 'SET_VENDOR',
								payload: {},
							});
							dispatch({
								type: 'SET_ALREADY_PAID',
								payload: {
									isPaid: null,
									paymentMode: paymentModes[0],
									uploadedDocFileName: '',
									uploadedDocFile: '',
									transactionRefNumber: '',
								},
							});
						},
					};
				}
				if (
					ele?.inputType === 'textArea' &&
					ele?.id === 'description'
				) {
					return {
						...ele,
						value: state?.description,
						onChange: (value) => {
							dispatch({
								type: 'SET_DESCRIPTION',
								payload: value,
							});
						},
					};
				}
				if (ele?.inputType === 'options' && ele?.id === 'paymentMode') {
					return {
						...ele,
						value: state?.paymentMode || ele?.value,
						onChange: (value) => {
							dispatch({
								type: 'SET_PAYMENT_MODE',
								payload: value,
							});
						},
					};
				}
				if (ele?.inputType === 'options' && ele?.id === 'taxSlab') {
					return {
						...ele,
						optionsList: taxSlabsList?.map((cat) => {
							return cat?.value;
						}),
						extraInfo: extraLabelData,
						value: state?.taxSlab || ele?.value,
						onChange: (value) => {
							dispatch({
								type: 'SET_TAX_SLAB',
								payload: value,
							});
						},
					};
				}
				if (ele?.id === 'amount') {
					return {
						...ele,
						value: state?.amount,
						onChange: (e) => {
							const value = e?.target?.value;
							const isPositiveDecimal =
								/^(?:\d+(\.\d*)?|\.\d+)?$/.test(value);
							if (value === '' || isPositiveDecimal) {
								dispatch({
									type: 'SET_AMOUNT',
									payload: e.target.value,
								});
							}
						},
					};
				}
				if (ele?.id === 'orderNumber') {
					return {
						...ele,
						value: state?.refNo,
						onChange: (e) => {
							dispatch({
								type: 'SET_REF_NO',
								payload: e.target.value,
							});
						},
					};
				}
				if (ele?.id === 'clientTransactionId') {
					return {
						...ele,
						value: state?.clientTransactionId,
						onChange: (e) => {
							dispatch({
								type: 'SET_PETTY_CASH_ID',
								payload: e.target.value,
							});
						},
					};
				}
				if (ele?.inputType === 'docUploader') {
					return {
						...ele,
						value: state?.uploadedDoc,
						prefix: state?.uploadedDoc?.file ? 'Update' : 'Upload',
						btnText: 'Reference File',
						uploadFileClick: () => {
							toggleFileUploaderModal(true, {
								heading: 'Upload Vendor Invoice',
								selectedFile: '',
								confirmText: 'Upload',
								handleConfirm: (uploadedFile) => {
									dispatch({
										type: 'SET_UPLOADED_DOC',
										payload: uploadedFile,
									});
								},
							});
						},
					};
				}
				return ele;
			});
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} showProgress />
			<CreatePageHeader
				heading='Add Ad-Hoc Expense/Income'
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className={styles.body}>
				<CreateFormpage
					inputsList={formattedInputs(
						pettyCashCreateConfig?.inputsList
					)}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addVendorModal;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const storesList = state.form.STORES.data.LIST;
	const { permittedStores } = state.navigation;

	return {
		show,
		data,
		vendorsList,
		storesList,
		permittedStores,
	};
};

const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PettyCashCreatePage);
