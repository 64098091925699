/* eslint-disable no-nested-ternary */
/* eslint-disable no-tabs */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { connect, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import {
	InputAdornment,
	OutlinedInput,
	FormControl,
	InputLabel,
	Skeleton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash';
import {
	setCookie,
	getCookie,
	deleteCookie,
} from '../../../Services/Storage Services';
import { useAuth } from './auth';
import { notificationServices, authServices } from '../../../Services';
import {
	getRandomNeonDarkColor, history, inputHelper
} from '../../../Helpers';
import { navigationActions } from '../../../Actions';
import styles from './Login.module.scss';
import uyNoBg from '../../../Images/uyNoBg.png';
import userRolesAndPermissions from '../../../Services/userRolesAndPermissions.services';
import modulesConfiguration from '../../../Services/modulesConfiguration.services';
import { urls } from '@/Constants';

const useStyles = makeStyles(() => {
	return {
		paper: {
			marginTop: '5rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		form: {
			width: '100%',
			marginTop: '1rem',
		},
		submit: {
			margin: '1rem 0px 2rem 0px',
		},
		version: {
			marginTop: 'auto',
			textAlign: 'center',
			color: 'rgba(0,0,0,0.5)',
		},
	};
});

const initialState = {
	username: '',
	password: '',
};

const Login = ({ navState }) => {
	const classes = useStyles();
	const {
		isLoggedIn,
		signIn,
		setAppConfig,
		appConfig,
		setUserConfig,
		signout,
		setUserStores,
	} = useAuth();

	const [state, setState] = useState({
		...initialState,
	});

	const { username, password } = state;

	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useDispatch();
	const [fetchedConfig, setFetchedConfig] = useState(false);

	const checkToken = async () => {
		const isValid = await authServices.checkToken();
		if (isValid) {
			const stores = JSON.parse(localStorage.getItem('userStores'));
			setUserStores(stores);
			const userRoles = {};
			const userLevels = {};
			const storedAppConfig = JSON.parse(getCookie('user'));
			stores?.forEach((store) => {
				store?.levels?.forEach((roleAndLevel) => {
					userRoles[roleAndLevel.roleId] = true;
					userLevels[roleAndLevel.levelId] = true;
				});
			});
			dispatch(
				navigationActions.updateUserPermittedStores({
					userStores: stores,
				})
			);
			if (Object.keys(userRoles).length > 0) {
				dispatch(
					navigationActions.handleUserRolesNavItem({
						userRoles,
					})
				);
				dispatch(
					navigationActions.updateUserPermissionsBasedOnLevels({
						userLevels: Object.keys(userLevels),
						internal: storedAppConfig.internal,
						accToInternal: true,
					})
				);
			}
			signIn(storedAppConfig);
		}
	};
	const fetchAppConfig = (autoLogin = false) => {
		authServices.fetchAppConfig().then(
			(res) => {
				const { data } = res;
				localStorage.setItem('appConfig', JSON.stringify(data));
				setAppConfig(data);
				dispatch(navigationActions.updateNavPermissions());
				setFetchedConfig(true);
				// setCookie('appConfig',JSON.stringify(res.data),30);
				setUserConfig(JSON.parse(getCookie('user')));
				if (autoLogin) {
					checkToken();
				}
			},
			(err) => {
				notificationServices.generateNotification({
					type: 'error',
					message: err,
				});
			}
		);
	};

	const updateNavState = async () => {
		try {
			const navStateRolesMapped = await modulesConfiguration.getModulesConfiguration();
			dispatch(navigationActions.updateNavStateRolesMapping({
				rolesConfig: navStateRolesMapped.data,
			}));
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
		}
	};

	useEffect(() => {
		if (!signout) {
			if (getCookie('user')) {
				fetchAppConfig(true);
			} else {
				fetchAppConfig();
			}
		} else {
			fetchAppConfig();
		}
		updateNavState();
	}, []);

	const formSubmitHandler = async () => {
		if (!fetchedConfig) {
			notificationServices.generateNotification({
				type: 'error',
				message: ' Config not fetched, Refreshing Page...',
			});
			setTimeout(() => {
				window.location.reload();
			}, 200);
			return;
		}
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'Loading...',
		});
		setLoading(true);

		try {
			const req = {
				data: {
					UserName: username,
					Password: password,
				},
			};
			const res = await authServices.login(req);
			let message = '';
			let type = '';
			if (res.statusCode === 200) {
				setCookie('logged_in', 'true', 1440);
				setCookie(
					'user',
					JSON.stringify({
						...res,
						color: getRandomNeonDarkColor(),
					}),
					1440
				);
				setUserConfig({
					...res,
					color: getRandomNeonDarkColor(),
				});
				const userName = get(res, 'userDetails.name', '');
				message = `Welcome ${userName}!`;
				type = 'wave';
				try {
					const data = await userRolesAndPermissions.getUserStores(req);
					localStorage.setItem('userStores', JSON.stringify(data.data));
					const userRoles = {};
					const userLevels = {};
					data?.data?.forEach((store) => {
						store?.levels?.forEach((roleAndLevel) => {
							userRoles[roleAndLevel.roleId] = true;
							userLevels[roleAndLevel.levelId] = true;
						});
					});
					setUserStores(data.data);
					dispatch(
						navigationActions.updateUserPermittedStores({
							userStores: data.data,
						})
					);
					if (Object.keys(userRoles).length > 0) {
						dispatch(
							navigationActions.handleUserRolesNavItem({
								userRoles,
							})
						);
						dispatch(
							navigationActions.updateUserPermissionsBasedOnLevels({
								userLevels: Object.keys(userLevels),
								internal: res.internal,
								accToInternal: true,
							})
						);
					}
					await signIn(res);
				} catch (err) {
					// eslint-disable-next-line no-console
					console.log('userStores', err);
				}
			} else if (res.errorMessage !== undefined) {
				deleteCookie('logged_in');
				deleteCookie('userCookie');
				message = res.errorMessage;
				type = 'error';
			} else if (res.message !== undefined) {
				message = res.message;
				type = '';
			}
			notificationServices.generateNotification({
				type,
				message,
				config: {
					id: notificationId,
				},
			});
			setLoading(false);
		} catch (err) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Unable to login, Please try again',
				config: {
					id: notificationId,
				},
			});
			setLoading(false);
		}
	};

	if (isLoggedIn) {
		const redirectionState = history?.location?.state?.from ?? null;
		const desiredUrlSplits = redirectionState?.pathname.split('/');
		let defaultRedirection = `${urls.EXTENSION}${urls.NOT_ACCESSIBLE_PAGE}`;
		let isDisabled = true;
		const desiredUrl = desiredUrlSplits?.slice(2)?.join('/');
		let allowedDesriredUrl = '';
		let parentUrl = '';
		for (const urlSplit in desiredUrlSplits) {
			if (desiredUrlSplits[urlSplit] === urls.EXTENSION.substring(1)) {
				// eslint-disable-next-line no-continue
				continue;
			}
			allowedDesriredUrl += desiredUrlSplits[urlSplit];
			if (parentUrl !== '') {
				if (navState?.[parentUrl].submenu) {
					if (navState?.[parentUrl].submenu?.[allowedDesriredUrl]) {
						isDisabled = navState?.[parentUrl].submenu?.[allowedDesriredUrl]?.disabled;
					}
				}
			} else if (navState?.[allowedDesriredUrl]) {
				isDisabled = navState?.[allowedDesriredUrl]?.disabled;
				parentUrl = allowedDesriredUrl;
			}
			allowedDesriredUrl += '/';
		}
		if (isDisabled) {
			Object.keys(navState)?.forEach((key) => {
				if (!navState?.[key]?.submenu &&
				navState['/' + desiredUrl]) {
					isDisabled = navState['/' + desiredUrl]?.disabled;
				} else if (navState?.[key]?.submenu) {
					const navSub = navState?.[key]?.submenu;
					Object.keys(navSub)?.forEach((subKey) => {
						if (navSub?.[subKey]) {
							isDisabled = navSub[subKey].disabled;
						}
					});
				}
			});
		}
		if (!isDisabled && desiredUrl && desiredUrl !== 'login' && desiredUrl !== 'nonAccessable') {
			if (navState?.['/' + desiredUrl] && !navState?.['/' + desiredUrl]?.submenu) {
				// eslint-disable-next-line prefer-template
				isDisabled = navState?.['/' + desiredUrl]?.disabled;
			}
			if (!isDisabled) {
				Object.keys(navState).forEach((key) => {
					if (!isDisabled && navState?.[key]?.submenu) {
					// eslint-disable-next-line prefer-template
						isDisabled = navState?.[key]?.submenu?.['/' + desiredUrl]?.disabled;
					} else if (!navState?.[key]?.submenu && navState?.[key]?.disabled === false && defaultRedirection === `${urls.EXTENSION}${urls.NOT_ACCESSIBLE_PAGE}` &&
						navState?.[key]?.href
					) {
						defaultRedirection = navState?.[key]?.href;
					}
				});
			}
		}
		if (isDisabled || desiredUrl === 'nonAccessable' || desiredUrl === 'login') {
			Object.keys(navState)?.forEach((key) => {
				if (!navState?.[key]?.submenu && navState?.[key]?.disabled === false &&
					defaultRedirection === (urls.EXTENSION + urls.NOT_ACCESSIBLE_PAGE) &&
					navState?.[key]?.href) {
					defaultRedirection = navState?.[key]?.href;
				} else if (navState?.[key]?.submenu &&
				defaultRedirection === (urls.EXTENSION + urls.NOT_ACCESSIBLE_PAGE)) {
					const navSub = navState?.[key]?.submenu;
					Object.keys(navSub)?.forEach((subKey) => {
						if (navSub?.[subKey]?.disabled === false &&
							defaultRedirection === (urls.EXTENSION + urls.NOT_ACCESSIBLE_PAGE) &&
							navSub[subKey].href) {
							defaultRedirection = navSub[subKey].href;
						}
					});
				}
			});
			return (
				<Redirect
					to={
						defaultRedirection
					}
				/>
			);
		}
		// console.log(desiredUrl, defaultRedirection, 'pppppppp');
		return (
			<Redirect
				to={
					redirectionState
						? `${redirectionState?.pathname}${redirectionState?.search}`
						: defaultRedirection
				}
			/>
		);
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			formSubmitHandler();
		}
	};

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setState({
			...state,
			[fieldName]: fieldValue,
		});
	};

	return (
		<div className={styles.root}>
			<div className={styles.paper}>
				{(appConfig?.logob64 || appConfig?.logo) ? (
					<img
						className={styles['client-logo']}
						src={appConfig?.logob64 ?? appConfig?.logo}
						alt={appConfig?.companyName}
					/>
				) : (
					<Skeleton variant='rounded' width={200} height={75} />
				)}
				<div className={styles['form-container']}>
					<Typography
						style={{
							marginBottom: 20,
						}}
						component='h1'
						variant='h3'
					>
						Console
					</Typography>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					<div className={classes.form} noValidate>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='username'
							label='Username'
							name='username'
							autoComplete='username'
							autoFocus
							value={username}
							onChange={handleInput}
							onKeyPress={handleKeyPress}
						/>
						<FormControl fullWidth variant='outlined'>
							<InputLabel required htmlFor='password'>
								Password
							</InputLabel>
							<OutlinedInput
								required
								fullWidth
								name='password'
								label='Password'
								id='password'
								type={showPassword ? 'text' : 'password'}
								autoComplete='current-password'
								value={password}
								onChange={handleInput}
								onKeyPress={handleKeyPress}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											onClick={() => {
												setShowPassword(!showPassword);
											}}
											size='large'
										>
											{showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						{/* Mock first time login: <Checkbox checked={mock} onChange={()=>setMock(!mock)}/> */}
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							className={classes.submit}
							disabled={
								username.length === 0 ||
								password.length === 0 ||
								loading
							}
							onClick={formSubmitHandler}
						>
							{(loading ? 'Signing In' : (fetchedConfig ? 'Sign In' : 'Refresh Page'))}
						</Button>
					</div>
				</div>
				<div className={styles['uyLogo-version']}>
					<img
						className={styles.logo}
						src={uyNoBg}
						alt='UdyogYantra'
					/>
					<p className={styles.version}>
						{`v${process.env.REACT_APP_VERSION}`}
					</p>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
