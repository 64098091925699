import styles from './MealDetails.module.css';
import { classes } from '../../Helpers';
import { STAT_COLORS } from '../../Styles/colors';

const MealDetails = (props) => {
	const { meal } = props;

	return (
		<div className={classes(styles.root)}>
			<div className={classes(styles['meal-image'])}>
				{/* Meal Results Vertical */}
				<div
					className={classes(styles['meal-results'], styles.vertical)}
				>
					{/* Food Weight */}
					{meal?.weight && (
						<div
							className={classes(styles.result)}
							style={{
								backgroundColor: STAT_COLORS[1],
							}}
						>
							<span className={classes(styles['result-header'])}>
								Food Weight
							</span>
							<span className={classes(styles['result-stat'])}>
								{`${
									(typeof meal.weight === 'number'
										? (meal.weight * 1000).toFixed(0)
										: meal.weight) ?? '-'
								}g`}
							</span>
						</div>
					)}
					{/* Food Temp */}
					{meal?.temp && (
						<div
							className={classes(styles.result)}
							style={{
								backgroundColor: STAT_COLORS[2],
							}}
						>
							<span className={classes(styles['result-header'])}>
								Food Temp
							</span>
							<span className={classes(styles['result-stat'])}>
								{`${meal?.temp ?? '-'}℃`}
							</span>
						</div>
					)}
					{/* Protein */}
					<div
						className={classes(styles.result)}
						style={{
							backgroundColor: STAT_COLORS[0],
						}}
					>
						<span className={classes(styles['result-header'])}>
							Protein
						</span>
						<span className={classes(styles['result-stat'])}>
							{`${meal?.protein ?? '-'}g`}
						</span>
					</div>
					{/* Calories */}
					<div
						className={classes(styles.result)}
						style={{
							backgroundColor: STAT_COLORS[3],
						}}
					>
						<span className={classes(styles['result-header'])}>
							Calories
						</span>
						<span className={classes(styles['result-stat'])}>
							{`${meal?.calories ?? '-'}cal`}
						</span>
					</div>
					{/* Carbs in g */}
					<div
						className={classes(styles.result)}
						style={{
							backgroundColor: STAT_COLORS[6],
						}}
					>
						<span className={classes(styles['result-header'])}>
							Carbs
						</span>
						<span className={classes(styles['result-stat'])}>
							{`${meal?.carbs ?? '-'}g`}
						</span>
					</div>
					{/* Calcium in mg */}
					<div
						className={classes(styles.result)}
						style={{
							backgroundColor: STAT_COLORS[7],
						}}
					>
						<span className={classes(styles['result-header'])}>
							Calcium
						</span>
						<span className={classes(styles['result-stat'])}>
							{`${meal?.calcium ?? '-'}mg`}
						</span>
					</div>
					{/* Fat in g */}
					<div
						className={classes(styles.result)}
						style={{
							backgroundColor: STAT_COLORS[8],
						}}
					>
						<span className={classes(styles['result-header'])}>
							Fat
						</span>
						<span className={classes(styles['result-stat'])}>
							{`${meal?.fat ?? '-'}g`}
						</span>
					</div>
					{/* Fibre in g */}
					{meal?.fiber && (
						<div
							className={classes(styles.result)}
							style={{
								backgroundColor: STAT_COLORS[9],
							}}
						>
							MealDetails
							{' '}
							<span className={classes(styles['result-header'])}>
								Fiber
							</span>
							<span className={classes(styles['result-stat'])}>
								{`${meal?.fiber ?? '-'}g`}
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MealDetails;
