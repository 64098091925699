import { Warning } from '@mui/icons-material';
import toast from 'react-hot-toast';
import authActions from '../Actions/auth.actions';
import { store } from '../Helpers';

const notificationServices = {
	generateNotification,
};
function generateNotification({ type, message, config = {} }) {
	let notificationId = null;
	if (config.id) {
		store.dispatch(authActions.toggleLoading(false));
	}

	if (typeof message === 'object') {
		return null;
	}
	switch (type) {
		case 'success':
			notificationId = toast.success(message, {
				...config,
			});
			break;
		case 'error':
			notificationId = toast.error(message, {
				...config,
			});
			break;
		case 'loading':
			store.dispatch(authActions.toggleLoading(true));
			notificationId = toast.loading(message, {
				...config,
			});
			break;
		case 'warning':
			notificationId = toast(message, {
				icon: (
					<Warning
						style={{
							color: '#ffc107',
						}}
					/>
				),
				...config,
			});
			break;
		case 'wave':
			notificationId = toast(message, {
				icon: '👏',
				...config,
			});
			break;
		default:
			notificationId = toast(message, {
				...config,
			});
			break;
	}
	return notificationId;
}

export default notificationServices;
