import _ from 'lodash';

const requestRefundConfig = {
	initialState: {
		query: '',
		fields: {
			orderId: '',
			customerDetails: {
				name: '',
				mobile: '',
				address: '',
				email: '',
			},
			paymentDetails: {
				cartValue: '',
				invoiceValue: '',
				transactionAmount: '',
				refundedAmount: '',
			},
			promoDetails: {
				couponApplied: null,
				couponDiscount: '',
			},
			items: [],
			raiseRefundRequestState: [],
			refundDetails: {
				recommendedRefundAmount: '',
				refundReason: '',
				image: '',
			},
		},
	},
	list: [],
	deserializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', (fields) => {
			const sendData = {
				orderId: fields.orderId,
				recommendedRefundAmount:
					fields.refundDetails.recommendedRefundAmount,
				image: fields.refundDetails.image,
				refundReason: fields.refundDetails.refundReason,
			};
			return sendData;
		});
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				orderId: data.orderId,
				customerDetails: data.customerDetails,
				paymentDetails: data.orderDetails,
				items: data.orderDetails.orderItems,
				raiseRefundRequestState: data.refundHistory,
				promoDetails: data.orderDetails.promoDetails,
				refundDetails: {
					orderId: data.orderId,
					recommendedRefundAmount: '',
					image: '',
					refundReason: '',
				},
			};
			return newState;
		});
	},
};
export default requestRefundConfig;
