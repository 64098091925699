import { DraggableHCard, HCard } from './DraggableHCard';

const DraggableHCards = (props) => {
	const { cardsList, moveCard, isDraggable } = props;

	if (isDraggable) {
		return cardsList.map((card, idx) => {
			return (
				<DraggableHCard
					key={card.key}
					id={card.key}
					index={idx}
					moveCard={moveCard}
					isDraggable={isDraggable}
				>
					{card}
				</DraggableHCard>
			);
		});
	}
	return cardsList.map((card, idx) => {
		return (
			<HCard
				key={card.key}
				id={card.key}
				index={idx}
				moveCard={moveCard}
				isDraggable={isDraggable}
			>
				{card}
			</HCard>
		);
	});
};

DraggableHCards.defaultProps = {
	isDraggable: true,
};

export default DraggableHCards;
