// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import annotationActions from '../../../Actions/annotation.actions';
import { BaseModal } from '../BaseModal';
import { CheckList } from '../../CheckList';
import { useSearch } from '../../../Helpers';
import { modalTypes } from '../../../Constants';

const HEADING = 'Select Label';

const initialState = {
	product: null,
};

const MODAL_TYPE = modalTypes.ANNOTATION_LABELS;

const AnnotationToolModal = (props) => {
	const { show, data, labels, toggleModal, heading } = props;
	const { query, handleSearch, clearSearch } = useSearch();
	const [state, setState] = useState({
		...initialState,
	});

	useEffect(() => {
		setState({
			...initialState,
		});
	}, [show]);

	const handleClose = () => {
		clearSearch();
		toggleModal(false);
	};

	const handleCancel = () => {
		// eslint-disable-next-line react/destructuring-assignment
		props.handleCancel(data.annotationKey, data?.isEdit ?? false);
		handleClose();
	};

	const handleConfirm = (idx) => {
		const label = labels?.[idx]?.title;
		const id = labels?.[idx]?.ID;
		// eslint-disable-next-line react/destructuring-assignment
		props.handleConfirm({
			menuItem: {
				id,
				label,
				key: label,
			},
			...data,
		});
		handleClose();
	};

	const renderBtns = () => {
		return '';
	};

	const generateProductList = () => {
		return labels
			.filter((item) => {
				const name = item?.title;
				return (
					name
						?.toLowerCase()
						?.indexOf(query?.toLowerCase()?.trim()) !== -1
				);
			})
			.map((item) => {
				const id = item?.id;
				const name = item?.title;
				return {
					id: parseInt(id, 10),
					title: name,
				};
			});
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleCancel}
			height='30rem'
			width='32rem'
			handleConfirm={handleConfirm}
			renderBtns={renderBtns}
		>
			<CheckList
				list={generateProductList()}
				selectedItems={state.selectedList}
				handleItemSelect={handleConfirm}
				showSearch
				handleSearchInput={handleSearch}
				placeholder='Search Class'
			/>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.[MODAL_TYPE];
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(MODAL_TYPE, show, data));
		},
		fetchProducts: (...args) => {
			dispatch(annotationActions.fetchProducts(...args));
		},
	};
};

AnnotationToolModal.defaultProps = {
	heading: HEADING,
	handleConfirm: () => {},
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AnnotationToolModal);
