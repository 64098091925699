import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import styles from './MyContainersPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import containerServices from '../../../Services/myContianer.services';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { convertWeight } from '../../../Helpers';
import { notificationServices } from '../../../Services';

const TABLE_COLUMNS = [];

const SPECIAL_COLUMNS = [
	{
		title: 'Image',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Container Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Mode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Weight',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Delete Container',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	Image: 'containerImage',
	'Container Name': 'name',
	Mode: 'mode',
	Weight: 'weight',
};

const FIND_FILTERS = [];

function MyContainersPage({ userPermissions, createPath }) {
	const history = useHistory();
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const isUS = measurement === 'US';
	const [isLoading, setIsLoading] = useState(false);
	const [containersData, setContainersData] = useState([]);

	const handleListFetch = async () => {
		setIsLoading(true);
		try {
			const responseData = await containerServices.fetchContainerList();
			if (responseData?.statusCode === 200) {
				setContainersData(responseData?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		handleListFetch({});
	}, []);

	const handleDeleteContainer = async (containerId) => {
		const req = {
			data: {
				containerId,
			},
		};
		try {
			const response = await containerServices.removeContainer(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				handleListFetch();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const SPECIAL_ITEMS = {
		Image: (id) => {
			const container = containersData?.find((ele) => {
				return ele.id === id;
			});
			return (
				<img
					style={{
						width: '75px',
						height: '75px',
						borderRadius: '20px',
					}}
					src={container?.containerImage}
					alt='Container'
				/>
			);
		},
		'Container Name': (id) => {
			const container = containersData?.find((ele) => {
				return ele.id === id;
			});
			return <div>{container?.name}</div>;
		},
		Mode: (id) => {
			const container = containersData?.find((ele) => {
				return ele.id === id;
			});
			return <div>{container?.mode}</div>;
		},
		Weight: (id) => {
			const container = containersData?.find((ele) => {
				return ele.id === id;
			});
			const convertedWeight = convertWeight(container?.weight, isUS);
			return (
				<div>
					{convertedWeight?.value?.toFixed(2)}
					{convertedWeight?.unit}
				</div>
			);
		},
		'Delete Container': (id) => {
			const container = containersData?.find((ele) => {
				return ele.id === id;
			});
			return userPermissions?.INTERNAL ? (
				<Button
					variant='outlined'
					color='error'
					type='button'
					onClick={() => {
						handleDeleteContainer(container?.id);
					}}
				>
					Delete
				</Button>
			) : '-';
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={isLoading} />
			<ImageModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='My Containers'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					additionInfo={{
						showAdd: userPermissions?.INTERNAL,
						// showAdd: false,
						addText: 'Container',
						handleAddClick,
					}}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={containersData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyContainersPage);
