/* eslint-disable no-tabs */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getGeoDataAction, modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import { CheckList } from '../../CheckList';
import analyticsAction from '../../../Actions/analytics.actions';

const initialState = {
	stateId: [],
	cityId: [],
	storeId: [],
	expandStateId: null,
	expandCityId: null,
	level: 1,
};
const GeographyFilterModal = (props) => {
	const HEADING = 'Geography Filter';
	const [state, setState] = useState({
		...initialState,
	});
	const {
		show,
		geographyFilterModal,
		fetchGeoData,
		geoData,
		setGeoData,
		updatedGeoData,
		// updatedBrandData,
		// fetchData,
		// timeFilterData,
		filterModal,
		brandModal,
		dispatchSelectedList: dispatch,
	} = props;
	const { stateId, cityId, storeId, expandCityId, expandStateId, level } =
		state;
	const handleClose = () => {
		geographyFilterModal(false);
	};
	useEffect(() => {
		fetchGeoData();
	}, []);
	useEffect(() => {
		setState({
			stateId: updatedGeoData.state,
			cityId: updatedGeoData.city,
			storeId: updatedGeoData.store,
			expandStateId: null,
			expandCityId: null,
			level: 1,
		});
	}, [show]);
	const handleStateId = (setStateId) => {
		setState({
			...state,
			expandStateId: setStateId,
			level: 2,
		});
	};
	const handleCityId = (setCityId) => {
		setState({
			...state,
			expandCityId: setCityId,
			level: 3,
		});
	};
	const handleStoreId = (setStoreId, isSelected) => {
		if (isSelected) {
			const currCityStoreId = Object.keys(
				geoData[expandStateId].city[expandCityId].store
			).map((currStoreId) => {
				return parseInt(currStoreId, 10);
			});
			const tempCurrCityStoreId = currCityStoreId.filter((currId) => {
				return storeId.includes(currId);
			});
			const moreThanOneStoreExist = tempCurrCityStoreId.length > 1;
			let currStateCityId = Object.keys(geoData[expandStateId].city).map(
				(currCityId) => {
					return parseInt(currCityId, 10);
				}
			);
			currStateCityId = currStateCityId.filter((currCityId) => {
				return cityId.includes(currCityId);
			});
			const store = storeId.filter((currStoreId) => {
				return currStoreId !== setStoreId;
			});
			const city = moreThanOneStoreExist
				? cityId
				: cityId.filter((currCityId) => {
						return currCityId !== expandCityId;
				  });
			const tempstate =
				moreThanOneStoreExist || currStateCityId.length > 1
					? stateId
					: stateId.filter((currStateId) => {
							return currStateId !== expandStateId;
					  });
			setState({
				...state,
				storeId: store,
				cityId: city,
				stateId: tempstate,
			});
			dispatch({
				type: 'SET_GEO_FILTER',
				payload: {
					...updatedGeoData,
					store,
					city,
					state: tempstate,
				},
			});
		} else {
			const store = [...storeId, setStoreId];
			const tempstate = stateId.includes(expandStateId)
				? stateId
				: [...stateId, expandStateId];
			const city = cityId.includes(expandCityId)
				? cityId
				: [...cityId, expandCityId];
			setState({
				...state,
				storeId: store,
				stateId: tempstate,
				cityId: city,
			});
			dispatch({
				type: 'SET_GEO_FILTER',
				payload: {
					...updatedGeoData,
					store,
					city,
					state: tempstate,
				},
			});
		}
	};
	const selectStateId = (setStateId, isSelected) => {
		const cityList = Object.keys(geoData[setStateId].city).map((currId) => {
			return parseInt(currId, 10);
		});
		const storeList = cityList.reduce((accStoreList, currCityId) => {
			const storeListId = Object.keys(
				geoData[setStateId].city[currCityId].store
			).map((currId) => {
				return parseInt(currId, 10);
			});
			accStoreList = [...accStoreList, ...storeListId];
			return accStoreList;
		}, []);
		if (isSelected) {
			const tempstate = stateId.filter((currStateId) => {
				return currStateId !== setStateId;
			});
			const city = cityId.filter((currCityId) => {
				return !cityList.includes(currCityId);
			});
			const store = storeId.filter((currStoreId) => {
				return !storeList.includes(currStoreId);
			});
			setState({
				...state,
				stateId: tempstate,
				cityId: city,
				storeId: store,
			});
			dispatch({
				type: 'SET_GEO_FILTER',
				payload: {
					...updatedGeoData,
					store,
					city,
					state: tempstate,
				},
			});
		} else {
			const tempstate = [...stateId, setStateId];
			const city = [...cityId, ...cityList];
			const store = [...storeId, ...storeList];
			setState({
				...state,
				stateId: tempstate,
				cityId: city,
				storeId: store,
			});
			dispatch({
				type: 'SET_GEO_FILTER',
				payload: {
					...updatedGeoData,
					store,
					city,
					state: tempstate,
				},
			});
		}
	};
	const selectCityId = (setCityId, isSelected) => {
		const storeList = Object.keys(
			geoData[expandStateId].city[setCityId].store
		).map((currId) => {
			return parseInt(currId, 10);
		});
		if (isSelected) {
			const currStateCityId = Object.keys(
				geoData[expandStateId].city
			).filter((currCityId) => {
				return cityId.includes(parseInt(currCityId, 10));
			});
			const tempstate =
				currStateCityId.length > 1
					? stateId
					: stateId.filter((currStateId) => {
							return currStateId !== expandStateId;
					  });
			const city = cityId.filter((currCityId) => {
				return currCityId !== setCityId;
			});
			const store = storeId.filter((currStateId) => {
				return !storeList.includes(currStateId);
			});
			setState({
				...state,
				cityId: city,
				storeId: store,
				stateId: tempstate,
			});
			dispatch({
				type: 'SET_GEO_FILTER',
				payload: {
					...updatedGeoData,
					store,
					city,
					state: tempstate,
				},
			});
		} else {
			const city = [...cityId, setCityId];
			const tempstate = [...stateId, expandStateId];
			const store = [...storeId, ...storeList];
			setState({
				...state,
				cityId: city,
				storeId: store,
				stateId: tempstate,
			});
			dispatch({
				type: 'SET_GEO_FILTER',
				payload: {
					...updatedGeoData,
					store,
					city,
					state: tempstate,
				},
			});
		}
	};

	const generateList = (currLevel) => {
		return currLevel === 1 ? (
			<CheckList
				list={Object.keys(geoData).reduce((accGeoData, currGeoData) => {
					const tempGeoData = {
						id: parseInt(currGeoData, 10),
						title: geoData[currGeoData].stateName,
					};
					accGeoData.push(tempGeoData);
					return accGeoData;
				}, [])}
				selectedItems={stateId}
				handleItemExpand={handleStateId}
				handleItemSelect={selectStateId}
				isExpandable
			/>
		) : currLevel === 2 ? (
			<CheckList
				list={Object.keys(geoData[expandStateId].city).reduce(
					(accGeoData, currGeoData) => {
						const tempGeoData = {
							id: parseInt(currGeoData, 10),
							title: geoData[expandStateId].city[currGeoData]
								.cityName,
						};
						accGeoData.push(tempGeoData);
						return accGeoData;
					},
					[]
				)}
				selectedItems={cityId}
				handleItemExpand={handleCityId}
				handleItemSelect={selectCityId}
				isExpandable
			/>
		) : (
			<CheckList
				list={Object.keys(
					geoData[expandStateId].city[expandCityId].store
				).reduce((accGeoData, currGeoData) => {
					const tempGeoData = {
						id: parseInt(currGeoData, 10),
						title: geoData[expandStateId].city[expandCityId].store[
							currGeoData
						].name,
					};
					accGeoData.push(tempGeoData);
					return accGeoData;
				}, [])}
				handleItemSelect={handleStoreId}
				selectedItems={storeId}
			/>
		);
	};
	const handleBack = () => {
		switch (level) {
			case 1:
				handleClose();
				filterModal(true);
				break;
			case 2:
				setState({
					...state,
					level: state.level - 1,
					expandStateId: null,
					expandCityId: null,
				});
				break;
			case 3:
				setState({
					...state,
					level: state.level - 1,
					expandCityId: null,
				});
				break;
			default:
				setState({
					...initialState,
				});
		}
	};
	// eslint-disable-next-line consistent-return
	// const handleRenderButton = () => {
	// 	if (level === 1) {
	// 		return stateId.length > 0 ? (
	// 			<></>
	// 		) : (
	// 			{
	// 				renderBtns: () => {
	// 					<Button variant='contained' color='primary'>
	// 						Confirm
	// 					</Button>;
	// 				},
	// 			}
	// 		);
	// 	}
	// 	if (level === 2) {
	// 		const list = Object.keys(geoData[expandStateId].city)
	// 			.map((currGeoData) => {
	// 				return parseInt(currGeoData, 10);
	// 			})
	// 			.filter((currCityId) => {
	// 				return cityId.includes(currCityId);
	// 			});
	// 		return list.length > 0 ? (
	// 			<></>
	// 		) : (
	// 			{
	// 				renderBtns: () => {
	// 					<>
	// 						<Button variant='contained' color='primary' hidden>
	// 							Confirm
	// 						</Button>
	// 					</>;
	// 				},
	// 			}
	// 		);
	// 	}
	// 	if (level === 3) {
	// 		const list = Object.keys(
	// 			geoData[expandStateId].city[expandCityId].store
	// 		)
	// 			.map((currGeoData) => {
	// 				return parseInt(currGeoData, 10);
	// 			})
	// 			.filter((currStoreId) => {
	// 				return storeId.includes(currStoreId);
	// 			});
	// 		return list.length ? (
	// 			<></>
	// 		) : (
	// 			{
	// 				renderBtns: () => {
	// 					<>
	// 						<Button variant='contained' color='primary' hidden>
	// 							Confirm
	// 						</Button>
	// 					</>;
	// 				},
	// 			}
	// 		);
	// 	}
	// };
	const displayShowBack = () => {
		return true;
	};
	const handleConfirm = () => {
		setGeoData({
			state: stateId,
			city: cityId,
			store: storeId,
		});
		dispatch({
			type: 'SET_GEO_FILTER',
			payload: {
				...updatedGeoData,
				store: storeId,
				city: cityId,
				state: stateId,
			},
		});
		// fetchData({
		// 	entity: 'STORE_ANALYSIS',
		// 	req: {
		// 		brandFilter: updatedBrandData,
		// 		timeFilter: timeFilterData,
		// 		geoFilter: storeId,
		// 	},
		// });
		props.handleConfirm();
		filterModal(false);
		brandModal(false);
		geographyFilterModal(false);
	};
	return (
		<div>
			<BaseModal
				open={show}
				heading={HEADING}
				handleClose={handleClose}
				height={450}
				showBack={displayShowBack()}
				handleBack={handleBack}
				handleConfirm={handleConfirm}
				width={450}
			>
				{generateList(state.level)}
			</BaseModal>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { show, data } = state.modal.geography;
	const geoData = state.geoData.GEO_DATA.data;
	const updatedBrandData = state.analytics.BRAND_FILTER.selectedList;
	const timeFilterData = state.analytics.TIME_FILTER;
	return {
		show,
		data,
		geoData,
		updatedBrandData,
		timeFilterData,
	};
};
const mapDispatchToProps = {
	geographyFilterModal: modalActions.toggleGeographyFilterModal,
	fetchGeoData: getGeoDataAction.fetchGeoData,
	setGeoData: analyticsAction.setGeoData,
	fetchData: analyticsAction.gateway,
	filterModal: modalActions.toggleFilterModal,
	brandModal: modalActions.toggleBrandFilterModal,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeographyFilterModal);
