import { CheckListItem } from './CheckListItem';
import styles from './CheckList.module.scss';
import SearchBar from '../SearchBar/SearchBar';

const CheckList = (props) => {
	const {
		list,
		emptyMessage,
		displayCheck,
		showSearch,
		handleSearchInput,
		handleItemSelect,
		handleItemExpand,
		isExpandable,
		placeholder,
		selectAll,
		selectedItems,
	} = props;
	return (
		<div className={styles['check-list']}>
			{showSearch && (
				<SearchBar
					autofocus
					handleInput={handleSearchInput}
					placeholder={placeholder}
					className={styles['search-bar']}
				/>
			)}
			<div className={styles['check-list-item-container']}>
				{list.length > 0 ? (
					list.map((item) => {
						return (
							<CheckListItem
								key={item.id}
								item={item}
								isSelected={
									selectedItems.indexOf(item.id) !== -1 ||
									selectedItems.indexOf(item.title) !== -1 ||
									selectAll
								}
								handleItemSelect={handleItemSelect}
								displayCheck={displayCheck}
								handleItemExpand={handleItemExpand}
								isExpandable={isExpandable}
							/>
						);
					})
				) : (
					<p>{emptyMessage}</p>
				)}
			</div>
		</div>
	);
};

CheckList.defaultProps = {
	list: [],
	selectedItems: [],
	selectAll: false,
	displayCheck: true,
	handleItemSelect: () => {},
	emptyMessage: 'No items found',
	handleItemExpand: () => {},
	isExpandable: false,
	showSearch: false,
	placeholder: '',
};

export default CheckList;
