// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { CreatePage } from '../../../Pages';
import { InventorySettingEditPage } from '../../../Pages/Inventory';

const InventorySettingRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<CreatePage
					createPage={<InventorySettingEditPage />}
					entity='INVENTORY SETTING'
				/>
			</Route>
		</Switch>
	);
};

export default InventorySettingRoutes;
