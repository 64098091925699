/* eslint-disable no-tabs */
import { Switch } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper } from '../../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Inventory Management',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Bulk Consumption',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Batch Mode',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	'Inventory Management': 'isIMSEnabled',
	'Bulk Consumption': 'bulkConsumption',
	'Batch Mode': 'isBatchMode',
};

const InventoryConfigListPage = (props) => {
	const { data, query, fetchInventoryConfig, toggleBulkConmsumption } = props;

	useEffect(() => {
		fetchInventoryConfig({
			entity: 'INVENTORY_CONFIG',
			req: {
				params: {
					id: '',
					skuType: '',
					combo: true,
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleEnable = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);

		toggleBulkConmsumption({
			entity: 'INVENTORY_CONFIG',
			req: {
				params: {
					id: dataset.id,
				},
				data: {
					[fieldName]: fieldValue,
				},
			},
			method: 'PATCH',
		});
	};

	const SPECIAL_ITEMS = {
		'Inventory Management': (id, isEnabled) => {
			return (
				<Switch
					name='IMSEnabled'
					value='IMSEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'INVENTORY_CONFIG',
					}}
				/>
			);
		},
		'Bulk Consumption': (id, isEnabled) => {
			return (
				<Switch
					name='bulkEnabled'
					value='bulkEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'INVENTORY_CONFIG',
					}}
				/>
			);
		},
		'Batch Mode': (id, isEnabled) => {
			return (
				<Switch
					name='batchEnabled'
					value='batchEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'INVENTORY_CONFIG',
					}}
				/>
			);
		},
	};

	return (
		<div className='list-page-root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={Object.keys(data)
					.filter((productId) => {
						const product = data[productId];
						return (
							product.productName
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})
					.map((productId) => {
						return data[productId];
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_CONFIG.data.LIST;
	// const data = CookingStationsConfig.api;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchInventoryConfig: formActions.gateway,
	toggleBulkConmsumption: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryConfigListPage);
