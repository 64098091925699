import './OrderTracking.scss';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import Timecards from '../Timeline/Timecards';
import FmsManufacturing from '../FmsManufacturing/FmsManufacturing';
import { notificationServices } from '../../../Services/Notification';
import { modalActions } from '../../../Actions';
import { POItemsModal } from '../../Modals/POItemsModal';
// import BaseKitchen from './BaseKitchen';
// import Outlet from './Outlet';

const Dummy2 = ({
	storename, outletname, processSelect, poData, selectProcess,
	originStoreId, prodName, selectedState, togglePOItemsModal,
}) => {
	useEffect(() => {
		if (selectProcess === 'fms' && processSelect === 'thirdbt') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Select another Process',
			});
		}
	}, [selectProcess, processSelect]);
	const poActionHandler = async (data) => {
		togglePOItemsModal(true, {
			heading: 'Items',
			items: data,
			excludedColumns: [
				'Dispatched',
				'Received',
				'Packaged',
				'Ordered Quantity',
				'Returned Quantity',
				'Fulfilled',
				'Returned',
				'From Process',
				'PO Id',
				'Item Price',
				'Discount %',
				'Item Sub Total',
				'Tax Percentage',
				'Item Tax',
				'Tax value',
				'Item Amount',
				'Total Item Amount',
				'Category Name',
			],
		});
	};
	return (
		<>
			<POItemsModal />
			<div className='frame'>
				<div style={{
					height: 50,
					display: 'flex',
				}}
				>
					{outletname && (
						<div className='showInfo'>
							<b style={{
								fontWeight: '500',
							}}
							>
								Outlet:
								{' '}
							</b>
							{outletname}
						</div>
					)}
					{storename && (
						<div className='showInfo'>
							<b style={{
								fontWeight: '500',
							}}
							>
								Base Kitchen:
								{' '}
							</b>
							{storename}
						</div>
					)}
				</div>
				{selectProcess === 'scm' && (
					<div className='fixdot2'>
						<Timecards
							id={selectedState?.id1}
							processSelect={processSelect}
							poData={poData}
							selectProcess={selectProcess}
							orderId={selectedState?.orderId}
							type={selectedState?.type}
						/>
					</div>
				)}
				{selectProcess === 'fms' && processSelect !== 'thirdbt' && (
					<div
						className='fixdot3'
					>
						<FmsManufacturing
							prodId={selectedState?.productId}
							originStore={originStoreId}
							prodName={prodName}
							qrCode={selectedState?.id1}
							poActionHandler={poActionHandler}
						/>
					</div>
				)}
			</div>
		</>
	);
};
const mapStateToProps = () => { return {}; };
const mapDispatchToProps = {
	togglePOItemsModal: modalActions?.togglePOItemsModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dummy2);
