/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
import {
	Add,
	ArrowCircleRight,
	Close,
} from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import {
	Button,
	Checkbox,
	IconButton,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useReducer, useMemo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import RangeSlider from 'rsuite/RangeSlider';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import Dropdown from 'rsuite/Dropdown';
import SelectPicker from 'rsuite/SelectPicker';
import Slider from 'rsuite/Slider';
import {
	formActions,
	analyticsAction,
	brandActions,
	navigationActions,
	modalActions,
} from '../../Actions';
import {
	classes, inputHelper, useQuery
} from '../../Helpers';
import ExclusiveFilter from '../ExclusiveFilter/ExclusiveFilter';
import { UYDateRange } from '../Forms/Payment Settlements/Calender/UYDateRange';
import { ListModal } from '../Modals';
import { Tag } from '../Tags';
import config from './config';
import styles from './ImagesFinder.module.scss';
import processFlowServices from '../../Services/Analytics Services/process-flow.services';
import DatePickerCustom from '../DatePickerCustom/DatePickerCustom';
import { urls } from '@/Constants';

const identificationTypes = [
	{
		id: 1,
		type: 'AI',
	},
	{
		id: 2,
		type: 'Manual',
	},
	{
		id: 3,
		type: 'Both',
	},
];

const POSSIBLE_IMAGE_TYPES = [
	{
		id: 1,
		label: 'ASSET',
		returnValue: 'ASSET',
	},
	{
		id: 2,
		label: 'TRANSIT',
		returnValue: 'TRANSIT',
	},
	{
		id: 3,
		label: 'BOTH',
		returnValue: 'BOTH',
	},
];

const EMPTYVALUE = -999;

const defaultTimeTypeArr = [
	{
		label: 'Annotation Time',
		value: 'annotation',
	},
	{
		label: 'QC Time',
		value: 'qc',
	},
];

const typesList = [
	{
		name: 'QUALITY',
		value: 'QUALITY',
	},
	{
		name: 'IDENTITY',
		value: 'IDENTITY',
	},
];

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_BRAND':
			return {
				...state,
				brand: action.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action.payload,
			};
		case 'SET_RAISED_BY_STORE':
			return {
				...state,
				raisedBy: action.payload,
			};
		case 'SET_PROCESS_TYPE':
			return {
				...state,
				processType: action.payload,
			};
		case 'SET_FROM_PROCESS':
			return {
				...state,
				fromProcess: action.payload,
			};
		case 'SET_TO_PROCESS':
			return {
				...state,
				toProcess: action.payload,
			};
		case 'SET_FEATURE_TYPE':
			return {
				...state,
				featureType: action.payload,
			};
		case 'SET_PAGE':
			return {
				...state,
				page: action.payload,
			};
		case 'SET_IMAGES_TYPE':
			return {
				...state,
				imagesType: action.payload,
			};
		case 'SET_DEFECTS':
			return {
				...state,
				defects: action.payload,
			};
		case 'SET_VENDORS':
			return {
				...state,
				vendors: action.payload,
			};
		case 'SET_IDENTIFICATION_TYPE':
			return {
				...state,
				identificationType: action.payload,
			};
		case 'SET_PRODUCT_CATEGORY':
			return {
				...state,
				productCategories: action.payload,
			};
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.payload,
			};
		case 'SET_PRODUCT_EXCLUSIVE':
			return {
				...state,
				productExclusive: action.payload,
			};
		case 'SET_TIME_TYPE':
			return {
				...state,
				timeType: action.payload,
			};
		case 'SET_DATE_RANGE':
			return {
				...state,
				dateRange: action.payload,
			};
		case 'SET_STATUS':
			return {
				...state,
				status: action.payload,
			};
		case 'SET_GROUP':
			return {
				...state,
				group: action.payload,
			};
		case 'SET_REGION':
			return {
				...state,
				region: action.payload,
			};
		case 'SET_CUSTOM_EXCLUSIVE_FILTER':
			return {
				...state,
				customExclusiveFilters: {
					...action.payload,
				},
			};
		case 'SET_DATE_ONLY':
			return {
				...state,
				dateOnly: {
					...action.payload,
				},
			};
		default:
			return state;
	}
};

const params = {
	STORE: 'storeId',
	BRAND: 'brand',
	VENDOR: 'vendor',
	PRODUCT: 'product',
	DEFECTS: 'defect',
	CATEGORY: 'productCategory',
	from: 'from',
	to: 'to',
	egaPercentage: 'ega',
	iType: 'itype',
	conf: 'conf',
	GROUP: 'group',
	REGION: 'region',
	STATUS: 'status',
	DATE_RANGE_CHECKED: 'dateRangeActive',
};

const defaultStatusList = [
	{
		id: 'ALL',
		title: 'ALL',
	},
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'PENDING',
		title: 'PENDING',
	},
	{
		id: 'FULFILLED',
		title: 'CLOSED',
	},
	{
		id: 'ABANDONED',
		title: 'ABANDONED',
	},
];

const ImagesFinder = (props) => {
	const [state, dispatch] = useReducer(reducer, config?.initialState);
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(true);

	const query = useQuery();
	const {
		store,
		processType,
		raisedBy,
		brand,
		vendors,
		products,
		productExclusive,
		featureType,
		defects,
		toProcess,
		fromProcess,
		identificationType,
		imagesType,
		timeType,
		page: pageLimit,
		dateRange,
		dateOnly,
		productCategories,
		status,
		group,
		region,
	} = state;
	const {
		FILTERS,
		DEFAULT_FILTERS_VALUE = [],
		// hideProductSelection,
		toggleModal,
		productsList,
		fetchProducts,
		stores,
		permittedStores = [],
		groups,
		regions,
		setParentFilterState = () => {},
		onSubmit,
		fetchStoresList,
		brands,
		fetchBrands,
		isInternal = true,
		hideFinder = false,
		buyersList,
		productCategoriesList,
		fetchProductCategoriesList,
		vendorsList,
		fetchAllVendors,
		fetchDefects,
		defectsList,
		blink,
		pageLabel,
		// fetchProcessTypesList,
		// processTypes,
		dateOnlyText = null,
		dateRangeOnlyText = null,
		additionInfo = {},
		// searchHandler = {},
		update = null,
		allStoreOption = true,
		// allGroupOption = true,
		storeTypes = '',
		groupTypes = '',
		setAutoStore = true,
		// setAutoGroup = true,
		preventDateRangeCheckbox = false,
		// allowFutureDate = false,
		externalFilters = [],
		// eslint-disable-next-line no-unused-vars
		getParentRequisites,
		// eslint-disable-next-line no-unused-vars
		statusList = [],
		timeTypeArray,
		defaultState = {},
		conditionalFilters = [],
		filterLabels = {},
		footerComponent = null,
		headerComponent = () => {},
		onUpdate = () => {},
		customExclusiveFilters = [],
		maxConf = 1,
		minConf = 0.01,
		step = 0.01,
	} = props;
	const reduxDispatch = useDispatch();
	const location = useLocation();
	const [processTypes, setProcessTypes] = useState([]);
	const [sliderValue, setSliderValue] = useState([minConf, maxConf]);
	const [egaPercentage, setEgaPercentage] = useState(0.1);
	const [egaActive, setEgaActive] = useState(false);
	const currentPath = location?.pathname?.split(urls.EXTENSION);
	const navRequisites =
		reduxDispatch(
			navigationActions.getParentRequisites(currentPath?.[1])
		) ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const navStoreFilter = {
		id: navSelectedStore?.value?.id,
		type: 'billFrom',
		title: `By: ${navSelectedStore?.value?.name}`,
		removable: false,
	};

	async function fetchNodes() {
		// setLoading(true);
		try {
			const response = await processFlowServices?.fetchNodes({
				params: {
					storeId: store !== EMPTYVALUE && store,
				},
			});
			if (response?.statusCode === 200) {
				const { nodes } = response?.data ?? {};
				setProcessTypes(nodes);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	}

	useEffect(() => {
		if (FILTERS?.includes('customExclusiveFilters')) {
			customExclusiveFilters?.forEach((ele) => {
				if (!state?.customExclusiveFilters?.[ele?.key]) {
					dispatch({
						type: 'SET_CUSTOM_EXCLUSIVE_FILTER',
						payload: {
							[ele?.key]: ele?.defaultState,
						},
					});
				}
			});
		}
	}, [customExclusiveFilters]);

	useEffect(() => {
		const req = config?.deserializer(
			{
				store: store === EMPTYVALUE ? null : store,
				group: group === EMPTYVALUE ? null : group,
				region: region === EMPTYVALUE ? null : region,
				productExclusive:
					productExclusive === EMPTYVALUE ? null : productExclusive,
				processTypes: processType === EMPTYVALUE ? null : processType,
				brand: brand === EMPTYVALUE ? null : brand,
				vendors,
				products,
				imagesType: POSSIBLE_IMAGE_TYPES?.find((ele) => {
					return ele?.id === imagesType;
				})?.returnValue,
				productCategories,
				identificationType,
				featureType,
				conf: {
					minConf: Math.min(sliderValue[0], sliderValue[1]),
					maxConf: Math.max(sliderValue[0], sliderValue[1]),
				},
				...(egaActive && {
					egaPercentage,
				}),
				timeType,
				pageLimit,
				dateRange,
				dateOnly,
				status,
				customExclusiveFilters: state?.customExclusiveFilters,
			},
			FILTERS,
			isInternal,
			DEFAULT_FILTERS_VALUE
		);
		onUpdate(req);
	}, [state]);

	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		const req = config?.deserializer(
			{
				store: store === EMPTYVALUE ? null : store,
				group: group === EMPTYVALUE ? null : group,
				region: region === EMPTYVALUE ? null : region,
				productExclusive:
					productExclusive === EMPTYVALUE ? null : productExclusive,
				processTypes: processType === EMPTYVALUE ? null : processType,
				brand: brand === EMPTYVALUE ? null : brand,
				vendors,
				products,
				imagesType: POSSIBLE_IMAGE_TYPES?.find((ele) => {
					return ele?.id === imagesType;
				})?.returnValue,
				productCategories,
				identificationType,
				featureType,
				conf: {
					minConf: Math.min(sliderValue[0], sliderValue[1]),
					maxConf: Math.max(sliderValue[0], sliderValue[1]),
				},
				...(egaActive && {
					egaPercentage,
				}),
				timeType,
				pageLimit,
				dateRange,
				dateOnly,
				status,
				customExclusiveFilters: state?.customExclusiveFilters,
			},
			FILTERS,
			isInternal,
			DEFAULT_FILTERS_VALUE
		);
		onSubmit(req);
		setIsDataFetched(true);
	};
	const handleSubmitParams = (data) => {
		const req = config?.deserializer(
			{
				store: data?.store ?? (store === EMPTYVALUE ? null : store),
				group: data?.group ?? (group === EMPTYVALUE ? null : group),
				region: data?.region ?? (region === EMPTYVALUE ? null : region),
				productExclusive:
					data?.productExclusive ??
					(productExclusive === EMPTYVALUE ? null : productExclusive),
				processTypes:
					data?.processType ??
					(processType === EMPTYVALUE ? null : processType),
				brand: data?.brand ?? (brand === EMPTYVALUE ? null : brand),
				vendors: data?.vendors ?? vendors,
				products: data?.products ?? products,
				productCategories: data?.productCategories ?? productCategories,
				identificationType:
					data?.identificationType ?? identificationType,
				featureType: data?.featureType ?? featureType,
				conf: data?.conf ?? {
					minConf: Math.min(sliderValue[0], sliderValue[1]),
					maxConf: Math.max(sliderValue[0], sliderValue[1]),
				},
				timeType: data?.timeType ?? timeType,
				...(data?.egaPercentage > -1 && {
					egaPercentage: data?.egaPercentage,
				}),
				pageLimit: data?.pageLimit ?? pageLimit,
				dateRange: data?.dateRange ?? dateRange,
				dateOnly: data?.dateOnly,
				status: data?.status,
			},
			FILTERS,
			isInternal
		);
		onSubmit(req);
		setIsDataFetched(true);
	};

	useEffect(() => {
		if (update !== null && update > 0) {
			handleSubmit();
		}
	}, [update]);

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
		setParentFilterState(state);
	}, [state, blink]);

	useEffect(() => {
		const objectKeys = Object.keys(defaultState);
		if (defaultState && objectKeys?.length) {
			objectKeys?.forEach((key) => {
				const defaultFilter = defaultState?.[key];
				if (key === 'STORE') {
					dispatch({
						type: 'SET_STORE',
						payload: defaultFilter,
					});
				}
				if (key === 'GROUP') {
					dispatch({
						type: 'SET_GROUP',
						payload: defaultFilter,
					});
				}
				if (key === 'DATE_RANGE') {
					dispatch({
						type: 'SET_DATE_RANGE',
						payload: defaultFilter,
					});
				}
				if (key === 'STATUS') {
					dispatch({
						type: 'SET_STATUS',
						payload: defaultFilter,
					});
				}
				if (key === 'DATE_ONLY') {
					dispatch({
						type: 'SET_DATE_ONLY',
						payload: defaultFilter,
					});
				}
				if (key === 'VENDOR') {
					dispatch({
						type: 'SET_VENDORS',
						payload: defaultFilter,
					});
				}
				if (key === 'TIME_TYPE') {
					dispatch({
						type: 'SET_TIME_TYPE',
						payload: defaultFilter,
					});
				}
			});
		}
	}, [defaultState]);

	const addFilter = (filters, context) => {
		if (context.entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: [...products, ...filters],
			});
		} else if (context.entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: [...vendors, ...filters],
			});
		} else if (context.entity === 'CATEGORY') {
			dispatch({
				type: 'SET_PRODUCT_CATEGORY',
				payload: [...productCategories, ...filters],
			});
		} else if (context.entity === 'DEFECT') {
			dispatch({
				type: 'SET_DEFECTS',
				payload: [...defects, ...filters],
			});
		}
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: products.filter((product) => {
					return product !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: vendors.filter((vendor) => {
					return vendor !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'CATEGORY') {
			dispatch({
				type: 'SET_PRODUCT_CATEGORY',
				payload: productCategories.filter((category) => {
					return category !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'DEFECT') {
			dispatch({
				type: 'SET_DEFECTS',
				payload: defects.filter((defect) => {
					return defect !== parseInt(id, 10);
				}),
			});
		}
	};

	useEffect(() => {
		if (FILTERS?.includes('brand')) {
			fetchBrands({
				id: '',
				allData: true,
			});
		}
		if (FILTERS?.includes('store') || FILTERS?.includes('raisedBy')) {
			fetchStoresList({
				entity: 'STORES',
				req: {
					params: {
						id: '',
						type: storeTypes || '',
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('group')) {
			fetchStoresList({
				entity: 'GROUPES',
				req: {
					params: {
						id: '',
						type: groupTypes || '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		if (store !== EMPTYVALUE) {
			fetchNodes();
		}
	}, [store]);

	const selectIdentificationType = (type) => {
		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: type,
		});
	};

	// eslint-disable-next-line no-unused-vars
	const selectStore = (id) => {
		dispatch({
			type: 'SET_STORE',
			payload: id,
		});
	};
	const selectGroup = (id) => {
		dispatch({
			type: 'SET_GROUP',
			payload: id,
		});
		dispatch({
			type: 'SET_PRODUCT_EXCLUSIVE',
			payload: null,
		});
	};

	const selectRegion = (id) => {
		dispatch({
			type: 'SET_REGION',
			payload: id,
		});
	};
	// const selectProduct = (id) => {
	// 	dispatch({
	// 		type: 'SET_PRODUCT',
	// 		payload: id,
	// 	});
	// };
	const selectProductExclusive = (id) => {
		dispatch({
			type: 'SET_PRODUCT_EXCLUSIVE',
			payload: id,
		});
		dispatch({
			type: 'SET_GROUP',
			payload: null,
		});
	};
	const selectStatus = (statusEle) => {
		dispatch({
			type: 'SET_STATUS',
			payload: statusEle,
		});
	};
	const selectRaisedByStore = (id) => {
		dispatch({
			type: 'SET_RAISED_BY_STORE',
			payload: id,
		});
	};
	const selectProcessType = (id) => {
		dispatch({
			type: 'SET_PROCESS_TYPE',
			payload: id,
		});
	};
	const setFromProcess = (id) => {
		dispatch({
			type: 'SET_FROM_PROCESS',
			payload: id,
		});
	};
	const setToProcess = (id) => {
		dispatch({
			type: 'SET_TO_PROCESS',
			payload: id,
		});
	};
	const setPage = (page) => {
		dispatch({
			type: 'SET_PAGE',
			payload: page,
		});
	};
	const selectType = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_FEATURE_TYPE',
			payload: fieldValue,
		});
	};
	const selectBrand = (id) => {
		dispatch({
			type: 'SET_BRAND',
			payload: id,
		});
	};

	const brandsList = useMemo(() => {
		return [
			{
				id: EMPTYVALUE,
				title: 'ALL',
			},
			...Object.keys(brands).map((id) => {
				const currBrand = brands[id];
				return currBrand;
			}),
		];
	}, [brands]);

	useEffect(() => {
		dispatch({
			type: 'SET_BRAND',
			payload: brandsList[0]?.id,
		});
	}, [brandsList]);

	useEffect(() => {
		if (timeTypeArray) {
			dispatch({
				type: 'SET_TIME_TYPE',
				payload: timeTypeArray?.[0]?.value,
			});
		}
	}, [timeTypeArray]);

	useEffect(() => {
		if ((brand != null && FILTERS?.includes('product')) || (FILTERS?.includes('productExclusive'))) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
						brandId: (brand === EMPTYVALUE || brand === null) ? '' : brand,
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('features')) {
			fetchDefects({
				entity: 'DEFECTS',
				req: {
					params: {
						brandId: brand === EMPTYVALUE ? '' : brand,
					},
				},
				method: 'LIST',
			});
		}
	}, [brand, FILTERS?.includes('vendor')]);

	const VENDORS = useMemo(() => {
		return ((FILTERS?.includes('vendor') ? vendorsList : buyersList) ?? [])?.map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
			};
		});
	}, [vendorsList, buyersList, FILTERS?.includes('vendor')]);

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
				type: tStore?.type,
			};
		});
		if (allStoreOption) {
			tempStore?.unshift({
				id: EMPTYVALUE,
				title: 'ALL',
				type: 'ALL',
			});
		}
		return tempStore;
	}, [stores]);

	const FILTEREDSTORES = useMemo(() => {
		const validStores = {};
		permittedStores.forEach((validStoreLoop) => {
			validStores[validStoreLoop.id] = true;
		});
		const tempStore = stores?.filter((storeFilter) => {
			return permittedStores.length === 0 || validStores[storeFilter.id];
		})?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
				type: tStore?.type,
			};
		});
		if (allStoreOption) {
			tempStore?.unshift({
				id: EMPTYVALUE,
				title: 'ALL',
				type: 'ALL',
			});
		}
		return tempStore;
	}, [stores, permittedStores]);

	const GROUPS = useMemo(() => {
		const tempGroup = groups?.map((tGroup) => {
			return {
				id: tGroup.groupId,
				title: tGroup?.groupName ?? '',
			};
		});

		return tempGroup;
	}, [groups]);

	const REGIONS = useMemo(() => {
		const tempRegion = regions?.map((tregion) => {
			return {
				id: tregion.regionId,
				title: tregion?.regionName ?? '',
			};
		});

		return tempRegion;
	}, [regions]);

	useEffect(() => {
		if (FILTERS?.includes('productCategory')) {
			fetchProductCategoriesList({
				entity: 'PRODUCT_CATEGORY',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('imagesType')) {
			dispatch({
				type: 'SET_IMAGES_TYPE',
				payload: POSSIBLE_IMAGE_TYPES?.[0]?.id,
			});
		}
	}, []);

	useEffect(() => {
		if (FILTERS?.includes('productCategory')) {
			fetchProductCategoriesList({
				entity: 'PRODUCT_CATEGORY',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('vendor') || FILTERS?.includes('buyer')) {
			const type = FILTERS?.includes('buyer') ? 'BUYER' : 'VENDOR';
			fetchAllVendors({
				entity: type === 'BUYER' ? 'ALL_BUYERS' : 'ALL_VENDORS',
				req: {
					params: {
						id: '',
					},
				},
				type,
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('imagesType')) {
			dispatch({
				type: 'SET_IMAGES_TYPE',
				payload: POSSIBLE_IMAGE_TYPES?.[0]?.id,
			});
		}
	}, []);

	const PRODUCTS = useMemo(() => {
		let filteredProduct = productsList;
		if (FILTERS?.includes('buyer')) {
			filteredProduct = productsList?.filter((product) => product?.skuType === 'PRODUCT');
		}
		return filteredProduct?.map((product) => {
			return {
				id: product?.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList, FILTERS?.includes('buyer')]);

	useEffect(() => {
		if (!query.has(params.STORE)) {
			if (setAutoStore) {
				dispatch({
					type: 'SET_STORE',
					payload: STORES?.[0]?.id,
				});
			}
		}
	}, [stores, STORES, query.has(params.STORE), setAutoStore]);

	const PRODUCT_CATEGORIES = useMemo(() => {
		return productCategoriesList
			?.filter((category) => {
				if (!brand || brand === EMPTYVALUE) return category;
				return category?.brandId === brand;
			})
			?.map((category) => {
				return {
					id: category?.id,
					title: category?.productCategoryName ?? '',
				};
			});
	}, [productCategoriesList, brandsList]);

	const DEFECTS = useMemo(() => {
		return defectsList?.map((defect) => {
			return {
				id: defect?.id,
				title: defect?.label ?? '',
			};
		});
	}, [defectsList]);

	useEffect(() => {
		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: 'Both',
		});
	}, []);

	const
		dateRangeFilterArray = useMemo(() => {
			return [
				{
					startDate: dateRange.from,
					endDate: dateRange.to,
					key: 'selection',
				},
			];
		}, [dateRange]);

	const setDateRange = ({ active, from, to }) => {
		dispatch({
			type: 'SET_DATE_RANGE',
			payload: {
				active,
				from,
				to,
			},
		});
	};

	const setDateOnly = ({ active, date }) => {
		dispatch({
			type: 'SET_DATE_ONLY',
			payload: {
				active,
				date,
			},
		});
	};

	useEffect(() => {
		if (preventDateRangeCheckbox) {
			setDateRange({
				...dateRange,
				active: true,
			});
		}
	}, [preventDateRangeCheckbox]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
	};

	const selectImagesType = (e) => {
		const { fieldValue } = inputHelper(e);

		dispatch({
			type: 'SET_IMAGES_TYPE',
			payload: fieldValue,
		});
	};

	const selectTimeType = (e) => {
		const { fieldValue } = inputHelper(e);

		dispatch({
			type: 'SET_TIME_TYPE',
			payload: fieldValue,
		});
	};

	const PROCESS_TYPES = useMemo(() => {
		const types = (processTypes ?? [])
			?.map((ele) => {
				return {
					id: ele?.id,
					title: ele?.data?.heading,
				};
			});
		return types;
	}, [processTypes]);

	useEffect(() => {
		if (store === EMPTYVALUE) {
			dispatch({
				type: 'SET_PROCESS_TYPE',
				payload: null,
			});
			dispatch({
				type: 'SET_FROM_PROCESS',
				payload: null,
			});
			dispatch({
				type: 'SET_TO_PROCESS',
				payload: null,
			});
		}
	}, [PROCESS_TYPES, store]);

	useEffect(() => {
		// if (stores.length > 0) {
		let sub = false;
		const queryBrand = query.get(params.BRAND);
		const queryStore = query.get(params.STORE) && [
			Number.parseInt(query.get(params.STORE), 10),
		];
		const queryRegion = query.get(params.REGION);
		const queryGroup = query.get(params.GROUP);
		const queryEgaPercentage = query.get(params.egaPercentage);
		let egaNumber;
		if (queryEgaPercentage?.length > 0) {
			egaNumber = Number(queryEgaPercentage);
			setEgaActive(true);
			// eslint-disable-next-line no-restricted-globals
			if (isNaN(egaNumber)) {
				egaNumber = 0;
			}
			setEgaPercentage(egaNumber);
		}
		const from = query.get(params.from);
		const to = query.get(params.to);
		const active = query.get(params.DATE_RANGE_CHECKED);
		const confInput = Number(query.get(params.conf));
		let iType = query.get(params.iType);
		iType = iType?.replaceAll("'", '');
		const queryVendor = query.get(params.VENDOR) && [
			Number.parseInt(query.get(params.VENDOR), 10),
		];
		const queryProduct = query.get(params.PRODUCT) && [
			Number.parseInt(query.get(params.PRODUCT), 10),
		];
		const queryStatus = query.get(params.STATUS) && [
			query.get(params.STATUS),
		];
		const queryCategory = query.get(params.CATEGORY) && [
			Number.parseInt(query.get(params.CATEGORY), 10),
		];
		// const dateRangeChecked = query.get(params.DATE_RANGE_CHECKED);
		if (brandsList.length > 0) {
			if (query.has(params.BRAND)) {
				sub = true;
				dispatch({
					type: 'SET_BRAND',
					payload: queryBrand,
				});
			} else {
				dispatch({
					type: 'SET_BRAND',
					payload: null,
				});
			}
			if (query.has(params.VENDOR)) {
				sub = true;
				dispatch({
					type: 'SET_VENDORS',
					payload: queryVendor,
				});
			}
			if (query.has(params.STORE)) {
				sub = true;
				dispatch({
					type: 'SET_STORE',
					payload: queryStore?.[0],
				});
			}
			if (query.has(params.GROUP)) {
				sub = true;
				dispatch({
					type: 'SET_GROUP',
					payload: queryGroup?.[0],
				});
			}
			if (query.has(params.REGION)) {
				sub = true;
				dispatch({
					type: 'SET_REGION',
					payload: queryRegion?.[0],
				});
			}
			if (query.has(params.PRODUCT)) {
				sub = true;
				dispatch({
					type: 'SET_PRODUCTS',
					payload: queryProduct,
				});
			}
			if (query.has(params.STATUS)) {
				sub = true;
				dispatch({
					type: 'SET_STATUS',
					payload: queryStatus,
				});
			}
			if (query.has(params.CATEGORY)) {
				if (
					productCategoriesList.find((ele) => {
						return ele.id === queryCategory[0];
					})
				) {
					sub = true;
					dispatch({
						type: 'SET_PRODUCT_CATEGORY',
						payload: queryCategory,
					});
				}
			}
			if (egaNumber > -1) {
				sub = true;
			}
			if (iType) {
				sub = true;
				dispatch({
					type: 'SET_IDENTIFICATION_TYPE',
					payload: iType,
				});
			} else {
				dispatch({
					type: 'SET_IDENTIFICATION_TYPE',
					payload: 'Both',
				});
			}
			if (confInput) {
				sub = true;
				setSliderValue([confInput, 1]);
			}
			if (sub) {
				dispatch({
					type: 'SET_TIME_TYPE',
					payload: timeTypeArray?.[0]?.value ?? 'qc',
				});
				dispatch({
					type: 'SET_DATE_RANGE',
					payload: {
						// ...dateRange,
						from: new Date(from),
						active: active !== 'false',
						to: new Date(to),
					},
				});
				handleSubmitParams({
					store: queryStore?.[0] ?? null,
					group: queryGroup?.[0] ?? null,
					region: queryRegion?.[0] ?? null,
					brand: queryBrand ?? null,
					vendors: queryVendor ?? vendors,
					products: queryProduct ?? products,
					productCategories: queryCategory ?? productCategories,
					...(egaNumber > -1 && {
						egaPercentage: egaNumber,
					}),
					// identification: 'Both',
					identificationType: iType || 'Both',
					conf: {
						minConf: confInput ?? 0.1,
						maxConf: 1,
					},
					timeType: timeTypeArray?.[0]?.value ?? 'qc',
					pageLimit,
					dateRange: {
						from: from ? new Date(from) : new Date(),
						active: true,
						to: to ? new Date(to) : new Date(),
					},
					status: queryStatus,
				});
			}
		}
		// }
	}, [
		brandsList?.[0]?.id,
		query.get(params.BRAND),
		query.get(params.PRODUCT),
		query.get(params.STORE),
		query.get(params.VENDOR),
		query.get(params.STATUS),
		query.get(params.iType),
		query.get(params.egaPercentage),
		query.get(params.confInput),
		timeTypeArray,
	]);

	return (
		<form className={styles.root} onSubmit={handleSubmit}>
			<ListModal modalCallee='imagesFinder' handleConfirm={addFilter} />
			{(pageLabel || additionInfo?.showAdd) && (
				<div className={styles.header}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{pageLabel && (
							<p className={styles['page-label']}>{pageLabel}</p>
						)}
						{navStoreFilter?.id && (
							<div className={styles['selected-item']}>
								<Tooltip title={navStoreFilter?.title}>
									<p>
										{navStoreFilter?.title?.length > 18 // eslint-disable-next-line prefer-template
											? navStoreFilter?.title?.substring(
												0,
												18
											) + '...'
											: navStoreFilter?.title}
									</p>
								</Tooltip>
							</div>
						)}
						{externalFilters?.map((filterEle) => {
							return (
								<div className={styles['selected-item']}>
									<Tooltip title={filterEle?.title}>
										<p>
											{filterEle?.title?.length > 15
												// eslint-disable-next-line prefer-template
												? filterEle?.title?.substring(
													0,
													15
												) + '...'
												: filterEle?.title}
										</p>
									</Tooltip>
									{filterEle?.removable && (
										<IconButton
											className={styles.remove}
											onClick={() => {
												filterEle?.handleRemove(
													filterEle
												);
												filterEle?.handleRemove(
													filterEle
												);
											}}
										>
											<Close />
										</IconButton>
									)}
								</div>
							);
						})}
					</div>
					{headerComponent()}
					{additionInfo?.showAdd && (
						<button
							onClick={additionInfo?.handleAddClick}
							type='button'
							className={styles['add-btn']}
						>
							<Add />
							{`${additionInfo?.prefix ?? 'Add'} ${
								additionInfo?.addText
							}`}
						</button>
					)}
				</div>
			)}
			<div className={styles.form}>
				<div className={styles.filters}>
					{conditionalFilters?.length ? (
						<div className={styles.conditional_Filters}>
							{conditionalFilters?.includes('group') &&
								FILTERS?.includes('group') && (
								<ExclusiveFilter
									list={GROUPS}
									hideDone={hideFinder}
									selectedList={group}
									handleClick={selectGroup}
									label='Select Group'
									allowNoSelection
								/>
							)}
							<div> OR </div>
							{conditionalFilters?.includes('productExclusive') &&
								FILTERS?.includes('productExclusive') && (
								<ExclusiveFilter
									list={PRODUCTS}
									hideDone={hideFinder}
									selectedList={productExclusive}
									handleClick={selectProductExclusive}
									label='Search Group by Product'
									allowNoSelection
								/>
							)}
						</div>
					) : (
						<>
							<div className={styles['filters-exclusive']}>
								<div className={styles.left}>
									{FILTERS?.includes('store') && (
										// <ExclusiveFilter
										// 	list={STORES}
										// 	hideDone={hideFinder}
										// 	increasedWidth={
										// 		(FILTERS?.includes('store') ||
										// 			FILTERS?.includes(
										// 				'dateRangeOnly'
										// 			)) &&
										// 		FILTERS?.length <= 2
										// 	}
										// 	selectedList={store}
										// 	handleClick={selectStore}
										// 	label='Select Store'
										// />
										<SelectPicker
											data={STORES?.map((ele) => {
												return {
													...ele,
													label: ele?.title,
													role: ele?.type,
													value: ele?.id,
												};
											})}
											style={{
												width: 224,
											}}
											groupBy='type'
											placeholder={
												filterLabels?.store ??
												'Select Store'
											}
											onChange={selectStore}
											renderMenuItem={(_) => {
												return (
													<div>
														<i className='rs-icon rs-icon-user' />
														{' '}
														{_}
													</div>
												);
											}}
											renderMenuGroup={(_) => {
												return (
													<div>
														<i className='rs-icon rs-icon-group' />
														{_}
													</div>
												);
											}}
											cleanable={false}
											value={store}
											renderValue={(_, item) => {
												return (
													<div
														className={`${styles.selected_store} rsuite-select-picker-render-value`}
													>
														<p>
															{filterLabels?.store ??
																'Store:'}
															{' '}
															{item?.title}
														</p>
													</div>
												);
											}}
											// onClean={() => {
											// console.log('heeloooo there');
											// }}
										/>
									)}
									{FILTERS?.includes('filteredStore') && (
										<SelectPicker
											data={FILTEREDSTORES?.map((ele) => {
												return {
													...ele,
													label: ele?.title,
													role: ele?.type,
													value: ele?.id,
												};
											})}
											style={{
												width: 224,
											}}
											groupBy='type'
											placeholder={
												filterLabels?.store ??
												'Select Store'
											}
											onChange={selectStore}
											renderMenuItem={(_) => {
												return (
													<div>
														<i className='rs-icon rs-icon-user' />
														{' '}
														{_}
													</div>
												);
											}}
											renderMenuGroup={(_) => {
												return (
													<div>
														<i className='rs-icon rs-icon-group' />
														{_}
													</div>
												);
											}}
											value={store}
											cleanable={false}
											renderValue={(_, item) => {
												return (
													<div
														className={`${styles.selected_store} rsuite-select-picker-render-value`}
													>
														<p>
															{filterLabels?.store ??
																'Store:'}
															{' '}
															{item?.title}
														</p>
													</div>
												);
											}}
											// onClean={() => {
											// console.log('heeloooo there');
											// }}
										/>
									)}
									{FILTERS?.includes('processTypes') && (
										<ExclusiveFilter
											list={PROCESS_TYPES}
											selectedList={processType}
											handleClick={selectProcessType}
											label='Select Process Type'
											disabled={store === EMPTYVALUE}
											allowNoSelection
										/>
									)}
									{FILTERS?.includes('fromProcess') && (
										<ExclusiveFilter
											list={PROCESS_TYPES}
											selectedList={fromProcess}
											handleClick={setFromProcess}
											label='Select From Process'
											disabled={store === EMPTYVALUE}
											allowNoSelection
										/>
									)}
									{FILTERS?.includes('toProcess') && (
										<ExclusiveFilter
											list={PROCESS_TYPES}
											selectedList={toProcess}
											handleClick={setToProcess}
											label='Select To Process'
											disabled={store === EMPTYVALUE}
											allowNoSelection
										/>
									)}
									{FILTERS?.includes('regions') && (
										<ExclusiveFilter
											list={REGIONS}
											selectedList={region}
											handleClick={selectRegion}
											label='Select Region'
											allowNoSelection
										/>
									)}
									{FILTERS?.includes('productSelection') && (
										<ExclusiveFilter
											list={products}
											selectedList={processType}
											handleClick={selectProcessType}
											label='Select Process Type'
										/>
									)}
									{FILTERS?.includes('raisedBy') && (
										<ExclusiveFilter
											list={STORES}
											selectedList={raisedBy}
											handleClick={selectRaisedByStore}
											label='Raised by'
										/>
									)}
									{FILTERS?.includes('status') && (
										<ExclusiveFilter
											list={
												statusList ?? defaultStatusList
											}
											selectedList={status}
											handleClick={selectStatus}
											label='Select Status'
										/>
									)}
									{FILTERS?.includes('customExclusiveFilters') && customExclusiveFilters?.map((ele) => {
										return (
											<ExclusiveFilter
												list={
													ele?.filterList
												}
												selectedList={state?.customExclusiveFilters?.[ele?.key]}
												handleClick={(filterValue) => {
													dispatch({
														type: 'SET_CUSTOM_EXCLUSIVE_FILTER',
														payload: {
															[ele?.key]: filterValue,
														},
													});
												}}
												emptyText={ele?.emptyText}
												disabled={ele?.disabled}
												label={ele?.label}
												allowNoSelection={ele?.allowNoSelection}
												hideDone={ele?.hideDone}
											/>
										);
									})}
									{FILTERS?.includes('brand') && (
										<ExclusiveFilter
											list={brandsList?.map((ele) => {
												return {
													...ele,
													title:
														ele?.name ?? ele?.title,
												};
											})}
											selectedList={brand}
											handleClick={selectBrand}
											label='Select Brand'
											disabled={products?.length > 0}
										/>
									)}
								</div>
								<div className={styles.right}>
									{FILTERS?.includes('productCategory') && (
										<div
											className={classes(
												styles.filter,
												styles.productCategory
											)}
										>
											<p
												className={
													styles['filter-heading']
												}
											>
												Product category
											</p>
											<div
												className={
													styles['filter-body']
												}
											>
												<Button
													variant='outlined'
													startIcon={<Add />}
													className={
														styles['add-button']
													}
													// disabled={brand == null}
													fullWidth
													onClick={() => {
														toggleModal(true, {
															callee: 'imagesFinder',
															entity: 'CATEGORY',
															heading:
																'Select Category(s)',
															list: PRODUCT_CATEGORIES,
															selected:
																productCategories,
														});
													}}
												>
													Category
												</Button>
												<div className={styles.tags}>
													{productCategories.map(
														(id) => {
															const selectedCategory =
																PRODUCT_CATEGORIES?.find(
																	(v) => {
																		return (
																			id ===
																			v?.id
																		);
																	}
																);
															return (
																<Tag
																	key={
																		selectedCategory?.id
																	}
																	width='100%'
																	text={
																		selectedCategory?.title
																	}
																	handleDelete={
																		deleteFilter
																	}
																	dataAttrs={{
																		'data-id':
																			selectedCategory?.id,
																		'data-entity':
																			'CATEGORY',
																	}}
																/>
															);
														}
													)}
													{productCategories?.length ===
														0 && (
														<span>
															All Categories
															selected
														</span>
													)}
												</div>
											</div>
										</div>
									)}
									{FILTERS?.includes('product') && (
										<div
											className={classes(
												styles.filter,
												styles.product
											)}
										>
											<p
												className={
													styles['filter-heading']
												}
											>
												Select Product(s)
											</p>
											<div
												className={
													styles['filter-body']
												}
											>
												<Button
													variant='outlined'
													startIcon={<Add />}
													className={
														styles['add-button']
													}
													// disabled={brand == null}
													fullWidth
													onClick={() => {
														toggleModal(true, {
															entity: 'PRODUCT',
															callee: 'imagesFinder',
															heading:
																'Select Product(s)',
															list: PRODUCTS,
															selected: products,
														});
													}}
												>
													Product
												</Button>
												<div className={styles.tags}>
													{products.map((id) => {
														const product =
															PRODUCTS.find(
																(d) => {
																	return (
																		id ===
																		d.id
																	);
																}
															);
														return (
															<Tag
																key={id}
																width='100%'
																text={
																	product?.title
																}
																handleDelete={
																	deleteFilter
																}
																dataAttrs={{
																	'data-id':
																		id,
																	'data-entity':
																		'PRODUCT',
																}}
															/>
														);
													})}
													{products?.length === 0 && (
														<span>
															All products
															selected
														</span>
													)}
												</div>
											</div>
										</div>
									)}
									{(FILTERS?.includes('vendor') || FILTERS?.includes('buyer')) && (
										<div
											className={classes(
												styles.filter,
												styles.vendor
											)}
										>
											<p
												className={
													styles['filter-heading']
												}
											>
												{FILTERS?.includes('vendor') ? 'Select Vendor(s)' : 'Select Buyer(s)'}
											</p>
											<div
												className={
													styles['filter-body']
												}
											>
												<Button
													variant='outlined'
													startIcon={<Add />}
													className={
														styles['add-button']
													}
													// disabled={brand == null}
													fullWidth
													onClick={() => {
														toggleModal(true, {
															entity: 'VENDOR',
															callee: 'imagesFinder',
															heading:
															FILTERS?.includes('vendor') ? 'Select Vendor(s)' : 'Select Buyer(s)',
															list: VENDORS,
															selected: vendors,
														});
													}}
												>
													{FILTERS?.includes('vendor') ? 'VENDOR' : 'BUYER'}
												</Button>
												<div className={styles.tags}>
													{vendors.map((id) => {
														const selectedVendor =
															VENDORS?.find(
																(v) => {
																	return (
																		id ===
																		v?.id
																	);
																}
															);
														return (
															<Tag
																key={
																	selectedVendor?.id
																}
																width='100%'
																text={
																	selectedVendor?.title
																}
																handleDelete={
																	deleteFilter
																}
																dataAttrs={{
																	'data-id':
																		selectedVendor?.id,
																	'data-entity':
																		'VENDOR',
																}}
															/>
														);
													})}
													{vendors?.length === 0 && (
														<span>
															{FILTERS?.includes('vendor') ? 'All Vendors selected' : 'All Buyers selected'}
														</span>
													)}
												</div>
											</div>
										</div>
									)}
									{FILTERS?.includes('features') && (
										<div
											className={classes(
												styles.filter,
												styles.defect
											)}
										>
											<p
												className={
													styles['filter-heading']
												}
											>
												Select Feature(s)
											</p>
											<div
												className={
													styles['filter-body']
												}
											>
												<Button
													variant='outlined'
													startIcon={<Add />}
													className={
														styles['add-button']
													}
													// disabled={brand == null}
													fullWidth
													onClick={() => {
														toggleModal(true, {
															entity: 'DEFECT',
															callee: 'imagesFinder',
															heading:
																'Select Defect(s)',
															list: DEFECTS,
															selected: defects,
														});
													}}
												>
													FEATURE
												</Button>
												<div className={styles.tags}>
													{defects.map((id) => {
														const defect =
															DEFECTS.find(
																(d) => {
																	return (
																		id ===
																		d.id
																	);
																}
															);
														return (
															<Tag
																key={id}
																text={
																	defect?.title
																}
																handleDelete={
																	deleteFilter
																}
																dataAttrs={{
																	'data-id':
																		id,
																	'data-entity':
																		'DEFECT',
																}}
															/>
														);
													})}
													{defects?.length === 0 && (
														<span>
															All Features
															selected
														</span>
													)}
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className={styles['filters-generic']}>
								<div className={styles['inner-generic']}>
									{FILTERS?.includes('featureType') && (
										<div
											className={classes(
												styles.filter,
												styles.product
											)}
										>
											<p>Feature Type:</p>
											<Select
												value={featureType ?? ''}
												className={
													styles[
														'feature-type-select'
													]
												}
												onChange={selectType}
											>
												{typesList?.map((type) => {
													return (
														<MenuItem
															key={type.name}
															value={type.value}
														>
															{type.name}
														</MenuItem>
													);
												})}
											</Select>
										</div>
									)}
									{FILTERS?.includes('pageLimit') && (
										<div
											className={classes(
												styles.filter,
												styles.product
											)}
										>
											<p>Limit:</p>
											<Select
												value={pageLimit ?? ''}
												className={
													styles['limit-select']
												}
												onChange={(e) => {
													setPage(e.target.value);
												}}
											>
												{[6, 25, 50, 100].map(
													(page) => {
														return (
															<MenuItem
																key={page}
																value={page}
															>
																{page}
															</MenuItem>
														);
													}
												)}
											</Select>
										</div>
									)}
									{FILTERS?.includes('imagesType') && (
										<div
											className={classes(
												styles.filter,
												styles.imagesType
											)}
										>
											<p>Image type:</p>
											<Select
												value={imagesType ?? ''}
												className={styles.dropdown}
												onChange={selectImagesType}
											>
												{POSSIBLE_IMAGE_TYPES.map(
													(item, idx) => {
														return (
															<MenuItem
																// eslint-disable-next-line react/no-array-index-key
																key={idx}
																value={item.id}
															>
																{item.label}
															</MenuItem>
														);
													}
												)}
											</Select>
										</div>
									)}
									{FILTERS?.includes(
										'identificationType'
									) && (
										<div
											className={classes(
												styles.filter,
												styles.identification
											)}
										>
											<p>Identification Type:</p>
											<div className={styles.types}>
												{identificationTypes?.map(
													(ele) => {
														const isSelected =
															ele?.type ===
															identificationType;
														return (
															<div
																key={ele?.id}
																onClick={() => {
																	selectIdentificationType(
																		ele?.type
																	);
																}}
																className={classes(
																	styles[
																		'types-type'
																	],
																	isSelected
																		? styles.selected
																		: ''
																)}
															>
																<p>
																	{ele?.type}
																</p>
															</div>
														);
													}
												)}
											</div>
										</div>
									)}
									{FILTERS?.includes('confidence') &&
										isInternal && (
										<div
											className={classes(
												styles.filter,
												styles.confidence
											)}
										>
											<p>
												{' '}
												{filterLabels?.confidence ?? 'confidence Range'}
											</p>
											<p>
												{`[ ${Math.min(
													sliderValue[0],
													sliderValue[1]
												)} - ${Math.max(
													sliderValue[0],
													sliderValue[1]
												)} ]`}
											</p>
											<RangeSlider
												className={
													styles['defect-select']
												}
												progress
												style={{
													marginTop: 16,
												}}
												value={sliderValue}
												min={minConf}
												max={maxConf}
												step={step}
												onChange={(value) => {
													const start =
															(Number(value?.[0]))?.toFixed(2);
													const end =
															(Number(value?.[1]))?.toFixed(2);
													setSliderValue([
														start,
														end,
													]);
												}}
											/>
										</div>
									)}
									{FILTERS?.includes('egaPercentage') &&
										isInternal && (
										<div
											className={classes(
												styles.filter,
												styles.egaFilter,
												egaActive
													? ''
													: styles.disabled
											)}
										>
											<p>
												<div
													className={
														styles?.checkbox
													}
												>
													<Checkbox
														checked={egaActive}
														onChange={() => {
															setEgaActive(
																(prev) => !prev
															);
														}}
														inputProps={{
															'aria-label':
																	'controlled',
														}}
													/>
												</div>
												EGA
												{' '}
												{'>'}
											</p>
											<p>
												{egaPercentage}
												%
											</p>
											<Slider
												className={
													styles['defect-select']
												}
												progress
												style={{
													marginTop: 16,
												}}
												value={egaPercentage}
												min={0}
												max={100}
												disabled={!egaActive}
												step={0.1}
												onChange={(value) => {
													setEgaPercentage(value);
												}}
											/>
										</div>
									)}
								</div>
								{FILTERS?.includes('dateRange') && (
									<div
										className={classes(
											styles['range-container'],
											dateRange.active
												? styles.applied
												: ''
										)}
									>
										<Checkbox
											checked={dateRange?.active}
											onChange={() => {
												return setDateRange({
													...dateRange,
													active: !dateRange.active,
												});
											}}
											inputProps={{
												'aria-label': 'controlled',
											}}
										/>
										<Select
											value={timeType ?? ''}
											className={styles['time-select']}
											onChange={selectTimeType}
											disabled={!dateRange.active}
										>
											{(
												timeTypeArray ??
												defaultTimeTypeArr
											).map((type, idx) => {
												return (
													<MenuItem
														// eslint-disable-next-line react/no-array-index-key
														key={idx}
														value={type.value}
													>
														{type.label}
													</MenuItem>
												);
											})}
										</Select>
										<UYDateRange
											disabled={!dateRange.active}
											dateRanges={dateRangeFilterArray}
											onDateRangeChange={setDate}
											enableOnlyBackwardSelection={false}
										/>
									</div>
								)}
								{FILTERS?.includes('dateOnly') && (
									<div
										className={classes(
											styles['range-container'],
											dateOnly.active
												? styles.applied
												: ''
										)}
									>
										<Checkbox
											checked={dateOnly?.active}
											onChange={() => {
												return setDateOnly({
													...dateOnly,
													active: !dateOnly?.active,
												});
											}}
											inputProps={{
												'aria-label': 'controlled',
											}}
										/>
										<div>
											{dateOnlyText}
										</div>
										<DatePickerCustom
											selectedDate={dateOnly?.date}
											selectDate={(rsuiteDate) => {
												setDateOnly({
													active: true,
													date: rsuiteDate,
												});
											}}
											oneTap
											defaultValue={dateOnly?.date}
											cleanable={false}
										/>
									</div>
								)}
								{FILTERS?.includes('dateRangeWithType') && (
									<div
										className={classes(
											styles['range-container'],
											styles.applied
										)}
									>
										<Select
											value={timeType ?? ''}
											className={styles['time-select']}
											onChange={selectTimeType}
										>
											{(
												timeTypeArray ??
												defaultTimeTypeArr
											).map((type, idx) => {
												return (
													<MenuItem
														// eslint-disable-next-line react/no-array-index-key
														key={idx}
														value={type.value}
													>
														{type.label}
													</MenuItem>
												);
											})}
										</Select>
										<UYDateRange
											dateRanges={dateRangeFilterArray}
											onDateRangeChange={setDate}
											enableOnlyBackwardSelection={false}
										/>
									</div>
								)}
								{FILTERS?.includes('dateRangeOnly') && (
									<div
										className={classes(
											styles['range-container'],
											FILTERS?.includes('dateRangeOnly')
												? styles.applied
												: ''
										)}
									>
										{dateRangeOnlyText && (
											<div style={{
												fontWeight: 600,
											}}
											>
												{dateRangeOnlyText}
											</div>
										)}
										<UYDateRange
											disabled={
												!FILTERS?.includes(
													'dateRangeOnly'
												)
											}
											enableOnlyBackwardSelection={false}
											dateRanges={dateRangeFilterArray}
											onDateRangeChange={setDate}
										/>
									</div>
								)}
							</div>
						</>
					)}
				</div>
				{!hideFinder && (
					<div className={classes(styles.submit)}>
						<span>Find</span>
						<IconButton
							type='submit'
							className={classes(
								styles.btn,
								shouldAnimate ? styles?.animate : '',
								isDataFetched ? styles?.disabled : ''
							)}
						>
							<ArrowCircleRight />
						</IconButton>
					</div>
				)}
			</div>
			{footerComponent && (
				<div className={styles.footer_elements}>
					{footerComponent()}
				</div>
			)}
		</form>
	);
};

ImagesFinder.defaultProps = {
	brands: [],
	products: [
		{
			label: 'Product D',
		},
	],
	onSubmit: () => {},
};

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { brands } = state.brand;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST: defectsList } = state.form.DEFECTS.data;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { LIST: buyersList } = state.form.ALL_BUYERS.data;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { permittedStores } = state?.navigation;

	return {
		stores,
		brands,
		productsList,
		dateRangeFilter,
		defectsList,
		buyersList,
		vendorsList,
		productCategoriesList,
		processTypes,
		permittedStores,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchBrands: brandActions.fetchBrands,
	fetchProducts: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchProductCategoriesList: formActions.gateway,
	fetchAllVendors: formActions.gateway,
	fetchDefects: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesFinder);
