import reportsConstants from '../Constants/reports.constants';
import { notificationServices } from '../Services';
import reportsServices from '../Services/reportsServices';

const reportsActions = {
	fetchReportsList,
	searchReport,
};

function fetchReportsList() {
	return async (dispatch, getState) => {
		const { query } = getState().reports.search;
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'loading...',
		});
		dispatch(request());
		const req = {
			params: {
				email: query,
			},
		};
		try {
			const response = await reportsServices?.fetchReportsList(req);
			if (response?.statusCode === 200) {
				dispatch(success(response?.data));
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Fetched Successfully',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			dispatch(failure());
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
				config: {
					id: notificationId,
				},
			});
		}
	};
}

function request() {
	return {
		type: reportsConstants.REPORTS_LIST_REQUEST,
	};
}
function success(payload) {
	return {
		type: reportsConstants.REPORTS_LIST_SUCCESS,
		payload,
	};
}
function failure() {
	return {
		type: reportsConstants.REPORTS_LIST_FAILURE,
	};
}

function searchReport(value) {
	return (dispatch) => {
		dispatch({
			type: reportsConstants.REPORTS_LIST_SEARCH,
			payload: value,
		});
	};
}

export default reportsActions;
