/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import { useRef } from 'react';
import { Pie, Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { classes } from '../../../Helpers';
import { DASHBOARD_CHARTS_COLORS } from '../../../Styles/colors';
import DownloadButton from '../DownloadButton/DownloadButton';
import Heading from '../Heading/Heading';
import styles from './PieChart.module.scss';

function PieChart({ data, className = '' }) {
	const { heading: title, labels, tooltip, limit = undefined } = data;
	// const [legends, setLegends] = useState([]);
	const pieChart = useRef(null);
	const dataSets = data?.datasets?.[0]?.data?.slice(0, limit);
	const url = data?.datasets?.[0]?.url;
	const idList = data?.datasets?.[0]?.ids?.slice(0, limit);
	const history = useHistory();
	const dataArr = data?.datasets?.[0].data;
	let sum = 0;
	dataArr?.forEach((eleData) => {
		sum += Number(eleData);
	});
	return (
		<div className={classes(styles.root, className)}>
			<div className={styles.title}>
				<Heading tooltip={tooltip} heading={title} />
				<DownloadButton data={data} />
			</div>
			{/* <div className={labels?.length < 12 ? styles.chart : styles.chartScroll}> */}
			<div className={styles.chart}>
				<Pie
					ref={pieChart}
					// className={labels?.length < 12 ? styles.pieChart : styles.pieChartScroll}
					className={styles.pieChart}
					plugins={[ChartDataLabels]}
					options={{
						onClick(evt, currentArc) {
							if (url) {
								history.push(
									`${url}${idList?.[currentArc?.[0].index]}`
								);
							}
						},
						maintainAspectRatio: false, // To allow customization of width and height
						responsive: true,
						// aspectRatio: 1,
						plugins: {
							legend: {
								position: 'right',
								labels: {
									// boxWidth: 15,
									usePointStyle: true,
								},
							},
							datalabels: {
								align: 'end',
								offset: -5,
								formatter: (value, ctx) => {
									const percentage = `${(
										(value * 100) /
										sum
									).toFixed(2)}%`;
									return percentage;
								},
							},
						},
						// color: '#fff',
					}}
					data={{
						labels: labels.map((label, index) => {
							const percentage = `${(
								(dataSets[index] * 100) /
								sum
							).toFixed(2)}%`;
							return `${label} ${percentage}`;
						}),
						// labels,
						datasets: [
							{
								data: dataSets,
								backgroundColor:
									DASHBOARD_CHARTS_COLORS.pieChart,
								// cutout: '80%',
							},
						],
					}}
				/>
			</div>
		</div>
	);
}

export default PieChart;
