import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TagPicker from 'rsuite/TagPicker';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import styles from './ModulesConfriguration.module.scss';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import userRolesAndPermissions from '../../../Services/userRolesAndPermissions.services';
import { navMenus } from '../../../Constants';
import { CreatePageHeader } from '../../../Components/Headers';
import modulesConfiguration from '../../../Services/modulesConfiguration.services';
import { BackdropLoader } from '../../../Components/Loaders';

function ModulesConfiguration() {
	const [isloading, setIsloading] = useState(false);
	const [rolesMapping, setRolesMapping] = useState({});
	const [userRoles, setUserRoles] = useState([]);

	useEffect(async () => {
		setIsloading(true);
		const userRolesRes = await userRolesAndPermissions.getUserRoles();
		setUserRoles(userRolesRes);
		const userRolesMappings =
			await modulesConfiguration.getModulesConfiguration();
		setRolesMapping(userRolesMappings.data);
		setIsloading(false);
	}, []);

	const handleSave = async () => {
		const req = {
			data: rolesMapping,
		};
		setIsloading(true);
		const updateData =
			await modulesConfiguration.updateModulesConfiguration(req);
		if (updateData.statusCode === 200) {
			notificationServices.generateNotification({
				type: 'success',
				message: updateData.message,
			});
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: updateData.message,
			});
		}
		setIsloading(false);
	};

	const [dropDown, setDropDown] = useState({});

	const handleClick = (id) => {
		const localDropDown = dropDown;
		if (!localDropDown[id]) {
			localDropDown[id] = true;
		} else localDropDown[id] = false;
		setDropDown({
			...localDropDown,
		});
	};

	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={isloading} />
			<div
				style={{
					position: 'sticky',
					top: '50px',
					zIndex: 999,
				}}
			>
				<CreatePageHeader
					handleSave={handleSave}
					showCancel={false}
					heading='Module Configuration'
				/>
			</div>
			{Object.values(navMenus).map((val) => {
				return (
					!(val.type && val?.type === 'NON_LINK') &&
					val.name && val.id && (
						<div
							style={{
								backgroundColor: '#e2e8f0',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									margin: '10px 10px 0 10px',
									minHeight: '50px',
								}}
								onClick={() => { return handleClick(val.id); }}
							>
								<div
									style={{
										fontWeight: '600',
										marginRight: '10px',
										display: 'flex',
										fontSize: '15px',
										alignItems: 'center',
									}}
								>
									{/* <div
										style={{
											marginRight: '10px',
										}}
									>
										{val.id}
										.
									</div> */}
									<div style={{
										textWrap: 'nowrap',
									}}
									>
										{val.name}
									</div>
								</div>
								{(!val?.submenu ||
									Object.keys(val.submenu)?.length === 0) && (
									<div
										style={{
											display: 'flex',
											margin: '5px',
											alignItems: 'center',
											width: '100%',
										}}
									>
										<TagPicker
											preventOverflow
											value={
												rolesMapping?.[val.id]
													?.rolesMapped
											}
											style={{
												minWidth: '100%',
											}}
											placeholder='Select To Map User Roles'
											data={userRoles}
											onChange={(changedVal) => {
												const roles = rolesMapping;
												roles[val.id] = {
													name: val.name,
													rolesMapped: changedVal,
													disable: val.disabled,
												};
												setRolesMapping({
													...roles,
												});
											}}
										/>
									</div>
								)}
								{!!val?.submenu && (
									<div>
										{!dropDown[val.id] ? (
											<ArrowDropDown sx={{
												fontSize: 30,
											}}
											/>
										) : (
											<ArrowDropUp sx={{
												fontSize: 30,
											}}
											/>
										)}
									</div>
								)}
							</div>
							{!!val?.submenu && (
								<div
									style={{
										backgroundColor: '#f1f5f9',
										maxHeight: dropDown[val.id] ? 'none' : '0',
										overflow: 'hidden',
										transition: 'max-height 1s ease',
									}}
								>
									{Object.values(val.submenu).map(
										(subVal) => {
											return (val.id && subVal.id && (
												<div
													style={{
														display: 'flex',
														justifyContent:
															'space-between',
														marginLeft: '25px',
														marginRight: '20px',
													}}
												>
													<span
														style={{
															fontSize: '15px',
															fontWeight: '460',
															padding: '10px',
															display: 'flex',
														}}
													>
														{/* {`${subVal.id}. `} */}
														<div
															style={{
																marginLeft:
																	'5px',
																textWrap: 'nowrap',
															}}
														>
															{subVal.name}
														</div>
													</span>
													<div
														style={{
															display: 'flex',
															margin: '0 5px',
															alignItems:
																'center',
															width: '100%',
														}}
													>
														<TagPicker
															preventOverflow
															value={
																rolesMapping?.[
																	subVal.id
																]?.rolesMapped
															}
															style={{
																minWidth:
																	'100%',
															}}
															onChange={(
																changedVal
															) => {
																const roles =
																	rolesMapping;
																roles[
																	subVal.id
																] = {
																	rolesMapped:
																		changedVal,
																	name: subVal.name,
																	disable:
																		subVal.disabled,
																};
																setRolesMapping(
																	{
																		...roles,
																	}
																);
															}}
															placeholder='Select To Map User Roles'
															data={userRoles}
														/>
													</div>
												</div>
											)
											);
										}
									)}
								</div>
							)}
						</div>
					)
				);
			})}
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModulesConfiguration);
