/* eslint-disable no-mixed-spaces-and-tabs */
import _ from 'lodash';
import { notificationServices } from '../../../Services';

/* eslint-disable no-param-reassign */
const masterMenuConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			categories: {
				items: {
					1: {
						name: 'Unnamed Category',
						description: '',
						categoryLogo: null,
						timeSlots: {
							isEnabled: false,
							slots: [],
						},
						subCategories: {
							items: {
								1: {
									name: 'Unnamed Subcategory',
									description: '',
									timeSlots: {
										isEnabled: false,
										slots: [],
									},
									selectedProducts: [],
								},
							},
							order: [1],
						},
					},
				},
				order: [1],
			},
		},
		errors: {
			name: '',
			description: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: true,
		categories: [
			{
				name: true,
				description: true,
				categoryLogo: false,
				subCategories: [
					{
						name: true,
						description: true,
						timeSlots: true,
						discount: true,
						isAddOn: true,
						Price: true,
					},
				],
			},
		],
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.menuName,
				description: data.menuDescription,
				categories: {
					items: data.menuCategories.reduce(
						(accCategory, currCategory, idxCategory) => {
							accCategory[idxCategory + 1] = {
								categoryId: currCategory.categoryId,
								name: currCategory.menuCategoryName,
								description:
									currCategory.menuCategoryDescription,
								timeSlots: {
									slots: getTimeSlots(
										currCategory.menuCategoriesTimeSlot
									),
									isEnabled:
										currCategory.menuCategoriesTimeSlot
											.length > 0,
								},
								categoryLogo: currCategory.categoryLogo ?? null,
								subCategories: {
									items: currCategory.menuSubCategories.reduce(
										(
											accSubCategory,
											currSubCategory,
											idxSubCategory
										) => {
											accSubCategory[idxSubCategory + 1] =
												{
													name: currSubCategory.menuSubCategoryName,
													description:
														currSubCategory.menuSubCategoryDescription,
													timeSlots: {
														slots: getTimeSlots(
															currSubCategory.menuSubCategoriesTimeSlot
														),
														isEnabled:
															currSubCategory
																.menuSubCategoriesTimeSlot
																.length > 0,
													},
													selectedProducts:
														currSubCategory.products.map(
															(product) => {
																return {
																	timeSlots:
																		getTimeSlots(
																			product.productTimeSlots
																		),
																	applicableDays:
																		product?.applicableDays,
																	applicableDates:
																		product?.applicableDates,
																	productId:
																		product.productId,
																	discount:
																		product.discount,
																	isAddOn:
																		product.isAddOn ||
																		false,
																	maxQty: product?.maxQty,
																};
															}
														),
												};
											return accSubCategory;
										},
										{}
									),
									order: currCategory.menuSubCategories.map(
										(subCategory, idx) => {
											return idx + 1;
										}
									),
								},
							};
							return accCategory;
						},
						{}
					),
					order: data.menuCategories.map((category, idx) => {
						return idx + 1;
					}),
				},
			};
			return newState;
		});
	},
	deserializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				menuName: state.fields.name,
				menuDescription: state.fields.description,
				menuCategories: state.fields.categories.order.reduce(
					(accCategory, currCategory) => {
						const tempMenuCategories = {};
						const currCategoryObj =
							state.fields.categories.items[currCategory];
						tempMenuCategories.categoryId =
							currCategoryObj.categoryId;
						tempMenuCategories.menuCategoryName =
							currCategoryObj.name;
						tempMenuCategories.menuCategoryDescription =
							currCategoryObj.description;
						tempMenuCategories.categoryLogo =
							currCategoryObj.categoryLogo ?? null;
						tempMenuCategories.menuCategoriesTimeSlot =
							currCategoryObj.timeSlots.isEnabled
								? generateTimeSlots(
									currCategoryObj.timeSlots.slots
								  )
								: generateTimeSlots([]);
						tempMenuCategories.menuSubCategories =
							currCategoryObj.subCategories.order.reduce(
								(accSubCategory, currSubCategory) => {
									const currSubCategoryObj =
										currCategoryObj.subCategories.items[
											currSubCategory
										];
									const tempSubCategory = {
										menuSubCategoryName:
											currSubCategoryObj.name,
										menuSubCategoryDescription:
											currSubCategoryObj.description,
										menuSubCategoriesTimeSlot:
											currSubCategoryObj.timeSlots
												.isEnabled
												? generateTimeSlots(
													currSubCategoryObj
														.timeSlots.slots
												  )
												: generateTimeSlots([]),
										products:
											currSubCategoryObj.selectedProducts.reduce(
												(
													accSelectedProduct,
													currSelectedProduct
												) => {
													const tempProduct = {
														productTimeSlots:
															generateTimeSlots(
																currSelectedProduct.timeSlots
															),
														applicableDays:
															currSelectedProduct?.applicableDays,
														applicableDates:
															currSelectedProduct?.applicableDates,
														productId:
															currSelectedProduct.productId.toString(),
														discount:
															currSelectedProduct.discount,
														isAddOn:
															currSelectedProduct.isAddOn,
														maxQty: currSelectedProduct?.maxQty,
													};
													accSelectedProduct.push(
														tempProduct
													);
													return accSelectedProduct;
												},
												[]
											),
									};
									accSubCategory.push(tempSubCategory);
									return accSubCategory;
								},
								[]
							);
						accCategory.push(tempMenuCategories);
						return accCategory;
					},
					[]
				),
			};
			return newState;
		});
	},
	validator: (state) => {
		const data = _.cloneDeep(state);
		if (data.name.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Menu Name Cannot Be Blank',
			});
			return false;
		}
		if (data.description.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Menu Description Cannot Be Blank',
			});
			return false;
		}
		const numOfMenuCategory = data.categories.order.length;
		for (let i = 0; i < numOfMenuCategory; i++) {
			const categoryData = data.categories.items[data.categories.order[i]];
			if (categoryData.name.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Category Name cannot be blank ${i + 1}`,
				});
				return false;
			}
			if (categoryData.description.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Category Description cannot be blank ${
						i + 1
					}`,
				});
				return false;
			}
			const numOfSubCategory =
			categoryData.subCategories.order.length;
			for (let j = 0; j < numOfSubCategory; j++) {
				const subCategoryData =
				categoryData.subCategories.items[categoryData.subCategories.order[j]];
				if (
					subCategoryData.name.length === 0
				) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Sub Category Name cannot be blank category ${
							i + 1
						} sub category ${j + 1}`,
					});
					return false;
				}
				if (
					subCategoryData.description.length === 0
				) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Sub Category Description cannot be blank category ${
							i + 1
						} sub category ${j + 1}`,
					});
					return false;
				}
				if (
					subCategoryData.selectedProducts.length === 0
				) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Atleast one Product should be selected in Category ${
							i + 1
						} Sub Category  ${j + 1}`,
					});
					return false;
				}
			}
		}
		return true;
	},
};

function generateTimeSlots(slots, limit = 3) {
	const res = [];
	for (let i = 0; i < limit; i++) {
		if (_.get(slots[i], 'startTime', false)) {
			res.push({
				[`startTime${i}`]: slots[i].startTime,
				[`endTime${i}`]: slots[i].endTime,
			});
		} else {
			res.push({
				[`startTime${i}`]: null,
				[`endTime${i}`]: null,
			});
		}
	}
	return res;
}
function getTimeSlots(slots) {
	const res = [];
	for (let idx = 0; idx < slots.length; idx++) {
		if (slots[idx] != null) {
			const start = 'startTime'.concat(idx);
			const end = 'endTime'.concat(idx);
			res.push({
				startTime: slots[idx][start],
				endTime: slots[idx][end],
			});
		}
	}
	return res;
}
export default masterMenuConfig;
