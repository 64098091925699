import styles from './RaiseRequestRefundTable.module.scss';

const RaiseRequestRefundTable = (props) => {
	const { orders } = props;
	return (
		<table className={`${styles['list-table-root']}`}>
			<thead>
				<tr>
					<th>Initiated At</th>
					<th>Amount(₹)</th>
					<th>Reason</th>
					<th>Approved</th>
					<th>Refund Status</th>
				</tr>
			</thead>
			<tbody>
				{orders &&
					orders.map((order) => {
						return (
							<tr>
								<td>{order.initiatedAt}</td>
								<td>{order.recommendedRefundAmount}</td>
								<td>{order.reason}</td>
								<td>
									{order.approved ? 'Approved' : 'Pending'}
								</td>
								<td>{order.status}</td>
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};
export default RaiseRequestRefundTable;
