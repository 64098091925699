import { AddVContainer } from './AddVContainer';
import { DraggableVContainer, VContainer } from './DraggableVContainer';
import styles from './DraggableVContainers.module.scss';

const DraggableVContainers = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		Component,
		items,
		order,
		handleInput,
		handleAddNew,
		handleDelete,
		addText,
		switchConfig,
		mandatoryFields,
		isDraggable,
		isDisabled,
		extraProps,
		handleReorder,
		showDelete,
		showAddNew,
	} = props;

	return (
		<div className={`${styles.root}`}>
			{order.map((id, idx) => {
				const item = items[id];
				if (isDraggable) {
					return (
						<DraggableVContainer
							key={id}
							id={id}
							index={idx}
							moveCard={handleReorder}
							handleInput={handleInput}
							handleDelete={handleDelete}
							switchConfig={switchConfig}
							isDraggable={isDraggable}
							isDisabled={isDisabled}
							showDelete={showDelete}
						>
							<Component
								item={item}
								handleInput={handleInput}
								handleDelete={handleDelete}
								mandatoryFields={mandatoryFields}
								id={id}
								isDisabled={isDisabled}
								extraProps={extraProps}
							/>
						</DraggableVContainer>
					);
				}
				return (
					<VContainer
						key={id}
						id={id}
						index={idx}
						handleInput={handleInput}
						handleDelete={handleDelete}
						switchConfig={switchConfig}
						isDraggable={isDraggable}
						isDisabled={isDisabled}
						showDelete={showDelete}
					>
						<Component
							item={item}
							handleInput={handleInput}
							handleDelete={handleDelete}
							mandatoryFields={mandatoryFields}
							id={id}
							isDisabled={isDisabled}
							extraProps={extraProps}
						/>
					</VContainer>
				);
			})}
			{!isDisabled && showAddNew && (
				<AddVContainer text={addText} handleClick={handleAddNew} />
			)}
		</div>
	);
};

DraggableVContainers.defaultProps = {
	extraProps: {},
	isDraggable: true,
	showDelete: true,
	showAddNew: true,
};

export default DraggableVContainers;
