const colors = [
	'#191970',
	'#2F4F4F',
	'#673AB7',
	'#6A5ACD',
	'#191970',
	'#2F4F4F',
	'#673AB7',
	'#6A5ACD',
	'#191970',
	'#2F4F4F',
	'#673AB7',
	'#6A5ACD',
	'#191970',
	'#2F4F4F',
	'#673AB7',
	'#6A5ACD',
];
export default colors;
