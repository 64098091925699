/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const cookingAnalyticsServices = {
	gateway,
	fetchCookingAnalysisList,
	fetchCookingYieldQcs,
	exportCookingList,
};

function gateway({ req, method }) {
	switch (method) {
		// case 'CHART_DATA':
		// 	return fetchCookingAnalysisGraph({
		// 		req,
		// 	});
		case 'LIST_DATA':
			return fetchCookingAnalysisList({
				req,
			});
		case 'FIND_COOKING_IMAGES':
			return findCookingImages(req);
		default:
			break;
	}
	return new Promise();
}

function fetchCookingAnalysisList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.COOKING_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockQAList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function exportCookingList({ req }) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.COOKING_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
			export: 'True',
		}),
	};
	return fetch(url, requestOptions);
}

function fetchCookingYieldQcs({ recipeId }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			recipeId,
		}),
	};
	// return mockQAList(requestOptions);
	return fetch(endpoints.COOKING_YIELD_QCS, requestOptions).then(
		handleResponse
	);
}

function findCookingImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_COOKING_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
			qcType: 'CMS',
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

// function mockQAList() {
// 	const mockResponse = {
// 		data: [
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/51332fc6-9b4b-4a34-9a44-d3570d6f3e3b.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=ca60844553b02d98c5d73309467af6165039c7269ca3aef964c2d51363bd5311',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 110,
// 				MaxWeight: 10000,
// 				MinTemp: -20,
// 				MinWeight: 0,
// 				OrderId: '1652261689506',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:00:01',
// 				Result: 1,
// 				Temp: 36.7,
// 				TempCheckRequired: true,
// 				Weight: 1554.8,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 32,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/0a5ca007-b9fe-4ab9-a1ba-8a5cf504dcd2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=4e8e026b69213639eb3d828e5ccb397f41c877cbe513bebd3c7017a6a1b9d4d8',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 110,
// 				MaxWeight: 10000,
// 				MinTemp: -20,
// 				MinWeight: 0,
// 				OrderId: '1652261928164',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:08:50',
// 				Result: 1,
// 				Temp: 27.7,
// 				TempCheckRequired: true,
// 				Weight: 109,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 26.5,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/2c81e896-cc33-4524-9237-2ec7a7b18dfd.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=c1c9cc1d4ea321da15b697fe5d32d460a1cc539f3b83e5a8f7f14326deacfd60',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 110,
// 				MaxWeight: 10000,
// 				MinTemp: -20,
// 				MinWeight: 0,
// 				OrderId: '1652261980265',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:09:45',
// 				Result: 1,
// 				Temp: 36.6,
// 				TempCheckRequired: true,
// 				Weight: 327.2,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 32.4,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/772c6715-73ab-45f0-936c-4d1e78efe0ec.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=a03ee97e707999d48674bc9e505ef6e2793d9579c5bb32109c0646f3d65998d2',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 110,
// 				MaxWeight: 10000,
// 				MinTemp: -20,
// 				MinWeight: 0,
// 				OrderId: '1652261996912',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:10:03',
// 				Result: 1,
// 				Temp: 36.8,
// 				TempCheckRequired: true,
// 				Weight: 324,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 32.4,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/55edb27a-c0d3-4952-84cb-49c00475805b.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=1fa2c7fcde82012c37e0d40477bc6c0ed6aefd2af0161a9ffac3d7d0a39cdc63',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 110,
// 				MaxWeight: 10000,
// 				MinTemp: -20,
// 				MinWeight: 0,
// 				OrderId: '1652262521769',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Manchow%20Soup-10-05-22_23%3A57%3A56.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e9d334552ccc3b3ac923a3b146433f0950cb39c97ae3bf8f0a89800d23521440',
// 				ProductName: 'Veg Manchow Soup',
// 				QCTime: '11/05/22 15:18:45',
// 				Result: 1,
// 				Temp: 33.9,
// 				TempCheckRequired: true,
// 				Weight: 338.2,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 27.9,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/65809608-f682-4b7b-a0f3-938a688d2c5c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=3e929abe830caf552945b497fd5c78857935c7830b89e7fdd7bce1b3a28ef516',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 110,
// 				MaxWeight: 10000,
// 				MinTemp: -20,
// 				MinWeight: 0,
// 				OrderId: '1652262533163',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:18:54',
// 				Result: 1,
// 				Temp: 33.7,
// 				TempCheckRequired: true,
// 				Weight: 338.3,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/e99fa350-e937-44d5-9bb4-e921186fe2a4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e2b1eaa8d6335c32130f144f408c0b1afd84f05b16983ec2c52e3611a0d17c55',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262761890',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Manchow%20Soup-10-05-22_23%3A57%3A56.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e9d334552ccc3b3ac923a3b146433f0950cb39c97ae3bf8f0a89800d23521440',
// 				ProductName: 'Veg Manchow Soup',
// 				QCTime: '11/05/22 15:22:44',
// 				Result: 1,
// 				Temp: 33.4,
// 				TempCheckRequired: true,
// 				Weight: 211.8,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/95e4885c-77d5-4807-b73f-5b0a566bf094.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=1627ef7d5d478b6fa4c23a95cbb763152994632d66aa4d42e8678e69ea709011',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262778444',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:23:00',
// 				Result: 1,
// 				Temp: 33.6,
// 				TempCheckRequired: true,
// 				Weight: 211.6,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/e1b8be9d-a64c-4d71-a4ed-fba95be93433.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e597b81324d751863a1e3b6620d5f257e34b4f7c4f92d70cdbfd12c3656f5603',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262788956',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:23:10',
// 				Result: 1,
// 				Temp: 34.5,
// 				TempCheckRequired: true,
// 				Weight: 211.8,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/019183d0-64e3-4708-8bae-60bda1c2f8f6.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=8576b738aef6113522a3ab9c922357d68269d99dbca2c394a9b949ba5261a113',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262800385',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Manchow%20Soup-10-05-22_23%3A57%3A56.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e9d334552ccc3b3ac923a3b146433f0950cb39c97ae3bf8f0a89800d23521440',
// 				ProductName: 'Veg Manchow Soup',
// 				QCTime: '11/05/22 15:23:22',
// 				Result: 1,
// 				Temp: 33.7,
// 				TempCheckRequired: true,
// 				Weight: 211.8,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/297de283-3b1c-43d9-9d99-b0b1f717263c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=490e0f7a2405cae01aca83dc8df8ea94d584f56256b6087bb0ae3088325037d5',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262811477',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:23:33',
// 				Result: 1,
// 				Temp: 33.7,
// 				TempCheckRequired: true,
// 				Weight: 211.6,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/aa6e131a-7f95-4288-95d9-6130762e3465.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=3f362b5be26d4c790493dea45b7cb9984e4718d73e61d5a7e2c551951ba65d6a',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262839140',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:24:01',
// 				Result: 1,
// 				Temp: 33.6,
// 				TempCheckRequired: true,
// 				Weight: 895.4,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.1,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/7ac24bbd-c3f3-4115-a5af-0110bf57611d.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=6f1495f5a16a62694cac962a7a8d18c86b5a4db51e4e96bb0ea9ac73ca0323be',
// 				IsTempInRange: true,
// 				IsWeightInRange: false,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262846170',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:24:08',
// 				Result: 0,
// 				Temp: 33.5,
// 				TempCheckRequired: true,
// 				Weight: 1107.2,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.3,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/8ce19d36-48ea-46cf-bd84-e0ad76861f3e.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=f969908232da129dbf552b672182fc481ceea01f1a68b51c72e5fa51f0b9a99b',
// 				IsTempInRange: true,
// 				IsWeightInRange: false,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652262846170',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:24:19',
// 				Result: 0,
// 				Temp: 33.5,
// 				TempCheckRequired: true,
// 				Weight: 1107.2,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.3,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/f1f8fea5-17da-45f6-95d4-938f2fe9a9ff.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=162a6c429c718462cd19c94ff4a5039a15fb6ad1f46f326160f01d53c20971fc',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652263079320',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Manchow%20Soup-10-05-22_23%3A57%3A56.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e9d334552ccc3b3ac923a3b146433f0950cb39c97ae3bf8f0a89800d23521440',
// 				ProductName: 'Veg Manchow Soup',
// 				QCTime: '11/05/22 15:28:02',
// 				Result: 1,
// 				Temp: 33.1,
// 				TempCheckRequired: true,
// 				Weight: 895.3,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 27.9,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/cef60fe3-c812-414b-a531-f8ad35c0761a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=cbe147e408d18dc1546f895e296392f49692d184e2c54f13c306dfa49d467722',
// 				IsTempInRange: true,
// 				IsWeightInRange: false,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652263345216',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Chicken%20Burmese%20Khao%20Suey-11-05-22_14%3A35%3A59.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=af4342851df8e3eed1e99efb8281d06b36304ce8da65184be3f0c303e75c7527',
// 				ProductName: 'Chicken Burmese Khao Suey',
// 				QCTime: '11/05/22 15:32:27',
// 				Result: 0,
// 				Temp: 32.8,
// 				TempCheckRequired: true,
// 				Weight: 930.9,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 27.8,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/d5df7cfd-1289-4c4e-9976-1226932de8e5.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=85bfd798f406c533fa2a94450b1d1f6c85be2f7a7559d6f7f69e1dd6d7e3b878',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652263445589',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20American%20Choupsey-11-05-22_00%3A06%3A37.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=99f886a25802035178d878b16ac3117e7562c5f87ac1a61452dd87af7c0205dc',
// 				ProductName: 'Veg American Choupsey',
// 				QCTime: '11/05/22 15:34:08',
// 				Result: 1,
// 				Temp: 34.9,
// 				TempCheckRequired: true,
// 				Weight: 280.9,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 27.9,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/627b3055-5433-49eb-95da-adfe41be1d53.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=9bc64cc29a935f61f3cdc2d661e387f21c7bff2240ef07088a64f3c9601ddc63',
// 				IsTempInRange: false,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652263479628',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Manchow%20Soup-10-05-22_23%3A57%3A56.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e9d334552ccc3b3ac923a3b146433f0950cb39c97ae3bf8f0a89800d23521440',
// 				ProductName: 'Veg Manchow Soup',
// 				QCTime: '11/05/22 15:34:41',
// 				Result: 0,
// 				Temp: 68.9,
// 				TempCheckRequired: true,
// 				Weight: 761.8,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 32.6,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/efe21733-5ad3-4b6f-89da-33ff1de05827.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=e39a1a796d9605a2aa62ccc91fa3dde416f7c8ce2823cd3d26eafc0334cec6c5',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652263922748',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:42:07',
// 				Result: 1,
// 				Temp: 32,
// 				TempCheckRequired: true,
// 				Weight: 390.4,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.4,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/dfdc2338-9ad2-4e52-a372-6cea3fb2678b.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=16c8c774d63ebbb451c7fe5813096fd7f649cb07cb4b0e20c91541cbea768e16',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652264443468',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:50:47',
// 				Result: 1,
// 				Temp: 33,
// 				TempCheckRequired: true,
// 				Weight: 72.7,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 			{
// 				BrandImage:
// 					'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
// 				FoodImage:
// 					'https://resoee.s3.amazonaws.com/45/QualityCheck/bf8dcf53-f43a-4661-824f-e183cfaf1d33.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=3f68cad587dd09e31d1c3f673346e2c45176d1fe53206c9f473f6097d37389b6',
// 				IsTempInRange: true,
// 				IsWeightInRange: true,
// 				MaxTemp: 60,
// 				MaxWeight: 900,
// 				MinTemp: 20,
// 				MinWeight: 50,
// 				OrderId: '1652264482802',
// 				ProductImage:
// 					'https://resoee.s3.amazonaws.com/45/ProductCategory/Veg%20Sweet%20Corn%20Soup-10-05-22_23%3A55%3A07.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220514%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220514T035453Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d1580e96188791f32dad487d135239ecae2a85ce3845556683b3a43129860a31',
// 				ProductName: 'Veg Sweet Corn Soup',
// 				QCTime: '11/05/22 15:52:01',
// 				Result: 1,
// 				Temp: 32.7,
// 				TempCheckRequired: true,
// 				Weight: 72.7,
// 				WeightCheckRequired: true,
// 				foodLabel: null,
// 				isTempMax: null,
// 				isVeg: null,
// 				itemId: null,
// 				otherTemp: 28.2,
// 			},
// 		],
// 	};

// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 1000);
// 	});
// }

export default cookingAnalyticsServices;
