import _ from 'lodash';

// eslint-disable-next-line object-curly-newline
const ProductsConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
		},
		errors: {
			name: '',
			description: '',
		},
	},
	mandatoryFields: {
		name: true,
		description: true,
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.name,
				description: data.description,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = {
			name: state.fields.name,
			description: state.fields.description,
		};
		return newState;
	},
};
export default ProductsConfig;
