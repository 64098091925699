import { cloneDeep } from 'lodash';
import ListTable from '../../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Settlement Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Transactions from',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Transactions to',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Gross Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Refund Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Net Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Platform Charges',
		config: {
			type: 'DATA',
		},
	},
];
const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	'Settlement Id': 'settlementId',
	'Transactions from': 'transaction_from',
	'Transactions to': 'transaction_to',
	'Gross Amount': 'gross_amount',
	'Refund Amount': 'refund_amount',
	'Net Amount': 'net_amount',
	'Platform Charges': 'platform_charges',
};

const PaymentSettlementListData = (props) => {
	const { data, query } = props;
	return (
		data && (
			<div className='list-page-root'>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data.reduce((accSettlement, currSettlement) => {
						const tempSettlement = cloneDeep(currSettlement);
						if (
							tempSettlement.settlementId
								.toString()
								.indexOf(query) !== -1
						) {
							tempSettlement.id = tempSettlement.settlementId;
							accSettlement.push(tempSettlement);
						}
						return accSettlement;
					}, [])}
					actions={[...ACTION_COLUMNS]}
					special={{}}
				/>
			</div>
		)
	);
};
export default PaymentSettlementListData;
