import { connect } from 'react-redux';
import { useEffect, useReducer } from 'react';
import {
	Button, FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import styles from './EditChildModal.module.css';
import { BaseModal } from '../BaseModal';
import { formActions, modalActions } from '../../../Actions';
import childServices from '../../../Services/Form Services/child.services';
import { notificationServices } from '../../../Services';

const initialState = {
	name: '',
	class: '',
	section: '',
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_NAME':
			return {
				...state,
				name: action.payload,
			};
		case 'SET_CLASS':
			return {
				...state,
				class: action.payload,
			};
		case 'SET_SECTION':
			return {
				...state,
				section: action.payload,
			};
		case 'SET_INITIAL':
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

const EditChildModal = (props) => {
	const { show, data, toggleModal, handleSearch } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		dispatch({
			type: 'SET_INITIAL',
			payload: {
				name: data?.addressLine1 ?? '',
				class: data?.addressLine2 ?? '',
				section: data?.city ?? '',
			},
		});
	}, [data]);

	const handleClose = () => {
		toggleModal(false, {});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		const type = `SET_${name?.toUpperCase()}`;
		dispatch({
			type,
			payload: value,
		});
	};

	const handleSubmit = async () => {
		try {
			const req = {
				id: data?.id,
				data: {
					addressLine1: state?.name,
					addressLine2: state?.class,
					city: state?.section,
				},
			};
			const response = await childServices.gateway({
				req,
				method: 'EDIT_CHILD',
			});
			if (response?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.responseMessage,
				});
				handleSearch();
				handleClose();
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message ?? error,
			});
		}
	};

	return (
		<BaseModal
			open={show}
			heading={`Edit Child ${state?.name}`}
			handleClose={handleClose}
			showConfirm={false}
			width='40rem'
			renderBtns={() => {
				return (
					<Button
						type='button'
						fullWidth
						variant='contained'
						onClick={handleSubmit}
					>
						Submit
					</Button>
				);
			}}
		>
			<div className={styles.root}>
				<div className={styles['input-container']}>
					<TextField
						fullWidth
						name='name'
						onChange={handleInputChange}
						value={state?.name}
						label='Edit Name'
						className={styles.input}
					/>
					<FormControl fullWidth>
						<InputLabel id='class-label'>Class</InputLabel>
						<Select
							name='class'
							onChange={handleInputChange}
							value={state?.class}
							labelId='class-label'
							label='Class'
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11}>11</MenuItem>
							<MenuItem value={12}>12</MenuItem>
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id='section-label'>Section</InputLabel>
						<Select
							name='section'
							onChange={handleInputChange}
							value={state?.section}
							labelId='section-label'
							label='Section'
						>
							<MenuItem value='A'>A</MenuItem>
							<MenuItem value='B'>B</MenuItem>
							<MenuItem value='C'>C</MenuItem>
							<MenuItem value='D'>D</MenuItem>
							<MenuItem value='E'>E</MenuItem>
							<MenuItem value='F'>F</MenuItem>
							<MenuItem value='G'>G</MenuItem>
							<MenuItem value='H'>H</MenuItem>
							<MenuItem value='I'>I</MenuItem>
							<MenuItem value='J'>J</MenuItem>
							<MenuItem value='K'>K</MenuItem>
							<MenuItem value='L'>L</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.editChildModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleEditChildModal,
	fetchList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChildModal);
