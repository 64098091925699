import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { DraggableHTabs } from '../../../Components/Draggable';
import MenuCategory from '../../../Components/Forms/MenuDetails/MenuCategory';
import MenuDetails from '../../../Components/Forms/MenuDetails/MenuDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import menuConfig from './mastermenu.config';

const TABS = ['MENU DETAILS'];

const MasterMenuViewPage = (props) => {
	const { data, fetchMenus, fetchProducts, breadcrumbs } = props;
	const [state, setState] = useState({
		activeTab: 0,
		activeSubTab: 0,
		fields: _.cloneDeep(menuConfig.initialState.fields),
		mandatoryFields: _.cloneDeep(menuConfig.mandatoryFields),
	});
	const { id } = useRouteMatch().params;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const { url } = useRouteMatch();
	useEffect(() => {
		fetchMenus({
			entity: 'MASTER_MENUS',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					skuType: '',
				},
			},
			method: 'LIST',
		});
	}, []);
	useEffect(() => {
		if (!isEmpty(data)) {
			setState(menuConfig.serializer(state, data));
		}
	}, [data]);
	const [activeCategory, setActiveCategory] = useState(
		state.fields.categories.order.length
	);
	const [activeSubCategory, setActiveSubCategory] = useState(1);
	const { activeTab, fields, mandatoryFields } = state;
	const handleActiveCategory = (tempId) => {
		if (
			fields.categories.items[tempId].subCategories.order.length <
			activeSubCategory
		) {
			setActiveSubCategory(1);
		}
		setActiveCategory(tempId);
	};
	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<>
						<MenuDetails
							isDisabled
							menuName={fields.name}
							menuDescription={fields.description}
						/>
						<DraggableHTabs
							items={fields.categories.items}
							order={fields.categories.order}
							handleActiveTab={handleActiveCategory}
							activeTab={activeCategory}
							limit={5}
							isDraggable={false}
							isDisabled
						/>
						<MenuCategory
							categoryDetails={
								fields.categories.items[activeCategory]
							}
							activeCategory={activeCategory}
							fields={fields}
							mandatoryFields={mandatoryFields}
							isDisabled
							activeSubCategory={activeSubCategory}
							setActiveSubCategory={setActiveSubCategory}
							checkBoxShow={false}
						/>
					</>
				);
			default:
				return <></>;
		}
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Master Menu'
				handleEdit={redirectToEdit}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.name}`,
					},
				]}
				showBreadcrumbs
				showDelete={false}
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MASTER_MENUS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchMenus: formActions.gateway,
	fetchProducts: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterMenuViewPage);
