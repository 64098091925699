/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { notificationServices } from '../../../Services';

const BrandingConfig = {
	initialState: {
		fields: {
			name: '',
			logo: null,
			bannerList: [],
			bannerListMobile: [],
			TOS: null,
			PP: null,
		},
		errors: {
			name: '',
			logo: '',
			bannerList: '',
			bannerListMobile: '',
			TOS: '',
			PP: '',
		},
	},
	mandatoryFields: {
		name: true,
		logo: true,
		bannerList: false,
		bannerListMobile: false,
		TOS: true,
		PP: true,
	},
	serializer: (state, data) => {
		// console.log('brand config serialzer data', data);
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.displayName,
				logo: data.logo,
				bannerList: data?.bannerImages ?? [],
				bannerListMobile: data?.phoneBannerImages ?? [],
				TOS: data.TOSUrl,
				PP: data.privacyUrl,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = {
			displayName: state.fields.name,
			logo: state.fields.logo,
			bannerImages: state.fields.bannerList,
			phoneBannerImages: state.bannerListMobile,
			TOSUrl: state.fields.TOS,
			privacyUrl: state.fields.PP,
		};
		return newState;
	},
	validator: (state) => {
		if (state.fields.bannerList.length < 3) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select atleast 3 banner images for desktop',
			});
			return false;
		}
		// if (state.fields.bannerListMobile.length < 3) {
		// notificationServices.generateNotification({
		// type: 'error',
		// message: 'Please select atleast 3 banner images for mobile',
		// });
		// return false;
		// }
		return true;
	},
};

export default BrandingConfig;
