import barCodeImg from '../../../Images/LabelGenerator/barCode.jpg';

function Barcode(props) {
	const barCodeStyle = {
		height: props.height,
		width: props.width,
		overflow: 'hidden',
		border:
			// eslint-disable-next-line no-nested-ternary
			props?.selectedComponent === props?.id
				? '1px solid orange'
				: props?.currentComponent === props?.id
					? '1px solid red'
					: null,
	};
	return (
		<div className='bar-code' style={barCodeStyle}>
			<img
				className='bar-code-img'
				src={barCodeImg}
				alt='bar-code'
				style={{
					width: '100%', height: '100%',
				}}
			/>
		</div>
	);
}
export default Barcode;
