import { DraggableVContainers } from '../../../Draggable';
import { CustomizationGroup } from '../CustomizationGroup';
import styles from './CustomizationDetails.module.scss';

const CustomizationDetails = (props) => {
	const {
		mandatoryFields,
		handleInput,
		handleAddNewGroup,
		handleDelete,
		isDisabled,
		isDraggable,
		customizations,
		extraProps,
		handleReorder,
		showDelete,
		showAddNew,
		component,
	} = props;
	return (
		<div className={styles.root}>
			<DraggableVContainers
				Component={component || CustomizationGroup}
				mandatoryFields={mandatoryFields}
				handleReorder={handleReorder}
				handleInput={handleInput}
				handleAddNew={handleAddNewGroup}
				handleDelete={handleDelete}
				addText='Customization'
				isDraggable={isDraggable}
				isDisabled={isDisabled}
				items={customizations.items}
				order={customizations.order}
				switchConfig={{
					checked: customizations.order.reduce((acc, curr) => {
						acc[curr] = customizations.items[curr].isRemovable;
						return acc;
					}, {}),
					type: 'group',
					name: 'isRemovable',
					label: 'Removable',
				}}
				extraProps={extraProps}
				showDelete={showDelete}
				showAddNew={showAddNew}
			/>
		</div>
	);
};

CustomizationDetails.defaultProps = {
	customizations: {
		items: {},
		order: [],
	},
	isDraggable: true,
	showDelete: true,
	showAddNew: true,
};

export default CustomizationDetails;
