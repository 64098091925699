import { Add, ArrowCircleRight } from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import { Button, Checkbox, IconButton, MenuItem, Select } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useReducer, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { modalActions, formActions, brandActions, analyticsAction } from '../../../../Actions';
import {
	classes, inputHelper, usePagination, useQuery
} from '../../../../Helpers';
import { UYDateRange } from '../../../Forms/Payment Settlements/Calender/UYDateRange';
import { ListModal } from '../../../Modals';
import { Tag } from '../../../Tags';
import styles from './DefectsImagesFinder.module.scss';

const EMPTYVALUE = -999;

const defectTypes = [
	{
		id: 1,
		type: 'AI',
	},
	{
		id: 2,
		type: 'Manual',
	},
	{
		id: 3,
		type: 'Both',
	},
];

const timeTypeArr = [
	{
		label: 'Annotation Time',
		value: 'annotation',
	},
	{
		label: 'QC Time',
		value: 'qc',
	},
];

const typesList = [
	{
		name: 'QUALITY',
		value: 'QUALITY',
	},
	{
		name: 'COMPONENT',
		value: 'IDENTITY',
	},
];

const params = {
	BRAND: 'brand',
	VENDOR: 'vendor',
	STORE: 'store',
	PRODUCT: 'product',
	DEFECTS: 'defect',
	CATEGORY: 'productCategory',
	from: 'from',
	to: 'to',
	iType: 'itype',
};

const initialState = {
	brand: null,
	featureType: 'QUALITY',
	store: null,
	defects: [],
	products: [],
	vendors: [],
	productCategories: [],
	identificationType: 'AI',
	timeType: 'annotation',
	dateRange: {
		active: false,
		from: new Date(),
		to: new Date(),
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_BRAND':
			return {
				...state,
				brand: action.payload,
			};
		case 'SET_FEATURE_TYPE':
			return {
				...state,
				featureType: action.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action.payload,
			};
		case 'SET_DEFECTS':
			return {
				...state,
				defects: action.payload,
			};
		case 'SET_VENDORS':
			return {
				...state,
				vendors: action.payload,
			};
		case 'SET_IDENTIFICATION_TYPE':
			return {
				...state,
				identificationType: action.payload,
			};
		case 'SET_PRODUCT_CATEGORY':
			return {
				...state,
				productCategories: action.payload,
			};
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.payload,
			};
		case 'SET_TIME_TYPE':
			return {
				...state,
				timeType: action.payload,
			};
		case 'SET_DATE_RANGE':
			return {
				...state,
				dateRange: action.payload,
			};
		default:
			return state;
	}
};

const DefectsImagesFinder = (props) => {
	const { clientType } = JSON.parse(localStorage.getItem('appConfig'));
	const [state, dispatch] = useReducer(reducer, initialState);
	const query = useQuery();
	const {
		brand,
		featureType,
		store,
		defects,
		vendors,
		products,
		identificationType,
		timeType,
		dateRange,
		productCategories,
	} = state;
	const {
		toggleModal,
		brands,
		fetchBrands,
		defectsList,
		fetchDefects,
		productsList,
		fetchStoresList,
		stores,
		fetchProducts,
		fetchAllVendors,
		vendorsList,
		productCategoriesList,
		fetchProductCatergoriesList,
		onSubmit,
		// dateRangeFilter,
		// setDateRangeFilter,
	} = props;

	const brandsList = useMemo(() => {
		return Object.keys(brands).map((id) => {
			const currBrand = brands[id];
			return currBrand;
		});
	}, [brands]);

	const { limit, setLimit } = usePagination({
		customLimit: 6,
	});
	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		onSubmit({
			store: store === EMPTYVALUE ? null : store,
			brand,
			featureType,
			vendors,
			defects,
			products,
			productCategories,
			identification: identificationType,
			timeType,
			pageLimit: limit,
			dateRange,
		});
	};

	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
			};
		});
		tempStore?.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return tempStore;
	}, [stores]);

	useEffect(() => {
		dispatch({
			type: 'SET_STORE',
			payload: STORES?.[0]?.id,
		});
	}, [stores]);

	useEffect(() => {
		let sub = false;
		const queryBrand = query.get(params.BRAND);
		const queryStore = query.get(params.STORE) && [
			Number.parseInt(query.get(params.STORE), 10),
		];
		const from = query.get(params.from);
		const to = query.get(params.to);
		let iType = query.get(params.iType);
		iType = iType?.replaceAll("'", '');
		const queryVendor = query.get(params.VENDOR) && [
			Number.parseInt(query.get(params.VENDOR), 10),
		];
		const queryProduct = query.get(params.PRODUCT) && [
			Number.parseInt(query.get(params.PRODUCT), 10),
		];
		const queryDefect = query.get(params.DEFECTS) && [
			Number.parseInt(query.get(params.DEFECTS), 10),
		];
		const queryCategory = query.get(params.CATEGORY) && [
			Number.parseInt(query.get(params.CATEGORY), 10),
		];
		if (brandsList.length > 0) {
			if (query.has(params.BRAND)) {
				sub = true;
				dispatch({
					type: 'SET_BRAND',
					payload: queryBrand,
				});
			} else {
				dispatch({
					type: 'SET_BRAND',
					payload: brandsList[0].id,
				});
			}
			if (query.has(params.VENDOR)) {
				sub = true;
				dispatch({
					type: 'SET_VENDORS',
					payload: queryVendor,
				});
			}
			if (query.has(params.PRODUCT)) {
				sub = true;
				dispatch({
					type: 'SET_PRODUCTS',
					payload: queryProduct,
				});
			}
			if (query.has(params.STORE)) {
				sub = true;
				dispatch({
					type: 'SET_STORE',
					payload: queryStore,
				});
			}
			if (query.has(params.DEFECTS)) {
				sub = true;
				dispatch({
					type: 'SET_DEFECTS',
					payload: queryDefect,
				});
			}
			if (iType) {
				sub = true;
				dispatch({
					type: 'SET_IDENTIFICATION_TYPE',
					payload: iType,
				});
			} else {
				dispatch({
					type: 'SET_IDENTIFICATION_TYPE',
					payload: 'Both',
				});
			}

			if (query.has(params.CATEGORY)) {
				if (
					productCategoriesList.find((ele) => {
						return ele.id === queryCategory[0];
					})
				) {
					sub = true;
					dispatch({
						type: 'SET_PRODUCT_CATEGORY',
						payload: queryCategory,
					});
				}
			}
			if (sub) {
				// console.log('runnning');

				dispatch({
					type: 'SET_TIME_TYPE',
					payload: 'qc',
				});
				dispatch({
					type: 'SET_DATE_RANGE',
					payload: {
						// ...dateRange,
						from: new Date(from),
						active: true,
						to: new Date(to),
					},
				});
				// console.log('dispactched..time');
				onSubmit({
					store: queryStore ?? null,
					brand: queryBrand ?? brandsList[0].id,
					featureType,
					vendors: queryVendor ?? vendors,
					defects: queryDefect ?? defects,
					products: queryProduct ?? products,
					productCategories: queryCategory ?? productCategories,
					// identification: 'Both',
					identification: iType || 'Both',
					timeType: 'qc',
					pageLimit: limit,
					dateRange: {
						from: from ? new Date(from) : new Date(),
						active: true,
						to: to ? new Date(to) : new Date(),
					},
				});
			}
		}
	}, [
		brandsList?.[0]?.id,
		query.get(params.BRAND),
		query.get(params.PRODUCT),
		query.get(params.DEFECTS),
		query.get(params.iType),
		query.get(params.VENDOR),
	]);

	const selectBrand = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_BRAND',
			payload: fieldValue,
		});
	};
	const selectType = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_FEATURE_TYPE',
			payload: fieldValue,
		});
	};
	const selectStore = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_STORE',
			payload: fieldValue,
		});
	};

	const addFilter = (filters, context) => {
		if (context.entity === 'DEFECT') {
			dispatch({
				type: 'SET_DEFECTS',
				payload: [...defects, ...filters],
			});
		} else if (context.entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: [...products, ...filters],
			});
		} else if (context.entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: [...vendors, ...filters],
			});
		} else if (context.entity === 'CATEGORY') {
			dispatch({
				type: 'SET_PRODUCT_CATEGORY',
				payload: [...productCategories, ...filters],
			});
		}
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;

		if (entity === 'DEFECT') {
			dispatch({
				type: 'SET_DEFECTS',
				payload: defects.filter((defect) => {
					return defect !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: products.filter((product) => {
					return product !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: vendors.filter((vendor) => {
					return vendor !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'CATEGORY') {
			dispatch({
				type: 'SET_PRODUCT_CATEGORY',
				payload: productCategories.filter((category) => {
					return category !== parseInt(id, 10);
				}),
			});
		}
	};

	useEffect(() => {
		fetchBrands({
			id: '',
			allData: true,
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		if (!query.has(params.from)) {
			dispatch({
				type: 'SET_DATE_RANGE',
				payload: {
					active: false,
					from: new Date(),
					to: new Date(),
				},
			});
		}
		fetchProductCatergoriesList({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchAllVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		dispatch({
			type: 'SET_BRAND',
			payload: brandsList[0]?.id,
		});
	}, [brandsList]);

	useEffect(() => {
		fetchDefects({
			entity: 'DEFECTS',
			req: {
				params: {
					brandId: brand,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					brandId: brand,
				},
			},
			method: 'LIST',
		});
	}, [brand]);

	const DEFECTS = useMemo(() => {
		return defectsList.map((defect) => {
			return {
				id: defect.id,
				title: defect?.label ?? '',
			};
		});
	}, [defectsList]);

	const VENDORS = useMemo(() => {
		return vendorsList.map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
			};
		});
	}, [vendorsList]);

	const PRODUCTS = useMemo(() => {
		return productsList.map((product) => {
			return {
				id: product.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList]);

	const PRODUCT_CATEGORIES = useMemo(() => {
		return productCategoriesList
			?.filter((category) => {
				return category?.brandId === brand;
			})
			?.map((category) => {
				return {
					id: category.id,
					title: category?.productCategoryName ?? '',
				};
			});
	}, [productCategoriesList, brandsList]);

	const selectDefectType = (e) => {
		const { fieldValue } = inputHelper(e);

		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: fieldValue,
		});
	};

	const selectTimeType = (e) => {
		const { fieldValue } = inputHelper(e);

		dispatch({
			type: 'SET_TIME_TYPE',
			payload: fieldValue,
		});
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDateRange = ({ active, from, to }) => {
		dispatch({
			type: 'SET_DATE_RANGE',
			payload: {
				active,
				from,
				to,
			},
		});
	};

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
	};

	return (
		<form className={styles.root} onSubmit={handleSubmit}>
			<ListModal handleConfirm={addFilter} list={PRODUCTS} />
			<div className={styles['header-container']}>
				<div className={styles['header-selector-wrapper']}>
					{/* <p className={styles.header}>Feature Images Finder</p> */}
					<div>
						<p>Identification Type:</p>
						<Select
							value={identificationType ?? ''}
							className={styles['defect-select']}
							onChange={selectDefectType}
							disabled={defectTypes.length === 0}
						>
							{defectTypes.map((type) => {
								return (
									<MenuItem key={type.id} value={type.type}>
										{type.type}
									</MenuItem>
								);
							})}
						</Select>
					</div>
					<div>
						<p>Page Limit:</p>
						<Select
							value={limit ?? ''}
							className={styles['defect-select']}
							onChange={(e) => {
								setLimit(e.target.value);
							}}
						>
							{[6, 25, 50, 100].map((pageLimit) => {
								return (
									<MenuItem key={pageLimit} value={pageLimit}>
										{pageLimit}
									</MenuItem>
								);
							})}
						</Select>
					</div>
				</div>
				<div className={styles.headerStoreSelector}>
					<p className={styles['filter-heading']}>Select a Store</p>
					<div className={styles['filter-body']}>
						<Select
							className={styles['store-select']}
							value={store}
							onChange={selectStore}
							disabled={false}
						>
							{STORES?.map((storeObj) => {
								return (
									<MenuItem
										key={storeObj.id + storeObj.title}
										value={storeObj?.id}
									>
										{storeObj.title}
									</MenuItem>
								);
							})}
						</Select>
					</div>
				</div>
				<div className={styles.headerBrandSelector}>
					<p className={styles['filter-heading']}>Select a Brand</p>
					<div className={styles['filter-body']}>
						<Select
							className={styles['brand-select']}
							value={brand ?? ''}
							onChange={selectBrand}
							disabled={defects.length > 0 || products.length > 0}
						>
							{brandsList?.map((brandObj) => {
								return (
									<MenuItem key={brandObj.id} value={brandObj.id}>
										{brandObj.name}
									</MenuItem>
								);
							})}
						</Select>
					</div>
				</div>
				{clientType !== 'SCHOOL' && (
					<div className={styles.headerBrandSelector}>
						<p className={styles['filter-heading']}>Select Feature Type</p>
						<div className={styles['filter-body']}>
							<Select
								className={styles['brand-select']}
								value={featureType ?? ''}
								onChange={selectType}
								// disabled={defects.length > 0 || products.length > 0}
							>
								{typesList?.map((type) => {
									return (
										<MenuItem
											key={type.name}
											value={type.value}
										>
											{type.name}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					</div>
				)}
				<div
					className={classes(
						styles['range-container'],
						dateRange.active ? styles.applied : ''
					)}
				>
					<Checkbox
						checked={dateRange?.active}
						onChange={() => {
							return setDateRange({
								...dateRange,
								active: !dateRange.active,
							});
						}}
						inputProps={{
							'aria-label': 'controlled',
						}}
					/>
					<Select
						value={timeType ?? ''}
						className={styles['time-select']}
						onChange={selectTimeType}
						disabled={!dateRange.active}
					>
						{timeTypeArr.map((type, idx) => {
							return (
								// eslint-disable-next-line react/no-array-index-key
								<MenuItem key={idx} value={type.value}>
									{type.label}
								</MenuItem>
							);
						})}
					</Select>
					<UYDateRange
						disabled={!dateRange.active}
						dateRanges={dateRangeFilterArray}
						onDateRangeChange={setDate}
					/>
				</div>
			</div>
			<div className={styles.form}>
				<div className={styles.filters}>
					<div className={classes(styles.filter, styles.productCategory)}>
						<p className={styles['filter-heading']}>Product category</p>
						<div className={styles['filter-body']}>
							<div className={styles.tags}>
								{productCategories.map((id) => {
									const selectedCategory = PRODUCT_CATEGORIES?.find((v) => {
										return id === v?.id;
									});
									return (
										<Tag
											key={selectedCategory?.id}
											text={selectedCategory?.title}
											handleDelete={deleteFilter}
											dataAttrs={{
												'data-id': selectedCategory?.id,
												'data-entity': 'CATEGORY',
											}}
										/>
									);
								})}
								{productCategories?.length === 0 && (
									<span>All Categories selected</span>
								)}
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									onClick={() => {
										toggleModal(true, {
											entity: 'CATEGORY',
											heading: 'Select Category(s)',
											list: PRODUCT_CATEGORIES,
											selected: productCategories,
										});
									}}
								>
									Category
								</Button>
							</div>
						</div>
					</div>
					<div className={styles.seperator}>
						<Add />
					</div>
					<div className={classes(styles.filter, styles.vendor)}>
						<p className={styles['filter-heading']}>Select Vendor(s)</p>
						<div className={styles['filter-body']}>
							<div className={styles.tags}>
								{vendors.map((id) => {
									const selectedVendor = VENDORS?.find((v) => {
										return id === v?.id;
									});
									return (
										<Tag
											key={selectedVendor?.id}
											text={selectedVendor?.title}
											handleDelete={deleteFilter}
											dataAttrs={{
												'data-id': selectedVendor?.id,
												'data-entity': 'VENDOR',
											}}
										/>
									);
								})}
								{vendors?.length === 0 && <span>All Vendors selected</span>}
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									onClick={() => {
										toggleModal(true, {
											entity: 'VENDOR',
											heading: 'Select Vendors(s)',
											list: VENDORS,
											selected: vendors,
										});
									}}
								>
									VENDOR
								</Button>
							</div>
						</div>
					</div>
					<div className={styles.seperator}>
						<Add />
					</div>
					<div className={classes(styles.filter, styles.defect)}>
						<p className={styles['filter-heading']}>Select Feature(s)</p>
						<div className={styles['filter-body']}>
							<div className={styles.tags}>
								{defects.map((id) => {
									const defect = DEFECTS.find((d) => {
										return id === d.id;
									});
									return (
										<Tag
											key={id}
											text={defect?.title}
											handleDelete={deleteFilter}
											dataAttrs={{
												'data-id': id,
												'data-entity': 'DEFECT',
											}}
										/>
									);
								})}
								{defects?.length === 0 && <span>All Features selected</span>}
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									onClick={() => {
										toggleModal(true, {
											entity: 'DEFECT',
											heading: 'Select Defect(s)',
											list: DEFECTS,
											selected: defects,
										});
									}}
								>
									FEATURE
								</Button>
							</div>
						</div>
					</div>
					<div className={styles.seperator}>
						<Add />
					</div>
					<div className={classes(styles.filter, styles.product)}>
						<p className={styles['filter-heading']}>Select Product(s)</p>
						<div className={styles['filter-body']}>
							<div className={styles.tags}>
								{products.map((id) => {
									const product = PRODUCTS.find((d) => {
										return id === d.id;
									});
									return (
										<Tag
											key={id}
											text={product?.title}
											handleDelete={deleteFilter}
											dataAttrs={{
												'data-id': id,
												'data-entity': 'PRODUCT',
											}}
										/>
									);
								})}
								{products?.length === 0 && <span>All products selected</span>}
								<Button
									variant='outlined'
									startIcon={<Add />}
									className={styles['add-button']}
									// disabled={brand == null}
									onClick={() => {
										toggleModal(true, {
											entity: 'PRODUCT',
											heading: 'Select Product(s)',
											list: PRODUCTS,
											selected: products,
										});
									}}
								>
									Product
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.submit}>
					<span>Find</span>
					<IconButton type='submit' className={styles.btn}>
						<ArrowCircleRight />
					</IconButton>
				</div>
			</div>
		</form>
	);
};

DefectsImagesFinder.defaultProps = {
	brands: [],
	defects: [
		{
			label: 'Defect A',
		},
		{
			label: 'Defect B',
		},
		{
			label: 'Defect C',
		},
		{
			label: 'Defect D',
		},
	],
	products: [
		{
			label: 'Product D',
		},
	],
	onSubmit: () => {},
};

const mapStateToProps = (state) => {
	// console.log('my', state);
	const stores = state?.form?.STORES?.data?.LIST;
	const { brands } = state.brand;
	const { LIST: defectsList } = state.form.DEFECTS.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;

	return {
		stores,
		brands,
		defectsList,
		productsList,
		vendorsList,
		productCategoriesList,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchBrands: brandActions.fetchBrands,
	fetchDefects: formActions.gateway,
	fetchStoresList: formActions.gateway,
	fetchProducts: formActions.gateway,
	fetchAllVendors: formActions.gateway,
	fetchProductCatergoriesList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectsImagesFinder);
