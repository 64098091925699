import qrCodeImg from '../../../Images/LabelGenerator/qrCode.jpg';

function QRCode(props) {
	const qrCodeStyle = {
		height: props.height,
		width: props.width,
		overflow: 'hidden',
		border:
			// eslint-disable-next-line no-nested-ternary
			props?.selectedComponent === props?.id
				? '1px solid orange'
				: props?.currentComponent === props?.id
					? '1px solid red'
					: null,
	};
	return (
		<div className='qr-code' style={qrCodeStyle}>
			<img className='qr-code-img' src={qrCodeImg} alt='qr-code' />
		</div>
	);
}
export default QRCode;
