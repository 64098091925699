// eslint-disable-next-line object-curly-newline
// import { Badge, Button } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { Tune } from '@mui/icons-material';
import {
	IconButton, MenuItem, TextField
} from '@mui/material';
import { ArrowCircleRight } from '@mui/icons-material';
import analyticsAction from '../../../Actions/analytics.actions';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import { useQuery, useSearch } from '../../../Helpers';
import styles from './MySubscriptionsHistory.module.scss';
import { formActions, modalActions } from '../../../Actions';
import MySubscriptionsListTable from './MySubscriptionsListTable';
import OrdersItemsModal from '../../../Components/Modals/OrdersItemsModal/OrdersItemsModal';

const ANALYTICS_TYPE = analyticsTypeConstant.SUBSCRIPTIONS_HISTORY_LIST_DATA;

const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	ORDER_ID: 'Search by Order Id',
	ITEM_ID: 'Search by Item Id',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	STUDENT_NAME: 'studentDetails',
	SUBS_ID: 'id',
	USER_NUMBER: 'user',
};

const SEARCH_BY = {
	options: [
		{
			key: 'STUDENT_NAME',
			title: 'Student Name',
			get: 'StudentName',
		},
		// {
		// key: 'SUBS_ID',
		// title: 'Subs ID',
		// get: 'SubsId',
		// },
		{
			key: 'USER_NUMBER',
			title: 'Number',
			get: 'UserNumber',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const statusList = ['ALL', 'ACTIVE', 'CANCELLED', 'EXPIRED'];

const MySubscriptionsHistory = (props) => {
	const {
		data,
		setQCSearchResults,
		searchQuery,
		searchBy,
		brandData,
		gateway,
		toggleOrdersItemsModal,
		fetchData,
	} = props;
	const [statusFilter, setStatusFilter] = useState('ACTIVE');

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
		if (brandData.length === 0) {
			gateway({
				entity: 'BRANDS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setQCSearchResults('', data, 'ORDER_ID');
		}
	}, [queryParams]);

	const generateData = () => {
		const filteredData = data.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	const handleStatusFiler = (e) => {
		const { value } = e.target;
		setStatusFilter(value);
		fetchData({
			params: {
				status: value !== 'ALL' ? value : null,
			},
		});
	};
	const getData = () => {
		const status = statusFilter;
		fetchData({
			params: {
				status,
			},
		});
	};

	return (
		<div className={styles.root}>
			<OrdersItemsModal />
			<div className={styles.header}>
				<div className={styles['status-search']}>
					<SearchBar
						value={query}
						placeholder={PLACE_HOLDERS[searchField]}
						handleInput={handleSearch}
						handleChange={handleSearchBy}
						searchBy={
							SEARCH_BY != null
								? {
									...(SEARCH_BY ?? {}),
									current: searchField,
								}
								: null
						}
						className={styles.search}
					/>
					<TextField
						select
						label='Status'
						className={styles.status}
						value={statusFilter}
						onChange={handleStatusFiler}
					>
						{statusList.map((value) => {
							return (
								<MenuItem key={value} value={value}>
									{value}
								</MenuItem>
							);
						})}
					</TextField>
					<IconButton
						type='submit'
						className={styles.btn}
						onClick={getData}
					>
						<ArrowCircleRight />
					</IconButton>
				</div>
			</div>
			<MySubscriptionsListTable
				data={generateData()}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				fetchDataAfterSetting={false}
				toggleOrdersItemsModal={toggleOrdersItemsModal}
				status={statusFilter}
			/>
		</div>
	);
};

MySubscriptionsHistory.defaultProps = {};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter, BRAND_FILTER: brandFilter } =
		state.analytics;
	const { LIST_DATA: data } = state.analytics.SUBSCRIPTIONS_HISTORY;
	// console.log('3', data);
	const brandData = state.form.BRANDS.data.LIST;
	const { query: searchQuery, searchBy } =
		state.analytics.WASTAGE_HISTORY.SEARCH_DATA;
	return {
		dateRangeFilter,
		searchQuery,
		data,
		searchBy,
		brandData,
		brandFilter,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setQCSearchResults: analyticsAction.setQCSearchResults,
	fetchData: analyticsAction.fetchData,
	toggleModal: modalActions.toggleFilterModal,
	toggleOrdersItemsModal: modalActions.toggleOrdersItemsModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MySubscriptionsHistory);
