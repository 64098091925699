import { AddHTab } from './AddHTab';
import { DraggableHTab, HTab } from './DraggableHTab';
import styles from './DraggableHTabs.module.scss';

const DraggableHTabs = (props) => {
	const {
		handleReorder,
		items,
		order,
		activeTab,
		handleActiveTab,
		handleAddTab,
		limit,
		isDraggable,
		isDisabled,
		text,
	} = props;
	return (
		<div className={`${styles.root}  overflow-y-hidden`}>
			{order.map((id, idx) => {
				const { name } = items[id];
				if (isDraggable && !isDisabled) {
					return (
						<DraggableHTab
							key={id}
							id={id}
							text={name}
							moveCard={handleReorder}
							index={idx}
							active={id === activeTab}
							handleClick={handleActiveTab}
							isDraggable={isDraggable}
						/>
					);
				}
				return (
					<HTab
						isDraggable={isDraggable}
						key={id}
						id={id}
						text={name}
						index={idx}
						active={id === activeTab}
						handleClick={handleActiveTab}
					/>
				);
			})}
			{order.length < limit && !isDisabled && (
				<AddHTab text={text} handleClick={handleAddTab} />
			)}
		</div>
	);
};

DraggableHTabs.defaultProps = {
	limit: 10,
	isDraggable: true,
	isDisabled: false,
};

export default DraggableHTabs;
