/* eslint-disable */
import { Checkbox } from 'rsuite';
import { useState } from 'react';
import { PictureAsPdf } from '@mui/icons-material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function VendorQouteListTable(props) {
	const { tableData, selectedQuote, setSelectedQuote, editableValues, setEditableValues } = props;
	const handleCheckboxChange = (checked, assetId, quoteId, currentQty, currentPrice, storeId, vendorId, itemId, coreSpecifications, oem, assetType) => {
		setSelectedQuote(prev => {
			const prevQuotes = prev[assetId] || [];
			
			if (checked) {
				return {
					...prev,
					[assetId]: [...prevQuotes, {id: quoteId, itemId: itemId, vendorId: vendorId, storeId: storeId,assetType: assetType, qty: currentQty, price: currentPrice, oem: oem, coreSpecifications: coreSpecifications}]
				};
			} else {
				return {
					...prev,
					[assetId]: prevQuotes.filter(quote => quote.id !== quoteId)
				};
			}
		});
	};

	const handleValueChange = (assetId, quoteId, field, value) => {
		setEditableValues(prev => ({
			...prev,
			[assetId]: {
				...prev[assetId],
				[quoteId]: {
					...(prev[assetId]?.[quoteId] || {}),
					[field]: value
				}
			}
		}));
	};

	return (
		<>
			{tableData?.map((item, index) => (
				<tr
					key={index}
					style={{
						borderBottom: '2px solid #c6c6c6',
						padding: '10px ',
					}}
				>
					<td>{item?.assetName}</td>
					<td className='text-lg'>{item?.quantity}</td>
					{item?.qoute?.length > 0 &&
						Object.entries(
							item.qoute.reduce((acc, val) => {
								const { vendor } = val;
								if (!acc[vendor]) acc[vendor] = [];
								acc[vendor].push(val);
								return acc;
							}, {})
						).map(([vendor, quotes], vendorIndex) => (
							<td key={vendorIndex}>
								<div className="text-left flex flex-col gap-4">
									{quotes.map((val, subIndex) => {
										const {assetType ,itemId, oem, storeId, vendorId, price, qty, id, coreSpecifications } = val;
										console.log(itemId, 'itemId');
										const editedValues = editableValues[item.id]?.[id] || {};
										const currentQty =
											editedValues.qty !== undefined ? editedValues.qty : qty;
										const currentPrice =
											editedValues.price !== undefined ? editedValues.price : price;
										const pricePerUnit = currentPrice / currentQty;

										return (
											<div key={subIndex} className="flex  gap-2 border-b pb-2">
												<Checkbox
													checked={selectedQuote[item.id]?.some(
														(quote) => quote.id === id
													)}
													onChange={(value, checked) =>
														handleCheckboxChange(
															checked,
															item.id,
															id,
															currentQty,
															currentPrice,
															storeId,
															vendorId,
															itemId,
															coreSpecifications,
															oem,
															assetType,
														)
													}
												/>
												<div className="flex flex-col gap-2">
													{qty && (
														<div className="flex gap-2">
															<span>Quantity:</span>
															<input
																type="number"
																value={currentQty}
																onChange={(e) =>
																	handleValueChange(item.id, id, "qty", e.target.value)
																}
																className="w-20 px-1 border border-gray-300 rounded"
															/>
														</div>
													)}
													{price && (
														<div className="flex gap-2">
															<span className="w-[3.4rem]">Price: ₹</span>
															<input
																type="number"
																value={currentPrice}
																onChange={(e) =>
																	handleValueChange(item.id, id, "price", e.target.value)
																}
																className="w-32 px-1 border border-gray-300 rounded"
																/>
														</div>
													)}
													<span className="font-light">
														Price per unit: ₹
														{new Intl.NumberFormat("en-IN").format(
															pricePerUnit.toFixed(2)
														)}
													</span>
													<div>
														{coreSpecifications?.map((spec, specIndex) => (
															<div key={`core-${specIndex}`}>
																<span className="font-light">
																	{spec?.label}: {spec?.value ? spec?.value : "-"}
																</span>
															</div>
														))}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</td>
						))}
				</tr>
			))}
		</>
	);
}

export default VendorQouteListTable;
