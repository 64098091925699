const paymentSettlementConstants = {
	FETCH_PAYMENT_REQUEST: 'FETCH_PAYMENT_REQUEST',
	FETCH_PAYMENT_SUCCESS: 'FETCH_PAYMENT_SUCCESS',
	FETCH_PAYMENT_FAILURE: 'FETCH_PAYMENT_FAILURE',
	SET_DATE: 'SET_DATE',
	FETCH_ORDER_INVOICE_LINK_REQUEST: 'FETCH_ORDER_INVOICE_LINK_REQUEST',
	FETCH_ORDER_INVOICE_LINK_SUCCESS: 'FETCH_ORDER_INVOICE_LINK_SUCCESS',
	FETCH_ORDER_INVOICE_LINK_FAILURE: 'FETCH_ORDER_INVOICE_LINK_FAILURE',
};

export default paymentSettlementConstants;
