/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
import { get } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
// eslint-disable-next-line import/no-extraneous-dependencies
import momentTz from 'moment-timezone';
import stateCityActions from '../../../../Actions/stateCity.actions';
import { generateRequiredProps } from '../../../../Helpers';
// import EnableDisable from '../../EnableDisable/EnableDisable';
import styles from './GeneralDetails.module.scss';
import { notificationServices } from '../../../../Services';
import { Map } from '../../../Maps/Map';
import AppModules from '../../../AppModules/AppModules';

const getLatLng = (map) => {
	if (map) {
		const currLocation = map.getCenter();
		const lat = currLocation.lat();
		const lng = currLocation.lng();
		return {
			latitude: lat,
			longitude: lng,
		};
	}
	return {
		latitude: null,
		longitude: null,
	};
};
const getTimeZonesWithOffsets = () => {
	const usTimeZones = momentTz.tz.zonesForCountry('US');
	const indiaTimeZones = momentTz.tz.zonesForCountry('IN');
	const allTimeZones = momentTz.tz.names(); // Get all time zone names

	const usSpecificTimeZones = allTimeZones.filter((timeZone) => {
		return timeZone.startsWith('US/');
	});

	const usAndIndiaTimeZones = usTimeZones
		.concat(indiaTimeZones)
		.concat(usSpecificTimeZones);

	const timeZonesWithOffsets = usAndIndiaTimeZones.map((timeZone) => {
		const tz = momentTz.tz(timeZone);
		const offsetFromGMT = tz.utcOffset();

		const hours = Math.floor(Math.abs(offsetFromGMT) / 60); // Calculate hours
		const minutes = Math.abs(offsetFromGMT) % 60; // Calculate remaining minutes

		const offsetString = `${offsetFromGMT < 0 ? '-' : '+'}${hours}:${String(
			minutes
		).padStart(2, '0')}`; // Format the offset

		return {
			name: timeZone,
			offsetFromGMT: offsetString,
		};
	});

	return timeZonesWithOffsets;
};

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		isDisabled,
		stateCity,
		updateLocation,
		generateDefaultOption,
		coords,
		storesList,
		handleAppModule,
		handleSelectAllModule,
	} = props;

	const [zoom, setZoom] = useState(14);
	const timeZones = getTimeZonesWithOffsets();

	const handleDragEnd = (map) => {
		if (map) {
			updateLocation(getLatLng(map));
		}
	};

	const handleZoomChange = (map) => {
		if (map) {
			setZoom(map.getZoom());
		}
	};

	const showPosition = (position) => {
		updateLocation({
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
		});
	};
	const onErrorInLocation = () => {
		notificationServices.generateNotification({
			type: 'error',
			message: 'Enable your Location',
		});
	};
	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				showPosition,
				onErrorInLocation
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Geolocation is not supported by this browser.',
			});
		}
	};
	useEffect(() => {
		props.fetchStateCity({
			id: '',
			allData: true,
		});
	}, []);
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='storeName'
						data-mandatory={mandatoryFields.storeName}
					>
						Store Name
					</label>
					<input
						className='form-value'
						type='text'
						id='storeName'
						name='storeName'
						onInput={handleInput}
						value={fields.storeName}
						{...generateRequiredProps(mandatoryFields.storeName)}
					/>
					<span className='form-error'>{errors.storeName}</span>
				</div>
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='clientStoreId'
						data-mandatory={mandatoryFields.clientStoreId}
					>
						Client Store Id
					</label>
					<input
						className='form-value'
						type='text'
						id='clientStoreId'
						name='clientStoreId'
						onInput={handleInput}
						value={fields.clientStoreId}
						{...generateRequiredProps(
							mandatoryFields.clientStoreId
						)}
					/>
					<span className='form-error'>{errors.clientStoreId}</span>
				</div>
				{/* Address1 */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='address'
						data-mandatory={mandatoryFields.address}
					>
						Address
					</label>
					<textarea
						className='form-value'
						type='text'
						id='address'
						name='address'
						onInput={handleInput}
						value={fields.address}
						{...generateRequiredProps(mandatoryFields.address)}
					/>
					<span className='form-error'>{errors.address}</span>
				</div>
				{/* city name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='city'
						data-mandatory={mandatoryFields.city}
					>
						City
					</label>
					<select
						className='form-value'
						id='city'
						name='city'
						onChange={handleInput}
						value={
							fields.city
								? JSON.stringify(fields.city)
								: fields.city
						}
						{...generateRequiredProps(mandatoryFields.city)}
					>
						{!isDisabled &&
							generateDefaultOption('city', fields.city)}
						{stateCity.map((obj) => {
							return (
								<option value={JSON.stringify(obj)}>
									{obj.cityName}
								</option>
							);
						})}
						{/* <option value='1'>Default</option> */}
					</select>
					<span className='form-error'>{errors.city}</span>
				</div>
				{/* longitude */}
				<div className='form-field row'>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.longitude}
					>
						Longitude
					</label>
					<input
						className='form-value'
						type='number'
						value={fields.longitude}
						onChange={(e) => {
							const { value } = e.target;
							if (value === '') {
								handleInput({
									target: {
										name: 'longitude',
										value: null,
									},
								});
								return;
							}
							const longitude = parseFloat(value);
							if (
								Number.isNaN(longitude) ||
								longitude < -180 ||
								longitude > 180
							) {
								errors.longitude = 'Longitude must be between -180 and 180';
								return;
							}
							if (value.length <= 11) {
								handleInput({
									target: {
										name: 'longitude',
										value,
									},
								});
							}
						}}
					/>
					<span className='form-error'>{errors.longitude}</span>
				</div>
				{/* latitude */}
				<div className='form-field row'>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.latitude}
					>
						Latitude
					</label>
					<input
						className='form-value'
						type='number'
						value={fields.latitude}
						onChange={(e) => {
							const { value } = e.target;
							if (value === '') {
								handleInput({
									target: {
										name: 'latitude',
										value: null,
									},
								});
								return;
							}
							const latitude = parseFloat(value);
							if (
								Number.isNaN(latitude) ||
								latitude < -90 ||
								latitude > 90
							) {
								errors.latitude = 'Latitude must be between -90 and 90';
								return;
							}
							if (value.length <= 10) {
								handleInput({
									target: {
										name: 'latitude',
										value,
									},
								});
							}
						}}
					/>
					<span className='form-error'>{errors.latitude}</span>
				</div>
				{/* state name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='state'
						data-mandatory={mandatoryFields.state}
					>
						State
					</label>
					<input
						className='form-value'
						type='text'
						value={get(fields.city, 'stateName', null)}
						readOnly
					/>
					<span className='form-error'>{errors.state}</span>
				</div>
				{/* Address2 */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='pincode'
						data-mandatory={mandatoryFields.pincode}
					>
						Pin/Zip code
					</label>
					<input
						className='form-value'
						type='number'
						id='pincode'
						name='pincode'
						onInput={handleInput}
						value={fields.pincode}
						{...generateRequiredProps(mandatoryFields.pincode)}
					/>
					<span className='form-error'>{errors.pincode}</span>
				</div>
				{/* =================== TIME ZONE ================= */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='pincode'
						data-mandatory={mandatoryFields.timeZone}
					>
						Time Zone
					</label>
					<select
						className='form-value'
						id='storeType'
						name='timezone'
						onChange={handleInput}
						value={fields.timezone}
						{...generateRequiredProps(mandatoryFields.timezone)}
					>
						<option value=''>Select a timezone</option>
						{timeZones?.map((ele) => {
							return (
								<option key={ele?.name} value={ele?.name}>
									{ele?.name}
									(
									{ele?.offsetFromGMT}
									)
								</option>
							);
						})}
						{/* <option value='1'>Default</option> */}
					</select>
					<span className='form-error'>{errors.timezone}</span>
				</div>
				{/* ============= Store Type ================ */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='storeType'
						data-mandatory={mandatoryFields.city}
					>
						Store Type
					</label>
					<select
						className='form-value'
						id='storeType'
						name='type'
						onChange={handleInput}
						value={fields.type}
						{...generateRequiredProps(mandatoryFields.type)}
					>
						<option value=''>Select a store type</option>
						{[
							'BASE',
							'RETAIL',
							'WAREHOUSE',
							'WAREHOUSE_PRODUCT',
							'WAREHOUSE_INGREDIENT',
						].map((ele) => {
							return (
								<option key={ele} value={ele}>
									{ele?.split('_')?.join(' ')}
								</option>
							);
						})}
						{/* <option value='1'>Default</option> */}
					</select>
					<span className='form-error'>{errors.type}</span>
				</div>
				{/* ============= Store Sub Type ================ */}
				{fields.type === 'RETAIL' && (
					<div className='form-field row'>
						<label
							className='form-label'
							data-mandatory={mandatoryFields.subType}
						>
							Store Sub Type
						</label>
						<select
							className='form-value'
							id='storeSubType'
							name='subType'
							onChange={handleInput}
							value={fields.subType}
						>
							<option value=''>Select a store sub type</option>
							{['DARK_STORE', 'FC'].map((ele) => {
								return (
									<option key={ele} value={ele}>
										{ele === 'DARK_STORE'
											? 'Dark Store'
											: ele?.split('_')?.join(' ')}
									</option>
								);
							})}
						</select>
						<span className='form-error'>{errors.subType}</span>
					</div>
				)}
				{/* ============= Store Sub Type END ================ */}
				{/* ============= Store Type END ================ */}
				{/* ============= Dispatch Lead Days ================ */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='dispatchLeadDays'
						data-mandatory={mandatoryFields.dispatchLeadDays}
					>
						Order Lead Days
					</label>
					<input
						className='form-value'
						type='number'
						id='dispatchLeadDays'
						name='dispatchLeadDays'
						onInput={handleInput}
						value={fields.dispatchLeadDays}
						{...generateRequiredProps(
							mandatoryFields.dispatchLeadDays
						)}
					/>
					<span className='form-error'>
						{errors.dispatchLeadDays}
					</span>
				</div>
				{/* ============= Dispatch Lead Days END ================ */}
				{/* ============= Parent Store ================ */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='parentStore'
						data-mandatory={mandatoryFields.parentStore}
					>
						Parent Store
					</label>
					<select
						className='form-value'
						id='parentStore'
						name='parentStore'
						onChange={handleInput}
						value={fields.parentStore}
						{...generateRequiredProps(mandatoryFields.parentStore)}
					>
						<option key={-1} value={null}>
							Not Selected
						</option>
						{storesList.map((store) => {
							return (
								<option key={store?.id} value={store?.id}>
									{store?.name}
								</option>
							);
						})}
						{/* <option value='1'>Default</option> */}
					</select>
					<span className='form-error'>{errors.parentStore}</span>
				</div>
				{ /* ===================== Distance From Store ============ */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='distance'
						data-mandatory={mandatoryFields.distance}
					>
						Distance From Parent Store(in Km)
					</label>
					<input
						className='form-value'
						type='number'
						id=' distance'
						name='distance'
						onInput={handleInput}
						value={fields.distance}
						{...generateRequiredProps(
							mandatoryFields.distance
						)}
					/>
					<span className='form-error'>{errors.clientStoreId}</span>
				</div>
				{/* ============= Parent Store END ================ */}
				{/* ============= App Modules ================ */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='appModules'
						data-mandatory={mandatoryFields.appModules}
					>
						App Modules
					</label>
					<AppModules
						type='store'
						selectedList={fields?.appModules}
						handleAppModule={handleAppModule}
						handleAllSelection={handleSelectAllModule}
						handleRemoveAll={handleSelectAllModule}
					/>
					<span className='form-error'>{errors?.appModules}</span>
				</div>
				{/* ============= App Modules END ================ */}
				{/* order placement number */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='orderplacementnumber'
						data-mandatory={mandatoryFields.orderplacementnumber}
					>
						Order Placement Number
					</label>
					<input
						className='form-value'
						type='text'
						id='orderplacementnumber'
						name='orderplacementnumber'
						onInput={handleInput}
						value={fields.orderplacementnumber}
						{...generateRequiredProps(
							mandatoryFields.orderplacementnumber
						)}
					/>
					<span className='form-error'>
						{errors.orderplacementnumber}
					</span>
				</div>
				{/* Support Number */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='support'
						data-mandatory={mandatoryFields.support}
					>
						Support Number
					</label>
					<input
						className='form-value'
						type='number'
						id='support'
						name='support'
						onInput={handleInput}
						value={fields.support}
						{...generateRequiredProps(mandatoryFields.support)}
					/>
					<span className='form-error'>{errors.support}</span>
				</div>
				{/* GST check */}
				{/* <div className='form-field row'>
					<EnableDisable
						name='gstCheck'
						text='Enable GST'
						isEnabled={fields.gstCheck}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'gstCheck',
						}}
					/>
					<span className='form-error'>{errors.gstCheck}</span>
				</div> */}
				{/* GST no. */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='GSTno'
						data-mandatory={mandatoryFields.GSTno}
					>
						GST Number
					</label>
					<input
						className='form-value'
						type='text'
						id='GSTno'
						name='GSTno'
						onInput={handleInput}
						value={fields.GSTno}
						{...generateRequiredProps(mandatoryFields.GSTno)}
					/>
					<span className='form-error'>{errors.GSTno}</span>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* geolocation image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.image}
					>
						Geolocation
					</label>
					<div className='display-pic-root'>
						<div className={styles['map-container']}>
							<Map
								center={coords}
								zoom={zoom}
								onDragEnd={handleDragEnd}
								onZoomChange={handleZoomChange}
								draggable={!isDisabled}
								height={218}
							/>
						</div>
						{isDisabled ? (
							<></>
						) : (
							<Button
								variant='contained'
								className={styles['btn-location']}
								onClick={getLocation}
							>
								Locate me
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	draggable: true,
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
	updateLocation: () => {},
	handleAppModule: () => {},
};
const mapStateToProps = (state) => {
	const { stateCity } = state.stateCity;
	const storesList = state.form.STORES.data.LIST;
	return {
		stateCity,
		storesList,
	};
};

const mapDispatchToProps = {
	fetchStateCity: stateCityActions.fetchStateCity,
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
