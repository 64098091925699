/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../Components/SubHeader/SubHeader';
import analyticsTypeConstant from '../../Constants/analyticsType.contants';
import { getDateFilterString } from '../../Helpers';
import { analyticsAction } from '@/Actions';
import {
	Tabs, TabsList, TabsTrigger
} from '@/Components/ui/tabs';
import AuditAnalysisListPage from './AuditAnalysisListPage';
import styles from './PastAuditAnalysisHistoryPage.module.scss';

const ANALYTICS_TYPE = analyticsTypeConstant.AUDIT_ANALYTICS_LIST_DATA;

const dataTypes = [
	{
		label: 'Product/Intermediate',
		value: 'BOM',
	},
	{
		label: 'Ingredient',
		value: 'BOM_INGREDIENT',
	},
];

const PastAuditAnalysisHistoryPage = (props) => {
	const {
		breadcrumbs,
		DATE_FILTER,
		data,
		analysisFilters,
		setAnalysisDashboardFilters,
	} = props;
	const selectedDateString = useMemo(() => {
		return getDateFilterString(DATE_FILTER);
	}, [DATE_FILTER]);

	useEffect(() => {
		setAnalysisDashboardFilters(
			{
				...analysisFilters,
				DATA_TYPE: 'BOM_INGREDIENT',
			},
			false
		);
	}, []);

	const handleDataType = (value) => {
		setAnalysisDashboardFilters({
			...analysisFilters,
			DATA_TYPE: value,
		});
	};

	return (
		<div className={styles.root}>
			<SubHeader
				breadcrumbs={breadcrumbs}
				showBreadcrumbs
				selectedDateString={selectedDateString}
			/>
			{/* <div className='pt-4 px-2'>
				<Tabs
					value={analysisFilters?.DATA_TYPE}
					onValueChange={handleDataType}
					defaultValue={analysisFilters?.DATA_TYPE}
				>
					<TabsList className=''>
						{dataTypes?.map((ele) => {
							return (
								<TabsTrigger
									className='data-[state=active]:bg-uy-color'
									value={ele?.value}
								>
									{ele?.label}
								</TabsTrigger>
							);
						})}
					</TabsList>
				</Tabs>
			</div> */}
			<AuditAnalysisListPage data={data} ANALYTICS_TYPE={ANALYTICS_TYPE} />
		</div>
	);
};
const mapStateToProps = (state) => {
	const { DATE_FILTER, ANALYSIS_DASHBOARD_FILTERS } = state.analytics;
	const { LIST_DATA: data } = state.analytics.AUDIT_ANALYTICS;
	return {
		DATE_FILTER: DATE_FILTER.selection,
		data,
		analysisFilters: ANALYSIS_DASHBOARD_FILTERS,
	};
};
const mapDispatchToProps = {
	setAnalysisDashboardFilters: analyticsAction.setAnalysisDashboardFilters,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PastAuditAnalysisHistoryPage);
