import { cloneDeep, isEmpty } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useMemo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { BoxAnnotator } from '../../../Components/Annotation/BoxAnnotator';
import config from './config';
import {
	annotationServices,
	liveIMSServices,
	notificationServices,
} from '../../../Services';
import {
	history,
	inputHelper,
	useClientRect,
	useImageDimensionsRef,
	useQuery,
} from '../../../Helpers';
import { RouterPromptModal } from '../../../Components/Modals';
import { Header } from '../../../Components/Annotation/AnnotatorHeader';
import { urls } from '../../../Constants';
import {
	analyticsAction,
	retagActions,
	annotationActions,
} from '../../../Actions';
import useImageDimensionState from '../../../Helpers/useImageDimensionState';

const initialState = {
	imsData: {},
};

const IMSAnnotationPage = (props) => {
	const {
		listData,
		fetchIMSAnnotations,
		imsAnnotations,
		products,
		fetchProducts,
		defects,
		fetchDefects,
		userPermissions,
		updateIMSList,
	} = props;

	// qr code
	const { index } = useParams();
	const { ANNOTATE = false } = userPermissions;

	const query = useQuery();
	const scanId = query.get('id');
	const mode = query.get('ctx');

	const listIndex = useMemo(() => {
		const parsedIndex =
			index?.split('_')?.length > 1 ? index : parseInt(index, 10);
		return listData?.findIndex((scan) => {
			return (
				parseInt(scan.qrCode, 10) === parsedIndex &&
				scan.id === parseInt(scanId, 10)
			);
		});
	}, [index, scanId]);

	const [imageContainerRect, imageContainerRef] = useClientRect();
	const imageDimensions = useImageDimensionState(imsAnnotations?.image);

	const [isBlockingNavigation, setIsBlockingNavigation] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const [imsData, setIMSData] = useState(initialState);

	const { brand: brandId, skuId: productId } = imsData;

	const [annotations, setAnnotations] = useState([]);
	const [newAnnotation, setNewAnnotation] = useState([]);

	const scanImageDimension = useImageDimensionsRef(imsAnnotations?.image);

	const handleBack = useCallback(() => {
		history.push(`${urls.EXTENSION}${urls.PAST_IMS}/${index}?id=${scanId}`);
	}, [scanId]);

	const navigateScan = useCallback(
		(e) => {
			const { dataset } = inputHelper(e);

			let newListItem = null;

			if (dataset.type === 'next') {
				newListItem = listData?.[listIndex + 1];
			} else {
				newListItem = listData?.[listIndex - 1];
			}

			history.push(
				`${urls.EXTENSION}${urls.IMS_ANNOTATION}/${newListItem?.qrCode}?id=${newListItem?.id}&ctx=${mode}`
			);
		},
		[listIndex]
	);

	useEffect(() => {
		if (mode === 'IDENTIFICATION') {
			if (isEmpty(products)) {
				fetchProducts();
			}
		} else if (mode === 'FEATURES') {
			if (brandId != null && productId != null) {
				fetchDefects({
					params: {
						brandId,
						productId,
					},
				});
			}
		}
	}, [mode, brandId, productId]);

	const labels = useMemo(() => {
		if (mode === 'IDENTIFICATION') {
			return Object.keys(products ?? {}).map((pId, idx) => {
				const productName = products?.[pId];
				return {
					ID: pId,
					id: idx,
					title: productName,
				};
			});
		}
		if (mode === 'FEATURES') {
			return defects?.map((defect, idx) => {
				return {
					ID: defect.id,
					id: idx,
					title: defect.label,
				};
			});
		}
		return [];
	}, [mode, products, defects]);

	useEffect(() => {
		if (labels.length > 0) {
			let currentAnnotations = [];
			if (mode === 'IDENTIFICATION') {
				currentAnnotations = imsAnnotations?.identification;
			} else if (mode === 'FEATURES') {
				currentAnnotations = imsAnnotations?.features;
			}
			setAnnotations(
				config.serializer({
					annotations: currentAnnotations ?? [],
					imageDimensions,
					imageContainerRect: imageContainerRect(),
					labels,
				})
			);
		}
	}, [index, imsAnnotations, imageDimensions, labels]);

	const getPastIMSData = useCallback(async () => {
		try {
			const req = {
				params: {
					qrCode: index,
				},
			};

			const response = await liveIMSServices.getPastData(req);
			updateIMSList(listIndex > -1 ? listIndex : 0, response?.data ?? {});
			return response?.data ?? {};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			return {};
		}
	}, [listIndex]);

	useEffect(() => {
		(async () => {
			let currentScan = listData?.[listIndex] ?? null;

			if (currentScan == null) {
				const data = await getPastIMSData();
				currentScan = config.stateSerializer(data);
			}
			setIMSData(config.stateSerializer(currentScan));
			fetchIMSAnnotations(currentScan.id);
			if (currentScan.validation) {
				setIsDisabled(false);
			} else {
				notificationServices.generateNotification({
					type: 'warning',
					message: 'The item needs to be validated first.',
				});
				setIsDisabled(true);
			}
		})();
	}, [index, scanId]);

	const handleSubmit = async (
		finalAnnotations,
		imageDimensionsOg,
		imageDimensionsNew
	) => {
		const isValid = config.validator(finalAnnotations);

		if (isValid) {
			const req = config.deserializer({
				annotations,
				imageDimensionsOg,
				imageDimensionsNew,
				mode,
				scanId,
			});

			try {
				const response = await annotationServices.submitAnnotations(
					req
				);
				const clonedItem = cloneDeep(listData?.[listIndex]);
				if (mode === 'FEATURES') {
					clonedItem.features = req.data.features;
				} else if (mode === 'IDENTIFICATION') {
					clonedItem.identification = req.data.identification;
				}
				updateIMSList(listIndex, clonedItem);
				const { message } = response;
				notificationServices.generateNotification({
					type: 'success',
					message,
				});
				setIsBlockingNavigation(false);
				return true;
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					error,
				});
				return false;
			}
		}
		return false;
	};

	const saveAndRedirect = async () => {
		const canRedirect = await handleSubmit(
			annotations,
			scanImageDimension.current,
			imageContainerRect()
		);
		return canRedirect;
	};

	const cancelAndRedirect = () => {
		setIsBlockingNavigation(false);
		return true;
	};

	const updateAnnotations = (newState) => {
		setAnnotations(newState);
		setIsBlockingNavigation(newState.length > 0);
	};

	if (ANNOTATE === false) {
		return (
			<Redirect
				to={`${urls.EXTENSION}${urls.PAST_IMS}/${index}?id=${scanId}`}
			/>
		);
	}

	return (
		<div>
			<Header
				listData={listData}
				listIndex={listIndex}
				fields={{
					center: {
						label: 'QR Code',
						value: imsData.qrCode,
					},
					right: {
						label: 'Stock In At',
						value: imsData.stockedInAt,
					},
				}}
				handleBack={handleBack}
				navigate={navigateScan}
			/>
			<BoxAnnotator
				disabled={isDisabled && false}
				image={imsAnnotations?.image}
				handleSubmit={handleSubmit}
				imageDimensionsOg={scanImageDimension.current}
				imageContainerRect={imageContainerRect()}
				imageContainerRef={imageContainerRef}
				annotations={annotations}
				setAnnotations={updateAnnotations}
				newAnnotation={newAnnotation}
				setNewAnnotation={setNewAnnotation}
				brandId={imsData.brandId}
				labels={labels}
				limit={mode === 'IDENTIFICATION' ? 1 : null}
				heading={
					mode === 'IDENTIFICATION'
						? 'Product Tagging'
						: 'Item Tagging'
				}
				subHeading={
					mode === 'IDENTIFICATION' ? '' : `(${imsData.skuName})`
				}
				// modalHeading={`Select feature for ${imsData.identification?.[0]?.label}`}
				modalHeading={`Select feature for ${imsData.skuName}`}
				allowEdit={mode === 'FEATURES'}
				allowDelete={mode === 'FEATURES'}
			/>
			<RouterPromptModal
				when={isBlockingNavigation}
				title='Save before leaving?'
				okText='Save'
				cancelText="Don't Save"
				message='You have unsaved changes, are you sure you want to leave?'
				onOK={saveAndRedirect}
				onCancel={cancelAndRedirect}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { list: listData } = state.analytics.INVENTORY_ANALYTICS.SEARCH_DATA;
	const { imsAnnotations, products } = state.annotation;
	const { retagReasons: defects = [] } = state.retag;
	const { userPermissions } = state.navigation;
	return {
		listData,
		imsAnnotations,
		defects,
		products,
		userPermissions,
	};
};

const mapDispatchToProps = {
	fetchIMSAnnotations: annotationActions.fetchIMSAnnotations,
	fetchProducts: annotationActions.fetchProducts,
	fetchDefects: retagActions.fetchRetagReasons,
	updateIMSList: analyticsAction.updateIMSList,
};

IMSAnnotationPage.defaultProps = {
	mode: 'IDENTIFICATION',
};

export default connect(mapStateToProps, mapDispatchToProps)(IMSAnnotationPage);
