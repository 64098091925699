// eslint-disable-next-line object-curly-newline
import { getDate, getWeekOfMonth } from 'date-fns';
import { timeFilterMap } from '../../Constants';
import {
	classes, inputHelper, ordinalSuffixOf
} from '../../Helpers';
import styles from './TimeFilter.module.scss';

const TimeFilter = (props) => {
	const {
		timeFilters,
		onClick,
		className,
		graphYear = new Date().getFullYear(),
	} = props;

	const handleClick = (event) => {
		const { dataset } = inputHelper(event);
		const { type } = dataset;
		if (graphYear === new Date().getFullYear()) {
			onClick(type);
		} else {
			const date = new Date();
			date.setFullYear(graphYear);
			onClick(type, {
				year: graphYear,
				month: timeFilterMap.month[date.getUTCMonth()],
				day: getDate(date),
				hour: null,
				week: getWeekOfMonth(date),
			});
		}
	};

	return (
		<div className={classes(styles.root, className)}>
			{timeFilters.map((timeFilter) => {
				const { type, text, active } = timeFilter;
				return (
					<div
						key={type}
						className={classes(
							styles.filter,
							active ? styles.active : ''
						)}
						onClick={handleClick}
						data-type={timeFilter.type}
					>
						<span className={classes(styles.text)}>
							{type === 'day' && text !== 'DTD'
								? `${ordinalSuffixOf(text)}`
								: text}
						</span>
					</div>
				);
			})}
		</div>
	);
};

TimeFilter.defaultProps = {
	timeFilters: [],
	onClick: () => {},
	className: '',
};

export default TimeFilter;
