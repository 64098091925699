import { connect } from 'react-redux';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import {
	inputHelper,
	isEmpty,
	history,
	getUnitConvertedUnit,
} from '../../../Helpers';
import {
	VariantsTable,
	GeneralDetails,
} from '../../../Components/Forms/Variants';
import variantsConfig from './variants.config';
import { CreatePageHeader } from '../../../Components/Headers';
import styles from './variants.module.scss';
import { notificationServices } from '../../../Services';
import { formActions } from '../../../Actions';
import { urls } from '../../../Constants';
import { useAuth } from '../../../Components/Auth/Login/auth';

const VariantsCreatePage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const [state, setState] = useState({
		...variantsConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	// eslint-disable-next-line object-curly-newline
	const {
		limit,
		isDisabled,
		data,
		fetchVariant,
		isEdit,
		saveVariant,
		breadcrumbs,
		fetchSubCategories,
		subCategoriesList,
	} = props;
	const { id } = useRouteMatch().params;
	useEffect(() => {
		fetchVariant({
			entity: 'VARIANTS',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
			showNotification: true,
		});
	}, []);

	useEffect(() => {
		if (data?.categoryId) {
			fetchSubCategories({
				entity: 'PRODUCT_SUB_CATEGORY',
				req: {
					params: {
						categoryId: data?.categoryId,
					},
				},
				method: 'LIST',
			});
		}
	}, [data?.categoryId]);

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(
				variantsConfig.serializer(state, {
					...data,
					category: subCategoriesList?.find(
						(ele) => ele?.categoryId === data?.categoryId
					)?.categoryName,
					subCategory: subCategoriesList?.find(
						(ele) => ele?.categoryId === data?.categoryId
					)?.name,
					measurement,
				})
			);
		}
	}, [data, subCategoriesList]);
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		setState(
			_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
				const newState = variants.filter((variant, idx) => {
					// eslint-disable-next-line eqeqeq
					return idx != dataset.id;
				});
				return newState;
			})
		);
	};
	const addNewSize = () => {
		const purchaseUnit = state?.fields?.purchaseUnit;
		const unit = state?.fields?.unit;
		if (
			(purchaseUnit == null && unit !== 'count') ||
			(purchaseUnit != null && purchaseUnit !== 'count')
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Multiple Variants not allowed for this product ',
			});
			return;
		}
		setState(
			_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
				const newArr = variants;
				if (newArr.length >= limit) {
					notificationServices.generateNotification({
						type: 'error',
						message: `Can't add more than ${limit} Variants`,
					});
				} else {
					const newVar = _.cloneDeep(variantsConfig.newVariant);
					// eslint-disable-next-line max-len
					newVar.costPriceUnit = getUnitConvertedUnit(
						1,
						state?.fields?.purchaseUnit || state?.fields?.unit
					);
					newVar.transferPriceUnit = getUnitConvertedUnit(
						1,
						state?.fields?.unit
					);
					newVar.sellingPriceUnit = getUnitConvertedUnit(
						1,
						state?.fields?.unit
					);
					newArr.push(newVar);
				}
				return newArr;
			})
		);
	};
	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		if (fieldName === 'isDefault') {
			setState(
				_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
					let newArr = variants;
					newArr = newArr.map((currVariant) => {
						if (currVariant.isDefault) {
							// eslint-disable-next-line no-param-reassign
							currVariant.isDefault = false;
						}
						return currVariant;
					});
					newArr[dataset.id].servingQtyPercentage = 100;
					newArr[dataset.id].isDefault = fieldValue;
					return newArr;
				})
			);
		} else if (fieldName === 'pieces') {
			const reg = /^[\d,]+$/;
			if (reg.test(fieldValue)) {
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.variants[${dataset.id}][${fieldName}]`,
						() => {
							if (fieldName === 'isDisabled') return !fieldValue;
							return fieldValue;
						}
					)
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Only Comma and Number values are allowed',
				});
			}
		} else {
			setState(
				_.update(
					_.cloneDeep(state),
					`fields.variants[${dataset.id}][${fieldName}]`,
					() => {
						if (fieldName === 'isDisabled') return !fieldValue;
						return fieldValue;
					}
				)
			);
		}
	};
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = {
			variants: variantsConfig.deserializer(state).fields.variants,
		};
		setNumberOfSubmissions(numberOfSubmissions + 1);
		if (variantsConfig.validator(req)) {
			req.params = {
				id,
			};
			saveVariant({
				entity: 'VARIANTS',
				req,
				method: 'UPDATE',
				redirect: `${urls.EXTENSION}${urls.VARIANTS}`,
			});
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.VARIANTS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.VARIANTS}`);
		}
	};

	const handleToggle = (value, name, variantIdx) => {
		setState(
			_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
				const newArr = variants;
				newArr[variantIdx][name] = value;
				return newArr;
			})
		);
	};

	return (
		<div className={`${styles.root}`}>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Edit Variants'
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name} Variants`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>

				<FormSectionHeader
					tooltip='ProductDetails'
					sectionName='Product Details'
				>
					<GeneralDetails fields={state.fields} />
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Variant Details'
					sectionName='Variant Details'
				>
					<div className={`${styles['table-btn-container']}`}>
						{!isDisabled && (
							<div className={`${styles['add-btn']}`}>
								<Button
									variant='outlined'
									color='primary'
									onClick={addNewSize}
								>
									Add New Variant
								</Button>
							</div>
						)}
						<VariantsTable
							handleInput={handleInput}
							state={state.fields}
							handleDelete={handleDelete}
							addNewSize={addNewSize}
							isDisabled={isDisabled}
							handleToggle={handleToggle}
						/>
					</div>
				</FormSectionHeader>
			</form>
		</div>
	);
};
VariantsCreatePage.defaultProps = {
	limit: 10,
};
const mapStateToProps = (state) => {
	const data = state.form.VARIANTS.data.VIEW;
	const subCategoriesList = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	return {
		data,
		subCategoriesList,
	};
};
const mapDispatchToProps = {
	saveVariant: formActions.gateway,
	fetchVariant: formActions.gateway,
	fetchSubCategories: formActions.gateway,
};
export default connect(mapStateToProps, mapDispatchToProps)(VariantsCreatePage);
