import { cloneDeep, update } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import ProductDetails from '../../../Components/Forms/Products/ProductDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import config from './inventory-config.config';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import { inputHelper, isEmpty } from '../../../Helpers';
import { urls } from '../../../Constants';
import { formActions } from '../../../Actions';
import { InventoryConfigDetails } from '../../../Components/Forms/Inventory';

const InventoryConfigEditPage = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { isEdit, saveProduct, fetchProduct, productsMap, breadcrumbs } =
		props;
	const [state, setState] = useState({
		...config.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const [activeVariant, setActiveVariant] = useState(0);

	const { id } = useParams();

	const data = useMemo(() => {
		return productsMap[id];
	}, [id, productsMap]);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === id) {
				setState(config.serializer(state, data));
			} else {
				fetchProduct({
					entity: 'INVENTORY_CONFIG',
					req: {
						params: {
							id,
							combo: true,
						},
					},
					method: 'LIST',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(config.serializer(state, data));
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		const newState = {};

		switch (dataset.type) {
			case 'variant':
				newState.fields = update(
					cloneDeep(state.fields),
					'variants.items',
					(variantItems) => {
						// eslint-disable-next-line no-param-reassign
						variantItems[dataset.id][fieldName] = fieldValue;
						return variantItems;
					}
				);
				break;
			default:
				newState.fields = update(
					cloneDeep(state.fields),
					fieldName,
					() => {
						return fieldValue;
					}
				);
				break;
		}

		setState({
			...state,
			...newState,
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const isValid = config.validator(state);
		if (isValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			config.deserializer(state).then((req) => {
				if (isEdit) {
					req.params = {
						id,
					};
					saveProduct({
						entity: 'INVENTORY_CONFIG',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.INVENTORY_CONFIG}`,
					});
				} else {
					saveProduct({
						entity: 'INVENTORY_CONFIG',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.INVENTORY_CONFIG}`,
					});
				}
			});
		}
	};
	const generateDefaultOption = (type, brand, category) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			case 'category':
				if (brand) {
					if (!category || category.length === 0) {
						return (
							<option value={null} selected disabled hidden>
								Select a Category
							</option>
						);
					}
				} else {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand First
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const history = useHistory();
	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.INVENTORY_CONFIG}/view/${id}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.INVENTORY_CONFIG}`);
		}
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Product' : 'Create Product'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='Basic details about the product'
					sectionName='General Details'
				>
					<ProductDetails
						mandatoryFields={config.mandatoryFields}
						fields={state.fields}
						generateDefaultOption={generateDefaultOption}
						handleInput={handleInput}
						isDisabled
						exclude={{
							price: true,
							category: true,
							brand: true,
							veg: true,
							productUnits: true,
						}}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Details required for inventory management of the product'
					sectionName='Inventory Paramters'
				>
					<InventoryConfigDetails
						mandatoryFields={config.mandatoryFields}
						fields={state.fields}
						activeVariant={activeVariant}
						setActiveVariant={setActiveVariant}
						handleInput={handleInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const productsMap = state.form.INVENTORY_CONFIG.data.LIST;
	return {
		productsMap,
	};
};
const mapDispatchToProps = {
	saveProduct: formActions.gateway,
	fetchProduct: formActions.gateway,
};
InventoryConfigEditPage.defaultProps = {
	limit: 3,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryConfigEditPage);
