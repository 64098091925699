import { connect } from 'react-redux';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormSectionHeader } from '../../../Components/Forms';
import CustomerDetails from '../../../Components/Forms/Request Refund/Customer Details/CustomerDetails';
import ItemsTable from '../../../Components/Forms/Request Refund/Customer Details/ItemsTable';
import PaymentDetails from '../../../Components/Forms/Request Refund/Customer Details/PaymentDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import requestRefundConfig from './requestRefundConfig';
import RaiseRequestRefundTable from '../../../Components/Forms/Request Refund/Raise Refund Request/RaiseRequestRefundTable';
import styles from './RequestRefundCreatePage.module.scss';
import RaiseRefundRequestInputSection from '../../../Components/Forms/Request Refund/Raise Refund Request/RaiseRefundRequestInputSection';
// eslint-disable-next-line object-curly-newline
import { history, imageToBase64, inputHelper, isEmpty } from '../../../Helpers';
import { formActions, refundsActions } from '../../../Actions';
import { urls } from '../../../Constants';
import { notificationServices } from '../../../Services';
import SearchBar from '../../../Components/SearchBar/SearchBar';

const RequestRefundCreatePage = (props) => {
	const {
		data,
		requestRefund,
		placeholder,
		fetchRefund,
		setRefundInitialState,
		error,
	} = props;
	const [state, setState] = useState({
		...requestRefundConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	useEffect(() => {
		return () => {
			setRefundInitialState();
		};
	}, [error]);
	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState({
				...requestRefundConfig.serializer(state, data),
			});
		}
	}, [data]);
	const changeRefundDetails = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.refundDetails[${fieldName}]`,
				() => {
					return fieldValue;
				}
			)
		);
	};
	const handleFileInput = (e) => {
		const file = e.target.files[0];
		setState(
			_.update(_.cloneDeep(state), 'fields.refundDetails.image', () => {
				return file;
			})
		);
	};
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = requestRefundConfig.deserializer(state).fields;
		if (req.data.recommendedRefundAmount && req.data.refundReason) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			if (req.data.image) {
				imageToBase64(req.data.image).then((base64Image) => {
					req.data.image = base64Image;
					requestRefund({
						entity: 'REQUEST_REFUND',
						req: {
							...req,
							params: {
								orderId: state.fields.orderId,
							},
						},
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.REQUEST_REFUNDS}`,
					});
				});
			} else {
				requestRefund({
					entity: 'REQUEST_REFUND',
					req: {
						...req,
						params: {
							orderId: state.fields.orderId,
						},
					},
					method: 'CREATE',
					redirect: `${urls.EXTENSION}${urls.REQUEST_REFUNDS}`,
				});
			}
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Refund amount or Refund Reason cannot be blank',
			});
		}
	};
	const handleCancel = () => {
		history.push(`${urls.EXTENSION}${urls.REQUEST_REFUNDS}`);
	};
	const { fields } = state;
	const handleInput = (query) => {
		setState({
			...state,
			query,
		});
	};
	const handleOrderIdSearch = () => {
		fetchRefund({
			entity: 'REQUEST_REFUND',
			req: {
				params: {
					orderId: state.query,
				},
			},
			method: 'VIEW_REFUND',
		});
	};
	return (
		<div
			className={`${styles.root} page`}
			style={{
				maxWidth: '100%',
			}}
		>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Request Refund'
					handleCancel={handleCancel}
				/>
				<div className={`${styles['search-bar-container']}`}>
					<div className='search-bar'>
						<SearchBar
							handleInput={handleInput}
							placeholder={placeholder}
							handleSearch={handleOrderIdSearch}
						/>
					</div>
				</div>
				{data.orderId && (
					<div className='row'>
						<div
							style={{
								flexBasis: '65%',
								width: '65%',
							}}
						>
							<FormSectionHeader
								sectionName='Customer Details'
								tooltip='Customer Details'
							>
								<CustomerDetails
									fields={state.fields.customerDetails}
								/>
							</FormSectionHeader>
							<FormSectionHeader
								sectionName='Payment Details'
								tooltip='Payment Details'
							>
								<PaymentDetails
									fields={state.fields.paymentDetails}
									promoDetails={state.fields.promoDetails}
								/>
							</FormSectionHeader>
							<FormSectionHeader
								sectionName='Items'
								tooltip='Items'
							>
								<ItemsTable order={fields.items} />
							</FormSectionHeader>
							<FormSectionHeader
								sectionName='Refund Status'
								tooltip='Refund Status'
							>
								<RaiseRequestRefundTable
									orders={
										state.fields.raiseRefundRequestState
									}
								/>
							</FormSectionHeader>
						</div>
						<div
							style={{
								flexBasis: '35%',
								width: '35%',
							}}
							className={`${styles['input-wrapper']}`}
						>
							<FormSectionHeader
								sectionName='Raise Refund Request'
								tooltip='Raise Refund Request'
							>
								<RaiseRefundRequestInputSection
									changeRefundDetails={changeRefundDetails}
									refundDetails={state.fields.refundDetails}
									handleFileInput={handleFileInput}
								/>
							</FormSectionHeader>
						</div>
					</div>
				)}
			</form>
		</div>
	);
};
RequestRefundCreatePage.defaultProps = {
	placeholder: 'Search On Order Id',
};
const mapStateToProps = (state) => {
	const data = state.form.REQUEST_REFUND.data.VIEW_REFUND;
	const { error } = state.form.REQUEST_REFUND;
	return {
		data,
		error,
	};
};
const mapDispatchToProps = {
	fetchRefund: formActions.gateway,
	requestRefund: formActions.gateway,
	setRefundInitialState: refundsActions.setRefundsData,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RequestRefundCreatePage);
