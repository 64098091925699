/* eslint-disable linebreak-style */
import { useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { AllVendorListPage, VendorCreatePage } from '../../Pages';
import CRUDRoutes from '../CRUD Routes/CRUD.routes';

const VendorRoutes = ({ type }) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.VENDORS}`,
			name: 'Vendors',
		},
	];
	return (
		<CRUDRoutes
			entity='VENDORS'
			type={type === 'VENDOR' ? 'VENDOR' : 'BUYER'}
			path={path}
			listPage={AllVendorListPage}
			// viewPage={<VendorViewPage breadcrumbs={breadcrumbs} />}
			createPage={<VendorCreatePage breadcrumbs={breadcrumbs} />}
			editPage={<VendorCreatePage isEdit breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default VendorRoutes;
