/* eslint-disable no-tabs */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
// import { useHistory, useRouteMatch } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { modalActions } from '../../../Actions';
// import { inputHelper } from '../../../Helpers';
import { BaseModal } from '../BaseModal';
import { urls } from '../../../Constants';
import styles from './MenuModal.module.scss';

const MenuModal = (props) => {
	const { toggleMenuModal, show, data, toggleMenuList } = props;
	const initialState = {
		data: '',
	};
	const [state, setState] = useState({
		...initialState,
	});
	useEffect(() => {
		setState(data);
	}, [data]);
	const handleClose = () => {
		setState({
			...initialState,
		});
		toggleMenuModal(false);
	};
	const { url } = useRouteMatch();
	const history = useHistory();
	const navigateToItem = (id) => {
		return () => {
			handleClose();
			history.push(`${url}/edit/${id}`);
		};
	};
	const openlistModal = () => {
		const menu = {
			id: state.id,
			idx: data.idx,
			name: state.name,
			menuType: data?.menuType,
		};
		toggleMenuList(true, menu);
		handleClose();
	};
	const buttons = () => {
		return (
			<>
				<div>
					<Link
						className={styles.underline}
						to={`${urls.EXTENSION}/menus/master/view/${state.id}`}
						replace
					>
						<Button
							variant='contained'
							disableElevation
							color='primary'
							onClick={handleClose}
						>
							View
						</Button>
					</Link>
				</div>
				<div>
					<Button
						onClick={navigateToItem(data.idx)}
						variant='contained'
						disableElevation
						color='primary'
					>
						Availability
					</Button>
				</div>
				<div>
					<Button
						onClick={openlistModal}
						variant='contained'
						disableElevation
						color='primary'
					>
						Change
					</Button>
				</div>
			</>
		);
	};
	return (
		<BaseModal
			heading={state.name}
			height={150}
			open={show}
			renderBtns={buttons}
			handleClose={handleClose}
		/>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.livemenu;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleMenuModal: modalActions.toggleMenuModal,
	toggleMenuList: modalActions.toggleMenuList,
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuModal);
