/* eslint-disable no-tabs */
/* eslint-disable no-unreachable */
import { cloneDeep } from 'lodash';
import fallbackImage from '../../../Images/food.jpg';

// const RESULT_CODE = {
// 1: 'PASSED',
// 0: 'OVERRIDDEN',
// };

const QC_STATES = {
	PASSED: 'PASS',
	FAILED: 'FAIL',
	OVERRIDDEN: 'OVERRIDDEN',
};

function getResult(required, value) {
	if (required == null || required === false || value == null) {
		return null;
	}

	if (typeof value === 'string') {
		return value;
	}

	return value ? 'PASS' : 'FAIL';
}

function getMessage(result, weightResult, tempResult) {
	if (result === 'PASSED') {
		return 'Stock In Done ,Please Remove the Weight to Stock In New Sku.';
	}
	if (result === 'FAILED') {
		if (weightResult === 'FAIL' && tempResult === 'FAIL') {
			return 'Stock In not done';
		}
		if (weightResult === 'FAIL') {
			return 'The sku weight is not in the given range.';
		}
		if (tempResult === 'FAIL') {
			return 'The sku temperature is not in the given range.';
		}
		return 'Stock In Failed';
	}
	if (result === 'OVERRIDDEN') {
		return 'Overridden';
	}
	return null;
}

const getSensorValues = (sensorValues, containerRect, imgDimension) => {
	if (
		sensorValues?.length > 0 &&
		containerRect != null &&
		imgDimension?.width != null &&
		imgDimension?.height != null
	) {
		const scaleX = imgDimension.width / containerRect.width;
		const scaleY = imgDimension.height / containerRect.height;
		return sensorValues.map((value, idx) => {
			const productName = value.temperature;
			const x = value.x / scaleX;
			const y = value.y / scaleY;
			return {
				x,
				y,
				width: value.width / scaleX,
				height: value.height / scaleY,
				key: idx + 1,
				stroke: 'red',
				id: value?.id ?? null,
				labelId: value?.labelId ?? null,
				label: productName,
				class: productName,
			};
		});
	}
	return null;
};

function liveSerializer(state, data) {
	let { name } = state.product;

	const { imgDimensions, containerRect } = data;

	if (data?.skuName != null) {
		name = data?.skuName;
		if (data?.variantName != null) {
			name += ` - ${data?.variantName}`;
		}
	}

	const weightValue =
		data?.weight ?? state?.measurements?.weight?.values?.[0]?.value ?? null;

	let tempValue1 = null;
	let temp1Color = null;
	let tempValue2 = null;
	let temp2Color = null;
	tempValue1 =
		data?.temp ?? state?.measurements?.temp?.values?.[0]?.value ?? null;
	tempValue2 =
		data?.otherTemp ??
		state?.measurements?.temp?.values?.[1]?.value ??
		null;
	temp1Color = tempValue1 >= tempValue2 ? 'red' : 'blue';
	temp2Color = tempValue1 < tempValue2 ? 'red' : 'blue';

	const weightCheckRequired =
		data?.weightCheckRequired ?? state.measurements.weight.required;

	const weightResult = getResult(
		weightCheckRequired,
		data?.isWeightInRange ?? state.measurements.weight.result
	);

	const tempCheckRequired =
		data?.tempCheckRequired ?? state.measurements.temp.required;

	const tempResult = getResult(
		tempCheckRequired,
		data?.isTempinRange ?? state.measurements.temp.result
	);

	const qcDone = true;

	return {
		stockInAt: data?.stockedInAt ?? state.stockInAt,
		stockOutAt: data?.stockedOutAt ?? state.stockOutAt,
		currentDate: data?.current_date ?? state.currentDate,
		ranges: {
			weight: {
				min:
					(data?.minWeight > 1000
						? data?.minWeight / 1000
						: data?.minWeight) ?? state.ranges.weight.min,
				minUnit: data?.minWeight > 1000 ? 'kg' : 'g',
				max:
					(data?.maxWeight > 1000
						? data?.maxWeight / 1000
						: data?.maxWeight) ?? state.ranges.weight.max,
				maxUnit: data?.maxWeight > 1000 ? 'kg' : 'g',
			},
			temp: {
				min: data?.minTemp ?? state.ranges.temp.min,
				max: data?.maxTemp ?? state.ranges.temp.max,
			},
		},
		measurements: {
			weight: {
				live: data?.live_weight ?? state.measurements.weight.live,
				values: [
					{
						size: 'lg',
						value:
							weightValue >= 1000
								? (weightValue / 1000)?.toFixed(3)
								: weightValue?.toFixed(1),
						color: null,
					},
				],
				connected:
					data?.weighingScale ?? state.measurements.weight.connected,
				result: weightResult,
				required: weightCheckRequired,
				// unit: data?.weight?.unit ?? state.measurements.weight.unit,
				unit: weightValue >= 1000 ? 'kg' : 'g',
			},
			temp: {
				live: data?.live_temp ?? state.measurements.temp.live,
				values: [
					{
						size: 'lg',
						value: tempValue1?.toFixed(2),
						color: temp1Color,
					},
					{
						size: 'md',
						value: tempValue2,
						color: temp2Color,
					},
				],
				connected:
					data?.TempSensor ?? state.measurements.temp.connected,
				result: tempResult,
				required: tempCheckRequired,
				unit: '℃',
				sensorValues:
					getSensorValues(
						data?.temperature?.sensorValues,
						containerRect,
						imgDimensions
					) ?? state?.measurements?.temp?.sensorValues,
			},
			camera: {
				connected:
					data?.foodCamera ?? state.measurements.camera.connected,
				// image: data?.skuImage,
				image: data?.skuImage ?? state.measurements.camera.image,
			},
		},
		product: {
			// image: data?.referenceImage,
			image: data?.referenceImage ?? state.product.image,
			name,
			isVeg: data?.isVeg ?? state.product.isVeg,
			brand: data?.brandImage ?? state.product.brand,
			brandId: data?.brand ?? state.product.brandId,
			vendorName: data?.vendorName,
		},
		qcDone,
		result: QC_STATES?.[data?.result] ?? state.result,
		qrCode: data?.qrCode ?? state?.qrCode,
		message:
			getMessage(data?.result, weightResult, tempResult) ?? state.message,
		machine: {
			status: data?.state?.toUpperCase() ?? state.machine.status,
		},
		resetData: data?.resetData,
		duplicate: data?.duplicate ?? state?.duplicate,
		assetId: data?.assetId ?? state?.assetId,
	};
}

const config = {
	initialState: {
		stores: [],
		selectedStore: {
			storeId: null,
			storeName: '',
		},
		stockInAt: null,
		stockOutAt: null,
		ranges: {
			weight: {
				min: 0,
				max: 10000,
			},
			temp: {
				min: -20,
				max: 120,
			},
		},
		measurements: {
			weight: {
				live: null,
				values: [],
				connected: true,
				result: null,
				required: false,
				unit: 'g',
			},
			temp: {
				live: null,
				values: [],
				connected: true,
				result: null,
				required: false,
				unit: '℃',
				sensorValues: [],
			},
			camera: {
				connected: true,
				image: fallbackImage,
			},
		},
		product: {
			image: fallbackImage,
			name: '',
			isVeg: null,
			brand: null,
			brandId: null,
			vendorName: '',
		},
		machine: {
			status: 'OFFLINE',
		},
		qrCode: null,
		qcDone: false,
		result: null,
		resetData: null,
		message: '',
		qcStatusPollingId: null,
		currentDate: new Date(),
		duplicate: false,
		assetId: null,
	},
	serializer: (state, data) => {
		const clonedState = cloneDeep(state);
		return liveSerializer(clonedState, data);
	},
	liveSerializer,
};

export default config;
