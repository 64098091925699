import { useRef, useState } from 'react';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCode from 'qrcode';
import { connect } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import html2canvas from 'html2canvas';
// import jspdf from 'jspdf';
import styles from './ImmovableAsset.module.scss';
import assetServices from '../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../Services';
import { ListTable } from '../../../Components/ListTable';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import { modalActions } from '../../../Actions';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import { BackdropLoader } from '../../../Components/Loaders';
import { addCommas, renderComponentToHtml } from '../../../Helpers';

const FIND_FILTERS = [
	'filteredStore',
];
const TABLE_COLUMNS = [
	{
		title: 'Store Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Asset Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Asset Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Capacity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Current Filled',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Min Temp',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Max Temp',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'QR Code',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Download',
		config: {
			type: 'DATA',
		},
	},
];
const excludedColumns = [];
const colMap = {
	'Store Name': 'storeName',
	'Asset Type': 'assetType',
	'Asset Name': 'name',
	Capacity: 'capacity',
	'QR Code': 'id',
	'Min Temp': 'minTemp',
	'Max Temp': 'maxTemp',
	'Current Filled': 'count',
	Download: 'id',

};

function QrCodeDetails({ currAsset, qrDataURL }) {
	return (
		<div style={{
			width: '500px',
			height: '500px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '2rem',
					fontWeight: 500,
				}}
			>
				<div>
					{currAsset?.name}
				</div>
			</div>
			<img src={qrDataURL} alt='' height='300px' width='300px' />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div>
					{currAsset?.qrCode}
				</div>
				<div>
					Store Name:
					{currAsset?.storeName}
				</div>
			</div>
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				gap: '1.5rem',
			}}
			>
				{currAsset?.minTemp !== null && (
					<div>
						Min Temp:
						{` ${currAsset?.minTemp}\u00B0C`}
					</div>
				)}
				{currAsset?.maxTemp !== null && (
					<div>
						Max Temp:
						{` ${currAsset?.maxTemp}\u00B0C`}
					</div>
				)}
				{currAsset?.capacity && (
					<div>
						Capacity:
						{` ${currAsset?.capacity}`}
					</div>
				)}
			</div>
		</div>
	);
}

function ImmovableAsset({ toggleImageModal }) {
	const [assetList, setAssetList] = useState([]);
	const [asset, setAsset] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const ref = useRef(null);
	const downloadImage = (canvas, item) => {
		try {
		// Convert canvas to data URL
			const imgData = canvas.toDataURL('image/jpeg');

			// Create a link element
			const link = document.createElement('a');
			link.href = imgData;
			link.download = `QRCode_asset_${item?.storeName}_${item?.name}.jpg`; // Set the file name with the extension

			// Append the link to the body and trigger the download
			document.body.appendChild(link);
			link.click();

			// Remove the link from the DOM
			document.body.removeChild(link);
		} catch {
			// eslint-disable-next-line no-console
			console.log('error');
		}
	};
	const downloadPage = async (item) => {
		setIsLoading(true);
		try {
			const options = {
				width: 500,
				height: 400,
			};
			const qrDataURL = await QRCode.toDataURL(item?.qrCode, options);
			const htmlString = renderComponentToHtml(QrCodeDetails, {
				currAsset: item, qrDataURL,
			});
			const div = document.createElement('div');
			div.innerHTML = htmlString;
			div.style.position = 'absolute';
			div.style.left = '-10000px';
			div.style.top = '-10000px';
			document.body.appendChild(div);
			html2canvas(div).then((canvas) => {
				downloadImage(canvas, item);
			});
		} catch {
			// eslint-disable-next-line no-console
			console.log('error');
		} finally {
			setIsLoading(false);
		}
	};

	const submitFindRequest = async ({ store = null }) => {
		setIsLoading(true);
		const data = {
			store,
		};
		try {
			const response = await assetServices.storeAssetInfo({
				data,
			});
			if (response?.responseCode === 'SS-001') {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.responseMessage,
				});
				setAssetList(response?.data);
			}
		} catch {
			// eslint-disable-next-line no-console
			console.log('error');
		} finally {
			setIsLoading(false);
		}
	};
	const handleImageClick = async (item) => {
		try {
			setAsset(item);
			const options = {
				width: 500,
				height: 400,
			};
			const qrDataURL = await QRCode.toDataURL(item?.qrCode, options);
			toggleImageModal(true, {
				image: qrDataURL,
				width: '50rem',
				height: '50rem',
				heading: item?.name,
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	const SPECIAL_ITEMS = {
		'QR Code': (id) => {
			const item = assetList?.find((data) => {
				return (data?.id === id);
			});
			return (
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
				}}
				>
					<QrCodeIcon onClick={() => {
						handleImageClick(item);
					}}
					/>
				</div>
			);
		},
		Download: (id) => {
			const item = assetList?.find((data) => {
				return (data?.id === id);
			});
			return (
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
				}}
				>
					<FileDownloadIcon onClick={() => {
						setAsset(item);
						downloadPage(item);
					}}
					/>
				</div>
			);
		},
	};

	return (
		<>
			<BackdropLoader isLoading={isLoading} showProgress />
			<div className={styles.root}>
				<ImageModal ref={ref}>
					<div
						className={styles.asset_details}
					>
						<div>
							{asset?.qrCode}
						</div>
						<div>
							Store Name:
							{asset?.storeName}
						</div>
						<div style={{
							display: 'flex',
							justifyContent: 'center',
							gap: '1rem',
						}}
						>
							{asset?.minTemp !== null && (
								<div>
									Min Temp:
									{`${asset?.minTemp}\u00B0C`}
								</div>
							)}
							{asset?.maxTemp !== null && (
								<div>
									Max Temp:
									{`${asset?.maxTemp}\u00B0C`}
								</div>
							)}
							{asset?.capacity && (
								<div>
									Capacity:
									{asset?.capacity}
								</div>
							)}
						</div>
					</div>
				</ImageModal>
				<ImagesFinder
					pageLabel='Immovable Asset'
					FILTERS={FIND_FILTERS}
					onSubmit={submitFindRequest}
				/>
				<ListTable
					colMap={colMap}
					columns={TABLE_COLUMNS?.filter((ele) => {
						return !excludedColumns?.includes(ele?.title);
					})}
					data={assetList?.map((ele) => {
						return {
							...ele,
							capacity: addCommas(ele?.capacity, 'IN'),
						};
					})}
					special={{
						...SPECIAL_ITEMS,
					}}
				/>
			</div>
		</>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleImageModal: modalActions.toggleImageModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImmovableAsset);
