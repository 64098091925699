// eslint-disable-next-line object-curly-newline
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import { checkFormField } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import addChildConfig from './addChild.config';
import styles from './AddChild.module.scss';

function AddChild(props) {
	const { stores, fetchStoresList, addChild } = props;
	const [formState, setFormState] = useState({
		...addChildConfig.initialState,
	});

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleInputChange = (e = {}) => {
		const { name, value } = e?.target;
		setFormState({
			...formState,
			[name]: value,
		});
	};

	const handleAddChild = (e) => {
		e.preventDefault();
		const field = checkFormField(formState, 'section');
		if (field) {
			notificationServices.generateNotification({
				type: 'error',
				message: `${field} is mandatory`,
			});
		} else {
			addChild({
				entity: 'CHILD',
				req: {
					data: {
						...addChildConfig.deserialize(formState),
					},
				},
				method: 'ADD',
			});
		}
	};

	return (
		<div className={`${styles.root}`}>
			<h1>Add Child</h1>
			<form onSubmit={handleAddChild}>
				<TextField
					fullWidth
					name='name'
					onChange={handleInputChange}
					value={formState?.name}
					label='Child Name'
				/>
				<FormControl fullWidth>
					<InputLabel id='class-label'>Class</InputLabel>
					<Select
						name='class'
						onChange={handleInputChange}
						value={formState?.class}
						labelId='class-label'
						label='Class'
					>
						<MenuItem value={1}>1</MenuItem>
						<MenuItem value={2}>2</MenuItem>
						<MenuItem value={3}>3</MenuItem>
						<MenuItem value={4}>4</MenuItem>
						<MenuItem value={5}>5</MenuItem>
						<MenuItem value={6}>6</MenuItem>
						<MenuItem value={7}>7</MenuItem>
						<MenuItem value={8}>8</MenuItem>
						<MenuItem value={9}>9</MenuItem>
						<MenuItem value={10}>10</MenuItem>
						<MenuItem value={11}>11</MenuItem>
						<MenuItem value={12}>12</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id='section-label'>Section</InputLabel>
					<Select
						name='section'
						onChange={handleInputChange}
						value={formState?.section}
						labelId='section-label'
						label='Section'
					>
						<MenuItem value='A'>A</MenuItem>
						<MenuItem value='B'>B</MenuItem>
						<MenuItem value='C'>C</MenuItem>
						<MenuItem value='D'>D</MenuItem>
						<MenuItem value='E'>E</MenuItem>
						<MenuItem value='F'>F</MenuItem>
						<MenuItem value='G'>G</MenuItem>
						<MenuItem value='H'>H</MenuItem>
						<MenuItem value='I'>I</MenuItem>
						<MenuItem value='J'>J</MenuItem>
						<MenuItem value='K'>K</MenuItem>
						<MenuItem value='L'>L</MenuItem>
					</Select>
				</FormControl>
				<TextField
					fullWidth
					name='parentNumber'
					onChange={handleInputChange}
					value={formState?.parentNumber}
					label='Parent Mobile Number'
				/>
				<TextField
					fullWidth
					name='email'
					onChange={handleInputChange}
					value={formState?.email}
					label='Email Address'
				/>
				<FormControl fullWidth>
					<InputLabel id='school-label'>Select School</InputLabel>
					<Select
						name='school'
						onChange={handleInputChange}
						value={formState?.storeId}
						labelId='school-label'
						label='Select Section'
					>
						{stores?.map((school) => {
							return (
								<MenuItem value={school?.id}>
									{school?.name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<Button type='submit' variant='contained' color='primary'>
					Add Child
				</Button>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	return {
		stores,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	addChild: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddChild);
