const aiModelConfig = {
	initialState: {
		store: [],
		model: '',
		product: [],
		allStore: null,
		modeType: '',
	},
};

export default aiModelConfig;
