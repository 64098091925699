// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { urls } from '../../../Constants';
import { AiDispatchHistory } from '../../../Pages/Analytics/AiDispatchAnalysis/AiDispatchHistory';
import AiDispatchImagesPage from '../../../Pages/Analytics/AiDispatchAnalysis/AiDispatchImagesPage/AiDispatchImagesPage';

const getSubURL = (entity) => {
	const baseEntity = urls.AI_DISPATCH;
	return entity.slice(baseEntity.length);
};

const AiDispatchRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.AI_DISPATCH_IMAGES)}`}>
				<AiDispatchImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_DISPATCH_HISTORY)}`}>
				<AiDispatchHistory />
			</Route>
		</Switch>
	);
};

export default AiDispatchRoutes;
