import { Button } from '@mui/material';

/* eslint-disable no-unused-vars */
const PAYMENT_RECEIVED_FILTERS = [
	'Client Debit. Id',
	'Debit Note Date',
	'Billing Store',
	'Debit Note Amount',
	'Balance Amount',
	'Sales Return Id',
	'Debit Used',
	'Vendor',
	'Bill id',
	'Bill Type',
];
const PAYMENT_MADE_FILTERS = [
	'Client Debit. Id',
	'Debit Note Date',
	'Billing Store',
	'Bill id',
	'Debit Note Amount',
	'Balance Amount',
	'Sales Return Id',
	'Bill Amount',
	'Invoice id',
	'Bill Type',
	'Order id',
	'Store',
];
const CREDITS_FILTERS = [
	'Bill id',
	'Invoice id',
	'Payment date',
	'Paid Amount',
	'Debit Used',
	'Billing Store',
	'Sales Return Id',
	'Bill Amount',
	'Client Debit. Id',
	'Bill Type',
];
const CREDITS_NOTES_FILTERS = [
	'Bill id',
	'Invoice id',
	'Payment date',
	'Paid Amount',
	'Vendor',
	'Debit Used',
	'Billing Store',
	'Bill Type',
];
const BILLS_FILTER = [
	'Client Debit. Id',
	'Debit Note Date',
	'Debit Note Amount',
	'Store',
	'Balance Amount',
	'Sales Return Id',
	'Invoice id',
	'Payment date',
	'Debit Used',
];

const Colmap = (transactionType) => {
	let colmapObj = {
		id: 'id',
		Vendor: 'vendorName',
		Store: 'storeName',
		'Order id': 'orderId',
		'Bill id': 'clientTransactionId',
		'Bill Type': 'id',
		'Invoice id': 'clientTransactionId',
		'Payment date': 'transactionDate',
		'Bill date': 'transactionDate',
		'Due Date': 'dueDate',
		Status: 'transactionStatus',
		'Paid Amount': 'currentAmount',
		'Client Debit. Id': 'clientTransactionId',
		'Debit Note Date': 'transactionDate',
		'Debit Note Amount': 'finalTransactionAmount',
		'Balance Amount': 'currentAmount',
		'Debit Used': 'creditUsedAmount',
	};
	if (transactionType === 'CREDIT_BILLS') {
		colmapObj = {
			...colmapObj,
			Store: 'fromStoreName',
			'Billing Store': 'storeName',
			'Bill Amount': 'finalTransactionAmount',
			'Order Amount': 'orderAmount',
			'Paid Amount': 'currentAmount',
			// 'Payment Made': '',
			Status: 'transactionStatus',
		};
	}
	return colmapObj;
};

const TABLE_COLUMNS = [
	{
		title: 'id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Billing Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sales Return Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Invoice id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Debit. Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Debit Note Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Debit Note Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Balance Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Paid Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Debit Used',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];
const SPECIAL_ITEMS = {
	Delete: () => {
		return (
			<Button onClick={() => {}} variant='contained' color='primary'>
				Cancel bill
			</Button>
		);
	},
};

// eslint-disable-next-line consistent-return
const getTableColumns = (transcationType, type) => {
	if (transcationType === 'PAYMENTS') {
		return TABLE_COLUMNS?.filter((ele) => {
			if (type === 'paymentReceived') {
				return !PAYMENT_RECEIVED_FILTERS?.includes(ele?.title);
			}
			return !PAYMENT_MADE_FILTERS?.includes(ele?.title);
		});
	}
	if (transcationType === 'CREDIT_BILLS') {
		return TABLE_COLUMNS?.filter((ele) => {
			return !BILLS_FILTER?.includes(ele?.title);
		});
	}
	if (transcationType === 'CREDITS') {
		return TABLE_COLUMNS?.filter((ele) => {
			if (type === 'creditsNotes') {
				return !CREDITS_NOTES_FILTERS?.includes(ele?.title);
			}
			return !CREDITS_FILTERS?.includes(ele?.title);
		});
	}
};

const getTableData = (transcationType, type) => {
	return {
		TABLE_COLUMNS: getTableColumns(transcationType, type),
		SPECIAL_COLUMNS,
		SPECIAL_ITEMS,
		Colmap: Colmap(transcationType),
	};
};

const config = {
	TABLE_DATA: getTableData,
};

export default config;
