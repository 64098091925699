const analyticsTypeConstant = {
	DASHBOARD_ANALYTICS: 'DASHBOARD_ANALYTICS',
	AI_DASHBOARD_ANALYTICS: 'AI_DASHBOARD_ANALYTICS',
	AI_WORKFLOW_ANALYTICS: 'AI_WORKFLOW_ANALYTICS',
	STORE_ANALYTICS: 'STORE_ANALYTICS',
	QUALITY_ANALYTICS_CHART_DATA: 'QUALITY_ANALYTICS_CHART_DATA',
	QUALITY_ANALYTICS_LIST_DATA: 'QUALITY_ANALYTICS_LIST_DATA',
	INVENTORY_ANALYTICS_CHART_DATA: 'INVENTORY_ANALYTICS_CHART_DATA',
	INVENTORY_ANALYTICS_LIST_DATA: 'INVENTORY_ANALYTICS_LIST_DATA',
	QUALITY_STATISTICAL_ANALYTICS: 'QUALITY_STATISTICAL_ANALYTICS',
	QUALITY_HISTORY_LIST_DATA: 'QUALITY_HISTORY_LIST_DATA',
	COOKING_HISTORY_LIST_DATA: 'COOKING_HISTORY_LIST_DATA',
	ORDER_HISTORY_LIST_DATA: 'ORDER_HISTORY_LIST_DATA',
	WASTAGE_HISTORY_LIST_DATA: 'WASTAGE_HISTORY_LIST_DATA',
	AI_PRODUCTION_ANALYSIS_LIST_DATA: 'AI_PRODUCTION_ANALYSIS_LIST_DATA',
	AI_OUTWARDING_ANALYSIS_LIST_DATA: 'AI_OUTWARDING_ANALYSIS_LIST_DATA',
	AI_GRN_ANALYSIS_LIST_DATA: 'AI_GRN_ANALYSIS_LIST_DATA',
	AI_LEFT_OVER_INVENTORY_ANALYSIS_LIST_DATA: 'AI_LEFT_OVER_INVENTORY_ANALYSIS_LIST_DATA',
	AI_DISPATCH_ANALYSIS_LIST_DATA: 'AI_DISPATCH_ANALYSIS_LIST_DATA',
	SUBSCRIPTIONS_HISTORY_LIST_DATA: 'SUBSCRIPTIONS_HISTORY_LIST_DATA',

	// COGS
	COGS_ANALYTICS_CHART_DATA: 'COGS_ANALYTICS_CHART_DATA',
	COGS_ANALYTICS_LIST_DATA: 'COGS_ANALYTICS_LIST_DATA',
	COGS_HISTORY_LIST_DATA: 'COGS_HISTORY_LIST_DATA',

	// AUDIT/VARIANCE
	AUDIT_ANALYTICS_CHART_DATA: 'AUDIT_ANALYTICS_CHART_DATA',
	AUDIT_ANALYTICS_LIST_DATA: 'AUDIT_ANALYTICS_LIST_DATA',
	AUDIT_HISTORY_LIST_DATA: 'AUDIT_HISTORY_LIST_DATA',

	// TRANSIT
	TRANSIT_ANALYTICS_CHART_DATA: 'TRANSIT_ANALYTICS_CHART_DATA',
	TRANSIT_ANALYTICS_LIST_DATA: 'TRANSIT_ANALYTICS_LIST_DATA',
	TRANSIT_HISTORY_LIST_DATA: 'TRANSIT_HISTORY_LIST_DATA',

	// BOM/PRODUCTION COST
	BOM_ANALYTICS_CHART_DATA: 'BOM_ANALYTICS_CHART_DATA',
	BOM_ANALYTICS_LIST_DATA: 'BOM_ANALYTICS_LIST_DATA',
	BOM_HISTORY_LIST_DATA: 'BOM_HISTORY_LIST_DATA',

	// INVENTORY
	INV_ANALYTICS_CHART_DATA: 'INV_ANALYTICS_CHART_DATA',
	INV_ANALYTICS_LIST_DATA: 'INV_ANALYTICS_LIST_DATA',
	INV_HISTORY_LIST_DATA: 'INV_HISTORY_LIST_DATA',

	// FORECASTING
	FORECASTING_ANALYTICS_CHART_DATA: 'FORECASTING_ANALYTICS_CHART_DATA',
	FORECASTING_ANALYTICS_LIST_DATA: 'FORECASTING_ANALYTICS_LIST_DATA',
	FORECASTING_HISTORY_LIST_DATA: 'FORECASTING_HISTORY_LIST_DATA',

	// ============= COMMON ANALYTICS DATA PAGE
	COMMON_ANALYTICS_CHART_DATA: 'COMMON_ANALYTICS_CHART_DATA',
	COMMON_ANALYTICS_LIST_DATA: 'COMMON_ANALYTICS_LIST_DATA',
	COMMON_HISTORY_LIST_DATA: 'COMMON_HISTORY_LIST_DATA',
};
export default analyticsTypeConstant;
