import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	// eslint-disable-next-line no-unused-vars
	Typography,
} from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './ConfigurationPage.module.scss';
import VendorProductMapping from './VendorProductMapping/VendorProductMapping';

const accordionData = [
	{
		accordionId: 0,
		configurationTitle: 'Vendor Product Mapping',
	},
	{
		accordionId: 1,
		configurationTitle: 'Product Configurations',
	},
];

function ConfigurationPage() {
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => {
		return (event, isExpanded) => {
			setExpanded(isExpanded ? panel : false);
		};
	};

	return (
		<div className={styles.root}>
			<div className={styles.header}>Configuration</div>
			<div className={styles.body}>
				{accordionData?.map((ele) => {
					return (
						<Accordion
							className={styles.accordion}
							expanded={expanded === ele?.accordionId}
							onChange={handleChange(ele?.accordionId)}
						>
							<AccordionSummary
								className={styles.summary}
								expandIcon={<ExpandMoreIcon />}
							>
								<p>{ele?.configurationTitle}</p>
							</AccordionSummary>
							<AccordionDetails className={styles.details}>
								<VendorProductMapping />
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>
		</div>
	);
}

export default ConfigurationPage;
