/* eslint-disable no-tabs */
// eslint-disable-next-line object-curly-newline
import { Add, DeleteForever, FileCopy, Visibility } from '@mui/icons-material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import React, { useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { urls } from '../../../../Constants';
import { objectContainsProperty } from '../../../../Helpers';
import styles from '../../../ListTable/ListTable.module.scss';
import localStyles from './CustomizationListTable.module.scss';
import excel from '@/Images/excel.svg';

const CustomizationListTable = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		entity,
		colMap,
		columns,
		data,
		actions,
		special,
		hideRecipeCreation = false,
		handleClone,
		handleDelete,
		userPermissions,
		useWindowScroll = true,
		allowExport = true,
		onExportClick = () => {},
	} = props;
	const { url } = useRouteMatch();
	const history = useHistory();

	const navigateToItem = (id, type = 'CUSTOMIZATION', mode = 'view') => {
		return () => {
			switch (type) {
				case 'CUSTOMIZATION':
					history.push(`${url}/${mode}/${id}`);
					break;
				case 'RECIPE':
					history.push(
						`${urls.EXTENSION}${urls.COOKING_RECIPE}/${mode}/${id}`
					);
					break;
				default:
					break;
			}
		};
	};

	const generateActionBtns = (item, dataObj) => {
		const { id, type, isCustomizationEnabled } = item;
		const actionBtns = {
			Clone: (
				<td className={`${styles['action-item']}`}>
					<FileCopy
						className={`${styles['action-btn']} ${styles.clone}`}
						onClick={() => {
							handleClone(id);
						}}
					/>
				</td>
			),
			View: (
				<td className={`${styles['action-item']}`}>
					<span className={localStyles['action-wrapper']}>
						<Visibility
							className={`${styles['action-btn']} ${styles.view}`}
							onClick={navigateToItem(id)}
						/>
						<span className={localStyles['action-sub-text']}>
							Customization
						</span>
					</span>
				</td>
			),
			ViewRecipe: (
				<td className={`${styles['action-item']}`}>
					<span className={localStyles['action-wrapper']}>
						<Visibility
							className={`${styles['action-btn']} ${styles.view}`}
							onClick={navigateToItem(id, 'RECIPE')}
						/>
						<span className={localStyles['action-sub-text']}>
							Recipe
						</span>
					</span>
				</td>
			),
			AddCustomization: (
				<td className={`${styles['action-item']}`}>
					<span className={localStyles['action-wrapper']}>
						<Add
							className={`${styles['action-btn']} ${styles.add}`}
							onClick={navigateToItem(
								id,
								'CUSTOMIZATION',
								'edit'
							)}
						/>
						<span className={localStyles['action-sub-text']}>
							Customization
						</span>
					</span>
				</td>
			),
			AddRecipe: (
				<td className={`${styles['action-item']}`}>
					{hideRecipeCreation ? (
						<>-</>
					) : (
						<>
							<span className={localStyles['action-wrapper']}>
								<Add
									className={`${styles['action-btn']} ${styles.add}`}
									onClick={navigateToItem(
										id,
										'RECIPE',
										'edit'
									)}
								/>
								<span
									className={localStyles['action-sub-text']}
								>
									Recipe
								</span>
							</span>
						</>
					)}
				</td>
			),
			Delete: (
				<td className={`${styles['action-item']}`}>
					<DeleteForever
						className={`${styles['action-btn']} ${styles.delete}`}
						onClick={() => {
							handleDelete(id);
						}}
					/>
				</td>
			),
		};

		return actions
			.filter((action) => {
				if (action?.config?.type?.toLowerCase() === 'delete') {
					return userPermissions?.DELETE;
				}
				if (action?.config?.type?.toLowerCase() === 'view') {
					return userPermissions?.VIEW;
				}
				if (
					action?.config?.type?.toLowerCase() === 'update' ||
					action?.config?.type?.toLowerCase() === 'enabled'
				) {
					return userPermissions?.UPDATE;
				}
				return true;
			})
			.map((action) => {
				const { title } = action;
				if (objectContainsProperty(actionBtns, title)) {
					let newAction = title;
					if (title === 'View') {
						if (type === 'RECIPE') {
							newAction = 'ViewRecipe';
						} else if (type === 'CUSTOMIZATION') {
							if (entity === 'RECIPE') {
								newAction = 'AddRecipe';
							}
						} else if (entity === 'RECIPE') {
							newAction = 'AddRecipe';
						} else {
							newAction = 'AddCustomization';
						}
					}
					if (
						newAction.indexOf('Add') !== -1 &&
						!userPermissions?.CREATE
					) {
						return (
							<td
								aria-label='Save'
								className={`${styles['action-item']}`}
							/>
						);
					}
					return actionBtns[newAction];
				}
				const prop = colMap[title];
				const val = item[prop];
				const alt = {};
				// customization enabled flag
				// RMS enabled flag
				if (
					title === 'Enabled' ||
					title === 'Recipe Management' ||
					title === 'Customization Enabled'
				) {
					alt.isDisabled = false;
					if (val === null) {
						alt.isDisabled = true;
					}
					// current state of Product: Recipe | Customization | Recipe w/ Customization | -
				} else if (title === 'Current State') {
					if (type === 'RECIPE') {
						if (isCustomizationEnabled === null) {
							alt.text = 'Recipe';
						} else {
							alt.text = 'Recipe w/ Customization';
						}
					} else if (type === 'CUSTOMIZATION') {
						alt.text = 'Customization';
					} else {
						alt.text = '-';
					}
				}
				return (
					<td className={`${styles['action-item']}`}>
						{special[title](dataObj, alt)}
					</td>
				);
			});
	};

	const fixedHeaderContent = () => {
		return (
			<tr>
				{[...columns, ...actions]
					.filter((action) => {
						if (action?.config?.type?.toLowerCase() === 'delete') {
							return userPermissions?.DELETE;
						}
						if (action?.config?.type?.toLowerCase() === 'view') {
							return userPermissions?.VIEW;
						}
						if (
							action?.config?.type?.toLowerCase() === 'update' ||
							action?.config?.type?.toLowerCase() === 'enabled'
						) {
							return userPermissions?.UPDATE;
						}
						return true;
					})
					.map((col, idx) => {
						const { title } = col;
						return (
							<th
								className={
									idx >= columns.length ? styles.center : ''
								}
							>
								{title}
							</th>
						);
					})}
			</tr>
		);
	};
	return (
		<div className='flex flex-col gap-1'>
			<div className='flex justify-end px-2'>
				{allowExport && (
					<div
						onClick={() => {
							onExportClick();
						}}
						className={localStyles.downloadData}
					>
						<img src={excel} alt='excel' />
						EXPORT
					</div>
				)}
			</div>
			<TableVirtuoso
				useWindowScroll={useWindowScroll}
				style={{
					width: '100%',
				}}
				data={data}
				fixedHeaderContent={fixedHeaderContent}
				components={{
					Table: React.forwardRef(({ children }) => (
						<table
							className={`${styles['list-table-root']} ${localStyles.sticky} ${localStyles.root}`}
						>
							{children}
						</table>
					)),
					TableRow: useMemo(() => {
						return (rowProps) => {
							const { children, ...rest } = rowProps;
							const rowStyles =
								data?.[rest?.['data-index']]?.rowStyles;
							return (
								<tr
									{...rest}
									className={`border-r border-neutral-20 ${rowStyles}`}
								>
									{children}
								</tr>
							);
						};
					}, [columns, actions, data]),
				}}
				itemContent={useMemo(() => {
					return (idx, item) => {
						return (
							<>
								{columns
									.filter((action) => {
										if (
											action?.config?.type?.toLowerCase() ===
											'delete'
										) {
											return userPermissions?.DELETE;
										}
										if (
											action?.config?.type?.toLowerCase() ===
											'view'
										) {
											return userPermissions?.VIEW;
										}
										if (
											action?.config?.type?.toLowerCase() ===
												'update' ||
											action?.config?.type?.toLowerCase() ===
												'enabled'
										) {
											return userPermissions?.UPDATE;
										}
										return true;
									})
									.map((col) => {
										const { title } = col;
										const prop = colMap[title];
										const value = _.get(item, prop);
										if (
											objectContainsProperty(
												special,
												title
											)
										) {
											if (Array.isArray(value)) {
												return special[title](
													data[idx]
												);
											}
											return (
												<td key={title}>
													{special[title](data[idx])}
												</td>
											);
										}
										return <td key={title}>{value}</td>;
									})}
								{generateActionBtns(item, data[idx])}
							</>
						);
					};
				}, [data, columns, colMap])}
			/>
		</div>
	);
};
CustomizationListTable.defaultProps = {
	special: {},
	sticky: true,
};

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

export default connect(mapStateToProps)(CustomizationListTable);
