/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable prefer-template */
import { connect } from 'react-redux';
import {
	useEffect, useMemo, useState
} from 'react';
import { BaseModal } from '../BaseModal';
import { ListTable } from '../../ListTable';
import styles from './POItemsModal.module.scss';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal } from '../AlertModal';
import TransactionDetails from './TransactionDetails';
import {
	addCommas,
	getUnitConvertedQuantity,
	getUnitConvertedUnit,
} from '../../../Helpers';
import TxnHeader from './TxnHeader/TxnHeader';
import { Button } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import TotalContainer from './TotalContainer/TotalContainer';
import { modalActions } from '@/Actions';
import AssetGroupSelectionSummary from '@/Components/AssetSelector/AssetGroupSelectionSummary';

const ACTION_COLUMNS = [];

const COLMAP = {
	'Product Name': 'productName',
	Unit: 'unit',
	Dispatched: 'fulfilled',
	Received: 'storeFulfilled',
	Packaged: 'packaged',
	'Ordered Quantity': 'qty',
	'Returned Quantity': 'qty',
	'Used For': 'usedFor',
	Quantity: 'qty',
	Fulfilled: 'fulfilled',
	Returned: 'returned',
	'From Process': 'fromProcess',
	'PO Id': 'poId',
	'Item Price': 'id',
	'Discount %': 'id',
	'Item Sub Total': 'id',
	'Tax Percentage': 'id',
	'Item Tax': 'id',
	'Tax value': 'id',
	'Item Amount': 'id',
};
const TABLE_COLUMNS = [];

const SPECIAL_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ordered Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Returned Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Fulfilled',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Returned',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Packaged',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatched',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Received',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'From Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production Plan Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Price',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Discount %',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Tax Percentage',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Tax value',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Item Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Used For',
		config: {
			type: 'DATA',
		},
	},
];

const baseUnits = ['gram', 'gm', 'g', 'oz', 'floz', 'ml'];

const POItemsModal = (props) => {
	const { show, data, toggleModal, toggleAlert } = props;
	const {
		items = [],
		heading = 'Purchase Order Items',
		excludedColumns,
		isApprove,
		warningText = false,
		advanceBill = false,
		// type = "",
		assetGroupingSummaryData = {},
		showPdf,
		showTotal,
		showPoDetails = false,
		previewFile,
		hideItems = false,
		isAdvanceBill = false,
		txnHeader = {},
		showProductionPlanColumns = false,
		customColumns = [],
	} = data;

	const [searchQuery, setSearchQuery] = useState('');
	const [checkedInvoiceBill, setCheckedInvoiceBill] = useState(false);
	const [pdfState, setPdfState] = useState('');
	const [poData, setPOData] = useState(null);

	const getPoById = async (poId) => {
		const response = await inventoryAnalyticsServices.fetchPo(Number(poId));
		if (response?.statusCode === 200) {
			return response?.data;
		}
		return null;
	};

	const fetchPdfData = async () => {
		try {
			const response = await inventoryAnalyticsServices.fetchInvTransDoc({
				req: {
					params: {
						transactionId: data?.id,
					},
				},
			});
			if (response?.statusCode === 200) {
				setPdfState(response?.data?.base64);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	const fetchPoPdfData = async () => {
		try {
			const response = await inventoryAnalyticsServices.fetchPoPdfDoc({
				req: {
					params: {
						orderId: data?.id,
					},
				},
			});
			if (response?.statusCode === 200) {
				setPdfState(response?.data?.base64);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		if (!previewFile) {
			if (data?.id && isApprove && data?.uploadedDoc) {
				fetchPdfData();
			}
			if (data?.id && !!data?.poURL) {
				fetchPoPdfData();
			}
		} else {
			setPdfState(previewFile);
		}
	}, [data?.id, data?.poURL, previewFile]);

	useEffect(async () => {
		if (show && data?.poId) {
			const t = await getPoById(data?.poId);
			setPOData(t ?? {});
		}
	}, [show, data?.poId]);

	const handleClose = () => {
		toggleModal(false, {});
		setPdfState('');
		setCheckedInvoiceBill(false);
		setSearchQuery('');
	};

	const ITEMS = useMemo(() => {
		return items
			?.filter((item) => {
				if (!searchQuery) {
					return true; // Return true for all items when searchQuery is empty
				}
				return item?.productName
					?.toLowerCase()
					?.includes(searchQuery?.toLowerCase());
			})
			?.map((ele) => {
				let id = ele?.variantId + '_' + ele?.poId;
				if (ele?.assetId) {
					id = ele?.assetId + '_' + ele?.id;
				}
				if (ele?.serviceId) {
					id = ele?.serviceId + '_' + ele?.id;
				}
				if (ele?.skuId) {
					id = ele?.skuId + '_' + ele?.parentSkuId;
				}
				return {
					...ele,
					id,
				};
			});
	}, [items, searchQuery]);

	const SPECIAL_ITEMS = {
		'Product Name': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				<div>
					<p>
						{item?.productName +
							(item?.variantName
								? ' (' + item?.variantName + ')'
								: '')}
					</p>
					<p>{item?.description ? item?.description : ''}</p>
				</div>
			);
		},
		'Used For': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				<div>
					<p>{item?.usedFor}</p>
				</div>
			);
		},
		Unit: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemUnit = item?.unit;
			if (baseUnits?.includes(itemUnit)) {
				return getUnitConvertedUnit(item?.qty, itemUnit, true, false);
			}
			return itemUnit;
		},
		'Order Type': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.orderType ?? '-';
		},
		'Category Name': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.categoryName ?? '-';
		},
		'Ordered Quantity': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const poItem = poData?.items?.find((i) => {
				return item.variantId === i?.variantId;
			});
			const itemQty = poItem?.qty ?? item?.qty;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemQty,
					item?.unit,
					true,
					true
				);
			}
			return itemQty;
		},
		'Returned Quantity': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemQty = item?.qty;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemQty,
					item?.unit,
					true,
					true
				);
			}
			return itemQty;
		},
		Quantity: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemQty = item?.qty;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemQty,
					item?.unit,
					true,
					true
				);
			}
			return itemQty;
		},
		Packaged: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemPacked = item?.packaged;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemPacked,
					item?.unit,
					true,
					true
				);
			}
			return itemPacked;
		},
		Received: (itemId) => {
			const item = ITEMS?.find?.((poItem) => {
				return itemId === poItem?.id;
			});
			const poItem = poData?.items?.find?.((i) => {
				return item.variantId === i?.variantId;
			});
			const itemReceived = poItem ? item?.qty : item?.storeFulfilled;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemReceived,
					item?.unit,
					true,
					true
				);
			}
			return itemReceived;
		},
		Dispatched: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemFulfilled = item?.fulfilled;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemFulfilled,
					item?.unit,
					true,
					true
				);
			}
			return itemFulfilled;
		},
		Fulfilled: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemFulfilled = item?.fulfilled;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemFulfilled,
					item?.unit,
					true,
					true
				);
			}
			return itemFulfilled;
		},
		Returned: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemReturned = item?.returned;
			if (baseUnits?.includes(item?.unit)) {
				return getUnitConvertedQuantity(
					itemReturned,
					item?.unit,
					true,
					true
				);
			}
			return itemReturned;
		},
		'From Process': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.fromProcess ?? '-';
		},
		'PO Id': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.poId ?? '-';
		},
		'Production Plan Name': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			// return item?.poId ?? '-';
			return item?.clientPoId ?? '-';
		},
		'Total Item Amount': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				`₹${addCommas(item?.totalValue ?? item?.finalAmount)}` ?? '-'
			);
		},
		'Item Price': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `₹${item?.productCost ?? item?.price ?? '-'}`;
		},
		'Discount %': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.discountPercentage ?? '-';
		},
		'Tax Percentage': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `${item?.taxPercentage}%` ?? '-';
		},
		'Tax value': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemQty = item?.qty;
			if (baseUnits?.includes(item?.unit)) {
				itemQty = getUnitConvertedUnit(itemQty, item?.unit, true, true);
			}
			// itemQty = getUnitConvertedQuantity(itemQty, item?.unit, true, true);
			let itemPrice = item?.price;
			if (item?.unit === 'gram' || item?.unit === 'ml') {
				itemPrice /= 1000;
			}
			if (item?.unit === 'oz') {
				itemPrice /= 16;
			}
			if (item?.unit === 'floz') {
				itemPrice /= 128;
			}
			const percentageValue =
				(itemPrice * itemQty * item?.taxPercentage) / 100;
			if (!item?.productTaxValue) {
				return `₹${addCommas(percentageValue)}`;
			}
			return `₹${addCommas(item?.productTaxValue)}` ?? '-';
		},
	};

	const isDisabled = useMemo(() => {
		if (advanceBill) {
			return !checkedInvoiceBill;
		}
		return !pdfState || !checkedInvoiceBill;
	}, [pdfState, checkedInvoiceBill, advanceBill]);

	const renderBtns = () => {
		return (
			isApprove && (
				<div className={styles['btn-container']}>
					<div className={styles['terms-conditions']}>
						{warningText ? (
							<Label
								className='text-red-600'
								htmlFor='termsCheckbox'
							>
								{data?.warningText}
							</Label>
						) : (
							<>
								<input
									id='termsCheckbox'
									className={styles.checkbox}
									type='checkbox'
									checked={checkedInvoiceBill}
									onClick={() => {
										setCheckedInvoiceBill(
											!checkedInvoiceBill
										);
									}}
								/>
								<Label htmlFor='termsCheckbox'>
									{data?.approvalText}
								</Label>
							</>
						)}
					</div>
					<div className={styles.btns}>
						<Button
							className='primary-btn flex-1'
							disabled={isDisabled}
							onClick={() => {
								props.handleConfirm(data);
								setCheckedInvoiceBill(false);
								setPdfState('');
							}}
						>
							Approve
						</Button>
						<Button
							className='flex-1'
							variant='destructive'
							onClick={() => {
								if (props.handleReject) {
									toggleAlert(true, {
										handleConfirm: (rejectionReason) => {
											props?.handleReject(
												data,
												rejectionReason
											);
											handleClose();
											toggleAlert(false, {});
										},
										heading: `Reject #${
											data?.clientPoId ??
											data?.clientTransactionId
										}`,
										takeInput: true,
										confirmText: 'Reject',
										height: 'fit-content',
										width: '27.5rem',
										message: 'Please Give reason to Reject',
										inputLabel: 'Enter Reason',
										colorReverse: true,
										rendererType: 'poItemsModal',
									});
									// props.handleReject(data);
								}
							}}
						>
							Reject
						</Button>
					</div>
				</div>
			)
		);
	};

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalPrice = 0;
		let taxOnPackagingCharges = 0;
		let taxOnDeliveryCharges = 0;
		const additionalCharges = {};
		if (data?.packagingCharges) {
			taxOnPackagingCharges =
				data?.packagingCharges * (data?.packagingTaxPercentage / 100);
		}
		if (data?.deliveryCharges) {
			taxOnDeliveryCharges =
				data?.deliveryCharges * (data?.deliveryTaxPercentage / 100);
		}
		totalTax += taxOnDeliveryCharges + taxOnPackagingCharges;
		totalPrice +=
			data?.deliveryCharges +
			taxOnDeliveryCharges +
			(data?.packagingCharges + taxOnPackagingCharges);
		(data?.jsonData ?? data?.extraData)?.additionalCharges?.forEach(
			(ele) => {
				// eslint-disable-next-line no-prototype-builtins
				if (additionalCharges?.hasOwnProperty(ele?.type)) {
					additionalCharges[ele?.type] += Number(ele?.price)?.toFixed(
						2
					);
					additionalCharges[
						'Tax on ' + ele?.type + `(${ele?.taxPercentage}%)`
					] += Number(
						(ele?.price * ele?.taxPercentage) / 100
					)?.toFixed(2);
				} else {
					additionalCharges[ele?.type] = Number(ele?.price)?.toFixed(
						2
					);
					additionalCharges[
						'Tax on ' + ele?.type + `(${ele?.taxPercentage}%)`
					] = Number(
						(ele?.price * ele?.taxPercentage) / 100
					)?.toFixed(2);
				}
				totalTax += Number((ele?.price * ele?.taxPercentage) / 100);
				totalPrice += Number(
					ele?.price * (1 + ele?.taxPercentage / 100)
				);
			}
		);

		items?.forEach((ele) => {
			if (ele?.qty > 0) {
				if (ele?.finalValue) {
					totalPrice += ele?.finalValue;
					subTotal += ele?.finalValue - ele?.productTaxValue;
					totalTax += ele?.productTaxValue;
				} else {
					if (ele?.finalAmount) {
						totalPrice += ele?.finalAmount;
					}
					const itemSubTotal =
						ele?.finalAmount / (1 + ele?.taxPercentage / 100);
					if (!ele?.subTotal) {
						subTotal += itemSubTotal;
					}
					if (!ele?.taxValue) {
						totalTax += ele?.finalAmount - itemSubTotal;
					}
				}
			}
		});
		return {
			'Sub Total:': subTotal,
			'Packaging: ': data?.packagingCharges,
			'Tax On Packaging (18%): ': taxOnPackagingCharges,
			'Delivery: ': data?.deliveryCharges,
			'Tax On Delivery (18%): ': taxOnDeliveryCharges,
			'Additional Charges: ': additionalCharges,
			'Tax:':
				data?.taxType === 'STATE'
					? {
						SGST: (totalTax / 2)?.toFixed(2),
						CGST: (totalTax / 2)?.toFixed(2),
						Total: totalTax?.toFixed(2),
					}
					: {
						IGST: totalTax?.toFixed(2),
						Total: totalTax?.toFixed(2),
					},
			'Total Price: ': totalPrice,
		};
	}, [items]);

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			renderBtns={renderBtns}
			width={data?.width ?? '60rem'}
			height={data?.height ?? '30rem'}
			wrapperClassName='!overflow-y-hidden'
		>
			<AlertModal type='poItemsModal' />
			{isAdvanceBill && <p className={styles.advance}>Advance Bill</p>}
			<div className={styles.root}>
				{showPdf && pdfState && (
					<div className={styles.lhs}>
						<iframe
							className={styles.iframe}
							width='100%'
							height='100%'
							src={pdfState}
							title='PDF Rendered from Base64'
						/>
					</div>
				)}
				<div className={styles.rhs}>
					{txnHeader?.show && (
						<div className={styles.txn_header}>
							<TxnHeader
								heading={txnHeader?.heading}
								helper={txnHeader?.helper}
							/>
						</div>
					)}
					{showPoDetails && (
						<TransactionDetails
							transactionData={data?.transactionData}
						/>
					)}
					{!hideItems && (
						<div className={styles.items}>
							<Input
								value={searchQuery}
								onChange={(e) => {
									setSearchQuery(e?.target?.value);
								}}
								placeholder='Search Item'
								className='w-full'
							/>
							<div className={styles.list}>
								<ListTable
									colMap={COLMAP}
									columns={TABLE_COLUMNS}
									fixedHeaderTop={0}
									data={ITEMS}
									exportFilename={heading
										?.split(' ')
										?.join('_')}
									showTotal
									actions={[
										...SPECIAL_COLUMNS,
										...ACTION_COLUMNS,
									]?.filter((col) => {
										const defaultExluding = [
											'Order Type',
										]?.filter((ele) => {
											return !customColumns?.includes(
												ele
											);
										});
										return ![
											...([
												...(excludedColumns ?? []),
												...defaultExluding,
											] ?? [
												'Fulfilled',
												'From Process',
												'PO Id',
												'Quantity',
											]),
											showProductionPlanColumns &&
												'Production Plan Name',
										]?.includes(col?.title);
									})}
									special={SPECIAL_ITEMS}
								/>
							</div>
						</div>
					)}
					{assetGroupingSummaryData?.groupedAssets && (
						<AssetGroupSelectionSummary
							extraData={assetGroupingSummaryData?.extraData}
							cartItems={assetGroupingSummaryData?.cartItems}
							groupedAssets={assetGroupingSummaryData?.groupedAssets}
						/>
					)}
					{showTotal && (
						<TotalContainer calculatedData={calculatedData} />
					)}
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.poItemsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(POItemsModal);
