/* eslint-disable */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded';
import { Button } from '@mui/material';
import styles from './regionsMapping.module.scss';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { AddGroupModal } from '../../Components/Modals/AddGroupModal';
import { modalActions } from '../../Actions';
import { BackdropLoader } from '../../Components/Loaders';
import { notificationServices } from '../../Services';
import Chip from '@mui/material/Chip';
import regionStateServices from '../../Services/regionState.services';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

function App(props) {
	const { userPermissions, toggleAddGroupModal } = props;
	const [list1, setlist] = useState([]);
	const [regionState, setRegionState] = useState([]);
	const [list2, setlist2] = useState([]);
	const [query, setQuery] = useState('');
	const [query2, setQuery2] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isRegion, setIsRegion] = useState(null);
	const [changesMade, setChangesMade] = useState(false);
	useEffect(async () => {
		try {
			const response = await regionStateServices.getRegions();
			setRegionState(response);
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		}
	}, []);

	const FIND_FILTERS = ['regions'];

	const handleRegionsFetch = async (req) => {
		try {
			setIsLoading(true);
			const unmappedStates =
				await regionStateServices.getUnmappedStates();
			setlist2(unmappedStates);
			if (req.region) {
				const response = await regionStateServices.getRegions();
				console.log('=====>>',response);
				const region = response.find((region) => {
					return region.regionId == req.region;
				});
				setRegionState(response);
				setlist(region.states);
				setIsRegion(req.region);
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		} finally {
			setIsLoading(false);
			if (!req.region) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Please Select Region',
				});
				setlist([]);
				setlist2([]);
				setChangesMade(false);
			}
		}
	};

	const getRegionName = (isRegion) => {
		console.log(isRegion);
		const region = regionState.find((item) => {
			return item.regionId === isRegion;
		});
		return region ? region.regionName : '';
	};

	const handleLeftClick = (ele) => {
		const updatedList2 = [...list2, ele];
		setlist2(updatedList2);
		const updatedList1 = list1.filter((item) => {
			return item !== ele;
		});
		setlist(updatedList1);
		setChangesMade(true);
	};

	const handleRightClick = (ele) => {
		const updatedList1 = [...list1, ele];
		setlist(updatedList1);
		const updatedList2 = list2.filter((item) => {
			return item !== ele;
		});
		setlist2(updatedList2);
		setChangesMade(true);
	};

	const handleAddClick = () => {
		toggleAddGroupModal(true, {});
	};

	const handleAddRegion = async (groupName) => {
		try {
			const response = await regionStateServices.createRegion(groupName);
			if (response.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: 'Region created successfully!',
				});
			}
			try {
				const response = await regionStateServices.getRegions();
				setRegionState(response);
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};

	const handleSubmit = async () => {
		console.log('hello');
		try {
			setIsLoading(true);
			const reqData = {
				regionId: isRegion,
				stateIds: list1.map((state) => {
					return {
						stateId: state.stateId,
					};
				}),
			}
			const response = await regionStateServices.updateRegionStateMapping(reqData);
			const regResponse = await regionStateServices.getRegions();
			setRegionState(regResponse);
			if (response.statusCode === 200) {
				const successMessage = isRegion
					? `${getRegionName(isRegion)} updated successfully!`
					: 'Region created successfully!';
				notificationServices.generateNotification({
					type: 'success',
					message: successMessage,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
			console.log('===error',error);
		} finally {
			setIsLoading(false);
			setChangesMade(false);
		}
	};

	const handleSearch = (e) => {
		setQuery(e?.target?.value);
	};

	const handleSearch2 = (e) => {
		setQuery2(e?.target?.value);
	};

	return (
		<div className='App styles'>
			<AddGroupModal
				onSubmit={handleAddRegion}
				filterLabels={{
					addGroup: 'Add New Region',
					groupName: 'Region Name*',
					enterGroupName: 'Enter Region Name',
				}}
			/>
			<BackdropLoader showProgress isLoading={isLoading} />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Regions Mapping'
					FILTERS={FIND_FILTERS}
					onSubmit={handleRegionsFetch}
					regions={regionState}
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'Region',
						handleAddClick,
					}}
				/>
			</div>

			{(list1.length > 0 || list2.length > 0) && (
				<div
					style={{
						justifyContent: 'space-between',
						display: 'flex',
						height: '400px',
						width: '100%',
						gap: '1rem',
						margin: 'auto',
						alignItems: 'center',
						// pointerEvents: 'none',
						// border: '1px solid black',
					}}
				>
					<div
						style={{
							// width: '45%',
							height: '100%',
							border: '2px solid white',
							backgroundColor: '#A7ADC6',
							borderRadius: '8px',
							// margin: 'auto',
							flex: 1,
						}}
					>
						<div className='SimilarProducts'>
							<div className='group-box'>
								<div
									style={{
										fontSize: '18px',
										fontWeight: 'bold',
										color: '#333',
										margin: '10px',
										textAlign: 'center',
									}}
								>
									State(s) IN
									{isRegion
										? ` ${getRegionName(isRegion)}`
										: ' Region'}
								</div>
							</div>
						</div>
						<div className={styles.search_product_container}>
							<input
								placeholder='Search States'
								className={styles.search}
								type='text'
								value={query}
								onChange={handleSearch}
							/>
							<div
								style={{
									maxHeight: '300px',
									overflow: 'auto',
									padding: '10px',
									display: 'grid',
									gridTemplateColumns: 'repeat(3, 1fr)',
									// display: 'flex',
									// flexWrap: 'wrap',
									width: '100%',
									gap: '.5rem',
								}}
							>
								{list1
									.filter((ele) => {
										return ele?.name
											?.toLowerCase()
											?.includes(query?.toLowerCase());
									})
									?.map((ele) => {
										return (
											<span
												onClick={() => {
													return handleLeftClick(ele);
												}}
											>
												<Chip
													icon={
														<ArrowCircleRightIcon />
													}
													style={{
														fontSize: '15px',
														fontWeight: '600',
													}}
													label={ele.name}
													variant='outlined'
												/>
											</span>
										);
									})}
							</div>
						</div>
					</div>
					<MultipleStopRoundedIcon
						sx={{
							fontSize: 50,
						}}
					/>
					<div
						style={{
							// width: '45%',
							height: '100%',
							border: '2px solid white',
							backgroundColor: '#94A187',
							borderRadius: '8px',
							margin: 'auto',
							flex: 1,
						}}
					>
						<div className='SimilarProducts'>
							<div className='group-box'>
								<div
									style={{
										fontSize: '18px',
										fontWeight: 'bold',
										color: '#333',
										margin: '10px',
										textAlign: 'center',
									}}
								>
									State(s) Not In
									{isRegion
										? ` ${getRegionName(isRegion)}`
										: ' Region'}
								</div>
							</div>
						</div>
						<div className={styles.search_product_container}>
							<input
								placeholder='Search Unmapped States'
								className={styles.search}
								type='text'
								value={query2}
								onChange={handleSearch2}
							/>
							<div
								style={{
									maxHeight: '300px',
									overflow: 'auto',
									padding: '10px',
									display: 'grid',
									gridTemplateColumns: 'repeat(3, 1fr)',
									// display: 'flex',
									// flexWrap: 'wrap',
									width: '100%',
									gap: '.5rem',
								}}
							>
								{list2
									?.filter((ele) => {
										return ele?.name
											?.toLowerCase()
											?.includes(query2?.toLowerCase());
									})
									?.map((ele) => {
										return (
											<span
												onClick={() => {
													return handleRightClick(
														ele
													);
												}}
											>
												<Chip
													label={ele.name}
													style={{
														fontSize: '15px',
														fontWeight: '600',
													}}
													variant='outlined'
													icon={
														<ArrowCircleLeftIcon />
													}
												/>
											</span>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			)}
			{changesMade && (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						onClick={handleSubmit}
						variant='contained'
						color='primary'
					>
						Submit
					</Button>
				</div>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleAddGroupModal: modalActions.toggleAddGroupModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
