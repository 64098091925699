import {
	useEffect, useRef, useState
} from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import Input from 'rsuite/Input';
import InputPicker from 'rsuite/InputPicker';
import moment from 'moment';
import { CreatePageHeader } from '../../../Components/Headers';
import styles from './VendorOpeningCreatePage.module.scss';
import { formActions } from '../../../Actions';
import CustomInputSelector from '../../../Components/CustomInputSelector/CustomInputSelector';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import { billSubTypes } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { IconInput } from '../../../Components/Forms/Inputs';

const VendorOpeningCreatePage = (props) => {
	const { vendors } = props;
	const history = useHistory();
	const formRef = useRef(null);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		vendor: {},
		openingDate: new Date(),
		openingAmount: 0,
		subType: '',
		clientTransactionId: null,
	});

	async function fetchBillId() {
		if (state?.clientTransactionId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				setState((prev) => {
					return {
						...prev,
						clientTransactionId: response?.data,
					};
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	useEffect(() => {
		if (!state?.clientTransactionId) {
			fetchBillId();
		}
	}, [state?.clientTransactionId]);

	const handlebackAndCancel = () => {
		setState({
			vendor: {},
			openingDate: new Date(),
			subType: '',
			openingAmount: 0,
		});
		history.goBack();
	};

	const handleSave = async (e) => {
		e.preventDefault();
		// eslint-disable-next-line no-restricted-globals
		if (isNaN(Number(state?.openingAmount))) {
			notificationServices.generateNotification({
				type: 'error',
				messsage: 'Please enter valid Opening amount',
			});
			return;
		}
		if (!state?.clientTransactionId) {
			notificationServices.generateNotification({
				type: 'error',
				messsage: 'Please enter valid ID',
			});
			return;
		}
		if (!state?.subType) {
			notificationServices.generateNotification({
				type: 'error',
				messsage: 'Please select Valid Bill Type',
			});
			return;
		}
		// eslint-disable-next-line no-empty
		if (formRef.current.reportValidity()) {
			const req = {
				data: {
					isOpeningBalance: true,
					type: 'BILL',
					subType: state?.subType,
					vendorId: state?.vendor?.id,
					finalTransactionAmount: Number(state?.openingAmount),
					transactionDate: moment(state?.openingDate)?.format('M/D/YYYY'),
					dueDate: moment(state?.openingDate)?.format('M/D/YYYY'),
					clientTransactionId: `VO-${state?.clientTransactionId}`,
					vendorName: state?.vendor?.name,
				},
			};
			setLoading(true);
			try {
				const response = await inventoryAnalyticsServices.createTransactionRecord(
					req
				);
				if (response?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
					handlebackAndCancel();
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('error', error);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleVendorSelection = (vendor) => {
		setState((prev) => {
			return {
				...prev,
				vendor,
			};
		});
	};

	const handleKeyPress = () => {
		setState((prev) => {
			return {
				...prev,
				vendor: {},
			};
		});
	};

	return (
		<div className={styles.root}>
			<form ref={formRef} className={styles.form}>
				<CreatePageHeader
					heading='Add Vendor Opening'
					handleSave={handleSave}
					handleCancel={handlebackAndCancel}
					showBack
					handleBackClick={handlebackAndCancel}
				/>
				<div className={styles.form_container}>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							htmlFor='vendorSelection'
						>
							Vendor Name*
						</label>
						<CustomInputSelector
							inputName='VENDOR'
							width='300px'
							required
							inputLists={vendors?.map((ele) => {
								return {
									...ele,
									imageUrl: ele?.logo,
								};
							})}
							handleKeyPress={handleKeyPress}
							handleRemove={handleKeyPress}
							handleSelection={handleVendorSelection}
							addNewText='Add New Vendor'
							placeholder='Select A Vendor'
							selectedValue={state?.vendor?.name}
							classname={styles['vendor-selector']}
						/>
					</div>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							htmlFor='vendorSelection'
						>
							Opening Date*
						</label>
						<DatePickerCustom
							cleanable={false}
							selectedDate={state?.openingDate}
							selectDate={(rsuiteDate) => {
								if (rsuiteDate) {
									setState((prev) => {
										return {
											...prev,
											openingDate: rsuiteDate,
										};
									});
								}
							}}
							style={{
								width: 300,
							}}
							oneTap
							defaultValue={state?.openingDate}
							// shouldDisableDate={disabledDate}
						/>
					</div>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							// htmlFor={id}
						>
							Opening ID*
						</label>
						<IconInput
							type='custom'
							iconText='VO-'
							size='large'
							inputType='text'
							value={state?.clientTransactionId}
							handleInput={(e) => {
								setState((prev) => {
									return {
										...prev,
										clientTransactionId: e?.target?.value,
									};
								});
							}}
							style={{
								width: '300px',
							}}
						/>
					</div>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							htmlFor='vendorSelection'
						>
							Bill Type*
						</label>
						<InputPicker
							data={billSubTypes?.map((ele) => {
								return {
									label: ele,
									value: ele,
								};
							})}
							value={state?.subType}
							onChange={(subType) => {
								setState((prev) => {
									if (
										// eslint-disable-next-line no-restricted-globals
										!isNaN(Number(prev?.openingAmount)) &&
										Number(prev?.openingAmount) < 0
									) {
										return {
											...prev,
											subType,
											openingAmount: '',
										};
									}
									return {
										...prev,
										subType,
									};
								});
							}}
							style={{
								width: 300,
							}}
						/>
					</div>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							htmlFor='vendorSelection'
						>
							Opening Amount*
						</label>
						<Input
							placeholder='Opening Amount'
							value={state?.openingAmount}
							required
							style={{
								width: 300,
							}}
							onChange={(value) => {
								let defaultRegex = /^-?(?:\d+(\.\d*)?|\.\d+)?$/;
								if (state?.subType !== 'GRN') {
									defaultRegex = /^(?:\d+(\.\d*)?|\.\d+)?$/;
								}
								const isPositiveDecimal =
									defaultRegex.test(value);
								if (isPositiveDecimal) {
									setState((prev) => {
										return {
											...prev,
											openingAmount: value,
										};
									});
								}
							}}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	return {
		vendors,
	};
};
// ['PRODUCT', 'INTERMEDIATE']

const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VendorOpeningCreatePage);
