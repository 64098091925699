// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import PrinterConfigRoutes from './PrinterConfig Routes/printerConfig.routes';
import RiderRoutes from './Rider Routes/rider.routes';
import StoreSettingRoutes from './Store Setting Routes/storeSetting.routes';
import StoreRoutes from './Store Routes/store.routes';
import HolidayRoutes from './HolidayRoutes/holiday.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.MY_OUTLETS;
	return entity.slice(baseEntity.length);
};

const OutletRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.PRINTER_CONFIG)}`}>
				<PrinterConfigRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.RIDERS)}`}>
				<RiderRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.STORE_SETTINGS)}`}>
				<StoreSettingRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.STORES)}`}>
				<StoreRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.HOLIDAY)}`}>
				<HolidayRoutes />
			</Route>
		</Switch>
	);
};

export default OutletRoutes;
