/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
import {
	useCallback, useEffect, useState
} from 'react';
import SelectPicker from 'rsuite/SelectPicker';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styles from './MultiCascaderTree.module.scss';

const negativeSvg = (
	<svg
		id='i-close'
		viewBox='0 0 32 32'
		width='16'
		height='16'
		fill='none'
		stroke='currentcolor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth='10.9375%'
	>
		<path d='M2 30 L30 2 M30 30 L2 2' />
	</svg>
);
const positiveSvg = (
	<svg
		id='i-checkmark'
		viewBox='0 0 32 32'
		width='16'
		height='16'
		fill='none'
		stroke='currentcolor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth='10.9375%'
	>
		<path d='M2 20 L12 28 30 4' />
	</svg>
);

function TableDrop({
	data, width, handleChange, headerWidth, parentSelection, expandedIds, setExpandedIds,
}) {
	const toggleExpand = (id, type) => {
		const localExpanded = expandedIds;
		if (localExpanded[type] === id) {
			localExpanded[type] = null;
		} else {
			localExpanded[type] = id;
		}
		setExpandedIds({
			...localExpanded,
		});
	};

	return (
		<div>
			{data?.map((element) => {
				let backgroundColor = '';
				// eslint-disable-next-line max-len
				const parentStatus = element.select === 0 ? parentSelection : element.select;
				// eslint-disable-next-line max-len
				const currentStatusPos = (element.select === 0 && parentSelection === 1) || element.select === 1;
				// eslint-disable-next-line max-len
				const currentStatusNeg = (element.select === 0 && parentSelection === -1) || element.select === -1;
				if (expandedIds?.[element?.type] === element.id + '-' + element.type) {
					backgroundColor = 'white';
				}
				return (
					<div
						style={{
							backgroundColor: element.color,
							width: width + 'px',
							border: '1px solid black',
							overflow: 'hidden',
						}}
					>
						<div
							style={{
								// eslint-disable-next-line object-shorthand
								backgroundColor: backgroundColor,
								padding: '5px',
								width: headerWidth + 'px',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								fontWeight: '550',
								overflow: 'hidden',
							}}
						>
							<span
								className={styles.checkbox + ' ' +
									(element.select === 1
										? styles.positive
										: element.select === -1
											? styles.negative
											: ''
									)}
								onClick={() => {
									handleChange(element.id + '-' + element.type, parentSelection);
								}}
							>
								{element.select === 1
									? positiveSvg
									: element.select === -1
										? negativeSvg
										: ''}
							</span>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
								onClick={() => {
									toggleExpand(element.id + '-' + element.type, element.type);
								}}
							>
								<span
									style={{
										fontSize: '15px',
										userSelect: 'none',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{currentStatusPos && (
										<div style={{
											color: 'white',
											backgroundColor: 'green',
											marginRight: '3px',
											justifyContent: 'center',
											alignItems: 'center',
											display: 'flex',
											minWidth: '10px',
											width: '10px',
											height: '10px',
											borderRadius: '50%',
										}}
										>
											{' '}
										</div>
									)}
									{currentStatusNeg && (
										<div style={{
											color: 'white',
											backgroundColor: 'red',
											marginRight: '3px',
											justifyContent: 'center',
											alignItems: 'center',
											display: 'flex',
											minWidth: '10px',
											width: '10px',
											height: '10px',
											borderRadius: '50%',
										}}
										>
											{' '}
										</div>
									)}
									{element.name}
								</span>

								<span>
									{element.children?.length > 0 && (
										<KeyboardArrowRightIcon />
									)}
								</span>
							</div>
						</div>
						{element?.children?.length !== 0 &&
							expandedIds[element?.type] === element?.id + '-' + element?.type ? (
								<div
									style={{
										transform: 'translateX(' + headerWidth + 'px)',
									}}
								>
									<TableDrop
										data={element.children}
										width={width - headerWidth}
										headerWidth={headerWidth}
										handleChange={handleChange}
										parentSelection={parentStatus}
										expandedIds={expandedIds}
										setExpandedIds={setExpandedIds}
									/>
								</div>
							) : (
								''
							)}
					</div>
				);
			})}
		</div>
	);
}

function MultiCascaderTree({
	inputState = [],
	setInputState,
	headers = [],
	widthPercentage = 75,
	disabled = false,
}) {
	const propWidth = window.screen.width * (widthPercentage / 100);
	const setRemoveToChilds = (nodes, status) => {
		return nodes?.map((ele) => {
			const node = ele;
			if (node.select === 1) {
				return node;
			}
			node.notRemove = status;
			if (status === 1) {
				node.select = 0;
			}
			setRemoveToChilds(node.children, status);
			return node;
		});
	};
	const findNodeById = (nodes, id, parentStatus) => {
		return nodes.map((ele) => {
			const node = ele;
			if (node.id + '-' + node.type === id) {
				if (node.select === 1) {
					if (node.type === 'root') {
						node.select = -1;
					} else if (node.notRemove || parentStatus === -1) {
						node.select = 0;
					} else {
						node.select = -1;
					}
				} else if (node.type === 'root') {
					node.select = 1;
				} else node.select += 1;
				if (node.select === -1) {
					setRemoveToChilds(node?.children, 1);
				} else if (node.select === 0) {
					node.notRemove = parentStatus === -1 ? 1 : 0;
					setRemoveToChilds(node?.children, node?.notRemove);
				} else {
					setRemoveToChilds(node?.children, 0);
				}
			} else if (node.children?.length) {
				findNodeById(node.children, id, parentStatus);
			}
			return node;
		});
	};

	const handleChange = (id, parentStatus) => {
		const tempNodes = findNodeById(inputState, id, parentStatus);
		setInputState([...tempNodes]);
	};

	const headerLength = headers?.length ?? 5;
	const headerWidth = parseInt(propWidth / headerLength, 10);

	const [searchData, setSearchData] = useState({});

	const [expandedIds, setExpandedIds] = useState({});

	const mapSearchedData = (
		regionArray,
		possibleSearch,
		searchList,
		parentIdMapping
	) => {
		regionArray?.forEach((region) => {
			const localPs = possibleSearch + region?.name;
			const localParentIdMapping = {
				...parentIdMapping,
			}; // Create a new object
			localParentIdMapping[region?.type] =
				region?.id + '-' + region?.type;
			const localSearchList = searchList;
			localSearchList[localPs] = localParentIdMapping;
			setSearchData(localSearchList);
			mapSearchedData(
				region.children,
				localPs + '/',
				localSearchList,
				localParentIdMapping
			);
		});
	};

	useEffect(() => {
		mapSearchedData(inputState, '', {}, {});
	}, [inputState]);

	const SEARCHDATA = useCallback(() => {
		const dataPaths = Object.entries(searchData).map(([key, value]) => {
			return {
				label: key, value,
			};
		});
		return dataPaths;
	}, [searchData]);

	const handleSelect = (item) => {
		setExpandedIds(item);
	};

	return (
		<div
			style={{
				width: propWidth + 'px',
			}}
		>
			{disabled && (
				<div className={styles.disabled} />
			)}
			<div
				style={{
					margin: '5px',
					maxWidth: '400px',
					width: '400px',
				}}
			>
				<SelectPicker
					style={{
						minWidth: 400,
					}}
					data={SEARCHDATA()}
					menuStyle={{
						zIndex: 2000,
						maxHeight: '50%',
					}}
					cleanable={false}
					onSelect={(val) => {
						handleSelect(val);
					}}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					marginBottom: '3px',
				}}
			>
				{headers &&
					headers.length > 0 &&
					headers.map((region) => {
						return (
							<div
								key={region.id}
								style={{
									flex: '1',
									backgroundColor: region.color,
									padding: '10px',
									textAlign: 'center',
									width: headerWidth + 'px',
								}}
							>
								<span
									style={{
										margin: '0',
										color: '#fff',
										fontSize: '18px',
										fontWeight: '600',
									}}
								>
									{region.label}
								</span>
							</div>
						);
					})}
			</div>
			<div
				style={{
					width: propWidth + 'px',
					overflowY: 'auto',
					overflowX: 'hidden',
					height: 'calc(100% - 48px)',
				}}
			>
				<TableDrop
					data={inputState}
					width={propWidth}
					headerWidth={headerWidth}
					handleChange={handleChange}
					parentSelection={-1}
					expandedIds={expandedIds}
					setExpandedIds={setExpandedIds}
				/>
			</div>
		</div>
	);
}

export default MultiCascaderTree;
