// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { getUnitConvertedQuantity } from '../../../Helpers';

const initialState = {
	clientBillId: '',
	fromStore: null,
	store: null,
	vendor: null,
	subject: '',
	orderId: null,
	poGrnId: null,
	clientPOId: null,
	poData: {},
	deliveryCharges: 0,
	packagingCharges: 0,
	advanceBillAmount: 0,
	paymentTerm: '',
	billDate: new Date(),
	grnDate: null,
	orderDate: null,
	invoiceDoc: {
		file: '',
		fileName: '',
		changeParentDoc: false,
	},
	dueDate: new Date(new Date().setDate(new Date().getDate() + 1)),
	invoiceDate: new Date(new Date().setDate(new Date().getDate() + 1)),
	invoiceRef: '',
	invoiceAmount: '',
	description: '',
	items: [],
	additionalCharges: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = [
	{
		field: 'vendor',
		displayName: 'Vendor',
	},
	{
		field: 'clientBillId',
		displayName: 'Client Bill ID',
	},
	{
		field: 'store',
		displayName: 'Billing Store',
	},
	{
		field: 'billDate',
		displayName: 'Billing Date',
	},
	{
		field: 'invoiceDoc',
		displayName: 'Billing Date',
	},
	{
		field: 'paymentTerm',
		displayName: 'Payment Term',
	},
	{
		field: 'invoiceRef',
		displayName: 'Invoice ref ',
	},
	{
		field: 'invoiceDate',
		displayName: 'Invoice Date ',
	},
	{
		field: 'invoiceAmount',
		displayName: 'Invoice amount ',
	},
	{
		field: 'description',
		displayName: 'Notes ',
	},
];

const config = {
	initialState,
	reducer,
	serializer: (data) => {
		return data;
	},
	deserializer: (state, isAdvance) => {
		const clonedState = cloneDeep(state);
		const { ...remaining } = clonedState;
		if (isAdvance) {
			const advanceBillState = {
				dueDate: moment(remaining?.dueDate)?.format('M/D/YYYY'),
				transactionDate: moment(remaining?.billDate)?.format(
					'M/D/YYYY'
				),
				fromStore: remaining?.fromStore?.id,
				storeId: remaining?.store?.id,
				vendorId: remaining?.vendor?.id,
				vendorName: remaining?.vendor?.name,
				clientTransactionId: `BL-${remaining?.clientBillId}`,
				orderId: remaining?.orderId,
				clientOrderId: remaining?.clientPOId,
				uploadedDoc: remaining?.invoiceDoc?.file,
				changeParentDoc: remaining?.invoiceDoc?.changeParentDoc,
				type: 'BILL',
				subType: 'ADVANCE',
				orderAmount: remaining?.orderAmount,
				finalTransactionAmount: remaining?.advanceBillAmount,
				extraData: {
					paymentTerm: remaining?.paymentTerm,
				},
			};
			return advanceBillState;
		}
		const newState = {
			dueDate: moment(remaining?.dueDate)?.format('M/D/YYYY'),
			transactionDate: moment(remaining?.billDate)?.format('M/D/YYYY'),
			fromStore: remaining?.fromStore?.id,
			storeId: remaining?.store?.id,
			vendorId: remaining?.vendor?.id,
			vendorName: remaining?.vendor?.name,
			clientTransactionId: `BL-${remaining?.clientBillId}`,
			orderId: remaining?.orderId,
			clientOrderId: remaining?.clientPOId,
			uploadedDoc: remaining?.invoiceDoc?.file,
			changeParentDoc: remaining?.invoiceDoc?.changeParentDoc,
			type: 'BILL',
			subType: 'GRN',
			orderAmount: remaining?.orderAmount,
			parentId: remaining?.poGrnId,
			invoiceDate: moment(remaining?.invoiceDate)?.format('M/D/YYYY'),
			invoiceRef: remaining?.invoiceRef,
			invoiceAmount: remaining?.invoiceAmount,
			description: remaining?.description,
			extraData: {
				packagingCharges: Number(remaining?.packagingCharges),
				taxOnPackagingCharges: 18,
				deliveryCharges: Number(remaining?.deliveryCharges),
				taxOnDeliveryCharges: 18,
				paymentTerm: remaining?.paymentTerm,
				additionalCharges: remaining?.additionalCharges,
			},
			items: remaining?.items?.map((ele) => {
				let productId = ele?.productId;
				let variantId = ele?.selectedVariant?.id;
				if (ele?.selectedVariant?.displayName === 'ASSET') {
					variantId = -1;
				}
				if (ele?.selectedVariant?.displayName === 'SERVICE') {
					productId = Number(ele?.productId?.split('SERVICE_')?.[1]);
					variantId = -2;
				}
				const itemQty =
					ele?.productInputUnit !== 'count'
						? Number(
							getUnitConvertedQuantity(
								Number(ele?.quantity),
								ele?.productInputUnit,
								false,
								true
							)
						)
						: Number(ele?.quantity);
				return {
					productId,
					productName: ele?.productName,
					variantId,
					// eslint-disable-next-line no-restricted-globals
					qty: !isNaN(itemQty) ? itemQty : null,
					price: Number(ele?.selectedVariant?.price),
					tax: ele?.tax,
					subTotal: ele?.subTotal,
					totalTax: ele?.totalTax,
					finalAmount: ele?.totalPriceIncTax,
				};
			}),
		};
		return newState;
	},
	validator: (state) => {
		return mandatoryFields?.find((key) => {
			return (
				state[key?.field] === null ||
				state[key?.field] === '' ||
				!state[key?.field] === true
			);
		});
	},
};

export default config;
