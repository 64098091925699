/* eslint-disable no-tabs */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './Timeline.scss';
import { AccessTime } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
// import DeviceThermostatTwoToneIcon from '@mui/icons-material/DeviceThermostatTwoTone';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TimeComponent from './TimeComponent';
import blueTemp from '../../../Images/blue temp.gif';
import redTemp from '../../../Images/red temp.gif';
import weight from '../../../Images/weighing-machine.png';
import walkIn from '../../../Images/orderTrackingAssets/walkIN.jpg';
import zomato from '../../../Images/orderTrackingAssets/Zomato.jpg';
import swiggy from '../../../Images/orderTrackingAssets/swiggy.jpg';
// import pass from '../../../Images/pass.png';
// import fail from '../../../Images/fail.png';

function Cards({ node, handleImageClick = () => {} }) {
	// function extactNumberfromVariant(variantName) {
	// 	const regex = /(?:pack\s+of\s+|pcs\s+)(\d+)/i;
	// 	const match = variantName.match(regex);
	// 	return match ? parseInt(match[1], 10) : null;
	// }
	function chooseName(name) {
		if (name === 'UNLOADING') return 'Unloading At';
		if (name === 'VERIFICATION') return 'Verified At and Stored At';
		if (name === 'Dispatch') return 'Stocked Out At';
		if (name === 'Packaging') return 'Packed At';
		if (name === 'Audit') return 'Item Audit At';
		return 'Scanned At';
	}

	function chooseDurationName(name) {
		if (name === 'Packaging') {
			return 'Packed In';
		}
		if (name === 'Dispatch') {
			return 'Dispatch In';
		}
		if (name === 'TRANSIT') {
			return 'Transit Time';
		}
		if (name === 'UNLOADING') {
			return 'Unloading In';
		}
		if (name === 'VERIFICATION') {
			return 'Verified In';
		}
		if (name === 'Customer Dispatch') {
			return 'Order Completed In';
		}
		if (name === 'Audit') {
			return 'Audit Completed In';
		}
		return 'Stored For';
	}

	function TimeDuration(arrivedTime, dispatchtime) {
		const duration = arrivedTime &&
			dispatchtime
			? moment.duration(moment(dispatchtime).diff(moment(arrivedTime))) : null;
		let formattedDuration = '';
		if (duration) {
			const absDuration = Math.abs(duration.asMilliseconds());
			const absMomentDuration = moment.duration(absDuration);
			if (duration.asSeconds() < 60) {
				formattedDuration = `${absMomentDuration.seconds()} sec`;
			} else if (duration.asMinutes() < 60) {
				formattedDuration = `${absMomentDuration.minutes()} min ${absMomentDuration.seconds()} sec`;
			} else if (duration.asHours() < 24) {
				formattedDuration = `${Math.floor(absMomentDuration.asHours())} hrs ${absMomentDuration.minutes()} min`;
			} else {
				formattedDuration = `${Math.floor(absMomentDuration.asDays())} d ${Math.floor(absMomentDuration.hours())} hrs`;
			}
		}
		return (
			formattedDuration
		);
	}

	function TimeDurationPerItem(arrivedTime, dispatchTime, noOfItems) {
		const duration = arrivedTime &&
			dispatchTime
			? moment(dispatchTime).diff(moment(arrivedTime), 'minutes') : null;
		let scanPerMin = 0;
		if (duration) {
			scanPerMin = noOfItems / duration;
		}
		return (
			scanPerMin.toFixed(2)
		);
	}

	function convertRange(value) {
		const [amount, unit] = value.split('\n');
		const valueInGrams = parseFloat(amount);
		switch (unit) {
			case 'g':
				return (valueInGrams);
			case 'kg':
				return (valueInGrams * 1000);
			default:
				return (valueInGrams);
		}
	}

	function isDecimal(input) {
		// Check if the input string contains a decimal point.
		if (input.indexOf('.') !== -1) {
			return true;
		}
		return false;
	}

	function roundoff(value) {
		const [amount, unit] = value.split('\n');
		const newValue = parseFloat(amount);
		if (isDecimal(amount)) {
			switch (unit) {
				case 'g':
					return (`${newValue.toFixed(3)} g`);
				case 'kg':
					return (`${newValue.toFixed(3)} kg`);
				default:
					return value;
			}
		} else {
			return value;
		}
	}

	function formatName(name) {
		const words = name.split(' ');
		const formattedWords = words.map((word) => {
			if (word.charAt(0) === ' ') {
				return word.charAt(0) + word.charAt(1).toUpperCase() + word.slice(2).toLowerCase();
			}
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		});
		return formattedWords.join(' ');
	}

	function extPlatformImage(value) {
		if (value === 'zomato') {
			return zomato;
		}
		if (value === 'swiggy') {
			return swiggy;
		}
		return walkIn;
	}

	function ShowInformation(name, value) {
		return (
			<div className='info'>
				{name && (
					<div className='heading'>
						{name}
						:
						{' '}
					</div>
				)}
				<p>{value}</p>
			</div>
		);
	}

	function ShowInformationOnRight(name, value) {
		return (
			<div className='infoRight'>
				{name && (
					<div className='heading'>
						{name}
						:
						{' '}
					</div>
				)}
				<p>{value}</p>
			</div>
		);
	}

	function ShowInformationwithImage(label, value) {
		return (
			<div className='imageInfo'>
				{label && (
					<div className='heading'>
						{label}
						:
						{' '}
					</div>
				)}
				<div onClick={() => {
					handleImageClick(value);
				}}
				>
					<img
						className='showbigimage'
						height='40'
						width='70'
						src={value}
						alt={label}
					/>
				</div>
			</div>
		);
	}

	function ShowBillInformation(label, name, value) {
		return (
			<div className='info'>
				{label && (
					<div className='heading'>
						{label}
						:
						{'  '}
					</div>
				)}
				<a
					href={value}
					className='link'
				>
					<div className='download_chip'>
						<FileDownloadIcon />
						{name && <p>{name}</p>}
					</div>
				</a>

			</div>
		);
	}

	function ShowInformationInChip(infoEle) {
		const { label, icon, value, isImg } = infoEle;
		let showIcon = '';
		if (isImg) {
			showIcon = (
				<img
					src={icon}
					alt='icon'
					style={{
						height: '35px',
					}}
				/>
			);
		} else {
			showIcon = icon;
		}
		return (
			<div className='chipInfo'>

				{label && (
					<div className='heading'>
						{label}
						:
						{' '}
					</div>
				)}
				{infoEle?.minRange ? (
					<div
						className='customize-chip'
						style={{
							backgroundColor: '#F9FBF2',
						}}
					>
						<div className='range'>
							<div>min</div>
							<div>{infoEle?.minRange}</div>
						</div>
						<div className='customize-icon'>
							<img
								src={icon}
								alt='icon'
								style={{
									height: '35px',
								}}
							/>
						</div>
						<div
							className='customize-label'
							style={{
								color: (convertRange(value) <= convertRange(infoEle?.maxRange) && convertRange(value) >= convertRange(infoEle?.minRange)) ? 'green' : 'red',
							}}
						>
							{roundoff(value)}
						</div>
						<div className='customize-icon'>
							{(convertRange(value) <= convertRange(infoEle?.maxRange) && convertRange(value) >=
							convertRange(infoEle?.minRange))
								? (
									<CheckCircleIcon style={{
										color: 'green',
									}}
									/>
								) : (
									<CancelIcon style={{
										color: 'red',
									}}
									/>
								)}
						</div>
						<div className='range'>
							<div>max</div>
							<div>{infoEle?.maxRange}</div>
						</div>
					</div>
				)
					: (
						<Chip
							className='chip'
							style={{
								backgroundColor: '#F9FBF2',
								color: infoEle?.color,
							}}
							icon={showIcon}
							label={value}
							variant='outlined'
						/>
					)}
			</div>
		);
	}

	function showInformationWithIcon(infoEle) {
		const { label, img, value } = infoEle;
		return (
			<div className='info'>
				{label && (
					<div className='heading'>
						{label}
						:
						{' '}
					</div>
				)}
				<img src={img} height='20' width='20' alt='platform' />
				<p>{value}</p>
			</div>
		);
	}

	function selectInformation(infoEle) {
		const { name, valueName, value } = infoEle;
		switch (name) {
			case 'Crate ID':
				return (ShowInformation(name, value));
			case 'Total Crates Scanned':
				return (ShowInformation(name, value));
			case 'Total Crates Dispatched':
				return (ShowInformation(name, value));
			case 'Total QR Scanned':
				return (ShowInformation(name, value));
			case 'Temperature':
				return (
					ShowInformationInChip({
						label: 'Temperature',
						icon: node?.data?.temp <= 10 ? blueTemp : redTemp,
						value,
						minRange: infoEle?.minTemp,
						maxRange: infoEle?.maxTemp,
						isImg: true,
					}));
			case 'Weight':
				return (ShowInformationInChip({
					label: name,
					icon: weight,
					value,
					isImg: true,
					minRange: infoEle?.minweight,
					maxRange: infoEle?.maxweight,
				}));
			case 'Order ID':
				return infoEle?.nodeName === 'Packaging' ? ShowBillInformation(name, valueName, value) : ShowInformation(name, valueName);
			case 'Vehicle Temperature':
				return (ShowInformationwithImage(name, value));
			case 'Scanned Image':
				return (ShowInformationwithImage(name, value));
			case 'Temperature Image':
				return (ShowInformationwithImage('Temperature', value));
			case 'Invoice':
				return (ShowBillInformation(name, valueName, value));
			case 'GRN':
				return (ShowBillInformation(name, valueName, value));
			case 'Audit Summary':
				return (ShowBillInformation(name, valueName, value));
			case 'Duration':
				return (ShowInformationInChip({
					label: chooseDurationName(infoEle?.label),
					icon: <AccessTime style={{
						color: 'black',
					}}
					/>,
					value,
					isImg: false,
				}));
			case 'Record Temperature':
				return (
					ShowInformationInChip({
						label: 'Vehicle Temperature',
						icon: node?.data?.recordVehicalTemp <= 10 ? blueTemp : redTemp,
						value,
						color: 'black',
						isImg: true,
					}));
			case 'Product Scanned Per Minute':
				return (ShowInformation(name, value));
			case 'Crates Unload Per Min':
				return (ShowInformation(name, value));
			case 'Unloading At':
				return (ShowInformation(name, value));
			case 'Verified At':
				return (ShowInformation(name, value));
			case 'Packed At':
				return (ShowInformation(name, value));
			case 'Stocked Out At':
				return (ShowInformation(name, value));
			case 'Scanned At':
				return (ShowInformation(name, value));
			case 'QC Result':
				return (ShowInformationInChip(
					{
						label: infoEle?.name,
						value: infoEle?.value,
						icon: infoEle?.icon,
						color: infoEle?.value === 'Pass' ? 'green' : 'red',
						iconcolor: infoEle?.value === 'Pass' ? 'green' : 'red',
						isImg: false,
					}
				));
			case 'Pieces':
				return (ShowInformation(valueName, value));
			case 'Machine Name':
				return (ShowInformationOnRight('Scanned On', value));
			case 'urbanOId':
				return (ShowInformation('Partner Order ID', value));
			case 'extplatform':
				return (showInformationWithIcon({
					label: 'External Platform',
					value,
					img: infoEle?.icon,
				}));
			case 'extPlatformOId':
				return (ShowInformation('External Order ID', value));
			default:
				return (ShowInformation(name, value));
		}
	}

	return (
		<Card sx={{
			maxHeight: 300,
		}}
		>

			<CardContent style={{
				background: node?.data?.color,
				padding: 0,
				display: 'flex',
				width: '100%',
			}}
			>
				<div className='card_left_content'>
					<center>
						<div
							style={{
								color: 'white',
								fontSize: 20,
								fontWeight: 500,
							}}
						>
							{formatName(node?.data?.name)}
						</div>
					</center>
					<div
						className='details-containers'
						style={{
							fontSize: '0.85rem',
						}}
					>
						<div
							className='inner-details'
						>
							<div className='general-details'>
								{(!!node?.data?.totalCrates || node?.data?.totalCrates === 0) && (
									<>
										{node?.data?.name === 'Dispatch' && selectInformation(
											{
												name: 'Total Crates Dispatched',
												value: node?.data?.totalCrates,
											}
										)}
										{node?.data?.name === 'UNLOADING' && selectInformation(
											{
												name: 'Total Crates Scanned',
												value: node?.data?.totalCrates,
											}
										)}
										{node?.data?.name === 'VERIFICATION' && selectInformation(
											{
												name: 'Total QR Scanned', value: node?.data?.totalCrates,
											}
										) }

									</>
								)}
								{(!!node?.data?.temp || node?.data?.temp === 0) && (
									selectInformation({
										name: 'Temperature',
										value: `${node?.data?.temp}\u00B0C`,
										minTemp: `${node?.data?.minTemp}\u00B0C`,
										maxTemp: `${node?.data?.maxTemp}\u00B0C`,
									})
								)}
								{(!!node?.data?.recordVehicalTemp || node?.data?.recordVehicalTemp === 0) && (
									selectInformation(
										{
											name: 'Record Temperature', value: `${node?.data?.recordVehicalTemp}\u00B0C`,
										}
									)
								)}
								{(!!node?.data?.availableWeight || node?.data?.availableWeight === 0) && (
									selectInformation({
										name: 'Weight',
										value: node?.data?.availableWeight,
										minweight: node?.data?.minWeight,
										maxweight: node?.data?.maxWeight,
									})
								)}
								{node?.data?.clientPoId && (
									selectInformation({
										name: 'Order ID',
										valueName: node?.data?.clientPoId,
										value: node?.data?.poURL,
										nodeName: node?.data?.name,
									})
								)}
								{node?.data?.invoiceURL && (
									selectInformation({
										name: 'Invoice',
										valueName: node?.data?.fileName,
										value: node?.data?.invoiceURL,
									}))}
								{node?.data?.grnURL && (
									selectInformation({
										name: 'GRN',
										valueName: node?.data?.fileName,
										value: node?.data?.grnURL,
									}))}
								{node?.data?.auditDoc && (
									selectInformation({
										name: 'Audit Summary',
										value: node?.data?.auditDoc,
									})
								)}
								{node?.data?.scannedTime && (
									selectInformation({
										name: chooseName(node?.data?.name),
										value: node?.data?.name === 'VERIFICATION' ? (`${`${moment(node?.data?.scannedTime).format('hh:mm A')}  ${node?.data?.storedAt ? node?.data?.storedAt : ''}`}`) : moment(node?.data?.scannedTime).format('hh:mm A'),
									})
								)}
								{node?.data?.arrivedTime && node?.data?.dispatchTime && (node?.data?.name === 'UNLOADING' || node?.data?.name === 'VERIFICATION') && (selectInformation({
									name: node?.data?.name === 'UNLOADING' ? 'Crates Unload Per Min' : 'Product Scanned Per Min',
									value: TimeDurationPerItem(
										node?.data?.arrivedTime, node?.data?.dispatchTime, node?.data?.totalCrates
									),
								})
								)}
								{node?.data?.orderId && (
									selectInformation({
										name: 'urbanOId',
										value: node?.data?.orderId,
									})
								)}
								{node?.data?.extPlatform && (
									selectInformation({
										name: 'extplatform',
										value: node?.data?.extPlatform,
										icon: extPlatformImage(node?.data?.extPlatform),
									})
								)}
								{node?.data?.extPlatformOId && (
									selectInformation({
										name: 'extPlatformOId',
										value: node?.data?.extPlatformOId,
									})
								)}
								{!!node?.data?.pieces && node?.data?.name === 'Customer Dispatch' && (
									selectInformation({
										name: 'Pieces',
										valueName: node?.data?.pieces ? 'Order Qty / Avaiable Pcs' : 'Order Qty',
										// eslint-disable-next-line no-useless-concat
										value: `${node?.data?.pieces + (node?.data?.totalPieces ? `/${node?.data?.totalPieces}` : '')} PCS`,
									})
								)}
								{ node?.data?.name !== 'Labelling' && node?.data?.arrivedTime && node?.data?.dispatchTime && (selectInformation({
									name: 'Duration',
									label: node?.data?.name,
									value: TimeDuration(node?.data?.arrivedTime, node?.data?.dispatchTime),
								})
								)}
								{/* {node?.data?.qcResult && (
									selectInformation({
										name: 'QC Result',
										value: node?.data?.qcResult,
										icon: node?.data?.qcResult === 'Pass' ? (
											<CheckCircleIcon style={{
												color: 'green',
											}}
											/>
										) : (
											<CancelIcon style={{
												color: 'red',
											}}
											/>
										),
									})
								)} */}
								{node?.data?.expiryTime && (
									selectInformation({
										name: 'Expiry',
										value: moment(node?.data?.expiryTime).format('YYYY-MM-DD hh:mm A'),
									})
								)}
								{node?.data?.name === 'Audit' && node?.data?.auditItemId === null && (
									selectInformation({
										name: 'Item Status',
										value: 'Item Stocked Out',
									})
								)}
							</div>
							<div style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '0.5rem',
							}}
							>
								{node?.data?.vehicalTemp && (
									selectInformation({
										name: 'Vehicle Temperature',
										value: node?.data?.vehicalTemp,
									})
								)}
								{node?.data?.skuImage && (
									selectInformation({
										name: 'Scanned Image',
										value: node?.data?.skuImage,
									})
								)}
								{node?.data?.machine_name && (
									selectInformation({
										name: 'Machine Name',
										value: node?.data?.machine_name,
									})
								)}
								{node?.data?.tempImage && (
									selectInformation({
										name: 'Temperature Image',
										value: node?.data?.tempImage,
									})
								)}
								{!!node?.data?.pieces && node?.data?.name === 'Labelling' && (
									selectInformation({
										name: 'Pieces',
										valueName: 'Pieces',
										// eslint-disable-next-line no-useless-concat
										value: `${node?.data?.pieces + (node?.data?.totalPieces ? `/${node?.data?.totalPieces}` : '')} PCS`,
									})
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='card_right_component'>
					<TimeComponent
						name={node?.data?.name}
						arrivedTime={node?.data?.arrivedTime}
						dispatchTime={node?.data?.dispatchTime}
					/>
				</div>
			</CardContent>
		</Card>
	);
}

export default Cards;
