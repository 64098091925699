import {
	Switch, Checkbox, NativeSelect
} from '@mui/material';
import { DraggableHTabs } from '../../../../Draggable';
import { IconInput } from '../../../Inputs';
import styles from './VariantQualityConfig.module.scss';

const VariantQualityConfig = (props) => {
	const {
		fields,
		activeVariant,
		setActiveVariant,
		mandatoryFields,
		handleInput,
		isDisabled,
	} = props;

	const activeVariantFields = fields?.variants?.items?.[activeVariant] ?? {};

	return (
		<div className={styles.root}>
			{/* QMS enabled */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Quality Management
				</label>
				<Switch
					name='QMSEnabled'
					value='QMSEnabled'
					size='small'
					color='primary'
					disabled={isDisabled}
					onChange={handleInput}
					checked={fields?.QMSEnabled}
					inputProps={{
						'data-type': 'QMS',
					}}
				/>
			</div>
			{/* weight check */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Weight Check
				</label>
				<Switch
					name='applied'
					value='applied'
					size='small'
					color='primary'
					disabled={isDisabled}
					onChange={handleInput}
					checked={fields?.weightCheck}
					inputProps={{
						'data-type': 'weight',
					}}
				/>
			</div>
			{/* temp check */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Temperature Check
				</label>
				<Switch
					name='applied'
					value='applied'
					size='small'
					color='primary'
					disabled={isDisabled}
					onChange={handleInput}
					checked={fields?.tempCheck}
					inputProps={{
						'data-type': 'temperature',
					}}
				/>
			</div>
			<fieldset
				className='form-fieldset'
				style={{
					flexBasis: '100%',
				}}
				disabled={isDisabled}
			>
				{/* temp params */}
				{fields?.tempCheck && (
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='minTemp'
							data-mandatory={
								fields.tempCheck && mandatoryFields.minTemp
							}
						>
							Min Temperature (℃)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='℃'
							name='minTemp'
							id='minTemp'
							value={fields?.tempParams?.minTemp}
							handleInput={handleInput}
							dataAttrs={{
								min: -20,
								max: Math.min(120, fields?.tempParams?.maxTemp),
								required:
									fields.tempCheck && mandatoryFields.minTemp,
								'data-type': 'temperature',
							}}
							step='0.1'
						/>
						<label
							htmlFor='maxTemp'
							data-mandatory={mandatoryFields.maxTemp}
							className={`${styles.label} form-label`}
						>
							Max Temperature (℃)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='℃'
							name='maxTemp'
							id='maxTemp'
							value={fields?.tempParams?.maxTemp}
							handleInput={handleInput}
							dataAttrs={{
								min: Math.max(-20, fields?.tempParams?.minTemp),
								max: 120,
								required:
									fields.temperature &&
									mandatoryFields.maxTemp,
								'data-type': 'temperature',
							}}
							step='0.1'
						/>
						<span
							className='form-info'
							style={{
								margin: 'auto 0px',
								padding: '0px 1rem',
							}}
						>
							(Permissible range for temperature is -20℃ to 120℃)
						</span>
					</div>
				)}
			</fieldset>
			{/* defect check */}
			<div className='form-field row'>
				<label htmlFor='QMSEnabled' className='form-label'>
					Defect Check
				</label>
				<Switch
					name='applied'
					value='applied'
					size='small'
					color='primary'
					disabled={isDisabled}
					onChange={handleInput}
					checked={fields?.defectCheck}
					inputProps={{
						'data-type': 'defect',
					}}
				/>
			</div>
			{/* defect params */}
			{fields?.defectCheck && (
				<div className='form-field row'>
					<label htmlFor='QMSEnabled' className='form-label'>
						On the basis of Area
					</label>
					<Checkbox
						name='areaSelection'
						value='areaSelection'
						size='small'
						color='primary'
						disabled={isDisabled}
						onChange={handleInput}
						checked={fields?.defectParams?.areaSelection}
						inputProps={{
							'data-type': 'defect',
						}}
					/>
					<label htmlFor='QMSEnabled' className='form-label'>
						On the basis of Count
					</label>
					<Checkbox
						name='countSelection'
						value='countSelection'
						size='small'
						color='primary'
						disabled={isDisabled}
						onChange={handleInput}
						checked={fields?.defectParams?.countSelection}
						inputProps={{
							'data-type': 'defect',
						}}
					/>
					<span
						className='form-info'
						style={{
							margin: 'auto 0px',
							padding: '0px 1rem',
						}}
					>
						(Select at least one criteria for defect based rejection)
					</span>
				</div>
			)}

			<fieldset
				className={`form-fieldset ${styles['defect-field']}`}
				style={{
					flexBasis: '100%',
				}}
				disabled={isDisabled}
			>
				{/* defect params */}
				{fields?.defectCheck && fields?.defectParams?.areaSelection && (
					<div
						className='form-field row'
						style={{
							width: '30%',
						}}
					>
						<label className='form-label' htmlFor='areaThreshold'>
							Area Threshold (%)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='%'
							name='areaThreshold'
							id='areaThreshold'
							value={fields?.defectParams?.areaThreshold}
							handleInput={handleInput}
							dataAttrs={{
								min: 0,
								max: 100,
								required:
									fields.defectCheck &&
									mandatoryFields.areaThreshold,
								'data-type': 'defect',
							}}
							step='0.1'
						/>
					</div>
				)}
				{fields?.defectCheck &&
					fields?.defectParams?.areaSelection &&
					fields?.defectParams?.countSelection && (
					<NativeSelect
						defaultValue={false}
						id='andCondition'
						name='andCondition'
						value={fields?.defectParams?.andCondition}
						onChange={handleInput}
						inputProps={{
							'data-type': 'defect',
						}}
					>
						<option value={false}>OR</option>
						<option value>AND</option>
					</NativeSelect>
				)}
				{fields?.defectCheck && fields?.defectParams?.countSelection && (
					<div
						className='form-field row'
						style={{
							width: '30%',
						}}
					>
						<label className='form-label' htmlFor='countThreshold'>
							Count Threshold (nos)
						</label>
						<IconInput
							size='large'
							type='custom'
							iconText='nos'
							name='countThreshold'
							id='countThreshold'
							value={fields?.defectParams?.countThreshold}
							handleInput={handleInput}
							dataAttrs={{
								min: 0,
								max: 100,
								required:
									fields.defectCheck &&
									mandatoryFields.countThreshold,
								'data-type': 'defect',
							}}
							step='0.1'
						/>
					</div>
				)}
			</fieldset>
			<DraggableHTabs
				items={fields.variants.items}
				order={fields.variants.order}
				handleActiveTab={setActiveVariant}
				activeTab={activeVariant}
				limit={10}
				text=''
				isDisabled
				isDraggable={false}
			/>
			<div className={`${styles.root} row`}>
				<fieldset
					className={`${styles['enable-disable-container']} form-fieldset`}
					style={{
						flexBasis: '100%',
					}}
					disabled={isDisabled}
				>
					{/* weight params */}
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='minWeight'
							data-mandatory={mandatoryFields.minWeight}
						>
							Min Weight (grams)
						</label>

						<IconInput
							size='large'
							type='custom'
							iconText='gram'
							name='minWeight'
							id='minWeight'
							className={styles.inputWidthOverride}
							value={activeVariantFields?.weightCheck?.minWeight}
							handleInput={handleInput}
							dataAttrs={{
								min: 0,
								max: activeVariantFields?.weightCheck
									?.maxWeight,
								required: mandatoryFields.minWeight,
								'data-type': 'weight',
								'data-id': activeVariant,
							}}
							step='0.1'
						/>
						<label
							className={`${styles.label} form-label`}
							htmlFor='maxWeight'
							data-mandatory={mandatoryFields.maxWeight}
						>
							Max Weight (grams)
						</label>

						<IconInput
							size='large'
							type='custom'
							iconText='gram'
							className={styles.inputWidthOverride}
							name='maxWeight'
							id='maxWeight'
							value={activeVariantFields?.weightCheck?.maxWeight}
							handleInput={handleInput}
							dataAttrs={{
								min: Math.max(
									-20,
									activeVariantFields?.weightCheck?.minWeight
								),
								max: 120000,
								required: mandatoryFields.maxWeight,
								'data-type': 'weight',
								'data-id': activeVariant,
							}}
							step='0.1'
						/>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export default VariantQualityConfig;
