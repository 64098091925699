import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import moment from 'moment';
// import moment from 'moment';
import styles from './GeneralDetails.module.scss';
import { generateRandomKey } from '../../../../Helpers';

const GeneralDetails = (props) => {
	const { fields, isDisabled } = props;
	const handleStartsFromDateTime = (e) => {
		props.handleDateTime(e, 'startsFrom');
	};
	const handleEndsFromDateTime = (e) => {
		props.handleDateTime(e, 'endsOn');
	};
	// const changedDate = () => {
	// moment();
	// };
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* product name */}
				<div className='form-field row'>
					<div className='form-label'>Product Name</div>
					<div className='form-value'>{fields.name}</div>
				</div>
				{/* brand name */}
				<div className='form-field row'>
					<div className='form-label'>Brand Name</div>
					<div className='form-value'>{fields.brand}</div>
				</div>
				<div
					className='form-field row'
					style={{
						alignItems: 'center',
					}}
				>
					<div className='form-label'>Starts From</div>
					<div
						className='form-value'
						data-name='startsFrom'
						disabled={isDisabled}
					>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								disableOpenPicker={isDisabled}
								label='Subscription Starts On'
								value={fields.startsFrom}
								onChange={handleStartsFromDateTime}
								renderInput={(params) => {
									return <TextField {...params} />;
								}}
								{...generateRandomKey}
							/>
						</LocalizationProvider>
					</div>
				</div>
				<div
					className='form-field row'
					style={{
						alignItems: 'center',
					}}
				>
					<div className='form-label'>Ends On</div>
					<div
						className='form-value'
						data-name='endsOn'
						disabled={isDisabled}
					>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								disableOpenPicker={isDisabled}
								readOnly={isDisabled}
								label='Subscription Ends On'
								value={fields.endsOn}
								onChange={handleEndsFromDateTime}
								renderInput={(params) => {
									return <TextField {...params} />;
								}}
								minDate={moment(fields.startsFrom, 'DDHH:mm')}
								minDateTime={fields.startsFrom}
							/>
						</LocalizationProvider>
					</div>
				</div>
			</div>
			<div
				style={{
					marginLeft: '3rem',
					flexBasis: '50%',
				}}
			>
				{/* display image */}
				<div
					className='form-field row'
					style={{
						alignItems: 'flex-start',
					}}
				>
					<span className='form-label'>Display Image</span>
					<div className='display-pic-root'>
						<div className='display-pic-preview'>
							<img
								src={fields.image}
								className={`display-pic ${
									fields.image ? 'show' : 'hide'
								}`}
								alt='product-display'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	fields: {},
};

export default GeneralDetails;
