// import { useEffect } from 'react';
// import { get } from 'lodash';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import { generateRequiredProps } from '../../../Helpers';
import { IconInput } from '../Inputs';
import styles from './PromoCodeDetails.module.scss';

const PromoCodeDetails = (props) => {
	const { mandatoryFields, fields, errors, handleInput } = props;

	return (
		<div className={`${styles.root}`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* coupon code */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='couponCode'
						data-mandatory={mandatoryFields.couponCode}
					>
						Coupon Code
					</label>
					<input
						className='form-value'
						type='text'
						// placeholder='Enter Coupon Code'
						id='couponCode'
						name='couponCode'
						onInput={handleInput}
						value={fields.couponCode}
						{...generateRequiredProps(mandatoryFields.couponCode)}
					/>
					<span className='form-error'>{errors.couponCode}</span>
				</div>
				{/* no of coupons */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='noOfCoupons'
						data-mandatory={mandatoryFields.noOfCoupons}
					>
						No Of Coupons (per account)
					</label>
					<input
						className='form-value'
						type='number'
						id='noOfCoupons'
						name='noOfCoupons'
						onInput={handleInput}
						value={fields.noOfCoupons}
						{...generateRequiredProps(mandatoryFields.noOfCoupons)}
						min='0'
						max='500'
					/>
					<span className='form-error'>{errors.noOfCoupons}</span>
				</div>
				{/* description */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='description'
						data-mandatory={mandatoryFields.description}
					>
						Description
					</label>
					<textarea
						className='form-value'
						type='text'
						id='description'
						name='description'
						// placeholder='Enter here ...'
						onInput={handleInput}
						value={fields.description}
						{...generateRequiredProps(mandatoryFields.description)}
					/>
					<span className='form-error'>{errors.description}</span>
				</div>
				{/* Discount Type */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='discountType'
						data-mandatory={mandatoryFields.discountType}
					>
						Discount Type
					</label>
					<select
						className='form-value'
						id='discountType'
						name='discountType'
						onChange={handleInput}
						value={fields.discountType}
						{...generateRequiredProps(mandatoryFields.discountType)}
					>
						<option value='FIXED'>Fixed</option>
						<option value='PERCENTAGE'>Percentage</option>
					</select>
					<span className='form-error'>{errors.discountType}</span>
				</div>
				{/* discount */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='discount'
						data-mandatory={mandatoryFields.discount}
					>
						Discount
					</label>
					<div className='form-value row'>
						<IconInput
							size='large'
							type={
								fields.discountType === 'FIXED'
									? 'rupee'
									: 'percent'
							}
							dataAttrs={{
								required: mandatoryFields.discount,
							}}
							name='discount'
							id='discount'
							value={fields.discount}
							handleInput={handleInput}
							dataType=''
							dataId=''
							isDisabled
						/>
					</div>
					<span className='form-error'>{errors.discount}</span>
				</div>
				{/* minimum order amount */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='minOrderAmount'
						data-mandatory={mandatoryFields.minOrderAmount}
					>
						Minimum Order Amount
					</label>
					<div className='form-value row'>
						<IconInput
							size='large'
							type='rupee'
							name='minOrderAmount'
							id='minOrderAmount'
							value={fields.minOrderAmount}
							handleInput={handleInput}
							dataType=''
							dataId=''
							isDisabled
							dataAttrs={{
								required: mandatoryFields.minOrderAmount,
							}}
						/>
					</div>
					<span className='form-error'>{errors.minOrderAmount}</span>
				</div>
				{/* maximum Discount Amount  */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='maxDiscountAmount'
						data-mandatory={mandatoryFields.maxDiscountAmount}
					>
						Maximum Discount Amount
					</label>
					<div className='form-value row'>
						<IconInput
							size='large'
							type='rupee'
							name='maxDiscountAmount'
							id='maxDiscountAmount'
							value={fields.maxDiscountAmount}
							handleInput={handleInput}
							dataType=''
							dataId=''
							dataAttrs={{
								required: mandatoryFields.maxDiscountAmount,
							}}
							isDisabled
						/>
					</div>
					<span className='form-error'>
						{errors.maxDiscountAmount}
					</span>
				</div>
			</div>
		</div>
	);
};

PromoCodeDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

const mapStateToProps = (state) => {
	const data = state.form.PROMO_CODES.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchPromoCode: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeDetails);
