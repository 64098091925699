import { useEffect, useState } from 'react';

export default function useKeyPress() {
	const [keyPressed, setKeyPressed] = useState('');

	const keyPressHandler = ({ key }) => {
		if (
			key === 'ArrowLeft' ||
			key === 'ArrowRight'
		) {
			setKeyPressed(key);
		}
	};

	const keyUpHandler = ({ key }) => {
		if (
			key === 'ArrowLeft' ||
			key === 'ArrowRight'
		) {
			setKeyPressed('');
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyPressHandler);
		window.addEventListener('keyup', keyUpHandler);
		return () => {
			window.removeEventListener('keydown', keyPressHandler);
			window.removeEventListener('keyup', keyUpHandler);
		};
	}, []);

	return keyPressed;
}
