import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../';
import {
	addCommas, classes, convertToHigherUnit
} from '../../Helpers';
import styles from './AuditAnalysisListPage.module.scss';
import analyticsAction from '../../Actions/analytics.actions';
import { useAuth } from '../../Components/Auth/Login/auth';
// import { ListTable } from '../../Components/ListTable';
import { VirtualizedListTable } from '@/Components/VirtualizedListTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Variance Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Variance QTY',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Variance Cost',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'Product Name': 'productName',
	Unit: 'unit',
	Store: 'storeName',
	'Variance Date': 'auditClosedAt',
	'Variance QTY': 'totalLossQty',
	'Variance Cost': 'totalLoss',
	// 'Cost Category': 'costCategory',
};

const getRowStyles = (item) => {
	switch (item?.result) {
		default:
			return styles.normal;
	}
};

const AuditAnalysisListPage = (props) => {
	// const history = useHistory();
	const { data, setAnalyticsType, ANALYTICS_TYPE, exportVarianceAnalytics } = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;

	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, []);

	const SPECIAL_ITEMS = {
		'Product Name': (_, obj) => {
			// eslint-disable-next-line prefer-template
			return obj?.productName + (obj?.variantName ? ' (' + obj?.variantName + ')' : '');
		},
		Unit: (unitValue) => {
			return convertToHigherUnit(unitValue)?.unit;
		},
		'Variance Cost': (value) => {
			return addCommas(value, measurement, true);
		},
		'Variance QTY': (_, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(obj?.unit, (obj.totalLossQty ?? 0))?.value?.toFixed(2);
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<VirtualizedListTable
				fixedHeaderTop={0}
				hasDateRange
				// renderColumn={renderColumn}
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				data={data?.map((obj) => {
					const rowStyles = getRowStyles(obj);
					return {
						...obj,
						rowStyles,
						rowId: obj?.id,
						id: obj?.variantId,
					};
				})}
				columns={[...TABLE_COLUMNS]}
				actions={[]}
				onExportClick={exportVarianceAnalytics}

			/>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	exportVarianceAnalytics: analyticsAction.exportVarianceAnalytics,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AuditAnalysisListPage);
