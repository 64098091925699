import { Add } from '@mui/icons-material';
import styles from './AddVContainer.module.scss';

const AddVContainer = (props) => {
	const { text, handleClick } = props;
	return (
		<div className={`${styles.root}`} onClick={handleClick}>
			<Add className={`${styles['add-icon']}`} />
			{text}
		</div>
	);
};

export default AddVContainer;
