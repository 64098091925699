/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import {
	Button, IconButton, MenuItem, TextField
} from '@mui/material';
import {
	useEffect, useReducer, useState
} from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import InputPicker from 'rsuite/InputPicker';
import styles from './MyMachinesCreatePage.module.scss';
import config from './myMachinesCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../../Services';
import { ListModal } from '../../../../Components/Modals';
import { formActions, modalActions } from '../../../../Actions';
import licenseServices from '../../../../Services/license-services';
import BackdropLoader from '../../../../Components/Loaders/BackdropLoader';
import { inputHelper, useQuery } from '../../../../Helpers';
import myMachinesServices from '../../../../Services/AssetServices/myMachine.service';
import DatePickerCustom from '../../../../Components/DatePickerCustom/DatePickerCustom';

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		inputLabel,
		labelText,
		mandatory,
		optionsList,
		search = false,
		handleSelect,
	} = props;
	return (
		<div className={`${styles.tile} form-field row`}>
			<label
				className={`${mandatory ? styles.mandatory : ''} form-label`}
				htmlFor={id}
			>
				{labelText}
			</label>
			{!search ? (
				<TextField
					id={id}
					select
					name={name}
					value={value}
					onChange={onChange}
					label={inputLabel}
					sx={{
						width: 225,
						'.MuiInputBase-root': {
							borderRadius: '4px',
						},
					}}
				>
					{optionsList?.map((option) => {
						return (
							<MenuItem
								key={option?.id ?? option}
								value={option?.id ?? option}
							>
								{option?.title ?? option}
							</MenuItem>
						);
					})}
				</TextField>
			) : (
				<InputPicker
					preventOverflow
					data={optionsList}
					name={name}
					onSelect={(val) => handleSelect(val)}
					style={{
						width: 224,
					}}
				/>
			)}
		</div>
	);
};

function MyMachinesCreatePage(props) {
	const { fetchStoresList, stores, processTypes, fetchProcessTypesList } =
		props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const {
		machineName, macAddress, store, process, manufacturedOn,
		installedOn,
		expiresOn,
		serialNo,
		modelNo,
		softwareVersion,
		weightThreshold,
	} = state ?? {};
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const isEdit = Boolean(params.get('isEdit'));
	const machineId = params.get('machineId') ? parseInt(params.get('machineId'), 10) : null;
	// console.log('machineId', machineId);
	const handleListFetch = async () => {
		try {
			const responseData =
				await myMachinesServices.fetchListOfMachines({
					req: {
						data: {
							storeId: store,
							machineId,
						},
					},
				});
			if (responseData?.statusCode === 200) {
				dispatch({
					type: 'SET_VALUE',
					payload: {
						softwareVersion: responseData?.data?.latestVersion,
					},
				});
				if (isEdit && machineId) {
					dispatch({
						type: 'SET_VALUE',
						payload: config?.serializer(responseData?.data?.machinesList?.[0]),
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		handleListFetch();
	}, []);

	useEffect(() => {
		if (store) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: store,
					},
				},
				method: 'LIST',
			});
		}
	}, [store]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		if (isEdit && isNaN(machineId)) {
			history.goBack();
		}
	}, [isEdit, machineId]);

	const handleConfirm = async () => {
		const deserializedData = config?.deserializer(state);
		const validate = config?.validator(deserializedData);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		const req = {
			params: {},
			data: {
				...deserializedData,
			},
		};
		if (machineId) {
			req.params.machineId = machineId;
		}
		setLoading(true);
		try {
			const response = await myMachinesServices.addNewMachine(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		if (fieldName === 'weightThreshold') {
			if (fieldValue <= 0) {
				if (fieldValue === '') {
					dispatch({
						type: 'SET_VALUE',
						payload: {
							[fieldName]: '',
						},
					});
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Weight Threshold must be greater than 0',
					});
				}
				return;
			}
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[fieldName]: fieldValue,
			},
		});
	};

	const handleManufacturedOn = (date) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				manufacturedOn: date,
			},
		});
	};
	const handleInstalledOn = (date) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				installedOn: date,
			},
		});
	};
	const handleExpiresOn = (date) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				expiresOn: date,
			},
		});
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={loading} showProgress />
			{/* <ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/> */}
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>Add New Machine</p>
				</div>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
			<div className={styles.body}>
				{!isEdit && (
					<div className={`${styles.tile} form-field row`}>
						<label className='form-label'>
							Software Version(latest)
						</label>
						<p>{softwareVersion?.version}</p>
					</div>
				)}
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Machine Name*
						</label>
						<TextField
							id='machineName'
							name='machineName'
							value={machineName}
							onChange={handleChange}
							placeholder='Enter Machine Name'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Mac Address*
						</label>
						<TextField
							id='macAddress'
							name='macAddress'
							value={macAddress}
							onChange={handleChange}
							placeholder='Enter MAC address'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Model Number*
						</label>
						<TextField
							id='modelNumber'
							type='number'
							name='modelNo'
							value={modelNo}
							onChange={handleChange}
							placeholder='Enter Model Number'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Serial Number*
						</label>
						<TextField
							id='serialNo'
							type='number'
							name='serialNo'
							value={serialNo}
							onChange={handleChange}
							placeholder='Enter Serial Number'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Weight Threshold*
					</label>
					<TextField
						id='thresholdDelta'
						type='number'
						name='weightThreshold'
						value={weightThreshold}
						onChange={handleChange}
						placeholder='Enter Weight Threshold'
						sx={{
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				<LabelledInput
					mandatory
					id='baseStore'
					name='store'
					value={store}
					handleSelect={(val) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								store: val,
							},
						});
					}}
					search
					optionsList={stores?.map((ele) => {
						return {
							value: ele?.id,
							label: ele?.name,
						};
					})}
					labelText='Select Machine At Store*'
					inputLabel='Select Machine At Store'
				/>
				<LabelledInput
					id='processType'
					name='process'
					value={process}
					onChange={handleChange}
					optionsList={processTypes?.map((ele) => {
						return {
							...ele,
							title: ele?.data?.heading,
						};
					})}
					labelText='Select process'
					inputLabel='Select process'
				/>
				<div className={styles.multi}>
					<div className={styles.tile}>
						<label className={styles.mandatory} htmlFor='startDate'>
							Manufactured On*
						</label>
						<DatePickerCustom
							selectedDate={manufacturedOn}
							selectDate={handleManufacturedOn}
							style={{
								width: 225,
							}}
							oneTap
						/>
					</div>
					<div className={styles.tile}>
						<label className={styles.mandatory} htmlFor='endDate'>
							Installed On*
						</label>
						<DatePickerCustom
							selectedDate={installedOn}
							selectDate={handleInstalledOn}
							style={{
								width: 225,
							}}
							oneTap
							shouldDisableDate={(dates) => {
								return manufacturedOn && dates < manufacturedOn;
							}}
						/>
					</div>
				</div>
				<div className={styles.tile}>
					<label className={styles.mandatory} htmlFor='endDate'>
						Expires On*
					</label>
					<DatePickerCustom
						selectedDate={expiresOn}
						selectDate={handleExpiresOn}
						style={{
							width: 225,
						}}
						oneTap
						shouldDisableDate={(dates) => {
							return manufacturedOn && dates < manufacturedOn;
						}}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;

	return {
		userPermissions,
		stores,
		processTypes,
	};
};

const mapDispatchToProps = {
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyMachinesCreatePage);
