/* eslint-disable no-unused-vars */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';

function AppendToSearchList(data, searchList) {
	if (data?.id) {
		if (data?.entityType?.toLowerCase() === 'store') {
			searchList.push({
				...data,
			});
		}
		data?.children?.forEach((ele) => {
			AppendToSearchList(ele, searchList);
		});
	}
}

function flatenDataArray(data) {
	const searchList = [];
	AppendToSearchList(data, searchList);
	return searchList;
}

export default function SearchBar({ data, handleSearch }) {
	const [flattenArray, setFlattenArray] = useState([]);
	useEffect(() => {
		if (data) {
			setFlattenArray(flatenDataArray(data));
		}
	}, [data]);
	return (
		<Autocomplete
			disablePortal
			id='combo-box-demo'
			getOptionLabel={(option) => {
				return option.name;
			}}
			onChange={(event, selectedOption) => {
				handleSearch(selectedOption);
			}}
			options={flattenArray}
			sx={{
				width: 300,
				background: 'rgba(255,255,255,.1)',
			}}
			renderInput={(params) => {
				return <TextField {...params} label='Search Store' />;
			}}
		/>
	);
}
