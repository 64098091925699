import {
	Route, Switch, useRouteMatch
} from 'react-router';
import { BrandingCreatePage } from '../../../Pages/Accounts/Branding';
import BrandingViewPage from '../../../Pages/Accounts/Branding/BrandingViewPage';
// eslint-disable-next-line object-curly-newline
import { CreatePage, ViewPage } from '../../../Pages';
// import BrandingCreatePageMobile from '../../../Pages/Accounts/Branding/BrandingCreatePageMobile';

const BrandingRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<ViewPage viewPage={<BrandingViewPage />} />
			</Route>
			<Route exact path={`${path}/edit`}>
				<CreatePage
					createPage={<BrandingCreatePage />}
					entity='BRANDING'
				/>
			</Route>
		</Switch>
	);
};

export default BrandingRoutes;
