import Tool from '../LabelGeneratorTool/Tool';
// import Button from '../LabelGeneratorButton/Button';
import barCodeImg from '../../../Images/LabelGenerator/barCode.jpg';

function ToolBox(props) {
	const handleDrag = (id) => {
		props.toolInUse(id);
	};
	// const myComponentsResponse = () => {
	// props.myComponents();
	// };
	let content = (
		<div className='tool-box'>
			<div id='tools'>

				<Tool
					src='https://s3.amazonaws.com/static.graphemica.com/glyphs/i500s/000/010/225/original/0054-500x500.png?1275328233'
					info='Text'
					toolDragged={handleDrag}
				/>
				<Tool
					src='https://s3.amazonaws.com/static.graphemica.com/glyphs/i500s/000/007/226/original/0053-500x500.png'
					info='Static Text'
					toolDragged={handleDrag}
				/>
				<Tool
					src='https://miro.medium.com/v2/resize:fit:405/1*k0h04CRy0j8MbBbqsodGAA.png'
					info='qrCode'
					toolDragged={handleDrag}
				/>
				<Tool
					src={barCodeImg}
					info='barCode'
					toolDragged={handleDrag}
				/>
				{/* <Tool
					src='https://static.thenounproject.com/png/2192072-200.png'
					info='table'
					toolDragged={handleDrag}
				/> */}
				{/* <Tool
					src='https://static.thenounproject.com/png/2192072-200.png'
					info='Insert'
					toolDragged={handleDrag}
				/> */}
			</div>

			{/* <Button
				text='My Components'
				type='my-components'
				handleClick={(e) => { return myComponentsResponse(e?.targetId); }}
				variant='tertiary'
			/> */}
		</div>
	);
	if (!(
		!props?.myComponentsClicked &&
		!props?.savedLabelsClicked &&
		!props?.previewLabelClicked
	)) {
		content = <div />;
	}
	return <div id='tools-box'>{content}</div>;
}
export default ToolBox;
