// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { urls } from '../../../Constants';
import { AiOutwardingImagesPage } from '../../../Pages/Analytics/AiOutwardingAnalysis';
import { AiOutwardingHistory } from '../../../Pages/Analytics/AiOutwardingAnalysis/AiOutwardingHistory';

const getSubURL = (entity) => {
	const baseEntity = urls.AI_OUTWARDING;
	return entity.slice(baseEntity.length);
};

const AiOutwardingRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.AI_OUTWARDING_IMAGES)}`}>
				<AiOutwardingImagesPage />
			</Route>
			<Route path={`${path}${getSubURL(urls.AI_OUTWARDING_HISTORY)}`}>
				<AiOutwardingHistory />
			</Route>
		</Switch>
	);
};

export default AiOutwardingRoutes;
