/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../Shared';

const annotationServices = {
	fetchProducts,
	fetchQMSAnnotations,
	fetchTempAnnotations,
	submitAnnotations,
	fetchIMSAnnotations,
	fetchContainers,
	bulkValidation,
	submitTempAnnotations,
};

function fetchProducts() {
	const url = endpoints.FETCH_PRODUCTS;

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};

	if (url === 1) {
		return mockFetchProducts(url, requestOptions);
	}
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchContainers(req) {
	const url = endpoints.FETCH_CONTAINERS;

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	if (url === 1) {
		return mockFetchProducts(url, requestOptions);
	}
	return fetch(url, requestOptions).then(handleResponse);
}

function mockFetchProducts() {
	const mockResponse = {
		message: 'Loaded successfully',
		status: 'Success',
		statusCode: 200,
		data: {
			2: 'Pizza',
			5: 'Burger',
		},
	};
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(mockResponse);
		}, 2000);
	});
}

function fetchQMSAnnotations(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.QC_ANNOTATIONS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetchAnnotation(url, requestOptions);
}

function fetchTempAnnotations(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.TEMP_ANNOTATIONS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetchAnnotation(url, requestOptions);
}

// function mockFetchAnnotation() {
// 	const mockResponse = {
// 		message: 'Loaded successfully',
// 		status: 'Success',
// 		statusCode: 200,
// 		data: [
// 			{
// 				id: 1,
// 				x: 284,
// 				y: 182,
// 				width: 115,
// 				height: 178,
// 				labelId: 0,
// 				label: '0',
// 			},
// 		],
// 	};
// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 2000);
// 	});
// }

function submitAnnotations(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.QC_ANNOTATIONS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function submitTempAnnotations(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.SUBMIT_TEMP_ANNOTATIONS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function bulkValidation(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
			// clientId: 52,
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(endpoints.BULK_VALIDATION, requestOptions).then(
		handleResponse
	);
}

function fetchIMSAnnotations(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.IMS_ANNOTATIONS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetchAnnotation(url, requestOptions);
}

// function mockFetchAnnotation() {
// const mockResponse = {
// data: {
// features: [
// {
// ai: false,
// brandId: 89,
// customerId: 5,
// height: 233,
// id: 91,
// image: '5/QualityCheck/Annotated/0d455183-f103-4c14-b1b5-7c5314cd6550_raw_1.jpg',
// label: 'Garnish missing',
// labelId: 13,
// productId: 488,
// qcId: 2906,
// width: 226,
// x: 328,
// y: 214,
// },
// ],
// identification: [
// {
// height: 231.2041884816754,
// id: null,
// label: 'Paneer Onion Pizza',
// labelId: 488,
// width: 218.63714490757596,
// x: 333.5798239827807,
// y: 215.4973821989529,
// },
// ],
// image: 'https://resoee.s3.amazonaws.com/5/QualityCheck/0d455183-f103-4c14-b1b5-7c5314cd6550_raw.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220714%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220714T082637Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=b9d1a0aef4dc1d7c6ce9a59f7d2d8a0b79063961cc04ee6738f780ad67c2801b',
// },
// message: 'Fetched successfully',
// status: 'Success',
// statusCode: 200,
// };
//
// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1500);
// });
// }

export default annotationServices;
