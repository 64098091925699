import { cloneDeep, update } from 'lodash';
import { imageToBase64 } from '../../Helpers';
import { notificationServices } from '../../Services';

const vendorConfig = {
	initialState: {
		fields: {
			name: '',
			customerId: '',
			logo: null,
		},
	},
	mandatoryFields: {
		name: true,
		customerId: true,
		logo: false,
	},
	serializer: (state, data) => {
		return update(cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.vendorName,
				customerID: data.customerID,
				logo: data.productImageUrl,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const req = {};
		req.data = {
			productName: clonedState.fields.name,
			customerId: clonedState.fields.customerId,
			productImageUrl: clonedState.fields.logo,
		};
		return imageToBase64(req.data.productImageUrl).then((base64Image) => {
			req.data.productImageUrl = base64Image;
			return req;
		});
	},
	validator: (state) => {
		// eslint-disable-next-line object-curly-newline
		const { logo } =
			state.fields;

		// check if image is uploaded or not
		if (!logo) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please upload the logo of the vendor',
			});
			return false;
		}

		return true;
	},
};

export default vendorConfig;
