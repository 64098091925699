/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const orderAnalyticsServices = {
	gateway,
	fetchOrderAnalysisList,
	cancelOrder,
	fetchOrderItems,
	downloadCsv,
	updateEwayBill,
	exportGRNWiseOrderHistory,
	cancelEwayBill,
	generateEInvoice,
	cancelEInvoice,
};

function gateway({ req, method }) {
	switch (method) {
		// case 'CHART_DATA':
		// 	return fetchCookingAnalysisGraph({
		// 		req,
		// 	});
		case 'LIST_DATA':
			return fetchOrderAnalysisList({
				req,
			});
		default:
			break;
	}
	return new Promise(() => {});
}

function fetchOrderAnalysisList({ req }) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.ORDER_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function downloadCsv({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			Accept: '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(endpoints.ORDER_HISTORY_CSV, requestOptions);
}

function cancelOrder(req) {
	const bodyData = {
		orderId: req.orderData.orderId,
		user: req.orderData.mobile,
	};

	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...bodyData,
		}),
	};

	// return mockOHList(requestOptions);
	return fetch(endpoints.CANCEL_ORDER, requestOptions).then(handleResponse);
}

function updateEwayBill(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.EWAY_BILL_UPDATE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchOrderItems(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.ORDER_ITEMS, requestOptions).then(handleResponse);
}

function exportGRNWiseOrderHistory(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.EXPORT_GRN_WISE_ORDER_HISTORY, requestOptions);
}

function cancelEwayBill(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CANCEL_EWAY_BILL, requestOptions).then(handleResponse);
}
function generateEInvoice(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.GENERATE_EINVOICE, requestOptions).then(handleResponse);
}

function cancelEInvoice(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Token: authHeader()?.token,
			'content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CANCEL_E_INVOICE, requestOptions).then(handleResponse);
}
// function mockOHList() {
// 	const mockResponse = {
// 		data: [
// 			{
// 				orderId: 67247,
// 				orderTime: '2022-08-23 10:34:44',
// 				orderStatus: 'PENDING',
// 				mealType: 'LATER',
// 				scheduledAt: '2022-08-24',
// 				paymentType: 'DIGITAL',
// 				paymentAmount: 155.0,
// 				paymentStatus: 'SUCCESS',
// 				storeName: 'SKCH Mallasandra',
// 				mobile: '9811887303',
// 				addressLine1: 'Gatik Aggarwal',
// 				addressLine2: '1',
// 				city: 'C',
// 				invoiceUrl: 'http://www.kfhkdc.com',
// 			},
// 			{
// 				orderId: 67248,
// 				orderTime: '2022-08-23 10:34:44',
// 				orderStatus: 'PENDING',
// 				mealType: 'LATER',
// 				scheduledAt: '2022-08-24',
// 				paymentType: 'DIGITAL',
// 				paymentAmount: 155.0,
// 				paymentStatus: 'SUCCESS',
// 				storeName: 'SKCH Mallasandra',
// 				mobile: '9811887303',
// 				addressLine1: 'Gatik 1 Aggarwal',
// 				addressLine2: '1',
// 				city: 'C',
// 				invoiceUrl: 'http://www.kfhkdc.com',
// 			},
// 			{
// 				orderId: 67249,
// 				orderTime: '2022-08-23 10:34:44',
// 				orderStatus: 'PENDING',
// 				mealType: 'LATER',
// 				scheduledAt: '2022-08-24',
// 				paymentType: 'DIGITAL',
// 				paymentAmount: 155.0,
// 				paymentStatus: 'SUCCESS',
// 				storeName: 'SKCH Mallasandra',
// 				mobile: '9811887303',
// 				addressLine1: 'Gatik 2 Aggarwal',
// 				addressLine2: '1',
// 				city: 'C',
// 				invoiceUrl: 'http://www.kfhkdc.com',
// 			},
// 		],
// 		responseCode: 'SS-001',
// 		responseMessage: 'Data Fetched Successfully',
// 	};

// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 1000);
// 	});
// }

export default orderAnalyticsServices;
