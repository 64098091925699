import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { AddProductCard, ProductCard } from '..';
import { generateRandomKey, generateRequiredProps } from '../../../Helpers';
import { DraggableHCards } from '../../Draggable/DraggableHCards';
import ProductListModal from '../../Modals/ProductModal/ProductListModal';
import TimeModal from '../../Modals/TimeSlotModal/TimeModal';
import SliderDiscount from '../../SliderDiscount/SliderDiscount';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		fields,
		errors,
		mandatoryFields,
		handleInput,
		isDisabled,
		selectedProducts,
		products,
		handleDelete,
		handleSliderChange,
		openProducts,
		activeSubCategory,
		handleSwitchChange,
		handleReorderItems,
		addNewProduct,
		openTimeSlots,
		addNewTimeSlot,
		handleExpiredDateTime,
	} = props;
	const generateDOMCardsList = () => {
		return selectedProducts.map((productObj, idx) => {
			let product = products.filter((currProduct) => {
				return (
					parseInt(currProduct.id, 10) ===
					parseInt(productObj.productId, 10)
				);
			});
			product = product.length
				? {
					...product[0],
				}
				: {};
			return (
				<ProductCard
					title={product.productName}
					img={product.productImageUrl}
					key={`${productObj.productId}`}
					idx={idx}
					handleDelete={handleDelete}
					dataAttrs={{
						'data-name': 'product',
						'data-id': idx,
						'data-parent': activeSubCategory,
					}}
					isDisabled={isDisabled}
				>
					<SliderDiscount
						idx={idx}
						product={product}
						handleSliderChange={handleSliderChange}
						data-type='product'
						data-id={idx}
						data-parent={activeSubCategory}
						productObj={productObj}
						handleDelete={handleDelete}
						handleSwitchChange={handleSwitchChange}
						isDisabled={isDisabled}
						showAddOn={false}
						openTimeSlots={openTimeSlots}
					/>
				</ProductCard>
			);
		});
	};

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='spcialmenuName'
						data-mandatory={mandatoryFields.name}
					>
						Special Menu Name
					</label>
					<input
						className='form-value'
						type='text'
						id='spcialmenuName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='spcialmenuDescription'
						data-mandatory={mandatoryFields.description}
					>
						Special Menu Description
					</label>
					<input
						className='form-value'
						type='text'
						id='spcialmenuDescription'
						name='description'
						onInput={handleInput}
						value={fields.description}
						{...generateRequiredProps(mandatoryFields.description)}
					/>
					<span className='form-error'>{errors.description}</span>
				</div>
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='expires'
						data-mandatory={mandatoryFields.expires}
					>
						Expires On
					</label>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							label='Expires On'
							value={fields.expires}
							onChange={handleExpiredDateTime}
							renderInput={(params) => {
								return <TextField {...params} />;
							}}
							{...generateRandomKey}
						/>
					</LocalizationProvider>
					<span className='form-error'>{errors.description}</span>
				</div>
				<label
					className='sub-heading'
					htmlFor='spcialmenuProducts'
					data-mandatory={mandatoryFields.products}
				>
					Special Menu Products
				</label>
				<div className={`${styles['product-wrapper']}`}>
					<DraggableHCards
						moveCard={handleReorderItems}
						isDraggable={false}
						cardsList={generateDOMCardsList()}
					/>
					{!isDisabled && (
						<AddProductCard
							text='Product'
							handleClick={openProducts}
						/>
					)}
				</div>
				<ProductListModal
					handleClick={openProducts}
					handleConfirm={addNewProduct}
					selectedProdList={fields.products}
				/>
				<TimeModal handleConfirm={addNewTimeSlot} />
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

export default GeneralDetails;
