/* eslint-disable no-case-declarations */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
import { Scale } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AttachMoney from '@mui/icons-material/AttachMoney';
import { Pie } from 'react-chartjs-2';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styles from './DataTile.module.scss';
import Heading from '../../Heading/Heading';
import { tilestype } from '../../tempdata';
import { addCommas, classes } from '../../../../Helpers';
/*
function showTooltip(tooltip) {
	return (
		<>
			{tooltip?.length ? (
				<>
					<HtmlTooltip title={<>{tooltip}</>} placement='top' followCursor>
						<InfoOutlinedIcon />
						hell
					</HtmlTooltip>
				</>
			) : (
				<></>
			)}
		</>
	);
}
*/
/*
const HtmlTooltip = styled(({ className, ...props }) => {
	return (
		<Tooltip
			{...props}
			classes={{
				popper: className,
			}}
		/>
	);
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			background: '#eff5f5',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid black',
		},
	};
});
*/
function numDifferentiation(val, measurementType) {
	let k = '';
	let simpleRs = false;
	if (measurementType === 'US') {
		if (val >= 1000000) {
			k = `${(val / 1000000)?.toFixed(3)} M`;
		} else if (val >= 1000) {
			k = `${(val / 1000)?.toFixed(2)} K`;
		} else {
			simpleRs = true;
			k = `${val?.toFixed(2)}`;
		}
	} else if (val >= 10000000) {
		k = `${(val / 10000000)?.toFixed(3)} Cr`;
	} else if (val >= 100000) {
		k = `${(val / 100000)?.toFixed(2)} Lac`;
	} else {
		simpleRs = true;
		k = `${val?.toFixed(2)}`;
	}
	/* else if(val >= 1000) val = (val/1000).toFixed(2) + ' K'; */
	return [k, simpleRs];
}
function weightDifferentiation(val, measurementType) {
	let k = '';
	let simpleRs = false;
	if (val >= 1000 && measurementType !== 'US') {
		k = `${(val / 1000)?.toFixed(2)} T `;
	} else {
		simpleRs = true;
		k = `${(val / 1)?.toFixed(2)}`;
		k = addCommas(k);
	}
	/* else if(val >= 1000) val = (val/1000).toFixed(2) + ' K'; */
	return [k, simpleRs];
}

function CorrespondingDiv(
	type,
	value,
	scale,
	unit = '',
	tooltip,
	measurementType
) {
	switch (type) {
		case tilestype.percent:
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
							wordBreak: 'keep-all',
						}}
					>
						<Heading
							heading={value + (tooltip?.length > 0 ? unit : '')}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '1.1rem' : '1.7rem',
									}}
								>
									{unit}
								</span>
							</>
						)}
					</p>
					<Pie
						className={
							scale ? styles.smallPieChart : styles.pieChart
						}
						data={{
							labels: [`${value}`, ' '],
							datasets: [
								{
									data: [value, 100 - value],
									// backgroundColor: [loss ? 'red' : 'green', '#eff5f5'],
									backgroundColor: [
										'rgba(73, 113, 116, 1)',
										'#eff5f5',
									],
									// borderColor: loss ? 'red' : 'green',
									borderColor: 'rgba(73, 113, 116, 1)',
									borderWidth: '5',
								},
							],
						}}
						options={{
							plugins: {
								tooltip: {
									enabled: false,
								},
								legend: {
									display: false,
								},
							},
						}}
					/>
				</>
			);

		case tilestype.weight:
			let itemWeight = value;
			let itemUnit = unit;
			const [filteredWeight, isSimpleRs] = weightDifferentiation(value, measurementType);
			if (measurementType !== 'US') {
				itemWeight = filteredWeight;
				itemUnit = isSimpleRs ? unit : '';
			}
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
						}}
					>
						<Heading
							heading={
								itemWeight +
								(tooltip?.length > 0 ? ` ${itemUnit}` : '')
							}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '1.1rem' : '1.7rem',
									}}
								>
									{` ${itemUnit}`}
								</span>
							</>
						)}
					</p>
					<Scale
						style={{
							color: 'rgba(73, 113, 116, .8)',
							fontSize: scale ? '1.7rem' : '',
						}}
						className={styles.icon}
					/>
				</>
			);
		case tilestype.amount:
			// eslint-disable-next-line no-case-declarations
			const [formattedVal, inThousands] = numDifferentiation(value, measurementType);
			// eslint-disable-next-line no-case-declarations
			let heading = formattedVal;
			if (unit && unit === '$') {
				heading = `${unit}${formattedVal}`;
			} else if (tooltip?.length > 0 && inThousands) {
				heading = `Rs ${formattedVal}`;
			} else {
				heading = `${formattedVal}`;
			}
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '',
						}}
					>
						{tooltip?.length > 0 ? (
							<></>
						) : (
							<>
								<span
									style={{
										fontSize: scale ? '0.9rem' : '1.5rem',
									}}
								>
									{`${unit} `}
								</span>
							</>
						)}
						<Heading
							heading={heading}
							size={scale ? '1.1rem' : '2rem'}
							tooltip={tooltip}
						/>
					</p>
					{unit === '$' ? (
						<AttachMoney
							style={{
								color: 'rgba(79, 160, 149, 1)',
								fontSize: scale ? '1.7rem' : '',
							}}
							className={styles.icon}
						/>
					) : (
						<CurrencyRupeeIcon
							style={{
								color: 'rgba(79, 160, 149, 1)',
								fontSize: scale ? '1.7rem' : '',
							}}
							className={styles.icon}
						/>
					)}
				</>
			);
		case tilestype.genric:
			return (
				<>
					<div className={styles.generic}>
						{value?.map((ele) => {
							return (
								<div
									key={ele?.type + ele?.tooltip}
									className={styles.item}
								>
									{CorrespondingDiv(
										ele.type,
										ele.value,
										true,
										ele.unit,
										ele?.tooltip,
										measurementType,
									)}
								</div>
							);
						})}
						{/* <div className={styles.item}>{CorrespondingDiv('price', 20, true)}</div>
						<div className={styles.item}>{CorrespondingDiv('percent', 45, true)}</div> */}
					</div>
					{/* {''.split().join} */}
					{/* {value.split('/').join('  ')} */}
				</>
			);

		default:
			return (
				<>
					<p
						style={{
							fontSize: scale ? '1.3rem' : '1rem',
						}}
					>
						<Heading
							heading={addCommas(value) + unit}
							size={scale ? '1.1rem' : '1rem'}
							tooltip={tooltip}
						/>
					</p>
					<span
						style={{
							color: 'rgba(73, 113, 116, .8)',
							fontSize: scale ? '.8rem' : '1.4rem',
							fontWeight: scale ? '600' : '400',
						}}
					>
						{type}
					</span>
				</>
			);
	}
}

function DataTile({
	data,
	className,
	showArrow,
	showOnLeft,
	showOnRight,
	measurementType,
}) {
	const {
		heading, value, type, unit, tooltip, stats: extraData
	} = data;
	return (
		<div key={heading} className={classes(className, styles.root)}>
			{showArrow && (
				<>
					{/* <div className={styles?.arrow}>
						<ArrowRightIcon fontSize='large' />
					</div> */}
					{showOnRight && (
						<div className={styles?.arrowRight}>
							<ArrowRightAltIcon fontSize='large' />
						</div>
					)}
					{!showOnRight && (
						<div className={styles?.arrowTop}>
							<ArrowRightAltIcon fontSize='large' />
						</div>
					)}
					{/* <div className={styles?.animatedArrow}>
						<KeyboardArrowRightIcon fontSize='large' />
					</div> */}
				</>
			)}
			{showOnLeft && (
				<div className={styles?.arrowLeft}>
					<ArrowRightAltIcon fontSize='large' />
				</div>
			)}
			{/* <div className={styles.data}>Today’s Plan Production</div> */}
			<Heading heading={heading} tooltip={tooltip} />
			<div className={styles.content}>
				{/* <p>2300</p> */}
				{CorrespondingDiv(
					type,
					type === tilestype.genric ? extraData : value,
					false,
					unit,
					null,
					measurementType
				)}
			</div>
		</div>
	);
}

export default DataTile;
