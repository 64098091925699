const AddCreditsConfig = {
	initialState: {
		balance: null,
		number: null,
		comment: '',
		amount: null,
		transactionHistory: [],
	},
};

export default AddCreditsConfig;
