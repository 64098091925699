const drawerConstants = {
	TOGGLE_MODAL: 'TOGGLE_MODAL',

	SHOW_BILLS_DRAWER: 'SHOW_BILLS_DRAWER',
	HIDE_BILLS_DRAWER: 'HIDE_BILLS_DRAWER',

	SHOW_TRANSACTION_DRAWER: 'SHOW_TRANSACTION_DRAWER',
	HIDE_TRANSACTION_DRAWER: 'HIDE_TRANSACTION_DRAWER',

	SHOW_FILTER_DRAWER: 'SHOW_FILTER_DRAWER',
	HIDE_FILTER_DRAWER: 'HIDE_FILTER_DRAWER',

	SHOW_STOCK_HISTORY_DRAWER: 'SHOW_STOCK_HISTORY_DRAWER',
	HIDE_STOCK_HISTORY_DRAWER: 'HIDE_STOCK_HISTORY_DRAWER',

	SHOW_EXPORT_FILTER_DRAWER: 'SHOW_EXPORT_FILTER_DRAWER',
	HIDE_EXPORT_FILTER_DRAWER: 'HIDE_EXPORT_FILTER_DRAWER',

	SHOW_DEBIT_CREDIT_LIST_DRAWER: 'SHOW_DEBIT_CREDIT_LIST_DRAWER',
	HIDE_DEBIT_CREDIT_LIST_DRAWER: 'HIDE_DEBIT_CREDIT_LIST_DRAWER',

	SHOW_SETTINGS_DRAWER: 'SHOW_SETTINGS_DRAWER',
	HIDE_SETTINGS_DRAWER: 'HIDE_SETTINGS_DRAWER',
};

export default drawerConstants;
