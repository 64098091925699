import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions, productActions } from '../../../Actions';
import { DraggableHTabs } from '../../../Components/Draggable';
import { ComboVariant } from '../../../Components/Forms/Combos';
import GeneralDetails from '../../../Components/Forms/Combos/GeneralDetails/GeneralDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import combosConfig from './combos.config';

const TABS = ['GENERAL DETAILS', 'COMBO DETAILS'];

const ComboViewPage = (props) => {
	const { fetchCombo, fetchProducts, data, breadcrumbs } = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: cloneDeep(combosConfig.initialState.fields),
	});

	const [activeVariant, setActiveVariant] = useState(
		state.fields.variants.order.length
	);

	const { id } = useRouteMatch().params;

	useEffect(() => {
		fetchCombo({
			entity: 'COMBOS',
			req: {
				params: {
					combo_id: id,
				},
			},
			method: 'VIEW',
		});
		fetchProducts();
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(combosConfig.serializer(state, data));
		}
	}, [data]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return <GeneralDetails isDisabled fields={fields} />;
			case 1:
				return (
					<>
						<DraggableHTabs
							items={fields.variants.items}
							order={fields.variants.order}
							handleActiveTab={setActiveVariant}
							activeTab={activeVariant}
							limit={5}
							isDraggable={false}
							isDisabled
						/>
						<ComboVariant
							variantDetails={
								fields.variants.items[activeVariant]
							}
							mandatoryFields={combosConfig.mandatoryFields}
							activeVariant={activeVariant}
							isDisabled
						/>
					</>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Combo'
				handleEdit={redirectToEdit}
				showDelete={false}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${state.fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.COMBOS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchCombo: formActions.gateway,
	fetchProducts: productActions.fetchProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboViewPage);
