import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { connect } from 'react-redux';
import { paymentSettlementActions } from '../../../../Actions';
import ListTable from '../../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Transaction Amount',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMS = [
	{
		title: 'Download Invoice',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	// 'Settlement Id': 'settlement_info_id',
	'Order Id': 'incoming_order_id',
	'Created At': 'created_date',
	'Transaction Amount': 'txn_amount',
	// 'Transaction Type': 'txn_type',
};
const TransactionListTable = (props) => {
	const { fetchOrderInvoiceUrl } = props;
	const getInvoice = (orderId) => {
		fetchOrderInvoiceUrl({
			entity: 'INVOICE_URL',
			req: {
				orderId,
			},
		});
	};
	const { data, query } = props;
	const SPECIAL_ITEMS = {
		'Download Invoice': (orderId, id) => {
			return (
				<IconButton
					href={id}
					onClick={() => {
						getInvoice(orderId);
					}}
					size='large'
				>
					<DownloadIcon />
				</IconButton>
			);
		},
	};
	return (
		<div className='root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.filter((transaction) => {
						return (
							transaction.settlement_info_id
								.toString()
								.indexOf(query) !== -1
						);
					})
					.map((transaction) => {
						return {
							...transaction,
							id: transaction.incoming_order_id,
						};
					})}
				special={SPECIAL_ITEMS}
				actions={[...SPECIAL_COLUMS]}
			/>
		</div>
	);
};
TransactionListTable.defaultProps = {};
const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	fetchOrderInvoiceUrl: paymentSettlementActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TransactionListTable);
