import styles from './CustomInput.module.scss';

function CustomInput(props) {
	const {
		name,
		type,
		value,
		onChangeHandler,
		text,
		className,
		inputClassName,
		min,
		step,
		max,
		requiredProps,
	} = props;
	return (
		<div className={className || styles.root}>
			<input
				className={inputClassName || styles.input}
				type={type}
				name={name}
				min={min ?? 0}
				step={step ?? null}
				max={max ?? 100000000}
				// onInput={(e) => {
				// handleBatchSize(e, ele?.id);
				// }}
				onInput={(e) => {
					onChangeHandler(e);
				}}
				// value={}
				value={value}
				// data-type='recipe'
				// {...generateRequiredProps(mandatoryFields.batchSize)}
				{...requiredProps}
			/>
			{/* fields?.product?.unit?.toUpperCase() */}
			<p>{text}</p>
		</div>
	);
}

export default CustomInput;
