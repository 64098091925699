import Lottie from 'lottie-react';
import notAvailable from '../../Images/notAccessablePage.json';
import styles from './NotAccessable.module.scss';

function NotAccessiblePage() {
	return (
		<div className={styles.root}>
			<Lottie
				style={{
					height: 'calc(100vh - 100px)',
				}}
				animationData={notAvailable}
				loop
			/>
			<div className={styles.warningsWrapper}>
				<div className={styles.warningText}>
					Either you are trying to access
					{' '}
					<span className={styles.warningHighlighter}>Non existed URL</span>
				</div>
				<div className={styles.warningText}>
					Or
				</div>
				<div className={styles.warningText}>
					User is
					{' '}
					<span className={styles.warningHighlighter}>Not Permitted</span>
					{' '}
					to this page
				</div>
				<div className={`${styles.successHighlighter} ${styles.successText}`}>
					Please Select Tab From Left Side Navigation bar
				</div>
			</div>
		</div>
	);
}

export default NotAccessiblePage;
