import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../';
import { addCommas, classes } from '../../Helpers';
import styles from './COGSAnalysisListPage.module.scss';
import analyticsAction from '../../Actions/analytics.actions';
import { useAuth } from '../../Components/Auth/Login/auth';
import { ListTable } from '../../Components/ListTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	'Product Name': 'productName',
	'SKU Type': 'skuType',
	Store: 'storeName',
	'Amount Used': 'cogs',
	Ideal: 'ideal',
	'Produced Quantity (Kg)': 'qty',
	// 'Cost Category': 'costCategory',
	'Inventory Consumed': 'invConsumed',
	'Production Loss': 'productionLoss',
	Wastage: 'wastage',
};

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount Used',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ideal',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Produced Quantity (Kg)',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory Consumed',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production Loss',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Wastage',
		config: {
			type: 'DATA',
		},
	},
];

const getRowStyles = (item) => {
	switch (item?.result) {
		default:
			return styles.normal;
	}
};

// function setAttr(text) {
// return <span className={styles.text}>{text}</span>;
// }

const COGSAnalysisListPage = (props) => {
	// const history = useHistory();
	const { data, setAnalyticsType, ANALYTICS_TYPE, showInternalData } = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, [showInternalData]);
	const SPECIAL_ITEMS = {
		'Product Name': (_, obj) => {
			// eslint-disable-next-line prefer-template
			return (obj?.productName + (obj?.variantName ? ' (' + obj?.variantName + ')' : ''));
		},
		'Amount Used': (value) => {
			return addCommas(value, measurement, true);
		},
		'Inventory Consumed': (value) => {
			return addCommas(value, measurement, true);
		},
		'Production Loss': (value) => {
			return addCommas(value, measurement, true);
		},
		Wastage: (value) => {
			return addCommas(value, measurement, true);
		},
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ListTable
				fixedHeaderTop={0}
				hasDateRange
				// renderColumn={renderColumn}
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				data={data?.map((obj) => {
					const rowStyles = getRowStyles(obj);
					return {
						...obj,
						rowStyles,
					};
				})}
				columns={[...TABLE_COLUMNS]}
				actions={[]}
			/>
			{/* <ArrangePages
				listCount={61}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				setAnalyticsType={setAnalyticsType}
			/> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { showInternalData } = state?.navigation;
	return {
		showInternalData,
	};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(COGSAnalysisListPage);
