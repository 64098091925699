// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';

const initialState = {
	creditId: '',
	fromStore: {},
	store: {},
	vendor: null,
	orderId: null,
	salesReturnId: null,
	billId: null,
	creditingDate: new Date(),
	dueDate: new Date(),
	items: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = [
	{
		field: 'vendor',
		displayName: 'Vendor',
	},
	{
		field: 'creditId',
		displayName: 'Client credit Id',
	},
	{
		field: 'fromStore',
		displayName: 'Credit note from a store',
	},
	{
		field: 'itemReturnDate',
		displayName: 'Item return date',
	},
];

const config = {
	initialState,
	reducer,
	serializer: (data) => {
		return data;
		// const newState = {
		// machineName: data?.machine_name,
		// macAddress: data?.mac,
		// store: data?.storeId,
		// process: data?.processId,
		// manufacturedOn: data?.manufactured_on
		// ? new Date(data?.manufactured_on)
		// : null,
		// installedOn: data?.installed_on
		// ? new Date(data?.installed_on)
		// : null,
		// expiresOn: data?.warranty_expires_on
		// ? new Date(data?.warranty_expires_on)
		// : null,
		// serialNo: data?.serial_number,
		// modelNo: data?.model_number,
		// };
		// return newState;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const { ...remaining } = clonedState;
		const newState = {
			transactionDate: moment(remaining?.creditingDate)?.format('M/D/YYYY'),
			dueDate: moment(remaining?.dueDate)?.format('M/D/YYYY'),
			storeId: remaining?.salesReturnId ? remaining?.fromStore?.id : remaining?.store?.id,
			fromStore: remaining?.salesReturnId ? remaining?.store?.id : remaining?.fromStore?.id,
			parentId: remaining?.billId ?? remaining?.salesReturnId,
			clientTransactionId: `CN-${remaining?.creditId}`,
			orderId: remaining?.orderId,
			vendorId: remaining?.vendor?.id,
			vendorName: remaining?.vendor?.name,
			items: remaining?.items?.map((ele) => {
				return {
					productId: ele?.productId,
					variantId: ele?.selectedVariant?.id,
					qty: Number(ele?.quantity),
					price: Number(ele?.selectedVariant?.price),
					tax: ele?.tax,
					subTotal: ele?.subTotal,
					totalTax: ele?.totalTax,
					finalAmount: ele?.totalPriceIncTax,
				};
			}),
		};
		return newState;
	},
	validator: (state) => {
		return mandatoryFields?.find((key) => {
			return (
				state[key?.field] === null ||
				state[key?.field] === '' ||
				!state[key?.field] === true
			);
		});
	},
};

export default config;
