import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import analyticsAction from '../../../../Actions/analytics.actions';
import { DefectImageResult } from '../../../../Components/Analytics/Defect';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import { DefectImageModal } from '../../../../Components/Modals';
import defectAnalyticsServices from '../../../../Services/Analytics Services/defect-analytics.services';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './DefectImagesPage.module.scss';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const FIND_FILTERS = [
	'store',
	'processTypes',
	'brand',
	'features',
	'vendor',
	'product',
	'productCategory',
	'identificationType',
	'timeType',
	'pageLimit',
	'featureType',
	'dateRange',
];

const DefectImagesPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		brand: null,
		processType: null,
		featureType: 'QUALITY',
		defects: [],
		products: [],
		vendors: [],
		productCategories: [],
		identification: 'Both',
		submissions: 0,
		timeType: '',
		pageLimit: 6,
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
	});

	const { allDefectResults, fetchDefectImages, updateDefectImagesList, resetDefectImages } =
		props;

	useEffect(() => {
		resetDefectImages();
	}, []);

	const submitFindRequest = ({
		store = null,
		processTypes = null,
		brand,
		featureType,
		defects = [],
		products = [],
		productCategories = [],
		identification,
		timeType,
		page: pageLimit,
		vendors = [],
		dateRange = {},
	}) => {
		setFilters({
			store,
			processTypes,
			brand,
			featureType,
			defects,
			products,
			vendors,
			productCategories,
			identification,
			submissions: filters.submissions + 1,
			timeType,
			pageLimit,
			dateRange,
		});
		fetchDefectImages({
			entity: 'DEFECT_ANALYSIS',
			method: 'FIND_DEFECT_IMAGES',
			req: {
				params: {
					limit: pageLimit,
				},
				data: {
					storeId: store,
					brandId: brand,
					labels: defects,
					products,
					processTypes,
					vendors,
					productCategories,
					identification,
					type: featureType,
					dateRangeFilter: dateRange?.active
						? {
							timeType,
							from: moment(dateRange?.from).format(
								'YYYY-MM-DD'
							),
							to: moment(dateRange?.to).format('YYYY-MM-DD'),
						}
						: {},
				},
			},
		});
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	const fetchDefectImagesById = async ({ page, defectId, identification }, index) => {
		const response = await defectAnalyticsServices.findDefectImagesById({
			params: {
				page,
				limit: filters?.pageLimit,
			},
			data: {
				labelId: defectId,
				storeId: filters.store,
				products: filters.products,
				processTypes: filters.processTypes,
				brandId: filters.brand,
				vendors: filters.vendors,
				productCategories: filters.productCategories,
				identification,
				type: filters?.featureType,
				dateRangeFilter: filters?.dateRange?.active
					? {
						timeType: filters?.timeType,
						from: moment(filters?.dateRange.from).format('YYYY-MM-DD'),
						to: moment(filters?.dateRange.to).format('YYYY-MM-DD'),
					}
					: {},
			},
		});

		const { data = [] } = response;
		updateDefectImagesList(index, data);
		return data;
	};

	return (
		<div className={styles.root}>
			<DefectImageModal />
			<ImagesFinder pageLabel='Feature Images' FILTERS={FIND_FILTERS} onSubmit={submitFindRequest} />
			<div className={styles['image-results']}>
				{allDefectResults.map((defect, index) => {
					return (
						<DefectImageResult
							key={`${defect.id}${filters.submissions}`}
							identification={filters?.identification}
							fetchQCData={fetchQCData}
							fetchDefectImagesById={(req) => {
								return fetchDefectImagesById(req, index);
							}}
							defect={defect}
						/>
					);
				})}
				{allDefectResults.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_DEFECT_IMAGES: allDefectResults } = state.analytics.DEFECT_ANALYSIS;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	return {
		allDefectResults,
		dateRangeFilter,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDefectImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateDefectImagesList: (...args) => {
			dispatch(analyticsAction.updateDefectImagesList(...args));
		},
		resetDefectImages: () => {
			dispatch(analyticsAction.resetDefectImages());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectImagesPage);
