import { useMemo } from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../Components/SubHeader/SubHeader';
import analyticsTypeConstant from '../../Constants/analyticsType.contants';
import { getDateFilterString } from '../../Helpers';
import INVAnalysisListPage from './INVAnalysisListPage';
import styles from './PastINVAnalysisHistoryPage.module.scss';

const ANALYTICS_TYPE = analyticsTypeConstant.INV_ANALYTICS_LIST_DATA;
const PastINVAnalysisHistoryPage = (props) => {
	const { breadcrumbs, DATE_FILTER, data } = props;
	const selectedDateString = useMemo(() => {
		return getDateFilterString(DATE_FILTER);
	}, [DATE_FILTER]);
	return (
		<div className={styles.root}>
			<SubHeader
				breadcrumbs={breadcrumbs}
				showBreadcrumbs
				selectedDateString={selectedDateString}
			/>
			<INVAnalysisListPage
				data={data}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { DATE_FILTER } = state.analytics;
	const { LIST_DATA: data } = state.analytics.INV_ANALYTICS;
	return {
		DATE_FILTER: DATE_FILTER.selection,
		data,
	};
};
const mapDispatchToProps = {};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PastINVAnalysisHistoryPage);
