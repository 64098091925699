import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { modalTypes } from '../../../../Constants';
import AddProductCard from '../../AddProductCard';
import ProductCard from '../../ProductCard';
import { CustomizationSKU } from '../CustomizationSKU';
import styles from './CustomizationSKUs.module.scss';

const CustomizationSKUs = (props) => {
	const {
		id,
		skus,
		toggleProductVariants,
		isDisabled,
		selectedSkus,
		handleDelete,
		applicableOn,
		customizationType,
		deltaPricing,
		mandatoryFields,
		handleInput,
		showVariants,
		allVariants,
		defaultVariant,
		checkApplicability,
		extraProps,
		fallbackText,
	} = props;

	const openSKUs = () => {
		toggleProductVariants(true, {
			groupId: id,
			itemTypes: ['PRODUCT', 'INTERMEDIATE', 'INGREDIENT', 'PACKAGING', 'OTHER'],
		});
	};

	return (
		<div className={styles.root}>
			{showVariants && selectedSkus.length > 0 && (
				<div
					className={`${styles['applicable-variants']} ${
						selectedSkus.length === 0 ? styles.hidden : ''
					} ${deltaPricing ? '' : styles['not-delta-pricing']}`}
				>
					{(extraProps.skuMeta?.variants ||
						customizationType !== 'REMOVABLE') &&
						applicableOn
							?.filter((variant) => {
								if (checkApplicability) {
									return variant.isApplied;
								}
								return true;
							})
							.map((variant, idx) => {
								return (
									<div
										className={
											styles['variant-tile-wrapper']
										}
									>
										<span
											className={styles['variant-tile']}
										>
											{variant.name}
										</span>
										<span className={styles['svng-bs']}>
											<p>
												{
													allVariants?.[idx]
														?.servingQtyPercentage
												}
												%
											</p>
											<p>
												{allVariants?.[idx]?.batchSize}
											</p>
										</span>
									</div>
								);
							})}
					<div className={styles.filler} />
				</div>
			)}
			<div className={styles['skus-wrapper']}>
				{selectedSkus.reduce((accSelectedSku, currSelectedSku, idx) => {
					const sku = skus?.[currSelectedSku?.skuId] ?? null;
					if (sku != null) {
						const skuVariant =
							sku.ProductVariant[currSelectedSku.variantId];
						accSelectedSku.push(
							<ProductCard
								key={`${sku.id}-card`}
								title={`${sku.productName} ${skuVariant}`}
								img={sku.productImageUrl}
								idx={idx}
								handleDelete={handleDelete}
								dataAttrs={{
									'data-type': 'sku',
									'data-id': idx,
									'data-parent': id,
								}}
								isDisabled={isDisabled}
							>
								<CustomizationSKU
									id={id}
									key={`${sku.id}-sku`}
									idx={idx}
									sku={currSelectedSku}
									variant={skuVariant}
									applicableOn={applicableOn}
									allVariants={allVariants}
									customizationType={customizationType}
									deltaPricing={deltaPricing}
									mandatoryFields={mandatoryFields}
									handleInput={handleInput}
									showVariants={showVariants}
									checkApplicability={checkApplicability}
									defaultVariant={defaultVariant}
									extraProps={extraProps}
									isDisabled={isDisabled}
								/>
							</ProductCard>
						);
					}
					if (
						idx === selectedSkus.length - 1 &&
						accSelectedSku.length === 0
					) {
						accSelectedSku.push(
							<p className={styles.fallback}>{fallbackText}</p>
						);
					}
					return accSelectedSku;
				}, [])}
				{!isDisabled && (
					<AddProductCard text='SKU' handleClick={openSKUs} />
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const skus = state.product.products;
	return {
		skus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleProductVariants: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(
					modalTypes.PRODUCT_VARIANTS,
					show,
					data
				)
			);
		},
	};
};

CustomizationSKUs.defaultProps = {
	showVariants: true,
	checkApplicability: true,
	extraProps: {},
	fallbackText: 'No ingredients added',
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizationSKUs);
