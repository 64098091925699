/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable prefer-template */
import { connect } from 'react-redux';
import {
	useState,
	useEffect,
	useReducer,
	useMemo,
} from 'react';
import MultiCascader from 'rsuite/MultiCascader';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
	MenuItem, TextField, Button
} from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import config from './VendorContract.config';
import { formActions, modalActions } from '../../../Actions';
import { AlertModal } from '../../../Components/Modals';
import CreatePageHeader from '../../../Components/Headers/CreatePageHeader/CreatePageHeader';
import styles from './styles.module.scss';
import {
	classes,
	getPriceConvertedQuantity,
	useQuery
} from '../../../Helpers';
import { paymentTerms } from '../../../Components/POGeneralDetails/POGeneralDetails';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import DynamicHeaderTable from '../../../Components/DynamicHeaderTable/DynamicHeaderTable';
import allVendorServices from '../../../Services/Form Services/allvendor.services';
import { RegionMenuModal } from '../../../Components/Modals/RegionMenuModal';
import regionStateServices from '../../../Services/regionState.services';
import { notificationServices } from '../../../Services';
import { BackdropLoader } from '../../../Components/Loaders';
import DescriptionBox from '../../../Components/DescriptionBox/DescriptionBox';
// eslint-disable-next-line import/order
import { cloneDeep } from 'lodash';
import { useAuth } from '../../../Components/Auth/Login/auth';
import CustomInputSelector from '../../../Components/CustomInputSelector/CustomInputSelector';
import POVendorDetails from '../../../Components/POGeneralDetails/VendorDetails/POVendorDetails';

// import { RegionMenuModal } from '../../../Components/Modals/RegionMenuModal';

const regions = [
	{
		label: 'All Region',
		color: '#94a3b8',
	},
	{
		label: 'Region',
		color: '#facc15',
	},
	{
		label: 'State',
		color: '#008080',
	},
	{
		label: 'City',
		color: '#FF7F50',
	},
	{
		label: 'Store',
		color: '#2dd4bf',
	},
];

const descriptionData = [
	{
		component: (
			<div className={`${styles.checkbox} ${styles.negative}`}>
				<svg
					id='i-close'
					viewBox='0 0 32 32'
					width='16'
					height='16'
					fill='none'
					stroke='currentcolor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='10.9375%'
				>
					<path d='M2 30 L30 2 M30 30 L2 2' />
				</svg>
			</div>
		),
		label: 'Vendor Dont Sell Products In This Region',
	},
	{
		component: (
			<div className={`${styles.checkbox} ${styles.positive}`}>
				<svg
					id='i-checkmark'
					viewBox='0 0 32 32'
					width='16'
					height='16'
					fill='none'
					stroke='currentcolor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='10.9375%'
				>
					<path d='M2 20 L12 28 30 4' />
				</svg>
			</div>
		),
		label: 'Diffrent Price In This Region',
	},
	{
		component: <div className={`${styles.checkbox}`} />,
		label: 'Price Is Same As Its Parent',
	},
];

const CreateVendorContractPage = (props) => {
	const {
		productsList,
		productCategoriesList,
		fetchProducts,
		fetchProductCatergoriesList, toggleModal,
		vendorsList,
		buyersList,
		toggleAddVendorModal,
	} = props;
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);

	const [data, setData] = useState([]);
	const [value, setValue] = useState([]);
	const [flatData, setFlatData] = useState([]);
	const [prices, setPrices] = useState([]);
	const [region, setRegion] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [variants, setVariants] = useState({});
	const [childrenMapping, setChildrenMapping] = useState({});
	const [parentKey, setParentKey] = useState({});
	const [selectedVendor, setSelectedVendor] = useState({});
	const [isLoading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const isEdit = params.get('isEdit');
	const contractId = params.get('contractId');
	const pageType = params.get('type');
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;

	function convertJSON(json, levelToStart = 0) {
		const convertJSONRec = (
			jsonArray, level = 0, myParent = null, mappingDict = {}, parentDict = {}
		) => {
			return jsonArray.map((item) => {
				const newItem = {
					...item,
					color: level < regions.length ? regions[level].color : 'gray',
				};
				const key = item?.type + '-' + item?.id;
				if (item.children) {
					// eslint-disable-next-line no-param-reassign
					mappingDict[key] = item.children;
					// eslint-disable-next-line no-param-reassign
					parentDict[key] = myParent;
					newItem.children = convertJSONRec(item.children, level + 1, key, mappingDict, parentDict);
				}
				return newItem;
			});
		};

		const childrenMappingTemp = {};
		const parentDictTemp = {};
		const convertedJson = convertJSONRec(
			json, levelToStart, null, childrenMappingTemp, parentDictTemp
		);
		setChildrenMapping(childrenMappingTemp);
		setParentKey(parentDictTemp);
		return convertedJson;
	}

	const fetchContractInfo = async () => {
		try {
			const response = await allVendorServices.VendorContractInfo({
				contractId,
			});
			if (response?.statusCode === 200) {
				return response?.data;
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			setLoading(false);
			history.goBack();
		}
		return null;
	};
	const variantWiseUnits = useMemo(() => {
		return productsList?.reduce((acc, currProd) => {
			currProd?.variants?.forEach((currVariant) => {
				acc[currVariant?.id] = {
					productId: currProd?.id,
					unit: currProd.purchaseUnit ?? currProd?.unit,
				};
			});
			return acc;
		}, {});
	}, [productsList]);

	const setEditData = async () => {
		setLoading(true);
		const editData = await fetchContractInfo();
		const valueProd = [];
		editData?.products?.forEach((product) => {
			valueProd.push(product?.variantId);
		});
		setValue(valueProd);
		setRegion(convertJSON(editData?.regions));
		Object.keys(editData?.pricing)?.forEach((varId) => {
			const currentEle = editData?.pricing[varId];
			const eleUnit = variantWiseUnits[varId]?.unit;
			const priceFactor = getPriceConvertedQuantity(1, eleUnit);
			Object?.values(currentEle)?.forEach((innerEle) => {
				// eslint-disable-next-line no-param-reassign
				innerEle.value *= priceFactor;
			});
		});

		setPrices(editData?.pricing);
		const contract = editData?.contract;
		const vendor = editData?.vendor;
		dispatch({
			type: 'SET_END_DATE',
			payload: new Date(moment(contract?.expiryDate).format('YYYY-MM-DD')),
		});
		dispatch({
			type: 'SET_VENDOR',
			payload: vendor.id,
		});
		dispatch({
			type: 'SET_PAYMENT_TERM',
			payload: contract?.paymentTerm,
		});
		dispatch({
			type: 'SET_PRODUCTS',
			payload: editData?.products,
		});
		dispatch({
			type: 'SET_VENDOR_AUTH',
			payload: contract?.vendorAuth,
		});
		dispatch({
			type: 'SET_COMPANY_AUTH',
			payload: contract?.companyAuth,
		});
		setLoading(false);
	};

	const setCreateData = async () => {
		try {
			const response = await regionStateServices.getAllRegionMappings();
			const allRegion = convertJSON(response);
			setRegion(allRegion);
		} catch (err) {
			notificationServices.generateNotification({
				type: 'error',
				message: err.message,
			});
		}
	};

	useEffect(() => {
		if (isEdit) {
			setEditData();
		} else {
			setCreateData();
		}
	}, [isEdit, contractId, variantWiseUnits]);

	useEffect(() => {
		fetchProductCatergoriesList({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (productsList && productCategoriesList) {
			const categoryToProductMapping = {};
			const productToVariantMapping = {};
			const tmpVariants = {};
			const catChildren = {};
			productsList?.forEach((product) => {
				if ((pageType === 'BUYER' && product.skuType === 'PRODUCT') ||
					(pageType === 'VENDOR' && product.skuType !== 'INTERMEDIATE' && product?.skuType !== 'PRODUCT')) {
					const prodChildren = {};
					// eslint-disable-next-line prefer-template
					const productId = 'p-' + product?.id;
					product?.variants?.forEach((variant) => {
						const item = {
							productId: product?.id,
							productName: product?.productName,
							variantId: variant?.id,
							variantName: variant?.displayName,
							unit: product?.purchaseUnit ?? product?.unit,
							price: variant?.price,
						};
						tmpVariants[variant?.id] = item;
						if (productId in prodChildren) {
							productToVariantMapping[productId].push(item);
							prodChildren[productId].push({
								label: variant?.displayName,
								value: variant?.id,
							});
						} else {
							productToVariantMapping[productId] = [item];
							prodChildren[productId] = [{
								label: variant?.displayName,
								value: variant?.id,
							}];
						}
					});
					// eslint-disable-next-line prefer-template
					const categoryId = 'c-' + product?.categoryId;
					if (categoryId in catChildren) {
						categoryToProductMapping[categoryId].push(...productToVariantMapping[productId] ?? []);
						catChildren[categoryId].push({
							label: product?.productName,
							value: productToVariantMapping[productId],
							children: prodChildren[productId],
						});
					} else {
						categoryToProductMapping[categoryId] = [...productToVariantMapping[productId] ?? []];
						catChildren[categoryId] = [
							{
								label: product?.productName,
								value: productToVariantMapping[productId],
								children: prodChildren[productId],
							},
						];
					}
				}
			});

			const tempData = [];
			productCategoriesList?.forEach((category) => {
				// eslint-disable-next-line prefer-template
				const categoryId = 'c-' + category?.id;
				if (categoryToProductMapping[categoryId]?.length > 0) {
					const obj = {
						label: category?.productCategoryName,
						value: categoryToProductMapping[categoryId],
						children: catChildren[categoryId],
					};
					tempData.push(obj);
				}
			});
			setVariants(tmpVariants);
			setData(tempData);
		}
	}, [productsList, productCategoriesList]);

	useEffect(() => {
		if (Object.keys((variants)).length > 0) {
			const newProducts = [];
			const newPrices = {};
			value.forEach((item) => {
				if (Array.isArray(item)) {
					item.forEach((subItem) => {
						newProducts.push(subItem);
						if (subItem?.variantId in prices) {
							// Iterate over each subItem variantId
							const tmpFlatData = cloneDeep(flatData);
							Object.keys(prices?.[subItem?.variantId]).forEach((key) => {
								if (key in tmpFlatData) {
									tmpFlatData[key] = prices?.[subItem?.variantId]?.[key];
								}
							});
							newPrices[subItem?.variantId] = {
								...tmpFlatData,
							};
						} else {
							const flatDataWithPrice = {
								...flatData,
							};

							Object.keys(flatDataWithPrice).forEach((key) => {
								const updatedItem = {
									...flatData[key],
									value: getPriceConvertedQuantity(subItem?.price, subItem?.unit),
								};
								flatDataWithPrice[key] = updatedItem;
							});
							newPrices[subItem?.variantId] = flatDataWithPrice;
						}
					});
				} else {
					if (item in prices) {
						const tmpFlatData = cloneDeep(flatData);
						Object.keys(prices?.[item]).forEach((key) => {
							if (key in tmpFlatData) {
								tmpFlatData[key] = prices?.[item]?.[key];
							}
						});
						newPrices[item] = {
							...tmpFlatData,
						};
					} else {
						const flatDataWithPrice = {
							...flatData,
						};

						Object.keys(flatDataWithPrice).forEach((key) => {
							const updatedItem = {
								...flatData[key],
								value: getPriceConvertedQuantity(variants?.[item]?.price, variants?.[item]?.unit),
							};
							flatDataWithPrice[key] = updatedItem;
						});
						newPrices[item] = flatDataWithPrice;
					}
					newProducts.push(variants?.[item]);
				}
			});

			dispatch({
				type: 'SET_PRODUCTS',
				payload: newProducts,
			});
			setPrices(newPrices);
		}
	}, [value, flatData, variants]);

	useEffect(() => {
		const selVendor = (pageType === 'BUYER' ? buyersList : vendorsList).find((vendor) => {
			return vendor?.id === state?.vendor;
		});
		setSelectedVendor(selVendor);
	}, [state?.vendor]);

	const VENDORS = useMemo(() => {
		return (pageType === 'BUYER' ? buyersList : vendorsList).filter((vendor) => {
			if (pageType === 'BUYER') {
				return vendor.vendorType === 'BUYER';
			}
			return vendor.vendorType === 'INDENT';
		}).map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
				name: vendor?.name ?? '',
			};
		});
	}, [vendorsList, buyersList, pageType]);

	const mappingData = (dataToMap) => {
		const tempFlatData = {};

		const recFunction = (dataToRec) => {
			const returnData = [];
			let excludedChildren = [];
			dataToRec?.forEach((ele) => {
				const { returnData: children, excludedChildren: excChildren } = recFunction(ele?.children);
				const item = {};
				if (ele?.select === 1) {
					let name = ele?.name;
					if (excChildren.length) {
						name += ' ( Excluding ';
						name += excChildren?.map((childEle) => { return childEle?.name; }).join(', ');
						name += ' )';
					}
					item.colName = name;
					item.id = ele?.id;
					item.type = ele?.type;
					const parent = parentKey[ele?.type + '-' + ele?.id];
					const flatItem = {
						type: ele?.type,
						id: ele?.id,
						value: '',
						access: true,
						name: ele?.name,
						parentValue: '',
						override: true,
						myParent: parent,
					};
					// eslint-disable-next-line prefer-template
					tempFlatData[ele?.type + '-' + ele?.id] = flatItem;
					if (children.length > 0) {
						item.subCols = [
							{
								colName: 'Default',
								id: ele?.id,
								type: ele?.type,
								subCols: [],
							},
						];
					}
				} else if (ele?.select === -1) {
					excludedChildren.push(ele);
				}

				if (ele?.select !== 1) {
					excludedChildren = [...excludedChildren, ...excChildren];
				}
				if (children.length > 0) {
					let name = ele?.name;
					if (excChildren.length) {
						name += ' ( Excluding ';
						name += excChildren?.map((childEle) => { return childEle?.name; }).join(', ');
						name += ' )';
					}
					item.colName = name;
					if (item.subCols) {
						item.subCols = [...item.subCols, ...children];
					} else {
						item.subCols = children;
					}
				}
				if (Object.keys(item).length !== 0) {
					returnData.push(item);
				}
			});
			return {
				returnData, excludedChildren,
			};
		};

		const { returnData: children } = recFunction(dataToMap);
		const returnData = [
			{
				colName: 'Products',
				subCols: [],
			},
			{
				colName: 'Unit',
				subCols: [],
			},
			...children,
		];
		setFlatData(tempFlatData);
		return returnData;
	};

	useEffect(() => {
		setHeaders(mappingData(region));
	}, [region]);

	const Column = ({ header, children }) => {
		return (
			<div>
				<div
					style={{
						background: '#154c94',
						padding: '4px 10px',
						color: ' #fff',
						textAlign: 'center',
					}}
				>
					{header}
				</div>
				{children}
			</div>
		);
	};

	const updateValue =
	(variantIdToPass, typeToPass, idToPass,
		inputValueToPass, modifiedToPass, goToDepthToPass = true) => {
		const updatePriceValue =
		(variantId, type, id, inputValue, modified, updatedPrices = {}, goToDepth = false) => {
			if (updatedPrices[variantId]) {
				const key = `${type}-${id}`;
				const updatedProduct = {
					...updatedPrices[variantId],
				};

				if (updatedProduct[key]) {
					updatedProduct[key] = {
						...updatedProduct[key],
						[modified]: inputValue,
					};
					// eslint-disable-next-line no-param-reassign
					updatedPrices[variantId] = updatedProduct;
				}

				const price = updatedPrices?.[variantId]?.[key];
				childrenMapping[key]?.forEach((child) => {
					const valueToPass = inputValue ? price?.value : price?.parentValue;
					if (modified === 'value' || (modified === 'parentValue' && !price?.override)) {
						updatePriceValue(variantId, child?.type, child?.id, inputValue, 'parentValue', updatedPrices, goToDepth);
					} else if (modified === 'access' || modified === 'override') {
						updatePriceValue(variantId, child?.type, child?.id, valueToPass, 'parentValue', updatedPrices, goToDepth);
					} else if (goToDepth && modified === 'parentValue' && !price?.override) {
						updatePriceValue(variantId, child?.type, child?.id, price?.value, 'parentValue', updatedPrices, goToDepth);
					}
				});
			}
		};

		const updatedPrices = {
			...prices,
		};

		updatePriceValue(
			variantIdToPass,
			typeToPass,
			idToPass,
			inputValueToPass,
			modifiedToPass,
			updatedPrices,
			goToDepthToPass
		);

		setPrices(updatedPrices);
	};

	const EditableInput = ({ colId: id, type, rowItem }) => {
		const variantId = rowItem?.variantId;
		const flatItemData = prices?.[variantId]?.[type + '-' + id];
		return (
			<>
				<div className={classes(styles['editable-input'])}>
					{flatItemData?.access
						? (
							<div className={classes(styles['item-center'])}>
								<div className={classes(styles.yellow)}>
									{measurement === 'US' ? '$' : '₹'}
								</div>
								<input
									className={classes(styles['custom-input'])}
									type='number'
									min={0}
									// eslint-disable-next-line prefer-template
									value={flatItemData?.value}
									disabled={!flatItemData?.access ?? false}
									onChange={(e) => {
										const inputValue = e?.target?.value;
										const isPositiveDecimal = /^[0-9]\d*(\.\d+)?$/.test(
											inputValue
										);
										if (inputValue === '' || isPositiveDecimal) {
											updateValue(variantId, type, id, inputValue, 'value');
										}
									}}
									placeholder='Price'
								/>
							</div>
						) : (
							<span className={classes(styles['custom-input'])}>N.A.</span>
						)}
					<div>
						{flatItemData?.access
							? (
								<span
									onClick={() => {
										updateValue(variantId, type, id, 0, 'value');
										updateValue(variantId, type, id, false, 'access');
									}}
									className={classes(styles.includeIcon)}
								>
									<CheckCircleIcon />
								</span>
							) : (
								<span
									onClick={() => {
										updateValue(variantId, type, id, 0, 'value');
										updateValue(variantId, type, id, true, 'access');
									}}
									className={classes(styles.excludeIcon)}
								>
									<CancelIcon />
								</span>
							)}
					</div>
				</div>
			</>
		);
	};

	const handleAddVendor = (vendor) => {
		dispatch({
			type: 'SET_VENDOR',
			payload: vendor?.id,
		});
	};

	const handleAddVendorModal = () => {
		toggleAddVendorModal(true, {});
	};

	const handleInputChange = (type, e) => {
		dispatch({
			type,
			payload: e?.target?.value,
		});
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const handleSave = async () => {
		if (!state?.vendor) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Vendor is mandatory',
			});
			return;
		}

		if (!state?.endDate) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'End date is mandatory',
			});
			return;
		}

		if (!state?.paymentTerm) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Payment is mandatory',
			});
			return;
		}
		if (!state?.vendorAuth) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Vendor Signing authority is mandatory',
			});
			return;
		}
		if (!state?.companyAuth) {
			notificationServices.generateNotification({
				type: 'error',
				message:
					appConfig?.companyName + ' Signing authority is mandatory',
			});
			return;
		}

		if (!state?.products) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Select products to create contract',
			});
			return;
		}

		const vendorDetails = VENDORS.find((item) => { return item.id === state.vendor; });
		const details = {
			vendor: state?.vendor,
			products: state?.products,
			paymentTerm: state?.paymentTerm,
			paymentMode: state?.paymentMode,
			endDate: moment(state?.endDate)?.format('M/D/YYYY'),
			vendorAuth: state?.vendorAuth,
			companyAuth: state?.companyAuth,
			vendorDetails,
		};

		setLoading(true);
		const reqData = {
			prices,
			region,
			details,
			pageType,
		};
		try {
			let response;
			if (isEdit) {
				reqData.contractId = contractId;
				response = await allVendorServices.editVendorContract(reqData);
			} else {
				response = await allVendorServices.createVendorContract(reqData);
			}
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				history.goBack();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		dispatch({
			type: 'INIT',
		});
		setValue([]);
		setPrices([]);
	};

	const disabledExpiryDate = (rsuiteDate) => {
		return (
			rsuiteDate < state?.expiryDate ||
			rsuiteDate < new Date().setDate(new Date().getDate() - 1)
		);
	};

	const handleConfirm = (newtableState) => {
		setRegion(newtableState);
	};

	const roundedCancel = (
		<span className={`${styles.excludeIcon}`}>
			<CancelIcon />
		</span>
	);

	const roundedTick = (
		<span className={`${styles.includeIcon}`}>
			<CheckCircleIcon />
		</span>
	);

	const descriptionTableData = [
		{
			component: roundedTick,
			label: 'Enter the product price for that region',
		},
		{
			component: roundedCancel,
			label: 'Not selling product in that region',
		},
	];
	const contractType = pageType === 'BUYER' ? 'Buyer' : 'Vendor';
	const action = isEdit ? 'Edit' : 'Add';
	const pageTitle = `${action} ${contractType} Contract`;
	return (
		<div>
			<BackdropLoader isLoading={isLoading} showProgress />
			<AlertModal />
			<RegionMenuModal handleConfirm={handleConfirm} />
			<CreatePageHeader
				heading={pageTitle}
				showCancel
				handleSave={handleSave}
				handleCancel={handleCancel}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className={styles.root}>
				<div className={classes(styles['vendor-container'])}>
					<div className={classes(styles['label-container'])}>
						<label className={styles.mandatory} htmlFor='vendor'>
							{pageType === 'BUYER' ? 'Select Buyer:' : 'Select Vendor*'}
						</label>
						<CustomInputSelector
							inputName='VENDOR'
							width='300px'
							inputLists={VENDORS}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								handleAddVendor(null);
							}}
							handleKeyPress={handleAddVendor}
							handleSelection={handleAddVendor}
							handleAddNew={handleAddVendorModal}
							addNewText='Add New Vendor'
							placeholder={pageType === 'BUYER' ? 'Select a Buyer' : 'Select a Vendor'}
							selectedValue={selectedVendor?.name}
							classname={styles['vendor-selector']}
							hideAddNew
						/>
					</div>
					{state?.vendor && selectedVendor?.id && (
						<POVendorDetails
							toggleAddVendorModal={toggleAddVendorModal}
							vendorDetails={selectedVendor}
						/>
					)}
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='deliverTo'>
						Select Products*
					</label>
					<div className={styles['deliver-to']}>
						<MultiCascader
							data={data}
							value={value}
							onChange={setValue}
							block
							columnWidth={220}
							style={{
								width: 300,
							}}
							renderColumn={(childNodes, { layer }) => {
								return (
									<Column header={config?.Headers[layer]}>
										{' '}
										{childNodes}
									</Column>
								);
							}}
							placeholder={<span>Products </span>}
							renderValue={() => {
								return (
									<span>
										<span>
											Update Products
										</span>
									</span>
								);
							}}
						/>
					</div>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='paymemtTerm'>
						Payment term*
					</label>
					<TextField
						select
						id='paymentTerms'
						value={state?.paymentTerm ?? 'Payment term'}
						onChange={(e) => {
							handleInputChange('SET_PAYMENT_TERM', e);
						}}
						sx={{
							width: 300,
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					>
						{paymentTerms?.map((ele) => {
							return (
								<MenuItem key={ele} value={ele}>
									{ele}
								</MenuItem>
							);
						})}
					</TextField>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='deliverTo'>
						Contract end date*
					</label>
					<DatePickerCustom
						selectedDate={state?.endDate}
						selectDate={(deliveryDate) => {
							dispatch({
								type: 'SET_END_DATE',
								payload: deliveryDate,
							});
						}}
						style={{
							width: 300,
						}}
						oneTap
						defaultValue={state?.endDate}
						shouldDisableDate={disabledExpiryDate}
					/>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory}>
						{pageType === 'BUYER' ? 'Buyer Signing Authority*' : 'Vendor Signing Authority*'}
					</label>
					<TextField
						placeholder='Authority Name'
						value={state?.vendorAuth}
						onChange={(authName) => {
							dispatch({
								type: 'SET_VENDOR_AUTH',
								payload: authName?.target?.value,
							});
						}}
						sx={{
							width: 300,
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory}>
						{appConfig?.companyName + ' Signing Authority*'}
					</label>
					<TextField
						placeholder='Authority Name'
						value={state?.companyAuth}
						onChange={(authName) => {
							dispatch({
								type: 'SET_COMPANY_AUTH',
								payload: authName?.target?.value,
							});
						}}
						sx={{
							width: 300,
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					/>
				</div>
				{!!state?.products?.length && (
					<div className={classes(styles['label-container'])}>
						<label
							className={styles.mandatory}
							// style={{
							// color: '#E40000',
							// }}
						>
							Service Region*
						</label>
						<div className={styles['access-level']}>
							<Button
								variant='outlined'
								color='primary'
								type='button'
								onClick={() => {
									toggleModal(true, {
										descriptionData, tableState: region,
									});
								}}
							>
								Update Regions
							</Button>
						</div>
					</div>
				)}
			</div>
			{!!state?.products?.length && (
				<>
					<DescriptionBox data={descriptionTableData} />
					<DynamicHeaderTable
						headerData={headers}
						rowsData={state?.products}
						tableDataElement={EditableInput}
						excludedCols={['Products', 'Unit']}
						showUnit
					/>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { LIST: buyersList } = state.form.ALL_BUYERS.data;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;

	return {
		productsList,
		productCategoriesList,
		vendorsList,
		buyersList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleRegionModal,
	fetchProducts: formActions.gateway,
	fetchProductCatergoriesList: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateVendorContractPage);
