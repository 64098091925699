/* eslint-disable react/jsx-one-expression-per-line */
import CheckMark from '../../../isVegCheckMark/CheckMark';
import styles from './Reference.module.scss';
import { classes } from '../../../../Helpers';
import { Measurement } from '../Measurement';

function Reference(props) {
	const {
		source,
		name,
		isVeg,
		brand,
		className,
		vendorName,
		fromStoreName,
		wastageDate,
		assetId,
		temp,
		weight,
		isRTV,
		// wastageReason,
	} = props;

	return (
		<div className={classes(styles.root, className)}>
			<div className={styles.imageContainer}>
				{vendorName && (
					<div className={styles.vendor_name}>
						Vendor Name:
						{vendorName}
					</div>
				)}
				<img
					className={styles.image}
					src={source}
					alt='Food Reference'
				/>
				{isVeg != null && (
					<div className={styles.footer}>
						<img className={styles.brand} src={brand} alt='' />
						<CheckMark isVeg={isVeg} />
						<div className={styles['name-container']}>
							<p className={styles.name}>{name}</p>
							{assetId && <p>{assetId}</p>}
						</div>
					</div>
				)}
				<div className={styles.extraData}>
					{fromStoreName && (
						<div>
							From Outlet: <span> {fromStoreName} </span>
						</div>
					)}
					{wastageDate && (
						<div>
							Date: <span> {wastageDate} </span>
						</div>
					)}
				</div>
				{isRTV && (
					<div className={styles.rtv}>
						<p>This item was RETURNED to Vendor</p>
					</div>
				)}
				{/* {wastageReason &&
					(Array.isArray(wastageReason) ? (
						<>
							<div className={styles.reasonContainer}>
								{wastageReason?.map((reason) => {
									return (
										<p className={styles.reason}>
											Wastage Reason:
											{reason}
										</p>
									);
								})}
							</div>
						</>
					) : (
						<>
							<p className={styles.reason}>
								Wastage Reason:
								{wastageReason}
							</p>
						</>
					))} */}
				<Measurement
					className={styles.measurement}
					temp={temp}
					weight={weight}
				/>
			</div>
		</div>
	);
}

Reference.defaultProps = {
	source: '',
	name: '',
	isVeg: true,
	className: '',
};

export default Reference;
