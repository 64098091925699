import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../Pages';
import CreateUserPage from '../../../Pages/Accounts/MyUsersPermissions/CreateUserPage/CreateUserPage';
import MyUsersPermissionsPage from '../../../Pages/Accounts/MyUsersPermissions/MyUsersPage';

const MyUsersRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MyUsersPermissionsPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage createPage={<CreateUserPage />} entity='BRANDING' />
			</Route>
		</Switch>
	);
};

export default MyUsersRoutes;
