/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable*/
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../Actions';
import { GeneralDetails } from '../../Components/Forms/Brands/GeneralDetails';
import ServiceEnabled from '../../Components/Forms/Brands/Service Enabled/ServiceEnabled';
import { ViewPageHeader } from '../../Components/Headers';
import { StyledTab, StyledTabs, TabPanel } from '../../Components/StyledTabs';
/* eslint-disable linebreak-style */
import { generateDisabledProps, history, isEmpty } from '../../Helpers';
import brandsConfig from './Brands.config';
import authActions from '@/Actions/auth.actions';
import { useAuth } from '@/Components/Auth/Login/auth';

const TABS = ['GENERAL DETAILS'];

const BrandViewPage = (props) => {
	const { fetchBrand, data, deleteBrand, breadcrumbs, userPermissions, navState, fetchUserList, userList } = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(brandsConfig.initialState.fields),
	});
	const BrandId = useRouteMatch().params.id;
	useEffect(() => {
		fetchBrand({
			entity: 'BRANDS',
			req: {
				params: {
					id: BrandId,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(brandsConfig.serializer(state, data));
		}
	}, [data]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	// const { userConfig } = useAuth();

	// // userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/brands']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });

	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<>
						<div>
							<GeneralDetails isDisabled fields={fields} />
						</div>
						<br />
						<div className=''>
							<div className='p-1 mb-2 border-b-2 border-slate-200'>
								<p>Service Enabled</p>
							</div>
							<ServiceEnabled fields={fields} />
						</div>
					</>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteBrand({
			entity: 'BRANDS',
			req: {
				params: {
					id: BrandId,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};
	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Brands'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.BRANDS.data.VIEW;
	const { navState, userPermissions } = state.navigation;
	const { userList } = state.auth;
	return {
		data,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchBrand: formActions.gateway,
	deleteBrand: formActions.gateway,
	fetchUserList: authActions.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandViewPage);
