import { stateCityConstants } from '../Constants';

const initialState = {
	stateCity: [],
};

function brand(state = initialState, action) {
	switch (action.type) {
		case stateCityConstants.FETCH_STATECITY_REQUEST:
			return {
				...state,
			};
		case stateCityConstants.FETCH_STATECITY_SUCCESS:
			return {
				...state,
				stateCity: action.payload,
			};
		case stateCityConstants.FETCH_STATECITY_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default brand;
