import IconInput from '../../Inputs/IconInput';
import styles from './SellingParameters.module.scss';

const SellingParameters = (props) => {
	const { mandatoryFields, fields, errors, handleInput } = props;

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '100%',
				}}
			>
				<div className='form-field row'>
					<label
						htmlFor='sellingPrice'
						data-mandatory={mandatoryFields.sellingPrice}
						className='form-label'
					>
						Selling Price (per unit)
					</label>
					<IconInput
						size='large'
						type='rupee'
						iconText=''
						name='sellingPrice'
						id='sellingPrice'
						value={fields.sellingPrice}
						handleInput={handleInput}
						dataType=''
						dataId=''
						dataAttrs={{
							required: mandatoryFields.sellingPrice,
						}}
						step='0.01'
					/>
					<span className='form-error'>{errors.sellingPrice}</span>
					<label
						htmlFor='taxRate'
						data-mandatory={mandatoryFields.taxRate}
						className={`${styles.label} form-label`}
					>
						Tax Rate
					</label>
					<IconInput
						size='large'
						type='rupee'
						iconText=''
						name='taxRate'
						id='taxRate'
						value={fields.taxRate}
						handleInput={handleInput}
						dataType=''
						dataId=''
						dataAttrs={{
							required: mandatoryFields.taxRate,
						}}
						step='0.01'
					/>
					<span className='form-error'>{errors.taxRate}</span>
				</div>
			</div>
		</div>
	);
};

SellingParameters.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

export default SellingParameters;
