// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
// import moment from 'moment';

const initialState = {
	fromStore: {},
	store: {},
	salesReturnId: '',
	orderId: null,
	// vendor: null,
	salesReturnDate: new Date(),
	items: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = [
	{
		field: 'store',
		displayName: 'Store',
	},
	{
		field: 'salesReturnId',
		displayName: 'Client Challan Id',
	},
	// {
	// field: '',
	// displayName: 'Credit note from a store',
	// },
	// {
	// field: 'itemReturnDate',
	// displayName: 'Item return date',
	// },
];

const config = {
	initialState,
	reducer,
	serializer: (data) => {
		return data;
		// const newState = {
		// machineName: data?.machine_name,
		// macAddress: data?.mac,
		// store: data?.storeId,
		// process: data?.processId,
		// manufacturedOn: data?.manufactured_on
		// ? new Date(data?.manufactured_on)
		// : null,
		// installedOn: data?.installed_on
		// ? new Date(data?.installed_on)
		// : null,
		// expiresOn: data?.warranty_expires_on
		// ? new Date(data?.warranty_expires_on)
		// : null,
		// serialNo: data?.serial_number,
		// modelNo: data?.model_number,
		// };
		// return newState;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const { ...remaining } = clonedState;
		const newState = {
			transactionDate: moment(remaining?.salesReturnDate)?.format('M/D/YYYY'),
			fromStore: remaining?.fromStore?.id,
			storeId: remaining?.store?.id,
			clientTransactionId: `SR-${remaining?.salesReturnId}`,
			orderId: remaining?.orderId,
			items: remaining?.items?.map((ele) => {
				return {
					productId: ele?.productId,
					variantId: ele?.selectedVariant?.id,
					qty: Number(ele?.quantity),
					price: Number(ele?.selectedVariant?.price),
					tax: ele?.tax,
					subTotal: ele?.subTotal,
					totalTax: ele?.totalTax,
					finalAmount: ele?.totalPriceIncTax,
				};
			}),
		};
		return newState;
	},
	validator: (state) => {
		return mandatoryFields?.find((key) => {
			return (
				state[key?.field] === null ||
				state[key?.field] === '' ||
				!state[key?.field] === true
			);
		});
	},
};

export default config;
