import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { notificationServices } from '../../../Services';

const subscriptionConfig = {
	initialState: {
		fields: {
			id: 0,
			name: '',
			brand: '',
			image: '',
			startsFrom: '',
			endsOn: '',
			variants: [],
		},
	},
	list: [],
	deserializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				id: data.id,
				name: data.productName,
				brand: data.brandName,
				image: data.productImageUrl,
				startsFrom: data.subscriptionFrom
					? moment(data.subscriptionFrom, 'DD-MM-YYYY HH:mm').format(
						'MM DD YYYY HH:mm'
					)
					: null,
				endsOn: data.subscriptionTo
					? moment(data.subscriptionTo, 'DD-MM-YYYY HH:mm').format(
						'MM DD YYYY HH:mm'
					)
					: null,
				variants: data.variants.map((variant) => {
					const newVariant = cloneDeep(variant);
					newVariant.subscriptionPrice =
						newVariant.subscriptionPrice ?? newVariant.price;
					return newVariant;
				}),
			};
			return newState;
		});
	},
	serializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const data = {
				id: state.id,
				subscriptionFrom: moment(state.startsFrom).format(
					'DD-MM-YYYY HH:mm'
				),
				subscriptionTo: moment(state.endsOn).format('DD-MM-YYYY HH:mm'),
				variants: state.variants.map((variant) => {
					const tempVariant = {
						id: variant.id,
						subscriptionPrice: variant.subscriptionPrice
							? variant.subscriptionPrice.toString()
							: '0',
						subscriptionEnabled:
							variant.subscriptionEnabled || false,
					};
					return tempVariant;
				}),
			};
			return data;
		});
	},
	validator: (state) => {
		if (moment(state.fields.startsFrom).isAfter(state.fields.endsOn)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter valid timings',
			});
			return false;
		}
		return true;
	},
};
export default subscriptionConfig;
