/* eslint-disable no-tabs */
import {
	useEffect, useState, useRef
} from 'react';
import Draggable from 'react-draggable';
import StaticText from '../LabelGeneratorStaticText/StaticText';
import QRCode from '../LabelGeneratorQRCode/QRCode';
import Barcode from '../LabelGeneratorBarcode/Barcode';
import PlaceholderText from '../LabelGeneratorPlaceholderText/PlaceholderText';
import Image from '../LabelGeneratorImage/Image';
import InitializeTable from '../LableGeneratorTable/InitializeTable';

function Label(props) {
	const { setLabelComponentsState } = props;
	const placeholderRef = useRef(null);
	const [prevTool, setPrevTool] = useState(null);

	useEffect(() => {
		setPrevTool(props?.tool);
	}, [props?.tool]);

	useEffect(() => {
		const uid = Date.now();
		let newComponent = {};
		if (props?.tool === 'qrCode') {
			newComponent = {
				id: uid,
				type: 'qrCode',
				position: {
					x: 0, y: 0,
				},
				qrCode: {
					height: props?.selectedToolComponent?.qrCode?.qrCodeDim
						?.height,
					width: props?.selectedToolComponent?.qrCode?.qrCodeDim
						?.width,
				},
			};
		} else if (props?.tool === 'barCode') {
			newComponent = {
				id: uid,
				type: 'barCode',
				position: {
					x: 0, y: 0,
				},
				barCode: {
					height: props?.selectedToolComponent?.barCode?.barCodeDim
						?.height,
					width: props?.selectedToolComponent?.barCode?.barCodeDim
						?.width,
				},
			};
		} else if (props?.tool === 'Static Text') {
			newComponent = {
				id: uid,
				type: 'staticText',
				position: {
					x: 0, y: 0,
				},
				staticText: {
					font: 'Font A',
					fontSize: 16,
					text: props?.selectedToolComponent?.['Static Text']
						?.staticText,
					height: props?.selectedToolComponent?.['Static Text']
						?.staticTextBoxDim?.height,
					width: props?.selectedToolComponent?.['Static Text']
						?.staticTextBoxDim?.width,
					highlight: props?.selectedToolComponent?.['Static Text']?.highlight,
				},
			};
		} else if (props?.tool === 'Text') {
			newComponent = {
				id: uid,
				type: 'text',
				position: {
					x: 0, y: 0,
				},
				text: {
					placeHolderName:
						props?.selectedToolComponent?.Text?.placeholder,
					placeholderText:
						props?.selectedToolComponent?.Text?.placeholderText,
					font: 'Font A',
					height: props?.selectedToolComponent?.Text?.textBoxDim
						?.height,
					width: props?.selectedToolComponent?.Text?.textBoxDim
						?.width,
					fontSize: 16,
					inputLines: props?.selectedToolComponent?.Text?.inputLines,
					highlight: props?.selectedToolComponent?.Text?.highlight,
				},
			};
		} else if (props?.tool === 'Insert') {
			// setPrevImg(props?.imageURL);
			newComponent = {
				id: uid,
				type: 'image',
				position: {
					x: 0, y: 0,
				},
				image: {
					imageURL: props?.selectedToolComponent?.image?.imageURL,
					width: props?.selectedToolComponent?.image?.imageDim?.width,
					height: props?.selectedToolComponent?.image?.imageDim?.height,
				},
			};
		} else if (props?.tool === 'table') {
			newComponent = {
				id: uid,
				type: 'table',
				position: {
					x: 0, y: 0,
				},
				table: {
					width: props?.selectedToolComponent?.table?.width,
					...props?.selectedToolComponent?.table,
					rows: props?.selectedToolComponent?.table?.rows,
					columns: props?.selectedToolComponent?.table?.columns,
				},
			};
		}
		props?.setCurrentLabelComponents(() => { return [newComponent]; });
		props?.setCurrentComponent(newComponent?.id);
	}, [prevTool]);

	useEffect(() => {
		const newHeight = (props?.labelDetails.size.height * (96 / 25.4)) / 12.25;
		const newWidth = (props?.labelDetails.size.width * (96 / 25.4)) / 12.25;
		document.getElementById('label').style.width = `${newWidth}rem`;
		document.getElementById('label').style.height = `${newHeight}rem`;
	}, [props?.labelDetails]);

	useEffect(() => {
		if (props?.selectedComponent !== null) {
			const currElement = props?.labelComponentsState.find(
				(currentComponent) => { return props?.selectedComponent === currentComponent.id; }
			);

			props?.handleSelectedComponent(currElement);
		}
	}, [props?.selectedComponent]);

	useEffect(() => {
		if (props?.selectedComponent === null) {
			const newComponent = props?.currentLabelComponents.map(
				(component) => {
					if (component?.type === 'text') {
						return {
							...component,
							text: {
								...component?.text,
								placeHolderName:
									props?.selectedToolComponent?.Text
										?.placeholder,
								placeholderText:
									props?.selectedToolComponent?.Text
										?.placeholderText,
								height: props?.selectedToolComponent?.Text
									?.textBoxDim?.height,
								width: props?.selectedToolComponent?.Text
									?.textBoxDim?.width,
								fontSize:
									props?.selectedToolComponent?.Text
										?.textFontSize,
								inputLines:
									props?.selectedToolComponent?.Text
										?.inputLines,
								highlight:
									props?.selectedToolComponent?.Text
										?.highlight,
							},
						};
					}
					if (component?.type === 'staticText') {
						return {
							...component,
							staticText: {
								...component?.staticText,
								text: props?.selectedToolComponent?.[
									'Static Text'
								]?.staticText,
								height: props?.selectedToolComponent?.[
									'Static Text'
								]?.staticTextBoxDim?.height,
								width: props?.selectedToolComponent?.[
									'Static Text'
								]?.staticTextBoxDim?.width,
								fontSize:
									props?.selectedToolComponent?.[
										'Static Text'
									]?.staticTextFontSize,
								highlight:
									props?.selectedToolComponent?.[
										'Static Text'
									]?.highlight,
							},
						};
					}
					if (component?.type === 'qrCode') {
						return {
							...component,
							qrCode: {
								height: props?.selectedToolComponent?.qrCode
									?.qrCodeDim?.height,
								width: props?.selectedToolComponent?.qrCode
									?.qrCodeDim?.width,
							},
						};
					}
					if (component?.type === 'barCode') {
						return {
							...component,
							barCode: {
								height: props?.selectedToolComponent?.barCode?.barCodeDim
									?.height,
								width: props?.selectedToolComponent?.barCode?.barCodeDim
									?.width,
							},
						};
					}
					if (component?.type === 'image') {
						return {
							...component,
							image: {
								imageURL: props?.selectedToolComponent?.image?.imageURL,
								width: props?.selectedToolComponent?.image?.imageDim?.width,
								height: props?.selectedToolComponent?.image?.imageDim?.height,
							},
						};
					}
					if (component?.type === 'table' &&
					props?.selectedToolComponent?.table?.tableContent !== undefined &&
					props?.selectedToolComponent?.table?.tableContent.length) {
						return {
							...component,
							table: {
								...component?.table,
								rows:
									props?.selectedToolComponent?.table
										?.tableContent?.length - 1,
								columns:
									props?.selectedToolComponent?.table
										?.tableContent[0].length,
								tableContent:
									props?.selectedToolComponent?.table
										?.tableContent,
								width: props?.selectedToolComponent?.table?.width,
								columnRatio: props?.selectedToolComponent?.table?.columnRatio,
								tableFontSize: props?.selectedToolComponent?.table?.tableFontSize,
							},
						};
					}
					return {};
				}
			);
			props?.setCurrentLabelComponents(newComponent);
		} else if (props?.selectedComponent !== null) {
			let currElement = props?.labelComponentsState.find(
				(currentComponent) => { return props?.selectedComponent === currentComponent?.id; }
			);

			if (
				currElement?.type === 'text' &&
				props?.selectedToolComponent?.type === 'Text'
			) {
				currElement = {
					...currElement,
					text: {
						...currElement?.text,
						placeHolderName:
							props?.selectedToolComponent?.Text?.placeholder,
						placeholderText:
							props?.selectedToolComponent?.Text?.placeholderText,
						height: props?.selectedToolComponent?.Text?.textBoxDim
							?.height,
						width: props?.selectedToolComponent?.Text?.textBoxDim
							?.width,
						fontSize:
							props?.selectedToolComponent?.Text?.textFontSize,
						inputLines:
							props?.selectedToolComponent?.Text?.inputLines,
						highlight:
							props?.selectedToolComponent?.Text?.highlight,
					},
				};
			} else if (
				currElement?.type === 'staticText' &&
				props?.selectedToolComponent?.type === 'Static Text'
			) {
				currElement = {
					...currElement,
					staticText: {
						...currElement?.staticText,
						text: props?.selectedToolComponent?.['Static Text']
							?.staticText,
						height: props?.selectedToolComponent?.['Static Text']
							?.staticTextBoxDim?.height,
						width: props?.selectedToolComponent?.['Static Text']
							?.staticTextBoxDim?.width,
						fontSize:
							props?.selectedToolComponent?.['Static Text']
								?.staticTextFontSize,
						highlight:
							props?.selectedToolComponent?.['Static Text']
								?.highlight,
					},
				};
			} else if (
				currElement?.type === 'qrCode' &&
				props?.selectedToolComponent?.type === 'qrCode'
			) {
				currElement = {
					...currElement,
					qrCode: {
						height: props?.selectedToolComponent?.qrCode?.qrCodeDim
							?.height,
						width: props?.selectedToolComponent?.qrCode?.qrCodeDim
							?.width,
					},
				};
			} else if (
				currElement?.type === 'barCode' &&
				props?.selectedToolComponent?.type === 'barCode'
			) {
				currElement = {
					...currElement,
					barCode: {
						height: props?.selectedToolComponent?.barCode?.barCodeDim
							?.height,
						width: props?.selectedToolComponent?.barCode?.barCodeDim
							?.width,
					},
				};
			} else if (
				currElement?.type === 'image' &&
				props?.selectedToolComponent?.type === 'image'
			) {
				currElement = {
					...currElement,
					image: {
						imageURL: props?.selectedToolComponent?.image?.imageURL,
						width: props?.selectedToolComponent?.image?.imageDim?.width,
						height: props?.selectedToolComponent?.image?.imageDim?.height,
					},
				};
			} else if (
				currElement?.type === 'table' &&
				props?.selectedToolComponent?.type === 'table' &&
				props?.selectedToolComponent?.table?.tableContent !== undefined &&
				props?.selectedToolComponent?.table?.tableContent.length
			) {
				currElement = {
					...currElement,
					table: {
						rows:
							props?.selectedToolComponent?.table
								?.tableContent?.length - 1,
						columns:
							props?.selectedToolComponent?.table
								?.tableContent[0].length,
						tableContent:
							props?.selectedToolComponent?.table
								?.tableContent,
					},
				};
			}
			props?.handleSelectedComponent(currElement);
			setLabelComponentsState([
				...props?.labelComponentsState.filter((component) => {
					return component.id !== props?.selectedComponent;
				}),
				currElement,
			]);
		}
	}, [
		props?.selectedToolComponent?.Text?.placeholder,
		props?.selectedToolComponent?.Text?.highlight,
		props?.selectedToolComponent?.['Static Text']?.staticText,
		props?.selectedToolComponent?.Text?.textBoxDim?.height,
		props?.selectedToolComponent?.Text?.textBoxDim?.width,
		props?.selectedToolComponent?.['Static Text']?.staticTextBoxDim?.height,
		props?.selectedToolComponent?.['Static Text']?.staticTextBoxDim?.width,
		props?.selectedToolComponent?.qrCode?.qrCodeDim?.height,
		props?.selectedToolComponent?.qrCode?.qrCodeDim?.width,
		props?.selectedToolComponent?.barCode?.barCodeDim?.height,
		props?.selectedToolComponent?.barCode?.barCodeDim?.width,
		props?.selectedToolComponent?.image?.imageDim?.width,
		props?.selectedToolComponent?.image?.imageDim?.height,
		props?.selectedToolComponent?.image?.imageURL,
		props?.selectedToolComponent?.Text?.textFontSize,
		props?.selectedToolComponent?.['Static Text']?.staticTextFontSize,
		props?.selectedToolComponent?.['Static Text']?.highlight,
		props?.selectedToolComponent?.Text?.placeholderText,
		props?.selectedToolComponent?.Text?.inputLines,
		props?.selectedToolComponent?.table?.tableContent,
		props?.selectedToolComponent?.table?.columnRatio,
		props?.selectedToolComponent?.table?.tableFontSize,
	]);

	useEffect(() => {
		if (props?.backButtonClicked) {
			props?.setCurrentLabelComponents([]);
		} else if (props?.currentLabelComponents.length !== 0 ||
			props?.currentLabelComponents[0] !== undefined) {
			props?.setLabelComponentsState((prev) => {
				if (
					props?.currentLabelComponents.length === 0 ||
						props?.currentLabelComponents[0] === undefined ||
						Object.keys(props?.currentLabelComponents[0]).length === 0
				) {
					return [...prev];
				}
				return [...prev, props?.currentLabelComponents?.[0]];
			});
			const currElement = props?.labelComponentsState.find(
				(currentComponent) => { return props?.selectedComponent === currentComponent.id; }
			);

			props?.handleSelectedComponent(currElement);
			setTimeout(() => {
				props?.setCurrentLabelComponents([]);
			}, 500);
		}
	}, [props?.backButtonClicked,
		props?.currentLabelComponents?.length]);

	function renderComponent(component) {
		switch (component?.type) {
			case 'staticText':
				return (
					<StaticText
						key={component?.id}
						id={component?.id}
						text={component?.staticText?.text}
						textBoxHeight={component?.staticText?.height}
						textBoxWidth={component?.staticText?.width}
						fontSize={component?.staticText?.fontSize}
						selectedComponent={props?.selectedComponent}
						currentComponent={props?.currentComponent}
						setCurrentComponent={props?.setCurrentComponent}
					/>
				);
			case 'qrCode':
				return (
					<QRCode
						key={component?.id}
						id={component?.id}
						height={component?.qrCode?.height}
						width={component?.qrCode?.width}
						selectedComponent={props?.selectedComponent}
						currentComponent={props?.currentComponent}
						setCurrentComponent={props?.setCurrentComponent}
					/>
				);
			case 'barCode':
				return (
					<Barcode
						key={component?.id}
						id={component?.id}
						height={component?.barCode?.height}
						width={component?.barCode?.width}
						selectedComponent={props?.selectedComponent}
						currentComponent={props?.currentComponent}
						setCurrentComponent={props?.setCurrentComponent}
					/>
				);
			case 'text':
				return (
					<PlaceholderText
						ref={placeholderRef}
						key={component?.id}
						id={component?.id}
						placeHolderName={`<${component?.text?.placeHolderName}>`}
						placeholderText={component?.text?.placeholderText}
						font={component?.text?.font}
						height={component?.text?.height}
						width={component?.text?.width}
						selectedComponent={props?.selectedComponent}
						fontSize={component?.text?.fontSize}
						inputLines={component?.text?.inputLines}
						currentComponent={props?.currentComponent}
						setCurrentComponent={props?.setCurrentComponent}
					/>
				);
			case 'image':
				return (
					<Image
						key={component?.id}
						id={component?.id}
						src={component?.image?.imageURL}
						height={component?.image?.height}
						width={component?.image?.width}
						alt='uploadedImage'
						selectedComponent={props?.selectedComponent}
						currentComponent={props?.currentComponent}
						setCurrentComponent={props?.setCurrentComponent}
					/>
				);
			case 'table':
				return (
					<InitializeTable
						id={component?.id}
						width={component?.table?.width}
						rows={component?.table?.rows}
						fontSize={component?.table?.tableFontSize}
						labelDetails={props?.labelDetails?.size}
						tableContent={component?.table?.tableContent}
						columnRatio={component?.table?.columnRatio}
						columns={component?.table?.columns}
						selectedComponent={props?.selectedComponent}
						currentComponent={props?.currentComponent}
						tableFontSize={component?.table?.tableFontSize}
					/>
				);
			default:
				return null;
		}
	}
	return (
		<div
			id='label'
			style={{
				position: 'relative',
			}}
		>
			{props?.labelComponentsState.map((component, index) => {
				return (
					<Draggable
						bounds='parent'
						id={index}
						// eslint-disable-next-line react/no-array-index-key
						key={index + 1}
						axis='both'
						handle='.handle'
						position={component?.position}
						grid={[1, 1]}
						scale={1}
						onDrag={(Event, ui) => {
							props?.setLabelComponentsState((prev) => {
								return prev.map((ele, idx) => {
									if (index === idx) {
										return {
											...ele,
											position: {
												x: ui.x, y: ui.y,
											},
										};
									}
									return ele;
								});
							});
						}}
					>
						<div
							id='draggableClick'
							style={{
								position: 'absolute',
							}}
							onClick={() => {
								props?.setSaveButtonClicked(false);
								props?.setBackButtonClicked(false);
								const currElement =
									props?.labelComponentsState.find(
										(currentComponent) => {
											return component?.id ===
											currentComponent.id;
										}
									);
								if (
									props?.selectedComponent === component?.id
								) {
									props?.setSelectedComponent(null);
									props?.editComponentTool(null);
								} else {
									props?.editComponentTool(currElement?.type);
									props?.setSelectedComponent(component?.id);
								}
							}}
						>
							<div
								className='handle'
								key={component?.id}
								id={component?.id}
								style={{
									height: 0, backgroundColor: 'red',
								}}
							>
								{renderComponent(component)}
							</div>
						</div>
					</Draggable>
				);
			})}
			{props?.currentLabelComponents.map((component, index) => {
				return (
					<Draggable
						bounds='parent'
						id={index}
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						axis='both'
						handle='.handle'
						defaultPosition={
							props?.currentLabelComponents[index]?.position
						}
						grid={[1, 1]}
						scale={1}
						onDrag={(Event, ui) => {
							props?.setCurrentLabelComponents((prev) => {
								return prev.map((ele, idx) => {
									if (index === idx) {
										return {
											...ele,
											position: {
												x: ui.x, y: ui.y,
											},
										};
									}
									return ele;
								});
							});
						}}
					>
						<div
							style={{
								position: 'absolute',
								margin: 0,
								visibility: 'visible',
								padding: 0,
								minHeight: 0,
							}}
						>
							<div
								className='handle'
								key={component?.id}
								style={{
									margin: 0,
									padding: 0,
								}}
							>
								{renderComponent(component)}
							</div>
						</div>
					</Draggable>
				);
			})}
		</div>
	);
}

export default Label;
