import { useRouteMatch } from 'react-router';
import {
	HolidayCreatePage,
	HolidayListPage,
	HolidayViewPage,
} from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const HolidayRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='HOLIDAY'
			path={path}
			listPage={HolidayListPage}
			createPage={<HolidayCreatePage />}
			viewPage={<HolidayViewPage />}
		/>
	);
};

export default HolidayRoutes;
