import { formConstants, refundsConstants } from '../Constants';

const initialState = {
	COMBOS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	PRINTER_CONFIG: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	BRANDS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	MASTER_MENUS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	PRODUCTS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	STORES: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	FILTERED_STORES: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	PROCESS_TYPES: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	PRODUCT_CATEGORY: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	PRODUCT_SUB_CATEGORY: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	SKUS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	ALL_PRODUCTS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	CUSTOMIZATIONS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	VARIANTS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	VARIANTS_THRESHOLD: {
		data: {
			VIEW: {},
			LIST_DATA: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	SUBSCRIPTION: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	NUTRITIONAL_INFO: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	COOKING_PRODUCT_CONFIG: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	COOKING_STATIONS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		error: '',
	},
	INVENTORY_SKU: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	RIDERS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	BRANDING: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	STORE_SETTINGS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	APPROVE_REFUNDS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	REFUND_HISTORY: {
		data: {
			LIST: [],
		},

		isFetched: {
			LIST: false,
		},
	},
	REQUEST_REFUND: {
		data: {
			LIST: [],
			VIEW_REFUND: {},
		},
		isFetched: {
			VIEW_REFUND: false,
			LIST: false,
		},
		error: '',
	},
	MY_CUSTOMERS: {
		data: {
			VIEW: [],
			LIST: [],
		},
		isFetched: {
			LIST: false,
			VIEW: false,
		},
	},
	PROMO_CODES: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	INVENTORY_CATEGORY: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	USERS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
		error: '',
	},
	RECIPES: {
		data: {
			VIEW: {},
			LIST: [],
		},
		error: '',
	},
	QUALITY_SETTING: {
		data: {
			VIEW: {},
		},
		error: '',
	},
	PRODUCT_QUALITY_CONFIG: {
		data: {
			VIEW: {},
			LIST: [],
		},
	},
	INVENTORY_SETTING: {
		data: {
			VIEW: {},
		},
		error: '',
	},
	INVENTORY_CONFIG: {
		data: {
			VIEW: {},
			LIST: [],
		},
	},
	MY_FRANCHISEE: {
		data: {
			VIEW: {},
			LIST: [],
		},
	},
	DEFECTS: {
		data: {
			VIEW: {},
			LIST: [],
		},
	},
	AI_MODEL: {
		data: {
			LIST: [],
		},
	},
	CHILD: {
		data: {
			CHILD_LIST: {
				address: [],
			},
		},
	},
	ALL_VENDORS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
	ALL_BUYERS: {
		data: {
			VIEW: {},
			LIST: [],
		},
		isFetched: {
			VIEW: false,
			LIST: false,
		},
	},
};

function form(state = initialState, action) {
	switch (action.type) {
		case formConstants.FETCH_REQUEST:
			return {
				...state,
			};
		case formConstants.UPDATE_LIST:
		case formConstants.UPDATE_FIELDS:
		case formConstants.FETCH_SUCCESS:
		case formConstants.FETCH_FAILURE:
			return {
				...state,
				...action.payload,
			};
		case refundsConstants.SET_REFUNDS_DATA:
			return {
				...state,
				REQUEST_REFUND: {
					data: {
						LIST: [],
						VIEW_REFUND: {},
					},
					error: '',
				},
			};
		default:
			return state;
	}
}

export default form;
