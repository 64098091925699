/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import {
	AccordionDetails,
	FormControl,
	FormControlLabel,
} from '@mui/material';
// import { SelectPicker } from 'rsuite';
import { useEffect, useState } from 'react';
import {
	Select as SSelect, SelectContent, SelectItem, SelectTrigger, SelectValue
} from '@/Components/ui/select';

import {
	generateUniqueID, modes, processTypes
} from '../../../Helpers';
import { notificationServices } from '../../../Services';
import processFlowServices from '../../../Services/Analytics Services/process-flow.services';
import { Label } from '@/Components/ui/label';
import { Checkbox } from '@/Components/ui/checkbox';
import { Button } from '@/Components/ui/button';

function MapProcess(props) {
	const {
		setInitialState,
		baseStores,
		storeId,
		setIsChanged,
		immovableAssetsList,
		onClose,
	} = props;
	// const [selectedImage, setSelectedImage] = useState(null);
	const [selectedProcess, setSelectedProcess] = useState(null);
	const [selectedProcessType, setSelectedProcessType] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);
	const [defaultMode, setDefaultMode] = useState(null);
	const [selectedStore, setSelectedStore] = useState(null);
	const [selectedAsset, setSelectedAsset] = useState(null);
	const [availableNodesList, setAvailableNodesList] = useState([]);
	const [availableSectionsList, setAvailableSectionsList] = useState([]);
	const [hasResoeeApp, setHasResoeeApp] = useState(false);
	const [isIndentStore, setIsIndentStore] = useState(false);

	const handleAddProcess = () => {
		if (!selectedProcess) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No process selected',
			});
			return;
		}
		if (!selectedSection) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Section is Mandatory',
			});
			return;
		}
		if (!defaultMode && selectedProcessType === 'OPERATION') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Default Mode is Mandatory',
			});
			return;
		}
		if (!selectedProcessType) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Process Type is Mandatory',
			});
			return;
		}
		if (isIndentStore && !selectedStore) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Store is mandatory',
			});
			return;
		}
		if (selectedProcessType === 'STORAGE' && !selectedAsset) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Asset is mandatory for Storage Process Type',
			});
			return;
		}
		let nodeToAdd = {};
		const newNode = availableNodesList?.find((node) => {
			return node?.id === selectedProcess;
		});
		nodeToAdd = {
			id: generateUniqueID(),
			type: 'customNode',
			data: {
				heading: newNode?.heading,
				imageUrl: newNode?.imageUrl,
				nodeId: newNode?.id,
				sectionId: selectedSection,
				nodeStore: selectedStore,
				hasResoeeApp,
				defaultMode,
				type: selectedProcessType,
				assetId: selectedAsset,
			},
			position: {
				x: Math.floor(Math.random() * 500),
				y: Math.floor(Math.random() * 500),
			},
		};
		setInitialState((prev) => {
			const { nodes, edges } = prev;
			return {
				nodes: [...nodes, nodeToAdd],
				edges,
			};
		});
		setSelectedProcess(null);
		setSelectedProcessType(null);
		setSelectedAsset(null);
		setSelectedSection(null);
		setSelectedStore(null);
		setDefaultMode(null);
		setHasResoeeApp(false);
		setIsChanged(true);
		onClose();
	};

	useEffect(() => {
		fetchNodes();
	}, [storeId]);
	useEffect(() => {
		if (storeId) {
			fetchSections();
		}
	}, [storeId]);

	async function fetchNodes() {
		try {
			const response = await processFlowServices?.fetchAvailableNodes();
			if (response?.statusCode === 200) {
				setAvailableNodesList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	}
	async function fetchSections() {
		try {
			const response = await processFlowServices?.fetchAvailableSections({
				params: {
					storeId,
				},
			});
			if (response?.statusCode === 200) {
				setAvailableSectionsList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error:', error);
		}
	}
	return (
		<>
			<AccordionDetails className='flex flex-col gap-3'>
				<FormControl fullWidth>
					<Label className='text-red-600'>Select a Process*</Label>
					<SSelect
						value={selectedProcess}
						onValueChange={(value) => {
							setSelectedProcess(value);
						}}
					>
						<SelectTrigger className='w-full mt-2 outline-none'>
							<SelectValue placeholder='Select Process' />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							{availableNodesList?.map((ele) => {
								return (
									<SelectItem value={ele?.id}>
										{ele?.heading}
									</SelectItem>
								);
							})}
						</SelectContent>
					</SSelect>
				</FormControl>
				<FormControl fullWidth>
					<Label className='text-red-600'>Select Section*</Label>
					<SSelect
						value={selectedSection}
						onValueChange={(value) => {
							setSelectedSection(value);
						}}
					>
						<SelectTrigger className='w-full mt-2 outline-none'>
							<SelectValue placeholder='Select Process' />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							{availableSectionsList?.map((ele) => {
								return (
									<SelectItem value={ele?.id}>
										{ele?.sectionName}
									</SelectItem>
								);
							})}
						</SelectContent>
					</SSelect>
				</FormControl>
				<FormControl fullWidth>
					<div className={`${isIndentStore ? 'border border-slate-100 p-3 rounded-md' : 'border-none'}`}>
						<div className='flex gap-2 my-1'>
							<Checkbox
								className='rounded-none data-[state=checked]:bg-uy-color data-[state=checked]:text-black'
								checked={isIndentStore}
								onCheckedChange={(checked) => {
									setIsIndentStore(checked);
									if (!checked) {
										setSelectedStore(null);
									}
								}}
							/>
							<Label>Indent Store</Label>
						</div>
						{isIndentStore && (
							<div>
								<Label className='text-red-600'>Select Indent Store*</Label>
								<SSelect
									value={selectedStore}
									onValueChange={(value) => {
										setSelectedStore(value);
									}}
								>
									<SelectTrigger className='w-full mt-2 outline-none'>
										<SelectValue placeholder='Select Store' />
									</SelectTrigger>
									<SelectContent className='max-h-[200px] z-[9999] '>
										{baseStores?.filter((ele) => {
											return ele?.id !== storeId;
										})?.map((ele) => {
											return (
												<SelectItem value={ele?.id}>
													{ele?.name}
												</SelectItem>
											);
										})}
									</SelectContent>
								</SSelect>
							</div>
						)}
					</div>
				</FormControl>
				<FormControl>
					<Label className='text-red-600'>Select Process Type*</Label>
					<SSelect
						value={selectedProcessType}
						onValueChange={(value) => {
							setSelectedProcessType(value);
							if (value !== 'STORAGE' && selectedAsset) {
								setSelectedAsset(null);
							}
						}}
					>
						<SelectTrigger className='w-full mt-2 outline-none'>
							<SelectValue placeholder='Select Process Type' />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							{processTypes?.map((ele) => {
								return (
									<SelectItem value={ele?.id}>
										{ele?.name}
									</SelectItem>
								);
							})}
						</SelectContent>
					</SSelect>
				</FormControl>
				{selectedProcessType === 'STORAGE' && (
					<FormControl fullWidth>
						<Label className='text-red-600'>Select Immovable Asset*</Label>
						<SSelect
							disabled={selectedProcessType !== 'STORAGE'}
							value={selectedAsset}
							onValueChange={(value) => {
								setSelectedAsset(value);
							}}
						>
							<SelectTrigger className='w-full mt-2 outline-none'>
								<SelectValue placeholder='Select Process' />
							</SelectTrigger>
							<SelectContent className='max-h-[200px] z-[9999] '>
								{immovableAssetsList?.map((ele) => {
									return (
										<SelectItem value={ele?.id}>
											{!ele?.name ? '-' : ele?.name}
											{' '}
											{ele?.assetType ? `(${ele?.assetType})` : ''}
										</SelectItem>
									);
								})}
							</SelectContent>
						</SSelect>
					</FormControl>
				)}
				{
					selectedProcessType === 'OPERATION' && (
						<FormControl fullWidth>
							<Label className='text-red-600'>Select Default Mode*</Label>
							<SSelect
								value={defaultMode}
								onValueChange={(value) => {
									setDefaultMode(value);
								}}
							>
								<SelectTrigger className='w-full mt-2 outline-none'>
									<SelectValue placeholder='Select Process' />
								</SelectTrigger>
								<SelectContent className='z-[9999]'>
									{Object?.keys(modes)?.map((key) => {
										return (
											<SelectItem value={key}>
												{modes[key]}
											</SelectItem>
										);
									})}
								</SelectContent>
							</SSelect>
						</FormControl>

					)
				}
				<FormControlLabel
					className='flex gap-2 my-2.5 ml-1'
					control={(
						<Checkbox
							className='data-[state=checked]:bg-uy-color data-[state=checked]:text-black '
							checked={hasResoeeApp}
							onCheckedChange={(checked) => setHasResoeeApp(checked)}
						/>
					)}
					label='Resoee App?'
				/>
				<Button
					className='bg-sky-600 text-white'
					onClick={handleAddProcess}
				>
					Add Process
				</Button>
			</AccordionDetails>
		</>
	);
}

export default MapProcess;
