import { Layer, Stage } from 'react-konva';
import { useAuth } from '../../../Auth/Login/auth';
import CircleLabel from './CircleLabel';

const setBoxDimensions = (rect) => {
	return {
		width: rect?.width ?? 0,
		height: rect?.height ?? 0,
	};
};

const TemperatureHUD = (props) => {
	const { imageContainerRect, sensorValues } = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	return (
		<Stage
			{...setBoxDimensions(imageContainerRect)}
			style={{
				maxWidth: '100%',
				position: 'absolute',
				inset: 0,
			}}
		>
			<Layer>
				{sensorValues?.map((value) => {
					return (
						<CircleLabel
							{...value}
							key={value.key}
							isFahrenheit={measurement === 'US'}
						/>
					);
				})}
			</Layer>
		</Stage>
	);
};

export default TemperatureHUD;
