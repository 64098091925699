import styles from './ProductVariantsListItem.module.scss';

const ProductVariantsListItem = (props) => {
	const { product, selection, choices } = props;

	const handleClick = (id) => {
		props.handleClick(parseInt(product.id, 10), parseInt(id, 10));
	};

	return (
		<div className={`${styles.root}`}>
			<span className={`${styles['product-name']}`}>
				{product.productName}
			</span>
			<div className={`${styles['variants-wrapper']}`}>
				{Object.keys(product.ProductVariant)
					.filter((id) => {
						return !choices.find((obj) => {
							const res =
								obj.productId === parseInt(product.id, 10) &&
								obj.variantId === parseInt(id, 10);
							return res;
						});
					})
					.map((id) => {
						const variant = product.ProductVariant[id];
						const isSelected =
							selection.productId === parseInt(product.id, 10) &&
							selection.variantId === parseInt(id, 10);
						return (
							<div
								className={`${styles['variant-tile']} ${
									isSelected ? styles.active : ''
								}`}
								key={id}
								onClick={() => {
									handleClick(id);
								}}
							>
								{variant.displayName}
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default ProductVariantsListItem;
