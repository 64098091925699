function InitalizeTable(props) {
	function chooseBorder() {
		if (props?.selectedComponent === props?.id) {
			return '1px solid orange';
		}
		if (props?.selectedComponent === props?.id) {
			return '1px solid red';
		}
		return null;
	}

	return (
		<div
			className='table-component'
			style={{
				width: props?.width,
				height: `${
					(((((props?.rows + 1) * (2 * props?.tableFontSize)) / 203) *
						25.4) /
						props?.labelDetails.width) *
					22
				}rem`,
				fontSize: props?.tableFontSize,
				border: chooseBorder(),
				overflow: 'auto',
			}}
		>
			<table
				style={{
					width: '100%',
					overflow: 'hidden',
				}}
			>
				<thead>
					<tr key='tabelHeading'>
						{props?.tableContent !== undefined &&
							props?.tableContent.length &&
							props?.tableContent[0].map((cellContent) => {
								return <th>{cellContent?.content}</th>;
							})}
					</tr>
				</thead>
				<tbody>
					{props?.tableContent !== undefined &&
						props?.tableContent.length &&
						props?.tableContent.slice(1).map((row) => {
							return (
								<tr>
									{row.map((cols) => {
										return (
											<td
												style={{
													backgroundColor:
														cols?.type === 'fixed'
															? 'aqua'
															: '#A8FF3F',
													overflow: 'auto',
												}}
											>
												{/* key={`cell#${rowIndex}#${colIndex}`} */}
												{cols?.content}
											</td>
										);
									})}
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

export default InitalizeTable;
