import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const LOService = {
	addLOInventory,
	listLOInventory,
	listLOInventoryItems,
	deleteLOInventory,
	getLeftOverInventoryCsv,
	listWastage,
	listWastageItems,
};

function addLOInventory(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(endpoints.ADD_LEFT_OVER, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			// clientId: 5,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function listLOInventory(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(endpoints.LIST_LEFT_OVER, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			// clientId: 5,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function listLOInventoryItems(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(endpoints.LIST_LEFT_OVER_ITEM, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			// clientId: 5,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function deleteLOInventory(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(endpoints.DELETE_LEFT_OVER_ITEM, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			// clientId: 5,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function getLeftOverInventoryCsv(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};

	return fetch(endpoints.LEFT_OVER_ITEM_EXPORT, requestOptions);
}

function listWastage(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(endpoints.LIST_WASTAGE, requestOptions).then(handleResponse);
}

function listWastageItems(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};

	return fetch(endpoints.LIST_WASTAGE_ITEM, requestOptions).then(handleResponse);
}

export default LOService;
