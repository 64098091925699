import Toggle from 'rsuite/Toggle';
import styles from './CustomToggleRadio.module.scss';

function CustomToggleRadio(props) {
	const { checked, isLoading, onChange } = props;
	return (
		<div className={styles.root}>
			<Toggle
				loading={isLoading}
				checked={checked}
				checkedChildren='Enabled'
				unCheckedChildren='Disabled'
				size='md'
				onChange={onChange}
			/>
		</div>
	);
}

export default CustomToggleRadio;
