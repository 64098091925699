import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const nutritionalServices = {
	gateway,
};

function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewProduct({
				req,
			});
		case 'LIST':
			return listProduct({
				req,
			});
		case 'UPDATE':
			return updateProduct({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function updateProduct({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.UPDATE_NUTRITIONAL_INFO, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function viewProduct({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.NUTRITIONAL_INFO, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listProduct({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ALL_PRODUCTS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default nutritionalServices;
