/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import INVDashboard from '../../../Pages/INVAnalysisDashboard/INVDashboard';
import PastINVAnalysisHistoryPage from '@/Pages/INVAnalysisDashboard/PastINVAnalysisHistoryPage';
import INVHistory from '@/Pages/INVAnalysisDashboard/INVHistory';

const getSubURL = (entity) => {
	const baseEntity = urls.INV_ANALYTICS;
	return entity.slice(baseEntity.length);
};
const INVAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route path={`${path}${getSubURL(urls.INV_ANALYSIS)}`} exact>
					<INVDashboard />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.INV_ANALYSIS_LIST)}`}
					exact
				>
					<PastINVAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.INV_ANALYSIS,
								name: 'Inventory Analysis',
							},
							{
								link: '',
								name: 'Inventory Analysis History List',
							},
						]}
					/>
				</Route>
				<Route
					path={`${path}${getSubURL(urls.INV_HISTORY_LIST)}`}
					exact
				>
					<INVHistory />
				</Route>
			</Switch>
		</div>
	);
};

export default INVAnalyticsRoutes;
