// eslint-disable-next-line prefer-template
const DynamicHeaderTable = (props) => {
	const {
		headerData,
		showUnit = false,
		tableDataElement = () => {},
		excludedCols = [],
		rowsData = [],
		headerAction = () => {},
		colors = ['#e5e7eb', '#94a3b8', '#facc15', '#008080', '#FF7F50', '#2dd4bf'],
		hideConstantCol = false,
	} = props;
	const stateMap = [];

	// const goToRoot = ()

	const calculateSpansAndColors = (dataToCal) => {
		const setRowSpan = (data, height) => {
			if (!data || !height) return;

			data.forEach((item) => {
				if (item.height < height) {
					if (!(item?.subCols?.length)) {
						// eslint-disable-next-line no-param-reassign
						item.rowSpan = height;
					} else {
						setRowSpan(item?.subCols, height - 1);
					}
				}
				if (item?.subCols?.length) {
					setRowSpan(item?.subCols, height - 1);
				}
			});
		};

		const traverse = (node, index) => {
			if (!node.subCols || node.subCols.length === 0) {
				stateMap.push(node);
				return {
					height: 1, width: 1,
				};
			}
			let height = 0;
			let width = 0;
			node?.subCols?.forEach((subCol) => {
				const { height: subHeight, width: subWidth } = traverse(subCol, index + 1);
				width += subWidth;
				// eslint-disable-next-line no-param-reassign
				subCol.height = subHeight;
				// eslint-disable-next-line no-param-reassign
				subCol.colSpan = subWidth;
				// eslint-disable-next-line no-param-reassign
				subCol.color = subCol.colName === 'Default' ? index - 1 : index;
				// eslint-disable-next-line no-param-reassign
				subCol.color = subCol.colName === 'Default' ? index - 1 : index;
				height = Math.max(height, subHeight);
			});
			node?.subCols?.forEach((subCol) => {
				if (subCol.height === 1 && height > 1) {
					// eslint-disable-next-line no-param-reassign
					subCol.rowSpan = height;
				}
				if (!subCol.subCols?.length) {
					// eslint-disable-next-line no-param-reassign
					subCol.rowSpan = height;
				}
			});
			height += 1;
			return {
				height, width,
			};
		};

		let maxHeight = 0;
		let first = true;
		dataToCal.forEach((item) => {
			const { height, width } = traverse(item, 2);
			maxHeight = Math.max(maxHeight, height);
			// eslint-disable-next-line no-param-reassign
			item.color = first ? 0 : 1;
			first = false;
			// eslint-disable-next-line no-param-reassign
			item.height = height;
			// eslint-disable-next-line no-param-reassign
			item.colSpan = width;
		});
		// for (const item of dataToCal) {
		// const { height, width } = traverse(item, 2);
		// maxHeight = Math.max(maxHeight, height);
		// item.color = first ? 0 : 1;
		// first = false;
		// item.height = height;
		// item.colSpan = width;
		// }

		dataToCal.forEach((item) => {
			if (item.subCols?.length === 0) {
				// eslint-disable-next-line no-param-reassign
				item.rowSpan = maxHeight;
			}
		});

		// for (const item of dataToCal) {
		// if (item.subCols?.length === 0) {
		// item.rowSpan = maxHeight;
		// }
		// }

		setRowSpan(dataToCal, maxHeight);
	};

	const generateRowHeaders = (data) => {
		calculateSpansAndColors(data);

		const nextCols = [data];
		const rows = [];
		while (nextCols.length > 0) {
			const items = nextCols.shift();
			let toPush = [];
			rows.push(
				<tr
					style={{
						height: 30,
					}}
				>
					{items.map((item) => {
						if (item?.subCols?.length > 0) {
							toPush = [...toPush, ...item?.subCols];
						}
						return (
							<th
								style={{
									backgroundColor: colors[item?.color],
									minWidth: item?.action ? 500 : 100,
									...item?.colStyle,
								}}
								className='border border-black'
								rowSpan={item?.rowSpan ?? 1}
								colSpan={item.colSpan ?? 1}
							>
								<div style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
								>
									<div style={{
										flexGrow: 1,
									}}
									>
										{item?.colName}
									</div>
									{item?.action && (
										<div>
											{headerAction(item?.actionId)}
										</div>
									)}
								</div>
							</th>
						);
					})}
				</tr>
			);

			if (toPush.length > 0) {
				nextCols.push(toPush);
			}
		}
		return rows;
	};

	const generateRows = (rowsDataToRow, stateMapToRow) => {
		const rows = [];

		rowsDataToRow?.forEach((row) => {
			const cells = [];
			if (!hideConstantCol) {
				// eslint-disable-next-line prefer-template
				cells.push(
					<td
						key={row?.variantId}
						style={{
							fontWeight: 600,
							textAlign: 'center',
						}}
						className='border-2'
					>
						<div style={{
							...row?.style,
						}}
						>
							{row?.includeRowValidity && (
								tableDataElement({
									rowItem: row, colId: 'root',
								})
							)}
							<div>
								{/* eslint-disable-next-line prefer-template */}
								{row?.name ?? row?.productName + ' (' + row?.variantName + ')'}
							</div>
						</div>
					</td>
				);
			}
			if (showUnit) {
				let unit = row?.unit;
				if (unit === 'gram') {
					unit = 'Kg';
				}
				if (unit === 'ml') {
					unit = 'ltr';
				}
				if (unit === 'oz') {
					unit = 'Lb';
				}
				if (unit === 'floz') {
					unit = 'Gal';
				}
				cells.push(
					<td
						style={{
							fontWeight: 600, textAlign: 'center',
						}}
					>
						{unit}
					</td>
				);
			}
			stateMapToRow.forEach((stateRow) => {
				if (
					!excludedCols.includes(stateRow.colName)
				) {
					cells.push(
						<td className='border-2'>
							{tableDataElement({
								colId: stateRow?.id,
								type: stateRow?.type,
								rowItem: row,
							})}
						</td>
					);
				}
			});
			rows.push(
				<tr
					key={row?.variantId}
					style={{
						height: 30,
					}}
				>
					{cells}
				</tr>
			);
		});
		return rows;
	};

	return (
		<table
			className='border-2'
			style={{
				minWidth: '100%',
			}}
		>
			<thead>
				{generateRowHeaders(headerData)}
			</thead>
			<tbody>
				{generateRows(rowsData, stateMap)}
			</tbody>
		</table>
	);
};

export default DynamicHeaderTable;
