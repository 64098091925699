/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { CreatePageHeader } from '../../../Components/Headers';
import { GeneralDetails } from '../../../Components/Forms/Defects';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { history, inputHelper, useForm } from '../../../Helpers';
import config from '../config';
import { AlertModal, ProductListModal } from '../../../Components/Modals';

const DefectsCreatePage = (props) => {
	const { isEdit, data, fetchDefect, saveDefect, breadcrumbs, toggleAlert } =
		props;

	const { id } = useParams();

	const fetchData = useCallback(() => {
		fetchDefect({
			entity: 'DEFECTS',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, [fetchDefect]);

	const {
		formState: state,
		setFormState: setState,
		validateAndDeserialize,
	} = useForm({
		config,
		isEdit,
		data,
		fetchData,
	});

	const { fields, errors } = state;

	const handleInput = async (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		const checkboxValue = e.target.value;
		const newState = {};
		if (fieldName === 'type') {
			setState({
				...state,
				fields: {
					...state.fields,
					type: {
						...state.fields.type,
						IDENTITY:
							checkboxValue.toUpperCase() === 'IDENTITY'
								? checkboxValue.toUpperCase()
								: null,
						COMPONENT:
							checkboxValue.toUpperCase() === 'COMPONENT'
								? checkboxValue.toUpperCase()
								: null,
						QUALITY:
							checkboxValue.toUpperCase() === 'QUALITY'
								? checkboxValue.toUpperCase()
								: null,
					},
				},
				...errors,
			});
		} else {
			setState({
				...state,
				fields: {
					...state.fields,
					[fieldName]: fieldValue,
					...newState,
				},
				...errors,
			});
		}
	};

	const handleImage = (fieldName, blob) => {
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: blob,
			},
			...errors,
		});
	};

	const addProducts = (selectedProducts) => {
		const newProducts = selectedProducts.map((product) => {
			return {
				id: null,
				productId: product.productId,
			};
		});
		setState({
			...state,
			fields: {
				...state.fields,
				products: [...state.fields.products, ...newProducts],
			},
		});
	};
	const setProductIs = (selectedProducts) => {
		const newProduct = selectedProducts?.[0];
		setState({
			...state,
			fields: {
				...state.fields,
				productId: newProduct?.productId,
			},
		});
	};

	const handleIsProductDelete = () => {
		setState({
			...state,
			fields: {
				...state.fields,
				productId: null,
			},
		});
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		const { id: productId } = dataset;

		setState({
			...state,
			fields: {
				...state.fields,
				products: state.fields.products.filter((item) => {
					return item.productId !== parseInt(productId, 10);
				}),
			},
		});
	};

	const handleSave = async (e) => {
		try {
			e.preventDefault();
			const req = await validateAndDeserialize();
			if (isEdit) {
				req.params = {
					id,
				};
				const isBase64Image = req.data.image?.includes(
					'data:image/jpeg;base64'
				);
				req.data.image = !isBase64Image ? null : req.data.image;
				saveDefect({
					entity: 'DEFECTS',
					req,
					method: 'UPDATE',
					redirect: `${urls.EXTENSION}${urls.DEFECTS}`,
				});
			} else {
				saveDefect({
					entity: 'DEFECTS',
					req,
					method: 'CREATE',
					redirect: `${urls.EXTENSION}${urls.DEFECTS}`,
				});
			}
		} catch (error) {
			//
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.DEFECTS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.DEFECTS}`);
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Combo' : 'Create Combo'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `View ${state?.fields?.name ?? ''}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader sectionName='General Details'>
					<GeneralDetails
						fields={fields}
						mandatoryFields={config.mandatoryFields}
						handleInput={handleInput}
						handleDelete={handleDelete}
						isEdit={isEdit}
						handleIsProductDelete={handleIsProductDelete}
						addProducts={addProducts}
						setProductIs={setProductIs}
						handleImage={handleImage}
					/>
				</FormSectionHeader>
			</form>
			<AlertModal
				handleConfirm={(args) => {
					toggleAlert(false);
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { VIEW: data } = state.form.DEFECTS.data;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchDefect: formActions.gateway,
	saveDefect: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectsCreatePage);
