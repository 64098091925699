import _ from 'lodash';

const approveRefundPageConfig = {
	initialState: {
		fields: {
			orders: [],
		},
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields.orders', () => {
			const newState = data.map((refund) => {
				const newRefund = {
					orderId: refund.orderId,
					initiatedAt: refund.initiatedAt,
					store: refund.store.storeId,
					invoiceValue: refund.invoiceValue,
					reason: refund.reason,
					recommendedRefundAmount: refund.recommendedRefundAmount,
					finalrefundAmount: refund.finalrefundAmount,
					refundId: refund.refundId,
				};
				return newRefund;
			});
			return newState;
		});
	},
};
export default approveRefundPageConfig;
