import { Button } from '@/Components/ui/button';

const DownloadTMC = (props) => {
	const { trademark } = props;
	return (
		<Button
			variant='outline'
			className='border-uy-color py-1'
			onClick={() => {
				window.open(trademark, 'blank');
			}}
		>
			VIEW TMC
		</Button>
	);
};
export default DownloadTMC;
