import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, get } from 'lodash';
import { Switch } from '@mui/material';
import { formActions } from '../../../Actions';
import { inputHelper, isEmpty } from '../../../Helpers';
import { CustomizationListTable } from '../../../Components/Forms/Customizations/CustomizationListTable';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Brand',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Veg',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [
	{
		title: 'Current State',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Enabled',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	Name: 'productName',
	Brand: 'brandName',
	'SKU Type': 'skuType',
	Category: 'productCategoryName',
	Veg: 'isVeg',
	Enabled: 'isCustomizationEnabled',
};

const initialState = [];

const CustomizationListPage = (props) => {
	const { fetchCustomizations, toggleCustomization, data, query, searchBy } =
		props;

	const [state, setState] = useState(initialState);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const handleEnable = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);

		const clonedState = cloneDeep(state);
		const foundIndex = data.findIndex((obj) => {
			return obj.id === parseInt(dataset.id, 10);
		});
		clonedState[foundIndex][fieldName] = fieldValue;
		toggleCustomization({
			entity: 'CUSTOMIZATIONS',
			req: {
				params: {
					productId: dataset.id,
				},
				data: {
					isCustomizationEnabled: fieldValue,
				},
			},
			method: 'PATCH',
			promisify: true,
		})
			.then((res) => {
				if (res.status === 'Success') {
					setNumberOfSubmissions(numberOfSubmissions + 1);
					setState(clonedState);
				}
			})
			.catch(() => {
				// eslint-disable-next-line no-console
			});
	};

	const SPECIAL_ITEMS = {
		Veg: (dataObj) => {
			const { isVeg } = dataObj;
			return (
				<div
					className={`dot-container small ${
						isVeg ? 'veg' : 'non-veg'
					}`}
				>
					<div className='dot' />
				</div>
			);
		},
		Enabled: (dataObj, alt) => {
			const { id, isCustomizationEnabled } = dataObj;
			const { isDisabled } = alt;
			return (
				<Switch
					name='isCustomizationEnabled'
					value='isEnabled'
					size='small'
					color='primary'
					onChange={handleEnable}
					checked={isCustomizationEnabled}
					disabled={isDisabled}
					disableRipple={isDisabled}
					inputProps={{
						'data-id': id,
						'data-type': 'CUSTOMIZATIONS',
					}}
				/>
			);
		},
		'Current State': (dataObj, alt) => {
			const { text } = alt;
			return <div>{text}</div>;
		},
	};

	useEffect(() => {
		fetchCustomizations({
			entity: 'CUSTOMIZATIONS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(data);
		}
	}, [data]);

	return (
		<div className='list-page-root'>
			<CustomizationListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={state.filter((customization) => {
					return (
						get(customization, COLMAP?.[searchBy?.title])
							?.toLowerCase()
							?.indexOf(query?.trim()?.toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.CUSTOMIZATIONS.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchCustomizations: formActions.gateway,
	toggleCustomization: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizationListPage);
