// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { urls } from '../../../Constants';
import { InventoryAnalysis, StockInReport } from '../../../Pages';
import { IMSSummary } from '../../../Pages/Analytics/Inventory Analytics/IMSSummary';
import IndentOrderList from '../../../Pages/Analytics/Inventory Analytics/IndentOrderList/IndentOrderList';
import PurchaseOrderList from '../../../Pages/Analytics/Inventory Analytics/PurchaseOrderList/PurchaseOrderList';
import SkuStockReportRoutes from '../Inventory Report Routes/InventoryReport.routes';

const getSubURL = (entity) => {
	const baseEntity = urls.INVENTORY_ANALYTICS;
	return entity.slice(baseEntity.length);
};

const InventoryAnalyticsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.STOCK_IN_REPORT)}`}>
				<StockInReport type='STOCK_IN' />
			</Route>
			<Route path={`${path}${getSubURL(urls.SKUS_INVENTORY_STOCK)}`}>
				<SkuStockReportRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.IMS_SUMMARY)}`}>
				<IMSSummary />
			</Route>
			<Route path={`${path}${getSubURL(urls.INVENTORY_ANALYSIS)}`}>
				<InventoryAnalysis />
			</Route>
			<Route path={`${path}${getSubURL(urls.PO_LIST)}`}>
				<PurchaseOrderList type='internalSales' />
			</Route>
			<Route path={`${path}${getSubURL(urls.EXTERNAL_SALES_ORDERS_LIST)}`}>
				<PurchaseOrderList type='externalSales' />
			</Route>
			<Route path={`${path}${getSubURL(urls.INDENT_LIST)}`}>
				<IndentOrderList />
			</Route>
			<Route path={`${path}${getSubURL(urls.STOCK_CONSUMPTION)}`}>
				<StockInReport type='STOCK_CONSUME' />
			</Route>
		</Switch>
	);
};

export default InventoryAnalyticsRoutes;
