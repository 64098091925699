import { IconInput } from '../../Inputs';
import styles from './NutritionalDetails.module.scss';

const NutritionalDetails = (props) => {
	const { mandatoryFields, handleInput, fields } = props;
	return (
		<div className={styles.root}>
			<div className={`${styles['nutritional-details']} form-field row`}>
				<label
					htmlFor='Protein'
					className='form-label'
					data-mandatory={mandatoryFields.protein}
				>
					Protein
				</label>
				<IconInput
					size='large'
					type='custom'
					iconText='gms'
					name='Protein'
					id='Protein'
					value={fields.Protein}
					handleInput={handleInput}
					dataType=''
					dataId=''
					required={mandatoryFields.protein}
					step='.01'
				/>
			</div>
			<div className={`${styles['nutritional-details']} form-field row`}>
				<label
					htmlFor='Carbs'
					className='form-label'
					data-mandatory={mandatoryFields.carb}
				>
					Carbs
				</label>
				<IconInput
					size='large'
					type='custom'
					iconText='gms'
					name='Carbs'
					id='Carbs'
					value={fields.Carbs}
					handleInput={handleInput}
					dataType=''
					dataId=''
					required={mandatoryFields.carb}
					step='.01'
				/>
			</div>
			<div className={`${styles['nutritional-details']} form-field row`}>
				<label
					htmlFor='Fat'
					className='form-label'
					data-mandatory={mandatoryFields.fat}
				>
					Fat
				</label>
				<IconInput
					size='large'
					type='custom'
					iconText='gms'
					name='Fat'
					id='Fat'
					value={fields.Fat}
					handleInput={handleInput}
					dataType=''
					dataId=''
					required={mandatoryFields.fat}
					step='.01'
				/>
			</div>
			<div className={`${styles['nutritional-details']} form-field row`}>
				<label
					htmlFor='Calories'
					className='form-label'
					data-mandatory={mandatoryFields.calories}
				>
					Calories
				</label>
				<IconInput
					size='large'
					type='custom'
					iconText='kcal'
					name='Calories'
					id='Calories'
					value={fields.Calories}
					handleInput={handleInput}
					dataType=''
					dataId=''
					required={mandatoryFields.calories}
					step='.01'
				/>
			</div>
			<div className={`${styles['nutritional-details']} form-field row`}>
				<label
					htmlFor='Calcium'
					className='form-label'
					data-mandatory={mandatoryFields.calcium}
				>
					Calcium
				</label>
				<IconInput
					size='large'
					type='custom'
					iconText='mg'
					name='Calcium'
					id='calcium'
					value={fields.Calcium}
					handleInput={handleInput}
					dataType=''
					dataId=''
					required={mandatoryFields.calcium}
					step='.01'
				/>
			</div>
		</div>
	);
};
NutritionalDetails.defaultProps = {};
export default NutritionalDetails;
