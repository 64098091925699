/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { formConstants } from '../Constants';
import { formServices, notificationServices } from '../Services';
import { history } from '../Helpers';
import authActions from './auth.actions';

const formActions = {
	gateway,
	updateFields,
};

const NOTIFICATION_EXCLUSIONS = ['LIST', 'VIEW'];
// const NOTIFICATION_EXCLUSIONS = ['VIEW'];
function deleteEntityItemFromList(dispatch, update, prevState, entity, req) {
	const tempState = _.cloneDeep(prevState);
	tempState[entity].data.LIST = tempState[entity].data.LIST.filter((item) => {
		return item.id !== req.params.id;
	});
	return tempState;
}

function gateway({
	entity,
	type,
	req,
	method,
	redirect = false,
	promisify = false,
	showNotification = false,
}) {
	// eslint-disable-next-line consistent-return
	return (dispatch, getState) => {
		const { permittedStores } = getState()?.navigation;
		let notificationId = null;
		if (
			NOTIFICATION_EXCLUSIONS.indexOf(method) === -1 ||
			showNotification
		) {
			notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'Loading...',
			});
		}
		dispatch(authActions?.toggleLoading(true));

		if (promisify) {
			return new Promise((resolve, reject) => {
				dispatch(request());
				formServices
					.gateway({
						entity,
						req,
						method,
						type,
					})
					.then(
						(res) => {
							const { message, data, responseMessage } = res;
							const prevState = _.cloneDeep(getState().form);
							if (
								NOTIFICATION_EXCLUSIONS.indexOf(method) ===
									-1 ||
								showNotification
							) {
								notificationServices.generateNotification({
									type: 'success',
									message: message ?? responseMessage,
									config: {
										id: notificationId,
									},
								});
							}
							if (method === 'DELETE') {
								const newState = deleteEntityItemFromList(
									dispatch,
									update,
									prevState,
									entity,
									req
								);
								dispatch(success(newState));
							} else {
								const newState = _.update(
									prevState,
									`[${entity}]`,
									(entityObj) => {
										return {
											data: {
												...entityObj.data,
												[method]: data,
											},
											isFetched: {
												...entityObj.isFetched,
												[method]: true,
											},
											error: '',
										};
									}
								);
								const entitySrevState = prevState?.[entity];
								const newStateTest = {
									[entity]: {
										data: {
											...entitySrevState.data,
											[method]: data,
										},
										isFetched: {
											...entitySrevState.isFetched,
											[method]: true,
										},
										error: '',
									},
								};
								dispatch(success(newStateTest));
							}

							if (redirect) {
								history.push(redirect);
							}
							resolve(res);
							dispatch(authActions?.toggleLoading(false));
						},
						(error) => {
							const prevState = _.cloneDeep(getState().form);
							if (
								NOTIFICATION_EXCLUSIONS.indexOf(method) ===
									-1 ||
								showNotification
							) {
								notificationServices.generateNotification({
									type: 'error',
									message: error,
									config: {
										id: notificationId,
									},
								});
							}
							const newState = _.update(
								prevState,
								`[${entity}]`,
								(entityObj) => {
									return {
										...entityObj,
										error,
									};
								}
							);
							dispatch(failure(newState));
							reject(error);
							dispatch(authActions?.toggleLoading(false));
						}
					);
			});
		}
		dispatch(request());
		formServices
			.gateway({
				entity,
				req,
				method,
				type,
			})
			.then(
				(res) => {
					dispatch(authActions?.toggleLoading(false));
					const { message, data } = res;
					const prevState = _.cloneDeep(getState().form);
					if (
						NOTIFICATION_EXCLUSIONS.indexOf(method) === -1 ||
						showNotification
					) {
						notificationServices.generateNotification({
							type: 'success',
							message,
							config: {
								id: notificationId,
							},
						});
					}
					if (method === 'DELETE') {
						const newState = deleteEntityItemFromList(
							dispatch,
							update,
							prevState,
							entity,
							req
						);
						dispatch(success(newState));
					} else {
						const newState = _.update(
							prevState,
							`[${entity}]`,
							(entityObj) => {
								let dataObj = {
									...entityObj.data,
									[method]: entity === 'PROCESS_TYPES' ? data?.nodes : data,
								};
								if (entity === 'FILTERED_STORES') {
									dataObj = {
										...entityObj.data,
										[method]: data?.filter((ele) => {
											if (!permittedStores?.length) return true;
											const permittedStore = permittedStores?.find((pStore) => {
												return pStore?.id === ele?.id;
											});
											return permittedStore;
										}),
									};
								}
								return {
									data: dataObj,
									isFetched: {
										...entityObj.isFetched,
										[method]: true,
									},
									error: '',
								};
							}
						);
						const entitySrevState = prevState?.[entity];
						let newStateTest = {
							[entity]: {
								data: {
									...entitySrevState.data,
									[method]: entity === 'PROCESS_TYPES' ? data?.nodes : data,
								},
								isFetched: {
									...entitySrevState.isFetched,
									[method]: true,
								},
								error: '',
							},
						};
						if (entity === 'FILTERED_STORES') {
							newStateTest = {
								[entity]: {
									data: {
										...entitySrevState?.data,
										[method]: data?.filter((ele) => {
											if (!permittedStores?.length) return true;
											const permittedStore =
												permittedStores?.find((pStore) => {
													return pStore?.id === ele?.id;
												});
											return permittedStore;
										}),
									},
									isFetched: {
										...entitySrevState?.isFetched,
										[method]: true,
									},
									error: '',
								},
							};
						}
						dispatch(success(newStateTest));
					}

					if (redirect) {
						history.push(redirect);
					}
					dispatch(authActions?.toggleLoading(false));
				},
				(error) => {
					const prevState = _.cloneDeep(getState().form);
					if (
						NOTIFICATION_EXCLUSIONS.indexOf(method) === -1 ||
						showNotification
					) {
						notificationServices.generateNotification({
							type: 'error',
							message: error?.message ?? error,
							config: {
								id: notificationId,
							},
						});
					}
					const newState = _.update(
						prevState,
						`[${entity}]`,
						(entityObj) => {
							return {
								...entityObj,
								error,
							};
						}
					);
					dispatch(failure(newState));
					dispatch(authActions?.toggleLoading(false));
				}
			);
	};

	function request(payload) {
		return {
			type: formConstants.FETCH_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: formConstants.FETCH_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: formConstants.FETCH_FAILURE,
			payload,
		};
	}
	function update(payload) {
		return {
			type: formConstants.UPDATE_LIST,
			payload,
		};
	}
}

/**
 *
 * @param {Object} DataObj object containing Entity,
 * Method and the Data to be updated with.
 * Index is optional if the method type is LIST
 * @returns
 */
function updateFields({ entity, method, innerField = null, index = -1, data }) {
	return (dispatch, getState) => {
		const newState = _.cloneDeep(getState().form);
		if (index === -1) {
			if (innerField === null) {
				newState[entity][method] = data;
			} else {
				newState[entity][innerField][method] = data;
			}
		} else {
			newState[entity][method][index] = data;
		}
		dispatch(update(newState));
	};

	function update(payload) {
		return {
			type: formConstants.UPDATE_FIELDS,
			payload,
		};
	}
}

export default formActions;
