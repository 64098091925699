import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = (props) => {
	const { breadcrumbs } = props;
	return (
		<div>
			<Breadcrumbs
				separator={<NavigateNextIcon fontSize='small' />}
				aria-label='breadcrumb'
			>
				{breadcrumbs.map((currentBreadcrumb) => {
					if (currentBreadcrumb.link) {
						return (
							<Link
								className={styles.link}
								to={currentBreadcrumb.link}
							>
								{currentBreadcrumb.name}
							</Link>
						);
					}
					return (
						<Typography key='3' color='text.primary' variant='h6'>
							{currentBreadcrumb.name}
						</Typography>
					);
				})}
			</Breadcrumbs>
		</div>
	);
};

export default Breadcrumb;
