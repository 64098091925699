import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Input from 'rsuite/Input';
import { Popover } from '@mui/material';
import styles from './AssetCapacity.module.scss';
import assetServices from '../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../Services';
import { formActions, modalActions } from '../../../Actions';
import { BackdropLoader } from '../../../Components/Loaders';
import { classes } from '../../../Helpers';
import DynamicHeaderTable from '../../../Components/DynamicHeaderTable/DynamicHeaderTable';
import assetActions from '../../../Actions/asset.actions';

function AssetCapacity(props) {
	const {
		productCategoriesList,
		fetchProductCatergoriesList,
		fetchAssetTypeList,
		assetList,
	} = props;
	// const [assetList, setAssetList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [categoryQuery, setCategoryQuery] = useState('');
	const [state, setState] = useState({
		capacity: {},
	});
	const [headers, setHeaders] = useState([
		{
			colName: 'Asset Type',
			subCols: [],
		},
		{
			colName: 'Capacity (In Kg)',
			action: true,
			subCols: [
				{
					colName: 'Default',
					id: 'default',
					subCols: [],
				},
			],
		},
	]);
	const open = Boolean(anchorEl);

	const fetchCapacities = async () => {
		setIsLoading(true);

		try {
			const response = await assetServices.fetchCapacities({
				data: {
					types: ['MOVABLE'],
				},
			});
			if (response?.statusCode === 200) {
				setState((prev) => {
					return {
						...prev,
						capacity: response?.data?.capacity,
					};
				});
				const newHeaders = [
					{
						colName: 'Asset Type',
						subCols: [],
					},
					{
						colName: 'Capacity (In Kg)',
						action: true,
						subCols: [
							{
								colName: 'Default',
								id: 'default',
								subCols: [],
							},
							...(response?.data?.additionalCategories ?? []),
						],
					},
				];
				setHeaders(newHeaders);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchProductCatergoriesList({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchAssetTypeList({
			req: {
				data: {
					types: ['MOVABLE'],
				},
			},
		});
		fetchCapacities();
	}, []);

	const updateValue = (categoryId, assetTypeId, value) => {
		const prevObj = {
			...state?.capacity?.[assetTypeId],
			[categoryId]: value,
		};
		const setNewObj = {
			...state?.capacity,
			[assetTypeId]: prevObj,
		};
		setState({
			capacity: setNewObj,
		});
	};

	const EditableInput = (args) => {
		const { colId, rowItem } = args;
		return (
			<div className={classes(styles['item-center'])}>
				<input
					className={classes(styles['custom-input'])}
					type='number'
					min={0}
					// eslint-disable-next-line prefer-template
					value={state?.capacity?.[rowItem?.id]?.[colId]}
					onChange={(e) => {
						const inputValue = e?.target?.value;
						const isPositiveDecimal = /^[0-9]\d*(\.\d+)?$/.test(
							inputValue
						);
						if (inputValue === '' || isPositiveDecimal) {
							updateValue(colId, rowItem?.id, inputValue);
						}
					}}
					placeholder='Capacity (in Kg)'
				/>
			</div>
		);
	};

	const handleBtnClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const headerActionHelper = (item) => {
		const newHeaderToSet = {
			colName: item?.productCategoryName,
			id: item?.id,
			subcols: [],
		};
		setHeaders((prevHeader) => {
			const newHeader = [...prevHeader];
			const newCols = {
				...newHeader[1],
			};
			const newSubCols = [...newCols.subCols];
			newSubCols.splice(newSubCols.length, 0, newHeaderToSet);
			newCols.subCols = newSubCols;
			newHeader[1] = newCols;
			return newHeader;
		});
	};

	const handleFilterCategories = (value) => {
		setCategoryQuery(value);
	};

	const headerAction = () => {
		return (
			<div className={classes(styles['label-container'])}>
				<button
					aria-describedby='action'
					type='button'
					variant='contained'
					onClick={handleBtnClick}
					className={classes(styles.btn)}
				>
					+ Add new Category
				</button>
				<Popover
					id='action'
					open={open}
					anchorEl={anchorEl}
					onClose={() => {
						setAnchorEl(null);
					}}
					style={{
						height: '250px',
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					className={styles.popover}
				>
					<div className={styles.categories_list}>
						<Input placeholder='Search' onChange={handleFilterCategories} value={categoryQuery} />
						<div className={styles.items_list}>
							{productCategoriesList?.filter((ele) => {
								const headersCols = headers?.[1]?.subCols?.find((colsEle) => {
									return colsEle?.id === ele?.id;
								});
								return !headersCols &&
									ele?.productCategoryName?.toLowerCase().includes(categoryQuery.toLowerCase());
							})?.map((catItem) => {
								return (
									<button
										type='button'
										className={classes(styles.list)}
										onClick={() => {
											setAnchorEl(
												null
											);
											headerActionHelper(
												catItem
											);
										}}
									>
										{catItem?.productCategoryName}
									</button>
								);
							})}
						</div>
					</div>
				</Popover>
			</div>
		);
	};

	const rowsData = assetList?.map((ele) => {
		return {
			id: ele?.id,
			name: ele?.name,
		};
	});

	const handleSave = async () => {
		setIsLoading(true);
		try {
			const response = await assetServices.saveCapacities({
				data: {
					capacity: state?.capacity,
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} showProgress />
			<div className={styles.header}>
				<p className={styles.heading}>Asset Packaging Capacity</p>
				<div className={styles.btns_container}>
					<button
						type='button'
						onClick={handleSave}
						className={classes(styles.save_btn, styles.btn)}
					>
						Save
					</button>
				</div>
			</div>
			<DynamicHeaderTable
				headerData={headers}
				tableDataElement={EditableInput}
				excludedCols={['Asset Type']}
				rowsData={[...rowsData]}
				headerAction={headerAction}
				colors={['#e5e7eb', '#83c5be', '#83c5be', '#83c5be']}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const assetList = state.assets.list;
	const { userPermissions } = state.navigation;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;
	return {
		userPermissions,
		productCategoriesList,
		assetList,
	};
};

const mapDispatchToProps = {
	fetchProductCatergoriesList: formActions.gateway,
	toggleImageModal: modalActions.toggleImageModal,
	fetchAssetTypeList: assetActions.fetchAssetList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetCapacity);
