import { endpoints } from '../Constants';
import { authHeader } from '../Helpers';
import inventoryAnalyticsServices from './Analytics Services/inventory-analytics.services';
import qualityAnalyticsServices from './Analytics Services/qualityAnalytics.services';
import cookingAnalyticsServices from './Analytics Services/cookingAnalytics.services';
import orderAnalyticsServices from './Analytics Services/orderAnalytics.services';
import handleResponse from './helpers';
import fetchStatisticalAnalysisGraph from './Analytics Services/statisticalAnalysisServices';
import defectAnalyticsServices from './Analytics Services/defect-analytics.services';
import brandAnalysisServices from './Analytics Services/brand-analysis.services';
import productAnalyticsServices from './Analytics Services/product-analytics.services';
import outwardingAnalyticsServices from './Analytics Services/outwarding-analytics.services';
import wastageAnalyticsServices from './Analytics Services/wastage-analytics.services';
import subscriptionsAnalyticsServices from './Analytics Services/subscriptions-analytics.services';
import commonAnalyticsServices from './Analytics Services/common-analytics.services';
import containerAnalyticsServices from './Analytics Services/container-analytics.services';
import temperatureAnalyticsServices from './Analytics Services/temperature-analytics.services';
import productionPlanServices from './Analytics Services/production-plan.services';
import cogsAnalyticsServices from './Analytics Services/cogsAnalytics.services';
import invAnalyticsServices from './Analytics Services/invAnalytics.services';
import auditAnalyticsServices from './Analytics Services/auditAnalytics.services';
import forecastingAnalyticsServices from './Analytics Services/forecastingAnalytics.services';
import transitAnalyticsServices from './Analytics Services/transitAnalytics.services';

const analyticsServices = {
	gateway,
	exportFmsOrders,
};

function gateway({ entity, req, method }) {
	switch (entity) {
		case 'STORE_ANALYSIS':
			return fetchStoreAnalysis({
				req,
			});
		case 'INVENTORY_ANALYTICS':
			return inventoryAnalyticsServices.gateway({
				req,
				method,
			});
		case 'QUALITY_ANALYTICS':
			return qualityAnalyticsServices.gateway({
				req,
				method,
			});
		case 'COGS_ANALYTICS':
			return cogsAnalyticsServices.gateway({
				req,
				method,
			});
		case 'BOM_ANALYTICS':
			return cogsAnalyticsServices.gateway({
				req,
				method,
			});
		case 'AUDIT_ANALYTICS':
			return auditAnalyticsServices.gateway({
				req,
				method,
			});
		case 'TRANSIT_ANALYTICS':
			return transitAnalyticsServices.gateway({
				req,
				method,
			});
		case 'INV_ANALYTICS':
			return invAnalyticsServices.gateway({
				req,
				method,
			});
		case 'FORECASTING_ANALYTICS':
			return forecastingAnalyticsServices.gateway({
				req,
				method,
			});
		case 'COOKING_ANALYTICS':
			return cookingAnalyticsServices.gateway({
				req,
				method,
			});
		case 'ORDER_HISTORY':
			return orderAnalyticsServices.gateway({
				req,
				method,
			});
		case 'WASTAGE_HISTORY':
			return wastageAnalyticsServices.gateway({
				req,
				method,
			});
		case 'WASTAGE_ANALYSIS':
			return wastageAnalyticsServices.gateway({
				req,
				method,
			});
		case 'AI_OUTWARDING_ANALYSIS':
		case 'CRATE_ANALYSIS':
		case 'AI_GRN_ANALYSIS':
		case 'AI_PRODUCTION_ANALYSIS':
		case 'AI_DISPATCH_ANALYSIS':
		case 'AI_LEFT_OVER_INVENTORY_ANALYSIS':
			return commonAnalyticsServices.gateway({
				req,
				method,
			});
		case 'CONTAINER_ANALYSIS':
			return containerAnalyticsServices.gateway({
				req,
				method,
			});
		case 'TEMPERATURE_ANALYSIS':
			return temperatureAnalyticsServices.gateway({
				req,
				method,
			});
		case 'QC_STATISTICAL_ANALYSIS':
			return fetchStatisticalAnalysisGraph({
				req,
				method,
			});
		case 'DEFECT_ANALYSIS':
			return defectAnalyticsServices.gateway({
				req,
				method,
			});
		case 'WASTAGE_COMPONENTS':
			return defectAnalyticsServices.gateway({
				req,
				method,
			});
		case 'PRODUCT_ANALYSIS':
			return productAnalyticsServices.gateway({
				req,
				method,
			});
		case 'OUTWARDING_ANALYSIS':
			return outwardingAnalyticsServices.gateway({
				req,
				method,
			});
		case 'SUBSCRIPTIONS_HISTORY':
			return subscriptionsAnalyticsServices.gateway({
				req,
				method,
			});
		case 'BRAND_ANALYSIS':
			return brandAnalysisServices.gateway({
				req,
				method,
			});
		case 'PRODUCTION_PLANS':
			return productionPlanServices.gateway({
				req,
				method,
			});
		default:
			break;
	}
	return new Promise(() => {});
}

function fetchStoreAnalysis({
	req = {
		data: {},
	},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.ANALYTICS, requestOptions).then(handleResponse);
}

function exportFmsOrders({
	req = {
		data: {},
	},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.PO_LIST, requestOptions);
}
export default analyticsServices;
