import Input from 'rsuite/Input';

export const billsTableColumns = [
	{
		title: 'Client Bill. id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Billing Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'GRN Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Due Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Balance Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Available Balance',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Pay Bill',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Used Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Paid Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Use Amount',
		config: {
			type: 'DATA',
		},
	},
];

export const billsListingColmap = {
	Vendor: 'vendorName',
	'Billing Store': 'storeName',
	'Order id': 'clientOrderId',
	'GRN Id': 'parentRowId',
	'Client Bill. id': 'clientTransactionId',
	'Due Date': 'dueDate',
	'Bill Type': 'id',
	'Bill date': 'transactionDate',
	'Bill Amount': 'finalTransactionAmount',
	'Balance Amount': 'id',
	'Available Balance': 'id',
	'Pay Bill': 'id',
	'Paid Amount': 'txnUsedAmount',
	'Used Amount': 'txnUsedAmount',
	'Use Amount': 'id',
};
export const debitNoteTableColumns = [
	{
		title: 'Client Debit id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'GRN Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Debit date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Debit Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Available Balance',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Used Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Use Amount',
		config: {
			type: 'DATA',
		},
	},
];

export const debitNoteListingColmap = {
	'Order id': 'clientOrderId',
	'GRN Id': 'parentRowId',
	'Client Debit id': 'clientTransactionId',
	'Debit date': 'transactionDate',
	'Debit Amount': 'finalTransactionAmount',
	'Available Balance': 'id',
	'Used Amount': 'txnUsedAmount',
	'Use Amount': 'id',
};

export const generateUseAmountInput = ({
	inputItem,
	valueKey,
	id,
	keyToMatch,
	placeholder,
	totalAmount,
	setState,
	styles,
	showUseFull,
	// maxInpVal = null,
	isDisabled = false,
}) => {
	return (
		<div className={styles.use_amount}>
			{showUseFull && (
				<button
					type='button'
					onClick={() => {
						setState((prev) => {
							if (!inputItem) {
								return {
									...prev,
									[valueKey]: [
										...prev?.[valueKey],
										{
											[keyToMatch]: id,
											debitAmount: totalAmount,
										},
									],
								};
							}
							return {
								...prev,
								[valueKey]: prev?.[valueKey]?.map((ele) => {
									if (
										inputItem?.[keyToMatch] ===
										ele?.[keyToMatch]
									) {
										return {
											...ele,
											debitAmount: totalAmount,
										};
									}
									return ele;
								}),
							};
						});
					}}
				>
					Use Full
				</button>
			)}
			<Input
				style={{
					width: 100,
				}}
				value={inputItem?.debitAmount}
				type='number'
				// max={maxInpVal}
				disabled={isDisabled}
				onChange={(value) => {
					setState((prev) => {
						if (!inputItem) {
							return {
								...prev,
								[valueKey]: [
									...prev?.[valueKey],
									{
										[keyToMatch]: id,
										debitAmount: value,
									},
								],
							};
						}
						return {
							...prev,
							[valueKey]: prev?.[valueKey]?.map((ele) => {
								if (
									inputItem?.[keyToMatch] ===
									ele?.[keyToMatch]
								) {
									return {
										...ele,
										debitAmount: value,
									};
								}
								return ele;
							}),
						};
					});
					// }
				}}
				placeholder={placeholder}
			/>
		</div>
	);
};
