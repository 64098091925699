/* eslint-disable no-unused-vars */
import { MenuItem, TextField } from '@mui/material';
import {
	useState, useEffect, useCallback
} from 'react';
// import DatePicker from 'rsuite/DatePicker';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// eslint-disable-next-line no-unused-vars
import { CheckPicker } from 'rsuite';
import { classes, isTimeLessThanCurrentTime } from '../../Helpers';
import storeServices from '../../Services/Form Services/store.services';
import CustomInputSelector from '../CustomInputSelector/CustomInputSelector';
import DatePickerCustom from '../DatePickerCustom/DatePickerCustom';
import IconInput from '../Forms/Inputs/IconInput';
import styles from './POGeneralDetails.module.scss';
import GSTInComp from './GSTInComp/GSTInComp';
import POVendorDetails from './VendorDetails/POVendorDetails';
import { notificationServices } from '../../Services';
import {
	Tabs, TabsList, TabsTrigger
} from '../ui/tabs';
import { urls } from '@/Constants';
import { Button } from '../ui/button';

export const paymentTerms = [
	'Net 15',
	'Net 30',
	'Net 45',
	'Net 60',
	'Due end of the month',
	'Due end of next month',
	'Due on receipt',
];

function POGeneralDetails(props) {
	const {
		dispatchDelta,
		filteredStoresList,
		vendors,
		state,
		setState,
		toggleAddVendorModal,
		requiredFields,
		shiftsList,
		processList,
		disableMsg,
		isDeliverToDisabled,
		orderType,
		filterLabels = {},
		mandatoryFields = {},
		thisStore = false,
		disbalePaymentTerm = false,
		uploadInvoiceCick,
		doc,
		// isEdit = false,
		// queryUserOrderType,
		type,
	} = props;
	const {
		vendor: selectedVendor,
		vendorLocation: selectedVendorLocation,
		deliverTo,
		purchaseOrderId,
		date,
		expectedDeliveryDate,
		paymentTerm,
		fromStore,
		store,
		selectedProcess,
		toProcess,
		selectedShift,
		fromProcess,
		inventoryAtDayEndOf,
		transferOrderType,
	} = state ?? {};
	const history = useHistory();
	const [allStores, setAllStores] = useState([]);
	const [formattedDate, setFormattedDate] = useState(null);
	useEffect(() => {
		if (dispatchDelta) {
			const dispatchDate = new Date(expectedDeliveryDate);
			dispatchDate.setDate(expectedDeliveryDate.getDate() - dispatchDelta);
			const formatedDate = dispatchDate.toISOString().split('T')[0];
			setFormattedDate(formatedDate);
		}
	}, [expectedDeliveryDate, dispatchDelta]);

	const fetchAllStores = async () => {
		const req = {
			id: '',
			type: 'BASE,WAREHOUSE_PRODUCT,WAREHOUSE,WAREHOUSE_INGREDIENT,RETAIL',
		};
		try {
			const response = await storeServices.getStore(req);
			if (response?.statusCode === 200) {
				setAllStores(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchAllStores();
	}, []);

	const selectVendorLocation = (currVendorLocation) => {
		setState('SET_VENDOR_LOCATION', currVendorLocation);
	};

	const handleFromStoreSelection = (store_) => {
		// if (orderType !== 3) {
		if (store_?.id && state?.deliverTo?.id) {
			if (store_?.id !== state?.deliverTo?.id) {
				setState('SET_FROM_STORE', store_);
			} else {
				notificationServices.generateNotification({
					type: 'warning',
					message: 'From and To Store cannot be same',
				});
			}
		} else {
			setState('SET_FROM_STORE', store_);
		}
		// } else {
		// setState('SET_FROM_STORE', store_);
		// }
	};

	const handleAddVendor = () => {
		toggleAddVendorModal(true, {});
	};

	const handleDeliverToSelect = (deliverToStore) => {
		// if (orderType !== 3) {
		if (deliverToStore?.id && state?.fromStore?.id) {
			if (deliverToStore?.id !== state?.fromStore?.id) {
				setState('SET_DELIVER_TO', deliverToStore);
			} else {
				notificationServices.generateNotification({
					type: 'warning',
					message: 'From and To Store cannot be same',
				});
			}
		} else {
			setState('SET_DELIVER_TO', deliverToStore);
		}
		// } else {
		// setState('SET_DELIVER_TO', deliverToStore);
		// }
	};

	const handleSelectProcess = (currProcess) => {
		setState('SET_PROCESS', currProcess);
	};
	const handleStoreSelection = (store_) => {
		setState('SET_STORE', store_);
		setState('SET_FROM_PROCESS', null);
		setState('SET_TO_PROCESS', null);
	};
	const handleTransferOrderType = (value) => {
		setState('SET_TRANSFER_ORDER_TYPE', value);
	};
	const handleSelectFromProcess = (currProcess) => {
		setState('SET_FROM_PROCESS', currProcess);
	};
	const handleSelectToProcess = (currProcess) => {
		setState('SET_TO_PROCESS', currProcess);
		setState('SET_SHIFT', null);
	};

	const handleSelectToShift = (currProcess) => {
		setState('SET_SHIFT', currProcess);
	};
	// const disabledDate = (rsuiteDate) => {
	// return rsuiteDate < new Date().setDate(new Date().getDate() - 1);
	// };
	const disabledExpiryDate = (rsuiteDate) => {
		const previousDate = new Date(date);
		previousDate.setDate(previousDate.getDate() - 1);

		return rsuiteDate < previousDate;
	};
	const disabledYesterday = (rsuiteDate) => {
		const currentDateAtMidnight = moment().startOf('day');
		return rsuiteDate < currentDateAtMidnight;
	};

	const handleKeyPress = (e) => {
		const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			setState(`SET_${key}`, {});
			if (key === 'VENDOR') {
				setState('SET_VENDOR_LOCATION', {});
			}
		}
	};

	const handleVendorSelection = (vendor) => {
		setState('SET_VENDOR', vendor);
		if (vendor?.vendor_locations?.length) {
			if (vendor?.vendor_locations?.length === 1) {
				setState('SET_VENDOR_LOCATION', vendor?.vendor_locations?.[0]);
			} else {
				setState('SET_VENDOR_LOCATION', {});
			}
		} else {
			setState('SET_VENDOR_LOCATION', {});
		}
	};

	const filterShifts = (ele) => {
		if (moment(date).isSame(moment(), 'day') && moment(ele?.endDate).isSameOrAfter()) {
			const startDate = moment(ele?.startDate, 'YYYY-MM-DD');
			const endDate = moment(ele?.endDate, 'YYYY-MM-DD');
			const currentTime = moment().startOf('day');
			// console.log('endDateTime', endDateTime);
			if (ele?.process?.[0]?.endTime === '12:00 AM') return true;
			return currentTime.isSameOrAfter(startDate) && currentTime.isSameOrBefore(endDate);
		}
		return true;
	};

	const onLocationAddComplete = useCallback(() => {
		// selectVendorLocation({});
		const tempVendor = vendors?.find((ele) => {
			return ele?.id === state?.vendor?.id;
		});
		handleVendorSelection(tempVendor);
	}, [vendors]);

	useEffect(onLocationAddComplete, [vendors]);

	const STORES_LIST = useCallback(
		(wantFilter) => {
			if (wantFilter) {
				return filteredStoresList?.map((ele) => {
					return {
						...ele,
						imageUrl: ele?.logo,
					};
				});
			}
			return allStores?.map((ele) => {
				return {
					...ele,
					imageUrl: ele?.logo,
				};
			});
		},
		[allStores, filteredStoresList]
	);

	return (
		<div
			className={styles.root}
			style={{
				position: 'relative',
			}}
		>
			{disableMsg && (
				<div className='w-full h-3/5 flex items-center justify-center scale-105 bg-opacity-50 cursor-not-allowed bg-white absolute z-10 top-0 left-0'>
					<p className='text-red-800 text-xl text-center font-bold rotate-12'>{disableMsg}</p>
				</div>
			)}
			{state?.rejectionReason && (
				<div className={styles.rejected}>
					<p>This PO was rejected</p>
					<p className={styles.reason}>
						(Reason:
						{state?.rejectionReason}
						)
					</p>
				</div>
			)}
			{orderType !== 7 && (
				<>
					{(requiredFields?.vendor || type === 'BUYER') && (
						<div className={styles['label-container']}>
							<label
								className={styles.mandatory}
								htmlFor='vendorSelection'
							>
								{type === 'BUYER' ? 'Buyer Name*' : 'Vendor Name*'}
							</label>
							<CustomInputSelector
								inputName='VENDOR'
								width='300px'
								inputLists={vendors
									?.filter((vendor) => {
										if (orderType === 4) {
											return vendor.vendorType === 'CAPEX';
										}
										if (orderType === 1) {
											return vendor.vendorType === 'INDENT';
										}
										return true;
									})
									?.map((ele) => {
										return {
											...ele,
											imageUrl: ele?.logo,
										};
									})}
								// eslint-disable-next-line no-unused-vars
								handleRemove={(key) => {
									handleVendorSelection(null);
									selectVendorLocation({});
								}}
								handleKeyPress={handleKeyPress}
								handleSelection={handleVendorSelection}
								addNewText={type === 'BUYER' ? 'Add New Buyer' : 'Add New Vendor'}
								placeholder={type === 'BUYER' ? 'Select A Buyer' : 'Select A Vendor'}
								selectedValue={selectedVendor?.name}
								classname={styles['vendor-selector']}
								handleAddNew={handleAddVendor}
							/>
						</div>
					)}
					{(requiredFields?.vendorLocation) && (
						<div className={classes(styles['vendor-container'])}>
							<div className={styles['label-container']}>
								<label
									className={
										mandatoryFields?.vendorLocation
											? styles.mandatory
											: ''
									}
									htmlFor='vendorSelection'
								>
									Vendor location
									{mandatoryFields?.vendorLocation ? '*' : ''}
								</label>
								<CustomInputSelector
									inputName='VENDOR_LOCATION'
									width='300px'
									disabled={!state?.vendor?.id}
									inputLists={state?.vendor?.vendor_locations?.map(
										(ele) => {
											return {
												...ele,
												imageUrl: ele?.logo,
											};
										}
									)}
									// eslint-disable-next-line no-unused-vars
									handleRemove={(key) => {
										selectVendorLocation(null);
									}}
									handleKeyPress={handleKeyPress}
									handleSelection={selectVendorLocation}
									addNewText='Add New Vendor Location'
									placeholder='Select Vendor Location'
									selectedValue={state?.vendorLocation?.name}
									classname={styles['vendor-selector']}
									handleAddNew={() => {
										toggleAddVendorModal(true, {
											vendorData: state?.vendor,
											isEdit: true,
											onLocationAddComplete,
										});
									}}
								/>
							</div>
							{selectedVendor?.id && selectedVendorLocation?.id && (
								<POVendorDetails
									toggleAddVendorModal={toggleAddVendorModal}
									vendorDetails={{
										...selectedVendorLocation,
										vendorType: selectedVendor?.vendorType,
									}}
								/>
							)}
						</div>
					)}
				</>
			)}
			{(requiredFields?.buyerLocation || type === 'BUYER') && (
				<div className={classes(styles['vendor-container'])}>
					<div className={styles['label-container']}>
						<label
							className={
								mandatoryFields?.vendorLocation
									? styles.mandatory
									: ''
							}
							htmlFor='vendorSelection'
						>
							Buyer location
							{mandatoryFields?.buyerLocation ? '*' : ''}
						</label>
						<CustomInputSelector
							inputName='VENDOR_LOCATION'
							width='300px'
							disabled={!state?.vendor?.id}
							inputLists={state?.vendor?.vendor_locations?.map(
								(ele) => {
									return {
										...ele,
										imageUrl: ele?.logo,
									};
								}
							)}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								selectVendorLocation(null);
							}}
							handleKeyPress={handleKeyPress}
							handleSelection={selectVendorLocation}
							addNewText='Add New Buyer Location'
							placeholder='Select Buyer Location'
							selectedValue={state?.vendorLocation?.name}
							classname={styles['vendor-selector']}
							handleAddNew={() => {
								toggleAddVendorModal(true, {
									vendorData: state?.vendor,
									isEdit: true,
									onLocationAddComplete,
								});
							}}
						/>
					</div>
					{selectedVendor?.id && selectedVendorLocation?.id && (
						<POVendorDetails
							toggleAddVendorModal={toggleAddVendorModal}
							vendorDetails={{
								...selectedVendorLocation,
								vendorType: selectedVendor?.vendorType,
							}}
						/>
					)}
				</div>
			)}
			{requiredFields?.fromStore && (
				<div className={classes(styles['vendor-container'])}>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							htmlFor='vendorSelection'
						>
							Purchase From Store*
						</label>
						<CustomInputSelector
							inputName='FROM_STORE'
							width='300px'
							inputLists={STORES_LIST(false)}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								handleFromStoreSelection(null);
							}}
							handleKeyPress={handleKeyPress}
							handleSelection={handleFromStoreSelection}
							hideAddNew
							placeholder='Select From Store'
							selectedValue={fromStore?.name}
							classname={styles['vendor-selector']}
						/>
					</div>
				</div>
			)}
			{requiredFields?.store && (
				<div className={classes(styles['vendor-container'])}>
					<div className={styles['label-container']}>
						<label className={styles.mandatory} htmlFor='store'>
							Select Store*
						</label>
						<CustomInputSelector
							inputName='store'
							width='300px'
							inputLists={STORES_LIST(false)}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								handleStoreSelection(null);
							}}
							handleSelection={handleStoreSelection}
							hideAddNew
							placeholder='Select Store'
							selectedValue={store?.name}
							classname={styles['vendor-selector']}
						/>
					</div>
				</div>
			)}
			{(requiredFields?.deliverTo ||
				requiredFields?.filteredDeliverTo) && (
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='deliverTo'>
						{filterLabels?.store ?? 'Deliver To*'}
					</label>
					<div className={styles['deliver-to']}>
						<CustomInputSelector
							inputName='DELIVER_TO'
							width='300px'
							inputLists={STORES_LIST(false)}
							disabled={isDeliverToDisabled}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								handleDeliverToSelect(null);
							}}
							handleKeyPress={handleKeyPress}
							handleSelection={handleDeliverToSelect}
							hideAddNew
							placeholder={
								thisStore ? 'Select store' : 'Select Deliver to'
							}
							selectedValue={deliverTo?.name}
							classname={styles['vendor-selector']}
						/>
						{deliverTo?.id && (
							<GSTInComp
								gstIn={deliverTo?.gstNumber}
								onClick={() => {
									history.push(
										`${urls.EXTENSION}${urls.STORES}/edit/${deliverTo?.id}`
									);
								}}
							/>
						)}
					</div>
				</div>
			)}
			{requiredFields?.transferOrderType && (
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='deliverTo'>
						{filterLabels?.store ?? 'Deliver To*'}
					</label>
					<Tabs
						value={transferOrderType}
						onValueChange={handleTransferOrderType}
					>
						<TabsList className=''>
							{['PROCURE', 'DISPATCH']?.map((ele) => {
								return (
									<TabsTrigger
										className='data-[state=active]:bg-uy-color'
										value={ele}
									>
										{ele}
									</TabsTrigger>
								);
							})}
						</TabsList>
					</Tabs>
				</div>
			)}
			{requiredFields?.fromProcess && requiredFields?.toProcess && (
				<div className={styles['vertical-container']}>
					<>
						<div className={styles.note}>
							Note: Shifts listed on the basis of
							{' '}
							<span>
								{state?.transferOrderType !== 'DISPATCH'
									? 'Transfer To Process'
									: 'Transfer From Process'}
							</span>
							, and
							{' '}
							<span>Transfer Date</span>
						</div>
						<div className={classes(styles['label-container'])}>
							<label
								className={
									mandatoryFields?.fromProcess
										? styles.mandatory
										: ''
								}
								htmlFor='fromProcess'
							>
								Transfer From Process
								{mandatoryFields?.fromProcess ? '*' : ''}
							</label>
							<CustomInputSelector
								inputName='From Process'
								width='300px'
								disabled={
									!processList?.length || !state?.store?.id
								}
								inputLists={processList
									?.filter((ele) => {
										if (
											state?.transferOrderType ===
											'DISPATCH'
										) {
											return (
												ele?.data?.type !== 'STORAGE' &&
												!ele?.data?.nodeStore
											);
										}
										return ele?.data?.type === 'STORAGE';
									})
									?.map((ele) => {
										return {
											...ele,
											imageUrl: ele?.logo,
											name: `${
												ele?.data?.heading
											} (Section: ${
												ele?.data?.sectionName ?? '-'
											})`,
										};
									})}
								// eslint-disable-next-line no-unused-vars
								handleRemove={(key) => {
									handleSelectToProcess(null);
								}}
								handleSelection={handleSelectToProcess}
								hideAddNew
								placeholder='Select from process'
								selectedValue={toProcess?.name}
								classname={styles['vendor-selector']}
							/>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<ArrowDownwardIcon />
						</div>
					</>
					<div className={classes(styles['label-container'])}>
						<label
							className={
								mandatoryFields?.toProcess
									? styles.mandatory
									: ''
							}
							htmlFor='toProcess'
						>
							Transfer To Process
							{mandatoryFields?.toProcess ? '*' : ''}
						</label>
						<CustomInputSelector
							inputName='To Process'
							width='300px'
							disabled={!processList?.length || !state?.store?.id}
							inputLists={processList
								?.filter((ele) => {
									if (
										state?.transferOrderType === 'DISPATCH'
									) {
										return ele?.data?.type === 'STORAGE';
									}
									return (
										ele?.data?.type !== 'STORAGE' &&
										!ele?.data?.nodeStore
									);
								})
								?.map((ele) => {
									return {
										...ele,
										imageUrl: ele?.logo,
										name: `${ele?.data?.heading} (Section: ${ele?.data?.sectionName ?? '-'})`,
									};
								})}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								handleSelectFromProcess(null);
							}}
							handleSelection={handleSelectFromProcess}
							hideAddNew
							placeholder='Select To Process'
							selectedValue={fromProcess?.name}
							classname={styles['vendor-selector']}
						/>
					</div>
					<div className={classes(styles['label-container'])}>
						<label
							className={styles.mandatory}
							htmlFor='deliveryDate'
						>
							Transfer Date*
						</label>
						<DatePickerCustom
							selectedDate={date}
							selectDate={(thisDate) => {
								setState('SET_DATE', thisDate);
								handleSelectToShift(null);
							}}
							style={{
								width: 300,
							}}
							oneTap
							defaultValue={date}
							shouldDisableDate={disabledYesterday}
						/>
					</div>
					<div className={classes(styles['label-container'])}>
						<label className={styles.mandatory} htmlFor='shift'>
							Available shifts*
						</label>
						<div
							style={{
								width: '300px',
							}}
						>
							<CustomInputSelector
								inputName='Shift list'
								width='300px'
								disabled={!state?.toProcess?.id}
								inputLists={shiftsList
									?.map((ele) => {
										return {
											...ele,
											name: ele?.shiftName,
										};
									})
									?.filter(filterShifts)}
								// eslint-disable-next-line no-unused-vars
								handleRemove={(key) => {
									handleSelectToShift(null);
								}}
								handleSelection={handleSelectToShift}
								hideAddNew
								placeholder='Select shift'
								selectedValue={selectedShift?.name}
								classname={styles['vendor-selector']}
							/>
							{selectedShift && (
								<div className={styles.shiftStartEnd}>
									{selectedShift?.process?.[0]?.startTime}
									{' '}
									-
									{' '}
									{selectedShift?.process?.[0]?.endTime}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			<div className={styles.multi}>
				{requiredFields?.poId && (
					<div className={classes(styles['label-container'])}>
						<label
							className={styles.mandatory}
							htmlFor='purchaseOrder'
						>
							Purchase Order ID*
						</label>
						<IconInput
							type='custom'
							iconText={orderType === 8 ? '' : 'PO-'}
							size='extra-large'
							inputType='text'
							value={purchaseOrderId}
							handleInput={(e) => {
								setState(
									'SET_PURCHASE_ORDER',
									e?.target?.value
								);
							}}
							style={{
								width: '300px',
							}}
						/>
					</div>
				)}
			</div>
			{requiredFields?.date && (
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='dateSelector'>
						Order Date*
					</label>
					<span>{moment(state?.date)?.format('YYYY-MM-DD')}</span>
					{/* <DatePickerCustom
						selectedDate={date}
						selectDate={(poDate) => {
							setState('SET_DATE', poDate);
							setState('SET_EXPECTED_DELIVERY_DATE', null);
						}}
						style={{
							width: 200,
						}}
						oneTap
						defaultValue={date}
						shouldDisableDate={disabledDate}
					/> */}
				</div>
			)}
			{requiredFields?.expectedDeliveryDate && (
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='deliveryDate'>
						Expected Delivery Date*
					</label>
					<div className='block'>
						{dispatchDelta && formattedDate && (
							<p className='text-blue-700 m-1'>
								Dispatch Date:
								{formattedDate}
							</p>
						)}
						<DatePickerCustom
							selectedDate={expectedDeliveryDate}
							selectDate={(deliveryDate) => {
								setState(
									'SET_EXPECTED_DELIVERY_DATE',
									deliveryDate
								);
							}}
							style={{
								width: 300,
							}}
							oneTap
							defaultValue={expectedDeliveryDate}
							shouldDisableDate={disabledExpiryDate}
						/>
					</div>
				</div>
			)}
			{requiredFields?.inventoryAtDayEndOf && (
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='deliveryDate'>
						Inventory at day end of*
					</label>
					<DatePickerCustom
						selectedDate={inventoryAtDayEndOf}
						selectDate={(deliveryDate) => {
							setState('SET_INVENTORY_DATE', deliveryDate);
						}}
						style={{
							width: 200,
						}}
						oneTap
						defaultValue={inventoryAtDayEndOf}
						shouldDisableDate={(rsuiteDate) => {
							return rsuiteDate > new Date();
						}}
					/>
				</div>
			)}
			{requiredFields?.paymentTerms && (
				<div className={classes(styles['label-container'])}>
					<label className={styles.mandatory} htmlFor='paymentTerms'>
						Payment Terms*
					</label>
					<TextField
						select
						id='paymentTerms'
						value={paymentTerm}
						disabled={disbalePaymentTerm}
						onChange={(e) => {
							setState('SET_PAYMENT_TERM', e?.target?.value);
						}}
						sx={{
							width: 300,
							'.MuiInputBase-root': {
								borderRadius: '4px',
							},
							'.MuiInputBase-input': {
								paddingTop: '6px',
								paddingBottom: '6px',
							},
						}}
					>
						{paymentTerms?.map((ele) => {
							return (
								<MenuItem key={ele} value={ele}>
									{ele}
								</MenuItem>
							);
						})}
					</TextField>
				</div>
			)}
			<div className='flex flex-col gap-3 absolute left-[43rem]'>
				{orderType === 8 && (
					<div
						className={`flex items-center gap-4 ${doc?.file ? 'h-[15rem]' : 'h-auto'} p-2 w-[49%] bg-transparent`}
					>
						<Button
							type='button'
							variant='outline'
							color='primary'
							onClick={uploadInvoiceCick}
						>
							Refrence Document
						</Button>
						{doc?.file && doc?.fileName && (
							<div className='w-full h-full'>
								<iframe
									className='w-full h-full border rounded-md'
									src={doc?.file}
									title='PDF Rendered from Base64'
								/>
							</div>
						)}
					</div>
				)}
				{requiredFields?.process && (
					<div className={classes(styles['label-container'])}>
						<label
							className={
								mandatoryFields?.process ? styles.mandatory : ''
							}
							htmlFor='process'
						>
							{filterLabels?.processTypes ?? 'Delivery Location'}
							{mandatoryFields?.process ? '*' : ''}
						</label>
						<CustomInputSelector
							inputName='PROCESS'
							width='300px'
							disabled={
								!processList?.length || !state?.deliverTo?.id
							}
							inputLists={processList?.map((ele) => {
								return {
									...ele,
									imageUrl: ele?.logo,
									name: ele?.data?.heading,
								};
							})}
							// eslint-disable-next-line no-unused-vars
							handleRemove={(key) => {
								handleSelectProcess(null);
							}}
							handleKeyPress={handleKeyPress}
							handleSelection={handleSelectProcess}
							hideAddNew
							placeholder={
								filterLabels?.processTypes
									? 'Select Process Type'
									: 'Select Delivery Location'
							}
							selectedValue={selectedProcess?.name}
							classname={styles['vendor-selector']}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default POGeneralDetails;
