/* eslint-disable no-param-reassign */
import _ from 'lodash';

const BrandsConfig = {
	initialState: {
		fields: {
			name: '',
			brandCode: '',
			tagline: '',
			trademark: null,
			image: null,
			IMS: false,
			QMS: false,
			CMS: false,
		},
		errors: {
			name: '',
			brandCode: '',
			tagline: '',
			trademark: '',
			image: '',
			IMS: '',
			QMS: '',
			CMS: '',
		},
	},
	mandatoryFields: {
		name: true,
		brandCode: true,
		tagline: false,
		trademark: true,
		image: true,
		IMS: true,
		QMS: true,
		CMS: true,
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.name,
				brandCode: data.brandCode,
				tagline: data.tagline,
				trademark: data.tradeMarkCertificateUrl,
				image: data.brandImageUrl,
				IMS: data.isIMSEnabled,
				QMS: data.isQCEnabled,
				CMS: data.isCMSEnabled,
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const newState = {
			name: state.fields.name,
			brandCode: state.fields.brandCode,
			tagline: state.fields.tagline,
			tradeMarkCertificateUrl: state.fields.trademark,
			brandImageUrl: state.fields.image,
			isIMSEnabled: state.fields.IMS,
			isQCEnabled: state.fields.QMS,
			isCMSEnabled: state.fields.CMS,
		};
		return newState;
	},
};

export default BrandsConfig;
