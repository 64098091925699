// /* eslint-disable */
import { connect } from 'react-redux';
import _, { cloneDeep } from 'lodash';
import {
	useCallback, useEffect, useState
} from 'react';
import drawerActions from '../../../Actions/drawer.actions';
import BaseDrawer from '../BaseDrawer/BaseDrawer';
import { Button } from '@/Components/ui/button';
import { ListModal } from '@/Components/Modals';
import { inputHelper } from '@/Helpers';
import { modalActions } from '@/Actions';
import MultiSelectFilter from './MultiSelectFilter/MultiSelectFilter';
import SingleSelectFilter from './SingleSelectFilter/SingleSelectFilter';
import DateRangeFilter from './DateRangeFilter/DateRangeFilter';
import TabFilter from './TabFilter/TabFilter';
import { notificationServices } from '@/Services';

const ExportFiltersDrawer = (props) => {
	const {
		show,
		data,
		toggleDrawer,
		toggleListModal,
		onConfirm = () => {},
	} = props;
	const { filterData = [], note = '' } = data ?? {};

	const [state, setState] = useState({});

	useEffect(() => {
		if (show) {
			const clonedState = cloneDeep(state);
			filterData?.forEach((ele) => {
				const doesExists = ele?.selectedValueKey in clonedState;
				if (!doesExists) {
					clonedState[ele?.selectedValueKey] = ele?.defaultState;
				}
			});
			setState(clonedState);
		}
	}, [show, filterData]);

	const handleClose = () => {
		toggleDrawer(false);
		setState({});
	};

	const findInvalidItem = useCallback(() => {
		return filterData?.find((ele) => {
			let isEmpty = !state?.[ele?.selectedValueKey];
			if (
				Array?.isArray(ele?.defaultState) &&
				!state?.[ele?.selectedValueKey]?.length
			) {
				isEmpty = true;
			}
			if (
				!Array.isArray(ele?.defaultState) &&
				typeof ele?.defaultState === 'object' && ele?.defaultState !== null
			) {
				if (_?.isEmpty(state?.[ele?.selectedValueKey])) {
					isEmpty = true;
				}
			}
			return ele?.mandatory && isEmpty;
		});
	}, [state, filterData]);

	const handleOnExportClick = () => {
		const invalidItem = findInvalidItem();
		if (invalidItem) {
			notificationServices.generateNotification({
				type: 'error',
				message: `${invalidItem?.label} is Mandatory`,
			});
			return;
		}
		const confirmParams = {
			filtersState: state,
		};
		if (data?.onConfirm) {
			data?.onConfirm(confirmParams);
			return;
		}
		onConfirm(confirmParams);
	};

	const renderBtns = () => {
		return (
			<div className='flex gap-2'>
				<Button onClick={handleClose} variant='secondary'>
					Cancel
				</Button>
				<Button
					onClick={handleOnExportClick}
					className='bg-uy-color text-[#073763] hover:bg-[#ffb80377]'
				>
					Export
				</Button>
			</div>
		);
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (Array.isArray(state?.[entity])) {
			setState((prev) => {
				return {
					...prev,
					[entity]: prev?.[entity]?.filter((eleId) => {
						return eleId !== parseInt(id, 10);
					}),
				};
			});
		}
	};

	const components = {
		tabSelect: (compProps) => {
			const dataPropKey = compProps?.dataPropKey;
			if (dataPropKey && !(dataPropKey in props)) {
				throw Error(
					`${dataPropKey} is not passed to the Export Drawer`
				);
			}
			if (
				!dataPropKey &&
				(compProps?.compData === null ||
					compProps?.compData === undefined)
			) {
				throw Error(
					'Component TabFilter component Data cannot be null or undefined'
				);
			}
			const compDataFromProp = props[dataPropKey];
			return (
				<TabFilter
					filterData={filterData}
					filterProps={compProps}
					state={state}
					setState={setState}
					compDataFromProp={compDataFromProp}
				/>
			);
		},
		singleSelect: (compProps) => {
			const dataPropKey = compProps?.dataPropKey;
			if (dataPropKey && !(dataPropKey in props)) {
				throw Error(
					`${dataPropKey} is not passed to the Export Drawer`
				);
			}
			if (
				!dataPropKey &&
				(compProps?.compData === null ||
					compProps?.compData === undefined)
			) {
				throw Error(
					'Component SingleSelect component Data cannot be null or undefined'
				);
			}
			const compDataFromProp = props[dataPropKey];
			return (
				<SingleSelectFilter
					filterData={filterData}
					filterProps={compProps}
					state={state}
					setState={setState}
					compDataFromProp={compDataFromProp}
				/>
			);
		},
		multiSelect: (compProps) => {
			const dataPropKey = compProps?.dataPropKey;
			if (dataPropKey && !(dataPropKey in props)) {
				throw Error(
					`${dataPropKey} is not passed to the Export Drawer`
				);
			}
			if (
				!dataPropKey &&
				(compProps?.compData === null ||
					compProps?.compData === undefined)
			) {
				throw Error(
					'Component MultiSelect component Data cannot be null or undefined'
				);
			}
			const compDataFromProp = props[dataPropKey];
			return (
				<MultiSelectFilter
					filterData={filterData}
					filterProps={compProps}
					state={state}
					deleteFilter={deleteFilter}
					toggleListModal={toggleListModal}
					compDataFromProp={compDataFromProp}
				/>
			);
		},
		dateRange: (compProps) => {
			return (
				<DateRangeFilter
					filterProps={compProps}
					state={state}
					setState={setState}
					filterData={filterData}
				/>
			);
		},
	};

	const addFilter = (filters, context) => {
		if (Array.isArray(state?.[context?.entity])) {
			setState((prev) => {
				return {
					...prev,
					[context?.entity]: [...prev?.[context?.entity], ...filters],
				};
			});
		}
	};

	return (
		<BaseDrawer
			renderBtns={renderBtns}
			header={data?.heading}
			open={show}
			onClose={handleClose}
			// className='relative'
		>
			<ListModal modalCallee='exportFilter' handleConfirm={addFilter} />
			<div className='relative w-full h-fit border-b-2 border-slate-300 pb-2'>
				{note && (
					<div className='absolute bottom-[-2rem] animate-pulse'>
						<p className='font-semibold text-red-600'>{note}</p>
					</div>
				)}
				<div className='w-full pb-2 mb-2 border-b border-slate-200'>
					{filterData
						?.filter((ele) => {
							return ele?.type === 'tabSelect';
						})
						?.map((ele) => {
							if (
								ele?.type in components &&
								typeof components[ele?.type] === 'function'
							) {
								return components[ele?.type](ele);
							}
							return null;
						})}
				</div>
				<div className='grid grid-cols-4 w-full gap-2'>
					<div className='flex flex-col gap-2 col-span-1'>
						{filterData
							?.filter((ele) => {
								return ele?.type === 'singleSelect';
							})
							?.map((ele) => {
								if (
									ele?.type in components &&
									typeof components[ele?.type] === 'function'
								) {
									return components[ele?.type](ele);
								}
								return null;
							})}
					</div>
					<div className='flex gap-2 w-full  col-span-3 border-b border-slate-200 pb-2 overflow-x-auto'>
						{filterData
							?.filter((ele) => {
								return ele?.type === 'multiSelect';
							})
							?.map((ele) => {
								if (
									ele?.type in components &&
									typeof components[ele?.type] === 'function'
								) {
									return components[ele?.type](ele);
								}
								return null;
							})}
					</div>
				</div>
				<div className='flex justify-start gap-2 w-full mt-1'>
					{filterData
						?.filter((ele) => {
							return ele?.type === 'dateRange';
						})
						?.map((ele) => {
							if (
								ele?.type in components &&
								typeof components[ele?.type] === 'function'
							) {
								return components[ele?.type](ele);
							}
							return null;
						})}
				</div>
			</div>
		</BaseDrawer>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.exportFiltersDrawer;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleDrawer: drawerActions.toggleExportFiltersDrawer,
	toggleListModal: modalActions.toggleListModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExportFiltersDrawer);
