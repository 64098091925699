import { endpoints } from '../Constants';
import { authHeader, handleResponse } from '../Helpers';

const regionStateServices = {
	getRegions,
	createRegion,
	getUnmappedStates,
	updateRegionStateMapping,
	getAllRegionMappings,
};

function createRegion(regionName) {
	const sendData = {
		regionName,
	};

	const url = endpoints.CREATE_REGION;

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			// clientId: 5,
		},
		body: JSON.stringify(sendData),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function getRegions() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			// clientId: 5,
		},
	};

	return fetch(endpoints.LIST_REGION, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data.data.map((region) => {
					return {
						regionId: region.id,
						regionName: region.name,
						states: region.states,
					};
				});
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch Groups: ');
		});
}

function getUnmappedStates() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			// clientId: 5,
		},
	};

	return fetch(endpoints.CLIENT_UNMAPPED_STATE, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data.data.map((state) => {
					return {
						stateId: state.stateId,
						name: state.name,
					};
				});
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch Groups: ');
		});
}

function updateRegionStateMapping(data) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			// clientId: 5,
		},
		body: JSON.stringify(data),
	};

	return fetch(endpoints.UPDATE_REGION_STATE_MAPPING, requestOptions)
		.then(handleResponse)
		.then((res) => {
			return res;
		})
		.catch(() => {
			throw new Error('Failed to Fetch Groups: ');
		});
}

function getAllRegionMappings() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			// clientId: 5,
		},
	};

	return fetch(endpoints.LIST_ALL_REGION_MAPPING, requestOptions)
		.then(handleResponse)
		.then((data) => {
			if (data.statusCode === 200) {
				const result = data.data;
				return result;
			}
			throw new Error(data.message);
		})
		.catch(() => {
			throw new Error('Failed to Fetch Regions Mapping');
		});
}

export default regionStateServices;
