/* eslint-disable no-mixed-spaces-and-tabs */
import {
	useCallback, useEffect, useMemo, useState
} from 'react';
import ReactFlow, {
	Background,
	// MiniMap,
	Controls,
	applyEdgeChanges,
	applyNodeChanges,
	addEdge,
} from 'react-flow-renderer';
import _ from 'lodash';
import QRCode from 'qrcode';
import CustomNode from '../CustomNode/CustomNode';
import ButtonEdge from '../ButtonEdge/button-edge';

function FlowChart(props) {
	const {
		isInternal,
		initialState,
		handleNodesState,
		poActionHandler,
		setIsChanged,
		toggleImageModal,
		toggleEditNodeModal,
		showCheckBox,
		onCheckboxChange,
		handleEdgeDelete,
		handleNodeDelete,
		// eslint-disable-next-line no-unused-vars
		selectedStore,
	} = props;

	const [nodes, setNodes] = useState(initialState?.nodes);
	const [edges, setEdges] = useState(initialState?.edges);

	useEffect(() => {
		setNodes(initialState?.nodes);
	}, [initialState?.nodes]);

	useEffect(() => {
		setEdges(initialState?.edges);
	}, [initialState?.edges]);

	useEffect(() => {
		const debouncedHandler = _.debounce(() => {
			handleNodesState(nodes, edges);
		}, 500);

		debouncedHandler();

		// Return a cleanup function to cancel any ongoing debouncing
		return () => {
			debouncedHandler.cancel();
		};
	}, [nodes, edges]);

	const onNodesChange = useCallback(
		// eslint-disable-next-line consistent-return
		(changes) => {
			if (isInternal) {
				return setNodes((ns) => {
					return applyNodeChanges(changes, ns);
				});
			}
		},
		[isInternal]
	);

	const onEdgesChange = useCallback(
		// eslint-disable-next-line consistent-return
		(changes) => {
			if (isInternal) {
				return setEdges((es) => {
					return applyEdgeChanges(changes, es);
				});
			}
		},
		[isInternal]
	);

	const onNodeDragStop = useCallback(
		// eslint-disable-next-line consistent-return
		() => {
			setIsChanged(true);
		},
		[]
	);

	const onConnect = useCallback(
		// eslint-disable-next-line consistent-return
		(connection) => {
			if (isInternal) {
				setIsChanged(true);
				return setEdges((eds) => {
					return addEdge(connection, eds);
				});
			}
			setIsChanged(true);
		},
		[isInternal]
	);

	const handleAssetView = async (item) => {
		try {
			const options = {
				width: 500,
				height: 400,
			};
			const qrDataURL = await QRCode.toDataURL(item?.asset?.assetQrCode, options);
			toggleImageModal(true, {
				image: qrDataURL,
				width: '50rem',
				height: '50rem',
				heading: `${item?.asset?.assetName} (${item?.asset?.assetTypeName})`,
				centerImage: true,
			});
		} catch (error) {
		// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleAssetUpdate = async (item) => {
		try {
			toggleEditNodeModal(true, {
				heading: item?.heading,
				sectionId: item?.sectionId,
				asset: item?.asset?.id,
				type: item?.type,
				defaultMode: item?.defaultMode,
				id: item?.id,
				nodeStore: item?.nodeStore,
			});
		} catch (error) {
		// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const nodeTypes = useMemo(() => {
		return {
			customNode: (nodeProps) => {
				return (
					<CustomNode
						onDelete={handleNodeDelete}
						poActionHandler={poActionHandler}
						handleAssetView={handleAssetView}
						handleAssetUpdate={handleAssetUpdate}
						showCheckBox={showCheckBox}
						onCheckboxChange={onCheckboxChange}
						{...nodeProps}
					/>
				);
			},
		};
	}, [showCheckBox, selectedStore]);
	const edgeTypes = useMemo(() => {
		return {
			buttonEdge: (edgeProps) => {
				return (
					<ButtonEdge
						onEdgeDelete={handleEdgeDelete}
						poActionHandler={poActionHandler}
						handleAssetView={handleAssetView}
						handleAssetUpdate={handleAssetUpdate}
						isInternal={isInternal}
						{...edgeProps}
					/>
				);
			},
		};
	}, [selectedStore]);

	return (
		<div
			style={{
				width: '100%',
				height: 'calc(100vh - 80px)',
			}}
		>
			<ReactFlow
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				fitView='zoomToFit'
				onNodeDragStop={onNodeDragStop}
				fitViewOptions={{
					minZoom: 0.1,
				}}
				minZoom={0.1}
				defaultZoom={0.2}
			>
				{/* <MiniMap /> */}
				<Controls />
				<Background />
			</ReactFlow>
		</div>
	);
}

export default FlowChart;
