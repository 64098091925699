/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const cogsAnalyticsServices = {
	gateway,
	fetchCogsAnalysisGraph,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CHART_DATA':
			return fetchCogsAnalysisGraph({
				req,
			});
		case 'LIST_DATA':
			return fetchCogsAnalysisList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}
function fetchCogsAnalysisGraph({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.COGS_ANALYTICS, requestOptions).then(
		handleResponse
	);
	// const responseData1 = {
	// 	message: 'Success',
	// 	status: 'Success',
	// 	statusCode: 200,
	// 	data: {
	// 		chartData: {
	// 			labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	// 			consumed: [23, 115, 401, 403, 276, 142, 0, 0, 0, 33, 0, 12],
	// 			wastage: [15, 240, 86, 114, 52, 33, 0, 0, 0, 0, 77, 0],
	// 			productionLoss: [0, 90, 0, 180, 0, 0, 0, 88, 0, 100, 0, 0],
	// 		},
	// 		statsData: {
	// 			Total: {
	// 				label: 'Total',
	// 				value: 2438,
	// 			},
	// 			'Passed %': {
	// 				label: 'Passed %',
	// 				value: '55.78%',
	// 			},
	// 			Overidden: {
	// 				label: 'Overidden',
	// 				value: '22.07%',
	// 			},
	// 			Skipped: {
	// 				label: 'Skipped',
	// 				value: '0.0%',
	// 			},
	// 			'Weight Failure': {
	// 				label: 'Weight Failure',
	// 				value: '10.3%',
	// 			},
	// 			'Temp Failure': {
	// 				label: 'Temp Failure',
	// 				value: '6.6%',
	// 			},
	// 		},
	// 	},
	// };
	// return responseData1;
}

function fetchCogsAnalysisList({ req }) {
	const { params = {} } = req;
	// console.log('passed req', {
	// req,
	// });
	// const url = toQueryStringURL(endpoints.QUALITY_ANALYTICS_LIST, params);
	const url = toQueryStringURL(endpoints.COGS_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// clientID: 52,
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockQAList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
	// return mockQAList(url, requestOptions);
}

function mockQAList() {
	const mockResponse = {
		statusCode: 200,
		message: 'Success',
		data: [
			{
				id: 7812,
				productName: 'Butter Scotch Cake',
				skuType: 'PRODUCT',
				amountUsed: 6782,
				costCategory: 'Inventory Consumed',
			},
			{
				id: 7813,
				productName: 'Butter Scotch Base',
				skuType: 'INGREDIENT',
				amountUsed: 3082,
				costCategory: 'PL',
			},
			{
				id: 7814,
				productName: 'Cheese',
				skuType: 'INGREDIENT',
				amountUsed: 3890,
				costCategory: 'Wastage',
			},
		],
	};

	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(mockResponse);
		}, 1000);
	});
}

export default cogsAnalyticsServices;
