import selected from '../../../../Images/selected.png';
import blank from '../../../../Images/blank.png';
import styles from './CheckListItemImage.module.scss';

const BrandIconImage = (props) => {
	const { item, isSelected, displayCheck, handleItemSelect } = props;
	const handleClick = () => {
		handleItemSelect(parseInt(item.id, 10), isSelected);
	};
	return (
		<div
			className={`${styles.root} ${isSelected ? styles.selected : ''}`}
			onClick={handleClick}
			data-item={item.id}
		>
			{displayCheck && (
				<div className={`${styles['img-container']}`}>
					<img
						src={isSelected ? selected : blank}
						className={styles['check-img']}
						alt={item}
					/>
				</div>
			)}
			<img
				src={item.brandImageUrl}
				alt='brand-pic'
				className={`${styles['brand-img-container']}`}
			/>
			<span className={`${styles.title}`}>{item.name}</span>
		</div>
	);
};
BrandIconImage.defaultProps = {
	isSelected: false,
	displayCheck: false,
};
export default BrandIconImage;
