/* eslint-disable quote-props */
/* eslint-disable quotes */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const productionPlanServices = {
	gateway,
	getPlanDetails,
	getForcastingList,
};

function gateway({ req, method }) {
	switch (method) {
		case 'LIST':
			return fetchList(req);
		// case 'TOP_DEFECTS':
		// return fetchTopDefects(req);
		// case 'TOP_PRODUCTS':
		// return fetchTopProducts(req);
		default:
			break;
	}
	return new Promise();
}

function getForcastingList(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FETCH_FORECASTING_LIST, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req.data || {}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchList(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FETCH_PRODUCTION_PLANS, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions, handleResponse);
}

function getPlanDetails(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FETCH_PRODUCTION_PLAN_DETAILS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions, handleResponse);
}

// eslint-disable-next-line no-unused-vars
function mockFetch1() {
	const mockResponse = {
		statusCode: 200,
		message: '""',
		data: [
			{
				productionPlanId: 123,
				productionPlanDesc: 'NEW PRODUCTIoN PLAN',
				date: '2023-10-16',
				scheduledFor: '2023-10-20',
				items: {
					INGREDIENT: [
						{
							batchSize: null,
							calculatedQty: 12981,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 25000,
							orderedQty: null,
							prePlanStock: null,
							productId: 1474,
							productName: 'AK-Sugar',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1887,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 23300,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 56400,
							orderedQty: null,
							prePlanStock: null,
							productId: 1475,
							productName: 'AK-Water',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1888,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 33016,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 43568,
							orderedQty: null,
							prePlanStock: null,
							productId: 1477,
							productName: 'AK Bakery Mix Flour',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1890,
							variantName: 'FULL',
						},
					],
					INTERMEDIATE: [
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 50,
							unit: 'count',
							updatedQty: 1,
							variantId: 1895,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: 3,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: null,
							unit: 'count',
							updatedQty: 3,
							variantId: 1892,
							variantName: '1Kg',
						},
						{
							batchSize: 17,
							calculatedQty: 1,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 17,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1913,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 683,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 726,
							orderedQty: 1,
							prePlanStock: -22,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: -22,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1908,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1894,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1891,
							variantName: '500g',
						},
						{
							batchSize: 22,
							calculatedQty: 682,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 704,
							orderedQty: null,
							prePlanStock: null,
							productId: 1488,
							productName: 'AK Truffle Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1907,
							variantName: '500g',
						},
						{
							batchSize: 50000,
							calculatedQty: 25961,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 50000,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1481,
							productName: 'AK Sugar Syrup',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1897,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 5,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 6,
							orderedQty: null,
							prePlanStock: null,
							productId: 475,
							productName: 'Boiled Rice',
							scheduledAtDelta: 0,
							skipped: true,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 923,
							variantName: 'FULL',
						},
					],
					PRODUCT: [
						{
							batchSize: 22,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5016,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1906,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 72,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 85,
							orderedQty: 1,
							prePlanStock: 4,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 4,
							threshold: 75,
							unit: 'count',
							updatedQty: 1,
							variantId: 1896,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 82,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 88,
							orderedQty: 1,
							prePlanStock: 19,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 19,
							threshold: 100,
							unit: 'count',
							updatedQty: 1,
							variantId: 1893,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5015,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1911,
							variantName: '1Kg',
						},
					],
				},
			},
			{
				productionPlanId: 150,
				productionPlanDesc: 'NEW PRODUCTIoN PLAN 1',
				date: '2023-10-20',
				scheduledFor: '2023-10-25',
				storeCheckedAt: '2023/10/25 17:23:02',
				items: {
					INGREDIENT: [
						{
							batchSize: null,
							calculatedQty: 12981,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 25000,
							orderedQty: null,
							prePlanStock: null,
							productId: 1474,
							productName: 'AK-Sugar',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1887,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 23300,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 56400,
							orderedQty: null,
							prePlanStock: null,
							productId: 1475,
							productName: 'AK-Water',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1888,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 33016,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 43568,
							orderedQty: null,
							prePlanStock: null,
							productId: 1477,
							productName: 'AK Bakery Mix Flour',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1890,
							variantName: 'FULL',
						},
					],
					INTERMEDIATE: [
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 50,
							unit: 'count',
							updatedQty: 1,
							variantId: 1895,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: 3,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: null,
							unit: 'count',
							updatedQty: 3,
							variantId: 1892,
							variantName: '1Kg',
						},
						{
							batchSize: 17,
							calculatedQty: 1,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 17,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1913,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 683,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 726,
							orderedQty: 1,
							prePlanStock: -22,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: -22,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1908,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1894,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1891,
							variantName: '500g',
						},
						{
							batchSize: 22,
							calculatedQty: 682,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 704,
							orderedQty: null,
							prePlanStock: null,
							productId: 1488,
							productName: 'AK Truffle Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1907,
							variantName: '500g',
						},
						{
							batchSize: 50000,
							calculatedQty: 25961,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 50000,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1481,
							productName: 'AK Sugar Syrup',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1897,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 5,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 6,
							orderedQty: null,
							prePlanStock: null,
							productId: 475,
							productName: 'Boiled Rice',
							scheduledAtDelta: 0,
							skipped: true,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 923,
							variantName: 'FULL',
						},
					],
					PRODUCT: [
						{
							batchSize: 22,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5016,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5025,
							variantId: 1906,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 72,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 85,
							orderedQty: 1,
							prePlanStock: 4,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 4,
							threshold: 75,
							unit: 'count',
							updatedQty: 1,
							variantId: 1896,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 82,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 88,
							orderedQty: 1,
							prePlanStock: 19,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 19,
							threshold: 100,
							unit: 'count',
							updatedQty: 1,
							variantId: 1893,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5015,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1911,
							variantName: '1Kg',
						},
					],
				},
			},
			{
				productionPlanId: 200,
				productionPlanDesc: 'NEW PRODUCTIoN PLAN 2',
				date: '2023-10-24',
				scheduledFor: '2023-11-5',
				items: {
					INGREDIENT: [
						{
							batchSize: null,
							calculatedQty: 12981,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 25000,
							orderedQty: null,
							prePlanStock: null,
							productId: 1474,
							productName: 'AK-Sugar',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1887,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 23300,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 56400,
							orderedQty: null,
							prePlanStock: null,
							productId: 1475,
							productName: 'AK-Water',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1888,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 33016,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 43568,
							orderedQty: null,
							prePlanStock: null,
							productId: 1477,
							productName: 'AK Bakery Mix Flour',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1890,
							variantName: 'FULL',
						},
					],
					INTERMEDIATE: [
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 50,
							unit: 'count',
							updatedQty: 1,
							variantId: 1895,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: 3,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: null,
							unit: 'count',
							updatedQty: 3,
							variantId: 1892,
							variantName: '1Kg',
						},
						{
							batchSize: 17,
							calculatedQty: 1,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 17,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1913,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 683,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 726,
							orderedQty: 1,
							prePlanStock: -22,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: -22,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1908,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1894,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1891,
							variantName: '500g',
						},
						{
							batchSize: 22,
							calculatedQty: 682,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 704,
							orderedQty: null,
							prePlanStock: null,
							productId: 1488,
							productName: 'AK Truffle Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1907,
							variantName: '500g',
						},
						{
							batchSize: 50000,
							calculatedQty: 25961,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 50000,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1481,
							productName: 'AK Sugar Syrup',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1897,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 5,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 6,
							orderedQty: null,
							prePlanStock: null,
							productId: 475,
							productName: 'Boiled Rice',
							scheduledAtDelta: 0,
							skipped: true,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 923,
							variantName: 'FULL',
						},
					],
					PRODUCT: [
						{
							batchSize: 22,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5016,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1906,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 72,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 85,
							orderedQty: 1,
							prePlanStock: 4,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 4,
							threshold: 75,
							unit: 'count',
							updatedQty: 1,
							variantId: 1896,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 82,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 88,
							orderedQty: 1,
							prePlanStock: 19,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 19,
							threshold: 100,
							unit: 'count',
							updatedQty: 1,
							variantId: 1893,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5015,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1911,
							variantName: '1Kg',
						},
					],
				},
			},
			{
				productionPlanId: 300,
				productionPlanDesc: 'NEW PRODUCTIoN PLAN 3',
				date: '2023-10-28',
				scheduledFor: '2023-11-10',
				items: {
					INGREDIENT: [
						{
							batchSize: null,
							calculatedQty: 12981,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 25000,
							orderedQty: null,
							prePlanStock: null,
							productId: 1474,
							productName: 'AK-Sugar',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1887,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 23300,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 56400,
							orderedQty: null,
							prePlanStock: null,
							productId: 1475,
							productName: 'AK-Water',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1888,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 33016,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 43568,
							orderedQty: null,
							prePlanStock: null,
							productId: 1477,
							productName: 'AK Bakery Mix Flour',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1890,
							variantName: 'FULL',
						},
					],
					INTERMEDIATE: [
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 50,
							unit: 'count',
							updatedQty: 1,
							variantId: 1895,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: 3,
							prePlanStock: null,
							productId: 1479,
							productName: 'AK Blackforest Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: null,
							unit: 'count',
							updatedQty: 3,
							variantId: 1892,
							variantName: '1Kg',
						},
						{
							batchSize: 17,
							calculatedQty: 1,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 17,
							orderedQty: 1,
							prePlanStock: null,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1913,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 683,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 726,
							orderedQty: 1,
							prePlanStock: -22,
							productId: 1489,
							productName: 'AK Truffle Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: -22,
							threshold: null,
							unit: 'count',
							updatedQty: 1,
							variantId: 1908,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 75,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 102,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1894,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 83,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 110,
							orderedQty: null,
							prePlanStock: null,
							productId: 1478,
							productName: 'AK Blackforest Sponge',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'count',
							updatedQty: null,
							variantId: 1891,
							variantName: '500g',
						},
						{
							batchSize: 22,
							calculatedQty: 682,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 704,
							orderedQty: null,
							prePlanStock: null,
							productId: 1488,
							productName: 'AK Truffle Base',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 1907,
							variantName: '500g',
						},
						{
							batchSize: 50000,
							calculatedQty: 25961,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 50000,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1481,
							productName: 'AK Sugar Syrup',
							scheduledAtDelta: 0,
							skipped: false,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1897,
							variantName: 'FULL',
						},
						{
							batchSize: null,
							calculatedQty: 5,
							currentDayCount: null,
							currentDayStockIn: null,
							finalProduction: 6,
							orderedQty: null,
							prePlanStock: null,
							productId: 475,
							productName: 'Boiled Rice',
							scheduledAtDelta: 0,
							skipped: true,
							stock: null,
							threshold: null,
							unit: 'gram',
							updatedQty: null,
							variantId: 923,
							variantName: 'FULL',
						},
					],
					PRODUCT: [
						{
							batchSize: 22,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5016,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1906,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 72,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 85,
							orderedQty: 1,
							prePlanStock: 4,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 4,
							threshold: 75,
							unit: 'count',
							updatedQty: 1,
							variantId: 1896,
							variantName: '1Kg',
						},
						{
							batchSize: 22,
							calculatedQty: 82,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 88,
							orderedQty: 1,
							prePlanStock: 19,
							productId: 1480,
							productName: 'AK Blackforest Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 19,
							threshold: 100,
							unit: 'count',
							updatedQty: 1,
							variantId: 1893,
							variantName: '500g',
						},
						{
							batchSize: 17,
							calculatedQty: 5000,
							currentDayCount: 0,
							currentDayStockIn: null,
							finalProduction: 5015,
							orderedQty: 5000,
							prePlanStock: null,
							productId: 1487,
							productName: 'AK Truffle Cake',
							scheduledAtDelta: 0,
							skipped: false,
							stock: 0,
							threshold: 0,
							unit: 'gram',
							updatedQty: 5000,
							variantId: 1911,
							variantName: '1Kg',
						},
					],
				},
			},
		],
	};

	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(mockResponse);
		}, 1500);
	});
}

export default productionPlanServices;
