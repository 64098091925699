/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import ToolBox from '../LabelGeneratorToolsBox/ToolsBox';
import Screen from '../LabelGeneratorScreen/Screen';

function LeftBox(props) {
	const [staticTextToolInUse, setStaticTextToolInUse] = useState(false);

	useEffect(() => {
		setStaticTextToolInUse(!props.staticTextBackButtonClicked);
	}, [props.staticTextBackButtonClicked]);

	const selectTool = (id) => {
		if (id === 'Static Text') {
			setStaticTextToolInUse(true);
		} else {
			setStaticTextToolInUse(false);
		}
		props.toolInUseRenderScreen(id);
	};

	const myComponentsResponse = () => {
		props.handleMyComponentsClickRenderScreen();
		setStaticTextToolInUse(false);
	};

	const staticTextToolRenderRightScreenLeftBox = (text) => {
		props.staticTextToolRenderRightScreenApp(text);
	};

	return (
		<div id='left-box'>
			<div id='left-box-div'>
				<ToolBox
					toolInUse={selectTool}
					myComponents={myComponentsResponse}
					myComponentsClicked={props.myComponentsClicked}
					savedLabelsClicked={props?.savedLabelsClicked}
					previewLabelClicked={props?.previewLabelClicked}
				/>
				<Screen
					staticTextToolInUse={staticTextToolInUse}
					staticTextToolRenderRightScreenLeftBox={
						staticTextToolRenderRightScreenLeftBox
					}
					tool={props.tool}
					backButtonClicked={props.backButtonClicked}
					saveButtonClicked={props.saveButtonClicked}
					myComponentsClicked={props.myComponentsClicked}
					labelComponentsState={props?.labelComponentsState}
					setLabelComponentsState={props?.setLabelComponentsState}
					currentLabelComponents={props?.currentLabelComponents}
					setCurrentLabelComponents={props?.setCurrentLabelComponents}
					savedLabelsClicked={props?.savedLabelsClicked}
					savedLabels={props?.savedLabels}
					setSavedLabels={props?.setSavedLabels}
					setBackButtonClicked={props?.setBackButtonClicked}
					labelDetails={props?.labelDetails}
					setLabelDetails={props?.setLabelDetails}
					editComponentTool={(id) => {
						props?.editComponentTool(id);
					}}
					setSaveButtonClicked={props?.setSaveButtonClicked}
					handleSelectedComponent={(currElement) => { return props?.handleSelectedComponent(currElement); }}
					selectedComponent={props?.selectedComponent}
					setSelectedComponent={props?.setSelectedComponent}
					currentComponent={props?.currentComponent}
					setCurrentComponent={props?.setCurrentComponent}
					editTool={props?.editTool}
					handleBackButtonApp={props?.handleBackButtonApp}
					imageSrc={props?.imageSrc}
					previewLabelClicked={props?.previewLabelClicked}
					selectedToolComponent={props?.selectedToolComponent}
					setIsLoading={props?.setIsLoading}
					setLabelType={props?.setLabelType}
				/>
			</div>
		</div>
	);
}
export default LeftBox;
