import { X } from 'lucide-react';
import {
	useRef, useState, useEffect, useMemo
} from 'react';
import { getSmoothStepPath, getMarkerEnd } from 'react-flow-renderer';
import { Label } from '@/Components/ui/label';

const foreignObjectSize1 = 100;
const foreignObjectSize2 = 100;

export default function ButtonEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	arrowHeadType,
	markerEndId,
	onEdgeDelete,
	isInternal,
	data = [],
}) {
	const pathRef = useRef(null);
	const [pathLength, setPathLength] = useState(0);

	const edgePath = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});
	const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

	useEffect(() => {
		if (pathRef.current) {
			const length = pathRef.current.getTotalLength();
			setPathLength(length);
		}
	}, [sourceX, sourceY, targetX, targetY, edgePath]);

	const edgeCenter = useMemo(() => {
		if (pathRef.current) {
			const midpoint = pathRef.current.getPointAtLength(pathLength / 2);
			return {
				x: midpoint.x - foreignObjectSize1 / 2,
				y: midpoint.y - foreignObjectSize1 / 2,
			};
		}
		return {
			x: 0,
			y: 0,
		};
	}, [pathLength, foreignObjectSize1]);

	const edgeCenterFO2 = useMemo(() => {
		if (pathRef.current) {
			const midpoint = pathRef.current.getPointAtLength(pathLength / 1.5);
			return {
				x: midpoint.x - foreignObjectSize2 / 2,
				y: midpoint.y - foreignObjectSize2 / 2,
			};
		}
		return {
			x: 0,
			y: 0,
		};
	}, [pathLength, foreignObjectSize2]);

	return (
		<>
			<path
				id={id}
				ref={pathRef}
				style={style}
				className='react-flow__edge-path'
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<foreignObject
				width={foreignObjectSize1}
				height={foreignObjectSize1}
				x={edgeCenter.x}
				y={edgeCenter.y}
				className=''
			>
				{isInternal && (
					<body className=''>
						<button
							type='button'
							className='bg-slate-200 rounded-full p-1 w-full h-full'
							onClick={() => {
								onEdgeDelete(id);
							}}
						>
							<X className='w-full h-full text-red-600' />
						</button>
					</body>
				)}
			</foreignObject>
			<foreignObject
				width={foreignObjectSize2 + 150}
				height={foreignObjectSize2 * data?.length}
				x={edgeCenterFO2.x}
				y={edgeCenterFO2.y}
				className=''
			>
				{isInternal && (
					<body className='flex flex-col items-center gap-2 bg-[#9a9a9a3f] border-2 border-zinc-500 p-2 rounded-lg'>
						{data?.map((ele) => {
							return (
								<div className='flex flex-col gap-1'>
									<Label className='text-2xl'>
										{ele?.label}
									</Label>
									<p className='text-5xl font-bold'>
										{ele?.value}
									</p>
								</div>
							);
						})}
					</body>
				)}
			</foreignObject>
		</>
	);
}
