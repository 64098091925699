/* eslint-disable react/button-has-type */
import { Handle } from 'react-flow-renderer';
import { connect } from 'react-redux';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { modalActions } from '../../../Actions';
import { classes, modes } from '../../../Helpers';
import machine from '../../../Images/10kgMc.png';
import phone from '../../../Images/phone.png';
import view from '../../../Images/view.png';
import pdf from '../../../Images/pdf.png';
import editing from '../../../Images/editing.png';
import remove from '../../../Images/remove.png';
import styles from './CustomNode.module.scss';
import { Checkbox } from '@/Components/ui/checkbox';
import storageIcon from '@/Images/store.png';
import operationIcon from '@/Images/operation.png';
import { Button } from '@/Components/ui/button';

function CustomNode(props) {
	const {
		data,
		userPermissions,
		id,
		poActionHandler,
		toggleProcessModal,
		handleAssetView,
		handleAssetUpdate,
		onDelete,
		showCheckBox,
		onCheckboxChange,
	} = props;
	const isInternal = userPermissions?.INTERNAL;

	// eslint-disable-next-line no-unused-vars
	const actionHandler = () => {
		toggleProcessModal(true, {
			...data,
			id,
		});
	};
	const handleCheckboxClick = () => {
		onCheckboxChange(id);
	};
	return (
		<>
			<Handle
				type='target'
				position='top'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${id}-top`}
			/>
			<Handle
				type='target'
				position='left'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${id}-left`}
			/>
			<div
				style={{
					background: data?.color,
				}}
				className={styles['custom-node']}
			>
				{showCheckBox && (
					<div className='w-36 h-36 p-2 bg-slate-200 absolute -top-7 -left-7 z-[999]'>
						<Checkbox
							className='w-full h-full data-[state=checked]:bg-uy-color text-3xl'
							onClick={handleCheckboxClick}
							Checked={id}
							on
						/>
					</div>
				)}
				{/* <div className={styles['img-heading']}>
					<img
						className={isInternal ? styles.editable : ''}
						src={data?.imageUrl}
						alt={data?.id}
					/>
				</div> */}

				<div className={styles['actions-container']}>
					{/* <div
						className=''
						onClick={() => {
							onDelete(id);
						}}
					>
						<Trash2 className='text-red-600 w-24 h-24 ' />
					</div> */}
					{data?.orderId && (
						<div>
							<div
								className={styles.action}
								onClick={() => {
									poActionHandler(data);
								}}
							>
								<img src={view} alt='view' />
							</div>
							<div
								className={styles.action}
								onClick={() => {
									poActionHandler(data, 2);
								}}
							>
								<img className='mx-auto my-0 h-[88px]' src={pdf} alt='pdf' />
							</div>

						</div>
					)}
					{isInternal && (
						<>
							<div
								className={styles.action}
								onClick={() => handleAssetUpdate({
									id,
									...data,
								})}
							>
								<img className='mx-auto my-4 h-[88px]' src={editing} alt='view' />
							</div>
							<div
								className={styles.action}
								onClick={() => onDelete({
									id,
									...data,
								})}
							>
								<img className='mx-auto my-4 h-[88px]' src={remove} alt='view' />
							</div>
						</>
					)}
				</div>
				{data?.totalMachines > 0 && (
					<div className={styles['img-container']}>
						<img src={machine} alt='Machine ' />
						<p>{data?.totalMachines}</p>
					</div>
				)}
				{data?.hasResoeeApp > 0 && (
					<div className={styles['img-container']}>
						<img src={phone} alt='phone ' />
					</div>
				)}
				{data?.type === 'STORAGE' && (
					<div
						className='absolute top-[-7rem] left-[-17rem] z-[999] '
					>
						<img className='h-[14rem]' src={storageIcon} alt='storage' />
					</div>
				)}
				{data?.type === 'OPERATION' && (
					<div
						className=' absolute top-[-7rem] left-[-17rem] z-[999] '
					>
						<img className='h-[14rem]' src={operationIcon} alt='operation' />
					</div>
				)}
				{(data?.pending >= 0 || data?.completed >= 0) && (
					<div className={styles.orders_count}>
						{data?.pending !== null && (
							<div
								className={classes(
									styles.count,
									styles.pending
								)}
							>
								{/* <p className={styles.count_header}>Pending</p> */}
								<p>{data?.pending}</p>
							</div>
						)}
						{data?.completed !== null && (
							<div
								className={classes(
									styles.count,
									styles.completed
								)}
							>
								{/* <p className={styles.count_header}>Completed</p> */}
								<p>{data?.completed}</p>
							</div>
						)}
					</div>
				)}
				<p className={styles.heading}>{data?.heading}</p>
				{data?.sectionName && (
					<p
						className={styles.section}
						style={{
							background: data?.sectionColor ?? '',
						}}
					>
						Section:
						{' '}
						{data?.sectionName}
					</p>
				)}
				{data?.type && (
					<>
						<p className='bg-amber-500 w-full text-5xl text-center py-1'>
							TYPE:
							{' '}
							{data?.type}
						</p>
					</>
				)}
				{data?.asset?.assetName && (
					<div className='bg-blue-950 w-full py-1 block text-center text-white'>
						<p className='text-5xl text-center'>
							Asset:
							{' '}
							{data?.asset?.assetName}
							{' '}
							{data?.asset?.assetTypeName
								? `(${data?.asset?.assetTypeName})`
								: ''}
						</p>
						<div>
							<Button
								variant='secondary'
								onClick={() => handleAssetView(data)}
							>
								<VisibilitySharpIcon fontSize='large' />
								{' '}
								View
							</Button>
						</div>
					</div>
				)}
				{data?.defaultMode && (
					<p className={styles.section}>
						Default Mode:
						{' '}
						{modes?.[data?.defaultMode]}
					</p>
				)}
				<div className={styles['data-container']}>
					{Object.keys(data?.stats ?? {})?.map((ele) => {
						const isProgressBar =
							data?.stats[ele]?.indexOf('%') > 0;
						const width = isProgressBar
							? data?.stats[ele]?.split(' ')?.join('')
							: 0;
						const percentage = parseFloat(width);
						const bgColor = percentage > 50 ? 'green' : 'yellow';
						return (
							<div
								key={ele}
								className={classes(styles.analytics)}
							>
								{isProgressBar && (
									<div
										style={{
											width,
											background: bgColor,
										}}
										className={styles.progress}
									/>
								)}
								<p
									style={{
										color: percentage > 70 ? 'white' : '',
									}}
									className={styles['value-title']}
								>
									{ele}
								</p>
								<p
									style={{
										color: percentage > 70 ? 'white' : '',
									}}
								>
									{data?.stats[ele]}
								</p>
							</div>
						);
					})}
				</div>
			</div>
			<Handle
				type='source'
				position='bottom'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
					opacity: isInternal ? 0.25 : 1,
				}}
				id={`${id}-bottom`}
			/>
			<Handle
				type='source'
				position='right'
				style={{
					width: '40px',
					height: '40px',
					zIndex: 99,
				}}
				id={`${id}-right`}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	return {
		userPermissions,
	};
};
const mapDispatchToProps = {
	togglePOItemsModal: modalActions.togglePOItemsModal,
	toggleProcessModal: modalActions?.toggleProcessModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomNode);
