import {
	ArrowBack, ArrowForward, FilterAlt
} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import { useMemo, useState } from 'react';
import SelectPicker from 'rsuite/SelectPicker';
import styles from './FilterSelector.module.scss';
import { capitalizeFirstLetter, classes } from '../../../Helpers';
import PeriodFilter from './PeriodFilter/PeriodFilter';

const FILTERS = ['STORE', 'PERIOD'];

function FilterSelector(props) {
	const {
		stores,
		setSelectedStore,
		handlePeriod,
		selectedPeriod,
		filters,
		// selectedStore,
	} = props;
	const [showBody, setShowBody] = useState(false);
	const [filterState, setFilterState] = useState({
		level: 0,
		type: '',
	});

	const toggleShow = () => {
		setShowBody(!showBody);
	};

	const STORES = useMemo(() => {
		const temp = {
			id: -999,
			name: 'All',
			type: 'ALL',
		};
		return [temp, ...stores];
	}, [stores]);

	const handleFilterClick = (state) => {
		setFilterState(state);
	};

	const isFilterActive = (filterType) => {
		if (filterType === 'PERIOD') {
			const currSelection = filters?.DATE_FILTER?.selection ?? {};
			const filter = `${currSelection?.day ?? ''} ${
				currSelection?.month ?? ''
			} ${currSelection?.year ?? ''}`;
			return filter;
		}
		if (filterType === 'STORE') {
			return filters?.selectedStore?.id && filters?.selectedStore?.name;
		}
		return false;
	};

	return (
		<ClickAwayListener
			onClickAway={() => {
				setShowBody(false);
			}}
		>
			<div className={styles.root}>
				<div
					className={classes(
						styles['selector-body'],
						showBody ? styles.show : ''
					)}
				>
					{filterState?.level === 0 ? (
						<div className={styles['level-one']}>
							{FILTERS?.map((ele) => {
								const isActive = isFilterActive(ele);
								return (
									<div
										key={ele}
										onClick={() => {
											handleFilterClick({
												level: 1,
												type: ele,
											});
										}}
										className={classes(
											styles['filter-box'],
											isActive ? styles.selected : ''
										)}
									>
										<div className={styles.name}>
											<p>{ele}</p>
											{isActive && (
												<p>
													(
													{isActive}
													)
												</p>
											)}
										</div>
										<ArrowForward />
									</div>
								);
							})}
						</div>
					) : (
						<div className={styles['level-two']}>
							<div className={styles.header}>
								<div
									onClick={() => {
										setFilterState({
											level: 0,
											type: '',
										});
									}}
									className={styles['go-back']}
								>
									<ArrowBack className={styles.icon} />
								</div>
								<p>
									Select
									{' '}
									{capitalizeFirstLetter(filterState?.type)}
								</p>
							</div>
							<div className={styles['filter-container']}>
								{filterState?.type === 'PERIOD' && (
									<PeriodFilter
										handleSelection={handlePeriod}
										selectedPeriod={selectedPeriod}
										filterState={filters?.DATE_FILTER}
									/>
								)}
								{filterState?.type === 'STORE' && (
									<SelectPicker
										data={STORES?.map((ele) => {
											return {
												...ele,
												label: ele?.name,
												role: ele?.type,
												value: ele,
											};
										})}
										style={{
											width: '100%',
										}}
										groupBy='type'
										placeholder='Select Store'
										onChange={(option) => {
											setSelectedStore(option);
										}}
										menuStyle={{
											zIndex: 12,
										}}
										cleanable={false}
										renderMenuItem={(_) => {
											return (
												<div>
													<i className='rs-icon rs-icon-user' />
													{' '}
													{_}
												</div>
											);
										}}
										renderMenuGroup={(_) => {
											return (
												<div>
													<i className='rs-icon rs-icon-group' />
													{_}
												</div>
											);
										}}
										value={filters?.selectedStore}
										renderValue={(_, item) => {
											return (
												<div
													className={`${styles.selected_store} rsuite-select-picker-render-value`}
												>
													<p>
														Store:
														{' '}
														{item?.name}
													</p>
												</div>
											);
										}}
									/>
								)}
							</div>
						</div>
					)}
				</div>
				<div
					onClick={toggleShow}
					className={classes(
						styles['selector-btn'],
						showBody ? styles.visible : ''
					)}
				>
					<FilterAlt />
				</div>
			</div>
		</ClickAwayListener>
	);
}

export default FilterSelector;
