import { modalConstants } from '../Constants';

const modalActions = {
	toggleProducts,
	toggleAlert,
	toggleSKUs,
	toggleFilterModal,
	toggleBrandFilterModal,
	toggleGeographyFilterModal,
	toggleAddress,
	toggleApplicableUsersModal,
	toggleApplicableStoresModal,
	toggleApplicableProductsModal,
	toggleApplicableProductCategoriesModal,
	toggleNonApplicableProductsModal,
	toggleNonApplicableProductCategoriesModal,
	togglePincodeModal,
	toggleMenuModal,
	toggleMenuList,
	toggleImageCrop,
	toggleSpecialMenuModal,
	toggleImageModal,
	toggleVanImageModal,
	toggleAltAlertModal,
	toggleProductProcessMapCreatorModal,
	toggleListModal,
	toggleModal,
	toggleStockListModal,
	togglePOItemsModal,
	toggleItemsModal,
	toggleOrdersItemsModal,
	toggleOrderItemsModal,
	toggleWorkOrderItemsModal,
	toggleEditChildModal,
	toggleContainerModal,
	toggleReportEmailsModal,
	toggleProcessModal,
	toggleProductionPlanModal,
	toggleGenerateStandardPOModal,
	toggleAddVendorModal,
	toggleVendorProductMapModal,
	toggleVendorOrderGrnsModal,
	toggleAuditItemsModal,
	toggleImportModal,
	toggleProductWiseMappingModal,
	toggleAddLicenseModal,
	toggleChangePasswordModal,
	toggleFileUploaderModal,
	togglePaymentModal,
	toggleNavItemRequisitesModal,
	toggleTransactionsModal,
	toggleAuditCreationModal,
	toggleSearchModal,
	toggleBulkPaymentModal,
	toggleAddGroupModal,
	toggleRegionModal,
	toggleDynamicTableModal,
	toggleShiftTableModal,
	toggleErrorModal,
	togglePaymentDetailsModal,
	toggleLeftOverItemsModal,
	toggleAddWastageReasonModal,
	toggleEditNodeModal,
	toggleAddAssetCategoryModal,
	toggleSpecificationModal,
	toggleUploadDataModal,
	toggleAssetTransit,
};
function toggleSpecialMenuModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_SPECIAL_MENU,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_SPECIAL_MENU,
		payload: data,
	};
}
function toggleStockListModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_STOCK_LIST_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_STOCK_LIST_MODAL,
		payload: data,
	};
}

function toggleMenuList(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_MENULIST,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_MENULIST,
		payload: data,
	};
}

function toggleProducts(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PRODUCTS,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PRODUCTS,
		payload: data,
	};
}
function toggleAlert(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ALERT,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ALERT,
		payload: data,
	};
}
function toggleSKUs(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_SKUS,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_SKUS,
		payload: data,
	};
}
function toggleFilterModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_FILTER,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_FILTER,
		payload: data,
	};
}
function toggleAddress(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADDRESS,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADDRESS,
		payload: data,
	};
}
function togglePincodeModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_PINCODE,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_PINCODE,
		payload: data,
	};
}

function toggleMenuModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_LIVE_MENU,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_LIVE_MENU,
		payload: data,
	};
}
function toggleBrandFilterModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_BRAND_FILTER,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_BRAND_FILTER,
		payload: data,
	};
}
function toggleGeographyFilterModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_GEOGRAPHY_FILTER,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_GEOGRAPHY_FILTER,
		payload: data,
	};
}

function toggleImageCrop(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_IMAGE_CROP,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_IMAGE_CROP,
		payload: data,
	};
}

function toggleApplicableUsersModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_APPLICABLE_USERS,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_APPLICABLE_USERS,
		payload: data,
	};
}

function toggleApplicableStoresModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_APPLICABLE_STORES,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_APPLICABLE_STORES,
		payload: data,
	};
}
function toggleApplicableProductsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_APPLICABLE_PRODUCTS,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_APPLICABLE_PRODUCTS,
		payload: data,
	};
}
function toggleApplicableProductCategoriesModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_APPLICABLE_PRODUCT_CATEGORIES,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_APPLICABLE_PRODUCT_CATEGORIES,
		payload: data,
	};
}
function toggleNonApplicableProductsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_NONAPPLICABLE_PRODUCTS,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_NONAPPLICABLE_PRODUCTS,
		payload: data,
	};
}
function toggleNonApplicableProductCategoriesModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES,
		payload: data,
	};
}
function toggleImageModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_IMAGE_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_IMAGE_MODAL,
		payload: data,
	};
}
function toggleProductProcessMapCreatorModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_MAP_CREATOR_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_MAP_CREATOR_MODAL,
		payload: data,
	};
}

function toggleVanImageModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_VAN_IMAGE_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_VAN_IMAGE_MODAL,
		payload: data,
	};
}

function toggleAltAlertModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ALT_ALERT,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ALT_ALERT,
		payload: data,
	};
}

function toggleListModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_LIST_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_LIST_MODAL,
		payload: data,
	};
}

function togglePOItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PO_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PO_ITEMS_MODAL,
		payload: data,
	};
}
function toggleItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ITEMS_MODAL,
		payload: data,
	};
}

function toggleOrdersItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ORDERS_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ORDERS_ITEMS_MODAL,
		payload: data,
	};
}
function toggleOrderItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ORDER_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ORDER_ITEMS_MODAL,
		payload: data,
	};
}
function toggleWorkOrderItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_WORK_ORDER_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_WORK_ORDER_ITEMS_MODAL,
		payload: data,
	};
}

function toggleEditChildModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_EDIT_CHILD_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_EDIT_CHILD_MODAL,
		payload: data,
	};
}
function toggleContainerModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_CONTAINER_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_CONTAINER_MODAL,
		payload: data,
	};
}

function toggleReportEmailsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_REPORTS_EMAILS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_REPORTS_EMAILS_MODAL,
		payload: data,
	};
}

function toggleProcessModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PROCESS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PROCESS_MODAL,
		payload: data,
	};
}

function toggleProductionPlanModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PRODUCTION_PLAN_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PRODUCTION_PLAN_MODAL,
		payload: data,
	};
}
function toggleGenerateStandardPOModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_GENERATE_STANDARD_PO_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_GENERATE_STANDARD_PO_MODAL,
		payload: data,
	};
}

function toggleAddVendorModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_NEW_VENDOR_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_NEW_VENDOR_MODAL,
		payload: data,
	};
}

function toggleVendorProductMapModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_VENDOR_PRODUCT_MAP_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_VENDOR_PRODUCT_MAP_MODAL,
		payload: data,
	};
}

function toggleVendorOrderGrnsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_VENDOR_ORDER_GRNS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_VENDOR_ORDER_GRNS_MODAL,
		payload: data,
	};
}

function toggleAuditItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_AUDIT_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_AUDIT_ITEMS_MODAL,
		payload: data,
	};
}
function toggleImportModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_IMPORT_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_IMPORT_MODAL,
		payload: data,
	};
}
function toggleProductWiseMappingModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PRODUCTWISE_MAPPING_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PRODUCTWISE_MAPPING_MODAL,
		payload: data,
	};
}
function toggleAddLicenseModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_LICENSE_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_LICENSE_MODAL,
		payload: data,
	};
}
function toggleChangePasswordModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_CHANGE_PASSWORD_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_CHANGE_PASSWORD_MODAL,
		payload: data,
	};
}
function toggleFileUploaderModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_FILE_UPLOADER_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_FILE_UPLOADER_MODAL,
		payload: data,
	};
}
function togglePaymentModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PAYMENT_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PAYMENT_MODAL,
		payload: data,
	};
}

function toggleNavItemRequisitesModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_NAV_REQUISITES_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_NAV_REQUISITES_MODAL,
		payload: data,
	};
}

function toggleTransactionsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_TRANSACTIONS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_TRANSACTIONS_MODAL,
		payload: data,
	};
}

function toggleAuditCreationModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_AUDIT_CREATION_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_AUDIT_CREATION_MODAL,
		payload: data,
	};
}

function toggleSearchModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_SEARCH_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_SEARCH_MODAL,
		payload: data,
	};
}
function toggleBulkPaymentModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_BULK_PAYMENT_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_BULK_PAYMENT_MODAL,
		payload: data,
	};
}
function toggleAddGroupModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_GROUP_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_GROUP_MODAL,
		payload: data,
	};
}

function toggleRegionModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_REGION_MENU_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_REGION_MENU_MODAL,
		payload: data,
	};
}

function toggleDynamicTableModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_DYNAMIC_TABLE_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_DYNAMIC_TABLE_MODAL,
		payload: data,
	};
}

function toggleShiftTableModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_SHIFT_TABLE_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_SHIFT_TABLE_MODAL,
		payload: data,
	};
}

function toggleErrorModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ERROR_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ERROR_MODAL,
		payload: data,
	};
}
function togglePaymentDetailsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_PAYMENT_DETAILS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_PAYMENT_DETAILS_MODAL,
		payload: data,
	};
}
function toggleLeftOverItemsModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_LEFT_OVER_ITEMS_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_LEFT_OVER_ITEMS_MODAL,
		payload: data,
	};
}

function toggleAddWastageReasonModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_WASTAGE_REASON_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_WASTAGE_REASON_MODAL,
		payload: data,
	};
}

function toggleModal(modalType, show, data = {}) {
	const payload = {
		[modalType]: {
			show,
			data,
		},
	};
	return {
		type: modalConstants.TOGGLE_MODAL,
		payload,
	};
}
function toggleEditNodeModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_EDIT_NODE_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_EDIT_NODE_MODAL,
		payload: data,
	};
}
function toggleAddAssetCategoryModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ADD_ASSET_CATEGORY_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ADD_ASSET_CATEGORY_MODAL,
		payload: data,
	};
}
function toggleSpecificationModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_SPECIFICATION_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_SPECIFICATION_MODAL,
		payload: data,
	};
}
function toggleUploadDataModal(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_UPLOAD_DATA_MODAL,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_UPLOAD_DATA_MODAL,
		payload: data,
	};
}
function toggleAssetTransit(show, data = {}) {
	if (show) {
		return {
			type: modalConstants.SHOW_ASSET_TRANSIT,
			payload: data,
		};
	}
	return {
		type: modalConstants.HIDE_ASSET_TRANSIT,
		payload: data,
	};
}
export default modalActions;
