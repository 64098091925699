/* eslint-disable no-tabs */
import _ from 'lodash';
import moment from 'moment';
import { notificationServices, stateCityServices } from '../../../Services';

function handleTimeFormat(value) {
	if (value && value.includes(':')) {
		const arr = value.split(':');
		const d = new Date(2018, 11, 24, arr[0], arr[1], 30, 0);
		return d;
	}
	return value;
}
const RiderConfig = {
	initialState: {
		fields: {
			name: '',
			image: '',
			address1: '',
			address2: '',
			city: null,
			state: null,
			startTime: null,
			endTime: null,
			phoneNo: '',
			vehicleNo: '',
			licenseNo: '',
		},
		errors: {
			name: '',
			image: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			startTime: '',
			endTime: '',
			phoneNo: '',
			vehicleNo: '',
			licenseNo: '',
		},
	},
	mandatoryFields: {
		name: true,
		image: true,
		address1: true,
		address2: false,
		city: true,
		state: true,
		startTime: true,
		endTime: true,
		phoneNo: true,
		vehicleNo: true,
		licenseNo: true,
	},
	serializer: (state, data) => {
		const result = _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.name,
				image: data.imageUrl,
				address1: data.addressLine1,
				address2: data.addressLine2,
				city: data.city,
				state: data.state,
				startTime: handleTimeFormat(data.riderAvailabilityStartAt),
				endTime: handleTimeFormat(data.riderAvailabilityEndAt),
				phoneNo: data.phoneNumber,
				vehicleNo: data.vehicleNo,
				licenseNo: data.licenseNo,
			};
			return newState;
		});
		return new Promise((resolve) => {
			stateCityServices.getStateCity().then((res) => {
				const currentStateCity = res.data.find((stateCityObj) => {
					return stateCityObj.cityId === parseInt(data.city, 10);
				});
				result.fields.city = currentStateCity;
				result.fields.state = currentStateCity.stateName;
				resolve(result);
			});
		});
	},
	deserializer: (state) => {
		const newState = {
			name: state.fields.name,
			imageUrl: state.fields.image,
			addressLine1: state.fields.address1,
			addressLine2: state.fields.address2,
			city: state.fields.city.cityId,
			state: state.fields.city.stateId,
			riderAvailabilityStartAt: moment(state.fields.startTime).format(
				'HH:mm'
			),
			riderAvailabilityEndAt: moment(state.fields.endTime).format(
				'HH:mm'
			),
			phoneNumber: state.fields.phoneNo,
			vehicleNo: state.fields.vehicleNo,
			licenseNo: state.fields.licenseNo,
		};
		return newState;
	},
	validator: (state) => {
		if (!state.fields.city) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select city',
			});
			return false;
		}
		if (!state.fields.startTime || !state.fields.endTime) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter timings',
			});
			return false;
		}
		if (!state.fields.image) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Upload Image before Submission',
			});
			return false;
		}
		return true;
	},
};

export default RiderConfig;
