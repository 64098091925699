import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { paymentSettlementActions } from '../../../../Actions';
import AmountContainerDiv from '../../../../Components/Forms/Payment Settlements/AmountContainerDiv';
import Calender from '../../../../Components/Forms/Payment Settlements/Calender/Calender';
import styles from './ViewTransactionPage.module.scss';
import { history, isEmpty } from '../../../../Helpers';
import { urls } from '../../../../Constants';
import TransactionListTable from './TransactionListTable';
import transactionConfig from './TransactionConfig';
// import SearchBar from '../../../../Components/SearchBar/SearchBar';

const ViewTransactionPage = (props) => {
	const { fetchTransaction, data, date } = props;
	const [state, setState] = useState({
		...transactionConfig.initialState,
	});
	const { id } = useRouteMatch().params;
	if (date.length === 0) {
		history.push(`${urls.EXTENSION}${urls.PAYMENT_SETTLEMENTS}`);
	}
	useEffect(() => {
		fetchTransaction({
			entity: 'TRANSACTION',
			req: {
				data: {
					settlement_id: id,
				},
			},
		});
	}, []);
	useEffect(() => {
		if (!isEmpty(data)) {
			setState(transactionConfig.deserializer(state, data));
		}
	}, [data]);
	const handleCancel = () => {
		history.push(`${urls.EXTENSION}${urls.PAYMENT_SETTLEMENTS}`);
	};
	// const handleInput = (query) => {
	// setState({
	// ...state,
	// query,
	// });
	// };
	return (
		<div className={`${styles.root} page`}>
			<AmountContainerDiv amountCaption={state.fields.metricsData} />
			<div className={`${styles['settlement-page-search']}`}>
				<div>
					<Button
						variant='contained'
						disableElevation
						onClick={handleCancel}
					>
						Back
					</Button>
				</div>
				<div className={styles['settlement-id-container']}>
					<div className={styles['settlement-id-label']}>
						Settlement Id :
						{' '}
					</div>
					<div className={styles['settlement-id']}>{` ${id}`}</div>
				</div>
				{date.length > 0 && (
					<div>
						<Calender
							value={[
								{
									startDate: date?.[0],
									endDate: date?.[1],
									key: 'selection',
								},
							]}
							isDisabled
						/>
					</div>
				)}
			</div>
			<TransactionListTable
				data={state.fields.transactionData}
				query={state.query}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.paymentSettlement.TRANSACTION;
	const { date } = state.paymentSettlement;
	return {
		data,
		date,
	};
};
const mapDispatchToProps = {
	fetchTransaction: paymentSettlementActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewTransactionPage);
