import _ from 'lodash';

const storeAnalysisMetrics = {
	initialState: {
		fields: {
			amountCaption: [
				{
					caption: 'Total Revenue',
					amount: 'N/A',
				},
				{
					caption: 'Total Orders',
					amount: 'N/A',
					itemIcon: 'list',
				},
				{
					caption: 'Total CoGS',
					amount: 'N/A',
				},
				{
					caption: 'Gross Margin',
					amount: 'N/A',
				},
				{
					caption: 'Inventory Value',
					amount: 'N/A',
				},
				{
					caption: 'Pass',
					amount: 'N/A',
				},
			],
		},
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				amountCaption: [
					{
						caption: 'Total Revenue',
						amount: data['Total Revenue'],
					},
					{
						caption: 'Total Orders',
						amount: data['Total Orders'],
						itemIcon: 'list',
					},
					{
						caption: 'Total CoGS',
						amount: data['Total CoGS'],
					},
					{
						caption: 'Gross Margin',
						amount: data['Gross Margin'],
					},
					{
						caption: 'Inventory Value',
						amount: data['Inventory Value'],
					},
					{
						caption: 'Pass %',
						amount: data['Pass %'],
					},
				],
			};
			return newState;
		});
	},
};
export default storeAnalysisMetrics;
