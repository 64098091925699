function PreviewLabel(props) {
	return (
		<div className='preview-label-screen'>
			{props?.imageSrc ? (
				<img
					src={props?.imageSrc}
					style={{
						width: `${(props?.width * (96 / 25.4)) / 12.25}rem`,
						height: `${(props?.height * (96 / 25.4)) / 12.25}rem`,
						padding: 0,
						boxShadow: '0 0 10px #d1d1d1',
						borderRadius: '4px',
					}}
					alt='PreviewLabel'
				/>
			) : 'No Label Generated'}
		</div>
	);
}
export default PreviewLabel;
