import { getCookie } from '../Services/Storage Services';

// const token = '6-51-33db4c54-d7f5-4b85-9b9d-1875c7e5964d';

function authHeader() {
	const commonHeaders = {
		Source: 'console',
		Version: process.env.REACT_APP_VERSION,
	};
	const user = JSON.parse(getCookie('user'));
	if (user && user.token) {
		return {
			token: user.token,
			...commonHeaders,
			// clientId: 5,
		};
	}
	return {
		token: null,
		...commonHeaders,
		// clientId: 5,
	};
}

export default authHeader;
