import _ from 'lodash';
import { notificationServices } from '../../../Services';

const addFranchiseeConfig = {
	initialState: {
		fields: {
			name: '',
			otp: '',
			contact: '',
			addressLine1: '',
			addressLine2: '',
			longitude: 77.185024,
			latitude: 28.6523392,
			state: '',
			city: '',
			pincode: '',
			email: '',
		},
		errors: {
			name: '',
			otp: '',
			contact: '',
			addressLine1: '',
			addressLine2: '',
			state: '',
			city: '',
			pincode: '',
		},
	},
	mandatoryFields: {
		name: true,
		otp: true,
		contact: true,
		address: true,
		addressLine2: true,
		state: true,
		pincode: true,
		city: true,
		email: true,
	},
	serializer: (state, data) => {
		const result = _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.name,
				contact: data.mobile,
				otp: data.fixedOTP,
				addressLine1: data.address.addressLine1,
				addressLine2: data.address.addressLine2,
				pincode: data.address.pincode,
				latitude: data.address.lat,
				longitude: data.address.long,
				email: data.emailId,
			};
			return newState;
		});
		return result;
	},
	deseralizer: (state) => {
		const newState = {
			name: state.name,
			mobile: state.contact,
			fixedOTP: state.otp,
			emailId: state.email,
			address: {
				addressLine1: state.addressLine1,
				addressLine2: state.addressLine2,
				pincode: state.pincode,
				lat: state.latitude,
				long: state.longitude,
				// state: state.city.stateName,
				// city: state.city.cityName,
			},
		};
		return newState;
	},
	validate: (state) => {
		if (!state.name || state.name.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Franchisee Name Cannot be blank',
			});
			return false;
		}
		if (!state.contact || state.contact.length !== 10) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Franchisee Contact No. Cannot be blank',
			});
			return false;
		}
		const validRegex =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (!state.email.match(validRegex)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter A valid Email Id',
			});
			return false;
		}
		if (!state.otp || state.otp.length !== 4) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'OTP should be of length 4',
			});
			return false;
		}
		if (!state.latitude || !state.longitude) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a valid address',
			});
			return false;
		}
		if (!state.addressLine1 || !state.addressLine2) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'address line 1 / address line 2 cannot be blank ',
			});
			return false;
		}
		if (!state.pincode || state.pincode.length !== 6) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Pincode should be of length 6',
			});
			return false;
		}
		return true;
	},
};
export default addFranchiseeConfig;
