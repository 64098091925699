import { Switch } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import { inputHelper, isEmpty } from '../../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Description',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMS = [
	{
		title: 'Discount',
		config: {
			type: 'UPDATE',
		},
	},
];
const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	// {
	// title: 'Delete',
	// config: {
	// type: 'DELETE',
	// },
	// },
];

const COLMAP = {
	Name: 'menuName',
	Description: 'menuDescription',
	Discount: 'isDiscountEnable',
};

const initialState = [];

const MasterMenuListPage = (props) => {
	const { fetchMenus, data, query, toggleDiscount, deleteMenu } = props;

	const [state, setState] = useState(initialState);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	useEffect(() => {
		fetchMenus({
			entity: 'MASTER_MENUS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(data);
		}
	}, [data]);

	const handleDiscountEnable = async (e) => {
		try {
			const { fieldName, fieldValue, dataset } = inputHelper(e);
			const clonedState = cloneDeep(state);

			const foundIndex = data.findIndex((obj) => {
				return obj.id === parseInt(dataset.id, 10);
			});
			clonedState[foundIndex][fieldName] = fieldValue;

			const res = await toggleDiscount({
				entity: 'MASTER_MENUS',
				req: {
					params: {
						id: dataset.id,
					},
					data: {
						isDiscountEnable: fieldValue,
					},
				},
				method: 'PATCH',
				promisify: true,
			});
			if (res.status === 'Success') {
				setNumberOfSubmissions(numberOfSubmissions + 1);
				setState(clonedState);
			}
		} catch {
			//
		}
	};

	const handleDelete = ({ id }) => {
		deleteMenu({
			entity: 'MASTER_MENUS',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	const SPECIAL_ITEMS = {
		Discount: (id, isEnabled) => {
			return (
				<Switch
					name='isDiscountEnable'
					value='isDiscountEnable'
					size='small'
					color='primary'
					checked={isEnabled}
					onChange={handleDiscountEnable}
					inputProps={{
						'data-id': id,
						'data-type': 'MASTER_MENUS',
					}}
				/>
			);
		},
	};
	return (
		<div className='list-page-root'>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={state
					.filter((menu) => {
						return (
							menu.menuName
								.toLowerCase()
								.indexOf(query.toLowerCase()) !== -1
						);
					})
					.map((menu) => {
						return {
							...menu,
							_name: menu?.[COLMAP?.Name],
						};
					})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MASTER_MENUS.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchMenus: formActions.gateway,
	toggleDiscount: formActions.gateway,
	deleteMenu: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterMenuListPage);
