// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import analyticsAction from '../../../../Actions/analytics.actions';
import analyticsTypeConstant from '../../../../Constants/analyticsType.contants';
import { STAT_COLORS } from '../../../../Styles/colors';
import { QualityStatisticalGraph } from '../QualityStatisticalGraph';
import { StatisticalJumbotron } from '../StatisticalJumbotron';

const QualityStatisticalAnalysis = (props) => {
	const { metrics, graphData, boxData, products, setAnalyticsType } = props;

	const [showItem, setShowItem] = useState({});
	const [legendColor, setLegendColor] = useState({});

	useEffect(() => {
		setAnalyticsType(
			analyticsTypeConstant.QUALITY_STATISTICAL_ANALYTICS,
			{},
			false
		);
	}, []);

	const amountData = useMemo(() => {
		return [
			{
				caption: `(OVERALL) - ${boxData?.[0]?.passed ?? '-'}/${
					boxData?.[0]?.total ?? '-'
				}`,
				amount: `${boxData?.[0]?.pass_percent ?? '-'}%`,
			},
			{
				caption: `(BRAND) - ${boxData?.[1]?.passed ?? '-'}/${
					boxData?.[1]?.total ?? '-'
				}`,
				amount: `${boxData?.[1]?.pass_percent ?? '-'}%`,
			},
		];
	}, [boxData]);

	const tableData = useMemo(() => {
		return Object.keys(metrics).map((productId, idx) => {
			const productMetrics = metrics?.[productId] ?? {};
			const ids = productId.split(':');
			setLegendColor((ledColor) => {
				return {
					...ledColor,
					[productId]: STAT_COLORS[idx],
				};
			});
			return {
				show: showItem[productId] ?? true,
				id: productId,
				...productMetrics,
				legend: STAT_COLORS[idx],
				productName: `${products?.[ids[0]]?.productName} ${products?.[ids[0]]?.ProductVariant?.[ids[1]]?.displayName}` ?? '',
			};
		});
	}, [metrics, showItem]);

	const statisticalGraphData = useMemo(() => {
		return {
			labels: graphData?.x ?? [],
			datasets: Object.keys(graphData?.y ?? {}).reduce(
				(acc, productId, idx) => {
					if (showItem[productId] ?? true) {
						const data = graphData?.y?.[productId] ?? [];
						acc.push({
							label: products?.[productId]?.productName ?? ' ',
							data,
							fill: false,
							borderColor: STAT_COLORS[idx],
							tension: 0.1,
							productId,
						});
					}
					return acc;
				},
				[]
			),
		};
	}, [graphData, showItem]);

	const toggleShowItem = (id) => {
		setShowItem({
			...showItem,
			[id]: !(showItem?.[id] ?? true),
		});
	};

	const statisticalGraphAnnotations = useMemo(() => {
		const datasetLength = statisticalGraphData?.datasets?.length;
		let data = {};
		for (let i = 0; i < datasetLength; i++) {
			const productId = statisticalGraphData?.datasets?.[i]?.productId;
			const productMetrics = metrics?.[productId] ?? {};
			const newData = {
				[`${i}l`]: {
					type: 'line',
					value: productMetrics?.lowerLimit ?? 0,
					endValue: productMetrics?.lowerLimit ?? 0,
					scaleID: 'x',
					borderColor: legendColor?.[productId],
					borderWidth: 4,
					label: {
						position: 'start',
						content: `LSL: ${productMetrics?.lowerLimit ?? ''}`,
						enabled: true,
					},
				},
				[`${i}u`]: {
					type: 'line',
					value: productMetrics?.upperLimit ?? 0,
					endValue: productMetrics?.upperLimit ?? 0,
					scaleID: 'x',
					borderColor: legendColor?.[productId],
					borderWidth: 4,
					label: {
						position: 'start',
						content: `USL: ${productMetrics?.upperLimit ?? ''}`,
						enabled: true,
					},
				},
			};
			data = {
				...data, ...newData,
			};
		}
		return data;
	}, [statisticalGraphData, metrics, legendColor]);

	return (
		<>
			<StatisticalJumbotron
				boxData={amountData}
				tableData={tableData}
				toggleShowItem={toggleShowItem}
			/>
			<QualityStatisticalGraph
				graphData={statisticalGraphData}
				graphAnnotations={statisticalGraphAnnotations}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		boxData = [],
		metrics = {},
		graphData = {},
	} = state.analytics.QC_STATISTICAL_ANALYSIS;

	const { products } = state.product;

	return {
		boxData,
		metrics,
		graphData,
		products,
	};
};

const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityStatisticalAnalysis);
