import { useEffect } from 'react';
import { Label } from '@/Components/ui/label';
import {
	Tabs, TabsList, TabsTrigger
} from '@/Components/ui/tabs';

const TabFilter = (props) => {
	const { filterData, filterProps, state, setState = () => {} } = props;
	const {
		compData = [],
		dependentOn = -1,
		label = '',
		selectedValueKey,
		onDependencyUpdate = () => {},
		disabled = false,
		compDataFromProp,
	} = filterProps;
	const dependency = filterData?.[dependentOn];
	const isDisabled =
		(dependentOn !== -1 && !state?.[dependency?.selectedValueKey]) ||
		disabled;
	const selectedValue = state?.[selectedValueKey];

	useEffect(() => {
		onDependencyUpdate(state?.[dependency?.selectedValueKey]);
	}, [state?.[dependency?.selectedValueKey]]);

	const handleSelect = (value) => {
		if (isDisabled) return;
		setState((prev) => {
			return {
				...prev,
				[selectedValueKey]: value,
			};
		});
	};

	return (
		<div className='flex flex-col gap-1'>
			<Label className={`${filterProps?.mandatory ? 'text-red-500' : ''}`}>
				{label}
				{filterProps?.mandatory ? '*' : ''}
			</Label>
			<Tabs
				value={selectedValue}
				onValueChange={handleSelect}
				defaultValue={selectedValue}
			>
				<TabsList className=''>
					{(compDataFromProp ?? compData)?.map((ele) => {
						return (
							<TabsTrigger
								className='data-[state=active]:bg-uy-color'
								value={ele?.value}
							>
								{ele?.label}
							</TabsTrigger>
						);
					})}
				</TabsList>
			</Tabs>
		</div>
	);
};

export default TabFilter;
