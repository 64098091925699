// eslint-disable-next-line object-curly-newline
import _ from 'lodash';
import { useEffect, useState } from 'react';
// import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BrandsConfig from './Brands.config';
import { formActions } from '../../Actions';
import { FormSectionHeader } from '../../Components/Forms';
import { GeneralDetails } from '../../Components/Forms/Brands/GeneralDetails';
// eslint-disable-next-line object-curly-newline
import { inputHelper, imageToBase64, isEmpty } from '../../Helpers';
import { CreatePageHeader } from '../../Components/Headers';
import { urls } from '../../Constants';
import { notificationServices } from '../../Services';
import ServiceEnabled from '../../Components/Forms/Brands/Service Enabled/ServiceEnabled';

const BrandCreatePage = (props) => {
	const { isEdit, saveBrand, data, fetchBrand, breadcrumbs } = props;

	const [state, setState] = useState({
		...BrandsConfig.initialState,
	});

	const { fields, errors } = state;
	const BrandId = useParams().id;
	const history = useHistory();
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === BrandId) {
				setState(BrandsConfig.serializer(state, data));
			} else {
				fetchBrand({
					entity: 'BRANDS',
					req: {
						params: {
							id: BrandId,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				setState(BrandsConfig.serializer(state, data));
			}
		}
	}, [data]);
	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};

	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};

	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'image':
			case 'brandImageUrl':
			case 'tradeMarkCertificateUrl':
			case 'trademark':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.BRANDS}/view/${BrandId}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.BRANDS}`);
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.data = BrandsConfig.deserializer(state);
		if (req.data.brandImageUrl && req.data.tradeMarkCertificateUrl) {
			Promise.all([
				imageToBase64(req.data.brandImageUrl),
				imageToBase64(req.data.tradeMarkCertificateUrl),
			]).then((values) => {
				[req.data.brandImageUrl, req.data.tradeMarkCertificateUrl] =
					values;
				setNumberOfSubmissions(numberOfSubmissions + 1);
				if (isEdit) {
					req.params = {
						id: BrandId,
					};
					saveBrand({
						entity: 'BRANDS',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.BRANDS}`,
					});
				} else {
					saveBrand({
						entity: 'BRANDS',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.BRANDS}`,
					});
				}
			});
		} else if (!req.data.brandImageUrl) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Upload Brand Image before submission',
			});
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message:
					'Please Upload Trademark certificate Image before submission',
			});
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Brand' : 'Create Brand'}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit ? `Edit ${fields.name}` : 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<GeneralDetails
						mandatoryFields={BrandsConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						handleImageInput={handleImageInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
				{/* Service Enabled */}
				<FormSectionHeader sectionName='Services Enabled'>
					<ServiceEnabled
						mandatoryFields={BrandsConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.BRANDS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchBrand: formActions.gateway,
	saveBrand: formActions.gateway,
};

BrandCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandCreatePage);
