/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { ArrowCircleRight, Today } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getISOWeek, getWeekOfMonth } from 'date-fns';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { analyticsAction, formActions } from '../../../Actions';
import DateRangePickerRsuit from '../../../Components/DateRangePickerRsuit/DateRangePickerRsuit';
import { BackdropLoader } from '../../../Components/Loaders';
import { timeFilterMap } from '../../../Constants';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
import { classes } from '../../../Helpers';
import styles from './Dashboard.module.css';
import DataTileContainer from './DataTileContainer/DataTileContainer';
// import TwoColumnCharts from './TwoColumnCharts/TwoColumnCharts';
import SKUTileContainer from './SKUTileContainer/SKUTileContainer';
import aiModelCreation from '../../../Services/Form Services/aiModelCreation.services';
import TwoColumnCharts from '../../Dashboard/TwoColumnCharts/TwoColumnCharts';

const localStorageProperty = {
	daily: 'TODAY',
	weekly: 'THIS_WEEK',
	monthly: 'THIS_MONTH',
	custom: 'CUSTOM',
};
function setLocalStorage(str) {
	localStorage.setItem('DASHBOARD_DATERANGE_STATE', str);
}
function getLocalStorage(str) {
	return localStorage.getItem('DASHBOARD_DATERANGE_STATE');
}

let analyticType = analyticsTypeConstant.AI_WORKFLOW_ANALYTICS;

function Dashboard(props) {
	const {
		applyFilters,
		setAnalyticsType,
		fetchProducts,
		dateRangeFilter,
		setDateRangeFilter,
		productsList,
		data,
		noStoreForAiDashboardRoute,
		accuracy,
		annotations,
		isLoading,
		fetchDashboardData,
		stores,
		storeFilter,
		setStoreFilter,
	} = props;
	const [periodFilter, setPeriodFilter] = useState(() => {
		return getLocalStorage() ? getLocalStorage() : 'TODAY';
	});
	const [activeDateClick, setActiveDateClick] = useState(false);

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					brandId: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			setDateRangeFilter({
				...dateRangeFilter,
				active: true,
			});
		};
	}, []);

	const handlePeriodFilterChange = (fieldValue) => {
		let tempFilters = null;
		setDateRangeFilter({
			...dateRangeFilter,
			active: false,
		});
		switch (fieldValue) {
			case 'TODAY':
				setLocalStorage(localStorageProperty.daily);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getDate(),
						active: true,
						type: 'day',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: getWeekOfMonth(new Date()),
							day: new Date().getDate(),
							hour: null,
						},
					},
				};
				break;
			case 'THIS_YEAR':
				setLocalStorage(localStorageProperty.daily);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getFullYear(),
						active: true,
						type: 'year',
						selection: {
							year: new Date().getFullYear(),
							month: null,
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_WEEK':
				setLocalStorage(localStorageProperty.weekly);
				tempFilters = {
					DATE_FILTER: {
						text: new Date().getFullYear(),
						active: true,
						type: 'week',
						selection: {
							year: new Date().getFullYear(),
							month: null,
							week: getISOWeek(new Date()),
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'THIS_MONTH':
				setLocalStorage(localStorageProperty.monthly);
				tempFilters = {
					DATE_FILTER: {
						text: timeFilterMap.month[new Date().getUTCMonth()],
						active: true,
						type: 'month',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			case 'CUSTOM':
				setLocalStorage(localStorageProperty.custom);
				setDateRangeFilter({
					...dateRangeFilter,
					active: true,
				});
				setActiveDateClick(false);
				tempFilters = {
					DATE_FILTER: {
						text: timeFilterMap.month[new Date().getUTCMonth()],
						active: false,
						type: 'month',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							week: null,
							day: null,
							hour: null,
						},
					},
				};
				break;
			default:
				break;
		}
		setPeriodFilter(fieldValue);
		if (tempFilters) {
			applyFilters(tempFilters);
		}
	};

	useEffect(() => {
		if (noStoreForAiDashboardRoute) {
			analyticType = analyticsTypeConstant.AI_DASHBOARD_ANALYTICS;
		}
		setAnalyticsType(analyticType);
		setStoreFilter({
			active: true,
			selectedStores: [],
		});
		handlePeriodFilterChange(periodFilter);
	}, []);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		setActiveDateClick(true);
	};

	const fetchDateData = () => {
		fetchDashboardData();
		setActiveDateClick(false);
	};

	const handleStoreSelection = (id) => {
		if (id === 'all') {
			setStoreFilter({
				active: true,
				selectedStores: [],
			});
		} else {
			setStoreFilter({
				active: true,
				selectedStores: [id],
			});
		}
		fetchDashboardData();
	};

	return (
		<div className={classes(styles.root)}>
			<BackdropLoader showProgress isLoading={isLoading} />
			<div className={styles.tabs}>
				<div className={styles['date-tabs']}>
					<div
						className={classes(
							styles['link-button'],
							periodFilter === 'TODAY' && styles.selected
						)}
						style={
							periodFilter === 'TODAY'
								? {
									borderColor: '#ced4da',
								}
								: {}
						}
						onClick={() => {
							return handlePeriodFilterChange('TODAY');
						}}
					>
						<Today />
						DAILY
					</div>
					<div
						style={
							periodFilter === 'THIS_WEEK'
								? {
									borderColor: '#cbc0d3',
								}
								: {}
						}
						className={classes(
							styles['link-button'],
							periodFilter === 'THIS_WEEK' && styles.selected
						)}
						onClick={() => {
							return handlePeriodFilterChange('THIS_WEEK');
						}}
					>
						WEEKLY STATS
					</div>
					<div
						style={
							periodFilter === 'THIS_MONTH'
								? {
									borderColor: '#F8F4EA',
								}
								: {}
						}
						className={classes(
							styles['link-button'],
							periodFilter === 'THIS_MONTH' && styles.selected
						)}
						onClick={() => {
							return handlePeriodFilterChange('THIS_MONTH');
						}}
					>
						MONTHLY STATS
					</div>
					<div
						style={
							periodFilter === 'CUSTOM'
								? {
									borderColor: '#c3e7ff',
								}
								: {}
						}
						className={classes(
							styles['link-button'],
							periodFilter === 'CUSTOM' && styles.selected
						)}
						onClick={() => {
							return handlePeriodFilterChange('CUSTOM');
						}}
					>
						CUSTOM
					</div>
				</div>
				<div
					className={classes(
						styles.dateRangeDiv,
						periodFilter === 'CUSTOM' && styles.visibleAnimation
					)}
				>
					<DateRangePickerRsuit
						MAX={30}
						dateRanges={dateRangeFilterArray}
						onDateRangeChange={setDate}
						enableOnlyBackwardSelection
					/>
					<IconButton
						disabled={!activeDateClick}
						onClick={fetchDateData}
						className={classes(
							styles.dateButton,
							activeDateClick
								? styles.allowClick
								: styles.disableClick
						)}
					>
						<ArrowCircleRight fontSize='2rem' />
					</IconButton>
				</div>
			</div>
			<div
				className={classes(
					styles['data-box-container'],
					styles[periodFilter]
				)}
			>
				{stores?.length > 1 && !noStoreForAiDashboardRoute && (
					<div className={styles?.stores}>
						<div
							onClick={() => {
								return handleStoreSelection('all');
							}}
							className={classes(
								styles.store,
								storeFilter?.selectedStores?.length <= 0 &&
									styles.active
							)}
						>
							All
						</div>
						{stores?.map((store) => {
							const isSelected =
								storeFilter?.selectedStores?.includes(
									store?.id
								);
							return (
								<div
									onClick={() => {
										return handleStoreSelection(store?.id);
									}}
									className={classes(
										styles.store,
										isSelected && styles.active
									)}
								>
									{store?.name}
								</div>
							);
						})}
					</div>
				)}
				{data?.statsHolder?.map((ele) => {
					return (
						<DataTileContainer data={ele} />
					);
				})}
				{data?.workflowHolder?.map((ele) => {
					return (
						ele?.workflow?.length > 0 ? (<SKUTileContainer data={ele} />) : (<></>)
					);
				})}
				{data?.chartsHolder?.map((ele) => {
					return <TwoColumnCharts productsList={productsList} data={ele} />;
				})}
			</div>
		</div>
	);
}

const mapStateToProps = (state, componentProps) => {
	const { noStoreForAiDashboardRoute } = componentProps;
	const { data, isLoading } = noStoreForAiDashboardRoute
		? state?.analytics.AI_DASHBOARD_ANALYTICS
		: state.analytics.DASHBOARD_ANALYTICS;
	const productsList = state.form.ALL_PRODUCTS.data.LIST;
	const stores = state.form.STORES.data.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter, STORE_FILTER: storeFilter } =
		state.analytics;

	let filteredArray = data;
	let accuracy = 0;
	let annotations = 0;
	if (data?.statsHolder?.length > 1 &&
		data?.statsHolder[1]?.stats?.length > 0 &&
		data?.statsHolder[1]?.stats[0]?.stats?.length > 0) {
		annotations = data?.statsHolder[1]?.stats[0]?.stats[0]?.value;
	}
	if (data?.statsHolder && data?.statsHolder.length > 0) {
		filteredArray = data?.statsHolder[0]?.stats?.filter((item) => {
			return item?.heading?.includes('Detection') || item?.heading?.includes('Accuracy');
		});
		accuracy = filteredArray?.filter((item) => {
			return item?.heading?.includes('AI Accuracy');
		});
		accuracy = accuracy.length > 0 ? accuracy[0].value : 0;
		data.statsHolder[0].stats = filteredArray;
		if (data.statsHolder.length > 1) {
			data.statsHolder.pop();
		}
	}

	filteredArray = data;
	if (data?.chartsHolder && data?.chartsHolder.length > 0) {
		for (let index = 0; index < data?.chartsHolder.length; index++) {
			filteredArray = data?.chartsHolder[index]?.charts?.filter((item) => {
				return item?.heading?.includes('Detection') || item?.heading?.includes('Accuracy') || item?.heading?.includes('accuracy');
			});
			data.chartsHolder[index].charts = filteredArray;
		}
	}

	// if (annotations !== 0) {
	// if ((accuracy > 90 && annotations > parseInt((100 - accuracy) / 2, 10)) ||
	// (accuracy <= 90 && annotations > parseInt(100 - accuracy, 10))) {
	// }
	// }

	return {
		data,
		isLoading,
		productsList,
		dateRangeFilter,
		stores,
		storeFilter,
	};
};

const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	applyFilters: analyticsAction.applyFilters,
	resetFilters: analyticsAction.resetFilters,
	fetchProducts: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchDashboardData: analyticsAction.fetchData,
	setStoreFilter: analyticsAction.setStoreFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
