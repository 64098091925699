/* eslint-disable object-curly-newline */
import { useRouteMatch } from 'react-router';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormSectionHeader } from '../../../Components/Forms';
import { history, inputHelper, isEmpty } from '../../../Helpers';
import styles from './NutritionalEdit.module.scss';
import { urls } from '../../../Constants';
import { CreatePageHeader } from '../../../Components/Headers';
import {
	NutritionalDetails,
	GeneralDetails,
} from '../../../Components/Forms/Nutrition';
import nutritionalConfig from './nutritional.config';
import { formActions } from '../../../Actions';

const NutritionEditPage = (props) => {
	const { fetchProductNutrition, data, saveProductNutrition, breadcrumbs } = props;
	const [state, setState] = useState({
		...nutritionalConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const handleInput = (e) => {
		const { fieldValue, fieldName } = inputHelper(e);
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return fieldValue;
			})
		);
	};
	const { id } = useRouteMatch().params;
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		req.params = {
			id,
		};
		req.data = nutritionalConfig.serializer(state).fields;
		setNumberOfSubmissions(numberOfSubmissions + 1);
		saveProductNutrition({
			entity: 'NUTRITIONAL_INFO',
			req,
			method: 'UPDATE',
			redirect: `${urls.EXTENSION}${urls.NUTRITIONAL_INFO}`,
		});
	};
	useEffect(() => {
		fetchProductNutrition({
			entity: 'NUTRITIONAL_INFO',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, []);
	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(nutritionalConfig.deserializer(state, data));
		}
	}, [data]);
	const handleCancel = () => {
		history.push(`${urls.EXTENSION}${urls.NUTRITIONAL_INFO}/view/${id}`);
	};
	return (
		<div className={`${styles.root}`}>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Edit Nutritional Info'
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: `Edit ${state.fields.name}`,
						},
					]}
					showBreadcrumbs
				/>

				<FormSectionHeader
					tooltip='General Details'
					sectionName='Product Details'
				>
					<GeneralDetails fields={state.fields} />
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Nutritional Details'
					sectionName='Nutritional Details'
				>
					<NutritionalDetails
						fields={state.fields}
						handleInput={handleInput}
						mandatoryFields={nutritionalConfig.mandatoryFields}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.form.NUTRITIONAL_INFO.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchProductNutrition: formActions.gateway,
	saveProductNutrition: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(NutritionEditPage);
