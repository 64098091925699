import { useState } from 'react';

function useSearch(initQuery, initSearchBy = 0) {
	const [query, setQuery] = useState(initQuery ?? '');
	const [searchBy, setSearchBy] = useState(initSearchBy ?? '');

	const handleSearch = (queryString) => {
		setQuery(queryString);
	};

	const clearSearch = () => {
		setQuery('');
	};

	const handleSearchBy = (newField) => {
		setSearchBy(newField);
	};

	return {
		query,
		handleSearch,
		clearSearch,
		searchBy,
		handleSearchBy,
	};
}
export default useSearch;
