import { useState } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { inputHelper } from '../../../Helpers';
import { notificationServices } from '../../../Services/Notification';
// import { classes } from '../../../Helpers';
import { BaseModal } from '../BaseModal';
import styles from './ChangePasswordModal.module.scss';
import { Button } from '@/Components/ui/button';
import { Input } from '@/Components/ui/input';
import { Label } from '@/Components/ui/label';

const ChangePasswordModal = (props) => {
	const { show, toggleModal } = props;
	const [state, setState] = useState({
		currentPassword: '',
		newPassword: '',
		confirmationPassword: '',
	});

	const handleClose = () => {
		toggleModal(false);
	};

	const handleCancel = () => {
		handleClose();
	};

	const handleConfirm = () => {
		if (!state?.currentPassword) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Current Password is mandatory',
			});
			return;
		}
		if (!state?.newPassword) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'New Password is mandatory',
			});
			return;
		}
		if (!state?.confirmationPassword) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please confirm password',
			});
			return;
		}
		if (state?.newPassword !== state?.confirmationPassword) {
			notificationServices?.generateNotification({
				type: 'error',
				message: "Password and Confirm password doesn't match",
			});
			return;
		}
		props?.handleConfirm(state);
		setState({
			currentPassword: '',
			newPassword: '',
			confirmationPassword: '',
		});
	};

	const renderBtns = () => {
		return (
			<div className={`${styles.footer}`}>
				<Button
					variant='secondary'
					// color='secondary'
					className='flex-1'
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button
					className='primary-btn flex-1'
					onClick={handleConfirm}
				>
					Submit
				</Button>
			</div>
		);
	};

	const handleChange = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setState({
			...state,
			[fieldName]: fieldValue,
		});
	};

	return (
		<BaseModal
			open={show}
			heading='Change Password'
			handleClose={handleCancel}
			// height='30rem'
			// width='35rem'
			customStyles={{
				width: '40rem',
				height: '20rem',
				bottom: '50%',
			}}
			handleConfirm={handleConfirm}
			renderBtns={renderBtns}
		>
			<div className='flex flex-col gap-2 py-2 px-1'>
				<div className='flex justify-start items-center'>
					<Label
						className='text-left min-w-[150px]'
						// htmlFor={id}
					>
						Current Password*
					</Label>
					<Input
						type='password'
						name='currentPassword'
						value={state?.currentPassword}
						onChange={handleChange}
						placeholder='Enter Current Password'
					/>
				</div>
				<div className='flex justify-start items-center'>
					<Label
						className='text-left min-w-[150px]'
						// htmlFor={id}
					>
						New Password*
					</Label>
					<Input
						type='password'
						name='newPassword'
						value={state?.newPassword}
						onChange={handleChange}
						placeholder='Enter New Password'
					/>
				</div>
				<div className='flex justify-start items-center'>
					<Label
						className='text-left min-w-[150px]'
						// htmlFor={id}
					>
						Confirm New Password*
					</Label>
					<Input
						type='password'
						name='confirmationPassword'
						value={state?.confirmationPassword}
						onChange={handleChange}
						placeholder='Enter Confirm Password'
					/>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.changePasswordModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleChangePasswordModal(show, data));
		},
	};
};

ChangePasswordModal.defaultProps = {
	handleConfirm: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
