import { useRouteMatch } from 'react-router';
import CRUDRoutes from '../CRUD Routes/CRUD.routes';
import { urls } from '../../Constants';
import { AddAsset, ListAsset } from '../../Pages';

function CrudAssetRoutes({ props }) {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.ASSET_CATEGORY}`,
			name: 'Asset Management',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='ASSET CATEGORY'
			path={path}
			listPage={ListAsset}
			// listFilter={<ProductListFilters />}
			// viewPage={<ListAsset breadcrumbs={breadcrumbs} />}
			editPage={<AddAsset isEdit breadcrumbs={breadcrumbs} />}
			createPage={<AddAsset breadcrumbs={breadcrumbs} />}
		/>
	);
}

export default CrudAssetRoutes;
