/* eslint-disable no-unused-vars */
// import { get } from 'lodash';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import moment from 'moment';
import { ListTable } from '../../../Components/ListTable';
import { classes, convertWeightUnit } from '../../../Helpers';
// import { urls } from '../../../Constants';
import styles from './CookingAnalysisListPage.module.scss';
import analyticsAction from '../../../Actions/analytics.actions';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
import { urls } from '../../../Constants';
import cookingAnalyticsServices from '../../../Services/Analytics Services/cookingAnalytics.services';
import { BackdropLoader } from '../../../Components/Loaders';
import { notificationServices } from '../../../Services';
import { useAuth } from '../../../Components/Auth/Login/auth';

const ANALYTICS_TYPE = analyticsTypeConstant.COOKING_HISTORY_LIST_DATA;

const COLMAP = {
	'Order Id': 'orderId',
	Date: 'startedAt',
	Started: 'startedAt',
	Completed: 'completedAt',
	'Product Name': 'productName',
	// Status: 'batchStatus',
};

const TABLE_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const getRowStyles = (item) => {
	switch (item?.overridden ?? false) {
		case 1:
			return styles.critical;
		case false:
			if (item?.isCompleted) {
				return styles.normal;
			}
			return styles.white;
		default:
			return styles.white;
	}
};

const SPECIAL_COLUMNS = [
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Scheduled Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Overridden Steps',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Input Material',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expected yield',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Actual Yield',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Under weight total',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Over weight total',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Preparation Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Started',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Completed',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Duration',
		config: {
			type: 'DATA',
		},
	},
];

const CookingAnalysisListPage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const history = useHistory();

	const { data, setAnalyticsType, userPermissions, fetchDataAfterSetting } = props;
	const [loading, setLoading] = useState(false);

	const moveToQc = useCallback(
		async (recipeId) => {
			try {
				setLoading(true);
				const resp =
					await cookingAnalyticsServices.fetchCookingYieldQcs({
						recipeId,
					});
				if (resp?.data?.[recipeId]?.length === 0) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'No Yield Qc data found',
					});
				} else {
					const qcIdsString = resp?.data?.[recipeId]?.join(',');
					const path = `${
						urls.EXTENSION + urls.QC_HISTORY_LIST
					}?s_no_filter=${qcIdsString}`;
					window.open(path);
				}
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error ?? error?.message,
				});
			} finally {
				setLoading(false);
			}
		},
		[setLoading]
	);
	const SPECIAL_ITEMS = {
		'Order Id': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			return item?.orderId ?? itemId;
		},
		'Product Name': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			return (
				<div
					style={{
						minWidth: '150px',
					}}
				>
					{item?.productName}
					{' '}
					{item?.recipeName ? `(${item?.recipeName})` : ''}
				</div>
			);
		},
		'Order Date': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			if (!item?.orderedAt) return '-';
			return moment(item?.orderedAt)?.format('DD-MMM-YYYY');
		},
		'Scheduled Date': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			if (!item?.scheduledAt) return '-';
			return moment(item?.scheduledAt)?.format('DD-MMM-YYYY');
		},
		'Overridden Steps': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			const totalSteps = item?.ingredients?.length;
			if (!item?.isCompleted) {
				return (
					<div
						style={{
							fontSize: '.8rem',
						}}
					>
						--
					</div>
				);
			}
			return (
				<>
					{item?.overriddenSteps}
					/
					{totalSteps}
				</>
			);
		},
		'Under weight total': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			let underWeight = 0;
			let underWeightStepCount = 0;
			item?.ingredients?.forEach((ele) => {
				if (ele?.consumedWeight) {
					if (ele?.minWeight - ele?.consumedWeight > 0) {
						underWeightStepCount++;
						underWeight += ele?.minWeight - ele?.consumedWeight;
					}
				}
			});
			if (underWeightStepCount === 0) {
				return (
					<div
						style={{
							fontSize: '.8rem',
						}}
					>
						No underweight
					</div>
				);
			}
			return (
				<>
					{convertWeightUnit(underWeight, measurement === 'US' ? 'oz' : 'gram')}
					{' '}
					<br />
					{' '}
					{underWeightStepCount}
					{' '}
					steps
				</>
			);
		},
		'Over weight total': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			let overWeight = 0;
			let overWeightStepCount = 0;
			item?.ingredients?.forEach((ele) => {
				if (ele?.consumedWeight) {
					if (ele?.maxWeight - ele?.consumedWeight < 0) {
						overWeightStepCount++;
						overWeight += ele?.consumedWeight - ele?.maxWeight;
					}
				}
			});
			if (overWeightStepCount === 0) {
				return (
					<div
						style={{
							fontSize: '.8rem',
						}}
					>
						No overWeight
					</div>
				);
			}
			return (
				<>
					{convertWeightUnit(overWeight, measurement === 'US' ? 'oz' : 'gram')}
					{' '}
					<br />
					{' '}
					{overWeightStepCount}
					{' '}
					steps
				</>
			);
		},
		'Expected yield': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});

			return item?.batchSize;
		},
		'Input Material': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			const totalData = item?.ingredients?.reduce((prev, curr) => {
				return prev + curr?.consumedWeight;
			}, 0);
			return convertWeightUnit(totalData, measurement === 'US' ? 'oz' : 'gram');
		},
		'Actual Yield': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			let totalConsumedWeight = 0;
			if (!item?.isCompleted) {
				return (
					<div
						style={{
							fontSize: '.8rem',
						}}
					>
						--
					</div>
				);
			}
			item?.ingredients?.forEach((ele) => {
				if (ele?.consumedWeight) {
					totalConsumedWeight += ele?.consumedWeight;
				}
			});
			const percentageVal = Number(
				(item?.yieldWeight * 100) / totalConsumedWeight
			);
			return (
				<div
					style={{
						display: 'flex',
						width: '6rem',
						cursor: item?.isCompleted ? 'pointer' : 'auto',
						flexDirection: 'column',
					}}
					onClick={() => {
						if (!item?.isCompleted || item?.yieldWeight === 0) {
							return;
						}
						moveToQc(item?.id);
					}}
				>
					<div
						style={{
							fontSize: '.8rem',
						}}
					>
						{convertWeightUnit(
							item?.yieldWeight,
							measurement === 'US' ? 'oz' : 'gram'
						)}
					</div>
					<div>
						{percentageVal?.toFixed(2)}
						%
					</div>
					{item?.isCompleted && item?.yieldWeight > 0 && (
						<div
							style={{
								padding: '2px 5px',
								borderRadius: '.4rem',
								background: '#00f6',
								color: 'white',
							}}
						>
							View Scans
						</div>
					)}
				</div>
			);
		},
		'Preparation Date': (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			if (!item?.startedAt) return '-';
			return moment(item?.startedAt)?.format('DD-MMM-YYYY');
		},
		Started: (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			if (!item?.startedAt) return '-';
			const date = item?.startedAt?.split(' GMT')[0];
			return moment(date)?.format('hh:mm a');
		},
		Completed: (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			if (!item?.completedAt) return '-';
			const date = item?.completedAt?.split(' GMT')[0];
			return moment(date)?.format('hh:mm a');
		},
		Duration: (itemId) => {
			const item = data?.find((ele) => {
				return ele?.id === itemId;
			});
			if (!item?.completedAt) return '-';
			const started = item?.startedAt?.split(' GMT')[0];
			const completed = item?.completedAt?.split(' GMT')[0];
			return `${moment(completed)?.diff(
				moment(started),
				'minutes'
			)} minutes`;
		},
	};

	const handleViewClick = (item) => {
		history.push(
			{
				pathname: `${urls.EXTENSION}${urls.PAST_QC}/${item?.productId}`,
				search: `id=${item?.id}&type=CMS`,
				state: {
					from: history?.location?.pathname,
				},
			}
		);
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<BackdropLoader showProgress isLoading={loading} />
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				hasDateRange
				showTotal
				special={SPECIAL_ITEMS}
				data={
					data &&
					data.map((obj) => {
						const rowStyles = getRowStyles(obj);
						// const itemId = get(obj, COLMAP['Item Id']);
						// const qc = get(obj, COLMAP.qc);
						return {
							...obj,
							// recipeId: obj?.id,
							// id: idx + 1,
							rowStyles,
							// onClick: () => {
							// history.push(
							// `${urls.EXTENSION}${urls.PAST_QC}/${itemId}?id=${qc}`
							// );
							// },
						};
					})
				}
				columns={[...TABLE_COLUMNS]}
				actions={
					!userPermissions?.CONFIDENTIAL
						? [...SPECIAL_COLUMNS]
						: [...SPECIAL_COLUMNS, ...ACTION_COLUMNS]
				}
				handleViewClick={handleViewClick}
				allowExport={false}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingAnalysisListPage);
