import { Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import styles from './TimeSlots.module.scss';
import AddTimeSlot from './AddTimeSlot/AddTimeSlot';

const TimeSlots = (props) => {
	const {
		slots,
		limit,
		handleAddNewSlot,
		dataAttrs,
		isDisabled,
		handleDelete,
	} = props;
	return (
		<div className={styles.root}>
			{slots.map((slot, idx) => {
				const { startTime, endTime } = slot;
				return (
					<div className={`${styles['time-container']}`}>
						{!isDisabled && (
							<Tooltip
								title='Delete Time Slot'
								placement='right-end'
							>
								<Cancel
									className={`${styles['delete-icon']}`}
									onClick={handleDelete}
									data-confirm={idx}
									{...dataAttrs}
								/>
							</Tooltip>
						)}
						<p className={styles.heading}>{`Slot ${idx + 1}`}</p>
						<p className={`${styles['time-slot']}`}>
							<span>{startTime}</span>
							&nbsp; to &nbsp;
							<span>{endTime}</span>
						</p>
					</div>
				);
			})}
			{slots.length < limit && (
				<AddTimeSlot
					handleClick={handleAddNewSlot}
					dataAttrs={dataAttrs}
					isDisabled={isDisabled}
				/>
			)}
		</div>
	);
};

TimeSlots.defaultProps = {
	limit: 3,
	isDisabled: false,
};
export default TimeSlots;
