/* eslint-disable */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
// import DownloadIcon from '@mui/icons-material/Download';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import DatePicker from 'rsuite/DatePicker';
import { EditIcon } from 'lucide-react';
import {
	analyticsAction,
	formActions,
	modalActions,
} from '../../../../Actions';
import { ListTable } from '../../../../Components/ListTable';
import { POItemsModal } from '../../../../Components/Modals/POItemsModal';
import styles from './PurchaseOrderList.module.scss';
// import PurchaseOrderListHeader from './PurchaseOrderListHeader/PurchaseOrderListHeader';
import { VanImageModal } from '../../../../Components/Modals/VanImageModal';
import assetServices from '../../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../../Services';
import inventoryAnalyticsServices from '../../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal, ProductionPlanModal } from '../../../../Components/Modals';
import BackdropLoader from '../../../../Components/Loaders/BackdropLoader';
import { urls } from '../../../../Constants';
import {
	addCommas,
	classes,
	handleOrderStatus,
	handleResponseStreamDownload,
} from '../../../../Helpers';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import ExportFiltersDrawer from '@/Components/Drawers/ExportFiltersDrawer/ExportFiltersDrawer';
import orderAnalyticsServices from '@/Services/Analytics Services/orderAnalytics.services';
import drawerActions from '@/Actions/drawer.actions';
import DebitCreditListDrawer from '@/Components/Drawers/DebitCreditListDrawer/DebitCreditListDrawer';
import { Button } from '@/Components/ui/button';
import DatePickerModal from './DatePickerModal';
import { useAuth } from '@/Components/Auth/Login/auth'; 
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';
import AssetTransitModal from '@/Components/Modals/AssetTransitModal/AssetTransitModal';


const exportTypes = [
	{
		label: 'Invoice-Item wise Sales Register',
		value: 'invoiceItemWiseSales',
	},
	{
		label: 'Item-Wise GRN Fullfilment',
		value: 'itemWiseGrn',
	},
	{
		label: 'Item-Wise Sales Register',
		value: 'itemWiseSales',
	},
];

const TABLE_COLUMNS = [
	{
		title: 'Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client PO ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Invoice Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expected Dispatch Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Received Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expected Receive date',
		config: {
			type: 'DATA',
		},
	},

	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver To',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Deliver From',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Van Temp',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Transit Van Image',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Amount',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'Download',
		config: {
			type: 'VIEW',
		},
	},
	// {
	// title: 'View',
	// config: {
	// type: 'VIEW',
	// },
	// },
	{
		title: 'View More',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Action',
		config: {
			type: 'CANCEL',
		},
	},
	// {
	// title: 'MORE',
	// config: {
	// type: 'MORE',
	// },
	// },
];

const COLMAP = {
	Id: 'id',
	'Client PO ID': 'clientPoId',
	'Order Date': 'createdAt',
	'Invoice Date': 'invoicedAt',
	'Received Date': 'receivedAt',
	'Expected Dispatch Date': 'id',
	'Expected Receive date': 'id',
	'Deliver To': 'storeName',
	BASE: 'fromStoreName',
	'Van Temp': 'receivingTemp',
	'Transit Van Image': 'receivingTempImage',
	// 'Transit Van Temp': 'receivingTemp',
	'Order Status': 'orderStatus',
	Vendor: 'vendorName',
	Buyer: 'vendorName',
};

const orderStatus = [
	'PENDING',
	'DISPATCH',
	'TRANSIT',
	'UNLOADING',
	'VERIFICATION',
	'FULFILLED',
	'CANCELLED',
	'ABANDONED',
];

const FIND_FILTERS = ['store', 'dateRangeWithType', 'status', 'timeType'];
const dateTypeArr = [
	{
		label: 'Expected Dispatch Date',
		value: 'dispatchDate',
	},
	{
		label: 'Expected receive date',
		value: 'scheduled',
	},
	{
		label: 'Order Date',
		value: 'ordered',
	},
	{
		label: 'Invoice Date',
		value: 'invoiced',
	},
	{
		label: 'Received Date',
		value: 'received',
	},
];

function PurchaseOrderList(props) {
	const {  userConfig } = useAuth();
	const {
		data,
		type,
		toggleModal,
		toggleVanImageModal,
		toggleAlert,
		// toggleFileUploaderModal,
		toggleProductionPlanModal,
		navState,
		stores,
		fetchPurchaseOrderList,
		categoriesList,
		subCategoriesList,
		fetchSubCategories,
		toggleExportFiltersDrawer,
		toggleDebitCreditListDrawer,
		userList,
		fetchUserList,
		toggleAssetTransit,
	} = props;

	const isInternalSales = type === 'internalSales';
	const [update, setUpdate] = useState(0);
	const history = useHistory();
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const [datePickerModalState, setDatePickerModalState] = useState({
		show: false,
		heading: '',
		currentPo: null,
	});
	const [productionPlanLoading, setProductionPlanLoading] = useState(false);
	const exportFilters = useRef({});

	// userAcess
	useEffect(() => {
		fetchUserList();
	}, []);

	const map = new Map(userList.map((item) => [item.value, item.label]));
	const role = Object.keys(userConfig?.userRoles);
	const userRoles = [];
	role.forEach((ele) => {
		userRoles.push(ele);
	});
	const pageRole = navState['/analytics/sales']?.submenu['/analytics/inventory/list/po']?.rolesMapped;
	const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	const roleDetails = findMatchingRole.map((role) => {
		const key = Number(role);
		const name = map.get(key);
		return { role: key, name };
	});
	const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	const access = findMatchingRole ? userConfig?.userDetails?.level : false;
	let permit;
	if (access === false) {
		permit = false;
	} else {
		permit = isInternal && (access === 3 || access === 4);
	}
	// end user Acces
	// console.log(typeof(permit), 'permit');
	// console.log(permit, 'fix: permit');
	const handleDatePickerModalSetDate = useCallback(async (date, item) => {
		if (
			moment(item?.scheduledAt, 'YYYY-MM-DD HH:mm:ss').format(
				'YYYY-MM-DD'
			) === moment(date).format('YYYY-MM-DD')
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Date is already same',
			});
			return;
		}
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'Updating Date',
		});
		try {
			const response = await inventoryAnalyticsServices?.editVendorPO({
				params: {
					poId: item?.id,
				},
				data: {
					scheduled: moment(date).format('MM/DD/YYYY'),
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
					config: {
						id: notificationId,
					},
				});
				setFilters((prev) => {
					return {
						...prev,
						blink: !prev?.blink,
					};
				});
				setTimeout(() => {
					notificationServices.generateNotification({
						type: 'wave',
						message: 'Press find again to get latest updates',
						config: {
							id: notificationId,
						},
					});
				}, 1500);
				setDatePickerModalState({
					show: false,
					heading: '',
					currentPo: null,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
				config: {
					id: notificationId,
				},
			});
		}
	}, []);


	const handleDatePickerModalOpen = useCallback((item) => {
		setDatePickerModalState({
			show: true,
			heading: `Expected Receive date for ${item?.clientPoId}`,
			currentPo: item,
		});
	}, []);

	useEffect(() => {
		exportFilters.current = {
			...exportFilters.current,
			storeId:
				navSelectedStore?.value?.id === -999
					? null
					: navSelectedStore?.value?.id,
		};
	}, [navSelectedStore?.value?.id]);

	const [filters, setFilters] = useState({
		storeId: null,
		dateRange: {
			startDate: new Date(),
			endDate: new Date(),
		},
	});

	const storeIdMap = useMemo(() => {
		const map = {};
		stores?.forEach((store) => {
			map[store?.id] = store;
		});
		return map;
	}, [stores]);

	useEffect(() => {
		if (navSelectedStore?.value?.id) {
			setFilters((prev) => {
				return {
					...prev,
					fulfilledBy:
						navSelectedStore?.value?.id === -999
							? null
							: navSelectedStore?.value?.id,
				};
			});
			setUpdate((prev) => {
				return prev + 1;
			});
		}
	}, [navSelectedStore?.value?.id, type]);

	const handlePoStatus = (id, status) => {
		// eslint-disable-next-line no-console
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Cancelling Po ${id || ''}`,
			});
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status,
						},
					}
				);
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	// const handleDownloadGRN = (downloadLink) => {
	// window.open(downloadLink);
	// };

	const handleViewClick = (item) => {
		toggleModal(true, {
			heading: `PO Items of #${item?.id}`,
			...item,
			showPdf: item?.poURL,
			showTotal: true,
			showPoDetails: true,
			width: '80%',
			height: '90%',
			transactionData: {
				'Order ID': item?.clientPoId,

				'Deliver To Store': item?.storeName,
				'Deliver From Store': item?.fromStoreName,
				'Deliver To Store GSTIN': item?.storeGstin,
				'Deliver From Store GSTIN': item?.fromStoreGstin,
				'Payment Term': item?.jsonData?.paymentTerm,
			},
			excludedColumns: isInternalSales
				? [
						'PO Id',
						'From Process',
						'Quantity',
						'Returned Quantity',
						'Fulfilled',
						'Returned',
				  ]
				: ['Packaged', 'Dispatched', 'Returned Quantity'],
		});
	};
	const viewMoreOptionsList = {
		'View Items': (item, cb) => {
			handleViewClick(item);
			cb();
		},
		'View Debit/Credit Note': (item, cb) => {
			toggleDebitCreditListDrawer(true, {
				poId: item?.id,
				heading: `Debit/Credit Notes of ${
					item?.clientPoId ?? item?.id
				}`,
			});
			cb();
		},
		'View Assets': async (item, cb) => {
					try {
						const res = await assetServices?.fetchAssetTransitFlow(
							{
								req: {
									params: {
										id: Number(item?.id),
									},
								},
							}
						);
						if (res?.statusCode === 200) {
							const response = res?.data;
							if (response.length > 0) {
								toggleAssetTransit(true, {
									heading: `PoId : ${item?.id}`,
									data: response,
								});
							} else {
								notificationServices?.generateNotification({
									type: 'warning',
									message: 'No assets are packaged yet in this PO',
								});
							}
						}
					} catch (error) {
						notificationServices.generateNotification({
							type: 'error',
							message: error,
						});
					}
		},	
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [
			'Id',
			'Client PO Id',
			'Expected Receive date',
			'Order status',
			'Raised by',
			'Fulfilled by',
		];
		const downloadBtn = document.createElement('a');

		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const exportItemsWiseData = () => {
		const headers = [
			'Product Name',
			'Variant',
			'Unit',
			'Product Category',
			'Ordered Quantity',
			'Packaged',
			'Dispatched',
			'Received',
		];
		const totalValues = [];
		data?.forEach((ele) => {
			ele?.items?.forEach((item) => {
				totalValues?.push([
					ele?.id,
					ele?.clientPoId,
					ele?.scheduledAt,
					ele?.orderStatus,
					ele?.storeName,
					ele?.fromStoreName,
					item?.productName?.replaceAll(',', "'"),
					item?.variantName,
					item?.unit,
					item?.categoryName,
					item?.qty,
					item?.packaged,
					item?.fulfilled,
					item?.storeFulfilled,
				]);
			});
		});
		handleExcelDownload(
			headers,
			totalValues,
			`PO_${filters?.dateRange?.startDate}-${filters?.dateRange?.endDate}`
		);
	};

	const isLatLongSameForPOStore = (item) => {
		const fromStoreLatitude = storeIdMap[item?.fromStore]?.latitude;
		const fromStoreLongitude = storeIdMap[item?.fromStore]?.longitude;

		const toStoreLatitude = storeIdMap[item?.storeId]?.latitude;
		const toStoreLongitude = storeIdMap[item?.storeId]?.longitude;

		return (
			fromStoreLatitude === toStoreLatitude &&
			fromStoreLongitude === toStoreLongitude
		);
	};

	const renderDownloadField = (item) => {
		const sendStoreGstin = item?.fromStoreGstin;
		const receiveStoreGstin = item?.storeGstin;

		return (
			<div
				style={{
					width: '125px',
				}}
				className={styles?.downloadDiv}
			>
				{item?.poURL && (
					<Chip
						size='small'
						label='PO'
						onClick={() => {
							if (item?.poURL) {
								window.open(item?.poURL);
							}
						}}
						icon={<PictureAsPdfIcon />}
					/>
				)}
				<br />
				{item?.deliveryChallanURL && (
					<Chip
						size='small'
						label='DC'
						onClick={() => {
							if (item?.deliveryChallanURL) {
								window.open(item?.deliveryChallanURL);
							}
						}}
						icon={<PictureAsPdfIcon />}
					/>
				)}
				<br />
				{item?.woDocUrl && (
					<Chip
						size='small'
						label='WO'
						onClick={() => {
							if (item?.woDocUrl) {
								window.open(item?.woDocUrl);
							}
						}}
						icon={<PictureAsPdfIcon />}
					/>
				)}
				<br />
				{item?.invoiceURL &&
					(item?.jsonData?.ewayBill?.url ||
						isLatLongSameForPOStore(item)) && (
						<Chip
							size='small'
							label='Invoice'
							onClick={() => {
								if (item?.invoiceURL) {
									window.open(item?.invoiceURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
				{item?.grnURL && (
					<>
						<Chip
							size='small'
							label='GRN'
							onClick={() => {
								if (item?.grnURL) {
									window.open(item?.grnURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					</>
				)}
				{item?.jsonData?.ewayBill?.url ? (
					<>
						<div
							style={{
								display: 'flex',
								position: 'relative',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Chip
								size='small'
								label='Eway Bill'
								onClick={() => {
									if (item?.jsonData?.ewayBill?.url) {
										const anchor =
											document.createElement('a');
										anchor.href = `https://${item?.jsonData?.ewayBill?.url}`;
										anchor.download = 'EwayBill.pdf';
										anchor.target = 'blank';
										anchor.click();
									}
								}}
								icon={<PictureAsPdfIcon />}
							/>
							<EditIcon
								style={{
									position: 'absolute',
									right: '0px',
									cursor: 'pointer',
									backgroundColor: '#f0f0f0',
									color: '#666666',
									padding: '1px',
									borderRadius: '4px',
								}}
								size={16}
								onClick={() => {
									const urlType = 'EWAYBILL';
									const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${item?.id}&type=${urlType}`;
									history.push(url);
								}}
							/>
						</div>
					</>
				) : (
					item?.orderStatus === 'TRANSIT' &&
					!isLatLongSameForPOStore(item) && (
						<span
							style={{
								cursor: 'pointer',
								color: '#1976d2',
								textDecoration: 'underline',
								fontSize: '12px',
								display: 'inline-block',
								marginLeft: '8px',
								whiteSpace: 'nowrap',
							}}
							onClick={() => {
								const urlType = 'EWAYBILL';
								const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${item?.id}&type=${urlType}`;
								history.push(url);
							}}
						>
							Create E-way Bill
						</span>
					)
				)}
				{sendStoreGstin &&
					receiveStoreGstin &&
					((sendStoreGstin !== receiveStoreGstin || item?.jsonData?.eInvoice?.eInvoiceUrl?.length>0)) &&
					(item?.jsonData?.eInvoice?.eInvoiceUrl ? (
						<>
							{item?.jsonData?.ewayBill?.url ||
							!isLatLongSameForPOStore(item) ? (
								<div style={{
									display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center',
								}}
								>
									<Chip
										size='small'
										label='E-Invoice'
										onClick={() => {
											if (item?.jsonData?.eInvoice?.eInvoiceUrl) {
												const anchor = document.createElement('a');
												anchor.href = item?.jsonData?.eInvoice?.eInvoiceUrl;
												anchor.download = 'E-Invoice.pdf';
												anchor.target = 'blank';
												anchor.click();
											}
										}}
										icon={<PictureAsPdfIcon />}
									/>
									<EditIcon
										style={{
											position: 'absolute',
											right: '0px',
											cursor: 'pointer',
											backgroundColor: '#f0f0f0',
											color: '#666666',
											padding: '1px',
											borderRadius: '4px',
										}}
										size={16}
										onClick={() => {
											const urlType = 'INVOICE';
											const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${item?.id}&type=${urlType}`;
											history.push(url);
										}}
									/>
								</div>
							) : (
								<span
									style={{
										color: '#a00',
										fontSize: '10px',
										textAlign: 'center',
										display: 'inline-block',
										// marginLeft: '8px',
										width: '100%',
									}}
								>
									*Generate E-way Bill to download Invoices
								</span>
							)}
						</>
					) : (
						![
							'PENDING',
							'DISPATCH',
							'CANCELLED',
							'ABANDONED',
						].includes(item?.orderStatus) && (
							<span
								style={{
									cursor: 'pointer',
									color: '#1976d2',
									textDecoration: 'underline',
									fontSize: '12px',
									display: 'inline-block',
									marginLeft: '8px',
									whiteSpace: 'nowrap',
								}}
								onClick={() => {
									const urlType = 'INVOICE';
									const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${item?.id}&type=${urlType}`;
									history.push(url);
								}}
							>
								Create E-Invoice
							</span>
						)
					))}
			</div>
		);
	};

	const getPODeltas = (po) => {
		if(po?.orderStatus !== 'CLOSED') return <></>;

		const shortSupplyItems = po?.items?.filter((item) => (item?.fulfilled??0)>(item?.storeFulfilled??0))?.
								reduce((acc, item) => {
									const qty=(item?.fulfilled??0) - (item?.storeFulfilled??0);
									return acc + ((qty*item?.productCost)*(1+(item.taxPercentage/100)));
								} , 0);
								const extraSupplyItems = po?.items?.filter((item) => (item?.fulfilled??0)<(item?.storeFulfilled??0))?.
								reduce((acc, item) => {
									const qty=(item?.storeFulfilled??0)-(item?.fulfilled??0);
									return acc + ((qty*item?.productCost)*(1+(item.taxPercentage/100))) ;
								} , 0);

		return <div>
			{
				shortSupplyItems>0 && <div className="text-red-700 text-[10px]">Loss in transit ₹{shortSupplyItems>= 0 ? addCommas(shortSupplyItems) : '-'}</div>
			}
			{
				extraSupplyItems>0 && <div className="text-green-800 text-[10px]">Extra supply ₹{extraSupplyItems>= 0 ? addCommas(extraSupplyItems) : '-'}</div>
			}
		</div>
	}

	const SPECIAL_ITEMS = {
		Download: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return renderDownloadField(item);
		},
		'Expected Dispatch Date': (itemId) =>{
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const date = moment(item?.dispatchBy).format('YYYY-MM-DD');
			return(
				<p className=' w-36'>{date}</p>
			)
		},
		'Expected Receive date': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const status = ['CANCELLED','CLOSED','ABANDONED'];
			if(permit && !status.includes(item?.orderStatus)){
				return (
					<div
						className="flex w-36 items-center justify-between border border-gray-200 p-2 cursor-pointer hover:bg-gray-50 rounded-md transition-colors"
						onClick={() => {
							handleDatePickerModalOpen(item);
						}}
					>
						<span className="text-gray-700">{item?.scheduledAt?.split(' ')[0] ?? '-'}</span>
						<EditIcon className="text-gray-500 hover:text-gray-700" />
					</div>
				);
			}else{
				return(
					<span className="text-gray-700 block w-[7rem]">{item?.scheduledAt?.split(' ')[0] ?? '-'}</span>
				);
			}
		},
		'Deliver From': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div
					style={{
						width: '200px',
						textAlign: 'left',
					}}
				>
					{item?.fromStoreName ?? '-'}
				</div>
			);
		},
		'Van Temp': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return item?.receivingTemp ?? '-';
		},
		'Transit Van Image': (id, src) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div
					style={{
						width: '5rem',
						display: 'flex',
						cursor: 'pointer',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'center',
					}}
				>
					{item?.receivingTempImage ? (
						<img
							onClick={() => {
								toggleVanImageModal(true, {
									heading: `${item?.id ?? '-'} at ${
										item?.scheduledAt
									}`,
									image: item?.receivingTempImage,
									vanTemp: item?.receivingTemp,
									id: item?.id,
									// image: 'https://placehold.co/600x400',
									// height: 400,
								});
							}}
							width={100}
							height={50}
							// src='https://placehold.co/600x400'
							src={item?.receivingTempImage}
							alt={src}
						/>
					) : (
						<>-</>
					)}
				</div>
			);
		},
		'Order Amount': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const orderAmount = item?.finalValue==0? item?.orderValue : item?.finalValue;
			return <div>
				<span className="text-gray-700">₹{orderAmount >= 0 ? addCommas(orderAmount) : '-'}</span>
				{
					getPODeltas(item)
				}
			</div>
			
		},
		Action: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div className='flex flex-col gap-2 w-full'>
					{item?.orderStatus === 'DISPATCH' && (
						<Button
							className='125px'
							variant='secondary'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Re-Open PO',
									handleConfirm: () => {
										handlePoStatus(itemId, 'REOPEN');
										toggleAlert(false);
									},
									colorReverse: true,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you to re-open po-${
										item?.id || ''
									}?`,
								});
							}}
						>
							Re-Open
						</Button>
					)}
					{item?.orderStatus === 'PENDING' && (
						<Button
							className='125px danger-btn'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Cancel PO',
									handleConfirm: () => {
										handlePoStatus(itemId, 'CANCEL');
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you to cancel ${
										item?.id || ''
									}?`,
								});
							}}
						>
							Cancel PO
						</Button>
					)}
				</div>
			);
		},
	};

	const handleEditSubmit = async (editedValues) => {
		try {
			const response = await assetServices.editAssetTemp({
				data: editedValues,
				params: {
					type: 'PO',
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error ?? error?.message,
			});
		}
	};

	const generateProductionPlan = async (idsList) => {
		const req = {
			data: {
				poIds: idsList,
				storeId: navSelectedStore?.value?.id,
				date: filters?.dateRange?.startDate,
			},
		};
		setProductionPlanLoading(true);
		try {
			const response =
				await inventoryAnalyticsServices.fetchProductionPlan(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				toggleProductionPlanModal(true, {
					idsList,
					storeId: navSelectedStore?.value?.id,
					date: filters?.dateRange?.startDate,
					listData: response?.data,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setProductionPlanLoading(false);
		}
	};
	const moreOptionsList = {
		'E-Way bill': (po, cb) => {
			if (!orderStatus?.slice(2, -1).includes(po?.orderStatus)) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'No invoice has been generated yet for this order',
				});
				return;
			}
			const urlType = 'EWAYBILL';
			const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${po?.id}&type=${urlType}`;
			history.push(url);
			cb();
		},
		'E-Invoice': (po, cb) => {
			if (!orderStatus?.slice(2, -1).includes(po?.orderStatus)) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'No invoice has been generated yet for this order',
				});
				return;
			}
			const urlType = 'INVOICE';
			const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${po?.id}&type=${urlType}`;
			history.push(url);
			cb();
		},
	}

	const handleFetchPurchaseOrderList = (finderFilters) => {
		const dateRange = {
			startDate: moment(finderFilters?.dateRange?.from).format(
				'YYYY-MM-DD'
			),
			endDate: moment(finderFilters?.dateRange?.to).format('YYYY-MM-DD'),
			dateType: finderFilters?.timeType,
		};
		setFilters((prev) => {
			return {
				...prev,
				dateRange,
				fulfilledBy: finderFilters?.store,
			};
		});
		const req = {
			data: {
				filters: {
					storeId: finderFilters?.store,
					fromStoreId:
						navSelectedStore?.value?.id === -999
							? null
							: navSelectedStore?.value?.id,
					dateRangeFilter: {
						from: moment(finderFilters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(finderFilters?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
						dateType: dateRange.dateType,
					},
					status: finderFilters?.status,
					orderType: 'sales/purchase',
					userOrderType: 'sales',
				},
			},
		};
		exportFilters.current = req.data.filters;
		fetchPurchaseOrderList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'PO_LIST',
			req,
		});
	};

	// eslint-disable-next-line no-unused-vars
	const combinePO = async () => {
		const from = moment(filters?.dateRange?.startDate).format('YYYY-MM-DD');
		const to = moment(filters?.dateRange?.endDate).format('YYYY-MM-DD');
		try {
			const req = {
				data: {
					storeId: navSelectedStore?.value?.id,
					dateRangeFilter: {
						from,
						to,
					},
				},
			};
			const response =
				await inventoryAnalyticsServices.combinePurchaseOrder({
					req,
				});

			// Check if the Content-Type of the response is 'text/csv'
			const contentType = response?.headers?.get('Content-Type');
			if (contentType && contentType?.includes('text/csv')) {
				const blob = await response.blob(); // Get the response data as a Blob
				const blobUrl = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = blobUrl;
				link.download = `Combined_PO_${from}-${to}.csv`;
				link.click();
				window.URL.revokeObjectURL(blobUrl);

				notificationServices.generateNotification({
					type: 'success',
					message: 'Combined and Downloaded Successfully',
				});
			} else {
				// Handle the case where the response is not 'text/csv'
				try {
					const errorData = await response.json(); // Try to parse the response as JSON
					const errorMessage = errorData?.errorMessage; // Extract the error message
					notificationServices.generateNotification({
						type: 'error',
						message: errorMessage || 'Some error occurred.',
					});
				} catch (jsonError) {
					// Handle the case where the response is not JSON or doesn't contain an error message
					notificationServices.generateNotification({
						type: 'error',
						message: 'Some error occurred.',
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some Error occurred while combining',
			});
		}
	};

	const footerComponent = () => {
		return (
			navSelectedStore?.value?.id &&
			navSelectedStore?.value?.id !== -999 &&
			navSelectedStore?.value?.type === 'BASE' && (
				<Button
					type='button'
					onClick={combinePO}
					className='bg-blue-600'
				>
					COMBINE PO
				</Button>
			)
		);
	};

	const handleExport = async (fltersObj) => {
		try {
			const response =
				await orderAnalyticsServices.exportGRNWiseOrderHistory({
					data: {
						filters: fltersObj,
					},
				});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					`INTERNAL_SALES_ORDERS_${fltersObj?.dateRangeFilter?.from}_${fltersObj?.dateRangeFilter?.to}.csv`
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};

	const openExportDrawer = () => {
		toggleExportFiltersDrawer(true, {
			heading: 'Export Store Sales Orders',
			// note: 'Filters are disabled for now, they will be LIVE Soon!',
			onConfirm: (args) => {
				const { filtersState } = args ?? {};
				handleExport({
					...exportFilters.current,
					dateRangeFilter: {
						dateType: filtersState?.dateRangeInput,
						from: moment(filtersState?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filtersState?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
					},
					categories: filtersState?.categories,
					subCategories: filtersState?.subCategories,
					exportType: filtersState?.exportType,
				});
			},
			filterData: [
				{
					type: 'tabSelect',
					dependentOn: -1,
					compData: exportTypes,
					selectBy: 'value',
					label: 'Export Type',
					selectedValueKey: 'exportType',
					dateFormat: null,
					defaultState: exportTypes?.[1]?.value,
					disabled: false,
				},
				{
					type: 'singleSelect',
					dependentOn: -1,
					compData: categoriesList?.map((ele) => {
						return {
							id: ele?.id,
							value: ele?.id,
							title: ele?.productCategoryName,
							label: ele?.productCategoryName,
						};
					}),
					selectBy: 'id',
					label: 'Category',
					selectedValueKey: 'categories',
					dateFormat: null,
					disabled: true,
					defaultState: null,
				},
				{
					type: 'multiSelect',
					dependentOn: 1,
					compData: [],
					selectBy: 'id',
					label: 'Sub-Categories',
					selectedValueKey: 'subCategories',
					dateFormat: null,
					defaultState: [],
					disabled: true,
					dataPropKey: 'subCategories',
					onDependencyUpdate: (dependencyState) => {
						if (dependencyState) {
							fetchSubCategories({
								entity: 'PRODUCT_SUB_CATEGORY',
								req: {
									params: {
										categoryId: dependencyState,
									},
								},
								method: 'LIST',
							});
						}
					},
				},
				{
					type: 'dateRange',
					dependentOn: -1,
					compData: [],
					selectBy: '',
					label: 'Date Range',
					selectedValueKey: 'dateRange',
					dateFormat: null,
					withInput: true,
					disabled: false,
					dataPropKey: '',
					defaultState: {
						from: new Date(
							exportFilters?.current?.dateRangeFilter?.from
						),
						to: new Date(
							exportFilters?.current?.dateRangeFilter?.to
						),
					},
				},
				{
					type: 'dateRangeInput',
					dependentOn: -1,
					compData: dateTypeArr,
					selectBy: '',
					disabled: false,
					label: 'Date Type',
					selectedValueKey: 'dateRangeInput',
					dateFormat: null,
					defaultState:
						exportFilters?.current?.dateRangeFilter?.dateType,
				},
			],
		});
	};

	const handleOnUpdate = (finderFilters) => {
		const dateRange = {
			startDate: moment(finderFilters?.dateRange?.from).format(
				'YYYY-MM-DD'
			),
			endDate: moment(finderFilters?.dateRange?.to).format('YYYY-MM-DD'),
			dateType: finderFilters?.timeType,
		};
		const req = {
			data: {
				filters: {
					storeId: finderFilters?.store,
					fromStoreId:
						navSelectedStore?.value?.id === -999
							? null
							: navSelectedStore?.value?.id,
					dateRangeFilter: {
						from: moment(finderFilters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(finderFilters?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
						dateType: dateRange.dateType,
					},
					status: finderFilters?.status,
					orderType: 'sales/purchase',
					userOrderType: 'sales',
				},
			},
		};
		exportFilters.current = req.data.filters;
	};
	return (
		<div className={styles.root}>
			<AlertModal />
			<POItemsModal />
			<DatePickerModal
				show={datePickerModalState?.show}
				heading={datePickerModalState?.heading}
				restrictDateType='before'
				currentPo={datePickerModalState?.currentPo}
				onClose={() => {
					setDatePickerModalState({ show: false, heading: '' });
				}}
				onSubmit={handleDatePickerModalSetDate}
			/>
			<AssetTransitModal />
			<ProductionPlanModal />
			<BackdropLoader isLoading={productionPlanLoading} showProgress />
			<VanImageModal handleEditSubmit={handleEditSubmit} />
			<DebitCreditListDrawer />
			<ExportFiltersDrawer
				subCategories={subCategoriesList?.map((ele) => {
					return {
						id: ele?.id,
						value: ele?.id,
						title: ele?.categoryName,
						label: ele?.categoryName,
					};
				})}
			/>
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Store Sales Order'
					FILTERS={FIND_FILTERS}
					onSubmit={handleFetchPurchaseOrderList}
					update={update}
					blink={filters?.blink}
					// timeTypeArray={dateTypeArr}
					statusList={orderStatus?.map((ele) => {
						if (ele === 'FULFILLED') {
							return {
								id: ele,
								title: 'CLOSED',
							};
						}
						if (ele === 'TRANSIT') {
							return {
								id: ele,
								title: 'IN TRANSIT',
							};
						}
						if (ele === 'DISPATCH') {
							return {
								id: ele,
								title: 'PENDING DISPATCH',
							};
						}
						if (ele === 'UNLOADING') {
							return {
								id: ele,
								title: 'UNLOADING STARTED',
							};
						}
						if (ele === 'VERIFICATION') {
							return {
								id: ele,
								title: 'PENDING VERIFICATION',
							};
						}
						return {
							id: ele,
							title: ele,
						};
					})}
					preventDateRangeCheckbox
					onUpdate={handleOnUpdate}
					additionInfo={{
						// showAdd: userPermissions.INTERNAL,
						showAdd: true,
						addText: 'Order',
						prefix: 'Create',
						handleAddClick: () => {
							let typeId = 1;
							if (isInternalSales) {
								if (
									navSelectedStore?.value?.type?.includes(
										'WAREHOUSE'
									)
								) {
									typeId = 3;
								} else {
									typeId = 2;
								}
							}
							history.push(
								`${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?userOrderType=sales&typeId=${typeId}&fromStore=${navSelectedStore?.value?.id}&storeTypes=${navSelectedStore?.storesType}`
							);
						},
					}}
					filterLabels={{
						store: 'Deliver To',
					}}
					footerComponent={footerComponent}
					timeTypeArray={dateTypeArr}
				/>
			</div>
			<div
				className={styles?.table}
				style={{
					width: '100%',
				}}
			>
				<ListTable
					hasDateRange
					onExportClick={openExportDrawer}
					allowCheckList={
						data?.length > 0 &&
						navSelectedStore?.value?.type === 'BASE'
					}
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data?.map((ele) => {
						return {
							...ele,
							orderStatus: handleOrderStatus(ele?.orderStatus),
							rowStyles: classes(
								styles.row,
								styles?.[ele?.orderStatus]
							),
						};
					})}
					moreOptionsList={moreOptionsList}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
					generateProductionPlan={generateProductionPlan}
					viewMoreOptionsList={viewMoreOptionsList}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { navState , userPermissions} = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { PO_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	const categoriesList = state.form.PRODUCT_CATEGORY.data.LIST;
	const subCategoriesList = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	const { userList } = state.auth;
	return {
		data,
		stores,
		navState,
		dateRangeFilter,
		categoriesList,
		subCategoriesList,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleVanImageModal: modalActions.toggleVanImageModal,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	toggleProductionPlanModal: modalActions.toggleProductionPlanModal,
	fetchPurchaseOrderList: analyticsAction.gateway,
	fetchSubCategories: formActions.gateway,
	toggleExportFiltersDrawer: drawerActions.toggleExportFiltersDrawer,
	toggleDebitCreditListDrawer: drawerActions.toggleDebitCreditListDrawer,
	fetchUserList: authActions?.fetchUserList,
	toggleAssetTransit: modalActions.toggleAssetTransit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderList);
