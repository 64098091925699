import { useRouteMatch } from 'react-router';
import RefundHistoryList from '../../../Pages/Refund/Refund History/RefundHistoryList';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const RefundHistoryRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<CRUDRoutes
			entity='REFUND_HISTORY'
			path={path}
			listPage={RefundHistoryList}
			placeholder='Search By Order Id'
		/>
	);
};

export default RefundHistoryRoutes;
