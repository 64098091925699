/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const qualityConfigServices = {
	gateway,
	listConfig,
	updateConfig,
};

function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewConfig({
				req,
			});
		case 'LIST':
			return listConfig({
				req,
			});
		case 'UPDATE':
			return updateConfig({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function viewConfig({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_QUALITY_CONFIG, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'appplication/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function listConfig({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.GET_PRODUCTS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockViewConfig(url, requestOptions);
}

function updateConfig({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_QUALITY_CONFIG, params);

	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

// function mockViewConfig() {
// 	const mockResponse = {
// 		message: 'Loaded successfully',
// 		status: 'Success',
// 		statusCode: 200,
// 		data: {
// 			name: 'Paneer Roll',
// 			description: 'A paneer roll',
// 			brand: 1,
// 			image: 'https://foodelish.in/wp-content/uploads/2020/06/mughlai_paneer-roll.jpeg',
// 			productClass: 'product',
// 			QMSEnabled: true,
// 			variants: [
// 				{
// 					name: 'Variant 1',
// 					weightCheck: {
// 						applied: true,
// 						minWeight: 100,
// 						maxWeight: 103,
// 					},
// 					tempCheck: {
// 						applied: true,
// 						minTemp: 100,
// 						maxTemp: 105,
// 					},
// 				},
// 				{
// 					name: 'Variant 2',
// 					weightCheck: {
// 						applied: false,
// 						minWeight: 200,
// 						maxWeight: 200,
// 					},
// 					tempCheck: {
// 						applied: true,
// 						minTemp: 100,
// 						maxTemp: 100,
// 					},
// 				},
// 			],
// 		},
// 	};
// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(mockResponse);
// 		}, 2000);
// 	});
// }

export default qualityConfigServices;
