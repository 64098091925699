import { useAuth } from '@/Components/Auth/Login/auth';
import { addCommas } from '@/Helpers';
import { Label } from '@/Components/ui/label';

const TotalContainer = (props) => {
	const { calculatedData } = props;

	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};

	return (
		<div className='w-[400px] border-2 border-slate-300 self-end ml-auto rounded-lg px-4 py-2'>
			{Object.keys(calculatedData ?? {})?.map((ele) => {
				const dataItem = calculatedData?.[ele];
				const isObject =
					typeof dataItem === 'object' && dataItem !== null;
				return (
					<div key={ele} className={`grid grid-cols-3 ${isObject ? 'border border-slate-500 rounded-lg p-2 mb-1' : 'pb-1'}`}>
						<Label className='text-left text-sm text-slate-600 col-span-1'>{ele}</Label>
						{isObject ? (
							<div className='col-span-2'>
								{Object.keys(dataItem ?? {})?.map(
									(innerKey) => {
										const innerDataItem = dataItem?.[innerKey];
										const innerItemValue = addCommas(
											innerDataItem ?? 0,
											measurement,
											true
										);
										return (
											<div className='flex justify-between items-center gap-2 w-full'>
												<Label className=' text-left text-sm text-slate-600'>
													{innerKey}
													:
												</Label>
												<p className='text-right font-semibold text-lg'>
													{innerItemValue}
												</p>
											</div>
										);
									}
								)}
							</div>
						) : (
							<p className='text-right font-semibold text-lg w-full col-span-2'>{addCommas(dataItem ?? 0, measurement, true)}</p>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default TotalContainer;
