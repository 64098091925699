import {
	IconButton, MenuItem, Select, Button
} from '@mui/material';
import { Search } from '@mui/icons-material';
import styles from './SearchBar.module.scss';
import { inputHelper } from '../../Helpers';

function generateAttrs(predicate, object) {
	if (predicate) {
		return object;
	}
	return {};
}

const SearchBar = (props) => {
	const {
		searchBy,
		placeholder,
		handleSearch,
		className,
		autofocus,
		defaultValue,
		value,
		showBtn,
		handleOrderSearch = () => {},
	} = props;

	const handleInput = (e) => {
		const { fieldValue: query } = inputHelper(e);
		props.handleInput(query);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleOrderSearch();
		}
	};

	const handleChange = (e) => {
		const { fieldValue: index } = inputHelper(e);
		props.handleChange(index);
	};

	return (
		<div
			className={`${styles.root} ${className} ${searchBy ? styles['search-by-active'] : ''
			}`}
		>
			<div className={styles.search}>
				<input
					type='text'
					onInput={handleInput}
					className={`${styles.input}`}
					placeholder={
						searchBy?.current != null ? 'Search...' : placeholder
					}
					onKeyPress={handleKeyPress}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus={autofocus}
					value={value}
					defaultValue={defaultValue}
					{...generateAttrs(value, {
						value,
					})}
				/>
				<IconButton
					className={`${styles.icon}`}
					onClick={handleSearch}
					size='large'
				>
					<Search />
				</IconButton>
			</div>
			{searchBy != null && (
				<Select
					className={styles['search-by']}
					value={searchBy?.current}
					onChange={handleChange}
				>
					{searchBy?.options?.map((option, idx) => {
						const { title } = option;
						return (
							<MenuItem value={idx} key={title}>
								{title}
							</MenuItem>
						);
					})}
				</Select>
			)}
			{
				showBtn && (
					<div>
						<Button variant='contained' onClick={handleOrderSearch}>Search</Button>
					</div>
				)
			}
		</div>
	);
};
SearchBar.defaultProps = {
	searchBy: null,
	placeholder: 'Search...',
	value: null,
	defaultValue: '',
	className: '',
	handleSearch: () => { },
	handleInput: () => { },
	autofocus: false,
};
export default SearchBar;
