// import { cloneDeep } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useMemo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { BoxAnnotator } from '../../../Components/Annotation/BoxAnnotator';
import config from './config';
import { annotationServices, notificationServices } from '../../../Services';
import annotationActions from '../../../Actions/annotation.actions';
// eslint-disable-next-line object-curly-newline
import {
	history,
	inputHelper,
	keyPressHandler,
	useClientRect,
	useImageDimensionsRef,
	useQuery,
} from '../../../Helpers';
import { RouterPromptModal } from '../../../Components/Modals';
import { Header } from '../../../Components/Annotation/AnnotatorHeader';
import { liveQCServices } from '../../../Services/LiveQC';
import retagActions from '../../../Actions/retag.actions';
import { urls } from '../../../Constants';
import analyticsAction from '../../../Actions/analytics.actions';
import useImageDimensionState from '../../../Helpers/useImageDimensionState';
import useKeyPress from '../../../Helpers/useKeyPress';
import styles from './TEMPAnnotationPage.module.scss';

const initialState = {
	qcData: {
		assetId: null,
		assetName: '',
		createdAt: '',
		id: null,
		image: '',
		temp: null,
	},
};

const labelsList = Array.from({
	length: 12,
}, (_, i) => {
	if (i === 10) {
		return {
			id: '.',
			name: '.',
		};
	}
	if (i === 11) {
		return {
			id: '-',
			name: '-',
		};
	}
	return {
		id: i, name: `${i}`,
	};
});

const TEMPAnnotationPage = (props) => {
	const {
		assetList,
		poListData,
		fetchTempAnnotations,
		tempAnnotations,
		userPermissions,
		// removeFromQCList,
	} = props;
	const { ANNOTATE = false } = userPermissions;

	const query = useQuery();
	const itemId = query.get('id');
	const type = query.get('type');
	const isCms = query.get('CMS') === 'true';
	const recipeId = query.get('recipeId');

	const listData = type === 'PO' ? poListData : assetList;

	const listIndex = useMemo(() => {
		return listData?.findIndex((item) => {
			return item.id === parseInt(itemId, 10);
		});
	}, [itemId]);

	const [imageContainerRect, imageContainerRef] = useClientRect();
	const [qcData, setQCData] = useState(initialState);
	const imageDimensions = useImageDimensionState(tempAnnotations?.image);

	const [isBlockingNavigation, setIsBlockingNavigation] = useState(false);
	// const [isDisabled, setIsDisabled] = useState(false);

	const [annotations, setAnnotations] = useState([]);
	const [newAnnotation, setNewAnnotation] = useState([]);

	const qcImageDimension = useImageDimensionsRef(tempAnnotations?.image);

	const keyPressed = useKeyPress();

	const handleBack = useCallback(() => {
		// eslint-disable-next-line max-len
		// history.push(`${urls.EXTENSION}${urls.PAST_QC}/${index}?id=${qcId}${isCms ? '&type=CMS' : ''}`);
		history.goBack();
	}, [itemId]);

	const canvasStyles = useMemo(() => {
		return {
			backgroundImage: `url(${tempAnnotations?.image})`,
			backgroundPosition: 'center',
			backgroundSize: '100% 100%',
			backgroundRepeat: 'no-repeat',
			maxWidth: '100%',
			position: 'absolute',
			inset: 0,
			width: imageContainerRect()?.width,
		};
	}, [tempAnnotations?.image, imageContainerRect]);

	const navigateQC = useCallback(
		(e) => {
			const { dataset } = inputHelper(e);
			let newListItem = null;

			if (dataset.type === 'next') {
				newListItem = listData?.[listIndex + 1];
			} else {
				newListItem = listData?.[listIndex - 1];
			}

			history.replace(
				`${urls.EXTENSION}${urls.TEMP_ANNOTATION}?id=${newListItem?.id}&type=${type}`
			);
		},
		[listIndex, listData]
	);

	useEffect(() => {
		const data = listData?.[listIndex] ?? null;
		if (
			(keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight') &&
			data !== null
		) {
			const newListItem = keyPressHandler(
				keyPressed,
				listData,
				listIndex
			);
			if (newListItem) {
				history.replace(
					`${urls.EXTENSION}${urls.TEMP_ANNOTATION}?id=${newListItem?.id}&type=${type}`
				);
			}
		}
	}, [keyPressed]);

	const labels = useMemo(() => {
		return labelsList.map((label, idx) => {
			return {
				ID: label?.id,
				id: idx,
				title: label?.name,
			};
		});
	}, []);

	const heightOfContainer = useMemo(() => {
		const ratioOfImg = imageDimensions?.width / imageDimensions?.height;

		const height = imageContainerRect()?.width / ratioOfImg;
		return height;
	}, [imageContainerRect, imageDimensions]);

	useEffect(() => {
		if (labels.length > 0) {
			let currentAnnotations = [];
			currentAnnotations = tempAnnotations?.annotations;
			setAnnotations(
				config.serializer({
					annotations: currentAnnotations ?? [],
					imageDimensions,
					imageContainerRect: imageContainerRect(),
					labels,
				})
			);
		}
	}, [itemId, tempAnnotations, imageDimensions, labels, heightOfContainer]);

	const getPastQCDetails = useCallback(async () => {
		try {
			const req = {
				params: {
					itemId: isCms ? '' : itemId,
					qcId: isCms ? recipeId : itemId,
				},
			};
			const response = await liveQCServices.getPastData(req);
			return response?.data ?? {};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			return {};
		}
	}, [itemId]);

	useEffect(() => {
		(async () => {
			let currentQC = listData?.[listIndex] ?? null;

			if (currentQC == null) {
				currentQC = await getPastQCDetails();
			}
			setQCData(currentQC);
			const req = {
				params: {},
			};
			if (type === 'PO') {
				req.params.poId = currentQC.id;
			} else {
				req.params.tempRecordId = currentQC.id;
			}
			fetchTempAnnotations(req);
		})();
	}, [itemId]);

	const handleSubmit = async (
		finalAnnotations,
		imageDimensionsOg,
		imageDimensionsNew
	) => {
		const isValid = config.validator(finalAnnotations);

		if (isValid) {
			const req = config.deserializer({
				annotations,
				imageDimensionsOg,
				imageDimensionsNew,
				id: itemId,
				type,
			});

			try {
				const response = await annotationServices.submitTempAnnotations(
					req
				);
				// if (!isCms) {
				// const clonedItem = cloneDeep(listData?.[listIndex]);
				// if (mode === 'FEATURES') {
				// clonedItem.features = req.data.features;
				// clonedItem.annotated = req.data.features?.length;
				// } else if (mode === 'IDENTIFICATION') {
				// clonedItem.identification = req.data.identification;
				// } else if (mode === 'CONTAINER') {
				// clonedItem.container = req.data.container;
				// }
				// updateQCList(listIndex, clonedItem);
				// }
				const { message } = response;
				notificationServices.generateNotification({
					type: 'success',
					message,
				});
				setIsBlockingNavigation(false);
				return true;
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					error,
				});
				return false;
			}
		}
		return false;
	};

	const saveAndRedirect = async () => {
		const canRedirect = await handleSubmit(
			annotations,
			qcImageDimension.current,
			imageContainerRect()
		);
		return canRedirect;
	};

	const cancelAndRedirect = () => {
		setIsBlockingNavigation(false);
		return true;
	};

	const updateAnnotations = (newState) => {
		setAnnotations(newState);
		setIsBlockingNavigation(newState.length > 0);
	};

	if (ANNOTATE === false) {
		return (
			<Redirect
				to={`${urls.EXTENSION}${urls.PAST_QC}/?id=${itemId}&type=${type}`}
			/>
		);
	}

	return (
		<div>
			<Header
				listData={listData}
				listIndex={listIndex}
				isLiveQC={false}
				fields={{
					center: {
						label: qcData.assetId ? 'Asset Id' : 'Id',
						value: qcData.assetId ?? qcData?.id,
					},
				}}
				handleBack={handleBack}
				navigate={navigateQC}
			/>
			<div className={styles['annotator-container']}>
				<BoxAnnotator
					customBoxStyles={{
						width: '65%',
						height: heightOfContainer,
					}}
					stageStyles={{
						width: imageContainerRect()?.width,
						height: heightOfContainer,
					}}
					customBoxClassName={styles['box-annotator']}
					// cstmImgContainerClass={styles['img-container']}
					image={tempAnnotations?.image}
					identifiedBy={tempAnnotations?.identifiedBy}
					handleSubmit={handleSubmit}
					imageDimensionsOg={qcImageDimension.current}
					imageContainerRect={imageContainerRect()}
					imageContainerRef={imageContainerRef}
					annotations={annotations}
					setAnnotations={updateAnnotations}
					newAnnotation={newAnnotation}
					setNewAnnotation={setNewAnnotation}
					brandId={qcData.brandId}
					labels={labels}
					type={type}
					limit={null}
					heading='Temp Annotating'
					subHeading={qcData.id && `(${qcData?.id})`}
					modalHeading='Select label'
					allowEdit
					allowDelete
					customCanvasStyle={canvasStyles}
				/>
			</div>
			<RouterPromptModal
				when={isBlockingNavigation}
				title='Save before leaving?'
				okText='Save'
				cancelText="Don't Save"
				message='You have unsaved changes, are you sure you want to leave?'
				onOK={saveAndRedirect}
				onCancel={cancelAndRedirect}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const assetList = state.assets.monitoringData;
	const { PO_LIST: poListData } = state?.analytics?.INVENTORY_ANALYTICS;
	const { tempAnnotations, products, containers } = state.annotation;
	const { retagReasons: defects = [] } = state.retag;
	const { userPermissions } = state.navigation;
	return {
		assetList,
		poListData,
		tempAnnotations,
		defects,
		products,
		containers,
		userPermissions,
	};
};

const mapDispatchToProps = {
	fetchTempAnnotations: annotationActions.fetchTempAnnotations,
	fetchProducts: annotationActions.fetchProducts,
	fetchContainers: annotationActions.fetchContainers,
	fetchDefects: retagActions.fetchRetagReasons,
	updateQCList: analyticsAction.updateQCList,
	removeFromQCList: analyticsAction.removeFromQCList,
};

TEMPAnnotationPage.defaultProps = {
	mode: 'IDENTIFICATION',
};

export default connect(mapStateToProps, mapDispatchToProps)(TEMPAnnotationPage);
