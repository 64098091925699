/* eslint-disable no-tabs */
import { cloneDeep, update } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import liveStoreActions from '../../../Actions/liveStore.actions';
import { inputHelper, isEmpty } from '../../../Helpers';
import StoreSettingTable from './List Table/StoreSettingListTable';
import StoreSettingConfig from './storeSetting.config';

function StoreSettingListPage(props) {
	// eslint-disable-next-line object-curly-newline
	const {
		toggleLive,
		fetchStores,
		data,
		query,
		updateStore,
		updateStoreMenu,
	} = props;
	const [state, setState] = useState([]);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	useEffect(() => {
		fetchStores({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(StoreSettingConfig.serializer(data));
		}
	}, [data]);
	const updateSpecialMenu = (id, idx) => {
		setNumberOfSubmissions(numberOfSubmissions + 1);
		updateStore({
			entity: 'STORES',
			req: {
				params: {
					id,
				},
				data: {
					specialMenu: null,
				},
			},
			method: 'PATCH',
		});
		props.updateSpecialMenu(idx);
		setState(
			update(cloneDeep(state), idx, (obj) => {
				const tempObj = obj;
				tempObj.specialMenu = null;
				return tempObj;
			})
		);
	};
	const updateMenuState = (menuId, idx, id, menuObj, menuType) => {
		setNumberOfSubmissions(numberOfSubmissions + 1);
		updateStore({
			entity: 'STORES',
			req: {
				params: {
					id,
				},
				data: {
					menuId,
					menuType,
				},
			},
			method: 'PATCH',
		});
		updateStoreMenu(idx, {
			id: menuId,
			name: menuObj.menuName,
			menuType,
		});
		setState(
			update(cloneDeep(state), idx, (obj) => {
				const tempObj = obj;
				if (menuType === 'masterMenu') {
					tempObj.menuId = menuId;
					tempObj.menuName = menuObj.menuName;
				} else {
					tempObj[menuType] = menuId;
					tempObj[`${menuType}Name`] = menuObj.menuName;
				}
				return tempObj;
			})
		);
	};
	const handle24X7 = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		setNumberOfSubmissions(numberOfSubmissions + 1);
		if (fieldValue) {
			updateStore({
				entity: 'STORES',
				req: {
					params: {
						id: state[dataset.id].id,
					},
					data: {
						storeStartAt: null,
						storeShutsAt: null,
					},
				},
				method: 'PATCH',
			});
		}
		setState(
			update(cloneDeep(state), dataset.id, (obj) => {
				const tempObj = obj;
				tempObj.roundOclock = fieldValue;
				tempObj.isEdit = fieldValue;
				tempObj.storeShutsAt = null;
				tempObj.storeStartAt = null;
				return tempObj;
			})
		);
	};
	const toggleEditUpdate = (e) => {
		const { dataset } = inputHelper(e);

		if (state[dataset.id].isEdit) {
			const isValid = StoreSettingConfig.validator('timings', {
				startTime: state[dataset.id].storeStartAt,
				endTime: state[dataset.id].storeShutsAt,
				idx: dataset.id,
			});
			if (isValid) {
				setNumberOfSubmissions(numberOfSubmissions + 1);
				updateStore({
					entity: 'STORES',
					req: {
						params: {
							id: state[dataset.id].id,
						},
						data: {
							storeStartAt: moment(
								state[dataset.id].storeStartAt
							).format('HH:mm'),
							storeShutsAt: moment(
								state[dataset.id].storeShutsAt
							).format('HH:mm'),
						},
					},
					method: 'PATCH',
				});

				setState(
					update(cloneDeep(state), dataset.id, (obj) => {
						const tempObj = obj;
						tempObj.storeShutsAt = state[dataset.id].storeShutsAt;
						tempObj.storeStartAt = state[dataset.id].storeStartAt;
						return tempObj;
					})
				);
			} else {
				return;
			}
		}
		setState(
			update(cloneDeep(state), dataset.id, (obj) => {
				const tempObj = obj;
				tempObj.isEdit = !tempObj.isEdit;
				return tempObj;
			})
		);
	};
	const handleToggle = (e) => {
		const { fieldValue, dataset } = inputHelper(e);
		setNumberOfSubmissions(numberOfSubmissions + 1);
		toggleLive({
			entity: 'STORES',
			req: {
				params: {
					id: state[dataset.id].id,
				},
				data: {
					isEnabled: fieldValue,
				},
			},
			method: 'PATCH',
		});
		const newState = update(cloneDeep(state), dataset.id, (obj) => {
			const tempObj = obj;
			tempObj.isEnabled = fieldValue;
			return tempObj;
		});
		setState(newState);
	};
	const handletimeChange = (name, value, index) => {
		setState(
			update(cloneDeep(state), index, (obj) => {
				const tempObj = obj;
				tempObj[name] = value;
				return tempObj;
			})
		);
	};

	return (
		<div className='root'>
			<StoreSettingTable
				data={state.filter((store) => {
					return (
						store.name
							.toLowerCase()
							.indexOf(query.trim().toLowerCase()) !== -1
					);
				})}
				updateSpecialMenu={updateSpecialMenu}
				updateMenuState={updateMenuState}
				handle24X7={handle24X7}
				handletimeChange={handletimeChange}
				handleToggle={handleToggle}
				toggleEditUpdate={toggleEditUpdate}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	const data = state.form.STORES.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchStores: formActions.gateway,
	toggleLive: formActions.gateway,
	updateStore: formActions.gateway,
	updateStoreMenu: liveStoreActions.updateStoreMenu,
	updateSpecialMenu: liveStoreActions.updateSpecialMenu,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StoreSettingListPage);
