import { Switch } from '@mui/material';
import ListTable from '../../../../ListTable/ListTable';
import styles from './StoreInventorySettings.module.scss';

const TABLE_COLUMNS = [
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Address',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'City',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMS = [
	{
		title: 'Quality Check',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Weight Check',
		config: {
			type: 'UPDATE',
		},
	},
	{
		title: 'Temp Check',
		config: {
			type: 'UPDATE',
		},
	},
];

const ACTION_COLUMNS = [];

const COLMAP = {
	Name: 'name',
	Address: 'address',
	City: 'city',
	'Quality Check': 'isQCRequired',
	'Weight Check': 'isWeightCheckRequired',
	'Temp Check': 'isTempCheckRequired',
};

const StoreInventorySettings = (props) => {
	const { fields, query, handleInput } = props;

	const SPECIAL_ITEMS = {
		'Quality Check': (id, isEnabled) => {
			return (
				<Switch
					name='isQCRequired'
					value='isQCRequired'
					size='small'
					color='primary'
					onChange={handleInput}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'STORE',
					}}
				/>
			);
		},
		'Weight Check': (id, isEnabled) => {
			return (
				<Switch
					name='isWeightCheckRequired'
					value='isWeightCheckRequired'
					size='small'
					color='primary'
					onChange={handleInput}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'STORE',
					}}
				/>
			);
		},
		'Temp Check': (id, isEnabled) => {
			return (
				<Switch
					name='isTempCheckRequired'
					value='isTempCheckRequired'
					size='small'
					color='primary'
					onChange={handleInput}
					checked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'STORE',
					}}
				/>
			);
		},
	};

	return (
		<div className={styles.root}>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={fields.stores.filter((store) => {
					return (
						store.name
							.toLowerCase()
							.indexOf(query.toLowerCase()) !== -1
					);
				})}
				actions={[...SPECIAL_COLUMS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

StoreInventorySettings.defaultProps = {
	query: '',
};

export default StoreInventorySettings;
