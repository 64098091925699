// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { modalActions, formActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ProductListModal.module.scss';
import { CheckList } from '../../CheckList';

const HEADING = 'Select a Product';
const initialState = {
	selectedList: [],
	query: '',
};
const ProductListModal = (props) => {
	const {
		show,
		data,
		toggleModal,
		fetchProducts,
		products,
		selectedProdList,
		type,
		selectOne,
	} = props;

	const { itemTypes = [], brand = null, singleType = false } = data;

	useEffect(() => {
		if (products.length === 0) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	const [state, setState] = useState({
		...initialState,
	});

	const filteredMemoizedProducts = useMemo(() => {
		return products
			.filter((productObj) => {
				return (
					itemTypes.indexOf(productObj.skuType) !== -1 &&
					(brand == null ||
						productObj.brandId === parseInt(brand, 10)) &&
					!selectedProdList?.find((selectedProduct) => {
						return (
							selectedProduct?.productId ===
							parseInt(productObj.id, 10)
						);
					}) &&
					productObj.productName
						.toLowerCase()
						.indexOf(state.query.trim().toLowerCase()) !== -1
				);
			})
			.reduce((accProdList, currProduct) => {
				accProdList.push({
					id: currProduct.id,
					title: currProduct.productName,
				});
				return accProdList;
			}, []);
	}, [products, itemTypes, brand, selectedProdList, state.query]);

	const { selectedList } = state;

	const handleClose = () => {
		setState({
			...initialState,
			query: '',
		});
		toggleModal(false);
	};
	const handleClick = (id, isSelected) => {
		if (isSelected) {
			setState({
				...state,
				selectedList: selectedList.filter((productId) => {
					return id !== productId;
				}),
			});
		} else if (type?.IDENTITY) {
			selectedList.pop();
			setState({
				...state,
				selectedList: [id],
			});
		} else if (selectOne || singleType) {
			setState({
				...state,
				selectedList: [id],
			});
		} else {
			setState({
				...state,
				selectedList: [...selectedList, id],
			});
		}
	};
	const handleConfirm = () => {
		const selectedProducts = selectedList.map((productId) => {
			const selectedProduct = products.find((currProduct) => {
				return parseInt(currProduct.id, 10) === productId;
			});
			const productObj = {
				productId,
				discount: 0,
				timeSlots: [],
				product: {
					...selectedProduct,
				},
				isAddOn: false,
			};
			return productObj;
		});
		if (data?.handleConfirm) {
			data?.handleConfirm(selectedProducts);
		} else {
			props.handleConfirm(selectedProducts);
		}
		setState({
			...initialState,
		});
		toggleModal(false);
	};
	const handleSearchInput = (queryString) => {
		setState({
			...state,
			query: queryString,
		});
	};
	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<CheckList
					list={filteredMemoizedProducts}
					selectedItems={selectedList}
					handleItemSelect={handleClick}
					showSearch
					handleSearchInput={handleSearchInput}
					placeholder='Search Product'
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.products;
	const products = state.form.ALL_PRODUCTS.data.LIST;
	return {
		show,
		products,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleProducts,
	fetchProducts: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListModal);
