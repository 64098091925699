/* eslint-disable*/
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Button } from '@mui/material';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import BackdropLoader from '../../Components/Loaders/BackdropLoader';
import { ListTable } from '../../Components/ListTable';
import allVendorServices from '../../Services/Form Services/allvendor.services';
import { modalActions } from '../../Actions';
import DynamicTableModal from '../../Components/Modals/DynamicTableModal/DynamicTableModal';
import { notificationServices } from '../../Services';
import { urls } from '../../Constants';
import { classes } from '../../Helpers';
import styles from './listVendorContract.module.scss';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const FIND_FILTERS = [
	'store',
	'vendor',
	'product',
	'productCategory',
	'dateRangeOnly',
];

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Buyer',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Term',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expiry Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const COL_MAP = {
	ID: 'id',
	Vendor: 'vendorName',
	Buyer: 'vendorName',
	'Payment Term': 'paymentTerm',
	'Expiry Date': 'expiryDate',
	Status: 'status',
	Edit: 'id',
	Cancel: 'id',
};

const ListVendorContractPage = (props) => {
	const {
		toggleModal,
		toggleAlert,
		type,
		navState,
		fetchUserList,
		userList,
	} = props;
	const [contractData, setContractData] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const { userConfig } = useAuth();
	const history = useHistory();

	// USER START
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// function pageRoleMap(pageName) {
	// 	switch (pageName) {
	// 		case 'VENDOR':
	// 			return navState['/vendors']?.submenu['/vendor/contract']?.rolesMapped;
	// 		default:
	// 			return navState['/buyers']?.submenu['/buyer/contract']?.rolesMapped;
	// 	}
	// }
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = pageRoleMap(type);
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// USER END

	const fetchContracts = async () => {
		try {
			setLoading(true);
			const req = {
				pageType: type,
			};
			const response = await allVendorServices.ListVendorContract(req);
			if (response?.statusCode === 200) {
				const contracts = response?.data?.map((contract) => {
					const expiryDate = moment(contract?.expiryDate);
					let status = 'Valid';

					if (contract?.isDeleted) {
						status = 'Cancelled';
					} else if (expiryDate.isSame(moment(), 'day')) {
						status = 'Expiring today';
					} else if (expiryDate.isBefore(moment())) {
						status = 'Expired';
					} else if (expiryDate.isBefore(moment().add(1, 'day'))) {
						status = 'Expiring tomorrow';
					} else if (expiryDate.isBefore(moment().add(1, 'month'))) {
						status = 'Expiring within 1 month';
					}
					return {
						...contract,
						status,
					};
				});
				setContractData(contracts);
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some Error Occured',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchContracts();
	}, []);

	useEffect(() => {
		fetchContracts();
	}, [type]);

	const handleFind = async (data) => {
		try {
			setLoading(true);
			const filter = {
				...data,
				dateRangeFilter: {
					timeType: data?.timeType,
					from: moment(data?.dateRange?.from).format(
						'YYYY-MM-DD'
					),
					to: moment(data?.dateRange?.to).format('YYYY-MM-DD'),
				},
				pageType: type,
			};
			const response = await allVendorServices.VendorContractFilter(filter);
			if (response?.statusCode === 200) {
				const contracts = response?.data?.map((contract) => {
					const expiryDate = moment(contract?.expiryDate);
					let status = 'Valid';

					if (contract?.isDeleted) {
						status = 'Cancelled';
					} else if (expiryDate.isSame(moment(), 'day')) {
						status = 'Expiring today';
					} else if (expiryDate.isBefore(moment())) {
						status = 'Expired';
					} else if (expiryDate.isBefore(moment().add(1, 'day'))) {
						status = 'Expiring tomorrow';
					} else if (expiryDate.isBefore(moment().add(1, 'month'))) {
						status = 'Expiring within 1 month';
					}
					return {
						...contract,
						status,
					};
				});
				setContractData(contracts);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some Error Occured',
			});
		} finally {
			setLoading(false);
		}
		return data;
	};

	const handleAddClick = () => {
		if (type === 'BUYER') {
			history.push(`/console2/buyer/contract/create?type=${type}`);
		} else {
			history.push(`/console2/vendor/contract/create?type=${type}`);
		}
	};

	const fetchContractInfo = async (contractId) => {
		try {
			setLoading(true);
			const response = await allVendorServices.VendorContractInfo({
				contractId,
			});
			if (response?.statusCode === 200) {
				toggleModal(true, response?.data);
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some Error Occured',
			});
		} finally {
			setLoading(false);
		}
		return {};
	};

	const handleViewClick = (contract) => {
		fetchContractInfo(contract?.id);
	};

	const handleDelete = async (contractId) => {
		try {
			setLoading(true);
			const response = await allVendorServices.VendorContractDelete({
				contractId,
				type,
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: 'contract cancelled successfully',
				});
				const remainingContract = contractData.map((contract) => {
					if (contract.id === contractId) {
						// eslint-disable-next-line no-param-reassign
						contract.status = 'Cancelled';
					}
					return contract;
				});

				setContractData(remainingContract);
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some Error Occured',
			});
		} finally {
			setLoading(false);
		}
	};

	const SPECIAL_ITEMS = {
		'Expiry Date': (date) => {
			return moment(date).format('YYYY-MM-DD');
		},
		Actions: (contractId) => {
			const contractAction = contractData.find((contractTmp) => {
				return contractTmp.id === contractId;
			});
			return (
				<div>
					{contractAction?.status !== 'Cancelled' && (
						<>
							<Button
								onClick={() => {
									if (type === 'BUYER') {
										history.push(
												`${urls.EXTENSION}${urls.BUYER_CONTRACT_CREATE}?isEdit=True&contractId=${contractId}&type=${type}`
										);
									} else {
										history.push(
											`${urls.EXTENSION}${urls.VENDOR_CONTRACT_CREATE}?isEdit=True&contractId=${contractId}&type=${type}`
										);
									}
								}}
								variant='outlined'
								color='primary'
								fullWidth
							>
								Edit
							</Button>
							<div
								style={{
									width: '125px',
								}}
							>
								<Button
									variant='outlined'
									color='error'
									onClick={() => {
										const deleteContract = contractData.find((contract) => {
											return contract.id === contractId;
										});
										toggleAlert(true, {
											heading: 'Cancel Contract?',
											handleConfirm: () => {
												handleDelete(contractId);
												toggleAlert(false);
											},
											colorReverse: false,
											cancelText: 'No',
											confirmText: 'Yes',
											message: `Are you sure you want to Cancel contract for ${deleteContract?.vendorName ?? 'vendor'}?`,
										});
									}}
									fullWidth
								>
									Cancel
								</Button>
							</div>
						</>
					)}

				</div>
			);
		},
	};

	return (
		<div>
			<DynamicTableModal isBuyer={type === 'BUYER'} />
			<BackdropLoader isLoading={isLoading} showProgress />
			<div
				style={{
					padding: '10px',
				}}
			>
				<ImagesFinder
					pageLabel={type === 'BUYER' ? 'Buyer Contract' : 'vendor Contract'}
					onSubmit={handleFind}
					FILTERS={FIND_FILTERS.map((filter) => (filter === 'vendor' && type === 'BUYER' ? 'buyer' : filter))}
					filterLabels={{
						store: 'Store',
						dateRangeOnly: 'Left Over Inventory Date Range',
					}}
					dateRangeOnlyText='Expiry Date'
					additionInfo={{
						showAdd: true,
						addText: type === 'BUYER' ? 'Buyer Contract' : 'Vendor Contract',
						handleAddClick,
					}}
				/>

				<ListTable
					columns={TABLE_COLUMNS?.filter((ele) => {
						if (type === 'BUYER') {
							return ele?.title !== 'Vendor';
						}
						return true;
					})}
					colMap={type === 'BUYER'
						? Object.fromEntries(Object.entries(COL_MAP).filter(([key]) => key !== 'Vendor'))
						: COL_MAP}
					actions={[...ACTION_COLUMNS]}
					handleViewClick={handleViewClick}
					special={SPECIAL_ITEMS}
					data={contractData?.map((ele) => {
						const status = ele?.status?.split(' ').join('_');
						return {
							...ele,
							rowStyles: classes(
								styles.row,
								styles?.[status]
							),
						};
					})}
				/>
			</div>

		</div>
	);
};

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state.auth;
	return {
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleDynamicTableModal,
	toggleAlert: modalActions.toggleAlert,
	fetchUserList: authActions.fetchUserList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListVendorContractPage);
