const analyticsConstants = {
	SET_DASHBOARD_ANALYTICS: 'SET_DASHBOARD_ANALYTICS',
	FETCH_DASHBOARD_FAILURE: 'FETCH_DASHBOARD_FAILURE',
	FETCH_DASHBOARD_REQUEST: 'FETCH_DASHBOARD_REQUEST',
	SET_AI_DASHBOARD_ANALYTICS: 'SET_AI_DASHBOARD_ANALYTICS',
	FETCH_AI_DASHBOARD_FAILURE: 'FETCH_AI_DASHBOARD_FAILURE',
	FETCH_AI_DASHBOARD_REQUEST: 'FETCH_AI_DASHBOARD_REQUEST',
	FETCH_ANALYTICS_REQUEST: 'FETCH_ANALYTICS_REQUEST',
	FETCH_ANALYTICS_SUCCESS: 'FETCH_ANALYTICS_SUCCESS',
	FETCH_ANALYTICS_FAILURE: 'FETCH_ANALYTICS_FAILURE',
	UPDATE_ANALYTICS: 'UPDATE_ANALYTICS',
	SET_GEO_FILTER_DATA: 'SET_GEO_FILTER_DATA',
	SET_BRAND_FILTER_DATA: 'SET_BRAND_FILTER_DATA',
	SET_TIME_FILTER_DATA: 'SET_TIME_FILTER_DATA',
	SET_GROUP_TYPE_FILTER: 'SET_GROUP_TYPE_FILTER',
	SET_DUPLICATE_DATA_FILTER: 'SET_DUPLICATE_DATA_FILTER',
	SET_DATE_RANGE_FILTER: 'SET_DATE_RANGE_FILTER',
	SET_SINGLE_DATE_FILTER: 'SET_SINGLE_DATE_FILTER',
	SET_ANALYTICS_TYPE: 'SET_ANALYTICS_TYPE',
	SET_DATE_FILTER: 'SET_DATE_FILTER',
	SET_FAILURE_ANALYSIS_FILTER: 'SET_FAILURE_ANALYSIS_FILTER',
	SET_SELECTED_LIST_ID: 'SET_SELECTED_LIST_ID',
	SET_STATISTICAL_FILTER: 'SET_STATISTICAL_FILTER',
	SET_QC_STATISTICAL_ANALYSIS: 'SET_QC_STATISTICAL_ANALYSIS',
	SET_PRODUCT_FILTER: 'SET_PRODUCT_FILTER',
	SET_QC_SEARCH_RESULT: 'SET_QC_SEARCH_RESULT',
	SET_COGS_SEARCH_RESULT: 'SET_COGS_SEARCH_RESULT',
	SET_COMMON_SEARCH_RESULT: 'SET_COMMON_SEARCH_RESULT',
	SET_BOM_SEARCH_RESULT: 'SET_BOM_SEARCH_RESULT',
	SET_IMS_SEARCH_RESULT: 'SET_IMS_SEARCH_RESULT',
	SET_DEFECT_IMAGES: 'SET_DEFECT_IMAGES',
	RESET_DEFECT_ANALYSIS: 'RESET_DEFECT_ANALYSIS',
	SET_PRODUCT_IMAGES: 'SET_PRODUCT_IMAGES',
	RESET_PRODUCT_ANALYSIS: 'RESET_PRODUCT_ANALYSIS',
	SET_LEFT_OVER_INVENTORY_IMAGES: 'SET_LEFT_OVER_INVENTORY_IMAGES',
	RESET_LEFT_OVER_INVENTORY_ANALYSIS: 'RESET_LEFT_OVER_INVENTORY_ANALYSIS',
	SET_OUTWARDING_IMAGES: 'SET_OUTWARDING_IMAGES',
	RESET_OUTWARDING_ANALYSIS: 'RESET_OUTWARDING_ANALYSIS',
	SET_WASTAGE_IMAGES: 'SET_WASTAGE_IMAGES',
	RESET_WASTAGE_ANALYSIS: 'RESET_WASTAGE_ANALYSIS',
	// ==================
	SET_CONTAINER_IMAGES: 'SET_CONTAINER_IMAGES',
	RESET_CONTAINER_ANALYSIS: 'RESET_CONTAINER_ANALYSIS',
	// ==================
	SET_WASTAGE_COMPONENTS: 'SET_WASTAGE_COMPONENTS',
	RESET_WASTAGE_COMPONENTS: 'RESET_WASTAGE_COMPONENTS',
	SET_COOKING_IMAGES: 'SET_COOKING_IMAGES',
	RESET_COOKING_ANALYSIS: 'RESET_COOKING_ANALYSIS',
	SET_UNDETECTED_PRODUCTS_FILTER: 'SET_UNDETECTED_PRODUCTS_FILTER',
	SET_MEAL_TYPE_FILTER: 'SET_MEAL_TYPE_FILTER',
	SET_LIST_DATA_FILTER: 'SET_LIST_DATA_FILTER',
	SET_VALIDATED_DATA_FILTER: 'SET_VALIDATED_DATA_FILTER',
	SET_IDENTIFIED_DATA_FILTER: 'SET_IDENTIFIED_DATA_FILTER',
	SET_FEATURES_DATA_FILTER: 'SET_FEATURES_DATA_FILTER',
	SET_PRODUCT_COUNT_FILTER: 'SET_PRODUCT_COUNT_FILTER',
	SET_IMS_STOCK_FILTER: 'SET_IMS_STOCK_FILTER',
	SET_STORE_FILTER: 'SET_STORE_FILTER',
	SET_PROCESS_TYPE_FILTER: 'SET_PROCESS_TYPE_FILTER',
	SET_SKU_TYPE_FILTER: 'SET_SKU_TYPE_FILTER',
	SET_FROM_STORE_FILTER: 'SET_FROM_STORE_FILTER',

	RESET_AI_OUTWARDING_ANALYSIS: 'RESET_AI_OUTWARDING_ANALYSIS',
	SET_AI_OUTWARDING_IMAGES: 'SET_AI_OUTWARDING_IMAGES',

	RESET_AI_GRN_ANALYSIS: 'RESET_AI_GRN_ANALYSIS',
	SET_AI_GRN_IMAGES: 'SET_AI_GRN_IMAGES',

	RESET_AI_DISPATCH_ANALYSIS: 'RESET_AI_DISPATCH_ANALYSIS',
	SET_AI_DISPATCH_IMAGES: 'SET_AI_DISPATCH_IMAGES',

	RESET_DIGIT_TEMPERATURE_ANALYSIS: 'RESET_DIGIT_TEMPERATURE_ANALYSIS',
	SET_DIGIT_TEMPERATURE_IMAGES: 'SET_DIGIT_TEMPERATURE_IMAGES',

	RESET_TEMPERATURE_ANALYSIS: 'RESET_TEMPERATURE_ANALYSIS',
	SET_TEMPERATURE_IMAGES: 'SET_TEMPERATURE_IMAGES',

	SET_ANALYSIS_DASHBOARD_FILTERS: 'SET_ANALYSIS_DASHBOARD_FILTERS',
};
export const timeFilterMap = {
	month: [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	],
	monthNumber: {
		Jan: 0,
		Feb: 1,
		Mar: 2,
		Apr: 3,
		May: 4,
		Jun: 5,
		Jul: 6,
		Aug: 7,
		Sep: 8,
		Oct: 9,
		Nov: 10,
		Dec: 11,
	},
};

export const UI_FILTERS = {
	FAILURE: [
		{
			title: 'Weight Analysis',
			key: 'WEIGHT',
			children: [
				{
					title: 'Overweight Analysis',
					key: 'over-weight',
				},
				{
					title: 'Underweight Analysis',
					key: 'under-weight',
				},
			],
		},
		{
			title: 'Temperature Analysis',
			key: 'TEMPERATURE',
			children: [
				{
					title: 'Over Temp Analysis',
					key: 'over-temperature',
				},
				{
					title: 'Under Temp Analysis',
					key: 'under-temperature',
				},
			],
		},
		{
			title: 'Defect Analysis',
			key: 'DEFECT',
			children: [],
		},
	],
};

export const DATE_FORMAT = 'dd-MM-yyyy';

export const orderTypes = [
	{
		id: 1,
		name: 'Vendor PO',
		enum: 'VENDOR',
		skuTypes: ['INGREDIENT', 'PACKAGING', 'OTHER'],
		fields: {
			vendor: true,
			fromStore: false,
			deliverTo: true,
			poId: true,
			date: true,
			expectedDeliveryDate: true,
			paymentTerms: true,
			generateStandardPO: false,
			itemsTypes: ['INGREDIENT', 'PACKAGING', 'OTHER'],
			process: true,
			vendorLocation: true,
		},
	},
	{
		id: 2,
		name: 'Base Kitchen PO',
		enum: 'PURCHASEORDER',
		skuTypes: ['PRODUCT', 'INTERMEDIATE'],
		fields: {
			vendor: false,
			fromStore: true,
			deliverTo: true,
			poId: true,
			date: true,
			expectedDeliveryDate: true,
			paymentTerms: true,
			generateStandardPO: true,
			itemsTypes: ['PRODUCT', 'INTERMEDIATE'],
			process: true,
			vendorLocation: false,
		},
	},
	{
		id: 3,
		name: 'Base Warehouse PO',
		enum: 'PURCHASEORDER',
		skuTypes: ['INGREDIENT', 'PACKAGING', 'OTHER'],
		fields: {
			vendor: false,
			fromStore: true,
			deliverTo: true,
			poId: true,
			date: true,
			expectedDeliveryDate: true,
			paymentTerms: true,
			generateStandardPO: false,
			itemsTypes: ['INGREDIENT', 'PACKAGING', 'OTHER'],
			vendorLocation: false,
			process: true,
		},
	},
	{
		id: 4,
		name: 'CapEx order',
		enum: 'CAPEX',
		skuTypes: [],
		fields: {
			vendor: true,
			fromStore: false,
			deliverTo: true,
			poId: true,
			date: true,
			expectedDeliveryDate: true,
			paymentTerms: true,
			generateStandardPO: false,
			itemsTypes: [],
			process: false,
			vendorLocation: true,
		},
	},
	{
		id: 5,
		name: 'Transfer Order',
		enum: 'TRANSFER',
		skuTypes: [
			'INGREDIENT',
			'PRODUCT',
			'INTERMEDIATE',
			'PACKAGING',
			'OTHER',
		],
		fields: {
			vendor: false,
			store: true,
			date: true,
			poId: true,
			itemsTypes: [],
			fromProcess: true,
			toProcess: true,
			transferOrderType: true,
		},
	},
	{
		id: 6,
		name: 'Work order',
		enum: 'TRANSFER',
		skuTypes: [
			'INGREDIENT',
			'PRODUCT',
			'INTERMEDIATE',
			'PACKAGING',
			'OTHER',
		],
		fields: {
			vendor: false,
			store: true,
			date: true,
			shift: true,
			poId: true,
			itemsTypes: [],
			fromProcess: true,
			toProcess: true,
		},
	},
	{
		id: 7,
		name: 'Internal Store Asset Order',
		enum: 'INTERNAL_STORE_ASSET_ORDER',
		skuTypes: [],
		fields: {
			vendor: false,
			fromStore: true,
			deliverTo: true,
			poId: true,
			date: true,
			expectedDeliveryDate: true,
			paymentTerms: true,
			generateStandardPO: false,
			itemsTypes: [],
			process: false,
			vendorLocation: false,
		},
	},
	{
		id: 8,
		name: 'BUYER SO',
		enum: 'BUYER',
		skuTypes: ['PRODUCT'],
		fields: {
			buyer: true,
			fromStore: true,
			deliverTo: false,
			poId: true,
			date: true,
			expectedDeliveryDate: true,
			paymentTerms: true,
			generateStandardPO: false,
			itemsTypes: ['PRODUCT'],
			process: false,
			buyerLocation: true,
		},
	},
];

export default analyticsConstants;
