/* eslint-disable no-tabs */
/* eslint-disable prefer-template */
export const CHART_COLORS = [
	{
		backgroundColor: '#cb0101',
		borderColor: 'rgb(74, 112, 251)',
	},
	{
		backgroundColor: '#FFB703',
		borderColor: 'rgb(74, 112, 251)',
	},
	{
		backgroundColor: '#adb5bd',
		borderColor: 'rgb(74, 112, 251)',
	},
	{
		backgroundColor: '#0e8116',
		borderColor: 'rgb(74, 112, 251)',
	},
	{
		backgroundColor: 'rgb(74, 112, 251, 0.5)',
		borderColor: 'rgb(74, 112, 251)',
	},
	{
		backgroundColor: 'rgba(153, 102, 255, 0.5)',
		borderColor: 'rgb(153, 102, 255)',
	},
	{
		backgroundColor: 'rgba(75, 192, 192, 0.5)',
		borderColor: 'rgb(75, 192, 192)',
	},
	{
		backgroundColor: 'rgba(255, 99, 132, 0.5)',
		borderColor: 'rgb(255, 99, 132)',
	},
];
export const NEW_CHART_COLORS = [
	{
		backgroundColor: '#ff6961',
		borderColor: '#ff6961',
	},
	{
		backgroundColor: '#66c24d',
		borderColor: '#66c24d',
	},
	{
		backgroundColor: '#fdf8de',
		borderColor: '#fdf8de',
	},
	{
		backgroundColor: '#ffe766',
		borderColor: '#ffe766',
	},
	{
		backgroundColor: '#c4c4c4',
		borderColor: '#c4c4c4',
	},
	{
		backgroundColor: 'rgba(153, 102, 255, 0.5)',
		borderColor: 'rgb(153, 102, 255)',
	},
	{
		backgroundColor: '#fdf8de',
		borderColor: '#fdf8de',
	},
];

export const COGS_CHART_COLORS = [
	{
		backgroundColor: '#66c24d',
		borderColor: 'rgb(102, 194, 77)',
	},
	{
		backgroundColor: '#ed9121',
		borderColor: 'rgb(237, 145, 33)',
	},
	{
		backgroundColor: '#ff6961',
		borderColor: 'rgb(255, 105, 97)',
	},
	{
		backgroundColor: 'rgba(158, 123, 181, 0.5)',
		borderColor: 'rgba(158, 123, 181, 0.5)',
	},
	{
		backgroundColor: 'rgba(82, 121, 111, 0.5)',
		borderColor: 'rgb(82, 121, 111)',
	},
];

export const STAT_COLORS = [
	'rgb(74, 112, 251)',
	'rgb(153, 102, 255)',
	'rgb(75, 192, 192)',
	'rgb(255, 99, 132)',
	'rgb(255, 159, 64)',
	'rgb(95, 95, 95)',
	'rgb(102, 194, 77)',
	'rgb(104, 81, 85)',
	'rgb(244, 88, 102)',
	'rgb(73, 56, 67)',
	'rgb(80, 114, 85)',
	'rgb(59, 57, 35)',
	'rgb(154, 39, 90)',
	'rgb(29, 17, 40)',
	'rgb(13, 92, 99)',
	'rgb(88, 213, 107)',
	'#B34D4D',
	'#80B300',
	'#809900',
	'#E6B3B3',
	'#6680B3',
	'#66991A',
	'#FF99E6',
	'#CCFF1A',
	'#FF1A66',
	'#E6331A',
	'#33FFCC',
	'#66994D',
	'#B366CC',
	'#4D8000',
	'#B33300',
	'#CC80CC',
];
function generateUniqueNeonColors(numColors) {
	const neonColors = new Set();

	while (neonColors.size < numColors) {
		const r = Math.floor(Math.random() * 256);
		const g = Math.floor(Math.random() * 256);
		const b = Math.floor(Math.random() * 256);
		const color =
			'#' +
			('00' + r.toString(16)).slice(-2) +
			('00' + g.toString(16)).slice(-2) +
			('00' + b.toString(16)).slice(-2);
		neonColors.add(color);
	}

	return Array.from(neonColors);
}

export const BOX_COLORS = generateUniqueNeonColors(100);

// export const BOX_COLORS = [
// 	'#5800FF',
// 	'#005792',
// 	'#FF0075',
// 	'#3330E4',
// 	'#14C38E',
// 	'#F55353',
// 	'#143F6B',
// 	'#0E185F',
// 	'#000D6B',
// 	'#FF0000',
// 	'#570A57',
// 	'#000272',
// 	'#6527BE',
// 	'#F5F5F5',
// 	'#11009E',
// 	'#068DA9',
// 	'#00C4FF',
// 	'#F6FA70',
// 	'#321E1E',
// 	'#4E3636',
// 	'#FF0060',
// 	'#FFF4F4',
// 	'#9AC5F4',
// 	'#A0C49D',
// 	'#FF8551',
// 	'#17594A',
// 	'#E7B10A',
// 	'#A7EDE7',
// 	'#DFD7BF',
// 	'#3F2305',
// 	'#4C4B16',
// 	'#F7F1E5',
// ];

export const DASHBOARD_CHARTS_COLORS = {
	pieChart: [
		'RGBA(252, 185, 125, 1)',
		'RGBA(139, 232, 203, 1)',
		'RGBA(245, 100, 118, 1)',
		'RGBA(234, 196, 213, 1)',
		'rgba(79, 160, 149, .5)',
		'rgba(73, 113, 116, 0.5)',
		'rgba(235, 100, 64, 0.5)',
		'rgba(79, 80, 149, .5)',
		'rgba(73, 113, 11, 0.5)',
		'rgba(23, 100, 64, 0.5)',
		'rgba(79, 210, 199, .5)',
		'rgba(89, 13, 11, 0.5)',
		'rgba(100, 100, 164, 0.5)',
		'rgba(3, 113, 100, 0.5)',
		'rgba(113, 40, 104, 0.5)',
	],
	barChart: [
		'RGBA(252, 185, 125, 1)',
		'RGBA(139, 232, 203, 1)',
		'RGBA(245, 100, 118, 1)',
		'RGBA(234, 196, 213, 1)',
		'rgba(79, 120, 149, .5)',
		'rgba(99, 150, 116, 0.8)',
		'rgba(73, 80, 64, .7)',
		'rgba(149, 180, 149, .5)',
		'rgba(73, 113, 11, 0.5)',
		'rgba(23, 100, 64, 0.5)',
		'rgba(79, 210, 199, .5)',
		'rgba(89, 13, 11, 0.5)',
		'rgba(100, 100, 164, 0.5)',
		'rgba(49, 10, 109, .5)',
		'rgba(3, 113, 100, 0.5)',
		'rgba(113, 40, 104, 0.5)',
	],
	barChartLine: [
		'rgba(79, 160, 149, .5)',
		'rgba(255, 113, 116, 0.5)',
		'rgba(73, 80, 64, .7)',
		'rgba(149, 180, 149, .5)',
		'rgba(73, 113, 11, 0.5)',
		'rgba(23, 100, 64, 0.5)',
		'rgba(79, 210, 199, .5)',
		'rgba(89, 13, 11, 0.5)',
		'rgba(100, 100, 164, 0.5)',
		'rgba(49, 10, 109, .5)',
		'rgba(3, 113, 100, 0.5)',
		'rgba(113, 40, 104, 0.5)',
	],
	line: [
		'rgba(79, 160, 149, .5)',
		'rgba(73, 113, 116, 0.5)',
		'rgba(235, 100, 64, 0.5)',
		'rgba(79, 80, 149, .5)',
		'rgba(73, 113, 11, 0.5)',
		'rgba(23, 100, 64, 0.5)',
		'rgba(89, 13, 11, 0.5)',
		'rgba(100, 100, 164, 0.5)',
		'rgba(49, 10, 109, .5)',
		'rgba(3, 113, 100, 0.5)',
		'rgba(113, 40, 104, 0.5)',
	],
	highlightsColor: [
		'RGBA(252, 185, 125, 1)',
		'RGBA(139, 232, 203, 1)',
		'RGBA(245, 100, 118, 1)',
		'RGBA(234, 196, 213, 1)',
	],
};

export const CHARTS_CONSTANTS = {
	primary: '#FFB703',
	orange: '#FCB97D',
	green: '#8BE8CB',
	red: '#F56476',
	pink: '#EAC4D5',
};

export default CHART_COLORS;
