/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { ZoomIn } from '@mui/icons-material';
import { Checkbox, Skeleton } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import moment from 'moment';
import { classes, convertWeightUnit } from '../../../Helpers';
import styles from './ImageThumbnail.module.scss';
// eslint-disable-next-line import/no-cycle
import { legendWithColors } from '../../../Pages/Analytics/ProductAnalysis/ProductImagesPage/ProductImagesPage';

const STRING_FORMAT = 'D MMM YYYY kk:mm:ss';
const ImageThumbnail = (props) => {
	const {
		source,
		isExpandable,
		onClick,
		egaPercentageSelected,
		data,
		type,
		isInternal,
		isUS,
		machineDetection,
		identificationType,
		categoryHeading,
		featureCount,
		annotation,
		checkedItemList = [],
		updateChecked,
		hideHover,
		hideIdentificationType,
	} = props;

	const [loading, setLoading] = useState(true);
	const thisItemChecked =
		checkedItemList.findIndex((ele) => {
			return ele?.qcId === annotation?.qcId;
		}) !== -1;
	const createdAtDate = moment(annotation?.createdAt, STRING_FORMAT);
	return (
		<div
			className={classes(
				styles.root,
				isExpandable ? styles.expandable : ''
			)}
		>
			{isInternal && thisItemChecked && (
				<div className={styles?.selected} />
			)}
			{isInternal && annotation?.qcId && (
				<Checkbox
					checked={thisItemChecked}
					sx={{
						color: '#222',
						'&.Mui-checked': {
							color: 'black',
						},
					}}
					onChange={() => {
						// console.log('test2');
						// event.stopPropagation();
						updateChecked(annotation);
					}}
					className={styles.checkbox}
				/>
			)}

			<div className={styles.values}>
				{Object.entries(legendWithColors).map(([key, value]) => {
					if (
						(data?.[key] != null &&
							egaPercentageSelected &&
							key !== 'confidence') ||
						(key === 'confidence' &&
							isInternal &&
							machineDetection?.[0]?.confidence > 0)
					) {
						return (
							<div
								style={{
									backgroundColor: value?.color,
								}}
							>
								{key === 'confidence'
									? machineDetection?.[0]?.confidence
									: value?.suffix
									? Number(data?.[key]).toFixed(1) +
									  value.suffix
									: convertWeightUnit(
											data?.[key],
											isUS ? 'oz' : 'gram'
									  )}
							</div>
						);
					}
					return <></>;
				})}
			</div>
			{type === 'TEMPERATURE_IMAGES' && (
				<div className={styles.info}>
					{annotation?.poId && (
						<div style={{ fontWeight: 500, fontSize: '.7rem' }}>
							{annotation?.clientPoId ?? annotation?.poId
								? `PO: ${
										annotation?.clientPoId ??
										`#${annotation?.poId}`
								  }`
								: ''}
						</div>
					)}
					<div style={{ fontWeight: 500, fontSize: '.7rem' }}>
						{annotation?.storeName}
					</div>
					<div style={{ fontSize: '.7rem' }}>
						{createdAtDate.format('h:mm a | Do MMM YYYY')}
					</div>
				</div>
			)}

			{loading && source != null && (
				<Skeleton className={styles.skeleton} />
			)}
			{featureCount > 0 && (
				<span className={styles['feature-count']}>{featureCount}</span>
			)}
			{!hideIdentificationType && (
				<div
					className={classes(
						styles['identification-type'],
						identificationType && identificationType === true
							? styles.AI
							: styles.MN
					)}
				>
					{identificationType && identificationType === true
						? 'AI'
						: 'MN'}
				</div>
			)}
			{isInternal && annotation.validation && (
				<RadioButtonCheckedIcon className={styles?.validated} />
			)}
			{isInternal && categoryHeading !== data?.label && (
				<div className={classes(styles.labelNameText)}>
					{data?.label}
				</div>
			)}
			<img
				style={{
					imageRendering: 'pixelated',
				}}
				src={source}
				loading='lazy'
				onLoad={() => {
					setLoading(false);
				}}
				onError={() => {
					setLoading(false);
				}}
				alt=''
			/>
			{!hideHover && annotation?.qcId && (
				<div
					onClick={(event) => {
						onClick(event);
					}}
					className={styles.overlay}
				>
					<ZoomIn />
					<span>Click to Expand</span>
				</div>
			)}
		</div>
	);
};

ImageThumbnail.defaultProps = {
	isExpandable: false,
	onClick: () => {},
};

export default ImageThumbnail;
