import styles from './VendorProductMapping.module.scss';

function VendorProductMapping() {
	return (
		<div className={styles.root}>
			<div className={styles.lhs}>
				<p>VENDOR 1</p>
				<p>VENDOR 2</p>
				<p>VENDOR 3</p>
				<p>VENDOR 4</p>
				<p>VENDOR 5</p>
			</div>
			<div className={styles.rhs}>
				<p>VENDOR 1</p>
				<p>VENDOR 2</p>
				<p>VENDOR 3</p>
				<p>VENDOR 4</p>
				<p>VENDOR 5</p>
			</div>
		</div>
	);
}

export default VendorProductMapping;
