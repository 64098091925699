import { connect } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ListTable from '../../../../Components/ListTable/ListTable';
import styles from './OrderTable.module.scss';

const TABLE_COLUMNS = [
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Received At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Value',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Order Delivered',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'Order Id': 'id',
	'Order Received At': 'orderReceivedAt',
	'Order Delivered': 'isOrderDelivered',
	'Order Value': 'orderValue',
};
const OrderTable = (props) => {
	const { data } = props;
	const SPECIAL_ITEMS = {
		'Order Delivered': (isOrderDelivered) => {
			return isOrderDelivered ? (
				<div>
					<CheckIcon
						style={{
							color: 'green',
						}}
					/>
				</div>
			) : (
				<div>
					<ClearIcon
						style={{
							color: 'red',
						}}
					/>
				</div>
			);
		},
	};
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles.heading}`}>Order List Data</div>
			<div className={styles['list-container']}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data || []}
					actions={[...SPECIAL_COLUMNS]}
					special={SPECIAL_ITEMS}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.analytics.STORE_ANALYSIS.ordersData;
	return {
		data,
	};
};
export default connect(mapStateToProps, null)(OrderTable);
