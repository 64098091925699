import { cloneDeep, update } from 'lodash';

const qualitySettingConfig = {
	initialState: {
		fields: {
			isMasterQC: false,
			qcMode: 'RANGE',
			stores: [],
		},
	},
	/**
	 * @param {Object} state Component State
	 * @param {Object} data API data from Redux
	 * @returns new state
	 */
	serializer: (state, data) => {
		const clonedState = cloneDeep(state);
		return update(clonedState, 'fields', (fields) => {
			const newFields = fields;
			newFields.isMasterQC = data.ims;
			newFields.qcMode = data.imsMeasurementMode;
			newFields.stores = data.storeData.map((store) => {
				return {
					id: store.id,
					name: store.name,
					address: store.address,
					city: store.city,
					isQCRequired: store.imsQualityCheck,
					isWeightCheckRequired: store.imsWeightCheck,
					isTempCheckRequired: store.imsTempCheck,
				};
			});
			return newFields;
		});
	},
	/**
	 * @param {String} type Master | Store
	 * @param {Object} data object containing fieldName, fieldValue and id (for store)
	 * @returns {Object} object containing request payload
	 */
	deserializer: (type, data) => {
		const { fieldValue, fieldName } = data;
		const refMap = {
			isMasterQC: 'ims',
			qcMode: 'imsMeasurementMode',
			store: {
				id: 'id',
				name: 'name',
				address: 'address',
				city: 'city',
				isQCRequired: 'imsQualityCheck',
				isWeightCheckRequired: 'imsWeightCheck',
				isTempCheckRequired: 'imsTempCheck',
			},
		};
		const req = {};
		switch (type) {
			case 'MASTER':
				req.data = {
					[refMap[fieldName]]: fieldValue,
					storeId: null,
				};
				break;
			case 'STORE':
				req.data = {
					[refMap.store[fieldName]]: fieldValue,
					storeId: data.id,
				};
				break;
			default:
				break;
		}
		req.params = {};
		return req;
	},
};

export default qualitySettingConfig;
