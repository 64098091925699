import { endpoints } from '../../Constants';
import { handleResponse } from '../../Helpers';

const aiModelCreation = {
	gateway,
};

function gateway({ req = {}, method }) {
	switch (method) {
		case 'CREATE': {
			return startAiModelCreation({
				req,
			});
		}
		case 'LIST': {
			return fetchModelHistoryList({
				req,
			});
		}
		default:
			break;
	}
	return new Promise(() => {});
}

function startAiModelCreation({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return new Promise((resolve) => {
	// setTimeout(() => {
	// resolve(mockResponse(endpoints.AI_CREATION, requestOptions));
	// }, 1000);
	// });
	return fetch(endpoints.AI_CREATION, requestOptions).then(handleResponse);
}

function fetchModelHistoryList({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockResponse(endpoints.AI_HISTORY, requestOptions, handleResponse);
	return fetch(endpoints.AI_HISTORY, requestOptions).then(handleResponse);
}

// eslint-disable-next-line no-unused-vars
function mockResponse() {
	const mockData = {
		statusCode: 200,
		status: 'SUCCESS',
		data: [
			{
				storeId: 142,
				modelType: 'FACE',
				status: 'DEPLOYED',
				createdAt: '2022-12-10',
				updatedAt: '2022-12-10',
				metaData: null,
				userId: null,
				user: null,
			},
			{
				storeId: 142,
				modelType: 'FACE',
				status: 'DEPLOYED',
				createdAt: '2022-12-10',
				updatedAt: '2022-12-10',
				metaData: null,
				userId: null,
				user: null,
			},
			{
				storeId: 142,
				modelType: 'FACE',
				status: 'DEPLOYED',
				createdAt: '2022-12-10',
				updatedAt: '2022-12-10',
				metaData: null,
				userId: null,
				user: null,
			},
			{
				storeId: 142,
				modelType: 'FACE',
				status: 'COMPLETED',
				createdAt: '2022-12-10',
				updatedAt: '2022-12-10',
				metaData: null,
				userId: null,
				user: null,
			},
			{
				storeId: 142,
				modelType: 'FACE',
				status: 'COMPLETED',
				createdAt: '2022-12-10',
				updatedAt: '2022-12-10',
				metaData: null,
				userId: null,
				user: null,
			},
			{
				storeId: 142,
				modelType: 'FACE',
				status: 'DEPLOYED',
				createdAt: '2022-12-11',
				updatedAt: '2022-12-11',
				metaData: null,
				userId: null,
				user: null,
			},
		],
	};
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(mockData);
		}, 1000);
	});
}

export default aiModelCreation;
