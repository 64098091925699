import _ from 'lodash';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import styles from './AnalysisGraph.module.scss';
import {
	MostPopularDays,
	ProductRevenueDistribution,
	StoreRevenue,
} from '../../../../Components/Analytics';
import analyticsAction from '../../../../Actions/analytics.actions';
import analyticsGraphConfig from './analyticsGraph.config';
import { isEmpty } from '../../../../Helpers';

const AnalysisGraph = (props) => {
	const [state, setState] = useState({
		...analyticsGraphConfig.initialState,
	});
	const { fetchData, data, geoFilter, brandFilterData, setTimeFilter } =
		props;
	const onClick = (e) => {
		setTimeFilter(e.target.value);
		setState(
			_.update(_.cloneDeep(state), 'filter', (tempState) => {
				const newState = {
					...tempState,
					timeFilter: e.target.value,
				};
				return newState;
			})
		);
	};
	const handlePostData = () => {
		fetchData({
			entity: 'STORE_ANALYSIS',
			req: {
				data: {
					...analyticsGraphConfig.serializer(
						state,
						geoFilter,
						brandFilterData.selectedList
					).filter,
				},
			},
		});
		setTimeFilter(state.filter.timeFilter);
	};
	useEffect(() => {
		if (!isEmpty(data)) {
			setState({
				...state,
				...analyticsGraphConfig.deserializer(state, data),
			});
		}
	}, [data]);
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles['graph-container']}`}>
				<div className={`${styles['store-revenue']}`}>
					<StoreRevenue data={state?.fields?.graphData} />
				</div>
				<div className={`${styles['product-revenue']}`}>
					<ProductRevenueDistribution
						onClick={onClick}
						handleApply={handlePostData}
						data={state?.fields?.pieData}
					/>
				</div>
				<div className={`${styles['popular-graph-data']}`}>
					<MostPopularDays data={state?.fields?.popularGraphData} />
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.analytics.STORE_ANALYSIS;
	const geoFilter = state.analytics.GEO_FILTER.store;
	const brandFilterData = state.analytics.BRAND_FILTER;
	return {
		data,
		geoFilter,
		brandFilterData,
	};
};
const mapDispatchToProps = {
	fetchData: analyticsAction.gateway,
	setTimeFilter: analyticsAction.setTimeFilterData,
};
export default connect(mapStateToProps, mapDispatchToProps)(AnalysisGraph);
