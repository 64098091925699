/* eslint-disable no-shadow */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-useless-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
	useCallback, useEffect, useMemo, useReducer, useState
} from 'react';
import { connect } from 'react-redux';
import {
	useHistory, useLocation, useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { getStateFromGSTIN, useQuery } from 'src/Helpers';
import { BackdropLoader } from 'src/Components/Loaders';
import { CheckPicker, SelectPicker } from 'rsuite';
import config from '../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder.config';
import QuoteItemSelection from './QuoteItemSelection';
import { formActions, modalActions } from '@/Actions';
import assetActions from '@/Actions/asset.actions';
import styles from '../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder.module.scss';
import { notificationServices } from '@/Services/Notification';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';
import { useAuth } from '../Auth/Login/auth';
import CreateQuoteHeader from './CreateQuoteHeader';
import quoteServices from '@/Services/QuoteServices/quote.services';

const initialState = [
	{
		assetType: '',
		oems: [],
		coreSpecifications: [],
		qty: '',
		isNew: true,
	},
];

function CreateQuote(props) {
	const {
		vendors,
		storesList,
		fetchProducts,
		fetchVendors,
		fetchStoresList,
	} = props;
	const query = useQuery();
	const history = useHistory();
	const { userConfig } = useAuth();

	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const [assetData, setAssetData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showRow, setShowRow] = useState(false);
	const [row, setRow] = useState(initialState);
	const [store, setStore] = useState(null);
	const [vendor, setVendor] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const location = useLocation();
	const params = useParams();
	const { rfq } = location?.state || {};

	useEffect(() => {
		if (rfq) {
			setVendor(rfq?.vendors);
			setStore(rfq?.store);
			setRow(rfq?.items);
			setEditMode(true);
		}
	}, [rfq]);
	const filterTableData = row?.filter((item) => {
		return !Object.values(item).every((value) => value === '' ||
			(Array.isArray(value) && value.length === 0) ||
			(typeof value === 'object' && value !== null && Object.keys(value).length === 0) ||
			(typeof value === 'boolean' && value === true));
	});

	useEffect(() => {
		const fetchAssetList = async () => {
			try {
				const response = await quoteServices?.requestQuoteAssetList();
				if (response?.statusCode === 200) {
					const data = await response?.data;
					setAssetData(data);
				}
			} catch (error) {
				<p>{error}</p>;
			}
		};
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchAssetList();
	}, []);

	const handleSave = async () => {
		let hasError = false;

		if (vendor?.length === 0) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Vendor is Required',
			});
			hasError = true;
			return;
		}

		if (store === null) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Store is Required',
			});
			hasError = true;
			return;
		}
		filterTableData?.forEach((item) => {
			const asset = assetData?.find((val) => val?.id === item?.assetType);
			const mandatorySpecs = asset?.coreSpecifications?.filter((spec) => spec.rfqMandatory);

			if (mandatorySpecs?.length > 0) {
				if (!item?.coreSpecifications || item?.coreSpecifications?.length === 0) {
					notificationServices?.generateNotification({
						type: 'error',
						message: 'Specification is Required for each feild',
					});
					hasError = true;
					return;
				}
				const emptySpecifications = mandatorySpecs?.filter((spec) => {
					const matchingSpec = item?.coreSpecifications?.find(
						(itemSpec) => itemSpec?.coreSpecification?.id === spec?.id
					);
					const isEmpty = !matchingSpec?.value || matchingSpec?.value === '';
					if (spec?.dataType === 'BOOLEAN') {
						return false;
					}
					if (isEmpty) {
						notificationServices?.generateNotification({
							type: 'error',
							message: `${spec.label || 'Specification'} is required`,
						});
					}
					return isEmpty;
				});
				const hasEmptyValue = emptySpecifications?.length > 0;
				hasError = hasEmptyValue;
				if (hasEmptyValue) return;
			}
			if (item?.oems?.length === 0) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'OEM is Required',
				});
				hasError = true;
				return;
			}
		});
		if (hasError) return;
		try {
			const response = await quoteServices?.requestQuoteAssetSave({
				req: {
					data: {
						vendors: vendor,
						store,
						items: filterTableData.map((item) => ({
							assetType: item?.assetType,
							oems: item?.oems,
							coreSpecifications: item?.coreSpecifications,
							qty: item?.qty,
						})),
					},
				},
			});
			if (response?.statusCode === 200) {
				setStore(null);
				setVendor([]);
				setRow(initialState);
				notificationServices?.generateNotification({
					type: 'success',
					message: 'Quote Request Sent Successfully',
				});
				history.goBack();
			} else {
				notificationServices?.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};

	const handleEdit = async () => {
		let hasError = false;
		if (vendor?.length === 0) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Vendor is Required',
			});
			hasError = true;
			return;
		}

		if (store === null) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Store is Required',
			});
			hasError = true;
			return;
		}
		filterTableData?.forEach((item) => {
			const asset = assetData?.find((val) => val?.id === item?.assetType);
			const mandatorySpecs = asset?.coreSpecifications?.filter((spec) => spec.rfqMandatory);

			if (mandatorySpecs?.length > 0) {
				if (!item?.coreSpecifications || item?.coreSpecifications?.length === 0) {
					notificationServices?.generateNotification({
						type: 'error',
						message: 'Specification is Required for each feild',
					});
					hasError = true;
					return;
				}
				const emptySpecifications = mandatorySpecs?.filter((spec) => {
					const matchingSpec = item?.coreSpecifications?.find(
						(itemSpec) => itemSpec?.coreSpecification?.id === spec?.id
					);
					const isEmpty = !matchingSpec?.value || matchingSpec?.value === '';
					if (spec?.dataType === 'BOOLEAN') {
						return false;
					}
					if (isEmpty) {
						notificationServices?.generateNotification({
							type: 'error',
							message: `${spec.label || 'Specification'} is required`,
						});
					}
					return isEmpty;
				});
				const hasEmptyValue = emptySpecifications?.length > 0;
				hasError = hasEmptyValue;
				if (hasEmptyValue) return;
			}
			if (item?.oems?.length === 0) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'OEM is Required',
				});
				hasError = true;
				return;
			}
		});
		if (hasError) return;
		try {
			setIsLoading(true);
			const response = await quoteServices?.requestQuoteAssetSave({
				req: {
					data: {
						id: params?.id,
						vendors: vendor,
						store,
						items: filterTableData.map((item) => ({
							assetType: item?.assetType,
							oems: item?.oems,
							coreSpecifications: item?.coreSpecifications?.map((spec) => ({
								coreSpecification: {
									id: spec?.coreSpecification?.id,
								},
								value: spec?.value === 'true' || spec?.value === 'false' ? Boolean(spec?.value) : spec?.value,
							})),
							qty: item?.qty,
						})),
					},
				},
			});
			if (response?.statusCode === 200) {
				setStore(null);
				setVendor([]);
				setRow(initialState);
				notificationServices?.generateNotification({
					type: 'success',
					message: 'Quote Request Updated Successfully',
				});
				history.goBack();
			} else {
				notificationServices?.generateNotification({
					type: 'error',
					message: response?.error,
				});
				history.goBack();
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error || 'Failed to update quote request. Please try again.',
			});
		}
		setIsLoading(false);
		setEditMode(false);
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} showProgress />
			<CreateQuoteHeader history={history} handleSave={editMode ? handleEdit : handleSave} editMode={editMode} />
			<div className='w-[85%]'>
				<div className={styles.body}>
					<div className='flex gap-20 justify-center items-center'>
						<label
							className='text-red-600'
							htmlFor='vendorSelection'
						>
							Vendor Name*
						</label>
						<CheckPicker
							data={vendors?.map((item) => ({
								label: item?.name, value: item?.id,
							}))}
							value={vendor?.map((item) => item?.id)}
							placeholder='Select Vendors'
							style={{
								width: 304,
							}}
							menuStyle={{
								width: 304,
							}}
							onChange={(value) => setVendor(value.map((item) => ({
								id: item,
							})))}
						/>
					</div>
					<div className='flex gap-[5.7rem] justify-center items-center'>
						<label className='text-red-600' htmlFor='store'>
							Select Store*
						</label>
						<SelectPicker
							value={store}
							data={storesList?.map((item) => (
								{
									label: item?.name, value: item?.id,
								}
							))}
							style={{
								width: 304,
							}}
							menuStyle={{
								width: 304,
							}}
							onChange={(value) => {
								setStore(value);
								if (editMode) {
									setShowRow(false);
								} else {
									setShowRow(true);
								}
							}}
						/>
					</div>
					<div className='flex gap-8 justify-center items-center'>
						<label className={styles.mandatory} htmlFor='dateSelector'>
							Quote Request Date*
						</label>
						<span>{moment(state?.date)?.format('YYYY-MM-DD')}</span>
					</div>
					<QuoteItemSelection
						showRow={showRow}
						setRow={setRow}
						row={row}
						editMode={editMode}
					/>

				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	const { LIST: storesList } = state?.form?.STORES?.data;
	const { LIST: filteredStoresList } = state?.form?.FILTERED_STORES?.data;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const assetList = state.assets.list;

	return {
		productsList,
		vendors,
		storesList,
		filteredStoresList,
		processTypes,
		assetList,
	};
};

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	toggleGenerateStandardPOModal: modalActions.toggleGenerateStandardPOModal,
	fetchProcessTypesList: formActions.gateway,
	fetchAssetList: assetActions.fetchAssetList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateQuote);
