/* eslint-disable no-tabs */
import storeServices from './store.services';
import allProductServices from './allproduct.services';
import brandServices from '../brand.services';
import comboServices from './combo.services';
import productCategoryServices from './productCategoryServcies';
import printerConfigServices from './printerConfigServices';
import riderServices from './rider.services';
import customizationServices from './customization.services';
import menuServices from './menu.services';
import skuServices from './sku.services';
import variantServices from './variants.services';
import subscriptionServices from './subscription.services';
import nutritionalServices from './nutritional.services';
import cookingProductConfigServices from './cookingProductConfig.services';
import InventorySkuServices from './inventorySku.services';
import brandingServices from './branding.services';
import approveRefundsServices from './approveRefunds.services';
import refundHistoryServices from './refundHistory.services';
import requestRefundServices from './requestRrefund.services';
import myCustomerServices from './myCustomer.services';
import promoCodeServices from './promocode.services';
import inventoryCategoryServices from './inventoryCategoryServices';
import cookingRecipesServices from './cookingRecipes.services';
import cookingStationsServices from './cookingStations.services';
import qualitySettingServices from './qualitySetting.services';
import usersServices from './users.services';
import inventorySettingServices from './inventorySetting.services';
import myFranchiseeServices from './myFranchisee.services';
import qualityConfigServices from './qualityConfig.services';
import inventoryConfigServices from './inventoryConfig.services';
import defectServices from './defect.services';
import aiModelCreation from './aiModelCreation.services';
import allVendorServices from './allvendor.services';
import childServices from './child.services';
import processFlowServices from '../Analytics Services/process-flow.services';
import variantsThresholdServices from '../variantsThreshold.services';
import productSubCategoryServcies from './productSubCategoryServcies';

const formServices = {
	gateway,
};

function gateway({ entity, req, method, type = 'VENDOR' }) {
	switch (entity) {
		case 'COMBOS':
			return comboServices.gateway({
				req,
				method,
			});
		case 'PRINTER_CONFIG':
			return printerConfigServices.gateway({
				req,
				method,
			});
		case 'RIDERS':
			return riderServices.gateway({
				req,
				method,
			});
		case 'BRANDS':
			return brandServices.gateway({
				req,
				method,
			});
		case 'PRODUCT_CATEGORY':
			return productCategoryServices.gateway({
				req,
				method,
			});
		case 'PRODUCT_SUB_CATEGORY':
			return productSubCategoryServcies.gateway({
				req,
				method,
			});
		case 'MASTER_MENUS':
			return menuServices.gateway({
				req,
				method,
			});

		case 'STORES':
		case 'FILTERED_STORES':
			return storeServices.gateway({
				req,
				method,
			});
		case 'PROCESS_TYPES':
			return processFlowServices.gateway({
				req,
				method,
			});
		case 'SKUS':
			return skuServices.gateway({
				req,
				method,
			});
		case 'CUSTOMIZATIONS':
			return customizationServices.gateway({
				req,
				method,
			});

		case 'ALL_PRODUCTS':
			return allProductServices.gateway({
				req,
				method,
			});
		case 'VARIANTS':
			return variantServices.gateway({
				req,
				method,
			});
		case 'VARIANTS_THRESHOLD':
			return variantsThresholdServices.gateway({
				req,
				method,
			});
		case 'SUBSCRIPTION':
			return subscriptionServices.gateway({
				req,
				method,
			});
		case 'NUTRITIONAL_INFO': {
			return nutritionalServices.gateway({
				req,
				method,
			});
		}
		case 'COOKING_PRODUCT_CONFIG': {
			return cookingProductConfigServices.gateway({
				req,
				method,
			});
		}
		case 'COOKING_STATIONS': {
			return cookingStationsServices.gateway({
				req,
				method,
			});
		}
		case 'INVENTORY_SKU': {
			return InventorySkuServices.gateway({
				req,
				method,
			});
		}
		case 'INVENTORY_CONFIG': {
			return inventoryConfigServices.gateway({
				req,
				method,
			});
		}
		case 'INVENTORY_CATEGORY': {
			return inventoryCategoryServices.gateway({
				req,
				method,
			});
		}
		case 'BRANDING': {
			return brandingServices.gateway({
				req,
				method,
			});
		}
		case 'APPROVE_REFUNDS': {
			return approveRefundsServices.gateway({
				req,
				method,
			});
		}
		case 'REFUND_HISTORY': {
			return refundHistoryServices.gateway({
				req,
				method,
			});
		}
		case 'REQUEST_REFUND': {
			return requestRefundServices.gateway({
				req,
				method,
			});
		}
		case 'MY_CUSTOMERS': {
			return myCustomerServices.gateway({
				req,
				method,
			});
		}
		case 'PROMO_CODES': {
			return promoCodeServices.gateway({
				req,
				method,
			});
		}
		// case 'MY_CUSTOMERS_ORDERS': {
		// 	return myCustomerServices.gateway({
		// 		req,
		// eslint-disable-next-line no-tabs
		// 		method,
		// 	});
		// }
		case 'RECIPES': {
			return cookingRecipesServices.gateway({
				req,
				method,
			});
		}
		case 'QUALITY_SETTING': {
			return qualitySettingServices.gateway({
				req,
				method,
			});
		}
		case 'PRODUCT_QUALITY_CONFIG': {
			return qualityConfigServices.gateway({
				req,
				method,
			});
		}
		case 'INVENTORY_SETTING': {
			return inventorySettingServices.gateway({
				req,
				method,
			});
		}
		case 'USERS': {
			return usersServices.gateway({
				req,
				method,
			});
		}
		case 'MY_FRANCHISEE': {
			return myFranchiseeServices.gateway({
				req,
				method,
			});
		}
		case 'DEFECTS': {
			return defectServices.gateway({
				req,
				method,
			});
		}
		case 'CHILD': {
			return childServices.gateway({
				req,
				method,
			});
		}
		case 'AI_MODEL': {
			return aiModelCreation.gateway({
				req,
				method,
			});
		}
		case 'ALL_VENDORS': {
			return allVendorServices.gateway({
				req,
				method,
				type,
			});
		}
		case 'ALL_BUYERS': {
			return allVendorServices.gateway({
				req,
				method,
				type: 'BUYER',
			});
		}
		default:
			break;
	}

	return new Promise(() => {});
}

export default formServices;
