import { Cancel } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { classes } from '../../../Helpers';
// import { BaseModal } from '../BaseModal';
import styles from './ProcessModal.module.scss';

const initialState = {
	id: null,
	color: '',
	heading: '',
	imageUrl: '',
	nodeId: null,
	hasResoeeApp: false,
	totalMachines: null,
	sectionId: null,
	sectionName: '',
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'init':
			return {
				...state,
				...action.payload,
			};
		case 'SET_HEADING':
			return {
				...state,
				heading: action.payload,
			};
		default:
			return state;
	}
};

const ProcessModal = (props) => {
	const { show, toggleProcessModal, data } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const {
			id, color, heading, imageUrl, nodeId, hasResoeeApp, totalMachines, sectionId, sectionName,
		} = data ?? {};
		dispatch({
			type: 'init',
			payload: {
				id,
				color,
				heading,
				imageUrl,
				nodeId,
				hasResoeeApp,
				totalMachines,
				sectionId,
				sectionName,
			},
		});
	}, [data]);

	// eslint-disable-next-line no-unused-vars
	const handleClose = () => {
		toggleProcessModal(false, {});
	};

	const changeHandler = (e) => {
		const { name, value } = e?.target ?? {};
		dispatch({
			type: `SET_${name}`,
			payload: value,
		});
	};

	return (
		<div
			style={{
				backgroundColor: state?.color,
			}}
			className={classes(styles.root, show ? styles.show : '')}
		>
			<div className={styles['inner-container']}>
				<div className={styles.close} onClick={handleClose}>
					<Cancel className={`${styles['close-icon']}`} />
				</div>
				<div className={styles['edit-body']}>
					<TextField
						fullWidth
						size='small'
						color='info'
						name='HEADING'
						label='Change Process Name'
						value={state?.heading}
						onChange={changeHandler}
					/>
				</div>
				{/* <img src={state?.imageUrl} alt='ImageUrl' /> */}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.processModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleProcessModal: modalActions.toggleProcessModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcessModal);
