/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
// eslint-disable-next-line object-curly-newline
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
	Badge, Button, IconButton
} from '@mui/material';
import { ArrowCircleRight, Tune } from '@mui/icons-material';
import analyticsAction from '../../../Actions/analytics.actions';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import { classes, useQuery, useSearch } from '../../../Helpers';
import styles from './CookingHistory.module.scss';
import CookingAnalysisListPage from './CookingAnalysisListPage';
import Calender from '../../../Components/Forms/Payment Settlements/Calender/Calender';
import { formActions, modalActions } from '../../../Actions';
import { ListModal } from '../../../Components/Modals';
import FilterModal from '../../../Components/Filter Modal/FilterModal';
import excel from '../../../Images/excel.svg';

const ANALYTICS_TYPE = analyticsTypeConstant.COOKING_HISTORY_LIST_DATA;
const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	ORDER_ID: 'Search by Order Id',
	ITEM_ID: 'Search by Item Id',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	PRODUCT_NAME: 'productName',
	ORDER_ID: 'orderId',
	ITEM_ID: 'itemId',
	// VALIDATED: 'validation',
	// IDENTIFIED: 'identification',
	// FEATURES: 'annotated',
	// BRAND_NAME: 'brandName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'PRODUCT_NAME',
			title: 'Product Name',
			get: 'recipeName',
		},
		{
			key: 'ORDER_ID',
			title: 'Order ID',
			get: 'orderId',
		},
		{
			key: 'ITEM_ID',
			title: 'Item ID',
			get: 'itemId',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const FILTERS = [
	'PROCESS_TYPE_FILTER',
	'SKU_TYPE_FILTER',
];

const CookingHistoryListData = (props) => {
	const {
		dateRangeFilter,
		data,
		setDateRangeFilter,
		fetchData,
		setQCSearchResults,
		searchQuery,
		searchBy,
		brandData,
		userPermissions,
		gateway,
		toggleFilterModal,
		productsList,
		exportAiCookingAnalytics,
		setAnalyticsType,
	} = props;

	// const { page, limit } = usePagination({
	// 	customLimit: 10,
	// });
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [data]);
	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		setShouldAnimate(true);
	};

	const getData = () => {
		const { from, to } = dateRangeFilter;
		if (from != null && to != null) {
			setAnalyticsType(ANALYTICS_TYPE);
			setIsDataFetched(true);
		}
	};

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
		if (brandData.length === 0) {
			gateway({
				entity: 'BRANDS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setQCSearchResults('', data, 'ORDER_ID');
		}
	}, [queryParams]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const dataGenerator = () => {
		const filteredData =
			data &&
			data
				?.map((ele) => {
					return {
						...ele,
						productName:
							productsList?.[ele?.productId]?.productName ?? '-',
					};
				})
				?.filter((obj) => {
					return (
						obj?.[
							FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]
						]
							?.toString()
							?.toLowerCase()
							?.indexOf(query.trim().toLowerCase()) !== -1
					);
				});
		setQCSearchResults(query, filteredData, SEARCH_BY?.options?.[searchField]?.key);
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	return (
		<div className={styles.root}>
			<ListModal selectionBehaviour='SHOW' />
			<FilterModal
				type='quality'
				filters={FILTERS}
				clear={queryParams.has('clear')}
			/>
			<div className={styles.header}>
				<div className={styles.filters}>
					<SearchBar
						value={query}
						placeholder={PLACE_HOLDERS[searchField]}
						handleInput={handleSearch}
						handleChange={handleSearchBy}
						searchBy={
							SEARCH_BY != null
								? {
									...(SEARCH_BY ?? {}),
									current: searchField,
								}
								: null
						}
						className={styles.search}
					/>
					<Badge
						className={styles['brand-filter']}
						color='primary'
					>
						<Button
							variant='outlined'
							onClick={() => {
								toggleFilterModal(true, {
									heading: 'Filter',
								});
							}}
						>
							Filters
							<Tune fontSize='medium' />
						</Button>
					</Badge>
				</div>
				<div className={styles['date-selection']}>
					<p>Order Date: </p>
					<Calender value={dateRangeFilterArray} setValue={setDate} />
				</div>
				<div className='flex items-center gap-3 mr-2'>
					<div
						onClick={exportAiCookingAnalytics}
						className={styles.downloadData}
					>
						<img src={excel} alt='excel' />
					</div>
					<IconButton
						className={classes(
							styles.btn,
							shouldAnimate ? styles?.animate : '',
							isDataFetched ? styles?.disabled : ''
						)}
						onClick={getData}
					>
						<ArrowCircleRight />
					</IconButton>
				</div>
			</div>
			<CookingAnalysisListPage
				userPermissions={userPermissions}
				data={dataGenerator()}
			/>
		</div>
	);
};

CookingHistoryListData.defaultProps = {};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter, BRAND_FILTER: brandFilter } = state.analytics;
	const { LIST_DATA: data } = state.analytics?.COOKING_ANALYTICS;
	// const { count } = data??{count:};
	const { userPermissions } = state.navigation;
	const brandData = state.form.BRANDS.data?.LIST;
	const productsList = state.product.products;
	const searchQuery = state.analytics.COOKING_ANALYTICS.SEARCH_DATA?.query;
	const searchBy = state.analytics.COOKING_ANALYTICS.SEARCH_DATA?.searchBy;
	return {
		dateRangeFilter,
		searchQuery,
		data: data?.recipes,
		// count,
		productsList,
		searchBy,
		brandData,
		userPermissions,
		brandFilter,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setQCSearchResults: analyticsAction.setQCSearchResults,
	fetchData: analyticsAction.fetchData,
	toggleModal: modalActions.toggleListModal,
	toggleFilterModal: modalActions.toggleFilterModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
	setAnalyticsType: analyticsAction.setAnalyticsType,
	exportAiCookingAnalytics: analyticsAction.exportAiCookingAnalytics,
};
export default connect(mapStateToProps, mapDispatchToProps)(CookingHistoryListData);
