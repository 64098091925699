import { endpoints } from '../Constants';
import { authHeader } from '../Helpers';
import handleResponse from './helpers';

export default function downloadDashboardDataService({
	req = {
		data: {},
	},
}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	const data = fetch(endpoints.DASHBOARD_DATA_DOWNLOAD, requestOptions).then(handleResponse);
	return data;
}
