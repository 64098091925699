/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import ProductDetails from '../../../Components/Forms/Products/ProductDetails';
import VariantQualityConfig from '../../../Components/Forms/Quality/Quality Config/Variant Quality Config/VariantQualityConfig';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import qualityConfig from './quality-config.config';

const TABS = ['PRODUCT DETAILS', 'QUALITY PARAMETERS'];

const QualityConfigViewPage = (props) => {
	const { fetchProduct, productsMap, deleteProduct, breadcrumbs } = props;

	const [state, setState] = useState({
		activeTab: 1,
		fields: cloneDeep(qualityConfig.initialState.fields),
	});
	const { id } = useRouteMatch().params;
	const data = useMemo(() => {
		return productsMap[id];
	}, [id, productsMap]);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(qualityConfig.serializer(state, data));
		} else {
			fetchProduct({
				entity: 'PRODUCT_QUALITY_CONFIG',
				req: {
					params: {
						id,
						combo: true,
					},
				},
				method: 'LIST',
			});
		}
	}, [data]);

	const { activeTab, fields } = state;

	const [activeVariant, setActiveVariant] = useState(0);

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<ProductDetails
						isDisabled
						mandatoryFields={qualityConfig.mandatoryFields}
						fields={fields}
						exclude={{
							price: true,
							category: true,
							brand: true,
							veg: true,
						}}
					/>
				);
			case 1:
				return (
					<VariantQualityConfig
						mandatoryFields={qualityConfig.mandatoryFields}
						fields={fields}
						activeVariant={activeVariant}
						setActiveVariant={setActiveVariant}
						isDisabled
					/>
				);
			default:
				return <></>;
		}
	};
	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteProduct({
			entity: 'PRODUCT_QUALITY_CONFIG',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};
	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Product'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const productsMap = state.form.PRODUCT_QUALITY_CONFIG.data.LIST;
	return {
		productsMap,
	};
};

const mapDispatchToProps = {
	fetchProduct: formActions.gateway,
	deleteProduct: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityConfigViewPage);
