import { useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import { CreatePageHeader } from '../../../Components/Headers';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import { inputHelper } from '../../../Helpers';
import { urls } from '../../../Constants';
import { notificationServices } from '../../../Services';
import config from './createService.config';
import assetServices from '../../../Services/AssetServices/asset.services';

const CreateCapexService = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { isEdit, breadcrumbs } = props;
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const { id } = useParams();
	const history = useHistory();

	const fetchService = async () => {
		try {
			const response = await assetServices.listCapexService({
				id,
			});
			if (response?.statusCode === 200) {
				if (response?.data && response?.data?.length !== 0) {
					await dispatch({
						type: 'SET_EDIT_DATA',
						payload: response?.data[0],
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		if (isEdit) {
			fetchService(id);
		}
	}, []);

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		if (fieldName === 'name') {
			dispatch({
				type: 'SET_NAME',
				payload: fieldValue,
			});
		}
	};

	const handleSave = async (e) => {
		e.preventDefault();
		const isValid = config.validator(state);
		if (isValid) {
			const desState = await config.deserializer(state);
			let response;
			try {
				if (isEdit) {
					const req = {
						id,
						...desState,
					};
					response = await assetServices.updateCapexService(req);
				} else {
					response = await assetServices.addCapexService(desState);
				}
				if (response?.statusCode === 200) {
					dispatch({
						type: 'INIT',
					});
					history.goBack();
					notificationServices.generateNotification({
						type: 'success',
						message: 'Saved successfully',
					});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		}
		return true;
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
		}
	};

	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Product' : 'Create Product'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state?.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<div className='form-field row'>
						<label
							className='form-label'
							htmlFor='productName'
							data-mandatory
						>
							Service Name
						</label>
						<input
							className='form-value'
							type='text'
							id='serviceName'
							name='name'
							onInput={handleInput}
							value={state.name}
						/>
					</div>
				</FormSectionHeader>
			</form>
		</div>
	);
};

export default CreateCapexService;
