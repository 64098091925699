/* eslint-disable react/jsx-one-expression-per-line */
import { connect } from 'react-redux';
import {
	Button, MenuItem, TextField
} from '@mui/material';
import {
	useCallback, useMemo, useReducer, useState
} from 'react';
import styles from './PaymentModal.module.scss';
import { BaseModal } from '../BaseModal';
import { formActions, modalActions } from '../../../Actions';
import config from './paymentModal.config';
import { notificationServices } from '../../../Services/Notification';
import DatePickerCustom from '../../DatePickerCustom/DatePickerCustom';
import { ListTable } from '../../ListTable';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		min,
		name,
		placeholder,
		labelText,
		mandatory,
		type,
		max,
	} = props;
	return (
		<div className={styles.tile}>
			<label className={mandatory ? styles.mandatory : ''} htmlFor={id}>
				{labelText}
			</label>
			<TextField
				id={id}
				name={name}
				value={value}
				min={min}
				max={max}
				type={type}
				onChange={onChange}
				placeholder={placeholder}
				sx={{
					'.MuiInputBase-root': {
						borderRadius: '12px',
					},
					'.MuiInputBase-input': {
						paddingTop: '6px',
						paddingBottom: '6px',
					},
				}}
			/>
		</div>
	);
};

const optionsList = ['Bank Transfer', 'Cash', 'Credits'];

const CREDIT_COLUMNS = [
	{
		title: 'Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Credit Note Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Balance Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount',
		config: {
			type: 'DATA',
		},
	},
];

const BILL_COLUMNS = [
	{
		title: 'Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Billing Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Balance Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount',
		config: {
			type: 'DATA',
		},
	},
];

const PaymentModal = (props) => {
	const { show, toggleModal, data, toggleFileUploaderModal } = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const { paymentId, amount, date, paymentMode, paymentRefDoc } = state ?? {};
	const [creditsValue, setCreditsValue] = useState([]);
	const [billsValue, setBillsValue] = useState([]);
	const [otherPaymentMethod, setOtherPaymentMethod] = useState('');
	const [storeCreditsList, setStoreCreditsList] = useState([]);
	const [storeBillsList, setStoreBillsList] = useState([]);

	const handleClose = () => {
		toggleModal(false, {});
		setOtherPaymentMethod('');
		setStoreCreditsList([]);
		setStoreBillsList([]);
		setCreditsValue([]);
		setBillsValue([]);
		dispatch({
			type: 'INIT',
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		dispatch({
			type: `SET_${name}`,
			payload: value,
		});
		if (name === 'AMOUNT' && (creditsValue?.length || billsValue?.length)) {
			setCreditsValue([]);
			setBillsValue([]);
			setOtherPaymentMethod('');
		}
	};

	const handleConfirm = () => {
		const deserializedData = config?.deserializer(
			state,
			creditsValue,
			billsValue
		);
		if (!deserializedData?.paymentAmount) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Payment Amount is mandatory',
			});
			return;
		}
		if (deserializedData?.paymentAmount > data?.totalAmount) {
			notificationServices.generateNotification({
				type: 'error',
				message: "Payment Amount can't be more than Total Amount",
			});
			return;
		}
		if (!deserializedData?.transactionDate) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Payment Date is mandatory',
			});
			return;
		}
		if (!deserializedData?.clientTransactionId) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Payment ID is mandatory',
			});
			return;
		}
		const req = {
			data: {
				...deserializedData,
				parentId: data?.parentId,
			},
		};
		props.handleConfirm(req?.data);
		dispatch({
			type: 'INIT',
		});
		handleClose();
	};

	const renderBtns = useCallback(() => {
		return (
			<div className={styles['btns-container']}>
				<Button fullWidth variant='outlined' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					fullWidth
					variant='contained'
					color='primary'
					onClick={handleConfirm}
				>
					Confirm
				</Button>
			</div>
		);
	}, [state, creditsValue]);

	const totalCreditsApplied = useMemo(() => {
		return creditsValue?.reduce((acc, curr) => {
			// eslint-disable-next-line no-restricted-globals
			if (!isNaN(Number(curr?.amount))) {
				return acc + Number(curr?.amount);
			}
			return acc;
		}, 0);
	}, [creditsValue]);
	const totalBillsApplied = useMemo(() => {
		return billsValue?.reduce((acc, curr) => {
			// eslint-disable-next-line no-restricted-globals
			if (!isNaN(Number(curr?.amount))) {
				return acc + Number(curr?.amount);
			}
			return acc;
		}, 0);
	}, [billsValue]);

	const handleAmountChange = (id, amountVal) => {
		if (otherPaymentMethod === 'BILLS') {
			if (Number(amountVal) > Number(amount) - totalCreditsApplied) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'More Amount',
				});
				return;
			}
			const existing = billsValue?.find((ele) => {
				return ele?.id === id;
			});
			if (existing?.id) {
				setBillsValue((prev) => {
					return prev?.map((ele) => {
						if (ele?.id === existing?.id) {
							return {
								...ele,
								amount: amountVal,
							};
						}
						return ele;
					});
				});
			} else {
				const billItem = {
					id,
					amount: amountVal,
				};
				setBillsValue((prev) => {
					return [...prev, billItem];
				});
			}
		} else {
			if (Number(amountVal) > Number(amount) - totalBillsApplied) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'More Amount',
				});
				return;
			}
			const existing = creditsValue?.find((ele) => {
				return ele?.id === id;
			});
			if (existing?.id) {
				setCreditsValue((prev) => {
					return prev?.map((ele) => {
						if (ele?.id === existing?.id) {
							return {
								...ele,
								amount: amountVal,
							};
						}
						return ele;
					});
				});
			} else {
				const creditItem = {
					id,
					amount: amountVal,
				};
				setCreditsValue((prev) => {
					return [...prev, creditItem];
				});
			}
		}
	};

	const SPECIAL_ITEMS = {
		'Bill Balance Amount': (id) => {
			const billItem = storeBillsList?.find((ele) => {
				return ele?.id === id;
			});
			return billItem?.currentAmount - billItem?.usedAmount;
		},
		Amount: (id) => {
			const creditItem = storeCreditsList?.find((ele) => {
				return ele?.id === id;
			});
			const billItem = storeBillsList?.find((ele) => {
				return ele?.id === id;
			});
			const currCredit = [...creditsValue, ...billsValue]?.find((ele) => {
				return ele?.id === id;
			});
			if (billItem) {
				// eslint-disable-next-line operator-assignment
				billItem.pendingAmount =
					billItem?.currentAmount - billItem?.usedAmount;
			}
			return (
				<input
					type='number'
					min={0}
					step='any'
					// max={creditItem?.currentAmount}
					value={currCredit?.amount}
					onChange={(e) => {
						if (otherPaymentMethod === 'CREDITS') {
							if (
								creditItem?.currentAmount <
								Number(e?.target?.value)
							) {
								notificationServices.generateNotification({
									type: 'error',
									message:
										'Cannot Debit more than Balance Amount',
								});
								return;
							}
						} else if (
							billItem.pendingAmount < Number(e?.target?.value)
						) {
							notificationServices.generateNotification({
								type: 'error',
								message:
									'Cannot Debit more than Balance Amount',
							});
							return;
						}
						handleAmountChange(id, e?.target?.value);
					}}
				/>
			);
		},
	};

	const fetchStoreCredits = async () => {
		try {
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								storeId: data?.storeId ?? null,
								vendorId: data?.vendorId ?? null,
								currentAmountGt: 0,
								status: ['UNPAID', 'PARTIALLY_PAID'],
							},
							type: 'CREDIT',
						},
					},
				});
			if (response?.statusCode === 200) {
				setStoreCreditsList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const fetchStoreAdvanceBills = async () => {
		try {
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								storeId: data?.storeId ?? null,
								vendorId: data?.vendorId ?? null,
								currentAmountGt: 0,
								status: ['PARTIALLY_PAID', 'PAID'],
								parentId: null,
								orderId: data?.orderId,
							},
							type: 'BILL',
						},
					},
				});
			if (response?.statusCode === 200) {
				setStoreBillsList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleUseCredits = () => {
		setOtherPaymentMethod((prev) => {
			if (prev === 'CREDITS') {
				return '';
			}
			fetchStoreCredits();
			return 'CREDITS';
		});
	};

	const handleUseBills = () => {
		setOtherPaymentMethod((prev) => {
			if (prev === 'BILLS') {
				return '';
			}
			fetchStoreAdvanceBills();
			return 'BILLS';
		});
	};

	const uploadPaymentRefClick = () => {
		toggleFileUploaderModal(true, {
			heading: 'Upload Vendor Invoice',
			selectedFile: '',
			confirmText: 'Upload',
			handleConfirm: (uploadedFile) => {
				dispatch({
					type: 'SET_PAYMENT_REF_DOC',
					payload: uploadedFile,
				});
			},
		});
	};

	const handleFull = () => {
		dispatch({
			type: 'SET_AMOUNT',
			payload: Number(data?.totalAmount ?? 0),
		});
	};

	return (
		<BaseModal
			open={show}
			heading={data?.heading ?? 'Make payment of Bill #BIL-100001'}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='60%'
			height={data?.type === 'paymentMade' ? '40rem' : '30rem'}
		>
			<div className={styles.root}>
				<div className={styles.tile}>
					<label>Pending amount</label>
					<div>{Number(data?.totalAmount ?? 0).toFixed(3)}</div>
				</div>
				<div className={styles.multi}>
					<div className={styles['payment-input-container']}>
						<LabelledInput
							type='number'
							mandatory
							min={0}
							max={Number(data?.totalAmount ?? 0)}
							id='paymentAmount'
							name='AMOUNT'
							value={amount}
							onChange={handleChange}
							placeholder='Enter amount'
							labelText='Payment Amount*'
						/>
						{amount !== Number(data?.totalAmount ?? 0) && (
							<div
								onClick={handleFull}
								className={styles['pay-full']}
							>
								Pay Full
							</div>
						)}
						{(totalCreditsApplied > 0 || totalBillsApplied > 0) && (
							<div className={styles['payment-details']}>
								<p>
									Total Payment:{' '}
									{Number(amount ?? 0)?.toFixed(2)}
								</p>
								<p>
									Payment Made:{' '}
									{Number(
										amount -
											totalCreditsApplied -
											totalBillsApplied ?? 0
									)?.toFixed(2)}
								</p>
								<p>Bills Applied: {totalBillsApplied}</p>
								<p>Credits Applied: {totalCreditsApplied}</p>
							</div>
						)}
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Payment Reference
						</label>
						<div className={styles['invoice-uplaod']}>
							{paymentRefDoc?.file && paymentRefDoc?.fileName && (
								<p>{paymentRefDoc?.fileName}</p>
							)}
							<Button
								type='button'
								variant='contained'
								color='primary'
								onClick={uploadPaymentRefClick}
							>
								Upload
							</Button>
						</div>
					</div>
				</div>
				<LabelledInput
					mandatory
					id='paymentId'
					name='PAYMENT_ID'
					value={paymentId}
					onChange={handleChange}
					placeholder='Enter Payment ID'
					labelText='#ID *'
				/>
				<div className={styles.tile}>
					<label className={styles.mandatory}>Date*</label>
					<DatePickerCustom
						selectedDate={date}
						selectDate={(rsuiteDate) => {
							dispatch({
								type: 'SET_DATE',
								payload: rsuiteDate,
							});
						}}
						style={{
							width: 200,
						}}
						oneTap
						defaultValue={date}
						// shouldDisableDate={disabledDate}
					/>
				</div>
				<div className={styles.multi}>
					<div className={styles.tile}>
						<label>Payment Mode</label>
						<TextField
							select
							id='paymentMode'
							name='PAYMENT_MODE'
							value={paymentMode}
							onChange={handleChange}
							sx={{
								width: 200,
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
							}}
						>
							{optionsList?.map((option) => {
								return (
									<MenuItem
										key={option?.id ?? option}
										value={option?.id ?? option}
									>
										{option?.title ?? option}
									</MenuItem>
								);
							})}
						</TextField>
					</div>
					{!data?.isAdvance && (
						<div className={styles['bill-credit-btn']}>
							<Button
								type='button'
								variant={
									otherPaymentMethod === 'BILLS'
										? 'outlined'
										: 'contained'
								}
								color='primary'
								onClick={handleUseBills}
								className={styles.btn}
							>
								{otherPaymentMethod === 'BILLS'
									? 'Hide Advance Bills'
									: 'Use Advance bills'}
							</Button>
							<Button
								type='button'
								variant={
									otherPaymentMethod === 'CREDITS'
										? 'outlined'
										: 'contained'
								}
								color='primary'
								onClick={handleUseCredits}
								className={styles.btn}
							>
								{otherPaymentMethod === 'CREDITS'
									? `Hide ${
										data?.type !== 'paymentMade'
											? 'Store'
											: ''
									} Credits`
									: `Use ${
										data?.type !== 'paymentMade'
											? 'Store'
											: ''
									} Credits`}
							</Button>
						</div>
					)}
				</div>
				{!data?.isAdvance && otherPaymentMethod && (
					<div className={styles?.credits}>
						<p className={styles.heading}>
							{otherPaymentMethod} LIST
						</p>
						<ListTable
							colMap={{
								Id: 'id',
								'Bill Id': 'clientTransactionId',
								'Credit Note Id': 'clientTransactionId',
								Vendor: 'vendorName',
								'Billing Store': 'storeName',
								'Total Amount': 'finalTransactionAmount',
								'Balance Amount': 'currentAmount',
								Amount: 'id',
								'Bill Balance Amount': 'id',
							}}
							columns={
								otherPaymentMethod === 'CREDITS'
									? CREDIT_COLUMNS
									: BILL_COLUMNS
							}
							// allowCheckList
							allowExport={false}
							data={(otherPaymentMethod === 'BILLS'
								? storeBillsList
								: storeCreditsList
							)?.map((ele) => {
								const isSelected = [
									...creditsValue,
									...billsValue,
								]?.find((credVal) => {
									return (
										credVal?.id === ele?.id &&
										Number(credVal?.amount) > 0
									);
								});
								return {
									...ele,
									rowStyles: isSelected
										? styles.selected
										: '',
								};
							})}
							special={SPECIAL_ITEMS}
						/>
					</div>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.paymentModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.togglePaymentModal,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	saveVendor: formActions.gateway,
	fetchVendors: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
