/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const myCustomerServices = {
	gateway,
	listCustomers,
	viewCustomer,
};
function gateway({ req, method }) {
	switch (method) {
		case 'VIEW':
			return viewCustomer({
				req,
			});
		case 'LIST':
			return listCustomers({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function viewCustomer({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.MY_CUSTOMERS_CUSTOMER_DATA, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listCustomers({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.MY_CUSTOMERS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default myCustomerServices;
