import { Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
	// generateRandomKey,
	generateRequiredProps,
	isEmpty,
} from '../../../../../Helpers';
import { CustomizationDetails } from '../../../Customizations/CustomizationDetails';
import EnableDisable from '../../../EnableDisable/EnableDisable';
import { IconInput } from '../../../Inputs';
import styles from './RecipeProcess.module.scss';
import { RecipeCustomizationSKUs } from '../../RecipeCustomizationSKUs';
import { RecipeCustomizationGroup } from '../../RecipeCustomizationGroup';

const RecipeProcess = (props) => {
	const {
		isDisabled,
		handleInput,
		handleDelete,
		activeProcess,
		processDetails,
		mandatoryFields,
		allVariants,
		defaultVariant,
	} = props;

	if (isEmpty(processDetails)) {
		return <></>;
	}

	return (
		<div
			className={`${styles.root} bg-white p-3 rounded-xl`}
			key={activeProcess}
		>
			<fieldset
				disabled={isDisabled}
				className='form-fieldset row w-full'
			>
				<div
					style={{
						flexBasis: '100%',
					}}
				>
					<div className='icon_container absolute top-[-1rem] right-2 px-2 rounded-full bg-gray-300 flex gap-3'>
						{/* delete process button */}
						{!isDisabled && (
							<Tooltip
								title='Delete Process'
								placement='left-end'
							>
								<Delete
									className={`${styles['delete-icon']}`}
									onClick={handleDelete}
									data-type='process'
									data-id={activeProcess}
								/>
							</Tooltip>
						)}
						{/* move process button */}
						{/* {!isDisabled && (
							<Tooltip
								title='Move Process to Another Stage'
								placement='left-end'
							>
								<ImportExport
									className={`${styles['move-icon']}`}
									onClick={openExportProcess}
									data-type='process'
									data-id={activeProcess}
								/>
							</Tooltip>
						)} */}
					</div>
					<div className='w-full flex gap-2 items-start justify-between'>
						{/* process name */}
						<div className='form-field flex flex-col gap-1 flex-1'>
							<label
								className='text-xs'
								htmlFor='processName'
								data-mandatory={
									mandatoryFields.stages.processes.name
								}
							>
								Step Name
							</label>
							<input
								className={`p-2 rounded-xl w-full ${
									isDisabled
										? 'border-b-2 border-slate-400'
										: 'bg-white border border-black'
								}`}
								type='text'
								id='processName'
								name='name'
								onInput={handleInput}
								value={processDetails.name}
								data-type='process'
								data-id={activeProcess}
								{...generateRequiredProps(
									mandatoryFields.stages.processes.name
								)}
							/>
						</div>
						{/* time required */}
						<div className='form-field flex flex-col gap-1 flex-1'>
							<label
								className='text-xs'
								htmlFor='timeRequired'
								data-mandatory={
									mandatoryFields.stages.processes.time
								}
							>
								Time Required
							</label>
							<IconInput
								size='large'
								type='custom'
								iconText='min'
								name='time'
								id='timeRequired'
								value={processDetails.time}
								handleInput={handleInput}
								dataType='process'
								dataId={activeProcess}
								className='!w-full border border-black'
								dataAttrs={{
									required:
										mandatoryFields.stages.processes.time,
								}}
							/>
						</div>
						{/* instructions */}
						<div className='form-field flex flex-col gap-1 flex-1'>
							<label
								className='text-xs'
								htmlFor='processInstructions'
								data-mandatory={
									mandatoryFields.stages.processes
										.instructions
								}
							>
								Instructions to the chef
							</label>
							<textarea
								className={`p-2 rounded-xl w-full ${
									isDisabled
										? 'border-b-2 border-slate-400 resize-none'
										: 'bg-white border border-black'
								}`}
								id='processInstructions'
								name='instructions'
								onInput={handleInput}
								value={processDetails.instructions}
								data-type='process'
								data-id={activeProcess}
								{...generateRequiredProps(
									mandatoryFields.stages.processes
										.instructions
								)}
							/>
						</div>
					</div>
				</div>
				{/* <div
					style={{
						flexBasis: '50%',
						marginLeft: '1.25rem',
					}}
				>
				</div> */}
			</fieldset>
			<p className='text-base'>Ingredients</p>
			<div className='border border-slate-500 w-full' />
			{/* is this process customizable */}
			<EnableDisable
				name='isCustomizable'
				handleIsEnabled={handleInput}
				dataAttrs={{
					'data-type': 'process',
					'data-id': activeProcess,
				}}
				isEnabled={processDetails.isCustomizable}
				text='Add customization to this process?'
				checkBoxShow={!isDisabled}
			>
				<CustomizationDetails
					isDraggable={false}
					customizations={processDetails.customizations}
					handleInput={handleInput}
					handleDelete={handleDelete}
					mandatoryFields={mandatoryFields.stages.processes}
					extraProps={{
						skuMeta: {
							qty: true,
							price: processDetails.customizations.items[1]
								.applicableOn.deltaPricing,
							variants: true,
						},
					}}
					isDisabled={isDisabled}
					showDelete={false}
					showAddNew={false}
					component={RecipeCustomizationGroup}
				/>
			</EnableDisable>
			{/* if not customizable, show skus as it is */}
			{!processDetails.isCustomizable && (
				<RecipeCustomizationSKUs
					id={1}
					selectedSkus={processDetails.customizations.items[1].skus}
					applicableOn={
						processDetails.customizations.items[1].applicableOn
					}
					customizationType='ADDON'
					deltaPricing={false}
					mandatoryFields={mandatoryFields.stages.processes}
					handleDelete={handleDelete}
					handleInput={handleInput}
					allVariants={allVariants}
					isDisabled={isDisabled}
					showVariants={
						processDetails.customizations.items[1].skus.length > 0
					}
					checkApplicability={false}
					defaultVariant={defaultVariant}
				/>
			)}
		</div>
	);
};

export default RecipeProcess;
