import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

const deepCompareEqualsForMaps = (a, b) => {
	return isEqual(a, b);
};

export function useDeepCompareMemoize(value) {
	const ref = useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
}

export function useDeepCompareEffectForMaps(callback, dependencies) {
	useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
