import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const productProcessMapServices = {
	mapProductToProcess,
	listProductProcess,
	deleteProcessProductMap,
	fetchAvailableMappingTypes,
	getMappingTypeValues,
	fetchProductWiseMappingData,
};

function fetchAvailableMappingTypes() {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// // clientId: 5,
		},
	};
	return fetch(endpoints.AVAILABLE_MAPPING_TYPES, requestOptions).then(
		handleResponse
	);
	// return mockFetch(endpoints.PRODUCT_PROCESS_MAP, requestOptions);
}

function mapProductToProcess(req = {}) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_PROCESS_MAP, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// // clientId: 5,
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

function getMappingTypeValues(req = {}) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.MAPPING_TYPE_VALUES, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// // clientId: 5,
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchProductWiseMappingData(req = {}) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCTWISE_MAPPING, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// // clientId: 5,
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function listProductProcess(req) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.PRODUCT_PROCESS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// // clientId: 5,
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}
function deleteProcessProductMap(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// // clientId: 5,
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	// return mockOHList(requestOptions);
	return fetch(endpoints.PRODUCT_PROCESS_MAP_DELETE, requestOptions).then(
		handleResponse
	);
}

export default productProcessMapServices;
