// eslint-disable-next-line object-curly-newline
import { Route, useRouteMatch, Switch } from 'react-router';
import { urls } from '../../Constants';
import SavedLabels from '../../Components/LabelGenerator/LabelGeneratorSavedLabels/SavedLabels';
import LabelGenerator from '../../Pages/LabelGenerator/LabelGenerator';

const getSubURL = (entity) => {
	const baseEntity = urls.LABEL_GENERATOR;
	return entity?.slice(baseEntity.length);
};
const LabelGeneratorRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.LABEL_GENERATOR_LIST)}`}>
				<SavedLabels />
			</Route>
			<Route path={`${path}${getSubURL(urls.LABEL_GENERATOR_CREATE)}`}>
				<LabelGenerator />
			</Route>
		</Switch>
	);
};
export default LabelGeneratorRoutes;
