/* eslint-disable no-tabs */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import analyticsAction from '../../../../Actions/analytics.actions';
import { OutwardingImageResults } from '../../../../Components/Analytics/Outwarding';
import { DefectImageModal } from '../../../../Components/Modals';
import { liveQCServices } from '../../../../Services/LiveQC';
import styles from './WastageImagesPage.module.css';
import wastageAnalyticsServices from '../../../../Services/Analytics Services/wastage-analytics.services';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const FIND_FILTERS = [
	'store',
	'processTypes',
	'pageLimit',
	'identificationType',
	'brand',
	'product',
	'dateRange',
];

const WastageImagesPage = (props) => {
	const [filters, setFilters] = useState({
		store: null,
		processTypes: null,
		products: [],
		brand: null,
		identification: 'Both',
		dateRange: {
			active: false,
			from: new Date(),
			to: new Date(),
		},
	});

	const {
		allWastageResults,
		fetchWastageImages,
		resetWastageImages,
		updateWastageImagesById,
	} = props;

	useEffect(() => {
		resetWastageImages();
	}, []);

	const submitFindRequest = ({
		store = null,
		processTypes = null,
		products = [],
		dateRange = {},
		page = 6,
		brand = null,
		identification = 'Both',
	}) => {
		resetWastageImages();
		setFilters({
			store,
			processTypes,
			products,
			dateRange,
			page,
			identification,
			brand,
		});
		const data = {
			store,
			products,
			processTypes,
			brandId: brand,
			identification,
			dateRangeFilter: dateRange?.active
				? {
					from: moment(dateRange?.from).format('YYYY-MM-DD'),
					to: moment(dateRange?.to).format('YYYY-MM-DD'),
				}
				: {},
		};
		fetchWastageImages({
			entity: 'WASTAGE_ANALYSIS',
			method: 'FIND_WASTAGE_IMAGES',
			req: {
				data,
				params: {
					limit: page,
				},
			},
		});
	};

	const fetchWastageImagesById = async ({ pageNo, productId }, index) => {
		const response = await wastageAnalyticsServices.fetchWastageImagesById({
			params: {
				page: pageNo,
				limit: filters?.page,
			},
			data: {
				store: filters?.store,
				brandId: filters?.brand,
				processTypes: filters?.processTypes,
				identification: filters?.identification,
				productId,
				dateRangeFilter: filters?.dateRange?.active
					? {
						from: moment(filters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filters?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
					}
					: {},
			},
		});

		const { data = [] } = response;
		updateWastageImagesById(index, data);
		return data;
	};

	const fetchQCData = async (qcId) => {
		try {
			const req = {
				params: {
					qcId,
				},
			};

			const { data = null } = await liveQCServices.getPastData(req);

			return data;
		} catch {
			return null;
		}
	};

	return (
		<div className={styles.root}>
			<DefectImageModal />
			<ImagesFinder
				pageLabel='Wastage Images'
				FILTERS={FIND_FILTERS}
				onSubmit={submitFindRequest}
			/>
			<div className={styles['image-results']}>
				{allWastageResults?.map((product, index) => {
					return (
						product.Annotation.length > 0 && (
							<OutwardingImageResults
								key={product?.id}
								showLoadMore
								fetchImagesById={(req) => {
									fetchWastageImagesById(req, index);
								}}
								product={product}
								clickable
								extraLinkData='WASTAGE_IMAGES'
								fetchQCData={fetchQCData}
							/>
						)
					);
				})}
				{allWastageResults?.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_WASTAGE_IMAGES: allWastageResults } =
		state.analytics.WASTAGE_ANALYSIS;

	return {
		allWastageResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchWastageImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		updateWastageImagesById: (...args) => {
			dispatch(analyticsAction.updateWastageImagesList(...args));
		},
		resetWastageImages: () => {
			dispatch(analyticsAction.resetWastageImages());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WastageImagesPage);
