import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { connect } from 'react-redux';
import { modalActions } from '../../../../Actions';
import { inputHelper } from '../../../../Helpers';
import { PincodeModal } from '../../../Modals/PincodeModal';
import styles from './pincodes.module.scss';
import Tag from '../../../Tag/Tag';

const Pincodes = (props) => {
	const { fields, isDisabled, togglePincodeModal, handleConfirm } = props;
	const handleClick = (e) => {
		const { dataset } = inputHelper(e);
		togglePincodeModal(true, {
			...dataset,
		});
	};
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		props.handleDelete(dataset.id);
	};
	const generateTags = (allTags, type) => {
		return allTags.map((tag, idx) => {
			return (
				<Tag
					className={`${styles.tag}`}
					handleDelete={handleDelete}
					text={tag}
					isDisabled={isDisabled}
					dataAttrs={{
						'data-type': type,
						'data-id': idx,
						'data-name': 'serviceablePincodes',
					}}
				/>
			);
		});
	};
	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}>
			<div className='form-field row'>
				<label className='form-label'>Service Pincodes</label>
				<div className={`${styles['tag-wrapper']}`}>
					{fields.serviceablePincodes &&
						generateTags(
							fields.serviceablePincodes,
							'serviceablePincodes'
						)}
					{!isDisabled && (
						<div
							className={`${styles.add}`}
							onClick={handleClick}
							dataAttrs={{
								'data-type': 'serviceablePincodes',
								'data-id': Pincodes,
								'data-name': 'serviceablePincodes',
							}}
						>
							<AddOutlinedIcon
								fontSize='medium'
								color='primary'
							/>
						</div>
					)}
				</div>

				<PincodeModal handleConfirm={handleConfirm} />
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { data, show } = state.modal.pincodes;
	return {
		show,
		data,
	};
};
PincodeModal.defaultProps = {
	show: true,
	isDisabled: false,
	handleDelete: () => {},
};

const mapDispatchToProps = {
	togglePincodeModal: modalActions.togglePincodeModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Pincodes);
