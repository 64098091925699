/* eslint-disable no-tabs */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions, modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './MenuListModal.module.scss';
import { CheckList } from '../../CheckList';

const HEADING = 'Select a Menu';
const initialState = {
	selectedList: [],
};
const MenuListModal = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { show, toggleModal, fetchMenus, menus, data } = props;
	useEffect(() => {
		fetchMenus({
			entity: 'MASTER_MENUS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);
	const [state, setState] = useState({
		...initialState,
	});
	const { selectedList } = state;
	const handleClose = () => {
		setState({
			...initialState,
		});
		toggleModal(false);
	};
	const handleClick = (idx) => {
		setState({
			...state,
			selectedList: [idx],
		});
	};
	const handleConfirm = () => {
		props.handleConfirm(
			data.idx,
			data.id,
			menus[selectedList[0]].id,
			menus[selectedList[0]],
			data.menuType
		);
		toggleModal(false);
		setState({
			...state,
			selectedList: [],
		});
	};

	const generateList = () => {
		return Object.keys(menus).map((id) => {
			const menu = menus[id];
			return {
				id: parseInt(id, 10),
				title: menu.menuName,
			};
		});
	};

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={HEADING}
			open={show}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<CheckList
					list={generateList()}
					selectedItems={state.selectedList}
					handleItemSelect={handleClick}
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.menuList;
	const menus = state.form.MASTER_MENUS.data.LIST;
	return {
		show,
		data,
		menus,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleMenuList,
	fetchMenus: formActions.gateway,
	updateMenu: formActions.gateway,
};
MenuListModal.defaulProps = {
	selectedProdList: [],
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuListModal);
