/* eslint-disable no-shadow */
/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import moment from 'moment';
import styles from './CreditNotePage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../Components/Loaders';
import ListTable from '../../Components/ListTable/ListTable';
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
import myMachineService from '../../Services/AssetServices/myMachine.service';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { PaymentModal } from '../../Components/Modals/PaymentModal';
import { useAuth } from '../../Components/Auth/Login/auth';
import { urls } from '../../Constants';
import { TransactionsModal } from '../../Components/Modals/TransactionsModal';
import { capitalizeFirstLetter, useQuery } from '../../Helpers';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Destination Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sourcing Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Debit/Credit Note Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Balance Amount',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View Applied On',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'View',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const FIND_FILTERS = ['vendor', 'status', 'dateRangeOnly', 'filteredStore'];

const statusList = [
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'PAID',
		title: 'PAID',
	},
	{
		id: 'UNPAID',
		title: 'UNPAID',
	},
	{
		id: 'PARTIALLY_PAID',
		title: 'PARTIALLY PAID',
	},
	{
		id: 'APPROVAL_PENDING_MANAGER',
		title: 'APPROVAL PENDING MANAGER',
	},
	{
		id: 'APPROVAL_PENDING_FINANCE',
		title: 'APPROVAL PENDING FINANCE',
	},
];

const COLMAP = {
	id: 'id',
	type: 'subType',
	Vendor: 'vendorName',
	'Destination Store': 'storeName',
	'Sourcing Store': 'fromStoreName',
	'Order id': 'orderId',
	'Client Bill. Id': 'parentTransactionRecord.clientTransactionId',
	'Client Id': 'clientTransactionId',
	'Debit/Credit Note Date': 'transactionDate',
	Status: 'transactionStatus',
	'Total Amount': 'finalTransactionAmount',
	'Balance Amount': 'currentAmount',
};

function CreditNotePage({
	userPermissions,
	createPath,
	togglePOItemsModal,
	creditPageType,
	navState,
	toggleAlert,
	toggleTransactionsModal,
	userList,
	fetchUserList,
}) {
	const params = useQuery();
	let poId = params.get('poId') ? Number(params.get('poId')) : undefined;
	let clientPOId = params.get('clientPoId');
	const isCreditsPage = creditPageType === 'credits';
	// const navRequisites =
	// navState?.[isCreditsPage ? urls.PURCHASE_ORDERS : urls.SALES_ORDERS]
	// ?.requisites ?? [];
	// const navSelectedStore = navRequisites?.find((ele) => {
	// return ele?.type === 'store';
	// });
	const history = useHistory();
	const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [creditNoteList, setCreditNoteList] = useState([]);
	const [update, setUpdate] = useState(0);
	const [externalFilters, setExternalFilters] = useState([]);

	// user Access

	// userAcess
	// useEffect( () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/finance/management']?.submenu['/analytics/po/credits']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));

	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item?.name === 'Finance');
	// const billApproval = roleDetails.some((ele) => ele?.name === 'Finance');
	// const cxoApproval = roleDetails.some((ele) => ele?.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// // end user access

	useEffect(() => {
		if (poId) {
			setUpdate((prev) => {
				return prev + 1;
			});
			const poFilterExternal = {
				id: poId,
				type: 'orderId',
				title: `Order Id: ${clientPOId}`,
				removable: true,
				handleRemove: () => {
					params.delete('poId');
					params.delete('clientPoId');
					poId = null;
					clientPOId = null;
					setUpdate((prev) => {
						return prev + 1;
					});
					setExternalFilters((prev) => {
						return prev?.filter((ele) => {
							return ele?.type !== 'orderId';
						});
					});
					history.replace({
						search: params.toString(),
					});
				},
			};
			setExternalFilters((prev) => {
				const exists = prev?.find((ele) => {
					return ele?.type === 'orderId';
				});
				if (exists) {
					if (exists?.id !== poId) {
						return prev?.map((ele) => {
							if (ele?.type === 'orderId') {
								return poFilterExternal;
							}
							return ele;
						});
					}
					return prev;
				}
				return [...prev, poFilterExternal];
			});
		}
	}, [poId]);

	const handleListFetch = async (requestOptions) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								vendors: requestOptions?.vendors,
								storeId: requestOptions?.store,
								status: requestOptions?.status,
								orderId: poId,
								...(!poId && {
									dateRangeFilter: {
										from: moment(
											requestOptions?.dateRange?.from
										).format('YYYY-MM-DD'),
										to: moment(
											requestOptions?.dateRange?.to
										).format('YYYY-MM-DD'),
									},
								}),
							},
							type: 'CREDIT',
							transactionItems: true,
						},
					},
				});
			if (responseData?.statusCode === 200) {
				// setSelectedStore(store);
				setCreditNoteList(responseData?.data);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleCancelCredit = async (item) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: item?.id,
					},
					data: {
						status: 'CANCELLED',
						type: 'CREDIT',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleAppliedOnBills = async (credItem) => {
		try {
			const response =
				await inventoryAnalyticsServices.getTransactionRecord({
					params: {
						transactionId: credItem?.id,
						type: 'CREDIT',
					},
				});
			if (response?.statusCode === 200) {
				toggleTransactionsModal(true, {
					listData: response?.data?.PAYMENTS?.map((ele) => {
						return {
							...ele,
							creditUsedAmount: ele?.txnUsedAmount,
						};
					}),
					transactionType: 'PAYMENTS',
					heading: `#${
						response?.data?.clientTransactionId ??
						response?.data?.id
					}- Debit Applied on Payments`,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleApproveClick = (item) => {
		const noteType = item?.subType?.split('_')?.join(' ');
		togglePOItemsModal(true, {
			heading: `${capitalizeFirstLetter(noteType)} of #${
				item?.clientTransactionId ?? item?.id
			}`,
			...item,
			isApprove: true,
			advanceBill: true,
			approvalText: `I have reviewed and checked the ${
				item?.subType === 'DEBIT_NOTE' ? 'Debit Note' : 'Credit Note'
			}.`,
			showTotal: false,
			showPdf: true,
			showPoDetails: true,
			width: '90%',
			height: '90%',
			transactionData: {
				'Client ID': item?.clientTransactionId,
				Status: item?.transactionStatus?.split('_')?.join(' '),
				'Destination Store': item?.storeName,
				'Sourcing Store': item?.fromStoreName,
				'Payment Type': item?.extraData?.paymentType,
				// 'Payment Mode': item?.extraData?.paymentMode,
				Vendor: item?.vendorName,
				'Transaction Amount': `\u20B9${item?.finalTransactionAmount}`,
				'Transaction Date': moment(item?.transactionDate)?.format(
					'DD-MM-YYYY'
				),
			},
			excludedColumns: [
				'Category Name',
				'Ordered Quantity',
				'Returned Quantity',
				'Packaged',
				'Received',
				'Dispatched',
				'Fulfilled',
				'Returned',
				'From Process',
				'PO Id',
				'Used For',
			],
		});
	};

	const SPECIAL_ITEMS = {
		type: (typeVal) => {
			const withoutUnderscore = typeVal?.split('_')?.join(' ');
			return (
				<p>{typeVal ? withoutUnderscore : '-'}</p>
			);
		},
		'View Applied On': (id) => {
			const credItem = creditNoteList?.find((ele) => {
				return id === ele?.id;
			});
			return (
				<Visibility
					className={`${styles['action-btn']} ${styles.view}`}
					onClick={() => {
						handleAppliedOnBills(credItem);
					}}
				>
					Cancel
				</Visibility>
			);
		},
		Actions: (id) => {
			const credItem = creditNoteList?.find((ele) => {
				return id === ele?.id;
			});
			return (
				<div className={styles.btns}>
					{(
					credItem?.transactionStatus ===
						'APPROVAL_PENDING_MANAGER') ? (
							<Button
									variant='contained'
									color='primary'
									onClick={() => {
									handleApproveClick(credItem);
								// handleApproval('APPROVAL_PENDING_MANAGER', id);
								}}
									fullWidth
								>
								Manager Approval
								</Button>
						) : null}
					{(credItem?.transactionStatus === 'APPROVAL_PENDING_FINANCE')  ? (
							<Button
									variant='contained'
									color='primary'
									onClick={() => {
									handleApproveClick(credItem);
								// handleApproval('APPROVAL_PENDING_FINANCE', id);
								}}
									fullWidth
								>
								Finance Approval
								</Button>
						) : null}
					{credItem?.transactionStatus !== 'CANCELLED' &&
						credItem?.transactionStatus !==
							'APPROVAL_PENDING_FINANCE' &&
						credItem?.transactionStatus !== 'REJECTED' &&
						credItem?.finalTransactionAmount ===
						credItem?.currentAmount ? (
							<Button
								onClick={() => {
									toggleAlert(true, {
										heading: `Cancel Payment ${
											credItem?.clientTransactionId ??
											credItem?.id
										}`,
										handleConfirm: () => {
											handleCancelCredit(credItem);
											toggleAlert(false);
										},
										colorReverse: false,
										cancelText: 'No',
										confirmText: 'Yes',
										message: `Are you sure you want to Cancel Debit: ${
											credItem?.clientTransactionId || ''
										}?`,
										width: '28rem',
									});
								}}
								variant='outlined'
								fullWidth
								color='error'
							>
								Cancel
							</Button>
						) : (
							'-'
						)}
				</div>
			);
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleViewClick = (item) => {
		const noteType = item?.subType?.split('_')?.join(' ');
		togglePOItemsModal(true, {
			width: '90%',
			height: '90%',
			showPoDetails: true,
			heading: `${capitalizeFirstLetter(noteType)} of #${
				item?.clientInvoiceId ?? item?.id
			}`,
			...item,
			transactionData: {
				'Client ID': item?.clientTransactionId ?? '-',
				'Order ID': item?.orderId,
				'Vendor Name': item?.vendorName ?? '',
				Store: item?.storeName,
				[`${capitalizeFirstLetter(noteType)} Date`]: moment(
					item?.createdAt
				).format('YYYY-MM-DD'),
				[`${capitalizeFirstLetter(noteType)} Amount`]:
					item?.finalTransactionAmount,
			},
			excludedColumns: [
				'Packaged',
				'Ordered Quantity',
				'Dispatched',
				'Received',
				'Returned Quantity',
				'Fulfilled',
				'From Process',
				'PO Id',
				'Returned',
				// 'Quantity',
				'Used For',
			],
		});
	};

	const handleStatus = async (data, rejectionReason, type) => {
		let status = '';
		if (data?.transactionStatus === 'APPROVAL_PENDING_MANAGER') {
			status = 'APPROVAL_PENDING_FINANCE';
		}
		if (data?.transactionStatus === 'APPROVAL_PENDING_FINANCE') {
			status = 'UNPAID';
		}
		if (type === 'rejection') {
			status = 'REJECTED';
		}
		if (type === 'cancelation') {
			status = 'CANCELLED';
		}
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: data?.id,
					},
					data: {
						status,
						...(type === 'rejection' && {
							rejectionReason,
						}),
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
				togglePOItemsModal(false, {});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<POItemsModal
				handleConfirm={handleStatus}
				handleReject={(data, rejectionReason) => {
					handleStatus(data, rejectionReason, 'rejection');
				}}
			/>
			<TransactionsModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Debit Notes'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					update={update}
					statusList={statusList}
					additionInfo={{
						showAdd: false,
						// showAdd: false,
						addText: 'Debit Page',
						handleAddClick,
					}}
					externalFilters={externalFilters}
					dateRangeOnlyText='Debit/Credit Note Date'
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS?.filter((ele) => {
					if (!isCreditsPage) {
						return ele?.title !== 'Client Bill. Id';
					}
					return true;
				})}
				data={creditNoteList}
				// data={dummyData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleViewClick={handleViewClick}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state.auth;
	return {
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
	toggleTransactionsModal: modalActions.toggleTransactionsModal,
	toggleAlert: modalActions.toggleAlert,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotePage);
