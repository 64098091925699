import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const temperatureAnalyticsServices = {
	gateway,
	// fetchAnalyticsList,
	fetchImagesById,
	fetchDigitImagesById,
};

function fetchImagesById(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(
		endpoints.FIND_TEMPERATURE_IMAGES_BY_ID,
		params
	);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions).then(handleResponse);
}
function fetchDigitImagesById(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(
		endpoints.FIND_DIGIT_TEMPERATURE_IMAGES_BY_ID,
		params
	);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch2(url, requestOptions).then(handleResponse);
}

function gateway({ req, method }) {
	switch (method) {
		case 'FIND_DIGIT_TEMPERATURE_IMAGES':
			return findDigitTemperatureImages(req);
		case 'FIND_TEMPERATURE_IMAGES':
			return findTemperatureImages(req);
		// case 'LIST_DATA':
		// return fetchAnalyticsList({
		// req,
		// });
		default:
			break;
	}
	return new Promise();
}

function findDigitTemperatureImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_DIGIT_TEMPERATURE_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}
function findTemperatureImages(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.FIND_TEMPERATURE_IMAGES, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return mockFetch1(url, requestOptions);
}

export default temperatureAnalyticsServices;
