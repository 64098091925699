import { endpoints } from '../../Constants';
// eslint-disable-next-line object-curly-newline
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const allVendorServices = {
	gateway,
	createVendor,
	viewVendor,
	patchVendor,
	vendorProductMapList,
	mapVendorToProduct,
	getMappedVendors,
	deleteMapping,
	createVendorContract,
	ListVendorContract,
	VendorContractInfo,
	editVendorContract,
	VendorContractDelete,
	VendorContractProductsPricing,
	VendorContractFilter,
	listVendorType,
	fetchVendorOpenings,
	exportVendors,
	listVendor,
	exportBuyers,
};

function gateway({ req, method, type = 'VENDOR' }) {
	switch (method) {
		case 'CREATE':
			return createVendor({
				req,
				type,
			});
		case 'VIEW':
			return viewVendor({
				req,
			});
		case 'LIST':
			return listVendor({
				req,
				type,
			});
		case 'UPDATE':
			return updateVendor({
				req,
			});
		case 'PATCH':
			return patchVendor({
				req,
			});
		case 'DELETE':
			return deleteVendor({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function createVendor({ req, type }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	let url = endpoints.ADD_VENDOR;
	if (type === 'BUYER') {
		url = endpoints.ADD_BUYERS;
	}
	return fetch(url, requestOptions).then(handleResponse);
}
function patchVendor({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ALL_VENDORS, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function updateVendor({ req }) {
	// const params = {
	// ...req.params,
	// };

	// const url = toQueryStringURL(endpoints.UPDATE_VENDOR, params);

	const requestOptions = {
		method: 'PATCH',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.UPDATE_VENDOR, requestOptions).then(handleResponse);
}

function viewVendor({ req }) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.ALL_VENDORS, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listVendorType() {
	const params = {};

	const url = toQueryStringURL(endpoints.VENDOR_TYPES, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function listVendor({ req, type = 'VENDOR' }) {
	const params = {
		...req.params,
	};

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	let url = toQueryStringURL(endpoints.ALL_VENDORS, params);
	if (type === 'BUYER') {
		url = toQueryStringURL(endpoints.ALL_BUYERS, params);
	}

	return fetch(url, requestOptions).then(handleResponse);
}
function deleteVendor({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.DELETE_VENDOR, params);
	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function vendorProductMapList(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.VENDOR_PRODUCT_MAP_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function mapVendorToProduct(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.ADD_VENDOR_PRODUCT_MAP, requestOptions).then(
		handleResponse
	);
}
function getMappedVendors(req) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.MAPPED_VENDORS_TO_PRODUCT, params);

	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function deleteMapping(req) {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.VENDOR_PRODUCT_MAP_LIST, requestOptions).then(
		handleResponse
	);
}

function createVendorContract(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	let url = endpoints.CREATE_VENDOR_CONTRACT;
	if (req?.pageType === 'BUYER') {
		url = endpoints?.BUYER_CONTRACT_CREATE;
	}
	return fetch(url, requestOptions).then(
		handleResponse
	);
}
function editVendorContract(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	let url = endpoints.EDIT_VENDOR_CONTRACT;
	if (req?.pageType === 'BUYER') {
		url = endpoints.EDIT_BUYER_CONTRACT;
	}
	return fetch(url, requestOptions).then(
		handleResponse
	);
}

function ListVendorContract(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	let url = endpoints.LIST_VENDOR_CONTRACT;
	if (req?.pageType === 'BUYER') {
		url = endpoints.LIST_BUYER_CONTRACT;
	}
	return fetch(url, requestOptions).then(
		handleResponse
	);
}
function VendorContractInfo(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	return fetch(endpoints.VENDOR_CONTRACT_INFO, requestOptions).then(
		handleResponse
	);
}

function VendorContractDelete(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	let url = endpoints.VENDOR_CONTRACT_DELETE;
	if (req?.type === 'BUYER') {
		url = endpoints.BUYER_CONTRACT_DELETE;
	}
	return fetch(url, requestOptions).then(
		handleResponse
	);
}

function VendorContractProductsPricing(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	return fetch(endpoints.VENDOR_CONTRACT_PRODUCTS_PRICING, requestOptions).then((response) => {
		return response.text().then((text) => {
			const data = text && JSON.parse(text);
			if (data.status === 'success') {
				return data;
			}
			return {};
		});
	});
}

function VendorContractFilter(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req,
		}),
	};
	let url = endpoints.VENDOR_CONTRACT_PRODUCTS_FILTER;
	if (req?.pageType === 'BUYER') {
		url = endpoints.BUYER_CONTRACT_PRODUCTS_FILTER;
	}
	return fetch(url, requestOptions).then(
		handleResponse
	);
}

function fetchVendorOpenings(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.VENDOR_OPENINGS, requestOptions).then(
		handleResponse
	);
}
function exportVendors(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.EXPORT_VENDORS, requestOptions);
}
function exportBuyers(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.EXPORT_BUYERS, requestOptions);
}
export default allVendorServices;
