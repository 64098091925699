/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import BOMDashboard from '../../../Pages/BOMDashboard/BOMDashboard';
import PastBOMAnalysisHistoryPage from '../../../Pages/BOMDashboard/PastBOMAnalysisHistoryPage';
import BOMHistory from '../../../Pages/BOMDashboard/BOMHistory';

const getSubURL = (entity) => {
	const baseEntity = urls.BOM_ANALYTICS;
	return entity.slice(baseEntity.length);
};
const BOMAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route path={`${path}${getSubURL(urls.BOM_ANALYSIS)}`} exact>
					<BOMDashboard />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.BOM_ANALYSIS_LIST)}`}
					exact
				>
					<PastBOMAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.BOM_ANALYSIS,
								name: 'Production Cost Analysis',
							},
							{
								link: '',
								name: 'Production Cost Analysis History List',
							},
						]}
					/>
				</Route>
				<Route
					path={`${path}${getSubURL(urls.BOM_HISTORY_LIST)}`}
					exact
				>
					<BOMHistory />
				</Route>
			</Switch>
		</div>
	);
};

export default BOMAnalyticsRoutes;
