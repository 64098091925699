import { Switch } from '@mui/material';
import { DraggableHTabs } from '../../../Draggable';
import { DropDownIconInput, IconInput } from '../../Inputs';
import styles from './InventoryConfigDetails.module.scss';

const generateDefaultOption = (type, value) => {
	switch (type) {
		case 'unit':
			if (!value || value.length === 0) {
				return (
					<option value='' selected disabled hidden>
						Select a unit
					</option>
				);
			}
			break;
		default:
			break;
	}
	return <></>;
};

const InventoryConfigDetails = (props) => {
	const {
		fields,
		activeVariant,
		setActiveVariant,
		mandatoryFields,
		handleInput,
		isDisabled,
	} = props;

	const activeVariantFields = fields?.variants?.items?.[activeVariant] ?? {};

	return (
		<div className={styles.root}>
			<div className='form-field row'>
				<label
					htmlFor='IMSEnabled'
					data-mandatory={mandatoryFields.IMSEnabled}
					className='form-label'
				>
					Inventory Management
				</label>
				<Switch
					name='IMSEnabled'
					value='IMSEnabled'
					size='small'
					color='primary'
					disabled={isDisabled}
					onChange={handleInput}
					checked={fields?.IMSEnabled}
				/>
			</div>
			<div className='form-field row'>
				<label
					htmlFor='unit'
					data-mandatory={mandatoryFields.unit}
					className='form-label'
				>
					Tracking Unit
				</label>
				<select
					id='unit'
					name='unit'
					value={fields.unit}
					onChange={handleInput}
					className={`form-value ${styles.unit}`}
					disabled={isDisabled}
				>
					{generateDefaultOption('unit', fields.unit)}
					<option value='gram'>gram</option>
					{/* <option value='litre'>litre</option> */}
					<option value='ml'>mL</option>
					<option value='count'>count</option>
				</select>
			</div>

			<div className='form-field row'>
				<label
					htmlFor='bulkEnabled'
					data-mandatory={mandatoryFields.bulkEnabled}
					className='form-label'
				>
					Bulk Consumption
				</label>
				<Switch
					name='bulkEnabled'
					value='bulkEnabled'
					size='small'
					color='primary'
					disabled={isDisabled}
					onChange={handleInput}
					checked={fields?.bulkEnabled}
				/>
			</div>
			{!fields?.bulkEnabled && (
				<>
					<div className='form-field row'>
						<label htmlFor='batchEnabled' className='form-label'>
							Batch Mode
						</label>
						<Switch
							name='batchEnabled'
							value='batchEnabled'
							size='small'
							color='primary'
							disabled={isDisabled}
							onChange={handleInput}
							checked={fields?.batchEnabled}
						/>
					</div>
					<fieldset disabled={isDisabled} className='form-fieldset'>
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='expires'
								data-mandatory={mandatoryFields.expiry}
							>
								Expires In
							</label>
							<DropDownIconInput
								size='large'
								type='custom'
								iconText={fields.expiry}
								name='expiry'
								id='expiry'
								value={fields.expiry}
								handleInput={handleInput}
								dataType=''
								dataId=''
								selectValue={fields.expiryUnit}
								dataAttrs={{
									required: mandatoryFields.expiry,
								}}
							/>
						</div>
					</fieldset>
					<DraggableHTabs
						items={fields.variants.items}
						order={fields.variants.order}
						handleActiveTab={setActiveVariant}
						activeTab={activeVariant}
						limit={10}
						text=''
						isDisabled
						isDraggable={false}
					/>
					{/* variant inputs */}
					<fieldset
						disabled={isDisabled}
						className={`form-fieldset row ${styles['variant-inputs']}`}
					>
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='qty'
								data-mandatory={mandatoryFields.qty}
							>
								Qty. in SKU
							</label>
							<IconInput
								size='large'
								type='custom'
								className={styles.inputWidthOverride}
								iconText={fields?.unit ?? 'unit'}
								name='qty'
								max={12000}
								id='qty'
								value={activeVariantFields?.qty}
								handleInput={handleInput}
								dataType=''
								dataId=''
								dataAttrs={{
									required: true,
									'data-type': 'variant',
									'data-id': activeVariant,
								}}
								step='0.01'
							/>
						</div>
						<div className='form-field row'>
							<label
								htmlFor='threshold'
								data-mandatory={mandatoryFields.threshold}
								className={`${styles.label} form-label`}
							>
								Threshold
							</label>
							<IconInput
								size='large'
								type='custom'
								iconText={fields?.unit ?? 'unit'}
								name='threshold'
								id='threshold'
								className={styles.inputWidthOverride}
								max={12000}
								value={activeVariantFields?.threshold}
								handleInput={handleInput}
								dataType=''
								dataId=''
								dataAttrs={{
									required: true,
									'data-type': 'variant',
									'data-id': activeVariant,
								}}
								step='0.01'
							/>
						</div>
					</fieldset>
				</>
			)}
		</div>
	);
};

InventoryConfigDetails.defaultProps = {
	isDisabled: false,
};

export default InventoryConfigDetails;
