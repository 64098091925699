/* eslint-disable no-unused-vars */
import { useCallback, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { useHistory } from 'react-router';
import { CHARTS_CONSTANTS, DASHBOARD_CHARTS_COLORS } from '../../../Styles/colors';
import DownloadButton from '../DownloadButton/DownloadButton';
import Heading from '../Heading/Heading';
// import { STAT_COLORS } from '../../../Styles/colors';
import styles from './BarWithLineChart.module.scss';
import { classes, formatNumber } from '../../../Helpers';
import { useAuth } from '../../../Components/Auth/Login/auth';

ChartJS.defaults.font.size = 11;

function paginate(array, pageSize, pageNumber) {
	return array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}
const PAGE_SIZE = 4;

function BarWithLineChart({ data, className = '' }) {
	const { heading: title, tooltip, labels, limit = undefined } = data;
	const [pageNo, setPageNo] = useState(1);
	const history = useHistory();
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;
	// const dataSets = data.datasets[0].barData;
	// const dataSets2 = data.datasets[0].lineData;
	const buildDataSets = data.datasets.map((ele, index) => {
		let currentChart = {};
		const barProperties = {
			fill: false,
			borderRadius: 4,
			borderSkipped: false,
			barThickness: 20,
			barPercentage: 0.2,
			backgroundColor: CHARTS_CONSTANTS.primary,
			yAxisID: 'left-y-axis',
		};
		const lineProperties = {
			backgroundColor: DASHBOARD_CHARTS_COLORS.barChartLine[index],
			pointRadius: 6,
			pointHitRadius: 6,
			pointHoverRadius: 8,
			borderColor: DASHBOARD_CHARTS_COLORS.barChartLine[index],
			yAxisID: 'right-y-axis',
		};
		if (ele.type === 'LINE') {
			currentChart = lineProperties;
		} else if (ele.type === 'BAR') {
			currentChart = barProperties;
		}
		const dataP = paginate(ele?.data?.slice(0, limit), PAGE_SIZE, pageNo);
		const ids = paginate(ele?.ids?.slice(0, limit), PAGE_SIZE, pageNo);
		const url = ele?.url;
		const label = ele?.label;
		return {
			type: ele?.type?.toLowerCase(),
			data: dataP,
			label,
			ids,
			url,
			...currentChart,
		};
	});

	const previousPage = useCallback(() => {
		if (pageNo > 1) {
			setPageNo((page) => {
				return page - 1;
			});
		}
		return false;
	}, [data, pageNo]);
	const nextPage = useCallback(() => {
		if (pageNo < labels.length / PAGE_SIZE) {
			setPageNo((page) => {
				return page + 1;
			});
		}
		return false;
	}, [data, pageNo]);
	return (
		<div className={classes(styles.root, className)}>
			<span className={styles.xLabel}>{data?.xlabel}</span>
			{pageNo > 1 && (
				<span className={styles.leftClick} onClick={previousPage}>
					{'<'}
				</span>
			)}
			{pageNo < labels.length / PAGE_SIZE && (
				<span className={styles.rightClick} onClick={nextPage}>
					{'>'}
				</span>
			)}
			<span className={styles.yLabel}>{data?.ylabel}</span>
			<span className={styles.yLabelLine}>{data?.ylabelLine}</span>
			<div className={styles.title}>
				<Heading tooltip={tooltip} heading={title} />
				<DownloadButton data={data} />
			</div>
			<div className={styles.chart}>
				<Bar
					// className={styles.chart}
					data={{
						datasets: [...buildDataSets],
						labels: paginate(labels, PAGE_SIZE, pageNo),
					}}
					options={{
						onClick(evt, currentSet) {
							const currentDataset =
								buildDataSets[currentSet?.[0]?.datasetIndex];
							const currentIndex = currentSet?.[0]?.index;
							const url = currentDataset?.url;
							if (url) {
								history.push(
									`${url}${currentDataset?.ids?.[currentIndex]}`
								);
							}
						},
						maintainAspectRatio: false,
						responsive: true,
						aspectRatio: 1,
						plugins: {
							legend: {
								labels: {
									// boxWidth: 15,
									usePointStyle: true,
								},
							},
						},
						scales: {
							'left-y-axis': {
								type: 'linear',
								position: 'left',
								grid: {
									display: false,
								},
								ticks: {
									callback(value, index, values) {
										const convertedValue = formatNumber(measurement, value);
										return convertedValue;
									},
								},
							},
							'right-y-axis': {
								type: 'linear',
								position: 'right',
								grid: {
									display: false,
								},
								ticks: {
									color: 'rgb(73, 113, 116)',
									font: {
										weight: 'bold',
									},
								},
							},

							x: {
								grid: {
									display: false,
								},
								ticks: {
									callback: (value, index) => {
										const label = paginate(
											labels,
											PAGE_SIZE,
											pageNo
										)
											?.[index]?.split(' ')
											?.filter((val, idx) => {
												return (
													idx < 2 &&
													val?.substring(0, 5)
												);
											});
										return label;
									},
								},
							},
						},
					}}
				/>
			</div>
		</div>
	);
}

export default BarWithLineChart;
