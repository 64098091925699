/* eslint-disable max-len */
import { useState } from 'react';
import ViewOnlyCoreSpec from './ViewOnlyCoreSpec';
import SingleAssetSelectionModal from './SingleAssetSelectionModal';

const AssetGroup = ({
	specsToBeShownOnGroups,
	category,
	oem,
	assetType,
	assets,
	imageUrl,
	groupKey,
	onAssetSelect,
	handleCartChange,
	currentCartCount,
	showAsItem = false,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	return (
		<div className='mb-4 border rounded p-2 relative bg-white overflow-hidden shadow-sm border-blue-800 hover:shadow-md transition-shadow duration-200'>
			<div className='flex  relative items-start'>
				<div className='relative z-10 h-24 mb-1'>
					<img
						src={
							imageUrl ??
							assetType?.assetTypeImageUrl ??
							'https://dummyjson.com/image/150'
						}
						alt='Asset preview'
						className='w-full h-full object-cover rounded-lg shadow-sm transition-transform duration-300 hover:scale-125 origin-top-left'
					/>
				</div>
				<div className='flex flex-col flex-1 items-start justify-start content-start pl-2'>
					<div className='text-sm font-medium truncate'>
						{assetType?.name}
					</div>
					<div className='text-xs text-gray-500 truncate'>
						{oem?.name}
					</div>
				</div>
				<div className='flex absolute bottom-1 right-1 bg-gray-200 bg-opacity-90 py-[.1rem] rounded-tl-xl text-gray-700 justify-between items-center'>
					<span className='px-2 rounded-md text-[10px] font-medium'>
						{category?.name}
					</span>
				</div>
			</div>
			{/* <div className='flex absolute -top-0 -left-0 bg-blue-800 bg-opacity-90 py-[.1rem] rounded-br-xl text-white justify-between items-center'>
				<span className='px-2 rounded-md text-[10px] font-medium'>
					{assetType?.name}
				</span>
			</div>
			<div className='absolute top-2 right-2 flex gap-1'>
				<span className='px-2 py-[.1rem] text-[10px] font-medium rounded-sm bg-blue-50 text-blue-600'>
					{category?.name}
				</span>
			</div>
			<span className='px-2 py-[.1rem] text-xs font-medium text-gray-600'>
				{oem?.name}
			</span> */}
			<div className='space-y-[.1rem]'>
				<ViewOnlyCoreSpec
					showPrev={false}
					coreSpecs={specsToBeShownOnGroups}
				/>
			</div>
			{!showAsItem && (
				<>
					<div className='mt-2 flex flex-row-reverse justify-between items-center'>
						{/* <button
							onClick={() => setModalOpen(true)}
							className='text-sm w-1/2 text-blue-500 hover:text-blue-700'
						>
							Show Assets ({assets.length})
						</button> */}
						<div className='flex items-center justify-center border rounded-md p-1 w-1/2  gap-2'>
							{!currentCartCount ? (
								<>
									<button
										type='button'
										onClick={() => {
											handleCartChange(groupKey, +1);
										}}
										className='px-3 w-full py-1.5 text-sm bg-uy-color  rounded-md transition-colors duration-200'
									>
										Add
									</button>
								</>
							) : (
								<>
									<button
										type='button'
										onClick={() => {
											handleCartChange(groupKey, -1);
										}}
										className='px-3 py-1.5 text-sm bg-slate-100 rounded-md transition-colors duration-200'
										disabled={!currentCartCount}
									>
										-
									</button>
									<span className='w-8 text-center text-sm font-medium text-indigo-600'>
										{currentCartCount}
									</span>
									<button
										type='button'
										onClick={() => {
											handleCartChange(groupKey, +1);
										}}
										className='px-3 py-1.5 text-sm bg-uy-color rounded-md transition-colors duration-200'
									>
										+
									</button>
								</>
							)}
						</div>
					</div>
					<SingleAssetSelectionModal
						open={modalOpen}
						onClose={() => setModalOpen(false)}
						assets={assets}
						onSelect={onAssetSelect}
						// cartItems={assets.filter((asset) => currentCartCount)}
					/>
				</>
			)}
		</div>
	);
};

export default AssetGroup;
