import { endpoints } from '../Constants';
import { authHeader, handleResponse } from '../Helpers';

const modulesConfiguration = {
	getModulesConfiguration,
	updateModulesConfiguration,
};

function getModulesConfiguration() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(endpoints.GET_MODULES_CONFIGURATION, requestOptions)
		.then(handleResponse);
}

function updateModulesConfiguration(req) {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify({
			...req.data,
		}),
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			clientId: 5,
		},
	};
	return fetch(endpoints.UPDATE_MODULES_CONFIGURATION, requestOptions)
		.then(handleResponse);
}

export default modulesConfiguration;
