/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-mixed-spaces-and-tabs */
import { connect } from 'react-redux';
import { useState, useRef } from 'react';
import drawerActions from '../../../Actions/drawer.actions';
import settingsSubmenu from './SettingsSubmenu';
import { Button } from '@/Components/ui/button';
import { notificationServices } from '../../../Services';
import processFlowServices from '@/Services/Analytics Services/process-flow.services';
import BaseDrawer from '../BaseDrawer/BaseDrawer';

const ProcessSettingsDrawer = (props) => {
	const { show, data, toggleProcessSetting } = props;
	const {
		baseStores,
		storeId,
		setInitialState,
		isChanged,
		setIsChanged,
		immovableAssetsList,
	} = data ?? {};
	const [active, setActive] = useState({});
	const [activeConfirm, setActiveConfirm] = useState(false);
	const onClose = () => {
		toggleProcessSetting(false, {});
	};
	const handleClick = (item) => {
		setActive(item);
		if (item?.id !== 3) {
			setActiveConfirm(true);
		} else {
			setActiveConfirm(false);
		}
	};
	const ref = useRef({
		heading: '',
		flowColor: '',
		newSectionName: '',
		sectionColor: '',
	});

	const handleAddNewProcess = async (e) => {
		e.preventDefault();
		if (!ref?.current?.heading) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Process Name is mandatory',
			});
			return;
		}
		const req = {
			data: {
				heading: ref?.current?.heading,
				color: ref?.current?.flowColor,
			},
		};
		try {
			const response = await processFlowServices?.addNode(req);
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: response?.message,
				});
				// fetchNodes();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error uploading image:', error);
		}
	};
	// second sub menu
	const handleSaveSection = async () => {
		if (!ref.current.newSectionName) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please enter section Name',
			});
			return;
		}
		if (!storeId) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please Select Store first',
			});
			return;
		}
		const req = {
			data: {
				sectionName: ref.current.newSectionName,
				color: ref.current.sectionColor,
				storeId,
			},
		};
		try {
			const response = await processFlowServices?.addSection(req);
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: response?.message,
				});
				// fetchSections();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error uploading image:', error);
		}
	};

	const handleConfirmClick = (e) => {
		switch (active?.id) {
			case 1:
				handleAddNewProcess(e);
				break;
			case 2:
				handleSaveSection(e);
				break;
			// case 3:
			// handleSave(e);
			// setShowConfirm(false);
			// break;
			default:
				(() => {})();
				break;
		}
	};

	const renderBtns = () => {
		if (active.id && activeConfirm) {
			return (
				<div className='flex gap-2'>
					<Button variant='secondary'>Cancel</Button>
					<Button className='primary-btn' onClick={handleConfirmClick}>
						Confirm
					</Button>
				</div>
			);
		}
		return null;
	};
	return (
		<>
			<BaseDrawer
				header='Process Settings Drawer'
				open={show}
				onClose={onClose}
				renderBtns={renderBtns}
				size='sm'
			>
				<div className='grid grid-cols-6 grid-rows-'>
					<div
						className={`${
							!active.id ? 'col-span-6' : 'col-span-2'
						}`}
					>
						{settingsSubmenu.map((item) => {
							const isActive = item.id === active.id;
							return (
								<div
									onClick={() => {
										handleClick(item);
									}}
									className={`block p-4 mb-2 cursor-pointer transition-all 
                             ${isActive ? 'bg-[#ffb703]' : 'bg-[#f1f1f1]'}`}
								>
									{item.header}
								</div>
							);
						})}
					</div>
					<div
						className={` ${
							!active.id ? 'col-span-0' : 'col-span-4'
						}`}
					>
						<div>
							{active?.comp?.({
								ref,
								storeId,
								baseStores,
								setInitialState,
								isChanged,
								setIsChanged,
								immovableAssetsList,
								onClose,
							})}
						</div>
					</div>
				</div>
			</BaseDrawer>
		</>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.processSettingsDrawer;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleProcessSetting: drawerActions.toggleProcessSettingsDrawer,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProcessSettingsDrawer);
