import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import { DefectImagesPage } from '../../../Pages';

const getSubURL = (entity) => {
	const baseEntity = urls.DEFECT_ANALYTICS;
	return entity.slice(baseEntity.length);
};

const AnalyticsRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.DEFECT_IMAGES)}`}>
				<DefectImagesPage />
			</Route>
		</Switch>
	);
};

const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	return {
		navState,
	};
};

export default connect(mapStateToProps)(AnalyticsRoutes);
