import { CheckCircle, ModeEdit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
	useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
// eslint-disable-next-line import/no-cycle
import { ListTable } from '../../ListTable';
import { BaseModal } from '../BaseModal';
import config from '../../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder.config';
import styles from './GenerateStandardPo.module.scss';
import { Button } from '@/Components/ui/button';
import { Input } from '@/Components/ui/input';
import { Label } from '@/Components/ui/label';

const COLMAP = {
	Select: 'id',
	'Item Name': 'id',
	Unit: 'unit',
	'Current Stock': 'currentDayStockIn',
	'Recommended Stock Quantity': 'recommendedStockLevel',
	'Average Ordered Quantity': 'averageOrderQty',
	'Recommended Order Quantity': 'recommendedOrderQty',
	'Required Quantity': 'prePlanStock',
	'MOQ (Batch Size)': 'moq',
	'Rounded Off Ordered Quantity': 'finalProduction',
};

const SPECIAL_COLUMNS = [
	{
		title: 'Select',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Item Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Current Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Recommended Stock Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Average Ordered Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Recommended Order Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Required Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MOQ (Batch Size)',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Rounded Off Ordered Quantity',
		config: {
			type: 'DATA',
		},
	},
];

function getNextMultiple(batchSize, quantity) {
	if (batchSize) {
		return Math.ceil(quantity / batchSize) * batchSize;
	}
	return quantity;
}

const GenerateStandardPo = (props) => {
	// const history = useHistory();
	const {
		show,
		toggleGenerateStandardPOModal,
		data,
		productsList,
		poState,
		getVarPrice,
		setPoState,
	} = props;
	const { standardPOData } = data ?? {};
	const [listData, setListData] = useState([]);
	const [finalItemList, setFinalItemList] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [isEdit, setIsEdit] = useState({});

	useEffect(() => {
		setListData(standardPOData);
		setFinalItemList(standardPOData?.map((ele) => {
			return ele?.variantId;
		}));
	}, [standardPOData]);

	const handleEditClick = (varId) => {
		setIsEdit((prev) => {
			const temp = {
				...prev,
			};
			if (temp[varId]) {
				temp[varId] = false;
			} else {
				temp[varId] = true;
			}
			return temp;
		});
	};

	const handleQtyChange = (e, varId) => {
		setListData((prev) => {
			const tempData = prev?.map((ele) => {
				if (ele?.variantId === varId) {
					return {
						...ele,
						requiredQty: e?.target?.value,
						roundedOffQty: e?.target?.value
							? getNextMultiple(
								ele?.moq,
								Number(e?.target?.value)
							)
							: e?.target?.value,
					};
				}
				return ele;
			});
			return tempData;
		});
	};

	const handleItemCheck = (item, isChecked) => {
		if (isChecked) {
			setFinalItemList((prev) => {
				return prev?.filter((itemId) => {
					return itemId !== item?.variantId;
				});
			});
		} else {
			setFinalItemList((prev) => {
				return [...prev, item?.variantId];
			});
		}
	};

	const SPECIAL_ITEMS = {
		Select: (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			const isChecked = finalItemList?.includes(id);
			return (
				<input
					type='checkbox'
					onChange={() => {
						handleItemCheck(product, isChecked);
					}}
					checked={isChecked}
				/>
			);
		},
		'Item Name': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return `${product?.productName}${
				product?.variantName ? `(${product?.variantName})` : ''
			}`;
		},
		Unit: (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.unit;
		},
		'Current Stock': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.currentStock ?? '-';
		},
		'Recommended Stock Quantity': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.recommendedStockLevel;
		},
		'Average Ordered Quantity': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.averageOrderQty;
		},
		'Recommended Order Quantity': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.recommendedOrderQty;
		},
		'Required Quantity': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			const recommendedValue = product?.recommendedQty > 0 ? product?.recommendedQty : 0;

			if (isEdit[product?.variantId]) {
				return (
					<div>
						<input
							type='number'
							placeholder='Enter Required QTY'
							value={
								product?.requiredQty ?? recommendedValue
							}
							onChange={(e) => {
								handleQtyChange(e, product?.variantId);
							}}
						/>
						<IconButton
							onClick={() => {
								handleEditClick(product?.variantId);
							}}
						>
							<CheckCircle />
						</IconButton>
					</div>
				);
			}
			if (product?.requiredQty) {
				return (
					<span className={styles['order-qty']}>
						{/* <s className={styles.actual}>{product?.orderedQty}</s> */}
						<p>{product?.requiredQty}</p>
						<IconButton
							onClick={() => {
								handleEditClick(product?.variantId);
							}}
							className={styles['edit-btn']}
						>
							<ModeEdit />
						</IconButton>
					</span>
				);
			}
			return (
				<span className={styles['order-qty']}>
					<p>{recommendedValue}</p>
					<IconButton
						onClick={() => {
							handleEditClick(product?.variantId);
						}}
						className={styles['edit-btn']}
					>
						<ModeEdit />
					</IconButton>
				</span>
			);
			// return product?.updatedQty - (product?.orderedQty ?? 0);
		},
		'MOQ (Batch Size)': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.moq;
		},
		'Rounded Off Ordered Quantity': (id) => {
			const product = listData?.find((prod) => {
				return prod?.variantId === id;
			});
			return product?.roundedOffQty;
		},
	};

	const handleClose = () => {
		toggleGenerateStandardPOModal(false, {});
	};

	const handleConfirm = async () => {
		const temp = [];
		listData?.forEach((ele) => {
			if (finalItemList.includes(ele.variantId)) {
				const product = productsList?.find((prod) => {
					return prod?.id === ele?.productId;
				});
				let selectedVariant = product?.variants?.find((vrnt) => {
					return vrnt?.id === ele?.variantId;
				});
				if (!selectedVariant) {
					return;
				}
				selectedVariant = JSON.parse(JSON.stringify(selectedVariant));
				selectedVariant.price = getVarPrice(product, selectedVariant);
				temp?.push({
					id: selectedVariant?.id,
					productId: product?.id,
					productName: product?.productName,
					productImageUrl: product?.productImageUrl,
					variants: product?.variants,
					quantity: ele?.roundedOffQty,
					unit: product?.unit,
					selectedVariant,
					skuType: product?.skuType,
					tax: product?.tax,
					subTotal: config.getSubTotal(
						selectedVariant?.price,
						ele?.roundedOffQty,
						product?.unit
					),
					totalTax: config.getTotalTax(
						selectedVariant?.price,
						product?.tax,
						ele?.roundedOffQty,
						product?.unit
					),
					totalPriceIncTax: config.getTotalPrice(
						selectedVariant?.price,
						product?.tax,
						ele?.roundedOffQty,
						product?.unit
					),
				});
			}
		});
		setPoState([...poState, ...temp]);
		handleClose();
	};

	const renderBtns = () => {
		return (
			!data?.hideButtons && (
				<div className={styles.btns}>
					<Button
						onClick={handleClose}
						variant='outline'
						className='flex-1'
					>
						Close
					</Button>
					<Button
						onClick={handleConfirm}
						className='w-full flex-1 primary-btn'
					>
						Add Items
					</Button>
				</div>
			)
		);
	};

	// eslint-disable-next-line no-unused-vars
	const getRowStyles = (ele) => {
		return '';
	};

	const DATA = useMemo(() => {
		const filteredData = listData?.filter((ele) => {
			return (
				ele?.productName
					?.toLowerCase()
					?.indexOf(searchQuery?.toLowerCase()?.trim()) !== -1
			);
		})?.map((ele) => {
			return {
				...ele,
				id: ele?.variantId,
			};
		});
		return filteredData;
	}, [listData, searchQuery]);

	return (
		<BaseModal
			heading='Generated Standard PO'
			open={show}
			handleClose={handleClose}
			customStyles={{
				width: '95%',
				height: '90vh',
				// top: '25%',
			}}
			renderBtns={renderBtns}
			// handleConfirm={handleConfirm}
		>
			<div className={`${styles.root}`}>
				<div className={styles.filters}>
					<div className='flex flex-col items-start gap-1'>
						<Label>Search SKU</Label>
						<Input
							className='min-w-80 w-fit'
							value={searchQuery}
							onChange={(e) => {
								setSearchQuery(e?.target?.value);
							}}
							placeholder='Search SKU'
						/>
					</div>
				</div>
				<ListTable
					colMap={COLMAP}
					columns={[]}
					data={DATA}
					// onExportClick={handleExport}
					actions={[...SPECIAL_COLUMNS]}
					special={SPECIAL_ITEMS}
				/>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.generateStandardPoModal;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;

	return {
		show,
		data,
		productsList,
	};
};

const mapDispatchToProps = {
	toggleGenerateStandardPOModal: modalActions.toggleGenerateStandardPOModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(GenerateStandardPo);
