import { annotationConstants } from '../Constants';
import { annotationServices } from '../Services';

const annotationActions = {
	fetchProducts,
	fetchContainers,
	fetchQMSAnnotations,
	fetchIMSAnnotations,
	fetchTempAnnotations,
};

function fetchProducts() {
	return async (dispatch) => {
		try {
			const response = await annotationServices.fetchProducts();
			const { data } = response;
			dispatch(success(data));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	function success(payload) {
		return {
			type: annotationConstants.FETCH_ANNOTATION_PRODUCTS_SUCCESS,
			payload,
		};
	}
}

function fetchContainers(req) {
	return async (dispatch) => {
		try {
			const response = await annotationServices.fetchContainers(req);
			const { data } = response;
			dispatch(success(data));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	function success(payload) {
		return {
			type: annotationConstants.FETCH_ANNOTATION_CONTAINERS_SUCCESS,
			payload,
		};
	}
}

function fetchQMSAnnotations(qcId, type) {
	return async (dispatch) => {
		try {
			const req = {
				params: {
					qcId,
					type,
				},
			};
			const response = await annotationServices.fetchQMSAnnotations(req);
			const { data } = response;
			dispatch(success(data));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	function success(payload) {
		return {
			type: annotationConstants.FETCH_QMS_ANNOTATION_SUCCESS,
			payload,
		};
	}
}
function fetchTempAnnotations(req) {
	return async (dispatch) => {
		try {
			const response = await annotationServices.fetchTempAnnotations(req);
			const { data } = response;
			dispatch(success(data));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	function success(payload) {
		return {
			type: annotationConstants.FETCH_TEMP_ANNOTATION_SUCCESS,
			payload,
		};
	}
}

function fetchIMSAnnotations(scanId) {
	return async (dispatch) => {
		try {
			const req = {
				params: {
					scanId,
				},
			};
			const response = await annotationServices.fetchIMSAnnotations(req);
			const { data } = response;
			dispatch(success(data));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	function success(payload) {
		return {
			type: annotationConstants.FETCH_IMS_ANNOTATION_SUCCESS,
			payload,
		};
	}
}

export default annotationActions;
