import { Clear, Check } from '@mui/icons-material';
import styles from './RefundHistoryTable.module.scss';

const RefundHistoryTable = (props) => {
	const { orders } = props;
	return (
		<table className={`${styles['list-table-root']}`}>
			<thead>
				<tr>
					<th>Order Id</th>
					<th>Store</th>
					<th>Refunded Amount(₹)</th>
					<th>Reason</th>
					<th>Approved</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{orders &&
					orders.map((order) => {
						return (
							<tr>
								<td>
									<label>{order.orderId}</label>
								</td>
								<td>
									<label>{order.store.storeId}</label>
								</td>
								<td>
									<label>{order.finalRefundAmount}</label>
								</td>
								<td
									style={{
										width: '150px',
										maxWidth: '100%',
									}}
								>
									<div
										style={{
											width: '150px',
											maxWidth: '100%',
										}}
									>
										{order.reason}
									</div>
								</td>
								<td>
									{order.approved ? (
										<Check
											className={`${styles['special-item']} ${styles.approve}`}
										/>
									) : (
										<Clear
											className={`${styles['special-item']} ${styles.reject}`}
										/>
									)}
								</td>
								<td
									style={{
										width: '150px',
										maxWidth: '100%',
									}}
								>
									<div
										style={{
											width: '150px',
											maxWidth: '100%',
										}}
									>
										{order.paytmRefundStatus}
									</div>
								</td>
							</tr>
						);
					})}
				{orders.length === 0 && (
					<td colSpan={7}>
						<div> No more items to display</div>
					</td>
				)}
			</tbody>
		</table>
	);
};
export default RefundHistoryTable;
