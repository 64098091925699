import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import AllServiceListPage from '../../../Pages/CapexService/CapexService';
import CreateCapexService from '../../../Pages/CapexService/CreateService/CreateCapexService';
import ViewProductPage from '../../../Pages/Food Products/All Products/ProductViewPage';
// eslint-disable-next-line object-curly-newline
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const AllCapexRoutes = (props) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.SERVICES}`,
			name: 'Services',
		},
	];

	return (
		<CRUDRoutes
			{...props}
			entity='SERVICE'
			path={path}
			listPage={AllServiceListPage}
			viewPage={<ViewProductPage breadcrumbs={breadcrumbs} />}
			editPage={<CreateCapexService isEdit breadcrumbs={breadcrumbs} />}
			createPage={<CreateCapexService breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default AllCapexRoutes;
