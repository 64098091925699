/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const forecastingAnalyticsServices = {
	gateway,
	fetchForecastingAnalysisGraph,
	exportInvAnalysisList,
};

function gateway({ req, method }) {
	switch (method) {
		case 'CHART_DATA':
			return fetchForecastingAnalysisGraph({
				req,
			});
		case 'LIST_DATA':
			return fetchForecastingAnalysisList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function fetchForecastingAnalysisGraph({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.FORECASTING_ANALYTICS, requestOptions).then(handleResponse);
}

function fetchForecastingAnalysisList({ req }) {
	const { params = {} } = req;
	// console.log('passed req', {
	// req,
	// });
	// const url = toQueryStringURL(endpoints.QUALITY_ANALYTICS_LIST, params);
	const url = toQueryStringURL(endpoints.FORECASTING_ANALYTICS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			// clientID: 52,
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockQAList(requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
	// return mockQAList(url, requestOptions);
}

// exportInvAnalysisList;
function exportInvAnalysisList(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.INV_ANALYTICS_EXPORT, requestOptions);
}

export default forecastingAnalyticsServices;
