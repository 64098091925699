import styles from './ServiceModes.module.scss';
import EnableDisable from '../../EnableDisable/EnableDisable';
import { generateRequiredProps } from '../../../../Helpers';
import { IconInput } from '../../Inputs';
import Pincodes from './Pincodes';
import Slabs from './Slabs/Slabs';

const ServiceModes = (props) => {
	const {
		fields,
		errors,
		handleInput,
		mandatoryFields,
		handleConfirm,
		handleDelete,
		isDisabled,
		addSlab,
		deleteSlab,
		handleSlabInput,
	} = props;

	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '100%',
				}}
			>
				{/* Subscription */}
				<div className='form-field row'>
					<EnableDisable
						name='subscription'
						text='Enable Subscription'
						isEnabled={fields.subscription}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'subscription',
						}}
					/>
					<span className='form-error'>{errors.subscription}</span>
				</div>
				{/* Meal scheduling */}
				<div className='form-field row'>
					<EnableDisable
						name='mealScheduling'
						text='Enable Meal scheduling'
						isEnabled={fields.mealScheduling}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'mealScheduling',
						}}
					/>
					<span className='form-error'>{errors.mealScheduling}</span>
				</div>
				{/* Take away */}
				<div className='form-field row'>
					<EnableDisable
						name='takeaway'
						text='Takeaway'
						isEnabled={fields.takeaway}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'takeaway',
						}}
					/>
					<span className='form-error'>{errors.takeaway}</span>
				</div>
				{/* Delivery */}
				<div className='form-field row'>
					<EnableDisable
						name='delivery'
						text='Delivery'
						fields={fields}
						isEnabled={fields.delivery}
						handleIsEnabled={handleInput}
						dataAttrs={{
							'data-type': 'delivery',
						}}
					>
						<EnableDisable
							name='tracking'
							text='Tracking'
							fields={fields}
							isEnabled={fields.tracking}
							handleIsEnabled={handleInput}
							cond='true'
							dataAttrs={{
								'data-type': 'tracking',
							}}
						/>
						{/* selfDelivery */}
						<div className='form-field row'>
							<EnableDisable
								name='selfDelivery'
								text='Self Delivery'
								isEnabled={fields.selfDelivery}
								handleIsEnabled={handleInput}
								dataAttrs={{
									'data-type': 'selfDelivery',
								}}
							/>
							<span className='form-error'>
								{errors.selfDelivery}
							</span>
						</div>
						{/* Servicability radius */}
						<div className='form-field row'>
							<label
								className='form-label'
								htmlFor='servicabilityRadius'
								data-mandatory={
									fields.delivery &&
									mandatoryFields.servicabilityRadius
								}
							>
								Delivery Radius
							</label>

							<IconInput
								size='large'
								type='custom'
								iconText='Km'
								name='servicabilityRadius'
								id='servicabilityRadius'
								handleInput={handleInput}
								dataType='servicabilityRadius'
								value={fields.servicabilityRadius}
								dataAttrs={{
									required:
										fields.delivery &&
										mandatoryFields.servicabilityRadius,
								}}
								step='0.01'
							/>
							<span className='form-error'>
								{errors.servicabilityRadius}
							</span>
						</div>

						{/* Service pincodes */}
						<Pincodes
							fields={fields}
							isDisabled={isDisabled}
							mandatoryFields
							errors
							handleConfirm={handleConfirm}
							handleDelete={handleDelete}
							generateRequiredProps={generateRequiredProps}
						/>
						<Slabs
							fields={fields}
							isDisabled={isDisabled}
							mandatoryFields={mandatoryFields}
							errors
							addSlab={addSlab}
							deleteSlab={deleteSlab}
							handleSlabInput={handleSlabInput}
						/>
					</EnableDisable>
					<span className='form-error'>{errors.delivery}</span>
				</div>
			</div>
		</div>
	);
};

ServiceModes.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	handleDelete: () => {},
	generateRequiredProps: () => {
		return {};
	},
};
export default ServiceModes;
