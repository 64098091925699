import { connect } from 'react-redux';
import { Modal } from '@mui/material';
import { modalActions } from '../../../Actions';

const ErrorModal = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { show, data, toggleErrorModal } = props;

	const handleClose = () => {
		toggleErrorModal(false, {});
	};

	return (
		<Modal open={show} onClose={handleClose}>
			<div className='flex justfy-center items-center w-[100px] h-100'>
				<div className='bg-white w-[]'>ERRORRRRR</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.modal?.errorModal;

	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleErrorModal: modalActions.toggleErrorModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
