import { useMemo } from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../Components/SubHeader/SubHeader';
import analyticsTypeConstant from '../../Constants/analyticsType.contants';
import { getDateFilterString } from '../../Helpers';
import BOMAnalysisListPage from './BOMAnalysisListPage';
import { analyticsAction } from '@/Actions';
import {
	Tabs, TabsList, TabsTrigger
} from '@/Components/ui/tabs';

const ANALYTICS_TYPE = analyticsTypeConstant.BOM_ANALYTICS_LIST_DATA;

const dataTypes = [
	{
		label: 'Product/Intermediate',
		value: 'BOM',
	},
	{
		label: 'Ingredient',
		value: 'BOM_INGREDIENT',
	},
];

const PastBOMAnalysisHistoryPage = (props) => {
	const {
		breadcrumbs,
		DATE_FILTER,
		data,
		analysisFilters,
		setAnalysisDashboardFilters,
	} = props;

	const selectedDateString = useMemo(() => {
		return getDateFilterString(DATE_FILTER);
	}, [DATE_FILTER]);

	const handleDataType = (value) => {
		setAnalysisDashboardFilters({
			...analysisFilters,
			analysisType: value,
		});
	};

	return (
		<div className='page'>
			<SubHeader
				breadcrumbs={breadcrumbs}
				showBreadcrumbs
				selectedDateString={selectedDateString}
			/>
			<div className='pt-4 px-2'>
				<Tabs
					value={analysisFilters?.analysisType}
					onValueChange={handleDataType}
					defaultValue={analysisFilters?.analysisType}
				>
					<TabsList className=''>
						{dataTypes?.map((ele) => {
							return (
								<TabsTrigger
									className='data-[state=active]:bg-uy-color'
									value={ele?.value}
								>
									{ele?.label}
								</TabsTrigger>
							);
						})}
					</TabsList>
				</Tabs>
			</div>
			<BOMAnalysisListPage data={data} ANALYTICS_TYPE={ANALYTICS_TYPE} />
		</div>
	);
};
const mapStateToProps = (state) => {
	const { DATE_FILTER, ANALYSIS_DASHBOARD_FILTERS } = state.analytics;
	const { LIST_DATA: data } = state.analytics.BOM_ANALYTICS;
	return {
		DATE_FILTER: DATE_FILTER.selection,
		data,
		analysisFilters: ANALYSIS_DASHBOARD_FILTERS,
	};
};
const mapDispatchToProps = {
	setAnalysisDashboardFilters: analyticsAction.setAnalysisDashboardFilters,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PastBOMAnalysisHistoryPage);
