/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions, productActions } from '../../../Actions';
import { GeneralDetails } from '../../../Components/Forms/Customizations';
import { CustomizationDetails } from '../../../Components/Forms/Customizations/CustomizationDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import customizationConfig from './customization.config';

const TABS = ['CUSTOMIZATION DETAILS'];

const CustomizationViewPage = (props) => {
	const { fetchProducts, fetchCustomization, data, breadcrumbs } = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(customizationConfig.initialState.fields),
	});

	const { id } = useRouteMatch().params;

	useEffect(() => {
		fetchProducts();
		fetchCustomization({
			entity: 'CUSTOMIZATIONS',
			req: {
				params: {
					productId: id,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data)) {
			setState(customizationConfig.serializer(state, data));
		}
	}, [data]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<fieldset disabled className='form-fieldset'>
						<GeneralDetails fields={fields} />
						<CustomizationDetails
							customizations={fields.customizations}
							mandatoryFields={
								customizationConfig.mandatoryFields
							}
							isDisabled
						/>
					</fieldset>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Customization'
				handleEdit={redirectToEdit}
				showDelete={false}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.product.productName}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.CUSTOMIZATIONS.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchCustomization: formActions.gateway,
	fetchProducts: productActions.fetchProducts,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizationViewPage);
