/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const myMachinesServices = {
	updateMachineService,
	fetchListOfMachines,
	addNewMachine,
};

function fetchListOfMachines({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.LIST_MACHINES}`, requestOptions).then(
		handleResponse
	);
}

function updateMachineService({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.UPDATE_MACHINES}`, requestOptions).then(
		handleResponse
	);
}
function addNewMachine(req) {
	const { params = {} } = req ?? {};
	const url = toQueryStringURL(endpoints.ADD_MACHINE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

export default myMachinesServices;
