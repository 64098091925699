/* eslint-disable */
// import { useState } from 'react';
import InputBox from '../LabelGeneratorInputBox/InputBox';
import Unit from '../LabelGeneratorUnit/Unit';
import Button from '../LabelGeneratorButton/Button';
// import { getCookie } from '../../../Services/Storage Services';
import LableGeneratorTable from '../LableGeneratorTable/LableGeneratorTable';
import labelGeneratorConfig from '../LabelGenerator.config';
import './RightBox.scss';

function RightBox(props) {
	// const user = JSON.parse(getCookie('user'));
	// const storeIP = user?.store?.printerIp?.split('.');
	// const [defaultValue, setDefaultValue] = useState(storeIP || [192, 168, 1, 9]);

	function handleChange(event) {
		props?.setLabelType(event?.target?.value);
	}

	const handleChangeScreen = (text) => {
		props?.staticTextEnter(text);
	};
	let content = (
		<>
			<div className='label-properties-content'>
				<h2 id='label-properties-header'>Label Properties</h2>
				<div className='label-properties'>
					<label>Label Size(Width x Height):</label>
					<div className='label-size'>
						<InputBox
							id='size-input-box-width'
							class='label-size-input-box'
							type='number'
							info='Enter width'
							labelDetails={props?.labelDetails}
							setLabelDetails={props?.setLabelDetails}
							value={props?.labelDetails?.size?.width}
							labelComponentsState={props?.labelComponentsState}
							currentLabelComponents={props?.currentLabelComponents}
						/>
						<Unit />
						<InputBox
							id='size-input-box-height'
							type='number'
							class='label-size-input-box'
							info='Enter height'
							labelDetails={props?.labelDetails}
							setLabelDetails={props?.setLabelDetails}
							value={props?.labelDetails?.size?.height}
							labelComponentsState={props?.labelComponentsState}
							currentLabelComponents={props?.currentLabelComponents}
						/>
						<Unit />
					</div>
				</div>
				<div className='label-properties'>
					<label>Label Name1:</label>
					<div>
						<InputBox
							id='label-name-input-box'
							type='text'
							class='label-input-box'
							info='Enter Label Name'
							labelDetails={props?.labelDetails}
							setLabelDetails={props?.setLabelDetails}
							value={props?.labelDetails?.name}
							labelComponentsState={props?.labelComponentsState}
							currentLabelComponents={props?.currentLabelComponents}
						/>
					</div>
				</div>
				<div className='label-properties'>
					<label>Print Copies:</label>
					<div>
						<InputBox
							id='label-count-input-box'
							type='text'
							class='label-input-box'
							info='Enter Print Copies'
							labelDetails={props?.labelDetails}
							setLabelDetails={props?.setLabelDetails}
							value={props?.labelDetails?.printCount}
							labelComponentsState={props?.labelComponentsState}
							currentLabelComponents={props?.currentLabelComponents}
						/>
					</div>
				</div>
			</div>

			<div className='label-buttons'>
				<Button
					type='save-label'
					text='Save Label'
					handleClick={props?.saveLabel}
					variant='primary'
				/>
				<Button
					type='preview-label'
					text='Preview Label'
					// printLabel={props?.printLabel}
					handleClick={props?.previewLabel}
					variant='secondary'
				/>
			</div>
		</>
	);

	const handleBackButton = (id) => {
		props.handleBackButtonApp(id);
	};

	const handleBackButtonSavedLabelsScreen = (id) => {
		props.handleBackButtonSavedLabelsScreen(id);
	};

	const handleDeleteComponent = (id) => {
		if (props?.editTool !== null && props?.selectedComponent) {
			props?.setEditToolInUse(null);
			props?.setLabelComponentsState(
				props?.labelComponentsState.filter((component) => {
					return component.id !== props?.selectedComponent;
				})
			);
			props?.setSelectedComponent(null);
			props?.handleSaveButtonApp(id);
		}
	};

	const handleSaveButton = (id) => {
		props?.setEditToolInUse(null);
		props?.setSelectedComponent(null);
		props?.handleSaveButtonApp(id);
	};

	function saveDeleteButton() {
		return (
			<div
				className='tools-back-save'
				style={{
					marginTop: '105%',
				}}
			>
				<Button
					text='Delete'
					type='delete-saved-component'
					handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
					variant='delete'
				/>
				<Button
					text='Save'
					type='save-component'
					handleClick={(e) => { return handleSaveButton(e?.targetId); }}
					variant='primary'
				/>
			</div>
		);
	}

	if (!props.rightScreenContent) {
		if (props.savedLabelsClicked) {
			content = (
				<>
					<div className='label-properties-content' />
					<Button
						text='Back'
						type='back-button-saved-labels-screeen'
						handleClick={
							(e) => { return handleBackButtonSavedLabelsScreen(e?.targetId); }
						}
						variant='secondary'
					/>
				</>
			);
		} else if (props?.previewLabelClicked) {
			let newArray = [];
			let obj = {};
			props?.labelComponentsState.forEach((component) => {
				 obj = {
					id: component?.id,
					name: component?.text?.placeHolderName,
				};
				if (component?.type === 'text') {
					newArray = [...newArray, obj];
				}
				// return (component.type === 'text') && component?.text?.placeHolderName;
			});
			newArray = [...new Set(newArray)];
			content = (
				<>
					<div className='label-properties-content'>
						{newArray.map(({ name, id }) => {
							// const datesColumn = ['Manufacturing Date', 'Expiry Date'];
							let inputType = 'text';
							const normalDatesArr = ['Inward Date', 'Expiry Date', 'Manufacturing Date', 'Scheduled At'];
							if (name === 'Manufacturing Timestamp') {
								inputType = 'datetime-local';
							} else if (normalDatesArr?.includes(name)) {
								inputType = 'date';
							}
							// const inputType = datesColumn.includes(name) ? 'datetime-local' : 'text';
							return (
								<div className='label-properties'>
									<label>
										{name}
										:
									</label>
									<InputBox
										id={id}
										type={inputType}
										class='parameter-input-box'
										// eslint-disable-next-line prefer-template
										info={'Enter ' + name}
										// handleText={props?.handleText}
										placeholderArray={newArray}
										currentParameters={props?.currentParameters}
										setCurrentParameters={props?.setCurrentParameters}
										labelComponentsState={props?.labelComponentsState}
									/>
								</div>
							);
						})}
						{/* <div className='label-properties'>
							<label>Printer IP Address:</label>
							<div
								className='ip'
								style={{
									fontWeight: '900',
								}}
							>
								<InputBox
									id='printer-ip-address1'
									type='number'
									class='printer-ip-address'
									// info='192'
									printerIP={props?.printerIP}
									setPrinterIP={props?.setPrinterIP}
									min='0'
									max='255'
									value={defaultValue[0]}
									setDefaultValue={setDefaultValue}
								/>
								.
								<InputBox
									id='printer-ip-address2'
									type='number'
									class='printer-ip-address'
									// info='168'
									printerIP={props?.printerIP}
									setPrinterIP={props?.setPrinterIP}
									min='0'
									max='255'
									value={defaultValue[1]}
									setDefaultValue={setDefaultValue}
								/>
								.
								<InputBox
									id='printer-ip-address3'
									type='number'
									class='printer-ip-address'
									// info='1'
									printerIP={props?.printerIP}
									setPrinterIP={props?.setPrinterIP}
									min='0'
									max='255'
									value={defaultValue[2]}
									setDefaultValue={setDefaultValue}
								/>
								.
								<InputBox
									id='printer-ip-address4'
									type='number'
									class='printer-ip-address'
									// info='9'
									printerIP={props?.printerIP}
									setPrinterIP={props?.setPrinterIP}
									min='0'
									max='255'
									value={defaultValue[3]}
									setDefaultValue={setDefaultValue}
								/>
							</div>
						</div> */}
					</div>
					<div
						className='label-buttons'
						style={{
							display: 'flex',
							gap: '1rem',
						}}
					>
						<Button
							text='Back'
							type='back-button'
							handleClick={(e) => { return handleBackButton(e?.targetId); }}
							variant='secondary'
						/>
						<Button
							text='Refresh'
							type='refresh-label'
							handleClick={props?.refreshParameters}
							variant='secondary'
							// handleBackButton={handleBackButton}
						/>
						{/* <Button
							text='Print Label'
							type='print-label'
							handleClick={props?.printLabel}
							variant='primary'
							// handleBackButton={handleBackButton}
						/> */}
					</div>
				</>
			);
		} else {
			content = (
				<>
					<div className='label-properties-content' />
					<Button
						text='Back'
						type='back-button-saved-component'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='secondary'
					/>
				</>
			);
		}
	} else if (props.tool === 'Text' || props.editTool === 'Text') {
		let buttonContent;
		if (props.editTool === 'Text') {
			buttonContent = (
				<div className='tools-back-save'>
					<Button
						text='Delete'
						type='delete-saved-component'
						handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
						variant='delete'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		} else {
			buttonContent = (
				<div className='tools-back-save'>
					<Button
						text='Back'
						type='back-component'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='secondary'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		}
		content = (
			<>
				<div className='label-properties-content'>

					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<div className='label-properties'>
							<label>Placeholder Name:</label>
							<div>
								<select
									name={props?.selectedToolComponent?.Text?.placeholder}
									id='placeholder-name-select'
									onChange={(Event) => {
										props.handlePlaceHolderChange(Event.target.value);
									}}
								>
									{props?.placeholderArray.map((name) => {
										if (props?.selectedToolComponent?.Text?.placeholder === name) {
											return (
												<option selected value={name}>
													{name}
												</option>
											);
										}
										return (
											<option value={name}>

												{name}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<label>Textbox Width:</label>
						<div className='text-size'>

							<InputBox
								id='text-size-input-box-width'
								type='number'
								class='text-size-input-box'
								info='Enter width'
								handleText={props?.handleText}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.Text?.textBoxDim?.width?.substring(
											0,
											props?.selectedToolComponent?.Text?.textBoxDim?.width.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
								textBoxDim={props?.selectedToolComponent?.Text?.textBoxDim}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit className='text-unit' />
						</div>
					</div>
					<div className='label-properties'>
						<label>Number of lines:</label>
						<div>
							<InputBox
								id='text-lines-input-box'
								type='number'
								class='text-lines-input-box'
								info='Enter number of lines'
								handleInputLines={props?.handleInputLines}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.selectedToolComponent?.Text?.inputLines}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
						</div>
					</div>
					{/* <div className='label-properties'>
						<label>
							{props?.selectedToolComponent?.Text?.placeholder}
							:
						</label>
						<div>
							<InputBox
								id='placeholder-text-input-box'
								type='text'
								class='placeholder-text-input-box'
								info='Enter text'
								handlePlaceHolderTextChange={props?.handlePlaceHolderTextChange}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.selectedToolComponent?.Text?.placeholderText}
							/>
						</div>
					</div> */}
					<div className='label-properties'>
						<label>Font Size:</label>
						<div>
							<InputBox
								id='text-font-size-input-box'
								type='number'
								class='text-font-size-input-box'
								info='Enter height'
								handleTextSize={props?.handleTextSize}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.selectedToolComponent?.Text?.textFontSize}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
						</div>
					</div>
					<div className='label-properties highlight'>
						<label>Highlight:</label>
						<InputBox
							id='text-highlight-input-box'
							type='checkbox'
							class='text-highlight-input-box'
							handleHighlight={props?.handleHighlight}
							labelDetails={props?.labelDetails}
							setLabelDetails={props?.setLabelDetails}
							value={props?.selectedToolComponent?.Text?.highlight}
							labelComponentsState={props?.labelComponentsState}
							currentLabelComponents={props?.currentLabelComponents}
						/>
					</div>
				</div>
				{buttonContent}
			</>
		);
	} else if (props.tool === 'Static Text' || props.editTool === 'Static Text') {
		let buttonContent;
		if (!props.staticTextEntered) {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '60%',
					}}
				>
					<Button
						text='Back'
						type='back-component-static-text'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		} else if (props.editTool === 'Static Text') {
			buttonContent = (
				<div id='static-tools-back-save' className='tools-back-save'>
					<Button
						text='Delete'
						type='delete-saved-component'
						handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
						variant='delete'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		} else {
			buttonContent = (
				<div id='static-tools-back-save' className='tools-back-save'>
					<Button
						text='Back'
						type='back-component'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='secondary'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		}
		content = (
			<>
				<div className='label-properties-content'>
					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<label>Enter Text:</label>
						<div>
							<InputBox
								id='static-text-input-box'
								type='text'
								class='text-input-box'
								info='Enter text'
								handleChangeScreen={handleChangeScreen}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.selectedToolComponent?.['Static Text']?.staticText !== 'Static Text' ? props?.selectedToolComponent?.['Static Text']?.staticText : ''}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>

						</div>
						<label>Text Size(Width x Height):</label>
						<div className='static-text-size'>

							<InputBox
								id='static-text-size-input-box-width'
								type='number'
								class='static-text-size-input-box'
								info='Enter width'
								handleStaticText={props?.handleStaticText}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.['Static Text']?.staticTextBoxDim?.width?.substring(
											0,
											props?.selectedToolComponent?.['Static Text']?.staticTextBoxDim?.width.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
							<InputBox
								id='static-text-size-input-box-height'
								type='number'
								class='static-text-size-input-box'
								info='Enter height'
								handleStaticText={props?.handleStaticText}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.['Static Text']?.staticTextBoxDim?.height?.substring(
											0,
											props?.selectedToolComponent?.['Static Text']?.staticTextBoxDim?.height.length - 3
										)
									) *
									12.25) /
									(96 / 25.4)
								)}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
						</div>
					</div>
					<div className='label-properties'>
						<label>Font Size:</label>
						<div>
							<InputBox
								id='static-text-font-size-input-box'
								type='number'
								class='static-text-font-size-input-box'
								info='Enter height'
								handleStaticTextSize={props?.handleStaticTextSize}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.selectedToolComponent?.['Static Text']?.staticTextFontSize}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
						</div>
					</div>
					<div className='label-properties highlight'>
						<label>Highlight:</label>
						<InputBox
							id='static-text-highlight-input-box'
							type='checkbox'
							class='static-text-highlight-input-box'
							handleHighlight={props?.handleHighlight}
							labelDetails={props?.labelDetails}
							setLabelDetails={props?.setLabelDetails}
							value={props?.selectedToolComponent?.['Static Text']?.highlight}
							labelComponentsState={props?.labelComponentsState}
							currentLabelComponents={props?.currentLabelComponents}
						/>
					</div>
				</div>
				{buttonContent}
			</>
		);
	} else if (props.tool === 'qrCode' || props.editTool === 'qrCode') {
		let buttonContent;
		if (props.editTool === 'qrCode') {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '105%',
					}}
				>
					<Button
						text='Delete'
						type='delete-saved-component'
						handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
						variant='delete'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		} else {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '105%',
					}}
				>
					<Button
						text='Back'
						type='back-component'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='secondary'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		}
		content = (
			<>
				<div className='label-properties-content'>

					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<label>
							QR Code Size:(size should be in between 6 and
							{` ${Math.min(props?.labelDetails?.size?.width, props?.labelDetails?.size?.height, 30)}`}
							)
							{' '}
						</label>
						<div className='qrcode-size'>
							<InputBox
								id='qrcode-size-input-box-width'
								class='qrcode-size-input-box'
								type='number'
								info='Enter width'
								handleQRCode={props?.handleQRCode}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.qrCode?.qrCodeDim?.width?.substring(
											0,
											props?.selectedToolComponent?.qrCode?.qrCodeDim?.width.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
						</div>
					</div>
				</div>

				{buttonContent}
			</>
		);
	} else if (props.tool === 'barCode' || props.editTool === 'barCode') {
		let buttonContent;
		if (props.editTool === 'barCode') {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '105%',
					}}
				>
					<Button
						text='Delete'
						type='delete-saved-component'
						handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
						variant='delete'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		} else {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '105%',
					}}
				>
					<Button
						text='Back'
						type='back-component'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='secondary'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		}
		content = (
			<>
				<div className='label-properties-content'>

					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<label>Barcode Size(Width x Height):</label>
						<div className='barcode-size'>
							<InputBox
								id='barcode-size-input-box-width'
								class='barcode-size-input-box'
								type='number'
								info='Enter width'
								handleBarcode={props?.handleBarcode}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.barCode?.barCodeDim?.width?.substring(
											0,
											props?.selectedToolComponent?.barCode?.barCodeDim?.width.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
							<InputBox
								id='barcode-size-input-box-height'
								class='barcode-size-input-box'
								type='number'
								info='Enter height'
								handleBarcode={props?.handleBarcode}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.barCode?.barCodeDim?.height?.substring(
											0,
											props?.selectedToolComponent?.barCode?.barCodeDim?.height.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
						</div>
					</div>
				</div>

				{buttonContent}
			</>
		);
	} else if (props.tool === 'Insert' || props.editTool === 'Insert') {
		let buttonContent;
		if (props.editTool === 'Insert') {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '105%',
					}}
				>
					<Button
						text='Delete'
						type='delete-saved-component'
						handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
						variant='delete'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		} else if (props.tool === 'table' || props.editTool === 'table') {
			if (props.editTool === 'table') {
				saveDeleteButton();
			}
		} else {
			buttonContent = (
				<div
					className='tools-back-save'
					style={{
						marginTop: '105%',
					}}
				>
					<Button
						text='Back'
						type='back-component'
						handleClick={(e) => { return handleBackButton(e?.targetId); }}
						variant='secondary'
					/>
					<Button
						text='Save'
						type='save-component'
						handleClick={(e) => { return handleSaveButton(e?.targetId); }}
						variant='primary'
					/>
				</div>
			);
		}
		content = (
			<>
				<div className='label-properties-content'>

					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<label>Image Size(Width x Height):</label>
						<div className='image-size'>
							<InputBox
								type='number'
								id='image-size-input-box-width'
								class='image-size-input-box'
								info='Enter width'
								handleImage={props?.handleImage}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.image?.imageDim?.width?.substring(
											0,
											props?.selectedToolComponent?.image?.imageDim?.width.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
							/>
							<Unit />
							<InputBox
								id='image-size-input-box-height'
								type='number'
								class='image-size-input-box'
								info='Enter height'
								handleImage={props?.handleImage}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.image?.imageDim?.height?.substring(
											0,
											props?.selectedToolComponent?.image?.imageDim?.height.length - 3
										)
									) *
                  12.25) /
                  (96 / 25.4)
								)}
							/>
							<Unit />
						</div>
					</div>
				</div>

				{buttonContent}
			</>
		);
	} else if (props.tool === 'table' || props.editTool === 'table') {
		const buttonContent = (
			<div
				className='tools-back-save'
			>
				<Button
					text='Delete'
					type='delete-saved-component'
					handleClick={(e) => { return handleDeleteComponent(e?.targetId); }}
					variant='delete'
				/>
				<Button
					text='Save'
					type='save-component'
					handleClick={(e) => { return handleSaveButton(e?.targetId); }}
					variant='primary'
				/>
			</div>
		);
		content = (
			<>
				<div className='label-properties-content'>
					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<label>Table Width:</label>
						<div className='table-size'>
							<InputBox
								id='table-size-input-box-width'
								type='number'
								class='table-size-input-box'
								info='Enter width'
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Math.round(
									(Number(
										props?.selectedToolComponent?.table?.width?.substring(
											0,
											props?.selectedToolComponent?.table?.width?.length - 3
										)
									) *
									12.25) /
									(96 / 25.4)
								)}
								handleTableDim={props?.handleTableDim}
							/>
							<Unit />
						</div>
					</div>
					<div className='label-properties'>
						<label>Font Size:</label>
						<div className='table-font-size'>
							<InputBox
								id='table-font-size-input-box'
								type='number'
								class='table-font-size-input-box'
								info='Enter font size'
								handleText={props?.handleText}
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={Number(
									props?.selectedToolComponent?.table?.tableFontSize
								)}
								handleTableFontSize={props?.handleTableFontSize}
							/>
						</div>
					</div>
					<div className='label-properties'>
						<label>Column ratios(Must add to 1):</label>
						<div className='table-column-ratio'>
							<InputBox
								id='table-column-ratio-input-box'
								type='text'
								class='table-column-ratio-input-box'
								info='Enter ratio'
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								handleTableColumnRatio={props?.handleTableColumnRatio}
							/>
						</div>
					</div>
					<LableGeneratorTable
						tableContent={props?.tableContent}
						setTableContent={props?.setTableContent}
						rows={props?.selectedToolComponent?.table?.rows}
						columns={props?.selectedToolComponent?.table?.columns}
					/>
				</div>
				{buttonContent}
			</>
		);
	} else {
		content = (
			<>
				<div className='label-properties-content'>

					<h2 id='label-properties-header'>Label Properties</h2>
					<div className='label-properties'>
						<label>Label Size(Width x Height):</label>
						<div className='label-size'>
							<InputBox
								id='size-input-box-width'
								class='label-size-input-box'
								type='number'
								info='Enter width'
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.labelDetails?.size?.width}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
							<InputBox
								id='size-input-box-height'
								type='number'
								class='label-size-input-box'
								info='Enter height'
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.labelDetails?.size?.height}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
							/>
							<Unit />
						</div>
					</div>
					<div className='label-properties'>
						<label>Label Name:</label>
						<div>
							<InputBox
								id='label-name-input-box'
								type='text'
								class='label-input-box'
								info='Enter Label Name'
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.labelDetails?.name}
							/>
						</div>
					</div>
					<div className='label-properties'>
						<label>
							Label Type:
						</label>
						<div>
							<select
								value={props?.labelType}
								onChange={handleChange}
								class='label-input-box'
							>
								<option value='MACHINE'>{labelGeneratorConfig?.namingOfMachineType?.MACHINE}</option>
								<option value='APP'>{labelGeneratorConfig?.namingOfMachineType?.APP}</option>
								<option value='ASSET'>{labelGeneratorConfig?.namingOfMachineType?.ASSET}</option>
							</select>
						</div>
					</div>
					<div className='label-properties'>
						<label>Print Copies:</label>
						<div>
							<InputBox
								id='label-count-input-box'
								type='number'
								class='label-input-box'
								info='Enter Print Copies'
								min='1'
								max='5'
								labelDetails={props?.labelDetails}
								setLabelDetails={props?.setLabelDetails}
								value={props?.labelDetails?.printCount}
								labelComponentsState={props?.labelComponentsState}
								currentLabelComponents={props?.currentLabelComponents}
						/>
						</div>
					</div>
				</div>

				<div className='label-buttons'>
					<Button
						type='save-label'
						text='Save Label'
						handleClick={props?.saveLabel}
						variant='primary'
					/>
					<Button
						type='preview-label'
						text='Preview Label'
						handleClick={props?.previewLabel}
						variant='secondary'
					/>
				</div>
			</>
		);
	}

	return <div id='right-box'>{content}</div>;
}
export default RightBox;
