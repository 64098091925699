import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../Pages';
import CreateUserPage from '../../../Pages/Accounts/MyUsers/CreateUserPage/CreateUserPage';
import MyUsersPage from '../../../Pages/Accounts/MyUsers/MyUsersPage';

const MyUsersRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MyUsersPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage createPage={<CreateUserPage />} entity='BRANDING' />
			</Route>
		</Switch>
	);
};

export default MyUsersRoutes;
