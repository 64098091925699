import { classes } from '../../../../Helpers';
import AmountContainer from '../../../Forms/Payment Settlements/Amount Container/AmountContainer';
import { StatisticalTable } from '../StatisticalTable';
import styles from './StatisticalJumbotron.module.scss';
import { STAT_COLORS } from '../../../../Styles/colors';

const StatisticalJumbotron = (props) => {
	const { boxData, tableData, toggleShowItem } = props;

	return (
		<div className={classes(styles.root)}>
			<div className={classes(styles.left)}>
				<div className={classes(styles.stats)}>
					<AmountContainer
						colorObj={{
							color: STAT_COLORS[2],
						}}
						amountCaption={boxData?.[1] ?? {}}
					/>
				</div>
				<p className={classes(styles.label)}>Pass%</p>
			</div>
			<div className={classes(styles.right)}>
				<StatisticalTable data={tableData} toggleShowItem={toggleShowItem} />
			</div>
		</div>
	);
};

StatisticalJumbotron.defaultProps = {
	tableData: [],
};

export default StatisticalJumbotron;
