/* eslint-disable */
import { useEffect, useMemo, useState, useRef, memo } from 'react';
import { connect } from 'react-redux';
import assetActions from '@/Actions/asset.actions';
import AssetSelector from '@/Components/AssetSelector/AssetSelector';
import { tempdata2 } from './tempData';

const useContainerDimensions = (navbarHeight = 55, sidebarWidth = 256) => {
	const containerRef = useRef(null);
	const [dimensions, setDimensions] = useState({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		const calculateAvailableSpace = () => {
			if (!containerRef.current) return;

			// Calculate available dimensions based on screen size
			const isSmallScreen = window.innerWidth < 1200; // MUI's sm breakpoint
			const effectiveSidebarWidth = isSmallScreen ? 0 : sidebarWidth;

			const availableWidth = window.innerWidth - effectiveSidebarWidth;
			const availableHeight = window.innerHeight - navbarHeight;

			setDimensions({
				width: availableWidth,
				height: availableHeight,
			});
		};

		// Initial calculation
		calculateAvailableSpace();

		// Recalculate on window resize
		window.addEventListener('resize', calculateAvailableSpace);

		// Create ResizeObserver to handle container size changes
		const resizeObserver = new ResizeObserver(calculateAvailableSpace);
		if (containerRef.current) {
			resizeObserver.observe(containerRef.current);
		}

		return () => {
			window.removeEventListener('resize', calculateAvailableSpace);
			resizeObserver.disconnect();
		};
	}, [navbarHeight, sidebarWidth]);

	return [containerRef, dimensions];
};

const processAssetList = (assetList, assetTypeIdMap) => {
	const items = [];
	assetList.forEach((asset) => {
		const newObj = {
			id: asset.id,
			assetTypeId: asset.assetTypeId,
			assetTypeName: asset.name,
			qrCode: asset.qrCode,
			category: asset.grnData.jsonData.assetCategory,
			coreSpecs: asset.grnData.jsonData.coreSpecifications,
			oem: asset.grnData.jsonData.oem,
			price: asset.grnData.jsonData.productCost,
			tax: asset.grnData.jsonData.productTaxValue,
			discount: asset.grnData.jsonData.discountPercentage,
			poItemId: asset.grnData.jsonData.itemId,
			grnId: asset.grnData.jsonData.id,
			poId: asset.grnData.poId,
			imageUrl: asset.imageUrl,
			assetTypeImageUrl: assetTypeIdMap[asset.assetTypeId].image,
		};
		items.push(newObj);
	});
	return items;
};

function AssetListing(props) {
	const {
		stores,
		selectedStoreAssets,
		fetchStoreAssetListing,
		assetList,
		onAssetGroupSelect,
		fetchAssetList,
		avoidTrackingChildAssets=true,
		setParentGroupedAssets,
		heightSubtraction,
		selectedStoreId,
		height,
		resetStoreAssetListing,
	} = props;

	const [containerRef, dimensions] =
		useContainerDimensions(heightSubtraction);

	useEffect(() => {
		if (selectedStoreId) {
			fetchStoreAssetListing({
				req: {
					data: {
						storeId: selectedStoreId,
						coreSpecs: true,
						movable: 'all',
					},
				},
			});
			fetchAssetList({
				req: {},
			});
		} else {
			resetStoreAssetListing();
		}
	}, [selectedStoreId]);
	const assetTypeIdMap = useMemo(() => {
		return assetList.reduce((acc, asset) => {
			acc[asset.id] = asset;
			return acc;
		}, {});
	}, [assetList]);

	const storeAssetQrDataList = useMemo(() => {
		if (
			selectedStoreAssets.length > 0 &&
			Object.keys(assetTypeIdMap).length > 0
		) {
			return processAssetList(selectedStoreAssets, assetTypeIdMap);
		}
		return [];
	}, [selectedStoreAssets, assetTypeIdMap]);

	return (
		<>
			{storeAssetQrDataList.length > 0 && assetList.length > 0 ? (
				<div
					ref={containerRef}
					style={{
						width: '100%',
						height: height || dimensions.height || '100%',
						maxWidth: '100%',
						maxHeight: '100%',
						overflow: 'hidden',
					}}
					className='p-2'
				>
					<AssetSelector
						height={height || dimensions.height}
						assetTypeList={assetList}
						onAssetGroupSelect={onAssetGroupSelect}
						avoidTrackingChildAssets={avoidTrackingChildAssets}
						storeAssetQrDataList={storeAssetQrDataList}
						setParentGroupedAssets={setParentGroupedAssets}
						// storeAssetQrDataList={tempdata2}
					/>
				</div>
			) : (
				<div className='flex w-full justify-center items-start h-40'>
					<p className='text-red-800 px-4 py-2 border-2 text-center border-red-700'>
						No assets found for this store. Please select correct
						store
					</p>
				</div>
			)}
		</>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;
	const { assets: selectedStoreAssets } = state.assets.storeAssetList;
	const assetList = state.assets.list;

	return {
		stores,
		selectedStoreAssets,
		assetList,
	};
};

const mapDispatchToProps = {
	fetchStoreAssetListing: assetActions.fetchStoreAssetListing,
	resetStoreAssetListing: assetActions.resetStoreAssetListing,
	fetchAssetList: assetActions.fetchAssetList,
};
export default memo(connect(mapStateToProps, mapDispatchToProps)(AssetListing));
