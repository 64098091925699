/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-template */
import { connect } from 'react-redux';
import {
	useEffect, useMemo, useReducer
} from 'react';
import { cloneDeep } from 'lodash';
import {
	Button, MenuItem, Select
} from '@mui/material';
import drawerActions from '../../../Actions/drawer.actions';
import BaseDrawer from '../BaseDrawer/BaseDrawer';
import styles from './FilterDrawer.module.scss';
import {
	analyticsAction, formActions, modalActions, productActions
} from '@/Actions';
import filterDrawerConfig from './FilterDrawer.config';
import {
	classes, inputHelper, skuTypesListWithId, useSearch
} from '@/Helpers';
import { notificationServices } from '@/Services';
import { Failure } from './Screens';
import { CheckListImage } from '@/Components/Analytics/CheckListImage';
import { CheckList } from '@/Components/CheckList';
import { CheckTile } from '@/Components/CheckTile';
// import { Button as CnButton } from '@/Components/ui/button';

const reducer = (state, action) => {
	switch (action.type) {
		case 'INIT':
			return {
				...state,
				...action.payload,
			};
		case 'NEXT_LEVEL':
			return {
				...state,
				levels: [...state.levels, action.payload],
			};
		case 'PREV_LEVEL': {
			const levels = [...state.levels];
			levels.pop();
			return {
				...state,
				levels,
			};
		}
		case 'SET_GEO_FILTER':
			return {
				...state,
				GEO_FILTER: {
					...action.payload,
				},
			};
		case 'SET_DATE_FILTER':
			return {
				...state,
				...action.payload,
			};
		case 'SET_BRAND_FILTER':
			return {
				...state,
				...action.payload,
			};
		case 'SET_STORE_FILTER':
			return {
				...state,
				STORE_FILTER: action.payload,
			};
		case 'SET_PROCESS_TYPE_FILTER':
			return {
				...state,
				PROCESS_TYPE_FILTER: action.payload,
			};
		case 'SET_SKU_TYPE_FILTER':
			return {
				...state,
				SKU_TYPE_FILTER: action.payload,
			};
		case 'SET_FROM_STORE_FILTER':
			return {
				...state,
				FROM_STORE_FILTER: action.payload,
			};
		case 'SET_STATISTICAL_FILTER':
			return {
				...state,
				...action.payload,
			};
		case 'SET_FAILURE_ANALYSIS_FILTER':
			return {
				...state,
				FAILURE_ANALYSIS_FILTER: action.payload,
			};
		case 'SET_FAILURE_WEIGHT':
			return {
				...state,
				FAILURE_ANALYSIS_FILTER: {
					...state.FAILURE_ANALYSIS_FILTER,
					WEIGHT: action.payload,
				},
			};
		case 'SET_FAILURE_TEMPERATURE':
			return {
				...state,
				FAILURE_ANALYSIS_FILTER: {
					...state.FAILURE_ANALYSIS_FILTER,
					TEMPERATURE: action.payload,
				},
			};
		case 'SET_DEFECTS':
			return {
				...state,
				FAILURE_ANALYSIS_FILTER: {
					...state.FAILURE_ANALYSIS_FILTER,
					DEFECT: {
						applied: action.payload.length > 0,
						defects: action.payload,
					},
				},
			};
		case 'SELECT_STATISTICAL_BRAND':
			return {
				...state,
				STATISTICAL_FILTER: {
					...state.STATISTICAL_FILTER,
					selectedBrands: action.payload,
					selectedProductIds: [],
				},
			};
		case 'SET_UNDETECTED_PRODUCTS_FILTER':
			return {
				...state,
				UNDETECTED_PRODUCTS_FILTER: action.payload,
			};
		case 'SET_MEAL_TYPE_FILTER':
			return {
				...state,
				MEAL_TYPE_FILTER: {
					list: [...state.MEAL_TYPE_FILTER.list],
					...action.payload,
				},
			};
		case 'SET_MODE_TYPE_FILTER':
			return {
				...state,
				MODE_TYPE_FILTER: {
					selectedList: [...state.MODE_TYPE_FILTER.list],
					...action.payload,
				},
			};
		case 'SET_LIST_DATA_FILTER':
			return {
				...state,
				LIST_DATA_FILTER: action.payload,
			};
		case 'SET_VALIDATED_DATA_FILTER':
			return {
				...state,
				VALIDATED_DATA_FILTER: action.payload,
			};
		case 'SET_DUPLICATE_DATA_FILTER':
			return {
				...state,
				DUPLICATE_DATA_FILTER: action.payload,
			};
		case 'SET_IDENTIFIED_DATA_FILTER':
			return {
				...state,
				IDENTIFIED_DATA_FILTER: action.payload,
			};
		case 'SET_FEATURES_DATA_FILTER':
			return {
				...state,
				FEATURES_DATA_FILTER: action.payload,
			};
		case 'SET_PRODUCT_COUNT_FILTER':
			return {
				...state,
				PRODUCT_COUNT_FILTER: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};
const checkIfApplied = (state, stateToBeChecked) => {
	switch (stateToBeChecked) {
		case 'GEO_FILTER':
			return state?.GEO_FILTER?.state?.length;
		case 'BRAND_FILTER':
			return state?.BRAND_FILTER?.selectedList?.length;
		case 'STORE_FILTER':
			return state?.STORE_FILTER?.selectedStores?.length;
		case 'PROCESS_TYPE_FILTER':
			return state?.PROCESS_TYPE_FILTER?.selectedProcessTypes?.length;
		case 'SKU_TYPE_FILTER':
			return state?.SKU_TYPE_FILTER?.selectedSkuTypes?.length;
		case 'FROM_STORE_FILTER':
			return state?.FROM_STORE_FILTER?.selectedStores?.length;
		case 'FAILURE_ANALYSIS_FILTER':
			return (
				state?.FAILURE_ANALYSIS_FILTER.WEIGHT['over-weight'] ||
				state?.FAILURE_ANALYSIS_FILTER.WEIGHT['under-weight'] ||
				state?.FAILURE_ANALYSIS_FILTER.TEMPERATURE[
					'over-temperature'
				] ||
				state?.FAILURE_ANALYSIS_FILTER.TEMPERATURE[
					'under-temperature'
				] ||
				state?.FAILURE_ANALYSIS_FILTER.DEFECT.applied
			);
		case 'STATISTICAL_FILTER':
			return state?.STATISTICAL_FILTER.selected;
		case 'UNDETECTED_PRODUCTS_FILTER':
			return state?.UNDETECTED_PRODUCTS_FILTER?.count > 0;
		case 'MEAL_TYPE_FILTER':
			return state?.MEAL_TYPE_FILTER?.applied;
		case 'MODE_TYPE_FILTER':
			return state?.MEAL_TYPE_FILTER?.applied;
		case 'VALIDATED_DATA_FILTER':
			return state?.VALIDATED_DATA_FILTER?.applied;
		case 'DUPLICATE_DATA_FILTER':
			return state?.DUPLICATE_DATA_FILTER?.applied;
		case 'IDENTIFIED_DATA_FILTER':
			return state?.IDENTIFIED_DATA_FILTER?.applied;
		case 'FEATURES_DATA_FILTER':
			return state?.FEATURES_DATA_FILTER?.applied;
		case 'PRODUCT_COUNT_FILTER':
			return state?.PRODUCT_COUNT_FILTER?.count > 0;
		default:
			return false;
	}
};

const listDataOptions = [
	{
		id: 1,
		title: 'Yes',
	},
	{
		id: 2,
		title: 'No',
	},
	{
		id: 3,
		title: 'All',
	},
];

const productCountOptions = [
	{
		id: 0,
		title: 0,
	},
	{
		id: 1,
		title: 1,
	},
	{
		id: 2,
		title: 2,
	},
	{
		id: 3,
		title: 3,
	},
	{
		id: 4,
		title: 4,
	},
	{
		id: 5,
		title: 5,
	},
];

const FilterDrawer = (props) => {
	const {
		show,
		data,
		toggleDrawer,
		brandModal,
		geographyFilterModal,
		type,
		brandData,
		stores,
		processTypes,
		applyFilters,
		GEO_FILTER,
		FAILURE_ANALYSIS_FILTER,
		DATE_FILTER,
		BRAND_FILTER,
		STORE_FILTER,
		PROCESS_TYPE_FILTER,
		SKU_TYPE_FILTER,
		FROM_STORE_FILTER,
		resetFilters,
		filters,
		TIME_FILTER,
		gateway,
		fetchProducts,
		fetchStoresList,
		fetchProcessTypesList,
		productData,
		STATISTICAL_FILTER,
		UNDETECTED_PRODUCTS_FILTER,
		MEAL_TYPE_FILTER,
		LIST_DATA_FILTER,
		VALIDATED_DATA_FILTER,
		DUPLICATE_DATA_FILTER,
		IDENTIFIED_DATA_FILTER,
		FEATURES_DATA_FILTER,
		PRODUCT_COUNT_FILTER,
		defects,
		clear,
	} = props;

	const { clientType } = JSON.parse(localStorage.getItem('appConfig'));

	useEffect(() => {
		if (clear) {
			resetFilters(
				[
					'BRAND_FILTER',
					'STORE_FILTER',
					'PROCESS_TYPE_FILTER',
					'SKU_TYPE_FILTER',
					'FROM_STORE_FILTER',
					'GEO_FILTER',
					'FAILURE_ANALYSIS_FILTER',
					'TIME_FILTER',
					'STORE_ANALYSIS',
					'STATISTICAL_FILTER',
					'PRODUCT_FILTER',
					'UNDETECTED_PRODUCTS_FILTER',
					'MEAL_TYPE_FILTER',
					'MODE_TYPE_FILTER',
					'VALIDATED_DATA_FILTER',
					'DUPLICATE_DATA_FILTER',
					'IDENTIFIED_DATA_FILTER',
					'FEATURES_DATA_FILTER',
					'PRODUCT_COUNT_FILTER',
				],
				false
			);
		}

		gateway({
			entity: 'BRANDS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			params: {
				id: '',
			},
		});
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchProcessTypesList({
			entity: 'PROCESS_TYPES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		gateway({
			entity: 'DEFECTS',
			req: {
				params: {},
			},
			method: 'LIST',
		});
	}, []);
	const [state, dispatch] = useReducer(
		reducer,
		cloneDeep({
			...filterDrawerConfig.initialState,
			// GEO_FILTER,
			// FAILURE_ANALYSIS_FILTER,
			// DATE_FILTER,
			// BRAND_FILTER,
		})
	);
	const {
		FAILURE_ANALYSIS_FILTER: failureFilter,
		STATISTICAL_FILTER: statFilter,
		levels,
	} = state;
	const {
		query: queryStatistical,
		handleSearch: handleSearchStatistical,
		clearSearch: clearQueryStatistical,
	} = useSearch();

	const handleClose = () => {
		clearQueryStatistical();
		brandModal(false);
		geographyFilterModal(false);
		toggleDrawer(false);
		// setState({
		// ...filterDrawerConfig.initialState,
		// });
		dispatch({
			type: 'INIT',
			payload: {
				...cloneDeep({
					...filterDrawerConfig.initialState,
					GEO_FILTER,
					FAILURE_ANALYSIS_FILTER,
					DATE_FILTER,
					BRAND_FILTER,
					STORE_FILTER,
					PROCESS_TYPE_FILTER,
					SKU_TYPE_FILTER,
					FROM_STORE_FILTER,
					TIME_FILTER,
					STATISTICAL_FILTER,
					MEAL_TYPE_FILTER,
					UNDETECTED_PRODUCTS_FILTER,
					LIST_DATA_FILTER,
					VALIDATED_DATA_FILTER,
					DUPLICATE_DATA_FILTER,
					IDENTIFIED_DATA_FILTER,
					FEATURES_DATA_FILTER,
					PRODUCT_COUNT_FILTER,
				}),
			},
		});
	};

	const level = useMemo(() => {
		clearQueryStatistical();
		const tempLevels = [...levels];
		return {
			...tempLevels.pop(),
			current: tempLevels.length,
		};
	}, [levels]);

	useEffect(() => {
		dispatch({
			type: 'INIT',
			payload: {
				...cloneDeep({
					...filterDrawerConfig.initialState,
					GEO_FILTER,
					FAILURE_ANALYSIS_FILTER,
					DATE_FILTER,
					BRAND_FILTER,
					STORE_FILTER,
					PROCESS_TYPE_FILTER,
					SKU_TYPE_FILTER,
					FROM_STORE_FILTER,
					TIME_FILTER,
					STATISTICAL_FILTER,
					UNDETECTED_PRODUCTS_FILTER,
					MEAL_TYPE_FILTER,
					LIST_DATA_FILTER,
					VALIDATED_DATA_FILTER,
					DUPLICATE_DATA_FILTER,
					IDENTIFIED_DATA_FILTER,
					FEATURES_DATA_FILTER,
					PRODUCT_COUNT_FILTER,
				}),
			},
		});
	}, [
		GEO_FILTER,
		FAILURE_ANALYSIS_FILTER,
		DATE_FILTER,
		BRAND_FILTER,
		STORE_FILTER,
		PROCESS_TYPE_FILTER,
		SKU_TYPE_FILTER,
		FROM_STORE_FILTER,
		STATISTICAL_FILTER,
		UNDETECTED_PRODUCTS_FILTER,
		MEAL_TYPE_FILTER,
		LIST_DATA_FILTER,
		VALIDATED_DATA_FILTER,
		DUPLICATE_DATA_FILTER,
		IDENTIFIED_DATA_FILTER,
		FEATURES_DATA_FILTER,
		PRODUCT_COUNT_FILTER,
	]);

	const handleConfirm = () => {
		const requiredFilters = {};
		Object.keys(state).forEach((current) => {
			if (filters.includes(current)) {
				requiredFilters[current] = state[current];
			}
		});
		if (
			requiredFilters?.STATISTICAL_FILTER?.selected &&
			(requiredFilters?.FAILURE_ANALYSIS_FILTER.WEIGHT['over-weight'] ||
				requiredFilters?.FAILURE_ANALYSIS_FILTER.WEIGHT[
					'under-weight'
				] ||
				requiredFilters?.FAILURE_ANALYSIS_FILTER.TEMPERATURE[
					'over-temperature'
				] ||
				requiredFilters?.FAILURE_ANALYSIS_FILTER.TEMPERATURE[
					'under-temperature'
				] ||
				requiredFilters?.FAILURE_ANALYSIS_FILTER.DEFECT.applied)
		) {
			notificationServices.generateNotification({
				type: 'error',
				message:
					"Failure Analysis and Statistical Analysis can't be applied together.",
			});
			return;
		}
		applyFilters(requiredFilters, true);
		handleClose();
	};

	const PROCESS_TYPES = useMemo(() => {
		return processTypes
			?.filter((ele) => {
				return ele?.data?.totalMachines > 0;
			})
			?.map((ele) => {
				return {
					id: ele?.data?.nodeId,
					title: ele?.data?.heading,
				};
			});
	}, [processTypes]);

	const SKU_TYPES = useMemo(() => {
		return skuTypesListWithId;
	}, []);

	const handleItemSelectStatistical = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state.STATISTICAL_FILTER.selectedProductIds);
		if (isSelected) {
			tempArray = tempArray.filter((id) => {
				return id !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_STATISTICAL_FILTER',
			payload: {
				STATISTICAL_FILTER: {
					...state.STATISTICAL_FILTER,
					selected: tempArray.length !== 0,
					selectedProductIds: tempArray,
				},
			},
		});
	};

	const handleUndetectedProductSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state.UNDETECTED_PRODUCTS_FILTER.selected);
		if (isSelected) {
			tempArray = tempArray.filter((id) => {
				return id !== selectedId;
			});
		} else if (tempArray?.length <= 1) {
			tempArray.push(selectedId);
			tempArray = tempArray.filter((id) => {
				return id === selectedId;
			});
		}
		dispatch({
			type: 'SET_UNDETECTED_PRODUCTS_FILTER',
			payload: {
				applied: tempArray?.length > 0,
				selected: tempArray,
			},
		});
	};

	const handleStoreSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state.STORE_FILTER.selectedStores);
		if (isSelected) {
			tempArray = tempArray.filter((id) => {
				return id !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_STORE_FILTER',
			payload: {
				active: tempArray?.length > 0,
				selectedStores: tempArray,
			},
		});
	};
	const handleProcessTypesSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(
			state.PROCESS_TYPE_FILTER.selectedProcessTypes
		);
		if (isSelected) {
			tempArray = tempArray.filter((id) => {
				return id !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_PROCESS_TYPE_FILTER',
			payload: {
				active: tempArray?.length > 0,
				selectedProcessTypes: tempArray,
			},
		});
	};
	const handleSkuTypesSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state?.SKU_TYPE_FILTER?.selectedSkuTypes);
		if (isSelected) {
			tempArray = tempArray.filter((id) => {
				return id !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_SKU_TYPE_FILTER',
			payload: {
				active: tempArray?.length > 0,
				selectedSkuTypes: tempArray,
			},
		});
	};
	const handleFromStoreSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state.FROM_STORE_FILTER.selectedStores);
		if (isSelected) {
			tempArray = tempArray.filter((id) => {
				return id !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_FROM_STORE_FILTER',
			payload: {
				active: tempArray?.length > 0,
				selectedStores: tempArray,
			},
		});
	};

	const generateProductList = (filterType) => {
		if (filterType === 'UNDETECTED_PRODUCTS') {
			return Object.keys(productData)
				.filter((productId) => {
					const product = productData[productId];
					return (
						product.productName
							?.toLowerCase()
							.indexOf(queryStatistical.toLowerCase().trim()) !==
						-1
					);
				})
				.map((productId) => {
					const product = productData[productId];
					return {
						id: parseInt(productId, 10),
						title: product.productName,
					};
				});
		}
		return Object.keys(productData)
			.filter((productId) => {
				const product = productData[productId];
				return (
					product.productName
						?.toLowerCase()
						.indexOf(queryStatistical.toLowerCase().trim()) !==
						-1 &&
					product.brandId ===
						state.STATISTICAL_FILTER.selectedBrands?.[0]
				);
			})
			.map((productId) => {
				const product = productData[productId];
				return {
					id: parseInt(productId, 10),
					title: product.productName,
				};
			});
	};

	const handleStatFilterSelect = (e) => {
		const { dataset } = inputHelper(e);
		dispatch({
			type: 'SET_STATISTICAL_FILTER',
			payload: {
				STATISTICAL_FILTER: {
					...state.STATISTICAL_FILTER,
					filter: dataset.id,
				},
			},
		});
	};

	const handleMealTypeSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state?.MEAL_TYPE_FILTER?.selected);
		if (isSelected) {
			tempArray = tempArray.filter((ids) => {
				return ids !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_MEAL_TYPE_FILTER',
			payload: {
				applied: true,
				selected: tempArray,
			},
		});
	};
	const handleModeTypeSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state?.MODE_TYPE_FILTER?.selected);
		if (isSelected) {
			tempArray = tempArray.filter((ids) => {
				return ids !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_MODE_TYPE_FILTER',
			payload: {
				applied: true,
				selected: tempArray,
			},
		});
	};

	const handleProductCountSelect = (selectedId, isSelected) => {
		let tempArray = cloneDeep(state?.PRODUCT_COUNT_FILTER?.count);
		if (isSelected) {
			tempArray = tempArray.filter((ids) => {
				return ids !== selectedId;
			});
		} else {
			tempArray.push(selectedId);
		}
		dispatch({
			type: 'SET_PRODUCT_COUNT_FILTER',
			payload: {
				applied: tempArray?.length > 0,
				count: tempArray,
			},
		});
	};

	const handleDataFilter = (selectedId, isSelected, filterName) => {
		const tempValue = listDataOptions.find((ele) => {
			return ele.id === selectedId;
		});
		if (isSelected) {
			dispatch({
				type: `SET_${filterName}`,
				payload: {
					applied: true,
					value: null,
				},
			});
		} else {
			dispatch({
				type: `SET_${filterName}`,
				payload: {
					applied: true,
					value: tempValue.title,
				},
			});
		}
	};

	const selectUnselectStatisticalFilter = (filterName) => {
		if (!checkIfApplied(state, filterName)) {
			dispatch({
				type: 'NEXT_LEVEL',
				payload: {
					heading: 'Statistical Filter',
					type: 'STATISTICAL',
				},
			});
		}
		dispatch({
			type: 'SET_STATISTICAL_FILTER',
			payload: {
				STATISTICAL_FILTER: {
					...state.STATISTICAL_FILTER,
					selected: false,
					selectedProductIds: [],
				},
			},
		});
	};
	const handleClick = (id, isSelected) => {
		const selectedList = cloneDeep(state.BRAND_FILTER.selectedList);
		if (isSelected) {
			// setState({
			// ...state,
			// selectedList: selectedList.filter((productId) => {
			// return id !== productId;
			// }),
			// });
			dispatch({
				type: 'SET_BRAND_FILTER',
				payload: {
					BRAND_FILTER: {
						selectedList: selectedList.filter((productId) => {
							return id !== productId;
						}),
					},
				},
			});
		} else {
			// setState({
			// ...state,
			// selectedList: [...selectedList, id],
			// });
			dispatch({
				type: 'SET_BRAND_FILTER',
				payload: {
					BRAND_FILTER: {
						selectedList: [...selectedList, id],
					},
				},
			});
		}
	};

	const handleBrandSelect = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SELECT_STATISTICAL_BRAND',
			payload: [fieldValue],
		});
	};

	const screenAccordingToLevel = () => {
		if (level.type === 'FAILURE') {
			return (
				<Failure
					dispatch={dispatch}
					filterState={failureFilter}
					level={level}
				/>
			);
		}
		if (level.type === 'BRAND') {
			if (level.current === 1) {
				return (
					<div className={styles['brand-filter']}>
						<CheckListImage
							list={brandData}
							selectedItems={state.BRAND_FILTER.selectedList}
							handleItemSelect={handleClick}
						/>
					</div>
				);
			}
		}
		if (level.type === 'STORE_FILTER') {
			if (level.current === 1) {
				return (
					<div className={styles['store-filter']}>
						<CheckList
							list={stores?.filter((ele) => {
								return (
									ele.name
										?.toLowerCase()
										.indexOf(
											queryStatistical
												.toLowerCase()
												.trim()
										) !== -1
								);
							})}
							selectedItems={state.STORE_FILTER.selectedStores}
							handleItemSelect={handleStoreSelect}
							showSearch
							handleSearchInput={handleSearchStatistical}
							placeholder='Search Store'
						/>
					</div>
				);
			}
		}
		if (level.type === 'PROCESS_TYPE_FILTER') {
			if (level.current === 1) {
				return (
					<div className={styles['store-filter']}>
						<CheckList
							list={PROCESS_TYPES}
							selectedItems={
								state.PROCESS_TYPE_FILTER.selectedProcessTypes
							}
							handleItemSelect={handleProcessTypesSelect}
							// showSearch
							// handleSearchInput={handleSearchStatistical}
							// placeholder='Search Product'
						/>
					</div>
				);
			}
		}
		if (level.type === 'SKU_TYPE_FILTER') {
			if (level.current === 1) {
				return (
					<div className={styles['store-filter']}>
						<CheckList
							list={SKU_TYPES}
							selectedItems={
								state?.SKU_TYPE_FILTER?.selectedSkuTypes
							}
							handleItemSelect={handleSkuTypesSelect}
							// showSearch
							// handleSearchInput={handleSearchStatistical}
							// placeholder='Search Product'
						/>
					</div>
				);
			}
		}
		if (level.type === 'FROM_STORE_FILTER') {
			if (level.current === 1) {
				return (
					<div className={styles['store-filter']}>
						<CheckList
							list={stores}
							selectedItems={
								state.FROM_STORE_FILTER.selectedStores
							}
							handleItemSelect={handleFromStoreSelect}
							// showSearch
							// handleSearchInput={handleSearchStatistical}
							// placeholder='Search Product'
						/>
					</div>
				);
			}
		}
		if (level.type === 'STATISTICAL') {
			if (level.current === 1) {
				return (
					<div className={styles['statistical-filter']}>
						<div className={styles['statistical-filter-header']}>
							<Button
								onClick={handleStatFilterSelect}
								variant={
									statFilter.filter === 'Weight'
										? 'contained'
										: 'outlined'
								}
								color='secondary'
								data-id='Weight'
								className={styles['header-item']}
							>
								Weight
							</Button>
							<Button
								onClick={handleStatFilterSelect}
								variant={
									statFilter.filter !== 'Weight'
										? 'contained'
										: 'outlined'
								}
								color='secondary'
								data-id='Temperature'
								className={styles['header-item']}
							>
								Temperature
							</Button>
							<Select
								displayEmpty
								variant='outlined'
								id='brand'
								value={
									state.STATISTICAL_FILTER
										.selectedBrands?.[0] ?? ''
								}
								onChange={handleBrandSelect}
								className={classes(
									styles['header-item'],
									styles['brand-select']
								)}
							>
								<MenuItem value='' disabled>
									Brand
								</MenuItem>
								{brandData.map((brand) => {
									return (
										<MenuItem
											value={brand.id}
											key={brand.id}
										>
											{brand.name}
										</MenuItem>
									);
								})}
							</Select>
						</div>
						<CheckList
							list={generateProductList()}
							selectedItems={
								state.STATISTICAL_FILTER.selectedProductIds
							}
							handleItemSelect={handleItemSelectStatistical}
							showSearch
							handleSearchInput={handleSearchStatistical}
							placeholder='Search Product'
							emptyMessage={
								state.STATISTICAL_FILTER.selectedBrands
									.length === 0
									? 'Select a brand first'
									: 'No items found'
							}
						/>
					</div>
				);
			}
		}
		if (level.type === 'UNDETECTED_PRODUCTS') {
			if (level.current === 1) {
				return (
					<div className={styles['statistical-filter']}>
						<CheckList
							list={generateProductList('UNDETECTED_PRODUCTS')}
							selectedItems={
								state.UNDETECTED_PRODUCTS_FILTER.selected
							}
							handleItemSelect={handleUndetectedProductSelect}
							showSearch
							handleSearchInput={handleSearchStatistical}
							placeholder='Search Product'
						/>
					</div>
				);
			}
		}
		if (level.type === 'MEAL_TYPE') {
			if (level.current === 1) {
				return (
					<div className={styles['statistical-filter']}>
						<CheckList
							list={state.MEAL_TYPE_FILTER.list}
							selectedItems={state.MEAL_TYPE_FILTER.selected}
							handleItemSelect={handleMealTypeSelect}
						/>
					</div>
				);
			}
		}
		if (level.type === 'MODE_TYPE') {
			if (level.current === 1) {
				return (
					<div className={styles['list-data-filter']}>
						<CheckList
							list={state.MODE_TYPE_FILTER.list}
							selectedItems={state.MODE_TYPE_FILTER.selected}
							handleItemSelect={handleModeTypeSelect}
						/>
					</div>
				);
			}
		}
		if (level.type === 'VALIDATED_DATA') {
			if (level.current === 1) {
				return (
					<div className={styles['list-data-filter']}>
						<CheckList
							list={listDataOptions}
							selectedItems={[state.VALIDATED_DATA_FILTER.value]}
							handleItemSelect={(id, isSelected) => {
								return handleDataFilter(
									id,
									isSelected,
									'VALIDATED_DATA_FILTER'
								);
							}}
						/>
					</div>
				);
			}
		}
		if (level.type === 'DUPLICATE_DATA') {
			if (level.current === 1) {
				return (
					<div className={styles['list-data-filter']}>
						<CheckList
							list={listDataOptions}
							selectedItems={[state.DUPLICATE_DATA_FILTER?.value]}
							handleItemSelect={(id, isSelected) => {
								return handleDataFilter(
									id,
									isSelected,
									'DUPLICATE_DATA_FILTER'
								);
							}}
						/>
					</div>
				);
			}
		}
		if (level.type === 'IDENTIFIED_DATA') {
			if (level.current === 1) {
				return (
					<div className={styles['list-data-filter']}>
						<CheckList
							list={listDataOptions}
							selectedItems={[state.IDENTIFIED_DATA_FILTER.value]}
							handleItemSelect={(id, isSelected) => {
								return handleDataFilter(
									id,
									isSelected,
									'IDENTIFIED_DATA_FILTER'
								);
							}}
						/>
					</div>
				);
			}
		}
		if (level.type === 'FEATURES_DATA') {
			if (level.current === 1) {
				return (
					<div className={styles['list-data-filter']}>
						<CheckList
							list={listDataOptions}
							selectedItems={[state.FEATURES_DATA_FILTER.value]}
							handleItemSelect={(id, isSelected) => {
								return handleDataFilter(
									id,
									isSelected,
									'FEATURES_DATA_FILTER'
								);
							}}
						/>
					</div>
				);
			}
		}
		if (level.type === 'PRODUCT_COUNT') {
			if (level.current === 1) {
				return (
					<div className={styles['list-data-filter']}>
						<CheckList
							list={productCountOptions}
							selectedItems={state.PRODUCT_COUNT_FILTER.count}
							handleItemSelect={handleProductCountSelect}
						/>
					</div>
				);
			}
		}
		return <></>;
	};

	const handleBack = () => {
		dispatch({
			type: 'PREV_LEVEL',
		});
	};

	const renderBtns = () => {
		return (
			<div className='flex items-center gap-2'>
				{level.current > 0 && (
					<Button
						onClick={handleBack}
						variant='outlined'
						className='flex-1'
					>
						Back
					</Button>
				)}
				<Button
					onClick={handleConfirm}
					variant='contained'
					className='flex-1'
				>
					Confirm
				</Button>
			</div>
		);
	};

	return (
		<BaseDrawer renderBtns={renderBtns} header={data?.heading} open={show} onClose={handleClose}>
			{/* initial Screen */}
			{level.type == null ? (
				<div className={`${styles['list-container']}`}>
					{brandData?.length > 0 &&
						filters.includes('BRAND_FILTER') && (
						<CheckTile
							title='Brand filter'
							isCheckable={
								state.BRAND_FILTER.selectedList.length > 0
							}
							onExpand={() => {
								// brandModal(true);
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Select Brands',
										type: 'BRAND',
									},
								});
							}}
							checked={checkIfApplied(state, 'BRAND_FILTER')}
							onCheck={() => {
								const applied =
										state.BRAND_FILTER.selectedList.length >
										0;
								if (applied) {
									dispatch({
										type: 'SET_BRAND_FILTER',
										payload: {
											BRAND_FILTER: {
												selectedList: [],
											},
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('GEO_FILTER') && (
						<CheckTile
							title='Geography filter'
							isCheckable={checkIfApplied(state, 'GEO_FILTER')}
							onExpand={() => {
								geographyFilterModal(true);
								toggleDrawer(false);
							}}
							checked={checkIfApplied(state, 'GEO_FILTER')}
							onCheck={() => {
								if (checkIfApplied(state, 'GEO_FILTER')) {
									dispatch({
										type: 'SET_GEO_FILTER',
										payload: {
											city: [],
											state: [],
											store: [],
										},
									});
								} else {
									geographyFilterModal(true);
									toggleDrawer(false);
								}
							}}
						/>
					)}
					{type !== 'store' &&
						filters.includes('FAILURE_ANALYSIS_FILTER') && (
						<CheckTile
							title='Failure Analysis'
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Failure Analysis',
										type: 'FAILURE',
									},
								});
							}}
							onCheck={() => {
								const applied = checkIfApplied(
									state,
									'FAILURE_ANALYSIS_FILTER'
								);
								let newFilter = {};

								if (applied) {
									newFilter = cloneDeep(
										filterDrawerConfig.initialState
											.FAILURE_ANALYSIS_FILTER
									);
								} else {
									newFilter = {
										WEIGHT: {
											'over-weight': true,
											'under-weight': true,
										},
										TEMPERATURE: {
											'over-temperature': true,
											'under-temperature': true,
										},
										DEFECT: {
											applied: true,
											defects: defects.map(
												(defect) => {
													return defect.id;
												}
											),
										},
									};
								}
								dispatch({
									type: 'SET_FAILURE_ANALYSIS_FILTER',
									payload: newFilter,
								});
							}}
							checked={checkIfApplied(
								state,
								'FAILURE_ANALYSIS_FILTER'
							)}
						/>
					)}
					{filters.includes('STATISTICAL_FILTER') && (
						<CheckTile
							title='Statistical Analysis'
							isCheckable={checkIfApplied(
								state,
								'STATISTICAL_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Statistical Analysis',
										type: 'STATISTICAL',
									},
								});
							}}
							onCheck={selectUnselectStatisticalFilter}
							checked={checkIfApplied(
								state,
								'STATISTICAL_FILTER'
							)}
						/>
					)}
					{filters.includes('UNDETECTED_PRODUCTS_FILTER') && (
						<CheckTile
							title='Undetected Products'
							isCheckable={checkIfApplied(
								state,
								'UNDETECTED_PRODUCTS_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Undetected Products',
										type: 'UNDETECTED_PRODUCTS',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'UNDETECTED_PRODUCTS_FILTER'
							)}
							onCheck={() => {
								if (
									checkIfApplied(
										state,
										'UNDETECTED_PRODUCTS_FILTER'
									)
								) {
									dispatch({
										type: 'UNDETECTED_PRODUCTS_FILTER',
										payload: {
											applied: false,
											selected: [],
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('MEAL_TYPE_FILTER') &&
						clientType === 'SCHOOL' && (
						<CheckTile
							title='Meal Type'
							isCheckable={checkIfApplied(
								state,
								'MEAL_TYPE_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Meal Type',
										type: 'MEAL_TYPE',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'MEAL_TYPE_FILTER'
							)}
							onCheck={() => {
								const applied =
										state.MEAL_TYPE_FILTER?.selected
											?.length > 0;
								if (applied) {
									dispatch({
										type: 'SET_MEAL_TYPE_FILTER',
										payload: {
											applied: false,
											selected: [],
											list: state?.MEAL_TYPE_FILTER
												?.list,
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('MODE_TYPE_FILTER') &&
						clientType === 'SCHOOL' && (
						<CheckTile
							title='Mode Type'
							isCheckable={checkIfApplied(
								state,
								'MODE_TYPE_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Mode Type',
										type: 'MODE_TYPE',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'MODE_TYPE_FILTER'
							)}
							onCheck={() => {
								const applied =
										state.MODE_TYPE_FILTER?.selected
											?.length > 0;
								if (applied) {
									dispatch({
										type: 'SET_MODE_TYPE_FILTER',
										payload: {
											applied: false,
											selected: [],
											list: state?.MEAL_TYPE_FILTER
												?.list,
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('DUPLICATE_DATA_FILTER') && (
						<CheckTile
							title='Duplicate'
							isCheckable={checkIfApplied(
								state,
								'DUPLICATE_DATA_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Duplicate',
										type: 'DUPLICATE_DATA',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'DUPLICATE_DATA_FILTER'
							)}
							onCheck={() => {
								if (
									checkIfApplied(
										state,
										'DUPLICATE_DATA_FILTER'
									)
								) {
									dispatch({
										type: 'SET_DUPLICATE_DATA_FILTER',
										payload: {
											applied: false,
											value: null,
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('VALIDATED_DATA_FILTER') && (
						<CheckTile
							title='Validated'
							isCheckable={checkIfApplied(
								state,
								'VALIDATED_DATA_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Validated',
										type: 'VALIDATED_DATA',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'VALIDATED_DATA_FILTER'
							)}
							onCheck={() => {
								if (
									checkIfApplied(
										state,
										'VALIDATED_DATA_FILTER'
									)
								) {
									dispatch({
										type: 'SET_VALIDATED_DATA_FILTER',
										payload: {
											applied: false,
											value: null,
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('IDENTIFIED_DATA_FILTER') &&
						clientType !== 'SCHOOL' && (
						<CheckTile
							title='Identified'
							isCheckable={checkIfApplied(
								state,
								'IDENTIFIED_DATA_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Identified',
										type: 'IDENTIFIED_DATA',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'IDENTIFIED_DATA_FILTER'
							)}
							onCheck={() => {
								if (
									checkIfApplied(
										state,
										'IDENTIFIED_DATA_FILTER'
									)
								) {
									dispatch({
										type: 'SET_IDENTIFIED_DATA_FILTER',
										payload: {
											applied: false,
											value: null,
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('FEATURES_DATA_FILTER') && (
						<CheckTile
							title='Features'
							isCheckable={checkIfApplied(
								state,
								'FEATURES_DATA_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Features',
										type: 'FEATURES_DATA',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'FEATURES_DATA_FILTER'
							)}
							onCheck={() => {
								if (
									checkIfApplied(
										state,
										'FEATURES_DATA_FILTER'
									)
								) {
									dispatch({
										type: 'SET_FEATURES_DATA_FILTER',
										payload: {
											applied: false,
											value: null,
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('PRODUCT_COUNT_FILTER') &&
						clientType === 'SCHOOL' && (
						<CheckTile
							title='No. of Products in Meal'
							isCheckable={checkIfApplied(
								state,
								'PRODUCT_COUNT_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'No. of Products in Meal',
										type: 'PRODUCT_COUNT',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'PRODUCT_COUNT_FILTER'
							)}
							onCheck={() => {
								const applied =
										state.PRODUCT_COUNT_FILTER?.count
											?.length > 0;
								if (applied) {
									dispatch({
										type: 'SET_PRODUCT_COUNT_FILTER',
										payload: {
											applied: false,
											count: [],
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('STORE_FILTER') && (
						<CheckTile
							title='STORE'
							isCheckable={checkIfApplied(state, 'STORE_FILTER')}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'SELECT STORE',
										type: 'STORE_FILTER',
									},
								});
							}}
							checked={checkIfApplied(state, 'STORE_FILTER')}
							onCheck={() => {
								const applied =
									state.STORE_FILTER?.selectedStores?.length >
									0;
								if (applied) {
									dispatch({
										type: 'SET_STORE_FILTER',
										payload: {
											active: false,
											selectedStores: [],
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('PROCESS_TYPE_FILTER') && (
						<CheckTile
							title='PROCESS TYPE'
							isCheckable={checkIfApplied(
								state,
								'PROCESS_TYPE_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'SELECT PROCESS TYPES',
										type: 'PROCESS_TYPE_FILTER',
									},
								});
							}}
							checked={checkIfApplied(
								state,
								'PROCESS_TYPE_FILTER'
							)}
							onCheck={() => {
								const applied =
									state.PROCESS_TYPE_FILTER
										?.selectedProcessTypes?.length > 0;
								if (applied) {
									dispatch({
										type: 'SET_PROCESS_TYPE_FILTER',
										payload: {
											active: false,
											selectedStores: [],
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('SKU_TYPE_FILTER') && (
						<CheckTile
							title='SKU TYPE'
							isCheckable={checkIfApplied(
								state,
								'SKU_TYPE_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'SELECT SKU TYPES',
										type: 'SKU_TYPE_FILTER',
									},
								});
							}}
							checked={checkIfApplied(state, 'SKU_TYPE_FILTER')}
							onCheck={() => {
								const applied =
									state?.SKU_TYPE_FILTER?.selectedSkuTypes
										?.length > 0;
								if (applied) {
									dispatch({
										type: 'SET_SKU_TYPE_FILTER',
										payload: {
											active: false,
											selectedSkuTypes: [],
										},
									});
								}
							}}
						/>
					)}
					{filters.includes('FROM_STORE_FILTER') && (
						<CheckTile
							title='FROM OUTLET'
							isCheckable={checkIfApplied(
								state,
								'FROM_STORE_FILTER'
							)}
							onExpand={() => {
								dispatch({
									type: 'NEXT_LEVEL',
									payload: {
										heading: 'Select FROM OUTLET',
										type: 'FROM_STORE_FILTER',
									},
								});
							}}
							checked={checkIfApplied(state, 'FROM_STORE_FILTER')}
							onCheck={() => {
								const applied =
									state.FROM_STORE_FILTER?.selectedStores
										?.length > 0;
								if (applied) {
									dispatch({
										type: 'SET_FROM_STORE_FILTER',
										payload: {
											active: false,
											selectedStores: [],
										},
									});
								}
							}}
						/>
					)}
				</div>
			) : (
				screenAccordingToLevel()
			)}
		</BaseDrawer>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.filterDrawer;
	const { LIST: defects } = state.form.DEFECTS.data;
	const geoFilterData = state.analytics.GEO_FILTER;
	const geoData = state.geoData.GEO_DATA.data;
	const brandFilterData = state.analytics.BRAND_FILTER.selectedList;
	const brandData = state.form.BRANDS.data.LIST;
	const productData = state.product?.products ?? {};
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;
	const processTypes = state?.form?.PROCESS_TYPES?.data?.LIST;
	const {
		FAILURE_ANALYSIS_FILTER,
		DATE_FILTER,
		BRAND_FILTER,
		STORE_FILTER,
		PROCESS_TYPE_FILTER,
		FROM_STORE_FILTER,
		GEO_FILTER,
		TIME_FILTER,
		STATISTICAL_FILTER,
		UNDETECTED_PRODUCTS_FILTER,
		MEAL_TYPE_FILTER,
		LIST_DATA_FILTER,
		VALIDATED_DATA_FILTER,
		DUPLICATE_DATA_FILTER,
		IDENTIFIED_DATA_FILTER,
		FEATURES_DATA_FILTER,
		PRODUCT_COUNT_FILTER,
		SKU_TYPE_FILTER,
	} = state.analytics;
	return {
		show,
		data,
		defects,
		geoFilterData,
		brandFilterData,
		brandData,
		stores,
		processTypes,
		GEO_FILTER,
		FAILURE_ANALYSIS_FILTER,
		DATE_FILTER,
		BRAND_FILTER,
		STORE_FILTER,
		PROCESS_TYPE_FILTER,
		SKU_TYPE_FILTER,
		FROM_STORE_FILTER,
		TIME_FILTER,
		geoData,
		productData,
		STATISTICAL_FILTER,
		UNDETECTED_PRODUCTS_FILTER,
		MEAL_TYPE_FILTER,
		LIST_DATA_FILTER,
		VALIDATED_DATA_FILTER,
		DUPLICATE_DATA_FILTER,
		IDENTIFIED_DATA_FILTER,
		FEATURES_DATA_FILTER,
		PRODUCT_COUNT_FILTER,
	};
};

const mapDispatchToProps = {
	toggleDrawer: drawerActions.toggleFilterDrawer,
	brandModal: modalActions.toggleBrandFilterModal,
	gateway: formActions.gateway,
	fetchProducts: productActions.fetchProducts,
	geographyFilterModal: modalActions.toggleGeographyFilterModal,
	applyFilters: analyticsAction.applyFilters,
	resetFilters: analyticsAction.resetFilters,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer);
