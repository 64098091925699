import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { StoreSettingListPage, UpdateAvailibiltyPage } from '../../../Pages';
import SpecialMenu from '../../../Pages/My Outlets/Store Setting/Special Menu/SpecialMenu';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const StoreSettingRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.STORE_SETTINGS}`,
			name: 'Store Settings',
		},
	];
	return (
		<CRUDRoutes
			entity='STORE_SETTINGS'
			path={path}
			listPage={StoreSettingListPage}
			editPage={<UpdateAvailibiltyPage breadcrumbs={breadcrumbs} />}
			viewPage={<SpecialMenu breadcrumbs={breadcrumbs} />}
		/>
	);
};

export default StoreSettingRoutes;
