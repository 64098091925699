import { useCallback } from 'react';
import { classes } from '../../Helpers';
import excel from '../../Images/excel.svg';
import styles from './ExcelDownload.module.css';

function ExcelDownload(props) {
	const { header, fileData, fileName, className } = props;

	const handleExcelDownload = useCallback(() => {
		const downloadBtn = document.createElement('a');

		const csvData = [header, ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, [header, fileData, fileName]);

	return (
		<div className={classes(styles.root, className)} onClick={handleExcelDownload}>
			<img src={excel} alt='excel' />
			<p>Download</p>
		</div>
	);
}

export default ExcelDownload;
