/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
import { connect } from 'react-redux';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Nav from 'rsuite/Nav';
import moment from 'moment';
import { analyticsAction, formActions, modalActions } from '../../../Actions';
import { POGeneralDetails } from '../../../Components/POGeneralDetails';
import config from './CreateClosing.config';
import { POItemSelection } from '../../../Components/POItemSelection';
import CreatePageHeader from '../../../Components/Headers/CreatePageHeader/CreatePageHeader';
import { LOService, notificationServices } from '../../../Services';
import { AlertModal } from '../../../Components/Modals';
import styles from './CreateClosingPage.module.scss';
import {
	addCommas,
	classes,
	getPriceConvertedQuantity,
} from '../../../Helpers';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';
import { useAuth } from '@/Components/Auth/Login/auth';

const CreateClosingPage = (props) => {
	const saveRef = useRef(null);
	const {
		productsList,
		fetchProducts,
		processTypes,
		fetchProcessTypesList,
		fetchOrdersList,
		fmsOrdersList,
		resetInventoryAnalyticsState,
		toggleAlert,
		fetchStoresList,
		storesList,
	} = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const history = useHistory();
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const [selectedTypeTab, setSelectedTypeTab] = useState('INTERMEDIATE');
	const [variantLevelPricing, setVariantLevelPricing] = useState({});

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			dispatch({
				type: 'INIT',
			});
		};
	}, []);

	const fetchFmsOrders = () => {
		const req = {
			data: {
				filters: {
					dateRangeFilter: {
						from: moment(state?.inventoryAtDayEndOf)?.format(
							'YYYY-MM-DD'
						),
						to: moment(state?.inventoryAtDayEndOf)?.format(
							'YYYY-MM-DD'
						),
						dateType: 'scheduled',
					},
					fromProcessType: state?.selectedProcess?.id,
					storeId: state?.deliverTo?.id,
					orderType: 'fms',
					productionPlanIds: [],
				},
			},
		};
		fetchOrdersList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'FMS_ORDERS_LIST',
			req,
		});
	};

	useEffect(() => {
		resetInventoryAnalyticsState({
			entity: 'INVENTORY_ANALYTICS',
			method: 'FMS_ORDERS_LIST',
			data: [],
		});
	}, []);

	useEffect(() => {
		if (
			state?.deliverTo?.id &&
			state?.selectedProcess?.id &&
			state?.inventoryAtDayEndOf
		) {
			fetchFmsOrders();
		}
	}, [
		state?.deliverTo?.id,
		state?.selectedProcess?.id,
		state?.inventoryAtDayEndOf,
	]);

	useEffect(() => {
		if (state?.deliverTo?.id) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: state?.deliverTo?.id,
					},
				},
				method: 'LIST',
			});
		}
	}, [state?.deliverTo?.id]);

	const setState = (type, value) => {
		if (type) {
			saveRef.current = false;
			if (type === 'SET_DELIVER_TO' && value?.id) {
				perItemPricing(value?.id);
			}
			dispatch({
				type: 'SET_PO_ITEMS',
				payload: {
					INTERMEDIATE: [],
					INGREDIENT: [],
				},
			});
			resetInventoryAnalyticsState({
				entity: 'INVENTORY_ANALYTICS',
				method: 'FMS_ORDERS_LIST',
				data: [],
			});
			dispatch({
				type,
				payload: value,
			});
		}
	};
	const setPOItems = (newState) => {
		dispatch({
			type: 'SET_PO_ITEMS',
			payload: {
				...state?.poItems,
				[selectedTypeTab]: newState,
			},
		});
	};
	function changeAddedItemsToReq() {
		const reqList = {};
		Object?.keys(state?.poItems ?? {})?.forEach((eleKey) => {
			const skuTypeItems = state?.poItems?.[eleKey];
			skuTypeItems?.forEach((element) => {
				const InputUnit = element?.productInputUnit;
				let qty = parseInt(element?.quantity, 10);
				if (
					(element?.unit === 'gram' && InputUnit === 'kg') ||
					(element?.unit === 'ml' && InputUnit === 'ltr')
				) {
					qty *= 1000;
				}
				if (element?.unit === 'floz' && InputUnit === 'gal') {
					qty *= 128;
				}
				if (element?.unit === 'oz' && InputUnit === 'lbs') {
					qty *= 16;
				}
				if (reqList?.[eleKey]) {
					reqList?.[eleKey]?.push({
						variantId: element?.id,
						productId: element?.productId,
						quantity: qty,
						productInputUnit: element?.unit,
					});
				} else {
					reqList[eleKey] = [
						{
							variantId: element?.id,
							productId: element?.productId,
							quantity: qty,
							productInputUnit: element?.unit,
						},
					];
				}
			});
		});
		return reqList;
	}

	const saveLeftOverInv = async () => {
		const req = {
			storeId: state?.deliverTo?.id,
			inventoryAtDayEndOf: moment(state?.inventoryAtDayEndOf).format(
				'M/D/YYYY'
			),
			addedItems: changeAddedItemsToReq(),
			selectedProcess: state?.selectedProcess?.id,
		};
		try {
			const response = await LOService.addLOInventory(req);
			if (response?.statusCode === 200) {
				dispatch({
					type: 'INIT',
				});
				history.goBack();
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const PRODUCTS = useMemo(() => {
		return productsList?.filter((product) => {
			if (selectedTypeTab === 'INGREDIENT') {
				return !['PRODUCT', 'INTERMEDIATE']?.includes(product?.skuType);
			}
			return product?.skuType === selectedTypeTab;
		});
	}, [productsList, selectedTypeTab]);

	const handleBackClick = () => {
		history.goBack();
		saveRef.current = false;
		dispatch({
			type: 'INIT',
		});
	};

	const processDayIngredients = useMemo(() => {
		setPOItems([]);
		const skuTypeWiseItems = [];
		fmsOrdersList?.forEach((ele) => {
			// eslint-disable-next-line consistent-return
			ele?.items?.forEach((poItem) => {
				if (poItem?.skuType === 'PRODUCT') return null;
				const doesExists = skuTypeWiseItems?.[poItem?.skuType]?.find(
					(item) => {
						return (
							item?.productId === poItem?.productId &&
							item?.variantId === poItem?.variantId
						);
					}
				);
				// const exists = state?.poItems?.[selectedTypeTab]?.find(
				// 	(statePOItem) => {
				// 		return statePOItem?.variantId === ele?.variantId;
				// 	}
				// );
				// let qty = '';
				// if (exists) {
				// 	qty = exists?.qty;
				// }
				if (!doesExists) {
					let productInputUnit = 'kg';
					if (poItem?.unit === 'ml') {
						productInputUnit = 'ltr';
					}
					if (poItem?.unit === 'count') {
						productInputUnit = 'piece';
					}
					if (poItem?.unit === 'floz') {
						productInputUnit = 'gal';
					}
					if (poItem?.unit === 'oz') {
						productInputUnit = 'lbs';
					}
					skuTypeWiseItems?.push({
						...poItem,
						id: poItem?.variantId,
						qty: '',
						orderId: poItem?.id,
						productInputUnit,
					});
					// if (poItem?.skuType === 'INGREDIENT') {
					// }
				}
			});
		});
		return skuTypeWiseItems;
	}, [fmsOrdersList]);

	useEffect(() => {
		const newPOItems = {
			INTERMEDIATE: [],
			INGREDIENT: [],
		};
		processDayIngredients?.forEach((ele) => {
			const alreadyAdded = newPOItems?.[ele?.skuType]?.find(
				(listItem) => {
					return listItem?.variantId === ele?.variantId;
				}
			);
			if (!alreadyAdded) {
				newPOItems?.[ele?.skuType]?.push({
					...ele,
				});
			}
		});
		dispatch({
			type: 'SET_PO_ITEMS',
			payload: {
				...newPOItems,
			},
		});
	}, [processDayIngredients]);

	const handleSave = () => {
		const validationErrors = config.validateItems(state?.poItems);
		if (validationErrors.length) {
			notificationServices.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: 'Please enter valid Quantity of ' + validationErrors?.join(', '),
			});
			return;
		}
		const totalAmount = [
			...state?.poItems?.INTERMEDIATE,
			...state?.poItems?.INGREDIENT,
		]?.reduce((acc, currProd) => {
			const isBaseUnit = ['oz', 'floz', 'ml', 'gram', 'g']?.includes(
				currProd?.productInputUnit
			);
			let qtyNum = parseInt(currProd?.quantity, 10);
			if (!currProd?.quantity) {
				qtyNum = 0;
			}
			const itemVarPrice =
				variantLevelPricing?.[currProd?.selectedVariant?.id];
			let perItemPrice = itemVarPrice * qtyNum;
			if (isBaseUnit) {
				perItemPrice = getPriceConvertedQuantity(
					perItemPrice,
					currProd?.productInputUnit,
					true
				);
			}
			// eslint-disable-next-line no-param-reassign
			acc += Number(perItemPrice);
			return acc;
		}, 0);
		toggleAlert(true, {
			heading: 'Add Left Over inventory',
			handleConfirm: () => {
				if (saveRef.current) {
					return;
				}
				saveRef.current = true;
				saveLeftOverInv();
				toggleAlert(false);
			},
			colorReverse: false,
			cancelText: 'No',
			confirmText: 'Yes',
			message: (
				<div className='flex flex-col gap-1'>
					<p className='m-0'>
						Adding Left Over Inventory of
						{' '}
						{moment(state?.inventoryAtDayEndOf)?.format(
							'YYYY-MM-DD'
						)}
					</p>
					<p className='font-semibold text-lg m-0 animate-pulse text-blue-700'>
						Total Amount:
						{' '}
						{addCommas(totalAmount, measurement, true)}
					</p>
				</div>
			),
		});
	};

	// eslint-disable-next-line no-unused-vars
	async function perItemPricing(storeId) {
		const response = await inventoryAnalyticsServices.skuStock({
			params: {
				type: 'stock',
				storeId,
			},
		});
		const tempVariantLevelPricing = {};
		Object.entries(response?.data)?.forEach(([, value]) => {
			value?.currentStock?.forEach((ele) => {
				tempVariantLevelPricing[ele?.variantId] = ele?.rate;
			});
		});
		setVariantLevelPricing(tempVariantLevelPricing);
	}

	const customComponents = {
		QUANTITY: (currProd) => {
			const isBaseUnit = ['oz', 'floz', 'ml', 'gram', 'g']?.includes(
				currProd?.productInputUnit
			);
			let qtyNum = parseInt(currProd?.quantity, 10);
			if (!currProd?.quantity) {
				qtyNum = 0;
			}
			const itemVarPrice =
				variantLevelPricing?.[currProd?.selectedVariant?.id];
			let perItemPrice = itemVarPrice * qtyNum;
			if (isBaseUnit) {
				perItemPrice = getPriceConvertedQuantity(
					perItemPrice,
					currProd?.productInputUnit,
					true
				);
			}
			return (
				<div className='flex gap-2 text-blue-700 font-semibold'>
					<p className='text-[10px] m-0'>Total Price:</p>
					<p className='text-[10px] m-0'>
						{addCommas(perItemPrice, measurement, true)}
					</p>
				</div>
			);
		},
	};

	return (
		<div>
			<AlertModal />
			<CreatePageHeader
				heading='Add Left Over Inventory'
				showCancel={false}
				handleSave={handleSave}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div
				style={{
					paddingTop: '5px',
					paddingBottom: '5px',
					paddingLeft: '10px',
				}}
			>
				<POGeneralDetails
					filteredStoresList={storesList}
					state={state}
					filterLabels={{
						store: 'Store',
						processTypes: 'Process',
					}}
					setState={setState}
					processList={processTypes}
					thisStore
					requiredFields={{
						fromStore: false,
						filteredDeliverTo: true,
						itemsTypes: ['INGREDIENT'],
						process: true,
						inventoryAtDayEndOf: true,
					}}
					mandatoryFields={{
						process: true,
					}}
				/>
			</div>
			<div className={styles.selector}>
				<Nav
					appearance='tabs'
					activeKey={selectedTypeTab}
					onSelect={setSelectedTypeTab}
					className={styles.tabs}
				>
					<Nav.Item
						style={{
							background: '#FBF1D3',
						}}
						className={classes(
							styles.tab,
							styles?.[selectedTypeTab],
							selectedTypeTab === 'INTERMEDIATE'
								? styles.selected
								: ''
						)}
						eventKey='INTERMEDIATE'
					>
						INTERMEDIATE
					</Nav.Item>
					<Nav.Item
						style={{
							background: '#D3FAC7',
						}}
						className={classes(
							styles.tab,
							styles?.[selectedTypeTab],
							selectedTypeTab === 'INGREDIENT'
								? styles.selected
								: ''
						)}
						eventKey='INGREDIENT'
					>
						INGREDIENT
					</Nav.Item>
				</Nav>
				<div
					className={classes(styles.list, styles?.[selectedTypeTab])}
				>
					{selectedTypeTab === 'INTERMEDIATE' && (
						<POItemSelection
							state={state?.poItems?.INTERMEDIATE ?? []}
							disabled={
								!state?.deliverTo?.id ||
								!state?.selectedProcess?.id ||
								!state?.inventoryAtDayEndOf
							}
							productsList={PRODUCTS}
							setState={setPOItems}
							customComponents={customComponents}
							filterColumns={[
								'TAX %',
								'QTY-INFO',
								'RATE',
								'Discount%',
								'Total Discount',
								'Total Amount',
								'Sub total',
								'Total Tax',
							]}
						/>
					)}
					{selectedTypeTab === 'INGREDIENT' && (
						<POItemSelection
							state={state?.poItems?.INGREDIENT ?? []}
							disabled={
								!state?.deliverTo?.id ||
								!state?.selectedProcess?.id ||
								!state?.inventoryAtDayEndOf
							}
							productsList={PRODUCTS}
							setState={setPOItems}
							customComponents={customComponents}
							filterColumns={[
								'TAX %',
								'QTY-INFO',
								'RATE',
								'Discount%',
								'Total Discount',
								'Total Amount',
								'Sub total',
								'Total Tax',
							]}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	const { LIST: storesList } = state?.form?.FILTERED_STORES?.data;
	const { navState } = state?.navigation;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { FMS_ORDERS_LIST: fmsOrdersList } =
		state?.analytics?.INVENTORY_ANALYTICS;

	return {
		productsList,
		vendors,
		storesList,
		navState,
		processTypes,
		fmsOrdersList,
	};
};
const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	toggleGenerateStandardPOModal: modalActions.toggleGenerateStandardPOModal,
	fetchProcessTypesList: formActions.gateway,
	fetchOrdersList: analyticsAction.gateway,
	resetInventoryAnalyticsState: analyticsAction.resetInventoryAnalyticsState,
	toggleAlert: modalActions?.toggleAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateClosingPage);
