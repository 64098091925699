/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { cloneDeep } from 'lodash';
import { notificationServices } from '../../../../Services';

const addOverrideKey = (obj, level) => {
	Object.entries(obj).forEach(([key, value]) => {
		if (typeof value === 'object' && value !== null) {
			Object.entries(value).forEach(([key1, val]) => {
				if (!val.hasOwnProperty('override')) {
					val.override = 0;
				}
				if (val.hasOwnProperty('value')) {
					val.value = level;
					val.parentValue = level;
				}
			});
		}
	});
};
const initialState = {
	id: null,
	username: '',
	password: '',
	confirmationPassword: '',
	displayName: '',
	email: '',
	contact: '',
	accessLevel: [],
	userRoles: [],
	appModules: [],
	businessUser: false,
	assetId: null,
	store: null,
	selectedLevels: {},
	saveMenuState: {},
	appUser: false,
	machineUser: false,
	userLevel: null,
	processIds: [],
	processPasscode: '',
	consoleUser: true,
	image: null,
};

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'SET_USER_DATA':
			return {
				...action?.payload,
			};
		case 'INIT':
			return {
				id: null,
				username: '',
				password: '',
				confirmationPassword: '',
				displayName: '',
				email: '',
				contact: '',
				accessLevel: [],
				appModules: [],
				businessUser: false,
				assetId: null,
				store: null,
				selectedLevels: {},
				userRoles: [],
				saveMenuState: {},
				appUser: false,
				machineUser: false,
				userLevel: null,
				processIds: [],
				processPasscode: '',
				consoleUser: true,
				image: null,
			};
		default:
			return state;
	}
};

const config = {
	initialState,
	reducer,
	validator: (state, isEdit) => {
		if (!state?.username) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'User name is Mandatory',
			});
			return false;
		}
		if (!isEdit && !state?.password) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Password is mandatory',
			});
			return false;
		}
		if (!state?.displayName) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Display name is mandatory',
			});
			return false;
		}
		if (state?.password !== state?.confirmationPassword) {
			notificationServices.generateNotification({
				type: 'error',
				message: "Password and Confirm password doesn't match",
			});
			return false;
		}
		if (state?.contact === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Contact number is mandatory',
			});
			return false;
		}
		if (state?.contact.length < 10) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Contact number must be 10 digits',
			});
			return false;
		}
		if (state?.email === '' || !emailPattern.test(state?.email)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Email Address is not valid',
			});
			return false;
		}
		if (state?.userRoles?.length === 0 && !(state?.appUser || state?.machineUser)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select atleast one role',
			});
			return false;
		}
		if ((state?.appUser || state?.machineUser || state?.consoleUser) && !state?.userLevel) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Level is Mandatory',
			});
			return false;
		}
		if ((state?.appUser || state?.machineUser) && !state?.store) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Select  Store is Mandatory ',
			});
			return false;
		}
		if (state?.machineUser && state?.userLevel === 3 && state?.processIds?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Assign Process for Machine User Mandatory',
			});
			return false;
		}
		if (state?.appUserUser && state?.userLevel >= 3 && state?.processIds?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Assign Process for App User Mandatory',
			});
			return false;
		}
		if (state?.machineUser && state?.userLevel === 3 && !state?.processPasscode) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'PassCode is mandatory',
			});
			return false;
		}
		if (state?.appUser && state?.userLevel >= 3 && !state?.processPasscode) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'PassCode is mandatory',
			});
			return false;
		}
		if ((state?.machineUser || state?.appUser) && state?.processPasscode && (state?.processPasscode.length < 6 ||
			state?.processPasscode.length > 6)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Your passcode must be exactly 6 digits. ',
			});
			return false;
		}
		return true;
	},
	isValid: (state, isEdit) => {
		if (!state?.username) {
			return false;
		}
		if (!isEdit && !state?.password) {
			return false;
		}
		if (!state?.displayName) {
			return false;
		}
		if (!isEdit && state?.password !== state?.confirmationPassword) {
			return false;
		}
		if (state?.contact === '') {
			return false;
		}
		if (state?.userRoles?.length === 0 && (state?.appUser || state?.machineUser)) {
			return false;
		}
		return true;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const newState = {
			...clonedState,
			level: clonedState?.userLevel,
			store: clonedState?.store !== 'none' ? clonedState?.store : -1,
			assetId: clonedState?.assetId !== -11 ? clonedState?.assetId : null,
			processIds:
				clonedState?.processIds ? clonedState?.processIds : null,
			appModules:
				clonedState?.appModules?.length > 0
					? clonedState?.appModules
					: null,
			userRoles: clonedState.appUser ? [] : clonedState.userRoles,
			selectedLevels: clonedState.appUser ? {} : (() => {
				addOverrideKey(clonedState.selectedLevels, clonedState?.userLevel);
				return clonedState.selectedLevels;
			})(),
			saveMenuState: clonedState.appUser ? {} : clonedState.saveMenuState,
		};
		// if (isEdit) {
		// // eslint-disable-next-line no-restricted-globals
		// const graceDays = !isNaN(parseInt(clonedState?.graceDays, 10))
		// ? parseInt(clonedState?.graceDays, 10)
		// : 0;
		// newState = {
		// storeId: clonedState?.store,
		// graceDays,
		// status: status(),
		// startDate: moment(clonedState?.startDate)?.format('YYYY-MM-DD'),
		// endDate: moment(clonedState?.endDate)?.format('YYYY-MM-DD'),
		// };
		// }
		return newState;
	},
	deserializeUser: (userData) => {
		const tempData = {
			username: userData?.userDetails?.username,
			displayName: userData?.userDetails?.name,
			email: userData?.userDetails?.email,
			contact:
				// eslint-disable-next-line no-restricted-globals
				!isNaN(Number(userData?.userDetails?.mobile))
					? Number(userData?.userDetails?.mobile)
					: null,
			accessLevel: userData?.accessLevel
				? userData?.accessLevel?.split(',')
				: [],
			appModules: userData?.accessLevel ?? [],
			businessUser: userData?.isBusinessUser,
			selectedLevels: userData?.selectedLevels,
			menuState: userData?.menuState,
			processIds: [],
			assetId: null,
			store: null,
			appUser: userData?.appUser,
			machineUser: userData?.machineUser,
			userLevel: userData?.userLevel,
			passcode: userData?.passcode,
		};
		return tempData;
	},
};

export default config;
