import { generateRequiredProps } from '../../../../Helpers';
import styles from './ManagerDetails.module.scss';

const ManagerDetails = (props) => {
	const { mandatoryFields, fields, errors, handleInput } = props;
	return (
		<div className={`${styles.root} row`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='managerName'
						data-mandatory={fields.enableSection.managerDetails || mandatoryFields.managerName}
					>
						Name
					</label>
					<input
						className='form-value'
						type='text'
						id='managerName'
						name='managerName'
						onInput={handleInput}
						value={fields.managerName}
						{...generateRequiredProps(
							fields.enableSection.managerDetails ||
								mandatoryFields.managerName
						)}
					/>
					<span className='form-error'>{errors.managerName}</span>
				</div>
				{/* Email no. */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='email'
						data-mandatory={
							fields.enableSection.managerDetails || mandatoryFields.email
						}
					>
						Email
					</label>
					<input
						className='form-value'
						type='text'
						id='email'
						name='email'
						onInput={handleInput}
						value={fields.email}
						{...generateRequiredProps(
							fields.enableSection.managerDetails || mandatoryFields.email
						)}
					/>
					<span className='form-error'>{errors.GSTno}</span>
				</div>
				{/* phone Number */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='phoneno'
						data-mandatory={
							fields.enableSection.managerDetails || mandatoryFields.phoneno
						}
					>
						Mobile Number
					</label>
					<input
						className='form-value'
						type='number'
						id='phoneno'
						name='phoneno'
						onInput={handleInput}
						value={fields.phoneno}
						{...generateRequiredProps(
							fields.enableSection.managerDetails || mandatoryFields.phoneno
						)}
					/>
					<span className='form-error'>{errors.phoneno}</span>
				</div>
			</div>
		</div>
	);
};

ManagerDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	handleFileInput: () => {},
	generateRequiredProps: () => {
		return {};
	},
};

export default ManagerDetails;
