import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import styles from './PageNavigation.module.css';

const PageNavigation = (props) => {
	const { prev, next, navigate, current } = props;

	return (
		<div className={styles.root}>
			<Tooltip title={prev?.tooltip} arrow>
				<IconButton
					style={{
						visibility: prev?.show ? 'visible' : 'hidden',
					}}
					disabled={prev?.disabled}
					onClick={navigate}
					data-type='prev'
					size='large'
				>
					<ArrowBackIos />
				</IconButton>
			</Tooltip>
			<div className={styles.stat}>
				<div className={styles.label}>{current?.label}</div>
				<div className={styles.value}>{current?.value}</div>
			</div>
			<Tooltip title={next?.tooltip} arrow>
				<IconButton
					style={{
						visibility: next?.show ? 'visible' : 'hidden',
					}}
					disabled={next?.disabled}
					onClick={navigate}
					data-type='next'
					size='large'
				>
					<ArrowForwardIos />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default PageNavigation;
