/* eslint-disable react/jsx-one-expression-per-line */
// import ClearIcon from '@mui/icons-material/Clear';
import { Cancel } from '@mui/icons-material';
import { IconInput } from '../../../Inputs';
import styles from './SlabTag.module.scss';

const SlabTag = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		slab,
		dataAttrs,
		handleDelete,
		isDisabled,
		idx,
		mandatoryFields,
		deliveryFrom,
	} = props;
	const handleInput = (e) => {
		props.handleSlabInput(e, idx);
	};

	return (
		<div
			className={`${styles.root} ${isDisabled ? styles.disabled : ''}`}
			{...dataAttrs}
		>
			<div className={`${styles.text}`}>
				<span>
					Delivery slab {idx + 1} : {deliveryFrom} km -{' '}
					{slab.deliveryRadius ? slab.deliveryRadius : "' '"} Km
				</span>
			</div>
			{/* Delivery Charges */}
			<div>
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='deliveryCharges'
						data-mandatory={mandatoryFields.slabs}
					>
						Delivery Charges
					</label>
					<IconInput
						size='large'
						type='rupee'
						name='deliveryCharges'
						id='deliveryCharges'
						handleInput={handleInput}
						dataType='deliveryCharges'
						value={slab.deliveryCharges}
						step='0.01'
					/>

					<label
						className={`form-label ${styles.label}`}
						htmlFor='deliveryRadius'
						data-mandatory={mandatoryFields.slabs}
					>
						Delivery Upto
					</label>
					<IconInput
						size='large'
						type='custom'
						iconText='Km'
						name='deliveryRadius'
						id='deliveryRadius'
						handleInput={handleInput}
						dataType='deliveryRadius'
						value={slab.deliveryRadius}
						step='0.01'
					/>
				</div>
			</div>
			<Cancel
				className={styles.clear}
				onClick={handleDelete}
				{...dataAttrs}
			/>
		</div>
	);
};

SlabTag.defaultProps = {
	text: '',
	dataAttrs: {},
	handleDelete: () => {},
	isDisabled: false,
};

export default SlabTag;
