/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Cancel } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import TimePicker from '@mui/lab/TimePicker';
import { TextField, Tooltip } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import { BaseModal } from '../BaseModal';
import styles from './TimeShowModal.module.css';
import { modalActions } from '../../../Actions';
import { modalTypes } from '../../../Constants';
import CustomDatesPicker from '../../CustomDatesPicker/CustomDatesPicker';
import CustomWeekDays from '../../CustomWeekDays/CustomWeekDays';
import { notificationServices } from '../../../Services';

const MODAL_TYPE = modalTypes.TIME_SLOTS;

const TimeModal = (props) => {
	const HEADING = 'Select Time';
	const { toggleModal, show, data, handleDelete } = props;

	const initialState = {
		startTime: '',
		endTime: '',
		applicableDates: [],
		applicableDays: [data?.days],
		timeSlots: [],
		shouldNotFilterDates: false,
		shouldNotFilterDays: false,
	};
	const [state, setState] = useState({
		...initialState,
	});

	useEffect(() => {
		setState({
			...state,
			applicableDates: data?.dates ?? [],
			applicableDays: data?.days ?? [],
			timeSlots: data?.slots ?? [],
		});
	}, [data?.days, data?.dates]);

	const { startTime, endTime } = state;

	const handleClose = () => {
		setState({
			...initialState,
		});
		toggleModal(false);
	};

	const handleConfirm = () => {
		props.handleConfirm({
			timeSlot: {
				...state,
				applicableDates: state.shouldNotFilterDates
					? null
					: state.applicableDates,
				applicableDays: state.shouldNotFilterDays
					? null
					: state.applicableDays,
			},
			config: {
				id: data?.id,
				name: data?.name,
				type: data?.type,
			},
		});
		handleClose();
	};

	const handleInput = (name, newValue) => {
		setState({
			...state,
			[name]: newValue,
		});
	};

	const handleSelectedDates = (dates) => {
		setState({
			...state,
			applicableDates: dates,
		});
	};

	const handleSelectedDays = (days) => {
		setState({
			...state,
			applicableDays: days,
		});
	};

	const timeDelete = (idx) => {
		const newTimeSlot = state?.timeSlots?.filter((slot, index) => {
			return index !== idx;
		});
		setState({
			...state,
			timeSlots: newTimeSlot,
		});
	};

	const showTime = (name, time, text) => {
		return (
			<div className={`${styles['time-picker']}`}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<TimePicker
						label={text}
						value={time}
						onChange={(newValue) => {
							handleInput(name, newValue);
						}}
						renderInput={(params) => {
							return <TextField {...params} />;
						}}
					/>
				</LocalizationProvider>
			</div>
		);
	};

	const handleAddSlot = () => {
		const slotObj = {
			startTime: moment(startTime).format('HH:mm'),
			endTime: moment(endTime).format('HH:mm'),
		};
		if (slotObj.startTime.length > 5 || slotObj.endTime.length > 5) {
			notificationServices.generateNotification({
				message: 'Please Enter Valid Time',
				type: 'error',
			});
		} else {
			state.timeSlots.push(slotObj);
			setState({
				...state,
				timeSlots: state?.timeSlots,
			});
		}
	};

	return (
		<div>
			<BaseModal
				heading={HEADING}
				height={500}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				open={show}
				width='50%'
			>
				<div className={`${styles.root}`}>
					<div className={`${styles['time-selector']}`}>
						<div>Choose a time:</div>
						<div className={styles['time-wrapper']}>
							{showTime('startTime', startTime, 'Start Time')}
							{showTime('endTime', endTime, 'End Time')}
							{data?.type === 'product' && (
								<button
									type='button'
									className={styles['add-btn']}
									onClick={handleAddSlot}
									disabled={state?.timeSlots?.length > 2}
								>
									Add Time Slot
								</button>
							)}
						</div>
						<div className={styles['slot-label']}>
							Selected Time Slots:
						</div>
						{data?.type === 'product' &&
							state?.timeSlots?.map((slot, idx) => {
								return (
									<div className={styles['time-container']}>
										<Tooltip
											title='Delete Time Slot'
											placement='right-end'
										>
											<Cancel
												className={
													styles['delete-icon']
												}
												onClick={() => {
													return timeDelete(idx);
												}}
												data-confirm={idx}
												data-id={data?.id}
												data-name={data?.name}
												data-type={data?.type}
											/>
										</Tooltip>
										<p className={styles.heading}>
											{slot?.startTime}
											to
											{slot?.endTime}
										</p>
									</div>
								);
							})}
					</div>

					{data?.type === 'product' && (
						<>
							<div className={`${styles.divider}`} />

							<div className={`${styles['date-selector']}`}>
								<div>
									<div>Choose dates:</div>
									<div className={styles['dates-wrapper']}>
										<CustomDatesPicker
											selectedDates={
												state?.applicableDates
											}
											handleSelectedDates={
												handleSelectedDates
											}
											disabled={
												state.shouldNotFilterDates
											}
										/>
									</div>
								</div>
								<div className={styles['no-filter']}>
									<input
										type='checkbox'
										id='noFilterDates'
										onChange={() => {
											return setState({
												...state,
												shouldNotFilterDates:
													!state.shouldNotFilterDates,
											});
										}}
										checked={state.shouldNotFilterDates}
									/>
									<label htmlFor='noFilterDates'>
										No Filter
									</label>
								</div>
							</div>

							<div className={`${styles.divider}`} />

							<div className={`${styles['days-selector']}`}>
								<div className={styles['days-container']}>
									<div>Choose days:</div>
									<div className={styles['days-wrapper']}>
										<CustomWeekDays
											selectedDays={state?.applicableDays}
											handleSelectedDays={
												handleSelectedDays
											}
											disabled={state.shouldNotFilterDays}
										/>
									</div>
								</div>
								<div className={styles['no-filter']}>
									<input
										type='checkbox'
										id='noFilterDays'
										onChange={() => {
											return setState({
												...state,
												shouldNotFilterDays:
													!state.shouldNotFilterDays,
											});
										}}
										checked={state.shouldNotFilterDays}
									/>
									<label htmlFor='noFilterDays'>
										No Filter
									</label>
								</div>
							</div>
						</>
					)}
				</div>
			</BaseModal>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.[MODAL_TYPE];
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(MODAL_TYPE, show, data));
		},
	};
};

TimeModal.defaultProps = {
	show: true,
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeModal);
