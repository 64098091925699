import styles from './CustomerDetails.module.scss';

const PaymentDetails = (props) => {
	const { fields, promoDetails } = props;
	return (
		<div className={styles.root}>
			<div className='form-field row'>
				<div
					className='form-field row'
					style={{
						width: '50%',
					}}
				>
					<div className='form-label'>Cart Value</div>
					<div className='form-value'>
						₹
						{fields.cartValue}
					</div>
				</div>
				<div
					className='form-field row'
					style={{
						width: '50%',
					}}
				>
					<div className='form-label'>Invoice Value</div>
					<div className='form-value'>
						₹
						{fields.invoiceValue}
					</div>
				</div>
			</div>
			<div className='form-field row'>
				<div
					className='form-field row'
					style={{
						width: '50%',
					}}
				>
					<div className='form-label'>Transaction Amount</div>
					<div className='form-value'>
						₹
						{fields.transactionAmount}
					</div>
				</div>
				<div
					className='form-field row'
					style={{
						width: '50%',
					}}
				>
					<div className='form-label'>Refunded Amount</div>
					<div className='form-value'>
						₹
						{' '}
						{fields.refundedAmount}
					</div>
				</div>
			</div>
			<div className='form-field row'>
				<div
					className='form-field row'
					style={{
						width: '50%',
					}}
				>
					<div className='form-label'>Promo Code Applied</div>
					<div className='form-value'>
						{promoDetails.couponApplied ? 'true' : 'false'}
					</div>
				</div>
				<div
					className='form-field row'
					style={{
						width: '50%',
					}}
				>
					<div className='form-label'>Promo Code Discount</div>
					<div className='form-value'>
						₹
						{' '}
						{promoDetails.couponDiscount}
					</div>
				</div>
			</div>
		</div>
	);
};
PaymentDetails.defaultProps = {};
export default PaymentDetails;
