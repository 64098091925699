import { generateCheckedProps, generateRandomKey } from '../../../Helpers';
import styles from './EnableDisable.module.scss';

const EnableDisable = (props) => {
	// eslint-disable-next-line object-curly-newline
	const {
		children,
		dataAttrs,
		name,
		handleIsEnabled,
		isEnabled,
		text,
		condition,
		checkBoxShow,
	} = props;
	const showChildren = () => {
		if (condition) {
			return !isEnabled && children != null;
		}
		return isEnabled && children != null;
	};
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles['enable-disable']}`}>
				<input
					{...generateRandomKey()}
					name={name}
					type='checkbox'
					onChange={handleIsEnabled}
					{...dataAttrs}
					{...generateCheckedProps(isEnabled)}
					id={`${dataAttrs['data-type']}-time-slot`}
					className={`${styles['enable-disable-check']}`}
					style={{
						display: checkBoxShow ? '' : 'none',
					}}
				/>
				<label
					htmlFor={`${dataAttrs['data-type']}-time-slot`}
					style={{
						display: checkBoxShow ? '' : 'none',
					}}
				>
					{text}
				</label>
			</div>
			{showChildren() && (
				<div className={`${styles.children}`}>{children}</div>
			)}
		</div>
	);
};
EnableDisable.defaultProps = {
	dataAttrs: {},
	checkBoxShow: true,
};
export default EnableDisable;
