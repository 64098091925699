import _ from 'lodash';
import { notificationServices } from '@/Services';

export const cookingConfig = {
	initialState: {
		fields: {
			isEnabled: false,
			isCMSEnabled: false,
			timeReq: '',
		},
	},
	serializer: (state, data) => {
		return (_.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				isEnabled: data?.isCookingEnabled,
				timeReq: data?.cookingTime,
				isCMSEnabled: data?.isCMSEnabled,
			};
			return newState;
		}));
	},
	deserializer: (state) => {
		return (_.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				cookingTime: state.fields.timeReq
					? parseInt(state.fields.timeReq, 10)
					: 0,
				isCookingEnabled: state?.fields?.isEnabled,
				isCMSEnabled: state?.fields?.isCMSEnabled,
			};
			return newState;
		}));
	},
	validator: (state) => {
		if (!state?.fields?.timeReq) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Time is required',
			});
		}
	},
};
