/* eslint-disable object-curly-newline */
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import styles from './UtilitiesCreatePage.module.scss';
import CreateFormpage from '../../../Components/CreateFormpage/CreateFormpage';
import { CreatePageHeader } from '../../../Components/Headers';
import { formActions, modalActions } from '../../../Actions';
import utilitiesCreateConfig from './utilitiesCreate.config';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import {
	getStateCodeFromGSTIN,
	getTaxFromStr,
	useQuery,
} from '../../../Helpers';
import { BackdropLoader } from '../../../Components/Loaders';
import BillAlreadyPaidInputs from '../../PettyCash/PettyCashCreatePage/BillAlreadyPaidInputs/BillAlreadyPaidInputs';
import { urls } from '../../../Constants';
// import POTotalContainer from '../../../Components/POTotalContainer/POTotalContainer';

const UtilitiesCreatePage = (props) => {
	const query = useQuery();
	const history = useHistory();
	const queryToStore = query.get('toStore');

	const { fetchVendors, vendorsList, storesList, toggleFileUploaderModal, fetchStoresList } =
		props;
	const [state, dispatch] = useReducer(
		utilitiesCreateConfig?.reducer,
		utilitiesCreateConfig?.initialState
	);
	const [categoryList, setCategoryList] = useState([]);
	const [taxSlabsList, setTaxSlabsList] = useState([]);
	const [isLoading, setIsloading] = useState(false);

	const fetchCategoryList = async (catType = 'UTILITIES') => {
		try {
			const response = await inventoryAnalyticsServices.fetchCategoryList(
				{
					req: {
						data: {
							type: catType,
						},
					},
				}
			);
			if (response?.statusCode === 200) {
				if (catType === 'UTILITIES') {
					setCategoryList(response?.data?.categories);
				} else if (catType === 'TAX_SLAB') {
					setTaxSlabsList(response?.data?.categories);
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};

	useEffect(() => {
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchVendors({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchCategoryList();
		fetchCategoryList('TAX_SLAB');
	}, []);

	useEffect(() => {
		if (queryToStore && queryToStore !== 'undefined') {
			const newStore = storesList?.find((ele) => {
				return ele?.id === Number(queryToStore);
			});
			dispatch({
				type: 'SET_STORE',
				payload: newStore,
			});
		}
	}, [queryToStore, storesList]);

	const handleSubmit = async () => {
		const isInvalid = utilitiesCreateConfig?.validator(state);
		// if (state?.periodTo > state?.billDate) {
		// notificationServices.generateNotification({
		// type: 'error',
		// message:
		// 'The period of the cycle should end before the selected bill date.',
		// });
		// return;
		// }
		if (state?.amount?.includes('-') || state?.amount <= 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter valid Amount',
			});
			return;
		}
		if (isInvalid) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please enter Mandatory Fields',
			});
			return;
		}
		setIsloading(true);
		const deserializedData = utilitiesCreateConfig?.deserializer(state);
		const req = {
			data: {
				...deserializedData,
				nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.UTILITIES_PAGE}?clientUtilityId=${deserializedData?.clientOrderId}`,
			},
		};
		try {
			const response =
				await inventoryAnalyticsServices.createTransactionRecord(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch ({ name, message }) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message,
			});
		} finally {
			setIsloading(false);
		}
	};

	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				<Button
					onClick={() => {
						handleSubmit();
					}}
					variant='contained'
					color='primary'
					type='button'
				>
					Save
				</Button>
			</div>
		);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const handleVendorSelection = (newVendor) => {
		dispatch({
			type: 'SET_VENDOR',
			payload: newVendor,
		});
	};

	const handleStoreSelection = (newStore) => {
		dispatch({
			type: 'SET_STORE',
			payload: newStore,
		});
	};

	const handleRemoveVendor = (e) => {
		if (e) {
			if (e === 'vendor' || e.keyCode === 8) {
				dispatch({
					type: 'SET_VENDOR',
					payload: {},
				});
			}
		}
	};
	const handleRemoveStore = (e) => {
		if (e) {
			if (e === 'store' || e.keyCode === 8) {
				dispatch({
					type: 'SET_STORE',
					payload: {},
				});
			}
		}
	};

	const handleDateSelection = (newDate, type) => {
		dispatch({
			type,
			payload: newDate,
		});
	};

	async function fetchTempId() {
		if (state?.clientTransactionId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_UTILITY_ID',
					payload: response?.data,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	useEffect(() => {
		fetchTempId();
	}, []);

	const isStateTax = (vendorGst, storeGst) => {
		if (
			getStateCodeFromGSTIN(vendorGst) === getStateCodeFromGSTIN(storeGst)
		) {
			return true;
		}
		return false;
	};

	function formattedInputs(mainList = []) {
		const vendorGst = state?.vendor?.gstin;
		const storeGst = state?.store?.gstNumber;
		let vendorHaveGst = false;
		let isSameState = false;
		// eslint-disable-next-line no-unused-vars, prefer-const
		let extraLabelData = { color: 'green' };
		let tax = 0;
		if (vendorGst && vendorGst?.length > 0 && storeGst) {
			vendorHaveGst = true;
			isSameState = isStateTax(vendorGst, storeGst);
			if (state?.taxSlab) {
				tax = getTaxFromStr(state.taxSlab);
				if (!isSameState) {
					extraLabelData.text = `IGST ${tax.toFixed(1)}% `;
				} else {
					extraLabelData.text = `SGST ${(tax / 2).toFixed(
						1
					)}%  |  CGST ${(tax / 2).toFixed(1)}%`;
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (!isSameState) {
					extraLabelData.text = 'IGST';
				} else {
					extraLabelData.text = 'SGST | CGST';
				}
			}
			// eslint-disable-next-line no-unused-vars
		}
		let list = mainList;
		if (!vendorHaveGst) {
			list = list?.filter((ele) => {
				return ele?.id !== 'taxSlab';
			});
		}

		return list?.map((ele) => {
			if (Array.isArray(ele)) {
				return formattedInputs(ele);
			}
			if (ele?.inputType === 'customComponent') {
				return {
					...ele,
					component: (
						<BillAlreadyPaidInputs
							state={{
								checkValue: state?.alreadyPaid?.isPaid,
								uploadedDoc: {
									file: state?.alreadyPaid?.uploadedDocFile,
									fileName:
										state?.alreadyPaid?.uploadedDocFileName,
								},
								paymentMode: state?.alreadyPaid?.paymentMode,
							}}
							setState={(fields) => {
								if (fields?.uploadedDoc) {
									dispatch({
										type: 'SET_ALREADY_PAID',
										payload: {
											...state?.alreadyPaid,
											uploadedDocFile:
												fields?.uploadedDoc?.file,
											uploadedDocFileName:
												fields?.uploadedDoc?.fileName,
										},
									});
									return;
								}
								if (fields?.paymentMode) {
									dispatch({
										type: 'SET_ALREADY_PAID',
										payload: {
											...state?.alreadyPaid,
											paymentMode: fields?.paymentMode,
										},
									});
									return;
								}
								if (fields?.transactionRefNumber) {
									dispatch({
										type: 'SET_ALREADY_PAID',
										payload: {
											...state?.alreadyPaid,
											transactionRefNumber:
												fields?.transactionRefNumber,
										},
									});
									return;
								}
								dispatch({
									type: 'SET_ALREADY_PAID',
									payload: {
										...state?.alreadyPaid,
										isPaid: fields?.checkValue,
									},
								});
							}}
						/>
					),
				};
			}
			if (ele?.inputType === 'options_search' && ele?.id === 'vendor') {
				const vendors = vendorsList.filter((item) => {
					return item.vendorType === 'UTILITY';
				});
				return {
					...ele,
					value: state?.vendor?.name,
					handleSelection: handleVendorSelection,
					optionsList: vendors,
					handleKeyPress: handleRemoveVendor,
					handleRemove: handleRemoveVendor,
				};
			}
			if (ele?.inputType === 'options_search' && ele?.id === 'store') {
				return {
					...ele,
					value: state?.store?.name,
					handleSelection: handleStoreSelection,
					optionsList: storesList,
					handleKeyPress: handleRemoveStore,
					handleRemove: handleRemoveStore,
				};
			}
			if (ele?.inputType === 'dateSelector' && ele?.id === 'periodFrom') {
				return {
					...ele,
					value: state?.periodFrom,
					oneTap: true,
					onChange: (date) => {
						handleDateSelection(date, 'SET_PERIOD_FROM');
					},
				};
			}
			if (ele?.inputType === 'dateSelector' && ele?.id === 'periodTo') {
				return {
					...ele,
					value: state?.periodTo,
					oneTap: true,
					onChange: (date) => {
						handleDateSelection(date, 'SET_PERIOD_TO');
					},
					disabledDate: (dateSelectorDate) => {
						return dateSelectorDate < state?.periodFrom;
					},
				};
			}
			if (ele?.inputType === 'dateSelector' && ele?.id === 'billDate') {
				return {
					...ele,
					value: state?.billDate,
					oneTap: true,
					onChange: (date) => {
						handleDateSelection(date, 'SET_BILL_DATE');
					},
					disabledDate: (dateSelectorDate) => {
						return dateSelectorDate >= new Date();
					},
				};
			}
			if (ele?.inputType === 'options' && ele?.id === 'taxSlab') {
				return {
					...ele,
					optionsList: taxSlabsList?.map((cat) => {
						return cat?.value;
					}),
					extraInfo: extraLabelData,
					value: state?.taxSlab || ele?.value,
					onChange: (value) => {
						dispatch({
							type: 'SET_TAX_SLAB',
							payload: value,
						});
					},
				};
			}
			if (ele?.inputType === 'dateSelector' && ele?.id === 'dueDate') {
				return {
					...ele,
					value: state?.dueDate,
					oneTap: true,
					onChange: (date) => {
						handleDateSelection(date, 'SET_DUE_DATE');
					},
					disabledDate: (dateSelectorDate) => {
						return dateSelectorDate <= state?.billDate - 1;
					},
				};
			}
			if (ele?.inputType === 'options' && ele?.id === 'utilityCategory') {
				return {
					...ele,
					optionsList: categoryList?.map((catItem) => {
						return {
							...catItem,
							title: catItem?.value,
						};
					}),
					value: state?.category?.id,
					onChange: (value) => {
						const categoryItem = categoryList?.find((currItem) => {
							return currItem?.id === Number(value);
						});
						dispatch({
							type: 'SET_CATEGORY',
							payload: categoryItem,
						});
					},
				};
			}
			if (ele?.id === 'amount') {
				return {
					...ele,
					value: state?.amount,
					onChange: (e) => {
						const value = e?.target?.value;
						const isPositiveDecimal =
							/^(?:\d+(\.\d*)?|\.\d+)?$/.test(value);
						// eslint-disable-next-line no-restricted-globals
						if (value === '' || isPositiveDecimal) {
							dispatch({
								type: 'SET_AMOUNT',
								payload: e.target.value,
							});
						}
					},
				};
			}
			if (ele?.id === 'clientTransactionId') {
				return {
					...ele,
					value: state?.clientTransactionId,
					onChange: (e) => {
						dispatch({
							type: 'SET_UTILITY_ID',
							payload: e.target.value,
						});
					},
				};
			}
			if (ele?.id === 'billNo') {
				return {
					...ele,
					value: state?.billNo,
					onChange: (e) => {
						dispatch({
							type: 'SET_BILL_NO',
							payload: e.target.value,
						});
					},
				};
			}
			if (ele?.inputType === 'docUploader') {
				return {
					...ele,
					value: state?.uploadedDoc,
					prefix: state?.uploadedDoc?.file ? 'Update' : 'Upload',
					btnText: 'Reference File',
					uploadFileClick: () => {
						toggleFileUploaderModal(true, {
							heading: 'Upload Vendor Invoice',
							selectedFile: '',
							confirmText: 'Upload',
							handleConfirm: (uploadedFile) => {
								dispatch({
									type: 'SET_UPLOADED_DOC',
									payload: uploadedFile,
								});
							},
						});
					},
				};
			}
			return ele;
		});
	}

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} showProgress />
			<CreatePageHeader
				heading='Add Utilities'
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className={styles.body}>
				<CreateFormpage
					inputsList={formattedInputs(
						utilitiesCreateConfig?.inputsList
					)}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addVendorModal;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const storesList = state.form.FILTERED_STORES.data.LIST;

	return {
		show,
		data,
		vendorsList,
		storesList,
	};
};

const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UtilitiesCreatePage);
