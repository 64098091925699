import { useRef } from 'react';
import { getElementAtEvent, Line } from 'react-chartjs-2';
import { Ticks } from 'chart.js';
import { LineChartSkeleton } from './Skeleton';
import { inputHelper } from '../../../../Helpers';

const LineChart = (props) => {
	const {
		title,
		legendDisplay,
		maintainAspectRatio,
		data,
		className,
		loading,
		stackX,
		stackY,
		xAxisCallback,
		yAxisCallback,
		xScale,
		yScale,
		dataAttrs,
		annotations,
	} = props;

	const ref = useRef(null);

	const onClick = (event) => {
		const { current: chart } = ref;
		if (chart) {
			const elementAtEvent = getElementAtEvent(chart, event);
			const { dataset } = inputHelper(event);
			// eslint-disable-next-line react/destructuring-assignment
			props.onClick(elementAtEvent, dataset);
		}
	};

	return (
		<>
			<Line
				ref={ref}
				onClick={onClick}
				data={data}
				options={{
					responsive: true,
					maintainAspectRatio,
					scales: {
						x: {
							stacked: stackX,
							ticks: {
								font: {
									size: 13,
									color: 'black',
								},
								callback(value, index, ticks) {
									return xAxisCallback(
										this,
										value,
										index,
										ticks
									);
								},
							},
							...xScale,
						},

						y: {
							stacked: stackY,
							ticks: {
								font: {
									size: 12,
									color: 'black',
								},
								callback(value, index, ticks) {
									return yAxisCallback(
										this,
										value,
										index,
										ticks
									);
								},
							},
							...yScale,
						},
					},
					plugins: {
						legend: {
							position: 'top',
							display: legendDisplay,
						},
						annotation: {
							annotations,
						},
						title: {
							display: true,
							text: title,
							font: {
								color: 'black',
								size: 16,
							},
						},
					},
				}}
				className={className}
				{...dataAttrs}
			/>
			{loading && <LineChartSkeleton />}
		</>
	);
};

LineChart.defaultProps = {
	title: '',
	legendDisplay: true,
	maintainAspectRatio: false,
	data: {
		datasets: [],
		labels: [],
	},
	showDataLabels: true,
	dataLabelFormatter: (context) => {
		if (context) {
			return Math.round(context);
		}
		return '';
	},
	handleElementClick: () => {},
	className: '',
	maxBarThickness: 50,
	stackX: false,
	stackY: false,
	onClick: () => {},
	xAxisCallback(chartThis, value, index, ticks) {
		const newValue = chartThis.getLabelForValue(value);
		return Ticks.formatters.values.apply(chartThis, [
			newValue,
			index,
			ticks,
		]);
	},
	yAxisCallback(chartThis, value, index, ticks) {
		const newValue = chartThis.getLabelForValue(value);
		return Ticks.formatters.values.apply(chartThis, [
			newValue,
			index,
			ticks,
		]);
	},
	dataAttrs: {},
	annotations: {},
	xScale: {},
	yScale: {},
};

export default LineChart;
