// eslint-disable-next-line object-curly-newline
import { cloneDeep, get, update } from 'lodash';
import { isEmpty } from '../../../Helpers';
import { notificationServices } from '../../../Services';

const customizationConfig = {
	initialState: {
		fields: {
			recipeInfo: {
				// recipe id
				id: null,
				// product id
				productId: null,
				recipeDescription: '',
				recipeURL: '',
				isIntermediate: false,
				totalCookingTime: 0,
			},
			stageInfo: {
				id: null,
				stageName: null,
				stageDescription: '',
				stationId: null,
				stageDependency: [],
			},
			customizations: {
				items: {},
				order: [],
			},
			product: {
				id: '',
				productName: '',
				brandName: '',
				productImageUrl: '',
				variants: [],
				defaultVariant: {},
			},
		},
		numberOfSubmissions: 0,
	},
	mandatoryFields: {
		customizations: {
			items: {
				1: {
					name: true,
					type: true,
					deltaPricing: true,
					limit: true,
					skus: [
						{
							qty: true,
							price: true,
						},
					],
				},
			},
		},
	},
	newGroupObject: {
		processId: null,
		id: null,
		name: 'Unnamed Customization',
		type: '',
		limit: null,
		isRemovable: false,
		deltaPricing: false,
		applicableOn: [],
		skus: [],
	},
	serializer: (state, data) => {
		const clonedState = cloneDeep(state);
		const newState = update(clonedState, 'fields', (fields) => {
			const newFields = fields;
			newFields.product = {
				id: data.product.id,
				productName: data.product.productName,
				brandName: data.product.brandName,
				productImageUrl: data.product.productImageUrl,
				variants: data.product.variants,
				defaultVariant: data.product.variants.find((variant) => {
					return variant.isDefault;
				}),
			};
			const recipeInfo = data.recipe;
			if (!isEmpty(recipeInfo)) {
				newFields.recipeInfo = {
					id: recipeInfo.id,
					productId: recipeInfo.productId,
					recipeDescription: recipeInfo.recipeDescription,
					recipeURL: recipeInfo.recipeURL,
					isIntermediate: recipeInfo.isIntermediate,
					totalCookingTime: recipeInfo.totalCookingTime,
				};
				if (recipeInfo.stages.length > 0) {
					const stageInfo = recipeInfo.stages[0];
					newFields.stageInfo = {
						id: stageInfo.id,
						stageName: stageInfo.stageName,
						stageDescription: stageInfo.stageDescription,
						stationId: stageInfo.stationId,
						stageDependency: stageInfo.stageDependency,
					};
					newFields.customizations.items =
						recipeInfo.stages[0].processes.reduce(
							(
								accCustomization,
								currCustomization,
								idxCustomization
							) => {
								// eslint-disable-next-line no-param-reassign
								accCustomization[idxCustomization + 1] = {
									id: idxCustomization + 1,
									processId: currCustomization.id,
									name: currCustomization.customizationName,
									processName: currCustomization.processName,
									processDescription:
										currCustomization.processDescription,
									processTime: currCustomization.processTime,
									type: currCustomization.customizationType,
									limit: currCustomization.addOnLimit,
									isRemovable:
										currCustomization.removableProcess,
									deltaPricing:
										currCustomization.deltaPricing,
									applicableOn: data.product.variants.map(
										(variant) => {
											return {
												id: variant.id,
												name: variant.displayName,
												size: variant.size,
												isApplied:
													currCustomization.applicableSizeVariants.includes(
														variant.id.toString()
													),
											};
										}
									),
									skus: currCustomization.skus.map(
										(skuObj) => {
											return {
												id: skuObj.id,
												skuId: skuObj.skuId,
												isDefault: skuObj.replaceable,
												unit: skuObj.unit,
												variantId: skuObj.variantId,
												variants:
													data.product.variants.map(
														(variant, idx) => {
															if (
																variant.id ===
																get(
																	skuObj.pricing,
																	`pricing[${idx}]`,
																	{}
																).id
															) {
																return skuObj
																	.pricing
																	.pricing[idx];
															}
															return {
																id: variant.id,
																qty: 1,
																price: 1,
															};
														}
													),
											};
										}
									),
								};
								return accCustomization;
							},
							{}
						);
					newFields.customizations.order =
						recipeInfo.stages[0].processes.map(
							(customizationObj, idx) => {
								return idx + 1;
							}
						);
				}
			} else {
				newFields.recipeInfo = {
					// recipe id
					id: null,
					// product id
					productId: null,
					recipeDescription: '',
					recipeURL: '',
					isIntermediate: false,
					totalCookingTime: 0,
				};
				newFields.stageInfo = {
					id: null,
					stageName: null,
					stageDescription: '',
					stationId: null,
					stageDependency: [],
				};
				newFields.customizations = {
					items: {},
					order: [],
				};
			}
			return newFields;
		});
		return newState;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const req = {
			...clonedState.fields.recipeInfo,
			stages: [
				{
					...clonedState.fields.stageInfo,
					stageName: clonedState?.fields?.stageInfo?.stageName ?? 'Unnamed Stage',
					processes: clonedState.fields.customizations.order.map(
						(processIdx) => {
							const process =
								clonedState.fields.customizations.items[
									processIdx
								];
							return {
								id: process.processId,
								processName: get(process, 'processName', 'Unnamed Process'),
								processDescription: get(
									process,
									'processDescription',
									''
								),
								processTime: get(process, 'processTime', 0),
								customizationName: process.name,
								customizationType: process.type,
								addOnLimit: process.limit,
								deltaPricing: process.deltaPricing,
								applicableSizeVariants: process.applicableOn
									.reduce((accVariant, currVariant) => {
										if (currVariant.isApplied) {
											accVariant.push(currVariant.id);
										}
										return accVariant;
									}, [])
									.join(','),
								removableProcess: process.isRemovable,
								skus: process.skus.map((sku) => {
									return {
										id: sku.id,
										skuId: sku.skuId,
										unit: sku.unit,
										variantId: sku.variantId,
										replaceable: sku.isDefault,
										pricing: {
											pricing: sku.variants.filter(
												(variant, idx) => {
													return process.applicableOn[
														idx
													].isApplied;
												}
											),
										},
									};
								}),
							};
						}
					),
				},
			],
		};
		return req;
	},
	validator: (state) => {
		const totalGroups = state.fields.customizations.order.length;

		for (let groupIdx = 0; groupIdx < totalGroups; groupIdx++) {
			const groupObj =
				state.fields.customizations.items[
					state.fields.customizations.order[groupIdx]
				];
			// customization type cannot be empty
			if (groupObj.type === '' || !groupObj.type) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please select a customization type in Group ${
						groupIdx + 1
					}`,
				});
				return false;
			}
			const isAppliedToSomeVariant = groupObj.applicableOn.some(
				(variant) => {
					return variant.isApplied;
				}
			);
			// at least one variant must be selected
			if (!isAppliedToSomeVariant) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please apply customization to at least one variant in Group ${
						groupIdx + 1
					}`,
				});
				return false;
			}
			// at least one sku must be selected in a group
			if (groupObj.skus.length === 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Please select at least one SKU in Group ${
						groupIdx + 1
					}`,
				});
				return false;
			}
		}
		return true;
	},
};

export default customizationConfig;
