/* eslint-disable no-tabs */
import { endpoints } from '../../Constants';
import { authHeader } from '../../Helpers';
import handleResponse from '../helpers';

function fetchAiData({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			// clientId: 52,
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.AI_STATS_DATA}`, requestOptions).then(
		handleResponse
	);
}

function fetchAiProductData({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			// clientId: 52,
			...authHeader(),
		},
		body: JSON?.stringify({
			...req?.data,
		}),
	};
	return fetch(`${endpoints.QUALITY_ANALYTICS_LIST}`, requestOptions).then(
		handleResponse
	);
}

// function updateMachineService({ req }) {
// 	const requestOptions = {
// 		method: 'POST',
// 		headers: {
// 			Accept: 'application/json',
// 			'Content-Type': 'application/json',
// 			...authHeader(),
// 		},
// 		body: JSON?.stringify({
// 			...req?.data,
// 		}),
// 	};
// 	return fetch(`${endpoints.UPDATE_MACHINES}`, requestOptions).then(
// 		handleResponse
// 	);
// }

export default {
	fetchAiData,
	fetchAiProductData,
};
