import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { ProductListFilters } from '../../../Components/Forms/Products';
import { urls } from '../../../Constants';
import { formActions } from '../../../Actions';
import {
	InventoryConfigEditPage,
	InventoryConfigListPage,
	InventoryConfigViewPage,
} from '../../../Pages';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const InventoryConfigRoutes = (props) => {
	const { fetchInventoryConfig } = props;

	const fetchAPI = (skuType) => {
		fetchInventoryConfig({
			entity: 'INVENTORY_CONFIG',
			req: {
				params: {
					id: '',
					skuType,
					combo: true,
				},
			},
			method: 'LIST',
		});
	};

	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.INVENTORY_CONFIG}`,
			name: 'Inventory Config',
		},
	];

	return (
		<CRUDRoutes
			entity='INVENTORY CONFIG'
			path={path}
			listPage={InventoryConfigListPage}
			listFilter={<ProductListFilters fetchAPI={fetchAPI} />}
			viewPage={<InventoryConfigViewPage breadcrumbs={breadcrumbs} />}
			editPage={
				<InventoryConfigEditPage isEdit breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	fetchInventoryConfig: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryConfigRoutes);
