import { Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import { KeyboardArrowDown } from '@mui/icons-material';
import { cloneDeep, get } from 'lodash';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { urls } from '../../../../Constants';
import { classes, cpkComparator } from '../../../../Helpers';
import { ListTable } from '../../../ListTable';
import styles from './StatisticalTable.module.scss';
import analyticsAction from '../../../../Actions/analytics.actions';

const COLUMNS = [
	{
		title: 'Show',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Legend',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Pass %',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'LSL',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'USL',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'μ',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'σ',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Cpk',
		config: {
			type: 'DATA',
		},
	},
];

const COL_MAP = {
	Show: 'show',
	Legend: 'legend',
	Product: 'productName',
	Total: 'total',
	'Pass %': 'passpercent',
	LSL: 'lowerLimit',
	USL: 'upperLimit',
	μ: 'mean',
	σ: 'stddev',
	Cpk: 'cpk',
};

const getRowStyles = (item) => {
	if (item.cpk >= 1.3 || item.cpk === 'Perfect process') {
		return styles.normal;
	}
	if (item.cpk < 1.3 && item.cpk >= 0) {
		return styles.moderate;
	}
	return styles.critical;
};

const StatisticalTable = (props) => {
	const history = useHistory();
	const [isAscendingSort, setIsAscendingSort] = useState(false);

	const { data, applyFilters, toggleShowItem } = props;

	const sortCPK = () => {
		setIsAscendingSort((prev) => {
			return !prev;
		});
	};

	const renderColumn = (column) => {
		switch (column) {
			case 'μ':
			case 'σ':
				return <>{column}</>;
			case 'Cpk':
				return (
					<span className={styles.cpk} onClick={sortCPK}>
						<span>
							<span>C</span>
							<span className={styles.subscript}>pk</span>
						</span>
						<KeyboardArrowDown
							className={classes(
								styles['sort-icon'],
								isAscendingSort ? styles.up : styles.down
							)}
						/>
					</span>
				);
			default:
				return null;
		}
	};

	const special = {
		Show: (show, item) => {
			return (
				<div>
					<Checkbox
						checked={show}
						onClick={(e) => {
							e.stopPropagation();
							toggleShowItem(item.id);
						}}
					/>
				</div>
			);
		},
		Legend: (color) => {
			return (
				<div
					className={classes(styles.legend)}
					style={{
						background: color,
					}}
				/>
			);
		},
	};

	const listData = useMemo(() => {
		const newData = cloneDeep(data);
		return newData.sort((a, b) => {
			const aCPK = get(a, COL_MAP.Cpk);
			const bCPK = get(b, COL_MAP.Cpk);
			return cpkComparator(aCPK, bCPK, isAscendingSort);
		});
	}, [data, isAscendingSort]);
	return (
		<ListTable
			columns={COLUMNS}
			colMap={COL_MAP}
			renderColumn={renderColumn}
			data={listData.map((obj, idx) => {
				const rowStyles = getRowStyles(obj);
				return {
					...obj,
					idx: idx + 1,
					rowStyles,
					onClick: () => {
						applyFilters({
							PRODUCT_FILTER: [parseInt(obj.id, 10)],
						});
						history.push(
							`${urls.EXTENSION}${urls.QUALITY_ANALYSIS_LIST}`
						);
					},
				};
			})}
			special={special}
		/>
	);
};

StatisticalTable.defaultProps = {
	data: [],
};
const mapStateToProps = () => {};
const mapDispatchToProps = {
	applyFilters: analyticsAction.applyFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(StatisticalTable);
