import { ReceiptText } from 'lucide-react';
import { ArrowBack } from '@mui/icons-material';
import { Button } from '../ui/button';

const CreateQuoteHeader = (props) => {
	const { history, handleSave, editMode } = props;
	return (
		<div className='w-full flex justify-between items-center p-4   mb-2 border-b border-[1px solid #d1d1d1]'>
			<div className='flex gap-2 justify-center items-center'>
				<div
					onClick={() => {
						history.goBack();
					}}
					className='w-10 h-10 rounded-full hover:bg-slate-100 cursor-pointer flex justify-center items-center'
				>
					<ArrowBack />
				</div>
				<ReceiptText className='text-yellow-500' />
				<p className='text-xl font-medium'>
					{' '}
					{editMode ? 'Update/View' : 'Raise '}
					{' '}
					RFQ
				</p>
			</div>
			<Button className='primary-btn' onClick={handleSave}>
				{editMode ? 'Update' : 'Submit'}
				{' '}
				RFQ
			</Button>
		</div>
	);
};

export default CreateQuoteHeader;
