/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import ForecastingDashboard from '@/Pages/ForecastingAnalysisDashboard/ForecastingDashboard';
import ForecastingHistory from '@/Pages/ForecastingAnalysisDashboard/ForecastingHistory';
import ListForecasting from '@/Pages/Forecasting/ListForecasting';

const getSubURL = (entity) => {
	const baseEntity = urls.FORECASTING_ANALYTICS;
	return entity.slice(baseEntity.length);
};
const ForecastingAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route
					path={`${path}${getSubURL(urls.FORECASTING_ANALYSIS)}`}
					exact
				>
					<ForecastingDashboard />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.FORECASTING_ANALYSIS_LIST)}`}
					exact
				>
					<ListForecasting />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.FORECASTING_HISTORY_LIST)}`}
					exact
				>
					<ForecastingHistory />
				</Route>
			</Switch>
		</div>
	);
};

export default ForecastingAnalyticsRoutes;
