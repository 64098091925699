/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable  */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { Download, Visibility } from '@mui/icons-material';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import SelectPicker from 'rsuite/SelectPicker';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { ListTable } from '../../Components/ListTable';
import styles from './PettyCash.module.scss';
import { urls } from '../../Constants';
import financialTransactionsServices from '../../Services/Analytics Services/financial-transactions.services';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { notificationServices } from '../../Services';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { formActions, modalActions } from '../../Actions';
import { BackdropLoader } from '../../Components/Loaders';
import { handleExcelDownload } from '../../Helpers';
import useSearchFilters from '../../Helpers/useSearchFilters';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const statusList = [
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'PAID',
		title: 'PAID',
	},
	{
		id: 'UNPAID',
		title: 'UNPAID',
	},
	{
		id: 'PARTIALLY_PAID',
		title: 'PARTIALLY PAID',
	},
	{
		id: 'APPROVAL_PENDING_MANAGER',
		title: 'APPROVAL PENDING MANAGER',
	},
	{
		id: 'APPROVAL_PENDING_FINANCE',
		title: 'APPROVAL PENDING FINANCE',
	},
];

const timeTypeArr = [
	{
		label: 'Bill Date',
		value: 'transactionDate',
	},
	{
		label: 'Due Date',
		value: 'dueDate',
	},
];

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment Mode',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Transaction Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Transaction Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Due Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'L1 Action By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'L2 Action By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expense Sub Total',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expense tax slab',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expense tax amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expense Total',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Income Total',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ref. Number',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Reference doc',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	ID: 'id',
	'Payment Type': 'extraData',
	Vendor: 'vendorName',
	Store: 'storeName',
	Category: 'category',
	Status: 'transactionStatus',
	'Created By': 'initiatedByUserDisplayName',
	'Created At': 'createdAt',
	'Client Transaction Id': 'clientTransactionId',
	'Transaction Date': 'transactionDate',
	'Due Date': 'dueDate',
	'L1 Action By': 'l1ActionByUserDisplayName',
	'L2 Action By': 'l2ActionByUserDisplayName',
	'Payment Mode': 'extraData',
	'Expense tax slab': 'id',
	'Expense tax amount': 'id',
	'Expense Sub Total': 'id',
	'Expense Total': 'id',
	'Income Total': 'id',
	'Payment By': 'id',
	'Ref. Number': 'extraData',
	'Reference doc': 'uploadedDoc',
};

const searchQueries = [
	{
		id: 'transactionId',
		type: 'billId',
		generateTitle: (exlusiveKey) => {
			return `Id: ${exlusiveKey}`;
		},
		removable: true,
		exclusiveKey: 'clientTransactionId',
		dependentKey: '',
	},
];

const PettyCash = (props) => {
	const {
		togglePOItemsModal,
		navState,
		toggleAlert,
		stores,
		vendorsList,
		fetchAllVendors,
		fetchStoresList,
		userPermissions,
		fetchUserList,
		userList,
	} = props;
	const history = useHistory();
	const { userConfig } = useAuth();
	const [pettyCashList, setPettyCashList] = useState([]);
	const [update, setUpdate] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [subType, setSubtype] = useState('ALL');
	const navRequisites = navState?.[urls.PURCHASE_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});

	const { searchFilters } = useSearchFilters({
		searchQueries,
		callBackFn: () => {
			setUpdate((prev) => {
				return prev + 1;
			});
		},
	});

	// user Access
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/finance/management']?.submenu['/financial/petty']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Finance');
	// const billApproval = roleDetails.some((ele) => ele?.name === 'Finance');
	// const cxoApproval = roleDetails.some((ele) => ele?.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user access
	useEffect(() => {
		fetchAllVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					type: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const storeIdMap = useMemo(() => {
		const map = {};
		stores?.forEach((ele) => {
			map[ele?.id] = ele;
		});
		return map;
	}, [stores]);

	const vendorIdMap = useMemo(() => {
		const map = {};
		vendorsList?.forEach((ele) => {
			map[ele?.id] = ele;
		});
		return map;
	}, [vendorsList]);

	const handleStatus = async (data, rejectionReason, type) => {
		let status = '';
		if (data?.transactionStatus === 'APPROVAL_PENDING_MANAGER') {
			status = 'APPROVAL_PENDING_FINANCE';
		}
		if (data?.transactionStatus === 'APPROVAL_PENDING_FINANCE') {
			// if (paidPaymentModes?.includes(data?.extraData?.paymentMode)) {
			// status = 'PAID';
			// } else {
			// status = 'UNPAID';
			// }
			if (data?.extraData?.isPaid) {
				status = 'PAID';
			} else {
				status = 'UNPAID';
			}
		}
		if (type === 'rejection') {
			status = 'REJECTED';
		}
		if (type === 'cancelation') {
			status = 'CANCELLED';
		}
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: data?.id,
					},
					data: {
						status,
						type: 'BILL',
						// subType: ''
						nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.PETTY_CASH}?clientUtilityId=${data?.clientTransactionId}`,
						...(type === 'rejection' && {
							rejectionReason,
						}),
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
				togglePOItemsModal(false, {});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const getTaxFromStr = (tt) => {
		let num = 0;
		let taxStr = tt;
		if (!taxStr) return num;
		if (taxStr?.includes?.('%')) {
			taxStr = taxStr?.replace('%', '');
			if (!Number.isNaN(taxStr)) {
				num = Number(taxStr);
			}
		}
		return num;
	};
	const specialItems = {
		'Payment Type': (extraData) => {
			return extraData?.paymentType ?? '-';
		},
		'Expense tax slab': (id) => {
			const currentAdHoc = pettyCashList?.find((ele) => {
				return ele?.id === id;
			});
			if (currentAdHoc?.extraData?.paymentType !== 'Expense') {
				return '-';
			}
			const taxStr = currentAdHoc?.extraData?.taxSlab;
			return taxStr;
		},
		'Expense tax amount': (id, showRs = true) => {
			const currentAdHoc = pettyCashList?.find((ele) => {
				return ele?.id === id;
			});
			if (currentAdHoc?.extraData?.paymentType !== 'Expense') {
				return '-';
			}
			const taxStr = currentAdHoc?.extraData?.taxSlab;
			const tax = getTaxFromStr(taxStr);
			const beforeTax =
				currentAdHoc?.finalTransactionAmount / (1 + tax / 100);
			const value = (
				currentAdHoc?.finalTransactionAmount - beforeTax
			).toFixed(2);
			return `${showRs ? '\u20B9' : ''}${value}`;
		},
		'Expense Sub Total': (id, showRs = true) => {
			const currentAdHoc = pettyCashList?.find((ele) => {
				return ele?.id === id;
			});
			if (currentAdHoc?.extraData?.paymentType !== 'Expense') {
				return '-';
			}
			const taxStr = currentAdHoc?.extraData?.taxSlab;
			const tax = getTaxFromStr(taxStr);
			const beforeTax =
				currentAdHoc?.finalTransactionAmount / (1 + tax / 100);
			const value = beforeTax.toFixed(2);
			return `${showRs ? '\u20B9' : ''}${value}`;
		},
		'Expense Total': (id, showRs = true) => {
			const currentAdHoc = pettyCashList?.find((ele) => {
				return ele?.id === id;
			});
			if (currentAdHoc?.extraData?.paymentType !== 'Expense') {
				return '-';
			}
			return `${showRs ? '\u20B9' : ''}${(
				currentAdHoc?.finalTransactionAmount ?? 0
			).toFixed(2)}`;
		},
		'Income Total': (id) => {
			const currentAdHoc = pettyCashList?.find((ele) => {
				return ele?.id === id;
			});
			if (currentAdHoc?.extraData?.paymentType !== 'Income') {
				return '-';
			}
			return `\u20B9${currentAdHoc?.finalTransactionAmount}`;
		},
		'Payment Mode': (extraData) => {
			return (
				<div
					style={{
						textAlign: 'center',
					}}
				>
					{extraData?.paymentMode ?? '-'}
				</div>
			);
		},
		Status: (_, item) => {
			if (item?.transactionStatus === 'REJECTED') {
				return (
					<div className={styles.rejected}>
						<p>{item?.transactionStatus?.split('_')?.join(' ')}</p>
						<p>
							{item?.extraData?.rejectionReason
								? `Reason: (${item?.extraData?.rejectionReason})`
								: ''}
						</p>
					</div>
				);
			}
			return item?.transactionStatus?.split('_')?.join(' ');
		},
		'Payment By': (_, item) => {
			return item?.extraData?.isPaid ? 'Submitter' : 'Finance';
		},
		'Transaction Date': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Due Date': (date) => {
			if (!date) return '-';
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Created At': (date) => {
			if (!date) return '-';
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Reference doc': (uploadedDoc) => {
			if (!uploadedDoc) return '-';
			return (
				<div
					className={styles.reference}
					onClick={() => {
						if (uploadedDoc) {
							window.open(uploadedDoc, '_blank');
						} else {
							notificationServices.generateNotification({
								type: 'error',
								message: 'No Reference Uploaded',
							});
						}
					}}
				>
					<Download className={styles.icon} />
				</div>
			);
		},
		'Ref. Number': (extraData) => {
			return extraData?.referenceNumber;
		},
		Actions: (id) => {
			const item = pettyCashList?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles.btns}>
					{(item?.transactionStatus === 'APPROVAL_PENDING_MANAGER') ? (
						<Button
							variant='contained'
							color='primary'
							onClick={() => {
								handleApproveClick(item);
								// handleApproval('APPROVAL_PENDING_MANAGER', id);
							}}
							fullWidth
						>
							Manager Approval
						</Button>
					) : null}
					{(item?.transactionStatus === 'APPROVAL_PENDING_FINANCE') ? (
						<Button
							variant='contained'
							color='primary'
							onClick={() => {
								handleApproveClick(item);
								// handleApproval('APPROVAL_PENDING_FINANCE', id);
							}}
							fullWidth
						>
							Finance Approval
						</Button>
					) : null}
					{item?.transactionStatus !== 'CANCELLED' &&
					item?.transactionStatus !== 'APPROVAL_PENDING_FINANCE' &&
					item?.transactionStatus !== 'REJECTED' ? (
						<Button
							variant='outlined'
							color='error'
							onClick={() => {
								toggleAlert(true, {
									heading: `Cancel Ad-Hoc Expense/Income - ${item?.clientTransactionId}`,
									handleConfirm: () => {
										handleStatus(item, id, 'cancelation');
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Cancel Entry: ${
										item?.clientTransactionId || ''
									}?`,
									width: '28rem',
								});
							}}
							fullWidth
						>
							Cancel
						</Button>
					) : (
						'-'
					)}
				</div>
			);
		},
	};

	function handleApproveClick(item) {
		togglePOItemsModal(true, {
			heading: `Ad-Hoc Expense/Income #${
				item?.clientTransactionId ?? item?.id
			}`,
			...item,
			isApprove: true,
			advanceBill: true,
			approvalText: 'I have checked the Ad-Hoc Expense/Income Bill.',
			showTotal: false,
			showPdf: true,
			showPoDetails: true,
			width: '90%',
			height: '90%',
			txnHeader: {
				show: item?.extraData?.isPaid,
				heading: 'Transaction Already PAID',
				helper: 'Post Approval, a payment record will be generated',
			},
			transactionData: {
				'Client Transaction ID': item?.clientTransactionId,
				'Payment Type': item?.extraData?.paymentType,
				Store: item?.storeName,
				'Payment Mode': item?.extraData?.paymentMode,
				Vendor: item?.vendorName,
				...(item?.extraData?.paymentType !== 'Income' && {
					'Expense Sub Total': specialItems['Expense Sub Total']?.(
						item.id,
						true
					),
					'Expense Tax Slab': specialItems['Expense tax slab']?.(
						item.id,
						true
					),
					'Expense Tax Amount': specialItems['Expense tax amount']?.(
						item.id,
						true
					),
				}),
				'Transaction Amount': `\u20B9${item?.finalTransactionAmount}`,
				'Transaction Date': moment(item?.transactionDate)?.format(
					'DD-MM-YYYY'
				),
				'Due Date': moment(item?.dueDate)?.format('DD-MM-YYYY'),
				Status: item?.transactionStatus?.split('_')?.join(' '),
				'Ref. Number': item?.extraData?.referenceNumber ?? '-',
				Description: item?.description ?? '-',
				Category: item?.category ?? '-',
			},
			hideItems: true,
			excludedColumns: ['Used For'],
		});
	}

	const fetchPettyCashList = async (req) => {
		setIsLoading(true);
		const searchFilterPayload = {};
		searchFilters?.forEach((ele) => {
			if (ele?.value) {
				searchFilterPayload[ele?.id] = ele?.value;
			}
		});
		const filters = {
			subTypes: subType === 'ALL' ? ['EXPENSE', 'INCOME'] : [subType],
			status: req?.status,
			storeId: null,
		};
		if (req?.dateRange?.active) {
			filters.dateRangeFilter = {
				timeType: req?.timeType,
				from: moment(req?.dateRange?.from).format('YYYY-MM-DD'),
				to: moment(req?.dateRange?.to).format('YYYY-MM-DD'),
			};
		}
		try {
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							type: 'BILL',
							filters,
							...searchFilterPayload,
						},
					},
				});
			if (response?.statusCode === 200) {
				// setSelectedStore(store);
				setPettyCashList(response?.data);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
			// console.log('Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddClick = () => {
		const redirectUrl =
			navSelectedStore?.value?.id !== -999
				? `${urls.EXTENSION}${urls.PETTY_CASH}/add?toStore=${navSelectedStore?.value?.id}`
				: `${urls.EXTENSION}${urls.PETTY_CASH}/add`;
		history.push(redirectUrl);
	};

	const handleViewClick = (item) => {
		togglePOItemsModal(true, {
			heading: `Ad-Hoc Expense/Income #${
				item?.clientTransactionId ?? item?.id
			}`,
			...item,
			isApprove: false,
			advanceBill: false,
			approvalText: 'I have checked the Ad-Hoc Expense/Income Bill.',
			showTotal: false,
			showPdf: true,
			showPoDetails: true,
			width: '90%',
			height: '90%',
			transactionData: {
				'Client Transaction ID': item?.clientTransactionId,
				'Payment Type': item?.extraData?.paymentType,
				Store: item?.storeName,
				'Payment Mode': item?.extraData?.paymentMode,
				Vendor: item?.vendorName,
				'Transaction Amount': `\u20B9${item?.finalTransactionAmount}`,
				'Transaction Date': moment(item?.transactionDate)?.format(
					'DD-MM-YYYY'
				),
				'Due Date': moment(item?.dueDate)?.format('DD-MM-YYYY'),
				Status: item?.transactionStatus?.split('_')?.join(' '),
				'Ref. Number': item?.extraData?.referenceNumber ?? '-',
				Description: item?.description ?? '-',
				Category: item?.category ?? '-',
			},
			hideItems: true,
			excludedColumns: ['Used For'],
		});
	};

	const onExportClick = () => {
		const headers = TABLE_COLUMNS?.filter((ele) => {
			return ![]?.includes(ele?.title);
		})?.map((ele) => {
			return ele?.title;
		});
		headers.splice(14, 0, 'Rejection Reason');
		headers.splice(18, 1);
		headers.splice(18, 0, ...['IGST amount', 'CGST amount', 'SGST amount']);
		headers.splice(999, 0, ...['store gst', 'vendor gst']);
		const totalValues = [];
		pettyCashList?.forEach((pettyCashItem) => {
			if (pettyCashItem?.transactionStatus !== 'CANCELLED') {
				totalValues?.push([
					pettyCashItem?.id,
					pettyCashItem?.extraData?.paymentType,
					pettyCashItem?.storeName,
					pettyCashItem?.category ?? '-',
					pettyCashItem?.extraData?.paymentMode,
					pettyCashItem?.vendorName,
					pettyCashItem?.clientTransactionId,
					pettyCashItem?.transactionStatus,
					pettyCashItem?.initiatedByUserDisplayName,
					moment(pettyCashItem?.createdAt).format('YYYY-MM-DD'),
					(pettyCashItem?.extraData?.isPaid
						? 'Submitter'
						: 'Finance') ?? '-',
					pettyCashItem?.transactionDate,
					pettyCashItem?.dueDate,
					pettyCashItem?.l1ActionByUserDisplayName,
					pettyCashItem?.extraData?.rejectionReason ?? '-',
					pettyCashItem?.l2ActionByUserDisplayName,
					...(pettyCashItem?.extraData?.paymentType === 'Expense'
						? [
								specialItems['Expense Sub Total']?.(
									pettyCashItem.id,
									false
								),
						  ]
						: ['-']),
					...(pettyCashItem?.extraData?.paymentType === 'Expense'
						? [specialItems['Expense tax slab']?.(pettyCashItem.id)]
						: ['-']),
					...(pettyCashItem?.extraData?.paymentType === 'Expense' &&
					!pettyCashItem?.extraData?.isSameState
						? [
								specialItems['Expense tax amount']?.(
									pettyCashItem.id,
									false
								),
						  ]
						: [0]),
					...(pettyCashItem?.extraData?.paymentType === 'Expense' &&
					pettyCashItem?.extraData?.isSameState
						? [
								(
									Number(
										specialItems['Expense tax amount']?.(
											pettyCashItem.id,
											false
										)
									) / 2
								).toFixed,
						  ]
						: [0]),
					...(pettyCashItem?.extraData?.paymentType === 'Expense' &&
					pettyCashItem?.extraData?.isSameState
						? [
								(
									Number(
										specialItems['Expense tax amount']?.(
											pettyCashItem.id,
											false
										)
									) / 2
								).toFixed,
						  ]
						: [0]),
					...(pettyCashItem?.extraData?.paymentType === 'Expense'
						? [pettyCashItem?.finalTransactionAmount]
						: ['-']),
					...(pettyCashItem?.extraData?.paymentType === 'Income'
						? [pettyCashItem?.finalTransactionAmount]
						: ['-']),
					pettyCashItem?.extraData?.referenceNumber ?? '-',
					pettyCashItem?.uploadedDoc,
					pettyCashItem?.extraData?.paymentType === 'Expense'
						? storeIdMap?.[pettyCashItem?.storeId]?.gstNumber ?? '-'
						: '-',
					pettyCashItem?.extraData?.paymentType === 'Expense'
						? vendorIdMap?.[pettyCashItem?.vendorId]?.gstin ?? '-'
						: '-',
				]);
			}
		});
		handleExcelDownload(headers, totalValues, 'Ad-Hoc Expense/Income');
	};

	const getRowStyles = (status) => {
		return styles?.[status];
	};

	const footerComponent = () => {
		return (
			<div className={styles.footer}>
				<div className={styles.sub_type}>
					<label>Ad-Hoc Type:</label>
					<SelectPicker
						data={['ALL', 'EXPENSE', 'INCOME']?.map((ele) => {
							return {
								label: ele,
								value: ele,
							};
						})}
						value={subType}
						onChange={(subTypeVal) => {
							setSubtype(subTypeVal);
						}}
						cleanable={false}
						searchable={false}
						style={{
							width: 150,
						}}
						placeholder='Select Ad-Hoc'
					/>
				</div>
			</div>
		);
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} showProgress />
			<POItemsModal
				handleConfirm={handleStatus}
				handleReject={(data, rejectionReason) => {
					handleStatus(data, rejectionReason, 'rejection');
				}}
			/>
			<ImagesFinder
				pageLabel='Ad-Hoc Expense/Income'
				FILTERS={['dateRange', 'status', 'timeType']}
				onSubmit={fetchPettyCashList}
				statusList={statusList}
				additionInfo={{
					showAdd: true,
					addText: 'Ad-Hoc Expense/Income',
					prefix: 'Add',
					handleAddClick,
				}}
				timeTypeArray={timeTypeArr}
				update={update}
				footerComponent={footerComponent}
				externalFilters={searchFilters}
			/>
			<div className={styles.list}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={pettyCashList?.map((ele) => {
						return {
							...ele,
							rowStyles: getRowStyles(ele?.transactionStatus),
						};
					})}
					actions={[...SPECIAL_COLUMNS]}
					special={specialItems}
					handleViewClick={handleViewClick}
					onExportClick={onExportClick}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { navState, userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { userList } = state?.auth;

	return {
		stores,
		vendorsList,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	togglePOItemsModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
	fetchAllVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PettyCash);
