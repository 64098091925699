/* eslint-disable no-tabs */
/* eslint-disable block-spacing */
// import { useEffect } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';

const TABLE_COLUMNS = [
	{
		title: 'Franchisee Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Contact',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Login Pin',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Email',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Send Email',
		config: {
			type: 'DATA',
		},
	},
];
const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

const COLMAP = {
	'Franchisee Name': 'name',
	Contact: 'mobile',
	'Login Pin': 'fixedOTP',
	// 'Total Orders': 'totalOrders',
	// 'Total Order Value': 'totalOrderValue',
	Email: 'emailId',
};
const AddCustomersListPage = (props) => {
	const { fetchCustomers, data, query, sendDataToPrint } = props;
	const SPECIAL_ITEMS = {
		'Send Email': (phone) => {
			return (
				<IconButton
					onClick={() => {
						let dataToPrint = {};
						for (let i = 0; i < data.length; i++) {
							if (data[i].mobile === phone) {
								dataToPrint = data[i];
								break;
							}
						}
						sendDataToPrint({
							entity: 'MY_FRANCHISEE',
							req: {
								...dataToPrint,
							},
							method: 'PRINT',
						});
					}}
					size='large'
				>
					<EmailIcon />
				</IconButton>
			);
		},
	};

	useEffect(() => {
		fetchCustomers({
			entity: 'MY_FRANCHISEE',
			req: {
				params: {
					user: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className='root'>
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.map((customer) => {
						return {
							...customer,
							id: customer.mobile,
						};
					})
					.filter((customer) => {
						return (
							customer.name
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.MY_FRANCHISEE.data.LIST;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchCustomers: formActions.gateway,
	sendDataToPrint: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCustomersListPage);
