/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import AuditDashboard from '@/Pages/AuditDashboard/AuditDashboard';
import PastAuditAnalysisHistoryPage from '@/Pages/AuditDashboard/PastAuditAnalysisHistoryPage';
import AuditAnalysisHistory from '@/Pages/AuditDashboard/AuditAnalysisHistory';

const getSubURL = (entity) => {
	const baseEntity = urls.AUDIT_ANALYTICS;
	return entity.slice(baseEntity.length);
};
const AuditAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route path={`${path}${getSubURL(urls.AUDIT_ANALYSIS)}`} exact>
					<AuditDashboard />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.AUDIT_ANALYSIS_LIST)}`}
					exact
				>
					<PastAuditAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.AUDIT_ANALYSIS,
								name: 'Variance Analysis',
							},
							{
								link: '',
								name: 'Variance Analysis History List',
							},
						]}
					/>
				</Route>
				<Route
					path={`${path}${getSubURL(urls.AUDIT_HISTORY_LIST)}`}
					exact
				>
					<AuditAnalysisHistory />
				</Route>
			</Switch>
		</div>
	);
};

export default AuditAnalyticsRoutes;
