import { endpoints } from '../Constants';
import { authHeader } from '../Helpers';
import handleResponse from './helpers';

const shiftServices = {
	createShift,
	getShift,
	listShift,
	editShift,
	deleteShift,
	listProcessShifts,
};

function createShift(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.SHFIT_CREATE, requestOptions).then(handleResponse);
}

function getShift(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.SHIFT_GET, requestOptions).then(handleResponse);
}

function listShift(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.SHIFT_LIST, requestOptions).then(handleResponse);
}
function listProcessShifts(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.SHIFT_GET_PROCESS, requestOptions).then(handleResponse);
}

function editShift(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.SHIFT_EDIT, requestOptions).then(handleResponse);
}

function deleteShift(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.SHIFT_DELETE, requestOptions).then(handleResponse);
}

export default shiftServices;
