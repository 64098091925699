/* eslint-disable no-unreachable */
import { cloneDeep } from 'lodash';
import {
	getUnitConvertedQuantity,
	getUnitConvertedUnit,
} from '../../../Helpers';
import fallbackImage from '../../../Images/food.jpg';

// const RESULT_CODE = {
// 1: 'PASSED',
// 0: 'OVERRIDDEN',
// };

const QC_STATES = {
	PASSED: 'PASS',
	FAILED: 'FAIL',
	OVERRIDDEN: 'OVERRIDDEN',
	SKIPPED: 'SKIPPED',
};

function getResult(required, value) {
	if (required == null || required === false || value == null) {
		return null;
	}

	if (typeof value === 'string') {
		return value;
	}

	return value ? 'PASS' : 'FAIL';
}

function getMessage(result, type) {
	if (type === 'WASTAGE') {
		switch (result) {
			case 'PASSED':
				return 'Recorded';
			case 'FAILED':
				return 'Not recorded';
			case 'OVERRIDDEN':
				return 'Overridden Recorded';
			case 'SKIPPED':
				return 'Skipped';
			default:
				return null;
		}
	}
	switch (result) {
		case 'PASSED':
			return 'Approved For Delivery';
		case 'FAILED':
			return 'Product not approved for delivery';
		case 'OVERRIDDEN':
			return 'Overridden';
		case 'SKIPPED':
			return 'Skipped';
		default:
			return null;
	}
}

const getSensorValues = (sensorValues, containerRect, imgDimension) => {
	if (
		sensorValues?.length > 0 &&
		containerRect != null &&
		imgDimension?.width != null &&
		imgDimension?.height != null
	) {
		const scaleX = imgDimension.width / containerRect.width;
		const scaleY = imgDimension.height / containerRect.height;
		return sensorValues.map((temp, idx) => {
			const { value } = temp;
			const x = temp.x / scaleX;
			const y = temp.y / scaleY;
			return {
				x,
				y,
				width: temp.width / scaleX,
				// height: value.height / scaleY,
				key: idx + 1,
				stroke: 'red',
				id: temp?.id ?? null,
				labelId: temp?.labelId ?? null,
				value,
				class: value,
			};
		});
	}
	return null;
};

// eslint-disable-next-line no-unused-vars
const getWeightUnit = (weight, itemUnit, measurementType) => {
	let weightUnit = getUnitConvertedUnit(weight, itemUnit);

	if (itemUnit === 'count') {
		if (measurementType === 'US') {
			weightUnit = getUnitConvertedUnit(weight, 'oz');
		} else {
			weightUnit = getUnitConvertedUnit(weight, 'g');
		}
	}
	return weightUnit;
};

function liveSerializer(state, data) {
	let { name } = state.product;
	const { imgDimension, containerRect } = data;

	if (data?.productName != null) {
		name = data?.productName;
		if (data?.variantName != null) {
			name += ` - ${data?.variantName}`;
		}
	}

	const weightValue =
		data?.Weight ?? state?.measurements?.weight?.values?.[0]?.value ?? null;

	let tempValue1 = null;
	let temp1Color = null;
	let tempValue2 = null;
	let temp2Color = null;
	tempValue1 =
		data?.Temp ?? state?.measurements?.temp?.values?.[0]?.value ?? null;
	tempValue2 =
		data?.otherTemp ??
		state?.measurements?.temp?.values?.[1]?.value ??
		null;
	temp1Color = tempValue1 >= tempValue2 ? 'red' : 'blue';
	temp2Color = tempValue1 < tempValue2 ? 'red' : 'blue';

	const weightCheckRequired =
		data?.weightCheckRequired ?? state.measurements.weight.required;

	const weightResult = getResult(
		weightCheckRequired,
		data?.weightInRange ?? state.measurements.weight.result
	);

	const tempCheckRequired =
		data?.tempCheckRequired ?? state.measurements.temp.required;

	const tempResult = getResult(
		tempCheckRequired,
		data?.tempInRange ?? state.measurements.temp.result
	);

	const qcDone = true;

	return {
		totalQCS: data?.total_qcs ?? state.totalQCS,
		passedQCS: data?.passed_qcs ?? state.passedQCS,
		orderId: data?.orderId ?? state.orderId,
		itemId: data?.itemId ?? state.itemId,
		updatedAt: data?.updated_at ?? state.updatedAt,
		passedPercentage: data?.pass_percentage ?? state.passedPercentage,
		lastQcAt: data?.lastQcAt ?? state.lastQcAt,
		currentDate: data?.current_date ?? state.currentDate,
		ranges: {
			weight: {
				min: data?.MinWeight ?? state.ranges.weight.min,
				max: data?.MaxWeight ?? state.ranges.weight.max,
			},
			temp: {
				min: data?.MinTemp ?? state.ranges.temp.min,
				max: data?.MaxTemp ?? state.ranges.temp.max,
			},
		},
		measurements: {
			weight: {
				live: data?.live_weight ?? state.measurements.weight.live,
				values: [
					{
						size: 'lg',
						value:
							weightValue >= 1
								? weightValue?.toFixed(3)
								: (weightValue * 1000)?.toFixed(1),
						color: null,
					},
				],
				connected:
					data?.weighingScale ?? state.measurements.weight.connected,
				result: weightResult,
				required: weightCheckRequired,
				// unit: data?.weight?.unit ?? state.measurements.weight.unit,
				unit: weightValue >= 1 ? 'kg' : 'g',
				piecesCount: data?.piecesCount,
			},
			temp: {
				live: data?.live_temp ?? state.measurements.temp.live,
				values: [
					{
						size: 'lg',
						value: tempValue1?.toFixed(2),
						color: temp1Color,
					},
					{
						size: 'md',
						value: tempValue2?.toFixed(2),
						color: temp2Color,
					},
				],
				connected:
					data?.TempSensor ?? state.measurements.temp.connected,
				result: tempResult,
				required: tempCheckRequired,
				unit: '℃',
				sensorValues: getSensorValues(
					Object?.values(data?.tempMapping ?? {}),
					containerRect,
					imgDimension
				),
			},
			camera: {
				connected:
					data?.foodCamera ?? state.measurements.camera.connected,
				image: data?.foodImage ?? state.measurements.camera.image,
			},
		},
		product: {
			image: data?.refrenceImage ?? state.product.image,
			name,
			isVeg: data?.isVeg ?? state.product.isVeg,
			brand: data?.brandImage ?? state.product.brand,
			brandId: data?.brandId ?? state.product.brandId,
		},
		qcDone,
		result: QC_STATES?.[data?.QC_Result] ?? state.result,
		qrCode: data?.qrCode ?? state?.qrCode,
		message: getMessage(data?.QC_Result ?? '') ?? state.message,
		machine: {
			status: data?.state?.toUpperCase() ?? state.machine.status,
		},
		resetData: data?.resetData,
		nutrition: data?.nutrition ?? state?.nutrition,
		type: data?.type,
		fromStoreName: data?.fromStoreName,
		features: data?.features,
		identity: data?.identity,
		wastageDate: data?.wastageDate,
		machineName: data?.machineName,
		scanType: data?.scanType,
		processName: data?.processName,
		isRTV: data?.returned,
		poId: data?.poId,
		expiryTime: data?.expiryTime,
	};
}

function pastSerializer(state, data) {
	let { name } = JSON.parse(JSON.stringify(state.product));
	const { containerRect, imgDimension, measurementType } = data;

	if (data?.ProductName != null) {
		name = data?.ProductName;
		if (data?.variantName != null) {
			name += ` - ${data?.variantName}`;
		}
	}

	// return {};
	const weightValue = data?.Weight ?? null;

	let tempValue1 = null;
	let temp1Color = null;
	let tempValue2 = null;
	let temp2Color = null;
	// const isTempMax = data?.isTempMax ?? true;
	// tempValue1 = data?.Temp;
	// tempValue2 = data?.otherTemp;
	// temp1Color = 'red';
	// temp2Color = 'blue';

	tempValue1 = data?.Temp ?? null;
	tempValue2 = data?.otherTemp ?? null;
	temp1Color = tempValue1 >= tempValue2 ? 'red' : 'blue';
	temp2Color = tempValue1 < tempValue2 ? 'red' : 'blue';

	const weightCheckRequired =
		data?.WeightCheckRequired ?? state.measurements.weight.required;

	const weightResult = getResult(
		weightCheckRequired,
		data?.IsWeightInRange ?? state.measurements.weight.result
	);

	const tempCheckRequired =
		data?.TempCheckRequired ?? state.measurements.temp.required;

	const tempResult = getResult(
		tempCheckRequired,
		data?.IsTempInRange ?? state.measurements.temp.result
	);

	const result = QC_STATES?.[data?.result] ?? state.result;

	const qcDone = true;

	let itemUnit = data?.unit;

	if (itemUnit === 'count') {
		if (measurementType === 'US') {
			itemUnit = 'oz';
		} else {
			itemUnit = 'gram';
		}
	}

	return {
		totalQCS: data?.total_qcs ?? state.totalQCS,
		passedQCS: data?.passed_qcs ?? state.passedQCS,
		orderId: data?.OrderId ?? state.orderId,
		itemId: data?.itemId ?? state.itemId,
		updatedAt: null,
		passedPercentage: data?.pass_percentage ?? state.passedPercentage,
		lastQcAt: data?.QCTime ?? state.lastQcAt,
		currentDate: data?.QCTime?.split(' ')?.[0] ?? state.currentDate,
		ranges: {
			weight: {
				min:
					getUnitConvertedQuantity(
						data?.MinWeight * (data?.piecesCount ?? 1),
						itemUnit
					) ?? state.ranges.weight.min,
				minUnit: getUnitConvertedUnit(
					data?.MinWeight * (data?.piecesCount ?? 1),
					itemUnit
				),
				max:
					getUnitConvertedQuantity(
						data?.MaxWeight * (data?.piecesCount ?? 1),
						itemUnit
					) ?? state.ranges.weight.max,
				maxUnit: getUnitConvertedUnit(
					data?.MaxWeight * (data?.piecesCount ?? 1),
					itemUnit
				),
			},
			temp: {
				min: data?.MinTemp ?? state.ranges.temp.min,
				max: data?.MaxTemp ?? state.ranges.temp.max,
			},
		},
		measurements: {
			weight: {
				live: null,
				values: [
					{
						size: data?.piecesCount ? 'md' : 'lg',
						value: getUnitConvertedQuantity(weightValue, itemUnit),
						color: null,
						rawValue: data?.Weight,
					},
				],
				connected: true,
				result: weightResult,
				required: weightCheckRequired,
				// unit: data?.weight?.unit ?? state.measurements.weight.unit,
				piecesCount: data?.piecesCount,
				unit: getUnitConvertedUnit(weightValue, itemUnit),
				rawUnit: data?.unit,
			},
			temp: {
				live: null,
				values: [
					{
						size: 'lg',
						value: tempValue1?.toFixed(2),
						color: temp1Color,
						rawValue: data?.Temp,
					},
					{
						size: 'md',
						value: tempValue2?.toFixed(2),
						color: temp2Color,
						rawValue: data?.otherTemp,
					},
				],
				connected: true,
				result: tempResult,
				required: tempCheckRequired,
				unit: measurementType === 'US' ? '°F' : '℃',
				sensorValues: getSensorValues(
					Object?.values(data?.tempMapping ?? {}),
					containerRect,
					imgDimension
				),
			},
			camera: {
				connected: true,
				image:
					result === 'SKIPPED'
						? fallbackImage
						: data?.FoodImage ?? fallbackImage,
			},
		},
		product: {
			id: data?.productId ?? state.product.id,
			image: data?.ProductImage ?? state.product.image,
			name,
			isVeg: data?.isVeg ?? state.product.isVeg,
			brand: data?.BrandImage ?? state.product.brand,
			brandId: data?.brandId ?? state.product.brandId,
		},
		qcDone,
		result,
		qrCode: null,
		message: getMessage(data?.result ?? '', data?.type) ?? state.message,
		machine: {
			status: 'ONLINE',
		},
		resetData: false,
		validated: data?.validation,
		annotated: data?.annotated,
		features: data?.features,
		identity: data?.identity,
		identification: data?.identification,
		nutrition: data?.nutrition ?? state?.nutrition,
		duplicate: data?.duplicate,
		duplicateActionBy: data?.duplicateActionBy,
		wastageReason: data?.wastageReason ?? '',
		type: data?.type,
		fromStoreName: data?.fromStoreName,
		wastageDate: data?.wastageDate,
		container: {
			...data?.container,
			unit: measurementType === 'US' ? 'oz' : 'g',
		},
		weightByComponents: data?.weightByComponents ?? null,
		machineName: data?.machineName,
		scanType: data?.scanType,
		processName: data?.processName,
		isRTV: data?.returned,
		poId: data?.poId,
		expiryTime: data?.expiryTime,
		clientPoId: data?.clientPoId,
		stockedOutAt: data?.stockedOutAt,
		// container: data?.container ?? {
		// id: 7,
		// image: 'https://resoee.s3.amazonaws.com/Blue_Medium.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230706%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230706T092827Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=af0592cea86cf1ccabb1787070b89a11f21e1cca104272423cd61092a0ceb8b9',
		// name: 'Blue Small',
		// type: 'INWARD',
		// weight: 1500,
		// },
	};
}

const config = {
	initialState: {
		stores: [],
		selectedStore: {
			storeId: null,
			storeName: '',
		},
		ranges: {
			weight: {
				min: 0,
				max: 10000,
			},
			temp: {
				min: -20,
				max: 120,
			},
		},
		measurements: {
			weight: {
				live: null,
				values: [],
				connected: true,
				result: null,
				required: false,
				unit: 'g',
				piecesCount: null,
			},
			temp: {
				live: null,
				values: [],
				connected: true,
				result: null,
				required: false,
				unit: '℃',
				sensorValues: [],
			},
			camera: {
				connected: true,
				image: fallbackImage,
			},
		},
		product: {
			id: null,
			image: fallbackImage,
			name: '',
			isVeg: null,
			brand: null,
			brandId: null,
		},
		machine: {
			status: 'OFFLINE',
		},
		qrCode: null,
		qcDone: false,
		result: null,
		resetData: null,
		message: '',
		qcStatusPollingId: null,
		lastQcAt: '',
		currentDate: '',
		totalQCS: 0,
		passedPercentage: 0,
		passedQCS: 0,
		orderId: '',
		itemId: '',
		updatedAt: '',
		validated: false,
		annotated: false,
		features: null,
		identity: null,
		identification: null,
		nutrition: {},
		duplicate: false,
		recipe: [],
		wastageReason: null,
		wastageReasons: [],
		type: '',
		fromStoreName: null,
		wastageDate: null,
		containerId: null,
		machineName: null,
		scanType: null,
		processName: null,
		isRTV: null,
		poId: null,
		expiryTime: null,
		clientPoId: null,
		stockedOutAt: null,
	},
	serializer: (state, data, live = true) => {
		const clonedState = cloneDeep(state);
		if (live) {
			return liveSerializer(clonedState, data);
		}
		return pastSerializer(clonedState, data);
	},
	liveSerializer,
	pastSerializer,
};

export default config;
