import { useRouteMatch } from 'react-router';
import NutritionalListPage from '../../../Pages/Food Products/Nutrition Form/NutritionListPage';
// eslint-disable-next-line object-curly-newline
import NutritionalEditPage from '../../../Pages/Food Products/Nutrition Form/NutritionEditPage';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';
import NutritionalViewPage from '../../../Pages/Food Products/Nutrition Form/NutritionalViewPage';
import { urls } from '../../../Constants';

const NutritionalRoutes = (props) => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.NUTRITIONAL_INFO}`,
			name: 'Nutritional Info',
		},
	];
	return (
		<CRUDRoutes
			{...props}
			entity='NUTRITION'
			path={path}
			listPage={NutritionalListPage}
			editPage={<NutritionalEditPage breadcrumbs={breadcrumbs} />}
			viewPage={
				<NutritionalViewPage isDisabled breadcrumbs={breadcrumbs} />
			}
		/>
	);
};

export default NutritionalRoutes;
