/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { Edit } from '@mui/icons-material';
import React, {
	forwardRef, useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import { Download, Upload } from 'lucide-react';
import {
	classes, csvArrayToObject, getOurProductUnit, handleExcelDownload, parseCSV,
	reverseUnitAndQtyNew
} from '../../Helpers';
// import { notificationServices } from '../../Services';
import styles from './POItemSelection.module.scss';
import TableRow from './TableRow/TableRow';
import config from '../../Pages/Analytics/CreatePurchaseOrder/CreatePurchaseOrder.config';
import { useAuth } from '../Auth/Login/auth';
import { Button } from '../ui/button';
import UploadDataModal from '../Modals/UploadDataModal/UploadDataModal';
import { modalActions } from '@/Actions';
import excel from '../../Images/excel.svg';
import { notificationServices } from '@/Services';

const defaultColumns = [
	{
		title: 'ITEM DETAILS',
		icon: false,
		width: '30%',
	},
	{
		title: 'UNIT',
		icon: false,
		width: false,
	},
	{
		title: 'QTY-INFO',
		icon: false,
		width: false,
	},
	{
		title: 'EXPECTED LEFT OVER',
		icon: false,
		width: '100px',
	},
	{
		title: 'QUANTITY',
		icon: <Edit className={styles.icon} />,
		width: '150px',
	},
	{
		title: 'RATE',
		icon: <Edit className={styles.icon} />,
		width: '150px',
	},
	{
		title: 'Discount%',
		icon: <Edit className={styles.icon} />,
		width: '120px',
	},
	{
		title: 'Total Discount',
		icon: false,
		width: '125px',
	},
	{
		title: 'TAX %',
		icon: false,
		width: '100px',
	},
	{
		title: 'Sub total',
		icon: false,
		width: '100px',
	},
	{
		title: 'Total Tax',
		icon: false,
		width: '100px',
	},
	{
		title: 'Total Amount',
		icon: false,
		width: '100px',
	},
];

const capexOrderColumns = [
	{
		title: 'S.No',
		icon: false,
		width: '10%',
	},
	{
		title: 'ITEM DETAILS',
		icon: false,
		width: '20%',
	},
	{
		title: 'SPECIFICATIONS',
		icon: <Edit className={styles.icon} />,
		width: '30%',
	},
	{
		title: 'QUANTITY',
		icon: <Edit className={styles.icon} />,
		width: '100px',
	},
	{
		title: 'RATE',
		icon: <Edit className={styles.icon} />,
		width: '100px',
	},
	{
		title: 'TAX %',
		icon: <Edit className={styles.icon} />,
		width: '50px',
	},
	{
		title: 'Sub total',
		icon: false,
		width: '100px',
	},
	{
		title: 'Total Tax',
		icon: false,
		width: '100px',
	},
	{
		title: 'Total Amount',
		icon: false,
		width: '100px',
	},
];

const getProductInputUnit = (productUnit, qty) => {
	if (qty === null && productUnit === 'gram') {
		return {
			productInputUnit: 'kg',
			productQtyValue: null,
		};
	}
	if (qty === null && (productUnit === 'litre' || productUnit === 'ml')) {
		return {
			productInputUnit: 'ltr',
			productQtyValue: null,
		};
	}
	if (qty === null && productUnit === 'oz') {
		return {
			productInputUnit: 'lbs',
			productQtyValue: null,
		};
	}
	if (productUnit === 'gram') {
		if (Math.floor(qty / 1000) > 0) {
			return {
				productInputUnit: 'kg',
				productQtyValue: qty / 1000,
			};
		}
		return {
			productInputUnit: 'g',
			productQtyValue: qty,
		};
	}
	return {
		productInputUnit: productUnit,
		productQtyValue: qty,
	};
};

const POItemSelection = forwardRef((props, ref) => {
	const {
		productsList,
		state,
		setState,
		skuStockInfo,
		productWiseMappingData,
		requiredFields,
		handleGenerateStandardPO,
		disabled,
		ratePerString,
		toggleUploadDataModal,
		vendorPrices = {},
		defaultSelectedItems = [],
		filterColumns = ['EXPECTED LEFT OVER'],
		isDeliveryChallan = false,
		rowError = {},
		setRowError = () => {},
		typeId,
		qtyRegex = null,
		customComponents = {},
		taxApplied = {
			isApplied: true,
			reason: '',
		},
	} = props;
	const [quantity, setQuantity] = useState(null);
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;

	const handleItemSelection = (newValue) => {
		if (newValue) {
			let itemQty = quantity;
			const itemMoq = newValue?.selectedVariant?.pieces?.split(',')?.[0];
			let itemPrice =
				vendorPrices?.[newValue?.selectedVariant?.id] ??
				newValue?.selectedVariant?.price;
			if (newValue?.unit === 'gram' || newValue?.unit === 'ml') {
				itemPrice *= 1000;
			}
			if (newValue?.unit === 'oz') {
				itemPrice *= 16;
			}
			if (newValue?.unit === 'floz') {
				itemPrice *= 128;
			}
			if (typeId === 3 && itemMoq) {
				itemQty = Number(itemMoq);
			}
			if (!itemQty || itemQty === 0) {
				setRowError((prev) => {
					if (prev?.[newValue?.selectedVariant?.id]) {
						return {
							...prev,
							[newValue?.selectedVariant?.id]: {
								...prev?.[newValue?.selectedVariant?.id],
								quantity: true,
							},
						};
					}
					return {
						...prev,
						[newValue?.selectedVariant?.id]: {
							quantity: true,
						},
					};
				});
			}
			if (!itemPrice) {
				setRowError((prev) => {
					if (prev?.[newValue?.selectedVariant?.id]) {
						return {
							...prev,
							[newValue?.selectedVariant?.id]: {
								...prev?.[newValue?.selectedVariant?.id],
								rate: true,
							},
						};
					}
					return {
						...prev,
						[newValue?.selectedVariant?.id]: {
							rate: true,
						},
					};
				});
			}
			const { productInputUnit, productQtyValue } = getProductInputUnit(
				newValue?.unit,
				itemQty
			);
			const subTotal = config.getSubTotal(
				itemPrice,
				0,
				productQtyValue,
				productInputUnit
			);
			let totalTax = 0;
			if (taxApplied?.isApplied) {
				totalTax = config.getTotalTax(
					itemPrice,
					0,
					newValue?.tax,
					productQtyValue,
					productInputUnit
				);
			}
			const totalDiscount = config.getTotalDiscount(
				itemPrice,
				0,
				productQtyValue,
				productInputUnit
			);
			const deepCopy = JSON.parse(JSON.stringify(newValue));
			const newItem = {
				id: deepCopy?.selectedVariant?.id ?? deepCopy?.id,
				disabledPrice: !!vendorPrices?.[deepCopy?.selectedVariant?.id],
				productId: deepCopy?.id,
				productName: deepCopy?.productName,
				productImageUrl: deepCopy?.productImageUrl,
				variants: deepCopy?.variants,
				quantity: productQtyValue,
				unit: deepCopy?.unit,
				selectedVariant: {
					...deepCopy?.selectedVariant,
					price: itemPrice,
				},
				discountPercentage: 0,
				skuType: deepCopy?.skuType,
				tax: deepCopy?.tax,
				subTotal,
				coreSpecifications: deepCopy?.coreSpecifications,
				oems: deepCopy?.assetCategory?.oems?.map((oem) => ({
					...oem,
					client: null,
				})),
				totalTax,
				totalDiscount,
				totalPriceIncTax: config.getTotalPrice(subTotal, totalTax),
				productInputUnit,
			};
			setState([...state, newItem]);
			// setQuantity(1);
		}
	};
	const handleItemsListSelection = (selectedItems) => {
		const newSelectedItemsState = [];
		selectedItems?.forEach((ele, index) => {
			let productDetails = null;
			if (!ele?.productId && ele?.variantId) {
				productDetails = productsList?.find((prod) => {
					return prod?.variants?.find((vrnt) => {
						return vrnt?.id === ele?.variantId;
					});
				});
			} else if (ele?.productId) {
				productDetails = productsList?.find((prod) => {
					return prod?.id === ele?.productId;
				});
			}
			// const { productInputUnit, productQtyValue } = getProductInputUnit(
			// productDetails?.unit,
			// ele?.qty
			// );
			if (!productDetails) {
				// eslint-disable-next-line no-console
				console.log('error missing productDetails', ele);
				// eslint-disable-next-line no-console
				console.log('error missing selectedItems', selectedItems);
				notificationServices.generateNotification({
					type: 'error',
					message: `Missing product details at index ${index + 1}, Json: ${JSON.stringify(ele)}`,
				});
				return;
			}
			const variantDetails = productDetails?.variants?.find(
				(vrnt) => {
					return vrnt?.id === ele?.variantId;
				}
			);
			const selectedVariant = variantDetails;
			if (selectedVariant) {
				if (ele?.price) {
					selectedVariant.price = ele?.price;
				}
				if (ele?.productCost) {
					selectedVariant.price = ele?.productCost;
				}
			}
			const itemPrice =
					ele?.variantVendorPrice ?? selectedVariant?.price;
			const subTotal = config.getSubTotal(
				itemPrice,
				ele?.discountPercentage,
				ele?.qty,
				ele?.productInputUnit ?? ele?.unit
			);
			let totalTax = 0;
			if (taxApplied?.isApplied) {
				totalTax = config.getTotalTax(
					itemPrice,
					ele?.discountPercentage,
					productDetails?.tax,
					ele?.qty,
					ele?.productInputUnit ?? ele?.unit
				);
			}
			const totalDiscount = config.getTotalDiscount(
				itemPrice,
				ele?.discountPercentage,
				ele?.qty,
				ele?.productInputUnit ?? ele?.unit
			);
			const newItem = {
				id: variantDetails?.id,
				productId: productDetails?.id,
				productName: productDetails?.productName,
				productImageUrl: productDetails?.productImageUrl,
				variants: productDetails?.variants,
				quantity: ele?.qty,
				unit: productDetails?.unit,
				selectedVariant: {
					...selectedVariant,
					discountPercentage: ele?.discountPercentage,
					price: itemPrice,
				},
				skuType: productDetails?.skuType,
				discountPercentage: ele?.discountPercentage
					? ele?.discountPercentage
					: 0,
				tax: productDetails?.tax,
				subTotal,
				totalTax,
				totalDiscount,
				totalPriceIncTax: config.getTotalPrice(subTotal, totalTax),
				productInputUnit: ele?.productInputUnit ?? ele?.unit,
				disabledPrice: ele?.disabledPrice,
			};
			newSelectedItemsState?.push(newItem);
		});
		setState([...newSelectedItemsState]);
	};
	useEffect(() => {
		if (defaultSelectedItems && defaultSelectedItems?.length > 0) {
			handleItemsListSelection(defaultSelectedItems);
		}
	}, [defaultSelectedItems, productsList]);

	const handleQuantity = (id, value, idx = null) => {
		if (!id) {
			setQuantity(value);
		} else {
			if (!value || !Number(value)) {
				setRowError((prev) => {
					if (prev?.[id]) {
						return {
							...prev,
							[id]: {
								...prev?.[id],
								quantity: true,
							},
						};
					}
					return {
						...prev,
						[id]: {
							quantity: true,
						},
					};
				});
			} else {
				setRowError((prev) => {
					if (prev?.[id]) {
						return {
							...prev,
							[id]: {
								...prev?.[id],
								quantity: false,
							},
						};
					}
					return {
						...prev,
						[id]: {
							quantity: false,
						},
					};
				});
			}
			if (idx !== null) {
				const newItem = state?.[idx];
				newItem.quantity = value;
				const itemQty = Number(value);
				const subTotal = config.getSubTotal(
					newItem?.selectedVariant?.price,
					newItem?.discountPercentage,
					itemQty,
					newItem?.productInputUnit
				);
				let totalTax = 0;
				if (taxApplied?.isApplied) {
					totalTax = config.getTotalTax(
						newItem?.selectedVariant?.price,
						newItem?.discountPercentage,
						newItem?.tax,
						itemQty,
						newItem?.productInputUnit
					);
				}
				const totalDiscount = config.getTotalDiscount(
					newItem?.selectedVariant?.price,
					newItem?.discountPercentage,
					itemQty,
					newItem?.productInputUnit
				);
				newItem.subTotal = subTotal;
				newItem.totalTax = totalTax;
				newItem.totalDiscount = totalDiscount;
				newItem.totalPriceIncTax = config.getTotalPrice(
					subTotal,
					totalTax
				);
				setState([...state]);
				return;
			}
			const newState = state?.map((ele) => {
				if (ele?.id === id) {
					const itemQty = Number(value);
					const subTotal = config.getSubTotal(
						ele?.selectedVariant?.price,
						ele?.discountPercentage,
						itemQty,
						ele?.productInputUnit
					);
					let totalTax = 0;
					if (taxApplied?.isApplied) {
						totalTax = config.getTotalTax(
							ele?.selectedVariant?.price,
							ele?.discountPercentage,
							ele?.tax,
							itemQty,
							ele?.productInputUnit
						);
					}
					const totalDiscount = config.getTotalDiscount(
						ele?.selectedVariant?.price,
						ele?.discountPercentage,
						itemQty,
						ele?.productInputUnit
					);
					return {
						...ele,
						quantity: value,
						subTotal,
						totalTax,
						totalDiscount,
						totalPriceIncTax: config.getTotalPrice(
							// ele?.selectedVariant?.price,
							// ele?.discountPercentage,
							// ele?.tax,
							// Number(value),
							// ele?.unit
							subTotal,
							totalTax
						),
					};
				}
				return {
					...ele,
				};
			});
			setState(newState);
		}
	};
	const handleItemDescription = (id, value) => {
		const newState = state?.map((ele) => {
			if (ele?.id === id) {
				return {
					...ele,
					description: value,
				};
			}
			return {
				...ele,
			};
		});
		setState(newState);
	};

	const handleRemove = (product, removedIdx) => {
		const newState = state?.filter((ele, idx) => {
			if (typeId === 4) {
				if (idx === removedIdx) return false;
				return true;
			}
			return ele?.id !== product?.id;
		});
		if (rowError?.[product?.id]) {
			setRowError((prev) => {
				if (prev?.[product?.id]) {
					return {
						...prev,
						[product?.id]: {},
					};
				}
				return {
					...prev,
				};
			});
		}
		setState(newState);
	};

	const handleRate = (id, value, idx = null) => {
		// eslint-disable-next-line no-useless-escape
		// const reg = new RegExp('^[0-9]+$');
		if (!value || !Number(value)) {
			setRowError((prev) => {
				if (prev?.[id]) {
					return {
						...prev,
						[id]: {
							...prev?.[id],
							rate: true,
						},
					};
				}
				return {
					...prev,
					[id]: {
						rate: true,
					},
				};
			});
		} else {
			setRowError((prev) => {
				if (prev?.[id]) {
					return {
						...prev,
						[id]: {
							...prev?.[id],
							rate: false,
						},
					};
				}
				return {
					...prev,
					[id]: {
						rate: false,
					},
				};
			});
		}
		if (idx !== null) {
			const newItem = state?.[idx];
			newItem.selectedVariant.price = value;
			const subTotal = config.getSubTotal(
				Number(value),
				newItem?.discountPercentage,
				newItem?.quantity,
				newItem?.productInputUnit
			);
			let totalTax = 0;
			if (taxApplied?.isApplied) {
				totalTax = config.getTotalTax(
					Number(value),
					newItem?.discountPercentage,
					newItem?.tax,
					newItem?.quantity,
					newItem?.productInputUnit
				);
			}
			const totalDiscount = config.getTotalDiscount(
				Number(value),
				newItem?.discountPercentage,
				newItem?.quantity,
				newItem?.productInputUnit
			);
			newItem.subTotal = subTotal;
			newItem.totalTax = totalTax;
			newItem.totalDiscount = totalDiscount;
			newItem.totalPriceIncTax = config.getTotalPrice(subTotal, totalTax);
			setState([...state]);
			return;
		}
		if (id && value !== '') {
			const newState = state?.map((ele) => {
				if (ele?.id === id) {
					const subTotal = config.getSubTotal(
						Number(value),
						ele?.discountPercentage,
						ele?.quantity,
						ele?.productInputUnit
					);
					let totalTax = 0;
					if (taxApplied?.isApplied) {
						totalTax = config.getTotalTax(
							Number(value),
							ele?.discountPercentage,
							ele?.tax,
							ele?.quantity,
							ele?.productInputUnit
						);
					}
					const totalDiscount = config.getTotalDiscount(
						Number(value),
						ele?.discountPercentage,
						ele?.quantity,
						ele?.productInputUnit
					);
					return {
						...ele,
						selectedVariant: {
							...ele?.selectedVariant,
							price: value,
						},
						subTotal,
						totalTax,
						totalDiscount,
						totalPriceIncTax: config.getTotalPrice(
							subTotal,
							totalTax
						),
					};
				}
				return {
					...ele,
				};
			});
			setState(newState);
		}
	};
	const handleDiscount = (id, value) => {
		if (id) {
			const newState = state?.map((ele) => {
				const subTotal = config.getSubTotal(
					ele?.selectedVariant?.price,
					parseFloat(value),
					ele?.quantity,
					ele?.productInputUnit
				);
				let totalTax = 0;
				if (taxApplied?.isApplied) {
					totalTax = config.getTotalTax(
						ele?.selectedVariant?.price,
						parseFloat(value),
						ele?.tax,
						ele?.quantity,
						ele?.productInputUnit
					);
				}
				const totalDiscount = config.getTotalDiscount(
					ele?.selectedVariant?.price,
					parseFloat(value),
					ele?.quantity,
					ele?.productInputUnit
				);
				if (ele?.id === id) {
					return {
						...ele,
						discountPercentage: value,
						subTotal,
						totalTax,
						totalDiscount,
						totalPriceIncTax: config.getTotalPrice(
							subTotal,
							totalTax
						),
					};
				}
				return {
					...ele,
				};
			});
			setState(newState);
		}
	};

	const handleChangeVariant = (id, variant) => {
		const newState = state?.map((ele) => {
			if (ele?.id === id) {
				return {
					...ele,
					id: variant?.id,
					selectedVariant: variant,
					totalPriceIncTax: config.getTotalPrice(
						ele?.subTotal,
						ele?.totalTax
					),
				};
			}
			return {
				...ele,
			};
		});
		setState(newState);
	};

	const getRowStyles = (id, idx) => {
		if (idx % 2 === 0) {
			return styles.even;
		}
		return '';
	};
	const tableColumns = (
		typeId === 4 || typeId === 7
			? capexOrderColumns?.filter((colEle) => {
				if (colEle?.title === 'SPECIFICATIONS' && typeId === 7) {
					return false;
				}
				return true;
			})
			: defaultColumns
	)?.filter((colEle) => {
		if (!filterColumns?.length) {
			return true;
		}
		return !filterColumns?.includes(colEle?.title);
	});

	const handleChangeProductUnit = (id, unit) => {
		const newState = state?.map((ele) => {
			const subTotal = config.getSubTotal(
				ele?.selectedVariant?.price,
				ele?.discountPercentage,
				ele?.quantity,
				unit
			);
			let totalTax = 0;
			if (taxApplied?.isApplied) {
				totalTax = config.getTotalTax(
					ele?.selectedVariant?.price,
					ele?.discountPercentage,
					ele?.tax,
					ele?.quantity,
					unit
				);
			}
			const totalDiscount = config.getTotalDiscount(
				ele?.selectedVariant?.price,
				ele?.discountPercentage,
				ele?.quantity,
				unit
			);
			if (ele?.id === id) {
				return {
					...ele,
					subTotal,
					totalTax,
					totalDiscount,
					productInputUnit: unit,
					totalPriceIncTax: config.getTotalPrice(
						// ele?.selectedVariant?.price,
						// ele?.discountPercentage,
						// ele?.tax,
						// Number(value),
						// ele?.unit
						subTotal,
						totalTax
					),
				};
			}
			return {
				...ele,
			};
		});
		setState(newState);
	};
	const handleSpecifications = (idx, specId, value) => {
		const newState = [...state];
		const currentItem = newState?.[idx];
		const specification = currentItem?.coreSpecifications?.find(
			(spec) => spec.id === specId
		);
		if (!specification) return;
		specification.value = value;
		setState(newState);
	};

	const handleOemSelection = (idx, selectedOemsIds) => {
		const newState = [...state];
		const currentItem = newState?.[idx];
		const previousOems = currentItem?.oems;
		previousOems?.forEach((oem) => {
			// eslint-disable-next-line no-param-reassign
			oem.isSelected = selectedOemsIds?.includes(oem?.id);
		});
		setState(newState);
	};

	const productListExcelDownload = () => {
		const item = [];
		const header = [
			'Item Code',
			'UY Item Id',
			'Product Name',
			'Variant Name',
			'Unit',
			'Quantity',
		];
		productsList.forEach((product) => {
			product?.variants?.forEach((variant) => {
				const row = [
					variant?.clientVariantId,
					variant?.id,
					product?.productName,
					variant?.displayName,
					product?.unit,
					0,
				];
				item.push(row);
			});
		});
		handleExcelDownload(header, item, 'Product List', true);
	};

	const handleImport = (fileObject) => {
		const base64 = fileObject?.file?.split?.('text/csv;base64,')[1];
		if (base64?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please upload a valid CSV file, with correct headers',
			});
			return;
		}
		const decoded = atob(base64);
		const parsedData = parseCSV(decoded);
		const arr = csvArrayToObject(parsedData);
		let index = 0;
		const newState = [];
		for (const object of arr) {
			let {
				Quantity: qty,
				Unit: objUnit,
				'Item Code': itemCode,
				'UY Item Id': variantId,
			} = object;
			qty = Number(qty);
			if (Number.isNaN(qty) || ['', null, undefined, 0, '-', '0', '0.00', '0.0000'].includes(qty)) {
				// eslint-disable-next-line no-console
				console.log('skipping', qty, object);
				// eslint-disable-next-line no-continue
				continue;
			}
			if (['', null, undefined, 0, '-', '0', '0.00', '0.0000'].includes(variantId)) {
				// eslint-disable-next-line no-console
				console.log('error missing variantId', qty, object);
				notificationServices.generateNotification({
					type: 'error',
					message: `Missing variantId at index ${index + 1} and row ${itemCode} with quantity ${qty}`,
				});
				return;
			}
			if (['', null, undefined, 0, '-', '0', '0.00', '0.0000'].includes(objUnit)) {
				// eslint-disable-next-line no-console
				console.log('error missing unit', qty, object);
				notificationServices.generateNotification({
					type: 'error',
					message: `Missing unit at index ${index + 1} and row ${itemCode} with quantity ${qty}`,
				});
				return;
			}
			index++;
			// eslint-disable-next-line prefer-const
			let [unit, isHigherUnit] = getOurProductUnit(objUnit);
			if (!unit) {
				notificationServices.generateNotification({
					type: 'error',
					message: `Invalid unit (${objUnit}) at index ${index + 1} and row ${itemCode} with quantity ${qty}`,
				});
				return;
			}

			// if (isHigherUnit) {
			// const [newQty, newUnit] = reverseUnitAndQtyNew(qty, unit);
			// qty = newQty;
			// unit = newUnit;
			// }
			newState.push({
				variantId: Number(variantId),
				qty: Number(qty),
				unit,
				discountPercentage: 0,
			});
		}
		handleItemsListSelection(newState);
	};

	return (
		<div className={classes(styles.root)}>
			<UploadDataModal
				fileAcceptable='.csv'
				heading='Upload items to populate list'
				handleImport={handleImport}
			/>
			{!!disabled && (
				<div
					onClick={() => {
						// notificationServices.generateNotification({
						// type: 'error',
						// message: 'Please enter Mandatory fields',
						// });
						return null;
					}}
					className={styles.disabled}
				/>
			)}
			<div className={styles['actions-container']}>
				{
					state?.length === 0 &&
					(
						<>

							<div
								onClick={() => {
									productListExcelDownload();
								}}
								className={`${styles.downloadData}`}
							>
								<img src={excel} alt='excel' />
								EXPORT
								<Upload size={18} className='text-white' />
							</div>
							<div
								onClick={() => {
									toggleUploadDataModal(true, {});
								}}
								className={`${styles.downloadData}`}
								style={{
									backgroundColor: '#3f6cff',
								}}
							>
								<img src={excel} alt='excel' />
								IMPORT
								<Download size={18} className='text-white' />
							</div>
						</>
					)
				}

				{requiredFields?.generateStandardPO && state?.length === 0 && (
					<Button
						onClick={handleGenerateStandardPO}
						className='primary-btn'
					>
						Generate Standard PO
					</Button>
				)}
			</div>

			<div className='flex flex-col gap-1 w-full items-end'>
				{(!taxApplied?.isApplied || taxApplied?.reason?.length > 0) && (
					taxApplied?.reason?.length > 0 && (
						<p className='text-uy-color font-semibold animate-pulse m-0'>
							{taxApplied?.reason}
						</p>

					)
				)}
				{typeId === 1 && (
					<p className='font-semibold text-slate-600 m-0'>
						Some prices are fixed or non-editable due to vendor
						contracts.
					</p>
				)}
			</div>
			<div className={styles['item-row']}>
				<table>
					<thead>
						<tr>
							{tableColumns?.map((ele) => {
								return (
									<th
										style={{
											width: ele?.width ? ele?.width : '',
											fontSize: 14,
										}}
									>
										{ele?.title?.toUpperCase()}
										{' '}
										{ele?.icon}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody ref={ref}>
						{state?.map((ele, idx) => {
							const rowStyles = getRowStyles(
								ele?.selectedVariant?.id,
								idx
							);
							const isChangeable = !!ele?.isChangeable;
							return (
								<React.Fragment key={ele?.selectedVariant?.id}>
									<TableRow
										key={
											typeId === 4
												? idx
												: ele?.selectedVariant?.id
										}
										idx={idx}
										customComponents={customComponents}
										handleItemDescription={
											handleItemDescription
										}
										rowStyles={rowStyles}
										product={ele}
										quantity={ele?.quantity}
										productWiseMappingData={
											productWiseMappingData
										}
										handleSpecifications={
											handleSpecifications
										}
										handleOemSelection={handleOemSelection}
										ratePerString={ratePerString}
										rowError={rowError}
										disabled={disabled}
										productsList={productsList}
										handleSelection={handleItemSelection}
										handleQuantity={handleQuantity}
										handleRate={handleRate}
										handleDiscount={handleDiscount}
										handleChangeVariant={
											handleChangeVariant
										}
										handleRemove={handleRemove}
										skuStockInfo={skuStockInfo}
										measurementType={measurement}
										columns={tableColumns}
										isChangeable={isChangeable}
										isDeliveryChallan={isDeliveryChallan}
										typeId={typeId}
										handleChangeProductUnit={
											handleChangeProductUnit
										}
										qtyRegex={qtyRegex}
									/>
								</React.Fragment>
							);
						})}
						{!disabled && (
							<TableRow
								addedItems={state}
								disabled={disabled}
								productWiseMappingData={productWiseMappingData}
								productsList={productsList}
								handleSelection={handleItemSelection}
								handleQuantity={handleQuantity}
								quantity={quantity}
								skuStockInfo={skuStockInfo}
								columns={tableColumns}
								typeId={typeId}
								isDeliveryChallan
							/>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
});

const mapDispatchToProps = {
	toggleUploadDataModal: modalActions.toggleUploadDataModal,
};
export default connect(null, mapDispatchToProps)(POItemSelection);
