import { Search } from '@mui/icons-material';
import {
	IconButton, InputAdornment, TextField
} from '@mui/material';
import { connect } from 'react-redux';
import reportsActions from '../../../../Actions/reports.actions';
import styles from './ReportsConfigurationHeader.module.scss';

function ReportsConfigurationHeader(props) {
	// eslint-disable-next-line no-unused-vars
	const { query, searchReport, fetchReportsList } = props;
	const handleEmailSearch = (e) => {
		searchReport(e.target.value);
		// fetchReportsList();
	};

	const handleSearch = () => {
		fetchReportsList();
	};

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<TextField
					label='Search Email'
					variant='outlined'
					onChange={handleEmailSearch}
					value={query}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleSearch();
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									onClick={handleSearch}
									className={styles['search-btn']}
									edge='end'
									color='primary'
								>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { search = {} } = state.reports;
	const { query } = search;
	return {
		query,
	};
};

const mapDispatchToProps = {
	fetchReportsList: reportsActions.fetchReportsList,
	searchReport: reportsActions.searchReport,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReportsConfigurationHeader);
