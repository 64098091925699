import { AccountTree } from '@mui/icons-material';
import urls from './urls';

const navIcon = <AccountTree />;

/**
 * * nav props:
 * * icon - to be displayed for expandable navs only
 * * expand - only for navs with submenu; true means expanded
 * * active - can be clicked by the user; visible
 * * disabled - hidden from the user
 *
 * * subNav props:
 * * href - link for the selectable navs
 */

// *********** NEXT AVAILABLE ID: 188, Write next to it and update this comment ****

// this page is needed, we will add later in future
// [urls.HOMEPAGE]: {
// id: 1,
// name: 'Homepage',
// active: true,
// completed: false,
// setup: false,
// disabled: true,
// rolesMapped: ['None'],
// href: `${urls.EXTENSION}${urls.HOMEPAGE}`,
// },

export const navMenus = {
	[urls.NEW_DASHBOARD]: {
		id: 2,
		name: 'Dashboard',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.NEW_DASHBOARD}`,
	},
	[urls.DASHBOARD]: {
		id: 3,
		name: 'Ops Dashboard',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.DASHBOARD}`,
	},
	[urls.AI_DASHBOARD]: {
		id: 4,
		name: 'AI Dashboard',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.AI_DASHBOARD}`,
	},
	[urls.ASSET_DASHBOARD]: {
		id: 5,
		name: 'Stock Dashboard',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.ASSET_DASHBOARD}`,
	},
	[urls.PROCESS_FLOW]: {
		id: 7,
		name: 'Process Flow',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.PROCESS_FLOW}`,
	},
	[urls.ORDER_TRACKNG]: {
		id: 8,
		name: 'Product Traceability',
		active: true,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.ORDER_TRACKNG}`,
	},
	[urls.MY_ACCOUNT]: {
		id: 9,
		icon: navIcon,
		name: 'My Account',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			// [urls.USERS]: {
			// id: 10,
			// name: 'My Users',
			// active: true,
			// completed: false,
			// setup: false,
			// disabled: true,
			// href: `${urls.EXTENSION}${urls.USERS}`,
			// },
			[urls.USERS_PERMISSIONS]: {
				id: 11,
				name: 'My Users Access',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.USERS_PERMISSIONS}`,
			},
			[urls.MODULES_CONFIGURATION]: {
				id: 12,
				name: 'Modules Configuration',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MODULES_CONFIGURATION}`,
			},
			[urls.REVENUE_PAGE]: {
				id: 13,
				name: 'Store Revenue',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.REVENUE_PAGE}`,
			},
			[urls.MY_LICENSES]: {
				id: 14,
				name: 'My Licenses',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MY_LICENSES}`,
			},
			[urls.WASTAGE_REASONS]: {
				id: 174,
				name: 'Wastage Reasons',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.WASTAGE_REASONS}`,
			},
			[urls.MY_AUDITS]: {
				id: 15,
				name: 'My Audits',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MY_AUDITS}`,
			},
			[urls.MY_MACHINES]: {
				id: 16,
				name: 'My Machines',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MY_MACHINES}`,
			},
			[urls.MY_CONTAINERS]: {
				id: 17,
				name: 'My Containers',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MY_CONTAINERS}`,
			},
			[urls.BRANDING]: {
				id: 18,
				name: 'Branding',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.BRANDING}`,
			},
			[urls.PROMO_CODES]: {
				id: 19,
				name: 'Promo Codes',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PROMO_CODES}`,
			},
			[urls.CHILD_LIST]: {
				id: 20,
				name: 'Add/Edit Child',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.CHILD_LIST}`,
			},
			[urls.REPORTS_CONFIG]: {
				id: 21,
				name: 'Reports Configuration',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.REPORTS_CONFIG}`,
			},
			// [urls.ACCOUNT_SETTINGS]: {
			// name: 'Settings',
			// active: true,
			// completed: false,
			// setup: false,
			// disabled: false,
			// href: `${urls.EXTENSION}${urls.ACCOUNT_SETTINGS}`,
			// },
		},
	},
	[urls.PROCESS_CONFIG]: {
		id: 7,
		name: 'Process Config',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.PROCESS_CONFIG}`,
	},
	[urls.GST_DETAILS]: {
		id: 186,
		name: 'Gst Details',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.GST_DETAILS}`,
	},
	[urls.MY_SUBSCRIPTIONS]: {
		id: 22,
		icon: navIcon,
		name: 'Customer Subscriptions',
		expand: false,
		active: true,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: '',
		submenu: {
			[urls.MY_SUBSCRIPTIONS_HISTORY]: {
				id: 23,
				name: 'Subscriptions History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MY_SUBSCRIPTIONS_HISTORY}?clear=true`,
			},
		},
	},
	[urls.AI_WORKFLOW]: {
		id: 24,
		icon: navIcon,
		name: 'AI Workflow',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.AI_ACCURACIES]: {
				id: 25,
				name: 'AI Accuracies',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.AI_ACCURACIES}`,
			},
			[urls.AI_MODEL_CREATION]: {
				id: 26,
				name: 'AI Model Creation',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_MODEL_CREATION}`,
			},
			[urls.AI_STATS]: {
				id: 27,
				name: 'AI statistics',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_STATS}`,
			},
			[urls.AI_MODEL_HISTORY]: {
				id: 28,
				name: 'AI Model History',
				active: false,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_MODEL_HISTORY}`,
			},
		},
	},
	[urls.ASSET]: {
		id: 29,
		icon: navIcon,
		name: 'Assets',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.ASSET_CATEGORY]: {
				id: 30,
				name: 'Asset Types',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ASSET_CATEGORY}`,
			},
			[urls.ASSET_MONITORING]: {
				id: 31,
				name: 'Asset Monitoring',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ASSET_MONITORING}`,
			},
			[urls.ASSET_IMMOVEABLE]: {
				id: 160,
				name: 'Immovable Asset',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ASSET_IMMOVEABLE}`,
			},
			[urls.ASSET_CAPACITY]: {
				id: 167,
				name: 'Packaging Capacity',
				active: true,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ASSET_CAPACITY}`,
			},
			[urls.ASSET_LISTING]: {
				id: 183,
				name: 'Asset Listing',
				active: true,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ASSET_LISTING}`,
			},
		},
	},
	[urls.ADD_CUSTOMERS]: {
		id: 32,
		name: 'My Franchisee',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.ADD_CUSTOMERS}`,
	},
	[urls.REFUNDS]: {
		id: 33,
		icon: navIcon,
		name: 'Refunds',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.REQUEST_REFUNDS]: {
				id: 34,
				name: 'Request Refunds',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.REQUEST_REFUNDS}`,
			},
			[urls.APPROVE_REFUNDS]: {
				id: 35,
				name: 'Approve Refunds',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.APPROVE_REFUNDS}`,
			},
			[urls.REFUNDS_HISTORY]: {
				id: 36,
				name: 'Refunds History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.REFUNDS_HISTORY}`,
			},
		},
	},
	[urls.TEMPERATURE_IMAGES]: {
		id: 37,
		name: 'Temperature Images',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.TEMPERATURE_IMAGES}`,
	},
	filler0: {
		name: '',
		type: 'NON_LINK',
	},
	[urls.FINANCE_MANAGEMENT]: {
		name: 'Finance Management',
		type: 'NON_LINK',
	},
	[urls.FINANCE_MANAGEMENT_MENU]: {
		id: 161,
		icon: navIcon,
		name: 'Finance Management',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.BILLS]: {
				id: 38,
				name: 'Bills',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.BILLS}`,
			},
			[urls.BILLS_SUMMARY]: {
				id: 159,
				name: 'Bills Summary',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.BILLS_SUMMARY}`,
			},
			[urls.PAYMENT_MADE]: {
				id: 93,
				name: 'Payment made',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				rolesMapped: ['None'],
				generatable: false,
				generateLink: '',
				href: `${urls.EXTENSION}${urls.PAYMENT_MADE}`,
			},
			[urls.CREDITS]: {
				id: 94,
				name: 'Debit/Credit Note',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				generateLink: `${urls.EXTENSION}${urls.CREDITS}/add`,
				href: `${urls.EXTENSION}${urls.CREDITS}`,
			},
			[urls.PETTY_CASH]: {
				id: 39,
				name: 'Ad-Hoc Expense/Income',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.PETTY_CASH}/add?`,
				href: `${urls.EXTENSION}${urls.PETTY_CASH}`,
			},
			[urls.UTILITIES_PAGE]: {
				id: 40,
				name: 'Utilities',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.UTILITIES_PAGE}/add?`,
				href: `${urls.EXTENSION}${urls.UTILITIES_PAGE}`,
			},
			[urls.VENDOR_OPENING]: {
				id: 162,
				name: 'Vendor Opening',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.VENDOR_OPENING}`,
			},
			[urls.PAYMENT_SETTLEMENTS]: {
				id: 41,
				name: 'Payment Settlements',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PAYMENT_SETTLEMENTS}`,
			},
		},
	},
	filler7: {
		name: '',
		type: 'NON_LINK',
	},
	[urls.ORDER_OPERATIONS]: {
		id: 42,
		name: 'Order Operations',
		type: 'NON_LINK',
	},
	[urls.UPLOAD_PO]: {
		id: 43,
		name: 'Upload Data',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.UPLOAD_PO}`,
	},
	[urls.CREATE_EWAY_BILL]: {
		id: 44,
		name: 'Generate E-Way Bill',
		active: true,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}`,
	},
	[urls.CREATE_PURCHASE_ORDER]: {
		id: 45,
		name: 'Create Order',
		active: true,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}`,
	},

	[urls.SALES_ORDERS]: {
		id: 85,
		icon: navIcon,
		name: 'Internal Sales Orders',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		requisites: [
			{
				type: 'store',
				displayName: 'From',
				storesType: 'BASE',
				value: null,
			},
		],
		submenu: {
			// [urls.PURCHASE_ORDERS_HISTORY]: {
			// name: 'History',
			// active: true,
			// completed: false,
			// setup: false,
			// disabled: false,
			// href: `${urls.EXTENSION}${urls.PURCHASE_ORDERS_HISTORY}`,
			// },
			[urls.PO_LIST]: {
				id: 86,
				name: 'Store Sale Orders',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?typeId=2`,
				href: `${urls.EXTENSION}${urls.PO_LIST}`,
			},
			[urls.DELIVERY_CHALLAN]: {
				id: 87,
				name: 'Delivery Challan',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.DELIVERY_CHALLAN}`,
			},
			[urls.SALES_RETURN]: {
				id: 88,
				name: 'Sales Return',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.SALES_RETURN}`,
			},
		},
	},
	[urls.PURCHASE_ORDERS]: {
		id: 89,
		icon: navIcon,
		name: 'Vendor Orders',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		requisites: [
			{
				type: 'store',
				displayName: 'To',
				value: null,
			},
		],

		submenu: {
			[urls.VENDOR_ORDER_LIST]: {
				id: 90,
				name: 'Vendor Orders',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?typeId=1&userOrderType=purchase`,
				href: `${urls.EXTENSION}${urls.VENDOR_ORDER_LIST}`,
			},
			[urls.CAPEX_ORDER_LIST]: {
				id: 91,
				name: 'Asset Purchase Order',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?typeId=4&userOrderType=purchase`,
				href: `${urls.EXTENSION}${urls.CAPEX_ORDER_LIST}`,
			},
			[urls.GRN_LIST]: {
				id: 92,
				name: 'GRN Listing',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				generatable: false,
				generateLink: `${urls.EXTENSION}${urls.GRN_LIST}/add`,
				href: `${urls.EXTENSION}${urls.GRN_LIST}`,
			},
			[urls.QUOTE_LIST]: {
				id: 178,
				name: 'Assets Quote',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.QUOTE_LIST}`,
			},
		},
	},
	[urls.INTERNAL_PURCHASE_ORDERS]: {
		id: 95,
		icon: navIcon,
		name: 'Internal Purchase Orders',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		requisites: [
			{
				type: 'store',
				displayName: 'To',
				value: null,
			},
		],

		submenu: {
			[urls.INTERNAL_PURCHASE_ORDERS_LIST]: {
				id: 96,
				name: 'Internal Purchase Orders',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}`,
				href: `${urls.EXTENSION}${urls.INTERNAL_PURCHASE_ORDERS_LIST}`,
			},
			[urls.INTERNAL_STORE_ASSET_ORDERS]: {
				id: 182,
				name: 'Asset Transfer Order',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.INTERNAL_STORE_ASSET_ORDERS}`,
			},
			[urls.INTERNAL_PURCHASE_ORDERS_BILLS]: {
				id: 97,
				name: 'Store Bills',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				generatable: false,
				generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.INTERNAL_PURCHASE_ORDERS_BILLS}`,
			},
		},
	},
	[urls.EXTERNAL_SALES_ORDERS]: {
		id: 81,
		icon: navIcon,
		name: 'Customer Sales Orders',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.ORDER_HISTORY_LIST]: {
				id: 82,
				name: 'Orders History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ORDER_HISTORY_LIST}`,
			},
			[urls.MY_SUBSCRIPTIONS_HISTORY]: {
				id: 83,
				name: 'Subscriptions History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MY_SUBSCRIPTIONS_HISTORY}?clear=true`,
			},
			[urls.ADD_CREDITS]: {
				id: 84,
				name: 'Add Credits',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ADD_CREDITS}`,
			},
			[urls.BUYER_ORDER_LIST]: {
				id: 206,
				name: 'Buyers Sales Orders',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: true,
				generateLink: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?typeId=8&userOrderType=purchase`,
				href: `${urls.EXTENSION}${urls.BUYER_ORDER_LIST}`,
			},
		},
	},
	[urls.FMS_ORDERS]: {
		id: 103,
		icon: navIcon,
		name: 'FMS',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.FMS_ORDERS_LIST]: {
				id: 104,
				name: 'FMS Orders List',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.FMS_ORDERS_LIST}`,
			},
			[urls.PRODUCTION_PLANS]: {
				id: 105,
				name: 'Production Plans',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PRODUCTION_PLANS}`,
			},
		},
	},
	[urls.INDENT_LIST]: {
		id: 107,
		name: 'Indent List',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.INDENT_LIST}`,
	},
	// filler5: {
	// name: '',
	// type: 'NON_LINK',
	// },
	// [urls.FINANCIAL]: {
	// name: 'Financial',
	// type: 'NON_LINK',
	// },
	filler6: {
		name: '',
		type: 'NON_LINK',
	},
	[urls.ANALYTICS]: {
		name: 'Reports',
		type: 'NON_LINK',
	},
	[urls.REPORTS]: {
		id: 6,
		name: 'Custom Reports',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.REPORTS}`,
	},
	[urls.STORE_ANALYSIS]: {
		id: 46,
		name: 'Store Analysis',
		active: true,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: `${urls.EXTENSION}${urls.STORE_ANALYSIS}`,
	},
	[urls.COGS_ANALYTICS]: {
		id: 164,
		icon: navIcon,
		name: 'COGS',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.COGS_ANALYSIS]: {
				id: 162,
				name: 'COGS Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.COGS_ANALYSIS}`,
			},
			[urls.COGS_HISTORY_LIST]: {
				id: 163,
				name: 'COGS History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.COGS_HISTORY_LIST}?clear=true`,
			},
		},
	},
	[urls.BOM_ANALYTICS]: {
		id: 165,
		icon: navIcon,
		name: 'Production Cost',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.BOM_ANALYSIS]: {
				id: 166,
				name: 'Production Cost Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.BOM_ANALYSIS}`,
			},
			[urls.BOM_HISTORY_LIST]: {
				id: 167,
				name: 'Production Cost History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.BOM_HISTORY_LIST}?clear=true`,
			},
		},
	},
	[urls.INV_ANALYTICS]: {
		id: 168,
		icon: navIcon,
		name: 'Inventory Analytics',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.INV_ANALYSIS]: {
				id: 169,
				name: 'Inventory Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.INV_ANALYSIS}`,
			},
			[urls.INV_HISTORY_LIST]: {
				id: 172,
				name: 'Inventory Analysis History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.INV_HISTORY_LIST}?clear=true`,
			},
			[urls.DAY_END_REPORT]: {
				id: 174,
				name: 'Day End Report',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				href: `${urls.EXTENSION}${urls.DAY_END_REPORT}`,
			},
		},
	},
	[urls.TRANSIT_ANALYTICS]: {
		id: 168,
		icon: navIcon,
		name: 'Transit Loss Analytics',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.TRANSIT_ANALYSIS]: {
				id: 169,
				name: 'Transit Loss Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.TRANSIT_ANALYSIS}`,
			},
			[urls.TRANSIT_HISTORY_LIST]: {
				id: 172,
				name: 'Transit Loss Analysis History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.TRANSIT_HISTORY_LIST}?clear=true`,
			},
		},
	},
	[urls.FORECASTING_ANALYTICS]: {
		id: 179,
		icon: navIcon,
		name: 'Forecasting Analytics',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.FORECASTING_ANALYSIS]: {
				id: 180,
				name: 'Forecasting Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				href: `${urls.EXTENSION}${urls.FORECASTING_ANALYSIS}`,
			},
			[urls.FORECASTING_ANALYSIS_LIST]: {
				id: 181,
				name: 'Forecasting Analysis List',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.FORECASTING_ANALYSIS_LIST}`,
			},
		},
	},
	[urls.AUDIT_ANALYTICS]: {
		id: 170,
		icon: navIcon,
		name: 'Variance Analytics',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.AUDIT_ANALYSIS]: {
				id: 171,
				name: 'Variance Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.AUDIT_ANALYSIS}`,
			},
			[urls.AUDIT_HISTORY_LIST]: {
				id: 167,
				name: 'Variance History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.AUDIT_HISTORY_LIST}?clear=true`,
			},
		},
	},
	[urls.COMMON_ANALYTICS]: {
		id: 175,
		icon: navIcon,
		name: 'Common Analytics',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: true,
		href: '',
		submenu: {
			[urls.COMMON_ANALYSIS]: {
				id: 176,
				name: 'Common Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.COMMON_ANALYSIS}`,
			},
			[urls.COMMON_HISTORY_LIST]: {
				id: 177,
				name: 'Common History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				generatable: false,
				// generateLink: `${urls.EXTENSION}${urls.BILLS}/add`,
				href: `${urls.EXTENSION}${urls.COMMON_HISTORY_LIST}?clear=true`,
			},
		},
	},
	[urls.AI_GRN]: {
		id: 47,
		icon: navIcon,
		name: 'AI GRN',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.AI_GRN_HISTORY]: {
				id: 48,
				name: 'AI GRN History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_GRN_HISTORY}`,
			},
			[urls.AI_GRN_IMAGES]: {
				id: 49,
				name: 'AI GRN Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_GRN_IMAGES}`,
			},
		},
	},
	[urls.STOCK_CONSUMPTION]: {
		id: 50,
		name: 'Stock Consumption',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.STOCK_CONSUMPTION}`,
	},
	[urls.COOKING_ANALYSIS]: {
		id: 51,
		icon: navIcon,
		name: 'Cooking',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.COOKING_ANALYSIS_LIST]: {
				id: 52,
				name: 'Cooking History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.COOKING_ANALYSIS_LIST}`,
			},
			[urls.COOKING_IMAGES]: {
				id: 53,
				name: 'Ingredients Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.COOKING_IMAGES}`,
			},
			[urls.WORK_ORDER_LIST]: {
				id: 54,
				name: 'Work Order List',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.WORK_ORDER_LIST}`,
			},
		},
	},
	[urls.AI_PRODUCTION]: {
		id: 55,
		icon: navIcon,
		name: 'AI Production',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.AI_PRODUCTION_HISTORY]: {
				id: 56,
				name: 'Production History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_PRODUCTION_HISTORY}`,
			},
			[urls.PRODUCT_IMAGES]: {
				id: 57,
				name: 'Production Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PRODUCT_IMAGES}`,
			},
		},
	},
	[urls.WASTAGE_HISTORY]: {
		id: 58,
		icon: navIcon,
		name: 'Wastage',
		expand: false,
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.WASTAGE_HISTORY_LIST]: {
				id: 59,
				name: 'Wastage History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.WASTAGE_HISTORY_LIST}?clear=true`,
			},
			[urls.WASTAGE_IMAGES]: {
				id: 60,
				name: 'Wastage Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.WASTAGE_IMAGES}`,
			},
			[urls.WASTAGE_COMPONENTS]: {
				id: 61,
				name: 'Wastage Components',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.WASTAGE_COMPONENTS}`,
			},
		},
	},
	[urls.QUALITY_ANALYSIS_CONTAINER]: {
		id: 62,
		name: 'Quality',
		icon: navIcon,
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.QUALITY_ANALYSIS]: {
				id: 63,
				name: 'Quality Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.QUALITY_ANALYSIS}?clear=true`,
				icon: navIcon,
			},
			[urls.QC_HISTORY_LIST]: {
				id: 64,
				name: 'Quality History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.QC_HISTORY_LIST}?clear=true`,
				icon: navIcon,
			},
			[urls.NUTRITION_ANALYSIS]: {
				id: 65,
				name: 'Nutrition Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.NUTRITION_ANALYSIS}`,
				icon: navIcon,
			},
		},
	},
	[urls.INVENTORY_ANALYTICS]: {
		id: 67,
		icon: navIcon,
		name: 'Stock History',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.SKUS_INVENTORY_STOCK]: {
				id: 68,
				name: 'Current Stock',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.SKUS_INVENTORY_STOCK}`,
			},
			[urls.STOCK_IN_REPORT]: {
				id: 69,
				name: 'Stock History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.STOCK_IN_REPORT}`,
			},
			[urls.IMS_SUMMARY]: {
				id: 70,
				name: 'Inventory Summary',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.IMS_SUMMARY}`,
			},
			[urls.INVENTORY_ANALYSIS]: {
				id: 71,
				name: 'Inventory Analysis',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.INVENTORY_ANALYSIS}?clear=true`,
			},
			// [urls.INVENTORY_ANALYSIS_LIST]: {
			// name: 'Inventory History',
			// active: true,
			// completed: false,
			// setup: false,
			// disabled: false,
			// href: `${urls.EXTENSION}${urls.INVENTORY_ANALYSIS_LIST}`,
			// },
		},
	},
	[urls.AI_OUTWARDING]: {
		id: 72,
		icon: navIcon,
		name: 'AI Outwarding',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.AI_OUTWARDING_HISTORY]: {
				id: 73,
				name: 'Outwarding History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_OUTWARDING_HISTORY}`,
			},
			[urls.AI_OUTWARDING_IMAGES]: {
				id: 74,
				name: 'Outwarding Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_OUTWARDING_IMAGES}`,
			},
		},
	},
	[urls.AI_DISPATCH]: {
		id: 75,
		icon: navIcon,
		name: 'AI Dispatch',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.AI_DISPATCH_HISTORY]: {
				id: 76,
				name: 'AI Dispatch History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_DISPATCH_HISTORY}`,
			},
			[urls.AI_DISPATCH_IMAGES]: {
				id: 77,
				name: 'AI Dispatch Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.AI_DISPATCH_IMAGES}`,
			},
		},
	},
	[urls.BRAND_TRENDS]: {
		id: 78,
		name: 'Brand Trends',
		active: true,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: `${urls.EXTENSION}${urls.BRAND_TRENDS}`,
	},

	[urls.ORDER_HISTORY]: {
		id: 79,
		icon: navIcon,
		name: 'Orders',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: '',
		submenu: {
			[urls.ORDER_HISTORY_LIST]: {
				id: 80,
				name: 'Orders History',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ORDER_HISTORY_LIST}`,
			},
		},
	},
	// [urls.INVENTORY_ANALYSIS]: {
	// name: 'Inventory Analysis',
	// active: true,
	// completed: false,
	// setup: false,
	// disabled: true,
	// rolesMapped: ['None'],
	// href: `${urls.EXTENSION}${urls.INVENTORY_ANALYSIS}`,
	// },
	[urls.IMAGES_FINDER]: {
		id: 98,
		icon: navIcon,
		name: 'Images Finder',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.DEFECT_IMAGES]: {
				id: 99,
				name: 'Feature Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.DEFECT_IMAGES}`,
			},
			[urls.CONTAINER_IMAGES]: {
				id: 100,
				name: 'Container Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.CONTAINER_IMAGES}`,
			},
			[urls.TEMPERATURE_DIGIT_IMAGES]: {
				id: 101,
				name: 'Temperature Digit Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.TEMPERATURE_DIGIT_IMAGES}`,
			},
			[urls.APP_OUTWARDING_IMAGES]: {
				id: 102,
				name: 'App Outwarding Images',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.APP_OUTWARDING_IMAGES}`,
			},
		},
	},
	[urls.MY_CUSTOMERS]: {
		id: 106,
		name: 'My Customers',
		active: true,
		completed: false,
		setup: false,
		disabled: true,
		rolesMapped: ['None'],
		href: `${urls.EXTENSION}${urls.MY_CUSTOMERS}`,
	},

	// [urls.CREATE_PURCHASE_ORDER]: {
	// name: 'Create Vendor Orders',
	// active: true,
	// completed: false,
	// setup: false,
	// disabled: false,
	// href: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}`,
	// },
	filler1: {
		name: '',
		type: 'NON_LINK',
	},
	filler1_1: {
		name: 'Food Management',
		type: 'NON_LINK',
	},
	[urls.MY_OUTLETS]: {
		id: 108,
		icon: navIcon,
		name: 'My Outlets',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.STORE_SETTINGS]: {
				id: 109,
				name: 'Store Settings',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.STORE_SETTINGS}`,
			},
			[urls.STORES]: {
				id: 110,
				name: 'Stores',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.STORES}`,
			},
			[urls.RIDERS]: {
				id: 111,
				name: 'Riders',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.RIDERS}`,
			},
			[urls.PRINTER_CONFIG]: {
				id: 112,
				name: 'Printer Config',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PRINTER_CONFIG}`,
			},
			[urls.LIVE_VIEW]: {
				id: 113,
				name: 'Live View',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.LIVE_VIEW}`,
			},
			[urls.HOLIDAY]: {
				id: 114,
				name: 'Holiday',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.HOLIDAY}`,
			},
		},
	},
	[urls.BRANDS]: {
		id: 115,
		icon: navIcon,
		name: 'My Brands',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.BRANDS}`,
	},
	[urls.FOOD_PRODUCTS]: {
		id: 116,
		icon: navIcon,
		name: 'My Food Products',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.ACTIVE_PRODUCTS]: {
				id: 117,
				name: 'Active Products',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.ACTIVE_PRODUCTS}`,
			},
			[urls.ALL_PRODUCTS]: {
				id: 118,
				name: 'All Products',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.ALL_PRODUCTS}`,
			},
			[urls.CUSTOMIZATIONS]: {
				id: 119,
				name: 'Customizations',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.CUSTOMIZATIONS}`,
			},
			[urls.VARIANTS_THRESHOLD]: {
				id: 120,
				name: 'Variants Threshold',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.VARIANTS_THRESHOLD}`,
			},
			[urls.VARIANTS]: {
				id: 121,
				name: 'Variants',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.VARIANTS}`,
			},
			[urls.PRODUCT_CATEGORY]: {
				id: 122,
				name: 'Product Category',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`,
			},
			[urls.PRODUCT_SUB_CATEGORY]: {
				id: 170,
				name: 'Product Sub-Category',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PRODUCT_SUB_CATEGORY}`,
			},
			[urls.COMBOS]: {
				id: 123,
				name: 'Combos',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.COMBOS}`,
			},
			[urls.NUTRITIONAL_INFO]: {
				id: 124,
				name: 'Nutritional Info',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.NUTRITIONAL_INFO}`,
			},
			[urls.BULK_ORDERING]: {
				id: 125,
				name: 'Bulk Ordering',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.BULK_ORDERING}`,
			},
			[urls.SUBSCRIPTION]: {
				id: 126,
				name: 'Subscription',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.SUBSCRIPTION}`,
			},
		},
	},
	[urls.MENUS]: {
		id: 127,
		icon: navIcon,
		name: 'My Menus',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.ACTIVE_MENUS]: {
				id: 128,
				name: 'Active Menus',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.ACTIVE_MENUS}`,
			},
			[urls.ALL_MENUS]: {
				id: 129,
				name: 'All Menus',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.ALL_MENUS}`,
			},
			[urls.MASTER_MENUS]: {
				id: 130,
				name: 'Master Menus',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.MASTER_MENUS}`,
			},
			[urls.BULK_MENUS]: {
				id: 131,
				name: 'Bulk Menus',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.BULK_MENUS}`,
			},
			[urls.SUBSCRIPTION_MENUS]: {
				id: 132,
				name: 'Subscription Menus',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.SUBSCRIPTION_MENUS}`,
			},
			[urls.SPECIAL_MENUS]: {
				id: 133,
				name: 'Special Menus',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.SUBSCRIPTION_MENUS}`,
			},
		},
	},
	filler2: {
		name: '',
		type: 'NON_LINK',
	},
	[urls.KITCHEN_MANAGEMENT_SYSTEM]: {
		name: 'Kitchen Management',
		type: 'NON_LINK',
	},
	[urls.QUALITY]: {
		id: 134,
		icon: navIcon,
		name: 'Quality',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.QUALITY_SETTING]: {
				id: 135,
				name: 'Quality Settings',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.QUALITY_SETTING}`,
			},
			[urls.PRODUCT_QUALITY_CONFIG]: {
				id: 136,
				name: 'Quality Config',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PRODUCT_QUALITY_CONFIG}`,
			},
			[urls.LIVE_QC]: {
				id: 137,
				name: 'Live QC',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.LIVE_QC}`,
			},
		},
	},
	[urls.COOKING]: {
		id: 138,
		icon: navIcon,
		name: 'Cooking',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.COOKING_SETTING]: {
				id: 139,
				name: 'Cooking Setting',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.COOKING_SETTING}`,
			},
			[urls.COOKING_STATIONS]: {
				id: 140,
				name: 'Cooking Stations',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.COOKING_STATIONS}`,
			},
			[urls.COOKING_RECIPE]: {
				id: 141,
				name: 'Recipe',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.COOKING_RECIPE}`,
			},
			[urls.COOKING_PRODUCT_CONFIG]: {
				id: 142,
				name: 'Cooking Config',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.COOKING_PRODUCT_CONFIG}`,
			},
		},
	},
	[urls.INVENTORY]: {
		id: 143,
		icon: navIcon,
		name: 'Inventory',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.INVENTORY_SETTING]: {
				id: 144,
				name: 'Inventory Settings',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.INVENTORY_SETTING}`,
			},
			[urls.INVENTORY_SKU]: {
				id: 145,
				name: 'Inventory SKU',
				active: true,
				completed: false,
				setup: false,
				disabled: true,
				rolesMapped: ['None'],
				href: `${urls.EXTENSION}${urls.INVENTORY_SKU}`,
			},
			[urls.INVENTORY_CONFIG]: {
				id: 146,
				name: 'Inventory Config',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.INVENTORY_CONFIG}`,
			},
			[urls.LIVE_IMS]: {
				id: 147,
				name: 'Live Check',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.LIVE_IMS}`,
			},
		},
	},
	[urls.VENDORS_CONTAINER]: {
		id: 148,
		icon: navIcon,
		name: 'Vendors',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.VENDORS]: {
				id: 149,
				name: 'Vendors List',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.VENDORS}`,
			},
			[urls.VENDOR_PRODUCT_MAPPING]: {
				id: 150,
				name: 'Vendor Product Mapping',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.VENDOR_PRODUCT_MAPPING}`,
			},
			[urls.VENDOR_CONTRACT]: {
				id: 151,
				name: 'Vendor Contract',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.VENDOR_CONTRACT}`,
			},
		},
	},
	[urls.BUYER_CONTAINER]: {
		id: 200,
		icon: navIcon,
		name: 'Buyers',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		submenu: {
			[urls.BUYER]: {
				id: 201,
				name: 'Buyers List',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.BUYER}`,
			},
			[urls.BUYER_CONTRACT]: {
				id: 202,
				name: 'Buyer Contract',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.BUYER_CONTRACT}`,
			},
		},
	},
	[urls.PRODUCT_PROCESS_MAP]: {
		id: 152,
		name: 'Product Mapping',
		active: true,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.PRODUCT_PROCESS_MAP}`,
	},
	[urls.DEFECTS]: {
		id: 153,
		icon: navIcon,
		name: 'Features',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.DEFECTS}`,
	},
	[urls.LEFT_OVER_INVENTORY]: {
		id: 154,
		icon: navIcon,
		name: 'Left Over Inventory',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: '',
		submenu: {
			[urls.PROCESS_CLOSINGS]: {
				id: 185,
				icon: navIcon,
				name: 'Left Over Inventory List',
				active: true,
				expand: false,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.PROCESS_CLOSINGS}`,
			},
			[urls.LEFT_OVER_INVENTORY_HISTORY]: {
				id: 184,
				name: 'Left Over Inventory History Scans',
				active: true,
				completed: false,
				setup: false,
				disabled: false,
				href: `${urls.EXTENSION}${urls.LEFT_OVER_INVENTORY_HISTORY}`,
			},
		},
	},
	[urls.WASTAGE]: {
		id: 187,
		icon: navIcon,
		name: 'Wastage Inventory',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.WASTAGE}`,
	},
	[urls.SERVICES]: {
		id: 155,
		icon: navIcon,
		name: 'Services',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.SERVICES}`,
	},
	[urls.SIMILAR_PRODUCT]: {
		id: 156,
		icon: navIcon,
		name: 'Similar Products Mapping',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.SIMILAR_PRODUCT}`,
	},
	[urls.REGION_MAPPING]: {
		id: 157,
		icon: navIcon,
		name: 'Regions Mapping',
		active: true,
		expand: false,
		completed: false,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.REGION_MAPPING}`,
	},
	[urls.LABEL_GENERATOR]: {
		id: 158,
		name: 'Label Generator',
		active: true,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.LABEL_GENERATOR_LIST}`,
	},
	[urls.SHIFT]: {
		id: 166,
		name: 'Shift Management',
		active: true,
		setup: false,
		disabled: false,
		href: `${urls.EXTENSION}${urls.SHIFT}`,
	},
	// filler3: {
	// name: '',
	// type: 'NON_LINK',
	// },
	// [urls.CONFIGURATION]: {
	// name: 'CONFIGURATIONS',
	// type: 'NON_LINK',
	// },
	// [urls.CONFIGURATION_PAGE]: {
	// name: 'Configuration',
	// active: true,
	// completed: false,
	// setup: false,
	// disabled: false,
	// href: `${urls.EXTENSION}${urls.CONFIGURATION_PAGE}`,
	// },
	filler4: {
		name: '',
		type: 'NON_LINK',
	},
};
export { default as urls } from './urls';
