/* eslint-disable no-shadow */
/* eslint-disable*/
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import styles from './DeliveryChallan.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import myMachineService from '../../../Services/AssetServices/myMachine.service';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../../Components/Modals/POItemsModal';
import { PaymentModal } from '../../../Components/Modals/PaymentModal';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { urls } from '../../../Constants';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Delivery Challan ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'DATA',
		},
	},
	// {
	// : 'Cancel credit',
	// : {
	// type: 'DATA',
	// },
	// },
];

const COLMAP = {
	ID: 'id',
	'Delivery Challan ID': 'clientTransactionId',
	'Order Id': 'orderId',
	// 'Invoice Id': 'Invoice id',
	Date: 'transactionDate',
	Store: 'storeName',
	Status: 'transactionStatus',
	Amount: 'finalTransactionAmount',
};

const FIND_FILTERS = [];

function DeliveryChallan({
	userPermissions,
	createPath,
	togglePOItemsModal,
	togglePaymentModal,
	navState,
	fetchUserList,
	userList,
}) {
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const history = useHistory();
	const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [deliveryChallanList, setDeliveryChallanList] = useState([]);

	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/analytics/sales']?.submenu['/analytics/po/delivery/challan']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// // end user Acces

	const handleListFetch = async (requestOptions) => {
		// setDeliveryChallanList(dummyData);
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								storeId:
									navSelectedStore?.value?.id === -999
										? null
										: navSelectedStore?.value?.id,
							},
							type: 'DELIVERY_CHALLAN',
						},
					},
				});
			if (responseData?.statusCode === 200) {
				// setSelectedStore(store);
				setDeliveryChallanList(responseData?.data);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
		// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {
		Delete: (id) => {
			return (
				<Button onClick={() => {}} variant='contained' color='primary'>
					Cancel bill
				</Button>
			);
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleViewClick = (item) => {
		togglePOItemsModal(true, {
			heading: 'Delivery Challan of Invoice #13245',
			...item,
			excludedColumns: [
				'Packaged',
				'Dispatched',
				'Returned Quantity',
				'Received',
				'Fulfilled',
				'From Process',
				'PO Id',
				'Returned',
				'Quantity',
				'Used For',
			],
		});
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<POItemsModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Delivery Challans'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					additionInfo={{
						showAdd: userPermissions.INTERNAL,
						// showAdd: false,
						addText: 'Delivery Challan',
						handleAddClick,
					}}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={deliveryChallanList}
				// data={dummyData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleViewClick={handleViewClick}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	const { userPermissions } = state.navigation;
	const { userList } = state?.auth;
	return {
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryChallan);
