// eslint-disable-next-line object-curly-newline
import { Button, styled, TextField } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { generateImageUrl } from '../../../../Helpers';
import { IconInput } from '../../Inputs';
import styles from './RaiseRefundRequestInputSection.module.scss';

const Input = styled('input')({
	display: 'none',
});
const RaiseRefundRequestInputSection = (props) => {
	const { refundDetails, changeRefundDetails, handleFileInput } = props;
	return (
		<div className={styles.root}>
			<div className={`form-field row ${styles.input}`}>
				<label
					className='form-label'
					data-mandatory
					htmlFor='recommendedRefundAmount'
				>
					Amount
				</label>
				<div className={`${styles['refund-amount']} ${styles.input}`}>
					<IconInput
						required
						size='extra-large'
						name='recommendedRefundAmount'
						id='recommendedRefundAmount'
						type='rupee'
						variant='outlined'
						value={refundDetails.recommendedRefundAmount}
						handleInput={changeRefundDetails}
						dataAttrs={{
							required: true,
						}}
					/>
				</div>
			</div>
			<div className={`form-field row ${styles.input}`}>
				<label
					className='form-label'
					data-mandatory
					htmlFor='refundReason'
				>
					Reason
				</label>
				<div className={`${styles['refund-amount']} ${styles.input}`}>
					<TextField
						required
						id='refundReason'
						name='refundReason'
						label='Reason'
						variant='outlined'
						multiline
						maxRows={4}
						value={refundDetails.refundReason}
						onChange={changeRefundDetails}
					/>
				</div>
			</div>
			<div className={`${styles.input} form-field row `}>
				<label className='form-label'>Display Image</label>
				<div
					className={`${styles['image-container']} ${styles.input} `}
				>
					<div className={`${styles['image-preview']}`}>
						<img
							src={generateImageUrl(refundDetails.image)}
							className={`${styles.image}`}
							alt={
								refundDetails.image ? 'refund-pic-display' : ''
							}
						/>
					</div>
					<label htmlFor='contained-button-file'>
						<Input
							accept='image/*'
							id='contained-button-file'
							multiple
							type='file'
							onChange={handleFileInput}
						/>
						<Button
							variant='contained'
							component='span'
							startIcon={<PhotoCamera />}
							className={`${styles['upload-btn']}`}
							color='primary'
						>
							Upload
						</Button>
					</label>
				</div>
			</div>
		</div>
	);
};
RaiseRefundRequestInputSection.defaultProps = {
	refundDetails: {},
	handleFileInput: () => {},
};
export default RaiseRefundRequestInputSection;
