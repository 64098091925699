import { cloneDeep } from 'lodash';
import { formConstants } from '../Constants';

const liveStoreActions = {
	updateStoreMenu,
	updateSpecialMenu,
};

function updateStoreMenu(idx, data) {
	return (dispatch, getState) => {
		const clonedState = cloneDeep(getState().form);
		const store = clonedState.STORES.data.LIST[idx];
		if (data.menuType === 'masterMenu') {
			store.menuId = data.id;
			store.menuName = data.name;
		} else {
			store[data.menuType] = data.id;
			store[`${data.menuType}Name`] = data.name;
		}
		store.menuAvailability = null;
		dispatch({
			type: formConstants.UPDATE_LIST,
			payload: clonedState,
		});
	};
}

function updateSpecialMenu(idx) {
	return (dispatch, getState) => {
		const clonedState = cloneDeep(getState().form);
		const store = clonedState.STORES.data.LIST[idx];
		store.specialMenu = null;
		dispatch({
			type: formConstants.UPDATE_LIST,
			payload: clonedState,
		});
	};
}

export default liveStoreActions;
