/* eslint-disable no-use-before-define */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Tree, TreeNode } from 'react-organizational-chart';
// import ScrollContainer from 'react-indiana-drag-scroll';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	useRef, useState, useEffect, useMemo
} from 'react';
import Breadcrumb from 'rsuite/Breadcrumb';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Button } from '@mui/material';
import TreeNodeTile from './TreeNodeTile/TreeNodeTile';
import styles from './AssetDashboardPage.module.css';
import { classes } from '../../../Helpers';
import DataTileContainer from '../../Dashboard/DataTileContainer/DataTileContainer';
import TwoColumnCharts from '../../Dashboard/TwoColumnCharts/TwoColumnCharts';
import assetActions from '../../../Actions/asset.actions';
import SearchBar from './SearchBar/SearchBar';
import { BackdropLoader } from '../../../Components/Loaders';
import { formActions } from '../../../Actions';

function checkEquals(data, search) {
	if (data?.id === search?.id && data?.entityType === search?.entityType) return true;
	return false;
}

const BuildDataTile = ({
	onClick,
	levelData,
	setLevel,
	selectionArray,
	data,
	zoomToElement,
	searchData,
}) => {
	return (
		<TreeNodeTile
			selectionArray={selectionArray}
			clickHandle={() => {
				onClick();
			}}
			levelData={levelData}
			setLevel={setLevel}
			searchData={searchData}
			zoomToElement={zoomToElement}
			data={data}
		/>
	);
};

const RecursiveBuildNode = ({
	levelData,
	setLevel,
	data,
	selectionArray,
	appendId,
	zoomToElement,
	searchData,
}) => {
	const newAppendId = (array = []) => {
		appendId([
			...array,
			{
				...data,
				// id: data?.id,
				// name: data?.name,
				// type: data?.type,
			},
		]);
	};
	let expandThis = false;
	if (!searchData?.search) {
		const index = selectionArray.findIndex((ele) => {
			return checkEquals(ele, data);
			// return ele.id === data?.id && ele.type === data?.type;
		});
		if (index >= 0) {
			expandThis = true;
		}
		if (levelData?.level > 0) {
			expandThis = true;
		}
	} else {
		expandThis = true;
	}

	if (expandThis && data?.children?.length > 0) {
		return (
			<TreeNode
				// label={<div>{data?.name}</div>
				label={(
					<BuildDataTile
						onClick={() => {
							newAppendId();
						}}
						zoomToElement={zoomToElement}
						data={data}
						levelData={levelData}
						setLevel={setLevel}
						searchData={searchData}
						selectionArray={selectionArray}
					/>
				)}
			>
				{data?.children?.map((child) => {
					return (
						<RecursiveBuildNode
							zoomToElement={zoomToElement}
							selectionArray={selectionArray}
							appendId={newAppendId}
							levelData={{
								...levelData,
								level: levelData.level - 1,
							}}
							setLevel={setLevel}
							searchData={searchData}
							data={child}
						/>
					);
				})}
			</TreeNode>
		);
	}
	return (
		<TreeNode
			label={(
				<BuildDataTile
					onClick={() => {
						newAppendId();
					}}
					zoomToElement={zoomToElement}
					data={data}
					levelData={levelData}
					setLevel={setLevel}
					searchData={searchData}
					selectionArray={selectionArray}
				/>
			)}
		/>
	);
};
const TreeBuilder = ({
	levelData,
	setLevel,
	data,
	appendId,
	selectionArray,
	searchData,
	zoomToElement,
}) => {
	const newAppendId = (array = []) => {
		appendId([
			...array,
			{
				...data,
			},
		]);
	};
	if (data?.children?.length > 0) {
		return (
			<div>
				<Tree
					label={(
						<BuildDataTile
							selectionArray={selectionArray}
							zoomToElement={zoomToElement}
							data={data}
							searchData={searchData}
							levelData={levelData}
							setLevel={setLevel}
							onClick={newAppendId}
						/>
					)}
				>
					{data?.children?.map((child) => {
						return (
							<RecursiveBuildNode
								zoomToElement={zoomToElement}
								selectionArray={selectionArray}
								appendId={newAppendId}
								levelData={{
									...levelData,
									level: levelData.level - 1,
								}}
								setLevel={setLevel}
								searchData={searchData}
								data={child}
							/>
						);
					})}
				</Tree>
			</div>
		);
	}
	return (
		<div className={styles.test}>
			<Tree
				label={(
					<BuildDataTile
						zoomToElement={zoomToElement}
						selectionArray={selectionArray}
						data={data}
						levelData={levelData}
						setLevel={setLevel}
						searchData={searchData}
						onClick={newAppendId}
					/>
				)}
			/>
		</div>
	);
};

function AssetDashboardPage({
	assetData,
	productsList,
	fetchAssetDashboardData,
	fetchStoresList,
	stores,
	dashboardChartsData,
	isloading,
	fetchAssetDashboardChartsData,
}) {
	const [expand, setExpand] = useState(true);
	const [search, setSearch] = useState(false);
	const [level, setLevel] = useState({
		level: 0,
		selectedElement: null,
		selectedElementLevel: 1,
	});
	const STORES = useMemo(() => {
		const tempStore = stores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
				name: tStore?.name ?? '',
			};
		});
		return tempStore;
	}, [stores]);
	// const [scaleDown, setScaleDown] = useState(false);
	const scrollValue = useRef(0);
	const Container = useRef(null);
	const [parentArray, setParentArray] = useState([]);
	const newAppendId = (array) => {
		setParentArray([...array]);
		const selectedElement = array[0];
		fetchAssetDashboardChartsData({
			req: {
				params: {
					// type: selectedElement?.assetType,
					id: selectedElement?.id,
					type: selectedElement?.entityType,
				},
			},
		});
	};

	useEffect(() => {
		if (stores?.length <= 0) {
			fetchStoresList({
				entity: 'FILTERED_STORES',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
		fetchAssetDashboardData();
	}, [stores?.length]);
	useEffect(() => {
		if (assetData?.children?.length > 0) {
			setParentArray([
				{
					id: assetData?.id,
					name: assetData?.name,
					type: assetData?.type,
				},
			]);
		}
	}, [assetData]);
	return (
		<div
			ref={Container}
			onScroll={(event) => {
				const st = window.pageYOffset || event.target.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
				if (st > scrollValue.current) {
					if (expand && st > 0 && st < 100) {
						setExpand(false);
					}
					// downscroll code
				} else if (st < scrollValue.current) {
					// if (!expand && st <= 0) {
					// setExpand(true);
					// }
					// upscroll code
				} // else was horizontal scroll
				scrollValue.current = st <= 0 ? 0 : st;
			}}
			className={styles.rootHolder}
		>
			<BackdropLoader showProgress isLoading={isloading} />
			<div className={classes(styles.root)}>
				<ExpandMoreIcon
					onClick={() => {
						setExpand((prev) => {
							return !prev;
						});
					}}
					className={classes(styles.expandIcon, expand ? styles.rotateExpandIcon : '')}
				/>
				<Accordion
					TransitionProps={{
						unmountOnExit: true,
					}}
					expanded={expand}
					onChange={() => {
						setExpand((prev) => {
							return !prev;
						});
					}}
					defaultExpanded
					className={styles.accordion}
				>
					<AccordionSummary
						className={styles.accordionTitle}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Breadcrumb separator='|' className={styles.breadcrumb}>
							{parentArray
								?.map((ele, index, array) => {
									return (
										<Breadcrumb.Item
											className={classes(
												styles.breadcrumbItem,
												index === 0 ? styles.active : ''
											)}
											onClick={(event) => {
												event.stopPropagation();
												setExpand(true);
												setSearch(false);
												newAppendId(array.slice(index, array.length));
											}}
											active={index === 0}
										>
											{ele.name}
										</Breadcrumb.Item>
									);
								})
								.reverse()}
						</Breadcrumb>
					</AccordionSummary>
					<AccordionDetails>
						<div className={classes(styles.accordionDetailsScroller)}>
							<TransformWrapper centerOnInit minScale={0.5}>
								{({ zoomIn, zoomOut, zoomToElement }) => {
									return (
										<>
											<TransformComponent>
												<TreeBuilder
													searchData={{
														search,
														setSearch,
													}}
													levelData={level}
													setLevel={setLevel}
													zoomToElement={zoomToElement}
													selectionArray={parentArray}
													appendId={newAppendId}
													data={assetData}
												/>
											</TransformComponent>
											<div className={styles.tools}>
												<Button
													onClick={() => {
														return zoomIn();
													}}
												>
													+
												</Button>
												<Button
													onClick={() => {
														return zoomOut();
													}}
												>
													-
												</Button>
											</div>
										</>
									);
								}}
							</TransformWrapper>
							<div className={styles.searchDiv}>
								<SearchBar
									STORES={STORES}
									data={assetData}
									handleSearch={(selectedOption) => {
										setSearch(selectedOption);
									}}
								/>
							</div>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
			<div className={classes(styles['data-box-container'])}>
				{dashboardChartsData?.statsHolder?.map((ele) => {
					return <DataTileContainer data={ele} />;
				})}
				{dashboardChartsData?.chartsHolder?.map((ele) => {
					return <TwoColumnCharts productsList={productsList} data={ele} />;
				})}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	// const stores = state?.form?.STORES?.data?.LIST;
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;
	const assetData = state.assets.dashboardAnalytics;
	const { dashboardChartsData, isloading } = state.assets;
	const productsList = state.form.ALL_PRODUCTS.data.LIST;

	return {
		stores,
		assetData,
		isloading,
		productsList,
		dashboardChartsData,
	};
};
const mapDispatchToProps = {
	fetchAssetDashboardData: assetActions.fetchAssetDashboardData,
	fetchStoresList: formActions.gateway,
	fetchAssetDashboardChartsData: assetActions.fetchAssetDashboardChartsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetDashboardPage);
