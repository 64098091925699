import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const getStoreList = () => {
	const params = {
		id: '',
	};

	const url = toQueryStringURL(endpoints.STORES, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
};
const getLiveData = (req) => {
	const url = toQueryStringURL(endpoints.showLiveStatus, req.params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
};

const getPastData = (req) => {
	const url = toQueryStringURL(endpoints.GET_PAST_QC_DETAILS, req.params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
};

const getWastageReasons = (req) => {
	const url = toQueryStringURL(endpoints.GET_WASTAGE_REASONS, req.params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
};

const getRecipeData = (req) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.GET_RECIPE, requestOptions).then(handleResponse);
	// return new Promise((resolve) => {
	// setTimeout(() => {
	// resolve({
	// id: 3367,
	// isBulk: true,
	// isCompleted: true,
	// maxTemp: 110,
	// maxWeight: 10000,
	// minTemp: 0,
	// minWeight: 0,
	// orderDetails: {
	// isCompleted: false,
	// isStarted: true,
	// },
	// poId: 287,
	// productId: 805,
	// recipeName: 'Paneer Tikka Skewers (60Kg)  - Batch1',
	// recipeURL: '',
	// stages: [
	// {
	// completedAt: '06/06/23 12:48:53',
	// dependency: [],
	// description: 'Stage 1',
	// id: 5362,
	// isCompleted: true,
	// isStarted: false,
	// name: 'Stage 1',
	// processes: [
	// {
	// completedAt: '06/06/23 12:48:53',
	// dependency: [],
	// description: 'Process 1',
	// id: 8108,
	// ingredients: [
	// {
	// activeTemp: null,
	// consumedSKUImage: null,
	// consumption: 220,
	// containerWeight: null,
	// customizableSKU: false,
	// defaultSKU: false,
	// extraWeight: null,
	// hoursToExpire: null,
	// id: 483,
	// image: 'https://resoee.s3.amazonaws.com/5/ProductCategory/Panner-22-04-22_18%3A20%3A41.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230605T060112Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=80b09d9125d7a441627a480b28781482835b7d217d4ba9cf3c305658ab2bf43a',
	// imageCheckRequired: true,
	// imagePass: true,
	// ingredientId: 14321,
	// isBatchMode: false,
	// isConsumed: true,
	// maxTemp: null,
	// maxWeight: 5275,
	// minTemp: null,
	// minWeight: 5000,
	// name: 'Panner',
	// overridden: true,
	// qcImage:
	// 'https://resoee.s3.amazonaws.com/5/Inventory/341686035883811_c52d011c-3282-4ef8-a459-002d55c74130.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230606%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230606T071907Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=0508fbd61c3b67b4eba36ee6627889d69f45c43d7e858885bf1b2365ea919ee1',
	// qcMaxTemp: null,
	// qcMinTemp: null,
	// qcOtherTemp: null,
	// qcTemp: 27.2,
	// qcWeight: 220,
	// requiredQty: 5000,
	// result: 'OVERRIDDEN',
	// tempCheckRequired: false,
	// tempPass: true,
	// unit: 'gram',
	// variantId: 931,
	// weightCheckRequired: false,
	// weightPass: false,
	// identification: [
	// {
	// height: 277,
	// label: 'Butterscotch Cake',
	// labelId: 884,
	// width: 313,
	// x: 248,
	// y: 138,
	// },
	// ],
	// features: [
	// {
	// x: 288.1091219086545,
	// y: 290.96774193548384,
	// width: 283.2587999910004,
	// height: 245.6774193548387,
	// id: null,
	// labelId: 53,
	// label: 'Fan',
	// },
	// ],
	// validation: true,
	// },
	// {
	// activeTemp: null,
	// consumedSKUImage: null,
	// consumption: 220,
	// containerWeight: null,
	// customizableSKU: false,
	// defaultSKU: false,
	// extraWeight: null,
	// hoursToExpire: null,
	// id: 480,
	// image: 'https://resoee.s3.amazonaws.com/5/ProductCategory/Cheese-22-04-22_18%3A15%3A36.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230605T060112Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=af5299dd3ee18ea332573ef3e2148fcd29d9f48b0f0ddf1e527ebab3aaa2ab85',
	// imageCheckRequired: true,
	// imagePass: true,
	// ingredientId: 14322,
	// isBatchMode: false,
	// isConsumed: true,
	// maxTemp: null,
	// maxWeight: 10550,
	// minTemp: null,
	// minWeight: 10000,
	// name: 'Cheese',
	// overridden: true,
	// qcImage:
	// 'https://resoee.s3.amazonaws.com/5/Inventory/341686035893446_916ed73d-05ff-4819-bb91-8bc898fa4087.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230606%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230606T071907Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=07f1974b9ae663a807a656aaefa5e1b9dc80ee8514ff722ca713ef2a0a8100fb',
	// qcMaxTemp: null,
	// qcMinTemp: null,
	// qcOtherTemp: null,
	// qcTemp: 27.2,
	// qcWeight: 220,
	// requiredQty: 10000,
	// result: 'OVERRIDDEN',
	// tempCheckRequired: false,
	// tempPass: true,
	// unit: 'gram',
	// variantId: 928,
	// weightCheckRequired: false,
	// weightPass: false,
	// identification: [
	// {
	// height: 277,
	// label: 'Butterscotch Cake',
	// labelId: 884,
	// width: 313,
	// x: 248,
	// y: 138,
	// },
	// ],
	// features: [
	// {
	// x: 288.1091219086545,
	// y: 290.96774193548384,
	// width: 283.2587999910004,
	// height: 245.6774193548387,
	// id: null,
	// labelId: 53,
	// label: 'Fan',
	// },
	// ],
	// validation: true,
	// },
	// {
	// activeTemp: null,
	// consumedSKUImage: null,
	// consumption: 220,
	// containerWeight: null,
	// customizableSKU: false,
	// defaultSKU: false,
	// extraWeight: null,
	// hoursToExpire: null,
	// id: 477,
	// image: 'https://resoee.s3.amazonaws.com/5/ProductCategory/Mix%20Veg-21-04-22_17%3A27%3A45.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230605T060112Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=c0ae84dbd48bd2d53b7081bd9936ab7111bf4b6a1d03c7828c2b087e57be93a1',
	// imageCheckRequired: true,
	// imagePass: true,
	// ingredientId: 14323,
	// isBatchMode: false,
	// isConsumed: true,
	// maxTemp: null,
	// maxWeight: 5275,
	// minTemp: null,
	// minWeight: 5000,
	// name: 'Mix Veg',
	// overridden: true,
	// qcImage:
	// 'https://resoee.s3.amazonaws.com/5/Inventory/341686035917492_8db78d28-0c6d-4faa-b433-0bf1cd79fda5.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230606%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230606T071907Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=3c5d6919d8d8e6d57e8d48e99083f96b8bfa98863ec5ae0403b8a4a90a7b72e7',
	// qcMaxTemp: null,
	// qcMinTemp: null,
	// qcOtherTemp: null,
	// qcTemp: 27.1,
	// qcWeight: 220,
	// requiredQty: 5000,
	// result: 'OVERRIDDEN',
	// tempCheckRequired: false,
	// tempPass: true,
	// unit: 'gram',
	// variantId: 925,
	// weightCheckRequired: false,
	// weightPass: false,
	// identification: [
	// {
	// height: 277,
	// label: 'Butterscotch Cake',
	// labelId: 884,
	// width: 313,
	// x: 248,
	// y: 138,
	// },
	// ],
	// features: [
	// {
	// x: 288.1091219086545,
	// y: 290.96774193548384,
	// width: 283.2587999910004,
	// height: 245.6774193548387,
	// id: null,
	// labelId: 53,
	// label: 'Fan',
	// },
	// ],
	// validation: false,
	// },
	// {
	// activeTemp: null,
	// consumedSKUImage: null,
	// consumption: 220,
	// containerWeight: null,
	// customizableSKU: false,
	// defaultSKU: false,
	// extraWeight: null,
	// hoursToExpire: null,
	// id: 821,
	// image: 'https://resoee.s3.amazonaws.com/3/ProductCategory/Salt-07-07-22_09%3A38%3A05.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230605T060112Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=9db051e45f1e5046dd9697eb46abc3927c2e320c5f98be2ad9f4faee36f89576',
	// imageCheckRequired: true,
	// imagePass: true,
	// ingredientId: 14324,
	// isBatchMode: false,
	// isConsumed: true,
	// maxTemp: null,
	// maxWeight: 528,
	// minTemp: null,
	// minWeight: 500,
	// name: 'Salt',
	// overridden: true,
	// qcImage:
	// 'https://resoee.s3.amazonaws.com/5/Inventory/341686035930418_3274c47d-5419-4c3b-bf7a-743f8e74a217.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20230606%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230606T071907Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=1fac770878a4f8725007c745926c5d88935e34af4109c727099a651f8bd63163',
	// qcMaxTemp: null,
	// qcMinTemp: null,
	// qcOtherTemp: null,
	// qcTemp: 27.2,
	// qcWeight: 220,
	// requiredQty: 500,
	// result: 'OVERRIDDEN',
	// tempCheckRequired: false,
	// tempPass: true,
	// unit: 'gram',
	// variantId: 1161,
	// weightCheckRequired: false,
	// weightPass: false,
	// identification: [
	// {
	// height: 277,
	// label: 'Butterscotch Cake',
	// labelId: 884,
	// width: 313,
	// x: 248,
	// y: 138,
	// },
	// ],
	// features: [
	// {
	// x: 288.1091219086545,
	// y: 290.96774193548384,
	// width: 283.2587999910004,
	// height: 245.6774193548387,
	// id: null,
	// labelId: 53,
	// label: 'Fan',
	// },
	// ],
	// validation: false,
	// },
	// ],
	// isCompleted: true,
	// isStarted: false,
	// name: 'Process 1',
	// processTime: 5,
	// startedAt: '06/06/23 12:48:07',
	// timeAlertRequired: false,
	// videoUrl: null,
	// },
	// ],
	// startedAt: '06/06/23 12:48:07',
	// station: null,
	// },
	// ],
	// tempCheckRequired: false,
	// totalWeight: 880,
	// variantId: 1145,
	// weightCheckRequired: false,
	// });
	// }, 1000);
	// });
};

// {
// params: {
// storeId: 'sdf', (lastQCAt = 'sdf');
// }
// }
const liveQCServices = {
	getStoreList,
	getLiveData,
	getPastData,
	getRecipeData,
	getWastageReasons,
};
export default liveQCServices;
