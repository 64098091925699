import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
// import Box from '@mui/material/Box';
import { DatePicker } from '@mui/lab';
import { UYDateRange } from './UYDateRange';
import styles from './Calender.module.scss';

const Calender = (props) => {
	const { value, setValue, singleDate, allowFutureDates } = props;

	return (
		<div className={`${styles['calender-container']}`}>
			{singleDate ? (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						value={value}
						onChange={setValue}
						renderInput={(pickerProps) => {
							return <TextField {...pickerProps} variant='outlined' />;
						}}
					/>
				</LocalizationProvider>
			) : (
				<UYDateRange
					enableOnlyBackwardSelection={!allowFutureDates}
					dateRanges={value}
					onDateRangeChange={setValue}
				/>
			)}
		</div>
	);
};
Calender.defaultProps = {
	setValue: () => {},
	singleDate: false,
};
export default Calender;
