/* eslint-disable no-mixed-operators */
import { useMemo } from 'react';
// import DateRangePickerRsuit from '../../DateRangePickerRsuit/DateRangePickerRsuit';
import DatePickerCustom from '@/Components/DatePickerCustom/DatePickerCustom';

const DatePickerInput = ({ spec, onValueChange }) => (
	<div className='relative w-full'>
		<DatePickerCustom
			style={{
				width: '100%',
			}}
			selectedDate={spec.value ? new Date(spec.value) : null}
			selectDate={(rsuiteDate) => onValueChange(spec.id, rsuiteDate.toISOString().split('T')[0])}
		/>
	</div>
);

const BooleanInput = ({ spec, onValueChange }) => (
	<div className='flex gap-2'>
		<label className='inline-flex items-center'>
			<input
				type='radio'
				className='w-3 h-3 text-blue-600 checked:bg-blue-600'
				checked={spec.value === true}
				onChange={() => onValueChange(spec.id, true)}
			/>
			<span className='ml-1.5 text-[11px] text-gray-700'>Yes</span>
		</label>
		<label className='inline-flex items-center'>
			<input
				type='radio'
				className='w-3 h-3 text-blue-600 checked:bg-blue-600'
				checked={spec.value === false}
				onChange={() => onValueChange(spec.id, false)}
			/>
			<span className='ml-1.5 text-[11px] text-gray-700'>No</span>
		</label>
	</div>
);

const TextualInput = ({ spec, onValueChange }) => (
	<input
		type={(spec.dataType === 'NUMBER' || spec.dataType === 'DECIMAL') ? 'number' : 'text'}
		value={spec.value}
		onChange={(e) => onValueChange(spec.id, e.target.value)}
		placeholder={`Enter ${spec.label}`}
		className='w-full px-2 py-1 text-[11px] border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500'
	/>
);

// eslint-disable-next-line no-unused-vars, max-len
function CoreSpecifications({ specifications = [], onValueChange, mode = [], showAllSpecs = true }) {
	const renderInput = (spec) => {
		switch (spec.dataType) {
			case 'DATE':
				return <DatePickerInput spec={spec} onValueChange={onValueChange} />;
			case 'BOOLEAN':
				return <BooleanInput spec={spec} onValueChange={onValueChange} />;
			default:
				return <TextualInput spec={spec} onValueChange={onValueChange} />;
		}
	};

	const inputContainerClass = useMemo(() => (
		'bg-white rounded shadow-sm'
	), []);

	const filteredSpecs = specifications.filter((spec) => {
		if (showAllSpecs) return true;
		if (Array.isArray(mode)) {
			return (
				(mode.includes('supplier') && spec.supplierMandatory) ||
				(mode.includes('receiver') && spec.receiverMandatory) ||
				(mode.includes('rfq') && spec.rfqMandatory)
			);
		}
		if (mode === 'supplier') return spec.supplierMandatory;
		if (mode === 'receiver') return spec.receiverMandatory;
		if (mode === 'rfq') return spec.rfqMandatory;

		return true;
	});

	return (
		<div className='space-y-2 p-2'>
			{filteredSpecs.map((spec) => {
				// eslint-disable-next-line no-unused-vars
				const { supplierMandatory, receiverMandatory, rfqMandatory } = spec;
				let mandatory = false;
				if (supplierMandatory && (mode === 'supplier' || Array.isArray(mode) && mode.includes('supplier'))) mandatory = true;
				if (receiverMandatory && (mode === 'receiver' || Array.isArray(mode) && mode.includes('receiver'))) mandatory = true;
				if (rfqMandatory && (mode === 'rfq' || Array.isArray(mode) && mode.includes('rfq'))) mandatory = true;

				return (
					<div key={spec?.id} className={`flex items-center gap-2 ${!mandatory ? ' opacity-50 focus-within:outline-none' : ''}`}>
						<label className={`text-[11px] font-medium ${mandatory ? 'text-red-500' : 'text-gray-700'} min-w-[120px] flex-[1]`}>
							{spec.label}
							{mandatory && <span className='text-red-500'>*</span>}
							{spec.uom && (
								<span className='text-[9px] text-gray-500 ml-1'>
									(
									{spec.uom}
									)
								</span>
							)}
						</label>
						<div className={`${inputContainerClass} flex-[.8] ${!mandatory ? 'pointer-events-none' : ''}`}>
							{renderInput(spec)}
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default CoreSpecifications;
