import {
	useCallback, useEffect, useState
} from 'react';
// import { APP_MODULES_LIST } from '../../Constants/user.constansts';
import { classes } from '../../Helpers';
import authServices from '../../Services/auth.services';
import notificationServices from '../../Services/notification.services';
import styles from './AppModules.module.scss';

function AppModules(props) {
	const [appModules, setAppModules] = useState([]);
	const {
		elementId = 'appModules',
		type = '',
		selectedList,
		handleAppModule = () => {},
		handleAllSelection = () => {},
		// eslint-disable-next-line no-unused-vars
		handleRemoveAll = () => {},
	} = props;

	const fetchAppModules = async () => {
		try {
			const response = await authServices.fetchAppModules({
				params: {
					type,
				},
			});
			if (response?.statusCode === 200) {
				setAppModules(response?.data);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchAppModules();
	}, []);

	const handleSelectAll = useCallback(() => {
		const allAppModules = appModules?.map((ele) => {
			return ele?.value;
		});
		handleAllSelection(allAppModules);
	}, [appModules]);

	return (
		<div className={styles.root} id={elementId}>
			{appModules?.length && (
				<div className={styles['select-remove-all']}>
					<div onClick={handleSelectAll} className={styles.select}>
						Select All Modules
					</div>
					<div
						onClick={() => {
							handleRemoveAll([]);
						}}
						className={classes(styles.select, styles.remove)}
					>
						Remove All Modules
					</div>
				</div>
			)}
			<div className={styles['list-container']}>
				{appModules?.map((ele) => {
					const isActive = selectedList?.includes(ele?.value);
					return (
						<div
							key={ele?.id}
							onClick={() => {
								handleAppModule(ele?.value, isActive);
							}}
							className={classes(
								styles.module,
								isActive ? styles.selected : ''
							)}
						>
							{ele?.name}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default AppModules;
