/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import toast from 'react-hot-toast';
// import { usePagination } from '../../Helpers';
import styles from './ArrangePages.module.scss';

const ArrangePages = (props) => {
	const {
		listCount,
		page,
		limit,
		nextPage,
		prevPage,
		setPage,
		reqOptions,
		setAnalyticsType,
		ANALYTICS_TYPE,
		query,
		queryTracker,
	} = props;
	let pageCount = Math.floor(listCount / limit);

	if (listCount % limit !== 0) {
		pageCount += 1;
	}

	useEffect(() => {
		if (page !== 0) {
			setAnalyticsType(ANALYTICS_TYPE, reqOptions);
		}
	}, [page]);

	useEffect(() => {
		if (query === '' && queryTracker === 1) {
			setAnalyticsType(ANALYTICS_TYPE, reqOptions);
		}
	}, [query]);

	const [disabled, setDisabled] = useState(false);

	const loadPrev = async () => {
		try {
			if (page > 1) {
				prevPage(page);
			}
		} catch (err) {
			//
		} finally {
			setDisabled(false);
		}
	};

	const loadNext = async () => {
		try {
			if (page < pageCount) {
				setDisabled(true);
				nextPage(page);
			}
		} catch (err) {
			//
		} finally {
			setDisabled(false);
		}
	};

	const handleChange = (e) => {
		try {
			const newValue = e.target.value;
			e.target.style.width = newValue > 0 ? `${newValue.length}ch` : null;
			if (newValue > pageCount) {
				setDisabled(true);
				setPage(pageCount);
			} else {
				setDisabled(true);
				setPage(Number(newValue));
			}
		} catch (err) {
			//
		} finally {
			setDisabled(false);
		}
	};

	const renderHtml = () => {
		if (pageCount >= 1) {
			return (
				<div className={styles.root}>
					<button disabled={disabled} type='button' onClick={loadPrev}>
						<ArrowBackIcon />
					</button>
					<div
						className={styles['currentPage-totalPage']}
						// onClick={allowEditing}
					>
						<input type='number' value={page} onChange={handleChange} />
						<div>{`/${pageCount}`}</div>
					</div>
					<button disabled={disabled} type='button' onClick={loadNext}>
						<ArrowForwardIcon />
					</button>
				</div>
			);
		}
		return false;
	};

	return renderHtml();
};
export default ArrangePages;
