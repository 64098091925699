/* eslist no-use-before-define: 0 */
import { connect } from 'react-redux';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Typography,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './VendorOrderGrnsModal.module.scss';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { ListTable } from '../../ListTable';
import { urls } from '../../../Constants';
import { FileUploaderModal } from '../FileUploaderModal';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { notificationServices } from '../../../Services';

const COLMAP = {
	'Product Name': 'productName',
	'PO Ordered Qty': 'qty',
	'Fulfilled Qty': 'fulfilled',
};

const SPECIAL_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'PO Ordered Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Fulfilled Qty',
		config: {
			type: 'DATA',
		},
	},
];

const getItemUnitQty = (qty, unit) => {
	switch (unit) {
		case 'gram':
			if (qty > 1000) {
				return {
					qty: Math.floor(qty / 1000),
					unit: 'kg',
				};
			}
			return {
				unit,
				qty,
			};
		default:
			return {
				unit,
				qty,
			};
	}
};

const VendorOrderGrnsModal = (props) => {
	const history = useHistory();
	const { show, data, toggleModal, toggleFileUploaderModal } = props;
	const { grnList = [], heading = 'GRNs', orderType } = data;
	const [expanded, setExpanded] = useState('');

	const handleClose = () => {
		toggleModal(false, {});
		setExpanded('');
	};

	const handleAccordion = (expandedAc) => {
		setExpanded((prev) => {
			if (prev === expandedAc) return '';
			return expandedAc;
		});
	};

	const getSpecialItems = (grnId) => {
		const currGrn = grnList?.find((ele) => {
			return ele?.id === grnId;
		});
		return {
			'Product Name': (id) => {
				const currItem = currGrn?.items?.find((ele) => {
					return ele?.id === id;
				});
				return `${currItem?.productName}${
					currItem?.variantName ? `(${currItem?.variantName})` : ''
				}`;
			},
			'PO Ordered Qty': (id) => {
				const currItem = currGrn?.items?.find((ele) => {
					return ele?.id === id;
				});
				const qtyUnit = getItemUnitQty(currItem?.qty, currItem?.unit);
				return `${qtyUnit?.qty} ${qtyUnit?.unit}`;
			},
			'Fulfilled Qty': (id) => {
				const currItem = currGrn?.items?.find((ele) => {
					return ele?.id === id;
				});
				const qtyUnit = getItemUnitQty(
					currItem?.fulfilled,
					currItem?.unit
				);
				return `${qtyUnit?.qty} ${qtyUnit?.unit}`;
			},
		};
	};

	const handleGenerateBill = (grnId, poId) => {
		const url = `${urls.EXTENSION}${urls.BILLS}/add?grnId=${grnId}&poId=${poId}${orderType ? `&orderType=${orderType}` : ''}`;
		history.push(url);
		handleClose();
	};

	const handleVendorInvoiceUpload = async (fileData, id) => {
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: id,
					},
					data: {
						uploadedDoc: fileData?.file,
						type: 'GRN',
					},
				});
			if (responseData?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
				toggleFileUploaderModal(false);
				handleClose();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const uploadInvoiceCick = (isUpdate, id) => {
		toggleFileUploaderModal(true, {
			heading: isUpdate ? 'Update Invoice' : 'Upload Invoice',
			selectedFile: '',
			confirmText: 'Upload',
			id,
		});
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			width='50rem'
			height='35rem'
		>
			{!grnList?.length > 0 && (
				<div className={styles['no-grn']}>No GRN Found</div>
			)}
			<FileUploaderModal handleConfirm={handleVendorInvoiceUpload} />
			{grnList?.map((grn) => {
				const isBillCreated = grn?.childTransactionRecord?.find(
					(ele) => {
						return ele?.rowType === 'BILL' && ele?.transactionStatus !== 'CANCELLED';
					}
				);
				return (
					<Accordion
						expanded={expanded === grn?.id}
						onChange={() => {
							return handleAccordion(grn?.id);
						}}
						TransitionProps={{
							unmountOnExit: true,
						}}
						style={{
							borderRadius: '12px',
							backgroundColor: '#ECF8F9',
						}}
						sx={{
							'.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root':
								{
									borderRadius: '12px',
								},
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1bh-content'
							id='panel1bh-header'
							sx={{
								'.MuiAccordionSummary-content': {
									margin: '15px 0',
									alignItems: 'center',
									justifyContent: 'space-between',
								},
							}}
						>
							<div className={styles['header-container']}>
								<div className={styles['header-bill-btn']}>
									<Typography
										sx={{
											// width: '100%',
											flexShrink: 0,
											fontSize: '1.25rem',
											fontWeight: 600,
											color: '#212A3E',
											textAlign: 'left',
											marginLeft: '0.5rem',
										}}
									>
										{grn?.id}
									</Typography>
									{!!isBillCreated && (
										<p>Bill Generated</p>
									)}
									{!isBillCreated && !!grn?.items?.length && (
										<Button
											variant='contained'
											color='primary'
											onClick={() => {
												handleGenerateBill(
													grn?.id,
													grn?.orderId
												);
											}}
										>
											Generate Bill
										</Button>
									)}
									<Button
										variant='outlined'
										color='primary'
										onClick={() => {
											uploadInvoiceCick(
												!!grn?.uploadedDoc,
												grn?.id
											);
										}}
									>
										{grn?.uploadedDoc
											? 'Update Invoice'
											: 'Upload Invoice'}
									</Button>
								</div>
								<div className={styles['download-btns']}>
									{grn?.childTransactionRecord?.length >
										0 && (
										<Chip
											size='small'
											className={styles['download-btn']}
											label='View Bills'
											onClick={(e) => {
												e.stopPropagation();
												// eslint-disable-next-line prefer-template
												window.open(urls.EXTENSION + urls.BILLS + '?grnId=' + grn?.id);
											}}
											icon={<PictureAsPdfIcon />}
										/>
									)}
									{grn?.generatedDoc && (
										<Chip
											size='small'
											className={styles['download-btn']}
											label='GRN'
											onClick={(e) => {
												e.stopPropagation();
												if (grn?.generatedDoc) {
													window.open(
														grn?.generatedDoc
													);
												}
											}}
											icon={<PictureAsPdfIcon />}
										/>
									)}
									{grn?.uploadedDoc && (
										<Chip
											size='small'
											className={styles['download-btn']}
											label='Invoice'
											onClick={(e) => {
												e.stopPropagation();
												if (grn?.uploadedDoc) {
													window.open(
														grn?.uploadedDoc
													);
												}
											}}
											icon={<PictureAsPdfIcon />}
										/>
									)}
								</div>
							</div>
							<div>
								<Typography>
									Start Time:
									{grn?.createdAt
										? moment(grn?.createdAt)?.format(
											'HH:MM A'
										)
										: '-'}
								</Typography>
								<Typography>
									End Time:
									{grn?.closedAt
										? moment(grn?.closedAt)?.format(
											'HH:MM A'
										)
										: '-'}
								</Typography>
								{/* <Typography>
									Products:
									{grn?.items?.length}
								</Typography> */}
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<div className={styles['accordion-details']}>
								<ListTable
									hasDateRange
									colMap={COLMAP}
									columns={[]}
									data={grn?.items}
									actions={[...SPECIAL_COLUMNS]}
									special={getSpecialItems(grn?.id)}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.vendorOrderGrnsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleVendorOrderGrnsModal,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VendorOrderGrnsModal);
