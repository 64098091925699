/* eslint-disable no-tabs */
import Box from '@mui/material/Box';
import { Chip, ClickAwayListener } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useMemo, useState } from 'react';
import { get } from 'lodash';
// import SelectPicker from 'rsuite/SelectPicker';
// eslint-disable-next-line no-unused-vars
import { connect, useDispatch } from 'react-redux';
import { Password } from '@mui/icons-material';
import Toggle from 'rsuite/Toggle';
import TextField from '@mui/material/TextField';
import { getCookie } from '../../../Services/Storage Services';
import { useAuth } from '../../Auth/Login/auth';
// import userRolesAndPermissions from '../../../Services/userRolesAndPermissions.services';/
// import { notificationServices } from '../../../Services';
import { navigationActions } from '../../../Actions';
// import { BackdropLoader } from '../../Loaders';
import styles from './AccountMenu.module.scss';

const chipStyle = {
	color: 'black', // Set text color to white
	border: '1px solid black', // Set border color to white
	backgroundColor: 'white',
	padding: '5px',
	height: '32px',
	fontWeight: 520,
	fontSize: '12px',
	borderRadius: '20px',
};

function AccountMenu(props) {
	// eslint-disable-next-line no-unused-vars
	const {
		handleChangePassword,
		toggleShowInternalData,
		showInternalData,
		userPermissions,
	} = props;
	const user = JSON.parse(getCookie('user'));
	// const dispatch = useDispatch();
	const { signOut, userStores } = useAuth();
	const [anchorEl, setAnchorEl] = useState(null);
	// const [isLoading, setIsloading] = useState(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		signOut();
	};

	const [viewUserList, setViewUserList] = useState(false);
	const [searchStoresQuery, setSearchStoresQuery] = useState('');

	// const USERSTORES = () => {
	// 	const stores = userStores;
	// 	const mappedStores = stores?.map((item) => {
	// 		return {
	// 			label: item.name,
	// 			value: item.id,
	// 		};
	// 	});
	// 	return [
	// 		{
	// 			label: 'All Stores',
	// 			value: 0,
	// 		},
	// 		...mappedStores,
	// 	];
	// };

	// const NavStateAccordingToRoles = (userRoles) => {
	// 	dispatch(
	// 		navigationActions.handleUserRolesNavItem({
	// 			userRoles,
	// 		})
	// 	);
	// };

	// const handleStoreSelection = async (storeId) => {
	// 	if (storeId === 0) {
	// 		const userRoles = {};
	// 		const userLevels = {};
	// 		localStorage.setItem(
	// 			'userRolesAndLevels',
	// 			JSON.stringify(userStores)
	// 		);
	// 		userStores?.forEach((store) => {
	// 			store?.levels?.forEach((levelAndRole) => {
	// 				userRoles[levelAndRole.roleId] = true;
	// 				userLevels[levelAndRole.levelId] = true;
	// 			});
	// 		});
	// 		NavStateAccordingToRoles(userRoles);
	// 		dispatch(
	// 			navigationActions.updateUserPermissionsBasedOnLevels({
	// 				userLevels: Object.keys(userLevels),
	// 			})
	// 		);
	// 		return;
	// 	}
	// 	try {
	// 		setIsloading(true);
	// 		const result = userStores.find((userStore) => {
	// 			return userStore.id === storeId;
	// 		});
	// 		const levelAndRoles = result.levels;
	// 		const roles = {};
	// 		const levels = {};
	// 		levelAndRoles.forEach((item) => {
	// 			roles[item.roleId] = true;
	// 			levels[item.levelId] = true;
	// 		});
	// 		NavStateAccordingToRoles(roles);
	// 		const localState = {
	// 			storeId,
	// 			levelAndRoles,
	// 		};
	// 		dispatch(
	// 			navigationActions.updateUserPermissionsBasedOnLevels({
	// 				userLevels: Object.keys(levels),
	// 			})
	// 		);
	// 		localStorage.setItem(
	// 			'userRolesAndLevels',
	// 			JSON.stringify([localState])
	// 		);
	// 	} catch (err) {
	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: err,
	// 		});
	// 	} finally {
	// 		setIsloading(false);
	// 	}
	// };

	const storeName = get(user, 'store.storeName', null);

	// const handleOpen = () => {
	// 	document.body.style.overflow = 'hidden';
	// };

	// const handleCloseStoresMenu = () => {
	// 	document.body.style.overflow = 'auto';
	// };

	const SEARCHED_USER_STORES = useMemo(() => {
		const userStoresList = userStores;
		return userStoresList?.filter((item) => {
			return item?.name
				.toLowerCase()
				?.includes(
					searchStoresQuery.toLowerCase()
				);
		});
	}, [searchStoresQuery, viewUserList, userStores]);

	return (
		<>
			{/* <BackdropLoader showProgress isLoading={isLoading} /> */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				{!!userStores?.length && (
					<Chip
						style={chipStyle}
						size='small'
						onClick={() => {
							setViewUserList(!viewUserList);
							if (viewUserList) {
								setSearchStoresQuery('');
							}
						}}
						label='Permitted Stores'
						icon={(
							<VisibilityIcon
								style={{
									color: 'black',
								}}
							/>
						)}
					/>
				)}
				{viewUserList && (
					<ClickAwayListener
						onClickAway={() => {
							setViewUserList(false);
							setSearchStoresQuery('');
						}}
					>
						<div className={styles?.['user-stores-list-container']}>
							<TextField
								label='Search User Stores.....'
								type='search'
								variant='standard'
								onChange={(e) => {
									setSearchStoresQuery(e?.target?.value);
								}}
								sx={{
									margin: '0px 5px',
								}}
							/>
							<div className={styles?.['user-stores-list']}>
								{SEARCHED_USER_STORES.map((item) => {
									return (
										<div className={styles?.['list-item']}>
											{item.name}
										</div>
									);
								})}
							</div>
						</div>
					</ClickAwayListener>
				)}
				{/* <SelectPicker
					style={{
						width: 150,
						borderRadius: 2,
					}}
					menuStyle={{
						zIndex: 2000,
					}}
					cleanable={false}
					className='selector'
					data={USERSTORES()}
					onSelect={(val) => {
						handleStoreSelection(val);
					}}
					defaultValue={0}
					onOpen={handleOpen}
					onClose={handleCloseStoresMenu}
				/> */}

				<div className='selectorPicker'>
					<Typography
						sx={{
							minWidth: 100,
							display: {
								xs: 'none',
								sm: 'block',
							},
						}}
					>
						{get(user, 'userDetails.name', '')}
					</Typography>
					{typeof storeName === 'string' && (
						<div
							style={{
								fontSize: '.9rem',
							}}
						>
							{storeName}
						</div>
					)}
				</div>
				<Tooltip title='Account settings'>
					<IconButton
						onClick={handleClick}
						size='small'
						color='primary'
						sx={{
							ml: 2,
						}}
					>
						<Avatar
							sx={{
								width: 32,
								height: 32,
								background: user?.color,
							}}
						>
							{get(user, 'userDetails.name[0]', '')}
						</Avatar>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
						'& .MuiMenuItem-root': {
							display: 'flex',
							justifyContent: 'flex-start',
							p: '0.4rem 1.25rem',
						},
					},
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
			>
				{/* <MenuItem>
					<Avatar />
					Profile
				</MenuItem>
				<MenuItem>
					<Avatar />
					My account
				</MenuItem>
				<Divider /> */}
				{/* <MenuItem onClick={handleChangePassword}>
					<ListItemIcon>
						<Password fontSize='small' />
					</ListItemIcon>
					Whats New
				</MenuItem> */}
				{userPermissions?.INTERNAL && (
					<MenuItem>
						{/* <ListItemIcon>
							<Password fontSize='small' />
						</ListItemIcon> */}
						<Toggle
							checked={showInternalData}
							onClick={toggleShowInternalData}
							// size='sm'
						>
							Show Internal Data
						</Toggle>
					</MenuItem>
				)}
				<MenuItem onClick={handleChangePassword}>
					<ListItemIcon>
						<Password fontSize='small' />
					</ListItemIcon>
					Change Password
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<Logout fontSize='small' />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
}

const mapStateToProps = (state) => {
	const { navState, showInternalData, userPermissions } = state.navigation;
	return {
		navState,
		showInternalData,
		userPermissions,
	};
};

const mapDispatchToProps = {
	updateUserPermissions: navigationActions.updateUserPermissions,
	handleRemoveAllRequisites: navigationActions.handleRemoveAllRequisites,
	toggleShowInternalData: navigationActions.toggleShowInternalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
