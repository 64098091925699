import { useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import {
	AddCustomersCreatePage,
	AddCustomersListPage,
	AddCustomersViewPage,
} from '../../../Pages/Analytics/Add Customers';
import CRUDRoutes from '../../CRUD Routes/CRUD.routes';

const AddCustomersRoutes = () => {
	const { path } = useRouteMatch();
	const breadcrumbs = [
		{
			link: `${urls.EXTENSION}${urls.ADD_CUSTOMERS}`,
			name: 'Franchisee',
		},
	];
	return (
		<>
			<CRUDRoutes
				entity='FRANCHISEE'
				path={path}
				listPage={AddCustomersListPage}
				viewPage={<AddCustomersViewPage breadcrumbs={breadcrumbs} />}
				createPage={
					<AddCustomersCreatePage breadcrumbs={breadcrumbs} />
				}
				editPage={
					<AddCustomersCreatePage breadcrumbs={breadcrumbs} isEdit />
				}
			/>
		</>
	);
};

export default AddCustomersRoutes;
