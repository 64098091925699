import styles from './Button.module.scss';

function Button(props) {
	const {
		handleClick, id, text, key, type, className, variant,
	} = props;
	let buttonStyle;
	if (variant === 'primary') {
		buttonStyle = {
			backgroundColor: '#FFB703',
			color: 'black',
		};
	} else if (variant === 'secondary') {
		buttonStyle = {
			backgroundColor: '#F1F1F1',
			border: '1px solid #d1d1d1',
			color: 'black',
		};
	} else if (variant === 'tertiary') {
		buttonStyle = {
			backgroundColor: 'white',
			border: '1px solid #d1d1d1',
			color: 'black',
		};
	} else if (variant === 'delete') {
		buttonStyle = {
			backgroundColor: 'red',
			color: 'white',
		};
	}
	return (
		<button
			type='button'
			key={key}
			id={type}
			className={`${styles.button} ${className}`}
			style={buttonStyle}
			onClick={(e) => {
				handleClick({
					targetId: e?.target?.id,
					itemId: id,
				});
			}}
		>
			{text}
		</button>
	);
}
export default Button;
