import {
	useRouteMatch, Route, Switch
} from 'react-router';
import { urls } from '../../../Constants';
import VariantsThresholdListPage from '../../../Pages/Food Products/VariantsThreshold/VariantsThresholdListPage';

const getSubURL = (entity) => {
	const baseEntity = urls.VARIANTS_THRESHOLD;
	return entity.slice(baseEntity.length);
};

const VariantsThresholdRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}${getSubURL(urls.VARIANTS_THRESHOLD)}`}>
				<VariantsThresholdListPage />
			</Route>
		</Switch>
	);
};

export default VariantsThresholdRoutes;
