/* eslint-disable prefer-template */
import { connect } from 'react-redux';
import { useState } from 'react';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import wastageAnalyticsServices from '@/Services/Analytics Services/wastage-analytics.services';
import { notificationServices } from '@/Services';
import { Button } from '@/Components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/Components/ui/select';

const initialState = {
	reason: '',
	storeType: null,
	storeSubType: null,
};

const AddWastageReasonModal = (props) => {
	const { show, data, toggleModal, staticEnums } = props;
	const { heading } = data ?? {};

	const [state, setState] = useState({
		...initialState,
	});

	const handleClose = () => {
		toggleModal(false, {});
		setState(initialState);
	};

	const handleSubmit = async () => {
		if (!state?.reason) {
			notificationServices.generateNotification({
				message: 'Wastage Reason is Mandatory',
				type: 'error',
			});
			return;
		}
		if (!state?.storeType && !state?.storeSubType) {
			notificationServices.generateNotification({
				message: 'Store Type or Store Sub Type any one is Mandatory',
				type: 'error',
			});
			return;
		}
		try {
			const response = await wastageAnalyticsServices.addWastageReason({
				req: {
					data: {
						...state,
					},
				},
			});
			if (response?.statusCode === 200) {
				handleClose();
				if (data?.onSuccessCallback) {
					data?.onSuccessCallback();
				}
				notificationServices.generateNotification({
					message: response?.message,
					type: 'success',
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};

	const renderBtns = () => {
		return (
			<div className='w-full flex gap-2'>
				<Button
					onClick={handleClose}
					variant='secondary'
					className='flex-1'
				>
					Cancel
				</Button>
				<Button
					onClick={handleSubmit}
					className='bg-uy-color hover:bg-[#f1f1f1] hover:text-black flex-1 text-black'
				>
					Create
				</Button>
			</div>
		);
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			width={data?.width ?? '30rem'}
			height={data?.height ?? '20rem'}
			renderBtns={renderBtns}
		>
			<div className='flex flex-col gap-2 px-2'>
				<div className='flex flex-col items-start gap-1'>
					<Label>Reason</Label>
					<Input
						placeholder='Enter Wastage Reason'
						onChange={(event) => {
							setState((prev) => {
								return {
									...prev,
									reason: event?.target?.value,
								};
							});
						}}
					/>
				</div>
				<div className='flex flex-col items-start gap-1'>
					<Label>Store Type</Label>
					<Select
						value={state?.storeType}
						onValueChange={(value) => {
							setState((prev) => {
								return {
									...prev,
									storeType: value,
								};
							});
						}}
						disabled={state?.storeSubType}
					>
						<SelectTrigger>
							<SelectValue placeholder='Theme' />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							<SelectItem value={null}>None</SelectItem>
							{Object.keys(staticEnums?.StoreTypeEnum)?.map(
								(ele) => {
									return (
										<SelectItem value={ele}>
											{ele}
										</SelectItem>
									);
								}
							)}
						</SelectContent>
					</Select>
				</div>
				<div className='flex flex-col items-start gap-1'>
					<Label>Store Sub Type</Label>
					<Select
						value={state?.storeSubType}
						onValueChange={(value) => {
							setState((prev) => {
								return {
									...prev,
									storeSubType: value,
								};
							});
						}}
						disabled={state?.storeType}
					>
						<SelectTrigger>
							<SelectValue placeholder='Theme' />
						</SelectTrigger>
						<SelectContent className='z-[9999]'>
							<SelectItem value={null}>None</SelectItem>
							{Object.keys(staticEnums?.StoreSubTypeEnum)?.map(
								(ele) => {
									return (
										<SelectItem value={ele}>
											{ele}
										</SelectItem>
									);
								}
							)}
						</SelectContent>
					</Select>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addWastageReasonModal;
	const { staticEnums } = state.auth;
	return {
		show,
		data,
		staticEnums,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddWastageReasonModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddWastageReasonModal);
