import { Tooltip } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import styles from './ProductCard.module.scss';

const ProductCard = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { children, title, img, handleDelete, dataAttrs, isDisabled, className = '' } = props;

	return (
		<div className={`${styles.root} ${className}`}>
			<div className={`${styles['image-wrapper']}`}>
				<img
					className={`${styles.image}`}
					alt='choice'
					src={img}
					loading='lazy'
				/>
				{!isDisabled && (
					<Tooltip title='Delete Item' placement='right-start'>
						<Cancel
							className={`${styles['delete-icon']}`}
							onClick={handleDelete}
							{...dataAttrs}
						/>
					</Tooltip>
				)}
			</div>
			<span className={`${styles.title}`}>{title}</span>
			{children}
		</div>
	);
};

export default ProductCard;
