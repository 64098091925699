/* eslint-disable no-mixed-spaces-and-tabs */
import drawerConstants from '../Constants/drawer.constants';

const initialState = {
	billsDrawer: {
		show: false,
		data: {},
	},
	transactionDrawer: {
		show: false,
		data: {},
	},
	filterDrawer: {
		show: false,
		data: {},
	},
	stockHistoryDrawer: {
		show: false,
		data: {},
	},
	exportFiltersDrawer: {
		show: false,
		data: {},
	},
	debitCreditNoteListDrawer: {
		show: false,
		data: {},
	},
	processSettingsDrawer: {
		show: false,
		data: {},
	},
};

function drawer(state = initialState, action) {
	switch (action.type) {
		case drawerConstants.SHOW_BILLS_DRAWER:
			return {
				...state,
				billsDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_BILLS_DRAWER:
			return {
				...state,
				billsDrawer: {
					show: false,
					data: action.payload,
				},
			};
		case drawerConstants.SHOW_TRANSACTION_DRAWER:
			return {
				...state,
				transactionDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_TRANSACTION_DRAWER:
			return {
				...state,
				transactionDrawer: {
					show: false,
					data: action.payload,
				},
			};
		case drawerConstants.SHOW_FILTER_DRAWER:
			return {
				...state,
				filterDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_FILTER_DRAWER:
			return {
				...state,
				filterDrawer: {
					show: false,
					data: action.payload,
				},
			};
		case drawerConstants.SHOW_STOCK_HISTORY_DRAWER:
			return {
				...state,
				stockHistoryDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_STOCK_HISTORY_DRAWER:
			return {
				...state,
				stockHistoryDrawer: {
					show: false,
					data: action.payload,
				},
			};
		case drawerConstants.SHOW_EXPORT_FILTER_DRAWER:
			return {
				...state,
				exportFiltersDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_EXPORT_FILTER_DRAWER:
			return {
				...state,
				exportFiltersDrawer: {
					show: false,
					data: action.payload,
				},
			};
		case drawerConstants.SHOW_DEBIT_CREDIT_LIST_DRAWER:
			return {
				...state,
				debitCreditNoteListDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_DEBIT_CREDIT_LIST_DRAWER:
			return {
				...state,
				debitCreditNoteListDrawer: {
					show: false,
					data: action.payload,
				},
			};
		case drawerConstants.TOGGLE_MODAL:
			return {
				...state,
				...action.payload,
			};
		// eslint-disable-next-line no-undef
		case drawerConstants.SHOW_SETTINGS_DRAWER:
			return {
            	...state,
				processSettingsDrawer: {
					show: true,
					data: action.payload,
				},
			};
		case drawerConstants.HIDE_SETTINGS_DRAWER:
			return {
				...state,
				processSettingsDrawer: {
					show: false,
					data: action.payload,
				},
			};
		default:
			return state;
	}
}

export default drawer;
