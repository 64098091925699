// eslint-disable-next-line object-curly-newline
import {
	CalendarMonth,
	LocalDining,
	OpenInNew,
	Report,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import styles from './DefectImageModal.module.scss';
import { modalTypes } from '../../../Constants';
import StickyLegends from '../../MySticky/StickyLegends/StickyLegends';

const MODAL_TYPE = modalTypes.DEFECT_IMAGE;

const DefectImageModal = (props) => {
	const { show, data, handleConfirm, toggleModal } = props;

	const { item = {} } = data;

	// eslint-disable-next-line object-curly-newline
	const { source, name, defect, date, link, legendData = {} } = item;
	const { legendKeyValues = null, getValueFunction = null } = legendData;
	const handleClose = () => {
		toggleModal(false);
	};

	const renderBtns = () => {
		return (
			<Button
				variant='outlined'
				endIcon={<OpenInNew />}
				href={link}
				disabled={link == null}
				target='_blank'
			>
				Open Scan
			</Button>
		);
	};

	return (
		<BaseModal
			open={show}
			heading={`${defect ? 'Defect' : 'Product'} Image`}
			handleClose={handleClose}
			handleConfirm={handleConfirm}
			className={styles.root}
			height='auto'
			width='38rem'
			renderBtns={renderBtns}
		>
			<div className={styles.body}>
				{item?.qcData?.id && (
					<div className={styles.qcId}>
						QcId:
						{item?.qcData?.id}
					</div>
				)}
				<div className={styles['image-container']}>
					{legendKeyValues && (
						<div className={styles.labels}>
							<StickyLegends
								sticky={false}
								getValueFunction={getValueFunction}
								legendKeyValues={legendKeyValues}
							/>
						</div>
					)}
					<img src={source} alt='' />
				</div>
				<table className={styles.table}>
					<tr className={styles.food}>
						<td>
							<LocalDining />
							Food
						</td>
						<td>{name}</td>
					</tr>
					{defect && (
						<tr className={styles.defect}>
							<td>
								<Report />
								Defect
							</td>
							<td>{defect}</td>
						</tr>
					)}
					<tr className={styles.date}>
						<td>
							<CalendarMonth />
							Date
						</td>
						<td>{date}</td>
					</tr>
				</table>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal?.[MODAL_TYPE];
	return {
		show,
		data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(MODAL_TYPE, show, data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectImageModal);
