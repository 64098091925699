import { connect } from 'react-redux';
import {
	Accordion, AccordionDetails, AccordionSummary, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useHistory } from 'react-router';
import styles from './WorkOrderItemsModal.module.css';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import BatchBox from '../../BatchBox/BatchBox';
import { urls } from '../../../Constants';

const WorkOrderItemsModal = (props) => {
	const history = useHistory();
	const { show, data, toggleModal, userPermissions } = props;
	const { items = [], heading = 'Purchase Order Items' } = data;
	const [expanded, setExpanded] = useState('');

	const handleClose = () => {
		toggleModal(false, {});
	};

	const handleAccordion = (expandedAc) => {
		setExpanded((prev) => {
			if (prev === expandedAc) return '';
			return expandedAc;
		});
	};

	const handleClick = (itemId, id) => {
		history.push(`${urls.EXTENSION}${urls.PAST_QC}/${itemId}?id=${id}&type=CMS`);
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			width='40rem'
			// height='20rem'
		>
			{items?.map((item) => {
				return (
					<Accordion
						expanded={expanded === item?.variant?.id}
						onChange={() => {
							return handleAccordion(item?.variant?.id);
						}}
						TransitionProps={{
							unmountOnExit: true,
						}}
						style={{
							borderRadius: '12px',
							backgroundColor: '#ECF8F9',
						}}
						sx={{
							'.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root': {
								borderRadius: '12px',
							},
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1bh-content'
							id='panel1bh-header'
							sx={{
								'.MuiAccordionSummary-content': {
									alignItems: 'center',
								},
							}}
						>
							<img
								className={styles['prod-img']}
								src={item?.image}
								alt={item?.name}
							/>
							<Typography
								sx={{
									width: '70%',
									flexShrink: 0,
									fontSize: '1.25rem',
									fontWeight: 600,
									color: '#212A3E',
									textAlign: 'left',
									marginLeft: '0.5rem',
								}}
							>
								{item?.name}
								{' '}
								{item?.variant?.displayName}
							</Typography>
							<Typography>
								Batch Count:
								{item?.batches?.length}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<div className={styles['accordion-details']}>
								<div className={styles['batch-list']}>
									{item?.batches?.map((batch) => {
										return (
											<BatchBox
												header={batch?.recipeName?.split('-')[1]}
												onClick={() => {
													if (userPermissions?.CONFIDENTIAL) {
														handleClick(item?.id, batch?.id);
													}
												}}
												data={batch}
											/>
										);
									})}
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.workOrderItemsModal;
	const { userPermissions } = state.navigation;
	return {
		show,
		data,
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleWorkOrderItemsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderItemsModal);
