import { connect } from 'react-redux';
import { generateRequiredProps } from '../../../../Helpers';
import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
	} = props;
	return (
		<div className={`${styles.root}`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='productCategoryName'
						data-mandatory={mandatoryFields.name}
					>
						Name
					</label>
					<input
						className='form-value'
						type='text'
						id='productCategoryName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				{/* description */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='description'
						data-mandatory={mandatoryFields.description}
					>
						Description
					</label>
					<textarea
						className='form-value'
						type='text'
						id='description'
						name='description'
						onInput={handleInput}
						value={fields.description}
						{...generateRequiredProps(mandatoryFields.description)}
					/>
					<span className='form-error'>{errors.description}</span>
					<span className='form-error'>{errors.brand}</span>
				</div>
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};
const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
