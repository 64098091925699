import { endpoints } from '../Constants';
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const authServices = {
	checkToken,
	fetchAppConfig,
	login,
	reportBug,
	createUser,
	changePassword,
	fetchUsersList,
	fetchAppModules,
	fetchStaticEnums,
};

function checkToken() {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(endpoints.CHECK_TOKEN, requestOptions).then((response) => {
		return response.json().then((responseData) => {
			if (responseData.message === 'authenticated') {
				return true;
			}
			return false;
		});
	});
}

function fetchUsersList(req) {
	const params = {
		...req.params,
	};

	const url = toQueryStringURL(endpoints.USERS_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function fetchAppModules(req) {
	const params = {
		...req?.params,
	};

	const url = toQueryStringURL(endpoints.APP_MODULES, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function fetchAppConfig() {
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			// clientId: 5,
		},
	};
	return fetch(endpoints.APP_CONFIG, requestOptions).then(handleResponse);
}
function login(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Source: 'console',
			Version: process.env.REACT_APP_VERSION,
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.LOGIN, requestOptions).then((response) => {
		return response.json();
	});
}
function createUser(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CREATE_USER, requestOptions).then(handleResponse);
}
function changePassword(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.CHANGE_PASSWORD, requestOptions).then(
		handleResponse
	);
}

function reportBug(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.REPORT_BUG, requestOptions).then((response) => {
		return response.json();
	});
}
function fetchStaticEnums() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.STATIC_ENUMS, requestOptions).then((response) => {
		return response.json();
	});
}

export default authServices;
