/* eslint-disable  */
import {
	ArrowDownward, ArrowUpward, Check
} from '@mui/icons-material';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { analyticsAction } from '@/Actions';
import { useAuth } from '@/Components/Auth/Login/auth';
import { ListTable } from '@/Components/ListTable';
import { urls } from '@/Constants';
import styles from './list.module.scss';
import { classes, convertWeightUnit } from '@/Helpers';

const COLMAP = {
	Store: 'storeName',
	Time: 'QCTime',
	'Order Id': 'OrderId',
	'Item Id': 'itemId',
	'Product Name': 'ProductName',
	'Process' : 'processName',
	Temp: 'Temp',
	// 'Inventory Type': 'Inventory Type',
	Weight: 'Weight',
	qc: 'id',
	Validated: 'validation',
	Identified: 'identification',
	Features: 'annotated',
	'Input From': 'storeName',
};

const Modes = {
	PRODUCTION: 'Ai-Inventory-In',
	INWARD: 'Ai-GRN',
	OUTWARD: 'Ai-Inventory-Out',
};

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Input From',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Time',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Item Id',
	// config: {
	// type: 'DATA',
	// },
	// },
	// {
	// 	title: 'Validated',
	// 	config: {
	// 		type: 'EDIT',
	// 	},
	// },
	// {
	// 	title: 'Identified',
	// 	config: {
	// 		type: 'ANNOTATE',
	// 	},
	// },
	// {
	// 	title: 'Features',
	// 	config: {
	// 		type: 'ANNOTATE',
	// 	},
	// },
	{
		title: 'Weight',
		config: {
			type: 'DATA',
		},
	},
];

const renderColumn = (column) => {
	switch (column) {
		case 'Validated':
		case 'Identified':
		case 'Features':
			return (
				<div
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					{column?.toUpperCase()}
				</div>
			);
		default:
			return null;
	}
};

const getRowStyles = (item) => {
	switch (item?.result) {
		case 'FAILED':
			return styles.critical;
		case 'SKIPPED':
			return styles.skipped;
		case 'OVERRIDDEN':
			return styles.moderate;
		default:
			return styles.normal;
	}
};

function setAttr(text) {
	return <span className={styles.text}>{text}</span>;
}

const LeftOverInventoryList = (props) => {
	const history = useHistory();
	const { data } = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};


	const SPECIAL_ITEMS = {
		ID: (altProps) => {
			return setAttr(data[altProps - 1]?.id ?? '-');
		},
		Weight: (altProps) => {
			const item = data[altProps - 1];
			let failure =
				item?.Weight > item?.MaxWeight / 1000 ? 'OVER' : 'UNDER';
			failure =
				item?.WeightCheckRequired && !item?.IsWeightInRange && failure;
			const itemWeight = get(item, COLMAP.Weight, 0);
			let itemUnit = item?.unit;
			if (item?.unit === 'count') {
				if (measurement === 'US') {
					itemUnit = 'oz';
				} else {
					itemUnit = 'gram';
				}
			}
			return (
				<span
					className={`${styles['no-wrap']} ${styles.text} ${
						failure ? styles.critical : styles.normal
					}`}
				>
					{convertWeightUnit(itemWeight, itemUnit) ?? '-'}
					{data[altProps - 1].IsWeightInRange === false ? (
						<>
							{failure === 'OVER' && <ArrowUpward />}
							{failure === 'UNDER' && <ArrowDownward />}
						</>
					) : (
						<></>
					)}
				</span>
			);
		},
		Temp: (altProps) => {
			let failure =
				data[altProps - 1].Temp > data[altProps - 1].MaxTemp
					? 'OVER'
					: 'UNDER';

			failure =
				data?.[altProps - 1]?.TempCheckRequired &&
				!data?.[altProps - 1]?.IsTempInRange &&
				failure;
			return (
				<span
					className={`${styles['no-wrap']} ${styles.text} ${
						failure ? styles.critical : styles.normal
					}`}
				>
					{get(data[altProps - 1], COLMAP.Temp)?.toFixed(2) ?? '-'}
					{`°${data[altProps - 1]?.tempUnit ?? ''}`}
					{data[altProps - 1].IsTempInRange === false ? (
						<>
							{failure === 'OVER' && <ArrowUpward />}
							{failure === 'UNDER' && <ArrowDownward />}
						</>
					) : (
						<></>
					)}
				</span>
			);
		},
		// Brand: (altProps) => {
		// return (
		// <span className={styles['brand-column']}>
		// <img
		// className={styles.logo}
		// src={data[altProps - 1]?.BrandImage}
		// alt='Brand'
		// />
		// <p>{data[altProps - 1]?.brandName}</p>
		// </span>
		// );
		// },
		Store: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data[altProps - 1]?.storeName ?? '-'}</p>
				</span>
			);
		},
		Time: (altProps) => {
			return (
				<span className={styles['brand-column']}>
				<p>{data[altProps - 1]?.QCTime.slice(8) ?? '-'}</p>
			</span>
			);
		},
		'Process': (altProps) => {
			return setAttr(get(data[altProps-1]?.processName ?? '-'));
		},
		'Product Name': (altProps) => {
			return setAttr(get(data[altProps - 1], COLMAP['Product Name']));
		},
		'Inventory Type': (altProps) => {
			const currItem = data[altProps - 1];
			return setAttr(Modes?.[currItem.type], COLMAP['Inventory Type']);
		},
		'Order Id': (altProps) => {
			const text = get(data[altProps - 1], COLMAP['Order Id']);
			// eslint-disable-next-line prefer-template
			return setAttr(
				`...${text?.substring(text?.length - 6, text?.length - 1)}`
			);
		},
		'Input From' : (altProps) => {
			return setAttr(get(data[altProps - 1], COLMAP['Input From']));
		}
		// 'Item Id': (altProps) => {
		// return setAttr(get(data[altProps - 1], COLMAP['Item Id']));
		// },
		// Validated: (altProps) => {
		// 	const validated = get(data[altProps - 1], COLMAP.Validated);

		// 	if (validated) {
		// 		return <Check />;
		// 	}
		// 	return null;
		// },
		// Identified: (altProps) => {
		// 	const identification = get(data[altProps - 1], [
		// 		COLMAP.Identified,
		// 		'0',
		// 	]);

		// 	let identified = false;

		// 	if (
		// 		identification?.x != null &&
		// 		identification?.y != null &&
		// 		identification?.width != null &&
		// 		identification?.height != null
		// 	) {
		// 		identified = true;
		// 	}

		// 	if (identified) {
		// 		return <Check />;
		// 	}
		// 	return null;
		// },
		// Features: (altProps) => {
		// 	const annotated = get(data[altProps - 1], COLMAP.Features);
		// 	return annotated;
		// },
	};

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ListTable
				fixedHeaderTop={0}
				renderColumn={renderColumn}
				colMap={COLMAP}
				hasDateRange
				special={SPECIAL_ITEMS}
				data={data.map((obj, idx) => {
					const rowStyles = getRowStyles(obj);
					const itemId = get(obj, COLMAP['Item Id']);
					const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						id: idx + 1,
						rowStyles,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.PAST_QC}/${itemId}`,
								search: `id=${qc}`,
								state: {
									from: history?.location?.pathname,
								},
							});
						},
					};
				})}
				columns={[]}
				actions={[...TABLE_COLUMNS]}
				allowExport={false}
			/>
			{/* <ArrangePages
				listCount={61}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				setAnalyticsType={setAnalyticsType}
			/> */}
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LeftOverInventoryList);
