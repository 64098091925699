/* eslint-disable react/jsx-one-expression-per-line */
import { People } from '@mui/icons-material';
// import EditIcon from '@mui/icons-material/Edit';
// import CloseIcon from '@mui/icons-material/Close';
import styles from './IconInput.module.scss';

const IconInput = (props) => {
	const {
		className,
		id,
		type,
		name,
		value,
		handleInput,
		dataType,
		dataId,
		isDisabled = false,
		dataAttrs,
		strikeOff,
		editedValue,
		size,
		extraValue,
		showExtra,
		min = '0',
		max,
		isEdited = false,
		iconText,
		step,
		style = {},
		inputType = 'number',
		iconWrapperClass = '',
	} = props;
	let localExtra = extraValue;
	const convertIconText = (text) => {
		switch (text) {
			case 'gram':
				return 'g';
			case 'count':
				return 'pcs';
			case 'litre':
				return 'ltr';
			case 'ml':
				return 'ml';
			default:
				return text;
		}
	};

	if (showExtra && Number.isNaN(localExtra)) {
		localExtra = 0;
	}
	const CustomIcon = () => {
		switch (type) {
			case 'rupee':
				return (
					<span className={`${styles.icon} ${styles.rupee}`}>₹</span>
				);
			case 'US':
			case 'dollar':
				return (
					<span className={`${styles.icon} ${styles.dollar}`}>$</span>
				);
			case 'percent':
				return (
					<span className={`${styles.icon} ${styles.percent}`}>
						%
					</span>
				);
			case 'serves':
				return <People className={`${styles.icon} ${styles.serves}`} />;
			case 'custom':
				return (
					<span className={`${styles.icon} ${styles.custom}`}>
						{convertIconText(iconText)}
					</span>
				);
			default:
				return <></>;
		}
	};
	return (
		<div className={`${styles.root} ${styles[size]}`} style={style}>
			{/* {strikeOff && <div className={styles.strikeOff} />} */}
			{/* {strikeOff && (
				<div className={styles.cross}>
					<CloseIcon
						style={{
							fontSize: '2rem',
							color: '#a009',
						}}
					/>
				</div>
			)} */}
			{isEdited && (
				<>
					<div className={styles.isEdited}>{editedValue}</div>
					{!showExtra && <div className={styles.squiggle} />}
					{showExtra && <div className={styles.squiggle3} />}
				</>
			)}
			{strikeOff && <div className={styles.strikeOff} />}
			{showExtra && (
				<div className={styles.extraValue}>{localExtra}g</div>
			)}
			<div className={`${styles['icon-wrapper']} ${iconWrapperClass}`}>
				<CustomIcon />
			</div>
			<input
				type={inputType}
				min={min ?? 0}
				max={max}
				name={name}
				onInput={handleInput}
				id={id}
				step={step}
				value={value}
				style={{
					opacity: isDisabled ? 0.5 : 1,
					fontSize: isEdited ? '1rem' : 'auto',
					...(showExtra && {
						padding: showExtra ? '0rem' : 'auto',
						height: showExtra ? '.5rem' : '',
						borderBottom: showExtra ? 'none' : 'auto',
					}),
				}}
				disabled={isDisabled}
				className={`${styles.input} form-value ${className}`}
				data-type={dataType}
				data-id={dataId}
				{...dataAttrs}
			/>
		</div>
	);
};

IconInput.defaultProps = {
	size: 'small',
	max: 100000,
	iconText: '',
	dataAttrs: {},
	step: '1',
};

export default IconInput;
