/* eslint-disable no-tabs */
import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import analyticsAction from '../../../../Actions/analytics.actions';
import { OutwardingImageResults } from '../../../../Components/Analytics/Outwarding';
import ImagesFinder from '../../../../Components/ImagesFinder/ImagesFinder';
import styles from './CookingImagesPage.module.css';

// const source =
// 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg';

const FIND_FILTERS = [
	'store',
	'processTypes',
	'identificationType',
	'brand',
	'product',
	'dateRange',
];

const CookingImagesPage = (props) => {
	const { allCookingResults, fetchCookingImages, resetCookingImages } = props;

	useEffect(() => {
		resetCookingImages();
	}, []);

	const submitFindRequest = ({
		store = null,
		processTypes = null,
		products = [],
		dateRange = {},
	}) => {
		resetCookingImages();
		const data = {
			store,
			processTypes,
			products,
			dateRangeFilter: dateRange?.active
				? {
					from: moment(dateRange?.from).format('YYYY-MM-DD'),
					to: moment(dateRange?.to).format('YYYY-MM-DD'),
				}
				: {},
		};
		fetchCookingImages({
			entity: 'COOKING_ANALYTICS',
			method: 'FIND_COOKING_IMAGES',
			req: {
				data,
			},
		});
	};

	return (
		<div className={styles.root}>
			<ImagesFinder
				FILTERS={FIND_FILTERS}
				onSubmit={submitFindRequest}
			/>
			<div className={styles['image-results']}>
				{allCookingResults?.map((product) => {
					return (
						product.Annotation.length > 0 && (
							<OutwardingImageResults key={product?.id} product={product} />
						)
					);
				})}
				{allCookingResults?.length === 0 && (
					<p className={styles.fallback}>No items to display</p>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { FIND_COOKING_IMAGES: allCookingResults } = state.analytics.COOKING_ANALYTICS;

	return {
		allCookingResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCookingImages: (...args) => {
			dispatch(analyticsAction.gateway(...args));
		},
		resetCookingImages: () => {
			dispatch(analyticsAction.resetCookingImages());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CookingImagesPage);
