/* eslint-disable */
/* eslint-disable quotes */
import { Switch } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import ListTable from '../../../Components/ListTable/ListTable';
import storeServices from '@/Services/Form Services/store.services';
import { handleResponseStreamDownload } from '@/Helpers';
import { notificationServices } from '@/Services';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Store ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Store Id',
		config: {
			type: 'DATA',
		},
	},

];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Delete',
		config: {
			type: 'DELETE',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SUPPORT NUMBER',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MANAGER NAME',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MANAGER NUMBER',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MANAGER EMAIL',
		config: {
			type: 'DATA',
		},
	},
];
const COLMAP = {
	'Store ID': 'id',
	Name: 'name',
	Type: 'type',
	'Client Store Id': 'clientStoreId',
	'SUPPORT NUMBER': 'customersupport',
	'MANAGER NAME': 'managerName',
	'MANAGER NUMBER': 'managerPhone',
	'MANAGER EMAIL': 'managerEmail',
};

const StoreListPage = (props) => {
	const {
		fetchStore, data, query, deleteStore, navState, fetchUserList, userList,
	} = props;
	const { appConfig, userConfig } = useAuth();
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/outlets']?.submenu['/outlets/stores']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	useEffect(() => {
		fetchStore({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleDelete = ({ id }) => {
		deleteStore({
			entity: 'STORES',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
	};

	const handleExport = async () => {
		try {
			const response = await storeServices.exportStoresList();
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					`STORES_${appConfig?.companyName
						?.split(' ')
						?.join('_')
						?.toUpperCase()}.csv`
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};
	const handleType = (id, type) => {
		let subType = data.find((store) => store.id === id)?.subType;
		subType =
			subType === 'DARK_STORE' ? 'Dark Store' : subType?.split('_')?.join(' ');
		return (
			<div className='flex items-center gap-1 justify-center'>
				<span className='text-capitalize'>{type}</span>
				{subType && (
					<span className='text-capitalize text-yellow-500'>
						{' '}
						(
						{subType}
						)
					</span>
				)}
			</div>
		);
	};

	const SPECIAL_ITEMS = {
		Type: (id, type) => {
			return handleType(id, type);
		},
		'SUPPORT NUMBER': (id, supportNumber) => {
			return <span>{supportNumber}</span>;
		},
		'MANAGER NAME': (id, managerName) => {
			return <span>{managerName}</span>;
		},
		'MANAGER NUMBER': (id, managerNumber) => {
			return <span>{managerNumber}</span>;
		},
		'MANAGER EMAIL': (id, managerEmail) => {
			return <span>{managerEmail?.split(',')?.join(' ')}</span>;
		},

		Enabled: (id, isEnabled) => {
			return (
				<Switch
					name='customization'
					value='customization'
					size='small'
					color='primary'
					defaultChecked={isEnabled}
					inputProps={{
						'data-id': id,
						'data-type': 'COMBO',
					}}
				/>
			);
		},
	};

	return (
		<div className='list-page-root'>
			<ListTable
				// fixedHeaderTop={0}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data
					.filter((store) => {
						return (
							store.name
								.toLowerCase()
								.indexOf(query.trim().toLowerCase()) !== -1
						);
					})
					.map((store) => {
						return {
							...store,
							_name: store?.[COLMAP?.Name],
						};
					})}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleDelete={handleDelete}
				onExportClick={handleExport}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.FILTERED_STORES.data.LIST;
	const { userPermissions, navState } = state.navigation;
	const { userList } = state?.auth;
	return {
		data,
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchStore: formActions.gateway,
	deleteStore: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreListPage);
