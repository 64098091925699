/* eslint-disable */
import { HighlightOff } from '@mui/icons-material';
import { classes } from 'src/Helpers';
import { CheckPicker, TreePicker } from 'rsuite';
import { useEffect, useState } from 'react';
import styles from '../POItemSelection/TableRow/TableRow.module.scss';
import quoteServices from '@/Services/QuoteServices/quote.services';
import CoreSpecifications from './CoreSpecifications';
import assetServices from '@/Services/AssetServices/asset.services';


function CreateQuoteTable(props) {
	const {
		product,
		rowStyles,
		isChangeable = true,
		rowError = {},
		customComponents = {},
		showRow,
		setRow,
		row,
		editMode,
	} = props;

	const [disable, setDisable] = useState(true);
	const [assetData, setAssetData] = useState([]);
	const [treeData, setTreeData] = useState([]);
	const [oemList, setOemList] = useState([]);
	console.log('row', row);
	function addRow() {
		return setRow((prevRows) => [
				...prevRows,
				{
				assetType: '',
					oems: [],
					coreSpecifications: [],
					qty: '',
					isNew: true,
				},
			]);
	}

	const fetchAssetList = async () => {
		try {
			const response = await quoteServices?.requestQuoteAssetList();
			if (response?.statusCode === 200) {
				const data = await response?.data;
				setAssetData(data);
			}
		} catch (error) {
			<p>{error}</p>;
		}
	};

	const fetchOemList = async () => {
		try {
			const response = await assetServices?.assetOemListService();
			if (response?.statusCode === 200) {
				const data = await response?.data;
				setOemList(data);
			}
		} catch (error) {
			<p>{error}</p>;
		}
	};


	useEffect(() => {
		const data = assetData.map((items) => ({
			label: items?.assetCategory?.name,
			value: items?.assetCategory?.id,
			children: [
				{
					value: items?.id,
					label: items?.name,
				},
			],
		}));
		const groupedData = data.reduce((acc, item) => {
			const label = item?.label || 'Category';
			if (!acc[label]) {
				acc[label] = {
					label,
					value: 0,
					children: [],
				};
			}
			acc[label].value += item.value;
			acc[label].children.push(...item.children);
			return acc;
		}, {});
		const resultArray = Object.values(groupedData);
		setTreeData(resultArray);
	}, [assetData]);

	useEffect(() => {
		fetchAssetList();
		fetchOemList();
	}, []);

	function delRow(index) {
		const filterRow = row?.filter((_, id) => id !== index);
		setRow(filterRow);
	}


	useEffect(() => {
		if (row.length === 1) {
			setDisable(false);
		} else {
			setDisable(true);
		}
	}, [row]);

	const tableRows = (rowItem, index) => {
		return {
			'Asset Type': () => {
				return (
					<TreePicker
						data={treeData}
						style={{
							width: 245,
						}}
						value={rowItem?.assetType}
						onChange={(value) => {
							const updateRow = [...row];
							updateRow[index] = {...updateRow[index], assetType: value}
							if(rowItem?.isNew){
								updateRow[index] = {...updateRow[index], isNew: false}
								setRow(updateRow);
								addRow();
							}
							else{
								setRow(updateRow);
								if(row?.length === 1){
									addRow();
								}
							}
						}}
						placeholder='Select Asset Type'
					/>
				);
			},
			OEM: () => {
				const filterOem = assetData.filter((item) => item?.id === rowItem?.assetType);
				return (
					<CheckPicker
						data={
							filterOem?.[0]?.assetCategory?.oems?.length > 0
								? filterOem[0].assetCategory.oems.map((item) => ({
									label: item.name,
									value: item.id,
								}))
							: oemList.map((item) => ({
									label: item.name,
									value: item.id,
								}))
						}
						style={{
							width: 245,
						}}
						menuStyle={{
							width: 245,
						}}
						value={rowItem?.oems?.map((item) => item?.id)}
						placeholder='Select OEM'
						onChange={(value) => {
							const updateRow = [...row];
							const selectedOem = filterOem?.[0]?.assetCategory?.oems?.filter((item) => value.includes(item?.id));
							updateRow[index] = { ...updateRow[index], oems: selectedOem ? selectedOem.map((item) => ({
									id: item?.id, name: item?.name,
								}))
								: null }
							setRow(updateRow);
						}}
					/>
				);
			},
			'Core Specification': () => {
				const asset = assetData?.find((item) => item?.id === rowItem?.assetType);
				const specifications = asset?.coreSpecifications || [];
				console.log(rowItem?.coreSpecifications, 'rowItem?.coreSpecifications');
				const data = rowItem?.coreSpecifications?.map((item) => ({
					...item?.coreSpecification,
					value: item?.value === 'true' ? true : item?.value === 'false' ? false : item?.value,
				})) || [];
				console.log(data, 'data');

				const newCoreSpecification = editMode ? [...data] : JSON.parse(JSON.stringify(specifications));

				if (rowItem?.coreSpecifications) {
					rowItem.coreSpecifications.forEach(ele => {
						const reqSpecification = newCoreSpecification?.find(
							(item) => item?.id === ele?.coreSpecification?.id
						);
						if (reqSpecification) {
							reqSpecification.value = ele?.value;
						}
					});
				}
				
				return (
					<CoreSpecifications
						specifications={newCoreSpecification}
						mode={'rfq'}
						onValueChange={(specId, value) => {
							const updatedRow = [...row];
							if (!updatedRow[index].coreSpecifications) {
								updatedRow[index].coreSpecifications = [];
							}
							
							const coreSpecifications = [...updatedRow[index].coreSpecifications];
							const specIndex = coreSpecifications.findIndex(
								spec => spec.coreSpecification?.id === specId
							);
							
							if (specIndex > -1) {
								coreSpecifications[specIndex] = {
									...coreSpecifications[specIndex],
									value
								};
							} else {
								coreSpecifications.push({
									coreSpecification: { id: specId },
									value
								});
							}
							
							updatedRow[index] = {
								...updatedRow[index],
								coreSpecifications
							};
							
							setRow(updatedRow);
						}}
					/>
				);
			},
			QUANTITY: () => {
				return (
					<>
						<div className={classes(styles['editable-input'])}>
							{customComponents?.QUANTITY?.(product)}
							<input
								className={classes(
									styles['custom-input'],
									rowError?.[product?.id]?.quantity
										? styles.error
										: ''
								)}
								type='text'
								min={0}
								value={rowItem?.qty}
								onChange={(e) => {
									const inputValue = e?.target?.value;
									const updateRow = [...row];
									updateRow[index] = {...updateRow[index], qty: inputValue}
									setRow(updateRow);
								}}
								placeholder='Quantity'
								disbaled={!isChangeable}
							/>
						</div>
					</>
				);
			},
		};
	};

	return (
		(showRow || editMode) && (row.map((rowItem, index) => {
			const currentRowData = tableRows(rowItem, index);
			return (
			<tr
				key={index}
				className={classes(styles['table-tr'], rowStyles)}
			>
				{Object.keys(currentRowData)?.map((ele) => {
					return (
						<td
							style={{
								width: ele?.width,
								verticalAlign: 'middle',
							}}
							key={ele}
						>
							{currentRowData[ele]() }
						</td>
					);
				})}
				{disable && (
					<div
						onClick={() => delRow(index)}
						className={styles['remove-container']}
					>
						<HighlightOff className={styles.icon} />
					</div>
				)}
			</tr>
		);
		}))
	);
}

export default CreateQuoteTable;
