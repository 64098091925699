// import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import styles from './ProductionPlan.module.scss';
import { ListTable } from '../../Components/ListTable';
import { classes } from '../../Helpers';
import { formActions, modalActions } from '../../Actions';
import { ProductionPlanModal } from '../../Components/Modals';
import ProductPlanHeader from './ProductPlanHeader/ProductPlanHeader';
import productionPlanServices from '../../Services/Analytics Services/production-plan.services';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	'Production Plan ID': 'id',
	'Fulfilled Store': 'store',
	'Production Plan Name': 'description',
	'Generated Date': 'createdAt',
	'Scheduled for': 'scheduledAt',
};

const TABLE_COLUMNS = [
	{
		title: 'Production Plan ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production Plan Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Generated Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Scheduled for',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Fulfilled Store',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
];

// const getRowStyles = (item) => {
// switch (item?.result) {
// case 'FAILED':
// return styles.critical;
// case 'SKIPPED':
// return styles.skipped;
// case 'OVERRIDDEN':
// return styles.moderate;
// default:
// return styles.normal;
// }
// };

const ProductionPlans = (props) => {
	// const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const { data, toggleProductionPlanModal, stores, fetchStoresList } = props;
	const SPECIAL_ITEMS = {
		'Fulfilled Store': (id) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			const store = stores?.find((ele) => {
				return ele?.id === item?.store;
			});
			return (
				<div
					style={{
						width: '200px',
					}}
				>
					{store?.name}
				</div>
			);
		},
	};

	const handleViewClick = async (ele) => {
		const req = {
			params: {
				planId: ele?.id,
			},
		};
		try {
			const response = await productionPlanServices.getPlanDetails(req);
			if (response?.statusCode === 200) {
				toggleProductionPlanModal(true, {
					listData: response?.data,
					hideButtons: true,
					nonEditable: true,
					date: ele?.scheduledAt,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ProductionPlanModal />
			<ProductPlanHeader />
			<ListTable
				fixedHeaderTop={0}
				colMap={COLMAP}
				hasDateRange
				special={SPECIAL_ITEMS}
				data={data.map((obj) => {
					return {
						...obj,
					};
				})}
				columns={TABLE_COLUMNS}
				actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
				handleViewClick={handleViewClick}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: data } = state?.analytics.PRODUCTION_PLANS;
	return {
		data,
		stores,
	};
};
const mapDispatchToProps = {
	toggleProductionPlanModal: modalActions.toggleProductionPlanModal,
	fetchStoresList: formActions.gateway,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductionPlans);
