import { useRouteMatch, Route } from 'react-router';
import RequestRefundCreatePage from '../../../Pages/Refund/Request Refund/RequestRefundCreatePage';
// eslint-disable-next-line object-curly-newline

const RequestRefundRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Route
			path={path}
			component={RequestRefundCreatePage}
		/>
	);
};

export default RequestRefundRoutes;
