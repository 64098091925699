import { Switch } from '@mui/material';
import { generateRandomKey, generateRequiredProps } from '../../../../Helpers';
import { VariantCheckboxes } from '../../Inputs/VariantCheckboxes';
import { CustomizationSKUs } from '../CustomizationSKUs';
import styles from './CustomizationGroup.module.scss';

const generateDefaultOption = (type) => {
	if (!type || type.length === 0) {
		return (
			<option value='' selected disabled hidden>
				Select a Type
			</option>
		);
	}
	return <></>;
};

const CUSTOMIZATION_TYPES = ['REMOVABLE', 'REPLACEABLE', 'ADDON'];

const CustomizationGroup = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { mandatoryFields, id, item, handleDelete, isDisabled, extraProps } =
		props;

	const handleInput = (e) => {
		if (e.target.dataset.type === 'sku') {
			e.target.dataset.parent = id;
		}
		props.handleInput(e);
	};

	return (
		<div className={styles.root}>
			<fieldset disabled={isDisabled} className='form-fieldset'>
				{/* customization name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor={`groupName-${id}`}
						data-mandatory={
							mandatoryFields.customizations.items[1].name
						}
					>
						Customization Name
					</label>
					<input
						className='form-value'
						type='text'
						id={`groupName-${id}`}
						name='name'
						onInput={handleInput}
						value={item.name}
						data-type='group'
						data-id={id}
						isDisabled={isDisabled}
						{...generateRequiredProps(
							mandatoryFields.customizations.items[1].name
						)}
					/>
				</div>
				{/* customization type */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor={`type-${id}`}
						data-mandatory={
							mandatoryFields.customizations.items[1].type
						}
					>
						Customization Type
					</label>
					<select
						className='form-value'
						id={`type-${id}`}
						name='type'
						onChange={handleInput}
						value={item.type}
						{...generateRequiredProps(
							mandatoryFields.customizations.items[1].type
						)}
						data-type='group'
						data-id={id}
					>
						{generateDefaultOption(item.type)}
						{CUSTOMIZATION_TYPES.map((type) => {
							return (
								<option key={type} value={type}>
									{type}
								</option>
							);
						})}
					</select>
					{/* <span className='form-error'>{errors.type}</span> */}
				</div>
				{item.type && item.type.length > 0 && (
					<>
						{/* customization limit */}
						{item.type !== 'REMOVABLE' && (
							<div className='form-field row'>
								<label
									className='form-label'
									htmlFor={`groupLimit-${id}`}
									data-mandatory={
										mandatoryFields.customizations.items[1]
											.limit
									}
								>
									Max Customization Limit
								</label>
								<input
									className='form-value'
									type='number'
									id={`groupLimit-${id}`}
									name='limit'
									onInput={handleInput}
									value={item.limit}
									data-type='group'
									data-id={id}
									{...generateRequiredProps(
										mandatoryFields.customizations.items[1]
											.limit
									)}
									style={{
										maxWidth: 75,
									}}
								/>
							</div>
						)}
						{/* is applied */}
						<div className='form-field row'>
							<label className='form-label'>Applicable On</label>
							<div className='form-value'>
								<VariantCheckboxes
									items={item.applicableOn}
									fieldName='isApplied'
									dataAttrs={{
										'data-type': 'group',
										'data-parent': id,
									}}
									handleInput={handleInput}
									isDisabled={isDisabled}
								/>
							</div>
						</div>
						{/* delta pricing */}
						{item.type !== 'REMOVABLE' && (
							<div className='form-field row'>
								<label className='form-label'>
									Delta Pricing
								</label>
								<div className='form-value row'>
									<Switch
										{...generateRandomKey()}
										name='deltaPricing'
										value='deltaPricing'
										size='small'
										color='primary'
										onChange={handleInput}
										checked={item.deltaPricing}
										inputProps={{
											'data-id': id,
											'data-type': 'group',
										}}
									/>
								</div>
							</div>
						)}
						{/* skus */}
						<CustomizationSKUs
							id={id}
							selectedSkus={item.skus}
							applicableOn={item.applicableOn}
							customizationType={item.type}
							deltaPricing={item.deltaPricing}
							mandatoryFields={mandatoryFields}
							handleDelete={handleDelete}
							handleInput={handleInput}
							isDisabled={isDisabled}
							extraProps={extraProps}
						/>
					</>
				)}
			</fieldset>
		</div>
	);
};

export default CustomizationGroup;
