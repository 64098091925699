import store from './store';

export default function useFilteredStores() {
	const reduxState = store.getState();
	const permittedStores = reduxState?.navigation?.permittedStores;
	const stores = reduxState?.form?.STORES?.data?.LIST;

	const validStores = {};
	permittedStores.forEach((validStoreLoop) => {
		validStores[validStoreLoop.id] = true;
	});
	const tempStore = stores?.filter((storeFilter) => {
		return permittedStores.length === 0 || validStores[storeFilter.id];
	})?.map((tStore) => {
		return {
			id: tStore.id,
			title: tStore?.name ?? '',
			name: tStore?.name ?? '',
			type: tStore?.type,
		};
	});
	return tempStore;
}
