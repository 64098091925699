/* eslint-disable prefer-template */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import InputBox from '../LabelGeneratorInputBox/InputBox';

function LableGeneratorTable(props) {
	const [rows, setRows] = useState(props?.rows);
	const [columns, setColumns] = useState(props?.columns);
	const [table, setTable] = useState(null);
	const entries = [
		'fat',
		'carbs',
		'calcium',
		'Protein',
		'calories',
	];

	const tableContent = props?.tableContent;
	const setTableContent = props?.setTableContent;

	const handleHeading = (e) => {
		const colId = Number((e?.target?.id)?.split('#')[1]);
		setTableContent((prevTableContent) => {
			const updatedTableContent = prevTableContent ? [...prevTableContent] : [];
			if (updatedTableContent.length) {
				updatedTableContent[0][colId].content = e?.target?.value; // Update the cell value
			}
			return updatedTableContent; // Return the updated table content
		});
	};

	const handleContent = (e) => {
		const rowId = Number((e?.target?.id)?.split('#')[1]);
		const colId = Number((e?.target?.id)?.split('#')[2]);
		setTableContent((prevTableContent) => {
			const updatedTableContent = prevTableContent ? [...prevTableContent] : [];
			if (rowId + 1 < updatedTableContent.length) {
				updatedTableContent[rowId + 1] = [...updatedTableContent[rowId + 1]];
				updatedTableContent[rowId + 1][colId].content = e?.target?.value;
			}
			return updatedTableContent; // Return the updated table content
		});
	};

	const handleSelect = (e) => {
		const rowId = Number((e?.target?.id)?.split('#')[1]);
		const colId = Number((e?.target?.id)?.split('#')[2]);
		setTableContent((prevTableContent) => {
			const updatedTableContent = prevTableContent ? [...prevTableContent] : [];
			if (rowId + 1 < updatedTableContent.length) {
				updatedTableContent[rowId + 1] = [...updatedTableContent[rowId + 1]];
				updatedTableContent[rowId + 1][colId].content = e?.target?.value;
			}
			return updatedTableContent; // Return the updated table content
		});
	};

	const handleCheckBox = (e) => {
		const rowId = Number((e?.target?.id)?.split('#')[1]);
		const colId = Number((e?.target?.id)?.split('#')[2]);
		setTableContent((prevTableContent) => {
			const updatedTableContent = prevTableContent ? [...prevTableContent] : [];
			if (rowId + 1 < updatedTableContent.length) {
				updatedTableContent[rowId + 1] = [...updatedTableContent[rowId + 1]];
				updatedTableContent[rowId + 1][colId].type = e.target.checked ? 'fixed' : 'dynamic';
				updatedTableContent[rowId + 1][colId].content = e.target.checked ? '' : entries[0];
			}
			return updatedTableContent;
		});
	};

	const handleRefresh = () => {
		let content = [];
		for (let i = 0; i <= rows; i++) {
			if (i === 0) {
				let rowContent = [];
				for (let j = 0; j < columns; j++) {
					const currentEle = {
						// type:'fixed'
						content: `Heading${j + 1}`,
					};
					rowContent = [
						...rowContent,
						currentEle,
					];
				}
				content = [...content, rowContent];
			} else {
				let rowContent = [];
				for (let j = 0; j < columns; j++) {
					const currentEle = {
						type: 'fixed',
						content: '',
					};
					rowContent = [
						...rowContent,
						currentEle,
					];
				}
				content = [...content, rowContent];
			}
		}
		setTableContent(content);
		// setTableContentType(type);
	};

	const handleCreateTable = () => {
		// handleRefresh();
		setTable(
			<table>
				<thead>
					<tr key='tabelHeading'>
						{tableContent[0]?.map((cols, colIndex) => {
							return (
								// eslint-disable-next-line react/no-array-index-key
								<th key={colIndex}>
									<input
										id={'tableData#' + colIndex}
										type='text'
										placeholder={'Heading' + colIndex + 1}
										onChange={handleHeading}
									/>
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{tableContent.slice(1).map((row, rowIndex) => {
						return (
							// eslint-disable-next-line react/no-array-index-key
							<tr key={rowIndex}>
								{row.map((cols, colIndex) => {
									return (
										<td
										// eslint-disable-next-line react/no-array-index-key
											key={`cell#${rowIndex}#${colIndex}`}
											style={{
												backgroundColor: ((rowIndex < tableContent.length) && ((!tableContent[rowIndex + 1][colIndex]) || (tableContent[rowIndex + 1][colIndex]?.type === 'fixed'))) ? 'aqua' : 'rgb(168, 255, 63)',
											}}
										>
											<div style={{
												display: 'flex',
											}}
											>
												<label for='tableData'>Fixed</label>
												<input
													id={`checkBox#${rowIndex}#${colIndex}`}
													type='checkbox'
													onChange={handleCheckBox}
													// eslint-disable-next-line max-len
													checked={(!tableContent[rowIndex + 1][colIndex]) || (tableContent[rowIndex + 1][colIndex]?.type === 'fixed')}
												/>
											</div>
											{tableContent[rowIndex + 1][colIndex]?.type === 'dynamic'
												? (
													<select
														// name={selectedToolComponent?.Text?.placeholder}
														id={`cell-content-select#${rowIndex}#${colIndex}`}
														onChange={handleSelect}
													>
														{entries.map((name) => {
															/*
						if (selectedToolComponent?.Table?.val === name) {
							return (
								<option selected value={name}>
									{name}
								</option>
							);
						}
						*/
															return <option value={name}>{name}</option>;
														})}
													</select>
												)
												: <input id={`tableRows#${rowIndex}#${colIndex}`} className='table-input-box' type='text' placeholder='Enter value' onChange={handleContent} />}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};

	useEffect(() => {
		handleRefresh();
	}, []);

	useEffect(() => {
		if (tableContent !== null) {
			handleCreateTable();
		}
	}, [tableContent]);

	return (
		<div className='table-modal'>
			<label>Table Dimensions(Rows x Columns):</label>
			<span
				className='tableRowsColsInputBox'
				style={{
					display: 'flex',
					gap: '1rem',
				}}
			>
				<div
					className='tableRowsColsInputBox'
					style={{
						display: 'flex',
						gap: '1rem',
					}}
				>
					<InputBox
						id='tableRows'
						class='table-input-box'
						type='number'
						min={1}
						info='Enter rows'
						handleTable={(r) => {
							setRows(r);
						}}
					/>
					<InputBox id='tableCols' class='table-input-box' type='number' min={1} info='Enter cols' handleTable={(_, c) => { setColumns(c); }} />
				</div>
				<button id='createTabelButton' onClick={handleRefresh} type='submit'>Create Table</button>
			</span>
			<div
				className='table'
			>
				{table}
			</div>
		</div>
	);
}

export default LableGeneratorTable;
