import { sub } from 'date-fns';
import { paymentSettlementConstants } from '../Constants';

const initialState = {
	date: [
		sub(new Date(), {
			months: 1,
		}),
		new Date(),
	],
	PAYMENT_SETTLEMENTS: {
		metrics: {},
		paymentData: [],
	},
	TRANSACTION: {
		metrics: {},
		transactionData: [],
	},
	link: '',
};

function paymentSettlement(state = initialState, action) {
	switch (action.type) {
		case paymentSettlementConstants.FETCH_PAYMENT_REQUEST:
			return {
				...state,
			};
		case paymentSettlementConstants.FETCH_PAYMENT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case paymentSettlementConstants.FETCH_PAYMENT_FAILURE:
			return {
				...state,
			};
		case paymentSettlementConstants.FETCH_ORDER_INVOICE_LINK_REQUEST:
			return {
				...state,
			};
		case paymentSettlementConstants.FETCH_ORDER_INVOICE_LINK_FAILURE:
			return {
				...state,
			};
		case paymentSettlementConstants.FETCH_ORDER_INVOICE_LINK_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case paymentSettlementConstants.SET_DATE: {
			return {
				...state,
				date: action.payload,
			};
		}
		default:
			return state;
	}
}

export default paymentSettlement;
