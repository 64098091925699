import Drawer from 'rsuite/Drawer';
import styles from './BaseDrawer.module.scss';

const BaseDrawer = (props) => {
	const {
		open,
		onClose,
		header,
		renderBtns = () => {},
		children,
		placement = 'right',
		style = {},
		size = 'lg',
		className = '',
	} = props;
	return (
		<Drawer
			style={style}
			size={size}
			placement={placement}
			open={open}
			onClose={onClose}
			className={`${styles.root} ${className}`}
		>
			<Drawer.Header>
				<Drawer.Title>{header}</Drawer.Title>
				<Drawer.Actions>{renderBtns()}</Drawer.Actions>
			</Drawer.Header>
			<Drawer.Body className={styles.body}>{children}</Drawer.Body>
		</Drawer>
	);
};

export default BaseDrawer;
