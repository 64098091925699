import {
	useCallback, useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';

// import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CircularProgress from '@mui/material/CircularProgress';
import SwapVertIcon from '@mui/icons-material/SwapVert';
// import WebIcon from '@mui/icons-material/Web';
import CachedIcon from '@mui/icons-material/Cached';
import { modalActions } from '../../../../Actions';
import { modalTypes, urls } from '../../../../Constants';
import {
	convertWeightUnit,
	usePagination,
} from '../../../../Helpers';
// eslint-disable-next-line import/no-cycle
import { ImageThumbnail } from '../../../Image';
import styles from './ProductImagesResult.module.scss';
import loadMoreLoader from '../../../../Images/loadmoreLoader.svg';
import { annotationServices } from '../../../../Services';
import { notificationServices } from '../../../../Services/Notification';
import { legendWithColors } from '../../../../Pages/Analytics/ProductAnalysis/ProductImagesPage/ProductImagesPage';

const chipStyle = {
	color: 'white', // Set text color to white
	border: '1px solid white', // Set border color to white
};

const ProductImageResult = (props) => {
	const { page, limit, nextPage } = usePagination({
		customLimit: 6,
	});
	const history = useHistory();

	const [disabled, setDisabled] = useState(false);
	const [checkedItemList, setCheckedItemList] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
		isInternal,
		product,
		fetchQCData,
		egaPercentageSelected,
		setRefreshLoading,
		toggleRetagModal,
		refreshLoading,
		toggleModal,
		isUS,
		fetchProductImagesById,
		identification,
		machineIds,
	} = props;

	const loadMoreImages = async () => {
		try {
			setDisabled(true);
			setLoading(true);
			await fetchProductImagesById({
				productId: product.id,
				page: page + 1,
				machineIds,
				identification,
				limit,
			});
			nextPage();
		} catch (err) {
			//
		} finally {
			setDisabled(false);
			setLoading(false);
		}
	};
	const refresh = useCallback(
		async (addLimit) => {
			try {
				await fetchProductImagesById({
					productId: product.id,
					page: 1,
					identification,
					typeRefresh: true,
					limit: product?.Annotation?.length + (addLimit ?? 0),
				});
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
			} finally {
				setRefreshLoading(false);
			}
		},
		[fetchProductImagesById, product]
	);

	const openModal = (item) => {
		toggleModal(true, {
			item,
		});
	};

	const handleQcPageRoute = useCallback(() => {
		const qcIdsString = checkedItemList
			?.map((ele) => {
				return ele?.qcId;
			})
			.join(',');
		const path = `${
			urls.EXTENSION + urls.QC_HISTORY_LIST
		}?s_no_filter=${qcIdsString}`;

		window.open(path);
		// history.push({
		// pathname: path,
		// });
	}, [checkedItemList, history]);

	const updateChecked = useCallback(
		(item) => {
			setCheckedItemList((prev) => {
				const list = [...prev];
				const index = prev.findIndex((ele) => {
					return ele?.qcId === item?.qcId;
				});
				if (index !== -1) {
					list.splice(index, 1);
				} else {
					list.push(item);
				}
				return list;
			});
		},
		[setCheckedItemList]
	);
	const inverseSelection = useCallback(() => {
		setCheckedItemList((prev) => {
			const newArray = [];
			product.Annotation?.forEach((ele) => {
				if (
					prev.findIndex((annotation) => {
						return annotation?.qcId === ele?.qcId;
					}) === -1
				) {
					newArray.push(ele);
				}
			});
			return newArray;
		});
	}, [setCheckedItemList, product.Annotation]);

	useEffect(() => {
		if (refreshLoading?.labelId === product?.id) {
			refresh(refreshLoading?.addLimit);
		}
	}, [refreshLoading?.labelId]);

	const validate = useCallback(
		async (aiCorrect, labelId = '') => {
			setRefreshLoading({
				labelId: product?.id,
			});
			const qcIdList = checkedItemList.map((ele) => {
				return ele.qcId;
			});
			const data = {
				aiCorrect,
				labelId,
				qcIdList,
			};
			try {
				const resp = await annotationServices.bulkValidation({
					data,
				});
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.message,
				});
				setCheckedItemList([]);
			} catch (err) {
				notificationServices.generateNotification({
					type: 'error',
					message: err,
				});
				// eslint-disable-next-line no-console
				console.log(err);
			}
		},
		[checkedItemList, setCheckedItemList]
	);

	// eslint-disable-next-line no-unused-vars
	const revalidateSKU = () => {
		toggleRetagModal(true, {
			functionToExecute: (labelId) => {
				validate(false, labelId);
				setTimeout(() => {
					setRefreshLoading({
						labelId,
						addLimit: checkedItemList.length,
					});
				}, 200);
			},
		});
	};

	const selectDeselect = useCallback(() => {
		setCheckedItemList((prev) => {
			if (prev.length === 0) {
				return product.Annotation;
			}
			return [];
		});
	}, [setCheckedItemList, product.Annotation]);
	// const MarkAiCorrect = useCallback((item) => {}, [checkedItemList]);
	// const MarkAiInCorrect = useCallback(() => {}, [setCheckedItemList]);

	return (
		<div className={styles.result}>
			<p className={styles['result-header']}>
				<div className={styles?.text}>
					{product.label}
					<span className={styles.count}>
						{` (Showing ${product.Annotation.length} of ${
							product?.count ?? 0
						} image(s))`}
					</span>
				</div>
				{isInternal && (
					<div className={styles?.selectionButtons}>
						{/* <Chip
							style={chipStyle}
							size='small'
							label='refresh'
							onClick={refresh}
						/> */}
						<Chip
							style={{
								...chipStyle,
								border: 'none',
							}}
							size='small'
							onClick={() => {
								refresh();
							}}
							icon={(
								<CachedIcon
									style={{
										color: 'white',
									}}
								/>
							)}
						/>
						{checkedItemList?.length > 0 && (
							<>
								<Chip
									style={chipStyle}
									size='small'
									onClick={() => {
										validate(true);
									}}
									label='Mark Ai-Correct'
									icon={(
										<DoneAllIcon
											style={{
												color: 'white',
											}}
										/>
									)}
								/>

								<Chip
									style={chipStyle}
									size='small'
									label='Mark Ai-Incorrect'
									onClick={revalidateSKU}
									icon={(
										<CloseIcon
											style={{
												color: 'white',
											}}
										/>
									)}
								/>
							</>
						)}

						<Chip
							style={chipStyle}
							size='small'
							label='Invert Selection'
							onClick={inverseSelection}
							icon={(
								<SwapVertIcon
									style={{
										color: 'white',
									}}
								/>
							)}
						/>

						{checkedItemList?.length > 0 && (
							<>
								<Chip
									onClick={selectDeselect}
									style={chipStyle}
									size='small'
									label={
										checkedItemList?.length === 0
											? 'Select All'
											: 'Deselect All'
									}
								/>
								<Chip
									style={chipStyle}
									icon={(
										<CallMadeIcon
											style={{
												color: 'white',
											}}
										/>
									)}
									onClick={handleQcPageRoute}
									size='small'
									label='Qc page'
								/>
							</>
						)}
					</div>
				)}
			</p>
			<div className={styles['image-grid']}>
				{refreshLoading?.labelId === product?.id && isInternal && (
					<div className={styles.refreshLoading}>
						<CircularProgress />
					</div>
				)}
				{product.Annotation.map((annotation, index) => {
					return (
						<ImageThumbnail
							checkedItemList={checkedItemList}
							updateChecked={updateChecked}
							annotation={annotation}
							key={annotation?.qcId ?? annotation?.id ?? index}
							source={annotation.image}
							identificationType={annotation.ai}
							isInternal={isInternal}
							isUS={isUS}
							egaPercentageSelected={egaPercentageSelected}
							data={annotation}
							categoryHeading={isInternal && product?.label}
							machineDetection={
								isInternal && annotation?.machineDetection
							}
							featureCount={annotation.featureCount}
							onClick={async () => {
								const qcData = await fetchQCData(
									annotation.qcId
								);
								openModal({
									source: annotation.image,
									name: qcData?.ProductName,
									product: product.label,
									date: qcData?.QCTime,
									legendData: {
										legendKeyValues: legendWithColors,
										getValueFunction: (key, value) => {
											if (
												egaPercentageSelected &&
												key !== 'confidence'
											) {
												return {
													color: value?.color,
													fullLabel: `${
														value?.label
													} ${
														value?.hasUnit
															? convertWeightUnit(
																annotation?.[
																	key
																],
																isUS
																	? 'oz'
																	: 'gram'
															)
															: annotation?.[key]
													}`,
												};
											}
											if (
												key === 'confidence' &&
												isInternal &&
												annotation
													?.machineDetection?.[0]
													?.confidence
											) {
												return {
													color: value?.color,
													fullLabel: `${value?.label} ${annotation?.machineDetection?.[0]?.confidence}`,
												};
											}
											return {};
										},
									},
									qcData,
									link:
										qcData != null
											? `${urls.EXTENSION}${urls.PAST_QC}/${qcData?.itemId}?id=${qcData?.id}`
											: null,
								});
							}}
						/>
					);
				})}
				{product.count && product.Annotation.length < product.count && (
					<button
						// disabled={disabled}
						disabled={disabled}
						type='button'
						className={styles['load-more']}
						onClick={loadMoreImages}
					>
						{loading ? (
							<div>
								<img src={loadMoreLoader} alt='loader' />
								<p>Loading</p>
							</div>
						) : (
							'Load More'
						)}
					</button>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	return {
		productsList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(modalTypes.DEFECT_IMAGE, show, data)
			);
		},
		toggleRetagModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(modalTypes.RETAG, show, data));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductImageResult);
