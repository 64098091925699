import _ from 'lodash';
import {
	getUnitConvertedQuantity,
	getUnitConvertedUnit,
} from '../../../Helpers';
import { notificationServices } from '../../../Services';

const getPriceUnit = (
	variant,
	measurementType,
	productUnit,
	priceKey = 'costPrice'
) => {
	let itemPrice = variant?.[priceKey];
	let itemUnit = '';
	if (productUnit === 'count') {
		itemUnit = 'piece';
		return [itemPrice, itemUnit];
	}
	if (productUnit === 'ltr') {
		itemUnit = 'ltr';
		return [itemPrice, itemUnit];
	}
	// if (productUnit === 'ml') {
	// itemUnit = 'ml';
	// return [itemPrice, itemUnit];
	// }

	if (productUnit === 'floz') {
		// if ((itemPrice * 128) > 1 && Number.isInteger(itemPrice * 128)) {
		if (itemPrice * 128 >= 1) {
			itemUnit = 'gal';
			itemPrice *= 128;
		} else {
			itemUnit = 'floz';
		}
		return [itemPrice, itemUnit];
	}
	if (productUnit === 'oz') {
		if (itemPrice * 16 > 1) {
			itemPrice *= 16;
			itemUnit = 'lbs';
		} else {
			itemUnit = 'oz';
		}
	}
	if (productUnit === 'gram') {
		if (itemPrice * 1000 > 1) {
			itemPrice *= 1000;
			itemUnit = 'kg';
		} else {
			itemUnit = 'g';
		}
	}
	if (productUnit === 'ml') {
		if (itemPrice * 1000 > 1) {
			itemPrice *= 1000;
			itemUnit = 'ltr';
		} else {
			itemUnit = 'ml';
		}
	}
	return [itemPrice, itemUnit];
};

const convertPrice = (price, unit) => {
	if (unit === 'lbs') {
		return price / 16;
	}
	if (unit === 'kg' || unit === 'ltr') {
		return price / 1000;
	}
	if (unit === 'gal') {
		return price / 128;
	}
	return price;
};

const getMinMaxUnit = (productUnit, weight, measurementType) => {
	let tempUnit = productUnit;
	if (productUnit === 'count') {
		if (measurementType === 'US') {
			tempUnit = 'oz';
		} else {
			tempUnit = 'gram';
		}
	}
	return getUnitConvertedUnit(weight, tempUnit, true);
};

const getProductUnit = (itemUnit, measurementType) => {
	if (itemUnit === 'count') {
		if (measurementType === 'US') {
			return 'oz';
		}
		return 'gram';
	}
	return itemUnit;
};

const variantsConfig = {
	initialState: {
		fields: {
			name: '',
			unit: '',
			skuType: '',
			categoryName: '',
			subCategoryName: '',
			brand: '',
			image: '',
			moq: null,
			purchaseUnit: null,
			variants: [],
		},
	},
	newVariant: {
		id: '',
		isDisabled: false,
		displayName: 'A',
		clientVariantId: null,
		description: '',
		moq: null,
		containerWeight: null,
		containerUnit: '',
		min: null,
		max: null,
		minUnit: '',
		maxUnit: '',
		servingQtyPercentage: '',
		serves: '',
		sellingPrice: null,
		transferPrice: null,
		costPrice: null,
		costPriceUnit: '',
		isDefault: false,
		factorPurchaseUnit: 1,
	},
	serializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.productName,
				unit: data.unit,
				purchaseUnit: data.purchaseUnit,
				image: data.productImageUrl,
				skuType: data.skuType,
				categoryName: data?.category ?? '-',
				subCategoryName: data?.subCategory ?? '-',
				brand: data.brandName,
				moq: data?.moq,
				containerWeight: data?.containerWeight,
				variants: data.variants.map((currVariant) => {
					const [costPrice, costPriceUnit] = getPriceUnit(
						currVariant,
						data?.measurement,
						(data?.purchaseUnit || data?.unit)
					);
					const [transferPrice, transferPriceUnit] = getPriceUnit(
						currVariant,
						data?.measurement,
						data?.unit,
						'transferPrice'
					);
					const [sellingPrice, sellingPriceUnit] = getPriceUnit(
						currVariant,
						data?.measurement,
						data?.unit,
						'sellingPrice'
					);
					let itemUnit = data?.unit;
					if (
						itemUnit === 'count' ||
						itemUnit === 'litre' ||
						itemUnit === 'ml' ||
						itemUnit === 'floz'
					) {
						if (data?.measurement === 'US') {
							itemUnit = 'oz';
						} else {
							itemUnit = 'g';
						}
					}

					return {
						...currVariant,
						minWeight: getUnitConvertedQuantity(
							currVariant?.minWeight,
							getProductUnit(data?.unit, data?.measurement),
							true
						),
						maxWeight: getUnitConvertedQuantity(
							currVariant?.maxWeight,
							getProductUnit(data?.unit, data?.measurement),
							true
						),
						minUnit: getMinMaxUnit(
							data?.unit,
							currVariant?.minWeight,
							data?.measurement
						),
						maxUnit: getMinMaxUnit(
							data?.unit,
							currVariant?.maxWeight,
							data?.measurement
						),
						containerWeight: getUnitConvertedQuantity(
							currVariant?.containerWeight,
							itemUnit,
							true
						),
						containerUnit: getUnitConvertedUnit(
							currVariant?.containerWeight,
							itemUnit,
							true
						),
						costPrice,
						costPriceUnit,
						transferPrice,
						transferPriceUnit,
						sellingPrice,
						sellingPriceUnit,
						calculatedRecipeCost: currVariant.calculatedRecipeCost,
						calculatedRecipeCostUnit: getUnitConvertedUnit(
							1001,
							data?.unit
						),
					};
				}),
			};
			return newState;
		});
	},
	deserializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields.variants', () => {
			const skuType = state?.fields?.skuType;
			const newState = state.fields.variants.map((currVariant, idx) => {
				let tempVariant = {};
				const weightChecks = ['lbs', 'kg', 'gal', 'ltr'];
				tempVariant = {
					...tempVariant,
					...currVariant,
					minWeight: weightChecks?.includes(currVariant?.minUnit)
						? getUnitConvertedQuantity(
							currVariant?.minWeight,
							currVariant?.minUnit,
							true
						)
						: currVariant?.minWeight,
					maxWeight: weightChecks?.includes(currVariant?.maxUnit)
						? getUnitConvertedQuantity(
							currVariant?.maxWeight,
							currVariant?.maxUnit,
							true
						)
						: currVariant?.maxWeight,
					containerWeight: weightChecks?.includes(
						currVariant?.containerUnit
					)
						? getUnitConvertedQuantity(
							currVariant?.containerWeight,
							currVariant?.containerUnit,
							true
						)
						: currVariant?.containerWeight,
				};
				tempVariant.size = `size${idx + 1}`;
				tempVariant.costPrice = convertPrice(
					tempVariant?.costPrice,
					tempVariant?.costPriceUnit
				)?.toString();
				if (skuType !== 'INGREDIENT') {
					tempVariant.transferPrice = convertPrice(
						tempVariant?.transferPrice,
						tempVariant?.transferPriceUnit
					)?.toString();
					tempVariant.sellingPrice = convertPrice(
						tempVariant?.sellingPrice,
						tempVariant?.sellingPriceUnit
					)?.toString();
				} else {
					tempVariant.transferPrice = null;
					tempVariant.sellingPrice = null;
				}
				return tempVariant;
			});
			return newState;
		});
	},
	validator: (req) => {
		for (
			let variants = 0;
			variants < req.data.variants.length;
			variants++
		) {
			if (req.data.variants[variants].costPrice <= 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Variants cost Price should be more than zero',
				});
				return false;
			}
			// if (!req.data.variants[variants].description) {
			// notificationServices.generateNotification({
			// type: 'error',
			// message: "Variants Description can't be empty",
			// });
			// return false;
			// }
			if (!req.data.variants[variants].displayName) {
				notificationServices.generateNotification({
					type: 'error',
					message: "Variants Display Name can't be empty",
				});
				return false;
			}
			if (
				req.data.variants[variants].displayName &&
				req.data.variants[variants].displayName.length === 0
			) {
				notificationServices.generateNotification({
					type: 'error',
					message: "Variants Display Name can't be empty",
				});
				return false;
			}
			// if (
			// req.data.variants[variants].description &&
			// req.data.variants[variants].description.length === 0
			// ) {
			// notificationServices.generateNotification({
			// type: 'error',
			// message: "Variants Description can't be empty",
			// });
			// return false;
			// }
		}
		return true;
	},
};

export default variantsConfig;
