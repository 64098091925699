import { endpoints } from '../Constants';
import { authHeader } from '../Helpers';
import handleResponse from './helpers';

const geoDataServices = {
	getGeoData,
};
function getGeoData() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.GETGEODATA, requestOptions).then(handleResponse);
}
export default geoDataServices;
