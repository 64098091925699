/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable object-curly-newline */
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../../Constants';
import COGDashboard from '../../../Pages/COGDashboard/COGDashboard';
import COGSHistory from '../../../Pages/COGDashboard/COGSHistory';
import PastCogsAnalysisHistoryPage from '../../../Pages/COGDashboard/PastCogsAnalysisHistoryPage';

const getSubURL = (entity) => {
	const baseEntity = urls.COGS_ANALYTICS;
	return entity.slice(baseEntity.length);
};
const COGSAnalyticsRoutes = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route path={`${path}${getSubURL(urls.COGS_ANALYSIS)}`} exact>
					<COGDashboard />
				</Route>
				<Route
					path={`${path}${getSubURL(urls.COGS_ANALYSIS_LIST)}`}
					exact
				>
					<PastCogsAnalysisHistoryPage
						breadcrumbs={[
							{
								link: urls.EXTENSION + urls.COGS_ANALYSIS,
								name: 'COGS Analysis',
							},
							{
								link: '',
								name: 'COGS Analysis History List',
							},
						]}
					/>
				</Route>
				<Route
					path={`${path}${getSubURL(urls.COGS_HISTORY_LIST)}`}
					exact
				>
					<COGSHistory />
				</Route>
			</Switch>
		</div>
	);
};

export default COGSAnalyticsRoutes;
