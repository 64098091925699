import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../../Pages';
import InvoiceCreatePage from '../../../../Pages/InvoicesPage/InvoiceCreatePage/InvoiceCreatePage';
import InvoicesPage from '../../../../Pages/InvoicesPage/InvoicesPage';

const InvoiceRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				{/* <MyMachinesPage createPath={`${path}/add`} /> */}
				<InvoicesPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<InvoiceCreatePage />}
					entity='MACHINES'
				/>
			</Route>
		</Switch>
	);
};

export default InvoiceRoutes;
