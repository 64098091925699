/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
// eslint-disable-next-line object-curly-newline
import { Badge, Button, IconButton } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowCircleRight, Tune } from '@mui/icons-material';
import { useLocation, useHistory } from 'react-router';
import analyticsAction from '../../../Actions/analytics.actions';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import { classes, usePagination, useQuery, useSearch } from '../../../Helpers';
import styles from './QCHistory.module.scss';
import QualityAnalysisListPage from './QualityAnalysisListPage';
import Calender from '../../../Components/Forms/Payment Settlements/Calender/Calender';
import { formActions, modalActions } from '../../../Actions';
import FilterModal from '../../../Components/Filter Modal/FilterModal';
import { BackdropLoader } from '../../../Components/Loaders';
import aiAnalyticsActions from '../../../Actions/aiAnalytics.actions';
import excel from '../../../Images/excel.svg';

const ANALYTICS_TYPE = analyticsTypeConstant.QUALITY_HISTORY_LIST_DATA;

const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	ORDER_ID: 'Search by Order Id',
	ITEM_ID: 'Search by Item Id',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	PRODUCT_NAME: 'ProductName',
	ORDER_ID: 'OrderId',
	ITEM_ID: 'itemId',
	VALIDATED: 'validation',
	IDENTIFIED: 'identification',
	FEATURES: 'annotated',
	// BRAND_NAME: 'brandName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'PRODUCT_NAME',
			title: 'Product Name',
			get: 'ProductName',
		},
		{
			key: 'ORDER_ID',
			title: 'Order ID',
			get: 'OrderId',
		},
		{
			key: 'ITEM_ID',
			title: 'Item ID',
			get: 'itemId',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const COLMAP = {
	Store: 'storeName',
	'QC TIME': 'QCTime',
	'Order Id': 'OrderId',
	'Item Id': 'itemId',
	'Product Name': 'ProductName',
	'Inventory Type': 'Inventory Type',
	Validated: 'validation',
	Identified: 'identification',
	Features: 'annotated',
	Weight: 'Weight',
	Temp: 'Temp',
	qc: 'id',
	Count: 'count',
};

const Modes = {
	PRODUCTION: 'Ai-Inventory-In',
	INWARD: 'Ai-GRN',
	OUTWARD: 'Ai-Inventory-Out',
};

const TABLE_COLUMNS = [
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'QC TIME',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Item Id',
	// config: {
	// type: 'DATA',
	// },
	// },
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Inventory Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Validated',
		config: {
			type: 'EDIT',
		},
	},
	{
		title: 'Identified',
		config: {
			type: 'ANNOTATE',
		},
	},
	{
		title: 'Features',
		config: {
			type: 'ANNOTATE',
		},
	},
	{
		title: 'Weight',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Count',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Temp',
		config: {
			type: 'DATA',
		},
	},
];

const FILTERS = [
	'BRAND_FILTER',
	'UNDETECTED_PRODUCTS_FILTER',
	'MEAL_TYPE_FILTER',
	'VALIDATED_DATA_FILTER',
	'FEATURES_DATA_FILTER',
	'IDENTIFIED_DATA_FILTER',
	'PRODUCT_COUNT_FILTER',
	'STORE_FILTER',
	'PROCESS_TYPE_FILTER',
	'SKU_TYPE_FILTER',
];

const QCHistoryListData = (props) => {
	const {
		dateRangeFilter,
		data,
		setDateRangeFilter,
		fetchData,
		setQCSearchResults,
		searchQuery,
		searchBy,
		fetchAiQcData,
		// applyFilters,
		toggleModal,
		brandFilter,
		brandData,
		gateway,
		exportAiQcHistoryAnalytics,
	} = props;

	const { page, limit } = usePagination({
		customLimit: 10,
	});
	const [aiFilters, setAiFilters] = useState(false);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);
	const params = new URLSearchParams(location.search);

	// console.log('1', data);
	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [data]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		setShouldAnimate(true);
	};

	const getData = () => {
		const { from, to } = dateRangeFilter;
		if (from != null && to != null) {
			fetchData({
				params: {
					page,
					limit,
				},
			});
			setIsDataFetched(true);
		}
	};

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
		if (brandData.length === 0) {
			gateway({
				entity: 'BRANDS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		const obj = location?.aiFilter;
		if (obj) {
			setAiFilters(obj);
		}
	}, [location.search]);

	const fetchAiData = (reqData) => {
		try {
			setLoading(true);
			fetchAiQcData({
				req: {
					data: {
						...reqData,
					},
				},
			});
		} catch (error) {
			// console.log({
			// error,
			// });
		} finally {
			setLoading(false);
		}
	};

	const tryFetchAiData = () => {
		if (!params.has('AI')) return;
		const aiFilter = [];
		Array.from(params.entries()).forEach(([key, val]) => {
			let value = val;
			if (
				key === 'AI' ||
				key === 'clear' ||
				key === 'from' ||
				key === 'to'
			) {
				return;
			}
			if (!Number.isNaN(Number(val))) value = Number(val);
			if (val === 'null') value = null;
			const type = 'equal';
			aiFilter.push({
				column: key,
				value,
				type,
			});
		});
		fetchAiData({
			aiFilter,
			dateRangeFilter: {
				from: params.get('from').includes('T')
					? params.get('from').split('T')[0]
					: params.get('from'),
				to: params.get('to').includes('T')
					? params.get('to').split('T')[0]
					: params.get('to'),
			},
		});
	};

	useEffect(() => {
		tryFetchAiData();
	}, [location.search]);

	useEffect(() => {
		if (aiFilters) {
			Object.entries(aiFilters).forEach(([key, value]) => {
				params.set(key, value);
			});
			params.set('AI', true);
			history.replace({
				pathname: location.pathname,
				search: params.toString(),
			});
		}
	}, [aiFilters]);

	useEffect(() => {
		if (params.has('s_no_filter')) {
			const values = params.get('s_no_filter');
			const sNoArray = values.split(',').map((ele) => {
				return Number(ele);
			});
			fetchAiData({
				sNoArray,
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setQCSearchResults('', data, 'ORDER_ID');
		}
	}, [queryParams]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const generateData = () => {
		const filteredData = data.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		setQCSearchResults(
			query,
			filteredData,
			SEARCH_BY?.options?.[searchField]?.key
		);
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	const exportData = useCallback(() => {
		const downloadBtn = document.createElement('a');
		const pixelsArray = Array(8)
				.fill()
				.map((_, index) => { return index; });
		const header = [
			...(TABLE_COLUMNS?.map((ele) => {
				return ele?.title;
			}) || []),
			// eslint-disable-next-line prefer-template
			...pixelsArray?.map((pixelIdx) => { return 'PIXEL' + pixelIdx; }),
		];

		const temp = TABLE_COLUMNS?.map((col) => { return COLMAP?.[col?.title]; });

		const fileData = data?.map((ele) => {
			const mappedValues = temp?.map((key) => {
				if (key === 'Inventory Type') return Modes?.[ele.type];
				if (key === 'identification') {
					return (
							Boolean(ele?.identification?.x) &&
							Boolean(ele?.identification?.y) &&
							Boolean(ele?.identification?.width) &&
							Boolean(ele?.identification?.height)
						);
					}
				if (key === 'validation') return !!ele?.validation;
				return ele[key];
			});

			// const pixelValues = Object.values(ele?.tempMapping || {}).map(
			// (value) => { return value?.value ?? '-'; }
			// );

			const pixelValues = pixelsArray?.map((key) => {
				if (ele?.tempMapping?.[key]) {
					return ele?.tempMapping?.[key]?.value;
				}
				return '-';
			});

			return mappedValues.concat(pixelValues);
		});

		const csvData = [[...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = 'Quality_history_CSV';
		downloadBtn.click();
	}, [data]);
	// const applyBrandFilter = (selectedList) => {
	// applyFilters({
	// BRAND_FILTER: {
	// selectedList,
	// },
	// });
	// };

	return (
		<>
			<BackdropLoader showProgress isLoading={loading} />
			<div className={styles.root}>
				{/* <ListModal
				handleConfirm={applyBrandFilter}
				selectionBehaviour='SHOW'
			/> */}
				<FilterModal
					type='quality'
					filters={FILTERS}
					clear={queryParams.has('clear')}
				/>
				{!(
					location?.aiFilter ||
					params.has('AI') ||
					params.has('s_no_filter')
				) && (
					<div className={styles.header}>
						<SearchBar
							value={query}
							placeholder={PLACE_HOLDERS[searchField]}
							handleInput={handleSearch}
							handleChange={handleSearchBy}
							searchBy={
								SEARCH_BY != null
									? {
											...(SEARCH_BY ?? {}),
											current: searchField,
									  }
									: null
							}
							className={styles.search}
						/>
						<Badge
							className={styles['brand-filter']}
							badgeContent={
								brandFilter?.selectedList?.length ?? 0
							}
							color='primary'
						>
							<Button
								variant='outlined'
								onClick={() => {
									toggleModal(true, {
										heading: 'Filter',
										list: brandData.map((brand) => {
											return {
												id: brand.id,
												title: brand.name,
											};
										}),
										selected: brandFilter.selectedList,
									});
								}}
							>
								Filters
								<Tune fontSize='medium' />
							</Button>
						</Badge>
						<div>
							Total:
							{generateData().length}
						</div>
						<Calender
							value={dateRangeFilterArray}
							setValue={setDate}
						/>
						<div
							className={styles.downloadData}
							onClick={exportAiQcHistoryAnalytics}
						>
							<img src={excel} alt='excel' />
						</div>
						<IconButton
							type='submit'
							className={classes(
						styles.btn,
						shouldAnimate ? styles?.animate : '',
						isDataFetched ? styles?.disabled : ''
					)}
							onClick={getData}
						>
							<ArrowCircleRight />
						</IconButton>
					</div>
				)}

				<QualityAnalysisListPage
					aiTrue={
						location?.aiFilter ||
						params.has('AI') ||
						params.has('s_no_filter')
					}
					data={generateData()}
					ANALYTICS_TYPE={ANALYTICS_TYPE}
					fetchDataAfterSetting={false}
					TABLE_COLUMNS={TABLE_COLUMNS}
					COLMAP={COLMAP}
				/>
			</div>
		</>
	);
};

QCHistoryListData.defaultProps = {};

const mapStateToProps = (state) => {
	const { DATE_RANGE_FILTER: dateRangeFilter, BRAND_FILTER: brandFilter } =
		state.analytics;
	const { LIST_DATA: data } = state.analytics.QUALITY_ANALYTICS;
	// console.log('3', data);
	const brandData = state.form.BRANDS.data.LIST;
	const { query: searchQuery, searchBy } =
		state.analytics.QUALITY_ANALYTICS.SEARCH_DATA;
	return {
		dateRangeFilter,
		searchQuery,
		data,
		searchBy,
		brandData,
		brandFilter,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setQCSearchResults: analyticsAction.setQCSearchResults,
	fetchData: analyticsAction.fetchData,
	fetchAiQcData: aiAnalyticsActions.fetchAiQcData,
	toggleModal: modalActions.toggleFilterModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
	exportAiQcHistoryAnalytics: analyticsAction.exportAiQcHistoryAnalytics,
};
export default connect(mapStateToProps, mapDispatchToProps)(QCHistoryListData);
