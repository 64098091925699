import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import BaseModal from '../BaseModal/BaseModal';

const OrderAddressModal = (props) => {
	const { toggleModal, show, data } = props;
	const HEADING = 'Address';
	const handleClose = () => {
		toggleModal(false);
	};

	return (
		<div>
			<BaseModal
				heading={HEADING}
				height={150}
				handleClose={handleClose}
				open={show}
				renderBtns={() => {}}
			>
				<div>{data.address}</div>
			</BaseModal>
		</div>
	);
};
const mapStateToProps = (state) => {
	const { show, data } = state.modal.address;
	// const address = state.form.MY_CUSTOMERS.data.VIEW;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddress,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderAddressModal);
