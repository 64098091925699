import { analyticsConstants } from '../Constants';
import aiStatsService from '../Services/AiStatsServices/aiStats.service';
import { notificationServices } from '../Services/Notification';

function fetchAiQcData(req = {}) {
	return (dispatch) => {
		dispatch(request());
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'loading...',
		});
		aiStatsService.fetchAiProductData(req).then(
			(res) => {
				notificationServices.generateNotification({
					type: 'success',
					message: res?.message,
					config: {
						id: notificationId,
					},
				});
				const { data } = res;
				dispatch(
					success({
						QUALITY_ANALYTICS: {
							CHART_DATA: {
								brands: {},
								statsData: {},
								chartData: {
									labels: [],
									passed: [],
									failed: [],
								},
							},
							LIST_DATA: data,
							SEARCH_DATA: {
								list: [],
								query: '',
								searchBy: 'ORDER_ID',
							},
						},
					})
				);
			},
			(error) => {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
				dispatch(failure(error));
			}
		);
	};

	function request(payload) {
		return {
			type: analyticsConstants.FETCH_ANALYTICS_REQUEST,
			payload,
		};
	}
	function success(payload) {
		return {
			type: analyticsConstants.FETCH_ANALYTICS_SUCCESS,
			payload,
		};
	}
	function failure(payload) {
		return {
			type: analyticsConstants.FETCH_ANALYTICS_FAILURE,
			payload,
		};
	}
}
export default {
	fetchAiQcData,
};
