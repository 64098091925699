import { endpoints } from '../Constants';
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const variantsThresholdServices = {
	gateway,
	editThreshold,
	uploadVariantsThresholdData,
};

function gateway({ req, method }) {
	switch (method) {
		// case 'VIEW':
		// return viewProduct({
		// req,
		// });
		case 'LIST_DATA':
			return fetchVariantsThresholdList(req);
		// case 'UPDATE':
		// return updateProduct({
		// req,
		// });
		default:
			break;
	}
	return new Promise();
}
function fetchVariantsThresholdList(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.VARIANTS_THRESHOLD_LIST, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function editThreshold(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.VARIANTS_THRESHOLD_EDIT, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function uploadVariantsThresholdData(req = {}) {
	const { data } = req;
	const url = endpoints.VARIANTS_THRESHOLD_IMPORT;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

export default variantsThresholdServices;
