import {
	Route, Switch, useRouteMatch
} from 'react-router';
// eslint-disable-next-line object-curly-newline
import { CreatePage } from '../../../Pages';
import MyMachinesCreatePage from '../../../Pages/Accounts/MyMachines/MyMachinesCreatePage/MyMachinesCreatePage';
import MyMachinesPage from '../../../Pages/Accounts/MyMachines/MyMachinesPage';

const MyMachinesRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MyMachinesPage createPath={`${path}/add`} />
			</Route>
			<Route exact path={`${path}/add`}>
				<CreatePage
					createPage={<MyMachinesCreatePage />}
					entity='MACHINES'
				/>
			</Route>
		</Switch>
	);
};

export default MyMachinesRoutes;
