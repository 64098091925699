import { endpoints } from '../Constants';
import { authHeader, toQueryStringURL } from '../Helpers';
import handleResponse from './helpers';

const auditServices = {
	fetchAuditList,
	fetchScheduledAuditItems,
	createAudit,
	closeAudit,
	fetchAuditItems,
	adjustReport,
	cancelAudit,
	fetchNonQrItems,
	uploadNonQrItems,
};

function fetchAuditList(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDITS_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data ?? {},
		}),
	};

	// return mockResponse(url, requestOptions);
	return fetch(url, requestOptions).then(handleResponse);
}

function createAudit(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.AUDITS_CREATE, requestOptions).then((res) => { return res.json(); });
}
function closeAudit(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDITS_CLOSE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function cancelAudit(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDIT_CANCEL, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function adjustReport(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDIT_REPORT_ADJUST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function fetchAuditItems(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDIT_ITEMS, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchScheduledAuditItems(req = {}) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.AUDIT_ITEMS_SCHEDULED, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchNonQrItems(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.AUDIT_NON_QR_ITEMS, requestOptions).then(handleResponse);
}

function uploadNonQrItems(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints.AUDIT_NON_QR_ITEMS_UPLOAD, requestOptions).then(handleResponse);
}

export default auditServices;
