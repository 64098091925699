/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable object-curly-newline */
import { connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import SelectPicker from 'rsuite/SelectPicker';
import moment from 'moment';
import Input from 'rsuite/Input';
import { Button } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { modalActions } from '../../../Actions';
import styles from './PaymentDetailsModal.module.scss';
import { BaseModal } from '../BaseModal';
import DetailBox from './DetailBox';
import { IconInput } from '../../Forms/Inputs';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import DatePickerCustom from '../../DatePickerCustom/DatePickerCustom';
import {
	billsListingColmap,
	billsTableColumns,
	debitNoteListingColmap,
	debitNoteTableColumns,
	generateUseAmountInput,
} from './summaryTableData';
import { ListTable } from '../../ListTable';
import { addCommas, classes, isDateWithinRange, paymentModes } from '../../../Helpers';
import { notificationServices } from '../../../Services';
import { FileUploaderModal } from '../FileUploaderModal';
import { BackdropLoader } from '../../Loaders';

const optionsList = paymentModes?.map((ele) => {
	return {
		label: ele,
		value: ele,
	};
});

const PaymentDetailsModal = (props) => {
	const { show, data, toggleModal, toggleFileUploaderModal, updateState } =
		props;
	const {
		rowData,
		dateRangeFilter,
		paymentType,
		filterType,
		disabled,
		avoidFromDateFilter,
		billType,
	} = data ?? {};
	const [paymentDetailsState, setPaymentDetailsState] = useState({
		BILL: [],
		ADVANCE_BILL: [],
		CREDIT: [],
	});
	const [state, setState] = useState({
		paymentId: null,
		paymentDate: new Date(),
		paymentMode: '',
		bills: [],
		appliedCredits: [],
		appliedBills: [],
		uploadedDoc: {
			file: '',
			fileName: '',
		},
		refNumber: '',
	});
	const [advanceBillsList, setAdvanceBillsList] = useState([]);
	const [loading, setLoading] = useState([]);

	const filteredAdvBillsList = useMemo(() => {
		const allAdvBills = paymentDetailsState?.ADVANCE_BILL?.filter((ele) => {
			const payingBill = paymentDetailsState?.BILL?.find((billEle) => {
				return billEle?.orderId === ele?.orderId;
			});
			return !!payingBill;
		});
		return allAdvBills;
	});
	const checkHasSelectedBills = (advanceBillEle) => {
		return state?.bills?.some((selectedBillEle) => {
			const billElement = paymentDetailsState?.BILL?.find((billEle) => {
				return selectedBillEle?.billId === billEle?.id;
			});
			return billElement?.orderId === advanceBillEle?.orderId;
		});
	};

	useEffect(() => {
		const updatedAdvanceBillsList = [];
		paymentDetailsState?.BILL?.forEach((ele) => {
			const isSelected = state?.bills?.find((selectedBillEle) => {
				return selectedBillEle?.billId === ele?.id;
			});
			filteredAdvBillsList?.forEach(
				(advBillEle) => {
					if (advBillEle?.orderId === ele?.orderId) {
						const advExists = updatedAdvanceBillsList?.find(
							(billEle) => {
								return billEle?.id === advBillEle?.id;
							}
						);
						if (!advExists) {
							updatedAdvanceBillsList?.push({
								...advBillEle,
								isEnabled: !!isSelected,
								payingBills: [
									{
										...ele,
										...isSelected,
									},
								],
							});
						} else {
							const hasSelectedBills = checkHasSelectedBills(advBillEle);
							advExists.isEnabled = hasSelectedBills;
							const currBillExists = advExists?.payingBills?.find(
								(payingBillEle) => {
									return payingBillEle?.id === ele?.id;
								}
							);
							if (!currBillExists) {
								advExists?.payingBills?.push({
									...ele,
									...isSelected,
								});
							} else {
								currBillExists.isSelected = isSelected;
							}
						}
					}
				}
			);
		});
		setAdvanceBillsList(updatedAdvanceBillsList);
	}, [paymentDetailsState, state?.bills]);

	async function fetchBillId() {
		if (state?.paymemtId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				setState((prev) => {
					return {
						...prev,
						paymentId: response?.data,
					};
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	const fetchPaymentDetails = async () => {
		setState({
			paymentId: null,
			paymentDate: new Date(),
			paymentMode: '',
			bills: [],
			appliedCredits: [],
			appliedBills: [],
			uploadedDoc: {
				file: '',
				fileName: '',
			},
			refNumber: '',
		});
		try {
			setLoading(true);
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								...(filterType === 'VENDOR_WISE' && {
									vendorId: rowData?.id ?? null,
								}),
								...(filterType === 'PO_WISE' && {
									orderId: rowData?.id ?? null,
								}),
								dateRangeFilter,
								avoidFromDateFilter,
								...(filterType === 'VENDOR_WISE' && {
									subTypes: [billType, 'ADVANCE'],
								}),
							},
							showAdvanceUnused: true,
							paymentDetails:
								paymentType === 'grnBillDues'
									? 'BILL'
									: 'ADVANCE_BILL',
						},
					},
				});
			if (response?.statusCode === 200) {
				setPaymentDetailsState(response?.data);
				setState((prev) => {
					return {
						...prev,
						bills: response?.data?.BILL?.map((ele) => {
							return {
								billId: ele?.id,
								payAmount:
									(ele?.finalTransactionAmount -
									(ele?.currentAmount || 0))?.toFixed(2),
							};
						}),
					};
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		if (show && !state?.paymentId && !disabled) {
			fetchBillId();
		}
	}, [show, state?.paymentId, paymentType, disabled]);

	useEffect(() => {
		if (show && !disabled) {
			fetchPaymentDetails();
		}
	}, [show, paymentType, disabled]);

	useEffect(() => {
		if (show && disabled) {
			setPaymentDetailsState({
				BILL: rowData?.BILL,
				ADVANCE_BILL: rowData?.ADVANCE_BILL,
				CREDIT: rowData?.CREDIT,
			});
			setState(() => {
				return {
					paymentId: rowData?.clientTransactionId?.split('-')?.[1],
					paymentDate: new Date(rowData?.transactionDate),
					appliedBills: rowData?.ADVANCE_BILL?.map((ele) => {
						return {
							billId: ele?.id,
							debitAmount: ele?.txnUsedAmount,
						};
					}),
					appliedCredits: rowData?.CREDIT?.map((ele) => {
						return {
							creditId: ele?.id,
							debitAmount: ele?.txnUsedAmount,
						};
					}),
					bills: rowData?.BILL?.map((ele) => {
						return {
							billId: ele?.id,
							payAmount: ele?.txnUsedAmount,
						};
					}),
					paymentMode: rowData?.extraData?.paymentMode ?? '',
					refNumber:
						rowData?.extraData?.refNumber ??
						rowData?.extraData?.referenceNumber ??
						'',
					uploadedDoc: {
						file: rowData?.uploadedDoc,
						fileName: rowData?.extraData?.uploadedDocFileName,
					},
				};
			});
		}
	}, [show, paymentType, rowData, disabled]);

	const handleClose = () => {
		toggleModal(false, {});
		setPaymentDetailsState({});
		setState({
			paymentId: null,
			paymentDate: new Date(),
			paymentMode: '',
			bills: [],
			appliedCredits: [],
			appliedBills: [],
			uploadedDoc: {
				file: '',
				fileName: '',
			},
			refNumber: '',
		});
	};

	const calculatedData = useMemo(() => {
		let totalPaymentAmount = 0;
		let totalAdvancesAmount = 0;
		let totalCreditsAmount = 0;
		let totalAmount = 0;

		state?.bills?.forEach((ele) => {
			const ex = paymentDetailsState?.BILL?.find((pmEle) => {
				return pmEle?.id === ele?.billId;
				// if (pmEle?.id === ele?.billId) {
				// }
			});
			totalAmount +=
				ex?.finalTransactionAmount - (ex?.currentAmount || 0);
			if (ele?.payAmount && !isNaN(Number(ele?.payAmount))) {
				totalPaymentAmount += Number(ele?.payAmount);
			}
		});
		state?.appliedBills?.forEach((ele) => {
			if (ele?.debitAmount && !isNaN(Number(ele?.debitAmount))) {
				totalAdvancesAmount += Number(ele?.debitAmount);
			}
		});
		state?.appliedCredits?.forEach((ele) => {
			if (ele?.debitAmount && !isNaN(Number(ele?.debitAmount))) {
				totalCreditsAmount += Number(ele?.debitAmount);
			}
		});
		return {
			totalPaymentAmount:
				totalPaymentAmount - totalAdvancesAmount - totalCreditsAmount,
			totalAdvancesAmount,
			totalCreditsAmount,
			totalAmount,
			totalPayingAmount: totalPaymentAmount,
		};
	}, [state, show, paymentDetailsState]);

	const billsListingSpecial = {
		'Bill date': (billDate) => {
			return moment(billDate)?.format('YYYY-MM-DD');
		},
		'Due Date': (dueDate) => {
			if (!dueDate) return '-';
			const isBillInRange = isDateWithinRange(dueDate, dateRangeFilter);
			return (
				<div className={classes(styles.due_date, !isBillInRange ? styles.due_out_range : '')}>
					{moment(dueDate)?.format('YYYY-MM-DD')}
				</div>
			);
		},
		'Bill Type': (id) => {
			const item = paymentDetailsState?.BILL?.find((ele) => {
				return ele?.id === id;
			});
			const type = item?.subType;
			return (
				<div className={classes(styles.TYPE, styles[type])}>{type}</div>
			);
		},
		'Bill Amount': (amt) => {
			return (
				<>
					&#x20B9;
					{addCommas(amt)}
				</>
			);
		},
		'Balance Amount': (id) => {
			const item = paymentDetailsState?.BILL?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<>
					&#x20B9;
					{addCommas(
						item?.finalTransactionAmount -
							(item?.currentAmount || 0)
					)}
				</>
			);
		},
		'Available Balance': (id) => {
			const item = paymentDetailsState?.ADVANCE_BILL?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<>
					&#x20B9;
					{addCommas(item?.currentAmount - (item?.usedAmount || 0))}
				</>
			);
		},
		'Pay Bill': (id) => {
			// eslint-disable-next-line no-unused-vars
			const item = paymentDetailsState?.BILL?.find((ele) => {
				return ele?.id === id;
			});
			const inputItem = state?.bills?.find((ele) => {
				return ele?.billId === id;
			});
			return (
				<Input
					style={{
						width: 100,
					}}
					value={inputItem?.payAmount}
					type='number'
					onChange={(value) => {
						// if (value <= inputItem?.payAmount) {
						setState((prev) => {
							if (!inputItem) {
								return {
									...prev,
									bills: [
										...prev?.bills,
										{
											billId: id,
											payAmount: value,
										},
									],
								};
							}
							return {
								...prev,
								bills: prev?.bills?.map((ele) => {
									if (inputItem?.billId === ele?.billId) {
										return {
											...ele,
											payAmount: value,
										};
									}
									return ele;
								}),
							};
						});
						// }
					}}
					placeholder='Enter Pay Amount'
				/>
			);
		},
		'Use Amount': (id) => {
			// eslint-disable-next-line no-unused-vars
			const item = advanceBillsList?.find((ele) => {
				return ele?.id === id;
			});
			const inputItem = state?.appliedBills?.find((ele) => {
				return ele?.billId === id;
			});
			const maxInpVal = item?.payingBills?.reduce(
				(totalAmount, billEle) => {
					totalAmount += billEle?.payAmount ?? 0;
					// Object.values(poBills)?.forEach((innerBill) => {
					// });
					return totalAmount;
				},
				0
			);

			return generateUseAmountInput({
				inputItem,
				valueKey: 'appliedBills',
				id,
				keyToMatch: 'billId',
				placeholder: 'Enter Amount',
				totalAmount: item?.currentAmount - item?.usedAmount,
				setState,
				styles,
				maxInpVal,
				isDisabled: !maxInpVal,
				showUseFull:
					item?.currentAmount <= calculatedData?.totalPaymentAmount,
			});
		},
	};
	const debitNoteListingSpecial = {
		'Available Balance': (id) => {
			const item = paymentDetailsState?.CREDIT?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<>
					&#x20B9;
					{item?.currentAmount}
				</>
			);
		},
		'Use Amount': (id) => {
			// eslint-disable-next-line no-unused-vars
			const item = paymentDetailsState?.CREDIT?.find((ele) => {
				return ele?.id === id;
			});
			const inputItem = state?.appliedCredits?.find((ele) => {
				return ele?.creditId === id;
			});
			return generateUseAmountInput({
				inputItem,
				valueKey: 'appliedCredits',
				id,
				keyToMatch: 'creditId',
				placeholder: 'Enter Amount',
				totalAmount: item?.currentAmount,
				setState,
				styles,
			});
		},
	};

	const getBillRowStyles = (billEle) => {
		const exists = state?.bills?.find((ele) => {
			return ele?.billId === billEle?.id;
		});
		let rowStyle = '';
		if (exists && exists?.payAmount) {
			rowStyle = styles.selected_bill;
		}
		return rowStyle;
	};

	const getAdvRowStyles = (advBillEle) => {
		return (!advBillEle?.isEnabled && !disabled) ? styles.adv_disabled : '';
	};

	const validator = (validatorData, isInvalid = false) => {
		// eslint-disable-next-line consistent-return
		Object.values(validatorData)?.forEach((eleVal) => {
			if (
				typeof eleVal === 'object' &&
				!(eleVal instanceof Date) &&
				!Array.isArray(eleVal)
			) {
				isInvalid = validator(eleVal, isInvalid);
			}
			if (
				!eleVal &&
				!Array.isArray(eleVal) &&
				!(eleVal instanceof Date)
			) {
				isInvalid = true;
			}
		});
		return isInvalid;
	};

	const handleConfirm = async () => {
		const isInvalid = validator(state, false);
		if (isInvalid) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter mandatory fields',
			});
			return;
		}
		const req = {
			data: {
				bills: state?.bills?.map((ele) => {
					return {
						...ele,
						payAmount: Number(ele?.payAmount),
					};
				}),
				appliedBills: state?.appliedBills?.map((ele) => {
					return {
						...ele,
						debitAmount: Number(ele?.debitAmount),
					};
				}),
				appliedCredits: state?.appliedCredits?.map((ele) => {
					return {
						...ele,
						debitAmount: Number(ele?.debitAmount),
					};
				}),
				finalTransactionAmount: calculatedData?.totalPayingAmount,
				vendorId: rowData?.vendorId,
				transactionDate: moment(state?.paymentDate)?.format('M/D/YYYY'),
				clientTransactionId: `PM-${state?.paymentId}`,
				type: 'PAYMENTS',
				paymentAmount: calculatedData?.totalPaymentAmount,
				uploadedDoc: state?.uploadedDoc?.file,
				extraData: {
					paymentMode: state?.paymentMode,
					uploadedDocFileName: state?.uploadedDoc?.fileName ?? null,
					refNumber: state?.refNumber,
				},
			},
		};
		try {
			setLoading(true);
			const response =
				await inventoryAnalyticsServices.createTransactionRecord(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				fetchPaymentDetails();
				updateState();
			}
		} catch ({ name, message }) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message,
			});
			// eslint-disable-next-line no-console
		} finally {
			setLoading(false);
		}
	};

	const renderBtns = () => {
		return (
			<div className={styles.btn_container}>
				<button
					onClick={handleClose}
					type='button'
					className={styles.back}
				>
					Cancel
				</button>
				<button
					onClick={handleConfirm}
					type='button'
					className={styles.next}
					disabled={!paymentDetailsState?.BILL?.length}
				>
					Record Payment
				</button>
			</div>
		);
	};

	const handleVendorInvoiceUpload = (fileData) => {
		setState((prev) => {
			return {
				...prev,
				uploadedDoc: fileData,
			};
		});
		toggleFileUploaderModal(false);
	};

	const uploadInvoiceCick = () => {
		toggleFileUploaderModal(true, {
			heading: 'Upload Reference File',
			selectedFile: '',
			confirmText: 'Upload',
		});
	};

	return (
		<BaseModal
			open={show}
			heading={data?.heading ?? `Payment Details - ${paymentDetailsState?.BILL?.[0]?.vendorName ?? ''}`}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='95%'
			height='98%'
			hideFooter={disabled}
			customStyles={{
				background: disabled ? '#f1f1f1' : '',
			}}
		>
			<BackdropLoader isLoading={loading} showProgress />
			<div className={styles.root}>
				<FileUploaderModal handleConfirm={handleVendorInvoiceUpload} />
				<div className={styles.left}>
					<DetailBox
						className={styles.bills}
						header='Making payment of Bills'
					>
						<ListTable
							fixedHeaderTop={0}
							showBtnContainer={false}
							allowExport={false}
							allowCheckList
							disabledRowSelection={disabled}
							selectedRows={state?.bills?.map((ele) => {
								return ele?.billId;
							})}
							setSelectedRow={(selectedBills) => {
								let tSelectedBills = [];
								if (typeof selectedBills === 'function') {
									tSelectedBills = selectedBills(
										state?.bills?.map((ele) => {
											return ele?.billId;
										})
									);
								} else {
									tSelectedBills = selectedBills;
								}
								if (!tSelectedBills?.length) {
									setState((prev) => {
										return {
											...prev,
											bills: [],
										};
									});
								} else {
									setState((prev) => {
										return {
											...prev,
											bills: tSelectedBills?.map(
												(ele) => {
													const bill =
														paymentDetailsState?.BILL?.find(
															(bEle) => {
																return (
																	bEle?.id ===
																	ele
																);
															}
														);
													return {
														billId: ele,
														payAmount: (
															bill?.finalTransactionAmount -
															(bill?.currentAmount ||
																0)
														)?.toFixed(2),
													};
												}
											),
										};
									});
								}
							}}
							colMap={billsListingColmap}
							columns={billsTableColumns?.filter((ele) => {
								return ![
									'Available Balance',
									'Use Amount',
									'Used Amount',
									!disabled && 'Paid Amount',
									disabled && 'Pay Bill',
								]?.includes(ele?.title);
							})}
							data={paymentDetailsState?.BILL?.map((ele) => {
								return {
									...ele,
									rowStyles: getBillRowStyles(ele),
								};
							})}
							showTotal={false}
							actions={[]}
							special={billsListingSpecial}
						/>
					</DetailBox>
					{paymentType === 'grnBillDues' && (
						<DetailBox
							className={styles.available}
							header='Available Advances'
						>
							<ListTable
								fixedHeaderTop={0}
								showBtnContainer={false}
								allowExport={false}
								allowCheckList={false}
								selectedRows={[]}
								setSelectedRow={() => {}}
								colMap={billsListingColmap}
								columns={billsTableColumns?.filter((ele) => {
									return ![
										'Balance Amount',
										'Pay Bill',
										'Bill Type',
										'GRN Id',
										'Due Date',
										'Paid Amount',
										!disabled && 'Used Amount',
										disabled && 'Use Amount',
									]?.includes(ele?.title);
								})}
								data={advanceBillsList?.map((ele) => {
									return {
										...ele,
										rowStyles: getAdvRowStyles(ele),
									};
								})}
								showTotal={false}
								actions={[]}
								special={billsListingSpecial}
							/>
						</DetailBox>
					)}
					<DetailBox
						className={styles.available}
						header='Available Debit Notes'
					>
						<ListTable
							fixedHeaderTop={0}
							showBtnContainer={false}
							allowExport={false}
							allowCheckList={false}
							selectedRows={[]}
							setSelectedRow={() => {}}
							colMap={debitNoteListingColmap}
							columns={debitNoteTableColumns?.filter((ele) => {
								if (disabled) {
									return ele?.title !== 'Use Amount';
								}
								return ele?.title !== 'Used Amount';
							})}
							data={paymentDetailsState?.CREDIT}
							showTotal={false}
							actions={[]}
							special={debitNoteListingSpecial}
						/>
					</DetailBox>
				</div>
				<div className={styles.payment_details}>
					<div className={styles.label_value}>
						<label>Vendor:</label>
						<p>{rowData?.name}</p>
					</div>
					<div className={styles.label_value}>
						<label>Total Bill Amount:</label>
						<p>
							&#x20B9;
							{addCommas(calculatedData?.totalAmount)}
						</p>
					</div>
					<div className={styles.label_value}>
						<label>Payment Via Advance Bills:</label>
						<p>
							&#x20B9;
							{calculatedData?.totalAdvancesAmount}
						</p>
					</div>
					<div className={styles.label_value}>
						<label>Payment Via Credits:</label>
						<p>
							&#x20B9;
							{calculatedData?.totalCreditsAmount}
						</p>
					</div>
					<div className={styles.label_value}>
						<label className={styles.mandatory}>
							Payment Amount:
						</label>
						<p>
							&#x20B9;
							{addCommas(calculatedData?.totalPaymentAmount)}
						</p>
					</div>
					<div className={styles.label_value}>
						<label className={styles.mandatory}>Payment ID:</label>
						<IconInput
							type='custom'
							iconText='PM-'
							size='large'
							inputType='text'
							isDisabled={disabled}
							value={state?.paymentId}
							handleInput={(e) => {
								setState((prev) => {
									return {
										...prev,
										paymentId: e?.target?.value,
									};
								});
							}}
							style={{
								width: '200px',
							}}
						/>
					</div>
					<div className={styles.label_value}>
						<label className={styles.mandatory}>
							Payment Date:
						</label>
						{disabled ? (
							<p>
								{moment(state?.paymentDate)?.format(
									'YYYY-MM-DD'
								)}
							</p>
						) : (
							<DatePickerCustom
								disabled={disabled}
								selectedDate={state?.paymentDate}
								selectDate={(rsuiteDate) => {
									setState((prev) => {
										return {
											...prev,
											paymentDate: rsuiteDate,
										};
									});
								}}
								style={{
									width: 200,
								}}
								oneTap
								shouldDisableDate={(date) => {
									if (disabled) return false;
									return (
										date <=
										new Date(
											new Date().setDate(
												new Date().getDate() - 1
											)
										)
									);
								}}
							/>
						)}
					</div>
					<div className={styles.label_value}>
						<label className={styles.mandatory}>
							Payment Mode:
						</label>
						<SelectPicker
							data={optionsList}
							searchable={false}
							value={state?.paymentMode}
							disabled={disabled}
							onChange={(value) => {
								setState((prev) => {
									return {
										...prev,
										paymentMode: value,
									};
								});
							}}
							style={{
								width: 200,
								borderRadius: '8px',
							}}
							className={styles.payment_mode}
							menuStyle={{
								zIndex: 9999,
							}}
							cleanable={false}
							placeholder='Payment mode'
						/>
					</div>
					<div className={styles.label_value}>
						<label className={styles.mandatory}>
							Reference ID:
						</label>
						<Input
							style={{
								width: 200,
							}}
							value={state?.refNumber}
							onChange={(value) => {
								setState((prev) => {
									return {
										...prev,
										refNumber: value,
									};
								});
							}}
							disabled={disabled}
							placeholder='Enter Ref. ID'
						/>
					</div>
					<div className={styles.label_value}>
						<label className={styles.mandatory}>
							Reference File:
						</label>
						{state?.uploadedDoc?.fileName ? (
							<div className={styles.file}>
								<p>{state?.uploadedDoc?.fileName}</p>
								<div
									onClick={() => {
										if (disabled) return;
										setState((prev) => {
											return {
												...prev,
												uploadedDoc: {
													file: '',
													fileName: '',
												},
											};
										});
									}}
									className={styles.icon}
								>
									<Cancel fontSize='8px' />
								</div>
							</div>
						) : (
							<Button
								type='button'
								variant='contained'
								color='primary'
								onClick={uploadInvoiceCick}
								disabled={disabled}
							>
								Upload
							</Button>
						)}
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.paymentDetailsModal;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;

	return {
		show,
		data,
		vendors,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.togglePaymentDetailsModal,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentDetailsModal);
