/* eslint-disable no-tabs */
import { Bar } from 'react-chartjs-2';
import styles from './MostPopularDays.module.scss';

const MostPopularDays = (props) => {
	const { data } = props;
	return (
		<div className={`${styles.root}`}>
			<div className={`${styles.heading}`}>Most Popular Days</div>
			<div className={`${styles.graph}`}>
				<Bar data={data.day} />
			</div>
			<div className={`${styles.heading}`}>Most Popular Times</div>
			<div className={`${styles.graph}`}>
				<Bar
					data={data.time}
					options={{
						title: {
							display: true,
							text: 'Average Rainfall per month',
							fontSize: 20,
						},
						legend: {
							display: true,
							position: 'right',
						},
					}}
				/>
			</div>
		</div>
	);
};

export default MostPopularDays;
