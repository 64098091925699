import { refundsConstants } from '../Constants';

const refundsActions = {
	setRefundsData,
};
function setRefundsData(payload) {
	return {
		type: refundsConstants.SET_REFUNDS_DATA,
		payload,
	};
}
export default refundsActions;
