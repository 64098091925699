/* eslint-disable no-console */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
import styles from './StickyLegends.module.scss';

/**
 *
 * parent componenet should have some defined height, and scroll should be active
 * @param sticky = default true,
 * @param legendKeyValues = { key:value, key:value }
 * @param getValueFunction = function will loop over entries function(key,value) should return {color,label}
 */
function StickyLegends({ legendKeyValues, getValueFunction, sticky = true }) {
	return (
		<div
			className={styles.root}
			style={{
				...(sticky && {
					position: 'sticky',
					top: '10px',
					zIndex: 1,
				}),
			}}
		>
			<div className={styles.holder}>
				{Object?.entries(legendKeyValues).map(([key, ele]) => {
					const {
						color,
						label,
						fullLabel = false,
					} = getValueFunction(key, ele);
					if (fullLabel) {
						return (
							<div key={key} className={styles.row}>
								<div
									style={{
										backgroundColor: color,
									}}
									className={styles.fullLabel}
								>
									{fullLabel}
								</div>
							</div>
						);
					}
					if (!label || !color) return <></>;
					return (
						<div key={key} className={styles.row}>
							<div
								className={styles.color}
								style={{
									backgroundColor: color,
								}}
							/>
							<div className={styles.label}>{label}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default StickyLegends;
