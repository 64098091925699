/* eslint-disable no-tabs */
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import styles from './ProductRevenueDistribution.module.scss';

const pieData = {
	maintainAspectRatio: true,
	responsive: true,
	labels: ['Cheese & Corn', 'Chicken Shawarma', 'Double Cheese Margherita'],
	datasets: [
		{
			data: [339, 400, 1545],
			backgroundColor: [
				'#666699',
				'#CC9933',
				'#006666',
				'#3399FF',
				'#993300',
				'#CCCC99',
				'#666666',
				'#FFCC66',
				'#6699CC',
			],
			hoverBackgroundColor: [
				'#666699',
				'#CC9933',
				'#006666',
				'#3399FF',
				'#993300',
				'#CCCC99',
				'#666666',
				'#FFCC66',
				'#6699CC',
			],
		},
	],
};

const ProductRevenueDistribution = (props) => {
	const { timeFrame, data, onClick, handleApply } = props;

	useEffect(() => {
		handleApply();
	}, []);

	return (
		<div className={`${styles.root}`}>
			<div className={`${styles['apply-button']}`}>
				<div className={`${styles.option}`}>
					<select
						className='form-value'
						id='time-frame'
						name='time-frame'
						onChange={onClick}
					>
						{timeFrame.map((time) => {
							return <option value={time.val}>{time.key}</option>;
						})}
					</select>
				</div>
				<div>
					<Button variant='contained' onClick={handleApply}>
						Apply
					</Button>
				</div>
			</div>
			<div className={`${styles.heading}`}>
				Product Revenue Distribution
			</div>
			<div className={`${styles.chart}`}>
				<Pie data={data || pieData} />
			</div>
		</div>
	);
};
ProductRevenueDistribution.defaultProps = {
	timeFrame: [
		{
			key: 'This Day',
			val: 'curr_day',
		},
		{
			key: 'This Month',
			val: 'curr_mon',
		},
		{
			key: 'This Year',
			val: 'curr_year',
		},
		{
			key: 'Previous Day',
			val: 'prev_day',
		},
		{
			key: 'Previous Month',
			val: 'prev_mon',
		},
		{
			key: 'Previous Year',
			val: 'prev_year',
		},
	],
};
export default ProductRevenueDistribution;
