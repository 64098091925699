import { cloneDeep } from 'lodash';
import { notificationServices } from '../../../../Services';

const initialState = {
	id: null,
	username: '',
	password: '',
	confirmationPassword: '',
	displayName: '',
	email: '',
	contact: '',
	accessLevel: [],
	appModules: [],
	businessUser: false,
	processId: null,
	assetId: null,
	store: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'SET_USER_DATA':
			return {
				...action?.payload,
			};
		case 'INIT':
			return {
				id: null,
				username: '',
				password: '',
				confirmationPassword: '',
				displayName: '',
				email: '',
				contact: '',
				accessLevel: [],
				appModules: [],
				businessUser: false,
				processId: null,
				assetId: null,
				store: null,
			};
		default:
			return state;
	}
};

const config = {
	initialState,
	reducer,
	validator: (state, isEdit) => {
		if (!state?.username) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'User name is Mandatory',
			});
			return false;
		}
		if (!isEdit && !state?.password) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Password is mandatory',
			});
			return false;
		}
		if (!isEdit && state?.password !== state?.confirmationPassword) {
			notificationServices.generateNotification({
				type: 'error',
				message: "Password and Confirm password doesn't match",
			});
			return false;
		}
		if (!state?.accessLevel?.length > 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select at least 1 access level',
			});
			return false;
		}
		if (!state?.businessUser && (!state?.store || state?.store === -11)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Store is mandatory for non-business user',
			});
			return false;
		}
		if (
			!state?.businessUser &&
			state?.store &&
			(!state?.processId || state?.processId === -11) &&
			!state?.assetId &&
			state?.assetId !== -11
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Process or Asset is mandatory',
			});
			return false;
		}
		return true;
	},
	deserializer: (state, isInternal) => {
		const clonedState = cloneDeep(state);
		const newState = {
			...clonedState,
			password: (isInternal && clonedState?.password) ? clonedState?.password : null,
			assetId: clonedState?.assetId !== -11 ? clonedState?.assetId : null,
			processId:
				clonedState?.processId !== -11 ? clonedState?.processId : null,
			store: clonedState?.store !== -11 ? clonedState?.store : null,
			appModules:
				clonedState?.appModules?.length > 0
					? clonedState?.appModules
					: null,
		};
		// if (isEdit) {
		// // eslint-disable-next-line no-restricted-globals
		// const graceDays = !isNaN(parseInt(clonedState?.graceDays, 10))
		// ? parseInt(clonedState?.graceDays, 10)
		// : 0;
		// newState = {
		// storeId: clonedState?.store,
		// graceDays,
		// status: status(),
		// startDate: moment(clonedState?.startDate)?.format('YYYY-MM-DD'),
		// endDate: moment(clonedState?.endDate)?.format('YYYY-MM-DD'),
		// };
		// }
		return newState;
	},
	deserializeUser: (userData) => {
		const tempData = {
			username: userData?.userDetails?.username,
			displayName: userData?.userDetails?.name,
			email: userData?.userDetails?.email,
			contact:
				// eslint-disable-next-line no-restricted-globals
				!isNaN(Number(userData?.userDetails?.mobile))
					? Number(userData?.userDetails?.mobile)
					: null,
			accessLevel: userData?.accessLevel
				? userData?.accessLevel?.split(',')
				: [],
			appModules: userData?.appModules ?? [],
			businessUser: userData?.isBusinessUser,
			processId: null,
			assetId: null,
			store: null,
		};
		return tempData;
	},
};

export default config;
