/* eslint-disable no-console */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import {
	useEffect, useMemo, useState
} from 'react';
import ReactFlow, {
	ReactFlowProvider, useEdgesState, useNodesState
} from 'react-flow-renderer';
import { connect } from 'react-redux';
import CmsCustomeNode from '../CmsCustomNode/CmsCustomeNode';
// import CmsCustomEdges from '../FmsCustomEdges/FmsCustomEdges';
import orderTrackingServices from '../../../Services/orderTracking.services';
import { notificationServices } from '../../../Services/Notification';
import { BackdropLoader } from '../../Loaders';
import { modalActions } from '../../../Actions';

function Layout({
	prodId, originStore, prodName, qrCode, poActionHandler, handleImageClick,
}) {
	const [nnodes, setNodes, onNodesChange] = useNodesState();
	const [eedges, setEdges, onEdgesChange] = useEdgesState();
	const [isLoading, setIsLoading] = useState(false);
	async function fetchPreProduction(prop) {
		try {
			setIsLoading(true);
			const response = await orderTrackingServices.fetchPreProduction({
				params: {
					productName: prop?.prodName,
					productID: prop?.prodId,
					storeId: prop?.storeId,
					qrCode: prop?.qrCode,
				},
			});
			notificationServices.generateNotification({
				type: 'success',
				message: response?.message,
			});
			setNodes(response?.data?.nodes);
			setEdges(response?.data?.edges);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	}
	useEffect(() => {
		if (prodName && prodId && originStore) {
			fetchPreProduction({
				prodName,
				prodId,
				storeId: originStore,
				qrCode,
			});
		}
	}, [prodId, originStore, prodName]);
	const nodeTypes = useMemo(
		() => {
			return {
				customize:
			(prop) => {
				return (
					<CmsCustomeNode
						data={prop?.data}
						ndata={prop}
						poActionHandler={poActionHandler}
						handleImageClick={handleImageClick}
					/>
				);
			},
			};
		},
		[]
	);
	// const edgeTypes = useMemo(
	// 	() => {
	// 		return {
	// 			smooth: (prop) => {
	// 				return (<CmsCustomEdges edgeData={prop} />);
	// 			},
	// 		};
	// 	},
	// 	[]
	// );
	return (
		<>
			<BackdropLoader isLoading={isLoading} showProgress />
			<div
				style={{
					width: '100%',
					height: '100%',
				}}
			>
				<ReactFlow
					nodeTypes={nodeTypes}
					// edgeTypes={edgeTypes}
					nodes={nnodes}
					edges={eedges}
					fitView='zoomToFit'
					fitViewOptions={{
						minZoom: 0.05,
						maxZoom: 0.3,
					}}
					minZoom={0.1}
					defaultZoom={0.2}
				/>
			</div>
		</>
	);
}
function FmsManufacturing({
	prodId, originStore, prodName, qrCode, poActionHandler, toggleImageModal,
}) {
	const handleImageClick = (image) => {
		toggleImageModal(true, {
			image,
			width: '50rem',
			height: '40rem',
		});
	};
	return (
		<ReactFlowProvider>
			<Layout
				prodId={prodId}
				originStore={originStore}
				prodName={prodName}
				qrCode={qrCode}
				poActionHandler={poActionHandler}
				handleImageClick={handleImageClick}
			/>
		</ReactFlowProvider>
	);
}
const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	toggleImageModal: modalActions.toggleImageModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(FmsManufacturing);
