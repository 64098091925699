// eslint-disable-next-line import/no-unresolved, import/extensions
import { useAuth } from '@/Components/Auth/Login/auth';
import HighlightChart from '../../../Components/Charts/HighlightChart/HighlightChart';
import LineChart from '../../../Components/Charts/LineChart/LineChart';
import BarWithLineChart from '../../Dashboard/BarWithLineChart/BarWithLineChart';
import PieChart from '../../Dashboard/PieChart/PieChart';
import Table from '../../Dashboard/Table/Table';
import { chartTypes } from '../../Dashboard/tempdata';
import styles from './TrendTilesContainer.module.scss';

function TrendTilesContainer(props) {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;
	function displayChart(type, data) {
		switch (type) {
			case chartTypes.pieChart:
				return (
					<>
						<PieChart data={data} />
					</>
				);
			case chartTypes.barWithLineChart:
				return (
					<>
						<BarWithLineChart data={data} />
					</>
				);
			case chartTypes.barChart:
				return (
					<>
						<HighlightChart data={data} />
					</>
				);
			case chartTypes.areaChart:
				return (
					<>
						<LineChart data={data} />
					</>
				);
			case chartTypes.listTable:
				return (
					<>
						<Table
							measurement={measurement}
							productsList={props?.productsList}
							data={data}
						/>
					</>
				);

			default:
				// eslint-disable-next-line consistent-return
				return (
					<>
						<div> some other Chart </div>
					</>
				);
		}
	}
	return (
		<div className={styles.root}>
			{/* <DataTile type='doughnut' heading='Dough Nut' />
			<DataTile type='bar' heading='Bar Chart' />
			<DataTile type='h-bar' heading='H-Bar Chart' />
			<DataTile type='line' heading='Line Chart' />
			<DataTile color='#EAC4D5' />
			<DataTile color='#EAC4D5' /> */}
			{props?.data?.map((ele) => {
				return (
					<div className={styles['chart-container']}>
						{displayChart(ele?.type, ele, [])}
					</div>
				);
			})}
		</div>
	);
}

export default TrendTilesContainer;
