import { connect } from 'react-redux';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import { FormSectionHeader } from '../../../Components/Forms';
// eslint-disable-next-line object-curly-newline
import { inputHelper, isEmpty, history } from '../../../Helpers';
import {
	SubscriptionTable,
	GeneralDetails,
} from '../../../Components/Forms/Subscription';
import subscriptionConfig from './subscription.config';
import { CreatePageHeader } from '../../../Components/Headers';
import styles from './subscription.module.scss';
import { notificationServices } from '../../../Services';
import { formActions } from '../../../Actions';
import { urls } from '../../../Constants';

const SubscriptionEditPage = (props) => {
	const [state, setState] = useState({
		...subscriptionConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	// eslint-disable-next-line object-curly-newline
	const { isDisabled, data, fetchSubscription, saveSubscription, breadcrumbs } = props;
	const { id } = useRouteMatch().params;
	useEffect(() => {
		fetchSubscription({
			entity: 'SUBSCRIPTION',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, []);
	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(subscriptionConfig.deserializer(state, data));
		}
	}, [data]);
	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.variants.[${dataset.id}].[${fieldName}]`,
				() => {
					return fieldValue;
				}
			)
		);
	};
	const handleSave = (e) => {
		e.preventDefault();
		const isValid = subscriptionConfig.validator(state);
		if (isValid) {
			const req = {};
			req.data = subscriptionConfig.serializer(state.fields).fields;
			req.params = {
				id,
			};
			setNumberOfSubmissions(numberOfSubmissions + 1);
			saveSubscription({
				entity: 'SUBSCRIPTION',
				req,
				method: 'UPDATE',
				redirect: `${urls.EXTENSION}${urls.SUBSCRIPTION}`,
			});
		}
	};
	const handleCancel = () => {
		history.push(`${urls.EXTENSION}${urls.SUBSCRIPTION}/view/${id}`);
	};
	const handleDateTime = (fieldValue, fieldName) => {
		const timeSlotObj = _.cloneDeep(fieldValue);
		if (moment(timeSlotObj).isValid()) {
			setState(
				_.update(_.cloneDeep(state), `fields.[${fieldName}]`, () => {
					return timeSlotObj.toString();
				})
			);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please Enter Valid Date Time',
			});
		}
	};
	return (
		<div className={`${styles.root}`}>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Edit Subscription'
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: `Edit ${state.fields.name}`,
						},
					]}
					showBreadcrumbs
				/>

				<FormSectionHeader
					tooltip='ProductDetails'
					sectionName='Product Details'
				>
					<GeneralDetails
						fields={state.fields}
						handleInput={handleInput}
						handleDateTime={handleDateTime}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='Variant Details'
					sectionName='Variant Details'
				>
					<div className={`${styles['table-container']}`}>
						<SubscriptionTable
							variants={state.fields.variants}
							isDisabled={isDisabled}
							handleInput={handleInput}
						/>
					</div>
				</FormSectionHeader>
			</form>
		</div>
	);
};
const mapStateToProps = (state) => {
	const data = state.form.SUBSCRIPTION.data.VIEW;
	return {
		data,
	};
};
const mapDispatchToProps = {
	saveSubscription: formActions.gateway,
	fetchSubscription: formActions.gateway,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubscriptionEditPage);
