/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { get } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useEffect } from 'react';
// import ReportProblemIcon from '@mui/icons-material/ReportProblem';
// import { ListTable } from '../../../../Components/ListTable';
import {
 classes, convertWeightUnit, handleResponseStreamDownload
} from '../../../../Helpers';
import { urls } from '../../../../Constants';
import styles from './WastageListTable.module.scss';
import { useAuth } from '../../../../Components/Auth/Login/auth';
import { VirtualizedListTable } from '@/Components/VirtualizedListTable';
import commonAnalyticsServices from '@/Services/Analytics Services/common-analytics.services';
import { notificationServices } from '@/Services';
import { analyticsAction } from '@/Actions';
import { deserializeFilters } from '@/Actions/analytics.actions';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const COLMAP = {
	Store: 'storeName',
	'QC TIME': 'QCTime',
	'Order Id': 'OrderId',
	Type: 'type',
	'Item Id': 'itemId',
	'Product Name': 'ProductName',
	Temp: 'Temp',
	// 'Inventory Type': 'Inventory Type',
	Weight: 'Weight',
	qc: 'id',
};

const Modes = {
	PRODUCTION: 'Ai-Inventory-In',
	INWARD: 'Ai-GRN',
	OUTWARD: 'Ai-Inventory-Out',
};

const TABLE_COLUMNS = [
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Reason',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'QC TIME',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Weight',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Temp',
		config: {
			type: 'DATA',
		},
	},
];

const renderColumn = (column) => {
	switch (column) {
		case 'Validated':
		case 'Identified':
		case 'Features':
			return (
				<div
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					{column?.toUpperCase()}
				</div>
			);
		default:
			return null;
	}
};

const getRowStyles = (item) => {
	switch (item?.result) {
		case 'FAILED':
			return styles.critical;
		case 'SKIPPED':
			return styles.skipped;
		case 'OVERRIDDEN':
			return styles.moderate;
		default:
			return styles.normal;
	}
};

function setAttr(text) {
	return <span className={styles.text}>{text}</span>;
}

const WastageListTable = (props) => {
	const history = useHistory();
	const { data, setAnalyticsType, ANALYTICS_TYPE, req } = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	// const listCount = data.length - 1;
	useEffect(() => {
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, []);
	const SPECIAL_ITEMS = {
		Weight: (altProps) => {
			const item = data[altProps - 1];
			let failure =
				item?.Weight > item?.MaxWeight / 1000 ? 'OVER' : 'UNDER';
			failure =
				item?.WeightCheckRequired && !item?.IsWeightInRange && failure;
			const itemWeight = get(item, COLMAP.Weight, 0);
			let itemUnit = item?.unit;
			if (item?.unit === 'count') {
				if (measurement === 'US') {
					itemUnit = 'oz';
				} else {
					itemUnit = 'gram';
				}
			}
			return (
				<span
					className={`${styles['no-wrap']} ${styles.text} ${
						failure ? styles.critical : styles.normal
					}`}
				>
					{convertWeightUnit(itemWeight, itemUnit) ?? '-'}
					{data[altProps - 1].IsWeightInRange === false ? (
						<>
							{failure === 'OVER' && <ArrowUpward />}
							{failure === 'UNDER' && <ArrowDownward />}
						</>
					) : (
						<></>
					)}
				</span>
			);
		},
		Temp: (altProps) => {
			let failure = data[altProps - 1].Temp > data[altProps - 1].MaxTemp ? 'OVER' : 'UNDER';

			failure =
				data?.[altProps - 1]?.TempCheckRequired &&
				!data?.[altProps - 1]?.IsTempInRange &&
				failure;
			const tempValue = get(data[altProps - 1], COLMAP.Temp);
			return (
				<span
					className={`${styles['no-wrap']} ${styles.text} ${
						failure ? styles.critical : styles.normal
					}`}
				>
					{tempValue?.toFixed(2) ?? '-'}
					{tempValue ? ` ${data[altProps - 1]?.tempUnit ?? ''}` : ''}
					{data[altProps - 1].IsTempInRange === false ? (
						<>
							{failure === 'OVER' && <ArrowUpward />}
							{failure === 'UNDER' && <ArrowDownward />}
						</>
					) : (
						<></>
					)}
				</span>
			);
		},
		// Brand: (altProps) => {
		// return (
		// <span className={styles['brand-column']}>
		// <img
		// className={styles.logo}
		// src={data[altProps - 1]?.BrandImage}
		// alt='Brand'
		// />
		// <p>{data[altProps - 1]?.brandName}</p>
		// </span>
		// );
		// },
		Reason: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data[altProps - 1]?.WastageReason ?? '-'}</p>
				</span>
			);
		},
		Store: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data[altProps - 1]?.storeName ?? '-'}</p>
				</span>
			);
		},
		Outlet: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data[altProps - 1]?.fromStoreName ?? '-'}</p>
				</span>
			);
		},
		Type: (altProps) => {
			return (
				<span className={styles['brand-column']}>
					<p>{data[altProps - 1]?.type ?? '-'}</p>
				</span>
			);
		},
		'QC TIME': (altProps) => {
			return setAttr(get(data[altProps - 1], COLMAP['QC TIME']));
		},
		'Product Name': (altProps) => {
			return setAttr(get(data[altProps - 1], COLMAP['Product Name']));
		},
		'Inventory Type': (altProps) => {
			const currItem = data[altProps - 1];
			return setAttr(Modes?.[currItem.type], COLMAP['Inventory Type']);
		},
		'Order Id': (altProps) => {
			const text = get(data[altProps - 1], COLMAP['Order Id']);
			// eslint-disable-next-line prefer-template
			return setAttr(`...${text?.substring(text?.length - 6, text?.length - 1)}`);
		},
	};

	const handleExport = async () => {
		try {
			req.data.export = true;
			req.data.qcType = 'WASTAGE';
			const response = await commonAnalyticsServices.exportAnalyticsList({
				req: {
					...req,
				},
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'WASTAGE_HISTORY.csv'
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occurred',
			});
		}
	};

	return (
		<div className={classes(styles.root)}>
			<VirtualizedListTable
				fixedHeaderTop={0}
				renderColumn={renderColumn}
				colMap={COLMAP}
				hasDateRange
				special={SPECIAL_ITEMS}
				data={data.map((obj, idx) => {
					const rowStyles = getRowStyles(obj);
					const itemId = get(obj, COLMAP['Item Id']);
					const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						id: idx + 1,
						rowStyles,
						onClick: () => {
							if (obj.type !== 'APP') {
								history.push({
									pathname: `${urls.EXTENSION}${urls.PAST_QC}/${itemId}`,
									search: `id=${qc}`,
									state: {
										from: history?.location?.pathname,
									},
								});
							}
						},
					};
				})}
				columns={[]}
				actions={[...TABLE_COLUMNS]}
				onExportClick={handleExport}
			/>
			{/* <ArrangePages
				listCount={61}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				setAnalyticsType={setAnalyticsType}
			/> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		GEO_FILTER,
		BRAND_FILTER,
		DATE_RANGE_FILTER,
		FAILURE_ANALYSIS_FILTER,
		PRODUCT_FILTER,
		STORE_FILTER,
		FROM_STORE_FILTER,
		PROCESS_TYPE_FILTER,
		SKU_TYPE_FILTER,
		UNDETECTED_PRODUCTS_FILTER,
		VALIDATED_DATA_FILTER,
		IDENTIFIED_DATA_FILTER,
		FEATURES_DATA_FILTER,
		DUPLICATE_DATA_FILTER,
	} = (state.analytics);
	const filters = deserializeFilters({
		GEO_FILTER,
		BRAND_FILTER,
		DATE_RANGE_FILTER,
		FAILURE_ANALYSIS_FILTER,
		PRODUCT_FILTER,
		STORE_FILTER,
		FROM_STORE_FILTER,
		PROCESS_TYPE_FILTER,
		SKU_TYPE_FILTER,
		UNDETECTED_PRODUCTS_FILTER,
		VALIDATED_DATA_FILTER,
		IDENTIFIED_DATA_FILTER,
		FEATURES_DATA_FILTER,
		DUPLICATE_DATA_FILTER,
	});
	const req = {
		data: {
			...filters,
		},
	};
	return {
		req,
	};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
};
export default connect(mapStateToProps, mapDispatchToProps)(WastageListTable);
