import { cloneDeep } from 'lodash';
import { convertWeight } from '../../../../Helpers';

function formatWeight(weight, unit) {
	// Conversion factors
	const kgToG = 1000;
	const lbToG = 453.592;
	const ozToG = 28.3495;

	let weightInGrams;

	// Convert to grams based on the given unit
	switch (unit.toLowerCase()) {
		case 'kgs':
			weightInGrams = weight * kgToG;
			break;
		case 'lbs':
			weightInGrams = weight * lbToG;
			break;
		case 'oz':
			weightInGrams = weight * ozToG;
			break;
		default:
			return 'Invalid unit provided';
	}

	weightInGrams = Math.round(weightInGrams);

	return weightInGrams;
}

const initialState = {
	containerName: '',
	containerImage: null,
	weight: null,
	containerUnit: '',
	mode: '',
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const mandatoryFields = [
	{
		field: 'containerName',
		displayName: 'Container Name',
	},
	{
		field: 'containerImage',
		displayName: 'Container Image',
	},
	{
		field: 'weight',
		displayName: 'Weight',
	},
	{
		field: 'mode',
		displayName: 'Mode',
	},
];

const config = {
	initialState,
	reducer,
	serializer: (state, data) => {
		const isUS = data?.isUS;
		const newWeightAndUnit = convertWeight(data?.weight, isUS);
		const newState = {
			containerName: data?.containerName,
			containerImage: data?.containerImage,
			mode: data?.type,
			weight: newWeightAndUnit?.value,
			containerUnit: newWeightAndUnit?.unit,
		};
		return newState;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const newState = {
			...clonedState,
			containerImage: clonedState.containerImage,
			weight: formatWeight(clonedState.weight, clonedState.containerUnit),
		};
		return newState;
	},
	validator: (state) => {
		return mandatoryFields?.find((key) => {
			return state[key?.field] === null || state[key?.field] === '';
		});
	},
};

export default config;
