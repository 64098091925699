/* eslint-disable object-curly-newline */
import { connect } from 'react-redux';
import { Button, MenuItem, TextField } from '@mui/material';
import { useReducer, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import styles from './CreateUserPage.module.scss';
import { BackdropLoader } from '../../../../Components/Loaders';
import config from './createUserPage.config';
import { classes, inputHelper, useQuery } from '../../../../Helpers';
import { authServices, notificationServices } from '../../../../Services';
import { CreatePageHeader } from '../../../../Components/Headers';
import AppModules from '../../../../Components/AppModules/AppModules';
import { formActions, modalActions } from '../../../../Actions';
import assetActions from '../../../../Actions/asset.actions';

const accessLevels = ['VIEW', 'EDIT', 'ANNOTATE', 'INTERNAL', 'CONFIDENTIAL', 'FINANCE', 'MANAGER', 'CONFIG_EDIT'];

const LabelledInput = (props) => {
	const {
		value,
		onChange,
		id,
		name,
		inputLabel,
		labelText,
		mandatory,
		optionsList,
		disabled,
	} = props;
	return (
		<div className={`${styles.tile} form-field row`}>
			<label
				className={`${mandatory ? styles.mandatory : ''} form-label`}
				htmlFor={id}
			>
				{labelText}
			</label>
			<TextField
				id={id}
				select
				name={name}
				value={value}
				onChange={onChange}
				label={inputLabel}
				disabled={disabled}
				sx={{
					width: 200,
					'.MuiInputBase-root': {
						borderRadius: '4px',
					},
					// '.MuiInputBase-input': {
					// // paddingTop: '6px',
					// // paddingBottom: '6px',
					// },
				}}
			>
				{optionsList?.map((option) => {
					return (
						<MenuItem
							key={option?.id ?? option}
							value={option?.id ?? option}
						>
							{option?.title ?? option}
						</MenuItem>
					);
				})}
			</TextField>
		</div>
	);
};

function CreateUserPage(props) {
	const {
		storesList,
		fetchStoresList,
		fetchProcessTypesList,
		processTypes,
		fetchStoreAssetListing,
		assets,
		userPermissions,
	} = props;
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const {
		username,
		password,
		confirmationPassword,
		displayName,
		email,
		contact,
		accessLevel,
		appModules,
		businessUser,
		processId,
		assetId,
		store,
	} = state;
	const params = useQuery();
	const history = useHistory();
	const id = parseInt(params.get('id'), 10);
	const isEdit = Boolean(params.get('isEdit'));
	const isInternal = userPermissions?.INTERNAL;
	const [errors, setErrors] = useState({});
	const [isLoading, setIsloading] = useState(false);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		if (store) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: store,
					},
				},
				method: 'LIST',
			});
			fetchStoreAssetListing(
				{
					req: {
						data: {
							storeId: store,
							permanent: true,
							category: 'IMMOVABLE',
						},
					},
				}
			);
		}
	}, [store]);

	useEffect(() => {
		if (confirmationPassword && !password) {
			setErrors({
				confirmationPassword: 'Please Enter Password first',
			});
		} else if (confirmationPassword && password && password !== confirmationPassword) {
			setErrors({
				confirmationPassword:
					"Password and Confirm Password doesn't match",
			});
		} else {
			setErrors({});
		}
	}, [password, confirmationPassword]);

	const fetchUser = async () => {
		setIsloading(true);
		try {
			const response = await authServices.fetchUsersList({
				params: {
					userId: id,
				},
			});
			if (response?.statusCode === 200) {
				const userData = response?.data?.[0];
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'SET_VALUE',
					payload: {
						id: userData?.id,
						username: userData?.username,
						displayName: userData?.displayName,
						email: userData?.email,
						contact: userData?.username,
						accessLevel: userData?.accessLevel?.split(','),
						appModules: userData?.appModules ?? [],
						businessUser: userData?.isBuisnessUser > 0,
						processId: userData?.processId,
						assetId: userData?.assetId,
						store: userData?.storeId,
					},
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		if (isEdit && id) {
			fetchUser();
		}
	}, [id, isEdit]);

	const handleChange = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);

		if (fieldName === 'processId' && state?.assetId !== -11) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					assetId: null,
				},
			});
		}

		if (fieldName === 'assetId' && state?.processId !== -11) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					processId: null,
				},
			});
		}
		if (fieldName === 'businessUser' && fieldValue) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					processId: null,
					assetId: null,
				},
			});
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[fieldName]: fieldValue,
			},
		});
	};

	const selectAccessLevel = (level, isSelected) => {
		let tempData = [...accessLevel];
		if (isSelected) {
			tempData = tempData?.filter((ele) => {
				return ele !== level;
			});
		} else {
			tempData?.push(level);
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				accessLevel: tempData,
			},
		});
	};

	const selectAppModules = (appModule, isSelected) => {
		let tempData = [...appModules];
		if (isSelected) {
			tempData = tempData?.filter((ele) => {
				return ele !== appModule;
			});
		} else {
			tempData?.push(appModule);
		}
		dispatch({
			type: 'SET_VALUE',
			payload: {
				appModules: tempData,
			},
		});
	};

	const handleAllModules = (allAppModules) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				appModules: allAppModules,
			},
		});
	};

	const STORES_LIST = useMemo(() => {
		const tempData = [
			{
				title: 'None',
				id: -11,
			},
			...storesList?.map((ele) => {
				return {
					...ele,
					title: ele?.name,
				};
			}),
		];
		return tempData;
	}, [processTypes]);

	const PROCESS_LIST = useMemo(() => {
		const tempData = [
			{
				title: 'None',
				id: -11,
			},
			...processTypes?.map((ele) => {
				return {
					...ele,
					title: ele?.data?.heading,
				};
			}),
		];
		return tempData;
	}, [processTypes]);

	const ASSETS_LIST = useMemo(() => {
		const tempData = [
			{
				title: 'None',
				id: -11,
			},
			...assets?.map((ele) => {
				return {
					...ele,
					// eslint-disable-next-line prefer-template
					title: ele?.name + '[id:' + ele.id + ']',
				};
			}),
		];
		return tempData;
	}, [assets]);

	const handleSubmit = async () => {
		const isValid = config?.validator(state, isEdit);
		if (isValid) {
			try {
				const deserializedData = config?.deserializer(state, isInternal);
				const req = {
					data: deserializedData,
				};
				const response = await authServices?.createUser(req);
				if (response?.statusCode === 200) {
					notificationServices.generateNotification({
						type: 'success',
						message: response?.message,
					});
					dispatch({
						type: 'INIT',
					});
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message: response?.message,
					});
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
			}
		}
	};

	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				{/* {isEdit ? (
					<Button
						onClick={() => {
							setIsEdit(false);
							dispatch({
								type: 'INIT',
							});
						}}
						variant='outlined'
						color='error'
						type='button'
					>
						Cancel
					</Button>
				) : (
					<Button onClick={handleEditUser} variant='outlined' color='primary' type='button'>
						Edit Logged In
					</Button>
				)} */}
				<Button onClick={handleSubmit} variant='contained' color='primary' type='button'>
					Save
				</Button>
			</div>
		);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const checkAssetProcessId = (type) => {
		if (type === 'processId') {
			if ((assetId && assetId !== -11) || !store) {
				return true;
			}
		}
		if (type === 'assetId') {
			if ((processId && processId !== -11) || !store) {
				return true;
			}
		}
		if (businessUser) {
			return true;
		}
		return false;
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} />
			<CreatePageHeader
				handleSubmit={handleSubmit}
				showCancel={false}
				heading='Create User'
				renderBtns={renderBtns}
				showBack
				handleBackClick={handleBackClick}
			/>
			<div className={styles.body}>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Username*
						</label>
						<TextField
							id='username'
							name='username'
							value={username}
							onChange={handleChange}
							placeholder='Enter Username'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Display Name
						</label>
						<TextField
							id='displayName'
							name='displayName'
							value={displayName}
							onChange={handleChange}
							autoComplete='off'
							placeholder='Enter Display name'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
				</div>
				{(!isEdit || isInternal) && (
					<div className={styles.multi}>
						<div className={`${styles.tile} form-field row`}>
							<label
								className={`${styles.mandatory} form-label`}
								// htmlFor={id}
							>
								{isEdit ? 'New ' : ''}
								Password*
							</label>
							<TextField
								id='Password'
								type='password'
								name='password'
								autoComplete='off'
								value={password}
								onChange={handleChange}
								placeholder={`Enter ${
									isEdit ? ' New ' : ''
								} Password`}
								sx={{
									'.MuiInputBase-root': {
										borderRadius: '4px',
									},
									'.MuiInputBase-input': {
										paddingTop: '6px',
										paddingBottom: '6px',
									},
								}}
							/>
						</div>
						<div className={`${styles.tile} form-field row`}>
							<label
								className={`${styles.mandatory} form-label`}
								// htmlFor={id}
							>
								Confirm
								{isEdit ? ' New ' : ''}
								Password*
							</label>
							<TextField
								id='confirmationPassword'
								type='password'
								name='confirmationPassword'
								value={confirmationPassword}
								onChange={handleChange}
								placeholder={`Enter ${
									isEdit ? ' New ' : ''
								} Confirm Password`}
								sx={{
									'.MuiInputBase-root': {
										borderRadius: '4px',
									},
									'.MuiInputBase-input': {
										paddingTop: '6px',
										paddingBottom: '6px',
									},
								}}
							/>
							<p className={styles.error}>
								{errors?.confirmationPassword}
							</p>
						</div>
					</div>
				)}
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Email Address
						</label>
						<TextField
							id='email'
							name='email'
							value={email}
							onChange={handleChange}
							placeholder='Enter Email address'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Contact Number
						</label>
						<TextField
							id='contact'
							name='contact'
							value={contact}
							onChange={handleChange}
							placeholder='Enter Number'
							sx={{
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						/>
					</div>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className='form-label'
						// htmlFor={id}
					>
						Is Business User?
					</label>
					<div className={styles['access-level']}>
						<input
							name='businessUser'
							type='checkbox'
							checked={businessUser}
							onChange={handleChange}
						/>
						<p>Business User</p>
					</div>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Access Level
						{accessLevel?.length === 0 ? ' (at-least 1)*' : ''}
					</label>
					<div className={styles['access-level']}>
						{accessLevels?.map((level) => {
							const isSelected = accessLevel?.includes(level);
							return (
								<div
									onClick={() => {
										selectAccessLevel(level, isSelected);
									}}
									className={classes(
										styles['access-level-item'],
										isSelected ? styles.selected : ''
									)}
								>
									{level}
								</div>
							);
						})}
					</div>
				</div>
				<div className={`${styles.tile} form-field row`}>
					<label
						className='form-label'
						// htmlFor={id}
					>
						App Modules
					</label>
					<AppModules
						type='user'
						selectedList={appModules}
						handleAppModule={selectAppModules}
						handleAllSelection={handleAllModules}
						handleRemoveAll={handleAllModules}
					/>
				</div>
				<LabelledInput
					mandatory={!businessUser}
					// disabled={businessUser}
					id='store'
					name='store'
					value={store}
					onChange={handleChange}
					labelText={`Select Store ${!businessUser ? '*' : ''}`}
					optionsList={STORES_LIST}
					inputLabel='Select Store'
				/>
				<div className={styles.multi}>
					<LabelledInput
						id='process'
						name='processId'
						value={processId}
						onChange={handleChange}
						labelText='Select Process'
						disabled={checkAssetProcessId('processId')}
						optionsList={PROCESS_LIST}
						inputLabel='Select Process'
					/>
					<LabelledInput
						id='asset'
						name='assetId'
						value={assetId}
						onChange={handleChange}
						labelText='Select Asset'
						disabled={checkAssetProcessId('assetId')}
						optionsList={ASSETS_LIST}
						inputLabel='Select Asset'
					/>
				</div>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const storesList = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const assets = state.assets.storeAssetList?.assets;

	return {
		userPermissions,
		storesList,
		processTypes,
		assets,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAuditItemsModal,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
	fetchStoreAssetListing: assetActions.fetchStoreAssetListing,

};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserPage);
