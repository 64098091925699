import { Button } from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { BaseModal } from '../BaseModal';

const RouterPromptModal = (props) => {
	// eslint-disable-next-line object-curly-newline
	const { when, onOK, onCancel, title, okText, cancelText, message } = props;

	const history = useHistory();

	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');

	const unblockRef = useRef();

	useEffect(() => {
		unblockRef.current = history.block((prompt) => {
			if (when) {
				setCurrentPath(`${prompt.pathname}${prompt.search}`);
				setShowPrompt(true);
				return false;
			}
			return true;
		});
		return () => {
			if (unblockRef.current) {
				unblockRef.current();
			}
		};
	}, [when]);

	const handleOK = async () => {
		if (onOK) {
			if (unblockRef) {
				unblockRef.current();
			}
			const canRoute = await Promise.resolve(onOK());
			if (canRoute) {
				setShowPrompt(false);
				history.push(currentPath);
			}
		}
	};

	const handleCancel = async () => {
		if (onCancel) {
			if (unblockRef) {
				unblockRef.current();
			}
			const canRoute = await Promise.resolve(onCancel());
			if (canRoute) {
				setShowPrompt(false);
				history.push(currentPath);
			}
		}
	};

	const renderBtns = () => {
		return (
			<div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-around',
				}}
			>
				<Button
					variant='contained'
					color='error'
					onClick={handleCancel}
					sx={{
						color: 'white',
					}}
				>
					{cancelText || 'Cancel'}
				</Button>
				<Button variant='contained' color='primary' onClick={handleOK}>
					{okText || 'OK'}
				</Button>
			</div>
		);
	};

	return showPrompt ? (
		<BaseModal
			open={showPrompt}
			heading={title}
			height='12rem'
			width='32rem'
			showClose={false}
			renderBtns={renderBtns}
		>
			{message ||
				'Are you sure you want to navigate away from this page?'}
		</BaseModal>
	) : null;
};

export default RouterPromptModal;
