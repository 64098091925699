// import { Add } from '@mui/icons-material';
import Checkbox from 'rsuite/Checkbox';
import CheckboxGroup from 'rsuite/CheckboxGroup';
import styles from './AuditStep2.module.scss';
import MultiCascaderTree from '../../../MultiCascaderTree/MultiCascaderTree';

const storesTypes = [
	{
		title: 'BASE',
		value: 'BASE',
	},
	{
		title: 'RETAIL',
		value: 'RETAIL',
	},
	{
		title: 'WAREHOUSE INGREDIENT',
		value: 'WAREHOUSE_INGREDIENT',
	},
	{
		title: 'WAREHOUSE',
		value: 'WAREHOUSE',
	},
	{
		title: 'WAREHOUSE PRODUCT',
		value: 'WAREHOUSE_PRODUCT',
	},
];

const AuditStep2 = (props) => {
	const {
		inputState,
		setInputState,
		regions,
		handleStoreTypeSelection,
		auditCreationState,
		validStoreTypes,
	} = props;

	// const STORES = useMemo(() => {
	// const tempStore = stores?.map((tStore) => {
	// return {
	// id: tStore.id,
	// title: tStore?.name ?? '',
	// type: tStore?.type,
	// };
	// });
	// return tempStore;
	// }, [stores]);

	const STORE_TYPES = storesTypes?.filter((ele) => {
		return Object.keys(validStoreTypes).length === 0 || validStoreTypes[ele?.value];
	});

	return (
		<div className={styles.root}>
			{/* <div className={styles.select_all}>
				<input
					type='checkbox'
					checked={allStoresSelected}
					onChange={(e) => {
						console.log('e?.target?.value', e?.target?.value);
						setAllStoresSelected(e?.target?.value);
					}}
				/>
			</div> */}
			{(STORE_TYPES.length > 1 || STORE_TYPES.length === 0) && (
				<div className={styles.type}>
					<p>Select Store Type:</p>
					<CheckboxGroup
						className={styles.checkbox}
						inline
						name='checkbox-group'
						onChange={handleStoreTypeSelection}
						value={auditCreationState?.selectedStoreTypes}
					>
						{STORE_TYPES?.map((ele) => {
							return (
								<Checkbox
								// checked={auditCreationState?.selectedStoreTypes?.includes(
								// ele?.value
								// )}
									value={ele?.value}
								>
									{ele?.title}
								</Checkbox>
							);
						})}
					</CheckboxGroup>
				</div>
			)}
			<MultiCascaderTree
				inputState={inputState}
				setInputState={setInputState}
				headers={regions}
				widthPercentage={70}
				disabled
			/>
		</div>
	);
};

export default AuditStep2;
