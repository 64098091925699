import _ from 'lodash';

const cookingProductConfig = {
	initialState: {
		fields: {
			productName: 'Product Name',
			productDescription: 'Product Description',
			image: '',
			timeReq: '',
			isEnabled: false,
			cookingRequired: false,
		},
	},
	list: [],
	deserializer: (state, data) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				productName: data.productName || '',
				productDescription: data.productDescription || '',
				image: data.productImageUrl || '',
				timeReq: data.cookingTime,
				isEnabled: data.isCookingEnabled || false,
				cookingRequired: data.isCMSEnabled || false,
			};
			return newState;
		});
	},
	serializer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				cookingTime: state.fields.timeReq
					? parseInt(state.fields.timeReq, 10)
					: 0,
				isCookingEnabled: state.fields.isEnabled || false,
			};
			return newState;
		});
	},
};
export default cookingProductConfig;
