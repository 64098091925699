/* eslint-disable no-restricted-globals */
/* eslint-disable no-case-declarations */
// eslint-disable-next-line object-curly-newline
import _ from 'lodash';
import { useEffect, useState } from 'react';
// import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import {
	GeneralDetails,
	Payment,
	StoreDesign,
} from '../../../Components/Forms/Stores';
import ManagerDetails from '../../../Components/Forms/Stores/ManagerDetails/ManagerDetails';
import ServiceModes from '../../../Components/Forms/Stores/Mode of Services/ServiceModes';
import StoreTiming from '../../../Components/Forms/Stores/Store Timings/StoreTiming';
import { CreatePageHeader } from '../../../Components/Headers';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { imageToBase64, inputHelper, isEmpty } from '../../../Helpers';
import StoresConfig from './Stores.config';

const generateBannerPromises = (banners) => {
	if (banners === null || banners.length === 0 || banners[0] === null) {
		return [];
	}
	return banners.map((banner) => {
		return imageToBase64(banner);
	});
};

const StoreCreatePage = (props) => {
	const { isEdit, saveStore, data, fetchStore, breadcrumbs } = props;
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const [state, setState] = useState({
		...StoresConfig.initialState,
	});
	const { fields, errors } = state;
	const StoreId = useParams().id;
	const history = useHistory();
	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && data.id === StoreId) {
				StoresConfig.serializer(state, data).then((newState) => {
					setState(newState);
				});
			} else {
				fetchStore({
					entity: 'STORES',
					req: {
						params: {
							id: StoreId,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			if (!isEmpty(data) && numberOfSubmissions === 0) {
				StoresConfig.serializer(state, data).then((newState) => {
					setState(newState);
				});
			}
		}
	}, [data]);

	const handleInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'support':
				if (fieldValue.toString().length > 11) {
					return;
				}
				break;
			case 'phoneno':
				if (fieldValue.toString().length > 11) {
					return;
				}
				break;
			case 'city':
				newState[fieldName] = JSON.parse(fieldValue);
				break;
			case 'pincode':
				if (fieldValue.toString().length > 6) {
					return;
				}
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};
	const handleAppModule = (module, isSelected) => {
		let temp = [...state?.fields?.appModules];
		if (isSelected) {
			temp = temp?.filter((ele) => {
				return ele !== module;
			});
		} else {
			temp?.push(module);
		}
		setState(
			_.update(
				_.cloneDeep(state),
				'fields.appModules',
				() => {
					return temp;
				}
			)
		);
	};

	const handleSelectAllModule = (appModules) => {
		setState((prev) => {
			return {
				...prev,
				fields: {
					...prev.fields,
					appModules: [...appModules],
				},
				...errors,
			};
		});
	};

	const addNewPincode = (pincode) => {
		setState(
			_.update(
				_.cloneDeep(state),
				'fields.serviceablePincodes',
				(pincodes) => {
					pincodes.push(pincode);
					return pincodes;
				}
			)
		);
	};
	const handleImageInput = (fieldName, blob) => {
		setState(
			_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
				return blob;
			})
		);
	};
	const handleFileInput = (e) => {
		const fieldName = 'QRcode';
		const file = e.target.files[0];
		switch (fieldName) {
			case 'QRcode':
			case 'bannerImg':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.STORES}/view/${StoreId}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.STORES}`);
		}
	};
	const handlePincodeDelete = (idx) => {
		const arr = [...fields.serviceablePincodes];
		arr.splice(idx, 1);
		setState({
			...state,
			fields: {
				...state.fields,
				serviceablePincodes: arr,
			},
			...errors,
		});
	};
	const handleSave = (e) => {
		e.preventDefault();
		const req = {};
		const valid = StoresConfig.validator(state);
		if (valid) {
			req.data = StoresConfig.deserializer(state);
			Promise.all([
				...generateBannerPromises(req.data.bannerImages),
				...generateBannerPromises([req.data.storeQRImageUrl]),
			]).then((values) => {
				if (req.data.bannerImages) {
					const bannerLength = req.data.bannerImages.length;
					req.data.bannerImages = [];
					for (let i = 0; i <= bannerLength; i++) {
						if (i < bannerLength) {
							req.data.bannerImages.push(values[i] || null);
						} else {
							req.data.storeQRImageUrl = values[i] || null;
						}
					}
				} else {
					req.data.bannerImages = [];
					req.data.storeQRImageUrl = values[0] || null;
				}
				setNumberOfSubmissions(numberOfSubmissions + 1);
				if (isEdit) {
					req.params = {
						id: StoreId,
					};
					saveStore({
						entity: 'STORES',
						req,
						method: 'UPDATE',
						redirect: `${urls.EXTENSION}${urls.STORES}`,
					});
				} else {
					saveStore({
						entity: 'STORES',
						req,
						method: 'CREATE',
						redirect: `${urls.EXTENSION}${urls.STORES}`,
					});
				}
			});
		}
	};
	const generateDefaultOption = (type, city) => {
		switch (type) {
			case 'city':
				if (!city || city.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a City
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const handleBannerList = (list) => {
		const newState = {};
		setState({
			...state,
			fields: {
				...state.fields,
				bannerList: list,
				...newState,
			},
			...errors,
		});
	};
	const handleIsChecked = (name, value) => {
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.enableSection[${name}]`,
				() => {
					return !value;
				}
			)
		);
	};
	const updateLocation = ({ latitude = null, longitude = null }) => {
		const newState = {};
		setState({
			...state,
			fields: {
				...state.fields,
				latitude,
				longitude,
				...newState,
			},
			...errors,
		});
	};
	const addSlab = () => {
		const newState = {};
		const newSlab = fields.slabs;
		newSlab.push({
			deliveryCharges: null,
			deliveryRadius: null,
		});
		setState({
			...state,
			fields: {
				...state.fields,
				slabs: newSlab,
				...newState,
			},
			...errors,
		});
	};

	const deleteSlab = (idx) => {
		const slabArr = [...fields.slabs];
		slabArr.splice(idx, 1);
		setState({
			...state,
			fields: {
				...state.fields,
				slabs: slabArr,
			},
		});
	};

	const handleSlabInput = (e, idx) => {
		const { fieldName, fieldValue } = inputHelper(e);
		const slabArr = [...fields.slabs];
		slabArr[idx][fieldName] = fieldValue;
		setState({
			...state,
			fields: {
				...state.fields,
				slabs: slabArr,
			},
		});
	};

	const updateTime = (name, value) => {
		const newState = {};
		setState({
			...state,
			fields: {
				...state.fields,
				[name]: value,
				...newState,
			},
			...errors,
		});
	};
	return (
		<div className='root'>
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading={isEdit ? 'Edit Store' : 'Create Store'}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${fields.storeName}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				{/* general details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
					handleIsChecked={handleIsChecked}
					name='generalDetails'
					isChecked={fields.enableSection.generalDetails}
				>
					<GeneralDetails
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						generateDefaultOption={generateDefaultOption}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
						updateLocation={updateLocation}
						handleAppModule={handleAppModule}
						handleSelectAllModule={handleSelectAllModule}
						coords={{
							lat: fields.latitude,
							lng: fields.longitude,
						}}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Manager Details'
					// isExpandable='true'
					handleIsChecked={handleIsChecked}
					name='managerDetails'
					isChecked={fields.enableSection.managerDetails}
				>
					<ManagerDetails
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						handleFileInput={handleFileInput}
						isEdit={isEdit}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Store Timings'
					isExpandable='true'
					handleIsChecked={handleIsChecked}
					name='storeTiming'
					isChecked={fields.enableSection.storeTiming}
				>
					<StoreTiming
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						updateTime={updateTime}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Mode Of Services'
					isExpandable='true'
					handleIsChecked={handleIsChecked}
					name='serviceModes'
					isChecked={fields.enableSection.serviceModes}
				>
					<ServiceModes
						mandatoryFields={StoresConfig.mandatoryFields}
						handleConfirm={addNewPincode}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						handleDelete={handlePincodeDelete}
						addSlab={addSlab}
						deleteSlab={deleteSlab}
						handleSlabInput={handleSlabInput}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Payment Details'
					isExpandable='true'
					handleIsChecked={handleIsChecked}
					name='payment'
					isChecked={fields.enableSection.payment}
				>
					<Payment
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						errors={errors}
						handleInput={handleInput}
						isEdit={isEdit}
						handleImageInput={handleImageInput}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Store Design'
					isExpandable='true'
					handleIsChecked={handleIsChecked}
					name='storeDesign'
					isChecked={fields.enableSection.storeDesign}
				>
					<StoreDesign
						mandatoryFields={StoresConfig.mandatoryFields}
						fields={fields}
						isEdit={isEdit}
						handleBannerList={handleBannerList}
					/>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.STORES.data.VIEW;
	return {
		data,
	};
};

const mapDispatchToProps = {
	fetchStore: formActions.gateway,
	saveStore: formActions.gateway,
};

StoreCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreCreatePage);
