import { connect } from 'react-redux';
import { modalActions } from '../../../../../../Actions';
import { modalTypes } from '../../../../../../Constants';
import { inputHelper } from '../../../../../../Helpers';
import { StageDependenciesModal } from '../../../../../Modals/StageDependenciesModal';
import { Tag } from '../../../../../Tags';
import { AddTag } from '../../../../../Tags/AddTag';
import styles from './StageDependencies.module.scss';

const StageDependencies = (props) => {
	const {
		stages,
		activeStage,
		toggleStageDependencies,
		addDependencies,
		isDisabled,
	} = props;
	const { dependencies } = stages.items[activeStage];

	const openDependenciesModal = () => {
		toggleStageDependencies(true, {
			stages,
			activeStage,
		});
	};

	const removeDependency = (e) => {
		if (!isDisabled) {
			const { dataset } = inputHelper(e);
			props.removeDependency(dataset.id);
		}
	};

	const generateTags = () => {
		return dependencies.map((dep, idx) => {
			const stageName = stages.items[dep].name;
			const stageOrder = stages.order.findIndex((stageId) => {
				return stageId === dep;
			});
			const text = `Stage ${stageOrder + 1} - ${stageName}`;
			return (
				<Tag
					className={`${styles.tag}`}
					handleDelete={removeDependency}
					text={text}
					dataAttrs={{
						'data-type': 'stage',
						'data-id': idx,
						'data-name': 'dependencies',
					}}
					isDisabled={isDisabled}
				/>
			);
		});
	};

	return (
		<div className={styles.root}>
			<div className={styles['tags-wrapper']}>
				{generateTags()}
				{!isDisabled && (
					<AddTag
						handleClick={openDependenciesModal}
						text='DEPENDENCY'
					/>
				)}
			</div>
			<StageDependenciesModal
				selectedDeps={dependencies}
				handleConfirm={addDependencies}
			/>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleStageDependencies: (show, data = {}) => {
			dispatch(
				modalActions.toggleModal(
					modalTypes.COOKING_STAGE_DEPS,
					show,
					data
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StageDependencies);
