/* eslint-disable  */
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import Nav from 'rsuite/Nav';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { BaseModal } from '../BaseModal';
import { modalActions } from '../../../Actions';
import { ListTable } from '../../ListTable';
import styles from './LeftOverItemsModal.module.scss';
import {
	addCommas,
	classes,
	getUnitConvertedQuantity,
	getUnitConvertedUnit,
	handleExcelDownload,
} from '../../../Helpers';
import excel from '../../../Images/excel.svg';
import machine from '../../../Images/scan.jpeg';

const ACTION_COLUMNS = [];

const COLMAP = {
	'Product Name': 'productName',
	Unit: 'unit',
	Dispatched: 'fulfilled',
	Received: 'storeFulfilled',
	Packaged: 'packaged',
	'Ordered Quantity': 'qty',
	Quantity: 'qty',
	'Captured On': 'onMachine',
};
const TABLE_COLUMNS = [];

const SPECIAL_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ordered Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Captured On',
		config: {
			type: 'DATA',
		},
	},
];

const LeftOverItemsModal = (props) => {
	const [selectedTypeTab, setSelectedTypeTab] = useState('INTERMEDIATE');
	const [searchQuery, setSearchQuery] = useState('');
	const { show, data, toggleModal } = props;
	const {
		items = [],
		heading = 'Left Over Invntory Items',
		excludedColumns,
		showProductionPlanColumns = false,
	} = data;
	const handleClose = () => {
		toggleModal(false, {});
	};

	const ITEMS = useMemo(() => {
		return items?.[selectedTypeTab]
			?.filter((item) => {
				if (!searchQuery) {
					return true; // Return true for all items when searchQuery is empty
				}
				return item?.productName
					?.toLowerCase()
					?.includes(searchQuery?.toLowerCase());
			});
	}, [items, searchQuery, selectedTypeTab]);

	const SPECIAL_ITEMS = {
		'Product Name': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				<div>
					<p>
						{item?.productName +
							(item?.variantName
								? '(' + item?.variantName + ')'
								: '')}
					</p>
					<p>{item?.description ? item?.description : ''}</p>
				</div>
			);
		},
		'Captured On': (itemId) => {
			const captured = ITEMS.find((ele) => itemId === ele?.id)?.onMachine;
			return captured ? (
				<div className='w-[40px] ml-[5rem]'>
					<img src={machine} alt='machine' />
				</div>
			) : (
				<div className='w-[30px] ml-[2rem]'>
					<KeyboardIcon fontSize='large' />
				</div>
			);
		},
		'Used For': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				<div>
					<p>{item?.usedFor}</p>
				</div>
			);
		},
		Unit: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemUnit = item?.unit;
			return getUnitConvertedUnit(item?.qty, itemUnit, true, false);
			// return itemUnit;
		},
		'Category Name': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.categoryName ?? '-';
		},
		'Ordered Quantity': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemQty = item?.qty;
			return getUnitConvertedQuantity(itemQty, item?.unit, true, true);
		},
		'Returned Quantity': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemQty = item?.qty;
			return getUnitConvertedQuantity(itemQty, item?.unit, true, true);
		},
		Quantity: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemQty = item?.qty;
			return getUnitConvertedQuantity(itemQty, item?.unit, true, true);
		},
		Packaged: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemPacked = item?.packaged;
			return getUnitConvertedQuantity(itemPacked, item?.unit, true, true);
		},
		Received: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemReceived = item?.storeFulfilled;
			return getUnitConvertedQuantity(
				itemReceived,
				item?.unit,
				true,
				true
			);
		},
		Dispatched: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemFulfilled = item?.fulfilled;
			return getUnitConvertedQuantity(
				itemFulfilled,
				item?.unit,
				true,
				true
			);
		},
		Fulfilled: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemFulfilled = item?.fulfilled;
			return getUnitConvertedQuantity(
				itemFulfilled,
				item?.unit,
				true,
				true
			);
		},
		Returned: (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			const itemReturned = item?.returned;
			return getUnitConvertedQuantity(
				itemReturned,
				item?.unit,
				true,
				true
			);
		},
		'From Process': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.fromProcess ?? '-';
		},
		'PO Id': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.poId ?? '-';
		},
		'Production Plan Name': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			// return item?.poId ?? '-';
			return item?.clientPoId ?? '-';
		},
		'Total Item Amount': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return (
				`₹${addCommas(item?.totalValue ?? item?.finalAmount)}` ?? '-'
			);
		},
		'Item Price': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `₹${item?.productCost ?? item?.price ?? '-'}`;
		},
		'Discount %': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return item?.discountPercentage ?? '-';
		},
		'Tax Percentage': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			return `${item?.taxPercentage}%` ?? '-';
		},
		'Tax value': (itemId) => {
			const item = ITEMS?.find((poItem) => {
				return itemId === poItem?.id;
			});
			let itemQty = item?.qty;
			itemQty = getUnitConvertedQuantity(itemQty, item?.unit, true, true);
			let itemPrice = item?.price;
			if (item?.unit === 'gram' || item?.unit === 'ml') {
				itemPrice /= 1000;
			}
			if (item?.unit === 'oz') {
				itemPrice /= 16;
			}
			if (item?.unit === 'floz') {
				itemPrice /= 128;
			}
			if (!item?.productTaxValue) {
				return `₹${addCommas(
					(itemPrice * itemQty * item?.taxPercentage) / 100
				)}`;
			}
			return `₹${addCommas(item?.productTaxValue)}` ?? '-';
		},
	};

	const handleExport = () => {
		const headers = [
			'Product Name',
			'Variant Name',
			'Unit',
			'SKU Type',
			'Category Name',
			'Quantity',
		];
		const totalValues = [];
		const exportData = [
			...items?.INTERMEDIATE ?? [],
			...items?.INGREDIENT ?? [],
		];
		exportData?.forEach((product) => {
			totalValues?.push([
				product?.name?.split(',')?.join(' ') ?? '-',
				product?.variant ?? '-',
				product?.unit ?? '-',
				product?.skuType ?? '-',
				product?.categoryName ?? '-',
				product?.qty ?? '-',
			]);
		});
		handleExcelDownload(
			headers,
			totalValues,
			`Left Over Inventory Items of ${data?.processName} for ${data?.date}`
		);
	};

	return (
		<BaseModal
			open={show}
			heading={heading}
			handleClose={handleClose}
			showConfirm={false}
			width={data?.width ?? '60rem'}
			height={data?.height ?? '30rem'}
		>
			<div className={styles.root}>
				<div className={styles.header}>
					<div
						onClick={() => {
							handleExport();
						}}
						className={styles.downloadData}
					>
						<img src={excel} alt='excel' />
						EXPORT
					</div>
				</div>
				<div className={styles.items}>
					<TextField
						fullWidth
						value={searchQuery}
						onChange={(e) => {
							setSearchQuery(e?.target?.value);
						}}
						placeholder='Search Item'
					/>
					<Nav
						appearance='tabs'
						activeKey={selectedTypeTab}
						onSelect={setSelectedTypeTab}
						className={styles.tabs}
					>
						<Nav.Item
							style={{
								background: '#FBF1D3',
							}}
							className={classes(
								styles.tab,
								styles?.[selectedTypeTab],
								selectedTypeTab === 'INTERMEDIATE'
									? styles.selected
									: ''
							)}
							eventKey='INTERMEDIATE'
						>
							INTERMEDIATE
						</Nav.Item>
						<Nav.Item
							style={{
								background: '#D3FAC7',
							}}
							className={classes(
								styles.tab,
								styles?.[selectedTypeTab],
								selectedTypeTab === 'INGREDIENT'
									? styles.selected
									: ''
							)}
							eventKey='INGREDIENT'
						>
							INGREDIENT
						</Nav.Item>
					</Nav>
					<div
						className={classes(
							styles.list,
							styles?.[selectedTypeTab]
						)}
					>
						<ListTable
							colMap={COLMAP}
							columns={TABLE_COLUMNS}
							fixedHeaderTop={0}
							data={ITEMS}
							exportFilename={heading?.split(' ')?.join('_')}
							showTotal
							showBtnContainer={false}
							actions={[
								...SPECIAL_COLUMNS,
								...ACTION_COLUMNS,
							]?.filter((col) => {
								return ![
									...(excludedColumns ?? [
										'Fulfilled',
										'From Process',
										'PO Id',
										'Quantity',
									]),
									showProductionPlanColumns &&
										'Production Plan Name',
								]?.includes(col?.title);
							})}
							special={SPECIAL_ITEMS}
						/>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.leftOverItemsModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleLeftOverItemsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftOverItemsModal);
