import { cloneDeep, update } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import GeneralSettings from '../../../Components/Forms/Inventory/Inventory Setting/GeneralSettings/GeneralSettings';
import StoreInventorySettings from '../../../Components/Forms/Inventory/Inventory Setting/StoreInventorySettings/StoreInventorySettings';
import { CreatePageHeader } from '../../../Components/Headers';
import { inputHelper, isEmpty } from '../../../Helpers';
import inventorySettingConfig from './inventorySetting.config';

const InventorySettingEditPage = (props) => {
	const { data, fetchSettings, updateSettings, userPermissions } = props;

	const [state, setState] = useState({
		...inventorySettingConfig.initialState,
	});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);

	const { fields } = state;

	useEffect(() => {
		fetchSettings({
			entity: 'INVENTORY_SETTING',
			req: {},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(data) && numberOfSubmissions === 0) {
			setState(inventorySettingConfig.serializer(state, data));
		}
	}, [data]);

	const handleInput = (e) => {
		setNumberOfSubmissions(numberOfSubmissions + 1);
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		const checkboxValue = e.target.value;
		let req = {};
		let clonedState = cloneDeep(state);
		switch (dataset.type) {
			case 'MASTER':
				req = inventorySettingConfig.deserializer(dataset.type, {
					fieldName,
					fieldValue:
						fieldName === 'qcMode'
							? checkboxValue.toUpperCase()
							: fieldValue,
				});
				clonedState = update(clonedState, 'fields', (f) => {
					const newFields = f;
					newFields[fieldName] =
						fieldName === 'qcMode'
							? checkboxValue.toUpperCase()
							: fieldValue;
					return newFields;
				});
				break;
			case 'STORE':
				req = inventorySettingConfig.deserializer(dataset.type, {
					fieldName,
					fieldValue,
					id: dataset.id,
				});
				clonedState = update(clonedState, 'fields.stores', (stores) => {
					const newStores = stores;
					const foundStore = newStores.find((store) => {
						return store.id === parseInt(dataset.id, 10);
					});
					foundStore[fieldName] = fieldValue;
					return newStores;
				});
				break;
			default:
				break;
		}
		updateSettings({
			entity: 'INVENTORY_SETTING',
			req,
			method: 'PATCH',
			promisify: true,
		})
			.then((res) => {
				if (res.status === 'Success') {
					setState(clonedState);
				}
			})
			.catch(() => {
				// eslint-disable-next-line no-console
			});
	};

	return (
		<div>
			<CreatePageHeader
				heading='Inventory Settings'
				showCancel={false}
				showSave={false}
			/>
			{/* general settings */}
			<FormSectionHeader
				tooltip='Master Level Inventory Settings'
				sectionName='General Settings'
			>
				<GeneralSettings
					fields={fields}
					handleInput={handleInput}
					isDisabled={!userPermissions?.EDIT}
				/>
			</FormSectionHeader>
			{/* store inventory settings */}
			<FormSectionHeader
				tooltip='Inventory Settings for Stores'
				sectionName='Store Inventory Settings'
			>
				<StoreInventorySettings
					fields={fields}
					handleInput={handleInput}
				/>
			</FormSectionHeader>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.INVENTORY_SETTING.data.VIEW;
	const { userPermissions } = state.navigation;
	return {
		data,
		userPermissions,
	};
};

const mapDispatchToProps = {
	fetchSettings: formActions.gateway,
	updateSettings: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventorySettingEditPage);
